// @ts-nocheck
import React, { useEffect } from "react";
import "./index.css";
import ReactQuill from "react-quill";
import { isUndefined } from "lodash";
import { replaceHTags } from "../../../utils/editor";

const QuestionDesc = ({ helpers }) => {
  let { question, setFormState, questionIndex, activeState } = helpers;
  let questionQuillRef = React.useRef(null);
  const [isEditorActive, setIsEditorActive] = React.useState(false);
  const [unprivilegedEditor, setUnprivilegedEditor] = React.useState(null);

  function showEditMenu(display) {
    let input = document.querySelector(
      `#quill-question-desc-${question.id} .ql-toolbar`
    );
    console.log(input);
    input.style.display = display;
    let qlEditor = document.querySelector(
      `#quill-question-desc-${question.id} .ql-container .ql-editor`
    );

    if (display === "none") {
      qlEditor.style.borderBottom = "1px solid #0000001f";
    } else {
      qlEditor.style.borderBottom = "none";
    }
  }

  useEffect(() => {
    let qlEditor = document.querySelector(
      `#quill-question-desc-${question.id} .ql-container .ql-editor`
    );
    if (!qlEditor) return;

    if (isEditorActive) {
      qlEditor.style.borderBottom = "1px solid #0000001f";
    } else {
      qlEditor.style.borderBottom = "none";
    }
  }, [isEditorActive, question.id]);

  useEffect(() => {
    let isEditorActive_ =
      questionIndex.p === activeState.pageIndex &&
      questionIndex.q === activeState.questionIndex;
    setIsEditorActive(isEditorActive_);
  }, [activeState, questionIndex]);

  useEffect(() => {
    let quilleditor = questionQuillRef.current?.getEditor();
    const unprivilegedEditor =
      questionQuillRef.current?.makeUnprivilegedEditor(quilleditor);
    setUnprivilegedEditor(unprivilegedEditor);
  }, []);

  let isDescription = question?.isDescription;
  if (!isUndefined(isDescription) && !isDescription) {
    return null;
  }

  return (
    <div className="w-full mt-2.5 mb-4">
      <ReactQuill
        ref={questionQuillRef}
        value={question.descriptionHtml ?? question.description}
        placeholder="Description"
        onChange={async (value) => {
          let newValue = value;
          newValue = await replaceHTags(newValue);
          setFormState((prevState) => {
            let newState = { ...prevState };
            newState.pages.forEach((page) => {
              page.questions.forEach((question_) => {
                if (question_.id === question.id) {
                  let value_ = unprivilegedEditor
                    ?.getText()
                    ?.replace(/\n/g, "");
                  question_.descriptionHtml = newValue;
                  unprivilegedEditor?.getText() &&
                    (question_.description = value_);
                }
              });
            });
            return newState;
          });
        }}
        onFocus={() => {
          showEditMenu("flex");
        }}
        onBlur={() => {
          showEditMenu("none");
        }}
        modules={{
          toolbar: [
            ["bold", "italic", "underline"],
            [
              "link",
              // "image"
            ],
            [
              { list: "ordered" },
              { list: "bullet" },
              // {
              //   indent: "-1",
              // },
              // {
              //   indent: "+1",
              // },
            ],
            ["clean"],
          ],
        }}
        style={{
          fontSize: "14px",
        }}
        className="fade quill-que flex"
        id={`quill-question-desc-${question.id}`}
      />
    </div>
  );
};

export default QuestionDesc;
