// @ts-nocheck

import React from "react";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { DatePicker, Radio, Space } from "antd";

const Date = ({ data }) => {
  let { question, setFormState, isEditor, questionNo } = data;

  const onChange = (date, dateString) => {
    // console.log(dateString);

    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.value = dateString;
          }
        });
      });
      return newState;
    });
  };

  return (
    <div className="w-full mb-6">
      {/* {questionNo && <div className="mr-2">{questionNo}.</div>} */}
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex flex-row">
            {isEditor ? (
              <input
                type="text"
                value={question.title}
                placeholder="Question"
                onChange={(e) => {
                  setFormState((prevState) => {
                    let newState = { ...prevState };
                    newState.pages.forEach((page) => {
                      page.questions.forEach((question_) => {
                        if (question_.id === question.id) {
                          question_.title = e.target.value;
                        }
                      });
                    });
                    return newState;
                  });
                }}
                className="w-full"
                disabled={!isEditor}
              />
            ) : (
              <div className="text-gray-700 dark:text-gray-300 text-xs flex flex-row">
                <div
                  className="w-full"
                  dangerouslySetInnerHTML={{
                    __html: question.titleHtml ?? question.title,
                  }}
                ></div>
                {question?.required && (
                  <div className="text-red-500 h-fit  ml-1">*</div>
                )}
              </div>
            )}
          </div>
          {isEditor ? (
            <input
              className="text-xs mt-1"
              type="text"
              value={question.description}
              placeholder="Description"
              onChange={(e) => {
                setFormState((prevState) => {
                  let newState = { ...prevState };
                  newState.pages.forEach((page) => {
                    page.questions.forEach((question_) => {
                      if (question_.id === question.id) {
                        question_.description = e.target.value;
                      }
                    });
                  });
                  return newState;
                });
              }}
              disabled={!isEditor}
            />
          ) : (
            question.description && (
              <div
                className="w-full text-xs text-gray-500 mt-1"
                dangerouslySetInnerHTML={{
                  __html: question.descriptionHtml ?? question.description,
                }}
              ></div>
            )
          )}
        </div>
        <div className="mt-2 rounded-lg flex-1 appearance-none border border-gray-300 dark:border-gray-600 w-full bg-white text-gray-700 dark:bg-notion-dark-light dark:text-gray-300 dark:placeholder-gray-500 placeholder-gray-400 shadow-sm text-base  focus:border-transparent focus:ring-opacity-100">
          <DatePicker
            onChange={onChange}
            disabled={isEditor}
            className="w-full py-2.5 focus:border-none border-none shadow-none"
          />
        </div>
        {isEditor && (
          <div className="mt-4 flex">
            <button
              onClick={() => {
                setFormState((prevState) => {
                  let newState = { ...prevState };
                  newState.pages.forEach((page) => {
                    page.questions.forEach((question_) => {
                      if (question_.id === question.id) {
                        question_.required = !question_.required;
                      }
                    });
                  });
                  return newState;
                });
              }}
              className="ml-auto text-xs flex flex-row items-center"
            >
              {question.required ? (
                <BiToggleRight className="text-lg mr-2 text-primary" />
              ) : (
                <BiToggleLeft className="text-lg mr-2" />
              )}
              Required
            </button>
            <button
              onClick={() => {
                setFormState((prevState) => {
                  let newState = { ...prevState };
                  newState.pages.map((page) => {
                    let questions = page.questions.filter(
                      (question_) => question_.id !== question.id
                    );
                    page.questions = questions;
                  });
                  // console.log(newState);
                  return newState;
                });
              }}
              className="ml-6 text-xs flex flex-row items-center text-red-600"
            >
              <AiOutlineDelete className="text-lg mr-2" />
              Delete
            </button>
          </div>
        )}
        {!isEditor && question.error && (
          <div className="text-red-500 text-[10px] mt-4">
            {"This is a required question"}
          </div>
        )}
      </div>
    </div>
  );
};

export default Date;
