function setProfitWell() {
  let user = JSON.parse(localStorage.getItem("owner"));
  if (!user) return;
  console.log("setting up profitwell for ...", user.email);
  // setting up profit well with this
  window.profitwell('start', { 'user_email': user.email });

}

export { setProfitWell };
