import { useEffect, useMemo, useState } from "react";
import { MdClose } from "react-icons/md";
import { fetchOwner, getOwnerPlanDetails } from "../../utils";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";
import { BoloButton } from "../Common/Button/BoloButton";
import { useNavigate } from "react-router-dom";
// import { getDashboardUrlV1 } from "../../constants/constants";
// import { useIntercom } from "react-use-intercom";

import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { FaCircleCheck } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";

type TrialExtendedModalProps = {
  completedTask: {
    title: string;
    description: string;
    increasedDays: number;
  };
  setShow: any;
  show: boolean;
  isOnBoarding?: boolean;
};

const TrialExtendedModal = ({
  completedTask,
  setShow,
  show,
  isOnBoarding = false,
}: TrialExtendedModalProps) => {
  let { title, description, increasedDays } = completedTask || {};

  const navigate = useNavigate();

  // const intercomHelper = useIntercom();

  const closeModal = (e: any) => {
    e?.stopPropagation();
    setShow(false);
  };

  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);

  const currentPlan = owner?.planhistory?.[owner?.planhistory?.length - 1];

  const collaborators = owner?.collaborators;

  const collaboratorAlreadyAdded = owner?.collaboratorAlreadyAdded;

  const accountWorkspace = owner?.workSpaces?.[0]?.accountInfo;

  const isBranding =
    owner?.accountInfo?.companyLogo || owner?.accountInfo?.theme || false;
  let { usedQuanity, usageQuantityAllowed } = currentPlan;

  const countDaysRemaining = () => {
    let trialEndDate = currentPlan?.trialEndDate;
    let differenceInDays = 7;
    if (trialEndDate) {
      const givenDate = new Date(trialEndDate).getTime();
      const currentDate = new Date().getTime();
      const differenceInMs = givenDate - currentDate;
      differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    } else {
      const givenDate = new Date(owner?.createdAt).getTime();
      const currentDate = new Date().getTime();
      const differenceInMs = currentDate - givenDate;
      differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    }

    // console.log(
    //   `You have ${differenceInDays} days remaining of your free trial plan (Business Plan).`
    // );
    return differenceInDays;
  };

  console.log("MODAL RENDERED");

  const remainingSteps = useMemo(() => {
    return [
      {
        title: "Create a form",
        description: "Create a form with Boloforms.",
        isDone: usedQuanity[1] > 0,
        link: "/dashboard/forms",
        extendableDays: 1,
      },
      {
        title: "Send a PDF for signing",
        description: "Send a PDF for signing to your user",
        isDone: usedQuanity[0] > 0,
        link: "/dashboard/pdfs",
        extendableDays: 1,
      },
      {
        title: "Custom branding",
        description: "Customize your workspace with your brand",
        isDone: isBranding,
        link: "/settings?type=BRANDING&subType=",
        extendableDays: 1,
      },
      {
        title: "Invite a collaborator",
        description: "Add a collaborator to your workspace to work together",
        isDone: collaboratorAlreadyAdded,
        link: "/teams",
        extendableDays: 1,
      },
      {
        title: "Add custom PDF to your form",
        description: "Customize your form with a PDF template",
        isDone: usedQuanity[3] > 0,
        extendableDays: 1,
        link: "/dashboard/forms",
      },
      {
        title: "Send a PDF Template for signing",
        description: "Send a PDF Template for signing to your user",
        isDone: usedQuanity[5] > 0,
        link: "/dashboard/templates",
        extendableDays: 1,
      },
    ];
  }, [usedQuanity, isBranding, collaboratorAlreadyAdded]);
  const { width, height } = useWindowSize();

  const [recycle, setRecycle] = useState(true);

  let remainingStepsCount = remainingSteps.filter(
    (step) => !step.isDone
  ).length;

  let remainingStep = remainingSteps.find((step) => !step.isDone);

  let isAllCompleted =
    remainingStepsCount === 1 && remainingStep?.title === title;

  let isAllDoneInRemainingSteps = remainingSteps.every((step) => step.isDone);

  const [showAllDoneImage, setShowAllDoneImage] = useState(false);

  useEffect(() => {
    // if is all completed, show all done image after 1 second
    if (isAllCompleted || isAllDoneInRemainingSteps) {
      setTimeout(() => {
        setShowAllDoneImage(true);
      }, 700);
    }
  }, [isAllCompleted, isAllDoneInRemainingSteps]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRecycle(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (!show) return null;

  return (
    <div className="w-[100vw] h-screen inset-0 z-[999] fixed bg-black bg-opacity-20 flex justify-center items-center px-3 md:px-0 overflow-y-auto pb-4 md:mb-0">
      <Confetti width={width} height={height} recycle={recycle} />
      <div className="w-[100%] md:w-[800px] bg-white rounded relative px-4 fade flex flex-col md:flex-row mt-28 md:mt-0">
        <MdClose
          onClick={closeModal}
          className="absolute text-2xl top-4 right-4 text-gray-400 cursor-pointer z-[9999]"
        />
        <div className="text-sm md:w-[50%] w-full flex flex-col gap-8 px-2 py-8 border-b-2 md:border-r-2 border-gray-200 overflow-y-auto ">
          {remainingSteps.map((step, index) => {
            return (
              <div
                key={index}
                className="flex flex-col gap-1 cursor-pointer "
                onClick={() => {
                  if (step.isDone) return;
                  if (step.link) {
                    navigate(step.link);
                  }
                }}
              >
                <div className="flex items-center gap-2 ">
                  {step.isDone || title === step.title ? (
                    <FaCircleCheck className="text-emerald-600" size={18} />
                  ) : (
                    <FiCircle className="text-gray-500" size={18} />
                  )}
                  <p
                    className={`${
                      step.isDone && title !== step.title
                        ? "line-through"
                        : title === step.title
                        ? "strike"
                        : ""
                    } font-semibold text-gray-700 `}
                  >
                    {step.title}
                  </p>

                  <span
                    className={`font-medium whitespace-nowrap  px-3 py-0.5 rounded-md  text-[10px] ml ${
                      step.isDone || title === step.title
                        ? "animate-pulse bg-emerald-600 text-white"
                        : "bg-gray-200 text-gray-500"
                    }  `}
                  >
                    +{increasedDays || step.extendableDays || 1} days
                  </span>
                </div>
                <p
                  className={`w-fit flex items-center font-normal ml-6 text-gray-400 text-xs ${
                    step.isDone && title !== step.title
                      ? "line-through"
                      : title === step.title
                      ? "strike"
                      : ""
                  } `}
                >
                  {step.description}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-center w-full md:w-[50%] flex-col gap-2 p-3 relative">
          {!showAllDoneImage ? (
            <img
              src={IMAGE_LINKS.MEDAL_IMAGE}
              alt=""
              className={`w-[60%] animate-rotate-y animate-once animate-duration-1000 animate-delay-0 animate-ease-in-out  `}
            />
          ) : (
            <FaCheckCircle className="text-emerald-600 min-w-[60%] animate-rotate-y animate-once animate-duration-1000 animate-delay-0 animate-ease-in-out text-8xl h-[177px]" />
          )}
          {/* {!showAllDoneImage ? (
            <img
              src={IMAGE_LINKS.MEDAL_IMAGE}
              alt=""
              className={`w-[60%] animate-jump-out animate-duration-1000 animate-ease-in-out  `}
            />
          ) : (
            <FaCheckCircle className="text-emerald-600 min-w-[60%] animate-jump-in animate-duration-1000 animate-ease-in-out text-8xl h-[150px] mb-[27px]" />
          )} */}
          <h1 className="text-xl font-bold">Congratulations!</h1>
          {!isOnBoarding ? (
            <p className="text-gray-700 text-sm text-center ">
              We have extended your free trial by{" "}
              <span className="font-bold ">{increasedDays}</span> days. You now
              have <span className="font-bold ">{countDaysRemaining()}</span>{" "}
              days 🎉
            </p>
          ) : (
            <p className="text-gray-700 text-sm text-center ">
              We have given you a head start by extending your free trial by{" "}
              <span className="font-bold ">{increasedDays || 3}</span> days! 🎉
            </p>
          )}

          {!isOnBoarding ? (
            <p className="text-gray-700 text-sm text-center">
              Keep completing the tasks for more!
            </p>
          ) : (
            <p className="text-gray-700 text-sm text-center">
              Complete the tasks to extend your free trial further!
            </p>
          )}

          {/* <img
            src={IMAGE_LINKS.ARROW_IMAGE}
            alt="arrow"
            className={`hidden md:block absolute mix-blend-multiply w-16 h-16   opacity-70 ${
              isOnBoarding
                ? "bottom-8 -rotate-[20deg] -left-8"
                : "bottom-16 -rotate-[15deg] -left-4"
            } `}
          /> */}

          <BoloButton
            variant={"bolo-primary"}
            onClick={() => {
              navigate("/billings");
            }}
            className="rounded-md mt-2"
            size={"sm"}
          >
            Upgrade Plan{" "}
          </BoloButton>
        </div>
      </div>
    </div>
  );
};

export default TrialExtendedModal;
