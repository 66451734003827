import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import axios from "../../utils/axios";
import { BASE_URL } from "../../constants/constants";
// import { get } from "sortablejs";
import { useNavigate } from "react-router-dom";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { isFeatureEnabled } from "../../utils/paidFeat";
import BoloLoader from "../Common/Loader/BoloLoader";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const FormResponseSummary = ({ helpers }) => {
  const navigate = useNavigate();
  let { forms, active, formId } = helpers;
  const [responsesSummaryCharts, setResponsesSummaryCharts] = useState({});
  const [isFormAnalyticsEnabled, setIsFormAnalyticsEnabled] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const getResponsesSummaryCharts = async () => {
    try {
      setLoading(true);
      let { data } = await axios.get(
        `${BASE_URL}/chartsummary/form?formId=${forms?.[0]?.formId}&limit=4&page=${page}`
      );
      setLoading(false);
      console.log("data", data);
      setResponsesSummaryCharts(data.responsesSummaryCharts);
      setTotalPages(data.totalPages);

      return data;
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  function getMonthNameYear(monthYear) {
    let month = monthYear.split("-")[1];
    let year = monthYear.split("-")[0];

    let monthName = "";
    switch (month) {
      case "01":
        monthName = "Jan";
        break;
      case "02":
        monthName = "Feb";
        break;
      case "03":
        monthName = "Mar";
        break;
      case "04":
        monthName = "Apr";
        break;
      case "05":
        monthName = "May";
        break;
      case "06":
        monthName = "Jun";
        break;
      case "07":
        monthName = "Jul";
        break;
      case "08":
        monthName = "Aug";
        break;
      case "09":
        monthName = "Sep";
        break;
      case "10":
        monthName = "Oct";
        break;
      case "11":
        monthName = "Nov";
        break;
      case "12":
        monthName = "Dec";
        break;
      default:
        break;
    }

    return `${monthName} ${year}`;
  }

  async function getFormAnalyticsEnabledStatus() {
    const isFormAnalyticsEnabled = await isFeatureEnabled("FORM_ANALYTICS");
    setIsFormAnalyticsEnabled(isFormAnalyticsEnabled);
  }

  useEffect(() => {
    if (active.tab === "responses" && active.responses.tab === "summary") {
      getResponsesSummaryCharts();
    }
  }, [active.tab, active.responses.tab, page]);

  useEffect(() => {
    getFormAnalyticsEnabledStatus();
  }, []);

  if (!isFormAnalyticsEnabled) {
    return (
      <div
        className={`mt-3 rounded-lg border bg-white border-[#dadce0] overflow-hidden`}
      >
        <div className="w-[800px] bg-white rounded-lg relative">
          <div className="p-6 text-center">
            <h1 className="text-xl font-semibold text-gray-700 capitalize">
              Form Analytics is available in Team Plan and above
            </h1>
            <p className="text-sm text-gray-500 mt-1 capitalize">
              You are on Free Plan, Please upgrade to use this feature.
            </p>
          </div>
          <div className="mb-6 px-6 flex justify-center">
            <button
              onClick={() =>
                window.open(
                  "https://signature.boloforms.com/billings",
                  "_blank"
                )
              }
              className="px-5 py-2 bg-primary rounded text-white ml-2 font-medium text-sm hover:opacity-75"
            >
              <BsFillRocketTakeoffFill className="inline mr-2" /> Upgrade Now
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {!loading && (
        <div className="flex justify-between items-center">
          <div></div>
          <div className="flex flex-row justify-between items-center py-3 border-t md:border-t-2">
            <button
              onClick={() => {
                setPage(page - 1);
              }}
              disabled={page === 1 || loading}
              className="bg-white py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
            >
              Previous
            </button>
            <div className="text-gray-500 text-xs md:text-sm mx-4">
              {" "}
              <div>
                Page {page} of {totalPages}
              </div>
            </div>
            <button
              disabled={page === totalPages || loading}
              onClick={() => {
                setPage(page + 1);
              }}
              className="bg-white py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
            >
              Next
            </button>
          </div>
        </div>
      )}
      <div>
        {!loading ? (
          Object.keys(forms?.[0]?.responseItemsId)?.map((questionId) => {
            let chartsummary = responsesSummaryCharts?.[questionId];
            console.log("chartsummary", chartsummary);
            return (
              chartsummary && (
                <div
                  className={`mt-3 rounded-lg border bg-white border-[#dadce0] overflow-hidden`}
                >
                  <div className="flex-col h-full">
                    <div className="pt-6 pb-4 px-6  fade">
                      <h2 className="mb-2">{chartsummary?.question}</h2>
                      <p className="text-xs text-gray-600">
                        {chartsummary?.responseCount || 0} responses
                      </p>
                      <div className="mt-6">
                        {chartsummary.chartType === "pie" && (
                          <Pie
                            data={chartsummary?.chartData}
                            options={{
                              responsive: true,
                              maintainAspectRatio: false,
                              plugins: {
                                legend: {
                                  display: true,
                                  position: "right",
                                  align: "center",
                                  labels: {
                                    color: "#000",
                                    boxWidth: 10,
                                    boxHeight: 10,
                                    padding: 20,
                                  },
                                },
                              },
                            }}
                          />
                        )}
                        {chartsummary.chartType === "bar" && (
                          <Bar
                            data={chartsummary?.chartData}
                            options={chartsummary?.chartOptions || {}}
                          />
                        )}
                        {chartsummary.chartType === "text" && (
                          <div className="max-h-[400px] overflow-y-auto">
                            {chartsummary?.data?.answers?.map((item) => {
                              return (
                                item && (
                                  <div className="p-2.5 mt-1 rounded bg-gray-50 text-gray-600 text-sm">
                                    {item}
                                  </div>
                                )
                              );
                            })}
                            {chartsummary?.responseCount > 100 && (
                              <button
                                onClick={() => navigate(`/form?id=${formId}`)}
                                className="w-full p-2.5 mt-2 mb-4 rounded border border-gray-300 text-center text-blue-500 text-sm"
                              >
                                View {chartsummary?.responseCount - 100} more
                                responses in responses page
                              </button>
                            )}
                          </div>
                        )}
                        {chartsummary.chartType === "date" && (
                          <div className="">
                            {chartsummary?.chartData?.map((item) => {
                              return (
                                <div className="p-2.5 mt-1 text-gray-600 text-sm flex">
                                  <div className="px-4 py-2.5 font-semibold text-sm">
                                    {getMonthNameYear(item?.monthYear)}
                                  </div>
                                  <div className="border-l border-gray-800"></div>
                                  <div className="flex pl-4 items-center flex-wrap">
                                    {item?.answers?.map((item) => {
                                      return (
                                        <div className="m-0.5 pl-2.5 pr-[3px] py-[3px] h-[27px] rounded-full bg-primary flex items-center text-white text-xs">
                                          {item.answer}{" "}
                                          <div className="h-[21px] flex items-center justify-center rounded-full ml-2 py-1.5 px-2.5 bg-secondary text-primary">
                                            {item.count}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {chartsummary.chartType === "time" && (
                          <div className="">
                            {chartsummary?.chartData?.map((item) => {
                              return (
                                <div className="p-2.5 mt-1 text-gray-600 text-sm flex">
                                  <div className="flex pr-4 items-center flex-wrap">
                                    <div className="m-0.5 pl-2.5 pr-[3px] py-[3px] h-[27px] rounded-full bg-primary flex items-center text-white text-xs">
                                      {item.answer}{" "}
                                      <div className="h-[21px] flex items-center justify-center rounded-full ml-2 py-1.5 px-2.5 bg-secondary text-primary">
                                        {item.count}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })
        ) : (
          <div className="flex justify-center items-center h-[400px]">
            {/* <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div> */}
            <BoloLoader />
          </div>
        )}
      </div>
    </div>
  );
};

export default FormResponseSummary;
