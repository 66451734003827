//@ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import { BiCloudUpload } from "react-icons/bi";
import { CgMenuGridO, CgMenuGridR } from "react-icons/cg";
import { FaSignature } from "react-icons/fa";
import { GrTextAlignFull } from "react-icons/gr";
import {
  MdAccessTime,
  MdArrowDropDown,
  MdLinearScale,
  MdOutlineCheckBox,
  MdOutlineEvent,
  MdOutlineExpandCircleDown,
  MdOutlineNotes,
  MdOutlineRadioButtonChecked,
  MdShortText,
} from "react-icons/md";
import MixPanel from "../../../utils/services/mixpanel";

let options = [
  {
    type: "Signature",
    name: "Signature",
    icon: <FaSignature className="text-2xl text-gray-600" />,
  },
  {
    type: "Text",
    name: "Short answer",
    icon: <MdShortText className="text-2xl text-gray-600" />,
  },
  {
    type: "LongText",
    name: "Paragraph",
    icon: <MdOutlineNotes className="text-2xl text-gray-600" />,
  },
  {
    type: "RadioGroup",
    name: "Multiple choice",
    icon: <MdOutlineRadioButtonChecked className="text-2xl text-gray-600" />,
  },
  {
    type: "Checkbox",
    name: "Checkboxes",
    icon: <MdOutlineCheckBox className="text-2xl text-gray-600" />,
  },
  {
    type: "Dropdown",
    name: "Dropdown",
    icon: <MdOutlineExpandCircleDown className="text-2xl text-gray-600" />,
  },
  {
    type: "FileUpload",
    name: "File upload",
    icon: <BiCloudUpload className="text-2xl text-gray-600" />,
  },
  {
    type: "Date",
    name: "Date",
    icon: <MdOutlineEvent className="text-2xl text-gray-600" />,
  },
  {
    type: "Time",
    name: "Time",
    icon: <MdAccessTime className="text-2xl text-gray-600" />,
  },
  {
    type: "Linear",
    name: "Linear",
    icon: <MdLinearScale className="text-2xl text-gray-600" />,
  },
  {
    type: "GridCheckbox",
    name: "Checkbox Grid",
    icon: <CgMenuGridR className="text-2xl text-gray-600" />,
  },
  {
    type: "GridRadioGroup",
    name: "Multiple Choice Grid",
    icon: <CgMenuGridO className="text-2xl text-gray-600" />,
  },
];

const QuestionTypeChange = ({ helpers }) => {
  let { question, setFormState } = helpers;
  let activeQuestion = options.find((option) => option.type === question.type);
  const questionTypeDropdownRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [displacementAmount, setDisplacementAmount] = useState(0);
  const [prevQuestionHasOtherOption, setPreviousQuestionHasOtherOption] =
    useState(question?.hasOtherOption);
  const [prevQuestionOptions, setPrevQuestionOptions] = useState(
    question?.options
  );
  const [prevQuestionGridRange, setPrevQuestionGridRange] = useState(
    question?.gridRange
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeQuestionType = (questionType) => {
    if (questionType === "Signature") {
      new MixPanel().track("Form", {
        form_action: "form_add_signature_question",
        paid: false,
      });
      new MixPanel().increment("form_add_signature_question");
    }
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            const commonOptions = [
              {
                type: "option",
                text: "Option 1",
                value: "",
                enabled: true,
                goToNextPage: "next",
              },
              {
                type: "option",
                text: "Option 2",
                value: "",
                enabled: true,
                goToNextPage: "next",
              },
              {
                type: "option",
                text: "Option 3",
                value: "",
                enabled: true,
                goToNextPage: "next",
              },
              { type: "add", text: "Option 4", value: "", enabled: false },
              {
                type: "other",
                text: "Other (describe)",
                value: "",
                enabled: false,
              },
            ];

            const commonGridRange = {
              rows: ["Row 1", "Row 2", "Row 3"],
              columns: ["Column 1", "Column 2", "Column 3"],
            };

            if (
              questionType === "Checkbox" ||
              questionType === "RadioGroup" ||
              questionType === "Dropdown"
            ) {
              question_.options = prevQuestionOptions || commonOptions;
              question_.hasOtherOption = prevQuestionHasOtherOption || false;
              if (questionType !== "Checkbox") {
                question_.pageBasedOnAnswer = false;
              }
            } else {
              question_.options = commonOptions;
            }

            if (questionType === "FileUpload") {
              question_.multiple = false;
            }

            if (questionType === "Linear") {
              question_.linearRange = {
                first: 0,
                last: 10,
                labelFirst: "",
                labelLast: "",
              };
            }

            if (
              questionType === "GridCheckbox" ||
              questionType === "GridRadioGroup"
            ) {
              question_.gridRange = prevQuestionGridRange || commonGridRange;
            } else {
              if (prevQuestionGridRange) {
                question_.gridRange = commonGridRange;
              }
            }

            // for this condition when the question type is changes from text to other the response validation stays true thus creating an 'annoying' error

            // the responseValidation boolean field should go false regardless of the question type

            question_.responseValidation = false;
            question_.responseValidationValue = {};

            // if (questionType === "Text" || questionType === "LongText") {
            //   question_.responseValidation = false;
            //   question_.responseValidationValue = {};
            // }
            // this is not done as not to confuse the old users
            // automatically converting from DD/MM/YYYY format to MM/DD/YYYY format
            // if (questionType === "Date") {
            //   question_.dateFormat = "MM/DD/YYYY";
            // }
            question_.type = questionType;
            question_.value = "";
          }
        });
      });
      return newState;
    });
  };

  const handleOptionClick = (option, index) => {
    // console.log(-index * 48);
    let displacement = -index * 48;
    setDisplacementAmount(displacement);
    // console.log("displacementAmount", displacementAmount);
    changeQuestionType(option.type);
    setIsOpen((prev) => !prev);
  };

  const handleOutsideClick = (e) => {
    if (questionTypeDropdownRef.current !== null) {
      if (questionTypeDropdownRef.current.contains(e.target)) return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    setPrevQuestionOptions(question?.options);
    setPreviousQuestionHasOtherOption(question?.hasOtherOption);
  }, [question, question?.options]);

  useEffect(() => {
    setPrevQuestionGridRange(question?.gridRange);
  }, [question, question?.gridRange]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative ml-2">
      <div className="relative">
        {/* <div
          className="w-52 h-12 bg-white border border-gray-300 rounded-md flex items-center justify-between px-4 cursor-pointer"
          style={{
            color: selectedOption === "Choose" ? "#0000008a" : "black",
          }}
          onClick={toggleDropdown}
        >
          {selectedOption}
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 12a1 1 0 01-.707-.293l-3-3a1 1 0 111.414-1.414L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3A1 1 0 0110 12z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div> */}
        <div
          onClick={toggleDropdown}
          className="h-[48px] min-w-[242px] border py-2 px-3 rounded flex items-center cursor-pointer"
        >
          <div className="mr-2">{activeQuestion.icon}</div>
          <div className="ml-1 mr-2">
            <span className="text-gray-600 text-sm">{activeQuestion.name}</span>
          </div>
          <div className="ml-auto">
            <MdArrowDropDown className="text-2xl text-gray-500" />
          </div>
        </div>
        {isOpen && (
          <div
            ref={questionTypeDropdownRef}
            style={{
              marginTop: "-48px",
              position: "absolute",
              //   top: `${displacementAmount}px`,
              boxShadow:
                "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
            }}
            className="py-2 min-w-[242px] bg-white border border-[#0000001f] rounded z-50"
          >
            {options.map((option, index) => {
              let isBorder =
                index === 0 ||
                index === 2 ||
                index === 5 ||
                index === 6 ||
                index === 8;
              return (
                <Fragment key={index}>
                  <div
                    onClick={() => handleOptionClick(option, index)}
                    className={`${
                      activeQuestion.type === option.type && "bg-[#e4f0fc]"
                    } h-[48px] w-full py-2 px-3 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <div className="mr-2">{option.icon}</div>
                    <div className="ml-1 mr-2">
                      <span className="text-gray-600 text-sm">
                        {option.name}
                      </span>
                    </div>
                  </div>
                  {isBorder && (
                    <div className="border-t border-[#0000001f] my-2"></div>
                  )}
                  {/* //   <div
                //     key={index}
                //     className={`h-[45px] px-4 ${
                //       selectedOption === option ? "bg-[#1a73e814]" : "bg-white"
                //     } hover:bg-gray-100 cursor-pointer flex items-center`}
                //     onClick={() => handleOptionClick(option, index)}
                //   >
                //     {option}
                //     <div className="w-full h-0.5 bg-gray-300 absolute top-[45px] right-0"></div>
                //   </div> */}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionTypeChange;
