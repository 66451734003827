import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import { BASE_URL } from "../../../constants/constants";
import PleaseUpgradeModal from "../../PleaseUpgradeModal";
import { isFeatureEnabled } from "../../../utils/paidFeat";
import { useAppSelector } from "../../../redux/store";
import MixPanel from "../../../utils/services/mixpanel";
// import psl from "psl";
import { ALL_FEATURES } from "../../../types/tiers";
import { BoloButton } from "../../Common/Button/BoloButton";
import { validateEmail } from "../../../utils/validateEmail";
import { FaInfo } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import { pslAltParse } from "../../../constants/whiteLabel";
import {
  BoloTab,
  BoloTabSwitcher,
} from "../../Common/BoloTabSwitcher/BoloTabSwitcher";
import { useSearchParams } from "react-router-dom";
import useWorkSpaceDetails from "../../../hooks/reduxHooks/useGetWorkSpaceDetails";

const CName = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const workSpaceDetails = useWorkSpaceDetails();

  const [owner, setOwner] = useState(
    JSON.parse(localStorage.getItem("owner") || "{}")
  );
  const rxOwner = useAppSelector(
    (state) => state?.root?.ownerSlice?.data?.owner
  );
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isGmailPermission, setIsGmailPermission] = useState(false);
  const [senderEmailInfo, setSenderEmailInfo] = useState({
    name: whiteLabelInfo?.settings?.organization?.name || "BoloForms",
    email:
      whiteLabelInfo?.settings?.organization?.mailingEmail ||
      "notification@email.boloforms.com",
    isSendEmailEnabled: false,
    emailVerified: false,
  });
  const [senderOrgPersonalEmailInfo, setSenderOrgPersonalEmailInfo] = useState({
    name: "",
    email: "",
    emailVerified: false,
  });
  const [emailSettings, setEmailSettings] = useState({ edit: false });
  const [sendEmailsUsing, setSendEmailsUsing] = useState("ORG_EMAIL");

  const updatePersonalEmailInfo = async () => {
    if (!validateEmail(senderOrgPersonalEmailInfo.email)) {
      return toast.error("Invalid Email");
    }

    let isEmailBranding = await isFeatureEnabled("DNS_SETTING");
    if (!isEmailBranding) {
      setShowUpgradeModal(true);
      setUpgradeModalType("DNS_SETTING");
      return;
    }

    if (
      (senderOrgPersonalEmailInfo.email === "notification@boloforms.com" &&
        senderOrgPersonalEmailInfo.name !== "BoloForms") ||
      (senderOrgPersonalEmailInfo.email === "notification@emailboloforms.com" &&
        senderOrgPersonalEmailInfo.name !== "BoloForms")
    ) {
      return toast.error("You cannot use this email.");
    }

    if (!senderOrgPersonalEmailInfo.email || !senderOrgPersonalEmailInfo.name) {
      return toast.error("Please enter email and name");
    }

    let { data } = await axios.put(`/workspace/collaborator-emailsettings`, {
      emailSettings: {
        senderName: senderOrgPersonalEmailInfo.name,
        senderEmail: senderOrgPersonalEmailInfo.email,
      },
      collaboratorId: owner._id,
    });

    toast.success(data.message);

    setSenderOrgPersonalEmailInfo((prev) => ({
      ...prev,
      emailVerified: data.collaborator?.postmark?.emailVerified ?? false,
    }));
  };

  const updateSenderEmailPreferences = async (prefrence: string) => {
    let isEmailBranding = await isFeatureEnabled("DNS_SETTING");
    if (!isEmailBranding) {
      setShowUpgradeModal(true);
      setUpgradeModalType("DNS_SETTING");
      return;
    }
    try {
      setSendEmailsUsing(prefrence);
      let { data } = await axios.put(`/workspace/sender-email-preferences`, {
        sendEmailsUsing: prefrence,
      });

      toast.success(data.message);

      setSenderOrgPersonalEmailInfo((prev) => ({
        ...prev,
        emailVerified: data.collaborator?.postmark?.emailVerified ?? false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCollaboratorEmailSettings = async () => {
    try {
      let { data } = await axios.put(
        `/workspace/delete-collaborator-email-settings`,
        {
          collaboratorId: owner?._id,
        }
      );

      toast.success(data.message);

      setSenderOrgPersonalEmailInfo({
        name: "",
        email: "",
        emailVerified: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateSenderEmailInfo = async (disable = false) => {
    try {
      // let isPaidPlan = await isEntryPaidPlan(owner, "EMAIL_SETTINGS");
      // if (!isPaidPlan) {
      //   return setShow((prev) => ({
      //     ...prev,
      //     paidModal: {
      //       visible: true,
      //       data: {
      //         title: "Organization Email Settings is a premium feature!",
      //         desc: "You are on free plan. Please upgrade to use this feature.",
      //         videoSrc: "https://www.youtube.com/embed/JeqRfnr3XYU",
      //       },
      //     },
      //   }));
      // }

      console.log({ senderEmailInfo });

      if (!validateEmail(senderEmailInfo.email)) {
        return toast.error("Invalid Email");
      }

      let isEmailBranding = await isFeatureEnabled("DNS_SETTING");
      if (!isEmailBranding) {
        setShowUpgradeModal(true);
        setUpgradeModalType("DNS_SETTING");
        return;
      }

      if (
        (senderEmailInfo.email === "notification@boloforms.com" &&
          senderEmailInfo.name !== "BoloForms") ||
        (senderEmailInfo.email === "notification@emailboloforms.com" &&
          senderEmailInfo.name !== "BoloForms")
      ) {
        return toast.error("You cannot use this email.");
      }

      // the above is is obsolete delete later
      // let parsedLocation_ = psl.parse(window.location.hostname);
      // let parsedLocation = !parsedLocation_.error
      //   ? parsedLocation_?.domain
      //   : "";

      let parsedLocation = pslAltParse(window.location.hostname);

      if (
        senderEmailInfo.email?.includes("boloforms.com") ||
        (parsedLocation && senderEmailInfo.email?.includes(parsedLocation))
      ) {
        return toast.error("You can't use this email.");
      }

      let { data } = await axios.post(`/owners/sender-signature`, {
        senderName: senderEmailInfo.name,
        senderEmail: senderEmailInfo.email,
        isSendEmailEnabled: disable,
      });
      setSenderEmailInfo((prev) => ({
        ...prev,
        emailVerified: data?.postmark?.emailVerified ?? false,
      }));

      data?.owner && localStorage.setItem("owner", JSON.stringify(data?.owner));
      data?.owner && setOwner(data?.owner);

      toast.success("Verification email sent");
      setEmailSettings((prev) => ({ ...prev, edit: false }));
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const updateSenderEmailStatus = async (disable = false) => {
    let isEmailBranding = await isFeatureEnabled("DNS_SETTING");
    if (!isEmailBranding) {
      setShowUpgradeModal(true);
      setUpgradeModalType("DNS_SETTING");
      return;
    }
    try {
      setSenderEmailInfo((prev) => ({ ...prev, isSendEmailEnabled: disable }));
      const { data } = await axios.post(`/owners/sender-signature`, {
        // ownerId: owner?._id,
        senderName: senderEmailInfo.name,
        senderEmail: senderEmailInfo.email,
        isSendEmailEnabled: disable,
      });

      data?.owner && localStorage.setItem("owner", JSON.stringify(data?.owner));
      data?.owner && setOwner(data?.owner);

      toast.success(`Organization Email ${disable ? "Enabled" : "Disabled"}`);
      setEmailSettings((prev) => ({ ...prev, edit: false }));
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const updateSenderEmailVerifiedStatus = async () => {
    let isEmailBranding = await isFeatureEnabled("DNS_SETTING");
    if (!isEmailBranding) {
      setShowUpgradeModal(true);
      setUpgradeModalType("DNS_SETTING");
      return;
    }
    try {
      let { data } = await axios.put(`/owners/sender-signature`, {
        // ownerId: owner?._id,
        email: senderEmailInfo.email,
      });

      data?.owner && localStorage.setItem("owner", JSON.stringify(data?.owner));
      data?.owner && setOwner(data?.owner);

      setSenderEmailInfo((prev) => ({
        ...prev,
        emailVerified: data.emailVerified,
        isSendEmailEnabled: data.isSendEmailEnabled,
      }));

      data.emailVerified
        ? toast.success("Email is verified")
        : toast.error("Please verify your email");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  function userEmailSet() {
    let domain = whiteLabelInfo?.domain || "boloforms.com";
    return (
      !senderEmailInfo.email?.includes(domain) &&
      senderEmailInfo.email !== "notification@boloforms.com" &&
      senderEmailInfo.email !== "no-reply@boloforms.com"
    );
  }

  function userSetEmailAndNotVerified() {
    return userEmailSet() && !senderEmailInfo.emailVerified;
  }

  useEffect(() => {
    let owner = JSON.parse(localStorage.getItem("owner") || "{}");
    let postmark = owner?.settings?.mailing?.postmark;
    const sendEmailsUsing =
      owner?.settings?.mailing?.sendEmailsUsing || "ORG_EMAIL";
    setSendEmailsUsing(sendEmailsUsing);

    console.log({ postmark });
    if (postmark) {
      setSenderEmailInfo({
        name: postmark.senderName,
        email: postmark.senderEmail,
        isSendEmailEnabled: postmark?.isSendEmailEnabled ?? false,
        emailVerified: postmark?.emailVerified ?? false,
      });
    }

    let collaborator = workSpaceDetails?.currentWorkSpace?.collaborators?.find(
      (collaborator: any) => collaborator.collaboratorId?._id === owner?._id
    );

    if (collaborator) {
      setSenderOrgPersonalEmailInfo({
        name: collaborator?.postmark?.name,
        email: collaborator?.postmark?.email,
        emailVerified: collaborator?.postmark?.emailVerified ?? false,
      });
    }
  }, [owner, rxOwner]);

  useEffect(() => {
    let owner = JSON.parse(localStorage.getItem("owner") || "{}");
    let postmark = owner?.settings?.mailing?.postmark;
    if (!postmark) {
      setSenderEmailInfo((prev) => ({
        ...prev,
        name: whiteLabelInfo?.settings?.organization?.name || "BoloForms",
        email:
          whiteLabelInfo?.settings?.organization?.mailingEmail ||
          "notification@email.boloforms.com",
      }));
    }
  }, [whiteLabelInfo, rxOwner]);

  return (
    <div className="max-w-[600px] w-[100%] md:w-[80%] md:mx-4">
      <div className="mb-4">
        <h3 className="text-md font-semibold mb-1">Email Settings</h3>
        <p className="text-xs text-gray-500 mb-4">
          Manage your email settings here.
        </p>

        {/* <BoloTabSwitcher
          value={searchParams.get("subType") || "ORG_EMAIL"}
          onChangeTab={(tab) => {
            searchParams.set("subType", tab);
            setSearchParams(searchParams);
          }}
        >
          <BoloTab value="ORG_EMAIL" size={"sm"}>
            {" "}
            Organization Email
          </BoloTab>
          {workSpaceDetails?.currentWorkSpaceRole !== "owner" && (
            <BoloTab value="ORG_PERSONAL_EMAIL" size={"sm"}>
              Personal Email
            </BoloTab>
          )}
        </BoloTabSwitcher> */}
      </div>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          blockingType={upgradeModalType}
          data={{}}
        />
      )}

      <div className="mt-6">
        {workSpaceDetails?.currentWorkSpaceRole === "owner" && (
          // (!searchParams.get("subType") ||
          //   searchParams.get("subType") === "ORG_EMAIL") &&
          <div className="mt-8">
            {/* <div className="mb-2">
              <h3 className="text-sm font-semibold mb-1 text-gray-600">
                Organization Email
              </h3>
              <p className="text-xs text-gray-500">
                Set up the organization email that will be used to send emails.
              </p>
            </div> */}

            {/* {senderEmailInfo.isSendEmailEnabled  ? ( */}
            <div className="w-full mt-4">
              <div className="text-xs">
                <div className="mb-4">
                  <label className="inline-flex items-center mb-1 font-medium text-xs text-gray-700">
                    Sender Name
                  </label>{" "}
                  <input
                    type="text"
                    className="border border-gray-300 outline-none rounded-md px-4 py-2 w-full text-sm text-gray-600"
                    placeholder="Sender Name"
                    value={senderEmailInfo.name}
                    disabled={!emailSettings.edit}
                    onChange={(e) => {
                      setSenderEmailInfo({
                        ...senderEmailInfo,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>

                <div>
                  <label className="inline-flex items-center mb-1 font-medium text-xs text-gray-700">
                    Sender Email
                  </label>{" "}
                  <div className="mb-2 relative">
                    <input
                      type="email"
                      className="mb-2.5 border border-gray-300 outline-none rounded-md px-4 py-2 w-full text-sm text-gray-600"
                      placeholder="Sender Email"
                      value={senderEmailInfo.email}
                      disabled={!emailSettings.edit}
                      onChange={(e) => {
                        setSenderEmailInfo({
                          ...senderEmailInfo,
                          email: e.target.value,
                        });
                      }}
                    />
                    {senderEmailInfo.email !== "notification@boloforms.com" &&
                      senderEmailInfo.email !== "no-reply@boloforms.com" &&
                      senderEmailInfo.email !==
                        "notification@email.boloforms.com" && (
                        <span
                          className={`m-2 px-3 py-1 text-[10px] text-white rounded-lg absolute right-0 ${
                            senderEmailInfo.emailVerified
                              ? "bg-green-500"
                              : "bg-red-500"
                          }`}
                        >
                          {senderEmailInfo.emailVerified
                            ? "Verified"
                            : "Not Verified"}
                        </span>
                      )}
                  </div>
                </div>

                <div className="flex gap-2 text-xs text-gray-500 items-center">
                  <FaInfoCircle /> Your emails will be sent via{" "}
                  <span className="text-primary">
                    {senderEmailInfo?.isSendEmailEnabled
                      ? senderEmailInfo?.email
                      : whiteLabelInfo?.settings?.organization?.mailingEmail ||
                        "notification@email.boloforms.com"}
                  </span>
                </div>
              </div>
              <div className="flex mt-4 justify-end gap-2">
                <BoloButton
                  size={"sm"}
                  variant={
                    emailSettings.edit || userEmailSet()
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    setEmailSettings((prev) => ({
                      ...prev,
                      edit: emailSettings.edit ? false : true,
                    }));
                  }}
                  // className="text-xs text-primary border px-4 py-2 rounded-md"
                >
                  {!emailSettings.edit ? "Edit" : "Cancel"}
                </BoloButton>
                {emailSettings.edit && (
                  <BoloButton
                    size={"sm"}
                    onClick={() => {
                      new MixPanel().track("Email", {
                        custom_email_action: "custom_email_update",
                        paid: true,
                      });
                      new MixPanel().increment("custom_email_update");
                      updateSenderEmailInfo();
                    }}
                    // className="text-xs text-white bg-primary px-4 py-2 rounded-md disabled:opacity-50"
                  >
                    Update Email Settings
                  </BoloButton>
                )}
                {senderEmailInfo.emailVerified && !emailSettings.edit && (
                  <>
                    <BoloButton
                      size={"sm"}
                      variant={
                        senderEmailInfo.isSendEmailEnabled
                          ? "danger-border"
                          : "success-border"
                      }
                      onClick={() =>
                        updateSenderEmailStatus(
                          !senderEmailInfo.isSendEmailEnabled
                        )
                      }
                      // className={`text-xs text-white bg-primary px-4 py-2 rounded-md disabled:opacity-50`}
                    >
                      {senderEmailInfo.isSendEmailEnabled
                        ? "Disable"
                        : "Enable"}
                    </BoloButton>
                  </>
                )}

                {/* <BoloButton
                  size={"sm"}
                  onClick={() =>
                    updateSenderEmailStatus(senderEmailInfo.isSendEmailEnabled)
                  }
                  // className={`text-xs text-white bg-primary px-4 py-2 rounded-md disabled:opacity-50`}
                >
                  Save
                </BoloButton> */}

                {userSetEmailAndNotVerified() && !emailSettings.edit && (
                  <BoloButton
                    size={"sm"}
                    onClick={updateSenderEmailVerifiedStatus}
                    // className="text-xs text-white bg-primary px-4 py-2 rounded-md"
                  >
                    I have verified my email
                  </BoloButton>
                )}
              </div>
            </div>
            {/* 
            ) : (
            <div className="w-full ">
                <div>
                  <label className="inline-flex items-center mb-1 font-medium text-xs text-gray-700">
                    Sender Email
                  </label>{" "}
                  <div className="relative">
                    <input
                      type="email"
                      className="border outline-none rounded-md px-4 py-3 w-full text-xs text-gray-600 disabled:bg-white"
                      placeholder="Sender Email"
                      value={owner?.email}
                      disabled
                    />
                    <div
                      className={`mx-2 mt-2 px-3 py-1 text-[10px] text-white rounded-md absolute right-0 top-0 ${
                        isGmailPermission
                          ? "bg-green-500"
                          : "bg-primary border hover:opacity-90"
                      }`}
                    >
                      {isGmailPermission ? (
                        "Permission Granted"
                      ) : (
                        <button
                          onClick={() => {
                            window.open(
                              `${BASE_URL}/auth/google?scopesType=${
                                owner?.oAuthRefreshToken
                                  ? "gmail-sheets"
                                  : "gmail"
                              }&ownerEmail=${owner?.email}&redirectUrl=${
                                window.location.href
                              }`,
                              "_self"
                            );
                          }}
                        >
                          Grant Permission
                        </button>
                      )}
                    </div>
                  </div>
                  {!isGmailPermission && (
                    <p className="mt-1 text-[10px] text-red-600">
                      Click on <b>Grant Permission</b> to send email using your
                      Gmail account
                    </p>
                  )}
                </div>
                <button
                  onClick={() => updateSenderEmailStatus(false)}
                  className="mt-6 text-sm text-white bg-primary px-4 py-2 rounded-md"
                >
                  Save
                </button>
              </div>
            )} 
            */}

            <div className="mt-6 pt-4">
              <div className="mb-2">
                <h3 className="text-sm font-semibold mb-1 text-gray-600">
                  Other Email Settings
                </h3>
                <p className="text-xs text-gray-500">
                  This setting will be applied only if the organization email is
                  set as the default email. If checked, collaborators will be
                  able to send emails using their personal email.
                </p>
              </div>
              <div className="mt-5 pb-4 flex items-center gap-2">
                {/* <input
                type="radio"
                id="org-email"
                name="send-emails-using"
                value="ORG_EMAIL"
                checked={sendEmailsUsing === "ORG_EMAIL"}
                onChange={() => updateSenderEmailPreferences("ORG_EMAIL")}
                className="w-4 h-4"
              />
              <label htmlFor="org-email" className="text-gray-500 text-sm mr-4">
                Organization email
              </label>

              <input
                type="radio"
                id="collaborators-email"
                name="send-emails-using"
                value="COLLABORATORS_EMAIL"
                checked={sendEmailsUsing === "COLLABORATORS_EMAIL"}
                onChange={() =>
                  updateSenderEmailPreferences("COLLABORATORS_EMAIL")
                }
                className="w-4 h-4"
              />

              <label
                htmlFor="collaborators-email"
                className="text-gray-500 text-sm"
              >
                Collaborators email (personal email)
              </label> */}

                <input
                  type="checkbox"
                  id="org-email"
                  name="send-emails-using"
                  value="ORG_EMAIL"
                  checked={sendEmailsUsing === "COLLABORATORS_EMAIL"}
                  onChange={(e) =>
                    updateSenderEmailPreferences(
                      e.target.checked ? "COLLABORATORS_EMAIL" : "ORG_EMAIL"
                    )
                  }
                  className="w-4 h-4"
                />
                <label
                  htmlFor="collaborators-email"
                  className="text-gray-500 text-sm"
                >
                  Allow collaborators to send emails using their personal email
                </label>
              </div>
            </div>
          </div>
        )}
        {workSpaceDetails?.currentWorkSpaceRole !== "owner" && (
          // searchParams.get("subType") === "ORG_PERSONAL_EMAIL" &&
          <div className="">
            <div className="mt-8 mb-4">
              <h3 className="text-sm font-semibold mb-1 text-gray-600">
                Personal Email
              </h3>
              <p className="text-xs text-gray-500">
                Set up the personal email that will be used to send emails. if
                you don't setup personal email, organization email will be used.
              </p>
            </div>
            <div className="w-full ">
              <div className="text-xs">
                <div className="mb-4">
                  <label className="inline-flex items-center mb-1 font-medium text-xs text-gray-700">
                    Sender Name
                  </label>{" "}
                  <input
                    type="text"
                    className="border border-gray-300 outline-none rounded-md px-4 py-2 w-full text-sm text-gray-600"
                    placeholder="Sender Name"
                    value={senderOrgPersonalEmailInfo.name}
                    disabled={!emailSettings.edit}
                    onChange={(e) => {
                      setSenderOrgPersonalEmailInfo({
                        ...senderOrgPersonalEmailInfo,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>

                <div>
                  <label className="inline-flex items-center mb-1 font-medium text-xs text-gray-700">
                    Sender Email
                  </label>{" "}
                  <div className="mb-2 relative">
                    <input
                      type="email"
                      className="mb-2.5 border border-gray-300 outline-none rounded-md px-4 py-2 w-full text-sm text-gray-600"
                      placeholder="Sender Email"
                      value={senderOrgPersonalEmailInfo.email}
                      disabled={!emailSettings.edit}
                      onChange={(e) => {
                        setSenderOrgPersonalEmailInfo({
                          ...senderOrgPersonalEmailInfo,
                          email: e.target.value,
                        });
                      }}
                    />

                    {senderOrgPersonalEmailInfo.email && (
                      <span
                        className={`m-2 px-3 py-1 text-[10px] text-white rounded-lg absolute right-0 ${
                          senderOrgPersonalEmailInfo.emailVerified
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      >
                        {senderOrgPersonalEmailInfo.emailVerified
                          ? "Verified"
                          : "Not Verified"}
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className="flex gap-2 text-xs text-gray-500 items-center">
                  <FaInfoCircle /> Your emails will be sent via{" "}
                  <span className="text-primary">
                    {senderEmailInfo?.isSendEmailEnabled
                      ? senderEmailInfo?.email
                      : whiteLabelInfo?.settings?.organization?.mailingEmail ||
                        "notification@email.boloforms.com"}
                  </span>
                </div> */}
              </div>
              <div className="flex gap-2 text-xs text-gray-500">
                <FaInfoCircle />
                <p>
                  {owner?.settings?.mailing?.sendEmailsUsing ===
                    "ORG_EMAIL" && (
                    <span>
                      The workspace owner has restricted sending emails via
                      personal emails. <br />
                    </span>
                  )}
                  Your emails will be sent via{" "}
                  <span className="text-primary">
                    {owner?.settings?.mailing?.sendEmailsUsing ===
                    "COLLABORATORS_EMAIL"
                      ? senderOrgPersonalEmailInfo.email ||
                        senderEmailInfo?.email ||
                        whiteLabelInfo?.settings?.organization?.mailingEmail ||
                        "notification@email.boloforms.com"
                      : owner?.settings?.mailing?.sendEmailsUsing ===
                        "ORG_EMAIL"
                      ? senderEmailInfo?.email
                      : whiteLabelInfo?.settings?.organization?.mailingEmail ||
                        "notification@email.boloforms.com"}
                  </span>
                </p>
              </div>

              <div className="flex mt-4 justify-end gap-2">
                {!emailSettings.edit && (
                  <BoloButton
                    size={"sm"}
                    variant={"danger-border"}
                    onClick={deleteCollaboratorEmailSettings}
                  >
                    Delete
                  </BoloButton>
                )}
                <BoloButton
                  size={"sm"}
                  variant={
                    emailSettings.edit || userEmailSet()
                      ? "secondary"
                      : "primary"
                  }
                  onClick={() => {
                    setEmailSettings((prev) => ({
                      ...prev,
                      edit: emailSettings.edit ? false : true,
                    }));
                  }}
                  // className="text-xs text-primary border px-4 py-2 rounded-md"
                >
                  {!emailSettings.edit ? "Edit" : "Cancel"}
                </BoloButton>
                {emailSettings.edit && (
                  <BoloButton
                    size={"sm"}
                    onClick={() => {
                      new MixPanel().track("Email", {
                        custom_email_action: "custom_email_update",
                        paid: true,
                      });
                      new MixPanel().increment("custom_email_update");
                      updatePersonalEmailInfo();
                    }}
                  >
                    {senderOrgPersonalEmailInfo.emailVerified
                      ? "Update email settings"
                      : "Verify Email"}
                  </BoloButton>
                )}

                {/* {senderEmailInfo.emailVerified && !emailSettings.edit && (
                  <>
                    <BoloButton
                      size={"sm"}
                      variant={
                        senderEmailInfo.isSendEmailEnabled
                          ? "danger-border"
                          : "success-border"
                      }
                      onClick={() =>
                        updateSenderEmailStatus(
                          !senderEmailInfo.isSendEmailEnabled
                        )
                      }
                      // className={`text-xs text-white bg-primary px-4 py-2 rounded-md disabled:opacity-50`}
                    >
                      {senderEmailInfo.isSendEmailEnabled
                        ? "Disable"
                        : "Enable"}
                    </BoloButton>
                  </>
                )} */}

                {/* <BoloButton
                  size={"sm"}
                  onClick={() =>
                    updateSenderEmailStatus(senderEmailInfo.isSendEmailEnabled)
                  }
                  // className={`text-xs text-white bg-primary px-4 py-2 rounded-md disabled:opacity-50`}
                >
                  Save
                </BoloButton> */}

                {/* {userSetEmailAndNotVerified() && !emailSettings.edit && (
                  <BoloButton
                    size={"sm"}
                    onClick={updateSenderEmailVerifiedStatus}
                    // className="text-xs text-white bg-primary px-4 py-2 rounded-md"
                  >
                    I have verified my email
                  </BoloButton>
                )} */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CName;
