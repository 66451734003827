/* eslint-disable no-unused-vars */
import { BASE_URL } from "../constants/constants";

// const axios_ = require("axios").default;
import axios_ from "axios";
const axios = axios_.create({
  baseURL: BASE_URL,
  headers: {
    // "Content-Type": "application/json",
    "x-auth-token": localStorage.getItem("x-auth-token"),
    "bypass-token": localStorage.getItem("bypass-token"),
    // lowercase because headers are automtically turned to lower case
    workspaceid:
      localStorage.getItem("workspaceid") ||
      JSON.parse(localStorage.getItem("owner") || "{}")?.workSpaces?.[0]
        ?.workSpaceId,
    "x-whitelabelowner": localStorage.getItem("whitelabelowner_header"),
    // this is used to authenticate the whitelabel owner
    "x-partner-auth": localStorage.getItem("x-partner-auth"),
    "x-embed-token": localStorage.getItem("x-embed-token"),
    "embed-session-inputs": localStorage.getItem("embed-session-inputs"),
    "is-embeded": localStorage.getItem("is-embeded"),
    // "x-partner-bypass-token": localStorage.getItem("partner-bypass-token"),
  },
});

axios.interceptors.request.use((config) => {
  config.headers.workspaceid =
    localStorage.getItem("workspaceid") ||
    JSON.parse(localStorage.getItem("owner") || "{}")?.workSpaces?.[0]
      ?.workSpaceId;
  config.headers["x-whitelabelowner"] = localStorage.getItem(
    "whitelabelowner_header"
  );
  config.headers["x-auth-token"] = localStorage.getItem("x-auth-token");
  config.headers["x-partner-auth"] = localStorage.getItem("x-partner-auth");
  config.headers["x-embed-token"] = localStorage.getItem("x-embed-token");
  config.headers["embed-session-inputs"] = localStorage.getItem(
    "embed-session-inputs"
  );
  config.headers["is-embeded"] = localStorage.getItem("is-embeded");
  // config.headers["x-partner-bypass-token"] = localStorage.getItem(
  //   "partner-bypass-token"
  // );

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // whatever you want to do with the error
    // console.log("axios.interceptors.response.use", error);
    if (error?.response?.status === 401) {
      console.log("error?.response?.status === 401", error.config.url);
      localStorage.removeItem("user");
      localStorage.removeItem("workspaceid");
      localStorage.removeItem("currentUserEmail");
      localStorage.removeItem("accountType");
      localStorage.removeItem("isApprovalTimeline");
      localStorage.removeItem("formColumnPrefrences");
      localStorage.removeItem("respondentPageNo");
      localStorage.removeItem("owner");
      localStorage.removeItem("x-auth-token");
      localStorage.removeItem("x-partner-auth");
    }
    // When user try to access unautherized route
    if (error?.response?.status === 403) {
      window.location.href = "/";
    }
  }
);

export default axios;
