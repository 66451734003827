import React, { useState, useEffect } from "react";
// import Pager from './Pager';
// import Zoom from './Zoom';
import { Size, SchemaForUI } from "../../../../common/src";
// import { Size } from '@pdfme/common';

type Props = {
  size: Size;
  explicitSize: Size;
  pageCursor: number;
  pageNum: number;
  setPageCursor: (page: number) => void;
  zoomLevel: number;
  setZoomLevel: (zoom: number) => void;
  schemasList: SchemaForUI[][];
  setActiveSchema: any;
};

const barWidth = 250;

const RequiredBar = (props: Props) => {
  const {
    size,
    pageCursor,
    pageNum,
    setPageCursor,
    zoomLevel,
    setZoomLevel,
    explicitSize,
    schemasList,
    setActiveSchema,
  } = props;
  const width = pageNum > 1 ? barWidth : barWidth / 2;
  const [currentPage, setCurrentPage] = useState(pageCursor);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestion = schemasList[pageCursor][currentQuestionIndex];

  const items: { pageIdx: number; queIdx: number; question: SchemaForUI }[] =
    [];
  schemasList?.map((page, pageIdx) => {
    page?.map((question, queIdx) => {
      items.push({ question, pageIdx, queIdx });
    });
  });

  const handlePrevious = () => {
    // Implement the logic for going to the previous form step
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      console.log(currentQuestionIndex - 1);
    } else if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      setPageCursor(currentPage - 1);
      setCurrentQuestionIndex(schemasList[currentPage - 1].length - 1);
    }
  };

  const handleNext = () => {
    // Implement the logic for going to the next form step
    if (currentQuestionIndex < schemasList[currentPage].length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (currentPage < schemasList.length - 1) {
      setCurrentPage(currentPage + 1);
      setPageCursor(currentPage + 1);
      setCurrentQuestionIndex(0);
    }
  };

  useEffect(() => {
    setActiveSchema(currentQuestion);
  }, []);

  // useEffect(() => {
  //   setActiveSchema(currentQuestion);
  // }, [currentQuestion]);

  return null;

  return (
    <div
      className={`flex items-center justify-center left-[15px] bottom-[17px] bg-white mx-auto gap-4 text-sm`}
      style={{
        position: "fixed",
        zIndex: 200,
        // width: explicitSize.width, // Adjust the width as needed
        borderRadius: 2,
        // padding: "0.5rem",
      }}
    >
      <button
        className="border-2 border-primary  text-primary rounded-[3px] px-2 py-1"
        onClick={handlePrevious}
      >
        Previous
      </button>

      <select className="mr-2 px-4 py-2 bg-white border rounded">
        {items?.map((item) => {
          return (
            <option
              onClick={() => {
                let currentQuestion = schemasList[item.pageIdx][item.queIdx];
                if (pageCursor !== item.pageIdx) {
                  setPageCursor(item.pageIdx);
                }
                setActiveSchema(currentQuestion);
              }}
              value="total"
            >
              <span>{`${item.question.showKey || item.question.key} `}</span>
              {" - "}
              <span>{` Page ${item.pageIdx + 1}`}</span>
            </option>
          );
        })}
      </select>

      <button
        className="border-2 border-primary  text-primary rounded-[3px] px-2 py-1"
        onClick={handleNext}
      >
        Next
      </button>
    </div>
  );
};

export default RequiredBar;
