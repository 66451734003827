// @ts-nocheck

const AddonModal = ({ modalContent }) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div className={` h-[95%] w-[95%] bg-white rounded-sm overflow-y-auto`}>
        {modalContent}
      </div>
    </div>
  );
};

export default AddonModal;
