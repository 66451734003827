import React from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import { formatPrice } from "../../utils/quoteBuilder";

const PaymentTypeTab = ({
  title,
  amount,
  currency,
  count,
  color,
  tooltip,
}: {
  title: string;
  amount: number;
  currency: string;
  count: number;
  color: string;
  tooltip: string;
}) => {
  return (
    <div className="group flex flex-col justify-between border-2 rounded-md min-w-[250px] h-[100px] shadow p-3 hover:bg-gray-50 cursor-pointer">
      <div className="flex justify-between items-start">
        <div className={`text-xs ${color}`}>{title}</div>
        <IoInformationCircleOutline
          data-tooltip-id={`payment-tooltip-${title}`}
          className="text-gray-500 scale-0  group-hover:scale-100 transition-all duration-300"
          size={18}
        />
      </div>
      <CustomTooltip
        helpers={{
          id: `payment-tooltip-${title}`,
          content: tooltip,
          place: "top",
        }}
      />
      <div>
        <div className="text-lg font-bold">
          {formatPrice({ amount, currency })}
        </div>
        <div className="text-[10px] text-gray-500">{count} payments </div>
      </div>
    </div>
  );
};

export default PaymentTypeTab;
