import React from "react";
// import { Size } from '@pdfme/common';
import { RULER_HEIGHT } from "../../../constants";
import { Size } from "../../../../../common/src";

const Mask = ({ width, height }: Size) => (
  <div
    style={{
      position: "absolute",
      // top: -RULER_HEIGHT,
      // left: -RULER_HEIGHT,
      top: 0,
      left: 0,
      zIndex: 100,
      background: "rgba(0,0,0,0.1)",
      width,
      height,
    }}
  />
);

export default Mask;
