// @ts-nocheck

import React from "react";
import { v4 } from "uuid";

const SectionOptions = ({ helpers }) => {
  const { index, page, formState, setFormState, setShow, active, setActive } =
    helpers;

  const handleDuplicateSection = () => {
    setFormState((prevState) => {
      // console.log("old:", page?.questions?.[0]);
      let newForm = { ...prevState };
      let newPage = { ...page };
      newPage.id = v4();
      if (newPage.questions) {
        newPage.questions = newPage.questions?.map((question) => {
          return {
            ...question,
            id: v4(),
          };
        });
      }
      // console.log("new:", newPage?.questions?.[0]);
      newForm.pages.splice(index, 0, newPage);
      return newForm;
    });
    // console.log(formState);
    setShow((prev) => ({
      ...prev,
      moreOptions: false,
    }));
  };

  const handleMoveSection = () => {
    setActive((prev) => ({
      ...prev,
      sorting: "pages",
    }));
    setShow((prev) => ({
      ...prev,
      moreOptions: false,
    }));
  };

  const handleDeleteSection = () => {
    setFormState((prevState) => {
      let newForm = { ...prevState };
      newForm.pages.splice(index, 1);

      // this will prevent if user has set the based on page and then delete the page
      if (newForm.pages?.length === 2) {
        newForm.pages[0].goToNextPage = "next";
        newForm.pages[0].goToPrevPage = "prev";
      }

      return newForm;
    });
    setShow((prev) => ({
      ...prev,
      moreOptions: false,
    }));
  };

  const handleMergeWithAbove = () => {
    setFormState((prevState) => {
      let newForm = { ...prevState };
      let abovePage = newForm.pages[index - 1];
      let belowPage = newForm.pages[index];
      abovePage.questions = [...abovePage.questions, ...belowPage.questions];
      newForm.pages[index - 1] = abovePage;
      newForm.pages.splice(index, 1);

      // this will prevent if user has set the based on page and then merge the page
      if (newForm.pages?.length === 2) {
        newForm.pages[0].goToNextPage = "next";
        newForm.pages[0].goToPrevPage = "prev";
      }

      return newForm;
    });
    setShow((prev) => ({
      ...prev,
      moreOptions: false,
    }));
  };

  return (
    <div
      style={{
        marginTop: "48px",
        position: "absolute",
        top: 0,
        right: "-80px",
        boxShadow:
          "0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122)",
      }}
      className="py-2 w-[208px] bg-white rounded z-50"
    >
      <div
        onClick={() => handleDuplicateSection()}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-base">Duplicate section</span>
        </div>
      </div>
      <div
        onClick={() => handleMoveSection()}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-base">Move section</span>
        </div>
      </div>
      <div
        onClick={() => handleDeleteSection()}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-base">Delete section</span>
        </div>
      </div>
      <div
        onClick={() => handleMergeWithAbove()}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-base">Merge with above</span>
        </div>
      </div>
    </div>
  );
};

export default SectionOptions;
