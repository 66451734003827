// @ts-nocheck
import { Fragment, useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";
import { BsSend, BsThreeDotsVertical } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
import { LiaEditSolid, LiaSignatureSolid } from "react-icons/lia";
import {
  MdClose,
  MdDownload,
  MdDriveFileRenameOutline,
  MdFolder,
  MdFolderOpen,
  MdFormatListBulleted,
  MdKeyboardArrowRight,
  MdOutlineDelete,
  MdOutlineDriveFileMoveRtl,
  MdOutlineFolderDelete,
  MdOutlineHistory,
  MdOutlineMessage,
  MdOutlinePictureAsPdf,
  MdOutlineSimCardDownload,
  MdPeopleAlt,
} from "react-icons/md";
import { BASE_URL } from "../../constants/constants";
import { Slide, ToastContainer, toast } from "react-toastify";
import CreateFolderModal from "../../components/CreateFolderModal";
import MoveItemModal from "../../components/MoveItemModal";
import DeleteFolderModal from "../../components/DeleteFolderModal";
import { IoMdArrowRoundBack } from "react-icons/io";
import RenameItemModal from "../../components/RenameFolderModal";
import { RiSendPlaneLine } from "react-icons/ri";
import { IoCopyOutline, IoLinkOutline } from "react-icons/io5";
import { PiCertificate, PiFilePdfLight } from "react-icons/pi";
import { HiOutlineDocumentRemove } from "react-icons/hi";
import HistoryModal from "../../components/Ui/HistoryModal";
import { CiBoxList } from "react-icons/ci";
import { isFeatureEnabled } from "../../utils/paidFeat";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import Modal from "../../components/Modal";
import { Switch } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import useDrivePicker from "react-google-drive-picker";
import SigningOrderDiagram from "../../components/SigningOrderDiagram";
import { LuLayoutTemplate, LuListTree } from "react-icons/lu";
import RemoveFromFolder from "../../components/RemoveFromFolder";
import Papa from "papaparse";
import SendACopy from "../../components/Ui/SendACopy";
import CreateFileModal from "../../components/CreateFileModal";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import { TiCancel } from "react-icons/ti";
import { BLOCKING } from "../../constants/blockingConsants";
import { getWorkSpaceDetails } from "../../utils";
import ShareViaLink from "../../components/ShareViaLink";
import SigningInPerson from "../../components/SigningInPerson";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import PlanExaustedBannerEditor from "../../components/PlanExaustedModalEditor";
import GenerateLinksModal from "../../components/GenerateLinks";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { FaPlus } from "react-icons/fa";
import { CgTemplate } from "react-icons/cg";
import { TbPdf } from "react-icons/tb";
import {
  BoloIconForm,
  BoloIconPDFTemplate,
  BoloIconPDF,
} from "../../components/Common/Icons/BoloIcons";
import { GrDropbox } from "react-icons/gr";
import DropboxDriveModal from "../../components/DropboxDriveModal";
import OnedriveModalUpload from "../../components/OndeDriveModalUpload";
import DropdownActionsForms from "../../components/DropdownActions/DropdownActionsForms";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../components/Common/SelectDropDown/BoloDropDown";
import DropdownActionsSignature from "../../components/DropdownActions/DropdownActionsPdfs";
import DropdownActionsTemplate from "../../components/DropdownActions/DropdownActionsTemplates";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/Common/Dialog/BoloDialog";
import ShareWithTeammates from "../../components/ShareWithTeammates";
const Options = ({
  item,
  setActiveOption,
  setFolder,
  folder,
  setMoveItem,
  setMoveItemModal,
  setDeleteItemModal,
  setDeleteItem,
  optionFunctions,
  setOptionFunctions,
  stateUpdate,
  isSharedPage,
  setRemoveFromFolder,
  setRemoveItem,
  setUpgradeModal,
  isFolderEnabled,
  workSpaceDetails,
  dropdownState,
  updateDropdownState,
  isIntegrationPermissionAvailable,
}) => {
  const navigate = useNavigate();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;

  const handleRemoveItem = async () => {
    setRemoveFromFolder(true);
    setRemoveItem(item);
    setActiveOption(null);
  };
  const downloadPlainSignature = async (documentId) => {
    if (documentId) {
      try {
        const { data: documentData } = await axios.get(
          `/getOneDocument?documentId=${documentId}`
        );
        let documentDetails = documentData.document;
        let pdfUrl = documentDetails?.documentUrl;

        const responseFile = await fetch(pdfUrl);
        const pdfBuffer = await responseFile.arrayBuffer();

        // Convert PDF buffer to a base64 string
        let pdfBase64 = arrayBufferToBase64(pdfBuffer);
        pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;

        let schemas = documentDetails?.pdfTemplate?.schemas;

        let responseInputs = documentDetails?.pdfTemplate?.inputs;
        const urlToReplace =
          "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature";
        const updatedResponse = await Promise.all(
          responseInputs.map(async (obj: any) => {
            const updatedObj: any = {};
            for (const key in obj) {
              if (Object.hasOwnProperty.call(obj, key)) {
                const value = obj[key];

                if (
                  value ===
                    "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature-template/4vnRsfZ.png" ||
                  value === "https://i.imgur.com/AfyPZQg.png"
                ) {
                  updatedObj[key] = "";
                } else if (
                  typeof value === "string" &&
                  value.includes(urlToReplace)
                ) {
                  const responseFile = await fetch(value);
                  const imageBuffer = await responseFile.arrayBuffer();
                  const imageBase64 = arrayBufferToBase64(imageBuffer);
                  const fileExtension = value.split(".").pop();
                  console.log("detectedType", fileExtension);
                  updatedObj[key] =
                    `data:image/${fileExtension ?? "png"};base64,` +
                    imageBase64;
                } else {
                  updatedObj[key] = value;
                }
              }
            }
            return updatedObj;
          })
        );

        console.log("input responses ==>", updatedResponse);

        const inputs = updatedResponse;
        const template: Template = {
          schemas: schemas,
          basePdf: pdfBase64,
        };

        let downloadbledPdf = await generate({ template, inputs });

        const pdfArray = Uint8Array.from(downloadbledPdf);
        const pdfBlob = new Blob([pdfArray], {
          type: "application/pdf",
        });

        download(
          pdfBlob,
          `${documentDetails?.documentName}_${documentId.toString()}.pdf`,
          "application/pdf"
        );
      } catch (error) {
        console.error("An error occurred:", error);
        toast.error(error.message);
        // Handle error, show error message to the user, etc.
      }
    }
  };
  const handleDeleteFolder = () => {
    setDeleteItemModal(true);
    setDeleteItem(item);
  };

  return (
    <div className="cursor-pointer relative">
      <div className="flex items-center">
        <div className="hidden md:flex gap-4 items-center w-[200px] h-9">
          <button
            data-tooltip-id="hover-btn-move"
            data-tooltip-offset={10}
            className="flex rounded-full transition-all duration-200 h-0 w-0 group-hover:h-9 group-hover:w-9 items-center justify-center text-gray-600 hover:text-black"
            id="hover-btn-move"
            disabled={
              !workSpaceDetails?.isWorkSpaceOwner &&
              workSpaceDetails?.currentWorkSpaceRole === "readonly"
            }
            onClick={(e) => {
              e.stopPropagation();
              new MixPanel().track("Folder", {
                folder_action: "folder_item_move",
                paid: false,
              });
              new MixPanel().increment("folder_item_move");
              if (!isFolderEnabled) {
                setUpgradeModal({
                  show: true,
                  data: {
                    title: BLOCKING.FOLDER.title,
                    desc: BLOCKING.FOLDER.desc,
                    redirect: BLOCKING.FOLDER.redirect,
                    videoSrc: "",
                    redirectText: BLOCKING.FOLDER.redirectText,
                  },
                });
                return;
              }
              setMoveItem(item);
              setMoveItemModal(true);
            }}
          >
            <MdOutlineDriveFileMoveRtl className="text-xl hover:opacity-85" />
            <CustomTooltip
              helpers={{
                id: "hover-btn-move",
                content: "Move Item",
                place: "top",
              }}
            />
          </button>
          {item?.type !== "FOLDER" && (
            <button
              data-tooltip-id="hover-btn-file-remove"
              data-tooltip-offset={10}
              className="flex rounded-full transition-all duration-200 h-0 w-0 group-hover:h-9 group-hover:w-9 items-center justify-center text-gray-600 hover:text-black"
              disabled={
                !workSpaceDetails?.isWorkSpaceOwner &&
                workSpaceDetails?.currentWorkSpaceRole === "readonly"
              }
              onClick={(e) => {
                e.stopPropagation();
                new MixPanel().track("Folder", {
                  folder_action: "folder_item_remove",
                  paid: false,
                });
                new MixPanel().increment("folder_item_remove");
                if (!isFolderEnabled) {
                  setUpgradeModal({
                    show: true,
                    data: {
                      title: BLOCKING.FOLDER.title,
                      desc: BLOCKING.FOLDER.desc,
                      redirect: BLOCKING.FOLDER.redirect,
                      videoSrc: "",
                      redirectText: BLOCKING.FOLDER.redirectText,
                    },
                  });
                  return;
                }
                handleRemoveItem();
              }}
            >
              <MdOutlineFolderDelete className="text-xl hover:opacity-85" />
              <CustomTooltip
                helpers={{
                  id: "hover-btn-file-remove",
                  content: "Remove from Folder",
                  place: "top",
                }}
              />
            </button>
          )}
          {item?.type == "FOLDER" && (
            <>
              <button
                data-tooltip-id="hover-btn-folder-rename"
                data-tooltip-offset={10}
                className="flex rounded-full transition-all duration-200 h-0 w-0 group-hover:h-9 group-hover:w-9 items-center justify-center text-gray-600 hover:text-black"
                disabled={
                  !workSpaceDetails?.isWorkSpaceOwner &&
                  workSpaceDetails?.currentWorkSpaceRole === "readonly"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  new MixPanel().track("Folder", {
                    folder_action: "folder_rename",
                    paid: false,
                  });
                  new MixPanel().increment("folder_remane");
                  if (!isFolderEnabled) {
                    setUpgradeModal({
                      show: true,
                      data: {
                        title: BLOCKING.FOLDER.title,
                        desc: BLOCKING.FOLDER.desc,
                        redirect: BLOCKING.FOLDER.redirect,
                        videoSrc: "",
                        redirectText: BLOCKING.FOLDER.redirectText,
                      },
                    });
                    return;
                  }
                  setOptionFunctions((prev) => ({
                    ...prev,
                    rename: { ...prev.rename, show: true, item: item },
                  }));
                }}
              >
                <MdDriveFileRenameOutline className="text-xl hover:opacity-85" />
                <CustomTooltip
                  helpers={{
                    id: "hover-btn-folder-rename",
                    content: "Rename Folder / Change Color",
                    place: "top",
                  }}
                />
              </button>
              <button
                data-tooltip-id="hover-btn-folder-delete"
                data-tooltip-offset={10}
                className="flex rounded-full transition-all duration-200 h-0 w-0 group-hover:h-9 group-hover:w-9 items-center justify-center text-gray-600 hover:text-black"
                disabled={
                  !workSpaceDetails?.isWorkSpaceOwner &&
                  (workSpaceDetails?.currentWorkSpaceRole === "readonly" ||
                    workSpaceDetails?.currentWorkSpaceRole === "editor")
                }
                onClick={(e) => {
                  e.stopPropagation();
                  new MixPanel().track("Folder", {
                    folder_action: "folder_delete",
                    paid: false,
                  });
                  new MixPanel().increment("folder_delete");
                  if (!isFolderEnabled) {
                    setUpgradeModal({
                      show: true,
                      data: {
                        title: BLOCKING.FOLDER.title,
                        desc: BLOCKING.FOLDER.desc,
                        redirect: BLOCKING.FOLDER.redirect,
                        videoSrc: "",
                        redirectText: BLOCKING.FOLDER.redirectText,
                      },
                    });
                    return;
                  }
                  handleDeleteFolder(item?.value?._id);
                }}
              >
                <MdOutlineDelete className="text-xl hover:opacity-85" />
                <CustomTooltip
                  helpers={{
                    id: "hover-btn-folder-delete",
                    content: "Delete Folder",
                    place: "top",
                  }}
                />
              </button>
            </>
          )}
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <span className={`m-auto hover:text-gray-800 text-gray-600`}>
              <BsThreeDotsVertical className="text-lg" />
            </span>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            sideOffset={5}
            className="p-0 w-52 max-h-60 overflow-y-auto action-dropdown"
          >
            {/*Options of Move and Remove from Folder for small screen */}
            {item?.type !== "FOLDER" && (
              <DropdownMenuItem
                className={`flex md:hidden flex-row items-center text-center text-xs capitalize text-gray-700 px-3 py-2 gap-2 w-full `}
                disabled={
                  !workSpaceDetails?.isWorkSpaceOwner &&
                  workSpaceDetails?.currentWorkSpaceRole === "readonly"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  new MixPanel().track("Folder", {
                    folder_action: "folder_item_move",
                    paid: false,
                  });
                  new MixPanel().increment("folder_item_move");
                  setMoveItem(item);
                  setMoveItemModal(true);
                  setActiveOption(null);
                }}
              >
                <MdOutlineDriveFileMoveRtl className="text-xl" />
                Move
              </DropdownMenuItem>
            )}
            {item?.type !== "FOLDER" && (
              <DropdownMenuItem
                className={`flex md:hidden flex-row items-center text-center text-xs capitalize text-gray-700 px-3 py-2 gap-2 w-full `}
                disabled={
                  !workSpaceDetails?.isWorkSpaceOwner &&
                  workSpaceDetails?.currentWorkSpaceRole === "readonly"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  new MixPanel().track("Folder", {
                    folder_action: "folder_item_remove",
                    paid: false,
                  });
                  new MixPanel().increment("folder_item_remove");
                  handleRemoveItem();
                }}
              >
                <MdOutlineFolderDelete className="text-xl" />
                Remove from Folder
              </DropdownMenuItem>
            )}
            {/* Folder Dropdown */}
            {item?.type == "FOLDER" && (
              <>
                <DropdownMenuItem
                  className={`flex flex-row items-center text-center text-xs capitalize text-gray-700 px-3 py-2 gap-2 w-full`}
                  disabled={
                    !workSpaceDetails?.isWorkSpaceOwner &&
                    workSpaceDetails?.currentWorkSpaceRole === "readonly"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    new MixPanel().track("Folder", {
                      folder_action: "folder_move",
                      paid: false,
                    });
                    new MixPanel().increment("folder_move");
                    setMoveItem(item);
                    setMoveItemModal(true);
                    setActiveOption(null);
                  }}
                >
                  <MdOutlineDriveFileMoveRtl className="text-xl" />
                  Move
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={`flex flex-row items-center text-center text-xs capitalize text-gray-700 px-3 py-2 gap-2 w-full `}
                  disabled={
                    !workSpaceDetails?.isWorkSpaceOwner &&
                    workSpaceDetails?.currentWorkSpaceRole === "readonly"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    new MixPanel().track("Folder", {
                      folder_action: "folder_rename",
                      paid: false,
                    });
                    new MixPanel().increment("folder_rename");
                    setOptionFunctions((prev) => ({
                      ...prev,
                      rename: { ...prev.rename, show: true, item: item },
                    }));
                    setActiveOption(null);
                  }}
                >
                  <MdDriveFileRenameOutline className="text-xl" />
                  Rename
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={`flex flex-row items-center text-center text-xs capitalize px-3 py-2 gap-2 w-full hover:!bg-red-100 text-red-400 hover:!text-red-400`}
                  disabled={
                    !workSpaceDetails?.isWorkSpaceOwner &&
                    (workSpaceDetails?.currentWorkSpaceRole === "readonly" ||
                      workSpaceDetails?.currentWorkSpaceRole === "editor")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    new MixPanel().track("Folder", {
                      folder_action: "folder_delete",
                      paid: false,
                    });
                    new MixPanel().increment("folder_delete");
                    handleDeleteFolder(item?.value?._id);
                    setActiveOption(null);
                  }}
                >
                  <MdOutlineDelete className="text-xl" />
                  Delete Folder
                </DropdownMenuItem>
              </>
            )}
            {item?.type == "FORM" && (
              <DropdownActionsForms
                form={item?.value}
                dropdownState={dropdownState}
                updateDropdownState={updateDropdownState}
                type="FOLDER"
                folderId={folder?.folderId}
              />
            )}
            {item?.type == "PDF" && (
              <DropdownActionsSignature
                owner={owner}
                dropdownState={dropdownState}
                updateDropdownState={updateDropdownState}
                isIntegrationPermissionAvailable={
                  isIntegrationPermissionAvailable
                }
                document={item?.value}
                type="FOLDER"
                folderId={folder?.folderId}
              />
            )}
            {item?.type == "PDF-TEMPLATE" && (
              <DropdownActionsTemplate
                owner={owner}
                document={item?.value}
                dropdownState={dropdownState}
                updateDropdownState={updateDropdownState}
                isIntegrationPermissionAvailable={
                  isIntegrationPermissionAvailable
                }
                type="FOLDER"
                folderId={folder?.folderId}
              />
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
};

const FolderItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  const [dropdownState, setDropdownState] = useState({
    reload: false,
    showUpgradeModal: false,
    upgradeModalType: "",
    showBulkUploadModal: { state: false, templateId: "" },
    deletingForm: null,
    isSharedDashboard: false,
    workSpaceDetails: getWorkSpaceDetails(),
    isQuotaExausted: false,
    loading: false,
    isModalOpen: false,
    documentHistory: [],
    documentForHistory: [],
    isSendACopyModalOpen: false,
    documentForSendACopy: {
      documentName: "",
      documentUrl: "",
      documentType: "",
    },
    isSigningOrderDiagram: false,
    signingOrderRecipientList: [],
    currOrderDocument: null,
    showGoogleDriveModal: false,
    driveModalDoc: {},
    deletingResponse: null,
    show: {
      formMoreOptions: {
        active: false,
        id: null,
      },
    },
    showDeletingResponse: {
      formMoreOptions: {
        active: false,
        id: null,
      },
    },
    showShareViaLink: false,
    showSigningInPerson: false,
    generatedLinksData: [],
    showGenerateLinksModal: false,
    showDropboxDriveModal: false,
    dropboxDoc: {
      documentId: "",
      enabled: false,
      parentFolderName: "",
      parentFolderUrl: "",
      parentFolderId: "",
    },
    showOneDriveModal: false,
    oneDriveDoc: {
      documentId: "",
      enabled: false,
      parentFolderName: "",
      parentFolderUrl: "",
      parentFolderId: "",
      parentFolderDriveId: "",
    },
    activeForm: null,
    activeDoc: null,
    showShare: false,
    showShareDocType: "",
  });
  const updateDropdownState = (key, value) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [isDriveParentFolderId, setIsDriveParentFolderId] = useState(false);
  const [openPicker, authResponse] = useDrivePicker();
  const [driveModalDocStatus, setDriveModalDocStatus] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [folder, setFolder] = useState({});
  const [paths, setPaths] = useState([]);
  const [activeOption, setActiveOption] = useState("");
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const [createFileModal, setCreateFileModal] = useState(false);
  const [moveItemModal, setMoveItemModal] = useState(false);
  const [moveItem, setMoveItem] = useState({});
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [udpateDone, setUpdateDone] = useState(false);
  const [whichAdmin, setWhichAdmin] = useState("");
  const [isSharedPage, setIsSharedPage] = useState(
    window.location.pathname?.includes("shared-with-me")
  );
  const [csvData, setCsvData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [activeForSendModal, setActiveForSendModal] = useState("email");
  const [emailForSendModal, setEmailForSendModal] = useState({
    to: "",
    subject: "I am sharing a signed pdf with you",
    message: "Consider this response document you asked for..",
  });
  const [removeItem, setRemoveItem] = useState({});
  const [removeFromFolder, setRemoveFromFolder] = useState(false);
  const [isFolderEnabled, setIsFolderEnabled] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState({
    show: false,
    data: { title: "", desc: "", videoSrc: "", redirect: "" },
  });
  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );
  // Using sigular large objects would be ideal convert the above states as properties later
  const [optionFunctions, setOptionFunctions] = useState({
    rename: { item: {}, show: false },
  });

  const getItemsInFolder = async () => {
    try {
      const { data } = await axios.post("/folder/items", {
        folderId: searchParams.get("folderId"),
      });
      if (data) {
        setFolder(data?.folder || {});
        setPaths(data?.paths || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function isIntegrationPermissionAvailable() {
    const { data: permissionDetails } = await axios.post(
      `/forms/check-google-permission`,
      {
        ownerEmail: owner?.email,
        isFormDashboard: true,
      }
    );
    return permissionDetails?.isDrivePermission;
  }
  const PurpleSwitch = styled(Switch)(({}) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
      "&:hover": {
        backgroundColor: alpha(
          whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
          0
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
    },
  }));
  const handleGooglePicker = async (documentId) => {
    let isSufficientPermission = await isIntegrationPermissionAvailable();

    if (!isSufficientPermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/dashboard/pdfs`,
        "_self"
      );
      return;
    }

    let clientId, developerKey;

    try {
      const { data } = await axios.get(
        `${BASE_URL}/common/get-google-credentials`
      );
      clientId = data?.clientId;
      developerKey = data?.developerKey;
    } catch (error) {
      console.error(
        "Error fetching client ID and developer key from the backend",
        error
      );
    }

    if (clientId && developerKey) {
      openPicker({
        clientId,
        developerKey,
        viewId: "FOLDERS",
        token: owner?.integrationTokens?.google?.oAuthToken,
        setIncludeFolders: true,
        setSelectFolderEnabled: true,
        showUploadView: false,
        supportDrives: true,
        multiselect: false,
        appId: 204560571245,
        callbackFunction: async (driveData) => {
          if (driveData.action === "cancel") {
            console.log("User clicked cancel/close button");
          }

          if (driveData?.docs) {
            try {
              let res = await axios.put(
                `${BASE_URL}/common/update-drive-folder`,
                {
                  documentId,
                  parentFolderId: driveData?.docs[0]?.id,
                  parentFolderName: driveData?.docs[0]?.name,
                  parentFolderUrl: driveData?.docs[0]?.url,
                  enabled: true,
                  documentType: "DOCUMENT",
                }
              );
              toast.success("Folder Updated");
              setIsDriveParentFolderId(true);
              setDriveModalDocStatus(true);
              updateDropdownState("driveModalDoc", {
                ...dropdownState.driveModalDoc,
                parentFolderId: driveData?.docs[0]?.id,
                parentFolderName: driveData?.docs[0]?.name,
                parentFolderUrl: driveData?.docs[0]?.url,
              });
            } catch (error) {
              console.log("error", error);
              toast.error("Something went wrong");
            }
          }
        },
      });
    }
  };
  const updateIntegrationStatus = async (
    status,
    integrationName,
    documentId,
    parentFolderId,
    parentFolderName,
    parentFolderUrl
  ) => {
    try {
      let res = await axios.put(`/common/update-drive-folder`, {
        documentId,
        enabled: status,
        parentFolderId,
        parentFolderName,
        parentFolderUrl,
        documentType: "DOCUMENT",
      });
      toast.success("Updated");
      return true;
    } catch (error) {
      console.log("updateIntegrationStatus error", error);
      return false;
    }
  };
  const GoogleDriveModal = () => {
    let isFreePlan =
      owner?.planhistory[owner?.planhistory.length - 1]?.planId === "FREE_PLAN";
    return (
      <div className="md:w-[600px] py-2 md:py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            updateDropdownState("showGoogleDriveModal", false);
          }}
          className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <h2 className="mb-1 text-lg md:text-xl font-semibold">
          Google Drive Integration
        </h2>
        <p className="text-xs text-gray-600 pb-2 mb-6 border-b-2 border-gray-200">
          Select drive folder to save the response pdf files to your Google
          Drive.
        </p>

        {!dropdownState.driveModalDoc?.isDrivePermission && !isFreePlan && (
          <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
            <h2 className="mb-1 text-xs md:text-sm">
              You are not connected to Google Drive
            </h2>
            <p className="text-xs text-gray-500 mb-4">
              Connect your Google Drive to save the finished PDF to your
              specified Google Drive folder.
            </p>
            <button
              className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
              onClick={() => {
                window.open(
                  `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/dashboard/templates`,
                  "_self"
                );
              }}
            >
              Connect to Google Drive
            </button>
          </div>
        )}
        {dropdownState.driveModalDoc?.isDrivePermission && !isFreePlan && (
          <div className="flex justify-between">
            {!dropdownState.driveModalDoc?.parentFolderId ? (
              <div>
                <h2 className="mb-1 text-xs md:text-smfont-medium text-gray-700">
                  Your document is not connected to any Google Drive Folder
                </h2>
                <p className="text-xs text-gray-500 mb-4">
                  Pick a folder to save your form responses as PDFs.
                </p>
                <button
                  className="w-[50%] md:w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                  onClick={async () => {
                    await handleGooglePicker(
                      dropdownState.driveModalDoc?.document?.documentId
                    );
                  }}
                >
                  Select Folder
                </button>
              </div>
            ) : (
              <div>
                <h2 className="mb-1 text-xs md:text-sm font-medium text-gray-700">
                  Your document is connected to Google Drive
                </h2>
                <p className="text-xs text-gray-500 mb-4">
                  Your document responses will be saved to this Drive Folder:{" "}
                  <span
                    className="text-primary font-semibold cursor-pointer"
                    onClick={() => {
                      window.open(
                        dropdownState.driveModalDoc?.parentFolderUrl,
                        "_blank"
                      );
                    }}
                  >
                    {dropdownState.driveModalDoc?.parentFolderName}
                  </span>
                </p>
                <div className="w-full flex justify-between gap-4">
                  <button
                    disabled={isFreePlan}
                    className="w-full border-2 border-primary bg-white inset-2 text-primary px-4 py-2 rounded-md text-xs mr-1"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await handleGooglePicker(
                        dropdownState.driveModalDoc?.document?.documentId
                      );
                    }}
                  >
                    Update Folder
                  </button>
                  <button
                    className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        dropdownState.driveModalDoc?.parentFolderUrl,
                        "_blank"
                      );
                    }}
                  >
                    View Folder
                  </button>
                </div>
              </div>
            )}
            <PurpleSwitch
              checked={dropdownState.driveModalDocStatus}
              onChange={async (e) => {
                await updateIntegrationStatus(
                  e.target.checked,
                  "googleDrive",
                  dropdownState.driveModalDoc?.document?.documentId,
                  dropdownState.driveModalDoc?.parentFolderId,
                  dropdownState.driveModalDoc?.parentFolderName,
                  dropdownState.driveModalDoc?.parentFolderUrl
                );
                updateDropdownState(
                  "driveModalDocStatus",
                  !dropdownState.driveModalDocStatus
                );
              }}
            />
          </div>
        )}
      </div>
    );
  };
  // Not opening the responses in forms,pdf and pdf-template type item inside folder when user click on the list item
  const listClickHandler = (item) => {
    switch (item?.type) {
      case "FOLDER":
        if (isSharedPage) {
          navigate(
            `/shared-with-me/folder/items?folderId=${item?.value?.folderId}&whichAdmin=${whichAdmin}`
          );
        } else {
          navigate(`/folder/items?folderId=${item?.value?.folderId}`);
        }
        return;
      case "FORM":
        return;
      case "PDF":
        return;
      case "PDF-TEMPLATE":
        return;
    }
  };

  const bulkUploadModalContent = (templateId) => {
    return (
      <div className="w-[100%] md:w-[600px] py-2 md:py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            setCsvFile(null);
            setCsvData([]);
            updateDropdownState("showBulkUploadModal", {
              ...dropdownState.showBulkUploadModal,
              state: !dropdownState.showBulkUploadModal.state,
            });
          }}
          className="absolute md:top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <h2 className="mb-1 text-lg md:text-2xl font-semibold">Bulk Submit</h2>
        <p className="text-xs md:text-sm text-gray-600 mb-6">
          Send this form to multiple people for signing
        </p>
        <div className="flex flex-col">
          <p className="text-sm md:text-lg w-fit uppercase font-semibold rounded-md flex items-center justify-center">
            Step 1
          </p>
          <p className="text-xs md:text-sm text-gray-600 mt-1 mb-4">
            Download the csv template and fill the details
          </p>
          <div>
            {/* <h3 className="text-md font-semibold mb-1">
              Download Sheet Template
            </h3> */}
            <button
              onClick={() => {
                const csvData = ["name", "email", "subject", "message"];

                // Create a Blob containing the CSV data
                const blob = new Blob([csvData], { type: "text/csv" });

                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(blob);

                // Create a link and trigger a click to download the file
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `bulk-template-${templateId}.csv`;
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              }}
              // className="px-4 py-1 bg-primary text-sm text-white rounded-md hover:opacity-90 mr-4"
              className="w-fit text-xs md:text-sm bg-secondary text-primary font-medium rounded-md px-4 py-1.5 flex items-center justify-center"
            >
              Download
            </button>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <p className="text-sm md:text-lg w-fit uppercase font-semibold rounded-md flex items-center justify-center">
            Step 2
          </p>
          <p className="text-xs md:text-sm text-gray-600 mt-1 mb-4">
            Upload the filled csv file
          </p>
          <div>
            <div className="flex items-center">
              <label
                htmlFor="csv-upload"
                onClick={() => {}}
                className="whitespace-nowrap w-fit cursor-pointer text-xs md:text-sm bg-secondary text-primary font-medium rounded-md px-4 py-1.5 flex items-center justify-center"
              >
                Choose File
              </label>
              <p className="break-all ml-2 text-xs md:text-sm text-gray-700">
                {csvFile ? csvFile?.name : "No file chosen"}
              </p>
            </div>
            <input
              className="py-2 px-4 w-full outline-none text-gray-700 rounded-md border disabled:opacity-50 text-sm hidden"
              id="csv-upload"
              type="file"
              accept=".csv, .xlsx"
              onChange={(e) => {
                const file = e.target.files[0];

                // Read the file and parse it
                setCsvFile(file);
                const reader = new FileReader();
                reader.onload = (event) => {
                  const result = event.target.result;
                  // Parse the CSV data
                  Papa.parse(result, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    complete: (parsedData) => {
                      setCsvData(parsedData.data);
                    },
                    error: (error) => {
                      console.error("CSV parsing error:", error.message);
                    },
                  });
                };
                reader.readAsText(file);
              }}
            />
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={() => {
              setCsvFile(null);
              setCsvData([]);
              updateDropdownState("showBulkUploadModal", {
                ...dropdownState.showBulkUploadModal,
                state: !dropdownState.showBulkUploadModal.state,
              });
            }}
            // className="px-6 py-2 mt-4 border-2 inset-2 border-red-400 text-sm text-[#dd2624] rounded-lg hover:opacity-90 mr-4"
            className="px-6 py-2 mt-4 text-primary text-xs md:text-sm bg-secondary rounded-lg hover:opacity-90 mr-4 disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={() => initiateBulkSubmit(templateId)}
            disabled={!csvFile || !csvData || csvData.length === 0}
            className="px-6 py-2 mt-4 bg-primary text-xs md:text-sm text-white rounded-lg hover:opacity-90 md:mr-4 disabled:opacity-50"
          >
            Initiate Bulk Submit
          </button>
        </div>
      </div>
    );
  };

  const initiateBulkSubmit = async (templateId) => {
    try {
      const owner = JSON.parse(localStorage.getItem("owner"));

      if (!owner) {
        console.log("Owner data not found in local storage.");
        return;
      }

      const activePlan = owner.planhistory.find(
        (plan) => plan.isActive === true
      );
      const emailData = [];
      let reachedMaxQuantity = false;
      for (const currentRow of csvData) {
        const email = currentRow.email;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (emailRegex.test(email)) {
          if (activePlan.usedQuanity[2] < activePlan.usageQuantityAllowed[2]) {
            emailData.push({
              ...currentRow,
            });
            activePlan.usedQuanity[2]++; // Increment the usedQuanity
          } else {
            reachedMaxQuantity = true;
            break; // Stop processing if quota is reached
          }
        } else {
          console.log(`Skipping row: Invalid email format - ${email}`);
        }
      }
      if (emailData.length === 0) {
        console.log("No valid email addresses found.");
        toast.error("No valid email addresses found.");
        return;
      }
      const res = await axios.post(`/forms/bulk-request`, {
        receiversList: emailData,
        documentId: templateId,
        ownerId: owner._id,
      });
      if (res.status === 200) {
        if (reachedMaxQuantity) {
          toast.warning(
            "You reached the maximum allowed quantity but some emails were sent successfully."
          );
        } else {
          toast.success("Email(s) sent successfully");
        }
        updateDropdownState("showBulkUploadModal", {
          ...dropdownState.showBulkUploadModal,
          state: !dropdownState.showBulkUploadModal.state,
        });
      } else {
        toast.error("Bulk request failed!");
      }
    } catch (error) {
      console.error("Error during bulk submit:", error);
    }
  };
  const deleteForm = async (formId) => {
    try {
      updateDropdownState("isDeleting", true);
      const { data } = await axios.post(`/forms/deleteForm?formId=${formId}`);
      if (!data.error) {
        setUpdateDone(!udpateDone);
        updateDropdownState("deletingForm", null);
        updateDropdownState("isDeleting", false);
        // return toast.info("The form was successfully deleted");
        return;
      } else {
        updateDropdownState("deletingForm", null);
        updateDropdownState("isDeleting", false);
        return toast.error(data.error);
      }
    } catch (error) {
      updateDropdownState("deletingForm", null);
      updateDropdownState("isDeleting", false);
      console.log("Form deletion: ", error);
      return toast.error("Failed to delete the form");
    }
  };

  const handleOneResponseDelete = async () => {
    updateDropdownState("showDeletingResponse", {
      ...dropdownState.show,
      oneDeleting: true,
    });
    let id = dropdownState.deletingResponse._id;

    try {
      let { data } = await axios.post(
        `/respondents/delete-pdfdocument-response`,
        { id }
      );
      if (data?.success) {
        setUpdateDone(!udpateDone);
      }
    } catch (error) {
      console.log(error);
    }
    updateDropdownState("showDeletingResponse", {
      ...dropdownState.showDeletingResponse,
      deleteOneResponseModal: false,
      oneDeleting: false,
    });
  };
  const handleVoidDocumentDelete = async () => {
    updateDropdownState("showDeletingResponse", {
      ...dropdownState.showDeletingResponse,
      oneDeleting: true,
    });

    let id = dropdownState.deletingResponse._id;

    try {
      let { data } = await axios.post(
        `/respondents/void-pdfdocument-response`,
        {
          id,
        }
      );
      if (data?.success) {
        setIsDeleted(id);
      }
      setUpdateDone(!udpateDone);
    } catch (error) {
      console.log(error);
    }
    updateDropdownState("showDeletingResponse", {
      ...dropdownState.showDeletingResponse,
      voidDocumentModal: false,
      oneDeleting: false,
    });
  };
  const checkIfFolderEnabled = async () => {
    // ----------------------- make sure to discuss for shared with
    const isFolder = await isFeatureEnabled("FOLDER_ORG");
    // now the shared cannot access folders if they dont have folder access
    setIsFolderEnabled(isFolder);
  };

  useEffect(() => {
    getItemsInFolder();
    checkIfFolderEnabled();
  }, [searchParams.get("folderId"), udpateDone, dropdownState.reload]);

  useEffect(() => {
    owner?.addedBy && setWhichAdmin(owner?.addedBy?.[0]?.ownerId?.email);
  }, []);

  useEffect(() => {
    if (location.pathname.includes("shared-with-me")) {
      setIsSharedPage(true);
    } else {
      setIsSharedPage(false);
    }
  }, [location]);

  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      setWorkSpaceDetails(localWorkSpaceDetails);
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  return (
    <div className="-m-4">
      <Helmet>
        <title translate="no">Folder Items</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Folder Items" />
        <meta
          name="description"
          content="Explore and manage items within your folders efficiently on BoloForms."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/folder/items`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Folder Items`} />
        <meta
          property="og:description"
          content="Explore and manage items within your folders efficiently on BoloForms."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/folder/items"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="px-3 md:px-4 md:h-[40px] my-4 sm:my-6 text-gray-600 w-full flex items-center justify-end md:justify-between gap-2 flex-wrap md:flex-nowrap">
        {upgradeModal.show && (
          <PleaseUpgradeModal
            setShow={(value) => {
              setUpgradeModal((prev) => ({ ...prev, show: value }));
            }}
            show={upgradeModal.show}
            data={upgradeModal.data}
          />
        )}
        {moveItemModal && (
          <MoveItemModal
            item={moveItem}
            fromFolder={folder}
            setShow={setMoveItemModal}
            stateUpdate={setUpdateDone}
          />
        )}
        {deleteItemModal && (
          <DeleteFolderModal
            item={deleteItem}
            setShow={setDeleteItemModal}
            stateUpdate={setUpdateDone}
          />
        )}
        {optionFunctions.rename.show && (
          <RenameItemModal
            setShow={(value) => {
              setOptionFunctions((prev) => ({
                ...prev,
                rename: { ...prev.rename, show: value },
              }));
            }}
            item={optionFunctions.rename.item}
            stateUpdate={setUpdateDone}
          />
        )}
        {dropdownState.showBulkUploadModal.state && (
          <Modal
            modalContent={bulkUploadModalContent(
              dropdownState.showBulkUploadModal.templateId
            )}
          />
        )}
        {dropdownState.isModalOpen && (
          <HistoryModal
            setIsModalOpen={(value) => {
              updateDropdownState("isModalOpen", value);
            }}
            history={dropdownState.documentHistory}
            documentDetails={dropdownState.documentForHistory}
          />
        )}
        {dropdownState.isSendACopyModalOpen &&
          dropdownState.documentForSendACopy?.documentUrl && (
            <Modal
              modalContent={SendACopy({
                documentId: dropdownState.documentForSendACopy?.documentId,
                documentName: dropdownState.documentForSendACopy?.documentName,
                documentUrl: dropdownState.documentForSendACopy?.documentUrl,
                documentType: dropdownState.documentForSendACopy?.documentType,
                setting: dropdownState.documentForSendACopy?.setting,
                setOpen: (value) => {
                  updateDropdownState("isSendACopyModalOpen", value);
                },
                active: activeForSendModal,
                setActive: setActiveForSendModal,
                email: emailForSendModal,
                setEmail: setEmailForSendModal,
              })}
            />
          )}
        {removeFromFolder && (
          <RemoveFromFolder
            item={removeItem}
            stateUpdate={setUpdateDone}
            setShow={setRemoveFromFolder}
          />
        )}
        {dropdownState.deletingForm && (
          <Dialog
            open={dropdownState.deletingForm?.formId}
            onOpenChange={(value) => {
              updateDropdownState("deletingForm", null);
              updateDropdownState("isDeleting", value);
            }}
          >
            <DialogContent className="">
              <DialogHeader>
                <DialogTitle>Remove Form</DialogTitle>
              </DialogHeader>
              <div>
                <h2 className="text-sm md:text-base font-medium md:leading-[2] leading-[2]">
                  Are you sure you want to delete this form?
                </h2>
                <p className="text-[10px] md:text-xs text-gray-600">
                  The form{" "}
                  <span className="font-semibold">
                    {dropdownState.deletingForm?.formJson?.title}
                  </span>{" "}
                  will be deleted.
                </p>
              </div>

              <DialogFooter>
                <BoloButton
                  variant={"danger-border"}
                  size={"sm"}
                  onClick={async () => {
                    await deleteForm(dropdownState.deletingForm?.formId);
                  }}
                  disabled={dropdownState.isDeleting}
                >
                  {dropdownState.isDeleting ? "Deleting..." : "Delete"}
                </BoloButton>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        )}
        <Dialog
          open={dropdownState.showDeletingResponse.deleteOneResponseModal}
          onOpenChange={(value) => {
            updateDropdownState("showDeletingResponse", {
              ...dropdownState.showDeletingResponse,
              deleteOneResponseModal: value,
            });
          }}
        >
          <DialogContent className="">
            <DialogHeader>
              <DialogTitle>Remove Document</DialogTitle>
            </DialogHeader>
            <div>
              <h2 className="text-sm md:text-base font-medium md:leading-[2] leading-[2]">
                Are you sure you want to delete this document?
              </h2>
              <p className="text-[10px] md:text-xs text-gray-600">
                This document will be deleted.
              </p>
            </div>

            <DialogFooter>
              <BoloButton
                variant={"danger-border"}
                size={"sm"}
                onClick={handleOneResponseDelete}
                disabled={dropdownState.showDeletingResponse.oneDeleting}
              >
                {dropdownState.showDeletingResponse.oneDeleting
                  ? "Deleting..."
                  : "Delete"}
              </BoloButton>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <Dialog
          open={dropdownState.showDeletingResponse.voidDocumentModal}
          onOpenChange={(value) => {
            updateDropdownState("showDeletingResponse", {
              ...dropdownState.showDeletingResponse,
              voidDocumentModal: value,
            });
          }}
        >
          <DialogContent className="">
            <DialogHeader>
              <DialogTitle>Void Document</DialogTitle>
            </DialogHeader>
            <div>
              <h2 className="text-sm md:text-base font-medium md:leading-[2] leading-[2]">
                Are you sure you want to void this document?
              </h2>
              <p className="text-[10px] md:text-xs text-gray-600">
                This document will be void.
              </p>
            </div>
            <DialogFooter>
              <BoloButton
                variant={"danger-border"}
                size={"sm"}
                disabled={dropdownState.showDeletingResponse.oneDeleting}
                onClick={handleVoidDocumentDelete}
              >
                Void Document
              </BoloButton>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <div className="flex flex-col w-full md:w-fit">
          <div className="text-base md:text-xl font-semibold flex items-center gap-3">
            <IoMdArrowRoundBack
              className="cursor-pointer text-lg md:text-2xl"
              onClick={() => {
                if (isSharedPage) {
                  if (paths[1]) {
                    navigate(
                      `/shared-with-me/folder/items?folderId=${paths[1]?.folderId}&whichAdmin=${whichAdmin}`
                    );
                  } else {
                    navigate(`/shared-with-me/folder`);
                  }
                } else {
                  if (paths[1]) {
                    navigate(`/folder/items?folderId=${paths[1]?.folderId}`);
                  } else {
                    navigate(`/folder`);
                  }
                }
              }}
            />{" "}
            {/* Folder : {folder.name} */}
            <div className="ml-1 flex text-xs md:text-base flex-row-reverse justify-end">
              {paths?.map((elm, idx) => {
                return (
                  <div
                    className="cursor-pointer flex flex-wrap items-center"
                    onClick={() => {
                      if (isSharedPage) {
                        navigate(
                          `/shared-with-me/folder/items?folderId=${elm?.folderId}&whichAdmin=${whichAdmin}`
                        );
                      } else {
                        navigate(`/folder/items?folderId=${elm?.folderId}`);
                      }
                    }}
                  >
                    {elm?.name}
                    {idx !== 0 && <MdKeyboardArrowRight className="text-xl" />}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          {!isSharedPage && (
            <BoloButton
              size={"sm"}
              className="hidden md:flex h-[32px] whitespace-nowrap text-xs md:text-sm gap-3 items-center"
              disabled={
                !workSpaceDetails?.isWorkSpaceOwner &&
                workSpaceDetails?.currentWorkSpaceRole === "readonly"
                // || workSpaceDetails?.currentWorkSpaceRole === "editor"
              }
              onClick={() => {
                new MixPanel().track("Folder", {
                  folder_action: "folder_create_file",
                  paid: false,
                });
                new MixPanel().increment("folder_create_file");
                if (!isFolderEnabled) {
                  setUpgradeModal({
                    show: true,
                    data: {
                      title: BLOCKING.FOLDER.title,
                      desc: BLOCKING.FOLDER.desc,
                      redirect: BLOCKING.FOLDER.redirect,
                      videoSrc: "",
                      redirectText: BLOCKING.FOLDER.redirectText,
                    },
                  });
                  return;
                }
                setCreateFileModal(true);
              }}
            >
              <FaPlus /> Create File
            </BoloButton>
          )}
          <BoloButton
            size={"sm"}
            className="h-[32px] whitespace-nowrap text-xs md:text-sm flex gap-3 items-center"
            disabled={
              !workSpaceDetails?.isWorkSpaceOwner &&
              workSpaceDetails?.currentWorkSpaceRole === "readonly"
              // || workSpaceDetails?.currentWorkSpaceRole === "editor"
            }
            onClick={() => {
              new MixPanel().track("Folder", {
                folder_action: "folder_create",
                paid: false,
              });
              new MixPanel().increment("folder_create");
              if (!isFolderEnabled) {
                setUpgradeModal({
                  show: true,
                  data: {
                    title: BLOCKING.FOLDER.title,
                    desc: BLOCKING.FOLDER.desc,
                    redirect: BLOCKING.FOLDER.redirect,
                    videoSrc: "",
                    redirectText: BLOCKING.FOLDER.redirectText,
                  },
                });
                return;
              }
              setCreateFolderModal(true);
            }}
          >
            <FaPlus />
            Create Folder
          </BoloButton>
        </div>
      </div>
      <div
        className="md:pb-[350px]"
        // style={{ scrollbarGutter: "stable" }}
        id="mini-pdf-custom-scroll"
      >
        <div className="flex flex-col text-xs md:text-sm mt-0 md:mt-4 border-b">
          {folder?.items?.map((item, itemIdx) => {
            return (
              <div
                className={`flex items-center border-t px-2 md:px-6 py-1.5 hover:bg-secondary/40 group ${
                  item?.type === "FOLDER" ? "cursor-pointer" : ""
                }`}
                key={itemIdx}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isFolderEnabled) {
                    setUpgradeModal({
                      show: true,
                      data: {
                        title: BLOCKING.FOLDER.title,
                        desc: BLOCKING.FOLDER.desc,
                        redirect: BLOCKING.FOLDER.redirect,
                        videoSrc: "",
                        redirectText: BLOCKING.FOLDER.redirectText,
                      },
                    });
                    return;
                  }
                  listClickHandler(item);
                }}
              >
                {/* <FolderItemContent item={item} /> */}
                <div className="w-full flex justify-between items-center text-gray-700">
                  <div className="flex gap-3 items-center">
                    <span>
                      {item?.type == "FORM" && <BoloIconForm />}
                      {item?.type == "PDF" && <BoloIconPDF />}
                      {item?.type == "PDF-TEMPLATE" && <BoloIconPDFTemplate />}
                      {item?.type == "FOLDER" && (
                        <MdFolder
                          className="text-2xl"
                          style={{ color: item?.value?.color || "#3c4043" }}
                        />
                      )}
                    </span>
                    <div className="">
                      <div className="text-xs">
                        {item?.type == "FORM" && item?.value?.formJson?.title}
                        {item?.type == "PDF" && item?.value?.documentName}
                        {item?.type == "PDF-TEMPLATE" &&
                          item?.value?.documentName}
                        {item?.type == "FOLDER" && item?.value?.name}
                      </div>
                      <p className="block md:hidden text-gray-500 text-[10px]">
                        {new Date(item?.value?.createdAt).toDateString()}{" "}
                        {new Date(item?.value?.createdAt).toLocaleTimeString()}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="hidden md:block">
                      <h3 className="text-gray-700 text-xs w-[250px]">
                        {new Date(item?.value?.createdAt).toDateString()}
                      </h3>
                      <p className="text-gray-500 text-xs mt-0.5">
                        {new Date(item?.value?.createdAt).toLocaleTimeString()}
                      </p>
                    </div>
                    <Options
                      dropdownState={dropdownState}
                      updateDropdownState={updateDropdownState}
                      item={item}
                      setActiveOption={setActiveOption}
                      setFolder={setFolder}
                      folder={folder}
                      setMoveItem={setMoveItem}
                      setMoveItemModal={setMoveItemModal}
                      setDeleteItemModal={setDeleteItemModal}
                      setDeleteItem={setDeleteItem}
                      optionFunctions={optionFunctions}
                      setOptionFunctions={setOptionFunctions}
                      stateUpdate={setUpdateDone}
                      isSharedPage={isSharedPage}
                      setRemoveFromFolder={setRemoveFromFolder}
                      setRemoveItem={setRemoveItem}
                      isFolderEnabled={isFolderEnabled}
                      setUpgradeModal={setUpgradeModal}
                      workSpaceDetails={workSpaceDetails}
                      isIntegrationPermissionAvailable={
                        isIntegrationPermissionAvailable
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {!folder?.items?.length && (
          <div className="mt-4 px-6 flex w-full text-gray-700 text-sm">
            No items found in the folder
          </div>
        )}
      </div>
      {createFolderModal && (
        <CreateFolderModal
          setShow={setCreateFolderModal}
          stateUpdate={setUpdateDone}
          parentFolder={folder?._id}
          isShared={isSharedPage}
        />
      )}
      {createFileModal && (
        <CreateFileModal
          setShow={setCreateFileModal}
          stateUpdate={setUpdateDone}
          parentFolder={folder?.folderId}
          isShared={isSharedPage}
        />
      )}

      {dropdownState.showUpgradeModal && (
        <PleaseUpgradeModal
          show={dropdownState.showUpgradeModal}
          setShow={(value) => {
            updateDropdownState("showUpgradeModal", value);
          }}
          data={upgradeModalData}
          blockingType={dropdownState.upgradeModalType}
        />
      )}
      {dropdownState.showGoogleDriveModal && (
        <Modal modalContent={GoogleDriveModal()} />
      )}
      {dropdownState.showDropboxDriveModal && (
        <DropboxDriveModal
          setShowDropboxDriveModal={(value) => {
            updateDropdownState("showDropboxDriveModal", value);
          }}
          driveModalDoc={dropdownState.dropboxDoc}
          setDropboxModalDoc={(value) => {
            updateDropdownState("dropboxDoc", value);
          }}
        />
      )}
      {dropdownState.showOneDriveModal && (
        <OnedriveModalUpload
          setOnedriveModalDoc={(value) => {
            updateDropdownState("oneDriveDoc", value);
          }}
          onedriveModalDoc={dropdownState.oneDriveDoc}
          setShowOneDriveModal={(value) => {
            updateDropdownState("showOneDriveModal", value);
          }}
        />
      )}
      {dropdownState.isSigningOrderDiagram && (
        <SigningOrderDiagram
          recipientList={dropdownState.signingOrderRecipientList}
          setIsSigningOrderDiagram={(value) => {
            updateDropdownState("isSigningOrderDiagram", value);
          }}
          isSigningOrderDiagram={dropdownState.isSigningOrderDiagram}
          currDocument={dropdownState.currOrderDocument}
        />
      )}
      {dropdownState.showShareViaLink && (
        <ShareViaLink
          setShowShareViaLink={(value) => {
            updateDropdownState("showShareViaLink", value);
          }}
          type="PDF"
          sendToSigners={() => {
            return;
          }}
          sending={false}
          documentId={dropdownState.generatedLinksData?.documentId}
          generatedLinks={
            dropdownState.generatedLinksData?.respondentsOfDoc || []
          }
        />
      )}
      {dropdownState.showSigningInPerson && (
        <SigningInPerson
          setShowSigningInPerson={(value) => {
            updateDropdownState("showSigningInPerson", value);
          }}
          type="PDF"
          documentId={dropdownState.generatedLinksData?.documentId}
          isSigningOrderData={
            dropdownState.generatedLinksData?.isSigningOrderData
          }
          generatedLink={
            dropdownState.generatedLinksData?.respondentsOfDoc || []
          }
          sending={false}
        />
      )}
      {dropdownState.showShareDocType && (
        <ShareWithTeammates
          helpers={{
            documentType: dropdownState.showShareDocType,
            documentName:
              dropdownState.showShareDocType === "forms"
                ? dropdownState.activeForm?.formJson?.title ||
                  dropdownState.activeForm?.name ||
                  "Untitled"
                : dropdownState.activeDoc?.documentName ||
                  dropdownState.activeDoc?.name ||
                  "Untitled",
            documentId: dropdownState.showShareDocType
              ? dropdownState.activeForm?.formId
              : dropdownState.activeDoc?.documentId,
            isOpen: dropdownState.showShare,
            setIsOpen: (value) => {
              updateDropdownState("showShare", value);
            },
          }}
        />
      )}

      {dropdownState.showGenerateLinksModal && (
        <GenerateLinksModal
          setShowGenerateLinksModal={(value) => {
            updateDropdownState("showGenerateLinksModal", value);
          }}
          setShowShareViaLink={(value) => {
            updateDropdownState("showShareViaLink", value);
          }}
          docType="PDF"
          documentId={dropdownState.generatedLinksData?.documentId}
          document={dropdownState.generatedLinksData}
          setGeneratedLinksData={(value) => {
            updateDropdownState("generatedLinksData", value);
          }}
        />
      )}
      {dropdownState.isQuotaExausted && (
        <PlanExaustedBannerEditor
          setShow={(value: boolean) => {
            updateDropdownState("isQuotaExausted", value);
          }}
        />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default FolderItems;
