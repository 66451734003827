import { SchemaForUI } from "../pdfme/common/src";
import {
  DEFAULT_FONT_NAME,
  DEFAULT_FONT_NAME_BOLD,
  DEFAULT_FONT_NAME_BOLD_ITALIC,
  DEFAULT_FONT_NAME_ITALIC,
  Size,
} from "../pdfme/common/src";
import {
  BASE_FONTS_NAMES,
  BASE_FONT_NAMES_TYPES,
  CSS_FONT_NAME,
  FONT_NAMES,
} from "../pdfme/common/src/constants";
import {
  DropdownOptionSchema,
  isTableSchema,
  TableSchema,
  TextSchema,
} from "../pdfme/common/src/type";
import {
  ChangeSchemasType,
  ChangeSchemasArg,
} from "../pdfme/ui/src/components/Designer";
import { getHeightFromFontSize } from "../pdfme/ui/src/constants";

const getFontName = (args: {
  bold: boolean;
  italic: boolean;
  currFont: string;
}): string => {
  const { bold, italic, currFont } = args;

  let fontBaseName: BASE_FONT_NAMES_TYPES =
    BASE_FONTS_NAMES[currFont] || "ROBOTO";
  let fontVariants = FONT_NAMES[fontBaseName];

  if (!bold && !italic) {
    return fontVariants.REGULAR;
  }

  if (bold && italic) {
    return fontVariants.BOLD_ITALIC;
  }

  if (bold) {
    return fontVariants.BOLD;
  }

  if (italic) {
    return fontVariants.ITALIC;
  }

  return DEFAULT_FONT_NAME;
};

const handleSchemaFontSize = (args: {
  fontSize: string | number;
  activeSchema: SchemaForUI;
  pageSize: Size;
  changeSchemas: ChangeSchemasType;
  cb?: () => void;
}) => {
  const { fontSize, activeSchema, pageSize, changeSchemas } = args;

  if (!(activeSchema?.type == "text" || activeSchema?.type == "table")) {
    return;
  }

  const currentFontSize = Number(fontSize);
  const dynamincFontSizeMinAdjust =
    activeSchema?.dynamicFontSize &&
    activeSchema?.dynamicFontSize?.min > currentFontSize;

  let extraSettings: ChangeSchemasArg[] = [];
  if (currentFontSize >= 10 && !isTableSchema(activeSchema)) {
    let bottom = activeSchema.position.y + currentFontSize;
    if (bottom < pageSize.height && currentFontSize > activeSchema.height) {
      extraSettings = [
        {
          key: "height",
          value: getHeightFromFontSize(currentFontSize, false) || 5.4,
          schemaId: activeSchema.id,
        },
      ];
    }
  }

  changeSchemas([
    {
      key: "fontSize",
      value: currentFontSize,
      schemaId: activeSchema.id,
    },
    ...extraSettings,
    ...(dynamincFontSizeMinAdjust
      ? [
          {
            key: "dynamicFontSize.min",
            value: currentFontSize,
            schemaId: activeSchema.id,
          },
        ]
      : []),
  ]);
};

const handleSchemaFontHeight = (args: {
  lineHeight: string | number;
  activeSchema: SchemaForUI;
  pageSize: Size;
  changeSchemas: ChangeSchemasType;
  cb?: () => void;
}) => {
  const { lineHeight, activeSchema, pageSize, changeSchemas } = args;

  if (!(activeSchema?.type == "text" || activeSchema?.type == "table")) {
    return;
  }

  let currLineHeight = Number(lineHeight);

  let extraSettings: ChangeSchemasArg[] = [];
  if (currLineHeight >= 1 && !isTableSchema(activeSchema)) {
    let adjustedHeight = activeSchema.height / (activeSchema?.lineHeight || 1);
    let scaledHeight = 10 * currLineHeight;

    let bottom = activeSchema.position.y + scaledHeight;
    if (bottom < pageSize.height && scaledHeight > activeSchema.height) {
      extraSettings = [
        {
          key: "height",
          value: scaledHeight || 10,
          schemaId: activeSchema.id,
        },
      ];
    }
  }
  changeSchemas([
    {
      key: "lineHeight",
      value: Number(currLineHeight),
      schemaId: activeSchema.id,
    },
    ...extraSettings,
  ]);
};

export { getFontName, handleSchemaFontSize, handleSchemaFontHeight };
