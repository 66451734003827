import { toast } from "react-toastify";
import { BASE_URL, FIREBASE_CONFIG } from "../../constants/constants";
import { getWhiteLabelInfoLocal, isCollaboratorInWorkspace } from "../../utils";
import axios from "../../utils/axios";
import MixPanel from "../../utils/services/mixpanel";
import Tracking from "../../utils/tracking";

export async function updateLoginInfoOnlyBackend(result: any, method?: string) {
  const user = result.user;
  let referrer = localStorage.getItem("ref");
  let refReward = localStorage.getItem("refReward");
  let timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  const currentUrl = window.location.search;
  const searchParams = new URLSearchParams(currentUrl);
  const fromParam = searchParams.get("from");
  const whiteLabelInfo = getWhiteLabelInfoLocal();

  if (refReward) {
    referrer = null;
    localStorage.setItem("ref", "");
  }
  localStorage.setItem("refReward", "");

  // createOwner
  try {
    let { data } = await axios.post(`${BASE_URL}/owners`, {
      email: user.email,
      name: user.displayName,
      profilePic: user.photoURL,
      phone: user.phoneNumber,
      from: fromParam,
      referrer,
      timezone,
      accessToken: result?.user?.accessToken,
      projectId:
        whiteLabelInfo?.settings?.organization?.firebase?.config?.projectId ||
        FIREBASE_CONFIG?.projectId,
    });
    console.log("data", data);
  } catch (error) {
    console.log(error);
  }
}

export async function updateLoginInfo(result: any, method?: string) {
  const user = result.user;
  let referrer = localStorage.getItem("ref");
  let refReward = localStorage.getItem("refReward");
  let timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  const currentUrl = window.location.search;
  const searchParams = new URLSearchParams(currentUrl);
  const fromParam = searchParams.get("from");
  const redirectUrl = searchParams.get("redirect");
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const userType =
    searchParams.get("type") === "collaborator" ? "collaborator" : "owner";

  if (refReward) {
    referrer = null;
    localStorage.setItem("ref", "");
  }
  localStorage.setItem("refReward", "");

  // createOwner
  try {
    let { data } = await axios.post(`${BASE_URL}/owners`, {
      email: user.email,
      name: user.displayName,
      profilePic: user.photoURL,
      phone: user.phoneNumber,
      from: fromParam,
      referrer,
      timezone,
      accessToken: result?.user?.accessToken,
      projectId:
        whiteLabelInfo?.settings?.organization?.firebase?.config?.projectId ||
        FIREBASE_CONFIG?.projectId,
    });
    console.log("data", data);

    if (data?.owner) {
      new MixPanel().track("login", { method: method });
      new Tracking().trackSignUp(user.email);
      console.log("owner", data?.owner);
      localStorage.setItem("workspaceid", data.owner?.currentWorkSpaceId);
      localStorage.setItem("owner", JSON.stringify(data.owner));
      localStorage.setItem("currentUserEmail", user.email);
      localStorage.setItem("accountType", userType);
      localStorage.setItem("x-auth-token", data?.["x-auth-token"]);

      // handle the redirect
      if (redirectUrl) {
        let pdfUrl = searchParams.get("pdfUrl");
        let pdfName = searchParams.get("pdfName");

        if (pdfUrl) {
          window.open(
            `${redirectUrl}?pdfUrl=${pdfUrl}&pdfName=${pdfName}`,
            "_self"
          );
        } else {
          window.open(`${redirectUrl}`, "_self");
        }
      } else {
        let workspaceId = searchParams.get("workspaceId");
        if (workspaceId) {
          localStorage.setItem("workspaceid", workspaceId);
          const isInWorkspace = isCollaboratorInWorkspace(user.email);
          if (!isInWorkspace) {
            localStorage.setItem("workspaceid", data.owner?.currentWorkSpaceId);
          }
        } else {
          let localWorkspaceId = localStorage.getItem(
            "collaboratorWorkspaceId"
          );
          if (localWorkspaceId) {
            localStorage.setItem("workspaceid", localWorkspaceId);
            const isInWorkspace = isCollaboratorInWorkspace(user.email);
            if (!isInWorkspace) {
              localStorage.setItem(
                "workspaceid",
                data.owner?.currentWorkSpaceId
              );
            }
            // Clean the local workspace after login
            localStorage.removeItem("collaboratorWorkspaceId");
          }
        }
        window.open("/dashboard/forms", "_self");
      }

      // navigate("/dashboard");
    } else {
      // toast(`You're not ${userType}`, {
      //   type: "error",
      // });
      toast(`Something went wrong! Please try again`, {
        type: "error",
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export async function get2FAInfo({ email }: { email: string }): Promise<{
  verified: boolean;
  enabled: boolean;
}> {
  try {
    const { data } = await axios.post("/2fa/get-info", { email });
    return {
      verified: data?.verified || false,
      enabled: data?.enabled || false,
    };
  } catch (error) {
    console.log("Error in get2FAInfo");
    console.log(error);
    return { verified: false, enabled: false };
  }
}

export async function embedLogin({ token }: { token: string }): Promise<any> {
  try {
    const { data } = await axios.post("/embed/login", { embedToken: token });
    console.log("data.owner embed login", data?.owner);
    if (data?.owner) {
      // new MixPanel().track("login", { method: method });
      console.log("owner", data?.owner);
      localStorage.setItem("workspaceid", data.owner?.currentWorkSpaceId);
      localStorage.setItem("owner", JSON.stringify(data.owner));
      localStorage.setItem("currentUserEmail", data.owner?.email);
      localStorage.setItem("accountType", "OWNER");
      localStorage.setItem("x-auth-token", data?.["x-auth-token"]);
    } else {
      toast(`Something went wrong! Please try again`, {
        type: "error",
      });
    }
    return data;
  } catch (error) {
    console.log("Error in embedLogin");
    console.log(error);
  }
}

// export const handleVerifyOTP = async ({
//   otp,
//   email,
//   validCB,
//   invalidCB,
//   errorCB,
// }: {
//   otp: string;
//   email: string;
//   validCB?: () => void;
//   invalidCB?: () => void;
//   errorCB?: () => void;
// }) => {
//   try {
//     let url = `${BASE_URL}/2fa/verify-qr`;
//     const body = { otp, email };
//     const { data } = await axios.post(url, body);
//     console.log(data);
//     if (data?.success && data?.isValid) {
//       validCB && validCB();
//     } else {
//       invalidCB && invalidCB();
//       toast.error(data?.message || "Something went wrong");
//     }
//   } catch (error) {
//     errorCB && errorCB();
//     console.log("error in handleVerify");
//     console.log(error);
//     toast.error("Something went wrong");
//   }
// };
