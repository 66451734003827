import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BOLO_CURRENCIES } from "../../../constants/currency";
import useWorkSpaceDetails from "../../../hooks/reduxHooks/useGetWorkSpaceDetails";
import axios from "../../../utils/axios";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../Common/BoloSelect/BoloSelect";

const WorkSpaceDefaults = () => {
  const [currency, setCurrency] = useState("");
  const [loading, setLoading] = useState(false);
  const workSpaceDetails = useWorkSpaceDetails();

  useEffect(() => {
    setCurrency(workSpaceDetails?.currentWorkSpace?.settings?.defaultCurrency);
  }, [workSpaceDetails]);

  const handleWorkSpaceCurrency = async (value: string) => {
    setLoading(true);
    try {
      const { data } = await axios.post("/workspace/settings", {
        defaultCurrency: value,
      });
      if (data?.success) {
        toast.success(data?.message || "Updated settings succesfullly");
      } else {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("error in handleWorkSpaceCurrency");
      console.log(error);
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  return (
    <div className="px-4">
      <div>
        <p className="font-semibold mb-2 text-sm">Default currency</p>
        <p className="text-xs text-gray-600">
          Selected default currency will be applied when creating pricing
          tables, payment blocks, etc.
        </p>

        <Select
          disabled={loading}
          value={currency}
          onValueChange={(value) => {
            setCurrency(value);
            handleWorkSpaceCurrency(value);
          }}
        >
          <SelectTrigger className="max-w-[400px] w-full px-0 pb-0">
            <SelectValue placeholder="Select Currency" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {BOLO_CURRENCIES?.map((cur, idx) => {
                return (
                  <SelectItem value={cur.code}>
                    <div className="flex gap-2 text-gray-500 text-xs">
                      <div className="font-semibold pr-2 w-10">{cur.code}</div>
                      <div className=" px-2">{cur.currency}</div>
                    </div>
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default WorkSpaceDefaults;
