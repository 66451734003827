// @ts-nocheck
import React, { useEffect, useState, useRef, Fragment } from "react";
// import "./index.css";
import {
  useNavigate,
  NavLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { IoLanguageSharp, IoRocketSharp } from "react-icons/io5";
import { IoIosHelpCircle } from "react-icons/io";
import {
  MdKeyboardArrowRight,
  MdOutlineHelp,
  MdGroups,
  MdMenu,
} from "react-icons/md";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import {
  fetchOwner,
  getWhiteLabelInfoLocal,
  getWorkSpaceDetails,
} from "../../utils";

import { FaBlog } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { BiCookie, BiSitemap } from "react-icons/bi";
import { PiFilePdfFill } from "react-icons/pi";
import { match } from "assert";
import MixPanel from "../../utils/services/mixpanel";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { getWhiteLabelHelper } from "../../utils";
import { BoloButton } from "../Common/Button/BoloButton";
import CustomTooltip from "../EditForm/CustomTooltip";
import ExtendTrialSetup from "../Ui/ExtendTrialSetup";
import { useAppSelector } from "../../redux/store";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";

const NavBar = ({ helpers }) => {
  let { isOpen, setIsOpen, showTrialExtension } = helpers;
  const navbarRef = useRef();
  const profileSelfRef = useRef();
  const helpSelfRef = useRef();
  const navigate = useNavigate();

  const ownerFromRedux = useAppSelector(
    (state) => state?.root?.ownerSlice?.data?.owner
  );

  const currentPlanFromRedux =
    ownerFromRedux?.planhistory?.[ownerFromRedux?.planhistory?.length - 1];

  // const [owner, setOwner] = useState({});
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [show, setShow] = useState({
    issues: false,
  });
  // const [workSpaceDetails, setWorkSpaceDetails] = useState(
  //   getWorkSpaceDetails()
  // );

  const workSpaceDetails = useWorkSpaceDetails();

  const [showInfoOptions, setShowInfoOptions] = useState(false);

  useEffect(() => {
    let owner = JSON.parse(localStorage.getItem("owner"));
    if (owner === null) {
      navigate("/login");
    }
  }, []);

  const getProfilePhoto = () => {
    //@ts-ignore
    let owner = JSON.parse(localStorage.getItem("owner"));
    if (owner === null) {
      navigate("/login");
    }

    let profilePicUrl = owner?.profilePic;
    return (
      profilePicUrl ??
      "https://rewards.superassistant.io/static/media/avatar-3.f81bc93cc7cd674461d1dbaf67dc96d7.svg"
    );
  };

  const getProfileName = () => {
    let accountType = localStorage.getItem("accountType");
    let owner = JSON.parse(localStorage.getItem("owner"));
    if (owner === null) {
      navigate("/login");
    }
    let name = owner?.name;
    return name?.split(" ")?.[0] || currentUserEmail || "User";
  };

  const handleOutsideClick = (e: Event) => {
    if (navbarRef.current !== null) {
      // @ts-ignore
      if (navbarRef?.current?.contains(e.target)) return;
    }
    setShowProfileOptions(false);
  };

  useEffect(() => {
    let accountType = localStorage.getItem("accountType");
    accountType = accountType === "admin" ? "Owner" : accountType;
    setAccountType(accountType || "unknown");
  }, []); //? dependence was owner origninally, [approval-dashboard-new components>dashboard>navbar>index>line 107]

  const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs();
    const [searchParams] = useSearchParams();
    const searchParamMapper = {
      documentType: {
        FORM: "Form",
        PDF: "Pdf",
        "PDF-TEMPLATE": "Pdf Template",
      },
      type: {
        PROFILE: "Profile",
        APIKEY: "API Key",
      },
    };

    function searchParamParser() {
      let value = false;
      // console.log(searchParams);
      Object.keys(searchParamMapper).map((key) => {
        if (searchParams.get(key)) {
          value = searchParamMapper[key][searchParams.get(key)];
        }
      });
      return value;
    }
    // console.log(searchParamParser());
    const slicedBreadCrumbs =
      window.innerWidth > 768 ? breadcrumbs : breadcrumbs.slice(-2);

    // useEffect(() => {
    //   function checkUserDetails() {
    //     const localWorkSpaceDetails = getWorkSpaceDetails();
    //     setWorkSpaceDetails(localWorkSpaceDetails);
    //   }
    //   window.addEventListener("storage", checkUserDetails);
    //   return () => {
    //     window.removeEventListener("storage", checkUserDetails);
    //   };
    // }, []);

    return (
      <div className="flex">
        {slicedBreadCrumbs.map(({ match, breadcrumb, location }, index) => (
          <NavLink
            key={match.pathname}
            to={{
              pathname: `${
                match.pathname === "/dashboard"
                  ? "/dashboard/forms"
                  : match.pathname
              }`,
              search: location.search,
            }}
            className="flex"
          >
            <div
              className={`${
                location.pathname === match.pathname
                  ? "bg-white shadow ml-1 md:ml-3 font-semibold text-primary"
                  : "hover:bg-opacity-30 hover:bg-white"
              } flex cursor-pointer justify-center items-center px-2 md:px-3 py-1 rounded text-xs`}
            >
              {/* {breadcrumbs.length - 1 === index && searchParamParser() ? (
                <div className="flex gap-1">
                  <p>{searchParamParser()}</p>
                  <p>{breadcrumb}</p>
                </div>
              ) : (
                breadcrumb
              )} */}
              {breadcrumb}
            </div>
            {slicedBreadCrumbs.length - 1 !== index && (
              <div className="flex items-center justify-center mx-1">
                <MdKeyboardArrowRight className="text-xl" />
              </div>
            )}
          </NavLink>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`absolute top-0 left-0 w-full h-[50px] md:h-[60px] px-3 sm:px-4 md:px-6 text-white flex items-center justify-between border-b bg-primary `}
    >
      <h3 className="flex gap-2 capitalize text-xs md:text-sm">
        <button
          className="block md:hidden"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <MdMenu size={25} />
        </button>
        <Breadcrumbs />
        {/* {location.pathname?.slice(1)} */}
      </h3>
      <div className="ml-auto flex gap-1 items-center md:gap-2">
        {currentPlanFromRedux?.tier === "FREE_TRIAL_TIER" && (
          <ExtendTrialSetup showDropdown={showTrialExtension} />
        )}

        <div
          data-tooltip-id="navbar-language"
          className="flex relative gap-2 p-1 hover:bg-secondary hover:text-primary rounded-full transition-all ease-in-out duration-300 "
        >
          <div className="flex justify-start items-center p-[6px]">
            <IoLanguageSharp className={`text-lg md:text-xl`} />
            <CustomTooltip
              helpers={{
                id: "navbar-language",
                content: "Change Language",
                place: "bottom",
              }}
            />
          </div>

          <div
            id="google_translate_element"
            className="absolute inset-0 h-full opacity-0 cursor-pointer"
            onClick={() => {
              new MixPanel().track("translate dashboard", {
                dashboard_action: "dashboard_translate",
                language: Cookies.get("googtrans"),
                paid: false,
              });
              new MixPanel().increment("dashboard_translate");
            }}
          ></div>
        </div>
        <button
          ref={helpSelfRef}
          onClick={() => setShowInfoOptions((prev) => !prev)}
          className={`hidden md:block outline-none ml-4 relative text-sm px-2 py-1 hover:bg-secondary hover:text-primary rounded`}
        >
          {/* <MdOutlineHelp className={`text-2xl`} /> */}
          Help
          {showInfoOptions && (
            <InfoItemsOptions
              selfRef={helpSelfRef}
              setShowInfoOptions={setShowInfoOptions}
              navigate={navigate}
            />
          )}
        </button>

        {/* @ts-ignore */}
        <div ref={navbarRef} className="relative ml-1 md:ml-4">
          <div
            ref={profileSelfRef}
            onClick={() => setShowProfileOptions((prev) => !prev)}
            className="w-auto md:min-w-[110px] flex flex-row items-center justify-between hover:bg-secondary/20 p-1 px-2 rounded cursor-pointer group transition-all duration-200"
          >
            <div className="w-8 h-8 md:w-8 md:h-8 bg-white rounded-full md:mr-2 overflow-hidden border border-gray-600">
              <img
                src={
                  getProfilePhoto() ||
                  "https://rewards.superassistant.io/static/media/avatar-3.f81bc93cc7cd674461d1dbaf67dc96d7.svg"
                }
                alt="profile"
                referrerPolicy="no-referrer"
              />
            </div>
            <div className="hidden md:block max-w-[100px]">
              <p translate="no" className="text-xs truncate">
                {getProfileName()}
              </p>
              <span className="text-[8px] bg-gray-100 text-gray-500 px-2 py-0.5 rounded capitalize group-hover:bg-white">
                {workSpaceDetails?.formattedRole || "Owner"}
              </span>
            </div>
            {showProfileOptions && (
              <ProfileOptions
                selfRef={profileSelfRef}
                setShowProfileOptions={setShowProfileOptions}
                navigate={navigate}
              />
            )}
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip-team" openOnClick={false}>
        <p className="m-0 font-light rounded cursor-pointer">
          Collaborators and Teams
        </p>
      </Tooltip>
    </div>
  );
};

const ProfileOptions = ({ setShowProfileOptions, navigate, selfRef }: any) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const whiteLabelHelper = getWhiteLabelHelper();
  function openExternalLink(link: string) {
    window.open(link, "_blank");
    setShowProfileOptions(false);
  }

  const logOut = () => {
    localStorage.removeItem("owner");
    localStorage.removeItem("workspaceid");
    localStorage.removeItem("currentUserEmail");
    localStorage.removeItem("accountType");
    localStorage.removeItem("isApprovalTimeline");
    localStorage.removeItem("formColumnPrefrences");
    localStorage.removeItem("respondentPageNo");
    localStorage.removeItem("x-auth-token");
    new MixPanel().track("logout");
    mixpanel.reset();
    // localStorage.removeItem("activeView");
    window.location.href = "/login";
  };
  const profileOptionsRef = useRef(null);

  const handleOutsideClickNew = (e) => {
    console.log(selfRef);
    if (selfRef?.current !== null) {
      if (selfRef?.current.contains(e.target)) return;
    }
    setShowProfileOptions(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickNew);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickNew);
    };
  }, []);

  return (
    <div
      ref={profileOptionsRef}
      className="w-[180px] border absolute z-50 top-0 right-0 mt-[55px] ml-2 bg-white rounded-lg shadow-lg overflow-hidden"
    >
      <div className="block md:hidden">
        {/* <div
          onClick={() => {
            navigate("/billings");
          }}
          className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
        >
          <IoRocketSharp className="text-lg mr-3 text-gray-500" />
          <div>Upgrade</div>
        </div> */}
        <div
          onClick={() => openExternalLink("https://help.boloforms.com/en/")}
          className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
        >
          <IoIosHelpCircle className="text-lg mr-3 text-gray-500" />
          <div>Guides</div>
        </div>
        {/* <div
          onClick={() =>
            openExternalLink(
              "https://api.whatsapp.com/send/?phone=918830631677&text=Need%20Help%20with%20BoloForms%20Approvals"
            )
          }
          className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
        >
          <RiWhatsappFill className="text-lg mr-3 text-gray-500" />
          <div>Whatsapp Us</div>
        </div> */}
        {!whiteLabelHelper?.isWhiteLabel && (
          <div
            onClick={() => openExternalLink("https://www.boloforms.com/blog")}
            className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
          >
            <FaBlog className="text-lg mr-3 text-gray-500" />
            <div>Blogs</div>
          </div>
        )}
        {(whiteLabelInfo?.settings?.organization?.links?.featureRequest ||
          !whiteLabelHelper?.isWhiteLabel) && (
          <div
            onClick={() =>
              openExternalLink(
                whiteLabelInfo?.settings?.organization?.links?.featureRequest ||
                  "https://boloforms.canny.io/"
              )
            }
            className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
          >
            <BiSitemap className="text-lg mr-3 text-gray-500" />
            <div>Request a feature</div>
          </div>
        )}
        <div
          onClick={() =>
            openExternalLink("https://www.boloforms.com/signature/pdf-tools/")
          }
          className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
        >
          <PiFilePdfFill className="text-lg mr-3 text-gray-500" />
          <div>PDF Tools</div>
        </div>
      </div>

      <div
        onClick={logOut}
        className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-100 cursor-pointer"
      >
        <FiLogOut className="text-lg mr-3 text-gray-500" />
        <div>Log Out</div>
      </div>
    </div>
  );
};

const InfoItemsOptions = ({ setShowInfoOptions, navigate, selfRef }: any) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const whiteLabelHelper = getWhiteLabelHelper();

  const infoItemsOptionsRef = useRef(null);
  function openExternalLink(link: string) {
    window.open(link, "_blank");
    setShowInfoOptions(false);
  }

  const handleOutsideClick = (e) => {
    console.log(selfRef);
    if (selfRef?.current !== null) {
      if (selfRef?.current.contains(e.target)) return;
    }
    setShowInfoOptions(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    //? it was left-0 previously, i did it as -left-2
    <div
      ref={infoItemsOptionsRef}
      className="w-[240px] border absolute z-50 top-0 -left-2 mt-[50px] mr-4 bg-white rounded-lg shadow-lg overflow-hidden"
    >
      {/* <div
        onClick={() => {
          navigate("/billings");
        }}
        className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
      >
        <IoRocketSharp className="text-lg mr-3 text-gray-500" />
        <div>Upgrade</div>
      </div> */}
      <div
        onClick={() => openExternalLink("https://help.boloforms.com/en/")}
        className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
      >
        <IoIosHelpCircle className="text-lg mr-3 text-gray-500" />
        <div>Guides</div>
      </div>
      {/* <div
        onClick={() =>
          openExternalLink(
            "https://api.whatsapp.com/send/?phone=918830631677&text=Need%20Help%20with%20BoloForms%20Approvals"
          )
        }
        className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
      >
        <RiWhatsappFill className="text-lg mr-3 text-gray-500" />
        <div>Whatsapp Us</div>
      </div> */}
      {!whiteLabelHelper?.isWhiteLabel && (
        <div
          onClick={() => openExternalLink("https://www.boloforms.com/blog")}
          className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
        >
          <FaBlog className="text-lg mr-3 text-gray-500" />
          <div>Blogs</div>
        </div>
      )}
      {(whiteLabelInfo?.settings?.organization?.links?.featureRequest ||
        !whiteLabelHelper?.isWhiteLabel) && (
        <div
          onClick={() =>
            openExternalLink(
              whiteLabelInfo?.settings?.organization?.links?.featureRequest ||
                "https://boloforms.canny.io/"
            )
          }
          className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
        >
          <BiSitemap className="text-lg mr-3 text-gray-500" />
          <div>Request a feature</div>
        </div>
      )}
      <div
        onClick={() =>
          openExternalLink("https://www.boloforms.com/signature/pdf-tools/")
        }
        className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50 cursor-pointer"
      >
        <PiFilePdfFill className="text-lg mr-3 text-gray-500" />
        <div>PDF Tools</div>
      </div>

      <div></div>
    </div>
  );
};

export default NavBar;
