import React, { useState, useEffect } from "react";
import { WhiteLabelOwnerType } from "../../redux/slices/whiteLabelSlice";
import { useAppSelector } from "../../redux/store";

export type WhiteLabelHelperType = {
  isWhiteLabel: boolean;
  viewerUrl: string;
};

export const whiteLabelHookHelper = (
  info: WhiteLabelOwnerType | null
): WhiteLabelHelperType => {
  if (!info) {
    return {
      isWhiteLabel: false,
      viewerUrl: `https://fill.boloforms.com/signature`,
    };
  }

  let subDomain = `${info?.settings?.organization?.links?.fill || "fill"}`;
  let domain = `${info?.settings?.organization?.domain || "boloforms.com"}`;

  const viewerUrl = `https://${subDomain}.${domain}/signature`;

  return {
    isWhiteLabel: !!info.email,
    viewerUrl,
  };
};

const useWhiteLabelHelper = () => {
  const info = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [helperData, setHelperData] = useState<WhiteLabelHelperType>({
    isWhiteLabel: false,
    viewerUrl: `https://fill.boloforms.com/signature`,
  });

  useEffect(() => {
    setHelperData(whiteLabelHookHelper(info));
  }, [info]);

  return helperData;
};

export default useWhiteLabelHelper;
