import React from "react";
import ReactDOM from "react-dom";
// import { PreviewProps } from '@pdfme/common';
import { PreviewProps, checkPreviewProps } from "../../common/src";
import { PreviewUI } from "./class";
import { DESTROYED_ERR_MSG } from "./constants";
import { I18nContext, FontContext } from "./contexts";
import Preview from "./components/Preview";
import {
  MapperType,
  PreviewFetchedType,
  SetMapperType,
} from "../../common/src/type";

class Form extends PreviewUI {
  private onChangeInputCallback?: (arg: {
    index: number;
    value: string;
    key: string;
  }) => void;
  public setShownModal: (arg: boolean) => void;
  private isOnlyPdfTemplate: boolean;
  private isPreview: boolean;
  private previewStyle: string;
  private isSigning: boolean;
  private fetchedData: PreviewFetchedType;
  private unfilledFieldsKeys?: string[];
  private isApprover?: boolean;
  private mapperState?: MapperType;
  private setMapperState?: SetMapperType;
  constructor(props: PreviewProps) {
    super(props);
    checkPreviewProps(props);
    this.setShownModal = props?.setShownModal || ((open: boolean) => {});
    this.isOnlyPdfTemplate = props.isOnlyPdfTemplate
      ? props.isOnlyPdfTemplate
      : false;
    this.isPreview = props.isPreview ? props.isPreview : false;
    this.previewStyle = props.previewStyle ? props.previewStyle : "";
    this.isSigning = props.isSigning ? props.isSigning : false;
    this.isApprover = props.isApprover ? props.isApprover : false;
    // console.log("Inside new form", props?.fetchedData);
    this.fetchedData = props.fetchedData
      ? props.fetchedData
      : { accountInfo: {} };
    this.unfilledFieldsKeys = props.unfilledFieldsKeys;
    this.mapperState = props.mapperState;
    this.setMapperState = props.setMapperState;
    this.render();
  }

  public onChangeInput(
    cb: (arg: { index: number; value: string; key: string }) => void
  ) {
    this.onChangeInputCallback = cb;
  }

  protected render() {
    if (!this.domContainer) throw Error(DESTROYED_ERR_MSG);
    ReactDOM.render(
      <I18nContext.Provider value={this.getI18n()}>
        <FontContext.Provider value={this.getFont()}>
          <Preview
            previewStyle={this.previewStyle}
            isPreview={this.isPreview}
            isApprover={this.isApprover}
            template={this.template}
            size={this.size}
            inputs={this.inputs}
            onChangeInput={(arg: {
              index: number;
              value: string;
              key: string;
            }) => {
              const { index, value, key } = arg;
              if (this.onChangeInputCallback) {
                this.onChangeInputCallback({ index, value, key });
              }
              this.inputs[index][key] = value;
              this.render();
            }}
            setShownModal={this.setShownModal}
            isOnlyPdfTemplate={this.isOnlyPdfTemplate}
            isSigning={this.isSigning}
            fetchedData={this.fetchedData}
            unfilledFieldsKeys={this.unfilledFieldsKeys}
            mapperState={this.mapperState}
            setMapperState={this.setMapperState}
          />
        </FontContext.Provider>
      </I18nContext.Provider>,
      this.domContainer
    );
  }
}

export default Form;
