// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { dropboxClientID } from "../constants/Integrations/dropbox";

const DropboxFolderSelect = ({ onSuccess }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const scriptId = "dropboxjs";

    // Create a script element
    const script = document.createElement("script");
    script.src = "https://www.dropbox.com/static/api/2/dropins.js";
    script.id = scriptId;
    script.setAttribute("data-app-key", dropboxClientID);
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Event listener for when script is loaded
    script.onload = () => {
      const options = {
        success: (files) => {
          if (onSuccess) onSuccess(files);
        },
        cancel: () => {
          console.log("cancel");
        },
        linkType: "preview", // or "direct"
        multiselect: false,
        extensions: [".vtx"],
        folderselect: true,
      };

      Dropbox.choose(options);
    };

    // Clean up function
    return () => {
      // Remove script element when component unmounts
      const existingScript = document.getElementById(scriptId);
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return <div id="dropbox-container" ref={buttonRef}></div>;
};

export default DropboxFolderSelect;
