import { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace, MdUpdate } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa";
import axios from "../../utils/axios";
// import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import RolesInput from "../../components/Ui/Roles";
import { fetchOwner } from "../../utils";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaFilePdf } from "react-icons/fa6";
import { BiSolidUpArrowCircle } from "react-icons/bi";
type Props = {};

const EditTemplateRoles = (props: Props) => {
  let navigate = useNavigate();
  const [owner, setOwner] = useState<any>({});
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [pdfPages, setPdfPages] = useState<number>(1);
  const [templateAvailable, setTemplateAvailable] = useState(false);
  const [schemas, setSchemas] = useState<any>([{}]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [searchParams] = useSearchParams();
  const documentId = searchParams.get("documentId");

  const [roles, setRoles] = useState<any>([]);

  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
    };
    updatedOwner();
  }, []);

  useEffect(() => {
    let getDocumentDetails = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `/getOneDocument?documentId=${documentId}`
        );
        if (!data.error) {
          //   toast.success(data.message);
        }
        let fileName =
          data.document.documentName || data.document.pdfTemplate.basePdfName;
        setFileName(fileName);
        let pdfUrl =
          data.document.documentUrl || data.document.pdfTemplate.basePdf;
        if (!pdfUrl) {
          // toast.error("Error fetching form");
          console.log("Error fetching form, while updating roles");
          return;
        }
        setPdfUrl(pdfUrl);
        let roles = data.document?.roles || [];
        console.log("roles", roles);
        setRoles(roles);

        setLoading(false);
      } catch (errror) {
        setLoading(false);
        // toast.error("Error fetching form");
      }
    };
    getDocumentDetails();
  }, [documentId]);

  const updateRoles = async () => {
    const documentId = searchParams.get("documentId");
    try {
      setLoading(true);
      console.log("roles", roles);
      const { data } = await axios.post(`/updateRoles`, {
        roles,
        documentId,
      });
      if (!data.error) {
        toast.success(data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log("error updating roles", error);
    }
  };

  function hasDuplicateTitles(roles: { title: string }[]) {
    const titleSet = new Set();

    for (const role of roles) {
      // Convert the title to a string to handle different data types
      const titleString = String(role.title).toLowerCase();

      if (titleSet.has(titleString)) {
        // Duplicate title found
        toast.error("There can not be more than one role with same title");
        return true;
      }

      titleSet.add(titleString);
    }

    // No duplicate titles found
    return false;
  }

  return (
    <div className="min-h-full">
      <div className="template-navbar sticky top-0 z-10 h-10 md:h-14 px-3 md:px-8 py-2 bg-primary flex gap-2 text-white items-center">
        <IoArrowBackCircle
          className="hover:opacity-70 text-3xl cursor-pointer rounded-sm"
          onClick={() => {
            navigate("/dashboard/templates");
          }}
        />
        <p className="text-sm ml-2">Edit Template Roles</p>
        <p className="hidden md:block ml-auto text-sm">
          <span className="font-semibold">{fileName}</span>
        </p>
      </div>
      <div className="flex flex-col  py-5 md:py-10 px-3 md:px-8 gap-4 justify-start md:justify-between min-h-full">
        <div className="flex flex-col gap-4 max-h-[calc(100vh_-_130px)] overflow-y-auto md:overflow-y-visible md:max-h-fit">
          <div className="uploader flex flex-col w-[100%] md:w-[65%] max-w-[800px] mx-auto">
            <div className="w-full">
              <h1 className="text-lg md:text-2xl font-bold mb-3 text-gray-700">
                Your Template
              </h1>
              <p className="text-sm md:text-base mb-3 w-full capitalize font-medium">
                Edit roles for your template or upload a new template
              </p>
            </div>
            <label
              className={`mb-6 flex flex-col items-center justify-center p-4 md:p-8  border-2 border-gray-300 hover:border-gray-500 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-full text-gray-600 transition-all ease-in-out duration-150 bg-secondary border-primary/80`}
              onClick={(e) => {
                window.open(pdfUrl, "_blank");
              }}
            >
              <div className="flex flex-row md:flex-col items-center justify-center gap-8 py-8">
                <FaFilePdf size={50} className="text-gray-600" />
                <div className="flex flex-col items-center justify-center">
                  {/* <TiTick size={30} className="text-primary" /> */}
                  <p className="text-gray-600 text-xs md:text-sm break-all">
                    {fileName}
                  </p>
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/create/pdf-template?documentId=${documentId}`);
                    }}
                    className="text-white text-xs mt-2 font-medium bg-gray-500 px-4 py-1 rounded-md"
                  >
                    Edit Template
                  </p>
                </div>
              </div>
            </label>
          </div>
          <div className="role flex flex-col ">
            {/* <span>Roles</span> */}
            <RolesInput onSave={setRoles} preFetchedRoles={roles} />
          </div>
        </div>
        <div className="fixed md:static bottom-3 right-3 w-[calc(100%_-_1.5rem)] md:w-[65%] max-w-[800px] mx-auto cursor-pointer">
          <div className="bg-primary w-full text-white px-6 py-2 rounded-md text-xs md:text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70">
            <MdUpdate className="text-2xl" />
            <span
              className="transition-all ease-in-out duration-300 "
              onClick={async () => {
                if (loading || !roles.length || hasDuplicateTitles(roles))
                  return;

                await updateRoles();
              }}
            >
              {loading ? "Updating Roles..." : "Update Roles"}
            </span>
          </div>
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default EditTemplateRoles;
