import React, { useEffect, useState } from "react";
import ViewHeader from "./ViewHeader";
import UploadAttachment from "../../../../../../components/Ui/UploadAttachment";
import { HiOutlineDotsVertical } from "react-icons/hi";
import {
  BoloPopover,
  BoloPopOverClose,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../../../../../components/Common/PopOver/BoloPopOver";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LuDownload } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { Slide, toast, ToastContainer } from "react-toastify";
import { DesignerState, GlobalHelpersType } from "..";
import axios from "../../../../../../utils/axios";
import getS3PreSignedUrl from "../../../../../../utils/getS3PreSignedUrl";
import { saveAs } from "file-saver";
import { isFeatureEnabled } from "../../../../../../utils/paidFeat";
import PleaseUpgradeModal from "../../../../../../components/PleaseUpgradeModal";
import { ALL_FEATURES } from "../../../../../../types/tiers";
export type AttachmentsViewProps = {
  helpers: {
    open: boolean;
    setOpen: (value: boolean) => void;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};
export type fileUploadType = {
  mimeType: string;
  fileName: string;
  fileUrl: string;
  dateAdded: Date;
  fileSize: number;
  pageNo?: number;
  fileId: string;
};
const formatDate = (dateString: Date): string => {
  const date = new Date(dateString);
  // Get month name in three-letter abbreviation
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const month = monthNames[date.getMonth()];

  // Get day and year
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the date
  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
};
export const convertFilename = (file: fileUploadType): string => {
  let fileName = file?.fileName;
  if (fileName?.length > 25) {
    let prefix = fileName.substring(0, 15);
    let suffix = fileName.substring(fileName?.length - 7);
    return `${prefix}...${suffix}`;
  }
  return fileName;
};
export const formatFileSize = (fileSizeInBytes: number) => {
  const fileSizeInKB = fileSizeInBytes / 1024;

  if (fileSizeInKB < 1024) {
    return `${Math.floor(fileSizeInKB)} KB`;
  } else {
    const fileSizeInMB = fileSizeInKB / 1024;
    return `${fileSizeInMB.toFixed(2)} MB`;
  }
};
export const downloadFilePrivate = async (
  privateUrl: string,
  fileName: string
) => {
  try {
    let url = await getS3PreSignedUrl(privateUrl);
    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, fileName);
  } catch (e) {
    console.log("Error while fetching file");
    toast.error("Failed to fetch pdf");
  }
};
const AttachmentsView = ({ helpers }: AttachmentsViewProps) => {
  const { open, setOpen, designerState, setDesignerState, globalHelpers } =
    helpers;

  const [showUploadfileModal, setShowUploadfileModal] =
    useState<boolean>(false);
  const [attachments, setAttachments] = useState<fileUploadType[]>(
    designerState?.fetchedData?.document?.settings?.attachments || []
  );
  const [upgradeModalType, setUpgradeModalType] =
    useState<ALL_FEATURES>("SIGNER_ATTACHMENTS");
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  useEffect(() => {
    setAttachments(
      designerState?.fetchedData?.document?.settings?.attachments || []
    );
  }, [designerState]);
  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      <div className="px-4 overflow-y-auto">
        <div className="">
          <ViewHeader name="Attachments" open={open} setOpen={setOpen} />
        </div>
        <p className="text-gray-500 text-xs pr-4 mb-4">
          All attachments will be stored here and can be downloaded.
        </p>

        <div>
          <button
            className={`mb-4 flex gap-2 items-center justify-center w-full py-2 px-auto cursor-pointer text-xs text-primary bg-primary/10 font-lightbold  rounded overflow-hidden`}
            disabled={attachments?.length > 4}
            onClick={async () => {
              let featuresAccess = await isFeatureEnabled("SIGNER_ATTACHMENTS");

              if (!featuresAccess) {
                setShowUpgradeModal(true);
                setUpgradeModalType("SIGNER_ATTACHMENTS");
                return;
              }
              setShowUploadfileModal(true);
            }}
          >
            <span className="font-medium"> Add attachment</span>
          </button>
        </div>
        {attachments !== null && attachments?.length > 0 && (
          <>
            <div className="text-xs text-gray-500 my-4">ATTACHED BY SENDER</div>
            {attachments?.map((attachment: fileUploadType, index: number) => (
              <div className=" group border-b flex justify-between items-center hover:bg-slate-100 hover:text-primary  px-2">
                <div className="py-2">
                  <div className="font-semibold text-xs text-ellipsis overflow-hidden">
                    {convertFilename(attachment)}
                  </div>
                  <div className="flex text-xs  py-1 gap-1  items-center">
                    <span className="text-gray-600 text-semibold">
                      {formatFileSize(attachment?.fileSize)}
                    </span>
                    <span className="w-1 h-1 bg-gray-500 rounded-full"></span>
                    <span className="text-gray-400">
                      {formatDate(attachment?.dateAdded)}
                    </span>
                  </div>
                </div>
                <div className="">
                  <BoloPopover>
                    <BoloPopoverTrigger>
                      <button className="text-gray-400 hover:text-gray-500 mt-1 group-hover:inline-block hidden">
                        <BsThreeDotsVertical size={18} />
                      </button>
                    </BoloPopoverTrigger>
                    <BoloPopoverContent align="end">
                      <div className="flex flex-col gap-2 text-gray-400 text-xs rounded-lg bg-white w-fit p-2">
                        <BoloPopOverClose>
                          <button
                            className="hover:text-black flex gap-3 p-1 items-center w-full"
                            onClick={async () => {
                              await downloadFilePrivate(
                                attachment?.fileUrl,
                                attachment?.fileName
                              );
                            }}
                          >
                            <LuDownload size={18} />
                            Download
                          </button>
                        </BoloPopOverClose>

                        <BoloPopOverClose>
                          <button
                            className="hover:text-red-500 disabled:cursor-not-allowed disabled:opacity-100 flex gap-3 p-1 items-center w-full"
                            onClick={async () => {
                              const newAttachments = attachments?.filter(
                                (_, i) => i !== index
                              );
                              await globalHelpers.updateDocumentAttachments(
                                newAttachments
                              );
                            }}
                          >
                            <MdDelete size={20} />
                            Delete attachment
                          </button>
                        </BoloPopOverClose>
                      </div>
                    </BoloPopoverContent>
                  </BoloPopover>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {showUploadfileModal && (
        <UploadAttachment
          setShowUploadfileModal={setShowUploadfileModal}
          attachmentFiles={attachments}
          globalHelpers={globalHelpers}
        />
      )}
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          blockingType={upgradeModalType}
        />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default AttachmentsView;
