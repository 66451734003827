// @ts-nocheck

import React, { Fragment, useEffect } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import getQuestionTypes from "../../../pages/EditForm/getQuestionTypes";
import { MdClose, MdOutlineCheck, MdOutlineContentCopy } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { v4 } from "uuid";
import { isUndefined } from "lodash";
import CustomTooltip from "../CustomTooltip";
import { COLORS } from "../../../constants/common";

const PurpleSwitch = styled(Switch)(({ theme, inputProps }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: inputProps?.accentColor ?? COLORS.primary,
    "&:hover": {
      backgroundColor: alpha(
        inputProps?.accentColor ?? COLORS.primary,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: inputProps?.accentColor ?? COLORS.primary,
  },
}));

const DeleteQuestionConfirmation = ({ setShow, handleDelete }) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />{" "}
          <div className="text-base md:text-lg mb-4 font-semibold">
            Delete Question
          </div>
          <span className="text-xs md:text-sm">
            This Question is mapped in the pdf are you sure you want to delete
            this question ?
          </span>
          <span className="text-xs md:text-sm">
            Mapped items are not deleted automatically from the PDF instead they
            will be highlighted in{" "}
            <span className="text-red-600 font-semibold">Red color</span>.
          </span>
          <div className="flex gap-2 items-center justify-end mt-8">
            <button
              className="text-xs md:text-sm text-primary border px-4 py-2 rounded-md"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              className="text-xs md:text-sm text-white bg-red-600 px-4 py-2 rounded-md"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuestionBottomOptions = ({ helpers }) => {
  let {
    question,
    setFormState,
    questionIndex,
    activeState,
    setActiveState,
    formState,
    setActive,
    schemas,
  } = helpers;
  const moreOptionsRef = React.useRef(null);

  const [show, setShow] = React.useState({
    moreOptions: false,
    deleteQuestion: false,
  });
  // let isFileUploadQuestion = question?.type === "FileUpload";

  const cloneQuestion = async () => {
    await setFormState((prevState) => {
      // this creates a ref to objects inside the objects therefore modifying both
      // ----------------------------------------
      // let newState = { ...prevState };
      // ----------------------------------------
      // in order to resolve this we use deep copy
      // this assumes that the prevState does not depend on values such as
      // functions and other not parseAble values
      let newState = JSON.parse(JSON.stringify(prevState));

      let pageIndex = activeState.pageIndex;
      let questionIndex = activeState.questionIndex;

      newState.pages[pageIndex].questions.splice(questionIndex + 1, 0, {
        ...question,
        id: v4(),
      });

      return newState;
    });
    // setActiveState((prevState) => {
    //   let newState = { ...prevState };
    //   newState.questionIndex = newState.questionIndex + 1;
    //   return newState;
    // });
    setActive((prevState) => ({
      ...prevState,
      questionIndex: prevState.questionIndex + 1,
    }));
    // console.log("cloned", activeState);
  };

  const handleOutsideClick = (e) => {
    if (moreOptionsRef.current !== null) {
      if (moreOptionsRef.current.contains(e.target)) return;
    }
    setShow((prev) => ({ ...prev, moreOptions: false }));
  };

  const isQuestionInSchema = () => {
    let isInSchema = false;
    schemas?.map((page) => {
      Object.values(page).map((que) => {
        const splitId = que?.id?.split("_")?.[1];
        if (splitId == question?.id) {
          isInSchema = true;
        }
      });
    });
    return isInSchema;
  };

  const handleDelete = () => {
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.map((page) => {
        let questions = page.questions.filter(
          (question_) => question_.id !== question.id
        );
        page.questions = questions;
      });
      // console.log(newState);
      return newState;
    });
  };

  const handleDeleteClick = () => {
    const isInSchema = isQuestionInSchema();
    if (!isInSchema) {
      handleDelete();
      return;
    }
    setShow((prev) => ({ ...prev, deleteQuestion: true }));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  if (
    questionIndex.p !== activeState.pageIndex ||
    questionIndex.q !== activeState.questionIndex
  ) {
    return null;
  }

  return (
    <div className="mt-2 h-[65px] flex justify-between border-t border-[#dadce0]">
      <div className="w-full flex justify-end items-center">
        <div className="h-[52px] flex justify-center items-center">
          <div className="h-full">
            <div
              data-tooltip-id="duplicate"
              data-tooltip-offset={0}
              onClick={cloneQuestion}
              className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
            >
              <MdOutlineContentCopy className="text-2xl text-gray-600" />
            </div>
            <CustomTooltip
              helpers={{
                id: "duplicate",
                content: "Duplicate",
                place: "bottom",
              }}
            />
          </div>

          <div className="h-full">
            {show.deleteQuestion && (
              <DeleteQuestionConfirmation
                setShow={(value) => {
                  setShow((prev) => ({ ...prev, deleteQuestion: value }));
                }}
                handleDelete={handleDelete}
              />
            )}
            <div
              data-tooltip-id="delete"
              data-tooltip-offset={0}
              onClick={() => {
                handleDeleteClick();
                // setFormState((prevState) => {
                //   let newState = { ...prevState };
                //   newState.pages.map((page) => {
                //     let questions = page.questions.filter(
                //       (question_) => question_.id !== question.id
                //     );
                //     page.questions = questions;
                //   });
                //   // console.log(newState);
                //   return newState;
                // });
              }}
              className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
            >
              <RiDeleteBin6Line className="text-2xl text-gray-600" />
            </div>
            <CustomTooltip
              helpers={{
                id: "delete",
                content: "Delete",
                place: "bottom",
              }}
            />
          </div>
          <div className="h-[32px] mx-4 border-l border-[#dadce0]"></div>
          <div>
            <span className="mr-1 text-sm text-gray-800">
              {question?.type == "GridCheckbox" ||
              question?.type == "GridRadioGroup"
                ? "Require a response in each row"
                : "Required"}
            </span>
            <PurpleSwitch
              {...{
                inputProps: {
                  accentColor: formState?.accentColor,
                  "aria-label": "Switch demo",
                },
              }}
              checked={question.required}
              onChange={(e) => {
                setFormState((prevState) => {
                  let newState = { ...prevState };
                  newState.pages.forEach((page) => {
                    page.questions.forEach((question_) => {
                      if (question_.id === question.id) {
                        question_.required = e.target.checked;
                      }
                    });
                  });
                  return newState;
                });
              }}
            />
          </div>
          <div ref={moreOptionsRef} className="h-full relative">
            <div
              data-tooltip-id="moreOptions"
              data-tooltip-offset={0}
              onClick={() =>
                setShow((prev) => ({ ...prev, moreOptions: !prev.moreOptions }))
              }
              className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
            >
              <BsThreeDotsVertical className="text-xl text-gray-600" />
            </div>
            <CustomTooltip
              helpers={{
                id: "moreOptions",
                content: "More Options",
                place: "bottom",
              }}
            />

            {show.moreOptions && (
              <MoreOptions helpers={{ question, setFormState, setShow }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionBottomOptions;

const MoreOptions = ({ helpers }) => {
  let { question, setFormState, setShow } = helpers;
  let isPageBasedOnAnswerQuestion =
    question?.type === "RadioGroup" || question?.type === "Dropdown";
  let isResponseValidationQuestion =
    question?.type === "Text" || question?.type === "LongText";

  const options = [
    {
      name: "Description",
      key: "isDescription",
      isActive: question.isDescription ?? true,
    },
  ];
  isPageBasedOnAnswerQuestion &&
    options.push({
      name: "Page Based On Answer",
      key: "pageBasedOnAnswer",
      isActive: question.pageBasedOnAnswer,
    });

  isResponseValidationQuestion &&
    options.push({
      name: "Response Validation",
      key: "responseValidation",
      isActive: question.responseValidation,
    });

  const handleOptionClick = (option) => {
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            if (option.key !== "responseValidation") {
              question_[option.key] = isUndefined(question_[option.key])
                ? false
                : !question_[option.key];
            }
            if (option.key === "responseValidation") {
              question_.responseValidation = !question_.responseValidation;
              question_.responseValidationValue = {};
            }
          }
        });
      });
      return newState;
    });

    setShow((prevState) => {
      let newState = { ...prevState };
      newState.moreOptions = !newState.moreOptions;
      return newState;
    });
  };

  return (
    <div
      style={{
        marginTop: "48px",
        position: "absolute",
        top: 0,
        right: "-100px",
        boxShadow:
          "0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122)",
      }}
      className="py-2 w-[300px] bg-white rounded z-50"
    >
      <p className="text-sm px-4 text-gray-500">Show</p>
      {options.map((option, i) => {
        return (
          <Fragment key={i}>
            <div
              onClick={() => handleOptionClick(option)}
              className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
            >
              <div className="w-[40px]">
                {option.isActive && (
                  <MdOutlineCheck className="text-2xl text-gray-400 ml-auto" />
                )}
              </div>
              <div className="ml-1 px-4">
                <span className="text-gray-600 text-base">{option.name}</span>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
