import { useEffect, useRef, useState } from "react";
import { MdAdd, MdArrowDropDown, MdFileUpload } from "react-icons/md";
import { BsInputCursorText, BsThreeDotsVertical } from "react-icons/bs";
import { modalTypes } from "../../pages/Contacts";
import MixPanel from "../../utils/services/mixpanel";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import { BoloButton } from "../Common/Button/BoloButton";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../Common/PopOver/BoloPopOver";
interface SelectOptionsProps {
  setModalType: (type: string) => void;
  openModal: (type: string) => void;
  resetContactForm: () => void;
}
const SelectOptions = ({
  setModalType,
  openModal,
  resetContactForm,
}: SelectOptionsProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const workSpaceDetails = useWorkSpaceDetails();
  // List of all types of options inside the contact dropdown
  const options = [
    {
      title: "Create Contact",
      icon: <MdAdd />,
      function: () => {
        new MixPanel().track("Contact", {
          contact_action: "contact_create",
          paid: false,
        });
        new MixPanel().increment("contact_create");
        openModal("create");
        resetContactForm();
      },
    },
    {
      title: "Import Contacts",
      icon: <MdFileUpload />,
      function: () => {
        new MixPanel().track("Contact", {
          contact_action: "contact_bulk_upload",
          paid: false,
        });
        new MixPanel().increment("contact_bulk_upload");
        setModalType(modalTypes.UPLOAD_CONTACTS);
      },
    },
    {
      title: "Custom Fields",
      icon: <BsInputCursorText />,
      function: () => {
        setModalType(modalTypes.CUSTOM_FIELDS);
      },
    },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const dropdownRef = useRef<any>(null);
  const handleOutsideClick = (e: any) => {
    if (dropdownRef.current !== null) {
      if (dropdownRef.current.contains(e.target)) return;
    }
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="relative">
      <BoloButton
        size={"sm"}
        className="flex max-h-[32px] items-center !p-0 md:!p-0"
        // className="w-48 flex justify-center items-center bg-primary text-white h-[38px] rounded-md "
        disabled={workSpaceDetails?.currentWorkSpaceRole === "readonly"}
      >
        <span
          // className="flex gap-1 flex-1 justify-center items-center text-sm "
          className="flex gap-2 p-1 px-2 pr-3"
          onClick={options[0].function}
        >
          <MdAdd className="text-lg" />
          Contact
        </span>
        <div className="h-[32px] min-w-[1px] max-w-[1px] border-r border-white"></div>
        <BoloPopover>
          <BoloPopoverTrigger>
            <div className="text-lg p-1 px-2" onClick={toggleDropdown}>
              <MdArrowDropDown />
            </div>
          </BoloPopoverTrigger>
          <BoloPopoverContent align="end" sideOffset={10}>
            <div className="w-48 p-2">
              {options.map((option, index) => {
                return (
                  <div
                    className="h-10  w-full hover:bg-gray-100 cursor-pointer flex justify-center items-center rounded-md"
                    onClick={option.function}
                    key={index}
                  >
                    <span className="aspect-square h-full flex justify-center items-center text-[18px] text-gray-700">
                      {option.icon}
                    </span>
                    <span className="flex-1 text-gray-700 text-sm flex justify-start items-center">
                      {option.title}
                    </span>
                  </div>
                );
              })}
            </div>
          </BoloPopoverContent>
        </BoloPopover>
        {/* <span
          // className="w-[38px] h-[38px] flex justify-center items-center border-l border-white text-xl"
          className="text-lg p-1 px-2"
          onClick={toggleDropdown}
        >
          <MdArrowDropDown />
        </span> */}
      </BoloButton>
      {/* {isDropdownOpen && (
        <div
          className="absolute left-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg p-2"
          ref={dropdownRef}
        >
          {options.map((option, index) => {
            return (
              <div
                className="h-10  w-full hover:bg-gray-100 cursor-pointer flex justify-center items-center rounded-md"
                onClick={option.function}
                key={index}
              >
                <span className="aspect-square h-full flex justify-center items-center text-[18px] text-gray-700">
                  {option.icon}
                </span>
                <span className="flex-1 text-gray-700 text-sm flex justify-start items-center">
                  {option.title}
                </span>
              </div>
            );
          })}
        </div>
      )} */}
    </div>
  );
};

export default SelectOptions;
