// @ts-nocheck

import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import axios from "../../utils/axios";
// import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
// import { InfinitySpin } from "react-loader-spinner";
import PdfTemplateMapper from "../../components/PdfTemplateMapper";
// import Uploader from "../../components/Ui/Uploader";
// import RolesInput from "../../components/Ui/Roles";
import PlanExhaustedNavBanner from "../../components/PlanExhaustedNavBanner";
import { fetchOwner } from "../../utils";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import SignatureTemplateResponseMapper from "../../components/SignatureTemplateResponseMapper";
import { getDocument } from "pdfjs-dist/legacy/build/pdf.js";
// import { IoArrowBackCircle, IoArrowForwardCircle } from "react-icons/io5";
import { Helmet } from "react-helmet";
// import MixPanel from "../../utils/mixpanel";
import { useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";

type Props = {};

const PDFTemplate = (props: Props) => {
  // let navigate = useNavigate();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [owner, setOwner] = useState<any>({});
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [pdfPages, setPdfPages] = useState<number>(1);
  // const [pdfLoading, setPdfLoading] = useState(false);
  const [templateAvailable, setTemplateAvailable] = useState(false);
  const [schemas, setSchemas] = useState<any>([{}]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [documentId, setDocumentId] = useState(searchParams.get("documentId"));
  const [templateId, settemplateId] = useState(searchParams.get("templateId"));
  // const folderId = searchParams.get("folderId");
  const [showToast, setShowToast] = useState(false); // Add a state to control toast display
  const [quotaExhausted, setQuotaExhausted] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [startSave, setStartSave] = useState(false);
  const [showPreviewRoles, setShowPreviewRoles] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const [roles, setRoles] = useState<any>([]);
  const [previewRole, setPreviewRole] = useState<any>();
  const [fetchData, setFetchData] = useState<any>({});
  const [reload, setReload] = useState<boolean>(false);

  // const [initialUploadedFile, setInitialUploadedFile] = useState<any>(null);
  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
    };
    updatedOwner();

    // Show the toast messages when the component mounts
    if (showToast) {
      toast.info("This form will be converted to a template");
      toast.info("You'll find this in the template dashboard");
      setShowToast(false); // Set the flag to false so that the toast messages won't show again
    }
  }, []);

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  let getDocumentDetails = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/getOneDocument?documentId=${documentId}&templateId=${
          templateId || ""
        }`
      );
      data.templateId = templateId || "";
      setFetchData(data);
      if (!data.error) {
        toast.success(data.message);
      }
      let fileName =
        data.document.documentName || data.document.pdfTemplate.basePdfName;
      setFileName(fileName);

      let roles = data.document?.roles || [];
      setRoles(roles);
      setPreviewRole(roles[0]);

      let pdfUrl =
        data.document.documentUrl || data.document.pdfTemplate.basePdf;
      if (!pdfUrl) {
        // toast.error("Error fetching form");
        return;
      }
      const responseFile = await fetch(pdfUrl);
      const pdfBuffer = await responseFile.arrayBuffer();

      const pdfDoc = await getDocument(pdfUrl).promise;
      const numPages = pdfDoc.numPages;
      setPdfPages(numPages);

      // Convert PDF buffer to a base64 string
      let pdfBase64 = arrayBufferToBase64(pdfBuffer);
      pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
      setUploadedFile(pdfBase64);
      setSchemas(data?.document?.pdfTemplate?.schemas);
      setLoading(false);
    } catch (errror) {
      setLoading(false);
      // toast.error("Error fetching form");
    }
  };

  useEffect(() => {
    getDocumentDetails();
  }, [documentId, isPreview]);

  useEffect(() => {
    let getDocumentDetails = async () => {
      const { data } = await axios.get(
        `/getOneDocument?documentId=${documentId}&templateId=${
          templateId || ""
        }`
      );
      if (data?.document?.pdfTemplate?.schemas) {
        await setSchemas(data?.document?.pdfTemplate?.schemas);
      }
    };
    getDocumentDetails();
    console.log("isSaved", isSaved);
  }, [isSaved, isPreview]);

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  if (quotaExhausted) {
    return <PlanExhaustedNavBanner />;
  }

  if (loading) {
    return (
      <div className="h-screen -mt-[70px] flex justify-center items-center bg-gray-200">
        <BoloLoader />
      </div>
    );
  }

  return (
    <>
      {/* <MobileDeviceNotSupported /> */}
      <div className="">
        <Helmet>
          <title translate="no">Create Pdf Template</title>
          <meta charSet="utf-8" />
          <meta name="title" content="Create Pdf Template" />
          <meta
            name="description"
            content="Effortlessly create custom templates for PDFs, simplifying the signing process for easy and efficient document completion."
          />
          <meta name="author" content="BoloForms" />
          <meta name="publisher" content="BoloForms" />
          <meta
            property="og:url"
            content={`https://signature.boloforms.com/create/pdf-template`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`Create Pdf Template - Boloforms`}
          />
          <meta
            property="og:description"
            content="Effortlessly create custom templates for PDFs, simplifying the signing process for easy and efficient document completion."
          />
          <link
            rel="canonical"
            href="https://signature.boloforms.com/create/pdf-template"
          />
          <link
            rel="icon"
            type="image/png"
            href={
              whiteLabelInfo?.settings?.organization?.favicon ||
              IMAGE_LINKS.FAVICON
            }
          />
        </Helmet>
        {uploadedFile && !loading && (
          <div className="w-full h-full">
            {isPreview && (
              // <div className="fixed top-1 left-50% translate-x-[50%] z-[100] group h-[6px] bg-black/80 animate-pulse-full hover:animate-none hover:bg-transparent rounded-full w-[50vw] flex justify-center">
              <div className="fixed top-1 cursor-pointer opacity-50 hover:opacity-100 left-50% translate-x-[50%] rounded-full z-[110] w-[50vw] transition-all duration-300 animate-none h-8 overflow-visible flex justify-between items-center px-4 text-xs bg-black text-white">
                This is a Preview
                <div className="flex gap-4">
                  <span className="text-xs flex gap-2 items-center">
                    Viewing as :{/* Custom select */}
                    <div
                      id="custom-role-selector"
                      onClick={() => setShowPreviewRoles((prev) => !prev)}
                      className="cursor-pointer relative w-[200px] flex gap-2 justify-between items-center h-6 border border-white text-xs px-3 py-[2px] rounded-full"
                    >
                      <div className="flex gap-2 items-center">
                        <div
                          className="h-3 w-3 rounded-full"
                          style={{ background: previewRole?.colour }}
                        ></div>
                        <div className="truncate w-[130px]">
                          {previewRole?.title}
                        </div>
                      </div>
                      {showPreviewRoles ? (
                        <AiOutlineUp className="text-white" size={12} />
                      ) : (
                        <AiOutlineDown size={12} className="text-white " />
                      )}
                      {showPreviewRoles && (
                        <div className="absolute w-full top-[26px] left-0 z-120 bg-red-300">
                          {roles?.map((role: any) => {
                            return (
                              <div
                                onClick={() => {
                                  setPreviewRole(
                                    roles?.find(
                                      (roleLocal: any) =>
                                        roleLocal?.title == role?.title
                                    )
                                  );
                                }}
                                className="flex gap-2 items-center cursor-pointer bg-white text-black px-3 py-1 border-b w-full"
                              >
                                <div
                                  className="h-3 w-3 rounded-full"
                                  style={{ background: role?.colour }}
                                ></div>
                                <div className="truncate w-[160px]">
                                  {role?.title}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </span>
                  <MdClose
                    className="cursor-pointer"
                    size={20}
                    onClick={() => {
                      setIsPreview(false);
                    }}
                  />
                </div>
              </div>
              // </div>
            )}
            {isPreview ? (
              <SignatureTemplateResponseMapper
                file={uploadedFile}
                isPreview={true}
                previewStyle={"DEFAULT"}
                navHeight={32}
                numberOfPages={pdfPages}
                signerDetails={{
                  name: "Signer's Name",
                  email: "example@domain.com",
                  roleTitle: previewRole?.title,
                  roleColor: previewRole?.colour,
                  signerId: "test",
                }}
                schemas={schemas || [{}]}
                loading={loading}
                setShownModal={() => {}}
                fetchedData={{
                  accountInfo: {},
                  document: fetchData?.document,
                }}
              />
            ) : (
              <PdfTemplateMapper
                setStartSave={setStartSave}
                startSave={startSave}
                setIsPreview={setIsPreview}
                isPreview={isPreview}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
                file={uploadedFile}
                numberOfPages={pdfPages}
                schemas={schemas}
                roles={roles}
                setReload={setReload}
                fetchedData={fetchData}
              />
            )}
          </div>
        )}
        <ToastContainer
          transition={Slide}
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
        />
      </div>
    </>
  );
};

export default PDFTemplate;
