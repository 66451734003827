import React from "react";
import { BsCheck2, BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  MdImage,
  MdOutlineArrowDropDownCircle,
  MdOutlineCheckBox,
  MdOutlineMailOutline,
  MdOutlinePriceChange,
  MdPriceChange,
  MdTextFields,
} from "react-icons/md";
import { SlPuzzle } from "react-icons/sl";
import { getTintFromHex } from "../../../../../../utils/uiUtils";
import { v4 } from "uuid";
import { SIZES, STATIC_SCHEMA } from "../../../constants";
import { FaSignature, FaTable } from "react-icons/fa6";
import { TbLetterCaseUpper } from "react-icons/tb";
import { LuStamp } from "react-icons/lu";
import { GoNumber } from "react-icons/go";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoMdRadioButtonOn } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { RxDragHandleDots2 } from "react-icons/rx";
import ViewHeader from "./ViewHeader";
import { DesignerState, GlobalHelpersType } from "..";
import axios from "../../../../../../utils/axios";
import { defaultContactFields } from "../../../../../../constants/common";
import { convertToTitleCase } from "../../../../../../utils/contacts";
import { PiSignature, PiUploadSimpleFill } from "react-icons/pi";
import { COLORS } from "../../../../../../constants/common";
import { TbBracketsContain } from "react-icons/tb";

export type ContentViewProps = {
  helpers: any;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
  customFields?: any;
};

const ContentView = ({
  helpers,
  designerState,
  setDesignerState,
  globalHelpers,
  customFields,
}: ContentViewProps) => {
  const {
    setIsShowDropDown,
    isShowDropDown,
    open,
    // SetOfFields,
    // roles,
    // selectedRole,
    // setSelectedRole,
    setOpen,
    addSchema,
    onDragStartHandle,
    // isReceiversListValid,
    // instanceFrom,
    // receiversList,
  } = helpers;

  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  const SetOfFields = ({ color, selectedRole }: any) => {
    const fieldsArray = [
      {
        functionalData: {
          data: {
            title: "Signature",
            type: "Signature",
            id: v4(),
            required: false,
            subType: "signature",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.isFormMapper.isSignature.width,
            height: SIZES.isFormMapper.isSignature.height,
          },
        },
        showData: {
          iconSvg: PiSignature,
          // icon: (
          //   <FaSignature
          //     size={25}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{
          //       color: color,
          //       background: getTintFromHex(color, 85),
          //     }}
          //   />
          // ),
          name: "Signature",
        },
      },
      {
        functionalData: {
          data: {
            title: "Initials",
            type: "image",
            id: v4(),
            required: false,
            subType: "initials",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.isFormMapper.isNotSignature.width,
            height: SIZES.isFormMapper.isNotSignature.height,
          },
        },
        showData: {
          iconSvg: TbLetterCaseUpper,
          // icon: (
          //   <TbLetterCaseUpper
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Initials",
        },
      },
      {
        functionalData: {
          data: {
            title: "Stamp",
            type: "stamp",
            id: v4(),
            required: false,
            subType: "stamp",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.stamp.width,
            height: SIZES.stamp.height,
          },
        },
        showData: {
          iconSvg: LuStamp,
          // icon: (
          //   <LuStamp
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Stamp",
        },
      },
      {
        functionalData: {
          data: {
            title: "Text",
            type: "text",
            id: v4(),
            required: false,
            subType: "text",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.isFormMapper.isNotSignature.width,
            height: SIZES.isFormMapper.isNotSignature.height,
          },
        },
        showData: {
          iconSvg: MdTextFields,
          // icon: (
          //   <MdTextFields
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Text",
        },
      },
      {
        functionalData: {
          data: {
            title: "Number",
            type: "number",
            id: v4(),
            required: false,
            subType: "number",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.number.width,
            height: SIZES.number.height,
          },
        },
        showData: {
          iconSvg: GoNumber,
          // icon: (
          //   <GoNumber
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Number",
        },
      },
      {
        functionalData: {
          data: {
            title: "Date",
            type: "date",
            id: v4(),
            required: false,
            subType: "date",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.isFormMapper.isNotSignature.width,
            height: SIZES.isFormMapper.isNotSignature.height,
          },
        },
        showData: {
          iconSvg: AiOutlineCalendar,
          // icon: (
          //   <AiOutlineCalendar
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Date",
        },
      },
      {
        functionalData: {
          data: {
            title: "CheckBox",
            type: "checkbox",
            id: v4(),
            required: false,
            subType: "checkbox",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.checkbox.width,
            height: SIZES.checkbox.height,
          },
        },
        showData: {
          iconSvg: MdOutlineCheckBox,
          // icon: (
          //   <MdOutlineCheckBox
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Checkbox",
        },
      },
      {
        functionalData: {
          data: {
            title: "RadioGroup",
            type: "radiogroup",
            id: v4(),
            required: false,
            subType: "radiogroup",
            role: selectedRole,
            radiogroupId: v4(),
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.radiogroup.width,
            height: SIZES.radiogroup.height,
          },
        },
        showData: {
          iconSvg: IoMdRadioButtonOn,
          // icon: (
          //   <IoMdRadioButtonOn
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Radio Group",
        },
      },
      {
        functionalData: {
          data: {
            title: "DropDown",
            type: "dropdown",
            id: v4(),
            required: false,
            subType: "dropdown",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.dropdown.width,
            height: SIZES.dropdown.height,
          },
        },
        showData: {
          iconSvg: MdOutlineArrowDropDownCircle,
          // icon: (
          //   <MdOutlineArrowDropDownCircle
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "DropDown",
        },
      },
      {
        functionalData: {
          data: {
            title: "Name",
            type: "name",
            id: v4(),
            required: false,
            subType: "name",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.dropdown.width,
            height: SIZES.dropdown.height,
          },
        },
        showData: {
          iconSvg: CgProfile,
          // icon: (
          //   <CgProfile
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Name",
        },
      },
      {
        functionalData: {
          data: {
            title: "Email",
            type: "email",
            id: v4(),
            required: false,
            subType: "email",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.dropdown.width,
            height: SIZES.dropdown.height,
          },
        },
        showData: {
          iconSvg: MdOutlineMailOutline,
          // icon: (
          //   <MdOutlineMailOutline
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Email",
        },
      },
      {
        functionalData: {
          data: {
            title: `File Upload`,
            type: "text",
            id: v4(),
            required: false,
            subType: "file_upload",
            role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.dropdown.width,
            height: SIZES.dropdown.height,
          },
        },
        showData: {
          iconSvg: PiUploadSimpleFill,
          name: "Collect File",
        },
      },
    ];
    return (
      <div className="">
        <ul className="text-xs cursor-pointer grid grid-cols-2 gap-2">
          {fieldsArray?.map((elem, idx) => {
            let dataToAdd = {};
            if (designerState?.instanceFrom == "PDF-TEMPLATE") {
              dataToAdd = { ...elem?.functionalData?.data, id: v4() };
            }
            if (designerState?.instanceFrom == "PDF") {
              let data = {
                // ...elem?.functionalData?.data,
                ...selectedRole,
                title: elem?.functionalData?.data?.title,
                type: elem?.functionalData?.data?.type,
                subType: elem?.functionalData?.data?.subType,
                role: {
                  title: selectedRole?.name,
                  colour: selectedRole?.colour,
                },
              };
              if (elem?.functionalData?.data?.type == "radiogroup") {
                data.radiogroupId = v4();
              }
              delete data.id;
              dataToAdd = data;
            }

            const Icon = elem?.showData?.iconSvg;

            return (
              <li
                key={idx}
                className={`flex items-center font-semibold  hover:opacity-90 hover:shadow-lg transition-all duration-200 border  border-primary rounded group h-[52px] cursor-pointer`}
                style={{
                  background: getTintFromHex(color || COLORS.primary, 92),
                  border: `1px solid ${color}`,
                }}
                onClick={() => {
                  console.log("data to add", dataToAdd);
                  addSchema(dataToAdd);
                }}
                draggable
                onDragStart={(e) => {
                  onDragStartHandle(e, {
                    data: dataToAdd,
                    type: elem?.functionalData?.type,
                    size: elem?.functionalData?.size,
                  });
                }}
              >
                {/* {elem?.showData?.icon} */}
                <div
                  className=" h-[100%] rounded-l w-[0px] group-hover:w-[24px] overflow-hidden transition-all duration-200 flex items-center"
                  style={{
                    background: getTintFromHex(color || COLORS.primary, 75),
                  }}
                >
                  <RxDragHandleDots2
                    size={20}
                    style={{
                      color: color,
                    }}
                  />
                </div>
                <div className="flex h-full flex-1 items-center justify-between  gap-1 px-3">
                  <span className="text-gray-700">{elem?.showData?.name}</span>
                  <span className="h-6 w-6 flex justify-center items-center group-hover:h-0 group-hover:w-0 transition-all duration-200">
                    <Icon
                      size={20}
                      className={`text-primary rounded bg-secondary `}
                      style={{
                        color: color,
                        background: getTintFromHex(color, 92),
                      }}
                    />
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  // To display the list of contact fields below the default fields
  const CustomFieldsList = ({ color, selectedRole, customFields }: any) => {
    let fieldsArray: any = [];
    const fieldsList = customFields || [];
    fieldsList.forEach((field: any) => {
      let subType = "contact_field";
      const isDefaultField = defaultContactFields.find(
        (value) => value?.field_name === field?.field_name
      );
      if (!isDefaultField) {
        subType = "custom_field";
      }
      let obj = {
        functionalData: {
          data: {
            title: field?.field_name,
            type: "text",
            id: v4(),
            required: false,
            subType: subType,
            role: selectedRole,
            contactFieldName: field?.field_name, // Unique value , work as key
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.isFormMapper.isNotSignature.width,
            height: SIZES.isFormMapper.isNotSignature.height,
          },
        },
        showData: {
          name: field?.field_name,
          iconSvg: TbBracketsContain,
        },
      };
      fieldsArray.push(obj);
    });

    return (
      <div className="mb-5">
        <ul className="text-xs grid grid-cols-2 gap-2">
          {fieldsArray?.map((elem: any, idx: any) => {
            let dataToAdd = {};
            if (designerState?.instanceFrom == "PDF-TEMPLATE") {
              dataToAdd = { ...elem?.functionalData?.data, id: v4() };
            }
            if (designerState?.instanceFrom == "PDF") {
              let data = {
                // ...elem?.functionalData?.data,
                ...selectedRole,
                title: elem?.functionalData?.data?.title,
                type: elem?.functionalData?.data?.type,
                contactFieldName: elem?.functionalData?.data?.contactFieldName,
                subType: elem?.functionalData?.data?.subType,
                role: {
                  title: selectedRole?.name,
                  colour: selectedRole?.colour,
                },
              };
              delete data.id;
              dataToAdd = data;
            }
            const Icon = elem?.showData?.iconSvg;
            return (
              <li
                key={idx}
                className={`flex items-center font-semibold  hover:opacity-90 hover:shadow-lg transition-all duration-200 border  border-primary rounded group h-[52px] cursor-pointer`}
                style={{
                  background: getTintFromHex(color || COLORS.primary, 92),
                  border: `1px solid ${color}`,
                }}
                onClick={() => {
                  console.log("data to add", dataToAdd);
                  addSchema(dataToAdd);
                }}
                draggable
                onDragStart={(e) => {
                  onDragStartHandle(e, {
                    data: dataToAdd,
                    type: elem?.functionalData?.type,
                    size: elem?.functionalData?.size,
                  });
                }}
              >
                <div
                  className=" h-[100%] rounded-l w-[0px] group-hover:w-[24px] overflow-hidden transition-all duration-200 flex items-center"
                  style={{
                    background: getTintFromHex(color || COLORS.primary, 75),
                  }}
                >
                  <RxDragHandleDots2
                    size={20}
                    style={{
                      color: color,
                    }}
                  />
                </div>
                <div className="flex h-full flex-1 items-center justify-between  gap-1 px-3">
                  <span className="text-gray-700 ">
                    {convertToTitleCase(elem?.showData?.name)}
                  </span>
                  <span className="h-6 w-6 flex justify-center items-center group-hover:h-0 group-hover:w-0 transition-all duration-200">
                    <Icon
                      size={20}
                      className={`text-primary rounded bg-secondary `}
                      style={{
                        color: color,
                        background: getTintFromHex(color, 92),
                      }}
                    />
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const SetOfBlocks = ({ color, selectedRole }: any) => {
    const fieldsArray = [
      {
        functionalData: {
          data: {
            title: "Text",
            type: "text",
            id: v4(),
            required: false,
            subType: "text",
            // role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.isFormMapper.isNotSignature.width,
            height: SIZES.isFormMapper.isNotSignature.height,
          },
        },
        showData: {
          iconSvg: MdTextFields,
          // icon: (
          //   <MdTextFields
          //     size={20}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{ color: color, background: getTintFromHex(color, 85) }}
          //   />
          // ),
          name: "Text",
        },
      },
      {
        functionalData: {
          data: {
            title: "Image",
            type: "image",
            id: v4(),
            required: false,
            subType: "static_image",
            // role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.isFormMapper.isSignature.width,
            height: SIZES.isFormMapper.isSignature.height,
          },
        },
        showData: {
          iconSvg: MdImage,
          // icon: (
          //   <MdImage
          //     size={25}
          //     className={`text-primary h-8 w-8 rounded-md p-1 bg-secondary group-hover:h-0 group-hover:w-0`}
          //     style={{
          //       color: color,
          //       background: getTintFromHex(color, 85),
          //     }}
          //   />
          // ),
          name: "Image",
        },
      },
      {
        functionalData: {
          data: {
            title: "Table",
            type: "table",
            id: v4(),
            required: false,
            subType: "table",
            // role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.table.width,
            height: SIZES.table.height,
          },
        },
        showData: {
          iconSvg: FaTable,
          name: "Table",
        },
      },
      {
        functionalData: {
          data: {
            title: "Date",
            type: "date",
            id: v4(),
            required: false,
            subType: "date",
            // role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.isFormMapper.isNotSignature.width,
            height: SIZES.isFormMapper.isNotSignature.height,
          },
        },
        showData: {
          iconSvg: AiOutlineCalendar,
          name: "Date",
        },
      },
      {
        functionalData: {
          data: {
            title: "PricingTable",
            type: "table",
            id: v4(),
            required: false,
            subType: "pricing_table",
            // role: selectedRole,
          },
          type: "PDF_TEMPLATE",
          size: {
            width: SIZES.pricing_table.width,
            height: SIZES.pricing_table.height,
          },
        },
        showData: {
          iconSvg: MdOutlinePriceChange,
          name: "Pricing Table",
        },
      },
      // {
      //   functionalData: {
      //     data: {
      //       title: "Signature",
      //       type: "Signature",
      //       id: v4(),
      //       required: false,
      //       subType: "static_signature",
      //       // role: selectedRole,
      //     },
      //     type: "PDF_TEMPLATE",
      //     size: {
      //       width: SIZES.isFormMapper.isSignature.width,
      //       height: SIZES.isFormMapper.isSignature.height,
      //     },
      //   },
      //   showData: {
      //     iconSvg: PiSignature,
      //     name: "My Signature",
      //   },
      // },
    ];

    return (
      <div className="">
        <ul className="text-xs cursor-pointer grid grid-cols-2 gap-2">
          {fieldsArray?.map((elem, idx) => {
            let dataToAdd: any = {};
            if (
              designerState?.instanceFrom == "PDF-TEMPLATE" &&
              elem?.functionalData?.data?.subType === "static_signature"
            ) {
              return null;
            }
            if (designerState?.instanceFrom == "PDF-TEMPLATE") {
              dataToAdd = { ...elem?.functionalData?.data, id: v4() };
            }
            if (designerState?.instanceFrom == "PDF") {
              let data = {
                // ...elem?.functionalData?.data,
                // ...selectedRole,
                title: elem?.functionalData?.data?.title,
                type: elem?.functionalData?.data?.type,
                subType: elem?.functionalData?.data?.subType,
                // role: {
                //   title: selectedRole?.name,
                //   colour: selectedRole?.colour,
                // },
              };
              // delete data.id;
              dataToAdd = data;
            }
            dataToAdd.isStatic = true;

            const Icon = elem?.showData?.iconSvg;

            return (
              <li
                key={idx}
                className={`flex items-center font-semibold space-x-4 hover:opacity-90 hover:shadow-lg transition-all duration-200 border  border-primary rounded group h-[52px]`}
                style={{
                  background: getTintFromHex(color || COLORS.primary, 85),
                  border: `1px solid ${color}`,
                }}
                onClick={() => {
                  addSchema(dataToAdd);
                }}
                draggable
                onDragStart={(e) => {
                  onDragStartHandle(e, {
                    data: dataToAdd,
                    type: elem?.functionalData?.type,
                    size: elem?.functionalData?.size,
                  });
                }}
              >
                {/* {elem?.showData?.icon} */}
                <div
                  className=" h-[100%] rounded-l w-[0px] group-hover:w-[24px] overflow-hidden transition-all duration-200 flex items-center"
                  style={{
                    background: getTintFromHex(color || COLORS.primary, 55),
                  }}
                >
                  <RxDragHandleDots2
                    size={20}
                    className="text-gray-500"
                    // style={{
                    //   color: color,
                    // }}
                  />
                </div>
                <div className="flex h-full w-[100%] items-center justify-between p-2">
                  <span className="text-gray-700">{elem?.showData?.name}</span>
                  <Icon
                    size={20}
                    className={`text-primary h-8 w-8 rounded p-1 bg-secondary group-hover:h-0 group-hover:w-0 transition-all duration-200`}
                    style={{
                      color: color,
                      background: getTintFromHex(color, 92),
                    }}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="flex h-[100%] max-h-[100%] justify-between flex-col w-[100%]">
      <div className="h-[100%]">
        <div className="px-4">
          <ViewHeader name="Content" open={open} setOpen={setOpen} />
        </div>
        <div
          className="px-4 max-h-[calc(100%_-_80px)] min-h-[calc(100%_-_80px)] overflow-y-auto"
          id="mini-pdf-custom-scroll"
        >
          <div className="mb-6">
            <p className="text-gray-500 text-sm pr-4 mb-2 ml-1 uppercase">
              Blocks
            </p>
            <SetOfBlocks
              color={STATIC_SCHEMA.color}
              selectedRole={{
                ...designerState?.selectedRole,
              }}
            />
          </div>
          <div>
            <p className="text-gray-500 text-sm pr-4 mb-2 ml-1 uppercase">
              Fillable fields for
            </p>
            <div className="relative">
              {isShowDropDown && (
                <div className="absolute mt-11 border rounded w-full cursor-pointer overflow-hidden shadow-lg">
                  <div className="flex flex-col gap-2 py-1 items-start text-sm bg-white">
                    {(designerState?.instanceFrom == "PDF"
                      ? designerState?.receiversList
                      : designerState?.roles
                    )?.map((item: any, index: any) => {
                      return (
                        <div
                          className="flex items-center justify-between p-2 hover:bg-gray-50 w-full bg-white"
                          onClick={() => {
                            globalHelpers.setSelectedRole(item);
                            setIsShowDropDown(false);
                          }}
                        >
                          <div className="flex gap-2">
                            <div
                              className="w-5 h-5 rounded-[3px]"
                              style={{ background: item.colour }}
                            ></div>
                            <span className="text-gray-700">
                              {designerState?.instanceFrom == "PDF"
                                ? item?.name
                                : item?.title}
                              {console.log(item)}
                              {designerState?.instanceFrom == "PDF" &&
                              item?.email?.toLowerCase() ===
                                owner?.email?.toLowerCase()
                                ? " (you)"
                                : ""}
                            </span>
                          </div>
                          {((designerState?.selectedRole?.title ===
                            item?.title &&
                            designerState?.instanceFrom == "PDF-TEMPLATE") ||
                            (designerState?.selectedRole?.name === item?.name &&
                              designerState?.instanceFrom == "PDF")) && (
                            <BsCheck2 size={20} className="text-primary" />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {(designerState?.instanceFrom == "PDF"
                ? designerState?.receiversList
                : designerState?.roles) && (
                <div
                  className={`mb-4 bg-white flex items-center justify-between w-full py-2.5 px-3 cursor-pointer border text-sm text-gray-800 rounded overflow-hidden`}
                  onClick={() => {
                    setIsShowDropDown(!isShowDropDown);
                  }}
                >
                  <div className="flex items-center justify-center gap-2">
                    <div
                      className="w-4 h-4 rounded-[3px]"
                      style={{
                        background: designerState?.selectedRole?.colour,
                      }}
                    ></div>
                    <span className="text-gray-700 text-sm">
                      {designerState?.instanceFrom == "PDF"
                        ? designerState?.selectedRole?.name
                        : designerState?.selectedRole?.title}{" "}
                      {designerState?.instanceFrom == "PDF" &&
                      designerState?.selectedRole?.email?.toLowerCase() ===
                        owner?.email?.toLowerCase()
                        ? " (you)"
                        : ""}
                    </span>
                  </div>
                  {isShowDropDown ? (
                    <BsChevronUp size={15} className="" />
                  ) : (
                    <BsChevronDown size={15} className="" />
                  )}
                </div>
              )}
            </div>
            <SetOfFields
              color={designerState?.selectedRole?.colour}
              selectedRole={designerState?.selectedRole}
            />
            <p className="text-gray-500 text-sm pr-4 mt-5 mb-3 ml-1 uppercase">
              Contact / Custom Fields
            </p>
            <CustomFieldsList
              color={designerState?.selectedRole?.colour}
              selectedRole={designerState?.selectedRole}
              customFields={customFields}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentView;
