import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";
import { InfinitySpin } from "react-loader-spinner";
import { useAppSelector } from "../../redux/store";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import AccessCodeVerify from "../../components/AccessCodeVerify";
import { BASE_URL } from "../../constants/constants";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";

const MailInvite = () => {
  const [searchParams] = useSearchParams();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const key = searchParams.get("key");
  const SigningInPerson = searchParams.get("SigningInPerson");

  const [accessKeyInput, setAccessKeyInput] = useState("");
  const [verifying, setVerifying] = useState(false);

  const [isAccessCodeNeeded, setIsAccessCodeNeeded] = useState(false);

  const [verifyClicked, setVerifyClicked] = useState(false);
  const [wrongAccessCode, setWrongAccessCode] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const createSigningLog = async () => {
    if (!key) {
      return;
    }

    if (isAccessCodeNeeded && accessKeyInput === "") {
      return;
    }
    if (isAccessCodeNeeded && accessKeyInput !== "") {
      setVerifying(true);
    }

    const ipResponse = getIpAddressLocal();
    const ipData = await ipResponse;
    const { data } = await axios.get(
      `/signingLog/resolveMailInvite?key=${key}&ip=${ipData.ip}&accessKeyInput=${accessKeyInput}`
    );
    console.log(data);
    if (data?.redirect) {
      console.log(data);
      setVerifying(false);
      setIsVerified(true);
      if (SigningInPerson === "1") {
        window.open(data?.redirect + "&SigningInPerson=1", "_self");
      } else {
        window.open(data?.redirect, "_self");
      }
    } else if (data?.isAccessCodeNeeded) {
      setVerifying(false);
      setIsAccessCodeNeeded(true);
    } else if (data?.incorrectAccessCode) {
      setVerifying(false);
      setWrongAccessCode(true);
    }
  };

  useEffect(() => {
    setWrongAccessCode(false);
  }, [accessKeyInput]);

  useEffect(() => {
    createSigningLog();
  }, [key, verifyClicked]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      {!isAccessCodeNeeded && <BoloLoader />}

      {isAccessCodeNeeded && (
        <AccessCodeVerify
          accessKeyInput={accessKeyInput}
          setAccessKeyInput={setAccessKeyInput}
          setVerifyClicked={setVerifyClicked}
          verifying={verifying}
          wrongAccessCode={wrongAccessCode}
          isVerified={isVerified}
        />
      )}
    </div>
  );
};

export default MailInvite;
