import React from "react";

import { RxCross2 } from "react-icons/rx";

const FeatureInfoPopup = ({ image, close }) => (
  <div
    className="fixed top-0 left-0 w-screen h-screen backdrop-blur-md bg-black/20 grid place-items-center z-[9999]"
    onClick={() => close()}
  >
    <div
      className="relative max-w-screen-md rounded-xl overflow-hidden m-2"
      onClick={(e) => e.stopPropagation()}
    >
      <img src={image} alt={image} width={1000} height={400} />

      <button
        className="absolute text-lg md:text-2xl top-1 right-1 md:top-2 md:right-2 text-black p-2 rounded-full hover:bg-gray-200 hover:text-gray-800 transition-colors duration-300 ease-in-out"
        onClick={() => close()}
      >
        <RxCross2 />
      </button>
    </div>
  </div>
);

export default FeatureInfoPopup;
