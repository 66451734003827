import { RefObject, useEffect, useRef, useState } from "react";
import { MdOutlineClose, MdDone, MdClose } from "react-icons/md";
import { Color } from "../../entities";
import { ToastContainer, toast, Slide } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { BsCardImage, BsUpload } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { toPng } from "html-to-image";
import { getDashboardUrlV1 } from "../../constants/constants";
import "./allFonts.css";
import { PreviewStateType } from "../../pdfme/ui/src/components/Preview";
import styled from "styled-components";
// import { useAppSelector } from "../../redux/store";
import { getWhiteLabelInfoLocal } from "../../utils";
import { SIGNATURE_RATIO } from "../../pdfme/ui/src/constants";
import { SignatureType } from "../../pdfme/ui/src/components/Designer/PdfTemplateSidebar/SettingsView";
import { checkAndSetEnabledKey, getTintFromHex } from "../../utils/uiUtils";
import { BoloButton } from "../Common/Button/BoloButton";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Common/BoloSelect/BoloSelect";
import {
  BoloTab,
  BoloTabSwitcher,
} from "../Common/BoloTabSwitcher/BoloTabSwitcher";
import { height, width } from "@mui/system";

interface Props {
  open: boolean;
  dismiss: () => void;
  confirm: (drawing: string) => void; // Updated type for confirm function
  drawing?: DrawingAttachment;
  isDefaultSignatureTemplate?: boolean;
  isStamp?: boolean;
  imageType?: "initial" | "stamp" | "signature";
  previewState?: PreviewStateType;
  signatureSettingType?: SignatureType;
}

const fontNames = [
  "font115",
  "font2",
  "font91",
  "font92",
  "font3",
  "font116",
  "font117",
  "font118",
  "font119",
  "font4",
  "font1",
  "font5",
  "font6",
  "font7",
  "font8",
  "font9",
  "font10",
  "font11",
  "font12",
  "font13",
  "font14",
  "font15",
  "font16",
  "font17",
  "font18",
  "font19",
  "font20",
  "font21",
  "font22",
  "font23",
  "font24",
  "font25",
  "font26",
  "font27",
  "font28",
  "font29",
  "font30",
  "font31",
  "font32",
  "font33",
  "font34",
  "font35",
  "font36",
  "font37",
  "font38",
  "font39",
  "font40",
  "font41",
  "font42",
  "font43",
  "font44",
  "font45",
  "font46",
  "font47",
  "font48",
  "font49",
  "font50",
  "font51",
  "font52",
  "font53",
  "font54",
  "font55",
  "font56",
  "font57",
  "font58",
  "font59",
  "font60",
  "font61",
  "font62",
  "font63",
  "font64",
  "font65",
  "font66",
  "font67",
  "font68",
  "font69",
  "font70",
  "font71",
  "font72",
  "font73",
  "font74",
  "font75",
  "font76",
  "font77",
  "font78",
  "font79",
  "font80",
  "font81",
  "font82",
  "font83",
  "font84",
  "font85",
  "font86",
  "font87",
  "font88",
  "font89",
  "font90",
  "font93",
  "font94",
  "font95",
  "font96",
  "font97",
  "font98",
  "font99",
  "font100",
  "font101",
  "font103",
  "font105",
  "font106",
  "font107",
  "font108",
  "font109",
  "font110",
  "font111",
  "font112",
  "font113",
  "font114",
  "font120",
  "font121",
];

const SignatureTemplateModal = ({
  open,
  dismiss,
  confirm,
  drawing,
  isDefaultSignatureTemplate,
  isStamp,
  imageType,
  previewState,
  signatureSettingType,
}: Props) => {
  // const whiteLabelInfo = useAppSelector(
  //   (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  // );
  const getHeader = () => {
    switch (imageType) {
      case "initial":
        return "Initials";
      case "stamp":
        return "Stamp";
      default:
        return "Signature";
    }
  };

  const getAction = () => {
    switch (imageType) {
      case "initial":
        return "Confirm";
      case "stamp":
        return "Stamp";
      default:
        return "Sign";
    }
  };

  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const [strokeWidth, setStrokeWidth] = useState(3);
  const [stroke, setStroke] = useState(Color.BLACK);
  const [strokeDropdownOpen, setStrokeDropdownOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("draw");
  const [file, setFile] = useState<any>();
  const [signatureText, setSignatureText] = useState(getHeader());
  const [penColor, setPenColor] = useState<string>(Color.BLACK);
  const [showModal, setShowModal] = useState(false);
  const [currentFont, setCurrentFont] = useState<string>("font115");
  const [fontSize, setFontSize] = useState(65);
  const [confirmText, setConfirmText] = useState(`Accept and ${getAction()}`);
  const signatureBox = useRef<HTMLInputElement | HTMLElement | null>(null);
  const [primaryBtn, setPrimaryBtn] = useState<React.CSSProperties>({});
  const [secondaryBtn, setSecondaryBtn] = useState<React.CSSProperties>({});
  const [signingTypeEnabled, setSigningTypeEnabled] = useState<boolean>(false);
  const StyledInputParent = styled.div``;
  const StyledLable = styled.label`
    &:hover {
      background: ${previewState?.fetchedData?.accountInfo?.themeLight};
      border: 2px dashed ${previewState?.fetchedData?.accountInfo?.theme};
      color: ${previewState?.fetchedData?.accountInfo?.theme};
    }

    .file-input-parent:focus-within & {
      background: ${previewState?.fetchedData?.accountInfo?.themeLight};
      border: 2px dashed ${previewState?.fetchedData?.accountInfo?.theme};
      color: ${previewState?.fetchedData?.accountInfo?.theme};
    }
  `;
  const [owner, setOwner] = useState(
    JSON.parse(localStorage.getItem("owner") || "{}")
  );
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [tabIdxData, setTabIdxData] = useState<{
    idx: { [key: string]: { cancel: number; procced: number } };
    ref: { [key: string]: RefObject<HTMLButtonElement> };
  }>({
    idx: {
      draw: { cancel: -1, procced: -1 },
      upload: { cancel: -1, procced: -1 },
      type: { cancel: -1, procced: -1 },
      saved: { cancel: -1, procced: -1 },
    },
    ref: {
      draw: useRef(null),
      upload: useRef(null),
      type: useRef(null),
      saved: useRef(null),
    },
  });

  const getSaved = () => {
    switch (imageType) {
      case "initial":
        return owner?.defaultInitial;
      case "stamp":
        return owner?.defaultStamp;
      default:
        return owner?.defaultSignature;
    }
  };

  const createCanvas = async () => {
    let width = signatureBox?.current?.getBoundingClientRect()?.width;
    let height = signatureBox?.current?.getBoundingClientRect()?.height;

    const canvas = canvasRef.current;
    if (!canvas) {
      throw new Error("Canvas not found");
    }
    const ctx = canvas.getContext("2d");

    // Set canvas size
    canvas.width = width || 600;
    canvas.height = height || 200;
    if (!ctx) {
      throw new Error("ctx not found");
    }
    // Set background color
    ctx.imageSmoothingQuality = "high";
    ctx.fillStyle = `#ffffff00`;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // Set the font and text color
    ctx.font = `${fontSize}px ${currentFont}`;
    ctx.fillStyle = penColor;

    // Calculate the position to center the text
    const textWidth = ctx.measureText(signatureText).width;
    const x = (canvas.width - textWidth) / 2;
    const y = canvas.height / 2 + fontSize / 2;
    console.log({ x, y, textWidth });
    // Draw the text
    ctx.fillText(signatureText, x, y);

    // Convert canvas to image
    const dataURL = canvas.toDataURL("image/png");
    return dataURL;
  };
  // This is specific for type signature
  async function handleConfirm() {
    try {
      setConfirmText("Signing ...");
      if (signatureBox.current === null) {
        return;
      }
      const isMacOrApple = /Macintosh|iPhone|iPad/.test(navigator.userAgent);
      let dataUrl = "";
      if (isMacOrApple) {
        dataUrl = (await createCanvas()) || "";
      } else {
        dataUrl = await toPng(signatureBox.current, { cacheBust: true });
      }
      if (dataUrl === "") {
        throw new Error("Failed to create canvas");
      }
      // Only add signature type in fixed canvas
      if (imageType === "signature") {
        confirmCanvas(dataUrl);
      } else {
        confirm(dataUrl);
      }
      console.log(dataUrl);
      // const link = document.createElement("a");
      // link.download = "signature.png";
      // link.href = dataUrl;
      // link.click();
      setConfirmText("Confirm");
      closeModal();
    } catch (err) {
      toast.error("Something went wrong !");
      console.log("Error", err);
      console.log("Save Error: ");
    }
  }

  const openModal = (font: string) => {
    if (signatureText === "") {
      return;
    }
    setShowModal(true);
    setCurrentFont(font);
  };

  const signaturePad = useRef<any>(null);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset overflow on component unmount
    };
  }, [open]);

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab);
  };

  const handleDone = () => {
    // @ts-ignore
    const drawnImage = signaturePad.current.toDataURL("image/png");

    if (signaturePad.current && signaturePad.current.isEmpty()) {
      return toast.error(`${getHeader()} Empty`);
    }

    // Only add signature type in fixed canvas
    if (imageType === "signature") {
      confirmCanvas(drawnImage);
    } else {
      confirm(drawnImage);
    }
    closeModal();
  };

  const closeModal = () => {
    //@ts-ignore
    signaturePad.current?.clear();
    dismiss();
  };

  const strokeSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleColorSelect = (color: Color) => {
    setStroke(color);
    setPenColor(color);
  };

  const handleStrokeWidthSelect = (size: number) => {
    setStrokeWidth(size);
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size > 4 * 1024 * 1024) {
      toast.error("File should be no larger than 4mb");
      return;
    }
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // @ts-ignore
        const dataUrl = e.target?.result; // Data URL of the uploaded file
        setFile({ file: selectedFile, dataUrl }); // Store the file and its data URL
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUseDefault = async () => {
    try {
      const signatureUrl = getSaved();

      if (!signatureUrl) {
        throw new Error("No default signature found");
      }

      const response = await fetch(signatureUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch signature image (${response.status})`);
      }

      const blob = await response.blob();
      const base64data = await blobToBase64(blob);
      // Only add signature type in fixed canvas
      if (imageType === "signature") {
        confirmCanvas(base64data as string);
      } else {
        confirm(base64data as string);
      }

      closeModal();
    } catch (error: any) {
      console.error("Error:", error);
      toast.error(error.message);
      // Handle the error here
    }
  };

  async function blobToBase64(blob: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const handleDrop = (e: any) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    if (
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpg" ||
      selectedFile.type === "image/jpeg"
    ) {
      if (selectedFile && selectedFile.size > 4 * 1024 * 1024) {
        toast.error("File should be no larger than 4mb");
        return;
      }
      if (selectedFile) {
        const reader = new FileReader();

        reader.onload = (e) => {
          // @ts-ignore
          const dataUrl = e.target?.result; // Data URL of the uploaded file
          setFile({ file: selectedFile, dataUrl }); // Store the file and its data URL
        };

        reader.readAsDataURL(selectedFile);
      }
    } else {
      toast.error("Only jpg, png and jpeg files accecpted");
    }
  };

  useEffect(() => {
    if (isStamp) setCurrentTab("upload");
    let checkSigningTypeEnabled = checkAndSetEnabledKey(signatureSettingType);
    setSigningTypeEnabled(checkSigningTypeEnabled);
  }, []);

  useEffect(() => {
    setPrimaryBtn({
      color: previewState?.fetchedData.accountInfo?.themeText,
      background: previewState?.fetchedData.accountInfo?.theme,
      border: previewState?.fetchedData.accountInfo?.theme
        ? `2px solid ${previewState?.fetchedData.accountInfo?.theme}`
        : "",
    });
    setSecondaryBtn({
      color: previewState?.fetchedData.accountInfo?.theme,
      // background: previewState?.fetchedData.accountInfo?.themeLight,
      // border: previewState?.fetchedData.accountInfo?.theme
      //   ? `2px solid ${previewState?.fetchedData.accountInfo?.theme}`
      //   : "",
    });
  }, [previewState?.fetchedData.accountInfo]);

  useEffect(() => {
    if (currentTab && tabIdxData.ref[currentTab].current) {
      // ! HACK_FIX: Not ideal behaviour
      setTimeout(() => {
        tabIdxData?.ref?.[currentTab]?.current?.focus();
      }, 300);
    }
  }, [tabIdxData, currentTab]);

  // This function sets a standard canvas width and height for any type of image
  // while maintaining the aspect ratio of the original image.
  const confirmCanvas = (dataUrl: any) => {
    const canvas = document.createElement("canvas");
    canvas.width = SIGNATURE_RATIO.canvasWidth;
    canvas.height = SIGNATURE_RATIO.canvasHeight;
    const ctx: any = canvas.getContext("2d");

    const img = new Image();
    img.src = dataUrl;

    img.onload = function () {
      const aspectRatio = SIGNATURE_RATIO.aspectRatio;
      let newWidth, newHeight, xOffset, yOffset;

      if (img.width / img.height > aspectRatio) {
        newWidth = canvas.width;
        newHeight = (newWidth / img.width) * img.height;
        xOffset = 0;
        yOffset = (canvas.height - newHeight) / 2;
      } else {
        newHeight = canvas.height;
        newWidth = (newHeight / img.height) * img.width;
        yOffset = 0;
        xOffset = (canvas.width - newWidth) / 2;
      }

      ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);

      confirm(canvas.toDataURL());
    };
  };

  function getTabIndex(): number {
    const indexMap: { [key: string]: number } = {
      draw: 0,
      // upload: 1,
      // type: 2,
      // saved: 3,
    };
    let counter = 0;
    if (signatureSettingType?.image) {
      counter++;
      indexMap.upload = counter;
    }
    if (signatureSettingType?.typing) {
      counter++;
      indexMap.type = counter;
    }
    if (!checkAndSetEnabledKey(signatureSettingType)) {
      counter++;
      indexMap.saved = counter;
    }
    const indexMapStamp: { [key: string]: number } = {
      upload: 0,
      saved: 1,
    };
    switch (imageType) {
      case "initial":
        return indexMap[currentTab] || 0;
      case "stamp":
        return indexMapStamp[currentTab] || 0;
      default:
        return indexMap[currentTab] || 0;
    }
  }

  function getTabCountAndWidth(): { count: number; width: number } {
    let sigC = 1;
    let staC = 2;
    if (signatureSettingType?.image) {
      sigC++;
    }
    if (signatureSettingType?.typing) {
      sigC++;
    }
    if (!checkAndSetEnabledKey(signatureSettingType)) {
      sigC++;
    }
    if (isDefaultSignatureTemplate) {
      sigC--;
      staC--;
    }
    switch (imageType) {
      case "initial":
        return { count: sigC, width: 100 / sigC };
      case "stamp":
        return { count: staC, width: 100 / staC };
      default:
        return { count: sigC, width: 100 / sigC };
    }
  }

  function TermsAndConditions() {
    return (
      <p className="text-[11px] text-gray-500">
        By electronically signing this document, I agree that my signature and
        initials are the equivalent of my handwritten signature and are
        considered originals on all documents, including legally binding
        contracts. I also agree to the{" "}
        <a
          className="text-primary cursor-pointer"
          style={{ color: previewState?.fetchedData?.accountInfo?.theme }}
          target={"_blank"}
          href={
            whiteLabelInfo?.settings?.organization?.policy?.tos ||
            "https://www.boloforms.com/terms-of-service/"
          }
        >
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          className="text-primary cursor-pointer "
          style={{ color: previewState?.fetchedData?.accountInfo?.theme }}
          target={"_blank"}
          href={
            whiteLabelInfo?.settings?.organization?.policy?.privacy ||
            "https://www.boloforms.com/privacy-policy/"
          }
        >
          Privacy Policy
        </a>
        .
      </p>
    );
  }

  function PenColorSelector() {
    return (
      <div className="flex h-6 items-center gap-3">
        <button
          data-pencolor={stroke}
          className={`h-4 w-4 data-[pencolor=black]:h-6 data-[pencolor=black]:w-6 rounded-full cursor-pointer flex items-center justify-center transition-all duration-300`}
          style={{ background: Color.BLACK }}
          onClick={() => handleColorSelect(Color.BLACK)}
        >
          {stroke == Color.BLACK && (
            <MdDone size={17} className="text-white " />
          )}
        </button>
        <button
          data-pencolor={stroke}
          className={`h-4 w-4 data-[pencolor=blue]:h-6 data-[pencolor=blue]:w-6 rounded-full cursor-pointer flex items-center justify-center transition-all duration-300`}
          style={{ background: Color.BLUE }}
          onClick={() => handleColorSelect(Color.BLUE)}
        >
          {stroke == Color.BLUE && <MdDone size={17} className="text-white " />}
        </button>
        <button
          data-pencolor={stroke}
          className={`h-4 w-4 data-[pencolor=red]:h-6 data-[pencolor=red]:w-6 rounded-full cursor-pointer flex items-center justify-center transition-all duration-300`}
          style={{ background: Color.RED }}
          onClick={() => handleColorSelect(Color.RED)}
        >
          {stroke == Color.RED && <MdDone size={17} className="text-white " />}
        </button>
        <button
          data-pencolor={stroke}
          className={`h-4 w-4 data-[pencolor=green]:h-6 data-[pencolor=green]:w-6 rounded-full cursor-pointer flex items-center justify-center transition-all duration-300`}
          style={{ background: Color.GREEN }}
          onClick={() => handleColorSelect(Color.GREEN)}
        >
          {stroke == Color.GREEN && (
            <MdDone size={17} className="text-white " />
          )}
        </button>
      </div>
    );
  }

  return (
    // <div className={`fixed inset-0 h-screen w-screen justify-center items-center z-[99999] ${open ? "flex" : "hidden"}`}>
    //   <div className="absolute inset-0 bg-black opacity-75"></div>
    <div className="relative bg-white p-0 w-[95vw] sm:w-[600px] rounded-sm md:rounded h-fit">
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      <div className="p-4 pb-0 shadow-md">
        <div className="flex justify-between items-center pb-3">
          <h2 className="text font-semibold text-gray-800">
            Add your {getHeader()}
          </h2>
          <button
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={closeModal}
          >
            <MdOutlineClose size={30} className="" />
          </button>
        </div>
        <div className="flex gap-2  text-xs">
          <BoloTabSwitcher
            value={currentTab}
            className="w-full"
            activeColor={previewState?.fetchedData?.accountInfo?.theme}
          >
            {!isStamp && (
              <BoloTab
                value={"draw"}
                size={"sm"}
                className={`w-full whitespace-nowrap text-xs`}
              >
                <button
                  className={`px-3 py-2 w-[100%] ${
                    currentTab === "draw"
                      ? "text-gray-800 font-semibold"
                      : "text-gray-400"
                  }`}
                  onClick={() => handleTabChange("draw")}
                >
                  Draw
                </button>
              </BoloTab>
            )}
            <BoloTab
              value={"upload"}
              size={"sm"}
              className={`w-full whitespace-nowrap text-xs ${
                !isStamp && signingTypeEnabled && !signatureSettingType?.image
                  ? "hidden"
                  : ""
              }`}
            >
              <button
                className={`px-3 py-2 w-[100%] ${
                  currentTab === "upload"
                    ? "text-gray-800 font-semibold"
                    : "text-gray-400"
                } ${
                  !isStamp && signingTypeEnabled && !signatureSettingType?.image
                    ? "hidden"
                    : ""
                }`}
                onClick={() => handleTabChange("upload")}
              >
                Upload Image
              </button>
            </BoloTab>
            {!isStamp && (
              <BoloTab
                value={"type"}
                size={"sm"}
                className={`w-full whitespace-nowrap text-xs ${
                  signingTypeEnabled && !signatureSettingType?.typing
                    ? "hidden"
                    : ""
                }`}
              >
                <button
                  className={`px-3 py-2 w-[100%] ${
                    currentTab === "type"
                      ? "text-gray-800 font-semibold"
                      : "text-gray-400"
                  } ${
                    signingTypeEnabled && !signatureSettingType?.typing
                      ? "hidden"
                      : ""
                  } `}
                  onClick={() => handleTabChange("type")}
                >
                  Type
                </button>
              </BoloTab>
            )}
            {!isDefaultSignatureTemplate && (
              <BoloTab
                value={"saved"}
                size={"sm"}
                className={`w-full whitespace-nowrap text-xs ${
                  signingTypeEnabled && !isStamp ? "hidden" : ""
                }`}
              >
                <button
                  className={`px-3 py-2 w-[100%] ${
                    currentTab === "saved"
                      ? "text-gray-800 font-semibold"
                      : "text-gray-400"
                  } ${signingTypeEnabled && !isStamp ? "hidden" : ""}`}
                  onClick={() => handleTabChange("saved")}
                >
                  Saved
                </button>
              </BoloTab>
            )}
          </BoloTabSwitcher>
        </div>
        {/* <div className="relative">
          <div
            className="border-b-[3px] rounded border-primary absolute bottom-0 transition-all duration-300"
            style={{
              width: `calc(100% / ${getTabCountAndWidth().count})`,
              left: `${getTabCountAndWidth().width * getTabIndex()}%`,
              borderColor: previewState?.fetchedData?.accountInfo?.theme,
            }}
          ></div>
        </div> */}
      </div>
      <div className="">
        <div className="rounded h-[400px]">
          {currentTab === "upload" && (
            <div className="flex flex-col justify-between h-full p-4">
              <div
                className="flex items-center w-full justify-between group file-input-parent"
                style={
                  {
                    "--acc-info-theme-light":
                      previewState?.fetchedData?.accountInfo?.themeLight,
                    "--acc-info-theme":
                      previewState?.fetchedData?.accountInfo?.theme,
                  } as React.CSSProperties
                }
              >
                <StyledLable
                  htmlFor={`template-upload`}
                  className={`file-input-parent flex flex-col items-center justify-center p-8 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-secondary/80 hover:border-primary/80 w-full text-gray-600 hover:text-primary transition-all ease-in-out duration-150 ${
                    file ? "bg-secondary/80 border-primary/80" : ""
                  } `}
                  style={{
                    borderColor: file
                      ? previewState?.fetchedData?.accountInfo?.theme
                      : "",
                    background: file
                      ? previewState?.fetchedData?.accountInfo?.theme
                        ? getTintFromHex(
                            previewState?.fetchedData?.accountInfo?.theme,
                            85
                          )
                        : ""
                      : "",
                  }}
                  onDrop={handleDrop}
                  onDragOver={(e) => {
                    // Handle drag over event if needed
                    e.preventDefault();
                  }}
                >
                  {!file && (
                    <div className="flex flex-col items-center justify-center gap-8 py-4">
                      <BsCardImage size={50} className="" />
                      <div className="m-0 flex flex-col items-center">
                        <div className="text-lg font-semibold flex items-center gap-2 justify-center">
                          <BsUpload size={15} className="" />
                          Click to upload
                        </div>
                        <p> or drag and drop</p>
                      </div>
                    </div>
                  )}
                  {file && (
                    <div className="flex flex-col items-center justify-center gap-8 py-4">
                      <BsCardImage
                        size={50}
                        className="text-primary"
                        style={{
                          color: previewState?.fetchedData?.accountInfo?.theme,
                        }}
                      />
                      <div className="flex gap-4 items-center justify-center">
                        <TiTick
                          size={30}
                          className="text-primary"
                          style={{
                            color:
                              previewState?.fetchedData?.accountInfo?.theme,
                          }}
                        />
                        <p
                          style={{
                            color:
                              previewState?.fetchedData?.accountInfo?.theme,
                          }}
                        >
                          {file.file.name}
                        </p>
                      </div>
                    </div>
                  )}
                </StyledLable>
                <input
                  id={`template-upload`}
                  type="file"
                  className="appearance-none h-0 w-0"
                  onChange={(e) => {
                    handleFileChange(e);
                  }} // Call the handleFileChange function on file selection
                  accept="image/png"
                />
              </div>
              <TermsAndConditions />
              <div className="flex justify-between mt-4 gap-2">
                <BoloButton
                  size={"sm"}
                  variant="secondary"
                  ref={tabIdxData.ref.upload}
                  className="focus:outline focus:outline-blue-700"
                  // className="text-xs md:text-sm bg-white text-primary border-2 border-primary py-1 md:py-2 px-4 md:px-6 rounded-[3px] flex gap-2 items-center"
                  style={{ ...secondaryBtn }}
                  onClick={() => {
                    setFile(null);
                    closeModal();
                  }}
                >
                  {/* <MdRemoveCircleOutline size={20} className="inline-block" /> */}
                  Cancel
                </BoloButton>
                <BoloButton
                  size={"sm"}
                  // className="bg-primary hover:opacity-80 text-white px-3 md:px-6 py-1 md:py-2 text-xs md:text-sm rounded-[3px] flex gap-2 items-center"
                  className="focus:outline-white"
                  style={{ ...primaryBtn }}
                  onClick={() => {
                    if (!file) {
                      toast.error("Upload a file");
                      return;
                    }
                    // Only add signature type in fixed canvas
                    if (imageType === "signature") {
                      confirmCanvas(file.dataUrl);
                    } else {
                      confirm(file.dataUrl);
                    }
                    setFile(null);
                    closeModal();
                  }}
                >
                  {/* <MdDone size={20} className="inline-block" /> */}
                  Accept and {getAction()}
                </BoloButton>
              </div>
            </div>
          )}
          {currentTab === "saved" && (
            <div className="flex justify-between flex-col h-full p-4">
              {owner?.email && (
                <div className="mt-1 mb-2 border border-gray-300 rounded-md px-3 py-2 outline-none h-[200px] w-full flex items-center justify-center">
                  {getSaved() ? (
                    <img
                      className="rounded-md max-h-[198px] max-w-[calc(100% - 2px)]"
                      src={getSaved()}
                      alt={`My ${getHeader()}`}
                    />
                  ) : (
                    <div className="text-center text-gray-500">
                      <p className="mb-2">No saved defaults</p>
                      <p className="text-sm text-gray-400">
                        Visit{" "}
                        <span
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            window.open(
                              `${getDashboardUrlV1()}/settings?type=DEFAULTSETTINGS`,
                              "_blank"
                            );
                          }}
                        >
                          {whiteLabelInfo?.settings?.organization?.name ||
                            "BoloForms"}
                        </span>{" "}
                        now, to add your default signature,
                      </p>
                      <p className="text-sm text-gray-400">
                        and finish your signing process in one click :)
                      </p>
                    </div>
                  )}
                </div>
              )}
              {/* user not logged in */}
              {!owner?.email && (
                <div className="mt-1 mb-2 border border-gray-300 rounded-md px-3 py-2 outline-none h-[200px] w-full flex items-center justify-center">
                  {
                    <div className="text-center text-gray-500">
                      <p className="mb-2">User not Logged In </p>
                      <p className="text-sm text-gray-400">
                        Login to{" "}
                        <span
                          className="text-primary cursor-pointer"
                          onClick={() => {
                            window.open(
                              `${getDashboardUrlV1()}/login`,
                              "_blank"
                            );
                          }}
                        >
                          {whiteLabelInfo?.settings?.organization?.name ||
                            "BoloForms"}
                          ,
                        </span>{" "}
                        now, to add your default signature,
                      </p>
                      <p className="text-sm text-gray-400">
                        and finish your signing process in one click :)
                      </p>
                    </div>
                  }
                </div>
              )}
              <TermsAndConditions />
              <div className="flex justify-between mt-4 gap-2">
                <BoloButton
                  variant={"secondary"}
                  size="sm"
                  ref={tabIdxData.ref.saved}
                  className="focus:outline focus:outline-blue-700"
                  // className="text-xs md:text-sm bg-white text-primary border-2 border-primary py-1 md:py-2 px-4 md:px-6 rounded-[3px] flex gap-2 items-center"
                  onClick={closeModal}
                  style={{ ...secondaryBtn }}
                >
                  {/* <MdRemoveCircleOutline size={20} className="inline-block" /> */}
                  Cancel
                </BoloButton>
                <BoloButton
                  size={"sm"}
                  style={{ ...primaryBtn }}
                  // className="bg-primary hover:opacity-80 text-white py-2 px-6 text-sm rounded-[3px] flex gap-2 items-center"
                  className="focus:outline-white"
                  onClick={handleUseDefault}
                  disabled={!owner?.defaultSignature}
                >
                  {/* <MdDone size={20} className="inline-block" /> */}
                  Accept and {getAction()}
                </BoloButton>
              </div>
            </div>
          )}
          {currentTab === "draw" && (
            <div className="flex flex-col justify-between h-full p-4">
              <div>
                <div className="flex justify-between gap-8 mb-2">
                  <button
                    onClick={() => {
                      // @ts-ignore
                      signaturePad.current.clear();
                    }}
                    // style={{ ...secondaryBtn }}
                    // className="px-6 py-1 rounded-[3px] border-2 border-primary text-primary text-xs w-[100px]"
                    className="text-sm text-gray-600"
                  >
                    Clear
                  </button>
                  <PenColorSelector />
                </div>
                <SignatureCanvas
                  penColor={stroke}
                  canvasProps={{
                    // width: 550,
                    // height: 200,
                    // style: {
                    //   border: previewState?.fetchedData?.accountInfo?.theme
                    //     ? `1px solid ${previewState?.fetchedData?.accountInfo?.theme}`
                    //     : "",
                    // },
                    className:
                      "sigCanvas mx-auto bg-[#F8F8F8] border w-full h-[200px] sm:h-[200px] ",
                  }}
                  ref={(ref) => {
                    // @ts-ignore
                    signaturePad.current = ref;
                  }}
                  onEnd={() => {
                    // @ts-ignore
                    // setDrawingData(signaturePad.current.toDataURL("image/png"));
                  }}
                />
              </div>
              <TermsAndConditions />
              <div className="flex justify-between mt-4 gap-2">
                <BoloButton
                  size={"sm"}
                  variant={"secondary"}
                  ref={tabIdxData.ref.draw}
                  style={{ ...secondaryBtn }}
                  className="focus:outline focus:outline-blue-700"
                  // className="text-xs md:text-sm bg-white text-primary border-2 border-primary py-1 md:py-2 px-4 md:px-6 rounded-[3px] flex gap-2 items-center"
                  onClick={closeModal}
                >
                  {/* <MdRemoveCircleOutline size={20} className="inline-block" /> */}
                  Cancel
                </BoloButton>
                <BoloButton
                  size={"sm"}
                  style={{ ...primaryBtn }}
                  // className="bg-primary hover:opacity-80 text-white py-1 md:py-2 text-xs md:text-sm px-4 md:px-6 rounded-[3px] flex gap-2 items-center"
                  className="focus:outline-white"
                  onClick={handleDone}
                >
                  {/* <MdDone size={20} className="inline-block" /> */}
                  Accept and {getAction()}
                </BoloButton>
              </div>
            </div>
          )}
          {currentTab === "type" && (
            <div className="flex flex-col justify-between h-full p-4">
              <div className="h-full relative flex flex-col justify-center items-center w-fullrounded-md ">
                {/* <div
                    onClick={() => {
                      setShowModal(false);
                      setConfirmText("Confirm");
                    }}
                    className="absolute right-4 top-4 cursor-pointer"
                  >
                    <MdClose className="font-bold" size={30} />
                  </div> */}
                <div className="h-full w-full">
                  <div className="flex flex-col gap-[15px] w-full h-full justify-between">
                    {/* <div className=" font-semibold">Confirm Signature</div> */}
                    {/* <div className="text-[18px]">Go artistic. Use your mouse or trackpad to make a statement.</div> */}
                    <div>
                      <div className="flex justify-between items-center">
                        <Select
                          value={currentFont}
                          onValueChange={(value) => {
                            setCurrentFont(value);
                            // handleWorkSpaceCurrency(value);
                          }}
                        >
                          <SelectTrigger className="z-[200005] mr-4 max-w-[50%] focus:outline-blue-600">
                            <SelectValue placeholder="Select Font" />
                          </SelectTrigger>
                          <SelectContent className="z-[200005]">
                            <SelectGroup>
                              {fontNames?.map((font, idx) => {
                                return (
                                  <SelectItem value={font}>
                                    <div
                                      className={`${font} flex gap-2 text-gray-500 text-lg h-10 items-center`}
                                    >
                                      {signatureText}
                                    </div>
                                  </SelectItem>
                                );
                              })}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <div className="flex items-center h-full justify-end gap-5 text-gray-800">
                          <PenColorSelector />
                          <div className="flex flex-row rounded-full relative bg-transparent max-h-[26px]">
                            <button
                              onClick={() => setFontSize((prev) => prev - 5)}
                              className="border border-primary text-primary bg-primary/20 h-full rounded-l-sm cursor-pointer px-2 max-h-[26px]"
                              style={{
                                ...secondaryBtn,
                                border: previewState?.fetchedData.accountInfo
                                  ?.theme
                                  ? `1px solid ${previewState?.fetchedData.accountInfo?.theme}`
                                  : "",
                                background:
                                  previewState?.fetchedData?.accountInfo
                                    ?.themeLight,
                              }}
                            >
                              <span className="m-auto text-xl">−</span>
                            </button>
                            <input
                              value={fontSize}
                              onChange={(e) => {
                                if (!isNaN(parseInt(e.target.value))) {
                                  setFontSize(parseInt(e.target.value));
                                }
                              }}
                              type="text"
                              className="border-t border-b border-primary max-h-[26px] max-w-[50px] focus:outline-none text-center w-full text-xs font-semibold hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none"
                              style={{
                                borderTop: previewState?.fetchedData.accountInfo
                                  ?.theme
                                  ? `1px solid ${previewState?.fetchedData.accountInfo?.theme}`
                                  : "",
                                borderBottom: previewState?.fetchedData
                                  .accountInfo?.theme
                                  ? `1px solid ${previewState?.fetchedData.accountInfo?.theme}`
                                  : "",
                              }}
                              name=""
                            />
                            <button
                              onClick={() => setFontSize((prev) => prev + 5)}
                              style={{
                                ...secondaryBtn,
                                border: previewState?.fetchedData.accountInfo
                                  ?.theme
                                  ? `1px solid ${previewState?.fetchedData.accountInfo?.theme}`
                                  : "",
                                background:
                                  previewState?.fetchedData?.accountInfo
                                    ?.themeLight,
                              }}
                              className="max-h-[26px] border border-primary text-primary bg-primary/20 h-full rounded-r-sm cursor-pointer px-2"
                            >
                              <span className="m-auto text-xl ">+</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="w-full  lg:w-full h-[180px] bg-transparent p-[10px] flex justify-center items-center relative border mt-4 bg-[#F8F8F8]"
                        // style={{
                        //   border: previewState?.fetchedData.accountInfo?.theme
                        //     ? `1px solid ${previewState?.fetchedData.accountInfo?.theme}`
                        //     : "",
                        // }}
                      >
                        {/* <div
                            ref={signatureBox}
                            className={`font-[550] ${currentFont} h-full w-full flex items-center justify-center`}
                            style={{
                              color: penColor,
                              fontSize: fontSize + "px",
                            }}
                          >
                            {signatureText}
                          </div> */}
                        <input
                          onChange={(e) => setSignatureText(e.target.value)}
                          value={signatureText}
                          ref={signatureBox}
                          className={`font-[550] ${currentFont} h-full w-full flex items-center justify-center outline-none text-center bg-transparent`}
                          style={{
                            color: penColor,
                            fontSize: fontSize + "px",
                          }}
                        />
                      </div>
                    </div>
                    <TermsAndConditions />
                    <div className="flex items-center justify-between w-full">
                      <BoloButton
                        size={"sm"}
                        variant="secondary"
                        ref={tabIdxData.ref.type}
                        onClick={() => {
                          setShowModal(false);
                          setConfirmText("Confirm");
                          closeModal();
                        }}
                        style={{ ...secondaryBtn }}
                        className="focus:outline focus:outline-blue-700"
                        // className="text-xs md:text-sm bg-white text-primary border-2 border-primary py-1 md:py-2 px-4 md:px-6 rounded-[3px] flex gap-2 items-center"
                      >
                        Cancel
                      </BoloButton>
                      <BoloButton
                        size={"sm"}
                        style={{ ...primaryBtn }}
                        onClick={() => {
                          handleConfirm();
                        }}
                        // className="bg-primary hover:opacity-80 text-white px-3 md:px-6 py-1 md:py-2 text-xs md:text-sm rounded-[3px] flex gap-2 items-center"
                        className="focus:outline-white"
                      >
                        Accept and {getAction()}
                      </BoloButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
    // </div>
  );
};

export default SignatureTemplateModal;
