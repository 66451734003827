import React, { useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
// import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { ItemInterface, ReactSortable } from "react-sortablejs";
import {
  DesignerState,
  GlobalHelpersType,
  OrderedPageType,
} from "../../pdfme/ui/src/components/Designer";
import { PreviewStateType } from "../../pdfme/ui/src/components/Preview";
import { fmtTemplate } from "../../pdfme/ui/src/helper";
import CustomTooltip from "../EditForm/CustomTooltip";
import { arrayEquals } from "../../utils/uiUtils";
import MiniPDFPageOptions from "./PdfViewer/MiniPDFPageOptions";
import { getWhiteLabelInfoLocal } from "../../utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js`;

interface Props {
  pdfFile: File | null | undefined;
  pages: [];
  setPageIndex: (pageIndex: number) => void;
  currentPageIndex: number;
  isPdfMeDesigner?: boolean;
  isFormPov?: boolean;
  previewState?: PreviewStateType;
  designerState?: DesignerState;
  setDesignerState?: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers?: GlobalHelpersType;
  backgrounds: string[];
}

export type PageEditorCommonProps = {
  designerState?: DesignerState;
  setDesignerState?: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers?: GlobalHelpersType;
  pageNo: number;
};

const AddPageSeperator = (props: PageEditorCommonProps) => {
  const { designerState, setDesignerState, pageNo, globalHelpers } = props;

  const handleAddPage = async (pageNo: number) => {
    if (!designerState || !setDesignerState || !globalHelpers) {
      console.log({ designerState, setDesignerState, globalHelpers });
      return;
    }
    globalHelpers.PDF.addPage(pageNo);
  };
  return (
    <div className="group relative min-h-[2px] max-h-[2px] w-full flex items-center justify-between gap-1">
      <div className="w-[40%] border-b-2 border-primary hidden group-hover:block"></div>
      <FaPlus
        data-tooltip-id="add-page-btn"
        data-tooltip-offset={10}
        size={15}
        className={`absolute translate-y-[-5%] left-[50%] translate-x-[-50%] z-10 text-gray-500 hover:text-primary hidden group-hover:block`}
        onClick={() => handleAddPage(pageNo)}
      />
      <CustomTooltip
        helpers={{
          id: "add-page-btn",
          content: "Add Page",
          place: "bottom",
        }}
      />
      <div className="w-[40%] border-b-2 border-primary hidden group-hover:block"></div>
    </div>
  );
};

const DeletePageButton = (props: PageEditorCommonProps) => {
  const { designerState, setDesignerState, pageNo, globalHelpers } = props;
  const handleDeletePage = async (pageNo: number) => {
    if (!designerState || !setDesignerState || !globalHelpers) {
      console.log({ designerState, setDesignerState, globalHelpers });
      return;
    }

    globalHelpers.PDF.delPage(pageNo);
  };
  return (
    <button
      disabled={designerState?.schemasList?.length == 1}
      className="disabled:opacity-50 disabled:cursor-not-allowed"
    >
      <MdDelete
        data-tooltip-id="del-page-btn"
        data-tooltip-offset={10}
        size={20}
        className={`text-gray-800 hover:text-primary`}
        onClick={() => handleDeletePage(pageNo)}
      />
      <CustomTooltip
        helpers={{
          id: "del-page-btn",
          content: "Delete Page",
          place: "bottom",
        }}
      />
    </button>
  );
};

export const PageViewer = ({
  pdfFile,
  pages,
  setPageIndex,
  currentPageIndex,
  isPdfMeDesigner,
  isFormPov,
  previewState,
  designerState,
  setDesignerState,
  globalHelpers,
  backgrounds,
}: Props) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const [file, setFile] = useState(pdfFile);
  const [orderedPages, setOrderedPages] = useState<OrderedPageType[]>(
    pages.map((page, index) => ({ data: page, order: index, id: index }))
  );
  // function onDocumentLoadSuccess({ numPages }: any) {
  //   // setNumPages(2);
  // }

  type HandleSetPagesArg = ItemInterface & OrderedPageType;
  async function handleSetPages(pages_: HandleSetPagesArg[]) {
    if (!setDesignerState || !designerState || !globalHelpers) {
      // console.debug({ setDesignerState, designerState });
      return;
    }

    let newOrder = pages_.map((page) => page.order);
    let sortedOrder = [...newOrder].sort((a, b) => a - b);
    // console.log({ newOrder, sortedOrder });
    if (arrayEquals(newOrder, sortedOrder)) {
      // console.log({ arrayEquals: true });
      return;
    }
    // console.log({ pages_ });

    let newSchemaList = pages_.map((page) => page.data);
    let newTemplate = fmtTemplate(designerState?.template, newSchemaList);

    // console.log({ newOrder, newSchemaList, newTemplate });

    globalHelpers.PDF.reorder(newOrder);
  }

  useEffect(() => {
    setFile(pdfFile);
  }, [pdfFile]);

  useEffect(() => {
    setOrderedPages(
      pages.map((page, index) => ({ data: page, order: index, id: index }))
    );
  }, [pages]);

  // useEffect(() => {
  //   console.log("Pages", pages);
  // }, [pages]);

  const setPageIndexHandler = async (index: number) => {
    setPageIndex(index);
  };

  return (
    <div className={`w-full ${isFormPov ? "mb-[120px]" : ""}`}>
      {/* <Document file={file} onLoadSuccess={onDocumentLoadSuccess}> */}
      <div className="flex flex-col items-center w-full cursor-pointer mb-4 pb-8">
        {!(isFormPov || designerState?.mode !== "EDITING") && (
          <AddPageSeperator
            pageNo={0}
            designerState={designerState}
            setDesignerState={setDesignerState}
            globalHelpers={globalHelpers}
          />
        )}
        <ReactSortable
          className="flex flex-col items-center w-full"
          direction={"vertical"}
          list={orderedPages}
          setList={handleSetPages}
          animation={200}
          easing="ease-out"
          dragClass="sortableDrag"
          disabled={
            isFormPov ||
            designerState?.instanceFrom == "FORM" ||
            designerState?.mode !== "EDITING"
          }
        >
          {orderedPages.map((page_: OrderedPageType, index: number) => {
            let page = page_.data;
            return (
              <>
                <div
                  key={index}
                  className={`sortableDrag w-full pt-4 ${
                    index === currentPageIndex ? "bg-secondary/50" : ""
                  } ${isFormPov && "px-10"}`}
                  style={{
                    background:
                      index === currentPageIndex
                        ? `${previewState?.fetchedData?.accountInfo?.theme}20`
                        : "",
                  }}
                >
                  <div className="flex justify-center relative">
                    <div
                      className={`border-2 mx-3 ${
                        isFormPov || designerState?.instanceFrom == "FORM"
                          ? ""
                          : "mr-[22px]"
                      } flex flex-col items-center w-fit hover:opacity-90 hover:shadow ${
                        index === currentPageIndex
                          ? " border-primary"
                          : "opacity-70"
                      }`}
                      style={{
                        borderColor:
                          index === currentPageIndex
                            ? `${previewState?.fetchedData?.accountInfo?.theme}`
                            : "",
                      }}
                      onClick={() => {
                        setPageIndexHandler(index);
                      }}
                    >
                      <img src={backgrounds[index]} alt={`Page ${index + 1}`} />
                      {/* <Page
                          pageNumber={index + 1}
                          key={index}
                          renderMode="canvas"
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                          scale={0.17}
                        /> */}
                    </div>
                    {!(
                      isFormPov ||
                      designerState?.instanceFrom == "FORM" ||
                      designerState?.mode !== "EDITING"
                    ) && (
                      <MiniPDFPageOptions
                        pageNo={index}
                        designerState={designerState}
                        setDesignerState={setDesignerState}
                        globalHelpers={globalHelpers}
                      />
                    )}
                  </div>
                  <div className="flex items-center justify-center gap-2 px-3 py-1.5 text-gray-700 mx-auto mb-2">
                    <div className="text-center text-xs">{index + 1}</div>
                    {isPdfMeDesigner && (
                      <div className="flex items-center">
                        <div className="text-xs">Fields: {page.length}</div>
                      </div>
                    )}
                    {/* {!isFormPov && (
                        <DeletePageButton
                          pageNo={index}
                          designerState={designerState}
                          setDesignerState={setDesignerState}
                          globalHelpers={globalHelpers}
                        />
                      )} */}
                  </div>
                  {!(isFormPov || designerState?.mode !== "EDITING") && (
                    <AddPageSeperator
                      key={`add-page-sep${index}`}
                      // +1 because we want to add after the page
                      pageNo={index + 1}
                      designerState={designerState}
                      setDesignerState={setDesignerState}
                      globalHelpers={globalHelpers}
                    />
                  )}
                </div>
                {/* {!isFormPov && (
                  <AddPageSeperator
                    key={`add-page-sep${index}`}
                    pageNo={index}
                    designerState={designerState}
                    setDesignerState={setDesignerState}
                    globalHelpers={globalHelpers}
                  />
                )} */}
              </>
            );
          })}
        </ReactSortable>
      </div>
      {/* </Document> */}
    </div>
  );
};
