// @ts-nocheck

//useFetch.js
import { useState, useEffect } from "react";
// import axios from "../utils/axios";
import axios from "axios";
import { BASE_URL } from "../constants/constants";

// When user tries to access unauthorized route
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403) {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

function useFetch(route) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading("loading...");
    setData(null);
    setError(null);
    // const source = axios.CancelToken.source();
    axios
      .get(`${BASE_URL}${route}`, {
        // cancelToken: source.token,
        headers: {
          "x-auth-token": localStorage.getItem("x-auth-token"),
        },
      })
      .then((res) => {
        setLoading(false);
        //checking for multiple responses for more flexibility
        //with the route we send in.
        res.data && setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError("An error occurred. Awkward..");
      });
  }, [route]);

  return { data, loading, error };
}
export default useFetch;
