import { MdClose } from "react-icons/md";
import { BoloButton } from "../../../../../components/Common/Button/BoloButton";
import { toast } from "react-toastify";
import { LuExternalLink } from "react-icons/lu";

const GetTemplateLink = ({
  eTemplateLink,
  setIsOpen,
}: {
  eTemplateLink: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.4)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-full h-screen  flex justify-center items-start md:items-center p-4"
    >
      <div className={`w-[450px] max-w-full relative bg-white rounded-md p-6`}>
        <MdClose
          onClick={() => {
            setIsOpen(false);
          }}
          className="text-2xl absolute top-1 right-0 cursor-pointer m-4"
        />
        <h2 className="text-gray-700 text-md font-semibold mb-6 flex justify-start items-center gap-2">
          Template web link{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              window.open(eTemplateLink, "_blank");
            }}
          >
            <LuExternalLink />
          </span>
        </h2>
        <p className="text-gray-600 text-sm mb-2">
          Share a direct link to this template.
        </p>
        <div className="flex gap-2">
          <div className="px-2 py-1 bg-gray-100 border-b border-gray-600 text-sm italic overflow-x-auto whitespace-nowrap no-scrollbar">
            <p className=" overflow-x-auto whitespace-nowrap no-scrollbar">
              {eTemplateLink}
            </p>
          </div>
          <BoloButton
            onClick={() => {
              navigator.clipboard.writeText(eTemplateLink);
              toast.success("Link copied to clipboard");
            }}
            variant={"primary"}
            size={"xs"}
            className="whitespace-nowrap font-medium"
          >
            Copy Link
          </BoloButton>
        </div>
      </div>
    </div>
  );
};

export default GetTemplateLink;
