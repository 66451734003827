// import axios from "axios";
import axios from "../../utils/axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DrawingModal from "../../components/Modals/DrawingModal";
import EmailInputForm from "../../components/Ui/EmailInput";
import MailDataForm from "../../components/Ui/MailDataForm";
import NextPage from "../../components/Ui/NextPage";
import Uploader from "../../components/Ui/Uploader";
import { BASE_URL } from "../../constants/constants";
import { InfinitySpin } from "react-loader-spinner";

import { v4 } from "uuid";
import { delay, fetchOwner } from "../../utils";
import ReceivesASignedCopy from "../../components/Ui/ReceivesASignedCopy";
import { validateEmail } from "../../utils/validateEmail";
import { IoArrowBackCircle } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import BoloCheckboxMui from "../../components/Common/Checkbox";
import { getEditableFields } from "../../utils/fillablePDF";
import { removeDefaultInputFields } from "../../utils/helpers";
import { convertToNormalPDFFile } from "../../utils/uiUtils";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import { getEmbedHome, isEmbeded } from "../../utils/embed";
import { detectStatusandBody } from "../../redux/apis/fileupload";
import { copyPdfBytes } from "../../utils/localPdfUtils";
import SMSBodyDataForm from "../../components/Ui/SMSBodyDataForm";

const Home: React.FC = () => {
  let navigate = useNavigate();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [searchParams, setSearchParams] = useSearchParams();
  // const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const [goToNextPage, setGoToNextPage] = useState(false); //

  const [receiverEmails, setReceiverEmails] = useState<
    {
      name: string;
      email: string;
      message?: string;
      colour?: string;
      accessCode?: string;
      phone?: string;
    }[]
  >([]);
  const [mailData, setMailData] = useState<{ subject?: string; body?: string }>(
    {}
  );
  const [isSigner, setIsSigner] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [documentId, setDocumentId] = useState<string | null>("");
  const [signerSignCount, setSignerSignCount] = useState(0);
  const [signsObject, setSignsObject] = useState<any>({
    signs: [],
    pageIndexes: [],
    email: "",
    documentId: "",
  });
  const [isAutoFill, setIsAutoFill] = useState(true);
  const [file, setFile] = useState<File | null>(null);

  const [startSigning, setStartSigning] = useState(false);
  const [isDraftEditing, setIsDraftEditing] = useState(false);
  const [isDrafting, setIsDrafting] = useState(false);
  const [isSigningOrderData, setIsSigningOrderData] = useState(false);

  const [drawingModalOpen, setDrawingModalOpen] = useState(false);

  const [pdfCount, setPdfCount] = useState(1);
  const [mapFields, setMapFields] = useState(true);
  const [fieldsCountInPdf, setFieldsCountInPdf] = useState(0);
  const [owner, setOwner] = useState(false);

  const [emailTags, setEmailTags] = useState([]);

  const [sendViaSMS, setSendViaSMS] = useState(false);

  const [smsBody, setSmsBody] = useState("");

  // useEffect(() => {
  //   const file = uploadedPdf || fileFromUrl;
  //   if (file) {
  //     setFile(file);
  //   }
  // }, [fileFromUrl, uploadedPdf]);

  // const collectReceiverEmails = (
  //   recipient: {
  //     email: string;
  //     name: string;
  //     message?: string;
  //   }[]
  // ) => {
  //   setReceiverEmails(recipient);
  //   console.log(receiverEmails);
  // };

  // const collectMailData = (mailData: { subject: string; body: string }) => {
  //   setMailData(mailData);
  // };
  const onSaveReceivers = (
    receivers: {
      email: string;
      name: string;
      message?: string | undefined;
      colour?: string | undefined;
      accessCode?: string;
      phone?: string;
    }[]
  ) => {
    setReceiverEmails(receivers);
  };

  const saveAsDraft = async (redirect?: boolean) => {
    if (isSigner || isMerging || isDraftEditing) {
      return toast.info("This one is already a draft");
    }
    if (!file) return;
    let isSendToEmailNotValid = false;
    emailTags.forEach((email: any) => {
      if (!validateEmail(email)) {
        toast.error("Please enter a valid email");
        isSendToEmailNotValid = true;
      }
    });

    if (isSendToEmailNotValid) return;

    try {
      setIsDrafting(true);
      const authorEmail = localStorage.getItem("currentUserEmail");

      let documentIdV4 = v4();

      let pdfFile: File = file;
      let errorInFieldRemoval = false;

      // const pdfBuffer = await pdfFile.arrayBuffer();

      // // Convert PDF buffer to a base64 string
      // let pdfBase64 = arrayBufferToBase64(pdfBuffer);
      // pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;

      // let docIdWaterMark = `BoloForms Document ID: ${documentIdV4}`;

      // const template: Template = {
      //   // schemas: templateSchema,
      //   //   schemas: transformedSchemas || [{}],
      //   schemas: [
      //     {
      //       'docId': {
      //         type: "text",
      //         position: {
      //           x: 2.38,
      //           y: 2.38,
      //         },
      //         width: 155.82,
      //         height: 5.47,
      //         fontSize: 8,
      //         fontColor: "#000000",
      //         fontName: "Roboto",
      //       },
      //     },
      //   ],
      //   // sampledata: [{ Name: "Input example", photo: "https://picsum.photos/200/300"}],
      //   //   sampledata: sampledata || [{}],
      //   // @ts-ignore
      //   basePdf: pdfBase64,
      // };
      // const updatedResponse = [{
      //   'docId': docIdWaterMark
      // }]
      // const inputs = updatedResponse;
      // let fileWithDocId = await generate({ template, inputs });

      // const fileData = new Uint8Array(fileWithDocId);
      // const blob = new Blob([fileData], { type: "application/pdf" });

      // // Then, create a File from the Blob
      // let fileWithDocIdFile = new File([blob], pdfFile.name, {
      //   type: "application/pdf",
      // });

      let fileWithDocIdFile = pdfFile;
      console.log("pdf File", pdfFile);
      if (mapFields && fieldsCountInPdf > 0) {
        // pdfFile = await removeDefaultInputFields(pdfFile);
        let processedFile = await removeDefaultInputFields(pdfFile);
        if (!processedFile.error) {
          pdfFile = processedFile.file;
        } else {
          errorInFieldRemoval = true;
          toast.info("Could not map fields with AI");
          toast.info("Proceeding with standard PDF");
          await delay(2000); // delay to let users read the message
          pdfFile = await convertToNormalPDFFile(pdfFile);
        }
      } else {
        // if not checked the use AI mapping checkbox and there are fillable fields in the pdf
        // we flatten so that the fields seen in editor are also reflected in generation
        pdfFile = await convertToNormalPDFFile(pdfFile);
      }

      const formData = new FormData();

      // console.log("file at 2 data", fileWithchanged.data);
      //* get Ip address
      const response = getIpAddressLocal();
      const ipData = await response;
      console.log("receivers", receiverEmails);
      formData.append("pdfFile", pdfFile, pdfFile.name);
      formData.append("responseReceivers", JSON.stringify(emailTags));
      formData.append("ip", ipData.ip);
      formData.append("receiverEmails", JSON.stringify(receiverEmails));
      formData.append("mailData", JSON.stringify(mailData));
      formData.append("smsBody", smsBody);
      formData.append("docId", documentIdV4);
      formData.append("folderId", searchParams.get("folderId") || "");
      formData.append("isSigningOrderData", isSigningOrderData ? "1" : "0");
      formData.append("sendViaSMS", sendViaSMS ? "1" : "0");
      if (mapFields && fieldsCountInPdf > 0 && !errorInFieldRemoval) {
        // Create a schema of fillable fields from the pdf file
        const roles = [
          {
            title: receiverEmails[0].name,
            colour: receiverEmails[0].colour,
          },
        ];
        const instanceFrom = "PDF";
        const finalSchema = await getEditableFields(
          fileWithDocIdFile,
          roles,
          instanceFrom
        );
        // Send schema data at the time of document creation for adding fields
        formData.append("schema", JSON.stringify(finalSchema));
      }

      // @ts-ignore
      formData.append("authorEmail", authorEmail);
      formData.append(
        "createdBy",
        JSON.parse(localStorage.getItem("owner") || "")?._id
      );

      // const { data } = await axios.post(`${BASE_URL}/saveasdraft`, formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // });

      let handledUpload = await detectStatusandBody("/saveasdraft", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log({ handledUpload });

      if (handledUpload.error && handledUpload.error === "403-nb") {
        formData.delete("pdfFile");
        formData.append("pdfFile", await copyPdfBytes(pdfFile), pdfFile.name);
        handledUpload = await detectStatusandBody("/saveasdraft", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
      if (handledUpload.error && handledUpload.error !== "403-nb") {
        console.log(handledUpload.error);
        console.log(handledUpload.errorData);
        toast.error("403 response unhandled error");
      }
      const { data } = handledUpload;

      setPdfLoading(false);

      if (!data.error) {
        // resetAll();
        if (redirect) {
          navigate(`/create/pdf-signature?documentId=${data.documentId}`);
          return;
        } else {
          toast.success("Saved as draft, you can find it in dashboard");
          setTimeout(() => {
            if (isEmbeded()) {
              navigate(getEmbedHome());
            } else {
              navigate("/dashboard/pdfs");
            }
          }, 1000);
        }
      } else {
        // toast.error("Something went wrong");
        console.error(data.error);
      }
      setIsDrafting(false);
    } catch (error: any) {
      setIsDrafting(false);
      console.log(error);
      toast.error("Something went wrong");
      toast.error(error.message);
    }
  };

  const onFileUpload = async (file: File) => {
    // setFile(file);
    // setPdfUrl(URL.createObjectURL(file));
    if (file) {
      setFile(file);
    }
  };
  let updatedOwner = async () => {
    const owner = await fetchOwner(
      // @ts-ignore
      JSON.parse(localStorage.getItem("owner"))?._id
    );
    setOwner(owner);
  };

  async function setFileIfFileExists() {
    let pdfUrl = searchParams.get("pdfUrl");
    let pdfName = searchParams.get("pdfName");

    if (pdfUrl) {
      const fileS3Url = pdfUrl;
      // "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/pdf/85bba26c-2273-45de-b031-5fdd572a5244.pdf";
      let { data } = await axios.get(fileS3Url, {
        responseType: "arraybuffer",
      });
      console.log(data);
      // arrayBuffer to file
      const pdfFile = new File([data], `${pdfName || "Untitled"}.pdf`, {
        type: "application/pdf",
      });
      console.log(pdfFile);
      onFileUpload(pdfFile);
    }
  }

  useEffect(() => {
    updatedOwner();
    setFileIfFileExists();
  }, []);

  if (pdfLoading) {
    return (
      <div className="h-screen -mt-[70px] flex justify-center items-center">
        <BoloLoader />
      </div>
    );
  }
  const setPdfsCount = (count: number) => {
    setPdfCount(count);
  };
  const setFieldsCount = (count: number) => {
    setFieldsCountInPdf(count);
  };
  return (
    <div className={`w-full pb-[50px] `}>
      <Helmet>
        <title translate="no">Create Pdf</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Create Pdf" />
        <meta
          name="description"
          content="Effortlessly generate PDFs for easy and efficient signing with our user-friendly tool."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/create/pdf`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Create Pdf - Boloforms`} />
        <meta
          property="og:description"
          content="Effortlessly generate PDFs for easy and efficient signing with our user-friendly tool."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/create/pdf"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {(!file || !goToNextPage) && !isDraftEditing ? (
        <>
          <div className="template-navbar sticky top-0 z-10 h-auto md:h-14 px-3 md:px-8 py-1 md:py-2 bg-primary flex gap-2 text-white items-center">
            <IoArrowBackCircle
              className={`hover:opacity-70 text-3xl cursor-pointer rounded-sm ${
                isEmbeded() ? "hidden" : ""
              }`}
              onClick={() => {
                if (isEmbeded()) {
                  navigate(getEmbedHome());
                  return;
                }
                navigate("/create");
              }}
            />
            <p className="text-sm ml-2">Prepare Signature document</p>
            <p className="ml-auto text-sm">
              <span className="font-semibold hidden md:block">
                {file?.name}
              </span>
            </p>
          </div>
          {!loading && (
            <div className="mt-2 md:mt-6 flex flex-col items-start justify-start py-2 md:py-4 px-3 md:px-8">
              <Uploader
                preUploadedFile={file}
                onFileUpload={onFileUpload}
                isOnlyPdfTemplate={false}
                isSignature={true}
                isValidReceivers={true}
                setFileName={() => {
                  return;
                }}
                setPdfsCount={setPdfsCount}
                setFieldsCount={setFieldsCount}
              />
              {fieldsCountInPdf > 0 && (
                <div className=" w-[100%] md:w-[65%] max-w-[800px] mx-auto">
                  <BoloCheckboxMui
                    id="mapFields"
                    disabled={pdfCount > 1}
                    onChange={(e: any) => {
                      setMapFields(e.target.checked);
                    }}
                    checked={pdfCount > 1 ? false : mapFields}
                  />
                  <label
                    htmlFor="mapFields"
                    className="cursor-pointer text-sm md:text-base"
                  >
                    Automagically map fields using AI
                  </label>
                </div>
              )}
              <EmailInputForm
                onSave={onSaveReceivers}
                resetAll={isDrafting}
                isOnlySignature={true}
                isSigningOrderData={isSigningOrderData}
                setIsSigningOrderData={setIsSigningOrderData}
                sendViaSMS={sendViaSMS}
                setSendViaSMS={setSendViaSMS}
              />

              {!sendViaSMS ? (
                <MailDataForm onSave={() => {}} setMailData={setMailData} />
              ) : (
                <SMSBodyDataForm smsBody={smsBody} setSmsBody={setSmsBody} />
              )}
              <ReceivesASignedCopy
                emailTags={emailTags}
                setEmailTags={setEmailTags}
                owner={owner}
              />
              <NextPage
                file={file}
                loading={false}
                setGoToNextPage={setGoToNextPage}
                setStartSigning={setStartSigning}
                goToNextPage={goToNextPage}
                saveAsDraft={saveAsDraft}
                isDrafting={isDrafting}
                receiversList={receiverEmails}
                isSigningOrderData={isSigningOrderData}
              />
            </div>
          )}
        </>
      ) : (
        <>{file && !loading && <p></p>}</>
      )}

      <DrawingModal
        open={drawingModalOpen}
        dismiss={() => setDrawingModalOpen(false)}
        confirm={() => {
          toast.success("Drawing added successfully");
        }}
      />
      {/* {receiverEmails.length > 0 && file && (
        <SaveSignaturePlaceholders
          receiverEmails={receiverEmails}
          mailData={mailData}
          pdfFile={file}
          allAttachments={[]}
          reset={() => {
            toast.info("Resetting attachments");
          }}
          isDraftEditing={isDraftEditing}
          documentId={documentId}
        />
      )} */}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default Home;
