import DropdownActionsRespondent from "./DropdownActionsRespondent";
import { useEffect, useState, useRef } from "react";
import { BsThreeDots } from "react-icons/bs";
import { BoloButton } from "../Common/Button/BoloButton";
const DropDownActionOptions = ({
  documentData,
  setIsModalOpen,
  setRespondentsHistory,
  setRespondentForHistory,
  setRespondentStatus,
  setLoading,
  loading,
  documentDetails,
  setShowUpgradeModal,
  setUpgradeModalType,
  setIsSendACopyModalOpen,
  setDocumentForSendACopy,
  setSigningOrderRecipientList,
  setIsSigningOrderDiagram,
  setCurrOrderDocument,
  setDeletingResponse,
  setShow,
  roleInCollaboration,
  setShowShareViaLink,
  setGeneratedLinksData,
  setShowSigningInPerson,
  setUpgradeModalData,
  setShowGenerateLinksModal,
}: any) => {
  const [actionShowingRespondent, setActionShowingRespondent] = useState(null);
  const selfRef: any = useRef();

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (actionShowingRespondent !== documentData?._id) {
        return;
      } //@ts-ignore
      if (selfRef.current !== null && selfRef.current.contains(e.target)) {
        return;
      }
      setActionShowingRespondent(null);
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [actionShowingRespondent, documentData?._id, setActionShowingRespondent]);

  return (
    <>
      <div className="" ref={selfRef}>
        <BoloButton
          size={"icon-sm"}
          variant={"gray-shadow"}
          className="bg-white"
          // className={`m-auto hover:text-gray-800 text-gray-600`}
          onClick={() => {
            setActionShowingRespondent((prev: any) =>
              prev === documentData?._id ? null : documentData?._id
            );
          }}
        >
          <BsThreeDots />
        </BoloButton>
        <DropdownActionsRespondent
          document={documentData}
          actionShowingRespondent={actionShowingRespondent}
          setActionShowingRespondent={setActionShowingRespondent}
          setIsModalOpen={setIsModalOpen}
          setRespondentsHistory={setRespondentsHistory}
          setRespondentForHistory={setRespondentForHistory}
          setRespondentStatus={setRespondentStatus}
          setLoading={setLoading}
          loading={loading}
          documentDetails={documentDetails}
          setShowUpgradeModal={setShowUpgradeModal}
          setUpgradeModalType={setUpgradeModalType}
          setIsSendACopyModalOpen={setIsSendACopyModalOpen}
          setDocumentForSendACopy={setDocumentForSendACopy}
          setIsSigningOrderDiagram={setIsSigningOrderDiagram}
          setSigningOrderRecipientList={setSigningOrderRecipientList}
          setCurrOrderDocument={setCurrOrderDocument}
          setDeletingResponse={setDeletingResponse}
          setShow={setShow}
          roleInCollaboration={roleInCollaboration}
          setShowShareViaLink={setShowShareViaLink}
          setGeneratedLinksData={setGeneratedLinksData}
          setShowSigningInPerson={setShowSigningInPerson}
          setUpgradeModalData={setUpgradeModalData}
          setShowGenerateLinksModal={setShowGenerateLinksModal}
        />
      </div>
    </>
  );
};

export default DropDownActionOptions;
