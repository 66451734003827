// @ts-nocheck
import React, { useEffect } from "react";
import "./index.css";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { BsFillFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { fileToBase64 } from "../../../../../utils/FileDataToBase64";

const FileUpload = ({ data }) => {
  let { question, setFormState, isEditor, questionNo } = data;
  const [files, setFiles] = React.useState([]);

  useEffect(() => {
    if (files?.length > 0) {
      let value = [];
      (async () => {
        for (let file of files) {
          let data = await fileToBase64(file);
          value.push(data);
        }
        // console.log("value", value);
        setFormState((prevState) => {
          let newState = { ...prevState };
          newState.pages.forEach((page) => {
            page.questions.forEach((question_) => {
              if (question_.id === question.id) {
                question_.value = value;
              }
            });
          });
          return newState;
        });
      })();
    }
    // console.log("files", files);
  }, [files]);

  return (
    <div
      className={`${
        question.error && "border-red-600"
      } w-full flex flex-row  mb-6`}
    >
      {/* {questionNo && <div className="mr-2">{questionNo}.</div>} */}
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex flex-row">
            {isEditor ? (
              <input
                type="text"
                value={question.title}
                placeholder="Question"
                onChange={(e) => {
                  setFormState((prevState) => {
                    let newState = { ...prevState };
                    newState.pages.forEach((page) => {
                      page.questions.forEach((question_) => {
                        if (question_.id === question.id) {
                          question_.title = e.target.value;
                        }
                      });
                    });
                    return newState;
                  });
                }}
                className="w-full"
                disabled={!isEditor}
              />
            ) : (
              <div className="text-gray-700 dark:text-gray-300 text-xs flex flex-row">
                <div
                  className="w-full"
                  dangerouslySetInnerHTML={{
                    __html: question.titleHtml ?? question.title,
                  }}
                ></div>
                {question?.required && (
                  <div className="text-red-500 h-fit  ml-1">*</div>
                )}
              </div>
            )}
          </div>
          {isEditor ? (
            <input
              className="text-xs mt-1"
              type="text"
              value={question.description}
              placeholder="Description"
              onChange={(e) => {
                setFormState((prevState) => {
                  let newState = { ...prevState };
                  newState.pages.forEach((page) => {
                    page.questions.forEach((question_) => {
                      if (question_.id === question.id) {
                        question_.description = e.target.value;
                      }
                    });
                  });
                  return newState;
                });
              }}
            />
          ) : (
            question.description && (
              <div
                className="w-full text-xs text-gray-500 mt-1"
                dangerouslySetInnerHTML={{
                  __html: question.descriptionHtml ?? question.description,
                }}
              ></div>
            )
          )}
        </div>
        <div className="formbold-mb-5 formbold-file-input">
          <input
            type="file"
            name={`file-${question.id}`}
            id={`file-${question.id}`}
            onChange={(e) => {
              // console.log("e.target.files", e.target.files);
              setFiles(e.target.files);
              // console.log("files", files);
            }}
            disabled={isEditor}
            multiple={question?.multiple}
            className="w-full"
          />
          <label htmlFor={`file-${question.id}`}>
            {files?.length > 0 ? (
              <div className="flex flex-row items-center flex-wrap">
                {[...files]?.map((file, i) => {
                  return (
                    <div className="w-[100px] p-4 h-full mr-4 border flex flex-col items-center bg-gray-100 rounded-md">
                      <div>
                        <BsFillFileEarmarkSpreadsheetFill className="text-4xl text-gray-500" />
                      </div>
                      <div className="mt-6 w-full text-xs truncate">
                        {file?.name}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <span className="formbold-drop-file text-gray-500">
                  Drop a file here or click the button below to load the file.
                </span>
                <span className="formbold-browse">Choose file</span>
              </div>
            )}
          </label>
        </div>
        <div className="text-xs text-gray-600">Max file size: 5MB</div>
        {isEditor && (
          <div className="mt-4 flex">
            <button
              onClick={() => {
                setFormState((prevState) => {
                  let newState = { ...prevState };
                  newState.pages.forEach((page) => {
                    page.questions.forEach((question_) => {
                      if (question_.id === question.id) {
                        question_.multiple = !question_.multiple;
                      }
                    });
                  });
                  return newState;
                });
              }}
              className="ml-auto text-xs flex flex-row items-center"
            >
              {question.multiple ? (
                <BiToggleRight className="text-lg mr-2 text-primary" />
              ) : (
                <BiToggleLeft className="text-lg mr-2" />
              )}
              Multiple Files
            </button>
            <button
              onClick={() => {
                setFormState((prevState) => {
                  let newState = { ...prevState };
                  newState.pages.forEach((page) => {
                    page.questions.forEach((question_) => {
                      if (question_.id === question.id) {
                        question_.required = !question_.required;
                      }
                    });
                  });
                  return newState;
                });
              }}
              className="ml-6 text-xs flex flex-row items-center"
            >
              {question.required ? (
                <BiToggleRight className="text-lg mr-2 text-primary" />
              ) : (
                <BiToggleLeft className="text-lg mr-2" />
              )}
              Required
            </button>
            <button
              onClick={() => {
                setFormState((prevState) => {
                  let newState = { ...prevState };
                  newState.pages.map((page) => {
                    let questions = page.questions.filter(
                      (question_) => question_.id !== question.id
                    );
                    page.questions = questions;
                  });
                  // console.log(newState);
                  return newState;
                });
              }}
              className="ml-6 text-xs flex flex-row items-center text-red-600"
            >
              <AiOutlineDelete className="text-lg mr-2" />
              Delete
            </button>
          </div>
        )}
        {/* required question error  */}
        {!isEditor && question.error && (
          <div className="text-red-500 text-[10px] mt-4">
            {"This is a required question"}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
