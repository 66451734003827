import React, { ChangeEvent, useRef, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { Slide, toast, ToastContainer } from "react-toastify";
import { BoloButton } from "../../../../components/Common/Button/BoloButton";
import imageCompression from "browser-image-compression";
import { WhiteLabelOwnerType } from "../../../../redux/slices/whiteLabelSlice";
import { generalizedFileUploadViaSignedUrlPublic } from "../../../../utils/fileUpload";
import { uuid } from "../../../../pdfme/ui/src/helper";

const LogoSelector = ({
  imageLink,
  setImageLink,
}: {
  imageLink: string | undefined;
  setImageLink: (imgLink: string) => void;
}) => {
  const imageInputRef = useRef(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<null | Area>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<null | Area>(null);

  const [originalName, setOriginalName] = useState("");
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<string | undefined>(
    undefined
  );

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleBrowseBtnClick = () => {
    //@ts-ignore
    imageInputRef.current.click();
  };

  function readFile(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file && file.size > 5 * 1024 * 1024) {
        toast.error("File should be no larger than 5mb");
        return;
      }
      setOriginalName(file?.name);
      let imageDataUrl = await readFile(file);

      if (typeof imageDataUrl == "string") {
        setUploadedImage(imageDataUrl);
      }
    }
  };

  function rotateSize(width: any, height: any, rotation: any) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  const createImage = (url: any): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
  }

  const base64toBlob = (base64: string, type = "application/octet-stream") => {
    const arr = base64.split(",");
    //@ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime || type });
  };

  const compressImage = async (file: string) => {
    try {
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1200,
        useWebWorker: true,
      };

      const blob = base64toBlob(file);
      //@ts-ignore
      const compressedBlob = await imageCompression(blob, options);
      return compressedBlob;
    } catch (error) {
      console.log("Error compressing image: ", error);
    }
  };

  async function getCroppedImg(
    imageSrc: any,
    pixelCrop: any,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image?.width,
      image?.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.fillStyle = "#FFFFFF";
    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // As Base64 string
    return croppedCanvas.toDataURL("image/png");

    // As a blob
    // return new Promise((resolve, reject) => {
    //     croppedCanvas.toBlob((file) => {
    //         resolve(URL.createObjectURL(file))
    //     }, 'image/jpeg')
    // })
  }

  const handleImageUpload = async (selectedImage: any) => {
    if (selectedImage) {
      setIsUploadingImage(true);
      try {
        const croppedImage = await getCroppedImg(
          uploadedImage,
          croppedAreaPixels
        );
        //@ts-ignore
        const compressedImage = await compressImage(croppedImage);
        const file = new File(
          //@ts-ignore
          [compressedImage],
          uuid() + originalName || "Compnay Logo.png",
          {
            //@ts-ignore
            type: compressedImage.type,
          }
        );

        const link = await generalizedFileUploadViaSignedUrlPublic({
          file,
          folderName: "whitelabel/branding",
        });
        console.log({ link });
        setImageLink(link || "");
        setIsUploadingImage(false);
        setUploadedImage(undefined);

        // setShow(false);
      } catch (error) {
        setIsUploadingImage(false);
        console.error("Error handling file change:", error);
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center gap-y-1 bg-white">
        {uploadedImage ? (
          <div className=" flex flex-col fixed z-40 inset-0 bg-white h-[80%] w-[800px] translate-x-[-50%] left-[50%] top-[50%] translate-y-[-50%] p-4 rounded-md shadow">
            <div className="relative h-[calc(100%_-_60px)] bg-white">
              <Cropper
                image={uploadedImage}
                aspect={1 / 1}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                onCropAreaChange={setCroppedArea}
                restrictPosition={false}
              />
            </div>

            <div className="flex gap-x-[10px] mt-auto justify-end">
              <BoloButton
                variant={"bolo-secondary"}
                size={"xs"}
                onClick={() => {
                  setUploadedImage(undefined);
                }}
              >
                Clear
              </BoloButton>
              <BoloButton
                variant={"bolo-primary"}
                size={"xs"}
                onClick={() => {
                  handleImageUpload(croppedArea);
                }}
              >
                Done
              </BoloButton>
            </div>
          </div>
        ) : (
          <div className="flex ">
            {imageLink ? (
              <img
                className="cursor-pointer"
                src={imageLink}
                height={128}
                width={128}
                onClick={handleBrowseBtnClick}
              />
            ) : (
              <BoloButton
                className="h-fit w-20"
                variant={"bolo-secondary"}
                onClick={handleBrowseBtnClick}
                size={"xs"}
              >
                Browse
              </BoloButton>
            )}
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={imageInputRef}
              onChange={handleImageChange}
              multiple={false}
            />
          </div>
        )}
      </div>

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default LogoSelector;
