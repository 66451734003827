import React, { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { IoMdDocument } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdDelete, MdUpload } from "react-icons/md";
import {
  BoloPopover,
  BoloPopOverClose,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../../../components/Common/PopOver/BoloPopOver";
import CustomTooltip from "../../../../components/EditForm/CustomTooltip";
import { DesignerState, GlobalHelpersType } from "./Designer";
import MixPanel from "../../../../utils/services/mixpanel";
import { CgAttachment } from "react-icons/cg";
import { isFeatureEnabled } from "../../../../utils/paidFeat";

type PaperSeperatorProps = {
  designerState?: DesignerState;
  globalHelpers?: GlobalHelpersType;
  setDesignerState?: React.Dispatch<React.SetStateAction<DesignerState>>;
  pageNo: number;
};

const UploadContent = ({
  designerState,
  setDesignerState,
  globalHelpers,
  pageNo,
}: PaperSeperatorProps) => {
  const mixpanelSetting = (actionName: string) => {
    let Mixpanel = new MixPanel();

    if (designerState?.instanceFrom === "PDF") {
      Mixpanel.track("PDF", {
        pdf_action: `pdf_${actionName}`,
        paid: false,
      });
      Mixpanel.increment(`pdf_${actionName}`);
    } else if (designerState?.instanceFrom === "PDF-TEMPLATE") {
      Mixpanel.track("PDF template", {
        pdf_template_action: `pdf_template_${actionName}`,
        paid: false,
      });
      Mixpanel.increment(`pdf_template_${actionName}`);
    }
  };
  const handleAddPage = async (pageNo: number) => {
    mixpanelSetting("editor_add_page");
    if (!designerState || !setDesignerState || !globalHelpers) {
      console.log({ designerState, setDesignerState, globalHelpers });
      return;
    }
    globalHelpers.PDF.addPage(pageNo);
  };

  const handleDupPage = async (pageNo: number) => {
    mixpanelSetting("editor_duplicate_page");
    if (!designerState || !setDesignerState || !globalHelpers) {
      console.log({ designerState, setDesignerState, globalHelpers });
      return;
    }
    globalHelpers.PDF.duplicatePage(pageNo);
  };

  const handleDeletePage = async (pageNo: number) => {
    mixpanelSetting("editor_delete_page");

    if (!designerState || !setDesignerState || !globalHelpers) {
      console.log({ designerState, setDesignerState, globalHelpers });
      return;
    }

    globalHelpers.PDF.delPage(pageNo);
  };

  return (
    <div className="rounded flex flex-col py-2">
      <div
        className="py-2 flex gap-2 px-4 text-gray-500 text-xs items-center cursor-pointer group pr-12 hover:bg-secondary/40"
        onClick={() => handleAddPage(pageNo)}
      >
        <IoMdDocument className="group-hover:text-primary" size={18} />
        <span>Blank Page</span>
      </div>
      <BoloPopOverClose>
        <div
          className="py-2 flex gap-2 px-4 text-gray-500 text-xs items-center cursor-pointer group pr-12 hover:bg-secondary/40"
          onClick={() => {
            mixpanelSetting("editor_upload_file");
            setDesignerState &&
              setDesignerState((prev) => ({
                ...prev,
                addDocument: {
                  ...prev.addDocument,
                  show: true,
                  pageNo: pageNo,
                },
              }));
          }}
        >
          <MdUpload className="group-hover:text-primary" size={20} />
          <span>Upload File</span>
        </div>
      </BoloPopOverClose>
      <BoloPopOverClose>
        <div
          className="py-2 flex gap-2 px-4 text-gray-500 text-xs items-center cursor-pointer group pr-12 hover:bg-secondary/40"
          onClick={async () => {
            let featuresAccess = await isFeatureEnabled("SIGNER_ATTACHMENTS");

            if (!featuresAccess) {
              setDesignerState &&
                setDesignerState((prev) => ({
                  ...prev,
                  showUpgradeModal: {
                    ...prev.showUpgradeModal,
                    show: true,
                    type: "SIGNER_ATTACHMENTS",
                  },
                }));
              return;
            }
            setDesignerState &&
              setDesignerState((prev) => ({
                ...prev,
                addAttachment: {
                  ...prev.addAttachment,
                  show: true,
                  pageNo: pageNo,
                },
              }));
          }}
        >
          <CgAttachment
            className={`text-gray-500 text-lg font-bold cursor-pointer group-hover:text-primary`}
          />
          <span>Add attachment</span>
        </div>
      </BoloPopOverClose>

      {(designerState?.schemasList?.length || 0) > pageNo && (
        <>
          <div
            className="py-2 flex gap-2 px-4 text-gray-500 text-xs items-center cursor-pointer group pr-12 hover:bg-secondary/40"
            onClick={() => handleDupPage(pageNo)}
          >
            <IoDocuments className="group-hover:text-primary" size={20} />
            <span>Duplicate Page</span>
          </div>
          <div
            className="py-2 flex gap-2 px-4 text-gray-500 text-xs items-center cursor-pointer group pr-12 hover:bg-red-400/10"
            onClick={() => handleDeletePage(pageNo)}
          >
            <MdDelete className="group-hover:text-red-600" size={20} />
            <span>Delete Page</span>
          </div>
        </>
      )}
    </div>
  );
};

const PaperSeperator = ({
  designerState,
  globalHelpers,
  setDesignerState,
  pageNo,
}: PaperSeperatorProps) => {
  return (
    <div className="flex justify-center py-2 min-h-[32px]">
      {designerState?.mode === "EDITING" && (
        <BoloPopover>
          <BoloPopoverTrigger>
            <BsThreeDots
              data-tooltip-id="add-page-paper-btn"
              data-tooltip-offset={10}
              size={20}
              className={`text-gray-500 hover:text-primary cursor-pointer`}
            />
            <CustomTooltip
              helpers={{
                id: "add-page-paper-btn",
                content: "Pdf Options",
                place: "bottom",
              }}
            />
          </BoloPopoverTrigger>
          <BoloPopoverContent>
            <UploadContent
              pageNo={pageNo}
              designerState={designerState}
              setDesignerState={setDesignerState}
              globalHelpers={globalHelpers}
            />
          </BoloPopoverContent>
        </BoloPopover>
      )}
    </div>
  );
};

export default PaperSeperator;
