import { ENV, SKIP_EMBED_CHECK_DOMAIN } from "../constants/constants";
import { addBulkContactsEmbed } from "../redux/apis/contacts";
import { ContactSchemaArray } from "../zodTypes";

function isEmbeded(): boolean {
  return (
    window.self !== window.top &&
    (window.location.hostname === "embed.boloforms.com" ||
      (ENV as string) === "DEV")
  );
}

function getEmbedHome() {
  const url = `/embed/login?embed-token=${
    localStorage.getItem("x-embed-token") || ""
  }&embed-session-inputs=${localStorage.getItem("embed-session-inputs") || ""}`;
  // console.log("Embed home", url);
  return url;
}

function sessionInfoFormatter() {
  try {
    console.log(localStorage.getItem("embed-session-inputs"));
    const session = JSON.parse(
      localStorage.getItem("embed-session-inputs") || "{}"
    );
    let sessionName = "";
    for (let [key, value] of Object.entries(session)) {
      sessionName += `${key}: ${value}, `;
    }
    return sessionName || "";
  } catch (error) {}
}

/**
 * Checks if the current window is embedded within another boloforms domain or not.
 * if yes then we allow users to use embed login page without having to run the embed logic
 * TODO: make seperate checks in a differnt domain
 *
 * @return {boolean} Returns true if the current window is embedded in boloforms.com, false otherwise.
 */
function isSelfEmbed(): boolean {
  try {
    // console.log({ ref: document.referrer });
    // const parentHostname = new URL(document.referrer).hostname;
    // console.log({
    //   parentHostname,
    //   SKIP_EMBED_CHECK_DOMAIN,
    //   referrer: document.referrer,
    // });
    // return parentHostname.includes(SKIP_EMBED_CHECK_DOMAIN);

    // if error that means it is cross origin and shoudl be false
    // even if cross origin is allowed then we check href

    const parentUrl = window.parent.location.href;
    console.log(parentUrl);
    return parentUrl.includes(SKIP_EMBED_CHECK_DOMAIN);
  } catch (error) {
    console.error("Error accessing parent hostname:", error);
    return false;
  }
}

const CONTACT_INFO = {
  checkAndSet: async (data: any): Promise<{ data: any; error?: any }> => {
    if (!isEmbeded()) {
      console.log("CONTACT_INFO", "not embeded");
      return { data, error: false };
    }

    if (!data) {
      return { data, error: "" };
    }

    let result = ContactSchemaArray.safeParse(data);
    console.log("CONTACT_INFO", data, result, isEmbeded());
    if (result.success) {
      // localStorage.setItem("EMBED_CONTACTS", JSON.stringify(result.data));
      const data = addBulkContactsEmbed({ contacts: result.data });
      return { data, error: false };
    } else {
      console.log({ result });
      return { data, error: "Invalid contacts array [zod]" };
    }
  },
};

const getIfIsInChromeExtensionIframe = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const from = urlParams.get("from");

    return from === "CHROME-EXTENSION";
  } catch (e) {
    console.log(e);
    return false;
  }
};

export {
  isEmbeded,
  sessionInfoFormatter,
  getEmbedHome,
  isSelfEmbed,
  CONTACT_INFO,
  getIfIsInChromeExtensionIframe,
};
