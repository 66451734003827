export const convertTagsToObjects = (html: string) => {
  if (!html) return "";
  let tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  let tagElements = tempDiv.querySelectorAll("tag");

  tagElements.forEach((tag: any) => {
    let key = tag.getAttribute("key");
    let title = tag.getAttribute("title");
    let value = tag.getAttribute("value");
    let type = tag.getAttribute("type");

    // Construct the object as required
    let tagObject = {
      value: value,
      key: key,
      title: title,
      type: type,
    };
    let tagFormat = `[[${JSON.stringify(tagObject)}]]`;
    tempDiv.innerHTML = tempDiv.innerHTML.replace(tag.outerHTML, tagFormat);
  });
  return tempDiv.innerHTML;
};
export const applyStyleToTags = (htmlString: string) => {
  if (!htmlString) return "";
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const pTags = tempDiv.querySelectorAll("p");
  pTags.forEach((p) => {
    p.style.margin = "0";
    p.style.padding = "0";
  });

  const olTags = tempDiv.querySelectorAll("ol");
  olTags.forEach((ol) => {
    ol.style.margin = "0";
  });

  const ulTags = tempDiv.querySelectorAll("ul");
  ulTags.forEach((ul) => {
    ul.style.margin = "0";
  });
  return tempDiv.innerHTML;
};
export const convertToNormalText = (htmlString: string) => {
  if (!htmlString) return "";
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  return tempDiv.innerText;
};

export const convertTextToHtml = (inputText: string) => {
  if (!inputText) return "";
  let processedText = inputText.trim();
  processedText = `<div style="color:#000;"> ${processedText}</div>`;
  return processedText;
};
export const convertObjectsToTags = (html: string) => {
  if (!html) return "";
  // Define a regex pattern to match the formatted string
  let tagPattern = /\[\[({.*?})\]\]/g;

  let modifiedHtml = html.replace(tagPattern, (match, p1: any) => {
    try {
      // Parse the JSON object from the matched string
      let tagObject = JSON.parse(p1);
      let tagElement = `<tag key="${tagObject.key}" title="${tagObject.title}" type="${tagObject.type}" value="${tagObject.value}">${tagObject.title}</tag>`;
      return tagElement;
    } catch (error) {
      console.error("Error parsing JSON: ", error);
      return match;
    }
  });
  return modifiedHtml;
};
export const convertHtmlToText = (inputHtml: string) => {
  if (!inputHtml) return "";
  let processedText = inputHtml.trim();
  const divMatch = processedText.match(/^<div[^>]*>(.*)<\/div>$/);
  if (divMatch) {
    processedText = divMatch[1];
  }
  return processedText;
};
