import React, { useState } from "react";
import {
  DeleteProductFuncType,
  GetProductStateType,
  ProductType,
} from "../../hooks/reduxHooks/useQuoteBuilder";

import { MdDelete, MdEdit, MdImage } from "react-icons/md";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/Common/SelectDropDown/BoloDropDown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ProductState } from "./ProductCatalog";
import dayjs from "dayjs";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/Common/Dialog/BoloDialog";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { toast } from "react-toastify";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import NoSearchResults from "../../components/NoSearchResults/NoSearchResults";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import DashboardBottomNav from "../../components/DashboardBottomNav/DashboardBottomNav";
import { formatPrice } from "../../utils/quoteBuilder";
import { VscEmptyWindow } from "react-icons/vsc";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";

const DeleteProductConfirm = ({
  deleteProdFunc,
  isOpen,
  setIsOpen,
  product,
}: {
  product: ProductType | null;
  deleteProdFunc: DeleteProductFuncType;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) => {
  const handleDelete = async () => {
    try {
      const data = await deleteProdFunc({
        productId: product?.productId || "",
      });
    } catch (error) {
      console.log("Error in handle create");
      console.log(error);
      toast.error("Something went wroong");
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {/* <DialogTrigger asChild>
        <div className="flex items-center text-xs text-red-600">
          <MdDelete className="mr-2" /> Delete
        </div>
      </DialogTrigger> */}
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Delete Product</DialogTitle>
        </DialogHeader>
        <div>
          You're about to{" "}
          <span className="text-red-600 font-semibold">permanently delete</span>{" "}
          this product "{product?.name}". This action cannot be undone.
        </div>
        <DialogFooter>
          <BoloButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </BoloButton>

          <BoloButton
            variant={"danger-border"}
            size={"sm"}
            onClick={() => {
              handleDelete();
            }}
          >
            Delete
          </BoloButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const ProductsTable = ({
  products,
  productState,
  setProductState,
  deleteProdFunc,
  product,
}: {
  product: GetProductStateType;
  products: ProductType[];
  productState: ProductState;
  deleteProdFunc: DeleteProductFuncType;
  setProductState: React.Dispatch<React.SetStateAction<ProductState>>;
}) => {
  const workSpaceDetails = useWorkSpaceDetails();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState<ProductType | null>(null);
  const { getSearchData } = useQueryHook();

  return (
    <div className="h-full justify-between">
      <div
        className="flex flex-col text-gray-600 h-[calc(100%_-_64px)] overflow-y-auto"
        id="mini-pdf-custom-scroll"
      >
        {/* <p className="text-sm font-semibold text-gray-600">Products</p>{" "} */}
        <div className="w-full py-2 pl-4 flex text-xs max-h-[32px] min-h-[32px]">
          <div className="w-[60px] max-w-[60px] mr-3"></div>
          <div className="w-[40%] pr-2">Name</div>
          <div className="w-[20%] pr-2">SKU</div>
          <div className="w-[15%] text-right pr-2">Price</div>
          <div className="w-[15%]">Modified</div>
        </div>
        {product.loading && (
          <div className="p-8">
            <BoloLoader />
          </div>
        )}

        {getSearchData().query_ &&
          products?.length === 0 &&
          !product.loading && <NoSearchResults />}
        {!getSearchData().query_ &&
          products.length === 0 &&
          !product.loading && (
            <div
              onClick={() => {
                // navigate(navLink);
                setProductState((prev) => ({ ...prev, show: "PROD_INFO" }));
              }}
              className="mt-6 w-full px-3 md:px-[100px] pb-10 rounded-md hover:border-primary cursor-pointer"
            >
              <div className="w-full flex flex-col justify-center items-center">
                <div className="border-2 border-primary rounded-md mb-4 w-[100%] md:w-[400px]">
                  <VscEmptyWindow className="text-9xl text-primary p-8 m-auto" />
                  <h1 className="text-lg md:text-2xl font-medium mb-2 text-center">
                    No Products!
                  </h1>
                  <h2 className="text-xs text-center">Create new Products</h2>
                  <button
                    onClick={() => {
                      setProductState((prev) => ({
                        ...prev,
                        show: "PROD_INFO",
                      }));
                    }}
                    className="w-full mt-6 px-6 py-3 bg-primary text-white text-sm hover:opacity-90"
                  >
                    Create your own
                  </button>
                </div>
              </div>
            </div>
          )}
        <div
          className="flex flex-col h-[calc(100%_-_32px)] overflow-y-auto"
          id="mini-pdf-custom-scroll"
        >
          {!product.loading &&
            products.map((product, idx) => {
              return (
                <div
                  className={`group border-t-2 w-full py-2 pl-4 flex text-xs items-center cursor-pointer`}
                >
                  <div className="w-[60px] max-w-[60px] h-[60px] max-h-[80px] rounded flex items-center justify-center mr-3 bg-gray-100">
                    {product?.images?.length === 0 ? (
                      <MdImage className="text-gray-400" size={25} />
                    ) : (
                      <img
                        src={product?.images?.[0]}
                        className="h-[60px] w-[60px] object-contain rounded"
                      />
                    )}
                  </div>
                  <div className="w-[40%] flex flex-col pr-2">
                    <div className="text-sm font-semibold text-black mb-1 truncate">
                      {product?.name}
                    </div>
                    <div className="text-[10px] rounded bg-gray-100 px-2 py-1 w-fit text-gray-500">
                      {product?.category || "NO CATEGORY"}
                    </div>
                  </div>
                  <div className="w-[20%] pr-2">{product?.SKU}</div>
                  <div className="w-[15%] text-right pr-2">
                    {/* {getSymbolFromCurrency(product?.pricing?.currencyCode)}{" "} */}
                    {/* {product?.pricing?.currencyCode} */}
                    {/* {product?.pricing?.price} */}
                    {formatPrice({
                      amount: Number(product?.pricing?.price) ?? 0,
                      currency: product?.pricing?.currencyCode,
                    })}
                  </div>
                  <div className="w-[15%] flex justify-between items-center">
                    <div className="flex flex-col mt-3.5">
                      <p>
                        {product?.updatedAt
                          ? dayjs(product?.updatedAt).format("MMMM D, YYYY")
                          : ""}
                      </p>
                      <p className="text-[10px] text-gray-500">
                        {product?.updatedAt
                          ? dayjs(product?.updatedAt).format("h:mm:ss A")
                          : ""}
                      </p>
                    </div>
                    <div>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <div className="p-1 rounded-md hover:bg-gray-100 cursor-pointer">
                            <BsThreeDotsVertical size={16} />
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuGroup>
                          <DropdownMenuContent
                            align="end"
                            sideOffset={10}
                            className=""
                          >
                            <DropdownMenuItem
                              disabled={
                                {
                                  owner: false,
                                  admin: false,
                                  editor: false,
                                  readonly: true,
                                }[workSpaceDetails.currentWorkSpaceRole]
                              }
                              onClick={() =>
                                setProductState((prev) => ({
                                  ...prev,
                                  show: "PROD_INFO",
                                  productDetails: product,
                                }))
                              }
                            >
                              <div className="flex items-center text-xs">
                                <MdEdit className="mr-2" /> Edit
                              </div>
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              disabled={
                                {
                                  owner: false,
                                  admin: false,
                                  editor: true,
                                  readonly: true,
                                }[workSpaceDetails.currentWorkSpaceRole]
                              }
                              onClick={() => {
                                setIsOpen(true);
                                setDeleteProduct(product);
                              }}
                            >
                              <div className="flex items-center text-xs text-red-600">
                                <MdDelete className="mr-2" /> Delete
                              </div>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenuGroup>
                      </DropdownMenu>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <DashboardBottomNav count={product.productCount} />
      <DeleteProductConfirm
        product={deleteProduct}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        deleteProdFunc={deleteProdFunc}
      />
    </div>
  );
};

export default ProductsTable;
