import { z } from "zod";

const ContactSchema = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().email(), // Zod has a built-in email validator
  source: z.string().default("MANUAL").optional(), // Default value
  phone: z.string().optional(),
  company: z.string().optional(),
  jobTitle: z.string().optional(),
  streetAddress: z.string().optional(),
  city: z.string().optional(),
  postalCode: z.string().optional(),
  country: z.string().optional(),
  state: z.string().optional(),
  customFields: z.record(z.union([z.string(), z.number()])).optional(),
});
const ContactSchemaArray = z.array(ContactSchema);

// Types -------------------------------------------------------
type ContactSchemaType = z.infer<typeof ContactSchema>;

export { ContactSchema, ContactSchemaArray };
export type { ContactSchemaType };
