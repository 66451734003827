import { useEffect, useRef, createRef, useState } from "react";
import { MdOutlineClose, MdDone, MdRemoveCircleOutline } from "react-icons/md";
import { Color } from "../../entities";

interface Props {
  open: boolean;
  dismiss: () => void;
  confirm: (drawing?: {
    width: number;
    height: number;
    path: string;
    strokeWidth: number;
    stroke: string;
  }) => void;
  drawing?: DrawingAttachment;
}

const DrawingModal = ({ open, dismiss, confirm, drawing }: Props) => {
  const svgRef = createRef<SVGSVGElement>();
  const [paths, setPaths] = useState<Array<[string, number, number]>>([]);
  const [path, setPath] = useState((drawing && drawing.path) || "");
  const [svgX, setSvgX] = useState(0);
  const [svgY, setSvgY] = useState(0);
  const [minX, setMinX] = useState(Infinity);
  const [maxX, setMaxX] = useState(0);
  const [minY, setMinY] = useState(Infinity);
  const [maxY, setMaxY] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(3);
  const [stroke, setStroke] = useState(Color.BLACK);
  const [strokeDropdownOpen, setStrokeDropdownOpen] = useState(false);

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;
    const { x, y } = svg.getBoundingClientRect();
    setSvgX(x);
    setSvgY(y);
  }, [svgRef]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMouseDown(true);

    const x = event.clientX - svgX;
    const y = event.clientY - svgY;
    setMinX(Math.min(minX, x));
    setMaxX(Math.max(maxX, x));
    setMinY(Math.min(minY, y));
    setMaxY(Math.max(maxY, y));
    setPath(path + `M${x},${y}`);
    setPaths([...paths, ["M", x, y]]);
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!mouseDown) return;

    const x = event.clientX - svgX;
    const y = event.clientY - svgY;
    setMinX(Math.min(minX, x));
    setMaxX(Math.max(maxX, x));
    setMinY(Math.min(minY, y));
    setMaxY(Math.max(maxY, y));
    setPath(path + `L${x},${y}`);
    setPaths([...paths, ["L", x, y]]);

    console.log(path);
  };

  const handleMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMouseDown(false);
  };

  const resetDrawingBoard = () => {
    setPaths([]);
    setPath("");
    setMinX(Infinity);
    setMaxX(0);
    setMinY(Infinity);
    setMaxY(0);
    setStrokeWidth(5);
    setStroke(Color.BLACK);
  };

  const handleDone = () => {
    if (!paths.length) {
      confirm();
      return;
    }

    const boundingWidth = maxX - minX;
    const boundingHeight = maxY - minY;

    const dx = -(minX - 10);
    const dy = -(minY - 10);

    confirm({
      stroke,
      strokeWidth: strokeWidth,
      width: boundingWidth + 20,
      height: boundingHeight + 20,
      path: paths.reduce(
        (fullPath, lineItem) =>
          `${fullPath}${lineItem[0]}${lineItem[1] + dx}, ${lineItem[2] + dy}`,
        ""
      ),
    });

    closeModal();
  };

  const closeModal = () => {
    resetDrawingBoard();
    dismiss();
  };

  const strokeSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleColorSelect = (color: Color) => {
    setStroke(color);
  };

  const handleStrokeWidthSelect = (size: number) => {
    setStrokeWidth(size);
  };

  return (
    <div
      className={`fixed inset-0 justify-center items-center z-10 ${
        open ? "flex" : "hidden"
      } `}
    >
      <div className="absolute inset-0 bg-black opacity-75"></div>
      <div className="relative bg-white p-6 w-[85%] lg:w-[60%] rounded-md">
        <div className="flex justify-between items-center mb-4  ">
          <h2 className="text-xl font-bold text-gray-800">Add your Drawing</h2>
          <button
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            onClick={closeModal}
          >
            <MdOutlineClose
              size={30}
              className="text-red-500 hover:text-red-600"
            />
          </button>
        </div>
        <div
          className="relative bg-gray-100 rounded-md "
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <svg
            ref={svgRef}
            className=" z-50 cursor-cell h-24"
            style={{
              width: "100%",
            }}
          >
            <g className="z-50 ">
              {paths.map((lineItem, index) => (
                <path
                  className="z-50 absolute"
                  key={index}
                  strokeWidth={strokeWidth}
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  stroke={stroke}
                  fill="none"
                  d={`${lineItem[0]}${lineItem[1]}, ${lineItem[2]}`}
                />
              ))}
            </g>
            <path
              className=""
              strokeWidth={strokeWidth}
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={stroke}
              fill="none"
              d={path} // Use the currentPath variable
            />
          </svg>
        </div>
        <div className="flex flex-col justify-between mt-4 gap-2">
          <div className="flex-1">
            <p className="font-bold">Stroke Width:</p>
            <div className="flex gap-2">
              {strokeSizes.map((size) => (
                <button
                  key={size}
                  className={`w-8 h-8 border-2 border-gray-400 rounded-full bg-white hover:bg-gray-200 ${
                    strokeWidth === size ? "ring-4 ring-primary" : ""
                  }`}
                  style={{ borderWidth: size }}
                  onClick={() => handleStrokeWidthSelect(size)}
                ></button>
              ))}
            </div>
          </div>
          <div className="flex-1">
            <p className="font-bold">Color:</p>
            <div className="flex gap-2">
              {Object.values(Color).map((color) => (
                <button
                  key={color}
                  className={`w-8 h-8 rounded-full ${
                    stroke === color ? "ring-4 ring-primary" : ""
                  }`}
                  style={{ backgroundColor: color }}
                  onClick={() => handleColorSelect(color as Color)}
                ></button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4 gap-2">
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md flex gap-2 items-center"
            onClick={closeModal}
          >
            <MdRemoveCircleOutline size={20} className="inline-block" />
            Cancel
          </button>
          <button
            className="bg-primary hover:bg-[#7f55d3] text-white font-bold py-2 px-4 rounded-md flex gap-2 items-center"
            onClick={handleDone}
          >
            <MdDone size={20} className="inline-block" />
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default DrawingModal;
