import { AiOutlineDelete } from "react-icons/ai";
interface MultiplePdfUploadedProps {
  uploadedPdfs: any;
  setUploadedPdfs?: React.Dispatch<React.SetStateAction<any>>;
  mergingPdfs?: (uploadingFiles: any) => void;
}

export const MultiplePdfUploaded = ({
  uploadedPdfs,
  setUploadedPdfs = () => {},
  mergingPdfs = () => {},
}: MultiplePdfUploadedProps) => {
  return (
    <div className="mt-4 py-4 m w-[100%]  max-w-[800px] mx-auto">
      <div>
        <div className="inputs flex flex-col">
          <h2 className="font-semibold text-sm md:text-base">Uploaded Files</h2>
          <p className="text-xs md:text-sm text-gray-500 mt-2">
            These are the uploaded files.
          </p>
          <div className="mt-4">
            <div className="flex gap-2 w-full py-3">
              <div className="name flex gap-3 sm:gap-5 flex-wrap">
                {uploadedPdfs.map((pdf: any, index: number) => (
                  <div
                    key={index}
                    className="w-[169px] sm:w-[172px] h-[200px] border border-gray-300 flex flex-col"
                  >
                    <div className="flex justify-center items-center  bg-gray-50 flex-1">
                      <img
                        src="https://i.imgur.com/U9fUe5h.png"
                        className="w-[90px] h-[90px]"
                        draggable="false"
                        alt="paper"
                      ></img>
                    </div>
                    <div className="h-[50px] gap-3  border-t border-gray-300 flex items-center justify-between text-[13px] px-2 pr-3 py-1 overflow-hidden">
                      <div className="line-clamp-2">
                        {pdf?.name?.slice(0, -4)}
                      </div>
                      <button
                        onClick={async () => {
                          await setUploadedPdfs((prev: any) => {
                            const updatedPdfs = [...prev];
                            updatedPdfs.splice(index, 1);
                            mergingPdfs(updatedPdfs);
                            return updatedPdfs;
                          });
                        }}
                      >
                        <AiOutlineDelete className="text-red-500 text-lg" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
