import React, { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import { GOHIGHLEVEL_ACCESS_EMAILS } from "../../constants/Integrations/goHighLevel";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import MixPanel from "../../utils/services/mixpanel";
import { ALL_FEATURES } from "../../types/tiers";

interface Integration {
  name: string;
  key: string;
  description: string;
  logo: string;
  link: string;
}

interface IntegrationWithTokenCardProps {
  integration: Integration;
  index: number;
  owner: any;
  setShowUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpgradeModalType: React.Dispatch<React.SetStateAction<ALL_FEATURES>>;
}
interface UninstallAppProps {
  integration: Integration;
  setShowUninstallModal: React.Dispatch<React.SetStateAction<boolean>>;
  removeIntegration: () => void;
}

const IntegrationWithTokenCard: React.FC<IntegrationWithTokenCardProps> = ({
  integration,
  index,
  owner,
  setShowUpgradeModal,
  setUpgradeModalType,
}) => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [isIntegrated, setIsIntegrated] = useState<boolean>(
    (owner?.integrationTokens?.[integration.key]?.access_token &&
      owner?.integrationTokens?.[integration.key]?.access_token !== "") ||
      false
  );
  const [showUninstallModal, setShowUninstallModal] = useState<boolean>(false);
  // Redux code ----------------------------------------------------------------------------------

  const dispatch = useAppDispatch();
  // const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  // const workSpaceDetails = useWorkSpaceDetails();
  // const ownerPlanDetails = useOwnerPlanDetails();
  const featuresEnabled = useIsFeatureEnabled();

  useEffect(() => {
    dispatch(getOwnerThunk());
    // dispatch(getOwnerPaymentStatusThunk());
  }, []);

  // Redux code end -------------------------------------------------------------------------------

  const removeIntegration = async () => {
    try {
      const { data } = await axios.post(`/${integration.key}/uninstall`, {
        ownerEmail: owner?.email,
      });
      if (data.success) {
        toast.success(data.message);
        setIsIntegrated(false);
        dispatch(getOwnerThunk());
      } else {
        toast.error(data.message);
      }
      setShowUninstallModal(false);
    } catch (err) {
      toast.error("Some error occured please try again!");
    }
  };

  useEffect(() => {
    if (owner?.integrationTokens?.[integration.key]?.access_token) {
      setIsIntegrated(
        owner?.integrationTokens?.[integration.key]?.access_token !== ""
      );
    }
  }, [owner, integration]);
  // for selected emails
  // if (
  //   integration.key === "hubspot" &&
  //   !HUBSPOT_ACCESS_EMAILS.includes(owner?.email)
  // ) {
  //   return <></>;
  // }
  // if (
  //   integration.key === "gohighlevel" &&
  //   !(GOHIGHLEVEL_ACCESS_EMAILS.includes(owner?.email) || isIntegrated)
  // ) {
  //   return <></>;
  // }
  return (
    <>
      <div
        className="flex flex-col border border-gray-200 shadow-sm rounded-md p-4 w-[45%] lg:w-[250px] cursor-pointer"
        key={index || integration.name}
      >
        <div className="flex justify-between items-center">
          <img
            src={integration.logo}
            alt={integration.name}
            className="w-10 h-10"
          />
          {isIntegrated && (
            <span className="text-[10px] md:text-[12px] text-green-600 flex font-semibold items-center justify-center">
              {" "}
              <TiTick className="w-4 h-4 md:w-6 md:h-6 " /> Connected
            </span>
          )}
        </div>
        <h3 className="text-lg font-bold mt-4">{integration.name}</h3>
        <p className="text-[12px] mt-2 mb-4"> {integration.description} </p>
        <div className="flex items-end">
          <span className="text-[10px] text-gray-600">
            {whiteLabelInfo?.settings?.organization?.name
              ? "Powered By BoloForms"
              : ""}
          </span>
          {isIntegrated ? (
            <div
              className="ml-auto mt-2 p-2 font-medium text-sm bg-primary text-white rounded-md "
              onClick={() => setShowUninstallModal(true)}
            >
              Uninstall
            </div>
          ) : (
            <div
              className="ml-auto mt-2 p-2 font-medium text-sm bg-primary text-white rounded-md "
              onClick={() => {
                new MixPanel().track("Integration", {
                  integration_action: `integration_${integration.key}_add`,
                  paid: true,
                });
                new MixPanel().increment(`integration_${integration.key}_add`);
                if (!featuresEnabled?.CRM_INTEGRATIONS) {
                  setShowUpgradeModal(true);
                  setUpgradeModalType("CRM_INTEGRATIONS");
                  return;
                }
                window.open(integration?.link, "_self");
              }}
            >
              Install
            </div>
          )}
        </div>
        {showUninstallModal && (
          <UninstallApp
            setShowUninstallModal={setShowUninstallModal}
            removeIntegration={removeIntegration}
            integration={integration}
          />
        )}
      </div>
    </>
  );
};

const UninstallApp: React.FC<UninstallAppProps> = ({
  integration,
  setShowUninstallModal,
  removeIntegration,
}) => {
  return (
    <div className="w-full h-screen inset-0 z-[999] fixed bg-black bg-opacity-20 flex justify-center items-center px-3 md:px-0">
      <div className="w-[95%] md:w-[700px] bg-white rounded-lg relative">
        <MdClose
          onClick={() => setShowUninstallModal(false)}
          className="absolute text-2xl top-4 right-3 md:right-4 text-gray-400 cursor-pointer"
        />
        <div className="p-4 md:p-6">
          <h1 className="text-lg md:text-xl font-semibold text-gray-700 pr-4">
            Are you sure you want to uninstall {integration?.name}?
          </h1>
          <p className="text-xs md:text-sm text-gray-500 mt-1">
            Uninstalling the integration will prevent BoloForms from sending
            requests to your {integration?.name} account.
          </p>
        </div>
        <div className="rounded-lg overflow-hidden hidden">
          <div className="w-full h-[400px]">
            <img
              src={`https://i.ytimg.com/vi_webp/wtK-UPUTLr0/maxresdefault.webp`}
              alt="thubmnail"
              className="h-full w-full"
            />
          </div>
        </div>
        <div className="p-4 md:p-6 flex justify-end gap-4">
          <button
            onClick={() => setShowUninstallModal(false)}
            className="px-4 py-2 rounded-lg text-gray-700 text-xs md:text-sm font-medium hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              removeIntegration();
            }}
            className="px-5 py-2  bg-red-600 rounded text-white ml-2 font-medium text-xs md:text-sm hover:opacity-75"
          >
            Uninstall
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntegrationWithTokenCard;
