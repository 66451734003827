import React, { ReactNode, useEffect, useState } from "react";
import { MdCheck, MdClose, MdLogout, MdSave } from "react-icons/md";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { BoloButton } from "../../../components/Common/Button/BoloButton";
import { BoloDropDown } from "../../../components/Common/DropDown/BoloDropDown";
import { BoloInput } from "../../../components/Common/Input/BoloInput";
import BoloLoader from "../../../components/Common/Loader/BoloLoader";
import BoloRadioButtonMui from "../../../components/Common/RadioButton/BoloRadioButtonMui";
import { INTERNAL_TOOLS_URL } from "../../../constants/constants";
import {
  addSearchFilter,
  BoloUser,
  getBoloUserInfoThunk,
  setFilter,
  setSearchQuery,
  updateWhiteLabelInfoThunk,
  whiteLabelCredsCheckThunk,
  WhiteLabelOwnerType,
} from "../../../redux/slices/whiteLabelSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import axios from "../../../utils/axios";
import { validateEmail } from "../../../utils/validateEmail";
import UserTable from "../Components/UserTable/UserTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import BoloDateCalendar from "../../../components/Common/DateCalender/BoloDateCalenderMui";
import { IoCaretBackOutline, IoCaretForwardOutline } from "react-icons/io5";
import ThemeSelector from "../Components/ThemeSelector/ThemeSelector";
import LogoSelector from "../Components/LogoSelector/LogoSelector";
import InfoContent from "../Components/InfoContent/InfoContent";
import CustomTooltip from "../../../components/EditForm/CustomTooltip";
import { partnerLogOut } from "../../../utils";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../../components/Common/PopOver/BoloPopOver";
import { FaPlus } from "react-icons/fa6";
import BulkUploadUsersContent from "../Components/BulkUploadUsers/BulkUploadUsers";
import BulkUserDeactivate from "../Components/BulkUserDeactivate/BulkUserDeactivate";
import BulkReviewUpload from "../Components/BulkReviewUpload/BulkReviewUpload";

const WhiteLabelHeader = () => {
  return (
    <>
      <div className="w-[100%] h-12 p-2 flex items-center justify-between px-4 shadow fixed top-0 left-0 right-0 text-white bg-primary-bolo">
        <p className="text-lg font-semibold">BoloForms Admin Dashboard</p>
        <BoloButton
          data-tooltip-id="partner-logout"
          data-tooltip-offset={10}
          className="flex items-center"
          variant={"bolo-secondary"}
          size="icon-sm"
          onClick={() => partnerLogOut()}
        >
          <MdLogout />
        </BoloButton>
      </div>
      <CustomTooltip
        helpers={{
          id: "partner-logout",
          content: "Logout",
          place: "left",
        }}
      />
    </>
  );
};

type ToolWrapperType = {
  content: ReactNode;
  heading: string;
  widthPercentage?: number;
};
const ToolWrapper = ({
  content,
  heading,
  widthPercentage,
}: ToolWrapperType) => {
  return (
    <div
      className="w-[100%] border rounded shadow"
      style={{ width: widthPercentage ? `${widthPercentage}%` : `` }}
    >
      <div className="w-[100%] border-b text-sm font-semibold p-2 bg-gray-200">
        {heading}
      </div>
      <div className="w-[100%] p-2 text-xs">{content}</div>
    </div>
  );
};

const WhiteLabelDataToolContent = () => {
  const dispatch = useAppDispatch();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelPartner
  );
  const whiteLabelSlice = useAppSelector(
    (state) => state?.root?.whiteLabelSlice
  );
  const [formData, setFormData] = useState<WhiteLabelOwnerType>(whiteLabelInfo);
  const SESSION_DURATION = [
    // { key: "60s", value: "60 Seconds" },
    { key: "1h", value: "1 Hour" },
    { key: "2h", value: "2 Hours" },
    { key: "4h", value: "4 Hours" },
    { key: "8h", value: "8 Hours" },
    { key: "12h", value: "12 Hours" },
    { key: "24h", value: "24 Hours" },
    { key: "1d", value: "1 Day" },
    { key: "2d", value: "2 Days" },
    { key: "3d", value: "3 Days" },
    { key: "7d", value: "7 Days" },
    { key: "30d", value: "30 Days" },
    { key: "365d", value: "365 Days" },
  ];

  useEffect(() => {
    setFormData(whiteLabelInfo);
  }, [whiteLabelInfo]);

  const handleSave = async () => {
    try {
      dispatch(updateWhiteLabelInfoThunk({ updatedOwner: formData }));
      toast.success("Updated Info Succesfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <div>
      <div className="flex w-[100%] flex-col md:flex-row md:flex-wrap text-xs pb-2 gap-4 justify-between">
        <div className="flex gap-2 flex-col border rounded p-2 w-full md:w-[49%] shadow">
          <p className="text-sm p-2 -mx-2 -mt-2 bg-gray-200">General Details</p>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Name</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Name"
              value={formData.name}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, name: e.target.value }))
              }
              type={"text"}
            />
            <InfoContent
              helper="Used for general Identification"
              id="partner-name"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Email</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Name"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
              disabled
              type={"email"}
            />
            <InfoContent
              helper="Used as Credentials to Login to the BoloForms Partner Dashboard"
              id="creds-email"
            />
          </div>
        </div>
        <div className="flex gap-2 flex-col border rounded p-2 w-full md:w-[49%] shadow">
          <p className="text-sm p-2 -mx-2 -mt-2 bg-gray-200">Domain Settings</p>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Domain</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Domain"
              value={formData.settings?.organization?.domain}
              onChange={(e) => {
                const copyFormData = { ...formData };
                if (copyFormData?.settings?.organization) {
                  copyFormData.settings.organization.domain = e.target.value;
                }
                setFormData(copyFormData);
              }}
              disabled
            />
            <InfoContent
              helper="Domain where the site is hosted"
              id="main-domain"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Dashboard Sub Domain</p>
            <BoloInput
              disabled
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Dashboard Sub Domainn"
              value={formData.settings?.organization?.links?.home}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization?.links) {
                  copyFormData.settings.organization.links.home =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="This is the Sub-Domain where the end-users will access the Main Dashboard"
              id="home-subdomain"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Forms Sub Domain</p>
            <BoloInput
              disabled
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Form Fill Sub-Domain"
              value={formData.settings?.organization?.links?.fill}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization?.links) {
                  copyFormData.settings.organization.links.fill =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="This is the Sub-Domain where the end-users will fill forms"
              id="form-subdomain"
            />
          </div>
        </div>
        <div className="flex gap-2 flex-col border rounded p-2 w-full md:w-[49%] shadow">
          <p className="text-sm p-2 -mx-2 -mt-2 bg-gray-200">
            Organization Details
          </p>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Organization Name</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Organization Name"
              value={formData.settings?.organization?.name}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization) {
                  copyFormData.settings.organization.name = e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="This is the name that will be used throughut the WebSite and Emails."
              id="org-name"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Mailing Email</p>
            <BoloInput
              disabled
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Mailing Email"
              value={formData.settings?.organization?.mailingEmail}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization) {
                  copyFormData.settings.organization.mailingEmail =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="All Mails will be sent using this Email"
              id="org-email"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Pricing Contact Email</p>
            <BoloInput
              type={"email"}
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Pricing Contact Email"
              value={formData.settings?.organization?.pricingContactEmail}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization) {
                  copyFormData.settings.organization.pricingContactEmail =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="In the billings page users will be able to contact to this email for pricing"
              id="pricing-email"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Signed By Stamp</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Signed By Stamp"
              value={formData.settings?.organization?.signedBy}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization) {
                  copyFormData.settings.organization.signedBy = e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="All Signatures are stamped with the following text"
              id="signed-by"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Organization Theme</p>
            <div className="w-[350px]">
              <ThemeSelector
                whiteLableOwnerInfo={formData}
                setWhiteLabelInfo={(info) => setFormData(info)}
              />
            </div>
          </div>
          <div className="flex items-start gap-4">
            <p className="p-1 w-[180px]">Organization Logo </p>
            <LogoSelector
              imageLink={formData?.settings?.organization?.logo}
              setImageLink={(imgLink: string) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization) {
                  copyFormData.settings.organization.favicon = imgLink;
                  copyFormData.settings.organization.logo = imgLink;
                }
                setFormData(copyFormData);
              }}
            />
          </div>
          {/* <div className="flex items-end gap-4">
          <p className="p-1 w-[180px]">Site Favicon *</p>
          <BoloInput
            variant={"bolo-primary"}
            className="w-[350px]"
            bolosize={"xs"}
            placeholder="Name"
            value={formData.settings?.organization?.name}
            onChange={(e) => {
              const copyFormData = JSON.parse(JSON.stringify(formData));
              if (copyFormData?.settings?.organization) {
                copyFormData.settings.organization.name = e.target.value;
              }
              setFormData(copyFormData);
            }}
          />
        </div> */}
        </div>
        <div className="flex gap-2 flex-col border rounded p-2 w-full md:w-[49%] shadow">
          <p className="text-sm p-2 -mx-2 -mt-2 bg-gray-200">Links</p>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Data Policy</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Data Policy"
              value={formData.settings?.organization?.policy?.data}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization?.policy) {
                  copyFormData.settings.organization.policy.data =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="Data policy is shown on the Login/Register page"
              id="data-policy"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Terms Of Service</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Terms Of Service"
              value={formData.settings?.organization?.policy?.tos}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization?.policy) {
                  copyFormData.settings.organization.policy.tos =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="Terms of Service are shown on the Login/Register page"
              id="tos-policy"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Privacy Policy</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Privacy Policy"
              value={formData.settings?.organization?.policy?.privacy}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization?.policy) {
                  copyFormData.settings.organization.policy.privacy =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="Privacy Policy is shown on the Login/Register page"
              id="privacy-policy"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Security Policy</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Privacy Policy"
              value={formData.settings?.organization?.policy?.security}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization?.policy) {
                  copyFormData.settings.organization.policy.security =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="Security Policy is shown on the Login/Register page"
              id="security-policy"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Help and Support</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Help and Support"
              value={formData.settings?.organization?.links?.help}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization?.links) {
                  copyFormData.settings.organization.links.help =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="In any E-Mail Help and Support is linked in the footer."
              id="help-support"
            />
          </div>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">Feature Request</p>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[350px]"
              bolosize={"xs"}
              placeholder="Feature Request"
              value={formData.settings?.organization?.links?.featureRequest}
              onChange={(e) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization?.links) {
                  copyFormData.settings.organization.links.featureRequest =
                    e.target.value;
                }
                setFormData(copyFormData);
              }}
            />
            <InfoContent
              helper="End Customers can request for features. From the 'Navbar Help button'"
              id="feature-request"
            />
          </div>
        </div>
        <div className="flex gap-2 flex-col border rounded p-2 w-full md:w-[49%] shadow">
          <p className="text-sm p-2 -mx-2 -mt-2 bg-gray-200">User Control</p>
          <div className="flex items-end gap-4">
            <p className="p-1 w-[180px]">User Session Duration</p>
            <BoloPopover>
              <BoloPopoverTrigger>
                <div className="px-1 py-0.5 rounded border w-[350px] hover:opacity-80">
                  {SESSION_DURATION.find(
                    (e) =>
                      e.key === formData.settings?.organization?.sessionExpiry
                  )?.value || "Default 7 Days"}
                </div>
              </BoloPopoverTrigger>
              <BoloPopoverContent align="end">
                {SESSION_DURATION.map((ses) => {
                  return (
                    <div
                      className={`px-2 py-1 border-b w-[350px] hover:opacity-80 text-xs hover:text-primary-bolo cursor-pointer ${
                        ses.key ===
                        formData.settings?.organization?.sessionExpiry
                          ? "bg-secondary-bolo text-primary-bolo"
                          : ""
                      }`}
                      onClick={() => {
                        const copyFormData = JSON.parse(
                          JSON.stringify(formData)
                        );
                        if (copyFormData?.settings?.organization) {
                          copyFormData.settings.organization.sessionExpiry =
                            ses.key;
                        }
                        setFormData(copyFormData);
                      }}
                    >
                      {ses.value}
                    </div>
                  );
                })}
              </BoloPopoverContent>
            </BoloPopover>
            <InfoContent
              helper="Will Logout User after specified amount of time."
              id="session-duration"
            />
          </div>
        </div>

        <div className="flex gap-2 flex-col border rounded p-2 w-full md:w-[49%] shadow">
          <p className="text-sm p-2 -mx-2 -mt-2 bg-gray-200">Login Reviews</p>
          <div className="flex flex-col">
            <BulkReviewUpload
              reviews={formData?.settings?.organization?.reviews || []}
              setReviews={(reviews) => {
                const copyFormData = JSON.parse(JSON.stringify(formData));
                if (copyFormData?.settings?.organization) {
                  copyFormData.settings.organization.reviews = reviews;
                }
                setFormData(copyFormData);
              }}
              handleSave={handleSave}
            />
          </div>
        </div>
      </div>
      <BoloButton
        variant={"bolo-primary"}
        size={"sm"}
        className="flex gap-4 items-center mt-3 px-8 sm:px-8"
        onClick={() => handleSave()}
        disabled={whiteLabelSlice?.updateWhiteLabelLoading}
      >
        <MdSave size={16} /> Save
      </BoloButton>
    </div>
  );
};

const EmailContent = () => {
  const [formData, setFormData] = useState({
    originalEmail: "",
    migrateToEmail: "",
  });
  const [loading, setLoading] = useState(false);

  const handleOriginal = (email: string) => {
    setFormData((prev) => ({ ...prev, originalEmail: email }));
  };

  const handleMigrateTo = (email: string) => {
    setFormData((prev) => ({ ...prev, migrateToEmail: email }));
  };

  const handleMigrate = async () => {
    try {
      if (!validateEmail(formData.originalEmail)) {
        return toast.error("Invalid Original email");
      }
      if (!validateEmail(formData.migrateToEmail)) {
        return toast.error("Invalid Migrate to email");
      }
      setLoading(true);
      let { data } = await axios.post(
        `${INTERNAL_TOOLS_URL}/internal-tools/migratePaidPlan`,
        formData
      );
      if (data?.success) {
        toast.success("Account Migrated successfully");
      } else {
        toast.error(data?.message || "Something went wrong");
      }
      setLoading(false);
      setFormData({ originalEmail: "", migrateToEmail: "" });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="flex w-[100%] flex-col text-xs pt-4 pb-2 gap-4">
      <div className="flex items-end gap-4">
        <p className="p-1 w-[180px]">Original Email</p>
        <BoloInput
          variant={"bolo-primary"}
          className="w-[350px]"
          bolosize={"xs"}
          placeholder="Email"
          value={formData.originalEmail}
          onChange={(e) => handleOriginal(e.target.value)}
          type={"email"}
        />
      </div>
      <div className="flex items-end gap-4">
        <p className="p-1 w-[180px]">Migrate To Email</p>
        <BoloInput
          variant={"bolo-primary"}
          className="w-[350px]"
          bolosize={"xs"}
          value={formData.migrateToEmail}
          onChange={(e) => handleMigrateTo(e.target.value)}
          placeholder="Migrate to Email"
          type={"email"}
        />
      </div>
      <BoloButton
        variant={"bolo-primary"}
        size={"xs"}
        className="w-[180px] mt-4"
        onClick={() => handleMigrate()}
        disabled={
          !formData.migrateToEmail || !formData.originalEmail || loading
        }
      >
        {loading ? "Migrating" : "Migrate"}
      </BoloButton>
    </div>
  );
};

const UserManagmentPlanContent = ({ users }: { users: BoloUser[] }) => {
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.helpers?.searchQuery
  );
  const searchFilters = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.helpers?.searchFilters
  );
  const helpers = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.helpers
  );
  const [currPage_, setCurrPage_] = useState(0);
  // filters logic
  // if the given filter is passed with empty value it is to be ignored
  // if it contains a value that value if to be used to construct filter
  const filters: {
    [key: string]: { name: string; values: { key: string; name: string }[] };
  } = {
    userPlanType: {
      name: "User Plan Type",
      values: [
        { key: "", name: "None" },
        { key: "free", name: "Free" },
        { key: "paid", name: "Paid" },
      ],
    },
  };
  const setSearchQueryhandler = (query: string) => {
    dispatch(setSearchQuery(query));
  };

  const handleSearch = ({ page }: { page: number }) => {
    if (!searchQuery) return;
    dispatch(
      getBoloUserInfoThunk({
        searchQuery: searchQuery,
        limit: 10,
        page: page,
        filters: searchFilters,
      })
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setCurrPage_(0);
      handleSearch({ page: 0 });
    }
  };

  useEffect(() => {
    setCurrPage_(0);
    handleSearch({ page: 0 });
  }, [searchFilters]);

  return (
    <div className="flex w-[100%] flex-col pt-4 pb-2 gap-4">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <BoloInput
            variant={"bolo-primary"}
            className="w-[300px]"
            bolosize={"xs"}
            placeholder="Search by name or email"
            value={searchQuery}
            onChange={(e) => setSearchQueryhandler(e.target.value)}
            onKeyDown={(e) => {
              handleKeyPress(e);
            }}
          />
          <BoloButton
            variant={"bolo-primary"}
            size={"xs"}
            disabled={!searchQuery}
            onClick={() => {
              setCurrPage_(0);
              handleSearch({ page: 0 });
            }}
          >
            Search
          </BoloButton>
          <BoloPopover>
            <BoloPopoverTrigger>
              <BoloButton variant={"bolo-secondary"} size={"xs"}>
                Filters
              </BoloButton>
            </BoloPopoverTrigger>
            <BoloPopoverContent align="start">
              <div className="w-[400px] min-h-8 p-2">
                {searchFilters?.map((filter) => {
                  return (
                    <div className="flex border rounded h-8 p-1 px-3 text-xs justify-between items-center">
                      {filters[filter.key]?.name}{" "}
                      <BoloPopover>
                        <BoloPopoverTrigger>
                          <div className="border h-6 rounded min-w-[160px] text-left p-1 px-3 flex items-center text-gray-600 ">
                            {filters[filter.key].values.find(
                              (_) => _.key == filter.value
                            )?.name || "None"}
                          </div>
                        </BoloPopoverTrigger>
                        <BoloPopoverContent align="start">
                          <div className="flex flex-col max-h-20 w-[160px]">
                            {filters[filter.key]?.values?.map((filterValue) => {
                              return (
                                <div
                                  className="text-xs text-gray-600 p-1 px-3 cursor-pointer w-full hover:bg-secondary-bolo"
                                  onClick={() =>
                                    dispatch(
                                      setFilter({
                                        key: filter.key,
                                        value: filterValue.key,
                                      })
                                    )
                                  }
                                >
                                  {filterValue.name}
                                </div>
                              );
                            })}
                          </div>
                        </BoloPopoverContent>
                      </BoloPopover>
                    </div>
                  );
                })}
                {/* <p
                  className="flex items-center p-1 px-3 mt-3 text-xs gap-3 text-gray-600 cursor-pointer hover:bg-gray-100 rounded w-fit "
                  onClick={() => dispatch(addSearchFilter(undefined))}
                >
                  <FaPlus /> Add
                </p> */}
              </div>
            </BoloPopoverContent>
          </BoloPopover>
        </div>
        <div className="flex gap-2">
          <IoCaretBackOutline
            className="hover:text-primary-bolo cursor-pointer"
            size={20}
            onClick={() => {
              let page = Math.max(0, currPage_ - 1);
              handleSearch({ page });
              setCurrPage_(page);
            }}
          />
          Page {currPage_ + 1} of {helpers?.numPages}
          <IoCaretForwardOutline
            className="hover:text-primary-bolo cursor-pointer"
            size={20}
            onClick={() => {
              let page = Math.min(helpers?.numPages - 1, currPage_ + 1);
              handleSearch({ page });
              setCurrPage_(page);
            }}
          />
        </div>
      </div>
      <UserTable users={users} />
    </div>
  );
};

const UserCurrentPlanContent = () => {
  const selectedBoloUser = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.helpers?.selectedUser
  );

  const currentPlan =
    selectedBoloUser?.planhistory?.[selectedBoloUser?.planhistory?.length - 1];

  return (
    <div className="flex flex-col border rounded-md">
      <div className="flex bg-gray-100 font-semibold">
        <div className="w-[50%] p-2">Key</div>
        <div className="w-[50%] p-2">Value</div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Tier</div>
        <div className="w-[50%] p-2">{currentPlan?.tier}</div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Plan Name</div>
        <div className="w-[50%] p-2">{currentPlan?.planName}</div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Plan ID</div>
        <div className="w-[50%] p-2">{currentPlan?.planId}</div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Plan Price</div>
        <div className="w-[50%] p-2">{currentPlan?.planPrice}</div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Amount Paid</div>
        <div className="w-[50%] p-2">{currentPlan?.amountPaid}</div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Currency Code</div>
        <div className="w-[50%] p-2">{currentPlan?.currencyCode}</div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Is Active</div>
        <div className="w-[50%] p-2">
          {currentPlan?.isActive ? <MdCheck /> : <MdClose />}
        </div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Charged At</div>
        <div className="w-[50%] p-2">
          {currentPlan?.chargedAt &&
            new Date(currentPlan?.chargedAt).toISOString()}
        </div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Used Quantity</div>
        <div className="w-[50%] p-2 text-ellipsis overflow-hidden max-w-[230px]">
          {JSON.stringify(currentPlan?.usedQuanity)}
        </div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Allowed Quantity</div>
        <div className="w-[50%] p-2 text-ellipsis overflow-hidden max-w-[230px]">
          {JSON.stringify(currentPlan?.usageQuantityAllowed)}
        </div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Charge Retry Count</div>
        <div className="w-[50%] p-2">{currentPlan?.chargeRetryCount}</div>
      </div>
      <div className="flex">
        <div className="w-[50%] p-2">Created At</div>
        <div className="w-[50%] p-2">
          {currentPlan?.createdAt &&
            new Date(currentPlan?.createdAt).toISOString()}
        </div>
      </div>
    </div>
  );
};

const UpdateUserPlanContent = () => {
  const dispatch = useAppDispatch();
  const planIdOptions = [
    { key: "FREE_PLAN", value: "Free Plan" },
    { key: "FREE_PLAN_TRIAL", value: "Free Plan Trial" },
    { key: "PAID_PLAN", value: "Paid Plan" },
    { key: "PAID_PLAN_TRIAL", value: "Paid Plan Trial" },
  ];
  const planNameOptions = [
    { key: "FREE_TIER_V1", value: "Free Plan" },
    { key: "TIER_1_V1", value: "Freelancer Plan" },
    { key: "TIER_2_V1", value: "Team Plan" },
    { key: "TIER_3_V1", value: "Growing Business Plan" },
    // { key: "BOLOFORMS_LIFETIME_V2", value: "Life Time Plan" },
  ];

  const searchQuery = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.helpers?.searchQuery
  );
  const selectedBoloUser = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.helpers?.selectedUser
  );
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    addNewPlan: true,
    isTrial: false,
    planhistory:
      selectedBoloUser?.planhistory?.[
        selectedBoloUser?.planhistory?.length - 1
      ] || {},
    stripeCustomerId: selectedBoloUser?.stripeCustomerId,
    selectedPlanId: planIdOptions?.find(
      (value) =>
        value?.key ===
        selectedBoloUser?.planhistory?.[
          selectedBoloUser?.planhistory?.length - 1
        ]?.planId
    ) || { key: "FREE_PLAN", value: "Free Plan" },
    selectedPlanName: planNameOptions?.find(
      (value) =>
        value?.key ===
        selectedBoloUser?.planhistory?.[
          selectedBoloUser?.planhistory?.length - 1
        ]?.tier
    ) || { key: "FREE_TIER_V1", value: "Free Plan" },
    trialTill: dayjs(new Date()),
  });

  const handleSearch = () => {
    if (!searchQuery) return;
    dispatch(
      getBoloUserInfoThunk({ searchQuery: searchQuery, limit: 10, page: 0 })
    );
  };

  const handleUpdateQuota = async () => {
    try {
      setLoading(true);
      let { data } = await axios.post(
        `${INTERNAL_TOOLS_URL}/internal-tools/updateQuota`,
        {
          ownerId: selectedBoloUser?._id,
          addNewPlan: formData?.addNewPlan,
          planhistory: {
            ...formData?.planhistory,
            planName: formData?.selectedPlanName?.value,
            tier: formData?.selectedPlanName?.key,
            planId: formData?.selectedPlanId?.key,
          },
          isTrial: formData?.isTrial,
          stripeCustomerId: selectedBoloUser?.stripeCustomerId,
        }
      );
      if (data?.success) {
        toast.success("Plan updated successfully");
      }
      handleSearch();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      planhistory:
        selectedBoloUser?.planhistory?.[
          selectedBoloUser?.planhistory?.length - 1
        ] || {},
      stripeCustomerId: selectedBoloUser?.stripeCustomerId,
      selectedPlanId: planIdOptions?.find(
        (value) =>
          value?.key ===
          selectedBoloUser?.planhistory?.[
            selectedBoloUser?.planhistory?.length - 1
          ]?.planId
      ) || { key: "FREE_PLAN", value: "Free Plan" },
      selectedPlanName: planNameOptions?.find(
        (value) =>
          value?.key ===
          selectedBoloUser?.planhistory?.[
            selectedBoloUser?.planhistory?.length - 1
          ]?.tier
      ) || { key: "FREE_TIER_V1", value: "Free Plan" },
    }));
  }, [selectedBoloUser]);

  // console.log(formData?.selectedPlanName);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-stretch gap-2">
        <div className="flex flex-col gap-3 w-[100%] p-2 border rounded-md">
          <p className="text-sm font-semibold">
            {formData?.addNewPlan
              ? "You are adding a new plan"
              : "You are updating the existing plan"}
          </p>
          <p className="text-gray-700">
            {selectedBoloUser?.email
              ? `Showing Plan details for  "${selectedBoloUser?.email}"`
              : "Please Select an account to update"}
          </p>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Plan Name</div>
            <BoloDropDown
              size={"xs"}
              className="w-[65%]"
              options={planNameOptions}
              selectOption={(value) => {
                setFormData((prev) => ({ ...prev, selectedPlanName: value }));
              }}
              selectedOption={formData?.selectedPlanName}
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Plan ID</div>
            <BoloDropDown
              size={"xs"}
              className="w-[65%]"
              options={planIdOptions}
              selectOption={(value) => {
                setFormData((prev) => ({ ...prev, selectedPlanId: value }));
              }}
              selectedOption={formData?.selectedPlanId}
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Plan Price</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              type="nummber"
              value={formData?.planhistory?.planPrice}
              onChange={(e) => {
                let newPlanHistory = JSON.parse(
                  JSON.stringify(formData?.planhistory)
                );
                newPlanHistory.planPrice = isNaN(parseInt(e.target.value))
                  ? newPlanHistory.planPrice
                  : parseInt(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  planhistory: newPlanHistory,
                }));
              }}
              bolosize={"xs"}
              placeholder="Plan Price"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Discount Amount *</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              type="nummber"
              value={formData?.planhistory?.planPrice}
              onChange={(e) => {
                let newPlanHistory = JSON.parse(
                  JSON.stringify(formData?.planhistory)
                );
                newPlanHistory.planPrice = isNaN(parseInt(e.target.value))
                  ? newPlanHistory.planPrice
                  : parseInt(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  planhistory: newPlanHistory,
                }));
              }}
              bolosize={"xs"}
              placeholder="Plan Price"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Stripe Customer Id</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              value={formData?.stripeCustomerId}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  stripeCustomerId: e.target.value,
                }));
              }}
              bolosize={"xs"}
              placeholder="Stripe Customer Id"
            />
          </div>
          <div className="flex gap-2 mt-4">
            <div className="flex">
              <div className="flex items-center">Add a new plan </div>
              <BoloRadioButtonMui
                variant="bolo-primary"
                checked={formData?.addNewPlan}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    addNewPlan: e.target.checked,
                  }))
                }
              />
            </div>
            <div className="flex">
              <div className="flex items-center">Is Active </div>
              <BoloRadioButtonMui
                variant="bolo-primary"
                checked={formData?.planhistory?.isActive}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    planhistory: {
                      ...prev.planhistory,
                      isActive: e.target.checked,
                    },
                  }))
                }
              />
            </div>
            <div className="flex">
              <div className="flex items-center">Is Trial </div>
              <BoloRadioButtonMui
                variant="bolo-primary"
                checked={formData?.isTrial}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    isTrial: e.target.checked,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 w-fit p-2 border rounded-md">
          <p className="text-sm font-semibold">Trial Till</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BoloDateCalendar
              value={formData?.trialTill}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, trialTill: value }))
              }
            />
          </LocalizationProvider>
        </div>
        <div className="flex flex-col gap-3 w-[100%] p-2 pb-4 border rounded-md">
          <p className="text-sm font-semibold">Quotas</p>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Number of PDFs</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              type="nummber"
              value={formData?.planhistory?.usageQuantityAllowed?.[0]}
              onChange={(e) => {
                let newPlanHistory = JSON.parse(
                  JSON.stringify(formData?.planhistory)
                );
                newPlanHistory.usageQuantityAllowed[0] = isNaN(
                  parseInt(e.target.value)
                )
                  ? newPlanHistory.usageQuantityAllowed[0]
                  : parseInt(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  planhistory: newPlanHistory,
                }));
              }}
              bolosize={"xs"}
              placeholder="Number of PDFs"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Number of Forms</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              type="nummber"
              value={formData?.planhistory?.usageQuantityAllowed?.[1]}
              onChange={(e) => {
                let newPlanHistory = JSON.parse(
                  JSON.stringify(formData?.planhistory)
                );
                newPlanHistory.usageQuantityAllowed[1] = isNaN(
                  parseInt(e.target.value)
                )
                  ? newPlanHistory.usageQuantityAllowed[1]
                  : parseInt(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  planhistory: newPlanHistory,
                }));
              }}
              bolosize={"xs"}
              placeholder="Number of Forms"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Number of Responses</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              type="nummber"
              value={formData?.planhistory?.usageQuantityAllowed?.[2]}
              onChange={(e) => {
                let newPlanHistory = JSON.parse(
                  JSON.stringify(formData?.planhistory)
                );
                newPlanHistory.usageQuantityAllowed[2] = isNaN(
                  parseInt(e.target.value)
                )
                  ? newPlanHistory.usageQuantityAllowed[2]
                  : parseInt(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  planhistory: newPlanHistory,
                }));
              }}
              bolosize={"xs"}
              placeholder="Number of Responses"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Number of Collabs</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              type="nummber"
              value={formData?.planhistory?.usageQuantityAllowed?.[6]}
              onChange={(e) => {
                let newPlanHistory = JSON.parse(
                  JSON.stringify(formData?.planhistory)
                );
                newPlanHistory.usageQuantityAllowed[6] = isNaN(
                  parseInt(e.target.value)
                )
                  ? newPlanHistory.usageQuantityAllowed[6]
                  : parseInt(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  planhistory: newPlanHistory,
                }));
              }}
              bolosize={"xs"}
              placeholder="Number of Collabs"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Number of PDF Templates</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              type="nummber"
              value={formData?.planhistory?.usageQuantityAllowed?.[4]}
              onChange={(e) => {
                let newPlanHistory = JSON.parse(
                  JSON.stringify(formData?.planhistory)
                );
                newPlanHistory.usageQuantityAllowed[4] = isNaN(
                  parseInt(e.target.value)
                )
                  ? newPlanHistory.usageQuantityAllowed[4]
                  : parseInt(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  planhistory: newPlanHistory,
                }));
              }}
              bolosize={"xs"}
              placeholder="Number of PDF Templates"
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="w-[40%]">Number of Templates filled</div>
            <BoloInput
              variant={"bolo-primary"}
              className="w-[55%]"
              type="nummber"
              value={formData?.planhistory?.usageQuantityAllowed?.[5]}
              onChange={(e) => {
                let newPlanHistory = JSON.parse(
                  JSON.stringify(formData?.planhistory)
                );
                newPlanHistory.usageQuantityAllowed[5] = isNaN(
                  parseInt(e.target.value)
                )
                  ? newPlanHistory.usageQuantityAllowed[5]
                  : parseInt(e.target.value);
                setFormData((prev) => ({
                  ...prev,
                  planhistory: newPlanHistory,
                }));
              }}
              bolosize={"xs"}
              placeholder="Number of Templates filled"
            />
          </div>
        </div>
      </div>
      <BoloButton
        variant={"bolo-primary"}
        className="flex items-center w-fit gap-4 px-8 mt-4"
        size={"xs"}
        disabled={!selectedBoloUser?._id}
        onClick={() => handleUpdateQuota()}
      >
        <MdSave size={15} />
        {loading ? "Saving" : "Save"}
      </BoloButton>
    </div>
  );
};

const WhiteLabelAdminDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = [
    { key: "INFO", value: "General Info" },
    { key: "USERS", value: "User Management" },
    // { key: "MIGRATION", value: "Email Migration" },
  ];

  const userSubTabs = [
    {
      key: "USER_PLAN",
      value: "Plan Management",
    },
    { key: "USER_MIGRATION", value: "Email Migration" },
    { key: "BULK_USER_UPLOAD", value: "Bulk User Upload" },
    { key: "BULK_USER_DEACTIVATE", value: "Bulk User Deactivate" },
  ];

  const boloUsers = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.helpers?.owners
  );
  const whiteInfoSlice = useAppSelector(
    (state) => state?.root?.whiteLabelSlice
  );

  const tabHandler = (tab: string, subtab?: string) => {
    searchParams.set("tab", tab);
    if (subtab) {
      searchParams.set("subtab", subtab);
    }
    setSearchParams(searchParams);
  };

  // useEffect(() => {
  //   dispatch(
  //     getBoloUserInfoThunk({ searchQuery: "Ayush", limit: 10, page: 0 })
  //   );
  // }, []);

  useEffect(() => {
    // !Note : Uncomment in prod
    dispatch(whiteLabelCredsCheckThunk());
  }, []);

  // if we crate a common layout add this code to that comp
  useEffect(() => {
    let whitelabelowner_jwt = localStorage.getItem("x-partner-auth");
    if (whitelabelowner_jwt === null) {
      navigate("/partner/login");
    }
  }, []);
  //-----------------------

  if (whiteInfoSlice?.checkCredsLoading) {
    return (
      <div className="w-[100vw] min-h-[100vh] flex items-center justify-center">
        <BoloLoader />
      </div>
    );
  }

  return (
    <div className="w-[100vw] max-h-[100vh] flex flex-col pt-12">
      <WhiteLabelHeader />
      <div
        className="p-4 w-[100%] flex flex-col gap-5 overflow-y-auto pb-12 pt-12"
        id="mini-pdf-custom-scroll"
        style={{ minHeight: `calc(100vh - 48px)` }}
      >
        {/* tabs div */}
        <div className="flex w-[100%] h-10 gap-2 fixed z-[50] px-4 top-12 left-0 right-0 bg-white">
          {tabs?.map((tab) => {
            return (
              <div
                className={`px-4 py-2 text-sm font-semibold border-b-2 cursor-pointer hover:opacity-60 transition-all duration-300 ${
                  searchParams.get("tab") == tab.key
                    ? "border-b-primary-bolo"
                    : "border-b-transparent"
                }`}
                onClick={() => tabHandler(tab.key)}
              >
                {tab.value}
              </div>
            );
          })}
        </div>
        {/* Default view */}
        {(searchParams.get("tab") == "INFO" || !searchParams.get("tab")) && (
          <ToolWrapper
            heading="Partner Info"
            content={<WhiteLabelDataToolContent />}
          />
        )}

        {searchParams.get("tab") == "USERS" && (
          <>
            {/* user tabs div */}
            <div className="flex w-[100%] gap-2">
              {userSubTabs?.map((tab) => {
                return (
                  <div
                    className={`px-4 py-2 text-xs border-b-2 cursor-pointer hover:opacity-60 transition-all duration-300 ${
                      searchParams.get("subtab") == tab.key
                        ? "border-b-primary-bolo"
                        : "border-b-transparent"
                    }`}
                    onClick={() => tabHandler("USERS", tab.key)}
                  >
                    {tab.value}
                  </div>
                );
              })}
            </div>
            {(searchParams.get("subtab") == "USER_PLAN" ||
              !searchParams.get("subtab")) && (
              <>
                <div className="flex justify-between gap-2">
                  <ToolWrapper
                    heading="User Plan Managment"
                    widthPercentage={60}
                    content={<UserManagmentPlanContent users={boloUsers} />}
                  />
                  <ToolWrapper
                    heading="Current Plan"
                    widthPercentage={40}
                    content={<UserCurrentPlanContent />}
                  />
                </div>
                <ToolWrapper
                  heading="Update User Plan"
                  content={<UpdateUserPlanContent />}
                />
              </>
            )}
            {searchParams.get("subtab") == "USER_MIGRATION" && (
              <ToolWrapper
                heading="Email Migration"
                content={<EmailContent />}
              />
            )}
            {searchParams.get("subtab") == "BULK_USER_UPLOAD" && (
              <ToolWrapper
                heading="Bulk User Upload"
                content={<BulkUploadUsersContent />}
              />
            )}
            {searchParams.get("subtab") == "BULK_USER_DEACTIVATE" && (
              <ToolWrapper
                heading="Bulk User Deactivate"
                content={<BulkUserDeactivate />}
              />
            )}
          </>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default WhiteLabelAdminDashboard;
