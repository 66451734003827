//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";

import {
  MdArrowDropDown,
  MdClose,
  MdLink,
  MdMailOutline,
  MdOutlineCode,
  MdOutlinePersonAddAlt,
} from "react-icons/md";
import { RiSendPlaneLine } from "react-icons/ri";
import {
  BASE_URL,
  getDashboardUrlV1,
  getFormViewerUrl,
} from "../../../constants/constants";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import axios from "../../../utils/axios";
import { Slide, ToastContainer, toast } from "react-toastify";
import { validateEmail } from "../../../utils/validateEmail";
import Papa from "papaparse";
import PleaseUpgradeModal from "../../PleaseUpgradeModal";
import { isFeatureEnabled } from "../../../utils/paidFeat";
import EmailSingleSelect from "../../Ui/EmailSingleSelect";
import MixPanel from "../../../utils/services/mixpanel";
import { BiSolidEditAlt } from "react-icons/bi";
import CustomTooltip from "../CustomTooltip";
import useIsFeatureEnabled from "../../../hooks/reduxHooks/useIsFeatureEnabled";
import { useAppDispatch } from "../../../redux/store";
import { getOwnerThunk } from "../../../redux/slices/ownerSlice";

const collectEmailOptions = {
  doNotCollect: "Do not collect",
  verified: "Verified",
  respondentInput: "Respondent input",
};

const SendModal = ({ helpers }) => {
  const [csvData, setCsvData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);

  let {
    form,
    formState,
    setFormState,
    setShow,
    isRespondentEmailVerify,
    setIsRespondentEmailVerify,
    ownerName,
  } = helpers;

  // Redux code ----------------------------------------------------------------------------------
  const dispatch = useAppDispatch();
  // const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  // const workSpaceDetails = useWorkSpaceDetails();
  // const ownerPlanDetails = useOwnerPlanDetails();
  const featuresEnabled = useIsFeatureEnabled();

  useEffect(() => {
    dispatch(getOwnerThunk());
    // dispatch(getOwnerPaymentStatusThunk());
  }, []);

  // Redux code end -------------------------------------------------------------------------------

  const [searchParams] = useSearchParams();
  let formId = searchParams.get("id");
  const navigate = useNavigate();
  const location = useLocation();
  const collectEmailDropdownRef = React.useRef(null);

  const [active, setActive] = React.useState({
    tab: "email",
  });
  const [email, setEmail] = React.useState({
    to: "",
    subject: formState?.title,
    message: "I've invited you to fill out a form ",
  });
  const [iframeDims, setIframeDims] = React.useState({
    width: 200,
    height: 700,
  });
  const [iframeLink, setIframeLink] = React.useState(
    `<iframe src="${getFormViewerUrl()}/${formId}?p=view" width= 200px" height=700px" name="BoloForms"></iframe>`
  );
  const [isCollectEmailOpen, setIsCollectEmailOpen] = useState(false);
  const [activeCollectEmailOption, setActiveCollectEmailOption] =
    useState("respondentInput");
  const toggleDropdown = () => {
    setIsCollectEmailOpen(!isCollectEmailOpen);
  };

  const owner = JSON.parse(localStorage.getItem("owner") || "{}");

  const [customUrlOptions, setCustomUrlOptions] = useState({
    setCustomUrl: false,
    customUrlText: form?.uniqueShortName ? form?.uniqueShortName : "",
    fixedUrl: `${getFormViewerUrl(BASE_URL)}/form/`,
    isCustomUrlSet:
      form?.uniqueShortName && form.uniqueShortName !== "" ? true : false,
    editing: false,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const [upgradeModalType, setUpgradeModalType] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;

  const handleSendEmail = async () => {
    new MixPanel().track("Form", {
      form_action: "form_send_via_email",
      paid: false,
    });
    new MixPanel().increment("form_send_via_email");
    console.log("send email");
    if (!email.to || !email.subject || !email.message) {
      return toast.error("Please fill all fields!");
    }
    if (!validateEmail(email.to)) {
      return toast.error("Please enter a valid email!");
    }
    await axios.post("/forms/send-form-email", {
      // formId: form?._id,
      formId: formId,
      email,
      ownerName,
    });
    setShow((prev) => ({ ...prev, sendModal: false }));
  };
  const handleCopyLink = (e) => {
    new MixPanel().track("Form", {
      form_action: "form_send_via_link",
      paid: false,
    });
    new MixPanel().increment("form_send_via_link");
    if (customUrlOptions.setCustomUrl) {
      navigator.clipboard.writeText(
        customUrlOptions.fixedUrl + customUrlOptions.customUrlText
      );
    } else {
      navigator.clipboard.writeText(form?.formUrl);
    }
    inputRef.current && inputRef.current.select();
    e?.target?.select && e.target.select();
  };
  const handleCopyEmbed = (e) => {
    new MixPanel().track("Form", {
      form_action: "form_send_via_iframe",
      paid: false,
    });
    new MixPanel().increment("form_send_via_iframe");
    navigator.clipboard.writeText(iframeLink);
    e.target.select();
  };

  const handleCollectRespondentEmail = async (collect) => {
    if (!collect) {
      setIsRespondentEmailVerify(false);
      await setFormState((prevState) => {
        const newForm = { ...prevState };
        newForm.pages[0].questions = newForm.pages[0].questions.filter(
          (question) => question.validation !== "respondent_email"
        );
        return newForm;
      });
      // await handleSave();
      return;
      // toast.success("Collect respondent email disabled!");
    }

    let collectEmailQuestion = {
      type: "Text",
      title: `Email`,
      description: "",
      required: true,
      id: v4(),
      value: "",
      validation: "respondent_email",
    };

    await setFormState((prevState) => {
      const newForm = { ...prevState };
      const firstQuestion = newForm.pages[0].questions[0];
      if (firstQuestion.validation === "respondent_email") {
        return newForm;
      }
      newForm.pages[0].questions.unshift(collectEmailQuestion);
      return newForm;
    });

    // handleSave();
    // toast.success("Collect respondent email enabled!");
  };

  const handleEmailDoNotCollect = () => {
    handleCollectRespondentEmail(false);
    setIsCollectEmailOpen((prev) => !prev);
    setActiveCollectEmailOption("doNotCollect");
  };
  const handlEmailVerified = () => {
    setIsRespondentEmailVerify(true);
    handleCollectRespondentEmail(true);
    setIsCollectEmailOpen((prev) => !prev);
    setActiveCollectEmailOption("verified");
  };
  const handleEmailRespondentInput = () => {
    handleCollectRespondentEmail(true);
    setIsCollectEmailOpen((prev) => !prev);
    setActiveCollectEmailOption("respondentInput");
  };

  const initiateBulkSubmit = async (templateId) => {
    new MixPanel().track("Form", { form_action: "form_bulk_send", paid: true });
    new MixPanel().increment("form_bulk_send");

    const isEmailBrandingEnabled = await isFeatureEnabled("BULK_SEND");
    console.log({ form });
    if (!isEmailBrandingEnabled && owner?.email === form?.authorEmail) {
      setShowUpgradeModal(true);
      setUpgradeModalType("BULK_SEND");
      // setUpgradeModalData({
      //   title: "Bulk Send is available in Team Plan and above",
      //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
      //   videoSrc: "",
      // });
      return;
    }
    if (!csvFile || !csvData || csvData.length === 0) {
      return toast.error("No CSV file or empty CSV data!");
    }

    try {
      const owner = JSON.parse(localStorage.getItem("owner"));

      if (!owner) {
        console.log("Owner data not found in local storage.");
        return;
      }

      const activePlan = owner.planhistory.find(
        (plan) => plan.isActive === true
      );

      // if (activePlan.usedQuanity[2] === activePlan.usageQuantityAllowed[2]) {
      //   toast.error("You have reached the maximum allowed quantity.");
      //   setShowBulkUploadModal((prev) => {
      //     return {
      //       ...prev,
      //       state: !prev.state,
      //     };
      //   });

      //   return;
      // }

      const emailData = [];

      let reachedMaxQuantity = false;

      for (const currentRow of csvData) {
        const email = currentRow.email;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (emailRegex.test(email)) {
          if (activePlan.usedQuanity[2] < activePlan.usageQuantityAllowed[2]) {
            emailData.push({
              ...currentRow,
            });

            activePlan.usedQuanity[2]++; // Increment the usedQuanity
          } else {
            reachedMaxQuantity = true;
            break; // Stop processing if quota is reached
          }
        } else {
          console.log(`Skipping row: Invalid email format - ${email}`);
        }
      }

      if (emailData.length === 0) {
        console.log("No valid email addresses found.");
        toast.error("No valid email addresses found.");
        return;
      }

      const res = await axios.post(`/forms/bulk-request`, {
        receiversList: emailData,
        documentId: templateId,
        ownerId: owner._id,
      });

      if (res.status === 200) {
        if (reachedMaxQuantity) {
          toast.warning(
            "You reached the maximum allowed quantity but some emails were sent successfully."
          );
        } else {
          toast.success("Email(s) sent successfully");
        }
        setShow((prev) => ({ ...prev, sendModal: false }));
      } else {
        toast.error("Bulk request failed!");
      }
    } catch (error) {
      console.error("Error during bulk submit:", error);
    }
  };
  const handleOutsideClick = (e) => {
    if (collectEmailDropdownRef.current !== null) {
      if (collectEmailDropdownRef.current.contains(e.target)) return;
    }
    setIsCollectEmailOpen(false);
  };
  const checkUniqueShortUrl = async () => {
    new MixPanel().track("Form", {
      form_action: "form_set_custom_url",
      paid: true,
    });
    new MixPanel().increment("form_set_custom_url");
    let pattern = /^[^\/`~!@#$%^&*()=+{}[\]\\|;:'",<>?]+$/;
    if (!pattern.test(customUrlOptions?.customUrlText)) {
      toast.error("Please remove special characters");
      return;
    }
    if (!featuresEnabled?.FORM_CUSTOM_URL) {
      setShowUpgradeModal(true);
      setUpgradeModalType("FORM_CUSTOM_URL");
      // setUpgradeModalData({
      //   title: "Bulk Send is available in Team Plan and above",
      //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
      //   videoSrc: "",
      // });
      return;
    }

    try {
      if (
        customUrlOptions.editing &&
        customUrlOptions.customUrlText === form?.uniqueShortName
      ) {
        toast.success("Url same as before!!");
        setCustomUrlOptions((prev) => ({
          ...prev,
          customUrlText: form?.uniqueShortName,
          editing: false,
          isCustomUrlSet: true,
        }));
        return;
      }
      const { data } = await axios.put("/forms/check-unique-url", {
        formId,
        shortUrl: customUrlOptions.customUrlText,
      });
      console.log("data after changing url", data);
      if (data?.isSet) {
        form.uniqueShortName = customUrlOptions.customUrlText;
        toast.success(data?.message);
        setCustomUrlOptions((prev) => ({
          ...prev,
          isCustomUrlSet: true,
        }));
      } else {
        toast.error(data?.error);
        setCustomUrlOptions((prev) => ({
          ...prev,
          customUrlText: "",
          isCustomUrlSet: false,
        }));
      }
    } catch (err) {
      toast.error(err);
      console.error(err);
    }
  };

  const editCustomUrl = () => {
    toast.warning("Previous links shared would stop working!");
    setCustomUrlOptions((prev) => ({
      ...prev,
      customUrlText: "",
      isCustomUrlSet: false,
      editing: true,
    }));
  };

  useEffect(() => {
    let isCollectEmail =
      formState?.pages?.[0]?.questions?.[0]?.validation === "respondent_email";
    let activeEmailOption =
      isCollectEmail && isRespondentEmailVerify
        ? "verified"
        : isCollectEmail
        ? "respondentInput"
        : "doNotCollect";
    setActiveCollectEmailOption(activeEmailOption);

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const updateEmailValue = (newValue) => {
    setEmail((prev) => ({ ...prev, to: newValue.trim() }));
  };

  return (
    <div className="px-[38px] pt-[40px] fixed inset-0 bg-black bg-opacity-40 flex justify-center z-50">
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      <div className="fade w-[590px] max-h-[90vh] overflow-y-auto h-fit bg-white rounded-lg border">
        <div className="pl-6 pr-4 py-4 flex justify-between">
          <h2 className="text-xl">Send form</h2>
          <MdClose
            onClick={() => {
              setShow((prev) => ({ ...prev, sendModal: false }));
            }}
            className="text-2xl text-gray-600 cursor-pointer"
          />
        </div>
        <div className="h-[66px] px-6 py-3 mb-6 flex items-center justify-between bg-[#f1f3f4]">
          <h2 className="text-sm">Collect email addresses</h2>
          <div className="relative ml-2">
            <div className="relative">
              <div
                onClick={toggleDropdown}
                className="h-[40px] min-w-[176px] bg-white border border-[#dadce0] py-2 px-3 rounded flex items-center cursor-pointer"
              >
                <div className="ml-1 mr-2">
                  <span className="text-gray-700 text-sm">
                    {collectEmailOptions[activeCollectEmailOption]}
                  </span>
                </div>
                <div className="ml-auto">
                  <MdArrowDropDown className="text-2xl text-gray-500" />
                </div>
              </div>
              {isCollectEmailOpen && (
                <div
                  ref={collectEmailDropdownRef}
                  style={{
                    marginTop: "-48px",
                    position: "absolute",
                    boxShadow:
                      "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
                  }}
                  className="py-2 min-w-[178px] bg-white border border-[#0000001f] rounded z-50"
                >
                  <div
                    onClick={handleEmailDoNotCollect}
                    className={`${
                      activeCollectEmailOption === "doNotCollect" &&
                      "bg-[#e4f0fc]"
                    } h-[48px] w-full py-2 px-3 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <div className="ml-1 mr-2">
                      <span className="text-gray-600 text-sm">
                        Do not collect
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={handlEmailVerified}
                    className={`${
                      activeCollectEmailOption === "verified" && "bg-[#e4f0fc]"
                    } h-[48px] w-full py-2 px-3 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <div className="ml-1 mr-2">
                      <span className="text-gray-600 text-sm">Verified</span>
                    </div>
                  </div>
                  <div
                    onClick={handleEmailRespondentInput}
                    className={`${
                      activeCollectEmailOption === "respondentInput" &&
                      "bg-[#e4f0fc]"
                    } h-[48px] w-full py-2 px-3 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <div className="ml-1 mr-2">
                      <span className="text-gray-600 text-sm">
                        Respondent input
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="h-[41px] flex border-b">
          <div
            className={`pl-6 pr-3 text-[#3C4043] relative h-full text-sm w-fit font-medium flex items-center`}
          >
            Send via
          </div>
          <div
            onClick={() => setActive((prev) => ({ ...prev, tab: "email" }))}
            style={{
              color:
                active.tab === "email" ? formState?.accentColor : "#3C4043",
            }}
            className={`w-[84px] relative h-full text-sm font-medium flex items-center justify-center cursor-pointer`}
          >
            <MdMailOutline className="text-2xl" />

            {active.tab === "email" && (
              <div
                style={{
                  background: formState?.accentColor,
                }}
                className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
              ></div>
            )}
          </div>
          <div
            onClick={() => setActive((prev) => ({ ...prev, tab: "link" }))}
            style={{
              color: active.tab === "link" ? formState?.accentColor : "#3C4043",
            }}
            className={` w-[84px] relative h-full text-sm font-medium flex items-center justify-center cursor-pointer`}
          >
            <MdLink className="text-2xl" />
            {active.tab === "link" && (
              <div
                style={{
                  background: formState?.accentColor,
                }}
                className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
              ></div>
            )}
          </div>
          <div
            onClick={() => setActive((prev) => ({ ...prev, tab: "embed" }))}
            style={{
              color:
                active.tab === "embed" ? formState?.accentColor : "#3C4043",
            }}
            className={`w-[84px] relative h-full text-sm font-medium flex items-center justify-center cursor-pointer`}
          >
            <MdOutlineCode className="text-2xl" />

            {active.tab === "embed" && (
              <div
                style={{
                  background: formState?.accentColor,
                }}
                className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
              ></div>
            )}
          </div>
          <div
            onClick={() => setActive((prev) => ({ ...prev, tab: "bulkSend" }))}
            style={{
              color:
                active.tab === "bulkSend" ? formState?.accentColor : "#3C4043",
            }}
            className={`w-[84px] relative h-full text-sm font-medium flex items-center justify-center cursor-pointer`}
          >
            <RiSendPlaneLine className="text-xl" />

            {active.tab === "bulkSend" && (
              <div
                style={{
                  background: formState?.accentColor,
                }}
                className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
              ></div>
            )}
          </div>
        </div>
        <div className="pt-6 pb-4">
          {active.tab === "email" && (
            //adding hidden because for now this feature is not available, flex tha maine hidden kiya hai
            <div className="px-6 flex flex-col">
              <h2 className="text-base font-medium text-gray-800">Email</h2>
              <div className="flex flex-col items-center">
                <p className="mt-6 w-full text-[10px] text-gray-500">To</p>
                <EmailSingleSelect
                  selectedValue={email.to}
                  updateEmailValue={updateEmailValue}
                  isCustomStyle={true}
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="mt-4 w-full text-[10px] text-gray-500">Subject</p>
                <input
                  type="text"
                  value={email.subject}
                  placeholder="subject"
                  onChange={(e) => {
                    setEmail((prev) => ({ ...prev, subject: e.target.value }));
                  }}
                  className="mt-2 text-sm w-full pb-1 text-gray-700 border-b border-gray-200 hover:border-gray-200 disabled:hover:border-white focus:border-b-2 focus:border-[#4c2b87] outline-none"
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="mt-4 w-full text-[10px] text-gray-500">Message</p>
                <input
                  type="text"
                  value={email.message}
                  placeholder="message"
                  onChange={(e) => {
                    setEmail((prev) => ({ ...prev, message: e.target.value }));
                  }}
                  className="mt-2 text-sm w-full pb-1 text-gray-700 border-b border-gray-200 hover:border-gray-200 disabled:hover:border-white focus:border-b-2 focus:border-[#4c2b87] outline-none"
                />
              </div>
              <div className="h-[56px] mt-6 flex items-center justify-between">
                <div
                  onClick={() => {
                    window.open(
                      `${getDashboardUrlV1()}/settings/collaborators?id=${
                        form?._id
                      }`,
                      "_blank"
                    );
                    // navigate(`/settings/collaborators?id=${form?._id}`);
                  }}
                  // adding invisible cause for now this feature is not available
                  className="h-[36px] w-fit px-2 text-blue-500 font-medium text-sm rounded-md hover:bg-blue-50 flex items-center cursor-pointer invisible"
                >
                  <MdOutlinePersonAddAlt className="text-2xl mr-2" />
                  Add editor
                </div>
                <div className="flex">
                  <button
                    onClick={() => {
                      setShow((prev) => ({ ...prev, sendModal: false }));
                    }}
                    className="h-[36px] w-[84px] px-2 mr-2 text-gray-600 text-sm flex items-center justify-center cursor-pointer"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSendEmail}
                    style={{
                      color: formState?.accentColor,
                    }}
                    className="h-[36px] w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
          {active.tab === "link" && (
            <div className="px-6 flex flex-col">
              <h2 className="text-base flex  gap-2 items-center font-medium text-gray-800">
                Link
                {customUrlOptions.setCustomUrl &&
                  customUrlOptions.isCustomUrlSet && (
                    <button onClick={editCustomUrl} data-tooltip-id="editUrl">
                      <BiSolidEditAlt
                        size={20}
                        className="cursor-pointer text-gray-500"
                      />
                    </button>
                  )}
                <CustomTooltip
                  helpers={{
                    id: "editUrl",
                    content: "Edit Url",
                    place: "right",
                  }}
                />
              </h2>
              {customUrlOptions.setCustomUrl ? (
                <>
                  <div className="flex items-center mt-2 ">
                    {!customUrlOptions.isCustomUrlSet && (
                      <span
                        className="mt-2 text-sm w-full pb-1 text-gray-700 border-b-2 border-gray-200 "
                        onClick={() => {
                          if (inputRef.current) {
                            inputRef.current.focus();
                          }
                        }}
                      >
                        {customUrlOptions.fixedUrl}
                      </span>
                    )}
                    <input
                      type="text"
                      ref={inputRef}
                      onClick={handleCopyLink}
                      value={
                        customUrlOptions.isCustomUrlSet
                          ? customUrlOptions.fixedUrl +
                            customUrlOptions.customUrlText
                          : customUrlOptions.customUrlText
                      }
                      onChange={(e) => {
                        if (!customUrlOptions.isCustomUrlSet) {
                          setCustomUrlOptions((prev) => ({
                            ...prev,
                            customUrlText: e.target.value,
                          }));
                        }
                      }}
                      placeholder="custom url"
                      className="mt-2 text-sm w-full pb-1 text-gray-700 border-b-2  border-gray-200 focus:border-b-2 focus:border-[#4c2b87] outline-none"
                    />
                  </div>
                  <div className="flex  items-center mt-3 ">
                    <input
                      type="checkbox"
                      checked={customUrlOptions.setCustomUrl}
                      onChange={() => {
                        setCustomUrlOptions((prev) => ({
                          ...prev,
                          setCustomUrl: !prev.setCustomUrl,
                        }));
                      }}
                      id="customUrl"
                      className="text-xl w-5 h-5 cursor-pointer hover:shadow-xl "
                    />
                    <label htmlFor="customUrl" className="text-sm ml-2">
                      Custom URL
                    </label>
                  </div>

                  <div className="h-[56px] flex items-center justify-between">
                    <div></div>
                    <div className="flex">
                      <button
                        onClick={() => {
                          setShow((prev) => ({ ...prev, sendModal: false }));
                        }}
                        className="h-[36px] w-[84px] px-2 mr-2 text-gray-600 text-sm flex items-center justify-center cursor-pointer"
                      >
                        Cancel
                      </button>
                      {customUrlOptions.isCustomUrlSet ? (
                        <button
                          onClick={handleCopyLink}
                          style={{
                            color: formState?.accentColor,
                          }}
                          className="h-[36px] w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                        >
                          Copy
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={checkUniqueShortUrl}
                            style={{
                              color: formState?.accentColor,
                            }}
                            className="h-[36px] w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                          >
                            Check
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col items-center mt-2">
                    <input
                      ref={inputRef}
                      type="text"
                      value={form?.formUrl}
                      onChange={(e) => {}}
                      onClick={handleCopyLink}
                      placeholder="subject"
                      className="mt-2 text-sm w-full pb-1 text-gray-700 border-b border-gray-200 focus:border-b-2 focus:border-[#4c2b87] outline-none"
                    />
                  </div>
                  <div className="flex items-center mt-3">
                    <input
                      type="checkbox"
                      checked={customUrlOptions.setCustomUrl}
                      onChange={() => {
                        setCustomUrlOptions((prev) => ({
                          ...prev,
                          setCustomUrl: !prev.setCustomUrl,
                        }));
                        // setShortenUrl((prev) => !prev);
                      }}
                      id="customUrl"
                      className="text-xl w-5 h-5 cursor-pointer hover:shadow-lg"
                    />
                    <label htmlFor="customUrl" className="text-sm ml-2">
                      Custom URL
                    </label>
                  </div>
                  <div className="h-[56px] flex items-center justify-between">
                    <div></div>
                    <div className="flex">
                      <button
                        onClick={() => {
                          setShow((prev) => ({ ...prev, sendModal: false }));
                        }}
                        className="h-[36px] w-[84px] px-2 mr-2 text-gray-600 text-sm flex items-center justify-center cursor-pointer"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleCopyLink}
                        style={{
                          color: formState?.accentColor,
                        }}
                        className="h-[36px] w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {active.tab === "embed" && (
            <div className="px-6 flex flex-col">
              <h2 className="text-base font-medium text-gray-800">
                Embed HTML
              </h2>
              <div className="flex flex-col items-center mt-2">
                <input
                  type="text"
                  value={iframeLink}
                  onChange={(e) => {}}
                  onClick={handleCopyEmbed}
                  placeholder="subject"
                  className="mt-2 text-sm w-full pb-1 text-gray-700 border-b border-gray-200 focus:border-b-2 focus:border-[#4c2b87] outline-none"
                />
              </div>
              <div className="flex items-center my-6">
                <div className="flex items-end py-2">
                  <p className="text-sm mr-2">Width</p>
                  <input
                    type="text"
                    className="text-sm w-[45px] text-gray-700 border-b border-gray-200 focus:border-b-2 focus:border-[#4c2b87] outline-none text-right"
                    value={iframeDims.width}
                    onChange={(e) => {
                      setIframeDims({ ...iframeDims, width: e.target.value });
                      setIframeLink(
                        `<iframe src="${getFormViewerUrl()}/${formId}?p=view" width="${
                          e.target.value
                        }px" height="${
                          iframeDims.height
                        }px" name="boloformEdit"></iframe>`
                      );
                    }}
                  />
                  <p className="text-sm ml-2">px</p>
                </div>
                <div className="border-l h-[30px] mx-4"></div>
                <div className="flex items-end  py-2">
                  <p className="text-sm mr-2">Height</p>
                  <input
                    type="text"
                    className="text-sm w-[45px] text-gray-700 border-b border-gray-200 focus:border-b-2 focus:border-[#4c2b87] outline-none text-right"
                    value={iframeDims.height}
                    onChange={(e) => {
                      setIframeDims({ ...iframeDims, height: e.target.value });
                      setIframeLink(
                        `<iframe src="${getFormViewerUrl()}/${formId}?p=view" width="${
                          iframeDims.width
                        }px" height="${
                          e.target.value
                        }px" name="boloformEdit"></iframe>`
                      );
                    }}
                  />
                  <p className="text-sm ml-2">px</p>
                </div>
              </div>
              <div className="h-[56px] flex items-center justify-between">
                <div></div>
                <div className="flex">
                  <button
                    onClick={() => {
                      setShow((prev) => ({ ...prev, sendModal: false }));
                    }}
                    className="h-[36px] w-[84px] px-2 mr-2 text-gray-600 text-sm flex items-center justify-center cursor-pointer"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleCopyEmbed}
                    style={{
                      color: formState?.accentColor,
                    }}
                    className="h-[36px] w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          )}
          {active.tab === "bulkSend" && (
            <div className="px-6 flex flex-col">
              <h2 className="text-base font-medium text-gray-800">
                Bulk Submit
              </h2>
              <p className="text-sm text-gray-600 mb-6">
                Send this form to multiple people for signing
              </p>
              <div className="flex flex-col">
                <p className="text-md w-fit uppercase font-semibold rounded-md flex items-center justify-center">
                  Step 1
                </p>
                <p className="text-sm text-gray-600 mt-1 mb-4">
                  Download the csv template and fill the details
                </p>
                <div>
                  <button
                    onClick={() => {
                      const csvData = ["name", "email", "subject", "message"];

                      // Create a Blob containing the CSV data
                      const blob = new Blob([csvData], { type: "text/csv" });

                      // Create a temporary URL for the Blob
                      const url = window.URL.createObjectURL(blob);

                      // Create a link and trigger a click to download the file
                      const a = document.createElement("a");
                      a.style.display = "none";
                      a.href = url;
                      a.download = `bulk-template-${formId}.csv`;
                      document.body.appendChild(a);
                      a.click();

                      // Clean up
                      window.URL.revokeObjectURL(url);
                      document.body.removeChild(a);
                    }}
                    className="w-fit text-sm bg-secondary text-primary font-medium rounded-md px-4 py-1.5 flex items-center justify-center"
                  >
                    Download
                  </button>
                </div>
              </div>
              <div className="mt-4 flex flex-col">
                <p className="text-md w-fit uppercase font-semibold rounded-md flex items-center justify-center">
                  Step 2
                </p>
                <p className="text-sm text-gray-600 mt-1 mb-4">
                  Upload the filled csv file
                </p>
                <div>
                  <div className="flex items-center min-h-[40px]">
                    <label
                      htmlFor="csv-upload"
                      onClick={() => {}}
                      className="cursor-pointer min-w-[120px] text-sm bg-secondary text-primary font-medium rounded-md px-4 py-1.5 flex items-center justify-center"
                    >
                      Choose File
                    </label>
                    <p className="ml-2 text-sm text-gray-700">
                      {csvFile ? csvFile?.name : "No file chosen"}
                    </p>
                  </div>
                  <input
                    className="py-2 self-start px-4 w-full outline-none text-gray-700 rounded-md border disabled:opacity-50 text-sm hidden"
                    id="csv-upload"
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={(e) => {
                      const file = e.target.files[0];

                      // Read the file and parse it
                      setCsvFile(file);
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        const result = event.target.result;
                        // Parse the CSV data
                        Papa.parse(result, {
                          header: true,
                          dynamicTyping: true,
                          skipEmptyLines: true,
                          complete: (parsedData) => {
                            setCsvData(parsedData.data);
                          },
                          error: (error) => {
                            console.error("CSV parsing error:", error.message);
                          },
                        });
                      };
                      reader.readAsText(file);
                    }}
                  />
                </div>
              </div>

              <div className="h-[56px] mt-6 flex items-center justify-end">
                {/* <div className="flex"> */}
                <button
                  onClick={() => {
                    setCsvFile(null);
                    setCsvData([]);
                    setShow((prev) => ({ ...prev, sendModal: false }));
                  }}
                  className="h-[36px] w-[84px] px-2 mr-2 text-gray-600 text-sm flex items-center justify-center cursor-pointer"
                >
                  Cancel
                </button>
                <button
                  onClick={() => initiateBulkSubmit(formId)}
                  style={{
                    color: formState?.accentColor,
                  }}
                  className="h-[36px] w-fit px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                >
                  Initiate Bulk Submit
                </button>
                {/* </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default SendModal;
