import { useEffect, useRef, useState } from "react";
import axios from "../../utils/axios";
// import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
import {
  MdArrowDropDownCircle,
  MdCheckCircleOutline,
  MdClose,
  MdMoreVert,
} from "react-icons/md";
import { Template } from "../../pdfme/generator/src";
import { Form } from "../../pdfme/ui/src";
import { DEFAULT_DATA, STANDARD_DATA } from "../../pdfme/ui/src/constants";
import dayjs from "dayjs";
import { BoloButton } from "../Common/Button/BoloButton";
import { getDocumentValueInfo } from "../../pdfme/ui/src/components/Designer";
import EndUserPaymentButton from "../SignatureTemplateResponseMapper/EndUserPaymentButton";
import PaymentInfoModal from "../SignatureTemplateResponseMapper/PaymentInfoModal";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { FaExternalLinkAlt } from "react-icons/fa";
import { BASE_URL } from "../../constants/constants";
import { IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../Common/PopOver/BoloPopOver";
import { CgAttachment } from "react-icons/cg";
import CustomTooltip from "../EditForm/CustomTooltip";
import { MapperType } from "../../pdfme/common/src/type";
import { isEditorSchema } from "../../pdfme/ui/src/designerHelper";

interface MapperProps {
  file?: File | null;
  numberOfPages?: number;
  signerDetails?: any;
  schemas?: any;
  loading?: boolean;
  setShownModal?: any;
  isPreview?: boolean;
  navHeight?: Number;
  previewStyle?: string;
  isSigning?: boolean;
  documentIdProp?: string;
  signerIdProp?: string;
  setRefetchData?: React.Dispatch<React.SetStateAction<boolean>>;
  fetchedData: {
    accountInfo: any;
    document: any;
    // !NOTE: this is not supported in old docmentId signerId system
    // ! and will only be supported in getDocumentByKey
    paymentStatus: {
      isPayment: boolean;
      message: string;
      isPaid?: boolean;
    } | null;
  };
}

const SignatureResponseMapper = ({
  file,
  numberOfPages,
  signerDetails,
  schemas,
  loading,
  setShownModal,
  isPreview,
  navHeight,
  previewStyle,
  isSigning,
  documentIdProp,
  signerIdProp,
  fetchedData,
  setRefetchData,
}: MapperProps) => {
  // console.log("SignatureResponseMapper --------", fetchedData);
  const formRef = useRef<HTMLDivElement | null>(null);
  const form = useRef<Form | null>(null);
  const [searchParams] = useSearchParams();
  let documentId = searchParams.get("documentId") || documentIdProp;
  let signerId = searchParams.get("signerId") || signerIdProp;
  let key = searchParams.get("key");
  let isSigningInPerson = searchParams.get("SigningInPerson");

  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );

  const [sending, setSending] = useState(false);
  const [updatedSchema, setUpdatedSchema] = useState();
  const [unfilledFieldsKeys, setUnfilledFieldsKeys] = useState<string[]>([]);
  const [documentValue, setDocumentValue] = useState(
    getDocumentValueInfo(fetchedData?.document)
  );
  const [status, setStatus] = useState<{
    document: "PENDING" | "SENT" | "SIGNED";
  }>({
    document: (() => {
      if (signerDetails?.status === "SIGNED") {
        return "SIGNED";
      }
      return "PENDING";
    })(),
  });
  const [prevField, setPrevField] = useState<string>();
  const [allRequiredFilled, setAllRequiredFilled] = useState<boolean>(false);
  const [otherOptions, setOtherOptions] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(false);
  const [isDeclineToSign, setIsDeclineToSign] = useState(false);
  // const [accountInfo, setAccountInfo] = useState<any>(null);

  const otherOptionsRef = useRef<any>(null);

  const [mapperState, setMapperState] = useState<MapperType>({
    showSideBar: "MINI_PDF",
  });
  let navigate = useNavigate();

  useEffect(() => {
    const template: Template = {
      // schemas: templateSchema,
      schemas: updatedSchema || [{}],
      // sampledata: [{ Name: "Input example", photo: "https://picsum.photos/200/300"}],
      //   sampledata: sampledata || [{}],
      // @ts-ignore
      basePdf: file,
    };

    if (formRef.current && updatedSchema) {
      const inputs = form?.current?.getInputs();
      const inputsoptional = [
        {
          name: "Pet Name",
          photo: "https://picsum.photos/200/300",
          age: "4 years",
          sex: "Male",
          weight: "33 pounds",
          breed: "Mutt",
          owner: "https://pdfme.com/",
        },
      ];
      form.current = new Form({
        domContainer: formRef.current,
        template,
        inputs: inputs || inputsoptional,
        setShownModal,
        isOnlyPdfTemplate: false,
        isPreview: isPreview,
        previewStyle: previewStyle,
        isSigning: isSigning,
        fetchedData,
        unfilledFieldsKeys,
        mapperState,
        setMapperState,
      });
    }
  }, [unfilledFieldsKeys, mapperState]);
  // @ts-ignore
  useEffect(() => {
    const transformedSchemas = schemas.map((schemaObj: any) => {
      // Create a new object to store the transformed data
      const transformedSchemaObj: any = {};

      // Loop through the properties of the current schemaObj
      for (const key in schemaObj) {
        if (Object.hasOwnProperty.call(schemaObj, key)) {
          const schemaData = schemaObj[key];
          // Split the id field by '___' and get the second part
          const idParts = schemaData.id.split("_");
          const extractedSignerId = idParts[1];
          // variable logic -----------------------------------------
          // allow anyone to see variable
          const subType = schemaData?.subType;
          // Variable are shown regardless of the role
          if (subType === "variable") {
            transformedSchemaObj[key] = schemaData;
          }
          // Changing the schema of preview to show only test in place of variable data if variable Data does not exists
          if (isPreview && subType === "variable") {
            transformedSchemaObj[key] = {
              ...schemaData,
              data: schemaData.data || "test variable",
            };
          }
          // ------------------------------------------------------------
          // if it is static schema show to all
          if (schemaData?.isStatic) {
            transformedSchemaObj[key] = schemaData;
          }
          // ------------------------------------------------------------
          // Check if the extracted signerId matches the provided signerId
          if (extractedSignerId === signerId) {
            // If it matches, keep this property in the transformed object
            transformedSchemaObj[key] = schemaData;
          }

          // this is for preview
          // note could keep this above the prev if
          // in order to prevent faulty assignments
          if (isPreview && extractedSignerId === signerDetails?._id) {
            transformedSchemaObj[key] = schemaData;
          }
        }
      }

      // Return the transformed object for this schemaObj
      return transformedSchemaObj;
    });

    const combinedInputObjects = (arr: any) => {
      return arr.reduce((result: any, obj: any) => {
        for (const key in obj) {
          if (
            Object.hasOwnProperty.call(obj, key) &&
            !result.hasOwnProperty(key)
          ) {
            // If the key doesn't exist in the result object, add the key-value pair
            // if (
            //   obj[key].data === STANDARD_DATA.signature.unsigned_DBKEYV1.key
            // ) {
            //   result[key] = obj[key].data;
            // } else {
            //   result[key] = "https://i.imgur.com/AfyPZQg.png";
            // }
            if (obj[key].subType === "name") {
              obj[key].data = signerDetails?.name;
            }
            if (obj[key].subType === "email") {
              obj[key].data = signerDetails?.email;
            }
            if (obj[key].subType === "number") {
              // writng this here as previously the data was "Number"
              // there for we used to replace it with zero
              // obj[key].data = "0";
              obj[key].data = obj[key].data;
            }
            if (obj[key].subType === "date" && obj[key]?.isStatic) {
              obj[key].data = dayjs(new Date()).format(
                obj[key]?.ifDateFormat?.toUpperCase() || "MM/DD/YYYY"
              );
            }
            // If the key doesn't exist in the result object, add the key-value pair
            if (obj[key].type === "image" && obj[key].subType === "signature") {
              if (
                obj[key].data === STANDARD_DATA.signature.unsigned_DBKEYV1.key
              ) {
                result[key] = obj[key].data;
              } else if (
                isEditorSchema({
                  schema: obj[key],
                  receiversList: fetchedData?.document?.respondants,
                  instanceFrom: "PDF",
                })
              ) {
                result[key] = obj[key].data;
              } else {
                // This is very old image and currently we show images, we show icons if no data found
                result[key] = "https://i.imgur.com/AfyPZQg.png";
              }
            } else if (
              obj[key].type === "image" &&
              obj[key].subType === "initials"
            ) {
              if (
                isEditorSchema({
                  schema: obj[key],
                  receiversList: fetchedData?.document?.respondants,
                  instanceFrom: "PDF",
                })
              ) {
                result[key] = obj[key].data;
              } else {
                result[key] =
                  "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature-template/4vnRsfZ.png";
              }
            } else if (obj[key].subType === "date") {
              result[key] = new Date().toLocaleDateString();
            } else {
              result[key] = obj[key].data;
            }

            // above if is a jank if fix later for any more modifications
            // overwriting proprties here
            if (obj[key].subType === "date") {
              result[key] = obj[key].data;
            }
          }
        }
        return result;
      }, {});
    };

    // Call the combineObjects function with the transformedSchemas array
    const uniqueInputs = combinedInputObjects(transformedSchemas);

    const template: Template = {
      // schemas: templateSchema,
      schemas: transformedSchemas || [{}],
      // sampledata: [{ Name: "Input example", photo: "https://picsum.photos/200/300"}],
      //   sampledata: sampledata || [{}],
      // @ts-ignore
      basePdf: file,
    };

    setUpdatedSchema(transformedSchemas);

    const inputs = [
      {
        name: "Pet Name",
        photo: "https://picsum.photos/200/300",
        age: "4 years",
        sex: "Male",
        weight: "33 pounds",
        breed: "Mutt",
        owner: "https://pdfme.com/",
      },
    ];
    // Run this effect when the containerRef is available
    if (formRef.current && transformedSchemas) {
      form.current = new Form({
        domContainer: formRef.current,
        template,
        inputs: [uniqueInputs] || inputs,
        setShownModal,
        isOnlyPdfTemplate: false,
        isPreview: isPreview,
        previewStyle: previewStyle,
        isSigning: isSigning,
        fetchedData,
        unfilledFieldsKeys,
        mapperState,
        setMapperState,
      });
      //   form.current.getInputs();
      // designer.current.onChangeTemplate(setTemplate);
    }
    return () => {
      // Destroy the designer when the component unmounts
      // @ts-ignore
      // form.current.destroy();
    };
  }, [formRef, file, signerDetails, fetchedData]);

  const handleOutsideClick = (e: any) => {
    if (otherOptionsRef.current !== null) {
      if (otherOptionsRef.current.contains(e?.target)) return;
    }
    setOtherOptions(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  //! function to send to signers

  //! end of function to send to signers

  const getTheSchemaRequiredFields = (arr: any) => {
    return arr.reduce((result: any, obj: any) => {
      for (const key in obj) {
        if (
          Object.hasOwnProperty.call(obj, key) &&
          !result.hasOwnProperty(key)
        ) {
          // If the key doesn't exist in the result object, add the key-value pair
          if (obj[key].required && obj[key].subType !== "checkbox") {
            result[key] = obj[key];
          }
        }
      }
      return result;
    }, {});
  };
  const getSchemas = (arr: any) => {
    let flattenedArray = arr?.flatMap((obj: any) => Object.values(obj));
    let filteredSchemas = flattenedArray?.filter(
      (schema: any) => !schema?.isStatic && schema?.subType !== "variable"
    );
    return filteredSchemas;
  };
  const checkNotFilledField = (
    element: any,
    key: string,
    radioGroupChecker: any,
    allRadioGroups: Set<unknown>,
    radioGroupFields: any[]
  ) => {
    // @ts-ignore
    const inputs = form.current.getInputs();
    const inputObject = inputs[0];
    if (!element?.required) {
      return false;
    }
    switch (element.subType) {
      case "initials":
        return (
          inputObject[key] === "" ||
          inputObject[key] === DEFAULT_DATA.initials.defaultAWSLink
        );
      case "stamp":
        return (
          inputObject[key] === "" ||
          inputObject[key] === DEFAULT_DATA.stamp.defaultLink
        );
      case "dropdown":
        return inputObject[key] === "";
      case "number":
        return inputObject[key] === "" || inputObject[key] === "Number";
      case "date":
        return inputObject[key] === "" || inputObject[key] === "Date";
      case "signature":
        return (
          inputObject[key] === "https://i.imgur.com/AfyPZQg.png" ||
          inputObject[key] === "" ||
          inputObject[key] === STANDARD_DATA.signature.unsigned_DBKEYV1.key
        );
      case "text":
        return inputObject[key] === "" || inputObject[key] === "Text";
      case "radiogroup":
        if (inputObject[key] == STANDARD_DATA.radiogroup.checked_DBKEYV1.key) {
          radioGroupChecker[element.radiogroupId] = true;
        }
        allRadioGroups.add(element.radiogroupId);
        radioGroupFields.push(element);
        return false;
      case "file_upload":
        return inputObject[key] === "";
      default:
        return false;
    }
  };
  const nextField = () => {
    if (isPreview) return;

    const inputFields = getSchemas(updatedSchema);
    if (allRequiredFilled && prevField) {
      inputFields.forEach((element: any, index: number) => {
        if (element?.id === prevField) {
          if (index < inputFields?.length - 1) {
            setPrevField(inputFields[index + 1]?.id);
            setUnfilledFieldsKeys([inputFields[index + 1]?.id]);
          }
        }
      });
    }

    let requiredFieldsNotFilledKeys: any[] = [];
    let notFilledSignaturesFields = false;

    const radioGroupChecker: any = {};
    const allRadioGroups = new Set();
    const radioGroupFields: any[] = [];

    for (const element of inputFields) {
      const key = element?.key;
      notFilledSignaturesFields = checkNotFilledField(
        element,
        key,
        radioGroupChecker,
        allRadioGroups,
        radioGroupFields
      );

      if (notFilledSignaturesFields) {
        setUnfilledFieldsKeys([element?.id]);
        setPrevField(element?.id);
        break;
      }
    }

    if (notFilledSignaturesFields) return;

    allRadioGroups.forEach((radGrpId: any) => {
      if (!radioGroupChecker[radGrpId]) {
        requiredFieldsNotFilledKeys.push(radGrpId);
        const fieldId = radioGroupFields.find(
          (radio) => radio?.radiogroupId === radGrpId
        )?.id;
        setPrevField(fieldId);
      }
    });

    if (requiredFieldsNotFilledKeys.length > 0) {
      setUnfilledFieldsKeys(requiredFieldsNotFilledKeys);
      return;
    }
    setAllRequiredFilled(true);
    if (prevField) {
      inputFields.forEach((element: any, index: any) => {
        if (element?.id === prevField) {
          if (index < inputFields?.length - 1) {
            setPrevField(inputFields[index + 1]?.id);
            setUnfilledFieldsKeys([inputFields[index + 1]?.id]);
          }
        }
      });
    } else {
      const lastFieldId = inputFields[inputFields.length - 1]?.id;
      setPrevField(lastFieldId);
      setUnfilledFieldsKeys([lastFieldId]);
    }
  };
  const prevFields = () => {
    if (isPreview) return;

    const inputFields = getSchemas(updatedSchema);

    if (prevField) {
      inputFields.forEach((element: any, index: any) => {
        if (element?.id === prevField) {
          if (index > 0) {
            setPrevField(inputFields[index - 1]?.id);
            setUnfilledFieldsKeys([inputFields[index - 1]?.id]);
          } else {
            setPrevField(inputFields[0]?.id);
            setUnfilledFieldsKeys([inputFields[0]?.id]);
          }
        }
      });

      return;
    }

    let requiredFieldsNotFilledKeys: any[] = [];
    let notFilledSignaturesFields = false;

    const radioGroupChecker: any = {};
    const allRadioGroups = new Set();
    const radioGroupFields: any[] = [];

    for (let index = 0; index < inputFields.length; index++) {
      const element = inputFields[index];
      const key = element?.key;
      notFilledSignaturesFields = checkNotFilledField(
        element,
        key,
        radioGroupChecker,
        allRadioGroups,
        radioGroupFields
      );

      if (notFilledSignaturesFields) {
        const unfilledElementId =
          index > 0 ? inputFields[index - 1]?.id : element?.id;

        setUnfilledFieldsKeys([unfilledElementId]);
        setPrevField(unfilledElementId);

        break; // Exit the loop after finding the first unfilled field
      }
    }

    if (notFilledSignaturesFields) return;

    allRadioGroups.forEach((radGrpId: any) => {
      if (!radioGroupChecker[radGrpId]) {
        requiredFieldsNotFilledKeys.push(radGrpId);
        const fieldId = radioGroupFields.find(
          (radio) => radio?.radiogroupId === radGrpId
        )?.id;
        setPrevField(fieldId);
      }
    });

    if (requiredFieldsNotFilledKeys.length > 0) {
      setUnfilledFieldsKeys(requiredFieldsNotFilledKeys);
      return;
    }

    const lastFieldId = inputFields[inputFields.length - 1]?.id;
    setPrevField(lastFieldId);
    setUnfilledFieldsKeys([lastFieldId]);
  };

  const saveInputs = async () => {
    // downloadJsonFile(designer.current.getTemplate(), 'template');
    // @ts-ignore
    let inputs = form.current.getInputs();
    const requiredFields = getTheSchemaRequiredFields(updatedSchema);
    let requiredFieldsNotFilledKeys = [];

    let inputObject = inputs[0];

    let emptySignaturesFields;
    let notFilledSignaturesFields;

    let radioGroupChecker: any = {};
    let allRadioGroups = new Set();

    if (Object.keys(requiredFields).length > 0) {
      for (const key in inputObject) {
        notFilledSignaturesFields = false;
        let sameKeyInRequiredFields = requiredFields[key];
        //for non-required fields sameKeyInRequiredFields will be undefined
        if (!sameKeyInRequiredFields) continue;

        // Radio group require check --------------------------------------------------
        // add radio group id attr to radioGrpchecker
        // if attr with given radGrpId does not exist
        // it has'nt been answered
        if (sameKeyInRequiredFields.subType == "radiogroup") {
          if (
            inputObject[key] == STANDARD_DATA.radiogroup.checked_DBKEYV1.key
          ) {
            radioGroupChecker[sameKeyInRequiredFields.radiogroupId] = true;
          }
          allRadioGroups.add(sameKeyInRequiredFields.radiogroupId);
        }
        // --------------------------------------------------

        // Initials required check --------------------------
        if (sameKeyInRequiredFields.subType === "initials") {
          if (
            inputObject[key] === "" ||
            inputObject[key] === DEFAULT_DATA.initials.defaultAWSLink
          ) {
            emptySignaturesFields = true;
            notFilledSignaturesFields = true;
          }
        }
        // Stamps required check --------------------------
        if (sameKeyInRequiredFields.subType === "stamp") {
          if (
            inputObject[key] === "" ||
            inputObject[key] === DEFAULT_DATA.stamp.defaultLink
          ) {
            emptySignaturesFields = true;
            notFilledSignaturesFields = true;
          }
        }
        // --------------------------------------------------
        // Dropdown required check --------------------------
        if (sameKeyInRequiredFields.subType === "dropdown") {
          if (inputObject[key] === "") {
            emptySignaturesFields = true;
            notFilledSignaturesFields = true;
          }
        }
        // --------------------------------------------------
        if (sameKeyInRequiredFields.subType === "number") {
          if (inputObject[key] === "" || inputObject[key] === "Number") {
            emptySignaturesFields = true;
            notFilledSignaturesFields = true;
          }
        }
        // --------------------------------------------------
        if (sameKeyInRequiredFields.subType === "date") {
          if (inputObject[key] === "" || inputObject[key] === "Date") {
            emptySignaturesFields = true;
            notFilledSignaturesFields = true;
          }
        }
        // --------------------------------------------------
        if (sameKeyInRequiredFields.subType === "text") {
          if (inputObject[key] === "" || inputObject[key] === "Text") {
            emptySignaturesFields = true;
            notFilledSignaturesFields = true;
          }
        }
        // --------------------------------------------------
        if (sameKeyInRequiredFields.subType === "file_upload") {
          if (inputObject[key] === "") {
            emptySignaturesFields = true;
            notFilledSignaturesFields = true;
          }
        }
        // --------------------------------------------------

        if (
          sameKeyInRequiredFields.subType !== "initials" &&
          sameKeyInRequiredFields.subType !== "date"
        ) {
          if (
            sameKeyInRequiredFields.subType === "signature" &&
            (inputObject[key] === "https://i.imgur.com/AfyPZQg.png" ||
              inputObject[key] === "" ||
              inputObject[key] === STANDARD_DATA.signature.unsigned_DBKEYV1.key)
          ) {
            emptySignaturesFields = true;
            notFilledSignaturesFields = true;
            // break;
          }
          // else if (
          //   sameKeyInRequiredFields.subType === "text" &&
          //   (inputObject[key] === "" ||
          //     inputObject[key] === sameKeyInRequiredFields.data)
          // ) {
          // bad logic
          // if fields automatically imported
          // it sets data as the text say someones name
          // until the user does not change the name which is not logically it will pop
          // fill all fields error
          //   emptySignaturesFields = true;
          //   notFilledSignaturesFields = true;
          // break;
          // }
        }

        // above code is jank code fix later make proper if statements
        if (notFilledSignaturesFields) {
          requiredFieldsNotFilledKeys.push(requiredFields[key]?.id);
        }
      }
    }

    // checking if each radio group has been answered
    allRadioGroups.forEach((radGrpId: any) => {
      if (!radioGroupChecker[radGrpId]) {
        emptySignaturesFields = true;
        requiredFieldsNotFilledKeys.push(radGrpId);
      }
    });
    // ----------------------------------------

    if (emptySignaturesFields) {
      setUnfilledFieldsKeys(requiredFieldsNotFilledKeys);
      toast.error("Please fill all the required fields");
      return;
    }

    // // ! remove this later =============================================================
    // console.log("done");
    // return;

    try {
      setSending(true);
      // localStorage.removeItem("temporarySignature");
      const response = getIpAddressLocal();
      const { ip } = await response;
      const { data } = await axios.post(`/saveInputs`, {
        inputs,
        documentId,
        ip,
        signerId,
      });
      if (data.error) {
        toast.error(data.message);
        setSending(false);
        return;
      }
      if (!data.error) {
        toast.success(data.message);
        if (isSigningInPerson == "1") {
          localStorage.removeItem("temporarySignature");
          localStorage.removeItem("temporaryInitial");
          localStorage.removeItem("temporaryStamp");
        }
        setTimeout(() => {
          let navigateSuccess = () => {
            if (key) {
              navigate(`/signature-success?key=${key}`);
            } else {
              navigate(
                `/signature-success?documentId=${documentId}&signerId=${signerId}`
              );
            }
          };
          if (!fetchedData?.paymentStatus?.isPayment) {
            navigateSuccess();
          } else {
            // ! no need to navigate here as there wont be a case
            // ! case 1 : 2 signer unpaid doc -> 2ns signer on clicking pay will have checked the status in
            // ! createStripePayment  returns isPaid approriate ui is shown
            // ! procced button in modal
            // ! case 2 : 2 signer 1 signed paid -> paymentStatus.isPayment ->  false & isPaid -> true
            // ! case 3 :  2 signer doc signed ->  both will be shown pay btn -> case 1 is one pays
            // if (data?.document?.status === "PAID") {
            // navigateSuccess();
            // }
          }
        }, 2000);
        setSending(false);
        setStatus((prev) => ({ ...prev, document: "SENT" }));
        setRefetchData && setRefetchData((prev) => !prev);

        return;
      }
    } catch (error: any) {
      setSending(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  const DeclineToSign = () => {
    const divRef = useRef<any>(null);
    const handleOutsideClick = (e: any) => {
      if (divRef.current !== null) {
        if (divRef.current.contains(e?.target)) return;
      }
      setIsDeclineToSign(false);
    };

    const handleDeclineSigning = async () => {
      const ipData = await fetch("https://api.ipify.org/?format=json");
      const { ip } = await ipData.json();
      const response = await axios.post(`${BASE_URL}/declineSigning`, {
        documentId: documentId,
        isOnlyPDF: true,
        signerId: signerDetails?.signerId,
        email: signerDetails?.email,
        ip: ip,
        signerName: signerDetails?.name,
      });
      window.location.reload();
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div className="flex z-30 absolute h-screen w-full top-0  left-0 items-center justify-center">
        <div
          className="flex flex-col gap-6 p-4 bg-white shadow rounded text-sm max-w-[95vw]"
          ref={divRef}
        >
          <div className="font-semibold text-base">
            Caution{" "}
            <MdClose
              className="absolute right-4 top-4 cursor-pointer"
              size={20}
              onClick={() => {
                setIsDeclineToSign(false);
              }}
            />
          </div>
          <div className="border-b -mx-4"></div>
          <div className="flex flex-col gap-4 text-xs">
            <div>
              If you select <span className="font-semibold">Continue</span>,
              this document will be void.
            </div>
            <div>
              To request changes to this document, please select{" "}
              <span className="font-semibold">Cancel</span> and contact the
              sender directly with your request.
            </div>
          </div>
          <div className="border-b -mx-4"></div>
          <div className="flex gap-4 items-center justify-start text-sm">
            <button
              className="px-3 py-1 bg-primary hover:bg-primary/80 font-semibold rounded shadow  text-white transition-all duration-300 ease-in-out"
              style={{
                background: fetchedData?.accountInfo?.theme,
                color: fetchedData?.accountInfo?.themeText,
              }}
              onClick={() => {
                handleDeclineSigning();
              }}
            >
              CONTINUE
            </button>
            <button
              className="px-3 py-1 bg-white hover:bg-gray-100 font-semibold rounded shadow  text-primary transition-all duration-300 ease-in-out"
              style={{
                color: fetchedData?.accountInfo?.theme,
              }}
              onClick={() => {
                setIsDeclineToSign(false);
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    );
  };

  const SessionInformation = () => {
    const divRef = useRef<any>(null);
    const handleOutsideClick = (e: any) => {
      if (divRef.current !== null) {
        if (divRef.current.contains(e?.target)) return;
      }
      setSessionInfo(false);
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div className="flex z-30 absolute h-screen w-full top-0  left-0 items-center justify-center">
        {" "}
        <div
          className="flex flex-col gap-6 p-4 bg-white shadow rounded text-sm relative max-w-[95vw]"
          ref={divRef}
        >
          <div className="font-semibold text-base">
            Session Information{" "}
            <MdClose
              className="absolute right-4 top-4 cursor-pointer"
              size={20}
              onClick={() => {
                setSessionInfo(false);
              }}
            />
          </div>
          <div className="border-b -mx-4"></div>
          <div className="flex justify-between gap-8 text-xs">
            <div>Envelope ID</div>
            <div className="font-semibold">{documentId}</div>
          </div>
          <div className="flex justify-between gap-8 text-xs">
            <div>Signer ID</div>
            <div className="font-semibold">{signerDetails?.signerId}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="h-full"
      style={{ height: isPreview ? `calc(100% - ${navHeight}px)` : "100%" }}
    >
      <div className="overlay-hidden" ref={formRef} id="form-container"></div>{" "}
      {/* This will be used as the container */}
      {/* <button className="p-2 rounded-lg bg-blue-300" onClick={saveTemplate}>
        Save template
      </button> */}
      <div className="h-[70px] border-b fixed left-0 right-0 flex justify-center items-center top-0 bg-white z-[20] px-2 lg:px-6">
        <div className="fixed bottom-0 w-full px-3 border-t py-2 bg-white flex gap-2 sm:hidden">
          <button
            className="py-2 px-3 bg-gray-100 text-xs text-gray-700 rounded font-medium flex items-center gap-2 border"
            onClick={prevFields}
            disabled={loading || sending || isPreview}
          >
            {/* <ChevronLeftIcon width={20} height={20} /> */}
            Prev
          </button>
          <button
            className="py-2 px-3 bg-gray-100 text-xs text-gray-700 rounded font-medium flex items-center gap-2 border"
            onClick={nextField}
            disabled={loading || sending || isPreview}
          >
            {/* <ChevronRightIcon width={20} height={20} /> */}
            Next
          </button>
          <div className="flex items-center ml-auto gap-2">
            {fetchedData?.paymentStatus?.isPayment &&
              status.document === "PENDING" && (
                <div className="flex sm:hidden">
                  <PaymentInfoModal
                    document={fetchedData.document}
                    docType={"PDF"}
                    brandingInfo={fetchedData?.accountInfo}
                  />
                </div>
              )}
            {(status.document === "SENT" || status.document === "SIGNED") &&
              fetchedData?.paymentStatus?.isPayment && (
                <div className="ml-auto flex sm:hidden gap-2">
                  <BoloButton
                    size={"sm"}
                    variant="success-border"
                    className="px-4 disabled:!opacity-100 flex gap-2 items-center"
                    disabled
                  >
                    <MdCheckCircleOutline size={18} />
                    Signed
                  </BoloButton>
                  <EndUserPaymentButton
                    document={fetchedData.document}
                    docType={"PDF"}
                    signerDetails={signerDetails}
                    accountInfo={fetchedData?.accountInfo}
                  />
                </div>
              )}
            {(status.document === "PENDING" ||
              !fetchedData?.paymentStatus?.isPayment) && (
              <button
                className="py-2 px-6 bg-primary text-xs text-white rounded font-medium flex items-center hover:opacity-90"
                onClick={saveInputs}
                style={{
                  color: fetchedData?.accountInfo?.themeText,
                  background: fetchedData?.accountInfo?.theme,
                }}
                disabled={loading || sending || isPreview}
              >
                {/* {loading || sending ? (
              <MdOutlineScheduleSend className="text-xl" />
            ) : (
              <MdOutlineSendAndArchive className="text-xl" />
            )} */}
                <IoCheckmarkDoneCircleSharp className="text-xl mr-3" />
                {loading || sending ? "Sending.." : "Finish"}
              </button>
            )}
          </div>
        </div>
        <div className="w-full py- flex gap-2 items-center justify-between">
          <div className="flex items-center max-w-[80%] md:w-full">
            <div className="mr-4 hidden md:block">
              <img
                src={
                  fetchedData?.accountInfo?.companyLogo ||
                  whiteLabelInfo?.settings?.organization?.logo ||
                  IMAGE_LINKS.BOLOLOGO
                }
                alt="logo"
                className="h-12 aspect-square rounded-full"
              />
            </div>
            <div className="w-full md:w-fit">
              <h1 className="w-full text-sm font-semibold truncate">
                {fetchedData?.document?.documentName || "Untitled Document"}
              </h1>
              <p className="mt-1 w-full text-xs text-gray-600 truncate flex">
                by {fetchedData?.document?.ownerId?.name}
                <span className="hidden md:flex">
                  ({fetchedData?.document?.authorEmail})
                </span>
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2 sm:gap-3">
            {sessionInfo && <SessionInformation />}
            {isDeclineToSign && <DeclineToSign />}
            {fetchedData?.document?.settings?.attachments?.length > 0 && (
              <>
                <div
                  onClick={() => {
                    if (mapperState?.showSideBar === "ATTACHMENTS") {
                      setMapperState({
                        ...mapperState,
                        showSideBar: "MINI_PDF",
                      });
                    } else {
                      setMapperState({
                        ...mapperState,
                        showSideBar: "ATTACHMENTS",
                      });
                    }
                  }}
                  data-tooltip-id="attachment-files"
                >
                  <CgAttachment
                    className={`text-gray-500  text-lg sm:text-xl font-bold cursor-pointer `}
                  />
                </div>
                <CustomTooltip
                  helpers={{
                    id: "attachment-files",
                    content: "Attachments",
                    place: "left",
                  }}
                />
              </>
            )}
            <BoloPopover>
              <BoloPopoverTrigger>
                <>
                  <button
                    className="hidden md:flex whitespace-nowrap py-2 px-6 bg-gray-100 text-xs text-gray-700 rounded font-medium items-center hover:opacity-90"
                    onClick={() => setOtherOptions(!otherOptions)}
                    // style={{
                    //   color: fetchedData?.accountInfo?.themeText,
                    //   background: fetchedData?.accountInfo?.theme,
                    // }}
                    disabled={loading || sending || isPreview}
                  >
                    <MdArrowDropDownCircle className="text-xl mr-3" />
                    More
                  </button>

                  <button
                    className="md:hidden flex pr-6 text-gray-700 items-center hover:opacity-90"
                    onClick={() => setOtherOptions(!otherOptions)}
                    // style={{
                    //   color: fetchedData?.accountInfo?.themeText,
                    //   background: fetchedData?.accountInfo?.theme,
                    // }}
                    disabled={loading || sending || isPreview}
                  >
                    <MdMoreVert className="text-xl" />
                  </button>
                </>
              </BoloPopoverTrigger>
              <BoloPopoverContent align="end">
                <div
                  // ref={otherOptionsRef}
                  className="flex flex-col pt-2 w-[200px]  h-[190px]  text-xs text-gray-600 bg-white rounded shadow"
                >
                  <div
                    className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer"
                    onClick={() => {
                      // console.log("Finish later");
                      window.close();
                    }}
                  >
                    Finish Later
                  </div>
                  <div
                    className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer"
                    onClick={() => {
                      setIsDeclineToSign(true);
                      setOtherOptions(false);
                    }}
                  >
                    Decline to Sign
                  </div>
                  <div className="border-b my-2 w-full"></div>
                  <a
                    href="https://help.boloforms.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer">
                      Help and support
                      <FaExternalLinkAlt size={11} />
                    </div>
                  </a>
                  <a
                    href="https://www.boloforms.com/signature"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer">
                      About Boloforms
                      <FaExternalLinkAlt size={11} />
                    </div>
                  </a>
                  <div
                    className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer"
                    onClick={() => {
                      setSessionInfo(!sessionInfo);
                      setOtherOptions(false);
                    }}
                  >
                    Session Information
                  </div>
                </div>
              </BoloPopoverContent>
            </BoloPopover>
            {fetchedData?.paymentStatus?.isPayment &&
              status.document === "PENDING" && (
                <div className="hidden md:flex">
                  <PaymentInfoModal
                    document={fetchedData.document}
                    docType={"PDF"}
                    brandingInfo={fetchedData?.accountInfo}
                  />
                </div>
              )}
            {(status.document === "PENDING" ||
              !fetchedData?.paymentStatus?.isPayment) && (
              <button
                className="sm:flex hidden py-2 px-6 bg-primary text-xs text-white rounded font-medium  items-center hover:opacity-90"
                style={{
                  color: fetchedData?.accountInfo?.themeText,
                  background: fetchedData?.accountInfo?.theme,
                }}
                onClick={saveInputs}
                disabled={loading || sending || isPreview}
              >
                {/* {loading ? (
              <MdOutlineScheduleSend className="text-xl" />
            ) : (
              <MdOutlineSendAndArchive className="text-xl" />
            )} */}
                <IoCheckmarkDoneCircleSharp className="text-xl mr-3" />

                {loading || sending ? "Sending.." : "Finish"}
              </button>
            )}
            {(status.document === "SENT" || status.document === "SIGNED") &&
              fetchedData?.paymentStatus?.isPayment && (
                <div className="ml-auto sm:flex hidden gap-2">
                  <BoloButton
                    size={"sm"}
                    variant="success-border"
                    className="px-4 disabled:!opacity-100 flex gap-2 items-center"
                    disabled
                  >
                    <MdCheckCircleOutline size={18} />
                    Signed
                  </BoloButton>
                  <EndUserPaymentButton
                    document={fetchedData.document}
                    docType={"PDF"}
                    signerDetails={signerDetails}
                    accountInfo={fetchedData?.accountInfo}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatureResponseMapper;
