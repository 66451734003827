import React, { useState } from "react";
import { FaRegCreditCard } from "react-icons/fa";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { STRIPE_CONNECT_URL } from "../../constants/constants";
import {
  DocumentValueType,
  getDocumentValueInfo,
} from "../../pdfme/ui/src/components/Designer";
import { delay } from "../../utils";
import axios from "../../utils/axios";
import { formatPrice } from "../../utils/quoteBuilder";
import { BoloButton } from "../Common/Button/BoloButton";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../Common/Dialog/BoloDialog";
import _ from "lodash";

type PaymentItem = {
  quantity: number;
  name: string;
  price: number;
  description?: string;
  currency: string;
  // tierId: string;
};

type PaymentDetails = {
  items: PaymentItem[];
  successUrl: string;
  cancelUrl: string;
  workSpaceId: string;
  paymentType: "payment" | "subscription";
  customerEmail: string;
  form_id?: string; // Optional as it is not used right now
  document_id: string;
  docType: "PDF" | "PDF_TEMPLATE";
  payerId: string; // it is email in case for PDF and roleTitle in case of PDF_TEMPLATE
};

// unused
// function parseSchemaForTable({ schemas }: { schemas: any }): PaymentItem[] {
//   const PRECISION = 2;
//   let items: PaymentItem[] = [];
//   for (let page of schemas) {
//     for (let [key, schema] of Object.entries<{ [key: string]: any }>(page)) {
//       if (schema?.subType == "pricing_table") {
//         let numProducts = schema?.rows?.length;
//         let currency = schema?.subtotalMenu?.currency;
//         let discountFormat = schema?.subtotalMenu?.discount?.format;
//         let feeFormat = schema?.subtotalMenu?.fee?.format;
//         let taxFormat = schema?.subtotalMenu?.tax?.format;

//         let discountAmount =
//           discountFormat === "absolute"
//             ? schema?.subtotalMenu?.discount?.amount
//             : schema?.subtotalMenu?.discount?.percentage;
//         let feeAmount =
//           feeFormat === "absolute"
//             ? schema?.subtotalMenu?.fee?.amount
//             : schema?.subtotalMenu?.fee?.percentage;
//         let taxAmount =
//           taxFormat === "absolute"
//             ? schema?.subtotalMenu?.tax?.amount
//             : schema?.subtotalMenu?.tax?.percentage;

//         for (let row of schema?.rows) {
//           let rowPrice = row?.[1]?.price;
//           let rowQty = row?.[2]?.qty;
//           let rowSubtotal = row?.[3]?.subtotal;
//           let rowSubTotalRatio2TableSubtotal =
//             rowSubtotal / schema?.subtotalMenu?.subtotal?.amount;
//           let item: PaymentItem = {
//             name: row?.[0]?.header || "Table Item",
//             description: row?.[0]?.header || "",
//             currency,
//             quantity: rowQty || 1,
//             price: rowPrice || 0,
//           };

//           let newRowPrice = rowPrice;
//           console.log(1, { newRowPrice });
//           if (discountFormat === "absolute") {
//             newRowPrice =
//               newRowPrice -
//               (discountAmount * rowSubTotalRatio2TableSubtotal) / rowQty;
//           }
//           if (discountFormat === "percent") {
//             newRowPrice = newRowPrice * ((100 - discountAmount) / 100);
//           }
//           console.log(2, { newRowPrice });

//           if (feeFormat === "absolute") {
//             newRowPrice =
//               newRowPrice +
//               (feeAmount * rowSubTotalRatio2TableSubtotal) / rowQty;
//           }
//           if (feeFormat === "percent") {
//             newRowPrice = newRowPrice * ((100 + feeAmount) / 100);
//           }
//           console.log(3, { newRowPrice });

//           if (taxFormat === "absolute") {
//             newRowPrice =
//               newRowPrice +
//               (taxAmount * rowSubTotalRatio2TableSubtotal) / rowQty;
//           }
//           if (taxFormat === "percent") {
//             newRowPrice = newRowPrice * ((100 + taxAmount) / 100);
//           }
//           console.log(4, { newRowPrice });

//           // this should always be in end
//           item.price = _.round(newRowPrice, PRECISION);
//           console.log(item.price);

//           console.log({ item });
//           items.push(item);
//         }
//       }
//     }
//   }

//   return items;
// }

function parseDescFromTable({ schemas }: { schemas: any }): string {
  let description = "";
  for (let page of schemas) {
    for (let [key, schema] of Object.entries<{ [key: string]: any }>(page)) {
      if (schema?.subType == "pricing_table") {
        for (let row of schema?.rows) {
          description += `${row?.[0]?.header} x ${row?.[2]?.qty}; `;
        }
      }
    }
  }
  // description = description;
  return description;
}

function documentValue2StripePayment({
  document,
  signerEmail,
  docType,
  payerId,
  schemas,
  documentName,
}: {
  document: any;
  signerEmail: string;
  docType: "PDF" | "PDF_TEMPLATE";
  payerId: string;
  schemas: any;
  documentName: string;
}): PaymentDetails {
  const documentValue = getDocumentValueInfo(document);

  let items: PaymentItem[] = [];
  console.log({ document });

  if (documentValue?.valueType === "DOCUMENT_TOTAL") {
    // let tableItems = parseSchemaForTable({ schemas });
    // items = tableItems;
    let desc = parseDescFromTable({ schemas });
    items.push({
      name: documentName || "Document Value",
      description: desc || "A custom value for document/contract.",
      price: documentValue?.amount?.valueTotal,
      quantity: 1,
      currency: documentValue?.amount?.currency,
    });
  }

  if (documentValue?.valueType === "CUSTOM") {
    items.push({
      name: documentName || "Document Value",
      description: "A custom value for document/contract.",
      price: documentValue?.amount?.customAmount,
      quantity: 1,
      currency: documentValue?.amount?.currency,
    });
  }

  let paymentDetails: PaymentDetails = {
    items,
    successUrl: window.location.toString(),
    cancelUrl: window.location.toString(),
    workSpaceId: document?.workSpaceId,
    paymentType: "payment",
    customerEmail: signerEmail,
    document_id: document?._id,
    docType,
    payerId,
  };
  return paymentDetails;
}

const EndUserPaymentButton = ({
  document,
  docType,
  signerDetails,
  pdfTemplateRespondent,
  accountInfo,
}: {
  document: any;
  docType: "PDF" | "PDF_TEMPLATE";
  signerDetails: any;
  pdfTemplateRespondent?: any;
  accountInfo?: any;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let managedDocument =
    docType === "PDF_TEMPLATE"
      ? { ...pdfTemplateRespondent, workSpaceId: document?.workSpaceId }
      : document;
  let payerId =
    docType === "PDF_TEMPLATE"
      ? signerDetails?.roleTitle
      : signerDetails?.email;
  let managedSchemas =
    docType === "PDF_TEMPLATE"
      ? signerDetails?.templateSchemaForCalc
      : document?.pdfTemplate?.schemasForCalc;
  let managedDocName = `${document?.documentName}`;
  const documentValue = getDocumentValueInfo(managedDocument);

  const handleCreateStripePayemnt = async () => {
    setLoading(true);
    try {
      const stripeFormattedValue = documentValue2StripePayment({
        document: managedDocument,
        signerEmail: signerDetails?.email,
        docType,
        payerId,
        schemas: managedSchemas,
        documentName: managedDocName,
      });
      console.log({ stripeFormattedValue });
      let { data } = await axios.post(
        `${STRIPE_CONNECT_URL}/createStripePayment`,
        {
          ...stripeFormattedValue,
        }
      );
      if (data?.sessionUrl) {
        window.open(data?.sessionUrl, "_self");
      } else {
        if (data?.paymentStatus?.isPaid) {
          toast.success("Already paid for");
          await delay(2000);
          window.location.reload();
        } else {
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log("error in handleCreateStripePayemnt");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
      }}
    >
      <DialogTrigger asChild>
        <BoloButton
          size={"sm"}
          className="flex gap-2 items-center"
          disabled={loading}
          onClick={() => setOpen(true)}
          style={{
            background: accountInfo?.theme,
            borderColor: accountInfo?.theme,
          }}
        >
          <p>Pay</p>
          <p>
            {formatPrice({
              amount:
                documentValue?.valueType === "DOCUMENT_TOTAL"
                  ? documentValue?.amount?.valueTotal
                  : documentValue?.amount?.customAmount,
              currency: documentValue.amount.currency,
            })}
          </p>
        </BoloButton>
      </DialogTrigger>
      <DialogContent className="!max-w-[400px] md:!max-w-[400px]">
        <div className="flex items-center flex-col">
          <FaRegCreditCard size={72} className="mt-12" />
          <p className="text-sm md:text-lg text-black font-semibold text-center mt-2">
            Please make one-time payment
          </p>
          <p className="text-xs  text-gray-600 text-center">
            You can pay this document's requested amount via a secure payment
            gateway
          </p>

          <div className="flex-col flex w-full mt-8 items-center">
            {managedDocument?.status !== "SIGNED" && (
              <>
                <p className="text-[10px] text-gray-600 text-center">
                  Some signers are yet to sign the document
                </p>
                <p className="text-[10px] text-gray-600 text-center">
                  Payment will be enabled when all the signers sign the document
                </p>
              </>
            )}
            <BoloButton
              disabled={managedDocument?.status !== "SIGNED" || loading}
              size={"sm"}
              variant="primary"
              className="mt-4 flex gap-2 w-full items-center justify-center text-center disabled:cursor-default"
              onClick={() => handleCreateStripePayemnt()}
              style={{
                background: accountInfo?.theme,
                borderColor: accountInfo?.theme,
              }}
            >
              {loading && <TailSpin color="#ffffff" height={20} width={20} />}
              <p>Continue to payment</p>
              {loading && <div className="min-w-[20px]"></div>}
            </BoloButton>
            <BoloButton
              disabled={loading}
              size={"sm"}
              variant="gray-primary"
              className="mt-2 flex gap-2 w-full items-center justify-center text-center"
              onClick={() => setOpen(false)}
              style={{
                color: accountInfo?.theme,
              }}
            >
              <p>View Document</p>
            </BoloButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EndUserPaymentButton;
