import React from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../Common/Dialog/BoloDialog";
import { BoloButton } from "../Common/Button/BoloButton";
import { FiShare2 } from "react-icons/fi";
import Select from "react-select";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/constants";

const ShareWithTeammates = ({ helpers }) => {
  let { documentType, documentName, documentId, isOpen, setIsOpen } = helpers;
  const workSpaceDetails = useWorkSpaceDetails();
  //   console.log(workSpaceDetails, "workSpaceDetails");
  const collaborators = workSpaceDetails?.currentWorkSpace?.collaborators;
  // const [isOpen, setIsOpen] = React.useState(false);
  const [selectedTeammates, setSelectedTeammates] = React.useState([]);

  const handleShare = async () => {
    try {
      console.log("selectedTeammates", selectedTeammates);
      console.log("documentType", documentType);
      console.log("documentName", documentName);
      console.log("documentId", documentId);

      let { data } = await axios.put(`${BASE_URL}/owners/share-documents`, {
        documentType,
        documentName,
        ...(documentType === "forms" ? { formId: documentId } : { documentId }),
        selectedTeammates,
      });

      console.log(data, "data");

      setIsOpen(false);
      toast.success("Document shared successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error sharing document");
    }
  };
  return (
    <div className="w-full">
      {" "}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        {/* <DialogTrigger asChild>
          <button
            onClick={async (e) => {
              e.stopPropagation();
              // new MixPanel().track("Form", {
              //   form_action: "form_remove_custom_pdf",
              //   paid: false,
              // });
              // new MixPanel().increment("form_remove_custom_pdf");
              // await removeCustomPdf(form?.formId);
            }}
            //   disabled={
            //     !workSpaceDetails?.isWorkSpaceOwner &&
            //     workSpaceDetails?.currentWorkSpaceRole === "readonly"
            //   }
            className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20`}
          >
            <FiShare2 className="text-xl hover:opacity-85 mr-2" />
            Share
          </button>
        </DialogTrigger> */}
        <DialogContent
          className=""
          onClick={() => {
            console.log("Dialog content clicked");
          }}
        >
          <DialogHeader>
            <DialogTitle>Share with Teammates</DialogTitle>
            <p>Share this document with your teammates to collaborate on it.</p>
          </DialogHeader>

          <div className="my-4">
            <label className="mb-2 text-xs block text-gray-600">
              Choose teammates
            </label>
            <Select
              className="text-sm"
              closeMenuOnSelect={false}
              isMulti
              options={[
                ...(collaborators || [])
                  ?.map((collaborator) => ({
                    value: collaborator?._id,
                    label: `${collaborator?.collaboratorId?.email}`,
                  }))
                  ?.filter(
                    (collaborator) =>
                      collaborator?.label !==
                      workSpaceDetails?.currentWorkSpace?.email
                  ),
              ]}
              onChange={(selectedOptions) => {
                setSelectedTeammates(selectedOptions);
              }}
            />
          </div>

          <DialogFooter>
            <BoloButton
              variant={"secondary"}
              size={"sm"}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </BoloButton>

            <BoloButton variant={"primary"} size={"sm"} onClick={handleShare}>
              Share
            </BoloButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShareWithTeammates;
