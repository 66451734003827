//@ts-nocheck
import "./index.css";
import React from "react";
import QuestionInput from "../../QuestionInput";
import QuestionDesc from "../../QuestionDesc";
import ImageQuestionPreview from "../../ImageQuestionPreview";
import QuestionBottomOptions from "../../QuestionBottomOptions";
import { MdClose } from "react-icons/md";

const GridCheckbox = ({ data }) => {
  let {
    question,
    formState,
    setFormState,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
    schemas,
  } = data;
  const isEditor =
    questionIndex.p === activeState.pageIndex &&
    questionIndex.q === activeState.questionIndex;

  const handleRowAdd = () => {
    let newRows = question.gridRange.rows;
    newRows.push(`Row ${newRows?.length + 1}`);
    const newGridRange = {
      ...question.gridRange,
      rows: newRows,
    };
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.gridRange = newGridRange;
          }
        });
      });
      return newState;
    });
  };

  const handleColAdd = () => {
    let newCols = question.gridRange.columns;
    newCols.push(`Column ${newCols?.length + 1}`);
    const newGridRange = {
      ...question.gridRange,
      columns: newCols,
    };
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.gridRange = newGridRange;
          }
        });
      });
      return newState;
    });
  };

  const handleRowDel = (rowIdx) => {
    let newRows = question.gridRange.rows;
    newRows.splice(rowIdx, 1);
    const newGridRange = {
      ...question.gridRange,
      rows: newRows,
    };
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.gridRange = newGridRange;
          }
        });
      });
      return newState;
    });
  };

  const handleColDel = (colIdx) => {
    let newCols = question.gridRange.columns;
    newCols.splice(colIdx, 1);
    const newGridRange = {
      ...question.gridRange,
      columns: newCols,
    };
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.gridRange = newGridRange;
          }
        });
      });
      return newState;
    });
  };

  const handleRowChange = (row, rowIdx) => {
    let newRows = question.gridRange.rows;
    newRows[rowIdx] = row;
    const newGridRange = {
      ...question.gridRange,
      rows: newRows,
    };
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.gridRange = newGridRange;
          }
        });
      });
      return newState;
    });
  };

  const handleColChange = (col, colIdx) => {
    let newCols = question.gridRange.columns;
    newCols[colIdx] = col;
    const newGridRange = {
      ...question.gridRange,
      columns: newCols,
    };
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.gridRange = newGridRange;
          }
        });
      });
      return newState;
    });
  };

  const getColName = (col) => {
    const nCols = question?.gridRange?.columns?.length || 0;
    const sIdx = Math.max(13 - parseInt(nCols), 0);
    return col?.slice(0, sIdx) + (col?.length > sIdx ? "..." : "");
  };

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <QuestionInput
            helpers={{
              question,
              setFormState,
              isEditor,
              questionIndex,
              activeState,
            }}
          />
        </div>
        <QuestionDesc
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
      </div>
      <ImageQuestionPreview
        helpers={{ question, setFormState, questionIndex, activeState }}
      />
      <div className="">
        {isEditor && (
          <div className=" flex mb-6 justify-between">
            <div className="flex flex-col text-sm w-[48%] gap-1">
              <span className="text-sm font-medium mb-3">Rows</span>
              {question?.gridRange?.rows?.map((row, rowIdx) => {
                return (
                  <div
                    className="h-[48px] flex flex-row items-center gap-1"
                    key={rowIdx}
                  >
                    <div className="h-5 min-w-[20px] w-[20px]  mr-2 mb-1">
                      {rowIdx + 1}.
                    </div>
                    <input
                      type="text"
                      value={row}
                      onChange={(e) => {
                        handleRowChange(e.target.value, rowIdx);
                      }}
                      className="outline-none w-full hover:border-b border-gray-300 b-btm-fade pb-1 pl-1"
                    />
                    <div
                      style={{
                        visibility: !isEditor ? "hidden" : "visible",
                      }}
                      onClick={() => {
                        handleRowDel(rowIdx);
                      }}
                      className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                    >
                      <MdClose className="text-2xl text-gray-500" />
                    </div>
                  </div>
                );
              })}
              <div className="h-[48px] flex flex-row items-center">
                <div
                  className="flex"
                  onClick={() => {
                    handleRowAdd();
                  }}
                >
                  <div className="h-5 w-5 mr-3 mb-1">
                    {question?.gridRange?.rows?.length + 1 || 0}.
                  </div>
                  <div className="w-[85px] text-sm opacity-70  text-gray-500 pb-1 border-b border-white hover:border-gray-200 focus:border-primary outline-none">
                    Add Option
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-sm w-[48%] gap-1">
              <span className="text-sm font-medium mb-3">Columns</span>
              {question?.gridRange?.columns?.map((col, colIdx) => {
                return (
                  <div
                    className="h-[48px] flex flex-row items-center gap-1"
                    key={colIdx}
                  >
                    <div className="h-5 min-w-[20px] w-[20px]  mr-3 mb-1 border-2 border-[#00000033] rounded"></div>
                    <input
                      type="text"
                      value={col}
                      onChange={(e) => {
                        handleColChange(e.target.value, colIdx);
                      }}
                      className="outline-none w-full hover:border-b border-gray-300 b-btm-fade pb-1 pl-1"
                    />
                    <div
                      style={{
                        visibility: !isEditor ? "hidden" : "visible",
                      }}
                      onClick={() => {
                        handleColDel(colIdx);
                      }}
                      className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                    >
                      <MdClose className="text-2xl text-gray-500" />
                    </div>
                  </div>
                );
              })}
              <div className="h-[48px] flex flex-row items-center">
                <div
                  className="flex"
                  onClick={() => {
                    handleColAdd();
                  }}
                >
                  <div className="h-5 w-5 mr-3 mb-1 border-2 border-[#00000033] rounded"></div>
                  <div className="w-[85px] text-sm opacity-70  text-gray-500 pb-1 border-b border-white hover:border-gray-200 focus:border-primary outline-none">
                    Add Option
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isEditor && (
          <div className="mb-6">
            <div className="h-[50px] w-full flex text-sm">
              <div className="w-[100%] h-full flex items-center justify-center"></div>
              {question?.gridRange?.columns?.map((col, colIdx) => {
                return (
                  <div
                    className="w-[100%] h-full flex items-center justify-center"
                    key={colIdx}
                  >
                    {col}
                  </div>
                );
              })}
            </div>
            {question?.gridRange?.rows?.map((row, rowIdx) => {
              return (
                <div className="h-[50px] w-full flex text-sm" key={rowIdx}>
                  <div className="w-[100%] h-full flex items-center">
                    <p className="pl-4">{row}</p>
                  </div>
                  {question?.gridRange?.columns?.map((col, colIdx) => {
                    return (
                      <div
                        className="w-[100%] h-full flex items-center justify-center"
                        key={colIdx}
                      >
                        <div className="h-5 w-5 border-2 border-[#00000033] rounded"></div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <QuestionBottomOptions
        helpers={{
          question,
          setFormState,
          questionIndex,
          activeState,
          setActiveState,
          setActive,
          formState,
          schemas,
        }}
      />
    </div>
  );
};

export default GridCheckbox;
