import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import { IoCopyOutline, IoLinkOutline } from "react-icons/io5";
import { MdCheck, MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { calculateContrast, getTintFromHex } from "../../utils/uiUtils";
import CustomTooltip from "../EditForm/CustomTooltip";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { BoloLoaderLine } from "../Common/Loader/BoloLoader";
import { BoloButton } from "../Common/Button/BoloButton";
import {
  getEmbedHome,
  isEmbeded,
  getIfIsInChromeExtensionIframe,
} from "../../utils/embed";

interface ShareViaLinkProps {
  setShowShareViaLink: React.Dispatch<React.SetStateAction<boolean>>;
  documentId: string;
  sendToSigners: any;
  generatedLinks: any;
  type: string;
  sending: boolean;
  receiversList?: any[];
}

const ShareViaLink = ({
  setShowShareViaLink,
  documentId,
  sendToSigners,
  generatedLinks,
  type,
  sending,
  receiversList,
}: ShareViaLinkProps) => {
  const [generatedLink, setGeneratedLink] = useState(generatedLinks || []);
  const [linksGenerated, setLinksGenerated] = useState(false);
  const [documentData, setDocumentData] = useState<any>(null);
  const [Copied, setCopied] = useState(-1);
  const [loading, setLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   setGeneratedLink(generatedLinks || []);
  // }, [generatedLinks]);

  const getGeneratedLinks = async (documentId: string) => {
    try {
      let { data } = await axios.get(
        `/getOneDocument?documentId=${documentId}`
      );
      setDocumentData(data?.document);
      setGeneratedLink(data?.document?.respondants || []);
    } catch (err) {
      toast.error("Error in generating Links!!");
      console.error(err);
    }
  };
  const handleClose = () => {
    if (!linksGenerated) {
      setShowShareViaLink(false);
    } else {
      handleRedirect();
    }
  };

  const handleRedirect = () => {
    if (isEmbeded()) {
      if (getIfIsInChromeExtensionIframe()) {
        window?.top?.postMessage({ action: "closeModal" }, "*");
      }
      window.location.href = getEmbedHome();
    }
    if (sending) {
      if (type == "PDF") {
        window.location.href = `/dashboard/pdfs?page=1`;
      } else if (type == "PDF-TEMPLATE") {
        window.location.href = `/dashboard/templates?page=1`;
      }
    } else {
      setShowShareViaLink(false);
    }
  };

  useEffect(() => {
    if (type === "PDF") {
      getGeneratedLinks(documentId);
    }
  }, []);

  useEffect(() => {
    if (type == "PDF-TEMPLATE") {
      generatedLinks?.length > 0
        ? setGeneratedLink(generatedLinks || [])
        : setGeneratedLink(receiversList || []);
    }
  }, [generatedLinks]);

  useEffect(() => {
    generatedLink?.forEach((link: any) => {
      if (link?.shortUrl) {
        setLinksGenerated(true);
        return;
      }
    });
  }, [generatedLink]);

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center py-3"
    >
      <div
        className={`w-[95%] md:w-[780px] relative bg-white rounded px-4 sm:px-8 py-5 `}
      >
        <div className="flex  gap-2 mb-1">
          <h1 className="text-lg md:text-xl flex gap-3 font-semibold text-gray-700">
            Share Link{" "}
          </h1>
        </div>
        <BoloLoaderLine loading={loading} />
        {/* {generatedLink.length == 0 ? ( */}
        {/* ) : ( */}
        <div className="w-full">
          <div className="mb-6 sm:mb-8 flex border border-blue-200 bg-blue-50 p-2 rounded items-center mt-4 gap-4">
            <IoLinkOutline
              size={80}
              className="text-lg text-gray-400 hover:opacity-85 mx-3 my-1"
            />
            <div>
              <p className="text-sm text-gray-700 font-semibold">
                Generate Link to share the document.
              </p>
              {/* <p className="text-xs text-gray-500 ">
                Once links are generated, the document will be in{" "}
                <span className="font-semibold text-gray-700">
                  SENT VIA LINK
                </span>{" "}
                status.
              </p> */}
              <p className="text-xs text-gray-500 mt-2">
                Links are unique for each recipient. Make sure that the intended
                recipients/signers are the only ones accessing the document
                through their link.
              </p>
            </div>
          </div>
          <div
            className={`my-10 ${
              linksGenerated ? "" : "pointer-events-none opacity-50"
            }`}
          >
            {generatedLink?.map((recipient: any, index: number) => (
              <div
                key={index}
                className=" flex flex-col items-center gap-4 text-xs my-4"
              >
                <div className="flex flex-col md:flex-row  justify-between gap-2 w-full  my-2">
                  <div
                    className="border flex items-center sm:text-center min-w-[40%] sm:min-w-[30%] md:min-w-[20%] px-4 py-1 sm:py-2 w-full sm:w-fit text-gray-700 rounded font-medium "
                    style={{
                      backgroundColor: getTintFromHex(
                        recipient.colour || recipient?.roleColour,
                        90
                      ),
                      borderColor: recipient.colour || recipient?.roleColour,
                    }}
                  >
                    <p
                      className={`w-full text-xs  outline-none`}
                      style={{
                        color: recipient.colour || recipient.roleColour,
                      }}
                    >
                      {recipient?.name || recipient?.roleTitle || ""}
                    </p>
                  </div>
                  <div className="flex-1 flex gap-2">
                    <div className=" flex-1 rounded-md">
                      <input
                        className={`w-full bg-white px-1.5 py-1.5 my-0.5 border-b-2 border-primary text-xs text-[#70757a] outline-none`}
                        value={
                          recipient?.shortUrl ||
                          "https://signature.boloforms.com/..."
                        }
                        onChange={() => {}}
                      ></input>
                    </div>
                    <div className="w-[13%] justify-center flex ">
                      {recipient.status == "SIGNED" ? (
                        <button
                          data-tooltip-id="SignedTooltip"
                          className={`flex px-1 flex-row items-center text-center text-xs capitalize  text-gray-800 rounded font-medium `}
                        >
                          <MdCheck className="text-lg md:text-xl text-gray-600 hover:opacity-85 sm:mr-1" />
                          <span className="hidden sm:inline">Signed</span>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(recipient?.shortUrl);
                            setCopied(index);
                            setTimeout(() => {
                              setCopied(-1);
                            }, 3000);
                          }}
                          className={`flex px-1 items-center justify-center text-xs capitalize  text-gray-800 rounded font-medium `}
                        >
                          {Copied === index ? (
                            <LuCopyCheck className="text-lg md:text-xl text-gray-600 hover:opacity-85 md:mr-2" />
                          ) : (
                            <LuCopy className="text-lg md:text-xl text-gray-600 hover:opacity-85 md:mr-2" />
                          )}

                          <span className="hidden sm:inline">
                            {Copied === index ? "Copied" : "Copy"}
                          </span>
                        </button>
                      )}
                    </div>
                    <CustomTooltip
                      helpers={{
                        id: "SignedTooltip",
                        content: "This document is already signed.",
                        place: "right",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end gap-4">
            {!linksGenerated ? (
              <BoloButton
                size={"sm"}
                className="px-5 py-2 bg-primary rounded text-white font-medium text-xs md:text-sm hover:opacity"
                onClick={async () => {
                  if (!sending) {
                    return;
                  }
                  setLoading(true);
                  if (type == "PDF-TEMPLATE") {
                    await sendToSigners(true);
                  } else if (type == "PDF") {
                    await sendToSigners({ isLink: true, _redirect: false });
                    getGeneratedLinks(documentId);
                  }
                  setLoading(false);
                }}
                disabled={loading}
              >
                <span className="">
                  {" "}
                  {loading ? "Generating Link" : "Generate Link"}
                </span>
              </BoloButton>
            ) : (
              <BoloButton
                size={"sm"}
                onClick={handleRedirect}
                className="px-5 py-2 bg-primary rounded text-white ml-2 font-medium text-xs md:text-sm hover:opacity-75"
              >
                {" "}
                {sending ? "Done" : "Close"}
              </BoloButton>
            )}
          </div>
        </div>
        {/* )} */}
        <div
          className="absolute top-0 md:top-1 right-0 sm:right-2 cursor-pointer"
          onClick={handleClose}
        >
          <MdClose className="text-2xl m-4" />
        </div>
      </div>
    </div>
  );
};

export default ShareViaLink;
