import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { MdDelete, MdImage } from "react-icons/md";
import { DesignerState, GlobalHelpersType } from "..";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import BoloCheckboxMui from "../../../../../../components/Common/Checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../../components/Common/Dialog/BoloDialog";
import { BoloLoaderLine } from "../../../../../../components/Common/Loader/BoloLoader";
import CustomTooltip from "../../../../../../components/EditForm/CustomTooltip";
import { ProductType } from "../../../../../../hooks/reduxHooks/useQuoteBuilder";
import { fetchAllProducts } from "../../../../../../redux/apis/product";
import {
  formatPrice,
  getDefaultCurrency,
  getRowSubtotal,
} from "../../../../../../utils/quoteBuilder";
import { AddMutipleProductFunc } from "./QuoteBuilder";

export type ProductAdderModalProps = {
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  addMultipleProducts: AddMutipleProductFunc;
};

const ProductAdderModal = ({
  designerState,
  setDesignerState,
  addMultipleProducts,
}: ProductAdderModalProps) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [products, setProducts] = useState<{
    products: ProductType[];
    productCount: number;
  }>({ products: [], productCount: 0 });
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<
    { product: ProductType; qty: number }[]
  >([]);

  const handleCheckBox = ({
    product,
    action,
  }: {
    product: ProductType;
    action: "add" | "remove";
  }) => {
    if (action === "add") {
      setSelectedProducts((prev) => [...prev, { product: product, qty: 1 }]);
    }

    if (action === "remove") {
      let newSelectedProducts: { product: ProductType; qty: number }[] =
        structuredClone(selectedProducts);
      let productId = newSelectedProducts.findIndex(
        (elm) => elm.product?.productId === product?.productId
      );
      if (productId !== -1) {
        newSelectedProducts.splice(productId, 1);
      }
      setSelectedProducts(newSelectedProducts);
    }
  };

  const handleSelectedProductQty = ({
    product,
    qty,
  }: {
    product: ProductType;
    qty: number;
  }) => {
    let newSelectedProducts: { product: ProductType; qty: number }[] =
      structuredClone(selectedProducts);
    let productId = newSelectedProducts.findIndex(
      (elm) => elm.product?.productId === product?.productId
    );
    if (productId !== -1) {
      if (qty <= 0) {
        newSelectedProducts.splice(productId, 1);
      } else {
        newSelectedProducts[productId].qty = qty;
      }
    }
    setSelectedProducts(newSelectedProducts);
  };

  const handleAddProducts = () => {
    let idx = designerState.quoteBuilder.activeTable?.rows.length
      ? designerState.quoteBuilder.activeTable?.rows.length
      : 0;
    addMultipleProducts({
      data: selectedProducts?.map((elm) => ({
        header: elm.product?.name || "",
        description: elm?.product?.description || "",
        images: elm?.product?.images,
        currency: elm?.product?.pricing?.currencyCode,
        price: Number(elm?.product?.pricing?.price),
        qty: elm?.qty,
      })),
      idx,
    });
    setOpen(false);
  };

  const handleSearch = () => {
    // console.log("handle search called");
    setSearchQuery(searchText);
  };

  async function getProducts() {
    setLoading(true);
    try {
      const data = await fetchAllProducts({ limit: -1, query: searchQuery });
      setProducts({
        products: data?.products || [],
        productCount: data?.productCount || 0,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    getProducts();
    setSelectedProducts([]);
    setProducts({ products: [], productCount: 0 });
  }, [open]);

  useEffect(() => {
    getProducts();
  }, [searchQuery]);

  return (
    <div>
      <Dialog
        onOpenChange={(value) => {
          setOpen(value);
        }}
        open={open}
      >
        <DialogTrigger asChild>
          <BoloButton
            variant={"primary"}
            className="flex gap-2 items-center"
            size={"xs"}
            onClick={() => {}}
          >
            <FaPlus />
            From Catalog
          </BoloButton>
        </DialogTrigger>
        <DialogContent className="flex flex-col justify-between w-[80vw] h-[80vh] max-w-[80vw] max-h-[80vh] min-w-[80vw] min-h-[80vh] ">
          <DialogHeader className="h-fit">
            <DialogTitle>
              <div className="flex justify-between items-center pr-10">
                <div className="">
                  <div>Add Products</div>
                  <div className="text-[10px] mt-1 text-gray-400 font-medium">
                    Non{" "}
                    <span className="font-semibold">
                      {
                        designerState?.quoteBuilder?.activeTable?.subtotalMenu
                          ?.currency
                      }
                    </span>{" "}
                    products are disabled
                  </div>
                </div>
                <form
                  onSubmit={handleSearch}
                  className={`font-normal h-full text-xs flex flex-row gap-2 w-full md:w-[500px]`}
                >
                  <div className="border shadow overflow-hidden h-full flex rounded-md w-full md:w-[500px]">
                    <input
                      placeholder="Search by Name, SKU or Category"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        if (!e.target.value) {
                          setSearchQuery("");
                        }
                      }}
                      className="w-full md:w-[500px] h-full py-2 px-4   outline-none text-gray-700 "
                    />
                    <button
                      type="submit"
                      className="min-w-[40px] flex justify-center items-center bg-primary text-white cursor-pointer hover:opacity-90"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSearch();
                      }}
                    >
                      <BsSearch className="text-xl" />
                    </button>
                  </div>
                </form>
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="h-full overflow-y-auto">
            <BoloLoaderLine loading={loading} />
            <div
              className="flex h-[calc(100%_-_8px)] overflow-y-auto border-t-2 border-gray-100"
              id="mini-pdf-custom-scroll"
            >
              <div className="flex flex-col w-[70%] border-r-2 border-gray-100 relative">
                <div className="max-h-[40px] h-[40px] min-h-[40px] border-b-2 flex items-center w-full text-gray-500 border-gray-100 absolute top-0 left-0 right-0 bg-white">
                  <div className="w-[8%] flex items-center justify-center">
                    {/* <BoloCheckboxMui
                    onChange={(e) => {}}
                    // checked={}
                    // disabled={}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        height: 18,
                        width: 18,
                      },
                    }}
                  /> */}
                  </div>
                  <div className="w-[50%] flex">
                    <div className="w-[40px] max-w-[40px] mr-3 "></div>
                    <div className="flex flex-col pr-2 w-[calc(100%_-_40px)]">
                      <div className="text-xs ">Name</div>
                    </div>
                  </div>
                  <div className="w-[20%] pr-2">SKU</div>
                  <div className="w-[20%] text-right pr-2">Price</div>
                </div>
                <div
                  className="overflow-y-auto mt-[40px]"
                  id="mini-pdf-custom-scroll"
                >
                  {products?.products?.map((product) => {
                    let checked =
                      selectedProducts.findIndex(
                        (elm) => elm.product?.productId === product?.productId
                      ) !== -1;
                    let currencyMatch =
                      designerState?.quoteBuilder?.activeTable?.subtotalMenu
                        ?.currency === product?.pricing?.currencyCode;
                    return (
                      <>
                        <div
                          data-tooltip-id={`currency-mismatch-${product?.productId}`}
                          className={`h-[60px] max-h-[60px] min-h-[60px] border-b-2 flex items-center w-full hover:bg-gray-100 border-gray-100 ${
                            currencyMatch
                              ? "cursor-pointer"
                              : "cursor-not-allowed opacity-50"
                          } ${checked ? "bg-secondary/50" : ""}`}
                          onClick={() => {
                            if (currencyMatch) {
                              handleCheckBox({
                                product,
                                action: checked ? "remove" : "add",
                              });
                            }
                          }}
                        >
                          <div className="w-[8%] flex items-center justify-center">
                            <BoloCheckboxMui
                              onChange={(e) => {}}
                              checked={checked}
                              disabled={!currencyMatch}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  height: 18,
                                  width: 18,
                                },
                              }}
                            />
                          </div>
                          <div className="w-[50%] flex">
                            <div className="w-[40px] max-w-[40px] h-[40px] max-h-[40px] rounded flex items-center justify-center mr-3 bg-gray-100">
                              {product?.images?.length === 0 ? (
                                <MdImage className="text-gray-400" size={25} />
                              ) : (
                                <img
                                  src={product?.images?.[0]}
                                  className="h-[40px] w-[40px] object-contain rounded"
                                />
                              )}
                            </div>
                            <div className="flex flex-col pr-2 w-[calc(100%_-_40px)]">
                              <div className="text-xs font-semibold text-black mb-1 truncate">
                                {product?.name}
                              </div>
                              <div className="text-[10px] rounded bg-gray-100 px-2 py-0.5 w-fit text-gray-500">
                                {product?.category || "NO CATEGORY"}
                              </div>
                            </div>
                          </div>
                          <div className="w-[20%] pr-2">{product?.SKU}</div>
                          <div className="w-[20%] text-right pr-2">
                            {formatPrice({
                              amount: Number(product?.pricing?.price) ?? 0,
                              currency: product?.pricing?.currencyCode,
                            })}
                          </div>
                        </div>
                        {!currencyMatch && (
                          <CustomTooltip
                            helpers={{
                              id: `currency-mismatch-${product?.productId}`,
                              content:
                                "The currency of the product does not match to the currency of table and document",
                              place: "top",
                            }}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="w-[30%] flex flex-col justify-between">
                <div
                  className="flex flex-col overflow-y-auto"
                  id="mini-pdf-custom-scroll"
                >
                  {selectedProducts?.map((selectedProduct) => {
                    return (
                      <div
                        className={`group h-[80px] max-h-[80px] min-h-[80px] border-b-2 border-gray-50 hover:bg-gray-50 flex flex-col justify-center px-4 text-gray-600 py-1`}
                      >
                        <div className="flex justify-between">
                          <div className="flex items-center">
                            <div className="w-[35px] max-w-[35px] h-[35px] max-h-[35px] rounded flex items-center justify-center mr-3 bg-gray-100">
                              {selectedProduct?.product?.images?.length ===
                              0 ? (
                                <MdImage className="text-gray-400" size={25} />
                              ) : (
                                <img
                                  src={selectedProduct?.product?.images?.[0]}
                                  className="h-[35px] w-[35px] object-contain rounded"
                                />
                              )}
                            </div>
                            <div className="font-semibold text-black text-sm truncate">
                              {selectedProduct?.product?.name}
                            </div>
                          </div>
                          <div className="hidden group-hover:flex items-center justify-center">
                            <MdDelete
                              className="text-red-500 cursor-pointer"
                              size={18}
                              onClick={() => {
                                handleSelectedProductQty({
                                  product: selectedProduct.product,
                                  qty: 0,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-1.5">
                          <div className="flex rounded border">
                            <div
                              className="flex items-center justify-center hover:bg-gray-300 cursor-pointer p-1 border-r min-w-[30px] max-w-[30px]"
                              onClick={() => {
                                handleSelectedProductQty({
                                  product: selectedProduct.product,
                                  qty: selectedProduct?.qty - 1,
                                });
                              }}
                            >
                              <FaMinus />
                            </div>
                            <input
                              type="number"
                              min="0"
                              value={selectedProduct.qty}
                              onChange={(e) => {
                                let qty = Number(e.target.value);
                                handleSelectedProductQty({
                                  product: selectedProduct.product,
                                  qty,
                                });
                              }}
                              className="outline-none p-1 min-w-[30px] max-w-[30px] text-center"
                            />
                            <div
                              className="flex items-center justify-center hover:bg-gray-300 cursor-pointer p-1 border-l min-w-[30px] max-w-[30px]"
                              onClick={() => {
                                handleSelectedProductQty({
                                  product: selectedProduct.product,
                                  qty: selectedProduct?.qty + 1,
                                });
                              }}
                            >
                              <FaPlus />
                            </div>
                          </div>
                          <div>
                            {formatPrice({
                              amount: getRowSubtotal({
                                price: Number(
                                  selectedProduct?.product?.pricing?.price
                                ),
                                qty: selectedProduct?.qty,
                              }).subtotal,
                              currency:
                                selectedProduct?.product?.pricing?.currencyCode,
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {selectedProducts?.length > 0 && (
                  <div className="p-3 mt-2 border-t-2 border-gray-100 flex flex-col gap-2 text-gray-600">
                    <div className=" flex items-center justify-between">
                      <span className="">TOTAL</span>
                      <span className="font-semibold text-black">
                        {formatPrice({
                          amount: selectedProducts.reduce(
                            (acc, selectedProduct) =>
                              getRowSubtotal({
                                price: Number(
                                  selectedProduct?.product?.pricing?.price
                                ),
                                qty: selectedProduct?.qty,
                              }).subtotal + acc,
                            0
                          ),
                          currency:
                            designerState?.quoteBuilder?.activeTable
                              ?.subtotalMenu?.currency || getDefaultCurrency(),
                        })}
                      </span>
                    </div>
                    <BoloButton
                      size={"xs"}
                      className="w-full"
                      onClick={() => handleAddProducts()}
                    >
                      Add Products
                    </BoloButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProductAdderModal;
