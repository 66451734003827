import { useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./EmailInputWithRoles.css";

import { MdDelete, MdOutlineKeyboardArrowDown } from "react-icons/md";
import SigningOrderDiagram from "../SigningOrderDiagram";
import EmailSingleSelect from "./EmailSingleSelect";
import CustomTooltip from "../EditForm/CustomTooltip";
import { useLocation } from "react-router-dom";
import { IoKey } from "react-icons/io5";
import AccessCodeDetailsComponent from "./AccessCodeDetailsComponent";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
import MobileNoSingleSelect from "./MobileNoSingleSelect";
import MixPanel from "../../utils/services/mixpanel";
import { COLORS } from "../../constants/common";
import { ALL_FEATURES } from "../../types/tiers";
import { ToastContainer, Slide, toast } from "react-toastify";
import axios from "../../utils/axios";

const EmailInputWithRoles = ({
  onSave,
  resetAll,
  isOnlySignature,
  isPdfTemplate = false,
  roles = [],
  receiversList,
  setReceiversList,
  isSigningOrderData,
  setIsSigningOrderData,
  documentData,
  sendViaSMS,
  setSendViaSMS,
  approvalWorkflowSettedUp,
  approvalWorkflowEnabled,
  setApprovalWorkflowEnabled,
}: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const featuresEnabled = useIsFeatureEnabled();

  const [showInputForm, setShowInputForm] = useState<boolean>(true);
  const [emails, setEmails] = useState<string[]>([]);
  const [recipient, setRecipient] = useState<
    {
      email: string;
      name: string;
      message?: string;
      colour?: string;
      signingOrderNo: number;
    }[]
  >([]);
  const [sendToEmail, setSendToEmail] = useState("");
  const [sendToName, setSendToName] = useState("");
  const [selectedColor, setSelectedColor] = useState(COLORS.primary);

  const [sendToNote, setSendToNote] = useState("");
  const [currentEmail, setCurrentEmail] = useState<string>("");

  const signingOrderDiagramRef = useRef(null);
  const [isSigningOrderDiagram, setIsSigningOrderDiagram] =
    useState<Boolean>(false);
  const owner = JSON.parse(localStorage.getItem("owner") || "");

  const [showAccessCode, setShowAccessCode] = useState<{
    show: boolean;
    email: string;
    roleTitle: string;
  }>({
    show: false,
    email: "",
    roleTitle: "",
  });

  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });

  // const [roleStatus, setRoleStatus] = useState<{
  //   [key: string]: Boolean;
  // }>(() => {
  //   return roles.reduce(
  //     (acc: { [x: string]: Boolean }, role: { title: string }) => {
  //       acc[role.title] = true;
  //       return acc;
  //     },
  //     {}
  //   );
  // });

  const updateAccessCodeState = (email: string, roleTitle: string) => {
    if (email === "" && roleTitle === "") {
      setShowAccessCode({
        show: false,
        email: "",
        roleTitle: "",
      });
    } else if (roleTitle === showAccessCode.roleTitle) {
      setShowAccessCode({
        show: !showAccessCode.show,
        email: email,
        roleTitle: roleTitle,
      });
    } else {
      setShowAccessCode({
        show: true,
        email: email,
        roleTitle: roleTitle,
      });
    }
  };

  useEffect(() => {
    if (resetAll) {
      setEmails([]);
      setRecipient([]);
      setShowInputForm(false);
    } else {
      return;
    }
  }, [resetAll]);

  const updateEmailValue = (newValue: any, index: number, name: any) => {
    const newReceiversList = [...receiversList];
    if (newReceiversList.length > 0) {
      newReceiversList[index].email = newValue;
      if (name?.trim()?.length > 0) {
        newReceiversList[index].name = name.trim();
      }
      setReceiversList(newReceiversList);
    }
  };
  const updateMobileNoValue = (
    newValue: any,
    index: number,
    name: any,
    email: string
  ) => {
    const newReceiversList = [...receiversList];
    if (newReceiversList.length > 0) {
      newReceiversList[index].phone = newValue;
      if (name?.trim()?.length > 0) {
        newReceiversList[index].name = name.trim();
      }
      if (email?.trim()?.length > 0) {
        newReceiversList[index].email = email.trim();
      }
      setReceiversList(newReceiversList);
    }
  };
  return (
    <div className=" w-[100%] md:w-[65%] max-w-[800px] md:mt-4 ">
      <div
        className="md:pb-4 cursor-pointer m-0 flex justify-between items-center"
        // onClick={handleTextClick}
      >
        <div className="">
          <p className="text-sm md:text-[16px] mb-1 md:mb-3 w-full font-medium text-gray-700">
            {documentData?.documentName}
          </p>
          <h1 className="text-lg md:text-2xl font-bold mb-1 md:mb-3 text-gray-700">
            Add Recipients
          </h1>
          <p className="text-xs md:text-md mb-1 md:mb-3 w-full capitalize font-medium text-gray-500">
            Add Recipients to send the document to signers and receive a signed
            document.
          </p>
        </div>
      </div>
      {isSigningOrderDiagram && (
        <SigningOrderDiagram
          recipientList={receiversList}
          setIsSigningOrderDiagram={setIsSigningOrderDiagram}
          isSigningOrderDiagram={isSigningOrderDiagram}
        />
      )}
      <div className="flex gap-2">
        <div className="flex gap-2 text-xs text-gray-500 mt-2 whitespace-nowrap items-center">
          <input
            type="checkbox"
            checked={isSigningOrderData}
            onChange={() => {
              setIsSigningOrderData(!isSigningOrderData);
            }}
          />
          Set Signing Order Data
          {isSigningOrderData && (
            <div
              className="text-blue-400 cursor-pointer flex gap-2 items-center"
              onClick={() => {
                setIsSigningOrderDiagram(true);
              }}
            >
              View Order
            </div>
          )}
        </div>
        {approvalWorkflowSettedUp && (
          <label className="pl-4 flex gap-2 text-xs text-gray-500 mt-2 whitespace-nowrap items-center border-l border-gray-500">
            <input
              type="checkbox"
              checked={approvalWorkflowEnabled}
              onChange={async () => {
                if (sendViaSMS) {
                  setSendViaSMS(false);
                }

                let message = !approvalWorkflowEnabled
                  ? "Approval Workflow Enabled!"
                  : "Approval Workflow Disabled!";

                try {
                  const { data } = await axios.post(`/document/settings`, {
                    documentId: documentData?.documentId,
                    approvalWorkflow: {
                      enabled: !approvalWorkflowEnabled,
                      approvers:
                        documentData?.settings?.approvalWorkflow?.approvers,
                    },
                  });
                } catch (e) {
                  console.log("erorr while updating workflow status");
                }

                setApprovalWorkflowEnabled((prev: any) => !prev);

                toast.success(message);
              }}
            />
            Approval Workflow
          </label>
        )}
        <div className="flex gap-2 text-xs text-gray-500 mt-2 border-l border-gray-500">
          <input
            type="checkbox"
            disabled={approvalWorkflowEnabled}
            className="ml-2 cursor-pointer"
            checked={sendViaSMS}
            onChange={(e) => {
              if (approvalWorkflowEnabled) {
                return;
              }
              if (!featuresEnabled?.SMS_AUTHENTICATION) {
                setUpgradeModalType("SMS_AUTHENTICATION");
                setShowUpgradeModal(true);
                return;
              }
              setSendViaSMS(!sendViaSMS);
            }}
          />
          Send via SMS
        </div>
      </div>
      {showInputForm && roles && isPdfTemplate === true && (
        <div>
          {roles.map(
            (role: { title: string; colour: string }, index: number) => {
              return (
                <div key={index} className="flex flex-col items-center text-xs">
                  <div className="w-full email-roles-grid-container grid md:flex items-center gap-2 pt-3 pb-0 sm:pb-3 ">
                    <div className="flex gap-2 name-num-grid-area w-[100%] md:w-fit">
                      {isSigningOrderData && (
                        <div className="w-[40px] md:w-[55px] flex flex-row justify-center items-center gap-2 border border-gray-300 rounded-md p-2">
                          <input
                            type="number"
                            min="1"
                            max={receiversList?.length}
                            value={receiversList[index].signingOrderNo}
                            onChange={(e) => {
                              const newReceiversList = [...receiversList];
                              newReceiversList[index].signingOrderNo = isNaN(
                                parseInt(e.target.value)
                              )
                                ? ""
                                : parseInt(e.target.value);
                              setReceiversList(newReceiversList);
                            }}
                            className="hide-spinner w-full text-xs md:text-sm outline-none text-center"
                          />
                        </div>
                      )}
                      <div className="w-[100%] md:w-[100%] flex flex-row justify-center items-center gap-2 border border-gray-300 rounded-md p-2">
                        <input
                          type="text"
                          placeholder="Name"
                          value={receiversList[index]?.name}
                          onChange={(e) => {
                            const newReceiversList = [...receiversList];
                            newReceiversList[index].name = e.target.value;
                            setReceiversList(newReceiversList);
                          }}
                          className="w-full text-xs md:text-sm outline-none "
                          disabled={
                            receiversList[index].isEnabled ? false : true
                          }
                        />
                      </div>
                    </div>
                    <div className=" w-full flex flex-row justify-center items-center gap-2 rounded-md">
                      {/* It select email from the contacts list and can add new email if needed */}
                      {!sendViaSMS ? (
                        <EmailSingleSelect
                          selectedValue={receiversList[index]?.email}
                          updateEmailValue={updateEmailValue}
                          index={index}
                          disabled={
                            receiversList[index].isEnabled ? false : true
                          }
                        />
                      ) : (
                        <MobileNoSingleSelect
                          selectedValue={receiversList[index]?.phone}
                          updateMobileValue={updateMobileNoValue}
                          index={index}
                          disabled={
                            receiversList[index].isEnabled ? false : true
                          }
                        />
                      )}
                    </div>

                    <div
                      className="cursor-pointer border border-gray-300 rounded-md p-2"
                      data-tooltip-id={`${role.title}AccessCode`}
                      data-tooltip-html="Access Code:<br />Enter a code that only you and this recipient know."
                      onClick={async () => {
                        // if (
                        //   !featuresEnabled?.ACCESS_CODE &&
                        //   getOwnerPlanDetails()?.isLifeTime
                        // ) {
                        //   setUpgradeModalType("ADDON_FEATURE");
                        //   setShowUpgradeModal(true);
                        //   return;
                        // }

                        if (!featuresEnabled?.ACCESS_CODE) {
                          setUpgradeModalType("ACCESS_CODE");
                          setShowUpgradeModal(true);
                          return;
                        }

                        updateAccessCodeState(
                          receiversList[index]?.email,
                          role.title
                        );
                      }}
                    >
                      <CustomTooltip
                        helpers={{
                          id: `${role.title}AccessCode`,
                          content: `Access Code: Enter a code that only you and this recipient know. This code will be required to access the document.`,
                          place: "top",
                        }}
                      />
                      <IoKey
                        size={20}
                        className={`${
                          receiversList[index].accessCode
                            ? "text-gray-800"
                            : "text-gray-500"
                        }`}
                      />
                    </div>

                    <div
                      className="role-grid-area md:h-[38px] w-[100%]  flex md:justify-center items-center text-gray-700 p-1 md:p-2 px-2 rounded-md font-semibold md:w-[180px] text-start border  "
                      style={{
                        backgroundColor: role.colour + 40,
                        borderColor: role.colour,
                      }}
                    >
                      <span
                        className={`truncate ${
                          receiversList[index].isEnabled
                            ? ""
                            : "line-through decoration-red-600 decoration-2"
                        } `}
                      >
                        {role.title}
                      </span>

                      <div
                        data-tooltip-id={`${role.title}Checkbox`}
                        className="ml-auto role-checkbox"
                      >
                        <CustomTooltip
                          helpers={{
                            id: `${role.title}Checkbox`,
                            content: `${
                              receiversList[index].isEnabled
                                ? "Disable"
                                : "Enable"
                            } ${role.title}`,
                            place: "right",
                          }}
                        />
                        <input
                          type="checkbox"
                          className=""
                          style={{
                            accentColor: role.colour + 50,
                          }}
                          checked={receiversList[index].isEnabled}
                          onChange={() => {
                            new MixPanel().track("PDF template", {
                              pdf_template_action:
                                "pdf_template_enable_disable_roles",
                              paid: false,
                            });
                            new MixPanel().increment(
                              "pdf_template_enable_disable_roles"
                            );
                            const newReceiversList = [...receiversList];
                            newReceiversList[index].isEnabled =
                              !newReceiversList[index].isEnabled;
                            setReceiversList(newReceiversList);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {showAccessCode.show && showAccessCode.roleTitle && (
                    <AccessCodeDetailsComponent
                      showAccessCode={showAccessCode}
                      setShowAccessCode={setShowAccessCode}
                      receiversList={receiversList}
                      index={index}
                      updateAccessCodeState={updateAccessCodeState}
                      setReceiversList={setReceiversList}
                    />
                  )}

                  {receiversList[index].accessCode && (
                    <div
                      className={` ${
                        showAccessCode.roleTitle ===
                          receiversList[index]?.roleTitle && showAccessCode.show
                          ? "hidden  "
                          : "block animate-slide-up"
                      } w-full bg-zinc-100 border border-gray-300 rounded-md py-2 px-4 flex justify-between items-center cursor-pointer`}
                    >
                      <p>
                        Access code for {role.title}:{" "}
                        <span
                          style={{
                            color: role.colour,
                          }}
                        >
                          <span className="font-semibold">
                            {receiversList[index].accessCode}
                          </span>
                        </span>
                      </p>
                      <div className="flex gap-2">
                        <MdDelete
                          size={18}
                          className="text-gray-500"
                          onClick={() => {
                            updateAccessCodeState("", "");
                            setReceiversList((prev: any) => {
                              const temp = [...prev];
                              temp[index].accessCode = "";
                              return temp;
                            });
                          }}
                        />
                        <MdOutlineKeyboardArrowDown
                          size={22}
                          className="text-primary "
                          onClick={() => {
                            updateAccessCodeState(
                              receiversList[index]?.email,
                              role.title
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
      )}
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default EmailInputWithRoles;
