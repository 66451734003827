import { useEffect, useState } from "react";
import "./index.css";
import axios from "../../utils/axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { validateEmail } from "../../utils/validateEmail";
import {
  BASE_URL,
  getDashboardUrl,
  getDashboardUrlV1,
} from "../../constants/constants";
import { FaArrowCircleRight } from "react-icons/fa";
import { useAppSelector } from "../../redux/store";
import { Helmet } from "react-helmet";
import { IMAGE_LINKS } from "../../constants/common";

const SignatureExpired = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let documentId = searchParams.get("documentId");
  let signerId = searchParams.get("signerId");
  let key = searchParams.get("key");
  const [signUpEmail, setSignUpEmail] = useState("");
  const [accountInfo, setAccountInfo] = useState<any>(null);

  useEffect(() => {
    if (!(key || (signerId && documentId))) {
      navigate("/login");
    }
  }, [documentId, key, navigate, signerId]);

  useEffect(() => {
    const getDocumentDetailsByKey = async () => {
      if (!key) {
        return;
      }
      try {
        const { data } = await axios.get(
          `/signingLog/signSuccessByKey?key=${key}`
        );

        if (data.error) {
          toast.error(data.message);
          return;
        }
        let allSigners = data.document.respondants;
        let signer = allSigners.find(
          (signer: any) =>
            signer._id === data.signerId || signer.signerId === data.signerId
        );
        if (signer?.email) {
          setSignUpEmail(signer?.email);
        }
      } catch (errror) {
        toast.error("Error fetching form");
      }
    };
    let getDocumentDetails = async () => {
      try {
        const { data } = await axios.get(
          `/getOneDocument?documentId=${documentId}&signerId=${signerId}`
        );
        if (data.error) {
          toast.error(data.message);
          return;
        }
        if (!data.error) {
          // toast.success(data.message);
        }

        let allSigners = data.document.respondants;
        let signer = allSigners.find(
          (signer: any) =>
            signer._id === signerId || signer.signerId === signerId
        );
        if (signer?.email) {
          setSignUpEmail(signer?.email);
        }
      } catch (errror) {
        toast.error("Error fetching form");
      }
    };
    signerId && documentId && getDocumentDetails();
    key && getDocumentDetailsByKey();
  }, [documentId, key, signerId]);

  useEffect(() => {
    const getBrandingInfo = async () => {
      try {
        let { data } = await axios.post(`${BASE_URL}/owner/get-branding-info`, {
          key,
        });
        if (data?.accountInfo) {
          setAccountInfo(data?.accountInfo);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getBrandingInfo();
  }, [key]);

  return (
    <div className="w-full h-screen bg-white flex justify-center items-center">
      <Helmet>
        <title translate="no">Signature Expired</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Signature Expired" />
        <meta name="description" content="Your document has expired." />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/signature-expired`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Signature Success - Boloforms`} />
        <meta
          property="og:description"
          content="Your document has been successfully signed. "
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/signature-expired"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="mx-auto max-w-[1200px]">
        <div className="w-full lg:py-0 px-3 sm:px-6 lg:px-12 flex flex-col justify-center items-center">
          <div className="w-full max-w-[1280px] flex flex-col items-center">
            <div className="text-center">
              <div className="success-animation">
                <svg
                  className="cross__svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="cross__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="cross__path cross__path--right"
                    fill="none"
                    d="M16,16 l20,20"
                  />
                  <path
                    className="cross__path cross__path--right"
                    fill="none"
                    d="M16,36 l20,-20"
                  />
                </svg>
              </div>
              <h1 className="text-2xl sm:text-5xl font-semibold leading-[40px] sm:leading-[60px]">
                Thank you for attempting to sign!
              </h1>
              <p className="mt-4 sm:text-xl">
                Unfortunately, the document has <b>expired</b>, and for
                inquiries or assistance, please contact the relevant party.
              </p>
            </div>
            <div className="mt-12 text-center flex flex-col justify-center items-center">
              <p className="text-gray-900 text-center mt-4">
                Over <span className="font-semibold">10,000</span> businesses
                love{" "}
                {whiteLabelInfo?.settings?.organization?.name || "BoloForms"}{" "}
                Signature
              </p>
              <div className="flex w-full px-2 mt-4">
                <input
                  type="text"
                  onChange={(e) => {
                    setSignUpEmail(e.target.value);
                  }}
                  value={signUpEmail}
                  placeholder="Enter your Email"
                  className="w-full text-xs sm:text-sm px-4 sm:px-5 border border-primary outline-none rounded-l-md h-[38px] sm:h-[45px]"
                  style={{
                    border: accountInfo?.theme
                      ? `1px solid ${accountInfo?.theme}`
                      : "",
                  }}
                />
                <button
                  className="max-w-[30vw] bg-primary text-xs sm:text-sm h-[38px] sm:h-[45px] font-semibold text-white flex items-center justify-center px-4 sm:px-5 hover:opacity-90 rounded-r-md"
                  style={{
                    color: accountInfo?.themeText,
                    background: accountInfo?.theme,
                  }}
                  onClick={() => {
                    if (validateEmail(signUpEmail)) {
                      window.open(
                        `${getDashboardUrlV1()}/register?from=FORM_FINAL_SCREEN&ownerEmail=${signUpEmail}`,
                        "_blank"
                      );
                    } else {
                      toast.error("Invalid Email");
                    }
                  }}
                >
                  SignUp
                  <FaArrowCircleRight className="ml-3 hidden sm:block text-xl" />
                </button>
              </div>
              <p className="text-xs text-gray-500 mt-2">
                Start your free trial today. No credit card required.
              </p>
            </div>
          </div>
        </div>
        <ToastContainer
          transition={Slide}
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
        />
      </div>
    </div>
  );
};

export default SignatureExpired;
