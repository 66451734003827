import React, { useState } from "react";
import BoloModal from "../Common/Modal/BoloModal";
import { BiSolidUpArrowCircle } from "react-icons/bi";
import { TailSpin } from "react-loader-spinner";
import { getWhiteLabelInfoLocal } from "../../utils";
import { COLORS } from "../../constants/common";
import { toast } from "react-toastify";
import { generalizedFileUploadViaSignedUrlPrivate } from "../../utils/fileUpload";
import { S3PATHS } from "../../constants/s3Folders";
import { fileUploadType } from "../../pdfme/ui/src/components/Designer/PdfTemplateSidebar/AttachmentsView";
import { GlobalHelpersType } from "../../pdfme/ui/src/components/Designer";
import { v4 } from "uuid";
interface UploadAttachmentTypes {
  setShowUploadfileModal?: React.Dispatch<React.SetStateAction<boolean>>;
  attachmentFiles: fileUploadType[];
  pageNo?: number;
  setShow?: (value: boolean) => void;
  isDefault?: boolean;
  globalHelpers: GlobalHelpersType;
}

const UploadAttachment = ({
  setShowUploadfileModal,
  attachmentFiles,
  setShow,
  pageNo,
  isDefault = true,
  globalHelpers,
}: UploadAttachmentTypes) => {
  const handleFileInputChange = async (
    e: any,
    type: "input" | "drop" = "input"
  ) => {
    setLoaders((prev) => ({ ...prev, uploading: true }));
    const fileCountLimit = 5 - (attachmentFiles?.length ?? 0);

    let files_: File[] | null = null;
    if (type == "drop") {
      files_ = Array.from(e.dataTransfer.files);
    } else {
      files_ = Array.from(e.target.files);
    }
    if (files_ != null) {
      if (files_.length > fileCountLimit) {
        setLoaders((prev) => ({ ...prev, uploading: false }));

        toast.error(
          "Only 5 attachments are allowed for a particular document!"
        );
        return;
      }
      for (const file of files_) {
        if (file?.size > 5 * 1024 * 1024) {
          setLoaders((prev) => ({ ...prev, uploading: false }));

          toast.error("File should be no larger than 5mb");
          return;
        }
      }
      let fileUploads: fileUploadType[] = [];
      for (const file of files_) {
        let link = await generalizedFileUploadViaSignedUrlPrivate({
          file: file,
          folderName: S3PATHS.DOCUMENT_ATTACHMENTS,
        });
        fileUploads.push({
          fileId: v4(),
          mimeType: file?.type,
          fileName: file?.name,
          fileUrl: link,
          dateAdded: new Date(),
          fileSize: file?.size,
          pageNo: pageNo,
        });
      }
      let newAttachmentFiles = [...attachmentFiles, ...fileUploads];
      globalHelpers.updateDocumentAttachments(newAttachmentFiles);
      setLoaders((prev) => ({ ...prev, uploading: false }));
      if (isDefault && setShowUploadfileModal) {
        setShowUploadfileModal(false);
      } else {
        setShow && setShow(false);
      }
    }
  };
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const [loaders, setLoaders] = useState({
    drive: false,
    uploading: false,
    confirm: false,
  });

  return (
    <>
      <BoloModal
        setShow={(value) => {
          if (isDefault) {
            console.log("valeue1", value);
            setShowUploadfileModal && setShowUploadfileModal(value);
          } else {
            console.log("valeue2", value);
            setShow && setShow(value);
          }
        }}
        content={
          <>
            <div className="text-base md:text-lg mb-4 font-semibold">
              Upload File
            </div>
            <div className=" mb-4 text-xs text-gray-500">
              Our per-document upload limit is 5 files (5 MB each).
            </div>
            <div>
              <label
                htmlFor="dropzone-file"
                className={`flex flex-col items-center justify-center p-8 border-2 border-gray-300 hover:border-gray-500 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-full text-gray-600 transition-all ease-in-out duration-150 `}
                onDrop={(e) => {
                  // handleDrop(e);
                  handleFileInputChange(e, "drop");
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="">
                  {!loaders.uploading ? (
                    <div className="gap-8 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                      <div className="p-2 md:p-4 bg-gray-200 rounded-full">
                        <BiSolidUpArrowCircle
                          size={50}
                          className="text-gray-400"
                        />
                      </div>
                      <div className="m-0 flex flex-col items-center text-sm md:text-base">
                        <p>
                          <span className="text-primary mr-1 font-medium">
                            Browse
                          </span>{" "}
                          or drag files here
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="gap-8 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                        <div className="p-2 md:p-4  rounded-full">
                          <TailSpin
                            height="60"
                            width="60"
                            color={
                              whiteLabelInfo?.settings?.organization?.themeHex
                                ?.primary || COLORS.primary
                            }
                          />
                        </div>
                        <div className="m-0 flex flex-col items-center text-sm md:text-base">
                          <p>Uploading your files.</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </label>
              <input
                id="dropzone-file"
                type="file"
                className="appearance-none hidden disabled:opacity-60"
                onClick={(e) => {}}
                onChange={handleFileInputChange}
                multiple
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default UploadAttachment;
