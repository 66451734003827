/**
 * How does the extension page works?
 * - User installs extension and is redirected to Dashboard
 * - User logins and extension picks up the user auth details
 * - User selects pdf from gmail, iframe is opened with the extension
 * - User details are imported from the extension with help of postMessage
 * - User selects the workspace if multiple workspaces are present
 * - PDF import is called with the selected workspace
 * - User google auth is checked and if not present, user is asked to login
 * - and the process continues to PDF editor
 * - In Editor user will be provided with one additional option to draft the PDF in email reply
 *
 * Defined steps
 * - 0: Initial step
 * - 1: Select workspace
 * - 2: Google auth
 * - 3: Final process
 */

import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { v4 } from "uuid";
import { Slide, ToastContainer, toast } from "react-toastify";
import { FcGoogle } from "react-icons/fc";

import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";

import { ENV, BASE_URL, WEBSITE_ASSETS_URL } from "../../constants/constants";
import axios from "../../utils/axios";
import { getRandomColorV1 } from "../../utils/uiUtils";

import { BoloButton } from "../../components/Common/Button/BoloButton";
import BoloLoader from "../../components/Common/Loader/BoloLoader";

const DASHBOARD_URL = {
  DEV: "http://localhost:3000",
  STAGE: "https://dash-signature-staging.boloforms.com",
  PROD: "https://signature.boloforms.com",
}[ENV];

const EMBED_URL = {
  DEV: "http://localhost:3000",
  STAGE: "https://dash-signature-staging.boloforms.com",
  PROD: "https://embed.boloforms.com",
}[ENV];

/**
 * Function to get the usable workspaces for the owner
 */
const getUsableWorkSpaces = (owner: any) => {
  const usableWorkSpaces = owner?.workSpaces?.filter((workspace: any) => {
    if (workspace?.email === owner?.email) return true;

    const collaborators = workspace?.collaborators || [];

    return (
      collaborators.find(
        (collaborator: any) =>
          collaborator?.collaboratorId?.email === owner?.email
      )?.role !== "readonly"
    );
  });

  return usableWorkSpaces;
};

/**
 * Function to get and check the local workspace
 */
const getAndCheckLocalWorkSpace = (workSpaces: any[] = []) => {
  const localWorkSpaceId = localStorage.getItem("workspaceid");

  const isPresent = workSpaces.find(
    (workspace) => workspace.workSpaceId === localWorkSpaceId
  );

  if (isPresent) return localWorkSpaceId;

  return workSpaces?.[0]?.workSpaceId;
};

/**
 * Function to close the iframe modal
 * - check if the current url is dashboard/pdfs
 * - if yes, send a message to the top window to close the iframe
 */
const handleCloseIframeModal = () => {
  const closeModal = setInterval(() => {
    if (
      window.location.pathname.includes("/dashboard/pdfs") ||
      window.location.pathname.includes("/embed/login")
    ) {
      window?.top?.postMessage({ action: "closeModal" }, "*");
      clearInterval(closeModal);
      return;
    }
  }, 500);

  return closeModal;
};

const WorkSpaceSelector: React.FC<{
  workSpaces: any[];
  workSpace: string;
  setWorkSpace: any;
  nextStep?: any;
}> = ({ workSpaces, workSpace, setWorkSpace, nextStep }) => {
  return (
    <div className="w-80">
      <label className="font-medium">Select a Workspace</label>
      <select
        className="w-full p-2 border border-gray-300 rounded-md outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
        value={workSpace}
        onChange={(e) => {
          const value = e.target.value;
          setWorkSpace(value);
          localStorage.setItem("workspaceid", value);
        }}
      >
        <option value="">Select a workspace</option>
        {workSpaces.map((item) => (
          <option key={item._id} value={item.workSpaceId}>
            {item.name}
          </option>
        ))}
      </select>

      <BoloButton
        variant="primary"
        orientation="center"
        className="mt-2 ml-auto shadow-md"
        type="button"
        onClick={nextStep}
        disabled={!workSpace}
      >
        Continue
      </BoloButton>
    </div>
  );
};

const ImportPDF = () => {
  const navigate = useNavigate();

  // Getting initial data from local storage
  // const localOwner = localStorage.getItem("owner");
  // const usableWorkSpaces = getUsableWorkSpaces(JSON.parse(localOwner || "{}"));
  // const initialWorkSpaceId = getAndCheckLocalWorkSpace(usableWorkSpaces);

  // defined states
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(true);
  const [owner, setOwner] = useState<any>({});
  const [selectedWorkSpace, setSelectedWorkSpace] = useState<string>("");
  const [validWorkSpaces, setValidWorkSpaces] = useState<any[]>([]);
  const [searchParams] = useSearchParams();

  // Function to handle the message from the parent window
  const handleGetAuthMessage = (event: any) => {
    localStorage.setItem("owner", event.data.auth.owner);
    localStorage.setItem("x-auth-token", event.data.auth["x-auth-token"]);
    // localStorage.setItem("ip_data", event.data.auth.ip_data);

    const parsedOwner = JSON.parse(event.data.auth.owner || "{}");
    const usableWorkSpaces = getUsableWorkSpaces(parsedOwner);
    const initialWorkSpaceId = getAndCheckLocalWorkSpace(usableWorkSpaces);
    localStorage.setItem("workspaceid", initialWorkSpaceId);
    const canMoveToNextStep =
      usableWorkSpaces?.length === 1 && initialWorkSpaceId;

    setOwner(parsedOwner);
    setValidWorkSpaces(usableWorkSpaces);
    setSelectedWorkSpace(initialWorkSpaceId);
    setStep(canMoveToNextStep ? 3 : 1);
    if (canMoveToNextStep) {
      setViewLoading(true);
    }
  };

  // Get the owner details from the parent window
  useEffect(() => {
    const handleMessage = async (event: any) => {
      if (event.data.action === "setAuth") {
        handleGetAuthMessage(event);

        setViewLoading(false);
        window.removeEventListener("message", handleMessage);
      }
    };

    // set timeout if owner is not found in 5 seconds and show the login button
    setTimeout(() => {
      if (!owner?._id) {
        setViewLoading(false);
      }
    }, 5000);

    // Add the event listener for messages
    window.addEventListener("message", handleMessage);

    // Send the initial message to the parent
    window.parent.postMessage({ action: "getAuth" }, "*");

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  /**
   * Function to handle the login process
   * - open dashboard in a new tab
   * - check for the auth details (picked up by the extension) in the parent window every second
   * - set the auth details in the local storage
   * @returns void
   */
  const handleGetAuth = async () => {
    try {
      setViewLoading(true);

      window.open(DASHBOARD_URL, "_blank");

      let counter = 0;

      const checkAuth = setInterval(() => {
        // console.log("Checking for auth");
        window.parent.postMessage({ action: "getAuth" }, "*");
        counter += 1;

        if (counter > 60) {
          clearInterval(checkAuth);
          setViewLoading(false);
        }
      }, 1000);

      window.addEventListener("message", async (event) => {
        if (event.data.action === "setAuth") {
          handleGetAuthMessage(event);

          if (event.data.auth.owner) {
            setViewLoading(false);
            clearInterval(checkAuth);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Function to handle the google permission auth
   * - open the google auth url in a new popup window
   * - check if window is closed in the parent window every second
   * - if the window is closed, clear the interval
   * - if the window url contains the success url, close the window and move to the next step
   */
  const handleGooglePermissionAuth = async () => {
    setLoading(true);
    const redirectUrl = `${EMBED_URL}/extension/import-pdf`;

    const googleAuthUrl = `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=chrome-extension&redirectUrl=${redirectUrl}&from=CHROME-EXTENSION`;

    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const authWindow = window.open(
      googleAuthUrl,
      "GoogleAuthPopup",
      `width=${width}, height=${height}, top=${top}, left=${left}`
    );

    if (authWindow) {
      const pollTimer = window.setInterval(() => {
        try {
          if (authWindow.closed) {
            window.clearInterval(pollTimer);
            setLoading(false);
          } else if (authWindow.location.href.includes(EMBED_URL)) {
            // console.log("Google Auth Success");
            setLoading(false);
            setStep(3);
            authWindow.close();
          }
        } catch (e) {
          console.error(e);
        }
      }, 1000);
    }
  };

  /**
   * useEffect to run final process after workspace is selected
   */
  useEffect(() => {
    if (step !== 3) return;

    handleCloseIframeModal();

    setViewLoading(true);

    const asyncEffect = async () => {
      try {
        // get ip address
        const { ip } = getIpAddressLocal();

        const body = {
          from: "CHROME-EXTENSION",
          docId: v4(),
          ip: ip || "",
          createdBy: owner?._id,
          authorEmail: owner?.email,
          gmailFromChromeExtension: searchParams.get("email"),
          gmailMessageId: searchParams.get("gmail_message_uuid"),
          gmailDocumentAttachmentId: searchParams.get("attid"),
          gmailDocumentAttachmentName: searchParams.get("gmail_filename"),
          responseReceivers: JSON.stringify([]),
          receiverEmails: JSON.stringify([
            {
              email: owner?.email || "",
              name: owner?.name || "",
              message: "",
              colour: getRandomColorV1(),
              signingOrderNo: 1,
              accessCode: "",
            },
          ]),
          mailData: JSON.stringify({ subject: "", body: "" }),
          folderId: "",
          isSigningOrderData: "0",
        };

        const response = await axios.post(`/saveasdraft`, body);

        if (response?.data?.message) {
          toast.success(response?.data?.message);
          navigate(
            `/create/pdf-signature?documentId=${response?.data?.documentId}&isDraft=true&from=CHROME-EXTENSION`
          );
          return;
        }

        setViewLoading(false);

        if (response?.data?.reason === "alreadyLinked") {
          setStep(1);
          toast.error(
            response?.data?.error ||
              "Your BoloSign account is already linked with other google account."
          );
          return;
        }

        setStep(2);

        // check if the error is due to google auth
        if (response?.data?.reason === "noIntegrationFound") {
          console.log("Google Auth Error");
          handleGooglePermissionAuth();
          return;
        }

        // check if the error is due to google permissions
        if (response?.data?.reason === "insufficientPermissions") {
          console.log("Insufficient Permissions");
          handleGooglePermissionAuth();
          return;
        }

        toast.error(response?.data?.error || "Something went wrong");
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    };

    asyncEffect();
  }, [step]);

  return (
    <>
      <div className="w-full h-screen grid place-items-center">
        <div className="">
          <div>
            <img
              src={`${WEBSITE_ASSETS_URL}/BoloForms.png`}
              className="h-20 mx-auto"
            />
          </div>

          <div className="min-h-[100px]">
            {viewLoading ? (
              <BoloLoader />
            ) : !owner?._id ? (
              <BoloButton className="w-full" onClick={handleGetAuth}>
                Login to continue!
              </BoloButton>
            ) : step === 1 ? (
              <WorkSpaceSelector
                workSpaces={validWorkSpaces}
                workSpace={selectedWorkSpace}
                setWorkSpace={setSelectedWorkSpace}
                nextStep={() => {
                  setViewLoading(true);
                  setStep(3);
                }}
              />
            ) : (
              <BoloButton
                variant="secondary"
                orientation="center"
                className="w-full shadow-md hover:bg-secondary !text-black"
                type="button"
                onClick={handleGooglePermissionAuth}
              >
                {loading ? (
                  <BoloLoader width={24} height={24} strokeWidth={4} />
                ) : (
                  <FcGoogle className="text-2xl" />
                )}
                <span className="ml-2">Give Permissions</span>
              </BoloButton>
            )}
          </div>
        </div>
      </div>

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </>
  );
};

export default ImportPDF;
