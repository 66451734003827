import React, { useEffect, useRef } from "react";
import { getInitials } from "../../utils/uiUtils";
import { MdCheck, MdClose } from "react-icons/md";

export function sortAndGroupBySigningOrderNo(arr: any) {
  arr.sort(function (a: any, b: any) {
    return a.signingOrderNo - b.signingOrderNo;
  });

  var result = [];
  var currentGroup = [];

  for (var i = 0; i < arr.length; i++) {
    if (i === 0 || arr[i].signingOrderNo === arr[i - 1].signingOrderNo) {
      currentGroup.push(arr[i]);
    } else {
      result.push(currentGroup);
      currentGroup = [arr[i]];
    }
  }

  result.push(currentGroup);

  return result;
}

type Props = {
  recipientList: any[];
  setIsSigningOrderDiagram: any;
  isSigningOrderDiagram: Boolean;
  currDocument?: any;
};

const SigningOrderDiagram = ({
  recipientList,
  setIsSigningOrderDiagram,
  isSigningOrderDiagram,
  currDocument,
}: Props) => {
  const sortedGroup = sortAndGroupBySigningOrderNo(recipientList);
  const signingOrderDiagramRef = useRef(null);
  const owner = JSON.parse(localStorage.getItem("owner") || "");

  const handleOutsideClick = (e: any) => {
    if (signingOrderDiagramRef?.current !== null) {
      // @ts-ignore
      if (signingOrderDiagramRef?.current?.contains(e.target)) return;
    }
    setIsSigningOrderDiagram(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  console.log("curr Docu", currDocument);

  return (
    <div
      ref={signingOrderDiagramRef}
      className="z-30 fixed top-20 left-[50%] w-[550px] max-w-[95vw] translate-x-[-50%] bg-white shadow-lg text-sm"
    >
      <div className="relative px-3 py-3">
        <p className="font-semibold">Signing Order Diagram</p>
        <MdClose
          onClick={() => {
            setIsSigningOrderDiagram(false);
          }}
          className="absolute top-3 right-3 cursor-pointer"
          size={20}
        />
      </div>
      <div className="border-b"></div>
      <div
        className=" max-h-[200px] overflow-y-auto"
        id="mini-pdf-custom-scroll"
      >
        <div className="px-3 py-2 w-full h-[50px] flex justify-between items-center border-b">
          <div className="text-gray-600">Sent By</div>
          <div className="w-[60%] flex gap-2 items-center justify-center mr-4">
            <div
              className={`rounded-full ${
                !!currDocument?.status ? "bg-green-300" : "bg-gray-300"
              } p-2 text-sm h-9 w-9 flex items-center justify-center`}
            >
              {getInitials(owner?.name)}
            </div>
          </div>
        </div>

        {sortedGroup?.map((group: any, index: any) => {
          return (
            <div className="px-3 py-2 w-full h-[50px] flex justify-between items-center border-b">
              <div className="text-gray-600">{group?.[0]?.signingOrderNo}</div>

              <div className="w-[60%] flex gap-2 items-center justify-center mr-4">
                {group?.map((item: any, idx: any) => {
                  return (
                    <div
                      className={`rounded-full ${
                        item?.hasDeclined
                          ? "bg-red-300"
                          : item?.status == "SIGNED"
                          ? "bg-green-300"
                          : "bg-gray-300"
                      } p-2 text-sm h-9 w-9 flex items-center justify-center`}
                    >
                      {getInitials(item?.name)}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div className="px-3 py-2 w-full h-[50px] flex justify-between items-center border-b">
          <div className="text-gray-600">Completed</div>
          <div className="w-[60%] flex gap-2 items-center justify-center mr-4">
            <div
              className={`rounded-full ${
                currDocument?.status == "DECLINED"
                  ? "bg-red-300"
                  : currDocument?.status == "SIGNED"
                  ? "bg-green-300"
                  : "bg-gray-300"
              } p-2 text-sm h-9 w-9 flex items-center justify-center`}
            >
              {currDocument?.status == "DECLINED" ? (
                <MdClose className="text-sm" />
              ) : (
                <MdCheck className="text-sm" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 py-2 flex justify-between items-center">
        <div className="flex gap-2 items-center text-xs">
          <div className="flex items-center gap-2">
            <span className="h-2 w-2 rounded-full bg-red-300"></span> Declined
          </div>
          <div className="flex items-center gap-2">
            <span className="h-2 w-2 rounded-full bg-green-300"></span> Signed
          </div>
          <div className="flex items-center gap-2">
            <span className="h-2 w-2 rounded-full bg-gray-300"></span> Waiting
          </div>
        </div>
        <button
          className="text-primary bg-secondary px-4 py-1.5 rounded-md text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
          onClick={() => setIsSigningOrderDiagram(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SigningOrderDiagram;
