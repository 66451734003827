import { GrClose } from "react-icons/gr";
import { CgTemplate } from "react-icons/cg";
import { VscEmptyWindow } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
const SendDocModal = ({ setModalType, selectedContact }: any) => {
  interface CardViewProps {
    text?: string;
    icon?: any;
    desc?: string;
    onClick?: () => void;
  }

  const Card = ({ icon, text, desc, onClick }: CardViewProps) => {
    return (
      <div
        onClick={onClick}
        className="border p-2 md:p-4  rounded-xl cursor-pointer hover:scale-100 md:hover:scale-110 transition-all flex justify-center items-center flex-col w-full md:w-[160px] "
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
        }}
      >
        <div className="flex justify-center items-center  bg-gray-100 md:mx-auto rounded-full w-[60px] md:w-[100px] aspect-square text-2xl md:text-4xl text-gray-600">
          {icon}
        </div>
        <h1 className="font-semibold text-xs md:text-sm mt-1">{text}</h1>
        <span className="text-[9px] md:text-xs text-gray-500 text-center">
          {desc}
        </span>
      </div>
    );
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="flex fixed top-0 left-0 z-[51] w-full h-full p-2 md:p-8 bg-[#00000091] justify-center items-start overflow-y-auto">
        <div className="  md:rounded-md rounded-sm  max-w-xl w-full min-h-[464px] flex justify-start items-center flex-col bg-white">
          <div className="flex justify-between items-center  border-b py-2 md:py-4 md:px-8 px-4 bg-gray-50 md:rounded-t-md rounded-t-sm w-full">
            <h2 className=" text-xl md:text-xl font-medium tracking-tight">
              Send Document
            </h2>
            <GrClose
              className="text-xl hover:cursor-pointer"
              onClick={() => {
                setModalType("");
              }}
            />
          </div>
          <div className="mt-2 mb-4 md:px-8 px-4  h-full flex justify-center items-center flex-col flex-1 w-full">
            <div className="mb-2 md:mb-8 text-center w-full">
              <h1 className="text-[16px] md:text-[24px] font-bold">
                Select Your Document For Sending
              </h1>
              <p className="text-xs md:text-sm text-gray-600 mt-1">
                Send document to <strong>{selectedContact?.email}</strong>
              </p>
            </div>
            <div className="flex gap-2 md:gap-4 flex-col md:flex-row mt-4 pb-4 md:pb-8 w-full items-center justify-center">
              <Card
                text="Create"
                desc="New Document"
                icon={<VscEmptyWindow />}
                onClick={() => {
                  console.log("Create New Document");
                  const name =
                    selectedContact?.firstName.trim() +
                    " " +
                    selectedContact?.lastName.trim();
                  if (name.trim().length > 0) {
                    navigate(
                      `/create/pdf?email=${
                        selectedContact?.email
                      }&name=${name.trim()}`
                    );
                  } else {
                    navigate(`/create/pdf?email=${selectedContact?.email}`);
                  }
                }}
              />
              <Card
                text="My Templates"
                desc="Select Template"
                icon={<CgTemplate />}
                onClick={() => {
                  console.log("Select From Templates");
                  const name =
                    selectedContact?.firstName.trim() +
                    " " +
                    selectedContact?.lastName.trim();
                  if (name.trim().length > 0) {
                    navigate(
                      `/dashboard/templates?pageNo_=1&email=${
                        selectedContact?.email
                      }&name=${name.trim()}`
                    );
                  } else {
                    navigate(
                      `/dashboard/templates?page=1&email=${selectedContact?.email}`
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendDocModal;
