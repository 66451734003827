// export const ENV = "DEV";
// export const ENV = "STAGE";
export const ENV = "PROD";

const URLS = {
  DEV: {
    BASE: "http://localhost:8080/api/v1/signature",
    PAYMENT: "http://localhost:8080/payments",
    INTERNAL_TOOLS: "http://localhost:8080/internal-tools",
    LAMBDA_FILE_UPLOAD_URL:
      "https://zth4a4ygfcw3kxkvabnb6daqu40fmoht.lambda-url.ap-south-1.on.aws/",
    LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PRIVATE:
      "https://zth4a4ygfcw3kxkvabnb6daqu40fmoht.lambda-url.ap-south-1.on.aws/",
    LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PUBLIC:
      "https://zo4agmdxdqpqzffq3xxdtuxk5u0zwleg.lambda-url.ap-south-1.on.aws/",
    STRIPE_CONNECT: "http://localhost:8080/stripe/connect",
    FACEBOOK_PIXEL_ID: "406551895324621",
    TIKTOK_PIXEL_ID: "TEST_PIXEL_ID",
    REDDIT_PIXEL_ID: "TEST_PIXEL_ID",
  },
  STAGE: {
    BASE: "https://signature-backend-staging.boloforms.com/api/v1/signature",
    PAYMENT: "https://signature-backend-staging.boloforms.com/payments",
    INTERNAL_TOOLS:
      "https://signature-backend-staging.boloforms.com/internal-tools",
    LAMBDA_FILE_UPLOAD_URL:
      "https://booo2symxcesmavjbvmbgz3rhy0rpovv.lambda-url.ap-south-1.on.aws/",
    LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PRIVATE:
      "https://booo2symxcesmavjbvmbgz3rhy0rpovv.lambda-url.ap-south-1.on.aws/",
    LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PUBLIC:
      "https://azuclpij3egyse76ujrdhzjyey0xsiiz.lambda-url.ap-south-1.on.aws/",
    STRIPE_CONNECT:
      "https://signature-backend-staging.boloforms.com/stripe/connect",
    FACEBOOK_PIXEL_ID: "406551895324621",
    TIKTOK_PIXEL_ID: "CQ2DJJBC77U1H6D7QVI0",
    REDDIT_PIXEL_ID: "TEST_PIXEL_ID",
  },
  PROD: {
    BASE: "https://signature-backend.boloforms.com/api/v1/signature",
    PAYMENT: "https://signature-backend.boloforms.com/payments",
    INTERNAL_TOOLS: "https://signature-backend.boloforms.com/internal-tools",
    LAMBDA_FILE_UPLOAD_URL:
      "https://booo2symxcesmavjbvmbgz3rhy0rpovv.lambda-url.ap-south-1.on.aws/",
    LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PRIVATE:
      "https://booo2symxcesmavjbvmbgz3rhy0rpovv.lambda-url.ap-south-1.on.aws/",
    LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PUBLIC:
      "https://azuclpij3egyse76ujrdhzjyey0xsiiz.lambda-url.ap-south-1.on.aws/",
    STRIPE_CONNECT: "https://signature-backend.boloforms.com/stripe/connect",
    FACEBOOK_PIXEL_ID: "1567698137102969",
    TIKTOK_PIXEL_ID: "CQ3EH2RC77U6L0ALUQQG",
    REDDIT_PIXEL_ID: "a2_fayue0mf4i9g",
  },
};

const MIXPANEL_TOKENS = {
  DEV: "e99a6ca61ede26fcc37cab59a7234a4b",
  STAGE: "e99a6ca61ede26fcc37cab59a7234a4b",
  PROD: "524520788dd62897bad4e299e4e4d741",
};

export const SKIP_EMBED_CHECK_DOMAINS = {
  DEV: "localhost",
  STAGE: "boloforms.com",
  PROD: "boloforms.com",
};

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCaiH6ahboH2jhaWaMU4dGFVgn_YmdCsiI",
  authDomain: "boloforms-signature.firebaseapp.com",
  projectId: "boloforms-signature",
  storageBucket: "boloforms-signature.appspot.com",
  messagingSenderId: "980217179187",
  appId: "1:980217179187:web:6014724409a35d13364746",
  measurementId: "G-KPY51MSHG9",
};

export const GOOGLE_CLIENT_ID_SIGN_IN =
  "980217179187-at05i14hqiqundjtjfj4233vsn79h994.apps.googleusercontent.com";
export const BASE_URL = URLS[ENV].BASE;
export const PAYMENTS_BASE_URL = URLS[ENV].PAYMENT;
export const INTERNAL_TOOLS_URL = URLS[ENV].INTERNAL_TOOLS;
export const STRIPE_CONNECT_URL = URLS[ENV].STRIPE_CONNECT;

const UTIL_ROUTES_ALL = {
  DEV: { IP: `https://api.ipify.org/?format=json` },
  STAGE: { IP: `${BASE_URL}/utils/ip` },
  PROD: { IP: `${BASE_URL}/utils/ip` },
};
export const UTIL_ROUTES = UTIL_ROUTES_ALL[ENV];

export const MIXPANEL_TOKEN = MIXPANEL_TOKENS[ENV];
export const SKIP_EMBED_CHECK_DOMAIN = SKIP_EMBED_CHECK_DOMAINS[ENV];
export const FACEBOOK_PIXEL_ID = URLS[ENV].FACEBOOK_PIXEL_ID;
export const TIKTOK_PIXEL_ID = URLS[ENV].TIKTOK_PIXEL_ID;
export const REDDIT_PIXEL_ID = URLS[ENV].REDDIT_PIXEL_ID;
// export const BASE_URL = "http://localhost:8080/api/v1/signature";
// export const BASE_URL =
//   "https://signature-backend.boloforms.com/api/v1/signature";
// export const BASE_URL = "https://9309-122-162-216-52.ngrok.io/api";
// export const BASE_URL =
//   "https://signature-backend-staging.boloforms.com/api/v1/signature";

// export const PAYMENTS_BASE_URL = "http://localhost:8080/payments";
// export const PAYMENTS_BASE_URL =
//   "https://signature-backend.boloforms.com/payments";
// export const PAYMENTS_BASE_URL =
//   "https://signature-backend-staging.boloforms.com/payments";

/*
approval-file-upload-prod
https://ohgtikyi7br6kdzwivr7tdjmoy0zwyof.lambda-url.ap-south-1.on.aws

signature-file-upload-prod
!--------------------------------------------------
!Note : this is not used
!--------------------------------------------------
https://booo2symxcesmavjbvmbgz3rhy0rpovv.lambda-url.ap-south-1.on.aws/

generalized-signature-dashboard-uploads
https://azuclpij3egyse76ujrdhzjyey0xsiiz.lambda-url.ap-south-1.on.aws/
*/

// !--------------------------------------------------
// !Note : this is not used
// !--------------------------------------------------
export const LAMBDA_FILE_UPLOAD_URL = URLS[ENV].LAMBDA_FILE_UPLOAD_URL;
// "https://booo2symxcesmavjbvmbgz3rhy0rpovv.lambda-url.ap-south-1.on.aws/";

export const LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PUBLIC =
  URLS[ENV].LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PUBLIC;
// "https://azuclpij3egyse76ujrdhzjyey0xsiiz.lambda-url.ap-south-1.on.aws/";

export const LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PRIVATE =
  URLS[ENV].LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PRIVATE;
// "https://booo2symxcesmavjbvmbgz3rhy0rpovv.lambda-url.ap-south-1.on.aws/";

export const LAMBDA_GET_S3_SIGNED_URL =
  "https://agovggcghnvx7rtm5ndhl4vqdm0hirpq.lambda-url.ap-south-1.on.aws/";

export const WEBSITE_ASSETS_URL =
  "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets";

export function getDashboardUrlV1() {
  /*
   considerations made
   1. In prod/stage say signature.boloforms.com
   2. Any get dashboard url is supposed to return https://signature.boloforms.com
   3. There for the below switch will work
   4. It is only for local that we need to make an exception
   5. For any whitelabel the window.location.pathname will automatically work without having to get whitelabel info
  */

  switch (ENV) {
    case "DEV":
      return `http://localhost:3000`;
    case "STAGE":
      return `https://${window.location.hostname}`;
    case "PROD":
      return `https://${window.location.hostname}`;
    default:
      return `https://${window.location.hostname}`;
  }
}

export function getDashboardUrl() {
  switch (BASE_URL) {
    case "http://localhost:8080/api":
    case "https://approval-staging.boloforms.com/api":
      return "http://localhost:3000";
    case "https://approval.boloforms.com/api":
      return "https://approval-dashboard.boloforms.com";
    case "https://signature-backend.boloforms.com/api/v1/signature":
      return "https://signature.boloforms.com";
    case "https://signature-backend-staging.boloforms.com/api/v1/signature":
      return "https://dash-signature-staging.boloforms.com";
    default:
      return "http://localhost:3000";
  }
}

export function getFormViewerUrl() {
  let info = JSON.parse(localStorage.getItem("whiteLabelOwner") || "{}");
  let subDomain = `${info?.settings?.organization?.links?.fill || "fill"}`;
  let domain = `${info?.settings?.organization?.domain || "boloforms.com"}`;

  const viewerUrl = `https://${subDomain}.${domain}/signature`;

  switch (ENV) {
    case "DEV":
      return `http://localhost:3003/signature`;
    case "STAGE":
      return viewerUrl;
    case "PROD":
      return viewerUrl;
    default:
      return viewerUrl;
  }
}

// not used
export function getFormViewerUrlOld() {
  switch (BASE_URL) {
    case "http://localhost:8080/api":
    case "https://approval-staging.boloforms.com/api":
      return "http://localhost:3003";
    case "https://approval.boloforms.com/api":
      return "https://fill.boloforms.com";
    case "https://signature-backend.boloforms.com/api/v1/signature":
      return "https://fill.boloforms.com/signature";
    default:
      // return "http://localhost:3003";
      return "http://localhost:3003/signature";
  }
}

export function getBulkApproveRejectLambdaUrl() {
  switch (BASE_URL) {
    case "http://localhost:8080/api":
    case "https://approval-staging.boloforms.com/api":
      return "https://axynddknf4bwibtcik2euka7te0rfsfj.lambda-url.ap-south-1.on.aws/";
    case "https://approval.boloforms.com/api":
      return "https://7ykmyz224d3i7bloh7nvducteq0bcwyq.lambda-url.ap-south-1.on.aws/";
    default:
      return "https://axynddknf4bwibtcik2euka7te0rfsfj.lambda-url.ap-south-1.on.aws/";
  }
}

export function getApproveRejectLambdaUrl() {
  switch (BASE_URL) {
    case "http://localhost:8080/api":
    case "https://approval-staging.boloforms.com/api":
      return "https://cc4aaz5nvwpfs426spt5tklkoe0fpjzm.lambda-url.ap-south-1.on.aws/";
    case "https://approval.boloforms.com/api":
      return "https://7dwo3vlp77ltkqaq23f4gw7gum0czcnk.lambda-url.ap-south-1.on.aws/";
    default:
      return "https://cc4aaz5nvwpfs426spt5tklkoe0fpjzm.lambda-url.ap-south-1.on.aws/";
  }
}

export function getMainWebsiteBackendUrl() {
  switch (BASE_URL) {
    case "http://localhost:8080/api":
    case "https://approval-staging.boloforms.com/api":
      return "https://main-website-backend.boloforms.com/api/get";
    case "https://approval.boloforms.com/api":
      return "https://main-website-backend.boloforms.com/api/get";
    default:
      return "https://main-website-backend.boloforms.com/api/get";
  }
}

// export const SMS_CREDIT_PAYMENT_PRICE_ID = "price_1PjDCWSAPgaOwdfl0r49XE8b"; // dev, local stripe dashboard.. will need ngrok to run locally
export const SMS_CREDIT_PAYMENT_PRICE_ID = "price_1Pjap6LiGbRu8waGMLhteSkc";
