//@ts-nocheck
import React from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import { useSearchParams } from "react-router-dom";

const RemoveFromFolder = ({ item, stateUpdate, setShow }) => {
  const [searchParams] = useSearchParams();

  const handleRemoveItem = async () => {
    try {
      const { data } = await axios.post("/folder/remove-item", {
        itemId: item?.id,
        type: item?.type,
        folderId: searchParams.get("folderId"),
      });
      if (data) {
        const { folder } = data;
        // setFolder(folder || {});
        stateUpdate && stateUpdate((prev) => !prev);
        setShow && setShow(false);
      }
    } catch (error) {
      toast.error("Could not remove Item");
      console.log(error);
    }
  };
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />
          <div className="text-base md:text-lg mb-4 font-semibold">
            Remove from Folder
          </div>
          <span className="text-xs md:text-sm">
            Removing{" "}
            <span className="text-primary font-semibold">
              {item?.type == "FORM" && item?.value?.formJson?.title}
              {item?.type == "PDF" && item?.value?.documentName}
              {item?.type == "PDF-TEMPLATE" && item?.value?.documentName}
            </span>{" "}
            will remove it from the current folder only.
          </span>
          <span className="text-xs md:text-sm">
            The file will still be accessible from the main dashboard
          </span>
          <div className="flex gap-2 items-center justify-end mt-8">
            <button
              className="text-xs md:text-sm text-primary border px-4 py-2 rounded-md"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              className="text-xs md:text-sm text-white bg-primary px-4 py-2 rounded-md"
              onClick={() => {
                handleRemoveItem();
              }}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveFromFolder;
