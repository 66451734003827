// #673ab7 - google
// @ts-nocheck
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AiFillEye,
  AiFillInfoCircle,
  AiOutlineDatabase,
  AiOutlineRollback,
} from "react-icons/ai";
import {
  BsFileEarmarkSpreadsheetFill,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { BiImageAlt } from "react-icons/bi";
import { LuRedo2, LuUndo2 } from "react-icons/lu";
import {
  MdArrowDropDown,
  MdCheck,
  MdClose,
  MdDragIndicator,
  MdImage,
  MdOutlineColorLens,
  MdOutlineLogout,
  MdOutlinePersonOutline,
} from "react-icons/md";
import { SiGoogledrive } from "react-icons/si";
import { PiFilePdfDuotone } from "react-icons/pi";
import { getOwnerPlanDetails, isFormCustomer } from "../../utils";
import axios from "./../../utils/axios";
import useDrivePicker from "react-google-drive-picker";
import "./index.css";

import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import _, { extend } from "lodash";
import { Helmet } from "react-helmet";
import { InfinitySpin } from "react-loader-spinner";
// import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { v4 } from "uuid";
import { startTour } from "@intercom/messenger-js-sdk";

import AddQuestionFloater from "../../components/EditForm/AddQuestionFloater";
import FormDescInput from "../../components/EditForm/FormDescInput";
import FormTitleInput from "../../components/EditForm/FormTitleInput";
import GoogleStyleNextPageDropdown from "../../components/EditForm/GoogleStyleNextPageDropdown";
import PageSection from "../../components/EditForm/PageSection";
import SendModal from "../../components/EditForm/SendModal";
import Sorting from "../../components/EditForm/Sorting";
import Modal from "../../components/Modal";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import {
  BASE_URL,
  getDashboardUrl,
  getDashboardUrlV1,
  getFormViewerUrl,
} from "../../constants/constants";
import useFetch from "../../hooks/useFetch";
import useUndoableState from "../../hooks/useUndoableState";
import { fetchOwner } from "../../utils";
import { fileToBase64 } from "../../utils/FileDataToBase64";
import {
  getOwnerFromDetails,
  isEntryPaidPlan,
  isFeatureEnabled,
} from "../../utils/paidFeat";
import getOtherComponents from "./getOtherComponents";
import getQuestionTypes from "./getQuestionTypes";
import { validateEmail } from "../../utils/validateEmail";
import { IoIosClose } from "react-icons/io";
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import HeaderImageModal from "../../components/EditForm/HeaderImageModal";
import TextField from "@mui/material/TextField";
import imageCompression from "browser-image-compression";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import GoogleSheetModal from "../../components/EditForm/GoogleSheetModal";
import FormResponseSummary from "../../components/FormResponseSummary";
import EmailMultiSelect from "../../components/Ui/EmailMultiSelect";
import { languages } from "../../constants/languages";
import MixPanel from "../../utils/services/mixpanel";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import useWhiteLabelHelper from "../../hooks/reduxHooks/useWhiteLabelHelper";
import { GrDropbox } from "react-icons/gr";
import DropboxFolderSelect from "../../components/DropboxFolderSelect";
import {
  dropboxAuthRedirectUrl,
  dropboxClientID,
} from "../../constants/Integrations/dropbox";
import TrialExtendedModal from "../../components/TrialExtendedModal";
import { ImOnedrive } from "react-icons/im";
import {
  microsoftAuthRedirectUrl,
  microsoftClientID,
  microsoftScopes,
} from "../../constants/Integrations/Microsoft";
import OnedriveModalFolderSelect from "../../components/Ui/OndriveModalFolderSelect";
import { useDispatch } from "react-redux";
import { increaseTrialEndDateByOneDay } from "../../redux/slices/ownerSlice";

const PurpleSwitch = styled(Switch)(({ theme, inputProps }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: inputProps?.accentColor ?? COLORS.primary,
    "&:hover": {
      backgroundColor: alpha(
        inputProps?.accentColor ?? COLORS.primary,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: inputProps?.accentColor ?? COLORS.primary,
  },
}));

function CustomDropDown({ opts, setSelectedOpt, selectedOpt, defaultText }) {
  let activeQuestion = opts.find((option) => option?.key === selectedOpt?.key);
  const questionTypeDropdownRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [displacementAmount, setDisplacementAmount] = useState(0);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, index) => {
    let displacement = -(index - 1) * 48;
    setDisplacementAmount(displacement);
    setSelectedOpt(option);
    setIsOpen((prev) => !prev);
  };

  const handleOutsideClick = (e) => {
    if (questionTypeDropdownRef.current !== null) {
      if (questionTypeDropdownRef.current.contains(e.target)) return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative ml-6 max-w-[240px] w-[200px] border rounded-sm hover:shadow">
      <div className="relative">
        <div
          onClick={toggleDropdown}
          className="h-[36px] px-3 rounded flex items-center cursor-pointer"
        >
          <div className="ml-1 mr-2">
            <span className="text-gray-800 text-sm">
              {defaultText || activeQuestion?.value}
            </span>
          </div>
          <div className="ml-auto">
            <MdArrowDropDown className="text-2xl text-gray-500" />
          </div>
        </div>
        {isOpen && (
          <div
            ref={questionTypeDropdownRef}
            style={{
              marginTop: "-48px",
              position: "absolute",
              boxShadow:
                "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
              // top: `${displacementAmount}px`,
              zIndex: 5,
            }}
            className="py-2 max-w-[200px] min-w-[200px] bg-white border border-[#0000001f] rounded z-150 max-h-[250px] overflow-y-scroll"
            id="mini-pdf-custom-scroll"
          >
            {opts.map((option, index) => {
              return (
                <Fragment key={index}>
                  <div
                    onClick={() => handleOptionClick(option, index)}
                    className={`${
                      activeQuestion?.key === option?.key && "bg-[#e4f0fc]"
                    } min-h-[48px] w-full py-2 pl-4 pr-8 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <span className="text-gray-800 text-sm font-medium">
                      {option?.value}
                    </span>
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

// let languages = [
//   { key: "ar", value: "Arabic" },
//   { key: "ch", value: "Chinese" },
//   { key: "nl", value: "Dutch" },
//   { key: "en", value: "English" },
//   { key: "fr", value: "French" },
//   { key: "de", value: "German" },
//   { key: "hr", value: "Hebrew" },
//   { key: "id", value: "Indonesian" },
//   { key: "jp", value: "Japanese" },
//   { key: "pt", value: "Portuguese" },
//   { key: "es", value: "Spanish" },
//   { key: "th", value: "Thai" },
// ];

let integrationOptions_ = [
  {
    name: "Google Sheets",
    desc: "Send your form responses to a Google Sheet.",
    type: "googleSheet",
    enabled: false,
    icon: <BsFileEarmarkSpreadsheetFill className="text-2xl text-green-600" />,
  },
  {
    name: "Google Drive",
    desc: "Send your form responses as a pdf to Google Drive",
    type: "googleDrive",
    enabled: false,
    icon: <SiGoogledrive className="text-2xl text-yellow-500" />,
  },
  {
    name: "Dropbox",
    desc: "Send your form responses as a pdf to Dropbox.",
    type: "dropboxDrive",
    icon: <GrDropbox className="inline-block text-lg  text-blue-600" />,
  },
  {
    name: "OneDrive",
    desc: "Send your form responses as a pdf to OneDrive.",
    type: "msOneDrive",
    icon: <ImOnedrive className="inline-block text-lg text-blue-600" />,
  },
  // {
  //   name: "Notion",
  //   desc: "Send your form responses to a Notion database.",
  //   type: "notion",
  //   enabled: false,
  //   icon: <SiNotion className="text-2xl text-gray-700" />,
  // },
];

const EditForm = () => {
  const form = {
    title: "Form name (BoloForms)",
    description: "No Description",
    logo: "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/BoloForms+Logo+(1)/2.svg",
    headerImage: "",
    confirmationMessage: "Your response has been recorded.",
    redirectionUrl: "",
    fontFamily: "Poppins",
    accentColor: COLORS.primary,
    backgroundColor: COLORS.secondary,
    themeTemplate: "google-forms", //google-forms, notion-forms, default
    darkMode: false,
    pages: [
      {
        title: "Page 1",
        description: "Page Description",
        id: v4(),
        questions: [
          {
            type: "Text",
            title: "Email",
            description: "",
            required: true,
            id: v4(),
            value: "",
            mandatory: true,
            validation: "respondent_email",
          },
        ],
        goToNextPage: "next",
        goToPrevPage: "prev",
      },
      {
        title: "Page 2",
        description: "Page Description",
        id: v4(),
        questions: [],
        goToNextPage: "next",
        goToPrevPage: "prev",
      },
    ],
    prefill: {},
    prefillSource: "NONE",
    showViewAndVerifyPdf: true,
    showThankYouPageBranding: true,
  };

  let zendeskPrefillQuestionsOptions = [
    "ticket.requester.email",
    "ticket.requester.name",
    "ticket.assignee.group.name",
    "ticket.assignee.user.name",
    "ticket.subject",
    "ticket.description",
    "ticket.priority",
  ];
  const [owner, setOwner] = useState({});
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const whiteLabelHelper = useWhiteLabelHelper();
  const [searchParams] = useSearchParams();
  let formId = searchParams.get("id");
  let isTrialExtended = searchParams.get("trialExtended");
  let extendedTrialDays = searchParams.get("d");
  const navigate = useNavigate();
  const [openPicker, authResponse] = useDrivePicker();
  const pagesRef = useRef([]);
  const editorContainerRef = useRef();
  const topBarMoreOptionsRef = useRef();
  const profileOptionsRef = useRef();
  const {
    // state,
    // setState,
    formState,
    setFormState,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    goBack: undoDoc,
    goForward: redoDoc,
  } = useUndoableState(form);

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/private-forms?formId=${formId}`);
      setData(response?.data || {});
    };
    fetchData();
  }, [formId]);
  let { forms, respondentCount } = data || {};
  const schemas = data?.forms?.[0]?.pdfTemplate?.schemas;

  const [questionTypes, setQuestionTypes] = React.useState([]);
  const [otherComponents, setOtherComponents] = React.useState({});
  const [isFormUser, setIsFormUser] = React.useState(false);

  const [noPlanError, setNoPlanError] = React.useState(false);

  const [iframeDims, setIframeDims] = React.useState({
    width: 200,
    height: 700,
  });
  const [iframeLink, setIframeLink] = React.useState(
    `<iframe src="${getFormViewerUrl()}/${formId}?p=view" width= 200px" height=700px" name="BoloForms"></iframe>`
  );
  const [openEmbedModal, setOpenEmbedModal] = React.useState(false);
  // const [formState, setFormState] = React.useState(null);
  const [active, setActive] = React.useState({
    pageIndex: 0,
    questionIndex: null,
    tab: "questions",
    rightSidebar: "theme",
    integrationSidebar: "list",
    sorting: null,
    responses: {
      tab: "integrations",
    },
  });
  const [show, setShow] = React.useState({
    questionList: {
      pageId: formState?.pages?.[0]?.id,
      show: false,
    },
    preview: {
      currentPage: 0,
    },
    rightSidebar: false,
    previousButton: false,
    nextButton: true,
    submitButton: false,
    needPermission: false,
    loading: false,
    paidModal: {
      visible: false,
      data: {},
    },
    onSaveMessage: false,
    sendModal: false,
    topBarMoreOptions: false,
    profileOptions: false,
    headerImageModal: false,
    confirmationMessage: false,
    redirectionUrl: false,
    googleSheetModal: false,
    trialExtendedModal: isTrialExtended ? true : false,
    // trialExtendedModal: true,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isTrialExtended) {
      dispatch(increaseTrialEndDateByOneDay());
    }
  }, []);

  const [prefillOptions, setPrefillOptions] = React.useState({});
  const [prefillGoogleSheet, setPrefillGoogleSheet] = React.useState({
    sheets: [],
    activeSheet: {},
  });
  const [isApprovalWorkflowEnabled, setIsApprovalWorkflowEnabled] =
    React.useState(true);
  const [isRespondentEmailVerify, setIsRespondentEmailVerify] =
    React.useState(false);
  const [integrations, setIntegrations] = React.useState({
    googleSheet: null,
    notion: null,
    googleDrive: null,
    dropboxDrive: null,
    msOneDrive: null,
  });

  const [integrationOptions, setIntegrationsOptions] =
    React.useState(integrationOptions_);
  const [isArchive, setIsArchive] = React.useState(false);
  const [isNotificationEnabled, setIsNotificationEnabled] =
    React.useState(false);
  const [isGeneratePdfEnabled, setIsGeneratePdfEnabled] = React.useState(false);

  const [updateThankYouPageBranding, setUpdateThankYouPageBranding] =
    React.useState(false);

  const [genLang, setGenLang] = React.useState<any>({
    key: "en",
    value: "English",
  });
  const [sendACopyInputs, setSendACopyInputs] = React.useState([]);
  const [receivesACopy, setReceivesACopy] = React.useState([]);
  // const [generatedPDFName, setGeneratedPDFName] = React.useState([]);
  const [generatedPDFNameQuestions, setGeneratedPDFNameQuestions] =
    React.useState([]);
  const [mixedTagsSettings, setMixedTagsSettings] = React.useState({
    pattern: /@/, // <- must define "patten" in mixed mode
    dropdown: {
      enabled: 1,
      position: "text",
    },
    whitelist: [],
    duplicates: true,
  });
  const [showDropBoxChooser, setShowDropBoxChooser] = useState<boolean>(false);
  const [showOneDriveSelector, setShowOneDriveSelector] =
    useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState({});
  const onChange = useCallback((e) => {
    // console.log(
    //   "CHANGED:",
    //   {
    //     tagify: e.detail.tagify.value,
    //     cleanValue: e.detail.tagify.getCleanValue(),
    //     value: e.detail.value,
    //   },
    //   e.detail.tagify.value, // Array where each tag includes tagify's (needed) extra properties
    //   e.detail.tagify.getCleanValue(), // Same as above, without the extra properties
    //   e.detail.value // a string representing the tags
    // );
    setMixedTagsValue(e?.detail?.value?.replace(/\n$/, ""));
  }, []);
  const [mixedTagsValue, setMixedTagsValue] = useState("");
  console.log(mixedTagsValue);

  const [showRemoveReceiverModal, setShowRemoveReceiverModal] =
    React.useState(false);
  const [removeReceiver, setRemoveReceiver] = React.useState(null);
  const [isResponseRemoving, setIsResponseRemoving] = React.useState(false);

  const [needPaidPlanError, setNeedPaidPlainError] = React.useState(false);

  const [confirmationMessageInput, setConfirmationMessageInput] =
    React.useState(
      form?.confirmationMessage || "Your response has been recorded."
    );
  const [redirectionUrlInput, setRedirectionUrlInput] = React.useState(
    form?.redirectionUrl || ""
  );

  const [upgradeModalType, setUpgradeModalType] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;
  const [isThankYouPageEnabled, setIsThankYouPageEnabled] = useState(false);

  const checkFeatures = async () => {
    let isThankYou = await isFeatureEnabled("DISABLE_THANK_YOU_PAGE_BRANDING");
    console.log("checking feature", isThankYou);
    setIsThankYouPageEnabled(isThankYou);
  };

  const isVaildPrefillQuestionType = (questionType) => {
    return (
      questionType === "Text" ||
      questionType === "Textarea" ||
      questionType === "Dropdown" ||
      questionType === "RadioGroup"
    );
  };

  function isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
      // setPlan(owner?.planhistory[owner?.planhistory?.length - 1]);
    };
    updatedOwner();
  }, []);

  const handleAddQuestion = (question, index) => {
    const newQuestion = {
      type: question.name,
      title: `Question (${question.name}) ${
        formState.pages[active.pageIndex].questions.length + 1
      }`,
      description: "",
      isDescription: true,
      required: false,
      id: v4(),
      value: "",
      otherValue: "",
    };
    if (
      question.name === "Checkbox" ||
      question.name === "RadioGroup" ||
      question.name === "Dropdown"
    ) {
      newQuestion.options = [
        {
          type: "option",
          text: "Option 1",
          value: "",
          enabled: true,
          goToNextPage: "next",
        },
        {
          type: "option",
          text: "Option 2",
          value: "",
          enabled: true,
          goToNextPage: "next",
        },
        {
          type: "option",
          text: "Option 3",
          value: "",
          enabled: true,
          goToNextPage: "next",
        },
        { type: "add", text: "Option 4", value: "", enabled: false },
        { type: "other", text: "Other (describe)", value: "", enabled: false },
      ];
      newQuestion.hasOtherOption = false;

      if (question.name !== "Checkbox") {
        newQuestion.pageBasedOnAnswer = false;
      }
    }
    if (question.name === "FileUpload") {
      newQuestion.multiple = false;
    }
    // console.log("index", index);
    // console.log(
    //   "active.pageIndex < formState.pages.length",
    //   active.pageIndex < formState.pages.length
    // );
    let insertIndex =
      index ??
      (active.pageIndex < formState.pages.length
        ? active.pageIndex
        : formState.pages.length - 1);
    // console.log("insertIndex", insertIndex);
    setFormState((prevState) => {
      const newForm = { ...prevState };
      newForm.pages[insertIndex].questions.push(newQuestion);

      let isOneEmptyPage = false;
      newForm.pages.forEach((page) => {
        if (page.questions.length === 0) {
          isOneEmptyPage = true;
        }
      });
      if (!isOneEmptyPage) {
        // if (newForm.pages[active.pageIndex].questions.length === 0) {
        // add new page
        newForm.pages.push({
          title: `Page ${newForm.pages.length + 1}`,
          description: "Page Description",
          id: v4(),
          questions: [],
          goToNextPage: "next",
          goToPrevPage: "prev",
        });
      }
      return newForm;
    });
    toast.success("Question added!");
  };

  const handleSave = async (formState, owner, isRespondentEmailVerify) => {
    let isSignatureQuestionFeature = false;
    // console.log("Form Saving...");

    let responseItemsId = {};
    let formInputFields = [];

    for (const page of formState?.pages) {
      for (const question of page.questions) {
        delete question["chosen"];
        delete question["selected"];
        responseItemsId[question.id] = question.title;
        formInputFields.push(question.title);

        //! due to auto save
        // if (question.title.trim() === "") {
        //   toast.error("Please fill all the question titles");
        //   return;
        // }

        // is can signature is valid
        if (!isSignatureQuestionFeature && question.type === "Signature") {
          isSignatureQuestionFeature = true;
        }
      }
    }

    let isPaidPlan = await isEntryPaidPlan(owner, "SIGNATURE_QUESTION");
    if (!isPaidPlan && isSignatureQuestionFeature) {
      return setShow((prev) => ({
        ...prev,
        paidModal: {
          visible: true,
          data: {
            title: "Signature Question is premium feature!",
            desc: "You are on free plan. Please upgrade to use this feature.",
            videoSrc: "https://www.youtube.com/embed/a_xF-376KXk",
          },
        },
        onSaveMessage: (
          <span className="text-red-500 font-semibold">
            Please remove signature question in order to save the form!
          </span>
        ),
      }));
    }

    let data_ = {
      ownerId: owner._id,
      formId,
      responseItemsId,
      formJson: formState,
      formInputFields,
      isApprovalWorkflowEnabled,
      isRespondentEmailVerify: formState?.pages?.[0]?.questions?.some(
        (question) => question.validation === "respondent_email"
      )
        ? isRespondentEmailVerify
        : false,
    };

    // console.log("data_",  data_);

    let { data } = await axios.put(`/forms/custom-form-json`, data_);

    // if (!data.success) {
    //   let currentPlan = owner?.planhistory[owner.planhistory.length - 1];
    //   return setShow((prev) => ({
    //     ...prev,
    //     paidModal: {
    //       visible: true,
    //       data: {
    //         title: `You have reached your ${
    //           currentPlan?.usageQuantityAllowed?.[2] || ""
    //         } approvers/collaborators limit!`,
    //         desc: "You are on free plan. Please upgrade to use this feature.",
    //         videoSrc: "https://www.youtube.com/embed/hdz3Pswq2wQ",
    //       },
    //     },
    //     onSaveMessage: null,
    //   }));
    // }

    setShow((prev) => {
      return { ...prev, onSaveMessage: "All changes saved" };
    });

    // toast(data.message, {
    //   type: data.success ? "success" : "error",
    // });
  };

  // function debounceHandleSave(formState) {
  //   handleSave(formState);
  // }

  async function archiveForm(status) {
    setIsArchive(status);
    await axios.put(`/forms/status`, {
      formId: forms[0]?.formId,
      status: status ? "ACTIVE" : "INACTIVE",
    });
  }

  const handleCollectRespondentEmail = async (e) => {
    let checked = e.target.checked;

    new MixPanel().track("Form", {
      form_action: "form_collect_respondent_email",
      paid: false,
    });
    new MixPanel().increment("form_collect_respondent_email");
    if (!checked) {
      setIsRespondentEmailVerify(false);
      await setFormState((prevState) => {
        const newForm = { ...prevState };
        newForm.pages[0].questions = newForm.pages[0].questions.filter(
          (question) => question.validation !== "respondent_email"
        );
        return newForm;
      });
      // await handleSave();
      return toast.success("Collect respondent email disabled!");
    }

    let collectEmailQuestion = {
      type: "Text",
      title: `Email`,
      description: "",
      required: true,
      id: v4(),
      value: "",
      validation: "respondent_email",
    };

    await setFormState((prevState) => {
      const newForm = { ...prevState };
      newForm.pages[0].questions.unshift(collectEmailQuestion);
      return newForm;
    });

    // handleSave();
    toast.success("Collect respondent email enabled!");
  };

  const handleUpdateNotificationStatus = async (e) => {
    let checked = e?.target?.checked;

    new MixPanel().track("Form", {
      form_action: "form_send_email_notification",
      paid: false,
    });
    new MixPanel().increment("form_send_email_notification");

    let { data } = await axios.put(`/forms/update-notification-status`, {
      formId: forms[0]?.formId,
      status: checked,
    });
    if (!data?.error) {
      setIsNotificationEnabled(checked);
      toast.success("Notification status updated!");
    } else {
      toast.error(data?.error);
    }
  };

  const handleUpdateGeneratePdf = async (e) => {
    let checked = e?.target?.checked;

    new MixPanel().track("Form", {
      form_action: "form_generate_response_pdf",
      paid: false,
    });
    new MixPanel().increment("form_generate_response_pdf");

    setFormState((prevState) => {
      const newForm = { ...prevState };
      newForm.showViewAndVerifyPdf = checked;
      return newForm;
    });

    let { data } = await axios.put(`/forms/update-generate-pdf`, {
      formId: forms[0]?.formId,
      status: checked,
    });
    if (!data?.error) {
      setIsGeneratePdfEnabled(checked);
      toast.success("Generate PDF status updated!");
    } else {
      toast.error(data?.error);
    }
  };

  const handleUpdateThankYouBranding = async (e) => {
    let checked = e?.target?.checked;

    let featuresAccess = await isFeatureEnabled(
      "DISABLE_THANK_YOU_PAGE_BRANDING"
    );

    new MixPanel().track("Form", { form_action: "form_thankyou", paid: true });
    new MixPanel().increment("form_thankyou");

    let ownerFrom = getOwnerFromDetails();
    if (!featuresAccess && ownerFrom === "APPSUMO") {
      setShowUpgradeModal(true);
      setUpgradeModalType("DISABLE_THANK_YOU_PAGE_BRANDING");

      return;
    } else {
      setFormState((prevState) => {
        const newForm = { ...prevState };
        newForm.showThankYouPageBranding = checked;
        return newForm;
      });
      setUpdateThankYouPageBranding((prev) => !prev);
    }
  };

  const handleSaveSendACopyInput = async () => {
    new MixPanel().track("Form", {
      form_action: "form_send_a_copy_to",
      paid: true,
    });
    new MixPanel().increment("form_send_a_copy_to");
    if (
      owner?.planhistory[owner?.planhistory.length - 1]?.planId === "FREE_PLAN"
    ) {
      setNeedPaidPlainError(true);
      return;
    }

    if (sendACopyInputs.length === 0) {
      return toast.error("Please enter email address");
    }

    for (const email of sendACopyInputs) {
      if (!validateEmail(email)) {
        return toast.error("Please enter valid email address");
      }
    }

    let { data } = await axios.put(`/forms/receives-a-copy`, {
      formId: forms[0]?.formId,
      emails: sendACopyInputs,
    });

    if (!data?.error) {
      setReceivesACopy((prev) => {
        return data?.receivers;
      });
      setSendACopyInputs([]);
      toast.success("Emails updated!");
    } else {
      toast.error(data?.error);
    }
  };

  const handleChangeGenLang = async (lang) => {
    if (!lang) {
      return;
    }
    let { data } = await axios.put(`/forms/update-generate-pdf-lang`, {
      formId: forms?.[0]?.formId,
      lang: lang,
    });
    if (!data?.error) {
      new MixPanel().track("Form", {
        form_action: "form_pdf_language",
        form_language: lang,
        paid: false,
      });
      new MixPanel().increment("form_pdf_language");
      setGenLang(lang);
      toast.success("Generate PDF status updated!");
    } else {
      toast.error(data?.error);
    }
  };

  const handleGeneratedPDFName = async () => {
    // if (!generatedPDFName?.length) {
    //   toast.error("No Questions Selected");
    //   return;
    // }

    // let idArray = generatedPDFName?.map((que) => que?.id);

    let featureAccess = await isFeatureEnabled("CUSTOM_PDF_NAME");
    const ownerPlanDetails = getOwnerPlanDetails();
    if (!featureAccess) {
      setShowUpgradeModal(true);
      setUpgradeModalType("CUSTOM_PDF_NAME");
      // if (ownerPlanDetails.isAppSumo) {
      //   setUpgradeModalData({
      //     title: BLOCKING.FOLDER.title,
      //     desc: BLOCKING.FOLDER.desc,
      //     redirect: BLOCKING.FOLDER.redirect,
      //     videoSrc: "",
      //     redirectText: BLOCKING.FOLDER.redirectText,
      //   });
      // } else {
      //   setUpgradeModalData({
      //     title: "Custom Pdf Name is available in FreeLancer Plan and above",
      //     desc: `You are on ${ownerPlanDetails?.currentPlan?.planName} upgrade to use this feature.`,
      //   });
      // }

      return;
    }

    let { data } = await axios.post(`/forms/update-generated-pdf-name`, {
      formId: forms[0]?.formId,
      genratedFileName: mixedTagsValue,
    });

    if (!data.error) {
      toast.success("Generate PDF FileName updated!");
    } else {
      toast.error(data?.error);
    }
  };

  const getGeneratedPDFFileName = async () => {};

  const handleGeneratePDFFileNameLocal = (queKey) => {
    // const alreadyExists = generatedPDFName?.find(
    //   (question) => question?.id && question?.id == queKey?.key
    // );

    // if (alreadyExists) {
    //   toast.error("Question Already exists");
    //   return;
    // }

    // const questionToAdd = generatedPDFNameQuestions?.find(
    //   (question) => question?.id && question?.id == queKey?.key
    // );
    // if (questionToAdd) {
    //   setGeneratedPDFName((prev) => [...prev, questionToAdd]);
    // }

    new MixPanel().track("Form", {
      form_action: "form_generate_pdf_name",
      paid: true,
    });
    new MixPanel().increment("form_generate_pdf_name");

    const questionToAdd = generatedPDFNameQuestions?.find(
      (question) => question?.id && question?.id == queKey?.key
    );
    const addStringify = [
      [{ value: questionToAdd?.title, prefix: "@", title: questionToAdd?.id }],
    ];
    setMixedTagsValue((mixedTagsValue || "") + JSON.stringify(addStringify));
  };

  const deleteOneReceiverModal = () => (
    <Fragment>
      <div className="flex justify-end flex-col">
        <div className="py-6 px-6">
          <h2 className="text-lg font-semibold mb-1">
            Are you sure you want to remove this receiver?
          </h2>
          <p className="text-sm">
            The email <span className="font-semibold">{removeReceiver}</span>{" "}
            will be removed from the response receivers list.
          </p>
        </div>
        <button
          disabled={isResponseRemoving}
          onClick={async () => {
            try {
              setIsResponseRemoving(true);
              let removeEmail = removeReceiver;
              let { data } = await axios.put(`/forms/receives-a-copy`, {
                formId: forms[0]?.formId,
                emails: [],
                removeEmail: removeEmail,
              });

              if (!data?.error) {
                setReceivesACopy((prev) => {
                  return data?.receivers;
                });
                setIsResponseRemoving(false);
                setRemoveReceiver(null);
                setShowRemoveReceiverModal(false);
                toast.success("Emails updated!");
              } else {
                setIsResponseRemoving(false);
                setRemoveReceiver(null);
                setShowRemoveReceiverModal(false);
                toast.error(data?.error);
              }
            } catch (error) {
              setIsResponseRemoving(false);
              setRemoveReceiver(null);
              setShowRemoveReceiverModal(false);
              console.log("errodeleteOneReceiverModal", error);
              toast.error("Something went wrong! Please try again");
            }
          }}
          className="mx-6 bg-red-600 text-md text-white px-8 py-2.5 rounded-xl shadow mr-4 mb-4 hover:opacity-80 disabled:opacity-50 w-fit ml-auto"
        >
          {isResponseRemoving ? "Deleting..." : "Delete"}
        </button>
      </div>
      {/* )} */}
      <div
        onClick={() => {
          setIsResponseRemoving(false);
          setRemoveReceiver(null);
          setShowRemoveReceiverModal(false);
        }}
        className="absolute top-0 right-0 cursor-pointer"
      >
        <MdClose className="text-2xl m-4" />
      </div>
    </Fragment>
  );

  const googleSheetsPrefillSettings = async () => {
    try {
      setShow((prev) => {
        return { ...prev, loading: true };
      });
      let { data } = await axios.put(`${BASE_URL}/forms/sheets-prefill`, {
        formId,
      });
      console.log("data", data);
      if (data.needPermission) {
        setShow((prev) => {
          return { ...prev, needPermission: true, loading: false };
        });
        toast.error("Please give permission to access your google sheets");
        return;
      }

      // setting spreadsheetUrl
      data.spreadsheetUrl && (forms[0].spreadsheetUrl = data.spreadsheetUrl);

      let activeSheet =
        data?.sheets?.length > 0
          ? data.sheets.find(
              (sheet) =>
                sheet.sheetId == formState?.prefill?.googleSheet?.sheetId
            ) ?? data.sheets[0]
          : {};

      setPrefillGoogleSheet((prev) => {
        return {
          ...prev,
          sheets: data?.sheets,
          activeSheet: activeSheet,
        };
      });
      let columns = activeSheet?.columns ?? [];
      setPrefillOptions(columns);
      setFormState((prev) => {
        return {
          ...prev,
          prefill: {
            ...prev.prefill,
            googleSheet: {
              ...prev.prefill?.googleSheet,
              sheetId: activeSheet?.sheetId,
            },
            trigger: {
              question: prev.pages[0].questions[0].id,
              ansColumn: columns?.[0] ?? "",
            },
          },
        };
      });
      setShow((prev) => {
        return { ...prev, needPermission: false, loading: false };
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const syncGoogleSheetsPrefillData = async () => {
    try {
      setShow((prev) => {
        return { ...prev, loading: true };
      });
      toast.info("Syncing...");
      let { data } = await axios.post(
        `${BASE_URL}/forms/sync-sheets-prefill-data`,
        {
          prefillSheetId: formState?.prefill?.googleSheet?.sheetId,
          id: formState?.prefill?.databaseId,
          formId,
        }
      );
      console.log("data", data);
      setShow((prev) => {
        return { ...prev, loading: false };
      });
      toast.success("Synced successfully!");
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateIntegrationStatus = async (status, type) => {
    try {
      if (
        owner?.planhistory[owner?.planhistory.length - 1]?.planId ===
          "FREE_PLAN" &&
        type !== "googleSheet"
      ) {
        setNoPlanError(true);
        return;
      }

      const { data: permissionDetails } = await axios.post(
        `/forms/check-google-permission`,
        {
          ownerEmail: owner?.email,
          formId: formId,
          isFormDashboard: true,
        }
      );

      let lookingPermissionFor =
        type === "googleSheet"
          ? permissionDetails?.isSheetsPermission
          : permissionDetails?.isDrivePermission;

      let scopeType = type === "googleSheet" ? "sheets" : "drive";

      if (!lookingPermissionFor) {
        window.open(
          `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=${scopeType}&redirectUrl=/create/forms?id=${formId}&from=DASHBOARD`,
          "_self"
        );
        return;
      }

      let { data } = await axios.put(`${BASE_URL}/forms/integration-status`, {
        formId,
        integration: type,
        status,
        ownerId: owner._id,
      });
      console.log("data", data);
      if (data.needPermission) {
        return toast.error(data.message);
      }
      let spreadsheetUrl = {};
      if (data.spreadsheetUrl) {
        spreadsheetUrl = { spreadsheetUrl: data.spreadsheetUrl };
      }
      setIntegrations((prev) => {
        return {
          ...prev,
          [type]: {
            ...prev[type],
            enabled: status,
            ...spreadsheetUrl,
          },
        };
      });

      toast.success("Updated successfully!");
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleSubmit = async (folder) => {
    try {
      const { data } = await axios.post("/dropbox/setFolderForForm", {
        folder,
        formId: formId,
        ownerEmail: owner?.email,
      });
      if (data?.success) {
        setIntegrations((prev) => ({
          ...prev,
          dropboxDrive: {
            ...prev.dropboxDrive,
            parentFolderId: folder?.parentFolderId,
            parentFolderName: folder?.parentFolderName,
            parentFolderUrl: folder?.parentFolderUrl,
            enabled: data?.formMetaFields?.enabled,
          },
        }));
        toast.success("Updated Dropbox info!");
        setShowDropBoxChooser(false);
      }
    } catch (error) {
      console.log("Error---", error);
    }
  };
  const handleOneDriveSubmit = async (folder, enabled = true) => {
    try {
      const { data } = await axios.post("/microsoft/setFolderForForm", {
        folder,
        formId,
        enabled,
      });
      console.log();
      if (data?.success) {
        setIntegrations((prev) => ({
          ...prev,
          msOneDrive: {
            ...prev.msOneDrive,
            parentFolderId: data?.onedriveFields?.parentFolderId,
            parentFolderName: data?.onedriveFields?.parentFolderName,
            parentFolderUrl: data?.onedriveFields?.parentFolderUrl,
            parentFolderDriveId: data?.onedriveFields?.parentFolderDriveId,
            enabled: data?.onedriveFields?.enabled,
          },
        }));
        toast.success("Updated OneDrive info!");
      } else {
        toast.error(data?.message);
      }
      setShowOneDriveSelector(false);
    } catch (error) {
      console.log("error in saving folder ", error);
      toast.error("Something went wrong!");
    }
  };
  const handleDriveFolderPick = async (documentId) => {
    let isSufficientPermission =
      isIntegrationPermissionAvailable("googleDrive");

    if (!isSufficientPermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/create/forms?id=${formId}`,
        "_self"
      );
      return;
    }
    const { data: permissionDetails } = await axios.post(
      `/forms/check-google-permission`,
      {
        ownerEmail: owner?.email,
        formId: formId,
        isFormDashboard: true,
      }
    );

    if (!permissionDetails?.isDrivePermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/create/forms?id=${formId}`,
        "_self"
      );
      return;
    }

    let clientId, developerKey;

    try {
      const { data } = await axios.get(
        `${BASE_URL}/common/get-google-credentials`
      );
      clientId = data?.clientId;
      developerKey = data?.developerKey;
    } catch (error) {
      console.error(
        "Error fetching client ID and developer key from the backend",
        error
      );
    }

    if (clientId && developerKey) {
      openPicker({
        clientId,
        developerKey,
        token: owner?.integrationTokens?.google?.oAuthToken,
        viewId: "FOLDERS",
        setIncludeFolders: true,
        setSelectFolderEnabled: true,
        showUploadView: false,
        supportDrives: true,
        multiselect: false,
        appId: 204560571245,
        callbackFunction: async (driveData) => {
          if (driveData.action === "cancel") {
            console.log("User clicked cancel/close button");
          }

          if (driveData?.docs) {
            try {
              let res = await axios.put(
                `${BASE_URL}/common/update-drive-folder`,
                {
                  formId: formId,
                  parentFolderId: driveData?.docs[0]?.id,
                  parentFolderName: driveData?.docs[0]?.name,
                  parentFolderUrl: driveData?.docs[0]?.url,
                  documentType: "FORM",
                }
              );
              toast.success("Folder Updated");
              setIntegrations((prev) => {
                return {
                  ...prev,
                  googleDrive: {
                    ...prev.googleDrive,
                    parentFolderId: driveData?.docs[0]?.id,
                    parentFolderName: driveData?.docs[0]?.name,
                    parentFolderUrl: driveData?.docs[0]?.url,
                  },
                };
              });
            } catch (error) {
              console.log("error", error);
              toast.error("Something went wrong");
            }
          }
        },
      });
    }
  };

  const checkForZendeskPermission = async (prefillSource) => {
    setShow((prev) => {
      return { ...prev, needPermission: false, loading: false };
    });

    if (prefillSource !== "ZENDESK") return;

    let isZendeskPermission = owner?.integrationTokens?.zendesk?.accessToken;

    if (!isZendeskPermission) {
      setShow((prev) => {
        return { ...prev, needPermission: true, loading: false };
      });
      toast.error("Please give permission to access your Zendesk");
      return;
    }
  };

  //iframe
  function copyIframeLink(link) {
    navigator.clipboard.writeText(link);
    toast.success("Copied!");
  }

  function isIntegrationPermissionAvailable(integration) {
    switch (integration) {
      case "notion":
        return owner?.integrationTokens?.notion?.access_token;
      case "googleSheet":
        return (
          owner?.integrationTokens?.google?.oAuthRefreshToken ||
          owner?.oAuthRefreshToken
        );
      case "googleDrive":
        return (
          owner?.integrationTokens?.google?.oAuthRefreshToken ||
          owner?.oAuthRefreshToken
        );
      case "dropboxDrive":
        return (
          owner?.integrationTokens?.dropbox &&
          owner?.integrationTokens?.dropbox?.access_token !== ""
        );
      case "msOneDrive":
        return (
          owner?.integrationTokens?.microsoft &&
          owner?.integrationTokens?.microsoft?.access_token !== ""
        );
      default:
        return false;
    }
  }
  // async function isIntegrationPermissionAvailable(integration) {
  //   const { data } = await axios.post(`/forms/check-google-permission`, {
  //     ownerEmail: owner?.email,
  //     formId: formId || forms[0]?.formId,
  //     isFormDashboard: true,
  //   });

  //   console.log("The isIntegrationPermissionAvailable data: ", data);
  //   return false;
  // }

  const getProfilePhoto = () => {
    let accountType = localStorage.getItem("accountType");
    let owner = JSON.parse(localStorage.getItem("owner"));
    let currentUserEmail = localStorage.getItem("currentUserEmail");
    let profilePicUrl =
      currentUserEmail === owner?.email
        ? owner?.accountInfo?.photo || owner?.profilePic
        : accountType === "approver"
        ? owner?.approvers?.find((a) => a.email === currentUserEmail)
            ?.profilePic
        : owner?.collaborators?.find((c) => c.email === currentUserEmail)
            ?.profilePic;

    return (
      profilePicUrl ||
      "https://rewards.superassistant.io/static/media/avatar-3.f81bc93cc7cd674461d1dbaf67dc96d7.svg"
    );
  };

  const embedModalContent = () => {
    return (
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-2">Embed Form</h2>

        <p className="text-xs text-gray-600 mt-1 px-1">
          Please select width and height for iframe
        </p>
        <div className="flex">
          <div className="mt-4">
            <p className="text-sm mb-1">Height (in px)</p>
            <input
              type="text"
              id="width"
              placeholder="Enter Height"
              className="mr-5 border border-gray-300 rounded-md p-2 text-sm"
              value={iframeDims.height}
              onChange={(e) => {
                setIframeDims({ ...iframeDims, height: e.target.value });
                setIframeLink(
                  `<iframe src="${getFormViewerUrl()}/${formId}?p=view" width="${
                    iframeDims.width
                  }px" height="${
                    e.target.value
                  }px" name="boloformEdit"></iframe>`
                );
              }}
            />
          </div>

          <div className="mt-4">
            <p className="text-sm mb-1">Width (in px)</p>
            <input
              type="text"
              id="width"
              placeholder="Enter Width"
              className="w-full border border-gray-300 rounded-md p-2 text-sm"
              value={iframeDims.width}
              onChange={(e) => {
                setIframeDims({ ...iframeDims, width: e.target.value });
                setIframeLink(
                  `<iframe src="${getFormViewerUrl()}/${formId}?p=view" width="${
                    e.target.value
                  }px" height="${
                    iframeDims.height
                  }px" name="boloformEdit"></iframe>`
                );
              }}
            />
          </div>
        </div>

        {iframeLink.length > 0 && (
          <div className="mt-4">
            <p className="text-sm mb-1">Iframe Link </p>
            <div className={"w-full"}>
              <input
                type="text"
                id="width"
                className="w-full border border-gray-300 rounded-md p-2 text-sm"
                value={iframeLink}
                disabled
              />
              <div className={"w-full text-right mt-4"}>
                <button
                  onClick={() => {
                    setOpenEmbedModal(false);
                  }}
                  className="mt-4  rounded-lg hover:opacity-90"
                >
                  Cancel
                </button>

                <button
                  data-tip={"Copy Link"}
                  onClick={() => copyIframeLink(iframeLink)}
                  className={` h-full text-center p-2 px-4 ml-4 shadow bg-primary text-white hover:opacity-90 rounded-md `}
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getAllNotionDatabases = async () => {
    try {
      let { data } = await axios.get(
        `${BASE_URL}/notion/databases?ownerId=${forms?.[0]?.ownerId?._id}`
      );
      // console.log("data", data);
      let metafield = forms?.[0]?.metafields?.notion ?? {};
      let databaseId = metafield?.databaseId;
      let selectedDatabase = data?.find((db) => db.id === databaseId) ?? null;
      setIntegrations((prev) => {
        return {
          ...prev,
          notion: {
            ...prev.notion,
            databases: data,
            selectedDatabase,
            enabled: metafield?.enabled ?? false,
          },
        };
      });
      return data;
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleNotionSync = async () => {
    await handleSave(formState, owner, isRespondentEmailVerify);
    let { data } = await axios.put(`${BASE_URL}/notion/sync`, {
      formId,
      databaseId: integrations?.notion?.selectedDatabase.id,
    });
    setFormState(data?.form?.formJson);
    toast.success("Synced successfully!");
  };

  const handleGoToPageChange = (value, index) => {
    setFormState((prevState) => {
      const newForm = { ...prevState };

      let prevPointedPage = newForm.pages[index].goToNextPage;
      if (prevPointedPage !== "submit" && prevPointedPage !== "next") {
        newForm.pages[prevPointedPage].goToPrevPage = "prev";
      }

      newForm.pages[index].goToNextPage = value;

      let prevPageIndex;
      if (value === "submit") {
        prevPageIndex = newForm.pages.length - 2;

        newForm.pages[prevPageIndex].goToPrevPage = index - 1;
      } else if (value === "next") {
        prevPageIndex = index + 1;

        newForm.pages[prevPageIndex].goToPrevPage = "prev";
      } else {
        prevPageIndex = parseInt(value);
        newForm.pages[prevPageIndex].goToPrevPage = index;
      }

      return newForm;
    });
  };

  const debounceCallHandleSave = useCallback(
    _.debounce(
      (formState, owner, isRespondentEmailVerify) =>
        handleSave(formState, owner, isRespondentEmailVerify),
      3000
    ),
    []
  );
  const handleTopBarMoreOptionsOutsideClick = (e) => {
    if (topBarMoreOptionsRef.current !== null) {
      if (topBarMoreOptionsRef.current.contains(e.target)) return;
    }
    setShow((prev) => ({ ...prev, topBarMoreOptions: false }));
  };

  const handleProfileOptionsOutsideClick = (e) => {
    if (profileOptionsRef.current !== null) {
      if (profileOptionsRef.current.contains(e.target)) return;
    }
    setShow((prev) => ({ ...prev, profileOptions: false }));
  };

  function setQuilleditorFontFamily(fontFamily) {
    document?.documentElement?.style?.setProperty(
      "--ql-font-family",
      `${fontFamily}, sans-serif`
    );
  }

  function parseFormQuestionAndSetGeneratedFileName(form) {
    // const fromDBgeneratedFileName = form?.genratedFileName || [];
    // let questions = form?.formJson?.pages?.flatMap((page) => page.questions);
    // console.log({ questions });
    // let parsedGeneratePDFName = [];
    // fromDBgeneratedFileName?.map((id) => {
    //   let isQuestionFound = questions?.find((que) => que?.id == id);
    //   if (isQuestionFound) {
    //     parsedGeneratePDFName.push(isQuestionFound);
    //   } else {
    //     parsedGeneratePDFName.push({
    //       description: "",
    //       id: id,
    //       mandatory: false,
    //       required: false,
    //       title: "Deleted Question",
    //       titleHtml: "<p>Deleted Question</p>",
    //       type: "Text",
    //       value: "",
    //       isDeletedQuestion: true,
    //     });
    //   }
    // });
    // setGeneratedPDFName(parsedGeneratePDFName || []);
  }

  useEffect(() => {
    if (formState) {
      // console.log("formState", formState);
      setShow((prev) => ({ ...prev, onSaveMessage: "Saving..." }));
      debounceCallHandleSave(formState, owner, isRespondentEmailVerify);
    }
  }, [formState, owner, isRespondentEmailVerify]);

  useEffect(() => {
    if (formState) {
      setShow((prev) => {
        let isSumbitButton =
          formState?.pages?.[show.preview.currentPage]?.goToNextPage ===
          "submit"
            ? true
            : formState?.pages?.length - 2 === show.preview.currentPage &&
              formState?.pages[show.preview.currentPage]?.goToNextPage ===
                "next";

        let isNextButton =
          formState?.pages?.[show.preview.currentPage]?.goToNextPage !== "next"
            ? !isSumbitButton
            : formState?.pages?.length - 2 !== show.preview.currentPage;

        let isPreviousButton =
          formState?.pages?.[show.preview.currentPage]?.goToNextPage !== "next"
            ? true
            : formState?.pages?.length > 2;

        // console.log("isPreviousButton", isPreviousButton);

        return {
          ...prev,
          submitButton: isSumbitButton,
          nextButton: isNextButton,
          previousButton: isPreviousButton,
        };
      });
    }
  }, [formState, show.preview, active]);

  useEffect(() => {
    if (forms) {
      if (forms?.[0]?.formJson) {
        setFormState(forms?.[0]?.formJson);
        setQuestionTypes(getQuestionTypes(forms?.[0]?.formJson?.themeTemplate));
        setOtherComponents(
          getOtherComponents(forms?.[0]?.formJson?.themeTemplate)
        );
      } else {
        setFormState((prev) => {
          return {
            ...form,
            title: forms?.[0]?.name ?? "Untitled Form",
            description: forms?.[0]?.description ?? "No Description",
            logo:
              owner?.branding?.companyLogo ??
              prev?.logo ??
              "https://i.imgur.com/jvjNDSr.png",
            themeTemplate: "google-forms",
          };
        });
        setQuestionTypes(getQuestionTypes("default"));
        setOtherComponents(getOtherComponents("default"));
      }
      setIsApprovalWorkflowEnabled(
        forms?.[0]?.isApprovalWorkflowEnabled ?? true
      );
      setIsRespondentEmailVerify(forms?.[0]?.isRespondentEmailVerify ?? false);
      setIsArchive(forms?.[0]?.status === "ACTIVE");
      setIsNotificationEnabled(forms?.[0]?.notification);

      setIsGeneratePdfEnabled(forms?.[0]?.generateResponsePdf ?? false);

      setUpdateThankYouPageBranding(
        forms?.[0]?.formJson?.showThankYouPageBranding ?? false
      );

      setGenLang((prev) => (forms?.[0]?.lang?.key ? forms?.[0]?.lang : prev));
      // parseFormQuestionAndSetGeneratedFileName(forms?.[0]);
      setMixedTagsValue(forms?.[0]?.genratedFileName);
      // -----------------------------------------------------------------------

      //notion
      if (forms?.[0]?.ownerId?.integrationTokens?.notion) {
        getAllNotionDatabases();
      }
      setIntegrations((prev) => {
        return {
          ...prev,
          googleSheet: {
            ...prev.googleSheet,
            spreadsheetUrl: forms?.[0]?.spreadsheetUrl ?? "",
            enabled: forms?.[0]?.metafields?.googleSheet?.enabled ?? false,
          },
          googleDrive: {
            ...prev.googleDrive,
            parentFolderId: forms?.[0]?.metafields?.googleDrive?.parentFolderId,
            parentFolderName:
              forms?.[0]?.metafields?.googleDrive?.parentFolderName,
            parentFolderUrl:
              forms?.[0]?.metafields?.googleDrive?.parentFolderUrl,
            enabled: forms?.[0]?.metafields?.googleDrive?.enabled ?? false,
          },
          dropboxDrive: {
            ...prev.dropboxDrive,
            parentFolderId:
              forms?.[0]?.metafields?.dropboxDrive?.parentFolderId,
            parentFolderName:
              forms?.[0]?.metafields?.dropboxDrive?.parentFolderName,
            parentFolderUrl:
              forms?.[0]?.metafields?.dropboxDrive?.parentFolderUrl,
            enabled: forms?.[0]?.metafields?.dropboxDrive?.enabled ?? false,
          },
          msOneDrive: {
            ...prev.msOneDrive,
            enabled: forms?.[0]?.metafields?.msOneDrive?.enabled ?? false,
            parentFolderId: forms?.[0]?.metafields?.msOneDrive?.parentFolderId,
            parentFolderName:
              forms?.[0]?.metafields?.msOneDrive?.parentFolderName,
            parentFolderUrl:
              forms?.[0]?.metafields?.msOneDrive?.parentFolderUrl,
            parentFolderDriveId:
              forms?.[0]?.metafields?.msOneDrive?.parentFolderDriveId,
          },
        };
      });

      setIntegrationsOptions((prev) => {
        let metafields = forms?.[0]?.metafields ?? {};
        return prev?.map((option) => ({
          ...option,
          enabled: metafields[option.type]?.enabled ?? false,
        }));
      });

      setReceivesACopy((prev) => {
        return forms?.[0]?.responseReceivers ?? [];
      });

      // getResponsesSummaryCharts
    }
  }, [forms]);

  useEffect(() => {
    if (formState) {
      switch (formState?.prefillSource) {
        case "ZENDESK":
          setPrefillOptions(zendeskPrefillQuestionsOptions);
          break;

        case "GOOGLE_SHEETS":
          googleSheetsPrefillSettings();
          break;
        default:
          setPrefillOptions([]);
          break;
      }
    }
  }, [formState?.prefillSource]);

  useEffect(() => {
    if (formState) {
      setQuestionTypes(getQuestionTypes(formState?.themeTemplate));
      setOtherComponents(getOtherComponents(formState?.themeTemplate));
      setQuilleditorFontFamily(formState?.fontFamily);
    }
  }, [formState?.themeTemplate, formState]);

  useEffect(() => {
    fetchOwner();
    (async () => {
      setIsFormUser(await isFormCustomer());
    })();

    document.addEventListener("mousedown", handleTopBarMoreOptionsOutsideClick);
    document.addEventListener("mousedown", handleProfileOptionsOutsideClick);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleTopBarMoreOptionsOutsideClick
      );
      document.removeEventListener(
        "mousedown",
        handleProfileOptionsOutsideClick
      );
    };
  }, []);

  useEffect(() => {
    let questionsToAddInName = [];
    let mixedSettingsWhiteList = [];
    formState?.pages?.map((page, pgIdx) => {
      page?.questions?.map((question, queIdx) => {
        if (
          question?.type == "Text" ||
          question?.type == "RadioGroup" ||
          question?.type == "Dropdown" ||
          question?.type == "Date" ||
          question?.type == "Time"
        ) {
          questionsToAddInName.push(question);
          mixedSettingsWhiteList.push({
            id: question?.id,
            value: question?.title,
            title: question?.title,
          });
        }
      });
    });
    setMixedTagsSettings((prev) => ({
      ...prev,
      whitelist: mixedSettingsWhiteList,
    }));
    setGeneratedPDFNameQuestions(questionsToAddInName);
  }, [formState]);

  useEffect(() => {
    checkFeatures();
  }, [owner]);

  const compressImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1200,
        useWebWorker: true,
      };

      // const blob = base64toBlob(file);
      const compressedBlob = await imageCompression(file, options);
      return compressedBlob;
    } catch (error) {
      console.log("Error compressing image: ", error);
    }
  };

  if (!formState) {
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-white z-50 flex justify-center items-center">
        <BoloLoader />
      </div>
    );
  }
  const updateEmailValues = (newValues) => {
    setSendACopyInputs(newValues);
  };

  return (
    <div className="bg-white flex flex-row h-screen">
      <Helmet>
        <title translate="no">Edit Form</title>
        <meta charSet="utf-8" />
        {/* <!-- Event snippet for Form Created conversion page --> */}
        <meta name="title" content="Edit Form" />
        <meta
          name="description"
          content="Seamlessly modify and tailor your forms on the Edit Form page for a personalized and refined user experience."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/create/forms`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Edit Form - Boloforms`} />
        <meta
          property="og:description"
          content="Seamlessly modify and tailor your forms on the Edit Form page for a personalized and refined user experience."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/create/forms"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
        <script>
          {` gtag('event', 'conversion', {'send_to': 'AW-11249137482/AjERCLuJ_roYEMrugPQp'});`}
        </script>
      </Helmet>
      <div className="w-full h-full">
        {showUpgradeModal && (
          <PleaseUpgradeModal
            show={showUpgradeModal}
            setShow={setShowUpgradeModal}
            data={upgradeModalData}
            blockingType={upgradeModalType}
          />
        )}
        <div className="w-full h-[110px] flex flex-col text-sm border-b border-[#dadce0]">
          <div className="w-full h-[66px] pt-1.5 flex ">
            <div className="w-full pl-2.5 flex items-center">
              <div className="h-full">
                <div
                  data-tooltip-id="boloformsHome"
                  data-tooltip-offset={0}
                  onClick={() => navigate("/dashboard/forms")}
                  className="w-[56px] h-[56px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                >
                  <img
                    src={
                      whiteLabelInfo?.settings?.organization?.logo ||
                      IMAGE_LINKS.BOLOLOGO
                    }
                    alt="logo"
                    className="h-[40px] w-[40px]"
                  />{" "}
                </div>
              </div>
              <CustomTooltip
                helpers={{
                  id: "boloformsHome",
                  content: `${
                    whiteLabelInfo?.settings?.organization?.name || "BoloForms"
                  } Home`,
                  place: "bottom",
                }}
              />

              <div className="max-w-[175px] truncate mx-2.5 text-lg py-0.5">
                {formState?.title}
              </div>
              {/* <div
                onClick={() => {
                  window.open(`${getDashboardUrl()}/forms/settings`, "_blank");
                }}
                className="ml-4 mr-2 w-[28px] h-[28px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <MdOutlineSettings className="text-2xl text-gray-600" />
              </div> */}
              {/* <div className="mr-2 w-[28px] h-[28px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full">
                <LiaStar className="text-2xl text-gray-600" />
              </div> */}
              <div
                className="mx-4 text-xs text-gray-500 font-light"
                data-tooltip-id="onSaveMessage"
                data-tooltip-offset={10}
              >
                {show?.onSaveMessage}
              </div>
              <CustomTooltip
                helpers={{
                  id: "onSaveMessage",
                  content: "Every change you make is automatically saved",
                  place: "bottom",
                }}
              />
            </div>
            <div className="px-2 flex">
              {/* <div
                onClick={() => {
                  window.open(
                    `${getDashboardUrl()}/forms/settings/approval-flow?id=${formId}`,
                    "_blank"
                  );
                }}
                className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <RiFlowChart className="text-2xl text-gray-600" />
              </div> */}
              <div
                data-tooltip-id="customizeTheme"
                data-tooltip-offset={0}
                onClick={() => {
                  setShow((prev) => {
                    return {
                      ...prev,
                      rightSidebar: !prev.rightSidebar,
                    };
                  });
                }}
                className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <MdOutlineColorLens className="text-2xl text-gray-600" />
              </div>
              <CustomTooltip
                helpers={{
                  id: "customizeTheme",
                  content: "Customize Theme",
                  place: "bottom",
                }}
              />

              <div
                data-tooltip-id="preview"
                data-tooltip-offset={0}
                onClick={() => {
                  window.open(
                    `${getFormViewerUrl()}/${formId}?p=view`,
                    "_blank"
                  );
                }}
                className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <FiEye className="text-2xl text-gray-600" />
              </div>
              <CustomTooltip
                helpers={{
                  id: "preview",
                  content: "Preview",
                  place: "bottom",
                }}
              />

              <button
                data-tooltip-id="undo"
                data-tooltip-offset={0}
                onClick={() => undoDoc()}
                disabled={!canUndo}
                className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <LuUndo2 className="text-2xl text-gray-600" />
              </button>
              <CustomTooltip
                helpers={{
                  id: "undo",
                  content: "Undo",
                  place: "bottom",
                }}
              />

              <button
                data-tooltip-id="redo"
                data-tooltip-offset={0}
                onClick={() => redoDoc()}
                disabled={!canRedo}
                className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <LuRedo2 className="text-2xl text-gray-600" />
              </button>
              <CustomTooltip
                helpers={{
                  id: "redo",
                  content: "Redo",
                  place: "bottom",
                }}
              />

              <div className="h-[48px] mx-2.5 flex items-center">
                <button
                  style={{
                    background: formState.accentColor ?? COLORS.primary,
                  }}
                  onClick={() => {
                    new MixPanel().track("Form", {
                      form_action: "form_customize_pdf",
                      paid: false,
                    });
                    new MixPanel().increment("form_customize_pdf");
                    navigate(
                      !forms?.[0]?.pdfTemplate
                        ? `/create/form/pdf?id=${formId}`
                        : `/create/pdf-mapping?id=${formId}`
                    );
                  }}
                  className="font-light px-6 h-[36px] text-white flex justify-center items-center hover:bg-opacity-90 cursor-pointer rounded whitespace-nowrap"
                >
                  {!forms?.[0]?.pdfTemplate ? "Customize Pdf" : "Map to PDF"}
                </button>
              </div>
              <div className="h-[48px] mx-2.5 flex items-center">
                {/* send funtion */}
                <button
                  style={{
                    border: `2px solid ${
                      formState.accentColor ?? COLORS.primary
                    }`,
                    color: formState.accentColor ?? COLORS.primary,
                  }}
                  onClick={
                    () =>
                      setShow((prev) => ({
                        ...prev,
                        sendModal: !prev.sendModal,
                      }))
                    // setShow((prev) => ({
                    //   ...prev,
                    //   trialExtendedModal: !prev.trialExtendedModal,
                    // }))
                  }
                  className=" px-6 h-[36px] flex justify-center items-center hover:bg-opacity-90 cursor-pointer rounded"
                >
                  Send
                </button>
              </div>
              <div ref={topBarMoreOptionsRef} className="h-full relative">
                <div
                  data-tooltip-id="more"
                  data-tooltip-offset={0}
                  onClick={() =>
                    setShow((prev) => ({
                      ...prev,
                      topBarMoreOptions: !prev.topBarMoreOptions,
                    }))
                  }
                  className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                >
                  <BsThreeDotsVertical className="text-xl text-gray-600" />
                </div>
                <CustomTooltip
                  helpers={{
                    id: "more",
                    content: "More",
                    place: "bottom",
                  }}
                />

                {show.topBarMoreOptions && (
                  <TopBarMoreOptions helpers={{ formId, setShow, forms }} />
                )}
              </div>

              <div ref={profileOptionsRef} className="h-full relative">
                <div
                  onClick={() =>
                    setShow((prev) => ({
                      ...prev,
                      profileOptions: !prev.profileOptions,
                    }))
                  }
                  className="w-[48px] h-[48px] p-1 flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                >
                  <div className="p-1">
                    <img
                      className="rounded-full h-full w-full"
                      src={getProfilePhoto()}
                      // "https://lh3.googleusercontent.com/-k9LulbvKDAk/AAAAAAAAAAI/AAAAAAAAAAA/AJIwbgbk48gQfk00wC2ZiFTqti52FvSy-w/photo.jpg?sz=46"
                      alt="user"
                      referrerPolicy="no-referrer"
                    />
                  </div>
                </div>
                {show.profileOptions && (
                  <ProfileOptions helpers={{ setShow }} />
                )}
              </div>

              {/* <div className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full">
                <BsThreeDotsVertical className="text-xl text-gray-600" />
              </div> */}
            </div>
          </div>
          <div className="h-[36px] mt-2 flex justify-center">
            <div
              onClick={() =>
                setActive((prev) => ({ ...prev, tab: "questions" }))
              }
              style={{
                color:
                  active.tab === "questions"
                    ? formState?.accentColor
                    : "#3C4043",
              }}
              className={`relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
            >
              <span className="px-3">Questions</span>
              {active.tab === "questions" && (
                <div
                  style={{
                    background: formState?.accentColor,
                  }}
                  className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
                ></div>
              )}
            </div>
            <div
              onClick={() =>
                setActive((prev) => ({ ...prev, tab: "responses" }))
              }
              style={{
                color:
                  active.tab === "responses"
                    ? formState?.accentColor
                    : "#3C4043",
              }}
              className={`relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
            >
              <span className="px-3">Responses</span>
              {active.tab === "responses" && (
                <div
                  style={{
                    background: formState?.accentColor,
                  }}
                  className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
                ></div>
              )}
            </div>
            <div
              onClick={() =>
                setActive((prev) => ({ ...prev, tab: "settings" }))
              }
              style={{
                color:
                  active.tab === "settings"
                    ? formState?.accentColor
                    : "#3C4043",
              }}
              className={` relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
            >
              <span className="px-3">Settings</span>
              {active.tab === "settings" && (
                <div
                  style={{
                    background: formState?.accentColor,
                  }}
                  className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
                ></div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            height: "calc(100% - 110px)",
            background: formState?.backgroundColor ?? "#f0ebf8",
          }}
          className="relative"
        >
          {/* editor  */}
          <div
            ref={editorContainerRef}
            style={{
              display: active.tab === "questions" ? "block" : "none",
              fontFamily: `${formState.fontFamily}, sans-serif`,
            }}
            className="h-full overflow-y-auto pb-[64px]"
          >
            <div className="max-w-[768px] mx-auto relative">
              <AddQuestionFloater
                helpers={{
                  editorContainerRef,
                  formState,
                  setFormState,
                  active,
                  setActive,
                  pagesRef,
                }}
              />
            </div>
            <div translate="no" className="pt-3">
              {formState?.headerImage && (
                <div className="max-w-[768px] max-h-[192px] mx-auto rounded-lg overflow-hidden">
                  <img
                    className="w-full h-full object-cover rounded-lg"
                    style={{
                      aspectRatio: 4 / 1,
                    }}
                    src={formState?.headerImage}
                    alt="Header"
                  />
                </div>
              )}

              <div className="mt-2 max-w-[768px] mx-auto">
                {formState?.pages?.length > 2 && (
                  <div className="h-[36px]">
                    <div
                      style={{
                        background: formState.accentColor ?? COLORS.primary,
                      }}
                      className="border-x text-sm w-fit h-full text-white flex items-center px-4 rounded-t-lg"
                    >
                      Section 1 of {formState?.pages?.length - 1}
                    </div>
                  </div>
                )}
                <div
                  onClick={() => {
                    setActive((prev) => ({
                      ...prev,
                      pageIndex: 0,
                      questionIndex: null,
                    }));
                  }}
                  className={`${
                    formState?.pages?.length > 2
                      ? "border-x border-b rounded-tr-lg rounded-b-lg"
                      : "rounded-lg border"
                  } bg-white border-[#dadce0] overflow-hidden cursor-pointer`}
                >
                  <div
                    style={{
                      background: formState.accentColor ?? COLORS.primary,
                    }}
                    className="h-2.5"
                  ></div>
                  <div className="flex flex-row h-full">
                    <div
                      className={`${
                        active.pageIndex === 0 &&
                        active.questionIndex === null &&
                        "bg-blue-500"
                      } min-w-[6px] flex flex-col mr-2.5`}
                    ></div>
                    <div className="pl-2 pt-3 pb-5 pr-6 w-full flex flex-row items-start justify-between">
                      <div className="w-[85%] flex flex-col">
                        <FormTitleInput helpers={{ formState, setFormState }} />
                        <FormDescInput helpers={{ formState, setFormState }} />
                      </div>
                      <div className="ml-4 group max-h-20 min-w-20 cursor-pointer bg-white border shadow-sm rounded relative overflow-hidden">
                        <input
                          type="file"
                          name="editFormLogoFile"
                          id="editFormLogoFile"
                          onChange={async (e) => {
                            // const isFormBrandingEnabled =
                            //   await isFeatureEnabled("FORM_BRANDING");
                            // if (!isFormBrandingEnabled) {
                            //   setShowUpgradeModal(true);
                            //   setUpgradeModalData({
                            //     title:
                            //       "Form Branding is available in Growing Business Plan and above",
                            //     desc: `You are on ${currentPlan} upgrade to use this feature.`,
                            //   });
                            //   return;
                            // }
                            // if (
                            //   owner?.planhistory[owner?.planhistory?.length - 1]
                            //     ?.planId === "FREE_PLAN"
                            // ) {
                            //   toast.error(
                            //     "Please Upgrade your plan to use this feature"
                            //   );
                            // } else {
                            const compressedImage = await compressImage(
                              e.target.files[0]
                            );
                            let base64Image = await fileToBase64(
                              compressedImage
                            );
                            const response = await axios.post(
                              `/forms/upload-to-aws`,
                              { base64Image }
                            );
                            const logoUrl = response?.data?.imageUrl;
                            setFormState((prevState) => {
                              const newForm = { ...prevState };
                              newForm.logo = logoUrl;
                              return newForm;
                            });
                            // }
                          }}
                          // accept="image/*"
                          accept=".jpg, .jpeg, .png"
                          className="hidden"
                        />
                        <label
                          htmlFor="editFormLogoFile"
                          className="cursor-pointer"
                        >
                          <img
                            src={formState?.logo}
                            alt="company logo"
                            className="max-h-20 object-cover"
                          />
                        </label>
                        <div className="absolute h-[40px] w-full bottom-0 left-0 flex justify-center items-center bg-gray-700 bg-opacity-60 mt-6 pointer-events-none">
                          <MdImage className="text-white text-2xl group-hover:text-gray-100 opacity-70" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 pb-[64px] max-w-[768px] mx-auto relative">
                {/* <AddQuestionFloater
                  helpers={{
                    editorContainerRef,
                    formState,
                    setFormState,
                    active,
                    setActive,
                    pagesRef,
                  }}
                /> */}

                {formState?.pages?.map((page, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setActive((prev) => ({
                          ...prev,
                          pageIndex: index,
                        }));
                      }}
                      ref={(el) => (pagesRef.current[index] = el)}
                      // className={`bg-white rounded-md p-4 mb-4 border-2 ${
                      //   active.pageIndex === index && "border-primary"
                      // } ${page.questions.length === 0 && "opacity-60"}`}
                    >
                      {index !== 0 &&
                        index !== formState?.pages?.length - 1 &&
                        formState?.pages?.length > 2 && (
                          <PageSection
                            helpers={{
                              index,
                              page,
                              formState,
                              setFormState,
                              active,
                              setActive,
                            }}
                          />
                        )}

                      <div className="mt-4">
                        {page.questions.map((question, qIndex) => {
                          return (
                            <div
                              key={question.id || qIndex}
                              onClick={() => {
                                setActive((prev) => ({
                                  ...prev,
                                  questionIndex: qIndex,
                                }));
                              }}
                              style={{
                                boxShadow:
                                  active.pageIndex === index &&
                                  active.questionIndex === qIndex
                                    ? "0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122)"
                                    : "none",
                              }}
                              className="mt-3 bg-white rounded-lg border border-[#dadce0] cursor-pointer"
                            >
                              <div className="flex flex-row h-full">
                                <div
                                  className={`${
                                    active.pageIndex === index &&
                                    active.questionIndex === qIndex
                                      ? "bg-blue-500"
                                      : ""
                                  } min-w-[6px] flex flex-col mr-2.5 rounded-l-lg`}
                                ></div>
                                <div className="pl-2 px-6 w-full flex flex-col items-center">
                                  <div className="h-6 flex items-center">
                                    {active.pageIndex === index &&
                                      active.questionIndex === qIndex && (
                                        <MdDragIndicator
                                          data-tooltip-id="shuffle"
                                          data-tooltip-offset={2}
                                          onClick={() => {
                                            setActive((prev) => ({
                                              ...prev,
                                              sorting: "questions",
                                            }));
                                          }}
                                          onDrag={() => {
                                            setActive((prev) => ({
                                              ...prev,
                                              sorting: "questions",
                                            }));
                                          }}
                                          className="text-gray-400 text-[22px] rotate-90"
                                        />
                                      )}
                                    <CustomTooltip
                                      helpers={{
                                        id: "shuffle",
                                        content: "Shuffle",
                                        place: "top",
                                      }}
                                    />
                                  </div>
                                  {getQuestionTypes().map((questionType, i) => {
                                    if (questionType.name === question.type) {
                                      return questionType.component({
                                        question,
                                        setFormState,
                                        isEditor: true,
                                        questionIndex: {
                                          p: index,
                                          q: qIndex,
                                        },
                                        formState,
                                        activeState: active,
                                        setActiveState: setActive,
                                        setActive,
                                        schemas,
                                      });
                                    }
                                    return null;
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* go to section  */}
                      {index < formState?.pages?.length - 2 &&
                        formState?.pages?.length > 2 && (
                          <GoogleStyleNextPageDropdown
                            helpers={{
                              formState,
                              page,
                              onChange: (value) =>
                                handleGoToPageChange(value, index),
                              index,
                            }}
                          />
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* responses  */}
          <div
            style={{
              display: active.tab === "responses" ? "block" : "none",
            }}
            className="h-full"
          >
            <div
              translate="no"
              className="pt-3 pb-[64px] h-full overflow-y-auto"
            >
              <div className="max-w-[768px] mx-auto">
                <div
                  className={`rounded-lg border bg-white border-[#dadce0] overflow-hidden`}
                >
                  <div className="flex flex-col h-full">
                    <div className="pl-6 pt-4 pr-2 w-full flex flex-row justify-between items-center">
                      <div className="fade w-full flex justify-between items-center">
                        <span className="text-gray-700 text-2xl">
                          {respondentCount ?? 0} responses
                        </span>

                        {!whiteLabelHelper?.isWhiteLabel && (
                          <>
                            {isIntegrationPermissionAvailable("googleSheet") ||
                            forms?.[0]?.ownerId?._id !== owner?._id ? (
                              <div>
                                {integrations?.googleSheet?.spreadsheetUrl ||
                                forms?.[0]?.destinationId ? (
                                  <>
                                    <div
                                      data-tooltip-id="viewInSheets"
                                      data-tooltip-offset={0}
                                      className={`bg-white hover:bg-[#f8fbff] rounded-md px-4 py-2 w-fit flex items-center justify-center gap-x-2 cursor-pointer mr-4`}
                                      onClick={() => {
                                        window.open(
                                          integrations?.googleSheet
                                            ?.spreadsheetUrl ||
                                            `https://docs.google.com/spreadsheets/d/${forms?.[0]?.destinationId}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <BsFileEarmarkSpreadsheetFill className="text-xl text-green-600" />
                                      <span className="text-sm font-medium text-[#1a73e8]">
                                        View in Sheets
                                      </span>
                                    </div>
                                    <CustomTooltip
                                      helpers={{
                                        id: "viewInSheets",
                                        content: "Opens linked spreadsheet",
                                        place: "bottom",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Fragment>
                                    {forms?.[0]?.ownerId?._id === owner._id && (
                                      <>
                                        <div
                                          data-tooltip-id="linkToSheets"
                                          data-tooltip-offset={0}
                                          className={`bg-white hover:bg-[#f8fbff] rounded-md px-4 py-2 w-fit flex items-center justify-center gap-x-2 cursor-pointer mr-4`}
                                          onClick={() => {
                                            setShow((prev) => ({
                                              ...prev,
                                              googleSheetModal:
                                                !prev.googleSheetModal,
                                            }));

                                            new MixPanel().track("Form", {
                                              form_action:
                                                "form_link_google_sheet",
                                              paid: false,
                                            });
                                            new MixPanel().increment(
                                              "form_link_google_sheet"
                                            );
                                          }}
                                        >
                                          <BsFileEarmarkSpreadsheetFill className="text-xl text-green-600" />
                                          <span className="text-sm font-medium text-[#1a73e8]">
                                            Link to Sheets
                                          </span>
                                        </div>
                                        <CustomTooltip
                                          helpers={{
                                            id: "linkToSheets",
                                            content:
                                              "Sends responses to a spreadsheet",
                                            place: "bottom",
                                          }}
                                        />
                                      </>
                                    )}
                                  </Fragment>
                                )}
                              </div>
                            ) : (
                              <Fragment>
                                {forms?.[0]?.ownerId?._id === owner._id && (
                                  <div
                                    className={`bg-gray-200 rounded-md px-4 py-2 w-fit flex items-center justify-center gap-x-2 cursor-not-allowed mr-4`}
                                    onClick={() => {
                                      new MixPanel().track("Form", {
                                        form_action: "form_link_google_sheet",
                                      });
                                      new MixPanel().increment(
                                        "form_link_google_sheet"
                                      );
                                      toast.error(
                                        "Connect to Google Sheets in Integrations Section"
                                      );
                                    }}
                                    disabled
                                  >
                                    <BsFileEarmarkSpreadsheetFill className="text-xl text-gray-400" />
                                    <span className="text-sm font-medium text-gray-400">
                                      Link to Sheets
                                    </span>
                                  </div>
                                )}
                              </Fragment>
                            )}
                          </>
                        )}
                      </div>
                      <div className="h-[48px]"></div>
                    </div>
                    <div className="fade py-2 px-6 flex justify-between items-center">
                      <div>
                        <button
                          onClick={() => {
                            window.open(
                              `${getDashboardUrlV1()}/form?id=${formId}`,
                              "_blank"
                            );
                          }}
                          style={{
                            color: formState?.accentColor,
                          }}
                          className="h-[36px] px-3 text-xs rounded border flex items-center justify-center cursor-pointer hover:border-gray-300"
                        >
                          View Responses
                        </button>
                      </div>
                      <div className="fade h-[48px] w-fit flex items-center ml-auto">
                        <span className="text-xs text-gray-500 mr-2">
                          Accepting responses{" "}
                        </span>
                        <span>
                          {" "}
                          <PurpleSwitch
                            {...{
                              inputProps: {
                                accentColor: formState?.accentColor,
                                "aria-label": "Switch demo",
                              },
                            }}
                            checked={isArchive}
                            onChange={(e) => {
                              new MixPanel().track("Form", {
                                form_action: "form_accept_response",
                                paid: false,
                              });
                              new MixPanel().increment("form_accept_response");
                              archiveForm(e.target.checked);
                            }}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="w-full h-[36px] mt-2 flex justify-center">
                      <div className="w-full flex justify-center">
                        <div
                          onClick={() => {
                            new MixPanel().track("Form", {
                              form_action: "form_summary_of_responses",
                              paid: true,
                            });
                            new MixPanel().increment(
                              "form_summary_of_responses"
                            );
                            setActive((prev) => ({
                              ...prev,
                              responses: { tab: "summary" },
                            }));
                          }}
                          style={{
                            color:
                              active.responses.tab === "summary"
                                ? formState?.accentColor
                                : "#3C4043",
                          }}
                          className={`relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
                        >
                          <span className="px-3">Summary</span>
                          {active.responses.tab === "summary" && (
                            <div
                              style={{
                                background: formState?.accentColor,
                              }}
                              className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
                            ></div>
                          )}
                        </div>
                      </div>
                      <div className="w-full flex justify-center">
                        <div
                          onClick={() =>
                            setActive((prev) => ({
                              ...prev,
                              responses: { tab: "integrations" },
                            }))
                          }
                          style={{
                            color:
                              active.responses.tab === "integrations"
                                ? formState?.accentColor
                                : "#3C4043",
                          }}
                          className={`relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
                        >
                          <span className="px-3">Integrations</span>
                          {active.responses.tab === "integrations" && (
                            <div
                              style={{
                                background: formState?.accentColor,
                              }}
                              className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
                            ></div>
                          )}
                        </div>
                      </div>
                      {/* <div className="w-full flex justify-center">
                        <div
                          onClick={() =>
                            setActive((prev) => ({ ...prev, responses:{tab: "summary"} }))
                          }
                          style={{
                            color:
                              active.responses.tab === "settings"
                                ? formState?.accentColor
                                : "#3C4043",
                          }}
                          className={`relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
                        >
                          <span className="px-3">Settings</span>
                          {active.responses.tab === "settings" && (
                            <div
                              style={{
                                background: formState?.accentColor,
                              }}
                              className="absolute bottom-0 w-full h-[3px] rounded-t-xl"
                            ></div>
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {active.responses.tab === "integrations" && (
                  <div
                    className={`mt-3 rounded-lg border bg-white border-[#dadce0] overflow-hidden`}
                  >
                    <div className="flex-col h-full">
                      <div className="pt-6 pb-4 px-6  fade">
                        <h2 className="font-medium mb-1 ">Integrations</h2>
                        <div className="mb-6">
                          <p className="text-xs text-gray-500">
                            Connect your form to your favorite apps and
                            services.
                          </p>
                          <a
                            className="text-blue-500 text-xs underline"
                            href="https://help.boloforms.com/en/collections/6930837-integrations"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn More
                          </a>
                        </div>

                        {active.integrationSidebar === "list" ? (
                          integrationOptions?.map((option, i) => {
                            if (
                              whiteLabelHelper?.isWhiteLabel &&
                              option.type == "googleSheet"
                            ) {
                              return null;
                            }
                            return (
                              <div
                                key={i}
                                onClick={() => {
                                  setActive((prevState) => {
                                    const newState = { ...prevState };
                                    newState.integrationSidebar = option.type;
                                    return newState;
                                  });
                                }}
                                className="border rounded-md bg-white px-4 py-2 w-full flex justify-between items-center mb-3 cursor-pointer"
                              >
                                <div className="flex items-center">
                                  <div className="mr-2 flex justify-center items-center rounded-md w-10 h-10 p-1">
                                    {option.type === "googleDrive" ? (
                                      <img
                                        src="https://i.imgur.com/DzE0cSv.png"
                                        alt="Google Drive"
                                      />
                                    ) : option.type === "msOneDrive" ? (
                                      <img
                                        src="https://img.icons8.com/?size=100&id=117559&format=png&color=000000"
                                        alt="onedrive-logo"
                                        className="w-[20px] h-[20px]"
                                      />
                                    ) : (
                                      option.icon
                                    )}
                                  </div>
                                  <div className="">
                                    <h2 className="text-sm font-medium mb-1 text-gray-700">
                                      {option.name}
                                    </h2>
                                    <p className="text-xs text-gray-600 truncate w-[250px]">
                                      {option.desc}
                                    </p>
                                    <p className="text-[10px] text-gray-600">
                                      {whiteLabelHelper?.isWhiteLabel
                                        ? "Powered By BoloForms"
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                {integrations[option?.type]?.enabled && (
                                  <FaCheckCircle className="text-green-500 text-xl" />
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <Fragment>
                            <div className="flex justify-between items-center w-full mb-4">
                              <div>
                                <h2 className="mb-1 text-sm font-semibold text-gray-700">
                                  {
                                    integrationOptions.find(
                                      (option) =>
                                        option.type ===
                                        active.integrationSidebar
                                    )?.name
                                  }
                                </h2>
                                <p className="text-xs text-gray-500">
                                  {
                                    integrationOptions.find(
                                      (option) =>
                                        option.type ===
                                        active.integrationSidebar
                                    )?.desc
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <button
                                onClick={() => {
                                  setActive((prevState) => {
                                    const newState = { ...prevState };
                                    newState.integrationSidebar = "list";
                                    return newState;
                                  });
                                }}
                                className="flex items-center"
                              >
                                <AiOutlineRollback className="text-gray-700 text-2xl hover:text-primary" />
                              </button>
                              <div
                                style={{
                                  display: isIntegrationPermissionAvailable(
                                    active.integrationSidebar
                                  )
                                    ? "flex"
                                    : "none",
                                }}
                                className="text-xs sm:text-sm flex items-center justify-center sm:justify-start mr-[2px]"
                              >
                                <div className="flex justify-between">
                                  {noPlanError &&
                                    active.integrationSidebar !==
                                      "googleSheet" && (
                                      <div className="h-[40px] flex flex-row ml-4 items-center text-xs text-center bg-red-200 text-red-600 rounded-md p-2">
                                        You can't use Goole Integration on the
                                        free plan{" "}
                                        <span
                                          className="font-semibold underline cursor-pointer ml-2"
                                          onClick={() => {
                                            navigate("/billings");
                                          }}
                                        >
                                          Upgrade now
                                        </span>
                                        <MdClose
                                          onClick={() => setNoPlanError(false)}
                                          className="text-lg cursor-pointer inline-block ml-4"
                                        />
                                      </div>
                                    )}
                                  {((active.integrationSidebar ===
                                    "googleSheet" &&
                                    (integrations?.googleSheet
                                      ?.spreadsheetUrl ||
                                      forms?.[0]?.destinationId)) ||
                                    active.integrationSidebar !==
                                      "googleSheet") && (
                                    <PurpleSwitch
                                      {...{
                                        inputProps: {
                                          accentColor: formState?.accentColor,
                                          "aria-label": "Switch demo",
                                        },
                                      }}
                                      checked={
                                        integrations[active.integrationSidebar]
                                          ?.enabled
                                      }
                                      disabled={
                                        forms?.[0]?.ownerId?._id !== owner._id
                                      }
                                      onChange={async (e) => {
                                        if (
                                          active.integrationSidebar ===
                                          "dropboxDrive"
                                        ) {
                                          const isDriveIntegrationsEnabled =
                                            await isFeatureEnabled(
                                              "DROPBOX_INTEGRATION"
                                            );
                                          if (!isDriveIntegrationsEnabled) {
                                            setShowUpgradeModal(true);
                                            setUpgradeModalType(
                                              "DROPBOX_INTEGRATION"
                                            );
                                            return;
                                          }
                                          if (
                                            !integrations?.dropboxDrive
                                              ?.parentFolderId
                                          ) {
                                            toast.error(
                                              "Please select a Folder first!"
                                            );
                                            return;
                                          }
                                          handleSubmit({
                                            enabled:
                                              !integrations?.dropboxDrive
                                                ?.enabled,
                                            parentFolderId:
                                              integrations?.dropboxDrive
                                                ?.parentFolderId,
                                            parentFolderName:
                                              integrations?.dropboxDrive
                                                ?.parentFolderName,
                                            parentFolderUrl:
                                              integrations?.dropboxDrive
                                                ?.parentFolderUrl,
                                          });
                                          return;
                                        }
                                        if (
                                          active.integrationSidebar ===
                                          "msOneDrive"
                                        ) {
                                          const isDriveIntegrationsEnabled =
                                            await isFeatureEnabled(
                                              "ONEDRIVE_INTEGRATION"
                                            );
                                          if (!isDriveIntegrationsEnabled) {
                                            setShowUpgradeModal(true);
                                            setUpgradeModalType(
                                              "ONEDRIVE_INTEGRATION"
                                            );
                                            return;
                                          }
                                          if (
                                            !integrations?.msOneDrive
                                              ?.parentFolderId
                                          ) {
                                            toast.error(
                                              "Please select a Folder first!"
                                            );
                                            return;
                                          }
                                          handleOneDriveSubmit(
                                            {
                                              id: integrations?.msOneDrive
                                                ?.parentFolderId,
                                              name: integrations?.msOneDrive
                                                ?.parentFolderName,
                                              driveId:
                                                integrations?.msOneDrive
                                                  ?.parentFolderDriveId,
                                              webUrl:
                                                integrations?.msOneDrive
                                                  ?.parentFolderUrl,
                                            },
                                            !integrations?.msOneDrive?.enabled
                                          );
                                          return;
                                        }
                                        await updateIntegrationStatus(
                                          e.target.checked,
                                          active.integrationSidebar
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                                {/* <label
                                className={`switch-button m-0 ${
                                  integrations[active.integrationSidebar]
                                    ?.enabled && "switch-button-checked"
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  className="w-4 h-4 cursor-pointer"
                                  checked={
                                    integrations[active.integrationSidebar]
                                      ?.enabled
                                  }integrationSidebar
                                  onChange={(e) =>
                                    updateIntegrationStatus(
                                      e.target.checked,
                                      active.integrationSidebar
                                    )
                                  }
                                />
                                <div></div>
                              </label> */}
                              </div>
                            </div>
                            {active.integrationSidebar === "googleSheet" && (
                              <div>
                                {isIntegrationPermissionAvailable(
                                  active.integrationSidebar
                                ) || forms?.[0]?.ownerId?._id !== owner?._id ? (
                                  <div className="mt-6">
                                    {integrations?.googleSheet
                                      ?.spreadsheetUrl ||
                                    forms?.[0]?.destinationId ? (
                                      <Fragment>
                                        <h2 className="mb-1 text-sm font-medium text-gray-700">
                                          Your form is connected to Google
                                          Sheets
                                        </h2>
                                        <p className="text-xs text-gray-500 mb-4">
                                          Your form responses will be saved to
                                          this spreadsheet.
                                        </p>
                                        <button
                                          className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                                          onClick={() => {
                                            window.open(
                                              integrations?.googleSheet
                                                ?.spreadsheetUrl ||
                                                `https://docs.google.com/spreadsheets/d/${forms?.[0]?.destinationId}`,
                                              "_blank"
                                            );
                                          }}
                                        >
                                          View Spreadsheet
                                        </button>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {forms?.[0]?.ownerId?._id !==
                                          owner._id && (
                                          <div className={``}>
                                            <h2 className="p-2 text-yellow-700 bg-yellow-200 text-sm rounded-md text-center">
                                              Contact the owner of this form to
                                              integrate with Google Sheets{" "}
                                            </h2>
                                          </div>
                                        )}
                                        {forms?.[0]?.ownerId?._id ===
                                          owner._id && (
                                          <div className={``}>
                                            <h2 className="mb-1 text-sm font-medium text-gray-700">
                                              Your form is not connected to
                                              Google Sheets
                                            </h2>
                                            <p className="text-xs text-gray-500 mb-4">
                                              Enable Google Sheets to create a
                                              spreadsheet for your form
                                              responses.
                                            </p>
                                          </div>
                                        )}
                                      </Fragment>
                                    )}
                                  </div>
                                ) : (
                                  <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
                                    {forms?.[0]?.ownerId?._id !== owner._id && (
                                      <div className={``}>
                                        <h2 className="p-2 text-yellow-700 bg-yellow-200 text-sm rounded-md text-center">
                                          Contact the owner of this form to
                                          integrate with Google Sheets{" "}
                                        </h2>
                                      </div>
                                    )}
                                    {forms?.[0]?.ownerId?._id === owner._id && (
                                      <div className={``}>
                                        <h2 className="mb-1 text-sm">
                                          Connect your Google Account
                                        </h2>
                                        <p className="text-xs text-gray-500 mb-4">
                                          Connect your Google Account to create
                                          a spreadsheet for your form responses.
                                        </p>
                                        <button
                                          className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                                          onClick={() => {
                                            // if (
                                            //   owner?.planhistory[
                                            //     owner?.planhistory.length - 1
                                            //   ]?.planId === "FREE_PLAN"
                                            // ) {
                                            //   toast.error(
                                            //     "You are on free plan. Please upgrade to use this feature."
                                            //   );
                                            //   setNoPlanError(true);
                                            //   return;
                                            // }
                                            window.open(
                                              `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=sheets&redirectUrl=/create/forms?id=${formId}&from=DASHBOARD`,
                                              "_self"
                                            );
                                          }}
                                        >
                                          Connect to Google Sheets
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {active.integrationSidebar === "googleDrive" && (
                              <div>
                                {isIntegrationPermissionAvailable(
                                  active.integrationSidebar
                                ) || forms?.[0]?.ownerId?._id !== owner?._id ? (
                                  <div className="mt-6">
                                    {integrations?.googleDrive
                                      ?.parentFolderName ? (
                                      <Fragment>
                                        <h2 className="mb-1 text-sm font-medium text-gray-700">
                                          Your form is connected to Google Drive
                                        </h2>
                                        <p className="text-xs text-gray-500 mb-4">
                                          Your form responses will be saved to
                                          this Drive Folder:{" "}
                                          <span
                                            className="text-primary font-semibold cursor-pointer"
                                            onClick={() => {
                                              window.open(
                                                integrations?.googleDrive
                                                  ?.parentFolderUrl,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            {
                                              integrations?.googleDrive
                                                ?.parentFolderName
                                            }
                                          </span>
                                        </p>
                                        <div className="w-full flex justify-between gap-4">
                                          <button
                                            disabled={
                                              forms?.[0]?.ownerId?._id !==
                                              owner?._id
                                            }
                                            className={`w-full border-2 border-primary bg-white inset-2 text-primary px-4 py-2 rounded-md text-xs mr-1 ${
                                              forms?.[0]?.ownerId?._id !==
                                              owner?._id
                                                ? "hidden"
                                                : ""
                                            } `}
                                            onClick={async () => {
                                              if (
                                                owner?.planhistory[
                                                  owner?.planhistory.length - 1
                                                ]?.planId === "FREE_PLAN"
                                              ) {
                                                setNoPlanError(true);
                                                return;
                                              }
                                              await handleDriveFolderPick();
                                            }}
                                          >
                                            Update Folder
                                          </button>
                                          <button
                                            className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                                            onClick={() => {
                                              window.open(
                                                integrations?.googleDrive
                                                  ?.parentFolderUrl,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            View Folder
                                          </button>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {forms?.[0]?.ownerId?._id !==
                                          owner._id && (
                                          <div className={``}>
                                            <h2 className="p-2 text-yellow-700 bg-yellow-200 text-sm rounded-md text-center">
                                              Contact the owner of this form to
                                              integrate with Google Drive{" "}
                                            </h2>
                                          </div>
                                        )}
                                        {forms?.[0]?.ownerId?._id ===
                                          owner._id && (
                                          <div className={``}>
                                            <h2 className="mb-1 text-sm font-medium text-gray-700">
                                              Your form is not connected to
                                              Google Drive
                                            </h2>
                                            <p className="text-xs text-gray-500 mb-4">
                                              Pick a folder to save your form
                                              responses as PDFs.
                                            </p>
                                            <button
                                              className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                                              onClick={async () => {
                                                if (
                                                  owner?.planhistory[
                                                    owner?.planhistory.length -
                                                      1
                                                  ]?.planId === "FREE_PLAN"
                                                ) {
                                                  setNoPlanError(true);
                                                  return;
                                                }
                                                await handleDriveFolderPick();
                                              }}
                                            >
                                              Select Folder
                                            </button>
                                          </div>
                                        )}
                                      </Fragment>
                                    )}
                                  </div>
                                ) : (
                                  <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
                                    {forms?.[0]?.ownerId?._id !== owner._id && (
                                      <div className={``}>
                                        <h2 className="p-2 text-yellow-700 bg-yellow-200 text-sm rounded-md text-center">
                                          Contact the owner of this form to
                                          integrate with Google Drive{" "}
                                        </h2>
                                      </div>
                                    )}
                                    {forms?.[0]?.ownerId?._id === owner._id && (
                                      <div className={``}>
                                        <h2 className="mb-1 text-sm">
                                          Connect your Google Account
                                        </h2>
                                        <p className="text-xs text-gray-500 mb-4">
                                          Connect your Google Account to save
                                          responses as PDF to your Google Drive.
                                        </p>
                                        <button
                                          className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                                          onClick={() => {
                                            new MixPanel().track("Form", {
                                              form_action:
                                                "form_link_google_drive",
                                              paid: false,
                                            });
                                            new MixPanel().increment(
                                              "form_link_google_drive"
                                            );
                                            window.open(
                                              `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/create/forms?id=${formId}&from=DASHBOARD`,
                                              "_self"
                                            );
                                          }}
                                        >
                                          Connect to Google Drive
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {active.integrationSidebar === "dropboxDrive" && (
                              <div>
                                {isIntegrationPermissionAvailable(
                                  active.integrationSidebar
                                ) || forms?.[0]?.ownerId?._id !== owner?._id ? (
                                  <div className="mt-6">
                                    {integrations?.dropboxDrive
                                      ?.parentFolderName ? (
                                      <Fragment>
                                        <h2 className="mb-1 text-sm font-medium text-gray-700">
                                          Your form is connected to Dropbox
                                        </h2>
                                        <p className="text-xs text-gray-500 mb-4">
                                          Your form responses will be saved to
                                          this Drive Folder:{" "}
                                          <span
                                            className="text-primary font-semibold cursor-pointer"
                                            onClick={() => {
                                              window.open(
                                                integrations?.dropboxDrive
                                                  ?.parentFolderUrl,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            {
                                              integrations?.dropboxDrive
                                                ?.parentFolderName
                                            }
                                          </span>
                                        </p>
                                        <div className="w-full flex justify-between gap-4">
                                          <button
                                            disabled={
                                              forms?.[0]?.ownerId?._id !==
                                              owner?._id
                                            }
                                            className={`w-full border-2 border-primary bg-white inset-2 text-primary px-4 py-2 rounded-md text-xs mr-1 ${
                                              forms?.[0]?.ownerId?._id !==
                                              owner?._id
                                                ? "hidden"
                                                : ""
                                            } `}
                                            onClick={async () => {
                                              const isDriveIntegrationsEnabled =
                                                await isFeatureEnabled(
                                                  "DROPBOX_INTEGRATION"
                                                );
                                              if (!isDriveIntegrationsEnabled) {
                                                setShowUpgradeModal(true);
                                                setUpgradeModalType(
                                                  "DROPBOX_INTEGRATION"
                                                );
                                                return;
                                              }
                                              setShowDropBoxChooser(true);
                                            }}
                                          >
                                            Update Folder
                                          </button>
                                          <button
                                            className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                                            onClick={() => {
                                              window.open(
                                                integrations?.dropboxDrive
                                                  ?.parentFolderUrl,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            View Folder
                                          </button>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {forms?.[0]?.ownerId?._id !==
                                          owner._id && (
                                          <div className={``}>
                                            <h2 className="p-2 text-yellow-700 bg-yellow-200 text-sm rounded-md text-center">
                                              Contact the owner of this form to
                                              integrate with Dropbox{" "}
                                            </h2>
                                          </div>
                                        )}
                                        {forms?.[0]?.ownerId?._id ===
                                          owner._id && (
                                          <div className={``}>
                                            <h2 className="mb-1 text-sm font-medium text-gray-700">
                                              Your form is not connected to
                                              Dropbox
                                            </h2>
                                            <p className="text-xs text-gray-500 mb-4">
                                              Pick a folder to save your form
                                              responses as PDFs.
                                            </p>
                                            <button
                                              className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                                              onClick={async () => {
                                                const isDriveIntegrationsEnabled =
                                                  await isFeatureEnabled(
                                                    "DROPBOX_INTEGRATION"
                                                  );
                                                if (
                                                  !isDriveIntegrationsEnabled
                                                ) {
                                                  setShowUpgradeModal(true);
                                                  setUpgradeModalType(
                                                    "DROPBOX_INTEGRATION"
                                                  );
                                                  return;
                                                }
                                                setShowDropBoxChooser(true);
                                              }}
                                            >
                                              Select Folder
                                            </button>
                                          </div>
                                        )}
                                      </Fragment>
                                    )}
                                  </div>
                                ) : (
                                  <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
                                    {forms?.[0]?.ownerId?._id !== owner._id && (
                                      <div className={``}>
                                        <h2 className="p-2 text-yellow-700 bg-yellow-200 text-sm rounded-md text-center">
                                          Contact the owner of this form to
                                          integrate with Dropbox{" "}
                                        </h2>
                                      </div>
                                    )}
                                    {forms?.[0]?.ownerId?._id === owner._id && (
                                      <div className={``}>
                                        <h2 className="mb-1 text-sm">
                                          Connect your Dropbox Account
                                        </h2>
                                        <p className="text-xs text-gray-500 mb-4">
                                          Connect your Dropbox account and save
                                          responses as PDFs to Dropbox.
                                        </p>
                                        <button
                                          className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                                          onClick={() => {
                                            new MixPanel().track("Form", {
                                              form_action:
                                                "form_link_google_drive",
                                              paid: false,
                                            });
                                            new MixPanel().increment(
                                              "form_link_google_drive"
                                            );
                                            window.open(
                                              `https://www.dropbox.com/oauth2/authorize?client_id=${dropboxClientID}&token_access_type=offline&response_type=code&state=${owner?.email};${window.location.pathname}&redirect_uri=${dropboxAuthRedirectUrl}`,
                                              "_self"
                                            );
                                          }}
                                        >
                                          Connect to Dropbox
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {active.integrationSidebar === "msOneDrive" && (
                              <div>
                                {isIntegrationPermissionAvailable(
                                  active.integrationSidebar
                                ) || forms?.[0]?.ownerId?._id !== owner?._id ? (
                                  <div className="mt-6">
                                    {integrations?.msOneDrive
                                      ?.parentFolderName ? (
                                      <Fragment>
                                        <h2 className="mb-1 text-sm font-medium text-gray-700">
                                          Your form is connected to OneDrive
                                        </h2>
                                        <p className="text-xs text-gray-500 mb-4">
                                          Your form responses will be saved to
                                          this Drive Folder:{" "}
                                          <span
                                            className="text-primary font-semibold cursor-pointer"
                                            onClick={() => {
                                              window.open(
                                                integrations?.msOneDrive
                                                  ?.parentFolderUrl,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            {
                                              integrations?.msOneDrive
                                                ?.parentFolderName
                                            }
                                          </span>
                                        </p>
                                        <div className="w-full flex justify-between gap-4">
                                          <button
                                            disabled={
                                              forms?.[0]?.ownerId?._id !==
                                              owner?._id
                                            }
                                            className={`w-full border-2 border-primary bg-white inset-2 text-primary px-4 py-2 rounded-md text-xs mr-1 ${
                                              forms?.[0]?.ownerId?._id !==
                                              owner?._id
                                                ? "hidden"
                                                : ""
                                            } `}
                                            onClick={async () => {
                                              const isDriveIntegrationsEnabled =
                                                await isFeatureEnabled(
                                                  "ONEDRIVE_INTEGRATION"
                                                );
                                              if (!isDriveIntegrationsEnabled) {
                                                setShowUpgradeModal(true);
                                                setUpgradeModalType(
                                                  "ONEDRIVE_INTEGRATION"
                                                );
                                                return;
                                              }
                                              setShowOneDriveSelector(true);
                                            }}
                                          >
                                            Update Folder
                                          </button>
                                          <button
                                            className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                                            onClick={() => {
                                              window.open(
                                                integrations?.msOneDrive
                                                  ?.parentFolderUrl,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            View Folder
                                          </button>
                                        </div>
                                      </Fragment>
                                    ) : (
                                      <Fragment>
                                        {forms?.[0]?.ownerId?._id !==
                                          owner._id && (
                                          <div className={``}>
                                            <h2 className="p-2 text-yellow-700 bg-yellow-200 text-sm rounded-md text-center">
                                              Contact the owner of this form to
                                              integrate with OneDrive{" "}
                                            </h2>
                                          </div>
                                        )}
                                        {forms?.[0]?.ownerId?._id ===
                                          owner._id && (
                                          <div className={``}>
                                            <h2 className="mb-1 text-sm font-medium text-gray-700">
                                              Your form is not connected to
                                              OneDrive
                                            </h2>
                                            <p className="text-xs text-gray-500 mb-4">
                                              Pick a folder to save your form
                                              responses as PDFs.
                                            </p>
                                            <button
                                              className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                                              onClick={async () => {
                                                const isDriveIntegrationsEnabled =
                                                  await isFeatureEnabled(
                                                    "ONEDRIVE_INTEGRATION"
                                                  );
                                                if (
                                                  !isDriveIntegrationsEnabled
                                                ) {
                                                  setShowUpgradeModal(true);
                                                  setUpgradeModalType(
                                                    "ONEDRIVE_INTEGRATION"
                                                  );
                                                  return;
                                                }
                                                setShowOneDriveSelector(true);
                                              }}
                                            >
                                              Select Folder
                                            </button>
                                          </div>
                                        )}
                                      </Fragment>
                                    )}
                                  </div>
                                ) : (
                                  <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
                                    {forms?.[0]?.ownerId?._id !== owner._id && (
                                      <div className={``}>
                                        <h2 className="p-2 text-yellow-700 bg-yellow-200 text-sm rounded-md text-center">
                                          Contact the owner of this form to
                                          integrate with OneDrive{" "}
                                        </h2>
                                      </div>
                                    )}
                                    {forms?.[0]?.ownerId?._id === owner._id && (
                                      <div className={``}>
                                        <h2 className="mb-1 text-sm">
                                          Connect your OneDrive Account
                                        </h2>
                                        <p className="text-xs text-gray-500 mb-4">
                                          Connect your OneDrive account and save
                                          responses as PDFs to OneDrive.
                                        </p>
                                        <button
                                          className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                                          onClick={() => {
                                            new MixPanel().track("Form", {
                                              form_action:
                                                "form_link_google_drive",
                                              paid: false,
                                            });
                                            new MixPanel().increment(
                                              "form_link_google_drive"
                                            );
                                            window.open(
                                              `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${microsoftClientID}&scope=${microsoftScopes}&response_type=code&redirect_uri=${microsoftAuthRedirectUrl}&state=${owner?.email};${window.location.pathname}`,
                                              "_self"
                                            );
                                          }}
                                        >
                                          Connect to OneDrive
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {active.integrationSidebar === "notion" && (
                              <div>
                                {isIntegrationPermissionAvailable(
                                  active.integrationSidebar
                                ) ? (
                                  <div className="mt-6">
                                    <Fragment>
                                      <h2 className="mb-1 text-[10px] px-1">
                                        Select the database to connect your form
                                      </h2>
                                      <div className="bg-white rounded-md px-4 py-3 mb-2 border text-sm">
                                        <div className="flex flex-col">
                                          <select
                                            value={
                                              integrations?.notion
                                                ?.selectedDatabase?.id
                                            }
                                            onChange={(e) => {
                                              setIntegrations((prevState) => {
                                                const newState = {
                                                  ...prevState,
                                                };
                                                newState.notion.selectedDatabase =
                                                  integrations?.notion?.databases?.find(
                                                    (database) =>
                                                      database.id ===
                                                      e.target.value
                                                  );
                                                return newState;
                                              });
                                            }}
                                            className="outline-none"
                                          >
                                            <option value="">None</option>
                                            {integrations?.notion?.databases?.map(
                                              (value, i) => {
                                                return (
                                                  <option
                                                    key={i}
                                                    value={value.id}
                                                  >
                                                    {value.title}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <div
                                        onClick={() => {
                                          window.open(
                                            `${BASE_URL}/auth/notion?ownerEmail=${owner?.emails[0]}&redirectUrl=${window.location.href}`,
                                            "_self"
                                          );
                                        }}
                                        className="mb-4 text-xs text-blue-500 px-1 cursor-pointer"
                                      >
                                        Add more databases from your Notion
                                        workspace
                                      </div>
                                      {integrations?.notion
                                        ?.selectedDatabase && (
                                        <Fragment>
                                          <div className="bg-white border py-3 px-4 flex justify-between items-center rounded-md">
                                            <div className="flex items-center">
                                              <AiOutlineDatabase className="text-gray-700 text-base mr-4" />
                                              <h2 className="text-sm font-medium text-gray-700">
                                                {
                                                  integrations?.notion
                                                    ?.selectedDatabase?.title
                                                }
                                              </h2>
                                            </div>
                                            <AiFillEye
                                              className="text-gray-700 text-base cursor-pointer"
                                              onClick={() => {
                                                window.open(
                                                  integrations?.notion
                                                    ?.selectedDatabase?.url,
                                                  "_blank"
                                                );
                                              }}
                                            />
                                          </div>
                                          <div className="bg-white flex justify-between items-center mt-2 mb-4">
                                            <button
                                              onClick={() => {
                                                handleNotionSync();
                                              }}
                                              data-tip="Sync to Notion Database"
                                              className="bg-primary text-xs p-2 w-full flex justify-center text-white rounded-md"
                                            >
                                              Sync Notion Database
                                            </button>
                                          </div>
                                        </Fragment>
                                      )}
                                    </Fragment>
                                  </div>
                                ) : (
                                  <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
                                    <h2 className="mb-1 text-sm">
                                      Connect your Notion Account
                                    </h2>
                                    <p className="text-xs text-gray-500 mb-4">
                                      Connect your Notion Account to sync a
                                      database to your form responses.
                                    </p>
                                    <button
                                      className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                                      onClick={() => {
                                        window.open(
                                          `${BASE_URL}/auth/notion?ownerEmail=${owner?.emails[0]}&redirectUrl=${window.location.href}`,
                                          "_self"
                                        );
                                      }}
                                    >
                                      Connect to Notion
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {active.responses.tab === "summary" && (
                  <FormResponseSummary helpers={{ forms, active, formId }} />
                )}
              </div>
            </div>
          </div>
          {/* settings  */}
          <div
            style={{
              display: active.tab === "settings" ? "block" : "none",
            }}
            className="h-full overflow-y-auto pb-[64px]"
          >
            <div className="pt-3">
              <div className="max-w-[768px] mx-auto">
                <div
                  className={`p-6 rounded-lg border bg-white border-[#dadce0] overflow-hidden`}
                >
                  <div className="pb-3 text-lg border-b fade">
                    Basic Settings
                  </div>
                  <div className="pl-[30px] fade">
                    <div className="pt-[20px] pr-[15px] pb-[30px]">
                      <div>
                        <h2 className="text-base">Responses</h2>
                        <p className="mt-1 text-xs text-gray-500">
                          Manage how response and protected
                        </p>
                      </div>
                      <div className="pt-[30px] pl-[30px] pb-[30px] border-b">
                        <div className="flex justify-between">
                          <div>
                            <h2 className="text-sm">
                              Collect respondent email
                            </h2>
                            <p className="mt-1 text-[10px] text-gray-500">
                              Collect respondent email to send them a copy of
                              their responses and approval status (if enabled).
                            </p>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <PurpleSwitch
                                {...{
                                  inputProps: {
                                    accentColor: formState?.accentColor,
                                    "aria-label": "Switch demo",
                                  },
                                }}
                                checked={
                                  formState?.pages?.[0]?.questions?.some(
                                    (question) =>
                                      question.validation === "respondent_email"
                                  )
                                    ? true
                                    : false
                                }
                                onChange={handleCollectRespondentEmail}
                              />
                            </span>
                          </div>
                        </div>
                        {formState?.pages?.[0]?.questions?.some(
                          (question) =>
                            question.validation === "respondent_email"
                        ) && (
                          <div className="mt-4 flex justify-between">
                            <div>
                              <h2 className="text-sm">
                                Verify respondent email
                              </h2>
                              <p className="mt-1 text-[10px] text-gray-500">
                                We'll verify the respondent before they fill the
                                form (if enabled).
                              </p>
                            </div>
                            <div>
                              <span>
                                {" "}
                                <PurpleSwitch
                                  {...{
                                    inputProps: {
                                      accentColor: formState?.accentColor,
                                      "aria-label": "Switch demo",
                                    },
                                  }}
                                  checked={isRespondentEmailVerify}
                                  onChange={() => {
                                    new MixPanel().track("Form", {
                                      form_action:
                                        "form_verify_respondent_email",
                                      paid: false,
                                    });
                                    new MixPanel().increment(
                                      "form_verify_respondent_email"
                                    );
                                    setIsRespondentEmailVerify((prev) => !prev);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="mt-4 flex justify-between">
                          <div>
                            <h2 className="text-sm">
                              Send email notifications
                            </h2>
                            <p className="mt-1 text-[10px] text-gray-500">
                              We'll send you email with attached response PDF
                              whenever your from receives a new response (if
                              enabled).
                            </p>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <PurpleSwitch
                                {...{
                                  inputProps: {
                                    accentColor: formState?.accentColor,
                                    "aria-label": "Switch demo",
                                  },
                                }}
                                checked={isNotificationEnabled}
                                onChange={handleUpdateNotificationStatus}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-[30px] fade">
                    <div className="pr-[15px] pb-[30px]">
                      <div>
                        <h2 className="text-base">Response PDF</h2>
                        <p className="mt-1 text-xs text-gray-500">
                          Manage your response PDF generation
                        </p>
                      </div>
                      <div className="pt-[30px] pl-[30px] pb-[30px] border-b">
                        <div className="flex justify-between">
                          <div>
                            <h2 className="text-sm">Generate response PDF</h2>
                            <p className="mt-1 text-[10px] text-gray-500">
                              We'll generate a PDF with the form responses
                              received even if there is no custom PDF mapped (if
                              enabled).
                            </p>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <PurpleSwitch
                                {...{
                                  inputProps: {
                                    accentColor: formState?.accentColor,
                                    "aria-label": "Switch demo",
                                  },
                                }}
                                checked={isGeneratePdfEnabled}
                                onChange={handleUpdateGeneratePdf}
                              />
                            </span>
                          </div>
                        </div>
                        {isGeneratePdfEnabled && forms?.[0]?.pdfTemplate && (
                          <div className="flex justify-between mt-4">
                            <div>
                              <h2 className="text-sm">View and Verify PDF</h2>
                              <p className="mt-1 text-[10px] text-gray-500">
                                Enable respondents to review and verify PDF
                                before finalizing the form submission.
                              </p>
                            </div>
                            <div>
                              <span>
                                {" "}
                                <PurpleSwitch
                                  {...{
                                    inputProps: {
                                      accentColor: formState?.accentColor,
                                      "aria-label": "Switch demo",
                                    },
                                  }}
                                  checked={formState?.showViewAndVerifyPdf}
                                  onChange={(e) => {
                                    setFormState((prevState) => {
                                      const newForm = { ...prevState };
                                      newForm.showViewAndVerifyPdf =
                                        e?.target?.checked;
                                      return newForm;
                                    });
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                        <div className="flex justify-between mt-4 items-center">
                          <div>
                            <h2 className="text-sm">PDF Language</h2>
                            <p className="mt-1 text-[10px] text-gray-500">
                              You can change the language in which the PDF is
                              generated, English will be supported by default in
                              all languages.
                            </p>
                            <p className="mt-1 text-[10px] text-gray-500">
                              For any other language request{" "}
                              <a
                                href="https://boloforms.canny.io/"
                                target="_blank"
                                className="text-blue-900"
                              >
                                here
                              </a>
                            </p>
                          </div>
                          <div className="z-50">
                            <CustomDropDown
                              opts={languages}
                              selectedOpt={languages?.find(
                                (elm) => genLang?.key == elm?.key
                              )}
                              setSelectedOpt={handleChangeGenLang}
                            />
                          </div>
                        </div>
                        {isGeneratePdfEnabled && (
                          <div className="flex justify-between items-end gap-4 mt-4 ">
                            <div className="flex flex-col items-center w-full">
                              <p className=" w-full text-sm ">Send a copy to</p>
                              <EmailMultiSelect
                                selectedValues={sendACopyInputs}
                                updateEmailValues={updateEmailValues}
                                isCustomStyle={true}
                              />
                              {/* <input
                                type="text"
                                value={sendACopyInput}
                                placeholder="Enter email address"
                                onChange={(e) => {
                                  setSendACopyInput(e.target.value);
                                }}
                                className={`mt-2 text-xs w-full pb-1 text-gray-700 border-b border-gray-200 hover:border-gray-200 disabled:hover:border-white focus:border-b focus:border-[#4c2b87] outline-none`}
                              /> */}
                            </div>
                            <button
                              onClick={handleSaveSendACopyInput}
                              style={{
                                color: formState?.accentColor,
                              }}
                              className="h-[36px] w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                            >
                              Save
                            </button>
                          </div>
                        )}
                        {isGeneratePdfEnabled && (
                          <div className="flex justify-between items-end gap-4 mt-4">
                            <div className="flex flex-col w-full">
                              <p className=" w-full text-sm ">
                                These emails will receive a copy of the
                                responses
                              </p>
                              <div className="flex flex-wrap mt-2 gap-2">
                                {receivesACopy?.map((email, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="text-xs p-2 rounded-md border flex justify-end items-center gap-2"
                                      style={{
                                        color: formState?.accentColor,
                                      }}
                                    >
                                      {email}
                                      <IoIosClose
                                        className="inline-block rounded-md bg-red-200 text-red-500"
                                        onClick={() => {
                                          setShowRemoveReceiverModal(true);
                                          setRemoveReceiver(email);
                                        }}
                                        size={20}
                                      />
                                    </div>
                                  );
                                })}
                                {receivesACopy?.length === 0 && (
                                  <div
                                    className="text-xs p-2 rounded-md border"
                                    style={{
                                      color: formState?.accentColor,
                                    }}
                                  >
                                    No emails added
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="flex flex-col mt-4 gap-4">
                          <div className="flex justify-between items-end gap-4">
                            <div className="flex flex-col w-full">
                              <p className=" w-full text-sm ">
                                Generated PDF Name
                              </p>
                              <div className="flex flex-wrap mt-2 gap-2">
                                <p className="mt-1 text-[10px] text-gray-500">
                                  The name of the pdf will contain the values of
                                  following questions along with the written
                                  text. <br />
                                  [Spaces are ignored]
                                </p>
                              </div>
                            </div>
                            <div>
                              <CustomDropDown
                                opts={generatedPDFNameQuestions?.map(
                                  (question) => ({
                                    key: question?.id,
                                    value: question?.title,
                                  })
                                )}
                                selectedOpt={null}
                                defaultText={"Add Question"}
                                setSelectedOpt={handleGeneratePDFFileNameLocal}
                              />
                            </div>
                            {/* <button
                              onClick={handleGeneratedPDFName}
                              style={{
                                color: formState?.accentColor,
                              }}
                              className="h-[36px] min-w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                            >
                              Save
                            </button> */}
                          </div>
                          {/* <div className="flex flex-wrap gap-4">
                            {generatedPDFName?.map((question, qIdx) => {
                              return (
                                // <span className="flex items-center">
                                <div
                                  className={`flex items-center gap-2 text-xs ${
                                    question?.isDeletedQuestion
                                      ? "text-red-600 border-red-600"
                                      : "text-gray-700"
                                  } rounded px-3 py-1.5 border w-fit`}
                                >
                                  {question?.title}{" "}
                                  <MdClose
                                    size={13}
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                      setGeneratedPDFName((prev) =>
                                        prev?.filter(
                                          (q, qIdxInner) => qIdx !== qIdxInner
                                        )
                                      );
                                    }}
                                  />
                                </div>
                                // {qIdx !== generatedPDFName?.length && "-"}
                                // </span>
                              );
                            })}
                          </div> */}
                          <div className="flex gap-4 items-end text-xs w-full justify-between">
                            <div className="w-full">
                              <MixedTags
                                className="text-xs w-full myTags"
                                autoFocus={true}
                                settings={mixedTagsSettings}
                                readOnly={false}
                                onChange={onChange}
                                value={mixedTagsValue}
                              />
                            </div>
                            <button
                              onClick={handleGeneratedPDFName}
                              style={{
                                color: formState?.accentColor,
                              }}
                              className="h-[32px] min-w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-[30px] fade">
                    <div className="pr-[15px] pb-[30px]">
                      <div>
                        <h2 className="text-base">Presentation</h2>
                        <p className="mt-1 text-xs text-gray-500">
                          Manage how the form and responses are presented
                        </p>
                      </div>
                      <div className="pt-[30px] pl-[30px] pb-[30px]">
                        <div className="flex justify-between items-start gap-4">
                          {show?.confirmationMessage ? (
                            <TextField
                              id="outlined-required"
                              label="Confirmation message"
                              defaultValue={confirmationMessageInput}
                              className="w-full"
                              onChange={(e) => {
                                setConfirmationMessageInput(e.target.value);
                              }}
                            />
                          ) : (
                            <div className="w-full">
                              <p className="text-sm mb-1">
                                Confirmation message
                              </p>
                              <p className="text-sm text-gray-500 italic">
                                {formState?.confirmationMessage ||
                                  form?.confirmationMessage}
                              </p>
                            </div>
                          )}
                          {show?.confirmationMessage ? (
                            <div className="flex gap-x-[3px]">
                              <button
                                onClick={() => {
                                  setFormState((prevState) => {
                                    const newForm = { ...prevState };
                                    newForm.confirmationMessage =
                                      confirmationMessageInput?.trim() === ""
                                        ? formState?.confirmationMessage
                                        : confirmationMessageInput?.trim();
                                    return newForm;
                                  });
                                  setConfirmationMessageInput(
                                    confirmationMessageInput?.trim() === ""
                                      ? formState?.confirmationMessage
                                      : confirmationMessageInput
                                  );
                                  setShow((prev) => ({
                                    ...prev,
                                    confirmationMessage: false,
                                  }));
                                }}
                                className="text-sm text-[#1a73e8] p-[6px] hover:bg-secondary/60 font-medium rounded-sm"
                              >
                                Save
                              </button>
                              <button
                                onClick={() => {
                                  setConfirmationMessageInput(
                                    formState?.confirmationMessage ||
                                      form?.confirmationMessage
                                  );
                                  setShow((prev) => ({
                                    ...prev,
                                    confirmationMessage: false,
                                  }));
                                }}
                                className="text-sm text-[#595a5a] p-[6px] hover:bg-[#F3F4F6] font-medium rounded-sm"
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                new MixPanel().track("Form", {
                                  form_action: "form_confirmation_message",
                                  paid: false,
                                });
                                new MixPanel().increment(
                                  "form_confirmation_message"
                                );
                                setConfirmationMessageInput(
                                  formState?.confirmationMessage ||
                                    form?.confirmationMessage
                                );
                                setShow((prev) => ({
                                  ...prev,
                                  confirmationMessage: true,
                                }));
                              }}
                              className="text-sm text-[#1a73e8] p-[6px] hover:bg-secondary/60 font-medium rounded-sm"
                            >
                              Edit
                            </button>
                          )}
                        </div>
                        <div
                          className={`  justify-between mt-4 ${
                            isThankYouPageEnabled ? "flex" : "hidden"
                          }`}
                        >
                          <div>
                            <h2 className="text-sm">
                              BoloForms branding on 'Thank You' Page
                            </h2>
                            <p className="mt-1 text-[10px] text-gray-500">
                              We'll not show the 'SignUp' call to action for the
                              form respondents (if disabled).
                            </p>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <PurpleSwitch
                                {...{
                                  inputProps: {
                                    accentColor: formState?.accentColor,
                                    "aria-label": "Switch demo",
                                  },
                                }}
                                checked={updateThankYouPageBranding}
                                onChange={handleUpdateThankYouBranding}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-start gap-4 mt-4">
                          {show?.redirectionUrl ? (
                            <TextField
                              id="outlined-required"
                              label="Redirection URL"
                              defaultValue={redirectionUrlInput}
                              className="w-full"
                              onChange={(e) => {
                                setRedirectionUrlInput(e.target.value);
                              }}
                            />
                          ) : (
                            <div className="w-full">
                              <p className="text-sm mb-1">Redirection URL</p>
                              {/* <p className="text-[10px] text-gray-500 mb-1">
                                After the form is submitted, the respondent will
                                be redirected to this URL.
                              </p> */}
                              <p className="text-sm text-gray-500 italic">
                                {formState?.redirectionUrl ||
                                  form?.redirectionUrl}
                              </p>
                            </div>
                          )}
                          {show?.redirectionUrl ? (
                            <div className="flex gap-x-[3px]">
                              <button
                                onClick={() => {
                                  let trimmedUrlInput =
                                    redirectionUrlInput?.trim();
                                  if (
                                    trimmedUrlInput !== "" &&
                                    !isValidURL(trimmedUrlInput)
                                  ) {
                                    toast.error(
                                      "Please enter a valid URL including 'https://' or 'http://"
                                    );
                                    return;
                                  }

                                  setFormState((prevState) => {
                                    const newForm = { ...prevState };
                                    // newForm.redirectionUrl =
                                    //   redirectionUrlInput?.trim() === ""
                                    //     ? formState?.redirectionUrl
                                    //     : redirectionUrlInput?.trim();
                                    newForm.redirectionUrl =
                                      redirectionUrlInput?.trim();
                                    return newForm;
                                  });
                                  setRedirectionUrlInput(
                                    redirectionUrlInput?.trim() === ""
                                      ? formState?.redirectionUrl
                                      : redirectionUrlInput
                                  );
                                  setShow((prev) => ({
                                    ...prev,
                                    redirectionUrl: false,
                                  }));
                                }}
                                className="text-sm text-[#1a73e8] p-[6px] hover:bg-secondary/60 font-medium rounded-sm"
                              >
                                Save
                              </button>
                              <button
                                onClick={() => {
                                  setRedirectionUrlInput(
                                    formState?.redirectionUrl ||
                                      form?.redirectionUrl
                                  );
                                  setShow((prev) => ({
                                    ...prev,
                                    redirectionUrl: false,
                                  }));
                                }}
                                className="text-sm text-[#595a5a] p-[6px] hover:bg-[#F3F4F6] font-medium rounded-sm"
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={async () => {
                                let featuresAccess = await isFeatureEnabled(
                                  "REDIRECTION_URL"
                                );
                                new MixPanel().track("Form", {
                                  form_action: "form_redirection_url",
                                  paid: true,
                                });
                                new MixPanel().increment(
                                  "form_redirection_url"
                                );
                                if (!featuresAccess) {
                                  setShowUpgradeModal(true);

                                  // let ownerFrom = getOwnerFromDetails();
                                  setUpgradeModalType("REDIRECTION_URL");
                                  // if (ownerFrom === "APPSUMO") {
                                  //   setUpgradeModalData({
                                  //     title: BLOCKING.FOLDER.title,
                                  //     desc: BLOCKING.FOLDER.desc,
                                  //     redirect: BLOCKING.FOLDER.redirect,
                                  //     videoSrc: "",
                                  //     redirectText:
                                  //       BLOCKING.FOLDER.redirectText,
                                  //   });
                                  // } else {
                                  //   setUpgradeModalData({
                                  //     title:
                                  //       "Adding Redirection URL is available in Business Plan and above",
                                  //     desc: `You are on ${currentPlan} upgrade to use this feature.`,
                                  //   });
                                  // }

                                  return;
                                }

                                setRedirectionUrlInput(
                                  formState?.redirectionUrl ||
                                    form?.redirectionUrl
                                );
                                setShow((prev) => ({
                                  ...prev,
                                  redirectionUrl: true,
                                }));
                              }}
                              className="text-sm text-[#1a73e8] p-[6px] hover:bg-secondary/60 font-medium rounded-sm"
                            >
                              Edit
                            </button>
                          )}
                        </div>
                        <div className="mt-4 flex justify-between">
                          <div>
                            <h2 className="text-sm">
                              Allow user to submit one submission
                            </h2>
                            <p className="mt-1 text-[10px] text-gray-500">
                              Users can submit this form only once to prevent
                              duplicate entries.
                            </p>
                          </div>
                          <div>
                            <span>
                              {" "}
                              <PurpleSwitch
                                {...{
                                  inputProps: {
                                    accentColor: formState?.accentColor,
                                    "aria-label": "Switch demo",
                                  },
                                }}
                                checked={formState?.isOnlyOneResponse || false}
                                onChange={(e) => {
                                  let checked = e?.target?.checked;
                                  setFormState((prevState) => {
                                    const newForm = { ...prevState };
                                    newForm.isOnlyOneResponse = checked;
                                    return newForm;
                                  });
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`mt-3 p-6 rounded-lg border bg-white border-[#dadce0] overflow-hidden cursor-pointer hidden`}
                >
                  <div className="pb-3 text-lg border-b">
                    <h2 className="fade">Prefill Settings</h2>
                    <div className="mt-1 flex fade">
                      <p className="text-xs text-gray-500">
                        Prefill settings allow you to prefill ans in the form.
                      </p>
                      <a
                        className="text-blue-500 text-xs underline ml-2"
                        href="https://approval-docs.boloforms.com/textual-guides/boloforms-dashboard/how-to-setup-prefill-questions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>

                  <h2 className="mt-6 mb-2 text-xs px-1 fade">
                    Prefill Source
                  </h2>
                  <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm fade">
                    <div className="flex flex-col">
                      <select
                        value={formState?.prefillSource}
                        onChange={async (e) => {
                          let isPaidPlan = await isEntryPaidPlan(
                            owner,
                            "PREFILL"
                          );
                          if (!isPaidPlan) {
                            return setShow((prev) => ({
                              ...prev,
                              paidModal: {
                                visible: true,
                                data: {
                                  title: "Prefill is a premium feature!",
                                  desc: "You are on free plan. Please upgrade to use this feature.",
                                  videoSrc:
                                    "https://www.youtube.com/embed/qcjGlewKDJE",
                                },
                              },
                            }));
                          }

                          checkForZendeskPermission(e.target.value);

                          setFormState((prevState) => {
                            const newForm = { ...prevState };
                            newForm.prefillSource = e.target.value;
                            if (
                              e.target.value !== "NONE" &&
                              !newForm?.prefill?.databaseId
                            ) {
                              let prefill = newForm?.prefill || {};
                              prefill.databaseId = v4();
                              newForm.prefill = prefill;
                            }
                            return newForm;
                          });
                        }}
                        className="outline-none"
                      >
                        <option value="NONE">None</option>
                        <option value="ZENDESK">Zendesk</option>
                        <option value="GOOGLE_SHEETS">Google Sheets</option>
                      </select>
                    </div>
                  </div>

                  {formState?.prefillSource === "ZENDESK" &&
                    show.needPermission && (
                      <button
                        onClick={() => {
                          navigate("/settings/integration?tab=ZENDESK");
                        }}
                        className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                      >
                        Give Zendesk Permission
                      </button>
                    )}

                  {formState?.prefillSource === "GOOGLE_SHEETS" && (
                    <div>
                      <hr className="my-4 -mx-4 border-gray-300" />
                      {show.needPermission ? (
                        <div className="flex ">
                          <button
                            onClick={() => {
                              let ownerEmail =
                                owner?.integrationTokens?.google?.email ??
                                owner.emails[0];
                              let permissionUrl = `${BASE_URL}/auth/google?scopesType=sheets&ownerEmail=${ownerEmail}`;
                              window.open(permissionUrl, "_blank");
                            }}
                            className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                          >
                            Give Google Sheets Permission
                          </button>

                          <button
                            onClick={googleSheetsPrefillSettings}
                            className="border-primary border-2 text-gray-700 px-4 py-2 rounded-md text-sm ml-1"
                          >
                            Continue
                          </button>
                        </div>
                      ) : (
                        <Fragment>
                          <h2 className="mt-4 mb-1 text-xs px-1">
                            Select Sheet
                          </h2>
                          <div className="flex flex-row  border-2 border-green-600 text-xs rounded-md overflow-hidden">
                            <div className="bg-white px-3 py-2 flex flex-col w-full">
                              <select
                                value={formState?.prefill?.googleSheet?.sheetId}
                                onChange={(e) => {
                                  setFormState((prevState) => {
                                    const newForm = { ...prevState };
                                    let prefill = { ...newForm.prefill };
                                    let prefillSheets =
                                      prefill?.googleSheet || {};
                                    prefillSheets.sheetId = e.target.value;
                                    prefill.googleSheet = prefillSheets;
                                    newForm.prefill = prefill;
                                    return newForm;
                                  });
                                  setPrefillGoogleSheet((prevState) => {
                                    let state = { ...prevState };
                                    state.activeSheet = state.sheets.find(
                                      (sheet) => sheet.sheetId == e.target.value
                                    );
                                    let columns = state.activeSheet?.columns;
                                    setPrefillOptions(columns);
                                    return state;
                                  });
                                }}
                                className="outline-none"
                              >
                                {prefillGoogleSheet?.sheets?.map((sheet) => {
                                  return (
                                    <option
                                      key={sheet.sheetId}
                                      value={sheet?.sheetId}
                                    >
                                      {sheet?.sheetName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <button
                              onClick={syncGoogleSheetsPrefillData}
                              disabled={show.loading}
                              className="w-[150px] bg-primary text-white px-4 py-2 text-xs"
                            >
                              Sync Data
                            </button>
                          </div>
                          <div className="mb-4 mt-1 flex justify-between">
                            <a
                              className="text-blue-600 underline text-xs cursor-pointer px-1"
                              href={forms?.[0].spreadsheetUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Edit Sheet
                            </a>
                          </div>

                          <hr className="my-4 -mx-4 border-gray-300" />
                          <div className="mb-4 flex items-center">
                            <h2 className="text-[10px] px-1  font-semibold">
                              Setup Trigger Question
                            </h2>
                            <div>
                              <AiFillInfoCircle
                                className="text-gray-700 text-base ml-1 cursor-pointer"
                                onClick={() =>
                                  window.open(
                                    "https://approval-docs.boloforms.com/textual-guides/boloforms-dashboard/how-to-setup-prefill-questions",
                                    "_blank"
                                  )
                                }
                                data-tip={"Learn More"}
                              />
                            </div>
                          </div>

                          <h2 className="mb-1 text-[10px] px-1 mt-4 font-medium">
                            Trigger Question
                          </h2>
                          <div className="bg-white rounded-md px-3 py-2 mb-4 border text-xs">
                            <div className="flex flex-col">
                              <select
                                value={formState?.prefill?.trigger?.question}
                                onChange={(e) => {
                                  setFormState((prevState) => {
                                    const newForm = { ...prevState };
                                    let prefill = { ...newForm.prefill };
                                    let trigger = prefill.trigger || {};
                                    trigger.question = e.target.value;
                                    prefill.trigger = trigger;
                                    newForm.prefill = prefill;
                                    return newForm;
                                  });
                                }}
                                className="outline-none"
                              >
                                {formState?.pages?.map((page, index) => {
                                  return page.questions?.map(
                                    (question, index) => {
                                      return (
                                        isVaildPrefillQuestionType(
                                          question?.type
                                        ) && (
                                          <option
                                            key={question.id}
                                            value={question?.id}
                                          >{`${question?.title} (${question?.type})`}</option>
                                        )
                                      );
                                    }
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="mb-1 mt-1 flex justify-between">
                            <h2 className="text-[10px] px-1 mt-2 font-medium">
                              Ans Column
                            </h2>
                            <button
                              onClick={googleSheetsPrefillSettings}
                              disabled={show.loading}
                              className="text-blue-600 text-xs cursor-pointer px-1"
                            >
                              Refresh Columns
                            </button>
                          </div>
                          <div className="bg-white rounded-md px-3 py-2 mb-4 border text-xs">
                            <div className="flex flex-col">
                              <select
                                value={formState?.prefill?.trigger?.ansColumn}
                                onChange={(e) => {
                                  setFormState((prevState) => {
                                    const newForm = { ...prevState };
                                    let prefill = { ...newForm.prefill };
                                    let trigger = prefill.trigger || {};
                                    trigger.ansColumn = e.target.value;
                                    prefill.trigger = trigger;
                                    newForm.prefill = prefill;
                                    return newForm;
                                  });
                                }}
                                className="outline-none"
                              >
                                {prefillGoogleSheet?.activeSheet?.columns?.map(
                                  (column) => {
                                    return (
                                      <option key={column} value={column}>
                                        {column}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  )}
                  {/* <hr className="my-4 -mx-4 border-gray-300" /> */}

                  {formState?.prefillSource &&
                    formState?.prefillSource !== "NONE" &&
                    !show.needPermission && (
                      <Fragment>
                        <div className="mb-4 flex items-center">
                          <h2 className="text-[10px] px-1  font-semibold">
                            Ans Mapping
                          </h2>
                          <div>
                            <AiFillInfoCircle
                              className="text-gray-700 text-base ml-1 cursor-pointer"
                              onClick={() =>
                                window.open(
                                  "https://approval-docs.boloforms.com/textual-guides/boloforms-dashboard/how-to-setup-prefill-questions",
                                  "_blank"
                                )
                              }
                              data-tip={"Learn More"}
                            />
                          </div>
                        </div>

                        {formState?.pages?.map((page, index) => {
                          return page.questions?.map((question, index) => {
                            return (
                              !question.mandatory &&
                              isVaildPrefillQuestionType(question.type) && (
                                <Fragment key={question.id}>
                                  <h2 className="mb-1 text-[10px] px-1">
                                    {`${question?.title ?? "Untitled"} (${
                                      question?.type
                                    })`}
                                  </h2>
                                  <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm">
                                    <div className="flex flex-col">
                                      <select
                                        value={
                                          formState?.prefill?.mapping?.[
                                            question?.id
                                          ]
                                        }
                                        onChange={(e) => {
                                          setFormState((prevState) => {
                                            const newForm = { ...prevState };
                                            let prefill = {
                                              ...newForm?.prefill,
                                            };
                                            let mapping = {
                                              ...prefill?.mapping,
                                            };
                                            mapping[question?.id] =
                                              e.target.value;
                                            prefill.mapping = mapping;
                                            newForm.prefill = prefill;
                                            return newForm;
                                          });
                                        }}
                                        className="outline-none"
                                      >
                                        <option value="">None</option>
                                        {prefillOptions?.map((value, i) => {
                                          return (
                                            <option key={i} value={value}>
                                              {value}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </Fragment>
                              )
                            );
                          });
                        })}
                      </Fragment>
                    )}
                </div>
              </div>
            </div>
          </div>

          {show.rightSidebar && (
            <div
              style={{
                height: "calc(100vh - 110px)",
                boxShadow:
                  "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
              }}
              className={` ${
                show.rightSidebar ? "min-w-[400px] w-[400px]" : "w-[0px]"
              } mt-[110px] bg-white border-l ml-auto fixed top-0 right-0 z-[110]`}
            >
              <div className="h-[56px] border-b w-full px-3 flex flex-row items-center">
                <div className="h-full mr-2 flex items-center ">
                  <MdOutlineColorLens className="text-2xl text-gray-600" />
                  <span className="text-base font-medium text-gray-800 ml-3">
                    Theme
                  </span>
                </div>
                <div
                  onClick={() => {
                    setShow((prev) => ({
                      ...prev,
                      rightSidebar: false,
                    }));
                  }}
                  className="ml-auto w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                >
                  <MdClose className="text-2xl text-gray-600" />
                </div>
              </div>
              <div
                style={{
                  height: "calc(100% - 56px)",
                }}
                className="w-full p-4 overflow-y-auto"
              >
                {active.rightSidebar === "theme" && (
                  <Fragment>
                    <h2 className="font-medium">Theme Settings</h2>
                    <p className="text-xs mb-4 text-gray-500">
                      Customize the look and feel of your form.
                    </p>
                    <h2 className="mb-2 text-xs px-1">Theme Template</h2>
                    <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm">
                      <div className="flex flex-col">
                        <select
                          value={formState?.themeTemplate}
                          onChange={(e) => {
                            setFormState((prevState) => {
                              const newForm = { ...prevState };
                              newForm.themeTemplate = e.target.value;
                              return newForm;
                            });
                          }}
                          className="outline-none"
                        >
                          {/* <option value="default">Classic</option> */}
                          <option value="google-forms">Google Forms</option>
                          <option value="notion-forms">Notion</option>
                          <option value="type-forms">Typeform</option>
                        </select>
                      </div>
                    </div>
                    <h2 className="mb-2 text-xs px-1">Font</h2>
                    <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm">
                      <div className="flex flex-col">
                        <select
                          value={formState?.fontFamily}
                          onChange={(e) => {
                            setFormState((prevState) => {
                              const newForm = { ...prevState };
                              newForm.fontFamily = e.target.value;
                              return newForm;
                            });
                          }}
                          className="outline-none"
                        >
                          <option value="Poppins">Poppins</option>
                          <option value="cursive">Cursive</option>
                          <option value="fantasy">Fantasy</option>
                          <option value="monospace">Monospace</option>
                          <option value="Raleway">Raleway</option>
                          <option value="Ubuntu">Ubuntu</option>
                          <option value="Source Sans Pro">
                            Source Sans Pro
                          </option>
                          <option value="Nunito">Nunito</option>
                          <option value="Karla">Karla</option>
                        </select>
                      </div>
                    </div>
                    <h2 className="mb-2 text-xs px-1">Accent Color</h2>
                    <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm">
                      <div className="flex flex-col">
                        <input
                          value={formState?.accentColor}
                          onChange={(e) => {
                            console.log(formState?.themeTemplate);
                            setFormState((prevState) => {
                              const newForm = { ...prevState };
                              newForm.accentColor = e.target.value;
                              newForm.backgroundColor =
                                newForm.themeTemplate === "google-forms"
                                  ? `${e.target.value}1a`
                                  : "#ffffff";
                              return newForm;
                            });
                          }}
                          type="color"
                        />
                      </div>
                    </div>
                    <h2 className="mb-2 text-xs px-1">Header</h2>
                    <div className="flex gap-x-1 mb-4 items-center">
                      <div
                        onClick={async () => {
                          setShow((prev) => ({
                            ...prev,
                            headerImageModal: !prev.headerImageModal,
                          }));
                        }}
                        className={`bg-white ${
                          formState?.headerImage
                            ? ""
                            : "hover:bg-[#f8fbff] hover:border-[#e0ecfe]"
                        } rounded-md px-4 py-2 border w-fit flex items-center justify-center gap-x-2 cursor-pointer`}
                      >
                        <BiImageAlt
                          className={`${
                            formState?.headerImage
                              ? "text-[#d93025]"
                              : "text-[#1a73e8]"
                          } text-[20px]`}
                        />
                        {formState?.headerImage ? (
                          <span className="text-sm text-black">
                            Image Uploaded
                          </span>
                        ) : (
                          <span className="text-sm font-medium text-[#1a73e8]">
                            Choose Image
                          </span>
                        )}
                      </div>
                      {formState?.headerImage && (
                        <span
                          onClick={() => {
                            setFormState((prevState) => {
                              const newForm = { ...prevState };
                              newForm.headerImage = "";
                              return newForm;
                            });
                          }}
                          className="w-[30px] h-[30px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                        >
                          <MdClose className="text-lg text-gray-600" />
                        </span>
                      )}
                    </div>
                    <h2 className="mb-2 text-xs px-1">Background Color</h2>
                    <div className="flex pt-2">
                      {[1, 2, 3, 4]?.map((bg) => (
                        <div
                          style={{
                            backgroundColor: `${formState?.accentColor}${bg}a`,
                          }}
                          className="rounded-full w-10 h-10 mr-4 border border-gray-300 hover:scale-105 cursor-pointer flex justify-center items-center"
                          onClick={(e) => {
                            setFormState((prevState) => {
                              const newForm = { ...prevState };
                              newForm.backgroundColor = `${formState?.accentColor}${bg}a`;
                              return newForm;
                            });
                          }}
                        >
                          {formState?.backgroundColor ===
                            `${formState?.accentColor}${bg}a` && (
                            <MdCheck className="text-gray-800 text-2xl" />
                          )}
                        </div>
                      ))}
                    </div>
                    {/* <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm">
                      <div className="flex flex-col">
                        <input
                          value={formState?.backgroundColor}
                          onChange={(e) => {
                            setFormState((prevState) => {
                              const newForm = { ...prevState };
                              newForm.backgroundColor = e.target.value;
                              return newForm;
                            });
                          }}
                          type="color"
                        />
                      </div>
                    </div> */}
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {active.sorting && (
        <Sorting helpers={{ formState, setFormState, active, setActive }} />
      )}
      {showDropBoxChooser && (
        <DropboxFolderSelect
          onSuccess={(files: any) => {
            let file = files[0];
            if (!file?.isDir) {
              toast.error("Please select a folder");
              return;
            }
            handleSubmit({
              enabled: true,
              parentFolderId: file?.id,
              parentFolderName: file?.name,
              parentFolderUrl: file?.link,
            });
          }}
        />
      )}
      {showOneDriveSelector && (
        <OnedriveModalFolderSelect
          setShowOnedriveModal={setShowOneDriveSelector}
          ownerEmail={owner?.email}
          setSelectedFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
          handleSubmit={handleOneDriveSubmit}
        />
      )}

      {show.sendModal && (
        <SendModal
          helpers={{
            form: forms?.[0],
            formState,
            setFormState,
            active,
            setShow,
            isRespondentEmailVerify,
            setIsRespondentEmailVerify,
            ownerName: owner?.name,
          }}
        />
      )}
      {showRemoveReceiverModal && removeReceiver && (
        <Modal modalContent={deleteOneReceiverModal()} />
      )}
      {needPaidPlanError && (
        <PleaseUpgradeModal
          setShow={setNeedPaidPlainError}
          show={needPaidPlanError}
          data={{
            title: "Setting Receivers is a Premium feature!",
            desc: "Please upgrade to use this feature.",
          }}
        />
      )}
      {openEmbedModal && <Modal modalContent={embedModalContent()} />}
      {show.headerImageModal && (
        <HeaderImageModal setShow={setShow} setFormState={setFormState} />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />

      <PleaseUpgradeModal
        data={show.paidModal.data}
        helpers={{
          show,
          setShow,
        }}
      />

      {show.googleSheetModal && (
        <GoogleSheetModal
          helpers={{
            formState,
            setShow,
            setIntegrations,
            updateIntegrationStatus,
            isIntegrationPermissionAvailable,
            owner,
            formId,
          }}
        />
      )}

      {show.trialExtendedModal && (
        <TrialExtendedModal
          setShow={() => {
            setShow((prev) => ({
              ...prev,
              trialExtendedModal: false,
            }));
            try {
              Intercom("startTour", 545110);
              console.log("INTERCOM TOUR RAN");
            } catch (error) {
              console.log("Error in starting tour", error);
            }
          }}
          show={show.trialExtendedModal}
          completedTask={{
            title: "Create a form",
            description: "You have created a form",
            increasedDays: extendedTrialDays || 0,
          }}
        />
      )}
    </div>
  );
};

export default EditForm;

// const mapStateToProps = (state) => {
//   return {
//     owner: state.OwnerReducer.owner,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     fetchOwner: () => dispatch(fetchOwner()),
//   };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(EditForm);

const TopBarMoreOptions = ({ helpers }) => {
  let { formId, setShow, forms } = helpers;

  let navigate = useNavigate();

  const handleOptionClick = (option) => {
    let openLink = "";

    switch (option) {
      case "approvalWorkflow":
        openLink = `${getDashboardUrlV1()}/forms/settings/approval-flow?id=${formId}&formType=BOLOFORMS&temp=blank`;
        break;
      case "emailSettings":
        openLink = `${getDashboardUrlV1()}/forms/settings/email-settings?id=${formId}&formType=BOLOFORMS&temp=blank`;

        break;
      case "customizePDF":
        openLink = forms?.[0]?.pdfTemplate
          ? `${getDashboardUrlV1()}/create/form/pdf?id=${formId}`
          : `${getDashboardUrlV1()}/create/pdf-mapping?id=${formId}`;

        break;
      case "reportsAndAnalytics":
        openLink = `${getDashboardUrlV1()}/forms/settings/reports?id=${formId}&formType=BOLOFORMS`;

        break;
      case "addCollaborators":
        openLink = `${getDashboardUrlV1()}/settings/collaborators`;

        break;
      default:
        break;
    }
    window.open(openLink, "_blank");
    setShow((prev) => ({ ...prev, topBarMoreOptions: false }));
  };

  return (
    <div
      style={{
        marginTop: "48px",
        position: "absolute",
        top: 0,
        right: "-40px",
        boxShadow:
          "0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122)",
      }}
      className="py-2 w-[256px] bg-white rounded z-50"
    >
      {/* <div
        onClick={() => handleOptionClick("approvalWorkflow")}
        className={`fade h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <RiFlowChart className="text-2xl text-gray-600 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Approval Workflow</span>
        </div>
      </div> */}
      {/* <div
        onClick={() => handleOptionClick("emailSettings")}
        className={`fade h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdOutlineMarkEmailRead className="text-2xl text-gray-600 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Email Settings</span>
        </div>
      </div> */}
      <div
        // onClick={() => handleOptionClick("customizePDF")}
        onClick={() => {
          navigate(
            !forms?.[0]?.pdfTemplate
              ? `/create/form/pdf?id=${formId}`
              : `/create/pdf-mapping?id=${formId}`
          );
        }}
        className={`fade h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <PiFilePdfDuotone className="text-2xl text-gray-600 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">
            {!forms?.[0]?.pdfTemplate ? "Customize Pdf" : "Map to PDF"}
          </span>
        </div>
      </div>
      {/* <div
        onClick={() => handleOptionClick("reportsAndAnalytics")}
        className={`fade h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdOutlineAnalytics className="text-2xl text-gray-600 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Reports & Analytics</span>
        </div>
      </div> */}

      {/* <div className="border-t border-[#0000001f] my-2"></div> */}

      {/* <div
        onClick={() => handleOptionClick("addCollaborators")}
        className={`fade h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdOutlineGroupAdd className="text-2xl text-gray-600 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Add collaborators</span>
        </div>
      </div> */}
    </div>
  );
};

const ProfileOptions = ({ helpers }) => {
  let { setShow } = helpers;
  const logOut = () => {
    localStorage.removeItem("owner");
    localStorage.removeItem("currentUserEmail");
    localStorage.removeItem("accountType");
    localStorage.removeItem("isApprovalTimeline");
    localStorage.removeItem("formColumnPrefrences");
    localStorage.removeItem("respondentPageNo");
    localStorage.removeItem("x-auth-token");
    localStorage.removeItem("copytemplateId");
    // localStorage.removeItem("activeView");
    localStorage.setItem("isMergeDash", "true");
    window.location.href = "/login";
  };

  return (
    <div
      style={{
        marginTop: "48px",
        position: "absolute",
        top: 0,
        right: "0px",
        boxShadow:
          "0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122)",
      }}
      className="py-2 w-[200px] bg-white rounded z-50"
    >
      <div
        onClick={() => {
          window.open(`${getDashboardUrlV1()}/settings?type=PROFILE`, "_blank");
          setShow((prev) => ({ ...prev, profileOptions: false }));
        }}
        className={`fade h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdOutlinePersonOutline className="text-2xl text-gray-600 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Account</span>
        </div>
      </div>
      <div
        onClick={logOut}
        className={`fade h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdOutlineLogout className="text-2xl text-gray-600 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Log out</span>
        </div>
      </div>
    </div>
  );
};
