import React, { useEffect, useState, useRef } from "react";
import jsPDF from "jspdf";
import { convertTimestampToTimeZone } from "../../utils/uiUtils";
import { fetchOwner } from "../../utils/validateEmail";

type Props = {
  history: any;
  setIsModalOpen: (isModalOpen: boolean) => void;
  documentDetails?: any;
  respondent?: any;
  ifRespondentStatus?: string;
};

const HistoryModal = ({
  history,
  setIsModalOpen,
  documentDetails,
  respondent,
  ifRespondentStatus,
}: Props) => {
  const [divHeight, setDivHeight] = useState("85%");

  const { authorEmail, documentId, documentName, status, createdAt } =
    documentDetails;
  let envelopeHistory: any;
  if (respondent) {
    envelopeHistory = [...documentDetails?.history, ...history];
  } else {
    envelopeHistory = [...history];
  }
  console.log({ ifRespondentStatus });
  console.log({ history, documentDetails });

  function convertStringToDate(dateString: any) {
    try {
      if (!dateString) {
        return false;
      }
      // Assuming the input string is in the format "DD/MM/YYYY, h:mm:ss a"
      const [datePart, timePart] = dateString.split(", ");

      // Split the date into day, month, and year
      const [day, month, year] = datePart.split("/");

      // Split the time into hours, minutes, seconds, and am/pm
      const [time, ampm] = timePart.split(" ");
      const [hours, minutes, seconds] = time.split(":");

      // Convert the string parts into numbers
      const yearNum = parseInt(year, 10);
      const monthNum = parseInt(month, 10) - 1; // Months are zero-based in JavaScript
      const dayNum = parseInt(day, 10);
      const hoursNum =
        parseInt(hours, 10) + (ampm.toLowerCase() === "pm" ? 12 : 0);
      const minutesNum = parseInt(minutes, 10);
      const secondsNum = parseInt(seconds, 10);

      // Validate the parsed values
      if (
        isNaN(yearNum) ||
        isNaN(monthNum) ||
        isNaN(dayNum) ||
        isNaN(hoursNum) ||
        isNaN(minutesNum) ||
        isNaN(secondsNum)
      ) {
        throw new Error("Invalid date or time format");
      }

      // Create a new Date object
      const convertedDate = new Date(
        yearNum,
        monthNum,
        dayNum,
        hoursNum,
        minutesNum,
        secondsNum
      );

      // Validate if the Date object is valid
      if (isNaN(convertedDate.getTime())) {
        throw new Error("Invalid date or time");
      }

      return convertedDate;
    } catch (error: any) {
      console.error(error.message);
      return false;
    }
  }

  const createPDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();

    // Your HTML content as a string
    const htmlString = `
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&display=swap');
          </style>
      </head>
      <body>
          <div style="margin-bottom: 40px; font-family: 'Nunito Sans', sans-serif;">
            <h2 style="font-size: 25px; font-weight: 700; margin-bottom: 20px">Details</h2>
            <div style="display: flex; flex-direction: column; row-gap: 10px; font-size: 18px">
                <div style="display: flex; justify-content: space-between row-gap: 5px;">
                    <div style="display: flex; flex-basis: 50%; flex-direction: column;">
                        <span style="font-weight: 700">Envelope Name</span>
                        <span>${documentName}</span>
                    </div>
                    <div style="display: flex; flex-basis: 50%; flex-direction: column;">
                        <span style="font-weight: 700">Envelope Id</span>
                        <span>${documentId}</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between; flex-basis: 50%;">
                    <div style="display: flex; flex-direction: column;">
                        <span style="font-weight: 700">Envelope Recipients</span>
                        ${
                          respondent
                            ? respondent?.signers
                                ?.map(
                                  (item: any) =>
                                    `<span key=${item._id}>${item.name}</span>`
                                )
                                .join("")
                            : documentDetails?.respondentsOfDoc
                                ?.map(
                                  (item: any) =>
                                    `<span key=${item._id}>${item.name}</span>`
                                )
                                .join("")
                        }
                    </div>
                    <div style="display: flex; flex-basis: 50%; flex-direction: column;">
                        <span style="font-weight: 700">Date Sent</span>
                        <span>${convertTimestampToTimeZone(
                          convertStringToDate(
                            history?.find(
                              (item: any) =>
                                item.status === "SENT" ||
                                item.status === "RECEIVED" ||
                                item.status === "SENT VIA LINK"
                            )?.timestamp
                          ),
                          ownerTz
                        )}</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between">
                    <div style="display: flex; flex-basis: 50%; flex-direction: column;">
                        <span style="font-weight: 700">Status</span>
                        <span>${ifRespondentStatus ?? status}</span>
                    </div>
                    <div style="display: flex; flex-basis: 50%; flex-direction: column;">
                        <span style="font-weight: 700">Date Created</span>
                        <span>${convertTimestampToTimeZone(
                          createdAt,
                          ownerTz
                        )}</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: space-between">
                    <div style="display: flex; flex-basis: 50%; flex-direction: column;">
                        <span style="font-weight: 700">Time Zone</span>
                        <span>${
                          //   new Date(createdAt).toLocaleTimeString(
                          //   "en-US",
                          //   { timeZoneName: "short" }
                          // )
                          owner?.accountInfo?.timeZone || "UTC"
                        }</span>
                    </div>
                    <div style="display: flex; flex-basis: 50%; flex-direction: column;">
                        <span style="font-weight: 700">Holder</span>
                        <span>${authorEmail}</span>
                    </div>
                </div>
                
                <div style="display: flex; justify-content: space-between">
                
                <div style="display: ${
                  respondent ? "flex" : "none"
                }; flex-basis: 50%; flex-direction: column;">
                    <span style="font-weight: 700">Envelope Response _id</span>
                    <span>${respondent?._id}</span>
                </div>
            </div>
                
            </div>
          </div>
          <div>
            <h2 style="font-size: 25px; font-weight: 700; margin-bottom: 25px">Activities</h2>
            <table>
                <thead>
                    <tr style="font-size: 18px;">
                        <th style="text-align: left; padding: 10px; border: 1px solid rgb(229 231 235);">Time</th>
                        <th style="text-align: left; padding: 10px; border: 1px solid rgb(229 231 235);">User</th>
                        <th style="text-align: left; padding: 10px; border: 1px solid rgb(229 231 235);">Action</th>
                        <th style="text-align: left; padding: 10px; border: 1px solid rgb(229 231 235);">Activity</th>
                        <th style="text-align: left; padding: 10px; border: 1px solid rgb(229 231 235);">Status</th>
                    </tr>
                </thead>
                <tbody>
                    ${envelopeHistory
                      .map((item: any, index: any) => {
                        return `
                      <tr style="font-family: 'Nunito Sans', sans-serif; font-size: 18px;" key={index}>
                      <td style="border: 1px solid rgb(229 231 235); padding: 10px;">
                          ${convertTimestampToTimeZone(
                            convertStringToDate(item.timestamp) || createdAt,
                            ownerTz
                          )}
                      </td>
                      <td style="border: 1px solid rgb(229 231 235); padding: 10px;">
                          ${item.userId}, ip:${item.ip}
                      </td>
                      <td style="border: 1px solid rgb(229 231 235); padding: 10px;">
                          ${item.action}
                      </td>
                      <td style="border: 1px solid rgb(229 231 235); padding: 10px;">
                          ${item.activity}
                      </td>
                      <td style="border: 1px solid rgb(229 231 235); padding: 10px;">
                          ${item.status}
                      </td>
                    </tr>
                      `;
                      })
                      .join("")}
                </tbody >
            </table >
          </div >
      </body >
        `;
    function addImageToAllPages(doc: any, imageUrl: any, callback: any) {
      const pageCount = doc.internal.getNumberOfPages();

      // Load the image from the URL
      const imgData = imageUrl; // Replace with image data or URL
      const imgWidth = 150; // Adjust image width as needed
      const imgHeight = 150; // Adjust image height as needed

      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);

        // Get page width and height
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;

        // Calculate image coordinates to center it on the page
        const imgX = (pageWidth - imgWidth) / 2;
        const imgY = (pageHeight - imgHeight) / 2;

        doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
      }

      // Execute the callback function
      if (typeof callback === "function") {
        callback(doc);
      }
    }

    // // Add the HTML content to the PDF
    // Add the HTML content to the PDF
    doc.html(htmlString, {
      callback: function (finalDoc) {
        // Call the addImageToAllPages function to add the same image to all pages of the PDF
        addImageToAllPages(
          finalDoc,
          "https://i.imgur.com/i4EiHiU.png",
          function (docWithImage: any) {
            // Save the PDF with the image on all pages
            docWithImage.save(`${documentName}_history.pdf`);
          }
        );
      },
      x: 0,
      y: 0,
      width: 190,
      windowWidth: 950,
      margin: [10, 10, 10, 10],
    });
  };

  const historyModalRef = useRef<any>(null);
  const [ownerTz, setOwnerTz] = useState<any>(undefined);
  const [owner, setOwner] = useState<any>(undefined);

  const handleOutsideClick = (e: any) => {
    if (historyModalRef.current !== null) {
      if (historyModalRef.current.contains(e.target)) return;
    }
    setIsModalOpen(false);
  };

  async function getOwner() {
    let ownerData = await fetchOwner();
    setOwner(ownerData);
    setOwnerTz(ownerData?.accountInfo?.timeZone);
  }

  useEffect(() => {
    getOwner();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Function to set the height based on screen height
    function setCustomHeight() {
      const screenHeight = window.innerHeight;

      if (screenHeight > 1150) {
        setDivHeight("55%");
      } else if (screenHeight > 1000) {
        setDivHeight("60%");
      } else if (screenHeight > 800) {
        setDivHeight("70%");
      } else {
        setDivHeight("85%");
      }
    }

    setCustomHeight();
    window.addEventListener("resize", setCustomHeight);

    return () => {
      window.removeEventListener("resize", setCustomHeight);
    };
  }, []);

  return (
    <div className="absolute inset-0 z-50 flex items-center justify-center w-[calc(100%_-_1.5rem)] md:w-[calc(100%_-_65px)] sm:w-[calc(100%_-_90px)] overflow-y-scroll m-auto top-0 md:top-[80px]">
      <div className="fixed inset-0 bg-gray-500 opacity-50"></div>
      <div
        ref={historyModalRef}
        style={{ maxHeight: divHeight }}
        className={`bg-white rounded shadow relative overflow-y-auto`}
      >
        <div className="w-full sticky p-3 md:p-4 top-0 bg-white flex items-center gap-x-4 border-b-2 border-solid border-gray-200">
          <h2 className="text-md md:text-lg font-semibold">Envelope History</h2>
        </div>
        <div className="p-3 md:p-5 max-w-[100%]">
          <div className="mb-4 md:mb-8">
            <h2 className="font-semibold text-sm md:text-sm mb-4">Details</h2>
            <div className="text-xs md:text-sm font-light flex flex-col gap-y-4">
              <div className="flex justify-between">
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Envelope Name</span>
                  <span>{documentName}</span>
                </div>
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Envelope Id</span>
                  <span>{documentId}</span>
                </div>
              </div>
              <div className="flex basis-1/2 justify-between">
                <div className="flex flex-col gap-y-1">
                  <span className="font-medium">Envelope Recipients</span>
                  {respondent
                    ? respondent.signers?.map((item: any, i: number) => {
                        return <span key={i}>{item.name}</span>;
                      })
                    : documentDetails?.respondentsOfDoc?.map(
                        (item: any, i: number) => (
                          <span key={item._id}>{item.name}</span>
                        )
                      )}
                </div>
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Date Sent</span>
                  <span>
                    {convertTimestampToTimeZone(
                      convertStringToDate(
                        history?.find(
                          (item: any) =>
                            item.status === "SENT" ||
                            item.status === "RECEIVED" ||
                            item.status === "SENT VIA LINK"
                        )?.timestamp
                      ),
                      ownerTz
                    )}
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Status</span>
                  <span>{ifRespondentStatus ?? status}</span>
                </div>
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Date Created</span>
                  <span>
                    {
                      // new Date(createdAt).toLocaleString("en-US", {
                      //   year: "numeric",
                      //   month: "numeric",
                      //   day: "numeric",
                      //   hour: "numeric",
                      //   minute: "numeric",
                      //   second: "numeric",
                      // })
                      convertTimestampToTimeZone(createdAt, ownerTz)
                    }
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Time Zone</span>
                  <span>
                    {
                      // new Date(createdAt).toLocaleTimeString("en-US", {
                      //   timeZoneName: "short",
                      // })
                      owner?.accountInfo?.timeZone || "UTC"
                    }
                  </span>
                </div>
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Holder</span>
                  <span>{authorEmail}</span>
                </div>
              </div>
              {respondent && (
                <div className="flex justify-between">
                  <div className="flex basis-1/2 flex-col gap-y-1">
                    <span className="font-medium">Envelope Response _id</span>
                    <span>{respondent?._id}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="max-w-[100%] overflow-x-auto">
            <h2 className="max-w-[100%] font-semibold text-sm md:text-sm mb-3">
              Activities
            </h2>
            <table className="text-xs md:text-sm font-light">
              <thead className="bg-gray-100">
                <tr className="">
                  <th className="font-semibold text-left p-2 md:p-4 border md:border-2 border-gray-300">
                    Time
                  </th>
                  <th className="font-semibold text-left p-2 md:p-4 border md:border-2 border-gray-300">
                    User
                  </th>
                  <th className="font-semibold text-left p-2 md:p-4 border md:border-2 border-gray-300">
                    Action
                  </th>
                  <th className="font-semibold text-left p-2 md:p-4 border md:border-2 border-gray-300">
                    Activity
                  </th>
                  <th className="font-semibold text-left p-2 md:p-4 border md:border-2 border-gray-300">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {envelopeHistory.map((item: any, index: any) => (
                  <tr key={index}>
                    <td className="text-gray-600 p-2 md:p-4 border md:border-2 border-gray-200">
                      {convertTimestampToTimeZone(
                        convertStringToDate(item.timestamp) || createdAt,
                        ownerTz
                      )}
                    </td>
                    <td className="text-gray-600 p-2 md:p-4 border md:border-2 border-gray-200">
                      {item.userId}, ip:{item.ip}
                    </td>
                    <td className="text-gray-600 p-2 md:p-4 border md:border-2 border-gray-200">
                      {item.action}
                    </td>
                    <td className="text-gray-600 p-2 md:p-4 border md:border-2 border-gray-200">
                      {item.activity}
                    </td>
                    <td className="text-gray-600 p-2 md:p-4 border md:border-2 border-gray-200">
                      {item.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-xs md:text-sm w-full sticky pt-0 p-4 bottom-0 bg-white flex justify-end items-center gap-x-4 border-t-2 border-solid border-gray-200">
          <button
            className="mt-4 px-8 py-2 bg-primary text-white rounded font-semibold hover:bg-[#ac86f9]"
            onClick={createPDF}
          >
            Download
          </button>

          <button
            className="mt-4 px-8 py-2 border-primary border-2 text-primary bg-white rounded font-semibold hover:opacity-70"
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default HistoryModal;
