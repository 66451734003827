import axios from "axios";
import { v4 } from "uuid";
import { removeSpecialCharacters } from ".";
import {
  LAMBDA_FILE_UPLOAD_URL,
  LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PRIVATE,
  LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PUBLIC,
} from "../constants/constants";

// !--------------------------------------------------
// !Note : this is not used
// !--------------------------------------------------
const uploadViaSignedUrl = async (file: File) => {
  try {
    // console.log(file);
    /*
responseInputs [
  {
    'Signature(1)': 'https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature-template/b62fe611-2598-43f8-bebd-ad8f3f8735e8.png',
    'Image(1)': 'https://boloforms-main.s3.amazonaws.com/approval-form-uploads/1708413311423-SALVAMERICA_LOGOPNG.png',
    'Image___1(1)': ''
  }
]

This is the response i get so make such lambda that uploads to /signature bucket instead of approval bucket
and then revert changes in generateFinishedPdfFile function 
urlToReplace to original
*/
    const signedUrl = await axios.post(`${LAMBDA_FILE_UPLOAD_URL}`, {
      fileName: file.name,
    });

    if (signedUrl?.data?.url) {
      const data = await fetch(signedUrl?.data?.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      //   console.log(signedUrl.data);
      return signedUrl?.data?.publicUrl;
    }

    return "";
  } catch (error) {
    console.log(error);
  }
};

const generalizedFileUploadViaSignedUrlPublic = async (args: {
  file: File;
  folderName: string;
  bucketName?: string;
}) => {
  try {
    let { file, folderName, bucketName } = args;
    let newFileName = removeSpecialCharacters(file.name);
    newFileName = v4() + newFileName;
    file = new File([file], newFileName, { type: file.type });

    let uploadBody: {
      fileName: string;
      folderName: string;
      bucketName?: string;
    } = { fileName: file.name, folderName };
    if (bucketName) {
      uploadBody.bucketName = bucketName;
    }

    console.log(uploadBody);

    const signedUrl = await axios.post(
      `${LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PUBLIC}`,
      uploadBody
    );

    if (signedUrl?.data?.url) {
      const data = await fetch(signedUrl?.data?.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      //   console.log(signedUrl.data);
      return signedUrl?.data?.publicUrl;
    }

    return "";
  } catch (error) {
    console.log(error);
  }
};

const generalizedFileUploadViaSignedUrlPrivate = async (args: {
  file: File;
  folderName: string;
  bucketName?: string;
  notUnique?: boolean;
}) => {
  try {
    let { file, folderName, bucketName, notUnique } = args;
    let newFileName = removeSpecialCharacters(file.name);
    if (!notUnique) {
      newFileName = v4() + newFileName;
    }
    file = new File([file], newFileName, { type: file.type });

    let uploadBody: {
      fileName: string;
      folderName: string;
      bucketName?: string;
    } = { fileName: file.name, folderName };
    if (bucketName) {
      uploadBody.bucketName = bucketName;
    }

    console.log(uploadBody);

    const signedUrl = await axios.post(
      `${LAMBDA_GENERALIZED_FILE_UPLOAD_URL_PRIVATE}`,
      uploadBody
    );

    if (signedUrl?.data?.url) {
      const data = await fetch(signedUrl?.data?.url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      //   console.log(signedUrl.data);
      return signedUrl?.data?.publicUrl;
    }

    return "";
  } catch (error) {
    console.log(error);
  }
};

export {
  uploadViaSignedUrl,
  generalizedFileUploadViaSignedUrlPublic,
  generalizedFileUploadViaSignedUrlPrivate,
};
