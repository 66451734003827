export const pipedriveVariablesMapping = {
  "[Pipedrive.Deal.ActivitiesToDo]": "undone_activities_count",
  "[Pipedrive.Deal.CCEmail]": "cc_email",
  "[Pipedrive.Deal.ContactPerson.Email]": "person_id.email[0].value",
  "[Pipedrive.Deal.ContactPerson.Id]": "person_id.value",
  "[Pipedrive.Deal.ContactPerson.Name]": "person_id.name",
  "[Pipedrive.Deal.ContactPerson.Phone]": "person_id.phone[0].value",
  "[Pipedrive.Deal.Created]": "add_time",
  "[Pipedrive.Deal.Creator.Email]": "creator_user_id.email",
  "[Pipedrive.Deal.Creator.Id]": "creator_user_id.id",
  // "[Pipedrive.Deal.Creator.Name]": "creator_user_id.name",
  "[Pipedrive.Deal.Currency]": "currency",
  "[Pipedrive.Deal.DealClosedOn]": "close_time",
  "[Pipedrive.Deal.DoneActivities]": "done_activities_count",
  "[Pipedrive.Deal.EmailMessagesCount]": "email_messages_count",
  "[Pipedrive.Deal.ExpectedCloseDate]": "expected_close_date",
  "[Pipedrive.Deal.FormattedValue]": "formatted_value",
  "[Pipedrive.Deal.FormattedWeightedValue]": "formatted_weighted_value",
  "[Pipedrive.Deal.ID]": "id",
  //"[Deal.Label]": "---",
  "[Pipedrive.Deal.LastActivityDate]": "last_activity_date",
  "[Pipedrive.Deal.LastEmailReceived]": "last_incoming_mail_time",
  "[Pipedrive.Deal.LastEmailSent]": "last_outgoing_mail_time",
  "[Pipedrive.Deal.LastStageChange]": "stage_change_time",
  "[Pipedrive.Deal.LostReason]": "lost_reason",
  "[Pipedrive.Deal.LostTime]": "lost_time",
  "[Pipedrive.Deal.NextActivityDate]": "next_activity_date",
  "[Pipedrive.Deal.Organization.Address]": "org_id.address",
  "[Pipedrive.Deal.Organization.CCEmail]": "org_id.cc_email",
  "[Pipedrive.Deal.Organization.Id]": "org_id.value",
  "[Pipedrive.Deal.Organization.Name]": "org_id.name",
  "[Pipedrive.Deal.Organization.Owner.Id]": "org_id.owner_id",
  "[Pipedrive.Deal.Organization.PeopleCount]": "org_id.people_count",
  "[Pipedrive.Deal.Owner.Email]": "user_id.email",
  "[Pipedrive.Deal.Owner.Id]": "user_id.id",
  "[Pipedrive.Deal.Owner.Name]": "user_id.name",
  // "[Pipedrive.Deal.OwnerName]": "owner_name",
  "[Pipedrive.Deal.PersonName]": "person_name",
  "[Pipedrive.Deal.Probability]": "probability",
  //"[Deal.Product_amount]": "---",
  //"[Deal.Product_name]": "---",
  "[Pipedrive.Deal.Product_quantity]": "products_count",
  "[Pipedrive.Deal.Stage]": "stage_id",
  "[Pipedrive.Deal.Status]": "status",
  "[Pipedrive.Deal.Title]": "title",
  "[Pipedrive.Deal.TotalActivities]": "activities_count",
  "[Pipedrive.Deal.UpdateTime]": "update_time",
  "[Pipedrive.Deal.Value]": "value",
  //"[Deal.VisibleTo]": "All users",
  "[Pipedrive.Deal.WeightedValue.Currency]": "weighted_value_currency",
  "[Pipedrive.Deal.WeightedValue.Formatted]": "formatted_weighted_value",
  "[Pipedrive.Deal.WeightedValue.Value]": "weighted_value",
  "[Pipedrive.Deal.WonTime]": "won_time",
  "[Pipedrive.Person.ActivitiesToDo]": "person.activities_count",
  "[Pipedrive.Person.ClosedDeals]": "person.closed_deals_count",
  "[Pipedrive.Person.Created]": "person.add_time",
  "[Pipedrive.Person.DoneActivities]": "person.done_activities_count",
  "[Pipedrive.Person.Email]": "person.primary_email",
  "[Pipedrive.Person.EmailMessagesCount]": "person.email_messages_count",
  "[Pipedrive.Person.FirstName]": "person.first_name",
  // "[Pipedrive.Person.ID]": "person.id",
  "[Pipedrive.Person.Label]": "person.label",
  "[Pipedrive.Person.LastActivityDate]": "person.last_activity_date",
  "[Pipedrive.Person.LastEmailReceived]": "person.last_incoming_mail_time",
  "[Pipedrive.Person.LastEmailSent]": "person.last_outgoing_mail_time",
  "[Pipedrive.Person.LastName]": "person.last_name",
  "[Pipedrive.Person.LostDeals]": "person.lost_deals_count",
  "[Pipedrive.Person.Name]": "person.name",
  "[Pipedrive.Person.NextActivityDate]": "person.next_activity_date",
  "[Pipedrive.Person.OpenDeals]": "person.open_deals_count",
  "[Pipedrive.Person.Organization.Address]": "person.postal_address",
  "[Pipedrive.Person.Organization.Id]": "person.org_id.value",
  "[Pipedrive.Person.Organization.Name]": "person.org_name",
  "[Pipedrive.Person.Owner.Email]": "person.owner_id.email",
  "[Pipedrive.Person.Owner.Id]": "person.owner_id.id",
  "[Pipedrive.Person.Owner.Name]": "person.owner_id.name",
  "[Pipedrive.Person.Phone]": "person.phone[0].value",
  "[Pipedrive.Person.TotalActivities]": "person.activities_count",
  "[Pipedrive.Person.UpdateTime]": "person.update_time",
  "[Pipedrive.Person.VisibleTo]": "person.visible_to",
  "[Pipedrive.Person.WonDeals]": "person.won_deals_count",
  // [Person.Organization.Owner.Id]	---
  // [Person.Organization.PeopleCount]	---
  // [Person.Organization.CCEmail]:
};

export const pipedriveLogo = "https://i.imgur.com/XsLCQB4.png";

export const PIPEDRIVE_CLIENT_ID = "9ff26d9bc436946d";
// dev
// export const PIPEDRIVE_CLIENT_ID = "622755404e77ddae";
