export const BLOCKING = {
  FOLDER: {
    title: "Join BoloForms Signature Beta",
    desc: "Experience features when they are released instantly, instead of waiting for 2 months. Latest features- Folder Structure, custom PDF names by reviewing us and after review send us the screenshot in the live chat.",
    videoSrc: "",
    redirect:
      "https://reviews.capterra.com/products/new/ed28049a-8f11-4b79-b185-28ad436eb92e",
    redirectText: "Unlock Now",
  },
  BLANK: {
    title: "",
    desc: "",
    videoSrc: "",
    redirect: "",
    redirectText: "",
  },
};
