import { z } from "zod";
import {
  Lang,
  Size,
  Alignment,
  barcodeSchemaTypes,
  schemaTypes as _schemaTypes,
  BarcodeSchemaType,
  SchemaType,
  CommonSchema as _CommonSchema,
  TextSchema,
  ImageSchema,
  BarcodeSchema,
  Schema,
  SchemaInputs,
  SchemaForUI,
  Font,
  BasePdf,
  Template,
  CommonProps,
  GeneratorOptions,
  GenerateProps,
  UIOptions,
  UIProps,
  PreviewProps,
  PreviewReactProps,
  DesignerProps,
  DesignerReactProps,
  DropDownOptionSchemaObject,
  // VariablesHelper,
  CustomVariableType,
  InstanceFromType,
  PreviewFetchedType,
  PDFReceivers,
  SendToSignersFunctionType,
  SendToSignersArgsTypes,
  LineSchema,
  TableSchema,
  TableRow,
  TableCell,
  StylingSchema,
  PriceSubTotal,
  // CheckboxShema,
  MapperType,
  SetMapperType,
} from "./schema";

type CommonSchema = z.infer<typeof _CommonSchema>;
export const schemaTypes = _schemaTypes;
// export const isCheckboxSchema = (arg: CommonSchema): arg is CheckboxSchema =>
//   arg.subType === "checkbox";
export const isTextSchema = (arg: CommonSchema): arg is TextSchema =>
  arg.type === "text";
export const isImageSchema = (arg: CommonSchema): arg is ImageSchema =>
  arg.type === "image";
export const isBarcodeSchema = (arg: CommonSchema): arg is BarcodeSchema =>
  barcodeSchemaTypes.map((t) => t as string).includes(arg.type);
export const isLineSchema = (arg: CommonSchema): arg is LineSchema =>
  arg.type === "line";
export const isTableSchema = (arg: CommonSchema): arg is TableSchema =>
  arg.type === "table";

export type Lang = z.infer<typeof Lang>;
export type Size = z.infer<typeof Size>;
export type Alignment = z.infer<typeof Alignment>;
export type SchemaType = z.infer<typeof SchemaType>;
export type BarCodeType = z.infer<typeof BarcodeSchemaType>;
export type TextSchema = z.infer<typeof TextSchema>;
export type ImageSchema = z.infer<typeof ImageSchema>;
export type BarcodeSchema = z.infer<typeof BarcodeSchema>;
export type LineSchema = z.infer<typeof LineSchema>;
export type TableSchema = z.infer<typeof TableSchema>;
export type TableRow = z.infer<typeof TableRow>;
export type TableCell = z.infer<typeof TableCell>;
export type PriceSubTotalType = z.infer<typeof PriceSubTotal>;
export type StylingSchema = z.infer<typeof StylingSchema>;
// export type CheckboxSchema = z.infer<typeof CheckboxShema>;
export type Schema = z.infer<typeof Schema>;
export type SchemaInputs = z.infer<typeof SchemaInputs>;
export type SchemaForUI = z.infer<typeof SchemaForUI>;
export type DropdownOptionSchema = z.infer<typeof DropDownOptionSchemaObject>;
export type Font = z.infer<typeof Font>;
export type BasePdf = z.infer<typeof BasePdf>;
export type Template = z.infer<typeof Template>;
export type CommonProps = z.infer<typeof CommonProps>;
export type GeneratorOptions = z.infer<typeof GeneratorOptions>;
export type GenerateProps = z.infer<typeof GenerateProps>;
export type UIOptions = z.infer<typeof UIOptions>;
export type UIProps = z.infer<typeof UIProps>;
export type PreviewProps = z.infer<typeof PreviewProps>;
export type PreviewReactProps = z.infer<typeof PreviewReactProps>;
export type DesignerProps = z.infer<typeof DesignerProps>;
// export type VariablesHelper = z.infer<typeof VariablesHelper>;
export type CustomVariableType = z.infer<typeof CustomVariableType>;
export type DesignerReactProps = z.infer<typeof DesignerReactProps>;
export type InstanceFromType = z.infer<typeof InstanceFromType>;
export type PreviewFetchedType = z.infer<typeof PreviewFetchedType>;
export type PDFReceivers = z.infer<typeof PDFReceivers>;
export type SendToSignersFunctionType = z.infer<
  typeof SendToSignersFunctionType
>;
export type SendToSignersArgsTypes = z.infer<typeof SendToSignersArgsTypes>;
export type MapperType = z.infer<typeof MapperType>;
export type SetMapperType = z.infer<typeof SetMapperType>;
