import AddPDFTemplateSigners from "../pages/AddPDFTemplateSigners";
import Billings from "../pages/Billings";
import Collaborators from "../pages/Collaborators";
import Create from "../pages/Create";
// import Dashboard from "../pages/Dashboard";
import Respondents from "../pages/FormRespondents";
import PdfTemplateRespondents from "../pages/PDFTemplateRespondents";
// import SharedDashboard from "../pages/SharedDashboard";
import Settings from "../pages/Settings";

import FoldersPage from "../components/FoldersPage";
import AssignedToMe from "../pages/AssignedToMe";
import FolderItems from "../pages/FolderItems";

// seperated dashboard
import FormsDashboard from "../pages/FormsDashboard";
import PdfsDashboard from "../pages/PdfsDashboard";
import TemplatesDashboard from "../pages/TemplatesDashboard";
import Contacts from "../pages/Contacts";
import AddonStore from "../pages/AddonStore";
import OpenSecureLink from "../pages/OpenSecureLink";
import ProductCatalog from "../pages/ProductCatalog/ProductCatalog";
import Payments from "../pages/Payments/Payments";
import Overview from "../pages/Overview/Overview";

// let previousSessionDocumentType = localStorage.getItem("documentType") ? localStorage.getItem("documentType") : "forms";

const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
  },
  // done
  {
    path: "/create",
    name: "Create Signature",
    element: Create,
  },
  {
    path: "/teams",
    name: "Create Team",
    element: Collaborators,
  },
  {
    path: "/contacts",
    name: "Contacts",
    element: Contacts,
  },
  // not using i guess
  // {
  //   path: "/shared-admin",
  //   name: "Shared Dashboard",
  //   element: SharedAdmin,
  // },
  //done
  // {
  //   path: "/shared-with-me",
  //   name: "Shared Dashboard",
  //   element: {<Navigate to={"/dashboard/forms"} replace />},
  // },
  {
    path: "/shared-with-me/folder",
    name: "Shared Dashboard",
    element: FoldersPage,
  },
  {
    path: "/shared-with-me/folder/items",
    name: "Folder Items",
    element: FolderItems,
  },

  // seperated shared dashboard

  {
    path: "/shared-with-me/forms",
    name: "Forms Dashboard",
    element: FormsDashboard,
  },
  {
    path: "/shared-with-me/pdfs",
    name: "PDFs Dashboard",
    element: PdfsDashboard,
  },

  {
    path: "/shared-with-me/templates",
    name: "Templates Dashboard",
    element: TemplatesDashboard,
  },

  // done
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   element: Dashboard,
  // },
  { path: "/folder", name: "Folders", element: FoldersPage },
  {
    path: "/folder/items",
    name: "Folder Items",
    element: FolderItems,
  },

  // seperated dashboard
  {
    path: "/dashboard/forms",
    name: "Forms Dashboard",
    element: FormsDashboard,
  },
  {
    path: "/dashboard/pdfs",
    name: "PDFs Dashboard",
    element: PdfsDashboard,
  },

  {
    path: "/dashboard/templates",
    name: "Templates Dashboard",
    element: TemplatesDashboard,
  },

  // {
  //   path: "/teams",
  //   name: "Create Team",
  //   element: Collaborators,
  // },
  // {
  //   path: "/create/pdf",
  //   name: "Create Signature",
  //   element: Home,
  // },
  // {
  //   path: "/create/pdf-signature",
  //   name: "Map Signatures",
  //   element: MapSignatures,
  // },
  // {
  //   path: "/create/pdf/edit",
  //   name: "Edit Envelope",
  //   element: Home,
  // },
  // {
  //   path: "/create/form-template",
  //   name: "Create Form Template",
  //   element: CreateTemplate,
  // },
  // {
  //   path: "/create/pdf-template",
  //   name: "Create PDF Template",
  //   element: PDFTemplate,
  // },
  //done
  {
    path: "/dashboard/templates/respondents",
    name: "Pdf Template Responses",
    element: PdfTemplateRespondents,
  },
  // {
  //   path: "/shared-with-me/respondents",
  //   name: "Pdf Template Responses",
  //   element: PdfTemplateRespondents,
  // },
  //done
  {
    path: "/create/pdf-template/send-for-signature",
    name: "Add signers for the pdf-template",
    element: AddPDFTemplateSigners,
  },
  // {
  //   path: "/pdf-template/response",
  //   name: "View response of pdf template",
  //   element: DocumentTemplateResponseViewer,
  // },
  // {
  //   path: "/create/pdf-mapping",
  //   name: "Design Form Template",
  //   element: DesignTemplate,
  // },
  // {
  //   path: "/form/pdf-template",
  //   name: "Pdf templated",
  //   element: PdfGenerator,
  // },
  // {
  //   path: "/create/form/pdf",
  //   name: "Link form to pdf",
  //   element: PdfGenerator,
  // },
  // {
  //   path: "/finished",
  //   name: "Finished",
  //   element: FinishedPage,

  // },
  // {
  //   path: "/pdf",
  //   name: "Pdf Dashboard",
  //   element: Envelopes,
  // },
  // {
  //   path: "/forms",
  //   name: "Form Dashboard",
  //   element: FinishedForms,
  // },
  // done
  { path: "/form", name: "Respondents", element: Respondents },
  // {
  //   path: "/finished/response",
  //   name: "Dashboard",
  //   element: DocumentResponses,
  // },
  // {
  //   path: "/forms/settings",
  //   name: "Form Settings",
  //   element: FormSettings,
  // },
  // {
  //   path: "/forms/pdf-generator",
  //   name: "Form Pdf Generator",
  //   element: PdfGenerator,
  // },
  // {
  //   path: "/sign",
  //   name: "Dashboard",
  //   element: DocumentSigner,
  // },
  // {
  //   path: "/signature-success",
  //   name: "signature-success",
  //   element: SignatureSuccess,
  //   exact: true,
  // },
  // done
  { path: "/billings", name: "Billings", element: Billings },
  // { path: "/settings", name: "Settings", element: Settings },
  //  done
  {
    path: "assigned-to-me",
    name: "Assigned to me",
    element: AssignedToMe,
  },
  {
    path: "apps",
    name: "Addon Store",
    element: AddonStore,
  },

  { path: "/settings", name: "Settings", element: Settings },
  {
    path: "/payments/product-catalog",
    name: "Product Catalog",
    element: ProductCatalog,
  },
  {
    path: "/payments",
    name: "Payments",
    element: Payments,
  },
  {
    path: "/overview",
    name: "Overview",
    element: Overview,
  },
];

export default routes;
