import { useAppSelector } from "../../redux/store";
import { BoloButton } from "../Common/Button/BoloButton";

// can move this component in sperate file
const WLPriceContact = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  return (
    <div className="flex-1 w-full flex flex-col border-2 border-gray-500 rounded-lg h-full">
      <div className="px-4 py-2 bg-primary text-white text-xl rounded-t-md font-semibold">
        Pricing
      </div>

      <div className="flex flex-col items-start justify-between h-full px-4 py-5 rounded-b-md bg-white">
        <p className="text-sm text-gray-600 h-[80px]">
          To learn more about pricing, plans, quotas, and feature access, please
          contact us at the email address below.
        </p>

        <div className="flex flex-col self-center items-center gap-y-2">
          <div className="flex flex-col mb-8">
            <div className="flex items-center flex-col">
              <img
                className=" rounded-full mb-6"
                src={whiteLabelInfo?.settings?.organization?.logo}
                height="80"
                width="80"
              />
              <p className="text-2xl font-semibold">
                Connect with {whiteLabelInfo?.settings?.organization?.name} at
              </p>
              <p>
                {whiteLabelInfo?.settings?.organization?.pricingContactEmail}
              </p>
            </div>
          </div>

          <BoloButton
            size={"sm"}
            className="rounded-lg"
            onClick={() => {
              window.open(
                `mailto:${whiteLabelInfo?.settings?.organization?.pricingContactEmail}`,
                "_blank"
              );
            }}
          >
            Contact Us
          </BoloButton>
        </div>
      </div>
    </div>
  );
};

export default WLPriceContact;
