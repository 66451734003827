//@ts-nocheck

import React, { useEffect } from "react";
import "./index.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { replaceHTags } from "../../../utils/editor";

const FormDescInput = ({ helpers }) => {
  let { formState, setFormState } = helpers;
  let questionQuillRef = React.useRef(null);

  // const [quilleditor, setQuilleditor] = React.useState(null);
  const [unprivilegedEditor, setUnprivilegedEditor] = React.useState(null);

  function showEditMenu(display) {
    let input = document.querySelector("#quill-form-desc .ql-toolbar");
    // console.log(input);
    input.style.display = display;
  }

  useEffect(() => {
    let quilleditor = questionQuillRef.current?.getEditor();
    // setQuilleditor(quilleditor);
    const unprivilegedEditor =
      questionQuillRef.current?.makeUnprivilegedEditor(quilleditor);
    setUnprivilegedEditor(unprivilegedEditor);
  }, []);

  return (
    <div className="w-full mt-2.5 mr-4">
      <ReactQuill
        ref={questionQuillRef}
        value={formState?.descriptionHtml ?? formState?.description}
        placeholder="Form Title"
        onChange={async (value) => {
          let newValue = value;
          newValue = await replaceHTags(newValue);
          setFormState((prevState) => {
            const newForm = { ...prevState };
            newForm.descriptionHtml = newValue;
            unprivilegedEditor?.getText() &&
              (newForm.description = unprivilegedEditor?.getText());
            return newForm;
          });
        }}
        onFocus={() => {
          // console.log("focus");
          showEditMenu("flex");
          // document.querySelector(".ql-toolbar").style.display = "flex";
        }}
        onBlur={() => {
          // setIsEditorActive(false);
          // document.querySelector(".ql-toolbar").style.display = "none";
          showEditMenu("none");
        }}
        modules={{
          toolbar: [
            ["bold", "italic", "underline"],
            [
              "link",
              // "image"
            ],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"],
          ],
        }}
        style={{ fontSize: "14px" }}
        className="fade quill-que flex"
        id="quill-form-desc"
      />
    </div>
  );
};

export default FormDescInput;
