/* eslint dot-notation: "off"*/
import { z } from "zod";

const langs = ["en", "ja", "ar", "th", "pl"] as const;
export const Lang = z.enum(langs);

export const Size = z.object({ height: z.number(), width: z.number() });

const alignments = ["left", "center", "right"] as const;
export const Alignment = z.enum(alignments);

// prettier-ignore
export const barcodeSchemaTypes = ['qrcode', 'japanpost', 'ean13', 'ean8', 'code39', 'code128', 'nw7', 'itf14', 'upca', 'upce', 'gs1datamatrix'] as const;
const notBarcodeSchemaTypes = ["text", "image", "line", "table"] as const;
const signatureRelatedTypes = [
  "signature",
  "initial",
  "date",
  "checkbox",
  "radiogroup",
  "stamp",
  "dropdown",
  "name",
  "email",
  "number",
  "image",
  "static_image",
  "file_upload",
  "imageFileUpload",
] as const;

export const schemaTypes = [
  ...notBarcodeSchemaTypes,
  ...barcodeSchemaTypes,
  ...signatureRelatedTypes,
] as const;

export const BarcodeSchemaType = z.enum(barcodeSchemaTypes);
export const SchemaType = z.enum(schemaTypes);

export const CommonSchema = z.object({
  type: SchemaType,
  position: z.object({ x: z.number(), y: z.number() }),
  width: z.number(),
  height: z.number(),
  rotate: z.number().optional(),
  required: z.boolean().optional(),
  subType: z.string().optional(),
  ifDateFormat: z.string().optional(),
  signatureColour: z.string().optional(),
  backgroundColor: z.string().optional(),
  isQuestionDeleted: z.boolean().optional(),
  role: z
    .object({
      title: z.string(),
      colour: z.string(),
    })
    .optional(),
  placeholder: z.string().optional(),
  isStatic: z.boolean().optional(),
  linkGroup: z.string().optional(),
});

export const StylingSchema = z.object({
  alignment: Alignment.optional(),
  fontSize: z.number().optional(),
  fontName: z.string().optional(),
  fontColor: z.string().optional(),
  backgroundColor: z.string().optional(),
  characterSpacing: z.number().optional(),
  lineHeight: z.number().optional(),
  dynamicFontSize: z
    .object({
      max: z.number(),
      min: z.number(),
    })
    .optional(),
  bold: z.boolean().optional(),
  italic: z.boolean().optional(),
});

export const TextSchema = CommonSchema.merge(StylingSchema).extend({
  type: z.literal(SchemaType.Enum.text),
  isLink: z.boolean().optional(),
  hyperLink: z.string().optional(),
  // alignment: Alignment.optional(),
  // fontSize: z.number().optional(),
  // fontName: z.string().optional(),
  // fontColor: z.string().optional(),

  // characterSpacing: z.number().optional(),
  // lineHeight: z.number().optional(),
  // dynamicFontSize: z
  //   .object({
  //     max: z.number(),
  //     min: z.number(),
  //   })
  //   .optional(),
  checked: z.boolean().optional(),
});

export const ImageSchema = CommonSchema.extend({
  type: z.literal(SchemaType.Enum.image),
});

// ----------------------------------------------------------
// Table related schemas

export const TableCellSchema = TextSchema;

export const TableCell = z.object({
  data: z.string(),
  // price table changes
  header: z.string().optional(),
  description: z.string().optional(),
  images: z.array(z.string()).optional(),
  price: z.number().optional(),
  qty: z.number().optional(),
  subtotal: z.number().optional(),
});

export const TableRow = z.array(TableCell);

export const PriceSubTotal = z.object({
  symbol: z.string().optional(),
  currency: z.string(),
  subtotal: z.object({
    amount: z.number(),
    data: z.string(),
  }),
  discount: z.object({
    amount: z.number(),
    percentage: z.number(),
    format: z.enum(["percent", "absolute"]),
    show2Rec: z.boolean(),
    data: z.string(),
  }),
  fee: z.object({
    amount: z.number(),
    percentage: z.number(),
    format: z.enum(["percent", "absolute"]),
    show2Rec: z.boolean(),
    data: z.string(),
  }),
  tax: z.object({
    amount: z.number(),
    percentage: z.number(),
    format: z.enum(["percent", "absolute"]),
    show2Rec: z.boolean(),
    data: z.string(),
  }),
  total: z.object({ amount: z.number(), data: z.string() }),
});

export const TableSchema = TextSchema.extend({
  head: TableRow,
  headStyle: StylingSchema,
  rows: z.array(TableRow),
  headWidthPercentages: z.array(z.number()),
  tableBorderColor: z.string(),
  tableBorderWidth: z.number(),
  type: z.literal(SchemaType.Enum.table),
  subtotalMenu: PriceSubTotal.optional(),
});

// line schema
export const LineSchema = CommonSchema.extend({
  type: z.literal(SchemaType.Enum.line),
});
// ----------------------------------------------------------

// export const CheckboxShema = CommonSchema.extend({
//   type: z.literal(SchemaType.Enum.checkbox),
//   checked: z.boolean().optional(),
// });

export const BarcodeSchema = CommonSchema.extend({ type: BarcodeSchemaType });

export const DropDownOptionSchemaObject = z.object({
  value: z.string(),
  option: z.string(),
});

export const Schema = z.union([
  TextSchema,
  ImageSchema,
  BarcodeSchema,
  LineSchema,
  TableSchema,
]);

const SchemaForUIAdditionalInfo = z.object({
  id: z.string(),
  key: z.string(),
  showKey: z.string(),
  data: z.string(),
  radiogroupId: z.string().optional(),
  radiogroupColor: z.string().optional(),
  dropdownOptions: z.array(DropDownOptionSchemaObject),
  variableName: z.string().optional(), // for custom variable
  contactFieldName: z.string().optional(), // for custom contact field
});

export const SchemaForUI = z.union([
  TextSchema.merge(SchemaForUIAdditionalInfo),
  ImageSchema.merge(SchemaForUIAdditionalInfo),
  BarcodeSchema.merge(SchemaForUIAdditionalInfo),
  LineSchema.merge(SchemaForUIAdditionalInfo),
  TableSchema.merge(SchemaForUIAdditionalInfo),
  // CheckboxShema.merge(SchemaForUIAdditionalInfo),
]);

const ArrayBufferSchema: z.ZodSchema<ArrayBuffer> = z
  .any()
  .refine((v) => v instanceof ArrayBuffer);
const Uint8ArraySchema: z.ZodSchema<Uint8Array> = z
  .any()
  .refine((v) => v instanceof Uint8Array);

export const Font = z.record(
  z.object({
    data: z.union([z.string(), ArrayBufferSchema, Uint8ArraySchema]),
    fallback: z.boolean().optional(),
    subset: z.boolean().optional(),
  })
);

export const BasePdf = z.union([
  z.string(),
  ArrayBufferSchema,
  Uint8ArraySchema,
]);

export const Template = z.object({
  schemas: z.array(z.record(Schema)),
  basePdf: BasePdf,
  sampledata: z.array(z.record(z.string())).length(1).optional(),
  columns: z.array(z.string()).optional(),
});

export const Inputs = z.array(z.record(z.string())).min(1);

const CommonOptions = z.object({ font: Font.optional() });

export const PDFReceivers = z.object({
  name: z.string(), // Expected type: string
  email: z.string(), // Expected type: string
  message: z.string().optional(),
  permission: z.string().optional(), // Expected type: string
  sentUrl: z.string().optional(),
});

export const SendToSignersArgsTypes = z.object({
  redirect: z.boolean().optional(),
  isLink: z.boolean().optional(),
});

export const SendToSignersFunctionType = z
  .function()
  .args(SendToSignersArgsTypes)
  .returns(z.promise(z.any()))
  .optional();

export const CommonProps = z.object({
  template: Template,
  options: CommonOptions.optional(),
  formQuestions: z
    .array(
      z.object({
        type: z.string(), // Expected type: string
        title: z.string(), // Expected type: string
        required: z.boolean(), // Expected type: boolean
      })
    )
    .optional(),
  isOnlyPdfTemplate: z.boolean().optional(),
  isPreview: z.boolean().optional(),
  receiversList: z
    .array(
      // z.object({
      //   name: z.string(), // Expected type: string
      //   email: z.string(), // Expected type: string
      //   message: z.string().optional(),
      //   permission: z.string().optional(), // Expected type: string
      //   sentUrl: z.string().optional(),
      // })
      PDFReceivers
    )
    .optional(),
  roles: z
    .array(
      z.object({
        title: z.string(), // Expected type: string
        colour: z.string(), // Expected type: string
      })
    )
    .optional(),
  sendToSigners: SendToSignersFunctionType,
  //   z
  //     .function()
  //     .args(SendToSignersArgsTypes)
  //     .returns(z.promise(z.any()))
  //     .optional(),
});

// -------------------generate-------------------

export const GeneratorOptions = CommonOptions;

export const GenerateProps = CommonProps.extend({
  inputs: Inputs,
  options: GeneratorOptions.optional(),
}).strict();

export const SchemaInputs = z.record(z.string());

// ---------------------------------------------

export const UIOptions = CommonOptions.extend({ lang: Lang.optional() });

const HTMLElementSchema: z.ZodSchema<HTMLElement> = z
  .any()
  .refine((v) => v instanceof HTMLElement);

export const UIProps = CommonProps.extend({
  domContainer: HTMLElementSchema,
  options: UIOptions.optional(),
});

// -----------------Form, Viewer-----------------
export const MapperType = z.object({
  showSideBar: z.enum(["MINI_PDF", "ATTACHMENTS"]),
});

export const SetMapperType = z.function().args(MapperType);

export const PreviewFetchedType = z.object({
  accountInfo: z.any().optional(),
  document: z.any().optional(),
});
export const PreviewProps = UIProps.extend({
  inputs: Inputs,
  setShownModal: z.function().args(z.boolean()).returns(z.void()).optional(),
  isPreview: z.boolean().optional(),
  previewStyle: z.string().optional(),
  isSigning: z.boolean().optional(),
  fetchedData: PreviewFetchedType.optional(),
  unfilledFieldsKeys: z.array(z.string()).optional(),
  isApprover: z.boolean().optional(),
  mapperState: MapperType.optional(),
  setMapperState: SetMapperType.optional(),
});
export const PreviewReactProps = PreviewProps.omit({
  domContainer: true,
}).extend({
  onChangeInput: z
    .function()
    .args(z.object({ index: z.number(), value: z.string(), key: z.string() }))
    .returns(z.void())
    .optional(),
  size: Size,
  isPreview: z.boolean().optional(),
  isSigning: z.boolean().optional(),
  unfilledFieldsKeys: z.array(z.string()).optional(),
  isApprover: z.boolean().optional(),
});

// ---------------Designer---------------

export const CustomVariableType = z.object({
  varName: z.string(),
  varValue: z.string(),
});

// export const VariablesHelper = z.object({
//   customVariables: z.array(CustomVariableType),
//   // setCustomVariables: z.function().args(z.array(CustomVariableType)),
//   setCustomVariables: z.function().args(z.any()),
// });

export const InstanceFromType = z.enum(["PDF", "PDF-TEMPLATE", "FORM"]);

export const DesignerProps = UIProps.extend({
  fetchedData: z.any(),
  setReload: z.function().args(z.any()),
  setIsPreview: z.function().args(z.any()),
  // variablesHelper: VariablesHelper.optional(),
  instanceFrom: InstanceFromType,
  inputs: Inputs.optional(),
}).strict();
export const DesignerReactProps = DesignerProps.omit({
  domContainer: true,
}).extend({
  onSaveTemplate: z.function().args(Template).returns(z.void()),
  onSaveTemplateWithoutRedirect: z
    .function()
    .args(Template)
    .returns(z.void())
    .optional(),
  size: Size,
});
