import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
// import {
//   DEFAULT_FONT_SIZE,
//   DEFAULT_ALIGNMENT,
//   DEFAULT_LINE_HEIGHT,
//   DEFAULT_CHARACTER_SPACING,
//   DEFAULT_FONT_COLOR,
//   TextSchema,
//   calculateDynamicFontSize,
// } from '@pdfme/common';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DEFAULT_ALIGNMENT,
  DEFAULT_CHARACTER_SPACING,
  DEFAULT_FONT_COLOR,
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  TextSchema,
  calculateDynamicFontSize,
} from "../../../../common/src";
import { STATIC_SCHEMA, ZOOM } from "../../constants";
import { FontContext } from "../../contexts";
import { SchemaUIProps } from "./SchemaUI";
import _ from "lodash";
import dayjs from "dayjs";
import { getWhiteLabelInfoLocal } from "../../../../../utils";
import { getCSSFont } from "../../../../common/src/constants";
import { v4 } from "uuid";
import { ChangeSchemasArg } from "../Designer";
import { COLORS } from "../../../../../constants/common";
import { onResizeMoveOtherSchema } from "../../helper";
import { isEditorSchema } from "../../designerHelper";
type Props = SchemaUIProps & { schema: TextSchema };

const TextSchemaUI = (
  {
    schema,
    editable,
    placeholder,
    tabIndex,
    onChange,
    isActive,
    setActiveSchema,
    setShownModal,
    outlineStyle,
    isPreview,
    previewStyle,
    isSigning,
    previewState,
    changeSchemas,
    pageSizes,
    pageCursor,
    isApprover,
    designerState,
  }: Props,
  ref: Ref<HTMLTextAreaElement> | Ref<HTMLInputElement>
) => {
  const textAreaId = v4() + "text_area";
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const primaryColor =
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary;
  const font = useContext(FontContext);

  const [dynamicFontSize, setDynamicFontSize] = useState<number | undefined>(
    undefined
  );
  const isEditorARecipient = isEditorSchema({
    schema,
    receiversList: designerState?.receiversList || [],
    instanceFrom: designerState?.instanceFrom || "PDF-TEMPLATE",
  });

  useEffect(() => {
    if (schema.dynamicFontSize && schema.data) {
      calculateDynamicFontSize({
        textSchema: schema,
        font,
        input: schema.data,
      }).then(setDynamicFontSize);
    } else {
      setDynamicFontSize(undefined);
    }
  }, [
    schema.data,
    schema.width,
    schema.fontName,
    schema.fontSize,
    schema.dynamicFontSize,
    schema.dynamicFontSize?.max,
    schema.dynamicFontSize?.min,
    schema.characterSpacing,
    font,
  ]);

  const getBackground = () => {
    if (isPreview && previewStyle !== "DEFAULT") return "transparent";

    if (schema?.isQuestionDeleted) {
      return "rgb(255 0 0 / 0.30)";
    }

    if (schema.backgroundColor) {
      return schema.backgroundColor;
    }

    if (isPreview || isSigning) {
      if (schema.isStatic) {
        return "transparent";
      }
      if (previewState?.fetchedData?.accountInfo?.theme) {
        if (isActive && editable) {
          return previewState?.fetchedData?.accountInfo?.theme + "70";
        } else {
          return previewState?.fetchedData?.accountInfo?.theme + "20";
        }
      } else {
        if (isActive && editable) {
          // return primaryColor + "70";
          return `hsl(var(--primary) / 0.55)`;
        } else {
          // return primaryColor + "20";
          return `hsl(var(--primary) / 0.2)`;
        }
      }
    }
    if (isApprover && schema?.isStatic) {
      return "transparent";
    }

    if (isActive && editable) {
      if (schema?.isStatic) return STATIC_SCHEMA.color + 70;

      if (schema?.signatureColour) return schema?.signatureColour + 70;

      if (schema?.role?.colour) return schema?.role?.colour + "70";
    } else {
      if (schema?.isStatic) return STATIC_SCHEMA.color + 20;

      if (schema?.signatureColour) return schema?.signatureColour + 20;

      if (schema?.role?.colour) return schema?.role?.colour + "20";
    }
  };

  const style: React.CSSProperties = {
    padding: "0.2rem",
    resize: "none",
    position: "absolute",
    fontFamily:
      getCSSFont({
        currFont: schema?.fontName,
      }) || "Poppins",
    height: schema.height * ZOOM,
    width: schema.width * ZOOM,
    textAlign: schema.alignment ?? DEFAULT_ALIGNMENT,
    verticalAlign: "middle",
    fontSize: `${dynamicFontSize ?? schema.fontSize ?? DEFAULT_FONT_SIZE}pt`,
    letterSpacing: `${schema.characterSpacing ?? DEFAULT_CHARACTER_SPACING}pt`,
    lineHeight: `${schema.lineHeight ?? DEFAULT_LINE_HEIGHT}em`,
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    border: "none",
    color: schema.fontColor ? schema.fontColor : DEFAULT_FONT_COLOR,
    backgroundColor: getBackground(),
    fontWeight: schema.bold ? 600 : 400,
    fontStyle: schema.italic ? "italic" : "normal",
  };
  const variableStyle: React.CSSProperties = {
    ...style,
    backgroundColor: "transparent",
  };
  const dateStyle: React.CSSProperties = {
    padding: "0.2rem",
    resize: "none",
    // position: "absolute",
    fontFamily:
      getCSSFont({
        currFont: schema?.fontName,
      }) || "Poppins",
    height: schema.height * ZOOM,
    width: schema.width * ZOOM,
    textAlign: schema.alignment ?? DEFAULT_ALIGNMENT,
    verticalAlign: "middle",
    fontSize: `${dynamicFontSize ?? schema.fontSize ?? DEFAULT_FONT_SIZE}pt`,
    letterSpacing: `${schema.characterSpacing ?? DEFAULT_CHARACTER_SPACING}pt`,
    lineHeight: `${schema.lineHeight ?? DEFAULT_LINE_HEIGHT}em`,
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    border: "none",
    color: schema.fontColor ? schema.fontColor : DEFAULT_FONT_COLOR,
    backgroundColor: getBackground(),
    fontWeight: schema.bold ? 600 : 400,
    fontStyle: schema.italic ? "italic" : "normal",
  };

  const dateFormat = schema?.ifDateFormat?.replace("mm", "MM") || "mm/dd/yyyy";

  function isValidNumber(inputString: string): boolean {
    if (inputString == "" || inputString == "-" || inputString == ".") {
      return true;
    }
    const pattern = /^-?0*\d*\.?\d+$/;
    return pattern.test(inputString);
  }

  // why is this function implemented
  // previouly in editor we used to show schema.data
  // now we want to show schema.data || schema.placeholder
  // issue: 1. in text it works fine as parsing text to text does not give problem
  // issue: 2. in number the data is by default "Number" which cannot be parsed to number
  // issue: 3. in date the data is by default "Date" which cannot be parsed to date
  // there fore does not showup in data editor default value input
  // sol: in editor based on subtype we check data
  // if data is "Number" || "Date" we show placeholder
  // in number if the data is parseable to num we show it
  // similarly for date if the date is valid we show it
  function getPrasedSchemaData(): string {
    if (designerState?.mode === "PREVIEW") {
      if (schema.subType == "email") {
        return schema.data;
      }

      if (schema.subType == "name") {
        return schema.data;
      }
    }

    if (schema.subType == "text") {
      return schema.data;
    }

    if (schema.subType == "date") {
      let isDateValid = dayjs(
        schema.data,
        schema?.ifDateFormat?.toUpperCase() || "MM/DD/YYYY",
        true
      ).isValid();

      if (isDateValid) {
        return schema.data;
      } else {
        return "";
      }
    }

    if (schema.subType == "number") {
      let isNumberValid = !isNaN(Number(schema.data));
      if (isNumberValid) {
        return schema.data;
      } else {
        return "";
      }
    }

    if (schema.subType === "variable") {
      return schema.data;
    }

    return "";
  }

  function handleHeight(): ChangeSchemasArg[] {
    if (
      isPreview ||
      isSigning ||
      // removed this because now we want the editor fields could also adjust the height
      // !schema.isStatic ||
      !changeSchemas ||
      !pageSizes ||
      typeof pageCursor == "undefined"
    ) {
      return [];
    }
    let data: ChangeSchemasArg[] = [];
    let onResizeOtherSchemas: {
      changeSchemas: ChangeSchemasArg[];
      isBottomOverFlow: boolean;
    } = { changeSchemas: [], isBottomOverFlow: false };

    const textarea = document.getElementById(textAreaId) as HTMLTextAreaElement;
    if (textarea) {
      const scrollHeight = textarea.scrollHeight;
      let calcHeight = _.round(scrollHeight / ZOOM, 2);
      if (calcHeight + schema.position.y > pageSizes[pageCursor].height) {
        data = [];
      } else {
        let targetOG: HTMLElement | null = document.getElementById(schema.id);
        if (targetOG) {
          let target: HTMLElement = targetOG.cloneNode(true) as HTMLElement;
          target.style.height = `${scrollHeight}px`;
          onResizeOtherSchemas = onResizeMoveOtherSchema({
            customSchema: designerState?.settings?.customSchema || [],
            pageCursor: designerState?.pageCursor || 0,
            pageSizes,
            schemasList: designerState?.schemasList || [],
            target,
            preventOverflow: true,
          });
        }
        if (!onResizeOtherSchemas.isBottomOverFlow) {
          data = [{ key: "height", value: calcHeight, schemaId: schema.id }];
        }
      }
    }
    return [...data, ...onResizeOtherSchemas.changeSchemas];
  }

  // same convention is to be followed in preview
  function getEditorSpanData(): string {
    return getPrasedSchemaData() || schema?.placeholder || schema.showKey || "";
  }
  function getTitle(): string {
    if (schema?.isQuestionDeleted) {
      return "This Question is Deleted";
    } else {
      if (isApprover) {
        if (
          (!schema?.isStatic || schema?.subType !== "variable") &&
          schema?.role
        ) {
          let signer = previewState?.signersData?.[schema?.role?.title];
          return signer?.name || signer?.email || schema?.showKey;
        }
      }
      return schema?.showKey;
    }
  }
  // for all normal i.e non static schemas it should works as it worked previously
  // for staic schemas
  // condition 1. in designer it should be editable
  // condition 2. in form viewer it should be shown as text
  let newEditable = editable || isEditorARecipient;
  if (schema.isStatic) {
    newEditable = !(isPreview || isSigning || isApprover);
  }
  // format the below return properly
  // too many ternaries
  // console.log(schema, "newEditable", newEditable);
  return (
    <div
      title={getTitle()}
      className={`w-0 h-0`}
      onClick={() => {
        if (setActiveSchema) {
          setActiveSchema(schema);
        }
      }}
    >
      {newEditable ? (
        schema.subType === "variable" ? ( //to show only data of variable without styling
          <div style={variableStyle}>{schema.data}</div>
        ) : schema.subType !== "date" ? (
          schema.subType === "number" ? (
            <input
              data-schema-sub-type={schema.subType}
              data-schema-id={schema.id}
              autoFocus={isActive}
              // @ts-ignore
              ref={ref}
              className={` ${
                isActive && newEditable
                  ? "bg-primary/70"
                  : "bg-primary bg-opacity-20"
              }`}
              type="number"
              placeholder={placeholder}
              tabIndex={tabIndex}
              style={style}
              onChange={(e) => {
                // console.log("Onchange triggreed", e.target.value);
                onChange(e.target.value);
              }}
              disabled={!newEditable || isApprover}
              value={schema.data}
            />
          ) : (
            <textarea
              id={textAreaId}
              data-schema-sub-type={schema.subType}
              data-schema-id={schema.id}
              autoFocus={isActive}
              // @ts-ignore
              ref={ref}
              className={` ${
                isActive && newEditable
                  ? "bg-primary/70"
                  : "bg-primary bg-opacity-20"
              }`}
              placeholder={placeholder}
              tabIndex={tabIndex}
              style={style}
              onChange={(e) => {
                // console.log("Onchange triggreed", e.target.value);
                let extra = handleHeight();
                onChange(e.target.value, extra);
              }}
              // Disable the contact_field and custom_field as these are not editable
              disabled={
                !newEditable ||
                // This is because in isEditorAReciepient codition this should not be disabled
                (!isEditorARecipient &&
                  (schema.subType === "date" ||
                    schema.subType === "name" ||
                    schema.subType === "email" ||
                    schema.subType === "contact_field" ||
                    schema.subType === "custom_field")) ||
                isApprover
              }
              value={schema.data}
            ></textarea>
          )
        ) : schema.subType === "date" ? (
          <DatePicker
            data-schema-sub-type={schema.subType}
            data-schema-id={schema.id}
            autoFocus={isActive}
            tabIndex={tabIndex}
            className="w-full h-full"
            placeholderText={placeholder}
            disabled={isApprover}
            selected={
              dayjs(
                schema?.data,
                schema?.ifDateFormat?.toUpperCase() || "MM/DD/YYYY"
              ).isValid()
                ? dayjs(
                    schema?.data,
                    schema?.ifDateFormat?.toUpperCase() || "MM/DD/YYYY"
                  ).toDate()
                : null
            }
            onChange={(date: Date | null) => {
              // setSelectedDate(date);
              let dateString = "";
              if (date) {
                dateString = dayjs(new Date(date)).format(
                  schema?.ifDateFormat?.toUpperCase() || "MM/DD/YYYY"
                );
              }
              onChange(dateString || "");
            }}
            dateFormat={dateFormat}
            customInput={
              <input
                data-schema-sub-type={schema.subType}
                data-schema-id={schema.id}
                style={dateStyle}
                placeholder={placeholder}
                className={` ${
                  isActive && newEditable
                    ? "bg-primary/70"
                    : "bg-primary bg-opacity-20"
                }`}
              />
            }
          />
        ) : (
          schema.subType === "date" &&
          schema?.isStatic && <div style={dateStyle}>Date</div>
        )
      ) : (
        <div
          style={style}
          className={` ${
            isActive && newEditable
              ? "bg-primary/70"
              : "bg-primary bg-opacity-20"
          }`}
          onClick={() => {
            if (schema.isLink && schema.hyperLink) {
              window.open(schema.hyperLink || "", "_blank");
            }
          }}
        >
          {schema.subType == "variable" && schema.data == "" ? ( //To show the variable name if the vartiable data is not set
            <span>{schema.variableName}</span>
          ) : (
            <>
              {/*  Set the letterSpacing of the last character to 0. */}
              {getEditorSpanData()
                .split("")
                .map((l, i) => (
                  <span
                    key={i}
                    style={{
                      letterSpacing:
                        String(getEditorSpanData()).length === i + 1
                          ? 0
                          : "inherit",
                    }}
                  >
                    {l}
                  </span>
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default forwardRef<HTMLTextAreaElement, Props>(TextSchemaUI);
