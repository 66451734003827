import { Switch } from "@mui/material";
import { alpha, styled } from "@mui/system";
import React from "react";
import { COLORS } from "../../../constants/common";
import { getWhiteLabelInfoLocal } from "../../../utils";

const whiteLabelInfo = getWhiteLabelInfoLocal();
const BoloRadioButtonMui = styled(Switch)(
  ({ variant }: { variant?: "default" | "bolo-primary" }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color:
        variant == "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
      "&:hover": {
        backgroundColor: alpha(
          variant == "bolo-primary"
            ? COLORS.primary
            : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
                COLORS.primary,
          0
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor:
        variant == "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
    },
  })
);

export default BoloRadioButtonMui;
