import React, { useContext } from "react";
// import { SchemaForUI } from '@pdfme/common';
import { readFiles } from "../../../../helper";
import { FontContext, I18nContext } from "../../../../contexts";
import { SidebarProps } from "..";
import { SchemaForUI } from "../../../../../../common/src";
import { AiOutlineClose as XMarkIcon } from "react-icons/ai";
const MakeFieldRequired = (
  props: Pick<
    SidebarProps,
    | "changeSchemas"
    //  "schemasList" |
    | "pageCursor"
  > & {
    schemasList: SchemaForUI[][];
    activeSchema: SchemaForUI;
  }
) => {
  const { changeSchemas, activeSchema, schemasList, pageCursor } = props;
  const i18n = useContext(I18nContext);
  const fontData = useContext(FontContext);

  const handleToggleRequired = () => {
    // ------------------------ radiogroup handle
    let wasRequired = activeSchema.required;
    if (activeSchema.subType == "radiogroup") {
      let changedShema: any[] = [];
      schemasList?.[pageCursor]?.map((schema: any) => {
        if (schema?.radiogroupId == activeSchema?.radiogroupId) {
          changedShema.push({
            key: "required",
            value: !wasRequired,
            schemaId: schema?.id,
          });
        }
      });
      changeSchemas(changedShema);
      return;
    }
    //------------------------

    // Toggle the required status of the field
    const isRequired = activeSchema.required;
    let schemaId = activeSchema.id;
    changeSchemas([{ key: "required", value: !isRequired, schemaId }]);
  };

  // Disable the required field in case of contact_field and custom_field
  if (
    activeSchema?.subType == "checkbox" ||
    activeSchema?.subType == "name" ||
    activeSchema?.subType == "email" ||
    activeSchema?.subType == "contact_field" ||
    activeSchema?.subType == "custom_field"
  ) {
    return (
      <div className="flex gap-2 text-xs">
        <label className="cursor-not-allowed opacity-50">
          <input
            className="cursor-not-allowed"
            type="checkbox"
            checked={false}
            disabled={true}
            // onChange={handleToggleRequired}
          />
          <span className="ml-2">Make the field required</span>
        </label>
      </div>
    );
  }

  return (
    <div className="flex gap-2 text-xs">
      <label>
        <input
          type="checkbox"
          checked={activeSchema.required}
          onChange={handleToggleRequired}
        />
        <span className="ml-2">Make the field required</span>
      </label>
    </div>
  );
};

export default MakeFieldRequired;
