import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { alpha, styled } from "@mui/system";
import { getWhiteLabelInfoLocal } from "../../../utils";
import { COLORS } from "../../../constants/common";

const whiteLabelInfo = getWhiteLabelInfoLocal();

// TO DO styling does not work

const BoloDateCalendar = styled(DateCalendar)(
  ({ variant }: { variant?: "default" | "bolo-primary" }) => ({
    "& .MuiPickersDay-day": {
      color:
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary,
      "&:hover": {
        backgroundColor: alpha(
          variant === "bolo-primary"
            ? COLORS.primary
            : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
                COLORS.primary,
          0.04
        ),
      },
    },
    "& .MuiPickersDay-daySelected": {
      backgroundColor:
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary,
    },
  })
);

export default BoloDateCalendar;
