import React, { useEffect, useState } from "react";
import { WhiteLabelOwnerType } from "../../../../redux/slices/whiteLabelSlice";
import { SketchPicker } from "react-color";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../../../components/Common/PopOver/BoloPopOver";
import {
  calculateContrast,
  dbHsl2Hex,
  generateShadesAndTints,
  hexToHSL,
  ShadesAndTints,
} from "../../../../utils/uiUtils";
import { COLORS } from "../../../../constants/common";

function generateVariants(hex: string): ShadesAndTints {
  let data = generateShadesAndTints(hex);
  if (!data) {
    return { shades: [], tints: [] };
  }
  return data;
}

const ThemeSelector = ({
  whiteLableOwnerInfo,
  setWhiteLabelInfo,
}: {
  whiteLableOwnerInfo: WhiteLabelOwnerType;
  setWhiteLabelInfo: (info: WhiteLabelOwnerType) => void;
}) => {
  const choosen = [0, 4, 8];
  const [hexColor, setHexColor] = useState<string>(
    dbHsl2Hex(whiteLableOwnerInfo?.settings?.organization?.theme?.primary) ||
      COLORS.primary
  );
  const [variants, setVariants] = useState(generateVariants(hexColor).tints);

  const [textColor, setTextColor] = useState(
    getTextColor(
      dbHsl2Hex(whiteLableOwnerInfo?.settings?.organization?.theme?.primary)
    )
  );

  function getTextColor(hex: string | null | undefined) {
    if (!hex) {
      return "#FFFFFF";
    }
    let shades = generateVariants(hex).shades;
    return parseFloat(calculateContrast(hex, "#FFFFFF")) > 3
      ? "#FFFFFF"
      : shades[8] || "#000000";
  }

  const handleChange = (hex: string) => {
    const primaryVariants = generateVariants(hex).tints;
    setVariants(primaryVariants);
    setHexColor(hex);
    ///
    let whiteLabelOwnerData = JSON.parse(JSON.stringify(whiteLableOwnerInfo));
    let textColor_ = getTextColor(primaryVariants[choosen[0]]);

    if (whiteLabelOwnerData?.settings?.organization?.theme) {
      whiteLabelOwnerData.settings.organization.theme.primary = hexToHSL(
        primaryVariants[choosen[0]]
      );
      whiteLabelOwnerData.settings.organization.theme.secondary = hexToHSL(
        primaryVariants[choosen[2]]
      );
      whiteLabelOwnerData.settings.organization.theme.tertiary = hexToHSL(
        primaryVariants[choosen[1]]
      );
      whiteLabelOwnerData.settings.organization.theme.textPrimary =
        hexToHSL(textColor_);
      setTextColor(textColor_);
      setWhiteLabelInfo(whiteLabelOwnerData);
    }
  };

  useEffect(() => {
    setHexColor(
      dbHsl2Hex(whiteLableOwnerInfo?.settings?.organization?.theme?.primary) ||
        COLORS.primary
    );
  }, [whiteLableOwnerInfo?.settings?.organization?.theme?.primary]);

  useEffect(() => {
    setVariants(generateVariants(hexColor).tints);
  }, [hexColor]);

  return (
    <div className="">
      <BoloPopover>
        <div className="flex">
          <BoloPopoverTrigger>
            <div
              className="h-6 w-20 mr-7"
              style={{ background: hexColor }}
            ></div>
          </BoloPopoverTrigger>
          <div className="h-6 w-20" style={{ background: textColor }}></div>
          <div
            className="h-6 w-20"
            style={{ background: variants[choosen[0]] }}
          ></div>
          <div
            className="h-6 w-20"
            style={{ background: variants[choosen[1]] }}
          ></div>
          <div
            className="h-6 w-20"
            style={{ background: variants[choosen[2]] }}
          ></div>
        </div>
        <BoloPopoverContent>
          <SketchPicker
            color={hexColor}
            onChange={(e) => handleChange(e.hex)}
          />
        </BoloPopoverContent>
      </BoloPopover>
    </div>
  );
};

export default ThemeSelector;
