import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignatureTemplateResponseMapper from "../../components/SignatureTemplateResponseMapper";
import StartSigningSignerPage from "../../components/Ui/StartSigningPageComponent";
import axios from "../../utils/axios";
import {
  getDocument,
  GlobalWorkerOptions,
} from "pdfjs-dist/legacy/build/pdf.js";
import SignerDeclined from "../../components/SignerDeclined";
import { BASE_URL } from "../../constants/constants";
import { Helmet } from "react-helmet";
import { IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js`;

const DocumentTemplateSigner: React.FC = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [documentId, setDocumentId] = useState(
    searchParams.get("documentId") ?? ""
  );
  const [signerId, setSignerId] = useState(searchParams.get("signerId") ?? "");
  // const documentId = searchParams.get("documentId");
  // const signerId = searchParams.get("signerId");
  const key = searchParams.get("key");
  const [owner, setOwner] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [documentAuthor, setDocumentAuthor] = useState("");
  const [signerDetails, setSignerDetails] = useState<any>({});
  const [isStartSigningPage, setIsStartSigningPage] = useState(true);
  const [schemas, setSchemas] = useState<any>([]);
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [signerName, setSignerName] = useState("");
  const [shownModal, setShownModal] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [viewLimit, setViewLimit] = useState(false);
  const [refetch, setRefetchData] = useState(false);
  const [fetchedData, setFetchedData] = useState<{
    accountInfo: any;
    document: any;
    // !NOTE: this is not supported in old docmentId signerId system
    // ! and will only be supported in getDocumentByKey
    paymentStatus: {
      isPayment: boolean;
      message: string;
      isPaid?: boolean;
    } | null;
    pdfTemplateRespondent: any;
  }>({
    accountInfo: null,
    document: null,
    paymentStatus: null,
    pdfTemplateRespondent: null,
  });
  const [documentDataFetched, setDocumentDataFetched] =
    useState<boolean>(false);
  const [brandingInfofetched, setBrandingInfoFetched] =
    useState<boolean>(false);

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  const getDocumentDetailsByKey = async () => {
    if (!key) {
      return;
    }
    try {
      // setLoading(true);

      const ipResponse = getIpAddressLocal();
      const ipData = await ipResponse;

      const { data } = await axios.get(
        `/signingLog/getOneDocumentByKey?key=${key}&ip=${ipData.ip}`
      );

      if (data?.success === false) {
        setViewLimit(true);
      }

      let { signer, document } = data;

      if (data.error) {
        toast.error(data.error);
        // setLoading(false);
        return;
      }
      if (!data.error) {
        // toast.success(data.message);
      }
      if (signer.error) {
        toast.error(signer.error);
        // setLoading(false);
        return;
      }
      setSignerId(signer?.signerId || "");
      setDocumentId(data?.document?.documentId || "");

      let pdfUrl =
        signer.pdfLink ||
        data.document.documentUrl ||
        data.document.pdfTemplate?.basePdf;
      setPdfUrl(pdfUrl);

      onClickStart(pdfUrl);

      setFetchedData((prev) => ({
        ...prev,
        document: data?.document,
        paymentStatus: data?.paymentStatus,
        pdfTemplateRespondent: data?.pdfTemplateRespondent,
      }));
      setDocumentAuthor(data.document.authorEmail);
      setSignerDetails(signer);
      setSchemas(signer.templateSchema);
      if (signer.status === "SIGNED") {
        // this has to be changed
        if (!data?.paymentStatus?.isPayment) {
          navigate(`/signature-success?key=${key}`);
        }
        // navigate(
        //   `/signature-success?documentId=${
        //     documentId || data?.document?.documentId
        //   }&signerId=${signerId || signer?.signerId}`
        // );
      }
      // When document is not deleted or void then check for expired
      if (!document?.isDeleted && !document?.isVoid) {
        if (document?.status === "EXPIRED") {
          navigate(`/signature-expired?key=${key}`);
        }
      }

      setAuthorEmail(data.document.authorEmail);
      setAuthorName(data.document.ownerId.name);
      setSignerName(signer.name);
      setDocumentDataFetched(true);

      // setLoading(false);
    } catch (errror) {
      console.log("Error in fetching key", errror);
      // setLoading(false);
      toast.error("Error fetching form");
    }
  };

  useEffect(() => {
    let getDocumentDetails = async () => {
      console.log("!!key", !!key);
      if (!!key) {
        return;
      }
      try {
        console.log("--------getDocumentDetails");
        // setLoading(true);
        const { data } = await axios.get(
          `/getOneDocument?documentId=${documentId}`
        );
        if (data.error) {
          toast.error(data.error);
          // setLoading(false);
          return;
        }
        if (!data.error) {
          // toast.success(data.message);
        }

        const ipResponse = getIpAddressLocal();
        const ipData = await ipResponse;

        let { data: signer } = await axios.get(
          `/getOnePdfTemplateRespondent?signerId=${signerId}&documentId=${documentId}&ip=${ipData.ip}`
        );

        if (signer.error) {
          toast.error(signer.error);
          // setLoading(false);
          return;
        }

        console.log("signer", signer);
        signer = signer.signer;

        let pdfUrl =
          signer.pdfLink ||
          data.document.documentUrl ||
          data.document.pdfTemplate?.basePdf;
        setPdfUrl(pdfUrl);

        onClickStart(pdfUrl);
        setFetchedData((prev) => ({ ...prev, document: data?.document }));
        setDocumentAuthor(data.document.authorEmail);
        setSignerDetails(signer);
        setSchemas(signer.templateSchema);
        if (signer.status === "SIGNED") {
          navigate(
            `/signature-success?documentId=${documentId}&signerId=${signerId}`
          );
        }
        setAuthorEmail(data.document.authorEmail);
        setAuthorName(data.document.ownerId.name);
        setSignerName(signer.name);

        // setLoading(false);
        if (!key) {
          setDocumentDataFetched(true);
        }
      } catch (errror) {
        console.log("Error in fetching old", errror);
        // setLoading(false);
        toast.error("Error fetching form");
      }
    };
    getDocumentDetails();
    getDocumentDetailsByKey();
  }, [documentId, signerId, key, refetch]);

  const getBrandingInfo = async () => {
    try {
      let { data } = await axios.post(`${BASE_URL}/owner/get-branding-info`, {
        id: documentId,
        type: "PDF",
      });

      if (data?.accountInfo) {
        await setFetchedData((prev) => ({
          ...prev,
          accountInfo: data?.accountInfo,
        }));
        setBrandingInfoFetched(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (documentId) {
      getBrandingInfo();
    }
  }, [documentId]);

  useEffect(() => {
    if (pdfFile) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [pdfFile]);

  let onClickStart = async (pdfUrl: string) => {
    const responseFile = await fetch(pdfUrl);
    const pdfBuffer = await responseFile.arrayBuffer();

    // Load PDF document
    const pdfDoc = await getDocument(pdfUrl).promise;

    // Get number of pages
    const numPages = pdfDoc.numPages;

    setNumberOfPages(numPages);

    // Convert PDF buffer to a base64 string

    let pdfBase64 = arrayBufferToBase64(pdfBuffer);
    pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
    setPdfFile(pdfBase64);
  };

  if (viewLimit) {
    return (
      <div className="h-screen flex justify-center items-center flex-col px-4">
        <div className="text-xl md:text-3xl font-bold mb-16 text-center">
          Due to Security Reasons your Link has Expired
        </div>
        <div className="flex gap-4 md:flex-row flex-col-reverse">
          <img
            // src="https://i.imgur.com/wJRAEFT.png"
            src="https://i.imgur.com/hlqjxfi.png"
            alt="logo"
            className="max-h-[45vh] md:max-w-[60vw]"
            loading="lazy"
          />
          <div className="flex flex-col gap-2 items-center md:items-start md:max-w-[300px]">
            <div className="font-semibold md:text-lg ">
              To regenerate your link
            </div>
            <div className="text-sm text-center md:text-start text-gray-600">
              Select the "Sign Now" button in the email that redirected you to
              this page to continue
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (signerDetails?.hasDeclined) {
    return <SignerDeclined fetchedData={fetchedData} />;
  }

  if (loading || !brandingInfofetched || !documentDataFetched) {
    return (
      <div className="h-screen flex justify-center items-center">
        <BoloLoader />
      </div>
    );
  }

  return (
    // removed negative margins
    <div className={`w-full`}>
      <Helmet>
        <title translate="no">Pdf Template Signing</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Pdf template Signing" />
        <meta
          name="description"
          content="Signer's dedicated page for completing and adding signatures to the pdf template document."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/sign-pdf-template`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Pdf template Signing - Boloforms`}
        />
        <meta
          property="og:description"
          content="Signer's dedicated page for completing and adding signatures to the pdf template document."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/sign-pdf-template"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {shownModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.6)", // Dark overlay color
            zIndex: 999, // Place the overlay above other content
          }}
        ></div>
      )}
      {pdfFile && signerDetails && (
        <div
          className={`w-full h-full overflow-y-hidden absolute ${
            documentId &&
            signerId &&
            signerDetails &&
            isStartSigningPage &&
            "blur-sm"
          }`}
        >
          <SignatureTemplateResponseMapper
            file={pdfFile}
            numberOfPages={numberOfPages}
            signerDetails={signerDetails}
            schemas={schemas}
            loading={loading}
            setShownModal={setShownModal}
            isSigning={true}
            documentIdProp={documentId}
            signerIdProp={signerId}
            fetchedData={fetchedData}
            setRefetchData={setRefetchData}
          />
        </div>
      )}
      {documentId &&
        signerId &&
        signerDetails &&
        isStartSigningPage &&
        fetchedData?.accountInfo &&
        brandingInfofetched && (
          <StartSigningSignerPage
            loading={loading}
            isSigner={true}
            // onClick={onClickStart}
            onClick={() => {
              setIsStartSigningPage(false);
            }}
            setStartSigning={() => {}}
            // setStartSigning={setStartSigning}
            email={signerDetails.email}
            documentId={documentId}
            extraDetails={{
              authorEmail,
              authorName,
              signerName,
              signerId,
            }}
            isOnlyPDF={false}
            isDocumentAvailableToSign={
              (signerDetails?.isDeleted ? !signerDetails?.isDeleted : true) &&
              (signerDetails?.isVoid ? !signerDetails?.isVoid : true)
            }
            accountInfo={fetchedData?.accountInfo}
          />
        )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default DocumentTemplateSigner;
