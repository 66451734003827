import React, { useEffect, useState } from "react";
import BoloModal from "../Common/Modal/BoloModal";
import { IoLinkOutline } from "react-icons/io5";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { MdCheck } from "react-icons/md";
import { getTintFromHex } from "../../utils/uiUtils";
import { LuCopy } from "react-icons/lu";
import CustomTooltip from "../EditForm/CustomTooltip";
import { BoloButton } from "../Common/Button/BoloButton";
import { BoloLoaderLine } from "../Common/Loader/BoloLoader";
type FileType = "PDF" | "PDF-TEMPLATE";
interface GenerateLinksModalTypes {
  setShowGenerateLinksModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowShareViaLink: React.Dispatch<React.SetStateAction<boolean>>;
  docType: FileType;
  documentId: string;
  setGeneratedLinksData: React.Dispatch<React.SetStateAction<any>>;
  document: any;
}
const GenerateLinksModal = ({
  setShowGenerateLinksModal,
  setShowShareViaLink,
  docType,
  documentId,
  setGeneratedLinksData,
  document,
}: GenerateLinksModalTypes) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [signers, setSigners] = useState<any[]>([]);
  const generateLinks = async () => {
    try {
      let docData: any = { documentId, docType };
      if (docType === "PDF-TEMPLATE") {
        docData = { ...docData, signerId: document?.signers?.[0]?.signerId };
      }
      const { data } = await axios.post(
        "/document/generateShortLinks",
        docData
      );
      if (data?.error) {
        toast.error(data?.error);
      } else {
        if (docType === "PDF-TEMPLATE") {
          setGeneratedLinksData(data?.doc);
          setShowGenerateLinksModal(false);
          setShowShareViaLink(true);
        } else {
          setGeneratedLinksData({
            ...data?.doc,
            respondentsOfDoc: data?.doc?.respondants,
          });
          setShowGenerateLinksModal(false);
          setShowShareViaLink(true);
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error("Some Error Occured!! ");
      console.log("Some Error Occured", error);
    }
  };
  useEffect(() => {
    if (docType === "PDF-TEMPLATE") {
      if (document?.signers?.[0]?.shortUrl) {
        setGeneratedLinksData(document);
        setShowShareViaLink(true);
        setShowGenerateLinksModal(false);
      }
    } else {
      if (
        document?.respondentsOfDoc?.[0]?.shortUrl ||
        document?.respondants?.[0]?.shortUrl
      ) {
        setGeneratedLinksData(document);
        setShowShareViaLink(true);
        setShowGenerateLinksModal(false);
      }
    }
  }, []);

  useEffect(() => {
    if (docType === "PDF") {
      setSigners(document?.respondentsOfDoc || []);
    }
    if (docType === "PDF-TEMPLATE") {
      setSigners(document?.signers || []);
    }
  }, [document]);

  return (
    <div>
      <BoloModal
        setShow={setShowGenerateLinksModal}
        content={
          <div>
            <div className="flex  gap-2 mb-1">
              <h1 className="text-lg md:text-xl flex gap-3 font-semibold text-gray-700">
                Share Link{" "}
              </h1>
            </div>
            <BoloLoaderLine loading={loading} />

            <div className="">
              <div className="w-full">
                <div className="mb-6 sm:mb-8 flex border border-blue-200 bg-blue-50 p-2 rounded items-center mt-4 gap-4">
                  <IoLinkOutline
                    size={80}
                    className="text-lg text-gray-400 hover:opacity-85 mx-3 my-1"
                  />
                  <div>
                    <p className="text-sm text-gray-700 font-semibold">
                      Generate Link to share the document.
                    </p>
                    {/* <p className="text-xs text-gray-500 ">
                Once links are generated, the document will be in{" "}
                <span className="font-semibold text-gray-700">
                  SENT VIA LINK
                </span>{" "}
                status.
              </p> */}
                    <p className="text-xs text-gray-500 mt-2">
                      Links are unique for each recipient. Make sure that the
                      intended recipients/signers are the only ones accessing
                      the document through their link.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`my-10 ${
                  false ? "" : "pointer-events-none opacity-50"
                }`}
              >
                {signers?.map((recipient: any, index: number) => (
                  <div
                    key={index}
                    className=" flex flex-col items-center gap-4 text-xs my-4"
                  >
                    <div className="flex flex-col md:flex-row  justify-between gap-2 w-full  my-2">
                      <div
                        className="border flex items-center sm:text-center min-w-[40%] sm:min-w-[30%] md:min-w-[20%] px-4 py-1 sm:py-2 w-full sm:w-fit text-gray-700 rounded font-medium "
                        style={{
                          backgroundColor: getTintFromHex(
                            recipient.colour || recipient?.roleColour,
                            90
                          ),
                          borderColor:
                            recipient.colour || recipient?.roleColour,
                        }}
                      >
                        <p
                          className={`w-full text-xs  outline-none`}
                          style={{
                            color: recipient.colour || recipient.roleColour,
                          }}
                        >
                          {recipient?.name || recipient?.roleTitle || ""}
                        </p>
                      </div>
                      <div className="flex-1 flex gap-2">
                        <div className=" flex-1 rounded-md">
                          <input
                            className={`w-full bg-white px-1.5 py-1.5 my-0.5 border-b-2 border-primary text-xs text-[#70757a] outline-none`}
                            value={
                              recipient?.shortUrl ||
                              "https://signature.boloforms.com/..."
                            }
                            onChange={() => {}}
                          ></input>
                        </div>
                        <div className="w-[13%] justify-center flex ">
                          {recipient.status == "SIGNED" ? (
                            <button
                              data-tooltip-id="SignedTooltip"
                              className={`flex px-1 flex-row items-center text-center text-xs capitalize  text-gray-800 rounded font-medium `}
                            >
                              <MdCheck className="text-lg md:text-xl text-gray-600 hover:opacity-85 sm:mr-1" />
                              <span className="hidden sm:inline">Signed</span>
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  recipient?.shortUrl
                                );
                                // setCopied(index);
                                // setTimeout(() => {
                                //   setCopied(-1);
                                // }, 3000);
                              }}
                              className={`flex px-1 items-center justify-center text-xs capitalize  text-gray-800 rounded font-medium `}
                            >
                              {/* {Copied === index ? (
                                <LuCopyCheck className="text-lg md:text-xl text-gray-600 hover:opacity-85 md:mr-2" />
                              ) : ( */}
                              <LuCopy className="text-lg md:text-xl text-gray-600 hover:opacity-85 md:mr-2" />
                              {/* )} */}

                              <span className="hidden sm:inline">
                                {/* {Copied === index ? "Copied" : "Copy"} */}
                                Copy
                              </span>
                            </button>
                          )}
                        </div>
                        <CustomTooltip
                          helpers={{
                            id: "SignedTooltip",
                            content: "This document is already signed.",
                            place: "right",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className=" flex justify-end gap-4 mt-8 group mb-1">
                <BoloButton
                  variant={"secondary"}
                  size={"sm"}
                  // className="px-4 py-2 rounded-lg text-gray-700 text-xs md:text-sm font-medium hover:bg-gray-100"
                  onClick={() => setShowGenerateLinksModal(false)}
                >
                  Close
                </BoloButton>

                <BoloButton
                  size={"sm"}
                  // className="px-5 py-2 bg-primary rounded text-white font-medium text-xs md:text-sm hover:opacity"
                  onClick={async () => {
                    setLoading(true);
                    await generateLinks();
                  }}
                  disabled={loading}
                >
                  <span className="">
                    {" "}
                    {loading ? "Generating Link" : "Generate Link"}
                  </span>
                </BoloButton>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default GenerateLinksModal;
