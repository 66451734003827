import { BASE_URL } from "../constants";
import {
  GHL_OPTIONS,
  goHighLevelLogo,
  gohighlevelVariableMapping,
} from "./goHighLevel";
import {
  HUBSPOT_CLIENT_ID,
  hubspotLogo,
  hubspotscopes,
  hubspotvariableMapping,
} from "./hubspot";
import {
  PIPEDRIVE_CLIENT_ID,
  pipedriveLogo,
  pipedriveVariablesMapping,
} from "./pipedrive";

export const IntegrationsWithToken = (owner, domain) => {
  return [
    {
      name: "Pipedrive",
      key: "pipedrive",
      description:
        "Pipedrive is a CRM-replacing sales pipeline management tool.",
      logo: pipedriveLogo,
      link: `https://oauth.pipedrive.com/oauth/authorize?client_id=${PIPEDRIVE_CLIENT_ID}&redirect_uri=${BASE_URL}/auth/pipedrive/callback&state=${owner?.email};${domain}`,
      variableMapping: pipedriveVariablesMapping,
    },
    {
      name: "CRM",
      key: "gohighlevel",
      description: "A CRM-replacing sales pipeline management tool.",
      logo: goHighLevelLogo,
      link: `${GHL_OPTIONS.baseUrl}?response_type=${
        GHL_OPTIONS.requestType
      }&redirect_uri=${GHL_OPTIONS.redirectUri}&client_id=${
        GHL_OPTIONS.clientId
      }&scope=${GHL_OPTIONS.scopes.join(" ")}&state=${domain};${owner?.email}`,
      variableMapping: gohighlevelVariableMapping,
    },
    {
      name: "HubSpot",
      key: "hubspot",
      description:
        "HubSpot is the flexible, intuitive CRM system for your small business.",
      logo: hubspotLogo,
      link: `https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_CLIENT_ID}&redirect_uri=${BASE_URL}/auth/hubspot/callback&scope=${hubspotscopes.join(
        " "
      )}&state=${domain};${owner?.email}`,
      variableMapping: hubspotvariableMapping,
    },
  ];
};
