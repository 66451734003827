import React from "react";
import ReactDOM from "react-dom";
// import { Template, DesignerProps, checkDesignerProps, checkTemplate } from '@pdfme/common';
import { BaseUIClass } from "./class";
import { DESTROYED_ERR_MSG } from "./constants";
import { I18nContext, FontContext } from "./contexts";
import DesignerComponent from "./components/Designer/index";
import { cloneDeep } from "./helper";
import {
  DesignerProps,
  Template,
  checkDesignerProps,
  checkTemplate,
} from "../../common/src";
import {
  InstanceFromType,
  SendToSignersArgsTypes,
  SendToSignersFunctionType,
} from "../../common/src/type";
// import { VariablesHelper } from "../../common/src/type";

class Designer extends BaseUIClass {
  private saveTemplateWithoutRedirectCallback?: (template: Template) => void;
  private onSaveTemplateCallback?: (template: Template) => void;
  private onChangeTemplateCallback?: (template: Template) => void;
  private formQuestions: Array<{
    type: string;
    title: string;
    required: boolean;
  }>;
  private isOnlyPdfTemplate: boolean;

  private receiversList: Array<{
    email: string;
    name: string;
    message?: string;
    sentUrl?: string;
    permission?: string;
    colour?: string;
  }>;

  private roles: Array<{
    title: string;
    colour: string;
  }>;

  private fetchedData: any;
  private setReload: (value: any) => void;
  private setIsPreview: (value: any) => void;
  // private variablesHelper: VariablesHelper;
  private instanceFrom: InstanceFromType;
  private inputs: { [key: string]: string }[] | undefined;

  // defaultVariablesHelper: VariablesHelper = {
  //   customVariables: [{ varName: "", varValue: "" }],
  //   setCustomVariables: () => {
  //     console.log("uninitialised default variables helper designer class");
  //   },
  // };

  sendToSigners?: SendToSignersFunctionType;

  constructor(props: DesignerProps) {
    super(props);
    checkDesignerProps(props);
    this.formQuestions = props.formQuestions || [
      { type: "Text", title: "Text", required: false },
    ];
    this.isOnlyPdfTemplate = props.isOnlyPdfTemplate || false;

    this.receiversList = props.receiversList || [];
    this.roles = props.roles || [];
    this.render();
    this.sendToSigners = props.sendToSigners || this.defaultSendToSigners;
    this.fetchedData = props.fetchedData;
    this.setReload = props.setReload;
    this.setIsPreview = props.setIsPreview;
    // this.variablesHelper = props.variablesHelper || this.defaultVariablesHelper;
    this.instanceFrom = props.instanceFrom;
    this.inputs = props.inputs;
  }

  defaultSendToSigners = async (sendToSignerArgs: SendToSignersArgsTypes) => {
    return "default";
  };

  public saveTemplate() {
    if (!this.domContainer) throw Error(DESTROYED_ERR_MSG);
    if (this.onSaveTemplateCallback) {
      this.onSaveTemplateCallback(this.template);
    }
  }

  public updateTemplate(template: Template) {
    checkTemplate(template);
    if (!this.domContainer) throw Error(DESTROYED_ERR_MSG);
    this.template = cloneDeep(template);
    if (this.onChangeTemplateCallback) {
      this.onChangeTemplateCallback(template);
    }
    this.render();
  }

  public onSaveTemplate(cb: (template: Template) => void) {
    this.onSaveTemplateCallback = cb;
  }

  public onSaveTemplateWithoutRedirect(cb: (template: Template) => void) {
    this.saveTemplateWithoutRedirectCallback = cb;
  }

  public onChangeTemplate(cb: (template: Template) => void) {
    this.onChangeTemplateCallback = cb;
  }

  protected render() {
    if (!this.domContainer) throw Error(DESTROYED_ERR_MSG);
    ReactDOM.render(
      <I18nContext.Provider value={this.getI18n()}>
        <FontContext.Provider value={this.getFont()}>
          <DesignerComponent
            template={this.template}
            onSaveTemplate={(template) => {
              this.template = template;
              if (this.onSaveTemplateCallback) {
                this.onSaveTemplateCallback(template);
              }
            }}
            onSaveTemplateWithoutRedirect={(template) => {
              this.template = template;
              if (this.saveTemplateWithoutRedirectCallback) {
                this.saveTemplateWithoutRedirectCallback(template);
              }
            }}
            onChangeTemplate={(template) => {
              this.template = template;
              if (this.onChangeTemplateCallback) {
                this.onChangeTemplateCallback(template);
              }
            }}
            size={this.size}
            formQuestions={this.formQuestions}
            isOnlyPdfTemplate={this.isOnlyPdfTemplate}
            receiversList={this.receiversList}
            roles={this.roles}
            sendToSigners={this.sendToSigners}
            fetchedData={this.fetchedData}
            setReload={this.setReload}
            setIsPreview={this.setIsPreview}
            // variablesHelper={this.variablesHelper}
            instanceFrom={this.instanceFrom}
            inputs={this.inputs}
          />
        </FontContext.Provider>
      </I18nContext.Provider>,
      this.domContainer
    );
  }
}

export default Designer;
