import React, { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { getCalApi } from "@calcom/embed-react";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import { da } from "date-fns/locale";
import { PAYMENTS_BASE_URL } from "../../constants/constants";
import Tracking from "../../utils/tracking";

const AddOnCard = ({
  title,
  desc,
  button,
  features,
  isAnnualBilling,
  startingPrice,
  owner,
}) => {
  const [billPrice, setBillPrice] = useState();
  const createAPISubs = async ({
    priceIdBase,
    priceIdUsage,
    addonFeatures,
  }) => {
    try {
      const { data } = await axios.post(
        `${PAYMENTS_BASE_URL}/createAPISubscriptionUS`,
        {
          priceId: priceIdBase,
          meteredPriceId: priceIdUsage,
          addonFeatures,
          ownerEmail: JSON.parse(localStorage.getItem("owner", "{}"))?.email,
          successUrl: window.location.toString(),
          cancelUrl: window.location.toString(),
        }
      );

      new Tracking(owner?.email).trackInitiateCheckout(
        "API",
        priceIdBase,
        1,
        20
      );

      if (data?.sessionUrl) {
        window.open(data?.sessionUrl, "_blank");
      } else {
        toast.error("No Checkout URL");
      }
    } catch (error) {
      console.log("Error in createAPISubs");
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    setBillPrice(isAnnualBilling ? 999 : 99);
  }, [isAnnualBilling]);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <div className="flex-1 min-w-[300px] max-w-[500px]">
      {/* <div className="px-4 py-2 bg-primary/60 text-xl rounded-t-md font-semibold">
        {title}
      </div> */}
      {/* it had like this xl:h-[400px], i removed the xl */}

      <div
        className={`flex h-[450px] flex-col items-start p-4 border ${
          title === "HIPAA" && "rounded-r-md"
        } ${title === "API" && "rounded-l-md"}`}
      >
        <div className="mt-2 w-full font-medium flex flex-col justify-center">
          <h2 className="text-xl font-semibold text-gray-700">{title}</h2>
          <p className="mt-2 text-xs text-gray-400">{desc}</p>
        </div>
        {/* <p className="text-sm text-gray-600 h-[80px]">{desc}</p> */}

        {title === "API" ? (
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col justify-start">
              <div className={`mt-8 relative`}>
                <span className="text-4xl font-extrabold white">${20}</span>
                <span className="text-base font-medium text-gray-500">/mo</span>
                <span className="text-base font-medium text-gray-500">
                  {" "}
                  + $0.2/document
                  <br />
                </span>
                <p className="text-sm pt-2 text-gray-500">{"billed monthly"}</p>

                {/* {extraText && (
              <p className="bg-green-600 text-white w-[100px] text-center text-xs font-bold px-3 py-1 rounded-full absolute top-0 right-0">
                {extraText}
              </p>
            )} */}
              </div>
            </div>
            {/* <div className="flex flex-col mb-8">
              <div className="flex items-end">
                <p className="text-md font-semibold mr-1">$</p>
                <p className="text-2xl font-bold">
                  {!isAnnualBilling ? billPrice : 83.25}
                </p>
                <span className="text-xs text-gray-500 ml-1">/per month</span>
              </div>
              <p className="text-xs text-gray-600 mt-2 h-[20px]">
                {isAnnualBilling ? "Billed Annually" : "Billed Monthly"}
              </p>
            </div> */}

            <button
              onClick={() => {
                // isAnnualBilling
                //   ? window.open(
                //       "https://buy.stripe.com/28o7vPgAO3VXgG4fZ5",
                //       "_blank"
                //     )
                //   : window.open(
                //       "https://buy.stripe.com/bIY7vP5Wa789exW8wC",
                //       "_blank"
                //     );
                createAPISubs({
                  addonFeatures: ["API_ACCESS"],
                  priceIdBase: "price_1PYojRLiGbRu8waGVLQYGY9Q",
                  priceIdUsage: "price_1PYokyLiGbRu8waGlZU5MzpM",
                });
              }}
              className={`mt-4 bg-primary text-white hover:border-primary border-2 block w-full py-1.5 text-sm font-medium text-center  rounded-md  hover:bg-primary`}
            >
              Subscribe
            </button>
          </div>
        ) : title === "HIPAA" ? (
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col justify-start">
              <div className={`mt-8 relative`}>
                <span className="text-4xl font-extrabold white">$49</span>
                <span className="text-base font-medium text-gray-500">
                  /mo
                  <br />
                </span>
                <p className="text-sm pt-2 text-gray-500">
                  {isAnnualBilling ? "billed annually" : "billed monthly"}
                </p>
              </div>
            </div>

            <button
              onClick={() => {
                new Tracking(owner?.email).trackInitiateCheckout(
                  "HIPAA",
                  "dR6aI1doC7892Pe7t4",
                  1,
                  49
                );

                window.open(
                  "https://buy.stripe.com/dR6aI1doC7892Pe7t4",
                  "_blank"
                );
              }}
              className={`mt-4 bg-primary text-white hover:border-primary border-2 block w-full py-1.5 text-sm font-medium text-center  rounded-md  hover:bg-primary`}
            >
              Subscribe
            </button>
          </div>
        ) : (
          <div className="flex flex-col gap-y-2">
            <div className="flex flex-col mt-8">
              <div className="flex items-end">
                <p className="text-3xl font-bold">
                  {startingPrice || "Get a Quote"}
                </p>
              </div>
              <p className="text-sm pt-2 text-gray-500">
                Reach out to us for a custom quote
              </p>
            </div>

            <button
              onClick={() => {
                window.Intercom(
                  "showNewMessage",
                  "I want to inquire about BoloForms embedding for my software"
                );
              }}
              // data-cal-link="boloforms/signature"
              // data-cal-config='{"layout":"month_view"}'
              className={`mt-4 bg-primary text-white hover:border-primary border-2 block w-fit px-4 py-1.5 text-sm font-medium text-center  rounded-md  hover:bg-primary`}
            >
              Contact Us
            </button>
          </div>
        )}
        <div className="h-[80px] mt-6">
          {features.map((feature, index) => (
            <div key={index} className="flex py-2">
              <span className="mr-2">
                <BsCheckCircleFill className="text-sm text-green-600" />
              </span>
              <span className="text-gray-600 text-xs font-medium">
                {feature}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddOnCard;
