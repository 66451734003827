import { getDashboardUrlV1 } from "../../constants/constants";
import { ALL_FEATURES } from "../../types/tiers";
import { getOwnerPlanDetails } from "../../utils";

type TIER_RANK_TYPE = { tier: string; isReview: boolean; name: string };

// AY discuss names with chirag later

const FEATURE_MAP: {
  [key in ALL_FEATURES]: {
    name: string;
    plural?: boolean;
    tagline: string;
    videoSrc?: string;
  };
} = {
  "": { name: "", tagline: "" },
  WATERMARK: {
    name: "Watermark",
    tagline: "Watermarks helps you brand the generated PDFs with your Logo",
    videoSrc: "https://www.youtube.com/embed/i2YsBD9CdF8",
  },
  REDIRECTION_URL: {
    name: "Redirection URL",
    tagline:
      "Redirects users after the have completed the signing process to the designated page",
    videoSrc: "https://www.youtube.com/embed/P9wEVZzW9K0",
  },
  PDF_REDIRECT_URL: {
    name: "Redirection URL",
    tagline:
      "Redirects users after the have completed the signing process to the designated page",
    videoSrc: "https://www.youtube.com/embed/qiD_66Unq2s",
  },
  CSV_EXPORT: {
    name: "CSV Export",
    tagline: "Get response data in CSV format for better organization",
  },
  STRIPE_INTEGRATION: {
    name: "Stripe Integration",
    tagline:
      "Offer a seamless payment experience to your customers and automate payment processing to save time",
    videoSrc: "https://www.youtube.com/embed/D5rkiIBtz9w",
  },
  REMINDERS_ACCESS: {
    name: "Reminders",
    plural: true,
    tagline:
      "No more manual follow-ups! Our system sends automated reminders to signers, prompting action before the deadline",
    videoSrc: "https://www.youtube.com/embed/lVnawsVlmoI",
  },
  MAKE_INTEGRATION: {
    name: "Make Integration",
    tagline:
      "Seamlessly integration with Make.com, empowering you to streamline your digital document management process",
    videoSrc: "https://www.youtube.com/embed/U5MsSSMGpaQ",
  },
  FORM_ANALYTICS: {
    name: "Form Analytics",
    tagline: "Get detailed summary for your form responses",
  },
  COLLABORATION: {
    name: "Teams",
    tagline:
      "Collaborate with your team members, with tailored per document, form, template, folder level sharing",
    videoSrc: "https://www.youtube.com/embed/TaaGiKTJ-TQ",
  },
  ZAPIER_INTEGRATION: {
    name: "Zapier Integration",
    tagline:
      "Seamlessly integration with Zapier.com, empowering you to streamline your digital document management process",
  },
  FORM_CUSTOM_URL: {
    name: "Form Custom Url",
    tagline:
      "Create a unique link for your form, helping your end users better identify and trust the link",
  },
  E_TEMPLATES: {
    name: "E Templates",
    tagline: "Allow your customers to send templates to customized emails",
  },
  EMAIL_BRANDING: {
    name: "Email Branding",
    tagline:
      "Email branding makes it easier for your clients to recognize, believe, and associate your brand with your signature emails",
    videoSrc: "https://www.youtube.com/embed/i2YsBD9CdF8",
  },
  GOOGLE_DRIVE_INTEGRATION: {
    name: "Google Drive Integration",
    tagline:
      "Streamline your process of organizing signed PDF using the Google Drive Integration",
  },
  DNS_SETTING: {
    name: "DNS setting",
    tagline:
      "Custom DNS makes it easier for your clients to recognize, believe, and associate your brand with your signature emails",
    videoSrc: "https://www.youtube.com/embed/eVJ81kD2zvA",
  },
  IMPORT_FIELDS: {
    name: "Import Fields",
    tagline: "Import already existing schemas in your document to save time ",
  },
  VARIABLES: {
    name: "Variables",
    tagline:
      "Say goodbye to manual input and hello to streamlined invoicing with BoloForms Signature",
    videoSrc: "https://www.youtube.com/embed/vluVpTJ6Mf4",
  },
  BULK_SEND: {
    name: "Bulk Send",
    tagline: "Send forms and documents to multiple people with one click",
    videoSrc: "https://www.youtube.com/embed/SQCfm49nvpY",
  },
  CUSTOM_PDF_NAME: {
    name: "Custom PDF name",
    tagline: "Enhance your form management workflow by personalizing PDF names",
    videoSrc: "https://www.youtube.com/embed/xffH3vmzsf0",
  },
  INDIVIDUAL_FILE_SHARING: {
    name: "Individual File Sharing",
    tagline:
      "Collaborate with your team members, with tailored per document, form, template, folder level sharing",
    videoSrc: "https://www.youtube.com/embed/TaaGiKTJ-TQ",
  },
  SEND_A_COPY: {
    name: "Send a Copy",
    tagline:
      "Save time by sending copies of responses directly from the dashboard",
  },
  CRM_INTEGRATIONS: {
    name: "CRM Integration",
    tagline:
      "Seamlessly connect with CRM integration to streamline your document management and customer relationship processes",
  },
  BULK_DOWNLOAD: {
    name: "Bulk Download",
    tagline:
      "Save time by downloading multiple responses in bulk with our Bulk download feature",
  },
  PABBLY_INTEGRATION: {
    name: "Pabbly Integration",
    tagline:
      "Seamlessly automate document generation by triggering Pabbly in response to form submissions",
    videoSrc: "https://www.youtube.com/watch?v=bdPt__jH0U4",
  },
  API_ACCESS: {
    name: "API",
    tagline:
      "Send documents directly via APIs for seamless integration into your custom solution",
  },
  DISABLE_THANK_YOU_PAGE_BRANDING: {
    name: "Disable Thank you page Branding",
    tagline: "Remove branding from the last page more cutomized look",
  },
  WORKSPACE: {
    name: "Workspaces",
    plural: true,
    tagline:
      "Help organize multiple organtizations with Workspaces each with thier own unique branding",
    videoSrc: "https://www.youtube.com/embed/Y7aZJLp1Kgc",
  },
  WEBHOOKS: {
    name: "Webhooks",
    plural: true,
    tagline:
      "Use webhook to trigger custom action upon completion of documents and forms",
  },
  PASSWORD_PROTECTED_PDF: {
    name: "Password Protect PDF",
    tagline:
      "Safeguard your PDFs with password protection, Adding an extra layer of security to your documents",
    videoSrc: "https://www.youtube.com/embed/dFZw7J2Gkv4",
  },
  FOLDER_ORG: {
    name: "Folder Organization",
    tagline: "A great way to organize your document for quick access",
  },
  ACCESS_CODE: {
    name: "Access codes",
    tagline:
      "Add an extra layer of security to your important documents by using access codes",
    videoSrc: "https://www.youtube.com/embed/7KRQIAXsyv0",
  },
  FORM_BRANDING: {
    name: "Form Branding",
    tagline:
      "Form branding makes it easier for your clients to recognize, believe, and associate your brand with your forms",
  },
  FIFTEEN_MB_PDF_SIZE: {
    name: "15 MB PDFs",
    tagline:
      "Remove limit over the size of PDFs, increase allowed PDF size to 15MB",
  },
  // CUSTOM_EMAIL_DOMAIN: { name: "Custom Email Domain", tagline: "" },
  FIFTY_MB_PDF_SIZE: {
    name: "50 MB PDFs",
    tagline:
      "Remove limit over the size of PDFs, increase allowed PDF size to 50MB",
  },
  EMBEDDING: {
    name: "Embed BoloForms in your Website",
    tagline:
      "Now edit documents without leaving your website, integrate BoloForms into your website",
  },
  // comming soon
  CUSTOM_EMAIL_DOMAIN: { name: "Custom Email Domain", tagline: "" },
  MICROSOFT_TEAMS: { name: "Microsoft Teams", tagline: "" },
  ONLINE_NOTARY: { name: "Online Notary", tagline: "" },
  SALESFORCE: { name: "Sales Force", tagline: "" },
  SIGNER_ATTACHMENTS: {
    name: "Signer Attachments",
    tagline:
      "Add attachments to your documents, so that Attachments recipients can access additional files relevant to the main document",
  },
  SLACK_INTEGRATION: { name: "Slack Integration", tagline: "" },
  SMS_AUTHENTICATION: {
    name: "SMS authentication",
    tagline:
      "Send document signing invite links via SMS for convenient and secure access",
  },
  CUSTOM_FIELDS: {
    name: "Custom Fields",
    tagline:
      "Say goodbye to manual input and hello to streamlined contact management with BoloForms Signature.",
  },
  DYNAMIC_SUBJECT_AND_BODY: {
    name: "Dynamic email subject and body",
    tagline: "Create dynamic subject lines and email bodies effortlessly.",
  },
  DROPBOX_INTEGRATION: {
    name: "Dropbox Integration",
    tagline:
      "Streamline your process of organizing signed PDF using the Dropbox Integration",
  },
  APPROVAL_WORKFLOW: {
    name: "Approval Workflow",
    tagline: "Streamlined Approval Workflow for Seamless Signatures",
  },
  ONEDRIVE_INTEGRATION: {
    name: "Onedrive Integration",
    tagline:
      "Streamline your process of organizing signed PDF using the Onedrive Integration",
  },
  ENCRYPTED_FILE_UPLOAD: {
    name: "Encrypted File Upload",
    tagline: "Securely upload documents using Encrypted File Upload",
  },
};

const DEFAULT_USER_TIER_RANK: TIER_RANK_TYPE[] = [
  { tier: "FREE_TRIAL_TIER", isReview: false, name: "Free Trial Plan" },
  { tier: "FREE_TIER", isReview: false, name: "Free Plan" },
  { tier: "FREE_TIER_V1", isReview: false, name: "Free Plan" },
  { tier: "TIER_OLD", isReview: true, name: "Freelancer Plan Old" },
  { tier: "TIER_1", isReview: false, name: "Freelancer Plan" },
  { tier: "TIER_1_V1", isReview: true, name: "Freelancer Plan" },
  { tier: "TIER_2", isReview: false, name: "Team Plan" },
  // ! this tier exists but is not avilable for upgrade
  { tier: "TIER_2_V1", isReview: true, name: "Team Plan" },
  // { tier: "TIER_2_V2", isReview: true, name: "Team Plan" },
  { tier: "TIER_3", isReview: false, name: "Growing Business Plan" },
  { tier: "TIER_3_V1", isReview: true, name: "Growing Business Plan" },
  { tier: "API_TIER", isReview: false, name: "API Plan" },
];

const APPSUMO_USER_TIER_RANK: TIER_RANK_TYPE[] = [
  {
    tier: "APP_SUMO_BUSSINESS_TIER",
    isReview: false,
    name: "App Sumo Bussiness Plan",
  },
  {
    tier: "APP_SUMO_BUSSINESS_TIER_V1",
    isReview: true,
    name: "App Sumo Bussiness Plan",
  },
  {
    tier: "APP_SUMO_ENTERPRISE_TIER",
    isReview: false,
    name: "App Sumo Enterprise Plan",
  },
  {
    tier: "APP_SUMO_ENTERPRISE_TIER_V1",
    isReview: true,
    name: "App Sumo Enterprise Plan",
  },
];

const LTD_USER_TIER_RANK: TIER_RANK_TYPE[] = [
  {
    tier: "BOLOFORMS_LIFETIME_99",
    isReview: false,
    name: "BoloForms Secret Deal Tier",
  },
  // ! this tier exists but is not avilable for upgrade
  // {
  //   tier: "BOLOFORMS_LIFETIME",
  //   isReview: false,
  //   name: "BoloForms Secret Old Deal Tier",
  // },
  {
    tier: "BOLOFORMS_LIFETIME_299",
    isReview: false,
    name: "BoloForms Secret Deal Tier @299$",
  },
  {
    tier: "BOLOFORMS_LIFETIME_499",
    isReview: false,
    name: "BoloForms Secret Deal Tier @499$",
  },
];

const ADDON_FEATURES: ALL_FEATURES[] = [
  "BULK_SEND",
  "E_TEMPLATES",
  "SIGNER_ATTACHMENTS",
  // "FIFTY_MB_PDF_SIZE",
  "REDIRECTION_URL",
  "PDF_REDIRECT_URL",
  "CUSTOM_PDF_NAME",
  // "QUOTE_BUILDER",
  "EMAIL_BRANDING",
  "ONLINE_NOTARY",
  "SMS_AUTHENTICATION",
  "ACCESS_CODE",
  "PASSWORD_PROTECTED_PDF",
  "SALESFORCE",
  "MICROSOFT_TEAMS",
  "SLACK_INTEGRATION",
  "STRIPE_INTEGRATION",
  // "5MB_PDF",
  // "15MB_PDF",
  "FIFTEEN_MB_PDF_SIZE",
  "FIFTY_MB_PDF_SIZE",
  "DNS_SETTING",
];

const CONTACT_US_FEATURES: ALL_FEATURES[] = ["EMBEDDING"];

function checkGlobalFeatureMap({ feature }: { feature: ALL_FEATURES }): {
  title?: string;
  desc?: React.ReactNode | string;
  redirect?: string;
  videoSrc?: string;
  tagline?: string;
  redirectText?: string;
  calProps?: any;
} {
  const ownerPlanDetails = getOwnerPlanDetails();
  // let data: {
  //   title?: string;
  //   desc?: React.ReactNode | string;
  //   redirect?: string;
  //   redirectText?: string;
  // } = {
  //   title: "This is a Premium feature",
  //   desc: `You are on ${ownerPlanDetails?.currentPlan?.planName} please upgrade to use this feature`,
  //   redirect: "https://signature.boloforms.com/billings",
  //   redirectText: "Upgrade Now",
  // };

  try {
    const owner = JSON.parse(localStorage.getItem("owner") || "{}");
    const GLOBAL_FEATURE_MAP = owner?.paymentStatus?.GLOBAL_FEATURE_MAP;

    let userTypeMap = {
      DEFAULT: `BoloForms users`,
      APPSUMO: `Appsumo users`,
      BOLO_LTD: `BoloForms Life time users`,
    };

    let TIER_RANK: TIER_RANK_TYPE[] = [];
    if (ownerPlanDetails?.userType == "DEFAULT") {
      TIER_RANK = DEFAULT_USER_TIER_RANK;
    }
    if (ownerPlanDetails?.userType == "APPSUMO") {
      TIER_RANK = APPSUMO_USER_TIER_RANK;
    }
    if (ownerPlanDetails?.userType == "BOLO_LTD") {
      TIER_RANK = LTD_USER_TIER_RANK;
    }

    let availableInTier: TIER_RANK_TYPE | null = null;
    let selfTierFound = false;
    for (let rankedTier of TIER_RANK) {
      if (!selfTierFound) {
        selfTierFound = rankedTier.tier === ownerPlanDetails?.tier;
      } else {
        if (rankedTier.tier == "TIER_OLD") {
          continue; //  as we cant have anyone to upgrade to TIER_OLD
        }

        let tierFeatures = GLOBAL_FEATURE_MAP?.[rankedTier.tier] || [];

        if (tierFeatures?.includes(feature)) {
          availableInTier = rankedTier;
          console.log({
            availableInTier,
            ot: ownerPlanDetails?.tier,
            inc: rankedTier?.tier?.includes(ownerPlanDetails?.tier),
          });
          availableInTier.isReview =
            availableInTier.isReview &&
            rankedTier?.tier?.includes(ownerPlanDetails?.tier);
          break; // since we found the lowest tier needed to upgrade we break
        }
      }
    }

    let featureName = FEATURE_MAP[feature]?.name || "Feature";
    let featureTagline = FEATURE_MAP[feature]?.tagline || "";
    let featureVideoSrc = FEATURE_MAP[feature]?.videoSrc || "";
    let plural = FEATURE_MAP[feature]?.plural || false;
    let isAvailInAddon = ADDON_FEATURES?.includes(feature);

    if (availableInTier) {
      let upgrade2PlanName = availableInTier.name || "Higer plans";

      console.log({ availableInTier });
      const BLOCKING_DATA = {
        DEFAULT_UPGRADE: {
          title: `${featureName} ${
            plural ? "are" : "is"
          } available in ${upgrade2PlanName} and above`,
          desc: `You are on ${ownerPlanDetails?.currentPlan?.planName} upgrade to use this feature`,
          tagline: featureTagline,
          videoSrc: featureVideoSrc,
        },
        REVIEW: {
          title: "Join BoloForms Signature Beta",
          tagline:
            "Experience features when they are released instantly, instead of waiting for 2 months. Latest features- Folder Structure, custom PDF names by reviewing us and after review send us the screenshot in the live chat.",
          videoSrc: featureVideoSrc,
          redirect:
            "https://reviews.capterra.com/products/new/ed28049a-8f11-4b79-b185-28ad436eb92e",
          redirectText: "Unlock Now",
        },
      };
      // if tier found show appropriate message
      if (
        (ownerPlanDetails?.userType == "BOLO_LTD" ||
          ownerPlanDetails?.userType == "APPSUMO") &&
        !isAvailInAddon
      ) {
        if (availableInTier.isReview) {
          return BLOCKING_DATA.REVIEW;
        }
      }
      return BLOCKING_DATA.DEFAULT_UPGRADE;
    }

    // can have special message cases based on user type

    const BLOCKING_DATA_ADDON = {
      title: `${featureName || "This"} is an Addon Feature`,
      desc: `You can subscribe to this feature from the addon marketplace`,
      redirectText: "Go To Addon Marketplace",
      tagline: featureTagline,
      videoSrc: featureVideoSrc,
      redirect: `${getDashboardUrlV1()}/apps`,
    };

    if (
      ADDON_FEATURES?.includes(feature) &&
      (ownerPlanDetails?.userType == "BOLO_LTD" ||
        ownerPlanDetails?.userType == "APPSUMO")
    ) {
      // if tier not found and is a addon feature
      // set appropriate data
      return BLOCKING_DATA_ADDON;
    }

    // Contact us features
    const BLOCKING_DATA_CONTACT_US = {
      title: `${featureName || "This"}`,
      desc: `Connect with us to discuss a custom solution`,
      redirectText: "Contact Us",
      tagline: featureTagline,
      videoSrc: featureVideoSrc,
      redirect: `${getDashboardUrlV1()}/apps`,
      // calProps: {
      //   "data-cal-link": "boloforms/signature",
      //   "data-cal-config": '{"layout":"month_view"}',
      // },
      CBArgs: {
        type: "intercom",
        msg: "I want to inquire about BoloForms embedding for my software",
      },
    };

    if (CONTACT_US_FEATURES?.includes(feature)) {
      // if tier not found and is a addon feature
      // set appropriate data
      return BLOCKING_DATA_CONTACT_US;
    }

    // this return means there is no way this person can upgrade to get this featuer
    // for eg appsumo users cant upgrade to use the feature access codes
    // there fore we show this
    return {
      title: `${featureName || "This"} is a Premium Feature`,
      videoSrc: featureVideoSrc,
      desc: `This feature is not available for ${
        userTypeMap[ownerPlanDetails?.userType]
      }`,
      tagline: featureTagline,
    };
  } catch (error) {
    console.log("Something went wrong in checkGlobalFeatureMap");
    console.log(error);
    return {};
  }
}

export { checkGlobalFeatureMap };
