import React, { Fragment } from "react";
import { useAppSelector } from "../../redux/store";

const DataPrivacyListAndLinks = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  return (
    <Fragment>
      {/* <div className="flex justify-center items-center gap-0 mt-8">
        <a
          target="_blank"
          href={
            whiteLabelInfo?.settings?.organization?.policy?.data ||
            "https://data-policy.boloforms.com"
          }
          rel="noreferrer"
        >
          <img
            alt="SOC2"
            src="https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/certifications/soc.png"
            width="500"
            height="500"
            decoding="async"
            data-nimg="1"
            className="w-14 h-auto rounded-full overflow-hidden"
            loading="lazy"
          />
        </a>
        <a
          target="_blank"
          className="w-10 mx-2 h-auto rounded-full overflow-hidden"
          href={
            whiteLabelInfo?.settings?.organization?.policy?.data ||
            "https://data-policy.boloforms.com"
          }
          rel="noreferrer"
        >
          <img
            alt="ISO27001"
            src="https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/certifications/iso27001.png"
            width="500"
            height="500"
            decoding="async"
            data-nimg="1"
            className="w-10 h-auto scale-150"
            loading="lazy"
          />
        </a>
        <a
          target="_blank"
          href={
            whiteLabelInfo?.settings?.organization?.policy?.data ||
            "https://data-policy.boloforms.com"
          }
          rel="noreferrer"
        >
          <img
            alt="GDPR"
            src="https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/certifications/gdpr.png"
            width="500"
            height="500"
            decoding="async"
            data-nimg="1"
            className="w-14 h-auto"
            loading="lazy"
          />
        </a>
        <a
          target="_blank"
          href={
            whiteLabelInfo?.settings?.organization?.policy?.data ||
            "https://data-policy.boloforms.com"
          }
          rel="noreferrer"
        >
          <img
            alt="HIPAA"
            src="https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/certifications/hipaa.png"
            width="500"
            height="500"
            decoding="async"
            data-nimg="1"
            className="w-14 h-auto"
            loading="lazy"
          />
        </a>
      </div> */}
      <div className="w-full flex justify-evenly mt-6 text-white ">
        <a
          target="_blank"
          href={
            whiteLabelInfo?.settings?.organization?.policy?.data ||
            "https://data-policy.boloforms.com"
          }
          rel="noreferrer"
          className="text-xs hover:underline"
        >
          Data Policy
        </a>

        <a
          target="_blank"
          href={
            whiteLabelInfo?.settings?.organization?.policy?.tos ||
            "https://www.boloforms.com/terms-of-service/"
          }
          rel="noreferrer"
          className="text-xs hover:underline mx-6"
        >
          Terms of Service
        </a>

        <a
          target="_blank"
          href={
            whiteLabelInfo?.settings?.organization?.policy?.privacy ||
            "https://www.boloforms.com/privacy-policy/"
          }
          rel="noreferrer"
          className="text-xs hover:underline"
        >
          Privacy Policy
        </a>
        <a
          target="_blank"
          href={
            whiteLabelInfo?.settings?.organization?.policy?.security ||
            "https://security.boloforms.com/"
          }
          rel="noreferrer"
          className="text-xs hover:underline"
        >
          Security Policy
        </a>
      </div>
    </Fragment>
  );
};

export default DataPrivacyListAndLinks;
