import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ownerSlice from "./slices/ownerSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import whiteLabelSlice from "./slices/whiteLabelSlice";
import twoFASlice from "./slices/2faSlice";
// import { ENV } from "../constants/constants";

// switch (payload.type) {
//   case STATUS_TYPE.SUCCESS:
//     state.loading = false;
//     state.data.userInfo = payload.data;
//     // state.updateDone = !state.updateDone;
//     break;
//   default:
//     state.loading = false;
//     state.isError = true;
//     state.errorData = {
//       message: payload.message,
//       type: payload.type,
//       errors: payload.errors,
//     };
// }

/*
        ------------------------------------------------------------------------------
        the above way would be ideal way to send data from backend
        we will modify it according to ur needs
        note always try to send a status type 
        ideally an enum and have a constant structure
        if not then atleast send a boolean for success
        ideal structure as follows
        {
            STATUS_TYPE: String, ["SUCCESS", "FAILURE", "FAILURE_TYPE_1", "FAILURE_TYPE_2"]
                ** this will convey is the request was successful or not
                ** and if not then what was the reason
            message: String,
                ** Send some informational messgae regardless of status type
            errors: [String]
                ** Send array of errors these can be server errors or errors listed to the req body or params
                ** such as "Invalid Object Id", "No Workspace found", etc
                ** this helps to debug fast in prod
            data: {object} actual data 
                ** always send in data object containing the required data
                ** this ensures consistency and is extensible system
                ** later down the road other params can be sent to the frontend
        }
        ------------------------------------------------------------------------------
*/

const rootReducer = combineReducers({
  ownerSlice: ownerSlice,
  whiteLabelSlice: whiteLabelSlice,
  twoFASlice: twoFASlice,
});

// this is extensible with rtk query
const store = configureStore({
  reducer: {
    root: rootReducer,
  },
  //   middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware().concat(baseApi.middleware),

  // devTools: ENV === "DEV",
});

export default store;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
