import { useEffect, useState } from "react";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { FaInfo, FaInfoCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { ALL_FEATURES } from "../../types/tiers";
import { fetchOwner, getOwnerPlanDetails } from "../../utils";
import { BoloButton } from "../Common/Button/BoloButton";
// import { getDashboardUrlV1 } from "../../constants/constants";
import { checkGlobalFeatureMap } from "./featureHelper";
// import { useIntercom } from "react-use-intercom";

type PleaseUpgradeModalProps = {
  show: any;
  setShow: any;
  data?: any; // legacy code : @deprecated
  blockingType?: ALL_FEATURES;
  reviewAppSumo?: any;
};

const PleaseUpgradeModal = ({
  show,
  setShow,
  data,
  blockingType,
}: PleaseUpgradeModalProps) => {
  let {
    title,
    desc,
    videoSrc,
    redirect,
    redirectText,
    closeText,
    closeCb,
    tagline,
  } = data || {};
  const [blockingData, setBlockingData] = useState<any>({});
  const [planDetails, setPlanDetails] = useState(getOwnerPlanDetails());
  const [owner, setOwner] = useState({});
  // const intercomHelper = useIntercom();

  const closeModal = (e: any) => {
    e?.stopPropagation();
    closeCb && closeCb();
    setShow(false);
  };

  useEffect(() => {
    if (blockingType) {
      let newBlockingData = checkGlobalFeatureMap({ feature: blockingType });
      console.log({ newBlockingData });
      setBlockingData(newBlockingData);
    }
  }, [blockingType]);

  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        JSON.parse(localStorage.getItem("owner") || "{}")?._id
      );
      setOwner(owner);
    };
    updatedOwner();
    // intercomHelper.boot();
  }, []);

  if (!show) return null;

  return (
    <div className="w-full h-screen inset-0 z-[999] fixed bg-black bg-opacity-20 flex justify-center items-center px-3 md:px-0">
      <div className="w-[100%] md:w-[800px] bg-white rounded relative">
        <MdClose
          onClick={closeModal}
          className="absolute text-2xl top-4 right-4 text-gray-400 cursor-pointer"
        />
        <div className="p-4 md:p-6">
          <h1 className="text-lg md:text-xl font-semibold text-gray-700 mb-2">
            {blockingData?.title || title || "This is a Premium feature"}
          </h1>
          <p className="text-xs md:text-sm text-gray-600">
            {blockingData?.tagline || tagline || ``}
          </p>
        </div>
        <div
          className={`rounded flex items-center justify-center overflow-hidden relative w-full pb-[56.25%] ${
            blockingData?.videoSrc || videoSrc ? "" : "hidden"
          } `}
        >
          <iframe
            src={blockingData?.videoSrc || videoSrc}
            title="YouTube video player"
            // @ts-ignore
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className="absolute top-0 left-0 w-full h-full"
          ></iframe>
        </div>
        <div className="p-4 md:p-6 pt-4">
          {(blockingData?.desc || desc) && (
            <p className="text-[10px] md:text-xs text-gray-500 mb-2 flex gap-2 items-center">
              <FaInfoCircle />
              {blockingData?.desc ||
                desc ||
                `You are on ${planDetails?.currentPlan?.planName} please upgrade to use this feature`}
            </p>
          )}
          <div className="flex justify-end gap-4">
            <BoloButton
              variant={"secondary"}
              size={"sm"}
              onClick={closeModal}
              // className="px-4 py-2 rounded-lg text-gray-700 text-xs md:text-sm font-medium hover:bg-gray-100"
            >
              {closeText || "Cancel"}
            </BoloButton>
            <BoloButton
              {...blockingData?.calProps}
              size={"sm"}
              onClick={() => {
                if (blockingData?.calProps) {
                  return;
                }
                if (blockingData?.CBArgs?.type) {
                  switch (blockingData?.CBArgs?.type) {
                    case "intercom":
                      // intercomHelper.showNewMessage(
                      //   blockingData?.CBArgs?.msg || ""
                      // );
                      // window.open("mailto:support@boloforms.com", "_blank");
                      (window as any).Intercom(
                        "showNewMessage",
                        blockingData?.CBArgs?.msg || ""
                      );
                      return;
                    default:
                      window.open(
                        blockingData?.redirect ||
                          redirect ||
                          "https://signature.boloforms.com/billings",
                        "_blank"
                      );
                      return;
                  }
                }
                window.open(
                  blockingData?.redirect ||
                    redirect ||
                    "https://signature.boloforms.com/billings",
                  "_blank"
                );
              }}
              // className="px-5 py-2 bg-primary rounded text-white ml-2 font-medium text-xs md:text-sm hover:opacity-75"
            >
              <BsFillRocketTakeoffFill className="inline mr-2" />{" "}
              {blockingData?.redirectText || redirectText || "Upgrade Now"}
            </BoloButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PleaseUpgradeModal;
