/*
---------------------------------------------------------------
CLEAN CODE LATER 
---------------------------------------------------------------
 */
import React, {
  useState,
  Ref,
  useRef,
  RefObject,
  forwardRef,
  useEffect,
  useContext,
} from "react";
import { TableCell, TableSchema } from "../../../../common/src/type";
import { SchemaUIProps } from "./SchemaUI";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../../../../components/Common/Resizeable/BoloResizeable";
import {
  calculateDynamicFontSize,
  DEFAULT_ALIGNMENT,
  DEFAULT_CHARACTER_SPACING,
  DEFAULT_FONT_COLOR,
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
} from "../../../../common/src";
import { FontContext } from "../../contexts";
import { arrayEquals } from "../../../../../utils/uiUtils";
import { getCSSFont } from "../../../../common/src/constants";
import { ZOOM } from "../../constants";
import SubtotalMenu from "./SubtotalMenu";
import { COLORS } from "../../../../../constants/common";

type Props = SchemaUIProps & { schema: TableSchema };
type TableStateType = {
  isHeader: boolean;
  isClicked: boolean;
  rowIdx: number;
  colIdx: number;
  schemaId: string;
};
type SetTableStateType = React.Dispatch<React.SetStateAction<TableStateType>>;
type TableCellUIProps = {
  cellData: TableCell;
  handleChange: (value: string) => void;
  tableState: TableStateType;
  setTableState: SetTableStateType;
  rowIdx: number;
  colIdx: number;
  isHeader: boolean;
  inputStyle: React.CSSProperties;
  isPreview?: boolean;
  isSigning?: boolean;
  tableType?: string;
  pricingStyle: { [key: string]: React.CSSProperties };
};

const TableCellUI = forwardRef<HTMLInputElement, TableCellUIProps>(
  (
    props: TableCellUIProps,
    ref: Ref<HTMLTextAreaElement> | Ref<HTMLInputElement>
  ) => {
    const {
      cellData,
      handleChange,
      setTableState,
      isHeader,
      rowIdx,
      colIdx,
      inputStyle,
      isPreview,
      isSigning,
      tableType,
      pricingStyle,
    } = props;
    let tType = tableType === "pricing_table" ? tableType : "table";

    return (
      <>
        {tType === "pricing_table" && (
          <>
            {cellData?.data ? (
              <div style={{ ...inputStyle }}>{cellData.data}</div>
            ) : (
              <div
                style={{
                  borderRight: inputStyle.borderRight,
                  borderBottom: inputStyle.borderBottom,
                  ...(pricingStyle["wrapper"] || {}),
                }}
              >
                <>
                  <div style={pricingStyle["priceHeader"]}>
                    {cellData?.header}
                  </div>
                  <div style={pricingStyle["description"]}>
                    {cellData?.description}
                  </div>
                  {!!cellData?.images?.length && (
                    <div style={pricingStyle["imagesWrapper"]}>
                      {cellData?.images?.map((img) => (
                        <img src={img} style={pricingStyle["image"]} />
                      ))}
                    </div>
                  )}
                </>
              </div>
            )}
          </>
        )}
        {tType === "table" && (
          <input
            // type="text"
            value={cellData?.data}
            style={inputStyle}
            //@ts-ignore
            ref={ref}
            disabled={isPreview || isSigning}
            className="outline-none focus:bg-gray-100"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            onClick={() => {
              setTableState((prev) => ({ ...prev, isHeader, rowIdx, colIdx }));
            }}
          />
        )}
      </>
    );
  }
);

const TableSchemaUI = (props: Props, ref: Ref<HTMLInputElement>) => {
  const {
    setDesignerState,
    schema,
    changeSchemas,
    isPreview,
    previewStyle,
    isSigning,
    previewState,
    isActive,
    editable,
    designerState,
  } = props;
  const font = useContext(FontContext);
  const [rowResizing, setRowResizing] = useState<number | null>(null);

  // const localRef: RefObject<HTMLInputElement> = useRef(null);

  // const data = React.useMemo(() => makeData(20), []);
  // let columns: ColumnDef<Person>[] = [
  //   {
  //     accessorKey: "firstName",
  //     cell: (info) => info.getValue(),
  //     footer: (props) => props.column.id,
  //     size: 20,
  //   },
  //   {
  //     accessorFn: (row) => row.lastName,
  //     id: "lastName",
  //     cell: (info) => info.getValue(),
  //     header: () => <span>Last Name</span>,
  //     footer: (props) => props.column.id,
  //     size: 160,
  //   },
  // ];

  // const getBackground = () => {
  //   if (isPreview && previewStyle !== "DEFAULT") return "transparent";

  //   if (schema?.isQuestionDeleted) {
  //     return "rgb(255 0 0 / 0.30)";
  //   }

  //   if (schema.backgroundColor) {
  //     return schema.backgroundColor;
  //   }

  //   if (isPreview || isSigning) {
  //     if (schema.isStatic) {
  //       return "transparent";
  //     }
  //     if (previewState?.fetchedData?.accountInfo?.theme) {
  //       if (isActive && editable) {
  //         return previewState?.fetchedData?.accountInfo?.theme + "70";
  //       } else {
  //         return previewState?.fetchedData?.accountInfo?.theme + "20";
  //       }
  //     } else {
  //       if (isActive && editable) {
  //         return COLORS.primary + "70";
  //       } else {
  //         return COLORS.primary + "20";
  //       }
  //     }
  //   }

  //   if (isActive && editable) {
  //     // if (schema?.isStatic) return STATIC_SCHEMA.color + 70;
  //     // if (schema?.signatureColour) return schema?.signatureColour + 70;
  //     // if (schema?.role?.colour) return schema?.role?.colour + "70";
  //   } else {
  //     // if (schema?.isStatic) return STATIC_SCHEMA.color + 20;
  //     // if (schema?.signatureColour) return schema?.signatureColour + 20;
  //     // if (schema?.role?.colour) return schema?.role?.colour + "20";
  //   }
  // };

  const [tabelState, setTableState] = useState({
    isHeader: false,
    rowIdx: 0,
    colIdx: 0,
    isClicked: false,
    schemaId: schema.id,
  });
  const [dynamicFontSize, setDynamicFontSize] = useState<number | undefined>(
    undefined
  );

  const borderStyle = `${schema.tableBorderWidth * 2}px solid ${
    schema.tableBorderColor
  }`;
  let style: React.CSSProperties = {
    padding: "0.2rem",
    resize: "none",
    // height: "100%",
    // position: "absolute",
    // fontFamily: "Poppins",
    fontFamily: getCSSFont({ currFont: schema.fontName }) || "Poppins",
    // height: schema.height * ZOOM,
    // width: schema.width * ZOOM,
    width: "100%",
    textAlign: schema.alignment ?? DEFAULT_ALIGNMENT,
    verticalAlign: "middle",
    fontSize: `${dynamicFontSize ?? schema.fontSize ?? DEFAULT_FONT_SIZE}pt`,
    letterSpacing: `${schema.characterSpacing ?? DEFAULT_CHARACTER_SPACING}pt`,
    // lineHeight: `${schema.lineHeight ?? DEFAULT_LINE_HEIGHT}em`,
    lineHeight: `${(schema.lineHeight ?? DEFAULT_LINE_HEIGHT) * 20}px`,
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    // border: "none",
    borderTop: borderStyle,
    borderLeft: borderStyle,
    color: schema.fontColor ? schema.fontColor : DEFAULT_FONT_COLOR,
    wordWrap: "break-word",
    // backgroundColor: getBackground(),
    backgroundColor: "transparent",
  };

  if (schema.subType === "pricing_table") {
    style = { ...style, height: "100%" };
  }

  let pricingStyle: { [key: string]: React.CSSProperties } = {
    wrapper: {
      padding: style.padding,
      width: style.width,
      borderTop: style.borderTop,
      borderLeft: style.borderLeft,
      wordWrap: style.wordWrap,
      backgroundColor: style.backgroundColor,
    },
    data: { ...style },
    priceHeader: {
      lineHeight: "1em",
      fontWeight: 600,
      width: style.width,
      wordWrap: style.wordWrap,
      backgroundColor: style.backgroundColor,
      color: style.color,
    },
    description: {
      // color: "#4B5563",
      fontSize: "10pt",
      lineHeight: "1em",
      whiteSpace: "pre-line",
      width: style.width,
      wordWrap: style.wordWrap,
      backgroundColor: style.backgroundColor,
      color: style.color,
    },
    imagesWrapper: {
      marginTop: "4pt",
    },
    image: {
      maxWidth: 20 * ZOOM,
      maxHeight: 20 * ZOOM,
    },
  };

  const headerStyle: React.CSSProperties = {
    ...style,
    color: schema.headStyle?.fontColor || "",
    background: schema.headStyle?.backgroundColor || "",
  };

  // rename this function later
  function handleTableColumnResize(isResizing: boolean) {
    if (isResizing) {
      setDesignerState &&
        setDesignerState((prev) => ({
          ...prev,
          editorHelpers: {
            ...prev.editorHelpers,
            disbaleMove: schema.id,
          },
        }));
    } else {
      setDesignerState &&
        setDesignerState((prev) => ({
          ...prev,
          editorHelpers: {
            ...prev.editorHelpers,
            disbaleMove: "",
          },
        }));
    }
  }

  // function handleMinMaxSize({
  //   rowIdx,
  //   colIdx,
  // }: {
  //   rowIdx: number;
  //   colIdx: number;
  // }) {
  //   let unModWidths: { [key: number]: { [key: number]: number | undefined } } =
  //     {};
  //   for (let idx = 0; idx < schema?.rows?.length + 1; idx++) {
  //     // idx 0 is headers
  //     // idx 1 to idx rowsLen + 1 is rows

  //     unModWidths[idx][colIdx] = getDefaultSize(colIdx);
  //   }
  //   unModWidths[rowIdx] = {};
  //   setMinMaxPanelWidth(unModWidths);
  // }

  function handleMinMaxSize({
    rowIdx,
    // colIdx,
    isResizing,
  }: {
    rowIdx: number;
    colIdx: number;
    isResizing: boolean;
  }) {
    console.log({ rowIdx, isResizing });
    if (!isResizing) {
      setRowResizing(null);
    } else {
      setRowResizing(rowIdx);
    }
  }

  function getDefaultSize(idx: number): number {
    // handle incase of not having width percentage for the particular head
    const headLen = schema?.head?.length;

    if (schema?.headWidthPercentages?.[idx]) {
      return schema?.headWidthPercentages?.[idx];
    }

    // if not percetage width found we give each col equal width
    if (headLen) {
      return 100 / headLen;
    }

    // if headLen is zero
    // will not happen ever still a saftey barier
    return 1;
  }

  function handleHeadChange(data: { value: string; colIdx: number }) {
    const { value, colIdx } = data;
    const headCopy = [...schema?.head];
    headCopy[colIdx].data = value;

    changeSchemas &&
      changeSchemas([{ key: "head", schemaId: schema.id, value: headCopy }]);
  }

  function handleRowChange(data: {
    value: string;
    colIdx: number;
    rowIdx: number;
  }) {
    const { value, rowIdx, colIdx } = data;
    const rowsCopy = [...schema?.rows];
    rowsCopy[rowIdx][colIdx].data = value;

    changeSchemas &&
      changeSchemas([{ key: "rows", schemaId: schema.id, value: rowsCopy }]);
  }

  const onLayout = (sizes: number[]) => {
    // console.log({
    //   layout0: sizes[0],
    //   layout1: sizes[1],
    // });
    let sizes_ = [...sizes];
    let currSizes = [...(schema?.headWidthPercentages || [])];
    if (!arrayEquals(sizes_, currSizes) && sizes && schema.id) {
      // console.log("onLayout not equal");
      changeSchemas &&
        changeSchemas([
          { key: "headWidthPercentages", schemaId: schema.id, value: sizes },
        ]);
    }

    // console.log("onLayout", {
    //   sizes,
    //   schemaId: schema.id,
    //   schema,
    //   changeSchemas,
    // });
    // if (sizes && schema.id) {
    //   changeSchemas &&
    //     changeSchemas([
    //       { key: "headWidthPercentages", schemaId: schema.id, value: sizes },
    //     ]);
    // }
  };

  function isActiveCell(arg: {
    isHeader: boolean;
    rowIdx: number;
    colIdx: number;
  }): boolean {
    return (
      tabelState.isHeader == arg.isHeader &&
      tabelState.rowIdx == arg.rowIdx &&
      tabelState.colIdx == arg.colIdx
    );
  }

  useEffect(() => {
    if (schema.dynamicFontSize && schema.data) {
      calculateDynamicFontSize({
        textSchema: schema,
        font,
        input: schema.data,
      }).then(setDynamicFontSize);
    } else {
      setDynamicFontSize(undefined);
    }
  }, [
    schema.data,
    schema.width,
    schema.fontName,
    schema.fontSize,
    schema.dynamicFontSize,
    schema.dynamicFontSize?.max,
    schema.dynamicFontSize?.min,
    schema.characterSpacing,
    font,
  ]);

  // useEffect(() => {
  //   console.log(tabelState);
  // }, [tabelState]);
  // useEffect(() => {
  //   console.log({
  //     schema0: schema.headWidthPercentages[0],
  //     schema1: schema.headWidthPercentages[1],
  //     dfS: getDefaultSize(0),
  //   });
  // }, [schema.headWidthPercentages]);

  return (
    <div id={`table-schema-${schema.id}`}>
      {schema.subType === "table" && (
        <ResizablePanelGroup
          direction="horizontal"
          className="w-full h-fit"
          style={{
            height: "fit-content",
            // borderRight: borderStyle,
            // borderBottom: borderStyle,
          }}
          onLayout={onLayout}
          id={`table-schema-${schema.id}`}
        >
          {schema?.head?.map((hData, idx) => {
            return (
              <>
                <ResizablePanel
                  key={`${idx}-panel`}
                  defaultSize={getDefaultSize(idx)}
                >
                  <>
                    <TableCellUI
                      cellData={hData}
                      handleChange={(value) =>
                        handleHeadChange({ value, colIdx: idx })
                      }
                      ref={
                        isActiveCell({ isHeader: true, rowIdx: 0, colIdx: idx })
                          ? ref
                          : null
                      }
                      setTableState={setTableState}
                      tableState={tabelState}
                      isHeader={true}
                      rowIdx={0}
                      colIdx={idx}
                      inputStyle={{
                        ...headerStyle,
                        borderRight:
                          idx == schema?.head?.length - 1 ? borderStyle : "",
                        borderBottom:
                          schema?.rows?.length == 0 ? borderStyle : "",
                      }}
                      isPreview={isPreview}
                      isSigning={isSigning}
                      tableType={schema.subType}
                      pricingStyle={pricingStyle}
                    />
                    {schema?.rows?.map((row, rowIdx) => {
                      return (
                        <TableCellUI
                          key={`${idx}-${rowIdx}`}
                          cellData={row[idx]}
                          handleChange={(value) =>
                            handleRowChange({ value, rowIdx, colIdx: idx })
                          }
                          ref={
                            isActiveCell({
                              isHeader: false,
                              rowIdx: rowIdx,
                              colIdx: idx,
                            })
                              ? ref
                              : null
                          }
                          setTableState={setTableState}
                          tableState={tabelState}
                          isHeader={false}
                          rowIdx={rowIdx}
                          colIdx={idx}
                          inputStyle={{
                            ...style,
                            borderRight:
                              idx == schema?.head?.length - 1
                                ? borderStyle
                                : "",
                            borderBottom:
                              rowIdx == schema?.rows?.length - 1
                                ? borderStyle
                                : "",
                          }}
                          isPreview={isPreview}
                          isSigning={isSigning}
                          tableType={schema.subType}
                          pricingStyle={pricingStyle}
                        />
                      );
                    })}
                  </>
                </ResizablePanel>
                {idx != schema?.head?.length - 1 &&
                  !(
                    isPreview ||
                    isSigning ||
                    designerState?.mode !== "EDITING"
                  ) && (
                    <ResizableHandle
                      key={`${idx}-handle`}
                      style={{ background: schema.tableBorderColor }}
                      onDragging={(isDragging: boolean) =>
                        handleTableColumnResize(isDragging)
                      }
                    />
                  )}
              </>
            );
          })}
        </ResizablePanelGroup>
      )}
      {schema.subType === "pricing_table" && (
        <>
          <ResizablePanelGroup
            direction="horizontal"
            className="w-full h-fit"
            style={{
              height: "fit-content",
              // borderRight: borderStyle,
              // borderBottom: borderStyle,
            }}
            onLayout={onLayout}
            // id={`table-schema-${schema.id}`}
          >
            {schema?.head?.map((hData, idx) => {
              return (
                <>
                  <ResizablePanel
                    key={`${idx}-panel`}
                    defaultSize={getDefaultSize(idx)}
                    maxSize={getDefaultSize(idx)}
                    minSize={getDefaultSize(idx)}

                    // defaultSize={
                    //   rowResizing === 0 ? undefined : getDefaultSize(idx)
                    // }
                    // maxSize={rowResizing === 0 ? undefined : getDefaultSize(idx)}
                    // minSize={rowResizing === 0 ? undefined : getDefaultSize(idx)}
                  >
                    <>
                      <TableCellUI
                        cellData={hData}
                        handleChange={(value) =>
                          handleHeadChange({ value, colIdx: idx })
                        }
                        ref={
                          isActiveCell({
                            isHeader: true,
                            rowIdx: 0,
                            colIdx: idx,
                          })
                            ? ref
                            : null
                        }
                        setTableState={setTableState}
                        tableState={tabelState}
                        isHeader={true}
                        rowIdx={0}
                        colIdx={idx}
                        inputStyle={{
                          ...headerStyle,
                          borderRight:
                            idx == schema?.head?.length - 1 ? borderStyle : "",
                          borderBottom:
                            schema?.rows?.length == 0 ? borderStyle : "",
                        }}
                        isPreview={isPreview}
                        isSigning={isSigning}
                        tableType={schema.subType}
                        pricingStyle={pricingStyle}
                      />
                    </>
                  </ResizablePanel>
                  {idx != schema?.head?.length - 1 &&
                    !(
                      isPreview ||
                      isSigning ||
                      schema.subType === "pricing_table" ||
                      designerState?.mode !== "EDITING"
                    ) && (
                      <ResizableHandle
                        key={`${idx}-0-handle`}
                        style={{ background: schema.tableBorderColor }}
                        onDragging={(isDragging: boolean) => {
                          handleTableColumnResize(isDragging);
                          handleMinMaxSize({
                            rowIdx: 0,
                            colIdx: idx,
                            isResizing: isDragging,
                          });
                        }}
                      />
                    )}
                </>
              );
            })}
          </ResizablePanelGroup>
          {schema?.rows?.map((row, rowIdx) => {
            return (
              <ResizablePanelGroup
                direction="horizontal"
                className="w-full h-fit"
                style={{
                  height: "fit-content",
                  // borderRight: borderStyle,
                  // borderBottom: borderStyle,
                }}
                onLayout={onLayout}
                // id={`table-schema-${schema.id}`}
              >
                {schema?.head?.map((hData, idx) => {
                  return (
                    <>
                      <ResizablePanel
                        key={`${idx}-panel`}
                        // defaultSize={getDefaultSize(idx)}
                        // maxSize={getDefaultSize(idx)}
                        // minSize={getDefaultSize(idx)}

                        defaultSize={
                          rowResizing === rowIdx + 1
                            ? undefined
                            : getDefaultSize(idx)
                        }
                        maxSize={
                          rowResizing === rowIdx + 1
                            ? undefined
                            : getDefaultSize(idx)
                        }
                        minSize={
                          rowResizing === rowIdx + 1
                            ? undefined
                            : getDefaultSize(idx)
                        }
                      >
                        <>
                          {/* {schema?.rows?.map((row, rowIdx) => {
                    return ( */}
                          <TableCellUI
                            key={`${idx}-${rowIdx}`}
                            cellData={row[idx]}
                            handleChange={(value) =>
                              handleRowChange({ value, rowIdx, colIdx: idx })
                            }
                            ref={
                              isActiveCell({
                                isHeader: false,
                                rowIdx: rowIdx,
                                colIdx: idx,
                              })
                                ? ref
                                : null
                            }
                            setTableState={setTableState}
                            tableState={tabelState}
                            isHeader={false}
                            rowIdx={rowIdx}
                            colIdx={idx}
                            inputStyle={{
                              ...style,
                              borderRight:
                                idx == schema?.head?.length - 1
                                  ? borderStyle
                                  : "",
                              borderBottom:
                                rowIdx == schema?.rows?.length - 1
                                  ? borderStyle
                                  : "",
                            }}
                            isPreview={isPreview}
                            isSigning={isSigning}
                            tableType={schema.subType}
                            pricingStyle={pricingStyle}
                          />

                          {/*); })} */}
                        </>
                      </ResizablePanel>
                      {idx != schema?.head?.length - 1 &&
                        !(
                          isPreview ||
                          isSigning ||
                          schema.subType === "pricing_table" ||
                          designerState?.mode !== "EDITING"
                        ) && (
                          <ResizableHandle
                            // className="h-full"
                            key={`${idx}-${rowIdx}-handle`}
                            style={{ background: schema.tableBorderColor }}
                            onDragging={(isDragging: boolean) => {
                              handleTableColumnResize(isDragging);
                              handleMinMaxSize({
                                rowIdx: rowIdx + 1,
                                colIdx: idx,
                                isResizing: isDragging,
                              });
                            }}
                          />
                        )}
                    </>
                  );
                })}
              </ResizablePanelGroup>
            );
          })}
          <SubtotalMenu {...props} />
        </>
      )}
    </div>
  );
};

// export default TableSchemaUI;
export default forwardRef<HTMLInputElement, Props>(TableSchemaUI);
