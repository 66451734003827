import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { convertToTitleCase } from "../../../utils/contacts";

const CustomFieldsList = ({ fetchFields, fieldsList }: any) => {
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    fetchFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full mt-4 mb-8">
      <div className="flex justify-center items-center w-full  pb-4 border-b border-gray-200">
        <button className=" h-9 gap-1 bg-primary px-4 text-white rounded-l-md text-sm">
          <BsSearch />
        </button>
        <input
          type="text"
          placeholder="Search field..."
          className="flex-1 h-9 border px-3 rounded-r-md border-gray-300 focus:outline-none text-gray-700 focus:border-b focus:border-primary placeholder:font-light placeholder:text-gray-500 placeholder:text-xs text-xs"
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          value={searchText}
        />
      </div>
      <div className="cursor-pointer bg-gray-100 px-0 md:px-4 md:h-12 h-10 border-b border-gray-200 w-full">
        <div className="md:px-0 px-2 flex md:justify-between md:items-center gap-0 md:gap-2 flex-row justify-start items-center h-full">
          <span className="md:text-sm text-xs text-black  notranslate font-bold flex-1">
            Field Name
          </span>
          <span className="md:text-sm text-xs text-black  notranslate font-bold flex-1">
            Key
          </span>
          <span className="md:text-sm text-xs text-black  notranslate font-bold flex-1">
            Type
          </span>
        </div>
      </div>
      {fieldsList
        .filter((field: any) =>
          field.field_name
            .toLowerCase()
            .includes(searchText.trim().toLowerCase())
        )
        .map((field: any, index: number) => {
          return (
            <div
              key={index}
              className="cursor-pointer hover:bg-gray-50 px-0 md:px-4 md:h-12 h-10 border-b border-gray-200 w-full"
            >
              <div className="md:px-0 px-2 flex-1 flex md:justify-between md:items-center  md:gap-2 flex-row justify-start items-center h-full gap-2">
                <span className="text-[10px] md:text-sm  text-black  notranslate flex-1 overflow-hidden text-ellipsis whitespace-nowrap font-medium">
                  {convertToTitleCase(field?.field_name)}
                </span>
                <span className="text-[10px] md:text-sm  text-gray-700 notranslate flex-1 overflow-hidden text-ellipsis italic">
                  {field?.field_name}
                </span>
                <span className="text-[8px] md:text-xs  text-gray-600 notranslate flex-1 overflow-hidden text-ellipsis">
                  {field?.type === "text" ? "Text" : "Number"}
                </span>
              </div>
            </div>
          );
        })}
      {fieldsList.filter((field: any) =>
        field.field_name.toLowerCase().includes(searchText.trim().toLowerCase())
      ).length === 0 &&
        searchText.trim().length > 0 && (
          <p className="my-4 text-sm text-gray-600 w-full text-center">
            No field name found for <strong>"{searchText}"</strong>
          </p>
        )}
      {fieldsList.length === 0 && searchText.trim().length === 0 && (
        <p className="my-4 text-sm text-gray-600 w-full text-center">
          No fields have been added yet.
        </p>
      )}
    </div>
  );
};

export default CustomFieldsList;
