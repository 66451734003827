// @ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";

const GoogleStyleNextPageDropdown = ({ helpers }) => {
  let { onChange, formState, page, index } = helpers;
  const [options, setOptions] = useState([]);

  const questionTypeDropdownRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [displacementAmount, setDisplacementAmount] = useState(0);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, index) => {
    // console.log(-index * 48);
    let displacement = -index * 48;
    setDisplacementAmount(displacement);
    // console.log("displacementAmount", displacementAmount);
    onChange(option.value);
    setIsOpen((prev) => !prev);
  };

  const handleOutsideClick = (e) => {
    if (questionTypeDropdownRef.current !== null) {
      if (questionTypeDropdownRef.current.contains(e.target)) return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    let options = [
      {
        value: "next",
        name: "Continue to next page",
      },
    ];
    formState.pages.forEach((page, i) => {
      if (i !== index && i !== formState?.pages?.length - 1) {
        options.push({
          value: i,
          name: `Go to page (${page.title})`,
        });
      }
    });
    options.push({
      value: "submit",
      name: "Submit form",
    });
    setOptions(options);
  }, [formState]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative">
      <div className="relative">
        <div className="mt-[5px] h-[96px]">
          <div className="flex flex-row items-center">
            <div className="flex flex-row">
              <div className="text-sm mr-4 flex items-center">
                <p className="text-gray-700">After page {index + 1}</p>
              </div>
              <div
                onClick={toggleDropdown}
                className="relative h-[48px] text-sm py-1 text-gray-700 flex items-center cursor-pointer"
              >
                <div className="min-w-[200px]">
                  {
                    options?.find(
                      (option) => option?.value === page?.goToNextPage
                    )?.name
                  }
                </div>
                <div className="ml-[100px]">
                  <MdArrowDropDown className="text-2xl text-gray-500" />
                </div>
                {isOpen && (
                  <div
                    ref={questionTypeDropdownRef}
                    style={{
                      //   marginTop: "-48px",
                      position: "absolute",
                      top: `${displacementAmount}px`,
                      boxShadow:
                        "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
                    }}
                    className="py-2 w-full bg-white border border-[#0000001f] rounded z-50"
                  >
                    {options.map((option, i) => {
                      return (
                        <Fragment key={i}>
                          <div
                            onClick={() => handleOptionClick(option, index)}
                            className={`${
                              page.goToNextPage === option.value &&
                              "bg-[#e4f0fc]"
                            } min-h-[48px] w-full py-2 px-3 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                          >
                            <div className="ml-1">
                              <span className="text-gray-600 text-sm">
                                {option.name}
                              </span>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div
      onClick={toggleDropdown}
      className="h-[48px] min-w-[222px] border py-2 px-3 rounded flex items-center cursor-pointer"
    >
      <div className="ml-1 mr-2">
        <span className="text-gray-600 text-sm">{activeQuestion.name}</span>
      </div>
      <div className="ml-auto">
        <MdArrowDropDown className="text-2xl text-gray-500" />
      </div>
    </div> */}
      </div>
    </div>
  );
};

export default GoogleStyleNextPageDropdown;
