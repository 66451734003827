import { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../constants/quillCutomTag";
import { ALL_FEATURES } from "../../types/tiers";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import PleaseUpgradeModal from "../PleaseUpgradeModal";

const QuillEditor = ({
  varsList,
  className,
  placeholder,
  modules,
  setData,
  data,
}: any) => {
  const [value, setValue] = useState<string>("");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const editorRef = useRef<any>(null);
  const varsDropdownRef = useRef<any>(null);
  const [categories, setCategories] = useState<any>([]);

  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  const featuresEnabled = useIsFeatureEnabled();

  const handleChange = (content: string) => {
    setValue(content);
    setData(content);

    const quillEditor = editorRef.current?.getEditor();
    if (!quillEditor) return;

    quillEditor.focus();

    const selection = quillEditor.getSelection();
    if (selection && selection.index > 0) {
      const [line, offset] = quillEditor.getLine(selection.index);
      const textBeforeCaret = line?.domNode.textContent?.slice(0, offset) ?? "";

      if (textBeforeCaret.endsWith("@")) {
        setShowDropdown(true);
        const range = quillEditor.getBounds(selection.index);
        setDropdownPosition({
          top: range.bottom + 20,
          left: range.left + 10,
        });
      } else {
        setShowDropdown(false);
      }
    } else {
      setShowDropdown(false);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setShowDropdown(false);
    }
  };

  const handleDropdownClick = (data: any) => {
    if (!featuresEnabled?.DYNAMIC_SUBJECT_AND_BODY) {
      setUpgradeModalType("DYNAMIC_SUBJECT_AND_BODY");
      setShowUpgradeModal(true);
      return;
    }
    const quillEditor = editorRef.current?.getEditor();
    if (quillEditor) {
      quillEditor.focus();
      const selection = quillEditor.getSelection();
      if (selection) {
        const index = selection.index;
        let startIndex = index;
        let textBeforeCaret = "";
        let line, offset;

        // Traverse back to find the start of the line with "@"
        while (startIndex > 0) {
          [line, offset] = quillEditor.getLine(startIndex);
          textBeforeCaret = line?.domNode.textContent?.slice(0, offset) ?? "";
          const atPos = textBeforeCaret.lastIndexOf("@");

          if (atPos >= 0) {
            startIndex -= textBeforeCaret.length - atPos;
            break;
          }
          startIndex -= offset;
        }

        // Delete "@" and insert the tag value
        if (startIndex >= 0) {
          quillEditor.deleteText(startIndex, 1);
          quillEditor.insertText(startIndex, data.title + " ", "user"); // Insert the tag followed by a space
          quillEditor.formatText(startIndex, data.title.length, "tag", data);
          quillEditor.setSelection(startIndex + data.title.length + 1, 0); // Set the cursor position after the space
        }
      }
      setShowDropdown(false);
    }
  };
  const handleOutsideClick = (e: any) => {
    if (varsDropdownRef.current !== null) {
      if (varsDropdownRef.current?.contains(e.target)) return;
    }
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setValue(data);
  }, [data]);

  const categorizeData = (data: any) => {
    const categories: any = {
      default: [],
      variable: [],
      contact_field: [],
    };

    data.forEach((item: any) => {
      if (categories[item.type]) {
        categories[item.type].push(item);
      }
    });

    return categories;
  };
  useEffect(() => {
    const categoryData = categorizeData(varsList);
    setCategories(categoryData);
  }, [varsList]);

  return (
    <div className={`quill-editor ${className}`}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={modules}
        ref={editorRef}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
      {showDropdown && (
        <div
          ref={varsDropdownRef}
          id="dropdown"
          className="dropdown-content"
          style={{
            top: dropdownPosition.top,
            left:
              dropdownPosition.left + 240 > window.innerWidth
                ? "auto"
                : dropdownPosition.left,
            position: "absolute",
            right: "16px",
          }}
        >
          {categories?.default && categories?.default.length > 0 && (
            <>
              <div className="dropdown-category">
                <span className="text-xs text-slate-500 mb-2 block ">
                  SIGNER DETAILS
                </span>
                {categories?.default.map((item: any, index: number) => (
                  <div
                    key={`default-${index}`}
                    className="dropdown-item hover:bg-slate-100"
                    onClick={() => handleDropdownClick(item)}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            </>
          )}

          {categories?.variable && categories?.variable.length > 0 && (
            <>
              <div className="w-full h-[1px] bg-slate-200 mb-2"></div>
              <div className="dropdown-category">
                <span className="text-xs text-slate-500 mb-2 block ">
                  VARIABLES
                </span>
                {categories.variable.map((item: any, index: number) => (
                  <div
                    key={`variable-${index}`}
                    className="dropdown-item hover:bg-slate-100"
                    onClick={() => handleDropdownClick(item)}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            </>
          )}
          {categories?.contact_field &&
            categories?.contact_field.length > 0 && (
              <>
                <div className="w-full h-[1px] bg-slate-200 mb-2 "></div>
                <div className="dropdown-category">
                  <span className="text-xs text-slate-500 mb-2 block">
                    CONTACT FIELDS
                  </span>
                  {categories?.contact_field?.map(
                    (item: any, index: number) => (
                      <div
                        key={`contact_field-${index}`}
                        className="dropdown-item hover:bg-slate-100"
                        onClick={() => handleDropdownClick(item)}
                      >
                        {item.title}
                      </div>
                    )
                  )}
                </div>
              </>
            )}
        </div>
      )}
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
    </div>
  );
};

export default QuillEditor;
