// @ts-nocheck
import React, { useEffect } from "react";
import "./index.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { replaceHTags } from "../../../utils/editor";

const FormTitleInput = ({ helpers }) => {
  let { formState, setFormState } = helpers;
  let questionQuillRef = React.useRef(null);
  const [unprivilegedEditor, setUnprivilegedEditor] = React.useState(null);

  function showEditMenu(display) {
    let input = document.querySelector("#quill-form-title .ql-toolbar");
    input.style.display = display;
  }

  useEffect(() => {
    let quilleditor = questionQuillRef.current?.getEditor();
    // setQuilleditor(quilleditor);
    const unprivilegedEditor =
      questionQuillRef.current?.makeUnprivilegedEditor(quilleditor);
    setUnprivilegedEditor(unprivilegedEditor);
  }, []);

  return (
    <div className="w-full mr-4">
      <ReactQuill
        ref={questionQuillRef}
        value={formState?.titleHtml ?? formState?.title}
        placeholder="Form Title"
        onChange={async (value) => {
          let newValue = value;
          newValue = await replaceHTags(newValue);
          setFormState((prevState) => {
            const newForm = { ...prevState };
            newForm.titleHtml = newValue;
            unprivilegedEditor?.getText() &&
              (newForm.title = unprivilegedEditor?.getText());
            return newForm;
          });
        }}
        onFocus={() => {
          showEditMenu("flex");
        }}
        onBlur={() => {
          showEditMenu("none");
        }}
        modules={{
          toolbar: [
            ["bold", "italic", "underline"],
            // [
            //   { list: "ordered" },
            //   { list: "bullet" },
            // ],
            [
              "link",
              // "image"
            ],
            ["clean"],
          ],
        }}
        style={{ fontSize: "32px" }}
        className="fade quill-que flex"
        id="quill-form-title"
      />
    </div>
  );
};

export default FormTitleInput;
