import React, { useState } from "react";
import "./index.css";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { Slide, ToastContainer, toast } from "react-toastify";
import { validateEmail } from "../../utils/validateEmail";
import { getDashboardUrl, getDashboardUrlV1 } from "../../constants/constants";
import { FaArrowCircleRight } from "react-icons/fa";
import { PreviewFetchedType } from "../../pdfme/common/src/type";

export type SignerDeclinedProps = {
  fetchedData: PreviewFetchedType;
};

const SignerDeclined = ({ fetchedData }: SignerDeclinedProps) => {
  const [signUpEmail, setSignUpEmail] = useState("");
  return (
    <div className="w-full h-screen bg-white flex justify-center items-center">
      <div className="mx-auto max-w-[1200px]">
        <div className="w-full lg:py-0 px-3 sm:px-6 lg:px-12 flex flex-col justify-center items-center">
          <div className="w-full max-w-[1280px] flex flex-col items-center">
            <div className="text-center">
              <div className="success-animation">
                <svg
                  className="cross__svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="cross__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="cross__path cross__path--right"
                    fill="none"
                    d="M16,16 l20,20"
                  />
                  <path
                    className="cross__path cross__path--right"
                    fill="none"
                    d="M16,36 l20,-20"
                  />
                </svg>
              </div>
              <h1 className="text-2xl sm:text-5xl font-semibold leading-[40px] sm:leading-[60px]">
                You Declined to Sign
              </h1>
              <p className="mt-4 sm:text-xl">
                A notification email will be sent to the owner of this document.
              </p>
            </div>

            <div className="mt-6 text-center flex flex-col justify-center items-center">
              <div className="flex mt-4 w-full px-2 ">
                <input
                  type="text"
                  onChange={(e) => {
                    setSignUpEmail(e.target.value);
                  }}
                  placeholder="Enter your Email"
                  className="max-w-[60vw] text-xs sm:text-sm px-4 sm:px-5 border border-primary outline-none rounded-l-md h-[38px] sm:h-[45px]"
                  style={{
                    border: fetchedData?.accountInfo?.theme
                      ? `1px solid ${fetchedData?.accountInfo?.theme}`
                      : "",
                  }}
                />
                <button
                  className="max-w-[30vw] bg-primary text-xs sm:text-sm h-[38px] sm:h-[45px] font-semibold text-white flex items-center justify-center px-4 sm:px-5 hover:opacity-90 rounded-r-md"
                  style={{
                    background: fetchedData?.accountInfo?.theme,
                    color: fetchedData?.accountInfo?.themeText,
                  }}
                  onClick={() => {
                    if (validateEmail(signUpEmail)) {
                      window.open(
                        `${getDashboardUrlV1()}/register?from=FORM_FINAL_SCREEN&ownerEmail=${signUpEmail}`,
                        "_blank"
                      );
                    } else {
                      toast.error("Invalid Email");
                    }
                  }}
                >
                  SignUp
                  <FaArrowCircleRight className="ml-3 hidden sm:block text-xl" />
                </button>
              </div>
              <p className="mt-4 text-sm text-gray-500">
                Start your free trial today. No credit card required.
              </p>
            </div>

            <div className="bg-white rounded-md mt-20 h-[100px]">
              <img
                src={`https://i.imgur.com/BfvwItR.png`}
                alt={``}
                className="w-full grayscale max-h-full max-w-full"
              />
            </div>
          </div>
        </div>
        <ToastContainer
          transition={Slide}
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
        />
      </div>
    </div>
  );
};

export default SignerDeclined;
