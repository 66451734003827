import React, { useState, useEffect } from "react";
import "./EmailInputWithRoles.css";
import { removeSquareBrackets } from "../../utils/uiUtils";
import FetchDealsModal from "../Modals/FetchDealsModal";
import _ from "lodash";
import { BiUnlink } from "react-icons/bi";
import { IoLinkSharp } from "react-icons/io5";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { IntegrationsWithToken } from "../../constants/Integrations/index";
import { BoloButton } from "../Common/Button/BoloButton";
import { useAppDispatch } from "../../redux/store";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
import { ALL_FEATURES } from "../../types/tiers";

type VariableInputProps = {
  variables: any[];
  setVariables: any;
  owner: any;
  dealId?: string;
  hubspotdealId?: string;
  setHubspotDealInfo: React.Dispatch<React.SetStateAction<string | null>>;
};
const VariableInput = ({
  variables,
  setVariables,
  owner,
  dealId,
  hubspotdealId,
  setHubspotDealInfo,
}: VariableInputProps) => {
  const [showFetchDealsModal, setShowFetchDealsModal] = useState<string>("");
  const [selectedDeal, setSelectedDeal] = useState<any>({});
  const [loading, setLoading] = useState<string>("");
  const integrations = IntegrationsWithToken(
    owner,
    window.location.href.replace(window.location.origin, "")
  );

  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  // Redux code ----------------------------------------------------------------------------------
  const dispatch = useAppDispatch();
  // const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  // const workSpaceDetails = useWorkSpaceDetails();
  // const ownerPlanDetails = useOwnerPlanDetails();
  const featuresEnabled = useIsFeatureEnabled();

  useEffect(() => {
    dispatch(getOwnerThunk());
    // dispatch(getOwnerPaymentStatusThunk());
  }, []);

  // Redux code end -------------------------------------------------------------------------------
  async function getPipedriveDealById(type: string, dealId: string) {
    setLoading(type);
    try {
      const response = await axios.get(
        `/${type}/dealById?dealId=${dealId}&ownerEmail=${owner?.email}`
      );
      const { success, deal } = response.data;
      if (success) {
        setSelectedDeal({ deal: deal, type: type });
      }
    } catch (err) {
      console.log(err);
      toast.error("Error while fetching deal!");
    } finally {
      setLoading("");
    }
  }

  useEffect(() => {
    console.log("selectedDeal", selectedDeal);
    if (selectedDeal?.deal) {
      if (selectedDeal?.type === "hubspot") {
        setHubspotDealInfo(selectedDeal?.deal?.id);
      }
      const elem = integrations.filter((ele) => ele.key == selectedDeal?.type);
      // console.log("elem", elem);
      let dealsVariablesMapping = elem[0].variableMapping;
      const DealsVaraibleKeys = Object.keys(dealsVariablesMapping);
      const newVariables = variables.map((ele) => {
        if (DealsVaraibleKeys.includes(ele?.varName)) {
          //@ts-ignore
          let keyOfDeal = dealsVariablesMapping[ele.varName];
          return {
            ...ele,
            varValue: _.get(selectedDeal?.deal, keyOfDeal)
              ? `${_.get(selectedDeal?.deal, keyOfDeal)}`
              : "",
          };
        }
        return ele;
      });
      setVariables(newVariables);
    }
  }, [selectedDeal]);

  useEffect(() => {
    if (dealId && dealId != "" && owner) {
      getPipedriveDealById("pipedrive", dealId);
    }
    if (hubspotdealId && hubspotdealId != "" && owner) {
      getPipedriveDealById("hubspot", hubspotdealId);
    }
  }, [dealId, owner, hubspotdealId]);
  // console.log(selectedDeal);
  return (
    <div className="py-1 md:py-2 w-[100%] md:w-[65%] max-w-[800px] md:mt-4 mb-4">
      {integrations.map((integration, index) => {
        if (
          owner?.integrationTokens?.[integration.key]?.access_token &&
          owner?.integrationTokens?.[integration.key]?.access_token !== ""
        ) {
          return (
            <div className="mb-4" key={index}>
              <p className="text-xs font-medium flex items-center gap-3">
                {" "}
                {integration?.name} Integration{" "}
                {selectedDeal?.type == integration.key ? (
                  <button onClick={() => setSelectedDeal({})}>
                    <BiUnlink className="text-gray-500 text-lg" />
                  </button>
                ) : (
                  <IoLinkSharp className="text-gray-500 text-lg" />
                )}
              </p>
              <p className="text-xs mb-4 font-medium text-gray-500">
                You can select deal to set variables.
              </p>
              {selectedDeal?.type == integration.key ? (
                <>
                  <div className="w-full md:flex items-center my-1 md:my-0 py-1">
                    <div className="flex gap-2 w-[100%] md:w-[40%] p-2">
                      <span className="w-full whitespace-nowrap text-ellipsis text-xs md:text-sm md:overflow-clip lg:overflow-hidden">
                        Selected Deal:
                      </span>
                    </div>

                    <div className="w-[100%] md:w-[60%]  flex flex-row justify-center items-center gap-2 border border-gray-300 rounded-md p-2">
                      <span className="w-full text-xs md:text-sm outline-none">
                        {selectedDeal?.deal?.title ||
                          selectedDeal?.deal?.name ||
                          selectedDeal?.deal?.properties?.dealname}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <BoloButton
                  variant={"primary"}
                  size={"sm"}
                  onClick={() => {
                    if (!featuresEnabled?.CRM_INTEGRATIONS) {
                      setUpgradeModalType("CRM_INTEGRATIONS");
                      setShowUpgradeModal(true);
                      return;
                    }
                    setShowFetchDealsModal(integration.key);
                  }}
                  className="w-[180px] flex justify-center"
                >
                  {loading == integration.key ? (
                    <TailSpin height="20" width="20" color="#ffff" />
                  ) : (
                    <span>Select Deal</span>
                  )}
                </BoloButton>
              )}
              {showFetchDealsModal == integration.key && (
                <FetchDealsModal
                  setShowFetchDealsModal={setShowFetchDealsModal}
                  ownerEmail={owner?.email}
                  setSelectedDeal={setSelectedDeal}
                  integration={integration}
                />
              )}
              {showUpgradeModal && (
                <PleaseUpgradeModal
                  show={showUpgradeModal}
                  setShow={setShowUpgradeModal}
                  data={upgradeModalData}
                  blockingType={upgradeModalType}
                />
              )}
            </div>
          );
        }
      })}
      <div className="md:mt-4 pt-2">
        <p className="text-xs font-medium">Set Variables</p>
        <p className="text-xs mb-2 font-medium text-gray-500">
          You can leave the variables empty as well
        </p>
        <div
        // className={`transform translate-y-0 transition-transform ease-in-out duration-500`}
        >
          {variables?.map((variable: any, index: number) => {
            return (
              <div key={index} className="md:flex items-center gap-4 text-xs">
                <div className="w-full md:flex items-center my-1 md:my-0 py-1">
                  <div className="flex gap-2 w-[100%] md:w-[40%] p-2">
                    <span className="w-full whitespace-nowrap text-ellipsis text-xs md:text-sm md:overflow-clip lg:overflow-hidden">
                      {removeSquareBrackets(variable?.varName)}
                    </span>
                  </div>
                  <div className="w-[100%] md:w-[60%]  flex flex-row justify-center items-center gap-2 border border-gray-300 rounded-md p-2">
                    <input
                      type="Text"
                      placeholder="Variable Value"
                      value={variable?.varValue}
                      onChange={(e) => {
                        setVariables((prev: any) =>
                          prev.map((item: any, i: number) =>
                            item?.varName?.trim() == variable?.varName
                              ? { ...item, varValue: e.target.value }
                              : item
                          )
                        );
                      }}
                      className="w-full text-xs md:text-sm outline-none"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default VariableInput;
