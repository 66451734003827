import { MdClose } from "react-icons/md";

const UpdateNameModal = ({
  handleRename,
  templateName,
  setIsOpen,
  setTemplateName,
}: any) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.4)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-full h-screen  flex justify-center items-start md:items-center p-4"
    >
      <div className={`w-[420px] max-w-full relative bg-white rounded-xl p-6`}>
        <MdClose
          onClick={() => {
            setIsOpen(false);
          }}
          className="text-2xl absolute top-1 right-0 cursor-pointer m-4"
        />
        <h2 className="text-gray-700 text-md font-semibold mb-2">
          Edit Template Name
        </h2>
        <input
          type="text"
          className="outline-none w-full rounded-md border focus:border-primary mt-2 mb-3 px-3 py-2"
          value={templateName}
          placeholder="Enter template name..."
          onInput={(e) => {
            // @ts-ignore
            setTemplateName(e.target.value);
          }}
        />
        <button
          onClick={handleRename}
          disabled={templateName?.length === 0}
          className={` flex items-center justify-center py-2 px-4 cursor-pointer text-sm bg-primary text-white rounded overflow-hidden float-right`}
        >
          Update Name
        </button>
      </div>
    </div>
  );
};

export default UpdateNameModal;
