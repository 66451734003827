import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { BoloButton } from "../../../components/Common/Button/BoloButton";
import { BoloInput } from "../../../components/Common/Input/BoloInput";
import {
  BoloInputOTP,
  BoloInputOTPGroup,
  BoloInputOTPSeparator,
  BoloInputOTPSlot,
} from "../../../components/Common/OTP/BoloOTP";
import axios from "../../../utils/axios";
import { formatTime } from "../../../utils/uiUtils";
import { validateEmail } from "../../../utils/validateEmail";

const WhiteLabelOTPLogin = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    data: { email: "", otp: "", password: "" },
    isSent: false,
  });
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [countDown, setCountDown] = useState(0);

  const handleUsername = (email: string) => {
    setFormData((prev) => ({ ...prev, data: { ...prev.data, email: email } }));
  };
  const handlePassword = (password: string) => {
    setFormData((prev) => ({
      ...prev,
      data: { ...prev.data, password: password },
    }));
  };

  const handleSendOTP = async () => {
    setLoading(true);
    try {
      if (!formData.data.email) {
        toast.error("Email is required");
        return;
      }

      if (!validateEmail(formData.data.email)) {
        toast.error("Invalid Email");
        return;
      }

      let body = {
        action: "send",
        email: formData.data.email,
        password: formData.data.password,
        // otp: formData.data.otp,
      };

      let { data } = await axios.post("/whitelable/otp", body);

      if (data?.isVerified && data?.success) {
        localStorage.setItem("x-partner-auth", data?.token);
        navigate("/partner/admin?tab=INFO");
      }

      if (data?.success) {
        setFormData((prev) => ({ ...prev, isSent: true }));
        setCountDown(data?.duration || 60);
        setLoading(false);
        toast.success(data?.message || "OTP sent via E-Mail");
      } else {
        toast.error(data?.message || "Could not send OTP");
        setLoading(false);
        return;
      }

      setLoading(false);
    } catch (error) {
      console.log("errror in handleSendOTP");
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      if (!formData.data.otp) {
        toast.error("OTP is required");
        return;
      }

      let body = {
        action: "verify",
        email: formData.data.email,
        otp: formData.data.otp,
      };

      let { data } = await axios.post("/whitelable/otp", body);
      // AY continue here
      if (data?.isVerified && data?.success) {
        localStorage.setItem("x-partner-auth", data?.token);
        navigate("/partner/admin?tab=INFO");
      } else {
        setFormData((prev) => ({ ...prev, data: { ...prev.data, otp: "" } }));
        toast.error(
          data?.message || "Invalid or Expired OTP please try again."
        );
      }
    } catch (error) {
      console.log("errror in handleVerifyOtp");
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleResend = () => {
    setExpired(false);
    setFormData((prev) => ({ ...prev, data: { ...prev.data, otp: "" } }));
    handleSendOTP();
  };

  useEffect(() => {
    let intervalId: any;

    if (countDown > 0) {
      intervalId = setInterval(() => {
        if (countDown == 1) {
          setExpired(true);
        }
        setCountDown((count) => count - 1);
      }, 1000);
    }

    if (countDown == 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [formData.isSent, countDown]);

  return (
    <div className="h-[100vh] w-[100vw] flex items-center justify-center">
      <div className="flex flex-col p-8 gap-2 border shadow-md rounded-md min-w-[450px] w-[450px] max-w-[450px]">
        <p className="mb-4 font-semibold text-center text-xl text-gray-600">
          BoloForms Partners
        </p>
        {!formData.isSent && (
          <>
            <BoloInput
              variant={"bolo-primary"}
              placeholder="Email"
              value={formData.data.email}
              onChange={(e) => handleUsername(e.target.value)}
              type="email"
            />
            <BoloInput
              variant={"bolo-primary"}
              placeholder="Password"
              value={formData.data.password}
              onChange={(e) => handlePassword(e.target.value)}
              type="password"
            />
          </>
        )}
        {formData.isSent && (
          <div className="flex flex-col gap-6 items-center">
            <BoloInputOTP
              className="text-sm"
              maxLength={6}
              minLength={6}
              value={formData.data.otp}
              onChange={(value) =>
                setFormData((prev) => ({
                  ...prev,
                  data: { ...prev.data, otp: value },
                }))
              }
            >
              <BoloInputOTPGroup>
                <BoloInputOTPSlot index={0} variant="bolo-primary" />
                <BoloInputOTPSlot index={1} variant="bolo-primary" />
                <BoloInputOTPSlot index={2} variant="bolo-primary" />
              </BoloInputOTPGroup>
              <BoloInputOTPSeparator />
              <BoloInputOTPGroup>
                <BoloInputOTPSlot index={3} variant="bolo-primary" />
                <BoloInputOTPSlot index={4} variant="bolo-primary" />
                <BoloInputOTPSlot index={5} variant="bolo-primary" />
              </BoloInputOTPGroup>
            </BoloInputOTP>

            <div className="flex flex-col items-center text-xs text-gray-700 gap-2">
              <p>{formatTime(countDown)}</p>
            </div>
          </div>
        )}
        <BoloButton
          variant={"bolo-primary"}
          size={"sm"}
          className="mt-4"
          onClick={() =>
            formData.isSent ? handleVerifyOtp() : handleSendOTP()
          }
          disabled={
            (formData.isSent ? !formData.data.otp : !formData.data.email) ||
            loading
          }
        >
          {formData.isSent ? "Verify OTP" : "Send OTP"}
        </BoloButton>
        {formData.isSent && (
          <div className="flex gap-4 text-xs text-gray-700 justify-center">
            <button
              onClick={() => handleResend()}
              disabled={!expired}
              className="disabled:opacity-60 disabled:cursor-not-allowed"
            >
              Resend Email
            </button>
            <button
              onClick={() => {
                setFormData({
                  data: { email: "", otp: "", password: "" },
                  isSent: false,
                });
                setLoading(false);
                setExpired(false);
                setCountDown(0);
              }}
              className="disabled:opacity-60 disabled:cursor-not-allowed"
            >
              Change Email
            </button>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default WhiteLabelOTPLogin;
