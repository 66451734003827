// @ts-nocheck

import React, { useEffect, useState } from "react";
import {
  MdAddCircleOutline,
  MdOutlineCalendarViewDay,
  MdOutlineImage,
  MdTextFields,
} from "react-icons/md";
import { v4 } from "uuid";
import { FiYoutube } from "react-icons/fi";
import CustomTooltip from "../CustomTooltip";

const AddQuestionFloater = ({ helpers }) => {
  let {
    editorContainerRef,
    formState,
    setFormState,
    active,
    setActive,
    pagesRef,
    questionsRef,
  } = helpers;
  const [topPosition, setTopPosition] = useState(0);

  const handleAddQuestion = async (type = "RadioGroup") => {
    let question = {
      name: type,
    };

    const newQuestion = {
      type: question.name,
      title: `Question  ${
        formState.pages[active.pageIndex].questions.length + 1
      }`,
      description: "",
      isDescription: true,
      required: false,
      id: v4(),
      value: "",
      otherValue: "",
    };
    if (
      question.name === "Checkbox" ||
      question.name === "RadioGroup" ||
      question.name === "Dropdown"
    ) {
      newQuestion.options = [
        {
          type: "option",
          text: "Option 1",
          value: "",
          enabled: true,
          goToNextPage: "next",
        },
        {
          type: "option",
          text: "Option 2",
          value: "",
          enabled: true,
          goToNextPage: "next",
        },
        {
          type: "option",
          text: "Option 3",
          value: "",
          enabled: true,
          goToNextPage: "next",
        },
        { type: "add", text: "Option 4", value: "", enabled: false },
        { type: "other", text: "Other (describe)", value: "", enabled: false },
      ];
      newQuestion.hasOtherOption = false;

      if (question.name !== "Checkbox") {
        newQuestion.pageBasedOnAnswer = false;
      }
    }
    if (question.name === "FileUpload") {
      newQuestion.multiple = false;
    }

    // console.log(
    //   "active.pageIndex < formState.pages.length",
    //   active.pageIndex < formState.pages.length
    // );
    console.log("active", active);
    let insertIndex =
      //   index ??
      active.pageIndex < formState.pages.length
        ? active.pageIndex
        : formState.pages.length - 1;
    console.log("insertIndex", insertIndex);
    let questionIndex =
      active.questionIndex ??
      formState.pages[insertIndex].questions?.length - 1;
    questionIndex = questionIndex + 1;
    await setFormState((prevState) => {
      const newForm = { ...prevState };
      //   newForm.pages[insertIndex].questions.push(newQuestion);
      newForm.pages[insertIndex].questions.splice(
        questionIndex,
        0,
        newQuestion
      );

      let isOneEmptyPage = false;
      newForm.pages.forEach((page) => {
        if (page.questions.length === 0) {
          isOneEmptyPage = true;
        }
      });
      if (!isOneEmptyPage) {
        // if (newForm.pages[active.pageIndex].questions.length === 0) {
        // add new page
        newForm.pages.push({
          title: `Page ${newForm.pages.length + 1}`,
          description: "Page Description",
          id: v4(),
          questions: [],
          goToNextPage: "next",
          goToPrevPage: "prev",
        });
      }
      return newForm;
    });

    setActive((prev) => ({ ...prev, pageIndex: insertIndex, questionIndex }));

    // toast.success("Question added!");
  };

  const handleOtherItems = (type) => {
    switch (type) {
      case "TitleDesc":
        break;
      case "Image":
        break;
      case "Video":
        break;

      default:
        break;
    }
  };

  const handleAddNewPage = async () => {
    // last page is always in active
    // therefor the length will always be
    // actual num of pages + 1
    const prevNumOfPages = formState.pages.length - 1;
    console.log({ prevNumOfPages });
    await setFormState((prevState) => {
      const newForm = { ...prevState };
      newForm.pages.push({
        title: `Page ${newForm.pages.length + 1}`,
        description: "Page Description",
        id: v4(),
        questions: [],
        goToNextPage: "next",
        goToPrevPage: "prev",
      });

      return newForm;
    });
    setActive((prev) => ({
      ...prev,
      // pageIndex: formState.pages.length - 1,
      pageIndex: prevNumOfPages,
      questionIndex: null,
    }));
    console.log("active.pageIndex", active);
    console.log("formState.pages.length", formState.pages.length);
    pagesRef.current[formState.pages.length - 1].scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
    // pagesRef.current[active.pageIndex].scrollTo({
    //   top: pagesRef.current.scrollHeight,
    //   behavior: "smooth",
    // });
  };

  useEffect(() => {
    const handleCalculateTopPosition = () => {
      let top =
        editorContainerRef.current.scrollHeight -
        (editorContainerRef.current.scrollHeight -
          editorContainerRef.current.scrollTop);
      setTopPosition(top);

      // console.log("top", top);
    };

    let editorContainerRefCurrent = editorContainerRef.current;

    editorContainerRefCurrent.addEventListener(
      "scroll",
      handleCalculateTopPosition
    );

    return () => {
      editorContainerRefCurrent.removeEventListener(
        "scroll",
        handleCalculateTopPosition
      );
    };
  }, []);

  return (
    <div
      style={{
        top: `${topPosition + (formState.pages?.length > 2 ? 56 : 20)}px`,
        boxShadow:
          "0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122)",
        transition: "all .3s cubic-bezier(0.4,0,0.2,1)",
        zIndex: 100,
      }}
      className="absolute -mr-3 -right-[50px] w-[50px] py-2 rounded-lg border border-[#dadce0] bg-white flex flex-col items-center"
    >
      <div
        data-tooltip-id="addQuestion"
        onClick={() => handleAddQuestion()}
        className="w-[36px] h-[36px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
      >
        <MdAddCircleOutline className="text-2xl text-gray-600" />
      </div>
      <CustomTooltip
        helpers={{
          id: "addQuestion",
          content: "Add Question",
          place: "right",
        }}
      />

      <div
        data-tooltip-id="addTitleAndDescription"
        onClick={() => handleAddQuestion("TitleDesc")}
        className="mt-1 w-[36px] h-[36px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
      >
        <MdTextFields className="text-2xl text-gray-600" />
      </div>
      <CustomTooltip
        helpers={{
          id: "addTitleAndDescription",
          content: "Add Title And Description",
          place: "right",
        }}
      />

      <div
        data-tooltip-id="addImage"
        onClick={() => handleAddQuestion("Image")}
        className="mt-1 w-[36px] h-[36px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
      >
        <MdOutlineImage className="text-2xl text-gray-600" />
      </div>
      <CustomTooltip
        helpers={{
          id: "addImage",
          content: "Add Image",
          place: "right",
        }}
      />

      <div
        data-tooltip-id="addVideo"
        onClick={() => handleAddQuestion("Video")}
        className="mt-1 w-[36px] h-[36px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
      >
        <FiYoutube className="text-xl text-gray-600" />
      </div>
      <CustomTooltip
        helpers={{
          id: "addVideo",
          content: "Add Video",
          place: "right",
        }}
      />

      <div
        data-tooltip-id="addSection"
        onClick={handleAddNewPage}
        className="mt-1 w-[36px] h-[36px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
      >
        <MdOutlineCalendarViewDay className="text-2xl text-gray-600" />
      </div>
      <CustomTooltip
        helpers={{
          id: "addSection",
          content: "Add Section",
          place: "right",
        }}
      />
    </div>
  );
};

export default AddQuestionFloater;
