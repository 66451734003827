import Quill from "quill";

const Inline = Quill.import("blots/inline");

class TagBlot extends Inline {
  static create(value) {
    let node = super.create();
    if (value && value.title) {
      node.setAttribute("title", value.title);
      node.setAttribute("key", value.key);
      node.setAttribute("value", value.value);
      node.setAttribute("type", value.type);
      node.setAttribute("contenteditable", "false");
      node.setAttribute("class", "tag");
    }
    return node;
  }

  static formats(node) {
    return {
      title: node.getAttribute("title"),
      key: node.getAttribute("key"),
      value: node.getAttribute("value"),
      type: node.getAttribute("type"),
    };
  }
}

TagBlot.blotName = "tag";
TagBlot.tagName = "tag";
TagBlot.className = "tag";

Quill.register(TagBlot);
