import { Helmet } from "react-helmet";
import { useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";
import Uploader from "../../components/Ui/Uploader";
import { Slide, toast, ToastContainer } from "react-toastify";
import { IoArrowBackCircle, IoArrowForwardCircle } from "react-icons/io5";
import { useEffect, useState } from "react";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";
import BoloModal from "../../components/Common/Modal/BoloModal";
import BoloCheckboxMui from "../../components/Common/Checkbox";

const ReplacePdf = () => {
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [importFields, setImportFields] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [docName, setDocName] = useState<string>("");
  const [searchParams] = useSearchParams();
  let documentId = searchParams.get("documentId");
  let formId = searchParams.get("formId");
  let type = searchParams.get("type") || "PDF";

  const typesObj = {
    FORM: {
      redirectUrl: `/create/pdf-mapping?id=${formId}`,
    },
    PDF: { redirectUrl: `/create/pdf-signature?documentId=${documentId}` },
    "PDF-TEMPLATE": {
      redirectUrl: `/create/pdf-template?documentId=${documentId}`,
    },
  } as Record<string, { redirectUrl: string }>;
  let navigate = useNavigate();

  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const handleFileUpload = (base64File: any, numberOfPages: number) => {
    if (!base64File) return;
    setFile(base64File);
    return;
  };
  const replacePdfSignature = async () => {
    try {
      setImporting(true);
      const formData = new FormData();
      let pdfFile = file;
      formData.append("pdfFile", pdfFile || "");
      const { data } = await axios.post("/document/replacePdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: {
          documentId,
          importFields,
        },
      });
      if (data?.success) {
        setImporting(false);
        navigate(typesObj[type]?.redirectUrl);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error while uploading!!");
    }
  };
  const replacePdfForm = async () => {
    try {
      setImporting(true);

      const formData = new FormData();
      let pdfFile = file;
      formData.append("pdfFile", pdfFile || "");
      const { data } = await axios.post("/forms/replacePdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: {
          formId,
          importFields,
        },
      });
      if (data?.success) {
        setImporting(false);
        navigate(typesObj["FORM"]?.redirectUrl);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error while uploading!!");
    }
  };
  const fetchDataForm = async () => {
    try {
      const { data } = await axios.get(`/private-forms?formId=${formId}`);
      setDocName(data?.forms[0]?.formJson?.title);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDataDoc = async () => {
    try {
      let { data: updatedDocument } = await axios.get(
        `/getOneDocument?documentId=${documentId}`
      );
      setDocName(updatedDocument?.document?.documentName);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (type === "FORM") {
      fetchDataForm();
    } else {
      fetchDataDoc();
    }
  }, []);
  return (
    <div className={`w-full`}>
      <Helmet>
        <title translate="no">Replace Pdf</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Replace Pdf" />
        <meta name="description" content="Effortlessly replace your pdf." />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/replace/pdf`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Pdf Customize`} />
        <meta
          property="og:description"
          content="Effortlessly replace your pdf."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/replace/pdf"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>

      <div className="flex flex-col">
        <div className="template-navbar h-14 px-8 py-2 bg-primary flex gap-2 text-white items-center">
          <IoArrowBackCircle
            className="hover:opacity-70 text-3xl cursor-pointer rounded-sm"
            onClick={() => {
              navigate(-1);
            }}
          />
          <p className="text-sm ml-2">Replace Pdf</p>
          <p className="ml-auto text-sm">
            <span className="font-semibold">{docName}</span>
          </p>
        </div>
        <div className="mt-2 md:mt-8 flex flex-col md:py-4 px-3 md:px-8 gap-4">
          <div className="uploader flex flex-col ">
            <div className="hidden md:block w-[100%] md:w-[65%] max-w-[800px] mx-auto">
              <h1 className="text-lg md:text-2xl font-semibold md:font-bold mb-1 md:mb-3 text-gray-700">
                Upload your File
              </h1>
              <p className="mb-3 w-full capitalize text-sm md:text-base md:font-medium">
                Drop your File to replace with current pdf{" "}
              </p>
            </div>
            <Uploader
              preUploadedFile={null}
              onFileUpload={handleFileUpload}
              isOnlyPdfTemplate={true}
              isWithRole={true}
              setFileName={setFileName}
            />
          </div>
          <div
            className={`mx-auto  items-center justify-center w-[100%] md:w-[65%] max-w-[800px] py-2`}
          >
            {file && (
              <div className=" flex md:gap-2 mb-3 items-center w-full justify-end">
                <BoloCheckboxMui
                  id="importFields"
                  onChange={(e: any) => {
                    setImportFields((prev) => !prev);
                    if (e.target.checked) {
                      setShowWarningModal(true);
                    }
                  }}
                  checked={importFields}
                />
                <label
                  htmlFor="importFields"
                  className="cursor-pointer text-sm md:text-base"
                >
                  Want to import fields as well?
                </label>
              </div>
            )}
            <div className="flex gap-4">
              <BoloButton
                className="flex justify-center gap-3 items-center w-full"
                variant={"tertiary"}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <IoArrowBackCircle className="inline-block text-2xl" />
                <span className="">Go Back</span>{" "}
              </BoloButton>

              <BoloButton
                className="flex justify-center gap-3 items-center w-full"
                variant={"primary"}
                onClick={() => {
                  if (type === "FORM") {
                    replacePdfForm();
                  } else {
                    replacePdfSignature();
                  }
                }}
                disabled={file === null || importing}
              >
                <span className="">Continue</span>{" "}
                <IoArrowForwardCircle className="inline-block text-2xl" />
              </BoloButton>
            </div>
          </div>
        </div>
        {showWarningModal && (
          <BoloModal
            content={
              <>
                <div className="text-base md:text-lg mb-4 font-semibold ">
                  Import Fields
                </div>

                <div className="gap-2  items-center justify-between text-sm text-gray-600">
                  <p>
                    Please ensure that the new file closely resembles the
                    previous one to avoid any potential loss of fields.
                  </p>
                </div>

                <div className="flex gap-2 items-center justify-end mt-8">
                  <BoloButton
                    variant={"secondary"}
                    size={"sm"}
                    onClick={() => {
                      setImportFields(false);
                      setShowWarningModal(false);
                    }}
                  >
                    Cancel Import
                  </BoloButton>
                  <BoloButton
                    variant={"primary"}
                    size={"sm"}
                    onClick={() => {
                      setShowWarningModal(false);
                    }}
                  >
                    Import Anyway
                  </BoloButton>
                </div>
              </>
            }
            setShow={setShowWarningModal}
          />
        )}
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default ReplacePdf;
