import React from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants/constants";
import axios from "../../utils/axios";
import { CiBellOn } from "react-icons/ci";
import {
  MdOutlineDelete,
  MdOutlineDriveFileMoveRtl,
  MdOutlineHistory,
  MdOutlinePictureAsPdf,
  MdOutlineSimCardDownload,
  MdOutlinePeopleAlt,
} from "react-icons/md";
import { PiCertificate, PiFilePdfLight } from "react-icons/pi";
import { TiCancel } from "react-icons/ti";
import { toast } from "react-toastify";
import { isFeatureEnabled } from "../../utils/paidFeat";
import jsPDF from "jspdf";
import { BsSend } from "react-icons/bs";
import { LuFolderPlus, LuListTree } from "react-icons/lu";
import { saveAs } from "file-saver";
import { IoLinkOutline } from "react-icons/io5";
import MixPanel from "../../utils/services/mixpanel";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import { FiShare2 } from "react-icons/fi";
import { HiMiniEye } from "react-icons/hi2";
import { LiaGoogleDrive } from "react-icons/lia";
import { SiMicrosoftonedrive } from "react-icons/si";
import { DropdownMenuItem } from "../Common/SelectDropDown/BoloDropDown";
import "./action-dropdown.css";
import { SlSocialDropbox } from "react-icons/sl";

const DropdownActionsSignature = ({
  owner,
  document,
  isIntegrationPermissionAvailable,
  dropdownState,
  updateDropdownState,
  type = "DEFAULT",
  folderId = null,
}: any) => {
  const actions = [
    {
      show: true,
      onClick: () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_edit",
            paid: false,
          });
          new MixPanel().increment("pdf_edit");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_edit",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_edit");
        }
        navigate(
          `/create/pdf-signature?documentId=${document.documentId}&isDrafted=true`
        );
      },
      disabled:
        document.status === "SIGNED" ||
        document.status === "PAID" ||
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly"),
      icon:
        document?.status !== "DRAFT" ? (
          <HiMiniEye className="text-xl " />
        ) : (
          <PiFilePdfLight className="text-xl" />
        ),
      label:
        document?.status !== "DRAFT" ? (
          <>
            Preview
            <span className="ml-1 bg-[#FAB505] px-2 py-[1px] rounded text-white text-[10px]">
              Beta
            </span>
          </>
        ) : (
          "Edit Now"
        ),
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async (e: any) => {
        e.stopPropagation();
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_download_with_history",
            paid: false,
          });
          new MixPanel().increment("pdf_download_with_history");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_download_with_history",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_download_with_history");
        }
        if (dropdownState.loading) {
          return;
        }
        try {
          const response = await axios.post(
            `/common/pdf-response-with-history`,
            {
              finishedPdfUrl: document.finishedPdfUrl,
              documentId: document.documentId,
              templateRespondentId: "",
              timeZone: owner?.accountInfo?.timeZone,
            },
            {
              responseType: "blob",
            }
          );

          if (response?.data?.error) {
            toast.error(response?.data?.error);
            console.log(
              "error in getting certificate: ",
              response?.data?.error
            );
            return;
          } else {
            saveAs(response.data, `${document?.documentName}_with_history.pdf`);
          }
        } catch (error) {
          console.log("Error generating certificate: ", error);
          toast.error("Error generating certificate");
        }
      },
      disabled: !document.finishedPdfUrl,
      icon: <MdOutlineSimCardDownload className="text-xl" />,
      label: "Download with History",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_show_history",
            paid: false,
          });
          new MixPanel().increment("pdf_show_history");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_show_history",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_show_history");
        }

        handleGetHistory(document);
      },
      disabled: false,
      icon: <MdOutlineHistory className="text-xl" />,
      label: "History",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_get_certificate",
            paid: false,
          });
          new MixPanel().increment("pdf_get_certificate");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_get_certificate",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_get_certificate");
        }

        try {
          const { data: certificateHTML } = await axios.post(
            `${BASE_URL}/respondents/get-signature-certificate`,
            {
              documentId: document.documentId,
            }
          );

          // Check if the response contains HTML
          if (certificateHTML) {
            const doc = new jsPDF();

            const addImageToAllPages = (
              doc: any,
              imageUrl: any,
              callback: any
            ) => {
              const pageCount = doc.internal.getNumberOfPages();

              // Load the image from the URL
              const imgData = imageUrl; // Replace with image data or URL
              const imgWidth = 150; // Adjust image width as needed
              const imgHeight = 150; // Adjust image height as needed

              for (let i = 1; i <= pageCount; i++) {
                doc.setPage(i);

                // Get page width and height
                const pageWidth = doc.internal.pageSize.width;
                const pageHeight = doc.internal.pageSize.height;

                // Calculate image coordinates to center it on the page
                const imgX = (pageWidth - imgWidth) / 2;
                const imgY = (pageHeight - imgHeight) / 2;

                doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
              }

              // Execute the callback function
              if (typeof callback === "function") {
                callback(doc);
              }
            };

            // Add the HTML content to the PDF
            doc.html(certificateHTML, {
              callback: function (finalDoc) {
                // Call the addImageToAllPages function to add the same image to all pages of the PDF
                addImageToAllPages(
                  finalDoc,
                  "https://i.imgur.com/i4EiHiU.png",
                  function (docWithImage: any) {
                    // Save the PDF with the image on all pages
                    docWithImage.save(
                      `${document.documentName}_certificate.pdf`
                    );
                  }
                );
              },
              x: 0,
              y: 0,
              width: 190,
              windowWidth: 950,
              margin: [10, 10, 10, 10],
            });
          } else {
            return toast.error("Failed to get certificate");
          }
        } catch (error) {
          console.log(error);
          return toast.error("Failed to get certificate");
        }
      },
      disabled: document.isAllSigned !== true,
      icon: <PiCertificate className="text-xl" />,
      label: "Get Certificate",
      hiddenOnDesktop: false,
    },
    {
      show: !dropdownState.isSharedDashboard,
      onClick: async () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_send_a_copy",
            paid: true,
          });
          new MixPanel().increment("pdf_send_a_copy");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_send_a_copy",
            paid: true,
          });
          new MixPanel().increment("folder_pdf_send_a_copy");
        }
        const isSendACopyEnabled = await isFeatureEnabled("SEND_A_COPY");
        if (!isSendACopyEnabled) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("upgradeModalType", "SEND_A_COPY");
          return;
        }

        if (document.isAllSigned !== true) {
          return toast.error("Document is not signed yet");
        }
        if (!document.finishedPdfUrl) {
          return toast.error("Something is wrong, can not send this document");
        }
        updateDropdownState("isSendACopyModalOpen", true);
        updateDropdownState("documentForSendACopy", {
          documentName: document.documentName || "Untitled",
          documentUrl: document.finishedPdfUrl,
          documentType: "SIGNED_PDF",
          documentId: document?.documentId,
          settings: document?.settings,
        });
      },
      disabled: document.isAllSigned !== true && !document.finishedPdfUrl,
      icon: <BsSend className="text-xl" />,
      label: "Send a Copy",
      hiddenOnDesktop: false,
    },
    {
      show: document?.status === "SENT",
      onClick: async () => {
        new MixPanel().track("PDF", {
          pdf_action: "pdf_send_reminder",
          paid: true,
        });
        new MixPanel().increment("pdf_send_reminder");
        if (!featuresEnabled.REMINDERS_ACCESS) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("upgradeModalType", "REMINDERS_ACCESS");
          return;
        }
        try {
          const response = await axios.post(`/send-reminders`, {
            documentId: document?.documentId,
            respondentDocId: document?.documentId,
            docType: "PDF",
          });
          if (response.status === 200) {
            return toast.success("Reminder send successfully");
          }
          if (response?.data?.smsCreditExhausted) {
            return toast.error(response.data.error);
          }
        } catch (error) {
          console.log(error);
          return toast.error("Failed to send reminders");
        }
      },
      disabled: false,
      icon: <CiBellOn className="text-xl" />,
      label: "Send Reminder",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_show_signing_order",
            paid: false,
          });
          new MixPanel().increment("pdf_show_signing_order");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_show_signing_order",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_show_signing_order");
        }
        updateDropdownState("isSigningOrderDiagram", true);
        updateDropdownState(
          "signingOrderRecipientList",
          document?.respondentsOfDoc || []
        );
        updateDropdownState("currOrderDocument", document);
      },
      disabled: !document?.isSigningOrderData,
      icon: <LuListTree className="text-xl" />,
      label: "Signing Order",
      hiddenOnDesktop: false,
    },
    {
      show: document?.status == "SENT VIA LINK",
      onClick: () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_show_generated_links",
            paid: false,
          });
          new MixPanel().increment("pdf_show_generated_links");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_show_generated_links",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_show_generated_links");
        }

        updateDropdownState("showShareViaLink", true);
        updateDropdownState("generatedLinksData", document);
      },
      disabled: document?.isSigningOrderData,
      icon: <IoLinkOutline className="text-xl" />,
      label: "Share Via Link",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_replace_pdf",
            paid: false,
          });
          new MixPanel().increment("pdf_replace_pdf");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_replace_pdf",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_replace_pdf");
        }
        navigate(`/replace/pdf?documentId=${document?.documentId}&type=PDF`);
      },
      disabled:
        document.status !== "DRAFT" ||
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly"),
      icon: <MdOutlinePictureAsPdf className="text-xl" />,
      label: "Replace Pdf",
      hiddenOnDesktop: false,
    },
    {
      show: document?.status == "SENT",
      onClick: () => {
        updateDropdownState("generatedLinksData", document);
        updateDropdownState("showGenerateLinksModal", true);
      },
      disabled: document?.isSigningOrderData,
      icon: <IoLinkOutline className="text-xl" />,
      label: "Share via Link",
      hiddenOnDesktop: false,
    },
    {
      show: document?.status == "SENT",
      onClick: () => {
        new MixPanel().track("PDF", {
          pdf_action: "pdf_show_signing_in_person",
          paid: false,
        });
        new MixPanel().increment("pdf_show_signing_in_person");
        updateDropdownState("showSigningInPerson", true);
        updateDropdownState("generatedLinksData", document);
      },
      disabled: false,
      icon: <MdOutlinePeopleAlt className="text-xl" />,
      label: "Sign In Person",
      hiddenOnDesktop: false,
    },
    {
      show: !dropdownState.isSharedDashboard && type === "DEFAULT",
      onClick: (e: any) => {
        e.stopPropagation();
        new MixPanel().track("PDF", {
          pdf_action: "pdf_add_to_folder",
          paid: false,
        });
        new MixPanel().increment("pdf_add_to_folder");
        updateDropdownState("folderOptions", {
          ...dropdownState.folderOptions,
          move: {
            item: { id: document?._id, type: "PDF", value: document },
            show: true,
            fromFolder: document?.folder ?? null,
          },
          isAdd: !document?.folder?.name,
        });
      },
      disabled:
        !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
        dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly",
      icon: document?.folder?.name ? (
        <MdOutlineDriveFileMoveRtl className="text-xl " />
      ) : (
        <LuFolderPlus className="text-xl" />
      ),
      label: document?.folder?.name ? "Move to Folder" : "Add to Folder",
      hiddenOnDesktop: false,
    },
    {
      show: dropdownState.workSpaceDetails?.currentWorkSpaceRole !== "readonly",
      onClick: async (e: any) => {
        e.stopPropagation();
        updateDropdownState("activeDoc", document);
        updateDropdownState("showShare", true);
        if (type === "FOLDER") {
          updateDropdownState("showShareDocType", "pdfs");
        }
      },
      disabled: false,
      icon: <FiShare2 className="text-xl" />,
      label: "Share",
      hiddenOnDesktop: false,
    },
    {
      show: !dropdownState.isSharedDashboard,
      onClick: async () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("pdf_add_to_drive");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("folder_pdf_add_to_drive");
        }
        if (dropdownState.isSharedDashboard) {
          toast.error(
            "Contact the owner of this form to integrate with Google Drive"
          );
          return;
        }

        const isDriveIntegrationsEnabled = await isFeatureEnabled(
          "GOOGLE_DRIVE_INTEGRATION"
        );
        if (!isDriveIntegrationsEnabled) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("upgradeModalType", "GOOGLE_DRIVE_INTEGRATION");
          return;
        }
        let isFreePlan =
          owner?.planhistory[owner?.planhistory.length - 1]?.planId ===
          "FREE_PLAN";

        if (isFreePlan) {
          updateDropdownState("showGoogleDriveModal", true);
          return;
        }

        let isDrivePermission = await isIntegrationPermissionAvailable();

        let { data: updatedDocument } = await axios.get(
          `/getOneDocument?documentId=${document.documentId}`
        );

        let isEnabled =
          updatedDocument?.document?.metafields?.googleDrive?.enabled;
        let parentFolderId =
          updatedDocument?.document?.metafields?.googleDrive?.parentFolderId;
        let parentFolderName =
          updatedDocument?.document?.metafields?.googleDrive?.parentFolderName;
        let parentFolderUrl =
          updatedDocument?.document?.metafields?.googleDrive?.parentFolderUrl;
        updateDropdownState("showGoogleDriveModal", true);
        updateDropdownState("driveModalDoc", {
          document: updatedDocument.document,
          isDrivePermission: isDrivePermission,
          isEnabled: isEnabled,
          parentFolderId: parentFolderId,
          parentFolderUrl,
        });
      },
      disabled: !dropdownState.workSpaceDetails?.isWorkSpaceOwner,
      icon: <LiaGoogleDrive className="text-xl" />,
      label: "Integrate With Drive",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("pdf_add_to_drive");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("folder_pdf_add_to_drive");
        }
        const isDriveIntegrationsEnabled = await isFeatureEnabled(
          "DROPBOX_INTEGRATION"
        );
        if (!isDriveIntegrationsEnabled) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("upgradeModalType", "DROPBOX_INTEGRATION");
          return;
        }
        let { data: updatedDocument } = await axios.get(
          `/getOneDocument?documentId=${document.documentId}`
        );
        let newdocument = updatedDocument?.document;
        let driveDocData = {
          documentId: newdocument?.documentId,
          enabled: newdocument?.metafields?.dropboxDrive?.enabled ?? false,
          parentFolderName:
            newdocument?.metafields?.dropboxDrive?.parentFolderName,
          parentFolderUrl:
            newdocument?.metafields?.dropboxDrive?.parentFolderUrl,
          parentFolderId: newdocument?.metafields?.dropboxDrive?.parentFolderId,
        };
        updateDropdownState("dropboxDoc", driveDocData);
        updateDropdownState("showDropboxDriveModal", true);
        return;
      },
      disabled: !dropdownState.workSpaceDetails?.isWorkSpaceOwner,
      icon: <SlSocialDropbox className="text-xl" />,
      label: "Integrate With Dropbox",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("pdf_add_to_drive");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("folder_pdf_add_to_drive");
        }

        const isDriveIntegrationsEnabled = await isFeatureEnabled(
          "ONEDRIVE_INTEGRATION"
        );
        if (!isDriveIntegrationsEnabled) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("showUpgradeModalType", "ONEDRIVE_INTEGRATION");
          return;
        }
        let { data: updatedDocument } = await axios.get(
          `/getOneDocument?documentId=${document.documentId}`
        );
        let newdocument = updatedDocument?.document;
        let driveDocData = {
          documentId: newdocument?.documentId,
          enabled: newdocument?.metafields?.msOneDrive?.enabled ?? false,
          parentFolderName:
            newdocument?.metafields?.msOneDrive?.parentFolderName,
          parentFolderUrl: newdocument?.metafields?.msOneDrive?.parentFolderUrl,
          parentFolderId: newdocument?.metafields?.msOneDrive?.parentFolderId,
          parentFolderDriveId:
            newdocument?.metafields?.msOneDrive?.parentFolderDriveId,
        };
        updateDropdownState("oneDriveDoc", driveDocData);
        updateDropdownState("showOneDriveModal", true);
        return;
      },
      disabled: !dropdownState.workSpaceDetails?.isWorkSpaceOwner,
      icon: <SiMicrosoftonedrive className="text-xl" />,
      label: "Integrate With OneDrive",
      hiddenOnDesktop: false,
    },
    {
      show:
        document?.status !== "DRAFT" &&
        document?.status !== "SIGNED" &&
        document?.status !== "VOID" &&
        !dropdownState.isSharedDashboard,
      onClick: (e: any) => {
        e.stopPropagation();
        if (type === "DEFAULT") {
          new MixPanel().track("PDF", {
            pdf_action: "pdf_void_document",
            paid: false,
          });
          new MixPanel().increment("pdf_void_document");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_void",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_void");
        }
        updateDropdownState("deletingResponse", document);
        if (type === "DEFAULT") {
          updateDropdownState("show", {
            ...dropdownState.show,
            voidDocumentModal: true,
          });
        } else {
          updateDropdownState("showDeletingResponse", {
            ...dropdownState.showDeletingResponse,
            voidDocumentModal: true,
          });
        }
      },
      disabled:
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          (dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
            "readonly" ||
            dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
              "editor")) ||
        dropdownState.loading,
      icon: <TiCancel className="text-xl" />,
      label: "Void Document",
      hiddenOnDesktop: false,
    },
    {
      show: !dropdownState.isSharedDashboard,
      onClick: (e: any) => {
        e.stopPropagation();
        new MixPanel().track("PDF", {
          pdf_action: "pdf_delete",
          paid: false,
        });
        new MixPanel().increment("pdf_delete");
        updateDropdownState("deletingResponse", document);
        if (type === "DEFAULT") {
          updateDropdownState("show", {
            ...dropdownState.show,
            deleteOneResponseModal: true,
          });
        } else {
          updateDropdownState("showDeletingResponse", {
            ...dropdownState.showDeletingResponse,
            deleteOneResponseModal: true,
          });
        }
      },
      disabled:
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          (dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
            "readonly" ||
            dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
              "editor")) ||
        dropdownState.loading,
      icon: <MdOutlineDelete className="text-xl" />,
      label: "Delete",
      hiddenOnDesktop: false,
      dangerHover: true,
    },
  ];

  let navigate = useNavigate();
  const featuresEnabled = useIsFeatureEnabled();

  const handleGetHistory = async (finishedDocument: any) => {
    if (!finishedDocument) return toast.error("No document to get history");
    try {
      const { data } = await axios.post(`${BASE_URL}/getHistory`, {
        documentId: finishedDocument.documentId,
      });

      if (!data.error) {
        updateDropdownState("isModalOpen", true);
        updateDropdownState("documentHistory", data.history);
        updateDropdownState("documentForHistory", finishedDocument);
        return toast.success("History fetched successfully");
      } else {
        updateDropdownState("loading", false);
        return toast.error(data.error);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Failed to get history");
    }
    // Open the modal and set the description
  };

  return (
    <>
      {actions.map((action, index) => {
        if (!action.show) return null;
        return (
          <DropdownMenuItem
            key={index}
            onClick={action.onClick}
            disabled={action.disabled}
            className={`flex flex-row items-center text-center text-xs capitalize text-gray-700 px-3 py-2 gap-2 w-full  ${
              action?.dangerHover
                ? "hover:!bg-red-100 text-red-400 hover:!text-red-400"
                : ""
            } ${action?.hiddenOnDesktop ? "flex md:hidden" : ""}`}
          >
            {action.icon}
            {action.label}
          </DropdownMenuItem>
        );
      })}
    </>
  );
};

export default DropdownActionsSignature;
