import { PDFDocument, PDFField, PDFTextField } from "pdf-lib";
import { SIGNATURE_RATIO } from "../pdfme/ui/src/constants";
import { getWhiteLabelInfoLocal } from ".";
import { COLORS } from "../constants/common";
import axios from "axios";
import { isFeatureEnabled } from "./paidFeat";
const colors = [
  "#ADD8E6",
  "#87CEEB",
  "#FFC0CB",
  "#FFB6C1",
  "#00FFFF",
  "#98FB98",
  "#98FF98",
  "#90EE90",
  "#DDA0DD",
  "#F4A460",
  "#00FF7F",
  "#7FFF00",
  "#00FF00",
  "#FA8072",
  "#EE82EE",
  "#FF7F50",
  "#FF69B4",
  "#DA70D6",
  "#FF6347",
  "#4682B4",
  "#1E90FF",
  "#2E8B57",
  "#FF00FF",
  "#008080",
  "#228B22",
  "#FF0000",
  "#4169E1",
  "#008000",
  "#6A5ACD",
  "#A52A2A",
  "#0000FF",
  "#800080",
  "#800000",
  "#4B0082",
  "#000080",
  // "#FFFF00",
  // "#000000",
  // "#FFFFFF",
  // "#C0C0C0",
  // "#808080",
  // "#E6E6FA",
  // "#808000",
  // "#F0E68C",
  // "#FFD700",
  // "#FFF700",
  // "#FFDAB9",
  // "#FFA500",
  // "#D3D3D3",
];

const getRandomColorV1 = () => {
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  return randomColor;
};

function convertTimestampToTimeZone(timestamp: any, timeZone: any): any {
  const date = timestamp ? new Date(timestamp) : new Date();
  const options = {
    timeZone: timeZone || "UTC",
    hour12: true,
  };
  if (timeZone) {
    return date.toLocaleString("en-US", options);
  } else {
    return date.toUTCString(); //
  }
}

function getInitials(username: string): string {
  if (!username) {
    return "AA";
  }
  const words = username?.trim()?.split(" ");

  let inti = "";
  if (words?.[0]?.[0]?.toUpperCase()) {
    inti += words?.[0]?.[0]?.toUpperCase() || "";
  }
  if (words?.[1]?.[0]?.toUpperCase()) {
    inti += words?.[1]?.[0]?.toUpperCase() || "";
  } else {
    inti += words?.[0]?.[0]?.toUpperCase() || "";
  }

  if (!inti) {
    return "AA";
  }

  return inti;
}

function getTintFromHex(originalColor: any, tintPercentage: any) {
  // Remove the '#' from the hex color
  originalColor = originalColor?.replace(/^#/, "");

  // Parse the hex color into RGB values
  const red = parseInt(originalColor?.substring(0, 2), 16);
  const green = parseInt(originalColor?.substring(2, 4), 16);
  const blue = parseInt(originalColor?.substring(4, 6), 16);

  // Calculate the tinted color
  const tintedRed = Math.round(red + (255 - red) * (tintPercentage / 100));
  const tintedGreen = Math.round(
    green + (255 - green) * (tintPercentage / 100)
  );
  const tintedBlue = Math.round(blue + (255 - blue) * (tintPercentage / 100));

  // Convert the tinted RGB values back to hex
  const tintedColor = `#${tintedRed.toString(16).padStart(2, "0")}${tintedGreen
    .toString(16)
    .padStart(2, "0")}${tintedBlue.toString(16).padStart(2, "0")}`;

  return tintedColor;
}

function calculateContrast(color1: any, color2: any): string {
  // Helper function to convert hex to RGB
  function hexToRgb(hex: any) {
    // Remove the '#' from the hex color
    hex = hex?.replace(/^#/, "");

    // Parse the hex color into RGB values
    const bigint = parseInt(hex, 16);
    const red = (bigint >> 16) & 255;
    const green = (bigint >> 8) & 255;
    const blue = bigint & 255;

    return { red, green, blue };
  }

  // Helper function to calculate relative luminance
  function calculateLuminance(color: any) {
    const gammaCorrect = (value: any) => {
      value /= 255;
      return value <= 0.03928
        ? value / 12.92
        : Math.pow((value + 0.055) / 1.055, 2.4);
    };

    const { red, green, blue } = color;
    const r = gammaCorrect(red);
    const g = gammaCorrect(green);
    const b = gammaCorrect(blue);

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  // Calculate luminance for each color
  const luminance1 = calculateLuminance(hexToRgb(color1));
  const luminance2 = calculateLuminance(hexToRgb(color2));

  // Calculate contrast ratio
  const contrastRatio =
    (Math.max(luminance1, luminance2) + 0.05) /
    (Math.min(luminance1, luminance2) + 0.05);

  return contrastRatio.toFixed(2);
}

function removeAnnots(pdf: PDFDocument): PDFDocument {
  try {
    const pages = pdf.getPages();
    pages.forEach((page) => {
      const annots = page.node.Annots();
      if (annots) {
        const size = annots?.size();
        if (size) {
          for (let i = 0; i < size; i++) {
            //@ts-ignore
            annots.context.delete(annots.get(i));
          }
        }
      }
    });
    return pdf;
  } catch (error) {
    return pdf;
  }
}

const convertToNormalPDF = async (fillablePdfFile: File) => {
  try {
    // Read the fillable PDF file
    const fillablePdfBytes = await readFileAsync(fillablePdfFile);

    // Load the fillable PDF into the new document
    let fillablePdfDoc = await PDFDocument.load(fillablePdfBytes);

    const form = fillablePdfDoc.getForm();
    form.flatten();

    // removing annots
    fillablePdfDoc = removeAnnots(fillablePdfDoc);
    // Save the new PDF as a Blob
    const normalPdfBytes = await fillablePdfDoc.save();
    const normalPdfBlob = new Blob([normalPdfBytes], {
      type: "application/pdf",
    });

    // Download the Blob as a file
    // saveAs(normalPdfBlob, 'normal.pdf');
    return normalPdfBlob;
  } catch (error) {
    console.error("Error converting PDF:", error);
  }
};

const readFileAsync = (file: File): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.result instanceof ArrayBuffer) {
        resolve(new Uint8Array(event.target.result));
      } else {
        reject(new Error("Invalid file content"));
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

function blobToFile(blob: Blob, fileName: string) {
  const fileType = blob.type;
  const file = new File([blob], fileName, { type: fileType });
  return file;
}
async function convertToNormalPDFFile(originalFile: File): Promise<File> {
  const orignalName = originalFile?.name;
  let newFile = originalFile;
  let flattenedFile = await convertToNormalPDF(originalFile);
  if (flattenedFile) {
    newFile = blobToFile(flattenedFile, orignalName);
  }

  return newFile;
}

function formatTimeDifference(givenTimestamp: string): string {
  const givenDate = new Date(givenTimestamp);
  const currentDate = new Date();

  const timeDifference = currentDate.getTime() - givenDate.getTime();

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (years > 0) {
    return years === 1 ? `${years} year ago` : `${years} years ago`;
  } else if (months > 0) {
    const remainingMonths = months % 12;
    return months === 1
      ? `${months} month ${
          remainingMonths > 0 ? `${remainingMonths} months` : ""
        } ago`
      : `${months} months ago`;
  } else if (days > 0) {
    const remainingDays = days % 30;
    return days === 1
      ? `${days} day ${remainingDays > 0 ? `${remainingDays} days` : ""} ago`
      : `${days} days ago`;
  } else if (hours > 0) {
    const remainingHours = hours % 24;
    return hours === 1
      ? `${hours} hour ${
          remainingHours > 0 ? `${remainingHours} minutes` : ""
        } ago`
      : `${hours} hours ago`;
  } else if (minutes > 0) {
    const remainingMinutes = minutes % 60;
    return minutes === 1
      ? `${minutes} minute ${
          remainingMinutes > 0 ? `${remainingMinutes} seconds` : ""
        } ago`
      : `${minutes} minutes ago`;
  } else {
    return "Less than a minute ago";
  }
}

function removeSquareBrackets(inputString: string) {
  if (!inputString) {
    return "";
  }

  if (inputString?.startsWith("[") && inputString?.endsWith("]")) {
    return inputString?.slice(1, -1);
  } else {
    // If the string doesn't have square brackets at the start and end, return it as is
    return inputString;
  }
}

function addSquareBrackets(inputString: string) {
  return `[${inputString}]`;
}
// This function add the timestamp below signature
// Add a left side curve
// Add a top text BoloSign By on top of signature
function addTimeStampToSignature(
  drawing: string,
  schema: any
): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const whiteLabelInfo = getWhiteLabelInfoLocal();
      const baseWidth = SIGNATURE_RATIO.baseWidth;
      const baseHeight = SIGNATURE_RATIO.baseHeight;
      const timestamp = new Date().toLocaleString();
      const boloText = `${
        whiteLabelInfo?.settings?.organization?.signedBy || "BoloSign By"
      } : `;
      const img = new Image();
      img.src = drawing;

      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height + SIGNATURE_RATIO.timeStampHeight; // 80 added due to top and bottom text of signature
        canvas.width = canvas.width + SIGNATURE_RATIO.curveWidth; // 120 added due to left side curve to signature

        ctx.drawImage(img, 60, 40);
        let height = schema.height;
        let width = schema.width;

        let baseFontSize = 16;
        const widthRatio = width / baseWidth;
        const heightRatio = height / baseHeight;
        // It take the case for low height signature
        if (height < 12) {
          baseFontSize = 10;
        }
        // It take the case for the low width signature
        if (width < 40) {
          baseFontSize = 7;
        }

        const minRatio = Math.min(widthRatio, heightRatio);
        let adjustedFontSize = Math.floor(baseFontSize * (1 / minRatio));

        ctx.font = `${adjustedFontSize}px Poppins`;
        ctx.fillStyle = "black";
        const textX = 120; // Position of text from the left side
        const textY = canvas.height - 10;
        ctx.fillText(timestamp, textX, textY);
        ctx.fillText(boloText, textX, adjustedFontSize);

        // Add C-shaped element
        ctx.beginPath();
        ctx.moveTo(110, 10); // x = 110 and y = 10
        ctx.arcTo(5, 10, 5, 30, 60); // Here 60 is the border radius
        ctx.arcTo(5, canvas.height - 10, 50, canvas.height - 10, 60);
        ctx.lineTo(110, canvas.height - 10);

        ctx.strokeStyle = "#000"; // Curve Color
        ctx.lineWidth = 8; // Line Width
        ctx.stroke();

        const updatedDrawing = canvas.toDataURL();
        resolve(updatedDrawing);
      };
    } catch (error) {
      console.log("Error in adding timestamp to signature");
      reject(drawing);
    }
  });
}

function arrayEquals(a: any[], b: any[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

function parseHSL(hslString: string): number[] | null {
  const hslRegex = /^(\d+(\.\d+)?)(\s+)(\d+(\.\d+)?)(%)(\s+)(\d+(\.\d+)?)(%)$/;
  const match = hslString.match(hslRegex);

  if (!match) {
    console.error("Invalid HSL string format.");
    return null;
  }

  const h = parseFloat(match[1]);
  const s = parseFloat(match[4]);
  const l = parseFloat(match[8]);

  return [h, s, l];
}

function hslToHex(h: number, s: number, l: number): string {
  // Convert degrees to [0, 1]
  const hue = h / 360;
  const saturation = s / 100;
  const lightness = l / 100;

  let r, g, b;

  if (s === 0) {
    // If saturation is 0, the color is a shade of gray
    r = g = b = lightness;
  } else {
    const hueToRgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q =
      lightness < 0.5
        ? lightness * (1 + saturation)
        : lightness + saturation - lightness * saturation;
    const p = 2 * lightness - q;
    r = hueToRgb(p, q, hue + 1 / 3);
    g = hueToRgb(p, q, hue);
    b = hueToRgb(p, q, hue - 1 / 3);
  }

  const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

function dbHsl2Hex(hsl: string | undefined): string | null {
  if (!hsl) {
    return null;
  }

  let hslNums = parseHSL(hsl);

  if (!hslNums) {
    return null;
  }

  let hexNum = hslToHex(hslNums[0], hslNums[1], hslNums[2]);

  return hexNum;
}

interface RGB {
  r: number;
  g: number;
  b: number;
}

export interface ShadesAndTints {
  shades: string[];
  tints: string[];
}

function generateShadesAndTints(hexColor: string): ShadesAndTints | null {
  // Function to convert hexadecimal to RGB
  function hexToRgb(hex: string): RGB | null {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  // Function to convert RGB to hexadecimal
  function rgbToHex(r: number, g: number, b: number): string {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  // Function to generate shades
  function generateShade(color: number, factor: number): number {
    const newColor = Math.round(color * (1 - factor));
    return newColor < 0 ? 0 : newColor;
  }

  // Function to generate tints
  function generateTint(color: number, factor: number): number {
    const newColor = Math.round(color + (255 - color) * factor);
    return newColor > 255 ? 255 : newColor;
  }

  // Convert hex to RGB
  const rgb = hexToRgb(hexColor);
  if (!rgb) return null;

  // Generate shades
  const shades: string[] = [];
  for (let i = 0; i <= 10; i++) {
    const shadeR = generateShade(rgb.r, i / 10);
    const shadeG = generateShade(rgb.g, i / 10);
    const shadeB = generateShade(rgb.b, i / 10);
    shades.push(rgbToHex(shadeR, shadeG, shadeB));
  }

  // Generate tints
  const tints: string[] = [];
  for (let i = 0; i <= 10; i++) {
    const tintR = generateTint(rgb.r, i / 10);
    const tintG = generateTint(rgb.g, i / 10);
    const tintB = generateTint(rgb.b, i / 10);
    tints.push(rgbToHex(tintR, tintG, tintB));
  }

  return { shades, tints };
}

const hexToHSL = (hex: string): string => {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16);
    g = parseInt(hex.slice(3, 5), 16);
    b = parseInt(hex.slice(5, 7), 16);
  }

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  return `${Math.round(h * 360)} ${Math.round(s * 100)}% ${Math.round(
    l * 100
  )}%`;
};

function reorderArray<T>(inputArray: T[], order: number[]): T[] | undefined {
  try {
    const newArray: T[] = [];

    // Ensure that the provided order array is valid
    const isValidOrder = order.every(
      (index) => index >= 0 && index < inputArray.length
    );

    if (!isValidOrder) {
      console.log({ isValidOrder });
      return;
    }

    for (const index of order) {
      newArray.push(inputArray[index]);
    }

    return newArray;
  } catch (error) {
    console.log(error);
    return;
  }
}

const googleTranslateElementInit = () => {
  new (window as any).google.translate.TranslateElement(
    {
      pageLanguage: "en",
      includedLanguages:
        "en,fr,es,de,id,pt,nl,zh-CN,th,ja,ar,iw,cs,da,fil,it,ru,tr,vi,ko,bg,ru,uk",
    },
    "google_translate_element"
  );

  let skiptranslate = document.querySelector(".skiptranslate");
  if (skiptranslate?.lastChild) {
    skiptranslate.removeChild(skiptranslate.lastChild);
  }
  // removes google translate script error
  if (skiptranslate?.lastChild) {
    skiptranslate.removeChild(skiptranslate.lastChild);
  }
};

function formatTime(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}

const checkAndSetEnabledKey = (obj: any) => {
  let enabledKey = false;
  // Iterate over each key in the object
  for (let key in obj) {
    // Check if the value of the key is false
    if (obj[key] === false) {
      enabledKey = true;
      break; // No need to continue checking once we find a false value
    }
  }
  return enabledKey;
};

function filesToDataURIs(files: File[]): Promise<string[]> {
  return Promise.all(files.map(fileToDataURI));
}

function fileToDataURI(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const dataURI = reader.result as string;
      resolve(dataURI);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

function downloadFile(file: File): void {
  const fileURL = URL.createObjectURL(file);

  // Create an anchor element
  const anchor = document.createElement("a");
  anchor.href = fileURL;
  anchor.download = file.name;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(fileURL);
}

// TODO : Ayush try to use this function as a generalized theming function
function genThemeFromHex(hex: string): {
  hex: {
    primary: string;
    secondary: string;
    tertiary: string;
    textPrimary: string;
    textSecondary: string;
    textTertiary: string;
  };
  hsl: {
    primary: string;
    secondary: string;
    tertiary: string;
    textPrimary: string;
    textSecondary: string;
    textTertiary: string;
  };
} {
  const tintsAndShades = generateShadesAndTints(hex);
  return {
    hex: {
      primary: hex,
      secondary: tintsAndShades?.tints[8] || hex,
      tertiary: tintsAndShades?.tints[4] || hex,
      textPrimary:
        Number(calculateContrast(hex, "#FFFFFF")) > 3 ? "#FFFFFF" : "#000000",
      textSecondary: hex,
      textTertiary: tintsAndShades?.tints[4] || hex,
    },
    hsl: {
      primary: hexToHSL(hex),
      secondary: hexToHSL(tintsAndShades?.tints[8] || hex),
      tertiary: hexToHSL(tintsAndShades?.tints[4] || hex),
      textPrimary: hexToHSL(
        Number(calculateContrast(hex, "#FFFFFF")) > 3 ? "#FFFFFF" : "#000000"
      ),
      textSecondary: hexToHSL(hex),
      textTertiary: hexToHSL(tintsAndShades?.tints[4] || hex),
    },
  };
}

function setCSSTheme({
  primary,
  secondary,
  tertiary,
  textPrimary,
  textSecondary,
  textTertiary,
}: {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  textPrimary?: string;
  textSecondary?: string;
  textTertiary?: string;
}) {
  // console.log("Setting theme", {
  //   primary,
  //   secondary,
  //   tertiary,
  //   textPrimary,
  //   textSecondary,
  //   textTertiary,
  // });
  document.documentElement.style.setProperty(
    "--primary",
    primary || hexToHSL(COLORS.primary) || "260 97% 70%"
  );
  document.documentElement.style.setProperty(
    "--secondary",
    secondary || hexToHSL(COLORS.secondary) || "269 100% 95%"
  );
  document.documentElement.style.setProperty(
    "--tertiary",
    tertiary || hexToHSL(COLORS.tertiary) || "259 97% 76%"
  );
  document.documentElement.style.setProperty(
    "--primary-text",
    textPrimary || hexToHSL(COLORS.textPrimary) || "0 0% 100%"
  );
  document.documentElement.style.setProperty(
    "--secondary-text",
    textSecondary || hexToHSL(COLORS.textSecondary) || "260 97% 70%"
  );
  document.documentElement.style.setProperty(
    "--tertiary-text",
    textTertiary || hexToHSL(COLORS.textTertiary) || "259 97% 76%"
  );
}

export const formatDate = (dateInput: Date | string): string => {
  console.log("dateInput", dateInput);

  // Convert string to Date object if necessary
  const date: Date =
    typeof dateInput === "string" ? new Date(dateInput) : dateInput;

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
  };

  // If invalid date is passed, return empty string
  if (isNaN(date.getTime())) {
    console.log("Invalid date");
    return "";
  }

  const dayMonth = new Intl.DateTimeFormat("en-GB", options).format(date);
  const year = date.getFullYear();
  console.log(`${dayMonth}, ${year}`);
  return `${dayMonth}, ${year}`;
};
const decryptPdf = async (
  password: string,
  encryptedFile: File
): Promise<File> => {
  const formData = new FormData();
  const encyptedFileBlob = new Blob([encryptedFile], {
    type: "application/pdf",
  });
  const encyptedFileWithDefaultName = new File(
    [encyptedFileBlob],
    "Encypted_file",
    {
      type: "application/pdf",
      lastModified: new Date().getTime(),
    }
  );

  formData.append("file", encyptedFileWithDefaultName);
  if (password) {
    formData.append("password", password);
  }
  try {
    const response = await axios.post(
      "https://main-website-backend.boloforms.com/api/post/decryptPdf",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      }
    );

    // Create a new File object from the response blob
    const decryptedFile = new File([response.data], encryptedFile.name, {
      type: "application/pdf",
      lastModified: new Date().getTime(),
    });
    return decryptedFile;
  } catch (error) {
    console.log("Error decrypting pdf", error);
    throw new Error("Error decrypting pdf");
  }
};
export {
  formatTimeDifference,
  getRandomColorV1,
  convertTimestampToTimeZone,
  getInitials,
  getTintFromHex,
  calculateContrast,
  convertToNormalPDF,
  readFileAsync,
  blobToFile,
  addSquareBrackets,
  removeSquareBrackets,
  addTimeStampToSignature,
  arrayEquals,
  dbHsl2Hex,
  generateShadesAndTints,
  hexToHSL,
  reorderArray,
  googleTranslateElementInit,
  formatTime,
  checkAndSetEnabledKey,
  filesToDataURIs,
  downloadFile,
  convertToNormalPDFFile,
  genThemeFromHex,
  setCSSTheme,
  decryptPdf,
};
