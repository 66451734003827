import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { TagifySettings } from "@yaireo/tagify";
import { MdArrowDropDown } from "react-icons/md";
import { DesignerState } from "..";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import axios from "../../../../../../utils/axios";
import { toast, ToastContainer } from "react-toastify";
import { getInitials, getTintFromHex } from "../../../../../../utils/uiUtils";
import { isFeatureEnabled } from "../../../../../../utils/paidFeat";
import { BiRename } from "react-icons/bi";
import { COLORS } from "../../../../../../constants/common";
import { ALL_FEATURES } from "../../../../../../types/tiers";

interface CustomPdfNameProps {
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  setShowUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpgradeModalType: React.Dispatch<React.SetStateAction<ALL_FEATURES>>;
}
const CustomPdfName = ({
  designerState,
  setDesignerState,
  setShowUpgradeModal,
  setUpgradeModalType,
}: CustomPdfNameProps) => {
  const [mixedTagsValue, setMixedTagsValue] = useState<string>(
    designerState?.fetchedData?.document?.generatedFileName || ""
  );
  const [generatedPDFNameQuestions, setGeneratedPDFNameQuestions] = useState<
    { id: string; title: string; role: any; pageNo: number }[]
  >([{ id: "", title: "", role: {}, pageNo: 0 }]);
  const [mixedTagsSettings, setMixedTagsSettings] = useState<TagifySettings>({
    pattern: /@/, // <- must define "patten" in mixed mode
    dropdown: {
      enabled: 1,
      position: "text",
    },
    whitelist: [],
    duplicates: true,
  });
  const onChange = useCallback((e: any) => {
    setMixedTagsValue(e?.detail?.value?.replace(/\n$/, ""));
  }, []);
  const handleGeneratedPDFName = async () => {
    try {
      const isResponsePdfName = await isFeatureEnabled("CUSTOM_PDF_NAME");

      if (!isResponsePdfName) {
        setShowUpgradeModal(true);
        setUpgradeModalType("CUSTOM_PDF_NAME");
        return;
      }
      let { data } = await axios.post(`/document/update-generated-pdf-name`, {
        documentId: designerState?.fetchedData?.document?.documentId,
        generatedFileName: mixedTagsValue,
      });

      if (!data.error) {
        toast.success("Generate PDF FileName updated!");
        setDesignerState((prev) => ({
          ...prev,
          fetchedData: { ...prev.fetchedData, document: data?.document },
        }));
      } else {
        toast.error(data?.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGeneratePDFFileNameLocal = (queKey: any) => {
    const questionToAdd = generatedPDFNameQuestions?.find(
      (question) => question?.id && question?.id == queKey?.key
    );
    const addStringify = [
      [
        {
          value: questionToAdd?.title,
          prefix: "@",
          title: questionToAdd?.id,
        },
      ],
    ];
    setMixedTagsValue((mixedTagsValue || "") + JSON.stringify(addStringify));
  };
  function CustomDropDown({
    opts,
    setSelectedOpt,
    selectedOpt,
    defaultText,
  }: any) {
    let activeQuestion = opts.find(
      (option: any) => option?.key === selectedOpt?.key
    );
    const questionTypeDropdownRef = useRef<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [displacementAmount, setDisplacementAmount] = useState(0);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: any, index: number) => {
      let displacement = -(index - 1) * 48;
      setDisplacementAmount(displacement);
      setSelectedOpt(option);
      setIsOpen((prev) => !prev);
    };

    const handleOutsideClick = (e: any) => {
      if (questionTypeDropdownRef.current !== null) {
        if (questionTypeDropdownRef.current?.contains(e.target)) return;
      }
      setIsOpen(false);
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div className="relative w-full border border-gray-300 rounded-sm hover:shadow">
        <div className="relative">
          <div
            onClick={toggleDropdown}
            className="h-[36px] px-3 rounded flex items-center cursor-pointer"
          >
            <div className="ml-1 mr-2">
              <span className="text-gray-800 text-sm">
                {defaultText || activeQuestion?.value}
              </span>
            </div>
            <div className="ml-auto">
              <MdArrowDropDown className="text-2xl text-gray-500" />
            </div>
          </div>
          {isOpen && (
            <div
              ref={questionTypeDropdownRef}
              style={{
                marginTop: "-48px",
                position: "absolute",
                boxShadow:
                  "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
                // top: `${displacementAmount}px`,
                zIndex: 5,
              }}
              className="py-2 w-full bg-white border border-gray-300 rounded z-150 max-h-[250px] overflow-y-scroll"
              id="mini-pdf-custom-scroll"
            >
              {opts.map(
                (
                  option: {
                    key: string;
                    value: string;
                    role: any;
                    pageNo: number;
                  },
                  index: number
                ) => {
                  let role = option?.role;
                  const dataMap = {
                    "PDF-TEMPLATE": {
                      color: role?.colour,
                      title: role?.title,
                      email: "",
                    },
                    PDF: {
                      color: role?.colour,
                      title: role?.title,
                      email: role?.email,
                    },
                    // form here is useless just kept to avoid ts errors
                    FORM: {
                      color: "",
                      title: "",
                      email: "",
                    },
                  };
                  const activeData = dataMap[designerState.instanceFrom];
                  return (
                    <div key={index}>
                      <div
                        onClick={() => handleOptionClick(option, index)}
                        className={`${
                          activeQuestion?.key === option?.key && "bg-[#e4f0fc]"
                        } min-h-[48px] w-full pl-4 pr-8 flex items-center cursor-pointer hover:bg-[#f2f3f4] border-y-2`}
                      >
                        <span className="text-gray-800 text-sm font-medium w-[50%] truncate mr-3">
                          {option?.value}
                        </span>
                        <div
                          className="h-8 w-8 min-w-8 min-h-8 rounded-full flex items-center justify-center text-gray-600"
                          style={{
                            background: getTintFromHex(
                              activeData?.color || COLORS.primary,
                              85
                            ),
                            border: `1px solid ${
                              activeData?.color || COLORS.primary
                            }`,
                          }}
                        >
                          {getInitials(
                            activeData?.title || activeData?.email
                          ) || ""}
                        </div>
                        <span className="ml-5 "> Pg- {option?.pageNo} </span>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
  useEffect(() => {
    let notAllowedSubTypes = ["variable"];
    let finalOptions: {
      id: string;
      title: string;
      role: any;
      pageNo: number;
    }[] = [];
    designerState?.schemasList?.forEach((schemas, index: number) => {
      schemas.forEach((schema) => {
        if (
          schema?.type === "text" &&
          !schema?.isStatic &&
          !notAllowedSubTypes?.includes(schema?.subType || "")
        ) {
          finalOptions.push({
            id: schema?.id,
            title: schema?.placeholder || schema?.showKey || schema?.key,
            role: schema?.role,
            pageNo: index + 1,
          });
        }
      });
    });
    setGeneratedPDFNameQuestions(finalOptions);
  }, []);
  return (
    <>
      <div className="flex items-center justify-between mt-5">
        <p className="text-sm font-semibold mb-2 flex gap-2 items-center ">
          <BiRename className="text-xl text-black" />
          Generated Pdf Name
        </p>
      </div>
      <p className="text-gray-500 text-xs pr-4 my-2">
        The name of the pdf will contain the values of following fields along
        with the written text. [Spaces are ignored]
        <br />
        <br />* Use Placeholders for better identification of fields.
      </p>
      <div className="flex gap-4 items-end text-xs w-full justify-between">
        <div className="w-full my-2">
          <CustomDropDown
            opts={generatedPDFNameQuestions?.map((question) => ({
              key: question?.id,
              value: question?.title,
              role: question?.role,
              pageNo: question?.pageNo,
            }))}
            selectedOpt={null}
            defaultText={"Add Fields"}
            setSelectedOpt={handleGeneratePDFFileNameLocal}
          />
        </div>
      </div>
      <div className="w-full my-2">
        <MixedTags
          className="text-xs w-full myTags "
          autoFocus={true}
          settings={mixedTagsSettings}
          readOnly={false}
          onChange={onChange}
          value={mixedTagsValue}
        />
      </div>
      <BoloButton
        variant={"primary"}
        className="w-full my-2"
        size={"xs"}
        onClick={handleGeneratedPDFName}
      >
        Save
      </BoloButton>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </>
  );
};

export default CustomPdfName;
