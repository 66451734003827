import { useEffect, useRef, useState } from "react";
import { CgFileDocument } from "react-icons/cg";
import { FiPlusSquare } from "react-icons/fi";
import {
  MdClose,
  MdFolderOpen,
  MdKeyboardArrowDown,
  MdOutlineAssignmentInd,
  MdOutlineGroups,
  MdOutlineMonetizationOn,
  MdOutlineSettings,
  MdOutlineVerified,
} from "react-icons/md";
import { LuCombine } from "react-icons/lu";
import { PiCaretDownBold } from "react-icons/pi";
import { TbApps } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { IMAGE_LINKS } from "../../constants/common";
import { getDashboardUrlV1 } from "../../constants/constants";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import { useAppSelector } from "../../redux/store";
import { getOwnerPlanDetails } from "../../utils";
import { RiContactsBookLine } from "react-icons/ri";
import SwitchWorkSpaces from "../SwitchWorkSpaces";
import { LuBox } from "react-icons/lu";
import { googleTranslateElementInit } from "../../utils/uiUtils";

const allNavItems = [
  {
    name: "Create",
    icon: FiPlusSquare,
    link: "/create",
  },
  {
    name: "Overview",
    icon: LuCombine,
    link: "/overview",
  },
  {
    name: "Documents",
    icon: CgFileDocument,
    link: "/dashboard",
    submenu: [
      { name: "Forms", link: "/dashboard/forms" },
      { name: "PDFs", link: "/dashboard/pdfs" },
      { name: "PDF Templates", link: "/dashboard/templates" },
    ],
  },
  {
    name: "Folders",
    icon: MdFolderOpen,
    link: "/folder",
  },
  {
    name: "Contacts",
    icon: RiContactsBookLine,
    link: "/contacts",
  },
  {
    name: "Assigned to me",
    icon: MdOutlineAssignmentInd,
    link: "/assigned-to-me",
  },
  {
    name: "Teams",
    icon: MdOutlineGroups,
    link: "/teams",
  },
  {
    name: "Marketplace",
    icon: TbApps,
    link: "/apps",
  },
  {
    name: "Product Catalog",
    icon: LuBox,
    link: "/payments",
    submenu: [
      { name: "Catalog", link: "/payments/product-catalog" },
      { name: "Payments", link: "/payments" },
    ],
  },
  {
    name: "Billings",
    icon: MdOutlineMonetizationOn,
    link: "/billings",
  },
  {
    name: "Verify PDF",
    icon: MdOutlineVerified,
    link: `/verify-pdf`,
  },
];

const SideBar = ({
  height,
  helpers,
}: {
  height: number;
  helpers: {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
  };
}) => {
  const { isOpen, setIsOpen } = helpers;
  let navigate = useNavigate();
  let location = useLocation();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const owner = useAppSelector((state) => state.root.ownerSlice.data.owner);
  const workSpaceDetails = useWorkSpaceDetails();
  const sideBarRef = useRef(null);
  const [navItems, setNavItems] = useState(allNavItems);
  const [workSpaces, setWorkSpaces] = useState({ show: false });
  const [openSubMenu, setOpenSubMenu] = useState("");

  useEffect(() => {
    let googleTranslateScript = document.getElementById(
      "google_translate_script"
    );
    if (!googleTranslateScript) {
      googleTranslateScript = document.createElement("script");
      googleTranslateScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      googleTranslateScript.setAttribute("id", "google_translate_script");
      document.body.appendChild(googleTranslateScript);
      (window as any).googleTranslateElementInit = googleTranslateElementInit;
    }
  }, []);

  useEffect(() => {
    if (!workSpaceDetails.isWorkSpaceOwner) {
      let newAllNavItems = [...allNavItems];
      if (!getOwnerPlanDetails()?.isLifeTime) {
        newAllNavItems = newAllNavItems.filter((item) => item.link !== "/apps");
      }
      newAllNavItems = newAllNavItems.filter(
        (item) =>
          !(
            item.link == "/billings" ||
            (workSpaceDetails.currentWorkSpaceRole !== "admin" &&
              item.link == "/teams") ||
            (workSpaceDetails.currentWorkSpaceRole !== "admin" &&
              item.link == "/payments")
          )
      );
      setNavItems(newAllNavItems);
    }

    if (workSpaceDetails.isWorkSpaceOwner) {
      let newAllNavItems = [...allNavItems];
      if (!getOwnerPlanDetails()?.isLifeTime) {
        newAllNavItems = newAllNavItems.filter((item) => item.link !== "/apps");
      }
      setNavItems(newAllNavItems);
    }
  }, [workSpaceDetails]);

  return (
    <div
      ref={sideBarRef}
      className={`w-[250px] fixed top-[${height}px] md:top-0 md:relative bg-white border-r transition-all duration-300`}
      style={{
        zIndex: "50",
        height: `calc(100vh - ${height}px)`,
        left: isOpen ? "0px" : "-280px",
      }}
    >
      {workSpaces.show && (
        <SwitchWorkSpaces
          setShow={(value: any) => {
            setWorkSpaces((prev) => ({ ...prev, show: value }));
          }}
        />
      )}
      <div className="bg-white flex items-center gap-4 h-[50px] md:h-[60px] p-2 md:p-3 text-left">
        <img
          className={`h-9 w-9 max-h-9 max-w-9 rounded `}
          src={
            owner?.accountInfo?.companyLogo ||
            whiteLabelInfo?.settings?.organization?.logo ||
            IMAGE_LINKS?.BOLOLOGO
          }
          alt=""
        />
        <div
          className=" rounded cursor-pointer whitespace-nowrap w-[75%] group"
          onClick={() => setWorkSpaces((prev) => ({ ...prev, show: true }))}
        >
          <div className="flex items-center justify-between">
            <h2
              translate="no"
              className="text-gray-00 font-semibold text-xs md:text-sm flex items-center gap-2 truncate max-w-[calc(100%_-_20px)]"
            >
              {workSpaceDetails?.currentWorkSpace?.name}
            </h2>
            <MdKeyboardArrowDown className="h-5 w-5 transition-all duration-300 min-w-[20px] max-w-[20px]" />
          </div>
          {/* <p className="hidden md:block  text-[11px] mt-1 text-gray-700">
            {workSpaceDetails?.formattedRole}
          </p> */}
        </div>
        <MdClose
          size={25}
          className="block md:hidden cursor-pointer ml-auto"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <div
        className={`p-2 md:p-3 pb-0 h-[calc(100%_-_140px)] flex flex-col gap-1.5 overflow-y-auto mt-4`}
        id="mini-pdf-custom-scroll"
      >
        {navItems.map((item, idx) => {
          let Icon = item.icon;
          let isAcitve = location.pathname?.includes(item.link);
          return (
            <>
              <div
                key={idx}
                className={`flex gap-4 items-center text-sm rounded hover:bg-gray-100 cursor-pointer px-2 py-2 ${
                  isAcitve
                    ? "text-primary font-semibold bg-secondary/60"
                    : "text-gray-700"
                }`}
                onClick={() => {
                  if (item.submenu) {
                    const lastSubLink = localStorage.getItem(
                      `${item.link}-LAST_SUBLINK`
                    );
                    if (lastSubLink) {
                      navigate(lastSubLink);
                      setOpenSubMenu(item.link);
                      return;
                    }
                    setOpenSubMenu(openSubMenu == item.link ? "" : item.link);
                    return;
                  }
                  // note should use keys here instead of name
                  if (item.name == "Verify Pdf") {
                    window.open(item.link, "_blank");
                    return;
                  }
                  navigate(item.link);
                }}
              >
                <div className="flex items-center gap-4 w-[calc(100%_-_20px)]">
                  <Icon className="h-5 w-5" /> <p>{item.name}</p>
                </div>
                {item.submenu && (
                  <div
                    className={`transition-all duration-200 ${
                      openSubMenu === item.link ? "rotate-180" : ""
                    }`}
                    onClick={(e) => {
                      if (openSubMenu == item.link) {
                        e.stopPropagation();
                        setOpenSubMenu(
                          openSubMenu == item.link ? "" : item.link
                        );
                        return;
                      }
                    }}
                  >
                    <PiCaretDownBold />
                  </div>
                )}
              </div>
              <div
                className={`transition-all duration-300 overflow-hidden text-gray-700 text-xs flex flex-col gap-2 pl-8 ${
                  item?.submenu && openSubMenu === item.link
                    ? "h-[100px]"
                    : "h-[0px]"
                }`}
                style={{
                  height:
                    item?.submenu && openSubMenu === item.link
                      ? `${item?.submenu?.length * (24 + 10)}px`
                      : "0px",
                  minHeight:
                    item?.submenu && openSubMenu === item.link
                      ? `${item?.submenu?.length * (24 + 10)}px`
                      : "0px",
                  maxHeight:
                    item?.submenu && openSubMenu === item.link
                      ? `${item?.submenu?.length * (24 + 10)}px`
                      : "0px",
                }}
              >
                {item?.submenu?.map((subItem, idx) => {
                  // let isAcitve = location.pathname?.includes(subItem.link);
                  let isAcitve = location.pathname == subItem.link;
                  return (
                    <div
                      key={idx + "_subitem"}
                      className={`${
                        isAcitve
                          ? "text-primary font-semibold bg-secondary/60 "
                          : "text-gray-700"
                      } rounded hover:bg-gray-100 cursor-pointer px-2 py-1.5`}
                      onClick={() => {
                        localStorage.setItem(
                          `${item.link}-LAST_SUBLINK`,
                          subItem.link
                        );
                        navigate(subItem.link);
                      }}
                    >
                      {subItem?.name}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
      {workSpaceDetails.currentWorkSpaceRole !== "readonly" && (
        <div
          className="w-[250px] fixed bottom-0 flex gap-4 h-[40px] md:h-[45px] text-sm items-center justify-start px-4 text-gray-700 pb-4 cursor-pointer font-semibold"
          onClick={() => {
            let route = `/settings?type=PROFILE`;

            if (workSpaceDetails?.currentWorkSpaceRole === "editor") {
              route = `/settings?type=CNAME`;
            }

            navigate(route);
          }}
        >
          <MdOutlineSettings size={20} />
          Settings
        </div>
      )}
    </div>
  );
};

export default SideBar;
