import React from "react";
import BoloModal from "../Common/Modal/BoloModal";
import { useAppSelector } from "../../redux/store";
import Modal from "../Modal";
import { BoloButton } from "../Common/Button/BoloButton";
import { formatDate } from "../../utils/uiUtils";
import { MdOutlineCreditScore } from "react-icons/md";
import axios from "../../utils/axios";
import axios_ from "axios";
import {
  getDashboardUrl,
  PAYMENTS_BASE_URL,
  SMS_CREDIT_PAYMENT_PRICE_ID,
} from "../../constants/constants";
import { toast } from "react-toastify";

interface SMSCreditTransactionHistoryModalProps {
  setShow: (value: boolean) => void;
}

const SMSCreditTransactionHistoryModal: React.FC<
  SMSCreditTransactionHistoryModalProps
> = ({ setShow }) => {
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);

  const currentPlan = owner?.planhistory?.[owner?.planhistory?.length - 1];

  let smsCreditsCount = currentPlan?.smsCredits?.count || 0;
  let smsCreditTransactionHistory: {
    count: number;
    transactionId?: string;
    transactionDate: string;
    amountPaid: number;
    transactionType: "credited" | "debited";
    transactionDescription: string;
  }[] = currentPlan?.smsCredits?.transactionHistory || [];

  smsCreditTransactionHistory = smsCreditTransactionHistory.map((item) => {
    return {
      ...item,
      transactionDate: formatDate(item.transactionDate),
    };
  });

  const buyCredits = async () => {
    try {
      let { data } = await axios_.post(
        `${PAYMENTS_BASE_URL}/createStripeOneTimePaymentUS`,
        {
          priceId: SMS_CREDIT_PAYMENT_PRICE_ID,
          quantity: 1,
          ownerEmail: owner?.email,
          successUrl: `${getDashboardUrl()}/billings`,
          cancelUrl: `${getDashboardUrl()}/billings`,
        }
      );

      // if (!data?.success) {
      //   throw new Error("Failed to buy credits");
      // }

      let sessionUrl = data?.sessionUrl;

      if (!sessionUrl) {
        throw new Error("Failed to buy credits");
      } else {
        window.open(sessionUrl, "_self");
      }
    } catch (error) {
      console.log("Error buying credits", error);
      // toast.error("Failed to buy credits");
    }
  };

  return (
    <div className="p-4 w-[98%] md:w-[450px] overflow-hidden ">
      <div className="flex flex-col gap-2">
        <p className="text-xs font-medium text-gray-700">SMS Credit balance</p>
        <h1
          className={`text-2xl font-medium ${
            smsCreditsCount < 10 ? "text-red-600" : "text-green-700"
          } `}
        >
          {smsCreditsCount} <span className="text-xs">remaining</span>
        </h1>
        <div className="flex gap-2 items-center">
          <BoloButton
            size={"xs"}
            variant={"gray-secondary-border"}
            className=" w-fit border border-gray-200"
            onClick={() => setShow(false)}
          >
            Go back
          </BoloButton>
          <BoloButton
            size={"xs"}
            variant={"tertiary"}
            className="font-medium w-fit"
            onClick={buyCredits}
          >
            Buy Credits
          </BoloButton>
        </div>
        <div
          className="px-1 py-2 w-full rounded-md border border-gray-300 text-xs flex flex-col gap-4 my-4 overflow-y-auto max-h-[450px] "
          id="mini-pdf-custom-scroll"
        >
          {smsCreditTransactionHistory.map((transaction, index) => (
            <div
              className={`flex items-center p-2 rounded-md ${
                index % 2 === 0 ? "bg-gray-100" : ""
              } `}
              key={index}
            >
              <div className="mr-4 text-2xl text-primary-bolo p-1 bg-secondary-bolo  rounded-md">
                <MdOutlineCreditScore size={18} />
              </div>
              <div className="flex-grow">
                <div className="font-semibold">
                  {transaction.transactionDescription}
                </div>
                <div className="text-gray-500 text-[10px]">
                  {transaction.transactionDate}
                </div>
              </div>
              <div
                className={`font-medium  ${
                  transaction.transactionType === "credited"
                    ? "text-green-600"
                    : "text-red-600"
                }`}
              >
                {transaction.transactionType === "credited" ? "+" : "-"}
                {transaction.count} SMS
              </div>
            </div>
          ))}

          {smsCreditTransactionHistory.length === 0 && (
            <p className="text-center text-gray-500">
              No SMS credit purchase history
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SMSCreditTransactionHistoryModal;
