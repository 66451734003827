//@ts-nocheck
import React, { useEffect, useState } from "react";
import { CgTemplate } from "react-icons/cg";
import { FaFilePdf } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { v4 } from "uuid";
import { getFormViewerUrl } from "../../constants/constants";
import axios from "../../utils/axios";
import { fetchOwner } from "../../utils";
import { useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import PlanExaustedBannerEditor from "../PlanExaustedModalEditor";

const CreateFileModal = ({
  setShow,
  parentFolder,
  stateUpdate,
  isShared,
  sharedOwnerId,
}) => {
  const navigate = useNavigate();
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  const [owner, setOwner] = useState({});
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );

  const [isQuotaExausted, setIsQuotaExausted] = useState(false);
  const [formData, setFormData] = useState({
    id: v4(),
    name: "",
    desc: "",
    connectSpreadsheet: false,
    saving: false,
    // formJson: null,
    formJson: {
      title: "Untitled",
      description: "No Description",
      logo:
        whiteLabelInfo?.settings?.organization?.logo || IMAGE_LINKS.BOLOLOGO,
      fontFamily: "Poppins",
      accentColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
      backgroundColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.secondary ||
        COLORS.secondary,
      themeTemplate: "google-forms", // notion-forms, default
      darkMode: false,
      pages: [
        {
          title: "Page 1",
          description: "Page Description",
          id: v4(),
          questions: [
            {
              type: "Text",
              title: "Email",
              description: "",
              required: true,
              id: v4(),
              value: "",
              mandatory: true,
              validation: "respondent_email",
            },
          ],
          goToNextPage: "next",
          goToPrevPage: "prev",
        },
        {
          title: "Page 2",
          description: "Page Description",
          id: v4(),
          questions: [],
          goToNextPage: "next",
          goToPrevPage: "prev",
        },
      ],
      prefill: {},
      prefillSource: "NONE",
      showViewAndVerifyPdf: true,
      showThankYouPageBranding: true,
    },
    isApprovalWorkflowEnabled: true,
  });

  const handleCreateForm = async (
    redirectUrl: string = "/forms/edit",
    renderer: string = "BLANK"
  ) => {
    let formName = formData.name || "Untitled Form, Signature Test";
    if (formName === "" && renderer === "BLANK") {
      return toast.error("Please enter a valid form name");
    }
    setFormData({ ...formData, saving: true });

    let formUrl =
      redirectUrl?.split("/").length > 3
        ? `${getFormViewerUrl()}/${redirectUrl?.split("/")[3]}/${
            formData.id
          }?p=view`
        : `${getFormViewerUrl()}/${formData.id}?p=view`;

    try {
      let apiData: any = {
        id: formData.id,
        folderId: parentFolder,
        name: formData.name || "Untitled Form",
        desc: formData.desc || "No description",
        // destinationId: null,
        // spreadsheetUrl: null,
        authorEmail: currentUserEmail,
        formUrl,
        // @ts-ignore
        ownerId: owner._id,
        oAuthToken: null,
        formInputFields: [],
        formType: "BOLOFORMS",
        // writeSpreadsheet: JSON.parse(formData.connectSpreadsheet),
        // writeSpreadsheet: formData.connectSpreadsheet,
      };

      if (formData.formJson) {
        apiData.formJson = formData.formJson;

        let responseItemsId: any = {};
        let formInputFields: string[] = [];
        for (const page of apiData.formJson.pages) {
          for (const question of page.questions) {
            delete question["chosen"];
            delete question["selected"];
            responseItemsId[question.id] = question.title;
            formInputFields.push(question.title);
            if (question.title.trim() === "") {
              toast.error("Please fill all the question titles");
              return;
            }
          }
        }
        apiData.responseItemsId = responseItemsId;
        apiData.formInputFields = formInputFields;
      }

      try {
        const { data } = await axios.post("/forms", apiData);

        console.log("data", data);
        toast.dismiss();
        if (data.error === "You have exhausted your allowed quota") {
          setIsQuotaExausted(true);
          return;
        }

        let isTrialExtended = data?.trialExtended;

        let navigateUrl = `/create/forms/?id=${formData.id}`;

        if (isTrialExtended?.extended) {
          navigateUrl = `/create/forms/?id=${formData.id}&trialExtended=true&d=${isTrialExtended?.days}`;
        }

        if (!data?.needPermission && !data.error) {
          navigate(navigateUrl);
          setFormData({ ...formData, saving: false });
        } else {
          toast.error("Something went wrong, create page: line 190 : test");
        }
      } catch (error: any) {
        console.log(error);
        toast.error(error?.message);
        setFormData({ ...formData, saving: false });
      }
    } catch (error: any) {
      console.log(error);
      toast.error("Something went wrong, create page: line 190 : test");
      toast.error(error?.message);
      setFormData({ ...formData, saving: false });
    }
  };

  const handleChoice = (choice: string) => {
    if (choice === "forms") {
      // navigate("/create/forms");
      handleCreateForm();
    } else if (choice === "pdf") {
      navigate(`/create/pdf?folderId=${parentFolder}`);
    } else if (choice === "template") {
      // does not exist
      navigate(`/create/form-template?id=${v4()}&folderId=${parentFolder}`);
    } else if (choice === "pdf-template") {
      navigate(`/new/pdf-template?folderId=${parentFolder}`);
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />{" "}
          <div className="text-base md:text-lg mb-4 font-semibold">
            Create New File
          </div>
          <div className="w-full md:h-fit mt-4 md:mt-8 flex flex-col md:flex-row flex-wrap gap-6 md:gap-12 justify-center items-center mb-8">
            <FormView
              icon={<SiGoogleforms size={20} className="text-gray-600" />}
              name={"Form"}
              desc={"Signatures using Form"}
              onClick={() => handleChoice("forms")}
            />
            <FormView
              icon={<FaFilePdf size={20} className="text-gray-600" />}
              name={"PDF"}
              desc={"Send PDF for Signature"}
              onClick={() => handleChoice("pdf")}
            />
            <FormView
              icon={<CgTemplate size={20} className="text-gray-600" />}
              name={"PDF Template"}
              desc={"Templatize your PDFs"}
              onClick={() => handleChoice("pdf-template")}
            />
          </div>
        </div>
      </div>
      {isQuotaExausted && (
        <PlanExaustedBannerEditor
          setShow={(value: boolean) => {
            setIsQuotaExausted(value);
          }}
        />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

interface FormViewProps {
  imgUrl?: string;
  icon?: any;
  name?: string;
  desc?: string;
  onClick?: () => void;
}

const FormView = ({ imgUrl, name, desc, onClick, icon }: FormViewProps) => {
  return (
    <div
      onClick={onClick}
      style={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
      }}
      className="bg-white md:h-[200px] gap-4 w-[100%] md:w-fit md:mb-4 cursor-pointer border border-gray-50 rounded-2xl p-2 flex flex-row md:flex-col justify-start md:justify-center items-center hover:scale-110 transition-all"
    >
      <div className="h-[50px] md:h-[70px]  flex justify-center items-center aspect-square bg-gray-100 rounded-full">
        {icon}
      </div>
      <div className="p-2 md:text-center">
        <p className="mb-1.5 font-semibold">{name}</p>{" "}
        <p className="text-xs text-gray-400">{desc}</p>
      </div>
    </div>
  );
};

export default CreateFileModal;
