import { ImDrive } from "react-icons/im";
import { FaFolder } from "react-icons/fa";
import { useEffect, useState } from "react";
import BoloModal from "../Common/Modal/BoloModal";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import BoloLoader from "../Common/Loader/BoloLoader";
import { MdOutlineNavigateNext } from "react-icons/md";
import { BoloButton } from "../Common/Button/BoloButton";

interface OnedriveModalFolderSelectTypes {
  setShowOnedriveModal: React.Dispatch<React.SetStateAction<boolean>>;
  ownerEmail: String;
  setSelectedFolder: React.Dispatch<React.SetStateAction<any>>;
  selectedFolder: any;
  handleSubmit: (folder: any, enabled?: boolean) => void;
}
const OnedriveModalFolderSelect = ({
  setShowOnedriveModal,
  ownerEmail,
  setSelectedFolder,
  selectedFolder,
  handleSubmit,
}: OnedriveModalFolderSelectTypes) => {
  const [loading, setLoading] = useState(false);
  const [drives, setDrives] = useState([]);
  const [driveItems, setDriveItems] = useState([]);
  const [path, setPath] = useState<any>([]);
  // const dispatch = useAppDispatch();

  const fetchUsersDrive = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/microsoft/getAllDrives?ownerEmail=${ownerEmail}`
      );
      if (data?.success) {
        setDrives(data?.data);
      } else {
        toast.error("Request failed! Try again.");

        setShowOnedriveModal(false);
      }
      setLoading(false);
    } catch (error) {
      toast.error("Request failed! Try again.");

      setShowOnedriveModal(false);
    }
  };
  const getDriveItems = async (drive: any) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/microsoft/driveItems?ownerEmail=${ownerEmail}&driveId=${drive?.id}`
      );
      if (data?.success && data?.newData) {
        setDriveItems(data?.newData);
        setLoading(false);
        setDrives([]);
      } else {
        setLoading(false);
        toast.error("Request failed! Try again.");
      }
    } catch (error) {
      toast.error("Error while fecthing drive items");
    }
  };
  const fetchFolderItems = async (folder: any) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/microsoft/folderItems?ownerEmail=${ownerEmail}&folderId=${folder?.id}&driveId=${folder?.driveId}`
      );
      if (data?.success) {
        setDriveItems(data?.newData);
        setLoading(false);
        setDrives([]);
      } else {
        setLoading(false);
        toast.error("Request failed! Try again.");
      }
    } catch (error) {
      toast.error("Error while fecthing drive items");
    }
  };
  const disconnectOnedrive = async () => {
    try {
      const { data } = await axios.post("/microsoft/uninstall", { ownerEmail });
      if (data?.success) {
        toast.success("Microsoft Uninstalled!");
        setShowOnedriveModal(false);
        window.location.reload();
        // dispatch(getOwnerThunk());
      }
    } catch (error) {
      toast.error("Error while disconnecting!");
    }
  };

  useEffect(() => {
    fetchUsersDrive();
    // dispatch(getOwnerThunk());
  }, []);
  return (
    <>
      <BoloModal
        content={
          <div className="min-h-[600px]">
            <div className="text-lg md:text-xl mb-2 font-semibold ">
              OneDrive
            </div>

            <div className="gap-2 flex  items-center justify-between text-sm text-gray-600 mb-4">
              <p>Select a folder where you want to upload files.</p>
              <div
                className="text-xs pl-1 text-gray-400 hover:underline cursor-pointer hover:text-red-400"
                onClick={disconnectOnedrive}
              >
                Disconnect
              </div>
            </div>
            <div className="h-[480px]">
              {loading ? (
                <div className="mt-60">
                  <BoloLoader height={40} width={40} />
                </div>
              ) : (
                <>
                  {path.length > 0 && (
                    <div className="text-xs md:text-sm  flex gap-1 ">
                      <div
                        onClick={() => {
                          setPath([]);
                          fetchUsersDrive();
                          setDriveItems([]);
                        }}
                        className="hover:underline cursor-pointer"
                      >
                        My drives
                      </div>
                      {path?.map((ele: any, index: number) => {
                        if (index === path?.length - 1) {
                          return <></>;
                        }
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              if (ele?.type === "drive") {
                                getDriveItems(ele);
                              } else if (ele?.type === "Folder") {
                                fetchFolderItems(ele);
                              }
                              setPath((prevArray: any) =>
                                prevArray.slice(0, index + 1)
                              );
                            }}
                            className="flex items-center hover:underline cursor-pointer"
                          >
                            <MdOutlineNavigateNext className="text-black text-xs" />{" "}
                            {ele?.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {drives?.length > 0 && (
                    <div className="overflow-y-auto">
                      <div className="text-base mb-2 mt-3 font-semibold ">
                        My drives
                      </div>
                      {drives.map((drive: any, index: number) => (
                        <div
                          key={index}
                          className="flex gap-2 items-center border-t py-3 hover:bg-gray-100 cursor-pointer px-2 text-sm"
                          onClick={() => {
                            let newdrive = { ...drive, type: "drive" };
                            if (!drive?.name) {
                              newdrive = {
                                ...newdrive,
                                name: "Untitled drive",
                              };
                            }
                            setPath((prev: any) => [...prev, newdrive]);

                            setLoading(true);
                            getDriveItems(drive);
                          }}
                        >
                          <ImDrive className="text-blue-500" />
                          {drive?.name || "Untitled drive"}
                        </div>
                      ))}
                    </div>
                  )}
                  {driveItems?.length > 0 && (
                    <div
                      className="overflow-y-auto"
                      id="mini-pdf-custom-scroll"
                    >
                      <div className="text-base mb-2  font-semibold ">
                        {path?.[path?.length - 1]?.name || "Untitled"}
                      </div>
                      <div className="text-gray-400 flex border-b items-center text-xs pb-3 justify-between px-3 ">
                        {" "}
                        <span>TITLE</span> <span>SIZE</span>
                      </div>

                      {driveItems.map((item: any, index: number) => {
                        let typeFolder = item?.type === "Folder" ? true : false;
                        console.log("item", item);
                        if (typeFolder) {
                          return (
                            <div
                              key={index}
                              className="flex gap-2 items-center border-b  py-3 hover:bg-gray-100 cursor-pointer px-2 text-sm"
                              onClick={() => {
                                setPath((prev: any) => [...prev, item]);

                                setLoading(true);
                                fetchFolderItems(item);
                              }}
                            >
                              <input
                                type="radio"
                                className="cursor-pointer mr-3"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) => {
                                  console.log("selected", e.target.checked);
                                  if (e.target.checked) {
                                    setSelectedFolder(item);
                                  } else {
                                    setSelectedFolder({});
                                  }
                                }}
                                checked={item?.id == selectedFolder?.id}
                              />
                              <FaFolder className="text-blue-500" />

                              <span className={`hover:underline`}>
                                {item?.name}
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="flex justify-end gap-4">
              <BoloButton
                variant={"gray-primary"}
                size={"sm"}
                onClick={() => {
                  setSelectedFolder({});
                  setShowOnedriveModal(false);
                }}
              >
                Cancel
              </BoloButton>
              <BoloButton
                variant={"primary"}
                size={"sm"}
                onClick={() => {
                  if (Object?.entries(selectedFolder)?.length === 0) {
                    toast.error("Please select a folder");
                    return;
                  }
                  if (selectedFolder?.type !== "Folder") {
                    toast.error("Please select a folder type!");
                    return;
                  }
                  handleSubmit(selectedFolder);
                }}
              >
                Select
              </BoloButton>
            </div>
          </div>
        }
        setShow={setShowOnedriveModal}
      />
    </>
  );
};

export default OnedriveModalFolderSelect;
