// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { MdOutlineError } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { v4 } from "uuid";
import { BASE_URL, getFormViewerUrl } from "../../constants/constants";
import { PDFDocument } from "pdf-lib";
import { fetchOwner } from "../../utils";
import PlanExhaustedNavBanner from "../PlanExhaustedNavBanner";
import axios from "./../../utils/axios";
import { BiSolidUpArrowCircle } from "react-icons/bi";
import { FaCirclePlus, FaFilePdf } from "react-icons/fa6";
import {
  IoArrowBackCircle,
  IoArrowForwardCircle,
  IoCloudDownloadSharp,
} from "react-icons/io5";
import useDrivePicker from "react-google-drive-picker";
import { TailSpin } from "react-loader-spinner";
import { blobToFile, decryptPdf } from "../../utils/uiUtils";
import SamplePdfModal from "../SamplePdfModal";
import { BoloButton } from "../Common/Button/BoloButton";
import MixPanel from "../../utils/services/mixpanel";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import { MultiplePdfUploaded } from "./MultiplePdfUploaded";
import { mergePdfs } from "../../utils/pdf";
import { useDispatch } from "react-redux";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
import { GrDropbox } from "react-icons/gr";
import {
  microsoftClientID,
  microsoftAuthRedirectUrl,
  microsoftScopes,
} from "../../constants/Integrations/Microsoft";
import DropboxChooser from "react-dropbox-chooser";
import OnedriveModal from "./OnedriveModal";
import { isEmbeded } from "../../utils/embed";
import { detectStatusandBody } from "../../redux/apis/fileupload";
import {
  copyPdfBytes,
  updatePdfWithBlankPage,
} from "../../utils/localPdfUtils";
import { isFeatureEnabled } from "../../utils/paidFeat";

const arrayBufferToBase64 = (buffer: any) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

const Uploader = ({
  preUploadedFile,
  onFileUpload,
  isOnlyPdfTemplate = false,
  isSignature = false,
  isValidReceivers = false,
  isWithRole = false,
  isRolesAdded = false,
  roles = [],
  setFileName,
  formId = "",
  documentId = "",
  setPdfsCount = (count: number) => {},
  setFieldsCount = (count: number) => {},
}) => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [owner, setOwner] = useState({});
  const [quotaExhausted, setQuotaExhausted] = useState(false);
  const [isDriveFileUploading, setIsDriveFileUploading] = useState(false);
  const [searchParams] = useSearchParams();
  const [conversionError, setConversionError] = useState(false);
  const [uplaodingFileLoader, setUploadingFileLoader] = useState(false);
  const [uploadingPdf, setUploadingPdf] = useState(false);
  const [uploadingMultipleFiles, setUploadingMultipleFiles] = useState(false);
  const [showSamplePdfModal, setShowSamplePdfModal] = useState(false);
  const [samplePdfFile, setSamplePdfFile] = useState(null);
  const [uploadedPdfs, setUploadedPdfs] = useState([]);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalType, setUpgradeModalType] = useState("");
  const [showDriveDropDown, setShowDriveDropDown] = useState(false);
  const dropdownShareOptionsRef = useRef(null);
  const [showOnedriveModal, setShowOnedriveModal] = useState(false);
  const SamplePdfUrl =
    "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/pdf/1658b649-e5a5-4e0d-b44b-ead16dea34fa.pdf";
  const [formData, setFormData] = useState({
    id: searchParams.get("id"),
    name: "",
    desc: "",
    connectSpreadsheet: false,
    saving: false,
    formJson: {
      title: "Untitled",
      description: "No Description",
      logo:
        whiteLabelInfo?.settings?.organization?.logo || IMAGE_LINKS.BOLOLOGO,
      fontFamily: "Poppins",
      accentColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
      backgroundColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.secondary ||
        "#f3f4f6",
      themeTemplate: "google-forms", // notion-forms, default
      darkMode: false,
      pages: [
        {
          title: "Page 1",
          description: "Page Description",
          id: v4(),
          questions: [
            {
              type: "Text",
              title: "Email",
              description: "",
              required: true,
              id: v4(),
              value: "",
              mandatory: true,
              validation: "respondent_email",
            },
          ],
          goToNextPage: "next",
          goToPrevPage: "prev",
        },
        {
          title: "Page 2",
          description: "Page Description",
          id: v4(),
          questions: [],
          goToNextPage: "next",
          goToPrevPage: "prev",
        },
      ],
      prefill: {},
      prefillSource: "NONE",
    },
    isApprovalWorkflowEnabled: true,
  });
  const acceptedMimeTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-word.document.macroenabled.12",
  ];

  const [openPicker, authResponse] = useDrivePicker();

  const dispatch = useAppDispatch();

  const featuresEnabled = useIsFeatureEnabled();

  useEffect(() => {
    dispatch(getOwnerThunk());
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        const response = await fetch(SamplePdfUrl);
        const blob = await response.blob();
        const file = new File([blob], "Sample.pdf", {
          type: "application/pdf",
        });
        setSamplePdfFile(file);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    fetchPDF();
  }, []);
  useEffect(() => {
    setPdfsCount(uploadedPdfs.length);
  }, [setPdfsCount, uploadedPdfs]);

  useEffect(() => {
    const getFiledsCount = async () => {
      const pdfBytes = new Uint8Array(await file.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const formFields = pdfDoc.getForm().getFields();
      setFieldsCount(formFields.length);
    };
    if (file) {
      getFiledsCount();
    }
  }, [file, setFieldsCount]);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickShareOptions);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickShareOptions);
    };
  }, []);
  const handleOutsideClickShareOptions = (e: any) => {
    if (dropdownShareOptionsRef.current !== null) {
      if (dropdownShareOptionsRef.current.contains(e.target)) return;
    }
    setShowDriveDropDown(false);
  };
  async function checkFileEncryption(
    files: File[]
  ): Promise<{ error?: string; files: File[] }> {
    let decryptedFiles: File[] = [];
    let index = 0;
    console.log("check file encryption");
    for (let file of files) {
      console.log("file type", file?.type);
      if (file?.type !== "application/pdf") {
        decryptedFiles.push(file);
        continue;
      }
      // console.log("index", ++index);
      try {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        decryptedFiles.push(file);
      } catch (error) {
        if (error instanceof Error && error.message.includes("encrypted")) {
          // const isEncryptedFileUploadEnabled = await isFeatureEnabled(
          //   "ENCRYPTED_FILE_UPLOAD"
          // );
          // console.log(
          //   "isEncryptedFileUploadEnabled",
          //   isEncryptedFileUploadEnabled
          // );
          // if (!isEncryptedFileUploadEnabled) {
          //   setShowUpgradeModal(true);
          //   setUpgradeModalType("ENCRYPTED_FILE_UPLOAD");
          //   return;
          // }
          // Prompt the user to enter the password if the file is encrypted
          const password = prompt(
            `The file ${file.name} is encrypted. Please enter the password (if no password, just press enter):`
          );
          if (password === null) {
            return { error: "Password is required", files: files };
          }

          try {
            const decryptedFile = await decryptPdf(password, file);
            decryptedFiles.push(decryptedFile);
          } catch (decryptError) {
            console.log(`Error decrypting file ${file.name}:`, decryptError);
            return {
              error: `Error decrypting file ${file.name}`,
              files: decryptedFiles,
            };
          }
        } else {
          console.error(`Error processing file ${file.name}:`, error);

          return {
            error: `Error processing file ${file.name}`,
            files: decryptedFiles,
          };
        }
      }
    }
    return { files: decryptedFiles };
  }
  const handleFileInputChange = async (e, type = "input") => {
    e.preventDefault();
    setUploadedPdfs([]);
    if (type === "input" && e.target.files?.length == 0) {
      return;
    }
    setConversionError(false);
    let files;

    if (!showSamplePdfModal) {
      if (type == "drop") {
        files = Array.from(e.dataTransfer.files);
      } else {
        files = Array.from(e.target.files);
      }
    } else {
      files = [samplePdfFile];
    }
    let uploadingFiles = [];
    let totalSize = 0;

    let fileEncCheck = await checkFileEncryption(files);
    if (fileEncCheck.error) {
      console.log({ fileEncCheck });
      toast.error(fileEncCheck.error);
      return;
    }
    if (fileEncCheck.files.length > 0) {
      files = fileEncCheck.files;
    }

    files.forEach((file) => (totalSize += file?.size));

    if (totalSize > 50 * 1024 * 1024) {
      toast.error("Total size of the files should be less than 50 MB");

      return;
    }

    if (totalSize > 15 * 1024 * 1024 && !featuresEnabled["FIFTY_MB_PDF_SIZE"]) {
      if (!featuresEnabled["FIFTEEN_MB_PDF_SIZE"]) {
        setShowUpgradeModal(true);
        setUpgradeModalType("FIFTEEN_MB_PDF_SIZE");
      } else if (featuresEnabled["FIFTEEN_MB_PDF_SIZE"]) {
        setShowUpgradeModal(true);
        setUpgradeModalType("FIFTY_MB_PDF_SIZE");
      }

      return;
    }

    if (
      totalSize > 5 * 1024 * 1024 &&
      !featuresEnabled["FIFTEEN_MB_PDF_SIZE"] &&
      !featuresEnabled["FIFTY_MB_PDF_SIZE"]
    ) {
      // toast.error("Total size of the files should be less than 10 MB");

      setShowUpgradeModal(true);
      setUpgradeModalType("FIFTEEN_MB_PDF_SIZE");

      return;
    }

    setShowSamplePdfModal(false);
    setUploadingFileLoader(true);
    // if any issue arises with pdf corruption
    // can comment these three lines
    for (let i = 0; i < files.length; i++) {
      let uploadedFile = files[i];
      if (!acceptedMimeTypes?.includes(uploadedFile?.type)) {
        setUploadingFileLoader(false);
        toast.error("Only pdf and doc files accepted");
        return;
      }
      if (uploadedFile?.type == "application/pdf") {
        const orignalName = uploadedFile?.name;
        // const fillAblePDFCoverted = await convertToNormalPDF(uploadedFile);
        const pdfWithoutBlankPage = await updatePdfWithBlankPage(uploadedFile);
        // const convertedToFile = blobToFile(fillAblePDFCoverted, orignalName);
        if (
          typeof pdfWithoutBlankPage == File ||
          typeof pdfWithoutBlankPage == "object"
        ) {
          uploadedFile = pdfWithoutBlankPage;
        }
      }
      if (
        uploadedFile.type == "application/msword" ||
        uploadedFile.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        uploadedFile.type == "application/vnd.ms-word.document.macroenabled.12"
      ) {
        setFile(null);
        onFileUpload(null);
        const formData2 = new FormData();
        formData2.append("docxFile", uploadedFile);
        try {
          const response = await axios.post(
            "https://convertor.boloforms.com/api/conversion/convert-docx-to-pdf",
            formData2,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              responseType: "blob",
            }
          );
          const fileWithChangedData = response.data;

          const fileWithChangedBlob = new Blob([fileWithChangedData], {
            type: "application/pdf",
          });

          const newFileName = uploadedFile.name.replace(/\.[^.]+$/, ".pdf");
          const fileWithChanged = new File([fileWithChangedBlob], newFileName, {
            type: "application/pdf",
            lastModified: new Date().getTime(),
          });
          uploadedFile = fileWithChanged;
        } catch (error) {
          console.error("Error converting doc to pdf:", error);
          toast.error("Error while converting doc to pdf");
          setConversionError(true);
          return;
        }
      }
      uploadingFiles.push(uploadedFile);
    }
    setUploadedPdfs(uploadingFiles);
    if (uploadingFiles.length > 1) {
      mergingPdfs(uploadingFiles);
    } else {
      let uploadedFile = uploadingFiles[0];
      setFile(uploadedFile);
      if (isSignature) {
        onFileUpload(uploadedFile);
        setFileName && setFileName(uploadedFile.name);
      } else if (isOnlyPdfTemplate) {
        onFileUpload && onFileUpload(uploadedFile);
      }
    }
    setUploadingFileLoader(false);
  };
  const handleGoogleDriveFilePick = async () => {
    setShowDriveDropDown(false);
    setUploadedPdfs([]);
    if (isOnlyPdfTemplate) {
      new MixPanel().track("PDF template", {
        pdf_template_action: "pdf_template_upload_from_drive",
        paid: false,
      });
      new MixPanel().increment("pdf_template_upload_from_drive");
    } else {
      if (formId === "") {
        new MixPanel().track("PDF", {
          pdf_action: "pdf_upload_from_drive",
          paid: false,
        });
        new MixPanel().increment("pdf_upload_from_drive");
      } else {
        new MixPanel().track("Form", {
          form_action: "form_customize_pdf_upload_from_drive",
          paid: false,
        });
        new MixPanel().increment("form_customize_pdf_upload_from_drive");
      }
    }
    setIsDriveFileUploading(true);

    let redirectUrl =
      !isSignature && !isWithRole && formId
        ? `/create/form/pdf?id=${formId}`
        : isSignature && !isWithRole
        ? `/create/pdf`
        : isOnlyPdfTemplate && documentId
        ? `/create/pdf-template?documentId=${documentId}`
        : `/create`;

    let isSufficientPermission =
      owner?.integrationTokens?.google?.oAuthRefreshToken ||
      owner?.oAuthRefreshToken;

    if (!isSufficientPermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=${redirectUrl}`,
        "_self"
      );
      return;
    }
    const { data: permissionDetails } = await axios.post(
      `/forms/check-google-permission`,
      {
        ownerEmail: owner?.email,
      }
    );

    if (!permissionDetails?.isDrivePermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=${redirectUrl}`,
        "_self"
      );
      return;
    }

    let clientId, developerKey;

    try {
      const { data } = await axios.get(
        `${BASE_URL}/common/get-google-credentials`
      );
      clientId = data?.clientId;
      developerKey = data?.developerKey;
    } catch (error) {
      console.error(
        "Error fetching client ID and developer key from the backend",
        error
      );
    }

    setIsDriveFileUploading(false);

    if (clientId && developerKey) {
      setIsDriveFileUploading(true);

      openPicker({
        clientId,
        developerKey,
        token: owner?.integrationTokens?.google?.oAuthToken,
        viewId: "PDFS",
        supportDrives: true,
        multiselect: true,
        appId: 204560571245,
        callbackFunction: async (driveData) => {
          if (driveData.action === "cancel") {
            setIsDriveFileUploading(false);
            console.log("User clicked cancel/close button");
          }

          if (driveData?.docs) {
            let totalSize = 0;
            let uploadingFiles = [];
            try {
              for (let i = 0; i < driveData?.docs?.length; i++) {
                const fileId = driveData?.docs[i]?.id;
                // Fetch file content from Google Drive API
                const { data } = await axios.post(
                  `${BASE_URL}/common/get-file-from-drive-using-metadata`,
                  {
                    fileId,
                    ownerId: owner?._id,
                  },
                  {
                    responseType: "arraybuffer",
                  }
                );
                // Create a Blob from the ArrayBuffer
                const blob = new Blob([data], { type: "application/pdf" });

                // Create a File from the Blob
                const fileName = driveData?.docs[i]?.name;
                const uploadedFile = new File([blob], fileName, {
                  type: "application/pdf",
                });
                uploadingFiles.push(uploadedFile);
                totalSize += uploadedFile?.size;
              }
              if (totalSize > 10 * 1024 * 1024) {
                toast.error(
                  "Total size of the files should be less than 10 MB"
                );
                setIsDriveFileUploading(false);
                return;
              }
              setUploadedPdfs(uploadingFiles);
              if (uploadingFiles?.length > 1) {
                mergingPdfs(uploadingFiles);
              } else {
                let uploadedFile = uploadingFiles[0];
                setFile(uploadedFile);

                if (isSignature) {
                  onFileUpload(uploadedFile);
                  setFileName && setFileName(uploadedFile?.name);
                } else if (isOnlyPdfTemplate) {
                  onFileUpload && onFileUpload(uploadedFile);
                }
              }
            } catch (error) {
              console.log(error);
              toast.error("Something went wrong");
            }
            setIsDriveFileUploading(false);
          }
        },
      });
    }
  };
  const handleOneDriveFilePick = async () => {
    setUploadedPdfs([]);
    setIsDriveFileUploading(true);
    let redirectUrl =
      !isSignature && !isWithRole && formId
        ? `/create/form/pdf?id=${formId}`
        : isSignature && !isWithRole
        ? `/create/pdf`
        : isOnlyPdfTemplate && documentId
        ? `/create/pdf-template`
        : `/create`;
    let isIntegrated =
      (owner?.integrationTokens?.microsoft?.access_token &&
        owner?.integrationTokens?.microsoft?.access_token !== "") ||
      false;
    if (!isIntegrated) {
      window.open(
        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${microsoftClientID}&scope=${microsoftScopes}&response_type=code&redirect_uri=${microsoftAuthRedirectUrl}&state=${owner?.email};${redirectUrl}`,
        "_self"
      );
      return;
    } else {
      setShowOnedriveModal(true);
    }
  };
  const handleDropBoxFilePick = async (files) => {
    setIsDriveFileUploading(true);
    setShowDriveDropDown(false);
    setUploadedPdfs([]);

    let uploadingFiles = [];
    let totalSize = 0;
    try {
      for (const fileData of files) {
        const response = await fetch(fileData?.link);
        const blob = await response.blob();
        const file = new File([blob], fileData?.name, {
          type: "application/pdf",
        });
        uploadingFiles.push(file);
        totalSize += file.size;
      }

      if (totalSize > 10 * 1024 * 1024) {
        toast.error("Total size of the files should be less than 10 MB");
        setIsDriveFileUploading(false);
        return;
      }
      setUploadedPdfs(uploadingFiles);
      if (uploadingFiles?.length > 1) {
        mergingPdfs(uploadingFiles);
      } else {
        let uploadedFile = uploadingFiles[0];
        setFile(uploadedFile);

        if (isSignature) {
          onFileUpload(uploadedFile);
          setFileName && setFileName(uploadedFile?.name);
        } else if (isOnlyPdfTemplate) {
          onFileUpload && onFileUpload(uploadedFile);
        }
      }
      setIsDriveFileUploading(false);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };
  const mergingPdfs = async (uploadingFiles) => {
    new MixPanel().track("PDF", {
      pdf_action: "pdf_multiple_uploads",
      paid: false,
    });
    new MixPanel().increment("pdf_multiple_uploads");
    setUploadingMultipleFiles(true);
    // If only one left in the list then no merging of pdfs
    // As merging flatten the pdf
    if (uploadingFiles.length === 1) {
      let uploadedFile = uploadingFiles[0];
      setFile(uploadedFile);

      if (isSignature) {
        onFileUpload(uploadedFile);
        setFileName && setFileName(uploadedFile.name);
      } else if (isOnlyPdfTemplate) {
        onFileUpload && onFileUpload(uploadedFile);
      }
    } else {
      // Merge pdfs when multiple pdfs are selected by the user
      let mergedFile = await mergePdfs(uploadingFiles);
      if (mergedFile) {
        const fileWithChanged = new File([mergedFile], uploadingFiles[0].name, {
          type: "application/pdf",
          lastModified: new Date().getTime(),
        });
        mergedFile = fileWithChanged;
        setFile(mergedFile);
        if (isSignature) {
          onFileUpload(mergedFile);
          setFileName && setFileName(mergedFile.name);
        } else if (isOnlyPdfTemplate) {
          onFileUpload && onFileUpload(mergedFile);
        }
        setUploadingMultipleFiles(false);
      } else {
        setUploadedPdfs([]);
        setConversionError(true);
      }
    }
  };

  const handleCreateForm = async (
    redirectUrl: string = "/forms/edit",
    renderer: string = "BLANK"
  ) => {
    let formName = formData.name || "Untitled Form, Signature Test";
    if (formName === "" && renderer === "BLANK") {
      return toast.error("Please enter a valid form name");
    }
    setFormData({ ...formData, saving: true });

    let formUrl =
      redirectUrl?.split("/").length > 3
        ? `${getFormViewerUrl()}/${redirectUrl?.split("/")[3]}/${
            formData.id
          }?p=view`
        : `${getFormViewerUrl()}/${formData.id}?p=view`;

    try {
      let apiData: any = {
        id: formData.id,
        name: formData.name || "Untitled Form",
        desc: formData.desc || "No description",
        // destinationId: null,
        // spreadsheetUrl: null,
        authorEmail: currentUserEmail,
        formUrl,
        // @ts-ignore
        ownerId: owner._id,
        oAuthToken: null,
        formInputFields: [],
        formType: "BOLOFORMS",
        // writeSpreadsheet: JSON.parse(formData.connectSpreadsheet),
        // writeSpreadsheet: formData.connectSpreadsheet,
      };

      if (formData.formJson) {
        apiData.formJson = formData.formJson;

        let responseItemsId: any = {};
        let formInputFields: string[] = [];
        for (const page of apiData.formJson.pages) {
          for (const question of page.questions) {
            delete question["chosen"];
            delete question["selected"];
            responseItemsId[question.id] = question.title;
            formInputFields.push(question.title);
            if (question.title.trim() === "") {
              toast.error("Please fill all the question titles");
              return;
            }
          }
        }
        apiData.responseItemsId = responseItemsId;
        apiData.formInputFields = formInputFields;
      }

      try {
        const { data } = await axios.post("/forms", apiData);

        // if (data?.needPermission) {
        //   setShow((prev: any) => {
        //     return {
        //       ...prev,
        //       permissionModal: {
        //         ...prev.permissionModal,
        //         show: true,
        //         data: {
        //           ...prev.permissionModal.data,
        //           permissionOptions: data.scopeOptions,
        //           permissionUrl: data.permissionUrl,
        //           permission: data.scopeOptions[0].value,
        //         },
        //       },
        //     };
        //   });

        //   setFormData({ ...formData, saving: false });

        //   return;
        // }

        if (!data?.needPermission && !data.error) {
          // navigate(`/create/forms/?id=${formData.id}`);
          setFormData({ ...formData, saving: false });
        } else {
          toast.info("Something went wrong, try again");
        }
      } catch (error: any) {
        console.log(error);
        toast.error(error?.message);
        setFormData({ ...formData, saving: false });
      }
    } catch (error: any) {
      console.log(error);
      toast.error("Something went wrong, try again");
      toast.error(error?.message);
      setFormData({ ...formData, saving: false });
    }
  };

  const handleBuildForm = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }
    if (file && isOnlyPdfTemplate) {
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = async () => {
      //   const base64File = reader.result;
      //   onFileUpload(base64File);
      // };

      try {
        let documentId = searchParams.get("documentId");
        const formData = new FormData();
        formData.append("pdfFile", file);
        formData.append("documentId", documentId);
        formData.append("ownerId", owner._id);
        formData.append("roles", JSON.stringify(roles));

        // const { data } = await axios.post(
        //   "/create/signature-pdf-template",
        //   formData,
        //   {
        //     headers: { "Content-Type": "multipart/form-data" },
        //   }
        // );

        let handledUpload = await detectStatusandBody(
          "/create/signature-pdf-template",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (handledUpload.error && handledUpload.error === "403-nb") {
          formData.delete("pdfFile");
          formData.append("pdfFile", await copyPdfBytes(pdfFile));
          handledUpload = await detectStatusandBody(
            "/create/signature-pdf-template",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
        }
        if (handledUpload.error && handledUpload.error !== "403-nb") {
          console.log(handledUpload.error);
          console.log(handledUpload.errorData);
          toast.error("403 response unhandled error");
        }

        const { data } = handledUpload;

        if (!data.error) {
          toast.success(data.message);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const base64File = reader.result;
            console.log(base64File);
            onFileUpload(base64File);
            setFileName && setFileName(file.name);
          };

          // Trigger the reader.onload callback
          // reader.onload();
        } else {
          toast.error(data.error);
          if (data.error === "You have exhausted your allowed quota") {
            setQuotaExhausted(true);
          }
          return;
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message);
      }

      return;
    }
    setUploadingPdf(true);

    let formId = searchParams.get("id");

    const { data: isFormAvailable } = await axios.get(
      `/forms?formId=${formId}`
    );
    if (
      isFormAvailable?.forms?.length === 0 ||
      !isFormAvailable?.forms?.[0]?.formJson
    ) {
      await handleCreateForm();
    }

    if (file) {
      let pdfFile: File = file;
      // const pdfBuffer = await pdfFile.arrayBuffer();

      // // Convert PDF buffer to a base64 string
      // let pdfBase64 = arrayBufferToBase64(pdfBuffer);
      // pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;

      try {
        // let formIdWaterMark = `BoloForms Form ID: ${formId}`;
        // console.log(formIdWaterMark, "test")
        // const template = {
        //   schemas: [
        //     {
        //       formId: {
        //         type: "text",
        //         position: {
        //           x: 2.38,
        //           y: 2.38,
        //         },
        //         width: 155.82,
        //         height: 5.47,
        //         fontSize: 8,
        //         fontColor: "#000000",
        //         fontName: "Roboto",
        //       },
        //     },
        //   ],
        //   basePdf: pdfBase64,
        // };
        // const inputs = [
        //   {
        //     formId: "sdfhsoihdfoi",
        //   },
        // ];

        // console.log(inputs, "inputs")
        // console.log(template, "template");

        // let fileWithFormId = await generate({ template, inputs });

        // const fileData = new Uint8Array(fileWithFormId);
        // const blob = new Blob([fileData], { type: "application/pdf" });

        // // Then, create a File from the Blob
        // fileWithFormId = new File([blob], pdfFile.name, {
        //   type: "application/pdf",
        // });

        // fileWithFormId = pdfFile;

        const formData = new FormData();
        formData.append("pdfFile", pdfFile);
        formData.append("formId", formId);
        formData.append("ownerId", owner._id);

        // const { data } = await axios.post(
        //   "/forms/update-pdftemplate",
        //   formData,
        //   {
        //     headers: { "Content-Type": "multipart/form-data" },
        //   }
        // );

        let handledUpload = await detectStatusandBody(
          "/forms/update-pdftemplate",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        console.log({ handledUpload });

        if (handledUpload.error && handledUpload.error === "403-nb") {
          formData.delete("pdfFile");
          formData.append("pdfFile", await copyPdfBytes(pdfFile));
          handledUpload = await detectStatusandBody(
            "/forms/update-pdftemplate",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
        }

        if (handledUpload.error && handledUpload.error !== "403-nb") {
          console.log(handledUpload.error);
          console.log(handledUpload.errorData);
          toast.error("403 response unhandled error");
        }

        const { data } = handledUpload;
        // Handle the response from the backend if needed
        if (!data.error) {
          toast.success(data.message);
          if (
            isFormAvailable?.forms?.length === 0 ||
            !isFormAvailable?.forms?.[0]?.formJson
          ) {
            navigate(`/create/forms/?id=${formId}`);
          }
          const reader = new FileReader();
          reader.readAsDataURL(pdfFile);
          reader.onload = async () => {
            const base64File = reader.result;

            let isTrialExtended = data?.trialExtended?.extended;

            onFileUpload(base64File, isTrialExtended);
            setFileName && setFileName(file.name);
          };

          // Trigger the reader.onload callback
          // reader.onload();
        } else {
          toast.error(data.error);
          if (data.error === "You have exhausted your allowed quota") {
            setQuotaExhausted(true);
          }
          setUploadingPdf(false);
          return;
        }
      } catch (error) {
        // Handle errors if any
        console.error("Error:", error);
        setUploadingPdf(false);
      }
    }
  };

  useEffect(() => {
    if (preUploadedFile) {
      setFile(preUploadedFile);
    }
  }, [preUploadedFile]);

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);

  if (quotaExhausted) {
    return <PlanExhaustedNavBanner />;
  }

  return (
    <div
      className={`mx-auto flex flex-col items-center justify-center w-[100%] md:w-[65%] max-w-[800px] py-2`}
    >
      {!isSignature && !isWithRole && (
        <div className="w-full">
          <h1 className="text-2xl font-bold mb-3 text-gray-700">
            Upload your PDF
          </h1>
          <p className="mb-3 w-full capitalize font-medium">
            Drop your pdf to map your form questions in our pdf editor{" "}
          </p>
        </div>
      )}
      <label
        htmlFor="dropzone-file"
        className={`notranslate flex flex-col items-center justify-center p-8 border-2 border-gray-300 hover:border-gray-500 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-full text-gray-600 transition-all ease-in-out duration-150 ${
          file ? "bg-secondary/80 border-primary/80" : ""
        } `}
        onDrop={(e) => {
          // handleDrop(e);
          handleFileInputChange(e, "drop");
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        {!file && (
          <div className="">
            {!uplaodingFileLoader ? (
              <div className="gap-8 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                <div className="p-2 md:p-4 bg-gray-200 rounded-full">
                  <BiSolidUpArrowCircle size={50} className="text-gray-400" />
                </div>
                <div className="m-0 flex flex-col items-center text-sm md:text-base">
                  <p>
                    <span className="text-primary mr-1 font-medium">
                      Browse
                    </span>{" "}
                    or drag files here
                  </p>
                </div>
              </div>
            ) : (
              <>
                {conversionError ? (
                  <div className="gap-4 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                    <div className="p-2 rounded-full">
                      <MdOutlineError className="text-red-400" size={50} />
                    </div>
                    {uploadingMultipleFiles ? (
                      <div className="m-0 flex justify-between text-center gap-4 md:gap-8 flex-col items-center text-xs md:text-base">
                        <p>
                          Error While Merging your files, Please try this{" "}
                          <a
                            href="https://www.ilovepdf.com/merge_pdf"
                            className="text-primary"
                            target="_blank"
                            rel="noreferrer"
                          >
                            website
                          </a>{" "}
                          for merging files.
                        </p>
                        <button
                          className="px-4 py-1 bg-gray-300 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            setConversionError(false);
                            setUploadingFileLoader(false);
                          }}
                        >
                          Retry
                        </button>
                      </div>
                    ) : (
                      <div className="m-0 flex justify-between text-center gap-4 md:gap-8 flex-col items-center text-xs md:text-base">
                        <p>
                          Error While Converting your file, Please try this{" "}
                          <a
                            href="https://www.ilovepdf.com/word_to_pdf"
                            className="text-primary"
                            target="_blank"
                            rel="noreferrer"
                          >
                            website
                          </a>{" "}
                          for converting your doc
                        </p>
                        <button
                          className="px-4 py-1 bg-gray-300 rounded"
                          onClick={(e) => {
                            e.preventDefault();
                            setConversionError(false);
                            setUploadingFileLoader(false);
                          }}
                        >
                          Retry
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="gap-8 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                    <div className="p-2 md:p-4  rounded-full">
                      <TailSpin
                        height="60"
                        width="60"
                        color={
                          whiteLabelInfo?.settings?.organization?.themeHex
                            ?.primary || COLORS.primary
                        }
                      />
                    </div>
                    <div className="m-0 flex flex-col items-center text-sm md:text-base">
                      <p>Uploading your files.</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {file && (
          <div className="flex flex-row md:flex-col items-center justify-center gap-8 py-8">
            <FaFilePdf size={50} className="text-gray-600" />
            <div className="flex flex-col items-center justify-center">
              {/* <TiTick size={30} className="text-primary" /> */}
              <p className="text-gray-600 text-xs md:text-sm break-all">
                {file?.name}
              </p>
              <p className="text-white text-xs mt-2 font-medium bg-gray-500 px-4 py-1 rounded-md">
                {Math.floor(file?.size / 1024)} KB
              </p>
            </div>
          </div>
        )}
      </label>
      <input
        id="dropzone-file"
        type="file"
        className="appearance-none hidden disabled:opacity-60"
        onClick={(e) => {
          if (isSignature && !isValidReceivers) {
            e.preventDefault();
            toast.error("Please enter at least one receiver email");
          }
        }}
        onChange={handleFileInputChange}
        accept={acceptedMimeTypes.join(", ")}
        multiple
      />
      <div
        className={`mt-2 flex w-full justify-between relative ${
          !isSignature && !isWithRole && "mb-4"
        }`}
      >
        <BoloButton
          variant={"secondary"}
          size="sm"
          className="flex items-center justify-center gap-3 sm:w-[210px]"
          // className="text-primary sm:w-[210px] bg-secondary px-3 sm:px-6 py-2 rounded-md text-xs sm:text-sm flex justify-center gap-2 sm:gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
          onClick={() => setShowSamplePdfModal(true)}
        >
          <>
            <FaCirclePlus className="inline-block text-lg sm:text-xl" />
            <span className="text-primary font-medium">Add Sample Pdf</span>
          </>
        </BoloButton>
        <div className={`self-end flex items-center gap-x-2 sm:gap-x-5  `}>
          <span className="text-b sm:text-lg font-semibold text-gray-500">
            OR
          </span>
          <BoloButton
            variant={"secondary"}
            size="sm"
            className="flex items-center justify-center gap-3 sm:w-[210px]"
            // className="text-primary sm:w-[210px] bg-secondary px-3 sm:px-6 py-2 rounded-md text-xs sm:text-sm flex justify-center gap-2 sm:gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
            onClick={() => {
              setShowDriveDropDown((prev) => !prev);
            }}
          >
            {isDriveFileUploading ? (
              <>
                <TailSpin
                  height="20"
                  width="20"
                  color={
                    whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
                    COLORS.primary
                  }
                />
                <span className="text-primary font-medium">
                  Upload From Drive
                </span>
              </>
            ) : (
              <>
                <IoCloudDownloadSharp className="inline-block text-lg sm:text-xl" />
                <span className="text-primary font-medium">
                  Upload From Drive
                </span>
              </>
            )}
          </BoloButton>
        </div>
        {showDriveDropDown && (
          <div
            ref={dropdownShareOptionsRef}
            className={`absolute ${
              isEmbeded() ? "bottom-[-50px]" : "bottom-[-140px]"
            } right-[-10px] mt-2 border border-gray-300 rounded shadow-lg w-[220px] cursor-pointer bg-white`}
            style={{ zIndex: 100 }}
          >
            <div className="flex flex-col items-start justify-start ">
              <button
                onClick={handleGoogleDriveFilePick}
                className={`flex  items-center text-center text-xs capitalize p-3   gap-2 w-full font-medium hover:bg-primary/20`}
              >
                <img
                  src="https://img.icons8.com/?size=100&id=VLr4hUR8iMGF&format=png&color=000000"
                  alt="google-drive-logo"
                  className="w-[20px] h-[20px]"
                />
                Upload From Google Drive
              </button>
              <button
                onClick={handleOneDriveFilePick}
                className={`flex  items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium hover:bg-primary/20 ${
                  isEmbeded() ? "hidden" : ""
                }`}
              >
                <img
                  src="https://img.icons8.com/?size=100&id=117559&format=png&color=000000"
                  alt="onedrive-logo"
                  className="w-[20px] h-[20px]"
                />
                Upload From OneDrive
              </button>
              <div
                className={`w-full hover:bg-primary/20 ${
                  isEmbeded() ? "hidden" : ""
                }`}
              >
                <DropboxChooser
                  appKey={"qbvzzy8wow4eziq"}
                  success={(files) => handleDropBoxFilePick(files)}
                  cancel={() => {
                    setShowDriveDropDown(false);
                    setIsDriveFileUploading(false);
                  }}
                  multiselect={true}
                  extensions={[".pdf"]}
                  linkType="direct"
                >
                  <button
                    className={`flex flex-row items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium `}
                  >
                    <GrDropbox className="inline-block text-lg sm:text-xl text-blue-600" />
                    Upload From DropBox
                  </button>
                  <div className="dropbox"> </div>
                </DropboxChooser>
              </div>
            </div>
          </div>
        )}
      </div>
      {showOnedriveModal && (
        <OnedriveModal
          setShowOnedriveModal={setShowOnedriveModal}
          ownerEmail={owner?.email}
          isSignature={isSignature}
          onFileUpload={onFileUpload}
          isOnlyPdfTemplate={isOnlyPdfTemplate}
          setFileName={setFileName}
          setIsDriveFileUploading={setIsDriveFileUploading}
          setFile={setFile}
        />
      )}
      {showSamplePdfModal && (
        <SamplePdfModal
          setShowSamplePdfModal={setShowSamplePdfModal}
          handleFileInputChange={handleFileInputChange}
        />
      )}
      {uploadedPdfs.length > 1 && (
        <MultiplePdfUploaded
          uploadedPdfs={uploadedPdfs}
          mergingPdfs={mergingPdfs}
          setUploadedPdfs={setUploadedPdfs}
        />
      )}
      {!isSignature && !isWithRole && (
        <div className="mt-4 flex justify-end gap-4 w-full font-medium">
          <BoloButton
            className="flex justify-center gap-3 items-center w-full"
            variant={"tertiary"}
            onClick={() => {
              window.history.back();
            }}
          >
            <IoArrowBackCircle className="inline-block text-2xl" />
            <span className="">Go Back</span>{" "}
          </BoloButton>

          <BoloButton
            className="flex justify-center gap-3 items-center w-full"
            variant={"primary"}
            onClick={handleBuildForm}
            disabled={!file || (isWithRole && !isRolesAdded) || uploadingPdf}
          >
            <span className="">Continue</span>{" "}
            <IoArrowForwardCircle className="inline-block text-2xl" />
          </BoloButton>
        </div>
      )}

      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          blockingType={upgradeModalType}
        />
      )}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default Uploader;
