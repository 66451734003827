import React, { useState } from "react";
import { BsStripe } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/Common/Dialog/BoloDialog";
import { BoloLoaderLine } from "../../components/Common/Loader/BoloLoader";
import { createConnectedAccountApi } from "../../redux/apis/payments";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import { useAppDispatch } from "../../redux/store";

const PaymentGatewaysModal = ({ isBlocked }: { isBlocked: () => boolean }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConnect = async () => {
    let blocked = isBlocked();
    if (blocked) {
      setOpen(false);
      return;
    }
    setLoading(true);
    try {
      const data = await createConnectedAccountApi({});
      let url = data?.accountLink?.url;
      if (url) {
        window.open(url, "_self");
      }
      dispatch(getOwnerThunk());
      setOpen(false);
    } catch (error) {
      toast.error("Something went worong");
      console.log("error in handleConnect");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        onOpenChange={(value) => {
          setOpen(value);
        }}
        open={open}
      >
        <DialogTrigger asChild>
          <BoloButton
            variant={"primary"}
            className="flex gap-2 items-center"
            size={"xs"}
            onClick={() => {}}
          >
            Set up Payments
          </BoloButton>
        </DialogTrigger>
        <DialogContent className="">
          <DialogHeader className="h-fit">
            <DialogTitle>
              <div>Connect with payment gateway</div>
            </DialogTitle>
          </DialogHeader>
          <div className="h-full">
            <BoloLoaderLine loading={loading} />
            <div className="mt-2">
              Simplify how you make and receive payments by connecting a payment
              gateway through which funds can be transferred directly.{" "}
              <Link
                to="https://help.boloforms.com/en/articles/9403494-how-to-connect-stripe-boloforms"
                className="text-primary"
              >
                Learn more
              </Link>
            </div>
            <div className="flex flex-col gap-2 mt-4">
              <div className="group rounded border border-gray-100 flex items-center justify-between py-2 px-4 cursor-pointer hover:bg-secondary/30 hover:shadow-sm">
                <div className="flex items-center gap-2">
                  <BsStripe size={24} className="text-[#635BFF]" />
                  <p className="text-sm group-hover:text-primary">Stripe</p>
                </div>
                <BoloButton
                  size={"sm"}
                  disabled={loading}
                  variant="bolo-secondary"
                  className="font-semibold"
                  onClick={() => handleConnect()}
                >
                  Connect
                </BoloButton>
              </div>
            </div>
            <div className=" mt-4 text-[10px]">
              Don't see your preferred payment gateway?{" "}
              <Link
                to="https://boloforms.canny.io/feature-requests"
                className="text-primary"
              >
                Send a request
              </Link>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default PaymentGatewaysModal;
