//@ts-nocheck
import React, { useState } from "react";
import "./index.css";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import {
  AiOutlineDelete,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { v4 } from "uuid";
import QuestionBottomOptions from "../../QuestionBottomOptions";
import QuestionInput from "../../QuestionInput";
import QuestionDesc from "../../QuestionDesc";
import { MdDragIndicator, MdClose } from "react-icons/md";
import ImageQuestionPreview from "../../ImageQuestionPreview";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SingleColOptions from "../../../CsvOptionsModal/SingleColOptions";

const RadioGroup = ({ data }) => {
  let {
    question,
    formState,
    setFormState,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
    schemas,
  } = data;
  const isEditor =
    questionIndex.p === activeState.pageIndex &&
    questionIndex.q === activeState.questionIndex;

  const [isShowOtherOptionTextInput, setIsShowOtherOptionTextInput] =
    React.useState(false);

  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);
  const [modalType, setModalType] = useState("");

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    setFormState((prevState) => {
      const newForm = { ...prevState };
      newForm.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            let sortableOptions = question_?.options?.filter(
              (item) => item.enabled && item.type !== "other"
            );
            const draggedOption = sortableOptions.splice(
              result.source.index,
              1
            )[0];
            sortableOptions.splice(result.destination.index, 0, draggedOption);

            question_.options = question_.options.filter(
              (oldOption) => !oldOption.enabled || oldOption.type === "other"
            );
            question_.options.unshift(...sortableOptions);
          }
        });
      });
      return newForm;
    });
  };

  const handleOptionAddDelete = (option, index) => {
    if (option.enabled && option.type === "option") {
      const newOptions = [...question.options];
      newOptions.splice(index, 1);
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.options = newOptions;
            }
          });
        });
        return newState;
      });
    } else if (option.type === "other") {
      const newOptions = [...question.options];
      const otherItemIndex = newOptions.findIndex(
        (item) => item.type === "other"
      );

      if (otherItemIndex !== -1) {
        newOptions[otherItemIndex].enabled = false;
      }
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.options = newOptions;
              question_.hasOtherOption = false;
            }
          });
        });

        return newState;
      });
    } else {
      const newOptions = [...question.options];
      option.text = "Option " + newOptions.length;

      let newOption = {
        type: "option",
        text: `Option ${newOptions.length - 1}`,
        value: "",
        enabled: true,
        goToNextPage: "next",
      };
      newOptions.splice(index, 0, newOption);
      // console.log(newOptions);
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.options = newOptions;
            }
          });
        });
        return newState;
      });
    }
  };

  const handleAddOtherOption = (type) => {
    let options = question.options;
    let index;
    let otherOption = options?.find((option, i) => {
      index = i;
      return option.type === "other";
    });

    if (type === "other") {
      const newOptions = [...question.options];
      newOptions[index].enabled = !otherOption.enabled;
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.options = newOptions;
              question_.hasOtherOption = newOptions[index].enabled;
            }
          });
        });

        return newState;
      });
    } else {
      const newOptions = [...question.options];

      let newOption = {
        type: "option",
        text: `Option ${newOptions.length - 1}`,
        value: "",
        enabled: true,
        goToNextPage: "next",
      };
      newOptions.splice(index - 1, 0, newOption);
      // console.log(newOptions);
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.options = newOptions;
            }
          });
        });
        return newState;
      });
    }
  };

  const setIsShowOtherOption = (question) => {
    let show = false;
    question.options.forEach((option) => {
      if (option.type === "other") {
        show = question.value === option.text;
      }
    });

    setIsShowOtherOptionTextInput(show);
  };
  const addOptions = (data) => {
    const newOptions = [...question.options];
    let index;
    newOptions?.find((option, i) => {
      index = i;
      return option.type === "other";
    });
    // We are not chaning the index evertime, so reverse the array before adding options
    data.reverse().forEach((text) => {
      let option = {
        type: "option",
        text: text.option,
        value: "",
        enabled: true,
        goToNextPage: "next",
      };
      newOptions.splice(index - 1, 0, option);
    });
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.options = newOptions;
          }
        });
      });
      return newState;
    });
  };

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <QuestionInput
            helpers={{
              question,
              setFormState,
              isEditor,
              questionIndex,
              activeState,
            }}
          />
        </div>
        <QuestionDesc
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
      </div>
      <ImageQuestionPreview
        helpers={{ question, setFormState, questionIndex, activeState }}
      />
      <div className="mb-6">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="reorder-list" isDropDisabled={!isEditor}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {question?.options
                  ?.filter((item) => item.enabled && item.type !== "other")
                  ?.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`item-${index}`}
                      index={index}
                      isDragDisabled={!isEditor}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onMouseEnter={() => setHoveredItemIndex(index)}
                          onMouseLeave={() => setHoveredItemIndex(null)}
                          className="flex flex-row items-center relative"
                        >
                          {isEditor && hoveredItemIndex === index && (
                            <div
                              onMouseOver={() => setHoveredItemIndex(index)}
                              className="absolute left-[-20px] top-[11px] flex items-center"
                            >
                              <MdDragIndicator className="text-gray-400 text-[22px]" />
                            </div>
                          )}
                          <div className="h-5 min-w-[20px] mr-3 mb-1 border-2 border-[#00000033] rounded-full"></div>
                          <div className="w-full">
                            <input
                              type="text"
                              value={item.text}
                              placeholder="Option"
                              onChange={(e) => {
                                const newOptions = [...question.options];
                                newOptions[index].text = e.target.value;
                                setFormState((prevState) => {
                                  let newState = { ...prevState };
                                  newState.pages.forEach((page) => {
                                    page.questions.forEach((question_) => {
                                      if (question_.id === question.id) {
                                        question_.options = newOptions;
                                      }
                                    });
                                  });
                                  return newState;
                                });
                              }}
                              className={`${
                                isEditor && "hover:border-gray-200"
                              } option-input w-full text-sm  disabled:bg-transparent text-gray-600 pb-1 border-white outline-none`}
                            />
                            {item.type !== "other" && (
                              <div
                                style={{
                                  visibility: !isEditor ? "hidden" : "visible",
                                }}
                                className="option-input-border"
                              ></div>
                            )}
                          </div>
                          <div
                            style={{
                              visibility: !isEditor ? "hidden" : "visible",
                            }}
                            onClick={() => handleOptionAddDelete(item, index)}
                            className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                          >
                            <MdClose className="text-2xl text-gray-500" />
                          </div>
                          {isEditor &&
                            question?.pageBasedOnAnswer &&
                            item.type === "option" && (
                              <div className="ml-auto flex flex-row">
                                <div className="text-sm px-2 py-1 text-gray-600 rounded-md">
                                  <select
                                    className="text-sm outline-none bg-transparent"
                                    value={item.goToNextPage}
                                    onChange={(e) => {
                                      setFormState((prevState) => {
                                        const newForm = { ...prevState };
                                        newForm.pages.forEach((page) => {
                                          page.questions.forEach(
                                            (question_) => {
                                              if (
                                                question_.id === question.id
                                              ) {
                                                question_.options[
                                                  index
                                                ].goToNextPage = e.target.value;
                                              }
                                            }
                                          );
                                        });
                                        return newForm;
                                      });
                                    }}
                                  >
                                    <option value="next">
                                      Continue to next page
                                    </option>
                                    {formState.pages.map((page, i) => {
                                      if (
                                        // i !== index &&
                                        i !==
                                        formState?.pages?.length - 1
                                      ) {
                                        return (
                                          <option key={i} value={i}>
                                            {page.title}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                    <option value="submit">Submit</option>
                                  </select>
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {question?.options?.find((item) => item.type === "other")?.enabled && (
          <div className="flex flex-row items-center">
            <div className="h-5 min-w-[20px] mr-3 mb-1 border-2 border-[#00000033] rounded-full"></div>
            <div className="w-full">
              <input
                type="text"
                value="Other (describe)"
                placeholder="Option"
                disabled={true}
                className={`border-b border-dotted hover:border-gray-500" ${
                  isEditor && "hover:border-gray-200"
                } option-input w-full text-sm  disabled:bg-transparent text-gray-600 pb-1 border-white outline-none`}
              />
            </div>
            <div
              style={{ visibility: !isEditor ? "hidden" : "visible" }}
              onClick={() =>
                handleOptionAddDelete(
                  question?.options?.find((item) => item.type === "other")
                )
              }
              className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
            >
              <MdClose className="text-2xl text-gray-500" />
            </div>
          </div>
        )}
        {isEditor && (
          <div className="h-[48px] flex flex-row items-center">
            {/* {item.enabled ? (
                <AiOutlineMinusCircle
                  onClick={() => handleOptionAddDelete(item, index)}
                  className="text-red-500 mr-8 cursor-pointer"
                />
              ) : (
                <AiOutlinePlusCircle
                  onClick={() => handleOptionAddDelete(item, index)}
                  className="text-green-600 mr-8 cursor-pointer"
                />
              )} */}
            <div className="flex" onClick={() => handleAddOtherOption("add")}>
              <div className="h-5 min-w-[20px] mr-3 mb-1 border-2 border-[#00000033] rounded-full"></div>
              <div className="w-[85px] text-sm opacity-70  text-gray-600 pb-1 border-b border-white hover:border-gray-200 focus:border-primary outline-none">
                Add Option
              </div>
            </div>
            {!question?.options?.find((item) => item.type === "other")
              ?.enabled && (
              <div className="pb-1.5 flex items-center">
                <span className="ml-1.5 text-sm text-gray-700">or</span>
                <div
                  onClick={() => handleAddOtherOption("other")}
                  className="h-[36px] px-2 text-blue-500 font-medium text-sm rounded-md hover:bg-blue-50 flex items-center"
                >
                  add "Other"
                </div>
              </div>
            )}

            {/* {question?.pageBasedOnAnswer && item.type === "option" && (
            <div className="ml-auto flex flex-row">
              <div className="text-xs mr-4 flex items-center">
                <span className="text-gray-600">Go to</span>
              </div>
              <div className="text-xs px-2 py-1 bg-gray-600 border text-white rounded-md">
                <select
                  className="text-[10px] outline-none bg-transparent"
                  value={item.goToNextPage}
                  onChange={(e) => {
                    setFormState((prevState) => {
                      const newForm = { ...prevState };
                      newForm.pages.forEach((page) => {
                        page.questions.forEach((question_) => {
                          if (question_.id === question.id) {
                            question_.options[index].goToNextPage =
                              e.target.value;
                          }
                        });
                      });
                      return newForm;
                    });
                  }}
                >
                  <option value="next">Next Page</option>
                  {formState.pages.map((page, i) => {
                    if (
                      // i !== index &&
                      i !==
                      formState?.pages?.length - 1
                    ) {
                      return (
                        <option key={i} value={i}>
                          {page.title}
                        </option>
                      );
                    }
                  })}
                  <option value="submit">Submit</option>
                </select>
              </div>
            </div>
          )} */}
          </div>
        )}
        {isEditor && (
          <button
            className="h-[36px] px-2 text-blue-500 font-medium text-sm rounded-md hover:bg-blue-50 flex items-center"
            onClick={() => {
              setModalType("optionsUpload");
            }}
          >
            Add option from csv
          </button>
        )}
      </div>

      <QuestionBottomOptions
        helpers={{
          question,
          setFormState,
          questionIndex,
          activeState,
          setActiveState,
          setActive,
          formState,
          schemas,
        }}
      />
      {modalType === "optionsUpload" && (
        <SingleColOptions setModalType={setModalType} addOptions={addOptions} />
      )}
    </div>
  );
};

export default RadioGroup;
