import React from "react";

const Header = ({ formState }: any) => {
  return (
    <div className="max-w-[700px] mx-auto bg-white h-26 w-full py-2 rounded-md border-2">
      <div className="px-6 flex flex-row items-center">
        <div className="h-full w-24 mr-2">
          <img src={formState.logo} alt="company logo" className="h-full" />
        </div>
        <div className="w-full flex flex-col">
          {formState.title && (
            <h2 className="text-lg font-medium">{formState.title}</h2>
          )}
          {formState.description && (
            <p className="text-xs mt-1">{formState.description}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
