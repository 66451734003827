import React, { useEffect, useState } from "react";
import { CgFileDocument, CgFontHeight, CgFontSpacing } from "react-icons/cg";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaBold, FaItalic, FaPlus, FaTableColumns } from "react-icons/fa6";
import {
  MdFormatAlignCenter,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdRedo,
  MdUndo,
} from "react-icons/md";
import { BoloInput } from "../../../../../components/Common/Input/BoloInput";
import { BoloToggle } from "../../../../../components/Common/Toggle/BoloToggle";
import CustomTooltip from "../../../../../components/EditForm/CustomTooltip";
import {
  ChangeSchemasType,
  DesignerState,
  GlobalHelpersType,
} from "../Designer";
import { SketchPicker } from "react-color";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../../../../components/Common/PopOver/BoloPopOver";
import { IoMdColorFill } from "react-icons/io";
import {
  getFontName,
  handleSchemaFontHeight,
  handleSchemaFontSize,
} from "../../../../../utils/localPdfMe";
import { Size } from "../../../../common/src";
import { VscTextSize } from "react-icons/vsc";
import {
  DEFAULT_FONT_NAME,
  FONT_LIST,
  FONT_NAME_MAP,
  getCSSFont,
} from "../../../../common/src/constants";
import { PiPencilSimpleLineFill } from "react-icons/pi";
import { STATIC_SCHEMA } from "../../constants";
import UniversalUploader from "../../../../../components/Ui/UniversalUploader";
import MixPanel from "../../../../../utils/services/mixpanel";
import UploadAttachment from "../../../../../components/Ui/UploadAttachment";
import PleaseUpgradeModal from "../../../../../components/PleaseUpgradeModal";
import { BoloButton } from "../../../../../components/Common/Button/BoloButton";

export type EditorSubNavbarProps = {
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
  changeSchemas: ChangeSchemasType;
  pageSize: Size;
  past: any[];
  future: any[];
  requiredFieldsNotFilled: any[];
  setActiveElements: React.Dispatch<React.SetStateAction<HTMLElement[]>>;
};

const VerticalDivider = () => {
  return <div className="border-r-2 h-full mx-2.5"></div>;
};

const EditorSubNavbar = (props: EditorSubNavbarProps) => {
  const {
    designerState,
    setDesignerState,
    globalHelpers,
    changeSchemas,
    pageSize,
    past,
    future,
    requiredFieldsNotFilled,
    setActiveElements,
  } = props;
  const ButtonIconSize = 12;
  const activeSchema = globalHelpers.getActiveSchema();
  // const [show, setShow] = useState({
  //   docUploader: false,
  // });
  const [options, setOpitons] = useState({
    textAndTable: false,
    undoredo: true,
  });
  const [nextFieldIndex, setNextFieldIndex] = useState(0);
  // const [fontSize, setFontSize] = useState({
  //   show: false,
  //   sizes: [
  //     10, 11, 12, 13, 14, 15, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60,
  //   ],
  //   changedSize: 0,
  // });
  const fontSizes = [
    10, 11, 12, 13, 14, 15, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60,
  ];
  const lineHeights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const charSpacing = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  // const fontNameMap = {
  //   _: "Roboto",
  //   [DEFAULT_FONT_NAME]: "Roboto",
  //   [DEFAULT_FONT_NAME_BOLD]: "Roboto",
  //   [DEFAULT_FONT_NAME_ITALIC]: "Roboto",
  //   [DEFAULT_FONT_NAME_BOLD_ITALIC]: "Roboto",
  //   [FONT_NAMES.TIMES_NEW_ROMAN.REGULAR]: "Times New Roman",
  //   [FONT_NAMES.TIMES_NEW_ROMAN.BOLD]: "Times New Roman",
  //   [FONT_NAMES.TIMES_NEW_ROMAN.ITALIC]: "Times New Roman",
  //   [FONT_NAMES.TIMES_NEW_ROMAN.BOLD_ITALIC]: "Times New Roman",
  //   [FONT_NAMES.ARIAL.REGULAR]: "Arial",
  //   [FONT_NAMES.ARIAL.BOLD]: "Arial",
  //   [FONT_NAMES.ARIAL.ITALIC]: "Arial",
  //   [FONT_NAMES.ARIAL.BOLD_ITALIC]: "Arial",
  //   [FONT_NAMES.GEORGIA.REGULAR]: "Georgia",
  //   [FONT_NAMES.GEORGIA.BOLD]: "Georgia",
  //   [FONT_NAMES.GEORGIA.ITALIC]: "Georgia",
  //   [FONT_NAMES.GEORGIA.BOLD_ITALIC]: "Georgia",
  //   [FONT_NAMES.HELVETICA.REGULAR]: "Helvetica",
  //   [FONT_NAMES.HELVETICA.BOLD]: "Helvetica",
  //   [FONT_NAMES.HELVETICA.ITALIC]: "Helvetica",
  //   [FONT_NAMES.HELVETICA.BOLD_ITALIC]: "Helvetica",
  //   [FONT_NAMES.SPACE_MONO.REGULAR]: "Monospace",
  //   [FONT_NAMES.SPACE_MONO.BOLD]: "Monospace",
  //   [FONT_NAMES.SPACE_MONO.ITALIC]: "Monospace",
  //   [FONT_NAMES.SPACE_MONO.BOLD_ITALIC]: "Monospace",
  // };
  // const fonts = [
  //   {
  //     name: "Arial",
  //     genFont: FONT_NAMES.ARIAL.REGULAR,
  //     previewFont: getCSSFont({ currFont: FONT_NAMES.ARIAL.REGULAR }),
  //   },
  //   {
  //     name: "Georgia",
  //     genFont: FONT_NAMES.GEORGIA.REGULAR,
  //     previewFont: getCSSFont({ currFont: FONT_NAMES.GEORGIA.REGULAR }),
  //   },
  //   {
  //     name: "Helvetica",
  //     genFont: FONT_NAMES.HELVETICA.REGULAR,
  //     previewFont: getCSSFont({ currFont: FONT_NAMES.HELVETICA.REGULAR }),
  //   },
  //   {
  //     name: "Monospace",
  //     genFont: FONT_NAMES.SPACE_MONO.REGULAR,
  //     previewFont: getCSSFont({ currFont: FONT_NAMES.SPACE_MONO.REGULAR }),
  //   },
  //   {
  //     name: "Roboto",
  //     genFont: DEFAULT_FONT_NAME,
  //     previewFont: getCSSFont({ currFont: DEFAULT_FONT_NAME }),
  //   },
  //   {
  //     name: "Times New Roman",
  //     genFont: FONT_NAMES.TIMES_NEW_ROMAN.REGULAR,
  //     previewFont: getCSSFont({ currFont: FONT_NAMES.TIMES_NEW_ROMAN.REGULAR }),
  //   },
  // ];

  function toggleMiniPdf() {
    setDesignerState((prev) => ({
      ...prev,
      miniPdf: { ...prev.miniPdf, open: !prev.miniPdf.open },
    }));
  }
  const nextActiveElement = () => {
    // let schemas = designerState?.schemasList?.flat();
    let index = -1;
    let schemaId = "";

    for (let schemasIdx in designerState?.schemasList) {
      for (let schema of designerState?.schemasList[schemasIdx]) {
        if (
          schema?.subType === "radiogroup" &&
          requiredFieldsNotFilled[nextFieldIndex] === schema.radiogroupId
        ) {
          index = parseInt(schemasIdx);
          schemaId = schema.id;

          // return;
        } else if (requiredFieldsNotFilled[nextFieldIndex] === schema.id) {
          schemaId = schema.id;
          index = parseInt(schemasIdx);
        }
      }
    }

    if (index !== -1) {
      globalHelpers.setPageIndex(index);
      let activeElement = document.getElementById(schemaId);
      if (activeElement) {
        setActiveElements([activeElement]);
      }
      return;
    }
  };
  useEffect(() => {
    setOpitons((prev) => ({
      ...prev,
      textAndTable:
        activeSchema?.type == "text" ||
        activeSchema?.subType == "table" ||
        activeSchema?.subType == "pricing_table",
      undoredo: !(
        activeSchema?.type == "text" ||
        activeSchema?.subType == "table" ||
        activeSchema?.subType == "pricing_table"
      ),
    }));
    return () => {};
  }, [activeSchema]);
  useEffect(() => {
    setNextFieldIndex(0);
  }, [requiredFieldsNotFilled]);
  return (
    <div className="bg-white shadow h-9 z-[5] flex items-center px-4 py-1 text-sm text-gray-600">
      <button
        data-tooltip-id="btn-show-pages"
        className={`p-1 px-2 rounded bg-gray-100 hover:bg-gray-200 text-gray-500 flex items-center gap-2 ${
          designerState.miniPdf.open && "text-primary"
        }`}
        onClick={() => toggleMiniPdf()}
      >
        <CgFileDocument size={18} className={``} />
        <span className="font-bold text-xs">
          {designerState?.schemasList.length}
        </span>
      </button>
      <CustomTooltip
        helpers={{
          id: "btn-show-pages",
          content: designerState?.miniPdf?.open ? "Hide Pages" : "Show Pages",
          place: "bottom",
        }}
      />
      <button
        data-tooltip-id="btn-add-document"
        className={`${
          designerState?.mode === "EDITING" ? "" : "hidden"
        } ml-2 p-1 px-2 rounded hover:bg-gray-100 text-gray-500 flex items-center gap-2 group`}
        onClick={() => {
          if (designerState?.instanceFrom === "PDF") {
            new MixPanel().track("PDF", {
              pdf_action: `pdf_editor_add_document`,
              paid: false,
            });
            new MixPanel().increment(`pdf_editor_add_document`);
          } else if (designerState?.instanceFrom === "PDF-TEMPLATE") {
            new MixPanel().track("PDF template", {
              pdf_template_action: `pdf_template_editor_add_document`,
              paid: false,
            });
            new MixPanel().increment(`pdf_template_editor_add_document`);
          }
          setDesignerState((prev) => ({
            ...prev,
            addDocument: { ...prev.addDocument, show: true },
          }));
        }}
      >
        <FaPlus size={18} className={`group-hover:text-primary`} />
        <span className="font-bold text-xs">Document</span>
      </button>
      <CustomTooltip
        helpers={{
          id: "btn-add-document",
          content: "Add Files",
          place: "bottom",
        }}
      />
      {designerState?.addDocument?.show && (
        <UniversalUploader
          setLink={(link) => {}}
          setShow={(value) =>
            setDesignerState((prev) => ({
              ...prev,
              addDocument: { ...prev.addDocument, show: value },
            }))
          }
          handleFile={(file) =>
            globalHelpers.PDF.addDoc(
              designerState?.addDocument?.pageNo || 0,
              file
            )
          }
        />
      )}
      {designerState?.addAttachment?.show && (
        <UploadAttachment
          setShow={(value) =>
            setDesignerState((prev) => ({
              ...prev,
              addAttachment: { ...prev.addAttachment, show: value },
            }))
          }
          attachmentFiles={
            designerState?.fetchedData?.document?.settings?.attachments
          }
          pageNo={designerState?.addAttachment?.pageNo}
          isDefault={false}
          globalHelpers={globalHelpers}
        />
      )}
      {designerState?.showUpgradeModal?.show && (
        <PleaseUpgradeModal
          show={designerState?.showUpgradeModal?.show}
          setShow={(value: any) => {
            setDesignerState((prev) => ({
              ...prev,
              showUpgradeModal: { ...prev.showUpgradeModal, show: value },
            }));
          }}
          blockingType={designerState?.showUpgradeModal?.type}
        />
      )}
      <VerticalDivider />
      {options.undoredo && designerState?.mode === "EDITING" && (
        <>
          <button
            data-tooltip-id="btn-undo-document"
            className={`p-1 rounded hover:bg-gray-100 text-gray-500 flex items-center gap-2 group disabled:opacity-60`}
            onClick={() => globalHelpers.timeTravel("undo")}
            disabled={!past.length}
          >
            <MdUndo size={18} className={`group-hover:text-primary`} />
          </button>
          <CustomTooltip
            helpers={{
              id: "btn-undo-document",
              content: "Undo Ctrl/Cmd + Z",
              place: "bottom",
            }}
          />
          <button
            data-tooltip-id="btn-redo-document"
            className={`p-1 rounded hover:bg-gray-100 text-gray-500 flex items-center gap-2 group disabled:opacity-60`}
            onClick={() => globalHelpers.timeTravel("redo")}
            disabled={!future.length}
          >
            <MdRedo size={18} className={`group-hover:text-primary`} />
          </button>
          <CustomTooltip
            helpers={{
              id: "btn-redo-document",
              content: "Redo  Ctrl/Cmd + Y",
              place: "bottom",
            }}
          />
        </>
      )}
      {(activeSchema?.type == "text" || activeSchema?.type == "table") &&
        designerState?.mode === "EDITING" && (
          <>
            {(activeSchema?.subType == "table" ||
              activeSchema?.type == "text") && (
              <>
                <div className="flex gap-0 items-center h-full">
                  {/* {activeSchema?.type == "text" && ( */}
                  <BoloPopover>
                    <BoloPopoverTrigger>
                      <div
                        className="flex items-center gap-1"
                        data-tooltip-id="btn-font-type"
                      >
                        {/* <VscTextSize size={ButtonIconSize + 4} /> */}
                        <div className="w-40 h-6 text-xs flex items-center justify-start">
                          <div
                            className="flex items-center outline-none px-1.5 py-0.5 w-40 h-6 hover:bg-secondary/30 active:bg-secondary hover:text-primary active:text-primary rounded-md"
                            style={{
                              fontFamily: getCSSFont({
                                currFont: activeSchema?.fontName,
                              }),
                            }}
                          >
                            {FONT_NAME_MAP[activeSchema?.fontName || "_"]}
                          </div>
                        </div>
                        <CustomTooltip
                          helpers={{
                            id: "btn-font-type",
                            content: "Font Type",
                            place: "top",
                          }}
                        />
                      </div>
                    </BoloPopoverTrigger>
                    <BoloPopoverContent align="start">
                      <div
                        className="rounded h-40 overflow-y-auto w-40 bg-white z-[3030] text-gray-600"
                        id="mini-pdf-custom-scroll"
                      >
                        {FONT_LIST.map((font) => {
                          return (
                            <div
                              className={`px-1.5 py-1 text-xs flex items-center justify-start cursor-pointer hover:bg-secondary/30 hover:text-primary  ${
                                (activeSchema?.fontName?.includes(
                                  font.genFont
                                ) ||
                                  (!activeSchema?.fontName &&
                                    font.genFont == DEFAULT_FONT_NAME)) &&
                                "bg-secondary text-primary"
                              }`}
                              style={{
                                fontFamily: font.previewFont,
                              }}
                              onClick={() => {
                                changeSchemas([
                                  {
                                    key: "fontName",
                                    value: getFontName({
                                      bold: !!activeSchema?.bold,
                                      italic: !!activeSchema?.italic,
                                      currFont: font.genFont || "",
                                    }),
                                    schemaId: activeSchema?.id,
                                  },
                                ]);
                              }}
                            >
                              {font?.name}
                            </div>
                          );
                        })}
                      </div>
                    </BoloPopoverContent>
                  </BoloPopover>
                  {/* )} */}
                  <BoloPopover>
                    <BoloPopoverTrigger>
                      <div
                        className="flex items-center gap-1"
                        data-tooltip-id="btn-font-size"
                      >
                        <VscTextSize size={ButtonIconSize + 4} />
                        <div className="w-10 h-6 text-xs flex items-center justify-start ">
                          <input
                            className="outline-none px-1.5 py-0.5 w-10 h-6 hover:bg-secondary/30 active:bg-secondary hover:text-primary active:text-primary rounded-md"
                            value={activeSchema?.fontSize}
                            // type="number"
                            onChange={(e) => {
                              const fontSize = Number(e.target.value);
                              if (isNaN(fontSize)) {
                                return;
                              }
                              handleSchemaFontSize({
                                fontSize: fontSize,
                                activeSchema: activeSchema,
                                changeSchemas: changeSchemas,
                                pageSize,
                              });
                            }}
                          />
                        </div>
                        <CustomTooltip
                          helpers={{
                            id: "btn-font-size",
                            content: "Font Size",
                            place: "top",
                          }}
                        />
                      </div>
                    </BoloPopoverTrigger>
                    <BoloPopoverContent align="end">
                      <div
                        className="rounded h-40 overflow-y-auto w-11 bg-white z-[3030] text-gray-600"
                        id="mini-pdf-custom-scroll"
                      >
                        {fontSizes.map((sz: number) => {
                          return (
                            <div
                              className={`px-1.5 py-1 text-xs flex items-center justify-start cursor-pointer hover:bg-secondary/30 hover:text-primary  ${
                                sz == activeSchema?.fontSize &&
                                "bg-secondary text-primary"
                              }`}
                              onClick={() => {
                                handleSchemaFontSize({
                                  fontSize: sz,
                                  activeSchema: activeSchema,
                                  changeSchemas: changeSchemas,
                                  pageSize,
                                });
                              }}
                            >
                              {sz}
                            </div>
                          );
                        })}
                      </div>
                    </BoloPopoverContent>
                  </BoloPopover>
                  <BoloPopover>
                    <BoloPopoverTrigger>
                      <div
                        className="flex items-center gap-1"
                        data-tooltip-id="btn-line-height"
                      >
                        <CgFontHeight size={ButtonIconSize + 4} />
                        <div className="w-10 h-6 text-xs flex items-center justify-start">
                          <input
                            className="outline-none px-1.5 py-0.5 w-10 h-6 hover:bg-secondary/30 active:bg-secondary hover:text-primary active:text-primary rounded-md"
                            value={activeSchema?.lineHeight}
                            // type="number"
                            onChange={(e) => {
                              const lineHeight = Number(e.target.value);
                              if (isNaN(lineHeight)) {
                                return;
                              }
                              handleSchemaFontHeight({
                                lineHeight,
                                activeSchema: activeSchema,
                                changeSchemas: changeSchemas,
                                pageSize,
                              });
                            }}
                          />
                        </div>
                        <CustomTooltip
                          helpers={{
                            id: "btn-line-height",
                            content: "Line Height",
                            place: "top",
                          }}
                        />
                      </div>
                    </BoloPopoverTrigger>
                    <BoloPopoverContent align="end">
                      <div
                        className="rounded h-40 overflow-y-auto w-11 bg-white z-[3030] text-gray-600"
                        id="mini-pdf-custom-scroll"
                      >
                        {lineHeights?.map((sz: number) => {
                          return (
                            <div
                              className={`px-1.5 py-1 text-xs flex items-center justify-start cursor-pointer hover:bg-secondary/30 hover:text-primary  ${
                                sz == activeSchema?.lineHeight &&
                                "bg-secondary text-primary"
                              }`}
                              onClick={() => {
                                handleSchemaFontHeight({
                                  lineHeight: sz,
                                  activeSchema: activeSchema,
                                  changeSchemas: changeSchemas,
                                  pageSize,
                                });
                              }}
                            >
                              {sz}
                            </div>
                          );
                        })}
                      </div>
                    </BoloPopoverContent>
                  </BoloPopover>
                  <BoloPopover>
                    <BoloPopoverTrigger>
                      <div
                        className="flex items-center gap-1"
                        data-tooltip-id="btn-font-space"
                      >
                        <CgFontSpacing size={ButtonIconSize + 4} />
                        <div className="w-10 h-6 text-xs flex items-center justify-start">
                          <input
                            className="outline-none px-1.5 py-0.5 w-10 h-6 hover:bg-secondary/30 active:bg-secondary hover:text-primary active:text-primary rounded-md"
                            value={activeSchema?.characterSpacing}
                            // type="number"
                            onChange={(e) => {
                              const currentCharacterSpacing = Number(
                                e.target.value
                              );
                              if (isNaN(currentCharacterSpacing)) {
                                return;
                              }
                              changeSchemas([
                                {
                                  key: "characterSpacing",
                                  value: currentCharacterSpacing,
                                  schemaId: activeSchema?.id,
                                },
                              ]);
                            }}
                          />
                        </div>
                        <CustomTooltip
                          helpers={{
                            id: "btn-font-space",
                            content: "Character Spacing",
                            place: "top",
                          }}
                        />
                      </div>
                    </BoloPopoverTrigger>
                    <BoloPopoverContent align="end">
                      <div
                        className="rounded h-40 overflow-y-auto w-11 bg-white z-[3030] text-gray-600"
                        id="mini-pdf-custom-scroll"
                      >
                        {charSpacing?.map((sz: number) => {
                          return (
                            <div
                              className={`px-1.5 py-1 text-xs flex items-center justify-start cursor-pointer hover:bg-secondary/30 hover:text-primary   ${
                                sz == activeSchema?.characterSpacing &&
                                "bg-secondary text-primary"
                              }`}
                              onClick={() => {
                                changeSchemas([
                                  {
                                    key: "characterSpacing",
                                    value: sz,
                                    schemaId: activeSchema?.id,
                                  },
                                ]);
                              }}
                            >
                              {sz}
                            </div>
                          );
                        })}
                      </div>
                    </BoloPopoverContent>
                  </BoloPopover>
                </div>
                <VerticalDivider />
              </>
            )}
            <div className="flex gap-2 items-center h-full">
              <BoloPopover>
                <BoloPopoverTrigger>
                  <button
                    data-tooltip-id="btn-text-color"
                    className="h-5 w-5 flex items-center justify-center text-sm"
                    style={{
                      borderBottom: `4px solid ${
                        activeSchema?.fontColor || "black"
                      }`,
                    }}
                    onClick={() => {
                      // setColorPicker((prev) => !prev);
                    }}
                  >
                    A
                    <CustomTooltip
                      helpers={{
                        id: "btn-text-color",
                        content: "Text Color",
                        place: "top",
                      }}
                    />
                  </button>
                </BoloPopoverTrigger>
                <BoloPopoverContent>
                  <SketchPicker
                    color={activeSchema?.fontColor}
                    onChange={(e) => {
                      // console.log(e);
                      changeSchemas([
                        {
                          key: "fontColor",
                          value: e.hex,
                          schemaId: activeSchema?.id,
                        },
                      ]);
                    }}
                  />
                </BoloPopoverContent>
              </BoloPopover>
              {activeSchema?.type == "text" && (
                <BoloPopover>
                  <BoloPopoverTrigger>
                    <button
                      data-tooltip-id="btn-background-color"
                      className="h-5 w-5 flex items-center justify-center text-sm"
                      style={{
                        borderBottom: `4px solid ${
                          activeSchema?.backgroundColor || "white"
                        }`,
                      }}
                      onClick={() => {
                        // setColorPicker((prev) => !prev);
                      }}
                    >
                      <IoMdColorFill />
                      <CustomTooltip
                        helpers={{
                          id: "btn-background-color",
                          content: "Background Color",
                          place: "top",
                        }}
                      />
                    </button>
                  </BoloPopoverTrigger>
                  <BoloPopoverContent>
                    <SketchPicker
                      color={activeSchema?.backgroundColor}
                      onChange={(e) => {
                        // console.log(e);
                        changeSchemas([
                          {
                            key: "backgroundColor",
                            value: e.hex,
                            schemaId: activeSchema?.id,
                          },
                        ]);
                      }}
                    />
                  </BoloPopoverContent>
                </BoloPopover>
              )}
              {activeSchema?.type == "table" && (
                <>
                  <BoloPopover>
                    <BoloPopoverTrigger>
                      <button
                        data-tooltip-id="btn-header-text-color"
                        className="h-5 w-5 flex items-center justify-center text-sm"
                        style={{
                          borderBottom: `4px solid ${
                            activeSchema?.headStyle?.fontColor || "black"
                          }`,
                        }}
                        onClick={() => {
                          // setColorPicker((prev) => !prev);
                        }}
                      >
                        H
                        <CustomTooltip
                          helpers={{
                            id: "btn-header-text-color",
                            content: "Header Text Color",
                            place: "top",
                          }}
                        />
                      </button>
                    </BoloPopoverTrigger>
                    <BoloPopoverContent>
                      <SketchPicker
                        color={activeSchema?.headStyle?.fontColor}
                        onChange={(e) => {
                          // console.log(e);
                          changeSchemas([
                            {
                              key: "headStyle",
                              value: {
                                ...activeSchema?.headStyle,
                                fontColor: e.hex,
                              },
                              schemaId: activeSchema?.id,
                            },
                          ]);
                        }}
                      />
                    </BoloPopoverContent>
                  </BoloPopover>
                  <BoloPopover>
                    <BoloPopoverTrigger>
                      <button
                        data-tooltip-id="btn-border-color"
                        className="h-5 w-5 flex items-center justify-center text-sm"
                        style={{
                          borderBottom: `4px solid ${
                            activeSchema?.tableBorderColor || "black"
                          }`,
                        }}
                        onClick={() => {
                          // setColorPicker((prev) => !prev);
                        }}
                      >
                        <PiPencilSimpleLineFill />
                        <CustomTooltip
                          helpers={{
                            id: "btn-border-color",
                            content: "Border Color",
                            place: "top",
                          }}
                        />
                      </button>
                    </BoloPopoverTrigger>
                    <BoloPopoverContent>
                      <SketchPicker
                        color={activeSchema?.tableBorderColor}
                        onChange={(e) => {
                          // console.log(e);
                          changeSchemas([
                            {
                              key: "tableBorderColor",
                              value: e.hex,
                              schemaId: activeSchema?.id,
                            },
                          ]);
                        }}
                      />
                    </BoloPopoverContent>
                  </BoloPopover>
                  <BoloPopover>
                    <BoloPopoverTrigger>
                      <button
                        data-tooltip-id="btn-header-color"
                        className="h-5 w-5 flex items-center justify-center text-sm"
                        style={{
                          borderBottom: `4px solid ${
                            activeSchema?.headStyle.backgroundColor ||
                            STATIC_SCHEMA.color
                          }`,
                        }}
                        onClick={() => {
                          // setColorPicker((prev) => !prev);
                        }}
                      >
                        <FaTableColumns />
                        <CustomTooltip
                          helpers={{
                            id: "btn-header-color",
                            content: "Header Color",
                            place: "top",
                          }}
                        />
                      </button>
                    </BoloPopoverTrigger>
                    <BoloPopoverContent>
                      <SketchPicker
                        color={activeSchema?.headStyle.backgroundColor}
                        onChange={(e) => {
                          // console.log(e);
                          changeSchemas([
                            {
                              key: "headStyle",
                              value: {
                                ...activeSchema?.headStyle,
                                backgroundColor: e.hex,
                              },
                              schemaId: activeSchema?.id,
                            },
                          ]);
                        }}
                      />
                    </BoloPopoverContent>
                  </BoloPopover>
                </>
              )}
            </div>
            <VerticalDivider />
            {(activeSchema?.subType == "table" ||
              activeSchema?.type == "text") && (
              <>
                <div className="flex gap-2 items-center h-full">
                  <BoloToggle
                    size={"xs"}
                    data-tooltip-id="toggle-text-align-left"
                    // defaultPressed={activeSchema?.bold}
                    pressed={activeSchema?.alignment == "left"}
                    onPressedChange={(value: boolean) => {
                      changeSchemas([
                        {
                          key: "alignment",
                          value: "left",
                          schemaId: activeSchema?.id,
                        },
                      ]);
                    }}
                  >
                    <MdFormatAlignLeft size={ButtonIconSize + 2} />
                    <CustomTooltip
                      helpers={{
                        id: "toggle-text-align-left",
                        content: "Text Left Align",
                        place: "top",
                      }}
                    />
                  </BoloToggle>
                  <BoloToggle
                    size={"xs"}
                    data-tooltip-id="toggle-text-align-center"
                    // defaultPressed={activeSchema?.bold}
                    pressed={activeSchema?.alignment == "center"}
                    onPressedChange={(value: boolean) => {
                      changeSchemas([
                        {
                          key: "alignment",
                          value: "center",
                          schemaId: activeSchema?.id,
                        },
                      ]);
                    }}
                  >
                    <MdFormatAlignCenter size={ButtonIconSize + 2} />
                    <CustomTooltip
                      helpers={{
                        id: "toggle-text-align-center",
                        content: "Text Center Align",
                        place: "top",
                      }}
                    />
                  </BoloToggle>
                  <BoloToggle
                    size={"xs"}
                    data-tooltip-id="toggle-text-align-right"
                    // defaultPressed={activeSchema?.bold}
                    pressed={activeSchema?.alignment == "right"}
                    onPressedChange={(value: boolean) => {
                      changeSchemas([
                        {
                          key: "alignment",
                          value: "right",
                          schemaId: activeSchema?.id,
                        },
                      ]);
                    }}
                  >
                    <MdFormatAlignRight size={ButtonIconSize + 2} />
                    <CustomTooltip
                      helpers={{
                        id: "toggle-text-align-right",
                        content: "Text Right Align",
                        place: "top",
                      }}
                    />
                  </BoloToggle>
                </div>
                <VerticalDivider />
              </>
            )}
            {activeSchema?.type == "text" && (
              <>
                <div className="flex gap-2 items-center h-full">
                  <BoloToggle
                    size={"xs"}
                    data-tooltip-id="toggle-bold"
                    // defaultPressed={activeSchema?.bold}
                    pressed={activeSchema?.bold}
                    onPressedChange={(value: boolean) => {
                      changeSchemas([
                        {
                          key: "bold",
                          value: value,
                          schemaId: activeSchema?.id,
                        },
                        {
                          key: "fontName",
                          value: getFontName({
                            bold: value,
                            italic: !!activeSchema?.italic,
                            currFont: activeSchema?.fontName || "",
                          }),
                          schemaId: activeSchema?.id,
                        },
                      ]);
                    }}
                  >
                    <FaBold size={ButtonIconSize} />
                    <CustomTooltip
                      helpers={{
                        id: "toggle-bold",
                        content: "Bold",
                        place: "top",
                      }}
                    />
                  </BoloToggle>
                  <BoloToggle
                    size={"xs"}
                    data-tooltip-id="toggle-italic"
                    // defaultPressed={activeSchema?.italic}
                    pressed={activeSchema?.italic}
                    onPressedChange={(value: boolean) => {
                      changeSchemas([
                        {
                          key: "italic",
                          value: value,
                          schemaId: activeSchema?.id,
                        },
                        {
                          key: "fontName",
                          value: getFontName({
                            bold: !!activeSchema?.bold,
                            italic: value,
                            currFont: activeSchema?.fontName || "",
                          }),
                          schemaId: activeSchema?.id,
                        },
                      ]);
                    }}
                  >
                    <FaItalic size={ButtonIconSize} />
                    <CustomTooltip
                      helpers={{
                        id: "toggle-italic",
                        content: "Italic",
                        place: "top",
                      }}
                    />
                  </BoloToggle>
                  {activeSchema?.isStatic && (
                    <>
                      {" "}
                      <BoloToggle
                        size={"xs"}
                        data-tooltip-id="toggle-link"
                        defaultPressed={activeSchema?.isLink}
                        pressed={activeSchema?.isLink}
                        onPressedChange={(value: boolean) => {
                          changeSchemas([
                            {
                              key: "isLink",
                              value: value,
                              schemaId: activeSchema?.id,
                            },
                          ]);
                        }}
                      >
                        <FaExternalLinkAlt size={ButtonIconSize} />
                        <CustomTooltip
                          helpers={{
                            id: "toggle-link",
                            content: "Add HyperLink",
                            place: "top",
                          }}
                        />
                      </BoloToggle>
                      {activeSchema?.isLink && (
                        <BoloInput
                          className="ml-2 max-w-[190px] w-[190px]"
                          bolosize={"xs"}
                          placeholder={"e.g https://www.boloforms.com/"}
                          value={activeSchema?.hyperLink || ""}
                          onChange={(e) => {
                            changeSchemas([
                              {
                                key: "hyperLink",
                                value: e.target.value,
                                schemaId: activeSchema?.id,
                              },
                            ]);
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
                <VerticalDivider />
              </>
            )}
          </>
        )}
      {requiredFieldsNotFilled?.length > 0 && activeSchema === undefined && (
        <div className="flex mx-auto gap-3 items-center">
          <div className="text-sm">
            You have&nbsp;
            <span className="font-semibold">
              {requiredFieldsNotFilled?.length} required fields
            </span>
            &nbsp;to fill out.
          </div>
          <BoloButton
            size="xs"
            variant={"yellow"}
            onClick={() => {
              if (nextFieldIndex < requiredFieldsNotFilled.length - 1) {
                setNextFieldIndex((prev) => prev + 1);
              } else {
                setNextFieldIndex(0);
              }
              nextActiveElement();
            }}
          >
            Next
          </BoloButton>
        </div>
      )}
    </div>
  );
};

export default EditorSubNavbar;
