import React, { useEffect, useState } from "react";
import { DesignerState, GlobalHelpersType } from "./Designer";
import { PreviewStateType } from "./Preview";
import {
  downloadFilePrivate,
  fileUploadType,
  formatFileSize,
} from "./Designer/PdfTemplateSidebar/AttachmentsView";
import {
  BoloPopover,
  BoloPopOverClose,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../../../components/Common/PopOver/BoloPopOver";
import { BsThreeDotsVertical } from "react-icons/bs";
import { LuDownload } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import getS3PreSignedUrl from "../../../../utils/getS3PreSignedUrl";
import axios from "../../../../utils/axios";
import { toast } from "react-toastify";
import { CgAttachment } from "react-icons/cg";
import CustomTooltip from "../../../../components/EditForm/CustomTooltip";
import PaperSeperator from "./PaperSeperator";

const AttachmentInPaper = ({
  pageNo,
  designerState,
  setDesignerState,
  globalHelpers,
  previewState,
  totalPages,
  paperSize,
}: {
  pageNo: number;
  designerState?: DesignerState;
  globalHelpers?: GlobalHelpersType;
  setDesignerState?: React.Dispatch<React.SetStateAction<DesignerState>>;
  previewState?: PreviewStateType;
  totalPages: number;
  paperSize: { width: number; height: number };
}) => {
  const [attachments, setAttachments] = useState<fileUploadType[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [attachmentsToShow, setAttachmentsToShow] = useState<fileUploadType[]>(
    []
  );
  const [isPreview, setIsPreview] = useState<boolean>(false);

  useEffect(() => {
    let attachments = [];
    if (previewState) {
      attachments = previewState?.settings?.attachments;
      setAttachments(previewState?.settings?.attachments);
      setIsPreview(true);
    } else {
      attachments = designerState?.fetchedData?.document?.settings?.attachments;
      setAttachments(
        designerState?.fetchedData?.document?.settings?.attachments
      );
      setIsPreview(false);
    }
    if (pageNo === totalPages) {
      let filteredAttachments = attachments?.filter(
        (attachment: fileUploadType) =>
          attachment?.pageNo === undefined ||
          attachment?.pageNo === pageNo ||
          attachment?.pageNo > pageNo
      );
      setAttachmentsToShow(filteredAttachments);
      setShow(filteredAttachments?.length > 0);
    } else if (pageNo < totalPages) {
      let filteredAttachments = attachments?.filter(
        (attachment: fileUploadType) => attachment?.pageNo === pageNo
      );
      setAttachmentsToShow(filteredAttachments);
      setShow(filteredAttachments?.length > 0);
    }
  }, [designerState]);
  if (!show) {
    return <></>;
  }
  return (
    <div
      className={`flex flex-col justify-center items-center ${
        !isPreview && "mb-4"
      }`}
    >
      {attachmentsToShow?.map((attachment: fileUploadType, index: number) => (
        <div
          className={` group border-b flex justify-between items-center hover:bg-slate-100 hover:text-primary  px-3 bg-white my-2 shadow ${
            isPreview && "mb-4"
          }`}
          style={{ width: paperSize?.width }}
          key={index}
        >
          <div className="flex py-2 gap-2 items-center">
            <div>
              <CgAttachment
                className={`text-gray-500 text-2xl font-bold cursor-pointer `}
              />
            </div>
            <div>
              <div className="font-semibold text-sm text-ellipsis overflow-hidden">
                {attachment?.fileName}
              </div>
              <div className="flex text-xs  py-1 gap-1  items-center">
                <span className="text-gray-600 text-semibold">
                  {formatFileSize(attachment?.fileSize)}
                </span>
              </div>
            </div>
          </div>
          <div className="pt-2">
            {!isPreview ? (
              <BoloPopover>
                <BoloPopoverTrigger>
                  <button className="text-gray-400 hover:text-gray-500 mt-1  group-hover:inline-block hidden">
                    <BsThreeDotsVertical size={18} />
                  </button>
                </BoloPopoverTrigger>
                <BoloPopoverContent align="end">
                  <div className="flex flex-col gap-2 text-gray-400 text-xs rounded-lg bg-white w-fit p-2">
                    <BoloPopOverClose>
                      <button
                        className="hover:text-black flex gap-3 p-1 items-center w-full"
                        onClick={async (e) => {
                          e.stopPropagation();
                          await downloadFilePrivate(
                            attachment?.fileUrl,
                            attachment?.fileName
                          );
                        }}
                      >
                        <LuDownload size={18} />
                        Download
                      </button>
                    </BoloPopOverClose>
                    <BoloPopOverClose>
                      <button
                        className="hover:text-red-500 disabled:cursor-not-allowed disabled:opacity-100 flex gap-3 p-1 items-centerm w-full"
                        onClick={async () => {
                          let removeAttachmentId = attachment?.fileId;
                          const newAttachments = attachments?.filter(
                            (attachment) =>
                              attachment?.fileId !== removeAttachmentId
                          );
                          await globalHelpers?.updateDocumentAttachments(
                            newAttachments
                          );
                        }}
                      >
                        <MdDelete size={20} />
                        Delete attachment
                      </button>
                    </BoloPopOverClose>
                  </div>
                </BoloPopoverContent>
              </BoloPopover>
            ) : (
              <>
                <button
                  className="group-hover:inline-block hidden cursor-pointer "
                  onClick={async (e) => {
                    e.stopPropagation();
                    downloadFilePrivate(
                      attachment?.fileUrl,
                      attachment?.fileName
                    );
                  }}
                  data-tooltip-id="download-files"
                >
                  <LuDownload size={18} className="text-gray-600" />
                </button>
                <CustomTooltip
                  helpers={{
                    id: "download-files",
                    content: "Download",
                    place: "left",
                  }}
                />
              </>
            )}
          </div>
        </div>
      ))}
      {/* {!isPreview && designerState?.instanceFrom !== "FORM" && (
        <PaperSeperator
          pageNo={pageNo}
          designerState={designerState}
          setDesignerState={setDesignerState}
          globalHelpers={globalHelpers}
        />
      )} */}
    </div>
  );
};

export default AttachmentInPaper;
