import "./index.css";
import { useEffect, useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import axios from "../../utils/axios";
import Select from "react-select";
import { FaUserCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdClose, MdEdit, MdOutlineModeEdit } from "react-icons/md";
import { TiDelete, TiUserDeleteOutline } from "react-icons/ti";
import Modal from "../../components/Modal";
// import makeAnimated from "react-select/animated";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import { fetchOwner, getWorkSpaceDetails } from "../../utils";
import { isFeatureEnabled } from "../../utils/paidFeat";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import { BASE_URL } from "../../constants/constants";
import { DropdownOptionSchema } from "../../pdfme/common/src/type";
import { BLOCKING } from "../../constants/blockingConsants";
import EmailSingleSelect from "../../components/Ui/EmailSingleSelect";
import { FcGoogle } from "react-icons/fc";
import { useAppDispatch } from "../../redux/store";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import {
  getOwnerThunk,
  increaseTrialEndDateByOneDay,
} from "../../redux/slices/ownerSlice";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";
import useWhiteLabelHelper from "../../hooks/reduxHooks/useWhiteLabelHelper";
import { ALL_FEATURES } from "../../types/tiers";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { TbUserEdit, TbUserMinus } from "react-icons/tb";
import EmailMultiSelect from "../../components/Ui/EmailMultiSelect";
import { GiConsoleController } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/Common/Dialog/BoloDialog";
import TrialExtendedModal from "../../components/TrialExtendedModal";
// import { connect } from "react-redux";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// const animatedComponents = makeAnimated();
type Collaborator = {
  emails: string[];
  role: string;
  forms: any[];
  pdfs: any[];
  templates: any[];
  folders: any[];
  type: string;
};
const Collaborators = () => {
  // Redux code ----------------------------------------------------------------------------------
  const dispatch = useAppDispatch();
  // const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  // const workSpaceDetails = useWorkSpaceDetails();
  // const ownerPlanDetails = useOwnerPlanDetails();
  const featuresEnabled = useIsFeatureEnabled();

  // Redux code end -------------------------------------------------------------------------------

  let navigate = useNavigate();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const whiteLabelHelper = useWhiteLabelHelper();
  let [owner, setOwner] = useState<any>({});
  const initialCollaborator: Collaborator = {
    emails: [],
    role: "readonly",
    forms: [{ value: "ALL", label: "ALL" }],
    pdfs: [{ value: "ALL", label: "ALL" }],
    templates: [{ value: "ALL", label: "ALL" }],
    folders: [{ value: "ALL", label: "ALL" }],
    type: "add",
  };

  const [collaborators, setCollaborators] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [collaborator, setCollaborator] =
    useState<Collaborator>(initialCollaborator);
  // const [isAdmin, setIsAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [noPlanError, setNoPlanError] = useState(false);

  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
    redirect: "",
    redirectText: "",
  });
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;
  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );

  const [showTrialExtendedModal, setShowTrialExtendedModal] = useState(false);
  const [trialExtendedDays, setTrialExtendedDays] = useState(0);

  useEffect(() => {
    dispatch(getOwnerThunk());
    // dispatch(getOwnerPaymentStatusThunk());
  }, [updated]);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [allForms, setAllForms] = useState([]);
  const [allPdfs, setAllPdfs] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const [allFolders, setAllFolders] = useState([]);
  const [isDrivePermission, setIsDrivePermission] = useState(false);
  const [selectInput, setSelectInput] = useState({
    form: "",
    pdf: "",
    template: "",
    folder: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [collaboratorData, setCollaboratorData] = useState({
    email: "",
    collaboratorId: "",
  });

  useEffect(() => {
    let owner: any = JSON.parse(localStorage.getItem("owner") || "");
    const updataOwner = async () => {
      if (owner && owner._id) {
        let currentOwner = await fetchOwner(owner._id);
        setOwner(currentOwner);
      } else {
        navigate("/login");
      }
    };
    updataOwner();
    setOwner(owner);
  }, [updated]);

  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      setButtonDisabled(
        !localWorkSpaceDetails.isWorkSpaceOwner &&
          (localWorkSpaceDetails.currentWorkSpaceRole == "readonly" ||
            localWorkSpaceDetails.currentWorkSpaceRole == "editor")
      );
      setWorkSpaceDetails(localWorkSpaceDetails);
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  useEffect(() => {
    owner?.collaborators && setCollaborators(owner?.collaborators);
  }, [owner]);
  // console.log(owner._id, "line no 60 ");

  const validateCollaborator = (type = "add", email: string) => {
    // check if email is valid
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast("Enter a valid email address", {
        type: "error",
      });
      return true;
    }

    let isAlreadyCollaborator = collaborators?.find(
      (c: any) => c?.collaboratorId?.email === email
    );
    if (!!isAlreadyCollaborator && type === "add") {
      toast.error(`This email already Exists - ${email}`);
      return true;
    }
    if (email === workSpaceDetails?.currentWorkSpace?.email) {
      toast.error(
        `Cannot add Owner of the workspace as Collaborator -${email}`
      );
      return true;
    }

    if (email === owner?.email) {
      toast.error(`Cannot add Yourself as collaborator -${email}`);
      return true;
    }

    return false;
  };

  const handleAddCollaborator = async (e: any) => {
    e.preventDefault();
    const emails = collaborator?.emails?.map((email) => email?.toLowerCase());
    if (emails.length === 0) {
      toast.error("No email found!");
      return;
    }
    let isAnyNotValid = collaborator?.emails?.some((email) =>
      validateCollaborator("add", email)
    );
    if (isAnyNotValid) return;
    const isAddCollabEnabled = await isFeatureEnabled("COLLABORATION");
    if (!isAddCollabEnabled) {
      setShowUpgradeModal(true);
      setUpgradeModalType("COLLABORATION");
      return;
    }
    setButtonDisabled(true);
    try {
      let { data } = await axios.post(`/owners/addCollabrators`, {
        emails,
        role: collaborator?.role,
        ownerId: owner?._id,
        adminEmail: workSpaceDetails.currentWorkSpace?.email,
        forms: collaborator?.forms,
        pdfs: collaborator?.pdfs,
        templates: collaborator?.templates,
        folders: collaborator?.folders,
      });

      setCollaborators(data.collaborators);
      toast("Collaborator Added!", {
        type: "success",
      });
      setButtonDisabled(false);
      setShowModal(false);
      setCollaborator(initialCollaborator);

      let trialExtended = data?.trialExtended;

      if (trialExtended?.extended) {
        // no need to call this here cause we are already awaiting for trial date to extend before this
        // dispatch(increaseTrialEndDateByOneDay());

        setTrialExtendedDays(trialExtended?.days);
        setShowTrialExtendedModal(true);
      }

      setUpdated((prev) => !prev);
    } catch (error: any) {
      console.log(error);
      setButtonDisabled(false);
      toast.error(error.message);
    }
  };
  const checkAppsSumoBlocking = async (arr: any) => {
    if (arr.length === 0 || arr.length > 1) {
      const isAddCollabEnabled = await isFeatureEnabled(
        "INDIVIDUAL_FILE_SHARING"
      );
      if (!isAddCollabEnabled) {
        setShowUpgradeModal(true);
        setUpgradeModalType("INDIVIDUAL_FILE_SHARING");
        // setUpgradeModalData({
        //   title: BLOCKING.FOLDER.title,
        //   desc: BLOCKING.FOLDER.desc,
        //   videoSrc: "",
        //   redirect: BLOCKING.FOLDER.redirect,
        //   redirectText: BLOCKING.FOLDER.redirectText,
        // });
        return true;
      }
      return false;
    }
    return false;
  };

  // edit collaborator
  const handleEditCollaborator = async (e: any) => {
    e.preventDefault();
    setButtonDisabled(true);
    let isAnyNotValid = collaborator?.emails?.some((email) =>
      validateCollaborator("edit", email)
    );
    if (isAnyNotValid) return;

    const isAddCollabEnabled = await isFeatureEnabled("COLLABORATION");
    if (!isAddCollabEnabled) {
      setShowUpgradeModal(true);

      setUpgradeModalType("COLLABORATION");
      // setUpgradeModalData({
      //   title: "Teams  is available in Team Plan and above",
      //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
      //   videoSrc: "",
      // });
      return;
    }
    try {
      let { data } = await axios.put(`/owners/edit-collabrator`, {
        email: collaborator?.emails[0]?.toLowerCase(),
        role: collaborator?.role,
        // ownerId: owner?._id,
        adminEmail: localStorage.getItem("currentUserEmail") ?? owner?.email,
        workSpaceOwnerEmail: workSpaceDetails?.currentWorkSpace?.email,
        forms: collaborator?.forms,
        pdfs: collaborator?.pdfs,
        templates: collaborator?.templates,
        folders: collaborator?.folders,
      });

      if (!data.success) {
        toast.error("Something went wrong");
        return;
      }

      setCollaborators(data.collaborators);
      setCollaborator(initialCollaborator);
      setShowModal(false);
      setButtonDisabled(false);
      toast("Collaborator Updated!", {
        type: "success",
      });
      setUpdated((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };

  //remove collaborator
  const handleRemoveCollaborator = async (collaborator: any) => {
    const isAddCollabEnabled = await isFeatureEnabled("COLLABORATION");
    if (!isAddCollabEnabled) {
      setShowUpgradeModal(true);

      setUpgradeModalType("COLLABORATION");
      // setUpgradeModalData({
      //   title: "Teams  is available in Team Plan and above",
      //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
      //   videoSrc: "",
      // });
      return;
    }

    try {
      let { data } = await axios.put(`/owners/remove-collabrator`, {
        email: collaborator?.email,
        // ownerId: owner._id,
        workSpaceOwnerEmail: workSpaceDetails?.currentWorkSpace?.email,
      });
      setCollaborators(data.collaborators);
      setCollaborator(initialCollaborator);
      setShowModal(false);
      toast("Collaborator Removed!", {
        type: "success",
      });
      setUpdated((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };

  async function getGoogleTokenDetails() {
    try {
      let { data } = await axios.get(
        `/owners/google-permission?permission=drive`
      );
      console.log("data", data);
      setIsDrivePermission(data?.isPermission);
    } catch (error) {
      console.log(`getGoogleTokenDetails: ${error}`);
    }
  }

  // useEffect(() => {
  const getAllForms = async () => {
    try {
      let owner = JSON.parse(localStorage.getItem("owner")!);
      let currentUserEmail = localStorage.getItem("currentUserEmail");
      const ownerId = owner?._id;
      // let { data: formDocumentResponse } = await axios.get(
      //   `/get-all-forms?page=1&limit=100&filter=${"ALL"}&ownerId=${ownerId}&currentUserEmail=${currentUserEmail}`
      // );
      let { data: formDocumentResponse } = await axios.get(
        `/get-all-forms/v1?page=1&limit=30&filter=${"ALL"}&ownerId=${ownerId}&currentUserEmail=${currentUserEmail}&query=${
          selectInput.form
        }`
      );
      setAllForms(formDocumentResponse?.forms);
    } catch (error) {
      console.log("Error getting forms", error);
    }
  };
  const getAllPdfs = async () => {
    try {
      let currentUserEmail = localStorage.getItem("currentUserEmail");

      // const requestUrl = `${BASE_URL}/getAlldocuments?page=1&limit=100&filter=${"ALL"}`;
      const requestUrl = `${BASE_URL}/get-all-documents/v1?page=1&limit=30&filter=${"ALL"}&query=${
        selectInput.pdf
      }`;
      const requestOptions = {
        authorEmail: currentUserEmail,
        isStandAloneTemplate: false,
      };
      let { data } = await axios.post(requestUrl, requestOptions);
      setAllPdfs(data?.documents);
    } catch (error) {
      console.log("Error getting pdfs", error);
    }
  };
  const getAllTemplates = async () => {
    try {
      let currentUserEmail = localStorage.getItem("currentUserEmail");

      // const requestUrl = `${BASE_URL}/getAlldocuments?page=1&limit=100&filter=${"ALL"}`;
      const requestUrl = `${BASE_URL}/get-all-documents/v1?page=1&limit=30&filter=${"ALL"}&query=${
        selectInput.template
      }`;
      const requestOptions = {
        authorEmail: currentUserEmail,
        isStandAloneTemplate: true,
      };
      let { data } = await axios.post(requestUrl, requestOptions);
      setAllTemplates(data?.documents);
    } catch (error) {
      console.log("Error getting pdfs", error);
    }
  };
  const getAllFolders = async () => {
    try {
      const { data } = await axios.post("/folder/get-by-parent");
      setAllFolders(data?.folders);
    } catch (error) {
      console.log("Error getting folders", error);
    }
  };
  //   getAllTemplates();
  //   getAllForms();
  //   getAllPdfs();
  //   getAllFolders();
  //   getGoogleTokenDetails();
  // }, []);

  useEffect(() => {
    getAllForms();
  }, [selectInput.form]);

  useEffect(() => {
    getAllPdfs();
  }, [selectInput.pdf]);

  useEffect(() => {
    getAllTemplates();
  }, [selectInput.template]);

  useEffect(() => {
    getAllFolders();
    getGoogleTokenDetails();
  }, []);

  // const updateEmailValue = (newValue: any) => {
  //   setCollaborator({
  //     ...collaborator,
  //     email: newValue,
  //   });
  // };
  const updateEmailValueMulti = (newValue: any) => {
    setCollaborator((prev: Collaborator) => ({
      ...prev,
      emails: newValue,
    }));
  };

  const addCollaboratorModalContent = () => {
    return (
      <div
        className="collab-popup-cont py-2 md:py-4 rounded relative overflow-y-auto max-h-[85vh] px-2"
        id="mini-pdf-custom-scroll"
      >
        <MdClose
          onClick={() => {
            setShowModal((prev) => !prev);
          }}
          className="absolute top-1 md:top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <h2 className="font-medium md:text-sm text-gray-700 mb-1">
          Add Collaborators & Team
        </h2>
        <p className="text-xs text-gray-600 mb-6">
          Add your team members to collaborate on your workspaces.
        </p>
        <form>
          <div className="mb-5">
            <h2 className="text-xs mb-1 text-gray-600">Email address</h2>
            {collaborator?.type === "edit" ? (
              <EmailSingleSelect
                selectedValue={collaborator?.emails[0]}
                updateEmailValue={() => {}}
                // @ts-ignore
                disabled={collaborator?.type === "edit"}
              />
            ) : (
              <EmailMultiSelect
                selectedValues={collaborator?.emails}
                updateEmailValues={updateEmailValueMulti}
                // @ts-ignore
                disabled={collaborator?.type === "edit"}
              />
            )}
          </div>
          <div className="mb-5">
            <h2 className="text-xs mb-1 text-gray-600">
              Give permission to the owner
            </h2>
            <select
              className="text-xs md:text-sm form-select w-full md:h-10 py-2 px-2 md:px-4 border outline-none text-gray-700 rounded-md"
              value={collaborator.role}
              onChange={(e) =>
                setCollaborator({ ...collaborator, role: e.target.value })
              }
            >
              <option disabled>Select Role</option>
              <option value="admin">Admin</option>
              <option value="editor">Editor</option>
              <option value="readonly">Read Only</option>
            </select>
          </div>
          <div className="mb-5">
            <label className="mb-2 text-xs block text-gray-600">
              Select Forms
            </label>
            <Select
              className="text-sm"
              onInputChange={(e) => {
                setSelectInput((prev) => ({ ...prev, form: e }));
              }}
              closeMenuOnSelect={false}
              value={collaborator.forms}
              options={[
                { value: "ALL", label: "ALL" },
                ...(collaborator?.forms?.length === 1 &&
                collaborator?.forms[0]?.value === "ALL"
                  ? []
                  : allForms?.map((form: any) => ({
                      value: form?.formId,
                      label: form?.formJson?.title,
                    }))),
              ]}
              isMulti
              onChange={async (selectedOptions: any) => {
                if (await checkAppsSumoBlocking(selectedOptions)) {
                  return;
                }
                if (
                  selectedOptions.some((option: any) => option?.value === "ALL")
                ) {
                  setCollaborator({
                    ...collaborator,
                    forms: [{ value: "ALL", label: "ALL" }],
                  });
                } else {
                  setCollaborator({ ...collaborator, forms: selectedOptions });
                }
              }}
            />

            {!isDrivePermission && !whiteLabelHelper?.isWhiteLabel && (
              <div className="mt-2">
                <p className="text-xs text-gray-500">
                  Grant permission to access your drive if your forms are
                  connected to sheets. This will automatically share all the
                  sheets with collaborators.
                </p>
                <button
                  onClick={() =>
                    window.open(
                      `${BASE_URL}/auth/google?scopesType=drive&redirectUrl=${window.location.href}&ownerEmail=${owner?.email}`,
                      "_SELF"
                    )
                  }
                  type="button"
                  className="w-fit my-2 flex flex-row items-center justify-center py-2 px-4 p rounded-md bg-white border-[1px] border-gray-200 cursor-pointer hover:bg-secondary hover:border-secondary shadow-md"
                >
                  <FcGoogle className="text-xl" />
                  <span className="text-xs ml-2">Grant Drive Permission</span>
                </button>
              </div>
            )}
          </div>

          <div className="mb-5">
            <label className="mb-2 text-xs block text-gray-600">
              Select Pdfs
            </label>
            <Select
              className="text-sm"
              closeMenuOnSelect={false}
              onInputChange={(e) => {
                setSelectInput((prev) => ({ ...prev, pdf: e }));
              }}
              value={collaborator.pdfs}
              options={[
                { value: "ALL", label: "ALL" },
                ...(collaborator?.pdfs?.length === 1 &&
                collaborator?.pdfs[0]?.value === "ALL"
                  ? []
                  : allPdfs?.map((pdf: any) => ({
                      value: pdf?.documentId,
                      label: pdf?.documentName,
                    }))),
              ]}
              isMulti
              onChange={async (selectedOptions: any) => {
                if (await checkAppsSumoBlocking(selectedOptions)) {
                  return;
                }
                if (
                  selectedOptions.some((option: any) => option.value === "ALL")
                ) {
                  setCollaborator({
                    ...collaborator,
                    pdfs: [{ value: "ALL", label: "ALL" }],
                  });
                } else {
                  setCollaborator({ ...collaborator, pdfs: selectedOptions });
                }
              }}
            />
          </div>
          <div className="mb-5">
            <label className="mb-2 text-xs block text-gray-600">
              Select PDF Templates
            </label>
            <Select
              className="text-sm"
              closeMenuOnSelect={false}
              value={collaborator.templates}
              onInputChange={(e) => {
                setSelectInput((prev) => ({ ...prev, template: e }));
              }}
              options={[
                { value: "ALL", label: "ALL" },
                ...(collaborator?.templates?.length === 1 &&
                collaborator?.templates[0]?.value === "ALL"
                  ? []
                  : allTemplates?.map((pdf: any) => ({
                      value: pdf?.documentId,
                      label: pdf?.documentName,
                    }))),
              ]}
              isMulti
              onChange={async (selectedOptions: any) => {
                if (await checkAppsSumoBlocking(selectedOptions)) {
                  return;
                }
                if (
                  selectedOptions.some((option: any) => option.value === "ALL")
                ) {
                  setCollaborator({
                    ...collaborator,
                    templates: [{ value: "ALL", label: "ALL" }],
                  });
                } else {
                  setCollaborator({
                    ...collaborator,
                    templates: selectedOptions,
                  });
                }
              }}
            />
          </div>
          <div className="mb-5">
            <label className="mb-2 text-xs block text-gray-600">
              Select Folders
            </label>
            <Select
              className="text-sm"
              closeMenuOnSelect={false}
              value={collaborator.folders}
              options={[
                { value: "ALL", label: "ALL" },
                ...(collaborator?.folders?.length === 1 &&
                collaborator?.folders[0]?.value === "ALL"
                  ? []
                  : allFolders?.map((folder: any) => ({
                      value: folder?.folderId,
                      label: folder?.name,
                    }))),
              ]}
              isMulti
              onChange={async (selectedOptions: any) => {
                if (await checkAppsSumoBlocking(selectedOptions)) {
                  return;
                }
                if (
                  selectedOptions.some((option: any) => option.value === "ALL")
                ) {
                  setCollaborator({
                    ...collaborator,
                    folders: [{ value: "ALL", label: "ALL" }],
                  });
                } else {
                  setCollaborator({
                    ...collaborator,
                    folders: selectedOptions,
                  });
                }
              }}
            />
          </div>

          <BoloButton
            size={"sm"}
            disabled={buttonDisabled}
            className="md:text-xs text-xs"
            onClick={
              // @ts-ignore
              collaborator?.type === "edit"
                ? handleEditCollaborator
                : handleAddCollaborator
            }
            // className="px-6 py-2.5 mt-4 bg-primary text-xs text-white rounded-lg hover:opacity-90 mr-4 disabled:opacity-50"
          >
            {/* @ts-ignore */}
            {collaborator?.type === "edit"
              ? "Update Collaborator"
              : "Add Collaborator"}
          </BoloButton>
        </form>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full justify-between">
      <Helmet>
        <title translate="no">Teams</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Teams" />
        <meta
          name="description"
          content="Explore the Teams Page on BoloForms Dashboard and seamlessly collaborate with your team members."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/teams`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Teams Page on Boloforms Dashboard" />
        <meta
          property="og:description"
          content="Explore the Teams Page on BoloForms Dashboard and seamlessly collaborate with your team members."
        />
        <link rel="canonical" href="https://signature.boloforms.com/teams" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="md:p-4">
        {showUpgradeModal && (
          <PleaseUpgradeModal
            show={showUpgradeModal}
            setShow={setShowUpgradeModal}
            data={upgradeModalData}
            blockingType={upgradeModalType}
          />
        )}
        <h3 className="text-md font-semibold mb-1 pt-2">
          Collaborators & Team
        </h3>
        <p className="text-xs text-gray-500 mb-4">
          Invite your team members to BoloForms Dashboard.
        </p>
        <div className="w-[100%] md:max-w-[500px] mt-4">
          {collaborators?.length !== 0 && (
            <div className="w-full hidden md:flex flex-row items-center mb-4">
              <BoloButton
                size={"sm"}
                className="md:text-xs text-xs w-full"
                disabled={buttonDisabled}
                // className="w-full bg-primary text-white text-xs px-4 py-2.5 rounded-md disabled:opacity-50"
                onClick={() => {
                  setCollaborator(initialCollaborator);
                  setShowModal((prev) => !prev);
                }}
              >
                Add Collaborator
              </BoloButton>
            </div>
          )}
          <div className="mt-8 pb-20">
            {collaborators?.map((collaborator: any, index: number) => (
              <div key={index} className="w-full mb-6 rounded-lg ">
                <div className="flex flex-row items-center">
                  <div className="w-8 h-8 min-h-8 min-w-8 bg-gray-300 rounded-full mr-4">
                    {/* @ts-ignore */}
                    {collaborator?.collaboratorId?.profilePic ? (
                      <img
                        className="h-full w-full"
                        src={
                          /* @ts-ignore */
                          collaborator?.collaboratorId?.profilePic ||
                          "https://via.placeholder.com/100"
                        }
                        alt=""
                        referrerPolicy="no-referrer"
                      />
                    ) : (
                      <FaUserCircle className="h-full w-full text-white" />
                    )}
                  </div>
                  <div className="mr-auto">
                    <h2 className="font-medium text-gray-700 text-xs mb-1 w-full">
                      {/* @ts-ignore */}
                      {collaborator?.collaboratorId?.email}
                    </h2>
                    <p className="text-xs text-gray-500 capitalize">
                      {/* @ts-ignore */}
                      {collaborator?.role === "readonly"
                        ? "Read Only"
                        : //  @ts-ignore
                          collaborator?.role}
                    </p>
                  </div>
                  <div className="flex">
                    <button
                      disabled={
                        buttonDisabled ||
                        collaborator?.collaboratorId?.email == owner?.email
                      }
                      onClick={() => {
                        new MixPanel().track("Team", {
                          team_action: "team_edit",
                          paid: false,
                        });
                        new MixPanel().increment("team_edit");
                        //@ts-ignore
                        setCollaborator({
                          ...collaborator?.collaboratorId,
                          emails: [collaborator?.collaboratorId?.email],
                          role: collaborator?.role,
                          type: "edit",
                          forms: collaborator?.forms,
                          pdfs: collaborator?.pdfs,
                          templates: collaborator?.templates,
                          folders: collaborator?.folders,
                        });
                        setShowModal((prev) => !prev);
                      }}
                      className="text-gray-500 mr-3 hover:opacity-80 disabled:opacity-50"
                      data-tip="Edit Collaborator"
                    >
                      <MdOutlineModeEdit className="text-xl" />
                    </button>
                    <button
                      disabled={
                        buttonDisabled ||
                        collaborator?.collaboratorId?.email == owner?.email
                      }
                      onClick={() => {
                        setDeleteModal(true);
                        setCollaboratorData({
                          email: collaborator?.collaboratorId?.email,
                          collaboratorId: collaborator?.collaboratorId,
                        });
                      }}
                      className="text-red-500 hover:opacity-80 disabled:opacity-50"
                      data-tip="Remove Collaborator"
                    >
                      <MdClose className="text-xl" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {collaborators?.length === 0 && (
          <div className="mt-12 w-full py-16 flex flex-col items-center justify-center text-gray-600">
            <div className="mb-4">
              <FiSearch className="text-6xl" />
            </div>
            <p className="text-xs text-gray-500">
              It's lonely here! Add your team mates.
            </p>
            <BoloButton
              size={"sm"}
              className="md:text-xs text-xs mt-3"
              disabled={buttonDisabled}
              // className="w-fit mt-2 text-primary font-medium text-sm px-4 py-2.5 hover:opacity-90 hover:underline disabled:opacity-50"
              onClick={() => {
                if (!featuresEnabled?.COLLABORATION) {
                  setNoPlanError(true);
                  setShowUpgradeModal(true);
                  setUpgradeModalType("COLLABORATION");
                  return;
                }
                setCollaborator(initialCollaborator);
                setShowModal((prev) => !prev);
              }}
            >
              Add Collaborator
            </BoloButton>
            {/* {noPlanError && (
              <div className="h-[40px] flex flex-row ml-4 items-center text-xs text-center bg-red-200 text-red-600 rounded-sm p-2">
                You can't add collaborator on the free plan{" "}
                <span
                  className="font-semibold underline cursor-pointer ml-2"
                  onClick={() => {
                    navigate("/billings");
                  }}
                >
                  Upgrade now
                </span>
                <MdClose
                  onClick={() => setNoPlanError(false)}
                  className="text-lg cursor-pointer inline-block ml-4"
                />
              </div>
            )} */}
          </div>
        )}
        {showModal && <Modal modalContent={addCollaboratorModalContent()} />}
        {showTrialExtendedModal && (
          <TrialExtendedModal
            setShow={() => {
              setShowTrialExtendedModal(false);
            }}
            show={showTrialExtendedModal}
            completedTask={{
              title: "Invite a collaborator",
              description:
                "Add a collaborator to your workspace to work together",
              increasedDays: trialExtendedDays,
            }}
          />
        )}
        <ToastContainer
          transition={Slide}
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
        />
      </div>
      <Dialog
        open={deleteModal}
        onOpenChange={(value) => {
          setDeleteModal(value);
        }}
      >
        <DialogContent className="">
          <DialogHeader>
            <DialogTitle>Remove Collaborator</DialogTitle>
          </DialogHeader>
          <div>
            <h2 className="text-sm md:text-base font-medium md:leading-[2] leading-[2]">
              Sure you want to remove this collaborator?
            </h2>
            <p className="text-[10px] md:text-xs text-gray-600">
              The collaborator
              <span className="font-semibold mx-1 text-primary">
                {collaboratorData?.email}
              </span>
              will be removed.
            </p>
          </div>

          <DialogFooter>
            <BoloButton
              variant={"danger-border"}
              size={"sm"}
              onClick={() => {
                setDeleteModal(false);
                new MixPanel().track("Team", {
                  team_action: "team_delete",
                  paid: false,
                });
                new MixPanel().increment("team_delete");
                handleRemoveCollaborator(collaboratorData.collaboratorId);
              }}
            >
              Delete
            </BoloButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {collaborators?.length !== 0 && (
        <div className="w-full block md:hidden">
          <BoloButton
            size={"sm"}
            className="md:text-xs text-xs"
            // className="w-full bg-primary text-white text-xs px-4 py-2.5 rounded-md disabled:opacity-50"
            disabled={buttonDisabled}
            onClick={() => {
              setCollaborator(initialCollaborator);
              setShowModal((prev) => !prev);
            }}
          >
            Add Collaborator
          </BoloButton>
        </div>
      )}
    </div>
  );
};

export default Collaborators;
