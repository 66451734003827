// import dynamic from 'next/dynamic';
import { REDDIT_PIXEL_ID } from '../../constants/constants';
// const RedditPixel = dynamic(() => import('react-reddit-pixel'), { ssr: false });

// const advancedMatching = {
//   email: 'some@email.com',
//   phone_number: '0123456789',
// }; // optional, more info: https://ads.Reddit.com/help/article?aid=10007891


const options = {
  debug: false, // enable logs
};

class Reddit {
  constructor(email) {
    this.pixel = null;
    this.pixelReady = this.loadPixel();
  }


  async loadPixel() {
    const RedditPixel = await import('./react-reddit-pixel');
    this.pixel = RedditPixel.default;
    // console.log(" this.pixel", this.pixel)
    if (this.pixel && this.pixel.init) {
      this.pixel.init(REDDIT_PIXEL_ID, options);
      this.pixel.pageVisit();
    } else {
      console.error("RedditPixel or RedditPixel.init is not available");
    }
  }

  async ensurePixelReady() {
    if (!this.pixel) {
      await this.pixelReady;
    }
  }

  async trackPurchase(product, priceId, quantity, value) {
    await this.ensurePixelReady();
    if (this.pixel && this.pixel.track) {
      console.log(product, priceId, quantity, value, this.pixel)
      this.pixel.track('Purchase', {
        itemCount: quantity,
        value: value,
        currency: 'USD',
        products: [
          {
            id: priceId,
            name: product
          }
        ]
      });
    } else {
      console.error("RedditPixel or RedditPixel.track is not available");
    }
  }
  async trackInitiateCheckout(product, priceId, quantity, value) {
    await this.ensurePixelReady();
    if (this.pixel && this.pixel.track) {
      //   console.log(product, priceId, quantity, value);
      this.pixel.track('Lead', {
        // itemCount: quantity,
        value: value,
        currency: 'USD',
        products: [
          {
            id: priceId,
            name: product
          }
        ]
      });

    } else {
      console.error("RedditPixel or RedditPixel.track is not available");
    }
  }

  async trackSignUp(email) {
    await this.ensurePixelReady();
    if (this.pixel && this.pixel.track) {
      //   console.log(product, priceId, quantity, value);
      this.pixel.track('SignUp', {
        // itemCount: quantity,
        value: 99,
        currency: 'USD',
        products: [
          {
            id: 'price_1OUuyQLiGbRu8waGcVQflEdP', // 99$ bizz plan id
            name: "Business Plan"
          }
        ]
      });

    } else {
      console.error("RedditPixel or RedditPixel.track is not available");
    }
  }

  async trackCustom(data) {
    await this.ensurePixelReady();
    if (this.pixel && this.pixel.rdt) {
      this.pixel.rdt('track', 'Custom', {
        itemCount: data.quantity,
        value: data.value,
        currency: 'USD',
        products: [
          {
            id: data.priceId,
            name: data.product
          }
        ]

      });
    } else {
      console.error("RedditPixel or RedditPixel.rdt is not available");
    }
  }
}


export default Reddit


