import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { Provider } from "react-redux";
// import store from "./redux/store";
import { prepareAssets } from "./utils/prepareAssets";
import {
  BrowserRouter,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import store from "./redux/store";
import { Provider } from "react-redux";
import { ENV } from "./constants/constants";
import { IntercomProvider } from "react-use-intercom";
if (
  window.location.hostname === "signature.boloforms.com" ||
  window.location.hostname === "dash-signature-staging.boloforms.com"
) {
  Sentry.init({
    dsn: "https://0e0efbbc69b103d36f75304a434aeceb@o1317278.ingest.sentry.io/4505951882510336",
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/signature-backend\.boloforms\.com\/api/,
    ],
    // allowUrls: [/^https:\/\/signature\.boloforms\.com/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

prepareAssets();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  // <Provider store={store}>
  <BrowserRouter>
    {/* <IntercomProvider appId={"ijjql330"}> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </IntercomProvider> */}
  </BrowserRouter>
  /* </Provider> */
  /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
