// @ts-nocheck

import { useEffect, useState } from "react";
// import axios from "axios";
import { Slide, ToastContainer, toast } from "react-toastify";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
import { MdClose, MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import PdfMapper from "../../components/PdfMapper";
import Uploader from "../../components/Ui/Uploader";
import axios from "../../utils/axios";
import { fetchOwner } from "../../utils";
import useFetch from "../../hooks/useFetch";
import { AiFillEye } from "react-icons/ai";
import PdfPreview from "../../components/PDFPreview";
import { DEFAULT_DATA } from "../../pdfme/ui/src/constants";
import { v4 } from "uuid";
import { IoArrowBackCircle } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";

type Props = {};

const PdfGenerator = (props: Props) => {
  const form = {
    title: "Form name (BoloForms)",
    description: "No Description",
    logo: "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/BoloForms+Logo+(1)/2.svg",
    fontFamily: "Poppins",
    accentColor: COLORS.primary,
    backgroundColor: COLORS.secondary,
    themeTemplate: "google-forms",
    darkMode: false,
    pages: [
      {
        title: "Page 1",
        description: "Page Description",
        id: v4(),
        questions: [
          {
            type: "Text",
            title: "Email",
            description: "",
            required: true,
            id: v4(),
            value: "",
            validation: "respondent_email",
          },
        ],
        goToNextPage: "next",
        goToPrevPage: "prev",
      },
      {
        title: "Page 2",
        description: "Page Description",
        id: v4(),
        questions: [],
        goToNextPage: "next",
        goToPrevPage: "prev",
      },
    ],
  };
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [owner, setOwner] = useState<any>({});
  const [uploadedFile, setUploadedFile] = useState(null);
  const [pdfPages, setPdfPages] = useState<number>(1);
  const [templateAvailable, setTemplateAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(true); // Add a state to control toast display
  const [fileName, setFileName] = useState("");
  const [searchParams] = useSearchParams();
  const [isPreview, setIsPreview] = useState(false);
  const [formResponseData, setFormResponseData] = useState<any>(null);
  const [formState, setFormState] = useState<any>(null);
  const [startSave, setStartSave] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [formState_, setFormState_] = useState<any>({});
  const [schemas, setSchemas] = useState<any>([]);
  const [sampledata, setSampledata] = useState<any>([]);

  let formId = searchParams.get("id");
  let navigate = useNavigate();

  const [response, setResponse] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/private-forms?formId=${formId}`);
      setResponse(response);
    };
    fetchData();
  }, [formId]);
  const { data }: any = response || {};
  console.log(response);
  let { forms }: any = data || {};

  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
    };
    updatedOwner();
  }, []);
  // Rest of your code...

  // useEffect(()=>{
  //   let getFormDetails = async () => {
  //     try{
  //       setLoading(true);
  //       const { data: isFormAvailable } = await axios.get(
  //         `/forms?formId=${formId}`
  //       );
  //       if(!isFormAvailable.error){

  //       }
  //       setLoading(false);
  //     }catch(errror){
  //       setLoading(false);
  //       // toast.error("Error fetching form");
  //     }
  //   }
  //   getFormDetails();
  // }, [formId]);

  // const handleFileUpload = (base64File: any, numberOfPages: number) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(base64File);
  //   reader.onload = async () => {
  //     const arrayOfPdfFile = reader.result;
  //     // @ts-ignore
  //     setUploadedFile(arrayOfPdfFile);
  //   };
  // };

  const parseDefaultResponses = () => {
    let currentResponse: any = {};
    let responseItemsId: any = {};
    let today = new Date();
    let valueObj: any = {
      Text: "Test Answer",
      LongText: "Test Answer",
      Checkbox: "Test Option",
      RadioGroup: "Test Option",
      Dropdown: "Test Option",
      Date: `${today.getDate()}/${today.getMonth()}/${today.getFullYear()}`,
      Time: `${today.getHours()}:${today.getMinutes()}`,
      Signature: DEFAULT_DATA.signature.unsigned,
    };
    formState?.pages?.forEach((page: any) => {
      page.questions.forEach((question: any) => {
        currentResponse[question.title] =
          valueObj[question?.type] || "Default Value";

        if (question?.validation === "respondent_email") {
          currentResponse[question.title] = "example@domain.com";
        }
        responseItemsId[question.id] = question.title;
      });
    });

    let data = {
      formId,
      respondentEmail: "example@domain.com",
      response: currentResponse,
      responseItemsId,
      addUniqueIdToRow: null,
      from: "BOLOFORMS_FORM",
    };

    setFormResponseData(data);
  };

  useEffect(() => {
    parseDefaultResponses();
  }, [formState]);

  useEffect(() => {
    let getFormDetails = async () => {
      try {
        const { data } = await axios.get(`/forms?formId=${formId}`);
        let { forms }: any = data || {};
        setFormState_(forms);
        // Convert PDF buffer to a base64 string
        await setSchemas(data?.forms[0]?.pdfTemplate?.schemas);
        if (data?.forms[0]?.formJson) {
          await setFormState(data?.forms[0]?.formJson);
        } else {
          await setFormState(form);
        }
        setSampledata(data?.forms[0]?.pdfTemplate?.sampledata);
      } catch (errror) {
        setLoading(false);
        toast.error("Error fetching form");
      }
    };
    getFormDetails();
  }, [formId, isSaved]);

  const handleFileUpload = (base64File: any, isTrialExtended: boolean) => {
    // setUploadedFile(base64File);
    // setPdfPages(numberOfPages);
    if (!base64File) return;

    let navigateUrl = `/create/pdf-mapping?id=${formId}`;

    if (isTrialExtended) {
      navigateUrl = `/create/pdf-mapping?id=${formId}&trialExtended=true`;
    }

    navigate(navigateUrl);
    return;
  };

  return (
    <div className={`w-full`}>
      <Helmet>
        <title translate="no">Pdf Customize</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Pdf Customize" />
        <meta
          name="description"
          content="Effortlessly map your form questions to PDF using our editor, simplifying the process with ease"
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/create/form/pdf`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Pdf Customize`} />
        <meta
          property="og:description"
          content="Effortlessly map your form questions to PDF using our editor, simplifying the process with ease"
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/create/form/pdf"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {uploadedFile ? (
        <div className="w-full h-full">
          <div
            className="template-navbar px-8 py-2 bg-primary flex gap-2 text-white items-center h-8"
            style={{ background: isPreview ? "#0f0b19" : COLORS.primary }}
          >
            <div className="flex gap-2 items-center w-[100%]">
              <MdOutlineKeyboardBackspace
                className="hover:bg-white hover:text-primary text-2xl cursor-pointer rounded-sm"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <p className="text-sm">
                {/* @ts-ignore */}
                Edit Template: <span className="font-semibold">{fileName}</span>
              </p>
            </div>
            {isPreview && (
              <div className="text-sm flex items-center justify-center w-[100%] ">
                This is a Preview
              </div>
            )}
            <div className="flex items-end justify-end w-[100%]">
              {isPreview ? (
                <button onClick={() => setIsPreview(false)}>
                  <MdClose className="text-white" size={20} />
                </button>
              ) : (
                <button
                  onClick={() => {
                    // setIsPreview(true);
                    setStartSave(true);
                  }}
                >
                  <AiFillEye className="text-white" size={25} />
                </button>
              )}
            </div>
          </div>
          {!isPreview ? (
            // <PdfMapper file={uploadedFile} numberOfPages={pdfPages} />
            <PdfMapper
              setIsSaved={setIsSaved}
              setStartSave={setStartSave}
              startSave={startSave}
              setIsPreview={setIsPreview}
              file={uploadedFile}
              numberOfPages={pdfPages}
              schemas={schemas}
              sampledata={sampledata}
            />
          ) : (
            <div className="fixed top-0 left-0 w-screen h-screen bg-gray-300 flex items-start justify-start z-50 overflow-auto">
              <div className="w-full h-full flex flex-col justify-between">
                {/* Cross button to close the modal */}
                <div className="w-full flex items-center justify-start h-[40px] bg-black text-white gap-6 shadow text-sm">
                  <div className="flex gap-2 items-center w-[100%]">
                    <button
                      className=" text-gray-800 hover:text-primary ml-2 shadow px-2 py-1 rounded-[3px]"
                      onClick={() => {
                        setIsPreview(false);
                        // setIsShowPreview(false);
                        // setCheckboxVerificationChecked(false);
                      }}
                    >
                      <MdOutlineKeyboardBackspace className=" text-2xl" />
                    </button>
                    <span className="text-sm">{fileName}</span>
                  </div>
                  <div className="flex gap-2 items-center justify-start w-[100%]">
                    This is a Preview
                  </div>
                  <div className="flex gap-2 items-center justify-end w-[100%] pr-6">
                    <button onClick={() => setIsPreview(false)}>
                      <MdClose className="text-white" size={20} />
                    </button>
                  </div>
                </div>

                <PdfPreview
                  form={formState_?.[0]}
                  pdfFile={uploadedFile}
                  formResponseData={formResponseData}
                  heightSub={80}
                />

                <div className="w-full flex items-center justify-center h-[60px] bg-white gap-5 shadow">
                  <label className="flex items-center text-sm">
                    <input
                      type="checkbox"
                      className="mr-2"
                      disabled
                      checked={false}
                      onChange={() => {}}
                    />
                    I have read and verified the document
                  </label>

                  <button
                    className={`px-4 py-2 bg-primary text-white text-sm rounded-[3px] disabled:opacity-50 disabled:cursor-not-allowed`}
                    disabled={true}
                    onClick={async () => {
                      // setLoading(true);
                      // await handleFormSubmit();
                      // setIsShowPreview(false);
                      // setCheckboxVerificationChecked(false);
                      // setLoading(false);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="template-navbar h-14 px-8 py-2 bg-primary flex gap-2 text-white items-center">
            <IoArrowBackCircle
              className="hover:opacity-70 text-3xl cursor-pointer rounded-sm"
              onClick={() => {
                navigate(-1);
              }}
            />
            <p className="text-sm ml-2">Edit Form</p>
            <p className="ml-auto text-sm">
              <span className="font-semibold">
                {response?.data?.forms?.[0]?.formJson?.title}
              </span>
            </p>
          </div>
          <div className="mt-2 md:mt-6 py-2 md:py-4 px-3 md:px-8">
            <Uploader
              preUploadedFile={null}
              onFileUpload={handleFileUpload}
              setFileName={setFileName}
              formId={formId}
            />
          </div>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default PdfGenerator;
