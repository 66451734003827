import { MdClose } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";
import { useEffect, useState } from "react";
import axios from "../../../../../utils/axios";
import { toast } from "react-toastify";
interface FormData {
  isWarningEmail: boolean;
  days: number;
}
const ExpirationModal = ({ setSelectedSetting, documentId, settings }: any) => {
  console.log(settings);
  const today = new Date();
  const initialData: FormData = {
    isWarningEmail: false,
    days: 10,
  };
  const [form, setForm] = useState(initialData);
  const [isDaysValid, setIsDaysValid] = useState(true);
  const [expirationDate, setExpirationDate] = useState<Date>(() => {
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 10);
    return futureDate;
  });
  useEffect(() => {
    if (settings && settings?.expiration && settings?.expiration?.days) {
      setForm({
        isWarningEmail: settings?.expiration?.isWarningEmail,
        days: parseInt(settings?.expiration?.days),
      });
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() + settings?.expiration?.days);
      setExpirationDate(futureDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (!form.days || form.days < 1 || form.days > 3650) {
      setIsDaysValid(false);
    } else {
      setIsDaysValid(true);
    }
    const expiration = new Date(today);
    if (form.days && form.days > 0) {
      expiration.setDate(today.getDate() + form.days);
      setExpirationDate(expiration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.days]);
  const fillForm = async () => {
    try {
      const response = await axios.post("/save-expiration", {
        expiration: {
          isWarningEmail: form.isWarningEmail,
          days: form.days,
        },
        documentId,
      });
      if (response.status === 200) {
        toast.success("Expiration saved");
        setSelectedSetting("");
      }
    } catch (error) {
      toast.error("Error saving expiration");
      console.error(error);
    }
  };
  const clearExpiration = async () => {
    try {
      const response = await axios.post("/save-expiration", {
        expiration: {},
        documentId,
      });
      if (response.status === 200) {
        toast.success("Expiration removed");
        setSelectedSetting("");
      }
    } catch (error) {
      toast.error("Error removing expiration");
      console.error(error);
    }
  };
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.4)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-full h-screen  flex justify-center items-start md:items-center p-4"
    >
      <div
        className={`w-[540px] max-w-full bg-white rounded-md px-5 md:px-8 py-6`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-gray-700 text-md font-semibold">
            Expiration settings
          </h2>
          <MdClose
            onClick={() => {
              setSelectedSetting("");
            }}
            className="text-2xl cursor-pointer"
          />
        </div>
        <p className="w-[360px] max-w-full text-sm text-gray-500">
          Set your documents to expire automatically if they are not completed.
        </p>
        <p className="mt-4 mb-3 text-primary text-sm font-semibold">
          EXPIRATION PERIOD
        </p>
        <div className="flex justify-start items-center mb-2 gap-2">
          <span className="text-sm">Expire sent document in </span>
          <div
            className={` w-20 border-b pb-1${
              isDaysValid
                ? "focus-within:border-primary border-gray-300"
                : " border-red-500 focus-within:border-red-500"
            }`}
          >
            <input
              type="number"
              className="outline-none w-10 text-sm"
              placeholder="60"
              value={form.days}
              min={1}
              max={3650}
              onInput={(e: any) => {
                const inputValue = e.target.value;
                const firstFourDigits = inputValue.slice(0, 4);
                setForm({ ...form, days: parseInt(firstFourDigits) });
              }}
            />
            <span className="text-sm text-gray-500 float-right">
              {form.days === 1 || !form.days ? "day" : "days"}
            </span>
          </div>
        </div>
        {!isDaysValid ? (
          <p className="text-red-500 text-sm mb-3">
            Please enter an integer between 1 and 3650.
          </p>
        ) : null}
        <p className="w-[360px] max-w-full text-sm text-gray-500 mb-6">
          If sent today, documents will expire on{" "}
          {expirationDate.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </p>

        <p className="my-3 text-gray-600 text-sm">WARNING EMAIL</p>
        <label
          htmlFor="warningEmail"
          className="flex justify-start items-center gap-2 mb-6 cursor-pointer"
        >
          <input
            type="checkbox"
            id="warningEmail"
            className="hidden"
            checked={form.isWarningEmail}
            onChange={(e) => {
              setForm({ ...form, isWarningEmail: e.target.checked });
            }}
          />
          {form.isWarningEmail ? (
            <MdCheckBox className="w-6 h-6 text-primary p-0 " />
          ) : (
            <MdCheckBoxOutlineBlank className="w-6 h-6 text-gray-400 hover:bg-gray-100 p-0 " />
          )}

          <span className="text-sm text-gray-600 tracking-tight cursor-pointer">
            Warn signers 1 day prior to expiration
          </span>
        </label>
        {settings && settings?.expiration && settings?.expiration?.days && (
          <button
            className="items-center justify-center py-2 mb-5 px-4 cursor-pointer text-sm border-2 border-primary text-primary rounded float-left disabled:hover:cursor-auto mt-4"
            onClick={clearExpiration}
          >
            Remove
          </button>
        )}
        <button
          className="flex items-center justify-center py-2 px-4 cursor-pointer text-sm bg-primary text-white rounded overflow-hidden float-right disabled:hover:cursor-auto mt-4"
          onClick={fillForm}
          disabled={!isDaysValid}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default ExpirationModal;
