import React from "react";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";

export type CloseConfirmationProps = {
  setShow: (value: boolean) => void;
  handleOk: () => void;
};

const CloseConfirmation = ({ setShow, handleOk }: CloseConfirmationProps) => {
  return (
    <>
      <div className="text-base md:text-lg mb-4 font-semibold">
        No Inputs Addded
      </div>
      <div className="gap-2 items-center justify-between text-xs">
        <p>There are no Input elements dragged in the following document. </p>
        <p>
          Sending this will result in plain document to be shown to the signer.
        </p>
      </div>
      <div className="flex gap-2 items-center justify-end mt-8">
        <BoloButton
          variant={"secondary"}
          size={"sm"}
          onClick={() => {
            setShow(false);
          }}
        >
          Cancel
        </BoloButton>
        <BoloButton
          variant={"danger"}
          size={"sm"}
          onClick={() => {
            handleOk();
            setShow(false);
          }}
        >
          Proceed
        </BoloButton>
      </div>
    </>
  );
};

export default CloseConfirmation;
