import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { BASE_URL } from "../../constants/constants";
import {
  BASE_INITIAL_STATE,
  parseQueryParams,
  BaseInitialStateType,
} from "../reduxConstants";

export const getOwnerThunk = createAsyncThunk(
  "getOwner",
  async (data?: { id?: string }) => {
    try {
      let url = `${BASE_URL}/owners?id=${
        JSON.parse(localStorage.getItem("owner") || "{}")?._id || data?.id || ""
      }`;
      const res = await axios.get(url);
      return res.data;
    } catch (error: any) {
      return error?.response?.data;
    }
  }
);

export const getOwnerPaymentStatusThunk = createAsyncThunk(
  "getOnwerPaymentStatus",
  async (data?: { id?: string }) => {
    try {
      let url = `${BASE_URL}/owners/payment-status?ownerId=${
        JSON.parse(localStorage.getItem("owner") || "{}")?._id || data?.id || ""
      }`;
      const res = await axios.get(url);
      return res.data;
    } catch (error: any) {
      return error?.response?.data;
    }
  }
);

export type OwnerInitialStateType = {
  data: {
    owner: any;
  };
} & BaseInitialStateType;

const initialState: OwnerInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: {
    owner: JSON.parse(localStorage.getItem("owner") || "{}") || {},
  },
};

const authSlice = createSlice({
  name: "owner",
  initialState: initialState,
  reducers: {
    // using rx to identify away from normal state vars
    setOwnerRx: (state, action) => {
      // completly resets owner
      localStorage.setItem("owner", JSON.stringify(action.payload));
      state.data.owner = action.payload;
    },

    // Increases the trialEndDate of the last planHistory by 1 day
    increaseTrialEndDateByOneDay: (state) => {
      console.log("TRIAL END DATE INCREASED BY 1 DAY");

      const owner = state.data.owner;
      if (owner && owner.planhistory && owner.planhistory.length > 0) {
        const lastPlanIndex = owner.planhistory.length - 1;
        const lastPlan = owner.planhistory[lastPlanIndex];
        if (lastPlan?.trialEndDate) {
          const newDate = new Date(lastPlan.trialEndDate);
          newDate.setDate(newDate.getDate() + 1);
          owner.planhistory[lastPlanIndex] = {
            ...lastPlan,
            trialEndDate: newDate.toISOString(),
          };
          localStorage.setItem("owner", JSON.stringify(owner));
          state.data.owner = owner;
        }
      }
    },

    // Increases the usedQuanity at a specific index by a given amount
    increaseUsedQuantity: (state, action) => {
      const { index, amount } = action.payload;
      const owner = state.data.owner;
      if (owner && owner.planhistory && owner.planhistory.length > 0) {
        const lastPlanIndex = owner.planhistory.length - 1;
        const lastPlan = owner.planhistory[lastPlanIndex];
        if (
          lastPlan &&
          lastPlan.usedQuanity &&
          lastPlan.usedQuanity.length > index
        ) {
          lastPlan.usedQuanity[index] += amount;
          owner.planhistory[lastPlanIndex] = {
            ...lastPlan,
            usedQuanity: [...lastPlan.usedQuanity],
          };
          localStorage.setItem("owner", JSON.stringify(owner));
          state.data.owner = owner;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //getOwnerThunk ---------------------------------------------------------------------
      .addCase(getOwnerThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOwnerThunk.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.data.owner = payload?.owner || state?.data?.owner;
          localStorage.setItem("owner", JSON.stringify(state.data.owner));
        }
        state.loading = false;
      })
      .addCase(getOwnerThunk.rejected, (state, { payload }) => {
        state.loading = false;
      })
      //getOwnerPaymentStatusThunk ---------------------------------------------------------------------
      .addCase(getOwnerPaymentStatusThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getOwnerPaymentStatusThunk.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.data.owner.paymentStatus = payload?.paymentStatus;
          localStorage.setItem("owner", JSON.stringify(state.data.owner));
        }
        state.loading = false;
      })
      .addCase(getOwnerPaymentStatusThunk.rejected, (state, { payload }) => {
        state.loading = false;
      });
  },
});

export default authSlice.reducer;
export const {
  // for using these reducers use useAppDispatch instead of useDispatch
  setOwnerRx,
  increaseTrialEndDateByOneDay,
  increaseUsedQuantity,
} = authSlice.actions;
