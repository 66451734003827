import React from "react";
import { MdClose } from "react-icons/md";

export type BoloModalProps = {
  setShow: (value: boolean) => void;
  content: React.ReactElement;
  isNoCross?: boolean;
  isWidthFit?: boolean;
  isPaddingZero?: boolean;
  iconPositionString?: string;
};

const BoloModal = ({
  setShow,
  content,
  isNoCross,
  isWidthFit,
  isPaddingZero,
  iconPositionString,
}: BoloModalProps) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] ${
          isWidthFit ? "w-fit" : "w-[95%] md:w-[780px]"
        } relative bg-white rounded-sm md:rounded ${
          isPaddingZero ? "" : "px-4 md:px-8 py-2 md:py-5"
        }  overflow-y-auto
        `}
        id="mini-pdf-custom-scroll"
      >
        <div className="w-[100%] relative flex flex-col">
          {!isNoCross && (
            <MdClose
              className={`absolute cursor-pointer text-2xl text-gray-600 ${
                !iconPositionString ? "right-0 top-0" : iconPositionString
              } `}
              onClick={() => {
                setShow(false);
              }}
            />
          )}
        </div>
        {content}
      </div>
    </div>
  );
};

export default BoloModal;
