import React from "react";
import { SidebarProps } from "..";
import { BoloInput } from "../../../../../../../components/Common/Input/BoloInput";
import { SchemaForUI } from "../../../../../../common/src";
import Select from "react-select";
import { DropdownOptionSchema } from "../../../../../../common/src/type";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { getWhiteLabelInfoLocal } from "../../../../../../../utils";
import { COLORS } from "../../../../../../../constants/common";

const DataEditor = (
  props: Pick<SidebarProps, "changeSchemas" | "pageSize"> & {
    activeSchema: SchemaForUI;
  }
) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const primaryColor =
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary;

  const { activeSchema, changeSchemas } = props;
  const dateFormat =
    activeSchema?.ifDateFormat?.replace("mm", "MM") || "mm/dd/yyyy";

  // --------------------------------------------------------------------
  // CONTINUE HERE
  // --------------------------------------------------------------------
  return (
    <div className="py-2">
      <p className="text-xs">Placeholder</p>
      <BoloInput
        value={activeSchema?.placeholder || ""}
        onChange={(e) => {
          changeSchemas([
            {
              key: "placeholder",
              value: e.target.value,
              schemaId: activeSchema.id,
            },
          ]);
        }}
        placeholder={`Placeholder for ${
          activeSchema.subType === "file_upload"
            ? "collect file"
            : activeSchema.subType
        } field`}
        bolosize={"xs"}
        className="w-full mt-1"
      />
      {(activeSchema.subType == "text" || activeSchema.subType == "number") && (
        <>
          {" "}
          <p className="text-xs mt-4">Default Value</p>
          <BoloInput
            value={activeSchema.data}
            onChange={(e) => {
              changeSchemas([
                {
                  key: "data",
                  value: e.target.value,
                  schemaId: activeSchema.id,
                },
              ]);
            }}
            type={activeSchema?.subType == "number" ? "number" : "text"}
            placeholder={`Default value for ${activeSchema.subType} field`}
            bolosize={"xs"}
            className="w-full mt-1"
          />
        </>
      )}
      {activeSchema.subType == "date" && (
        <>
          <p className="text-xs mt-4">Default Value</p>
          <DatePicker
            wrapperClassName="w-full h-full"
            selected={
              dayjs(activeSchema?.data).isValid()
                ? dayjs(new Date(activeSchema?.data)).toDate()
                : null
            }
            onChange={(date: Date | null) => {
              // setSelectedDate(date);
              let dateString = "";
              if (date) {
                dateString = dayjs(new Date(date)).format(
                  activeSchema?.ifDateFormat?.toUpperCase() || "MM/DD/YYYY"
                );
              }
              changeSchemas([
                {
                  key: "data",
                  value: dateString,
                  schemaId: activeSchema.id,
                },
              ]);
            }}
            dateFormat={dateFormat}
            customInput={<BoloInput bolosize={"xs"} className="w-full mt-1" />}
          />
        </>
      )}
      {activeSchema.subType == "dropdown" && (
        <>
          {" "}
          <p className="text-xs mt-4 mb-1">Default Option</p>
          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                borderRadius: "none",
                boxShadow: "none",
                "&:hover": {
                  border: `1px solid ${primaryColor}`,
                },
                background: primaryColor + "20",
                border: "none",
                fontSize: "14px",
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: isSelected ? primaryColor : "white",
                  "&:hover": {
                    color: "black",
                    backgroundColor: primaryColor + "20",
                  },
                  fontSize: "14px",
                };
              },
            }}
            options={activeSchema?.dropdownOptions?.map(
              (opt: DropdownOptionSchema) => ({
                value: opt.value,
                label: opt.option,
              })
            )}
            value={activeSchema?.dropdownOptions
              ?.map((opt: DropdownOptionSchema) => ({
                value: opt.value,
                label: opt.option,
              }))
              .filter((option) => {
                return option.value === activeSchema.data;
              })}
            onChange={(e) => {
              changeSchemas([
                {
                  key: "data",
                  value: e?.value,
                  schemaId: activeSchema.id,
                },
              ]);
            }}
            className="z-30"
          />
        </>
      )}
    </div>
  );
};

export default DataEditor;
