import Header from "../../components/FormRender/Default/Header";
import NotionHeader from "../../components/FormRender/NotionFormsStyle/Header";

function getOtherComponents(type: string) {
  switch (type) {
    case "notion-forms":
      return {
        Header: NotionHeader,
        previousButtonClass:
          "min-w-[130px] border-2 mt-2 px-8 mx-1 py-2 text-base transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 ring-slate-200 focus:ring-offset-2 rounded-lg filter hover:brightness-110",
        nextButtonClass:
          "min-w-[130px] mt-2 px-8 mx-1 py-2 text-base transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 ring-slate-200 focus:ring-offset-2 rounded-lg filter hover:brightness-110",
        submitButtonClass:
          "min-w-[130px] mt-2 px-8 mx-1 py-2 text-base transition ease-in duration-200 text-center font-semibold shadow-md focus:outline-none focus:ring-2 ring-slate-200 focus:ring-offset-2 rounded-lg filter hover:brightness-110",
        pageTitleClass:
          "text-gray-700 dark:text-gray-300 font-bold text-xl uppercase flex flex-row",
        pageDescriptionClass: "text-xs mt-1 text-gray-400",
        pageContainerClass: "bg-white rounded-md p-4 mb-4",
      };

    default:
      return {
        Header,
        previousButtonClass:
          "w-full sm:w-fit h-16 border-2 mr-1 font-medium rounded-md",
        nextButtonClass:
          "w-full sm:w-fit h-16 ml-1  text-white font-medium rounded-md",
        submitButtonClass:
          "w-full sm:w-fit max-w-[345px] h-16 text-white font-medium rounded-md",
        pageTitleClass: "text-xl font-medium",
        pageDescriptionClass: "text-xs mt-1",
        pageContainerClass: "bg-white rounded-md p-4 mb-4 border-2",
      };
  }
}

export default getOtherComponents;
