//@ts-nocheck

import React from "react";
import QuestionDesc from "../../QuestionDesc";
import QuestionInputOther from "../../QuestionInputOther";

const TitleDesc = ({ data }) => {
  let {
    question,
    setFormState,
    isEditor,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
  } = data;

  return (
    <div className="w-full flex flex-row pb-2">
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <QuestionInputOther
              helpers={{
                question,
                setFormState,
                isEditor,
                questionIndex,
                activeState,
                setActiveState,
                setActive,
              }}
            />
          </div>
          <QuestionDesc
            helpers={{ question, setFormState, questionIndex, activeState }}
          />
        </div>
      </div>
    </div>
  );
};

export default TitleDesc;
