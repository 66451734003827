import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { FaBold, FaItalic } from "react-icons/fa6";
import { IoMdColorFill } from "react-icons/io";
import {
  MdFormatAlignCenter,
  MdFormatAlignLeft,
  MdFormatAlignRight,
} from "react-icons/md";
import { toast } from "react-toastify";
import { DesignerState, GlobalHelpersType } from "..";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import { BoloInput } from "../../../../../../components/Common/Input/BoloInput";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../../../../../components/Common/PopOver/BoloPopOver";
import { BoloToggle } from "../../../../../../components/Common/Toggle/BoloToggle";
import CustomTooltip from "../../../../../../components/EditForm/CustomTooltip";
import { BASE_URL } from "../../../../../../constants/constants";
import axios from "../../../../../../utils/axios";
import { getFontName } from "../../../../../../utils/localPdfMe";
import {
  DEFAULT_FONT_NAME,
  FONT_LIST,
  FONT_NAME_MAP,
  getCSSFont,
} from "../../../../../common/src/constants";
import { StylingSchema } from "../../../../../common/src/type";
import { STYLE_DEFAULTS } from "../../../constants";
import ViewHeader from "./ViewHeader";

export type DesignViewProps = {
  helpers: {
    open: boolean;
    setOpen: any;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};

const DesignView = ({ helpers }: DesignViewProps) => {
  const { open, setOpen, designerState, setDesignerState, globalHelpers } =
    helpers;

  const [localStyle, setLocalStyle] = useState<StylingSchema>({
    ...designerState?.settings?.schemaStyleDefaults,
  });
  const [localStyleError, setLocalStyleError] = useState({
    fontSize: false,
    lineHeight: false,
    characterSpacing: false,
  });

  function validateStyle(value: StylingSchema): boolean {
    let fontSize = value?.fontSize;
    let lineHeight = value?.lineHeight;
    let characterSpacing = value?.characterSpacing;

    // -----------------------------------------------------------
    if (fontSize == undefined) {
      // setLocalStyleError((prev) => ({ ...prev, fontSize: true }));
      return true;
    }
    if (isNaN(fontSize) || fontSize < 1 || fontSize > 100) {
      setLocalStyleError((prev) => ({ ...prev, fontSize: true }));
      return false;
    } else {
      setLocalStyleError((prev) => ({ ...prev, fontSize: false }));
    }
    // -----------------------------------------------------------
    if (lineHeight == undefined) {
      // setLocalStyleError((prev) => ({ ...prev, fontSize: true }));
      return true;
    }
    if (isNaN(lineHeight) || lineHeight < 1 || lineHeight > 20) {
      setLocalStyleError((prev) => ({ ...prev, lineHeight: true }));
      return false;
    } else {
      setLocalStyleError((prev) => ({ ...prev, fontSize: false }));
    }
    // -----------------------------------------------------------
    if (characterSpacing == undefined) {
      // setLocalStyleError((prev) => ({ ...prev, fontSize: true }));
      return true;
    }
    if (
      isNaN(characterSpacing) ||
      characterSpacing < 0 ||
      characterSpacing > 20
    ) {
      setLocalStyleError((prev) => ({ ...prev, characterSpacing: true }));
      return false;
    } else {
      setLocalStyleError((prev) => ({ ...prev, fontSize: false }));
    }

    return true;
  }

  const setField = ({ styleData }: { styleData: StylingSchema }) => {
    setDesignerState((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        schemaStyleDefaults: styleData,
      },
    }));
    // handleStyleSettings(styleData);
    globalHelpers.setDefaultStyles(styleData);
  };

  // const handleStyleSettings = async (value: StylingSchema | undefined) => {
  //   try {
  //     const { data } = await axios.post(`${BASE_URL}/document/settings`, {
  //       schemaStyleDefaults: value,
  //       documentId: designerState?.fetchedData?.document?.documentId,
  //     });
  //     if (data?.success) {
  //       // toast.success("Styles changed succesfully");
  //       if (data?.document?.schemaStyleDefaults) {
  //         // if settings exists on returned object
  //         // as when we change language settings will always exist on the document
  //         setDesignerState((prev) => ({
  //           ...prev,
  //           settings: {
  //             ...prev.settings,
  //             schemaStyleDefaults: {
  //               ...data?.document?.schemaStyleDefaults,
  //             },
  //           },
  //         }));
  //       }
  //     }
  //     if (!data?.success) {
  //       toast.error(data?.message || "Something went wrong");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    let isValidStyle = validateStyle(localStyle);
    console.log({ isValidStyle });
    if (isValidStyle) {
      setField({ styleData: localStyle });
    }
  }, [localStyle]);

  // console.log({ localStyle });

  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      <div className="px-4 overflow-y-auto h-[100%]">
        <div className="">
          <ViewHeader name="Document Design" open={open} setOpen={setOpen} />
        </div>
        {/* <p className="text-gray-500 text-sm mb-1 uppercase">DEFAULT SETTINGS</p> */}
        <p className="text-gray-500 text-xs mb-2">
          These are global document settings and are applied to all
          fields/blocks automatically.
        </p>
        <p className="text-gray-500 text-xs mb-6">
          You can change settings for individual fields/blocks later on.
        </p>
        <div className="flex flex-col gap-3 text-gray-700 text-xs">
          <div className="flex justify-between items-center h-6">
            Font
            <div className="flex items-center gap-4 w-[45%]">
              <BoloPopover>
                <BoloPopoverTrigger>
                  <BoloInput
                    value={FONT_NAME_MAP[localStyle?.fontName || "_"]}
                    bolosize={"xs"}
                    className="w-[100%]"
                    style={{
                      fontFamily: getCSSFont({
                        currFont: localStyle?.fontName,
                      }),
                    }}
                  />
                </BoloPopoverTrigger>
                <BoloPopoverContent align="end">
                  <div
                    className="rounded h-40 overflow-y-auto w-40 bg-white z-[3030]"
                    id="mini-pdf-custom-scroll"
                  >
                    {FONT_LIST.map((font) => {
                      return (
                        <div
                          className={`px-1.5 py-1 text-xs flex items-center justify-start cursor-pointer hover:bg-secondary/30 hover:text-primary  ${
                            (localStyle?.fontName?.includes(font.genFont) ||
                              (!localStyle?.fontName &&
                                font.genFont == DEFAULT_FONT_NAME)) &&
                            "bg-secondary text-primary"
                          }`}
                          style={{
                            fontFamily: font.previewFont,
                          }}
                          onClick={() => {
                            let fontName = getFontName({
                              bold: !!localStyle.bold,
                              italic: !!localStyle.italic,
                              currFont: font.genFont || "",
                            });
                            setLocalStyle((prev) => ({ ...prev, fontName }));
                          }}
                        >
                          {font?.name}
                        </div>
                      );
                    })}
                  </div>
                </BoloPopoverContent>
              </BoloPopover>
            </div>
          </div>
          {/* Will convert these to bolo drop down later */}
          <div className="flex justify-between items-center h-6">
            Text Size
            <div className="flex items-center gap-4 w-[45%]">
              <BoloInput
                variant={localStyleError?.fontSize ? "danger" : "primary"}
                value={localStyle?.fontSize || STYLE_DEFAULTS.fontSize}
                onChange={(e) => {
                  let fontSize = parseInt(e.target.value);
                  setLocalStyle((prev) => ({ ...prev, fontSize }));
                }}
                type="number"
                bolosize={"xs"}
                className="w-[100%]"
                placeholder="e.g. 12"
              />
            </div>
          </div>
          <div className="flex justify-between items-center h-6">
            Line Height
            <div className="flex items-center gap-4 w-[45%]">
              <BoloInput
                variant={localStyleError?.lineHeight ? "danger" : "primary"}
                value={localStyle?.lineHeight || STYLE_DEFAULTS.lineHeight}
                onChange={(e) => {
                  let lineHeight = parseInt(e.target.value);
                  setLocalStyle((prev) => ({ ...prev, lineHeight }));
                }}
                type="number"
                bolosize={"xs"}
                className="w-[100%]"
                placeholder="e.g. 1"
              />
            </div>
          </div>
          <div className="flex justify-between items-center h-6">
            Character Spacing
            <div className="flex items-center gap-4 w-[45%]">
              <BoloInput
                variant={
                  localStyleError?.characterSpacing ? "danger" : "primary"
                }
                value={
                  localStyle?.characterSpacing ||
                  STYLE_DEFAULTS.characterSpacing
                }
                onChange={(e) => {
                  let characterSpacing = parseInt(e.target.value);
                  setLocalStyle((prev) => ({ ...prev, characterSpacing }));
                }}
                type="number"
                bolosize={"xs"}
                className="w-[100%]"
                placeholder="e.g. 1"
              />
            </div>
          </div>

          <div className="flex justify-between items-center h-6">
            Text Alignment
            <div className="flex items-center justify-end gap-2.5 w-[45%]">
              <BoloToggle
                size={"xs"}
                data-tooltip-id="toggle-text-align-left"
                // defaultPressed={activeSchema?.bold}
                pressed={
                  localStyle?.alignment == "left" || !localStyle?.alignment
                }
                onPressedChange={(value: boolean) => {
                  setLocalStyle((prev) => ({ ...prev, alignment: "left" }));
                }}
              >
                <MdFormatAlignLeft size={15} />
                <CustomTooltip
                  helpers={{
                    id: "toggle-text-align-left",
                    content: "Text Left Align",
                    place: "top",
                  }}
                />
              </BoloToggle>
              <BoloToggle
                size={"xs"}
                data-tooltip-id="toggle-text-align-center"
                // defaultPressed={activeSchema?.bold}
                pressed={localStyle?.alignment == "center"}
                onPressedChange={(value: boolean) => {
                  setLocalStyle((prev) => ({ ...prev, alignment: "center" }));
                }}
              >
                <MdFormatAlignCenter size={15} />
                <CustomTooltip
                  helpers={{
                    id: "toggle-text-align-center",
                    content: "Text Center Align",
                    place: "top",
                  }}
                />
              </BoloToggle>
              <BoloToggle
                size={"xs"}
                data-tooltip-id="toggle-text-align-right"
                // defaultPressed={activeSchema?.bold}
                pressed={localStyle?.alignment == "right"}
                onPressedChange={(value: boolean) => {
                  setLocalStyle((prev) => ({ ...prev, alignment: "right" }));
                }}
              >
                <MdFormatAlignRight size={15} />
                <CustomTooltip
                  helpers={{
                    id: "toggle-text-align-right",
                    content: "Text Right Align",
                    place: "top",
                  }}
                />
              </BoloToggle>
            </div>
          </div>

          <div className="flex justify-between items-center h-6">
            Text Formatting
            <div className="flex items-center justify-start gap-2.5 w-[41%]">
              <BoloPopover>
                <BoloPopoverTrigger>
                  <button
                    data-tooltip-id="btn-text-color"
                    className="h-5 w-5 flex items-center justify-center text-sm"
                    style={{
                      borderBottom: `4px solid ${
                        localStyle?.fontColor || "black"
                      }`,
                    }}
                    onClick={() => {
                      // setColorPicker((prev) => !prev);
                    }}
                  >
                    A
                    <CustomTooltip
                      helpers={{
                        id: "btn-text-color",
                        content: "Text Color",
                        place: "top",
                      }}
                    />
                  </button>
                </BoloPopoverTrigger>
                <BoloPopoverContent>
                  <SketchPicker
                    color={localStyle?.fontColor}
                    onChange={(e) => {
                      // console.log(e);
                      setLocalStyle((prev) => ({
                        ...prev,
                        fontColor: e.hex,
                      }));
                    }}
                  />
                </BoloPopoverContent>
              </BoloPopover>
              <BoloPopover>
                <BoloPopoverTrigger>
                  <button
                    data-tooltip-id="btn-background-color"
                    className="h-5 w-5 flex items-center justify-center text-sm"
                    style={{
                      borderBottom: `4px solid ${
                        localStyle?.backgroundColor || "white"
                      }`,
                    }}
                    onClick={() => {
                      // setColorPicker((prev) => !prev);
                    }}
                  >
                    <IoMdColorFill />
                    <CustomTooltip
                      helpers={{
                        id: "btn-background-color",
                        content: "Background Color",
                        place: "top",
                      }}
                    />
                  </button>
                </BoloPopoverTrigger>
                <BoloPopoverContent>
                  <SketchPicker
                    color={localStyle?.backgroundColor}
                    onChange={(e) => {
                      setLocalStyle((prev) => ({
                        ...prev,
                        backgroundColor: e.hex,
                      }));
                    }}
                  />
                </BoloPopoverContent>
              </BoloPopover>
              <BoloToggle
                size={"xs"}
                data-tooltip-id="toggle-bold"
                pressed={localStyle?.bold}
                onPressedChange={(value: boolean) => {
                  const fontName = getFontName({
                    bold: value,
                    italic: !!localStyle.italic,
                    currFont: localStyle.fontName || "",
                  });
                  setLocalStyle((prev) => ({ ...prev, fontName, bold: value }));
                }}
              >
                <FaBold size={12} />
                <CustomTooltip
                  helpers={{
                    id: "toggle-bold",
                    content: "Bold",
                    place: "top",
                  }}
                />
              </BoloToggle>
              <BoloToggle
                size={"xs"}
                data-tooltip-id="toggle-italic"
                pressed={localStyle?.italic}
                onPressedChange={(value: boolean) => {
                  let fontName = getFontName({
                    bold: !!localStyle.bold,
                    italic: value,
                    currFont: localStyle.fontName || "",
                  });
                  setLocalStyle((prev) => ({
                    ...prev,
                    fontName,
                    italic: value,
                  }));
                }}
              >
                <FaItalic size={12} />
                <CustomTooltip
                  helpers={{
                    id: "toggle-italic",
                    content: "Italic",
                    place: "top",
                  }}
                />
              </BoloToggle>
            </div>
          </div>

          <div className="flex justify-end items-center h-6 mt-6">
            <BoloButton
              variant={"secondary"}
              size="xs"
              onClick={() => setLocalStyle(STYLE_DEFAULTS)}
            >
              Reset All
            </BoloButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignView;
