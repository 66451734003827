// @ts-nocheck
import { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import NavBar from "../components/NavBar";
import AppContent from "../components/AppContent";
import { Navigate, useNavigate } from "react-router-dom";
import { fetchOwner, getOwnerPlanDetails, getWorkSpaceDetails } from "../utils";
import { FaArrowRight } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { getOwnerThunk } from "../redux/slices/ownerSlice";
import UpgradeModal from "../components/Ui/UpgradeModal";
import FeedInfoModal from "../components/Modals/FeedInfoModal";
import { GrClose } from "react-icons/gr";
import { SwitchWorkSpacesComponent } from "../components/SwitchWorkSpaces";
import ExtendTrialSetup from "../components/Ui/ExtendTrialSetup";

const DefaultLayout = () => {
  const [plan, setPlan] = useState(null);
  let navigate = useNavigate();
  let user = localStorage.getItem("owner");
  const [isOpen, setIsOpen] = useState(false);
  const [workSpaceData, setWorkSpaceData] = useState({
    isDownGrade: false,
  });
  const [showUpgrageModal, setShowUpgradeModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  const [showTrialExtension, setShowTrialExtension] = useState(false);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const workSpaceDetails = getWorkSpaceDetails();
  const userFromRedux = useAppSelector(
    (state) => state?.root?.ownerSlice?.data?.owner
  );

  const dispatch = useAppDispatch();
  const countDaysRemaining = () => {
    let user = userFromRedux;
    const currentPlan = user?.planhistory?.[0];
    let trialEndDate = currentPlan?.trialEndDate;
    let differenceInDays = 7;
    if (trialEndDate) {
      const givenDate = new Date(trialEndDate).getTime();
      const currentDate = new Date().getTime();
      const differenceInMs = givenDate - currentDate;
      differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    } else {
      const givenDate = new Date(user?.createdAt).getTime();
      const currentDate = new Date().getTime();
      const differenceInMs = currentDate - givenDate;
      differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    }

    return differenceInDays;
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("owner"));

    if (user && user._id) {
      dispatch(getOwnerThunk());
      // dispatch(getOwnerPaymentStatusThunk());
    }
  }, []);

  useEffect(() => {
    const updateLocalStore = async () => {
      // @ts-ignore
      let user = JSON.parse(localStorage.getItem("owner"));
      if (user === null) {
        navigate("/login");
      }
      if (user?.isOnboarding === false) {
        setShowInfoModal(true);
        navigate("/create");
      }

      if (user && user._id) {
        let owner = await fetchOwner(user?._id);
        if (owner) {
          localStorage.setItem("owner", JSON.stringify(owner));
          const ownerPlanDetails = getOwnerPlanDetails();
          const enabledFeatures = owner?.paymentStatus?.enabledFeatures;
          const isCollaborationAllowed =
            enabledFeatures?.includes("COLLABORATION");
          if (!owner.isWorkSpaceOwner && !isCollaborationAllowed) {
            console.log({ msg: "No Acces to this workspace" });
            setWarningModal(true);
          }
          setPlan(
            ownerPlanDetails?.workSpacePlan ||
              owner.planhistory[owner.planhistory.length - 1]
          );
        }
      }
    };
    if (window.innerWidth > 768) {
      setIsOpen(true);
    }
    updateLocalStore();
  }, [navigate]);
  useEffect(() => {
    let owner = JSON.parse(localStorage.getItem("owner"));

    const ownerPlanDetails = getOwnerPlanDetails();
    let plan =
      ownerPlanDetails?.workSpacePlan ||
      owner.planhistory[owner.planhistory.length - 1];
    if (
      !owner?.ofWhiteLabelOwner &&
      workSpaceDetails?.isWorkSpaceOwner &&
      owner?.currentWorkSpaceId === localStorage.getItem("workspaceid")
    ) {
      if (!window.location.href?.includes("assigned-to-me")) {
        setShowUpgradeModal(plan?.tier?.includes("FREE_TIER"));
      }
    }
  }, []);
  // console.log({ isOpen });

  // useEffect(() => {
  //   const checkWorkSpaceDownGrade = async () => {
  //     let owner = await JSON.parse(localStorage.getItem("owner"));
  //     let currentPlan = owner?.planhistory?.[owner?.planhistory?.length - 1];
  //     const workSpaceUsed = currentPlan?.usedQuanity?.[7] || 1;
  //     const workSpaceAllowed = currentPlan?.usageQuantityAllowed?.[7] || 1;

  //     if (workSpaceUsed > workSpaceAllowed) {
  //       setWorkSpaceData((prev) => ({ ...prev, isDownGrade: true }));
  //     } else {
  //       setWorkSpaceData((prev) => ({ ...prev, isDownGrade: false }));
  //     }
  //   };

  //   checkWorkSpaceDownGrade();

  //   window.addEventListener("storage", checkWorkSpaceDownGrade);
  //   return () => {
  //     window.removeEventListener("storage", checkWorkSpaceDownGrade);
  //   };
  // }, []);

  if (user === null) {
    return <Navigate to="/login" />;
  }

  return (
    <div className=" h-screen w-full overflow-hidden">
      {/* {workSpaceData?.isDownGrade && <WorkSpaceDownGrade setShow={() => {}} />} */}
      {plan && plan?.tier === "FREE_TRIAL_TIER" && (
        <div className="bg-violet-950 py-3 md:py-4 text-xs h-[30px] text-white flex items-center justify-center text-[13px]">
          <h2 className="hidden sm:block">
            You have {countDaysRemaining()}{" "}
            {countDaysRemaining() > 1 ? "days" : "day"} remaining of your free
            trial plan (Business Plan).{" "}
          </h2>
          <h2 className="block sm:hidden">You are on Free trial Plan</h2>
          {/* <ExtendTrialSetup /> */}
          <span
            className="ml-4 cursor-pointer underline font-semibold"
            onClick={() => {
              // navigate("/billings");
              // setShowTrialExtension(true);
              setShowTrialExtension((prev) => !prev);
            }}
          >
            Extend trial <FaArrowRight size={15} className="inline-block" />{" "}
          </span>
        </div>
      )}
      {plan &&
        plan?.tier?.includes("FREE_TIER") &&
        workSpaceDetails?.isWorkSpaceOwner && (
          <div className="bg-violet-950 py-3 md:py-4 text-xs h-[30px] text-white flex items-center justify-center text-[13px]">
            <h2 className="hidden sm:block">
              Your Free quota usage Document Signed :{" "}
              <span className="font-semibold ">
                {plan?.usedQuanity?.[0]} / {plan?.usageQuantityAllowed?.[0]}
              </span>
              , Form Signed :{" "}
              <span className="font-semibold">
                {plan?.usedQuanity?.[2]} / {plan?.usageQuantityAllowed?.[2]}
              </span>
              , PDF Templates created:{" "}
              <span className="font-semibold">
                {plan?.usedQuanity?.[3]} / {plan?.usageQuantityAllowed?.[3]}
              </span>{" "}
            </h2>
            <h2 className="block sm:hidden">You are on Free Plan</h2>
            <span
              className="ml-4 cursor-pointer underline font-semibold"
              onClick={() => {
                navigate("/billings");
              }}
            >
              Upgrade Now <FaArrowRight size={15} className="inline-block" />{" "}
            </span>
          </div>
        )}

      <div
        className="flex flex-row h-screen w-full overflow-hidden"
        style={{
          height:
            plan &&
            plan?.tier?.includes("FREE_TIER") &&
            workSpaceDetails?.isWorkSpaceOwner
              ? "calc(100vh - 30px)"
              : "100vh",
        }}
      >
        <div>
          <SideBar
            helpers={{ isOpen, setIsOpen }}
            height={plan && plan?.planId === "FREE_PLAN" ? "30" : "0"}
            isFree={plan && plan?.planId === "FREE_PLAN"}
          />
        </div>
        <div
          className={`relative
          w-[calc(100%_-_0px)] md:w-[calc(100%_-_250px)] `}
        >
          <NavBar helpers={{ isOpen, setIsOpen, showTrialExtension }} />
          <div
            style={
              {
                // height: "calc(100vh - 70px)",
              }
            }
            className="mt-[50px] md:mt-[60px] h-[calc(100%_-_50px)] md:h-[calc(100%_-_60px)] p-4 w-full overflow-y-auto"
            id="mini-pdf-custom-scroll"
          >
            {/* <div className=""> */}
            <AppContent />
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* Warning Modal */}
      {warningModal && (
        <div className="fixed top-0 left-0 z-[10000] w-full min-h-full max-h-[100vh] p-4 md:p-8 bg-[#0000004c] justify-center items-start overflow-y-auto flex">
          <div className="bg-white rounded  w-[95%] md:w-[800px]  border border-gray-300">
            <div className=" md:px-8 px-4 py-6">
              <h2 className="text-base  md:text-base font-medium md:leading-[2] leading-[2]">
                Limited Access Due to Subscription Status
              </h2>
              <p className="text-xs text-gray-600 leading-normal mb-2 text-justify">
                The owner of this workspace currently does not have a proper
                subscription for using Teams. As a result, certain features and
                functionalities may be limited or unavailable.
              </p>
              <p className="text-xs text-gray-600 leading-normal text-justify mb-4">
                We recommend discussing this with the workspace owner to ensure
                a smooth and uninterrupted collaboration experience. Thank you
                for your understanding and cooperation.
              </p>
              <h3 className="mb-4 font-semibold text-sm">
                Please Switch the workspace
              </h3>
              <SwitchWorkSpacesComponent isModal={false} />
            </div>
          </div>
        </div>
      )}

      {showUpgrageModal && <UpgradeModal setShow={setShowUpgradeModal} />}
      {showInfoModal && <FeedInfoModal setShowInfoModal={setShowInfoModal} />}
    </div>
  );
};

export default DefaultLayout;
