import React from "react";
import { FaInfo } from "react-icons/fa6";
import { BoloButton } from "../../../../../components/Common/Button/BoloButton";
import BoloModal from "../../../../../components/Common/Modal/BoloModal";
import { SchemaForUI } from "../../../../common/src";
import { DesignerState, GlobalHelpersType } from "../Designer";
type LinkLinkGroupErrorProps = {
  setShow: (value: boolean) => void;
  handleOk: () => void;
  errors: string[];
  errorSchema: SchemaForUI | null;
  onEdit: (targets: HTMLElement[]) => void;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  pageNum: number;
  globalHelpers: GlobalHelpersType;
};

const LinkGroupError = ({
  setShow,
  handleOk,
  errors,
  errorSchema,
  onEdit,
  setDesignerState,
  pageNum,
  globalHelpers,
}: LinkLinkGroupErrorProps) => {
  return (
    <>
      <div className="text-base md:text-lg mb-4 font-semibold">
        Linked Elements Mismatch
      </div>
      <div className="gap-2 items-center justify-between text-xs">
        <p>
          There are linked elements in the document which have different
          properties{" "}
        </p>
        <p>Sending this may result in unexpected behaviour</p>
        <p>
          You can procced or correct the values in element before sending the
          document
        </p>
        <div className="mt-3 text-orange-600 gap-1 flex flex-col text-[11px]">
          <p className="tex-xs font-semibold">
            Mismatch in {errorSchema?.data || errorSchema?.placeholder}
          </p>
          {errors.map((error) => {
            return (
              <div className="flex items-center p-1 rounded-md px-3 bg-orange-200">
                <FaInfo className="mr-3" /> {error}
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="flex mt-8 gap-2 items-center justify-between"> */}
      <div className="flex gap-2 items-center justify-between mt-6 ">
        {/* <BoloButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </BoloButton> */}
        <BoloButton
          variant={"danger-border"}
          size={"sm"}
          onClick={() => {
            handleOk();
            setShow(false);
          }}
        >
          Proceed
        </BoloButton>
        <BoloButton
          variant={"primary"}
          size={"sm"}
          disabled={!errorSchema}
          onClick={() => {
            if (errorSchema) {
              globalHelpers.setPageIndex(pageNum);
              const target = document.getElementById(errorSchema?.id);
              if (target) {
                onEdit([target]);
              }
              setDesignerState((prev) => ({
                ...prev,
                combinedSidebar: {
                  ...prev.combinedSidebar,
                  open: true,
                  selectedView: "DETAIL_VIEW",
                },
              }));
              setShow(false);
            }
          }}
        >
          Jump To Element
        </BoloButton>
        {/* </div> */}
      </div>
    </>
  );
};

type LinkGroupErrorModalProps = LinkLinkGroupErrorProps;

const LinkGroupErrorModal = ({
  setShow,
  handleOk,
  errors,
  errorSchema,
  onEdit,
  setDesignerState,
  pageNum,
  globalHelpers,
}: LinkGroupErrorModalProps) => {
  return (
    <BoloModal
      setShow={setShow}
      content={
        <LinkGroupError
          setShow={setShow}
          handleOk={handleOk}
          errors={errors}
          errorSchema={errorSchema}
          onEdit={onEdit}
          setDesignerState={setDesignerState}
          pageNum={pageNum}
          globalHelpers={globalHelpers}
        />
      }
    />
  );
};

export default LinkGroupErrorModal;
