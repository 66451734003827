import { useEffect, useMemo, useState } from "react";
import { BoloButton } from "../../../../../components/Common/Button/BoloButton";
import BoloModal from "../../../../../components/Common/Modal/BoloModal";
import { fetchOwner } from "../../../../../utils";
import { BASE_URL } from "../../../../../constants/constants";
import axios from "../../../../../utils/axios";
import BoloLoader from "../../../../../components/Common/Loader/BoloLoader";
import { GrSearch } from "react-icons/gr";
import Select from "react-select";
import {
  DesignerState,
  generateUniqueKey,
  GlobalHelpersType,
} from "../Designer";
import { getUniqSchemaKey } from "../../helper";
import { v4 } from "uuid";
import { SIZES } from "../../constants";
import { SchemaForUI } from "../../../../common/src";
import { toast } from "react-toastify";
import { SchemaForUI as SchemaForUIZod } from "../../../../common/src/schema";
import { CustomVariableType } from "../../../../common/src/type";

interface ImportsFieldsType {
  setShowImportFieldsModal: React.Dispatch<React.SetStateAction<boolean>>;
  documentId: string;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
  // addSchema: any;
}

const ImportFields = ({
  setShowImportFieldsModal,
  documentId,
  designerState,
  globalHelpers,
  // addSchema,
  setDesignerState,
}: ImportsFieldsType) => {
  const [owner, setOwner] = useState<any>({});
  const [fetchedDocuments, setfetchedDocuments] = useState<any[]>([]);
  const [formsCount, setFormsCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocumet] = useState<any>({});
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showDocsModal, setShowDocsModal] = useState<boolean>(true);
  const [selectedDocumentRoles, setSelectedDocumentRoles] = useState<any>({});
  const [showPagesWarnModal, setShowPagesWarnModal] = useState<boolean>(false);
  const [showRolesAssignModal, setShowRolesAssignModal] =
    useState<boolean>(false);

  // const generateUniqueKey = (baseKey: string) => {
  //   const pageSchemas = designerState?.schemasList[designerState?.pageCursor];
  //   let uniqueKey = baseKey.replace(/\n$/, "");
  //   let index = 1;
  //   // let uniqueKey = baseKey;
  //   // if (isOnlyPdfTemplate) {
  //   //   while (
  //   //     pageSchemas.some(
  //   //       (schema) => schema.key === uniqueKey + `(${pageCursor + 1})`
  //   //     )
  //   //   ) {
  //   if (
  //     (Array.isArray(designerState?.receiversList) &&
  //       designerState?.receiversList.length > 0) ||
  //     designerState?.instanceFrom === "PDF-TEMPLATE"
  //   ) {
  //     while (
  //       pageSchemas.some(
  //         (schema) =>
  //           schema.key === uniqueKey + `(${designerState?.pageCursor + 1})`
  //       )
  //     ) {
  //       uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
  //       index++;
  //     }
  //     uniqueKey = `${uniqueKey}(${designerState?.pageCursor + 1})`;
  //   } else {
  //     while (pageSchemas.some((schema) => schema.key === uniqueKey)) {
  //       uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
  //       index++;
  //     }
  //   }
  //   return uniqueKey;
  // };

  // const functionalData = {
  //   data: {
  //     title: "Signature",
  //     type: "Signature",
  //     id: v4(),
  //     required: false,
  //     subType: "signature",
  //     // role: selectedRole,
  //   },
  //   type: "PDF_TEMPLATE",
  //   size: {
  //     width: SIZES.isFormMapper.isSignature.width,
  //     height: SIZES.isFormMapper.isSignature.height,
  //   },
  // };

  // if any new fields are added in content view this it to be updated
  const subType2TitlePdfTempalte: { [key: string]: string } = {
    signature: "Signature",
    initials: "Initials",
    stamp: "Stamp",
    text: "Text",
    number: "Number",
    date: "Date",
    checkbox: "Checkbox",
    radiogroup: "RadioGroup",
    dropdown: "DropDown",
    name: "Name",
    email: "Email",
    static_image: "Image",
    table: "Table",
  };

  // i dont remmber why radio and check box do not have signer name
  // there fore having this here
  const subType2TitlePdf: { [key: string]: string } = {
    checkbox: "Checkbox",
    radiogroup: "RadioGroup",
  };

  const getSelectedDocument = async () => {
    try {
      const { data } = await axios.get(
        `/getOneDocument?documentId=${selectedDocument?.documentId}`
      );
      return data;
    } catch (error) {
      console.log(error);
      toast.error("Could not fetch the document");
    }
  };

  const importFields = async () => {
    setLoading(true);
    try {
      const data = await getSelectedDocument();
      // this is the schema from which we import
      let schemasSource = [...(data?.document?.pdfTemplate?.schemas || [])];
      let variableSource: CustomVariableType[] = [
        ...(data?.document?.variables || []),
      ]?.map((elm) => ({ varName: elm?.varName, varValue: elm?.varValue }));

      // to create a deep copy
      let designerStateClone: DesignerState = structuredClone({
        ...designerState,
      });
      // removing duplicates in varibale source
      let noDupsVariables = [...designerStateClone.customVariables];
      for (let variable of variableSource) {
        let varNameExists = designerState.customVariables.find(
          (elm) => elm.varName == variable.varName
        );
        if (!varNameExists) {
          noDupsVariables.push(variable);
        }
      }
      // console.log({ noDupsVariables });

      // say is curr doc is of len 2 and source doc is of len 6
      // min len will make sure that iteration is done 2 times only
      let minLen = Math.min(
        schemasSource?.length,
        designerStateClone?.schemasList?.length
      );

      // iterate over source schemas
      for (let pgIdx = 0; pgIdx < minLen; pgIdx++) {
        // let pgIdx = parseInt(pgIdx_);
        let page = schemasSource?.[pgIdx];
        console.log({ pgIdx, len: Object.entries(page).length, page });
        for (let [key, value] of Object.entries<{ [key: string]: any }>(page)) {
          // Schema list route
          // i.e we modify the schema in editor and the debounce should save everything

          // # Processing schema -------------------------------------------------------------
          if (!value?.isStatic && value?.subType !== "variable") {
            if (designerState?.instanceFrom === "PDF-TEMPLATE") {
              let roleName = value?.role?.title;
              // this is the role we select from source document
              let oldRole = selectedDocumentRoles?.find(
                (ele: any) => ele?.title === roleName
              );

              if (oldRole?.inUse) {
                let newRole = designerStateClone?.roles?.find(
                  (ele: any) => ele?.title === oldRole?.newName
                );

                // this is the new migrated schema hopefully compatible with setSchemas
                //@ts-ignore
                let newSchema: SchemaForUI = {
                  ...value,
                  key: generateUniqueKey(
                    // as we dont store title in db i have created a map to get title from subtype
                    subType2TitlePdfTempalte[value?.subType] || "Signer",
                    designerStateClone,
                    pgIdx
                  ),
                  id: v4(),
                  role: { colour: newRole?.colour, title: newRole?.title },
                  signatureColour: newRole?.colour,
                  //@ts-ignore
                  // signatureColour: false,
                };

                SchemaForUIZod.parse(newSchema);
                designerStateClone.schemasList[pgIdx] = [
                  ...designerStateClone.schemasList[pgIdx],
                  newSchema,
                ];
              }
            }

            if (designerState?.instanceFrom === "PDF") {
              let roleId = value?.id?.split("_")[1];
              let oldRole = selectedDocumentRoles?.find(
                (ele: any) => ele?._id === roleId
              );

              if (oldRole?.inUse) {
                let newRole: any = designerStateClone?.receiversList?.find(
                  (ele) => ele?.email === oldRole?.newEmail
                );
                let id = v4() + `_${newRole?._id}_${newRole?._id}`;
                //@ts-ignore
                let newSchema: SchemaForUI = {
                  ...value,
                  key: generateUniqueKey(
                    // as we dont store title in db i have created a map to get title from subtype
                    subType2TitlePdf[value?.subType] ||
                      newRole?.name ||
                      "Signer",
                    designerStateClone,
                    pgIdx
                  ),
                  role: { colour: newRole?.colour, title: newRole?.name },
                  signatureColour: newRole?.colour,
                  id,
                };
                SchemaForUIZod.parse(newSchema);
                designerStateClone.schemasList[pgIdx] = [
                  ...designerStateClone.schemasList[pgIdx],
                  newSchema,
                ];
              }
            }
          } else {
            // # static fields
            if (value?.isStatic) {
              //@ts-ignore
              let newSchema: SchemaForUI = {
                ...value,
                id: v4(),
                key: generateUniqueKey(
                  subType2TitlePdfTempalte[value?.subType] || "Signer",
                  designerStateClone,
                  pgIdx
                ),
              };
              SchemaForUIZod.parse(newSchema);
              designerStateClone.schemasList[pgIdx] = [
                ...designerStateClone.schemasList[pgIdx],
                newSchema,
              ];
            }
            // $ static fields
            // # variables
            if (value?.subType === "variable") {
              //@ts-ignore
              let newSchema: SchemaForUI = {
                ...value,
                id: v4(),
                key: generateUniqueKey(
                  noDupsVariables.find(
                    (elm) => elm.varName == value?.variableName
                  )?.varName || "Signer",
                  designerStateClone,
                  pgIdx
                ),
              };
              SchemaForUIZod.parse(newSchema);
              designerStateClone.schemasList[pgIdx] = [
                ...designerStateClone.schemasList[pgIdx],
                newSchema,
              ];
            }
            // $ variables
          }
          // $ Processing schema -------------------------------------------------------------
        }
      }

      // console.log(
      //   "schema list sc",
      //   structuredClone(designerStateClone.schemasList)
      // );

      setDesignerState((prev) => ({
        ...prev,
        schemasList: structuredClone(designerStateClone.schemasList),
        customVariables: noDupsVariables,
      }));
      toast.success("Fields Imported Successfully");
      setShowImportFieldsModal(false);
    } catch (error) {
      console.log("error in importFields");
      console.log(error);
      toast.error("Import Failed");
      setLoading(false);
    }
    setLoading(false);
  };

  // const importFieldsOld = async () => {
  //   setLoading(true);
  //   try {
  //   } catch (error) {
  //     console.error("error while feching document ");
  //   }
  //   const { data } = await axios.get(
  //     `/getOneDocument?documentId=${selectedDocument?.documentId}`
  //   );
  //   let schemas = data?.document?.pdfTemplate?.schemas;

  //   // const dataToadd: SchemaForUI = {
  //   //   ...functionalData?.data,
  //   //   id: v4(),
  //   //   role: { colour: "#fffacd", title: "Main" },
  //   //   position: { x: 10, y: 10 },
  //   //   key: generateUniqueKey(functionalData?.data?.title),
  //   // };
  //   // let newSchemaList = [...designerState?.schemasList];
  //   // newSchemaList[1] = [...newSchemaList[1], dataToadd];
  //   // console.log("new schema list", newSchemaList);
  //   // setDesignerState((prev) => ({ ...prev, schemasList: newSchemaList }));

  //   const newSchemas = schemas.forEach((obj: any, index: number) => {
  //     let newObj: Record<string, any> = {};
  //     Object.entries(obj).forEach(([key, value]: [string, any]) => {
  //       // let newKey = key.endsWith("import") ? key : key + "import";
  //       // console.log("value", value);
  //       // const newKey = getUniqSchemaKey({
  //       //   copiedSchemaKey: key,
  //       //   value,
  //       //   stackUniqSchemaKeys: [],
  //       // });
  //       console.log("value", value);

  //       // if (!value?.isStatic && value?.subType !== "variable") {
  //       if (designerState?.instanceFrom === "PDF-TEMPLATE") {
  //         let roleName = value?.role?.title;
  //         let oldRole = selectedDocumentRoles?.find(
  //           (ele: any) => ele?.title === roleName
  //         );
  //         if (oldRole?.inUse) {
  //           let newRole = designerState?.roles?.find(
  //             (ele: any) => ele?.title === oldRole?.newName
  //           );
  //           let newSchema = {
  //             ...value,
  //             id: v4(),
  //             role: { colour: newRole?.colour, title: newRole?.title },
  //             signatureColour: newRole?.colour,
  //           };
  //           console.log("newSchema", newSchema);

  //           // newObj[newKey] = newSchema;
  //         }
  //       } else if (designerState?.instanceFrom === "PDF") {
  //         // let roleId = value?.id?.split("_")[1];
  //         // let oldRole = selectedDocumentRoles?.find(
  //         //   (ele: any) => ele?._id === roleId
  //         // );
  //         // if (oldRole?.inUse) {
  //         //   let newRole: any = designerState?.receiversList?.find(
  //         //     (ele) => ele?.email === oldRole?.newEmail
  //         //   );
  //         //   let id =
  //         //     value?.id.split("_")[0] + `_${newRole?._id}_${newRole?._id}`;
  //         //   newObj[newKey] = {
  //         //     ...value,
  //         //     role: { colour: newRole?.colour, title: newRole?.name },
  //         //     signatureColour: newRole?.colour,
  //         //     id,
  //         //   };
  //         // }
  //       }
  //       // } else {
  //       //   // newObj[newKey] = { ...value };
  //       // }
  //     });
  //     // return newObj;
  //   });

  //   const fetchedData = await axios.post("/document/importFields", {
  //     newSchemas,
  //     documentId,
  //   });
  //   setLoading(false);
  //   setShowImportFieldsModal(false);
  //   // window.location.reload();
  // };
  // roles for the current document
  const roles = useMemo(() => {
    let roles: { value: string; label: string }[] = [];
    if (designerState?.instanceFrom === "PDF-TEMPLATE") {
      designerState?.roles?.forEach((ele) =>
        roles.push({ value: ele?.title, label: ele?.title })
      );
    }
    if (designerState?.instanceFrom === "PDF") {
      designerState?.receiversList?.forEach((ele) =>
        roles.push({ value: ele?.email, label: ele?.email })
      );
    }
    return roles;
  }, [designerState]);

  const getDocumentsBySearch = async (e: any, documentType: string) => {
    // e && e.preventDefault();
    let isStandAloneTemplate = documentType === "PDF-TEMPLATE";
    try {
      // if (searchText?.trim()?.length > 0) {
      // @ts-ignore
      let owner = JSON.parse(localStorage.getItem("owner"));
      let currentUserEmail =
        localStorage.getItem("currentUserEmail") ?? owner?.email;

      const ownerId = owner?._id;

      // let searchDocumentRequestUrl = `/get-all-documents/search?ownerId=${ownerId}&query=${searchText}&filter=All&currentUserEmail=${currentUserEmail}&page=1&limit=5&accountType=owner&documentType=${documentType}&isStandAloneTemplate=${isStandAloneTemplate}`;
      let searchDocumentRequestUrl = `/get-all-documents/v1?ownerId=${ownerId}&query=${
        searchText || ""
      }&filter=ALL&currentUserEmail=${currentUserEmail}&page=1&limit=${
        !searchText ? 5 : -1
      }&accountType=owner&documentType=${documentType}&isStandAloneTemplate=${isStandAloneTemplate}`;

      setLoading(true);
      let { data } = await axios.post(searchDocumentRequestUrl);
      // let docs = data.documents?.filter(
      //   (doc: any) => doc?.documentId !== documentId
      // );
      setfetchedDocuments(data.documents);
      setFormsCount(data?.documentsCount);
      setLoading(false);
      // }
    } catch (error) {
      setLoading(false);

      console.log("Error getting documents by search", error);
    }
  };

  useEffect(() => {
    if (!searchText) {
      getDocumentsBySearch(undefined, designerState?.instanceFrom);
    }
  }, [searchText, owner]);

  // const getAllDocuments = async (
  //   page: string,
  //   limit: string,
  //   filter = "ALL"
  // ) => {
  //   try {
  //     setLoading(true);
  //     let currentUserEmail = localStorage.getItem("currentUserEmail");

  //     let requestUrl;
  //     let requestOptions;

  //     // requestUrl = `${BASE_URL}/getAlldocuments?page=${page}&limit=${limit}&filter=${filter}`;
  //     requestUrl = `${BASE_URL}/get-all-documents/v1?page=${page}&limit=${limit}&filter=${filter}`;

  //     requestOptions = {
  //       authorEmail: currentUserEmail,
  //       ownerId: owner?._id,
  //       isStandAloneTemplate: designerState?.instanceFrom === "PDF-TEMPLATE",
  //     };
  //     if (!requestUrl) {
  //       setfetchedDocuments([]);
  //       setFormsCount(0);
  //       setLoading(false);
  //       return;
  //     }
  //     let { data } = await axios.post(requestUrl, requestOptions);
  //     let docs = data.documents?.filter(
  //       (doc: any) => doc?.documentId !== documentId
  //     );
  //     setfetchedDocuments(docs?.length > 5 ? docs.slice(0, 5) : docs);
  //     setFormsCount(data.documentsCount);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("Error getting forms", error);
  //   }
  // };

  useEffect(() => {
    const getUser = async () => {
      let owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(owner);
    };
    getUser();
  }, []);

  useEffect(() => {
    // getAllDocuments("1", "6");
  }, [owner]);

  return (
    <>
      {showDocsModal && (
        <BoloModal
          content={
            <>
              <div className="text-base md:text-lg mb-4 font-semibold">
                Import Fields
              </div>
              <div className="gap-2 items-center justify-between text-xs text-gray-600">
                <p>
                  Select a document that has fields. The number of roles and
                  fields on each document is shown in the list below. You will
                  be able to assign roles to imported fields in the next step.
                </p>
              </div>
              <div
                className={`flex w-full border-b-2 mt-5 ${
                  isFocused ? "border-primary" : ""
                } pb-2 mb-3`}
                onClick={() => setIsFocused(true)}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    getDocumentsBySearch(e, designerState?.instanceFrom);
                  }}
                  className="flex w-full"
                >
                  <button type="submit">
                    <GrSearch className="text-gray-400 text-xl" />
                  </button>
                  <input
                    placeholder="Search by Document name"
                    value={searchText}
                    className="outline-none w-[95%] text-sm  px-2 "
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                </form>
              </div>

              <div
                className=" mt-5 min-h-[320px] max-h-[395px] overflow-y-auto"
                id="mini-pdf-custom-scroll"
              >
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="px-6 py-2 text-left text-xs font-medium text-gray-600  tracking-wider">
                        Document / Created On
                      </th>
                      <th className="px-6 py-2 text-left text-xs font-medium text-gray-600  tracking-wider">
                        Number of Roles
                      </th>
                      <th className="px-6 py-2 text-left text-xs font-medium text-gray-600  tracking-wider">
                        Fields
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tr role={"row"} className={"w-full text-center"}>
                      <td className={"w-full"} colSpan={8}>
                        <div className="p-8">
                          <BoloLoader />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tbody className="bg-white divide-y divide-gray-200">
                      {fetchedDocuments?.map((doc, index) => (
                        <tr
                          key={index}
                          className={`hover:bg-secondary/60 ${
                            selectedDocument?._id === doc?._id &&
                            "bg-secondary/60"
                          } ${
                            doc?.documentId === documentId
                              ? "opacity-40 cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (doc?.documentId === documentId) {
                              return;
                            }
                            setSelectedDocumet(doc);
                            let documentRoles = [];
                            if (designerState?.instanceFrom === "PDF") {
                              documentRoles = doc?.respondentsOfDoc?.map(
                                (role: any) => ({
                                  ...role,
                                  inUse: true,
                                })
                              );
                            } else if (
                              designerState?.instanceFrom === "PDF-TEMPLATE"
                            ) {
                              documentRoles = doc?.roles?.map((role: any) => ({
                                ...role,
                                inUse: true,
                              }));
                            }
                            setSelectedDocumentRoles(documentRoles);
                          }}
                        >
                          <td className="px-6 py-3 whitespace-nowrap max-w-xs">
                            <div className="truncate">{doc?.documentName}</div>
                            <div className="text-gray-500 text-xs">
                              {new Date(doc?.createdAt).toDateString()}
                            </div>
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            {designerState?.instanceFrom === "PDF-TEMPLATE"
                              ? doc?.roles?.length
                              : doc?.respondentsOfDoc?.length}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            {doc?.schemaFieldsCount || 0}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>

              <div className="flex gap-2 items-center justify-end mt-8">
                <BoloButton
                  variant={"secondary"}
                  size={"sm"}
                  onClick={() => setShowImportFieldsModal(false)}
                >
                  Cancel
                </BoloButton>
                <BoloButton
                  variant={"primary"}
                  size={"sm"}
                  disabled={Object.keys(selectedDocument).length === 0}
                  onClick={() => {
                    setShowDocsModal(false);
                    setShowRolesAssignModal(true);
                  }}
                >
                  Next
                </BoloButton>
              </div>
            </>
          }
          isNoCross={false}
          setShow={setShowImportFieldsModal}
        />
      )}

      {showRolesAssignModal && (
        <BoloModal
          isWidthFit={true}
          content={
            <>
              <div className="text-base md:text-lg mb-4 font-semibold">
                Import Fields
              </div>

              <div
                className=" mt-5  overflow-y-auto min-h-[320px]"
                id="mini-pdf-custom-scroll"
              >
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="">
                    <tr>
                      <th className="px-6 py-2 text-left text-xs font-medium text-gray-600  tracking-wider">
                        Import Role
                      </th>
                      <th className="px-6 py-2 text-left text-xs font-medium text-gray-600  tracking-wider">
                        Role
                      </th>
                      <th className="px-6 py-2 text-left text-xs font-medium text-gray-600  tracking-wider">
                        Assign Role
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 mb-10">
                    {selectedDocumentRoles?.map((role: any, index: number) => (
                      <tr
                        key={index}
                        className={`hover:bg-secondary/60 cursor-pointer 
                       bg-secondary/60`}
                      >
                        <td className="px-6 py-4 whitespace-nowrap max-w-xs">
                          <input
                            type="checkbox"
                            checked={role?.inUse}
                            onChange={(e) => {
                              setSelectedDocumentRoles((prev: any) =>
                                prev.map((ele: any, i: number) => {
                                  if (i === index) {
                                    return { ...ele, inUse: !ele.inUse };
                                  }
                                  return ele;
                                })
                              );
                            }}
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex flex-col md:min-w-[100px]">
                            <div className="whitespace-nowrap font-medium text-sm ">
                              {role?.title || role?.name}
                            </div>
                            {role?.email && (
                              <div className="whitespace-nowrap overflow-hidden text-[10px] leading-[17px] text-xs  text-gray-600">
                                {role?.email}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap ">
                          <Select
                            className="basic-single md:w-[300px] "
                            classNamePrefix="select"
                            isDisabled={!role?.inUse}
                            isLoading={false}
                            isClearable={true}
                            onChange={(selectedValue) => {
                              setSelectedDocumentRoles((prev: any) =>
                                prev.map((ele: any, i: number) => {
                                  if (i === index) {
                                    return {
                                      ...ele,
                                      newEmail: selectedValue?.value,
                                      newName: selectedValue?.label,
                                    };
                                  }
                                  return ele;
                                })
                              );
                            }}
                            isSearchable={true}
                            name="color"
                            options={roles}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="flex gap-2 items-center justify-end mt-8">
                <BoloButton
                  variant={"primary"}
                  size={"sm"}
                  onClick={() => {
                    setShowRolesAssignModal(false);
                    setShowDocsModal(true);
                  }}
                >
                  Back
                </BoloButton>
                <BoloButton
                  variant={"secondary"}
                  size={"sm"}
                  onClick={() => {
                    setShowImportFieldsModal(false);
                  }}
                >
                  Close
                </BoloButton>
                <BoloButton
                  variant={"primary"}
                  size={"sm"}
                  disabled={
                    !selectedDocumentRoles?.some((ele: any) => ele?.inUse) || // Check if at least one role has inUse true
                    !selectedDocumentRoles?.every(
                      (ele: any) => !ele?.inUse || ele?.newEmail
                    ) // Check if every inUse true has newEmail set
                  }
                  onClick={() => {
                    if (
                      selectedDocument?.pagesCount >
                      designerState?.schemasList?.length
                    ) {
                      setShowRolesAssignModal(false);
                      setShowPagesWarnModal(true);
                      return;
                    }
                    importFields();
                  }}
                >
                  {loading ? "Importing..." : "Import Field(s)"}
                </BoloButton>
              </div>
            </>
          }
          isNoCross={false}
          setShow={setShowImportFieldsModal}
        />
      )}
      {showPagesWarnModal && (
        <BoloModal
          content={
            <>
              <div className="text-base md:text-lg mb-4 font-semibold">
                Page Numbers Do Not Match
              </div>

              <div className="gap-2  items-center justify-between text-sm text-gray-600">
                <p>
                  This document has fewer pages than the document you are
                  importing from. Fields from extra pages will be lost.
                </p>
              </div>

              <div className="flex gap-2 items-center justify-end mt-8">
                <BoloButton
                  variant={"secondary"}
                  size={"sm"}
                  onClick={() => {
                    setShowImportFieldsModal(false);
                  }}
                >
                  Close
                </BoloButton>
                <BoloButton
                  variant={"primary"}
                  size={"sm"}
                  onClick={importFields}
                >
                  Import Anyway
                </BoloButton>
              </div>
            </>
          }
          isWidthFit={false}
          setShow={setShowImportFieldsModal}
          isNoCross={false}
        />
      )}
    </>
  );
};

export default ImportFields;
