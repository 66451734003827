import React, { useEffect, useState } from "react";
import BoloRadioButtonMui from "../Common/RadioButton/BoloRadioButtonMui";
import { useAppSelector } from "../../redux/store";
import BoloModal from "../Common/Modal/BoloModal";
import DropboxFolderSelect from "../DropboxFolderSelect";
import { Slide, toast, ToastContainer } from "react-toastify";
import axios from "../../utils/axios";
import {
  dropboxAuthRedirectUrl,
  dropboxClientID,
} from "../../constants/Integrations/dropbox";

interface DropboxDriveModalTypes {
  setShowDropboxDriveModal: React.Dispatch<React.SetStateAction<boolean>>;
  driveModalDoc: any;
  setDropboxModalDoc: React.Dispatch<React.SetStateAction<any>>;
}
const DropboxDriveModal = ({
  setShowDropboxDriveModal,
  driveModalDoc,
  setDropboxModalDoc,
}: DropboxDriveModalTypes) => {
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const [showDropBoxChooser, setShowDropBoxChooser] = useState<boolean>(false);
  const isIntegrated =
    owner?.integrationTokens?.dropbox?.access_token &&
    owner?.integrationTokens?.dropbox?.access_token !== "";
  const handleSubmit = async (file: any) => {
    try {
      const { data } = await axios.post("/dropbox/setFolderForDoc", {
        file,
        documentId: driveModalDoc?.documentId,
        ownerEmail: owner?.email,
      });
      if (data?.success) {
        toast.success("Dropbox info updated!");
      } else {
        toast.error(data?.message);
      }
      setShowDropBoxChooser(false);
    } catch (e) {
      console.log("");
    }
  };
  return (
    <div>
      <BoloModal
        setShow={setShowDropboxDriveModal}
        content={
          <>
            <h2 className="mb-1 text-lg md:text-xl font-semibold">
              Dropbox Integration
            </h2>
            <p className="text-xs text-gray-600 pb-2 mb-6 border-b-2 border-gray-200">
              Select drive folder to save the response pdf files to your
              Dropbox.
            </p>

            {!isIntegrated && (
              <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
                <h2 className="mb-1 text-xs md:text-sm">
                  You are not connected to Dropbox.
                </h2>
                <p className="text-xs text-gray-500 mb-4">
                  Connect your Dropbox to save the finished PDF to your
                  specified Dropbox folder.
                </p>
                <button
                  className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                  onClick={() => {
                    window.open(
                      `https://www.dropbox.com/oauth2/authorize?client_id=${dropboxClientID}&token_access_type=offline&response_type=code&state=${owner?.email};${window.location.pathname}&redirect_uri=${dropboxAuthRedirectUrl}`,
                      "_self"
                    );
                  }}
                >
                  Connect to Dropbox
                </button>
              </div>
            )}
            {isIntegrated && (
              <div className="flex justify-between">
                {!driveModalDoc?.parentFolderId ? (
                  <div>
                    <h2 className="mb-1 text-xs md:text-smfont-medium text-gray-700">
                      Your document is not connected to any Dropbox Folder
                    </h2>
                    <p className="text-xs text-gray-500 mb-4">
                      Pick a folder to save your form responses as PDFs.
                    </p>
                    <button
                      className="w-[50%] md:w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                      onClick={async () => {
                        setShowDropBoxChooser(true);
                      }}
                      disabled={showDropBoxChooser}
                    >
                      Select Folder
                    </button>
                  </div>
                ) : (
                  <div>
                    <h2 className="mb-1 text-xs md:text-sm font-medium text-gray-700">
                      Your document is connected to Dropbox.
                    </h2>
                    <p className="text-xs text-gray-500 mb-4">
                      Your document responses will be saved to this Drive
                      Folder:{" "}
                      <span
                        className="text-primary font-semibold cursor-pointer"
                        onClick={() => {
                          window.open(driveModalDoc?.parentFolderUrl, "_blank");
                        }}
                      >
                        {driveModalDoc?.parentFolderName}
                      </span>
                    </p>
                    <div className="w-full flex justify-between gap-4">
                      <button
                        className="w-full border-2 border-primary bg-white inset-2 text-primary px-4 py-2 rounded-md text-xs mr-1"
                        onClick={async (e) => {
                          e.stopPropagation();
                          setShowDropBoxChooser(true);
                        }}
                      >
                        Update Folder
                      </button>
                      <button
                        className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(driveModalDoc?.parentFolderUrl, "_blank");
                        }}
                      >
                        View Folder
                      </button>
                    </div>
                  </div>
                )}
                <BoloRadioButtonMui
                  variant="bolo-primary"
                  checked={driveModalDoc?.enabled}
                  onChange={async (e) => {
                    setDropboxModalDoc((prev: any) => ({
                      ...prev,
                      enabled: e.target.checked,
                    }));
                    await handleSubmit({
                      enabled: e.target.checked,
                      parentFolderId: driveModalDoc?.parentFolderId,
                      parentFolderName: driveModalDoc?.parentFolderName,
                      parentFolderUrl: driveModalDoc?.parentFolderUrl,
                    });
                  }}
                />
              </div>
            )}
            {showDropBoxChooser && (
              <DropboxFolderSelect
                onSuccess={(files: any) => {
                  console.log("files", files[0]);
                  let file = files[0];
                  if (!file?.isDir) {
                    toast.error("Please select a folder");
                    return;
                  }
                  handleSubmit({
                    documentId: driveModalDoc?.documentId,
                    enabled: true,
                    parentFolderId: file?.id,
                    parentFolderName: file?.name,
                    parentFolderUrl: file?.link,
                  });
                  setDropboxModalDoc({
                    documentId: driveModalDoc?.documentId,
                    enabled: true,
                    parentFolderId: file?.id,
                    parentFolderName: file?.name,
                    parentFolderUrl: file?.link,
                  });
                }}
              />
            )}
          </>
        }
      />
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default DropboxDriveModal;
