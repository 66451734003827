// @ts-nocheck
import { useEffect, useState } from "react";
import { GrSearch } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import axios from "../../utils/axios";
import { TailSpin } from "react-loader-spinner";
import { IoCheckmarkSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { BoloButton } from "../Common/Button/BoloButton";
import { COLORS } from "../../constants/common";
import BoloModal from "../Common/Modal/BoloModal";

const FetchDealsModal = ({
  setShowFetchDealsModal,
  ownerEmail,
  setSelectedDeal,
  integration,
}) => {
  const [searchDealName, setSearchDealName] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deals, setDeals] = useState({});
  const [userDeals, setUserDeals] = useState({});
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [fetchingPerson, setFetchingPerson] = useState(false);
  const [showHubspotContactSelect, setShowHubspotContactSelect] =
    useState(false);

  async function getDeals() {
    try {
      const response = await axios.get(
        `/${integration.key}/deals?ownerEmail=${ownerEmail}`
      );
      const { success, deals } = response.data;
      if (success) {
        setUserDeals(deals);
        setDeals(deals);
        setLoading(false);
      } else {
        setLoading(false);
        setShowFetchDealsModal("");
      }
    } catch (err) {
      setLoading(false);
      setShowFetchDealsModal("");
      toast.error("Error while fetching deals!");
    }
  }
  async function getPersonFromId(id) {
    setFetchingPerson(true);
    try {
      const response = await axios.get(
        `/${integration.key}/getPerson?ownerEmail=${ownerEmail}&personId=${id}`
      );
      const { success, person } = response.data;
      if (success) {
        setSelectedDeal((prev) => ({
          ...prev,
          deal: { ...prev.deal, person: person },
        }));
        setFetchingPerson(false);
      } else {
        setShowFetchDealsModal("");
      }
    } catch (err) {
      toast.error("Error while fetching person details");
    } finally {
      setShowFetchDealsModal("");
    }
  }

  async function getCompanyFromId(id, ownerId) {
    setFetchingPerson(true);
    try {
      const response = await axios.get(
        `/${integration.key}/getCompany?ownerEmail=${ownerEmail}&companyId=${id}&ownerId=${ownerId}`
      );
      const { success, company, owner } = response.data;
      if (success) {
        setSelectedDeal((prev) => ({
          ...prev,
          deal: { ...prev.deal, company: company, owner: owner },
        }));
        setFetchingPerson(false);
      }
    } catch (err) {
      toast.error("Error while fetching company details");
    } finally {
    }
  }

  useEffect(() => {
    getDeals();
  }, []);

  return (
    <>
      <BoloModal
        setShow={setShowFetchDealsModal}
        content={
          <>
            <div className="pb-2 pt-4 md:px-3">
              <h1 className="text-lg md:text-xl  font-semibold text-gray-700">
                Link to Deal
              </h1>
            </div>
            <div
              className=" md:px-3 h-[350px] md:h-[420px] overflow-y-auto"
              id="mini-pdf-custom-scroll"
            >
              <p className="text-gray-500  mb-6 text-xs md:text-sm">
                Select a {integration.name} Deal to link the document.
              </p>
              <div
                className={`flex w-full border-b-2 ${
                  isFocused ? "border-primary" : ""
                } pb-2 mb-3`}
                onClick={() => setIsFocused(true)}
              >
                <GrSearch className="text-gray-400 text-xl" />
                <input
                  placeholder="Search by Deal name"
                  value={searchDealName}
                  className="outline-none w-[95%] text-sm  px-2 "
                  onChange={(e) => {
                    setSearchDealName(e.target.value);
                    if (e.target.value !== "") {
                      const filteredDeals = userDeals.filter((deal) =>
                        new RegExp(e.target.value, "i").test(
                          deal?.title ||
                            deal?.name ||
                            deal?.properties?.dealname
                        )
                      );

                      setDeals(filteredDeals);
                    } else {
                      setDeals(userDeals);
                    }
                  }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              </div>

              {loading ? (
                <div className="pt-32 flex justify-center items-center">
                  <TailSpin width="150" color={COLORS.primary} />
                </div>
              ) : (
                <>
                  {deals?.length === 0 && (
                    <div className="flex justify-center items-center text-gray-600 mt-4">
                      No deals found! Create a new deal in your{" "}
                      {integration?.name} account.
                    </div>
                  )}
                  {deals.map((deal, index) => (
                    <div
                      key={deal?.id}
                      className="hover:text-primary flex gap-4 py-2 px-1 md:px-3 cursor-pointer hover:bg-[#e7e0f4] border-b"
                      onClick={() => setSelectedDealId(deal?.id)}
                      style={{
                        backgroundColor:
                          selectedDealId == deal?.id && "#eee8f9",
                        color: selectedDealId == deal?.id && COLORS.primary,
                      }}
                    >
                      <span className="text-base ">
                        {deal?.title ||
                          deal?.name ||
                          deal?.properties?.dealname}
                      </span>
                      <span className="text-gray-500 text-sm">
                        {" "}
                        {integration.key == "hubspot"
                          ? deal?.properties?.dealstage
                              ?.charAt(0)
                              ?.toUpperCase() +
                            deal?.properties?.dealstage?.slice(1)
                          : deal?.status?.charAt(0)?.toUpperCase() +
                            deal?.status?.slice(1)}
                      </span>
                      {selectedDealId == deal?.id && (
                        <span className="text-2xl ml-auto">
                          <IoCheckmarkSharp className="text-primary" />
                        </span>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="py-3 md:p-3 flex justify-end gap-4 mt-4">
              <BoloButton
                onClick={() => setShowFetchDealsModal("")}
                variant={"secondary"}
                className="w-[100px] h-10"
                size={"sm"}
              >
                <span className="font-medium">Cancel</span>
              </BoloButton>

              <BoloButton
                disabled={!selectedDealId}
                className="w-[100px] h-10 flex justify-center"
                onClick={() => {
                  setSelectedDeal({
                    deal: userDeals.find((deal) => deal.id == selectedDealId),
                    type: integration.key,
                  });
                  let deal = userDeals.find(
                    (deal) => deal.id == selectedDealId
                  );
                  if (integration.key == "pipedrive") {
                    getPersonFromId(deal?.person_id?.value);
                  } else if (integration.key == "gohighlevel") {
                    getPersonFromId(deal?.contactId);
                  } else if (integration.key == "hubspot") {
                    getCompanyFromId(
                      deal?.associations?.companies?.results[0]?.id,
                      deal?.properties?.hubspot_owner_id
                    );
                    setShowHubspotContactSelect(true);
                  }
                }}
                variant={"primary"}
              >
                {fetchingPerson ? (
                  <>
                    <TailSpin height="20" width="20" color="#fff" />
                  </>
                ) : (
                  <span className="font-medium">Link</span>
                )}
              </BoloButton>
            </div>
          </>
        }
      />

      {showHubspotContactSelect && (
        <HubspotFetchContactModal
          setShowHubspotContactSelect={setShowHubspotContactSelect}
          contacts={
            userDeals.find((deal) => deal.id == selectedDealId)?.associations
              ?.contacts?.results
          }
          ownerEmail={ownerEmail}
          setSelectedDeal={setSelectedDeal}
          setShowFetchDealsModal={setShowFetchDealsModal}
        />
      )}
    </>
  );
};
const HubspotFetchContactModal = ({
  setShowHubspotContactSelect,
  contacts,
  ownerEmail,
  setSelectedDeal,
  setShowFetchDealsModal,
}) => {
  const [loading, setLoading] = useState(true);
  const [contactsData, setContactsData] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState("");
  const getContacts = async () => {
    let contactsIds = contacts?.map((contact) => contact?.id);

    try {
      const { data } = await axios.post("/hubspot/getContacts", {
        contactsIds,
        ownerEmail,
      });
      if (data?.success) {
        setContactsData(data?.contactsData);
        setLoading(false);
      } else {
        toast.error(data?.message);
        setShowFetchDealsModal("");
      }
    } catch (error) {
      console.log("Error while fetching contacts!");
      setShowFetchDealsModal("");
      toast.error("Error while fetching contacts!");
    }
  };
  console.log(contactsData);

  useEffect(() => {
    getContacts();
  }, [contacts]);
  return (
    <BoloModal
      setShow={setShowHubspotContactSelect}
      content={
        <>
          <div className="pb-2 pt-4 md:px-3">
            <h1 className="text-lg md:text-xl  font-semibold text-gray-700">
              Select a Contact
            </h1>
          </div>
          <div
            className=" md:px-3 h-[350px] md:h-[420px] overflow-y-auto"
            id="mini-pdf-custom-scroll"
          >
            <p className="text-gray-500  mb-6 text-xs md:text-sm">
              Select a Hubspot Contact to link with the document.
            </p>

            {loading ? (
              <div className="pt-32 flex justify-center items-center">
                <TailSpin width="150" color={COLORS.primary} />
              </div>
            ) : (
              <div className="border-t">
                {contactsData?.length === 0 && (
                  <div className="flex justify-center items-center text-gray-600 mt-10">
                    No Contact found associated with this deal.
                  </div>
                )}
                {contactsData?.map((contact, index) => {
                  const fullName = `${contact?.properties?.firstname || ""} ${
                    contact?.properties?.lastname || ""
                  }`.trim();

                  return (
                    <div
                      key={contact?.id}
                      className="hover:text-primary flex gap-4 py-2 px-1 md:px-3 cursor-pointer hover:bg-[#e7e0f4] border-b"
                      onClick={() => setSelectedContactId(contact?.id)}
                      style={{
                        backgroundColor:
                          selectedContactId == contact?.id && "#eee8f9",
                        color:
                          selectedContactId == contact?.id && COLORS.primary,
                      }}
                    >
                      <span className="text-base ">{fullName}</span>
                      {selectedContactId == contact?.id && (
                        <span className="text-2xl ml-auto">
                          <IoCheckmarkSharp className="text-primary" />
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="py-3 md:p-3 flex justify-end gap-4 mt-4">
            <BoloButton
              onClick={() => {
                setShowFetchDealsModal("");
              }}
              variant={"secondary"}
              className="w-[100px] h-10"
              size={"sm"}
            >
              <span className="font-medium">Cancel</span>
            </BoloButton>

            <BoloButton
              disabled={!selectedContactId}
              className="w-[100px] h-10 flex justify-center"
              onClick={() => {
                let selectedContact = contactsData?.find(
                  (contact) => contact?.id === selectedContactId
                );
                setSelectedDeal((prev) => ({
                  ...prev,
                  deal: { ...prev.deal, contact: selectedContact },
                }));
                setShowFetchDealsModal("");
              }}
              variant={"primary"}
            >
              Select
            </BoloButton>
          </div>
        </>
      }
    />
  );
};
export default FetchDealsModal;
