import React, { useState, useEffect } from "react";
import { fetchOwner } from "../../utils";
import axios from "./../../utils/axios";
import SingleCardContent from "./SingleCardContent";
import { BsFillPersonFill, BsCheckCircleFill } from "react-icons/bs";
import { FaSignature } from "react-icons/fa";
import { getCalApi } from "@calcom/embed-react";
import { WEBSITE_ASSETS_URL } from "../../constants/constants";
import FeatureInfoPopup from "./FeatureImagePopup";
import "./index.css";

const PlanCards = ({ isAnnualBilling, owner }) => {
  const [isIndianPrice, setIsIndianPrice] = useState(false);
  const [imagePopup, setImagePopup] = useState(null);
  // async function getCountry() {
  //   let { data } = await axios("https://ipinfo.io/json?token=cbe652e779e0c6");
  //   // console.log(data);
  //   let country = data?.country;
  //   // setIsIndianPrice(country === "IN");
  //   // setIsPayPalAvailable(!country === "IN");

  //   return country;
  // }
  useEffect(() => {
    // fetchOwner();
    // (async () => {
    //   let country = localStorage.getItem("country");
    //   if (
    //     !country ||
    //     country === "" ||
    //     country === "null" ||
    //     country === "undefined"
    //   ) {
    //     country = await getCountry();
    //     console.log(country);
    //     localStorage.setItem("country", country);
    //   }
    //   setIsIndianPrice(country === "IN");
    // })();
  }, []);

  const commonFeatures = [
    {
      name: "Audit Log and History",
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/audit-trail.png`,
    },
    {
      name: "Structured Folder System",
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/create-files-folders.png`,
    },
    {
      name: "Signing Order",
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/signing-order.png`,
    },
    {
      name: "Notification Reminder",
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/real-time-tracking.png`,
    },
    {
      name: "Certification of completion & electronic record disclosure",
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/ueta.png`,
    },
    {
      name: "Form fields: drop-down, radio, approve/decline",
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/doc-editor.png`,
    },
  ];

  const pricingPlansData = [
    {
      title: "Forever Free",
      description: "Start your digital journey with BoloForms for free.",
      priceSymbol: `${isIndianPrice ? "₹" : "$"}`,
      price: `${0}`,
      priceDescription: `${
        isAnnualBilling ? "Billed Annually" : "Billed Monthly"
      }`,
      signatures: 5,
      featuresTitle: "You get to enjoy..",
      features: [
        {
          name: "Collect Signatures in Forms",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },

        //Features
        {
          name: "5 Form Submissions",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },
        {
          name: "2 Documents",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf.png`,
        },
        {
          name: "2 Templates",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf-template.png`,
        },
        {
          name: "5 Forms",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },
        ...commonFeatures,
        //! these are new pricing features
        // "Send for Signature - 2 documents (1 trial + 1 use)",
        // "2 Templates",
        // "5 Forms - Generate Custom PDFs on the basis of Form Submissions",
        // "Audit Log and History",
        // "BoloForms Signature Branding",

        //! these are old features

        // "Send for Signature (1 document/mo)",
        // "1 Template",
        // "5 Forms- Generate Custom PDFs on the basis of Form Submissions",
        // "Audit Log and History",
        // "BoloForms Signature Branding",
      ],
      isAnnualBilling: isAnnualBilling,
      isIndianPrice: isIndianPrice,
    },
    {
      title: "Freelancer",
      description: "For people just looking to take signatures in Forms.",
      priceSymbol: `${isIndianPrice ? "₹" : "$"}`,
      gimmickPrice: isIndianPrice
        ? isAnnualBilling
          ? 12300 // not sure about this
          : 1200 // not sure about this
        : isAnnualBilling
        ? 29 // it was 290, when we were showing in /yr, now it is only in /mo
        : 29,
      price: `${
        isIndianPrice
          ? isAnnualBilling
            ? 12300
            : 1200
          : isAnnualBilling
          ? 10 // it was 100, but as we are showing the price in /mo anyway so its 100/12 = 8.33
          : 15
      }`,
      priceDescription: `${
        isAnnualBilling ? "Billed Annually" : "Billed Monthly"
      }`,
      seat: "countable",
      maxSeatAllowed: 1000,
      minSeatAllowed: 1,
      // extraText: "Most Popular",
      additionalSeatPrice: isAnnualBilling ? 290 : 29,
      extraText: "",

      signatures: 100,
      featuresTitle: "You get to enjoy..",
      features: [
        "Everything in Free Plan",
        // "Collect Signatures inside Forms (100 submissions/mo)",

        //Features
        {
          name: "100 Form Submissions",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },
        {
          name: "5 Documents",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf.png`,
        },
        {
          name: "5 Templates",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf-template.png`,
        },
        {
          name: "5 Forms",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },
        {
          name: "Form Branding",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/form-branding.png`,
        },
        {
          name: "Form Analytics",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/form-analytics.png`,
        },
        // "Custom PDF Name",
        {
          name: "Send A Copy of Generated PDFs",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/generate-response-pdf.png`,
        },
        // ...commonFeatures,
        // "Send for Signature (5 documents/mo)",
        // "5 Templates",
        // "5 Forms - Generate Custom PDFs on the basis of Form Submissions",
        // "Audit Log and History",
        // "Notifications and Reminders",
        // "BoloForms Signature Branding",
      ],
      isAnnualBilling: isAnnualBilling,
      isIndianPrice: isIndianPrice,
    },

    {
      title: "Team",
      description:
        "For people who want a holistic solution to take signatures in Form and PDF.",
      priceSymbol: `${isIndianPrice ? "₹" : "$"}`,
      price: `${
        isIndianPrice
          ? isAnnualBilling
            ? 16999 // not sure about the indian price
            : 1800 // not sure about the indian price
          : isAnnualBilling
          ? 49 // it was 490, but as we are showing the price in /mo anyway so its 490/12 = 40.83
          : 69
      }`,
      priceDescription: `${
        isAnnualBilling ? "Billed Annually" : "Billed Monthly"
      }`,
      seat: "countable",
      maxSeatAllowed: 50, // chirag se puchna hai
      minSeatAllowed: 1,
      extraText: "Most Popular",
      additionalSeatPrice: isAnnualBilling ? 290 : 29, // additionalSeatPrice for TEAM plan is not used
      signatures: 500,
      featuresTitle: "You get to enjoy..",
      features: [
        //! these are new pricing features
        "Everything in Freelancer",
        {
          name: "500 Form Submissions",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },
        {
          name: "Unlimited Members",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/members.png`,
        },
        {
          name: "Unlimited Documents",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf.png`,
        },
        {
          name: "Unlimited Templates",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf-template.png`,
        },
        {
          name: "Unlimited Forms",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },
        {
          name: "Admin Dashboard for User Management",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/custom-user-roles.png`,
        },
        {
          name: "Share Templates with Team Members",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/team-workspaces.png`,
        },
        {
          name: "Custom Watermark in PDFs",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/watermark.png`,
        },
        {
          name: "Google Drive Integration",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/google-drive.png`,
        },
        {
          name: "Custom PDF Name",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/custom-pdf-name.png`,
        },
        {
          name: "Import Fields",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/import-fields.png`,
        },
        // "Collect Signatures inside Forms (500 submissions/mo)",
        // "Send for Signature (unlimited documents/mo)",
        // "Unlimited Forms - Generate Custom PDFs on the basis of Form Submissions.",
        // "Admin Dashboard for User Management",
        // "Share Templates with Team Members",
        // "BoloForms Signature Branding",

        //! these are old features
        // "Everything in Freelancer",
        // "Collect Signatures inside Forms (500 submissions/mo)",
        // "Send for Signature (unlimited documents/mo)",
        // "Unlimited Templates",
        // "15 Forms- Generate Custom PDFs on the basis of Form Submissions.",
        // "Admin Dashboard for User Management",
        // "Share Templates with Team Members",
        // "BoloForms Signature Branding",
      ],
      isAnnualBilling: isAnnualBilling,
      isIndianPrice: isIndianPrice,
    },

    {
      title: "Growing Business",
      description:
        "Geared towards businesses experiencing growth and expansion.",
      priceSymbol: `${isIndianPrice ? "₹" : "$"}`,
      price: `${
        isIndianPrice
          ? isAnnualBilling
            ? 24999 // not sure about the indian price
            : 2499 // not sure about the indian price
          : isAnnualBilling
          ? 99 // it was 990, but as we are showing the price in /mo anyway so its 990/12 = 82.5
          : 129
      }`,
      priceDescription: `${
        isAnnualBilling ? "Billed Annually" : "Billed Monthly"
      }`,

      seat: "countable",
      maxSeatAllowed: 100,
      minSeatAllowed: 1,
      workSpaceEnabled: true,
      maxWorkSpaceAllowed: 10, // DECIDE_VALUE
      minWorkSpaceAllowed: 1, // DECIDE_VALUE
      additionalSeatPrice: isAnnualBilling ? 29 : 29, // as it is only /mo showing, in annual billing price increment should be in 29 as well
      signatures: 1000,
      features: [
        "Everything in Team",
        {
          name: "Unlimited Form Submissions",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },
        {
          name: "Unlimited Members",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/members.png`,
        },
        {
          name: "Unlimited Documents",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf.png`,
        },
        {
          name: "Unlimited Templates",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf-template.png`,
        },
        {
          name: "Unlimited Forms",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
        },
        {
          name: "Redirection URL in Forms",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/custom-redirection-url.png`,
        },
        {
          name: "WorkSpaces",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/team-workspaces.png`,
        },
        {
          name: "Email Branding",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/email-subject-body.png`,
        },
        {
          name: "Bulk Download",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/bulk-download.png`,
        },
        {
          name: "Read Receipts",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/audit-trail.png`,
        },
        {
          name: "Pipedrive Integration",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pipedrive.png`,
        },
        {
          name: "Zapier Integration",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/zapier.png`,
        },
        {
          name: "Pabbly Integration",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pabbly.png`,
        },
        {
          name: "PDF Link for sharing",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/generate-signing-link.png`,
        },
        {
          name: "Import Fields",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/import-fields.png`,
        },
        {
          name: "Password proctected PDFs",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/password-protection.png`,
        },
        {
          name: "Stripe",
          image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/stripe-integration.png`,
        },
        // "Automatic Reminders",
        // "Structured Folder System",
        // "Redirection Url in Forms",
        // "Approval Workflow for Your Documents",
        // "Custom Branding",
        // "Read Receipts",
        // "Google Workspace Integration",
        // "Signing via Shareable Link and QR Code",
        // "HIPAA BAA on Request",
      ],
      isAnnualBilling: isAnnualBilling,
      isIndianPrice: isIndianPrice,
    },

    // {
    //   title: "Enterprise",
    //   description: "For large enterprises demanding top-tier solutions.",
    //   seat: "More than 30 Seats",
    //   signatures: "Unlimited",
    //   featuresTitle: "You get to enjoy..",
    //   features: [
    //     "Custom Solution Development using our API",
    //     "HIPAA BAA on Request",
    //     "Private Slack Channel",
    //     "Dedicated Account Manager",
    //     "Contract & Legal Review",
    //     "Support Reply within 1 Hour",
    //   ],
    //   isAnnualBilling: isAnnualBilling,
    //   isIndianPrice: isIndianPrice,
    // },
  ];

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    // <div className="flex flex-col gap-y-[80px] max-w-[1500px] self-center">
    <div className="mt-2">
      <div className="flex flex-col xl:flex-row justify-center gap-y-[80px] w-full">
        <div className="flex flex-col sm:flex-row justify-center gap-y-[80px] w-[100%] 2xl:w-[50%]">
          {pricingPlansData?.map((plan, id) => {
            if (id === 0 || id === 1) {
              return (
                <SingleCardContent
                  key={id}
                  {...plan}
                  owner={owner}
                  setImagePopup={setImagePopup}
                />
              );
            }
          })}
        </div>
        <div className="flex flex-col sm:flex-row justify-center gap-y-[80px]  w-[100%] 2xl:w-[50%]">
          {pricingPlansData?.map((plan, id) => {
            if (id === 2 || id === 3) {
              return (
                <SingleCardContent
                  key={id}
                  {...plan}
                  owner={owner}
                  setImagePopup={setImagePopup}
                />
              );
            }
          })}
        </div>
      </div>

      {imagePopup && (
        <FeatureInfoPopup
          image={imagePopup}
          close={() => setImagePopup(null)}
        />
      )}
    </div>
  );
};

export default PlanCards;
