import React from "react";

const Divider = () => (
  <div
    style={{
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      borderBottom: "1px solid #e5e5e5",
      marginLeft: "-16px",
      marginRight: "-16px",
    }}
  />
);

export default Divider;
