import { useContext, useState } from "react";
import "./index.css";
import { Size } from "../../../../../common/src";
import { FontContext } from "../../../contexts";
import { PageViewer } from "../../../../../../components/HelperComponents/PdfViewer";
import { PreviewStateType } from "../../Preview";
import { DesignerState, GlobalHelpersType } from "..";

interface MiniPdfProps {
  height: number;
  pageNum: number;
  pageCursor: number;
  size: Size;
  file?: string | ArrayBuffer | Uint8Array;
  setPageIndex: (pageCursor: number) => void;
  pages: any;
  isFormPov?: boolean;
  previewState?: PreviewStateType;
  designerState?: DesignerState;
  setDesignerState?: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers?: GlobalHelpersType;
  backgrounds: string[];
}

const MiniPdfViewer = ({
  height,
  pageNum,
  pageCursor,
  size,
  file,
  setPageIndex,
  pages,
  isFormPov,
  previewState,
  designerState,
  setDesignerState,
  globalHelpers,
  backgrounds,
}: MiniPdfProps) => {
  // minor change
  // const i18n = useContext(I18nContext);
  // const fonts = useContext(FontContext);
  // const fallbackFont = getFallbackFontName(fonts);
  // const [open, setOpen] = useState(true);
  // const [questionsAnomaly, setQuestionsAnomaly] = useState<any>([]);
  // const [showAttentionDetails, setShowAttentionDetails] = useState(false);

  // const [loading, setLoading] = useState(false);
  // const [pdfFile, setPDFFile] = useState<File | null>();

  return (
    <div
      style={{
        background: "#ffffffed",
      }}
      // className="shadow hidden lg:block"
      className={`shadow  duration-500 ${isFormPov && "hidden lg:block"} ${
        designerState?.miniPdf.open
          ? "translate-x-[0] w-[155px] max-w-[155px] min-w-[155px]"
          : isFormPov
          ? "translate-x-[0] w-[250px] max-w-[250px] min-w-[250px]"
          : "translate-x-[-100%]  w-[0] max-w-[0] min-w-[0]"
      }`}
    >
      <div
        className={`max-h-[94vh] overflow-y-auto overflow-x-hidden ${
          isFormPov && "hidden lg:block mt-[70px]"
        }`}
        // style={{ scrollbarGutter: "stable" }}
        id="mini-pdf-custom-scroll"
      >
        <div
          className={`flex items-center min-w-[155px] duration-500  ${
            designerState?.miniPdf.open || isFormPov
              ? "translate-x-[0]"
              : "translate-x-[-100%]"
          }`}
        >
          <PageViewer
            //@ts-ignore
            pdfFile={file}
            setPageIndex={setPageIndex}
            currentPageIndex={pageCursor}
            pages={pages}
            isPdfMeDesigner={true}
            isFormPov={isFormPov}
            previewState={previewState}
            designerState={designerState}
            setDesignerState={setDesignerState}
            globalHelpers={globalHelpers}
            backgrounds={backgrounds}
          />
        </div>
      </div>
    </div>
  );

  // return (
  //   <div
  //     style={{
  //       position: isFormPov ? "fixed" : "absolute",
  //       right: 0,
  //       // zIndex: 10,
  //       height: height ? height : "100%",
  //       width: open ? SIDEBAR_WIDTH : 0,
  //       marginTop: !isFormPov ? 0 : "0.0rem",
  //       transition: "width 0.3s ease-in-out",
  //     }}
  //     className="z-0 md:z-10"
  //   >
  //     <div
  //       // style={{ position: "sticky", zIndex: 1, fontSize: "1rem" }}
  //       className={` ${isFormPov ? "ml-auto" : ""} `}
  //       style={{ background: "black" }}
  //     >
  //       <div
  //         style={{
  //           height: size.height,
  //           // display: open ? "block" : "none",
  //           // top: RULER_HEIGHT / 2,
  //           right: 0,
  //           position: "absolute",
  //           background: "#ffffffed",
  //           color: "#333",
  //           border: "1px solid #eee",
  //           padding: "1rem 1.5rem",
  //           fontWeight: 400,
  //           textAlign: "left",
  //           boxSizing: "content-box",
  //         }}
  //         className="shadow hidden lg:block"
  //       >
  //         <div
  //           className="max-h-[94vh] overflow-y-auto hidden lg:block"
  //           style={{}}
  //           id="mini-pdf-custom-scroll"
  //         >
  //           <PageViewer
  //             //@ts-ignore
  //             pdfFile={file}
  //             setPageIndex={setPageIndex}
  //             currentPageIndex={pageCursor}
  //             pages={pages}
  //             isPdfMeDesigner={true}
  //             isFormPov={isFormPov}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default MiniPdfViewer;
