import React, { KeyboardEvent } from "react";
import { SchemaUIProps } from "./SchemaUI";
import {
  ImageSchema,
  SchemaForUI,
  TextSchema,
} from "../../../../common/src/type";
import { AiFillPlusCircle } from "react-icons/ai";
import { v4 } from "uuid";
import { SIZES, STANDARD_DATA } from "../../constants";

import { getWhiteLabelInfoLocal } from "../../../../../utils";
import { COLORS } from "../../../../../constants/common";
import { isEditorSchema } from "../../designerHelper";

type Props = SchemaUIProps & { schema: ImageSchema };

const RadioGroupSchema = ({
  schema,
  editable,
  isActive,
  addSchema,
  handleRadioClick,
  activeElements,
  pageSizes,
  pageCursor,
  isPreview,
  isSigning,
  previewStyle,
  previewState,
  isApprover,
  tabIndex,
  setActiveSchema,
  designerState,
  onChange,
}: Props) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const primaryColor =
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary;
  const isEditorARecipient = isEditorSchema({
    schema,
    receiversList: designerState?.receiversList || [],
    instanceFrom: designerState?.instanceFrom || "PDF-TEMPLATE",
  });
  const newEditable = editable || isEditorARecipient;
  const getImage = (schema: SchemaForUI) => {
    if (
      schema.data == "checked_DBKEYV1" ||
      schema.data == "unchecked_DBKEYV1"
    ) {
      return STANDARD_DATA.radiogroup[schema.data].data;
    }
    return "";
  };

  const getBackground = () => {
    if (isPreview && previewStyle !== "DEFAULT") return "transparent";

    if (schema?.isQuestionDeleted) {
      return "rgb(255 0 0 / 0.30)";
    }

    if (isPreview || isSigning) {
      if (previewState?.fetchedData?.accountInfo?.theme) {
        if (isActive && newEditable) {
          return previewState?.fetchedData?.accountInfo?.theme + "70";
        } else {
          return previewState?.fetchedData?.accountInfo?.theme + "20";
        }
      } else {
        if (isActive && newEditable) {
          // return primaryColor + "70";
          return `hsl(var(--primary) / 0.55)`;
        } else {
          // return primaryColor + "20";
          return `hsl(var(--primary) / 0.2)`;
        }
      }
    }

    if (isActive && newEditable) {
      if (schema?.signatureColour) return schema?.signatureColour + 70;

      if (schema?.role?.colour) return schema?.role?.colour + "70";
    } else {
      if (schema?.signatureColour) return schema?.signatureColour + 20;

      if (schema?.role?.colour) return schema?.role?.colour + "20";
    }
  };
  function getTitle(): string {
    if (schema?.isQuestionDeleted) {
      return "This Question is Deleted";
    } else {
      if (isApprover) {
        if (!schema?.isStatic && schema?.role) {
          let signer = previewState?.signersData?.[schema?.role?.title];
          return signer?.name || signer?.email || schema?.showKey;
        }
      }
      return schema?.showKey;
    }
  }

  const handleClick = () => {
    if (isApprover) {
      return;
    }
    setActiveSchema && setActiveSchema(schema);
    if (handleRadioClick && newEditable) {
      handleRadioClick(schema);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  return (
    <div
      data-schema-sub-type={schema.subType}
      data-schema-id={schema.id}
      tabIndex={tabIndex}
      className={`z-20 flex items-center justify-center relative h-full w-full text-xl`}
      style={{
        background: getBackground(),
      }}
      title={getTitle()}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <img
        src={getImage(schema)}
        style={{
          maxHeight: "100%",
          maxWidth: "100%",
        }}
        // onClick={() => {
        //   if (isApprover) {
        //     return;
        //   }
        //   if (handleRadioClick) handleRadioClick(schema);
        // }}
      />
      {/* {!editable && isActive && activeElements?.length == 1 && (
        <AiFillPlusCircle
          className="absolute left-[-35px] bottom-[-35px] text-2xl"
          color={schema.radiogroupColor}
          onClick={() => {
            if (addSchema) {
              let calcheight = 0;
              if (pageSizes) {
                let pageDim = pageSizes[pageCursor || 0].height;
                let oldCalcHeight =
                  schema.position.y +
                  schema.height +
                  5 +
                  SIZES.radiogroup.height;
                calcheight =
                  pageDim - oldCalcHeight < 0
                    ? pageDim - SIZES.radiogroup.height
                    : oldCalcHeight - SIZES.radiogroup.height;
              }
              addSchema({
                title: "RadioGroup",
                type: "radiogroup",
                id: v4(),
                required: false,
                subType: "radiogroup",
                role: schema.role,
                position: {
                  x: schema.position.x,
                  y: calcheight,
                },
                size: { height: schema.height, width: schema.width },
                radiogroupId: schema.radiogroupId,
                radiogroupColor: schema.radiogroupColor,
              });
            }
          }}
        />
      )} */}
    </div>
  );
};

export default RadioGroupSchema;
