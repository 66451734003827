import React, { useEffect, useState } from "react";
import { Matcher } from "react-day-picker";
import { BiFilterAlt } from "react-icons/bi";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { FaFilter } from "react-icons/fa6";
import { LuFilter } from "react-icons/lu";
import { MdUndo } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import useQueryHook, {
  UseQueryDashboardInstanceType,
} from "../../hooks/reduxHooks/useQueryHook";
import { BoloButton, buttonVariants } from "../Common/Button/BoloButton";
import { BoloCalender } from "../Common/DateCalender/BoloCalenderShadCn";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../Common/SelectDropDown/BoloDropDown";
import CustomTooltip from "../EditForm/CustomTooltip";

const DashboardFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getSearchData, setSearchData, reset, dashboardInstance } =
    useQueryHook();
  const [matcher, setMatcher] = useState<{
    fromDate: Matcher;
    toDate: Matcher;
  }>({ fromDate: false, toDate: false });

  const FORM_STATUS: { name: string; key: string }[] = [
    { key: "ALL", name: "All" },
    { key: "ACTIVE", name: "Active" },
    { key: "INACTIVE", name: "Archive" },
    { key: "PLAIN", name: "Default PDF" },
    { key: "TEMPLATED", name: "Custom PDF" },
  ];

  const PDF_STATUS: { name: string; key: string }[] = [
    { key: "ALL", name: "All" },
    { key: "SIGNED", name: "Signed" },
    { key: "DRAFT", name: "Draft" },
    { key: "SENT", name: "Sent" },
    { key: "VOID", name: "Void" },
  ];

  const OVERVIEW_STATUS: { name: string; key: string }[] = [
    { key: "ALL", name: "All" },
    { key: "PENDING", name: "Pending" },
    { key: "PAYMENT_PENDING", name: "Payment Pending" },
    { key: "APPROVED", name: "Approved" },
    { key: "SIGNED", name: "Signed" },
    { key: "PAID", name: "Paid" },
    { key: "REJECTED", name: "Rejected" },
    { key: "DECLINED", name: "Declined" },
    { key: "VOID", name: "Void" },
  ];

  const PDF_TEMPLATE_RESPONSE_STATUS: { name: string; key: string }[] = [
    { key: "ALL", name: "All" },
    { key: "FINISHED", name: "Finished" },
    { key: "PENDING", name: "Pending" },
    { key: "VOID", name: "Void" },
  ];

  const FORM_SORT_BY: { name: string; key: string }[] = [
    // { key: "", name: "Default" },
    { key: "", name: "Creation Time" },
    { key: "updatedAt", name: "Modification Time" },
    { key: "formJson.title", name: "Form Name" },
  ];

  // this handles both pdf and template
  const PDF_SORT_BY: { name: string; key: string }[] = [
    // { key: "", name: "Default" },
    { key: "", name: "Creation Time" },
    { key: "updatedAt", name: "Modification Time" },
    { key: "documentName", name: "Document Name" },
  ];

  // this handles both pdf and template
  const PDF_TEMPLATE_RESPONSE_SORT_BY: { name: string; key: string }[] = [
    // { key: "", name: "Default" },
    { key: "", name: "Creation Time" },
    { key: "updatedAt", name: "Modification Time" },
    // { key: "documentName", name: "Document Name" },
  ];

  // this handles both pdf and template
  const PRODUCT_SORT_BY: { name: string; key: string }[] = [
    // { key: "", name: "Default" },
    { key: "", name: "Creation Time" },
    { key: "updatedAt", name: "Modification Time" },
    { key: "name", name: "Product Name" },
    { key: "SKU", name: "SKU" },
    { key: "category", name: "Category" },
  ];

  const OVERVIEW_SORT_BY: { name: string; key: string }[] = [
    // { key: "", name: "Default" },
    { key: "", name: "Creation Time" },
    { key: "updatedAt", name: "Modification Time" },
    { key: "documentName", name: "Document Name" },
    { key: "status", name: "Status" },
  ];

  const OVERVIEW_TYPE: { name: string; key: string }[] = [
    { key: "ALL", name: "All" },
    { key: "PDF", name: "PDFs" },
    { key: "PDF_TEMPLATE", name: "PDF Templates" },
  ];

  const STATUS: {
    [key in UseQueryDashboardInstanceType]: { name: string; key: string }[];
  } = {
    FORM_DASHBOARD: FORM_STATUS,
    PDF_DASHBOARD: PDF_STATUS,
    PDF_TEMPLATE_DASHBOARD: [],
    PRODUCT_CATALOG: [],
    PDF_TEMPLATE_RESPONSE_DASHBOARD: PDF_TEMPLATE_RESPONSE_STATUS,
    PAYMENTS: [],
    OVERVIEW: OVERVIEW_STATUS,
  };

  const SORT_BY: {
    [key in UseQueryDashboardInstanceType]: { name: string; key: string }[];
  } = {
    FORM_DASHBOARD: FORM_SORT_BY,
    PDF_DASHBOARD: PDF_SORT_BY,
    PDF_TEMPLATE_DASHBOARD: PDF_SORT_BY,
    PRODUCT_CATALOG: PRODUCT_SORT_BY,
    PDF_TEMPLATE_RESPONSE_DASHBOARD: PDF_TEMPLATE_RESPONSE_SORT_BY,
    PAYMENTS: [],
    OVERVIEW: OVERVIEW_SORT_BY,
  };

  function parseISOToDate(isoString: string): undefined | Date {
    const date = new Date(isoString);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return undefined; // Return null for invalid dates
    } else {
      return date; // Return the Date object for valid dates
    }
  }

  useEffect(() => {
    let fromMatcher = parseISOToDate(getSearchData().dateFrom_);
    let toMatcher = parseISOToDate(getSearchData().dateTo_);
    setMatcher({
      toDate: fromMatcher ? { before: fromMatcher } : false,
      fromDate: toMatcher ? { after: toMatcher } : false,
    });
  }, [searchParams]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div
          data-tooltip-id="search-filters"
          className={buttonVariants({
            variant: "gray-shadow",
            size: "icon-xs",
            className: "h-[32px] w-[32px] flex items-center justify-center",
          })}
        >
          <LuFilter size={16} />
          <CustomTooltip
            helpers={{
              id: "search-filters",
              content: "Search Filters",
              place: "top",
            }}
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" sideOffset={15} className="w-60">
        <DropdownMenuLabel>Search Filters</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {dashboardInstance === "OVERVIEW" && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="text-xs">
                <div className="flex justify-between w-full pr-3">
                  <div>Type</div>{" "}
                  <div className="font-semibold">
                    {
                      OVERVIEW_TYPE?.find(
                        (e) => e.key === getSearchData().overViewType_
                      )?.name
                    }
                  </div>
                </div>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {OVERVIEW_TYPE.map((elm) => (
                    <DropdownMenuItem
                      className="text-xs"
                      onClick={() =>
                        setSearchData({ overViewType_: elm.key, pageNo_: 1 })
                      }
                    >
                      {elm.name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}
          {!!STATUS?.[dashboardInstance]?.length && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="text-xs">
                <div className="flex justify-between w-full pr-3">
                  <div>Status</div>{" "}
                  <div className="font-semibold">
                    {
                      STATUS?.[dashboardInstance].find(
                        (e) => e.key === getSearchData().filter_
                      )?.name
                    }
                  </div>
                </div>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {STATUS?.[dashboardInstance].map((elm) => (
                    <DropdownMenuItem
                      className="text-xs"
                      onClick={() =>
                        setSearchData({ filter_: elm.key, pageNo_: 1 })
                      }
                    >
                      {elm.name}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}
          {!!SORT_BY?.[dashboardInstance]?.length && (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="text-xs">
                <div className="flex justify-between w-full pr-3">
                  <div>Sort By</div>{" "}
                  <div className="font-semibold">
                    {
                      SORT_BY?.[dashboardInstance]?.find(
                        (e) => e.key === getSearchData().sortBy_
                      )?.name
                    }
                  </div>
                </div>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {SORT_BY?.[dashboardInstance].map((elm) => {
                    let isActive = elm.key === getSearchData().sortBy_;
                    let isDescnd =
                      getSearchData().sortOrder_ &&
                      getSearchData().sortOrder_ == "1"
                        ? false
                        : true;

                    return (
                      <DropdownMenuItem
                        className="text-xs"
                        onClick={() => {
                          let data = getSearchData();
                          if (data.sortBy_ == elm.key) {
                            setSearchData({
                              sortBy_: elm.key,
                              sortOrder_: isDescnd ? "1" : "-1",
                            });
                          } else {
                            setSearchData({ sortBy_: elm.key });
                          }
                        }}
                      >
                        <div className="flex items-center justify-between w-full">
                          <div className="pr-3">{elm.name}</div>
                          {isActive && (
                            <div>
                              {isDescnd ? (
                                <FaLongArrowAltDown />
                              ) : (
                                <FaLongArrowAltUp />
                              )}
                            </div>
                          )}
                        </div>
                      </DropdownMenuItem>
                    );
                  })}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          )}
          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="text-xs">
              <div className="flex justify-between w-full pr-3">
                <div>Date From</div>{" "}
                <div className="font-semibold">
                  {parseISOToDate(getSearchData().dateFrom_)?.toDateString()}
                </div>
              </div>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <div className="flex flex-col">
                  <BoloCalender
                    mode="single"
                    disabled={matcher.fromDate}
                    selected={parseISOToDate(getSearchData().dateFrom_)}
                    onSelect={(e) =>
                      setSearchData({ dateFrom_: e, pageNo_: 1 })
                    }
                    className="rounded-md"
                  />
                  <div className="flex justify-end mr-3 mb-1">
                    <BoloButton
                      variant={"secondary"}
                      size="xs"
                      onClick={() => {
                        reset("dateFrom_");
                      }}
                    >
                      Reset
                    </BoloButton>
                  </div>
                </div>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger className="text-xs">
              <div className="flex justify-between w-full pr-3">
                <div>Date To</div>{" "}
                <div className="font-semibold">
                  {parseISOToDate(getSearchData().dateTo_)?.toDateString()}
                </div>
              </div>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <div className="flex flex-col">
                  <BoloCalender
                    mode="single"
                    disabled={matcher.toDate}
                    selected={parseISOToDate(getSearchData().dateTo_)}
                    onSelect={(e) => setSearchData({ dateTo_: e, pageNo_: 1 })}
                    className="rounded-md"
                  />
                  <div className="flex justify-end mr-3 mb-1">
                    <BoloButton
                      variant={"secondary"}
                      size="xs"
                      onClick={() => {
                        reset("dateTo_");
                      }}
                    >
                      Reset
                    </BoloButton>
                  </div>
                </div>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => reset("all")}>
            <div className="flex justify-between items-center w-full text-xs">
              <div>Reset All</div>
              <MdUndo size={16} />{" "}
            </div>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DashboardFilter;
