import React, { useEffect, useState } from "react";

type Props = {
  history: any;
  documentDetails?: any;
  respondent?: any;
};

const AuditHistory = ({ history, documentDetails, respondent }: Props) => {
  const { authorEmail, documentId, documentName, status, createdAt } =
    documentDetails;
  console.log(documentDetails, "the document that is being verified");
  let envelopeHistory: any;

  let ifRespondentStatus;

  if (respondent) {
    ifRespondentStatus = respondent?.status;
  }

  if (respondent) {
    envelopeHistory = [...history, ...respondent?.history];
  } else {
    envelopeHistory = [...history];
  }

  return (
    <div className="inset-0 z-50 flex items-center justify-center m-auto max-w-[100%]">
      <div className="bg-white rounded max-w-[100%]">
        <div className="py-2 md:py-4 w-full bg-white flex items-center gap-x-4 border-b border-solid border-gray-200">
          <h2 className="text-lg md:text-xl font-bold text-gray-700 capitalize">
            Document History
          </h2>{" "}
        </div>
        <div className="mt-2 md:mt-4">
          <div className="mb-4 md:mb-8">
            <h2 className="font-semibold text-sm md:text-lg mb-2 md:mb-4">
              Details
            </h2>
            <div className="text-xs md:text-sm font-light flex flex-col gap-y-4">
              <div className="flex justify-between">
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Envelope Name</span>
                  <span>{documentName}</span>
                </div>
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Envelope Id</span>
                  <span>{documentId}</span>
                </div>
              </div>
              <div className="flex basis-1/2 justify-between">
                <div className="flex flex-col gap-y-1">
                  <span className="font-medium">Envelope Recipients</span>
                  {respondent
                    ? respondent.signers?.map((item: any, i: number) => {
                        return <span key={i}>{item.name}</span>;
                      })
                    : documentDetails?.respondentsOfDoc?.map(
                        (item: any, i: number) => (
                          <span key={item._id}>{item.name}</span>
                        )
                      )}
                </div>
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Date Sent</span>
                  <span>
                    {new Date(
                      envelopeHistory?.find(
                        (item: any) =>
                          item.status === "SENT" ||
                          item.status === "RECEIVED" ||
                          item.action === "SENT" ||
                          item.action === "RECEIVED"
                      )?.createdAt
                    ).toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Status</span>
                  <span>{ifRespondentStatus ?? status}</span>
                </div>
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Date Created</span>
                  <span>
                    {new Date(createdAt).toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                  </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Time Zone</span>
                  <span>
                    {new Date(createdAt).toLocaleTimeString("en-US", {
                      timeZoneName: "short",
                    })}
                  </span>
                </div>
                <div className="flex basis-1/2 flex-col gap-y-1">
                  <span className="font-medium">Holder</span>
                  <span>{authorEmail}</span>
                </div>
              </div>
              {respondent && (
                <div className="flex justify-between">
                  <div className="flex basis-1/2 flex-col gap-y-1">
                    <span className="font-medium">Envelope Response _id</span>
                    <span>{respondent?._id}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="max-w-[100%] overflow-x-auto">
            <h2 className="font-semibold text-sm md:text-lg mb-3">
              Activities
            </h2>
            <table className="text-xs md:text-sm font-light overflow-x-scroll max-w-[100%]">
              <thead className="">
                <tr className="">
                  <th className="font-semibold text-left p-2 md:p-4 border border-gray-200">
                    Time
                  </th>
                  <th className="font-semibold text-left p-2 md:p-4 border border-gray-200">
                    User
                  </th>
                  <th className="font-semibold text-left p-2 md:p-4 border border-gray-200">
                    Action
                  </th>
                  <th className="font-semibold text-left p-2 md:p-4 border border-gray-200">
                    Activity
                  </th>
                  <th className="font-semibold text-left p-2 md:p-4 border border-gray-200">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {envelopeHistory.map((item: any, index: any) => (
                  <tr key={index} className="text-xs">
                    <td className="text-gray-600 p-2 md:p-4 border border-gray-200">
                      {item.timestamp ||
                        new Date(item.createdAt).toLocaleString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                    </td>
                    <td className="text-gray-600 p-2 md:p-4 border border-gray-200">
                      {item.userId}, ip:{item.ip}
                    </td>
                    <td className="text-gray-600 p-2 md:p-4 border border-gray-200">
                      {item.action}
                    </td>
                    <td className="text-gray-600 p-2 md:p-4 border border-gray-200">
                      {item.activity}
                    </td>
                    <td className="text-gray-600 p-2 md:p-4 border border-gray-200">
                      {item.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditHistory;
