// @ts-nocheck

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { MdOutlineImage } from "react-icons/md";
import "./index.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuestionTypeChange from "../QuestionTypeChange";
import QuestionImage from "../QuestionImage";
import { replaceHTags } from "../../../utils/editor";

const QuestionInput = ({ helpers }) => {
  let { question, setFormState, isEditor, questionIndex, activeState } =
    helpers;
  const isExtraQuestion =
    question.type === "TitleDesc" ||
    question.type === "Video" ||
    question.type === "Image";
  let questionQuillRef = React.useRef(null);
  const [isEditorActive, setIsEditorActive] = React.useState(false);
  // const [quilleditor, setQuilleditor] = React.useState(null);
  const [unprivilegedEditor, setUnprivilegedEditor] = React.useState(null);

  function setQuillInputQuestionState() {
    let state = isEditorActive ? "active" : "inactive";
    let inputP = document.querySelector(
      `#quill-question-title-${question.id} .ql-container .ql-editor p`
    );
    // console.log("inputP", inputP);
    let qlContainer = document.querySelector(
      `#quill-question-title-${question.id} .ql-container`
    );
    let qlEditor = document.querySelector(
      `#quill-question-title-${question.id} .ql-container .ql-editor`
    );
    // !inputP && console.log("inputP", question);
    // qlEditor.style.padding = state === "active" ? "16px !important" : "0px";

    // inputP.forEach((p, i) => {
    //   if (i === 0) {
    //     p.style.padding = state === "active" ? "16px 16px 0 16px" : "0px";
    //   } else if (i === inputP.length - 1) {
    //     p.style.padding = state === "active" ? "0 16px 16px 16px" : "0px";
    //   } else {
    //     p.style.padding = state === "active" ? "16px" : "0px";
    //   }
    // });
    //
    if (inputP) inputP.style.padding = state === "active" ? "16px" : "0px";

    qlEditor.style.background = state === "active" ? "#f8f9fa" : "none";
    // qlContainer.style.background = "#f2f3f4";
    state === "active"
      ? qlEditor.classList.add("input-hover")
      : qlEditor.classList.remove("input-hover");
    qlEditor.style.borderBottom =
      state === "active" ? "1px solid #80868b" : "none";
  }

  function showEditMenu(display) {
    let inputP = document.querySelector(
      `#quill-question-title-${question.id} .ql-container .ql-editor p`
    );
    let qlContainer = document.querySelector(
      `#quill-question-title-${question.id} .ql-container`
    );
    let qlEditor = document.querySelector(
      `#quill-question-title-${question.id} .ql-container .ql-editor`
    );
    if (display === "flex") {
      if (inputP) inputP.style.padding = "16px";
      // qlEditor.style.padding = "16px !important";
      // inputP.style.border = "1px solid #e4e7eb";
      qlEditor.style.background = "#f8f9fa";
      // hover on qlContainer
      // qlContainer.get
      qlEditor.style.border = "none";
    } else {
      // inputP.style.padding = "0px";
      qlEditor.style.borderBottom = "1px solid #80868b";
    }
    let input = document.querySelector(
      `#quill-question-title-${question.id} .ql-toolbar`
    );
    // console.log(input);
    input.style.display = display;
  }

  useEffect(() => {
    setQuillInputQuestionState();
  }, [isEditorActive]);

  useEffect(() => {
    let isEditorActive_ =
      questionIndex.p === activeState.pageIndex &&
      questionIndex.q === activeState.questionIndex;
    setIsEditorActive(isEditorActive_);
  }, [activeState, questionIndex]);

  useEffect(() => {
    let quilleditor = questionQuillRef.current?.getEditor();
    // setQuilleditor(quilleditor);
    const unprivilegedEditor =
      questionQuillRef.current?.makeUnprivilegedEditor(quilleditor);
    setUnprivilegedEditor(unprivilegedEditor);
  }, []);

  return (
    <div className="w-full flex flex-wrap justify-between">
      <ReactQuill
        ref={questionQuillRef}
        value={question.titleHtml ?? question.title}
        placeholder="Question"
        onChange={async (value) => {
          let newValue = value;
          newValue = await replaceHTags(newValue);
          setFormState((prevState) => {
            let newState = { ...prevState };
            newState.pages.forEach((page) => {
              page.questions.forEach((question_) => {
                if (question_.id === question.id) {
                  question_.titleHtml = newValue;
                  unprivilegedEditor?.getText() &&
                    (question_.title = unprivilegedEditor?.getText());
                }
              });
            });
            return newState;
          });
        }}
        onFocus={() => {
          // console.log("focus");
          showEditMenu("flex");
          // document.querySelector(".ql-toolbar").style.display = "flex";
        }}
        onBlur={() => {
          // setIsEditorActive(false);
          // document.querySelector(".ql-toolbar").style.display = "none";
          showEditMenu("none");
        }}
        modules={{
          toolbar: [
            ["bold", "italic", "underline"],
            [
              "link",
              // "image"
            ],
            // [
            //   { list: "ordered" },
            //   { list: "bullet" },
            // ],
            ["clean"],
          ],
        }}
        disabled={!isEditor || question?.validation === "respondent_email"}
        readOnly={!isEditor || question?.validation === "respondent_email"}
        style={{ fontSize: "16px" }}
        className={`fade quill-que flex w-full max-w-full md:max-w-[412px]`}
        id={`quill-question-title-${question.id}`}
      />
      {!isEditor && question?.required && (
        <span className="text-red-600 text-lg ml-2">
          *
          {question.validation === "respondent_email" && (
            <span className="text-xs whitespace-nowrap">Respondent Email</span>
          )}{" "}
        </span>
      )}
      {isEditorActive && !isExtraQuestion && (
        <Fragment>
          {" "}
          <QuestionImage helpers={{ question, setFormState, isEditor: true }} />
          {/* <div className="mr-2 min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full">
            <MdOutlineImage className="text-2xl text-gray-500" />
          </div> */}
          {question?.validation !== "respondent_email" && (
            <QuestionTypeChange helpers={{ question, setFormState }} />
          )}
          {/* <div className="h-[48px] min-w-[222px] border py-2 px-3 rounded flex items-center">
            <div className="mr-2">
              <MdShortText className="text-2xl text-gray-500" />
            </div>
            <div className="ml-1 mr-2">
              <span className="text-gray-600 text-sm">Short Answer</span>
            </div>
            <div className="ml-auto">
              <MdArrowDropDown className="text-2xl text-gray-500" />
            </div>
          </div> */}
        </Fragment>
      )}
    </div>
  );
};

export default QuestionInput;
