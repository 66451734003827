import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/Common/BoloSelect/BoloSelect";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import BoloLoader, {
  BoloLoaderLine,
} from "../../components/Common/Loader/BoloLoader";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../components/Common/PopOver/BoloPopOver";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/Common/SelectDropDown/BoloDropDown";
import NoDocuments from "../../components/NoDocuments";
import { IMAGE_LINKS } from "../../constants/common";
import { BOLO_CURRENCIES } from "../../constants/currency";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import {
  getAccountLinkApi,
  getPaymentsApi,
  PaymentsDataType,
  updateStripeAccountDataAPI,
} from "../../redux/apis/payments";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import axios from "../../utils/axios";
import {
  formatPrice,
  getDefaultCurrency,
  getStatusByPayment,
} from "../../utils/quoteBuilder";
import { getInitials } from "../../utils/uiUtils";
import PaymentTypeTab from "./PaymentTypeTab";
import SetupPaymentGateway from "./SetupPaymentGateway";

const Payments = () => {
  const payments: {
    key: "total" | "paid" | "pending" | undefined;
    title: string;
    amount: number;
    count: number;
    // currency: string;
    color: string;
    tooltip: string;
  }[] = [
    {
      key: "total",
      title: "Total",
      amount: 0,
      count: 0,
      color: "text-gray-600",
      // currency,
      tooltip: "Includes all payments from the selected filters",
    },
    {
      key: "pending",
      title: "Pending",
      amount: 0,
      count: 0,
      color: "text-blue-400",
      // currency,
      tooltip:
        "Includes all documents awaiting payments from the selected filters",
    },
    {
      key: "paid",
      title: "Paid",
      amount: 0,
      count: 0,
      color: "text-green-600",
      // currency,
      tooltip: "Includes all documents for which the payment has been received",
    },
    {
      // key: "failed",
      key: undefined,
      title: "Failed",
      amount: 0,
      count: 0,
      color: "text-red-600",
      // currency,
      tooltip: "Includes all documents for which the payment has failed",
    },
  ];

  const dispatch = useAppDispatch();
  const naviagte = useNavigate();
  const { getSearchData, setSearchData } = useQueryHook();
  const workSpaceDetails = useWorkSpaceDetails();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [currency, setCurrency] = useState(
    getSearchData().currency_?.toUpperCase() ||
      workSpaceDetails?.stripeData?.default_currency?.toUpperCase()
  );
  const [loading, setLoading] = useState(false);
  const [listOPLoading, setListOPLoading] = useState(false);
  const [markAsPaid, setMarkAsPaid] = useState(false);
  const [paymentsData, setPaymentsData] = useState<
    PaymentsDataType | undefined
  >(undefined);
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);

  const handleAccountLink = async () => {
    setLoading(true);
    try {
      let data = await getAccountLinkApi({});
      let url = data?.url;
      if (data.url) {
        window.open(url, "_self");
      }
    } catch (error) {
      console.log("Error in handleAccountLink1");
      console.log(error);
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleUpdateAccountDataApi = async () => {
    try {
      setLoading(true);
      await updateStripeAccountDataAPI();
      setLoading(false);
    } catch (error) {
      console.log("Error in handleUpdateAccountDataApi");
      console.log(error);
    }
  };

  const handleMarkAsPaid = async (body: {
    docType: "PDF" | "PDF_TEMPLATE";
    resource_id: string;
  }) => {
    setListOPLoading(true);
    try {
      const { data } = await axios.post(`/payments/mark-as-paid`, body);
      setMarkAsPaid((prev) => !prev);
    } catch (error) {
      toast.error("Something went wrong");
      console.log("error in handleMarkAsPaid");
      console.log(error);
    }
    setListOPLoading(false);
  };

  const handlePaymentsData = async () => {
    if (
      !(
        workSpaceDetails.stripeData.isStripeConnectedAccount &&
        workSpaceDetails.stripeData.isStripeAccountActive
      )
    ) {
      return;
    }
    setListOPLoading(true);
    try {
      const data = await getPaymentsApi();
      setPaymentsData(data);
      setSearchData({
        currency_: Object.keys(data?.records || {})?.filter(
          (cur) => cur !== "all_curr"
        )?.[0],
      });
    } catch (error) {
      console.log("error in handlePaymentsData");
      console.log(error);
    }
    setListOPLoading(false);
  };

  useEffect(() => {
    handleUpdateAccountDataApi();
  }, [owner]);

  useEffect(() => {
    handlePaymentsData();
  }, [markAsPaid, workSpaceDetails]);

  useEffect(() => {
    dispatch(getOwnerThunk());
  }, []);

  useEffect(() => {
    setCurrency(
      getSearchData().currency_?.toUpperCase() ||
        workSpaceDetails?.stripeData?.default_currency?.toUpperCase()
    );
  }, [workSpaceDetails, getSearchData().currency_]);

  useEffect(() => {
    if (
      !workSpaceDetails.isWorkSpaceOwner &&
      workSpaceDetails.currentWorkSpaceId &&
      workSpaceDetails.currentWorkSpaceRole
    ) {
      if (workSpaceDetails.currentWorkSpaceRole !== "admin") {
        naviagte("/");
      }
    }
  }, [workSpaceDetails]);

  return (
    <div className="h-full pb-4">
      <Helmet>
        <title translate="no">Payments</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Payments" />
        <meta
          name="description"
          content="This is Boloforms signature Payments page."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/payments`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Payments`} />
        <meta
          property="og:description"
          content="This is Boloforms signature Payments page."
        />
        <link rel="canonical" href="https://signature.boloforms.com/payments" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="h-[40px] flex items-center gap-2 justify-between">
        <p className="font-semibold text-lg">All Payments</p>
        {/* <BoloButton size={"sm"} className="font-semibold">
          Get Started
        </BoloButton> */}
        {Object.keys(paymentsData?.summary || {}).length > 2 &&
          workSpaceDetails.stripeData.isStripeConnectedAccount &&
          workSpaceDetails.stripeData.isStripeAccountActive && (
            <Select
              required
              value={currency}
              onValueChange={(value) => {
                setSearchData({ currency_: value });
              }}
            >
              <SelectTrigger className="w-[350px] px-1">
                <SelectValue placeholder="Select Currency" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {Object.keys(paymentsData?.summary || {})?.map(
                    (curr, idx) => {
                      if (curr === "all_curr") {
                        return null;
                      }
                      return (
                        <SelectItem value={curr}>
                          <div className="flex gap-2">
                            <div className="text-gray-600 text-xs min-w-[40px] font-semibold">
                              {curr}
                            </div>
                            <div className="text-gray-600 text-xs">
                              {
                                BOLO_CURRENCIES.find(
                                  (b_curr) => b_curr.code === curr
                                )?.currency
                              }
                            </div>
                          </div>
                        </SelectItem>
                      );
                    }
                  )}
                </SelectGroup>
              </SelectContent>
            </Select>
          )}
      </div>
      {workSpaceDetails.stripeData.isStripeConnectedAccount &&
        workSpaceDetails.stripeData.isStripeAccountActive && (
          <div
            className="flex overflow-x-auto mt-2 gap-2"
            id="mini-pdf-custom-scroll"
          >
            {payments?.map((payment) => {
              return (
                <PaymentTypeTab
                  title={payment.title}
                  amount={
                    payment.key
                      ? paymentsData?.summary?.[currency]?.[payment.key]
                          ?.amount || payment.amount
                      : payment.amount
                  }
                  count={payment.count}
                  currency={currency}
                  color={payment.color}
                  tooltip={payment.tooltip}
                />
              );
            })}
          </div>
        )}
      <div className="max-h-[calc(100%_-_148px)] h-[calc(100%_-_148px)] py-4">
        {loading && (
          <div className="flex h-full w-full items-center justify-center">
            <BoloLoader />
          </div>
        )}
        {!loading && (
          <>
            {!workSpaceDetails.stripeData.isStripeConnectedAccount && (
              <SetupPaymentGateway />
            )}
            {workSpaceDetails.stripeData.isStripeConnectedAccount &&
              !workSpaceDetails.stripeData.isStripeAccountActive && (
                <div className="flex flex-col h-full w-full items-center justify-center gap-2">
                  <p className="font-semibold text-2xl text-black">
                    Complete your Registeration
                  </p>
                  <div className="w-full max-h-[200px] flex justify-center mt-6">
                    <img
                      className="max-h-[200px]"
                      src="https://i.imgur.com/SMSr4pr.png"
                      alt="payments_img"
                    />
                  </div>
                  <p className="text-xs text-gray-500">
                    Note: If changes are not reflected try refreshing the page
                  </p>
                  <div className="flex gap-2 mt-4">
                    <BoloButton
                      variant={"secondary"}
                      size="xs"
                      disabled={loading}
                      onClick={() =>
                        window.open("https://dashboard.stripe.com/dashboard")
                      }
                    >
                      Go to Stripe Dashboard
                    </BoloButton>
                    <BoloButton
                      variant={"primary"}
                      size="xs"
                      disabled={loading}
                      onClick={() => handleAccountLink()}
                    >
                      Submit Account Details
                    </BoloButton>
                  </div>
                </div>
              )}

            {paymentsData?.summary?.all_curr?.total?.count === 0 && (
              <div className="h-full w-full flex items-center justify-center">
                <NoDocuments navLink={"/new/pdf-template"} />
              </div>
            )}
            {paymentsData?.summary?.all_curr?.total?.count !== 0 &&
              workSpaceDetails.stripeData.isStripeConnectedAccount &&
              workSpaceDetails.stripeData.isStripeAccountActive && (
                <>
                  <div className="flex items-center text-xs text-black font-semibold border-b-2 border-gray-100 max-h-[40px] min-h-[45px] px-3">
                    <div className="px-2 w-[10%]">Price</div>
                    <div className="px-2 w-[30%] truncate">Document</div>
                    <div className="px-2 w-[15%] truncate">Status</div>
                    <div className="px-2 w-[20%] truncate">Creation Date</div>
                    <div className="px-2 w-[30%] ">Payers</div>
                    <div className="w-[5%] flex justify-end"></div>
                    {/* <div className="px-2 w-[10%] truncate">
                          {record?.docType}
                        </div> */}
                  </div>
                  <BoloLoaderLine loading={listOPLoading} />
                  <div
                    className="flex flex-col max-h-[calc(100%_-_45px)] overflow-y-auto"
                    id="mini-pdf-custom-scroll"
                  >
                    {paymentsData?.records?.[currency]?.map((record) => {
                      return (
                        <div className="flex items-center text-xs text-gray-600 border-b-2 border-gray-100 max-h-[40px] min-h-[45px] px-3">
                          <div className="px-2 font-semibold w-[10%]">
                            {formatPrice({
                              amount:
                                record?.documentValue?.valueType ===
                                "DOCUMENT_TOTAL"
                                  ? record?.documentValue?.amount?.valueTotal
                                  : record?.documentValue?.amount?.customAmount,
                              currency,
                            })}
                          </div>
                          <div className="px-2 w-[30%] truncate">
                            {record?.documentName}
                          </div>
                          <div
                            style={{
                              color: getStatusByPayment({
                                document: {
                                  status: record?.status,
                                  settings: {
                                    documentValue: record?.documentValue,
                                  },
                                },
                              }).color,
                            }}
                            className="px-2 w-[15%] truncate"
                          >
                            <div>{record?.status}</div>
                            <div className="text-[10px] text-yellow-500">
                              {
                                getStatusByPayment({
                                  document: {
                                    status: record?.status,
                                    settings: {
                                      documentValue: record?.documentValue,
                                    },
                                  },
                                }).message
                              }
                            </div>
                          </div>
                          <div className="px-2 w-[20%] truncate">
                            <h3>
                              {new Date(record?.documentCreated).toDateString()}
                            </h3>
                            <p className="text-[10px]">
                              {new Date(
                                record?.documentCreated
                              ).toLocaleTimeString()}
                            </p>
                          </div>
                          <div className="px-2 w-[30%] ">
                            <BoloPopover>
                              <BoloPopoverTrigger asChild>
                                <div className="cursor-pointer w-[100%] hover:text-gray-900 transition-all duration-200">
                                  Show Details
                                </div>
                              </BoloPopoverTrigger>
                              <BoloPopoverContent align="start">
                                <div className="bg-white min-w-[100%] text-xs text-gray-600 p-2 flex flex-col gap-2">
                                  {record?.signers
                                    ?.filter((signer) =>
                                      record?.documentValue?.selectedPayers?.includes(
                                        record?.docType === "PDF"
                                          ? signer?.email
                                          : signer?.roleTitle
                                      )
                                    )
                                    ?.map((signer) => {
                                      return (
                                        <div className="border flex gap-2 items-center rounded p-2">
                                          <div
                                            className="rounded-full h-7 w-7 border flex items-center justify-center"
                                            style={{
                                              background: `${
                                                signer?.roleColour ||
                                                signer?.colour
                                              }20`,
                                              border: `2px solid ${
                                                signer?.roleColour ||
                                                signer?.colour
                                              }`,
                                            }}
                                          >
                                            {getInitials(
                                              signer?.name || signer?.roleTitle
                                            )}
                                          </div>
                                          {/* {JSON.stringify(signer)} */}
                                          <div>
                                            <div className="font-semibold">
                                              {signer?.name ||
                                                signer?.roleTitle}
                                            </div>
                                            <div className="text-[10px]">
                                              {signer?.email}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {record?.signers?.filter((signer) =>
                                    record?.documentValue?.selectedPayers?.includes(
                                      record?.docType === "PDF"
                                        ? signer?.email
                                        : signer?.roleTitle
                                    )
                                  ).length === 0 && "No Payers assigned"}
                                </div>
                              </BoloPopoverContent>
                            </BoloPopover>
                          </div>
                          <div className="w-[5%] flex justify-end">
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <div className="p-1 rounded-md hover:bg-gray-100 cursor-pointer">
                                  <BsThreeDotsVertical size={16} />
                                </div>
                              </DropdownMenuTrigger>
                              <DropdownMenuGroup>
                                <DropdownMenuContent
                                  align="end"
                                  sideOffset={10}
                                  className=""
                                >
                                  <DropdownMenuItem
                                    disabled={record.status !== "SIGNED"}
                                    onClick={() => {
                                      handleMarkAsPaid({
                                        docType: record?.docType,
                                        resource_id: record?._id,
                                      });
                                    }}
                                  >
                                    <div className="flex gap-2 items-center text-xs">
                                      <FaCheck /> Mark as Paid
                                    </div>
                                  </DropdownMenuItem>
                                </DropdownMenuContent>
                              </DropdownMenuGroup>
                            </DropdownMenu>
                          </div>
                          {/* <div className="px-2 w-[10%] truncate">
                          {record?.docType}
                        </div> */}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default Payments;
