import React from "react";
import { CiSearch } from "react-icons/ci";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import { BoloButton } from "../Common/Button/BoloButton";

const NoSearchResults = () => {
  const { reset } = useQueryHook();
  return (
    <div className="flex flex-col gap-3 w-full items-center justify-center py-16">
      <div className="flex items-center gap-4 pr-4">
        <CiSearch size={60} /> No Search results found
      </div>
      <BoloButton size={"xs"} onClick={() => reset("all")}>
        Reset Search Filter
      </BoloButton>
    </div>
  );
};

export default NoSearchResults;
