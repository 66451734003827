import React, { useEffect, useState } from "react";
import IpTrackCheck from "../../components/IpTrackCheck/IpTrackCheck";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import axios from "../../utils/axios";

const MailInviteApproval = () => {
  const [searchParams] = useSearchParams();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const key = searchParams.get("key");
  const createSigningLog = async () => {
    if (!key) {
      return;
    }
    const ipResponse = getIpAddressLocal();
    const ipData = await ipResponse;
    const { data } = await axios.get(
      `/signingLog/resolveMailInvite-approval?key=${key}&ip=${ipData.ip}`
    );
    console.log(data);
    if (data?.redirect) {
      console.log(data);
      window.open(data?.redirect, "_self");
    }
  };

  useEffect(() => {
    createSigningLog();
  }, [key]);
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <IpTrackCheck /> <BoloLoader />
    </div>
  );
};

export default MailInviteApproval;
