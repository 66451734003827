import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";
import { InfinitySpin } from "react-loader-spinner";
import BoloLoader from "../../components/Common/Loader/BoloLoader";

const DocumentUrl = () => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");

  const createSigningLog = async () => {
    if (!key) {
      return;
    }
    const { data } = await axios.get(
      `/signingLog/getMailInvitelink?key=${key}`
    );
    if (data?.redirect) {
      console.log(data);
      window.open(data?.redirect, "_self");
    }
  };

  useEffect(() => {
    createSigningLog();
  }, [key]);

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <BoloLoader />
    </div>
  );
};

export default DocumentUrl;
