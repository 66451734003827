import React from "react";

interface SMSBodyDataFormProps {
  smsBody: string;
  setSmsBody: (smsBody: string) => void;
  isTemplate?: boolean;
}

const SMSBodyDataForm: React.FC<SMSBodyDataFormProps> = ({
  smsBody,
  setSmsBody,
  isTemplate = false,
}) => {
  return (
    <div
      className={`py-2 md:py-4 w-[100%] md:w-[65%] max-w-[800px] mb-4 ${
        !isTemplate ? "mx-auto" : ""
      }`}
    >
      <p className="text-sm mb-2 font-medium">
        Message for recipient (Optional)
      </p>{" "}
      <div className="flex flex-row justify-center items-center gap-2 border border-gray-300 rounded-md p-2">
        <input
          type="text"
          id="smsBody"
          //turn off suggestions for smsBody
          autoComplete="off"
          value={smsBody}
          placeholder="SMS body here..."
          className="w-full text-xs md:text-sm outline-none"
          onChange={(e) => {
            setSmsBody(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default SMSBodyDataForm;
