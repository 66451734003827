import React from "react";
import { TableSchema } from "../../../../common/src/type";
import { ZOOM } from "../../constants";
import { SchemaUIProps } from "./SchemaUI";
type Props = SchemaUIProps & { schema: TableSchema };
const SubtotalMenu = (props: Props) => {
  let { schema } = props;
  let style: React.CSSProperties = {
    width: "fit-content",
    marginLeft: "auto",
    fontSize: "10pt",
    padding: `${1 * ZOOM}pt`,
    textAlign: "right",
    lineHeight: "1em",
    color: schema.fontColor,
  };

  let tabContainer: React.CSSProperties = {
    display: "flex",
  };

  let tabStyle: React.CSSProperties = {
    height: 6 * ZOOM,
    width: 25 * ZOOM,
  };

  return (
    <div style={style}>
      <div style={tabContainer}>
        <div>
          <div style={{ ...tabStyle }}>Subtotal</div>
          {schema?.subtotalMenu?.discount?.show2Rec && (
            <div style={{ ...tabStyle }}>Discount</div>
          )}
          {schema?.subtotalMenu?.fee?.show2Rec && (
            <div style={{ ...tabStyle }}>Fee</div>
          )}
          {schema?.subtotalMenu?.tax?.show2Rec && (
            <div style={{ ...tabStyle }}>Tax</div>
          )}
          <div style={{ ...tabStyle }}>Total</div>
        </div>
        <div>
          <div style={{ ...tabStyle, fontWeight: 600 }}>
            {/* {schema?.subtotalMenu?.symbol || ""}
            {schema?.subtotalMenu?.subtotal?.amount} */}
            {schema?.subtotalMenu?.subtotal?.data}
          </div>
          {schema?.subtotalMenu?.discount?.show2Rec && (
            <div style={{ ...tabStyle, fontWeight: 600 }}>
              {/* {schema?.subtotalMenu?.symbol || ""}
            {schema?.subtotalMenu?.discount?.amount} */}
              {schema?.subtotalMenu?.discount?.data}
            </div>
          )}
          {schema?.subtotalMenu?.fee?.show2Rec && (
            <div style={{ ...tabStyle, fontWeight: 600 }}>
              {/* {schema?.subtotalMenu?.symbol || ""}
            {schema?.subtotalMenu?.discount?.amount} */}
              {schema?.subtotalMenu?.fee?.data}
            </div>
          )}
          {schema?.subtotalMenu?.tax?.show2Rec && (
            <div style={{ ...tabStyle, fontWeight: 600 }}>
              {/* {schema?.subtotalMenu?.symbol || ""}
            {schema?.subtotalMenu?.tax?.amount} */}
              {schema?.subtotalMenu?.tax?.data}
            </div>
          )}
          <div style={{ ...tabStyle, fontWeight: 600 }}>
            {/* {schema?.subtotalMenu?.symbol || ""}
            {schema?.subtotalMenu?.total?.amount} */}
            {schema?.subtotalMenu?.total?.data}
          </div>
        </div>
      </div>
      {/* <div style={tabContainer}>
        <div style={{ ...tabStyle }}>Subtotal</div>
        <div style={{ ...tabStyle, fontWeight: 600 }}>
          ₹{schema?.subtotalMenu?.subtotal?.amount}
        </div>
      </div>
      <div style={tabContainer}>
        <div style={{ ...tabStyle }}>Discount</div>
        <div style={{ ...tabStyle, fontWeight: 600 }}>
          ₹{schema?.subtotalMenu?.discount?.amount}
        </div>
      </div>
      <div style={tabContainer}>
        <div style={{ ...tabStyle }}>Tax</div>
        <div style={{ ...tabStyle, fontWeight: 600 }}>
          ₹{schema?.subtotalMenu?.tax?.amount}
        </div>
      </div>
      <div style={tabContainer}>
        <div style={{ ...tabStyle }}>Total</div>
        <div style={{ ...tabStyle, fontWeight: 600 }}>
          ₹{schema?.subtotalMenu?.total?.amount}
        </div>
      </div> */}
    </div>
  );
};

export default SubtotalMenu;
