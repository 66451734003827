import { useEffect, useRef, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
interface SelectOptionsProps {
  options?: any;
  value?: string;
  changeValue: (value: string) => void;
}
const SelectSigner = ({ options, value, changeValue }: SelectOptionsProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const dropdownRef = useRef<any>(null);
  const handleOutsideClick = (e: any) => {
    if (dropdownRef.current !== null) {
      if (dropdownRef.current.contains(e.target)) return;
    }
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const getSignerNameAndTitle = (value: string) => {
    const signer = options?.find((signer: any) => {
      return signer.email === value;
    });
    return `${signer?.roleTitle} ( ${signer?.name} )`;
  };
  return (
    <div className="relative">
      <div
        className="w-full flex justify-center items-center bg-white  h-[38px] border-gray-300 border rounded-md cursor-pointer"
        onClick={toggleDropdown}
      >
        <span className="flex gap-1 flex-1 justify-start items-center px-4 text-sm font-medium whitespace-nowrap  text-ellipsis w-full overflow-hidden">
          {value ? getSignerNameAndTitle(value) : "Select Signer"}
        </span>

        <div className="h-[32px] min-w-[1px] max-w-[1px] border-r border-gray-300"></div>

        <div className="text-lg p-1 px-2">
          <MdArrowDropDown />
        </div>
        {isDropdownOpen && (
          <div
            className="absolute left-0 mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg p-2 top-9"
            ref={dropdownRef}
          >
            {options
              ?.filter((option: any) => {
                return option.email;
              })
              ?.map((option: any, index: number) => {
                return (
                  <div
                    className="h-10  w-full hover:bg-gray-100 cursor-pointer flex justify-start items-center rounded-md whitespace-nowrap  text-ellipsis overflow-hidden"
                    onClick={() => {
                      changeValue(option?.email);
                    }}
                    key={index}
                  >
                    <span className="aspect-square h-full flex justify-center items-center text-[18px] text-gray-700">
                      {value === option?.email && <FaCheck />}
                    </span>
                    <span className="flex-1 text-gray-700 text-sm">
                      {option?.roleTitle} ( {option?.name} )
                    </span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectSigner;
