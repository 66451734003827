import React from "react";

const Header = ({ formState }: any) => {
  return (
    <div className="max-w-[700px] mx-auto bg-white w-full py-2 ">
      <div className="px-4">
        <div className="h-full w-24 mr-2 mb-4">
          <img src={formState.logo} alt="company logo" className="h-full" />
        </div>
        <div className="w-full flex flex-col">
          {formState.title && (
            <h2 className="text-4xl font-semibold">{formState.title}</h2>
          )}
          {formState.description && (
            <p className="text-xs mt-3 text-gray-700">
              {formState.description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
