import { BASE_URL, STRIPE_CONNECT_URL } from "../../constants/constants";
import { DocumentValueType } from "../../pdfme/ui/src/components/Designer";
import { getWorkSpaceDetails } from "../../utils";
import axios from "../../utils/axios";

type PaymentSummaryType = {
  [currency: string]: {
    total: { count: number; amount: number };
    paid: { count: number; amount: number };
    pending: { count: number; amount: number };
  };
};

type PaymentRecordType = {
  [currency: string]: {
    _id: string;
    status: string;
    documentName: string;
    documentValue: DocumentValueType;
    documentCreated: string;
    signers: {
      email: string;
      roleTitle: string;
      name: string;
      roleColour: string;
      colour: string;
    }[];
    docType: "PDF" | "PDF_TEMPLATE";
  }[];
};

export type PaymentsDataType = {
  summary: PaymentSummaryType;
  records: PaymentRecordType;
};

async function createConnectedAccountApi({
  return_url,
}: {
  return_url?: string;
}) {
  try {
    const localWorkSpaceId = getWorkSpaceDetails();
    const resp = await axios.post(
      `${STRIPE_CONNECT_URL}/createConnectedAccount`,
      {
        workSpaceId: localWorkSpaceId.currentWorkSpaceId,
        return_url,
      }
    );
    return resp?.data;
  } catch (error) {
    console.log("Error in createConnectedAccountApi");
    console.log(error);
  }
}

async function getAccountLinkApi({ return_url }: { return_url?: string }) {
  try {
    const localWorkSpaceId = getWorkSpaceDetails();
    const resp = await axios.get(
      `${STRIPE_CONNECT_URL}/accountLink?accountId=${
        localWorkSpaceId?.stripeData?.id
      }&return_url=${return_url || ""}`
    );
    return resp?.data;
  } catch (error) {
    console.log("Error in getAccountLinkApi");
    console.log(error);
  }
}

async function updateStripeAccountDataAPI() {
  try {
    const localWorkSpaceId = getWorkSpaceDetails();
    const resp = await axios.get(
      `${STRIPE_CONNECT_URL}/updateAccount?workSpaceId=${localWorkSpaceId?.currentWorkSpaceId}`
    );
    return resp?.data;
  } catch (error) {
    console.log("Error in getAccountLinkApi");
    console.log(error);
  }
}

async function disconnectAccountApi() {
  try {
    const localWorkSpaceId = getWorkSpaceDetails();
    const resp = await axios.post(`${STRIPE_CONNECT_URL}/disconnectAccount`, {
      workSpaceId: localWorkSpaceId?.currentWorkSpaceId,
    });
    return resp?.data;
  } catch (error) {
    console.log("Error in getAccountLinkApi");
    console.log(error);
  }
}
async function fetchConnectedAccountStatusApi() {}

async function getPaymentsApi(): Promise<PaymentsDataType | undefined> {
  let resp = await axios.get(`${BASE_URL}/payments`);
  // console.log(resp.data);
  return resp?.data;
}

export {
  createConnectedAccountApi,
  getAccountLinkApi,
  updateStripeAccountDataAPI,
  getPaymentsApi,
  disconnectAccountApi,
};
