import React, { MutableRefObject, ReactNode, useContext } from "react";
import "./Paper.css";
// import { SchemaForUI, Size, getFallbackFontName } from '@pdfme/common';
import { FontContext } from "../contexts";
import { ZOOM, RULER_HEIGHT } from "../constants";
import { SchemaForUI, Size, getFallbackFontName } from "../../../common/src";
import { round } from "lodash";
import { DesignerState, GlobalHelpersType } from "./Designer";
import PaperSeperator from "./PaperSeperator";
import AttachmentInPaper from "./AttachmentInPaper";
import { PreviewStateType } from "./Preview";

const Paper = (porps: {
  paperRefs: MutableRefObject<HTMLDivElement[]>;
  scale: number;
  size: Size;
  schemasList: SchemaForUI[][];
  pageSizes: Size[];
  backgrounds: string[];
  renderPaper?: (arg: { index: number; paperSize: Size }) => ReactNode;
  renderSchema: (arg: { index: number; schema: SchemaForUI }) => ReactNode;
  editable?: boolean;
  addSchema?: (schema: any) => void;
  pageCursor?: number;
  isEditor?: boolean;
  designerState?: DesignerState;
  globalHelpers?: GlobalHelpersType;
  setDesignerState?: React.Dispatch<React.SetStateAction<DesignerState>>;
  previewState?: PreviewStateType;
}) => {
  const {
    paperRefs,
    scale,
    size,
    schemasList,
    pageSizes,
    backgrounds,
    renderPaper,
    renderSchema,
    editable,
    addSchema,
    pageCursor,
    isEditor,
    designerState,
    setDesignerState,
    globalHelpers,
    previewState,
  } = porps;
  const font = useContext(FontContext);

  if (
    pageSizes.length !== backgrounds.length ||
    pageSizes.length !== schemasList.length
  ) {
    return null;
  }

  const mapper = (type: any) => {
    switch (type) {
      case "Signature":
        return;

      default:
        return;
    }
  };
  const pageSizeDefault = pageSizes[0];
  const paperSizeDefault = {
    width: pageSizeDefault.width * ZOOM,
    height: pageSizeDefault.height * ZOOM,
  };
  // function createFixedCircle(x: any, y: any) {
  //   // Create a new div element for the circle
  //   var circle = document.createElement("div");

  //   // Apply styles to make it a circle
  //   circle.style.width = "10px";
  //   circle.style.height = "10px";
  //   circle.style.borderRadius = "50%";
  //   circle.style.backgroundColor = "blue";
  //   circle.style.position = "absolute";

  //   // Set the position of the circle
  //   circle.style.left = x + "px";
  //   circle.style.top = y + "px";

  //   // Append the circle to the body of the document
  //   document.body.appendChild(circle);
  // }
  function handleDropInValue(e: any) {
    if (!String(e.target.id).endsWith(`${pageCursor}`)) {
      // not current page
      return;
    }

    const schemaData = e.dataTransfer.getData("schemaData");
    const parsedData = JSON.parse(schemaData);
    if (!parsedData?.data) {
      // in case of accidental drag events
      return;
    }

    const area = paperRefs.current[pageCursor || 0].getBoundingClientRect();
    // createFixedCircle(e.clientX, e.clientY);
    const pageSize = pageSizes[pageCursor || 0];
    // this is a workaround the library
    const dynamicZoom = {
      wR: area.width / pageSizes[pageCursor || 0].width,
      hR: area.height / pageSizes[pageCursor || 0].height,
    };
    const xRelativeToDropArea = e.clientX - area.left;
    const yRelativeToDropArea = e.clientY - area.top;
    const x = xRelativeToDropArea / dynamicZoom.wR || 0;
    const y = yRelativeToDropArea / dynamicZoom.hR || 0;
    // this added items height and width to position
    const adddedSize = {
      x: x + (parsedData?.size?.width || 0),
      y: y + (parsedData?.size?.height || 0),
    };
    // this checks for overflow of item
    const parsedSize = {
      x: round(
        adddedSize.x - pageSize.width > 0
          ? pageSize.width - parsedData?.size?.width
          : x,
        2
      ),
      y: round(
        adddedSize.y - pageSize.height > 0
          ? pageSize.height - parsedData?.size?.height
          : y,
        2
      ),
    };
    // console.log({
    //   actualPos: { x, y },
    //   pageSize: pageSize,
    //   adddedSize: adddedSize,
    //   parsedSize: parsedSize,
    // });
    if (addSchema && parsedData?.data && parsedData?.type == "PDF_TEMPLATE") {
      addSchema({ ...parsedData?.data, position: parsedSize });
    }
  }

  function handleDragOver(e: any) {
    e.preventDefault();
  }

  return (
    <div
      className={`${editable ? "mt-[80px]" : ""}`}
      style={{
        transform: `scale(${scale})`,
        transformOrigin: "center top",
        ...size,
        maxWidth: "100%",
      }}
    >
      {isEditor && designerState?.instanceFrom !== "FORM" && (
        <PaperSeperator
          pageNo={0}
          designerState={designerState}
          setDesignerState={setDesignerState}
          globalHelpers={globalHelpers}
        />
      )}
      {(designerState?.fetchedData?.document?.settings?.attachments?.length >
        0 ||
        (previewState && previewState?.settings?.attachments?.length > 0)) &&
        designerState?.instanceFrom !== "FORM" && (
          <AttachmentInPaper
            pageNo={0}
            designerState={designerState}
            setDesignerState={setDesignerState}
            globalHelpers={globalHelpers}
            previewState={previewState}
            totalPages={backgrounds?.length}
            paperSize={paperSizeDefault}
          />
        )}
      {backgrounds.map((background, paperIndex) => {
        const pageSize = pageSizes[paperIndex];
        const paperSize = {
          width: pageSize.width * ZOOM,
          height: pageSize.height * ZOOM,
        };

        return (
          <>
            <div
              onDrop={(e) => {
                handleDropInValue(e);
              }}
              onDragOver={handleDragOver}
              className="mx-auto shadow-lg"
              id={`@pdfme/ui-paper${paperIndex}`}
              key={paperIndex + JSON.stringify(paperSize)}
              ref={(e) => {
                if (e) {
                  paperRefs.current[paperIndex] = e;
                }
              }}
              onClick={(e) => {
                if (
                  e.currentTarget === e.target &&
                  document &&
                  document.hasFocus() &&
                  document.activeElement instanceof HTMLElement
                ) {
                  document.activeElement.blur();
                }
              }}
              style={{
                fontFamily: `'${getFallbackFontName(font)}'`,
                // top: `${RULER_HEIGHT}px`,
                top: "2px",
                left:
                  paperSize.width > size.width
                    ? `${(size.width - paperSize.width) / 2}px`
                    : 0,
                position: "relative",
                backgroundImage: `url(${background})`,
                backgroundSize: `${paperSize.width}px ${paperSize.height}px`,
                ...paperSize,
                marginBottom:
                  isEditor && designerState?.instanceFrom !== "FORM"
                    ? "0px"
                    : "16px",
                // marginBottom: backgrounds.length - 1 == paperIndex ? "0px" : "",
              }}
            >
              {renderPaper && renderPaper({ paperSize, index: paperIndex })}
              {schemasList[paperIndex].map((schema, schemaIndex) => {
                return (
                  <div key={schema.id}>
                    {renderSchema({
                      schema,
                      index:
                        paperIndex === 0
                          ? schemaIndex
                          : schemaIndex + schemasList[paperIndex - 1].length,
                    })}
                  </div>
                );
              })}
            </div>
            {isEditor && designerState?.instanceFrom !== "FORM" && (
              <PaperSeperator
                pageNo={paperIndex + 1}
                designerState={designerState}
                setDesignerState={setDesignerState}
                globalHelpers={globalHelpers}
              />
            )}
            {(designerState?.fetchedData?.document?.settings?.attachments
              ?.length > 0 ||
              (previewState &&
                previewState?.settings?.attachments?.length > 0)) &&
              designerState?.instanceFrom !== "FORM" && (
                <AttachmentInPaper
                  pageNo={paperIndex + 1}
                  designerState={designerState}
                  setDesignerState={setDesignerState}
                  globalHelpers={globalHelpers}
                  previewState={previewState}
                  totalPages={backgrounds?.length}
                  paperSize={paperSize}
                />
              )}
          </>
        );
      })}

      <div className="w-full pb-[100px]"></div>
    </div>
  );
};

export default Paper;
