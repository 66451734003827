import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Loader from "./Loader";

interface AddonImageSliderProps {
  images: string[];
}

const AddonImageSlider: React.FC<AddonImageSliderProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    setLoading(true); // Set loading to true when images change
    // const timer = setTimeout(() => {
    //   setLoading(false);
    // }, 5000);

    // return () => {
    //   clearTimeout(timer);
    // };
  }, [images]);

  const goToNextImage = () => {
    setPrevIndex(currentIndex);

    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevImage = () => {
    setPrevIndex(currentIndex);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleImageLoad = () => {
    setLoading(false); // Set loading to false when images are loaded
  };

  return (
    <div>
      <div
        className="relative overflow-hidden rounded-md w-fit "
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {/* <div className="flex  h-fit max-w-fit">
          {images.map(
            (image, index) => (
              console.log("image", image),
              (
                <React.Fragment key={index}>
                  {loading && <Loader />}
                  <img
                    src={image}
                    className={`duration-200 transition-transform ease-in-out -translate-x-[${
                      currentIndex * 100
                    }%]`}
                    alt="Addon"
                    onLoad={() => handleImageLoad()}
                    style={{ display: loading ? "none" : "block" }}
                  />
                </React.Fragment>
              )
            )
          )}
        </div> */}

        <div className="flex  h-fit max-w-fit">
          {/* {images.map((image, index) => (
            <React.Fragment key={index}>
              {loading && <Loader index={currentIndex} />}
              <img
                key={image}
                src={image}
                className={`duration-200 transition-transform ease-in-out -translate-x-[${
                  currentIndex * 100
                }%]`}
                alt="Addon"
                onLoad={() => handleImageLoad()}
                style={{ display: loading ? "none" : "block" }}
              />
            </React.Fragment>
          ))} */}
          <img
            src={images[currentIndex]}
            className={`duration-200 transition-transform ease-in-out -translate-x-[${
              currentIndex * 100
            }%]`}
            alt="Addon"
            // onLoad={() => handleImageLoad()}
            // style={{ display: loading ? "none" : "block" }}
          />
        </div>

        <button
          className={`absolute top-1/2 left-0 transform -translate-y-1/2 bg-white p-2 rounded-sm shadow-md text-gray-500 hover:bg-gray-100 transition-transform z-[100] ${
            hovered ? "translate-x-0" : "-translate-x-full"
          }`}
          onClick={goToPrevImage}
        >
          <IoIosArrowBack size={20} />
        </button>

        <button
          className={`absolute top-1/2 right-0 transform -translate-y-1/2 bg-white p-2 rounded-sm shadow-md text-gray-500 hover:bg-gray-100 transition-transform z-[100] ${
            hovered ? "translate-x-0" : "translate-x-full"
          }`}
          onClick={goToNextImage}
        >
          <IoIosArrowForward size={20} className="" />
        </button>
      </div>
      <div className="flex items-center justify-center gap-2 mt-2">
        {images.map((image, index) => (
          <button
            key={index}
            className={`w-6 h-[5px] rounded-sm bg-gray-300 hover:bg-gray-400 transition-all ease-in-out duration-[800] ${
              currentIndex === index ? "bg-gray-800" : ""
            }`}
            onClick={() => setCurrentIndex(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default AddonImageSlider;
