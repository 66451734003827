import React from "react";
import {
  DropdownOptionSchema,
  SchemaForUI,
} from "../../../../../../common/src/type";
import { MdClose, MdDelete } from "react-icons/md";

interface Props {
  activeSchema: SchemaForUI;
  dropdownOptions: DropdownOptionSchema[];
  changeSchemas: (
    objs: {
      key: string;
      value:
        | undefined
        | string
        | number
        | {
            min: number;
            max: number;
          }
        | boolean
        | DropdownOptionSchema[];
      schemaId: string;
    }[]
  ) => void;
}

const DropDownOptions = ({
  dropdownOptions,
  changeSchemas,
  activeSchema,
}: Props) => {
  const handleChange = (
    changeType: "value" | "option",
    data: string,
    idx: number
  ) => {
    let newDropDownOptions = dropdownOptions;
    newDropDownOptions[idx][changeType] = data;
    changeSchemas([
      {
        key: "dropdownOptions",
        value: newDropDownOptions,
        schemaId: activeSchema.id,
      },
    ]);
  };

  const handleAddOption = () => {
    let newDropDownOptions = dropdownOptions;
    newDropDownOptions.push({
      option: `Option ${dropdownOptions.length + 1}`,
      value: `Option ${dropdownOptions.length + 1}`,
    });
    changeSchemas([
      {
        key: "dropdownOptions",
        value: newDropDownOptions,
        schemaId: activeSchema.id,
      },
    ]);
  };

  const handleRemoveOption = (idx: number) => {
    let newDropDownOptions = dropdownOptions;
    newDropDownOptions.splice(idx, 1);
    changeSchemas([
      {
        key: "dropdownOptions",
        value: newDropDownOptions,
        schemaId: activeSchema.id,
      },
    ]);
  };

  return (
    <div className="text-xs">
      <div className="mb-4">Options:</div>
      <div className="flex flex-col gap-2">
        {/* <div className="flex w-full gap-1">
          <div className="w-full pl-1">Option</div>
          <div className="w-full pl-1">Value</div>
        </div> */}
        {dropdownOptions?.map((opt: DropdownOptionSchema, idx: number) => {
          return (
            <div className="flex w-full gap-1 items-center justify-between">
              <input
                className="w-[90%] border-2 border-primary/90 rounded-[3px] pl-1 outline-none"
                type="text"
                value={opt.option}
                onChange={(e) => {
                  handleChange("option", e.target.value, idx);
                  handleChange("value", e.target.value, idx);
                }}
              />
              {/* <input
                className="w-[45%] border-2 border-primary/90 rounded-[3px] pl-1 outline-none"
                type="text"
                value={opt.value}
                onChange={(e) => {
                  handleChange("value", e.target.value, idx);
                }}
              /> */}
              <MdClose
                className="cursor-pointer"
                size={16}
                onClick={() => {
                  handleRemoveOption(idx);
                }}
              />
            </div>
          );
        })}
        <div>
          <button
            onClick={handleAddOption}
            className="text-primary border-2 border-primary rounded-[3px] px-2 py-1 w-full mt-3"
          >
            Add Option
          </button>
        </div>
      </div>
    </div>
  );
};

export default DropDownOptions;
