// @ts-nocheck
import React from "react";
import { VscEmptyWindow } from "react-icons/vsc";

const NoFolders = ({ helpers }) => {
  const { sharedAdmins, onClickHandler } = helpers;
  return (
    <div
      onClick={() => {}}
      className="mt-6 w-full px-3 md:px-[100px] pb-10 rounded-md hover:border-primary cursor-pointer"
    >
      <div className="w-full flex flex-col justify-center items-center">
        <div className="border-2 border-primary rounded-md mb-4 w-[100%] md:w-[400px]">
          <VscEmptyWindow className="text-9xl text-primary p-8 m-auto" />
          <h1 className="text-lg md:text-2xl font-medium mb-2 text-center">
            No Folders!
          </h1>
          <h2 className="text-xs text-center">
            {sharedAdmins?.length > 0
              ? "Create a new folder or ask your admin to share one with you."
              : "No folders are assigned to you yet."}
          </h2>
          <button
            onClick={() => {
              onClickHandler?.();
            }}
            className="w-full mt-6 px-6 py-3 bg-primary text-white text-sm hover:opacity-90"
          >
            Create your own
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoFolders;
