import { useEffect, useRef } from "react";
import { FaBell } from "react-icons/fa";
import { MdTimer } from "react-icons/md";
import CustomTooltip from "../../../../../components/EditForm/CustomTooltip";
import { BiImport } from "react-icons/bi";
import MixPanel from "../../../../../utils/services/mixpanel";
// import { SlCalender } from "react-icons/sl";
const SettingsMenu = ({
  designerState,
  setIsSubMenu,
  selectOptionType,
}: any) => {
  const subMenuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
        setIsSubMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSubMenu]);
  const mixpanelSetting = (actionName: string) => {
    let Mixpanel = new MixPanel();

    if (designerState?.instanceFrom === "PDF") {
      Mixpanel.track("PDF", {
        pdf_action: `pdf_${actionName}`,
        paid: true,
      });
      Mixpanel.increment(`pdf_${actionName}`);
    } else if (designerState?.instanceFrom === "PDF-TEMPLATE") {
      Mixpanel.track("PDF template", {
        pdf_template_action: `pdf_template_${actionName}`,
        paid: true,
      });
      Mixpanel.increment(`pdf_template_${actionName}`);
    }
  };
  return (
    <div
      ref={subMenuRef}
      className="absolute top-[100%] right-0 bg-white border py-2 rounded shadow w-[190px] z-[50]"
    >
      <div
        className="flex items-center hover:bg-gray-100 px-3 py-[2px]"
        onClick={() => {
          mixpanelSetting("expiration");
          selectOptionType("expiration");
        }}
      >
        <span className="inline-flex justify-center items-center w-8 h-8 mr-2">
          <MdTimer className="text-xl" />
        </span>
        <span className="text-sm">Expiration</span>
      </div>
      <div
        className="flex items-center  hover:bg-gray-100 px-3 py-[2px]"
        onClick={() => {
          mixpanelSetting("reminder");
          selectOptionType("reminder");
        }}
      >
        <span className="inline-flex justify-center items-center w-8 h-8 mr-2">
          <FaBell className="text-xl" />
        </span>
        <span className="text-sm">Reminders</span>
      </div>
      <div
        data-tooltip-id="importFields-btn"
        data-tooltip-offset={10}
        className="flex items-center  hover:bg-gray-100 px-3 py-[2px]"
        onClick={() => {
          mixpanelSetting("import_fields");
          selectOptionType("import_fields");
        }}
      >
        <span className="inline-flex justify-center items-center w-8 h-8 mr-2">
          <BiImport className="text-xl" />
        </span>
        <span className="text-sm">Import Fields</span>
      </div>
      <CustomTooltip
        helpers={{
          id: "importFields-btn",
          content:
            "Select an existing document and import its fields in this document.",
          place: "left",
        }}
      />
      {/* <div
        className="flex items-center  hover:bg-gray-100 px-3 py-[2px]"
        onClick={() => setSelectedSetting("renewal")}
      >
        <span className="inline-flex justify-center items-center w-8 h-8 mr-2">
          <SlCalender />
        </span>
        <span className="text-sm">Renewal</span>
      </div> */}
    </div>
  );
};

export default SettingsMenu;
