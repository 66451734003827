import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { GOOGLE_CLIENT_ID_SIGN_IN } from "../../constants/constants";
import { isCollaboratorInWorkspace } from "../../utils";
const GoogleOneTapSignIn = () => {
  const [owner, setOwner] = useState<any>({});

  useEffect(() => {
    const checkOwner = async () => {
      let storedOwner = JSON.parse(localStorage.getItem("owner") ?? "null");
      if (!storedOwner) {
        googleOneTap();
      }
      setOwner(storedOwner);
    };

    checkOwner();
  }, []);

  const handleCredentialResponse = async (response: any) => {
    try {
      const { data } = await axios.post(`/owner/createOwnerGoogle`, {
        googleCredential: response.credential,
      });

      if (data?.success) {
        localStorage.setItem("owner", JSON.stringify(data?.owner));
        localStorage.setItem("x-auth-token", data?.["x-auth-token"]);
        localStorage.setItem("workspaceid", data?.owner?.currentWorkSpaceId);
        localStorage.setItem("currentUserEmail", data?.owner?.email);
        localStorage.setItem("accountType", "owner");
        let localWorkspaceId = localStorage.getItem("collaboratorWorkspaceId");
        if (localWorkspaceId) {
          localStorage.setItem("workspaceid", localWorkspaceId);
          const isInWorkspace = isCollaboratorInWorkspace(data?.owner?.email);
          if (!isInWorkspace) {
            localStorage.setItem("workspaceid", data.owner?.currentWorkSpaceId);
          }

          // Clean the local workspace after login
          localStorage.removeItem("collaboratorWorkspaceId");
        }
        window.open("/dashboard/forms", "_self"); // go to forms page after successful login
      }
    } catch (e: any) {
      console.error("Error during Google One Tap sign-in:", e);
    }
  };

  const googleOneTap = () => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID_SIGN_IN,
        callback: handleCredentialResponse,
        auto_select: true,
      });
      window.google.accounts.id.prompt((notification: any) => {
        console.log(notification);
      });
    };
    script.async = true;
    document.querySelector("body")?.appendChild(script);
  };

  return <></>;
};

export default GoogleOneTapSignIn;
