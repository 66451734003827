import { toast } from "react-toastify";
import { CONTACT_INFO } from "./embed";

async function postMessageParser(data: any) {
  // console.log("postMessageParser", data);
  let msgType = data?.type || "DEFAULT";
  let msgData = data?.data || undefined;

  //   if (!msgData) {
  //     console.log({
  //       postMessageParser: 1,
  //       msg: "Msg data not undefined",
  //       data,
  //     });
  //   }

  switch (msgType) {
    case "EMBED_CONTACTS":
      let res = await CONTACT_INFO.checkAndSet(msgData);
      if (res.error) {
        toast.error(res.error || "Could not set embed contacts");
      }
      break;
    default:
      //   console.log({
      //     postMessageParser: 1,
      //     msg: "Msg type case not handled",
      //     data,
      //   });
      break;
  }
}

export { postMessageParser };
