import React, { useEffect, useState } from "react";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import { BoloButton } from "../Common/Button/BoloButton";
import BoloModal from "../Common/Modal/BoloModal";

const IpTrackCheck = () => {
  const [access, setAccess] = useState(true);

  function ifEnoughTimePassed(): boolean {
    const currentPage: string = window.location.pathname;
    const currentTime = new Date().getTime();

    let blockData = JSON.parse(localStorage.getItem("ipBlockTimes") || "{}");
    console.log("pre", blockData);

    // Check if there is a previous visit record for this page
    if (blockData[currentPage]) {
      const timeDiff =
        (currentTime - blockData?.[currentPage]?.timestamp) / 1000;

      console.log(`Time difference for ${currentPage}: ${timeDiff} seconds`);
      // 2hrs
      if (timeDiff < 60 * 60 * 2) return false;
    }

    blockData[currentPage] = {
      timestamp: currentTime,
    };
    console.log(blockData);
    localStorage.setItem("ipBlockTimes", JSON.stringify(blockData));
    return true;
  }

  const checkAccess = async () => {
    try {
      const ipResponse = getIpAddressLocal();
      const { ip } = await ipResponse;
      if (!ip) {
        setAccess(false);
      }
    } catch (error) {
      console.log("Could not fetch ip");
      // if (ifEnoughTimePassed()) {
      setAccess(false);
      // }
    }
  };

  useEffect(() => {
    checkAccess();
  }, []);

  return (
    <>
      {access ? null : (
        <BoloModal
          isNoCross={true}
          content={
            <>
              <div className="text-base md:text-lg mb-4 font-semibold ">
                Could not fetch IP
              </div>

              <div className="gap-2  items-center justify-between text-sm text-gray-600">
                <p>
                  For Security purposes we track IP of the devices that use our
                  platform
                </p>
                <p>
                  If you are using a VPN/AdBlocker disable it for proper
                  functioning of the Website
                </p>
              </div>

              <div className="flex gap-2 items-center justify-end mt-8">
                <BoloButton
                  variant={"danger-border"}
                  size={"sm"}
                  onClick={() => {
                    setAccess(true);
                  }}
                >
                  Proceed
                </BoloButton>
              </div>
            </>
          }
          setShow={setAccess}
        />
      )}
    </>
  );
};

export default IpTrackCheck;
