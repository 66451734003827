//@ts-nocheck
import { useState, useRef } from "react";
import { MdClose } from "react-icons/md";
import imageCompression from "browser-image-compression";
import axios from "../../utils/axios";
import { fileToBase64 } from "../../utils/FileDataToBase64";
import { TailSpin } from "react-loader-spinner";
import UploadTabImage from "../../assets/images/headerImageUpload.png";
import Cropper from "react-easy-crop";
import { useAppSelector } from "../../redux/store";
import { COLORS } from "../../constants/common";

const CompanyImageModal = ({
  setShow,
  setAccountInfo,
  accountInfo,
  accountKey,
  text,
  setDeleteLogo,
  setDeleteWaterMark,
}) => {
  const imageInputRef = useRef(null);
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );

  const [active, setActive] = useState({
    tab: "upload",
  });

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [originalName, setOriginalName] = useState("");

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleBrowseBtnClick = () => {
    imageInputRef.current.click();
  };

  const handleDrop = async (e) => {
    e.preventDefault();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      let imageDataUrl = await readFile(file);

      setUploadedImage(imageDataUrl);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file && file.size > 5 * 1024 * 1024) {
        toast.error("File should be no larger than 5mb");
        return;
      }
      setOriginalName(file?.name);
      let imageDataUrl = await readFile(file);

      setUploadedImage(imageDataUrl);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.fillStyle = "#FFFFFF";
    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // As Base64 string
    return croppedCanvas.toDataURL("image/png");

    // As a blob
    // return new Promise((resolve, reject) => {
    //     croppedCanvas.toBlob((file) => {
    //         resolve(URL.createObjectURL(file))
    //     }, 'image/jpeg')
    // })
  }

  const handleImageUpload = async (selectedImage) => {
    if (selectedImage) {
      setIsUploadingImage(true);
      try {
        const croppedImage = await getCroppedImg(
          uploadedImage,
          croppedAreaPixels
        );
        const compressedImage = await compressImage(croppedImage);
        const file = new File(
          [compressedImage],
          originalName || "Compnay Logo.png",
          {
            type: compressedImage.type,
          }
        );
        setAccountInfo({ ...accountInfo, [accountKey || "orgLogo"]: file });
        if (accountKey == "orgLogo") {
          setDeleteLogo(false);
        }
        if (accountKey == "defaultWaterMark") {
          setDeleteLogo(false);
        }
        // const base64Image = await fileToBase64(compressedImage);
        // const imageUrl = await uploadImageToBackend(base64Image);

        setIsUploadingImage(false);

        setShow(false);
      } catch (error) {
        setIsUploadingImage(false);
        console.error("Error handling file change:", error);
      }
    }
  };

  const base64toBlob = (base64, type = "application/octet-stream") => {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime || type });
  };

  const compressImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1200,
        useWebWorker: true,
      };

      const blob = base64toBlob(file);
      const compressedBlob = await imageCompression(blob, options);
      return compressedBlob;
    } catch (error) {
      console.log("Error compressing image: ", error);
    }
  };

  return (
    <div className="px-3 md:px-[38px] pt-[40px] fixed inset-0 bg-white bg-opacity-40 flex items-center justify-center z-50">
      <div className="w-[700px] h-[80vh] bg-white shadow-xl rounded-xl">
        <div className="px-[20px] py-6 flex justify-between">
          <h2 className="text-lg md:text-[22px]">
            Select {text || "Company Logo"}
          </h2>
          <span
            onClick={() => {
              setShow(false);
            }}
            className="w-[30px] h-[30px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
          >
            <MdClose className="text-2xl text-gray-600" />
          </span>
        </div>
        <div className="h-[45px] flex border-b pl-[8px]">
          <div
            onClick={() => setActive((prev) => ({ ...prev, tab: "upload" }))}
            style={{}}
            onMouseOut={(e) => (e.currentTarget.style.background = "initial")}
            className={` w-[84px] relative h-full text-sm font-medium flex items-center justify-center cursor-pointer`}
          >
            Upload
            {active.tab === "upload" && (
              <div
                style={{
                  background:
                    whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
                    COLORS.primary,
                }}
                className="absolute bottom-0 w-[100%] h-[2px] rounded-xl"
              ></div>
            )}
          </div>
        </div>
        <div className="px-5 h-[calc(80vh-130px)]">
          {active.tab === "upload" &&
            (isUploadingImage ? (
              <div className="flex h-full items-center justify-center">
                <TailSpin height="40" width="40" color="#1a73e8" />
              </div>
            ) : uploadedImage ? (
              <div className="h-full flex flex-col gap-y-5">
                <div className="relative h-[80%] mt-1">
                  <Cropper
                    image={uploadedImage}
                    aspect={1 / 1}
                    crop={crop}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    onCropAreaChange={setCroppedArea}
                    restrictPosition={false}
                  />
                </div>

                <div className="flex self-end gap-x-[10px]">
                  <button
                    onClick={() => {
                      setUploadedImage(null);
                    }}
                    className="text-[15px] text-[#595a5a] p-[6px] hover:bg-secondary font-medium rounded-sm"
                  >
                    CLEAR
                  </button>
                  <button
                    onClick={() => {
                      handleImageUpload(croppedArea);
                    }}
                    className="text-[15px] text-primary p-[6px] hover:bg-secondary font-medium rounded-sm"
                  >
                    DONE
                  </button>
                </div>
              </div>
            ) : (
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="flex flex-col h-full items-center justify-center gap-y-5"
              >
                {/* <p className="text-sm text-gray-400">Recommended image size: 1200x300 pixels</p> */}
                <img
                  className="max-w-[300px]"
                  src={UploadTabImage}
                  alt="Upload"
                />
                <div className="flex flex-col items-center justify-center gap-y-1">
                  <button
                    className="text-primary w-full bg-secondary px-4 py-1.5 rounded-md flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
                    onClick={handleBrowseBtnClick}
                  >
                    Browse
                  </button>
                  <p className="text-gray-500">or drag a file here</p>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={imageInputRef}
                  onChange={handleImageChange}
                  multiple={false}
                />
              </div>
            ))}
          {/* {active.tab === "photos" && (
                        <div className="flex h-full items-center justify-center">
                        </div>
                    )} */}
        </div>
      </div>
    </div>
  );
};

export default CompanyImageModal;

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}
