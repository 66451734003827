import React, { useEffect, useState } from "react";
import { BoloButton } from "../Common/Button/BoloButton";
import { toast, ToastContainer, Slide } from "react-toastify";
import axios from "../../utils/axios";
import { getInitials, getTintFromHex } from "../../utils/uiUtils";
import { sortAndGroupBySigningOrderNo } from "../SigningOrderDiagram";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import { FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { getWhiteLabelInfoLocal } from "../../utils";
import { COLORS } from "../../constants/common";
import {
  getEmbedHome,
  isEmbeded,
  getIfIsInChromeExtensionIframe,
} from "../../utils/embed";

interface SigningInPersonProps {
  isSigningOrderData: boolean;
  setShowSigningInPerson: React.Dispatch<React.SetStateAction<boolean>>;
  documentId: string;
  generatedLink?: any;
  type: string;
  sending: boolean;
}

const SigningInPerson = ({
  setShowSigningInPerson,
  isSigningOrderData,
  documentId,
  generatedLink,
  type,
  sending,
}: SigningInPersonProps) => {
  // const whiteLabelInfo = useAppSelector(
  //   (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  // );
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const [primaryColor, setPrimaryColor] = useState(
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary
  );
  const [generatedLinks, setGeneratedLinks] = useState<any>([]);
  const [selectedUserIndex, setSelectedUserIndex] = useState<number>(0);
  const [allSigned, setAllSigned] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const getGeneratedLinks = async (documentId: string) => {
    try {
      let { data } = await axios.get(
        `/getOneDocument?documentId=${documentId}`
      );
      let respondents = data?.document?.respondants;
      if (respondents.length > 0) {
        let newGenLinks = sortAndGroupBySigningOrderNo(respondents).flat();
        setGeneratedLinks(newGenLinks);
      }
    } catch (err) {
      toast.error("Error in generating Links!!");
      console.error(err);
    }
  };

  const updateLinks = () => {
    let interval = 5000;
    let duration = 30000;
    let continueInterval = true;

    const updateLinkscall = async () => {
      try {
        let query = `/document/getCurrentSigner?documentId=${documentId}&type=${type}`;

        if (type === "PDF-TEMPLATE" && generatedLink[0]?.signerId) {
          query += `&signerId=${generatedLink[0]?.signerId}`;
        }
        const { data } = await axios.get(query);
        if (data?.signers.length > 0) {
          let newGenLinks = sortAndGroupBySigningOrderNo(data?.signers).flat();
          setGeneratedLinks(newGenLinks);
        }
        if (data?.notSignedNumber === 0) {
          setAllSigned(true);
          continueInterval = false;
        }
      } catch (error) {
        toast.error("Error in generating Links!!");
        console.error(error);
      }
    };
    const intervalId = setInterval(() => {
      if (continueInterval) {
        updateLinkscall();
      }
    }, interval);

    setTimeout(() => {
      clearInterval(intervalId);
    }, duration);
  };

  const confirmFunction = async () => {
    try {
      let selectedUser = generatedLinks[selectedUserIndex];
      let query = `/document/getCurrentSigner?documentId=${documentId}&type=${type}`;

      if (type === "PDF-TEMPLATE" && generatedLink[0]?.signerId) {
        query += `&signerId=${generatedLink[0]?.signerId}`;
      }
      const { data } = await axios.get(query);
      if (data?.signers) {
        let newGenLinks = sortAndGroupBySigningOrderNo(data?.signers).flat();
        setGeneratedLinks(newGenLinks);
        selectedUser = newGenLinks[selectedUserIndex];
      }

      // if (data?.notSignedNumber === 1) {
      updateLinks();
      // }
      if (isSigningOrderData) {
        // Check if the current signer is less than the selected index
        if (data?.currentSigner < selectedIndex) {
          toast.error("Previous Signers have not signed yet!!");
          return;
        }
      }
      window.open(selectedUser?.sentUrl + "&SigningInPerson=1", "_blank");
    } catch (error) {
      console.error("Error fetching current signer:", error);
    }
  };

  const handleRedirect = () => {
    if (!sending) {
      setShowSigningInPerson(false);
      return;
    }
    if (isEmbeded()) {
      if (getIfIsInChromeExtensionIframe()) {
        window?.top?.postMessage({ action: "closeModal" }, "*");
      }
      window.location.href = getEmbedHome();
    }
    if (type == "PDF") {
      window.location.href = `/dashboard/pdfs?page=1`;
    } else if (type == "PDF-TEMPLATE") {
      window.location.href = `/dashboard/templates?page=1`;
    }
  };

  useEffect(() => {
    if (type === "PDF") {
      getGeneratedLinks(documentId);
    }
    if (type === "PDF-TEMPLATE" && generatedLink.length > 0) {
      setGeneratedLinks(sortAndGroupBySigningOrderNo(generatedLink).flat());
    }
  }, [type, documentId]);

  useEffect(() => {
    setPrimaryColor(
      whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary
    );
  }, []);

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[630px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={handleRedirect}
          />
        </div>
        <div>
          <div className="text-base md:text-lg mb-4 font-semibold">
            In-person signing
          </div>
          <div className="gap-2 items-center justify-between text-xs">
            {isSigningOrderData ? (
              <p>
                This document has a specific signing order. Select a recipient
                to begin in-person signing.
              </p>
            ) : (
              <p>
                Please confirm the recipient who will sign your document, then
                pass the device for signature.
              </p>
            )}
          </div>
          <div className=" flex flex-col gap-2 mt-6 sm:mt-8">
            {generatedLinks?.map((ele: any, index: number) => {
              const dataMap: any = {
                "PDF-TEMPLATE": {
                  color: ele?.roleColour,
                  title: ele?.roleTitle,
                  email: ele?.email,
                  name: ele?.name,
                  signingOrderNo: ele?.signingOrderNo,
                },
                PDF: {
                  color: ele?.colour,
                  title: ele?.name,
                  email: ele?.email,
                  signingOrderNo: ele?.signingOrderNo,
                },
              };
              const data = dataMap[type];

              return (
                <div className="w-full" key={index}>
                  <label
                    htmlFor={`${index}`}
                    className={`cursor-pointer flex justify-between w-full`}
                  >
                    <div className="group flex gap-3 items-center h-14 justify-between cursor-pointer   min-h-[56px]">
                      <div className="flex gap-2 items-center">
                        {isSigningOrderData && (
                          <div className="mr-2 font-medium min-w-[10px]">
                            {data?.signingOrderNo}
                          </div>
                        )}
                        <div
                          className="h-10 w-10 min-w-8 min-h-8 rounded-full flex items-center justify-center text-gray-600"
                          style={{
                            background: getTintFromHex(
                              data?.color || primaryColor,
                              85
                            ),
                            border: `1px solid ${data?.color || primaryColor}`,
                          }}
                        >
                          {getInitials(data?.name || data?.email) || ""}
                        </div>
                        <div className="flex flex-col">
                          <div className="whitespace-nowrap font-medium text-sm sm:text-base">
                            {data?.title || data?.email}
                          </div>
                          {data?.email && (
                            <div className="whitespace-nowrap overflow-hidden text-[10px] leading-[17px] text-xs sm:text-sm text-gray-600">
                              {data?.email}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center ">
                      {ele?.status !== "SIGNED" ? (
                        <RadioGroup
                          key={index}
                          name="radio-buttons"
                          value={selectedUserIndex}
                        >
                          <FormControlLabel
                            value={index}
                            control={
                              <Radio
                                sx={{
                                  color: primaryColor,
                                  "&.Mui-checked": {
                                    color: primaryColor,
                                  },
                                }}
                                id={`${index}`}
                                checked={selectedUserIndex === index}
                              />
                            }
                            label=""
                            onChange={() => {
                              setSelectedUserIndex(index);
                              setSelectedIndex(data?.signingOrderNo);
                            }}
                          />
                        </RadioGroup>
                      ) : (
                        <span className="text-xl">
                          <FaRegCircleCheck className="text-primary mr-[26px]" />
                        </span>
                      )}
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
          <div className="flex gap-2 items-center justify-end mt-8">
            {!allSigned ? (
              <>
                <BoloButton
                  variant={"secondary"}
                  size={"sm"}
                  onClick={handleRedirect}
                >
                  Cancel
                </BoloButton>
                <BoloButton
                  variant={"primary"}
                  size={"sm"}
                  onClick={confirmFunction}
                >
                  Confirm
                </BoloButton>
              </>
            ) : (
              <BoloButton
                variant={"primary"}
                size={"sm"}
                onClick={handleRedirect}
              >
                Done
              </BoloButton>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default SigningInPerson;
