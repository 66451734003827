import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { BASE_URL } from "../../constants/constants";
import {
  BASE_INITIAL_STATE,
  parseQueryParams,
  BaseInitialStateType,
} from "../reduxConstants";
import { dbHsl2Hex } from "../../utils/uiUtils";
// import psl from "psl";
import { delay } from "../../utils";
import { pslAltParse } from "../../constants/whiteLabel";

// AY Update here
const LOCATION = window.location.hostname;
// const LOCATION = "bolosign.com";

export interface BoloUser {
  _id?: string;
  name?: String;
  email?: String;
  from?: String;
  planhistory?: any;
  accountInfo?: any;
  stripeCustomerId?: string;
}
export type SiteReviewType = {
  image: string;
  name: string;
  stars: number;
  title: string;
  content: string;
};

type WhiteLabelThemeType = {
  primary?: string;
  secondary?: string;
  tertiary?: string;
  textPrimary?: string;
  textSecondary?: string;
  textTertiary?: string;
};

export type WhiteLabelOwnerType = {
  _id?: string;
  name?: string;
  email?: string;
  domain?: string;
  settings?: {
    organization?: {
      name?: string;
      logo?: string;
      mailingEmail?: string;
      domain?: string;
      signedBy?: string;
      pricingContactEmail?: string;
      favicon?: string;
      theme?: WhiteLabelThemeType;
      themeHex?: WhiteLabelThemeType;
      links?: {
        home?: string;
        fill?: string;
        help?: string;
        featureRequest?: string;
      };
      policy: {
        data?: string;
        tos?: string;
        privacy?: string;
        security?: string;
      };
      reviews?: SiteReviewType[];
      firebase?: {
        // why here full config and not in backend
        // because ts gives error
        // and i dont want to restrict the backend based on schema
        config?: {
          apiKey?: string;
          authDomain?: string;
          projectId?: string;
          storageBucket?: string;
          messagingSenderId?: string;
          appId?: string;
          measurementId?: string;
        };
      }; // Adjust type as needed
      // integrations?: {
      //   zapier?: { learnMoreLink?: string; connectionLink?: string };
      //   make?: { learnMoreLink?: string; connectionLink?: string };
      //   pabbly?: { learnMoreLink?: string; connectionLink?: string };
      //   activepieces?: { learnMoreLink?: string; connectionLink?: string };
      // };
      // tokenIntegrations: {
      //   pipedrive: {
      //     clientId: string;
      //   };
      // };
      sessionExpiry: string;
    };
  };
};

export const getWhiteLabelInfoThunk = createAsyncThunk(
  "getWhiteLabel",
  async (data?: {}) => {
    try {
      // let parsedLocation_ = psl?.parse(window.location.hostname);
      // let parsedLocation_ = psl?.parse("bolosign.com");
      // let parsedLocation_ = psl?.parse(LOCATION);
      // let parsedLocation = !parsedLocation_?.error
      //   ? parsedLocation_?.domain
      //   : "";
      let parsedLocation = pslAltParse(LOCATION);
      // let url = `${BASE_URL}/whitelable?whiteLabelOwnerDomain=${"dash-signature-staging.boloforms.com"}`;
      let url = `${BASE_URL}/whitelable?whiteLabelOwnerDomain=${parsedLocation}`;
      const res = await axios.get(url);
      return res.data;
    } catch (error: any) {
      return error?.response?.data;
    }
  }
);

export const updateWhiteLabelInfoThunk = createAsyncThunk(
  "updateWhiteLabel",
  async (data: { updatedOwner: WhiteLabelOwnerType }) => {
    try {
      let url = `${BASE_URL}/whitelable`;
      const res = await axios.post(url, data);
      return res.data;
    } catch (error: any) {
      return error?.response?.data;
    }
  }
);

export const whiteLabelCredsCheckThunk = createAsyncThunk(
  "checkCredsWhitelabel",
  async () => {
    // AY CHANGE THIS LATER TO 2000
    await delay(2000);
    try {
      let url = `${BASE_URL}/whitelable/creds_check`;
      const res = await axios.get(url);
      return res.data;
    } catch (error: any) {
      return error?.response?.data;
    }
  }
);

// helpers -------------------------------------------

export const getBoloUserInfoThunk = createAsyncThunk(
  "getBoloUserInfo",
  async (data: {
    searchQuery: string;
    limit: number;
    page: number;
    filters?: { key: string; value: string }[];
  }) => {
    try {
      let url = `${BASE_URL}/whitelable/helper/owners`;
      const res = await axios.post(url, data);
      return res.data;
    } catch (error: any) {
      return error?.response?.data;
    }
  }
);

export type WhiteLabelInitialStateType = {
  checkCredsLoading: boolean;
  updateWhiteLabelLoading: boolean;
  data: {
    whiteLabelOwner: WhiteLabelOwnerType;
    whiteLabelPartner: WhiteLabelOwnerType;
    helpers: {
      owners: BoloUser[];
      numPages: number;
      currPage: number;
      selectedUser: BoloUser;
      searchQuery: string;
      searchFilters: { key: string; value: string }[];
    };
  };
} & BaseInitialStateType;

const initialState: WhiteLabelInitialStateType = {
  ...BASE_INITIAL_STATE,
  checkCredsLoading: false,
  updateWhiteLabelLoading: false,
  data: {
    whiteLabelOwner: {},
    whiteLabelPartner: {},
    helpers: {
      owners: [],
      numPages: 0,
      currPage: 0,
      selectedUser: {},
      searchQuery: "",
      searchFilters: [{ key: "userPlanType", value: "" }],
    },
  },
};

const whiteLabelSlice = createSlice({
  name: "whiteLable",
  initialState: initialState,
  reducers: {
    setSelectedBoloUser: (state, action) => {
      state.data.helpers.selectedUser = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.data.helpers.searchQuery = action.payload;
    },
    setCurrPage: (state, action) => {
      state.data.helpers.currPage = action.payload;
    },
    addSearchFilter: (state, action) => {
      state.data.helpers.searchFilters = [
        ...state.data.helpers.searchFilters,
        action.payload || { key: "userPlanType", value: "" },
      ];
    },
    setFilter: (state, action) => {
      let filters: { key: string; value: string }[] = [
        ...state.data.helpers.searchFilters,
      ];
      let fIdx = filters.findIndex((ele) => ele.key === action.payload.key);
      if (fIdx !== -1) {
        filters[fIdx].value = action.payload.value;
        state.data.helpers.searchFilters = filters;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      //getWhiteLabelInfoThunk ---------------------------------------------------------------------
      .addCase(getWhiteLabelInfoThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getWhiteLabelInfoThunk.fulfilled, (state, { payload }) => {
        if (!payload?.success) {
          localStorage.removeItem("whiteLabelOwner");
          localStorage.removeItem("whitelabelowner_header");
        }
        if (payload?.success) {
          state.data.whiteLabelOwner =
            payload?.whiteLabelOwner || state?.data?.whiteLabelOwner;

          // creating hex values ------------------------------
          let data = {
            primary:
              dbHsl2Hex(
                state.data.whiteLabelOwner.settings?.organization?.theme
                  ?.primary
              ) || "",
            secondary:
              dbHsl2Hex(
                state.data.whiteLabelOwner.settings?.organization?.theme
                  ?.secondary
              ) || "",
            tertiary:
              dbHsl2Hex(
                state.data.whiteLabelOwner.settings?.organization?.theme
                  ?.tertiary
              ) || "",
            textPrimary:
              dbHsl2Hex(
                state.data.whiteLabelOwner.settings?.organization?.theme
                  ?.textPrimary
              ) || "",
            textSecondary:
              dbHsl2Hex(
                state.data.whiteLabelOwner.settings?.organization?.theme
                  ?.textSecondary
              ) || "",
            textTertiary:
              dbHsl2Hex(
                state.data.whiteLabelOwner.settings?.organization?.theme
                  ?.textTertiary
              ) || "",
          };
          if (state?.data?.whiteLabelOwner?.settings?.organization) {
            state.data.whiteLabelOwner.settings.organization.themeHex = data;
          }
          // creating hex values ------------------------------

          // localStorage.setItem(
          //   "whitelabelowner_header",
          //   payload?.whiteLabelOwner?.email
          // );
          // let parsedLocation_ = psl?.parse(LOCATION);
          // let parsedLocation = !parsedLocation_?.error
          //   ? parsedLocation_?.domain
          //   : "";
          let parsedLocation = pslAltParse(LOCATION);
          localStorage.setItem(
            "whitelabelowner_header",
            // window.location.hostname
            // "bolosign.com"
            parsedLocation || ""
          );
          localStorage.setItem(
            "whiteLabelOwner",
            JSON.stringify({ ...state.data.whiteLabelOwner })
          );
        }
        state.loading = false;
      })
      .addCase(getWhiteLabelInfoThunk.rejected, (state, { payload }) => {
        state.loading = false;
      })
      //Creds check ---------------------------------------------------------------------
      .addCase(whiteLabelCredsCheckThunk.pending, (state, action) => {
        state.checkCredsLoading = true;
      })
      .addCase(whiteLabelCredsCheckThunk.fulfilled, (state, { payload }) => {
        if (!payload?.success) {
          localStorage.removeItem("whiteLabelPartner");
        }
        if (payload?.success) {
          state.data.whiteLabelPartner =
            payload?.whiteLabelPartner || state?.data?.whiteLabelPartner;

          // creating hex values ------------------------------
          let data = {
            primary:
              dbHsl2Hex(
                state.data.whiteLabelPartner.settings?.organization?.theme
                  ?.primary
              ) || "",
            secondary:
              dbHsl2Hex(
                state.data.whiteLabelPartner.settings?.organization?.theme
                  ?.secondary
              ) || "",
            tertiary:
              dbHsl2Hex(
                state.data.whiteLabelPartner.settings?.organization?.theme
                  ?.tertiary
              ) || "",
            textPrimary:
              dbHsl2Hex(
                state.data.whiteLabelPartner.settings?.organization?.theme
                  ?.textPrimary
              ) || "",
            textSecondary:
              dbHsl2Hex(
                state.data.whiteLabelPartner.settings?.organization?.theme
                  ?.textSecondary
              ) || "",
            textTertiary:
              dbHsl2Hex(
                state.data.whiteLabelPartner.settings?.organization?.theme
                  ?.textTertiary
              ) || "",
          };
          if (state?.data?.whiteLabelPartner?.settings?.organization) {
            state.data.whiteLabelPartner.settings.organization.themeHex = data;
          }
          // creating hex values ------------------------------
          localStorage.setItem(
            "whiteLabelPartner",
            JSON.stringify({ ...state.data.whiteLabelPartner })
          );
        }
        state.checkCredsLoading = false;
      })
      .addCase(whiteLabelCredsCheckThunk.rejected, (state, { payload }) => {
        state.checkCredsLoading = false;
      })
      // get bolo users ---------------------------------------------------------------------
      .addCase(getBoloUserInfoThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBoloUserInfoThunk.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.data.helpers.owners = payload?.owners || [];
          state.data.helpers.numPages = payload?.numPages || 0;
          if (payload?.owners?.length === 1) {
            state.data.helpers.selectedUser = payload?.owners?.[0] || {};
          }
        }
        state.loading = false;
      })
      .addCase(getBoloUserInfoThunk.rejected, (state, { payload }) => {
        state.loading = false;
      })
      // update whitelabel info ---------------------------------------------------------------------
      .addCase(updateWhiteLabelInfoThunk.pending, (state, action) => {
        state.updateWhiteLabelLoading = true;
      })
      .addCase(updateWhiteLabelInfoThunk.fulfilled, (state, { payload }) => {
        state.updateWhiteLabelLoading = false;
      })
      .addCase(updateWhiteLabelInfoThunk.rejected, (state, { payload }) => {
        state.updateWhiteLabelLoading = false;
      });
  },
});

export default whiteLabelSlice.reducer;
export const {
  setSelectedBoloUser,
  setSearchQuery,
  setCurrPage,
  addSearchFilter,
  setFilter,
} = whiteLabelSlice.actions;
