import Checkbox from "@mui/material/Checkbox";
import { alpha, styled } from "@mui/system";
import { COLORS } from "../../../constants/common";
import { getWhiteLabelInfoLocal } from "../../../utils";

const whiteLabelInfo = getWhiteLabelInfoLocal();

const BoloCheckboxMui = styled(Checkbox)(
  ({ variant }: { variant?: "default" | "bolo-primary" }) => ({
    "& .MuiSvgIcon-root": {
      color:
        variant == "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
      "&:hover": {
        backgroundColor: alpha(
          variant == "bolo-primary"
            ? COLORS.primary
            : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
                COLORS.primary,
          0.04
        ),
      },
    },
    "& .MuiTouchRipple-root ": {
      color:
        variant == "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
    },
    "&.Mui-disabled .MuiSvgIcon-root, & .MuiSvgIcon-root:disabled": {
      color: alpha(
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
              COLORS.primary,
        0.5 // Adjust the opacity to your liking
      ),
    },
    "&.Mui-disabled .MuiTouchRipple-root, & .MuiTouchRipple-root:disabled": {
      color: alpha(
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
              COLORS.primary,
        0.5 // Adjust the opacity to your liking
      ),
    },
  })
);

export default BoloCheckboxMui;
