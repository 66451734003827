// !Note : this file is used in editor dont use any redux hooks here
import { GrClose } from "react-icons/gr";
import { FaAngleDown } from "react-icons/fa6";
import { initialContactData } from "../../pages/Contacts";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  getContactDetails,
  getWorkSpaceDetails,
  setContactDetails,
} from "../../utils";
import { convertToTitleCase } from "../../utils/contacts";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
interface ContactModalProps {
  isOpen: boolean;
  isCreate: boolean;
  setIsOpen: any;
  formData: any;
  setFormData: any;
  workSpaceContacts?: any;
  handleSaveCallback?(contactInfo: any): void;
}
const ContactModal: React.FC<ContactModalProps> = ({
  isOpen,
  isCreate,
  setIsOpen,
  formData,
  setFormData,
  workSpaceContacts,
  handleSaveCallback,
}) => {
  const titles = ["Create a new contact", "Edit existing contact"];
  const [workSpaceContacts_, setWorkSpaceContacts_] = useState(
    workSpaceContacts || []
  );
  const [isExpanded, setIsExpanded] = useState({
    address: false,
    customFields: false,
  });

  const inputStyles =
    "py-2 w-full h-9 border px-3 rounded-md border-gray-300 focus:outline-none text-gray-700 focus:border-b focus:border-primary placeholder:font-light placeholder:text-gray-500 placeholder:text-xs text-xs";
  const labelStyles =
    "block text-[12px] text-gray-900 uppercase font-semibold mt-1 mb-1";

  const handleInputChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCustomInputChange = (e: any) => {
    setFieldsForm({
      ...fieldsForm,
      [e.target.name]: e.target.value,
    });
  };
  const addContact = async () => {
    try {
      // check if email is valid
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData?.email)) {
        toast("Enter a valid email address", {
          type: "error",
        });
        return true;
      }
      // Check email aready exits or not
      if (
        workSpaceContacts_.find(
          (contact: any) => contact.email === formData?.email
        )
      ) {
        toast("Email already exists", {
          type: "error",
        });
      }
      formData = {
        ...formData,
        customFields: fieldsForm,
      };
      const response = await axios.post("/workspace/addContact", formData);
      const data = response?.data;
      if (data) {
        if (!data.success) {
          toast.error("Something went wrong");
          return;
        }
        toast("Contact added successfully!", {
          type: "success",
        });
        setFormData(initialContactData);
        setIsOpen(false);
      }
    } catch (error) {
      console.log("Error updating contact.");
    }
  };
  const updateContact = async () => {
    try {
      // check if email is valid
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData?.email)) {
        toast("Enter a valid email address", {
          type: "error",
        });
        return true;
      }
      formData = {
        ...formData,
        customFields: fieldsForm,
      };
      const response = await axios.put("/workspace/updateContact", formData);
      const data = response?.data;
      if (data) {
        if (!data.success) {
          // Display an error toast when a 400 status code is received
          if (response?.status === 201) {
            toast.error("This email already exists.");
          } else {
            toast.error("Something went wrong");
          }
          return;
        }
        toast("Contact updated successfully!", {
          type: "success",
        });
        handleSaveCallback && handleSaveCallback(formData);
        setContactDetails(data?.contacts);
        setFormData(initialContactData);
        setIsOpen(false);
      }
    } catch (error) {
      console.log("Error updating contact", error);
    }
  };
  const [fieldsList, setFieldsList] = useState([]);
  const [fieldsForm, setFieldsForm] = useState<any>({});
  useEffect(() => {
    const initilizeFieldsForm = (fieldsData: any) => {
      let customFieldsObject: any = {};

      // Iterate through the customFields array and set the keys and values
      fieldsData.forEach((field: any) => {
        customFieldsObject[field.field_name] =
          (formData?.customFields &&
            formData?.customFields[field.field_name]) ||
          null;
      });
      setFieldsForm(customFieldsObject);
    };
    const fetchFields = async () => {
      try {
        const response = await axios.get("/workspace/getCustomFields");
        const data = response?.data;
        const fieldsData = data?.fields?.customFields || [];
        setFieldsList(fieldsData);
        initilizeFieldsForm(fieldsData);
      } catch (error) {
        console.log("Error fetching custom contact fields", error);
      }
    };
    fetchFields();
  }, [formData.email, formData?.customFields]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isCreate) {
      addContact();
    } else {
      updateContact();
    }
  };

  useEffect(() => {
    if (!workSpaceContacts) {
      const contacts = getContactDetails();
      setWorkSpaceContacts_(contacts.contacts || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const workSpaceDetails = getWorkSpaceDetails();
  return (
    <>
      <div className="flex fixed top-0 left-0 z-[51] w-full h-full p-2 md:p-6 bg-[#0000004c] justify-center items-start overflow-y-auto ">
        <div
          className="bg-white  md:rounded-xl rounded-sm max-w-2xl w-full max-h-full overflow-y-auto relative"
          style={{ scrollbarWidth: "thin" }}
        >
          <div className="flex justify-between items-center  md:py-4 py-4 md:px-8 px-6 bg-gray-50 rounded-sm md:rounded-t-xl border-b sticky top-0 z-10">
            <h2 className=" text-[18px] md:text-xl font-medium ">
              {isCreate ? titles[0] : titles[1]}
            </h2>
            <GrClose
              className="text-xl hover:cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
          <form onSubmit={handleSubmit} className="py-4 md:px-8 px-6">
            {/* Basic Contact Details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4  ">
              <div className="mb-2 col-span-2 md:col-span-1">
                <label htmlFor="firstName" className={labelStyles}>
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData?.firstName}
                  onChange={handleInputChange}
                  className={inputStyles}
                  placeholder="Enter first name"
                />
              </div>
              <div className="mb-2 col-span-2 md:col-span-1">
                <label htmlFor="lastName" className={labelStyles}>
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData?.lastName}
                  onChange={handleInputChange}
                  className={inputStyles}
                  placeholder="Enter last name"
                />
              </div>
              <div className="mb-2 col-span-2 md:col-span-1">
                <label htmlFor="email" className={labelStyles}>
                  Email <span className="text-primary">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData?.email}
                  onChange={handleInputChange}
                  className={inputStyles}
                  required
                  placeholder="Enter email address"
                />
              </div>
              <div className="mb-2 col-span-2 md:col-span-1">
                <label htmlFor="phone" className={labelStyles}>
                  Phone
                </label>
                {/* <input
                type="text"
                id="phone"
                name="phone"
                value={formData?.phone}
                onChange={handleInputChange}
                className={inputStyles}
                placeholder="Enter a phone number"
              /> */}
                <div className="mt-[6px]">
                  <PhoneInput
                    value={formData?.phone}
                    onChange={(value, data, event, formattedValue) => {
                      // console.log("value", value);
                      // console.log("data", data);
                      // console.log("event", event);
                      // console.log("formattedValue", formattedValue);

                      setFormData({
                        ...formData,
                        phone: formattedValue,
                      });
                    }}
                    country={"in"}
                    enableSearch={true}
                    placeholder="Enter phone number"
                    inputStyle={{
                      width: "100%",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              </div>
              <div className="mb-2 col-span-2 md:col-span-1">
                <label htmlFor="company" className={labelStyles}>
                  Company
                </label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData?.company}
                  onChange={handleInputChange}
                  className={inputStyles}
                  placeholder="Enter a company name"
                />
              </div>
              <div className="mb-2 col-span-2 md:col-span-1">
                <label htmlFor="jobTitle" className={labelStyles}>
                  Job Title
                </label>
                <input
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  value={formData?.jobTitle}
                  onChange={handleInputChange}
                  className={inputStyles}
                  placeholder="Enter a job title"
                />
              </div>
            </div>
            <div
              className="mt-2 mb-4 flex justify-start items-center gap-2 text-primary hover:cursor-pointer"
              onClick={() => {
                setIsExpanded({ ...isExpanded, address: !isExpanded.address });
              }}
            >
              <h3 className=" font-medium relative after:content-[''] after:block after:mt-1 after:border-t-2 after:border-primary after:w-20">
                Address Details
              </h3>
              <span>
                <FaAngleDown
                  style={{
                    transform: isExpanded.address
                      ? "rotate(180deg) translateY(2px)"
                      : "",
                  }}
                />
              </span>
            </div>
            {/* Address Contact Details */}
            {isExpanded.address && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 ">
                <div className="mb-2 col-span-2">
                  <label htmlFor="streetAddress" className={labelStyles}>
                    Street Address
                  </label>
                  <input
                    type="text"
                    id="streetAddress"
                    name="streetAddress"
                    value={formData?.streetAddress}
                    onChange={handleInputChange}
                    className={inputStyles}
                    placeholder="Street, apt, suite, bldg"
                  />
                </div>
                <div className="mb-2 col-span-2 md:col-span-1">
                  <label htmlFor="city" className={labelStyles}>
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData?.city}
                    onChange={handleInputChange}
                    className={inputStyles}
                    placeholder="Enter a city"
                  />
                </div>
                <div className="mb-2 col-span-2 md:col-span-1">
                  <label htmlFor="postalCode" className={labelStyles}>
                    Postal Code
                  </label>
                  <input
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    value={formData?.postalCode}
                    onChange={handleInputChange}
                    className={inputStyles}
                    placeholder="Enter a postal code"
                  />
                </div>
                <div className="mb-2 col-span-2 md:col-span-1">
                  <label htmlFor="country" className={labelStyles}>
                    Country
                  </label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    value={formData?.country}
                    onChange={handleInputChange}
                    className={inputStyles}
                    placeholder="Enter a country"
                  />
                </div>
                <div className="mb-2 col-span-2 md:col-span-1">
                  <label htmlFor="state" className={labelStyles}>
                    State/Region
                  </label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={formData?.state}
                    onChange={handleInputChange}
                    className={inputStyles}
                    placeholder="Enter a state or region"
                  />
                </div>
              </div>
            )}
            <div
              className="mt-2 mb-4 flex justify-start items-center gap-2 text-primary hover:cursor-pointer"
              onClick={() => {
                setIsExpanded({
                  ...isExpanded,
                  customFields: !isExpanded.customFields,
                });
              }}
            >
              <h3 className=" font-medium relative after:content-[''] after:block after:mt-1 after:border-t-2 after:border-primary after:w-20">
                Custom Fields
              </h3>
              <span>
                <FaAngleDown
                  style={{
                    transform: isExpanded.customFields
                      ? "rotate(180deg) translateY(2px)"
                      : "",
                  }}
                />
              </span>
            </div>

            <div className="col-span-2 mt-4 mb-4 md:mb-0 flex justify-between items-center">
              {isExpanded.customFields && (
                <>
                  {fieldsList.length === 0 && (
                    <p className="mt-2 mb-8 text-sm text-gray-600 w-full text-center ">
                      No custom fields have been added yet.
                    </p>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 ">
                    {fieldsList.map((customField: any, index: number) => {
                      return (
                        <div
                          className="mb-2 col-span-2 md:col-span-1"
                          key={index}
                        >
                          <label
                            htmlFor={customField.field_name}
                            className={labelStyles}
                          >
                            {convertToTitleCase(customField.field_name)}
                          </label>
                          <input
                            type={customField.type}
                            id={customField.field_name}
                            name={customField.field_name}
                            value={fieldsForm[customField.field_name]}
                            onChange={handleCustomInputChange}
                            className={inputStyles}
                            placeholder={convertToTitleCase(
                              customField.field_name
                            )}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>

            <div className="col-span-2 mt-2 mb-4 md:mb-2 flex justify-between items-center">
              <button
                type="button"
                className=" bg-white px-4 rounded-md  text-xs border h-8 border-primary text-primary"
                onClick={() => {
                  setFormData(initialContactData);
                }}
              >
                Clear Form
              </button>
              <button
                type="submit"
                className=" bg-primary text-white px-4 rounded-md text-xs h-8"
                disabled={
                  !!workSpaceDetails?.currentWorkSpaceRole &&
                  workSpaceDetails?.currentWorkSpaceRole === "readonly"
                }
              >
                Save Contact
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactModal;
