import { useRef, useState, useEffect, useContext, useCallback } from "react";
// import { DesignerReactProps, Template, SchemaForUI, SchemaType } from '@pdfme/common';
import Sidebar from "./Sidebar";
import PdfTemplateSidebar from "./PdfTemplateSidebar";
import Main from "./Main/index";
import {
  ZOOM,
  SIZES,
  DEFAULT_DATA,
  STATIC_SCHEMA,
  getHeightFromFontSize,
  POSITION,
} from "../../constants";
import { I18nContext } from "../../contexts";
import { v4 } from "uuid";
import {
  uuid,
  set,
  cloneDeep,
  initShortCuts,
  destroyShortCuts,
  templateSchemas2SchemasList,
  fmtTemplate,
  getInitialSchema,
  getSampleByType,
  getKeepRatioHeightByWidth,
  getUniqSchemaKey,
  moveCommandToChangeSchemasArg,
  getPagesScrollTopByIndex,
  getStaticPosForBlankPage,
  validBlankPageSchema,
} from "../../helper";
import { useUIPreProcessor, useScrollPageCursor } from "../../hooks";
import Root from "../Root";
import Error from "../Error";
import CtlBar from "../CtlBar/index";
import {
  DesignerReactProps,
  Template,
  SchemaForUI,
  SchemaType,
} from "../../../../common/src";
import MiniPdfViewer from "./MiniPdfViewer";
import {
  DropdownOptionSchema,
  InstanceFromType,
  isTableSchema,
  PDFReceivers,
  StylingSchema,
  TableRow,
  SendToSignersArgsTypes,
  isTextSchema,
  CustomVariableType,
  TableSchema,
} from "../../../../common/src/type";
import { getRandomColorV1, reorderArray } from "../../../../../utils/uiUtils";
import EditorNavbar from "../EditorNavbar";
import axios from "../../../../../utils/axios";
import {
  delay,
  getOnwerFromDB,
  getWhiteLabelInfoLocal,
} from "../../../../../utils";
import _ from "lodash";
import { toast } from "react-toastify";
import EditorSubNavbar from "../EditorSubNavbar/EditorSubNavbar";
import {
  addBlankPageToPdf,
  appendPdfPageAtPageNumber,
  deletePageFromPdf,
  duplicatePage,
  fileToBase64,
  rearrangePagesInPdf,
  updateBasePdfLink,
} from "../../../../../utils/localPdfUtils";
import { BASE_URL } from "../../../../../constants/constants";
import { async } from "@firebase/util";
import MixPanel from "../../../../../utils/services/mixpanel";
import QuoteBuilder from "./QuoteBuilder/QuoteBuilder";
import {
  formatPrice,
  getDefaultCurrency,
} from "../../../../../utils/quoteBuilder";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  COLORS,
  PREVIEW_AVAILABLE_STATUS,
} from "../../../../../constants/common";
import {
  convertToTitleCase,
  getContactFieldValue,
} from "../../../../../utils/contacts";
import { fileUploadType } from "./PdfTemplateSidebar/AttachmentsView";
import { ALL_FEATURES } from "../../../../../types/tiers";

export type PdfTemplateSidebarViewTypes =
  | "CONTENT"
  | "VARIABLE"
  | "DETAIL_VIEW"
  | "LANGUAGE"
  | "RECIPIENT"
  | "APPS"
  | "PIPEDRIVE"
  | "SETTINGS"
  | "DESIGN"
  | "DOCUMENT_VALUE"
  | "APPROVAL_WORKFLOW"
  | "PREVIEW"
  | "ATTACHMENTS";

export type DocumentValueType = {
  selectedPayers: string[];
  valueType: "CUSTOM" | "DOCUMENT_TOTAL";
  amount: {
    currency: string;
    valueTotal: number; // sum of schema totals
    customAmount: number; // Custom amount
    schemas: {
      schemaId: string; // not sure what i will use it for but might be helpful
      schemaName: string; // Display purpose
      schemaTotal: number; // after applying all discount, fee, tax i.e total amount
    }[];
  };
};

export type CustomSchemaType = { pageType: "BLANK" | "ORIGINAL" };

export type DesignerState = {
  instanceFrom: InstanceFromType;
  view: "EDITOR" | "QUOTE_BUILDER";
  quoteBuilder: {
    schemaId: string;
    activeTable: TableSchema | null;
  };
  fetchedData: any;
  pageCursor: number;
  roles: any[];
  receiversList: PDFReceivers[];
  selectedRole: any;
  miniPdf: {
    open: boolean;
  };
  combinedSidebar: {
    open: boolean;
    selectedView: PdfTemplateSidebarViewTypes;
  };
  template: Template;
  schemasList: SchemaForUI[][];
  owner: any;
  isSigningOrderData: boolean;
  saving: boolean;
  editorHelpers: {
    disbaleMove: string;
  };
  theme: {
    primary: string;
    secondary: string;
    tertiary: string;
    textPrimary: string;
    textSecondary: string;
    textTertiary: string;
  };
  addDocument: {
    show: boolean;
    pageNo: number;
  };
  addAttachment: {
    show: boolean;
    pageNo: number;
  };
  showUpgradeModal: {
    show: boolean;
    type: ALL_FEATURES;
  };
  settings: {
    schemaStyleDefaults: StylingSchema;
    customSchema?: CustomSchemaType[];
    signingType: {
      image: boolean;
      drawn: boolean;
      typing: boolean;
    };
    documentValue: DocumentValueType;
  };
  customVariables: CustomVariableType[];
  mode: "EDITING" | "PREVIEW";
  inputs: { [key: string]: string }[] | undefined;
  sentViaSMS: boolean;
};

export type OrderedPageType = {
  data: SchemaForUI[];
  order: number;
  id: number;
};

type PDFHelpersType = {
  customSchemaTracker: ({
    pageNo,
    action,
    docPageCount,
    schemasList,
  }: {
    pageNo: number;
    action: "add_blank" | "remove" | "duplicate" | "add_doc" | "reorder";
    docPageCount?: number;
    schemasList: SchemaForUI[][];
  }) => CustomSchemaType[];
  addPage: (pageNo: number, typeOf?: "new" | "duplicate") => Promise<void>;
  duplicatePage: (pageNo: number) => Promise<void>;
  addDoc: (pageNo: number, file: File) => Promise<void>;
  delPage: (pageNo: number) => Promise<void>;
  reorder: (pageOrder: number[]) => Promise<void>;
};

export type GlobalHelpersType = {
  getModifiedTemplate: () => Template;
  getFetchedDataFromDB: (
    id: string | undefined,
    templateId?: string | undefined
  ) => Promise<void>;
  renameExsitingSchemas: (from: string, to: string) => void;
  deleteSchemasFor: (key: string) => void;
  saveSchemasOnly: () => Promise<void>;
  setSelectedRole: (role: any) => void;
  setSchemasList: (schemaList_: SchemaForUI[][]) => void;
  getOwnerFromDb: () => Promise<void>;
  // saveHandler: (pdfRedirect?: boolean) => Promise<any>;
  // minor chnage
  saveHandler: (sendForSigners?: SendToSignersArgsTypes) => Promise<any>;
  PDF: PDFHelpersType;
  getActiveSchema: () => SchemaForUI | undefined;
  changeRole: (args: { id: string; title?: string; color: string }) => void;
  timeTravel: (mode: "undo" | "redo") => void;
  setPageIndex: (pageNum: number) => void;
  setDefaultStyles: (value: StylingSchema) => Promise<void>;
  applyStyles: (value: StylingSchema) => void;
  updateDocumentValue: () => Promise<void>;
  handlePriceTableDiscard: () => void;
  setCustomSchemaSetting: (value: CustomSchemaType[]) => Promise<void>;
  updateDocumentAttachments: (fileUploads: fileUploadType[]) => Promise<void>;
};

export type ChangeSchemasArg = {
  key: string;
  value:
    | undefined
    | string
    | number
    | { min: number; max: number }
    | boolean
    | DropdownOptionSchema[]
    | TableRow // for table head
    | TableRow[] // for table rows
    | number[] // for table widthPercentage
    | StylingSchema;
  schemaId: string;
};
export type ChangeSchemasType = (objs: ChangeSchemasArg[]) => void;

type AddSchemaArg = {
  fileType?: string;
  title: string;
  type: string;
  id: string;
  required: boolean;
  subType?: string;
  name?: string;
  email?: string;
  _id?: string;
  message?: string;
  colour?: string;
  role?: { title: string; colour: string };
  position?: { x: number; y: number };
  radiogroupId?: string;
  radiogroupColor?: string;
  size?: { height: number; width: number };
  variableName?: string; //for custom Variable
  isStatic?: boolean;
  contactFieldName?: string; // for custom and contact fields
};

export type AddSchemaType = (selectedQuestion: AddSchemaArg) => void;

export const generateUniqueKeyRoot = ({
  baseKey,
  schemasList,
  instanceFrom,
  receiversList,
  pageCursor,
  pageNo,
}: {
  baseKey: string;
  schemasList: SchemaForUI[][];
  instanceFrom: InstanceFromType;
  receiversList: PDFReceivers[]; // This is useless , it is used to detect type of document
  pageCursor: number;
  pageNo?: number;
}) => {
  let pageNo_ = typeof pageNo !== "undefined" ? pageNo : pageCursor;
  const pageSchemas = schemasList[pageNo_];
  let uniqueKey = baseKey.replace(/\n$/, "");
  let index = 1;
  // let uniqueKey = baseKey;
  // if (isOnlyPdfTemplate) {
  //   while (
  //     pageSchemas.some(
  //       (schema) => schema.key === uniqueKey + `(${pageCursor + 1})`
  //     )
  //   ) {
  if (
    (Array.isArray(receiversList) && receiversList.length > 0) ||
    instanceFrom === "PDF-TEMPLATE"
  ) {
    while (
      pageSchemas.some(
        (schema) => schema.key === uniqueKey + `(${pageNo_ + 1})`
      )
    ) {
      uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
      index++;
    }
    uniqueKey = `${uniqueKey}(${pageNo_ + 1})`;
  } else {
    while (pageSchemas.some((schema) => schema.key === uniqueKey)) {
      uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
      index++;
    }
  }
  return uniqueKey;
};
export const generateUniqueKey = (
  baseKey: string,
  designerState: DesignerState,
  pageNo?: number
) => {
  return generateUniqueKeyRoot({
    baseKey,
    schemasList: designerState?.schemasList,
    instanceFrom: designerState?.instanceFrom,
    receiversList: designerState?.receiversList,
    pageCursor: designerState?.pageCursor,
    pageNo,
  });
  // let pageNo_ =
  //   typeof pageNo !== "undefined" ? pageNo : designerState?.pageCursor;
  // const pageSchemas = designerState?.schemasList[pageNo_];
  // let uniqueKey = baseKey.replace(/\n$/, "");
  // let index = 1;
  // // let uniqueKey = baseKey;
  // // if (isOnlyPdfTemplate) {
  // //   while (
  // //     pageSchemas.some(
  // //       (schema) => schema.key === uniqueKey + `(${pageCursor + 1})`
  // //     )
  // //   ) {
  // if (
  //   (Array.isArray(designerState?.receiversList) &&
  //     designerState?.receiversList.length > 0) ||
  //   designerState?.instanceFrom === "PDF-TEMPLATE"
  // ) {
  //   while (
  //     pageSchemas.some(
  //       (schema) => schema.key === uniqueKey + `(${pageNo_ + 1})`
  //     )
  //   ) {
  //     uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
  //     index++;
  //   }
  //   uniqueKey = `${uniqueKey}(${pageNo_ + 1})`;
  // } else {
  //   while (pageSchemas.some((schema) => schema.key === uniqueKey)) {
  //     uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
  //     index++;
  //   }
  // }
  // return uniqueKey;
};

export function getDocumentValueInfo(
  document: any
): DocumentValueType & { currAmount: number } {
  let documenValue = document?.settings?.documentValue;
  let schemas: {
    schemaId: string;
    schemaName: string;
    schemaTotal: number;
  }[] = documenValue?.amount?.schemas?.map((elm: any) => ({
    schemaId: elm?.schemaId || "",
    schemaName: elm?.schemaName || "",
    schemaTotal: Number(elm?.schemaTotal) ?? 0,
  }));

  return {
    selectedPayers: document?.settings?.documentValue?.selectedPayers || [],
    valueType:
      documenValue?.valueType === "DOCUMENT_TOTAL"
        ? "DOCUMENT_TOTAL"
        : "CUSTOM",
    amount: {
      currency: documenValue?.amount?.currency || "",
      valueTotal: Number(documenValue?.amount?.valueTotal) ?? 0,
      customAmount: Number(documenValue?.amount?.customAmount) ?? 0,
      schemas,
    },
    currAmount:
      documenValue?.valueType === "DOCUMENT_TOTAL"
        ? Number(documenValue?.amount?.valueTotal) ?? 0
        : Number(documenValue?.amount?.customAmount) ?? 0,
  };
}

const TemplateEditor = ({
  template,
  size,
  onSaveTemplate,
  onChangeTemplate,
  formQuestions,
  isOnlyPdfTemplate,
  receiversList,
  sendToSigners,
  roles,
  onSaveTemplateWithoutRedirect,
  setReload,
  fetchedData,
  setIsPreview,
  instanceFrom,
  inputs,
}: // variablesHelper,
DesignerReactProps & {
  onChangeTemplate: (t: Template) => void;
}) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const copiedSchemas = useRef<SchemaForUI[] | null>(null);
  const past = useRef<SchemaForUI[][]>([]);
  const future = useRef<SchemaForUI[][]>([]);
  const mainRef = useRef<HTMLDivElement>(null);
  const newRootRef = useRef<HTMLDivElement>(null);
  const paperRefs = useRef<HTMLDivElement[]>([]);

  const i18n = useContext(I18nContext);

  const [hoveringSchemaId, setHoveringSchemaId] = useState<string | null>(null);
  const [activeElements, setActiveElements] = useState<HTMLElement[]>([]);
  // const [schemasList, setSchemasList] = useState<SchemaForUI[][]>([
  //   [],
  // ] as SchemaForUI[][]);
  // const [pageCursor, setPageCursor] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [requiredFieldsNotFilled, setRequiredFieldsNotFilled] = useState<any[]>(
    []
  );

  // const { backgrounds, pageSizes, scale, error } = useUIPreProcessor({
  //   template,
  //   size,
  //   zoomLevel,
  // });

  // new consts
  // const [isMiniPDFOpen, setIsMiniPFOpen] = useState(true);
  // pdf template
  // const [pdfTempSidebarOpen, setPdfTempSidebarOpen] = useState(true);
  // const [sidebarSelectedOption, setSidebarSelectedOption] =
  //   useState<PdfTemplateSidebarViewTypes>("CONTENT");
  /*
  -------------------------------------------------------------------
  new system for vars that are to be sent as prop
  later change all variables to this states

  why is this system implemented
  1. Any parent calling the new Designer function cannot communicate with this component
  2. redux wont work as this is rendered out of root
  3. react-router / any context based things wont work here
  4. to send any state updates from this component to the parent require too much work
      a. change schema.ts
      b. change type.ts
      c. Change all occurencers of new Designer
      d. adding props will overclutter the original library an can introduce bugs
  
  solution
  1. make this component self sufficient [i.e it should be able to do everything without need of parent sending any data]
  2. make a large local state that would convert the args passed to new Designer
  3. conisder this state as the state of the parent
  4. make changes in this state and then save from here 
  5. do not call parent fucntion to save anything apart from saving template 
  6. reduces the number of props to send to child comps

  upgrades that can be made 
  1. convert common functions into global helpers
  -------------------------------------------------------------------
  */

  const [designerState, setDesignerState] = useState<DesignerState>({
    instanceFrom: instanceFrom,
    view: "EDITOR",
    quoteBuilder: {
      schemaId: "",
      activeTable: null,
    },
    fetchedData: fetchedData,
    pageCursor: 0,
    roles: roles || [],
    receiversList: receiversList || [],
    selectedRole: instanceFrom == "PDF" ? receiversList?.[0] : roles?.[0],
    miniPdf: {
      open: true,
    },
    combinedSidebar: {
      open: true,
      selectedView: "CONTENT",
    },
    template: template,
    schemasList: [],
    owner: JSON.parse(localStorage.getItem("owner") || "{}"),
    isSigningOrderData: fetchedData?.document?.isSigningOrderData,
    saving: false,
    editorHelpers: {
      disbaleMove: "",
    },
    theme: {
      primary:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
      secondary:
        whiteLabelInfo?.settings?.organization?.themeHex?.secondary ||
        COLORS.secondary,
      tertiary:
        whiteLabelInfo?.settings?.organization?.themeHex?.tertiary ||
        COLORS.tertiary,
      textPrimary:
        whiteLabelInfo?.settings?.organization?.themeHex?.textPrimary ||
        COLORS.textPrimary,
      textSecondary:
        whiteLabelInfo?.settings?.organization?.themeHex?.textSecondary ||
        COLORS.textSecondary,
      textTertiary:
        whiteLabelInfo?.settings?.organization?.themeHex?.textTertiary ||
        COLORS.textTertiary,
    },
    addDocument: {
      show: false,
      pageNo: 0,
    },
    addAttachment: {
      show: false,
      pageNo: 0,
    },
    showUpgradeModal: {
      show: false,
      type: "SIGNER_ATTACHMENTS",
    },
    settings: {
      schemaStyleDefaults: {},
      customSchema: [],
      signingType: {
        drawn: true,
        typing: true,
        image: true,
      },
      documentValue: {
        selectedPayers: [],
        valueType: "CUSTOM",
        amount: { currency: "", customAmount: 0, schemas: [], valueTotal: 0 },
      },
    },
    customVariables: fetchedData?.document?.variables || [],
    mode: "EDITING",
    inputs: undefined,
    sentViaSMS: false,
  });

  const { backgrounds, pageSizes, scale, error } = useUIPreProcessor({
    template: designerState?.template,
    size,
    zoomLevel,
  });

  const handleUpdateBasepdfLink = async (link: string) => {
    try {
      const newBasePdfLink = link;
      const documentId = designerState?.fetchedData?.document?.documentId;
      const { data } = await axios.post(`${BASE_URL}/document/updateBasePdf`, {
        newBasePdfLink,
        documentId,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const updateAttahmentsPageNo = async (
    pageNo: number,
    noOfPagePages: number,
    type: "ADD" | "REMOVE"
  ) => {
    const attachments =
      designerState?.fetchedData?.document?.settings?.attachments;

    if (type === "ADD") {
      let newAttachments = attachments?.map(
        (attachment: fileUploadType, index: number) => {
          if (attachment?.pageNo === undefined || attachment?.pageNo < pageNo) {
            return attachment;
          } else if (attachment?.pageNo >= pageNo) {
            return { ...attachment, pageNo: attachment.pageNo + noOfPagePages };
          }
        }
      );
      await globalHelpers.updateDocumentAttachments(newAttachments);
    } else {
      let newAttachments = attachments?.map(
        (attachment: fileUploadType, index: number) => {
          if (
            attachment?.pageNo === undefined ||
            attachment?.pageNo < pageNo ||
            attachment?.pageNo == 0
          ) {
            return attachment;
          } else if (attachment?.pageNo >= pageNo) {
            return { ...attachment, pageNo: attachment.pageNo - noOfPagePages };
          }
        }
      );
      await globalHelpers.updateDocumentAttachments(newAttachments);
    }
  };
  const pdfHelpers: PDFHelpersType = {
    customSchemaTracker: ({ pageNo, action, docPageCount, schemasList }) => {
      // if not custom schema initalised
      let customSchema: CustomSchemaType[] = [
        ...(designerState?.settings?.customSchema || []),
      ];
      let numPages = schemasList?.length || 0;
      let customSchemaInitalized = false;
      console.log({ numPages });

      if (customSchema.length === 0 && action !== "reorder") {
        // if custom schema does not exist
        // that means all pages are original
        // and the action is reorder i.e shuffeling
        // custom schema wont change
        // therfore we dont update custom schema on reorder with no inital custom schema
        // and as we are doing a -1 which only affects reorder causing page count discrapency
        console.log("No prev custom schema creating a new schema");
        customSchema = Array.from({ length: numPages }, () => ({
          pageType: "ORIGINAL",
        }));
        customSchemaInitalized = true;
      }

      let newPages: CustomSchemaType[] = [];
      switch (action) {
        case "add_blank":
          customSchema.splice(pageNo, customSchemaInitalized ? 1 : 0, {
            pageType: "BLANK",
          });
          break;
        case "add_doc":
          newPages = Array.from({ length: docPageCount ?? 1 }, () => ({
            pageType: "ORIGINAL",
          }));
          customSchema.splice(
            pageNo,
            customSchemaInitalized ? docPageCount ?? 1 : 0,
            ...newPages
          );
          break;
        case "duplicate":
          let pageType = customSchema[pageNo].pageType || "ORIGINAL";
          customSchema.splice(pageNo, customSchemaInitalized ? 1 : 0, {
            pageType: pageType,
          });
          break;
        case "remove":
          customSchema.splice(pageNo, customSchemaInitalized ? 0 : 1);
          break;
        case "reorder":
          // for reorder we dont do anything
          break;
        default:
          newPages = Array.from({ length: docPageCount ?? 1 }, () => ({
            pageType: "ORIGINAL",
          }));
          customSchema.splice(
            pageNo,
            customSchemaInitalized ? docPageCount ?? 1 : 0,
            ...newPages
          );
          break;
      }

      return customSchema;
    },
    addPage: async (pageNo: number) => {
      if (typeof designerState.template.basePdf == "string") {
        const data = await addBlankPageToPdf(
          designerState.template.basePdf,
          pageNo
        );
        if (data) {
          let newSchemaList = [...designerState?.schemasList];
          newSchemaList.splice(pageNo, 0, []);

          const modifiedTemplate = fmtTemplate(
            designerState?.template,
            newSchemaList || designerState?.schemasList
          );
          if (
            designerState?.fetchedData?.document?.settings?.attachments
              ?.length > 0
          ) {
            updateAttahmentsPageNo(pageNo, 1, "ADD");
          }
          const customSchema = pdfHelpers.customSchemaTracker({
            action: "add_blank",
            pageNo,
            schemasList: newSchemaList,
          });

          setDesignerState((prev) => ({
            ...prev,
            template: { ...modifiedTemplate, basePdf: data.updatedBase64 },
            schemasList: newSchemaList || prev.schemasList,
            settings: {
              ...prev.settings,
              customSchema: customSchema,
            },
          }));
          globalHelpers.setCustomSchemaSetting(customSchema);
          updateBasePdfLink(
            data.updatedBase64,
            designerState?.fetchedData?.document?.documentId,
            handleUpdateBasepdfLink
          );
        } else {
          console.log("No updated data add page =>", data);
        }
      }
    },
    duplicatePage: async (pageNo: number) => {
      if (typeof designerState.template.basePdf == "string") {
        const data = await duplicatePage(
          designerState.template.basePdf,
          pageNo
        );
        if (data) {
          let newSchemaList = [...designerState?.schemasList];
          newSchemaList.splice(pageNo + 1, 0, []);

          const modifiedTemplate = fmtTemplate(
            designerState?.template,
            newSchemaList || designerState?.schemasList
          );
          if (
            designerState?.fetchedData?.document?.settings?.attachments
              ?.length > 0
          ) {
            updateAttahmentsPageNo(pageNo, 1, "ADD");
          }
          const customSchema = pdfHelpers.customSchemaTracker({
            action: "duplicate",
            pageNo,
            schemasList: newSchemaList,
          });

          setDesignerState((prev) => ({
            ...prev,
            template: { ...modifiedTemplate, basePdf: data.updatedBase64 },
            schemasList: newSchemaList || prev.schemasList,
            settings: {
              ...prev.settings,
              customSchema: customSchema,
            },
          }));
          globalHelpers.setCustomSchemaSetting(customSchema);
          updateBasePdfLink(
            data.updatedBase64,
            designerState?.fetchedData?.document?.documentId,
            handleUpdateBasepdfLink
          );
        } else {
          console.log("No updated data add page =>", data);
        }
      }
    },
    addDoc: async (pageNo: number, file: File) => {
      if (typeof designerState.template.basePdf == "string") {
        const newFileBase64 = await fileToBase64(file);
        if (!newFileBase64) {
          toast.error("Something went wrong");
          return;
        }

        if (newFileBase64) {
          const data = await appendPdfPageAtPageNumber(
            designerState.template.basePdf,
            pageNo,
            newFileBase64
          );

          if (data) {
            let newSchemaList = [...designerState?.schemasList];
            const blankArray = Array(data.numPagesToAdd).fill([]);
            newSchemaList.splice(pageNo, 0, ...blankArray);

            const modifiedTemplate = fmtTemplate(
              designerState?.template,
              newSchemaList || designerState?.schemasList
            );
            if (
              designerState?.fetchedData?.document?.settings?.attachments
                ?.length > 0
            ) {
              updateAttahmentsPageNo(pageNo, data?.numPagesToAdd, "ADD");
            }
            const customSchema = pdfHelpers.customSchemaTracker({
              action: "add_doc",
              pageNo,
              schemasList: newSchemaList,
              docPageCount: data.numPagesToAdd,
            });

            setDesignerState((prev) => ({
              ...prev,
              template: { ...modifiedTemplate, basePdf: data.updatedBase64 },
              schemasList: newSchemaList || prev.schemasList,
              settings: {
                ...prev.settings,
                customSchema: customSchema,
              },
            }));
            globalHelpers.setCustomSchemaSetting(customSchema);
            updateBasePdfLink(
              data.updatedBase64,
              designerState?.fetchedData?.document?.documentId,
              handleUpdateBasepdfLink
            );
          }
        }
      }
    },
    delPage: async (pageNo: number) => {
      if (typeof designerState.template.basePdf == "string") {
        const data = await deletePageFromPdf(
          designerState.template.basePdf,
          pageNo
        );
        if (data) {
          let newSchemaList = [...designerState?.schemasList];
          newSchemaList.splice(pageNo, 1);

          const modifiedTemplate = fmtTemplate(
            designerState?.template,
            newSchemaList || designerState?.schemasList
          );
          if (
            designerState?.fetchedData?.document?.settings?.attachments
              ?.length > 0
          ) {
            updateAttahmentsPageNo(pageNo, 1, "REMOVE");
          }
          const customSchema = pdfHelpers.customSchemaTracker({
            action: "remove",
            pageNo,
            schemasList: newSchemaList,
          });

          setDesignerState((prev) => ({
            ...prev,
            template: { ...modifiedTemplate, basePdf: data.updatedBase64 },
            schemasList: newSchemaList || prev.schemasList,
            pageCursor:
              pageNo == designerState?.pageCursor ? 0 : prev.pageCursor,
            settings: {
              ...prev.settings,
              customSchema: customSchema,
            },
          }));
          globalHelpers.setCustomSchemaSetting(customSchema);
          updateBasePdfLink(
            data.updatedBase64,
            designerState?.fetchedData?.document?.documentId,
            handleUpdateBasepdfLink
          );
        } else {
          console.log("No updated data reorder page =>", data);
        }
      }
    },
    reorder: async (pageOrder: number[]) => {
      if (typeof designerState.template.basePdf == "string") {
        const data = await rearrangePagesInPdf(
          designerState.template.basePdf,
          pageOrder
        );
        if (data) {
          // order
          let reorderedSchemaList = reorderArray(
            [...designerState.schemasList],
            pageOrder
          );

          const customSchema = pdfHelpers.customSchemaTracker({
            action: "reorder",
            pageNo: 0,
            schemasList: [...designerState.schemasList],
          });

          let reorderedCustomSchema = reorderArray(
            [...(customSchema || [])],
            pageOrder
          );

          const modifiedTemplate = fmtTemplate(
            designerState?.template,
            reorderedSchemaList || designerState.schemasList
          );

          setDesignerState((prev) => ({
            ...prev,
            template: { ...modifiedTemplate, basePdf: data.updatedBase64 },
            schemasList: reorderedSchemaList || prev.schemasList,
            settings: {
              ...prev.settings,
              customSchema: reorderedCustomSchema || customSchema,
            },
          }));

          globalHelpers.setCustomSchemaSetting(
            reorderedCustomSchema || customSchema
          );
          updateBasePdfLink(
            data.updatedBase64,
            designerState?.fetchedData?.document?.documentId,
            handleUpdateBasepdfLink
          );
        } else {
          console.log("No updated data reorder page =>", data);
        }
      }
    },
  };

  const globalHelpers: GlobalHelpersType = {
    getModifiedTemplate: () => {
      return fmtTemplate(designerState?.template, designerState?.schemasList);
    },
    getFetchedDataFromDB: async (
      id: string | undefined,
      templateId: string | undefined
    ) => {
      // console.log("getFetchedDataFromDB ---------------", {
      //   instanceFrom,
      //   instanceFromDB: designerState.instanceFrom,
      //   idDB: designerState.fetchedData?.document?.id,
      //   id,
      // });
      if (
        !(id || designerState.fetchedData?.document?.id) &&
        (designerState.instanceFrom == "PDF" ||
          designerState.instanceFrom == "PDF-TEMPLATE")
      ) {
        //         console.log(
        //           `------------------------------
        // Returned from getFetchedDataFromDB for instance: ${designerState.instanceFrom} and no id
        // ------------------------------`
        //         );
        return;
      }
      if (
        !(id || designerState?.fetchedData?.forms?.[0]?.formId) &&
        designerState.instanceFrom == "FORM"
      ) {
        console.log(`------------------------------
Returned from getFetchedDataFromDB for instance: ${designerState.instanceFrom} and no id
------------------------------`);
        return;
      }

      if (
        designerState.instanceFrom == "PDF" ||
        designerState.instanceFrom == "PDF-TEMPLATE"
      ) {
        console.log("Fetching document data -----------------");
        const { data } = await axios.get(
          `/getOneDocument?documentId=${
            designerState.fetchedData?.document?.id || id || ""
          }&templateId=${
            designerState.fetchedData?.templateId || templateId || ""
          }`
        );
        // console.log(data, designerState);
        setDesignerState((prev) => ({
          ...prev,
          fetchedData: data,
          roles: data?.document?.roles || [],
          receiversList: data?.document?.respondants || [],
          selectedRole:
            designerState.instanceFrom == "PDF"
              ? data?.document?.respondants?.[0]
              : data?.document?.roles?.[0],
          isSigningOrderData: data?.document?.isSigningOrderData,
        }));
      }
      if (designerState.instanceFrom == "FORM") {
        console.log("Fetching from data -----------------");
        const { data } = await axios.get(
          `/forms?formId=${
            designerState?.fetchedData?.forms?.[0]?.formId || id || ""
          }`
        );
        setDesignerState((prev) => ({
          ...prev,
          fetchedData: data,
        }));
      }
    },
    renameExsitingSchemas: (from, to) => {
      // used if the role is renamed in pdf-Template and pdf
      if (
        designerState.instanceFrom == "PDF-TEMPLATE" ||
        designerState.instanceFrom == "PDF"
      ) {
        const questionsToUpdate: any[] = [];
        designerState?.schemasList?.map((page): any => {
          page?.map((question) => {
            if (from === question?.role?.title && from && question?.role?.title)
              questionsToUpdate.push({
                key: "role",
                value: { title: to, colour: question?.role?.colour },
                schemaId: question?.id,
              });
          });
        });
        changeSchemas(questionsToUpdate);
      }
    },
    deleteSchemasFor: (key) => {
      console.log({ key });
      if (
        designerState.instanceFrom == "PDF-TEMPLATE" ||
        designerState.instanceFrom == "PDF"
      ) {
        commitSchemas(
          designerState?.schemasList[designerState?.pageCursor].filter(
            (schema) => !(schema?.role?.title == key && key)
          )
        );
      }
    },
    setSelectedRole: (role) => {
      setDesignerState((prev) => ({ ...prev, selectedRole: role }));
    },
    setSchemasList: (schemaList_) => {
      // console.log("Set Schema list called", schemaList_);
      setDesignerState((prev) => ({ ...prev, schemasList: schemaList_ }));
    },
    saveSchemasOnly: async () => {
      // review this function why it was written
      // await delay(2000); // add debounce if it works
      console.log("Save schemas only called", designerState?.template?.schemas);
      console.log(designerState?.template, designerState?.schemasList);
      const modifiedTemplate = fmtTemplate(
        designerState?.template,
        designerState?.schemasList
      );
      onSaveTemplateWithoutRedirect &&
        onSaveTemplateWithoutRedirect(modifiedTemplate);
      // if (
      //   designerState?.instanceFrom == "PDF" ||
      //   designerState?.instanceFrom == "PDF-TEMPLATE"
      // ) {
      //   try {
      //     const { data } = await axios.post("/update-signature-template", {
      //       documentId: designerState?.fetchedData?.document?.id,
      //       schemas: modifiedTemplate?.schemas,
      //       // ownerId: owner._id,
      //     });
      //     console.log(data);
      //   } catch (error) {
      //     console.log("Error in saving schemas");
      //   }
      // }
    },

    getOwnerFromDb: async () => {
      let owner = await getOnwerFromDB();
      setDesignerState((prev) => ({ ...prev, owner: owner }));
    },
    saveHandler: async (sendForSigners) => {
      // consolidate these functions later
      if (designerState?.instanceFrom == "PDF") {
        // signing order check ----------------------------------------
        let invalidOrderNumber = false;
        if (designerState?.isSigningOrderData) {
          designerState?.receiversList?.map((elm: any) => {
            console.log(elm?.signingOrderNo, elm?.name);
            if (
              isNaN(parseInt(elm?.signingOrderNo)) ||
              parseInt(elm?.signingOrderNo) < 1 ||
              parseInt(elm?.signingOrderNo) >
                designerState?.receiversList?.length
            ) {
              invalidOrderNumber = true;
            }
          });
        }
        if (invalidOrderNumber) {
          toast.error("Please provide appropriate Signing Order numbers");
          return;
        }
        // signing order check end ------------------------------------
        if (sendToSigners) {
          // because we have used savehandler without any args in multiple places,
          // TODO : change from positional args to object args
          let data = await sendToSigners({
            redirect:
              sendForSigners?.redirect !== undefined
                ? sendForSigners?.redirect
                : true,
            isLink: sendForSigners?.isLink ? sendForSigners?.isLink : false,
          });
          // console.log("data in global helpers save handler", data);
          return data;
        }
      }
      // PDFTEMPLATE =======================================================
      if (designerState?.instanceFrom == "PDF-TEMPLATE") {
        console.log("designer state", designerState);
        new MixPanel().track("PDF template ", {
          pdf_template_action: "pdf_template_save_document",
          isSigningOrderData: designerState?.isSigningOrderData,
          roles: designerState?.roles?.length,
          pdfLanguage:
            designerState?.fetchedData?.document?.settings?.lang?.value,
          paid: true,
        });
        new MixPanel().increment("pdf_template_save_document");
        // signing order check ----------------------------------------
        let invalidOrderNumber = false;
        if (designerState?.isSigningOrderData) {
          designerState?.roles?.map((elm: any) => {
            console.log(elm?.signingOrderNo, elm?.name);
            if (
              isNaN(parseInt(elm?.signingOrderNo)) ||
              parseInt(elm?.signingOrderNo) < 1 ||
              parseInt(elm?.signingOrderNo) > designerState?.roles?.length
            ) {
              invalidOrderNumber = true;
            }
          });
        }
        if (invalidOrderNumber) {
          toast.error("Please provide appropriate Signing Order numbers");
          return;
        }
        // signing order check end ------------------------------------
        const modifiedTemplate = fmtTemplate(
          designerState?.template,
          designerState?.schemasList
        );
        onSaveTemplate && onSaveTemplate(modifiedTemplate);
      }
      // FORMs =============================================================
      if (designerState?.instanceFrom == "FORM") {
        const modifiedTemplate = fmtTemplate(
          designerState?.template,
          designerState?.schemasList
        );
        onSaveTemplate && onSaveTemplate(modifiedTemplate);
      }
    },
    PDF: pdfHelpers,
    getActiveSchema: (): SchemaForUI | undefined => {
      const ids = activeElements.map((ae) => ae?.id);
      // const activeSchema = designerState?.schemasList?.[
      //   designerState?.pageCursor
      // ]?.find((s) => ids.includes(s.id));

      // if (activeSchema?.type === "text") {
      //   if (!activeSchema.fontName) {
      //     activeSchema.fontName = fallbackFont;
      //   }
      // }

      const activeSchema = designerState?.schemasList?.[
        designerState?.pageCursor
      ].filter((s) => ids.includes(s.id));

      if (activeSchema?.length > 1) {
        return undefined;
      }

      return activeSchema?.[activeSchema?.length - 1];
    },
    changeRole: ({ id, title, color }) => {
      if (
        // designerState?.instanceFrom == "PDF" ||
        designerState?.instanceFrom == "PDF-TEMPLATE"
      ) {
        const questionsToUpdate: any[] = [];
        designerState?.schemasList?.map((page): any => {
          page?.map((question) => {
            if (id === question?.id) {
              questionsToUpdate.push({
                key: "role",
                value: {
                  title: title || question?.role?.title,
                  colour: color || question?.role?.colour,
                },
                schemaId: question?.id,
              });
              questionsToUpdate.push({
                key: "signatureColour",
                value: color || question?.signatureColour,
                schemaId: question?.id,
              });
            }
          });
        });
        changeSchemas(questionsToUpdate);
      }

      if (designerState?.instanceFrom == "PDF") {
        // update id
        const targetRespondentId = designerState?.receiversList?.find(
          (rec) => rec.name == title
        );

        let newId =
          //@ts-ignore
          v4() + "_" + targetRespondentId?._id + "_" + targetRespondentId?._id;

        console.log({ newId, targetRespondentId });

        let newSchemaList = [...designerState?.schemasList];
        for (let page of newSchemaList) {
          for (let schema_ of page) {
            if (schema_?.id == id) {
              schema_.id = newId;
              schema_.role = {
                title: title || schema_?.role?.title || "",
                colour: color,
              };
              schema_.signatureColour = color;
              // Change the data when field type is custom / contact field
              if (
                schema_?.contactFieldName &&
                (schema_?.subType === "custom_field" ||
                  schema_?.subType === "contact_field")
              ) {
                schema_.data = getContactFieldValue(
                  targetRespondentId?.email,
                  schema_?.contactFieldName,
                  schema_?.subType
                );
              }
            }
          }
        }
        // console.log({ newSchemaList });
        globalHelpers.setSchemasList(newSchemaList);
        setPdfRoleUpdated({ action: "set-active", id: [newId], run: true });
      }
    },
    timeTravel: (mode: "undo" | "redo") => {
      const isUndo = mode === "undo";
      const stack = isUndo ? past : future;
      if (stack.current.length <= 0) return;
      (isUndo ? future : past).current.push(
        cloneDeep(designerState?.schemasList[designerState?.pageCursor])
      );
      const s = cloneDeep(designerState?.schemasList);
      s[designerState?.pageCursor] = stack.current.pop()!;
      // globalHelpers.setSchemasList(s);
      setDesignerState((prev) => ({ ...prev, schemasList: s }));
    },
    setPageIndex(pageNum) {
      if (!mainRef.current) return;
      mainRef.current.scrollTop = getPagesScrollTopByIndex(
        pageSizes,
        pageNum,
        scale
      );
      // setPageCursor(p);
      setDesignerState((prev) => ({ ...prev, pageCursor: pageNum }));
    },
    setDefaultStyles: async (value) => {
      try {
        const { data } = await axios.post(`${BASE_URL}/document/settings`, {
          schemaStyleDefaults: value,
          documentId: designerState?.fetchedData?.document?.documentId,
        });
        if (data?.success) {
          // toast.success("Styles changed succesfully");
          if (data?.document?.settings?.schemaStyleDefaults) {
            // if settings exists on returned object
            // as when we change language settings will always exist on the document
            setDesignerState((prev) => ({
              ...prev,
              settings: {
                ...prev.settings,
                schemaStyleDefaults: {
                  ...data?.document?.settings?.schemaStyleDefaults,
                },
              },
            }));
          }
        }
        if (!data?.success) {
          toast.error(data?.message || "Something went wrong");
        }
      } catch (error) {
        toast.error("Could not update styles");
        console.log(error);
      }
    },
    applyStyles: (value) => {
      let newSchemas = [...designerState.schemasList];
      for (let pgIdx in newSchemas) {
        let page = newSchemas[pgIdx];
        for (let schema_ of page) {
          schema_ = applyDefaults(schema_, value);
        }
      }
      setDesignerState((prev) => ({ ...prev, schemasList: newSchemas }));
    },
    updateDocumentValue: async () => {
      try {
        let tableSchema: SchemaForUI = structuredClone(
          designerState.quoteBuilder.activeTable
        ) as SchemaForUI;

        //  updating schemas with the updated table
        const schemasList: SchemaForUI[][] = structuredClone(
          designerState.schemasList
        );

        let newSchemasList: SchemaForUI[][] = schemasList.map((page) =>
          page.map((schema) => {
            if (schema.id === designerState.quoteBuilder.schemaId) {
              return tableSchema as SchemaForUI;
            }
            return schema;
          })
        );

        // updating document value with the new table
        let newValueSchemas = [
          ...designerState.settings.documentValue.amount.schemas,
        ];

        let checkIfTableExits = newValueSchemas.findIndex(
          (value) => value.schemaId == tableSchema.id
        );
        if (checkIfTableExits !== -1 && isTableSchema(tableSchema)) {
          newValueSchemas[checkIfTableExits].schemaTotal =
            tableSchema.subtotalMenu?.total.amount || 0;
        }

        let documentTotal = newValueSchemas.reduce(
          (acc, elm) => acc + elm.schemaTotal,
          0
        );
        let newDocumentValue: DocumentValueType = structuredClone(
          designerState.settings.documentValue
        );
        newDocumentValue = {
          ...newDocumentValue,
          amount: {
            ...newDocumentValue.amount,
            schemas: newValueSchemas,
            valueTotal: documentTotal,
          },
        };

        // here first await updating value new calculated doument value
        // AY add here network req to save the settings of document value
        const { data } = await axios.post(`/document/settings`, {
          documentValue: newDocumentValue,
          documentId: designerState?.fetchedData?.document?.documentId,
        });

        if (data?.success) {
          toast.success("Table Saved Sucessfully");
          if (data?.document?.settings?.documentValue) {
            // if settings exists on returned object
            // as when we change language settings will always exist on the document
            setDesignerState((prev) => ({
              ...prev,
              schemasList: newSchemasList,
              settings: {
                ...prev.settings,
                documentValue: newDocumentValue,
              },
              view: "EDITOR",
            }));
          }
        }
        if (!data?.success) {
          toast.error(data?.message || "Something went wrong");
        }
      } catch (error) {
        console.log("error in updateDocumentValue");
        console.log(error);
        toast.error("Something went wrong");
      }
    },
    handlePriceTableDiscard: () => {
      setDesignerState((prev) => ({
        ...prev,
        view: "EDITOR",
        quoteBuilder: { schemaId: "", activeTable: null },
      }));
    },
    updateDocumentAttachments: async (fileUploads: fileUploadType[]) => {
      try {
        const { data } = await axios.post(`/document/settings`, {
          documentId: designerState?.fetchedData?.document?.documentId,
          attachments: fileUploads,
        });

        if (data?.success) {
          setDesignerState((prev) => ({
            ...prev,
            fetchedData: { ...prev.fetchedData, document: data?.document },
          }));
          toast.success("Attachments updated!");
        }
      } catch (error) {
        console.error("Error updating settings:", error);
        toast.error("Failed to update Attachments.");
      }
    },
    setCustomSchemaSetting: async (value) => {
      try {
        const { data } = await axios.post(`${BASE_URL}/document/settings`, {
          customSchema: value,
          documentId: designerState?.fetchedData?.document?.documentId,
        });
        if (data?.success) {
          // toast.success("Styles changed succesfully");
          if (data?.document?.settings?.schemaStyleDefaults) {
            // if settings exists on returned object
            // as when we change language settings will always exist on the document
            setDesignerState((prev) => ({
              ...prev,
              settings: {
                ...prev.settings,
                schemaStyleDefaults: {
                  ...data?.document?.settings?.schemaStyleDefaults,
                },
              },
            }));
          }
        }
        if (!data?.success) {
          toast.error(data?.message || "Something went wrong");
        }
      } catch (error) {
        toast.error("Could not update styles");
        console.log(error);
      }
    },
  };
  // -------------------------------------------------------------------

  // ! NOTE: HACK_FIX code here ===============================
  // ! hack fix for changing role on schema hover options
  const [pdfRoleUpdated, setPdfRoleUpdated] = useState<{
    run: boolean;
    id: string[];
    action: "set-active" | null;
  }>({ run: false, id: [], action: null });

  useEffect(() => {
    const setActive = (newId: string) => {
      const elmWithChangedId = document.getElementById(newId);
      console.log({ elmWithChangedId });
      if (elmWithChangedId) {
        setActiveElements([elmWithChangedId]);
      }
    };
    if (pdfRoleUpdated.run) {
      if (pdfRoleUpdated.action === "set-active") {
        setActive(pdfRoleUpdated?.id?.[0]);
      }
      setPdfRoleUpdated({ run: false, id: [], action: null });
    }
  }, [designerState.schemasList, pdfRoleUpdated]);
  // ! =======================================================

  const SidebarWidths = {
    open: {
      pdf: 350,
      form: 300,
    },
    close: {
      pdf: 50,
      form: 300,
    },
  };

  const onEdit = (targets: HTMLElement[]) => {
    setActiveElements(targets);
    setHoveringSchemaId(null);
  };

  const onEditEnd = () => {
    setActiveElements([]);
    setHoveringSchemaId(null);
  };

  // This is for when we scroll in the editor it sets the active page in minipdf viewer
  // therefore solving the problem where we scroll back to top will resolve the problem
  // of scrollingn back up
  useScrollPageCursor({
    ref: mainRef,
    pageSizes,
    scale,
    pageCursor: designerState.pageCursor,
    onChangePageCursor: (p) => {
      // setPageCursor(p);
      setDesignerState((prev) => ({ ...prev, pageCursor: p }));
      onEditEnd();
    },
  });

  const modifiedTemplate = fmtTemplate(
    designerState?.template,
    designerState?.schemasList
  );

  const commitSchemas = useCallback(
    (newSchemas: SchemaForUI[], pageNo?: number) => {
      // if page number is passed we use that page num else use the active page
      // AY CURRENTLY_NON_FUNCTIONAL
      let pageNo_ =
        typeof pageNo == "number" ? pageNo : designerState?.pageCursor;
      future.current = [];
      past.current.push(cloneDeep(designerState?.schemasList[pageNo_]));
      const _schemasList = cloneDeep(designerState?.schemasList);
      _schemasList[pageNo_] = newSchemas;
      globalHelpers.setSchemasList(_schemasList);
      onChangeTemplate(fmtTemplate(designerState?.template, _schemasList));
      // onSaveTemplateWithoutRedirect &&
      //   onSaveTemplateWithoutRedirect(fmtTemplate(template, _schemasList));
    },
    [
      designerState?.template,
      designerState?.schemasList,
      designerState?.pageCursor,
      onChangeTemplate,
    ]
  );

  // major logic change -------------------------------------------
  // this useEffect is explicitly to handle duplicate button

  useEffect(() => {
    // since duplicating will always return the num of schemas > 0
    // we make this check in order not to remove the already existing
    // schemas from the db
    let numOfSchemas = 0;
    designerState?.schemasList?.map((page) => {
      page?.map((schemaItem) => {
        numOfSchemas += 1;
      });
    });
    if (numOfSchemas > 0) {
      onChangeTemplate(
        fmtTemplate(designerState?.template, designerState?.schemasList)
      );
    }
  }, [designerState?.schemasList]);

  function blankPagePosUpdate(ids: string[]): SchemaForUI[] {
    let removedSchemas = designerState?.schemasList[
      designerState?.pageCursor
    ].filter((schema) => ids.includes(schema.id));

    let modifiedSchemas = designerState?.schemasList[
      designerState?.pageCursor
    ]?.filter((schema) => !ids.includes(schema.id));

    for (let removedSchema of removedSchemas) {
      let removedHeight = removedSchema.height + POSITION.A4Gap.y;
      modifiedSchemas = modifiedSchemas?.map((schema) => {
        let isSchemaBelowDeleted = schema.position.y > removedSchema.position.y;
        let moveSchema =
          isSchemaBelowDeleted &&
          validBlankPageSchema(schema).isValid &&
          validBlankPageSchema(removedSchema).isValid;

        // console.log({
        //   isSchemaBelowDeleted,
        //   rm: removedSchema.position.y,
        //   og: schema.position.y,
        // });
        return {
          ...schema,
          position: {
            ...schema.position,
            y: moveSchema
              ? schema.position.y - removedHeight
              : schema.position.y,
          },
        };
      });
    }

    return modifiedSchemas;
  }

  const removeSchemas = useCallback(
    (ids: string[]) => {
      if (
        designerState?.settings?.customSchema?.[designerState?.pageCursor]
          ?.pageType === "BLANK"
      ) {
        commitSchemas(blankPagePosUpdate(ids));
      } else {
        commitSchemas(
          designerState?.schemasList[designerState?.pageCursor].filter(
            (schema) => !ids.includes(schema.id)
          )
        );
      }
      onEditEnd();
    },
    [designerState?.schemasList, designerState?.pageCursor, commitSchemas]
  );

  const changeSchemas = async (objs: ChangeSchemasArg[]) => {
    let newObjs: ChangeSchemasArg[] = [];
    let newObjsForPage: { [key: number]: ChangeSchemasArg[] } = {};
    // copy keys these keys are to be copied for linked groups
    let copyKeys: { [key: string]: number } = {
      placeholder: 1,
      data: 1,
      required: 1,
      ifDateFormat: 1,
      dropdownOptions: 1,
      role: 1,
      signatureColour: 1,
    };
    // key is linkgroup id and value is array of schema id
    let linkGroups: { [key: string]: string[] } = {};
    // key is schemaId id and value is link group id
    let schemas2LinkGroup: { [key: string]: string } = {};
    let schemas2PageNum: { [key: string]: number } = {};

    // this is 1 N loop
    for (let pgIdx in designerState.schemasList) {
      let page = designerState.schemasList[pgIdx];
      for (let schema_ of page) {
        if (schema_.linkGroup) {
          linkGroups[schema_.linkGroup] = [
            ...(linkGroups[schema_.linkGroup] || []),
            schema_.id,
          ];
          schemas2LinkGroup[schema_.id] = schema_.linkGroup;
          schemas2PageNum[schema_.id] = parseInt(pgIdx);
        }
      }
    }

    for (let obj of objs) {
      let schemaLinkGroupId = schemas2LinkGroup[obj.schemaId];
      let isCopyKey = copyKeys[obj.key];

      // console.log({ isCopyKey, schemaLinkGroupId });

      if (!(schemaLinkGroupId && isCopyKey)) {
        // base case that there is no link group
        newObjs.push(obj);
      } else {
        // where the schema is in a linked group
        let linkedSchemas = linkGroups[schemaLinkGroupId];
        for (let linkedSchema of linkedSchemas) {
          let linkedSchemaOnPage = schemas2PageNum[linkedSchema];
          if (linkedSchemaOnPage !== designerState.pageCursor) {
            // i.e the schema is not on the same page
            newObjsForPage[linkedSchemaOnPage] = [
              ...(newObjsForPage[linkedSchemaOnPage] || []),
              {
                schemaId: linkedSchema,
                key: obj.key,
                value: obj.value,
              },
            ];
          } else {
            // i.e the schema is on the same page
            newObjs.push({
              schemaId: linkedSchema,
              key: obj.key,
              value: obj.value,
            });
          }
        }
      }
    }

    changeSchemasMain(newObjs);
    for (let [pageNo, value] of Object.entries(newObjsForPage)) {
      console.log({ pageNo, value });
      // AY CURRENTLY_NON_FUNCTIONAL
      // make it a one single array
      // changeSchemasMain(value, parseInt(pageNo));
    }
  };

  const changeSchemasMain = useCallback(
    (objs: ChangeSchemasArg[], pageNo?: number) => {
      // if page number is passed we use that page num else use the active page
      // AY CURRENTLY_NON_FUNCTIONAL
      let pageNo_ =
        typeof pageNo == "number" ? pageNo : designerState?.pageCursor;
      const newSchemas = objs.reduce((acc, { key, value, schemaId }) => {
        const tgt = acc.find((s) => s.id === schemaId)!;
        // Assign to reference
        set(tgt, key, value);
        if (key === "type") {
          const type = String(value) as SchemaType;
          // set default value, text or barcode
          set(tgt, "data", getSampleByType(type));
          // For barcodes, adjust the height to get the correct ratio.
          if (
            type !== "text" &&
            type !== "image" &&
            type !== "signature" &&
            type !== "initial" &&
            type !== "date" &&
            type !== "checkbox" &&
            type !== "radiogroup" &&
            type !== "stamp" &&
            type !== "dropdown" &&
            type !== "name" &&
            type !== "email" &&
            type !== "number" &&
            type !== "static_image" &&
            type !== "imageFileUpload" &&
            type !== "line" &&
            type !== "table" &&
            type !== "file_upload"
          ) {
            set(tgt, "height", getKeepRatioHeightByWidth(type, tgt.width));
          }
        }
        return acc;
      }, cloneDeep(designerState?.schemasList[pageNo_]));
      commitSchemas(newSchemas, pageNo_);
    },
    [commitSchemas, designerState?.pageCursor, designerState?.schemasList]
  );

  const initEvents = useCallback(() => {
    const getActiveSchemas = () => {
      const ids = activeElements.map((ae) => ae.id);

      return designerState?.schemasList[designerState?.pageCursor].filter((s) =>
        ids.includes(s.id)
      );
    };
    const timeTravel = (mode: "undo" | "redo") => {
      const isUndo = mode === "undo";
      const stack = isUndo ? past : future;
      if (stack.current.length <= 0) return;
      (isUndo ? future : past).current.push(
        cloneDeep(designerState?.schemasList[designerState?.pageCursor])
      );
      const s = cloneDeep(designerState?.schemasList);
      s[designerState?.pageCursor] = stack.current.pop()!;
      globalHelpers.setSchemasList(s);
    };
    initShortCuts({
      move: (command, isShift) => {
        const pageSize = pageSizes[designerState?.pageCursor];
        const activeSchemas = getActiveSchemas();
        const arg = moveCommandToChangeSchemasArg({
          command,
          activeSchemas,
          pageSize,
          isShift,
        });
        changeSchemas(arg);
      },

      copy: () => {
        const activeSchemas = getActiveSchemas();
        if (activeSchemas.length === 0) return;
        copiedSchemas.current = activeSchemas;
      },
      paste: () => {
        if (!copiedSchemas.current || copiedSchemas.current.length === 0)
          return;
        const schema = designerState?.schemasList[designerState?.pageCursor];
        const stackUniqSchemaKeys: string[] = [];
        const pasteSchemas = copiedSchemas.current.map((cs) => {
          const oldQuestionId = cs.id;
          // this can be either question id or form id
          const questionId = oldQuestionId?.split("_")?.[1];
          let id = uuid();
          if (questionId) {
            // it is done twice in addSchema function therefore it is done here
            id = id + "_" + questionId + "_" + questionId;
          }
          const key = getUniqSchemaKey({
            copiedSchemaKey: cs.key,
            schema,
            stackUniqSchemaKeys,
          });
          const { height, width, position: p } = cs;
          const ps = pageSizes[designerState?.pageCursor];
          const position = {
            x: p.x + 10 > ps.width - width ? ps.width - width : p.x + 10,
            y: p.y + 10 > ps.height - height ? ps.height - height : p.y + 10,
          };

          return Object.assign(cloneDeep(cs), { id, key, position });
        });
        commitSchemas(
          designerState?.schemasList[designerState?.pageCursor].concat(
            pasteSchemas
          )
        );
        onEdit(pasteSchemas.map((s) => document.getElementById(s.id)!));
        copiedSchemas.current = pasteSchemas;
      },
      redo: () => timeTravel("redo"),
      undo: () => timeTravel("undo"),
      save: () => onSaveTemplate && onSaveTemplate(modifiedTemplate),
      remove: () => removeSchemas(getActiveSchemas().map((s) => s.id)),
      esc: onEditEnd,
      selectAll: () =>
        onEdit(
          designerState?.schemasList[designerState?.pageCursor].map(
            (s) => document.getElementById(s.id)!
          )
        ),
    });
  }, [
    activeElements,
    changeSchemas,
    commitSchemas,
    modifiedTemplate,
    designerState?.pageCursor,
    pageSizes,
    removeSchemas,
    onSaveTemplate,
    designerState?.schemasList,
  ]);

  const destroyEvents = useCallback(() => {
    destroyShortCuts();
  }, []);

  // although i commented out the scroll to top logic the page goes back up
  // commenting this function in the use effect will cause error  of prev schemas
  const updateTemplate = useCallback(async (newTemplate: Template) => {
    const sl = await templateSchemas2SchemasList(newTemplate);
    globalHelpers.setSchemasList(sl);
    onEditEnd();
    // setPageCursor(0);
    // setDesignerState((prev) => ({ ...prev, pageCursor: 0 }));
    // if (mainRef.current?.scroll) {
    //   mainRef.current.scroll({ top: 0, behavior: "smooth" });
    // }
  }, []);

  const [radioGroupCovers, setRadioGroupCovers] = useState<any[]>([]);

  function hexToRgb(hex: any) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  const newGetCover = (
    passedSchema: any,
    passedDivs: any,
    radiogroupColor: any
  ) => {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    passedSchema.forEach((schema_: any) => {
      minX = Math.min(minX, schema_?.position?.x || 0);
      minY = Math.min(minY, schema_?.position?.y || 0);
      maxX = Math.max(maxX, schema_?.position?.x + schema_?.width || 0);
      maxY = Math.max(maxY, schema_?.position?.y + schema_?.height || 0);
    });

    minX = minX * ZOOM;
    minY = minY * ZOOM;
    maxX = maxX * ZOOM - minX;
    maxY = maxY * ZOOM - minY;

    let padding = 16;
    let rgb = hexToRgb(radiogroupColor);

    return {
      onClick: () => {
        // there is this filter because in some cases the passedDiv is an array of null
        // and in pdf temp sidebar line 107 gives ae is null error
        // as active elms is set to an array of null
        setActiveElements(passedDivs?.filter((elm: any) => !!elm));
      },
      plusCircle: {
        style: {
          // color: radiogroupColor,
          color:
            passedSchema?.[0]?.role?.colour ||
            passedSchema?.[0]?.signatureColour ||
            designerState?.theme?.primary,
          position: "absolute",
          top: minY + maxY + padding / 1.5,
          left: minX + maxX + padding / 1.5,
          cursor: "pointer",
        },
        onClick: () => {
          let schema = passedSchema[passedSchema?.length - 1];
          console.log(schema);
          let calcheight = 0;
          if (pageSizes) {
            let pageDim = pageSizes[designerState?.pageCursor || 0].height;
            let oldCalcHeight =
              schema.position.y + schema.height + 5 + SIZES.radiogroup.height;
            calcheight =
              pageDim - oldCalcHeight < 0
                ? pageDim - SIZES.radiogroup.height
                : oldCalcHeight - SIZES.radiogroup.height;
          }
          if (instanceFrom == "PDF-TEMPLATE") {
            addSchema({
              title: "RadioGroup",
              type: "radiogroup",
              id: v4(),
              required: false,
              subType: "radiogroup",
              role: schema.role,
              position: {
                x: schema.position.x,
                y: calcheight,
              },
              size: { height: schema.height, width: schema.width },
              radiogroupId: schema.radiogroupId,
              radiogroupColor: schema.radiogroupColor,
            });
          }
          if (instanceFrom == "PDF") {
            const _id = schema?.id?.split("_")?.[1];
            const data = {
              title: "RadioGroup",
              type: "radiogroup",
              subType: "radiogroup",
              position: {
                x: schema.position.x,
                y: calcheight,
              },
              colour: schema?.signatureColour,
              radiogroupId: schema.radiogroupId,
              radiogroupColor: schema.radiogroupColor,
              role: schema.role,
              size: { height: schema.height, width: schema.width },
              _id,
            };
            //@ts-ignore
            addSchema(data);
          }
        },
      },
      style: {
        // border: `2px solid ${radiogroupColor}`,
        // background: rgb ? `rgba(${rgb?.r},${rgb?.g},${rgb.b}, ${0.1})` : "blue",
        border:
          passedSchema?.[0]?.role?.colour || passedSchema?.[0]?.signatureColour
            ? `2px solid ${
                passedSchema?.[0]?.role?.colour ||
                passedSchema?.[0]?.signatureColour
              }`
            : `2px solid ${designerState?.theme?.primary}`,
        background:
          (passedSchema?.[0]?.role?.colour ||
            passedSchema?.[0]?.signatureColour) + "20" ||
          designerState?.theme?.primary + "20",
        borderRadius: "3px",
        position: "absolute",
        zIndex: "1",
        left: minX - padding / 2 + "px",
        top: minY - padding / 2 + "px",
        width: maxX + padding + "px",
        height: maxY + padding + "px",
      },
    };
  };

  useEffect(() => {
    let newRadioGroups: any = {};
    let radioGroupColor: any = {};
    let radioGroupSchema: any = {};
    designerState?.schemasList?.[designerState?.pageCursor]?.map(
      (item: any) => {
        if (item?.subType == "radiogroup") {
          // setting color
          radioGroupColor[item?.radiogroupId] = item?.radiogroupColor;
          // new schema method start ---------------------------
          if (radioGroupSchema[item?.radiogroupId]) {
            radioGroupSchema[item?.radiogroupId] = [
              ...radioGroupSchema[item?.radiogroupId],
              item,
            ];
          } else {
            radioGroupSchema[item?.radiogroupId] = [item];
          }
          // need this do not remove
          if (newRadioGroups[item?.radiogroupId]) {
            newRadioGroups[item?.radiogroupId] = [
              ...newRadioGroups[item?.radiogroupId],
              document.getElementById(item?.id),
            ];
          } else {
            newRadioGroups[item?.radiogroupId] = [
              document.getElementById(item?.id),
            ];
          }
        }
      }
    );

    let coverStyles = [];
    for (let [key, value] of Object.entries(radioGroupSchema)) {
      coverStyles.push(
        newGetCover(value, newRadioGroups[key], radioGroupColor[key] || "green")
      );
    }
    setRadioGroupCovers(coverStyles);
  }, [designerState?.schemasList, designerState?.pageCursor, activeElements]);

  // cannot comment this out will cause error
  useEffect(() => {
    updateTemplate(designerState?.template);
  }, [designerState?.template, updateTemplate]);

  useEffect(() => {
    initEvents();

    return destroyEvents;
  }, [initEvents, destroyEvents]);

  function modifyForBlankPage(s: SchemaForUI): SchemaForUI {
    let activeCustomSchema =
      designerState?.settings?.customSchema?.[designerState?.pageCursor || 0];

    if (
      !validBlankPageSchema(s).isValid ||
      activeCustomSchema?.pageType !== "BLANK"
    ) {
      return s;
    }

    s.height = SIZES.A4.height;
    s.width = SIZES.A4.width;
    s.position = getStaticPosForBlankPage(
      s,
      designerState?.schemasList,
      designerState?.pageCursor,
      pageSizes
    ).position;

    return s;
  }

  function applyDefaults(
    s: SchemaForUI,
    schemaStyle?: StylingSchema
  ): SchemaForUI {
    // AY GOTTA do for table as well
    // AY TODO
    // gotta make a global height calc
    let styles = schemaStyle || designerState?.settings?.schemaStyleDefaults;
    if (isTextSchema(s)) {
      s.fontSize = styles?.fontSize || s.fontSize;
      s.lineHeight = styles?.lineHeight || s.lineHeight;
      s.characterSpacing = styles?.characterSpacing || s.characterSpacing;
      s.alignment = styles?.alignment || s.alignment;
      s.fontName = styles?.fontName || s.fontName;
      s.bold = styles?.bold || s.bold;
      s.italic = styles?.italic || s.italic;
      s.fontColor = styles?.fontColor || s.fontColor;
      s.backgroundColor = styles?.backgroundColor || s.backgroundColor;
      let newHeight = getHeightFromFontSize(s.fontSize || 10, false);
      if (s.subType !== "dropdown") {
        s.height = newHeight || s.height;
      }
    }

    if (isTableSchema(s) && s.subType === "table") {
      s.fontSize = styles?.fontSize || s.fontSize;
      s.lineHeight = styles?.lineHeight || s.lineHeight;
      s.characterSpacing = styles?.characterSpacing || s.characterSpacing;
      s.alignment = styles?.alignment || s.alignment;
      s.fontName = styles?.fontName || s.fontName;
    }
    return s;
  }

  const addSchema: AddSchemaType = (selectedQuestion: AddSchemaArg) => {
    /*
    ==============================================================================
    Notes : 
    Remove dependency from is From Mapper
    The name is misleading 
    It is used for pdf mapping and not form mapping
    ==============================================================================
    */
    //@ts-ignore
    // // console.log("inside add schema", selectedQuestion?.selectedQuestion?.color);
    //* Commenting this out as we are now mapping images in forms
    // if (selectedQuestion?.type === "FileUpload") {
    // return;
    // }
    // console.log("adding schema", selectedQuestion);
    let s = getInitialSchema();
    const paper = paperRefs.current[designerState?.pageCursor];
    const rectTop = paper ? paper.getBoundingClientRect().top : 0;
    s.position.y =
      rectTop > 0 ? 0 : pageSizes[designerState?.pageCursor].height / 2;
    s.key = `${i18n("field")}${
      designerState?.schemasList[designerState?.pageCursor].length + 1
    }`;

    if (selectedQuestion) {
      s.key = generateUniqueKey(
        selectedQuestion.name || selectedQuestion.title || "Signer",
        designerState
      );

      s.showKey = selectedQuestion.title || selectedQuestion.name || "";
      s.type = selectedQuestion.type === "Signature" ? "image" : "text";
      s.id = s.id + "_" + (selectedQuestion._id || selectedQuestion.id);
      s.width =
        selectedQuestion.type === "Signature"
          ? SIZES.isFormMapper.isSignature.width
          : SIZES.isFormMapper.isNotSignature.width;
      s.height =
        selectedQuestion.type === "Signature"
          ? SIZES.isFormMapper.isSignature.height
          : SIZES.isFormMapper.isNotSignature.height;
      s.data =
        selectedQuestion.type === "Signature"
          ? getSampleByType("image")
          : selectedQuestion.type === "date"
          ? s.ifDateFormat || "dd/mm/yyyy"
          : selectedQuestion.subType === "initials"
          ? "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature-template/4vnRsfZ.png"
          : s.key;
      s.subType = selectedQuestion.subType
        ? selectedQuestion.subType
        : s.subType;
      s.id = s.id + "_" + (selectedQuestion._id || selectedQuestion.id);
      s.data =
        selectedQuestion.type === "Signature"
          ? getSampleByType(s.type)
          : s.showKey;
      if (selectedQuestion.type === "checkbox") {
        s.data = "";
      }
      s.signatureColour = selectedQuestion.colour
        ? selectedQuestion.colour
        : designerState?.theme?.primary;

      // TO DO code clean up
      //checkbox settings [ideally group setting based on type of questions]
      if (selectedQuestion.type == "checkbox") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        s.type = "image";
        s.data = getSampleByType(selectedQuestion.type);
      }
      if (
        selectedQuestion.type == "FileUpload" &&
        selectedQuestion.fileType == "image"
      ) {
        s.width = SIZES.isFormMapper.isImageFileUpload.width;
        s.height = SIZES.isFormMapper.isImageFileUpload.height;
        s.type = "image";
        s.data = getSampleByType("imageFileUpload");
      }

      if (selectedQuestion.type == "radiogroup") {
        s.width =
          selectedQuestion.size?.width ?? SIZES[selectedQuestion.type].width;
        s.height =
          selectedQuestion.size?.height ?? SIZES[selectedQuestion.type].height;
        s.type = "image";
        s.data = getSampleByType(selectedQuestion.type);
        s.radiogroupId = selectedQuestion.radiogroupId;
        s.radiogroupColor =
          selectedQuestion.radiogroupColor ?? getRandomColorV1();
      }

      if (selectedQuestion.title == "Initials") {
        s.type = "image";
        s.data = DEFAULT_DATA.initials.defaultAWSLink;
        s.width = SIZES.initials.width;
        s.height = SIZES.initials.height;
      }

      if (selectedQuestion.type == "stamp") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        s.type = "image";
        s.data = getSampleByType(selectedQuestion.type);
      }

      if (selectedQuestion.type == "dropdown") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        s.type = "text";
        s.data = getSampleByType(selectedQuestion.type);
      }

      if (selectedQuestion.type == "name") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        s.type = "text";
        s.data = getSampleByType(selectedQuestion.type);
        s.required = false;
      }

      if (selectedQuestion.type == "email") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        s.type = "text";
        s.data = getSampleByType(selectedQuestion.type);
        s.required = false;
      }

      if (selectedQuestion.type == "number") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        s.type = "text";
        s.data = "";
        s.placeholder = getSampleByType(selectedQuestion.type);
        s.required = true;
      }

      if (selectedQuestion.type == "text") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        // Changing the required to false as these fields are autofilled
        if (
          selectedQuestion.subType == "custom_field" ||
          selectedQuestion.subType == "contact_field"
        ) {
          s.required = false;
          // Changing the data value for custom_field and contact_field (Fetch from the contacts)
          s.data = getContactFieldValue(
            selectedQuestion.email,
            selectedQuestion.contactFieldName,
            s.subType
          );
          s.showKey = convertToTitleCase(selectedQuestion.title);
          if (selectedQuestion.contactFieldName) {
            s.contactFieldName = selectedQuestion.contactFieldName;
          }
        }
      }

      if (selectedQuestion.type == "date") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        s.data = "";
        s.placeholder = getSampleByType(selectedQuestion.type);
      }

      if (selectedQuestion.subType == "variable") {
        s.required = false;
        if (selectedQuestion.variableName) {
          s.variableName = selectedQuestion.variableName;
        }
      }

      if (selectedQuestion.subType == "static_image") {
        // s.data = DEFAULT_DATA.static_image.defaultLink;
        s.width = SIZES[selectedQuestion.subType].width;
        s.height = SIZES[selectedQuestion.subType].height;
        s.type = "image";
        s.data = getSampleByType(selectedQuestion.subType);
      }

      if (selectedQuestion.type == "table") {
        // s.width = SIZES[selectedQuestion.type].width;
        // s.height = SIZES[selectedQuestion.type].height;
        s.type = "table";
        s.subType = selectedQuestion?.subType || "table";
        s.data = getSampleByType(selectedQuestion.type);
        if (isTableSchema(s)) {
          s.headWidthPercentages = [50, 50];
          // s.head = [{ data: "Header 1" }, { data: "Header 2" }];
          s.headStyle = {
            backgroundColor: STATIC_SCHEMA.color,
          };
          // # NORMAL TABLE
          if (selectedQuestion?.subType === "table") {
            s.width = SIZES[selectedQuestion.type].width;
            s.height = SIZES[selectedQuestion.type].height;
            s.head = [{ data: "Header 1" }, { data: "Header 2" }];
            s.rows = [
              [{ data: "Row 1-1" }, { data: "Row 1-2" }],
              [{ data: "Row 2-1" }, { data: "Row 2-2" }],
            ];
          }
          // # PRICING TABLE
          if (selectedQuestion?.subType === "pricing_table") {
            s.width = SIZES[selectedQuestion.subType].width;
            s.height = SIZES[selectedQuestion.subType].height;
            s.head = [
              { data: "Product" },
              { data: "Price" },
              { data: "Quantity" },
              { data: "SubTotal" },
            ];
            s.rows = [
              // [
              //   {
              //     data: "",
              //     header: "Taco",
              //     description:
              //       "Taco is a traditional Mexican dish consisting of a small hand-sized corn- or wheat-based tortilla topped with a filling.",
              //     images: [
              //       "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/product-images/4c178986-2110-430d-b2ff-2927d1b87028.png",
              //     ],
              //   },
              //   { data: "$250" },
              //   { data: "5" },
              //   { data: "$1250" },
              // ],
              // [
              //   {
              //     data: "",
              //     header: "Coffee",
              //     description:
              //       "Meticulously crafted in Italy these classic glass coffee mugs or tea cups will complete any barware collection. Contemporary sleek drinking glasses designed to enhance any décor.",
              //     images: [
              //       "https://boloforms-signatures-bucket.s3.amazonaws.com/signature-dashboard/static-assets/e1c22d02-ca6d-444e-a0f0-c192ef26cc796250x.png",
              //     ],
              //   },
              //   { data: "$150" },
              //   { data: "4" },
              //   { data: "$600" },
              // ],
            ];
            s.headWidthPercentages = [58, 14, 14, 14];
            const currency =
              designerState.settings.documentValue.amount.currency ||
              getDefaultCurrency();
            s.subtotalMenu = {
              // AY this might be causing the problem in generation -------------------------
              symbol: getSymbolFromCurrency(currency),
              currency: currency,
              subtotal: {
                amount: 0,
                data: formatPrice({ amount: 0, currency }),
              },
              discount: {
                amount: 0,
                percentage: 0,
                data: formatPrice({ amount: 0, currency }),
                format: "absolute",
                show2Rec: true,
              },
              fee: {
                amount: 0,
                percentage: 0,
                data: formatPrice({ amount: 0, currency }),
                format: "absolute",
                show2Rec: true,
              },
              tax: {
                amount: 0,
                percentage: 0,
                data: formatPrice({ amount: 0, currency }),
                format: "absolute",
                show2Rec: true,
              },
              total: { amount: 0, data: formatPrice({ amount: 0, currency }) },
            };
          }
          // s.headWidthPercentages = [50, 50];
          s.tableBorderColor = "#000000";
          s.tableBorderWidth = 1;
        }
      }

      if (selectedQuestion.type == "line") {
        s.width = SIZES[selectedQuestion.type].width;
        s.height = SIZES[selectedQuestion.type].height;
        s.type = "line";
        s.data = getSampleByType(selectedQuestion.type);
      }
      if (selectedQuestion?.subType === "file_upload") {
        s.data = getSampleByType(selectedQuestion.subType);
        s.width = SIZES[selectedQuestion.subType].width;
        s.height = SIZES[selectedQuestion.subType].height;
      }

      if (selectedQuestion?.isStatic) {
        s.isStatic = !!selectedQuestion?.isStatic;
        s.required = false;
      }
      // code below this line should always be last -------------------------
      // do not write anything below this code
      if (selectedQuestion.role) {
        s.role = selectedQuestion?.role;
        s.signatureColour = selectedQuestion?.role?.colour;
      }
      if (selectedQuestion?.position) {
        s.position = selectedQuestion.position;
      }
    }
    s = applyDefaults(s);
    s = modifyForBlankPage(s);
    commitSchemas(
      designerState?.schemasList[designerState?.pageCursor].concat(s)
    );
    setTimeout(() => onEdit([document.getElementById(s.id)!]));
  };

  const onSortEnd = (sortedSchemas: SchemaForUI[]) => {
    commitSchemas(sortedSchemas);
  };

  const onChangeHoveringSchemaId = (id: string | null) => {
    setHoveringSchemaId(id);
  };

  // Designer state setters -------------------------------------------
  useEffect(() => {
    const id = {
      PDF: fetchedData?.document?.documentId,
      "PDF-TEMPLATE": fetchedData?.document?.documentId,
      FORM: fetchedData?.forms?.[0]?.formId,
    };
    const templateId = {
      PDF: null,
      "PDF-TEMPLATE": fetchedData?.templateId,
      FORM: null,
    };
    // console.log(id);
    globalHelpers.getFetchedDataFromDB(
      id[instanceFrom],
      templateId[instanceFrom]
    );
  }, [fetchedData, instanceFrom, designerState?.instanceFrom]);

  useEffect(() => {
    setDesignerState((prev) => ({ ...prev, instanceFrom: instanceFrom }));
  }, [instanceFrom]);

  useEffect(() => {
    setDesignerState((prev) => ({ ...prev, role: roles || [] }));
  }, [roles]);

  useEffect(() => {
    setDesignerState((prev) => ({
      ...prev,
      receiversList: receiversList || [],
    }));
  }, [receiversList]);

  // ! Main use Effect for fetched data
  useEffect(() => {
    setDesignerState((prev) => ({
      ...prev,
      inputs: inputs,
      mode: PREVIEW_AVAILABLE_STATUS?.includes(fetchedData?.document?.status)
        ? "PREVIEW"
        : "EDITING",
      combinedSidebar: {
        open: true,
        selectedView: PREVIEW_AVAILABLE_STATUS?.includes(
          fetchedData?.document?.status
        )
          ? "PREVIEW"
          : "CONTENT",
      },
      settings: {
        ...prev.settings,
        schemaStyleDefaults: {
          ...fetchedData?.document?.settings?.schemaStyleDefaults,
        },
        signingType: {
          ...fetchedData?.document?.settings?.signingType,
        },
        documentValue: {
          ...prev.settings.documentValue,
          ...fetchedData?.document?.settings?.documentValue,
        },
        customSchema: fetchedData?.document?.settings?.customSchema || [],
      },
      customVariables: fetchedData?.document?.variables || [],
      sentViaSMS: fetchedData?.document?.sentViaSMS || false,
    }));
  }, [fetchedData]);

  useEffect(() => {
    globalHelpers.getOwnerFromDb();
  }, []);

  const debounceSaveWithoutRedirect = useCallback(
    _.debounce(async (designerState_: DesignerState) => {
      // console.log("debounce called");
      const modifiedTemplate = fmtTemplate(
        designerState_?.template,
        designerState_?.schemasList
      );
      setDesignerState((prev) => ({ ...prev, saving: true }));
      onSaveTemplateWithoutRedirect &&
        (await onSaveTemplateWithoutRedirect(modifiedTemplate));
      setDesignerState((prev) => ({ ...prev, saving: false }));
    }, 5000),
    []
  );

  useEffect(() => {
    // console.log("deboucne useffect called");
    debounceSaveWithoutRedirect(designerState);
  }, [designerState?.template, designerState?.schemasList]);
  // Designer state setters end -------------------------------------------

  // useEffect(() => {
  //   console.log("Schema list", designerState.schemasList);
  //   // console.log("Fmt temp", fmtTemp.schemas);
  // }, [designerState.schemasList]);

  // useEffect(() => {
  //   console.log(
  //     "designerState.template.schemas",
  //     designerState.template.schemas
  //   );
  // }, [designerState.template.schemas]);

  // useEffect(() => {
  //   console.log(
  //     "designerState.template.basePdf",
  //     designerState.template.basePdf
  //   );
  // }, [designerState.template.basePdf]);

  if (error) {
    return <Error size={size} error={error} />;
  }
  const onSaveTemplateHandler = async () => {
    // console.log("onSaveTemplateHandler", modifiedTemplate);
    onSaveTemplate && onSaveTemplate(modifiedTemplate);
  };
  return (
    <Root
      ref={newRootRef}
      // size not used
      size={size}
      scale={scale}
      calcHeight={0}
    >
      <div className="flex flex-col max-h-[100%] h-[100%] min-h-[100%]">
        <EditorNavbar
          fetchedData={designerState.fetchedData || fetchedData}
          setReload={setReload}
          setIsPreview={setIsPreview}
          designerState={designerState}
          setDesignerState={setDesignerState}
          globalHelpers={globalHelpers}
          onEdit={onEdit}
          addSchema={addSchema}
          setRequiredFieldsNotFilled={setRequiredFieldsNotFilled}
          setActiveElements={setActiveElements}
        />
        {designerState?.view === "EDITOR" && (
          <div className="flex max-h-[calc(100%_-_56px)] h-[calc(100%_-_56px)] min-h-[calc(100%_-_56px)]">
            <CtlBar
              size={size}
              pageCursor={designerState?.pageCursor}
              pageNum={designerState?.schemasList.length}
              setPageCursor={(p) => {
                if (!mainRef.current) return;
                mainRef.current.scrollTop = getPagesScrollTopByIndex(
                  pageSizes,
                  p,
                  scale
                );
                // setPageCursor(p);
                setDesignerState((prev) => ({ ...prev, pageCursor: p }));
                onEditEnd();
              }}
              zoomLevel={zoomLevel}
              setZoomLevel={(zoom) => {
                if (mainRef.current) {
                  mainRef.current.scrollTop = getPagesScrollTopByIndex(
                    pageSizes,
                    designerState?.pageCursor,
                    scale
                  );
                }
                setZoomLevel(zoom);
              }}
            />
            <div
              className={`flex flex-col transition-all duration-500`}
              style={{
                width: `calc(100% - ${
                  designerState.combinedSidebar.open
                    ? designerState.instanceFrom == "FORM"
                      ? SidebarWidths.open.form
                      : SidebarWidths.open.pdf
                    : designerState.instanceFrom == "FORM"
                    ? SidebarWidths.close.form
                    : SidebarWidths.close.pdf
                }px)`,
              }}
            >
              {!(designerState?.instanceFrom == "FORM") && (
                <EditorSubNavbar
                  designerState={designerState}
                  setDesignerState={setDesignerState}
                  globalHelpers={globalHelpers}
                  changeSchemas={changeSchemas}
                  pageSize={pageSizes[designerState?.pageCursor]}
                  past={past.current}
                  future={future.current}
                  requiredFieldsNotFilled={requiredFieldsNotFilled}
                  setActiveElements={setActiveElements}
                />
              )}
              <div
                className="flex"
                style={{
                  height:
                    designerState?.instanceFrom == "FORM"
                      ? `100%`
                      : `calc(100% - 36px)`,
                }}
              >
                <MiniPdfViewer
                  pageCursor={designerState?.pageCursor}
                  pageNum={designerState?.schemasList.length}
                  // not used in comp
                  height={0}
                  // height={mainRef.current ? mainRef.current.clientHeight : 0}
                  size={size}
                  file={modifiedTemplate.basePdf}
                  setPageIndex={(p) => {
                    if (!mainRef.current) return;
                    mainRef.current.scrollTop = getPagesScrollTopByIndex(
                      pageSizes,
                      p,
                      scale
                    );
                    // setPageCursor(p);
                    // setDesignerState((prev) => ({ ...prev, pageCursor: p }));
                    onEditEnd();
                  }}
                  pages={designerState?.schemasList}
                  designerState={designerState}
                  setDesignerState={setDesignerState}
                  globalHelpers={globalHelpers}
                  backgrounds={backgrounds}
                />
                <Main
                  radioGroupCovers={radioGroupCovers}
                  ref={mainRef}
                  paperRefs={paperRefs}
                  hoveringSchemaId={hoveringSchemaId}
                  onChangeHoveringSchemaId={onChangeHoveringSchemaId}
                  // not used
                  height={0}
                  // height={size.height - RULER_HEIGHT * ZOOM}
                  pageCursor={designerState?.pageCursor}
                  scale={scale}
                  size={size}
                  pageSizes={pageSizes}
                  backgrounds={backgrounds}
                  activeElements={activeElements}
                  schemasList={designerState?.schemasList}
                  changeSchemas={changeSchemas}
                  removeSchemas={removeSchemas}
                  onEdit={onEdit}
                  addSchema={addSchema}
                  // pdfTemplate={{
                  //   setOpen: setPdfTempSidebarOpen,
                  //   open: pdfTempSidebarOpen,
                  //   setSidebarSelectedOption: setSidebarSelectedOption,
                  //   sidebarSelectedOption: sidebarSelectedOption,
                  // }}
                  designerState={designerState}
                  setDesignerState={setDesignerState}
                  instanceFrom={instanceFrom}
                  globalHelpers={globalHelpers}
                />
              </div>
            </div>
            {/* {(instanceFrom == "PDF" || instanceFrom == "FORM") && ( */}
            {instanceFrom == "FORM" && (
              <Sidebar
                hoveringSchemaId={hoveringSchemaId}
                pageCursor={designerState?.pageCursor}
                pageNum={designerState?.schemasList.length}
                onChangeHoveringSchemaId={onChangeHoveringSchemaId}
                // not used in comp
                height={0}
                // height={mainRef.current ? mainRef.current.clientHeight : 0}
                size={size}
                pageSize={pageSizes[designerState?.pageCursor]}
                activeElements={activeElements}
                schemas={designerState?.schemasList[designerState?.pageCursor]}
                changeSchemas={changeSchemas}
                onSortEnd={onSortEnd}
                onEdit={(id: string) => {
                  const editingElem = document.getElementById(id);
                  editingElem && onEdit([editingElem]);
                }}
                onEditEnd={onEditEnd}
                addSchema={addSchema}
                formQuestions={formQuestions}
                receiversList={receiversList}
                // ideally this should all be replaces by designer state but it will be done later
                // will take too much time
                // can make editor unstable
                schemasList={designerState?.schemasList}
                setSchemasList={globalHelpers.setSchemasList}
                onSaveTemplateHandler={onSaveTemplateHandler}
                template={modifiedTemplate}
                isOnlyPdfTemplate={isOnlyPdfTemplate}
                // sendToSigners={sendToSigners}
              />
            )}
            {(instanceFrom == "PDF" || instanceFrom == "PDF-TEMPLATE") && (
              // {instanceFrom == "PDF-TEMPLATE" && (
              <PdfTemplateSidebar
                hoveringSchemaId={hoveringSchemaId}
                pageCursor={designerState?.pageCursor}
                pageNum={designerState?.schemasList.length}
                onChangeHoveringSchemaId={onChangeHoveringSchemaId}
                // not used in comp
                height={0}
                // height={mainRef.current ? mainRef.current.clientHeight : 0}
                size={size}
                pageSize={pageSizes[designerState?.pageCursor]}
                activeElements={activeElements}
                schemas={designerState?.schemasList[designerState?.pageCursor]}
                changeSchemas={changeSchemas}
                onSortEnd={onSortEnd}
                onEdit={(id: string) => {
                  const editingElem = document.getElementById(id);
                  editingElem && onEdit([editingElem]);
                }}
                onEditEnd={onEditEnd}
                addSchema={addSchema}
                formQuestions={formQuestions}
                // schemasList={schemasList}
                // setSchemasList={setSchemasList}
                onSaveTemplateHandler={onSaveTemplateHandler}
                // template={modifiedTemplate}
                // roles={roles}
                isOnlyPdfTemplate={isOnlyPdfTemplate}
                // variablesHelper={variablesHelper}
                fetchedData={designerState.fetchedData || fetchedData}
                // receiversList={receiversList}
                // sendToSigners={sendToSigners}
                // instanceFrom={instanceFrom}
                // sidebarSelectedOption={sidebarSelectedOption}
                // setSidebarSelectedOption={setSidebarSelectedOption}
                // open={pdfTempSidebarOpen}
                // setOpen={setPdfTempSidebarOpen}
                designerState={designerState}
                setDesignerState={setDesignerState}
                globalHelpers={globalHelpers}
              />
            )}
          </div>
        )}
        {designerState?.view === "QUOTE_BUILDER" && (
          <QuoteBuilder
            designerState={designerState}
            setDesignerState={setDesignerState}
          />
        )}
      </div>
      {/* </div> */}
    </Root>
  );
};

export default TemplateEditor;
