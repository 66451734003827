// @ts-nocheck
import { Slide, ToastContainer, toast } from "react-toastify";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import axios from "../../utils/axios";
import { BoloButton } from "../Common/Button/BoloButton";

const CreateWorkSpace = ({ setShow, showData, setWorkSpaces }) => {
  const [workSpaceName, setWorkSpaceName] = useState(showData?.workSpace?.name);
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  const currentPlan = owner?.planhistory[owner?.planhistory?.length - 1];

  const handleCreateWorkSpace = async () => {
    if (!workSpaceName) {
      return toast.error("Work Space name cannot be empty");
    }
    try {
      const { data } = await axios.post("/workspace/create", {
        email: owner?.email,
        workSpaceName: workSpaceName,
      });
      if (data?.success) {
        setWorkSpaces(data?.owner?.workSpaces);
        localStorage.setItem("owner", JSON.stringify(data?.owner));
        toast.success("WorkSpace Created");
        setShow && setShow(false);
      } else {
        toast.error("Something went wrong");
      }
      setShow && setShow(false);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const handleRenameWorkSpace = async () => {
    if (!workSpaceName) {
      return toast.error("Work Space name cannot be empty");
    }
    try {
      const { data } = await axios.post("/workspace/rename", {
        workSpaceId: showData?.workSpace?.workSpaceId,
        email: owner?.email,
        workSpaceName: workSpaceName,
      });

      if (data?.success) {
        setWorkSpaces(data?.owner?.workSpaces);
        localStorage.setItem("owner", JSON.stringify(data?.owner));
        window.dispatchEvent(new Event("storage"));
        toast.success("WorkSpace renamed");
        setShow && setShow(false);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />{" "}
          <div className="text-base md:text-lg mb-4 font-semibold">
            {showData?.rename
              ? "Rename WorkSpace"
              : `Create WorkSpace ${currentPlan?.usedQuanity?.[7] || 1}/${
                  currentPlan?.usageQuantityAllowed?.[7] || 1
                }`}
          </div>
          <div className="flex justify-between flex-col">
            <div className="flex flex-col gap-3">
              <input
                type="text"
                value={workSpaceName}
                placeholder="WorkSpace Name"
                className="border px-4 py-2 rounded-lg w-full text-xs md:text-sm outline-none"
                onChange={(e) => {
                  setWorkSpaceName(e.target.value);
                }}
              />
              {showData?.create &&
                currentPlan?.usedQuanity?.[7] >=
                  currentPlan?.usageQuantityAllowed?.[7] && (
                  <div className="text-xs bg-gray-100 rounded p-2">
                    <span className="font-semibold">Disclaimer:</span> A new
                    workspace will be added to your account. Please be advised
                    that commencing the next billing cycle, associated charges
                    will be applied for the utilization of this added workspace.
                  </div>
                )}
            </div>
            <div className="flex justify-end gap-4 mt-6">
              <BoloButton
                size={"sm"}
                disabled={false}
                // className="text-xs md:text-sm text-white bg-primary px-4 py-2 rounded-md"
                onClick={() => {
                  showData?.rename
                    ? handleRenameWorkSpace()
                    : handleCreateWorkSpace();
                }}
              >
                {showData?.rename ? "Rename" : "Create"}
              </BoloButton>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default CreateWorkSpace;
