import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import axios from "axios";
import { Slide, ToastContainer, toast } from "react-toastify";
import {
  BASE_URL,
  FIREBASE_CONFIG,
  getDashboardUrl,
  getDashboardUrlV1,
} from "../../constants/constants";
import { validateEmail } from "../../utils/validateEmail";
import { errors } from "../../constants/firebaseErrors";
import Reviews from "../../components/Reviews";
import DataPrivacyListAndLinks from "../../components/DataPrivacyListAndLinks";
import { FcGoogle } from "react-icons/fc";
import { MdEmail } from "react-icons/md";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import { getWhiteLabelInfoLocal } from "../../utils";
import { IMAGE_LINKS } from "../../constants/common";
// import axios from "../../utils/axios";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import {
  get2FAInfo,
  updateLoginInfo,
  updateLoginInfoOnlyBackend,
} from "../../redux/api/owner";
import { Login2FAModal } from "../Login/Login2FA";
import LoginReviews from "../Login/LoginReviews";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import Tracking from "../../utils/tracking";
import GoogleOneTapSignIn from "../../components/GoogleOneTapSignIn";
// / Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const whiteLabelInfo = getWhiteLabelInfoLocal();
const app = initializeApp(
  whiteLabelInfo?.settings?.organization?.firebase?.config || FIREBASE_CONFIG
);
const provider = new GoogleAuthProvider();
const auth = getAuth();

const Register = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const whiteLabaleRoot = useAppSelector((state) => state.root.whiteLabelSlice);

  const [user, setUser] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [isRegister, setIsRegister] = useState(true);

  const fromParam = searchParams.get("from");
  const workspaceId = searchParams.get("workspaceId");
  const ownerEmailParam = searchParams.get("ownerEmail");
  const [userType, setUserType] = React.useState("owner"); //? Did not know what to set
  const [twoFA, setTwoFA] = useState<{
    show: boolean;
    data: { result: any; method: string };
  }>({
    show: false,
    data: { result: null, method: "" },
  });
  useEffect(() => {
    if (workspaceId) {
      localStorage.setItem("workspaceid", workspaceId);
      localStorage.setItem("collaboratorWorkspaceId", workspaceId);
    }
  }, [workspaceId]);

  async function checkForOwnerAndSave(user: any) {
    try {
      let timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
      if (!localStorage.getItem("accountType"))
        localStorage.setItem("accountType", "admin");
      let userType = localStorage.getItem("accountType");
      let sourceType = localStorage.getItem("from") || "GSUITE";
      console.log({
        email: user.email,
        name: user.displayName || "",
        profilePic: user.photoURL || "",
        phone: user.phoneNumber || "",
        userType: userType,
        timezone,
        from: sourceType,
      });
      let { data } = await axios.post(
        `${BASE_URL}/owners`,
        {
          email: user.email,
          name: user.displayName || "",
          profilePic: user.photoURL || "",
          phone: user.phoneNumber || "",
          userType: userType,
          timezone,
          from: sourceType,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("x-auth-token"),
          },
        }
      );
      console.log("data", data);

      if (data?.owner) {
        localStorage.setItem("user", JSON.stringify(data.owner));
        localStorage.setItem("currentUserEmail", user.email);
        let copytemplateId = localStorage.getItem("copytemplateId");
        console.log("copytemplateId", copytemplateId);
        if (userType === "approver") {
          let approvers = data?.owner?.approvers;
          let isApprovalTimeline = approvers?.filter(
            (approver: any) => approver.email === user.email
          )?.[0]?.approvalTimeline;
          localStorage.setItem("isApprovalTimeline", isApprovalTimeline);
        }
        let activeView = localStorage.getItem("activeView");
        // let redirectUrl =
        //   activeView === "REQUESTER"
        //     ? `/filled-forms?from=${sourceType}`
        //     : `/dashboard/forms?from=${sourceType}&copytemplateId=${copytemplateId}`;

        let redirectUrl =
          activeView === "REQUESTER"
            ? `/filled-forms?from=${sourceType}`
            : `/dashboard/forms`;
        // navigate(redirectUrl);
        // console.log(redirectUrl);

        // set tracking data
        new Tracking().trackSignUp(user.email);
        window.open(redirectUrl, "_self");
      } else {
        toast(`You're not ${userType}`, {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function setUserTypeLocal(type: any) {
    localStorage.setItem("accountType", type);
  }

  function sendVerificationEmail() {
    // @ts-ignore
    let { uid, refreshToken, email } = auth.currentUser;

    var actionCodeSettings = {
      url: `${getDashboardUrlV1()}/login?uid=${uid}&email=${email}&accessToken=${refreshToken}`,
    };
    // sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => {
    //     // Email verification sent!
    //     console.log("Email Verification sent! Check your mail box");
    //     toast.success("Email Verification sent! Check your mail box");
    //   });
    if (auth.currentUser !== null) {
      sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => {
        // Email verification sent!
        console.log("Email Verification sent! Check your mail box");
        toast.success("Email Verification sent! Check your mail box");
      });
    }
  }

  const handleRegisterUser = async () => {
    let { email, password, confirmPassword } = user;

    if (!validateEmail(email)) return toast.error("Please enter valid email");
    if (password.trim() === "" || confirmPassword.trim() === "")
      return toast.error("Please enter password");

    // password must contain one Captial letter, one small letter, one number and one special character and minimum 8 characters
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegex.test(password))
      return toast.error(
        "Password must contain one Captial letter, one small letter, one number and one special character and minimum 8 characters"
      );

    if (password !== confirmPassword)
      return toast.error("Password and confirm password should be same");

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user", user);
        sendVerificationEmail();
        setUser({
          email: "",
          password: "",
          confirmPassword: "",
        });
        new MixPanel().track("signup", { method: "email_password" });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        console.log("error", errorMessage);
        // toast.error(errors[error.code] || error.message);
        toast.error(error.message);
      });
  };

  // async function updateLoginInfo(result: any, method: string) {
  //   const user = result.user;
  //   let referrer = localStorage.getItem("ref");
  //   let refReward = localStorage.getItem("refReward");
  //   let timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  //   if (refReward) {
  //     referrer = null;
  //     localStorage.setItem("ref", "");
  //   }
  //   localStorage.setItem("refReward", "");
  //   // createOwner
  //   try {
  //     let { data } = await axios.post(`${BASE_URL}/owners`, {
  //       email: user.email,
  //       name: user.displayName,
  //       profilePic: user.photoURL,
  //       phone: user.phoneNumber,
  //       from: fromParam,
  //       referrer,
  //       timezone,
  //       accessToken: result?.user?.accessToken,
  //       projectId:
  //         whiteLabelInfo?.settings?.organization?.firebase?.config?.projectId ||
  //         FIREBASE_CONFIG?.projectId,
  //     });
  //     // console.log("data", data);

  //     if (data?.owner) {
  //       new MixPanel().track("signup", { method: method });
  //       localStorage.setItem("owner", JSON.stringify(data.owner));
  //       localStorage.setItem("currentUserEmail", user.email);
  //       localStorage.setItem("accountType", userType);
  //       localStorage.setItem("x-auth-token", data?.["x-auth-token"]);
  //       window.open("/dashboard/forms", "_self");
  //       // navigate("/dashboard");
  //     } else {
  //       // toast(`You're not ${userType}`, {
  //       //   type: "error",
  //       // });
  //       toast(`Something went wrong! Please try again`, {
  //         type: "error",
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    if (ownerEmailParam) {
      provider.setCustomParameters({
        login_hint: ownerEmailParam ?? "",
      });
    }
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.

        await updateLoginInfoOnlyBackend(result, "google");
        let twoFaInfo = await get2FAInfo({ email: result?.user?.email || "" });
        if (twoFaInfo.enabled && twoFaInfo.verified) {
          setTwoFA((prev) => ({
            ...prev,
            data: { result: result, method: "google" },
            show: true,
          }));
        } else {
          await updateLoginInfo(result, "google");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signInWithMicrosoft = () => {
    const provider = new OAuthProvider("microsoft.com");
    if (ownerEmailParam) {
      provider.setCustomParameters({
        prompt: "consent",
        login_hint: ownerEmailParam ?? "",
      });
    }

    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Microsoft Access Token. You can use it to access the Microsoft API.
        // console.log(result);

        await updateLoginInfoOnlyBackend(result, "microsoft");
        let twoFaInfo = await get2FAInfo({ email: result?.user?.email || "" });
        if (twoFaInfo.enabled && twoFaInfo.verified) {
          setTwoFA((prev) => ({
            ...prev,
            data: { result: result, method: "microsoft" },
            show: true,
          }));
        } else {
          await updateLoginInfo(result, "microsoft");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  useEffect(() => {
    if (ownerEmailParam) {
      const isValidEmail = validateEmail(ownerEmailParam ?? "");
      if (isValidEmail) {
        setUser((prev) => ({
          ...prev,
          email: ownerEmailParam ?? "",
        }));
      } else {
        console.log("Email address is not valid");
      }
    }
  }, [fromParam, ownerEmailParam]);

  useEffect(() => {
    setUserType(
      searchParams.get("type") === "collaborator" ? "collaborator" : "owner"
    );
  }, []);

  useEffect(() => {
    //  checking for template copying
    let templateId = searchParams.get("copytemplateId");
    let copytemplateId = localStorage.getItem("copytemplateId");
    if (!copytemplateId || copytemplateId == "null") {
      localStorage.setItem("copytemplateId", templateId || "null");
    }

    let user = localStorage.getItem("x-auth-token");
    if (user) {
      let activeView = localStorage.getItem("activeView");
      // let redirectUrl =
      //   activeView === "REQUESTER"
      //     ? `/filled-forms`
      //     : `/forms?copytemplateId=${localStorage.getItem(
      //         "copytemplateId"
      //       )}`;
      let redirectUrl =
        activeView === "REQUESTER" ? `/filled-forms` : `/dashboard/forms`;

      navigate(redirectUrl);
    }

    let activeView = searchParams.get("view");
    localStorage.setItem("isMergeDash", "true");
    if (
      activeView === "requester" ||
      activeView === "approver" ||
      activeView === "owner" ||
      activeView === "collaborator"
    ) {
      console.log("activeView", activeView);
      localStorage.setItem("activeView", activeView.toUpperCase());
    }

    if (JSON.parse(searchParams.get("isauth") || "null")) {
      let user = {
        email: searchParams.get("ownerEmail"),
      };
      // console.log("searchParams", (searchParams.get("x-auth-token")));
      localStorage.setItem(
        "x-auth-token",
        searchParams.get("x-auth-token") || "null"
      );
      console.log(localStorage.getItem("x-auth-token"));
      checkForOwnerAndSave(user);
    }
    let userType = searchParams.get("type");
    let from = searchParams.get("from");
    if (userType) {
      setUserTypeLocal(userType);
    }

    // setSource(from);
    if (!localStorage.getItem("from"))
      localStorage.setItem("from", from ?? "DASHBOARD");
  }, []);

  if (whiteLabaleRoot?.loading) {
    return (
      <div className="h-[100vh] w-[100vw] flex items-center justify-center">
        <BoloLoader />
      </div>
    );
  }

  return (
    <div className="fade w-full h-screen flex flex-row items-center justify-center relative">
      <Helmet>
        <title translate="no">Register</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Register" />
        <meta
          name="description"
          content="Sign up for BoloForms to unlock streamlined document workflows! Secure your digital signatures, personalize with drag-and-drop customization, and seamlessly collaborate. Join now to embrace the digital age and start signing with ease!"
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/register`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Register - Boloforms`} />
        <meta
          property="og:description"
          content="Sign up for BoloForms to unlock streamlined document workflows! Secure your digital signatures, personalize with drag-and-drop customization, and seamlessly collaborate. Join now to embrace the digital age and start signing with ease!"
        />
        <link rel="canonical" href="https://signature.boloforms.com/register" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <Login2FAModal
        show={twoFA.show}
        setShow={(value) => setTwoFA((prev) => ({ ...prev, show: value }))}
        userData={twoFA.data}
      />
      <GoogleOneTapSignIn />
      {/* <div className="h-full w-full hidden lg:flex flex-col justify-center relative z-10 overflow-hidden border-r">
        <div className="w-[90%] mx-auto mb-4">
          <h2 className="text-2xl mb-2 font-medium">
            Let us help you automate your{" "}
            <span className="text-primary font-semibold">
              business processes
            </span>{" "}
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            BoloForms is a no-code platform that helps you automate your
            business processes and workflows. It is a simple, easy-to-use
            platform that helps you create forms, collect data, and automate
            your business processes.
          </p>
        </div>
        <Reviews />
        <DataPrivacyListAndLinks />
      </div> */}
      {/* <div className="hidden lg:flex h-full w-full flex-col justify-center relative z-10 overflow-hidden border-r">
        <div className="w-[90%] mx-auto mb-4">
          <h2 className="text-2xl mb-2 font-medium">
            Sign & Agree, the easy way.{" "}
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            BoloForms is an easy-to-use eSignature platform to sign, send, and
            manage contract/business agreements.
          </p>
        </div>
        <Reviews />
        <DataPrivacyListAndLinks />
      </div> */}
      <LoginReviews />
      <div className="bg-white w-full px-4 lg:px-0 h-full flex justify-center items-center min-h-screen">
        <div className="w-[430px] mx-auto flex flex-row">
          <div className="w-full px-2 sm:px-6 py-7 flex flex-col bg-white">
            <div className="pb-8 w-full h-full flex flex-col justify-end items-start text-black mt-4">
              {fromParam == "APPSUMO" && (
                <div className="w-full">
                  <div className="bg-gradient-to-r from-[#072E30] to-[#054748] py-2 text-sm text-white rounded-[3px] mb-4 flex items-center justify-center gap-4">
                    Taco 'bout a great deal, right?{" "}
                    <img
                      src="https://i.imgur.com/6sPaqt6.png"
                      height={25}
                      width={25}
                      alt=""
                    />
                  </div>
                  <h2 className="font-bold text-3xl w-full text-center mb-10">
                    Welcome Sumo-ling!
                  </h2>
                </div>
              )}
              <h2 className="text-xl capitalize mb-2 w-full text-center">
                Register with us to{" "}
                <span className="text-primary font-bold">get started</span>
              </h2>
              <p className="block lg:hidden text-xs text-center w-full">
                Unlimited signatures, templates, forms, and team members
              </p>
              <p className="block lg:hidden text-xs text-center w-full">
                One fixed price. No extra charges, ever
              </p>
            </div>
            <div className="w-full flex flex-col">
              {isRegister && (
                <>
                  <label className="text-xs mb-1 text-gray-700">Email</label>
                  <input
                    type="email"
                    className={`text-gray-700 w-full p-2.5 rounded-md border-[1.5px] focus:border-primary focus:outline-none text-sm ${
                      fromParam === "APPSUMO" &&
                      validateEmail(ownerEmailParam ?? "")
                        ? "!bg-gray-100 !text-gray-500"
                        : ""
                    }`}
                    placeholder="Enter your email"
                    value={user.email}
                    onChange={(e) => {
                      setUser((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                    }}
                    disabled={
                      fromParam === "APPSUMO" &&
                      Boolean(validateEmail(ownerEmailParam ?? ""))
                    }
                  />
                  <label className="text-xs mt-4 mb-1 text-gray-700">
                    Password
                  </label>
                  <input
                    type="password"
                    className="text-gray-700 w-full p-2.5 rounded-md border-[1.5px] focus:border-primary focus:outline-none text-sm"
                    placeholder="Enter your password"
                    value={user.password}
                    onChange={(e) => {
                      setUser((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }));
                    }}
                  />
                  <label className="text-xs mt-4 mb-1 text-gray-700">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="text-gray-700 w-full p-2.5 rounded-md border-[1.5px] focus:border-primary focus:outline-none text-sm"
                    placeholder="Enter your password"
                    value={user.confirmPassword}
                    onChange={(e) => {
                      setUser((prev) => ({
                        ...prev,
                        confirmPassword: e.target.value,
                      }));
                    }}
                  />
                  <p className="text-xs text-gray-400 mt-2">
                    Password must contain one captial letter, one small letter,
                    one number and one special character and minimum 8
                    characters
                  </p>
                  <div className="flex items-center mt-4">
                    {/* <input
                  type="checkbox"
                  className="mr-4 text-gray-700 p-2.5 rounded-md border-[1.5px] focus:border-primary focus:outline-none text-sm"
                  checked={user.agreeTerms}
                  onChange={(e) => {
                    setUser((prev) => ({
                      ...prev,
                      agreeTerms: e.target.checked,
                    }));
                  }}
                /> */}
                    <p
                      style={{
                        lineHeight: "1.2rem",
                      }}
                      className="text-[10px] md:text-xs text-gray-700"
                    >
                      By clicking “Register” below, you agree to the{" "}
                      <a
                        href={
                          whiteLabelInfo?.settings?.organization?.policy?.tos ||
                          "https://www.boloforms.com/terms-of-service/"
                        }
                        className="text-primary font-semibold"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        href={
                          whiteLabelInfo?.settings?.organization?.policy
                            ?.privacy ||
                          "https://www.boloforms.com/privacy-policy/"
                        }
                        className="text-primary font-semibold"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  </div>{" "}
                  <div className="flex flex-row items-center mt-2">
                    <BoloButton
                      onClick={handleRegisterUser}
                      className="w-full"
                      // className="w-full p-2 rounded-md bg-primary text-white focus:outline-none hover:opacity-90"
                    >
                      Register
                    </BoloButton>
                  </div>
                  <div className="flex items-center">
                    <div className="w-full border mr-4 h-fit my-4"></div>
                    <span className="text-gray-600 text-xs font-semibold">
                      OR
                    </span>
                    <div className="w-full border ml-4 h-fit"></div>
                  </div>
                </>
              )}
              <div className="flex flex-col items-center">
                <BoloButton
                  variant={"secondary"}
                  orientation={"center"}
                  className="w-full shadow-md hover:bg-secondary !text-black"
                  onClick={signInWithGoogle}
                  type="button"
                  // className="w-full my-2 flex flex-row items-center justify-center py-2 px-4 p rounded-md bg-white border-[1px] border-gray-200 cursor-pointer hover:bg-secondary hover:text-secondary-text hover:border-secondary shadow-md"
                >
                  <FcGoogle className="text-3xl" />
                  <label className="ml-4 w-[160px] text-left text-sm font-medium cursor-pointer">
                    Register with Google
                  </label>
                </BoloButton>
                <BoloButton
                  variant={"secondary"}
                  orientation={"center"}
                  className="w-full shadow-md hover:bg-secondary !text-black mt-3"
                  onClick={signInWithMicrosoft}
                  type="button"
                  // className="w-full my-2 flex flex-row items-center justify-center py-2 px-4 p rounded-md bg-white border-[1px] border-gray-200 cursor-pointer hover:bg-secondary hover:text-secondary-text hover:border-secondary shadow-md"
                >
                  <span className="py-[3px] pointer-events-none">
                    <object
                      type="image/svg+xml"
                      data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                      className="x-icon w-6 h-6"
                      aria-label="Microsoft"
                    ></object>
                  </span>
                  <label className="ml-5 w-[160px] text-left text-sm font-medium cursor-pointer">
                    Register with Microsoft
                  </label>
                </BoloButton>
                {/* <BoloButton
                  variant={"secondary"}
                  orientation={"center"}
                  className="w-full shadow-md hover:bg-secondary !text-black mt-3"
                  onClick={() => {
                    setIsRegister(true);
                  }}
                  // className="w-full my-2 flex flex-row items-center justify-center py-2 px-4 p rounded-md bg-white border-[1px] border-gray-200 cursor-pointer hover:bg-secondary hover:text-secondary-text hover:border-secondary shadow-md"
                >
                  <MdEmail className="text-3xl text-gray-700" />
                  <label className="ml-4 w-[160px] text-left  text-sm font-medium cursor-pointer">
                    Register with Email
                  </label>
                </BoloButton> */}
              </div>
            </div>
            <div className="mt-4">
              <p className="text-xs text-gray-500 text-center">
                Already have an account?{" "}
                <Link to="/login?signin=email" className="text-primary">
                  Login
                </Link>
              </p>
            </div>
            {/* <div className="flex items-center my-6">
              <div className="w-full border mr-4 h-fit"></div>
              <span className="text-gray-600 text-xs font-semibold">OR</span>
              <div className="w-full border ml-4 h-fit"></div>
            </div>

            <OtherSignInMethods /> */}
          </div>
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default Register;
