//@ts-nocheck
// import axios from "axios";
import axios from "./axios";
import { BASE_URL, getFormViewerUrl } from "../constants/constants";
import { toast } from "react-toastify";
import { getWorkSpaceDetailsHookHelper } from "../hooks/reduxHooks/useGetWorkSpaceDetails";
import { getOwnerPlanDetailsHookHelper } from "../hooks/reduxHooks/useOwnerPlanDetails";
import MixPanel from "./services/mixpanel";
import { WhiteLabelOwnerType } from "../redux/slices/whiteLabelSlice";
import {
  WhiteLabelHelperType,
  whiteLabelHookHelper,
} from "../hooks/reduxHooks/useWhiteLabelHelper";
import { getEmbedHome, isEmbeded } from "./embed";
export const isAdminPermission = async (owner = null) => {
  if (!owner) {
    owner = await fetchOwner();
  }

  let currentUserEmail =
    localStorage.getItem("currentUserEmail") ?? owner?.email;

  let collaborator = owner?.collaborators?.find(
    (c) => c.email === currentUserEmail
  );

  return currentUserEmail === owner?.email
    ? true
    : collaborator?.role === "admin";
};

export const getCollaboratorPermission = async (ownerId) => {
  let owner = await fetchOwner(ownerId);

  let currentUserEmail =
    localStorage.getItem("currentUserEmail") ?? owner?.emails?.[0];

  let collaborator = owner?.collaborators?.find(
    (c) => c.email === currentUserEmail
  );

  return collaborator;
};

export const fetchOwner = async (id?: string) => {
  let isOwnerAvailable = JSON.parse(localStorage.getItem("owner"))?._id;
  if (!isOwnerAvailable) {
    console.log("No owner found");
    localStorage.removeItem("owner");
    localStorage.removeItem("currentUserEmail");
    window.location.href = "/login";
    return;
    // window.location.reload();
  }

  let user = JSON.parse(localStorage.getItem("owner") ?? "");
  try {
    let { data } = await axios.get(`${BASE_URL}/owners?id=${id ?? user._id}`);
    // console.log("===================", data.success, data.owner);
    if (data.success === false) {
      console.log("No owner found");
      localStorage.removeItem("owner");
      localStorage.removeItem("currentUserEmail");
      window.location.reload();
    }
    return data.owner;
  } catch (error) {
    localStorage.removeItem("owner");
  }
};

export async function updateLoginInfo(result: any) {
  const user = result.user;
  let referrer = localStorage.getItem("ref");
  let refReward = localStorage.getItem("refReward");

  if (refReward) {
    referrer = null;
    localStorage.setItem("ref", "");
  }
  localStorage.setItem("refReward", "");
  // createOwner
  try {
    let { data } = await axios.post(`${BASE_URL}/owners`, {
      email: user?.email,
      name: user?.displayName,
      profilePic: user?.photoURL,
      phone: user?.phoneNumber,
      referrer,
    });
    // console.log("data", data);

    if (data?.owner) {
      localStorage.setItem("owner", JSON.stringify(data.owner));
      localStorage.setItem("currentUserEmail", user.email);
      localStorage.setItem("accountType", userType);

      return true;
      //  navigate("/create");
    } else {
      return false;
      // toast(`Something went wrong! Please try again`, {
      //   type: "error",
      // });
    }
  } catch (error) {
    console.log(error);
  }
}

// generate unique id
export function uid() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export const isFormCustomer = async () => {
  let user = JSON.parse(localStorage.getItem("owner"));
  if (user?.from?.includes("TEMPLATES")) {
    return true;
  }
  return false;
};

export function getWorkSpaceDetails() {
  let user = JSON.parse(localStorage.getItem("owner"));
  let data = getWorkSpaceDetailsHookHelper(user);
  return data;
  // const currentWorkSpaceId =
  //   localStorage.getItem("workspaceid") || user.currentWorkSpaceId;
  // // can add logout functionality here if currentWorkSpaceId is undefined
  // // ------------------------------------------------------------------------------------------------
  // // console.log({ currentWorkSpaceId });
  // if (!currentWorkSpaceId || currentWorkSpaceId == "undefined") {
  //   localStorage.removeItem("owner");
  //   localStorage.removeItem("workspaceid");
  //   localStorage.removeItem("currentUserEmail");
  //   localStorage.removeItem("accountType");
  //   localStorage.removeItem("isApprovalTimeline");
  //   localStorage.removeItem("formColumnPrefrences");
  //   localStorage.removeItem("respondentPageNo");
  //   localStorage.removeItem("x-auth-token");
  //   window.location.href = "/login";
  // }

  // const currentWorkSpace = user?.workSpaces?.find(
  //   (workspace) => workspace?.workSpaceId == currentWorkSpaceId
  // );
  // const isWorkSpaceOwner = user?.isWorkSpaceOwner;
  // const currentWorkSpaceRole = user?.currentWorkSpaceRole;
  // let formattedRole = "";
  // if (isWorkSpaceOwner) {
  //   formattedRole = "Owner";
  // }
  // if (currentWorkSpaceRole == "readonly") {
  //   formattedRole = "Read Only";
  // }
  // if (currentWorkSpaceRole == "editor") {
  //   formattedRole = "Editor";
  // }
  // if (currentWorkSpaceRole == "admin") {
  //   formattedRole = "Administrator";
  // }
  // return {
  //   currentWorkSpace,
  //   currentWorkSpaceId,
  //   isWorkSpaceOwner,
  //   currentWorkSpaceRole,
  //   formattedRole,
  // };
}

export function isCollaboratorInWorkspace(email: string): boolean {
  const workspaceData = getWorkSpaceDetails();
  const workSpaceCollaborators =
    workspaceData?.currentWorkSpace?.collaborators || [];
  let isInWorkspace = false;
  workSpaceCollaborators.forEach((collaborator: any) => {
    let collaboratorValue = collaborator.collaboratorId;
    if (collaboratorValue.email === email) {
      isInWorkspace = true;
    }
  });
  return isInWorkspace;
}

export const getOwnerPlanDetails = () => {
  let user = JSON.parse(localStorage.getItem("owner"));
  let data = getOwnerPlanDetailsHookHelper(user);
  return data;
  // let currentPlan = user?.planhistory[user?.planhistory?.length - 1];
  // let workSpacePlan = user?.paymentStatus?.workSpacePlanHistory;
  // // we also consider anyone having from appsumo to have a lifetime plan
  // let isLifeTime =
  //   currentPlan?.tier?.includes("BOLOFORMS_LIFETIME") ||
  //   user?.from == "APPSUMO";
  // return {
  //   isAppSumo: user?.from == "APPSUMO",
  //   tier: currentPlan?.tier,
  //   currentPlan,
  //   workSpacePlan,
  //   isLifeTime,
  // };
};

export const getContactDetails = () => {
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  const contacts = owner?.contacts;
  return { contacts };
};

export const setContactDetails = (contacts) => {
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  owner.contacts = contacts || [];
  localStorage.setItem("owner", JSON.stringify(owner));
};

export const logOut = (noNavigate?: boolean) => {
  new MixPanel().track("logout");
  localStorage.removeItem("owner");
  localStorage.removeItem("workspaceid");
  localStorage.removeItem("currentUserEmail");
  localStorage.removeItem("accountType");
  localStorage.removeItem("isApprovalTimeline");
  localStorage.removeItem("formColumnPrefrences");
  localStorage.removeItem("respondentPageNo");
  localStorage.removeItem("x-auth-token");
  // embed stuff
  // localStorage.removeItem("embed-session-inputs");
  // localStorage.removeItem("is-embeded");
  // localStorage.removeItem("x-embed-token");

  if (noNavigate) {
    return;
  }

  // localStorage.removeItem("activeView");
  if (isEmbeded()) {
    window.location.href = getEmbedHome();
    return;
  }
  window.location.href = "/login";
};

export const partnerLogOut = () => {
  localStorage.removeItem("x-partner-auth");
  window.location.href = "/partner/login";
};

// not used as of now
export const handleCreateFormGlobal = async (
  formData: {
    id: string;
    name: string;
    desc: string;
    formJson: any;
  },
  ownerId: string,
  redirectUrl: string = "/forms/edit",
  renderer: string = "BLANK"
) => {
  let formName = "Untitled Form";
  if (formName === "" && renderer === "BLANK") {
    return toast.error("Please enter a valid form name");
  }

  let formUrl =
    redirectUrl?.split("/").length > 3
      ? `${getFormViewerUrl()}/${redirectUrl?.split("/")[3]}/${
          formData?.id
        }?p=view`
      : `${getFormViewerUrl()}/${formData.id}?p=view`;

  let currentUserEmail = localStorage.getItem("currentUserEmail");

  try {
    let apiData: any = {
      id: formData?.id,
      name: formData?.name || "Untitled Form",
      desc: formData?.desc || "No description",
      // destinationId: null,
      // spreadsheetUrl: null,
      authorEmail: currentUserEmail,
      formUrl,
      // @ts-ignore
      ownerId: ownerId,
      oAuthToken: null,
      formInputFields: [],
      formType: "BOLOFORMS",
      // writeSpreadsheet: JSON.parse(formData.connectSpreadsheet),
      // writeSpreadsheet: formData.connectSpreadsheet,
    };

    if (formData?.formJson) {
      apiData.formJson = formData?.formJson;

      let responseItemsId: any = {};
      let formInputFields: string[] = [];
      for (const page of apiData.formJson.pages) {
        for (const question of page.questions) {
          delete question["chosen"];
          delete question["selected"];
          responseItemsId[question.id] = question.title;
          formInputFields.push(question.title);
          if (question.title.trim() === "") {
            toast.error("Please fill all the question titles");
            return;
          }
        }
      }
      apiData.responseItemsId = responseItemsId;
      apiData.formInputFields = formInputFields;
    }

    try {
      const { data } = await axios.post("/forms", apiData);

      console.log("data", data);

      return {
        success: true,
        data: data,
      };
    } catch (error: any) {
      console.log(error);
      toast.error(error?.message);
      return {
        error: error?.message,
        success: false,
      };
    }
  } catch (error: any) {
    console.log(error);
    // toast.error("Something went wrong, create page: line 190 : test");
    toast.error(error?.message);
    return {
      error: error?.message,
      success: false,
    };
  }
};
export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getOnwerFromDB() {
  try {
    let user = JSON.parse(localStorage.getItem("owner") ?? "{}");
    let { data } = await axios.get(`${BASE_URL}/owners?id=${user?._id}`);
    // console.log("===================", data.success, data.owner);
    return data?.owner;
  } catch (error) {
    // localStorage.removeItem("owner");
    console.log("Error in getOnwerFromDB");
    console.log(error);
  }
}

export function getWhiteLabelInfoLocal(): WhiteLabelOwnerType | null {
  try {
    let info = JSON.parse(localStorage.getItem("whiteLabelOwner") || "{}");
    return info;
  } catch (error) {
    console.log("Error in getWhiteLabelInfoLocal");
    console.log(error);
    return null;
  }
}

export function getWhiteLabelHelper(): WhiteLabelHelperType {
  let info = getWhiteLabelInfoLocal();
  return whiteLabelHookHelper(info);
}

export function openExternalLinkUtil(link: any) {
  window.open(link, "_blank");
}

export function removeSpecialCharacters(inputString: string) {
  return inputString.replace(/[^a-zA-Z0-9\s.]/g, "").replace(/\s/g, "_");
}

// checks if I need to show the migration div or not!
// condition: if the plan is lifetime and it's older than 2 weeks, do not show the div
export const shouldShowMigrationDiv = (
  isLifeTime,
  ownerLastPlanHistoryCreatedAt
) => {
  // console.log("ownerLastPlanHistoryCreatedAt", ownerLastPlanHistoryCreatedAt);
  // console.log("isLifeTime", isLifeTime);

  const lastPlanDate = new Date(ownerLastPlanHistoryCreatedAt);
  const currentDate = new Date();
  const diff = currentDate.getTime() - lastPlanDate.getTime();
  const days = diff / (1000 * 60 * 60 * 24);
  const isPlan2WeeksOld = days > 14;

  // If the plan is lifetime and it's older than 2 weeks, do not show the div
  if (isLifeTime && isPlan2WeeksOld) {
    return false;
  }

  // In all other cases, show the div
  return true;
};
