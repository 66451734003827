import React, { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import {
  createConnectedAccountApi,
  disconnectAccountApi,
  getAccountLinkApi,
  updateStripeAccountDataAPI,
} from "../../redux/apis/payments";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ALL_FEATURES } from "../../types/tiers";
import MixPanel from "../../utils/services/mixpanel";

type PaymentGatewayAppsProps = {
  setShowUpgradeModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpgradeModalType: React.Dispatch<React.SetStateAction<ALL_FEATURES>>;
};

const PaymentGatewayApps = ({
  setShowUpgradeModal,
  setUpgradeModalType,
}: PaymentGatewayAppsProps) => {
  const workSpaceDetails = useWorkSpaceDetails();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const featuresEnabled = useIsFeatureEnabled();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);

  const [loading, setLoading] = useState(false);

  const handleUpdateAccountDataApi = async () => {
    try {
      setLoading(true);
      await updateStripeAccountDataAPI();
      setLoading(false);
    } catch (error) {
      console.log("Error in handleUpdateAccountDataApi");
      console.log(error);
    }
  };
  const data = {
    key: "stripe",
    name: "Stripe",
    logo: "https://images.ctfassets.net/fzn2n1nzq965/HTTOloNPhisV9P4hlMPNA/cacf1bb88b9fc492dfad34378d844280/Stripe_icon_-_square.svg?q=80&w=1082",
    isIntegrated:
      workSpaceDetails.stripeData.isStripeAccountActive &&
      workSpaceDetails.stripeData.isStripeConnectedAccount,
    description: "Quick and secure way to accept credit/debit cards online.",
    installFun: async () => {
      setLoading(true);
      if (!featuresEnabled.STRIPE_INTEGRATION) {
        setShowUpgradeModal(true);
        setUpgradeModalType("STRIPE_INTEGRATION");
        return;
      }
      try {
        const data = await createConnectedAccountApi({
          return_url: window.location.toString(),
        });
        let url = data?.accountLink?.url;
        if (url) {
          window.open(url, "_self");
        }
        dispatch(getOwnerThunk());
      } catch (error) {
        toast.error("Something went worong");
        console.log("error in installFun");
        console.log(error);
      }
      setLoading(false);
    },
    linkFun: async () => {
      setLoading(true);
      try {
        let data = await getAccountLinkApi({
          return_url: window.location.toString(),
        });
        let url = data?.url;
        if (data.url) {
          window.open(url, "_self");
        }
      } catch (error) {
        console.log("Error in linkFun");
        console.log(error);
        toast.error("Something went wrong");
      }
      setLoading(false);
    },
    disconnectFunc: async () => {
      setLoading(true);
      try {
        let data = await disconnectAccountApi();
        if (data.success) {
          dispatch(getOwnerThunk());
        }
      } catch (error) {
        console.log("Error in disconnectFunc");
        console.log(error);
        toast.error("Something went wrong");
      }
      setLoading(false);
    },
  };

  useEffect(() => {
    handleUpdateAccountDataApi();
  }, [owner]);
  return (
    <>
      <h3 className="text-md font-semibold ">Payment Gateways</h3>
      <>
        <div
          className="flex flex-col border border-gray-200 shadow-sm rounded-md p-4 w-[45%] lg:w-[250px] cursor-pointer"
          key={data.key}
        >
          <div className="flex justify-between items-center">
            <img src={data.logo} alt={data.name} className="w-10 h-10" />
            {data.isIntegrated && (
              <span className="text-[10px] md:text-[12px] text-green-600 flex font-semibold items-center justify-center">
                {" "}
                <TiTick className="w-4 h-4 md:w-6 md:h-6 " /> Connected
              </span>
            )}
          </div>
          <h3 className="text-lg font-bold mt-4">{data.name}</h3>
          <p className="text-[12px] mt-2 mb-4"> {data.description} </p>
          <div className="flex items-end">
            <span className="text-[10px] text-gray-600">
              {whiteLabelInfo?.settings?.organization?.name
                ? "Powered By BoloForms"
                : ""}
            </span>
            {data?.isIntegrated ? (
              <div
                className="ml-auto mt-2 p-2 font-medium text-sm bg-primary text-white rounded-md disabled:opacity-60"
                onClick={() => data.disconnectFunc()}
              >
                {loading ? "Uninstalling" : "Uninstall"}
              </div>
            ) : (
              <div
                className={`flex gap-2 items-center ml-auto mt-2 p-2 font-medium text-sm bg-primary text-white rounded-md ${
                  loading ? "opacity-60 cursor-default" : ""
                }`}
                onClick={() => {
                  if (loading) {
                    return;
                  }
                  if (!workSpaceDetails?.stripeData?.isStripeConnectedAccount) {
                    data.installFun();
                  } else {
                    if (!workSpaceDetails?.stripeData?.isStripeAccountActive) {
                      data.linkFun();
                    } else {
                      console.log("All set");
                    }
                  }
                }}
              >
                {loading && <TailSpin color="#ffffff" height={16} width={16} />}
                {workSpaceDetails?.stripeData?.isStripeConnectedAccount
                  ? !workSpaceDetails?.stripeData?.isStripeAccountActive
                    ? loading
                      ? "Setting up"
                      : "Setup"
                    : "Installed"
                  : loading
                  ? "Installing"
                  : "Install"}
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default PaymentGatewayApps;
