import { useCallback, useRef, useState, useEffect } from "react";
// import { PreviewReactProps, SchemaForUI } from '@pdfme/common';
import { ZOOM, RULER_HEIGHT, STANDARD_DATA } from "../constants";
import UnitPager from "./UnitPager";
import Root from "./Root";
import Error from "./Error";
import CtlBar from "./CtlBar/index";
import RequiredBar from "./RequiredBar";
import Paper from "./Paper";
import SchemaUI from "./Schemas/SchemaUI";
import { useUIPreProcessor, useScrollPageCursor } from "../hooks";
import {
  templateSchemas2SchemasList,
  getPagesScrollTopByIndex,
} from "../helper";
import { PreviewReactProps, SchemaForUI } from "../../../common/src";
import MiniPdfViewer from "./Designer/MiniPdfViewer";
import LeftSideBar from "./Designer/LeftSideBar";
import { PreviewFetchedType } from "../../../common/src/type";
import { getWhiteLabelInfoLocal } from "../../../../utils";
import { checkAndSetEnabledKey } from "../../../../utils/uiUtils";
import { COLORS } from "../../../../constants/common";
import ActiveElmArrow from "./ActiveElmArrow/ActiveElmArrow";

export function focusOnElement(schemaId: string) {
  let targetElement = document.querySelector(`[data-schema-id="${schemaId}"]`);

  if (targetElement instanceof HTMLElement) {
    targetElement.focus();
  } else {
    console.log("Element with the specified data attribute not found.");
  }
}
import AttachmentSigningScreen from "./AttachmentSigningScreen";
import { fileUploadType } from "./Designer/PdfTemplateSidebar/AttachmentsView";

export type PreviewStateType = {
  fetchedData: PreviewFetchedType;
  settings: {
    signingType: {
      image: boolean;
      drawn: boolean;
      typing: boolean;
    };
    attachments: fileUploadType[];
  };
  signersData: {
    [key: string]: { name: string; email: string };
  };
  paperOptions: {
    scroll: number;
    hoveringSchemaId: string | null;
  };
};

const Preview = ({
  template,
  inputs,
  size,
  onChangeInput,
  setShownModal,
  isOnlyPdfTemplate,
  isPreview,
  previewStyle,
  isSigning,
  fetchedData,
  unfilledFieldsKeys,
  isApprover,
  mapperState,
  setMapperState,
}: PreviewReactProps) => {
  // console.log("Fetched data preview", fetchedData);
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const rootRef = useRef<HTMLDivElement>(null);
  const newRootRef = useRef<HTMLDivElement>(null);
  const paperRefs = useRef<HTMLDivElement[]>([]);

  const [unitCursor, setUnitCursor] = useState(0);
  const [pageCursor, setPageCursor] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [schemasList, setSchemasList] = useState<SchemaForUI[][]>([
    [],
  ] as SchemaForUI[][]);
  const [activeSchema, setActiveSchema] = useState(schemasList[0][0]);
  const { backgrounds, pageSizes, scale, error, base64Pdf } = useUIPreProcessor(
    {
      template,
      size,
      zoomLevel,
    }
  );
  const [previewState, setPreviewState] = useState<PreviewStateType>({
    fetchedData: fetchedData || { accountInfo: {} },
    settings: {
      signingType: {
        drawn: true,
        typing: true,
        image: true,
      },
      attachments: [],
    },
    signersData: {
      test: { name: "test", email: "test@gmail.com" },
    },
    paperOptions: { scroll: 0, hoveringSchemaId: null },
  });
  const urlParams = new URLSearchParams(window.location.search);
  const [isSigningInPerson, setIsSigningPerson] = useState(
    urlParams.get("SigningInPerson")
  );
  useEffect(() => {
    if (fetchedData) {
      let signersData: Record<string, { name: string; email: string }> = {};
      let fetchedDataNew: any = fetchedData;
      if (fetchedDataNew.pdfTemplateRespondent) {
        let signers = fetchedDataNew?.pdfTemplateRespondent?.signers;
        signers.forEach((signer: any) => {
          let roleTitle = signer?.roleTitle;
          signersData[roleTitle] = { name: signer?.name, email: signer?.email };
        });
      }
      // console.log("fetched data", fetchedData?.pdfTemplateRespondent?.signers);
      setPreviewState((prev) => ({
        ...prev,
        fetchedData: fetchedData,
        settings: {
          ...prev.settings,
          signingType: {
            ...fetchedData?.document?.settings?.signingType,
          },
          attachments: fetchedData?.document?.settings?.attachments,
        },
        signersData,
      }));
    }
  }, [fetchedData]);

  const init = useCallback(async () => {
    const sl = await templateSchemas2SchemasList(template);
    setSchemasList(sl);
  }, [template]);

  useEffect(() => {
    if (unitCursor > inputs.length - 1) {
      setUnitCursor(inputs.length - 1);
    }
  }, [inputs]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (isSigningInPerson == "1") {
      localStorage.removeItem("temporarySignature");
      localStorage.removeItem("temporaryInitial");
      localStorage.removeItem("temporaryStamp");
    }
  }, [isSigningInPerson]);

  useEffect(() => {
    let checkSigningTypeEnabled = checkAndSetEnabledKey(
      fetchedData?.document?.settings?.signingType
    );
    if (checkSigningTypeEnabled) {
      localStorage.removeItem("temporarySignature");
      localStorage.removeItem("temporaryInitial");
    }
  }, []);

  useScrollPageCursor({
    ref: rootRef,
    pageSizes,
    scale,
    pageCursor,
    onChangePageCursor: (p) => setPageCursor(p),
    setScrollT: (scroll) =>
      setPreviewState((prev) => ({
        ...prev,
        paperOptions: { ...prev.paperOptions, scroll },
      })),
  });

  const handleChangeInput = ({
    key,
    value,
    schema,
  }: {
    key: string;
    value: string;
    schema?: SchemaForUI;
  }) => {
    /*
    Note a link group is added in editor so any chnages made to a linked item must be refelected to other
    which is done here byt updating all the schema with the same link group if it exists
    */

    let linkedGroupKeys: { index: number; key: string; value: string }[] = [
      { index: unitCursor, key, value },
    ];

    if (schema && schema.linkGroup) {
      schemasList.map((page) => {
        page.map((schema_) => {
          if (schema.linkGroup == schema_.linkGroup && schema_.key !== key) {
            linkedGroupKeys.push({
              index: unitCursor,
              key: schema_.key,
              value,
            });
          }
        });
      });
    }

    // console.log({ linkedGroupKeys });

    // onChangeInput && onChangeInput({ index: unitCursor, key, value });

    linkedGroupKeys.map((data) => {
      onChangeInput && onChangeInput(data);
    });
  };

  const editable = Boolean(onChangeInput);
  const input = inputs[unitCursor];

  if (error) {
    return <Error size={size} error={error} />;
  }

  const pageSizesHeightSum = pageSizes.reduce(
    (acc, cur) => acc + (cur.height * ZOOM + RULER_HEIGHT * scale) * scale,
    0
  );
  // const pageSize = pageSizes[0];
  // const paperSize = { width: pageSize.width * ZOOM, height: pageSize.height * ZOOM };

  // console.log(schemasList, template.schemas);

  const handlePageCursor = (p: any) => {
    // if (!rootRef.current) return;
    // rootRef.current.scrollTop = getPagesScrollTopByIndex(pageSizes, p, scale);
    // setPageCursor(p);
    if (!rootRef.current) return;
    const targetScrollTop = getPagesScrollTopByIndex(pageSizes, p, scale);
    const duration = 300; // You can adjust the duration of the smooth scroll
    const startTime = performance.now();
    const startScrollTop = rootRef.current.scrollTop;
    function scrollToSmoothly(timestamp: any) {
      if (!rootRef.current) return;
      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1); // Ensure progress doesn't exceed 1
      rootRef.current.scrollTop =
        startScrollTop + progress * (targetScrollTop - startScrollTop);
      if (progress < 1) {
        // Continue scrolling
        requestAnimationFrame(scrollToSmoothly);
      }
    }
    // Start the smooth scrolling animation
    requestAnimationFrame(scrollToSmoothly);
  };

  function setActiveSchemaById(id: string) {
    for (let [pgIdx, page] of Object.entries(schemasList)) {
      for (let schema of page) {
        if (schema.id == id) {
          if (parseInt(pgIdx) != pageCursor) {
            handlePageCursor(parseInt(pgIdx));
          }
          setActiveSchema(schema);
        }
      }
    }
  }

  const handleNext = () => {
    let activeQueIdx = schemasList[pageCursor]
      .map((elm) => elm?.id)
      .indexOf(activeSchema?.id);

    console.log(activeQueIdx, pageCursor);
    if (activeQueIdx < schemasList[pageCursor].length - 1) {
      setActiveSchema(schemasList[pageCursor][activeQueIdx + 1]);
      focusOnElement(schemasList[pageCursor][activeQueIdx + 1].id);
    } else if (pageCursor < schemasList.length - 1) {
      const lastPage = pageCursor;
      setActiveSchema((prev) => schemasList[lastPage + 1][0] || prev);
      focusOnElement((schemasList[lastPage + 1][0] || activeSchema).id);
      handlePageCursor(lastPage + 1);
      // setPageCursor((prev) => prev + 1);
    }
  };

  const handleRadioClick = (schema: SchemaForUI) => {
    let schemaListForCurrPage = schemasList[pageCursor];
    // this temp list is not needed remove later
    schemaListForCurrPage.map((schemaPrev, schIdx) => {
      if (schemaPrev?.radiogroupId == schema?.radiogroupId) {
        handleChangeInput({
          key: schemaPrev.key,
          value: STANDARD_DATA.radiogroup.unchecked_DBKEYV1.key,
        });
        if (schemaPrev?.id == schema.id) {
          handleChangeInput({
            key: schemaPrev.key,
            value: STANDARD_DATA.radiogroup.checked_DBKEYV1.key,
          });
        }
      }
    });
  };
  const getOutlineStyle = () => {
    if (!editable || isApprover) {
      return { outline: `` };
    }
    if (editable) {
      return {
        outline: `1px dashed ${
          previewState?.fetchedData?.accountInfo?.theme ||
          whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
          "hsl(var(--primary))"
        }`,
      };
    }
  };
  return (
    <Root
      ref={newRootRef}
      size={size}
      scale={scale}
      // calcHeight={isPreview ? 32 : 0}
      calcHeight={0}
    >
      <div className="flex max-h-[100%] h-[100%] min-h-[100%]">
        {editable && (
          <>
            <RequiredBar
              size={{
                height: Math.max(size.height, pageSizesHeightSum),
                width: size.width,
              }}
              explicitSize={{
                height: Math.max(size.height, pageSizesHeightSum),
                width: size.width,
              }}
              setActiveSchema={setActiveSchema}
              schemasList={schemasList}
              pageCursor={pageCursor}
              pageNum={schemasList.length}
              setPageCursor={(p) => {
                if (!rootRef.current) return;
                rootRef.current.scrollTop = getPagesScrollTopByIndex(
                  pageSizes,
                  p,
                  scale
                );
                setPageCursor(p);
              }}
              zoomLevel={zoomLevel}
              setZoomLevel={(zoom) => {
                if (rootRef.current) {
                  rootRef.current.scrollTop = getPagesScrollTopByIndex(
                    pageSizes,
                    pageCursor,
                    scale
                  );
                }
                setZoomLevel(zoom);
              }}
            />

            <LeftSideBar
              isPreview={isPreview}
              schemasList={schemasList}
              setActiveSchema={setActiveSchema}
              activeSchema={activeSchema}
              setPageCursor={handlePageCursor}
              pageCursor={pageCursor}
              isOnlyPdfTemplate={isOnlyPdfTemplate}
              previewState={previewState}
              unfilledFieldsKeys={unfilledFieldsKeys}
            />
          </>
        )}
        <CtlBar
          size={{
            height: Math.max(size.height, pageSizesHeightSum),
            width: size.width,
          }}
          pageCursor={pageCursor}
          pageNum={schemasList.length}
          setPageCursor={(p) => {
            if (!rootRef.current) return;
            rootRef.current.scrollTop = getPagesScrollTopByIndex(
              pageSizes,
              p,
              scale
            );
            setPageCursor(p);
          }}
          zoomLevel={zoomLevel}
          setZoomLevel={(zoom) => {
            if (rootRef.current) {
              rootRef.current.scrollTop = getPagesScrollTopByIndex(
                pageSizes,
                pageCursor,
                scale
              );
            }
            setZoomLevel(zoom);
          }}
        />
        <UnitPager
          size={{
            height: Math.max(size.height, pageSizesHeightSum),
            width: size.width,
          }}
          unitCursor={unitCursor}
          unitNum={inputs.length}
          setUnitCursor={setUnitCursor}
        />

        <div
          autoFocus
          tabIndex={-1}
          ref={rootRef}
          className="flex items-center justify-center overflow-y-auto mt-[70px] relative"
          style={{ height: "calc(100vh - 73px)" }}
          id="mini-pdf-custom-scroll"
        >
          <ActiveElmArrow
            setActiveSchemaById={setActiveSchemaById}
            handleNext={handleNext}
            scrollTop={previewState.paperOptions.scroll}
            bg={previewState?.fetchedData?.accountInfo?.theme}
            text={previewState?.fetchedData?.accountInfo?.themeText}
          />
          <Paper
            editable={editable}
            paperRefs={paperRefs}
            scale={scale}
            size={size}
            schemasList={schemasList}
            pageSizes={pageSizes}
            backgrounds={backgrounds}
            renderSchema={({ schema, index }) => {
              const { key } = schema;
              const data = (input && input[key]) || "";
              const tabIndex = schema?.isStatic ? -1 : index + 100;
              const isHovering =
                previewState.paperOptions.hoveringSchemaId === schema.id;

              return (
                <SchemaUI
                  isHovering={isHovering}
                  isSigning={isSigning}
                  previewStyle={previewStyle}
                  isPreview={isPreview}
                  isOnlyPdfTemplate={isOnlyPdfTemplate}
                  handleNext={handleNext}
                  setShownModal={setShownModal}
                  isActive={activeSchema?.id === schema.id}
                  setActiveSchema={setActiveSchema}
                  key={schema.id}
                  schema={Object.assign(schema, { data })}
                  editable={editable}
                  placeholder={
                    schema.placeholder || schema.showKey || ""
                    // template.sampledata ? template.sampledata[0][key] : ""
                  }
                  tabIndex={tabIndex}
                  onChange={(value) =>
                    handleChangeInput({ key, value, schema })
                  }
                  handleRadioClick={handleRadioClick}
                  // onCheck={async (value) => {
                  //   handleChangeInput({ key, value });
                  // }}
                  outline={
                    editable
                      ? `1px dashed ${
                          whiteLabelInfo?.settings?.organization?.themeHex
                            ?.primary || "hsl(var(--primary))"
                        }`
                      : "transparent"
                  }
                  outlineStyle={getOutlineStyle()}
                  previewState={previewState}
                  isApprover={isApprover || false}
                  onChangeHoveringSchemaId={(id) =>
                    setPreviewState((prev) => ({
                      ...prev,
                      paperOptions: {
                        ...prev.paperOptions,
                        hoveringSchemaId: id,
                      },
                    }))
                  }
                />
              );
            }}
            previewState={previewState}
          />
        </div>
        {mapperState?.showSideBar === "ATTACHMENTS" && (
          <AttachmentSigningScreen
            attachments={fetchedData?.document?.settings?.attachments}
            documentName={fetchedData?.document?.documentName}
            setMapperState={setMapperState}
            mapperState={mapperState}
          />
        )}

        {mapperState?.showSideBar === "MINI_PDF" && (
          <MiniPdfViewer
            pageCursor={pageCursor}
            pageNum={schemasList.length}
            height={rootRef.current ? rootRef.current.clientHeight : 0}
            size={size}
            file={base64Pdf}
            setPageIndex={(p) => {
              if (!rootRef.current) return;
              rootRef.current.scrollTop = getPagesScrollTopByIndex(
                pageSizes,
                p,
                scale
              );
              // setPageCursor(p);
              // onEditEnd();
            }}
            pages={schemasList}
            isFormPov={true}
            previewState={previewState}
            backgrounds={backgrounds}
          />
        )}
      </div>
    </Root>
  );
};

export default Preview;
