import { useEffect, useState } from "react";
import { BsFiletypePdf, BsUpload } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchOwner } from "../../utils";
import { Slide, ToastContainer, toast } from "react-toastify";
import axios from "../../utils/axios";
import { PDFDocument } from "pdf-lib";
import { BiError, BiSolidUpArrowCircle } from "react-icons/bi";
import { MdOutlineDoneAll } from "react-icons/md";
import AuditHistory from "../../components/Ui/AuditHistoryOnVerify";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaFilePdf } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";

const arrayBufferToBase64 = (buffer: any) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};
const VerifyPdf = () => {
  let navigate = useNavigate();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [verficationStatus, setVerificationStatus] = useState<any>({});

  const [documentDetails, setDocumentDetails] = useState<any>();
  const [respondent, setRespondent] = useState<any>();

  const handleDrop = (e: any) => {
    e.preventDefault();
    const uploadedFile = e.dataTransfer.files[0];
    setFile(uploadedFile);
    // setFileName && setFileName(uploadedFile.name);
  };

  const handleFileInputChange = async (e: any) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    // if (isSignature) {
    //   onFileUpload(uploadedFile);
    //   setFileName && setFileName(uploadedFile.name);
    // } else if (isOnlyPdfTemplate) {
    //   onFileUpload && onFileUpload(uploadedFile);
    // }
  };

  useEffect(() => {
    const checkForVerification = async () => {
      try {
        setLoading(true);
        let pdfFile: File = file;
        const pdfBuffer = await pdfFile.arrayBuffer();
        // Convert PDF buffer to a base64 string
        let pdfBase64 = arrayBufferToBase64(pdfBuffer);
        pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;

        const pdfDoc = await PDFDocument.load(pdfBase64);
        const pdfCreator = pdfDoc.getCreator();
        const pdfAuthor = pdfDoc.getAuthor();

        const { data: verificationData } = await axios.post(
          `/common/verify-finished-pdf`,
          {
            pdfCreator,
            pdfAuthor,
          }
        );

        if (!verificationData.error) {
          setVerificationStatus(verificationData.feedback);
          console.log(verificationData?.feedback?.status);
          if (verificationData?.feedback?.status === "success") {
            new MixPanel().track("Verify Pdf", {
              verify_pdf_action: "verify_success",
              paid: false,
            });
            new MixPanel().increment("verify_success");
          } else if (verificationData?.feedback?.status === "failed") {
            new MixPanel().track("Verify Pdf", {
              verify_pdf_action: "verify_failed",
              paid: false,
            });
            new MixPanel().increment("verify_failed");
          }
          verificationData.document &&
            setDocumentDetails(verificationData.document);
          verificationData.templateResponse &&
            setRespondent(verificationData.templateResponse);
        } else {
          toast.error(verificationData.error);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Error verifying document");
      }
    };
    if (file) {
      console.log("checking for verification");
      checkForVerification();
    }
  }, [file]);

  console.log(verficationStatus, "verification status");
  console.log(documentDetails, "document details");
  console.log(respondent, "respondent");

  return (
    <div className="flex flex-col justify-center">
      <Helmet>
        <title translate="no">Verify Pdf</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Verify Pdf" />
        <meta
          name="description"
          content="Safeguard your documents by uploading an alternate copy for thorough verification against potential tampering."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/verify-pdf`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Verify Pdf - Boloforms`} />
        <meta
          property="og:description"
          content="Safeguard your documents by uploading an alternate copy for thorough verification against potential tampering."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/verify-pdf"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="template-navbar sticky top-0 z-10 md:h-14 px-4 md:px-8 py-2 bg-primary flex gap-2 text-white items-center">
        <IoArrowBackCircle
          className="hover:opacity-70 text-3xl cursor-pointer rounded-sm"
          onClick={() => {
            navigate("/dashboard/forms");
          }}
        />
        <p className="text-sm ml-2">Verify Document</p>
        {/* <p className="ml-auto text-sm">
          <span className="font-semibold">Preparing Template</span>
        </p> */}
      </div>
      <div className="md:py-8 p-4 md:p-12 md:mx-auto w-[100%] md:w-[85%] lg:w-[65%]">
        <h1 className="text-lg md:text-2xl font-bold mb-3 text-gray-700 capitalize">
          Verify your document
        </h1>
        <p className="text-xs md:text-sm mb-3 w-full capitalize font-medium">
          Have an alternate copy of this signed document which you believe might
          be tampered with? Upload it here to verify.
        </p>

        <p className="mt-4 text-xs text-red-500">
          <span className="font-semibold"> Note: </span> Documents generated
          after <span className="font-bold">11th September 2023</span> are
          verifiable only.
        </p>

        <label
          htmlFor="dropzone-file"
          className={`mt-5 md:mt-10 flex flex-col items-center justify-center p-4 md:p-8 border-2 border-gray-300 hover:border-gray-500 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-full text-gray-600 transition-all ease-in-out duration-150 ${
            file ? "bg-secondary/60 border-primary/80" : ""
          } ${
            verficationStatus?.status === "success"
              ? "border-green-500"
              : verficationStatus?.status === "failed"
              ? " border-red-500"
              : "border-primary/50"
          }`}
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          {!file && (
            <div className="flex flex-row md:flex-col items-center justify-center gap-4 md:gap-8 py-3 md:py-6">
              <div className="p-2 md:p-4 bg-gray-200 rounded-full">
                <BiSolidUpArrowCircle size={50} className="text-gray-400" />
              </div>
              <div className="m-0 flex flex-col items-center">
                <p>
                  <span className="text-primary mr-1 font-medium">Browse</span>{" "}
                  or drag a file here
                </p>
              </div>
            </div>
          )}
          {file && (
            <div className="flex flex-row md:flex-col items-center justify-center gap-8 py-8">
              <FaFilePdf size={50} className="text-gray-600" />
              <div className="flex flex-col items-center justify-center">
                {/* <TiTick size={30} className="text-primary" /> */}
                <p className="text-gray-600 text-sm">{file?.name}</p>
                <p className="text-white text-xs mt-2 font-medium bg-gray-500 px-4 py-1 rounded-md">
                  {Math.floor(file?.size / 1024)} KB
                </p>
              </div>
            </div>
          )}
        </label>
        <input
          id="dropzone-file"
          type="file"
          className="appearance-none hidden disabled:opacity-60"
          onChange={handleFileInputChange}
          accept="application/pdf"
        />
        <div className="w-full mb-4">
          {file && !loading && (
            <span
              className={`mt-2 md:mt-4 px-4 py-1 md:py-2 w-full text-xs md:text-sm rounded-md mx-auto flex gap-2 items-center ${
                verficationStatus.status === "success"
                  ? "text-green-600 bg-green-50 border border-green-500 "
                  : "text-red-600 bg-red-50 border border-red-500"
              } `}
            >
              {verficationStatus.status === "success" ? (
                <MdOutlineDoneAll size={20} />
              ) : (
                <BiError size={20} />
              )}
              {verficationStatus.message}
            </span>
          )}
        </div>
        {verficationStatus.status === "success" && documentDetails && (
          <AuditHistory
            history={documentDetails.history}
            documentDetails={documentDetails}
            respondent={respondent}
          />
        )}
        {/* {document && (
          <div className="documentDetails">
            <h1>Signed document details</h1>
            <p>
              Document ID: <span>{document.documentId}</span>
            </p>
            <p>
              Completion time:{" "}
              <span>
                {new Date(document.updatedAt).toDateString()}{" "}
                {new Date(document.updatedAt).toLocaleTimeString()}
              </span>
            </p>
          </div>
        )} */}
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default VerifyPdf;
