import React, { useState, useEffect, useRef } from "react";
// import Viewer from "../../pdfme/ui/src/Viewer";
import { Viewer } from "../../pdfme/ui/src";

const PdfPreview = ({ form, pdfFile, formResponseData, heightSub }) => {
  const viewerRef = useRef(null);
  const viewer = useRef(null);

  // useEffect(() => {
  //   setSmallDisplay(window.innerWidth < 900);
  // }, []);
  const extractFirstElementFromArrayFields = (responseData) => {
    if (!responseData || typeof responseData !== "object") {
      return null; // Not an object or null/undefined
    }

    for (const key in responseData) {
      if (Array.isArray(responseData[key]) && responseData[key].length > 0) {
        const firstElement = responseData[key][0];

        // Check if the first element includes "data:image/png;base64"

        if (
          typeof firstElement === "string" &&
          !firstElement.includes("data:image/png;base64")
        ) {
          // Convert the array to a comma-separated string

          responseData[key] = responseData[key].join(",");
        } else {
          responseData[key] = firstElement; // Keep the original value if it doesn't match the condition
        }
      }
    }

    return responseData;
  };

  // @ts-ignore
  useEffect(() => {
    if (!pdfFile || !form) return;

    let schemas = form?.pdfTemplate?.schemas || [];
    let formResponses = formResponseData?.response;
    for (const key in formResponses) {
      if (typeof formResponses[key] === "number") {
        formResponses[key] = formResponses[key].toString();
      }
    }
    let responseObj = extractFirstElementFromArrayFields(formResponses);
    const extractedKeys = {};
    schemas?.forEach((schema) => {
      Object.keys(schema).forEach((key) => {
        const idPart = schema[key].id.split("_")[1];
        const matchedKey = form.responseItemsId[idPart];
        // if (matchedKey && responseObj?.[matchedKey]) {
        //   extractedKeys[key] = responseObj?.[matchedKey];
        // }
        if (idPart && responseObj?.[idPart]) {
          extractedKeys[key] = responseObj?.[idPart];
        }
      });
    });

    const template = {
      // schemas: templateSchema,
      schemas: schemas,
      // sampledata: [{ Name: "Input example", photo: "https://picsum.photos/200/300"}],
      // sampledata:
      basePdf: pdfFile,
    };

    const inputs = [extractedKeys || responseObj];
    // Run this effect when the containerRef is available
    if (viewerRef.current) {
      viewer.current = new Viewer({
        domContainer: viewerRef.current,
        template,
        inputs,
        isPreview: true,
      });
      // designer.current.onSaveTemplate(saveTemplate);
      // designer.current.onChangeTemplate(setTemplate);
    }

    return () => {
      // Destroy the designer when the component unmounts
      // @ts-ignore
      // viewer.current.destroy();
    };
  }, [viewerRef, form, pdfFile, formResponseData]);

  // };

  return (
    <div
      className="flex items-start justify-start w-full overflow-hidden"
      ref={viewerRef}
      id="container"
      style={{
        maxHeight: `calc(100vh - ${(heightSub || 0) + 20}px)`,
        height: `calc(100vh - ${(heightSub || 0) + 20}px)`,
      }}
    ></div>
  );
};

export default PdfPreview;
