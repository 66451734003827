import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { BASE_URL } from "../../constants/constants";
import {
  BASE_INITIAL_STATE,
  parseQueryParams,
  BaseInitialStateType,
} from "../reduxConstants";

export const generateQRThunk = createAsyncThunk(
  "generateQR",
  async (data?: { email?: string; action: "get" | "generate" }) => {
    try {
      let url = `${BASE_URL}/2fa/generate-qr`;
      const res = await axios.post(url, data);
      return res.data;
    } catch (error: any) {
      return error?.response?.data;
    }
  }
);

export const verifyQRThunk = createAsyncThunk(
  "verifyQR",
  async (data?: { otp: string; email: string }) => {
    try {
      let url = `${BASE_URL}/2fa/verify-qr`;
      const res = await axios.post(url, data);
      return res.data;
    } catch (error: any) {
      return error?.response?.data;
    }
  }
);

export type TwoFAInitialStateType = {
  data: {
    otpAuth: string;
    isValid: boolean;
    secret: string;
    secretExists: boolean;
    verifyLoading: boolean;
    genLoading: boolean;
    verified: boolean;
    enabled: boolean;
  };
} & BaseInitialStateType;

const initialState: TwoFAInitialStateType = {
  ...BASE_INITIAL_STATE,
  data: {
    otpAuth: "",
    isValid: false,
    secret: "",
    secretExists: false,
    verifyLoading: false,
    genLoading: false,
    verified: false,
    enabled: false,
  },
};

const towFASlice = createSlice({
  name: "owner",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //generateQRThunk ---------------------------------------------------------------------
      .addCase(generateQRThunk.pending, (state, action) => {
        state.loading = true;
        state.data.genLoading = true;
      })
      .addCase(generateQRThunk.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.data.otpAuth = payload?.otpAuth || "";
          state.data.secret = payload?.secret || "";
          state.data.secretExists = payload?.secretExists || false;
          state.data.verified = payload?.verified || false;
          state.data.enabled = payload?.enabled || false;
        }
        state.loading = false;
        state.data.genLoading = false;
      })
      .addCase(generateQRThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.data.genLoading = false;
      })
      //verifyQRThunk ---------------------------------------------------------------------
      .addCase(verifyQRThunk.pending, (state, action) => {
        state.loading = true;
        state.data.verifyLoading = true;
      })
      .addCase(verifyQRThunk.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.data.isValid = payload?.isValid ?? false;
        }
        state.loading = false;
        state.data.verifyLoading = false;
      })
      .addCase(verifyQRThunk.rejected, (state, { payload }) => {
        state.loading = false;
        state.data.verifyLoading = false;
      });
  },
});

export default towFASlice.reducer;
export const {} = towFASlice.actions;
