// @ts-nocheck
import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import Modal from "../Modal";
import { FiRefreshCcw } from "react-icons/fi";
import { FaCopy, FaEye, FaEyeSlash } from "react-icons/fa6";
import { getWorkSpaceDetails } from "../../utils";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import axios from "../../utils/axios";
import { isFeatureEnabled } from "../../utils/paidFeat";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
import { BsInfoCircle } from "react-icons/bs";
import { getDashboardUrl, getDashboardUrlV1 } from "../../constants/constants";
import { useNavigate } from "react-router-dom";
import { INTEGRATION_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import MixPanel from "../../utils/services/mixpanel";
interface Integration {
  name: string;
  key: string;
  description: string;
  logo: string;
}

interface IntegrationCardProps {
  integration: Integration;
  index: number;
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  integration,
  index,
}) => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  //for feature blockings

  const [upgradeModalType, setUpgradeModalType] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const [showGenerateKeyModal, setShowGenerateKeyModal] = useState(false);
  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );
  const [owner, setOwner] = useState(
    JSON.parse(localStorage.getItem("owner") || "{}")
  );

  const [showApiKey, setShowApiKey] = useState(false);

  const [apiKey, setApiKey] = useState("No API key generated yet");

  let navigate = useNavigate();

  useEffect(() => {
    let apiKeyOfIntegration;

    switch (integration.key) {
      case "zapier":
        apiKeyOfIntegration = owner?.integration?.zapier?.apiKey;
        break;
      case "make":
        apiKeyOfIntegration = owner?.integration?.make?.apiKey;
        break;
      case "pabbly":
        apiKeyOfIntegration = owner?.integration?.pabbly?.apiKey;
        break;
      case "activepieces":
        apiKeyOfIntegration = owner?.integration?.activepieces?.apiKey;
        break;
      default:
        apiKeyOfIntegration = "";
        break;
    }

    setApiKey(apiKeyOfIntegration);
  }, [
    owner?.integration?.zapier?.apiKey,
    owner?.integration?.make?.apiKey,
    owner?.integration?.pabbly?.apiKey,
    owner?.integration?.activepieces?.apiKey,
    integration.key,
    showGenerateKeyModal,
  ]);

  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      setWorkSpaceDetails(localWorkSpaceDetails);
      setOwner(JSON.parse(localStorage.getItem("owner") || "{}"));
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  const handleUpdateApiKey = async () => {
    if (!apiKey || apiKey === "No API key generated yet") {
      return toast.error("This is not a valid API key");
    }
    try {
      const { data } = await axios.post("/workspace/integration-api", {
        email: owner?.email,
        integrationKey: integration.key,
        generatedApiKey: apiKey,
      });

      if (data?.success) {
        localStorage.setItem("owner", JSON.stringify(data?.owner));
        window.dispatchEvent(new Event("storage"));
        toast.success(`${integration.name} API key updated successfully`);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const generateKeyModalContent = (integrationName) => {
    let learnMoreLink;
    let connectionLink;
    let whitelabelIntLinks =
      whiteLabelInfo?.settings?.organization?.integrations;

    switch (integrationName) {
      case "Zapier":
        learnMoreLink =
          "https://help.boloforms.com/en/collections/7995285-zapier";
        connectionLink = "https://zapier.com/apps/boloforms/integrations";
        // learnMoreLink =
        //   whitelabelIntLinks?.zapier?.learnMoreLink ||
        //   INTEGRATION_LINKS.zapier.learnMoreLink;
        // connectionLink =
        //   whitelabelIntLinks?.zapier?.connectionLink ||
        //   INTEGRATION_LINKS.zapier.connectionLink;
        break;
      case "Pabbly":
        learnMoreLink =
          "https://help.boloforms.com/en/collections/8746035-pabbly";
        connectionLink =
          "https://connect.pabbly.com/share-app/DkYCMFAHB2QASgBqAmcBJltPCw8JU1JvUEkHFQQLXCRTHlU-VBUJYw9DBCZXDFU0UhsBa1MMVDUIHFBUB1EGdQceCRxcQwFcUWJUEQJdCyEObAJ1UBsHPQBRADMCSgE7W2ILLAlEUg9QXQdlBB9cGlMKVS5UGQkpD08EbFcYVX9SEwFoUwpUdAgx";
        // learnMoreLink =
        //   whitelabelIntLinks?.pabbly?.learnMoreLink ||
        //   INTEGRATION_LINKS.pabbly.learnMoreLink;
        // connectionLink =
        //   whitelabelIntLinks?.pabbly?.connectionLink ||
        //   INTEGRATION_LINKS.pabbly.connectionLink;
        break;
      // case "Activepieces":
      //   learnMoreLink =
      //     "https://help.boloforms.com/en/collections/7995285-zapier";
      //   break;
      case "Make":
        learnMoreLink =
          "https://help.boloforms.com/en/collections/8746040-make-com";
        connectionLink =
          "https://www.make.com/en/hq/app-invitation/b3be4666791b260ffa79a5eb7ada7a09";
        // learnMoreLink =
        //   whitelabelIntLinks?.make?.learnMoreLink ||
        //   INTEGRATION_LINKS.make.learnMoreLink;
        // connectionLink =
        //   whitelabelIntLinks?.make?.connectionLink ||
        //   INTEGRATION_LINKS.make.connectionLink;
        break;
      default:
        learnMoreLink = "";
        connectionLink = "";
        break;
    }

    return (
      <div className="w-[100%] md:w-[600px] py-2 md:py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            setShowGenerateKeyModal(false);
            setShowApiKey(false);
          }}
          className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <h2 className="mb-1 text-lg md:text-xl font-semibold ">
          Setup Integration & Generate your API key for {integrationName}
        </h2>
        <p className="text-xs md:text-sm text-gray-600 mb-6 flex gap-2">
          Follow the steps below to build your integration.{" "}
          <span
            className="flex gap-2 cursor-pointer items-center text-primary underline"
            onClick={() => {
              window.open(learnMoreLink, "_blank");
            }}
          >
            Learn More..
          </span>
        </p>
        <div className="flex flex-col">
          <p className="text-sm md:text-lg w-fit uppercase font-semibold rounded-md flex items-center justify-center">
            Step 1
          </p>
          <p className="text-xs md:text-sm text-gray-600 mt-1 mb-1">
            Connect{" "}
            {whiteLabelInfo?.settings?.organization?.name || "BoloForms"} with{" "}
            {integrationName} and instantly integrate with 1000+ apps.
          </p>
          <span className="text-xs mb-3 text-gray-600">
            {whiteLabelInfo?.settings?.organization?.name
              ? "Powered By BoloForms"
              : ""}
          </span>
          <button
            className="w-fit text-xs md:text-sm bg-primary/20 text-primary font-medium rounded-md px-4 py-1.5 flex items-center justify-center relative"
            onClick={() => {
              window.open(connectionLink, "_blank");
            }}
          >
            {/* <span className="rounded-lg p-1 bg-green-600 text-white text-xs font-medium absolute -top-2 -right-16">
                Beta Access
            </span> */}
            Connect{" "}
            {whiteLabelInfo?.settings?.organization?.name || "BoloForms"}
          </button>
        </div>
        <div className="mt-4 flex flex-col">
          <p className="text-sm md:text-lg w-fit uppercase font-semibold rounded-md flex items-center justify-center">
            Step 2
          </p>
          <p className="text-xs md:text-sm text-gray-600 mt-1 mb-3">
            Use this API key to access your{" "}
            <spam className="text-primary">
              {whiteLabelInfo?.settings?.organization?.name || "BoloForms"}
            </spam>{" "}
            account from {integrationName}.
          </p>
          <div className="flex flex-col gap-y-3">
            <div className="flex w-full justify-between">
              {/* <span className="text-sm font-semibold">API Key</span> */}
              <div className="flex gap-4 cursor-pointer">
                <span
                  className="p-2 bg-primary/20 rounded-md text-primary hover:scale-[1.2] transition-all duration-75 ease-out "
                  onClick={() => {
                    if (
                      !(
                        workSpaceDetails?.isWorkSpaceOwner ||
                        workSpaceDetails?.currentWorkSpaceRole === "admin"
                      )
                    ) {
                      toast.error(
                        "Only workspace owner and admins can generate API key"
                      );
                      return;
                    }
                    setApiKey(v4());
                  }}
                >
                  <FiRefreshCcw size={18} />
                </span>
                <span
                  className="p-2 bg-primary/20 rounded-md text-primary hover:scale-[1.2] transition-all duration-75 ease-out "
                  onClick={() => {
                    setShowApiKey(!showApiKey);
                  }}
                >
                  {showApiKey ? <FaEye size={18} /> : <FaEyeSlash size={18} />}
                </span>
                <span
                  className="p-2 bg-primary/20 rounded-md text-primary hover:scale-[1.2] transition-all duration-75 ease-out "
                  onClick={async () => {
                    if (!apiKey || apiKey === "No API key generated yet") {
                      return;
                    }
                    try {
                      await navigator.clipboard.writeText(apiKey);
                      toast.success("API key successfully copied to clipboard");
                    } catch (err) {
                      console.error("Failed to copy: ", err);
                    }
                  }}
                >
                  <FaCopy size={18} />
                </span>
              </div>
            </div>
            <div className="break-all text-xs md:text-md p-3 border text-gray-400 border-gray-100 rounded-md bg-gray-100">
              {/* {showApiKey
                ? apiKey === "" || apiKey === "No API key generated yet"
                  ? "No Api Key"
                  : apiKey
                : apiKey?.replace(/./g, "x")} */}

              {apiKey ? (
                showApiKey ? (
                  apiKey
                ) : (
                  apiKey?.replace(/./g, "x")
                )
              ) : (
                <p className="flex items-center">
                  No Api Key, Click
                  <span className="inline-block mx-2">
                    <FiRefreshCcw size={12} />
                  </span>
                  to generate new key.
                </p>
              )}
            </div>
            <div className="flex justify-end mt-2">
              <button
                className="px-6 py-2 text-primary text-xs md:text-sm bg-secondary rounded-lg hover:opacity-90 mr-4 disabled:opacity-50"
                onClick={() => {
                  setShowGenerateKeyModal(false);
                  setShowApiKey(false);
                }}
              >
                Cancel
              </button>
              <button
                className="px-6 py-2 bg-primary text-xs md:text-sm text-white rounded-lg hover:opacity-90 disabled:opacity-50"
                disabled={
                  !apiKey ||
                  apiKey === "No API key generated yet" ||
                  apiKey === owner?.integration?.[integration.key]?.apiKey ||
                  !(
                    workSpaceDetails?.isWorkSpaceOwner ||
                    workSpaceDetails?.currentWorkSpaceRole === "admin"
                  )
                }
                onClick={async () => {
                  await handleUpdateApiKey();
                }}
              >
                Save API Key
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const buyAPIPackageModal = (integrationName) => {
    return (
      <div className="w-[100%] md:w-[600px] py-2 md:py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            setShowGenerateKeyModal(false);
            setShowApiKey(false);
          }}
          className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />

        <h2 className="mb-1 text-lg md:text-xl font-semibold ">
          {integrationName} Integration is available on API package.
        </h2>
        <p>
          <span className="text-primary font-semibold">API package</span> allows
          you to integrate with third party services like Zapier, Pabbly and
          Activepieces.
        </p>

        <button
          className="px-6 py-2 bg-primary text-xs md:text-sm text-white rounded-lg hover:opacity-90 disabled:opacity-50 mt-4"
          onClick={() => {
            // navigate(`/billings`);
            window.open(`${getDashboardUrlV1()}/billings`, "_blank");
          }}
        >
          Buy Now
        </button>
      </div>
    );
  };

  return (
    <>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          blockingType={upgradeModalType}
        />
      )}
      <div
        className="flex flex-col border border-gray-200 shadow-sm rounded-md p-4 w-[45%] lg:w-[250px] cursor-pointer"
        key={index || integration.name}
      >
        <div className="flex justify-between items-center">
          <img
            src={integration.logo}
            alt={integration.name}
            className="w-10 h-10"
          />
          {owner?.integration?.[integration.key]?.apiKey && (
            <span className="text-[10px] md:text-[12px] text-green-600 flex font-semibold items-center justify-center">
              {" "}
              <TiTick className="w-4 h-4 md:w-6 md:h-6 " /> Connected
            </span>
          )}
        </div>
        <h3 className="text-lg font-bold mt-4">{integration.name}</h3>
        <p className="text-[12px] my-2"> {integration.description} </p>

        <div className="flex mt-auto items-end">
          <span className="text-[10px] text-gray-600">
            {whiteLabelInfo?.settings?.organization?.name
              ? "Powered By BoloForms"
              : ""}
          </span>
          <div
            className="ml-auto p-2 font-medium text-sm bg-primary text-white rounded-md "
            onClick={async () => {
              new MixPanel().track("Integration", {
                integration_action: `integration_${integration.key}_add`,
                paid: true,
              });
              new MixPanel().increment(`integration_${integration.key}_add`);
              if (
                !(
                  workSpaceDetails?.isWorkSpaceOwner ||
                  workSpaceDetails?.currentWorkSpaceRole === "admin"
                )
              ) {
                toast.error(
                  "Only workspace owner and admins can setup integration"
                );
                return;
              }
              let integrationFeatureName;
              switch (integration.key) {
                case "zapier":
                  integrationFeatureName = "ZAPIER_INTEGRATION";
                  break;
                case "pabbly":
                  integrationFeatureName = "PABBLY_INTEGRATION";
                  break;
                case "activepieces":
                  integrationFeatureName = "ACTIVEPIECES_INTEGRATION";
                  break;
                case "make":
                  integrationFeatureName = "MAKE_INTEGRATION";
                  break;
                default:
                  integrationFeatureName = "";
                  break;
              }
              let isFeature = await isFeatureEnabled(integrationFeatureName);
              if (!isFeature) {
                setUpgradeModalType(integrationFeatureName);
                setShowUpgradeModal(true);
                return;
              }
              //for now skipping the upgrate check for pabblt and activepieces
              // if (
              //   (integration.key === "zapier" || integration.key === "make") &&
              //   !isFeature
              // ) {
              //   setUpgradeModalType(integrationFeatureName);
              //   setShowUpgradeModal(true);
              //   return;
              // }
              setShowGenerateKeyModal(true);
            }}
          >
            Setup
          </div>
        </div>
      </div>
      {/* {showGenerateKeyModal && generateKeyModalContent()} */}

      {showGenerateKeyModal && (
        <Modal modalContent={generateKeyModalContent(integration.name)} />
      )}
      {/* {integration.key !== "zapier" && showGenerateKeyModal && (
        <Modal modalContent={buyAPIPackageModal(integration.name)} />
      )} */}
    </>
  );
};

export default IntegrationCard;
