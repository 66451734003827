import React, { useEffect, useState } from "react";
import { DesignerState, DocumentValueType, GlobalHelpersType } from "..";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../components/Common/BoloSelect/BoloSelect";
import ViewHeader from "./ViewHeader";
import BoloRadioGroupMui, {
  BoloRadioMui,
} from "../../../../../../components/Common/RadioGroup/BoloRadioGroupMui";
import { FormControlLabel } from "@mui/material";
import axios from "../../../../../../utils/axios";
import { toast } from "react-toastify";
import { BoloLoaderLine } from "../../../../../../components/Common/Loader/BoloLoader";
import { BoloInput } from "../../../../../../components/Common/Input/BoloInput";
import BoloCheckboxMui from "../../../../../../components/Common/Checkbox";
import { SchemaForUI } from "../../../../../common/src";
import { isTableSchema } from "../../../../../common/src/type";
import { formatPrice } from "../../../../../../utils/quoteBuilder";
import CustomTooltip from "../../../../../../components/EditForm/CustomTooltip";
import { BOLO_CURRENCIES } from "../../../../../../constants/currency";
import { getWorkSpaceDetails } from "../../../../../../utils";
import {
  getAccountLinkApi,
  updateStripeAccountDataAPI,
  createConnectedAccountApi,
} from "../../../../../../redux/apis/payments";
import SetupPaymentGateway from "../../../../../../pages/Payments/SetupPaymentGateway";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import { BsStripe } from "react-icons/bs";
import useOwnerLocal from "../../../../../../hooks/reduxHooks/useOwnerLocal";
import { MdClose } from "react-icons/md";
import _ from "lodash";
import { isFeatureEnabled } from "../../../../../../utils/paidFeat";
import PleaseUpgradeModal from "../../../../../../components/PleaseUpgradeModal";

export type PriceViewProps = {
  helpers: {
    open: boolean;
    setOpen: any;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};

const PriceView = ({ helpers }: PriceViewProps) => {
  const { open, setOpen, designerState, setDesignerState, globalHelpers } =
    helpers;
  let return_url = window.location.toString();

  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [numTableSchema, setNumTableSchema] = useState<number>(0);
  const [isStripeIntEnabled, setIsStripeIntEnabled] = useState<boolean>(false);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(
    designerState.settings.documentValue.amount.customAmount
  );
  const [localState, setLocalState] = useState<DocumentValueType>(
    designerState.settings.documentValue
  );
  const { owner } = useOwnerLocal([loading]);

  // # copied from payment js -----------------------------------------------------
  const handleAccountLink = async () => {
    setLoading(true);
    try {
      let data = await getAccountLinkApi({ return_url });
      let url = data?.url;
      if (data.url) {
        window.open(url, "_self");
      }
    } catch (error) {
      console.log("Error in handleAccountLink");
      console.log(error);
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleUpdateAccountDataApi = async () => {
    try {
      setLoading(true);
      await updateStripeAccountDataAPI();
      setLoading(false);
    } catch (error) {
      console.log("Error in handleUpdateAccountDataApi");
      console.log(error);
    }
  };

  // # from payment gateway modal
  const handleConnect = async () => {
    if (!isStripeIntEnabled) {
      setShowUpgradeModal(true);
      return;
    }
    setLoading(true);
    try {
      const data = await createConnectedAccountApi({ return_url });
      let url = data?.accountLink?.url;
      if (url) {
        window.open(url, "_self");
      }
    } catch (error) {
      toast.error("Something went worong");
      console.log("error in handleConnect");
      console.log(error);
    }
    setLoading(false);
  };
  // #-----------------------------------------------------

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(e.target.value);
    setValue(newValue);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (value < 0) {
      setValue(designerState.settings.documentValue.amount.customAmount);
      return toast.error("Value cannot be negative");
    }
    setChanged(true);
    setLocalState((prev) => ({
      ...prev,
      amount: { ...prev.amount, customAmount: value },
    }));
  };

  const handleCurrency = (currency: string) => {
    setChanged(true);
    setLocalState((prev) => ({
      ...prev,
      amount: {
        ...prev.amount,
        currency: currency,
        schemas: [],
        valueTotal: 0,
      },
    }));
  };

  const handleAssignedPayer = (payer: string) => {
    setChanged(true);
    setLocalState((prev) => ({
      ...prev,
      selectedPayers: [...prev.selectedPayers, payer],
    }));
  };

  const handleRemoveRole = (payer: string) => {
    setChanged(true);
    setLocalState((prev) => ({
      ...prev,
      selectedPayers: prev.selectedPayers.filter((payer_) => payer_ !== payer),
    }));
  };

  const handleValueType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setLocalState((prev) => ({
      ...prev,
      valueType: e.target.value === "CUSTOM" ? "CUSTOM" : "DOCUMENT_TOTAL",
    }));
  };

  const handePricingTables = ({
    id,
    key,
    amount,
    action,
  }: {
    id: string;
    key: string;
    amount: number;
    action: "add" | "remove";
  }) => {
    let newValueSchemas = [...localState.amount.schemas];
    if (action === "add") {
      let checkIfTableExits = newValueSchemas.findIndex(
        (value) => value.schemaId == id
      );
      if (checkIfTableExits === -1) {
        // i.e schema does not exist
        newValueSchemas.push({
          schemaId: id,
          schemaName: key,
          schemaTotal: amount,
        });
      } else {
        // i.e schema does exist
        newValueSchemas[checkIfTableExits].schemaTotal = amount;
      }
    }
    if (action === "remove") {
      newValueSchemas = newValueSchemas.filter((elm) => elm.schemaId !== id);
    }

    let documentTotal = newValueSchemas.reduce(
      (acc, elm) => acc + elm.schemaTotal,
      0
    );
    documentTotal = _.round(documentTotal, 2);

    setChanged(true);
    setLocalState((prev) => ({
      ...prev,
      amount: {
        ...prev.amount,
        schemas: newValueSchemas,
        valueTotal: documentTotal,
      },
    }));
  };

  const handleUpdateDocumentValue = async (value: DocumentValueType) => {
    try {
      setLoading(true);
      const { data } = await axios.post(`/document/settings`, {
        documentValue: value,
        documentId: designerState?.fetchedData?.document?.documentId,
      });
      if (data?.success) {
        toast.success("Settings Saved Sucessfully");
        if (data?.document?.settings?.documentValue) {
          // if settings exists on returned object
          // as when we change language settings will always exist on the document

          setDesignerState((prev) => ({
            ...prev,
            fetchedData: { ...prev.fetchedData, document: data?.document },
            settings: {
              ...prev.settings,
              documentValue: data?.document?.settings?.documentValue,
            },
          }));
        }
      }
      if (!data?.success) {
        toast.error(data?.message || "Something went wrong");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(true);
    }
  };

  const checkFeature = async () => {
    let isEnabled = await isFeatureEnabled("STRIPE_INTEGRATION");
    setIsStripeIntEnabled(isEnabled);
  };

  useEffect(() => {
    checkFeature();
  }, []);

  useEffect(() => {
    if (changed) {
      handleUpdateDocumentValue(localState);
    }
  }, [localState, changed]);

  useEffect(() => {
    handleUpdateAccountDataApi();
  }, []);

  useEffect(() => {
    setWorkSpaceDetails(getWorkSpaceDetails());
  }, [owner]);

  useEffect(() => {
    let count = 0;
    designerState?.schemasList?.map((page) =>
      page.map((schema) => {
        if (schema.subType === "pricing_table") {
          count++;
        }
      })
    );
    setNumTableSchema(count);
  }, [designerState?.schemasList]);

  //   useEffect(() => {
  //     setLocalState(designerState.settings.documentValue);
  //   }, [designerState.settings.documentValue]);
  // console.log({ isStripeIntEnabled });

  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      <div className="px-4 overflow-y-auto h-[100%]">
        <div className="">
          <ViewHeader
            name="Document Value"
            open={open}
            setOpen={setOpen}
            loading={loading}
          />
        </div>
        <p className="text-gray-500 text-xs mb-2">
          Use this to track and report on document value. Set it to the total of
          any/all pricing table(s) or enter a custom amount.
        </p>
        {showUpgradeModal && (
          <PleaseUpgradeModal
            show={showUpgradeModal}
            setShow={setShowUpgradeModal}
            blockingType={"STRIPE_INTEGRATION"}
          />
        )}

        {!workSpaceDetails?.stripeData?.isStripeConnectedAccount && (
          <div className="my-4">
            <div className="text-sm font-semibold">
              Connect with payment gateway
            </div>
            <div className="mt-2 text-xs text-gray-600">
              Simplify how you make and receive payments by connecting a payment
              gateway through which funds can be transferred directly.{" "}
              <a href="" className="text-primary">
                Learn more
              </a>
            </div>
            <div className="mt-2 group rounded border border-gray-200 flex items-center justify-between py-2 px-4 cursor-pointer hover:bg-secondary/30 hover:shadow-sm">
              <div className="flex items-center gap-2">
                <BsStripe size={20} className="text-[#635BFF]" />
                <p className="text-sm group-hover:text-primary">Stripe</p>
              </div>
              <BoloButton
                size={"xs"}
                disabled={loading}
                variant="bolo-secondary"
                className="font-semibold"
                onClick={() => handleConnect()}
              >
                Connect
              </BoloButton>
            </div>
          </div>
        )}

        {workSpaceDetails.stripeData.isStripeConnectedAccount &&
          !workSpaceDetails.stripeData.isStripeAccountActive && (
            <BoloButton
              variant={"primary"}
              size="xs"
              disabled={loading}
              className="w-full"
              onClick={() => handleAccountLink()}
            >
              Activate Stripe Account
            </BoloButton>
          )}

        <div
          className={`mt-6  ${
            (!workSpaceDetails?.stripeData?.isStripeAccountActive ||
              !workSpaceDetails?.stripeData?.isStripeConnectedAccount) &&
            "pointer-events-none opacity-50"
          }`}
        >
          {!isStripeIntEnabled && (
            <div className="rounded-full p-1 px-3 text-[10px] mb-4 text-orange-600 bg-orange-100">
              Note : Stripe Integration not enabled in this plan
            </div>
          )}
          <p className="text-gray-500 text-sm pr-4 uppercase">
            ASSIGNED PAYERS
          </p>
          {designerState?.instanceFrom === "PDF" && (
            <>
              <Select
                value={""}
                onValueChange={(value) => {
                  handleAssignedPayer(value);
                }}
              >
                <SelectTrigger className="w-full px-0 pb-0 outline-none">
                  <SelectValue placeholder="Select Payers" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {/* <SelectLabel>Currency Code</SelectLabel> */}
                    {designerState?.receiversList?.map((role, idx) => {
                      if (
                        designerState?.settings?.documentValue?.selectedPayers?.includes(
                          role?.email
                        )
                      ) {
                        return null;
                      }
                      return (
                        <SelectItem value={role?.email}>
                          <div className="flex gap-2 text-gray-500 text-xs max-w-[220px] min-w-[220px] items-center">
                            <div
                              className="h-3.5 w-3.5 rounded-full"
                              style={{
                                // @ts-ignore
                                border: `2px solid ${role?.colour}`,
                                //@ts-ignore
                                background: role?.colour,
                              }}
                            ></div>
                            <div className="">
                              <div className="text-left px-2 max-w-[190px] min-w-[190px] truncate font-semibold">
                                {role?.name}
                              </div>
                              <div className="text-left text-[11px] px-2 max-w-[190px] min-w-[190px] truncate">
                                {role?.email}
                              </div>
                            </div>
                          </div>
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <div className="mt-2">
                {designerState?.settings?.documentValue?.selectedPayers?.map(
                  (payer) => {
                    let role = designerState?.receiversList?.find(
                      (elm) => elm?.email === payer
                    );
                    return (
                      <div className="py-1 px-2 hover:bg-gray-100 flex gap-2 justify-between text-gray-500 text-xs w-full items-center">
                        <div className="flex gap-2 items-center">
                          <div
                            className="h-3.5 w-3.5 rounded-full"
                            style={{
                              // @ts-ignore
                              border: `2px solid ${role?.colour}`,
                              //@ts-ignore
                              background: role?.colour,
                            }}
                          ></div>
                          <div className="">
                            <div className="text-left px-2 max-w-[190px] min-w-[190px] truncate font-semibold">
                              {role?.name}
                            </div>
                            <div className="text-left text-[11px] px-2 max-w-[190px] min-w-[190px] truncate">
                              {role?.email}
                            </div>
                          </div>
                        </div>
                        <MdClose
                          className="cursor-pointer"
                          size={14}
                          onClick={() => handleRemoveRole(payer)}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}
          {designerState?.instanceFrom === "PDF-TEMPLATE" && (
            <>
              <Select
                value={""}
                onValueChange={(value) => {
                  handleAssignedPayer(value);
                }}
              >
                <SelectTrigger className="w-full px-0 pb-0 outline-none">
                  <SelectValue placeholder="Select Payers by Role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {/* <SelectLabel>Currency Code</SelectLabel> */}
                    {designerState?.roles?.map((role, idx) => {
                      if (
                        designerState?.settings?.documentValue?.selectedPayers?.includes(
                          role?.title
                        )
                      ) {
                        return null;
                      }
                      return (
                        <SelectItem value={role?.title}>
                          <div className="flex gap-2 text-gray-500 text-xs max-w-[220px] min-w-[220px] items-center">
                            <div
                              className="h-3.5 w-3.5 rounded-full"
                              style={{
                                border: `2px solid ${role?.colour}`,
                                background: role?.colour,
                              }}
                            ></div>
                            <div className="text-left px-2 max-w-[180px] min-w-[180px] truncate">
                              {role?.title}
                            </div>
                          </div>
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>
                </SelectContent>
              </Select>
              <div className="mt-2">
                {designerState?.settings?.documentValue?.selectedPayers?.map(
                  (payer) => {
                    let role = designerState?.roles?.find(
                      (elm) => elm?.title === payer
                    );
                    return (
                      <div className="py-1 px-2 hover:bg-gray-100 flex gap-2 justify-between text-gray-500 text-xs w-full items-center">
                        <div className="flex gap-2">
                          <div
                            className="h-3.5 w-3.5 rounded-full"
                            style={{
                              border: `2px solid ${role?.colour}`,
                              background: role?.colour,
                            }}
                          ></div>
                          <div className="text-left px-2 max-w-[180px] min-w-[180px] truncate">
                            {role?.title}
                          </div>
                        </div>
                        <MdClose
                          className="cursor-pointer"
                          size={14}
                          onClick={() => handleRemoveRole(payer)}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}

          <p className="text-gray-500 text-sm pr-4 uppercase mt-6">CURRENCY</p>
          <Select
            value={localState.amount.currency}
            onValueChange={(value) => {
              handleCurrency(value);
            }}
          >
            <SelectTrigger className="w-full px-0 pb-0 outline-none">
              <SelectValue placeholder="Select Currency" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {/* <SelectLabel>Currency Code</SelectLabel> */}
                {BOLO_CURRENCIES?.map((cur, idx) => {
                  return (
                    <SelectItem value={cur.code}>
                      <div className="flex gap-2 text-gray-500 text-xs max-w-[220px] min-w-[220px]">
                        <div className="font-semibold pr-2 min-w-[32px]">
                          {cur.code}
                        </div>
                        <div className="text-left px-2 max-w-[180px] min-w-[180px] truncate">
                          {cur.currency}
                        </div>
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>

          <p className="text-gray-500 text-sm pr-4 uppercase mt-6">SOURCE</p>
          <BoloRadioGroupMui
            value={localState.valueType}
            onChange={(e) => {
              handleValueType(e);
            }}
          >
            <label htmlFor="" className="">
              <div className="flex gap-2 items-center">
                <FormControlLabel
                  sx={{
                    marginRight: 0,
                    "& .MuiSvgIcon-root": {
                      height: 18,
                      width: 18,
                    },
                  }}
                  control={<BoloRadioMui value="CUSTOM" />}
                  label=""
                  value="CUSTOM"
                />
                <p className="text-xs text-gray-500">Custom amount</p>
              </div>
            </label>
            <div className="flex gap-2 items-center">
              <FormControlLabel
                sx={{
                  marginRight: 0,
                  "& .MuiSvgIcon-root": {
                    height: 18,
                    width: 18,
                  },
                }}
                control={<BoloRadioMui value="DOCUMENT_TOTAL" />}
                label=""
                value="DOCUMENT_TOTAL"
              />
              <p className="text-xs text-gray-500">Document Total</p>
            </div>
          </BoloRadioGroupMui>
          {localState.valueType === "CUSTOM" && (
            <div>
              <p className="text-gray-500 text-sm pr-4 uppercase mt-6 mb-1">
                AMOUNT
              </p>
              {!designerState.settings.documentValue.amount.currency && (
                <CustomTooltip
                  helpers={{
                    id: `custom-input-doc-value-view`,
                    content: "Please Select Document Currency",
                    place: "left",
                  }}
                />
              )}
              <div className="flex text-gray-500 text-sm items-end justify-between">
                <div className="w-[40%] text-xs mb-0.5">Document Value</div>
                <BoloInput
                  data-tooltip-id="custom-input-doc-value-view"
                  min={0}
                  disabled={
                    !designerState.settings.documentValue.amount.currency
                  }
                  className="max-w-[48%] px-1 pb-0 text"
                  bolosize={"xs"}
                  type="number"
                  value={value}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                />
                <div className="w-[10%] px-1 text-xs mb-0.5">
                  {localState.amount.currency}
                </div>
              </div>
            </div>
          )}
          {localState.valueType === "DOCUMENT_TOTAL" && (
            <div className="mt-6">
              <p className="text-gray-500 text-sm pr-4 uppercase mb-2">
                PRICING TABLE
              </p>
              <div className="flex flex-col gap-2">
                {numTableSchema === 0 && (
                  <p className="text-gray-600 text-xs">
                    Please add Pricing Table inorder to access document value
                  </p>
                )}
                {designerState?.schemasList?.map((page) =>
                  page.map((schema) => {
                    if (
                      schema.subType !== "pricing_table" ||
                      !isTableSchema(schema)
                    ) {
                      return null;
                    }
                    return (
                      <div
                        data-tooltip-id={`${schema.id}-doc-value-view`}
                        className={`p-1 pr-3 rounded bg-gray-100 text-xs text-gray-500 flex justify-between items-center ${
                          schema.subtotalMenu?.currency !==
                          designerState.settings.documentValue.amount.currency
                            ? "opacity-70"
                            : ""
                        }`}
                      >
                        <div className="flex items-center">
                          {schema.subtotalMenu?.currency !==
                            designerState.settings.documentValue.amount
                              .currency ||
                          !designerState.settings.documentValue.amount
                            .currency ? (
                            <CustomTooltip
                              helpers={{
                                id: `${schema.id}-doc-value-view`,
                                content: !designerState.settings.documentValue
                                  .amount.currency
                                  ? "Please Select Document Currency"
                                  : "Table should have the same currency as the document",
                                place: "left",
                              }}
                            />
                          ) : (
                            <div></div>
                          )}
                          <p>
                            <BoloCheckboxMui
                              onChange={(e) => {
                                handePricingTables({
                                  id: schema.id,
                                  amount:
                                    schema.subtotalMenu?.total.amount || 0,
                                  key: schema.key,
                                  action: e.target.checked ? "add" : "remove",
                                });
                              }}
                              checked={localState.amount.schemas.some(
                                (schema_) => schema_.schemaId === schema.id
                              )}
                              disabled={
                                schema.subtotalMenu?.currency !==
                                designerState.settings.documentValue.amount
                                  .currency
                              }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  height: 18,
                                  width: 18,
                                },
                              }}
                            />
                          </p>
                          <p className="">{schema.key}</p>
                        </div>
                        <div>
                          {formatPrice({
                            amount: schema.subtotalMenu?.total?.amount || 0,
                            currency: schema.subtotalMenu?.currency || "",
                          })}
                        </div>
                      </div>
                    );
                  })
                )}
                <div
                  className={`p-3 mt-4 rounded bg-gray-100 text-xs text-gray-800 flex justify-between items-center `}
                >
                  <div className="flex items-center">
                    <p className="">Document Value</p>
                  </div>
                  <div className="font-semibold text-sm">
                    {formatPrice({
                      amount:
                        designerState.settings.documentValue.amount
                          .valueTotal || 0,
                      currency:
                        designerState.settings.documentValue.amount.currency ||
                        "",
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceView;
