import { useEffect } from "react";
import useActiveElmData from "../../../../../hooks/reduxHooks/useActiveElmData";
import "./ActiveElmArrow.css";

const ActiveElmArrow = ({
  bg,
  text,
  scrollTop,
  handleNext,
  setActiveSchemaById,
}: {
  bg?: string;
  text?: string;
  scrollTop?: number;
  handleNext?: () => void;
  setActiveSchemaById?: (id: string) => void;
}) => {
  const { elmData } = useActiveElmData(scrollTop);

  const ACTION_MAP: { [key: string]: { actionText: string } } = {
    signature: { actionText: "Sign" },
    initials: { actionText: "Fill In" },
    stamp: { actionText: "Stamp" },
    text: { actionText: "Fill In" },
    number: { actionText: "Fill In" },
    date: { actionText: "Select" },
    dropdown: { actionText: "Select" },
    radiogroup: { actionText: "Select" },
    checkbox: { actionText: "Check" },
    file_upload: { actionText: "Upload file" },
  };

  useEffect(() => {
    setActiveSchemaById && setActiveSchemaById(elmData.schema.id);
  }, [elmData.schema.id]);
  return (
    <div
      onClick={() => {
        // console.log("Clicked");
        handleNext && handleNext();
      }}
      className={`${
        elmData.isActive || true
          ? "h-auto w-auto overflow-visible"
          : "max-h-0 max-w-0 p-0 overflow-hidden"
      } cursor-pointer absolute z-[3400] animate-arrow-delayed hidden lg:block`}
      style={{
        // calc explanation
        // |````````````````|
        // |                |
        // |                |
        // |................|
        // say height is 2 top is 10
        // then top + height / 2 for getting in middle
        // -16 for half of the height of the arrow div itself [could also be done using translate]
        // -73 for mt of the parent relative div
        // scroll top is the amount that is scrolled from the div with margin top of 73px
        top:
          elmData.pos.top +
            elmData.pos.height / 2 -
            16 -
            73 +
            (scrollTop ?? 0) || 0,
        left: 10,
      }}
    >
      <div
        className={`shadow-md relative font-semibold p-1 px-2 h-8 w-20 bg-[#f6ca04c4] hover:bg-[#F6C904] text-black flex items-center text-xs arrow-right after:border-l-primary after:border-l-[16px]`}
        // style={
        //   {
        //     background: bg,
        //     color: text,
        //     "--acc-info-theme": bg,
        //   } as React.CSSProperties
        // }
      >
        {ACTION_MAP[elmData?.schema?.subType]?.actionText || "Next"}
      </div>
    </div>
  );
};

export default ActiveElmArrow;
