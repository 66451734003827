import React, { useState } from "react";
import { MdClose, MdLink, MdMailOutline } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../utils/axios";
import { validateEmail } from "../../utils/validateEmail";
import EmailSingleSelect from "./EmailSingleSelect";
interface SendACopyProps {
  documentId: string;
  documentUrl: string;
  documentName: string;
  documentType: string;
  settings: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  active: any;
  setActive: React.Dispatch<React.SetStateAction<any>>;
  email: any;
  setEmail: React.Dispatch<React.SetStateAction<any>>;
}

const SendACopy = ({
  documentId,
  documentUrl,
  documentName,
  documentType,
  settings,
  setOpen,
  active,
  setActive,
  email,
  setEmail,
}: SendACopyProps) => {
  console.log("document settings", settings);

  // const [isSendPasswordProtectedPdf, setIsSendPasswordProtectedPdf] =
  //   useState(false);

  //   const [active, setActive] = useState({
  //     tab: "email",
  //   });
  //   const [email, setEmail] = React.useState({
  //     to: "",
  //     subject: "I am sharing a form with you",
  //     message: "Consider this response document you asked for..",
  //   });

  //   const handleSendEmail = async () => {
  //     console.log("send email");
  //     if (!email.to || !email.subject || !email.message) {
  //       return toast.error("Please fill all fields!");
  //     }
  //     await axios.post("/forms/send-form-email", {
  //       // formId: form?._id,
  //       formId: formId,
  //       email,
  //       ownerName,
  //     });
  //     setShow((prev) => ({ ...prev, sendModal: false }));
  //   };
  const handleCopyLink = (e: any) => {
    navigator.clipboard.writeText(documentUrl);
    e?.target?.select();
  };
  const handleCopyButton = (e: any) => {
    navigator.clipboard.writeText(documentUrl);
  };

  const owner = JSON.parse(localStorage.getItem("owner") || "");

  return (
    <div className="flex justify-center">
      <div className="fade w-[100%] md:w-[590px] max-h-[90vh] overflow-y-auto h-fit bg-white rounded-lg">
        <div className="px-2 md:pl-6 md:pr-4 py-2 md:py-4 flex justify-between">
          <h2 className="text-lg md:text-xl">Send a copy</h2>
          <MdClose
            onClick={() => {
              setOpen(false);
              setActive("email");
            }}
            className="text-2xl text-gray-600 cursor-pointer"
          />
        </div>
        <div className="h-[41px] flex border-b">
          <div
            className={`pl-3 md:pl-6 pr-3 text-[#3C4043] relative h-full text-sm w-fit font-medium flex items-center`}
          >
            Send via
          </div>
          <div
            onClick={() => setActive("email")}
            className={`w-[84px] relative h-full text-sm font-medium flex items-center justify-center cursor-pointer`}
          >
            <MdMailOutline className="text-2xl" />

            {active === "email" && (
              <div
                style={{}}
                className="absolute bottom-0 w-full h-[3px] rounded-t-xl bg-primary"
              ></div>
            )}
          </div>
        </div>
        <div className="pt-6 pb-4">
          {active === "email" && (
            //adding hidden because for now this feature is not available, flex tha maine hidden kiya hai
            <div className="px-3 md:px-6 flex flex-col">
              <h2 className="text-sm md:text-base font-medium text-gray-800">
                Email
              </h2>
              <div className="flex flex-col items-center">
                <p className="mt-6 w-full text-[10px] text-gray-500">To</p>
                <EmailSingleSelect
                  selectedValue={email.to}
                  updateEmailValue={(newValue: any) => {
                    setEmail({
                      ...email,
                      to: newValue.trim(),
                    });
                  }}
                  isCustomStyle={true}
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="mt-4 w-full text-[10px] text-gray-500">Subject</p>
                <input
                  type="text"
                  value={email.subject}
                  placeholder="subject"
                  onChange={(e) => {
                    setEmail({
                      ...email,
                      subject: e.target.value,
                    });
                  }}
                  className="mt-2 text-sm w-full pb-1 text-gray-700 border-b border-gray-200 hover:border-gray-200 disabled:hover:border-white focus:border-b-2 focus:border-primary outline-none"
                />
              </div>
              <div className="flex flex-col items-center">
                <p className="mt-4 w-full text-[10px] text-gray-500">Message</p>
                <input
                  type="text"
                  value={email.message}
                  placeholder="message"
                  onChange={(e) => {
                    setEmail({
                      ...email,
                      message: e.target.value,
                    });
                  }}
                  className="mt-2 text-sm w-full pb-1 text-gray-700 border-b border-gray-200 hover:border-gray-200 disabled:hover:border-white focus:border-b-2 focus:border-primary outline-none"
                />
              </div>
              {settings?.passwordProtection?.enabled && (
                <div className="mt-4">
                  <label className="flex items-center text-sm text-gray-600">
                    <input
                      type="checkbox"
                      className="mr-2"
                      disabled
                      // checked={isSendPasswordProtectedPdf}
                      // onChange={(e) => {
                      //   setIsSendPasswordProtectedPdf(e.target.checked);
                      // }}
                    />
                    Password Protected PDF
                  </label>
                </div>
              )}
              <div className="h-[56px] mt-6 flex items-end justify-end">
                <div className="flex">
                  <button
                    onClick={() => {
                      setOpen(false);
                      setActive("email");
                    }}
                    className="h-[36px] w-[84px] px-2 mr-2 text-gray-600 text-sm flex items-center justify-center cursor-pointer"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={async () => {
                      if (!email.to || !email.message) {
                        return toast.error("Please fill all fields!");
                      }
                      if (!validateEmail(email.to)) {
                        return toast.error("Please enter a valid email!");
                      }
                      let sentMailResponse = await axios.post(
                        "/common/send-a-copy",
                        {
                          // formId: form?._id,
                          documentUrl,
                          documentName,
                          email,
                          ownerEmail: owner?.email,
                          documentId,
                          isSendPasswordProtectedPdf: false,
                        }
                      );
                      if (sentMailResponse?.data?.error) {
                        return toast.error(sentMailResponse?.data?.error);
                      } else {
                        toast.success("Email sent successfully");
                      }
                    }}
                    className="h-[36px] w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer text-primary"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* {active === "link" && (
            <div className="px-6 flex flex-col">
              <h2 className="text-base font-medium text-gray-800">Link</h2>
              <div className="flex flex-col items-center mt-2">
                <input
                  type="text"
                  value={documentUrl}
                  onChange={(e) => {}}
                  onClick={handleCopyLink}
                  placeholder="subject"
                  className="mt-2 text-sm w-full pb-1 text-gray-700 border-b border-gray-200 focus:border-b-2 focus:border-primary outline-none"
                />
              </div>
              <div className="h-[56px] mt-4 flex justify-end items-end">
                <div className="flex">
                  <button
                    onClick={() => {
                      setOpen(false);
                      setActive("email");
                    }}
                    className="h-[36px] w-[84px] px-2 mr-2 text-gray-600 text-sm flex items-center justify-center cursor-pointer"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleCopyButton}
                    className="h-[36px] w-[84px] px-2 text-sm rounded border flex items-center justify-center cursor-pointer text-primary"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
      {/* <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        theme="dark"
      /> */}
    </div>
  );
};

export default SendACopy;
