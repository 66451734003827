import React, {
  useContext,
  forwardRef,
  ReactNode,
  Ref,
  useEffect,
} from "react";
// import { Size } from '@pdfme/common';
import { FontContext } from "../contexts";
import Spinner from "./Spinner";
import { Size } from "../../../common/src";
import { InfinitySpin } from "react-loader-spinner";
import { getWhiteLabelInfoLocal } from "../../../../utils";
import BoloLoader from "../../../../components/Common/Loader/BoloLoader";
import { COLORS } from "../../../../constants/common";

type Props = {
  size: Size;
  scale: number;
  children: ReactNode;
  calcHeight: number;
};

const Root = (
  { size, scale, children, calcHeight }: Props,
  ref: Ref<HTMLDivElement>
) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const primaryColor =
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary;
  const font = useContext(FontContext);

  useEffect(() => {
    if (!document || !document.fonts) return;
    const fontFaces = Object.entries(font).map(
      ([key, { data }]) =>
        new FontFace(key, typeof data === "string" ? `url(${data})` : data, {
          display: "swap",
        })
    );
    // @ts-ignore
    const newFontFaces = fontFaces.filter(
      (fontFace) => !document.fonts.has(fontFace)
    );

    Promise.allSettled(newFontFaces.map((f) => f.load())).then(
      (loadedFontFaces) => {
        loadedFontFaces.forEach((loadedFontFace) => {
          if (loadedFontFace.status === "fulfilled") {
            // @ts-ignore
            document.fonts.add(loadedFontFace.value);
          }
        });
      }
    );
  }, [font]);

  return (
    <div
      ref={ref}
      // ! Note :- Remove W-full if any UI issues found
      className="relative bg-gray-200 overflow-x-hidden w-full overflow-y-hidden"
      // style={{ height: "calc(100vh - 32px)" }}
    >
      <div
        style={{
          margin: "0 auto",
          // ...size,
          height: `calc(100svh - ${calcHeight || 0}px)`,
        }}
      >
        {scale === 0 ? (
          <div className="flex h-full items-center justify-center">
            <BoloLoader />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default forwardRef<HTMLDivElement, Props>(Root);
