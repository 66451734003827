import React, { useEffect, useState } from "react";
import { IMAGE_LINKS } from "../../constants/common";
import { IoPersonCircleOutline } from "react-icons/io5";
import { PiNumberCircleOne, PiNumberCircleTwo } from "react-icons/pi";
import { TbCircleNumber1, TbCircleNumber2 } from "react-icons/tb";
import { RiNumber1, RiNumber2 } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import { useAppSelector } from "../../redux/store";
import { BASE_URL } from "../../constants/constants";
import axios from "../../utils/axios";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

interface AccessCodeVerifyProps {
  accessKeyInput: string;
  setAccessKeyInput: React.Dispatch<React.SetStateAction<string>>;
  setVerifyClicked: React.Dispatch<React.SetStateAction<boolean>>;
  verifying: boolean;
  wrongAccessCode: boolean;
  isVerified: boolean;
}

const AccessCodeVerify = ({
  accessKeyInput,
  setAccessKeyInput,
  setVerifyClicked,
  verifying,
  wrongAccessCode,
  isVerified,
}: AccessCodeVerifyProps) => {
  const [searchParams] = useSearchParams();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const key = searchParams.get("key");

  const [accountInfo, setAccountInfo] = useState<any>({});

  const [authorEmail, setAuthorEmail] = useState("");

  const getBrandingInfo = async () => {
    try {
      let { data } = await axios.post(`${BASE_URL}/owner/get-branding-info`, {
        key,
      });
      console.log(data);
      if (data?.accountInfo) {
        setAccountInfo(data.accountInfo);
        setAuthorEmail(data.authorEmail);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBrandingInfo();
  }, [key]);

  return (
    <div className="w-screen h-screen flex flex-col gap-4 p-6 md:p-0 items-center ">
      <Helmet>
        <title translate="no">Pdf Template: Access Code</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Pdf template Signing" />
        <meta
          name="description"
          content="Signer's dedicated page for completing and adding signatures to the pdf template document."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/sign-pdf-template`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Pdf template Signing - Boloforms`}
        />
        <meta
          property="og:description"
          content="Signer's dedicated page for completing and adding signatures to the pdf template document."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/sign-pdf-template"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="h-fit flex flex-col gap-3 w-full text-sm cursor-pointer whitespace-nowrap items-center mt-6 md:mt-12">
        <img
          src={
            accountInfo?.companyLogo ||
            whiteLabelInfo.settings?.organization?.logo ||
            IMAGE_LINKS.BOLOLOGO
          }
          alt=""
          className="h-20 w-20"
        />
        <div className="flex items-center justify-center flex-col gap-4 my-8">
          <div className="flex items-center justify-center w-full gap-2">
            <span
              className="p-2 rounded-full bg-primary"
              style={{
                backgroundColor: isVerified
                  ? "#22c55e"
                  : wrongAccessCode
                  ? "#ef4444"
                  : accountInfo?.theme,
              }}
            >
              <RiNumber1 size={16} className="text-white" />
            </span>
            <p className="h-[1px] w-[150px] md:w-[200px] bg-gray-300 relative">
              {wrongAccessCode && (
                <MdClose
                  size={20}
                  className="text-red-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              )}
            </p>
            <span className="p-2 rounded-full bg-gray-400">
              {" "}
              <RiNumber2 size={16} className="text-white" />
            </span>
          </div>
          <div className="flex items-center w-full gap-2 justify-center">
            <span className="text-gray-800 font-semibold">
              Code Verification
            </span>
            <p className="h-[1px] w-[60px] md:w-[120px]"></p>
            <span className="text-gray-400">Sign Document</span>
          </div>
        </div>
      </div>

      {/* <div className="flex flex-col w-full"> */}
      {/* <p className="mr-auto text-sm font-medium pt-12 pl-6">
          Create your own documents.{" "}
          <span className="text-primary">Log in to BoloForms</span>
        </p> */}
      <div className="  w-fit ">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Access Code Verification
        </h2>
        <div className="text-gray-600">
          <p className="text-xs leading-relaxed  inline md:flex">
            The sender has requested you enter a secret access code prior to
          </p>
          <p className="text-xs leading-relaxed inline md:flex">
            reviewing the document. You should have received an access code in a
          </p>
          <p className="text-xs leading-relaxed  inline md:flex">
            separate communication. Please enter the code and validate it in
            order
          </p>
          <p className="text-xs leading-relaxed  inline md:flex">
            to proceed to viewing the document.
          </p>
        </div>

        <div className="mt-8 text-left">
          <p className="text-xs font-medium mb-[2px]">
            Did not receive any access code?{" "}
            <a
              href={`mailto:${authorEmail}?subject=Access%20Code%20Issue&body=Hello,%0D%0A%0D%0AI did not receive my access code.%0D%0A%0D%0AKind regards`}
              className="text-primary cursor-pointer"
              style={{ color: accountInfo?.theme }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact owner
            </a>
          </p>

          <input
            type="text"
            className="w-full border border-gray-300 rounded-md py-2 px-3 mb-2 outline-none"
            placeholder="Access Code"
            style={{
              borderColor: wrongAccessCode ? "red" : isVerified ? "green" : "",
            }}
            disabled={verifying || isVerified}
            value={accessKeyInput}
            onChange={(e) => setAccessKeyInput(e.target.value)}
          />
          {wrongAccessCode && (
            <p className={`text-xs text-red-500 font-medium `}>
              Sorry, the code is wrong. Please try again or contact the owner.
            </p>
          )}
          {isVerified && (
            <p className={`text-xs text-green-500 font-medium`}>
              Access Code Verified Successfully!
            </p>
          )}
        </div>
        <button
          disabled={
            accessKeyInput.length === 0 ||
            verifying ||
            wrongAccessCode ||
            isVerified
          }
          type="submit"
          className="w-full bg-primary text-white rounded-md py-2 text-sm mt-4 font-medium transition-all duration-300 ease-in-out tracking-wide disabled:opacity-50 "
          style={{
            color: accountInfo?.themeText,
            backgroundColor: accountInfo?.theme,
          }}
          onClick={() => {
            setVerifyClicked((prev) => !prev);
          }}
        >
          {verifying ? "Verifying..." : "Verify Code"}
        </button>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AccessCodeVerify;
