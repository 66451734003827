//@ts-nocheck

import React, { useRef } from "react";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import SignatureCanvas from "react-signature-canvas";
import QuestionBottomOptions from "../../QuestionBottomOptions";
import QuestionInput from "../../QuestionInput";
import QuestionDesc from "../../QuestionDesc";
import { FaSignature } from "react-icons/fa";
import ImageQuestionPreview from "../../ImageQuestionPreview";

const Signature = ({ data }) => {
  let {
    question,
    setFormState,
    isEditor,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
    formState,
    schemas,
  } = data;
  const signaturePad = useRef(null);

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <QuestionInput
            helpers={{
              question,
              setFormState,
              isEditor,
              questionIndex,
              activeState,
            }}
          />
        </div>

        <QuestionDesc
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
      </div>
      <ImageQuestionPreview
        helpers={{ question, setFormState, questionIndex, activeState }}
      />
      <div className="mb-10">
        <div className="w-[30%] relative">
          <input
            type="text"
            className={`w-full mt-1 py-1 text-sm text-[#70757a] outline-none`}
            disabled
            defaultValue={"Signature"}
          />
          <FaSignature className="text-2xl text-gray-400 absolute top-[7px] right-0" />
        </div>

        <div className="w-full mt-2 p-2 border rounded-md">
          <SignatureCanvas
            penColor="#262626"
            canvasProps={{
              width: 700,
              height: 200,
              className: "sigCanvas",
            }}
            ref={(ref) => {
              signaturePad.current = ref;
            }}
            onEnd={() => {}}
          />
        </div>
        {questionIndex.p === activeState.pageIndex &&
          questionIndex.q === activeState.questionIndex && (
            <button
              onClick={() => {
                signaturePad.current.clear();
              }}
              style={{
                color: formState?.accentColor,
              }}
              className="mt-2 h-[36px] px-6 text-xs rounded border flex items-center justify-center cursor-pointer border-gray-300"
            >
              Clear
            </button>
          )}
      </div>

      <QuestionBottomOptions
        helpers={{
          question,
          setFormState,
          questionIndex,
          activeState,
          setActiveState,
          setActive,
          formState,
          schemas,
        }}
      />
    </div>
  );
};

export default Signature;
