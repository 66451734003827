//@ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import NoFolders from "../NoFolders";
import axios from "../../utils/axios";
import Modal from "../Modal";
import {
  MdClose,
  MdDriveFileRenameOutline,
  MdFolder,
  MdFolderOpen,
  MdOutlineDeleteOutline,
  MdOutlineDriveFileMoveRtl,
} from "react-icons/md";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import CreateFolderModal from "../CreateFolderModal";
import { BsSearch, BsThreeDotsVertical } from "react-icons/bs";
import MoveItemModal from "../MoveItemModal";
import DeleteFolderModal from "../DeleteFolderModal";
import RenameItemModal from "../RenameFolderModal";
import { AiFillEdit } from "react-icons/ai";
import { FaEye, FaPlus, FaUserEdit } from "react-icons/fa";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
import { isFeatureEnabled } from "../../utils/paidFeat";
import { BLOCKING } from "../../constants/blockingConsants";
import { getWorkSpaceDetails } from "../../utils";
import { FaUserGear } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import BoloLoader from "../Common/Loader/BoloLoader";
import { BoloButton } from "../Common/Button/BoloButton";
import NoDocumentsV1 from "../NoDocuments/NoDocumentsV1";

const Options = ({
  activeOption,
  setActiveOption,
  item,
  setMoveItem,
  setMoveItemModal,
  setDeleteItemModal,
  setDeleteItem,
  setRenameItem,
  setRenameItemModal,
  roleInCollaboration,
  isSharedPage,
  isFolderEnabled,
  setUpgradeModal,
  workSpaceDetails,
}) => {
  const selfRef = useRef();
  // console.log({ activeOption, item });

  // console.log("Outside handle click", { activeOption, id: item?._id });
  const handleOutsideClick = (e) => {
    // console.log("Inside handle click", { activeOption, id: item?._id });
    if (item?._id == activeOption) {
      // we get inside this code only if the option is active
      if (selfRef.current !== null) {
        if (selfRef.current.contains(e.target)) return;
      }
      setActiveOption(() => null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [activeOption]);

  return (
    <div ref={selfRef} className="">
      <span
        className={`m-auto hover:text-gray-800 text-gray-600`}
        onClick={(e) => {
          e.stopPropagation();
          if (activeOption === item?._id) {
            setActiveOption(() => null);
            return;
          } else {
            setActiveOption(() => item?._id);
          }
        }}
      >
        {/* <span className="flex flex-col">
          <span className="text-xs">{"*" + activeOption}</span>
          <span className="text-xs">{item?._id}</span>
          <span className="text-xs">
            {item?._id == activeOption ? "true" : "false"}
          </span>
        </span> */}
        <BsThreeDotsVertical className="" />
      </span>
      {item?._id == activeOption && (
        <div
          className={`translate-x-[calc(-100%_+_1.5rem)] z-[40] absolute border border-gray-300 rounded-lg shadow-lg w-[200px] cursor-pointer bg-white`}
        >
          <div className="flex flex-col items-start justify-start ">
            <button
              className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-secondary`}
              // disabled={isSharedPage && roleInCollaboration === "readonly"}
              disabled={
                !workSpaceDetails?.isWorkSpaceOwner &&
                workSpaceDetails?.currentWorkSpaceRole === "readonly"
              }
              onClick={(e) => {
                e.stopPropagation();
                new MixPanel().track("Folder", {
                  folder_action: "folder_move",
                  paid: false,
                });
                new MixPanel().increment("folder_move");

                if (!isFolderEnabled) {
                  setUpgradeModal({
                    show: true,
                    data: {
                      title: BLOCKING.FOLDER.title,
                      desc: BLOCKING.FOLDER.desc,
                      redirect: BLOCKING.FOLDER.redirect,
                      videoSrc: "",
                      redirectText: BLOCKING.FOLDER.redirectText,
                    },
                  });
                  return;
                }

                setMoveItem({
                  id: item?._id,
                  type: "FOLDER",
                  value: item,
                });
                setMoveItemModal(true);
                setActiveOption(null);
              }}
            >
              <MdOutlineDriveFileMoveRtl className="text-xl text-gray-600 hover:opacity-85 mr-2" />
              Move
            </button>
            <button
              className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-secondary`}
              // disabled={isSharedPage && roleInCollaboration === "readonly"}
              disabled={
                !workSpaceDetails?.isWorkSpaceOwner &&
                workSpaceDetails?.currentWorkSpaceRole === "readonly"
              }
              onClick={(e) => {
                e.stopPropagation();
                new MixPanel().track("Folder", {
                  folder_action: "folder_rename",
                  paid: false,
                });
                new MixPanel().increment("folder_rename");
                if (!isFolderEnabled) {
                  setUpgradeModal({
                    show: true,
                    data: {
                      title: BLOCKING.FOLDER.title,
                      desc: BLOCKING.FOLDER.desc,
                      redirect: BLOCKING.FOLDER.redirect,
                      videoSrc: "",
                      redirectText: BLOCKING.FOLDER.redirectText,
                    },
                  });
                  return;
                }

                setRenameItemModal(true);
                setRenameItem({
                  id: item?._id,
                  type: "FOLDER",
                  value: item,
                });
                setActiveOption(null);
              }}
            >
              <MdDriveFileRenameOutline className="text-xl text-gray-600 hover:opacity-85 mr-2" />
              Rename
            </button>
            <button
              className={`flex flex-row items-center text-center text-xs capitalize p-3 text-red-600 w-full font-medium hover:bg-secondary`}
              // disabled={isSharedPage && roleInCollaboration === "readonly"}
              disabled={
                !workSpaceDetails?.isWorkSpaceOwner &&
                (workSpaceDetails?.currentWorkSpaceRole === "readonly" ||
                  workSpaceDetails?.currentWorkSpaceRole === "editor")
              }
              onClick={(e) => {
                e.stopPropagation();
                new MixPanel().track("Folder", {
                  folder_action: "folder_delete",
                  paid: false,
                });
                new MixPanel().increment("folder_delete");
                if (!isFolderEnabled) {
                  setUpgradeModal({
                    show: true,
                    data: {
                      title: BLOCKING.FOLDER.title,
                      desc: BLOCKING.FOLDER.desc,
                      redirect: BLOCKING.FOLDER.redirect,
                      videoSrc: "",
                      redirectText: BLOCKING.FOLDER.redirectText,
                    },
                  });
                  return;
                }

                setDeleteItemModal(true);
                setDeleteItem({
                  id: item?._id,
                  type: "FOLDER",
                  value: item,
                });
                setActiveOption(null);
              }}
            >
              <MdOutlineDeleteOutline className="text-xl text-red-600 hover:opacity-85 mr-2" />
              Delete Folder
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const FoldersPage = () => {
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [items, setItems] = useState([]);
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const [activeOption, setActiveOption] = useState("");
  const [moveItemModal, setMoveItemModal] = useState(false);
  const [moveItem, setMoveItem] = useState({});
  const [deleteItemModal, setDeleteItemModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [renameItemModal, setRenameItemModal] = useState(false);
  const [renameItem, setRenameItem] = useState({});
  const [udpateDone, setUpdateDone] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [roleInCollaboration, setRoleInCollaboration] = useState("owner");
  const [sharedAdmins, setSharedAdmins] = useState<any[]>([]);
  const [whichAdmin, setWhichAdmin] = useState("");
  const [whichOwner, setWhichOwner] = useState({});
  const [isFolderEnabled, setIsFolderEnabled] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState({
    show: false,
    data: { title: "", desc: "", videoSrc: "", redirect: "", redirectText: "" },
  });
  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const getTopLevelFolder = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post("/folder/get-by-parent", {
        ownerId: location.pathname.includes("shared-with-me")
          ? whichOwner?._id
          : owner?._id,
      });
      if (data) {
        const { folders } = data;
        setItems(folders || []);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchFolderByName = async (e) => {
    setIsLoading(true);
    //Clear the items before searching
    setItems([]);
    e && e.preventDefault();
    try {
      if (searchTerm?.trim()?.length == 0) {
      }
      const { data } = await axios.post("/folder/get-by-name", {
        ownerId: location.pathname.includes("shared-with-me")
          ? whichOwner?._id
          : owner?._id,
        name: searchTerm?.trim(),
      });
      if (data) {
        const { folders } = data;
        setItems(folders || []);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("Inside parent", { activeOption });

  const checkIfFolderEnabled = async () => {
    // ----------------------- make sure to discuss for shared with
    const isFolder = await isFeatureEnabled("FOLDER_ORG");
    // now the shared cannot access folders if they dont have folder access
    setIsFolderEnabled(isFolder);
  };

  useEffect(() => {
    if (!searchTerm) {
      // Refresh the base folders list
      setItems([]);
      getTopLevelFolder();
    }
    checkIfFolderEnabled();
  }, [udpateDone, searchTerm, whichOwner, whichAdmin, location.pathname]);

  useEffect(() => {
    owner?.addedBy && setSharedAdmins(owner?.addedBy);
    owner?.addedBy && setWhichAdmin(owner?.addedBy?.[0]?.ownerId?.email);
    owner?.addedBy && setWhichOwner(owner?.addedBy?.[0]?.ownerId);
    owner?.addedBy && setRoleInCollaboration(owner?.addedBy?.[0]?.role);
  }, []);

  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      setWorkSpaceDetails(localWorkSpaceDetails);
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  return (
    <div className="pb-16 -m-4 bg-white">
      <Helmet>
        <title translate="no">Folders</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Folders" />
        <meta
          name="description"
          content="Organize, manage, and access your files effortlessly with the Folder Page on BoloForms."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/folder`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Folders`} />
        <meta
          property="og:description"
          content="Organize, manage, and access your files effortlessly with the Folder Page on BoloForms."
        />
        <link rel="canonical" href="https://signature.boloforms.com/folder" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="border-b-2">
        {upgradeModal.show && (
          <PleaseUpgradeModal
            setShow={(value) => {
              setUpgradeModal((prev) => ({ ...prev, show: value }));
            }}
            blockingType={"FOLDER_ORG"}
            show={upgradeModal.show}
            data={{}}
          />
        )}
        {moveItemModal && (
          <MoveItemModal
            item={moveItem}
            fromFolder={null}
            setShow={setMoveItemModal}
            stateUpdate={setUpdateDone}
          />
        )}
        {deleteItemModal && (
          <DeleteFolderModal
            item={deleteItem}
            setShow={setDeleteItemModal}
            stateUpdate={setUpdateDone}
          />
        )}
        {createFolderModal && (
          <CreateFolderModal
            setShow={setCreateFolderModal}
            parentFolder={searchParams.get("folderId")}
            stateUpdate={setUpdateDone}
            isShared={location.pathname.includes("shared-with-me")}
            sharedOwnerId={whichOwner?._id}
          />
        )}
        {renameItemModal && (
          <RenameItemModal
            setShow={setRenameItemModal}
            item={renameItem}
            stateUpdate={setUpdateDone}
          />
        )}
        <div className="px-3 md:px-4 md:h-[40px] my-4 sm:my-6 text-gray-600 flex flex-col md:flex-row gap-2 md:items-center items-end justify-between w-full">
          <form className="w-full md:w-fit text-xs">
            <div
              className={`h-full flex flex-row rounded-md overflow-hidden ml-auto  md:w-[500px] border`}
            >
              <input
                placeholder="Search by folder title..."
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full h-full py-2 px-4   outline-none text-gray-700 "
              />
              <button
                className="min-w-[40px] flex justify-center items-center text-gray-500 cursor-pointer hover:opacity-90"
                onClick={(e) => {
                  e.preventDefault();
                  // Search only when search input is not empty
                  if (searchTerm.trim()?.length > 0) {
                    searchFolderByName(e);
                    new MixPanel().track("Folder", {
                      folder_action: "folder_search",
                      paid: false,
                    });
                    new MixPanel().increment("folder_search");
                  }
                }}
              >
                <BsSearch className="text-xl" />
              </button>
            </div>
          </form>
          <BoloButton
            size={"sm"}
            className="h-[32px] whitespace-nowrap text-xs md:text-sm flex gap-3 items-center"
            // className="h-[36px] whitespace-nowrap text-xs text-white bg-primary px-4 rounded-md disabled:opacity-50 disabled:cursor-default"
            // disabled={
            //   location.pathname.includes("shared-with-me") &&
            //   roleInCollaboration == "readonly"
            // }
            disabled={
              !workSpaceDetails?.isWorkSpaceOwner &&
              workSpaceDetails?.currentWorkSpaceRole === "readonly"
              // ||  workSpaceDetails?.currentWorkSpaceRole === "editor"
            }
            onClick={() => {
              if (!isFolderEnabled) {
                setUpgradeModal({
                  show: true,
                  data: {
                    title: BLOCKING.FOLDER.title,
                    desc: BLOCKING.FOLDER.desc,
                    redirect: BLOCKING.FOLDER.redirect,
                    videoSrc: "",
                    redirectText: BLOCKING.FOLDER.redirectText,
                  },
                });
                return;
              }
              setCreateFolderModal(true);
            }}
          >
            <FaPlus /> Create Folder
          </BoloButton>
        </div>
      </div>
      <div className="grid gap-2 grid-cols-1 md:grid-cols-4 text-xs md:text-sm p-4">
        {items?.map((item, itemIdx) => {
          return (
            <div
              className="border px-4 py-2 rounded cursor-pointer flex gap-4 items-center justify-between"
              key={itemIdx}
              onClick={() => {
                if (!isFolderEnabled) {
                  setUpgradeModal({
                    show: true,
                    data: {
                      title: BLOCKING.FOLDER.title,
                      desc: BLOCKING.FOLDER.desc,
                      redirect: BLOCKING.FOLDER.redirect,
                      redirectText: BLOCKING.FOLDER.redirectText,
                      videoSrc: "",
                    },
                  });
                  return;
                }

                if (location.pathname.includes("shared-with-me")) {
                  navigate(
                    `/shared-with-me/folder/items?folderId=${item?.folderId}&?whichAdmin=${whichAdmin}`
                  );
                } else {
                  navigate(`/folder/items?folderId=${item?.folderId}`);
                }
              }}
            >
              <div className="flex items-center gap-4 ">
                <MdFolder
                  className="h-6 md:h-6 w-6 md:w-6 flex-shrink-0"
                  size={30}
                  style={{ color: item?.color || "#3c4043" }}
                />
                <span className="break-all text-xs">{item?.name}</span>
              </div>
              <Options
                setActiveOption={setActiveOption}
                activeOption={activeOption}
                item={item}
                setMoveItem={setMoveItem}
                setMoveItemModal={setMoveItemModal}
                setDeleteItem={setDeleteItem}
                setDeleteItemModal={setDeleteItemModal}
                setRenameItem={setRenameItem}
                setRenameItemModal={setRenameItemModal}
                roleInCollaboration={roleInCollaboration}
                isSharedPage={location.pathname.includes("shared-with-me")}
                isFolderEnabled={isFolderEnabled}
                setUpgradeModal={setUpgradeModal}
                workSpaceDetails={workSpaceDetails}
              />
            </div>
          );
        })}
      </div>
      {isLoading && <BoloLoader />}
      {!isLoading &&
        items?.length == 0 &&
        !location.pathname.includes("shared-with-me") && (
          // <NoFolders
          //   helpers={{
          //     onClickHandler: () => {
          //       if (workSpaceDetails?.currentWorkSpaceRole !== "readonly") {
          //         setCreateFolderModal(true);
          //       }
          //     },
          //   }}
          // />
          <div className=" w-full pt-5 flex items-center justify-center">
            <NoDocumentsV1
              resourceType="FOLDER"
              cb={() => {
                if (workSpaceDetails?.currentWorkSpaceRole !== "readonly") {
                  setCreateFolderModal(true);
                } else {
                  toast.error(
                    "Readonly role are not allowed to create Folders"
                  );
                }
              }}
            />
          </div>
        )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default FoldersPage;
