//@ts-nocheck
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import { validateEmail } from "../../utils/validateEmail";
import axios from "../../utils/axios";
import { GoDownload } from "react-icons/go";
import { FcGoogle } from "react-icons/fc";
import { BASE_URL } from "../../constants/constants";
import BoloLoader from "../Common/Loader/BoloLoader";
const GoogleContactsModal = ({ setModalType }) => {
  const [isContactsPermission, setIsContactsPermission] = useState(false);
  let [owner, setOwner] = useState<any>({});
  const expectedFields = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "company",
    "jobTitle",
    "streetAddress",
    "city",
    "postalCode",
    "country",
    "state",
    "source",
  ];
  const [contactsList, setContactsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadBtn, setUploadBtn] = useState("Import Contacts");
  const [uploadStatus, setUploadStatus] = useState(false);
  const [validContacts, setValidContacts] = useState([]);
  const [invalidContacts, setInvalidContacts] = useState([]);
  async function getGoogleTokenDetails() {
    try {
      let { data } = await axios.get(
        `/owners/google-permission?permission=contacts`
      );
      setIsLoading(false);
      setIsContactsPermission(data?.isPermission);
    } catch (error) {
      console.log(`getGoogleTokenDetails: ${error}`);
    }
  }
  const verifiedContacts = (data) => {
    // Remove the duplicate emails form the csvData
    const uniqueEmails = [...new Set(data.map((contact) => contact.email))];
    const validData = [];
    let invalidData = [];
    for (let i = 0; i < uniqueEmails.length; i++) {
      const email = uniqueEmails[i];
      const contact = data.find((contact) => contact.email === email);
      if (validateEmail(email)) {
        validData.push(contact);
      } else {
        invalidData.push(contact);
      }
    }
    setInvalidContacts(invalidData);
    setValidContacts(validData);
    return validData;
  };
  useEffect(() => {
    getGoogleTokenDetails();
    let owner: any = JSON.parse(localStorage.getItem("owner") || "");
    setOwner(owner);
  }, []);
  const addContactsBulk = async () => {
    setUploadBtn("Importing Contacts...");
    const contacts = verifiedContacts(contactsList);
    setUploadStatus(true);
    try {
      const response = await axios.post("/workspace/bulkContactAdd", {
        contacts: contacts,
      });
      const data = response?.data;
      if (data) {
        if (!data.success) {
          toast.error("Something went wrong during bulk contact addition");
          return;
        }
        toast.success("Contacts added successfully!");
        // setModalType("");
        setUploadStatus(true);
      }
    } catch (error) {
      console.error("Error during bulk contact addition:", error);
      toast.error("Error during bulk contact addition");
    }
  };

  // Assuming response.data is an array of objects
  const updatedData = (data) => {
    return data.map((contact) => {
      const updatedContact = {};

      // Splitting the name field into firstName and lastName
      if (contact.name) {
        const nameParts = contact.name.split(" ");
        updatedContact.firstName = nameParts[0] || ""; // If there's no firstName, use an empty string
        updatedContact.lastName = nameParts.slice(1).join(" ") || ""; // Join the remaining parts as lastName, if there's none, use an empty string
      } else {
        updatedContact.firstName = "";
        updatedContact.lastName = "";
      }

      // Loop through expected fields (excluding firstName, lastName, and email) and add them to the updated contact
      expectedFields
        .filter((field) => !["firstName", "lastName", "email"].includes(field))
        .forEach((field) => {
          updatedContact[field] = contact[field] || ""; // If the field exists in the contact, use its value, otherwise use an empty string
        });

      updatedContact.email = contact.email || ""; // Keep the email field as it is

      updatedContact.source = "GOOGLE_CONTACT"; // Add source field with value "GOOGLE_CONTACT"

      return updatedContact;
    });
  };
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        let owner: any = JSON.parse(localStorage.getItem("owner") || "");
        console.log(owner);
        const ownerId = owner?._id;
        const response = await axios.get(
          `/common/search-contacts?ownerId=${ownerId}`
        );
        const completeData = updatedData(response?.data);
        setContactsList(completeData);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For downloading the csv file of the incorrect contacts for updating and reupload
  const downloadIncorrectContacts = () => {
    const contacts = invalidContacts;
    // Map the invalidContacts data into CSV format
    const csvDataNew = [
      expectedFields.join(","), // Header Row
      ...contacts.map((contact) =>
        expectedFields.map((field) => contact[field] || "").join(",")
      ),
    ].join("\n");
    const blob = new Blob([csvDataNew], {
      type: "text/csv",
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `contacts_correction_data.csv`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  return (
    <>
      <div className="flex fixed top-0 left-0 z-[51] w-full h-full p-2 md:p-8 bg-[#00000091] justify-center items-start overflow-y-auto">
        <div className="bg-white  md:rounded-md rounded-sm  max-w-xl w-full min-h-[464px]">
          <div className="flex justify-between items-center mb-6 border-b py-2 md:py-4 md:px-8 px-4 bg-gray-50 md:rounded-t-md rounded-t-sm">
            <h2 className=" text-xl md:text-xl font-medium tracking-tight">
              Upload Contacts
            </h2>
            <GrClose
              className="text-xl hover:cursor-pointer"
              onClick={() => setModalType("")}
            />
          </div>
          {isLoading && (
            <div className="flex justify-center items-center flex-col">
              <div className="mb-4 m-auto">
                <BoloLoader />
              </div>
              <p className="text-gray-500 text-sm">
                Verifying Google Contacts permissions.
              </p>
              <p className="text-gray-500 text-sm">Please wait...</p>
            </div>
          )}
          {!isLoading && (
            <div className="flex flex-col md:px-8 px-4 pb-4 md:pb-8">
              {!isContactsPermission && (
                <div className="mt-2 mb-2 md:mb-8 text-center md:px-8 px-4">
                  <h1 className="text-[16px] md:text-[24px] font-bold">
                    Grant Access to Google Contacts
                  </h1>
                  <p className="text-xs md:text-sm text-gray-600 mt-1">
                    Effortlessly Import and Utilize Your Google Contacts
                  </p>
                  <button
                    onClick={() =>
                      window.open(
                        `${BASE_URL}/auth/google?scopesType=contacts&redirectUrl=${"/contacts"}&ownerEmail=${
                          owner?.email
                        }`,
                        "_SELF"
                      )
                    }
                    type="button"
                    className="w-fit my-6 flex flex-row items-center justify-center py-2 px-4 p rounded-md bg-white border-[1px] border-gray-200 cursor-pointer hover:bg-secondary hover:border-secondary shadow-md m-auto"
                  >
                    <FcGoogle className="text-xl" />
                    <span className="text-xs ml-2">
                      Grant Contacts Permission
                    </span>
                  </button>
                </div>
              )}
              {isContactsPermission && !uploadStatus && (
                <>
                  <div class="mt-2 mb-2 md:mb-8 text-center md:px-8 px-4">
                    <h1 class="text-[16px] md:text-[24px] font-bold">
                      Google Contacts Status
                    </h1>
                    <p class="text-xs md:text-sm text-gray-600 mt-1">
                      We have discovered <strong>{contactsList?.length}</strong>{" "}
                      contacts with associated emails.
                    </p>
                  </div>

                  <div className="text-center mt-4">
                    <button
                      className="px-6 py-2 bg-primary text-xs md:text-sm text-white  rounded-lg hover:opacity-90 md:mr-4 disabled:opacity-50 w-auto self-center my-2"
                      disabled={uploadBtn !== "Import Contacts"}
                      onClick={addContactsBulk}
                    >
                      {uploadBtn}
                    </button>
                  </div>
                </>
              )}
              {isContactsPermission && uploadStatus && (
                <>
                  <div class="mt-2 mb-2 md:mb-8 text-center md:px-8 px-4">
                    <h1 class="text-[16px] md:text-[24px] font-bold">
                      Google Contacts Upload Status
                    </h1>
                    <p className="text-xs md:text-sm text-gray-600 mt-1">
                      <strong>{validContacts?.length}</strong> contacts uploaded
                      out of {contactsList?.length}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="pb-2 text-[#4BB71B] flex justify-center items-center flex-col gap-3">
                      <svg
                        className="checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                      >
                        <circle
                          className="checkmark__circle"
                          cx="26"
                          cy="26"
                          r="25"
                          fill="none"
                        />
                        <path
                          className="checkmark__check"
                          fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"
                        />
                      </svg>
                    </p>
                  </div>
                  {invalidContacts.length > 0 && (
                    <div className="text-center mt-4">
                      <p className="text-xs md:text-sm text-red-600 mt-1">
                        {invalidContacts?.length} contacts were not uploaded due
                        to incorrect email format.
                      </p>
                      <p className="text-xs md:text-sm text-gray-600 mt-1">
                        Download incorrect data, update, and re-upload.
                      </p>
                      <button
                        className="px-6 py-2 bg-primary text-xs md:text-sm text-white  rounded-lg hover:opacity-90 md:mr-4 disabled:opacity-50 w-auto self-center my-2"
                        onClick={downloadIncorrectContacts}
                      >
                        <GoDownload className="inline mr-1 mb-[2px]" />
                        <span>Download CSV</span>
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GoogleContactsModal;
