import { Fragment, useContext, useEffect, useRef, useState } from "react";
// import { SchemaForUI, Size } from '@pdfme/common';
import { getFallbackFontName } from "@pdfme/common";
import {
  BsChevronDown,
  BsChevronUp,
  BsCheck2,
  BsPeopleFill,
} from "react-icons/bs";
import {
  MdAttachment,
  MdDelete,
  MdDragIndicator,
  MdHistory,
  MdOutlineDriveFileRenameOutline,
  MdPalette,
  MdSend,
} from "react-icons/md";
import { MdSave } from "react-icons/md";
import { v4 } from "uuid";
import { SchemaForUI, Size, Template } from "../../../../../common/src";
import { ONLY_PDF_SIDEBAR_WIDTH, SIDEBAR_WIDTH } from "../../../constants";
import { FontContext, I18nContext } from "../../../contexts";
import Divider from "../../Divider";
import DetailView from "./DetailView/index";
import { SIZES } from "../../../constants";
import { IoMdPricetag, IoMdRadioButtonOn, IoMdSettings } from "react-icons/io";
import Modal from "../../../../../../components/Modal";
import {
  CustomVariableType,
  DropdownOptionSchema,
  InstanceFromType,
  SendToSignersArgsTypes,
  SendToSignersFunctionType,
  // VariablesHelper,
} from "../../../../../common/src/type";
import { CgBrackets, CgProfile } from "react-icons/cg";
import { AiFillPlusCircle } from "react-icons/ai";

import CustomTooltip from "../../../../../../components/EditForm/CustomTooltip";
import ContentView from "./ContentView";
import VariableView from "./VariableView";
import { LuPaintBucket } from "react-icons/lu";
import DetailViewTemplateSidebar from "./DetailView/DetailViewTemplateSidebar";
// import { PdfTemplateSidebarViewTypes } from "..";
import { IoLanguage } from "react-icons/io5";
import LanguageView from "./LanguageView";
import {
  AddSchemaType,
  ChangeSchemasType,
  DesignerState,
  GlobalHelpersType,
  PdfTemplateSidebarViewTypes,
} from "..";
import RecipientView from "./RecipientView";
import BoloModal from "../../../../../../components/Common/Modal/BoloModal";
import CloseConfirmation from "./CloseConfirmation";
import PipedriveView from "./PipedriveView";
import { PiDotsNineBold } from "react-icons/pi";
import IntegrationAppView from "./IntegrationAppView";
import { TbAppsFilled, TbGridDots } from "react-icons/tb";
import { FaExternalLinkAlt, FaExternalLinkSquareAlt } from "react-icons/fa";
import RedirectionView from "./SettingsView";
import SettingsView from "./SettingsView";
import { getOwnerPlanDetails } from "../../../../../../utils";
import { IconType } from "react-icons/lib";
import DesignView from "./DesignView";
import PriceView from "./PriceView";
import { HiCurrencyDollar } from "react-icons/hi2";
import axios from "../../../../../../utils/axios";
import { defaultContactFields } from "../../../../../../constants/common";
import { getCustomFieldsData } from "../../../../../../utils/contacts";
import { FcWorkflow } from "react-icons/fc";
import ApprovalWorkflowView from "./ApprovalWorkflowView";
import { RiFlowChart } from "react-icons/ri";
import PreviewView from "./PreviewView";
import AttachmentsView from "./AttachmentsView";
export type SidebarProps = {
  height: number;
  pageNum: number;
  pageCursor: number;
  hoveringSchemaId: string | null;
  onChangeHoveringSchemaId: (id: string | null) => void;
  size: Size;
  pageSize: Size;
  activeElements: HTMLElement[];
  schemas: SchemaForUI[];
  onSortEnd: (sortedSchemas: SchemaForUI[]) => void;
  onEdit: (id: string) => void;
  onEditEnd: () => void;
  // changeSchemas: (
  //   objs: {
  //     key: string;
  //     value:
  //       | undefined
  //       | string
  //       | number
  //       | {
  //           min: number;
  //           max: number;
  //         }
  //       | boolean
  //       | DropdownOptionSchema[];
  //     schemaId: string;
  //   }[]
  // ) => void;
  changeSchemas: ChangeSchemasType;
  addSchema: AddSchemaType;
  formQuestions?: Array<{ type: string; title: string; required: boolean }>;
  // schemasList: SchemaForUI[][];
  // setSchemasList: (schemasList: SchemaForUI[][]) => void;
  onSaveTemplateHandler: any;
  // template?: Template;
  isOnlyPdfTemplate?: boolean;
  // roles?: {
  //   title: string;
  //   colour: string;
  // }[];
  // variablesHelper?: VariablesHelper;
  fetchedData: any;
  // receiversList?: Array<{
  //   name: string;
  //   email: string;
  //   message?: string | undefined;
  //   permission?: string | undefined;
  //   sentUrl?: string | undefined;
  // }>;
  // sendToSigners?: (sendForSigners?: SendToSignersArgsTypes) => Promise<any>;
  // instanceFrom: InstanceFromType;
  // sidebarSelectedOption: PdfTemplateSidebarViewTypes;
  // setSidebarSelectedOption: (
  //   sidebarSelectedOption: PdfTemplateSidebarViewTypes
  // ) => void;
  // open: boolean;
  // setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
};

const Sidebar = (props: SidebarProps) => {
  const {
    height,
    size,
    activeElements,
    schemas,
    addSchema,
    formQuestions,
    // schemasList,
    onSaveTemplateHandler,
    // template,
    onEditEnd,
    onEdit,
    isOnlyPdfTemplate,
    // roles,
    changeSchemas,
    // setSchemasList,
    // variablesHelper,
    fetchedData,
    // receiversList,
    // sendToSigners,
    // instanceFrom,
    // sidebarSelectedOption,
    // setSidebarSelectedOption,
    // setOpen,
    // open,
    designerState,
    setDesignerState,
    globalHelpers,
  } = props;

  /*
    ----------------------------------------------
    Destructuring designer state
    JANK_FIX take this functions in global helpers later
    ----------------------------------------------- 
  */

  const open = designerState?.combinedSidebar?.open;
  const setOpen = (value: boolean) => {
    setDesignerState((prev) => ({
      ...prev,
      combinedSidebar: { ...prev.combinedSidebar, open: value },
    }));
  };
  const sidebarSelectedOption = designerState.combinedSidebar.selectedView;
  const setSidebarSelectedOption = (value: PdfTemplateSidebarViewTypes) => {
    setDesignerState((prev) => ({
      ...prev,
      combinedSidebar: { ...prev.combinedSidebar, selectedView: value },
    }));
  };

  // -----------------------------------------------

  const [isReceiversListValid, setIsReceiversListValid] = useState(
    Array.isArray(designerState?.receiversList) &&
      designerState?.receiversList?.length > 0
  );
  const [customFields, setCustomFields] = useState([]);

  // const i18n = useContext(I18nContext);
  const fonts = useContext(FontContext);
  const fallbackFont = getFallbackFontName(fonts);
  // const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [showEditView, setShowEditView] = useState(false);
  // const [showFormQuestionDropdown, setShowFormQuestionDropdown] =
  //   useState(true);
  // const [selectedFormQuestion, setSelectedFormQuestion] = useState(
  //   formQuestions ? formQuestions[0] : null
  // );
  // const [selectedQuestionIndex, setSelectedQuestionIndex] =
  //   useState<number>(-1);
  // const [selectedRole, setSelectedRole] = useState<any>(
  //   designerState?.instanceFrom == "PDF"
  //     ? designerState?.receiversList?.[0]
  //     : designerState?.roles?.[0]
  // );
  const [isShowDropDown, setIsShowDropDown] = useState<boolean>(false);
  // const [customVariables, setCustomVariables] = useState<CustomVariableType[]>(
  //   fetchedData?.document?.variables || []
  // );
  const [isZeroSchemaCount, setIsZeroSchemaCount] = useState(false);
  const [isPipedriveIntegrated, setIsPipedriveIntegrated] = useState<boolean>(
    (designerState?.owner?.integrationTokens?.pipedrive?.access_token &&
      designerState?.owner?.integrationTokens?.pipedrive?.access_token !==
        "") ||
      false
  );

  const [isGohighlevelIntegrated, setIsGohighlevelIntegrated] =
    useState<boolean>(
      (designerState?.owner?.integrationTokens?.gohighlevel?.access_token &&
        designerState?.owner?.integrationTokens?.gohighlevel?.access_token !==
          "") ||
        false
    );
  const [isHubspotIntegrated, setIsHubspotIntegrated] = useState<boolean>(
    (designerState?.owner?.integrationTokens?.hubspot?.access_token &&
      designerState?.owner?.integrationTokens?.hubspot?.access_token !== "") ||
      false
  );

  const getActiveSchemas = () => {
    const ids = activeElements.map((ae) => ae.id);
    const activeSchema = schemas.find((s) => ids.includes(s.id));

    if (activeSchema?.type === "text") {
      if (!activeSchema.fontName) {
        activeSchema.fontName = fallbackFont;
      }
    }

    return schemas.filter((s) => ids.includes(s.id));
  };

  const getLastActiveSchema = () => {
    const activeSchemas = getActiveSchemas();
    if (activeSchemas.length > 1) {
      return undefined;
    }
    return activeSchemas?.[activeSchemas?.length - 1];
  };

  const onDragStartHandle = (e: any, schema: any) => {
    e.dataTransfer.setData("schemaData", JSON.stringify(schema));
  };

  const countSchemas = () => {
    let count = 0;
    designerState?.schemasList?.map((page, idx) => {
      page?.map((schema) => {
        count += 1;
      });
    });

    return count;
  };

  // const saveTemplate = async () => {
  //   if (!countSchemas()) {
  //     setIsZeroSchemaCount(true);
  //     return;
  //   }
  //   onSaveTemplateHandler();
  // };

  // const sendToSigner = async () => {
  //   if (!countSchemas()) {
  //     setIsZeroSchemaCount(true);
  //     return;
  //   }
  //   if (!sendToSigners) {
  //     return;
  //   } else {
  //     setLoading(true);
  //     await sendToSigners();
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if (
      designerState?.owner?.integrationTokens?.pipedrive?.access_token &&
      designerState?.owner?.integrationTokens?.pipedrive?.access_token !== ""
    ) {
      setIsPipedriveIntegrated(true);
    }
    if (
      designerState?.owner?.integrationTokens?.gohighlevel?.access_token &&
      designerState?.owner?.integrationTokens?.gohighlevel?.access_token !== ""
    ) {
      setIsGohighlevelIntegrated(true);
    }
    if (
      designerState?.owner?.integrationTokens?.hubspot?.access_token &&
      designerState?.owner?.integrationTokens?.hubspot?.access_token !== ""
    ) {
      setIsHubspotIntegrated(true);
    }
  }, [designerState?.owner]);

  // useEffect(() => {
  //   const activeSchemaLength = getActiveSchemas()?.length;
  //   if (activeSchemaLength === 1) {
  //     setShowEditView(true);
  //   } else {
  //     setShowEditView(false);
  //   }
  // }, [getActiveSchemas()?.length]);

  const SideBarTabs: {
    id: PdfTemplateSidebarViewTypes;
    tooltipContent: string;
    icon: IconType;
    onlyTemplate: boolean;
    mode: "EDITING" | "PREVIEW";
  }[] = [
    {
      id: "CONTENT",
      tooltipContent: "Content",
      icon: AiFillPlusCircle,
      onlyTemplate: false,

      mode: "EDITING",
    },
    {
      id: "VARIABLE",
      tooltipContent: "Variables",
      icon: CgBrackets,
      onlyTemplate: true,
      mode: "EDITING",
    },
    {
      id: "DETAIL_VIEW",
      tooltipContent: "Edit Styles",
      icon: LuPaintBucket,
      onlyTemplate: false,
      mode: "EDITING",
    },
    {
      id: "DESIGN",
      tooltipContent: "Global Document Design",
      icon: MdPalette,
      onlyTemplate: false,
      mode: "EDITING",
    },
    {
      id: "RECIPIENT",
      tooltipContent:
        designerState?.instanceFrom == "PDF"
          ? "Manage Recipients"
          : "Manage Roles",
      icon: BsPeopleFill,
      onlyTemplate: false,
      mode: "EDITING",
    },
    {
      id: "LANGUAGE",
      tooltipContent: "Choose PDF language",
      icon: IoLanguage,
      onlyTemplate: false,
      mode: "EDITING",
    },
    {
      id: "APPS",
      tooltipContent: "Apps",
      icon: TbAppsFilled,
      onlyTemplate: true,
      mode: "EDITING",
    },
    {
      id: "DOCUMENT_VALUE",
      tooltipContent: "Document Value",
      icon: HiCurrencyDollar,
      onlyTemplate: false,
      mode: "EDITING",
    },
    {
      id: "ATTACHMENTS",
      tooltipContent: "Attachments",
      icon: MdAttachment,
      onlyTemplate: false,
      mode: "EDITING",
    },
    {
      id: "APPROVAL_WORKFLOW",
      tooltipContent: "Approval workflow",
      icon: RiFlowChart,
      onlyTemplate: true,
      mode: "EDITING",
    },
    {
      id: "SETTINGS",
      tooltipContent: "Settings",
      icon: IoMdSettings,
      onlyTemplate: false,
      mode: "EDITING",
    },
    {
      id: "PREVIEW",
      tooltipContent: "Audit Trail",
      icon: MdHistory,
      onlyTemplate: false,
      mode: "PREVIEW",
    },
  ];

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const fieldsData = getCustomFieldsData();
        setCustomFields(fieldsData || []);
      } catch (error) {
        console.error("Error fetching custom contact fields", error);
      }
    };
    fetchFields();
  }, []);

  return (
    <div
      style={{
        height: "100%",
        background: "#ffffffed",
      }}
      className={`flex shadow ${
        open
          ? "w-[348px] max-w-[348px] min-w-[348px]"
          : "w-[48px] max-w-[48px] min-w-[48px]"
      } transition-all duration-500`}
    >
      {isZeroSchemaCount && (
        <BoloModal
          setShow={(value: boolean) => {
            setIsZeroSchemaCount(value);
          }}
          content={
            <CloseConfirmation
              setShow={(value: boolean) => {
                setIsZeroSchemaCount(value);
              }}
              handleOk={
                // isReceiversListValid ? sendToSigners : onSaveTemplateHandler
                globalHelpers.saveHandler
              }
            />
          }
        />
      )}
      <div className="flex h-[100%] max-h-[100%] flex-col w-12 border">
        {SideBarTabs?.map((tab) => {
          if (designerState.mode !== tab.mode) {
            return null;
          }

          if (
            !(designerState?.instanceFrom == "PDF-TEMPLATE") &&
            tab.onlyTemplate
          ) {
            return null;
          }

          // if (getOwnerPlanDetails()?.isLifeTime && tab.id == "SETTINGS") {
          //   return null;
          // }

          return (
            <button
              data-tooltip-id={tab.id}
              data-tooltip-offset={10}
              className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
              onClick={() => {
                setSidebarSelectedOption(tab.id);
                setOpen(true);
              }}
            >
              <tab.icon
                size={23}
                className={`w-12 border-r-4 border-l-4 border-l-transparent  ${
                  sidebarSelectedOption !== tab.id
                    ? "text-gray-500 border-r-transparent"
                    : " text-primary  border-r-primary"
                }`}
              />
              <CustomTooltip
                helpers={{
                  id: tab.id,
                  content: tab.tooltipContent,
                  place: "left",
                }}
              />
            </button>
          );
        })}
        {/* <button
          data-tooltip-id="content-btn"
          data-tooltip-offset={10}
          className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
          onClick={() => {
            setSidebarSelectedOption("CONTENT");
            setOpen(true);
          }}
        >
          <AiFillPlusCircle
            size={25}
            className={`w-12 border-r-4 border-l-4 border-l-transparent  ${
              sidebarSelectedOption !== "CONTENT"
                ? "text-gray-500 border-r-transparent"
                : " text-primary  border-r-primary"
            }`}
          />
          <CustomTooltip
            helpers={{
              id: "content-btn",
              content: "Content",
              place: "left",
            }}
          />
        </button>
        {designerState?.instanceFrom == "PDF-TEMPLATE" && (
          <button
            data-tooltip-id="variables-btn"
            data-tooltip-offset={10}
            className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
            onClick={() => {
              setSidebarSelectedOption("VARIABLE");
              setOpen(true);
            }}
          >
            <CgBrackets
              size={25}
              className={`w-12 border-r-4  border-l-4 border-l-transparent  ${
                sidebarSelectedOption !== "VARIABLE"
                  ? "text-gray-500 border-r-transparent"
                  : " text-primary  border-r-primary"
              }`}
            />
            <CustomTooltip
              helpers={{
                id: "variables-btn",
                content: "Variables",
                place: "left",
              }}
            />
          </button>
        )}
        <button
          data-tooltip-id="detail-view-btn"
          data-tooltip-offset={10}
          className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
          onClick={() => {
            setSidebarSelectedOption("DETAIL_VIEW");
            setOpen(true);
          }}
        >
          <LuPaintBucket
            size={22}
            className={`w-12 border-r-4 border-l-4 border-l-transparent  ${
              sidebarSelectedOption !== "DETAIL_VIEW"
                ? "text-gray-500 border-r-transparent"
                : " text-primary  border-r-primary"
            }`}
          />
          <CustomTooltip
            helpers={{
              id: "detail-view-btn",
              content: "Edit Styles",
              place: "left",
            }}
          />
        </button>
        <button
          data-tooltip-id="recipient-view-btn"
          data-tooltip-offset={10}
          className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
          onClick={() => {
            setSidebarSelectedOption("RECIPIENT");
            setOpen(true);
          }}
        >
          <BsPeopleFill
            size={22}
            className={`w-12 border-r-4 border-l-4 border-l-transparent  ${
              sidebarSelectedOption !== "RECIPIENT"
                ? "text-gray-500 border-r-transparent"
                : " text-primary  border-r-primary"
            }`}
          />
          <CustomTooltip
            helpers={{
              id: "recipient-view-btn",
              content:
                designerState?.instanceFrom == "PDF"
                  ? "Manage Recipients"
                  : "Manage Roles",
              place: "left",
            }}
          />
        </button>
        <button
          data-tooltip-id="lang-btn"
          data-tooltip-offset={10}
          className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
          onClick={() => {
            setSidebarSelectedOption("LANGUAGE");
            setOpen(true);
          }}
        >
          <IoLanguage
            size={22}
            className={`w-12 border-r-4 border-l-4 border-l-transparent  ${
              sidebarSelectedOption !== "LANGUAGE"
                ? "text-gray-500 border-r-transparent"
                : " text-primary  border-r-primary"
            }`}
          />
          <CustomTooltip
            helpers={{
              id: "lang-btn",
              content: "Choose PDF language",
              place: "left",
            }}
          />
        </button>
        {designerState?.instanceFrom == "PDF-TEMPLATE" && (
          <button
            data-tooltip-id="app-btn"
            data-tooltip-offset={10}
            className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
            onClick={() => {
              setSidebarSelectedOption("APPS");
              setOpen(true);
            }}
          >
            <TbGridDots
              className={`w-12 border-r-4 border-l-4 border-l-transparent h-6  ${
                sidebarSelectedOption !== "APPS"
                  ? "text-gray-500 border-r-transparent"
                  : " text-primary  border-r-primary"
              }`}
            />

            <CustomTooltip
              helpers={{
                id: "app-btn",
                content: "Apps",
                place: "left",
              }}
            />
          </button>
        )}
        {!getOwnerPlanDetails()?.isLifeTime && (
          <button
            data-tooltip-id="settings-btn"
            data-tooltip-offset={10}
            className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
            onClick={() => {
              setSidebarSelectedOption("SETTINGS");
              setOpen(true);
            }}
          >
            <IoMdSettings
              size={22}
              className={`w-12 border-r-4 border-l-4 border-l-transparent  ${
                sidebarSelectedOption !== "SETTINGS"
                  ? "text-gray-500 border-r-transparent"
                  : " text-primary  border-r-primary"
              }`}
            />
            <CustomTooltip
              helpers={{
                id: "settings-btn",
                content: "Settings",
                place: "left",
              }}
            />
          </button>
        )}
        <button
          data-tooltip-id="design-btn"
          data-tooltip-offset={10}
          className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
          onClick={() => {
            setSidebarSelectedOption("DESIGN");
            setOpen(true);
          }}
        >
          <MdPalette
            size={22}
            className={`w-12 border-r-4 border-l-4 border-l-transparent  ${
              sidebarSelectedOption !== "DESIGN"
                ? "text-gray-500 border-r-transparent"
                : " text-primary  border-r-primary"
            }`}
          />
          <CustomTooltip
            helpers={{
              id: "design-btn",
              content: "Design",
              place: "left",
            }}
          />
        </button> */}
        {/* this will be uncommented when pipedrive approves our so dont remove */}
        {/* move this to the above array later */}
        {/* {isPipedriveIntegrated && (
          <button
            data-tooltip-id="pipedrive-btn"
            data-tooltip-offset={10}
            className="h-12 w-12 flex items-center justify-center text-gray-500 hover:bg-gray-200"
            onClick={() => {
              setSidebarSelectedOption("PIPEDRIVE");
              setOpen(true);
            }}
          >
            <div
              className={`flex justify-center  w-12 border-r-4 border-l-4 border-l-transparent ${
                sidebarSelectedOption !== "PIPEDRIVE"
                  ? "text-gray-500 border-r-transparent"
                  : " text-primary  border-r-primary"
              }`}
            >
              <img
                src={pipedriveLogo}
                alt="pipedrive-logo"
                className={`w-5
                }`}
              />
            </div>
            <CustomTooltip
              helpers={{
                id: "pipedrive-btn",
                content: "Pipedrive",
                place: "left",
              }}
            />
          </button>
        )} */}
      </div>
      <div className="flex flex-col w-[300px]">
        {sidebarSelectedOption === "CONTENT" && (
          <ContentView
            helpers={{
              setIsShowDropDown,
              isShowDropDown,
              open,
              // selectedRole,
              // setSelectedRole,
              setOpen,
              addSchema,
              onDragStartHandle,
              // receiversList,
              isReceiversListValid,
              // instanceFrom,
            }}
            designerState={designerState}
            setDesignerState={setDesignerState}
            globalHelpers={globalHelpers}
            customFields={customFields}
          />
        )}
        {sidebarSelectedOption === "VARIABLE" && (
          <VariableView
            helpers={{
              open,
              setOpen,
              onDragStartHandle,
              addSchema,
              schemasList: designerState?.schemasList,
              setSchemasList: globalHelpers.setSchemasList,
              customVariables: designerState.customVariables,
              setCustomVariables: (
                newStateOrCB:
                  | CustomVariableType[]
                  | ((prevState: CustomVariableType[]) => CustomVariableType[])
              ): void => {
                if (typeof newStateOrCB == "function") {
                  setDesignerState((prev) => {
                    let newData = prev;
                    newData.customVariables = newStateOrCB(
                      newData.customVariables
                    );
                    return newData;
                  });
                } else {
                  setDesignerState((prev) => {
                    let newData = prev;
                    newData.customVariables = newStateOrCB;
                    return newData;
                  });
                }
              },
              fetchedData,
              isPipedriveIntegrated,
              isGohighlevelIntegrated,
              isHubspotIntegrated,
              designerState,
            }}
          />
        )}
        {sidebarSelectedOption === "DETAIL_VIEW" && (
          <DetailViewTemplateSidebar
            {...props}
            setSchemasList={globalHelpers.setSchemasList}
            schemasList={designerState.schemasList}
            activeSchema={getLastActiveSchema()}
            onEditEnd={onEditEnd}
            helpers={{ setOpen, open }}
          />
        )}
        {sidebarSelectedOption === "LANGUAGE" && (
          <LanguageView
            helpers={{
              setOpen,
              open,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}
        {sidebarSelectedOption === "SETTINGS" && (
          <SettingsView
            helpers={{
              setOpen,
              open,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}
        {sidebarSelectedOption === "RECIPIENT" && (
          <RecipientView
            helpers={{
              open,
              setOpen,
              // instanceFrom,
              // fetchedData,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}
        {sidebarSelectedOption === "APPS" && (
          <IntegrationAppView
            helpers={{
              open,
              setOpen,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}
        {sidebarSelectedOption === "APPROVAL_WORKFLOW" && (
          <ApprovalWorkflowView
            helpers={{
              open,
              setOpen,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}
        {sidebarSelectedOption === "ATTACHMENTS" && (
          <AttachmentsView
            helpers={{
              open,
              setOpen,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}
        {/* </>
        )} */}
        {/* this will be uncommented when pipedrive approves our app so dont remove */}
        {/* 
        {sidebarSelectedOption === "PIPEDRIVE" && (
          <PipedriveView
            helpers={{
              open,
              setOpen,
            }}
          />
        )} */}
        {sidebarSelectedOption === "DESIGN" && (
          <DesignView
            helpers={{
              setOpen,
              open,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}

        {sidebarSelectedOption === "DOCUMENT_VALUE" && (
          <PriceView
            helpers={{
              setOpen,
              open,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}

        {sidebarSelectedOption === "PREVIEW" && (
          <PreviewView
            helpers={{
              setOpen,
              open,
              setDesignerState,
              designerState,
              globalHelpers,
            }}
          />
        )}

        {/* <div
          // Save button
          className={`flex items-center justify-between w-full`}
        >
          <button
            className="flex-1 border-2 border-primary bg-primary text-white py-2 px-4 text-sm tracking-wide text-center "
            onClick={isReceiversListValid ? sendToSigner : saveTemplate}
          >
            {isReceiversListValid ? (
              <MdSend size={18} className="inline-block" />
            ) : (
              <MdSave size={18} className="inline-block" />
            )}
            <span className="ml-3">
              {isReceiversListValid ? "Send" : "Save Template"}
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
