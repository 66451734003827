// @ts-nocheck

import React from "react";
import { BsPinAngleFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";

const ColumnOptions = ({ column, setTableData, setShow }) => {
  const [searchParams] = useSearchParams();
  let formId = searchParams.get("id");

  const saveColumnPrefrencesToLocalStorage = (cols) => {
    let formColumnPrefrences = localStorage.getItem("formColumnPrefrences");
    if (formColumnPrefrences) {
      formColumnPrefrences = JSON?.parse(formColumnPrefrences);

      cols = cols.map((col) => {
        return {
          ...col,
          Header: col.name,
        };
      });
      formColumnPrefrences[formId] = cols;
      let formColumnPrefrencesString = JSON.stringify(formColumnPrefrences);
      localStorage.setItem("formColumnPrefrences", formColumnPrefrencesString);

      return;
    }
    formColumnPrefrences = {};
    cols = cols.map((col) => {
      return {
        ...col,
        Header: col.name,
      };
    });
    formColumnPrefrences[formId] = cols;
    let formColumnPrefrencesString = JSON.stringify(formColumnPrefrences);
    localStorage.setItem("formColumnPrefrences", formColumnPrefrencesString);
  };

  const hideColumnOption = () => {
    setShow((prev) => {
      return {
        ...prev,
        columnOption: {
          name: null,
          show: false,
        },
      };
    });
  };

  const handleColumnHide = () => {
    setTableData((prev) => {
      let cols = prev.columns.map((col) => {
        if (col.name === column.name) {
          return {
            ...col,
            show: false,
          };
        }
        return col;
      });
      saveColumnPrefrencesToLocalStorage(cols);

      return {
        ...prev,
        columns: cols,
      };
    });
    hideColumnOption();
  };

  const handleColumnPinUnpin = () => {
    setTableData((prev) => {
      let cols = prev.columns.map((col) => {
        if (col.name === column.name) {
          return {
            ...col,
            pinned: !col.pinned,
          };
        }
        return col;
      });
      saveColumnPrefrencesToLocalStorage(cols);

      return {
        ...prev,
        columns: cols,
      };
    });
    hideColumnOption();
  };

  return (
    <div className="w-[200px] border absolute z-50 top-0 left-0 mt-[43px] ml-2 bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="overflow-y-auto">
        <div
          onClick={handleColumnPinUnpin}
          className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50"
        >
          <BsPinAngleFill className="text-lg mr-3 text-gray-600" />
          <div>{column.pinned ? "Unpin" : "Pin"} Column</div>
        </div>
        <div
          onClick={handleColumnHide}
          className="py-4 px-3 text-xs text-gray-700 flex flex-row items-center hover:bg-gray-50"
        >
          <BsFillEyeSlashFill className="text-lg mr-3 text-gray-600" />
          <div>{column.show ? "Hide" : "Show"} Column</div>
        </div>
      </div>
    </div>
  );
};

export default ColumnOptions;
