export const hubspotvariableMapping = {
  "[Hubspot.Deal.Amount]": "properties.amount",
  "[Hubspot.Deal.AmountInHomeCurrency]": "properties.amount_in_home_currency",
  "[Hubspot.Deal.CloseDate]": "properties.closedate",
  "[Hubspot.Deal.ClosedLostReason]": "properties.closed_lost_reason",
  "[Hubspot.Deal.ClosedWonReason]": "properties.closed_won_reason",
  "[Hubspot.Deal.CreateDate]": "properties.createdate",
  "[Hubspot.Deal.DaysToClose]": "properties.days_to_close",
  "[Hubspot.Deal.DealID]": "id",
  "[Hubspot.Deal.DealName]": "properties.dealname",
  "[Hubspot.Deal.DealStage]": "properties.dealstage",
  "[Hubspot.Deal.DealType]": "properties.dealtype",
  "[Hubspot.Deal.Description]": "properties.description",
  "[Hubspot.Deal.HsAllOwnerIds]": "properties.hubspot_owner_id",
  "[Hubspot.Deal.HsClosedAmount]": "properties.hs_closed_amount",
  "[Hubspot.Deal.HsClosedAmountInHomeCurrency]":
    "properties.hs_closed_amount_in_home_currency",
  "[Hubspot.Deal.HsDealStageProbability]":
    "properties.hs_deal_stage_probability",
  "[Hubspot.Deal.HsIsClosed]": "properties.hs_is_closed",
  "[Hubspot.Deal.HsObjectId]": "properties.hs_object_id",
  "[Hubspot.Deal.HsProjectedAmount]": "properties.hs_projected_amount",
  "[Hubspot.Deal.HsProjectedAmountInHomeCurrency]":
    "properties.hs_projected_amount_in_home_currency",
  "[Hubspot.Deal.HsUpdatedByUserId]": "properties.hs_updated_by_user_id",
  "[Hubspot.Deal.HubspotOwnerAssignedDate]":
    "properties.hubspot_owner_assigneddate",
  "[Hubspot.Deal.HubspotOwnerId]": "properties.hubspot_owner_id",
  "[Hubspot.Deal.NumAssociatedContacts]": "properties.num_associated_contacts",
  "[Hubspot.Deal.NumContactedNotes]": "properties.num_contacted_notes",
  "[Hubspot.Deal.NumNotes]": "properties.num_notes",
  "[Hubspot.Deal.Owner.UpdatedAt]": "properties.updatedAt",
  "[Hubspot.Deal.Pipeline]": "properties.pipeline",
  "[Hubspot.Deal.Owner.CreatedAt]": "owner.createdAt",
  "[Hubspot.Deal.Owner.Email]": "owner.email",
  "[Hubspot.Deal.Owner.FirstName]": "owner.firstName",
  "[Hubspot.Deal.Owner.LastName]": "owner.lastName",
  "[Hubspot.Deal.Owner.OwnerId]": "owner.id",
  "[Hubspot.Company.AboutUs]": "company.properties.about_us",
  "[Hubspot.Company.Address]": "company.properties.address",
  "[Hubspot.Company.Address2]": "company.properties.address2",
  "[Hubspot.Company.AnnualRevenue]": "company.properties.annualrevenue",
  "[Hubspot.Company.City]": "company.properties.city",
  "[Hubspot.Company.CloseDate]": "company.properties.closedate",
  "[Hubspot.Company.Country]": "company.properties.country",
  "[Hubspot.Company.CreateDate]": "company.properties.createdate",
  "[Hubspot.Company.DaysToClose]": "company.properties.days_to_close",
  "[Hubspot.Company.Description]": "company.properties.description",
  "[Hubspot.Company.Domain]": "company.properties.domain",
  "[Hubspot.Company.FacebookCompanyPage]":
    "company.properties.facebook_company_page",
  "[Hubspot.Company.FirstContactCreateDate]":
    "company.properties.first_contact_createdate",
  "[Hubspot.Company.FirstDealCreateDate]":
    "company.properties.first_deal_created_date",
  "[Hubspot.Company.FoundedYear]": "company.properties.founded_year",
  "[Hubspot.Company.HsLeadStatus]": "company.properties.hs_lead_status",
  "[Hubspot.Company.HsObjectId]": "company.properties.hs_object_id",
  "[Hubspot.Company.HsTargetAccount]":
    "company.properties.hs_is_target_account",
  "[Hubspot.Company.HubspotOwnerAssignedDate]":
    "company.properties.hubspot_owner_assigneddate",
  "[Hubspot.Company.HubspotOwnerId]": "company.properties.hubspot_owner_id",
  "[Hubspot.Company.Industry]": "company.properties.industry",
  "[Hubspot.Company.IsPublic]": "company.properties.is_public",
  "[Hubspot.Company.LifeCycleStage]": "company.properties.lifecyclestage",
  "[Hubspot.Company.LinkedinBio]": "company.properties.linkedinbio",
  "[Hubspot.Company.LinkedinCompanyPage]":
    "company.properties.linkedin_company_page",
  "[Hubspot.Company.Name]": "company.properties.name",
  "[Hubspot.Company.NotesLastContacted]":
    "company.properties.notes_last_contacted",
  "[Hubspot.Company.NumAssociatedContacts]":
    "company.properties.num_associated_contacts",
  "[Hubspot.Company.NumAssociatedDeals]":
    "company.properties.num_associated_deals",
  "[Hubspot.Company.NumberOfEmployees]": "company.properties.numberofemployees",
  "[Hubspot.Company.Owner.CreatedAt]": "company.properties.owner.createdAt",
  "[Hubspot.Company.Owner.Email]": "company.properties.owner.email",
  "[Hubspot.Company.Owner.FirstName]": "company.properties.owner.firstName",
  "[Hubspot.Company.Owner.LastName]": "company.properties.owner.lastName",
  "[Hubspot.Company.Owner.OwnerId]": "company.properties.owner.id",
  "[Hubspot.Company.Owner.UpdatedAt]": "company.properties.owner.updatedAt",
  "[Hubspot.Company.Owner.PortalId]": "company.properties.portalId",
  "[Hubspot.Company.Phone]": "company.properties.phone",
  "[Hubspot.Company.RecentDealAmount]": "company.properties.recent_deal_amount",
  "[Hubspot.Company.State]": "company.properties.state",
  "[Hubspot.Company.Timezone]": "company.properties.timezone",
  "[Hubspot.Company.TotalMoneyRaised]": "company.properties.total_money_raised",
  "[Hubspot.Company.TotalRevenue]": "company.properties.total_revenue",
  "[Hubspot.Company.TwitterBio]": "company.properties.twitterbio",
  "[Hubspot.Company.TwitterHandle]": "company.properties.twitterhandle",
  "[Hubspot.Company.Type]": "company.properties.type",
  "[Hubspot.Company.WebTechnologies]": "company.properties.web_technologies",
  "[Hubspot.Company.Website]": "company.properties.website",
  "[Hubspot.Company.Zip]": "company.properties.zip",
  "[Hubspot.Contact.address]": "contact.properties.address",
  "[Hubspot.Contact.city]": "contact.properties.city",
  "[Hubspot.Contact.country]": "contact.properties.country",
  "[Hubspot.Contact.create_date]": "contact.properties.createdate",
  "[Hubspot.Contact.DOB]": "contact.properties.date_of_birth",
  "[Hubspot.Contact.email]": "contact.properties.email",
  "[Hubspot.Contact.firstname]": "contact.properties.firstname",
  "[Hubspot.Contact.lastname]": "contact.properties.lastname",
  "[Hubspot.Contact.gender]": "contact.properties.gender",
  "[Hubspot.Contact.hs_object_id]": "contact.properties.hs_object_id",
  "[Hubspot.Contact.industry]": "contact.properties.industry",
  "[Hubspot.Contact.lastmodifieddate]": "contact.properties.lastmodifieddate",
  "[Hubspot.Contact.mobilephone]": "contact.properties.mobilephone",
  "[Hubspot.Contact.phone]": "contact.properties.phone",
  "[Hubspot.Contact.seniority]": "contact.properties.seniority",
  "[Hubspot.Contact.twitterhandle]": "contact.properties.twitterhandle",
  "[Hubspot.Contact.website]": "contact.properties.website",
  "[Hubspot.Contact.work_Email]": "contact.properties.work_email",
};

export const hubspotLogo =
  "https://d3m3a7p0ze7hmq.cloudfront.net/img/integrations/new-logo/hubspot.svg";

export const hubspotscopes = [
  "crm.objects.companies.read",
  "crm.objects.contacts.read",
  "crm.objects.owners.read",
  "crm.objects.deals.read",
];
// dev one
// export const HUBSPOT_CLIENT_ID = "55826c47-798f-48e9-a653-f2fdf638a04c";
// prod one
export const HUBSPOT_CLIENT_ID = "c799565b-2256-4424-98d6-a66cbd866b1b";

export const HUBSPOT_ACCESS_EMAILS = [
  "chirag@boloforms.com",
  "support@boloforms.com",
  "boloforms@gmail.com",
  "batrarahul409@gmail.com",
  "rickysh0098@gmail.com",
];
