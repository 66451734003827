import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoMdInformationCircle } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { MdAccountCircle, MdOutlineDoneAll } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import {
  BoloIconForm,
  BoloIconPDF,
  BoloIconPDFTemplate,
} from "../../components/Common/Icons/BoloIcons";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../components/Common/PopOver/BoloPopOver";
import DashboardBottomNav from "../../components/DashboardBottomNav/DashboardBottomNav";
import DashboardFilter from "../../components/DashboardFilter/DashboardFilter";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import NoDocumentsV1 from "../../components/NoDocuments/NoDocumentsV1";
import { IMAGE_LINKS } from "../../constants/common";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import { fetchAllOverview } from "../../redux/apis/dashboards";
import { useAppSelector } from "../../redux/store";
import axios from "../../utils/axios";
import getS3PreSignedUrl from "../../utils/getS3PreSignedUrl";
import { downloadBase64File } from "../../utils/localPdfUtils";
import MixPanel from "../../utils/services/mixpanel";
import { getStatusByPayment } from "../../utils/quoteBuilder";

const Overview = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const { getSearchData, setSearchData, isFilter } = useQueryHook();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fetchedResource, setFetchedResource] = useState<any[]>([]);
  const [resourceCount, setResourceCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  let navigate = useNavigate();
  const workSpaceDetails = useWorkSpaceDetails();

  async function handleGetAllDocuments() {
    setLoading(true);
    try {
      let params = getSearchData();
      let data = await fetchAllOverview({
        page: params.pageNo_,
        limit: params.limit_,
        isStandAloneTemplate: false,
        query: params.query_,
        dateFrom: params.dateFrom_,
        dateTo: params.dateTo_,
        sortBy: params.sortBy_,
        sortOrder: params.sortOrder_,
        filter: params.filter_,
        overViewType: params.overViewType_,
      });
      if (data) {
        setFetchedResource(data.resources || []);
        setResourceCount(data.resourceCount || 0);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error getting documents", error);
    }
    setLoading(false);
  }

  useEffect(() => {
    handleGetAllDocuments();
  }, [
    searchParams,
    getSearchData().rT_,
    getSearchData().pageNo_,
    getSearchData().limit_,
    getSearchData().filter_,
    getSearchData().sortBy_,
    getSearchData().sortOrder_,
    getSearchData().dateFrom_,
    getSearchData().dateTo_,
    getSearchData().overViewType_,
    reload,
  ]);

  // if searchText updates
  // and is "" then we go back to the state where to page one with no search query
  useEffect(() => {
    if (!searchText && getSearchData().query_) {
      let data = getSearchData();
      setSearchData({ query_: "", pageNo_: data.pageNo_ });
      // handleGetAllDocuments();
    }
  }, [searchText]);

  return (
    <div className="-m-4 bg-white h-[100%]">
      <Helmet>
        <title translate="no">Overview</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Overview" />
        <meta
          name="description"
          content="Centralize and manage all your resources effortlessly on the dashboard for streamlined organization and accessibility."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/dashboard/overview`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Overview - Boloforms`} />
        <meta
          property="og:description"
          content="Centralize and manage all your resources effortlessly on the dashboard for streamlined organization and accessibility."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/dashboard/overview"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div
        className={`px-3 md:px-4 flex-col md:flex-row md:h-[40px] my-4 sm:my-6 text-gray-600 w-full flex gap-2 md:justify-between items-center`}
      >
        <div className="flex items-center gap-2 w-full md:w-fit">
          <form className={`h-full text-xs w-full`}>
            <div
              className={`h-full flex flex-row rounded-md overflow-hidden ml-auto  md:w-[500px] border`}
            >
              <input
                placeholder="Search by document name, signer name, or email"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full h-full py-2 px-4   outline-none text-gray-700 "
              />
              <button
                className="min-w-[40px] flex justify-center items-center text-gray-500 cursor-pointer hover:opacity-90"
                onClick={(e) => {
                  e.preventDefault();
                  setSearchData({ query_: searchText, pageNo_: 1 });
                  handleGetAllDocuments();
                }}
              >
                <IoSearch className="text-xl" />
              </button>
            </div>
          </form>
          <div className="">
            <DashboardFilter />
          </div>
        </div>
      </div>
      <div
        className={`relative`}
        style={{
          overflowY:
            fetchedResource?.length === 0 && !loading ? "hidden" : "auto",
          maxHeight:
            fetchedResource?.length === 0 && !loading
              ? 0
              : "calc(100% - 130px)",
          height:
            fetchedResource?.length === 0 && !loading
              ? 0
              : "calc(100% - 130px)",
        }}
        id="mini-pdf-custom-scroll"
      >
        <div className="overflow-auto">
          <table className="min-w-full bg-white">
            <thead className="sticky top-0 bg-white shadow z-20">
              <tr className="h-[40px]">
                <th className="max-w-[40px] min-w-[32px] hidden md:md:table-cell"></th>
                <th className="text-left px-3 md:px-4 text-gray-600 text-sm font-semibold min-w-[150px] md:max-w-[300px]">
                  <span className="text-xs w-full md:max-w-none truncate notranslate">
                    Name
                  </span>
                </th>
                <th className="text-left px-3 md:px-4 text-gray-600 text-sm font-semibold min-w-[150px] max-w-[300px] hidden md:table-cell">
                  <span className="text-xs w-full md:max-w-none truncate">
                    Status
                  </span>
                </th>
                <th className="text-left px-3 md:px-4 text-gray-600 text-sm font-semibold min-w-[150px] max-w-[300px] hidden md:table-cell">
                  <div className="flex text-xs items-center justify-start gap-2 w-20 md:mr-2">
                    Preview
                  </div>
                </th>
                <th className="text-left px-3 md:px-4 text-gray-600 text-sm font-semibold w-fit md:min-w-[150px] max-w-[300px]">
                  <div className="flex text-xs items-center justify-start gap-2 w-20 md:mr-2">
                    <span className="hidden md:block">Download</span>
                    <span className="block md:hidden">Actions</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {fetchedResource?.map((elm, idx) => {
                // let ownerName = elm?.ownerId?.name || "Sender";
                // let lastModified = new Date(elm?.updatedAt).toLocaleString();
                // let ownerEmail = elm?.authorEmail;

                // const rolesArray = elm?.respondentsOfDoc?.map((signer: any) => {
                //   let role = {
                //     colour: signer?.colour,
                //     status: signer?.status,
                //     name: signer?.name,
                //     email: signer?.email,
                //     owner: ownerName,
                //     lastModified: lastModified,
                //     ownerEmail: ownerEmail,
                //     hasDeclined: signer?.hasDeclined,
                //     accessCode: signer?.accessCode,
                //   };
                //   return role;
                // });
                return (
                  <tr
                    key={idx}
                    className="border-b hover:bg-secondary/40 cursor-pointer text-gray-700 text-sm"
                  >
                    <td className="max-w-[40px] min-w-[32px] px-0 py-3 hidden md:table-cell">
                      {elm?.resourceType === "PDF" && (
                        <BoloIconPDF className="m-auto" />
                      )}
                      {elm?.resourceType === "PDF_TEMPLATE" && (
                        <BoloIconPDFTemplate className="m-auto" />
                      )}
                    </td>
                    <td className="px-2 py-3 text-sm truncate min-w-[150px] max-w-[300px] pl-4 md:pl-2">
                      <div>
                        <div className="flex gap-2">
                          {elm?.resourceType === "PDF" && (
                            <BoloIconPDF
                              className="md:hidden"
                              bolosize={"xs"}
                            />
                          )}
                          {elm?.resourceType === "PDF_TEMPLATE" && (
                            <BoloIconPDFTemplate
                              className="md:hidden"
                              bolosize={"xs"}
                            />
                          )}
                          <p>{elm?.documentName}</p>{" "}
                        </div>
                        <div className="flex gap-2 items-start text-gray-500 text-[11px]">
                          <p className="">
                            {new Date(elm.createdAt).toDateString()}
                          </p>
                          <p className="">
                            {new Date(elm.createdAt).toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-3 text-sm truncate min-w-[150px] max-w-[300px] hidden md:table-cell">
                      <div
                        data-tip={`the Document is in ${elm?.status} state`}
                        className="flex flex-row items-center w-fit text-[10px]"
                      >
                        <p
                          className=" text-gray-500 rounded px-2 font-semibold tracking-wider"
                          style={{
                            backgroundColor: getStatusByPayment({
                              document: elm,
                            }).backGround,
                            color: getStatusByPayment({ document: elm }).color,
                          }}
                        >
                          {getStatusByPayment({ document: elm }).status}
                        </p>
                      </div>
                      <p className=" text-gray-500 text-[10px] mt-0.5 leading-4">
                        {getStatusByPayment({ document: elm }).message}
                      </p>
                      {/* <BoloPopover>
                        <BoloPopoverTrigger>
                          <button
                            className="text-blue-500 hover:underline text-[10px] leading-3"
                            data-tooltip-id="single-pdf-details-tooltip"
                            data-tooltip-place="bottom-start"
                          >
                            Show Details
                          </button>
                        </BoloPopoverTrigger>
                        <BoloPopoverContent>
                          <div className="flex gap-2 max-h-[250px] p-2">
                            <div className="p-2 flex gap-2 items-start justify-start rounded-md w-full bg-white mb-2">
                              <MdAccountCircle className="text-xl rounded-full bg-gray-500" />
                              <div className="flex flex-col gap-1">
                                <p className="text-xs text-gray-700 flex items-center gap-2">
                                  {rolesArray?.[0]?.owner}
                                </p>
                                <span className="text-xs text-gray-500">
                                  {rolesArray?.[0]?.ownerEmail}
                                </span>
                                <span className="text-xs text-gray-500">
                                  Last modified {rolesArray?.[0]?.lastModified}
                                </span>
                              </div>
                            </div>
                            <span className="text-xs text-gray-700 mb-2">
                              Signers
                            </span>
                            {rolesArray?.map((item: any, index: any) => {
                              return (
                                <div
                                  className="p-2 flex gap-2 items-start justify-start rounded-md shadow hover:bg-secondary w-full bg-white"
                                  key={index}
                                >
                                  <MdAccountCircle
                                    className="text-xl rounded-full"
                                    style={{ background: item.colour }}
                                  />
                                  <div className="flex flex-col gap-1">
                                    <p className="text-xs text-gray-700 flex items-center gap-2">
                                      {item.name}
                                      <span className="">
                                        {item?.status === "SIGNED" ? (
                                          <MdOutlineDoneAll className="text-green-600" />
                                        ) : (
                                          <AiOutlineClockCircle className="text-red-600" />
                                        )}
                                      </span>{" "}
                                    </p>
                                    <span className="text-xs text-gray-500">
                                      {item.email}
                                    </span>
                                    <span className="text-xs text-gray-500">
                                      {item.status}
                                    </span>
                                    {item.accessCode && (
                                      <span className="text-xs text-gray-500">
                                        Access Code:{" "}
                                        <span className="font-semibold tracking-wide">
                                          {item?.accessCode}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </BoloPopoverContent>
                      </BoloPopover> */}
                    </td>
                    <td className="max-w-[40px] min-w-[32px] px-0 py-3 hidden md:table-cell">
                      <BoloButton
                        disabled={
                          elm.status === "SIGNED" ||
                          elm.status === "PAID" ||
                          elm.status === "REJECTED" ||
                          (!workSpaceDetails?.isWorkSpaceOwner &&
                            workSpaceDetails?.currentWorkSpaceRole ===
                              "readonly")
                        }
                        size={"sm"}
                        variant="secondary"
                        className="text-xs md:text-xs"
                        onClick={async () => {
                          new MixPanel().track("PDF", {
                            pdf_action: "pdf_edit",
                            paid: false,
                          });
                          new MixPanel().increment("pdf_edit");
                          if (elm?.resourceType === "PDF") {
                            navigate(
                              `/create/pdf-signature?documentId=${elm?.documentId}&isDrafted=true`
                            );
                          }
                          if (elm?.resourceType === "PDF_TEMPLATE") {
                            navigate(
                              `/create/pdf-template?documentId=${elm?.documentId}&templateId=${elm?.respId}`
                            );
                          }
                        }}
                      >
                        Preview
                      </BoloButton>
                    </td>
                    <td className="max-w-[40px] min-w-[32px] px-0 py-3">
                      <div className="flex gap-2 items-center">
                        <BoloButton
                          // disabled={
                          //   elm.status !== "SIGNED" && elm.status !== "PAID"
                          // }
                          disabled={
                            elm.status === "VOID" || elm.status === "REJECTED"
                          }
                          size={"sm"}
                          variant="tertiary"
                          className="text-xs md:text-xs"
                          onClick={async () => {
                            if (elm.finishedPdfUrl) {
                              let url = await getS3PreSignedUrl(
                                elm?.finishedPdfUrl
                              );
                              window.open(url, "_blank");
                            } else {
                              let url = `/downlod-semicomplete-pdf?documentId=${elm?.documentId}&type=${elm?.resourceType}&respId=${elm?.respId}`;
                              let { data } = await axios.get(url);
                              if (data?.success) {
                                downloadBase64File(
                                  data?.pdfBytes,
                                  `${elm?.documentId}-${elm?.respId || ""}.pdf`
                                );
                                // window.open(data?.pdfBytes, "_blank");
                              }
                            }
                          }}
                        >
                          Download
                        </BoloButton>
                        {elm.status !== "SIGNED" && elm.status !== "PAID" ? (
                          <IoMdInformationCircle
                            data-tooltip-id="incomplete-download"
                            className="text-gray-500"
                            size={16}
                          />
                        ) : null}
                        <CustomTooltip
                          helpers={{
                            id: "incomplete-download",
                            content: "Incomplete Download",
                            place: "right",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {fetchedResource?.length > 0 && (
        <DashboardBottomNav count={resourceCount || 0} />
      )}
      {fetchedResource?.length === 0 && !loading && (
        <div className="w-full flex flex-col items-center py-12 text-gray-600">
          <NoDocumentsV1
            cb={() => {
              if (workSpaceDetails?.currentWorkSpaceRole !== "readonly") {
                navigate("/create");
              } else {
                toast.error("Readonly role are not allowed to create files");
              }
            }}
            resourceType="DOCUMENT"
          />
        </div>
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default Overview;
