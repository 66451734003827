import React, { useState, useEffect } from "react";
import {
  getContactFieldValue,
  getCustomFieldsData,
} from "../../utils/contacts";

interface CustomFieldsValuesProps {
  receiversList?: any[];
  schemas?: any[];
  setContactVariables?: any;
  contactVariables?: any[];
}

const CustomFieldsValues: React.FC<CustomFieldsValuesProps> = ({
  receiversList = [],
  schemas = [],
  setContactVariables,
  contactVariables = [],
}) => {
  const [allCustomFields, setAllCustomFields] = useState<any>([]);

  // Fetch the signer email and signer index from the receivers list from role title
  const getEmailByRoleTitle = (roleTitle: string) => {
    let signerEmail = "";
    let signerNumber = null;
    let isSignerEnabled = true;
    receiversList.forEach((signer, index) => {
      if (signer.roleTitle === roleTitle) {
        signerEmail = signer.email;
        isSignerEnabled = signer.isEnabled;
        signerNumber = index;
      }
    });
    return { signerEmail, signerNumber, isSignerEnabled };
  };
  useEffect(() => {
    // This is used mapping the contact / custom fields according to signer
    const fixedVars: any[] = Array.from(
      { length: receiversList.length || 1 },
      () => []
    );
    // This is required in sending data to backend.
    const contactVars: any[] = [];

    schemas.forEach((item: any) => {
      Object.values(item).forEach((value: any) => {
        // Sub type is custom field
        if (value?.subType === "custom_field") {
          const { signerEmail, signerNumber, isSignerEnabled } =
            getEmailByRoleTitle(value?.role?.title);

          if (signerEmail && isSignerEnabled) {
            // get the field value
            const fieldValue = getContactFieldValue(
              signerEmail,
              value?.contactFieldName,
              "custom_field"
            );
            value.data = fieldValue;
          }
          if ((signerNumber || signerNumber === 0) && isSignerEnabled) {
            fixedVars[signerNumber][value?.contactFieldName] = value;
            const varData = {
              fieldName: value?.contactFieldName,
              fieldValue: value.data,
              roleTitle: value?.role?.title,
            };
            contactVars.push(varData);
          }
        } else if (value?.subType === "contact_field") {
          const { signerEmail, signerNumber, isSignerEnabled } =
            getEmailByRoleTitle(value?.role?.title);

          if (signerEmail && isSignerEnabled) {
            // get the field value
            const fieldValue = getContactFieldValue(
              signerEmail,
              value?.contactFieldName,
              "contact_field"
            );
            value.data = fieldValue;
          }
          if ((signerNumber || signerNumber === 0) && isSignerEnabled) {
            fixedVars[signerNumber][value?.contactFieldName] = value;
            const varData = {
              fieldName: value?.contactFieldName,
              fieldValue: value.data,
              roleTitle: value?.role?.title,
            };
            contactVars.push(varData);
          }
        }
      });
    });
    setContactVariables(contactVars);
    setAllCustomFields(fixedVars);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiversList]);

  const updateContactVariables = (updatedField: any) => {
    return contactVariables.map((field) => {
      if (
        field.fieldName === updatedField.fieldName &&
        field.roleTitle === updatedField.roleTitle
      ) {
        return { ...field, ...updatedField };
      }
      return field;
    });
  };
  const handleContactVarValueChange = (
    e: any,
    signerIndex: number,
    field: any
  ) => {
    const value = e.target.value;
    // This is required in sending data to backend.
    const updatedField = {
      fieldValue: value,
      fieldName: field?.contactFieldName,
      roleTitle: field?.role?.title,
    };
    const contactVars = updateContactVariables(updatedField);
    setContactVariables(contactVars);

    // Create a new array based on the current state
    const updatedCustomFields = allCustomFields.map(
      (signerFields: any, index: number) => {
        if (index !== signerIndex) {
          // Return the original object for other signers
          return signerFields;
        }
        let temp = contactVariables;

        temp.map((field) => {
          if (field.fieldName === field.contactFieldName) {
            return field;
          }
          return { ...field };
        });
        // Create a new object for the updated signer
        return {
          ...signerFields,
          [field.contactFieldName]: {
            ...signerFields[field.contactFieldName],
            data: value,
          },
        };
      }
    );
    setAllCustomFields(updatedCustomFields);
  };

  const getFieldType = (field_name: string) => {
    const customFieldsList = getCustomFieldsData();
    const fieldData = customFieldsList.find((field: any) => {
      return field.field_name === field_name;
    });
    if (fieldData) {
      return fieldData.type;
    } else {
      return "text";
    }
  };

  return (
    <>
      {contactVariables.length > 0 && (
        <div className="py-1 md:py-2 w-[100%] md:w-[65%] max-w-[800px] md:mt-4">
          <h2 className="text-xs font-medium mt-4">Contact Variables</h2>
          <p className="text-xs font-medium text-gray-500 ">
            Saved values are used from contacts data.
          </p>
          <p className="text-[10px] md:text-xs  leading-4 text-orange-500 mt-1 mb-3">
            Empty values for custom contact fields will not be visible to the
            signer
          </p>
          {allCustomFields.map((signer: any, index: number) => {
            return Object.values(signer)?.map((field: any) => {
              return (
                <div
                  key={field.id}
                  className="md:flex items-center gap-4 text-xs"
                >
                  <div className="w-full md:flex items-center py-2  px-2 md:gap-2">
                    <div
                      className="flex gap-2 w-[100%] md:w-[40%] md:pb-0 md:mb-0 mb-2 md:py-0 py-2 md:h-9 justify-center items-center px-3 border rounded-md"
                      style={{
                        backgroundColor: field?.role?.colour + 40,
                        borderColor: field?.role?.colour,
                      }}
                    >
                      <span className="w-full whitespace-nowrap text-ellipsis text-xs md:text-sm md:overflow-clip lg:overflow-hidden">
                        {field?.role?.title} - {field?.showKey}
                      </span>
                    </div>
                    <div className="w-[100%] md:w-[60%]  flex flex-row justify-center items-center gap-2 border border-gray-300 rounded-md p-2 ">
                      <input
                        type={getFieldType(field?.contactFieldName) || "text"}
                        placeholder={field?.showKey + "'s value"}
                        value={field?.data}
                        className="w-full text-xs md:text-sm outline-none "
                        onChange={(e) => {
                          handleContactVarValueChange(e, index, field);
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            });
          })}
        </div>
      )}
    </>
  );
};

export default CustomFieldsValues;
