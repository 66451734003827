import { Radio, RadioGroup } from "@mui/material";
import { alpha, styled } from "@mui/system";
import React from "react";
import { COLORS } from "../../../constants/common";
import { getWhiteLabelInfoLocal } from "../../../utils";

const whiteLabelInfo = getWhiteLabelInfoLocal();

const BoloRadioGroupMui = styled(RadioGroup)(
  ({ variant }: { variant?: "default" | "bolo-primary" }) => ({
    "& .MuiRadio-root": {
      color:
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
    },
    "& .MuiRadio-root.Mui-checked": {
      color:
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
    },
    "& .MuiRadio-root.Mui-checked:hover": {
      backgroundColor: alpha(
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
              COLORS.primary,
        0.04
      ),
    },
  })
);

export const BoloRadioMui = styled(Radio)(
  ({ variant }: { variant?: "default" | "bolo-primary" }) => ({
    "& .MuiRadio-root": {
      color:
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
    },
    "& .MuiRadio-root.Mui-checked": {
      color:
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
    },
    "& .MuiRadio-root.Mui-checked:hover": {
      backgroundColor: alpha(
        variant === "bolo-primary"
          ? COLORS.primary
          : whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
              COLORS.primary,
        0.04
      ),
    },
  })
);

export default BoloRadioGroupMui;
