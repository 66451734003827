import axios from "../../utils/axios";
import { ContactSchemaType } from "../../zodTypes";

async function addBulkContactsEmbed({
  contacts,
}: {
  contacts: ContactSchemaType[];
}) {
  try {
    const response = await axios.post("/workspace/embed/contacts", {
      contacts,
    });
    const data = response?.data;
    if (data?.success && data?.owner) {
      localStorage.setItem("owner", JSON.stringify(data?.owner));
    } else {
      console.log("addBulkContactsEmbed", data);
    }
    return data;
  } catch (error) {
    console.log("Error in addBulkContactsEmbed");
    console.error(error);
  }
}

export { addBulkContactsEmbed };
