import { useEffect, useState } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { validateEmail } from "../../utils/validateEmail";
import { toast } from "react-toastify";

interface EmailSelectProps {
  selectedValues: any;
  updateEmailValues: any;
  disabled?: boolean;
  isCustomStyle?: boolean;
}

const customStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    fontSize: "14px", // Adjust as needed
    border: "none",
    borderBottom: state.isFocused ? "2px solid #4c2b87" : "1px solid #e5e5e5",
    borderRadius: "0",
    outline: "none",
    boxShadow: "none",
    transition: "border-bottom-color 0.2s ease-in-out",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#a0aec0",
  }),
};
const EmailMultiSelect: React.FC<EmailSelectProps> = ({
  selectedValues,
  updateEmailValues,
  disabled,
  isCustomStyle,
}) => {
  const [workSpaceContacts, setWorkSpaceContacts] = useState([]);
  // Get contacts list of the workspace from localstorage
  useEffect(() => {
    const owner = JSON.parse(localStorage.getItem("owner") || "");
    const contacts = owner?.contacts;
    setWorkSpaceContacts(contacts);
  }, []);
  return (
    <>
      <CreatableSelect
        isDisabled={disabled}
        closeMenuOnSelect={false}
        styles={isCustomStyle ? customStyle : {}}
        className={`w-full text-xs md:text-sm outline-none`}
        isMulti={true}
        value={selectedValues.map((tag: any) => ({ value: tag, label: tag }))}
        options={workSpaceContacts?.map((contact: any) => ({
          value: contact?.email,
          label: contact?.email,
        }))}
        onChange={(selectedOptions: any) => {
          const arr = selectedOptions?.map((option: any) => option?.value);
          updateEmailValues(arr);
        }}
        onCreateOption={(inputValue) => {
          if (validateEmail(inputValue.trim())) {
            const data = {
              name: "",
              source: "MANUAL",
              email: inputValue.trim(),
              phoneNumber: "",
            };
            const temp: any = [...workSpaceContacts];
            temp.push(data);
            setWorkSpaceContacts(temp);
            let arr = selectedValues;
            if (!selectedValues.includes(inputValue.trim())) {
              arr.push(inputValue.trim());
              updateEmailValues(arr);
            }
          } else {
            toast.error("Please enter a valid email");
          }
        }}
        placeholder="Enter email address"
        components={{
          DropdownIndicator: isCustomStyle
            ? null
            : components.DropdownIndicator,
        }}
      />
    </>
  );
};

export default EmailMultiSelect;
