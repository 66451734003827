import React, { KeyboardEvent } from "react";
import { SchemaUIProps } from "./SchemaUI";
import {
  ImageSchema,
  SchemaForUI,
  TextSchema,
} from "../../../../common/src/type";
import { ImCheckboxChecked } from "react-icons/im";
import { BiCheckbox, BiCheckboxSquare } from "react-icons/bi";
import { SIZES, STANDARD_DATA } from "../../constants";
import { AiFillPlusCircle } from "react-icons/ai";
import { v4 } from "uuid";
import { getWhiteLabelInfoLocal } from "../../../../../utils";
import { COLORS } from "../../../../../constants/common";
import { isEditorSchema } from "../../designerHelper";

type Props = SchemaUIProps & { schema: ImageSchema };

const CheckboxSchemaUI = ({
  schema,
  editable,
  placeholder,
  tabIndex,
  onChange,
  isActive,
  setActiveSchema,
  setShownModal,
  outlineStyle,
  onCheck,
  activeElements,
  addSchema,
  pageSizes,
  pageCursor,
  isPreview,
  isSigning,
  previewStyle,
  instaceFrom,
  previewState,
  isApprover,
  designerState,
}: Props) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const primaryColor =
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary;
  const isEditorARecipient = isEditorSchema({
    schema,
    receiversList: designerState?.receiversList || [],
    instanceFrom: designerState?.instanceFrom || "PDF-TEMPLATE",
  });
  const newEditable = editable || isEditorARecipient;
  const handleClick = () => {
    if (schema.data === STANDARD_DATA.checkbox.checked_DBKEYV1.key) {
      onChange(STANDARD_DATA.checkbox.unchecked_DBKEYV1.key);
    } else {
      onChange(STANDARD_DATA.checkbox.checked_DBKEYV1.key);
    }
  };

  const getImage = (schema: SchemaForUI) => {
    if (
      schema.data == "checked_DBKEYV1" ||
      schema.data == "unchecked_DBKEYV1"
    ) {
      return STANDARD_DATA.checkbox[schema.data].data;
    }
    return "";
  };

  const getBackground = () => {
    if (isPreview && previewStyle !== "DEFAULT") return "transparent";

    if (schema?.isQuestionDeleted) {
      return "rgb(255 0 0 / 0.30)";
    }

    if (isPreview || isSigning) {
      if (previewState?.fetchedData?.accountInfo?.theme) {
        if (isActive && newEditable) {
          return previewState?.fetchedData?.accountInfo?.theme + "70";
        } else {
          return previewState?.fetchedData?.accountInfo?.theme + "20";
        }
      } else {
        if (isActive && newEditable) {
          // return primaryColor + "70";
          return `hsl(var(--primary) / 0.55)`;
        } else {
          // return primaryColor + "20";
          return `hsl(var(--primary) / 0.2)`;
        }
      }
    }

    if (isActive && newEditable) {
      if (schema?.signatureColour) return schema?.signatureColour + 70;

      if (schema?.role?.colour) return schema?.role?.colour + "70";
    } else {
      if (schema?.signatureColour) return schema?.signatureColour + 20;

      if (schema?.role?.colour) return schema?.role?.colour + "20";
    }
  };
  function getTitle(): string {
    if (schema?.isQuestionDeleted) {
      return "This Question is Deleted";
    } else {
      if (isApprover) {
        if (!schema?.isStatic && schema?.role) {
          let signer = previewState?.signersData?.[schema?.role?.title];
          return signer?.name || signer?.email || schema?.showKey;
        }
      }
      return schema?.showKey;
    }
  }

  const clickHandler = () => {
    if (isApprover) {
      return;
    }
    setActiveSchema && setActiveSchema(schema);
    if (newEditable) handleClick();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      clickHandler();
    }
  };

  return (
    <div
      data-schema-sub-type={schema.subType}
      data-schema-id={schema.id}
      tabIndex={tabIndex}
      className={`flex items-center justify-center relative h-full w-full text-xl`}
      style={{
        background: getBackground(),
      }}
      title={getTitle()}
      onClick={clickHandler}
      onKeyDown={handleKeyDown}
    >
      <img
        src={getImage(schema)}
        // style={{ maxHeight: "100%", maxWidth: "100%" }}
        style={{ height: "100%", width: "100%" }}
        // onClick={() => {
        //   if (isApprover) {
        //     return;
        //   }
        //   if (editable) handleClick();
        // }}
      />
      {!newEditable && isActive && activeElements?.length == 1 && (
        <AiFillPlusCircle
          className="absolute right-[-35px] bottom-[-35px] text-2xl"
          style={{ color: schema?.role?.colour || schema?.signatureColour }}
          onClick={() => {
            if (addSchema) {
              let calcheight = 0;
              if (pageSizes) {
                let pageDim = pageSizes[pageCursor || 0].height;
                let oldCalcHeight =
                  schema.position.y + schema.height + 5 + SIZES.checkbox.height;
                calcheight =
                  pageDim - oldCalcHeight < 0
                    ? pageDim - SIZES.checkbox.height
                    : oldCalcHeight - SIZES.checkbox.height;
              }
              if (instaceFrom == "PDF-TEMPLATE") {
                addSchema({
                  title: "CheckBox",
                  type: "checkbox",
                  id: v4(),
                  required: false,
                  subType: "checkbox",
                  role: schema.role,
                  position: {
                    x: schema.position.x,
                    y: calcheight,
                  },
                  size: { height: schema.height, width: schema.width },
                });
              }
              if (instaceFrom == "PDF") {
                const _id = schema?.id?.split("_")?.[1];
                const data = {
                  title: "CheckBox",
                  type: "checkbox",
                  subType: "checkbox",
                  position: {
                    x: schema.position.x,
                    y: calcheight,
                  },
                  colour: schema?.signatureColour,
                  role: schema?.role,
                  size: { height: schema.height, width: schema.width },
                  _id,
                };
                addSchema(data);
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default CheckboxSchemaUI;
