import React, { useEffect, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./../../utils/axios";
import { Slide, ToastContainer, toast } from "react-toastify";
import { BASE_URL, getFormViewerUrl } from "../../constants/constants";
import { fetchOwner, getWorkSpaceDetails } from "../../utils";
import "./index.css";

import { v4 } from "uuid";
import { uid } from "../../utils";
import { BsFiletypePdf } from "react-icons/bs";
import { MdOutlineFormatAlignLeft } from "react-icons/md";
import { FcSignature } from "react-icons/fc";
import { SiGoogleforms } from "react-icons/si";
import { FaFilePdf } from "react-icons/fa6";
import { HiTemplate } from "react-icons/hi";
import { CgTemplate } from "react-icons/cg";
import { isFeatureEnabled } from "../../utils/paidFeat";
import { Helmet } from "react-helmet";
import { useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import PlanExaustedBannerEditor from "../../components/PlanExaustedModalEditor";
import {
  BoloIconForm,
  BoloIconPDF,
  BoloIconPDFTemplate,
} from "../../components/Common/Icons/BoloIcons";
import { color } from "@mui/system";

const Index = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  const navigate = useNavigate();
  const [isQuotaExausted, setIsQuotaExausted] = useState(false);
  const [pos, setPos] = useState({ top: 0, left: 0 });

  const handleMouseOver = (event: MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPos({
      top: rect.top + rect.height / 2,
      left: rect.left + rect.width / 2,
    });
  };

  const [formData, setFormData] = useState({
    id: v4(),
    name: "",
    desc: "",
    connectSpreadsheet: false,
    saving: false,
    // formJson: null,
    formJson: {
      title: "Untitled",
      description: "No Description",
      logo:
        whiteLabelInfo?.settings?.organization?.logo || IMAGE_LINKS.BOLOLOGO,
      fontFamily: "Poppins",
      accentColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
      backgroundColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.secondary ||
        "#f3f4f6",
      themeTemplate: "google-forms", // notion-forms, default
      darkMode: false,
      pages: [
        {
          title: "Page 1",
          description: "Page Description",
          id: v4(),
          questions: [
            {
              type: "Text",
              title: "Email",
              description: "",
              required: true,
              id: v4(),
              value: "",
              mandatory: true,
              validation: "respondent_email",
            },
          ],
          goToNextPage: "next",
          goToPrevPage: "prev",
        },
        {
          title: "Page 2",
          description: "Page Description",
          id: v4(),
          questions: [],
          goToNextPage: "next",
          goToPrevPage: "prev",
        },
      ],
      prefill: {},
      prefillSource: "NONE",
      showViewAndVerifyPdf: true,
      showThankYouPageBranding: true,
    },
    isApprovalWorkflowEnabled: true,
  });
  const [owner, setOwner] = useState({});
  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );
  useEffect(() => {
    const updateLocalStore = async () => {
      // @ts-ignore
      let user = JSON.parse(localStorage.getItem("owner"));
      if (user && user._id) {
        let owner = await fetchOwner(user._id);
        if (owner) {
          localStorage.setItem("owner", JSON.stringify(owner));
        }
      }
    };
    updateLocalStore();
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);

  const handleCreateForm = async (
    redirectUrl: string = "/forms/edit",
    renderer: string = "BLANK"
  ) => {
    let formName = formData.name || "Untitled Form, Signature Test";
    if (formName === "" && renderer === "BLANK") {
      return toast.error("Please enter a valid form name");
    }
    setFormData({ ...formData, saving: true });

    let formUrl =
      redirectUrl?.split("/").length > 3
        ? `${getFormViewerUrl()}/${redirectUrl?.split("/")[3]}/${
            formData.id
          }?p=view`
        : `${getFormViewerUrl()}/${formData.id}?p=view`;

    try {
      let apiData: any = {
        id: formData.id,
        name: formData.name || "Untitled Form",
        desc: formData.desc || "No description",
        // destinationId: null,
        // spreadsheetUrl: null,
        authorEmail: currentUserEmail,
        formUrl,
        // @ts-ignore
        ownerId: owner._id,
        oAuthToken: null,
        formInputFields: [],
        formType: "BOLOFORMS",
        createdBy: JSON.parse(localStorage.getItem("owner") || "")._id,
        // writeSpreadsheet: JSON.parse(formData.connectSpreadsheet),
        // writeSpreadsheet: formData.connectSpreadsheet,
      };

      if (formData.formJson) {
        apiData.formJson = formData.formJson;
        apiData.formJson.accentColor =
          whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
          COLORS.primary;
        apiData.formJson.backgroundColor =
          whiteLabelInfo?.settings?.organization?.themeHex?.secondary ||
          COLORS.secondary;
        apiData.formJson.logo =
          whiteLabelInfo?.settings?.organization?.logo || IMAGE_LINKS.BOLOLOGO;

        let responseItemsId: any = {};
        let formInputFields: string[] = [];
        for (const page of apiData.formJson.pages) {
          for (const question of page.questions) {
            delete question["chosen"];
            delete question["selected"];
            responseItemsId[question.id] = question.title;
            formInputFields.push(question.title);
            if (question.title.trim() === "") {
              toast.error("Please fill all the question titles");
              return;
            }
          }
        }
        apiData.responseItemsId = responseItemsId;
        apiData.formInputFields = formInputFields;
      }

      try {
        console.log({ apiData });
        const { data } = await axios.post("/forms", apiData);
        toast.dismiss();
        if (data.error === "You have exhausted your allowed quota") {
          setIsQuotaExausted(true);
          return;
        }

        // if (data?.needPermission) {
        //   setShow((prev: any) => {
        //     return {
        //       ...prev,
        //       permissionModal: {
        //         ...prev.permissionModal,
        //         show: true,
        //         data: {
        //           ...prev.permissionModal.data,
        //           permissionOptions: data.scopeOptions,
        //           permissionUrl: data.permissionUrl,
        //           permission: data.scopeOptions[0].value,
        //         },
        //       },
        //     };
        //   });

        //   setFormData({ ...formData, saving: false });

        //   return;
        // }

        console.log("data", data);

        let isTrialExtended = data?.trialExtended;

        let navigateUrl = `/create/forms/?id=${formData.id}`;

        if (isTrialExtended?.extended) {
          navigateUrl = `/create/forms/?id=${formData.id}&trialExtended=true&d=${isTrialExtended?.days}`;
        }

        if (!data?.needPermission && !data.error) {
          navigate(navigateUrl);
          setFormData({ ...formData, saving: false });
        } else {
          // toast.error("Something went wrong, create page: line 190 : test");
        }
      } catch (error: any) {
        console.log(error);
        toast.error(error?.message);
        setFormData({ ...formData, saving: false });
      }
    } catch (error: any) {
      console.log(error);
      // toast.error("Something went wrong, create page: line 190 : test");
      toast.error(error?.message);
      setFormData({ ...formData, saving: false });
    }
  };

  const handleChoice = (choice: string) => {
    if (
      !workSpaceDetails?.isWorkSpaceOwner &&
      (workSpaceDetails?.currentWorkSpaceRole == "readonly" ||
        workSpaceDetails?.currentWorkSpaceRole == "editor")
    ) {
      toast.error("Only Owner and Administrators are allowed to create");
      return;
    }
    if (choice === "forms") {
      // navigate("/create/forms");
      handleCreateForm();
    } else if (choice === "pdf") {
      navigate("/create/pdf");
    } else if (choice === "template") {
      navigate(`/create/form-template?id=${v4()}`);
    } else if (choice === "pdf-template") {
      navigate(`/new/pdf-template`);
    }
  };

  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      setWorkSpaceDetails(localWorkSpaceDetails);
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  const data = isFeatureEnabled("");
  return (
    <div className="flex flex-col -m-4 items-center justify-center h-[calc(100%_+_32px)] p-4 relative">
      <div
        className={`absolute inset-0 h-full w-full z-[-1] transition-all duration-300 bg-secondary/50`}
        // style={{
        //   // background: "rgb(139,92,246)",
        //   background:
        //     "radial-gradient(circle, hsl(var(--primary) / 0.4) 0%, rgba(255,255,255,1) 90%)",
        // }}
      ></div>
      <Helmet>
        <title translate="no">Create</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Create" />
        <meta
          name="description"
          content="Effortlessly create new forms, Pdf templates, and Pdfs for seamless document signing on BoloForms."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/create`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Create`} />
        <meta
          property="og:description"
          content="Effortlessly create new forms, Pdf templates, and Pdfs for seamless document signing on BoloForms."
        />
        <link rel="canonical" href="https://signature.boloforms.com/create" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <h2 className="text-xl md:text-4xl font-bold text-gray-700 mb-3 capitalize">
        How do you want to take Signature?
      </h2>
      <p className="text-sm md:text-md mb-8 capitalize">
        Create a new{" "}
        <span className="text-primary font-medium">
          Form | Upload a PDF | Build a Template
        </span>{" "}
        to sign your documents.
      </p>

      <div className="w-full md:h-fit mt-4 md:mt-8 flex flex-col md:flex-row flex-wrap gap-6 md:gap-12 justify-center items-center">
        <FormView
          handleMouseOver={handleMouseOver}
          icon={
            // <SiGoogleforms size={40} className="text-gray-600" />
            // "https://ssl.gstatic.com/docs/templates/thumbnails/forms-blank-googlecolors.png"
            <BoloIconForm bolosize={"create-page"} />
          }
          color={COLORS.icons.FORM}
          name={"Form"}
          // desc={"Create a blank form to for Signature"}
          desc={"Signatures using Form"}
          onClick={() => handleChoice("forms")}
        />
        <FormView
          handleMouseOver={handleMouseOver}
          // imgUrl="https://www.pcworld.com/wp-content/uploads/2023/05/pdf-icon.jpg?resize=1024%2C683&quality=50&strip=all"
          // icon={<FaFilePdf size={40} className="text-gray-600" />}
          icon={<BoloIconPDF bolosize={"create-page"} />}
          name={"PDF"}
          color={COLORS.icons.PDF}
          // desc={"Upload a PDF document for Signature"}
          desc={"Send PDF for Signature"}
          onClick={() => handleChoice("pdf")}
        />
        <FormView
          handleMouseOver={handleMouseOver}
          // imgUrl="https://www.pcworld.com/wp-content/uploads/2023/05/pdf-icon.jpg?resize=1024%2C683&quality=50&strip=all"
          // icon={<CgTemplate size={40} className="text-gray-600" />}
          icon={<BoloIconPDFTemplate bolosize={"create-page"} />}
          name={"PDF Template"}
          color={COLORS.icons["PDF-TEMPLATE"]}
          // desc={"Build a PDF template and integrate with your form"}
          desc={"Templatize your PDFs"}
          onClick={() => handleChoice("pdf-template")}
        />

        {/* <FormView
          // imgUrl="https://www.pcworld.com/wp-content/uploads/2023/05/pdf-icon.jpg?resize=1024%2C683&quality=50&strip=all"
          icon={<MdOutlineFormatAlignLeft size={90} className="text-green-500" />}
          name={"Template"}
          desc={"Build a PDF template and integrate with your form"}
          onClick={() => handleChoice("template")}
        /> */}
      </div>
      {isQuotaExausted && (
        <PlanExaustedBannerEditor
          setShow={(value: boolean) => {
            setIsQuotaExausted(value);
          }}
        />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

interface FormViewProps {
  imgUrl?: string;
  icon?: any;
  name?: string;
  desc?: string;
  color: string;
  onClick?: () => void;
  handleMouseOver: (event: MouseEvent<HTMLDivElement>) => void;
}

const FormView = ({
  imgUrl,
  name,
  desc,
  onClick,
  icon,
  color,
  handleMouseOver,
}: FormViewProps) => {
  return (
    <div
      onMouseOver={handleMouseOver}
      onClick={onClick}
      className="bg-white shadow-md circle-trigger group/circle rounded-2xl overflow-hidden relative md:h-[250px] gap-4 create-white-cont md:mb-4 cursor-pointer border-gray-50 p-4 md:p-2 flex flex-row md:flex-col justify-start md:justify-center items-center hover:scale-125 transition-all ease-in"
    >
      <div
        className="h-32 w-32 z-[-1] absolute top-[-75px] right-[-75px] rounded-full transition-all duration-300 ease-in group-hover/circle:scale-[6]"
        style={{ background: color + 10 }}
      ></div>
      {/* sample commit */}
      {icon}
      <div className="p-2 md:text-center">
        <p className="mb-1.5 font-semibold">{name}</p>{" "}
        <p className="text-xs text-gray-400">{desc}</p>
      </div>
    </div>
  );
};

export default Index;
