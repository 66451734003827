import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import { IoArrowForwardCircle } from "react-icons/io5";
import { BiSolidTimeFive } from "react-icons/bi";
import MixPanel from "../../utils/services/mixpanel";
import { BoloButton } from "../Common/Button/BoloButton";
import { isEmbeded } from "../../utils/embed";

type Props = {
  loading: boolean;
  file: File | null;
  goToNextPage: boolean;
  setGoToNextPage: any;
  setStartSigning: any;
  saveAsDraft?: any;
  isDrafting?: boolean;
  receiversList: any[];
  isSigningOrderData?: boolean;
};

const NextPage = ({
  loading,
  file,
  goToNextPage,
  setGoToNextPage,
  setStartSigning,
  saveAsDraft,
  isDrafting,
  receiversList,
  isSigningOrderData,
}: Props) => {
  const goToNextHandler = async () => {
    new MixPanel().track("PDF", {
      pdf_action: "pdf_confirm_document",
      isSigningOrderData: isSigningOrderData,
      signers: receiversList?.length,
      paid: true,
    });
    new MixPanel().increment("pdf_confirm_document");
    if (receiversList) {
      let invalidOrderNumber = false;
      if (isSigningOrderData) {
        receiversList?.map((elm: any) => {
          if (
            isNaN(parseInt(elm?.signingOrderNo)) ||
            parseInt(elm?.signingOrderNo) < 1 ||
            parseInt(elm?.signingOrderNo) > receiversList?.length
          ) {
            invalidOrderNumber = true;
          }
        });
      }
      if (invalidOrderNumber) {
        toast.error("Please provide appropriate Signing Order numbers");
        return;
      }
    }
    if (!loading && file && receiversList?.length > 0) {
      if (saveAsDraft) await saveAsDraft(true);
    } else if (!file) {
      toast.error("Please upload a pdf file");
      return;
    } else if (receiversList?.length === 0) {
      toast.error("Please add receivers");
      return;
    }
  };
  const saveAsDraftHandler = async () => {
    new MixPanel().track("PDF", {
      pdf_action: "pdf_save_as_draft",
      isSigningOrderData: isSigningOrderData,
      signers: receiversList?.length,
      paid: true,
    });
    new MixPanel().increment("pdf_save_as_draft");
    if (!loading && file && receiversList?.length > 0) {
      if (saveAsDraft) await saveAsDraft();
    } else if (!file) {
      toast.error("Please upload a pdf file");
      return;
    } else if (receiversList?.length === 0) {
      toast.error("Please add receivers");
      return;
    }
  };

  const goBackHandler = () => {
    setGoToNextPage(false);
  };

  return (
    <div className="py-4 w-[100%] md:w-[65%] max-w-[800px] mb-8 flex gap-4 justify-end mx-auto">
      <div className="mt-4 flex justify-end gap-4 w-full font-medium">
        <BoloButton
          size={"sm"}
          variant={"tertiary"}
          className={`w-full ${isEmbeded() ? "hidden" : ""}`}
          data-tooltip-id="my-tooltip"
          data-tooltip-place="bottom"
          // className="text-primary w-full bg-secondary px-6 py-2 rounded-md text-xs md:text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
          onClick={saveAsDraftHandler}
          disabled={isDrafting}
        >
          <BiSolidTimeFive className="inline-block text-2xl" />
          <span className="">
            {" "}
            {isDrafting ? "Saving..." : "Save as Draft"}
          </span>{" "}
        </BoloButton>
        <Tooltip id="my-tooltip">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>The receivers and the document</span>
            <span>will be saved as a draft.</span>
            <span className="font-semibold text-yellow-200">
              You can continue editing it
            </span>
            <span className="font-semibold text-yellow-200">
              later from dashboard!
            </span>
          </div>
        </Tooltip>
        <BoloButton
          size={"sm"}
          className="w-full"
          // className="bg-primary w-full text-white px-6 py-2 rounded-md text-xs md:text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
          onClick={goToNextHandler}
          disabled={isDrafting}
        >
          <span className="">Confirm</span>{" "}
          <IoArrowForwardCircle className="inline-block text-2xl" />
        </BoloButton>
      </div>
    </div>
  );
};

export default NextPage;
