import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  BoloDropDown,
  BoloDropDownOption,
} from "../../../../../../components/Common/DropDown/BoloDropDown";
import { BASE_URL } from "../../../../../../constants/constants";
import { languages } from "../../../../../../constants/languages";
import axios from "../../../../../../utils/axios";
import ViewHeader from "./ViewHeader";
import { DesignerState, GlobalHelpersType } from "..";
import MixPanel from "../../../../../../utils/services/mixpanel";
import BoloModal from "../../../../../../components/Common/Modal/BoloModal";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../components/Common/BoloSelect/BoloSelect";

const r2lLangs: { [key: string]: boolean } = {
  ar: true,
  hr: true,
};

const ConfirmR2L = ({
  setShow,
  globalHelpers,
  designerState,
  r2l,
}: {
  setShow: (value: boolean) => void;
  globalHelpers: GlobalHelpersType;
  designerState: DesignerState;
  r2l: boolean;
}) => {
  return (
    <BoloModal
      setShow={setShow}
      content={
        <>
          <div className="text-base md:text-lg mb-4 font-semibold">
            Choose Default Text Alignment
          </div>
          <div className="gap-2 items-center justify-between text-xs">
            <p>
              You have switched to a language with{" "}
              <span className="font-semibold">
                {r2l ? "right-to-left" : "left-to-right"} script
              </span>
            </p>
            <p>
              Would you like to change the{" "}
              <span className="font-semibold">text alignment</span> along with
              the defaults for this document
            </p>
            <p>You can reset defaults at any time</p>
          </div>
          <div className="flex gap-2 items-center justify-end mt-8">
            <BoloButton
              variant={"secondary"}
              size={"sm"}
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </BoloButton>
            <BoloButton
              size={"sm"}
              onClick={() => {
                let defaultStyles = {
                  ...designerState?.settings?.schemaStyleDefaults,
                };
                defaultStyles.alignment = r2l ? "right" : "left";
                globalHelpers.setDefaultStyles(defaultStyles);
                globalHelpers.applyStyles(defaultStyles);
                setShow(false);
              }}
            >
              Confirm
            </BoloButton>
          </div>
        </>
      }
    />
  );
};

export type LanguageViewProps = {
  helpers: {
    open: boolean;
    setOpen: any;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};

const LanguageView = ({ helpers }: LanguageViewProps) => {
  const { open, setOpen, designerState, setDesignerState, globalHelpers } =
    helpers;

  const [textAlign, setTextAlign] = useState({
    isChanged: false,
    r2l: false,
  });
  const [selectedLang, setSelectedLang] = useState<BoloDropDownOption>(
    designerState?.fetchedData?.document?.settings?.lang
  );
  const selectLang = (value: BoloDropDownOption) => {
    if (r2lLangs[value.key] !== r2lLangs[selectedLang?.key]) {
      // setShowR2L(true);
      setTextAlign((prev) => ({
        ...prev,
        isChanged: true,
        r2l: r2lLangs[value.key],
      }));
    } else {
    }
    setSelectedLang(value);
  };

  const handleSelectedLangChange = async (
    value: BoloDropDownOption | undefined
  ) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/document/settings`, {
        lang: value,
        documentId: designerState?.fetchedData?.document?.documentId,
      });
      if (data?.success) {
        toast.success("Language changed succesfully");
        if (data?.document?.settings) {
          // if settings exists on returned object
          // as when we change language settings will always exist on the document
          setDesignerState((prev) => ({
            ...prev,
            fetchedData: { ...prev.fetchedData, document: data?.document },
          }));
        }
      }
      if (!data?.success) {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debounceCallHandleSave = useCallback(
    _.debounce(
      (debounceVariables: BoloDropDownOption | undefined) =>
        handleSelectedLangChange(debounceVariables),
      1000
    ),
    []
  );

  useEffect(() => {
    if (
      selectedLang?.key !==
        designerState?.fetchedData?.document?.settings?.lang?.key &&
      selectedLang?.key
    ) {
      debounceCallHandleSave(selectedLang);
    }
  }, [selectedLang]);

  // console.log(textAlign);

  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      {textAlign.isChanged && (
        <ConfirmR2L
          setShow={(value: boolean) => {
            setTextAlign((prev) => ({ ...prev, isChanged: value }));
          }}
          globalHelpers={globalHelpers}
          designerState={designerState}
          r2l={textAlign.r2l}
        />
      )}
      {/* {showUpgradeModal && (
    <PleaseUpgradeModal
      show={showUpgradeModal}
      setShow={setShowUpgradeModal}
      data={upgradeModalData}
      blockingType={upgradeModalType}
    />
  )} */}
      <div className="px-4 overflow-y-auto h-[100%]">
        <div className="">
          <ViewHeader name="Language" open={open} setOpen={setOpen} />
        </div>
        <div className="">
          <div className="text-xs p-2 mb-2 bg-orange-100 text-orange-600 rounded-md">
            Note: Some text formating options might not work with custom
            languages
          </div>
          <p className="text-gray-500 text-xs pr-4 mb-2">
            For better reliability please leave any formatting to default with
            custom languages
          </p>
          {/* <p className="text-gray-500 text-xs pr-4 mb-2">
            This allows us to support certain character sets which are not
            available by defaults
          </p> */}
          <p className="text-gray-500 text-xs pr-4 mb-2">
            You can change the language in which the PDF is generated, English
            will be supported by default in all languages.
          </p>
          <p className="text-gray-500 text-xs pr-4 mb-2">
            For any other language request{" "}
            <a
              href="https://boloforms.canny.io/"
              target="_blank"
              className="text-blue-600"
            >
              here
            </a>
          </p>
        </div>

        <div className="mt-4 w-[100%]">
          {/* <BoloDropDown
            className="w-[100%]"
            size={"sm"}
            defaultText="Select Language"
            options={languages}
            selectedOption={selectedLang}
            selectOption={selectLang}
          /> */}

          <Select
            required
            value={selectedLang?.key}
            onValueChange={(value) => {
              setSelectedLang((prev) => {
                let data = languages?.find((lang) => lang.key == value);
                if (data) {
                  return data;
                }
                return prev;
              });
            }}
          >
            <SelectTrigger className="w-full px-0 pb-0">
              <SelectValue placeholder="Select Language" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {/* <SelectLabel>Currency Code</SelectLabel> */}
                {languages?.map((lang) => {
                  return (
                    <SelectItem value={lang.key}>
                      <p className="text-gray-500 text-xs">{lang.value}</p>
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default LanguageView;
