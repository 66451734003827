import React, {
  Ref,
  MutableRefObject,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useContext,
} from "react";
import { OnDrag, OnResize, OnClick } from "react-moveable";
// import { SchemaForUI, Size } from '@pdfme/common';
// import { IoCloseSharp as XMarkIcon } from "react-icons/io5";
import {
  ZOOM,
  // RULER_HEIGHT,
  // FONT_2_HEIGHT,
  getHeightFromFontSize,
  STATIC_SCHEMA,
  POSITION,
  STANDARD_DATA,
} from "../../../constants";
import { usePrevious } from "../../../hooks";
import {
  uuid,
  round,
  flatten,
  getSchemaHeightForDiv,
  validBlankPageSchema,
  fmt4Num,
  fmt,
  onResizeMoveOtherSchema,
} from "../../../helper";
import Paper from "../../Paper";
import SchemaUI from "../../Schemas/SchemaUI";
import Selecto from "./Selecto";
import Moveable from "./Moveable";
import Guides from "./Guides";
import Mask from "./Mask";
import { FontContext } from "../../../contexts";
import { SchemaForUI, Size } from "../../../../../common/src";
import { AiFillPlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { removeSquareBrackets } from "../../../../../../utils/uiUtils";
// import {
//   DropdownOptionSchema,
//   isTableSchema,
// } from "../../../../../common/src/type";
import { LuPaintBucket } from "react-icons/lu";
import {
  ChangeSchemasArg,
  ChangeSchemasType,
  CustomSchemaType,
  DesignerState,
  GlobalHelpersType,
  PdfTemplateSidebarViewTypes,
} from "..";
import CustomTooltip from "../../../../../../components/EditForm/CustomTooltip";
import {
  FcAddColumn,
  FcAddRow,
  FcDeleteColumn,
  FcDeleteRow,
} from "react-icons/fc";
import { toast } from "react-toastify";
import { getWhiteLabelInfoLocal } from "../../../../../../utils";
import { IoMdSettings } from "react-icons/io";
import { TableSchema } from "../../../../../common/src/type";
import { IconType } from "react-icons/lib";
import { IconBaseProps } from "react-icons";
import { cn } from "../../../../../../components/Common/lib/utils";
import { COLORS } from "../../../../../../constants/common";
import _ from "lodash";
// import { WhiteLabelOwnerType } from "../../../../../../redux/slices/whiteLabelSlice";

const DELETE_BTN_ID = uuid();
const HOVER_OPTS_BTN_IDS = {
  DELETE: "DELETE" + uuid(),
  SETTINGS: "SETTINGS" + uuid(),
};
// const fmt4Num = (prop: string) => Number(prop.replace("px", ""));
// const fmt = (prop: string) => round(fmt4Num(prop) / ZOOM, 2);
const isTopLeftResize = (d: string) =>
  d === "-1,-1" || d === "-1,0" || d === "0,-1";

interface Props {
  height: number;
  hoveringSchemaId: string | null;
  onChangeHoveringSchemaId: (id: string | null) => void;
  pageCursor: number;
  schemasList: SchemaForUI[][];
  scale: number;
  backgrounds: string[];
  pageSizes: Size[];
  size: Size;
  activeElements: HTMLElement[];
  onEdit: (targets: HTMLElement[]) => void;
  // changeSchemas: (
  //   objs: { key: string; value: string | number | boolean; schemaId: string }[]
  // ) => void;
  changeSchemas: ChangeSchemasType;
  removeSchemas: (ids: string[]) => void;
  paperRefs: MutableRefObject<HTMLDivElement[]>;
  addSchema?: (schema: any) => void;
  radioGroupCovers?: any[];
  instanceFrom?: string;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
}

//! Note HACK_FIX
// since the svg paths do not have "data-hover-option-id" property
// and passing it to them is too intensive this is a hack fix

function IconPathHackFix({
  Icon,
  iconProps,
  wrapperClass,
  hoverOption,
}: {
  Icon: IconType;
  iconProps: IconBaseProps;
  wrapperClass?: string;
  hoverOption?: string;
}) {
  return (
    <div
      className={cn(
        "relative flex items-center justify-center h-full w-full",
        wrapperClass
      )}
      data-hover-option-id={hoverOption}
    >
      <Icon
        {...iconProps}
        className={cn(iconProps.className, "absolute z-0")}
      />
      <div
        className={cn("absolute inset-0 z-2 h-full w-full", wrapperClass)}
        data-hover-option-id={hoverOption}
      ></div>
    </div>
  );
}

const HoverOptions = ({
  activeElements: aes,
  schemasList,
  pageCursor,
  changeSchemas,
  pageSize,
  // pdfTemplate,
  instanceFrom,
  designerState,
  setDesignerState,
  globalHelpers,
  isDragging,
}: Pick<
  Props,
  | "activeElements"
  | "schemasList"
  | "pageCursor"
  | "changeSchemas"
  // | "pdfTemplate"
  | "instanceFrom"
  | "designerState"
  | "setDesignerState"
  | "globalHelpers"
> & {
  pageSize: Size;
  isDragging: boolean;
}) => {
  const top = Math.min(...aes.map(({ style }) => fmt4Num(style.top))) - 48;
  const left = Math.max(
    ...aes.map(({ style }) => fmt4Num(style.left) + fmt4Num(style.width) / 2)
  );
  const [schemaUpdated, setSchemaUpdated] = useState(false);
  const getActiveSchemas = (): SchemaForUI[] => {
    const ids = aes.map((ae) => ae.id);

    return schemasList[pageCursor].filter((s) => ids.includes(s.id));
  };
  const [activeSchema, setActiveSchema] = useState<SchemaForUI | undefined>(
    getActiveSchemas()?.[0]
  );
  const [fontSize, setFontSize] = useState({
    show: false,
    sizes: [
      10, 11, 12, 13, 14, 15, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60,
    ],
    changedSize: 0,
  });
  const [roles, setRoles] = useState<{ show: boolean; roles: any[] }>({
    show: false,
    roles: [],
  });
  const tableHelpers = {
    /*
    ------------------------------------------------------------------
    Here calc schema height
    // TODO: No need of args => {id:  string}
    ------------------------------------------------------------------
    */
    addCol: (args: { id: string }) => {
      if (activeSchema?.type == "table") {
        const rowsCopy = [...activeSchema.rows];
        const headCopy = [...activeSchema.head];
        const headWidthPercentagesCopy = [...activeSchema.headWidthPercentages];

        const numCols = headCopy.length || 0;
        const numRows = rowsCopy.length || 0;

        // handle percetage width
        // there are 4 columns
        // if we add 5th col we reduce all previous cols by 1/no_of_cols times
        // we make the 5th col the sum of all reductions
        let nthColWidth = 0;
        let newHeadWidthPercentages = [];
        for (let index = 0; index < numCols; index++) {
          // 2 because considering no one would add more than 50 cols in the table
          // 100/2 = 50
          const ratio = 1 / (numCols + 1);
          const reducedWidth = ratio * headWidthPercentagesCopy[index];
          let calcWidth = headWidthPercentagesCopy[index] - reducedWidth;
          let width = Math.max(2, calcWidth);
          nthColWidth += reducedWidth;
          newHeadWidthPercentages.push(width);
        }
        newHeadWidthPercentages.push(nthColWidth);

        // handle head
        headCopy.push({ data: `Header ${numCols + 1}` });

        // handle rows
        for (let index = 0; index < numRows; index++) {
          rowsCopy[index].push({ data: `Row ${index + 1}-${numCols + 1}` });
        }

        changeSchemas([
          {
            key: "headWidthPercentages",
            schemaId: args.id,
            value: newHeadWidthPercentages,
          },
          { key: "head", schemaId: args.id, value: headCopy },
          { key: "rows", schemaId: args.id, value: rowsCopy },
        ]);
        setSchemaUpdated((prev) => !prev);
      }
    },
    delCol: (args: { id: string }) => {
      if (activeSchema?.type == "table") {
        const rowsCopy = [...activeSchema.rows];
        const headCopy = [...activeSchema.head];
        const headWidthPercentagesCopy = [...activeSchema.headWidthPercentages];

        const colToRemove = headCopy.length - 1;
        const numCols = headCopy.length || 0;
        const numRows = rowsCopy.length || 0;

        if (numCols == 1) {
          return toast.error("Cannot Delete Last Column");
        }

        //handle percentage
        let headPercentageToRemove = headWidthPercentagesCopy[colToRemove];
        let widthByNoCols = headPercentageToRemove / (numCols - 1);
        let newHeadWidthPercentages = [];
        for (let index = 0; index < numCols; index++) {
          if (index == colToRemove) {
            continue;
          }
          let calcWidth = headWidthPercentagesCopy[index] + widthByNoCols;
          newHeadWidthPercentages.push(calcWidth);
        }

        // handle head
        headCopy.splice(colToRemove, 1);

        // handle rows
        for (let index = 0; index < numRows; index++) {
          rowsCopy[index].splice(colToRemove, 1);
        }

        changeSchemas([
          {
            key: "headWidthPercentages",
            schemaId: args.id,
            value: newHeadWidthPercentages,
          },
          { key: "head", schemaId: args.id, value: headCopy },
          { key: "rows", schemaId: args.id, value: rowsCopy },
        ]);
        setSchemaUpdated((prev) => !prev);
      }
    },
    addRow: (args: { id: string }) => {
      if (activeSchema?.type == "table") {
        const rowsCopy = [...activeSchema.rows];
        const numCols = activeSchema?.head?.length || 0;

        let newRow = [];
        for (let index = 0; index < numCols; index++) {
          newRow.push({
            data: `Row ${(rowsCopy.length || 0) + 1}-${index + 1}`,
          });
        }

        rowsCopy.push(newRow);

        const heights = getSchemaHeightForDiv({
          pageSize,
          schema: activeSchema,
        });

        changeSchemas([
          { key: "rows", schemaId: args.id, value: rowsCopy },
          ...heights.changeSchemaArg,
        ]);
        setSchemaUpdated((prev) => !prev);
      }
    },
    delRow: (args: { id: string }) => {
      if (activeSchema?.type == "table") {
        const rowsCopy = [...activeSchema.rows];
        const itemToRemove = rowsCopy.length - 1;
        rowsCopy.splice(itemToRemove, 1);
        const heights = getSchemaHeightForDiv({
          pageSize,
          schema: activeSchema,
        });
        changeSchemas([
          { key: "rows", schemaId: args.id, value: rowsCopy },
          ...heights.changeSchemaArg,
        ]);
        setSchemaUpdated((prev) => !prev);
      }
    },
  };

  useEffect(() => {
    let roles_: any[] = [];

    if (designerState?.instanceFrom == "PDF") {
      roles_ = [...designerState?.receiversList];
    }
    if (designerState?.instanceFrom == "PDF-TEMPLATE") {
      roles_ = [...designerState?.roles];
    }

    setRoles((prev) => ({ ...prev, roles: roles_ }));
  }, []);

  useEffect(() => {
    setActiveSchema(getActiveSchemas()?.[0]);
  }, [aes, schemaUpdated]);

  useEffect(() => {
    setRoles((prev) => ({ ...prev, show: false }));
    setFontSize((prev) => ({ ...prev, show: false }));
  }, [activeSchema?.id]);

  // useEffect(() => {
  //   console.log("Active schema changed", activeSchema);
  // }, [activeSchema]);

  return (
    <div
      data-hover-option-id={aes?.[0]?.id}
      className={`cursor-pointer text-sm duration-300 transition-all w-fit bg-[#545559] translate-x-[-50%] rounded text-white flex items-center gap-1 ${
        !isDragging && aes.length === 1
          ? "min-h-10 max-h-10 w-auto p-2"
          : "min-h-[0px] max-h-[0px] max-w-[0px] p-0 mt-auto overflow-hidden"
      }`}
      style={{
        position: "absolute",
        // such high zindex because react moveable control box has 3000 z index
        zIndex: 3030,
        top,
        left,
      }}
    >
      {designerState?.mode === "PREVIEW" && (
        <>
          {activeSchema?.role?.title && (
            <div
              data-hover-option-id={aes?.[0]?.id}
              onClick={() => {}}
              data-tooltip-id="schema-role"
              className="relative flex min-h-8 max-h-8 min-w-[100px] w-25 items-center gap-2 whitespace-nowrap font-semibold hover:bg-white/10 px-2 py-1 rounded"
            >
              <div
                data-hover-option-id={aes?.[0]?.id}
                className="h-3 w-3 rounded-full"
                style={{ background: activeSchema?.role?.colour }}
              ></div>
              <div data-hover-option-id={aes?.[0]?.id} className="">
                {activeSchema?.role?.title}
              </div>
              <CustomTooltip
                helpers={{
                  id: "schema-role",
                  content: (
                    <p className="text-xs">Who needs to fill out this field</p>
                  ),
                  place: "top",
                }}
              />
            </div>
          )}
          {!activeSchema?.role?.title && "Static Schema"}
        </>
      )}
      {designerState?.mode === "EDITING" && (
        <>
          {(activeSchema?.type == "text" ||
            (activeSchema?.type == "table" &&
              activeSchema?.subType == "table")) &&
            instanceFrom !== "FORM" && (
              <div
                data-hover-option-id={aes?.[0]?.id}
                data-tooltip-id="font-size"
                className="relative min-h-8 max-h-8 w-12 hover:bg-white/10 px-2 py-1 rounded"
                onClick={() => {
                  setFontSize((prev) => ({ ...prev, show: !prev.show }));
                }}
              >
                {fontSize.changedSize || activeSchema?.fontSize}
                <CustomTooltip
                  helpers={{
                    id: "font-size",
                    content: <p className="text-xs">Font Size</p>,
                    place: "top",
                  }}
                />
                {/* {fontSize.show && ( */}
                <div
                  data-hover-option-id={aes?.[0]?.id}
                  className={`absolute left-0 top-9 rounded overflow-y-auto w-full bg-[#545559] z-[3030] transition-all duration-300 ${
                    fontSize.show ? "h-auto max-h-[112px]" : "max-h-[0px]"
                  }`}
                  id="mini-pdf-custom-scroll"
                >
                  {fontSize.sizes?.map((sz: number) => {
                    return (
                      <div
                        data-hover-option-id={aes?.[0]?.id}
                        className={`px-1.5 py-1 hover:bg-gray-600`}
                        onClick={() => {
                          const currentFontSize = Number(sz);
                          setFontSize((prev) => ({ ...prev, changedSize: sz }));
                          const dynamincFontSizeMinAdjust =
                            activeSchema?.dynamicFontSize &&
                            activeSchema?.dynamicFontSize?.min >
                              currentFontSize;

                          let extraSettings: {
                            key: string;
                            value: boolean | string | number;
                            schemaId: string;
                          }[] = [];
                          if (currentFontSize >= 10) {
                            let bottom =
                              activeSchema.position.y + currentFontSize;
                            if (
                              bottom < pageSize.height &&
                              currentFontSize > activeSchema.height
                            ) {
                              extraSettings = [
                                {
                                  key: "height",
                                  value:
                                    getHeightFromFontSize(
                                      currentFontSize,
                                      false
                                    ) || 5.4,
                                  schemaId: activeSchema.id,
                                },
                              ];
                            }
                          }

                          changeSchemas([
                            {
                              key: "fontSize",
                              value: currentFontSize,
                              schemaId: activeSchema.id,
                            },
                            ...extraSettings,
                            ...(dynamincFontSizeMinAdjust
                              ? [
                                  {
                                    key: "dynamicFontSize.min",
                                    value: currentFontSize,
                                    schemaId: activeSchema.id,
                                  },
                                ]
                              : []),
                          ]);
                          setSchemaUpdated((prev) => !prev);
                        }}
                      >
                        {sz}
                      </div>
                    );
                  })}
                </div>
                {/* )} */}
              </div>
            )}

          {activeSchema?.subType === "pricing_table" && (
            <button
              data-tooltip-id="price-table-edit-quote"
              data-hover-option-id={aes?.[0]?.id}
              id={HOVER_OPTS_BTN_IDS.SETTINGS}
              className="min-h-8 max-h-8 hover:bg-white/10 py-0.5 rounded flex items-center gap-2 px-1"
              onClick={() => {
                setDesignerState((prev) => ({
                  ...prev,
                  view: "QUOTE_BUILDER",
                  quoteBuilder: {
                    ...prev.quoteBuilder,
                    schemaId: activeSchema.id,
                    activeTable: activeSchema as TableSchema,
                  },
                }));
              }}
            >
              {/* <IoMdSettings
            data-hover-option-id={aes?.[0]?.id}
            size={20}
            id={HOVER_OPTS_BTN_IDS.SETTINGS}
          /> */}
              <IconPathHackFix
                iconProps={{ size: 20, id: HOVER_OPTS_BTN_IDS.SETTINGS }}
                Icon={IoMdSettings}
                hoverOption={aes?.[0]?.id}
                wrapperClass="rounded h-6 w-6"
              />
              <p data-hover-option-id={aes?.[0]?.id}>Edit Table</p>
              <CustomTooltip
                helpers={{
                  id: "price-table-edit-quote",
                  content: (
                    <p className="text-xs">Edit product and quantites</p>
                  ),
                  place: "top",
                }}
              />
            </button>
          )}

          {activeSchema?.type == "table" &&
            activeSchema?.subType == "table" && (
              <div className="flex gap-2 mx-3">
                <button
                  data-tooltip-id="table-col-add"
                  data-hover-option-id={aes?.[0]?.id}
                  id={HOVER_OPTS_BTN_IDS.SETTINGS}
                  className="min-h-8 max-h-8 hover:bg-white/10 rounded"
                  onClick={() => {
                    tableHelpers.addCol({ id: activeSchema.id });
                  }}
                >
                  {/* <FcAddColumn
              data-hover-option-id={aes?.[0]?.id}
              size={23}
              id={HOVER_OPTS_BTN_IDS.SETTINGS}
            /> */}
                  <IconPathHackFix
                    iconProps={{ size: 23, id: HOVER_OPTS_BTN_IDS.SETTINGS }}
                    Icon={FcAddColumn}
                    hoverOption={aes?.[0]?.id}
                    wrapperClass="rounded h-8 w-8"
                  />
                  <CustomTooltip
                    helpers={{
                      id: "table-col-add",
                      content: <p className="text-xs">Add Column</p>,
                      place: "top",
                    }}
                  />
                </button>
                <button
                  data-tooltip-id="table-col-del"
                  data-hover-option-id={aes?.[0]?.id}
                  id={HOVER_OPTS_BTN_IDS.SETTINGS}
                  className="min-h-8 max-h-8 hover:bg-white/10 rounded"
                  onClick={() => {
                    tableHelpers.delCol({ id: activeSchema.id });
                  }}
                >
                  {/* <FcDeleteColumn
              data-hover-option-id={aes?.[0]?.id}
              size={23}
              id={HOVER_OPTS_BTN_IDS.SETTINGS}
            /> */}
                  <IconPathHackFix
                    iconProps={{ size: 23, id: HOVER_OPTS_BTN_IDS.SETTINGS }}
                    Icon={FcDeleteColumn}
                    hoverOption={aes?.[0]?.id}
                    wrapperClass="rounded h-8 w-8"
                  />
                  <CustomTooltip
                    helpers={{
                      id: "table-col-del",
                      content: <p className="text-xs">Delete Column</p>,
                      place: "top",
                    }}
                  />
                </button>
                <button
                  data-tooltip-id="table-row-add"
                  data-hover-option-id={aes?.[0]?.id}
                  id={HOVER_OPTS_BTN_IDS.SETTINGS}
                  className="min-h-8 max-h-8 hover:bg-white/10 rounded"
                  onClick={() => {
                    tableHelpers.addRow({ id: activeSchema.id });
                  }}
                >
                  {/* <FcAddRow
              data-hover-option-id={aes?.[0]?.id}
              size={25}
              id={HOVER_OPTS_BTN_IDS.SETTINGS}
            /> */}
                  <IconPathHackFix
                    iconProps={{ size: 23, id: HOVER_OPTS_BTN_IDS.SETTINGS }}
                    Icon={FcAddRow}
                    hoverOption={aes?.[0]?.id}
                    wrapperClass="rounded h-8 w-8"
                  />
                  <CustomTooltip
                    helpers={{
                      id: "table-row-add",
                      content: <p className="text-xs">Add Row</p>,
                      place: "top",
                    }}
                  />
                </button>
                <button
                  data-tooltip-id="table-row-del"
                  data-hover-option-id={aes?.[0]?.id}
                  id={HOVER_OPTS_BTN_IDS.SETTINGS}
                  className="min-h-8 max-h-8 hover:bg-white/10 rounded"
                  onClick={() => {
                    tableHelpers.delRow({ id: activeSchema.id });
                  }}
                >
                  {/* <FcDeleteRow
              data-hover-option-id={aes?.[0]?.id}
              size={25}
              id={HOVER_OPTS_BTN_IDS.SETTINGS}
            /> */}
                  <IconPathHackFix
                    iconProps={{ size: 23, id: HOVER_OPTS_BTN_IDS.SETTINGS }}
                    Icon={FcDeleteRow}
                    hoverOption={aes?.[0]?.id}
                    wrapperClass="rounded h-8 w-8"
                  />
                  <CustomTooltip
                    helpers={{
                      id: "table-row-del",
                      content: <p className="text-xs">Delete Row</p>,
                      place: "top",
                    }}
                  />
                </button>
              </div>
            )}

          {activeSchema?.role?.title && (
            <div
              data-hover-option-id={aes?.[0]?.id}
              onClick={() => {
                if (
                  designerState.instanceFrom == "PDF-TEMPLATE" ||
                  designerState.instanceFrom == "PDF"
                ) {
                  setRoles((prev) => ({ ...prev, show: !prev.show }));
                }
              }}
              data-tooltip-id="schema-role"
              className="relative flex min-h-8 max-h-8 min-w-[100px] w-25 items-center gap-2 whitespace-nowrap font-semibold hover:bg-white/10 px-2 py-1 rounded"
            >
              <div
                data-hover-option-id={aes?.[0]?.id}
                className="h-3 w-3 rounded-full"
                style={{ background: activeSchema?.role?.colour }}
              ></div>
              <div data-hover-option-id={aes?.[0]?.id} className="">
                {activeSchema?.role?.title}
              </div>
              <CustomTooltip
                helpers={{
                  id: "schema-role",
                  content: (
                    <p className="text-xs">Who needs to fill out this field</p>
                  ),
                  place: "top",
                }}
              />

              {/* {roles?.show && ( */}
              <div
                data-hover-option-id={aes?.[0]?.id}
                className={`absolute left-0 top-9 rounded overflow-y-auto w-full bg-[#545559] z-[3030] duration-300 transition-all ${
                  roles?.show ? "h-auto max-h-[112px]" : "max-h-[0px]"
                }`}
                id="mini-pdf-custom-scroll"
              >
                {roles?.roles?.map((role: any, idx) => {
                  return (
                    <div
                      key={idx}
                      data-hover-option-id={aes?.[0]?.id}
                      className="px-1.5 py-1 flex gap-2 items-center hover:bg-white/10"
                      onClick={() => {
                        globalHelpers.changeRole({
                          id: activeSchema?.id,
                          color: role?.colour,
                          title: role?.title || role?.name,
                        });
                        setSchemaUpdated((prev) => !prev);
                      }}
                    >
                      <div
                        data-hover-option-id={aes?.[0]?.id}
                        className="min-w-[12px] w-3 h-3 max-h-3 max-w-3 rounded-full"
                        style={{ background: role?.colour }}
                      ></div>
                      <div
                        data-hover-option-id={aes?.[0]?.id}
                        className="truncate"
                      >
                        {role?.title || role?.name}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* )} */}
            </div>
          )}

          {activeSchema?.subType == "variable" && (
            <div
              data-tooltip-id="variable-name"
              className="min-h-8 max-h-8 whitespace-nowrap text-ellipsis hover:bg-white/10 px-2 py-1 rounded"
            >
              {removeSquareBrackets(activeSchema?.variableName || "")}{" "}
              <CustomTooltip
                helpers={{
                  id: "variable-name",
                  content: <p className="text-xs">Variable Name</p>,
                  place: "top",
                }}
              />
            </div>
          )}

          {instanceFrom !== "FORM" && (
            <button
              data-tooltip-id="properties"
              data-hover-option-id={aes?.[0]?.id}
              id={HOVER_OPTS_BTN_IDS.SETTINGS}
              className="min-h-8 max-h-8 hover:bg-white/10 rounded"
              onClick={() => {
                // pdfTemplate.setSidebarSelectedOption("DETAIL_VIEW");
                // pdfTemplate.setOpen(true);
                setDesignerState((prev) => ({
                  ...prev,
                  combinedSidebar: {
                    ...prev.combinedSidebar,
                    selectedView: "DETAIL_VIEW",
                    open: true,
                  },
                }));
              }}
            >
              {/* <LuPaintBucket
            data-hover-option-id={aes?.[0]?.id}
            size={20}
            id={HOVER_OPTS_BTN_IDS.SETTINGS}
          /> */}
              <IconPathHackFix
                iconProps={{ size: 20, id: HOVER_OPTS_BTN_IDS.SETTINGS }}
                Icon={LuPaintBucket}
                hoverOption={aes?.[0]?.id}
                wrapperClass="rounded h-8 w-8"
              />
            </button>
          )}
          <CustomTooltip
            helpers={{
              id: "properties",
              content: <p className="text-xs">Properties</p>,
              place: "top",
            }}
          />

          <button
            data-tooltip-id="delete-schema"
            data-hover-option-id={aes?.[0]?.id}
            id={HOVER_OPTS_BTN_IDS.DELETE}
            className="min-h-8 max-h-8 hover:bg-white/10 px-1 py-1 rounded"
          >
            <MdDelete size={20} id={HOVER_OPTS_BTN_IDS.DELETE} />
          </button>
          <CustomTooltip
            helpers={{
              id: "delete-schema",
              content: <p className="text-xs">Delete</p>,
              place: "top",
            }}
          />
        </>
      )}
    </div>
  );
};

interface GuidesInterface {
  getGuides(): number[];
  scroll(pos: number): void;
  scrollGuides(pos: number): void;
  loadGuides(guides: number[]): void;
  resize(): void;
}

const Main = (props: Props, ref: Ref<HTMLDivElement>) => {
  const {
    pageCursor,
    scale,
    backgrounds,
    pageSizes,
    size,
    activeElements,
    schemasList,
    hoveringSchemaId,
    addSchema,
    radioGroupCovers,
    // pdfTemplate,
    instanceFrom,
    designerState,
    setDesignerState,
    globalHelpers,
  } = props;
  const {
    onEdit,
    changeSchemas,
    removeSchemas,
    onChangeHoveringSchemaId,
    paperRefs,
  } = props;

  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const verticalGuides = useRef<GuidesInterface[]>([]);
  const horizontalGuides = useRef<GuidesInterface[]>([]);
  const moveable = useRef<any>(null);
  const font = useContext(FontContext);

  // console.log({ verticalGuides, horizontalGuides });

  const [isPressShiftKey, setIsPressShiftKey] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isDraging, setIsDraging] = useState(false);

  const prevSchemas = usePrevious(schemasList[pageCursor]);

  const onKeydown = (e: KeyboardEvent) => {
    if (e.shiftKey) setIsPressShiftKey(true);
  };
  const onKeyup = (e: KeyboardEvent) => {
    if (e.key === "Shift") setIsPressShiftKey(false);
    if (e.key === "Escape" || e.key === "Esc") setEditing(false);
  };

  const initEvents = useCallback(() => {
    window.addEventListener("keydown", onKeydown);
    window.addEventListener("keyup", onKeyup);
  }, []);

  const destroyEvents = useCallback(() => {
    window.removeEventListener("keydown", onKeydown);
    window.removeEventListener("keyup", onKeyup);
  }, []);

  useEffect(() => {
    initEvents();

    return destroyEvents;
  }, [initEvents, destroyEvents]);

  useEffect(() => {
    moveable.current?.updateRect();
    if (prevSchemas === null) {
      return;
    }

    const prevSchemaKeys = JSON.stringify(prevSchemas?.[pageCursor] || {});
    const schemaKeys = JSON.stringify(schemasList?.[pageCursor] || {});

    if (prevSchemaKeys === schemaKeys) {
      moveable.current?.updateRect();
    }
  }, [pageCursor, schemasList, prevSchemas]);

  function checkIsBlankPgStSchema(id: string): boolean {
    let isBlankPgStSchema = false;
    let currCustomPage =
      designerState?.settings?.customSchema?.[designerState?.pageCursor];
    if (!currCustomPage) {
      return false;
    }

    if (currCustomPage.pageType === "BLANK") {
      let activeSchema: SchemaForUI | undefined = designerState?.schemasList?.[
        designerState?.pageCursor
      ].find((elm) => elm.id == id);
      if (activeSchema && validBlankPageSchema(activeSchema).isValid) {
        isBlankPgStSchema = true;
      }
    }
    return isBlankPgStSchema;
  }

  const onDrag = ({ target, left, top, height }: OnDrag) => {
    let isBlankPgStSchema = checkIsBlankPgStSchema(target.id);
    setIsDraging(true);
    if (!isBlankPgStSchema) {
      // if it was blank page valid st schema
      // then we dont move
      target.style.left = `${left < 0 ? 0 : left}px`;
      target.style.top = `${top < 0 ? 0 : top}px`;
    } else {
      let topCalc = POSITION.A4.y * ZOOM;
      let bottomCalc =
        (pageSizes[designerState?.pageCursor].height - POSITION.A4.y) * ZOOM -
        height;
      let handledTop = top;

      if (top < topCalc) {
        handledTop = topCalc;
      }
      if (top > bottomCalc) {
        handledTop = bottomCalc;
      }

      target.style.top = `${handledTop}px`;
    }
  };

  const onDragEnd = ({ target }: { target: HTMLElement | SVGElement }) => {
    const { top, left } = target.style;
    changeSchemas([
      { key: "position.y", value: fmt(top), schemaId: target.id },
      { key: "position.x", value: fmt(left), schemaId: target.id },
    ]);
    setIsDraging(false);
  };

  const onDragEnds = ({
    targets,
  }: {
    targets: (HTMLElement | SVGElement)[];
  }) => {
    const arg = targets.map(({ style: { top, left }, id }) => [
      { key: "position.y", value: fmt(top), schemaId: id },
      { key: "position.x", value: fmt(left), schemaId: id },
    ]);
    changeSchemas(flatten(arg));
  };

  const onResizeEnd = async ({
    target,
  }: {
    target: HTMLElement | SVGElement;
  }) => {
    // -----------------------------------------------
    let otherChangeSchemas = onResizeMoveOtherSchema({
      customSchema: designerState?.settings?.customSchema || [],
      pageCursor: designerState?.pageCursor,
      pageSizes: pageSizes,
      schemasList: designerState?.schemasList,
      target,
      preventOverflow: true,
    });
    // -----------------------------------------------
    const { id, style } = target;
    const { width, height, top, left } = style;
    changeSchemas([
      { key: "width", value: fmt(width), schemaId: id },
      { key: "height", value: fmt(height), schemaId: id },
      { key: "position.y", value: fmt(top), schemaId: id },
      { key: "position.x", value: fmt(left), schemaId: id },
      ...otherChangeSchemas.changeSchemas,
    ]);

    const targetSchema = schemasList[pageCursor].find(
      (schema) => schema.id === id
    );

    if (!targetSchema) return;

    targetSchema.width = fmt(width);
    targetSchema.height = fmt(height);
    targetSchema.position.y = fmt(top);
    targetSchema.position.x = fmt(left);
  };

  const onResizeEnds = ({
    targets,
  }: {
    targets: (HTMLElement | SVGElement)[];
  }) => {
    const arg = targets.map(({ style: { width, height, top, left }, id }) => [
      { key: "width", value: fmt(width), schemaId: id },
      { key: "height", value: fmt(height), schemaId: id },
      { key: "position.y", value: fmt(top), schemaId: id },
      { key: "position.x", value: fmt(left), schemaId: id },
    ]);
    changeSchemas(flatten(arg));
  };

  const onResize = ({ target, width, height, direction }: OnResize) => {
    // ---------------------------------------------------------------
    const schemaId = target.id.split("_")?.[0];

    // -------------------------------------------------------
    let isBlankPgStSchema = checkIsBlankPgStSchema(target.id);
    // -------------------------------------------------------
    let fontSize: any = null;
    let minHeight: number = 0;
    schemasList.map((page: SchemaForUI[]) => {
      page.map((schema: SchemaForUI) => {
        if (schema.id.split("_")?.[0] == schemaId) {
          // @ts-ignore
          fontSize = schema?.fontSize;
          const calcHeight = getHeightFromFontSize(fontSize, true);
          if (fontSize && !isNaN(calcHeight)) {
            minHeight = calcHeight;
          }

          if (schema.type !== "text") {
            minHeight = 0;
          }
        }
      });
    });
    const newHeight = height - minHeight < 0 ? minHeight : height;
    // ---------------------------------------------------------------
    if (!target) return;
    const s = target.style;
    const width_ = isBlankPgStSchema ? fmt4Num(s.width) : width;
    const newLeft = fmt4Num(s.left) + (fmt4Num(s.width) - width_);
    const newTop = fmt4Num(s.top) + (fmt4Num(s.height) - height);
    const obj: { top?: string; left?: string; width: string; height: string } =
      {
        width: `${width_}px`,
        height: `${newHeight}px`,
      };
    const d = direction.toString();
    if (isTopLeftResize(d)) {
      obj.top = `${newTop}px`;
      obj.left = `${newLeft}px`;
    } else if (d === "1,-1") {
      obj.top = `${newTop}px`;
    } else if (d === "-1,1") {
      obj.left = `${newLeft}px`;
    }
    Object.assign(s, obj);
  };

  const getGuideLines = (guides: GuidesInterface[], index: number) =>
    guides[index] && guides[index].getGuides().map((g) => g * ZOOM);

  const onClickMoveable = (e: OnClick) => {
    e.inputEvent.stopPropagation();
    console.log("clicked moveable");

    setEditing(true);
    const ic = inputRef.current;
    if (!ic) return;
    ic.focus();
    if (ic.type !== "file") {
      ic.setSelectionRange(ic.value.length, ic.value.length);
    }
  };

  useEffect(() => {
    // console.log("activeElements", activeElements);
    // console.log("schemasList", schemasList);

    if (activeElements.length === 1) {
      let activeElementId = activeElements[0].id;
      // console.log("activeElementId", activeElementId);
      let activeElement: any;

      schemasList.forEach((page: any) => {
        page.forEach((item: any) => {
          if (item.id === activeElementId) {
            activeElement = item;
          }
        });
      });
      // console.log("activeElement", activeElement);
      const signatureColour = activeElement?.isStatic
        ? STATIC_SCHEMA.color
        : activeElement?.signatureColour;

      document.documentElement.style.setProperty(
        "--react-movable-bg",
        signatureColour ||
          whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
          COLORS.primary
      );
    } else {
      document.documentElement.style.setProperty(
        "--react-movable-bg",
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
          COLORS.primary
      );
    }
  }, [activeElements, schemasList]);

  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        // console.log("out of moveable");
        setEditing(false);
      }}
      style={{ overflow: "overlay" }}
      id="mini-pdf-custom-scroll"
      className="max-w-[100%]"
    >
      <Selecto
        container={paperRefs.current[pageCursor]}
        continueSelect={isPressShiftKey}
        /*
        Documentation:
        1. onEdit()
        This function will set active schemas as the array of HTMLElements passed to it
        If passed empty array [] it delselects the currently selected elements

        2. onEditEnd()
        This function will set active schemas as empty array 
        same as above function explictly used to deselect all elements
        */
        onDragStart={(e) => {
          const { inputEvent } = e;
          const isMoveableElement = moveable.current?.isMoveableElement(
            inputEvent.target
          );
          if (
            (inputEvent.type === "touchstart" && e.isTrusted) ||
            isMoveableElement
          ) {
            e.stop();
          }

          if (paperRefs.current[pageCursor] === inputEvent.target) {
            onEdit([]);
          }

          if (inputEvent.target.id === HOVER_OPTS_BTN_IDS.DELETE) {
            removeSchemas(activeElements.map((ae) => ae.id));
          }
          if (inputEvent.target?.parentNode?.id === HOVER_OPTS_BTN_IDS.DELETE) {
            removeSchemas(activeElements.map((ae) => ae.id));
          }
        }}
        onSelect={({ added, removed, selected, inputEvent }) => {
          let conditions = {
            isClick: inputEvent.type === "mousedown",
            // isFontSize: inputEvent.target.id === HOVER_OPTS_BTN_IDS.FONT_SIZE,
            isHoverOptionClicked: inputEvent.target?.dataset?.hoverOptionId,
          };

          // const isClick = inputEvent.type === "mousedown";

          /*
          This means when we click on the schema
          we want to have that schema be active
          */
          let newActiveElements: HTMLElement[] = conditions.isClick
            ? (selected as HTMLElement[])
            : [];

          /*
          This means when we drag the added length become more than 1
          and !click signifies that it is a drag movement and not to happen on click movement
          */
          if (!conditions.isClick && added.length > 0) {
            newActiveElements = activeElements.concat(added as HTMLElement[]);
          }
          /*
          same logic as above here 
          while dragging one can select or deselect items
          there fore removed len
          */
          if (!conditions.isClick && removed.length > 0) {
            newActiveElements = activeElements.filter(
              (ae) => !removed.includes(ae)
            );
          }

          /*
          in hover options component 
          if we dont want to trigger a deselect on the hover option 
          we add data attribute of hover-option-id
          which allows us to click on that component and still have it active
          */
          if (
            removed.length == 1 &&
            conditions.isClick &&
            conditions.isHoverOptionClicked
          ) {
            newActiveElements = activeElements.filter(
              (ae) => ae.id == inputEvent.target?.dataset?.hoverOptionId
            );
          }

          onEdit(newActiveElements);
        }}
      />
      <Paper
        isEditor={true}
        designerState={designerState}
        globalHelpers={globalHelpers}
        setDesignerState={setDesignerState}
        pageCursor={pageCursor}
        addSchema={addSchema}
        paperRefs={paperRefs}
        scale={scale}
        size={size}
        schemasList={schemasList}
        pageSizes={pageSizes}
        backgrounds={backgrounds}
        renderPaper={({ index, paperSize }) => (
          <>
            {/* {!editing && activeElements.length > 0 && (
              <DeleteButton activeElements={activeElements} />
            )} */}
            {/* <Guides
              paperSize={paperSize}
              horizontalRef={(e) => {
                if (e) {
                  horizontalGuides.current[index] = e;
                }
              }}
              verticalRef={(e) => {
                if (e) {
                  verticalGuides.current[index] = e;
                }
              }}
            /> */}
            {/* {!isDraging && activeElements.length == 1 && ( */}
            <HoverOptions
              isDragging={isDraging}
              activeElements={activeElements}
              schemasList={schemasList}
              pageCursor={pageCursor}
              changeSchemas={changeSchemas}
              pageSize={pageSizes[pageCursor]}
              // pdfTemplate={pdfTemplate}
              instanceFrom={instanceFrom}
              designerState={designerState}
              setDesignerState={setDesignerState}
              globalHelpers={globalHelpers}
            />
            {/* )} */}
            {pageCursor !== index ? (
              <Mask width={paperSize.width} height={paperSize.height} />
            ) : (
              // <Mask width={paperSize.width} height={paperSize.height} />
              !editing && (
                <>
                  {radioGroupCovers?.map((coverStyle: any, index) => (
                    <>
                      <div
                        className="cover-div"
                        key={index}
                        style={{ ...coverStyle.style }}
                        onClick={() => coverStyle.onClick()}
                      ></div>
                      {designerState?.mode === "EDITING" && (
                        <AiFillPlusCircle
                          onClick={() => coverStyle?.plusCircle?.onClick()}
                          color={coverStyle?.plusCircle?.color}
                          style={{ ...coverStyle?.plusCircle?.style }}
                          size={25}
                        />
                      )}
                    </>
                  ))}
                  <Moveable
                    ref={moveable}
                    target={activeElements}
                    bounds={{
                      left: 0,
                      top: 0,
                      bottom: paperSize.height,
                      right: paperSize.width,
                    }}
                    horizontalGuidelines={getGuideLines(
                      horizontalGuides.current,
                      index
                    )}
                    verticalGuidelines={getGuideLines(
                      verticalGuides.current,
                      index
                    )}
                    keepRatio={isPressShiftKey}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    onDragGroupEnd={onDragEnds}
                    onResize={
                      designerState?.mode !== "EDITING"
                        ? () => {
                            console.log("EDITING mode inactive");
                          }
                        : onResize
                    }
                    onResizeEnd={onResizeEnd}
                    onResizeGroupEnd={onResizeEnds}
                    onClick={onClickMoveable}
                    draggable={
                      designerState?.editorHelpers.disbaleMove ||
                      designerState?.mode !== "EDITING"
                        ? false
                        : true
                      // true
                    }
                  />
                </>
              )
            )}
          </>
        )}
        renderSchema={({ schema }) => {
          // console.log(schema.role, schema.signatureColour, schema.backgroundColor)
          const isHovering = hoveringSchemaId === schema.id;
          let outlineColor = schema?.isStatic
            ? STATIC_SCHEMA.color
            : schema.signatureColour;
          // console.log("this outline is", outlineColor)
          const outlineStyle = isHovering
            ? {
                outline: `1px dotted ${
                  schema?.isQuestionDeleted ? "red" : outlineColor
                }`,
              }
            : {
                outline: `1px double ${
                  schema?.isQuestionDeleted ? "red" : outlineColor
                }`,
              };
          const data =
            (designerState?.inputs?.[0] &&
              designerState?.inputs?.[0]?.[schema.key]) ||
            "";

          // const assignDataInPreview =
          //   schema?.subType === "checkbox" || schema?.subType === "radiogroup"
          //     ? !!data
          //     : true;

          // console.log({ data, st: schema?.subType, schema });

          function parseSchemaForData() {
            if (designerState?.mode === "EDITING") {
              return schema;
            }

            if (designerState?.mode === "PREVIEW") {
              if (
                schema?.subType === "checkbox" ||
                schema?.subType === "radiogroup"
              ) {
                // console.log(
                //   "checkbox radiogroup",
                //   data,
                //   !!data,
                //   Object.assign(schema, { data })
                // );
                if (!!data) {
                  return Object.assign(schema, { data });
                } else {
                  return schema;
                }
              } else {
                return Object.assign(schema, { data });
              }
            }
            return schema;
          }
          const handleRadioClick = (schema: SchemaForUI) => {
            let schemaListForCurrPage = schemasList[pageCursor];
            // this temp list is not needed remove later
            let changedSchema: ChangeSchemasArg[] = [];
            schemaListForCurrPage.map((schemaPrev, schIdx) => {
              if (schemaPrev?.radiogroupId == schema?.radiogroupId) {
                if (schemaPrev?.id === schema.id) {
                  changedSchema.push({
                    key: "data",
                    value: STANDARD_DATA.radiogroup.checked_DBKEYV1.key,
                    schemaId: schema.id,
                  });
                } else {
                  changedSchema.push({
                    key: "data",
                    value: STANDARD_DATA.radiogroup.unchecked_DBKEYV1.key,
                    schemaId: schemaPrev.id,
                  });
                }
              }
            });
            changeSchemas(changedSchema);
          };
          return (
            <SchemaUI
              key={schema.id}
              // schema={schema}
              schema={parseSchemaForData()}
              pageSizes={pageSizes}
              pageCursor={pageCursor}
              activeElements={activeElements}
              isActive={activeElements.map((ae) => ae.id).includes(schema.id)}
              onChangeHoveringSchemaId={onChangeHoveringSchemaId}
              editable={
                editing &&
                activeElements.map((ae) => ae.id).includes(schema.id) &&
                !(
                  window.location.pathname.includes("create/form/pdf") ||
                  window.location.pathname.includes("create/pdf-mapping") ||
                  window.location.pathname.includes("create/pdf-signature") ||
                  window.location.pathname.includes("create/pdf-template")
                )
              }
              onChange={async (value, extra) => {
                let args: ChangeSchemasArg[] = [
                  { key: "data", value, schemaId: schema.id },
                ];
                if (extra) {
                  args = [...args, ...extra];
                }
                changeSchemas(args);
              }}
              onCheck={async (value) => {
                changeSchemas([{ key: "checked", value, schemaId: schema.id }]);
              }}
              outlineStyle={outlineStyle}
              ref={
                activeElements.map((ae) => ae.id).includes(schema.id) &&
                activeElements?.length == 1
                  ? inputRef
                  : null
              }
              outline={" "}
              addSchema={addSchema}
              handleRadioClick={handleRadioClick}
              instaceFrom={instanceFrom}
              designerState={designerState}
              setDesignerState={setDesignerState}
              changeSchemas={changeSchemas}
              placeholder={schema.placeholder || schema.showKey || ""}
            />
          );
        }}
      />
    </div>
  );
};
export default forwardRef<HTMLDivElement, Props>(Main);
