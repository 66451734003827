// @ts-nocheck
import React from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import { BoloButton } from "../Common/Button/BoloButton";

const DeleteWorkSpace = ({ setShow, showData, setWorkSpaces }) => {
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  const handleDeleteWorkSpace = async () => {
    try {
      const { data } = await axios.post("/workspace/delete", {
        email: owner?.email,
        workSpaceId: showData?.workSpace?.workSpaceId,
      });
      if (data?.success) {
        console.log({ data });
        setWorkSpaces(data?.owner?.workSpaces);
        localStorage.setItem("owner", JSON.stringify(data?.owner));
        window.dispatchEvent(new Event("storage"));
        toast.success("WorkSpace Delete");
        setShow && setShow(false);
      } else {
        toast.error(data?.message || "Could not Delete WorkSpace");
      }
    } catch (error) {
      console.log("Error in deleteing workspace");
      console.log(error);
      toast.error("Could not Delete WorkSpace");
    }
  };

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />
          <div className="text-base md:text-lg mb-4 font-semibold">
            Delete WorkSpace
          </div>
          <span className="text-xs md:text-sm">
            Deleting the WorkSpace{" "}
            <span className="text-red-600 font-semibold">
              {showData?.workSpace?.name}
            </span>{" "}
            this action cannot be reverted.
          </span>
          <span className="text-xs md:text-sm">
            Any Forms, PDFs and Templates, Folders and any associated data will
            not be accessible later on.
          </span>
          <div className="text-xs bg-gray-100 rounded p-2 my-2">
            <span className="font-semibold">Disclaimer:</span> This WorkSpace
            will be removed from your account. Please be advised that commencing
            the next billing cycle, associated charges will be reduced for the
            unused WorkSpace.
          </div>
          <div className="flex gap-2 items-center justify-end mt-8">
            <BoloButton
              size={"sm"}
              // className="text-xs md:text-sm text-primary border px-4 py-2 rounded-md"
              variant="secondary"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </BoloButton>
            <BoloButton
              // className="text-xs md:text-sm text-white bg-red-600 px-4 py-2 rounded-md"
              size={"sm"}
              variant="danger-border"
              onClick={() => {
                handleDeleteWorkSpace();
              }}
            >
              Delete
            </BoloButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteWorkSpace;
