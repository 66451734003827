import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  MdPersonRemove,
  MdPersonAdd,
  MdPersonAddAlt1,
  MdClose,
  MdCheck,
  MdDelete,
  MdOutlineKeyboardArrowDown,
  MdOutlineDelete,
} from "react-icons/md";
import {
  AiOutlineCaretDown,
  AiFillDelete,
  AiTwotoneDelete,
} from "react-icons/ai";
import { GiConfirmed } from "react-icons/gi";
import { EmailInputFormProps } from "../../types/ComponentPropsTypes";
import { validateEmail } from "../../utils/validateEmail";
import { TiDelete, TiUserDelete } from "react-icons/ti";
import { getInitials, getRandomColorV1 } from "../../utils/uiUtils";
import SigningOrderDiagram from "../SigningOrderDiagram";
import EmailSingleSelect from "./EmailSingleSelect";
import { useLocation } from "react-router-dom";
import CustomTooltip from "../EditForm/CustomTooltip";
import { IoKey } from "react-icons/io5";
import AccessCodeDetailsComponent from "./AccessCodeDetailsComponent";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
import { getOwnerPlanDetails } from "../../utils";
import MobileNoSingleSelect from "./MobileNoSingleSelect";
import { COLORS } from "../../constants/common";
import { ALL_FEATURES } from "../../types/tiers";
import { BoloButton } from "../Common/Button/BoloButton";

const EmailInputForm: React.FC<EmailInputFormProps> = ({
  onSave,
  resetAll,
  isOnlySignature,
  isSigningOrderData,
  setIsSigningOrderData,
  sendViaSMS,
  setSendViaSMS,
}) => {
  const featuresEnabled = useIsFeatureEnabled();

  const [showInputForm, setShowInputForm] = useState<boolean>(true);
  const [emails, setEmails] = useState<string[]>([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [recipient, setRecipient] = useState<
    {
      email: string;
      name: string;
      message?: string;
      colour?: string;
      signingOrderNo: number | string;
      accessCode?: string;
      phone?: string;
    }[]
  >([
    {
      email: "",
      name: "",
      message: "",
      colour: "",
      signingOrderNo: 1,
      accessCode: "",
    },
  ]);
  const [recipientsFields, setRecipientsFields] = useState<
    {
      email: string;
      name: string;
      message?: string;
      colour?: string;
    }[]
  >([]);
  const [sendToEmail, setSendToEmail] = useState("");
  const [sendToName, setSendToName] = useState("");
  const [selectedColor, setSelectedColor] = useState(COLORS.primary);

  const [sendToNote, setSendToNote] = useState("");
  const [currentEmail, setCurrentEmail] = useState<string>("");
  const [isSigningOrderDiagram, setIsSigningOrderDiagram] =
    useState<Boolean>(false);

  const [showAccessCode, setShowAccessCode] = useState<{
    show: boolean;
    email: string;
    roleTitle: string;
  }>({
    show: false,
    email: "",
    roleTitle: "",
  });

  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });

  const signingOrderDiagramRef = useRef(null);
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");

  useEffect(() => {
    console.log("render count");
    if (resetAll) {
      setEmails([]);
      setRecipient([]);
      setShowInputForm(false);
    } else {
      return;
    }
  }, [resetAll]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmail(e.target.value);
  };

  const handleAddEmail = () => {
    if (currentEmail !== "") {
      setEmails([...emails, currentEmail]);
      setCurrentEmail("");
    }
  };

  const handleRemoveEmail = (index: number) => {
    setEmails((prevEmails) =>
      prevEmails.filter((_, emailIndex) => emailIndex !== index)
    );
    let savingRecipients = recipient.filter(
      (_, emailIndex) => emailIndex !== index
    );
    setRecipient((prevRecipients) => {
      return prevRecipients.filter((_, emailIndex) => emailIndex !== index);
    });
    onSave(savingRecipients);
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color; // Add opacity (70%)
  };

  useEffect(() => {
    let modifiedRecipient = recipient
      .map((recipient) => {
        if (
          (sendViaSMS && recipient.name && recipient?.phone) ||
          (!sendViaSMS && recipient.name && recipient?.email)
        ) {
          return {
            ...recipient,
            colour: getRandomColorV1(),
            message: "",
          };
        }
        return null; // Exclude recipients with empty name or email
      })
      .filter((recipient) => recipient !== null);

    //@ts-ignore
    onSave(modifiedRecipient);
  }, [recipient]);

  const updateAccessCodeState = (email: string, name: string) => {
    if (!email && !name) {
      setShowAccessCode({
        show: false,
        email: "",
        roleTitle: "",
      });
    } else if (email === showAccessCode.email) {
      setShowAccessCode({
        show: !showAccessCode.show,
        email: email,
        roleTitle: "",
      });
    } else {
      setShowAccessCode({
        show: true,
        email: email,
        roleTitle: "",
      });
    }
  };

  const updateEmailValue = (newValue: any, index: number, name: any) => {
    if (recipient[index]) {
      recipient[index].email = newValue;
      if (name?.trim()) {
        recipient[index].name = name?.trim();
      }
      setRecipient([...recipient]);
    }
  };

  const updateMobileNoValue = (
    newValue: any,
    index: number,
    name: any,
    email: string
  ) => {
    const newReceiversList = [...recipient];
    if (newReceiversList.length > 0) {
      newReceiversList[index].phone = newValue;
      if (name?.trim()?.length > 0) {
        newReceiversList[index].name = name.trim();
      }
      if (email?.trim()?.length > 0) {
        newReceiversList[index].email = email.trim();
      }
      setRecipient(newReceiversList);
    }
  };

  useEffect(() => {
    const email = searchParams.get("email")!;
    const name = searchParams.get("name") || "";
    if (name?.trim()) {
      recipient[0].name = name?.trim();
    }
    if (email) {
      recipient[0].email = email;
    }
    setRecipient([...recipient]);
  }, []);

  return (
    <div className="mt-4 py-4 m w-[100%] md:w-[65%] max-w-[800px] mx-auto">
      {isSigningOrderDiagram && (
        <SigningOrderDiagram
          recipientList={recipient}
          setIsSigningOrderDiagram={setIsSigningOrderDiagram}
          isSigningOrderDiagram={isSigningOrderDiagram}
        />
      )}
      {showInputForm && (
        <div>
          <div className="inputs flex flex-col">
            <h2 className="font-semibold text-sm md:text-base">Add Signers</h2>
            <p className="text-xs md:text-sm text-gray-500 mt-2">
              Add the signers to your document. You can add multiple signers.
            </p>
            <div className="flex gap-2">
              <div className="flex gap-2 text-xs text-gray-500 mt-2">
                <input
                  type="checkbox"
                  checked={isSigningOrderData}
                  onChange={() => {
                    setIsSigningOrderData(!isSigningOrderData);
                  }}
                />
                Set Signing Order Data
                {isSigningOrderData && (
                  <span
                    className="text-blue-400 cursor-pointer"
                    onClick={() => {
                      setIsSigningOrderDiagram(true);
                    }}
                  >
                    View Order
                  </span>
                )}
              </div>
              <div className="flex gap-2 text-xs text-gray-500 mt-2 border-l border-gray-500">
                <input
                  type="checkbox"
                  className="ml-2 cursor-pointer"
                  checked={sendViaSMS}
                  onChange={() => {
                    if (!featuresEnabled?.SMS_AUTHENTICATION) {
                      setUpgradeModalType("SMS_AUTHENTICATION");
                      setShowUpgradeModal(true);
                      return;
                    }
                    setSendViaSMS(!sendViaSMS);
                  }}
                />
                Send via SMS
              </div>
            </div>

            <div className="mt-4">
              {recipient.map((current, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col items-center text-xs"
                  >
                    <div className="flex gap-2 w-full py-3">
                      {isSigningOrderData && (
                        <input
                          type="number"
                          min="1"
                          max={recipient?.length}
                          className="hide-spinner rounded text-xs md:text-sm outline-none border w-8 md:w-10 text-center"
                          value={recipient[index].signingOrderNo}
                          onChange={(e) => {
                            recipient[index].signingOrderNo = isNaN(
                              parseInt(e.target.value)
                            )
                              ? ""
                              : parseInt(e.target.value);
                            setRecipient([...recipient]);
                            // }
                          }}
                        />
                      )}
                      <div className="name flex flex-col w-[45%] ">
                        <div className="items-center gap-4">
                          <div className="flex flex-row justify-center items-center gap-2 border border-gray-200 rounded-[3px] p-2 bg-white">
                            <input
                              type="text"
                              id="name"
                              placeholder="Enter name"
                              className="w-full text-xs md:text-sm outline-none"
                              value={current.name || sendToName}
                              onChange={(e) => {
                                if (recipient[index]) {
                                  recipient[index].name = e.target.value;
                                  setRecipient([...recipient]);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="email flex flex-col w-full">
                        <div className="flex flex-row justify-center items-center gap-2  bg-white">
                          {/* Select email from the contacts list and can add new email if required */}
                          {!sendViaSMS ? (
                            <EmailSingleSelect
                              selectedValue={recipient[index]?.email}
                              index={index}
                              updateEmailValue={updateEmailValue}
                            />
                          ) : (
                            <MobileNoSingleSelect
                              selectedValue={recipient[index]?.phone}
                              updateMobileValue={updateMobileNoValue}
                              index={index}
                            />
                          )}
                        </div>
                      </div>
                      {!getOwnerPlanDetails()?.isLifeTime && (
                        <BoloButton
                          size={"icon-sm"}
                          variant="gray-shadow"
                          className="min-h-[38px] min-w-[38px] flex items-center justify-center"
                          // className="cursor-pointer border border-gray-300 rounded-md p-2"
                          data-tooltip-id={`${recipient[index]?.email}AccessCode`}
                          data-tooltip-html="Access Code:<br />Enter a code that only you and this recipient know."
                          onClick={() => {
                            if (!featuresEnabled?.ACCESS_CODE) {
                              setUpgradeModalType("ACCESS_CODE");
                              setShowUpgradeModal(true);
                              return;
                            }
                            updateAccessCodeState(
                              recipient[index]?.email,
                              recipient[index]?.name
                            );
                          }}
                        >
                          <CustomTooltip
                            helpers={{
                              id: `${recipient[index]?.email}AccessCode`,
                              content: `Access Code: Enter a code that only you and this recipient know. This code will be required to access the document.`,
                              place: "top",
                            }}
                          />
                          <IoKey
                            size={20}
                            className={`${
                              recipient[index]?.accessCode
                                ? "text-gray-800"
                                : "text-gray-500"
                            }`}
                          />
                        </BoloButton>
                      )}
                      {recipient[index] && (
                        <BoloButton
                          variant={"danger-border"}
                          size="icon-sm"
                          // className="flex items-center justify-center gap-2 sm:w-[210px]"
                          className={`${
                            recipient?.length === 1
                              ? "opacity-60 hover:opacity-60 !cursor-not-allowed"
                              : ""
                          }`}
                          onClick={() => {
                            if (recipient?.length > 1) {
                              handleRemoveEmail(index);
                            }
                          }}
                        >
                          <MdOutlineDelete className="text-lg md:text-2xl" />
                        </BoloButton>
                      )}
                    </div>
                    {showAccessCode.show && showAccessCode.email && (
                      <AccessCodeDetailsComponent
                        showAccessCode={showAccessCode}
                        setShowAccessCode={setShowAccessCode}
                        receiversList={recipient}
                        index={index}
                        updateAccessCodeState={updateAccessCodeState}
                        setReceiversList={setRecipient}
                        isSinglePdf={true}
                      />
                    )}
                    {recipient[index].accessCode && (
                      <div
                        className={` ${
                          showAccessCode.email === recipient[index]?.email &&
                          showAccessCode.show
                            ? "hidden  "
                            : "block animate-slide-up"
                        } w-full bg-zinc-100 border border-gray-300 rounded-md py-2 px-4 flex justify-between items-center cursor-pointer`}
                      >
                        <p>
                          Access code for {current.name}:{" "}
                          <span
                            style={{
                              color: current.colour,
                            }}
                          >
                            <span className="font-semibold">
                              {recipient[index].accessCode}
                            </span>
                          </span>
                        </p>
                        <div className="flex gap-2">
                          <MdDelete
                            size={18}
                            className="text-gray-500"
                            onClick={() => {
                              updateAccessCodeState("", "");
                              setRecipient((prev: any) => {
                                const temp = [...prev];
                                temp[index].accessCode = "";
                                return temp;
                              });
                            }}
                          />
                          <MdOutlineKeyboardArrowDown
                            size={22}
                            className="text-primary "
                            onClick={() => {
                              updateAccessCodeState(
                                recipient[index]?.email,
                                recipient[index]?.name
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="buttons flex gap-4 w-fit ml-auto mt-2">
              <BoloButton
                variant={"secondary"}
                size="sm"
                className="flex items-center justify-center gap-3"
                // className="text-primary w-full bg-secondary px-4 py-1.5 rounded-md text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
                onClick={async () => {
                  const newEmail = sendToEmail.trim();
                  setEmails((prevEmails) => [...prevEmails, newEmail]);
                  setSendToEmail("");
                  setSendToName("");
                  setSendToNote("");

                  setRecipient([
                    ...recipient,
                    {
                      email: "",
                      name: "",
                      signingOrderNo: recipient?.length + 1,
                    },
                  ]);
                }}
              >
                <MdPersonAddAlt1 className="inline-block text-lg md:text-2xl" />
                <span className="text-xs md:text-sm"> Add Signer</span>{" "}
              </BoloButton>
            </div>
          </div>
        </div>
      )}
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
    </div>
  );
};

export default EmailInputForm;
