import ReactPixel from 'react-facebook-pixel';
import TikTok from './tiktok';
import { FACEBOOK_PIXEL_ID } from "../../constants/constants";


const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

class FB {
  constructor(email) {
    this.pixel = ReactPixel;
    this.pixel.init(
      FACEBOOK_PIXEL_ID,
      email ? { em: email } : null, options
    );
    this.pixel.pageView()
  }

  trackPurchase(product, priceId, quantity, value) {
    console.log(product, priceId, quantity, value)
    this.pixel.track('Purchase', {
      value: value,
      num_items: quantity,
      currency: 'USD',
      content_ids: [priceId],
      content_name: product,
    });

  }

  trackInitiateCheckout(product, priceId, quantity, value,) {
    console.log(product, priceId, quantity, value)
    this.pixel.track('InitiateCheckout', {
      value: value,
      num_items: quantity,
      currency: 'USD',
      content_category: product,
      content_ids: [priceId],

    });



  }


  trackAddPaymentInfo(product, priceId, quantity, value) {
    console.log(product, priceId, quantity, value)
    this.pixel.track('AddPaymentInfo', {
      value: value,
      num_items: quantity,
      currency: 'USD',
      content_category: product,
      content_ids: [priceId],

    });


  }

  trackSignUp(email) {
    this.pixel.track('StartTrial', {
      currency: 'USD',
      value: 99,
      predicted_ltv: 250,
    })
  }


  trackCustom(event, data) {
    this.pixel.track(event, data);
  }

}


export default FB


