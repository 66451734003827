import React, {
  useState,
  forwardRef,
  Ref,
  useEffect,
  KeyboardEvent,
} from "react";
// import { ImageSchema } from '@pdfme/common';
import ReactDOM from "react-dom";
import { SchemaUIProps } from "./SchemaUI";
import {
  DEFAULT_DATA,
  STANDARD_DATA,
  STATIC_SCHEMA,
  ZOOM,
} from "../../constants";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ImageSchema, SchemaForUI } from "../../../../common/src";
import SignatureTemplateModal from "../../../../../components/Modals/SignatureTemlateModals";
import {
  addTimeStampToSignature,
  checkAndSetEnabledKey,
} from "../../../../../utils/uiUtils";
import StaticImageUploadModal from "./StaticImageUpload";
import { MdImage } from "react-icons/md";
import { getWhiteLabelInfoLocal } from "../../../../../utils";
import { FaSignature } from "react-icons/fa6";
import { LuStamp } from "react-icons/lu";
import { TbLetterCaseUpper } from "react-icons/tb";
import { PiSignature } from "react-icons/pi";
import { BoloButton } from "../../../../../components/Common/Button/BoloButton";
import { COLORS } from "../../../../../constants/common";
import { generalizedFileUploadViaSignedUrlPublic } from "../../../../../utils/fileUpload";
import { S3PATHS } from "../../../../../constants/s3Folders";
import { v4 } from "uuid";
import { Imagebase64ToFile } from "../../../../../utils/localPdfUtils";
import { isEditorSchema } from "../../designerHelper";
type Props = SchemaUIProps & { schema: ImageSchema };

const ImageSchemaUI = (props: Props, ref: Ref<HTMLInputElement>) => {
  const {
    editable,
    placeholder,
    tabIndex,
    schema,
    onChange,
    isActive,
    setShownModal,
    handleNext,
    isOnlyPdfTemplate,
    isPreview,
    previewStyle,
    isSigning,
    previewState,
    activeElements,
    isApprover,
    designerState,
    setActiveSchema,
  } = props;
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const primaryColor =
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary;
  const [fileName, setFileName] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalStatic, setShowModalStatic] = useState<boolean>(false);
  const isDefault =
    schema.data === STANDARD_DATA.signature.unsigned_DBKEYV1.key ||
    schema.data === DEFAULT_DATA.initials.defaultAWSLink ||
    schema.data === DEFAULT_DATA.stamp.defaultLink;
  const hasData = Boolean(schema.data);

  const [signingTypeEnabled, setSigningTypeEnabled] = useState<boolean>(false);
  const isEditorARecipient = isEditorSchema({
    schema,
    receiversList: designerState?.receiversList || [],
    instanceFrom: designerState?.instanceFrom || "PDF-TEMPLATE",
  });
  const [usedSignature, setUsedSignature] = useState<string>("");
  const urlParams = new URLSearchParams(window.location.search);
  const size: React.CSSProperties = {
    maxWidth: schema.width * ZOOM,
    maxHeight: schema.height * ZOOM,
  };
  const addDefaultSignature = async (signatureUrl: string) => {
    if (!signatureUrl) {
      return;
    }
    try {
      const owner = JSON.parse(localStorage.getItem("owner") || "{}");
      const signatureUrl = owner.defaultSignature;

      if (!signatureUrl) {
        throw new Error("Signature URL not found in local storage.");
      }

      const response = await fetch(signatureUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch signature image (${response.status})`);
      }

      const blob = await response.blob();
      const base64data = await blobToBase64(blob);
      if (schema?.isStatic || isEditorARecipient) {
        let blockSignatureFile = new File([blob], "editor_signature-" + v4(), {
          type: blob.type,
        });
        let blockSignUrl = await generalizedFileUploadViaSignedUrlPublic({
          file: blockSignatureFile,
          folderName: S3PATHS.STATIC_ASSETS,
        });
        onChange(blockSignUrl);
        return;
      }
      // const updatedDrawing = await addTimeStampToSignature(
      //   base64data as string,
      //   schema
      // );
      onChange(base64data as string);
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
    }
  };

  const addDefaultInitial = async (intialUrl: string) => {
    if (!intialUrl) {
      return;
    }
    try {
      const owner = JSON.parse(localStorage.getItem("owner") || "{}");
      const intialUrl = owner.defaultInitial;

      if (!intialUrl) {
        throw new Error("Initial URL not found in local storage.");
      }

      const response = await fetch(intialUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch intial image (${response.status})`);
      }

      const blob = await response.blob();
      const base64data = await blobToBase64(blob);
      if (isEditorARecipient) {
        let initialFile = new File([blob], "editor_initial-" + v4(), {
          type: blob.type,
        });
        let initialUrl = await generalizedFileUploadViaSignedUrlPublic({
          file: initialFile,
          folderName: S3PATHS.STATIC_ASSETS,
        });
        onChange(initialUrl);
        return;
      }

      onChange(base64data as string);
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
    }
  };

  const addDefaultStamp = async (stampUrl: string) => {
    if (!stampUrl) {
      return;
    }
    try {
      const owner = JSON.parse(localStorage.getItem("owner") || "{}");
      const stampUrl = owner.defaultStamp;

      if (!stampUrl) {
        throw new Error("Stamp URL not found in local storage.");
      }

      const response = await fetch(stampUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch stamp image (${response.status})`);
      }

      const blob = await response.blob();
      const base64data = await blobToBase64(blob);
      if (isEditorARecipient) {
        let stampFile = new File([blob], "editor_stamp-" + v4(), {
          type: blob.type,
        });
        let stampUrl = await generalizedFileUploadViaSignedUrlPublic({
          file: stampFile,
          folderName: S3PATHS.STATIC_ASSETS,
        });
        onChange(stampUrl);
        return;
      }
      onChange(base64data as string);
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
    }
  };
  const giveHostedUrl = async (base64: string, type?: string) => {
    let base64File = await Imagebase64ToFile(
      base64,
      `editor_${type?.toLowerCase()}` + v4()
    );
    if (base64File) {
      let hostedUrl = await generalizedFileUploadViaSignedUrlPublic({
        file: base64File,
        folderName: S3PATHS.STATIC_ASSETS,
      });
      return hostedUrl;
    }
  };
  async function blobToBase64(blob: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const getImage = (schema: SchemaForUI) => {
    if (schema.data == "unsigned_DBKEYV1") {
      return STANDARD_DATA.signature[schema.data].data;
    }
    return schema.data;
  };

  const getImageType = () => {
    if (schema.subType == "initials") {
      return "initial";
    }
    if (schema.subType == "stamp") {
      return "stamp";
    }
    if (schema.subType == "signature") {
      return "signature";
    }
  };

  const getImageIcon = () => {
    let color = schema.role?.colour;
    if (isPreview || isSigning) {
      if (previewState?.fetchedData?.accountInfo?.theme) {
        color = previewState?.fetchedData?.accountInfo?.theme;
      } else {
        color = "hsl(var(--primary))";
      }
    }
    if (schema.subType == "initials") {
      return (
        <TbLetterCaseUpper
          size={Number(size.maxHeight) * 0.9}
          style={{
            color: color,
            // background: getTintFromHex(color, 85),
          }}
        />
      );
    }
    if (schema.subType == "stamp") {
      return (
        <LuStamp
          size={Number(size.maxHeight) * 0.8}
          style={{
            color: color,
            // background: getTintFromHex(color, 85),
          }}
        />
      );
    }
    if (schema.subType == "signature") {
      return (
        <PiSignature
          size={Number(size.maxHeight)}
          style={{
            color: color,
            // background: getTintFromHex(color, 85),
          }}
        />
      );
    }

    return (
      <PiSignature
        size={Number(size.maxHeight)}
        style={{
          color: "hsl(var(--primary))",
          // background: getTintFromHex(color, 85),
        }}
      />
    );
  };

  const getBackground = () => {
    if (isPreview && previewStyle !== "DEFAULT") return "transparent";

    if (schema?.isQuestionDeleted) {
      return "rgb(255 0 0 / 0.30)";
    }

    if (isPreview || isSigning) {
      if (schema.isStatic) {
        return "transparent";
      }
      if (previewState?.fetchedData?.accountInfo?.theme) {
        if (isActive && editable) {
          return previewState?.fetchedData?.accountInfo?.theme + "70";
        } else {
          return previewState?.fetchedData?.accountInfo?.theme + "20";
        }
      } else {
        if (isActive && editable) {
          // return primaryColor + "70";
          return `hsl(var(--primary) / 0.55)`;
        } else {
          // return primaryColor + "20";
          return `hsl(var(--primary) / 0.2)`;
        }
      }
    }
    if (isApprover && schema?.isStatic) {
      return "transparent";
    }

    if (isActive && editable) {
      if (schema?.isStatic) return STATIC_SCHEMA.color + 70;

      if (schema?.signatureColour) return schema?.signatureColour + 70;

      if (schema?.role?.colour) return schema?.role?.colour + "70";
    } else {
      if (schema?.isStatic) return STATIC_SCHEMA.color + 20;

      if (schema?.signatureColour) return schema?.signatureColour + 20;

      if (schema?.role?.colour) return schema?.role?.colour + "20";
    }
  };
  function getTitle(): string {
    if (schema?.isQuestionDeleted) {
      return "This Question is Deleted";
    } else {
      if (isApprover) {
        if (!schema?.isStatic && schema?.role) {
          let signer = previewState?.signersData?.[schema?.role?.title];
          return signer?.name || signer?.email || schema?.showKey;
        }
      }
      return schema?.showKey;
    }
  }
  useEffect(() => {
    let signatureTypesEnabled = checkAndSetEnabledKey(
      previewState?.settings?.signingType
    );
    setSigningTypeEnabled(signatureTypesEnabled);
  }, []);
  // for all normal i.e non static schemas it should works as it worked previously
  // for staic schemas
  // condition 1. in designer it should be editable
  // condition 2. in form viewer it should be shown as text
  let newEditable = editable || isEditorARecipient;
  if (schema.isStatic) {
    newEditable = !(isPreview || isSigning);
  }
  const isTheOnlySchemaActive = isActive && activeElements?.length == 1;

  // --------------------------------------------------------------------------------------
  // new handle click and enter
  const handleClick = async (e?: any) => {
    console.log("isEditorARecipient", isEditorARecipient);

    if (isApprover) {
      return;
    }
    setActiveSchema && setActiveSchema(schema);
    // console.log("handle key click", schema?.subType);
    if (isEditorARecipient || editable) {
      e && e.stopPropagation();
      const owner = JSON.parse(localStorage.getItem("owner") || "{}");
      const signatureUrl = owner?.defaultSignature;
      const initialUrl = owner?.defaultInitial;
      const stampUrl = owner?.defaultStamp;
      // TODO: set isSigningInPerson false when user email and owner email is same
      const isSigningInPerson = urlParams.get("SigningInPerson") === "1";
      // this is for signature --------------------------------------
      // ! this commented code is a bug fix so if any issue arises uncomment it
      // if (schema.subType == "signature" || !isOnlyPdfTemplate) {
      if (schema.subType == "signature") {
        console.log("this is a signature");
        if (
          (schema?.data === "https://i.imgur.com/AfyPZQg.png" ||
            schema?.data === "" ||
            schema?.data === STANDARD_DATA.signature.unsigned_DBKEYV1.key) &&
          signatureUrl &&
          !isSigningInPerson &&
          !signingTypeEnabled
        ) {
          await addDefaultSignature(signatureUrl);
        } else if (
          (schema?.data === "https://i.imgur.com/AfyPZQg.png" ||
            schema?.data === "" ||
            schema.data === STANDARD_DATA.signature.unsigned_DBKEYV1.key) &&
          (!signatureUrl || isSigningInPerson || signingTypeEnabled)
        ) {
          let currentSignature = localStorage.getItem("temporarySignature");
          if (currentSignature) {
            // const updatedDrawing = await addTimeStampToSignature(
            //   currentSignature,
            //   schema
            // );
            if (isEditorARecipient) {
              let localStorageSignatureUrl = await giveHostedUrl(
                currentSignature,
                "SIGNATURE"
              );
              if (localStorageSignatureUrl) {
                onChange(localStorageSignatureUrl);
                return;
              }
            }
            onChange(currentSignature);
          } else {
            setShowModal(true);
          }
        } else if (setShownModal || isEditorARecipient) {
          setShowModal(true);
          setShownModal && setShownModal(true);
        }
      }
      // ----------------------------------------------------------
      // this is for initals --------------------------------------
      if (schema.subType == "initials") {
        if (
          (schema.data === "" ||
            schema?.data === DEFAULT_DATA.initials.defaultAWSLink) &&
          initialUrl &&
          !isSigningInPerson &&
          !signingTypeEnabled
        ) {
          await addDefaultInitial(initialUrl);
        } else if (
          (schema.data === "" ||
            schema?.data === DEFAULT_DATA.initials.defaultAWSLink) &&
          (!initialUrl || isSigningInPerson || signingTypeEnabled)
        ) {
          let currentInitial = localStorage.getItem("temporaryInitial");
          if (currentInitial) {
            if (isEditorARecipient) {
              let localStorageInitialUrl = await giveHostedUrl(
                currentInitial,
                "INITIALS"
              );
              if (localStorageInitialUrl) {
                onChange(localStorageInitialUrl);
                return;
              }
            }
            onChange(currentInitial);
          } else {
            setShowModal(true);
          }
        } else if (setShownModal || isEditorARecipient) {
          setShowModal(true);
          setShownModal && setShownModal(true);
        }
      }
      // ----------------------------------------------------------
      // this is for stamp --------------------------------------
      if (schema.subType == "stamp") {
        if (
          (schema.data === "" ||
            schema?.data === DEFAULT_DATA.stamp.defaultLink) &&
          stampUrl &&
          !isSigningInPerson
        ) {
          await addDefaultStamp(stampUrl);
        } else if (
          (schema.data === "" ||
            schema?.data === DEFAULT_DATA.stamp.defaultLink) &&
          (!stampUrl || isSigningInPerson)
        ) {
          let currentStamp = localStorage.getItem("temporaryStamp");
          if (currentStamp) {
            if (isEditorARecipient) {
              let localStorageStampUrl = await giveHostedUrl(
                currentStamp,
                "STAMP"
              );
              if (localStorageStampUrl) {
                onChange(localStorageStampUrl);
                return;
              }
            }
            onChange(currentStamp);
          } else {
            setShowModal(true);
          }
        } else if (setShownModal || isEditorARecipient) {
          setShowModal(true);
          setShownModal && setShownModal(true);
        }
      }
      // ----------------------------------------------------------
      // check with arian for right position in the above if else
      if (isActive) handleNext?.();
    }
    // this is for static_image ---------------------------------
    if (
      schema.subType == "static_image" &&
      isTheOnlySchemaActive &&
      designerState?.mode === "EDITING"
    ) {
      setShowModalStatic(true);
    }
    // this is for static signature --------------------------------
    if (schema.subType == "static_signature" && isTheOnlySchemaActive) {
      const owner = JSON.parse(localStorage.getItem("owner") || "{}");
      const signatureUrl = owner?.defaultSignature;
      // TODO: set isSigningInPerson false when user email and owner email is same
      const isSigningInPerson = urlParams.get("SigningInPerson") === "1";
      if (
        (schema?.data === "https://i.imgur.com/AfyPZQg.png" ||
          schema?.data === "" ||
          schema?.data === STANDARD_DATA.signature.unsigned_DBKEYV1.key) &&
        signatureUrl &&
        !isSigningInPerson &&
        !signingTypeEnabled
      ) {
        await addDefaultSignature(signatureUrl);
      } else if (
        (schema?.data === "https://i.imgur.com/AfyPZQg.png" ||
          schema?.data === "" ||
          schema.data === STANDARD_DATA.signature.unsigned_DBKEYV1.key) &&
        (!signatureUrl || isSigningInPerson || signingTypeEnabled)
      ) {
        let currentSignature = localStorage.getItem("temporarySignature");
        if (currentSignature) {
          // const updatedDrawing = await addTimeStampToSignature(
          //   currentSignature,
          //   schema
          // );
          let blockSignatureFile = await Imagebase64ToFile(
            currentSignature,
            "block_signature-" + v4()
          );
          if (blockSignatureFile) {
            let blockSignUrl = await generalizedFileUploadViaSignedUrlPublic({
              file: blockSignatureFile,
              folderName: S3PATHS.STATIC_ASSETS,
            });
            onChange(blockSignUrl);
          }
        } else {
          setShowModal(true);
        }
      } else {
        setShowModal(true);
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    // console.log("handle key down", event.key);
    if (event.key === "Enter") {
      handleClick();
    }
  };

  // ---------------------------------------------------------------------------------

  return (
    <>
      <div
        data-schema-sub-type={schema.subType}
        data-schema-id={schema.id}
        autoFocus={isActive}
        tabIndex={tabIndex}
        title={getTitle()}
        className={`flex items-center justify-center relative h-full w-full ${
          isActive && editable ? "bg-primary/70" : "bg-primary bg-opacity-20"
        }`}
        style={{
          ...size,
          // opacity: hasData ? 1 : 0.5,
          // backgroundImage: hasData || !editable ? 'none' : `url(${placeholder})`,
          backgroundSize: `${size.width}px ${size.height}px`,
          background: getBackground(),
        }}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {hasData && !isDefault && (
          <img style={{ ...size, borderRadius: 0 }} src={getImage(schema)} />
        )}
        {(isDefault || !hasData) && !schema.isStatic && getImageIcon()}
        {(isDefault || !hasData) &&
          newEditable &&
          schema.isStatic &&
          schema?.subType === "static_signature" &&
          designerState?.mode === "EDITING" && (
            <PiSignature
              size={Number(size.maxHeight)}
              style={{
                color: "GrayText",
                // background: getTintFromHex(color, 85),
              }}
            />
          )}

        {!hasData &&
          newEditable &&
          schema.isStatic &&
          schema?.subType === "static_image" &&
          designerState?.mode === "EDITING" && (
            <div className="flex gap-4 items-center justify-center text-gray-500">
              <MdImage size={30} /> Upload Image
            </div>
          )}
        {/* editable changed here only */}
        {newEditable && !schema.isStatic && (
          <span className="absolute bottom-[-14px] left-0 text-xs">
            {schema?.subType} *
          </span>
        )}
        {/* editable changed here only */}
        {!isApprover && hasData && newEditable && (
          <BoloButton
            title={`Remove ${schema.showKey}`}
            size={"icon-xs"}
            variant="danger-border"
            className="shadow-none hover:shadow-none rounded-[0] bg-transparent border-none"
            tabIndex={-1}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // color: "#333",
              // background: "#f2f2f2",
              // borderRadius: 2,
              // border: "1px solid #767676",
              cursor: "pointer",
              height: 20,
              width: 20,
              padding: 0,
            }}
            aria-label="close"
            onClick={(e) => {
              setFileName("");
              onChange("");
              e.stopPropagation();
            }}
          >
            <XMarkIcon width={16} height={16} />
          </BoloButton>
        )}
      </div>
      {showModalStatic &&
        ReactDOM.createPortal(
          <StaticImageUploadModal
            setShow={setShowModalStatic}
            saveSchemaData={(imgData: string) => {
              onChange(imgData);
            }}
          />,
          document.getElementById("modal-root")!
        )}
      {showModal &&
        ReactDOM.createPortal(
          // added class  bg-black bg-opacity-60 overlay fix
          <div className="absolute inset-0 z-[200000] flex items-center justify-center bg-black bg-opacity-60">
            {/* Dark overlay */}
            {/* <div className="fixed inset-0 bg-black opacity-60"></div> */}

            {/* Modal */}
            <div className="absolute z-[200001]">
              <SignatureTemplateModal
                dismiss={() => {
                  setShowModal(false);
                  setShownModal && setShownModal(false);

                  const nextElm = document.querySelector(
                    `[tabindex="${(tabIndex || 100) + 1}"]`
                  );
                  console.log(nextElm);
                  if (nextElm instanceof HTMLElement) {
                    nextElm.focus();
                  }
                }}
                open={showModal}
                confirm={async (drawing: string) => {
                  if (schema.subType == "signature") {
                    localStorage.setItem("temporarySignature", drawing);
                  }
                  if (schema.subType == "initials") {
                    localStorage.setItem("temporaryInitial", drawing);
                  }
                  if (schema.subType == "stamp") {
                    localStorage.setItem("temporaryStamp", drawing);
                  }
                  // if (schema.subType == "signature") {
                  //   // const updatedDrawing = await addTimeStampToSignature(
                  //   //   drawing,
                  //   //   schema
                  //   // );
                  //   onChange(drawing);
                  // }
                  // if (schema.subType == "static_signature") {
                  //   let blockSignatureFile = await Imagebase64ToFile(
                  //     drawing,
                  //     "block_signature-" + v4()
                  //   );
                  //   if (blockSignatureFile) {
                  //     let blockSignUrl =
                  //       await generalizedFileUploadViaSignedUrlPublic({
                  //         file: blockSignatureFile,
                  //         folderName: S3PATHS.STATIC_ASSETS,
                  //       });
                  //     onChange(blockSignUrl);
                  //   }
                  // } else {
                  //   onChange(drawing);
                  // }
                  if (isEditorARecipient) {
                    let localStorageSignatureUrl = await giveHostedUrl(
                      drawing,
                      schema?.subType?.toUpperCase()
                    );
                    if (localStorageSignatureUrl) {
                      onChange(localStorageSignatureUrl);
                      return;
                    }
                  }
                  onChange(drawing);
                }}
                imageType={getImageType()}
                isStamp={schema.subType == "stamp"}
                previewState={previewState}
                signatureSettingType={previewState?.settings?.signingType}
              />
            </div>
          </div>,
          document.getElementById("modal-root")! // A DOM element where the modal should be rendered
        )}
    </>
  );
};

export default forwardRef<HTMLInputElement, Props>(ImageSchemaUI);
