import React, { useEffect, useState } from "react";
import { FaInfo, FaInfoCircle } from "react-icons/fa";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { BoloInput } from "../../components/Common/Input/BoloInput";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import { IMAGE_LINKS } from "../../constants/common";
import { embedLogin } from "../../redux/api/owner";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { delay, logOut } from "../../utils";
import {
  isEmbeded,
  isSelfEmbed,
  sessionInfoFormatter,
} from "../../utils/embed";

const EmbedLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState<any>({});
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [sessionInputs, setSessionInputs] = useState<{ [key: string]: string }>(
    {}
  );
  const [activeView, setAciveView] = useState<
    | "loading"
    // | "sessioninput"
    | "create"
    | "invalid-key"
    | "expired-key"
    | "no-key"
  >("loading");

  const handleLogin = async () => {
    setLoading(true);
    // console.log("handle Login called with search params", searchParams);
    if (searchParams.get("embed-token")) {
      localStorage.setItem(
        "x-embed-token",
        searchParams.get("embed-token") || ""
      );
    }
    localStorage.setItem(
      "embed-session-inputs",
      searchParams.get("embed-session-inputs") || ""
    );
    if (!isSelfEmbed()) {
      const data = await embedLogin({
        token:
          searchParams.get("embed-token") ||
          localStorage.getItem("x-embed-token") ||
          "",
      });
      if (data?.owner?._id) {
        dispatch(getOwnerThunk({ id: data?.owner?._id }));
      }
      console.log("Embed login data", data);
      if (!data?.success) {
        setAciveView(data?.reason);
        // console.log("!data?.success", !data?.success, data?.reason);
        if (
          data?.reason == "invalid-key" ||
          data?.reason == "expired-key" ||
          data?.reason == "no-key"
        ) {
          logOut(true);
          setAciveView(data?.reason);
          setLoading(false);
        }
      }
    }
    setOwner(JSON.parse(localStorage.getItem("owner") || "{}"));
    setLoading(false);
  };

  // function handleSessionInputs(key: string, value: string) {
  //   setSessionInputs((prev) => ({ ...prev, [key]: value }));
  // }

  // function handleSessionContinue() {
  //   localStorage.setItem("embed-session-inputs", JSON.stringify(sessionInputs));
  //   setAciveView("create");
  // }

  // function handleClearSession() {
  //   localStorage.removeItem("embed-session-inputs");
  //   setSessionInputs({});
  //   setAciveView("sessioninput");
  // }

  function getActiveView() {
    if (
      activeView == "expired-key" ||
      activeView == "invalid-key" ||
      activeView == "no-key"
    ) {
      return activeView;
    }

    if (loading) {
      return "loading";
    }

    // let sessionInputs = owner?.embed?.sessionInputs || [];
    // let sessionInputsLocal = JSON.parse(
    //   localStorage.getItem("embed-session-inputs") || "{}"
    // );
    // if (!loading && owner.email) {
    //   if (!sessionInputs.length) {
    //     return "create";
    //   }

    //   for (let inp of sessionInputs) {
    //     if (!sessionInputsLocal?.[inp?.key]) {
    //       return "sessioninput";
    //     }
    //   }
    //   return "create";
    // }

    if (!loading && owner.email) {
      return "create";
    }

    return "loading";
  }

  const viewTextMap = {
    "expired-key": "Expired Key",
    "invalid-key": "Invalid Key",
    "no-key": "Is Empty string",
  };

  useEffect(() => {
    handleLogin();
  }, [searchParams]);

  useEffect(() => {
    console.log({ av: getActiveView(), loading, owner });
    setAciveView(getActiveView());
  }, [loading, owner]);

  return (
    <div className="flex flex-col items-center justify-center gap-2 text-sm w-full h-[100vh]">
      {/* <div className="flex items-center gap-4  mb-12">
        <img
          className="h-12 w-12"
          src={
            whiteLabelInfo?.settings?.organization?.name || IMAGE_LINKS.BOLOLOGO
          }
        />
        <p className="text-2xl font-bold text-primary">
          {whiteLabelInfo?.settings?.organization?.name || "BoloForms"}{" "}
          Integrations
        </p>
      </div> */}
      {/* <pre>{JSON.stringify(owner?.embed, null, "\t")}</pre> */}
      {activeView === "loading" && <BoloLoader />}
      <div className="max-w-[400px] w-[400px] flex items-center justify-center">
        {(activeView === "expired-key" ||
          activeView === "invalid-key" ||
          activeView === "no-key") && (
          <div className="flex-col flex gap-4 justify-center items-center">
            <div className="text-red-500 flex items-center gap-3 rounded bg-red-100 p-1 px-3">
              <FaInfoCircle />
              The provided embed key is
              <span className="font-semibold">
                {viewTextMap[activeView] || "is not valid"}
              </span>
            </div>
            <p className="text-xs text-gray-500">
              Please contact your admin for assistance
            </p>
          </div>
        )}
        {/* {activeView === "sessioninput" && (
          <div className="flex gap-2 flex-col w-full">
            {owner?.embed?.sessionInputs?.map((inp: any) => {
              return (
                <BoloInput
                  placeholder={inp?.key}
                  bolosize="sm"
                  className="w-full"
                  value={sessionInputs[inp?.key] || ""}
                  onChange={(e) => {
                    handleSessionInputs(inp?.key || "", e.target.value);
                  }}
                />
              );
            })}
            <BoloButton
              className="mt-4 w-full"
              size={"sm"}
              onClick={() => handleSessionContinue()}
            >
              Continue
            </BoloButton>
          </div>
        )} */}
        {activeView === "create" && (
          <div className="flex gap-2 flex-col">
            {/* {sessionInfoFormatter() && (
              <>
                <div className="flex w-full justify-between items-center">
                  <p>Session info</p>{" "}
                </div>
                <p className="text-xs mb-4">{sessionInfoFormatter()}</p>
              </>
            )} */}
            <BoloButton
              size={"sm"}
              className="px-6 md:px-6"
              onClick={() => navigate("/create/pdf")}
            >
              Create PDF
            </BoloButton>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default EmbedLogin;
