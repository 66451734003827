import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import { MdDelete, MdEdit, MdImage } from "react-icons/md";
import { DesignerState } from "..";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../../../../../components/Common/SelectDropDown/BoloDropDown";

import {
  isTableSchema,
  SchemaForUI,
  TableSchema,
  TableCell,
  PriceSubTotalType,
} from "../../../../../common/src/type";
import {
  formatPrice,
  getDefaultCurrency,
  getRowSubtotal,
  getTableGrandTotal,
  isValidArg,
} from "../../../../../../utils/quoteBuilder";
import { cn } from "../../../../../../components/Common/lib/utils";
import ProductAdderModal from "./ProductAdder";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../components/Common/BoloSelect/BoloSelect";
import getSymbolFromCurrency from "currency-symbol-map";
import BoloRadioButtonMui from "../../../../../../components/Common/RadioButton/BoloRadioButtonMui";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { BOLO_CURRENCIES } from "../../../../../../constants/currency";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../../components/Common/Dialog/BoloDialog";
export type QuoteBuilderProps = {
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
};

interface InputFieldProps {
  initial: number;
  currency: string;
  argType: "price" | "qty" | "percent";
  cb: ({ value }: { value: number }) => void;
  className?: string;
}

interface InputFieldTextProps {
  initial: string;
  argType: "header" | "description";
  cb: ({ value }: { value: string }) => void;
  className?: string;
}

const UploadImage = ({}: {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localFiles, setLocalFiles] = useState<File[] | null>(null);

  function handleFileChange(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLLabelElement>
  ) {
    let files: FileList | null = null;

    if (event.target instanceof HTMLInputElement && event.target.files) {
      // If file input triggered the change event
      files = event.target.files;
      //@ts-ignore
    } else if (event.dataTransfer) {
      // If drag and drop triggered the change event
      event.preventDefault();
      //@ts-ignore
      files = event.dataTransfer.files;
    }

    if (files) {
      // if (files.length > 1) {
      //   return toast.error("Only One file accepted");
      // }

      const fileArray: File[] = Array.from(files);
      for (let file of fileArray) {
        if (!file.type?.includes("image")) {
          return toast.error("Only Image file type accepted");
        }
      }

      setLocalFiles(fileArray);
    }
  }
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <div className="cursor-pointer h-[40px] w-[40px] bg-gray-300 text-white rounded flex items-center justify-center">
          <MdImage size={25} />
        </div>
      </DialogTrigger>
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Upload Images</DialogTitle>
        </DialogHeader>

        <div>
          <label
            htmlFor="dropzone-file"
            className={`notranslate flex flex-col items-center justify-center p-8 border-2 border-gray-300 hover:border-gray-500 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-full text-gray-600 transition-all ease-in-out duration-150 ${
              localFiles ? "bg-secondary/80 border-primary/80" : ""
            } `}
            onDrop={(e) => {
              handleFileChange(e);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
          >
            <p
              className={`text-base font-semibold ${
                localFiles && localFiles?.length
                  ? "text-primary"
                  : "text-gray-600"
              }`}
            >
              {localFiles && localFiles?.length > 0 ? (
                <div className="flex gap-5 items-center">
                  {" "}
                  <MdImage size={25} />
                  {localFiles?.[0]?.name}
                </div>
              ) : (
                <div className="flex gap-5 items-center">
                  {" "}
                  <MdImage size={25} /> Please Select an Image File
                </div>
              )}
            </p>
            <input
              id="dropzone-file"
              type="file"
              className="appearance-none hidden disabled:opacity-60"
              onChange={handleFileChange}
              accept={"image/*"}
            />
          </label>
        </div>

        <DialogFooter>
          <BoloButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => {
              setLocalFiles(null);
              setIsOpen(false);
            }}
          >
            Cancel
          </BoloButton>

          <BoloButton
            variant={"primary"}
            size={"sm"}
            onClick={() => {
              //AY refer image upload from static schema here
            }}
          >
            Upload
          </BoloButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export type AddMutipleProductFunc = ({
  data,
  idx,
}: {
  data: {
    header: string;
    description: string;
    images: string[];
    price: number;
    qty: number;
    currency: string;
  }[];
  idx: number;
}) => void;

const InputField: React.FC<InputFieldProps> = ({
  initial,
  currency,
  argType,
  cb,
  className,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initial);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (isValidArg({ value: value, argType })) {
      cb({ value: value });
    }
    // setValue(val);
  };

  useEffect(() => {
    setValue(initial);
  }, [initial]);

  return (
    <>
      {isEditing ? (
        <input
          className={cn(
            "w-[10%] text-sm text-right min-h-[54px] flex items-center justify-end px-1 outline-none border-2 border-transparent focus:border-primary hover:border-secondary",
            className
          )}
          type="number"
          value={value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleBlur();
            }
          }}
          autoFocus
        />
      ) : (
        <div
          className={cn(
            "w-[10%] text-sm text-right min-h-[54px] flex items-center justify-end px-1 border-2 border-transparent hover:border-secondary",
            className
          )}
          onClick={() => setIsEditing(true)}
        >
          {argType == "price" && formatPrice({ amount: initial, currency })}
          {argType == "qty" && initial}
          {argType == "percent" && `${initial}%`}
        </div>
      )}
    </>
  );
};

const InputFieldText: React.FC<InputFieldTextProps> = ({
  initial,
  argType,
  cb,
  className,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initial);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // if (isValidArg({ value: value, argType })) {
    cb({ value: value });
    // }
    // setValue(val);
  };

  return (
    <>
      {isEditing ? (
        <input
          className={cn(
            "text-sm min-h-[20px] flex items-center px-1 outline-none border-2 border-transparent focus:border-primary hover:border-secondary",
            className
          )}
          type="text"
          value={value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleBlur();
            }
          }}
          autoFocus
        />
      ) : (
        <div
          className={cn(
            "text-sm min-h-[20px] flex items-center px-1 border-2 border-transparent hover:border-secondary",
            className
          )}
          onClick={() => setIsEditing(true)}
        >
          {initial}
        </div>
      )}
    </>
  );
};

const QuoteBuilder = (props: QuoteBuilderProps) => {
  const { designerState, setDesignerState } = props;
  const [currency, setCurrency] = useState("");
  // const [activeTable, setActiveTable] = useState<TableSchema | null>(null);

  function setNewRows({ newRows }: { newRows: TableCell[][] }) {
    // const schemasList: SchemaForUI[][] = structuredClone(
    //   designerState.schemasList
    // );

    // let newSchemasList: SchemaForUI[][] = schemasList.map((page) =>
    //   page.map((schema) => {
    //     if (schema.id === designerState.quoteBuilder.schemaId) {
    //       let modifiedSchema_ = { ...schema, rows: newRows };
    //       if (isTableSchema(modifiedSchema_)) {
    //         let newSubTotalMenu = getTableGrandTotal({
    //           schema: modifiedSchema_,
    //         });
    //         modifiedSchema_.subtotalMenu = newSubTotalMenu;
    //       }
    //       return modifiedSchema_;
    //     }
    //     return schema;
    //   })
    // );

    // setDesignerState((prev) => ({ ...prev, schemasList: newSchemasList }));

    // # new system
    let newActiveTable: TableSchema = structuredClone(
      designerState.quoteBuilder.activeTable
    );
    newActiveTable.rows = newRows;
    let newSubTotalMenu = getTableGrandTotal({
      schema: newActiveTable,
    });
    newActiveTable.subtotalMenu = newSubTotalMenu;
    setDesignerState((prev) => ({
      ...prev,
      quoteBuilder: { ...prev.quoteBuilder, activeTable: newActiveTable },
    }));
  }

  function handleCurrency(currency: string) {
    if (currency !== designerState?.settings?.documentValue?.amount?.currency) {
      return toast.error("Currency must be the same as document currency");
    }
    setDesignerState((prev) => {
      let newActiveTable: TableSchema | null = prev.quoteBuilder.activeTable;
      console.log({ newActiveTable });
      if (newActiveTable && prev.quoteBuilder.activeTable) {
        newActiveTable = { ...prev.quoteBuilder.activeTable };
        let newSubTotalMenu = newActiveTable.subtotalMenu;
        if (newSubTotalMenu) {
          newActiveTable = {
            ...newActiveTable,
            subtotalMenu: { ...newSubTotalMenu, currency: currency },
          };
        }

        let newRows = [...newActiveTable.rows];
        for (let row of newRows) {
          row[1] = {
            data: formatPrice({ amount: row[1].price || 0, currency }),
            price: row[1].price || 0,
          };
          row[3] = {
            data: formatPrice({
              amount: getRowSubtotal({
                price: row[1].price || 0,
                qty: row[2].qty || 1,
              }).subtotal,
              currency,
            }),
            subtotal: getRowSubtotal({
              price: row[1].price || 0,
              qty: row[2].qty || 1,
            }).subtotal,
          };
        }
        newActiveTable.rows = newRows;

        newActiveTable.subtotalMenu = getTableGrandTotal({
          schema: newActiveTable,
        });
      }
      return {
        ...prev,
        quoteBuilder: {
          ...prev.quoteBuilder,
          activeTable: newActiveTable,
        },
      };
    });
    setCurrency(currency);
  }

  function removeProduct({ idx }: { idx: number }) {
    let modifiedSchema = designerState?.quoteBuilder?.activeTable;
    if (modifiedSchema) {
      let newRows = [...(modifiedSchema?.rows || [])];
      newRows.splice(idx, 1);
      modifiedSchema.rows = newRows;

      setNewRows({ newRows });
    }
  }

  const addMultipleProducts: AddMutipleProductFunc = ({ data, idx }) => {
    let modifiedSchema = designerState?.quoteBuilder?.activeTable;
    if (modifiedSchema) {
      let newRows = [...(modifiedSchema?.rows || [])];

      data.forEach(({ header, description, images, price, qty, currency }) => {
        newRows.splice(idx, 0, [
          { data: "", header, description, images },
          { data: formatPrice({ amount: price, currency }), price: price },
          { data: `${qty}`, qty: qty },
          {
            data: formatPrice({
              amount: getRowSubtotal({ price, qty }).subtotal,
              currency,
            }),
            subtotal: getRowSubtotal({ price, qty }).subtotal,
          },
        ]);
      });

      modifiedSchema.rows = newRows;
      setNewRows({ newRows });
    }
  };

  function addProduct({
    header,
    description,
    images,
    price,
    qty,
    currency,
    idx,
  }: {
    header: string;
    description: string;
    images: string[];
    price: number;
    qty: number;
    currency: string;
    idx: number;
  }) {
    // let modifiedSchema = designerState?.quoteBuilder?.activeTable;
    // if (modifiedSchema) {
    //   let newRows = [...(modifiedSchema?.rows || [])];

    //   newRows.splice(idx, 0, [
    //     { data: "", header, description, images },
    //     { data: formatPrice({ amount: price, currency }), price: price },
    //     { data: `${qty}`, qty: qty },
    //     {
    //       data: formatPrice({
    //         amount: getRowSubtotal({ price, qty }).subtotal,
    //         currency,
    //       }),
    //       subtotal: getRowSubtotal({ price, qty }).subtotal,
    //     },
    //   ]);

    //   modifiedSchema.rows = newRows;
    //   setNewRows({ newRows });
    // }
    addMultipleProducts({
      data: [{ header, description, images, price, qty, currency }],
      idx,
    });
  }

  function handleQtyAndPrice({
    idx,
    qty,
    price,
  }: {
    idx: number;
    qty?: number;
    price?: number;
    currency: string;
  }) {
    let modifiedSchema = designerState?.quoteBuilder?.activeTable;
    if (modifiedSchema) {
      let newRows = [...(modifiedSchema?.rows || [])];

      // console.log({ qty, price });

      // idx is the row number
      // row 1 will always contain price
      if (typeof price != "undefined") {
        newRows[idx][1] = {
          data: formatPrice({ amount: price, currency: currency }),
          price: price,
        };
      }
      // row 2 will always contain qty
      if (typeof qty != "undefined") {
        newRows[idx][2] = { data: `${qty}`, qty: qty };
      }

      // row 3 will always contain subtotal
      let newSubTotal = getRowSubtotal({
        price: newRows[idx][1].price || 0,
        qty: newRows[idx][2].qty || 0,
      });
      newRows[idx][3] = {
        data: formatPrice({ amount: newSubTotal.subtotal, currency }),
        subtotal: newSubTotal.subtotal,
      };

      modifiedSchema.rows = newRows;
      setNewRows({ newRows });
    }
  }

  function handleSubTotalMenu({
    // subTotal,
    discountFormat,
    feeFormat,
    taxFormat,
    discountAbsolute,
    feeAbsolute,
    taxAbsolute,
    discountPercent,
    feePercent,
    taxPercent,
    discountShow,
    feeShow,
    taxShow,
  }: {
    // subTotal?: number;
    discountFormat?: "percent" | "absolute";
    feeFormat?: "percent" | "absolute";
    taxFormat?: "percent" | "absolute";
    discountAbsolute?: number;
    feeAbsolute?: number;
    taxAbsolute?: number;
    discountPercent?: number;
    feePercent?: number;
    taxPercent?: number;
    discountShow?: boolean;
    feeShow?: boolean;
    taxShow?: boolean;
  }) {
    if (designerState?.quoteBuilder?.activeTable) {
      let modifiedSchema: TableSchema =
        designerState?.quoteBuilder?.activeTable;
      if (modifiedSchema && modifiedSchema.subtotalMenu) {
        // percentage -----------------------------------------
        if (typeof discountPercent !== "undefined") {
          modifiedSchema.subtotalMenu.discount.percentage = discountPercent;
        }
        if (typeof feePercent !== "undefined") {
          modifiedSchema.subtotalMenu.fee.percentage = feePercent;
        }
        if (typeof taxPercent !== "undefined") {
          modifiedSchema.subtotalMenu.tax.percentage = taxPercent;
        }

        // absolute -----------------------------------------
        if (typeof discountAbsolute !== "undefined") {
          modifiedSchema.subtotalMenu.discount.amount = discountAbsolute;
        }
        if (typeof feeAbsolute !== "undefined") {
          modifiedSchema.subtotalMenu.fee.amount = feeAbsolute;
        }
        if (typeof taxAbsolute !== "undefined") {
          modifiedSchema.subtotalMenu.tax.amount = taxAbsolute;
        }

        // format -----------------------------------------
        if (typeof discountFormat !== "undefined") {
          modifiedSchema.subtotalMenu.discount.format = discountFormat;
        }
        if (typeof feeFormat !== "undefined") {
          modifiedSchema.subtotalMenu.fee.format = feeFormat;
        }
        if (typeof taxFormat !== "undefined") {
          modifiedSchema.subtotalMenu.tax.format = taxFormat;
        }

        // show -----------------------------------------
        if (typeof discountShow !== "undefined") {
          modifiedSchema.subtotalMenu.discount.show2Rec = discountShow;
        }
        if (typeof feeShow !== "undefined") {
          modifiedSchema.subtotalMenu.fee.show2Rec = feeShow;
        }
        if (typeof taxShow !== "undefined") {
          modifiedSchema.subtotalMenu.tax.show2Rec = taxShow;
        }

        // # here we get the new grand total
        modifiedSchema.subtotalMenu = getTableGrandTotal({
          schema: modifiedSchema,
        });
        console.log("modifiedSchema", modifiedSchema.subtotalMenu);
        // $ here we get the new grand total

        // setting schema list ------------------------------------------------
        // const schemasList: SchemaForUI[][] = structuredClone(
        //   designerState.schemasList
        // );

        // let newSchemasList: SchemaForUI[][] = schemasList.map((page) =>
        //   page.map((schema) => {
        //     if (schema.id === designerState.quoteBuilder.schemaId) {
        //       return modifiedSchema as SchemaForUI;
        //     }
        //     return schema;
        //   })
        // );

        // setDesignerState((prev) => ({ ...prev, schemasList: newSchemasList }));
        // setting schema list ------------------------------------------------

        // # new system
        setDesignerState((prev) => ({
          ...prev,
          quoteBuilder: { ...prev.quoteBuilder, activeTable: modifiedSchema },
        }));
      }
    }
  }

  function handleHeaderAndDescription({
    header,
    description,
    idx,
  }: {
    header?: string;
    description?: string;
    idx: number;
  }) {
    console.log({ header, description, idx });
    let modifiedSchema = designerState?.quoteBuilder?.activeTable;
    if (modifiedSchema) {
      let newRows = [...(modifiedSchema?.rows || [])];

      // console.log({ qty, price });

      // idx is the row number
      // row 1 will always contain price
      if (typeof header != "undefined") {
        newRows[idx][0] = {
          ...newRows[idx][0],
          header: header || newRows[idx][0].header,
        };
      }

      if (typeof description != "undefined") {
        newRows[idx][0] = {
          ...newRows[idx][0],
          description: description || newRows[idx][0].description,
        };
      }

      modifiedSchema.rows = newRows;
      setNewRows({ newRows });
    }
  }

  // function getActiveTable() {
  //   designerState?.schemasList?.forEach((page) => {
  //     page.forEach((schema) => {
  //       if (
  //         schema.id === designerState?.quoteBuilder?.schemaId &&
  //         isTableSchema(schema)
  //       ) {
  //         setCurrency(schema.subtotalMenu?.currency || "");
  //         setActiveTable(schema);
  //       }
  //     });
  //   });
  // }

  // useEffect(() => {
  //   getActiveTable();
  // }, [designerState]);

  function getCurrency() {
    if (designerState.quoteBuilder.activeTable) {
      setCurrency(
        designerState.quoteBuilder.activeTable?.subtotalMenu?.currency ||
          getDefaultCurrency()
      );
    }
  }

  useEffect(() => {
    getCurrency();
  }, [designerState.quoteBuilder]);

  return (
    // 56 px because the top navbar is 56 px
    <div className="p-4 flex max-h-[calc(100%_-_56px)] h-[calc(100%_-_56px)] gap-4 ">
      <div
        className="rounded-lg bg-white shadow-md text-gray-600 text-xs py-4 w-[70%] h-fit max-h-[100%] overflow-y-auto"
        id="mini-pdf-custom-scroll"
      >
        <p className="text-base font-semibold text-black px-5 mb-4">Products</p>
        <div className="py-1.5 px-5 flex items-center justify-between">
          <div className="w-[65%] text-sm flex gap-2">
            <div className="w-[40px]"></div>
            Name
          </div>
          <div className="w-[10%] text-sm text-right px-1">Price</div>
          <div className="w-[10%] text-sm text-right px-1">Quantity</div>
          <div className="w-[10%] text-sm text-right">Subtotal</div>
          <div className="w-[20px]"></div>
        </div>
        {designerState?.quoteBuilder?.activeTable?.rows?.map((row, idx) => {
          return (
            <div className="border-t px-5 flex items-center justify-between min-h-[54px] h-[54px]">
              {/* row of 0 will always contain product data/description */}
              {/* row of 1 will always contain product price */}
              {/* row of 2 will always contain product qty */}
              {/* row of 3 will always contain product subtotal */}
              <div className="flex gap-2 items-center w-[65%] h-[100%]">
                <div className="w-[40px] overflow-hidden flex items-center justify-center">
                  {row?.[0]?.images?.[0] ? (
                    <img
                      src={row?.[0]?.images?.[0]}
                      className="max-h-[40px] max-w-[40px] h-[40px] w-[40px] object-fill rounded"
                    />
                  ) : (
                    <div className="h-[40px] w-[40px] bg-gray-300 text-white rounded flex items-center justify-center">
                      <MdImage size={25} />
                    </div>
                    // <UploadImage />
                  )}
                </div>
                <div className="flex flex-col max-w-[calc(100%_-_50px)] w-[calc(100%_-_50px)] h-[100%]">
                  {/* <div className="font-semibold text-sm">
                    {row?.[0]?.header}
                  </div> */}

                  <InputFieldText
                    initial={row?.[0]?.header || ""}
                    argType={"header"}
                    cb={({ value }) => {
                      handleHeaderAndDescription({ idx, header: value });
                    }}
                    className="font-semibold text-sm w-full h-[60%] items-end pt-1"
                  />
                  <InputFieldText
                    initial={row?.[0]?.description || ""}
                    argType={"header"}
                    cb={({ value }) => {
                      handleHeaderAndDescription({ idx, description: value });
                    }}
                    className="truncate w-full h-[40%] items-start text-xs"
                  />
                  {/* <div className="truncate">{row?.[0]?.description}</div> */}
                </div>
              </div>
              {/* <input
                value={row?.[1]?.data}
                className="w-[10%] text-sm text-right min-h-[54px] flex items-center justify-end px-1 outline-none border-2 border-transparent focus:border-primary hover:border-secondary"
              /> */}
              <InputField
                initial={row?.[1]?.price || 0}
                currency={currency}
                argType={"price"}
                cb={({ value }) => {
                  handleQtyAndPrice({ idx, price: value, currency });
                }}
              />

              {/* <div className="w-[10%] text-sm text-right px-1">
                {row?.[2]?.data}
              </div> */}
              <InputField
                initial={row?.[2]?.qty || 0}
                currency={currency}
                argType={"qty"}
                cb={({ value }) => {
                  handleQtyAndPrice({ idx, qty: value, currency });
                }}
              />
              <div className="w-[10%] text-sm text-right my-2">
                {row?.[3]?.data}
              </div>

              <div className="w-[16px] mx-1 flex items-center">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <div
                      className="min-w-[16px] mx-1 rounded hover:bg-gray-200 py-1.5 cursor-pointer flex items-center text-center"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <BsThreeDotsVertical size={16} />
                    </div>
                  </DropdownMenuTrigger>

                  <DropdownMenuGroup>
                    <DropdownMenuContent
                      align="end"
                      sideOffset={10}
                      className=""
                    >
                      {/* <DropdownMenuItem disabled onClick={(e) => {}}>
                        <div className="flex items-center text-xs">
                          <MdEdit className="mr-2" /> Edit
                        </div>
                      </DropdownMenuItem> */}
                      <DropdownMenuItem
                        onClick={(e) => {
                          removeProduct({ idx });
                        }}
                      >
                        <div className="flex items-center text-xs text-red-600">
                          <MdDelete className="mr-2" /> Delete
                        </div>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenuGroup>
                </DropdownMenu>
              </div>
            </div>
          );
        })}
        <div className="border-t"></div>
        <div className="px-5 pt-6 flex gap-2">
          <BoloButton
            variant={"secondary"}
            className="flex gap-2 items-center"
            size={"xs"}
            onClick={() => {
              addProduct({
                header: "Product Header",
                description: "Product Description",
                images: [],
                price: 0,
                qty: 1,
                currency,
                idx: designerState.quoteBuilder.activeTable?.rows.length
                  ? designerState.quoteBuilder.activeTable?.rows.length
                  : 0,
              });
            }}
          >
            <FaPlus />
            Add Product
          </BoloButton>
          <ProductAdderModal
            designerState={designerState}
            setDesignerState={setDesignerState}
            addMultipleProducts={addMultipleProducts}
          />
        </div>
      </div>
      {/* grand total div */}
      <div
        className="rounded-lg bg-white shadow-md text-gray-600 text-xs py-4 w-[30%] h-fit max-h-[100%] overflow-y-auto"
        id="mini-pdf-custom-scroll"
      >
        <div className="flex justify-between items-center">
          <p className="text-base font-semibold text-black px-5 mb-4">
            Grand Total
          </p>
          <div className="px-5 flex items-center mb-4">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div
                  className="min-w-[16px] mx-1 rounded hover:bg-gray-200 py-1.5 cursor-pointer flex items-center text-center"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <BsThreeDotsVertical size={16} />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuGroup>
                <DropdownMenuContent
                  align="end"
                  sideOffset={15}
                  className="w-[350px]"
                  id="mini-pdf-custom-scroll"
                >
                  <DropdownMenuItem
                    onClick={() => {
                      //  setSearchData({ pageNo_: idx + 1 })
                    }}
                  >
                    <div className="flex items-end text-xs justify-between w-full">
                      <div className="min-w-[40%] mb-1.5">Change Currency</div>
                      <Select
                        required
                        value={
                          designerState?.quoteBuilder?.activeTable?.subtotalMenu
                            ?.currency
                        }
                        onValueChange={(value) => {
                          handleCurrency(value);
                        }}
                      >
                        <SelectTrigger className="w-[350px] px-0 pb-0">
                          <SelectValue placeholder="Select Category" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {BOLO_CURRENCIES?.map((cur, idx) => {
                              return (
                                <SelectItem value={cur.code}>
                                  <div className="flex gap-2 text-gray-500 text-xs max-w-[150px]">
                                    <div className="font-semibold pr-2 min-w-[32px] max-w-[32px]">
                                      {cur.code}
                                    </div>
                                    <div className=" px-2 truncate min-w-[110px]">
                                      {cur.currency}
                                    </div>
                                  </div>
                                </SelectItem>
                              );
                            })}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenuGroup>
            </DropdownMenu>
          </div>
        </div>
        {designerState?.quoteBuilder?.activeTable && (
          <>
            <div className="text-sm px-5">
              <div className="min-h-[35px] flex justify-between items-center">
                <div>Subtotal</div>
                <div className="px-1 mr-4">
                  {
                    designerState?.quoteBuilder?.activeTable?.subtotalMenu
                      ?.subtotal?.data
                  }
                </div>
              </div>
              <div className="min-h-[35px] flex justify-between items-center">
                <div className="flex items-center gap-2">
                  {!designerState?.quoteBuilder?.activeTable?.subtotalMenu
                    ?.discount?.show2Rec && <FaRegEyeSlash />}
                  Discount
                </div>
                <div className="flex items-center w-[50%]">
                  <InputField
                    initial={
                      designerState?.quoteBuilder?.activeTable?.subtotalMenu
                        ?.discount?.format === "absolute"
                        ? designerState?.quoteBuilder?.activeTable?.subtotalMenu
                            ?.discount.amount || 0
                        : designerState?.quoteBuilder?.activeTable?.subtotalMenu
                            ?.discount.percentage || 0
                    }
                    argType={
                      designerState?.quoteBuilder?.activeTable?.subtotalMenu
                        ?.discount?.format === "absolute"
                        ? "price"
                        : "percent"
                    }
                    currency={currency}
                    cb={({ value }) => {
                      if (
                        designerState?.quoteBuilder?.activeTable?.subtotalMenu
                          ?.discount?.format === "absolute"
                      ) {
                        handleSubTotalMenu({ discountAbsolute: value });
                      }
                      if (
                        designerState?.quoteBuilder?.activeTable?.subtotalMenu
                          ?.discount?.format === "percent"
                      ) {
                        handleSubTotalMenu({ discountPercent: value });
                      }
                    }}
                    className="w-[calc(100%_-_16px)] min-h-[35px]"
                  />
                  <div className="flex items-center justify-center p-0 w-4 min-h-[35px] rounded hover:bg-gray-100 cursor-pointer">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <div
                          className="min-w-[16px] mx-1 rounded hover:bg-gray-200 py-1.5 cursor-pointer flex items-center text-center"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <BsThreeDotsVertical size={16} />
                        </div>
                      </DropdownMenuTrigger>

                      <DropdownMenuGroup>
                        <DropdownMenuContent
                          align="end"
                          sideOffset={10}
                          className="w-[350px]"
                        >
                          <DropdownMenuLabel>Discount</DropdownMenuLabel>
                          <DropdownMenuItem onClick={(e) => {}}>
                            <div className="flex items-end text-xs justify-between w-full">
                              <div className="min-w-[40%] mb-1.5">
                                Number Format
                              </div>
                              <Select
                                required
                                value={
                                  designerState?.quoteBuilder?.activeTable
                                    ?.subtotalMenu?.discount?.format
                                }
                                onValueChange={(value) => {
                                  if (
                                    value === "percent" ||
                                    value === "absolute"
                                  ) {
                                    handleSubTotalMenu({
                                      discountFormat: value,
                                    });
                                  }
                                }}
                              >
                                <SelectTrigger className="w-full px-0 pb-0">
                                  <SelectValue placeholder="Select Category" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="percent">
                                      <p className="text-gray-500 text-xs">
                                        Percent %
                                      </p>
                                    </SelectItem>
                                    <SelectItem value="absolute">
                                      <p className="text-gray-500 text-xs">
                                        Currency{" "}
                                        {getSymbolFromCurrency(
                                          designerState?.quoteBuilder
                                            ?.activeTable?.subtotalMenu
                                            ?.currency || ""
                                        )}
                                      </p>
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </div>
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <div className="flex items-center text-xs justify-between w-full">
                              <div className="min-w-[40%]">
                                Show to Recipient
                              </div>
                              <div>
                                <BoloRadioButtonMui
                                  checked={
                                    designerState?.quoteBuilder?.activeTable
                                      ?.subtotalMenu?.discount?.show2Rec
                                  }
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      width: 20,
                                      height: 20, // Adjust size of the thumb
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSubTotalMenu({
                                      discountShow:
                                        !designerState?.quoteBuilder
                                          ?.activeTable?.subtotalMenu?.discount
                                          ?.show2Rec,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenuGroup>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
              <div className="min-h-[35px] flex justify-between items-center">
                <div className="flex items-center gap-2">
                  {!designerState?.quoteBuilder?.activeTable?.subtotalMenu?.fee
                    ?.show2Rec && <FaRegEyeSlash />}
                  Fee
                </div>
                <div className="flex items-center w-[50%]">
                  <InputField
                    initial={
                      designerState?.quoteBuilder?.activeTable?.subtotalMenu
                        ?.fee?.format === "absolute"
                        ? designerState?.quoteBuilder?.activeTable?.subtotalMenu
                            ?.fee.amount || 0
                        : designerState?.quoteBuilder?.activeTable?.subtotalMenu
                            ?.fee.percentage || 0
                    }
                    argType={
                      designerState?.quoteBuilder?.activeTable?.subtotalMenu
                        ?.fee?.format === "absolute"
                        ? "price"
                        : "percent"
                    }
                    currency={currency}
                    cb={({ value }) => {
                      if (
                        designerState?.quoteBuilder?.activeTable?.subtotalMenu
                          ?.fee?.format === "absolute"
                      ) {
                        handleSubTotalMenu({ feeAbsolute: value });
                      }
                      if (
                        designerState?.quoteBuilder?.activeTable?.subtotalMenu
                          ?.fee?.format === "percent"
                      ) {
                        handleSubTotalMenu({ feePercent: value });
                      }
                    }}
                    className="w-[calc(100%_-_16px)] min-h-[35px]"
                  />
                  <div className="flex items-center justify-center p-0 w-4 min-h-[35px] rounded hover:bg-gray-100 cursor-pointer">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <div
                          className="min-w-[16px] mx-1 rounded hover:bg-gray-200 py-1.5 cursor-pointer flex items-center text-center"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <BsThreeDotsVertical size={16} />
                        </div>
                      </DropdownMenuTrigger>

                      <DropdownMenuGroup>
                        <DropdownMenuContent
                          align="end"
                          sideOffset={10}
                          className="w-[350px]"
                        >
                          <DropdownMenuLabel>Fee</DropdownMenuLabel>
                          <DropdownMenuItem onClick={(e) => {}}>
                            <div className="flex items-end text-xs justify-between w-full">
                              <div className="min-w-[40%] mb-1.5">
                                Number Format
                              </div>
                              <Select
                                required
                                value={
                                  designerState?.quoteBuilder?.activeTable
                                    ?.subtotalMenu?.fee?.format
                                }
                                onValueChange={(value) => {
                                  if (
                                    value === "percent" ||
                                    value === "absolute"
                                  ) {
                                    handleSubTotalMenu({
                                      feeFormat: value,
                                    });
                                  }
                                }}
                              >
                                <SelectTrigger className="w-full px-0 pb-0">
                                  <SelectValue placeholder="Select Category" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="percent">
                                      <p className="text-gray-500 text-xs">
                                        Percent %
                                      </p>
                                    </SelectItem>
                                    <SelectItem value="absolute">
                                      <p className="text-gray-500 text-xs">
                                        Currency{" "}
                                        {getSymbolFromCurrency(
                                          designerState?.quoteBuilder
                                            ?.activeTable?.subtotalMenu
                                            ?.currency || ""
                                        )}
                                      </p>
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </div>
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <div className="flex items-center text-xs justify-between w-full">
                              <div className="min-w-[40%]">
                                Show to Recipient
                              </div>
                              <div>
                                <BoloRadioButtonMui
                                  checked={
                                    designerState?.quoteBuilder?.activeTable
                                      ?.subtotalMenu?.fee?.show2Rec
                                  }
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      width: 20,
                                      height: 20, // Adjust size of the thumb
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSubTotalMenu({
                                      feeShow:
                                        !designerState?.quoteBuilder
                                          ?.activeTable?.subtotalMenu?.fee
                                          ?.show2Rec,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenuGroup>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
              <div className="min-h-[35px] flex justify-between items-center">
                <div className="flex items-center gap-2">
                  {!designerState?.quoteBuilder?.activeTable?.subtotalMenu?.tax
                    ?.show2Rec && <FaRegEyeSlash />}
                  Tax
                </div>
                <div className="flex items-center w-[50%]">
                  <InputField
                    initial={
                      designerState?.quoteBuilder?.activeTable?.subtotalMenu
                        ?.tax?.format === "absolute"
                        ? designerState?.quoteBuilder?.activeTable?.subtotalMenu
                            ?.tax.amount || 0
                        : designerState?.quoteBuilder?.activeTable?.subtotalMenu
                            ?.tax.percentage || 0
                    }
                    argType={
                      designerState?.quoteBuilder?.activeTable?.subtotalMenu
                        ?.tax?.format === "absolute"
                        ? "price"
                        : "percent"
                    }
                    currency={currency}
                    cb={({ value }) => {
                      if (
                        designerState?.quoteBuilder?.activeTable?.subtotalMenu
                          ?.tax?.format === "absolute"
                      ) {
                        handleSubTotalMenu({ taxAbsolute: value });
                      }
                      if (
                        designerState?.quoteBuilder?.activeTable?.subtotalMenu
                          ?.tax?.format === "percent"
                      ) {
                        handleSubTotalMenu({ taxPercent: value });
                      }
                    }}
                    className="w-[calc(100%_-_16px)] min-h-[35px]"
                  />
                  <div className="flex items-center justify-center p-0 w-4 min-h-[35px] rounded hover:bg-gray-100 cursor-pointer">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <div
                          className="min-w-[16px] mx-1 rounded hover:bg-gray-200 py-1.5 cursor-pointer flex items-center text-center"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <BsThreeDotsVertical size={16} />
                        </div>
                      </DropdownMenuTrigger>

                      <DropdownMenuGroup>
                        <DropdownMenuContent
                          align="end"
                          sideOffset={10}
                          className="w-[350px]"
                        >
                          <DropdownMenuLabel>Tax</DropdownMenuLabel>
                          <DropdownMenuItem onClick={(e) => {}}>
                            <div className="flex items-end text-xs justify-between w-full">
                              <div className="min-w-[40%] mb-1.5">
                                Number Format
                              </div>
                              <Select
                                required
                                value={
                                  designerState?.quoteBuilder?.activeTable
                                    ?.subtotalMenu?.tax?.format
                                }
                                onValueChange={(value) => {
                                  if (
                                    value === "percent" ||
                                    value === "absolute"
                                  ) {
                                    handleSubTotalMenu({
                                      taxFormat: value,
                                    });
                                  }
                                }}
                              >
                                <SelectTrigger className="w-full px-0 pb-0">
                                  <SelectValue placeholder="Select Category" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="percent">
                                      <p className="text-gray-500 text-xs">
                                        Percent %
                                      </p>
                                    </SelectItem>
                                    <SelectItem value="absolute">
                                      <p className="text-gray-500 text-xs">
                                        Currency{" "}
                                        {getSymbolFromCurrency(
                                          designerState?.quoteBuilder
                                            ?.activeTable?.subtotalMenu
                                            ?.currency || ""
                                        )}
                                      </p>
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </div>
                          </DropdownMenuItem>
                          <DropdownMenuItem>
                            <div className="flex items-center text-xs justify-between w-full">
                              <div className="min-w-[40%]">
                                Show to Recipient
                              </div>
                              <div>
                                <BoloRadioButtonMui
                                  checked={
                                    designerState?.quoteBuilder?.activeTable
                                      ?.subtotalMenu?.tax?.show2Rec
                                  }
                                  sx={{
                                    "& .MuiSwitch-thumb": {
                                      width: 20,
                                      height: 20, // Adjust size of the thumb
                                    },
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleSubTotalMenu({
                                      taxShow:
                                        !designerState?.quoteBuilder
                                          ?.activeTable?.subtotalMenu?.tax
                                          ?.show2Rec,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenuGroup>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
              <div className="min-h-[35px] flex justify-between items-center mt-4 font-semibold text-gray-800">
                <div>Total</div>
                <div className="px-1 mr-4">
                  {
                    designerState?.quoteBuilder?.activeTable?.subtotalMenu
                      ?.total?.data
                  }
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuoteBuilder;
