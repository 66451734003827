//@ts-nocheck
import React from "react";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import QuestionBottomOptions from "../../QuestionBottomOptions";
import QuestionInput from "../../QuestionInput";
import QuestionDesc from "../../QuestionDesc";
import ImageQuestionPreview from "../../ImageQuestionPreview";
import ResponseValidation from "../../ResponseValidation";

const LongText = ({ data }) => {
  let {
    question,
    setFormState,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
    formState,
    schemas,
  } = data;
  const isEditor =
    questionIndex.p === activeState.pageIndex &&
    questionIndex.q === activeState.questionIndex;
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <QuestionInput
            helpers={{
              question,
              setFormState,
              isEditor,
              questionIndex,
              activeState,
            }}
          />
        </div>

        <QuestionDesc
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
      </div>
      <ImageQuestionPreview
        helpers={{ question, setFormState, questionIndex, activeState }}
      />
      <input
        type="text"
        className="w-1/2 mt-1 mb-10 py-1 text-sm text-[#70757a] border-b border-dotted border-gray-400 outline-none"
        disabled
        defaultValue={"Long answer text"}
      />
      {isEditor && question?.responseValidation && (
        <ResponseValidation
          helpers={{
            question,
            setFormState,
            questionIndex,
            activeState,
            setActiveState,
            setActive,
            formState,
            options: ["length", "regularExpression"],
          }}
        />
      )}
      <QuestionBottomOptions
        helpers={{
          question,
          setFormState,
          questionIndex,
          activeState,
          setActiveState,
          setActive,
          formState,
          schemas,
        }}
      />
    </div>
  );
};

export default LongText;
