import React, { useEffect, useState } from "react";
import { getOnwerFromDB } from "../../utils";

// !note cannot export any function to utils as utils is imported

const useOwnerLocal = (deps: any[]) => {
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState<any>(
    JSON.parse(localStorage.getItem("owner") || "null")
  );

  const handleOwner = async () => {
    setLoading(true);
    try {
      let newOwner = await getOnwerFromDB();
      // console.log("use Owner Local triggered");
      if (newOwner) {
        setOwner(owner);
        localStorage.setItem("owner", JSON.stringify(newOwner));
      }
    } catch (error) {
      console.log("some error in handleOwner");
    }
    setLoading(false);
  };

  useEffect(() => {
    handleOwner();
  }, deps);

  return { loading, owner };
};

export default useOwnerLocal;
