import React, { useState, useEffect } from "react";
import {
  BsFillPersonFill,
  BsCheckCircleFill,
  BsDashLg,
  BsPersonWorkspace,
} from "react-icons/bs";
import { FaSignature } from "react-icons/fa";
import "./index.css";

import axios from "axios";
import { PAYMENTS_BASE_URL } from "../../constants/constants";
import { CgOrganisation } from "react-icons/cg";
import { FaCheck } from "react-icons/fa6";
import MixPanel from "../../utils/services/mixpanel";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Tracking from "../../utils/tracking";

const SingleCardContent = ({
  title,
  description,
  priceSymbol,
  gimmickPrice, // line through this price!
  price,
  extraText,
  priceDescription,
  seat,
  maxSeatAllowed,
  minSeatAllowed,
  additionalSeatPrice,
  signatures,
  features,
  isAnnualBilling,
  isIndianPrice,
  owner,
  setImagePopup = () => {},
}) => {
  const navigate = useNavigate();
  const [billPrice, setBillPrice] = useState();
  const [gimmickBillPrice, setGimmickBillPrice] = useState();
  const [count, setCount] = useState(minSeatAllowed ?? 1); // Initial count value

  useEffect(() => {
    setBillPrice(+price);
    setGimmickBillPrice(+gimmickPrice);
  }, [price, count, gimmickPrice]);

  const increment = () => {
    if (count < maxSeatAllowed) {
      setCount(count + 1);
    }
  };
  const decrement = () => {
    if (count > minSeatAllowed) {
      setCount(count - 1);
    }
  };

  const PRICE_IDS = {
    Freelancer: {
      monthly: "price_1OUqsgLiGbRu8waGk8eObdee",
      annually: "price_1Pm6c8LiGbRu8waGnOLvuz9X",
    },
    Team: {
      monthly: "price_1Prwz6LiGbRu8waGHZVC9vlT",
      annually: "price_1PryxwLiGbRu8waGJRAgEtfv",
    },
    "Growing Business": {
      monthly: "price_1PrwyWLiGbRu8waG4z6iibAA",
      annually: "price_1PryznLiGbRu8waGelr3LUcb",
    },
  };

  let createStripeSubscription = async (title) => {
    try {
      let priceId;
      // if (title === "Team") {
      //   priceId = isAnnualBilling
      //     ? "price_1O3fdXLiGbRu8waGkqtGYUpp"
      //     : "price_1O1SAMLiGbRu8waGjyVrxCLw";
      // } else if (title === "Freelancer") {
      //   priceId = isAnnualBilling
      //     ? "price_1O1tgsLiGbRu8waGpOA1j0Lf"
      //     : "price_1O1tgsLiGbRu8waGxW3KCrrj";
      // } else if (title === "Growing Business") {
      //   priceId = isAnnualBilling
      //     ? "price_1O1nD8LiGbRu8waG0TzwbNFV"
      //     : "price_1O1nD8LiGbRu8waGeuMhbRQB";
      // }

      const durationKey = isAnnualBilling ? "annually" : "monthly";
      priceId = PRICE_IDS?.[title]?.[durationKey];

      // let quantity = title === "Freelancer" ? 1 : count;
      let quantity = count;

      let { data } = await axios.post(
        `${PAYMENTS_BASE_URL}/createStripeSubscriptionUS`,
        {
          priceId: priceId,
          quantity: quantity,
          ownerEmail: owner?.email,
        }
      );
      let { sessionUrl } = data;

      new Tracking(owner?.email).trackInitiateCheckout(
        title,
        priceId,
        quantity,
        isAnnualBilling ? 12 * price * quantity : price * quantity
      );

      window.open(sessionUrl, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`singleCardContainer flex-1 h-[1200px] min-w-[230px] w-full border ${
        title === "Team" ? "bg-primary/10" : "bg-white"
      } ${title === "Growing Business" && "rounded-r-md"} ${
        title === "Forever Free" && "rounded-l-md"
      }`}
    >
      <div className={`h-[100%] flex flex-col items-start p-4`}>
        <div className="mt-2 w-full font-medium flex flex-col justify-center">
          <h2 className="text-xl font-semibold text-gray-700">{title}</h2>
          <p className="mt-2 text-xs text-gray-400">{description}</p>
        </div>

        <div className="flex flex-col mt-6 justify-start">
          <div className={`mt-8 relative`}>
            <span className="text-4xl font-extrabold white">
              {priceSymbol}
              {title == "Growing Business"
                ? title === "Forever Free"
                  ? +billPrice
                  : +billPrice + additionalSeatPrice * (count - 1)
                : +billPrice * count}
            </span>
            <span className="text-base font-medium text-gray-500">
              /mo
              <br />
            </span>
            <p className="text-sm pt-2">
              {isAnnualBilling ? "billed annually" : ""}
            </p>

            {/* {extraText && (
              <p className="bg-green-600 text-white w-[100px] text-center text-xs font-bold px-3 py-1 rounded-full absolute top-0 right-0">
                {extraText}
              </p>
            )} */}
          </div>

          {/* <div className="flex gap-4">
            {title === "Freelancer" && (
              <div className="flex items-end relative">
                <BsDashLg
                  className="absolute text-red-600 transform -rotate-45 "
                  size={60}
                  style={{ top: "-13px" }}
                />
                <p className="text-md font-semibold mr-1">{priceSymbol}</p>
                <p className={`text-xl font-bold opacity-90`}>
                  {title !== "Freelancer"
                    ? +gimmickBillPrice
                    : +gimmickBillPrice * count}
                </p>
              </div>
            )}
            <div className="flex items-end relative">
              <p className="text-md font-semibold mr-1">{priceSymbol}</p>

              <p className="text-2xl font-bold">
                {title == "Growing Business"
                  ? title === "Forever Free"
                    ? +billPrice
                    : +billPrice + additionalSeatPrice * (count - 1)
                  : +billPrice * count}
              </p>
              <span className="text-xs text-gray-500 ml-1">
                /mo
              </span>
              {extraText && (
                <div className="absolute flex items-center justify-center top-[-10px] right-[-50px] rounded-3xl bg-green-700 text-white px-2 text-[10px] font-medium">
                  {extraText}
                </div>
              )}
            </div>
          </div>
          <p className="text-xs text-gray-600 mt-2 h-[20px] text-center w-full">
            {priceDescription}
          </p> */}
        </div>

        <div className={`flex flex-col my-12 justify-center items-start`}>
          <div
            className={`flex items-center justify-center h-[25px] ${
              title === "Forever Free" ? "invisible" : ""
            }`}
          >
            {/* {title === "Growing Business" ? (
              <CgOrganisation className="text-gray-600 mr-1" />
            ) : (
              <BsFillPersonFill className="text-gray-600 mr-1" />
            )} */}

            <div className="text-sm flex font-medium w-full">
              {/* hiding the counter for workspace for now!! */}

              {seat === "countable" ? (
                <div
                  className={`flex items-center rounded-md ${
                    title === "Team" ? "bg-white" : "bg-purple-100"
                  }`}
                >
                  <button
                    className={`text-gray-700 px-3 py-1.5 border-r ${
                      title === "Team" ? "border-purple-100" : "border-white"
                    } ${
                      minSeatAllowed &&
                      count === minSeatAllowed &&
                      "cursor-not-allowed"
                    } `}
                    onClick={decrement}
                  >
                    <FiMinus />
                  </button>
                  <div className="px-3 py-1.5 w-fit font-semibold text-[10px] text-gray-500 outline-none text-center">
                    {title == "Growing Business"
                      ? `${count} Workspace`
                      : `${count} Pack`}
                  </div>

                  <button
                    className={`text-gray-700 px-3 py-1.5 border-l ${
                      title === "Team" ? "border-purple-100" : "border-white"
                    } ${
                      maxSeatAllowed &&
                      count === maxSeatAllowed &&
                      "cursor-not-allowed"
                    } `}
                    onClick={increment}
                  >
                    <FiPlus />
                  </button>
                </div>
              ) : (
                seat
              )}
            </div>
          </div>
          <div className="mt-4 flex items-center justify-center h-[40px] w-full">
            <FaSignature className="text-gray-600 mr-2" />
            <p className="text-xs font-medium">
              {title == "Growing Business"
                ? "Unlimited Form Signatures"
                : `${
                    // title != "Freelancer" ? signatures :
                    signatures * count
                  } form signatures /mo`}
            </p>
          </div>
          {/* {(title === "Growing Business" || title === "Team") && (
            <div className="flex items-start -mt-[24px]">
              <BsFillPersonFill className="text-gray-600 mr-1 mt-[2px]" />
              <p className="text-sm font-medium">Unlimited users forever</p>
            </div>
          )} */}
        </div>

        <button
          // className={` w-[95%] self-center px-4 py-2 rounded transition duration-300 ease-in-out ${
          //   title === "Team"
          //     ? "bg-primary hover:bg-primary/80 text-white"
          //     : "border-2 border-primary hover:bg-primary/80 hover:text-white"
          // }
          //   ${title === "Forever Free" ? "invisible" : ""}`}
          className={`${
            title === "Team"
              ? "bg-primary text-white hover:border-primary"
              : "text-primary bg-primary/20 hover:text-white "
          }  border-2 block w-full py-1.5 text-sm font-medium text-center  rounded-md  hover:bg-primary`}
          onClick={() => {
            if (title === "Forever Free") {
              navigate("/create");
              return;
            }

            console.log("total", billPrice * count);
            new MixPanel().track("Billing", {
              billing_action: "billing_click_subscribe",
              plan: title,
              pack: count,
              planCycle: isAnnualBilling ? "Annually" : "Monthly",
              // TODO: add afterwards
              // amount: billPrice * count,
            });
            new MixPanel().increment("billing_click_subscribe");

            createStripeSubscription(title);
          }}
        >
          {title === "Forever Free" ? "Get Started" : "Subscribe"}
        </button>

        {/* <p className="text-sm font-medium mt-8 mb-3">You get to enjoy...</p> */}
        <div className="flex flex-col mt-8">
          {features.map((feature, index) => (
            <div key={index} className="flex my-2 items-center">
              <span className="mr-3">
                <BsCheckCircleFill
                  className={`text-sm ${
                    index == 0 ? "text-green-500" : "text-green-500"
                  }`}
                />
              </span>
              <span
                className={`text-gray-600 leading-loose ${
                  index == 0 ? "font-bold text-xs" : "text-xs font-medium"
                } ${
                  feature?.image
                    ? "underline underline-offset-2 decoration-dotted cursor-pointer"
                    : ""
                }`}
                onClick={() => setImagePopup(feature?.image)}
              >
                {typeof feature === "string" ? feature : feature?.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleCardContent;
