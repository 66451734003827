// @ts-nocheck

import React, { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineDownload, AiOutlineClockCircle } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { FaInfoCircle } from "react-icons/fa";
import {
  MdClose,
  MdKeyboardArrowDown,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdRemoveRedEye,
  MdOutlineDownloadDone,
  MdOutlinePending,
  MdOutlineDoneAll,
  MdAccountCircle,
  MdOutlineVisibility,
  MdOutlineDownload,
} from "react-icons/md";
import { useParams, useSearchParams } from "react-router-dom";
import { useTable } from "react-table";
import { v4 } from "uuid";
import ColumnsShowHide from "../../components/ColumnsShowHide2";
import axios from "../../utils/axios";
import "./index.css";
// import ApprovalFlowTimeline from "../../components/ApprovalFlowTimeline";
import ColumnOptions from "../../components/ColumnOptions";
import DateRangeFilter from "../../components/DateRangePicker/DateRangeFilter";
import Modal from "../../components/Modal";
// import BulkSubmitModal from "../../components/BulkSubmitModal";
// import { connect } from "react-redux";
import { Slide, toast, ToastContainer } from "react-toastify";

import PlanExhaustedNavBanner from "../../components/PlanExhaustedNavBanner";
// import PlanExaustedBanner from "../../components/PlanExaustedBanner";
import { Template, generate } from "@pdfme/generator";
// import generate from "../../pdfme/generator/src/generate";
import download from "downloadjs";
import HistoryModal from "../../components/Ui/HistoryModal";
import { Tooltip } from "react-tooltip";
import { BsThreeDots } from "react-icons/bs";

import { HiDownload } from "react-icons/hi";
import JSZip from "jszip";
import SendACopy from "../../components/Ui/SendACopy";
import SigningOrderDiagram from "../../components/SigningOrderDiagram";

import { isFeatureEnabled } from "../../utils/paidFeat";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import DropDownActionOptions from "../../components/PdfTemplateRespondents/DropDownActionOptions";
import ShareViaLink from "../../components/ShareViaLink";
import SigningInPerson from "../../components/SigningInPerson";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import getS3PreSignedUrl from "../../utils/getS3PreSignedUrl";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import GenerateLinksModal from "../../components/GenerateLinks";
import { getStatusByPayment } from "../../utils/quoteBuilder";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/Common/BoloSelect/BoloSelect";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { generateShadesAndTints, getTintFromHex } from "../../utils/uiUtils";
import DashboardFilter from "../../components/DashboardFilter/DashboardFilter";
import { IoSearch } from "react-icons/io5";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import { fetchAllPdfTemplateResponses } from "../../redux/apis/dashboards";
import DashboardBottomNav from "../../components/DashboardBottomNav/DashboardBottomNav";
import BoloCheckboxMui from "../../components/Common/Checkbox";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import { IoMdInformationCircle } from "react-icons/io";
import { downloadBase64File } from "../../utils/localPdfUtils";

const PdfTemplateRespondents = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const dispatch = useAppDispatch();
  const columnHideRef = useRef();
  const approvalTimelineRef = useRef();
  const searchSuggestRef = useRef();
  const dateFilterRef = useRef();
  const moreOptionsRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  // is searchParams.get("page") is null then set it to 1
  let [respondentPageNo, setRespondentPageNo] = useState(
    Number(searchParams.get("page")) || 1
  );
  let currentUserEmail = localStorage.getItem("currentUserEmail");
  // @ts-ignore
  const PAGE_LIMIT = 10;
  // let respondentFilter = localStorage.getItem("respondentFilter") ?? "all";
  const whichAdmin = searchParams.get("whichAdmin");
  const params = useParams();
  let documentId = params?.documentId ?? searchParams.get("documentId");

  const { getSearchData, setSearchData, isFilter } = useQueryHook();

  // let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [respondentHistory, setRespondentsHistory] = useState([]);
  const [respondentForHistory, setRespondentForHistory] = useState([]);
  const [respondentStatus, setRespondentStatus] = useState("");
  const [collaborator, setCollaborator] = useState({});

  const [bulkResponsePdfDownloading, setBulkResponsePdfDownloading] =
    useState(false);
  const [isDeleted, setIsDeleted] = useState("");

  const [allRespondents, setRespondents] = useState([]);
  const [requestStatusCounts, setRequestStatusCounts] = useState({
    all: 0,
    approved: 0,
    rejected: 0,
    inProgress: 0,
  });
  const [documentDetails, setDocumentDetails] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState([]);
  const [searchResults, setSearchResults] = useState({});
  const [filter, setFilter] = useState("ALL");
  const [tableData, setTableData] = useState({
    columns: [],
    respondent: [],
    page: 1,
    perPage: PAGE_LIMIT,
  });
  const [rowData, setRowData] = useState([]);
  const [show, setShow] = useState({
    columns: false,
    activeCell: null,
    columnOption: {
      name: null,
      show: false,
    },

    dateFilter: false,
    searchSuggest: false,
    moreOptions: false,
    multiApproveNReject: false,
    bulkApproveRejectModal: false,
    bulkDeleteResponseModal: false,
    bulkApproveRejectActiveModalButton: null,
    bulkProcessing: false,
    deleteResponse: false,
    bulkSubmitModal: false,
  });
  const [owner, setOwner] = useState({});
  const [searchDateFilter, setSearchDateFilter] = useState({
    start: null,
    end: null,
  });
  const [roleInCollaboration, setRoleInCollaboration] = useState("");

  const [dateRangeFilter, setDateRangeFilter] = useState([
    {
      startDate: documentDetails?.createdAt,
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [loading, setLoading] = useState(false);

  const [approvalFlowTimeline, setApprovalFlowTimeline] = useState({
    show: false,
    data: {},
  });
  const [deletingResponse, setDeletingResponse] = useState(null);

  const [bulkApproveRejectData, setBulkApproveRejectData] = useState({
    selectAll: false,
    respondents: [],
    comment: "",
  });
  const [bulkDeleteResponseData, setBulkDeleteResponseData] = useState({
    selectAll: false,
    respondents: [],
  });

  const [bulkSubmitModalData, setBulkSubmitModalData] = useState({
    formId: null,
  });
  const rolesToShowInitially = 2;
  const [showRoles, setShowRoles] = useState<any>([]);
  const [showApprovers, setShowApprovers] = useState<any>([]);

  const [isSendACopyModalOpen, setIsSendACopyModalOpen] = useState(false);
  const [documentForSendACopy, setDocumentForSendACopy] = useState({
    documentName: "",
    documentUrl: "",
    documentType: "",
  });
  const [isSigningOrderDiagram, setIsSigningOrderDiagram] = useState(false);
  const [signingOrderRecipientList, setSigningOrderRecipientList] = useState(
    []
  );

  const [currOrderDocument, setCurrOrderDocument] = useState<any>();

  const [activeForSendModal, setActiveForSendModal] = useState("email");
  const [emailForSendModal, setEmailForSendModal] = useState({
    to: "",
    subject: "I am sharing a signed pdf with you",
    message: "Consider this response document you asked for..",
  });
  const [upgradeModalType, setUpgradeModalType] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;
  const [showShareViaLink, setShowShareViaLink] = useState(false);
  const [generatedLinksData, setGeneratedLinksData] = useState([]);
  const [showSigningInPerson, setShowSigningInPerson] = useState(false);
  const [showGenerateLinksModal, setShowGenerateLinksModal] = useState(false);
  const [showSelectedDownload, setShowSelectedDownload] = useState(false);
  const [selectedPdfs, setSelectedPdfs] = useState([]);

  useEffect(() => {
    dispatch(getOwnerThunk());
  }, []);

  async function getAllRespondents() {
    setLoading(true);
    try {
      let params = getSearchData();
      // console.log(params);
      let data = await fetchAllPdfTemplateResponses({
        page: params.pageNo_,
        limit: params.limit_,
        query: params.query_,
        dateFrom: params.dateFrom_,
        dateTo: params.dateTo_,
        sortBy: params.sortBy_,
        sortOrder: params.sortOrder_,
        filter: params.filter_,
        documentId,
      });
      if (data) {
        setRespondents(data.allRespondents);
        setDocumentDetails(data.document);
        setOwner(data?.ownerId);
      }
      setLoading(false);

      // setLoading(true);
      // let { data } = await axios.get(
      //   `/pdf-template-respondents?documentId=${documentId}&page=${tableData.page}&limit=${limit}&filter=${filter}`
      // );
      // setLoading(false);
      // setRespondents(data.allRespondents);
      // setDocumentDetails(data.document);
      // setOwner(data?.ownerId);
    } catch (error) {
      setLoading(false);
      console.log(`Error getting respondents: ${error}`);
    }
  }

  const handleOutsideClickDateFilter = (e) => {
    if (dateFilterRef?.current !== null) {
      if (dateFilterRef?.current?.contains(e.target)) return;
    }
    setShow((prev) => ({
      ...prev,
      dateFilter: false,
    }));
  };
  const handleOutsideClickSearchSuggest = (e) => {
    if (searchSuggestRef?.current !== null) {
      if (searchSuggestRef?.current?.contains(e.target)) return;
    }
    setShow((prev) => ({
      ...prev,
      searchSuggest: false,
    }));
  };
  const handleOutsideClickColumnHide = (e) => {
    if (columnHideRef?.current !== null) {
      if (columnHideRef?.current?.contains(e.target)) return;
    }
    setShow((prev) => ({
      ...prev,
      columns: false,
    }));
  };
  const handleOutsideClickMoreOptions = (e) => {
    if (moreOptionsRef?.current !== null) {
      if (moreOptionsRef?.current?.contains(e.target)) return;
    }
    setShow((prev) => ({
      ...prev,
      moreOptions: false,
    }));
  };

  const handleOutsideClickApprovalTimeline = (e) => {
    if (approvalTimelineRef?.current !== null) {
      if (approvalTimelineRef?.current?.contains(e.target)) return;
    }

    setApprovalFlowTimeline((prev) => ({
      ...prev,
      show: false,
    }));
  };

  // useEffect(() => {
  //   if (searchQuery?.length > 0) {
  //     getRespondentsBySearch(null, tableData.page);
  //   } else {
  //     setSearchResults({});
  //     getAllRespondents(tableData.page);
  //   }
  // }, [tableData.page, filter, tableData.perPage, searchQuery, isDeleted]);

  // useEffect(() => {
  //   if (searchText === "") {
  //     setSearchResults({});
  //     setTableData((prev) => ({ ...prev, page: 1 }));
  //     setFilter("ALL");
  //   }
  // }, [searchText]);

  useEffect(() => {
    if (!searchText && getSearchData().query_) {
      let data = getSearchData();
      setSearchData({ query_: "", pageNo_: data.pageNo_ });
    }
  }, [searchText]);

  useEffect(() => {
    getAllRespondents();
  }, [
    searchParams,
    getSearchData().rT_,
    getSearchData().pageNo_,
    getSearchData().limit_,
    getSearchData().filter_,
    getSearchData().sortBy_,
    getSearchData().sortOrder_,
    getSearchData().dateFrom_,
    getSearchData().dateTo_,
    // reload,
  ]);

  // useEffect(() => {
  //   let startDate = new Date(documentDetails?.createdAt);
  //   let endDate = new Date();

  //   if (
  //     startDate?.toString() !== dateRangeFilter?.[0]?.startDate?.toString() &&
  //     dateRangeFilter?.[0]?.endDate?.toString() !== endDate?.toString()
  //   ) {
  //     console.log("date range filter changed");
  //     getRespondentsBySearch();
  //   }
  // }, [dateRangeFilter]);

  // update the 'page' search parameter when the 'page' state changes
  // useEffect(() => {
  //   searchParams.set("page", respondentPageNo);
  //   setSearchParams(searchParams);
  // }, [respondentPageNo, setSearchParams, searchParams]);

  // useEffect(() => {
  //   setTableData((prev) => ({ ...prev, page: 1 }));
  // }, [tableData.perPage]);

  // useEffect(() => {
  //   setRespondentPageNo(tableData.page);
  // }, [tableData.page]);

  // useEffect(() => {
  //   setDateRangeFilter([
  //     {
  //       startDate: new Date(documentDetails?.createdAt),
  //       endDate: new Date(),
  //       key: "selection",
  //     },
  //   ]);
  //   // documentDetails?.isApprovalWorkflowEnabled && fetchRequestStatusCount();
  // }, [documentDetails]);

  useEffect(() => {
    // setTableData((prev) => {
    //   return {
    //     ...prev,
    //     page: respondentPageNo ?? 1,
    //   };
    // });
    // setFilter("ALL");
    // setShow((prev) => {
    //   return {
    //     ...prev,
    //     multiApproveNReject: false,
    //     deleteResponse: false,
    //   };
    // });
    // fetchFormFilters();
    document.addEventListener("mousedown", handleOutsideClickColumnHide);
    document.addEventListener("mousedown", handleOutsideClickSearchSuggest);
    document.addEventListener("mousedown", handleOutsideClickDateFilter);
    document.addEventListener("mousedown", handleOutsideClickApprovalTimeline);
    document.addEventListener("mousedown", handleOutsideClickMoreOptions);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickColumnHide);
      document.removeEventListener("mousedown", handleOutsideClickDateFilter);
      document.removeEventListener(
        "mousedown",
        handleOutsideClickSearchSuggest
      );
      document.removeEventListener(
        "mousedown",
        handleOutsideClickApprovalTimeline
      );
      document.removeEventListener("mousedown", handleOutsideClickMoreOptions);
    };
  }, []);

  //   useEffect(() => {
  //     (async () => {
  //       let collaborator = await getCollaboratorPermission(
  //         appState.activeOwner?._id
  //       );
  //       setCollaborator(collaborator);
  //       // console.log("collaborator", collaborator);
  //     })();
  //   }, [appState.activeOwner]);
  const workSpaceDetails = useWorkSpaceDetails();

  useEffect(() => {
    setRoleInCollaboration(workSpaceDetails?.currentWorkSpaceRole);
  }, [workSpaceDetails?.currentWorkSpaceRole]);

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  const handleBulkDownload = async (downloadAll = false) => {
    try {
      setBulkResponsePdfDownloading(true);
      let respondents =
        searchResults?.respondents?.length > 0 ||
        searchResults?.message === "No result found for this search"
          ? searchResults?.respondents
          : allRespondents?.respondents;
      const zip = new JSZip();
      // for selected pdf download feat
      if (!downloadAll) {
        respondents = selectedPdfs;
      }
      if (!respondents || respondents?.length === 0) {
        toast.error("No signed documents found for bulk download");
        setBulkResponsePdfDownloading(false);
        return;
      }

      const pdfsWithFinishedUrl = respondents.filter(
        (respondent) =>
          respondent.status === "SIGNED" && respondent?.finishedPdfUrl
      );

      if (pdfsWithFinishedUrl.length === 0) {
        toast.error("No signed documents found for bulk download");
        setBulkResponsePdfDownloading(false);
        return;
      }

      const documentDetailsForBulkDownload = pdfsWithFinishedUrl.map((doc) => {
        return {
          finishedPdfUrl: doc?.finishedPdfUrl,
        };
      });

      let { data: preSignedUrlData } = await axios.post(
        "/document/get-presigned-url",
        {
          documentDetails: documentDetailsForBulkDownload,
        }
      );

      const { presignedUrls } = preSignedUrlData;

      const pdfPromises = presignedUrls.map(async (respondent, index) => {
        const pdfUrlResponse = await fetch(respondent?.presignedUrl);
        if (!pdfUrlResponse?.ok) {
          throw new Error("Failed to fetch PDF");
        }
        const pdfBlob = await pdfUrlResponse.blob();
        zip.file(`${"response"}-${index + 1}.pdf`, pdfBlob);
      });

      await Promise.all(pdfPromises);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      download(
        zipBlob,
        `${
          documentDetails?.documentName +
          " - responses - page (" +
          tableData.page +
          ")"
        }.zip`
      );
      setBulkResponsePdfDownloading(false);
      setShowSelectedDownload(false);
      setSelectedPdfs([]);
    } catch (error) {
      setBulkResponsePdfDownloading(false);
      setShowSelectedDownload(false);
      setSelectedPdfs([]);
      console.error("Error during bulk PDF generation and download:", error);
    }
  };

  // const DropDownActionOptions = ({ documentData }: any) => {
  //   console.log("DOCUMENT DATA");
  //   const [actionShowingRespondent, setActionShowingRespondent] =
  //     useState(null);
  //   const selfRef = useRef();

  //   useEffect(() => {
  //     const handleOutsideClick = (e) => {
  //       console.log("Outside click");
  //       if (actionShowingRespondent !== documentData?._id) {
  //         return;
  //       }
  //       if (selfRef.current !== null && selfRef.current.contains(e.target)) {
  //         return;
  //       }
  //       setActionShowingRespondent(null);
  //     };
  //     document.addEventListener("mousedown", handleOutsideClick);
  //     return () => {
  //       document.removeEventListener("mousedown", handleOutsideClick);
  //     };
  //   }, [
  //     actionShowingRespondent,
  //     documentData?._id,
  //     setActionShowingRespondent,
  //   ]);

  //   return (
  //     <div className="" ref={selfRef}>
  //       <span
  //         className={`m-auto hover:text-gray-800 text-gray-600`}
  //         onClick={() => {
  //           console.log("Three dots clicked");
  //           setActionShowingRespondent((prev) =>
  //             prev === documentData?._id ? null : documentData?._id
  //           );
  //         }}
  //       >
  //         <BsThreeDots className="text-2xl hover:opacity-85 bg-white py-1 rounded-md border" />
  //       </span>
  //       <DropdownActionsRespondent
  //         document={documentData}
  //         actionShowingRespondent={actionShowingRespondent}
  //         setActionShowingRespondent={setActionShowingRespondent}
  //         setIsModalOpen={setIsModalOpen}
  //         setRespondentsHistory={setRespondentsHistory}
  //         setRespondentForHistory={setRespondentForHistory}
  //         setRespondentStatus={setRespondentStatus}
  //         setLoading={setLoading}
  //         loading={loading}
  //         documentDetails={documentDetails}
  //         setShowUpgradeModal={setShowUpgradeModal}
  //         setUpgradeModalType={setUpgradeModalType}
  //         setIsSendACopyModalOpen={setIsSendACopyModalOpen}
  //         setDocumentForSendACopy={setDocumentForSendACopy}
  //         setIsSigningOrderDiagram={setIsSigningOrderDiagram}
  //         setSigningOrderRecipientList={setSigningOrderRecipientList}
  //         setCurrOrderDocument={setCurrOrderDocument}
  //         setDeletingResponse={setDeletingResponse}
  //         setShow={setShow}
  //         roleInCollaboration={roleInCollaboration}
  //       />
  //     </div>
  //   );
  // };

  const IssueOptions = ({ documentData }: any) => {
    return (
      <span
        onClick={(e) => {
          console.log("Issue options", documentData?._id);
        }}
      >
        <BsThreeDots className="text-2xl hover:opacity-85 bg-white py-1 rounded-md border" />
      </span>
    );
  };

  const handleDownload = async (respondent) => {
    if (!respondent.finishedPdfUrl) {
      try {
        let url = `/downlod-semicomplete-pdf?documentId=${
          respondent?.documentId
        }&type=${"PDF_TEMPLATE"}&respId=${respondent?._id}`;
        let { data } = await axios.get(url);
        if (data?.success) {
          downloadBase64File(
            data?.pdfBytes,
            `${respondent?.documentId}-${respondent?._id}.pdf`
          );
          // window.open(data?.pdfBytes, "_blank");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
      // ! Frontend generation disbaled
      // if (documentDetails?.pdfTemplate?.basePdf && !respondent.finishedPdfUrl) {
      // try {
      //   let pdfUrl =
      //     respondent.pdfLink ||
      //     documentDetails?.pdfTemplate?.basePdf ||
      //     documentDetails?.documentUrl;
      //   setLoading(true);
      //   const responseFile = await fetch(pdfUrl);
      //   const pdfBuffer = await responseFile.arrayBuffer();
      //   // Convert PDF buffer to a base64 string
      //   let pdfBase64 = arrayBufferToBase64(pdfBuffer);
      //   pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
      //   let schemas = respondent?.templateSchema;
      //   // merge all the role's input together
      //   let responsesArray = respondent?.signers?.map((signer) => {
      //     return signer?.templateResponse[0];
      //   });
      //   responsesArray = responsesArray?.flat();
      //   const mergedObject = responsesArray.reduce(
      //     (acc, currentObject) => ({ ...acc, ...currentObject }),
      //     {}
      //   );
      //   let responseInputs = [mergedObject];
      //   const urlToReplace =
      //     "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature-template";
      //   const updatedResponse = await Promise.all(
      //     responseInputs.map(async (obj: any) => {
      //       const updatedObj: any = {};
      //       for (const key in obj) {
      //         if (Object.hasOwnProperty.call(obj, key)) {
      //           const value = obj[key];
      //           console.log("the value is ", value);
      //           if (
      //             value ===
      //               "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature-template/4vnRsfZ.png" ||
      //             value === "https://i.imgur.com/AfyPZQg.png"
      //           ) {
      //             updatedObj[key] = "";
      //           } else if (
      //             typeof value === "string" &&
      //             value.includes(urlToReplace)
      //             // (value.includes(urlToReplace) ||
      //             //   value.includes(
      //             //     "https://i.imgur.com/AfyPZQg.png"
      //             //   ) ||
      //             //   value.includes("https://i.imgur.com/AfyPZQg.png"))
      //           ) {
      //             const responseFile = await fetch(value);
      //             console.log("the image response ", responseFile);
      //             const imageBuffer = await responseFile.arrayBuffer();
      //             const imageBase64 = arrayBufferToBase64(imageBuffer);
      //             const fileExtension = value.split(".").pop();
      //             console.log("detectedType", fileExtension);
      //             updatedObj[key] =
      //               `data:image/${fileExtension ?? "png"};base64,` +
      //               imageBase64;
      //           } else {
      //             updatedObj[key] = value;
      //           }
      //         }
      //       }
      //       return updatedObj;
      //     })
      //   );
      //   const inputs = updatedResponse;
      //   const template: Template = {
      //     schemas: schemas,
      //     basePdf: pdfBase64,
      //   };
      //   console.log("The template is: ", template);
      //   console.log("The inputs are: ", inputs);
      //   let downloadbledPdf = await generate({ template, inputs });
      //   const pdfArray = Uint8Array.from(downloadbledPdf);
      //   const pdfBlob = new Blob([pdfArray], {
      //     type: "application/pdf",
      //   });
      //   download(
      //     pdfBlob,
      //     `${documentDetails?.documentName}_${respondent._id.toString()}.pdf`,
      //     "application/pdf"
      //   );
      //   setLoading(false);
      // } catch (error) {
      //   console.error("the generate function error: ", error);
      //   setLoading(false);
      //   // Handle error, show error message to the user, etc.
      // }
    } else if (respondent.finishedPdfUrl) {
      // window.open(respondent.finishedPdfUrl, "_blank");
      let url = await getS3PreSignedUrl(respondent?.finishedPdfUrl);
      window.open(url, "_blank");
    }
  };

  const deleteOneResponseModal = () => (
    <Fragment>
      <div>
        <div className="py-6 px-6">
          <h2 className="text-lg font-semibold mb-1">
            Are you sure you want to delete this response?
          </h2>
          <p className="text-sm">
            The response from {deletingResponse?.respondentEmail} will be
            deleted.
          </p>
        </div>
        <button
          disabled={show.oneDeleting}
          onClick={handleOneResponseDelete}
          className="mx-6 bg-red-600 text-md text-white px-8 py-2.5 rounded-xl shadow mr-4 mb-4 hover:opacity-80"
        >
          {show.oneDeleting ? "Deleting..." : "Delete"}
        </button>
      </div>
      {/* )} */}
      <div
        onClick={() => {
          setShow((prev) => {
            return {
              ...prev,
              deleteOneResponseModal: false,
            };
          });
        }}
        className="absolute top-0 right-0 cursor-pointer"
      >
        <MdClose className="text-2xl m-4" />
      </div>
    </Fragment>
  );

  const handleOneResponseDelete = async () => {
    setShow((prev) => {
      return {
        ...prev,
        oneDeleting: true,
      };
    });

    let id = deletingResponse._id;
    console.log(id);

    try {
      let { data } = await axios.post(
        `/respondents/delete-pdftemplate-response`,
        {
          id,
        }
      );
      // console.log(data);
      if (data?.success) {
        setIsDeleted(id);
      }
    } catch (error) {
      console.log(error);
    }

    setShow((prev) => {
      return {
        ...prev,
        deleteOneResponseModal: false,
        oneDeleting: false,
      };
    });
  };

  const voidDocumentModal = () => (
    <Fragment>
      <div>
        <div className="py-6 px-6">
          <h2 className="text-lg font-semibold mb-1">
            Are you sure you want to void this document?
          </h2>
          <p className="text-sm">This document will be void.</p>
        </div>
        <button
          disabled={show.oneDeleting}
          onClick={handleVoidDocumentDelete}
          className="mx-6 bg-red-600 text-md text-white px-8 py-2.5 rounded-xl shadow mr-4 mb-4 hover:opacity-80"
        >
          Void Document
        </button>
      </div>
      {/* )} */}
      <div
        onClick={() => {
          setShow((prev) => {
            return {
              ...prev,
              voidDocumentModal: false,
            };
          });
        }}
        className="absolute top-0 right-0 cursor-pointer"
      >
        <MdClose className="text-2xl m-4" />
      </div>
    </Fragment>
  );

  const handleVoidDocumentDelete = async () => {
    setShow((prev) => {
      return {
        ...prev,
        oneDeleting: true,
      };
    });

    let id = deletingResponse._id;
    console.log(id);
    let owner = JSON.parse(localStorage.getItem("owner"));

    try {
      const response = getIpAddressLocal();
      const ipData = await response;
      let { data } = await axios.post(
        `/respondents/void-pdftemplate-response`,
        {
          id,
          email: owner?.email,
          ip: ipData?.ip,
        }
      );
      // console.log(data);
      if (data?.success) {
        setIsDeleted(id);
      }
    } catch (error) {
      console.log(error);
    }

    setShow((prev) => {
      return {
        ...prev,
        voidDocumentModal: false,
        oneDeleting: false,
      };
    });
  };
  const checkSelected = (respondent) => {
    return selectedPdfs?.some((ele: any) => ele?._id === respondent?._id);
  };
  React.useMemo(() => {
    let respondents =
      searchResults?.respondents?.length > 0 ||
      searchResults?.message === "No result found for this search"
        ? searchResults?.respondents
        : allRespondents?.respondents;
    setRowData(respondents || []);

    let data = respondents?.map((respondent, i) => {
      let owner = respondent?.ownerId?.name;
      let lastModified = new Date(respondent?.updatedAt).toLocaleString();
      let ownerEmail = respondent?.ownerId?.email;
      const rolesArray = respondent?.signers?.map((signer) => {
        let role = {
          title: signer?.roleTitle,
          colour: signer?.roleColour,
          status: signer?.status,
          name: signer?.name,
          email: signer?.email,
          phone: signer?.phone,
          owner: owner,
          lastModified: lastModified,
          ownerEmail: ownerEmail,
          hasDeclined: signer?.hasDeclined,
          accessCode: signer?.accessCode,
        };
        return role;
      });
      const approversArray = respondent?.approvers?.map((approver) => {
        let approve = {
          colour: approver?.approverColor || "#6b7280",
          status: approver?.status,
          name: approver?.name,
          email: approver?.email,
          owner: owner,
          lastModified: lastModified,
          ownerEmail: ownerEmail,
          hasRejected: approver?.hasRejected,
        };
        return approve;
      });

      let response = {};

      return {
        id: respondent.signerId || respondents._id,
        "#": (
          <div className="font-semibold text-gray-600 text-xs">
            {tableData.page * PAGE_LIMIT + i + 1 - 10}
          </div>
        ),
        Select: (
          <BoloCheckboxMui
            onChange={(e) => {
              const isChecked = e.target.checked;
              setSelectedPdfs((prevSelected: any) => {
                let newSelected = [...prevSelected];
                if (
                  isChecked &&
                  !newSelected.some((item) => item?._id === respondent?._id)
                ) {
                  newSelected.push(respondent);
                } else if (!isChecked) {
                  newSelected = newSelected.filter(
                    (item: any) => item?._id !== respondent?._id
                  );
                }
                return newSelected;
              });
            }}
            disabled={
              !(
                respondent.status === "SIGNED" || respondent.status === "PAID"
              ) || loading
            }
            checked={checkSelected(respondent)}
          />
        ),
        "Response PDF": (
          <div className="flex gap-2 items-center">
            <BoloButton
              size={"sm"}
              className="flex items-center gap-2 text-xs md:text-xs h-6 mr-2"
              // disabled={
              //   !(
              //     respondent.status === "SIGNED" || respondent.status === "PAID"
              //   ) || loading
              // }
              disabled={
                respondent.status === "VOID" ||
                respondent.status === "REJECTED" ||
                respondent.status === "SENT FOR APPROVAL" ||
                loading
              }
              onClick={async (e) => {
                new MixPanel().track("PDF template", {
                  pdf_template_action: "pdf_template_response_download",
                  paid: false,
                });
                new MixPanel().increment("pdf_template_response_download");

                e.stopPropagation();
                handleDownload(respondent);
              }}
              // className={`flex-row justify-center items-center text-center hover:opacity-90 text-sm disabled:opacity-50 rounded-md mr-6 px-2 py-1 bg-primary font-medium flex text-white disabled:cursor-default `}
            >
              {/* <AiOutlineDownload className="hover:opacity-85 mr-2" /> */}
              <span className="text-xs">Download</span>
            </BoloButton>
            {respondent.status !== "SIGNED" && respondent.status !== "PAID" ? (
              <IoMdInformationCircle
                data-tooltip-id="incomplete-download"
                className="text-gray-500"
                size={16}
              />
            ) : null}
            <CustomTooltip
              helpers={{
                id: "incomplete-download",
                content: "Incomplete Download",
                place: "right",
              }}
            />
          </div>
        ),
        Status: (
          <div className="text-[10px]">
            <div
              data-tip={`the Document is in ${respondent.status} state`}
              className="flex flex-row items-center w-fit"
            >
              {/* <div
                style={{
                  backgroundColor: getStatusByPayment({ document: respondent })
                    .color,
                }}
                className={`w-2 h-2 rounded-full ${
                  // respondent.status === "SIGNED" || respondent.status === "PAID"
                  //   ? "bg-green-500"
                  //   : "bg-red-500"
                  ""
                }`}
              ></div> */}
              <span
                className="rounded px-1 tracking-wider font-semibold"
                style={{
                  backgroundColor: getStatusByPayment({ document: respondent })
                    .backGround,
                  color: getStatusByPayment({ document: respondent }).color,
                }}
              >
                {getStatusByPayment({ document: respondent }).status}{" "}
              </span>
              <p className="text-[10px] text-gray-500 ml-2">
                {getStatusByPayment({ document: respondent }).message}
                {respondent?.sentAsETemplate && (
                  <span className="text-xs text-gray-400 ml-2">
                    (via e-template)
                  </span>
                )}
                {respondent?.sentViaSMS && (
                  <span className="text-xs text-gray-400 ml-2">(via SMS)</span>
                )}
              </p>
            </div>
          </div>
        ),

        // ?? tableData.page * tableData.perPage + i + 1 - tableData.perPage,
        "Sent for Signature": (
          <div className="text-[10px] ">
            {new Date(respondent.createdAt).toDateString()}
            {"  "}
            {new Date(respondent.createdAt).toLocaleTimeString()}
          </div>
        ),
        "Signed By": (
          <div
            // data-tip={`This document is signed by ${document.totalSignedRespondants} people out of ${document.totalRespondants}`}
            className="flex flex-row items-center w-fit"
          >
            <div className={``}>
              {respondent.signers.every(
                (signer) => signer.status === "SIGNED"
              ) && <MdOutlineDownloadDone className="text-lg text-green-400" />}
              {!respondent.signers.every(
                (signer) => signer.status === "SIGNED"
              ) && <MdOutlinePending className="text-lg text-yellow-400" />}
            </div>
            <p className="text-[10px] text-gray-500 ml-2">
              {
                respondent.signers.filter(
                  (signer) => signer.status === "SIGNED"
                ).length
              }{" "}
              / {respondent.signers.length}
            </p>
          </div>
        ),
        Roles: (
          <div className="text-[10px]">
            <div className="flex gap-2">
              {rolesArray?.slice(0, rolesToShowInitially).map((role, index) => (
                <div
                  key={index}
                  className=" py-1 rounded-md text-xs flex gap-2 items-center justify-start"
                >
                  {/* <div
                    className="w-2 h-2 rounded-full"
                    style={{ backgroundColor: role?.colour }}
                  ></div> */}
                  <p
                    className="flex gap-2 items-center px-2 rounded"
                    style={{
                      backgroundColor: getTintFromHex(role?.colour, 90),
                      color: generateShadesAndTints(role?.colour)?.shades?.[5],
                    }}
                  >
                    {role?.title}
                  </p>
                  <span className="">
                    {role?.hasDeclined ? (
                      <MdClose className="text-red-600" />
                    ) : role?.status === "SIGNED" ? (
                      <MdOutlineDoneAll className="text-green-600" />
                    ) : (
                      <AiOutlineClockCircle className="text-red-600" />
                    )}
                  </span>
                </div>
              ))}
            </div>

            {/* {rolesArray.length > rolesToShowInitially && ( */}
            <div className="px-2 flex gap-2 font-medium">
              {rolesArray?.length > rolesToShowInitially && (
                <span>+{rolesArray.length - rolesToShowInitially} more</span>
              )}
              <button
                className="text-blue-500 hover:underline"
                data-tooltip-id="my-tooltip"
                data-tooltip-place="bottom-start"
                // onClick={(e) => {
                //   e.stopPropagation();
                //   // setShowActions(true);
                //   if (showSignersOf === respondent._id) {
                //     setShowSignersOf(null);
                //     return;
                //   }
                //   setShowSignersOf(respondent._id);
                // }}
                onMouseEnter={() => {
                  setShowRoles(rolesArray);
                  if (respondent?.approvers?.length > 0) {
                    setShowApprovers(approversArray);
                  }
                }}
                // onMouseLeave={() => {
                //   setShowRoles([]);
                // }}
              >
                {/* +{rolesArray.length - rolesToShowInitially} more */}
                Show Details
              </button>
            </div>
            {/* )} */}
          </div>
        ),
        // Actions: (
        //   <div className="">
        //     <span
        //       className={`m-auto hover:text-gray-800 text-gray-600`}
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         if (actionShowingRespondent === respondent._id) {
        //           setActionShowingRespondent(null);
        //           return;
        //         }
        //         setActionShowingRespondent(respondent._id);
        //       }}
        //     >
        //       <BsThreeDots className="text-2xl hover:opacity-85 mr-2 bg-white py-1 rounded-md border" />
        //     </span>
        //     <DropdownActionsRespondent document={respondent} />
        //   </div>
        // ),
        Actions: (
          <DropDownActionOptions
            documentData={respondent}
            setIsModalOpen={setIsModalOpen}
            setRespondentsHistory={setRespondentsHistory}
            setRespondentForHistory={setRespondentForHistory}
            setRespondentStatus={setRespondentStatus}
            setLoading={setLoading}
            loading={loading}
            documentDetails={documentDetails}
            setShowUpgradeModal={setShowUpgradeModal}
            setUpgradeModalType={setUpgradeModalType}
            setIsSendACopyModalOpen={setIsSendACopyModalOpen}
            setDocumentForSendACopy={setDocumentForSendACopy}
            setIsSigningOrderDiagram={setIsSigningOrderDiagram}
            setSigningOrderRecipientList={setSigningOrderRecipientList}
            setCurrOrderDocument={setCurrOrderDocument}
            setDeletingResponse={setDeletingResponse}
            setShow={setShow}
            roleInCollaboration={roleInCollaboration}
            setShowShareViaLink={setShowShareViaLink}
            setShowSigningInPerson={setShowSigningInPerson}
            setGeneratedLinksData={setGeneratedLinksData}
            setUpgradeModalData={setUpgradeModalData}
            setShowGenerateLinksModal={setShowGenerateLinksModal}
          />
        ),
        ...response,
      };
    });
    // console.log("data", data);
    setTableData((prev) => {
      return {
        ...prev,
        data,
      };
    });
  }, [
    allRespondents,
    searchResults,
    bulkApproveRejectData?.respondents,
    bulkDeleteResponseData?.respondents,
    show.multiApproveNReject,
    tableData.page,
    tableData.perPage,
    searchQuery.length,
    selectedPdfs,
  ]);

  const columnHeader = (column) => (
    <div className="flex items-center">
      <div className="truncate flex items-center gap-2">
        {column}{" "}
        {column === "Response PDF" && (
          <span className="inline-block" data-tooltip-id="download-tooltip">
            <FaInfoCircle className="text-gray-700" />
          </span>
        )}
      </div>

      <div className="flex items-center ml-2">
        <MdKeyboardArrowDown className="text-xl" />
      </div>
    </div>
  );
  React.useMemo(() => {
    let columnPreference = JSON?.parse(
      localStorage.getItem("formColumnPrefrences")
    )?.[documentId];

    if (columnPreference) {
      // check if hidden column preference is present in local storage or not
      let hiddenColIndex = columnPreference?.findIndex(
        (col) => col?.accessor === "hidden"
      );

      // check if hidden column preference is present in local storage or not
      let lastUpdatedColIndex = columnPreference?.findIndex(
        (col) => col?.accessor === "Last Updated"
      );

      if (lastUpdatedColIndex === -1) {
      }
      if (hiddenColIndex === -1) {
        columnPreference?.unshift(hiddenColumn);
      }

      columnPreference = columnPreference?.map((col) => {
        return {
          ...col,
          Header:
            col?.Header === "hidden"
              ? hiddenColumn.Header
              : columnHeader(col.Header),
        };
      });
      setTableData((prev) => {
        return {
          ...prev,
          columns: columnPreference,
        };
      });
      return;
    }

    let cols = [
      // hiddenColumn,
      // {
      //   id: v4(),
      //   name: "#",
      //   Header: columnHeader("#"),
      //   accessor: "#", // accessor is the "key" in the data
      //   show: true,
      //   pinned: true,
      // },
      //   {
      //     id: v4(),
      //     name: "Overall Status",
      //     Header: columnHeader("Overall Status"),
      //     accessor: "Overall Status",
      //     show: true,
      //     pinned: true,
      //   },
      {
        id: v4(),
        name: "Response PDF",
        Header: columnHeader("Response PDF"),
        accessor: "Response PDF",
        show: true,
      },
      // {
      //   id: v4(),
      //   name: "Delete Response",
      //   Header: columnHeader("Delete Response"),
      //   accessor: "Delete Response",
      //   show: true,
      // },
      {
        id: v4(),
        name: "Status",
        Header: columnHeader("Status"),
        accessor: "Status",
        show: true,
      },
      {
        id: v4(),
        name: "Signed By",
        Header: columnHeader("Signed By"),
        accessor: "Signed By",
        show: true,
      },
      {
        id: v4(),
        name: "Roles",
        Header: columnHeader("Roles"),
        accessor: "Roles",
        show: true,
      },
      {
        id: v4(),
        name: "Sent for Signature",
        Header: columnHeader("Sent for Signature"),
        accessor: "Sent for Signature",
        show: true,
      },
      {
        id: v4(),
        name: "Actions",
        Header: columnHeader("Actions"),
        accessor: "Actions",
        show: true,
      },
    ];
    if (showSelectedDownload) {
      let newCol = [
        {
          id: v4(),
          name: "Select",
          Header: columnHeader("Select"),
          accessor: "Select", // accessor is the "key" in the data
          show: true,
          pinned: false,
        },
        ...cols,
      ];
      cols = newCol;
    }
    setTableData((prev) => {
      return {
        ...prev,
        columns: cols,
      };
    });
  }, [documentDetails, showSelectedDownload]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableData.columns ?? [], data: tableData.data ?? [] });

  if (!tableData.data && loading) {
    // toast.error("Error getting respondents");
    return (
      <div className="h-screen -mt-[70px] flex justify-center items-center">
        <BoloLoader />
      </div>
    );
  }

  if (
    owner?.planhistory &&
    !owner?.planhistory?.[owner?.planhistory?.length - 1]?.isActive
  ) {
    console.log("Plan is exausted", owner);
    return <PlanExhaustedNavBanner />;
  }

  return (
    <div className="-m-4 bg-white h-[100%]">
      <Helmet>
        <title translate="no">Pdf Template Responses</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Pdf Template Responses" />
        <meta
          name="description"
          content="Access and manage all responses to your PDF templates, track document status history, and easily download signed documents for efficient document management."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/dashboard/templates/respondents`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Pdf Template Responses- Boloforms`}
        />
        <meta
          property="og:description"
          content="Access and manage all responses to your PDF templates, track document status history, and easily download signed documents for efficient document management."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/dashboard/templates/respondents"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      {isSigningOrderDiagram && (
        <SigningOrderDiagram
          recipientList={signingOrderRecipientList}
          setIsSigningOrderDiagram={setIsSigningOrderDiagram}
          isSigningOrderDiagram={isSigningOrderDiagram}
          currDocument={currOrderDocument}
        />
      )}
      {showShareViaLink && (
        <ShareViaLink
          setShowShareViaLink={setShowShareViaLink}
          type="PDF-TEMPLATE"
          sendToSigners={() => {
            return;
          }}
          sending={false}
          documentId={generatedLinksData?.documentId}
          generatedLinks={generatedLinksData?.signers}
        />
      )}
      {showSigningInPerson && (
        <SigningInPerson
          setShowSigningInPerson={setShowSigningInPerson}
          isSigningOrderData={generatedLinksData?.isSigningOrderData}
          type="PDF-TEMPLATE"
          documentId={generatedLinksData?.documentId}
          generatedLink={generatedLinksData?.signers}
          sending={false}
        />
      )}
      {showGenerateLinksModal && (
        <GenerateLinksModal
          setShowGenerateLinksModal={setShowGenerateLinksModal}
          setShowShareViaLink={setShowShareViaLink}
          docType="PDF-TEMPLATE"
          documentId={documentId}
          setGeneratedLinksData={setGeneratedLinksData}
          document={generatedLinksData}
        />
      )}
      <div className="px-3 md:px-4 md:h-[40px] my-3 md:my-6 text-gray-700 flex flex-row  items-center">
        {/* <div className="hidden md:flex flex-row h-full text-gray-700">
          <button
            onClick={() => {
              setTableData((prev) => {
                return {
                  ...prev,
                  page: prev.page - 1,
                };
              });
            }}
            disabled={tableData.page == 1}
            className="border p-3 shadow hover:bg-gray-200 cursor-pointer hover:opacity-90 rounded-l-md"
          >
            <MdOutlineArrowBackIos />
          </button>
          <button
            onClick={() => {
              setTableData((prev) => {
                return {
                  ...prev,
                  page: prev.page + 1,
                };
              });
            }}
            disabled={
              tableData.page * tableData.perPage >=
              (searchQuery?.length > 0
                ? searchResults.respondentsCount
                : allRespondents.respondentsCount)
            }
            className="border p-3 shadow hover:bg-gray-200 cursor-pointer hover:opacity-90 rounded-r-md"
          >
            <MdOutlineArrowForwardIos />
          </button>
        </div> */}

        <div className="flex items-center gap-2 w-full md:w-fit mr-auto">
          <form className={`h-full text-xs w-full`}>
            <div
              className={`h-full flex flex-row rounded-md overflow-hidden ml-auto  md:w-[500px] border`}
            >
              <input
                placeholder="Search by signer name, or email"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full h-full py-2 px-4   outline-none text-gray-700 "
              />
              <button
                className="min-w-[40px] flex justify-center items-center text-gray-500 cursor-pointer hover:opacity-90"
                onClick={(e) => {
                  e.preventDefault();
                  // new MixPanel().track("PDF", {
                  //   pdf_action: "pdf_search",
                  //   paid: false,
                  // });
                  // new MixPanel().increment("pdf_search");
                  // getDocumentsBySearch(e, "PDF");
                  setSearchData({ query_: searchText, pageNo_: 1 });
                  getAllRespondents();
                }}
              >
                <IoSearch className="text-xl" />
              </button>
            </div>
          </form>
          <div className="hidden md:flex">
            <DashboardFilter />
          </div>
        </div>

        {/* <Select
          onValueChange={(value) => {
            setFilter(value);
          }}
          value={filter}
        >
          <SelectTrigger className="max-w-[300px] h-[32px]">
            <SelectValue placeholder="Select Status" />
          </SelectTrigger>
          <SelectContent className="">
            <SelectGroup>
              <SelectItem value="ALL">All</SelectItem>
              <SelectItem value="FINISHED">FINISHED</SelectItem>
              <SelectItem value="PENDING">PENDING</SelectItem>
              <SelectItem value="VOID">VOID</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select> */}

        {/* @ts-ignore */}
        <div ref={columnHideRef} className="h-[32px] hidden md:flex flex-row">
          <BoloButton
            size={"sm"}
            variant="gray-shadow"
            className="flex items-center gap-2 h-[32px] text-xs md:text-xs"
            onClick={() => {
              setShow((prev) => {
                return {
                  ...prev,
                  columns: !prev.columns,
                };
              });
            }}
            // className="flex flex-row items-center py-2 px-4 h-full border shadow rounded text-xs font-medium"
          >
            <MdOutlineVisibility className="" size={16} />
            Columns
          </BoloButton>
          {show.columns && (
            <ColumnsShowHide
              columns={tableData.columns}
              setTableData={setTableData}
            />
          )}
        </div>

        {/* <div className="md:h-[40px] flex flex-row ml-4"> */}
        {showSelectedDownload ? (
          <>
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
              onClick={async (e) => {
                e.stopPropagation();
                setBulkResponsePdfDownloading(true);
                await handleBulkDownload(true);
                setBulkResponsePdfDownloading(false);
              }}
              disabled={loading}
            >
              <MdOutlineDownload className="" size={16} />
              <span className="hidden md:block">
                {bulkResponsePdfDownloading ? "Downloading" : "Download All"}
              </span>
              <span className="md:hidden">
                {bulkResponsePdfDownloading ? "Downloading" : "All"}
              </span>
            </BoloButton>
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
              onClick={async (e) => {
                e.stopPropagation();
                setBulkResponsePdfDownloading(true);
                await handleBulkDownload();
                setBulkResponsePdfDownloading(false);
              }}
              disabled={loading}
            >
              <MdOutlineDownload className="" size={16} />
              {bulkResponsePdfDownloading ? "Downloading" : "Selected"}
            </BoloButton>
            <BoloButton
              onClick={async (e) => {
                setShowSelectedDownload(false);
                setSelectedPdfs([]);
              }}
              disabled={loading}
              size={"sm"}
              variant="gray-shadow"
              className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
            >
              <span className=" md:block text-red-400">Cancel</span>
            </BoloButton>
          </>
        ) : (
          <BoloButton
            size={"sm"}
            variant="gray-shadow"
            className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
            onClick={async (e) => {
              new MixPanel().track("PDF template", {
                pdf_template_action: "pdf_template_response_bulk_pdf_download",
                paid: true,
              });
              new MixPanel().increment(
                "pdf_template_response_bulk_pdf_download"
              );
              const isBulkDownloadEnabled = await isFeatureEnabled(
                "BULK_DOWNLOAD"
              );
              if (!isBulkDownloadEnabled && !whichAdmin) {
                setShowUpgradeModal(true);
                setUpgradeModalType("BULK_DOWNLOAD");
                // setUpgradeModalData({
                //   title: "Bulk Download is available in Team Plan and above",
                //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
                // });
                return;
              }
              e.stopPropagation();
              setShowSelectedDownload(true);
              // setBulkResponsePdfDownloading(true);
              // await handleBulkDownload();
              // setBulkResponsePdfDownloading(false);
            }}
            disabled={loading}
            // className="flex flex-row items-center py-2 px-4 h-full border shadow rounded text-xs font-medium disabled:opacity-50 whitespace-nowrap"
          >
            <MdOutlineDownload className="" size={16} />
            <span className="hidden md:block">
              {bulkResponsePdfDownloading
                ? "Downloading..."
                : "Bulk PDF Download"}
            </span>
          </BoloButton>
        )}

        {/* </div> */}
        <div
          ref={dateFilterRef}
          className="h-[40px]  flex items-center cursor-pointer relative"
        >
          {show.dateFilter && (
            <DateRangeFilter
              setDateRangeFilter={setDateRangeFilter}
              dateRangeFilter={dateRangeFilter}
              setSearchDateFilter={setSearchDateFilter}
            />
          )}
        </div>
        {collaborator?.role !== "readOnly" && (
          <div ref={moreOptionsRef} className={`h-[40px] w-fit`}>
            <div className="relative h-full w-full cursor-pointer"></div>
          </div>
        )}
      </div>
      {searchQuery?.length > 0 && (
        <div className="px-4 pb-2 flex flex-row flex-wrap justify-end">
          {searchQuery.map((query, i) => (
            <div
              key={i}
              className="flex flex-row items-center w-fit m-1 px-2 py-1 text-xs my-2 rounded-md text-secondary/600 bg-secondary/60 cursor-pointer hover:opacity-90"
            >
              {query}{" "}
              <span
                onClick={() => {
                  setSearchQuery((prev) => {
                    return prev.filter((q) => q !== query);
                  });
                }}
                className="ml-2 cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          ))}
        </div>
      )}
      {window.innerWidth > 1024 && (
        <div
          className={`hidden md:block relative`}
          style={{
            overflowY:
              allRespondents?.respondents?.length === 0 && !loading
                ? "hidden"
                : "auto",
            maxHeight:
              allRespondents?.respondents?.length === 0 && !loading
                ? 0
                : "calc(100% - 130px)",
            height:
              allRespondents?.respondents?.length === 0 && !loading
                ? 0
                : "calc(100% - 130px)",
          }}
          id="mini-pdf-custom-scroll"
        >
          {/* <div className="">
            <table {...getTableProps()} className="pt-[40px]">
              <thead className="max-h-[40px] h-[40px] sticky top-0 bg-white shadow z-20">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      // console.log("column", column);
                      return (
                        column.show &&
                        column.pinned && (
                          <th
                            {...column.getHeaderProps()}
                            className={`${
                              column.name === "Response PDF" ? "pl-5" : ""
                            } px-2 py-2 text-left text-gray-600 font-semibold text-xs truncate relative cursor-pointer`}
                            style={{
                              overflow: "visible",
                            }}
                          >
                            <div
                              onClick={() => {
                                !show.multiApproveNReject &&
                                  !show.deleteResponse &&
                                  setShow((prev) => {
                                    return {
                                      ...prev,
                                      columnOption: {
                                        ...prev.columnOption,
                                        name: column.name,
                                        show:
                                          show.columnOption.name === column.name
                                            ? !prev.columnOption.show
                                            : true,
                                      },
                                    };
                                  });
                              }}
                            >
                              {column.render("Header")}
                            </div>
                            {show.columnOption.name === column.name &&
                              show.columnOption.show && (
                                <ColumnOptions
                                  column={column}
                                  setTableData={setTableData}
                                  setShow={setShow}
                                />
                              )}
                          </th>
                        )
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="hover:bg-secondary/40 cursor-pointer h-[69px]"
                      onClick={() => {
                        setApprovalFlowTimeline((prev) => {
                          return {
                            ...prev,
                            show: true,
                            respondent:
                              searchResults?.respondents?.length > 0
                                ? searchResults?.respondents[row.index]
                                : allRespondents.respondents[row.index],
                            form: documentDetails,
                          };
                        });
                      }}
                    >
                      {row.cells.map((cell, i) => {
                        let activeCell = `${cell.row.index + 1}-${i + 1}`;
                        let width =
                          cell.column.name === "#" ||
                          cell.column.name === "hidden"
                            ? "w-full"
                            : "";
                        let p =
                          cell.column.name === "Response PDF" ? "pl-4" : "";

                        return (
                          cell.column.show &&
                          cell.column.pinned && (
                            <td
                              {...cell.getCellProps()}
                              className={`${width} ${p} px-6 py-2 border-t text-sm relative`}
                              style={{
                                overflow: "visible",
                              }}
                              onClick={() => {
                                // console.log("cell", cell);
                                setShow((prev) => {
                                  return {
                                    ...prev,
                                    activeCell,
                                  };
                                });
                              }}
                            >
                              <div
                                className={`${
                                  cell.column.name !== "Overall Status" &&
                                  "truncate"
                                }`}
                              >
                                {cell.row.original[cell?.column?.name?.trim()]}
                              </div>
                              {show.activeCell === activeCell && (
                                <CellExtended
                                  width={width}
                                  value={cell.value}
                                />
                              )}
                            </td>
                          )
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
          <div className=" w-full border-l relative  flex-1">
            <table {...getTableProps()} className="w-full flex-1 pt-[40px]">
              <thead className="max-h-[40px] h-[40px] sticky top-0 bg-white shadow z-20">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      // console.log("column", column);

                      return (
                        column.show &&
                        !column.pinned && (
                          <th
                            {...column.getHeaderProps()}
                            className={`${
                              column.name === "Response PDF" ? "pl-4" : ""
                            } px-2 py-2 text-left text-gray-600 font-semibold text-xs truncate relative cursor-pointer`}
                            style={{
                              overflow: "visible",
                            }}
                          >
                            <div
                              onClick={() => {
                                setShow((prev) => {
                                  return {
                                    ...prev,
                                    columnOption: {
                                      ...prev.columnOption,
                                      name: column.name,
                                      show:
                                        show.columnOption.name === column.name
                                          ? !prev.columnOption.show
                                          : true,
                                    },
                                  };
                                });
                              }}
                            >
                              {column.render("Header")}
                            </div>

                            {show.columnOption.name === column.name &&
                              show.columnOption.show && (
                                <ColumnOptions
                                  column={column}
                                  setTableData={setTableData}
                                  setShow={setShow}
                                />
                              )}
                          </th>
                        )
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {loading ? (
                  <tr role={"row"} align={"center"} className={"w-full"}>
                    <td className={"w-full"} colSpan={8}>
                      <div className="p-8">
                        <BoloLoader />
                      </div>
                    </td>
                  </tr>
                ) : (
                  rows.map((row) => {
                    // console.log("row", row);
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="hover:bg-secondary/40 cursor-pointer h-[69px]"
                        onClick={() => {
                          let respondent = rowData[row?.id];
                          if (
                            showSelectedDownload &&
                            respondent?.finishedPdfUrl
                          ) {
                            if (
                              selectedPdfs.some(
                                (item: any) => item?._id === respondent?._id
                              )
                            ) {
                              setSelectedPdfs((prev: any) =>
                                prev.filter(
                                  (item: any) => item?._id !== respondent?._id
                                )
                              );
                            } else {
                              setSelectedPdfs((prev: any) => [
                                ...prev,
                                respondent,
                              ]);
                            }
                          }
                        }}
                      >
                        {row.cells.map((cell, i) => {
                          let activeCell = `${cell.row.index + 1}-${i + 1}`;
                          let width = cell.column.name === "#" ? "w-full" : "";

                          return (
                            cell.column.show &&
                            !cell.column.pinned && (
                              <td
                                {...cell.getCellProps()}
                                className={`${width} px-3 py-2 border-t text-sm relative`}
                                style={{
                                  overflow: "visible",
                                }}
                                onClick={() => {
                                  // console.log("cell", cell);
                                  setShow((prev) => {
                                    return {
                                      ...prev,
                                      activeCell,
                                    };
                                  });
                                }}
                              >
                                <div
                                  className={`${
                                    cell.column.name !== "Overall Status" &&
                                    "truncate"
                                  }`}
                                >
                                  {/* {cell.render("Cell")} */}
                                  {
                                    cell.row.original[
                                      cell?.column?.name?.trim()
                                    ]
                                  }
                                </div>
                                {show.activeCell === activeCell && (
                                  <CellExtended
                                    width={width}
                                    value={cell.value}
                                  />
                                )}
                              </td>
                            )
                          );
                        })}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Mobile Layout */}
      {window.innerWidth <= 1024 && (
        <div className="block lg:hidden">
          <div className="flex flex-col">
            {rowData.map((row, idx) => {
              return (
                <div
                  key={idx}
                  className={`relative border border-t py-3 px-3 flex  justify-between items-center hover:bg-secondary/40 cursor-pointer`}
                  onClick={() => {
                    if (showSelectedDownload && row?.finishedPdfUrl) {
                      if (
                        selectedPdfs.some((item: any) => item?._id === row?._id)
                      ) {
                        setSelectedPdfs((prev: any) =>
                          prev.filter((item: any) => item?._id !== row?._id)
                        );
                      } else {
                        setSelectedPdfs((prev: any) => [...prev, row]);
                      }
                    }
                  }}
                >
                  <div className="flex gap-3">
                    {showSelectedDownload && (
                      <BoloCheckboxMui
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setSelectedPdfs((prevSelected: any) => {
                            let newSelected = [...prevSelected];
                            if (
                              isChecked &&
                              !newSelected.some(
                                (item) => item?._id === row?._id
                              )
                            ) {
                              newSelected.push(row);
                            } else if (!isChecked) {
                              newSelected = newSelected.filter(
                                (item: any) => item?._id !== row?._id
                              );
                            }
                            return newSelected;
                          });
                        }}
                        disabled={!row?.finishedPdfUrl}
                        checked={selectedPdfs.some(
                          (item: any) => item?._id === row?._id
                        )}
                      />
                    )}
                    <div className="break-all flex text-xs flex-col ml-2">
                      <div className="font-semibold">
                        {documentDetails?.documentName ||
                          row?.documentName ||
                          row?.formJson?.title ||
                          row?.name ||
                          "Untitled"}
                      </div>
                      <div className="flex flex-wrap gap-2 text-[10px] mb-[2px]">
                        <span
                          className=""
                          style={{
                            color: getStatusByPayment({ document: row }).color,
                          }}
                        >
                          {getStatusByPayment({ document: row }).status}
                        </span>
                        <span className="text-gray-600">
                          {getStatusByPayment({ document: row }).message}
                        </span>
                      </div>
                      <div className="flex text-[10px] gap-2">
                        <span className="text-gray-700">
                          {new Date(row.createdAt).toDateString()}
                        </span>
                        <span className="text-gray-500">
                          {new Date(row.createdAt).toLocaleTimeString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center">
                    <div className="flex flex-col items-center w-fit">
                      <div className={``}>
                        {row?.signers?.every(
                          (signer) => signer.status === "SIGNED"
                        ) && (
                          <MdOutlineDownloadDone className="text-lg text-green-400" />
                        )}
                        {!row?.signers?.every(
                          (signer) => signer.status === "SIGNED"
                        ) && (
                          <MdOutlinePending className="text-lg text-yellow-400" />
                        )}
                      </div>
                      <p className="text-[10px] text-gray-500">
                        {
                          row?.signers?.filter(
                            (signer) => signer.status === "SIGNED"
                          ).length
                        }{" "}
                        / {row?.signers?.length}
                      </p>
                    </div>
                    <button
                      disabled={
                        !(row?.status === "SIGNED" || row?.status === "PAID") ||
                        loading
                      }
                      onClick={async (e) => {
                        e.stopPropagation();
                        new MixPanel().track("PDF template", {
                          pdf_template_action: "pdf_template_response_download",
                          paid: false,
                        });
                        new MixPanel().increment(
                          "pdf_template_response_download"
                        );
                        handleDownload(row);
                      }}
                      className={`w-fit whitespace-nowrap flex flex-row justify-center items-center text-center hover:opacity-90 text-xs rounded capitalize border px-2 py-1.5 bg-secondary/60 text-primary font-medium border-primary/50 hover:border-primary shadow-sm`}
                    >
                      Download
                    </button>
                    <div className="relative">
                      <DropDownActionOptions
                        documentData={row}
                        setIsModalOpen={setIsModalOpen}
                        setRespondentsHistory={setRespondentsHistory}
                        setRespondentForHistory={setRespondentForHistory}
                        setRespondentStatus={setRespondentStatus}
                        setLoading={setLoading}
                        loading={loading}
                        documentDetails={documentDetails}
                        setShowUpgradeModal={setShowUpgradeModal}
                        setUpgradeModalType={setUpgradeModalType}
                        setIsSendACopyModalOpen={setIsSendACopyModalOpen}
                        setDocumentForSendACopy={setDocumentForSendACopy}
                        setIsSigningOrderDiagram={setIsSigningOrderDiagram}
                        setSigningOrderRecipientList={
                          setSigningOrderRecipientList
                        }
                        setCurrOrderDocument={setCurrOrderDocument}
                        setDeletingResponse={setDeletingResponse}
                        setShow={setShow}
                        roleInCollaboration={roleInCollaboration}
                        setGeneratedLinksData={setGeneratedLinksData}
                        setShowShareViaLink={setShowShareViaLink}
                        setShowSigningInPerson={setShowSigningInPerson}
                        setUpgradeModalData={setUpgradeModalData}
                        setShowGenerateLinksModal={setShowGenerateLinksModal}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* 
      {window.innerWidth <= 768 && (
        <div className="block md:hidden">
          <div className="flex flex-col">
            {rowData.map((row, idx) => {
              return (
                <div key={idx} className="flex p-2 gap-3">
                  <IssueOptions documentData={row} />
                  <span
                    onClick={() => {
                      console.log("Issue options without component", row?._id);
                    }}
                  >
                    <BsThreeDots className="text-2xl hover:opacity-85 bg-white py-1 rounded-md border" />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      */}

      {show.deleteResponse && (
        <div className="flex flex-row items-center px-5 py-3 border-t-2">
          <button
            onClick={() => {
              setShow((prev) => {
                return {
                  ...prev,
                  bulkDeleteResponseModal: true,
                };
              });
            }}
            className="w-[80px] h-[27px] text-[10px] text-white bg-[#E0413E] px-2 py-1 rounded-md"
          >
            Delete
          </button>
        </div>
      )}

      {(searchResults?.message === "No result found for this search" ||
        allRespondents?.respondents?.length === 0) &&
        !loading && (
          <div className="w-full flex flex-col items-center py-12 text-gray-600">
            <div className="mb-4">
              <FiSearch className="text-5xl" />
            </div>
            <p className="text-sm">
              {searchResults?.message ?? "No Entries Found!"}
            </p>
          </div>
        )}
      <DashboardBottomNav count={allRespondents.respondentsCount || 0} />

      {show.bulkApproveRejectModal && (
        <Modal isWidthFit={false} modalContent={bulkApproveRejectModal()} />
      )}
      {show.bulkDeleteResponseModal && (
        <Modal modalContent={bulkDeleteResponseModal()} />
      )}

      {show.deleteOneResponseModal && (
        <Modal modalContent={deleteOneResponseModal()} />
      )}

      {show.voidDocumentModal && <Modal modalContent={voidDocumentModal()} />}

      {isSendACopyModalOpen && documentForSendACopy?.documentUrl && (
        <Modal
          modalContent={SendACopy({
            documentId: documentForSendACopy?.documentId,
            documentName: documentForSendACopy?.documentName,
            documentUrl: documentForSendACopy?.documentUrl,
            documentType: documentForSendACopy?.documentType,
            setting: documentForSendACopy?.setting,
            setOpen: setIsSendACopyModalOpen,
            active: activeForSendModal,
            setActive: setActiveForSendModal,
            email: emailForSendModal,
            setEmail: setEmailForSendModal,
          })}
        />
      )}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />

      {isModalOpen && (
        <HistoryModal
          setIsModalOpen={setIsModalOpen}
          history={respondentHistory}
          documentDetails={documentDetails}
          respondent={respondentForHistory}
          ifRespondentStatus={respondentStatus}
        />
      )}
      <Tooltip
        id="my-tooltip"
        className="border border-gray-300 rounded-md shadow-md"
        style={{
          backgroundColor: "#f3f4f6",
          padding: "0px",
        }}
        noArrow={true}
        openOnClick={true}
        closeOnScroll={true}
        closeOnEsc={true}
        clickable={true}
        opacity={95}
      >
        <div
          className="gap-2 p-2"
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "250px",
            overflowY: "auto",
          }}
        >
          {/* <span className="text-xs text-gray-700 mb-2">Owner</span> */}
          <div className="p-2 flex gap-2 items-start justify-start rounded-md w-full bg-white mb-2">
            <MdAccountCircle className="text-xl rounded-full bg-gray-500" />
            <div className="flex flex-col gap-1">
              <p className="text-xs text-gray-700 flex items-center gap-2">
                {showRoles?.[0]?.owner}
              </p>
              <span className="text-xs text-gray-500">
                {showRoles?.[0]?.ownerEmail}
              </span>
              <span className="text-xs text-gray-500">
                Last modified {showRoles?.[0]?.lastModified}
              </span>
            </div>
          </div>

          {showApprovers?.length > 0 && (
            <span className="text-xs text-gray-700 mb-2">Approvers</span>
          )}
          {showApprovers?.map((item: any, index: any) => {
            return (
              <div
                className="p-2 flex gap-2 items-start justify-start rounded-md shadow hover:bg-secondary w-full bg-white"
                key={index}
              >
                <MdAccountCircle
                  className="text-xl rounded-full"
                  style={{ background: item.colour }}
                />
                <div className="flex flex-col gap-1">
                  <p className="text-xs text-gray-700 flex items-center gap-2">
                    {item.name}
                    <span className="">
                      {item?.status === "APPROVED" ? (
                        <MdOutlineDoneAll className="text-orange-600" />
                      ) : item?.status === "REJECTED" ? (
                        <MdClose className="text-red-600" />
                      ) : (
                        <AiOutlineClockCircle className="text-red-600" />
                      )}
                    </span>{" "}
                  </p>
                  <span className="text-xs text-gray-500">{item.email}</span>
                  <span className="text-xs text-gray-500">{item.status}</span>
                </div>
              </div>
            );
          })}
          <span className="text-xs text-gray-700 mb-2">Signers</span>
          {showRoles?.map((item: any, index: any) => {
            return (
              <div
                className="p-2 flex gap-2 items-start justify-start rounded-md shadow hover:bg-secondary w-full bg-white"
                key={index}
              >
                <MdAccountCircle
                  className="text-xl rounded-full"
                  style={{ background: item.colour }}
                />
                <div className="flex flex-col gap-1">
                  <p className="text-xs text-gray-700 flex items-center gap-2">
                    {item.title}
                    <span className="">
                      {item?.status === "SIGNED" ? (
                        <MdOutlineDoneAll className="text-green-600" />
                      ) : (
                        <AiOutlineClockCircle className="text-red-600" />
                      )}
                    </span>{" "}
                  </p>
                  <span className="text-[10px] text-gray-500">{item.name}</span>
                  <span className="text-xs text-gray-500">{item.email}</span>
                  <span className="text-xs text-gray-500">{item.phone}</span>
                  <span className="text-xs text-gray-500">{item.status}</span>
                  {item.accessCode && (
                    <span className="text-xs text-gray-500">
                      Access Code:{" "}
                      <span className="font-semibold tracking-wide">
                        {item?.accessCode}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Tooltip>
      <Tooltip id="download-tooltip">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>You can download the pdf </span>
          <span>when all parties have signed!</span>
        </div>
      </Tooltip>
    </div>
  );
};
export default PdfTemplateRespondents;

const CellExtended = ({ width, value }: any) => {
  if (typeof value === "object") {
    return null;
  }

  return (
    <div
      className={`w-full min-h-[45px] max-h-[225px] py-3 px-6 border-x border-b  text-sm absolute z-20 top-0 right-0 bg-gray-50 overflow-y-auto`}
    >
      <div>{value}</div>
    </div>
  );
};
