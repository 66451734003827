import React from "react";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import { BoloButton, buttonVariants } from "../Common/Button/BoloButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../Common/SelectDropDown/BoloDropDown";

const DashboardBottomNav = ({ count }: { count: number }) => {
  const { setSearchData, getSearchData } = useQueryHook();
  return (
    <div className="h-[64px] max-h-[64px] flex flex-row justify-between items-start md:items-center px-3 md:px-5 py-3 border-t md:border-t-2">
      <div className=" flex flex-col md:flex-row gap-1 md:gap-3">
        <div className="flex gap-2 items-center">
          <BoloButton
            size={"sm"}
            variant="gray-shadow"
            onClick={() => {
              let data = getSearchData();
              setSearchData({ pageNo_: data.pageNo_ - 1 });
            }}
            disabled={getSearchData().pageNo_ === 1}
            // className="mr-2 py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
          >
            Previous
          </BoloButton>
          <div className="hidden text-gray-500 text-xs md:text-sm md:flex items-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                {/* <div className="ml-2 w-[32px] h-[32px] border rounded text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-70 text-center flex items-center justify-center">
                <p>{getSearchData().pageNo_ || 1}</p>
              </div> */}
                <div
                  className={buttonVariants({
                    variant: "gray-shadow",
                    size: "icon-sm",
                    className:
                      "w-[32px] h-[32px] flex items-center justify-center",
                  })}
                >
                  {getSearchData().pageNo_ || 1}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuGroup>
                <DropdownMenuContent
                  align="end"
                  sideOffset={15}
                  className="w-10 max-h-[200px] overflow-y-auto"
                  id="mini-pdf-custom-scroll"
                >
                  {Array(Math.ceil(count / getSearchData().limit_))
                    .fill(0)
                    .map((_: any, idx: number) => {
                      return (
                        <DropdownMenuItem
                          onClick={() => setSearchData({ pageNo_: idx + 1 })}
                          key={idx}
                        >
                          <p className="text-gray-500 text-xs md:text-sm">
                            {idx + 1}
                          </p>
                        </DropdownMenuItem>
                      );
                    })}
                </DropdownMenuContent>
              </DropdownMenuGroup>
            </DropdownMenu>
          </div>
          <BoloButton
            size={"sm"}
            variant="gray-shadow"
            disabled={getSearchData().pageNo_ * getSearchData().limit_ >= count}
            onClick={() => {
              let data = getSearchData();
              setSearchData({ pageNo_: data.pageNo_ + 1 });
            }}
            // className="py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
          >
            Next
          </BoloButton>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-end md:items-center gap-1 md:gap-6">
        <div className="text-gray-500 text-xs md:text-sm flex items-center">
          No of Rows
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              {/* <div className="mx-2 w-10 py-2 px-2 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-70 text-center">
                <p>{getSearchData().limit_ || 10}</p>
              </div> */}
              <div
                className={buttonVariants({
                  variant: "gray-shadow",
                  size: "icon-sm",
                  className:
                    "mx-2 w-[32px] h-[32px] flex items-center justify-center",
                })}
              >
                {getSearchData().limit_ || 10}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuGroup>
              <DropdownMenuContent align="end" sideOffset={15} className="w-10">
                <DropdownMenuItem
                  onClick={() => setSearchData({ limit_: 10, pageNo_: 1 })}
                >
                  <p className="text-gray-500 text-xs md:text-sm">10</p>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setSearchData({ limit_: 20, pageNo_: 1 })}
                >
                  <p className="text-gray-500 text-xs md:text-sm">20</p>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setSearchData({ limit_: 30, pageNo_: 1 })}
                >
                  <p className="text-gray-500 text-xs md:text-sm">30</p>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setSearchData({ limit_: 40, pageNo_: 1 })}
                >
                  <p className="text-gray-500 text-xs md:text-sm">40</p>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setSearchData({ limit_: 50, pageNo_: 1 })}
                >
                  <p className="text-gray-500 text-xs md:text-sm">50</p>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenuGroup>
          </DropdownMenu>
          per page
        </div>

        <div className="text-gray-500 text-xs md:text-sm ">
          {" "}
          <div>{`Page ${getSearchData().pageNo_} of ${Math.ceil(
            count / getSearchData().limit_
          )}`}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBottomNav;
