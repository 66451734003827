// @ts-nocheck

import {
  Document,
  Font,
  Image,
  Link,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../utils/axios";
import { isImageUrlInResponseValue } from "../../utils/isImageUrlInResponseValue";

const ResponsePdfViewer = () => {
  const [pdfData, setPdfData] = React.useState({
    response: {},
    form: {},
    owner: {},
  });
  const { id } = useParams();

  async function getRespondent() {
    try {
      let { data } = await axios.get(`/respondents?respondentId=${id}`);
      console.log("data", data);

      setPdfData({
        response: data?.respondents?.response,
        form: data?.respondents?.form,
        owner: data?.owner,
      });
    } catch (error) {
      console.log(`error getting respondent ${error}`);
    }
  }

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: "col",
      backgroundColor: "white",
      fontFamily: "Open Sans",
      padding: 20,
    },
    section: {
      margin: 10,
    },
    questionContainer: {
      marginBottom: 15,
    },
    que: {
      fontSize: 10,
      marginBottom: 8,
      fontWeight: 600,
    },
    ans: {
      fontSize: 12,
    },
    companyLogo: {
      width: 100,
      height: 100,
      marginBottom: 10,
    },
    linkWrapper: {
      marginBottom: 10,
      marginRight: 10,
    },
    link: {
      color: "blue",
      padding: 5,
      textDecoration: "none",
    },
    image: {
      width: 100,
      height: 100,
    },
    companyName: {
      fontWeight: 600,
    },
  });

  Font.register({
    family: "Open Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
        fontWeight: 600,
      },
    ],
  });

  let responses = {
    "What is your favorite color?":
      "My favorite color is blue. I find it calming and serene.",
    "Can you tell me more about lorem dcvdf 1?":
      "Certainly. Lorem dcvdf 1 is a concept that refers to...",
    "How do you feel about lorem dcvdf 2?":
      "I have mixed feelings about lorem dcvdf 2. On the one hand, it...",
    "What is the purpose of lorem dcvdf 3?":
      "The purpose of lorem dcvdf 3 is to...",
    "Can you give me an example of lorem dcvdf 4 in action?":
      "Sure. An example of lorem dcvdf 4 in action would be...",
    "How does lorem dcvdf 5 affect the environment?":
      "Lorem dcvdf 5 has a significant impact on the environment. It...",
    "What are some common misconceptions about lorem dcvdf 6?":
      "There are several common misconceptions about lorem dcvdf 6. One of them is that...",
    "Can you explain the relationship between lorem dcvdf 7 and lorem dcvdf 1?":
      "Certainly. Lorem dcvdf 7 and lorem dcvdf 1 are closely related because...",
    "What is the history of lorem dcvdf 2?":
      "The history of lorem dcvdf 2 can be traced back to...",
    "How can we prevent negative effects of lorem dcvdf 3 on society?":
      "Preventing negative effects of lorem dcvdf 3 on society requires a concerted effort from...",
    Signature:
      "https://boloforms-main.s3.ap-south-1.amazonaws.com/approval-form-uploads/1677248540267.png,https://boloforms-main.s3.ap-south-1.amazonaws.com/approval-form-uploads/1677248540267.png",
  };

  useEffect(() => {
    getRespondent();
  }, []);

  const getResponseValue = (value) => {
    if (
      value[0] &&
      value[0].includes(
        "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature"
      )
    ) {
      value = value[0];
    }
    value = String(value)?.includes("http")
      ? value.split(",").map((url, i) => (
          <View style={styles.linkWrapper} key={i}>
            <Link style={styles.link} src={url}>
              {isImageUrlInResponseValue(url) ? (
                <Image style={styles.image} src={url} />
              ) : (
                `Link ${i + 1}`
              )}
            </Link>
          </View>
        ))
      : typeof value !== "string"
      ? JSON.stringify(value)
      : value;
    console.log(value);
    return value;
  };

  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image
            style={styles.companyLogo}
            // src={pdfData?.owner?.branding?.companyLogo}
            src={
              pdfData?.form?.formJson?.logo || "https://i.imgur.com/jvjNDSr.png"
            }
            // src="https://i.imgur.com/jvjNDSr.png"
          />
          <Text style={styles.companyName}>
            {pdfData?.form?.formJson?.title || "Untitled Form"}
          </Text>
        </View>
        <View style={styles.section}>
          {Object?.entries(pdfData.response).map(([key, value], index) => {
            return (
              <View key={index} style={styles.questionContainer}>
                <Text style={styles.que}>{key}</Text>
                <Text style={styles.ans}>{getResponseValue(value)}</Text>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
  return (
    <PDFViewer className="w-full h-screen">
      <MyDocument />
    </PDFViewer>
  );
};

export default ResponsePdfViewer;
