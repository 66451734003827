import mixpanel from "mixpanel-browser";
import Cookies from "js-cookie";
import { MIXPANEL_TOKEN } from "../../constants/constants";

mixpanel.init(MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: "full-url",
  persistence: "localStorage",
});

export default class MixPanel {
  constructor() {
    this.mixpanel = mixpanel;
  }
  identify(id) {
    this.mixpanel.identify(id);
  }
  alias(id) {
    this.mixpanel.alias(id);
  }
  trackPageView() {
    // Get the current page title
    const pageTitle = document.title;
    if (pageTitle === "Dashboard") {
      return;
    }
    const dashboard_language = Cookies.get("googtrans");
    this.mixpanel.track("Page View - " + pageTitle, { dashboard_language });
  }
  track(name, props) {
    this.mixpanel.track(name, props);
  }
  peopleSet(props) {
    this.mixpanel.people.union(props);
  }
  increment(props) {
    this.mixpanel.people.increment(props);
  }
  trackAndIncrement(name, trackProp, incProp) {
    this.mixpanel.track(name, trackProp);
    this.mixpanel.people.increment(incProp);
  }
  setupMixPanel() {
    let user = JSON.parse(localStorage.getItem("owner"));
    if (!user) return;
    console.log("setting up mixpanel for ...", user);
    let planhistory = user?.planhistory[user?.planhistory.length - 1];

    mixpanel.identify(user._id);
    // setting up user profile
    mixpanel.people.set({
      name: user?.name, // Full name
      $email: user?.email, // Email address
      $created: user.createdAt,
      plan_name: planhistory.planName,
      plan_tier: planhistory.tier,
      plan_is_active: planhistory.isActive,
      plan_price: planhistory.planPrice,
      from: user?.from,
      hearAboutUs: user?.hearAboutUs || "",
    });
    // setgins up super property so that it is sent with each event
    //   mixpanel.register({

    // });
    // this.trackPageView();
  }
}
