import { useEffect, useState } from "react";
import { BoloButton } from "../Common/Button/BoloButton";
import axios from "../../utils/axios";
import { toast, ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setOwnerRx } from "../../redux/slices/ownerSlice";
import { IMAGE_LINKS, POLICY_LINKS } from "../../constants/common";
import useWhiteLabelHelper from "../../hooks/reduxHooks/useWhiteLabelHelper";
import TrialExtendedModal from "../TrialExtendedModal";
interface FeedInfoModalProps {
  setShowInfoModal: React.Dispatch<boolean>;
}

const FeedInfoModal = ({ setShowInfoModal }: FeedInfoModalProps) => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    orgName: "",
    orgSize: "",
    department: "",
    hearAboutUs: "",
  });
  const [additionalInfo, setAdditionalInfo] = useState<string>("");
  const dispatch = useAppDispatch();
  const [fieldNotFilled, setFieldNotFilled] = useState<boolean>(false);
  const [showPhoneNumberModal, setShowPhoneNumberModal] =
    useState<boolean>(false);
  const [phoneInput, setPhoneInput] = useState(null);
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );

  const [showTrialGivenModal, setShowTrialGivenModal] =
    useState<boolean>(false);

  const whiteLabelHelper = useWhiteLabelHelper();
  const submitInfo = async (buttonType?: string) => {
    var phoneNumber = null;
    if (buttonType !== "skip") {
      const phoneInputField = document.querySelector("#phoneInputField");
      // @ts-ignore
      phoneNumber = phoneInputField.value;
      // @ts-ignore

      var countryCode = phoneInput.getSelectedCountryData().dialCode;
      console.log(countryCode, phoneNumber);
      // @ts-ignore

      let isValidNumber = phoneInput.isValidNumber();
      //   console.log("isValidNumber", isValidNumber);
      if (!isValidNumber) {
        return toast.error("Please enter a valid phone number");
      }
    }

    let { firstName, lastName, orgName, orgSize, department, hearAboutUs } =
      userInfo;
    if (
      firstName === "" ||
      lastName === "" ||
      orgName === "" ||
      orgSize === "" ||
      department === "" ||
      hearAboutUs === ""
    ) {
      setFieldNotFilled(true);
      return;
    }
    if (hearAboutUs === "Others" && additionalInfo === "") {
      setFieldNotFilled(true);
      return;
    }
    if (hearAboutUs === "Others") {
      hearAboutUs = additionalInfo;
    }
    try {
      const { data } = await axios.post("/owners/onboarding-info", {
        ownerId: owner?._id,
        name: firstName + " " + lastName,
        orgName,
        orgSize,
        department,
        hearAboutUs,
        phoneNumber,
      });
      if (data?.success) {
        dispatch(setOwnerRx({ ...owner, ...data?.owner }));
        // setShowInfoModal(false);
        setShowPhoneNumberModal(false);
        setShowTrialGivenModal(true);
      } else {
        toast.error("Some Error Occuured!! ");
      }
    } catch (error) {
      toast.error("Some Error Occuured!! ");
      console.log(error);
    }
  };

  const goToNext = () => {
    let { firstName, lastName, orgName, orgSize, department, hearAboutUs } =
      userInfo;
    if (
      firstName === "" ||
      lastName === "" ||
      orgName === "" ||
      orgSize === "" ||
      department === "" ||
      hearAboutUs === ""
    ) {
      setFieldNotFilled(true);
      return;
    }
    if (hearAboutUs === "Others" && additionalInfo === "") {
      setFieldNotFilled(true);
      return;
    }
    setShowPhoneNumberModal(true);
  };
  const openExternalLink = (url: string) => {
    window.open(url, "_blank");
  };
  useEffect(() => {
    if (showPhoneNumberModal) {
      const phoneInputField = document.querySelector("#phoneInputField");
      if (!phoneInput) {
        // @ts-ignore
        const phoneInput = window.intlTelInput(phoneInputField, {
          initialCountry: "auto",
          autoPlaceholder: true,
          separateDialCode: true,
          nationalMode: false,
          formatOnDisplay: true,
          preferredCountries: ["us", "gb", "in", "ph", "mx"],
          geoIpLookup: function (callback: any) {
            try {
              callback("us");

              axios
                .get("https://ipinfo.io?token=4033074164c489")
                .then((resp) => {
                  resp = resp.data;
                  // @ts-ignore
                  var countryCode = resp && resp.country ? resp.country : "us";
                  // console.log("countryCode", countryCode);
                  callback(countryCode);
                });
            } catch (error) {
              console.log("error", error);
              callback("us");
            }
          },

          utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        });
        setPhoneInput(phoneInput);
      }
    }
  }, [showPhoneNumberModal]);

  return (
    <div
      className="w-full h-screen fixed inset-0 flex flex-col gap-3 justify-center items-center z-50"
      style={{
        background: "rgba(0, 0, 0, 0.3)",
      }}
    >
      {whiteLabelHelper?.isWhiteLabel && (
        <div
          className="absolute inset-0 w-full h-full"
          style={{
            // backgroundImage: "url('https://i.imgur.com/W7rv26V.png')",
            // backgroundSize: "cover",
            // filter: "blur(5px)",
            backdropFilter: "blur(4px)",
          }}
        ></div>
      )}
      {/* <div className="absolute inset-0 w-full h-full bg-black opacity-40"></div> */}
      <div className="flex gap-2 items-center" style={{ zIndex: "200" }}>
        <img
          src={
            whiteLabelInfo?.settings?.organization?.logo ||
            IMAGE_LINKS?.BOLOLOGO
          }
          alt={
            whiteLabelInfo?.settings?.organization?.name ||
            "BoloForms Signature"
          }
          className=" w-10 md:w-14 h-10 md:h-14"
        />
        <span className="text-white text-base md:text-xl font-semibold">
          {whiteLabelInfo?.settings?.organization?.name ||
            "BoloForms Signature"}
        </span>
      </div>
      {!showTrialGivenModal && (
        <div
          className={`min-h-fit max-h-[750px] w-[95%] sm:w-fit
        relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto `}
        >
          {showPhoneNumberModal ? (
            <div className="py-3 max-w-[450px]">
              <div>
                <div className="flex flex-col">
                  <div className="w-full flex flex-col mt-4">
                    <label className="text-xs text-gray-600">
                      Phone Number
                    </label>
                    <div className="mt-2">
                      <input
                        id="phoneInputField"
                        className="py-2 w-full outline-none border border-primary rounded-md"
                        placeholder="Enter your phone number"
                      />
                    </div>
                    <p className="text-xs mt-2 text-gray-500">
                      We are asking your number to make sure you setup the
                      processes correctly and everything works fine. We won’t
                      spam you ever, promise.
                    </p>
                  </div>
                </div>
                <p className="text-[10px] text-gray-500 my-2 mt-8">
                  This information will be securely stored and will not be
                  shared with anyone as per our{" "}
                  <span
                    onClick={() =>
                      openExternalLink(
                        whiteLabelInfo?.settings?.organization?.policy?.tos ||
                          POLICY_LINKS.TOS
                      )
                    }
                    className="text-primary cursor-pointer"
                  >
                    Terms of Service
                  </span>{" "}
                  &{" "}
                  <span
                    onClick={() =>
                      openExternalLink(
                        whiteLabelInfo?.settings?.organization?.policy
                          ?.privacy || POLICY_LINKS?.PRIVACY
                      )
                    }
                    className="text-primary cursor-pointer"
                  >
                    Privacy Policy
                  </span>
                </p>
                <div className=" flex gap-3 mt-2">
                  <BoloButton
                    variant={"secondary"}
                    className="w-full"
                    size={"md"}
                    onClick={() => submitInfo("skip")}
                  >
                    <span className="font-medium">Skip</span>
                  </BoloButton>
                  <BoloButton
                    variant={"primary"}
                    className="w-full"
                    size={"md"}
                    onClick={() => submitInfo()}
                  >
                    <span className="font-medium">Finish</span>
                  </BoloButton>
                </div>
              </div>
            </div>
          ) : (
            <div className="py-3 max-w-[500px]">
              <div className="text-center text-xl lg:text-2xl font-bold mb-2">
                Welcome!
              </div>
              <div className="text-center text-gray-500 text-sm lg:text-base mb-8">
                Add your info to make collaborating easy
              </div>
              <div className="flex gap-3">
                <div className="w-[50%]">
                  <label className="text-xs text-gray-600">First Name</label>
                  <input
                    className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none"
                    type="text"
                    placeholder="First Name"
                    value={userInfo?.firstName}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }))
                    }
                  />
                  {fieldNotFilled && userInfo?.firstName === "" && (
                    <span className="text-xs text-red-500">
                      *This is a required field.
                    </span>
                  )}
                </div>
                <div className="w-[50%]">
                  <label className="text-xs text-gray-600">Last Name</label>
                  <input
                    className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none"
                    type="text"
                    placeholder="Last Name"
                    value={userInfo?.lastName}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }))
                    }
                  />
                  {fieldNotFilled && userInfo?.lastName === "" && (
                    <span className="text-xs text-red-500">
                      *This is a required field.
                    </span>
                  )}
                </div>
              </div>

              <div className="flex gap-3">
                <div className="w-[50%]">
                  <label className="text-xs text-gray-600">
                    Organization Name
                  </label>
                  <input
                    className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none text-gray-700"
                    type="text"
                    placeholder="Organization Name"
                    value={userInfo?.orgName}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        orgName: e.target.value,
                      }))
                    }
                  />
                  {fieldNotFilled && userInfo?.orgName === "" && (
                    <span className="text-xs text-red-500">
                      *This is a required field.
                    </span>
                  )}
                </div>
                <div className="w-[50%]">
                  <label className="text-xs text-gray-600">
                    Organization Size
                  </label>
                  <div className="mb-2 mt-1 text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                    <select
                      className="w-full outline-none p-2"
                      value={userInfo?.orgSize}
                      onChange={(e) =>
                        setUserInfo((prev) => ({
                          ...prev,
                          orgSize: e.target.value,
                        }))
                      }
                    >
                      <option value="" className="hidden">
                        Select
                      </option>
                      <option value="1-10">1-10 Employees</option>
                      <option value="11-50">11-50 Employees</option>
                      <option value="51-100">51-100 Employees</option>
                      <option value="101-500">101-500 Employees</option>
                      <option value="501-1000">501-1000 Employees</option>
                      <option value="1001-5000">1001-5000 Employees</option>
                      <option value="5000+">5000+ Employees</option>
                    </select>
                  </div>
                  {fieldNotFilled && userInfo?.orgSize === "" && (
                    <span className="text-xs text-red-500">
                      *This is a required field.
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col my-1">
                <p className="text-gray-600 text-xs">Your Department</p>
                <div className="mt-2 text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                  <select
                    className="w-full outline-none p-2"
                    value={userInfo?.department}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        department: e.target.value,
                      }))
                    }
                  >
                    <option value="" className="hidden">
                      Select
                    </option>
                    <option value="sales">Sales</option>
                    <option value="marketing">Marketing</option>
                    <option value="customer-support">Customer Support</option>
                    <option value="it">IT</option>
                    <option value="finance">Finance</option>
                    <option value="human-resources">Human Resources</option>
                    <option value="operations">Operations</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                {fieldNotFilled && userInfo?.department === "" && (
                  <span className="text-xs text-red-500 mt-3">
                    *This is a required field.
                  </span>
                )}
              </div>
              <div className="flex flex-col mt-3">
                <p className="text-gray-600 text-xs">
                  Where did you hear about{" "}
                  {whiteLabelInfo?.settings?.organization?.name || "BoloForms"}?
                </p>
                <div className="mt-2 text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                  <select
                    className="w-full outline-none p-2"
                    value={userInfo?.hearAboutUs}
                    onChange={(e) =>
                      setUserInfo((prev) => ({
                        ...prev,
                        hearAboutUs: e.target.value,
                      }))
                    }
                  >
                    <option value="" className="hidden">
                      Select
                    </option>
                    <option value="Facebook">Facebook</option>
                    <option value="AppSumo">AppSumo</option>
                    <option value="Google">Google</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Bing">Bing</option>
                    <option value="Twitter">Twitter</option>
                    <option value="Reddit">Reddit</option>
                    <option value="Tiktok">Tiktok</option>
                    <option value="Word of Mouth">Word of Mouth</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                {userInfo?.hearAboutUs === "Others" && (
                  <div>
                    <label className="text-xs text-gray-600">
                      Please specify
                    </label>
                    <input
                      className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none"
                      type="text"
                      placeholder="Please specify"
                      value={additionalInfo}
                      onChange={(e) => setAdditionalInfo(e.target.value)}
                    />
                  </div>
                )}
                {fieldNotFilled &&
                  (userInfo?.hearAboutUs === "" ||
                    (userInfo?.hearAboutUs === "Others" &&
                      additionalInfo === "")) && (
                    <span className="text-xs text-red-500 mt-3">
                      *This is a required field.
                    </span>
                  )}
              </div>
              <p className="text-[10px] text-gray-500 my-2 mt-8">
                This information will be securely stored and will not be shared
                with anyone as per our{" "}
                <span
                  onClick={() =>
                    openExternalLink(
                      whiteLabelInfo?.settings?.organization?.policy?.tos ||
                        POLICY_LINKS.TOS
                    )
                  }
                  className="text-primary cursor-pointer"
                >
                  Terms of Service
                </span>{" "}
                &{" "}
                <span
                  onClick={() =>
                    openExternalLink(
                      whiteLabelInfo?.settings?.organization?.policy?.privacy ||
                        POLICY_LINKS.PRIVACY
                    )
                  }
                  className="text-primary cursor-pointer"
                >
                  Privacy Policy
                </span>
              </p>
              <div className="mt-2">
                <BoloButton
                  variant={"primary"}
                  className="w-full"
                  size={"md"}
                  onClick={goToNext}
                >
                  <span className="font-medium">Next</span>
                </BoloButton>
              </div>
            </div>
          )}
        </div>
      )}

      {showTrialGivenModal && (
        <TrialExtendedModal
          show={true}
          setShow={() => {
            setShowTrialGivenModal(false);
            setShowInfoModal(false);
          }}
          completedTask={{
            title: "NO_TITLE",
            description: "NO_DESCRIPTION",
            increasedDays: 0,
          }}
          isOnBoarding={true}
        />
      )}

      <ToastContainer className={`text-xs`} position="bottom-center" />
    </div>
  );
};

export default FeedInfoModal;
