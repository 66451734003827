import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants/constants";
import axios from "../../utils/axios";
import { DropdownMenuItem } from "../Common/SelectDropDown/BoloDropDown";
import { FiExternalLink, FiShare2 } from "react-icons/fi";
import { IoCopyOutline } from "react-icons/io5";
import { RiSendPlaneLine } from "react-icons/ri";
import { PiFilePdfLight } from "react-icons/pi";
import {
  MdOutlinePictureAsPdf,
  MdOutlineDriveFileMoveRtl,
  MdOutlineMessage,
} from "react-icons/md";
import { HiOutlineDocumentRemove } from "react-icons/hi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { LiaEditSolid } from "react-icons/lia";
import { toast } from "react-toastify";
import { isFeatureEnabled } from "../../utils/paidFeat";
import { LuFolderPlus } from "react-icons/lu";
import MixPanel from "../../utils/services/mixpanel";
import "./action-dropdown.css";

function getFromFillLink(form: any) {
  console.log(form);
  if (form?.formType === "GOOGLE_FORM") {
    return `${BASE_URL?.slice(0, BASE_URL?.length - 4)}/form/${form?._id}`;
  }
  return form.formUrl;
}
function openExternalLink(link: any) {
  window.open(link, "_blank");
}

const DropdownActionsForms = ({
  form,
  dropdownState,
  updateDropdownState,
  type = "DEFAULT",
  folderId = null,
}: any) => {
  let navigate = useNavigate();
  const cloneForms = async (formId: any) => {
    try {
      const ownerId = JSON.parse(localStorage.getItem("owner") || "")?._id;
      let data;
      if (type === "DEFAULT") {
        const response = await axios.post(
          `/forms/clone?formId=${formId}&ownerId=${ownerId}`
        );
        data = response.data;
      } else {
        const response = await axios.post(`/forms/clone?formId=${formId}`, {
          folderId: folderId,
        });
        data = response.data;
      }

      if (data.error === "You have exhausted your allowed quota") {
        updateDropdownState("isQuotaExausted", true);
        return;
      }
      if (!data.error) {
        updateDropdownState("reload", !dropdownState.reload);
        return toast.success("Form cloned successfully");
      } else {
        return toast.error(data.error);
      }
    } catch (error) {
      console.log("cloning form error: ", error);
      return toast.error("Failed to clone form");
    }
  };

  const removeCustomPdf = async (formId: any) => {
    try {
      const { data } = await axios.post(`/forms/removePdf?formId=${formId}`);
      if (!data.error) {
        updateDropdownState("reload", !dropdownState.reload);
        return toast.success("Custom Pdf was successfully removed");
      } else {
        return toast.error(data.error);
      }
    } catch (error) {
      console.log("Pdf removing form error: ", error);
      return toast.error("Failed to remove Pdf");
    }
  };
  const actions = [
    {
      show: type === "FOLDER",
      onClick: (e: any) => {
        e.stopPropagation();
        new MixPanel().track("Folder", {
          folder_action: "folder_form_see_responses",
          paid: false,
        });
        new MixPanel().increment("folder_form_see_responses");
        navigate(`/form?id=${form?.formId}`);
      },
      disabled: false,
      icon: <MdOutlineMessage className="text-xl" />,
      label: "Responses",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: (e: any) => {
        e.stopPropagation();
        openExternalLink(getFromFillLink(form));
      },
      disabled: false,
      icon: <FiExternalLink className="text-xl" />,
      label: "Preview",
      hiddenOnDesktop: type === "DEFAULT",
    },
    {
      show: true,
      onClick: (e: any) => {
        e.stopPropagation();
        if (type == "DEFAULT") {
          new MixPanel().track("Form", {
            form_action: "form_edit",
            paid: false,
          });
          new MixPanel().increment("form_edit");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_form_edit",
            paid: false,
          });
          new MixPanel().increment("folder_form_edit");
        }
        navigate(`/create/forms?id=${form.formId}`);
      },
      disabled:
        !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
        dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly",
      icon: <LiaEditSolid className="text-xl" />,
      label: "Edit Form",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async (e: any) => {
        e.stopPropagation();
        if (type === "FOLDER") {
          new MixPanel().track("Folder", {
            folder_action: "folder_form_copy",
            paid: false,
          });
          new MixPanel().increment("folder_form_copy");
        } else {
          new MixPanel().track("Form", {
            form_action: "form_copy",
            paid: false,
          });
          new MixPanel().increment("form_copy");
        }

        await cloneForms(form?.formId);
      },
      disabled:
        !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
        dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly",
      icon: <IoCopyOutline className="text-xl" />,
      label: "Copy Form",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async (e: any) => {
        const isBulkSendEnabled = await isFeatureEnabled("BULK_SEND");
        if (type === "DEFAULT") {
          new MixPanel().track("Form", {
            form_action: "form_bulk_send",
            paid: true,
          });
          new MixPanel().increment("form_bulk_send");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_form_bulk_send",
            paid: true,
          });
          new MixPanel().increment("folder_form_bulk_send");
        }

        if (!isBulkSendEnabled) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("upgradeModalType", "BULK_SEND");
          return;
        }
        e.stopPropagation();
        updateDropdownState("showBulkUploadModal", {
          ...dropdownState.showBulkUploadModal,
          state: !dropdownState.showBulkUploadModal.state,
          templateId: form.formId,
        });
      },
      disabled: false,
      icon: <RiSendPlaneLine className="text-xl" />,
      label: "Bulk Send",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: (e: any) => {
        e.stopPropagation();
        if (type === "DEFAULT") {
          new MixPanel().track("Form", {
            form_action: "form_customize_pdf",
            paid: false,
          });
          new MixPanel().increment("form_customize_pdf");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_form_customize_pdf",
            paid: false,
          });
          new MixPanel().increment("folder_form_customize_pdf");
        }
        form.pdfTemplate
          ? navigate(`/create/pdf-mapping?id=${form.formId}`)
          : navigate(`/create/form/pdf?id=${form.formId}`);
      },
      disabled:
        !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
        dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly",
      icon: <PiFilePdfLight className="text-xl" />,
      label: form?.pdfTemplate ? "Edit Pdf" : "Customize Pdf",
      hiddenOnDesktop: false,
    },
    {
      show: form?.pdfTemplate,
      onClick: (e: any) => {
        e.stopPropagation();
        if (type === "DEFAULT") {
          new MixPanel().track("Form", {
            form_action: "form_replace_pdf",
            paid: false,
          });
          new MixPanel().increment("form_replace_pdf");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_form_replace_pdf",
            paid: false,
          });
          new MixPanel().increment("folder_form_replace_pdf");
        }
        navigate(`/replace/pdf?formId=${form.formId}&type=FORM`);
      },
      disabled:
        !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
        dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly",
      icon: <MdOutlinePictureAsPdf className="text-xl" />,
      label: "Replace Pdf",
      hiddenOnDesktop: false,
    },
    {
      show: form?.pdfTemplate,
      onClick: async (e: any) => {
        e.stopPropagation();
        if (type === "DEFAULT") {
          new MixPanel().track("Form", {
            form_action: "form_remove_custom_pdf",
            paid: false,
          });
          new MixPanel().increment("form_remove_custom_pdf");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_form_remove_custom_pdf",
            paid: false,
          });
          new MixPanel().increment("folder_form_remove_custom_pdf");
        }

        await removeCustomPdf(form?.formId);
      },
      disabled:
        !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
        dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly",
      icon: <HiOutlineDocumentRemove className="text-xl" />,
      label: "Remove Custom PDF",
      hiddenOnDesktop: false,
    },
    {
      show: type === "DEFAULT",
      onClick: (e: any) => {
        e.stopPropagation();
        new MixPanel().track("Form", {
          form_action: "form_add_to_folder",
          paid: false,
        });
        new MixPanel().increment("form_add_to_folder");
        updateDropdownState("folderOptions", {
          ...dropdownState.folderOptions,
          move: {
            item: { id: form?._id, type: "FORM", value: form },
            show: true,
            fromFolder: form?.folder ?? null,
          },
          isAdd: !form?.folder?.name,
        });
      },
      disabled:
        !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
        dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly",
      icon: form?.folder?.name ? (
        <MdOutlineDriveFileMoveRtl className="text-xl" />
      ) : (
        <LuFolderPlus className="text-xl" />
      ),
      label: form?.folder?.name ? "Move to Folder" : "Add to Folder",
      hiddenOnDesktop: false,
    },
    {
      show: dropdownState.workSpaceDetails?.currentWorkSpaceRole !== "readonly",
      onClick: async (e: any) => {
        e.stopPropagation();
        updateDropdownState("activeForm", form);
        updateDropdownState("showShare", true);
        if (type === "FOLDER") {
          updateDropdownState("showShareDocType", "forms");
        }
      },
      disabled: false,
      icon: <FiShare2 className="text-xl" />,
      label: "Share",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async (e: any) => {
        e.stopPropagation();
        if (type === "DEFAULT") {
          new MixPanel().track("Form", {
            form_action: "form_delete",
            paid: false,
          });
          new MixPanel().increment("form_delete");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_form_delete",
            paid: false,
          });
          new MixPanel().increment("folder_form_delete");
        }
        updateDropdownState("deletingForm", form);
      },
      disabled:
        !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
        (dropdownState.workSpaceDetails?.currentWorkSpaceRole === "readonly" ||
          dropdownState.workSpaceDetails?.currentWorkSpaceRole === "editor"),
      icon: <MdOutlineDeleteOutline className="text-xl" />,
      label: "Delete Form",
      hiddenOnDesktop: false,
      dangerHover: true,
    },
  ];
  return (
    <>
      {actions.map((action, index) => {
        if (!action.show) return null;
        return (
          <DropdownMenuItem
            key={index}
            onClick={action.onClick}
            disabled={action.disabled}
            className={`flex flex-row items-center text-center text-xs capitalize text-gray-700 px-3 py-2 gap-2 w-full  ${
              action?.dangerHover
                ? "hover:!bg-red-100 text-red-400 hover:!text-red-400"
                : ""
            } ${action?.hiddenOnDesktop ? "flex md:hidden" : ""}`}
          >
            {action.icon}
            {action.label}
          </DropdownMenuItem>
        );
      })}
    </>
  );
};

export default DropdownActionsForms;
