import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store";

export const getOwnerPlanDetailsHookHelper = (user: any) => {
  let currentPlan = user?.planhistory?.[user?.planhistory?.length - 1];
  let workSpacePlan = user?.paymentStatus?.workSpacePlanHistory;
  // we also consider anyone having from appsumo to have a lifetime plan
  let isLifeTime =
    currentPlan?.tier?.includes("BOLOFORMS_LIFETIME") ||
    user?.from == "APPSUMO";
  let userType: "DEFAULT" | "APPSUMO" | "BOLO_LTD" = "DEFAULT";
  if (user?.from == "APPSUMO") {
    userType = "APPSUMO";
  }
  if (currentPlan?.tier?.includes("BOLOFORMS_LIFETIME")) {
    userType = "BOLO_LTD";
  }
  return {
    isAppSumo: user?.from == "APPSUMO",
    userType,
    tier: currentPlan?.tier,
    currentPlan,
    workSpacePlan,
    isLifeTime,
  };
};

const useOwnerPlanDetails = () => {
  const user = useAppSelector((state) => state.root.ownerSlice.data.owner);
  const [data, setData] = useState(getOwnerPlanDetailsHookHelper(user));

  useEffect(() => {
    setData(getOwnerPlanDetailsHookHelper(user));
  }, [user]);
  return data;
};

export default useOwnerPlanDetails;
