import { BoloButton } from "../Common/Button/BoloButton";

export type Props = {
  setShow: (value: boolean) => void;
  inNewTab?: boolean;
};
const PlanExaustedBannerEditor = ({ setShow, inNewTab = false }: Props) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center md:p-0 p-4"
    >
      <div className="w-[500px] max-w-[100%] bg-white rounded-xl md:p-10 p-6">
        <h1 className="font-medium text-xl text-center">
          You have exhausted your allowed quota
        </h1>
        <img
          src="https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/approval-images/tired-employee-exhausted-with-work_74855-4820.avif"
          alt="logo"
          loading="lazy"
          className="w-[80%] mx-auto"
        />
        <p className="text-xs md:text-sm text-gray-600 md:leading-5 leading-4">
          Your current plan has reached its limit. Please upgrade to continue
          accessing our services effectively. Thank you for your understanding
          and cooperation in this matter.
        </p>
        <div className="flex justify-between items-center mt-5">
          <BoloButton
            variant={"secondary"}
            onClick={() => setShow(false)}
            className="text-xs md:text-base"
          >
            Cancel
          </BoloButton>
          <BoloButton
            onClick={() => {
              const origin = window.location.origin;
              window.open(`${origin}/billings`, inNewTab ? "_blank" : "_self");
            }}
            className="text-xs md:text-base"
          >
            Upgrade Plan
          </BoloButton>
        </div>
      </div>
    </div>
  );
};

export default PlanExaustedBannerEditor;
