import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignatureTemplateResponseMapper from "../../components/SignatureTemplateResponseMapper";
import axios from "../../utils/axios";
import {
  getDocument,
  GlobalWorkerOptions,
} from "pdfjs-dist/legacy/build/pdf.js";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import SendETemplateToRoles from "../../components/Ui/SendETemplateToRoles";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js`;

const ETemplateSetup: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [eTemplateId, setETemplateId] = useState(searchParams.get("id") ?? "");

  const [loading, setLoading] = useState(false);

  const [documentDetails, setDocumentDetails] = useState<any>();
  const [accountInfo, setAccountInfo] = useState<any>();

  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [documentAuthor, setDocumentAuthor] = useState("");
  const [signerDetails, setSignerDetails] = useState<any>({});
  const [isStartSigningPage, setIsStartSigningPage] = useState(true);
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [signerName, setSignerName] = useState("");
  const [shownModal, setShownModal] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [refetch, setRefetchData] = useState(false);
  const [fetchedData, setFetchedData] = useState<{
    accountInfo: any;
    document: any;
    // !NOTE: this is not supported in old docmentId signerId system
    // ! and will only be supported in getDocumentByKey
    paymentStatus: {
      isPayment: boolean;
      message: string;
      isPaid?: boolean;
    } | null;
    pdfTemplateRespondent: any;
  }>({
    accountInfo: null,
    document: null,
    paymentStatus: null,
    pdfTemplateRespondent: null,
  });

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  useEffect(() => {
    let getDocumentDetails = async () => {
      console.log("!!eTemplateId", !!eTemplateId);
      if (!eTemplateId) {
        toast.error("No document found");
        return;
      }
      try {
        // setLoading(true);
        const { data } = await axios.get(
          `/public/getOneDocument?eTemplateId=${eTemplateId}`
        );
        if (data.error) {
          toast.error(data.error);
          // setLoading(false);
          return;
        }
        if (!data.error) {
          // toast.success(data.message);
        }

        const ipResponse = getIpAddressLocal();
        const ipData = await ipResponse;

        let pdfUrl =
          data.document.documentUrl || data.document.pdfTemplate?.basePdf;
        setPdfUrl(pdfUrl);
        setDocumentDetails(data.document);
        setFetchedData((prev) => ({
          ...prev,
          document: data?.document,
          paymentStatus: data?.paymentStatus,
          pdfTemplateRespondent: data?.pdfTemplateRespondent,
        }));
        setAccountInfo(data.accountInfo);

        console.log("pdfUrl ==>", pdfUrl);

        onClickStart(pdfUrl);
      } catch (errror) {
        console.log("Error in fetching old", errror);
        setLoading(false);
        toast.error("Error fetching form");
      }
    };
    getDocumentDetails();
  }, [eTemplateId, refetch]);

  useEffect(() => {
    if (pdfFile) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [pdfFile]);

  console.log("the loading is 💤 ", loading);

  let onClickStart = async (pdfUrl: string) => {
    console.log("onClickStart ==>", pdfUrl);

    const responseFile = await fetch(pdfUrl);
    const pdfBuffer = await responseFile.arrayBuffer();

    // Load PDF document
    const pdfDoc = await getDocument(pdfUrl).promise;

    // Get number of pages
    const numPages = pdfDoc.numPages;

    setNumberOfPages(numPages);

    // Convert PDF buffer to a base64 string

    let pdfBase64 = arrayBufferToBase64(pdfBuffer);
    pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
    setPdfFile(pdfBase64);
  };

  if (loading) {
    return (
      <div className="h-screen -mt-[70px] flex justify-center items-center">
        <BoloLoader />
      </div>
    );
  }

  return (
    // removed negative margins
    <div className={`w-full`}>
      {shownModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.6)", // Dark overlay color
            zIndex: 999, // Place the overlay above other content
          }}
        ></div>
      )}
      {pdfFile && (
        <div
          className={`w-full h-full overflow-y-hidden absolute blur-sm pointer-events-none`}
        >
          <SignatureTemplateResponseMapper
            file={pdfFile}
            numberOfPages={1}
            signerDetails={{}}
            schemas={[]}
            loading={loading}
            setShownModal={setShownModal}
            isSigning={false}
            documentIdProp={""}
            signerIdProp={""}
            fetchedData={fetchedData}
            setRefetchData={setRefetchData}
          />
        </div>
      )}

      {documentDetails && (
        <SendETemplateToRoles
          loading={loading}
          accountInfo={accountInfo}
          documentDetails={documentDetails}
          roles={documentDetails.roles}
        />
      )}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default ETemplateSetup;
