import { GrClose } from "react-icons/gr";
import AddFieldForm from "./CustomFields/AddFieldForm";
import CustomFieldsList from "./CustomFields/CustomFieldsList";
import axios from "../../utils/axios";
import { useState } from "react";
import { ALL_FEATURES } from "../../types/tiers";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
interface CustomFieldsModalProps {
  setModalType: (type: string) => void;
}
const CustomFieldsModal = ({ setModalType }: CustomFieldsModalProps) => {
  const [fieldsList, setFieldsList] = useState([]);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  const fetchFields = async () => {
    try {
      // Get the list of all custom fields in the workspace
      const response = await axios.get("/workspace/getCustomFields");
      const data = response?.data;
      setFieldsList(data?.fields?.customFields || []);
    } catch (error) {
      console.log("Error fetching custom contact fields", error);
    }
  };
  return (
    <div className="flex fixed top-0 left-0 z-[51] w-full h-full p-2 md:p-6 bg-[#0000004c] justify-center items-start overflow-y-auto">
      <div
        className=" bg-white  md:rounded-xl rounded-md max-w-2xl w-full min-h-[464px] max-h-full overflow-y-auto relative"
        style={{ scrollbarWidth: "thin" }}
      >
        <div className="flex justify-between items-center  md:py-4 py-4 md:px-8 px-6 bg-gray-50 rounded-t-md md:rounded-t-xl border-b sticky top-0 z-20">
          <h2 className="text-[18px] md:text-xl font-medium ">Custom Fields</h2>
          <GrClose
            className="text-xl hover:cursor-pointer"
            onClick={() => {
              setModalType("");
            }}
          />
        </div>
        <div className=" py-2 md:py-4 md:px-8 px-6  h-full flex justify-center items-center flex-col flex-1 w-full">
          <AddFieldForm
            fetchFields={fetchFields}
            setShowUpgradeModal={setShowUpgradeModal}
            setUpgradeModalType={setUpgradeModalType}
          />
          <CustomFieldsList fetchFields={fetchFields} fieldsList={fieldsList} />
        </div>
      </div>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
    </div>
  );
};

export default CustomFieldsModal;
