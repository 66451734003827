import React, { Ref, forwardRef, useContext, useEffect, useState } from "react";
import { SchemaUIProps } from "./SchemaUI";
import {
  DEFAULT_ALIGNMENT,
  DEFAULT_CHARACTER_SPACING,
  DEFAULT_FONT_COLOR,
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  TextSchema,
  calculateDynamicFontSize,
} from "../../../../common/src";
import { FontContext } from "../../contexts";
import { ZOOM } from "../../constants";
import { DropdownOptionSchema } from "../../../../common/src/type";
import Select, { components } from "react-select";
import { getWhiteLabelInfoLocal } from "../../../../../utils";
import { getCSSFont } from "../../../../common/src/constants";
import { COLORS } from "../../../../../constants/common";
import { isEditorSchema } from "../../designerHelper";

type Props = SchemaUIProps & { schema: TextSchema };

const DropdownSchema = (
  {
    schema,
    editable,
    placeholder,
    tabIndex,
    onChange,
    isActive,
    setActiveSchema,
    setShownModal,
    outlineStyle,
    isPreview,
    isSigning,
    previewStyle,
    previewState,
    isApprover,
    designerState,
  }: Props,
  ref: Ref<HTMLTextAreaElement> | Ref<HTMLInputElement>
) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const primaryColor =
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary;

  const font = useContext(FontContext);

  const [dynamicFontSize, setDynamicFontSize] = useState<number | undefined>(
    undefined
  );
  const isEditorARecipient = isEditorSchema({
    schema,
    receiversList: designerState?.receiversList || [],
    instanceFrom: designerState?.instanceFrom || "PDF-TEMPLATE",
  });

  const newEditable = editable || isEditorARecipient;
  useEffect(() => {
    if (schema.dynamicFontSize && schema.data) {
      calculateDynamicFontSize({
        textSchema: schema,
        font,
        input: schema.data,
      }).then(setDynamicFontSize);
    } else {
      setDynamicFontSize(undefined);
    }
  }, [
    schema.data,
    schema.width,
    schema.fontName,
    schema.fontSize,
    schema.dynamicFontSize,
    schema.dynamicFontSize?.max,
    schema.dynamicFontSize?.min,
    schema.characterSpacing,
    font,
  ]);

  const handleTypeSelect = (e: any) => {
    onChange(e.value);
  };

  const getBackground = () => {
    if (isPreview && previewStyle !== "DEFAULT") return "transparent";

    if (schema?.isQuestionDeleted) {
      return "rgb(255 0 0 / 0.30)";
    }
    // When custom background color is given by user
    if (schema.backgroundColor) {
      return schema.backgroundColor;
    }

    if (isPreview || isSigning) {
      if (previewState?.fetchedData?.accountInfo?.theme) {
        if (isActive && newEditable) {
          return previewState?.fetchedData?.accountInfo?.theme + "70";
        } else {
          return previewState?.fetchedData?.accountInfo?.theme + "20";
        }
      } else {
        if (isActive && newEditable) {
          // return primaryColor + "70";
          return `hsl(var(--primary) / 0.55)`;
        } else {
          // return primaryColor + "20";
          return `hsl(var(--primary) / 0.2)`;
        }
      }
    }

    if (isActive && newEditable) {
      if (schema?.signatureColour) return schema?.signatureColour + 70;

      if (schema?.role?.colour) return schema?.role?.colour + "70";
    } else {
      if (schema?.signatureColour) return schema?.signatureColour + 20;

      if (schema?.role?.colour) return schema?.role?.colour + "20";
    }
  };
  function getTitle(): string {
    if (schema?.isQuestionDeleted) {
      return "This Question is Deleted";
    } else {
      if (isApprover) {
        if (!schema?.isStatic && schema?.role) {
          let signer = previewState?.signersData?.[schema?.role?.title];
          return signer?.name || signer?.email || schema?.showKey;
        }
      }
      return schema?.showKey;
    }
  }

  // Custom Input component
  const CustomInput = (props: any) => (
    <components.Input
      {...props}
      innerRef={(input) => {
        if (input) {
          input.setAttribute("data-schema-sub-type", schema.subType || "");
          input.setAttribute("data-schema-id", schema.id || "");
          if (isActive) {
            // input.focus();
          }
        }
      }}
    />
  );

  return (
    <div
      title={getTitle()}
      onClick={() => {
        setActiveSchema && setActiveSchema(schema);
      }}
    >
      {/* <select
        name=""
        id=""
        style={style}
        onChange={(e) => onChange(e.target.value)}
      >
        <option
          selected
          disabled
          style={{ ...optionStyle, background: "lightgrey" }}
        >
          Select Option
        </option>
        {schema?.dropdownOptions?.map((opt: DropdownOptionSchema) => {
          return (
            <option
              value={opt.value}
              style={{ ...optionStyle, background: "white" }}
            >
              {opt.option}
            </option>
          );
        })}
      </select> */}
      <Select
        components={{ Input: CustomInput }}
        tabIndex={tabIndex}
        isRtl={schema.alignment === "right"}
        styles={{
          singleValue: (base) => ({
            ...base,
            color: schema.fontColor ? schema.fontColor : DEFAULT_FONT_COLOR,
          }),
          // When user select an option from the list
          input: (base) => ({
            ...base,
            color: schema.fontColor ? schema.fontColor : DEFAULT_FONT_COLOR,
          }),
          // Placeholder color used in editor view
          placeholder: (base) => ({
            ...base,
            color: schema.fontColor ? schema.fontColor : DEFAULT_FONT_COLOR,
          }),
          control: (styles) => ({
            ...styles,
            borderRadius: "none",
            boxShadow: "none",
            "&:hover": {
              border: `1px solid ${
                previewState?.fetchedData?.accountInfo?.theme ||
                "hsl(var(--primary))"
              }`,
            },
            fontFamily:
              getCSSFont({
                currFont: schema?.fontName,
              }) || "Poppins",
            // border: "none",
            fontSize: `${
              dynamicFontSize ?? schema.fontSize ?? DEFAULT_FONT_SIZE
            }pt`,
            letterSpacing: `${
              schema.characterSpacing ?? DEFAULT_CHARACTER_SPACING
            }pt`,
            lineHeight: `${schema.lineHeight ?? DEFAULT_LINE_HEIGHT}em`,
            background: getBackground(),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            height: `${schema.height * ZOOM}px`,
            fontWeight: schema.bold ? 600 : 400,
            fontStyle: schema.italic ? "italic" : "normal",
            color: schema.fontColor ? schema.fontColor : DEFAULT_FONT_COLOR,
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected
                ? previewState?.fetchedData?.accountInfo?.theme ||
                  `hsl(var(--primary) / 0.65)`
                : "white",
              "&:hover": {
                color:
                  previewState?.fetchedData?.accountInfo?.themeText || "black",
                backgroundColor:
                  previewState?.fetchedData?.accountInfo?.themeLight ||
                  `hsl(var(--primary) / 0.2)`,
              },
            };
          },
        }}
        options={schema?.dropdownOptions?.map((opt: DropdownOptionSchema) => ({
          value: opt.value,
          label: opt.option,
        }))}
        onChange={handleTypeSelect}
        value={schema?.dropdownOptions
          ?.map((opt: DropdownOptionSchema) => ({
            value: opt.value,
            label: opt.option,
          }))
          .filter((option) => {
            return option.value === schema.data;
          })}
        isDisabled={!newEditable || isApprover}
        // className="z-30"
        placeholder={placeholder || schema?.placeholder || schema.showKey}
      />
    </div>
  );
};

export default DropdownSchema;
