import React, { useEffect, useState } from "react";
import { PiCaretDownBold } from "react-icons/pi";
import useIsFeatureEnabled from "../../../hooks/reduxHooks/useIsFeatureEnabled";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { ALL_FEATURES } from "../../../types/tiers";
import { BoloButton } from "../../Common/Button/BoloButton";
import PleaseUpgradeModal from "../../PleaseUpgradeModal";
import { LuCopy, LuCopyCheck } from "react-icons/lu";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import CustomTooltip from "../../EditForm/CustomTooltip";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import { getOwnerThunk } from "../../../redux/slices/ownerSlice";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { getCalApi } from "@calcom/embed-react";
import BoloFormsEmbedWrapper from "../../Common/BoloFormsEmbed/BoloFormsEmbed";
import { BsInfoCircle } from "react-icons/bs";

const Embed = () => {
  const featuresEnabled = useIsFeatureEnabled();
  const dispatch = useAppDispatch();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEg, setShowEg] = useState(false);
  const [showLiveEmbed, setShowLiveEmbed] = useState(false);
  const [copy, setCopy] = useState({ key: false, code: false });
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );

  const codeString = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>BoloForms Embed Example</title>
    <style>
      #embed-container {
        position: relative;
        height: 600px;
        border: 1px solid #ccc;
        margin-bottom: 20px;
      }
    </style>
  </head>
  <body style="padding: 10px">
    <h1>BoloForms Embed Example</h1>
    <div id="embed-container"></div>

    <button onclick="reInit()">Initalize / Re-initialize</button>
    <button onclick="destroyInstance()">Destroy Instance</button>
    <button onclick="showBoloContainer()">Show Container</button>
    <button onclick="hideBoloContainer()">Hide Container</button>
    <button onclick="reloadIframe()">Reload Iframe</button>
    <button onclick="createPDF()">Create PDF</button>
    <button onclick="resetSessionInfo()">Reset Session Info</button>
    <input
      type="text"
      id="sessionInfo"
      placeholder="Session Info JSON object"
    />
    <button onclick="setSessionInfo()">Set Session Info</button>
    <input type="text" id="documentIdInput" placeholder="Enter Document ID" />
    <button onclick="openDocument()">Open Document</button>

    <script src="https://appscript-scripts.s3.ap-south-1.amazonaws.com/boloforms-embed-index.min.js"></script>
    <script>
      // Create an instance of BoloFormsEmbed
      let config = {
        containerId: "embed-container",
        token: "your-embed-key",
        iframeOpts: { width: "100%", height: "600px" },
        sessionInfo: "jhon-doe-123",
        contacts: {
          data: [
            {
              email: "jhon@gmail.com",
              company: "Jhon Inc",
              customFields: { custom1: 1, custom2: "2" },
            },
          ],
        },
      };
      let embed = new BoloFormsEmbed(config);

      function reInit() {
        if (embed) {
          embed.destroy();
        }
        embed = new BoloFormsEmbed(config);
      }

      function destroyInstance() {
        embed.destroy();
        embed = null;
      }

      // Functions to interact with the BoloFormsEmbed instance
      function reloadIframe() {
        embed.reloadIframe();
      }

      function openDocument() {
        const documentId = document.getElementById("documentIdInput").value;
        if (documentId) {
          embed.openDocument(documentId);
        } else {
          alert("Please enter a document ID");
        }
      }

      function setSessionInfo() {
        const sessionInfo = document.getElementById("sessionInfo").value;
        let sessionInfoJSON = JSON.parse(sessionInfo);
        embed.setSessionInfo(sessionInfoJSON);
      }

      function resetSessionInfo() {
        embed.resetSessionInfo();
      }

      function createPDF() {
        embed.createPDF();
      }

      function showBoloContainer() {
        embed.showBoloContainer();
      }

      function hideBoloContainer() {
        embed.hideBoloContainer();
      }
    </script>
  </body>
</html>
`;

  async function handleGenerateEmbedKey() {
    if (!featuresEnabled.EMBEDDING) {
      setShowUpgradeModal(true);
      setUpgradeModalType("EMBEDDING");
      return;
    }
    setLoading(true);
    try {
      const { data } = await axios.post("/embed/regenerate-key", {
        email: owner?.email,
      });
      dispatch(getOwnerThunk());
      toast.success("Key generated successfully`");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
    setLoading(false);
  }

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);

  return (
    <div className="w-full">
      <div className="max-w-[600px] w-[100%] md:w-[80%]">
        {showUpgradeModal && (
          <PleaseUpgradeModal
            show={showUpgradeModal}
            setShow={setShowUpgradeModal}
            blockingType={upgradeModalType}
            data={{}}
          />
        )}
        <div className="flex flex-col md:flex-row items-center gap-x-4 gap-y-2 lg:flex-row mb-6">
          <p className="text-xs md:text-sm font-semibold text-gray-400">
            Learn How to Use Embeds: Click Here for a Comprehensive Guide
          </p>
          <button
            onClick={() =>
              window.open(
                "https://help.boloforms.com/en/articles/9560874-how-to-use-boloforms-embed",
                "_blank"
              )
            }
            className={`text-xs md:text-sm flex gap-x-1 flex-row justify-center items-center text-center hover:opacity-90 text-[13px] rounded-md capitalize border px-3 py-2 bg-white text-primary font-medium border-primary/50 hover:border-primary`}
          >
            <BsInfoCircle className="text-primary text-[15px]" />
            Learn More
          </button>
        </div>
        <h3 className="text-sm font-semibold mb-1">Embed BoloForms</h3>
        <p className="text-xs text-gray-500 mb-4">
          Now edit documents without leaving your website. Integrate/embed
          BoloForms in your website.
        </p>
        <div>
          <CustomTooltip
            helpers={{
              id: "show-hide-embed-key",
              content: showKey ? "Hide Key" : "Show Key",
              place: "top",
            }}
          />
          <CustomTooltip
            helpers={{
              id: "copy-embed-key",
              content: "Copy Key",
              place: "top",
            }}
          />
          <CustomTooltip
            helpers={{
              id: "copy-embed-code",
              content: "Copy Sample code",
              place: "top",
            }}
          />
          {owner?.embed?.key && (
            <div className="flex gap-2 mb-4">
              <div className="rounded border p-1 px-3 text-xs w-[500px] text-gray-500">
                {showKey ? owner?.embed?.key : "XXXX-XXXX-XXXX-XXXX"}
              </div>
              <BoloButton
                data-tooltip-id="show-hide-embed-key"
                variant={"gray-shadow"}
                size="icon-sm"
                className="h-[30px] w-[30px]"
                onClick={() => setShowKey(!showKey)}
              >
                {showKey ? <FaRegEyeSlash size={16} /> : <FaRegEye size={16} />}
              </BoloButton>
              <BoloButton
                data-tooltip-id="copy-embed-key"
                variant={"gray-shadow"}
                size="icon-sm"
                className="h-[30px] w-[30px]"
                onClick={() => {
                  navigator.clipboard.writeText(owner?.embed?.key);
                  setCopy((prev) => ({ ...prev, key: true }));
                  toast.success("Key Copied Successfully");
                }}
              >
                {copy.key ? <LuCopyCheck /> : <LuCopy />}
              </BoloButton>
            </div>
          )}
          <BoloButton
            size={"sm"}
            onClick={handleGenerateEmbedKey}
            disabled={loading}
          >
            {owner?.embed?.key
              ? "Re-generate Embeding Key"
              : "Generate Embeding Key"}
          </BoloButton>
        </div>
      </div>

      <div className="flex flex-col mt-4 text-sm border rounded p-2">
        <div
          className="font-semibold cursor-pointer flex justify-between items-center"
          onClick={() => setShowEg(!showEg)}
        >
          Show Code example
          <div className="flex items-center gap-3">
            <div
              data-tooltip-id="copy-embed-code"
              // variant={"gray-shadow"}  /
              // size="icon-sm"
              // className="h-[30px] w-[30px]"
              onClick={(e) => {
                navigator.clipboard.writeText(codeString);
                setCopy((prev) => ({ ...prev, code: true }));
                toast.success("Code Copied Successfully");
                e.stopPropagation();
              }}
            >
              {copy.code ? <LuCopyCheck /> : <LuCopy />}
            </div>
            <PiCaretDownBold
              className={`transition-all duration-300 ${
                showEg ? "rotate-180" : "rotate-0"
              }`}
            />
          </div>
        </div>
        <div
          className={`overflow-hidden transition-all duration-300 text-xs ${
            showEg ? "max-h-[300vh]" : "max-h-[0]"
          }`}
        >
          <SyntaxHighlighter language={"html"} wrapLongLines>
            {codeString}
          </SyntaxHighlighter>
        </div>
      </div>

      {/* <div className="flex flex-col mt-4 text-sm border rounded p-2">
        <div
          className="font-semibold cursor-pointer flex justify-between items-center"
          onClick={() => setShowLiveEmbed(!showLiveEmbed)}
        >
          Show Live Example
          <div className="flex items-center gap-3">
            <PiCaretDownBold
              className={`transition-all duration-300 ${
                showLiveEmbed ? "rotate-180" : "rotate-0"
              }`}
            />
          </div>
        </div>
        <div
          className={`overflow-hidden transition-all duration-300 text-xs ${
            showLiveEmbed ? "max-h-[300vh] mt-1" : "max-h-[0]"
          }`}
        >
          <BoloFormsEmbedWrapper />
        </div>
      </div> */}
    </div>
  );
};

export default Embed;
