// @ts-nocheck

import axios_ from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import {
  MdClose,
  MdKeyboardArrowDown,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdRemoveRedEye,
  MdOutlineDelete,
  MdOutlineVisibility,
  MdOutlineDownload,
} from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import { TbTableExport } from "react-icons/tb";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { v4 } from "uuid";
import ColumnsShowHide from "../../components/ColumnsShowHide2";
import { getBulkApproveRejectLambdaUrl } from "../../constants/constants";
import axios from "../../utils/axios";
import "./index.css";
// import ApprovalFlowTimeline from "../../components/ApprovalFlowTimeline";
import ColumnOptions from "../../components/ColumnOptions";
import DateRangeFilter from "../../components/DateRangePicker/DateRangeFilter";
import Modal from "../../components/Modal";
// import BulkSubmitModal from "../../components/BulkSubmitModal";
import { InfinitySpin } from "react-loader-spinner";
import { isImageUrlInResponseValue } from "../../utils/isImageUrlInResponseValue";
// import { connect } from "react-redux";
import { Slide, toast, ToastContainer } from "react-toastify";
import PlanExhaustedNavBanner from "../../components/PlanExhaustedNavBanner";
// import PlanExaustedBanner from "../../components/PlanExaustedBanner";
import download from "downloadjs";
import { saveAs } from "file-saver";
import { fileToBase64 } from "../../utils/FileDataToBase64";
import JSZip from "jszip";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import { isFeatureEnabled } from "../../utils/paidFeat";
import { PiCertificateDuotone } from "react-icons/pi";
import { Tooltip } from "react-tooltip";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import { set } from "lodash";
import { IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import CustomTooltip from "../../components/EditForm/CustomTooltip";

import { useAppDispatch } from "../../redux/store";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import getS3PreSignedUrl from "../../utils/getS3PreSignedUrl";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import BoloCheckboxMui from "../../components/Common/Checkbox";

const Respondents = () => {
  // Redux code ----------------------------------------------------------------------------------
  const dispatch = useAppDispatch();
  // const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  // const workSpaceDetails = useWorkSpaceDetails();
  // const ownerPlanDetails = useOwnerPlanDetails();
  const featuresEnabled = useIsFeatureEnabled();

  useEffect(() => {
    dispatch(getOwnerThunk());
    // dispatch(getOwnerPaymentStatusThunk());
  }, []);

  // Redux code end -------------------------------------------------------------------------------

  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const columnHideRef = useRef();
  const approvalTimelineRef = useRef();
  const searchSuggestRef = useRef();
  const dateFilterRef = useRef();
  const moreOptionsRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  // is searchParams.get("page") is null then set it to 1
  let [respondentPageNo, setRespondentPageNo] = useState(
    Number(searchParams.get("page")) || 1
  );

  let currentUserEmail = localStorage.getItem("currentUserEmail");
  // @ts-ignore
  const PAGE_LIMIT = localStorage.getItem("pageLimit")
    ? // @ts-ignore
      parseInt(localStorage.getItem("pageLimit"))
    : 10;
  // let respondentFilter = localStorage.getItem("respondentFilter") ?? "all";
  const params = useParams();
  let formId = params?.id ?? searchParams.get("id");
  let whichAdmin = params?.whichAdmin ?? searchParams.get("whichAdmin");
  // let navigate = useNavigate();
  const [collaborator, setCollaborator] = useState({});
  const [roleInCollaboration, setRoleInCollaboration] = useState("");
  const [allRespondents, setRespondents] = useState([]);
  const [requestStatusCounts, setRequestStatusCounts] = useState({
    all: 0,
    approved: 0,
    rejected: 0,
    inProgress: 0,
  });
  const [rowData, setRowData] = useState([]);
  const [formDetails, setFormDetails] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState([]);
  const [searchResults, setSearchResults] = useState({});
  const [filter, setFilter] = useState("all");
  const [tableData, setTableData] = useState({
    columns: [],
    respondent: [],
    page: 1,
    perPage: PAGE_LIMIT,
  });
  const [show, setShow] = useState({
    columns: false,
    activeCell: null,
    columnOption: {
      name: null,
      show: false,
    },
    dateFilter: false,
    searchSuggest: false,
    moreOptions: false,
    multiApproveNReject: false,
    bulkApproveRejectModal: false,
    bulkDeleteResponseModal: false,
    deleteOneResponseModal: false,
    bulkApproveRejectActiveModalButton: null,
    bulkProcessing: false,
    oneDeleting: false,
    deleteResponse: false,
    bulkSubmitModal: false,
  });
  const [owner, setOwner] = useState({});
  const [searchDateFilter, setSearchDateFilter] = useState({
    start: null,
    end: null,
  });

  const [dateRangeFilter, setDateRangeFilter] = useState([
    {
      startDate: formDetails?.createdAt,
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bulkDownloading, setBulkDownloading] = useState(false);
  const [exportingToCsv, setExportingToCsv] = useState(false);
  const [noPlanError, setNoPlanError] = useState(false);
  const [approvalFlowTimeline, setApprovalFlowTimeline] = useState({
    show: false,
    data: {},
  });

  const [bulkApproveRejectData, setBulkApproveRejectData] = useState({
    selectAll: false,
    respondents: [],
    comment: "",
  });
  const [bulkDeleteResponseData, setBulkDeleteResponseData] = useState({
    selectAll: false,
    respondents: [],
  });
  const [deletingResponse, setDeletingResponse] = useState(null);

  const [isDeleted, setIsDeleted] = useState("");

  const [upgradeModalType, setUpgradeModalType] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;
  const [showSelectedDownload, setShowSelectedDownload] = useState(false);
  const [selectedPdfs, setSelectedPdfs] = useState([]);

  async function getAllRespondents(page = 1, limit = tableData.perPage) {
    try {
      setLoading(true);
      const pageNo = Number(searchParams.get("page") || "1");
      let { data } = await axios.get(
        `/respondents?formId=${formId}&page=${pageNo}&limit=${limit}&filter=${filter}`
      );
      console.log(data);
      setLoading(false);
      setRespondents(data);
      setFormDetails(data.form);
      setOwner(data?.form?.ownerId);

      setBulkApproveRejectData({
        selectAll: false,
        respondents: [],
        comment: "",
      });

      setBulkDeleteResponseData({
        selectAll: false,
        respondents: [],
      });

      // console.log("respondents", data);
    } catch (error) {
      setLoading(false);
      console.log(`Error getting respondents: ${error}`);
    }
  }

  const handleOutsideClickDateFilter = (e) => {
    if (dateFilterRef?.current !== null) {
      if (dateFilterRef?.current?.contains(e.target)) return;
    }
    setShow((prev) => ({
      ...prev,
      dateFilter: false,
    }));
  };
  const handleOutsideClickSearchSuggest = (e) => {
    if (searchSuggestRef?.current !== null) {
      if (searchSuggestRef?.current?.contains(e.target)) return;
    }
    setShow((prev) => ({
      ...prev,
      searchSuggest: false,
    }));
  };
  const handleOutsideClickColumnHide = (e) => {
    if (columnHideRef?.current !== null) {
      if (columnHideRef?.current?.contains(e.target)) return;
    }
    setShow((prev) => ({
      ...prev,
      columns: false,
    }));
  };
  const handleOutsideClickMoreOptions = (e) => {
    if (moreOptionsRef?.current !== null) {
      if (moreOptionsRef?.current?.contains(e.target)) return;
    }
    setShow((prev) => ({
      ...prev,
      moreOptions: false,
    }));
  };

  const handleOutsideClickApprovalTimeline = (e) => {
    if (approvalTimelineRef?.current !== null) {
      if (approvalTimelineRef?.current?.contains(e.target)) return;
    }

    setApprovalFlowTimeline((prev) => ({
      ...prev,
      show: false,
    }));
  };
  const fetchRequestStatusCount = async () => {
    try {
      let { data } = await axios.get(
        `/forms/request-status-count?formId=${formId}`
      );
      setRequestStatusCounts(data.approvalStatusCount);
    } catch (error) {
      console.log("Error getting request status count", error);
    }
  };

  const handleSelectAllChange = (e) => {
    // console.log("selectAll", show.multiApproveNReject, show.deleteResponse);
    // if (show.multiApproveNReject) {
    setBulkApproveRejectData((prev) => {
      return {
        ...prev,
        selectAll: e.target.checked,
        respondents: prev?.respondents?.map((item) => {
          return {
            ...item,
            checked: e.target.checked,
          };
        }),
      };
    });
    // }
    // if (show.deleteResponse) {
    setBulkDeleteResponseData((prev) => {
      return {
        ...prev,
        selectAll: e.target.checked,
        respondents: prev?.respondents?.map((item) => {
          return {
            ...item,
            checked: e.target.checked,
          };
        }),
      };
    });
    // }
  };
  const handleBulkApproveRejectCheckboxChange = (checked, id) => {
    setBulkApproveRejectData((prev) => {
      return {
        ...prev,
        respondents: prev?.respondents?.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              checked,
            };
          }
          return item;
        }),
      };
    });
  };

  const handleBulkDeleteResponseCheckboxChange = (checked, id) => {
    setBulkDeleteResponseData((prev) => {
      return {
        ...prev,
        respondents: prev?.respondents?.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              checked,
            };
          }
          return item;
        }),
      };
    });
  };

  const handleBulkApproveReject = async (status, comment = status) => {
    console.log(bulkApproveRejectData.respondents);
    let messages = [];

    bulkApproveRejectData?.respondents?.map((item) => {
      if (item.checked && item.approvalItemId) {
        let message = {
          uniqueId: item.id,
          status,
          approvalItemId: item.approvalItemId,
          senderEmail: currentUserEmail,
          comment,
        };
        messages.push(message);
      }
    });
    console.log(messages);
    const LAMBDA_URL = getBulkApproveRejectLambdaUrl();
    try {
      axios_.post(LAMBDA_URL, { messages });
    } catch (error) {
      console.log(error);
    }
    setShow((prev) => {
      return {
        ...prev,
        multiApproveNReject: false,
        bulkProcessing: true,
      };
    });
    setTableData((prev) => {
      let cols = prev.columns.map((col) => {
        if (col.accessor === "hidden") {
          return {
            ...col,
            show: false,
          };
        }
        return col;
      });

      return {
        ...prev,
        columns: cols,
      };
    });
  };

  const handleBulkDeleteResponse = async () => {
    setShow((prev) => {
      return {
        ...prev,
        bulkProcessing: true,
      };
    });

    let ids = [];
    bulkDeleteResponseData?.respondents?.map((item) => {
      if (item.checked) {
        ids.push(item.id);
      }
    });
    // console.log(ids);

    try {
      let { data } = await axios.put(`/respondents/bulk-delete`, {
        ids,
      });
      // console.log(data);
      if (data?.success) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }

    setShow((prev) => {
      return {
        ...prev,
        bulkDeleteResponseModal: false,
        bulkProcessing: false,
      };
    });
  };
  const handleOneResponseDelete = async () => {
    setShow((prev) => {
      return {
        ...prev,
        oneDeleting: true,
      };
    });

    let id = deletingResponse._id;
    // console.log(id);

    try {
      let { data } = await axios.post(`/respondents/delete-one-response`, {
        id,
      });
      // console.log(data);
      if (data?.success) {
        setIsDeleted(id);
      }
    } catch (error) {
      console.log(error);
    }

    setShow((prev) => {
      return {
        ...prev,
        deleteOneResponseModal: false,
        oneDeleting: false,
      };
    });
  };

  const handleBulkDownload = async (downloadAll = false) => {
    try {
      let allRespondentsNew = allRespondents?.data;
      setLoading(true);
      // For selected pdf download feature
      if (!downloadAll) {
        allRespondentsNew = selectedPdfs;
      }
      console.log("downloads", allRespondentsNew);
      let visibleResponseDetails = allRespondentsNew?.map((item) => {
        return {
          finishedPdfUrl: item?.finishedPdfUrl,
          respondentEmail: item?.respondentEmail,
        };
      });
      console.log("visibleRespnseDetails", visibleResponseDetails);
      if (!visibleResponseDetails || visibleResponseDetails?.length === 0) {
        setLoading(false);
        toast.error("No forms found for bulk download");
        return;
      }

      let { data } = await axios.post(`/respondents/get-presigned-url`, {
        formId: formId,
        responseDetails: visibleResponseDetails,
      });

      const { presignedUrls } = data;

      // console.log("presignedUrls", presignedUrls);

      const zip = new JSZip();

      const pdfPromises = presignedUrls.map(async (url, index) => {
        if (url?.presignedUrl) {
          const pdfUrlResponse = await fetch(url?.presignedUrl);
          if (!pdfUrlResponse?.ok) {
            throw new Error("Failed to fetch PDF");
          }
          const pdfBlob = await pdfUrlResponse.blob();
          zip.file(
            `${url?.respondentEmail || "response"}_${index + 1}.pdf`,
            pdfBlob
          );
        }
      });

      await Promise.all(pdfPromises);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      download(
        zipBlob,
        `${formDetails?.formJson?.title ?? "Form Responses"}.zip`
      );
      setLoading(false);
      setShowSelectedDownload(false);
      setSelectedPdfs([]);
    } catch (error) {
      setLoading(false);
      setShowSelectedDownload(false);
      setSelectedPdfs([]);
      console.error("Error during bulk PDF generation and download:", error);
    }
  };

  useEffect(() => {
    if (searchQuery?.length > 0) {
      // getRespondentsBySearch(null, tableData.page);
    } else {
      setSearchResults({});
      getAllRespondents(tableData.page);
    }
  }, [
    tableData.page,
    filter,
    tableData.perPage,
    searchQuery,
    isDeleted,
    searchParams.get("page"),
  ]);

  useEffect(() => {
    if (searchText === "") {
      setSearchResults({});
      setTableData((prev) => ({ ...prev, page: 1 }));
      setFilter("all");
    }
  }, [searchText]);

  useEffect(() => {
    let startDate = new Date(formDetails?.createdAt);
    let endDate = new Date();

    if (
      startDate?.toString() !== dateRangeFilter?.[0]?.startDate?.toString() &&
      dateRangeFilter?.[0]?.endDate?.toString() !== endDate?.toString()
    ) {
      console.log("date range filter changed");
      // getRespondentsBySearch();
    }
  }, [dateRangeFilter]);

  // update the 'page' search parameter when the 'page' state changes
  // useEffect(() => {
  //   searchParams.set("page", respondentPageNo);
  //   setSearchParams(searchParams);
  // }, [respondentPageNo, setSearchParams, searchParams]);

  useEffect(() => {
    setTableData((prev) => ({ ...prev, page: 1 }));
  }, [tableData.perPage]);

  useEffect(() => {
    setRespondentPageNo(tableData.page);
  }, [tableData.page]);

  useEffect(() => {
    setDateRangeFilter([
      {
        startDate: new Date(formDetails?.createdAt),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    formDetails?.isApprovalWorkflowEnabled && fetchRequestStatusCount();
  }, [formDetails]);

  useEffect(() => {
    setTableData((prev) => {
      return {
        ...prev,
        page: respondentPageNo ?? 1,
        // perPage: PAGE_LIMIT
      };
    });
    setFilter("all");
    setShow((prev) => {
      return {
        ...prev,
        multiApproveNReject: false,
        deleteResponse: false,
      };
    });
    // fetchFormFilters();
    document.addEventListener("mousedown", handleOutsideClickColumnHide);
    document.addEventListener("mousedown", handleOutsideClickSearchSuggest);
    document.addEventListener("mousedown", handleOutsideClickDateFilter);
    document.addEventListener("mousedown", handleOutsideClickApprovalTimeline);
    document.addEventListener("mousedown", handleOutsideClickMoreOptions);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickColumnHide);
      document.removeEventListener("mousedown", handleOutsideClickDateFilter);
      document.removeEventListener(
        "mousedown",
        handleOutsideClickSearchSuggest
      );
      document.removeEventListener(
        "mousedown",
        handleOutsideClickApprovalTimeline
      );
      document.removeEventListener("mousedown", handleOutsideClickMoreOptions);
    };
  }, []);

  useEffect(() => {
    if (whichAdmin) {
      let actualOwner = JSON.parse(localStorage.getItem("owner"));
      setRoleInCollaboration(
        actualOwner?.addedBy.find((admin) => admin.ownerId.email === whichAdmin)
          .role
      );
    }
  }, [whichAdmin]);

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  const extractFirstElementFromArrayFields = async (responseData) => {
    if (!responseData || typeof responseData !== "object") {
      toast.error("Error fetching the response data");
      return null; // Not an object or null/undefined
    }

    for (const key in responseData) {
      if (Array.isArray(responseData[key]) && responseData[key].length > 0) {
        // Check if the first element in the array is a URL
        const firstElement = responseData[key][0];
        if (
          typeof firstElement === "string" &&
          firstElement.includes(
            "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature"
          )
        ) {
          try {
            // Fetch the image from the URL
            const response = await fetch(firstElement);

            const blob = await response.blob();

            // Convert the image to a base64 string
            const base64String = await fileToBase64(blob);
            // Update the responseData with the base64 string
            responseData[key] = base64String;
          } catch (error) {
            console.error("Error fetching or converting the image:", error);
          }
        } else {
          responseData[key] = responseData[key].join(",");
        }
      }
    }

    return responseData;
  };

  React.useMemo(() => {
    let respondents =
      searchResults?.respondents?.length > 0 ||
      searchResults?.message === "No result found for this search"
        ? searchResults?.respondents
        : allRespondents?.data;
    setRowData(respondents);

    let data = respondents?.map((respondent, i) => {
      let response = {};

      respondent?.response?.map((responseItem) => {
        let { question, answer, id } = responseItem;
        // object to check if it is a grid response
        // array to check if it is Signature
        if (typeof answer == "object" && !Array.isArray(answer)) {
          // To fix a weird ↵ enter sign in the object key
          // key = key.replace("\n", "");
          question = question
            ?.replace("\n", "")
            ?.replace(/(\r\n|\n|\r)/gm, "")
            ?.trim();

          let customFormat = "";
          Object.keys(answer)?.map((key) => {
            customFormat += key;
            if (Array.isArray(answer[key])) {
              // for checkbox
              customFormat += ": [" + answer[key].join(", ") + "], ";
            } else {
              // for radiogroup
              customFormat += ": " + answer[key] + ", ";
            }
          });
          let pTag = <p className="text-xs">{customFormat}</p>;

          response[id] = customFormat;
        } else {
          // check with chirag if any other response can send object or not
          answer = String(answer);
          if (answer.includes("http")) {
            answer = answer.split(",").map((url, i) => (
              <button
                onClick={async () => {
                  if (responseItem?.type === "FileUpload") {
                    let signedUrl = await getS3PreSignedUrl(url);
                    window.open(signedUrl, "_blank");
                  } else {
                    window.open(url, "_blank");
                  }
                }}
                // href={url}
                className="text-blue-600 underline mr-2 h-full"
                target="_blank"
                rel="noreferrer"
                key={i}
              >
                {isImageUrlInResponseValue(url) ? (
                  <object
                    data={url}
                    type="image/png"
                    className="h-[30px] object-contain inline-block cursor-pointer"
                  >
                    {/* <img
                      alt="Response"
                      src="https://cdn-icons-png.flaticon.com/128/16/16410.png"
                      className="h-[30px] object-contain inline-block"
                    />{" "} */}
                    <div className="text-blue-600 underline h-full flex items-center">
                      Link {i + 1}
                    </div>
                  </object>
                ) : (
                  `Link ${i + 1}`
                )}
              </button>
            ));
          } else if (typeof answer !== "string") {
            answer = JSON.stringify(answer);
          }
          // To fix a weird ↵ enter sign in the object key
          // key = key.replace("\n", "");
          question = question
            ?.replace("\n", "")
            ?.replace(/(\r\n|\n|\r)/gm, "")
            ?.trim();
          response[id] = answer;
        }
      });

      let disabled = show.multiApproveNReject
        ? currentStage?.closed ||
          approvalFlowItem?.permission === "signature" ||
          respondent?.stages?.length === 0
        : false;

      let checkboxValue = show.multiApproveNReject
        ? currentStage && !currentStage?.closed
          ? bulkApproveRejectData?.respondents?.[i]?.checked
          : false
        : bulkDeleteResponseData?.respondents?.[i]?.checked;

      const onChange = (e) => {
        if (show.multiApproveNReject) {
          handleBulkApproveRejectCheckboxChange(
            e.target.checked,
            bulkApproveRejectData?.respondents?.[i]?.id
          );
        } else {
          handleBulkDeleteResponseCheckboxChange(
            e.target.checked,
            bulkDeleteResponseData?.respondents?.[i]?.id
          );
        }
      };
      const checkSelected = (respondent) => {
        return selectedPdfs?.some((ele: any) => ele?._id === respondent?._id);
      };
      return {
        id: respondent._id,
        hidden: (
          <div className="flex justify-start">
            <span
              data-tip={
                show.multiApproveNReject &&
                (approvalFlowItem?.permission === "signature" ||
                  respondent?.stages?.length === 0)
                  ? `${
                      approvalFlowItem?.permission === "signature"
                        ? "Signature not allowed"
                        : "No allowed"
                    }`
                  : ""
              }
            >
              <input
                type="checkbox"
                className={`h-4 w-4 ${disabled && "cursor-not-allowed"}`}
                onChange={onChange}
                disabled={disabled}
                value={checkboxValue}
                checked={checkboxValue}
              />
            </span>
          </div>
        ),
        "#": (
          <div className="font-semibold text-gray-600 text-xs">
            {respondent?.requestNo
              ? respondent.requestNo
              : searchQuery?.length > 0
              ? i + 1
              : `#${
                  allRespondents.respondentCount -
                  i -
                  tableData.page * tableData.perPage +
                  +tableData.perPage //converting the per page to number with the + charecter
                }`}
          </div>
        ),
        Select: showSelectedDownload && (
          <BoloCheckboxMui
            onChange={(e) => {
              const isChecked = e.target.checked;
              setSelectedPdfs((prevSelected: any) => {
                let newSelected = [...prevSelected];
                if (
                  isChecked &&
                  !newSelected.some((item) => item?._id === respondent?._id)
                ) {
                  newSelected.push(respondent);
                } else if (!isChecked) {
                  newSelected = newSelected.filter(
                    (item: any) => item?._id !== respondent?._id
                  );
                }
                return newSelected;
              });
            }}
            disabled={loading}
            checked={checkSelected(respondent)}
          />
        ),
        "Response PDF": (
          <div className="flex">
            <BoloButton
              size={"sm"}
              // variant="gray-shadow"
              className="flex items-center gap-2 text-xs md:text-xs h-6 mr-2"
              disabled={loading}
              onClick={async (e) => {
                e.stopPropagation();
                if (loading) {
                  return;
                }

                if (!respondent?.finishedPdfUrl) {
                  try {
                    toast?.info(
                      "For this response PDF generation was disabled!"
                    );
                    return;
                  } catch (err) {
                    console.log(
                      "error from pdf generation when pdf generation was disabled: ",
                      err
                    );
                    toast.error("Error generating pdf from server");
                    return;
                  }
                }

                if (respondent?.finishedPdfUrl) {
                  // window.open(respondent?.finishedPdfUrl, "_blank");

                  let url = await getS3PreSignedUrl(respondent?.finishedPdfUrl);
                  window.open(url, "_blank");
                }
              }}
              // className={`flex-row justify-center items-center text-center hover:opacity-90 text-sm disabled:opacity-50 rounded-md capitalize shadow border px-2 py-1 bg-primary font-medium flex text-white`}
              // data-tip={"Settings"}
            >
              {/* <MdOutlineDownload className="hover:opacity-85 mr-2 text-sm" /> */}
              <span className="text-xs">Download</span>
            </BoloButton>
            <BoloButton
              size={"icon-sm"}
              variant="secondary"
              className="flex items-center gap-2 text-xs md:text-xs h-6 p-1 md:p-1"
              data-tooltip-id="certificate-download-tooltip"
              // className="ml-2 mr-6 bg-secondary p-1 rounded-md"
              onClick={async (e) => {
                e.stopPropagation();
                new MixPanel().track("Form", {
                  form_action: "form_response_download_certificate",
                  paid: false,
                });
                new MixPanel().increment("form_response_download_certificate");
                if (loading) {
                  return;
                }
                try {
                  setLoading(true);

                  const response = await axios.post(
                    `/common/form-response-with-history`,
                    {
                      finishedPdfUrl: respondent?.finishedPdfUrl,
                      formDetails: {
                        formJson: formDetails.formJson,
                        formId: formDetails.formId,
                        createdAt: formDetails.createdAt,
                        authorEmail: formDetails.authorEmail,
                        lang: formDetails.lang,
                      },
                      formResponseId: respondent?.formResponseId,
                      respondentEmail: respondent?.respondentEmail,
                      timeZone: owner?.accountInfo?.timeZone,
                      responseFilledAt: respondent?.createdAt,
                    },
                    {
                      responseType: "blob",
                    }
                  );

                  if (response?.data?.error) {
                    toast.error(response?.data?.error);
                    console.log(
                      "error in getting certificate: ",
                      response?.data?.error
                    );
                    setLoading(false);
                    return;
                  } else {
                    saveAs(
                      response.data,
                      `${formDetails.formJson.title}-${respondent?.formResponseId}_with-history.pdf`
                    );
                    setLoading(false);
                  }
                } catch (error) {
                  setLoading(false);
                  console.log("Error generating certificate: ", error);
                  toast.error("Error generating certificate");
                }
              }}
              disabled={loading}
            >
              <PiCertificateDuotone size={18} className="" />
            </BoloButton>
          </div>
        ),
        // Certificate: (
        //   <button
        //     className={`flex-row justify-center items-center text-center hover:opacity-90 text-sm disabled:opacity-50 rounded-md capitalize mr-6 shadow border border-primary px-2 py-1 bg-white font-medium flex text-primary`}
        //     // data-tip={"Settings"}
        //   >
        //     <AiOutlineDownload className="hover:opacity-85 mr-2" />
        //     <span className="text-xs">Certificate</span>
        //   </button>
        // ),

        // ?? tableData.page * tableData.perPage + i + 1 - tableData.perPage,
        "Response Filled At": (
          <div className="text-[10px]">
            {new Date(respondent.createdAt).toDateString()}
            {"  "}
            {new Date(respondent.createdAt).toLocaleTimeString()}
          </div>
        ),
        "Delete Response": (
          <BoloButton
            size={"sm"}
            variant="danger"
            className="flex items-center gap-2 text-xs md:text-xs h-6"
            onClick={(e) => {
              e.stopPropagation();
              setDeletingResponse(respondent);
              setShow((prev) => {
                return {
                  ...prev,
                  deleteOneResponseModal: true,
                };
              });
            }}
            disabled={roleInCollaboration === "readonly" || loading}
            // className={`flex-row justify-center items-center text-center hover:opacity-90 text-sm disabled:opacity-50 rounded-md capitalize mr-6 shadow border px-2 py-1 bg-red-400 font-medium flex text-white`}
            // data-tip={"Settings"}
          >
            {/* <MdOutlineDelete className="hover:opacity-85" /> */}
            <span className="text-xs">Delete</span>
          </BoloButton>
        ),
        Respondent: respondent.respondentEmail ?? "N/A",
        ...response,
      };
    });

    setTableData((prev) => {
      return {
        ...prev,
        data,
      };
    });
  }, [
    allRespondents,
    searchResults,
    bulkApproveRejectData?.respondents,
    bulkDeleteResponseData?.respondents,
    show.multiApproveNReject,
    tableData.page,
    tableData.perPage,
    searchQuery.length,
    selectedPdfs,
    showSelectedDownload,
    setShowSelectedDownload,
  ]);

  const columnHeader = (column) => (
    <div className="flex items-center">
      <div className="truncate">{column}</div>
      <div className="flex items-center ml-2">
        <MdKeyboardArrowDown className="text-xl" />
      </div>
    </div>
  );

  React.useMemo(() => {
    let columnPreference = JSON?.parse(
      localStorage.getItem("formColumnPrefrences")
    )?.[formId];

    const hiddenColumn = {
      id: v4(),
      name: "hidden",
      Header: (
        <div className="text-xs text-gray-700 flex flex-row items-center justify-end">
          <div className="flex justify-center">
            <input
              type="checkbox"
              className="mr-3 h-4 w-4"
              onChange={handleSelectAllChange}
              // value={bulkApproveRejectData.selectAll}
              // checked={bulkApproveRejectData.selectAll}
            />
          </div>
          <div className="font-normal">Select All</div>
        </div>
      ),
      accessor: "hidden", // accessor is the "key" in the data
      show: false,
      pinned: true,
    };

    if (columnPreference) {
      // check if hidden column preference is present in local storage or not
      let hiddenColIndex = columnPreference?.findIndex(
        (col) => col?.accessor === "hidden"
      );

      // check if hidden column preference is present in local storage or not
      let lastUpdatedColIndex = columnPreference?.findIndex(
        (col) => col?.accessor === "Last Updated"
      );

      if (lastUpdatedColIndex === -1) {
        // let col = {
        //   id: v4(),
        //   name: "Last Updated",
        //   Header: "Last Updated",
        //   accessor: "Last Updated", // accessor is the "key" in the data
        //   show: true,
        //   pinned: false,
        // };
        // columnPreference?.splice(4, 0, col);
      }
      if (hiddenColIndex === -1) {
        columnPreference?.unshift(hiddenColumn);
      }

      columnPreference = columnPreference?.map((col) => {
        return {
          ...col,
          Header:
            col?.Header === "hidden"
              ? hiddenColumn.Header
              : columnHeader(col.Header),
        };
      });
      setTableData((prev) => {
        return {
          ...prev,
          columns: columnPreference,
        };
      });
      return;
    }

    let cols = [
      hiddenColumn,
      // {
      //   id: v4(),
      //   name: "#",
      //   Header: columnHeader("#"),
      //   accessor: "#", // accessor is the "key" in the data
      //   show: true,
      //   pinned: true,
      // },
      //   {
      //     id: v4(),
      //     name: "Overall Status",
      //     Header: columnHeader("Overall Status"),
      //     accessor: "Overall Status",
      //     show: true,
      //     pinned: true,
      //   },
      {
        id: v4(),
        name: "Response PDF",
        Header: columnHeader("Response PDF"),
        accessor: "Response PDF",
        show: true,
      },
      // {
      //   id: v4(),
      //   name: "Certificate",
      //   Header: columnHeader("Certificate"),
      //   accessor: "Certificate",
      //   show: true,
      // },
      {
        id: v4(),
        name: "Delete Response",
        Header: columnHeader("Delete Response"),
        accessor: "Delete Response",
        show: true,
      },
      {
        id: v4(),
        name: "Response Filled At",
        Header: columnHeader("Response Filled At"),
        accessor: "Response Filled At",
        show: true,
      },
      // {
      //   id: v4(),
      //   name: "Last Updated",
      //   Header: columnHeader("Last Updated"),
      //   accessor: "Last Updated",
      //   show: true,
      // },
      {
        id: v4(),
        name: "Respondent",
        Header: columnHeader("Respondent"),
        accessor: "Respondent",
        show: true,
      },
    ];
    if (showSelectedDownload) {
      let newCols = [
        {
          id: v4(),
          name: "Select",
          Header: columnHeader("Select"),
          accessor: "Select", // accessor is the "key" in the data
          show: true,
          pinned: false,
        },
        ...cols,
      ];
      cols = newCols;
    }

    Object?.entries(formDetails?.responseItemsId ?? {})?.forEach(
      ([key, value]) => {
        value = value
          ?.replace("\n", "")
          ?.replace(/(\r\n|\n|\r)/gm, "")
          ?.trim();
        cols.push({
          id: v4(),
          name: value,
          Header: columnHeader(value),
          accessor: key,
          show: true,
          pinned: false,
        });
      }
    );

    setTableData((prev) => {
      return {
        ...prev,
        columns: cols,
      };
    });
  }, [formDetails, showSelectedDownload, setShowSelectedDownload]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: tableData.columns ?? [], data: tableData.data ?? [] });

  const bulkApproveRejectModal = () => (
    <Fragment>
      {!show.bulkProcessing ? (
        <div id="form-container" className="w-full mt-10 flex flex-col">
          <label className="mb-2 text-sm">Your Comment: (internal)</label>
          <textarea
            placeholder="Write a message..."
            id="input-comment"
            className="border border-gray-500 bg-gray-100 rounded-lg p-4"
            rows="4"
            value={bulkApproveRejectData.comment}
            onChange={(e) =>
              setBulkApproveRejectData((prev) => ({
                ...prev,
                comment: e.target.value,
              }))
            }
          ></textarea>

          <label className="mt-6 text-sm">Default Comments</label>
          <div
            id="prefill-comments"
            className="flex flex-e=row flex-wrap text-sm mt-2 mb-4"
          >
            <span
              className="border-2 border-gray-700 px-4 py-1 mr-4 mb-4 rounded-lg cursor-pointer hover:opacity-90"
              onClick={() =>
                setBulkApproveRejectData((prev) => ({
                  ...prev,
                  comment: "Approved",
                }))
              }
            >
              Approved
            </span>
            <span
              className="border-2 border-gray-700 px-4 py-1 mr-4 mb-4 rounded-lg cursor-pointer hover:opacity-90"
              onClick={() =>
                setBulkApproveRejectData((prev) => ({
                  ...prev,
                  comment: "Rejected",
                }))
              }
            >
              Rejected
            </span>
            <span
              className="border-2 border-gray-700 px-4 py-1 mr-4 mb-4 rounded-lg cursor-pointer hover:opacity-90"
              onClick={() =>
                setBulkApproveRejectData((prev) => ({
                  ...prev,
                  comment: "Okay!",
                }))
              }
            >
              Okay!
            </span>
          </div>

          <div className="mt-4 ">
            {show.bulkApproveRejectActiveModalButton === "approve" && (
              <button
                id="approve-btn"
                onClick={() =>
                  handleBulkApproveReject(
                    "approved",
                    bulkApproveRejectData.comment
                  )
                }
                className="w-44 bg-blue-600 text-md text-white px-8 py-3 rounded-xl shadow mr-4 mb-4 hover:opacity-80"
              >
                Approve
              </button>
            )}
            {show.bulkApproveRejectActiveModalButton === "reject" && (
              <button
                id="reject-btn"
                onClick={() =>
                  handleBulkApproveReject(
                    "rejected",
                    bulkApproveRejectData.comment
                  )
                }
                className="w-44 bg-red-600 text-md text-white px-8 py-3 rounded-xl shadow hover:opacity-80"
              >
                Reject
              </button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="py-6 px-6">
            <h2 className="text-lg font-semibold mb-1">
              Your requests is being processed!
            </h2>
            <p className="text-sm">
              It can take upto 10 mins to be processed. Have patience and be
              calm :)
            </p>
          </div>
          <button
            onClick={() => {
              setShow((prev) => {
                return {
                  ...prev,
                  bulkApproveRejectModal: false,
                  bulkProcessing: false,
                };
              });
            }}
            className="w-44 m-6 bg-blue-600 text-md text-white px-8 py-3 rounded-xl shadow mr-4 mb-4 hover:opacity-80"
          >
            Close
          </button>
        </div>
      )}
      <div
        onClick={() => {
          setShow((prev) => {
            return {
              ...prev,
              bulkApproveRejectModal: false,
              bulkProcessing: false,
            };
          });
        }}
        className="absolute top-0 right-0 cursor-pointer"
      >
        <MdClose className="text-2xl m-4" />
      </div>
    </Fragment>
  );

  const bulkDeleteResponseModal = () => (
    <Fragment>
      <div>
        <div className="py-3 md:py-6 px-3  md:px-6">
          <h2 className="text-base md:text-lg font-semibold mb-1">
            Are you sure you want to delete these requests?
          </h2>
          <p className="text-sm">
            It can take upto 5 mins to be processed. Have patience and be calm
            :)
          </p>
        </div>
        <button
          disabled={show.bulkProcessing}
          onClick={handleBulkDeleteResponse}
          className="mx-6 bg-red-600 text-sm md:text-base text-white px-4 md:px-8 py-1.5 md:py-2.5 rounded-lg md:rounded-xl shadow mr-4 mb-4 hover:opacity-80 disabled:opacity-50 w-fit ml-auto"
        >
          {show.bulkProcessing ? "Deleting..." : "Delete"}
        </button>
      </div>
      {/* )} */}
      <div
        onClick={() => {
          setShow((prev) => {
            return {
              ...prev,
              bulkDeleteResponseModal: false,
            };
          });
        }}
        className="absolute top-0 right-0 cursor-pointer"
      >
        <MdClose className="text-2xl m-4" />
      </div>
    </Fragment>
  );

  const deleteOneResponseModal = () => (
    <Fragment>
      <div>
        <div className="py-6 px-6">
          <h2 className="text-lg font-semibold mb-1">
            Are you sure you want to delete this response?
          </h2>
          <p className="text-sm">
            The response from {deletingResponse?.respondentEmail} will be
            deleted.
          </p>
        </div>
        <button
          disabled={show.oneDeleting}
          onClick={handleOneResponseDelete}
          className="mx-6 bg-red-600 text-md text-white px-8 py-2.5 rounded-xl shadow mr-4 mb-4 hover:opacity-80"
        >
          {show.oneDeleting ? "Deleting..." : "Delete"}
        </button>
      </div>
      {/* )} */}
      <div
        onClick={() => {
          setShow((prev) => {
            return {
              ...prev,
              deleteOneResponseModal: false,
            };
          });
        }}
        className="absolute top-0 right-0 cursor-pointer"
      >
        <MdClose className="text-2xl m-4" />
      </div>
    </Fragment>
  );

  if (!tableData.data) {
    // toast.error("Error getting respondents");
    return (
      <div className="h-screen -mt-[70px] flex justify-center items-center">
        <BoloLoader />
      </div>
    );
  }

  if (
    owner?.planhistory &&
    !owner?.planhistory?.[owner?.planhistory?.length - 1]?.isActive
  ) {
    console.log("Plan is exausted", owner);
    return <PlanExhaustedNavBanner />;
  }

  return (
    <div className="-m-4 bg-white h-[100%]">
      <Helmet>
        <title translate="no">Form Responses</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Form Responses" />
        <meta
          name="description"
          content="Efficiently manage form responses. Download individual responses and access response details for streamlined form management."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/form`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Form Responses- Boloforms`} />
        <meta
          property="og:description"
          content="Efficiently manage form responses. Download individual responses and access response details for streamlined form management."
        />
        <link rel="canonical" href="https://signature.boloforms.com/form" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      <div className="w-[100%] md:w-fit px-3 md:px-4 md:h-[40px] my-3 md:my-6 text-gray-700 flex flex-row gap-4 items-center justify-between md:justify-start">
        {/* <div className="hidden md:flex flex-row h-full text-gray-700">
          <button
            onClick={() => {
              setTableData((prev) => {
                return {
                  ...prev,
                  page: prev.page - 1,
                };
              });
            }}
            disabled={tableData.page == 1}
            className="border p-3 shadow hover:bg-gray-200 cursor-pointer hover:opacity-90 rounded-l-md"
          >
            <MdOutlineArrowBackIos />
          </button>
          <button
            onClick={() => {
              setTableData((prev) => {
                return {
                  ...prev,
                  page: prev.page + 1,
                };
              });
            }}
            disabled={
              tableData.page * tableData.perPage >=
              (searchQuery?.length > 0
                ? searchResults.respondentCount
                : allRespondents.respondentCount)
            }
            className="border p-3 shadow hover:bg-gray-200 cursor-pointer hover:opacity-90 rounded-r-md"
          >
            <MdOutlineArrowForwardIos />
          </button>
        </div> */}
        {/* @ts-ignore */}
        {formDetails?.isApprovalWorkflowEnabled && (
          <div className="py-2 px-2 h-full border shadow rounded text-xs font-medium">
            <select
              className="w-[80px] h-full bg-transparent outline-none "
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                setTableData((prev) => ({ ...prev, page: 1 }));
                // localStorage.setItem("respondentFilter", e.target.value);
              }}
            >
              <option value="all">All</option>
              {/* {formFilters?.map((filter) => {
                return <option value={filter}>{filter}</option>;
              })} */}
            </select>
          </div>
        )}
        <div
          ref={columnHideRef}
          className="h-[40px] hidden md:flex flex-row items-center"
        >
          <div className="relative">
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              className="flex items-center gap-2 text-xs md:text-xs"
              onClick={() => {
                setShow((prev) => {
                  return {
                    ...prev,
                    columns: !prev.columns,
                  };
                });
              }}
              // className="flex flex-row items-center py-2 px-4 h-full border shadow rounded text-xs font-medium"
            >
              <MdOutlineVisibility className="" size={16} />
              Columns
            </BoloButton>
            {show.columns && (
              <ColumnsShowHide
                columns={tableData.columns}
                setTableData={setTableData}
              />
            )}
          </div>
        </div>
        {showSelectedDownload ? (
          <div className="flex gap-1">
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
              onClick={async (e) => {
                e.stopPropagation();
                setBulkDownloading(true);
                await handleBulkDownload(true);
                setBulkDownloading(false);
              }}
              disabled={loading}
            >
              <MdOutlineDownload className="" size={16} />
              <span className="hidden md:block">
                {bulkDownloading ? "Downloading" : "Download All"}
              </span>
              <span className="md:hidden">
                {bulkDownloading ? "Downloading" : "All"}
              </span>
            </BoloButton>
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
              onClick={async (e) => {
                e.stopPropagation();
                setBulkDownloading(true);
                await handleBulkDownload();
                setBulkDownloading(false);
              }}
              disabled={loading}
            >
              <MdOutlineDownload className="" size={16} />
              {bulkDownloading ? "Downloading" : "Selected"}
            </BoloButton>
            <BoloButton
              onClick={async (e) => {
                setShowSelectedDownload(false);
                setSelectedPdfs([]);
              }}
              disabled={loading}
              size={"sm"}
              variant="gray-shadow"
              className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
            >
              <span className=" md:block text-red-400">Cancel</span>
            </BoloButton>
          </div>
        ) : (
          <BoloButton
            size={"sm"}
            variant="gray-shadow"
            className="flex items-center gap-2 text-xs md:text-xs"
            onClick={async (e) => {
              const isBulkDownloadEnabled = await isFeatureEnabled(
                "BULK_DOWNLOAD"
              );
              if (!isBulkDownloadEnabled && !whichAdmin) {
                new MixPanel().track("Form", {
                  form_action: "form_bulk_pdf_download",
                  paid: true,
                });
                new MixPanel().increment("form_bulk_pdf_download");
                setShowUpgradeModal(true);
                setUpgradeModalType("BULK_DOWNLOAD");
                // setUpgradeModalData({
                //   title: "Bulk Download is available in Team Plan and above",
                //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
                // });
                return;
              }
              e.stopPropagation();
              setShowSelectedDownload(true);
              // setBulkDownloading(true);
              // await handleBulkDownload();
              // setBulkDownloading(false);
            }}
            disabled={loading}
            // className="flex flex-row items-center py-2 px-4 h-full border shadow rounded text-xs font-medium disabled:opacity-50 "
          >
            <MdOutlineDownload className="" size={16} />
            {bulkDownloading ? "Downloading..." : "Bulk PDF Download"}
          </BoloButton>
        )}

        {formDetails?.spreadsheetUrl && (
          <div className="h-[40px] flex flex-row">
            <button
              onClick={async (e) => {
                e.stopPropagation();
                window.open(
                  formDetails?.spreadsheetUrl ||
                    `https://docs.google.com/spreadsheets/d/${formDetails?.destinationId}`,
                  "_blank"
                );
              }}
              className="flex flex-row items-center py-2 px-4 h-full border shadow rounded text-xs font-medium disabled:opacity-50 "
            >
              <BsFileEarmarkSpreadsheetFill className="mr-2" />
              {"View in Sheets"}
            </button>
          </div>
        )}
        <BoloButton
          size={"sm"}
          variant="gray-shadow"
          className="flex items-center gap-2 text-xs md:text-xs"
          onClick={async (e) => {
            e.stopPropagation();

            new MixPanel().track("Form", {
              form_action: "form_export_to_excel",
              paid: true,
            });
            new MixPanel().increment("form_export_to_excel");
            if (!featuresEnabled.CSV_EXPORT) {
              setNoPlanError(true);
              setShowUpgradeModal(true);
              setUpgradeModalType("CSV_EXPORT");
              return;
            }

            setExportingToCsv(true);

            try {
              const response = await axios.get(
                `/respondents/export-to-csv/${formId}`,
                { responseType: "blob" }
              );
              const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const url = window.URL.createObjectURL(blob);

              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = `form_${formId}_responses.xlsx`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            } catch (error) {
              console.error(error);
            }

            setExportingToCsv(false);
          }}
          disabled={loading}
          // className="flex flex-row items-center py-2 px-4 h-full border shadow rounded text-xs font-medium disabled:opacity-50 "
        >
          <TbTableExport className="" size={14} />
          {exportingToCsv ? "Exporting..." : "Export to Excel"}
        </BoloButton>
        {/* {noPlanError && (
          <PleaseUpgradeModal
            show={noPlanError}
            setShow={(value) => setNoPlanError(value)}
            // data={upgradeModalData.data}
            blockingType={upgradeModalType}
          />
        )} */}

        <div
          ref={dateFilterRef}
          className="h-[40px]  hidden md:flex items-center cursor-pointer relative ml-4"
        >
          {show.dateFilter && (
            <DateRangeFilter
              setDateRangeFilter={setDateRangeFilter}
              dateRangeFilter={dateRangeFilter}
              setSearchDateFilter={setSearchDateFilter}
            />
          )}
        </div>
        {collaborator?.role !== "readOnly" && (
          <div
            ref={moreOptionsRef}
            className={`h-[40px] w-fit ml-4 hidden md:block`}
          >
            <div className="relative h-full w-full cursor-pointer"></div>
          </div>
        )}
      </div>
      {/* PC layout*/}
      {window.innerWidth > 768 && (
        <div
          className={`hidden md:block relative`}
          style={{
            overflowY:
              allRespondents?.data?.length === 0 && !loading
                ? "hidden"
                : "auto",
            maxHeight:
              allRespondents?.data?.length === 0 && !loading
                ? 0
                : "calc(100% - 130px)",
            height:
              allRespondents?.data?.length === 0 && !loading
                ? 0
                : "calc(100% - 130px)",
          }}
          id="mini-pdf-custom-scroll"
        >
          {/* <div className="">
            <table {...getTableProps()} className="pt-[40px]">
              <thead className="max-h-[40px] h-[40px] sticky top-0 bg-white shadow z-20">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      // console.log("column", column);
                      return (
                        column.show &&
                        column.pinned && (
                          <>
                            <th
                              {...column.getHeaderProps()}
                              className={`${
                                column.name === "#" || column.name === "hidden"
                                  ? ""
                                  : "min-w-[200px] max-w-[300px]"
                              } px-2 py-2 text-left text-gray-600 font-semibold text-xs truncate relative cursor-pointer`}
                              style={{
                                overflow: "visible",
                              }}
                            >
                              <div
                                data-tooltip-id={`column-header-tooltip-${column.name}`}
                                data-tooltip-offset={1}
                                onClick={() => {
                                  !show.multiApproveNReject &&
                                    !show.deleteResponse &&
                                    setShow((prev) => {
                                      return {
                                        ...prev,
                                        columnOption: {
                                          ...prev.columnOption,
                                          name: column.name,
                                          show:
                                            show.columnOption.name ===
                                            column.name
                                              ? !prev.columnOption.show
                                              : true,
                                        },
                                      };
                                    });
                                }}
                              >
                                {column.render("Header")}
                              </div>
                              {show.columnOption.name === column.name &&
                                show.columnOption.show && (
                                  <ColumnOptions
                                    column={column}
                                    setTableData={setTableData}
                                    setShow={setShow}
                                  />
                                )}
                            </th>
                            <CustomTooltip
                              helpers={{
                                id: `column-header-tooltip-${column.name}`,
                                content: column.name,
                                place: "top",
                              }}
                            />
                          </>
                        )
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="hover:bg-secondary/40 cursor-pointer h-[55px]"
                      onClick={() => {}}
                    >
                      {row.cells.map((cell, i) => {
                        let activeCell = `${cell.row.index + 1}-${i + 1}`;
                        let width =
                          cell.column.name === "#" ||
                          cell.column.name === "hidden"
                            ? "w-full"
                            : "min-w-[200px] max-w-[300px]";

                        return (
                          cell.column.show &&
                          cell.column.pinned && (
                            <>
                              <td
                                {...cell.getCellProps()}
                                className={`${width} px-6 py-3 border-t text-sm relative`}
                                style={{
                                  overflow: "visible",
                                }}
                                onClick={() => {
                                  // console.log("cell", cell);
                                  setShow((prev) => {
                                    return {
                                      ...prev,
                                      activeCell,
                                    };
                                  });
                                }}
                              >
                                <div
                                  className={`${
                                    cell.column.name !== "Overall Status" &&
                                    "truncate"
                                  }`}
                                >
                                  {cell.render("Cell")}
                                </div>
                                {show.activeCell === activeCell && (
                                  <CellExtended
                                    width={width}
                                    value={cell.value}
                                  />
                                )}
                              </td>
                              <div></div>
                            </>
                          )
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
          {/* previously the following line was [<div className="overflow-x-auto w-full border-l">] */}
          <div className="overflow-x-auto w-full border-l ">
            <table {...getTableProps()} className="pt-[40px]">
              <thead className="max-h-[40px] h-[40px] sticky top-0 bg-white shadow z-20">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      if (column?.name === "Select" && !showSelectedDownload) {
                        return <></>;
                      }

                      return (
                        column.show &&
                        !column.pinned && (
                          <>
                            <th
                              {...column.getHeaderProps()}
                              className={`${
                                column.name === "#"
                                  ? ""
                                  : "min-w-[200px] max-w-[300px]"
                              } px-6 py-3 text-left text-gray-600 font-semibold text-xs truncate relative cursor-pointer`}
                              style={{
                                overflow: "visible",
                              }}
                            >
                              <div
                                data-tooltip-id={`column-header-tooltip-${column.name}`}
                                data-tooltip-offset={1}
                                onClick={() => {
                                  setShow((prev) => {
                                    return {
                                      ...prev,
                                      columnOption: {
                                        ...prev.columnOption,
                                        name: column.name,
                                        show:
                                          show.columnOption.name === column.name
                                            ? !prev.columnOption.show
                                            : true,
                                      },
                                    };
                                  });
                                }}
                              >
                                {column.render("Header")}
                              </div>

                              {show.columnOption.name === column.name &&
                                show.columnOption.show && (
                                  <ColumnOptions
                                    column={column}
                                    setTableData={setTableData}
                                    setShow={setShow}
                                  />
                                )}
                            </th>
                            <CustomTooltip
                              helpers={{
                                id: `column-header-tooltip-${column.name}`,
                                content: column.name,
                                place: "top",
                              }}
                            />
                          </>
                        )
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  // console.log("row", row);
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="hover:bg-secondary/40 cursor-pointer h-[55px]"
                      onClick={() => {
                        let respondent = rowData[row?.id];
                        if (showSelectedDownload) {
                          if (
                            selectedPdfs?.some(
                              (ele: any) => ele?._id === respondent?._id
                            )
                          ) {
                            setSelectedPdfs((prev: any) =>
                              prev.filter(
                                (item: any) => item?._id !== respondent?._id
                              )
                            );
                          } else {
                            setSelectedPdfs((prev: any) => [
                              ...prev,
                              respondent,
                            ]);
                          }
                        }
                      }}
                    >
                      {row.cells.map((cell, i) => {
                        let activeCell = `${cell.row.index + 1}-${i + 1}`;
                        let width =
                          cell.column.name === "#"
                            ? "w-full"
                            : "min-w-[200px] max-w-[300px]";
                        if (
                          cell?.column?.name === "Select" &&
                          !showSelectedDownload
                        ) {
                          return <></>;
                        }
                        return (
                          cell.column.show &&
                          !cell.column.pinned && (
                            <>
                              <td
                                {...cell.getCellProps()}
                                className={`${width} px-6 py-3 border-t text-sm relative`}
                                style={{
                                  overflow: "visible",
                                }}
                                onClick={() => {
                                  // console.log("cell", cell);
                                  setShow((prev) => {
                                    return {
                                      ...prev,
                                      activeCell,
                                    };
                                  });
                                }}
                              >
                                <div
                                  className={`${
                                    cell.column.name !== "Overall Status" &&
                                    "truncate"
                                  }`}
                                >
                                  {cell.render("Cell")}
                                  {/* {cell.row.original[cell?.column?.name?.trim()]} */}
                                </div>
                                {show.activeCell === activeCell && (
                                  <CellExtended
                                    width={width}
                                    value={cell.value}
                                  />
                                )}
                              </td>
                              <div></div>
                            </>
                          )
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* Mobile Layout */}
      {window.innerWidth <= 768 && (
        <div className="block md:hidden">
          <div className="flex flex-col">
            {rowData.map((row, idx) => {
              return (
                <div
                  key={idx}
                  className={`relative border border-t py-3 px-3 flex gap-2 justify-between items-center hover:bg-secondary/40 cursor-pointer`}
                  onClick={() => {
                    if (showSelectedDownload && row?.finishedPdfUrl) {
                      if (
                        selectedPdfs?.some((ele: any) => ele?._id === row?._id)
                      ) {
                        setSelectedPdfs((prev: any) =>
                          prev.filter((item: any) => item?._id !== row?._id)
                        );
                      } else {
                        setSelectedPdfs((prev: any) => [...prev, row]);
                      }
                    }
                  }}
                >
                  <div className="flex gap-3">
                    {showSelectedDownload && (
                      <BoloCheckboxMui
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setSelectedPdfs((prevSelected: any) => {
                            let newSelected = [...prevSelected];
                            if (
                              isChecked &&
                              !newSelected.some(
                                (item) => item?._id === row?._id
                              )
                            ) {
                              newSelected.push(row);
                            } else if (!isChecked) {
                              newSelected = newSelected.filter(
                                (item: any) => item?._id !== row?._id
                              );
                            }
                            return newSelected;
                          });
                        }}
                        disabled={!row?.finishedPdfUrl}
                        checked={selectedPdfs.some(
                          (item: any) => item?._id === row?._id
                        )}
                      />
                    )}
                    <div className="break-all flex text-xs flex-col ml-2">
                      <div className="font-semibold">
                        {row?.documentName ||
                          row?.formJson?.title ||
                          row?.name ||
                          "Untitled"}
                      </div>
                      <div className="flex flex-wrap gap-2 text-[10px] mb-[2px]">
                        <span>{row?.respondentEmail}</span>
                      </div>
                      <div className="flex text-[10px] gap-2">
                        <span className="text-gray-700">
                          {new Date(row.createdAt).toDateString()}
                        </span>
                        <span className="text-gray-500">
                          {new Date(row.createdAt).toLocaleTimeString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <button
                      disabled={loading}
                      onClick={async (e) => {
                        e.stopPropagation();
                        new MixPanel().track("Form", {
                          form_action: "form_response_download_pdf",
                          paid: false,
                        });
                        new MixPanel().increment("form_response_download_pdf");
                        if (loading) {
                          return;
                        }

                        if (!row?.finishedPdfUrl) {
                          try {
                            toast?.info(
                              "For this response PDF generation was disabled!"
                            );
                            return;
                          } catch (err) {
                            console.log(
                              "error from pdf generation when pdf generation was disabled: ",
                              err
                            );
                            toast.error("Error generating pdf from server");
                            return;
                          }
                        }

                        if (row?.finishedPdfUrl) {
                          window.open(row?.finishedPdfUrl, "_blank");
                          return;
                        }
                      }}
                      className={`w-fit whitespace-nowrap flex flex-row justify-center items-center text-center hover:opacity-90 text-xs rounded capitalize border px-2 py-1.5 bg-secondary/60 text-primary font-medium border-primary/50 hover:border-primary shadow-sm`}
                    >
                      Download
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();

                        setDeletingResponse(row);
                        setShow((prev) => {
                          return {
                            ...prev,
                            deleteOneResponseModal: true,
                          };
                        });
                      }}
                      disabled={roleInCollaboration === "readonly" || loading}
                      className={`flex-row justify-center items-center text-center hover:opacity-90 text-sm disabled:opacity-50 rounded-md capitalize mr-6 shadow border px-2 py-1 bg-red-400 font-medium flex text-white`}
                      // data-tip={"Settings"}
                    >
                      <MdOutlineDelete className="hover:opacity-85" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {show.deleteResponse && (
        <div className="flex flex-row items-center px-5 py-3 border-t-2">
          <button
            onClick={() => {
              new MixPanel().track("Form", {
                form_action: "form_delete_response",
                paid: false,
              });
              new MixPanel().increment("form_delete_response");
              setShow((prev) => {
                return {
                  ...prev,
                  bulkDeleteResponseModal: true,
                };
              });
            }}
            className="w-[80px] h-[27px] text-[10px] text-white bg-[#E0413E] px-2 py-1 rounded-md"
          >
            Delete
          </button>
        </div>
      )}
      <div className="flex flex-row justify-between items-center px-3 md:px-5 py-3 border-t md:border-t-2">
        {searchResults?.message === "No result found for this search" ||
        allRespondents?.data?.length === 0 ? (
          <div className="w-full flex flex-col items-center py-12 text-gray-600">
            <div className="mb-4">
              <FiSearch className="text-5xl" />
            </div>
            <p className="text-sm">
              {searchResults?.message ?? "No Entries Found!"}
            </p>
          </div>
        ) : (
          <Fragment>
            <div className="flex gap-2 items-center">
              <BoloButton
                size={"sm"}
                variant="gray-shadow"
                className=""
                onClick={() => {
                  // setTableData((prev) => {
                  //   return {
                  //     ...prev,
                  //     page: prev.page - 1,
                  //   };
                  // });
                  let currPage = Number(searchParams.get("page") || "1");
                  searchParams.set("page", currPage - 1);
                  setSearchParams(searchParams);
                }}
                disabled={
                  Number(searchParams.get("page") || "1") === 1 ||
                  !searchParams.get("page")
                }
                // disabled={tableData.page == 1}
                // className="mr-2 py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
              >
                Previous
              </BoloButton>
              <BoloButton
                size={"sm"}
                variant="gray-shadow"
                onClick={() => {
                  // setTableData((prev) => {
                  //   return {
                  //     ...prev,
                  //     page: prev.page + 1,
                  //   };
                  // });
                  let currPage = Number(searchParams.get("page") || "1");
                  searchParams.set("page", currPage + 1);
                  setSearchParams(searchParams);
                }}
                disabled={
                  Number(searchParams.get("page") || "1") * tableData.perPage >=
                  (searchQuery?.length > 0
                    ? searchResults.respondentCount
                    : allRespondents.respondentCount)
                }
                className="py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
              >
                Next
              </BoloButton>
            </div>
            <div className="text-gray-500 text-xs md:text-sm">
              {" "}
              <div aria-hidden="true">{`Page ${tableData.page} of ${Math.ceil(
                (searchQuery?.length > 0
                  ? searchResults.respondentCount
                  : allRespondents.respondentCount) / tableData.perPage
              )}`}</div>
            </div>
            <div className="hidden md:block text-gray-500 text-xs md:text-sm">
              No. of rows
              <select
                value={tableData.perPage}
                onChange={(e) => {
                  setTableData((prev) => {
                    return {
                      ...prev,
                      perPage: e.target.value,
                    };
                  });
                  localStorage.setItem("pageLimit", e.target.value);
                }}
                className="form-select w-[60px] pl-3 bg-gray-100 rounded-md border border-gray-300 mx-4 outline-none"
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
              per page
            </div>
          </Fragment>
        )}
      </div>
      {show.bulkApproveRejectModal && (
        <Modal isWidthFit={false} modalContent={bulkApproveRejectModal()} />
      )}
      {show.bulkDeleteResponseModal && (
        <Modal modalContent={bulkDeleteResponseModal()} />
      )}
      {show.deleteOneResponseModal && (
        <Modal modalContent={deleteOneResponseModal()} />
      )}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
      <Tooltip id="certificate-download-tooltip" openOnClick={false}>
        <p className="m-0 font-light rounded-md cursor-pointer text-xs">
          Download response pdf with certificate
        </p>
      </Tooltip>
    </div>
  );
};
export default Respondents;

const CellExtended = ({ width, value }: any) => {
  if (typeof value === "object") {
    return null;
  }

  return (
    <div
      className={`w-full min-h-[55px] max-h-[225px] py-3 px-6 border-x border-b  text-sm absolute z-20 top-0 right-0 bg-gray-50 overflow-y-auto`}
    >
      <div>{value}</div>
    </div>
  );
};
