import React, { useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { generateQRThunk } from "../../../redux/slices/2faSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { BoloButton } from "../../Common/Button/BoloButton";
import QRCode from "qrcode";
import { toast } from "react-toastify";
import { MdContentCopy } from "react-icons/md";
import BoloLoader from "../../Common/Loader/BoloLoader";
import {
  BoloInputOTP,
  BoloInputOTPGroup,
  BoloInputOTPSeparator,
  BoloInputOTPSlot,
} from "../../Common/OTP/BoloOTP";
import { BASE_URL } from "../../../constants/constants";
import axios from "../../../utils/axios";

const Security = () => {
  const dispatch = useAppDispatch();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data
  );
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const twoFA = useAppSelector((state) => state?.root?.twoFASlice?.data);
  const twoFASlice = useAppSelector((state) => state?.root?.twoFASlice);
  const [qrImage, setQrImage] = useState<string>("");
  const [OTP, setOTP] = useState<string>("");
  const [mfa, setMfa] = useState<{
    show: "start-page" | "setup" | "regenerate";
    regenerate: {
      show: "base" | "update";
      action: "regenerate" | "enable" | "disbale";
    };
  }>({
    show: "start-page",
    regenerate: { show: "base", action: "regenerate" },
  });

  const handleVerify = async () => {
    try {
      let url = `${BASE_URL}/2fa/verify-qr`;
      const body = { otp: OTP, email: owner?.email };
      const { data } = await axios.post(url, body);
      console.log(data);
      if (data?.success && data?.isValid) {
        dispatch(
          generateQRThunk({
            email: owner?.email,
            action: "get",
          })
        );
        setOTP("");
      } else {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("error in handleVerify");
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleVerifyAndRegenerate = async () => {
    try {
      let url = `${BASE_URL}/2fa/verify-qr`;
      const body = { otp: OTP, email: owner?.email };
      const { data } = await axios.post(url, body);
      console.log(data);
      if (data?.success && data?.isValid) {
        setOTP("");
        dispatch(
          generateQRThunk({
            email: owner?.email,
            action: "generate",
          })
        );
        setMfa((prev) => ({
          ...prev,
          regenerate: { ...prev.regenerate, show: "base" },
        }));
      } else {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("error in handleVerifyAndRegenerate");
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleVerifyAndEnableDisable = async ({
    action,
  }: {
    action: "enable" | "disable";
  }) => {
    try {
      let url = `${BASE_URL}/2fa/verify-qr`;
      const body = { otp: OTP, email: owner?.email };
      const { data } = await axios.post(url, body);
      console.log(data);
      if (data?.success && data?.isValid) {
        const enableDisbale = await axios.post(
          `${BASE_URL}/2fa/enable-disbale`,
          {
            email: owner?.email,
            action,
          }
        );
        setOTP("");
        setMfa((prev) => ({
          ...prev,
          regenerate: { ...prev.regenerate, show: "base" },
        }));
        dispatch(
          generateQRThunk({
            email: owner?.email,
            action: "get",
          })
        );
      } else {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("error in handleVerifyAndRegenerate");
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleOTPAuth = (otpAuth: string) => {
    QRCode.toDataURL(
      otpAuth,
      (error: Error | null | undefined, url: string) => {
        if (error) {
          console.log(error);
        } else {
          setQrImage(url);
        }
      }
    );
  };

  useEffect(() => {
    dispatch(
      generateQRThunk({
        email: owner?.email,
        action: "get",
      })
    );
  }, [owner]);

  useEffect(() => {
    if (twoFA.otpAuth) {
      handleOTPAuth(twoFA.otpAuth);
      setMfa((prev) => ({ ...prev, show: "setup" }));
    }
    if (twoFA.secretExists && twoFA.verified) {
      setMfa((prev) => ({ ...prev, show: "regenerate" }));
    }
  }, [twoFA.otpAuth, twoFA.secretExists]);

  return (
    <div className="notranslate w-full md:w-[80%] md:mx-4 flex flex-col gap-4">
      <div className="flex flex-col">
        <p className="text-md font-semibold">Multi Factor Authentication</p>
        <p className="text-xs text-gray-500 mb-4">
          Multi-factor authentication adds a second layer of security to your
          account to help prevent unauthorised access
        </p>
        {twoFASlice?.loading ? (
          <div className="py-16">
            <BoloLoader />
          </div>
        ) : (
          <>
            {mfa.show === "start-page" && (
              <BoloButton
                size={"sm"}
                className="w-fit px-2 md:px-4"
                disabled={twoFA.genLoading}
                onClick={() => {
                  dispatch(
                    generateQRThunk({
                      email: owner?.email,
                      action: "generate",
                    })
                  );
                  setMfa((prev) => ({ ...prev, show: "setup" }));
                }}
              >
                Set up Multi-Factor Authentication
              </BoloButton>
            )}
            {mfa.show === "setup" && (
              <div className="flex flex-col mt-4">
                <p className="font-semibold text-sm mb-1">
                  Step 1: Select an Authenticator App
                </p>
                <p className="text-xs text-gray-500 mb-6">
                  Authenticator apps like Google Authenticator provide a code
                  that you'll need to copy and enter each time you log in
                </p>

                <p className="font-semibold text-sm mb-1">
                  Step 2: Add your account to the app by scanning this QR code
                </p>
                <p className="text-xs text-gray-500 mb-6">
                  Please make sure to scan this QR or add the key mannually as
                  this QR or key won't be visible to you later
                </p>
                <div className="flex flex-col py-4 items-center md:items-start">
                  <img className="max-w-[250px]" src={qrImage}></img>
                  <p className="text-xs text-gray-500 mb-6 mt-2">
                    Or Enter the setup key mannually
                  </p>
                  <div className="flex gap-2 items-center">
                    <p className="text-xs sm:text-sm text-gray-600 max-w-[450px] text-center border rounded p-1 px-2">
                      {twoFA.secret}
                    </p>
                    <p className="text-center border rounded p-1">
                      <MdContentCopy
                        size={16}
                        className="cursor-pointer transition-all duration-300 hover:text-primary text-gray-600"
                        data-tooltip-id="copy-secret"
                        onClick={async () => {
                          try {
                            await navigator.clipboard.writeText(twoFA.secret);
                            toast.success("Key copied to clipboard");
                          } catch (err) {
                            console.error("Failed to copy: ", err);
                          }
                        }}
                      />
                    </p>
                  </div>
                </div>

                <p className="font-semibold text-sm mb-1">
                  Step 3: Verify and enable Two-Factor Authentication
                </p>
                <p className="text-xs text-gray-500 mb-6">
                  After verifying two-factor authentication will be enabled
                </p>
                <div className="flex flex-col items-center md:items-start">
                  <div className="w-fit">
                    <BoloInputOTP
                      className="text-sm"
                      maxLength={6}
                      minLength={6}
                      value={OTP}
                      onChange={(value) => setOTP(value)}
                    >
                      <BoloInputOTPGroup>
                        <BoloInputOTPSlot index={0} variant="primary" />
                        <BoloInputOTPSlot index={1} variant="primary" />
                        <BoloInputOTPSlot index={2} variant="primary" />
                      </BoloInputOTPGroup>
                      <BoloInputOTPSeparator />
                      <BoloInputOTPGroup>
                        <BoloInputOTPSlot index={3} variant="primary" />
                        <BoloInputOTPSlot index={4} variant="primary" />
                        <BoloInputOTPSlot index={5} variant="primary" />
                      </BoloInputOTPGroup>
                    </BoloInputOTP>
                    <BoloButton
                      className="mt-4 w-full"
                      size={"sm"}
                      onClick={() => handleVerify()}
                    >
                      Verify and Enable
                    </BoloButton>
                  </div>
                </div>
              </div>
            )}
            {mfa.show === "regenerate" && (
              <div>
                <p className="text-sm mb-1">
                  Your Two-Factor Authentication has been set up and{" "}
                  {twoFA.enabled ? (
                    <span className="text-green-600 font-semibold">
                      enabled
                    </span>
                  ) : (
                    <span className="text-red-600 font-semibold">disbaled</span>
                  )}
                </p>
                {mfa.regenerate.show === "base" && (
                  <div className="flex gap-4">
                    {twoFA.enabled ? (
                      <BoloButton
                        className="mt-4 w-fit px-4 md:px-6"
                        size={"sm"}
                        variant="danger-border"
                        onClick={() =>
                          setMfa((prev) => ({
                            ...prev,
                            regenerate: {
                              ...prev.regenerate,
                              show: "update",
                              action: "disbale",
                            },
                          }))
                        }
                      >
                        Disbale 2FA
                      </BoloButton>
                    ) : (
                      <BoloButton
                        className="mt-4 w-fit px-4 md:px-6"
                        size={"sm"}
                        variant="secondary"
                        onClick={() =>
                          setMfa((prev) => ({
                            ...prev,
                            regenerate: {
                              ...prev.regenerate,
                              show: "update",
                              action: "enable",
                            },
                          }))
                        }
                      >
                        Enable 2FA
                      </BoloButton>
                    )}
                    <BoloButton
                      className="mt-4 w-fit px-4 md:px-6"
                      size={"sm"}
                      onClick={() =>
                        setMfa((prev) => ({
                          ...prev,
                          regenerate: {
                            ...prev.regenerate,
                            show: "update",
                            action: "regenerate",
                          },
                        }))
                      }
                    >
                      Change QR/Code
                    </BoloButton>
                  </div>
                )}
                {mfa.regenerate.show === "update" && (
                  <>
                    <p className="text-xs text-gray-500 mb-6">
                      {mfa.regenerate.action === "regenerate" &&
                        "Regenerating QR will invalidate all previous devices that are using the current code"}
                      {mfa.regenerate.action === "enable" &&
                        "Enabling 2FA will ask otp each time you login with this account"}
                      {mfa.regenerate.action === "disbale" &&
                        "Disabling 2FA will result in you not being prompted for the OTP every time you log in"}
                    </p>
                    <div className="flex flex-col items-center md:items-start">
                      <div className="w-fit">
                        <BoloInputOTP
                          className="text-sm"
                          maxLength={6}
                          minLength={6}
                          value={OTP}
                          onChange={(value) => setOTP(value)}
                        >
                          <BoloInputOTPGroup>
                            <BoloInputOTPSlot index={0} variant="primary" />
                            <BoloInputOTPSlot index={1} variant="primary" />
                            <BoloInputOTPSlot index={2} variant="primary" />
                          </BoloInputOTPGroup>
                          <BoloInputOTPSeparator />
                          <BoloInputOTPGroup>
                            <BoloInputOTPSlot index={3} variant="primary" />
                            <BoloInputOTPSlot index={4} variant="primary" />
                            <BoloInputOTPSlot index={5} variant="primary" />
                          </BoloInputOTPGroup>
                        </BoloInputOTP>
                        <div className="flex gap-2">
                          <BoloButton
                            className="mt-4 w-[50%]"
                            size={"sm"}
                            variant="secondary"
                            onClick={() => {
                              setMfa((prev) => ({
                                ...prev,
                                regenerate: {
                                  ...prev.regenerate,
                                  show: "base",
                                },
                              }));
                            }}
                          >
                            Cancel
                          </BoloButton>
                          <BoloButton
                            className="mt-4 w-[50%]"
                            size={"sm"}
                            onClick={() => {
                              if (mfa.regenerate.action === "regenerate") {
                                handleVerifyAndRegenerate();
                              }
                              if (
                                mfa.regenerate.action === "enable" ||
                                mfa.regenerate.action === "disbale"
                              ) {
                                handleVerifyAndEnableDisable({
                                  action:
                                    mfa.regenerate.action === "enable"
                                      ? "enable"
                                      : "disable",
                                });
                              }
                            }}
                          >
                            {mfa.regenerate.action === "regenerate" &&
                              "Regenerate QR"}
                            {mfa.regenerate.action === "enable" && "Enable 2FA"}
                            {mfa.regenerate.action === "disbale" &&
                              "Disbale 2FA"}
                          </BoloButton>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
        <Link
          to="https://help.boloforms.com/en/articles/9310510-what-is-2-factor-authentication"
          target={"_blank"}
          className="text-xs text-gray-500 mt-8 w-fit border-b border-gray-500 flex gap-2 items-center"
        >
          Learn More about multi-factor authentication <FiExternalLink />
        </Link>
        <p
          // to={`mailto:${
          //   whiteLabelInfo?.whiteLabelOwner?.settings?.organization
          //     ?.pricingContactEmail || "support@boloforms.com"
          // }`}
          // target={"_blank"}
          onClick={() => {
            (window as any).Intercom(
              "showNewMessage",
              "I have lost access to my 2FA device"
            );
          }}
          className="cursor-pointer text-xs text-gray-500 mt-2 w-fit border-b border-gray-500 flex gap-2 items-center"
        >
          In case you do not have access to you device contact us{" "}
          <FiExternalLink />
        </p>
      </div>
    </div>
  );
};

export default Security;
