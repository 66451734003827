import { useEffect, useState } from "react";

import axios from "../../utils/axios";
// import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import SignatureResponseMapper from "../../components/SignatureResponseMapper";
import StartSigningSignerPage from "../../components/Ui/StartSigningPageComponent";
import SignerDeclined from "../../components/SignerDeclined";
import { BASE_URL } from "../../constants/constants";
import { Helmet } from "react-helmet";
import { IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";

type Props = {};

const SignatureResponseMap = (props: Props) => {
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [showForm, setShowForm] = useState(false);
  const [owner, setOwner] = useState<any>({});
  const [documentAuthor, setDocumentAuthor] = useState<any>("");
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [signerDetails, setSignerDetails] = useState<any>({});
  const [schemas, setSchemas] = useState<any>([]);
  const [pdfPages, setPdfPages] = useState<number>(1);
  const [templateAvailable, setTemplateAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shownModal, setShownModal] = useState(false);
  const [isDocumentAvailableToSign, setIsDocumentAvailableToSign] =
    useState(true);
  const [refetch, setRefetchData] = useState(false);
  const [fetchedData, setFetchedData] = useState<{
    accountInfo: any;
    document: any;
    // !NOTE: this is not supported in old docmentId signerId system
    // ! and will only be supported in getDocumentByKey
    paymentStatus: {
      isPayment: boolean;
      message: string;
      isPaid?: boolean;
    } | null;
  }>({ accountInfo: null, document: null, paymentStatus: null });
  const [brandingInfofetched, setBrandingInfoFetched] =
    useState<boolean>(false);
  const [documentDataFetched, setDocumentDataFetched] =
    useState<boolean>(false);
  const [searchParams] = useSearchParams();
  let [documentId, setDocumentId] = useState(searchParams.get("documentId"));
  let [signerId, setSignerId] = useState(searchParams.get("signerId"));
  let key = searchParams.get("key");
  const [viewLimit, setViewLimit] = useState(false);
  let navigate = useNavigate();

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  const getDocumentDetailsByKey = async () => {
    if (!key) {
      return;
    }
    try {
      // need to send Ip here
      // setLoading(true);
      const ipResponse = await getIpAddressLocal();
      const ipData = await ipResponse;

      const { data } = await axios.get(
        `/signingLog/getOneDocumentOnlyPdfByKey?key=${key}&ip=${ipData.ip}`
      );

      if (data?.success === false) {
        setViewLimit(true);
      }

      if (data.error) {
        toast.error(data.message);
        // setLoading(false);
        return;
      }
      if (!data.error) {
        // toast.success(data.message);
      }

      let pdfUrl = data.document.documentUrl;
      const responseFile = await fetch(pdfUrl);
      const pdfBuffer = await responseFile.arrayBuffer();

      setDocumentId(data?.document?.documentId);
      setFetchedData((prev) => ({
        ...prev,
        document: data?.document,
        paymentStatus: data?.paymentStatus,
      }));
      let document = data?.document;
      console.log(
        "document?.isDeleted, document?.isVoid",
        document?.isDeleted,
        document?.isVoid
      );
      // When document is not deleted or void then check for expired
      if (!document?.isDeleted && !document?.isVoid) {
        if (document?.status === "EXPIRED") {
          navigate(`/signature-expired?key=${key}`);
        }
      }
      let isDocumentAvailableToSign =
        (document?.isDeleted ? !document?.isDeleted : true) &&
        (document?.isVoid ? !document?.isVoid : true);

      setIsDocumentAvailableToSign(isDocumentAvailableToSign);
      setSignerId(data?.signerId);

      // Convert PDF buffer to a base64 string
      let pdfBase64 = arrayBufferToBase64(pdfBuffer);
      pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
      setPdfFile(pdfBase64);
      let allSigners = data.document.respondants;
      let signer = allSigners.find(
        (signer: any) => signer._id === data.signerId
      );
      console.log("signer", signer);

      setDocumentAuthor(
        data.document.ownerId?.name || data.document.authorEmail
      );
      setSignerDetails(signer);
      console?.log(data?.document);
      if (signer?.status == "SIGNED") {
        let navigateSuccess = () => {
          if (key) {
            navigate(`/signature-success?key=${key}`);
          } else {
            navigate(
              `/signature-success?documentId=${documentId}&signerId=${signerId}`
            );
          }
        };
        if (!data?.paymentStatus?.isPayment) {
          // if not payemnt we send to success page else the page should stay the same
          navigateSuccess();
        } else {
          // there is payment then we check the document status
          if (data?.document?.status === "PAID") {
            navigateSuccess();
          }
        }
      }
      setSchemas(data.document?.pdfTemplate?.schemas);
      setDocumentDataFetched(true);
      // setLoading(false);
    } catch (errror) {
      // setLoading(false);
      toast.error("Error fetching form");
    }
  };

  useEffect(() => {
    let isSigner = signerDetails.permission === "SIGNATORY";
    if (!isSigner) return; // setLoading(false);
    if (!signerDetails || !documentId) return; // setLoading(false);

    const fetchIpAddressAndUpdateDocument = async () => {
      try {
        // setLoading(true);
        const response = await getIpAddressLocal();
        const data = await response;
        const { data: updateHistoryData } = await axios.post(`/updateHistory`, {
          email: signerDetails.email,
          documentId: documentId,
          ipAddress: data.ip,
        });
        if (updateHistoryData.error) {
          // toast.error(updateHistoryData.error);
          // if (key) {
          //   navigate(`/signature-success?key=${key}`);
          // } else {
          //   navigate(
          //     `/signature-success?documentId=${documentId}&signerId=${signerId}`
          //   );
          // }
        }
        // setLoading(false);
      } catch (error: any) {
        // setLoading(false);
        console.log("error:", error.message);
      }
    };

    fetchIpAddressAndUpdateDocument();
  }, [signerDetails]);

  let getDocumentDetails = async () => {
    if (!!key) {
      return;
    }
    try {
      // setLoading(true);
      const { data } = await axios.get(
        `/getOneDocument?documentId=${documentId}`
      );
      if (data.error) {
        toast.error(data.message);
        // setLoading(false);
        return;
      }
      if (!data.error) {
        // toast.success(data.message);
      }

      let pdfUrl = data.document.documentUrl;
      const responseFile = await fetch(pdfUrl);
      const pdfBuffer = await responseFile.arrayBuffer();

      // Convert PDF buffer to a base64 string
      let pdfBase64 = arrayBufferToBase64(pdfBuffer);
      pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
      setPdfFile(pdfBase64);
      let allSigners = data.document.respondants;
      let signer = allSigners.find((signer: any) => signer._id === signerId);
      setDocumentAuthor(
        data.document.ownerId?.name || data.document.authorEmail
      );
      setSignerDetails(signer);
      setSchemas(data.document?.pdfTemplate?.schemas);
      setFetchedData((prev) => ({ ...prev, document: data?.document }));

      if (!key) {
        setDocumentDataFetched(true);
      }
      // setLoading(false);
    } catch (errror) {
      // setLoading(false);
      toast.error("Error fetching form");
    }
  };

  const getBrandingInfo = async () => {
    try {
      let { data } = await axios.post(`${BASE_URL}/owner/get-branding-info`, {
        id: documentId,
        type: "PDF",
      });

      if (data?.accountInfo) {
        setFetchedData((prev) => ({
          ...prev,
          accountInfo: data?.accountInfo,
        }));
        setBrandingInfoFetched(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDocumentDetails();
  }, [documentId, signerId]);

  useEffect(() => {
    getDocumentDetailsByKey();
  }, [refetch]);

  useEffect(() => {
    if (pdfFile) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [pdfFile]);

  useEffect(() => {
    if (documentId) {
      getBrandingInfo();
    }
  }, [documentId]);

  console.log({ signerDetails, fetchedData });

  if (viewLimit) {
    return (
      <div className="h-screen flex justify-center items-center flex-col px-4">
        <div className="text-xl md:text-3xl font-bold mb-16 text-center">
          Due to Security Reasons your Link has Expired
        </div>
        <div className="flex gap-4 md:flex-row flex-col-reverse">
          <img
            // src="https://i.imgur.com/wJRAEFT.png"
            src="https://i.imgur.com/hlqjxfi.png"
            alt="logo"
            className="max-h-[45vh] md:max-w-[60vw]"
            loading="lazy"
          />
          <div className="flex flex-col gap-2 items-center md:items-start md:max-w-[300px]">
            <div className="font-semibold md:text-lg ">
              To regenerate your link
            </div>
            <div className="text-sm text-center md:text-start text-gray-600">
              Select the "Sign Now" button in the email that redirected you to
              this page to continue
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (signerDetails?.hasDeclined) {
    return <SignerDeclined fetchedData={fetchedData} />;
  }
  if (loading || !brandingInfofetched || !documentDataFetched) {
    return (
      <div className="h-screen  flex justify-center items-center">
        <BoloLoader />
      </div>
    );
  }
  // if (!showForm) {
  //   return (
  //     <StartSigningSignerPage
  //       loading={loading}
  //       isSigner={signerDetails.permission === "SIGNATORY"}
  //       setStartSigning={setShowForm}
  //       onClick={() => {
  //         setShowForm(true);
  //       }}
  //       documentId={documentId}
  //       extraDetails={{
  //         authorEmail: documentAuthor,
  //         authorName: documentAuthor,
  //         signerName: signerDetails.name,
  //       }}
  //     />
  //   );
  // }

  return (
    <div className="">
      <Helmet>
        <title translate="no">Pdf Signing</title>
        <meta charSet="utf-8" />
        <meta name="title" content="PDF Signature Page" />
        <meta
          name="description"
          content="Signer's dedicated page for completing and adding signatures to the document."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/sign`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`PDF Signature Page - Boloforms`} />
        <meta
          property="og:description"
          content="Signer's dedicated page for completing and adding signatures to the document."
        />
        <link rel="canonical" href="https://signature.boloforms.com/sign" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {shownModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.6)", // Dark overlay color
            zIndex: 999, // Place the overlay above other content
          }}
        ></div>
      )}

      {pdfFile && signerDetails && (
        <div
          className={`w-full h-full absolute ${
            !showForm && "blur-sm overflow-hidden"
          }`}
        >
          <SignatureResponseMapper
            file={pdfFile}
            numberOfPages={pdfPages}
            signerDetails={signerDetails}
            schemas={schemas}
            loading={loading}
            setShownModal={setShownModal}
            isSigning={true}
            documentIdProp={documentId || ""}
            signerIdProp={signerId || ""}
            fetchedData={fetchedData}
            setRefetchData={setRefetchData}
          />
        </div>
      )}
      {!showForm && fetchedData?.accountInfo && brandingInfofetched && (
        <StartSigningSignerPage
          loading={loading}
          isSigner={signerDetails.permission === "SIGNATORY"}
          email={signerDetails?.email}
          setStartSigning={setShowForm}
          onClick={() => {
            setShowForm(true);
          }}
          documentId={documentId}
          extraDetails={{
            authorEmail: documentAuthor,
            authorName: documentAuthor,
            signerName: signerDetails.name,
            signerId: signerId || "",
          }}
          isOnlyPDF={true}
          isDocumentAvailableToSign={isDocumentAvailableToSign}
          accountInfo={fetchedData?.accountInfo}
        />
      )}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default SignatureResponseMap;
