// @ts-nocheck
import { BsCardText, BsCalendarDate } from "react-icons/bs";
import { FaSignature } from "react-icons/fa";
import {
  MdShortText,
  MdRadioButtonChecked,
  MdOutlineArrowDropDownCircle,
  MdDriveFolderUpload,
  MdOutlineCheckBox,
  MdAvTimer,
  MdLinearScale,
} from "react-icons/md";
import { CgMenuGridO, CgMenuGridR } from "react-icons/cg";
// default Questions
import Signature from "../../components/EditForm/QuestionTypes/Signature";
import Checkbox from "../../components/EditForm/QuestionTypes/Checkbox";
import Dropdown from "../../components/EditForm/QuestionTypes/Dropdown";
import FileUpload from "../../components/EditForm/QuestionTypes/FileUpload";
import LongText from "../../components/EditForm/QuestionTypes/LongText";
import RadioGroup from "../../components/EditForm/QuestionTypes/RadioGroup";
import Text from "../../components/EditForm/QuestionTypes/Text";
import Time from "../../components/EditForm/QuestionTypes/Time";
import Date from "../../components/EditForm/QuestionTypes/Date";
import TitleDesc from "../../components/EditForm/QuestionTypes/TitleDesc";
import Image from "../../components/EditForm/QuestionTypes/Image";
import Video from "../../components/EditForm/QuestionTypes/Video";
// notion forms questions
import NotionText from "../../components/EditForm/QuestionTypes/NotionFormsStyle/Text";
import NotionLongText from "../../components/EditForm/QuestionTypes/NotionFormsStyle/LongText";
import NotionCheckbox from "../../components/EditForm/QuestionTypes/NotionFormsStyle/Checkbox";
import NotionRadioGroup from "../../components/EditForm/QuestionTypes/NotionFormsStyle/RadioGroup";
import NotionDropdown from "../../components/EditForm/QuestionTypes/NotionFormsStyle/Dropdown";
import NotionFileUpload from "../../components/EditForm/QuestionTypes/NotionFormsStyle/FileUpload";
import NotionDate from "../../components/EditForm/QuestionTypes/NotionFormsStyle/Date";
import NotionTime from "../../components/EditForm/QuestionTypes/NotionFormsStyle/Time";
import NotionSignature from "../../components/EditForm/QuestionTypes/NotionFormsStyle/Signature";

// new questions
import Linear from "../../components/EditForm/QuestionTypes/Linear";
import GridCheckbox from "../../components/EditForm/QuestionTypes/GridCheckbox";
import GridRadioGroup from "../../components/EditForm/QuestionTypes/GridRadioGroup";

const questionTypes = [
  {
    name: "Text",
    icon: <MdShortText className="text-2xl group-hover:text-primary" />,
    component: (data) => <Text data={data} key={data.questionNo} />,
  },
  {
    name: "LongText",
    icon: <BsCardText className="text-xl group-hover:text-primary" />,
    component: (data) => <LongText data={data} key={data.questionNo} />,
  },
  {
    name: "Checkbox",
    icon: <MdOutlineCheckBox className="text-2xl group-hover:text-primary" />,
    component: (data) => <Checkbox data={data} key={data.questionNo} />,
  },
  {
    name: "RadioGroup",
    icon: (
      <MdRadioButtonChecked className="text-2xl group-hover:text-primary" />
    ),
    component: (data) => <RadioGroup data={data} key={data.questionNo} />,
  },
  {
    name: "Dropdown",
    icon: (
      <MdOutlineArrowDropDownCircle className="text-2xl group-hover:text-primary" />
    ),
    component: (data) => <Dropdown data={data} key={data.questionNo} />,
  },
  {
    name: "FileUpload",
    icon: <MdDriveFolderUpload className="text-2xl group-hover:text-primary" />,
    component: (data) => <FileUpload data={data} key={data.questionNo} />,
  },
  {
    name: "Date",
    icon: <BsCalendarDate className="text-xl group-hover:text-primary" />,
    component: (data) => <Date data={data} key={data.questionNo} />,
  },
  {
    name: "Time",
    icon: <MdAvTimer className="text-2xl group-hover:text-primary" />,
    component: (data) => <Time data={data} key={data.questionNo} />,
  },
  {
    name: "Signature",
    icon: <FaSignature className="text-2xl group-hover:text-primary" />,
    component: (data) => <Signature data={data} key={data.questionNo} />,
  },
  {
    name: "TitleDesc",
    icon: <FaSignature className="text-2xl group-hover:text-primary" />,
    component: (data) => <TitleDesc data={data} key={data.questionNo} />,
  },
  {
    name: "Image",
    icon: <FaSignature className="text-2xl group-hover:text-primary" />,
    component: (data) => <Image data={data} key={data.questionNo} />,
  },
  {
    name: "Video",
    icon: <FaSignature className="text-2xl group-hover:text-primary" />,
    component: (data) => <Video data={data} key={data.questionNo} />,
  },
  {
    name: "Linear",
    icon: <MdLinearScale className="text-2xl group-hover:text-primary" />,
    component: (data) => <Linear data={data} key={data.questionNo} />,
  },
  {
    name: "GridCheckbox",
    icon: <CgMenuGridR className="text-2xl group-hover:text-primary" />,
    component: (data) => <GridCheckbox data={data} key={data.questionNo} />,
  },
  {
    name: "GridRadioGroup",
    icon: <CgMenuGridO className="text-2xl group-hover:text-primary" />,
    component: (data) => <GridRadioGroup data={data} key={data.questionNo} />,
  },
];

const notionFormsquestionTypes = [
  {
    name: "Text",
    icon: <MdShortText className="text-2xl" />,
    component: (data) => <NotionText data={data} key={data.questionNo} />,
  },
  {
    name: "LongText",
    icon: <BsCardText className="text-xl" />,
    component: (data) => <NotionLongText data={data} key={data.questionNo} />,
  },
  {
    name: "Checkbox",
    icon: <MdOutlineCheckBox className="text-2xl" />,
    component: (data) => <NotionCheckbox data={data} key={data.questionNo} />,
  },
  {
    name: "RadioGroup",
    icon: <MdRadioButtonChecked className="text-2xl" />,
    component: (data) => <NotionRadioGroup data={data} key={data.questionNo} />,
  },
  {
    name: "Dropdown",
    icon: <MdOutlineArrowDropDownCircle className="text-2xl" />,
    component: (data) => <NotionDropdown data={data} key={data.questionNo} />,
  },
  {
    name: "FileUpload",
    icon: <MdDriveFolderUpload className="text-2xl" />,
    component: (data) => <NotionFileUpload data={data} key={data.questionNo} />,
  },
  {
    name: "Date",
    icon: <BsCalendarDate className="text-xl" />,
    component: (data) => <NotionDate data={data} key={data.questionNo} />,
  },
  {
    name: "Time",
    icon: <MdAvTimer className="text-2xl" />,
    component: (data) => <NotionTime data={data} key={data.questionNo} />,
  },
  {
    name: "Signature",
    icon: <FaSignature className="text-2xl" />,
    component: (data) => <NotionSignature data={data} key={data.questionNo} />,
  },
  {
    name: "Linear",
    icon: <MdLinearScale className="text-2xl group-hover:text-primary" />,
    component: (data) => <Linear data={data} key={data.questionNo} />,
  },
  {
    name: "GridCheckbox",
    icon: <CgMenuGridR className="text-2xl group-hover:text-primary" />,
    component: (data) => <GridCheckbox data={data} key={data.questionNo} />,
  },
  {
    name: "GridRadioGroup",
    icon: <CgMenuGridO className="text-2xl group-hover:text-primary" />,
    component: (data) => <GridRadioGroup data={data} key={data.questionNo} />,
  },
];

function getQuestionTypes(type) {
  switch (type) {
    case "notion-forms":
      return notionFormsquestionTypes;

    default:
      return questionTypes;
  }
}

export default getQuestionTypes;
