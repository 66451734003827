import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdOutlineError } from "react-icons/md";
import { SiGoogledrive } from "react-icons/si";
import { TailSpin } from "react-loader-spinner";
import { getWhiteLabelInfoLocal } from "../../utils";
import { BoloButton } from "../Common/Button/BoloButton";
import { fetchOwner } from "../../utils";
import axios from "../../utils/axios";
import { BASE_URL } from "../../constants/constants";
import useDrivePicker from "react-google-drive-picker/dist";
import { toast } from "react-toastify";
import { mergePdfs } from "../../utils/pdf";
import { blobToFile, convertToNormalPDF } from "../../utils/uiUtils";
import { FaFilePdf } from "react-icons/fa6";
import { BiSolidUpArrowCircle } from "react-icons/bi";
import { MultiplePdfUploaded } from "./MultiplePdfUploaded";
import { IoCloudDownloadSharp } from "react-icons/io5";
import {
  microsoftAuthRedirectUrl,
  microsoftClientID,
  microsoftScopes,
} from "../../constants/Integrations/Microsoft";
import OnedriveModal from "./OnedriveModal";
// @ts-ignore
import DropboxChooser from "react-dropbox-chooser";
import { GrDropbox } from "react-icons/gr";
import { COLORS } from "../../constants/common";

type UniversalUploaderProps = {
  setLink?: (link: string) => void;
  handleFile?: (file: File) => void;
  setShow: (value: boolean) => void;
};

const acceptedMimeTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-word.document.macroenabled.12",
];

const UniversalUploader = ({
  setLink,
  handleFile,
  setShow,
}: UniversalUploaderProps) => {
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const [openPicker, authResponse] = useDrivePicker();
  const [uploadingMultipleFiles, setUploadingMultipleFiles] = useState(false);
  const [conversionError, setConversionError] = useState(false);
  const [owner, setOwner] = useState<any>({});
  const [files, setFiles] = useState<File[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");
  const [loaders, setLoaders] = useState({
    drive: false,
    uploading: false,
    confirm: false,
  });
  const [showDriveDropDown, setShowDriveDropDown] = useState<boolean>(false);
  const dropdownShareOptionsRef = useRef<any>(null);
  const [showOnedriveModal, setShowOnedriveModal] = useState<boolean>(false);
  const mergingPdfs = async (uploadingFiles: File[]) => {
    setUploadingMultipleFiles(true);
    let mergedFile = await mergePdfs(uploadingFiles);
    if (mergedFile) {
      const fileWithChanged = new File([mergedFile], uploadingFiles[0].name, {
        type: "application/pdf",
        lastModified: new Date().getTime(),
      });
      // mergedFile = fileWithChanged;
      setFile(fileWithChanged);
      // if (isSignature) {
      // onFileUpload(mergedFile);
      setFileName(fileWithChanged.name);
      // } else if (isOnlyPdfTemplate) {
      // onFileUpload && onFileUpload(mergedFile);
      // }
      setUploadingMultipleFiles(false);
    } else {
      setFiles([]);
      setConversionError(true);
    }
  };

  const handleFileInputChange = async (e: any, type = "input") => {
    e.preventDefault();
    setFiles([]);
    if (type === "input" && e.target.files?.length == 0) {
      return;
    }
    setConversionError(false);
    let files_: File[];

    if (type == "drop") {
      files_ = Array.from(e.dataTransfer.files);
    } else {
      files_ = Array.from(e.target.files);
    }

    let uploadingFiles = [];
    let totalSize = 0;
    files_.forEach((file) => (totalSize += file?.size));
    if (totalSize > 10 * 1024 * 1024) {
      toast.error("Total size of the files should be less than 10 MB");
      return;
    }
    // setShowSamplePdfModal(false);
    setLoaders((prev) => ({ ...prev, uploading: true }));
    // if any issue arises with pdf corruption
    // can comment these three lines
    for (let i = 0; i < files_.length; i++) {
      let uploadedFile = files_[i];
      if (!acceptedMimeTypes?.includes(uploadedFile?.type)) {
        setLoaders((prev) => ({ ...prev, uploading: false }));
        toast.error("Only pdf and doc files accepted");
        return;
      }
      if (uploadedFile?.type == "application/pdf") {
        const orignalName = uploadedFile?.name;
        const fillAblePDFCoverted = await convertToNormalPDF(uploadedFile);
        if (!fillAblePDFCoverted) {
          setLoaders((prev) => ({ ...prev, uploading: false }));
          toast.error("Something went wrong with file conversion");
          return;
        }
        const convertedToFile = blobToFile(fillAblePDFCoverted, orignalName);
        if (
          // typeof convertedToFile == File ||
          typeof convertedToFile == "object"
        ) {
          uploadedFile = convertedToFile;
        }
      }
      if (
        uploadedFile.type == "application/msword" ||
        uploadedFile.type ==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        uploadedFile.type == "application/vnd.ms-word.document.macroenabled.12"
      ) {
        setFile(null);
        // onFileUpload(null);
        const formData2 = new FormData();
        formData2.append("docxFile", uploadedFile);
        try {
          const response = await axios.post(
            "https://convertor.boloforms.com/api/conversion/convert-docx-to-pdf",
            formData2,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              responseType: "blob",
            }
          );
          const fileWithChangedData = response.data;

          const fileWithChangedBlob = new Blob([fileWithChangedData], {
            type: "application/pdf",
          });

          const newFileName = uploadedFile.name.replace(/\.[^.]+$/, ".pdf");
          const fileWithChanged = new File([fileWithChangedBlob], newFileName, {
            type: "application/pdf",
            lastModified: new Date().getTime(),
          });
          uploadedFile = fileWithChanged;
        } catch (error) {
          console.error("Error converting doc to pdf:", error);
          toast.error("Error while converting doc to pdf");
          setConversionError(true);
          return;
        }
      }
      uploadingFiles.push(uploadedFile);
    }
    setFiles(uploadingFiles);
    if (uploadingFiles.length > 1) {
      mergingPdfs(uploadingFiles);
    } else {
      let uploadedFile = uploadingFiles[0];
      setFile(uploadedFile);
      // if (isSignature) {
      // onFileUpload(uploadedFile);
      setFileName(uploadedFile.name);
      // } else if (isOnlyPdfTemplate) {
      // onFileUpload && onFileUpload(uploadedFile);
      // }
    }
    setLoaders((prev) => ({ ...prev, uploading: false }));
  };

  const handleGoogleDriveFilePick = async () => {
    setFiles([]);
    // if (isOnlyPdfTemplate) {
    //   new MixPanel().track("PDF template", {
    //     pdf_template_action: "pdf_template_upload_from_drive",
    //     paid: false,
    //   });
    //   new MixPanel().increment("pdf_template_upload_from_drive");
    // } else {
    //   if (formId === "") {
    //     new MixPanel().track("PDF", {
    //       pdf_action: "pdf_upload_from_drive",
    //       paid: false,
    //     });
    //     new MixPanel().increment("pdf_upload_from_drive");
    //   } else {
    //     new MixPanel().track("Form", {
    //       form_action: "form_customize_pdf_upload_from_drive",
    //       paid: false,
    //     });
    //     new MixPanel().increment("form_customize_pdf_upload_from_drive");
    //   }
    // }
    setLoaders((prev) => ({ ...prev, drive: true }));

    let redirectUrl = window.location.pathname + window.location.search;

    let isSufficientPermission =
      owner?.integrationTokens?.google?.oAuthRefreshToken ||
      owner?.oAuthRefreshToken;

    if (!isSufficientPermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=${redirectUrl}`,
        "_self"
      );
      return;
    }
    const { data: permissionDetails } = await axios.post(
      `/forms/check-google-permission`,
      {
        ownerEmail: owner?.email,
      }
    );

    if (!permissionDetails?.isDrivePermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=${redirectUrl}`,
        "_self"
      );
      return;
    }

    let clientId, developerKey;

    try {
      const { data } = await axios.get(
        `${BASE_URL}/common/get-google-credentials`
      );
      clientId = data?.clientId;
      developerKey = data?.developerKey;
    } catch (error) {
      console.error(
        "Error fetching client ID and developer key from the backend",
        error
      );
    }

    setLoaders((prev) => ({ ...prev, drive: false }));

    if (clientId && developerKey) {
      setLoaders((prev) => ({ ...prev, drive: true }));

      openPicker({
        clientId,
        developerKey,
        token: owner?.integrationTokens?.google?.oAuthToken,
        viewId: "PDFS",
        supportDrives: true,
        multiselect: true,
        appId: "204560571245",
        callbackFunction: async (driveData) => {
          if (driveData.action === "cancel") {
            setLoaders((prev) => ({ ...prev, drive: false }));
            console.log("User clicked cancel/close button");
          }

          if (driveData?.docs) {
            let totalSize = 0;
            let uploadingFiles: File[] = [];
            try {
              for (let i = 0; i < driveData?.docs?.length; i++) {
                const fileId = driveData?.docs[i]?.id;
                // Fetch file content from Google Drive API
                const { data } = await axios.post(
                  `${BASE_URL}/common/get-file-from-drive-using-metadata`,
                  {
                    fileId,
                    ownerId: owner?._id,
                  },
                  {
                    responseType: "arraybuffer",
                  }
                );
                // Create a Blob from the ArrayBuffer
                const blob = new Blob([data], { type: "application/pdf" });

                // Create a File from the Blob
                const fileName = driveData?.docs[i]?.name;
                const uploadedFile = new File([blob], fileName, {
                  type: "application/pdf",
                });
                uploadingFiles.push(uploadedFile);
                totalSize += uploadedFile?.size;
              }
              if (totalSize > 10 * 1024 * 1024) {
                toast.error(
                  "Total size of the files should be less than 10 MB"
                );
                setLoaders((prev) => ({ ...prev, drive: false }));
                return;
              }
              setFiles(uploadingFiles);
              if (uploadingFiles?.length > 1) {
                mergingPdfs(uploadingFiles);
              } else {
                let uploadedFile = uploadingFiles[0];
                setFile(uploadedFile);

                // if (isSignature) {
                // onFileUpload(uploadedFile);
                setFileName && setFileName(uploadedFile?.name);
                // } else if (isOnlyPdfTemplate) {
                // onFileUpload && onFileUpload(uploadedFile);
                // }
              }
            } catch (error) {
              console.log(error);
              toast.error("Something went wrong");
            }
            setLoaders((prev) => ({ ...prev, drive: false }));
          }
        },
      });
    }
  };

  const handleConfirm = async () => {
    setLoaders((prev) => ({ ...prev, confirm: true }));
    if (file) {
      handleFile && (await handleFile(file));
    }
    setLoaders((prev) => ({ ...prev, confirm: false }));
    setShow(false);
  };
  const handleOneDriveFilePick = async () => {
    setFiles([]);
    let isIntegrated =
      (owner?.integrationTokens?.microsoft?.access_token &&
        owner?.integrationTokens?.microsoft?.access_token !== "") ||
      false;
    if (!isIntegrated) {
      window.open(
        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${microsoftClientID}&scope=${microsoftScopes}&response_type=code&redirect_uri=${microsoftAuthRedirectUrl}&state=${
          owner?.email
        };${window.location.pathname + window.location.search}`,
        "_self"
      );
      return;
    } else {
      setShowOnedriveModal(true);
    }
  };
  const handleDropBoxFilePick = async (files: any) => {
    setShowDriveDropDown(false);
    setFiles([]);
    setLoaders((prev) => ({ ...prev, drive: true }));

    let uploadingFiles = [];
    let totalSize = 0;
    try {
      for (const fileData of files) {
        const response = await fetch(fileData?.link);
        const blob = await response.blob();
        const file = new File([blob], fileData?.name, {
          type: "application/pdf",
        });
        uploadingFiles.push(file);
        totalSize += file.size;
      }

      if (totalSize > 10 * 1024 * 1024) {
        toast.error("Total size of the files should be less than 10 MB");
        setLoaders((prev) => ({ ...prev, drive: false }));

        return;
      }
      setFiles(uploadingFiles);

      if (uploadingFiles?.length > 1) {
        mergingPdfs(uploadingFiles);
      } else {
        let uploadedFile = uploadingFiles[0];
        setFile(uploadedFile);
        setFileName && setFileName(uploadedFile?.name);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
      toast.error("Something went wrong");
    }
    setLoaders((prev) => ({ ...prev, drive: false }));
  };

  // ! Note : BAD_CODE ----------------------------------------------------
  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      return;
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);
  // ! Note : BAD_CODE_END ------------------------------------------------
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickShareOptions);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickShareOptions);
    };
  }, []);
  const handleOutsideClickShareOptions = (e: any) => {
    if (dropdownShareOptionsRef.current !== null) {
      if (dropdownShareOptionsRef.current?.contains(e.target)) return;
    }
    setShowDriveDropDown(false);
  };

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-screen h-screen absolute top-0 left-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[750px] w-[95%] md:w-[640px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
        id="mini-pdf-custom-scroll"
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />{" "}
          <div className="text-base md:text-lg mb-4 font-semibold">
            Upload File
          </div>
          <div>
            <label
              htmlFor="dropzone-file"
              className={`flex flex-col items-center justify-center p-8 border-2 border-gray-300 hover:border-gray-500 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-full text-gray-600 transition-all ease-in-out duration-150 ${
                file ? "bg-secondary/80 border-primary/80" : ""
              } `}
              onDrop={(e) => {
                // handleDrop(e);
                handleFileInputChange(e, "drop");
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              {!file && (
                <div className="">
                  {!loaders.uploading ? (
                    <div className="gap-8 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                      <div className="p-2 md:p-4 bg-gray-200 rounded-full">
                        <BiSolidUpArrowCircle
                          size={50}
                          className="text-gray-400"
                        />
                      </div>
                      <div className="m-0 flex flex-col items-center text-sm md:text-base">
                        <p>
                          <span className="text-primary mr-1 font-medium">
                            Browse
                          </span>{" "}
                          or drag files here
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      {conversionError ? (
                        <div className="gap-4 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                          <div className="p-2 rounded-full">
                            <MdOutlineError
                              className="text-red-400"
                              size={50}
                            />
                          </div>
                          {uploadingMultipleFiles ? (
                            <div className="m-0 flex justify-between text-center gap-4 md:gap-8 flex-col items-center text-xs md:text-base">
                              <p>
                                Error While Merging your files, Please try this{" "}
                                <a
                                  href="https://www.ilovepdf.com/merge_pdf"
                                  className="text-primary"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  website
                                </a>{" "}
                                for merging files.
                              </p>
                              <button
                                className="px-4 py-1 bg-gray-300 rounded"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setConversionError(false);
                                  setLoaders((prev) => ({
                                    ...prev,
                                    uploading: false,
                                  }));
                                }}
                              >
                                Retry
                              </button>
                            </div>
                          ) : (
                            <div className="m-0 flex justify-between text-center gap-4 md:gap-8 flex-col items-center text-xs md:text-base">
                              <p>
                                Error While Converting your file, Please try
                                this{" "}
                                <a
                                  href="https://www.ilovepdf.com/word_to_pdf"
                                  className="text-primary"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  website
                                </a>{" "}
                                for converting your doc
                              </p>
                              <button
                                className="px-4 py-1 bg-gray-300 rounded"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setConversionError(false);
                                  setLoaders((prev) => ({
                                    ...prev,
                                    uploading: false,
                                  }));
                                }}
                              >
                                Retry
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="gap-8 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                          <div className="p-2 md:p-4  rounded-full">
                            <TailSpin
                              height="60"
                              width="60"
                              color={
                                whiteLabelInfo?.settings?.organization?.themeHex
                                  ?.primary || COLORS.primary
                              }
                            />
                          </div>
                          <div className="m-0 flex flex-col items-center text-sm md:text-base">
                            <p>Uploading your files.</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {file && (
                <div className="flex flex-row md:flex-col items-center justify-center gap-8 py-8">
                  <FaFilePdf size={50} className="text-gray-600" />
                  <div className="flex flex-col items-center justify-center">
                    {/* <TiTick size={30} className="text-primary" /> */}
                    <p className="text-gray-600 text-xs md:text-sm break-all">
                      {file?.name}
                    </p>
                    <p className="text-white text-xs mt-2 font-medium bg-gray-500 px-4 py-1 rounded-md">
                      {Math.floor(file?.size / 1024)} KB
                    </p>
                  </div>
                </div>
              )}
            </label>
            <input
              id="dropzone-file"
              type="file"
              className="appearance-none hidden disabled:opacity-60"
              onClick={(e) => {}}
              onChange={handleFileInputChange}
              accept={acceptedMimeTypes.join(", ")}
              multiple
            />
          </div>
          {files.length > 1 && (
            <MultiplePdfUploaded
              uploadedPdfs={files}
              mergingPdfs={mergingPdfs}
              setUploadedPdfs={setFiles}
            />
          )}
          <div className="flex items-center justify-between mt-4">
            <BoloButton
              variant={"tertiary"}
              size={"sm"}
              className="flex gap-4 items-center"
              onClick={() => {
                setShowDriveDropDown((prev) => !prev);
              }}
            >
              {loaders.drive ? (
                <>
                  <TailSpin
                    height="20"
                    width="20"
                    color={
                      whiteLabelInfo?.settings?.organization?.themeHex
                        ?.primary || COLORS.primary
                    }
                  />
                  <span className="text-primary font-medium">
                    Upload From Drive
                  </span>
                </>
              ) : (
                <>
                  <IoCloudDownloadSharp className="inline-block text-lg sm:text-xl" />
                  <span className="text-primary font-medium">
                    Upload From Drive
                  </span>
                </>
              )}
            </BoloButton>
            <BoloButton
              disabled={!file}
              className=""
              size={"sm"}
              onClick={() => handleConfirm()}
            >
              {loaders.confirm ? (
                <TailSpin
                  height="20"
                  width="20"
                  color={
                    whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
                    COLORS.primary
                  }
                />
              ) : (
                "Confirm"
              )}
            </BoloButton>
            {showDriveDropDown && (
              <div
                ref={dropdownShareOptionsRef}
                className={`absolute bottom-8 mt-2 border border-gray-300 rounded shadow-lg w-[220px] cursor-pointer bg-white`}
                style={{ zIndex: 100 }}
              >
                <div className="flex flex-col items-start justify-start ">
                  <button
                    onClick={handleGoogleDriveFilePick}
                    className={`flex  items-center text-center text-xs capitalize p-3   gap-2 w-full font-medium hover:bg-primary/20`}
                  >
                    <img
                      src="https://img.icons8.com/?size=100&id=VLr4hUR8iMGF&format=png&color=000000"
                      alt="google-drive-logo"
                      className="w-[20px] h-[20px]"
                    />
                    Upload From Google Drive
                  </button>
                  <button
                    onClick={handleOneDriveFilePick}
                    className={`flex  items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium hover:bg-primary/20`}
                  >
                    <img
                      src="https://img.icons8.com/?size=100&id=117559&format=png&color=000000"
                      alt="onedrive-logo"
                      className="w-[20px] h-[20px]"
                    />
                    Upload From OneDrive
                  </button>
                  <div className="w-full hover:bg-primary/20">
                    <DropboxChooser
                      appKey={"qbvzzy8wow4eziq"}
                      success={(files: any) => handleDropBoxFilePick(files)}
                      cancel={() => {
                        setShowDriveDropDown(false);
                        setLoaders((prev) => ({ ...prev, drive: false }));
                      }}
                      multiselect={true}
                      extensions={[".pdf"]}
                      linkType="direct"
                    >
                      <button
                        className={`flex flex-row items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium `}
                      >
                        <GrDropbox className="inline-block text-lg sm:text-xl text-blue-600" />
                        Upload From DropBox
                      </button>
                      <div className="dropbox"> </div>
                    </DropboxChooser>
                  </div>
                </div>
              </div>
            )}
            {showOnedriveModal && (
              <OnedriveModal
                setShowOnedriveModal={setShowOnedriveModal}
                ownerEmail={owner?.email}
                setFileName={setFileName}
                setFile={setFile}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UniversalUploader;
