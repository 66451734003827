import React, { useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import PaymentGatewaysModal from "./PaymentGatewaysModal";

const SetupPaymentGateway = () => {
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const featuresEnabled = useIsFeatureEnabled();

  const isBlocked = (): boolean => {
    if (!featuresEnabled.STRIPE_INTEGRATION) {
      setShowUpgradeModal(true);
    }
    return !featuresEnabled.STRIPE_INTEGRATION;
  };

  return (
    <div className="flex flex-col justify-center items-center text-xs text-gray-600 h-full w-full">
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          blockingType={"STRIPE_INTEGRATION"}
        />
      )}
      <p className="font-semibold text-2xl text-black">No payments yet</p>
      <p className="max-w-[400px] text-center mt-4">
        Once you connect a payment gateway, it will be automatically applied to
        each document.
      </p>
      <div className="mt-6 flex items-center gap-2">
        <PaymentGatewaysModal isBlocked={isBlocked} />
        <BoloButton
          size={"xs"}
          variant="gray"
          className="flex items-center gap-2"
          onClick={() => {
            window.open(
              "https://help.boloforms.com/en/articles/9403494-how-to-connect-stripe-boloforms",
              "_blank"
            );
          }}
        >
          Learn More <FaExternalLinkAlt className="text-gray-500" />
        </BoloButton>
      </div>
    </div>
  );
};

export default SetupPaymentGateway;
