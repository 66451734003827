import React, { useState } from "react";
import { toast } from "react-toastify";
import { BoloButton } from "../../../../components/Common/Button/BoloButton";
import Papa from "papaparse";
import { validateEmail } from "../../../../utils/validateEmail";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/Common/Dialog/BoloDialog";
import axios from "../../../../utils/axios";
import { BASE_URL } from "../../../../constants/constants";
import { useAppSelector } from "../../../../redux/store";
import { TailSpin } from "react-loader-spinner";

const InvalidDataModal = ({
  data,
  show,
  setShow,
}: {
  data: any[];
  show: boolean;
  setShow: (value: boolean) => void;
}) => {
  function downloadCSV(data: any[], filename: string) {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      data.map((row) => row.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setShow(false);
  }

  function parseData(data: any[]): any[] {
    let newData: any[] = [["name", "email", "role", "error"]];
    for (let row of data) {
      // console.log(row);
      let newRow = [];
      newRow.push(row.name);
      newRow.push(row.email);
      newRow.push(row.role);
      newRow.push(row?.message?.join("; "));
      newData.push(newRow);
    }
    return newData;
  }

  function handleClick() {
    let parsedData = parseData(data);
    downloadCSV(
      parsedData,
      "ERRORS : remove the error column before uploading"
    );
  }

  return (
    <Dialog open={show} onOpenChange={setShow}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Invalid Data</DialogTitle>
          <DialogDescription>
            The CSV contains {data.length - 1} invalid records
          </DialogDescription>
        </DialogHeader>
        <div>
          You can download the invalid records and upload them later after
          correcting them
        </div>
        <DialogFooter>
          <BoloButton
            variant={"bolo-secondary"}
            size={"sm"}
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </BoloButton>
          <BoloButton
            variant={"bolo-primary"}
            size={"sm"}
            onClick={() => handleClick()}
          >
            Download Invalid Records
          </BoloButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const BulkUploadUsersContent = () => {
  const whiteLabelPartner = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelPartner
  );
  const expectedFields: string[] = ["name", "email", "role"];
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isCsvValid, setIsCsvValid] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [hasInvalidData, setHasInvalidData] = useState(false);
  const [invalidData, setInvalidData] = useState<any[]>([]);
  const COLOR_MAP: { [key: string]: string } = {
    superadmin: "red",
    admin: "orange",
    editor: "green",
    readonly: "",
  };
  const [addUserLoading, setAddUserLoading] = useState(false);

  const handleTemplateDownload = () => {
    //@ts-ignore
    const blob = new Blob([expectedFields], {
      type: "text/csv",
    });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link and trigger a click to download the file
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `bulk-user-upload-template.csv`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  function checkCSVData(data: any[]): {
    invalidData: any[];
    validData: any[];
    mesage: string[];
  } {
    let invalidData: any[] = [];
    let validData: any[] = [];
    let superAdminCount = 0;
    let roles = ["superadmin", "admin", "editor", "readonly"];
    let gMessage = [];
    let superadmin = [];

    for (let row of data) {
      // console.log({ row });
      let isInvalid = false;
      let message: any = [];

      if (!row?.name) {
        message.push("No Name Provided");
        isInvalid = true;
      }

      if (!validateEmail(row?.email)) {
        message.push("Invalid Email");
        isInvalid = true;
      }

      if (!roles.includes(row?.role)) {
        message.push("Invalid role");
        isInvalid = true;
      }

      if (row?.role === "superadmin") {
        superAdminCount += 1;
      }

      if (isInvalid) {
        let newRow: any = { ...row };
        newRow.message = message;
        invalidData.push(newRow);
      }

      if (!isInvalid) {
        if (row?.role === "superadmin") {
          superadmin = row;
        }
        validData.push(row);
      }
    }

    if (superAdminCount > 1) {
      gMessage.push("More than 1 Super Admin");
    }

    if (superAdminCount < 1) {
      gMessage.push("No Super Admin");
    }

    if (invalidData.length > 0) {
      invalidData.push(superadmin);
    }

    return { invalidData, validData, mesage: gMessage };
  }

  const handleCsvUpload = ({ file }: { file: File }) => {
    // e.target.value = "";
    // // Read the file and parse it
    setCsvFile(file);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const result = event.target.result;
      if (!result) {
        toast.error("Please check csv file.");
        setCsvFile(null);
        setIsCsvValid(false);
        return;
      }
      //   // Parse the CSV data
      Papa.parse(result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (parsedData: any) => {
          // console.log({
          //   parsedData,
          //   1: !parsedData,
          //   2: !parsedData.data,
          //   3: parsedData.data.length === 0,
          //   4: !parsedData.data[0],
          // });
          if (
            !parsedData ||
            !parsedData.data ||
            parsedData.data.length === 0 ||
            !parsedData.data[0]
          ) {
            toast.error("No data or invalid CSV");
            setCsvFile(null);
            setIsCsvValid(false);
            return;
          }

          const userCsvFields = Object.keys(parsedData.data[0]);
          const fieldsExists = expectedFields.every((field) =>
            userCsvFields.includes(field)
          );
          const checkedData = checkCSVData(parsedData.data);

          if (checkedData.mesage.length > 0) {
            return toast.error(checkedData.mesage.join(", "));
          }

          if (checkedData.invalidData.length > 0) {
            setHasInvalidData(true);
            setInvalidData(checkedData.invalidData);
          }

          if (fieldsExists) {
            setCsvData(checkedData.validData);
            setIsCsvValid(true);
          } else {
            toast.error("Please ensure all required fields are present.");
            setCsvFile(null);
            setIsCsvValid(false);
          }
        },
        error: (error: any) => {
          console.error("CSV parsing error:", error.message);
        },
      });
    };
    if (file) {
      reader.readAsText(file);
    } else {
      setCsvFile(null);
      setIsCsvValid(false);
    }
  };

  function handleCancel() {
    setIsCsvValid(false);
    setCsvData([]);
    setCsvFile(null);
  }

  async function handleAddUsers() {
    try {
      setAddUserLoading(true);
      let { data } = await axios.post(
        `${BASE_URL}/whitelable/helper/bulk-user-upload`,
        { users: csvData, forWhiteLabelOwner: whiteLabelPartner?._id }
      );
      // console.log(data);
      if (data.success) {
        handleCancel();
        toast.success("Users Imported");
      } else {
        toast.error(data.message || "Something went wrong");
      }
      setAddUserLoading(false);
    } catch (error) {
      console.log(error);
      setAddUserLoading(false);
      toast.error("Something went wrong");
    }
  }

  return (
    <div className="flex flex-col p-2">
      <InvalidDataModal
        data={invalidData}
        show={hasInvalidData}
        setShow={setHasInvalidData}
      />
      <p className="text-gray-700 mb-3">Add multiple users at once</p>
      <p className="text-sm font-semibold">STEP 1</p>
      <p className="text-gray-700 mb-2">
        Download the csv template and fill the details
      </p>
      <div className="flex gap-4">
        <BoloButton
          variant={"bolo-secondary"}
          size="xs"
          className="w-fit"
          onClick={() => handleTemplateDownload()}
        >
          Download
        </BoloButton>
      </div>
      <p className="text-sm font-semibold mt-4">STEP 2</p>
      <p className="text-gray-700 mb-2">Upload the filled csv file</p>
      <div className="flex gap-4 items-center">
        <div className="flex items-center">
          <label
            htmlFor="csv-upload"
            onClick={() => {}}
            className="px-2 py-1 text-xs rounded cursor-pointer min-w-[120px] bg-secondary-bolo text-primary-bolo font-medium border-primary-bolo border-2 flex items-center justify-center hover:opacity-80"
          >
            Choose File
          </label>
        </div>
        <input
          className="self-start px-4 w-full outline-none text-gray-700 rounded-md border disabled:opacity-50 text-sm hidden"
          id="csv-upload"
          type="file"
          accept=".csv, .xlsx"
          onChange={(e: any) => {
            const file = e.target.files[0];
            handleCsvUpload({ file });
          }}
        />
        <p className="ml-2 text-xs text-gray-700">
          {csvFile ? csvFile?.name : "No file chosen"}
        </p>
      </div>

      {csvData.length > 0 && (
        <div className="flex flex-col mt-6">
          <p className="text-sm font-semibold mb-2">USERS</p>
          <div className="flex border-b px-2 py-1 max-w-[760px] font-semibold">
            <div className="w-[30%] px-2">Name</div>
            <div className="w-[50%] px-2">Email</div>
            <div className="w-[20%] px-2">Role</div>
          </div>
          <div
            className="relative flex flex-col max-h-[200px] overflow-y-auto max-w-[760px]"
            id="mini-pdf-custom-scroll"
          >
            {csvData.map((elm) => {
              return (
                <div
                  className="flex border-b px-2 py-1 items-center"
                  key={elm?.email}
                >
                  <div className="w-[30%] px-2">{elm.name}</div>
                  <div className="w-[50%] px-2">{elm.email}</div>
                  <div
                    className="w-[20%] px-2"
                    style={{ color: COLOR_MAP[elm.role] }}
                  >
                    {elm.role}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex gap-2 mt-4">
            <BoloButton
              size={"xs"}
              variant="bolo-secondary"
              onClick={() => handleCancel()}
            >
              Cancel
            </BoloButton>
            <BoloButton
              size={"xs"}
              disabled={addUserLoading}
              variant="bolo-primary"
              onClick={() => handleAddUsers()}
            >
              {addUserLoading ? (
                <TailSpin color="#ffffff" height={15} width={15} />
              ) : (
                "Add Users"
              )}
            </BoloButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulkUploadUsersContent;
