import { FaCheck } from "react-icons/fa6";
import { FiPlus } from "react-icons/fi";
import { DesignerState } from "..";
import { useEffect, useState } from "react";
import { Integration } from "./IntegrationAppView";
import { GOHIGHLEVEL_ACCESS_EMAILS } from "../../../../../../constants/Integrations/goHighLevel";
import { HUBSPOT_ACCESS_EMAILS } from "../../../../../../constants/Integrations/hubspot";
import { isFeatureEnabled } from "../../../../../../utils/paidFeat";
import PleaseUpgradeModal from "../../../../../../components/PleaseUpgradeModal";
import MixPanel from "../../../../../../utils/services/mixpanel";
import { ALL_FEATURES } from "../../../../../../types/tiers";

interface IntegratedSidebarAppCardProps {
  IntegratedApp: Integration;
  index: number;
  designerState: DesignerState;
}

const IntegratedSidebarAppCard = ({
  IntegratedApp,
  index,
  designerState,
}: IntegratedSidebarAppCardProps) => {
  const [isIntegrated, setIsIntegrated] = useState<boolean>(
    (designerState?.owner?.integrationTokens?.[IntegratedApp.key]
      ?.access_token &&
      designerState?.owner?.integrationTokens?.[IntegratedApp.key]
        ?.access_token !== "") ||
      false
  );

  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeModalType, setUpgradeModalType] =
    useState<ALL_FEATURES>("CRM_INTEGRATIONS");
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });

  useEffect(() => {
    if (
      designerState?.owner?.integrationTokens?.[IntegratedApp.key]?.access_token
    ) {
      setIsIntegrated(
        designerState?.owner?.integrationTokens?.[IntegratedApp.key]
          ?.access_token !== ""
      );
    }
  }, [designerState?.owner, IntegratedApp]);
  // for email blocking
  // if (
  //   IntegratedApp.key === "hubspot" &&
  //   !HUBSPOT_ACCESS_EMAILS.includes(designerState?.owner?.email)
  // ) {
  //   return <></>;
  // }
  // if (
  //   IntegratedApp.key === "gohighlevel" &&
  //   !GOHIGHLEVEL_ACCESS_EMAILS.includes(designerState?.owner?.email)
  // ) {
  //   return <></>;
  // }

  return (
    <div
      className="border-1 border p-2 mb-4 border-gray-300 cursor-pointer"
      key={index}
      onClick={async (e) => {
        e.stopPropagation();
        new MixPanel().track("Integration", {
          integration_action: `integration_${IntegratedApp?.key}_add`,
          paid: true,
        });
        new MixPanel().increment(`integration_${IntegratedApp?.key}_add`);
        let featuresAccess = await isFeatureEnabled("CRM_INTEGRATIONS");

        if (!featuresAccess) {
          setShowUpgradeModal(true);
          setUpgradeModalType("CRM_INTEGRATIONS");
          return;
        }
        window.open(IntegratedApp.link, "_self");
      }}
    >
      <div className="flex justify-between items-center py-2">
        <div className="flex items-center gap-3 px-2 pr-3">
          <img src={IntegratedApp.logo} alt="pipedrive-logo" className="w-6" />
          <p className="text-base font-bold ">{IntegratedApp.name}</p>
        </div>
        {isIntegrated ? (
          <button className="mr-3">
            <FaCheck className="text-primary text-xl" />
          </button>
        ) : (
          <button className="hover:text-primary mr-3">
            <FiPlus className="hover:text-primary text-gray-500 text-xl" />
          </button>
        )}
      </div>
      <div className="text-xs text-gray-500 p-2 mt-2">
        Link your document to a Deal and import data from {IntegratedApp.name}.
      </div>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
    </div>
  );
};

export default IntegratedSidebarAppCard;
