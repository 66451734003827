import axios from "../../utils/axios";
import axiosOG, { AxiosError } from "axios";

async function detectStatusandBody(
  route: string,
  body: FormData,
  headers: Object
): Promise<{ data: any; error?: "403-nb" | "unhandeled"; errorData?: any }> {
  try {
    const resp = await axios.post(route, body, headers);
    // Process the data if the request is successful
    return { data: resp.data };
  } catch (error) {
    if (axiosOG.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 403 && !axiosError.response?.data) {
        console.log("Received 403 status with no body");
        return { data: undefined, error: "403-nb" };
        // Handle the 403 status and no body case here
      } else {
        // Handle other errors
        console.error("An error occurred:", axiosError);
        return { data: undefined, error: "403-nb", errorData: axiosError };
      }
    } else {
      // Handle non-Axios errors
      console.error("An unexpected error occurred:", error);
      return { data: undefined, error: "403-nb", errorData: error };
    }
  }
}

export { detectStatusandBody };
