import jsPDF from "jspdf";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import {
  MdOutlineHistory,
  MdOutlineDelete,
  MdOutlineSimCardDownload,
  MdPeopleAlt,
} from "react-icons/md";
import { TiCancel } from "react-icons/ti";
import { PiCertificate, PiFilePdfLight } from "react-icons/pi";
import { BsSend } from "react-icons/bs";
import { isFeatureEnabled } from "../../utils/paidFeat";
import { LuListTree } from "react-icons/lu";
import { saveAs } from "file-saver";
import { useAppSelector } from "../../redux/store";
import { IoLinkOutline } from "react-icons/io5";
import MixPanel from "../../utils/services/mixpanel";
import { CiBellOn } from "react-icons/ci";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import { useNavigate } from "react-router-dom";
import { HiMiniEye } from "react-icons/hi2";

const DropdownActionsRespondent = ({
  document,
  setActionShowingRespondent,
  actionShowingRespondent,
  setIsModalOpen,
  setRespondentsHistory,
  setRespondentForHistory,
  setRespondentStatus,
  setLoading,
  loading,
  documentDetails,
  setShowUpgradeModal,
  setUpgradeModalType,
  setIsSendACopyModalOpen,
  setDocumentForSendACopy,
  setSigningOrderRecipientList,
  setIsSigningOrderDiagram,
  setCurrOrderDocument,
  setDeletingResponse,
  setShow,
  roleInCollaboration,
  setShowShareViaLink,
  setGeneratedLinksData,
  setShowSigningInPerson,
  setUpgradeModalData,
  setShowGenerateLinksModal,
}: any) => {
  const featuresEnabled = useIsFeatureEnabled();
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const navigate = useNavigate();

  const handleGetHistory = async (finishedRespondents: any) => {
    if (!finishedRespondents) return toast.error("No document to get history");
    // if (respondentHistory.length > 0) {
    //   setIsModalOpen(true);
    //   return toast.success("History fetched successfully");
    // }
    try {
      const { data } = await axios.post(`pdf-respondent/get-history`, {
        signerId: finishedRespondents._id,
      });

      if (!data.error) {
        setIsModalOpen(true);
        setRespondentsHistory(data.history);
        setRespondentForHistory(finishedRespondents);
        setRespondentStatus(finishedRespondents.status);
        return toast.success("History fetched successfully");
      } else {
        setLoading(false);
        return toast.error(data.error);
      }
    } catch (error) {
      console.log(error);
      return toast.error("Failed to get history");
    }
    // Open the modal and set the description
  };
  function addImageToAllPages(doc: any, imageUrl: any, callback: any) {
    const pageCount = doc.internal.getNumberOfPages();

    // Load the image from the URL
    const imgData = imageUrl; // Replace with image data or URL
    const imgWidth = 150; // Adjust image width as needed
    const imgHeight = 150; // Adjust image height as needed

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      // Get page width and height
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;

      // Calculate image coordinates to center it on the page
      const imgX = (pageWidth - imgWidth) / 2;
      const imgY = (pageHeight - imgHeight) / 2;

      doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
    }

    // Execute the callback function
    if (typeof callback === "function") {
      callback(doc);
    }
  }
  return (
    <div
      className={` md:translate-x-0 z-[10] absolute border border-gray-300 rounded-lg shadow-lg w-[195px] cursor-pointer bg-white left-[-166px]  md:left-[-154px] ${
        actionShowingRespondent !== document._id ? "hidden" : ""
      }`}
    >
      <div className="flex flex-col items-start justify-start ">
        {/* disable the preview button to disable the preview system in  */}
        <button
          className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20 disabled:opacity-50`}
          disabled={document.finishedPdfUrl}
          onClick={() => {
            navigate(
              `/create/pdf-template?documentId=${document?.documentId}&templateId=${document?._id}`
            );
          }}
        >
          <HiMiniEye className="text-xl text-gray-600 hover:opacity-85 mr-2 whitespace-nowrap" />{" "}
          Preview{" "}
          <span className="ml-2 bg-[#FAB505] p-1 rounded text-white">Beta</span>
        </button>
        <button
          className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20 disabled:opacity-50`}
          disabled={!document.finishedPdfUrl}
          onClick={async (e) => {
            e.stopPropagation();
            new MixPanel().track("PDF template", {
              pdf_template_action:
                "pdf_template_response_download_with_history",
              paid: false,
            });
            new MixPanel().increment(
              "pdf_template_response_download_with_history"
            );
            if (loading) {
              return;
            }
            try {
              setLoading(true);

              const response = await axios.post(
                `/common/pdf-response-with-history`,
                {
                  finishedPdfUrl: document.finishedPdfUrl,
                  documentId: documentDetails.documentId,
                  templateRespondentId: document._id,
                  timeZone: owner?.accountInfo?.timeZone,
                },
                {
                  responseType: "blob",
                }
              );

              if (response?.data?.error) {
                toast.error(response?.data?.error);
                console.log(
                  "error in getting certificate: ",
                  response?.data?.error
                );
                setLoading(false);
                return;
              } else {
                saveAs(
                  response.data,
                  `${documentDetails.documentName}-${documentDetails.documentId}_with-history.pdf`
                );
                setLoading(false);
              }
            } catch (error) {
              setLoading(false);
              console.log("Error generating certificate: ", error);
              toast.error("Error generating certificate");
            }
          }}
        >
          <MdOutlineSimCardDownload className="text-xl text-gray-600 hover:opacity-85 mr-2 whitespace-nowrap" />
          Download with History
        </button>
        <button
          onClick={() => {
            new MixPanel().track("PDF template", {
              pdf_template_action: "pdf_template_response_show_history",
              paid: false,
            });
            new MixPanel().increment("pdf_template_response_show_history");
            handleGetHistory(document);
          }}
          className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20`}
        >
          <MdOutlineHistory className="text-xl text-gray-600 hover:opacity-85 mr-2" />
          History
        </button>
        <button
          disabled={document.status !== "SIGNED" || loading}
          onClick={async () => {
            new MixPanel().track("PDF template", {
              pdf_template_action: "pdf_template_response_get_certficate",
              paid: false,
            });
            new MixPanel().increment("pdf_template_response_get_certficate");
            try {
              const { data: certificateHTML } = await axios.post(
                `/respondents/get-signature-certificate`,
                {
                  documentId: documentDetails.documentId,
                  signerId: document._id,
                }
              );

              // Check if the response contains HTML
              if (certificateHTML) {
                const doc = new jsPDF();

                // Add the HTML content to the PDF
                doc.html(certificateHTML, {
                  callback: function (finalDoc) {
                    // Call the addImageToAllPages function to add the same image to all pages of the PDF
                    addImageToAllPages(
                      finalDoc,
                      "https://i.imgur.com/i4EiHiU.png",
                      function (docWithImage: any) {
                        // Save the PDF with the image on all pages
                        docWithImage.save(
                          `${documentDetails.documentName}_certificate.pdf`
                        );
                      }
                    );
                  },
                  x: 0,
                  y: 0,
                  width: 190,
                  windowWidth: 950,
                  margin: [10, 10, 10, 10],
                });
              } else {
                return toast.error("Failed to get certificate");
              }
            } catch (error) {
              return toast.error("Failed to get certificate");
            }
          }}
          className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20`}
        >
          <PiCertificate className="text-xl text-gray-600 hover:opacity-85 mr-2" />
          Get Certificate
        </button>
        <button
          disabled={document.status !== "SIGNED" && !document?.finishedPdfUrl}
          onClick={async () => {
            new MixPanel().track("PDF template", {
              pdf_template_action: "pdf_template_response_send_a_copy",
              paid: true,
            });
            new MixPanel().increment("pdf_template_response_send_a_copy");
            const isSendACopyEnabled = await isFeatureEnabled("SEND_A_COPY");
            if (!isSendACopyEnabled) {
              setShowUpgradeModal(true);
              // setUpgradeModalData({
              //   title:
              //     "Send a Copy is available in Freelancer Plan and above ",
              //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
              // });
              setUpgradeModalType("SEND_A_COPY");
              return;
            }

            if (document.status !== "SIGNED") {
              return toast.error("Document is not signed yet");
            }
            if (!document?.finishedPdfUrl) {
              return toast.error(
                "Something is wrong, can not send this document"
              );
            }

            setIsSendACopyModalOpen(true);
            setDocumentForSendACopy({
              documentName: document?.documentName || "Response",
              documentUrl: document?.finishedPdfUrl,
              documentType: "SIGNED_TEMPLATE_PDF",
              documentId: document?.documentId,
              settings: document?.settings,
            });
          }}
          className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20 `}
        >
          <BsSend className="text-xl text-gray-600 hover:opacity-85 mr-2" />
          Send a Copy
        </button>
        {document?.status === "SENT" && (
          <button
            className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-secondary `}
            onClick={async () => {
              new MixPanel().track("PDF template", {
                pdf_template_action: "pdf_template_send_reminder",
                paid: true,
              });
              new MixPanel().increment("pdf_template_send_reminder");
              if (!featuresEnabled.REMINDERS_ACCESS) {
                setShowUpgradeModal(true);
                setUpgradeModalType("REMINDERS_ACCESS");
                return;
              }
              try {
                const response = await axios.post(`/send-reminders`, {
                  documentId: document?.documentId,
                  respondentDocId: document?._id,
                  docType: "PDF_TEMPLATE",
                });
                if (response?.status === 200) {
                  setActionShowingRespondent("");
                  return toast.success("Reminder send successfully");
                } else if (response?.data?.smsCreditExhausted) {
                  return toast.error(response.data.error);
                } else {
                  throw new Error(response.data.error);
                }
              } catch (error: any) {
                console.log(error);
                return toast.error(error.message || "Failed to send reminder");
              }
            }}
          >
            <CiBellOn className="text-xl text-gray-600 hover:opacity-85 mr-2" />
            Send Reminder
            {documentDetails?.sentViaSMS && (
              <span className="text-xs text-gray-400"> (SMS)</span>
            )}
          </button>
        )}

        <button
          disabled={!document?.isSigningOrderData}
          onClick={() => {
            new MixPanel().track("PDF template", {
              pdf_template_action: "pdf_template_response_show_signing_order",
              paid: false,
            });
            new MixPanel().increment(
              "pdf_template_response_show_signing_order"
            );
            setIsSigningOrderDiagram(true);
            setSigningOrderRecipientList(document?.signers || []);
            setCurrOrderDocument(document);
            setActionShowingRespondent("");
          }}
          className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20 `}
        >
          <LuListTree className="text-xl text-gray-600 hover:opacity-85 mr-2" />
          Signing Order
        </button>
        {document?.status === "SENT VIA LINK" && (
          <button
            disabled={document?.isSigningOrderData}
            onClick={() => {
              new MixPanel().track("PDF template", {
                pdf_template_action:
                  "pdf_template_response_show_generated_links",
                paid: false,
              });
              new MixPanel().increment(
                "pdf_template_response_show_generated_links"
              );
              console.log("document", document);
              setShowShareViaLink(true);
              setGeneratedLinksData(document);
            }}
            className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20 `}
          >
            <IoLinkOutline className="text-xl text-gray-600 hover:opacity-85 mr-2" />
            Share Via Link
          </button>
        )}
        {document?.status === "SENT" && (
          <button
            disabled={document?.isSigningOrderData}
            onClick={() => {
              setGeneratedLinksData(document);
              setShowGenerateLinksModal(true);
            }}
            className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20 `}
          >
            <IoLinkOutline className="text-lg hover:opacity-85 mr-2" />
            Share via Link
          </button>
        )}
        {document?.status === "SENT" && (
          <button
            onClick={() => {
              new MixPanel().track("PDF template", {
                pdf_template_action:
                  "pdf_template_response_show_signing_in_person",
                paid: false,
              });
              new MixPanel().increment(
                "pdf_template_response_show_signing_in_person"
              );

              console.log("document", document);
              setShowSigningInPerson(true);
              setGeneratedLinksData(document);
            }}
            className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20 `}
          >
            <MdPeopleAlt className="text-xl text-gray-600 hover:opacity-85 mr-2" />
            Sign In Person
          </button>
        )}
        {document?.status !== "DRAFT" &&
          document?.status !== "SIGNED" &&
          document?.status !== "VOID" && (
            <button
              onClick={(e) => {
                new MixPanel().track("PDF template", {
                  pdf_template_action: "pdf_template_response_void_document",
                  paid: false,
                });
                new MixPanel().increment("pdf_template_response_void_document");
                e.stopPropagation();
                setDeletingResponse(document);
                setShow((prev: any) => {
                  return {
                    ...prev,
                    voidDocumentModal: true,
                  };
                });
              }}
              disabled={roleInCollaboration === "readonly" || loading}
              className={`flex flex-row items-center text-center text-xs capitalize p-3 text-gray-800 w-full font-medium hover:bg-primary/20 `}
            >
              <TiCancel className="text-xl text-gray-600 hover:opacity-85 mr-2" />
              Void Document
            </button>
          )}
        <button
          onClick={(e) => {
            new MixPanel().track("PDF template", {
              pdf_template_action: "pdf_template_response_delete",
              paid: false,
            });
            new MixPanel().increment("pdf_template_response_delete");
            e.stopPropagation();
            setDeletingResponse(document);
            setShow((prev: any) => {
              return {
                ...prev,
                deleteOneResponseModal: true,
              };
            });
          }}
          disabled={roleInCollaboration === "readonly" || loading}
          className={`flex flex-row items-center text-center text-xs capitalize p-3 text-red-400 w-full font-medium hover:bg-red-100`}
        >
          <MdOutlineDelete className="text-xl hover:opacity-85 mr-2" />
          Delete
        </button>
      </div>
    </div>
  );
};

export default DropdownActionsRespondent;
