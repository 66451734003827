import React, { useState, useEffect } from "react";
import { fetchOwner } from "../../utils";
import axios from "../../utils/axios";
import { BsCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

import { WEBSITE_ASSETS_URL } from "../../constants/constants";

import FeatureInfoPopup from "./FeatureImagePopup";

const ComparisonTable = ({ scrollToTarget }) => {
  const [isIndianPrice, setIsIndianPrice] = useState(false);
  const [isAnnualBilling, setIsAnnualBilling] = useState(true);
  const [planInMobile, setPlanInMobile] = useState("Team");
  const [imagePopup, setImagePopup] = useState(null);

  // async function getCountry() {
  //   let { data } = await axios("https://ipinfo.io/json?token=cbe652e779e0c6");
  //   // console.log(data);
  //   let country = data?.country;
  //   setIsIndianPrice(country === "IN");

  //   return country;
  // }
  useEffect(() => {
    fetchOwner();

    // (async () => {
    //   let country = localStorage.getItem("country");
    //   if (
    //     !country ||
    //     country === "" ||
    //     country === "null" ||
    //     country === "undefined"
    //   ) {
    //     country = await getCountry();
    //     console.log(country);
    //     localStorage.setItem("country", country);
    //   }
    //   setIsIndianPrice(country === "IN");
    // })();
  }, []);

  let features = [
    {
      name: "Freelancer",
      button: true,
      price: `${
        isIndianPrice
          ? isAnnualBilling
            ? `₹${8200}`
            : `₹${800}`
          : isAnnualBilling
          ? `$${10}` // it was 100, but as we are showing the price in /mo anyway so its 100/12 = 8.33
          : `$${15}`
      }`,
      planNumber: 0,
    },
    {
      name: "Team",
      button: true,
      price: `${
        isIndianPrice
          ? isAnnualBilling
            ? `₹${16999}`
            : `₹${1800}`
          : isAnnualBilling
          ? `$${49}` // it was 490, but as we are showing the price in /mo anyway so its 490/12 = 40.83
          : `$${69}`
      }`,
      planNumber: 1,
    },
    {
      name: "Growing Business",
      button: true,
      price: `${
        isIndianPrice
          ? isAnnualBilling
            ? `₹${24999}`
            : `₹${2499}`
          : isAnnualBilling
          ? `$${99}` // it was 990, but as we are showing the price in /mo anyway so its 990/12 = 82.5
          : `$${129}`
      }`,
      planNumber: 2,
    },
    // {
    //   name: "Enterprise",
    //   button: true,
    //   price: "Get a Quote",
    //   planNumber: 3,
    // },
  ];
  let selectedPlanInMobile = features.find(
    (feature) => feature.name === planInMobile
  );

  let usage = [
    {
      name: "Number Of WorkSpaces",
      // planCompare: [`1 Default`, `1 Default`, `Each @ 29$`, "Each @ 29$"],
      planCompare: [
        `1 Default`,
        `1 Default`,
        `Unlimited @ 29$ /month /workspace`,
      ],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/team-workspaces.png`,
    },
    {
      name: "Allowed Form Responses(Signatures)",
      // planCompare: [`100 /month`, `500 /month`, `1000 /month`, "Unlimited"],
      planCompare: [`100 /month`, `500 /month`, `Unlimited`],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/forms.png`,
    },
    {
      name: "Number of Documents",
      // planCompare: [`5 /month`, `Unlimited`, `Unlimited`, `Unlimited`],
      planCompare: [`5 /month`, `Unlimited`, `Unlimited`],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf.png`,
    },
    {
      name: "Number of Templates",
      // planCompare: [`5`, `Unlimited`, `Unlimited`, "Unlimited"],
      planCompare: [`5`, `Unlimited`, `Unlimited`],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf-template.png`,
    },
    {
      name: "Number of Templates Responses",
      // planCompare: [`5`, `Unlimited`, `Unlimited`, "Unlimited"],
      planCompare: [`5`, `Unlimited`, `Unlimited`],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pdf-template.png`,
    },
    {
      name: "Number of PDFs from Form Submissions",
      // planCompare: [`5`, `Unlimited`, `Unlimited`, "Unlimited"],
      planCompare: [`5`, `Unlimited`, `Unlimited`],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/generate-response-pdf.png`,
    },
  ];

  let userInterfaceFunctionality = [
    {
      name: "Audit Log and History",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/audit-trail.png`,
    },
    {
      name: "Signing Order",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/signing-order.png`,
    },
    {
      name: "Form fields: drop-down, radio, approve/decline",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/doc-editor.png`,
    },
    {
      name: "Notifications and Reminders",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/real-time-notifications.png`,
    },
    {
      name: "Form Branding",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/form-branding.png`,
    },
    {
      name: "Custom PDF Name",
      // planCompare: [true, true, true, true],
      planCompare: [false, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/custom-pdf-name.png`,
    },
    {
      name: "Send Generated PDFs",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/individual-file-sharing.png`,
    },
    {
      name: "Structured Folder System",
      // planCompare: [false, false, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/create-files-folders.png`,
    },
    {
      name: "Form Analytics",
      // planCompare: [true, true, true, true],
      planCompare: [false, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/form-analytics.png`,
    },
    {
      name: "Custom WaterMark on Generated PDFs",
      // planCompare: [true, true, true, true],
      planCompare: [false, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/watermark.png`,
    },
    {
      name: "Bulk Send",
      // planCompare: [true, true, true, true],
      planCompare: [false, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/bulk-send.png`,
    },
    {
      name: "Bulk Generated PDF Dowload",
      // planCompare: [true, true, true, true],
      planCompare: [false, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/bulk-download.png`,
    },
    {
      name: "Admin Dashboard for User Management",
      // planCompare: [false, true, true],
      planCompare: [false, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/custom-user-roles.png`,
    },
    {
      name: "Share Templates with Team Members",
      // planCompare: [false, true, true, true],
      planCompare: [false, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/team-workspaces.png`,
    },
    {
      name: "Signing via Shareable Link and QR Code",
      // planCompare: [false, false, true, true],
      planCompare: [false, false, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/generate-signing-link.png`,
    },
    {
      name: "Redirection URL in Forms",
      // planCompare: [false, false, true, true],
      planCompare: [false, false, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/custom-redirection-url.png`,
    },
    {
      name: "WorkSpaces",
      // planCompare: [false, false, true, true],
      planCompare: [false, false, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/team-workspaces.png`,
    },
    {
      name: "Custom Email Branding",
      // planCompare: [false, false, true, true],
      planCompare: [false, false, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/email-subject-body.png`,
    },
    {
      name: "Custom Development using our API",
      // planCompare: [false, false, false, true],
      planCompare: ["Contact Us", "Contact Us", "Contact Us"],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/api-integration.png`,
    },
  ];

  let integrations = [
    {
      name: "Google Sheets Integration",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/google-sheet.png`,
    },
    {
      name: "Google Contacts Integration",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/import-contacts.png`,
    },
    {
      name: "Google Drive Integration",
      // planCompare: [false, false, true, true],
      planCompare: [false, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/google-drive.png`,
    },
    {
      name: "Pipedrive Integration",
      // planCompare: [false, false, true, true],
      planCompare: [false, false, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pipedrive.png`,
    },
    {
      name: "Zapier Integration",
      // planCompare: [false, false, true, true],
      planCompare: [false, false, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/zapier.png`,
    },
    {
      name: "Pabbly Integration",
      // planCompare: [false, false, true, true],
      planCompare: [false, false, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/pabbly.png`,
    },
  ];

  let complianceSecurity = [
    {
      name: "HIPAA Compliance",
      // planCompare: [false, false, false, true],
      planCompare: ["$49/mo", "$49/mo", "$49/mo"],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/hipaa-compliance.png`,
    },
  ];

  // let collaborationCommunication = [
  //   {
  //     name: "Slack Group",
  //     // planCompare: [false, false, false, true],
  //     planCompare: [false, false, false],
  //   },
  // ];

  let supportCustomerSuccess = [
    {
      name: "Support Reply within 2 Days",
      // planCompare: [true, true, true, true],
      planCompare: [true, true, true],
      image: `${WEBSITE_ASSETS_URL}/v2/ltd/features/chat-support.png`,
    },
    // {
    //   name: "Dedicated Customer Success Manager",
    //   // planCompare: [false, false, false, true],
    //   planCompare: [false, false, false],
    // },
  ];

  // let contractLegalManagement = [
  //   {
  //     name: "Contract and Legal Review",
  //     // planCompare: [false, false, false, true],
  //     planCompare: [false, false, false],
  //   },
  // ];
  const handleChange = (event) => {
    setPlanInMobile(event.target.value);
  };
  return (
    <div className="flex flex-col gap-y-5 w-full">
      <div className="text-xs mt-3 sm:text-xs flex items-center justify-end">
        <span className="font-medium mr-3">Monthly billing</span>
        <label
          className={`switch-button m-0 ${
            isAnnualBilling && "switch-button-checked"
          }`}
        >
          <input
            type="checkbox"
            id="isApprovalWorkflow"
            name="isApprovalWorkflow"
            checked={isAnnualBilling}
            onChange={() => setIsAnnualBilling((prev) => !prev)}
          />
          <div></div>
        </label>
        <span className="font-medium ml-3">Annual billing</span>
        <div className=" flex items-center justify-center ml-2 rounded-3xl bg-green-600 text-white px-2 text-[10px] font-medium">
          Upto 40% Off
        </div>
      </div>
      <div className="hidden xl:block comparison-table w-[100%] mb-10">
        <table className="divide-y divide-gray-200 dark:divide-gray-700 w-full max-w-full overflow-x-auto">
          <thead className="border border-l-[0px] border-r-[0px] bg-white z-10 sticky -top-4 ">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-gray-700 font-semibold text-md w-[40%]"
              >
                Features
              </th>
              {features.map((feature, index) => (
                <th
                  key={index}
                  scope="col"
                  className={`px-2 py-3 text-center text-gray-700 font-semibold text-md w-[20%] ${
                    index === 1 || index === 3 ? "bg-gray-100" : ""
                  } ${index == 3 && "hidden"}`}
                >
                  <div className="text-lg">{feature.name}</div>
                  <div className="text-lg font-bold ">
                    <span>{feature.price}</span>
                    {/* {(feature.name === "Team" ||
                      feature.name === "Growing Business") && (
                      // <span className="text-xs">/workspace</span>
                      <span className="text-xs">
                        {" "}
                        {isAnnualBilling ? "/yr" : "/mo"}
                      </span>
                    )}
                    {feature.name === "Freelancer" && (
                      <span className="text-xs">
                        {isAnnualBilling ? "/yr" : "/mo"}
                      </span>
                    )} */}
                    <span className="text-xs">/mo</span>
                  </div>
                  <div>
                    {isAnnualBilling && (
                      <p className=" text-gray-400 text-xs font-light">
                        Billed Annually
                      </p>
                    )}

                    {feature.button ? (
                      <button
                        onClick={scrollToTarget}
                        className="mt-2 font-regular align-bottom text-xs bg-primary hover:bg-primary/80 text-white py-2 rounded-md px-2"
                      >
                        {feature.name === "Enterprise"
                          ? "Contact Us"
                          : "Subscribe"}
                      </button>
                    ) : (
                      <button className="invisible mt-2 font-regular text-xs bg-primary hover:bg-primary/80 text-white py-2 rounded-md px-2">
                        Get {feature.name}
                      </button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            <tr>
              <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                Usage
              </td>
            </tr>
            {usage.map((usage, index) => (
              <tr key={index}>
                <td className="px-2 py-2 text-sm font-medium text-gray-800">
                  <p
                    className="underline underline-offset-2 decoration-dotted cursor-pointer"
                    onClick={() => setImagePopup(usage?.image)}
                  >
                    {usage.name}
                  </p>
                </td>
                {usage.planCompare.map((plan, i) => (
                  <td
                    key={i}
                    className={`px-2 py-2 font-medium text-center text-xs text-gray-800  ${
                      i === 1 || i === 3 ? "bg-gray-100" : ""
                    }`}
                  >
                    {plan}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                Functionality
              </td>
            </tr>
            {userInterfaceFunctionality.map((functionality, index) => (
              <tr key={index}>
                <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                  <p
                    className="underline underline-offset-2 decoration-dotted cursor-pointer"
                    onClick={() => setImagePopup(functionality?.image)}
                  >
                    {functionality.name}
                  </p>
                </td>
                {functionality.planCompare.map((plan, i) => (
                  <td
                    key={i}
                    className={`px-2 py-2 text-center text-gray-800 ${
                      i === 1 || i === 3 ? "bg-gray-100" : ""
                    }`}
                  >
                    {typeof plan == "string" ? (
                      <span className="text-sm">{plan}</span>
                    ) : plan === true ? (
                      <span className="text-green-600">
                        <BsCheckCircleFill className="mx-auto" />
                      </span>
                    ) : (
                      <span className="text-red-600">
                        <BsFillXCircleFill className="mx-auto" />
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                Integrations
              </td>
            </tr>
            {integrations.map((integration, index) => (
              <tr key={index}>
                <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                  <p
                    className="underline underline-offset-2 decoration-dotted cursor-pointer"
                    onClick={() => setImagePopup(integration?.image)}
                  >
                    {integration.name}
                  </p>
                </td>
                {integration.planCompare.map((plan, i) => (
                  <td
                    key={i}
                    className={`px-2 py-2 text-center text-gray-800  ${
                      i === 1 || i === 3 ? "bg-gray-100" : ""
                    }`}
                  >
                    {plan === true ? (
                      <span className="text-green-600">
                        <BsCheckCircleFill className="mx-auto" />
                      </span>
                    ) : (
                      <span className="text-red-600">
                        <BsFillXCircleFill className="mx-auto" />
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                Security
              </td>
            </tr>
            {complianceSecurity.map((securityItem, index) => (
              <tr key={index}>
                <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                  <p
                    className="underline underline-offset-2 decoration-dotted cursor-pointer"
                    onClick={() => setImagePopup(securityItem?.image)}
                  >
                    {securityItem.name}
                  </p>
                </td>
                {securityItem.planCompare.map((plan, i) => (
                  <td
                    key={i}
                    className={`px-2 py-2 text-center text-gray-800  ${
                      i === 1 || i === 3 ? "bg-gray-100" : ""
                    }`}
                  >
                    <span className="text-sm">{plan}</span>
                  </td>
                ))}
              </tr>
            ))}
            {/* <tr>
              <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                Collaborations
              </td>
            </tr>
            {collaborationCommunication.map((collaboration, index) => (
              <tr key={index}>
                <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                  {collaboration.name}
                </td>
                {collaboration.planCompare.map((plan, i) => (
                  <td
                    key={i}
                    className={`px-2 py-2 text-center text-gray-800  ${
                      i === 1 || i === 3 ? "bg-gray-100" : ""
                    }`}
                  >
                    {plan === true ? (
                      <span className="text-green-700">✔</span>
                    ) : (
                      <span className="text-red-700">✖</span>
                    )}
                  </td>
                ))}
              </tr>
            ))} */}
            <tr>
              <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                Customer Support
              </td>
            </tr>
            {supportCustomerSuccess.map((support, index) => (
              <tr key={index}>
                <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                  <p
                    className="underline underline-offset-2 decoration-dotted cursor-pointer"
                    onClick={() => setImagePopup(support?.image)}
                  >
                    {support.name}
                  </p>
                </td>
                {support.planCompare.map((plan, i) => (
                  <td
                    key={i}
                    className={`px-2 py-2 text-center text-gray-800  ${
                      i === 1 || i === 3 ? "bg-gray-100" : ""
                    }`}
                  >
                    {plan === true ? (
                      <span className="text-green-600">
                        <BsCheckCircleFill className="mx-auto" />
                      </span>
                    ) : (
                      <span className="text-red-600">
                        <BsFillXCircleFill className="mx-auto" />
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
            {/* <tr>
              <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                Legal Support
              </td>
            </tr>
            {contractLegalManagement.map((legal, index) => (
              <tr key={index}>
                <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                  {legal.name}
                </td>
                {legal.planCompare.map((plan, i) => (
                  <td
                    key={i}
                    className={`px-2 py-2 text-center text-gray-800  ${
                      i === 1 || i === 3 ? "bg-gray-100" : ""
                    }`}
                  >
                    {plan === true ? (
                      <span className="text-green-700">✔</span>
                    ) : (
                      <span className="text-red-700">✖</span>
                    )}
                  </td>
                ))}
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>

      <div className="block xl:hidden comparison-table w-[100%] xl:w-[80%] max-w-full">
        <div className="flex justify-between items-center w-full border border-x-0 py-4 sticky bg-white -top-4">
          <div className="flex flex-col">
            <div className="font-medium text-lg">
              {selectedPlanInMobile.name}
            </div>
            <div className="text-sm text-gray-500 font-regular">
              <span className="text-sm text-gray-500 font-regular">
                {selectedPlanInMobile.price}
              </span>
              {/* {selectedPlanInMobile.name === "Team" ||
              selectedPlanInMobile.name === "Growing Business" ? (
                <span className="text-xs">
                  {/* /workspace /{isAnnualBilling ? "yr" : "mo"} */}
              {/* {isAnnualBilling ? "/yr" : "/mo"} */}
              {/* </span> */}
              {/* ) : <></>} */}
              <span className="text-xs">/mo</span>
            </div>
            <div className="text-sm text-gray-500 font-thin">
              {selectedPlanInMobile.details}
            </div>
          </div>
          <div className="w-fit">
            <select
              id="plan"
              className="block w-full hover:bg-gray-100 py-1 px-2 bg-white rounded-md sm:text-sm"
              value={planInMobile}
              onChange={handleChange}
            >
              {/* <option value="Freelancer">Freelancer</option>
              <option value="Team">Team</option>
              <option value="Growing Business">Growing Business</option>
              <option value="Enterprise">Enterprise</option> */}
              {features.map((feature, index) => {
                return (
                  <option key={index} value={feature.name}>
                    {feature.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {/* [
    {
      name: "Allowed quota",
      planCompare: [
        `120 minutes/month`,
        `1800 minutes/month`,
        `2000 minutes/month`,
        "Custom",
      ],
    },
    {
      name: "Number of user",
      planCompare: [`1`, `5`, `10`, "Custom"],
    },
  ]; */}
        <div className="flex flex-col w-full max-w-full overflow-x-auto">
          <table className=" divide-y divide-gray-200 dark:divide-gray-700">
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              <tr>
                <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                  Usage
                </td>
              </tr>
              {usage.map((usage, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 text-sm font-medium text-gray-800">
                    <p
                      className="underline underline-offset-2 decoration-dotted cursor-pointer"
                      onClick={() => setImagePopup(usage?.image)}
                    >
                      {usage.name}
                    </p>
                  </td>
                  <td
                    className={`px-2 py-2 font-medium text-right text-xs text-gray-800 `}
                  >
                    {usage.planCompare[selectedPlanInMobile.planNumber]}
                  </td>
                </tr>
              ))}

              <tr>
                <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                  Functionality
                </td>
              </tr>
              {userInterfaceFunctionality.map((functionality, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                    <p
                      className="underline underline-offset-2 decoration-dotted cursor-pointer"
                      onClick={() => setImagePopup(functionality?.image)}
                    >
                      {functionality.name}
                    </p>
                  </td>
                  <td className={`px-2 py-2 text-right text-xs text-gray-800 `}>
                    {functionality.planCompare[
                      selectedPlanInMobile.planNumber
                    ] === true ? (
                      <span className="text-green-600 text-lg">
                        <BsCheckCircleFill className="mx-auto" />
                      </span>
                    ) : (
                      <span className="text-red-600 text-lg">
                        <BsFillXCircleFill className="mx-auto" />
                      </span>
                    )}
                  </td>
                </tr>
              ))}

              <tr>
                <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                  Integrations
                </td>
              </tr>
              {integrations.map((integration, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                    <p
                      className="underline underline-offset-2 decoration-dotted cursor-pointer"
                      onClick={() => setImagePopup(integration?.image)}
                    >
                      {integration.name}
                    </p>
                  </td>
                  <td className={`px-2 py-2 text-right text-xs text-gray-800 `}>
                    {integration.planCompare[
                      selectedPlanInMobile.planNumber
                    ] === true ? (
                      <span className="text-green-600 text-lg">
                        <BsCheckCircleFill className="mx-auto" />
                      </span>
                    ) : (
                      <span className="text-red-600 text-lg">
                        <BsFillXCircleFill className="mx-auto" />
                      </span>
                    )}
                  </td>
                </tr>
              ))}

              <tr>
                <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                  Security
                </td>
              </tr>
              {complianceSecurity.map((security, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                    <p
                      className="underline underline-offset-2 decoration-dotted cursor-pointer"
                      onClick={() => setImagePopup(security?.image)}
                    >
                      {security.name}
                    </p>
                  </td>
                  <td className={`px-2 py-2 text-right text-xs text-gray-800 `}>
                    {security.planCompare[selectedPlanInMobile.planNumber] ===
                    true ? (
                      <span className="text-green-600 text-lg">
                        <BsCheckCircleFill className="mx-auto" />
                      </span>
                    ) : (
                      <span className="text-red-600 text-lg">
                        <BsFillXCircleFill className="mx-auto" />
                      </span>
                    )}
                  </td>
                </tr>
              ))}

              {/* <tr>
                <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                  Collaborations
                </td>
              </tr>
              {collaborationCommunication.map((collaboration, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                    {collaboration.name}
                  </td>
                  <td className={`px-2 py-2 text-right text-xs text-gray-800 `}>
                    {collaboration.planCompare[
                      selectedPlanInMobile.planNumber
                    ] === true ? (
                      <span className="text-green-700 text-lg">✔</span>
                    ) : (
                      <span className="text-red-700 text-lg">✖</span>
                    )}
                  </td>
                </tr>
              ))} */}

              <tr>
                <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                  Customer Support
                </td>
              </tr>
              {supportCustomerSuccess.map((support, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                    <p
                      className="underline underline-offset-2 decoration-dotted cursor-pointer"
                      onClick={() => setImagePopup(support?.image)}
                    >
                      {support.name}
                    </p>
                  </td>
                  <td className={`px-2 py-2 text-right text-xs text-gray-800 `}>
                    {support.planCompare[selectedPlanInMobile.planNumber] ===
                    true ? (
                      <span className="text-green-600 text-lg">
                        <BsCheckCircleFill className="mx-auto" />
                      </span>
                    ) : (
                      <span className="text-red-600 text-lg">
                        <BsFillXCircleFill className="mx-auto" />
                      </span>
                    )}
                  </td>
                </tr>
              ))}

              {/* <tr>
                <td className="px-2 pt-8 pb-2 text-base font-bold text-gray-800  mt-4">
                  Legal Support
                </td>
              </tr>
              {contractLegalManagement.map((legal, index) => (
                <tr key={index}>
                  <td className="px-2 py-2 text-sm font-medium text-gray-800 ">
                    {legal.name}
                  </td>
                  <td className={`px-2 py-2 text-right text-xs text-gray-800 `}>
                    {legal.planCompare[selectedPlanInMobile.planNumber] ===
                    true ? (
                      <span className="text-green-700 text-lg">✔</span>
                    ) : (
                      <span className="text-red-700 text-lg">✖</span>
                    )}
                  </td>
                </tr>
              ))} */}

              {/* <tr>
                  <td className=" px-2 py-4   text-sm font-medium text-gray-800 ">
                    John Brown
                  </td>
                  <td className=" px-2 py-4   text-sm text-gray-800 ">
                    45
                  </td>
                  <td className=" px-2 py-4   text-sm text-gray-800 ">
                    New York No. 1 Lake Park
                  </td>
                  <td className=" px-2 py-4   text-right text-sm font-medium">
                    <a className="text-blue-500 hover:text-blue-700" href="#">
                      Delete
                    </a>
                  </td>
                </tr> */}
            </tbody>
          </table>
        </div>
      </div>

      {imagePopup && (
        <FeatureInfoPopup
          image={imagePopup}
          close={() => setImagePopup(null)}
        />
      )}
    </div>
  );
};

export default ComparisonTable;
