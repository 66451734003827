import { BASE_URL } from "../constants/constants";
import { isFeatureEnabledHookHelper } from "../hooks/reduxHooks/useIsFeatureEnabled";
import { ALL_FEATURES } from "../types/tiers";
import axios from "./axios";

export function isPaidPlan(owner: any) {
  let activePlan = owner.planhistory[owner.planhistory.length - 1];

  let isValidPlanToUseThisFeat = false;
  if (activePlan.currencyCode === "₹") {
    parseInt(activePlan.planPrice) >= 9000 && (isValidPlanToUseThisFeat = true);
  } else {
    parseInt(activePlan.planPrice) >= 99 && (isValidPlanToUseThisFeat = true);
  }

  //TODO: add negative case in isValidPlanToUseThisFeat
  if (!activePlan.isActive || !isValidPlanToUseThisFeat) return true;

  return false;
}

export async function isEntryPaidPlan(owner: any, feature: any) {
  let paymentStatus = owner?.paymentStatus;

  // if (!paymentStatus) {
  //   let { data } = await axios.get(
  //     `/owners/payment-status?ownerId=${owner._id}`
  //   );
  //   paymentStatus = data.paymentStatus;
  // }
  // // console.log("paymentStatus", paymentStatus);

  // let { enabledFeatures, isOnFreeTrial, isOnPaidTrial, isPayingCustomer } =
  //   paymentStatus;

  // let isPaidPlan =
  //   (isOnFreeTrial || isOnPaidTrial || isPayingCustomer) &&
  //   enabledFeatures?.includes(feature);

  // return isPaidPlan;
  return true;
}

export async function isFeatureEnabled(feature: ALL_FEATURES) {
  let owner = JSON.parse(localStorage.getItem("owner") || "");
  let paymentStatus = owner?.paymentStatus;

  if (!paymentStatus && owner?._id) {
    let { data } = await axios.get(
      `/owners/payment-status?ownerId=${owner?._id}`
    );

    paymentStatus = data.paymentStatus;
    // set payment status here
    owner.paymentStatus = paymentStatus;
    localStorage.setItem("owner", JSON.stringify(owner));
  }

  const data = isFeatureEnabledHookHelper(feature, paymentStatus);
  return data;

  // let {
  //   enabledFeatures,
  //   isOnFreeTrial,
  //   isOnPaidTrial,
  //   isPayingCustomer,
  //   planEndDate,
  // } = paymentStatus;

  // // const today = new Date().getTime();
  // // const endDate = Number(planEndDate);
  // // const isPlanActive = today < endDate;
  // // endDate: new Date(endDate)

  // // console.log({
  // //   paymentStatus: { ...paymentStatus },
  // // });

  // let isPaidPlan =
  //   (isOnFreeTrial || isOnPaidTrial || isPayingCustomer) &&
  //   // isPlanActive &&
  //   enabledFeatures?.includes(feature);

  // return isPaidPlan;
}

export const getOwnerFromDetails = () => {
  const owner = JSON.parse(localStorage.getItem("owner") || "");
  let ownerFrom = owner?.from;
  return ownerFrom;
};
