import { useEffect, useState } from "react";

import axios from "../../utils/axios";
// import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import SignatureMapper from "../../components/SignatureMapper";
import { fetchOwner } from "../../utils";
import { MdClose, MdOutlineKeyboardBackspace } from "react-icons/md";
import MobileDeviceNotSupported from "../../components/MobileDeviceNotSupported";
import { AiFillEye, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import SignatureResponseMapper from "../../components/SignatureResponseMapper";
import { IoArrowBackCircle } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import BoloLoader from "../../components/Common/Loader/BoloLoader";

type Props = {};

const MapSignature = (props: Props) => {
  const navigate = useNavigate();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [owner, setOwner] = useState<any>({});
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [receiversList, setReceiversList] = useState<any>([]);
  const [schemas, setSchemas] = useState<any>([]);
  const [pdfPages, setPdfPages] = useState<number>(1);
  const [templateAvailable, setTemplateAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [isPreview, setIsPreview] = useState(false);
  const [showPreviewReceivers, setShowPreviewReceivers] = useState(false);
  const [previewReceivers, setPreviewReceivers] = useState<any>();
  const [isSaved, setIsSaved] = useState(false);
  const [startSave, setStartSave] = useState(false);
  const [fetchData, setFetchData] = useState<any>({});
  const [reload, setReload] = useState<boolean>(false);

  let documentId = searchParams.get("documentId");
  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
    };
    updatedOwner();
  }, []);

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  useEffect(() => {
    let getDocumentDetails = async () => {
      try {
        setLoading(true);
        toast.loading("Fetching form..");
        const { data } = await axios.get(
          `/getOneDocument?documentId=${documentId}`
        );
        setFetchData(data);
        if (data.error) {
          toast.error(data.message);
          setLoading(false);
          return;
        }
        if (!data.error) {
          // if (data.document.status === "SENT") {
          //   navigate("/dashboard");
          //   return;
          // }
          toast.success(data.message);
        }
        let fileName = data.document.documentName;
        setFileName(fileName);

        let pdfUrl = data.document.documentUrl;
        const responseFile = await fetch(pdfUrl);
        const pdfBuffer = await responseFile.arrayBuffer();

        // Convert PDF buffer to a base64 string
        let pdfBase64 = arrayBufferToBase64(pdfBuffer);
        pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
        setPdfFile(pdfBase64);
        setReceiversList(data.document.respondants);
        setPreviewReceivers(data?.document?.respondants?.[0]);
        setSchemas(data.document?.pdfTemplate?.schemas);
        setLoading(false);
      } catch (errror) {
        setLoading(false);
        console.log(errror);
        toast.error("Error fetching form");
      }
    };
    getDocumentDetails();
  }, [documentId, isPreview]);

  useEffect(() => {
    let getDocumentDetails = async () => {
      const { data } = await axios.get(
        `/getOneDocument?documentId=${documentId}`
      );
      if (data?.document?.pdfTemplate?.schemas) {
        await setSchemas(data?.document?.pdfTemplate?.schemas);
      }
    };
    getDocumentDetails();
  }, [isSaved, isPreview]);

  if (loading) {
    return (
      <div className="h-screen -mt-[70px] flex justify-center items-center">
        <BoloLoader />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title translate="no">Create Pdf Signature</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Create Pdf Signature" />
        <meta
          name="description"
          content="Effortlessly generate PDFs and simplify the signing process for signers using our intuitive drag-and-drop functionality, ensuring easy and efficient document completion."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/create/pdf-signature`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Create Pdf Signature- Boloforms`} />
        <meta
          property="og:description"
          content="Effortlessly generate PDFs and simplify the signing process for signers using our intuitive drag-and-drop functionality, ensuring easy and efficient document completion."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/create/pdf-signature"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <>
        <MobileDeviceNotSupported />
        <div className="p-display">
          {pdfFile && receiversList.length > 0 && (
            <div className="w-full h-full">
              {isPreview && (
                // <div className="fixed top-1 left-50% translate-x-[50%] z-[100] group h-[6px] bg-black/80 animate-pulse-full hover:animate-none hover:bg-transparent rounded-full w-[50vw] flex justify-center">
                <div className="fixed top-1 cursor-pointer opacity-50 hover:opacity-100 left-50% translate-x-[50%] rounded-full z-[110] w-[50vw] transition-all duration-300 animate-none h-8 overflow-visible flex justify-between items-center px-4 text-xs bg-black text-white">
                  <span> This is a Preview </span>
                  <div className="flex gap-8 items-center">
                    <span className="text-xs flex gap-2 items-center">
                      Viewing as :
                      <div
                        id="custom-receiver-selector"
                        onClick={() => setShowPreviewReceivers((prev) => !prev)}
                        className="cursor-pointer relative w-[200px] flex gap-2 justify-between items-center h-6 border border-white text-xs px-3 py-[2px] rounded-full"
                      >
                        <div className="flex gap-2 items-center">
                          <div
                            className="h-3 w-3 rounded-full"
                            style={{ background: previewReceivers?.colour }}
                          ></div>
                          <div className="text-white truncate w-[130px]">
                            {previewReceivers?.name}
                          </div>
                        </div>
                        {showPreviewReceivers ? (
                          <AiOutlineUp className="text-white" size={12} />
                        ) : (
                          <AiOutlineDown size={12} className="text-white " />
                        )}
                        {showPreviewReceivers && (
                          <div className="absolute w-full top-[26px] left-0 z-10">
                            {receiversList?.map((receiver: any) => {
                              return (
                                <div
                                  onClick={() => {
                                    setPreviewReceivers(
                                      receiversList?.find(
                                        (receiverLocal: any) =>
                                          receiverLocal?.name == receiver?.name
                                      )
                                    );
                                  }}
                                  className="flex gap-2 items-center cursor-pointer w-full bg-white text-black px-3 py-1 border-b"
                                >
                                  <div
                                    className="h-3 w-3 rounded-full"
                                    style={{ background: receiver?.colour }}
                                  ></div>
                                  <div className="truncate w-[130px]">
                                    {receiver?.name}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </span>
                    <button onClick={() => setIsPreview(false)}>
                      <MdClose className="text-white" size={20} />
                    </button>
                  </div>
                </div>
                // </div>
              )}
              {isPreview ? (
                <SignatureResponseMapper
                  file={pdfFile}
                  isPreview={true}
                  previewStyle={"DEFAULT"}
                  navHeight={32}
                  numberOfPages={pdfPages}
                  signerDetails={{
                    ...previewReceivers,
                  }}
                  schemas={schemas || [{}]}
                  loading={loading}
                  setShownModal={() => {}}
                  fetchedData={{
                    accountInfo: {},
                    document: fetchData?.document,
                    paymentStatus: null,
                  }}
                />
              ) : (
                <SignatureMapper
                  setStartSave={setStartSave}
                  startSave={startSave}
                  setIsPreview={setIsPreview}
                  isSaved={isSaved}
                  setIsSaved={setIsSaved}
                  file={pdfFile}
                  numberOfPages={pdfPages}
                  receiversList={receiversList}
                  schemas={schemas}
                  fetchedData={fetchData}
                  setReload={setReload}
                />
              )}
            </div>
          )}

          <ToastContainer
            transition={Slide}
            position="bottom-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
            closeButton={false}
          />
        </div>
      </>
    </>
  );
};

export default MapSignature;
