import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineDocumentMinus } from "react-icons/hi2";
import { IoDocuments } from "react-icons/io5";
import { MdDelete, MdUpload } from "react-icons/md";
import {
  DesignerState,
  GlobalHelpersType,
} from "../../../pdfme/ui/src/components/Designer";
import {
  BoloPopover,
  BoloPopOverClose,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../Common/PopOver/BoloPopOver";
import MixPanel from "../../../utils/services/mixpanel";
import { IoMdDocument } from "react-icons/io";
import { CgAttachment } from "react-icons/cg";
import { ALL_FEATURES } from "../../../types/tiers";
import { isFeatureEnabled } from "../../../utils/paidFeat";

export type MiniPDFPageOptions = {
  designerState?: DesignerState;
  setDesignerState?: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers?: GlobalHelpersType;
  pageNo: number;
};

const MiniPDFPageOptions = (props: MiniPDFPageOptions) => {
  const { designerState, setDesignerState, globalHelpers, pageNo } = props;
  const [upgradeModalType, setUpgradeModalType] =
    useState<ALL_FEATURES>("SIGNER_ATTACHMENTS");
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const handleAddPage = async (pageNo: number) => {
    if (!designerState || !setDesignerState || !globalHelpers) {
      console.log({ designerState, setDesignerState, globalHelpers });
      return;
    }
    globalHelpers.PDF.addPage(pageNo);
  };

  const handleDeletePage = async (pageNo: number) => {
    if (!designerState || !setDesignerState || !globalHelpers) {
      console.log({ designerState, setDesignerState, globalHelpers });
      return;
    }

    globalHelpers.PDF.delPage(pageNo);
  };

  const handleDupPage = async (pageNo: number) => {
    if (!designerState || !setDesignerState || !globalHelpers) {
      console.log({ designerState, setDesignerState, globalHelpers });
      return;
    }
    globalHelpers.PDF.duplicatePage(pageNo);
  };
  const mixpanelSetting = (actionName: string) => {
    let Mixpanel = new MixPanel();

    if (designerState?.instanceFrom === "PDF") {
      Mixpanel.track("PDF", {
        pdf_action: `pdf_${actionName}`,
        paid: false,
      });
      Mixpanel.increment(`pdf_${actionName}`);
    } else if (designerState?.instanceFrom === "PDF-TEMPLATE") {
      Mixpanel.track("PDF template", {
        pdf_template_action: `pdf_template_${actionName}`,
        paid: false,
      });
      Mixpanel.increment(`pdf_template_${actionName}`);
    }
  };
  return (
    <div className="max-w-5 w-5 absolute right-0.5 top-0.5 z-10">
      {/* <PiDotsThreeVerticalBold size={25} /> */}
      <BoloPopover>
        <BoloPopoverTrigger>
          <button className="text-gray-500 hover:text-gray-700">
            <BsThreeDotsVertical size={20} />
          </button>
        </BoloPopoverTrigger>
        <BoloPopoverContent align="end">
          <div className="flex flex-col gap-2 text-gray-500 text-xs rounded-lg bg-white w-fit py-2">
            <button
              className="hover:text-primary flex gap-1 p-1 items-center px-2 pr-3  hover:bg-secondary/40"
              onClick={() => {
                mixpanelSetting("editor_add_page");
                handleAddPage(pageNo);
              }}
            >
              <IoMdDocument size={18} />
              Blank Page
            </button>
            {/* <button
              className="hover:text-gray-700 flex gap-1 p-1 items-center px-2 pr-3  hover:bg-secondary/40"
              onClick={() => {
                handleAddPage(pageNo + 1);
              }}
            >
              <HiOutlineDocumentPlus size={18} />
              Add Page After
            </button> */}
            <div
              className="hover:text-primary flex gap-1 p-1 items-center px-2 pr-3  hover:bg-secondary/40 cursor-pointer"
              onClick={() => {
                mixpanelSetting("editor_upload_file");

                setDesignerState &&
                  setDesignerState((prev) => ({
                    ...prev,
                    addDocument: {
                      ...prev.addDocument,
                      show: true,
                      pageNo: pageNo,
                    },
                  }));
              }}
            >
              <MdUpload size={18} />
              <span>Upload File</span>
            </div>
            <div
              className="hover:text-primary flex gap-1 p-1 items-center px-2 pr-3  hover:bg-secondary/40 cursor-pointer"
              onClick={() => {
                mixpanelSetting("editor_duplicate_page");
                handleDupPage(pageNo);
              }}
            >
              <IoDocuments size={18} />
              <span>Duplicate Page</span>
            </div>
            <BoloPopOverClose>
              <div
                className="hover:text-primary flex gap-1 p-1 items-center px-2 pr-3 group hover:bg-secondary/40 cursor-pointer"
                onClick={async () => {
                  let featuresAccess = await isFeatureEnabled(
                    "SIGNER_ATTACHMENTS"
                  );

                  if (!featuresAccess) {
                    setDesignerState &&
                      setDesignerState((prev) => ({
                        ...prev,
                        showUpgradeModal: {
                          ...prev.showUpgradeModal,
                          show: true,
                          type: "SIGNER_ATTACHMENTS",
                        },
                      }));
                    return;
                  }
                  setDesignerState &&
                    setDesignerState((prev) => ({
                      ...prev,
                      addAttachment: {
                        ...prev.addAttachment,
                        show: true,
                        pageNo: pageNo + 1,
                      },
                    }));
                }}
              >
                <CgAttachment
                  className={`text-gray-500 text-lg font-bold cursor-pointer group-hover:text-primary`}
                />
                <span>Add attachment</span>
              </div>
            </BoloPopOverClose>
            <button
              data-tooltip-id="role-delete-btn"
              disabled={designerState?.schemasList?.length == 1}
              className="hover:text-red-500 disabled:cursor-not-allowed disabled:opacity-100 flex gap-1 p-1 items-center px-2 pr-3  hover:bg-red-500/10"
              onClick={() => {
                mixpanelSetting("editor_delete_page");
                handleDeletePage(pageNo);
              }}
            >
              <MdDelete size={18} />
              Delete Page
            </button>
          </div>
        </BoloPopoverContent>
      </BoloPopover>
    </div>
  );
};

export default MiniPDFPageOptions;
