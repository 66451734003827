import { useEffect, useState } from "react";
import axios from "../../utils/axios";
// import axios from "axios";
import { BASE_URL } from "../../constants/constants";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import Modal from "../../components/Modal";
import { MdClose, MdOutlineCreate, MdContentCopy } from "react-icons/md";
import { BsChevronDown, BsChevronUp, BsInfoCircle } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import SignatureTemplateModal from "../../components/Modals/SignatureTemlateModals";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { Tooltip } from "react-tooltip";
import CompanyImageModal from "../../components/CompanyLogoModal";
import { isFeatureEnabled } from "../../utils/paidFeat";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import BrandingPreview from "../../components/BrandingPreview/BrandingPreview";
import { calculateContrast, getTintFromHex } from "../../utils/uiUtils";
import IntegrationCard from "../../components/Ui/IntegrationCard";
import {
  getWorkSpaceDetails,
  logOut,
  shouldShowMigrationDiv,
} from "../../utils";
import { SwitchWorkSpacesComponent } from "../../components/SwitchWorkSpaces";
import Webhooks from "../../components/Settings/Webhooks";
import CName from "../../components/Settings/CName/CName";
import TopNavItem from "../../components/Settings/TopNavItem/TopNavItem";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  getOwnerThunk,
  increaseTrialEndDateByOneDay,
  setOwnerRx,
} from "../../redux/slices/ownerSlice";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import useOwnerPlanDetails from "../../hooks/reduxHooks/useOwnerPlanDetails";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import IntegrationWithTokenCard from "../../components/Ui/IntgerationWithTokenCard";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { IntegrationsWithToken } from "../../constants/Integrations/index.js";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import useWhiteLabelHelper from "../../hooks/reduxHooks/useWhiteLabelHelper";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import Security from "../../components/Settings/Security/Security";
import { TiWarningOutline } from "react-icons/ti";
import { validateEmail } from "../../utils/validateEmail";
import WorkSpaceDefaults from "../../components/Settings/WorkSpaceDefaults/WorkSpaceDefaults";
import PaymentGatewayApps from "../../components/PaymentGatwayApps/PaymentGatewayApps";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/Common/BoloSelect/BoloSelect";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import {
  BoloInputOTP,
  BoloInputOTPGroup,
  BoloInputOTPSeparator,
  BoloInputOTPSlot,
} from "../../components/Common/OTP/BoloOTP";
import {
  BoloTab,
  BoloTabSwitcher,
} from "../../components/Common/BoloTabSwitcher/BoloTabSwitcher";
import { ALL_FEATURES } from "../../types/tiers";
import Embed from "../../components/Settings/Embed/Embed";
import DeveloperSection from "../../components/Settings/DeveloperSection/DeveloperSection";
import APIKey from "../../components/Settings/APIKey/APIKey";
import TrialExtendedModal from "../../components/TrialExtendedModal";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
};

const integrations = [
  {
    name: "Zapier",
    key: "zapier",
    description:
      "Connect BoloForms with 6000+ apps to automate your workflows and save time.",
    logo: "https://i.imgur.com/1B48LWi.png",
  },
  {
    name: "Pabbly",
    key: "pabbly",
    description:
      "Connect BoloForms with Pabbly and do more with your forms and submissions.",
    logo: "https://i.imgur.com/0hP9noS.png",
  },
  // {
  //   name: "Activepieces",
  //   key: "activepieces",
  //   description:
  //     "Automate your work with Activepieces for free. Trusted by thousands of users who run millions of automated tasks every month.",
  //   logo: "https://i.imgur.com/VdAPPjV.png",
  // },
  {
    name: "Make",
    key: "make",
    description:
      "Automate your work. Build something new. Trusted by thousands of fast-scaling organizations around the globe.",
    logo: "https://i.imgur.com/ClJBGDP.png",
  },
  // {
  //   name: "Pabbly",
  //   key: "pabbly",
  //   description:
  //     "Automate your work. Build something new. Trusted by thousands of fast-scaling organizations around the globe.",
  //   logo: "https://i.imgur.com/ClJBGDP.png",
  // },
];

const TopNavItems = [
  { name: "Profile", key: "PROFILE" },
  { name: "Branding", key: "BRANDING" },
  { name: "Email Settings", key: "CNAME" },
  // { name: "API Key", key: "APIKEY" },
  // { name: "Embed Code", key: "EMBED" },
  { name: "Developer", key: "DEV_SEC", defaultSubType: "APIKEY" },
  { name: "Integrations", key: "INTEGRATIONS" },
  { name: "Security", key: "SECURITY" },
  { name: "WorkSpaces", key: "WORKSPACES" },
  {
    name: "Default Settings",
    key: "DEFAULTSETTINGS",
    defaultSubType: "USER_DEFAULTS",
  },
];

const TopNavItemsEditor = [{ name: "Email Settings", key: "CNAME" }];

const Settings = () => {
  // const [ownerOld, setOwnerOld] = useState(
  //   JSON.parse(localStorage.getItem("owner") || "{}")
  // );

  // Redux code ----------------------------------------------------------------------------------
  const dispatch = useAppDispatch();
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);

  let currentPlanHistoryTierRx =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.tier;

  const workSpaceDetails = useWorkSpaceDetails();
  const ownerPlanDetails = useOwnerPlanDetails();
  const featuresEnabled = useIsFeatureEnabled();
  const whiteLabelHelper = useWhiteLabelHelper();
  const integrationWithTokens = IntegrationsWithToken(
    owner,
    window.location.href.replace(window.location.origin, "")
  );
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );

  useEffect(() => {
    dispatch(getOwnerThunk());
    // dispatch(getOwnerPaymentStatusThunk());
  }, []);

  // Redux code end -------------------------------------------------------------------------------

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [accountInfo, setAccountInfo] = useState({
    name: owner?.name || "",
    photo: "",
    photoFile: null,
    org: owner?.accountInfo?.companyName || "",
    orgLogo: owner?.accountInfo?.companyLogo || "",
    orgSize: owner?.accountInfo?.companySize || "",
    orgIndustry: owner?.accountInfo?.companyIndustry || "",
    orgWebsite: owner?.accountInfo?.companyWebsite || "",
    role: owner?.accountInfo?.role || "",
    department: owner?.accountInfo?.department || "",
    timeZone: owner?.accountInfo?.timeZone || "",
    theme: owner?.accountInfo?.theme || "",
    defaultWaterMark: owner?.accountInfo?.defaultWatermark || "",
    selectedLanguage: owner?.accountInfo?.selectedLanguage || "en",
  });
  const [settingsType, setSettingsType] = useState<string>("PROFILE");

  // localStorage.getItem("settings") ??
  const [searchParams, setSearchParams] = useSearchParams();
  const [showGenerateKeyModal, setShowGenerateKeyModal] = useState(false);
  const [showCopyKeyModal, setShowCopyKeyModal] = useState(false);
  const [showKeyActions, setShowKeyActions] = useState(false);
  const [showKeyActionsModal, setShowKeyActionsModal] = useState(false);
  const [isKeyActionDelete, setIsKeyActionDelete] = useState(false);
  const [loadingKey, setLoadingKey] = useState(false);
  const [keyName, setKeyName] = useState("");
  const [showKeyNameError, setShowKeyNameError] = useState(false);
  const [isCompanyImageModal, setIsCompanyImageModal] = useState(false);
  const [compnayModalData, setCompanyModalData] = useState({
    accountKey: "orgLogo",
    text: "Company Logo",
  });
  const [brandingPreview, setBrandingPreview] = useState(false);
  const [deleteLogo, setDeleteLogo] = useState(false);
  const [deleteWaterMark, setDeleteWaterMark] = useState(false);
  const [isEmailBranding, setIsEmailBranding] = useState(false);
  const [isWaterMarkBranding, setIsWaterMarkBranding] = useState(false);
  const [currentBrandingTab, setCurrentBrandingTab] = useState("EMAIL");

  //default settings
  const [activeTab, setActiveTab] = useState("SIGNING");
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [showInitialModal, setShowInitialModal] = useState(false);
  const [showStampModal, setShowStampModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadingWaterMark, setUploadingWaterMark] = useState(false);
  const [uploadingInitial, setUploadingInitial] = useState(false);
  const [uploadingStamp, setUploadingStamp] = useState(false);
  const [noPlanError, setNoPlanError] = useState(false);
  const [showWatermarkPreview, setShowWatermarkPreview] = useState(false);
  const [defaultWaterMark, setDefaultWaterMark] = useState<any>(null);
  const [defaultWaterMarkSrc, setDefaultWaterMarkSrc] = useState<any>("");
  const [waterMarkURLGlobal, setWaterMarkURLGlobal] = useState(
    owner?.accountInfo?.defaultWatermark
  );
  const [logoURLGlobal, setLogoURLGlobal] = useState(
    owner?.accountInfo?.companyLogo
  );

  const [migrateToEmailInput, setMigrateToEmailInput] = useState("");
  const [sendingMigrateOtp, setSendingMigrateOtp] = useState(false);
  const [migrating, setMigrating] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpExpiresIn, setOtpExpiresIn] = useState(0);

  const [migrateOtpInput, setMigrateOtpInput] = useState("");

  const [showExtendedTrialModal, setShowExtendedTrialModal] = useState(false);

  // when otp send successfully, change the otpExpiresIn each second
  useEffect(() => {
    if (otpSent) {
      const interval = setInterval(() => {
        setOtpExpiresIn((prev) => prev - 1);
      }, 1000);
      if (otpExpiresIn <= 0) {
        setOtpSent(false);
        setOtpExpiresIn(0);
      }

      return () => clearInterval(interval);
    }
  }, [otpSent, otpExpiresIn]);

  const [loggingOut, setLoggingOut] = useState(false);

  // whitelabel color handling =================================================
  const [primaryColor, setPrimaryColor] = useState(
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary
  );
  // ===========================================================================
  // why this
  // account info was there already exists
  const [selectedLanguage, setSelectedLanguage] = useState(
    owner?.accountInfo?.selectedLanguage
  );

  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });

  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;
  // const [workSpaceDetails, setWorkSpaceDetails] = useState(
  //   getWorkSpaceDetails()
  // );
  const navigate = useNavigate();

  let paramsSettingsType = searchParams.get("type");

  const [ownerLastPlanHistoryCreatedAt, setOwnerLastPlanHistoryCreatedAt] =
    useState(ownerPlanDetails?.currentPlan?.createdAt);

  useEffect(() => {
    if (paramsSettingsType) {
      setSettingsType(searchParams.get("type") || "");
      // localStorage.setItem("settings", searchParams.get("type"));
    }
  }, [paramsSettingsType, searchParams]);

  const getEmailBranding = async () => {
    // const isEmailBrandingEnabled = await isFeatureEnabled("EMAIL_BRANDING");
    setIsEmailBranding(featuresEnabled.EMAIL_BRANDING);
    // const isWaterMarkEnabled = await isFeatureEnabled("WATERMARK");
    setIsWaterMarkBranding(featuresEnabled.WATERMARK);
  };

  const fileToDataUri = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target?.result);
      };
      reader.readAsDataURL(file);
    });

  const setImageGlobalLinks = async () => {
    if (accountInfo?.defaultWaterMark?.name) {
      const uri = await fileToDataUri(accountInfo?.defaultWaterMark);
      setWaterMarkURLGlobal(uri);
    }

    if (accountInfo?.orgLogo?.name) {
      const uri = await fileToDataUri(accountInfo?.orgLogo);
      setLogoURLGlobal(uri);
    }
  };

  useEffect(() => {
    getEmailBranding();
    setImageGlobalLinks();
  }, [owner, accountInfo]);

  useEffect(() => {
    setPrimaryColor(
      whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary
    );
  }, [whiteLabelInfo]);

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
  }, []);

  const getDefaultWaterMarkSrc = async () => {
    const isEmailBrandingEnabled = await isFeatureEnabled("EMAIL_BRANDING");
    if (isEmailBrandingEnabled) {
      setDefaultWaterMarkSrc(owner?.accountInfo?.companyLogo || "");
    } else {
      setDefaultWaterMark("https://i.imgur.com/jvjNDSr.png");
    }
  };

  const updateAccountInfo = async () => {
    new MixPanel().track("Profile", {
      profile_action: "profile_update",
      profile_name: accountInfo?.name,
      profile_role: accountInfo?.role,
      profile_department: accountInfo?.department,
      profile_timezone: accountInfo?.timeZone,
      paid: false,
    });
    new MixPanel().increment("profile_update");
    new MixPanel().track("Organization", {
      organization_action: "organization_update",
      organization_name: accountInfo?.org,
      organization_size: accountInfo?.orgSize,
      organization_industry: accountInfo?.orgIndustry,
      paid: false,
    });
    new MixPanel().increment("organization_update");
    if (
      !workSpaceDetails.isWorkSpaceOwner &&
      workSpaceDetails.currentWorkSpaceRole !== "admin"
    ) {
      toast.error("Only Owner and Administrtor can change settings");
      return;
    }
    try {
      // let owner = JSON.parse(localStorage.getItem("owner") || "{}");
      let ownerId = owner?._id;

      if (accountInfo?.orgWebsite) {
        try {
          new URL(accountInfo?.orgWebsite);
        } catch (error) {
          return toast.error("Please enter a valid org website");
        }
      }

      let formData = new FormData();
      // formData.append("photo", accountInfo?.photoFile ?? "");
      formData.append("name", accountInfo?.name ?? "");
      formData.append("org", accountInfo?.org ?? "");
      // formData.append("orgLogo", accountInfo?.orgLogo ?? "");
      formData.append("orgSize", accountInfo?.orgSize ?? "");
      formData.append("orgIndustry", accountInfo?.orgIndustry ?? "");
      formData.append("orgWebsite", accountInfo?.orgWebsite ?? "");
      formData.append("role", accountInfo?.role ?? "");
      formData.append("department", accountInfo?.department ?? "");
      formData.append("ownerId", ownerId);
      formData.append("timeZone", accountInfo?.timeZone ?? "");
      // formData.append("theme", accountInfo?.theme ?? "");

      console.log(formData.get("timeZone"));
      let { data } = await axios.put(
        `${BASE_URL}/owners/account-info`,
        formData
      );
      if (!data?.error) {
        // localStorage.setItem("owner", JSON.stringify(data.owner));
        dispatch(setOwnerRx({ ...owner, ...data?.owner }));
        setAccountInfo({
          name: data.owner.name || "",
          photo: "",
          photoFile: null,
          org: data?.owner?.accountInfo?.companyName || "",
          orgLogo: data?.owner?.accountInfo?.companyLogo || "",
          orgSize: data?.owner?.accountInfo?.companySize || "",
          orgIndustry: data?.owner?.accountInfo?.companyIndustry || "",
          orgWebsite: data?.owner?.accountInfo?.companyWebsite || "",
          role: data?.owner?.accountInfo?.role || "",
          department: data?.owner?.accountInfo?.department || "",
          timeZone: data?.owner?.accountInfo?.timeZone || "",
          theme: data?.owner?.accountInfo?.theme || "",
          defaultWaterMark: data?.owner?.accountInfo?.defaultWatermark || "",
          selectedLanguage: data?.owner?.accountInfo?.selectedLanguage || "en",
        });
        setWaterMarkURLGlobal(data?.owner?.accountInfo?.defaultWatermark || "");
        setSelectedLanguage(data?.owner?.accountInfo?.selectedLanguage || "en");
        setLogoURLGlobal(data?.owner?.accountInfo?.companyLogo || "");
        toast.success("Account info updated successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const updateBrandingInfo = async () => {
    // const isEmailBrandingEnabled = await isFeatureEnabled("EMAIL_BRANDING");
    // const isWaterMarkEnabled = await isFeatureEnabled("WATERMARK");
    if (currentBrandingTab === "EMAIL") {
      new MixPanel().track("Branding", {
        branding_action: "branding_email",
        paid: true,
      });
      new MixPanel().increment("branding_email");
    } else if (currentBrandingTab === "PDF") {
      new MixPanel().track("Branding", {
        branding_action: "branding_watermark",
        paid: true,
      });
      new MixPanel().increment("branding_watermark");
    } else if (currentBrandingTab === "LANGUAGE") {
      new MixPanel().track("Branding", {
        branding_action: "branding_language",
        branding_language: accountInfo?.selectedLanguage,
        paid: false,
      });
      new MixPanel().increment("branding_language");
    }

    const isEmailBrandingEnabled = featuresEnabled.EMAIL_BRANDING;
    const isWaterMarkEnabled = featuresEnabled.WATERMARK;
    if (!isEmailBrandingEnabled && currentBrandingTab == "EMAIL") {
      setShowUpgradeModal(true);
      setUpgradeModalType("EMAIL_BRANDING");
      // setUpgradeModalData({
      //   title: "Branding is available in Growing Business Plan and above",
      //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
      //   videoSrc: "",
      // });
      return;
    }
    if (!isWaterMarkEnabled && currentBrandingTab == "PDF") {
      setShowUpgradeModal(true);
      setUpgradeModalType("WATERMARK");
      // setUpgradeModalData({
      //   title: "WaterMark is available in Team Plan and above",
      //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
      //   videoSrc: "",
      // });
      return;
    }
    try {
      // let owner = JSON.parse(localStorage.getItem("owner") || "{}");
      let ownerId = owner?._id;

      const textColor = accountInfo?.theme
        ? Number(calculateContrast(accountInfo?.theme, "#FFFFFF")) > 3
          ? "#FFFFFF"
          : "#000000"
        : "";
      const themeLight = accountInfo?.theme
        ? getTintFromHex(accountInfo?.theme, 70)
        : "";

      let formData = new FormData();
      formData.append("org", accountInfo?.org ?? "");
      if (currentBrandingTab == "EMAIL" && isEmailBranding) {
        formData.append("orgLogo", accountInfo?.orgLogo ?? "");
      }
      if (currentBrandingTab == "PDF" && isWaterMarkEnabled) {
        formData.append("waterMark", accountInfo?.defaultWaterMark ?? "");
      }
      if (currentBrandingTab == "LANGUAGE") {
        formData.append("selectedLanguage", accountInfo?.selectedLanguage);
      }
      formData.append("theme", accountInfo?.theme ?? "");
      formData.append("themeLight", themeLight ?? "");
      formData.append("themeText", textColor ?? "");
      formData.append("deleteLogo", deleteLogo ? "1" : "0");
      formData.append("deleteDefaultWatermark", deleteWaterMark ? "1" : "0");
      formData.append("ownerId", ownerId);

      let { data } = await axios.post(
        `${BASE_URL}/owners/branding-info`,
        formData
      );
      if (!data?.error) {
        // localStorage.setItem("owner", JSON.stringify(data.owner));
        dispatch(setOwnerRx({ ...owner, ...data?.owner }));

        let prevCompanyLogo = owner?.accountInfo?.companyLogo;
        let prevCompanyTheme = owner?.accountInfo?.theme;

        let trialExtended = data?.trialExtended;

        if (trialExtended?.extended) {
          dispatch(increaseTrialEndDateByOneDay());

          setShowExtendedTrialModal(true);
        }

        setAccountInfo((prev) => ({
          ...prev,
          org: data?.owner?.accountInfo?.companyName || "",
          orgLogo: data?.owner?.accountInfo?.companyLogo || "",
          theme: data?.owner?.accountInfo?.theme || "",
          defaultWaterMark: data?.owner?.accountInfo?.defaultWatermark || "",
          selectedLanguage: data?.owner?.accountInfo?.selectedLanguage || "en",
        }));
        // setDefaultWaterMark()
        const message: any = {
          EMAIL: "Email Branding ",
          PDF: "WaterMark ",
          LANGUAGE: "Language ",
        };
        toast.success(
          `${message[currentBrandingTab]}Info updated successfully`
        );
      }
      setWaterMarkURLGlobal(data?.owner?.accountInfo?.defaultWatermark || "");
      setLogoURLGlobal(data?.owner?.accountInfo?.companyLogo || "");
      setSelectedLanguage(data?.owner?.accountInfo?.selectedLanguage || "en");
      setDeleteWaterMark(false);
      setDeleteLogo(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  // const generateKeyModalContent = () => {
  //   return (
  //     <div className="w-[100%] md:w-[600px] py-4 rounded-lg relative">
  //       <MdClose
  //         onClick={() => setShowGenerateKeyModal(false)}
  //         className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
  //       />

  //       <div className="flex flex-col px-3 md:px-10">
  //         <p className="mb-1 text-lg font-semibold text-center">
  //           Generate your API
  //         </p>
  //         <p className="text-sm font-semibold text-gray-500 mb-10 text-center">
  //           Type a API Key name that you will remember so you can identify it to
  //           monitor future usage.
  //         </p>
  //         <div className="mb-5 flex flex-col gap-y-3">
  //           <span className="text-sm font-semibold">API Key Name</span>
  //           <input
  //             type="text"
  //             placeholder="API Key Name"
  //             value={keyName}
  //             onChange={(e) => setKeyName(e.target.value)}
  //             className="text-xs md:text-base border border-gray-300 rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-50"
  //             required
  //           />
  //           {keyName.trim() === "" && showKeyNameError && (
  //             <p className="text-xs text-red-500">API Key Name is required.</p>
  //           )}
  //         </div>
  //         <div className="flex gap-x-5">
  //           <button
  //             onClick={async (e) => {
  //               if (keyName.trim() === "") {
  //                 setShowKeyNameError(true);
  //                 return;
  //               }
  //               e.stopPropagation();

  //               setLoadingKey(true);
  //               console.log(keyName);
  //               try {
  //                 const { data } = await axios.post(`${BASE_URL}/apikey`, {
  //                   regenerateApiKey: false,
  //                   ownerId: owner?._id,
  //                   apiKeyName: keyName?.trim(),
  //                 });

  //                 // setOwner((prev: any) => ({
  //                 //   ...prev,
  //                 //   apiKey: data?.apiKeyObj,
  //                 // }));
  //                 dispatch(setOwnerRx({ ...owner, apiKey: data?.apiKeyObj }));

  //                 // localStorage.setItem(
  //                 //   "owner",
  //                 //   JSON.stringify({
  //                 //     ...owner,
  //                 //     apiKey: data?.apiKeyObj,
  //                 //   })
  //                 // );
  //                 toast.success("API key generated successfully");
  //                 setLoadingKey(false);
  //                 setShowKeyNameError(false);
  //                 setKeyName("");
  //                 setShowCopyKeyModal(true);
  //               } catch (error) {
  //                 setLoadingKey(false);
  //                 setShowKeyNameError(false);
  //                 setKeyName("");
  //                 toast.error("Error generating API key");
  //               }
  //               setShowGenerateKeyModal(false);
  //             }}
  //             disabled={
  //               !workSpaceDetails.isWorkSpaceOwner &&
  //               workSpaceDetails.currentWorkSpaceRole !== "admin"
  //             }
  //             className="text-xs md:text-sm text-white bg-primary hover:bg-tertiary px-6 py-2 rounded-full disabled:opacity-50"
  //           >
  //             {loadingKey ? "Generating..." : "Generate API Key"}
  //           </button>
  //           <button
  //             onClick={() => {
  //               setShowKeyNameError(false);
  //               setShowGenerateKeyModal(false);
  //               setKeyName("");
  //             }}
  //             className="text-xs md:text-sm text-white bg-[#ff5151] hover:bg-[#fe7777] px-6 py-2 rounded-full"
  //           >
  //             Cancel
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const copyKeyModalContent = () => {
    return (
      <div className="w-[100%] md:w-[600px] py-4 rounded-lg relative">
        <MdClose
          onClick={() => setShowCopyKeyModal(false)}
          className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />

        <div className="flex flex-col px-3 md:px-10">
          <p className="mb-1 text-lg font-semibold text-center">
            You’ve created your API
          </p>
          <p className="text-sm font-semibold text-gray-500 mb-10 text-center">
            Copy the API key below to start using it to make requests to the
            BoloForms API.
          </p>
          <div className="mb-5 flex flex-col gap-y-3">
            <span className="text-sm font-semibold">API Key Value</span>
            <div className="break-all text-xs md:text-md px-3 py-2 border text-gray-400 border-gray-100 rounded-md bg-gray-100">
              {owner?.apiKey?.value}
            </div>
          </div>
          <div className="flex gap-x-5">
            <button
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(owner?.apiKey?.value);
                  toast.success("API key successfully copied to clipboard");
                } catch (err) {
                  console.error("Failed to copy: ", err);
                }
              }}
              className="text-xs md:text-sm text-white bg-primary hover:bg-tertiary px-6 py-2 rounded-full"
            >
              Copy API
            </button>
            <button
              onClick={() => {
                setShowGenerateKeyModal(false);
                setShowCopyKeyModal(false);
              }}
              className="text-xs md:text-sm text-white bg-[#ff5151] hover:bg-[#fe7777] px-6 py-2 rounded-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const WatermarkPreviewModal = () => {
    // Get the URL of the watermark image.
    let watermarkImageURL = owner?.accountInfo?.defaultWatermark;
    if (owner?.accountInfo?.defaultWatermark === "") {
      watermarkImageURL = IMAGE_LINKS.WHITE_RECT;
    }
    return (
      <div className="p-4 relative">
        <MdClose
          onClick={() => setShowWatermarkPreview(false)}
          className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <p className="font-semibold mb-4 text-sm">
          Preview of the watermark in a basic A4 aspect ratio
        </p>
        <div className="w-[400px] h-[564px] border border-gray-300 flex items-center justify-center">
          <img
            src={watermarkImageURL}
            alt="Watermark"
            className="m-auto w-[201px] h-[201px] opacity-40"
          />
        </div>
      </div>
    );
  };

  // const DropdownActionsKey = () => {
  //   return (
  //     <div
  //       className={`absolute top-5 border border-gray-300 rounded-lg shadow-lg w-[180px] cursor-pointer bg-white ${
  //         !showKeyActions ? "hidden" : ""
  //       } `}
  //     >
  //       <div className="flex flex-col py-2">
  //         <div
  //           onClick={() => {
  //             setIsKeyActionDelete(false);
  //             setShowKeyActionsModal(true);
  //             setShowKeyActions(false);
  //           }}
  //           className="flex items-center gap-x-3 border-b-[1px] border-gray-300 p-3 hover:bg-primary/20"
  //         >
  //           <MdOutlineCreate className="text-xl" />
  //           <span>Regenerate</span>
  //         </div>
  //         <div
  //           onClick={() => {
  //             setIsKeyActionDelete(true);
  //             setShowKeyActionsModal(true);
  //             setShowKeyActions(false);
  //           }}
  //           className="flex items-center gap-x-3 text-red-500 p-3 hover:bg-primary/20"
  //         >
  //           <RiDeleteBinLine className="text-xl" />
  //           <span>Delete</span>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const keyActionsModalContent = () => {
  //   return (
  //     <div className="w-[100%] md:w-[500px] py-4 rounded-lg relative">
  //       <MdClose
  //         onClick={() => {
  //           setIsKeyActionDelete(false);
  //           setShowKeyActionsModal(false);
  //         }}
  //         className="absolute top-1 md:top-0 right-0 text-2xl text-gray-600 cursor-pointer"
  //       />
  //       <div className="flex flex-col items-center justify-center px-3 md:px-10">
  //         <p className="mb-1 text-lg font-semibold">
  //           {`Are you sure want to ${
  //             isKeyActionDelete ? "delete" : "regenerate"
  //           } API key?`}
  //         </p>
  //         <p className="text-sm font-semibold text-gray-500 mb-10 text-center">
  //           {isKeyActionDelete
  //             ? "Deleting this API key will completely remove it. This cannot be undone."
  //             : "Regenerating this API key will create a new one with updated credentials, and it cannot be undone."}
  //         </p>
  //         <div className="flex gap-x-5">
  //           {isKeyActionDelete ? (
  //             <button
  //               onClick={async (e) => {
  //                 e.stopPropagation();

  //                 setLoadingKey(true);

  //                 try {
  //                   const { data } = await axios.put(
  //                     `${BASE_URL}/apikey/delete`,
  //                     {
  //                       ownerId: owner?._id,
  //                     }
  //                   );

  //                   // setOwner((prev: any) => data.updatedOwner);
  //                   dispatch(setOwnerRx({ ...owner, ...data?.updatedOwner }));

  //                   // localStorage.setItem(
  //                   //   "owner",
  //                   //   JSON.stringify(data.updatedOwner)
  //                   // );
  //                   setLoadingKey(false);
  //                   setIsKeyActionDelete(false);
  //                   setShowKeyActionsModal(false);
  //                   toast.success("API key deleted successfully");
  //                 } catch (error) {
  //                   setLoadingKey(false);
  //                   toast.error("Error deleting API key");
  //                 }
  //               }}
  //               className="text-sm text-white bg-[#ff5151] hover:bg-[#fe7777] px-6 py-2 rounded-full"
  //             >
  //               {loadingKey ? "Deleting..." : "Delete"}
  //             </button>
  //           ) : (
  //             <button
  //               onClick={async (e) => {
  //                 e.stopPropagation();

  //                 setLoadingKey(true);

  //                 try {
  //                   const { data } = await axios.post(`${BASE_URL}/apikey`, {
  //                     regenerateApiKey: true,
  //                     ownerId: owner?._id,
  //                     apiKeyName: owner?.apiKey?.apiKeyName,
  //                   });

  //                   // setOwner((prev: any) => ({
  //                   //   ...prev,
  //                   //   apiKey: data?.apiKeyObj,
  //                   // }));

  //                   dispatch(setOwnerRx({ ...owner, apiKey: data?.apiKeyObj }));

  //                   // localStorage.setItem(
  //                   //   "owner",
  //                   //   JSON.stringify({
  //                   //     ...owner,
  //                   //     apiKey: data?.apiKeyObj,
  //                   //   })
  //                   // );
  //                   setLoadingKey(false);
  //                   setShowKeyActionsModal(false);
  //                   toast.success("API key regenerated successfully");
  //                 } catch (error) {
  //                   setLoadingKey(false);
  //                   toast.error("Error regenerating API key");
  //                 }
  //               }}
  //               className="text-xs md:text-sm text-white bg-[#ff5151] hover:bg-[#fe7777] px-6 py-2 rounded-full"
  //             >
  //               {loadingKey ? "Regenerating..." : "Regenerate"}
  //             </button>
  //           )}
  //           <button
  //             onClick={() => {
  //               setIsKeyActionDelete(false);
  //               setShowKeyActionsModal(false);
  //             }}
  //             className="text-xs md:text-sm text-white bg-primary hover:bg-tertiary px-6 py-2 rounded-full"
  //           >
  //             Cancel
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const handleAddDefaultSignature = async (signature: any) => {
    try {
      setUploading(true);
      const { data } = await axios.post(`${BASE_URL}/owners/defaultSignature`, {
        ownerId: owner?._id,
        signature,
      });

      if (data.error) {
        toast.error(data.error);
        setUploading(false);
        return;
      }

      if (!data.error) {
        // setOwner((prev: any) => ({
        //   ...prev,
        //   defaultSignature: data?.signature,
        // }));
        dispatch(setOwnerRx({ ...owner, defaultSignature: data?.signature }));
        // localStorage.setItem(
        //   "owner",
        //   JSON.stringify({
        //     ...owner,
        //     defaultSignature: data?.signature,
        //   })
        // );
        toast.success("Signature added successfully");
      }
      setUploading(false);
    } catch (error: any) {
      setUploading(false);
      toast.error(error.message);
    }
  };

  const handleAddDefaultInitial = async (initial: any) => {
    try {
      setUploadingInitial(true);
      const { data } = await axios.post(`${BASE_URL}/owners/defaultInitials`, {
        ownerId: owner?._id,
        initial,
      });

      if (data.error) {
        toast.error(data.error);
        setUploadingInitial(false);
        return;
      }

      if (!data.error) {
        // setOwner((prev: any) => ({
        //   ...prev,
        //   defaultInitial: data?.initial,
        // }));
        dispatch(setOwnerRx({ ...owner, defaultInitial: data?.initial }));
        // localStorage.setItem(
        //   "owner",
        //   JSON.stringify({
        //     ...owner,
        //     defaultInitial: data?.initial,
        //   })
        // );
        toast.success("Inital added successfully");
      }
      setUploadingInitial(false);
    } catch (error: any) {
      setUploadingInitial(false);
      toast.error(error.message);
    }
  };

  const handleAddDefaultStamp = async (stamp: any) => {
    try {
      setUploadingStamp(true);
      const { data } = await axios.post(`${BASE_URL}/owners/defaultStamp`, {
        ownerId: owner?._id,
        stamp,
      });

      if (data.error) {
        toast.error(data.error);
        setUploadingStamp(false);
        return;
      }

      if (!data.error) {
        // setOwner((prev: any) => ({
        //   ...prev,
        //   defaultStamp: data?.stamp,
        // }));
        dispatch(setOwnerRx({ ...owner, defaultStamp: data?.stamp }));

        // localStorage.setItem(
        //   "owner",
        //   JSON.stringify({
        //     ...owner,
        //     defaultStamp: data?.stamp,
        //   })
        // );
        toast.success("Inital added successfully");
      }
      setUploadingStamp(false);
    } catch (error: any) {
      setUploadingStamp(false);
      toast.error(error.message);
    }
  };

  const setActiveTabParam = (tabName: string) => {
    const data = Object.fromEntries(searchParams);
    data["type"] = tabName;
    data["subType"] =
      TopNavItems.find((_) => _.key === tabName)?.defaultSubType || "";
    setSearchParams(createSearchParams(data));
  };

  useEffect(() => {
    getDefaultWaterMarkSrc();
  }, [accountInfo]);

  useEffect(() => {
    // const owner = JSON.parse(localStorage.getItem("owner") || "{}");
    setAccountInfo({
      name: owner?.name || "",
      photo: "",
      photoFile: null,
      org: owner?.accountInfo?.companyName || "",
      orgLogo: owner?.accountInfo?.companyLogo || "",
      orgSize: owner?.accountInfo?.companySize || "",
      orgIndustry: owner?.accountInfo?.companyIndustry || "",
      orgWebsite: owner?.accountInfo?.companyWebsite || "",
      role: owner?.accountInfo?.role || "",
      department: owner?.accountInfo?.department || "",
      timeZone: owner?.accountInfo?.timeZone || "",
      theme: owner?.accountInfo?.theme || "",
      defaultWaterMark: owner?.accountInfo?.defaultWatermark || "",
      selectedLanguage: owner?.accountInfo?.selectedLanguage || "en",
    });
  }, [workSpaceDetails]);

  // useEffect(() => {
  //   function checkUserDetails() {
  //     const localWorkSpaceDetails = getWorkSpaceDetails();
  //     setWorkSpaceDetails(localWorkSpaceDetails);
  //     setOwner(JSON.parse(localStorage.getItem("owner") || "{}"));
  //   }
  //   window.addEventListener("storage", checkUserDetails);
  //   return () => {
  //     window.removeEventListener("storage", checkUserDetails);
  //   };
  // }, []);

  const sendMigrateOtp = async () => {
    try {
      setSendingMigrateOtp(true);
      const migrateToEmail = migrateToEmailInput.trim();
      if (!migrateToEmail || !validateEmail(migrateToEmail)) {
        toast.error("Please enter a valid email address");
        setSendingMigrateOtp(false);
        return;
      }

      if (owner?.email === migrateToEmail) {
        toast.error("Please enter a different email address");
        setSendingMigrateOtp(false);
        return;
      }

      const { data } = await axios.post(`/owner/send-migrate-email-otp`, {
        originalEmail: owner?.email,
        migrateToEmail: migrateToEmail,
        workSpaceId: workSpaceDetails.currentWorkSpaceId,
      });

      if (!data.success) {
        toast.error(data.message);
        setSendingMigrateOtp(false);
        return;
      }

      if (data.success) {
        toast.success(data.message);
        setOtpSent(true);
        setOtpExpiresIn(data.duration);
        setSendingMigrateOtp(false);
      }
    } catch (error: any) {
      setSendingMigrateOtp(false);
      toast.error(error.message);
      console.log("error", error);
    }
  };
  const migrateToNewEmailHandler = async () => {
    try {
      setMigrating(true);
      setOtpSent(false);
      setOtpExpiresIn(0);

      if (!otpSent || otpExpiresIn <= 0 || !migrateOtpInput) {
        toast.error("Please enter a valid OTP to proceed");
        setMigrating(false);
        return;
      }
      const migrateToEmail = migrateToEmailInput.trim();
      if (!migrateToEmail || !validateEmail(migrateToEmail)) {
        toast.error("Please enter a valid email address");
        setMigrating(false);

        return;
      }

      if (owner?.email === migrateToEmail) {
        toast.error("Please enter a different email address");
        setMigrating(false);
        return;
      }

      const { data } = await axios.post(`/owner/migrate-email`, {
        originalEmail: owner?.email,
        migrateToEmail: migrateToEmail,
        otpInput: migrateOtpInput,
      });

      if (!data.success) {
        toast.error(data.message);
        setMigrating(false);
        setOtpSent(false);
        setOtpExpiresIn(0);
        setMigrateOtpInput("");
        return;
      }

      if (data.success) {
        toast.success(data.message);
        setLoggingOut(true);
        setTimeout(() => {
          logOut();
        }, 2000);
      }
    } catch (error: any) {
      setMigrating(false);
      setOtpSent(false);
      setOtpExpiresIn(0);
      setMigrateOtpInput("");
      toast.error(error.message);
      console.log("error", error);
    }
  };

  return (
    <div className="notranslate">
      <Helmet>
        <title translate="no">
          {TopNavItems.find((ele) => ele.key === settingsType)?.name}
        </title>
        <meta charSet="utf-8" />
        <meta name="title" content="Settings" />
        <meta
          name="description"
          content="Customize your BoloForms experience on the Settings page, effortlessly tailoring preferences, managing account details, and optimizing your workflow."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/settings`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Settings - Boloforms`} />
        <meta
          property="og:description"
          content="Customize your BoloForms experience on the Settings page, effortlessly tailoring preferences, managing account details, and optimizing your workflow."
        />
        <link rel="canonical" href="https://signature.boloforms.com/settings" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      <div
        className="sticky top-[-16px] flex w-full md:w-[calc(100%_+_32px)] bg-white md:ml-[-32px] md:pl-8 overflow-x-auto"
        id="mini-pdf-custom-scroll"
      >
        <BoloTabSwitcher
          value={settingsType}
          onChangeTab={(tab: string) => {
            setActiveTabParam(tab);
            setSettingsType(tab);
          }}
        >
          {workSpaceDetails?.currentWorkSpaceRole === "editor" &&
            TopNavItemsEditor?.map((item, idx) => {
              return (
                <BoloTab
                  value={item.key}
                  size={"sm"}
                  className="py-2 md:py-3 pt-2 md:pt-3  px-4 whitespace-nowrap"
                >
                  {item.name}
                </BoloTab>
              );
            })}

          {workSpaceDetails?.currentWorkSpaceRole !== "editor" &&
            TopNavItems?.map((item, idx) => {
              const SKIP_WHITE_LABEL = ["DEV_SEC", "APIKEY"];
              if (
                whiteLabelHelper?.isWhiteLabel &&
                SKIP_WHITE_LABEL.includes(item.key)
              ) {
                return null;
              }

              return (
                <BoloTab
                  value={item.key}
                  size={"sm"}
                  className="py-2 md:py-3 pt-2 md:pt-3  px-4 whitespace-nowrap"
                >
                  {item.name}
                </BoloTab>
              );
            })}
        </BoloTabSwitcher>
      </div>
      {/* <div
        className="sticky top-[-16px] flex w-full bg-white md:ml-[-32px] md:pl-8 overflow-x-auto"
        id="mini-pdf-custom-scroll"
      >
        {TopNavItems?.map((item, idx) => {
          if (whiteLabelHelper?.isWhiteLabel && item.key == "APIKEY") {
            return null;
          }

          if (
            (ownerPlanDetails.isAppSumo ||
              ownerPlanDetails.tier === "BOLOFORMS_LIFETIME") &&
            item?.key == "CNAME"
          ) {
            return null;
          }
          return (
            <TopNavItem
              setActiveTabParam={setActiveTabParam}
              setSettingsType={setSettingsType}
              settingsName={item?.name}
              settingsType={item?.key}
              activeSettingsType={settingsType}
            />
          );
        })}
      </div> */}
      <div className="mt-6">
        {/* {settingsType === "APIKEY" && <APIKey />} */}
        {settingsType === "PROFILE" && (
          <div className="md:mx-4 pb-14">
            <h3 className="text-md font-semibold mb-1 mt-6">Profile</h3>
            <p className="text-xs text-gray-500 mb-4">
              Your profile information is used to identify you on BoloForms.
            </p>
            <div className="flex flex-col">
              <div className="flex flex-row mb-2">
                <div className="max-w-[600px] w-[100%] md:w-[80%]">
                  <label className="text-xs text-gray-600">Name</label>
                  <input
                    className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none"
                    // disabled
                    type="text"
                    placeholder="Name"
                    value={accountInfo?.name}
                    onChange={(e) =>
                      setAccountInfo({ ...accountInfo, name: e.target.value })
                    }
                  />
                  <div className="flex flex-col">
                    <p className="text-gray-600 text-xs">
                      What's your role in the company?
                    </p>
                    <Select
                      value={accountInfo?.role}
                      onValueChange={(value) => {
                        setAccountInfo((prev) => ({
                          ...prev,
                          role: value,
                        }));
                      }}
                    >
                      <SelectTrigger className="w-full text-sm mt-1">
                        <SelectValue placeholder="Select your role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value={"CEO"}>
                            Chief Executive Officer (CEO)
                          </SelectItem>
                          <SelectItem value="COO">
                            Chief Operating Officer (COO)
                          </SelectItem>
                          <SelectItem value="CFO">
                            Chief Financial Officer (CFO)
                          </SelectItem>
                          <SelectItem value="CMO">
                            Chief Marketing Officer (CMO)
                          </SelectItem>
                          <SelectItem value="CTO">
                            Chief Technology Officer (CTO)
                          </SelectItem>
                          <SelectItem value="Department Manager">
                            Department Manager
                          </SelectItem>
                          <SelectItem value="Project Manager">
                            Project Manager
                          </SelectItem>
                          <SelectItem value="Sales Manager">
                            Sales Manager
                          </SelectItem>
                          <SelectItem value="Marketing Manager">
                            Marketing Manager
                          </SelectItem>
                          <SelectItem value="Human Resources Manager">
                            Human Resources Manager
                          </SelectItem>
                          <SelectItem value="Sales Representative">
                            Sales Representative
                          </SelectItem>
                          <SelectItem value="Customer Service Representative">
                            Customer Service Representative
                          </SelectItem>
                          <SelectItem value="Technical Support Representative">
                            Technical Support Representative
                          </SelectItem>
                          <SelectItem value="Retail Sales Associate">
                            Retail Sales Associate
                          </SelectItem>
                          <SelectItem value="Administrative Assistant">
                            Administrative Assistant
                          </SelectItem>
                          <SelectItem value="Accounting Clerk">
                            Accounting Clerk
                          </SelectItem>
                          <SelectItem value="Human Resources Assistant">
                            Human Resources Assistant
                          </SelectItem>
                          <SelectItem value="IT Support Technician">
                            IT Support Technician
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {/* <div className="mt-2 text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                      <select
                        value={accountInfo?.role}
                        onChange={(e) =>
                          setAccountInfo((prev) => ({
                            ...prev,
                            role: e.target.value,
                          }))
                        }
                        className="w-full outline-none p-2"
                      >
                        <option value="select">Select</option>
                        <option value="CEO">
                          Chief Executive Officer (CEO)
                        </option>
                        <option value="COO">
                          Chief Operating Officer (COO)
                        </option>
                        <option value="CFO">
                          Chief Financial Officer (CFO)
                        </option>
                        <option value="CMO">
                          Chief Marketing Officer (CMO)
                        </option>
                        <option value="CTO">
                          Chief Technology Officer (CTO)
                        </option>
                        <option value="Department Manager">
                          Department Manager
                        </option>
                        <option value="Project Manager">Project Manager</option>
                        <option value="Sales Manager">Sales Manager</option>
                        <option value="Marketing Manager">
                          Marketing Manager
                        </option>
                        <option value="Human Resources Manager">
                          Human Resources Manager
                        </option>
                        <option value="Sales Representative">
                          Sales Representative
                        </option>
                        <option value="Customer Service Representative">
                          Customer Service Representative
                        </option>
                        <option value="Technical Support Representative">
                          Technical Support Representative
                        </option>
                        <option value="Retail Sales Associate">
                          Retail Sales Associate
                        </option>
                        <option value="Administrative Assistant">
                          Administrative Assistant
                        </option>
                        <option value="Accounting Clerk">
                          Accounting Clerk
                        </option>
                        <option value="Human Resources Assistant">
                          Human Resources Assistant
                        </option>
                        <option value="IT Support Technician">
                          IT Support Technician
                        </option>
                      </select>
                    </div> */}
                  </div>
                  <div className="flex flex-col mt-4">
                    <p className="text-gray-600 text-xs">
                      Which department will use Boloforms?
                    </p>
                    <Select
                      value={accountInfo?.department}
                      onValueChange={(value) => {
                        setAccountInfo((prev) => ({
                          ...prev,
                          department: value,
                        }));
                      }}
                    >
                      <SelectTrigger className="w-full text-sm mt-1">
                        <SelectValue placeholder="Select your department" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {/* <SelectItem value="select">Select</SelectItem> */}
                          <SelectItem value="sales">Sales</SelectItem>
                          <SelectItem value="marketing">Marketing</SelectItem>
                          <SelectItem value="customer-support">
                            Customer Support
                          </SelectItem>
                          <SelectItem value="it">IT</SelectItem>
                          <SelectItem value="finance">Finance</SelectItem>
                          <SelectItem value="human-resources">
                            Human Resources
                          </SelectItem>
                          <SelectItem value="operations">Operations</SelectItem>
                          <SelectItem value="other">Other</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {/* <div className="mt-2 text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                      <select
                        value={accountInfo?.department}
                        onChange={(e) =>
                          setAccountInfo((prev) => ({
                            ...prev,
                            department: e.target.value,
                          }))
                        }
                        className="w-full outline-none p-2"
                      >
                        <option value="select">Select</option>
                        <option value="sales">Sales</option>
                        <option value="marketing">Marketing</option>
                        <option value="customer-support">
                          Customer Support
                        </option>
                        <option value="it">IT</option>
                        <option value="finance">Finance</option>
                        <option value="human-resources">Human Resources</option>
                        <option value="operations">Operations</option>
                        <option value="other">Other</option>
                      </select>
                    </div> */}
                  </div>
                  <div className="flex flex-col mt-4">
                    <p className="text-gray-600 text-xs">
                      What's the time-zone you operate in ? (This would be used
                      in your generated PDFs)
                    </p>
                    <Select
                      value={accountInfo?.timeZone}
                      onValueChange={(value) => {
                        setAccountInfo((prev) => ({
                          ...prev,
                          timeZone: value,
                        }));
                      }}
                    >
                      <SelectTrigger className="w-full text-sm mt-1">
                        <SelectValue placeholder="Select your timezone" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {options.map((option, index) => (
                            <SelectItem key={index} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {/* <div className="mt-2 text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                      <select
                        value={accountInfo?.timeZone}
                        onChange={(e) => {
                          setAccountInfo((prev) => ({
                            ...prev,
                            timeZone: e.target.value,
                          }));
                        }}
                        className="w-full outline-none p-2"
                      >
                        {options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div> */}
                  </div>
                  <h3 className="text-md font-semibold mb-1 mt-6">
                    Organization
                  </h3>
                  <p className="text-xs text-gray-500 mb-4">
                    Your organization information is used to identify your
                    company.
                  </p>

                  {/* {isCompanyImageModal && (
                    <CompanyImageModal
                      setShow={setIsCompanyImageModal}
                      setAccountInfo={setAccountInfo}
                      accountInfo={accountInfo}
                    />
                  )} */}
                  {/* <label
                    // htmlFor="organizationLogo"
                    className="text-xs text-gray-600 cursor-pointer"
                    onClick={() => {
                      setIsCompanyImageModal(true);
                    }}
                  >
                    <div className="text-xs mb-2">Organization Logo</div>
                    <div className="h-[150px] w-[150px] border rounded-sm flex items-center justify-center mb-2">
                      {accountInfo?.orgLogo && (
                        <img
                          src={accountInfo?.orgLogo}
                          className="h-max-full w-max-full"
                          alt=""
                        />
                      )}
                      {accountInfo?.orgLogo.name && (
                        <p>{accountInfo?.orgLogo.name}</p>
                      )}
                    </div>
                  </label>*/}

                  <label className="text-xs text-gray-600">
                    Organization Name
                  </label>
                  <input
                    className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none text-gray-700"
                    type="text"
                    placeholder="Organization Name"
                    value={accountInfo?.org}
                    onChange={(e) =>
                      setAccountInfo({ ...accountInfo, org: e.target.value })
                    }
                  />

                  <label className="text-xs text-gray-600">
                    Organization Size
                  </label>
                  <Select
                    value={accountInfo?.orgSize}
                    onValueChange={(value) => {
                      setAccountInfo({
                        ...accountInfo,
                        orgSize: value,
                      });
                    }}
                  >
                    <SelectTrigger className="w-full text-sm mt-1">
                      <SelectValue placeholder="No of employees" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="select">Select</SelectItem>
                        <SelectItem value="1-10">1-10 Employees</SelectItem>
                        <SelectItem value="11-50">11-50 Employees</SelectItem>
                        <SelectItem value="51-100">51-100 Employees</SelectItem>
                        <SelectItem value="101-500">
                          101-500 Employees
                        </SelectItem>
                        <SelectItem value="501-1000">
                          501-1000 Employees
                        </SelectItem>
                        <SelectItem value="1001-5000">
                          1001-5000 Employees
                        </SelectItem>
                        <SelectItem value="5000+">5000+ Employees</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {/* <div className="mb-2 mt-1 text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                    <select
                      value={accountInfo?.orgSize}
                      onChange={(e) =>
                        setAccountInfo({
                          ...accountInfo,
                          orgSize: e.target.value,
                        })
                      }
                      className="w-full outline-none p-2"
                    >
                      <option value="select">Select</option>
                      <option value="1-10">1-10 Employees</option>
                      <option value="11-50">11-50 Employees</option>
                      <option value="51-100">51-100 Employees</option>
                      <option value="101-500">101-500 Employees</option>
                      <option value="501-1000">501-1000 Employees</option>
                      <option value="1001-5000">1001-5000 Employees</option>
                      <option value="5000+">5000+ Employees</option>
                    </select>
                  </div> */}

                  <label className="text-xs text-gray-600">
                    Organization Industry
                  </label>
                  <Select
                    value={accountInfo?.orgIndustry}
                    onValueChange={(value) => {
                      setAccountInfo({
                        ...accountInfo,
                        orgIndustry: value,
                      });
                    }}
                  >
                    <SelectTrigger className="w-full text-sm mt-1">
                      <SelectValue placeholder="Select your industry" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="select">Select</SelectItem>
                        <SelectItem value="Accounting & Tax">
                          Accounting & Tax
                        </SelectItem>
                        <SelectItem value="Business Services / Consulting">
                          Business Services / Consulting
                        </SelectItem>
                        <SelectItem value="Construction">
                          Construction
                        </SelectItem>
                        <SelectItem value="Education">Education</SelectItem>
                        <SelectItem value="Financial Services">
                          Financial Services
                        </SelectItem>
                        <SelectItem value="Government">Government</SelectItem>
                        <SelectItem value="Healthcare - Health Plans & Payers">
                          Healthcare - Health Plans & Payers
                        </SelectItem>
                        <SelectItem value="Healthcare - Providers">
                          Healthcare - Providers
                        </SelectItem>
                        <SelectItem value="Insurance">Insurance</SelectItem>
                        <SelectItem value="Legal">Legal</SelectItem>
                        <SelectItem value="Life Sciences">
                          Life Sciences
                        </SelectItem>
                        <SelectItem value="Manufacturing">
                          Manufacturing
                        </SelectItem>
                        <SelectItem value="Mortgage">Mortgage</SelectItem>
                        <SelectItem value="Not For Profit">
                          Not For Profit
                        </SelectItem>
                        <SelectItem value="Real Estate - Commercial">
                          Real Estate - Commercial
                        </SelectItem>
                        <SelectItem value="Real Estate - Residential">
                          Real Estate - Residential
                        </SelectItem>
                        <SelectItem value="Retail">Retail</SelectItem>
                        <SelectItem value="Student">Student</SelectItem>
                        <SelectItem value="Technology">Technology</SelectItem>
                        <SelectItem value="Other">Other</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {/* <div className="mb-2 mt-1  text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                    <select
                      value={accountInfo?.orgIndustry}
                      onChange={(e) =>
                        setAccountInfo({
                          ...accountInfo,
                          orgIndustry: e.target.value,
                        })
                      }
                      className="w-full outline-none p-2"
                    >
                      <option value="select">Select</option>
                      <option value="Accounting & Tax">Accounting & Tax</option>
                      <option value="Business Services / Consulting">
                        Business Services / Consulting
                      </option>
                      <option value="Construction">Construction</option>
                      <option value="Education">Education</option>
                      <option value="Financial Services">
                        Financial Services
                      </option>
                      <option value="Government">Government</option>
                      <option value="Healthcare - Health Plans & Payers">
                        Healthcare - Health Plans & Payers
                      </option>
                      <option value="Healthcare - Providers">
                        Healthcare - Providers
                      </option>
                      <option value="Insurance">Insurance</option>
                      <option value="Legal">Legal</option>
                      <option value="Life Sciences">Life Sciences</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Mortgage">Mortgage</option>
                      <option value="Not For Profit">Not For Profit</option>
                      <option value="Real Estate - Commercial">
                        Real Estate - Commercial
                      </option>
                      <option value="Real Estate - Residential">
                        Real Estate - Residential
                      </option>
                      <option value="Retail">Retail</option>
                      <option value="Student">Student</option>
                      <option value="Technology">Technology</option>
                      <option value="Other">Other</option>
                    </select>
                  </div> */}
                </div>
              </div>

              <div className="max-w-[600px] w-[100%] md:w-[80%]">
                <div className="flex flex-row justify-end">
                  <BoloButton
                    size={"sm"}
                    disabled={
                      !workSpaceDetails.isWorkSpaceOwner &&
                      workSpaceDetails.currentWorkSpaceRole !== "admin"
                    }
                    onClick={updateAccountInfo}
                  >
                    Save
                  </BoloButton>
                </div>
              </div>

              {shouldShowMigrationDiv(
                ownerPlanDetails?.isLifeTime,
                ownerLastPlanHistoryCreatedAt
              ) && (
                <>
                  <div className={`max-w-[600px] w-[100%] md:w-[80%]   `}>
                    {/* email migration portion */}
                    <h3 className="text-md font-semibold mb-1 mt-6">
                      Email Migration
                    </h3>
                    <p className="text-xs text-gray-500 mb-4">
                      Migrate your account to a new email address.
                    </p>

                    <div className="text-xs font-medium text-gray-700 flex flex-col gap-2 bg-red-100 border border-red-500 rounded-md w-full p-2 mb-4">
                      <span className="font-semibold flex gap-2 items-center">
                        <TiWarningOutline size={20} className="text-red-600" />
                        Note:
                      </span>
                      <ul className=" list-disc	ml-4">
                        <li className="my-1">
                          The new email you are migrating to should be a new
                          account that was not logged in with BoloForms before!{" "}
                        </li>
                        <li className="my-1">
                          You might need to re-do all of your integrations!
                        </li>
                        <li className="my-1">
                          You will be logged out of your current account and can
                          log back in with the new email.
                        </li>
                        <li className="text-red-400 my-1">
                          All of your documents, templates, forms, settings,
                          contacts and your billing quotas will be migrated to
                          the new email.
                        </li>
                      </ul>
                    </div>
                    <label className="text-xs text-gray-600">
                      Your Current Email
                    </label>
                    <input
                      className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none text-gray-700 disabled:opacity-70"
                      type="text"
                      placeholder="Current Email"
                      value={owner?.email}
                      onChange={(e) => {
                        return;
                      }}
                      disabled
                    />
                    <label className="text-xs text-gray-600">
                      Migrate to New Email
                    </label>
                    <input
                      className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none text-gray-700 disabled:opacity-70"
                      type="text"
                      placeholder="Enter New Email"
                      value={migrateToEmailInput}
                      onChange={(e) => setMigrateToEmailInput(e.target.value)}
                      disabled={otpSent || migrating || sendingMigrateOtp}
                    />
                    {otpSent && (
                      <>
                        <label className="text-xs text-gray-600">
                          Enter OTP sent to new email
                        </label>
                        {/* <input
                          className="text-xs md:text-sm mt-1 mb-2 w-full border border-gray-300 rounded-md px-3 py-2 outline-none text-gray-700 disabled:opacity-70"
                          type="text"
                          placeholder="Enter OTP"
                          value={migrateOtpInput}
                          onChange={(e) => {
                            // accept only numbers
                            if (
                              /^\d+$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setMigrateOtpInput(e.target.value);
                            }
                          }}
                          disabled={!otpSent || otpExpiresIn <= 0}
                        /> */}
                        <BoloInputOTP
                          className="text-sm"
                          maxLength={6}
                          minLength={6}
                          value={migrateOtpInput}
                          onChange={(value) => {
                            if (/^\d+$/.test(value) || value === "") {
                              setMigrateOtpInput(value);
                            }
                          }}
                        >
                          <BoloInputOTPGroup>
                            <BoloInputOTPSlot index={0} variant="primary" />
                            <BoloInputOTPSlot index={1} variant="primary" />
                            <BoloInputOTPSlot index={2} variant="primary" />
                          </BoloInputOTPGroup>
                          <BoloInputOTPSeparator />
                          <BoloInputOTPGroup>
                            <BoloInputOTPSlot index={3} variant="primary" />
                            <BoloInputOTPSlot index={4} variant="primary" />
                            <BoloInputOTPSlot index={5} variant="primary" />
                          </BoloInputOTPGroup>
                        </BoloInputOTP>
                      </>
                    )}
                  </div>
                  <div className={`max-w-[600px] w-[100%] md:w-[80%]  pb-16`}>
                    <div className="flex flex-row justify-between gap-1 items-center">
                      {
                        <p className="w-full">
                          {otpSent && (
                            <span className="text-xs text-gray-400">
                              OTP sent to new email. Expires in{" "}
                              <span className="font-semibold">
                                {otpExpiresIn}{" "}
                              </span>{" "}
                              seconds
                            </span>
                          )}
                        </p>
                      }
                      <BoloButton
                        size={"sm"}
                        disabled={
                          (!workSpaceDetails.isWorkSpaceOwner &&
                            workSpaceDetails.currentWorkSpaceRole !==
                              "admin") ||
                          migrating ||
                          sendingMigrateOtp
                        }
                        className="whitespace-nowrap"
                        // className="text-xs text-white bg-primary px-4 py-2 rounded-md disabled:opacity-50 ml-auto w-fit whitespace-nowrap"

                        onClick={
                          !otpSent ? sendMigrateOtp : migrateToNewEmailHandler
                        }
                      >
                        {!otpSent && !migrating
                          ? sendingMigrateOtp
                            ? "Sending OTP"
                            : "Send OTP"
                          : migrating
                          ? "Migrating.."
                          : "Verify & migrate now"}
                      </BoloButton>
                    </div>
                    {
                      <p>
                        {loggingOut && (
                          <span className="text-xs text-gray-400 animate-pulse">
                            {" "}
                            Logging out..{" "}
                          </span>
                        )}
                      </p>
                    }
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {settingsType === "DEFAULTSETTINGS" && (
          <>
            <div className="flex flex-col md:mx-4 mb-4">
              <h3 className="text-md font-semibold mb-1">Defaults</h3>
              <p className="text-xs text-gray-500 mb-4">
                Set your defaults to save time
              </p>
              <BoloTabSwitcher
                value={searchParams.get("subType") || "USER_DEFAULTS"}
                onChangeTab={(tab) => {
                  searchParams.set("subType", tab);
                  setSearchParams(searchParams);
                }}
              >
                <BoloTab value="USER_DEFAULTS" size={"sm"}>
                  {" "}
                  User Defaults
                </BoloTab>
                <BoloTab value="WORKSPACE_DEFAULTS" size={"sm"}>
                  Workspace Defaults
                </BoloTab>
              </BoloTabSwitcher>
              {/* <div className="flex">
                <div
                  className={`text-xs ${
                    (searchParams.get("subType") === "USER_DEFAULTS" ||
                      !searchParams.get("subType")) &&
                    "border-b-2"
                  } py-1.5 md:py-2 px-4 border-primary cursor-pointer whitespace-nowrap`}
                  onClick={() => {
                    searchParams.set("subType", "USER_DEFAULTS");
                    setSearchParams(searchParams);
                  }}
                >
                  User Defaults
                </div>
                <div
                  className={`text-xs ${
                    searchParams.get("subType") === "WORKSPACE_DEFAULTS" &&
                    "border-b-2"
                  } py-1.5 md:py-2 px-4 border-primary cursor-pointer whitespace-nowrap`}
                  onClick={() => {
                    searchParams.set("subType", "WORKSPACE_DEFAULTS");
                    setSearchParams(searchParams);
                  }}
                >
                  Workspace Defaults
                </div>
              </div> */}
            </div>
            {(!searchParams.get("subType") ||
              searchParams.get("subType") === "USER_DEFAULTS") && (
              <div className="flex flex-col md:mx-4 gap-4 pb-8">
                {activeTab === "SIGNING" && (
                  <>
                    <div className="">
                      <h3 className="text-md font-semibold mb-1">
                        Default Signature
                      </h3>
                      <p className="text-xs text-gray-500 mb-4">
                        A default signature will be used for all documents you
                        sign.
                      </p>
                      {uploading && (
                        <p className="py-2 text-gray-400 animate-pulse">
                          {" "}
                          Uploading..{" "}
                        </p>
                      )}
                      {owner?.defaultSignature && !uploading && (
                        <div className="mt-1 mb-2 border border-gray-300 rounded-md px-3 py-2 outline-none max-w-[600px] w-[100%] md:w-[80%] flex items-center justify-center h-[200px]">
                          <img
                            className="rounded-md max-h-full max-w-full"
                            src={`${owner?.defaultSignature}`}
                            alt="My Signature"
                          />
                        </div>
                      )}
                      <button
                        disabled={
                          !workSpaceDetails.isWorkSpaceOwner &&
                          workSpaceDetails.currentWorkSpaceRole !== "admin"
                        }
                        className="mt-1 mb-2 text-xs md:text-sm rounded-md px-3 py-2 outline-none text-primary font-medium  max-w-[600px] w-[100%] md:w-[80%] bg-secondary disabled:opacity-50"
                        onClick={() => {
                          new MixPanel().track("default", {
                            default_action: "default_signature",
                            paid: false,
                          });
                          new MixPanel().increment("default_signature");
                          setShowSignatureModal(true);
                        }}
                      >
                        {owner?.defaultSignature
                          ? "Update Signature"
                          : "Add Signature"}
                      </button>{" "}
                    </div>
                    <div className="">
                      <h3 className="text-md font-semibold mb-1">
                        Default Initials
                      </h3>
                      <p className="text-xs text-gray-500 mb-4">
                        These default initals will be used for all documents you
                        fill.
                      </p>
                      {uploadingInitial && (
                        <p className="py-2 text-gray-400 animate-pulse">
                          {" "}
                          Uploading..{" "}
                        </p>
                      )}
                      {owner?.defaultInitial && !uploadingInitial && (
                        <div className="mt-1 mb-2 border border-gray-300 rounded-md px-3 py-2 outline-none max-w-[600px] w-[100%] md:w-[80%] flex items-center justify-center h-[200px]">
                          <img
                            className="rounded-md max-h-full max-w-full"
                            src={`${owner?.defaultInitial}`}
                            alt="My Stamp"
                          />
                        </div>
                      )}
                      <button
                        disabled={
                          !workSpaceDetails.isWorkSpaceOwner &&
                          workSpaceDetails.currentWorkSpaceRole !== "admin"
                        }
                        className="mt-1 mb-2 text-xs md:text-sm rounded-md px-3 py-2 outline-none text-primary font-medium  max-w-[600px] w-[100%] md:w-[80%] bg-secondary disabled:opacity-50"
                        onClick={() => {
                          new MixPanel().track("default", {
                            default_action: "default_initials",
                            paid: false,
                          });
                          new MixPanel().increment("default_initials");
                          setShowInitialModal(true);
                        }}
                      >
                        {owner?.defaultInitial
                          ? "Update Initial"
                          : "Add Initial"}
                      </button>{" "}
                    </div>
                    <div className="">
                      <h3 className="text-md font-semibold mb-1">
                        Default Stamp
                      </h3>
                      <p className="text-xs text-gray-500 mb-4">
                        A default stamp will be used for all documents you fill.
                      </p>
                      {uploadingStamp && (
                        <p className="py-2 text-gray-400 animate-pulse">
                          {" "}
                          Uploading..{" "}
                        </p>
                      )}
                      {owner?.defaultStamp && !uploadingStamp && (
                        <div className="mt-1 mb-2 border border-gray-300 rounded-md px-3 py-2 outline-none max-w-[600px] w-[100%] md:w-[80%] flex items-center justify-center h-[200px]">
                          <img
                            className="rounded-md max-h-full max-w-full"
                            src={`${owner?.defaultStamp}`}
                            alt="My Stamp"
                          />
                        </div>
                      )}
                      <button
                        disabled={
                          !workSpaceDetails.isWorkSpaceOwner &&
                          workSpaceDetails.currentWorkSpaceRole !== "admin"
                        }
                        className="mt-1 mb-2 text-xs md:text-sm rounded-md px-3 py-2 outline-none text-primary font-medium  max-w-[600px] w-[100%] md:w-[80%] bg-secondary disabled:opacity-50"
                        onClick={() => {
                          new MixPanel().track("default", {
                            default_action: "default_stamp",
                            paid: false,
                          });
                          new MixPanel().increment("default_stamp");
                          setShowStampModal(true);
                        }}
                      >
                        {owner?.defaultStamp ? "Update Stamp" : "Add Stamp"}
                      </button>{" "}
                    </div>
                  </>
                )}
              </div>
            )}
            {searchParams.get("subType") === "WORKSPACE_DEFAULTS" && (
              <WorkSpaceDefaults />
            )}
          </>
        )}
        {settingsType === "BRANDING" && (
          <div className="flex flex-col md:mx-4 gap-4">
            {brandingPreview && (
              <BrandingPreview
                accountInfo={accountInfo}
                show={brandingPreview}
                setShow={setBrandingPreview}
                currentBrandingTab={currentBrandingTab}
              />
            )}
            <div className="">
              <div className="max-w-[600px] md:w-[80%]">
                <h3 className="text-md font-semibold mb-1">Branding</h3>
                <p className="text-xs text-gray-500 mb-4">
                  This information will be used in branding your emails and
                  default pdf.{" "}
                  <button
                    className="cursor-pointer text-primary font-semibold"
                    onClick={() => {
                      setBrandingPreview(true);
                    }}
                  >
                    Preview here
                  </button>
                </p>
                <div className="flex w-full text-sm mb-4">
                  <BoloTabSwitcher
                    value={currentBrandingTab}
                    onChangeTab={(tab) => {
                      setCurrentBrandingTab(tab);
                    }}
                  >
                    <BoloTab value="EMAIL" size={"sm"}>
                      Email
                    </BoloTab>
                    <BoloTab value="PDF" size={"sm"}>
                      Watermark
                    </BoloTab>
                    <BoloTab value="LANGUAGE" size={"sm"}>
                      Language
                    </BoloTab>
                  </BoloTabSwitcher>
                  {/* <div
                    className="py-2 px-4 cursor-pointer "
                    style={{
                      borderBottom:
                        currentBrandingTab == "EMAIL"
                          ? `solid 2px ${primaryColor}`
                          : "",
                    }}
                    onClick={() => {
                      setCurrentBrandingTab("EMAIL");
                    }}
                  >
                    Email
                  </div>
                  <div
                    className="py-2 px-4 cursor-pointer "
                    style={{
                      borderBottom:
                        currentBrandingTab == "PDF"
                          ? `solid 2px ${primaryColor}`
                          : "",
                    }}
                    onClick={() => {
                      setCurrentBrandingTab("PDF");
                    }}
                  >
                    WaterMark
                  </div>
                  <div
                    className="py-2 px-4 cursor-pointer "
                    style={{
                      borderBottom:
                        currentBrandingTab == "LANGUAGE"
                          ? `solid 2px ${primaryColor}`
                          : "",
                    }}
                    onClick={() => {
                      setCurrentBrandingTab("LANGUAGE");
                    }}
                  >
                    Language
                  </div> */}
                </div>
                {currentBrandingTab == "PDF" && (
                  <label
                    // htmlFor="organizationLogo"
                    className="text-xs text-gray-600 cursor-pointer"
                  >
                    <div className="text-xs mb-2">WaterMark</div>
                    <div className="relative h-[150px] w-[150px]">
                      <MdClose
                        className="absolute top-1 right-1 z-10"
                        size={20}
                        onClick={async () => {
                          if (!isWaterMarkBranding) {
                            toast.error("You can't delete default watermark");
                            return;
                          }
                          setAccountInfo((prev) => ({
                            ...prev,
                            defaultWaterMark: IMAGE_LINKS?.WHITE_RECT,
                          }));
                          setDeleteWaterMark(true);
                        }}
                      />
                      <div
                        className="h-[150px] w-[150px] border rounded-sm flex items-center justify-center mb-2"
                        onClick={async () => {
                          setCompanyModalData({
                            text: "WaterMark",
                            accountKey: "defaultWaterMark",
                          });
                          setIsCompanyImageModal(true);
                        }}
                      >
                        {/* first check based on name tells us if local file is uploaded*/}
                        {/* if local file uploaded we show the file as we want to show the preview */}
                        {/* if not local file means link from db */}
                        {/* regardless of link if emaail branding is off we show default image */}
                        {accountInfo?.defaultWaterMark?.name ? (
                          waterMarkURLGlobal ? (
                            <img
                              src={waterMarkURLGlobal}
                              className="h-max-full w-max-full"
                              alt=""
                            />
                          ) : (
                            <p>{accountInfo?.defaultWaterMark?.name}</p>
                          )
                        ) : (
                          <img
                            data-tooltip-id="branding-watermark"
                            src={
                              // isWaterMarkBranding &&
                              // accountInfo?.defaultWaterMark
                              //   ? accountInfo?.defaultWaterMark
                              //   : whiteLabelInfo?.settings?.organization
                              //       ?.logo || IMAGE_LINKS?.BOLOLOGO
                              isWaterMarkBranding &&
                              accountInfo?.defaultWaterMark
                                ? accountInfo?.defaultWaterMark
                                : IMAGE_LINKS?.WHITE_RECT
                            }
                            className="h-max-full w-max-full"
                            alt=""
                          />
                        )}
                        {/* {accountInfo?.defaultWaterMark && (
                        <img
                          src={accountInfo?.defaultWaterMark}
                          className="h-max-full w-max-full"
                          alt=""
                        />
                      )}
                      {accountInfo?.defaultWaterMark?.name && (
                        <p>{accountInfo?.defaultWaterMark?.name}</p>
                      )} */}
                        <CustomTooltip
                          helpers={{
                            id: "branding-watermark",
                            place: "top",
                            content: "Click to change watermark",
                          }}
                        />
                      </div>
                    </div>
                  </label>
                )}
                {isCompanyImageModal && (
                  <CompanyImageModal
                    setShow={setIsCompanyImageModal}
                    setAccountInfo={setAccountInfo}
                    accountInfo={accountInfo}
                    accountKey={compnayModalData.accountKey || "orgLogo"}
                    text={compnayModalData.text || "Company Logo"}
                    setDeleteLogo={setDeleteLogo}
                    setDeleteWaterMark={setDeleteWaterMark}
                  />
                )}
                {currentBrandingTab == "EMAIL" && (
                  <>
                    <label
                      // htmlFor="organizationLogo"
                      className="text-xs text-gray-600 cursor-pointer"
                    >
                      <div className="text-xs mb-2 mt-2">Organization Logo</div>
                      <div className="relative h-[150px] w-[150px]">
                        <MdClose
                          className="absolute top-1 right-1 z-10"
                          size={20}
                          onClick={() => {
                            setAccountInfo((prev) => ({
                              ...prev,
                              orgLogo: isEmailBranding
                                ? IMAGE_LINKS.INVISIBLE_IMAGE
                                : whiteLabelInfo?.settings?.organization
                                    ?.logo || IMAGE_LINKS.BOLOLOGO,
                            }));
                            setDeleteLogo(true);
                          }}
                        />
                        <div
                          className="h-[150px] w-[150px] border rounded-sm flex items-center justify-center mb-2"
                          onClick={async () => {
                            setCompanyModalData({
                              text: "Company Logo",
                              accountKey: "orgLogo",
                            });
                            setIsCompanyImageModal(true);
                          }}
                        >
                          {/* first check based on name tells us if local file is uploaded*/}
                          {/* if local file uploaded we show the file as we want to show the preview */}
                          {/* if not local file means link from db */}
                          {/* regardless of link if emaail branding is off we show default image */}
                          {accountInfo?.orgLogo?.name ? (
                            logoURLGlobal ? (
                              <img
                                src={logoURLGlobal}
                                className="h-max-full w-max-full"
                                alt=""
                              />
                            ) : (
                              <p>{accountInfo?.orgLogo?.name}</p>
                            )
                          ) : (
                            <img
                              src={
                                isEmailBranding && accountInfo?.orgLogo
                                  ? accountInfo?.orgLogo ||
                                    IMAGE_LINKS.INVISIBLE_IMAGE
                                  : whiteLabelInfo?.settings?.organization
                                      ?.logo || IMAGE_LINKS.BOLOLOGO
                              }
                              className="h-max-full w-max-full"
                              alt=""
                            />
                          )}
                          {/* {accountInfo?.orgLogo && (
                        <img
                          src={accountInfo?.orgLogo}
                          className="h-max-full w-max-full"
                          alt=""
                        />
                      )}
                      {accountInfo?.orgLogo.name && <p>{}</p>} */}
                        </div>
                      </div>
                    </label>

                    <div className="flex flex-col">
                      <label className="text-xs text-gray-600 mt-2">
                        Organization Theme {}
                      </label>
                      <div className="flex gap-2">
                        <input
                          className="mt-1 h-8 w-[150px]"
                          type="color"
                          value={
                            isEmailBranding
                              ? accountInfo?.theme || primaryColor
                              : primaryColor
                          }
                          onChange={async (e) => {
                            setAccountInfo({
                              ...accountInfo,
                              theme: e.target.value,
                            });
                          }}
                        />
                        <button
                          className="mt-1 px-3 py-1 rounded-md flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
                          onClick={async () => {
                            await setAccountInfo({
                              ...accountInfo,
                              theme: "#000000",
                            });
                            setAccountInfo({
                              ...accountInfo,
                              theme: "",
                            });
                          }}
                        >
                          <MdClose />
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {currentBrandingTab == "LANGUAGE" && (
                  <div className="flex flex-col md:w-[50%]">
                    <p className="text-gray-600 text-xs">
                      Select your language.
                    </p>
                    <Select
                      value={accountInfo?.selectedLanguage}
                      onValueChange={(value) => {
                        setAccountInfo((prev) => ({
                          ...prev,
                          selectedLanguage: value,
                        }));
                      }}
                    >
                      <SelectTrigger className="w-full text-sm mt-1">
                        <SelectValue placeholder="Select your department" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {/* <SelectItem value="select">Select</SelectItem> */}
                          <SelectItem value="ar">Arabic</SelectItem>
                          <SelectItem value="bg">Bulgarian</SelectItem>
                          <SelectItem value="zh-CN">
                            Chinese (Simplified)
                          </SelectItem>
                          <SelectItem value="cs">Czech</SelectItem>
                          <SelectItem value="da">Danish</SelectItem>
                          <SelectItem value="nl">Dutch</SelectItem>
                          <SelectItem value="en">English</SelectItem>
                          <SelectItem value="fr">French</SelectItem>
                          <SelectItem value="de">German</SelectItem>
                          <SelectItem value="iw">Hebrew</SelectItem>
                          <SelectItem value="id">Indonesian</SelectItem>
                          <SelectItem value="it">Italian</SelectItem>
                          <SelectItem value="ja">Japanese</SelectItem>
                          <SelectItem value="ko">Korean</SelectItem>
                          <SelectItem value="pt">Portuguese</SelectItem>
                          <SelectItem value="ru">Russian</SelectItem>
                          <SelectItem value="es">Spanish</SelectItem>
                          <SelectItem value="th">Thai</SelectItem>
                          <SelectItem value="tr">Turkish</SelectItem>
                          <SelectItem value="uk">Ukrainian</SelectItem>
                          <SelectItem value="vi">Vietnamese</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {/* <div className="mt-2 text-xs md:text-sm text-gray-700 bg-white border border-gray-300 rounded-md pr-4 overflow-hidden">
                      <select
                        value={accountInfo?.selectedLanguage}
                        onChange={(e) => {
                          setAccountInfo((prev) => ({
                            ...prev,
                            selectedLanguage: e.target.value,
                          }));
                          // setSelectedLanguage(e.target.value);
                        }}
                        className="w-full outline-none p-2 cursor-pointer"
                      >
                        <option className="hidden" value="">
                          Select Language
                        </option>
                        <option value="ar">Arabic</option>
                        <option value="bg">Bulgarian</option>
                        <option value="zh-CN">Chinese (Simplified)</option>
                        <option value="cs">Czech</option>
                        <option value="da">Danish</option>
                        <option value="nl">Dutch</option>
                        <option value="en">English</option>
                        <option value="fr">French</option>
                        <option value="de">German</option>
                        <option value="iw">Hebrew</option>
                        <option value="id">Indonesian</option>
                        <option value="it">Italian</option>
                        <option value="ja">Japanese</option>
                        <option value="ko">Korean</option>
                        <option value="pt">Portuguese</option>
                        <option value="ru">Russian</option>
                        <option value="es">Spanish</option>
                        <option value="th">Thai</option>
                        <option value="tr">Turkish</option>
                        <option value="uk">Ukrainian</option>
                        <option value="vi">Vietnamese</option>
                      </select>
                    </div> */}
                  </div>
                )}
                <div className="flex flex-row justify-end gap-2 mt-4">
                  <div className="flex gap-2">
                    <BoloButton
                      size={"sm"}
                      disabled={
                        !workSpaceDetails.isWorkSpaceOwner &&
                        workSpaceDetails.currentWorkSpaceRole !== "admin"
                      }
                      onClick={updateBrandingInfo}
                      // className="text-xs text-white bg-primary px-4 py-2 rounded-md disabled:opacity-50"
                    >
                      Save
                    </BoloButton>
                  </div>
                </div>
                {/* <CName /> */}
              </div>
            </div>
          </div>
        )}

        {settingsType === "WORKSPACES" && (
          <div className="px-0 md:px-4 flex flex-col">
            <div>
              <h3 className="text-md font-semibold mb-1">WorkSpaces</h3>
              <p className="text-xs text-gray-500 mb-4">
                Allows you to organize branding and files for seperate
                organizations.
              </p>
            </div>
            <SwitchWorkSpacesComponent />
          </div>
        )}

        {settingsType === "INTEGRATIONS" && (
          <div className=" w-full md:w-[80%] md:mx-4 flex flex-col gap-4 pb-16">
            <h3 className="text-md font-semibold">Automation</h3>

            <div className="flex flex-wrap gap-4">
              {integrations.map((integration, index) => {
                return (
                  <IntegrationCard
                    integration={integration}
                    index={index}
                    key={index}
                  />
                );
              })}
            </div>
            <h3 className="text-md font-semibold ">Sales and CRM</h3>

            <div className="flex flex-wrap gap-4">
              {integrationWithTokens.map((integration, index) => {
                return (
                  <IntegrationWithTokenCard
                    integration={integration}
                    index={index}
                    owner={owner}
                    setShowUpgradeModal={setShowUpgradeModal}
                    setUpgradeModalType={setUpgradeModalType}
                  />
                );
              })}
            </div>
            <PaymentGatewayApps
              setShowUpgradeModal={setShowUpgradeModal}
              setUpgradeModalType={setUpgradeModalType}
            />
          </div>
        )}
        {settingsType === "CNAME" && <CName />}
        {settingsType === "EMBED" && <Embed />}
        {settingsType === "SECURITY" && <Security />}
        {settingsType === "DEV_SEC" && <DeveloperSection />}
      </div>

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
      <Tooltip id="preview-tooltip">click to see preview</Tooltip>
      {/* {showGenerateKeyModal && (
        <Modal modalContent={generateKeyModalContent()} />
      )} */}
      {showWatermarkPreview && <Modal modalContent={WatermarkPreviewModal()} />}
      {/* {showKeyActionsModal && <Modal modalContent={keyActionsModalContent()} />} */}
      {showCopyKeyModal && <Modal modalContent={copyKeyModalContent()} />}
      {showSignatureModal && (
        <div className="top-0 left-0 flex items-center justify-center fixed z-50 bg-black bg-opacity-40 h-[100vh] w-[100vw]">
          <SignatureTemplateModal
            dismiss={() => {
              setShowSignatureModal(false);
            }}
            open={showSignatureModal}
            confirm={handleAddDefaultSignature}
            isDefaultSignatureTemplate={true}
            signatureSettingType={{ image: true, drawn: true, typing: true }}
          />
        </div>
      )}
      {showInitialModal && (
        <div className="top-0 left-0 flex items-center justify-center fixed z-50 bg-black bg-opacity-40 h-[100vh] w-[100vw]">
          <SignatureTemplateModal
            dismiss={() => {
              setShowInitialModal(false);
            }}
            open={showInitialModal}
            confirm={handleAddDefaultInitial}
            isDefaultSignatureTemplate={true}
            imageType="initial"
            signatureSettingType={{ image: true, drawn: true, typing: true }}
          />
        </div>
      )}
      {showStampModal && (
        <div className="top-0 left-0 flex items-center justify-center fixed z-50 bg-black bg-opacity-40 h-[100vh] w-[100vw]">
          <SignatureTemplateModal
            dismiss={() => {
              setShowStampModal(false);
            }}
            open={showStampModal}
            confirm={handleAddDefaultStamp}
            isDefaultSignatureTemplate={true}
            imageType="stamp"
            isStamp={true}
            signatureSettingType={{ image: true, drawn: true, typing: true }}
          />
        </div>
      )}
      {showExtendedTrialModal && (
        <TrialExtendedModal
          setShow={() => {
            setShowExtendedTrialModal(false);
          }}
          show={showExtendedTrialModal}
          completedTask={{
            title: "Custom branding",
            description: "You have created a form",
            increasedDays: 1,
          }}
        />
      )}
    </div>
  );
};

export default Settings;
