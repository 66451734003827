import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import * as Sentry from "@sentry/react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import DocumentSigner from "./pages/Document-Signer";
import EditForm from "./pages/EditForm";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ResponsePdfViewer from "./pages/ResponsePdfViewer";
import SignatureSuccess from "./pages/SignatureSuccess";
import SignatureExpired from "./pages/SignatureExpired";
// import PdfGenerator from "./pages/PdfGenerator";
import DocumentTemplateSigner from "./pages/Document-Template-Signer";
import { setIntercom } from "./utils/services/intercom";
import { setProfitWell } from "./utils/services/profitwell";
import MixPanel from "./utils/services/mixpanel";
import DesignTemplate from "./pages/DesignTemplate";
import MapSignature from "./pages/MapSignatures";
import PDFTemplate from "./pages/PdfTemplate";
import PdfGenerator from "./pages/PdfToForm";
import EditTemplateRoles from "./pages/EditTemplateRoles";
import Home from "./pages/Home";
import VerifyPdf from "./pages/VerifyPdf";
import MailInvite from "./pages/MailInvite";
import { ErrorBoundary } from "react-error-boundary";
import { BoloButton } from "./components/Common/Button/BoloButton";
import { getWhiteLabelInfoLocal, logOut } from "./utils";
import DocumentUrl from "./pages/DocumentUrl";
import { useAppDispatch, useAppSelector } from "./redux/store";
import { getWhiteLabelInfoThunk } from "./redux/slices/whiteLabelSlice";
import WhiteLabelAdminDashboard from "./pages/WhiteLabel/WhiteLabelAdminDashboard/WhiteLabelAdminDashboard";
import WhiteLabelLogin from "./pages/WhiteLabel/WhiteLabelLogin/WhiteLabelLogin";
import OpenSecureLink from "./pages/OpenSecureLink";
import CreateTemplate from "./pages/CreateTemplate";
import ETemplateSetup from "./pages/ETemplate";
import ReplacePdf from "./pages/ReplacePdf";
import WhiteLabelOTPLogin from "./pages/WhiteLabel/WhiteLabelOTPLogin/WhiteLabelOTPLogin";
import Login2FA from "./pages/Login/Login2FA";
import { getIpAddressRemote } from "./hooks/reduxHooks/useIpHook";
import Atlas from "./pages/Atlas/Atlas";
import { ENV } from "./constants/constants";
import { genThemeFromHex, hexToHSL, setCSSTheme } from "./utils/uiUtils";
import {
  COLORS,
  EMBED_ALLOWED_PAGES,
  INTERCOM_DISALLOWED_PAGES,
} from "./constants/common";
import EmbedLogin from "./pages/EmbedLogin/EmbedLogin";
import { getEmbedHome, isEmbeded } from "./utils/embed";
import { toast } from "react-toastify";
import { getOwnerThunk } from "./redux/slices/ownerSlice";
import MailInviteApproval from "./pages/MailInviteApproval";
import DocumentTemplateApprover from "./pages/Document-Template-Approver";
import ApproverSuccess from "./pages/ApproverSuccess";
import Tracking from "./utils/tracking";
import HubSpotPDfDownload from "./pages/HubSpotPdfDownload";
import { postMessageParser } from "./utils/global";
import ExtensionImportPDF from "./pages/Extension/ImportPDF";
// import { setBrevo } from "./utils/brevo";
// import PhoneCollectModal from "./components/PhoneCollectModal";

function App() {
  let location = useLocation();
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const owner = useAppSelector((state) => state.root.ownerSlice?.data?.owner);

  function ErrorFallback({ error, resetErrorBoundary }: any) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
      <div className="h-[100svh] w-full flex flex-col items-center justify-center gap-4">
        <h1 className="text-xl font-semibold">Something went wrong</h1>
        <BoloButton variant={"primary"} size={"sm"} onClick={() => logOut()}>
          Logout
        </BoloButton>
      </div>
    );
  }

  const logError = (error: Error, info: { componentStack: string }) => {
    console.log("Error Boundary called --------------------------");
    console.log(error);
    console.log(info);
    logOut();
  };

  useEffect(() => {
    setIntercom();
    new MixPanel().setupMixPanel();
    new Tracking();
    setProfitWell();
    // setBrevo();
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getWhiteLabelInfoThunk());
    if (JSON.parse(localStorage?.getItem("owner") || "{}")?._id) {
      dispatch(getOwnerThunk());
    }
    getIpAddressRemote();
  }, []);

  useEffect(() => {
    // Embed Logic ========================================================
    if (
      isEmbeded() &&
      !(EMBED_ALLOWED_PAGES as { [key: string]: number })[location.pathname]
    ) {
      // toast.info("Page not allowed in Embedings");
      console.log("EMBED_REDIRECT");
      console.log(
        `Page not allowed in embeding, accessing page: ${location.pathname}.`
      );
      navigate(getEmbedHome());
    }
    localStorage.setItem("is-embeded", isEmbeded() ? "1" : "0");
    // Embed Logic ========================================================

    // InterCom Logic ========================================================
    (window as any).Intercom("update", {
      hide_default_launcher:
        (INTERCOM_DISALLOWED_PAGES as { [key: string]: number })[
          location.pathname
        ] === 1,
    });
    // InterCom Logic ========================================================
  }, [location]);

  useEffect(() => {
    // setting theme colors
    let initWhiteInfo = whiteLabelInfo;
    if (!initWhiteInfo?.email) {
      let localWhiteLabelInfo = getWhiteLabelInfoLocal();
      if (localWhiteLabelInfo) {
        initWhiteInfo = localWhiteLabelInfo;
      }
    }
    console.log("initWhiteInfo", { initWhiteInfo });
    // considerations made
    // since embed tier should always be considered as having branding feature enbaled
    // we dont do any checks here as this would result in color changing/flashing
    console.log("acc info use eff", owner);
    if (owner?.accountInfo?.theme && isEmbeded()) {
      const genTheme = genThemeFromHex(owner?.accountInfo?.theme);
      setCSSTheme(genTheme.hsl);
    } else {
      setCSSTheme({
        primary: initWhiteInfo?.settings?.organization?.theme?.primary,
        secondary: initWhiteInfo?.settings?.organization?.theme?.secondary,
        tertiary: initWhiteInfo?.settings?.organization?.theme?.tertiary,
        textPrimary: initWhiteInfo?.settings?.organization?.theme?.textPrimary,
        textSecondary:
          initWhiteInfo?.settings?.organization?.theme?.textSecondary,
        textTertiary:
          initWhiteInfo?.settings?.organization?.theme?.textTertiary,
      });
    }
  }, [whiteLabelInfo, owner?.accountInfo?.theme]);

  // PostMessage Event logic ========================================================
  // currently used for embeding
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Add domain checks here
      // if (event.origin !== '*' && event.origin !== 'http://your-domain.com') {
      //     return;
      // }
      postMessageParser(event.data);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  // PostMessage Event logic ========================================================

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <div className="bg-gray-0">
        <Routes>
          {/* <Route path="/hex" element={<HexChooser />} /> */}
          <Route path="/sign" element={<DocumentSigner />} />
          <Route
            path="/sign-pdf-template"
            element={<DocumentTemplateSigner />}
          />
          <Route
            path="/approve-pdf-template"
            element={<DocumentTemplateApprover />}
          />
          <Route path="/signature-success" element={<SignatureSuccess />} />
          <Route path="/approver-success" element={<ApproverSuccess />} />
          <Route path="/signature-expired" element={<SignatureExpired />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/create/forms" element={<EditForm />} />
          {/* <Route path="/forms/pdf-generator"  element={<PdfGenerator />} /> */}
          <Route path="*" element={<DefaultLayout />} />
          <Route
            path="/dashboard"
            element={<Navigate to={"/dashboard/forms"} replace />}
          />
          <Route
            path="/shared-with-me"
            element={<Navigate to={"/shared-with-me/forms"} replace />}
          />
          <Route path="/pdf/:id" element={<ResponsePdfViewer />} />
          <Route path="/create/pdf-mapping" element={<DesignTemplate />} />{" "}
          <Route path="/create/pdf-signature" element={<MapSignature />} />
          <Route path="/create/pdf-template" element={<PDFTemplate />} />
          <Route path="/new/pdf-template" element={<CreateTemplate />} />
          <Route path="/replace/pdf" element={<ReplacePdf />} />
          <Route
            path="/edit-roles/pdf-template"
            element={<EditTemplateRoles />}
          />
          <Route path="/create/pdf" element={<Home />} />
          <Route path="/create/form/pdf" element={<PdfGenerator />} />
          <Route path="/verify-pdf" element={<VerifyPdf />} />
          {/* This route is to redirect the sent url and update signing logs */}
          <Route path="/mail-invite" element={<MailInvite />} />
          <Route
            path="/mail-invite-approval"
            element={<MailInviteApproval />}
          />
          <Route path="/document-url" element={<DocumentUrl />} />
          {((ENV as any) == "DEV" || (ENV as any) == "STAGE") && (
            <Route path="/atlas" element={<Atlas />} />
          )}
          {/* whitelabel routes */}
          <Route path="/partner/admin" element={<WhiteLabelAdminDashboard />} />
          {/* <Route path="/partner/login" element={<WhiteLabelLogin />} /> */}
          <Route path="/partner/login" element={<WhiteLabelOTPLogin />} />
          <Route path="/open-link" element={<OpenSecureLink />} />
          <Route path="/etemplate" element={<ETemplateSetup />} />
          {/* Iframe pages */}
          <Route path="/embed/login" element={<EmbedLogin />} />
          <Route path="/hubspot/pdfDownload" element={<HubSpotPDfDownload />} />
          <Route
            path="/extension/import-pdf"
            element={<ExtensionImportPDF />}
          />
        </Routes>
        {/* {isShowPhoneCollection && (
        <PhoneCollectModal
          modalContent={
            <AccountInfo setIsShowPhoneCollection={setIsShowPhoneCollection} />
          }
        />
      )} */}
      </div>
    </ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
