import ViewHeader from "./ViewHeader";
import { DesignerState, GlobalHelpersType } from "..";
import { IntegrationsWithToken } from "../../../../../../constants/Integrations/index";
import IntegratedSidebarAppCard from "./IntegratedSidebarAppCard";

export type IntegrationAppViewProps = {
  helpers: {
    open: boolean;
    setOpen: (value: boolean) => void;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};
export type Integration = {
  name: string;
  key: string;
  link: string;
  logo: string;
  variableMapping: any;
  description: string;
};

const IntegrationAppView = ({ helpers }: IntegrationAppViewProps) => {
  const { open, setOpen, designerState } = helpers;
  const IntegratedApps: Integration[] = IntegrationsWithToken(
    designerState?.owner,
    window.location.href.replace(window.location.origin, "")
  );

  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      <div className="px-4 overflow-y-auto">
        <div className="">
          <ViewHeader name="Apps" open={open} setOpen={setOpen} />
        </div>
        <p className="text-gray-500 text-xs pr-4 mb-4">Integrate with Apps.</p>
      </div>
      <div className="p-3">
        {IntegratedApps.map((ele: Integration, index: number) => (
          <IntegratedSidebarAppCard
            IntegratedApp={ele}
            index={index}
            designerState={designerState}
          />
        ))}
      </div>
    </div>
  );
};

export default IntegrationAppView;
