import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { TbUserEdit } from "react-icons/tb";
import { PiUserCirclePlus } from "react-icons/pi";
import axios from "../../utils/axios";
import ContactModal from "../../components/Contacts/ContactModal";
import { Slide, ToastContainer, toast } from "react-toastify";
import { GrClose } from "react-icons/gr";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import { Helmet } from "react-helmet";
import UploadContactsModal from "../../components/Contacts/UploadContactsModal";
import CsvUploadModal from "../../components/Contacts/CsvUploadModal";
import MixPanel from "../../utils/services/mixpanel";
import GoogleContactsModal from "../../components/Contacts/GoogleContactsModal";
import OutlookContactsModal from "../../components/Contacts/OutlookContactsModal";
import { IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import SendDocModal from "../../components/Contacts/SendDocModal";
import { BsSearch } from "react-icons/bs";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import SelectOptions from "../../components/Contacts/SelectOptions";
import CustomFieldsModal from "../../components/Contacts/CustomFieldsModal";
import { IoSearch } from "react-icons/io5";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { MdOutlineDelete } from "react-icons/md";
const colors = [
  "#FF0000",
  "#0000FF",
  "#800000",
  "#008000",
  "#800080",
  "#008080",
  "#000080",
  "#4B0082",
  "#A52A2A",
  "#228B22",
];
export const initialContactData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  company: "",
  jobTitle: "",
  streetAddress: "",
  city: "",
  postalCode: "",
  country: "",
  state: "",
};
export const modalTypes = {
  UPLOAD_CONTACTS: "upload_contacts",
  UPLOAD_CSV: "upload_csv",
  GOOGLE_CONTACTS: "google_contacts",
  OUTLOOK_CONTACTS: "outlook_contacts",
  SEND_DOC: "send_doc",
  CUSTOM_FIELDS: "custom_fields",
};
const Contacts = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [workSpaceContacts, setWorkSpaceContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [formData, setFormData] = useState(initialContactData);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currId, setCurrId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const PAGE_LIMIT = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [modalType, setModalType] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const workSpaceDetails = useWorkSpaceDetails();

  // Calculate the indexes of the contacts to display on the current page

  const openModal = (type: String) => {
    if (type === "create") {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
    setIsOpen(true);
  };
  const getData = async (page?: number) => {
    setIsSearching(true);
    try {
      const response = await axios.post("/workspace/searchContacts", {
        page: page ? page : currentPage,
        searchText: searchText,
      });
      if (response) {
        setIsSearching(false);
      }
      const data = response?.data;
      const contacts = data?.contacts || [];
      const totalContacts = data?.totalContacts || 0;
      setWorkSpaceContacts(contacts);
      const tempTotalPages = Math.ceil(totalContacts / PAGE_LIMIT);
      if (currentPage > tempTotalPages && tempTotalPages > 0) {
        setCurrentPage(tempTotalPages);
      }

      setTotalPages(tempTotalPages);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching workspace details");
    }
  };
  const resetContactForm = () => {
    setFormData(initialContactData);
  };

  useEffect(() => {
    // Only refresh the contacts list after all types of modals closed
    if (modalType === "" && !isOpen && !deleteModal) {
      getData();
      setSearchText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, deleteModal, currentPage, totalPages, modalType]);

  const deleteContact = async (_id: any) => {
    try {
      const response = await axios.put("/workspace/removeContact", {
        _id: _id,
      });
      const data = response?.data;
      if (data) {
        if (!data.success) {
          toast.error("Something went wrong");
          return;
        }
        toast("Contact removed successfully!", {
          type: "success",
        });
        setDeleteModal(false);
      }
    } catch (error) {
      console.log("Error in removing contact", error);
    }
  };

  return (
    <div className="-m-4 h-[100%]">
      <Helmet>
        <title translate="no">Contacts</title>
        <meta charSet="utf-8" />
        <meta name="title" content="contacts" />
        <meta
          name="description"
          content="Manage your contacts seamlessly with BoloForms' Contacts Page."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/contacts`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Contacts - Boloforms`} />
        <meta
          property="og:description"
          content="Manage your contacts seamlessly with BoloForms' Contacts Page."
        />
        <link rel="canonical" href="https://signature.boloforms.com/contacts" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="px-3 md:px-4 flex-col md:flex-row md:h-[40px] my-4 sm:my-6 text-gray-600 w-full flex gap-2 md:justify-between items-end">
        {/* <div className="flex-1 px-0 md:px-4">
          <h1 className="text-md font-semibold mb-1 pt-2">All contacts</h1>
          <p className="text-xs text-gray-500 ">
            All the contacts of this workspace appear below.
          </p>
        </div> */}
        <div className="flex items-center gap-2 w-full md:w-fit">
          <form className={`h-full text-xs w-full`}>
            <div
              className={`h-full flex flex-row rounded-md overflow-hidden ml-auto  md:w-[500px] border`}
            >
              <input
                placeholder="Search Contact..."
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value === "") {
                    getData();
                  }
                  setIsSearching(false);
                }}
                className="w-full h-full py-2 px-4   outline-none text-gray-700 "
              />
              <button
                className="min-w-[40px] flex justify-center items-center text-gray-500 cursor-pointer hover:opacity-90"
                onClick={(e) => {
                  e.preventDefault();
                  new MixPanel().track("Form", {
                    form_action: "form_search",
                    paid: false,
                  });
                  new MixPanel().increment("form_search");
                  setCurrentPage(1);
                  getData(1);
                }}
              >
                <IoSearch className="text-xl" />
              </button>
            </div>
          </form>
        </div>
        {/* <div className="h-full flex flex-row rounded-md overflow-hidden md:w-[500px] border">
          <input
            type="text"
            placeholder="Search Contact ..."
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value === "") {
                getData();
              }
              setIsSearching(false);
            }}
            className="w-full h-full py-2 px-4   outline-none text-gray-700 "
          />
          <button
            className="min-w-[40px] flex justify-center items-center text-gray-500 cursor-pointer hover:opacity-90"
            onClick={(e) => {
              new MixPanel().track("Form", {
                form_action: "form_search",
                paid: false,
              });
              new MixPanel().increment("form_search");
              setCurrentPage(1);
              getData(1);
            }}
          >
            <IoSearch className="text-xl" />
          </button>
        </div> */}
        <SelectOptions
          setModalType={setModalType}
          openModal={openModal}
          resetContactForm={resetContactForm}
        />
      </div>

      <div
        className={`w-full`}
        style={{
          overflowY:
            workSpaceContacts?.length === 0 && !loading ? "hidden" : "auto",
          maxHeight:
            workSpaceContacts?.length === 0 && !loading
              ? 0
              : "calc(100% - 130px)",
          height:
            workSpaceContacts?.length === 0 && !loading
              ? 0
              : "calc(100% - 130px)",
        }}
        id="mini-pdf-custom-scroll"
      >
        {loading || isSearching ? (
          <div className="w-full m-auto flex justify-center items-center mt-5">
            <BoloLoader />
          </div>
        ) : workSpaceContacts && workSpaceContacts.length > 0 ? (
          <>
            <div className="shadow-sm flex justify-between items-center gap-4 group cursor-pointer px-3 md:px-4 h-[40px] border-b border-gray-200 text-gray-600 text-sm font-semibold">
              <div
                className={`w-0 h-0 md:w-8 md:h-8 rounded-full hidden md:flex items-center justify-center font-medium text-white text-xs notranslate`}
                style={
                  {
                    // color: colors[index],
                    // backgroundColor: colors[index] + "1a",
                    // border: "1px solid " + colors[index] + "3a",
                  }
                }
              >
                Image
              </div>
              <div className="flex-1 flex md:justify-between md:items-center gap-0 md:gap-2 md:flex-row flex-col justify-start items-start">
                <span className="text-xs w-full md:w-[50%] max-w-[250px] md:max-w-none truncate notranslate">
                  Name
                </span>
                <span className="text-xs w-full md:w-[50%] max-w-[250px] md:max-w-none truncate">
                  Email
                </span>
              </div>
              <div className="flex text-xs items-center justify-start gap-2 w-20 md:mr-2">
                Actions
              </div>
            </div>
            {workSpaceContacts?.map((contact: any, index) => (
              <div
                key={index}
                className="flex justify-between items-center gap-4 group cursor-pointer hover:bg-secondary/40 px-3 md:px-4 h-[52px] border-b border-gray-200"
                onClick={() => {
                  setSearchText("");
                  setModalType(modalTypes.SEND_DOC);
                  setSelectedContact(contact);
                }}
              >
                <div
                  className={`w-0 h-0 md:w-8 md:h-8 rounded-full hidden md:flex items-center justify-center font-medium text-white text-xs notranslate`}
                  style={{
                    color: colors[index],
                    backgroundColor: colors[index] + "1a",
                    border: "1px solid " + colors[index] + "3a",
                  }}
                >
                  {contact?.firstName[0] || "C"}
                  {contact?.lastName[0] || (index + 1).toString()[0]}
                </div>
                <div className="flex-1 flex md:justify-between md:items-center gap-0 md:gap-2 md:flex-row flex-col justify-start items-start">
                  <span className="text-sm  text-black w-full md:w-[50%] max-w-[250px] md:max-w-none truncate notranslate">
                    {contact?.firstName} {contact?.lastName}{" "}
                    {!contact?.firstName ? (
                      <span className="text-gray-400">No Name</span>
                    ) : null}
                  </span>
                  <span className="text-sm text-gray-600 w-full md:w-[50%] max-w-[250px] md:max-w-none truncate">
                    {contact?.email}
                  </span>
                </div>
                <div className="flex items-center justify-start gap-2 w-20 md:mr-2">
                  <button
                    // variant={"secondary"}
                    // size="icon-sm"
                    className="overflow-hidden h-9 w-9 p-0 md:p-0 transition-all duration-300 flex justify-center items-center rounded-full"
                    onClick={(e) => {
                      e.stopPropagation();
                      new MixPanel().track("Contact", {
                        contact_action: "contact_edit",
                        paid: false,
                      });
                      new MixPanel().increment("contact_edit");
                      setFormData(contact);
                      openModal("edit");
                    }}
                    data-tooltip-id="edit-contact"
                    data-tooltip-offset={5}
                    id="edit-contact"
                  >
                    <TbUserEdit className="cursor-pointer text-xl text-gray-600 hover:opacity-85 mr-2" />
                    <CustomTooltip
                      helpers={{
                        id: "edit-contact",
                        content: "Edit Contact",
                        place: "top",
                      }}
                    />
                  </button>
                  <button
                    // variant={"secondary"}
                    // size="icon-sm"
                    className="overflow-hidden h-9 w-9 p-0 transition-all duration-300 flex justify-center items-center rounded-full"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        workSpaceDetails?.currentWorkSpaceRole !== "readonly"
                      ) {
                        new MixPanel().track("Contact", {
                          contact_action: "contact_delete",
                          paid: false,
                        });
                        new MixPanel().increment("contact_delete");
                        setFormData(contact);
                        setCurrId(contact?._id);
                        setDeleteModal(true);
                      }
                    }}
                    disabled={
                      workSpaceDetails?.currentWorkSpaceRole === "readonly"
                    }
                    data-tooltip-id="rem-contact"
                    data-tooltip-offset={5}
                    id="rem-contact"
                  >
                    <MdOutlineDelete className="cursor-pointer text-xl text-gray-600 hover:opacity-85" />
                    <CustomTooltip
                      helpers={{
                        id: "rem-contact",
                        content: "Delete Contact",
                        place: "top",
                      }}
                    />
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="mt-6 w-full px-3 md:px-[100px] pb-10 rounded-md hover:border-primary ">
            <div className="w-full flex flex-col justify-center items-center ">
              <div
                className="border-2 border-primary rounded-md mb-4 w-[100%] md:w-[400px] cursor-pointer"
                onClick={() => {
                  if (workSpaceDetails?.currentWorkSpaceRole !== "readonly") {
                    setIsOpen(true);
                    setFormData(initialContactData);
                  }
                }}
              >
                <PiUserCirclePlus className="text-9xl text-primary p-4 m-auto" />
                <h1 className="text-lg md:text-2xl font-medium mb-2 text-center">
                  No contacts!
                </h1>
                <h2 className="text-xs text-center">
                  No contacts added in this workspace yet
                </h2>
                <button
                  className="w-full mt-6 px-6 py-3 bg-primary text-white text-sm hover:opacity-90"
                  disabled={
                    workSpaceDetails?.currentWorkSpaceRole === "readonly"
                  }
                >
                  Create Contact
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Paging */}
      {workSpaceContacts.length > 0 && (
        <div className="border-t-2 flex flex-row justify-between items-center py-3  px-4 h-[64px] min-h-[64px] max-h-[64px]">
          <div className="flex items-center gap-2">
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="mr-2 py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
            >
              Previous
            </BoloButton>
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
            >
              Next
            </BoloButton>
          </div>
          <div className="text-gray-500 text-xs md:text-sm">
            {" "}
            <div>{`Page ${currentPage} of ${totalPages}`}</div>
          </div>
        </div>
      )}
      {/* Delete Modal */}
      {deleteModal && (
        <div className="fixed top-0 left-0 z-[51] w-full min-h-full p-4 md:p-8 bg-[#0000004d] justify-center items-center overflow-y-auto flex">
          <div className="bg-white rounded-md md:rounded-xl  max-w-xl w-full">
            <div className="flex justify-between items-center  md:py-4 py-[10px] md:px-8 px-4 bg-gray-50 rounded-t-xl border-b ">
              <h2 className=" text-base md:text-xl font-medium ">
                Delete Contact
              </h2>
              <GrClose
                className="text-base md:text-xl hover:cursor-pointer"
                onClick={() => {
                  setDeleteModal(false);
                }}
              />
            </div>
            <div className=" md:py-3 py-3 md:px-8 px-4">
              <h2 className="text-sm  md:text-base font-medium md:leading-[2] leading-[2]">
                Sure you want to delete this contact?
              </h2>
              <p className="text-[10px] md:text-xs text-gray-600">
                The contact
                <span className="font-semibold mx-1 text-primary">
                  {formData?.email}
                </span>
                will be deleted.
              </p>
              <button
                className="bg-red-600 text-white md:px-6 md:py-2 px-4 py-[6px] text-xs md:text-sm rounded-md mx-auto flex justify-center items-center mt-3 md:mb-6 mb-4 float-right"
                onClick={() => {
                  deleteContact(currId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {isOpen && (
        <ContactModal
          isOpen={isOpen}
          isCreate={isCreate}
          setIsOpen={setIsOpen}
          formData={formData}
          setFormData={setFormData}
          workSpaceContacts={workSpaceContacts}
        />
      )}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
      {modalType === modalTypes.UPLOAD_CONTACTS && (
        <UploadContactsModal setModalType={setModalType} />
      )}
      {modalType === modalTypes.UPLOAD_CSV && (
        <CsvUploadModal setModalType={setModalType} />
      )}
      {modalType === modalTypes.GOOGLE_CONTACTS && (
        <GoogleContactsModal setModalType={setModalType} />
      )}
      {modalType === modalTypes.OUTLOOK_CONTACTS && (
        <OutlookContactsModal setModalType={setModalType} />
      )}
      {modalType === modalTypes.SEND_DOC && (
        <SendDocModal
          setModalType={setModalType}
          selectedContact={selectedContact}
        />
      )}
      {modalType === modalTypes.CUSTOM_FIELDS && (
        <CustomFieldsModal setModalType={setModalType} />
      )}
    </div>
  );
};

export default Contacts;
