//@ts-nocheck

import React, { useEffect } from "react";
// import "./index.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { v4 } from "uuid";
const GoogleDescInput = ({ helpers }) => {
  let { value, onChange, setUnprivilegedEditor, fontSize, isDescOption } =
    helpers;

  let inputId = `quill-desc-input-${v4()}`;

  let questionQuillRef = React.useRef(null);
  // const [quilleditor, setQuilleditor] = React.useState(null);

  function showEditMenu(display) {
    let input = document.querySelector(`#${inputId} .ql-toolbar`);
    input.style.display = display;
  }

  useEffect(() => {
    let quilleditor = questionQuillRef.current?.getEditor();
    // setQuilleditor(quilleditor);
    const unprivilegedEditor =
      questionQuillRef.current?.makeUnprivilegedEditor(quilleditor);
    setUnprivilegedEditor(unprivilegedEditor);
  }, []);

  return (
    <div className="w-full mt-2.5 mr-4">
      <ReactQuill
        ref={questionQuillRef}
        value={value}
        // value={formState?.descriptionHtml ?? formState?.description}
        placeholder="Description"
        onChange={onChange}
        // onChange={(value) => {
        //   setFormState((prevState) => {
        //     const newForm = { ...prevState };
        //     newForm.descriptionHtml = value;
        //     unprivilegedEditor?.getText() &&
        //       (newForm.description = unprivilegedEditor?.getText());
        //     return newForm;
        //   });
        // }}
        onFocus={() => {
          // console.log("focus");
          showEditMenu("flex");
          // document.querySelector(".ql-toolbar").style.display = "flex";
        }}
        onBlur={() => {
          // setIsEditorActive(false);
          // document.querySelector(".ql-toolbar").style.display = "none";
          showEditMenu("none");
        }}
        modules={{
          toolbar: [
            ["bold", "italic", "underline"],
            [
              "link",
              // "image"
            ],
            isDescOption ? [{ list: "ordered" }, { list: "bullet" }] : [],
            ["clean"],
          ],
        }}
        style={{ fontSize: fontSize || "14px" }}
        className="fade quill-que flex"
        id={inputId}
      />
    </div>
  );
};

export default GoogleDescInput;
