import React, { useContext } from "react";
// import {
//   SchemaForUI,
//   getFallbackFontName,
//   DEFAULT_FONT_SIZE,
//   DEFAULT_LINE_HEIGHT,
//   DEFAULT_CHARACTER_SPACING,
//   DEFAULT_FONT_COLOR,
// } from '@pdfme/common';
import { FontContext } from "../../../../contexts";
import { SidebarProps } from "..";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { AiOutlineLineHeight, AiOutlineFontSize } from "react-icons/ai";
import { TbLetterSpacing } from "react-icons/tb";
import {
  SchemaForUI,
  getFallbackFontName,
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  DEFAULT_CHARACTER_SPACING,
  DEFAULT_FONT_COLOR,
} from "../../../../../../common/src";
import { VscTextSize } from "react-icons/vsc";
import { CgFontHeight, CgFontSpacing } from "react-icons/cg";
import {
  DropdownOptionSchema,
  isTableSchema,
} from "../../../../../../common/src/type";
import {
  getHeightForTable,
  getHeightFromFontSize,
  STATIC_SCHEMA,
} from "../../../../constants";
import Divider from "../../../Divider";
import { BsBorderAll } from "react-icons/bs";
import { BoloToggle } from "../../../../../../../components/Common/Toggle/BoloToggle";
import { FaBold, FaExternalLinkAlt, FaItalic } from "react-icons/fa";
import CustomTooltip from "../../../../../../../components/EditForm/CustomTooltip";
import { MdAddLink } from "react-icons/md";

const inputStyle = {
  width: "90%",
  color: "#333",
  background: "none",
  borderRadius: 2,
  border: "1px solid #767676",
};
const selectStyle = inputStyle;

const NumberInputSet = (props: {
  width?: string;
  label: any;
  value: number;
  minNumber?: number;
  maxNumber?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { label, value, width, minNumber, maxNumber, onChange } = props;
  const formattedLabel = label;

  return (
    <div className="flex items-center gap-2">
      <label htmlFor={`input-${formattedLabel}`}>{label}</label>
      <input
        className="w-full border-2 border-primary/90 rounded-[3px] pl-1 font-semibold outline-none"
        id={`input-${formattedLabel}`}
        name={`input-${formattedLabel}`}
        onChange={onChange}
        value={value}
        type="number"
        {...(minNumber && { min: minNumber })}
        {...(maxNumber && { max: maxNumber })}
      />
    </div>
  );
};

const TextInputSet = (props: {
  width?: string;
  placeholder?: string;
  label: any;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { label, value, width, placeholder, onChange } = props;
  const formattedLabel = label;

  return (
    <div className="flex items-center gap-2">
      <label htmlFor={`input-${formattedLabel}`}>{label}</label>
      <input
        className="w-full border-2 border-primary/90 rounded-[3px] pl-1 outline-none"
        id={`input-${formattedLabel}`}
        name={`input-${formattedLabel}`}
        placeholder={placeholder || ""}
        onChange={onChange}
        value={value}
        type="text"
      />
    </div>
  );
};

const ColorInputSet = (props: {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}) => {
  const { label, value, onChange, onClear } = props;
  const formattedLabel = label;

  return (
    <div className="flex items-center gap-2 whitespace-nowrap text-xs w-full justify-between">
      <label className="block" htmlFor={`input-${formattedLabel}`}>
        {label}
      </label>
      <div className="flex">
        <input
          id={`input-${formattedLabel}`}
          name={`input-${formattedLabel}`}
          onChange={onChange}
          value={value || "#ffffff"}
          type="color"
          className="w-6 h-6  rounded-md border border-gray-300 focus:outline-none"
        />
        {/* <button
          onClick={onClear}
          className="flex items-center justify-center ml-2 bg-white rounded-md border border-primary/90 focus:outline-none hover:bg-gray-100 cursor-pointer h-fit my-auto"
        >
          <XMarkIcon className="w-4 h-4" />
        </button> */}
      </div>
    </div>
  );
};
const SelectSet = (props: {
  label: string;
  value: string;
  options: string[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isDisabled?: boolean;
}) => {
  const { label, value, options, onChange } = props;
  const formattedLabel = label;

  return (
    <div className="w-full flex gap-2 items-center justify-between">
      <label
        htmlFor={`select-${formattedLabel}`}
        className="w-fit  whitespace-nowrap"
      >
        {label}:
      </label>
      <select
        className="w-[50%] border-2 border-primary/90 rounded-[3px] px-1 py-[2px] bg-white"
        disabled={props.isDisabled}
        id={`select-${formattedLabel}`}
        name={`select-${formattedLabel}`}
        // style={selectStyle}
        onChange={onChange}
        value={value}
      >
        {options.map((o) => (
          <option key={o} value={o} className="">
            {o}
          </option>
        ))}
      </select>
    </div>
  );
};

const CheckboxSet = (props: {
  width: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  checked: boolean | undefined;
}) => {
  const { width, label, onChange, checked } = props;

  return (
    <label
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        width: `${width}`,
      }}
    >
      <input type="checkbox" checked={checked} onChange={onChange} />
      {label}
    </label>
  );
};

const TextPropEditor = (
  props: Pick<SidebarProps, "changeSchemas" | "pageSize"> & {
    activeSchema: SchemaForUI;
  }
) => {
  const { changeSchemas, activeSchema, pageSize } = props;
  const alignments = ["left", "center", "right"];
  const font = useContext(FontContext);
  const fallbackFontName = getFallbackFontName(font);

  // const getFontName = (args: { bold: boolean; italic: boolean }): string => {
  //   const { bold, italic } = args;

  //   if (!bold && !italic) {
  //     return DEFAULT_FONT_NAME;
  //   }

  //   if (bold && italic) {
  //     return DEFAULT_FONT_NAME_BOLD_ITALIC;
  //   }

  //   if (bold) {
  //     return DEFAULT_FONT_NAME_BOLD;
  //   }

  //   if (italic) {
  //     return DEFAULT_FONT_NAME_ITALIC;
  //   }

  //   return DEFAULT_FONT_NAME;
  // };

  if (!(activeSchema.type === "text" || activeSchema.type === "table"))
    return <></>;

  return (
    <section className="text-xs">
      <div className="flex items-center justify-between mb-2 gap-2 flex-col">
        {/* <SelectSet
          // isDisabled={true}
          label={"Font Name"}
          // value={activeSchema.fontName ?? fallbackFontName}
          value="Poppins"
          // options={Object.keys(font)}
          options={["Poppins"]}
          onChange={(e) => {
            changeSchemas([
              {
                key: "fontName",
                value: e.target.value,
                schemaId: activeSchema.id,
              },
            ]);
          }}
        /> */}

        <SelectSet
          label={"Text Alligment"}
          value={activeSchema.alignment ?? "left"}
          options={alignments}
          onChange={(e) =>
            changeSchemas([
              {
                key: "alignment",
                value: e.target.value,
                schemaId: activeSchema.id,
              },
            ])
          }
        />
      </div>

      <div className="flex items-center justify-between mb-2 gap-2">
        <NumberInputSet
          label={<VscTextSize size={20} />}
          value={activeSchema.fontSize ?? DEFAULT_FONT_SIZE}
          onChange={(e) => {
            const currentFontSize = Number(e.target.value);
            const dynamincFontSizeMinAdjust =
              activeSchema.dynamicFontSize &&
              activeSchema.dynamicFontSize.min > currentFontSize;

            let extraSettings: {
              key: string;
              value:
                | undefined
                | string
                | number
                | {
                    min: number;
                    max: number;
                  }
                | boolean
                | DropdownOptionSchema[];
              schemaId: string;
            }[] = [];
            if (currentFontSize >= 10 && !isTableSchema(activeSchema)) {
              let bottom = activeSchema.position.y + currentFontSize;
              if (
                bottom < pageSize.height &&
                currentFontSize > activeSchema.height
              ) {
                extraSettings = [
                  {
                    key: "height",
                    value: getHeightFromFontSize(currentFontSize, false) || 5.4,
                    schemaId: activeSchema.id,
                  },
                ];
              }
            }

            changeSchemas([
              {
                key: "fontSize",
                value: currentFontSize,
                schemaId: activeSchema.id,
              },
              ...extraSettings,
              ...(dynamincFontSizeMinAdjust
                ? [
                    {
                      key: "dynamicFontSize.min",
                      value: currentFontSize,
                      schemaId: activeSchema.id,
                    },
                  ]
                : []),
            ]);
          }}
        />
        <NumberInputSet
          label={<CgFontHeight size={20} />}
          value={activeSchema.lineHeight ?? DEFAULT_LINE_HEIGHT}
          onChange={(e) => {
            let currLineHeight = Number(e.target.value);
            let extraSettings: {
              key: string;
              value:
                | undefined
                | string
                | number
                | {
                    min: number;
                    max: number;
                  }
                | boolean
                | DropdownOptionSchema[];
              schemaId: string;
            }[] = [];
            if (currLineHeight >= 1 && !isTableSchema(activeSchema)) {
              let adjustedHeight =
                activeSchema.height / (activeSchema?.lineHeight || 1);
              let scaledHeight = 10 * currLineHeight;

              let bottom = activeSchema.position.y + scaledHeight;
              if (
                bottom < pageSize.height &&
                scaledHeight > activeSchema.height
              ) {
                extraSettings = [
                  {
                    key: "height",
                    value: scaledHeight || 10,
                    schemaId: activeSchema.id,
                  },
                ];
              }
            }
            changeSchemas([
              {
                key: "lineHeight",
                value: Number(e.target.value),
                schemaId: activeSchema.id,
              },
              ...extraSettings,
            ]);
          }}
        />

        <NumberInputSet
          label={<CgFontSpacing size={21} />}
          value={activeSchema.characterSpacing ?? DEFAULT_CHARACTER_SPACING}
          onChange={async (e) => {
            const currentCharacterSpacing = Number(e.target.value);
            changeSchemas([
              {
                key: "characterSpacing",
                value: currentCharacterSpacing,
                schemaId: activeSchema.id,
              },
            ]);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginBottom: "0.25rem",
        }}
      >
        {/* <CheckboxSet
          width="100%"
          label="Use dynamic font size"
          checked={Boolean(activeSchema.dynamicFontSize)}
          onChange={(e) => {
            changeSchemas([
              {
                key: "dynamicFontSize",
                value: e.target.checked
                  ? {
                      min: activeSchema.fontSize || DEFAULT_FONT_SIZE,
                      max: activeSchema.fontSize || DEFAULT_FONT_SIZE,
                    }
                  : undefined,
                schemaId: activeSchema.id,
              },
            ]);
          }}
        /> */}

        {activeSchema.dynamicFontSize && (
          <>
            <NumberInputSet
              width="45%"
              label={"FontSize Min(pt)"}
              value={
                activeSchema.dynamicFontSize.min ??
                Number(activeSchema.fontSize)
              }
              minNumber={0}
              maxNumber={activeSchema.fontSize}
              onChange={(e) => {
                changeSchemas([
                  {
                    key: "dynamicFontSize.min",
                    value: Number(e.target.value),
                    schemaId: activeSchema.id,
                  },
                ]);
              }}
            />

            <NumberInputSet
              width="45%"
              label={"FontSize Max(pt)"}
              value={
                activeSchema.dynamicFontSize.max ??
                Number(activeSchema.fontSize)
              }
              minNumber={activeSchema.fontSize}
              onChange={(e) => {
                changeSchemas([
                  {
                    key: "dynamicFontSize.max",
                    value: Number(e.target.value),
                    schemaId: activeSchema.id,
                  },
                ]);
              }}
            />
          </>
        )}
      </div>
      <div className="flex items-center justify-between mb-2 gap-4 w-full">
        <ColorInputSet
          label={"Color:"}
          value={activeSchema.fontColor ?? "#000000"}
          onChange={(e) =>
            changeSchemas([
              {
                key: "fontColor",
                value: e.target.value,
                schemaId: activeSchema.id,
              },
            ])
          }
          onClear={() =>
            changeSchemas([
              {
                key: "fontColor",
                value: DEFAULT_FONT_COLOR,
                schemaId: activeSchema.id,
              },
            ])
          }
        />
        {!isTableSchema(activeSchema) && (
          <ColorInputSet
            label={"Background:"}
            value={activeSchema.backgroundColor ?? "#ffffff"}
            onChange={(e) =>
              changeSchemas([
                {
                  key: "backgroundColor",
                  value: e.target.value,
                  schemaId: activeSchema.id,
                },
              ])
            }
            onClear={() =>
              changeSchemas([
                {
                  key: "backgroundColor",
                  value: "",
                  schemaId: activeSchema.id,
                },
              ])
            }
          />
        )}
        {isTableSchema(activeSchema) && (
          <ColorInputSet
            label={"Table Border:"}
            value={activeSchema.tableBorderColor ?? "#000000"}
            onChange={(e) =>
              changeSchemas([
                {
                  key: "tableBorderColor",
                  value: e.target.value,
                  schemaId: activeSchema.id,
                },
              ])
            }
            onClear={() =>
              changeSchemas([
                {
                  key: "tableBorderColor",
                  value: DEFAULT_FONT_COLOR,
                  schemaId: activeSchema.id,
                },
              ])
            }
          />
        )}
      </div>
      {/* {!isTableSchema(activeSchema) && (
        <>
          <div className="flex gap-2 items-center">
            <BoloToggle
              size={"xs"}
              data-tooltip-id="toggle-bold"
              // defaultPressed={activeSchema?.bold}
              pressed={activeSchema?.bold}
              onPressedChange={(value: boolean) => {
                changeSchemas([
                  {
                    key: "bold",
                    value: value,
                    schemaId: activeSchema.id,
                  },
                  {
                    key: "fontName",
                    value: getFontName({
                      bold: value,
                      italic: !!activeSchema.italic,
                    }),
                    schemaId: activeSchema.id,
                  },
                ]);
              }}
            >
              <FaBold />
              <CustomTooltip
                helpers={{
                  id: "toggle-bold",
                  content: "Bold",
                  place: "bottom",
                }}
              />
            </BoloToggle>
            <BoloToggle
              size={"xs"}
              data-tooltip-id="toggle-italic"
              // defaultPressed={activeSchema?.italic}
              pressed={activeSchema?.italic}
              onPressedChange={(value: boolean) => {
                changeSchemas([
                  {
                    key: "italic",
                    value: value,
                    schemaId: activeSchema.id,
                  },
                  {
                    key: "fontName",
                    value: getFontName({
                      bold: !!activeSchema.bold,
                      italic: value,
                    }),
                    schemaId: activeSchema.id,
                  },
                ]);
              }}
            >
              <FaItalic />
              <CustomTooltip
                helpers={{
                  id: "toggle-italic",
                  content: "Italic",
                  place: "bottom",
                }}
              />
            </BoloToggle>
            <BoloToggle
              size={"xs"}
              data-tooltip-id="toggle-link"
              defaultPressed={activeSchema?.isLink}
              pressed={activeSchema?.isLink}
              onPressedChange={(value: boolean) => {
                changeSchemas([
                  {
                    key: "isLink",
                    value: value,
                    schemaId: activeSchema.id,
                  },
                ]);
              }}
            >
              <FaExternalLinkAlt />
              <CustomTooltip
                helpers={{
                  id: "toggle-link",
                  content: "Add HyperLink",
                  place: "bottom",
                }}
              />
            </BoloToggle>
          </div>
          {activeSchema?.isLink && (
            <div className="mt-2">
              <TextInputSet
                label={""}
                placeholder={"e.g https://www.boloforms.com/"}
                value={activeSchema?.hyperLink || ""}
                onChange={(e) => {
                  changeSchemas([
                    {
                      key: "hyperLink",
                      value: e.target.value,
                      schemaId: activeSchema.id,
                    },
                  ]);
                }}
              />
            </div>
          )}
        </>
      )} */}
      {isTableSchema(activeSchema) && (
        <div className="flex items-center justify-between mb-2 gap-4 w-full">
          <ColorInputSet
            label={"Table Header:"}
            value={
              activeSchema.headStyle.backgroundColor ?? STATIC_SCHEMA.color
            }
            onChange={(e) =>
              changeSchemas([
                {
                  key: "headStyle",
                  value: {
                    ...activeSchema.headStyle,
                    backgroundColor: e.target.value,
                  },
                  schemaId: activeSchema.id,
                },
              ])
            }
            onClear={() =>
              changeSchemas([
                {
                  key: "headStyle",
                  value: {
                    ...activeSchema.headStyle,
                    backgroundColor: "",
                  },
                  schemaId: activeSchema.id,
                },
              ])
            }
          />
          <div className="w-full"></div>
          {/* 
          <NumberInputSet
            label={<BsBorderAll size={21} />}
            value={activeSchema.tableBorderWidth ?? 1}
            onChange={async (e) => {
              const tableBorderWidth = Number(e.target.value);
              changeSchemas([
                {
                  key: "tableBorderWidth",
                  value: tableBorderWidth,
                  schemaId: activeSchema.id,
                },
              ]);
            }}
          />
        */}
        </div>
      )}
    </section>
  );
};

export default TextPropEditor;
