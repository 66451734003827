// @ts-nocheck
import Papa from "papaparse";
import React, { useEffect, useRef, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BsSearch, BsThreeDots } from "react-icons/bs";
import { FaEye, FaPlus, FaUserEdit } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import {
  MdClose,
  MdFolder,
  MdFolderOpen,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { PiFilePdfLight, PiFilePdfThin } from "react-icons/pi";
import { VscEmptyWindow } from "react-icons/vsc";
import { InfinitySpin } from "react-loader-spinner";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTable } from "react-table";
import { Slide, ToastContainer, toast } from "react-toastify";
import { v4 } from "uuid";
import Modal from "../../components/Modal";
import { BASE_URL, getFormViewerUrl } from "../../constants/constants";
import { fetchOwner, getWorkSpaceDetails } from "../../utils";
import axios from "./../../utils/axios";
import MoveItemModal from "../../components/MoveItemModal";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import DropdownActionsForms from "../../components/DropdownActions/DropdownActionsForms";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import MobileLayoutDashboard from "../../components/MobileLayoutDashboard";
import { FaUserGear } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import PlanExaustedBannerEditor from "../../components/PlanExaustedModalEditor";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import { fetchAllForms } from "../../redux/apis/dashboards";
import DashboardFilter from "../../components/DashboardFilter/DashboardFilter";
import NoSearchResults from "../../components/NoSearchResults/NoSearchResults";
import DashboardBottomNav from "../../components/DashboardBottomNav/DashboardBottomNav";
import NavButton from "../../components/DashboardFilter/NavButton";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { IoSearch } from "react-icons/io5";
import NoDocumentsV1 from "../../components/NoDocuments/NoDocumentsV1";
import ShareWithTeammates from "../../components/ShareWithTeammates";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../../components/Common/SelectDropDown/BoloDropDown";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/Common/Dialog/BoloDialog";

const FormsDashboard = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  // new search based filters
  const { getSearchData, setSearchData, isFilter } = useQueryHook();
  const [owner, setOwner] = useState({});
  const [sharedAdmins, setSharedAdmins] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setloading] = useState(false);
  const [whichAdmin, setWhichAdmin] = useState("");

  const [roleInCollaboration, setRoleInCollaboration] = useState("owner");
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [fetchedDocuments, setfetchedDocuments] = useState([]);
  const [formsCount, setFormsCount] = useState(0);
  const [collabrator, setCollabrator] = useState({});
  const [tableData, setTableData] = useState({
    columns: [],
    respondent: [],
  });
  const [rowData, setRowData] = useState([]);
  const [show, setShow] = useState({
    formMoreOptions: {
      active: false,
      id: null,
    },
  });

  const [csvData, setCsvData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [dropdownState, setDropdownState] = useState({
    reload: false,
    showUpgradeModal: false,
    upgradeModalType: "",
    showBulkUploadModal: { state: false, templateId: "" },
    folderOptions: { move: { item: {}, show: false } },
    deletingForm: null,
    isSharedDashboard: false,
    workSpaceDetails: getWorkSpaceDetails(),
    isQuotaExausted: false,
    showShare: false,
    activeForm: null,
  });
  const updateDropdownState = (key, value) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const [lastAction, setLastAction] = useState<any>(false);
  const [formData, setFormData] = useState({
    id: v4(),
    name: "",
    desc: "",
    connectSpreadsheet: false,
    saving: false,
    // formJson: null,
    formJson: {
      title: "Untitled",
      description: "No Description",
      logo:
        whiteLabelInfo?.settings?.organization?.logo || IMAGE_LINKS.BOLOLOGO,
      fontFamily: "Poppins",
      accentColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
      backgroundColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.secondary ||
        "#f3f4f6",
      themeTemplate: "google-forms", // notion-forms, default
      darkMode: false,
      pages: [
        {
          title: "Page 1",
          description: "Page Description",
          id: v4(),
          questions: [
            {
              type: "Text",
              title: "Email",
              description: "",
              required: true,
              id: v4(),
              value: "",
              mandatory: true,
              validation: "respondent_email",
            },
          ],
          goToNextPage: "next",
          goToPrevPage: "prev",
        },
        {
          title: "Page 2",
          description: "Page Description",
          id: v4(),
          questions: [],
          goToNextPage: "next",
          goToPrevPage: "prev",
        },
      ],
      prefill: {},
      prefillSource: "NONE",
      showViewAndVerifyPdf: true,
      showThankYouPageBranding: true,
    },
    isApprovalWorkflowEnabled: true,
  });

  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });

  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;

  // console.log("IS SHARED DASHBOARD ", isSharedDashboard);

  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      updateDropdownState("workSpaceDetails", localWorkSpaceDetails);
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  const handleCreateForm = async (
    redirectUrl: string = "/forms/edit",
    renderer: string = "BLANK"
  ) => {
    let formName = formData.name || "Untitled Form, Signature Test";
    if (formName === "" && renderer === "BLANK") {
      return toast.error("Please enter a valid form name");
    }
    new MixPanel().track("Form", { form_action: "form_create", paid: false });
    new MixPanel().increment("form_create");
    setFormData({ ...formData, saving: true });

    let formUrl =
      redirectUrl?.split("/").length > 3
        ? `${getFormViewerUrl()}/${redirectUrl?.split("/")[3]}/${
            formData.id
          }?p=view`
        : `${getFormViewerUrl()}/${formData.id}?p=view`;

    try {
      let apiData: any = {
        id: formData.id,
        name: formData.name || "Untitled Form",
        desc: formData.desc || "No description",
        authorEmail: currentUserEmail,
        formUrl,
        // @ts-ignore
        ownerId: owner._id,
        oAuthToken: null,
        formInputFields: [],
        formType: "BOLOFORMS",
      };

      if (formData.formJson) {
        apiData.formJson = formData.formJson;

        apiData.formJson.accentColor =
          whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
          COLORS.primary;
        apiData.formJson.backgroundColor =
          whiteLabelInfo?.settings?.organization?.themeHex?.secondary ||
          "#673ab71a";
        apiData.formJson.logo =
          whiteLabelInfo?.settings?.organization?.logo || IMAGE_LINKS.BOLOLOGO;

        let responseItemsId: any = {};
        let formInputFields: string[] = [];
        for (const page of apiData.formJson.pages) {
          for (const question of page.questions) {
            delete question["chosen"];
            delete question["selected"];
            responseItemsId[question.id] = question.title;
            formInputFields.push(question.title);
            if (question.title.trim() === "") {
              toast.error("Please fill all the question titles");
              return;
            }
          }
        }
        apiData.responseItemsId = responseItemsId;
        apiData.formInputFields = formInputFields;
      }

      try {
        const { data } = await axios.post("/forms", apiData);

        console.log("data", data);
        toast.dismiss();
        if (data.error === "You have exhausted your allowed quota") {
          updateDropdownState("isQuotaExausted", true);
          return;
        }

        let isTrialExtended = data?.trialExtended;

        let navigateUrl = `/create/forms/?id=${formData.id}`;

        if (isTrialExtended?.extended) {
          navigateUrl = `/create/forms/?id=${formData.id}&trialExtended=true&d=${isTrialExtended?.days}`;
        }

        if (!data?.needPermission && !data.error) {
          navigate(navigateUrl);
          setFormData({ ...formData, saving: false });
        } else {
          // toast.error("Something went wrong, create page: line 190 : test");
        }
      } catch (error: any) {
        console.log(error);
        toast.error(error?.message);
        setFormData({ ...formData, saving: false });
      }
    } catch (error: any) {
      console.log(error);
      // toast.error("Something went wrong, create page: line 190 : test");
      toast.error(error?.message);
      setFormData({ ...formData, saving: false });
    }
  };

  const handleChoice = (choice: string) => {
    if (choice === "forms") {
      // navigate("/create/forms");
      handleCreateForm();
    } else if (choice === "pdf") {
      navigate("/create/pdf");
    } else if (choice === "template") {
      navigate(`/create/form-template?id=${v4()}`);
    } else if (choice === "pdf-template") {
      navigate(`/create/pdf-template?documentId=${v4()}`);
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);

  useEffect(() => {
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      setSharedAdmins(owner?.addedBy);
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      setWhichAdmin(owner?.addedBy?.[0]?.ownerId?.email);
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      setRoleInCollaboration(owner?.addedBy?.[0]?.role);
  }, [owner, dropdownState.isSharedDashboard]);

  async function handleGetAllForms() {
    setloading(true);
    try {
      let params = getSearchData();
      // console.log(params);
      let data = await fetchAllForms({
        page: params.pageNo_,
        limit: params.limit_,
        isStandAloneTemplate: true,
        query: params.query_,
        dateFrom: params.dateFrom_,
        dateTo: params.dateTo_,
        sortBy: params.sortBy_,
        sortOrder: params.sortOrder_,
        filter: params.filter_,
      });
      if (data) {
        setfetchedDocuments(data.forms);
        setFormsCount(data.formCount);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("Error getting documents", error);
    }
    setloading(false);
  }

  // if page num updates we make a query
  useEffect(() => {
    handleGetAllForms();
  }, [
    searchParams,
    getSearchData().rT_,
    getSearchData().pageNo_,
    getSearchData().limit_,
    getSearchData().filter_,
    getSearchData().sortBy_,
    getSearchData().sortOrder_,
    getSearchData().dateFrom_,
    getSearchData().dateTo_,
    dropdownState.reload,
  ]);

  // if searchText updates
  // and is "" then we go back to the state where to page one with no search query
  useEffect(() => {
    if (!searchText && getSearchData().query_) {
      let data = getSearchData();
      setSearchData({ query_: "", pageNo_: data.pageNo_ });
      // handleGetAllForms();
    }
  }, [searchText]);

  function getFromFillLink(form: any) {
    if (form?.formType === "GOOGLE_FORM") {
      return `${BASE_URL?.slice(0, BASE_URL?.length - 4)}/form/${form?._id}`;
    }
    return form.formUrl;
  }

  function openExternalLink(link: any) {
    window.open(link, "_blank");
  }

  const initiateBulkSubmit = async (templateId) => {
    try {
      const owner = JSON.parse(localStorage.getItem("owner"));

      if (!owner) {
        console.log("Owner data not found in local storage.");
        return;
      }

      const activePlan = owner.planhistory.find(
        (plan) => plan.isActive === true
      );

      const emailData = [];

      let reachedMaxQuantity = false;

      for (const currentRow of csvData) {
        const email = currentRow.email;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (emailRegex.test(email)) {
          if (activePlan.usedQuanity[2] < activePlan.usageQuantityAllowed[2]) {
            emailData.push({
              ...currentRow,
            });

            activePlan.usedQuanity[2]++; // Increment the usedQuanity
          } else {
            reachedMaxQuantity = true;
            break; // Stop processing if quota is reached
          }
        } else {
          console.log(`Skipping row: Invalid email format - ${email}`);
        }
      }

      if (emailData.length === 0) {
        console.log("No valid email addresses found.");
        toast.error("No valid email addresses found.");
        return;
      }

      const res = await axios.post(`/forms/bulk-request`, {
        receiversList: emailData,
        documentId: templateId,
        ownerId: owner._id,
      });

      if (res.status === 200) {
        if (reachedMaxQuantity) {
          toast.warning(
            "You reached the maximum allowed quantity but some emails were sent successfully."
          );
        } else {
          toast.success("Email(s) sent successfully");
        }
        updateDropdownState("showBulkUploadModal", {
          ...dropdownState.showBulkUploadModal,
          state: !dropdownState.showBulkUploadModal.state,
        });
      } else {
        toast.error("Bulk request failed!");
      }
    } catch (error) {
      console.error("Error during bulk submit:", error);
    }
  };

  const bulkUploadModalContent = (templateId) => {
    return (
      <div className="w-[100%] md:w-[600px] py-2 md:py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            setCsvFile(null);
            setCsvData([]);
            updateDropdownState("showBulkUploadModal", {
              ...dropdownState.showBulkUploadModal,
              state: !dropdownState.showBulkUploadModal.state,
            });
          }}
          className="absolute md:top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <h2 className="mb-1 text-lg md:text-2xl font-semibold">Bulk Submit</h2>
        <p className="text-xs md:text-sm text-gray-600 mb-6">
          Send this form to multiple people for signing
        </p>
        <div className="flex flex-col">
          <p className="text-sm md:text-lg w-fit uppercase font-semibold rounded-md flex items-center justify-center">
            Step 1
          </p>
          <p className="text-xs md:text-sm text-gray-600 mt-1 mb-4">
            Download the csv template and fill the details
          </p>
          <div>
            {/* <h3 className="text-md font-semibold mb-1">
              Download Sheet Template
            </h3> */}
            <button
              onClick={() => {
                const csvData = ["name", "email", "subject", "message"];

                // Create a Blob containing the CSV data
                const blob = new Blob([csvData], { type: "text/csv" });

                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(blob);

                // Create a link and trigger a click to download the file
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `bulk-template-${templateId}.csv`;
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              }}
              // className="px-4 py-1 bg-primary text-sm text-white rounded-md hover:opacity-90 mr-4"
              className="w-fit text-xs md:text-sm bg-secondary text-primary font-medium rounded-md px-4 py-1.5 flex items-center justify-center"
            >
              Download
            </button>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <p className="text-sm md:text-lg w-fit uppercase font-semibold rounded-md flex items-center justify-center">
            Step 2
          </p>
          <p className="text-xs md:text-sm text-gray-600 mt-1 mb-4">
            Upload the filled csv file
          </p>
          <div>
            <div className="flex items-center">
              <label
                htmlFor="csv-upload"
                onClick={() => {}}
                className="whitespace-nowrap w-fit cursor-pointer text-xs md:text-sm bg-secondary text-primary font-medium rounded-md px-4 py-1.5 flex items-center justify-center"
              >
                Choose File
              </label>
              <p className="break-all ml-2 text-xs md:text-sm text-gray-700">
                {csvFile ? csvFile?.name : "No file chosen"}
              </p>
            </div>
            <input
              className="py-2 px-4 w-full outline-none text-gray-700 rounded-md border disabled:opacity-50 text-sm hidden"
              id="csv-upload"
              type="file"
              accept=".csv, .xlsx"
              onChange={(e) => {
                const file = e.target.files[0];

                // Read the file and parse it
                setCsvFile(file);
                const reader = new FileReader();
                reader.onload = (event) => {
                  const result = event.target.result;
                  // Parse the CSV data
                  Papa.parse(result, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    complete: (parsedData) => {
                      setCsvData(parsedData.data);
                    },
                    error: (error) => {
                      console.error("CSV parsing error:", error.message);
                    },
                  });
                };
                reader.readAsText(file);
              }}
            />
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={() => {
              setCsvFile(null);
              setCsvData([]);
              updateDropdownState("showBulkUploadModal", {
                ...dropdownState.showBulkUploadModal,
                state: !dropdownState.showBulkUploadModal.state,
              });
            }}
            className="px-6 py-2 mt-4 text-primary text-xs md:text-sm bg-secondary rounded-lg hover:opacity-90 mr-4 disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={() => initiateBulkSubmit(templateId)}
            disabled={!csvFile || !csvData || csvData.length === 0}
            className="px-6 py-2 mt-4 bg-primary text-xs md:text-sm text-white rounded-lg hover:opacity-90 md:mr-4 disabled:opacity-50"
          >
            Initiate Bulk Submit
          </button>
        </div>
      </div>
    );
  };

  React.useMemo(() => {
    let cols = [
      {
        name: "Forms",
        Header: "Forms",
        accessor: "Forms", // accessor is the "key" in the data
      },
      {
        name: "Created",
        Header: "Created",
        accessor: "Created",
      },
      {
        name: "Created By",
        Header: "Created By",
        accessor: "Created By",
      },
      {
        name: "Responses",
        Header: "Responses",
        accessor: "Responses",
      },
      {
        name: "Preview Link",
        Header: "Preview Link",
        accessor: "Preview Link",
      },
      {
        name: "Actions",
        Header: "Actions",
        accessor: "Actions",
      },
    ];

    // @ts-ignore
    setTableData((prev) => {
      return {
        ...prev,
        columns: cols,
      };
    });
  }, []);

  const deleteForm = async (formId) => {
    try {
      setIsDeleting(true);
      const { data } = await axios.post(`/forms/deleteForm?formId=${formId}`);
      if (!data.error) {
        updateDropdownState("reload", !dropdownState.reload);
        updateDropdownState("deletingForm", null);
        setIsDeleting(false);
        // return toast.info("The form was successfully deleted");
        return;
      } else {
        updateDropdownState("deletingForm", null);
        setIsDeleting(false);
        return toast.error(data.error);
      }
    } catch (error) {
      updateDropdownState("deletingForm", null);
      setIsDeleting(false);
      console.log("Form deletion: ", error);
      return toast.error("Failed to delete the form");
    }
  };

  const ActionsFormOptions = ({ form, i, formDocuments }: any) => {
    const selfRef = useRef();
    const handleOutsideClick = (e) => {
      if (selfRef.current !== null) {
        if (selfRef.current.contains(e.target)) return;
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div className="relative md:static" ref={selfRef}>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <BoloButton
              size={"icon-sm"}
              variant="gray-shadow"
              className="bg-white"
            >
              <BsThreeDots />
            </BoloButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            sideOffset={5}
            className="p-0 w-52 max-h-60 overflow-y-auto action-dropdown"
          >
            <DropdownActionsForms
              form={form}
              dropdownState={dropdownState}
              updateDropdownState={updateDropdownState}
            />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  };

  React.useMemo(() => {
    let formDocuments =
      searchResults?.forms?.length > 0 ||
      searchResults?.message === "No result found for this search"
        ? searchResults?.forms
        : fetchedDocuments?.length > 0
        ? fetchedDocuments
        : [];
    setRowData(formDocuments);

    let data = formDocuments?.map((form: any, i: any) => {
      return {
        id: form.id,
        "#": (
          <span className="bg-gray-300 bg-opacity-40 shadow px-3 py-1.5 rounded-md">
            {getSearchData().pageNo_ * getSearchData().limit_ +
              i +
              1 -
              getSearchData().limit_}
          </span>
        ),
        Forms: (
          <div className="flex flex-col gap-1 pl-4 max-w-[100%]">
            <div
              data-tooltip-id={`form-name-${form?._id}`}
              data-tooltip-offset={10}
              translate="no"
              className="text-gray-700 text-sm w-fit flex items-center gap-1 max-w-[100%] truncate"
            >
              {dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
                form?.folder?.name && (
                  <>
                    <MdFolder
                      style={{
                        color: form?.folder?.color || "#3c4043",
                      }}
                      className="mr-1 text-base min-w-[20px]"
                    />
                    <Link
                      className="font-semibold"
                      to={
                        dropdownState.isSharedDashboard
                          ? `/shared-with-me/folder/items?folderId=${form?.folder?.folderId}`
                          : `/folder/items?folderId=${form?.folder?.folderId}`
                      }
                    >
                      {form?.folder?.name}
                    </Link>
                    <span>/</span>
                  </>
                )}
              <div className="truncate max-w-[100%]">
                {form?.formJson?.title || form?.name || "Untitled"}
              </div>
            </div>
            <span
              translate="no"
              className={`text-gray-500 text-xs leading-3`}
              onClick={() => {
                if (form?.pdfTemplate?.basePdfName) {
                  return;
                } else {
                  navigate(`/create/form/pdf?id=${form.formId}`);
                }
              }}
            >
              {form?.pdfTemplate?.basePdfName && (
                <PiFilePdfLight className="text-sm text-gray-600 mr-1 inline-block" />
              )}
              {form?.pdfTemplate?.basePdfName || "Default PDF"}
            </span>
            <CustomTooltip
              helpers={{
                id: `form-name-${form?._id}`,
                content: form?.formJson?.title || form?.name || "Untitled",
                place: "top",
              }}
            />
          </div>
        ),
        Created: (
          <div>
            <h3 className="text-gray-600">
              {new Date(form?.createdAt).toDateString()}
            </h3>
            <p className="text-gray-400 text-xs mt-1">
              {new Date(form?.createdAt).toLocaleTimeString()}
            </p>
          </div>
        ),
        "Created By": (
          <div className="flex items-center">
            <div className="mr-4 w-8 aspect-square bg-white rounded-full overflow-hidden border cursor-pointer">
              <img
                src={
                  form?.createdBy?.profilePic ||
                  owner?.profilePic ||
                  "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
                }
                alt="profile"
                referrerPolicy="no-referrer"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h3 className="text-gray-600">
                {form?.createdBy?.name ?? owner?.name ?? "User"}
              </h3>
              <p className="text-gray-400 text-xs mt-1">
                {form?.createdBy?.email ?? owner?.email}
              </p>
            </div>
          </div>
        ),
        Responses: (
          <BoloButton
            variant={"tertiary"}
            size="sm"
            onClick={() => {
              new MixPanel().track("Form", {
                form_action: "form_see_responses",
                paid: false,
              });
              new MixPanel().increment("form_see_responses");
              if (dropdownState.isSharedDashboard) {
                navigate(`/form?id=${form.formId}&whichAdmin=${whichAdmin}`);
              } else {
                navigate(`/form?id=${form.formId}`);
              }
            }}
            className="text-xs md:text-xs"
          >
            Responses
          </BoloButton>
        ),
        Actions: (
          <ActionsFormOptions form={form} i={i} formDocuments={formDocuments} />
        ),
        "Preview Link": (
          <div
            style={{
              display:
                !show.formMoreOptions.active ||
                show.formMoreOptions.id !== form?.id
                  ? "block"
                  : "none",
            }}
          >
            <BoloButton
              size={"icon-sm"}
              variant="secondary"
              data-tip={"Preview Link"}
              onClick={() => openExternalLink(getFromFillLink(form))}
            >
              <FiExternalLink className="" />
            </BoloButton>
          </div>
        ),
      };
    });

    setTableData((prev) => {
      return {
        ...prev,
        data,
      };
    });
  }, [fetchedDocuments, searchResults, show.oneDeleting]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //@ts-ignore
    useTable({ columns: tableData.columns ?? [], data: tableData.data ?? [] });

  /* This is checking if the user is already logged in. If the user is logged in, it will redirect to
  the dashboard. */
  let user = localStorage.getItem("owner");
  if (!user) {
    console.log("user not found");
    return <Navigate to="/login" />;
  }

  return (
    <div className="-m-4 bg-white h-[100%]">
      <Helmet>
        <title translate="no">Forms Dashboard</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Forms Dashboard" />
        <meta
          name="description"
          content="Centralize and manage all your Forms effortlessly on the Forms Dashboard for streamlined organization and accessibility."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/dashboard/forms`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Forms Dashboard - Boloforms`} />
        <meta
          property="og:description"
          content="Centralize and manage all your Forms effortlessly on the Forms Dashboard for streamlined organization and accessibility."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/dashboard/forms"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {dropdownState.showUpgradeModal && (
        <PleaseUpgradeModal
          show={dropdownState.showUpgradeModal}
          setShow={(value) => {
            updateDropdownState("showUpgradeModal", value);
          }}
          data={upgradeModalData}
          blockingType={dropdownState.upgradeModalType}
        />
      )}
      {dropdownState.folderOptions?.move?.show && (
        <MoveItemModal
          item={dropdownState.folderOptions?.move?.item}
          fromFolder={dropdownState.folderOptions.move.fromFolder}
          setShow={(value) => {
            console.log(value);
            updateDropdownState("folderOptions", {
              ...dropdownState.folderOptions,
              move: {
                ...dropdownState.folderOptions.move,
                show: value,
                fromFolder: null,
              },
            });
          }}
          stateUpdate={(value) => {}}
          isAdd={dropdownState.folderOptions.isAdd}
          setReload={(value) => {
            updateDropdownState("reload", value);
          }}
        />
      )}
      <ShareWithTeammates
        helpers={{
          documentType: "forms",
          documentName:
            dropdownState.activeForm?.formJson?.title ||
            dropdownState.activeForm?.name ||
            "Untitled",
          documentId: dropdownState.activeForm?.formId,
          isOpen: dropdownState.showShare,
          setIsOpen: (value) => {
            updateDropdownState("showShare", value);
          },
        }}
      />
      <div
        className={`px-3 md:px-4 md:h-[40px] my-4 sm:my-6 text-gray-600 flex items-center  gap-2 flex-wrap md:flex-nowrap w-full justify-between`}
      >
        <div className="flex gap-2 items-center w-full md:w-fit">
          <form
            className={`h-full text-xs flex flex-row gap-2 w-full md:w-[500px]`}
          >
            <div
              className={`h-full flex flex-row rounded-md overflow-hidden w-full md:w-[500px] border`}
            >
              <input
                placeholder="Search by title..."
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full md:w-[500px] h-full py-2 px-4   outline-none text-gray-700 "
              />
              <button
                className="min-w-[40px] flex justify-center items-center text-gray-500 cursor-pointer hover:opacity-90"
                onClick={(e) => {
                  e.preventDefault();
                  new MixPanel().track("Form", {
                    form_action: "form_search",
                    paid: false,
                  });
                  new MixPanel().increment("form_search");
                  setSearchData({ query_: searchText, pageNo_: 1 });
                  handleGetAllForms();
                  // getFormsBySearch(e);
                }}
              >
                <IoSearch className="text-xl" />
              </button>
            </div>
          </form>
          <div className="hidden md:flex">
            <DashboardFilter />
          </div>
        </div>
        <div className="h-full flex flex-row  items-center w-[100%] md:w-fit">
          <div className="flex gap-4 justify-between w-[100%] md:w-fit md:justify-start">
            <BoloButton
              size={"sm"}
              className="h-[32px] whitespace-nowrap text-xs md:text-sm flex gap-3 items-center"
              disabled={
                !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
                dropdownState.workSpaceDetails?.currentWorkSpaceRole ==
                  "readonly"
                // || dropdownState.workSpaceDetails?.currentWorkSpaceRole == "editor"
              }
              onClick={async () => {
                await handleCreateForm();
              }}
            >
              <FaPlus /> Create Form
            </BoloButton>
            <div className="flex md:hidden">
              <DashboardFilter />
            </div>
          </div>
        </div>
      </div>

      {/* PC layout*/}
      {/* 88px in top 54px in bottom  */}
      {window.innerWidth > 768 && (
        <div
          className={`hidden md:block relative`}
          style={{
            overflowY:
              fetchedDocuments?.length === 0 && !loading ? "hidden" : "auto",
            maxHeight:
              fetchedDocuments?.length === 0 && !loading
                ? 0
                : "calc(100% - 130px)",
            height:
              fetchedDocuments?.length === 0 && !loading
                ? 0
                : "calc(100% - 130px)",
          }}
          id="mini-pdf-custom-scroll"
        >
          <table {...getTableProps()} className="w-full pt-[40px]">
            <thead className="max-h-[40px] h-[40px] sticky top-0 bg-white shadow z-20">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`${
                        column.name === "Forms" ? "pl-5" : ""
                      } px-3 py-2 text-left text-gray-600 font-semibold text-xs truncate`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {loading ? (
                <tr role={"row"} align={"center"} className={"w-full"}>
                  <td className={"w-full"} colSpan={8}>
                    <div className="p-8">
                      <BoloLoader />
                    </div>
                  </td>
                </tr>
              ) : (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="hover:bg-secondary/40 cursor-pointer border-t"
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${
                              cell.column.name === "#"
                                ? "text-center"
                                : "min-w-[150px] max-w-[300px]"
                            } px-2 py-3 text-sm truncate`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* Mobile Layout */}
      {window.innerWidth <= 768 && (
        <MobileLayoutDashboard
          documentType="FORM"
          rowData={rowData}
          ActionsFormOptions={ActionsFormOptions}
        />
      )}

      {/* //? condition was previously like this searchResults?.message === "No result found for this search" */}
      {fetchedDocuments?.length === 0 && isFilter && <NoSearchResults />}
      {fetchedDocuments?.length === 0 && !isFilter ? (
        <div className="w-full flex flex-col items-center py-12 text-gray-600">
          {!loading && (
            <NoDocumentsV1
              resourceType="FORM"
              cb={() => {
                if (
                  dropdownState.workSpaceDetails?.currentWorkSpaceRole !==
                  "readonly"
                ) {
                  handleChoice("forms");
                } else {
                  toast.error("Readonly role are not allowed to create Forms");
                }
              }}
            />
          )}
        </div>
      ) : (
        <DashboardBottomNav count={formsCount} />
      )}
      {dropdownState.isQuotaExausted && (
        <PlanExaustedBannerEditor
          setShow={(value: boolean) => {
            updateDropdownState("isQuotaExausted", value);
          }}
        />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />

      {dropdownState.showBulkUploadModal?.state && (
        <Modal
          modalContent={bulkUploadModalContent(
            dropdownState.showBulkUploadModal.templateId
          )}
        />
      )}
      {dropdownState.deletingForm && (
        <Dialog
          open={dropdownState.deletingForm?.formId}
          onOpenChange={(value) => {
            updateDropdownState("deletingForm", null);
            setIsDeleting(value);
          }}
        >
          <DialogContent className="">
            <DialogHeader>
              <DialogTitle>Remove Form</DialogTitle>
            </DialogHeader>
            <div>
              <h2 className="text-sm md:text-base font-medium md:leading-[2] leading-[2]">
                Are you sure you want to delete this form?
              </h2>
              <p className="text-[10px] md:text-xs text-gray-600">
                The form{" "}
                <span className="font-semibold">
                  {dropdownState.deletingForm?.formJson?.title}
                </span>{" "}
                will be deleted.
              </p>
            </div>

            <DialogFooter>
              <BoloButton
                variant={"danger-border"}
                size={"sm"}
                onClick={async () => {
                  await deleteForm(dropdownState.deletingForm?.formId);
                }}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </BoloButton>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
      {show.voidDocumentModal && <Modal modalContent={voidDocumentModal()} />}
    </div>
  );
};

export default FormsDashboard;
