/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import React, { Fragment, useEffect } from "react";
import {
  MdOutlineCheck,
  MdOutlineContentCopy,
  MdOutlineImage,
} from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsThreeDotsVertical } from "react-icons/bs";
import { isUndefined } from "lodash";
import { v4 } from "uuid";
import QuestionImage from "../QuestionImage";
import QuestionVideo from "../QuestionVideo";
import { replaceHTags } from "../../../utils/editor";

const QuestionInputOther = ({ helpers }) => {
  let {
    question,
    setFormState,
    isEditor,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
  } = helpers;
  const moreOptionsRef = React.useRef(null);
  let questionQuillRef = React.useRef(null);

  const [isEditorActive, setIsEditorActive] = React.useState(false);
  // const [quilleditor, setQuilleditor] = React.useState(null);
  const [unprivilegedEditor, setUnprivilegedEditor] = React.useState(null);
  const [show, setShow] = React.useState({
    moreOptions: false,
  });

  function setQuillInputQuestionState() {
    let state = isEditorActive ? "active" : "inactive";
    let inputP = document.querySelector(
      `#quill-question-title-${question.id} .ql-container .ql-editor p`
    );
    let qlContainer = document.querySelector(
      `#quill-question-title-${question.id} .ql-container`
    );
    let qlEditor = document.querySelector(
      `#quill-question-title-${question.id} .ql-container .ql-editor`
    );
    // qlEditor.style.padding = state === "active" ? "16px !important" : "0px";
    inputP.style.padding = state === "active" ? "16px" : "0px";
    qlContainer.style.background = state === "active" ? "#f8f9fa" : "none";
    // qlContainer.style.background = "#f2f3f4";
    state === "active"
      ? qlContainer.classList.add("input-hover")
      : qlContainer.classList.remove("input-hover");
    qlEditor.style.borderBottom =
      state === "active" ? "1px solid #80868b" : "none";
  }

  function showEditMenu(display) {
    let inputP = document.querySelector(
      `#quill-question-title-${question.id} .ql-container .ql-editor p`
    );
    let qlContainer = document.querySelector(
      `#quill-question-title-${question.id} .ql-container`
    );
    let qlEditor = document.querySelector(
      `#quill-question-title-${question.id} .ql-container .ql-editor`
    );
    if (display === "flex") {
      inputP.style.padding = "16px";
      // qlEditor.style.padding = "16px !important";
      // inputP.style.border = "1px solid #e4e7eb";
      qlContainer.style.background = "#f8f9fa";
      // hover on qlContainer
      // qlContainer.get
      qlEditor.style.border = "none";
    } else {
      // inputP.style.padding = "0px";
      qlEditor.style.borderBottom = "1px solid #80868b";
    }
    let input = document.querySelector(
      `#quill-question-title-${question.id} .ql-toolbar`
    );
    // console.log(input);
    input.style.display = display;
  }

  const cloneQuestion = () => {
    setFormState((prevState) => {
      let newState = { ...prevState };

      let pageIndex = activeState.pageIndex;
      let questionIndex = activeState.questionIndex;

      newState.pages[pageIndex].questions.splice(questionIndex + 1, 0, {
        ...question,
        id: v4(),
      });

      return newState;
    });
    // setActiveState((prevState) => {
    //   let newState = { ...prevState };
    //   newState.questionIndex = newState.questionIndex + 1;
    //   return newState;
    // });
    setActive((prevState) => ({
      ...prevState,
      questionIndex: prevState.questionIndex + 1,
    }));
    // console.log("cloned", activeState);
  };
  const handleOutsideClick = (e) => {
    if (moreOptionsRef.current !== null) {
      if (moreOptionsRef.current.contains(e.target)) return;
    }
    setShow((prev) => ({ ...prev, moreOptions: false }));
  };

  useEffect(() => {
    setQuillInputQuestionState();
  }, [isEditorActive]);

  useEffect(() => {
    let isEditorActive_ =
      questionIndex.p === activeState.pageIndex &&
      questionIndex.q === activeState.questionIndex;
    setIsEditorActive(isEditorActive_);
  }, [activeState, questionIndex]);

  useEffect(() => {
    let quilleditor = questionQuillRef.current?.getEditor();
    // setQuilleditor(quilleditor);
    const unprivilegedEditor =
      questionQuillRef.current?.makeUnprivilegedEditor(quilleditor);
    setUnprivilegedEditor(unprivilegedEditor);

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={`${!question.isDescription && "pb-4"} w-full flex`}>
      <ReactQuill
        ref={questionQuillRef}
        value={question.titleHtml ?? question.title}
        placeholder="Question"
        onChange={async (value) => {
          let newValue = value;
          newValue = await replaceHTags(newValue);
          setFormState((prevState) => {
            let newState = { ...prevState };
            newState.pages.forEach((page) => {
              page.questions.forEach((question_) => {
                if (question_.id === question.id) {
                  question_.titleHtml = newValue;
                  unprivilegedEditor?.getText() &&
                    (question_.title = unprivilegedEditor?.getText());
                }
              });
            });
            return newState;
          });
        }}
        onFocus={() => {
          // console.log("focus");
          showEditMenu("flex");
          // document.querySelector(".ql-toolbar").style.display = "flex";
        }}
        onBlur={() => {
          // setIsEditorActive(false);
          // document.querySelector(".ql-toolbar").style.display = "none";
          showEditMenu("none");
        }}
        modules={{
          toolbar: [
            ["bold", "italic", "underline"],
            [
              "link",
              // "image"
            ],
            // [
            //   { list: "ordered" },
            //   { list: "bullet" },
            // ],
            ["clean"],
          ],
        }}
        disabled={!isEditor || question?.validation === "respondent_email"}
        style={{ fontSize: "16px" }}
        className="fade quill-que flex w-full"
        id={`quill-question-title-${question.id}`}
      />
      {isEditorActive && (
        <Fragment>
          {question?.type === "Image" && (
            <QuestionImage
              helpers={{ question, setFormState, isEditor: true }}
            />
          )}
          {question?.type === "Video" && (
            <QuestionVideo
              helpers={{ question, setFormState, isEditor: true }}
            />
          )}
          <div className="h-[52px] flex justify-center items-center">
            <div className="h-full">
              <div
                onClick={cloneQuestion}
                className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <MdOutlineContentCopy className="text-2xl text-gray-600" />
              </div>
            </div>
            <div className="h-full">
              <div
                onClick={() => {
                  setFormState((prevState) => {
                    let newState = { ...prevState };
                    newState.pages.map((page) => {
                      let questions = page.questions.filter(
                        (question_) => question_.id !== question.id
                      );
                      page.questions = questions;
                    });
                    // console.log(newState);
                    return newState;
                  });
                }}
                className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <RiDeleteBin6Line className="text-2xl text-gray-600" />
              </div>
            </div>
            <div ref={moreOptionsRef} className="h-full relative">
              <div
                onClick={() =>
                  setShow((prev) => ({
                    ...prev,
                    moreOptions: !prev.moreOptions,
                  }))
                }
                className="w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
              >
                <BsThreeDotsVertical className="text-xl text-gray-600" />
              </div>
              {show.moreOptions && (
                <MoreOptions helpers={{ question, setFormState, setShow }} />
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default QuestionInputOther;

const MoreOptions = ({ helpers }) => {
  let { question, setFormState, setShow } = helpers;

  const options = [
    {
      name: "Description",
      key: "isDescription",
      isActive: question.isDescription ?? true,
    },
  ];

  const handleOptionClick = (option) => {
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_[option.key] = isUndefined(question_[option.key])
              ? false
              : !question_[option.key];
            console.log(question_);
          }
        });
      });
      return newState;
    });

    setShow((prevState) => {
      let newState = { ...prevState };
      newState.moreOptions = !newState.moreOptions;
      return newState;
    });
  };

  return (
    <div
      style={{
        marginTop: "48px",
        position: "absolute",
        top: 0,
        right: "-100px",
        boxShadow:
          "0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122)",
      }}
      className="py-2 w-[300px] bg-white rounded z-50"
    >
      <p className="text-sm px-4 text-gray-500">Show</p>
      {options.map((option, i) => {
        return (
          <Fragment key={i}>
            <div
              onClick={() => handleOptionClick(option)}
              className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
            >
              <div className="w-[40px]">
                {option.isActive && (
                  <MdOutlineCheck className="text-2xl text-gray-400 ml-auto" />
                )}
              </div>
              <div className="ml-1 px-4">
                <span className="text-gray-600 text-base">{option.name}</span>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
