//@ts-nocheck
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import MixPanel from "../../utils/services/mixpanel";

const CreateFolderModal = ({
  setShow,
  parentFolder,
  stateUpdate,
  isShared,
  sharedOwnerId,
}) => {
  const [folderDetails, setFolderDetails] = useState({
    folderName: "",
    parentFolder: parentFolder || "",
    color: "#3c4043",
  });
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");

  const createFolder = async () => {
    try {
      if (!folderDetails.folderName) {
        toast.error("Enter Folder name");
        return;
      }

      const { data } = await axios.post("/folder/create", {
        folderName: folderDetails.folderName,
        parentFolder: folderDetails.parentFolder,
        color: folderDetails.color,
        ownerId: isShared ? sharedOwnerId : owner?._id,
      });
      if (data?.success) {
        const { folder, allFolders, updatedParent } = data;
        // setFolders && setFolders(allFolders);
        // updatedParent?.items && setItems && setItems(updatedParent?.items);
        stateUpdate && stateUpdate((prev) => !prev);
        new MixPanel().track("Folder", {
          folder_action: "folder_create",
          paid: false,
        });
        new MixPanel().increment("folder_create");
        toast.success("Folder created");
      } else {
        toast.error(data?.message || "Something went wrong");
      }

      // close the modal here
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
    setShow(false);
  };

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />{" "}
          <div className="text-base md:text-lg mb-4 font-semibold">
            Create New Folder
          </div>
          <div className="flex gap-2 items-center justify-between">
            <input
              type="text"
              value={folderDetails.folderName}
              placeholder="Folder Name"
              className="border px-4 py-2 rounded-lg w-[90%] text-xs md:text-sm outline-none"
              onChange={(e) => {
                setFolderDetails((prev) => ({
                  ...prev,
                  folderName: e.target.value,
                }));
              }}
            />
            <label
              className={`rounded-full min-h-9 min-w-9 h-9 w-9 cursor-pointer hover:scale-110 shadow`}
              style={{ background: folderDetails.color }}
            >
              <input
                type="color"
                value={folderDetails.color}
                className="h-full w-full hidden"
                onChange={(e) => {
                  setFolderDetails((prev) => ({
                    ...prev,
                    color: e.target.value,
                  }));
                }}
              />
            </label>
          </div>
          <div className="flex gap-2 items-center justify-end mt-8">
            <button
              className="text-xs md:text-sm text-primary border px-4 py-2 rounded-md"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              disabled={!folderDetails.folderName}
              className="text-xs md:text-sm text-white bg-primary px-4 py-2 rounded-md"
              onClick={() => {
                createFolder();
              }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFolderModal;
