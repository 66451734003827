import React, { useEffect, useState, useCallback } from "react";
import ViewHeader from "./ViewHeader";
import { InstanceFromType, PDFReceivers } from "../../../../../common/src/type";
import {
  getInitials,
  getRandomColorV1,
  getTintFromHex,
} from "../../../../../../utils/uiUtils";
import {
  MdClose,
  MdDelete,
  MdOutlineDriveFileRenameOutline,
} from "react-icons/md";
import { toast } from "react-toastify";
import axios from "../../../../../../utils/axios";
import { DesignerState, GlobalHelpersType } from "..";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import EmailSingleSelect from "../../../../../../components/Ui/EmailSingleSelect";
import ContactModal from "../../../../../../components/Contacts/ContactModal";
import { getContactDetails, setContactDetails } from "../../../../../../utils";
import CustomTooltip from "../../../../../../components/EditForm/CustomTooltip";
import { BoloInput } from "../../../../../../components/Common/Input/BoloInput";
import { BASE_URL } from "../../../../../../constants/constants";
import _ from "lodash";
import SigningOrderDiagram from "../../../../../../components/SigningOrderDiagram";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../../../../../../components/Common/PopOver/BoloPopOver";
import { COLORS } from "../../../../../../constants/common";

export type RecipientViewProps = {
  helpers: {
    open: boolean;
    setOpen: (value: boolean) => void;
    // instanceFrom: InstanceFromType;
    // fetchedData: any;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};

type DataType = {
  PDF: {
    header: string;
    desc: string;
  };
  "PDF-TEMPLATE": {
    header: string;
    desc: string;
  };
  FORM: {
    header: string;
    desc: string;
  };
};

export type DeleteRoleProps = {
  roles: any[];
  setRoles: React.Dispatch<React.SetStateAction<any[]>>;
  roleToDelete: number;
  setRoleToDelete: React.Dispatch<React.SetStateAction<number>>;
  fetchedData: any;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
};

const DeleteRole = ({
  setRoleToDelete,
  roleToDelete,
  setRoles,
  roles,
  fetchedData,
  globalHelpers,
  designerState,
  setDesignerState,
}: DeleteRoleProps) => {
  const [loading, setLoading] = useState(false);
  // console.log("delet roles", fetchedData, designerState?.fetchedData);

  const handleDeleteRole = async () => {
    setLoading(true);
    try {
      let deletedRole = roles[roleToDelete];
      let rolesCopy = [...roles];
      rolesCopy = rolesCopy?.filter((role, idx) => idx !== roleToDelete);

      const { data } = await axios.post(`/sidebar/roles`, {
        newRespondants: rolesCopy,
        documentId: fetchedData?.document?.documentId,
        instanceFrom: designerState?.instanceFrom,
      });
      globalHelpers.deleteSchemasFor(deletedRole?.title || deletedRole?.name);
      await globalHelpers.getFetchedDataFromDB(
        designerState?.fetchedData?.document?.documentId
      );
      if (!data?.error) {
        toast.success("Role Deleted Succesfully");
      }
      setRoleToDelete(-1);
      setRoles(rolesCopy);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`w-fit relative bg-white rounded-xl px-8 py-5 overflow-y-auto`}
      >
        <div className="lg:w-[25vw]">
          <div className="mb-4">
            <p className="text-gray-700 text-md font-semibold mb-2">
              Delete Role '
              {roles?.[roleToDelete]?.title || roles?.[roleToDelete]?.name}'
            </p>
          </div>
          <p>
            Deleting this role will delete the schemas related to the following
            role
          </p>

          <div className="flex justify-between gap-10 mt-5">
            <BoloButton
              onClick={() => {
                setRoleToDelete(-1);
              }}
              variant={"secondary"}
              size={"sm"}
              // className={` flex items-center justify-center py-1 px-2 lg:mr-6 xl:mr-9 cursor-pointer text-sm bg-[#EAEAEA]  font-lightboldrounded-md rounded overflow-hidden `}
            >
              <span className="font-medium"> Cancel</span>
            </BoloButton>
            <BoloButton
              onClick={() => {
                handleDeleteRole();
              }}
              variant={"danger"}
              size={"sm"}
              // className={` flex items-center justify-center py-1 px-2 cursor-pointer text-sm bg-red-600 text-white font-lightboldrounded-md rounded overflow-hidden`}
            >
              <span className="font-medium">
                {" "}
                {loading ? "Deleteing" : "Delete"}{" "}
              </span>
            </BoloButton>
          </div>
        </div>
        <div
          onClick={() => {
            setRoleToDelete(-1);
          }}
          className="absolute top-0 md:top-1 right-0 cursor-pointer"
        >
          <MdClose className="text-2xl m-4" />
        </div>
      </div>
    </div>
  );
};

export type EditRoleType = {
  roles: any[];
  setRoles: React.Dispatch<React.SetStateAction<any[]>>;
  roleToEdit: number;
  setRoleToEdit: React.Dispatch<React.SetStateAction<number>>;
  fetchedData: any;
  globalHelpers: GlobalHelpersType;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
};
const EditRole = ({
  roles,
  setRoles,
  roleToEdit,
  setRoleToEdit,
  fetchedData,
  designerState,
  setDesignerState,
  globalHelpers,
}: EditRoleType) => {
  const [localName, setLocalName] = useState(
    (designerState?.instanceFrom == "PDF"
      ? roles?.[roleToEdit]?.name
      : roles?.[roleToEdit]?.title) || ""
  );
  const [localEmail, setLocalEmail] = useState(
    roles?.[roleToEdit]?.email || ""
  );
  const [loading, setLoading] = useState(false);

  const updateRolesHandler = async () => {
    setLoading(true);
    try {
      const rolesCopy = [...roles];
      const fromRole = rolesCopy[roleToEdit].title;
      const toRole = localName;
      rolesCopy[roleToEdit].title = localName;

      const { data } = await axios.post(`/sidebar/roles`, {
        newRespondants: rolesCopy,
        documentId: fetchedData?.document?.documentId,
        instanceFrom: designerState?.instanceFrom,
      });
      await globalHelpers.getFetchedDataFromDB(
        designerState?.fetchedData?.document?.documentId
      );
      globalHelpers.renameExsitingSchemas(fromRole, toRole);
      globalHelpers.saveSchemasOnly();
      if (!data?.error) {
        toast.success(data?.message);
      }

      setRoles(rolesCopy);
      setLoading(false);
      setRoleToEdit(-1);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`w-fit relative bg-white rounded-xl px-8 py-5 overflow-y-auto`}
      >
        <div className="lg:w-[25vw]">
          <div className="mb-4">
            <p className="text-gray-700 text-md font-semibold mb-2">
              Edit Role
            </p>
          </div>
          <input
            placeholder="Role Name"
            value={localName}
            className="outline-none w-full border-b-2 focus:border-primary p-2 pl-0 mb-3"
            onChange={(e) => {
              setLocalName(e.target.value);
            }}
          />
          {roles.some(
            (ele, i) =>
              ele?.title?.trim() == localName?.trim() && i !== roleToEdit
          ) && (
            <span className="text-red-400 text-xs">Role already exists</span>
          )}

          <div className="flex justify-between gap-10 mt-5">
            <BoloButton
              onClick={() => {
                setRoleToEdit(-1);
              }}
              variant={"secondary"}
              size={"sm"}
              // className={` flex items-center justify-center py-1 px-2 lg:mr-6 xl:mr-9 cursor-pointer text-sm bg-[#EAEAEA]  font-lightboldrounded-md rounded overflow-hidden `}
            >
              <span className="font-medium"> Cancel</span>
            </BoloButton>
            <BoloButton
              variant={"primary"}
              size={"sm"}
              onClick={() => {
                updateRolesHandler();
              }}
              disabled={
                roles.some(
                  (ele, i) =>
                    (ele?.title?.trim() == localName?.trim() &&
                      i !== roleToEdit) ||
                    ele?.varName == ""
                ) || loading
              }
              // className={` flex items-center justify-center py-1 px-2 cursor-pointer text-sm bg-primary text-white font-lightboldrounded-md rounded overflow-hidden`}
            >
              <span className="font-medium">
                {" "}
                {loading ? "Saving" : "Save"}{" "}
              </span>
            </BoloButton>
          </div>
        </div>
        <div
          onClick={() => {
            setRoleToEdit(-1);
          }}
          className="absolute top-0 md:top-1 right-0 cursor-pointer"
        >
          <MdClose className="text-2xl m-4" />
        </div>
      </div>
    </div>
  );
};

export type RolesHandlerProps = {
  // fetchedData: any;
  // instanceFrom: InstanceFromType;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
};
const RolesHandler = ({
  designerState,
  setDesignerState,
  globalHelpers,
}: RolesHandlerProps) => {
  const [roles, setRoles] = useState<any[]>(
    (designerState?.instanceFrom == "PDF"
      ? designerState?.receiversList
      : designerState?.roles) || []
  );
  // const [receiversList, setReceiversList] = useState<PDFReceivers[]>(
  //   designerState?.receiversList
  // );
  const [roleToEdit, setRoleToEdit] = useState(-1);
  const [roleToDelete, setRoleToDelete] = useState(-1);
  const [roleToAdd, setRoleToAdd] = useState("");
  const [loading, setLoading] = useState(false);
  const [addRecipientEmail, setAddRecipientEmail] = useState({
    name: "",
    email: "",
  });
  const [contactFormData, setContactFormData] = useState<any>(null);
  const [isSigningOrderDiagram, setIsSigningOrderDiagram] =
    useState<Boolean>(false);

  const handleSelectedValue = async (
    email: string,
    index: any,
    name: string
  ) => {
    if (roles.find((role) => role?.email === email)) {
      toast.error("Email already exists");
      return;
    }
    // comment this out after yash updates Contact Modal
    const contacts = getContactDetails();
    const addContact = async () => {
      try {
        // check if email is valid
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          toast("Enter a valid email address", {
            type: "error",
          });
          return true;
        }
        // Check email aready exits or not
        if (
          contacts?.contacts.find((contact: any) => contact.email === email)
        ) {
          // toast("Email already exists", {
          //   type: "error",
          // });
          return;
        }
        const response = await axios.post("/workspace/addContact", { email });
        // const data = response?.data;
        // if (data) {
        //   if (!data.success) {
        //     toast.error("Something went wrong");
        //     return;
        //   }
        // toast("Contact added successfully!", {
        //   type: "success",
        // });
        // setFormData(initialContactData);
        // setIsOpen(false);
        // }
      } catch (error) {
        console.log("Error updating contact.");
      }
    };
    await addContact();
    const getData = async () => {
      try {
        const response = await axios.get("/workspace/getContacts");
        const data = response?.data;
        const contacts = data?.contacts?.contacts || [];
        setContactDetails(contacts);
      } catch (error) {
        console.log("Error fetching workspace details");
      }
    };
    await getData();
    // ---------------------------------------
    setAddRecipientEmail({ name: name?.trim() ? name?.trim() : email, email });
    addRoleHandler({
      name: name?.trim() ? name?.trim() : email,
      email,
      signingOrderNo: roles?.length + 1 || 1,
    });
  };

  const handlePDFEdit = (idx: number) => {
    const contactsData = getContactDetails();
    let contact = contactsData?.contacts?.find(
      (cont: any) => cont?.email == roles?.[idx]?.email
    );
    // console.log({ contact, idx, contactsData });
    setContactFormData(contact);
  };

  const handleSetFormData = async (contactInfo: any) => {
    setLoading(true);
    try {
      console.log(contactInfo);
      const rolesCopy = [...roles];
      const oldName = rolesCopy[roleToEdit].name;
      // if (contactInfo?.firstName || contactInfo?.lastName) {
      rolesCopy[roleToEdit].name = `${
        contactInfo?.firstName && contactInfo?.firstName
      }  ${contactInfo?.lastName && contactInfo?.lastName}`?.trim();
      // }
      if (!rolesCopy[roleToEdit].name) {
        rolesCopy[roleToEdit].name = contactInfo?.email;
      }
      if (contactInfo?.email) {
        rolesCopy[roleToEdit].email = contactInfo?.email;
      }
      const newName = rolesCopy[roleToEdit].name;
      setContactFormData(contactInfo);
      const { data } = await axios.post(`/sidebar/roles`, {
        newRespondants: rolesCopy,
        documentId: designerState?.fetchedData?.document?.documentId,
        instanceFrom: designerState?.instanceFrom,
      });
      await globalHelpers.getFetchedDataFromDB(
        designerState?.fetchedData?.document?.documentId
      );
      globalHelpers.renameExsitingSchemas(oldName, newName);
    } catch (error) {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const addRoleHandler = async ({
    name,
    email,
    signingOrderNo,
  }: {
    name?: string;
    email?: string;
    signingOrderNo?: number;
  }) => {
    setLoading(true);
    try {
      const rolesCopy = [...roles];
      if (designerState.instanceFrom == "PDF-TEMPLATE") {
        rolesCopy.push({
          title: roleToAdd,
          colour: getRandomColorV1(),
          signingOrderNo: signingOrderNo,
        });
      }
      if (designerState?.instanceFrom == "PDF") {
        //  why is this approach used
        // because if we do state update then
        // there is a chance that the state is not updated immedialty
        // there for we pass props
        rolesCopy.push({
          name: name,
          email: email,
          colour: getRandomColorV1(),
          signingOrderNo: signingOrderNo,
          status: "DRAFT",
        });
      }

      const { data } = await axios.post(`/sidebar/roles`, {
        newRespondants: rolesCopy,
        documentId: designerState?.fetchedData?.document?.documentId,
        instanceFrom: designerState?.instanceFrom,
      });
      await globalHelpers.getFetchedDataFromDB(
        designerState?.fetchedData?.document?.documentId
      );
      if (!data?.error) {
        toast.success("Role Added Succesfully");
      }
      setAddRecipientEmail({ name: "", email: "" });
      setRoleToAdd("");
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleSigningOrder = async (isSigningOrderData: boolean) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/document/signing-order`, {
        documentId: designerState?.fetchedData?.document?.documentId,
        isSigningOrderData,
        instaceFrom: designerState?.instanceFrom,
      });
    } catch (error) {
      console.log("Error in handleSigningOrder");
      console.log(error);
    }
  };

  useEffect(() => {
    setRoles(
      (designerState?.instanceFrom == "PDF"
        ? designerState?.receiversList
        : designerState?.roles) || []
    );
  }, [designerState?.roles, designerState?.receiversList]);

  return (
    <div className="text-sm mt-4 py-2">
      <p className="text-gray-500 pr-4 mb-3">Template Roles</p>
      {isSigningOrderDiagram && (
        <SigningOrderDiagram
          recipientList={[...roles]}
          setIsSigningOrderDiagram={setIsSigningOrderDiagram}
          isSigningOrderDiagram={isSigningOrderDiagram}
        />
      )}
      {roleToEdit !== -1 && designerState?.instanceFrom == "PDF-TEMPLATE" && (
        <EditRole
          setRoles={setRoles}
          roles={roles}
          setRoleToEdit={setRoleToEdit}
          roleToEdit={roleToEdit}
          fetchedData={designerState?.fetchedData}
          globalHelpers={globalHelpers}
          designerState={designerState}
          setDesignerState={setDesignerState}
        />
      )}
      {roleToEdit !== -1 && designerState?.instanceFrom == "PDF" && (
        <ContactModal
          isCreate={false}
          setIsOpen={() => setRoleToEdit(-1)}
          isOpen={roleToEdit !== -1 && designerState?.instanceFrom == "PDF"}
          formData={contactFormData}
          setFormData={setContactFormData}
          // workSpaceContacts={[]}
          handleSaveCallback={handleSetFormData}
        />
      )}
      {roleToDelete !== -1 && (
        <DeleteRole
          setRoles={setRoles}
          roles={roles}
          setRoleToDelete={setRoleToDelete}
          roleToDelete={roleToDelete}
          fetchedData={designerState?.fetchedData}
          globalHelpers={globalHelpers}
          designerState={designerState}
          setDesignerState={setDesignerState}
        />
      )}
      <div
        className="flex flex-col -mx-4 max-w-[300px]"
        // style={{
        //   maxHeight:
        //     designerState?.instanceFrom == "PDF"
        //       ? "calc(100vh - 370px)"
        //       : "calc(100vh - 400px)",
        // }}
        // id="mini-pdf-custom-scroll"
      >
        <div className="flex gap-2 text-xs text-gray-500 mb-4 px-4">
          <input
            type="checkbox"
            checked={designerState?.isSigningOrderData}
            onChange={async () => {
              await handleSigningOrder(!designerState?.isSigningOrderData);
              // setDesignerState((prev) => ({
              //   ...prev,
              //   isSigningOrderData: !designerState?.isSigningOrderData,
              // }));
              await globalHelpers.getFetchedDataFromDB(
                designerState?.fetchedData?.document?.documentId
              );
            }}
          />
          Set Signing Order Data
          {designerState?.isSigningOrderData && (
            <span
              className="text-blue-400 cursor-pointer"
              onClick={() => {
                setIsSigningOrderDiagram(true);
              }}
            >
              View Order
            </span>
          )}
        </div>
        {roles?.map((role, idx) => {
          const dataMap = {
            "PDF-TEMPLATE": {
              color: role?.colour,
              title: role?.title,
              email: "",
              signingOrderNo: role?.signingOrderNo || "",
            },
            PDF: {
              color: role?.colour,
              title: role?.name,
              email: role?.email,
              signingOrderNo: role?.signingOrderNo || "",
            },
            // form here is useless just kept to avoid ts errors
            FORM: {
              color: "",
              title: "",
              email: "",
              signingOrderNo: role?.signingOrderNo || "",
            },
          };
          const activeData = dataMap[designerState.instanceFrom];
          return (
            <div
              key={idx}
              className="group flex gap-3 items-center h-14 justify-between hover:bg-slate-100 cursor-pointer px-4 max-w-[300px] min-h-[56px]"
            >
              <div className="flex gap-2 items-center max-w-[205px]">
                {designerState?.isSigningOrderData && (
                  <div>
                    <BoloInput
                      className="w-6 hide-spinner text-center group-hover:bg-slate-100"
                      type="number"
                      min="1"
                      bolosize={"xs"}
                      value={activeData.signingOrderNo}
                      onChange={async (e) => {
                        const rolesCopy = [...roles];
                        rolesCopy[idx].signingOrderNo = e.target.value;
                        const { data }: any = await axios.post(
                          `/sidebar/roles`,
                          {
                            newRespondants: rolesCopy,
                            documentId:
                              designerState?.fetchedData?.document?.documentId,
                            instanceFrom: designerState?.instanceFrom,
                          }
                        );
                        await globalHelpers.getFetchedDataFromDB(
                          designerState?.fetchedData?.document?.documentId
                        );
                      }}
                    />
                  </div>
                )}
                <div
                  className="h-8 w-8 min-w-8 min-h-8 rounded-full flex items-center justify-center text-gray-600"
                  style={{
                    background: getTintFromHex(
                      activeData?.color || COLORS.primary,
                      85
                    ),
                    border: `1px solid ${activeData?.color || COLORS.primary}`,
                  }}
                >
                  {getInitials(activeData?.title || activeData?.email) || ""}
                </div>
                <div className="flex flex-col">
                  <div className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[130px]">
                    {activeData?.title || activeData?.email}
                  </div>
                  {activeData?.email && (
                    <div className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[130px] text-[10px] leading-[10px] text-gray-600">
                      {activeData?.email}
                    </div>
                  )}
                </div>
              </div>
              <BoloPopover>
                <BoloPopoverTrigger>
                  <button className="text-gray-400 hover:text-gray-500 mt-1">
                    <BsThreeDotsVertical size={18} />
                  </button>
                </BoloPopoverTrigger>
                <BoloPopoverContent align="end">
                  <div className="flex flex-col gap-2 text-gray-400 text-xs rounded-lg bg-white w-fit p-2">
                    <button
                      className="hover:text-gray-500 flex gap-3 p-1 items-center"
                      onClick={() => {
                        setRoleToEdit(idx);
                        handlePDFEdit(idx);
                      }}
                    >
                      <MdOutlineDriveFileRenameOutline size={18} />
                      Edit Role
                    </button>
                    <button
                      data-tooltip-id="role-delete-btn"
                      disabled={roles?.length == 1}
                      className="hover:text-gray-500 disabled:cursor-not-allowed disabled:opacity-100 flex gap-3 p-1 items-center"
                      onClick={() => {
                        setRoleToDelete(idx);
                      }}
                    >
                      <MdDelete size={20} />
                      Delete Role
                    </button>
                  </div>
                  {roles?.length == 1 && (
                    <CustomTooltip
                      helpers={{
                        id: "role-delete-btn",
                        content: "Atleast one role/recipient is required",
                        place: "top",
                      }}
                    />
                  )}
                </BoloPopoverContent>
              </BoloPopover>
            </div>
          );
        })}
      </div>
      {designerState?.instanceFrom == "PDF-TEMPLATE" && (
        <>
          <div className="flex gap-2 items-center mt-6">
            {/* <input
              placeholder="e.g. Manager"
              value={roleToAdd}
              className="outline-none w-full border-b-2 focus:border-primary p-1 text-sm pl-0"
              onChange={(e) => {
                setRoleToAdd(e.target.value);
              }}
            /> */}
            <BoloInput
              bolosize={"xs"}
              placeholder="e.g. Manager"
              value={roleToAdd}
              className="outline-none border-b-2 focus:border-primary w-[calc(100%_-_64px)] p-1 text-sm pl-0"
              onChange={(e) => {
                setRoleToAdd(e.target.value);
              }}
            />
            <BoloButton
              onClick={() => {
                addRoleHandler({ signingOrderNo: roles?.length + 1 });
              }}
              disabled={
                roles.some(
                  (ele, i) =>
                    (ele?.title?.trim() == roleToAdd?.trim() &&
                      i !== roleToEdit) ||
                    ele?.varName == ""
                ) ||
                loading ||
                !roleToAdd
              }
              size={"xs"}
              className={` flex items-center justify-center w-[60px]`}
            >
              {loading ? "Adding" : "Add"}{" "}
            </BoloButton>
          </div>
          {roles.some(
            (ele, i) =>
              ele?.title?.trim() == roleToAdd?.trim() && i !== roleToEdit
          ) && (
            <span className="text-[10px] text-red-500/80 mt-4">
              {" "}
              Role '{roleToAdd}' already exists
            </span>
          )}
        </>
      )}
      {designerState?.instanceFrom == "PDF" && (
        <>
          <div className="flex gap-2 items-center mt-6">
            <EmailSingleSelect
              selectedValue={addRecipientEmail?.email}
              updateEmailValue={handleSelectedValue}
              filterList={roles?.map((role) => role?.email) || []}
            />
          </div>
          {roles.some(
            (ele, i) =>
              ele?.title?.trim() == roleToAdd?.trim() && i !== roleToEdit
          ) && (
            <span className="mx-6 text-xs text-red-600 my-2">
              {" "}
              Role '{roleToAdd}' already exists
            </span>
          )}
        </>
      )}
    </div>
  );
};

const RecipientView = ({ helpers }: RecipientViewProps) => {
  // consts ----------------------------------------------------
  const { open, setOpen, designerState, setDesignerState, globalHelpers } =
    helpers;

  const Data: DataType = {
    PDF: {
      header: "Recipients",
      desc: "Add at least one recipient to view and/or sign your document",
    },
    "PDF-TEMPLATE": {
      header: "Roles",
      desc: "Roles are placeholders that allow you to pre-assign fields to document recipients. To help recipients complete this document, please assign fields to each role.",
    },
    FORM: { header: "", desc: "" },
  };
  // states ----------------------------------------------------
  // effects ---------------------------------------------------
  // testing ---------------------------------------------------
  return (
    <div className="flex h-[100%] max-h-[100%] justify-between flex-col w-[100%]">
      <div className="h-[100%]">
        <div className="px-4">
          <ViewHeader
            name={Data?.[designerState?.instanceFrom]?.header}
            open={open}
            setOpen={setOpen}
          />
        </div>
        <div
          className="px-4 max-h-[calc(100%_-_80px)] min-h-[calc(100%_-_80px)] overflow-y-auto"
          id="mini-pdf-custom-scroll"
        >
          <p className="text-gray-500 text-xs pr-4 mb-2">
            {Data?.[designerState?.instanceFrom]?.desc}
          </p>
          <RolesHandler
            designerState={designerState}
            setDesignerState={setDesignerState}
            globalHelpers={globalHelpers}
          />
        </div>
      </div>
    </div>
  );
};

export default RecipientView;
