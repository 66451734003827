import { BASE_URL } from "../../constants/constants";
import axios from "../../utils/axios";

const fetchAllDocuments = async ({
  page = 1,
  limit = 10,
  isStandAloneTemplate = true,
  filter = "ALL",
  query = "",
  dateFrom = "",
  dateTo = "",
  sortBy = "",
  sortOrder = "-1",
}: {
  page?: number;
  limit?: number;
  isStandAloneTemplate: boolean;
  filter?: string;
  query?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: string;
  sortOrder?: string;
}): Promise<{ documents?: any[]; documentsCount?: number } | undefined> => {
  try {
    let requestUrl;
    let requestOptions;
    let currentUserEmail = localStorage.getItem("currentUserEmail");
    let owner = JSON.parse(localStorage.getItem("owner") ?? "{}");
    requestUrl = `${BASE_URL}/get-all-documents/v1?page=${page}&limit=${limit}&filter=${filter}&query=${query}&sortBy=${sortBy}&sortOrder=${sortOrder}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    requestOptions = {
      authorEmail: currentUserEmail,
      isStandAloneTemplate,
      ownerId: owner?._id,
    };

    let { data } = await axios.post(requestUrl, requestOptions);
    return data;
  } catch (error) {
    console.log("error in fetchAllDocuments");
    console.log(error);
  }
};

const fetchAllPdfTemplateResponses = async ({
  page = 1,
  limit = 10,
  filter = "ALL",
  query = "",
  dateFrom = "",
  dateTo = "",
  sortBy = "",
  sortOrder = "-1",
  documentId,
}: {
  page?: number;
  limit?: number;
  isStandAloneTemplate: boolean;
  filter?: string;
  query?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: string;
  sortOrder?: string;
  documentId: string;
}): Promise<
  { allRespondents?: any[]; document?: any; ownerId?: any } | undefined
> => {
  try {
    let requestUrl;
    requestUrl = `${BASE_URL}/pdf-template-respondents?documentId=${documentId}&page=${page}&limit=${limit}&filter=${filter}&query=${query}&sortBy=${sortBy}&sortOrder=${sortOrder}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

    let { data } = await axios.get(requestUrl);
    return data;
  } catch (error) {
    console.log("error in fetchAllDocuments");
    console.log(error);
  }
};

const fetchAllForms = async ({
  page = 1,
  limit = 10,
  isStandAloneTemplate = true,
  filter = "ALL",
  query = "",
  dateFrom = "",
  dateTo = "",
  sortBy = "",
  sortOrder = "-1",
}: {
  page?: number;
  limit?: number;
  isStandAloneTemplate: boolean;
  filter?: string;
  query?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: string;
  sortOrder?: string;
}): Promise<{ forms?: any[]; formCount?: number } | undefined> => {
  try {
    let requestOptions;
    let currentUserEmail = localStorage.getItem("currentUserEmail");
    let owner = JSON.parse(localStorage.getItem("owner") ?? "{}");
    requestOptions = {
      authorEmail: currentUserEmail,
      isStandAloneTemplate,
      ownerId: owner?._id,
    };

    let { data } = await axios.get(
      `/get-all-forms/v1?page=${page}&limit=${limit}&filter=${filter}&ownerId=${owner?._id}&currentUserEmail=${currentUserEmail}&query=${query}&sortBy=${sortBy}&sortOrder=${sortOrder}&dateFrom=${dateFrom}&dateTo=${dateTo}`
    );

    return data;
  } catch (error) {
    console.log("error in fetchAllDocuments");
    console.log(error);
  }
};

const fetchAllOverview = async ({
  page = 1,
  limit = 10,
  filter = "ALL",
  query = "",
  dateFrom = "",
  dateTo = "",
  sortBy = "",
  sortOrder = "-1",
  overViewType = "ALL",
}: {
  page?: number;
  limit?: number;
  isStandAloneTemplate: boolean;
  filter?: string;
  query?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: string;
  sortOrder?: string;
  overViewType?: string;
}): Promise<{ resources?: any[]; resourceCount?: number } | undefined> => {
  try {
    let owner = JSON.parse(localStorage.getItem("owner") ?? "{}");
    let { data } = await axios.get(
      `/get-overview?ownerId=${owner?._id}&page=${page}&limit=${limit}&filter=${filter}&query=${query}&sortBy=${sortBy}&sortOrder=${sortOrder}&dateFrom=${dateFrom}&dateTo=${dateTo}&overViewType=${overViewType}`
    );
    return data;
  } catch (error) {
    console.log("error in fetchAllOverview");
    console.log(error);
  }
};

export {
  fetchAllDocuments,
  fetchAllForms,
  fetchAllPdfTemplateResponses,
  fetchAllOverview,
};
