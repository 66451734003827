import React, { useState } from "react";
import {
  convertFilename,
  downloadFilePrivate,
  fileUploadType,
  formatFileSize,
} from "../Designer/PdfTemplateSidebar/AttachmentsView";
import ViewHeader from "../Designer/PdfTemplateSidebar/ViewHeader";
import { LuDownload } from "react-icons/lu";
import CustomTooltip from "../../../../../components/EditForm/CustomTooltip";
import getS3PreSignedUrl from "../../../../../utils/getS3PreSignedUrl";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { Slide, toast, ToastContainer } from "react-toastify";
import { MapperType, SetMapperType } from "../../../../common/src/type";
import { MdClose } from "react-icons/md";

const AttachmentSigningScreen = ({
  attachments,
  documentName,
  mapperState,
  setMapperState,
}: {
  attachments: fileUploadType[];
  documentName: string;
  setMapperState?: SetMapperType;
  mapperState?: MapperType;
}) => {
  const [loading, setLoading] = useState(false);
  const downloadAllFiles = async () => {
    setLoading(true);
    const zip = new JSZip();
    for (const attachment of attachments) {
      try {
        let url = await getS3PreSignedUrl(attachment?.fileUrl);

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch PDF");
        }
        const blob = await response.blob();
        const fileName = attachment?.fileName;
        zip.file(fileName, blob);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
    try {
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${documentName}_files_attachments.zip`);
    } catch (error) {
      console.error("Error generating zip:", error);
      toast.error("Failed to generate zip");
    }
    setLoading(false);
  };
  return (
    <>
      <div
        style={{
          background: "#ffffffed",
        }}
        className={`shadow hidden  duration-500 sm:block translate-x-[0] w-full sm:w-[250px] sm:max-w-[250px] sm:min-w-[250px]`}
      >
        <div
          className={`max-h-[94vh] overflow-y-auto overflow-x-hidden ${"hidden sm:block mt-[70px]"}`}
          id="mini-pdf-custom-scroll"
        >
          <div className="mb-4">
            <div className="border-b -mx-4  px-6 py-2 shadow-sm">
              <div className="flex justify-between items-center text-gray-700 p-2 text-sm">
                <span className="font-semibold">Attachments</span>
                <MdClose
                  className={`text-xl cursor-pointer translate-x-[0.5rem] mr-3`}
                  onClick={() => {
                    setMapperState &&
                      setMapperState({
                        ...mapperState,
                        showSideBar: "MINI_PDF",
                      });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <p className="text-gray-500 text-xs pr-4 mb-4">
              All attachments will be stored here and can be downloaded.
            </p>
            <div>
              <button
                className={`mb-4 flex gap-2 items-center justify-center w-full py-2 px-auto cursor-pointer text-xs text-gray-600 bg-slate-100 font-lightbold  rounded overflow-hidden hover:bg-slate-200`}
                onClick={downloadAllFiles}
              >
                <LuDownload size={18} />

                <span className="font-medium">
                  {!loading ? "Download All" : "Downloading"}
                </span>
              </button>
            </div>
            <div className="text-xs text-gray-500 my-4">ATTACHED BY SENDER</div>
            {attachments?.map((attachment: fileUploadType, index: number) => (
              <div
                className=" group border-b flex justify-between items-center hover:bg-slate-100 hover:text-primary cursor-pointer px-2"
                onClick={async () => {
                  let url = await getS3PreSignedUrl(attachment?.fileUrl);
                  window.open(url, "_blank");
                }}
              >
                <div className="py-2">
                  <div className="font-semibold text-xs text-ellipsis overflow-hidden">
                    {convertFilename(attachment)}
                  </div>
                  <div className="flex text-xs  py-1 gap-1  items-center">
                    <span className="text-gray-600 text-semibold">
                      {formatFileSize(attachment?.fileSize)}
                    </span>
                    <span className="w-1 h-1 bg-gray-500 rounded-full"></span>
                  </div>
                </div>
                <button
                  className="group-hover:inline-block hidden cursor-pointer"
                  onClick={async (e) => {
                    e.stopPropagation();
                    downloadFilePrivate(
                      attachment?.fileUrl,
                      attachment?.fileName
                    );
                  }}
                  data-tooltip-id="download-files"
                >
                  <LuDownload size={18} className="text-gray-600" />
                </button>
              </div>
            ))}
            <CustomTooltip
              helpers={{
                id: "download-files",
                content: "Download",
                place: "left",
              }}
            />
          </div>
        </div>
      </div>
      <div className="sm:hidden">
        <div
          style={{
            background: "rgba(0, 0, 0, 0.3)",
            zIndex: 100,
          }}
          className="w-full max-h-[94vh] mt-[70px] fixed inset-0 flex justify-center items-center"
        >
          <div
            className={` h-full md:h-[90%] w-full  md:w-fit
         relative bg-white  px-4 md:px-8  overflow-y-auto`}
            id="mini-pdf-custom-scroll"
          >
            <div className="w-[100%] relative flex flex-col">
              <MdClose
                className="absolute top-2 md:top-3 right-0 cursor-pointer text-2xl"
                onClick={() => {
                  setMapperState &&
                    setMapperState({ ...mapperState, showSideBar: "MINI_PDF" });
                }}
              />
            </div>
            <div className="mb-4">
              <div className="border-b -mx-4  px-6 py-2 shadow-sm">
                <div className="flex justify-between items-center text-gray-700 p-2 text-sm">
                  <span className="font-semibold">Attachments</span>
                </div>
              </div>
            </div>
            <div className="px-3">
              <p className="text-gray-500 text-xs pr-4 mb-4">
                All attachments will be stored here and can be downloaded.
              </p>
              <div>
                <button
                  className={`mb-4 flex gap-2 items-center justify-center w-full py-2 px-auto cursor-pointer text-xs text-gray-600 bg-slate-100 font-lightbold  rounded overflow-hidden hover:bg-slate-200`}
                  onClick={downloadAllFiles}
                  disabled={loading}
                >
                  <LuDownload size={18} />

                  <span className="font-medium">
                    {!loading ? "Download All" : "Downloading"}
                  </span>
                </button>
              </div>
              <div className="text-xs text-gray-500 my-4">
                ATTACHED BY SENDER
              </div>
              {attachments?.map((attachment: fileUploadType, index: number) => (
                <div
                  className=" group border-b flex justify-between items-center hover:bg-slate-100 hover:text-primary cursor-pointer px-2"
                  onClick={async () => {
                    let url = await getS3PreSignedUrl(attachment?.fileUrl);
                    window.open(url, "_blank");
                  }}
                >
                  <div className="py-2">
                    <div className="font-semibold text-xs text-ellipsis overflow-hidden">
                      {convertFilename(attachment)}
                    </div>
                    <div className="flex text-xs  py-1 gap-1  items-center">
                      <span className="text-gray-600 text-semibold">
                        {formatFileSize(attachment?.fileSize)}
                      </span>
                      <span className="w-1 h-1 bg-gray-500 rounded-full"></span>
                    </div>
                  </div>
                  <button
                    className="group-hover:inline-block hidden cursor-pointer"
                    onClick={async (e) => {
                      e.stopPropagation();
                      downloadFilePrivate(
                        attachment?.fileUrl,
                        attachment?.fileName
                      );
                    }}
                    data-tooltip-id="download-files"
                  >
                    <LuDownload size={18} className="text-gray-600" />
                  </button>
                </div>
              ))}
              <CustomTooltip
                helpers={{
                  id: "download-files",
                  content: "Download",
                  place: "left",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </>
  );
};

export default AttachmentSigningScreen;
