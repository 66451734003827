// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { fetchOwner, getOwnerPlanDetails } from "../../utils";
import axios from "./../../utils/axios";
import axios_ from "axios";
import { Navigate } from "react-router-dom";
import PlanCards from "../../components/Billings/PlanCards";
import ComparisonTable from "../../components/Billings/ComparisonTable";
import AddOnCard from "../../components/Billings/AddOnCard";
import {
  getDashboardUrl,
  PAYMENTS_BASE_URL,
  SMS_CREDIT_PAYMENT_PRICE_ID,
} from "../../constants/constants";
import Slider from "@mui/material/Slider";
import { FaInfoCircle, FaCheck } from "react-icons/fa";
import "./index.css";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { isFeatureEnabled } from "../../utils/paidFeat";
import { Slide, ToastContainer, toast } from "react-toastify";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import { BLOCKING } from "../../constants/blockingConsants";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import useWhiteLabelHelper from "../../hooks/reduxHooks/useWhiteLabelHelper";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import WLPriceContact from "../../components/Billings/WLPriceContact";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdHistory } from "react-icons/md";
import SMSCreditTransactionHistoryModal from "../../components/Modals/SMSCreditTransactionHistoryModal";
import Modal from "../../components/Modal";
import BoloModal from "../../components/Common/Modal/BoloModal";

const Billings = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const whiteLabelHelper = useWhiteLabelHelper();
  const [owner, setOwner] = useState({});
  const [ownerPlanDetails, setOwnerPlanDetails] = useState(
    getOwnerPlanDetails()
  );
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [oneTimeQuota, setOneTimeQuota] = useState(100);
  const [showCompare, setShowCompare] = useState(true);
  const [appSumoDetails, setAppSumoDetails] = useState({
    isAppSumo: false,
    planId: "",
    responseUnlimited: false,
    seatsUnlimited: false,
    isEnterprise: false,
  });
  const [showExtraQuota, setShowExtraQuota] = useState({
    show: false,
    type: "WORKSPACE",
  });
  const [upgradeModalData, setUpgradeModalData] = useState({
    data: { title: "", desc: "", videoSrc: "" },
    show: false,
  });
  const [workSpacesEnabled, setWorkSpacesEnabled] = useState(false);
  const [showQuota, setShowQuota] = useState(false);
  const [quotaIsUnlimited, setQuotaIsUnlimited] = useState({
    documentCreated: false,
    templateCreated: false,
    responseFilled: false,
    pdfTemplateCreated: false,
    pdfTemplateResponse: false,
    noOfSeats: false,
    workSpaces: false,
  });
  const [primaryColor, setPrimaryColor] = useState(
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary
  );

  const appSumoPlanIDs = [
    "boloformssignature_tier1",
    "boloformssignature_tier2",
    "boloformssignature_tier3",
  ];

  const targetRef = useRef(null);

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const checkFeature = async () => {
    const isWorkSpaceEnabled = await isFeatureEnabled("WORKSPACE");
    setWorkSpacesEnabled(isWorkSpaceEnabled);
  };

  const [showSMSHistoryModal, setShowSMSHistoryModal] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      let user = await fetchOwner(
        JSON.parse(localStorage.getItem("owner"))._id
      );
      let newState = {
        isAppSumo: false,
        planId: "",
        responseUnlimited: false,
        seatsUnlimited: false,
        isEnterprise: false,
      };
      // console.log(
      //   user?.planhistory[user?.planhistory?.length - 1]?.tier,
      //   user?.planhistory[user?.planhistory?.length - 1]?.tier?.includes(
      //     "APP_SUMO_ENTERPRISE_TIER"
      //   )
      // );
      if (user?.from == "APPSUMO") {
        newState.isAppSumo = true;
      }

      if (
        user?.planhistory[user?.planhistory?.length - 1]?.tier?.includes(
          "APP_SUMO_ENTERPRISE_TIER"
        )
      ) {
        newState.isEnterprise = true;
      }

      let lastPlan = user?.planhistory?.[user?.planhistory?.length - 1];
      newState.planId = lastPlan?.planId;

      if (lastPlan?.planId == "boloformssignature_tier2") {
        newState.responseUnlimited = true;
      }
      if (lastPlan?.planId == "boloformssignature_tier3") {
        newState.responseUnlimited = true;
        newState.seatsUnlimited = true;
      }
      setAppSumoDetails(newState);
      setOwner(user);
      setOwnerPlanDetails(getOwnerPlanDetails());
      setCurrentPlan(user?.planhistory[user?.planhistory?.length - 1]);
    };
    getUser();
    checkFeature();
  }, []);

  useEffect(() => {
    if (appSumoDetails.isAppSumo) {
      setOneTimeQuota(1);
    }
  }, [appSumoDetails.isAppSumo]);

  useEffect(() => {
    if (
      currentPlan?.tier?.includes("APP_SUMO_ENTERPRISE_TIER") ||
      currentPlan?.tier?.includes("TIER_1") ||
      currentPlan?.tier?.includes("TIER_2")
    ) {
      setShowQuota(true);
    }

    if (
      currentPlan?.tier?.includes("TIER_3_V1") ||
      currentPlan?.tier?.includes("BOLOFORMS_LIFETIME")
    ) {
      setQuotaIsUnlimited({
        documentCreated: true,
        templateCreated: true,
        responseFilled: true,
        pdfTemplateCreated: true,
        pdfTemplateResponse: true,
        noOfSeats: true,
        workSpaces: false,
      });
    }

    if (
      currentPlan?.tier?.includes("TIER_3") ||
      currentPlan?.tier?.includes("APP_SUMO_ENTERPRISE_TIER") ||
      currentPlan?.tier?.includes("APP_SUMO_BUSSINESS_TIER") ||
      currentPlan?.tier?.includes("BOLOFORMS_LIFETIME")
    ) {
      setShowExtraQuota({ show: true, type: "WORKSPACE" });
      setOneTimeQuota(1);
    } else if (
      currentPlan?.tier?.includes("TIER_1") ||
      currentPlan?.tier?.includes("TIER_2") ||
      currentPlan?.tier?.includes("FREE_TIER")
    ) {
      setShowExtraQuota({ show: true, type: "SIGNATURE" });
      setOneTimeQuota(100);
    }
  }, [appSumoDetails, currentPlan]);

  useEffect(() => {
    setPrimaryColor(
      whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary
    );
  }, [whiteLabelInfo]);

  console.log({ workSpacesEnabled, showExtraQuota });

  const handleMangeBillings = async () => {
    try {
      let { data } = await axios.get(
        `/manageBillings?stripeCustomerId=${stripeCustomerId}`
      );

      const billingsPageUrl = data.data;
      window.open(billingsPageUrl, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const handleBuyPremium = async () => {
    window.open(`https://signature.boloforms.com/billings`, "_blank");
    return;
    //! hardcoded the frontEnd url here
  };

  const [isAnnualBilling, setIsAnnualBilling] = useState(true);
  const [isIndianPrice, setIsIndianPrice] = useState(false);
  const [isQuotaError, setIsQuotaError] = useState(false);

  // async function getCountry() {
  //   let { data } = await axios("https://ipinfo.io/json?token=cbe652e779e0c6");
  //   // console.log(data);
  //   let country = data.country;
  //   setIsIndianPrice(country === "IN");

  //   return country;
  // }

  //   useEffect(() => {
  //     owner?.stripeCustomerId && setStripeCustomerId(owner?.stripeCustomerId);
  //     owner?.planhistory &&
  //       setCurrentPlan(owner?.planhistory[owner?.planhistory?.length - 1]);
  //   }, [owner]);

  // useEffect(() => {
  //   // fetchOwner();

  //   (async () => {
  //     let country = localStorage.getItem("country");
  //     if (
  //       !country ||
  //       country === "" ||
  //       country === "null" ||
  //       country === "undefined"
  //     ) {
  //       country = await getCountry();
  //       console.log(country);
  //       localStorage.setItem("country", country || "");
  //     }
  //     setIsIndianPrice(country === "IN");
  //   })();
  // }, []);

  /* This is checking if the user is already logged in. If the user is logged in, it will redirect to
  the dashboard. */

  const addOnsData = [
    {
      title: "API",
      desc: "Automate your signature workflow by integrating our API into your systems.",
      features: ["100 Documents/mo Included", "$0.2 per Document Sent"],
    },
    {
      title: "Embedding",
      desc: "Add BoloForms PDF sending and signing in your website",
      features: [
        "Customizable Branding",
        "Session Control",
        "Create PDFs",
        "Send PDFs for signature",
        "Fetch PDF status in your website",
        "And more...",
      ],
    },
    // {
    //   title: "Custom Development",
    //   desc: "Want us to build signature workflow and integrate it into your systems?",
    //   features: ["Available on Enterprise Plan and with fixed pricing"],
    // },
    {
      title: "HIPAA",
      desc: "Add security to your medical pratice by purchasing HIPAA business associate agreement.",
      features: ["HIPAA BAA is Available on Team Plan and above"],
      // startingPrice: "Starting at $99",
    },
  ];

  let changeAppSumoOneTime = async () => {
    try {
      let { data } = await axios_.post(
        `${PAYMENTS_BASE_URL}/changeSubscriptionPlanUS`,
        {
          priceId: "price_1OV6FsLiGbRu8waG5LR8dEVL",
          quantity: oneTimeQuota,
          ownerEmail: owner?.email,
        }
      );
      let { sessionUrl } = data;

      toast.success(
        "WorkSpaces Updated the page will refresh in 5 seconds !!!"
      );
      setTimeout(() => {
        window.location.reload();
      }, 5000);

      // window.open(sessionUrl, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  let createAppSumoOneTime = async () => {
    try {
      let { data } = await axios_.post(
        `${PAYMENTS_BASE_URL}/createStripeSubscriptionUS`,
        {
          priceId: "price_1OV6FsLiGbRu8waG5LR8dEVL",
          quantity: oneTimeQuota,
          ownerEmail: owner?.email,
        }
      );
      let { sessionUrl } = data;

      window.open(sessionUrl, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  let changeOneTimeNormal = async () => {
    try {
      let { data } = await axios_.post(
        `${PAYMENTS_BASE_URL}/createStripeOneTimePaymentUS`,
        {
          priceId: "price_1OUquFLiGbRu8waGaT8FeB7U",
          quantity: Math.round(oneTimeQuota / 100),
          ownerEmail: owner?.email,
        }
      );
      let { sessionUrl } = data;

      window.open(sessionUrl, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOneTimeQuotaChange = (event, newValue) => {
    setOneTimeQuota(newValue);
  };

  const buyCredits = async () => {
    try {
      let { data } = await axios_.post(
        `${PAYMENTS_BASE_URL}/createStripeOneTimePaymentUS`,
        {
          priceId: SMS_CREDIT_PAYMENT_PRICE_ID,
          quantity: 1,
          ownerEmail: owner?.email,
          successUrl: `${getDashboardUrl()}/billings`,
          cancelUrl: `${getDashboardUrl()}/billings`,
        }
      );

      // if (!data?.success) {
      //   throw new Error("Failed to buy credits");
      // }

      let sessionUrl = data?.sessionUrl;

      if (!sessionUrl) {
        throw new Error("Failed to buy credits");
      } else {
        window.open(sessionUrl, "_self");
      }
    } catch (error) {
      console.log("Error buying credits", error);
      // toast.error("Failed to buy credits");
    }
  };

  let user = localStorage.getItem("owner");
  if (!user) {
    console.log("user not found");
    return <Navigate to="/login" />;
    //! uncomment this
  }

  return (
    <div className="my-4 mx-2 flex flex-col">
      <Helmet>
        <title translate="no">Billings</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Billings" />
        <meta
          name="description"
          content="This is Boloforms signature Billings page."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/billings`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Billings`} />
        <meta
          property="og:description"
          content="This is Boloforms signature Billing page."
        />
        <link rel="canonical" href="https://signature.boloforms.com/billings" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {upgradeModalData.show && (
        <PleaseUpgradeModal
          show={upgradeModalData.show}
          setShow={(value) =>
            setUpgradeModalData((prev) => ({
              ...prev,
              show: value,
              data: BLOCKING.BLANK,
            }))
          }
          data={upgradeModalData.data}
        />
      )}
      <h2 className="font-bold md:text-lg text-gray-700 mb-1">
        Welcome to Billings Section
      </h2>
      <p className="text-xs text-gray-600 mb-8">
        See how you have been using BoloForms from here.
      </p>
      <div className="flex flex-col xl:flex-row gap-[30px] w-full justify-between h-fit xl:h-[480px]">
        <div className="flex-1 w-full max-w-[100%] xl:max-w-[500px] border px-6 pt-6 pb-12 rounded-md overflow-hidden relative h-full">
          <div
            className={`${
              currentPlan?.isActive
                ? "text-green-600 bg-green-100"
                : "text-red-600 bg-red-100"
            } w-fit text-center text-[10px] m-2 font-medium px-6 py-1.5 absolute top-0 right-0 rounded-md`}
          >
            {currentPlan?.isActive
              ? appSumoDetails.isAppSumo || ownerPlanDetails?.isLifeTime
                ? "ACTIVE LIFETIME"
                : "ACTIVE"
              : "EXHAUSTED"}
          </div>

          {currentPlan?.subscriptionId === "ONE_TIME_PAYMENT" && (
            <h2 className="my-2 font-semibold text-sm">
              Your current plan will expire on{" "}
              {currentPlan?.planEndDate
                ? new Date(currentPlan?.planEndDate).toLocaleDateString()
                : "N/A"}
            </h2>
          )}
          <div className="mb-4">
            <p className="text-lg font-semibold mb-6 p-1 text-gray-700">
              {currentPlan?.planName ?? ""}
            </p>

            <p className="p-1 flex justify-between items-center text-sm mb-2">
              <span className="mr-2 text-gray-600 font-semibold">
                Plan Price{" "}
              </span>
              <span className="font-semibold text-gray-600">
                {`${currentPlan?.currencySymbol}${currentPlan?.planPrice}`}
              </span>
            </p>
            <p className="p-1 flex justify-between items-center text-sm mb-1">
              <span className="mr-2 text-xs text-gray-600">
                Documents created{" "}
              </span>
              {appSumoDetails.isAppSumo || quotaIsUnlimited.documentCreated ? (
                <span className="font-semibold text-xs text-gray-600">
                  Unlimited
                </span>
              ) : (
                <span className="font-semibold text-xs text-gray-600">
                  {currentPlan?.usedQuanity?.[0] +
                    "/" +
                    currentPlan?.usageQuantityAllowed?.[0]}
                </span>
              )}
            </p>
            <p className="p-1 flex justify-between items-center text-sm mb-1">
              <span className="mr-2 text-xs text-gray-600">
                Templates Created{" "}
              </span>
              {appSumoDetails.isAppSumo || quotaIsUnlimited.templateCreated ? (
                <span className="font-semibold text-xs text-gray-600">
                  Unlimited
                </span>
              ) : (
                <span className="font-semibold text-xs text-gray-600">
                  {currentPlan?.usedQuanity?.[3] +
                    "/" +
                    currentPlan?.usageQuantityAllowed?.[3]}
                </span>
              )}
            </p>
            <p className="p-1 flex justify-between items-center text-sm mb-1">
              <span className="mr-2 text-xs text-gray-600">
                Responses filled{" "}
              </span>
              {(appSumoDetails.isAppSumo && appSumoDetails.responseUnlimited) ||
              quotaIsUnlimited.responseFilled ? (
                <span className="font-semibold text-xs text-gray-600">
                  Unlimited
                </span>
              ) : (
                <span className="font-semibold text-xs text-gray-600">
                  {currentPlan?.usedQuanity?.[2] || 0}/
                  {currentPlan?.usageQuantityAllowed?.[2] || "unlimited"}
                </span>
              )}
            </p>
            <p className="p-1 flex justify-between items-center text-sm mb-1">
              <span className="mr-2 text-xs text-gray-600">
                PDF Template created{" "}
              </span>
              {appSumoDetails.isAppSumo ||
              quotaIsUnlimited.pdfTemplateCreated ? (
                <span className="font-semibold text-xs text-gray-600">
                  Unlimited
                </span>
              ) : (
                <span className="font-semibold text-xs text-gray-600">
                  {currentPlan?.usedQuanity?.[4] || 0}/
                  {currentPlan?.usageQuantityAllowed?.[4] || "unlimited"}
                </span>
              )}
            </p>
            <p className="p-1 flex justify-between items-center text-sm mb-1">
              <span className="mr-2 text-xs text-gray-600">
                PDF Template responses{" "}
              </span>
              {appSumoDetails.isAppSumo ||
              quotaIsUnlimited.pdfTemplateResponse ? (
                <span className="font-semibold text-xs text-gray-600">
                  Unlimited
                </span>
              ) : (
                <span className="font-semibold text-xs text-gray-600">
                  {currentPlan?.usedQuanity?.[5] || 0}/
                  {currentPlan?.usageQuantityAllowed?.[5] || "unlimited"}
                </span>
              )}
            </p>
            {appSumoDetails.isAppSumo && (
              <p className="p-1 flex justify-between items-center text-sm mb-1">
                <span className="mr-2 text-xs text-gray-600">No of Seats </span>
                {(appSumoDetails.isAppSumo && appSumoDetails.seatsUnlimited) ||
                quotaIsUnlimited.noOfSeats ? (
                  <span className="font-semibold">Unlimited</span>
                ) : (
                  <span className="font-semibold text-xs text-gray-600">
                    {currentPlan?.usedQuanity?.[6] || 0}/
                    {currentPlan?.usageQuantityAllowed?.[6] || "0"}
                  </span>
                )}
              </p>
            )}
            <p className="p-1 flex justify-between items-center text-sm mb-1">
              <span className="mr-2 text-xs text-gray-600">
                No of WorkSpaces{" "}
              </span>
              <span className="font-semibold text-xs text-gray-600">
                {currentPlan?.usedQuanity?.[7] || `1`} /
                {currentPlan?.usageQuantityAllowed?.[7] || `1`}
              </span>
            </p>
            <div className="p-1 flex justify-between items-center text-sm mb-1 ">
              <p className="flex gap-2 items-center mr-2 text-xs text-gray-600">
                SMS Credits
                <BoloButton
                  size={"xxs"}
                  variant={"tertiary"}
                  className="font-medium"
                  onClick={buyCredits}
                >
                  Buy Credits
                </BoloButton>
              </p>
              <p
                className={`font-semibold text-xs flex gap-2 items-center ${
                  (currentPlan?.smsCredits?.count || 0) < 10 && "text-red-500"
                } `}
              >
                {currentPlan?.smsCredits?.count || 0} remaining
                <span
                  className={`p-1 rounded-md bg-gray-200 cursor-pointer transition-all ease-in-out duration-200 ${
                    (currentPlan?.smsCredits?.count || 0) < 10 &&
                    "hover:bg-red-300"
                  } `}
                  onClick={(e) => {
                    setShowSMSHistoryModal(true);
                  }}
                >
                  <MdHistory size={18} />
                </span>
              </p>
            </div>

            {currentPlan?.isActive && !currentPlan?.planId == "FREE_PLAN" && (
              <>
                <p className="p-1 flex justify-between items-center text-sm mb-1">
                  <span className="mr-2">Charged At </span>
                  <span className="font-semibold">
                    {new Date(
                      Number(currentPlan?.planStartDate)
                    ).toLocaleDateString()}
                    {"\u00A0"}{" "}
                    {new Date(
                      Number(currentPlan?.planStartDate)
                    ).toLocaleTimeString()}
                  </span>
                </p>
                <p className="p-1 flex justify-between items-center text-sm mb-1">
                  <span className="mr-2">Ends On </span>
                  <span className="font-semibold">
                    {new Date(
                      Number(currentPlan?.planEndDate)
                    ).toLocaleDateString()}
                    {"\u00A0"}{" "}
                    {new Date(
                      Number(currentPlan?.planEndDate)
                    ).toLocaleTimeString()}
                  </span>
                </p>
              </>
            )}
          </div>

          {currentPlan?.planId !== "FREE_PLAN" && !appSumoDetails.isAppSumo && (
            <button
              onClick={() => {
                new MixPanel().track("Billing", {
                  billing_action: "billing_manage",
                });
                new MixPanel().increment("billing_manage");
                window.open(
                  `https://billing.stripe.com/p/login/00g6pf8DRh06a5y5kk`,
                  "_blank"
                );
              }}
              className={`absolute bottom-6 left-6 mt-4 px-4 bg-primary text-white hover:border-primary border-2 block py-1.5 text-sm font-medium text-center  rounded-md  hover:bg-primary`}

              // className="absolute bottom-4 left-4 mt-4 px-4 py-2 bg-primary text-sm text-white rounded-lg hover:opacity-90 mr-4"
            >
              Manage Billings
              {/* {stripeCustomerId ?  : "Buy Premium"} */}
            </button>
          )}
          {appSumoDetails.isAppSumo && (
            <a
              // onClick={() => {
              //   window.open(
              //     JSON.parse(localStorage.getItem("owner"))?.planhistory[
              //       JSON.parse(localStorage.getItem("owner"))?.planhistory
              //         ?.length - 1
              //     ]?.invoiceUrl,
              //     "_blank"
              //   );
              // }}
              href={currentPlan?.invoiceUrl}
              target="_blank"
              className="cursor-pointer absolute bottom-4 left-4 mt-4 px-4 py-2 bg-primary text-sm text-white rounded-lg hover:opacity-90 mr-4"
              rel="noreferrer"
            >
              Upgrade AppSumo Plan
            </a>
          )}
        </div>

        {whiteLabelHelper.isWhiteLabel && <WLPriceContact />}
        {showExtraQuota.show && !whiteLabelHelper.isWhiteLabel && (
          <div className=" flex-1 w-full flex flex-col border rounded-md h-full">
            <div className="px-4 lg:px-8 mt-6 w-full font-medium flex flex-col justify-center">
              <h2 className="text-xl font-semibold text-gray-700">
                {showExtraQuota.type == "WORKSPACE"
                  ? "Additional WorkSpaces"
                  : "Need more quota?"}
              </h2>
              <p className="mt-2 text-xs text-gray-400">
                {showExtraQuota.type == "WORKSPACE"
                  ? "Add more WorkSpaces to manage different clients/companies with their branding."
                  : "Add more quota to your current plan."}
              </p>
            </div>

            <div className="mt-4 w-full flex flex-col px-4 lg:px-8 py-5 border-b-[2px] border-gray-200 ">
              <div className="flex items-end justify-between mb-5">
                <span className="text-sm font-medium text-gray-500">
                  {oneTimeQuota}{" "}
                  {showExtraQuota.type == "WORKSPACE"
                    ? "Workspaces"
                    : "SIGNATURES"}
                </span>
                <span className="text-[35px] flex gap-x-1 items-end font-semibold">
                  $
                  {showExtraQuota.type == "WORKSPACE"
                    ? Math.round(oneTimeQuota * 29)
                    : Math.round((oneTimeQuota / 1000) * 200)}
                  <span className="text-xs text-gray-500 font-bold mb-3">
                    /month
                  </span>
                </span>
              </div>
              <div className="w-[100%]">
                <Slider
                  sx={{
                    "& .MuiSlider-thumb": {
                      color: primaryColor,
                    },
                    "& .MuiSlider-track": {
                      color: primaryColor + "80",
                    },
                    "& .MuiSlider-rail": {
                      color: primaryColor + "40",
                    },
                    "& .MuiSlider-active": {
                      color: primaryColor,
                    },
                  }}
                  value={oneTimeQuota}
                  onChange={handleOneTimeQuotaChange}
                  valueLabelDisplay="auto"
                  aria-label="One-time Quota Slider"
                  step={showExtraQuota.type == "WORKSPACE" ? 1 : 100}
                  min={showExtraQuota.type == "WORKSPACE" ? 1 : 100}
                  max={showExtraQuota.type == "WORKSPACE" ? 100 : 10000}
                />
              </div>
              {showExtraQuota.type == "WORKSPACE" ? (
                <div className="w-ful flex items-center gap-1 text-sm px-4 py-1.5 mt-[10px] text-primary bg-secondary w-fit rounded-md">
                  <FaInfoCircle size={15} className="mr-2" />
                  <span className="text-xs">
                    each Workspace allow you to manage a different
                    company/client with their branding
                  </span>
                </div>
              ) : (
                <div className="flex items-top gap-1 text-[13px] py-1 mt-[10px] text-primary bg-secondary w-fit rounded px-2">
                  <FaInfoCircle size={15} className="mt-1" />
                  {owner.planhistory &&
                  (!owner?.planhistory[owner?.planhistory?.length - 1]
                    ?.subscriptionId ||
                    owner?.planhistory[owner?.planhistory?.length - 1]
                      ?.subscriptionId === "ONE_TIME_PAYMENT") ? (
                    <span>
                      New signatures will be added to the current quota.
                      Signatures will expire after{" "}
                      <span className="font-semibold">1 month</span> from the
                      date of purchase.
                    </span>
                  ) : (
                    <span>
                      New signatures will be added to the current quota.
                      Signatures will valid till your subscription renews.
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="w-full flex flex-col gap-x-2 gap-y-5 px-4 lg:px-8 py-5">
              <button
                className={`mb-4 px-4 bg-primary text-white hover:border-primary border-2 block w-fit py-1.5 text-sm font-medium text-center  rounded-md  hover:bg-primary`}
                onClick={async () => {
                  new MixPanel().track("Billing", {
                    billing_action: "billing_add_workspace",
                    quantity: oneTimeQuota,
                    paid: true,
                  });
                  new MixPanel().increment("billing_add_workspace");
                  if (appSumoDetails.isAppSumo && !workSpacesEnabled) {
                    setUpgradeModalData((prev) => ({
                      ...prev,
                      show: true,
                      data: {
                        title:
                          "WorkSpaces are available in AppSumo EnterPrise Tier",
                        desc: `You are on AppSumo Business Tier upgrade to use this feature.`,
                        redirect: currentPlan?.invoiceUrl,
                      },
                    }));
                    return;
                  }
                  if (showExtraQuota.type == "WORKSPACE") {
                    if (owner?.stripeCustomerId) {
                      await changeAppSumoOneTime();
                    } else {
                      await createAppSumoOneTime();
                    }
                  } else {
                    await changeOneTimeNormal();
                  }
                }}
              >
                {showExtraQuota.type == "WORKSPACE"
                  ? "Add workspace"
                  : "Get Quota"}
              </button>
              <div className="flex flex-col gap-y-3">
                <div className="flex items-center gap-x-3 text-sm text-gray-500 font-medium">
                  <BsCheckCircleFill className="text-xs text-green-600" />
                  {showExtraQuota.type == "WORKSPACE"
                    ? "Branding Organization based on WorkSpaces"
                    : "Pricing is per 100 signatures for Every Plan"}
                </div>
                <div className="flex items-center gap-x-3 text-sm text-gray-500 font-medium">
                  <BsCheckCircleFill className="text-xs text-green-600" />
                  24/7 Email & chat support
                </div>
                <div className="flex items-center gap-x-3 text-sm text-gray-500 font-medium">
                  <BsCheckCircleFill className="text-xs text-green-600" />
                  100% Data ownership
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!whiteLabelHelper.isWhiteLabel && (
        <div
          ref={targetRef}
          className="my-12 mx-auto gap-y-10 w-full flex flex-col items-center justify-center max-w-[1300px]"
        >
          <div className="flex flex-col gap-y-5 py-4 w-full">
            <div className="flex items-center justify-between">
              <h3 className="text-2xl text-gray-800 font-semibold pl-1">
                Plans
              </h3>
              <div className="text-xs mt-3 sm:text-xs flex items-center justify-end">
                <span className="font-medium mr-3">Monthly billing</span>
                <label
                  className={`switch-button m-0 ${
                    isAnnualBilling && "switch-button-checked"
                  }`}
                >
                  <input
                    type="checkbox"
                    id="isApprovalWorkflow"
                    name="isApprovalWorkflow"
                    checked={isAnnualBilling}
                    onChange={() => setIsAnnualBilling((prev) => !prev)}
                  />
                  <div></div>
                </label>
                <span className="font-medium ml-3">Annual billing</span>
                <div className=" flex items-center justify-center ml-2 rounded-3xl bg-green-600 text-white px-3 py-1 text-[10px] font-medium">
                  Upto 40% Off
                </div>
              </div>
            </div>
            <PlanCards isAnnualBilling={isAnnualBilling} owner={owner} />
          </div>

          <div className="flex flex-col gap-y-5 py-4 mt-0 w-full">
            <h3 className="-mb-8 text-2xl text-gray-800 font-semibold pl-1 flex items-center">
              <span className="mr-3">Compare Plans</span>{" "}
              {showCompare ? (
                <AiFillMinusCircle
                  className="cursor-pointer"
                  onClick={() => {
                    setShowCompare(false);
                  }}
                />
              ) : (
                <AiFillPlusCircle
                  className="cursor-pointer"
                  onClick={() => {
                    setShowCompare(true);
                  }}
                />
              )}
            </h3>
            {showCompare && <ComparisonTable scrollToTarget={scrollToTarget} />}
          </div>
          {!whiteLabelHelper?.isWhiteLabel && (
            <div className="flex flex-col gap-y-5 py-4 w-full">
              <h3 className="text-2xl text-gray-800 font-semibold pl-1 flex items-center">
                Add-Ons
              </h3>
              <div className="flex flex-col items-center justify-center xl:flex-row xl:items-start">
                {addOnsData.map((addOn, i) => {
                  return (
                    <AddOnCard
                      key={i}
                      {...addOn}
                      isAnnualBilling={isAnnualBilling}
                      owner={owner}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}

      {!whiteLabelHelper.isWhiteLabel && (
        <div className="pb-16 flex items-center justify-center">
          <img className="" src="https://i.imgur.com/Fi0xb3x.png" alt="Image" />
        </div>
      )}

      {/* <div className="pb-20">
        <div
          style={{ boxShadow: "0px 20px 40px 0px rgba(2, 30, 70, 0.1)" }}
          className="my-12 w-full flex flex-col gap-y-4 items-center p-4 md:p-10 md:flex-row rounded-3xl"
        >
          <div className="h-16 w-16 sm:h-32 sm:w-32 rounded-full overflow-hidden">
            <img
              alt="User Image"
              src="https://www.boloforms.com/_next/static/media/deepak.e041dab0.jpeg"
              className="h-full w-full object-cover"
              loading="lazy"
            />
          </div>
          <div style={{ width: "calc(100% - 64px)" }} className="pl-4 sm:pl-12">
            <div className="h-6 mb-4">
              <img
                alt="Rating Stars"
                src="https://www.boloforms.com/_next/static/media/Stars.885edd0c.svg"
                className="w-32 h-full"
                loading="lazy"
              />
            </div>
            <p className="mb-2 text-sm sm:text-base">
              "Easy to use interface and timely support...looking for more
              features."
            </p>
            <h3 className="text-base sm:text-lg font-semibold">
              Deepak S (Solero Corporation)
            </h3>
          </div>
        </div>
      </div> */}

      {showSMSHistoryModal && (
        <BoloModal
          content={
            <SMSCreditTransactionHistoryModal
              setShow={setShowSMSHistoryModal}
            />
          }
          isWidthFit={true}
          setShow={setShowSMSHistoryModal}
          isPaddingZero={true}
          iconPositionString="top-1 right-1"
        />
      )}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default Billings;
