import { BiSolidUpArrowCircle } from "react-icons/bi";
import BoloModal from "../../../../components/Common/Modal/BoloModal";
import { ChangeSchemasArg } from "./Designer";
import { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { getWhiteLabelInfoLocal } from "../../../../utils";
import { Slide, toast, ToastContainer } from "react-toastify";
import { S3PATHS } from "../../../../constants/s3Folders";
import { generalizedFileUploadViaSignedUrlPublic } from "../../../../utils/fileUpload";
import { SchemaForUI } from "../../../common/src";
import { COLORS } from "../../../../constants/common";

const UploadFileModal = ({
  onChange,
  setShowUploadFileModal,
  brandingColor,
  schema,
  setFileUploadName,
  isSigning,
  isEditorARecipient,
}: {
  onChange: ((value: string, extra?: ChangeSchemasArg[]) => void) | null;
  setShowUploadFileModal: React.Dispatch<React.SetStateAction<boolean>>;
  brandingColor: any;
  schema: SchemaForUI;
  setFileUploadName: React.Dispatch<React.SetStateAction<string>>;
  isSigning?: boolean;
  isEditorARecipient?: boolean;
}) => {
  const [loaders, setLoaders] = useState<{ uploading: boolean }>({
    uploading: false,
  });
  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const handleFileInputChange = async (
    e: any,
    type: "input" | "drop" = "input"
  ) => {
    setLoaders((prev) => ({ ...prev, uploading: true }));
    let file: File | null = null;
    if (type == "drop") {
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }
    if (file != null) {
      if (file?.size > 5 * 1024 * 1024) {
        setLoaders((prev) => ({ ...prev, uploading: false }));
        toast.error("File should not be larger than 5mb!");
        return;
      }
      if (isSigning || isEditorARecipient) {
        let link = await generalizedFileUploadViaSignedUrlPublic({
          file: file,
          folderName: S3PATHS.STATIC_ASSETS,
        });
        setFileUploadName(file?.name);

        if (link) {
          onChange && onChange(link);
          setLoaders((prev) => ({ ...prev, uploading: false }));
          setShowUploadFileModal(false);
        }
      } else {
        onChange && onChange(file?.name);
        setFileUploadName(file?.name);
        setLoaders((prev) => ({ ...prev, uploading: false }));
        setShowUploadFileModal(false);
      }
    }
  };
  return (
    <div>
      <BoloModal
        setShow={setShowUploadFileModal}
        content={
          <>
            <div className="text-base md:text-lg mb-4 font-semibold">
              Upload File
            </div>
            <div className=" mb-4 text-xs text-gray-500">
              Our per-file upload limit is 5 MB.
            </div>
            <div>
              <label
                htmlFor="dropzone-file"
                className={`flex flex-col items-center justify-center p-8 border-2 border-gray-300 hover:border-gray-500 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-full text-gray-600 transition-all ease-in-out duration-150 `}
                onDrop={(e) => {
                  // handleDrop(e);
                  handleFileInputChange(e, "drop");
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="">
                  {!loaders.uploading ? (
                    <div className="gap-8 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                      <div className="p-2 md:p-4 bg-gray-200 rounded-full">
                        <BiSolidUpArrowCircle
                          size={50}
                          className="text-gray-400"
                        />
                      </div>
                      <div className="m-0 flex flex-col items-center text-sm md:text-base">
                        <p>
                          <span
                            style={{ color: brandingColor?.theme }}
                            className="text-primary mr-1 font-medium"
                          >
                            Browse
                          </span>{" "}
                          or drag file here
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="gap-8 flex flex-row md:flex-col items-center justify-center py-2 md:py-6">
                        <div className="p-2 md:p-4  rounded-full">
                          <TailSpin
                            height="60"
                            width="60"
                            color={
                              brandingColor?.theme ||
                              whiteLabelInfo?.settings?.organization?.themeHex
                                ?.primary ||
                              COLORS.primary
                            }
                          />
                        </div>
                        <div className="m-0 flex flex-col items-center text-sm md:text-base">
                          <p>Uploading your file</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </label>
              <input
                id="dropzone-file"
                type="file"
                className="appearance-none hidden disabled:opacity-60"
                onClick={(e) => {}}
                onChange={handleFileInputChange}
              />
            </div>
          </>
        }
      />
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default UploadFileModal;
