import React, { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "./index.css";
// Import the functions you need from the SDKs you need
// import axios from "axios";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { Slide, ToastContainer, toast } from "react-toastify";
import DataPrivacyListAndLinks from "../../components/DataPrivacyListAndLinks";
import Reviews from "../../components/Reviews";
import { BASE_URL, FIREBASE_CONFIG } from "../../constants/constants";
import { MdClose, MdEmail } from "react-icons/md";
import { validateEmail } from "../../utils/validateEmail";
import MixPanel from "../../utils/services/mixpanel";
import Modal from "../../components/Modal";
import { Helmet } from "react-helmet";
import { useAppSelector } from "../../redux/store";
import { getWhiteLabelInfoLocal } from "../../utils";
import { IMAGE_LINKS } from "../../constants/common";
import axios from "../../utils/axios";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import { Login2FAModal } from "./Login2FA";
import {
  get2FAInfo,
  updateLoginInfo,
  updateLoginInfoOnlyBackend,
} from "../../redux/api/owner";
import LoginReviews from "./LoginReviews";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import GoogleOneTapSignIn from "../../components/GoogleOneTapSignIn";
// / Your web app's Firebase configuration
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

let reviews = [
  {
    name: "Turnip Mandala",
    review:
      "This add on is easy to install and works just amazing. The dashboard has a clean user interface. The app is easy and simple to use. Whenever you need an approval process for any form (from one person or more), this add on is what makes the job.",
    profilePic:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/approvals/reviewers/repa.jpeg",
    designation: "Supply Chain Manager",
  },
  {
    name: "Alaa Khaled",
    review:
      "I like this software so much, super user friendly, A true life saver charging you with no cost, the customer support is super helpful mr paresh help me a lot in understanding the software seconds after asking his support. Thank you so much wishing you lots of success.",
    profilePic:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/approvals/reviewers/alaa.jpeg",
    designation: "Marketing Coordinator",
  },
  {
    name: "Patrick Nyama",
    review:
      "I was having challenges figuring out the right logic for my workflow. I called Paresh not realizing the time difference (it was almost midnight his time). He answered my call and helped me figure it out. The customer support team is super helpful. I will definitely recommend using the BoloForms. Thank you",
    profilePic:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/approvals/reviewers/patrick.jpeg",
    designation: "Marketing Manager",
  },
  {
    name: "John M.",
    review:
      "Customer support is super awesome, Great add on for our team! This helps our task management must easier. I can easily keep track of my member's request and work progress. The approval process is smooth and extremely quick. I highly recommend this addon especially for small business like mine.",
    profilePic:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/approvals/reviewers/john.jpeg",
    designation: "IT Manager",
  },
  {
    name: "Raman R",
    review:
      "We've been using BoloForms for about a month, and it has streamlined our leave request application process. It is simple to set up and track, plus, the one-click approval email makes it much faster to process requests., the customer support is super helpful mr paresh help me alot in understanding the software seconds after asking his support.",
    profilePic:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/approvals/reviewers/raman.jpeg",
    designation: "Inventory Manager",
  },
];
// Initialize Firebase
const whiteLabelInfo = getWhiteLabelInfoLocal();
const app = initializeApp(
  whiteLabelInfo?.settings?.organization?.firebase?.config || FIREBASE_CONFIG
);
const analytics = getAnalytics(app);
const auth = getAuth();
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const whiteLabaleRoot = useAppSelector((state) => state.root.whiteLabelSlice);
  const [searchParams] = useSearchParams();
  const fromParam = searchParams.get("from");
  const redirectUrl = searchParams.get("redirect");

  const [logInWithEmailUser, setLogInWithEmailUser] = React.useState({
    email: "",
    password: "",
  });
  // const [userType, setUserType] = React.useState("admin");
  const [userType, setUserType] = React.useState("owner"); //? Did not know what to set
  const [activeReview, setActiveReview] = useState(reviews[0]);
  const [show, setShow] = React.useState({
    forgetPasswordModal: false,
    signInOption: "email",
  });
  const [twoFA, setTwoFA] = useState<{
    show: boolean;
    data: { result: any; method: string };
  }>({
    show: false,
    data: { result: null, method: "" },
  });

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     let randomReview = Math.floor(Math.random() * reviews.length);
  //     setActiveReview(reviews[randomReview]);
  //   }, 10000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  // async function updateLoginInfo(result: any, method: string) {
  //   const user = result.user;
  //   let referrer = localStorage.getItem("ref");
  //   let refReward = localStorage.getItem("refReward");
  //   let timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  //   if (refReward) {
  //     referrer = null;
  //     localStorage.setItem("ref", "");
  //   }
  //   localStorage.setItem("refReward", "");
  //   // createOwner
  //   try {
  //     let { data } = await axios.post(`${BASE_URL}/owners`, {
  //       email: user.email,
  //       name: user.displayName,
  //       profilePic: user.photoURL,
  //       phone: user.phoneNumber,
  //       from: fromParam,
  //       referrer,
  //       timezone,
  //       accessToken: result?.user?.accessToken,
  //       projectId:
  //         whiteLabelInfo?.settings?.organization?.firebase?.config?.projectId ||
  //         FIREBASE_CONFIG?.projectId,
  //     });
  //     console.log("data", data);

  //     if (data?.owner) {
  //       new MixPanel().track("login", { method: method });
  //       console.log("owner", data?.owner);
  //       localStorage.setItem("workspaceid", data.owner?.currentWorkSpaceId);
  //       localStorage.setItem("owner", JSON.stringify(data.owner));
  //       localStorage.setItem("currentUserEmail", user.email);
  //       localStorage.setItem("accountType", userType);
  //       localStorage.setItem("x-auth-token", data?.["x-auth-token"]);

  //       // handle the redirect
  //       if (redirectUrl) {
  //         let pdfUrl = searchParams.get("pdfUrl");
  //         let pdfName = searchParams.get("pdfName");

  //         if (pdfUrl) {
  //           window.open(
  //             `${redirectUrl}?pdfUrl=${pdfUrl}&pdfName=${pdfName}`,
  //             "_self"
  //           );
  //         } else {
  //           window.open(`${redirectUrl}`, "_self");
  //         }
  //       } else {
  //         window.open("/dashboard/forms", "_self");
  //       }

  //       // navigate("/dashboard");
  //     } else {
  //       // toast(`You're not ${userType}`, {
  //       //   type: "error",
  //       // });
  //       toast(`Something went wrong! Please try again`, {
  //         type: "error",
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleLogin = async () => {
    let { email, password } = logInWithEmailUser;

    if (!email || !password)
      return toast.error("Please enter email and password");

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user: any = userCredential.user;
        console.log(user);
        if (!user?.emailVerified) {
          console.log("email not verified");
          return toast.error(
            "Please verify your email address, check your inbox."
          );
        }
        //! when using jwt i guess
        // authenticateWithServerAndGetAuthUrl({
        //   email: user.email,
        //   accessToken: user.accessToken,
        //   uid: user.uid,
        // });
        // ...

        //? for now
        await updateLoginInfoOnlyBackend(userCredential, "email_password");
        let twoFaInfo = await get2FAInfo({
          email: userCredential?.user?.email || "",
        });
        if (twoFaInfo.enabled && twoFaInfo.verified) {
          setTwoFA((prev) => ({
            ...prev,
            data: { result: userCredential, method: "email_password" },
            show: true,
          }));
        } else {
          await updateLoginInfo(userCredential, "email_password");
        }
      })
      .catch((error) => {
        // const errorMessage = error.message;
        // console.log(errorMessage);
        toast.error("Email or Password is incorrect. Please try again.");
      });
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.

        await updateLoginInfoOnlyBackend(result, "google");
        let twoFaInfo = await get2FAInfo({ email: result?.user?.email || "" });
        if (twoFaInfo.enabled && twoFaInfo.verified) {
          setTwoFA((prev) => ({
            ...prev,
            data: { result: result, method: "google" },
            show: true,
          }));
        } else {
          await updateLoginInfo(result, "google");
        }
      })
      .catch((error) => {
        toast.error("Something went Wrong. Please try again.");
        console.log(error);
      });
  };

  const signInWithMicrosoft = () => {
    const provider = new OAuthProvider("microsoft.com");
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Microsoft Access Token. You can use it to access the Microsoft API.
        // console.log(result);

        await updateLoginInfoOnlyBackend(result, "microsoft");
        let twoFaInfo = await get2FAInfo({ email: result?.user?.email || "" });
        if (twoFaInfo.enabled && twoFaInfo.verified) {
          setTwoFA((prev) => ({
            ...prev,
            data: { result: result, method: "microsoft" },
            show: true,
          }));
        } else {
          await updateLoginInfo(result, "microsoft");
        }
      })
      .catch((error) => {
        toast.error("Something went Wrong. Please try again.");
        console.log(error);
      });
  };

  useEffect(() => {
    // setUserType(
    //   searchParams.get("type") === "collaborator" ? "collaborator" : "admin"
    // );
    setUserType(
      searchParams.get("type") === "collaborator" ? "collaborator" : "owner"
    );
  }, []);

  /* This is checking if the user is already logged in. If the user is logged in, it will redirect to
  the dashboard. */
  // @ts-ignore
  let user = JSON.parse(localStorage.getItem("owner"));

  if (whiteLabaleRoot?.loading) {
    return (
      <div className="h-[100vh] w-[100vw] flex items-center justify-center">
        <BoloLoader />
      </div>
    );
  }

  if (user && user._id && redirectUrl) {
    window.open(`${redirectUrl}`, "_self");
  }

  if (user && user._id && !redirectUrl) {
    console.log("user found", user);
    return <Navigate to="/dashboard/forms" />;
  }

  // if (redirectUrl) {
  //   return <Navigate to={redirectUrl} />;
  // }

  const ForgotPassword = ({ setShow }: any) => {
    const location = useLocation();

    const [email, setEmail] = useState("");

    const handleForgotPassword = async () => {
      if (!validateEmail(email)) return toast.error("Please enter valid email");

      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent!
          console.log("Password reset email sent!");
          toast.success("Password reset email sent!");
          setShow((prev: any) => ({ ...prev, forgetPasswordModal: false }));
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.log(errorMessage);
          toast.error(error.message);
          // ..
        });
    };

    return (
      <div className="fade w-full h-fit py-6 flex justify-center items-center">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-full flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold text-gray-700">
              Forgot Password
            </h2>
            <p className="text-gray-500 text-sm mt-2">
              Enter your email address and we will send you a link to reset your
              password.
            </p>
          </div>
          <div className="w-full flex flex-col justify-center items-center mt-6">
            <input
              type="email"
              placeholder="Email"
              className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handleForgotPassword}
              className="w-full bg-primary text-white rounded-md px-4 py-2 mt-4 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            >
              Send Reset Link
            </button>
          </div>
          <div className="w-full flex justify-center items-center mt-6">
            <p className="text-gray-500 text-sm">
              Don't have an account?{" "}
              <Link
                to={`/register${location.search}`}
                className="text-primary hover:underline"
              >
                Register
              </Link>
            </p>
          </div>
        </div>
        <MdClose
          onClick={() =>
            setShow((prev: any) => ({ ...prev, forgetPasswordModal: false }))
          }
          className="absolute top-4 right-4 text-gray-500 cursor-pointer text-2xl"
        />
      </div>
    );
  };

  return (
    <div
      className="fade w-full h-screen flex flex-row items-center justify-center relative"
      // style={{
      //   background: `linear-gradient(90deg, rgba(139,92,246,1) 0%, rgba(255,255,255,1) 50%)`,
      // }}
    >
      <Helmet>
        <title translate="no">Login</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Login" />
        <meta
          name="description"
          content="Log in to BoloForms for instant access to streamlined document workflows. Securely manage your digital signatures, utilize drag-and-drop customization, and seamlessly collaborate. Dive into the digital era and enjoy hassle-free login experiences!"
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/login`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Login - Boloforms`} />
        <meta
          property="og:description"
          content="Log in to BoloForms for instant access to streamlined document workflows. Securely manage your digital signatures, utilize drag-and-drop customization, and seamlessly collaborate. Dive into the digital era and enjoy hassle-free login experiences!"
        />
        <link rel="canonical" href="https://signature.boloforms.com/login" />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <Login2FAModal
        show={twoFA.show}
        setShow={(value) => setTwoFA((prev) => ({ ...prev, show: value }))}
        userData={twoFA.data}
      />
      <GoogleOneTapSignIn />
      {/* <div className="hidden lg:flex h-full w-full flex-col justify-center relative z-10 overflow-hidden border-r">
        <div className="w-[90%] mx-auto mb-4">
          <h2 className="text-2xl mb-2 font-medium">
            Sign & Agree, the easy way.{" "}
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            BoloForms is an easy-to-use eSignature platform to sign, send, and
            manage contract/business agreements.
          </p>
        </div>
        <Reviews />
        <DataPrivacyListAndLinks />
      </div> */}
      <LoginReviews />
      <div className="bg-white w-full px-4 lg:px-0 h-full flex justify-center items-center">
        <div className="w-[430px] mx-auto flex flex-row h-fit overflow-hidden">
          <div className="w-full px-2 sm:px-6 py-7 flex flex-col bg-white">
            <div className="pb-8 w-full h-full flex flex-col justify-end items-start  text-black">
              <h2 className="text-xl capitalize mb-2 text-center w-full">
                Login to{" "}
                <span className="text-primary font-bold">
                  {whiteLabelInfo?.settings?.organization?.name || "BoloForms"}
                </span>{" "}
                Account
              </h2>
              <p className="block lg:hidden text-xs text-center w-full">
                Unlimited signatures, templates, forms, and team members
              </p>
              <p className="block lg:hidden text-xs text-center w-full">
                One fixed price. No extra charges, ever
              </p>
            </div>
            {show.signInOption === "email" && (
              <>
                <div className="w-full flex flex-col">
                  <label className="text-xs mb-1 text-gray-700">Email</label>
                  <input
                    type="email"
                    className="text-gray-700 w-full p-2.5 rounded-md border-[1.5px] focus:border-primary focus:outline-none text-sm"
                    placeholder="Enter your email"
                    value={logInWithEmailUser.email}
                    onChange={(e) => {
                      setLogInWithEmailUser((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                    }}
                  />

                  <label className="text-xs mt-4 mb-1 text-gray-700">
                    Password
                  </label>
                  <input
                    type="password"
                    className="text-gray-700 w-full p-2.5 rounded-md border-[1.5px] focus:border-primary focus:outline-none text-sm"
                    placeholder="Enter your password"
                    value={logInWithEmailUser.password}
                    onChange={(e) => {
                      setLogInWithEmailUser((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }));
                    }}
                  />
                  <div className="flex justify-between items-center my-6">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-primary focus:outline-none rounded-sm"
                      />
                      <span className="text-xs text-gray-500 ml-2">
                        Remember me
                      </span>
                    </div>
                    <p className="text-xs text-gray-500 mt-2">
                      <span
                        onClick={() => {
                          setShow((prev) => ({
                            ...prev,
                            forgetPasswordModal: true,
                          }));
                        }}
                        className="text-primary cursor-pointer"
                      >
                        Forgot Password?
                      </span>
                    </p>
                  </div>

                  <div className="flex flex-row items-center">
                    <BoloButton
                      onClick={handleLogin}
                      className="w-full"
                      // className="w-full p-2 rounded-md bg-primary text-white focus:outline-none hover:opacity-90"
                    >
                      Login
                    </BoloButton>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-xs text-gray-500 text-center">
                    Don't have an account?{" "}
                    <Link
                      to={`/register${location.search}`}
                      className="text-primary"
                    >
                      Register
                    </Link>
                  </p>
                </div>
                <div className="flex items-center mt-6 mb-3">
                  <div className="w-full border mr-4 h-fit"></div>
                  <span className="text-gray-600 text-xs font-semibold">
                    OR
                  </span>
                  <div className="w-full border ml-4 h-fit"></div>
                </div>
              </>
            )}
            <BoloButton
              variant={"secondary"}
              orientation={"center"}
              className="w-full shadow-md hover:bg-secondary !text-black"
              onClick={signInWithGoogle}
              type="button"
              // className="w-full my-2 flex flex-row items-center justify-center py-2 px-4 p rounded-md bg-white border-[1px] border-gray-200 cursor-pointer hover:bg-secondary hover:text-secondary-text hover:border-secondary shadow-md"
            >
              <FcGoogle className="text-3xl" />
              <label className="ml-4 w-[160px] text-left text-sm font-medium cursor-pointer">
                Sign in with Google
              </label>
            </BoloButton>
            <BoloButton
              variant={"secondary"}
              orientation={"center"}
              className="w-full shadow-md hover:bg-secondary !text-black mt-3"
              onClick={signInWithMicrosoft}
              type="button"
              // className="w-full my-2 flex flex-row items-center justify-center py-2 px-4 p rounded-md bg-white border-[1px] border-gray-200 cursor-pointer hover:bg-secondary hover:text-secondary-text hover:border-secondary shadow-md"
            >
              <span className="py-[3px] pointer-events-none">
                <object
                  type="image/svg+xml"
                  data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                  className="x-icon w-6 h-6"
                  aria-label="Microsoft"
                ></object>
              </span>
              <label className="ml-5 w-[160px] text-left text-sm font-medium cursor-pointer">
                Sign in with Microsoft
              </label>
            </BoloButton>
            {/* <BoloButton
              variant={"secondary"}
              orientation={"center"}
              className="w-full shadow-md hover:bg-secondary !text-black mt-3"
              onClick={() => {
                setShow((prev) => ({ ...prev, signInOption: "email" }));
              }}
              type="button"
              // className="w-full my-2 flex flex-row items-center justify-center py-2 px-4 p rounded-md bg-white border-[1px] border-gray-200 cursor-pointer hover:bg-secondary hover:text-secondary-text hover:border-secondary shadow-md"
            >
              <MdEmail className="text-3xl text-gray-700" />
              <label className="ml-4 w-[160px] text-left  text-sm font-medium cursor-pointer">
                Sign in with Email
              </label>
            </BoloButton> */}
            <div className="mt-4">
              <p className="text-xs text-gray-500 text-center">
                Don't have an account?{" "}
                <Link to="/register" className="text-primary">
                  Register
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {show.forgetPasswordModal && (
        <Modal modalContent={<ForgotPassword setShow={setShow} />} />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default Login;
