import { SidebarProps } from "../index";
import { SchemaForUI } from "../../../../../../common/src";
import React from "react";
import ViewHeader from "../ViewHeader";
import { DetailViewContent } from ".";

const DetailViewTemplateSidebar = (
  props: SidebarProps & {
    schemasList: SchemaForUI[][];
    setSchemasList: (schemasList: SchemaForUI[][]) => void;
    activeSchema: SchemaForUI | undefined;
    onEditEnd: any;
    helpers: {
      open: boolean;
      setOpen: any;
    };
  }
) => {
  const activeSchema_ = props.activeSchema;

  return (
    <div className="flex h-[100%] max-h-[100%] justify-between flex-col w-[100%]">
      <div className="h-[100%]">
        <div className="px-4">
          <ViewHeader
            name="Edit Fields"
            open={props?.helpers?.open}
            setOpen={props?.helpers?.setOpen}
          />
        </div>
        <div
          className="px-4 max-h-[calc(100%_-_80px)] min-h-[calc(100%_-_80px)] overflow-y-auto"
          id="mini-pdf-custom-scroll"
        >
          {activeSchema_ !== undefined ? (
            <DetailViewContent
              {...props}
              activeSchema={activeSchema_}
              isNew={true}
            />
          ) : (
            <div className="text-xs pt-2 text-gray-500">
              <p>Select/Click on Singular Element which you want to edit</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailViewTemplateSidebar;
