import React, { useEffect, useState } from "react";
import { DesignerState, GlobalHelpersType } from "..";
import ViewHeader from "./ViewHeader";
import BoloRadioButtonMui from "../../../../../../components/Common/RadioButton/BoloRadioButtonMui";
import BoloModal from "../../../../../../components/Common/Modal/BoloModal";
import EmailSingleSelect from "../../../../../../components/Ui/EmailSingleSelect";
import { getContactDetails, setContactDetails } from "../../../../../../utils";
import { Slide, toast, ToastContainer } from "react-toastify";
import axios from "../../../../../../utils/axios";
import {
  getInitials,
  getRandomColorV1,
  getTintFromHex,
} from "../../../../../../utils/uiUtils";
import { BoloInput } from "../../../../../../components/Common/Input/BoloInput";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import { MdDelete } from "react-icons/md";
import { BASE_URL } from "../../../../../../constants/constants";
import MailDataForm from "../../../../../../components/Ui/MailDataForm";
import { isFeatureEnabled } from "../../../../../../utils/paidFeat";
import PleaseUpgradeModal from "../../../../../../components/PleaseUpgradeModal";
import { ALL_FEATURES } from "../../../../../../types/tiers";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { COLORS } from "../../../../../../constants/common";

export type ApprovalWorkflowViewProps = {
  helpers: {
    open: boolean;
    setOpen: (value: boolean) => void;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};
const customStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    fontSize: "14px", // Adjust as needed
    border: "none",
    borderBottom: state.isFocused ? "2px solid #4c2b87" : "1px solid #e5e5e5",
    borderRadius: "0",
    outline: "none",
    boxShadow: "none",
    transition: "border-bottom-color 0.2s ease-in-out",
    zIndex: "100",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#a0aec0",
  }),
};
const ApprovalWorkflowView = ({ helpers }: ApprovalWorkflowViewProps) => {
  const { open, setOpen, designerState, setDesignerState, globalHelpers } =
    helpers;
  const [approvalToggle, setApprovalToggle] = useState<boolean>(
    designerState?.fetchedData?.document?.settings?.approvalWorkflow?.enabled ||
      false
  );
  const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
  const [approvers, setApprovers] = useState<any[]>(
    designerState?.fetchedData?.document?.settings?.approvalWorkflow
      ?.approvers || []
  );
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [upgradeModalType, setUpgradeModalType] =
    useState<ALL_FEATURES>("APPROVAL_WORKFLOW");
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      <div className="px-4 overflow-y-auto">
        <div className="">
          <ViewHeader name="Workflow" open={open} setOpen={setOpen} />
        </div>
        <p className="text-gray-500 text-xs pr-4 mb-4">
          Customize the lifecycle of your document by adding steps below.
        </p>
      </div>
      <div className="px-5 py-3">
        <section className="text-xs">
          <div
            className="flex gap-2 items-center"
            style={{ color: COLORS?.status?.intermediate?.primary }}
          >
            <div
              className="min-w-[12px] w-3 h-3 max-h-3 max-w-3 rounded-full"
              style={{ backgroundColor: COLORS?.status?.intermediate?.primary }}
            ></div>
            Draft
          </div>
          <p className="border-l-2 p-2.5 text-gray-400 m-[5px]">
            Documnent has been created
          </p>
        </section>
        <section className="text-xs my-2">
          <div className="border-1 border pb-2 border-gray-300 px-2">
            <div className="flex justify-between items-center ">
              <div
                className="flex gap-2 items-center"
                style={{
                  color: COLORS?.status?.approved?.primary,
                }}
              >
                <div
                  className="min-w-[12px] w-3 h-3 max-h-3 max-w-3 rounded-full "
                  style={{ backgroundColor: COLORS?.status?.approved?.primary }}
                ></div>
                Approved
              </div>
              <div className="flex gap-2 text-gray-500 items-center">
                <div className="font-semibold">
                  {approvalToggle ? "ON" : "OFF"}
                </div>
                <BoloRadioButtonMui
                  variant="bolo-primary"
                  checked={approvalToggle}
                  onChange={async (e) => {
                    let isOn = e.target.checked;
                    if (isOn) {
                      let featuresAccess = await isFeatureEnabled(
                        "APPROVAL_WORKFLOW"
                      );

                      if (!featuresAccess) {
                        setShowUpgradeModal(true);
                        setUpgradeModalType("APPROVAL_WORKFLOW");
                        return;
                      }
                    }
                    setApprovalToggle((prev) => !prev);
                    try {
                      const { data } = await axios.post(
                        `${BASE_URL}/document/settings`,
                        {
                          documentId:
                            designerState?.fetchedData?.document?.documentId,
                          approvalWorkflow: {
                            enabled: isOn,
                            approvers,
                          },
                        }
                      );

                      if (data?.success) {
                        setDesignerState((prev) => ({
                          ...prev,
                          fetchedData: {
                            ...prev.fetchedData,
                            document: data?.document,
                          },
                        }));
                        if (isOn) {
                          toast.success("Approval workflow enabled!");
                        } else {
                          toast.success("Approval workflow disabled!");
                        }
                      }
                    } catch (e) {
                      toast.error("Failed in updating Approval Workflow");
                      console.log("error ", e);
                    }
                  }}
                />
              </div>
            </div>
            <div className="text-xs text-gray-400 p-2 ">
              Require document's approval before it can be sent to recipients
            </div>
            {approvalToggle && (
              <div
                className="mx-auto mb-2 px-auto py-2 text-primary border-1 shadow text-center cursor-pointer hover:bg-gray-50"
                onClick={async () => {
                  let featuresAccess = await isFeatureEnabled(
                    "APPROVAL_WORKFLOW"
                  );

                  if (!featuresAccess) {
                    setShowUpgradeModal(true);
                    setUpgradeModalType("APPROVAL_WORKFLOW");
                    return;
                  }
                  setShowApprovalModal(true);
                }}
              >
                Set up approval workflow
              </div>
            )}
          </div>
        </section>
        <section className="text-xs">
          <div
            className="flex gap-2 items-center"
            style={{
              color: COLORS?.status?.sent?.primary,
            }}
          >
            <div
              className="min-w-[12px] w-3 h-3 max-h-3 max-w-3 rounded-full"
              style={{ backgroundColor: COLORS?.status?.sent?.primary }}
            ></div>
            Sent
          </div>
          <p className="border-l-2 p-2.5 text-gray-400 m-[5px]">
            The document has been sent to recipients
          </p>
        </section>
        <section className="text-xs">
          <div className="flex gap-2 text-gray-400 items-center">
            <div className="min-w-[12px] w-3 h-3 max-h-3 max-w-3 rounded-full bg-gray-400"></div>
            Viewed
          </div>
          <p className="border-l-2 p-2.5 text-gray-400 m-[5px]">
            Recipient has viewed the document
          </p>
        </section>
        <section className="text-xs">
          <div
            className="flex gap-2 items-center"
            style={{
              color: COLORS?.status?.success?.primary,
            }}
          >
            <div
              className="min-w-[12px] w-3 h-3 max-h-3 max-w-3 rounded-full"
              style={{ backgroundColor: COLORS?.status?.success?.primary }}
            ></div>
            Signed
          </div>
          <p className="border-l-2 p-2 text-gray-400 m-[5px]">
            The document has been accepted or signed by all recipients
          </p>
        </section>
        <section className="text-xs">
          <div
            className="flex gap-2  items-center"
            style={{
              color: COLORS?.status?.success?.primary,
            }}
          >
            <div
              className="min-w-[12px] w-3 h-3 max-h-3 max-w-3 rounded-full border-2 "
              style={{ borderColor: COLORS?.status?.success?.primary }}
            ></div>
            Waiting for payment
          </div>
          <p className="border-l-2 p-2 text-gray-400 m-[5px]">
            Recipient has been billed
          </p>
        </section>
        <section className="text-xs">
          <div
            className="flex gap-2 items-center"
            style={{
              color: COLORS?.status?.success?.primary,
            }}
          >
            <div
              className="min-w-[12px] w-3 h-3 max-h-3 max-w-3 rounded-full "
              style={{ backgroundColor: COLORS?.status?.success?.primary }}
            ></div>
            Paid
          </div>
          <p className="border-l-2 p-2 text-gray-400 m-[5px]">
            All payments have been received
          </p>
        </section>
      </div>
      {showApprovalModal && (
        <ApprovalWorkflowModal
          setShowApprovalModal={setShowApprovalModal}
          designerState={designerState}
          setDesignerState={setDesignerState}
          globalHelpers={globalHelpers}
        />
      )}
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
    </div>
  );
};
type ApproverType = {
  name: string;
  email: string;
  color: string;
  approverOrderNo: number;
};
const ApprovalWorkflowModal = ({
  setShowApprovalModal,
  designerState,
  setDesignerState,
  globalHelpers,
}: {
  setShowApprovalModal: React.Dispatch<React.SetStateAction<boolean>>;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
}) => {
  const [roles, setRoles] = useState<any>([]);
  const [addRecipientEmail, setAddRecipientEmail] = useState({
    name: "",
    email: "",
  });
  const [approvers, setApprovers] = useState<ApproverType[]>(
    designerState?.fetchedData?.document?.settings?.approvalWorkflow
      ?.approvers || []
  );
  const [teamContacts, setTeamContacts] = useState<any>([]);
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] =
    useState<boolean>(false);
  useEffect(() => {
    const owner = JSON.parse(localStorage.getItem("owner") || "");
    let collaborators = owner?.collaborators;
    let collaboratorsData = collaborators?.map((collab: any) => ({
      email: collab?.collaboratorId?.email,
      name: collab?.collaboratorId?.name,
    }));

    setTeamContacts(collaboratorsData);
  }, []);

  const handleSelectedValue = async (email: string, name: string) => {
    const contacts = getContactDetails();
    const addContact = async () => {
      try {
        // check if email is valid
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          toast.error("Enter a valid email address");
          return true;
        }
        // Check email aready exits or not
        if (
          contacts?.contacts.find((contact: any) => contact.email === email)
        ) {
          return;
        }
        const response = await axios.post("/workspace/addContact", { email });
      } catch (error) {
        console.log("Error updating contact.");
      }
    };
    await addContact();
    const getData = async () => {
      try {
        const response = await axios.get("/workspace/getContacts");
        const data = response?.data;
        const contacts = data?.contacts?.contacts || [];
        setContactDetails(contacts);
      } catch (error) {
        console.log("Error fetching workspace details");
      }
    };
    await getData();
    setApprovers((prev: ApproverType[]) => [
      ...prev,
      {
        email: email,
        name: name ? name.trim() : email,
        color: getRandomColorV1(),
        approverOrderNo: prev.length + 1,
      },
    ]);
  };
  const checkBeforeClosing = () => {
    if (approvers?.length < 1) {
      toast.error("There should be atleast 1 approver!");
    } else {
      setShowApprovalModal(false);
    }
  };
  const addApprovers = async () => {
    if (approvers?.length < 1) {
      toast.error("There should be atleast 1 approver!");
      return;
    }
    let invalidOrderNumber = false;
    approvers?.map((elm: any) => {
      console.log(elm?.approverOrderNo, elm?.name);
      if (
        isNaN(parseInt(elm?.approverOrderNo)) ||
        parseInt(elm?.approverOrderNo) < 1 ||
        parseInt(elm?.approverOrderNo) > approvers?.length
      ) {
        invalidOrderNumber = true;
      }
    });
    if (invalidOrderNumber) {
      toast.error("Please provide appropriate Approver Order numbers");
      return;
    }
    try {
      const { data } = await axios.post(`${BASE_URL}/document/settings`, {
        documentId: designerState?.fetchedData?.document?.documentId,
        approvalWorkflow: {
          enabled: true,
          approvers,
        },
      });

      if (data?.success) {
        setDesignerState((prev) => ({
          ...prev,
          fetchedData: {
            ...prev.fetchedData,
            document: data?.document,
          },
        }));
        toast.success("Approval workflow updated!");
      }
    } catch (e) {
      toast.error("Failed in updating Approval Workflow");
      console.log("error ", e);
    }
  };
  return (
    <BoloModal
      setShow={checkBeforeClosing}
      content={
        <div className="h-[500px]">
          <div className="text-base md:text-lg mb-4 font-semibold">
            Set up approval workflow
          </div>
          <div className="gap-2 items-center justify-between text-xs text-gray-600">
            <p>Documents has to be approved by each individual approver.</p>
          </div>
          <div className="text-gray-500 text-xs py-2 border-b border-gray-50">
            APPROVERS
          </div>
          <div>
            <div className="flex gap-2 items-center my-1 ">
              {/* <EmailSingleSelect
                selectedValue={addRecipientEmail?.email}
                updateEmailValue={handleSelectedValue}
                filterList={
                  approvers?.map((approver: any) => approver?.email) || []
                }
                isCustomStyle={true}
              /> */}
              <CreatableSelect
                styles={customStyle}
                className={`w-full text-xs md:text-sm outline-none`}
                isMulti={false}
                value={
                  addRecipientEmail?.email
                    ? {
                        value: addRecipientEmail?.email,
                        label: addRecipientEmail?.email,
                      }
                    : null
                }
                options={teamContacts?.map((contact: any) => ({
                  value: contact?.email,
                  label: contact?.email,
                }))}
                formatCreateLabel={(input) => "Invite New User +"}
                onChange={(newValue: any) => {
                  const details: any = teamContacts.find((contact: any) => {
                    return contact.email === newValue?.value;
                  });
                  const existsAlready = approvers?.findIndex(
                    (approver: any) => newValue?.value === approver?.email
                  );
                  if (existsAlready != -1) {
                    toast.info("Approver already added!");
                    return;
                  }
                  const name = details?.name?.trim() || "";
                  handleSelectedValue(newValue?.value, name.trim());
                }}
                onCreateOption={(inputValue) => {
                  setShowAddTeamMemberModal(true);
                }}
                placeholder="Enter email address"
              />
            </div>
            <div
              className="h-[300px] overflow-y-auto "
              id="mini-pdf-custom-scroll"
            >
              {approvers?.map((approver: ApproverType, index: number) => (
                <div
                  className=" group p-2 flex justify-between items-center hover:bg-gray-50  border-b border-gray-50 cursor-pointer"
                  key={index}
                >
                  <div className="flex gap-2 items-center max-w-[205px]">
                    <div>
                      <BoloInput
                        className="w-6 hide-spinner text-center group-hover:bg-slate-100"
                        type="number"
                        min="1"
                        bolosize={"xs"}
                        value={approver?.approverOrderNo}
                        onChange={(e) => {
                          setApprovers((prevApprovers) => {
                            return prevApprovers.map((approver, idx) => {
                              if (idx === index) {
                                return {
                                  ...approver,
                                  approverOrderNo: Number(e.target.value) || 1,
                                };
                              }
                              return approver;
                            });
                          });
                        }}
                      />
                    </div>
                    <div
                      className="h-8 w-8 min-w-8 min-h-8 rounded-full flex items-center justify-center text-gray-600"
                      style={{
                        background: getTintFromHex(
                          approver?.color || "#9a6afd",
                          85
                        ),
                        border: `1px solid ${approver?.color || "#9a6afd"}`,
                      }}
                    >
                      {getInitials(approver?.name || approver?.email) || ""}
                    </div>
                    <div className="flex flex-col">
                      <div className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[130px]">
                        {approver?.name || approver?.email}
                      </div>
                      {approver?.email && (
                        <div className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[130px] text-[10px] leading-[10px] text-gray-600">
                          {approver?.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="hidden group-hover:inline-block"
                    onClick={() => {
                      setApprovers((prev) =>
                        prev?.filter(
                          (ele: ApproverType, i: number) => i !== index
                        )
                      );
                    }}
                  >
                    <MdDelete size={20} className="text-red-400" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex gap-2 items-center justify-between mt-8">
            <BoloButton
              variant={"secondary"}
              size={"sm"}
              onClick={checkBeforeClosing}
            >
              Cancel
            </BoloButton>
            <BoloButton variant={"primary"} size={"sm"} onClick={addApprovers}>
              Save
            </BoloButton>
          </div>
          {showAddTeamMemberModal && (
            <AddTeamMemberMessage
              setShowAddTeamMemberModal={setShowAddTeamMemberModal}
              approvers={approvers}
              designerState={designerState}
            />
          )}
          <ToastContainer
            transition={Slide}
            position="bottom-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
            closeButton={false}
          />
        </div>
      }
    />
  );
};

const AddTeamMemberMessage = ({
  setShowAddTeamMemberModal,
  approvers,
  designerState,
}: {
  setShowAddTeamMemberModal: React.Dispatch<React.SetStateAction<boolean>>;
  approvers: any;
  designerState: DesignerState;
}) => {
  return (
    <BoloModal
      setShow={setShowAddTeamMemberModal}
      content={
        <>
          <div className="text-base md:text-lg mb-2 font-semibold">
            Add Team Member
          </div>
          <p className="text-xs md:text-sm text-gray-500 mt-2">
            To add a new team member, simply navigate to the Teams page and look
            for the option to add members. Enter the person's email, follow any
            prompts, and you're done!
          </p>

          <div className="flex gap-2 items-center justify-between mt-8">
            <BoloButton
              variant={"gray"}
              size={"sm"}
              className="bg-gray-100 font-medium"
              onClick={() => {
                setShowAddTeamMemberModal(false);
              }}
            >
              Cancel
            </BoloButton>

            <BoloButton
              variant={"primary"}
              size={"sm"}
              className=" text-white bg-green-700 border-green-700 font-medium"
              onClick={async () => {
                if (approvers?.length === 0) {
                  try {
                    const { data } = await axios.post(
                      `${BASE_URL}/document/settings`,
                      {
                        documentId:
                          designerState?.fetchedData?.document?.documentId,
                        approvalWorkflow: {
                          enabled: false,
                          approvers,
                        },
                      }
                    );
                  } catch (error) {
                    console.log("Some error occured");
                  }
                }
                window.open("/teams", "_self");
              }}
            >
              Go To Teams Page
            </BoloButton>
          </div>
        </>
      }
    />
  );
};

export default ApprovalWorkflowView;
