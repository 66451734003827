const COLORS = {
  primary: "#8B5CF6",
  secondary: "#f3effe",
  tertiary: "#b698f9",
  textPrimary: "#ffffff",
  textSecondary: "#8B5CF6",
  textTertiary: "#b698f9",
  status: {
    success: {
      primary: "#1b9e4b",
      secondary: "#d3f3df",
    },
    failure: {
      primary: "#bf3636",
      secondary: "#fdecec",
    },
    intermediate: {
      primary: "#bb8f06",
      secondary: "#fbf0ce",
    },
    approved: {
      primary: "#F97316",
      secondary: "#fee3d0",
    },
    sent: {
      primary: "#216AFC",
      secondary: "#d3e1fe",
    },
  },
  icons: {
    FORM: "#7248B9",
    "PDF-TEMPLATE": "#e2a905",
    PDF: "#F40F02",
  },
};

const EMBED_ALLOWED_PAGES = {
  "/embed/login": 1,
  "/atlas": 1,
  "/create/pdf": 1,
  "/new/pdf-template": 1,
  "/logout": 1,
  "/create/pdf-signature": 1,
  "/extension/import-pdf": 1,
};

const INTERCOM_DISALLOWED_PAGES = {
  "/etemplate": 1,
  "/sign-pdf-template": 1,
  "/signature-success": 1,
  "/sign": 1,
  "/approve-pdf-template": 1,
};

const IMAGE_LINKS = {
  INVISIBLE_IMAGE:
    "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/undefined/52433902-cd32-4e13-baaf-59a19ef77285.png",
  WHITE_RECT: "https://i.imgur.com/whHtrFD.png",
  BOLOLOGO: "https://i.imgur.com/jvjNDSr.png",
  FAVICON:
    "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/BoloForms+Logo+(1)/2.svg",
  SALESFORCELOGO: "https://i.imgur.com/CMsOnWa.png",
  MSTEAMS: "https://i.imgur.com/gPS3DQT.png",
  SLACK: "https://i.imgur.com/2j3wLe6.png",
  STRIPE: "https://i.imgur.com/vH9nmZ3.png",
  PANDDOC_DEMO: "https://i.imgur.com/Pwb6Bh3.png",
  ADDON_NO_RESULT: "https://i.imgur.com/qGi7TFK.png",
  EMAIL_PDF_PREVIEW:
    "https://boloforms-main.s3.ap-south-1.amazonaws.com/approval-dash/questionImages/baa005c5-cbac-4959-b8c7-f5b91014f1fa/Screenshot%202024-06-24%20at%2012.02.18%E2%80%AFPM.png",

  MEDAL_IMAGE: "https://i.imgur.com/SR4KWjg.png",
  ARROW_IMAGE: "https://i.imgur.com/BvN0YJo.png",
};

const ADDON_IMAGES = {
  BULK_SEND: [
    "https://i.imgur.com/gJaXzTN.gif",
    "https://i.imgur.com/sUp2Buy.gif",
    "https://i.imgur.com/ZNPQPgQ.gif",
  ],
  E_TEMPLATE: ["https://i.imgur.com/HY4xwLF.gif"],
  SALESFORCE: ["https://i.imgur.com/B5WCmqH.png"],
  ONLINE_NOTARY: ["https://i.imgur.com/hp5YagX.png"],
  OTP_AUTH: ["https://i.imgur.com/6frnQrg.png"],
  ATTACHMENTS: [
    "https://i.imgur.com/TH3w1NL.png",
    "https://i.imgur.com/YMTNsoo.png",
  ],
  CUSTOM_EMAIL_DOMAIN: [
    "https://i.imgur.com/qbDiYO5.png",
    "https://i.imgur.com/2h2i73n.png",
  ],
  CUSTOM_EMAIL_BRANDING: ["https://i.imgur.com/fDfhIC7.png"],
  CUSTOM_DOMAIN: ["https://i.imgur.com/qbDiYO5.png"],
  MS_TEAMS: [
    "https://i.imgur.com/eFpR3zi.png",
    "https://i.imgur.com/JG6uWCr.png",
    "https://i.imgur.com/paPgdrM.png",
  ],
  CUSTOM_PDF_NAME: ["https://i.imgur.com/vVsNArx.gif"],
  SLACK: ["https://i.imgur.com/C9k6EPT.png", "https://i.imgur.com/FoLrcOH.png"],
  STRIPE: ["https://i.imgur.com/zeJRWi5.gif"],
  ACCESS_CODE: ["https://i.imgur.com/GQd1MbG.gif"],
  PASSWORD_PDF: ["https://i.imgur.com/as3Lq8l.gif"],
  REMINDERS: [
    "https://i.imgur.com/7PM8hQ4.png",
    "https://i.imgur.com/cz8q3T0.png",
  ],
  UPLOAD_SIZE_50MB: ["https://i.imgur.com/d9BKTLz.png"],
  PRICING_TABLE: ["https://i.imgur.com/nfEOiPj.gif"],
  REDIRECTION_URL: ["https://i.imgur.com/DSSlzoc.gif"],
  PDF_SIZE: ["https://i.imgur.com/IumHDEY.gif"],
};

const INTEGRATION_LINKS = {
  zapier: {
    learnMoreLink: "https://help.boloforms.com/en/collections/7995285-zapier",
    connectionLink: "https://zapier.com/apps/boloforms/integrations",
  },
  make: {
    learnMoreLink: "https://help.boloforms.com/en/collections/8746040-make-com",
    connectionLink:
      "https://www.make.com/en/hq/app-invitation/b3be4666791b260ffa79a5eb7ada7a09",
  },
  pabbly: {
    learnMoreLink: "https://help.boloforms.com/en/collections/8746035-pabbly",
    connectionLink:
      "https://connect.pabbly.com/share-app/DkYCMFAHB2QASgBqAmcBJltPCw8JU1JvUEkHFQQLXCRTHlU-VBUJYw9DBCZXDFU0UhsBa1MMVDUIHFBUB1EGdQceCRxcQwFcUWJUEQJdCyEObAJ1UBsHPQBRADMCSgE7W2ILLAlEUg9QXQdlBB9cGlMKVS5UGQkpD08EbFcYVX9SEwFoUwpUdAgx",
  },
  activepieces: { learnMoreLink: "", connectionLink: "" },
};

function INTEGRATION_LINK_WITH_TOKEN(args) {
  const { owner, PIPEDRIVE_CLIENT_ID, BASE_URL } = args;

  return {
    pipedrive: {
      link: `https://oauth.pipedrive.com/oauth/authorize?client_id=${
        PIPEDRIVE_CLIENT_ID || ""
      }&redirect_uri=${BASE_URL || ""}/auth/pipedrive/callback&state=${
        owner?.email || ""
      };/settings?type=INTEGRATIONS`,
    },
  };
}

const POLICY_LINKS = {
  TOS: "https://boloforms.com/terms-of-service",
  PRIVACY: "https://boloforms.com/privacy-policy",
};

// "email", // Already Mapped as email (Default Field)
const defaultContactFields = [
  { field_name: "firstName", type: "text", _id: 1 },
  { field_name: "lastName", type: "text", _id: 2 },
  { field_name: "phone", type: "text", _id: 3 },
  { field_name: "company", type: "text", _id: 4 },
  { field_name: "jobTitle", type: "text", _id: 5 },
  { field_name: "streetAddress", type: "text", _id: 6 },
  { field_name: "city", type: "text", _id: 7 },
  { field_name: "postalCode", type: "text", _id: 8 },
  { field_name: "country", type: "text", _id: 9 },
  { field_name: "state", type: "text", _id: 10 },
];

const PREVIEW_AVAILABLE_STATUS = [
  "SIGNED",
  "PAID",
  "SENT",
  "SENT VIA LINK",
  "SENT FOR APPROVAL",
  "REJECTED",
  "DECLINED",
  "VOID",
  "EXPIRED",
  "UNPAID",
];
const DOCUMENT_COMPLETED_STATUS = ["SIGNED", "PAID"];

export {
  IMAGE_LINKS,
  INTEGRATION_LINKS,
  INTEGRATION_LINK_WITH_TOKEN,
  COLORS,
  POLICY_LINKS,
  ADDON_IMAGES,
  defaultContactFields,
  EMBED_ALLOWED_PAGES,
  INTERCOM_DISALLOWED_PAGES,
  PREVIEW_AVAILABLE_STATUS,
  DOCUMENT_COMPLETED_STATUS,
};
