import { DEFAULT_DATA, STANDARD_DATA } from "./constants";

function checkSchemaFilled(
  subtype: string,
  data: string
): {
  isFilled: boolean;
  status: "unchecked_c" | "unchecked_r" | "na";
} {
  let isEmpty = true;
  let status: "unchecked_c" | "unchecked_r" | "na" = "na";

  switch (subtype) {
    case "signature":
      isEmpty =
        data === "https://i.imgur.com/AfyPZQg.png" ||
        data === "" ||
        data === STANDARD_DATA.signature.unsigned_DBKEYV1.key;
      break;
    case "initials":
      isEmpty = data === "" || data === DEFAULT_DATA.initials.defaultAWSLink;
      break;
    case "stamp":
      isEmpty = data === "" || data === DEFAULT_DATA.stamp.defaultLink;
      break;
    case "text":
      isEmpty = data === "" || data === "Text";
      break;
    case "dropdown":
      isEmpty = data === "";
      break;
    case "number":
      isEmpty = data === "" || data === "Number";
      break;
    case "date":
      isEmpty = data === "" || data === "Date";
      break;
    case "checkbox":
      isEmpty =
        data === "" || data === STANDARD_DATA.checkbox.unchecked_DBKEYV1.key;
      status = "unchecked_c";
      break;
    case "radiogroup":
      isEmpty =
        data === "" || data === STANDARD_DATA.radiogroup.unchecked_DBKEYV1.key;
      status = "unchecked_r";
      break;
    case "file_upload":
      isEmpty = data === "";
      break;
    default:
      break;
  }

  return { isFilled: !isEmpty, status };
}

export { checkSchemaFilled };
