import React, { useEffect, useState } from "react";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import BoloModal from "../../../../../../components/Common/Modal/BoloModal";
import BoloLoader, {
  BoloLoaderLine,
} from "../../../../../../components/Common/Loader/BoloLoader";
import { COLORS } from "../../../../../../constants/common";
import {
  getEmbedHome,
  isEmbeded,
  getIfIsInChromeExtensionIframe,
} from "../../../../../../utils/embed";

export type SelfSigningProps = {
  // documentData: any;
  // cb: ({ redirect }: { redirect: boolean }) => Promise<any>;
  setShow: (value: boolean) => void;
  instanceFrom: "FORM" | "PDF" | "PDF-TEMPLATE";
  cbData: any;
  signLaterCB: () => void;
};

export type SelfSignModalProps = SelfSigningProps & {};

// const SelfSigning = ({
//   // documentData,
//   // cb,
//   setShow,
//   instanceFrom,
//   cbData,
//   signLaterCB,
// }: SelfSigningProps) => {
//   const [loading, setLoading] = useState({ signLater: false, signNow: false });
//   const owner = JSON.parse(localStorage.getItem("owner") || "{}");

//   return (
//     <>
//       <div className="text-base md:text-lg mb-4 font-semibold">
//         Sign your Document
//       </div>
//       <div className="gap-2 items-center justify-between text-xs">
//         <p>Your Document is Sent for Signing</p>
//         <p>
//           You can sign now or later from the "Assigned to Me" tab or from the
//           Email.
//         </p>
//       </div>
//       <div className="flex gap-2 items-center justify-end mt-8">
//         <BoloButton
//           variant={"secondary"}
//           size={"sm"}
//           disabled={loading.signLater || loading.signNow}
//           onClick={async () => {
//             // setLoading((prev) => ({ ...prev, signLater: true }));
//             // const data = await cb({ redirect: true });
//             // setLoading((prev) => ({ ...prev, signLater: false }));
//             // setShow(false);
//             signLaterCB();
//           }}
//         >
//           {loading.signLater ? "Sending" : "Sign Later"}
//         </BoloButton>
//         <BoloButton
//           disabled={loading.signLater || loading.signNow}
//           variant={"primary"}
//           size={"sm"}
//           onClick={async () => {
//             // setLoading((prev) => ({ ...prev, signNow: true }));
//             // const data = await cb({ redirect: false });
//             // setLoading((prev) => ({ ...prev, signNow: false }));
//             const data = cbData;
//             console.log("cbData self signin modal", cbData);
//             // pdf template handling ------------------------------------
//             if (instanceFrom == "PDF-TEMPLATE") {
//               const signers = data?.signers || [];
//               for (let signer of signers) {
//                 if (signer?.email == owner?.email) {
//                   if (signer?.sentUrl) {
//                     window.open(signer?.sentUrl, "_blank");
//                   }
//                 }
//               }
//               window.open(
//                 `/dashboard/templates/respondents?documentId=${cbData?.documentId}`,
//                 "_self"
//               );
//             }
//             // pdf template handling end ---------------------------------
//             // pdf handling ----------------------------------------------
//             if (instanceFrom == "PDF") {
//               const signers = data?.document?.respondants || [];
//               for (let signer of signers) {
//                 if (signer?.email == owner?.email) {
//                   if (signer?.sentUrl) {
//                     window.open(signer?.sentUrl, "_blank");
//                   }
//                 }
//               }
//               window.open(`/dashboard/pdfs`, "_self");
//             }
//             // pdf handling end ------------------------------------------
//             setShow(false);
//           }}
//         >
//           {loading.signNow ? "Sending" : "Sign Now"}
//         </BoloButton>
//       </div>
//     </>
//   );
// };
const RedirectComponentSigning = ({
  // documentData,
  // cb,
  setShow,
  instanceFrom,
  cbData,
  signLaterCB,
}: SelfSigningProps) => {
  const [loading, setLoading] = useState({ signLater: false, signNow: false });
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  // const whiteLabelInfo = useAppSelector(
  //   (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  // );
  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const redirectToSigningPage = async () => {
    await delay(2000);

    const data = cbData;

    console.log("cbData self signin modal", cbData);
    // pdf template handling ------------------------------------
    if (instanceFrom == "PDF-TEMPLATE") {
      const signers = data?.signers || [];
      let redirected = false;
      for (let signer of signers) {
        if (signer?.email == owner?.email) {
          if (signer?.sentUrl) {
            redirected = true;
            console.log("signer?.sentUrl", signer.sentUrl);
            let isEmbed = isEmbeded();
            window.open(signer?.sentUrl, isEmbed ? "_blank" : "_self");
            if (isEmbed) {
              window.open(getEmbedHome(), "_self");
            }
          }
        }
      }
      if (!redirected) {
        window.open(
          `/dashboard/templates/respondents?documentId=${cbData?.documentId}`,
          "_self"
        );
      }
    }
    // pdf template handling end ---------------------------------
    // pdf handling ----------------------------------------------
    if (instanceFrom == "PDF") {
      const signers = data?.document?.respondants || [];
      let redirected = false;
      for (let signer of signers) {
        if (signer?.email == owner?.email) {
          if (signer?.sentUrl) {
            redirected = true;
            let isEmbed = isEmbeded();
            const isInChromeExtensionIframe = getIfIsInChromeExtensionIframe();
            window.open(signer?.sentUrl, isEmbed ? "_blank" : "_self");
            if (isEmbed) {
              if (isInChromeExtensionIframe) {
                window?.top?.postMessage({ action: "closeModal" }, "*");
              }
              window.open(getEmbedHome(), "_self");
            }
          }
        }
      }
      if (!redirected) {
        window.open(`/dashboard/pdfs`, "_self");
      }
    }
    // pdf handling end ------------------------------------------
    setShow(false);
  };
  useEffect(() => {
    redirectToSigningPage();
  }, []);
  return (
    <div className="h-28">
      <div className="text-base md:text-lg mb-1 font-semibold">
        Redirecting you to Signing Page
      </div>
      <div className="-mx-[5px] mb-3">
        <BoloLoaderLine
          loading={true}
          bgColor={
            // whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary
          }
        ></BoloLoaderLine>
      </div>
      <div className="gap-2 items-center justify-between text-xs">
        <p>
          You can sign now or later from the "Assigned to Me" tab or from the
          Email.
        </p>
      </div>
      <div></div>
      <div className="flex gap-2 items-center justify-end mt-8">
        {/* <BoloButton
          variant={"secondary"}
          size={"sm"}
          disabled={loading.signLater || loading.signNow}
          onClick={async () => {
            signLaterCB();
          }}
        >
          Sign Later
        </BoloButton> */}
      </div>
    </div>
  );
};
const SelfSignModal = ({
  // documentData,
  setShow,
  // cb,
  instanceFrom,
  cbData,
  signLaterCB,
}: SelfSignModalProps) => {
  return (
    <BoloModal
      setShow={setShow}
      isNoCross={true}
      content={
        <RedirectComponentSigning
          // documentData={documentData}
          // cb={cb}
          setShow={setShow}
          instanceFrom={instanceFrom}
          cbData={cbData}
          signLaterCB={signLaterCB}
        />
      }
    ></BoloModal>
  );
};

export default SelfSignModal;
