import React, { useEffect, useState } from "react";
import { SchemaForUI } from "../../../../../common/src";
import { AiFillCaretRight } from "react-icons/ai";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { BiCircle, BiRadioCircle, BiSolidCircle } from "react-icons/bi";
import { focusOnElement, PreviewStateType } from "../../Preview";

type Props = {
  schemasList: SchemaForUI[][];
  activeSchema: SchemaForUI;
  setActiveSchema: any;
  setPageCursor: (p: number) => void;
  pageCursor: number;
  isOnlyPdfTemplate?: boolean;
  isPreview?: boolean;
  previewState?: PreviewStateType;
  unfilledFieldsKeys?: string[];
};

const LeftSideBar = (props: Props) => {
  const {
    schemasList,
    setPageCursor,
    activeSchema,
    setActiveSchema,
    pageCursor,
    isOnlyPdfTemplate,
    isPreview,
    previewState,
    unfilledFieldsKeys,
  } = props;

  const [radioGroups, setRadioGroups] = useState<any>({});

  const handleClick = (
    page: number,
    activeSchema_: SchemaForUI | undefined
  ) => {
    setPageCursor(page);
    if (activeSchema_) {
      setActiveSchema(activeSchema_);
      focusOnElement(activeSchema_?.id);
    } else {
      let firstSchema = schemasList[page][0];
      if (firstSchema) {
        setActiveSchema(firstSchema);
        focusOnElement(firstSchema?.id);
      }
    }
  };

  const defaultValues = {
    signature: "https://i.imgur.com/AfyPZQg.png",
    text: "Text",
    initials: "Initials",
  };

  function ifAnswered(schemaData: SchemaForUI): boolean {
    let answered = false;
    // console.log(schemaData.subType, schemaData.data);
    if (schemaData.subType == "signature") {
      return !!schemaData.data && schemaData.data !== defaultValues.signature;
    }

    if (schemaData.subType == "text") {
      return !!schemaData.data && schemaData.data !== defaultValues.text;
    }

    if (schemaData.subType == "initials") {
      return !!schemaData.data && schemaData.data !== defaultValues.initials;
    }

    if (schemaData.subType == "date") {
      return !!schemaData.data;
    }

    return answered;
  }

  function radioGroupFilter(page: SchemaForUI[]): SchemaForUI[] {
    let newPage: SchemaForUI[] = [];
    let foundRadioGroup: { [key: string]: SchemaForUI } = {};

    page.map((item, itemIdx) => {
      if (item.radiogroupId && item.subType == "radiogroup") {
        if (foundRadioGroup[item.radiogroupId]) {
          // do nothing as there already exists the represntative
        } else {
          newPage.push(item);
          foundRadioGroup[item.radiogroupId] = item;
        }
      } else {
        newPage.push(item);
      }
    });

    return newPage;
  }
  useEffect(() => {
    if (unfilledFieldsKeys && unfilledFieldsKeys?.length > 0) {
      let page;
      let newSchemaList = radioGroupFilter(schemasList.flat());
      let schema = newSchemaList?.filter(
        (ele: SchemaForUI) =>
          ele?.subType === "radiogroup" &&
          ele?.radiogroupId === unfilledFieldsKeys[0]
      )[0];
      schemasList.forEach((ele: SchemaForUI[], index: number) => {
        ele.forEach((element: SchemaForUI) => {
          if (schema !== undefined && schema?.subType === "radiogroup") {
            if (element?.id === schema?.id) {
              page = index;
              // schema = element;
            }
          } else {
            if (element?.id === unfilledFieldsKeys[0]) {
              page = index;
              schema = element;
            }
          }
        });
      });
      console.log("schema", page, schema);
      if (page !== undefined && page >= 0 && schema) {
        handleClick(page, schema);
      }
    }
  }, [unfilledFieldsKeys]);
  return (
    // removed fixed position remove useless properties later
    <div
      className="top-0 left-0 z-[1] bg-white hidden lg:flex flex-col w-[220px] h-[calc(100vh_-_70px)] mt-[70px]  text-sm"
      style={{ top: "0px" }}
      // style={{ top: isPreview ? "32px" : "0px" }}
    >
      {isPreview && (
        <div className="min-h-[50px] flex items-center pl-2 border-b">
          Preview
        </div>
      )}
      <p className="p-2 font-semibold">Fields</p>
      <div
        tabIndex={-1}
        className="flex flex-col gap-2 overflow-y-auto py-2 p-2 h-full"
        id="mini-pdf-custom-scroll"
      >
        {schemasList.map((page, pageIdx) => {
          page = page.filter((ele) => ele.subType !== "variable"); //For not showing the custom variable on thye LeftSideBar of preview
          page = page.filter((ele) => !ele.isStatic); //For not showing the static schema on thye LeftSideBar of preview
          page = page.filter(
            (ele) =>
              !(
                !ele.data &&
                (ele.subType === "custom_field" ||
                  ele.subType === "contact_field")
              )
          ); //For contact vars
          if (page?.length == 0) {
            return null;
          }

          return (
            <React.Fragment key={pageIdx}>
              <div className="my-1">
                <p
                  className={`text-[11px] ${
                    pageIdx == pageCursor ? "bg-gray-200" : "bg-gray-100"
                  } py-[2px] w-fit px-2 rounded cursor-pointer mb-1`}
                  onClick={() => {
                    handleClick(pageIdx, undefined);
                  }}
                >
                  Page {pageIdx + 1} of {schemasList.length}
                </p>
                {radioGroupFilter(page).map((item, itemIdx) => {
                  if (item?.isStatic) {
                    return null;
                  }
                  let unfilledField;
                  if (item?.subType === "radiogroup" && item?.radiogroupId) {
                    unfilledField = unfilledFieldsKeys?.includes(
                      item?.radiogroupId
                    );
                  } else {
                    unfilledField = unfilledFieldsKeys?.includes(item?.id);
                  }
                  return (
                    <div
                      className="ml-1 flex items-center gap-[2px]"
                      key={item.id}
                    >
                      {activeSchema?.id == item?.id ? (
                        <AiFillCaretRight
                          className="text-sm text-primary"
                          style={{
                            color:
                              previewState?.fetchedData?.accountInfo?.theme,
                          }}
                        />
                      ) : (
                        <div className="h-[14px] w-[14px]"></div>
                      )}
                      <div className="w-[150px] flex justify-between items-center">
                        <p
                          onClick={() => {
                            handleClick(pageIdx, item);
                          }}
                          className={`flex gap-2 items-center text-sm py-[3px] my-[3px] cursor-pointer w-fit px-1 ${
                            activeSchema?.id == item?.id
                              ? "border-b-black border-b"
                              : "border-b border-b-transparent"
                          }`}
                        >
                          <span
                            className={
                              unfilledField ? `text-red-500 ` : `text-black`
                            }
                          >
                            {/* {isOnlyPdfTemplate
                              ? item.showKey || item.key
                              : "Signature"} */}
                            {item.placeholder ||
                              item.showKey ||
                              item.key ||
                              "Signature"}
                          </span>

                          {item.subType == "radiogroup" && (
                            <BiSolidCircle
                              size={12}
                              color={item?.radiogroupColor}
                            />
                          )}
                        </p>
                        {/* {ifAnswered(item) && (
                          <RiCheckboxCircleFill
                            className="text-green-700"
                            size={17}
                          />
                        )} */}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="border-b border-b-[#e5e5e5] ml-[-8px] mr-[-8px]" />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default LeftSideBar;
