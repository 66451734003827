// @ts-nocheck

import { useCallback, useEffect, useMemo, useState } from "react";
// If you're only working with primitives, this is not required
import isEqual from "lodash/isEqual";
import _ from "lodash";
export default function useUndoableState(init) {
  const [states, setStates] = useState([]); // Used to store history of all states
  const [index, setIndex] = useState(0); // Index of current state within `states`
  //   const state = useMemo(() => states[index], [states, index]); // Current state
  const [isValidStateAdd, setIsValidStateAdd] = useState(true); // Current state
  const [formState, setFormState] = useState(null);

  const setState = (value) => {
    // don't set default state
    if (value.title?.toLowerCase() === "Form name (BoloForms)".toLowerCase()) {
      return console.log("default state");
    }

    const prevState = states[states.length - 1];
    // Use lodash isEqual to check for deep equality
    // If state has not changed, return to avoid triggering a re-render
    // console.log("isEqual(prevState, newValue)", isEqual(prevState, value));

    if (isEqual(prevState, value)) {
      return;
    }
    // const copy = states.slice(0, index + 1); // This removes all future (redo) states after current index
    // copy.push(value);
    // setStates(copy);
    // setIndex(copy.length - 1);

    // let newStates = states.slice(0, index + 1);
    // newStates.push(value);

    // // if greater than 10 remove first
    // if (newStates.length > 10) {
    //   newStates.shift();
    // }
    // console.log("newStates", newStates);
    // console.log("states 1", states);

    setStates((prev) => {
      let newStates = prev;
      // .slice(0, index + 1);
      newStates.push(value);
      if (newStates.length > 10) {
        newStates.shift();
      }
      setIndex(newStates.length - 1);
      // console.log("newStates", newStates);
      return newStates;
    });
    // console.log("states 2", states);
  };

  // Clear all state history
  const resetState = (init) => {
    setIndex(0);
    setStates([init]);
  };
  // Allows you to go back (undo) N steps
  const goBack = (steps = 1) => {
    setIndex(Math.max(0, Number(index) - (Number(steps) || 1)));
    let index_ = Math.max(0, Number(index) - (Number(steps) || 1));
    setIsValidStateAdd(false);
    setFormState(states[index_]);
  };
  // Allows you to go forward (redo) N steps
  const goForward = (steps = 1) => {
    setIndex(Math.min(states.length - 1, Number(index) + (Number(steps) || 1)));
    let index_ = Math.min(
      states.length - 1,
      Number(index) + (Number(steps) || 1)
    );
    setIsValidStateAdd(false);
    setFormState(states[index_]);
  };

  const debounceCallSetState = useCallback(
    _.debounce((formState) => setState(formState), 1500),
    []
  );

  useEffect(() => {
    // console.log("formState Changed");
    if (formState && isValidStateAdd) {
      let formStateCopy = JSON.parse(JSON.stringify(formState));
      debounceCallSetState(formStateCopy);
    }
    setIsValidStateAdd(true);
  }, [formState]);

  return {
    formState,
    setFormState,
    index,
    lastIndex: states.length - 1,
    goBack,
    goForward,
  };
}
