import React, { useEffect, useState } from "react";
import "./index.css";
import { AiOutlineCheck } from "react-icons/ai";
import { GrDocumentVerified } from "react-icons/gr";
import {
  BsArrowRight,
  BsRocketTakeoff,
  BsRocketTakeoffFill,
} from "react-icons/bs";
import { IoDocuments } from "react-icons/io5";
import axios from "../../utils/axios";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { validateEmail } from "../../utils/validateEmail";
import {
  BASE_URL,
  getDashboardUrl,
  getDashboardUrlV1,
} from "../../constants/constants";
import { FaArrowCircleRight } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";

const SignatureSuccess = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  let documentId = searchParams.get("documentId");
  let signerId = searchParams.get("signerId");
  let key = searchParams.get("key");
  const [loading, setLoading] = useState(false);
  const [signerDetails, setSignerDetails] = useState<any>({});
  const [pdfDetails, setPdfDetails] = useState<any>({});
  const [allSigners, setAllSigners] = useState<any>([]);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [accountInfo, setAccountInfo] = useState<any>(null);

  const [redirectTimer, setRedirectTimer] = useState(9);

  useEffect(() => {
    if (!(key || (signerId && documentId))) {
      navigate("/login");
    }
  }, []);

  const getBrandingInfo = async () => {
    try {
      let { data } = await axios.post(`${BASE_URL}/owner/get-branding-info`, {
        key,
      });
      if (data?.accountInfo) {
        setAccountInfo(data?.accountInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log({ accountInfo });

  const getDocumentDetailsByKey = async () => {
    if (!key) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/signingLog/signSuccessByKey?key=${key}`
      );

      if (data.error) {
        toast.error(data.message);
        setLoading(false);
        return;
      }
      if (!data.error) {
        // toast.success(data.message);
      }

      let pdfUrl = data.document.documentUrl;
      let pdfName = data.document.documentName;
      let pdfRedirectionUrl = data.document?.settings?.redirectionUrl;
      setPdfDetails({
        pdfUrl,
        pdfName,
        pdfRedirectionUrl,
      });
      let allSigners = data.document.respondants;
      console.log("allSigners", allSigners);
      setAllSigners(allSigners);
      let signer = allSigners.find(
        (signer: any) =>
          signer._id === data.signerId || signer.signerId === data.signerId
      );
      if (signer?.email) {
        setSignUpEmail(signer?.email);
      }
      console.log("signer", signer);
      setSignerDetails(signer);
      setLoading(false);
    } catch (errror) {
      setLoading(false);
      toast.error("Error fetching form");
    }
  };

  function isValidURL(url: string) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  useEffect(() => {
    let getDocumentDetails = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `/getOneDocument?documentId=${documentId}&signerId=${signerId}`
        );
        if (data.error) {
          toast.error(data.message);
          setLoading(false);
          return;
        }
        if (!data.error) {
          // toast.success(data.message);
        }

        let pdfUrl = data.document.documentUrl;
        let pdfName = data.document.documentName;
        let pdfRedirectionUrl = data.document?.settings?.redirectionUrl;
        setPdfDetails({
          pdfUrl,
          pdfName,
          pdfRedirectionUrl,
        });
        let allSigners = data.document.respondants;
        setAllSigners(allSigners);
        let signer = allSigners.find(
          (signer: any) =>
            signer._id === signerId || signer.signerId === signerId
        );
        if (signer?.email) {
          setSignUpEmail(signer?.email);
        }
        setSignerDetails(signer);
        setLoading(false);
      } catch (errror) {
        setLoading(false);
        toast.error("Error fetching form");
      }
    };
    signerId && documentId && getDocumentDetails();
    key && getDocumentDetailsByKey();
  }, [documentId, signerId]);

  useEffect(() => {
    getBrandingInfo();
  }, []);

  useEffect(() => {
    if (
      pdfDetails.pdfRedirectionUrl?.enabled &&
      isValidURL(pdfDetails.pdfRedirectionUrl?.url)
    ) {
      const timer = setTimeout(() => {
        if (redirectTimer > 0) {
          setRedirectTimer((prevTimer) => prevTimer - 1);
        } else {
          window.location.href = pdfDetails.pdfRedirectionUrl?.url;
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [pdfDetails.pdfRedirectionUrl, redirectTimer]);

  const handleInstantRedirect = () => {
    window.location.href = pdfDetails.pdfRedirectionUrl?.url;
    // window.history.replaceState(null, "", window.location.href);
  };

  return (
    <div className="w-full h-screen bg-white flex justify-center items-center">
      <Helmet>
        <title translate="no">Signature Success</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Signature Success" />
        <meta
          name="description"
          content="Your document has been successfully signed. "
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/signature-success`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Signature Success - Boloforms`} />
        <meta
          property="og:description"
          content="Your document has been successfully signed. "
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/signature-success"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="mx-auto max-w-[1200px]">
        <div className="w-full lg:py-0 px-3 sm:px-6 lg:px-12 flex flex-col justify-center items-center">
          <div className="w-full max-w-[1280px] flex flex-col items-center">
            <div className="text-center">
              <div className="success-animation">
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <h1 className="text-2xl sm:text-5xl font-semibold leading-[40px] sm:leading-[60px]">
                Thank you for signing!
              </h1>
              <p className="mt-4 sm:text-xl">
                A copy of signed documents will be emailed to you once the
                signers have finished signing the documents.{" "}
              </p>
            </div>

            {/* <div>
              <p className="text-black">
                {allSigners.length} Signers for this document
              </p>
              {allSigners.map((signer: any, index: number) => {
                return (
                  <p className="" key={index}>
                    <span className="text-black">
                      {signer.name}{" "}
                      {signerDetails.email === signer.email && "(You)"}{" "}
                    </span>
                    <br />
                    {signer.email}
                  </p>
                );
              })}
            </div> */}

            {/* <div className="mt-6 text-center flex flex-col justify-center items-center">
              <button
                onClick={() =>
                  window.open("https://www.boloforms.com/signature/", "_blank")
                }
              >
                Try For Free <BsRocketTakeoffFill className="ml-3 text-xl" />
              </button>
              <p className="mt-4 text-sm text-gray-500">
                Start your free trial today. No credit card required.
              </p>
            </div> */}

            <div className="mt-12 text-center flex flex-col justify-center items-center">
              <p className="text-gray-900 text-center mt-4">
                Over <span className="font-semibold">10,000</span> businesses
                love{" "}
                {whiteLabelInfo?.settings?.organization?.name || "BoloForms"}{" "}
                Signature
              </p>
              <div className="flex w-full px-2 mt-4">
                <input
                  type="text"
                  onChange={(e) => {
                    setSignUpEmail(e.target.value);
                  }}
                  value={signUpEmail}
                  placeholder="Enter your Email"
                  className="w-full text-xs sm:text-sm px-4 sm:px-5 border border-primary outline-none rounded-l-md h-[38px] sm:h-[45px]"
                  style={{
                    border: accountInfo?.theme
                      ? `1px solid ${accountInfo?.theme}`
                      : "",
                  }}
                />
                <button
                  className="max-w-[30vw] bg-primary text-xs sm:text-sm h-[38px] sm:h-[45px] font-semibold text-white flex items-center justify-center px-4 sm:px-5 hover:opacity-90 rounded-r-md"
                  style={{
                    color: accountInfo?.themeText,
                    background: accountInfo?.theme,
                  }}
                  onClick={() => {
                    if (validateEmail(signUpEmail)) {
                      window.open(
                        `${getDashboardUrlV1()}/register?from=FORM_FINAL_SCREEN&ownerEmail=${signUpEmail}`,
                        "_blank"
                      );
                    } else {
                      toast.error("Invalid Email");
                    }
                  }}
                >
                  SignUp
                  <FaArrowCircleRight className="ml-3 hidden sm:block text-xl" />
                </button>
              </div>
              <p className="text-xs text-gray-500 mt-2">
                Start your free trial today. No credit card required.
              </p>
            </div>

            {pdfDetails.pdfRedirectionUrl?.enabled &&
              isValidURL(pdfDetails.pdfRedirectionUrl?.url) && (
                <div className="max-w-[650px] mx-auto bg-white rounded-md mt-8 flex flex-row px-5 py-3 gap-6 justify-around items-center shadow-sm border-[1.5px] border-gray-200">
                  {/* Countdown timer */}
                  <p className="text-gray-600">
                    You'll be redirected in{" "}
                    <span
                      style={{
                        color: accountInfo?.theme
                          ? accountInfo?.theme
                          : COLORS.primary,
                      }}
                      className="hover:underline cursor-pointer "
                      onClick={handleInstantRedirect}
                    >
                      {redirectTimer} seconds.
                    </span>
                  </p>
                </div>
              )}

            <div className="bg-white rounded-md mt-20 h-[100px]">
              {/* <p className="text-gray-700 text-center mb-4">
                Over <span className="font-semibold">10,000</span> businesses
                love BoloForms Signature
              </p> */}
              {/* <img
                src={`https://i.imgur.com/BfvwItR.png`}
                alt={``}
                className="w-full grayscale max-h-full max-w-full"
              /> */}
            </div>
          </div>
        </div>
        <ToastContainer
          transition={Slide}
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
        />
      </div>
    </div>
  );
};

export default SignatureSuccess;
