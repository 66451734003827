import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-toastify/dist/ReactToastify.css";
import "./quill-editor.css";
import {
  applyStyleToTags,
  convertTextToHtml,
} from "../../utils/quillEditorUtils";
interface MailDataFormProps {
  onSave: (mailData: { subject: string; body: string }) => void;
  isPdfTemplate?: boolean;
  setMailData?: (mailData: { subject: string; body: string }) => void;
  className?: string;
}

const MailDataForm: React.FC<MailDataFormProps> = ({
  onSave,
  isPdfTemplate = false,
  setMailData,
  className = "mx-auto",
}) => {
  const [showMailDataForm, setShowMailDataForm] = useState(true);
  const [mailSubject, setMailSubject] = useState("");
  const [mailBody, setMailBody] = useState("");

  // const handleTextClick = () => {
  //   setShowMailDataForm((prev) => !prev);
  // };

  // const handleSave = () => {
  //   console.log(mailSubject, mailBody);
  //   const newMailSubject = mailSubject.trim();
  //   const newMailBody = mailBody.trim();
  //   setMailSubject("");
  //   setMailBody("");
  //   if (newMailSubject || newMailBody) {
  //     onSave({ subject: newMailSubject, body: newMailBody });
  //     return toast.success(
  //       "Mail data saved successfully, you can add Document now"
  //     );
  //   } else {
  //     return toast.error("Please enter valid mail data");
  //   }
  // };
  // const debouncedBodyText = useMemo(
  //   () => _.debounce((text) => convertTexttoHtml(text), 1000),
  //   [mailSubject]
  // );
  const bodyModules = {
    toolbar: [
      [{ bold: true }, { italic: true }, { underline: true }],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };
  const handleChange = (content: string) => {
    setMailBody(content);
  };
  useEffect(() => {
    setMailData &&
      setMailData({
        subject: mailSubject,
        body: convertTextToHtml(applyStyleToTags(mailBody)) || "",
      });
  }, [mailBody, setMailData, mailSubject]);
  return (
    <div
      className={`${className} py-2 md:py-4 w-[100%] md:w-[65%] max-w-[800px] mb-4`}
    >
      {/* <div
        className="py-4 cursor-pointer m-0 flex justify-between items-center"
        onClick={handleTextClick}
      >
        <h2>Add Email Details</h2>
        {!showMailDataForm ? (
          <AiOutlineCaretDown size={20} className="text-gray-600" />
        ) : (
          <AiOutlineCaretDown size={20} className="text-gray-600 rotate-180" />
        )}
      </div> */}
      {showMailDataForm && (
        <div
        // className={`translate-y-0 transition-transform ease-in-out duration-500`} Commented as it affect the react-select dropdown
        >
          <div className={`inputs flex flex-col gap-2 md:gap-4 `}>
            <div className="email flex flex-col">
              <p className="text-xs mb-2 font-medium">
                Email Subject (Optional)
              </p>{" "}
              <div className="flex flex-row justify-center items-center gap-2 border border-gray-300 rounded-md p-2">
                <input
                  type="text"
                  id="subject"
                  value={mailSubject}
                  placeholder="Enter email subject"
                  className="w-full text-xs md:text-sm outline-none"
                  onChange={(e) => {
                    setMailSubject(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="name flex flex-col mt-2">
              <p className="text-xs mb-2 font-medium">Email Body (Optional)</p>{" "}
              <div className="items-center gap-4">
                <div className="quill-editor">
                  <ReactQuill
                    theme="snow"
                    value={mailBody}
                    onChange={handleChange}
                    modules={bodyModules}
                    placeholder="Email body here..."
                    className="quill-textarea"
                  />
                </div>
              </div>
            </div>
            {/* {!isPdfTemplate && (
              <div className="buttons flex gap-4 justify-between w-fit ml-auto">
                <div
                  className="save flex gap-2 bg-white border border-gray-300 py-2 px-4 rounded-md cursor-pointer justify-center items-center hover:border-green-700 transition-all ease-in-out duration-300 hover:shadow-sm text-green-700 flex-1"
                  onClick={handleSave}
                >
                  <GiConfirmed className="w-6 h-6 cursor-pointer" />
                  <p className="font-bold">Save</p>
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MailDataForm;
