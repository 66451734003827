import { ENV, LAMBDA_GET_S3_SIGNED_URL } from "../constants/constants";
import axios from "./axios";

const getS3PreSignedUrl = async (url, workspaceId, email) => {
  let owner = JSON.parse(localStorage.getItem("owner"));

  if (!owner) {
    return null;
  }

  if (ENV === "DEV") {
    let { data } = await axios.get(
      `/common/s3-presigned-url?s3Url=${url}&ownerId=${owner._id}&workspaceId=${workspaceId}&email=${email}`
    );

    return data.signedUrl;
  }

  let { data } = await axios.post(
    LAMBDA_GET_S3_SIGNED_URL,
    {
      s3Url: url,
      expiry: 1 * 60 * 60, // 1 hour
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  console.log("data", data);

  return data.signedUrl;
};

export default getS3PreSignedUrl;
