import React, { useContext } from "react";
// import { SchemaForUI } from '@pdfme/common';
import { readFiles, uuid } from "../../../../helper";
import { FontContext, I18nContext } from "../../../../contexts";
import { SidebarProps } from "..";
import { SchemaForUI } from "../../../../../../common/src";
import { AiOutlineClose as XMarkIcon } from "react-icons/ai";
import { cloneDeep } from "../../../../helper";
import { getSampleByType } from "../../../../helper";
import { v4 } from "uuid";
import { BoloButton } from "../../../../../../../components/Common/Button/BoloButton";
const CloneField = (
  props: Pick<
    SidebarProps,
    | "changeSchemas"
    | "pageNum"
    | "pageCursor"
    // | "schemasList"
    | "addSchema"
    // | "setSchemasList"
    | "isOnlyPdfTemplate"
  > & {
    schemasList: SchemaForUI[][];
    setSchemasList: (schemasList: SchemaForUI[][]) => void;
    activeSchema: SchemaForUI;
  }
) => {
  const {
    changeSchemas,
    activeSchema,
    pageNum,
    pageCursor,
    schemasList,
    setSchemasList,
    isOnlyPdfTemplate,
  } = props;

  const handleDuplicateToAllPages = (isAll: boolean = false) => {
    const schemaId = activeSchema.id;
    const clonedSchema = { ...activeSchema }; // Clone the active schema
    const generateUniqueKey = (baseKey: string, explicitPageCursor: number) => {
      const pageSchemas = schemasList[explicitPageCursor];
      let uniqueKey = baseKey.replace(/\n$/, "");
      let index = 1;
      // let uniqueKey = baseKey;
      // if (!isOnlyPdfTemplate) {
      // @ts-ignore
      while (
        pageSchemas.some(
          (schema) => schema.key === uniqueKey + `(${explicitPageCursor + 1})`
        )
      ) {
        uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
        index++;
      }
      uniqueKey = `${uniqueKey}(${explicitPageCursor + 1})`;
      // } else {
      //   // @ts-ignore
      //   while (pageSchemas.some((schema) => schema.key === uniqueKey)) {
      //     uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
      //     index++;
      //   }
      // }
      return uniqueKey;
    };
    const newSchemasList = schemasList.map((page, index) => {
      if (!isAll && index <= pageCursor) {
        return page;
      }
      if (isAll && index === pageCursor) {
        return page;
      }
      let currentDate = new Date();
      let baseKey = clonedSchema.key.includes("___")
        ? clonedSchema.key.split("___")[0]
        : clonedSchema.key.split("(")[0];
      const oldQuestionId = clonedSchema?.id?.split("_")?.[1];
      let thisPagesSchema: SchemaForUI = {
        ...clonedSchema,
        key: generateUniqueKey(baseKey, index),
        id: uuid() + "_" + oldQuestionId + "_" + oldQuestionId,
      };

      return [...page, thisPagesSchema];
    });

    console.log(newSchemasList?.map((page) => page?.map((data) => data?.id)));
    setSchemasList(newSchemasList);

    // Update the state or data source with the new schemas list
    // For example: changeSchemas(newSchemasList);
  };

  return (
    <div className="mt-4 text-xs flex flex-col gap-2">
      <BoloButton
        variant={"tertiary"}
        size="xs"
        onClick={() => {
          handleDuplicateToAllPages(true);
        }}
      >
        Duplicate to all Pages
      </BoloButton>
      <BoloButton
        variant={"tertiary"}
        size="xs"
        onClick={() => {
          handleDuplicateToAllPages();
        }}
      >
        Duplicate to following Pages
      </BoloButton>
    </div>
  );
};

export default CloneField;
