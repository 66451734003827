import { BASE_URL } from "../constants";

export const gohighlevelVariableMapping = {
  "[crm.Opportunity.Name]": "name",
  "[crm.Opportunity.MonetaryValue]": "monetaryValue",
  "[crm.Opportunity.AssignedTo]": "assignedTo",
  "[crm.Opportunity.Source]": "source",
  "[crm.Opportunity.ContactPerson.Name]": "contact.name",
  "[crm.Opportunity.ContactPerson.Phone]": "contact.phone",
  "[crm.Opportunity.Created]": "createdAt",
  "[crm.Deal.ContactPerson.Email]": "contact.phone",
  "[crm.Deal.ContactPerson.CompanyName]": "contact.companyName",
  "[crm.Opportunity.LostReasonId]": "lostReasonId",
  "[crm.Opportunity.IndexVersion]": "indexVersion",
  "[crm.Opportunity.LastStatusChange]": "lastStatusChangeAt",
  "[crm.Opportunity.LastStageChange]": "lastStageChangeAt",
  "[crm.Opportunity.Status]": "status",
  "[crm.Opportunity.UpdatedAt]": "updatedAt",
  "[crm.Contact.Country]": "person.country",
  "[crm.Contact.FullNameLowerCase]": "person.fullNameLowerCase",
  "[crm.Contact.DateAdded]": "person.dateAdded",
  "[crm.Contact.FirstNameLowerCase]": "person.firstNameLowerCase",
  "[crm.Contact.Email]": "person.email",
  "[crm.Contact.CompanyName]": "person.companyName",
  "[crm.Contact.DateUpdated]": "person.dateUpdated",
  "[crm.Contact.CreatedByChannel]": "person.createdBy.channel",
  "[crm.Contact.CreatedBySource]": "person.createdBy.source",
  "[crm.Contact.CreatedBySourceName]": "person.createdBy.sourceName",
  "[crm.Contact.CreatedTime]": "person.createdBy.timestamp",
  "[crm.Contact.Source]": "person.source",
  "[crm.Contact.SessionSource]": "person.attributionSource.sessionSource",
  "[crm.Contact.Medium]": "person.attributionSource.medium",
  "[crm.Contact.EmailLowerCase]": "person.emailLowerCase",
  "[crm.Contact.FirstName]": "person.firstName",
  "[crm.Contact.Phone]": "person.phone",
};

export const goHighLevelLogo = "https://i.imgur.com/PKvuHBB.png";
export const GHL_OPTIONS = {
  requestType: "code",
  redirectUri: `${BASE_URL}/auth/crm/callback`,
  clientId: "6643453731801cb10090eeaa-lw6af9q6",
  scopes: [
    "opportunities.readonly",
    "contacts.readonly",
    "users.readonly",
    // "locations.readonly",
  ],
  baseUrl: "https://marketplace.gohighlevel.com/oauth/chooselocation",
};
// dev
// clientId: "6643466a0ebf435e6988b998-lwkp639r",

export const GOHIGHLEVEL_ACCESS_EMAILS = [
  "chigup36@gmail.com",
  "chirag@boloforms.com",
  "support@boloforms.com",
  "gohighlevel-testing@boloforms.com",
  "boloforms@gmail.com",
  // "batrarahul409@gmail.com",
  "rickysh0098@gmail.com",
  "rahulbatra647@gmail.com",
];
