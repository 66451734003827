import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { CgTemplate } from "react-icons/cg";
import { FaFolder, FaSave } from "react-icons/fa";
import {
  MdFormatListBulleted,
  MdEdit,
  MdSend,
  MdEmail,
  MdPeopleAlt,
} from "react-icons/md";
import { formatTimeDifference } from "../../../../../utils/uiUtils";
import { HiMiniEye } from "react-icons/hi2";
import CustomTooltip from "../../../../../components/EditForm/CustomTooltip";
import { IoMdArrowRoundBack } from "react-icons/io";
import { getDashboardUrlV1 } from "../../../../../constants/constants";
import axios from "../../../../../utils/axios";
import { toast } from "react-toastify";
import {
  AddSchemaType,
  DesignerState,
  DocumentValueType,
  GlobalHelpersType,
} from "../Designer";
import { BoloButton } from "../../../../../components/Common/Button/BoloButton";
import BoloModal from "../../../../../components/Common/Modal/BoloModal";
import CloseConfirmation from "../Designer/PdfTemplateSidebar/CloseConfirmation";
import SelfSignModal from "../Designer/SelfSign/SelfSignModal";
import { IoArrowBackCircleSharp, IoLinkOutline } from "react-icons/io5";
import ShareViaLink from "../../../../../components/ShareViaLink";
import SigningInPerson from "../../../../../components/SigningInPerson";
import MixPanel from "../../../../../utils/services/mixpanel";
import UpdateNameModal from "./UpdateNameModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import SettingsMenu from "./SettingsMenu";
import ExpirationModal from "./ExpirationModal";
import ReminderModal from "./ReminderModal";
import PleaseUpgradeModal from "../../../../../components/PleaseUpgradeModal";
import { isFeatureEnabled } from "../../../../../utils/paidFeat";
import ImportFields from "./ImportFieldsModal";
import { SchemaForUI } from "../../../../common/src";
import { arrayEquals } from "../../../../../utils/uiUtils";
import LinkGroupErrorModal from "./LinkGroupError";
import { FaLink } from "react-icons/fa6";
import GetTemplateLink from "./GetTemplateLinkModal";
import PlanExaustedBannerEditor from "../../../../../components/PlanExaustedModalEditor";
import { formatPrice } from "../../../../../utils/quoteBuilder";
import { TailSpin } from "react-loader-spinner";
import { getWorkSpaceDetails } from "../../../../../utils";
import { ALL_FEATURES } from "../../../../../types/tiers";
import {
  BoloIconForm,
  BoloIconPDF,
  BoloIconPDFTemplate,
} from "../../../../../components/Common/Icons/BoloIcons";
import Modal from "../../../../../components/Modal";
import { FcCancel } from "react-icons/fc";
import {
  getEmbedHome,
  isEmbeded,
  getIfIsInChromeExtensionIframe,
} from "../../../../../utils/embed";
import {
  COLORS,
  DOCUMENT_COMPLETED_STATUS,
} from "../../../../../constants/common";
import { downloadBase64File } from "../../../../../utils/localPdfUtils";
import getS3PreSignedUrl from "../../../../../utils/getS3PreSignedUrl";
import { useNavigate } from "react-router-dom";
import { giveRequiredFieldsNotFilled, saveInputs } from "../../designerHelper";
import BoloLoader from "../../../../../components/Common/Loader/BoloLoader";
// import RenewalModal from "./RenewalModal";
type EditorNavbarProps = {
  fetchedData: any;
  setReload: (value: any) => void;
  setIsPreview: (value: any) => void;
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
  globalHelpers: GlobalHelpersType;
  onEdit: (targets: HTMLElement[]) => void;
  addSchema: AddSchemaType;
  setRequiredFieldsNotFilled: React.Dispatch<React.SetStateAction<any[]>>;
  setActiveElements: React.Dispatch<React.SetStateAction<HTMLElement[]>>;
};

type DataType = {
  type: "PDF-TEMPLATE" | "PDF" | "FORM";
  icon: ReactNode;
  name: string | undefined;
  folder: string | undefined;
  folderColor: string | undefined;
  status: string | null | undefined;
  updatedAt: string | undefined;
  backUrl: string;
  saveBtn: {
    text: string;
    icon: ReactNode;
    save: () => void;
  };
  getCodeBtn?: {
    text: string;
    icon?: ReactNode;
    getCode: () => void;
  };
  btnVariant?: "yellow" | "primary";
};

const EditorNavbar = ({
  fetchedData,
  setReload,
  setIsPreview,
  designerState,
  setDesignerState,
  globalHelpers,
  onEdit,
  addSchema,
  setRequiredFieldsNotFilled,
  setActiveElements,
}: EditorNavbarProps) => {
  const isInChromeExtensionIframe = getIfIsInChromeExtensionIframe();
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  let currentPlan = owner?.planhistory?.[owner?.planhistory?.length - 1];
  const [templateName, setTemplateName] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [isZeroSchemaCount, setIsZeroSchemaCount] = useState(false);
  const [data, setData] = useState<DataType | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [isSelfSigning, setIsSelfSigning] = useState(false);
  const [cbData, setCbData] = useState<any>(null);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [incompleteDownloadLoading, setIncompleteDownloadLoading] =
    useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState("");
  const [settings, setSettings] = useState({});
  const [showSendingOptions, setShowSendingOptions] = useState<boolean>(false);
  const [showShareViaLink, setShowShareViaLink] = useState<boolean>(false);
  const [isSigningOrderData, setIsSigningOrderData] = useState<boolean>(
    fetchedData?.document?.isSigningOrderData
  );
  const dropdownShareOptionsRef = React.useRef<any>(null);
  const [showSigningInPerson, setShowSigningInPerson] =
    useState<boolean>(false);
  const [saveTableLoading, setSaveTableLoading] = useState<boolean>(false);
  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );
  const [showPaymentDisabledModal, setShowPaymentDisabledModal] =
    useState(false);

  const [showGetCodeModal, setShowGetCodeModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
    redirect: "",
    redirectText: "",
  });
  const [linkGroupData, setLinkGroupData] = useState<{
    linkgroupError: boolean;
    errors: string[];
    errorSchema: SchemaForUI | null;
    pageNum: number;
  }>({
    linkgroupError: false,
    errors: [],
    errorSchema: null,
    pageNum: 0,
  });

  const [showImportFieldsModal, setShowImportFieldsModal] = useState(false);
  const [isQuotaExausted, setIsQuotaExausted] = useState(false);

  const [showSMSExhaustedModal, setShowSMSExhaustedModal] =
    useState<boolean>(false);
  const [SMSExhaustedModalData, setSMSExhaustedModalData] = useState({
    title: "",
    desc: "",
  });
  const [showRequiredFieldsMessage, setShowRequiredFieldsMessage] =
    useState(false);

  const countSchemas = useCallback(() => {
    let count = 0;
    designerState?.schemasList?.map((page, idx) => {
      page?.map((schema) => {
        count += 1;
      });
    });

    return count;
  }, [designerState]);

  // const navigate = useNavigate();

  function checkStripeIntegration(): {
    isPayment: boolean;
    stripeConnectedAndActive: boolean;
  } {
    let stripeConnectedAndActive =
      workSpaceDetails?.stripeData?.isStripeAccountActive &&
      workSpaceDetails?.stripeData?.isStripeConnectedAccount;

    let documenValue: DocumentValueType =
      designerState?.settings?.documentValue;
    let currAmount =
      documenValue?.valueType === "DOCUMENT_TOTAL"
        ? Number(documenValue?.amount?.valueTotal) ?? 0
        : Number(documenValue?.amount?.customAmount) ?? 0;

    let isPayment: boolean = currAmount > 0;

    return { isPayment, stripeConnectedAndActive };
  }

  const linkedFieldCheck = (): {
    error: boolean;
    errors: string[];
    errorSchema: SchemaForUI | null;
    pageNum: number;
  } => {
    // can implement errors
    // not implemented yet
    let linkedGroup2Schema: { [key: string]: SchemaForUI[] } = {};
    let schema2pageNum: { [key: string]: number } = {};

    for (let pgIdx in designerState.schemasList) {
      const page = designerState.schemasList[pgIdx];
      for (let schema_ of page) {
        if (schema_?.linkGroup) {
          linkedGroup2Schema[schema_?.linkGroup] = [
            ...(linkedGroup2Schema[schema_?.linkGroup] || []),
            schema_,
          ];
          schema2pageNum[schema_.id] = parseInt(pgIdx);
        }
      }
    }

    // can implement later but it would cause slow downs
    let linkGroupsWithError: { [key: string]: { error: string } } = {};

    for (let [grpId, schemas_] of Object.entries(linkedGroup2Schema)) {
      // these keys must always be in sync with the
      // keys in change schemas of the designer index

      // if a link group has only one item no need to check
      if (schemas_.length <= 1) {
        continue;
      }

      // we assign the values of first schema to the group data
      // if any of other schema in the same group has a diiferent value for these keys
      // we flag as difference detected
      let gpD = {
        placeholder: schemas_[0]?.placeholder,
        data: schemas_[0]?.data,
        required: schemas_[0]?.required,
        ifDateFormat: schemas_[0]?.ifDateFormat,
        dropdownOptions: schemas_[0]?.dropdownOptions,
        role: schemas_[0]?.role,
        signatureColour: schemas_[0]?.signatureColour,
      };

      for (let scId in schemas_) {
        if (parseInt(scId) == 0) {
          // we dont need to compare the first elem with itself
          continue;
        }

        let sc_ = schemas_[scId];

        let gpE = {
          placeholder:
            // say agar placeholder hai and match nahi kar raha
            gpD.placeholder !== sc_.placeholder &&
            // say agar ek placeholder "" and ek undefined hai
            // 2 combination hai
            // any case we dont mark it is not same
            // therefore this code
            !(
              (gpD.placeholder === undefined && sc_.placeholder === "") ||
              (gpD.placeholder === "" && sc_.placeholder === undefined)
            ),
          // this consideration id for date fields and number field
          // after clearing the data is "" but by default for new date the data is "Date"
          // after clearing the data is "" but by default for new date the data is "Number"
          data:
            gpD.data !== sc_.data &&
            sc_.data !== "Date" &&
            gpD.data !== "Date" &&
            sc_.data !== "Number" &&
            gpD.data !== "Number",
          required: gpD.required !== sc_.required,
          ifDateFormat: gpD.ifDateFormat !== sc_.ifDateFormat,
          dropdownOptions:
            !arrayEquals(
              gpD.dropdownOptions.map((e) => e.option),
              sc_.dropdownOptions.map((e) => e.option)
            ) ||
            !arrayEquals(
              gpD.dropdownOptions.map((e) => e.value),
              sc_.dropdownOptions.map((e) => e.value)
            ),
          role:
            gpD.role?.colour !== sc_.role?.colour ||
            gpD.role?.title !== sc_.role?.title,
          signatureColour: gpD.signatureColour !== sc_.signatureColour,
        };

        if (
          gpE.placeholder || // c
          gpE.data || // c
          gpE.required || // c
          gpE.ifDateFormat || // c
          gpE.dropdownOptions || // c
          gpE.role || // c
          gpE.signatureColour // c
        ) {
          // console.log({ gpd: gpD.data, scd: sc_.data });
          // console.log({ gp: gpD.placeholder, sc: sc_.placeholder });
          // console.log(
          //   gpD.data !== sc_.data && sc_.data !== "Date" && gpD.data !== "Date"
          // );
          // console.log(gpE);
          let errors = [];

          if (gpE.placeholder === true) {
            errors.push("Placeholder does not match.");
          }

          if (gpE.data === true) {
            errors.push("Default Values does not match.");
          }

          if (gpE.required === true) {
            errors.push("Required property does not match.");
          }

          if (gpE.ifDateFormat === true) {
            errors.push("Date format does not match.");
          }

          if (gpE.dropdownOptions === true) {
            errors.push("Dropdown options do not match.");
          }

          if (gpE.role === true) {
            errors.push("Roles do not match.");
          }

          if (gpE.signatureColour === true) {
            errors.push("Roles do not match.");
          }
          return {
            error: true,
            errors: errors,
            errorSchema: sc_,
            pageNum: schema2pageNum[sc_.id],
          };
        }
      }
    }

    return { error: false, errors: [], errorSchema: null, pageNum: 0 };
  };

  const activePathRoute = useCallback((): DataType | undefined => {
    if (window.location.pathname.includes("pdf-template")) {
      return {
        type: "PDF-TEMPLATE",
        icon: <BoloIconPDFTemplate bolosize={"xl"} />,
        name: fetchedData?.document?.documentName,
        folder: fetchedData?.document?.folder?.name,
        folderColor: fetchedData?.document?.folder?.color,
        status: null,
        updatedAt: fetchedData?.document?.updatedAt,
        backUrl: `${getDashboardUrlV1()}/dashboard/templates`,
        saveBtn: {
          text: "Save",
          icon: <FaSave />,
          save: () => {
            if (!countSchemas()) {
              setIsZeroSchemaCount(true);
              return;
            }

            let linkFieldCheck = linkedFieldCheck();
            if (linkFieldCheck.error) {
              setLinkGroupData((prev) => ({
                ...prev,
                linkgroupError: true,
                errors: linkFieldCheck.errors,
                errorSchema: linkFieldCheck.errorSchema,
                pageNum: linkFieldCheck.pageNum,
              }));
              return;
            }

            globalHelpers.saveHandler();
          },
        },
        getCodeBtn: {
          text: "Get Invite Link",
          icon: <FaLink size={17} />,
          getCode: async () => {
            new MixPanel().track("PDF template", {
              pdf_template_action: "pdf_template_get_invite_link",
              paid: false,
            });
            new MixPanel().increment("pdf_template_get_invite_link");
            const isUserEnable = await isFeatureEnabled("E_TEMPLATES");
            if (!isUserEnable) {
              setShowUpgradeModal(true);
              setUpgradeModalType("E_TEMPLATES");
              return;
            }

            if (!fetchedData?.document?.eTemplateId) {
              toast.error(
                "This document is not published yet. Please publish it first."
              );
              return;
            }
            setShowGetCodeModal(true);
          },
        },
      };
    }
    if (window.location.pathname.includes("pdf-signature")) {
      let document = fetchedData?.document;
      if (
        document?.respondants?.length === 1 &&
        document?.respondants?.[0]?.email?.toLowerCase() ===
          owner?.email?.toLowerCase() &&
        designerState?.schemasList?.flat()?.length > 0
      ) {
        return {
          type: "PDF",
          icon: <BoloIconPDF bolosize={"xl"} />,
          name: fetchedData?.document?.documentName,
          folder: fetchedData?.document?.folder?.name,
          folderColor: fetchedData?.document?.folder?.color,
          status: fetchedData?.document?.status,
          updatedAt: fetchedData?.document?.updatedAt,
          btnVariant: "yellow",
          backUrl: `${getDashboardUrlV1()}/dashboard/pdfs`,
          saveBtn: {
            text: "Finish Document",
            icon: "",
            save: async () => {
              setLoading(true);
              setShowRequiredFieldsMessage(true);
              setActiveElements([]);
              let response = await saveInputs({
                designerState,
                setRequiredFieldsNotFilled,
              });
              if (response?.error) {
                setLoading(false);
                toast.error(response.error);
                return;
              } else {
                if (response?.requiredFieldsNotFilledKeys) {
                  setLoading(false);
                  setRequiredFieldsNotFilled(
                    response?.requiredFieldsNotFilledKeys
                  );
                  let activeElement = window.document.getElementById(
                    response?.requiredFieldsNotFilledKeys?.[0]
                  );
                  if (activeElement) {
                    setActiveElements([activeElement]);
                  }
                  toast.info(response?.message);
                } else {
                  setLoading(false);
                  toast.success(response?.message);
                  if (isEmbeded() && !isInChromeExtensionIframe) {
                    // window.location.replace(
                    //   `${window.location.origin}/create/pdf-signature?documentId=${response?.documentId}`
                    // );
                    window.location.href = getEmbedHome();
                  } else {
                    window.location.replace(
                      `${
                        window.location.origin
                      }/create/pdf-signature?documentId=${
                        response?.documentId
                      }${
                        isInChromeExtensionIframe
                          ? "&from=CHROME-EXTENSION"
                          : ""
                      }`
                    );
                  }
                }
              }
            },
          },
        };
      }
      return {
        type: "PDF",
        icon: <BoloIconPDF bolosize={"xl"} />,
        name: fetchedData?.document?.documentName,
        folder: fetchedData?.document?.folder?.name,
        folderColor: fetchedData?.document?.folder?.color,
        status: fetchedData?.document?.status,
        updatedAt: fetchedData?.document?.updatedAt,
        backUrl: `${getDashboardUrlV1()}/dashboard/pdfs`,
        saveBtn: {
          text: "Send",
          icon: <MdSend />,
          save: async () => {
            if (!countSchemas()) {
              setIsZeroSchemaCount(true);
              return;
            }

            let linkFieldCheck = linkedFieldCheck();
            if (linkFieldCheck.error) {
              setLinkGroupData((prev) => ({
                ...prev,
                linkgroupError: true,
                errors: linkFieldCheck.errors,
                errorSchema: linkFieldCheck.errorSchema,
                pageNum: linkFieldCheck.pageNum,
              }));
              return;
            }

            let status = checkStripeIntegration();
            let isStripeEnabled = await isFeatureEnabled("STRIPE_INTEGRATION");
            // console.log({ status, isStripeEnabled });
            if (
              !isStripeEnabled &&
              status.isPayment &&
              status.stripeConnectedAndActive
            ) {
              setShowPaymentDisabledModal(true);
              return;
            }

            setShowSendingOptions(true);
            //  let redirect = true;

            //  // console.log(designerState?.receiversList);
            //  for (let receiver of designerState?.receiversList) {
            //    if (
            //      receiver?.email == owner?.email &&
            //      !designerState?.isSigningOrderData
            //    ) {
            //      // setIsSelfSigning(true);
            //      redirect = false;
            //    }
            //  }
            //  const cbData = await globalHelpers.saveHandler(redirect);
            //  console.log("cbData editor navbar", cbData);
            //  setCbData(cbData);
            //  setIsSelfSigning(!redirect);
          },
        },
      };
    }

    if (window.location.pathname.includes("pdf-mapping")) {
      return {
        type: "FORM",
        icon: <BoloIconForm size={"xl"} />,
        name: fetchedData?.forms?.[0]?.formJson?.title,
        folder: fetchedData?.forms?.[0]?.folder?.name,
        folderColor: fetchedData?.document?.folder?.color,
        status: null,
        updatedAt: fetchedData?.forms?.[0]?.updatedAt,
        backUrl: fetchedData?.forms?.[0]?.formId
          ? `${getDashboardUrlV1()}/create/forms?id=${
              fetchedData?.forms?.[0]?.formId
            }`
          : `${getDashboardUrlV1()}/dashboard/forms`,
        saveBtn: {
          text: "",
          icon: <FaSave />,
          save: () => {
            if (!countSchemas()) {
              setIsZeroSchemaCount(true);
              return;
            }
            globalHelpers.saveHandler();
          },
        },
      };
    }
  }, [designerState]);

  useEffect(() => {
    const getDocumentData = async () => {
      // const { data } = await axios.get(
      //   `/getOneDocument?documentId=${documentId}&templateId=${templateId}`
      // );
      // setSettings(data?.document?.settings);
    };

    // this should be shifted to global helpers
    if (designerState?.instanceFrom !== "FORM") {
      getDocumentData();
    }
  }, [documentId, selectedSetting]);

  const handleRename = async () => {
    setIsOpen(false);
    console.log("DOCUMENT ID", documentId);
    try {
      const { data } = await axios.post("/update-document-name", {
        documentId,
        documentName: templateName,
      });
      console.log(data);
      if (data && !data.error) {
        toast.success(data.message);
        setData((prevData: any) => ({ ...prevData, name: templateName }));
      } else {
        toast.error("Error updating document");
      }
    } catch (error) {
      console.log("Error in Updating", error);
    }
  };

  const pdfSend = async ({
    isLink,
    _redirect,
  }: {
    isLink: boolean;
    _redirect?: boolean;
  }) => {
    let redirect = _redirect;
    console.log("redirect", redirect);
    if (typeof redirect === "undefined") {
      redirect = true;

      for (let receiver of designerState?.receiversList) {
        if (
          receiver?.email == owner?.email &&
          !designerState?.isSigningOrderData
        ) {
          redirect = false;
        }
      }
    }
    const cbData = await globalHelpers.saveHandler({ redirect, isLink });
    console.log("cbData editor navbar", cbData);
    if (cbData === "quota_exhausted") {
      setIsQuotaExausted(true);
      return;
    }
    setCbData(cbData);
    if (cbData !== undefined && isLink === false && _redirect === false) {
      setShowSigningInPerson(true);
    }
    if (typeof _redirect === "undefined") {
      setIsSelfSigning(!redirect);
    }
  };

  const handleOutsideClickShareOptions = (e: any) => {
    if (dropdownShareOptionsRef.current !== null) {
      if (dropdownShareOptionsRef.current.contains(e.target)) return;
    }
    setShowSendingOptions(false);
  };
  useEffect(() => {
    setData(activePathRoute());
    document.addEventListener("mousedown", handleOutsideClickShareOptions);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickShareOptions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlParamsId = new URLSearchParams(window.location.search).get(
      "documentId"
    );
    const urltemplateId = new URLSearchParams(window.location.search).get(
      "templateId"
    );
    setDocumentId(urlParamsId || "");
    setTemplateId(urltemplateId || "");
  }, []);

  useEffect(() => {
    setData(activePathRoute());
  }, [designerState]);
  useEffect(() => {
    setIsSigningOrderData(fetchedData?.document?.isSigningOrderData);
  }, [fetchedData]);
  // This checks for required fields not filled in isEditor a only receipient condition
  useEffect(() => {
    if (!showRequiredFieldsMessage) {
      return;
    }
    let document = designerState?.fetchedData?.document;
    if (
      document?.respondants?.length === 1 &&
      document?.respondants?.[0]?.email?.toLowerCase() ===
        owner?.email?.toLowerCase() &&
      designerState?.schemasList?.flat()?.length > 0
    ) {
      let requiredFieldsNotFilled = giveRequiredFieldsNotFilled(designerState);
      if (requiredFieldsNotFilled?.requiredFieldsNotFilledKeys?.length > 0) {
        setRequiredFieldsNotFilled(
          requiredFieldsNotFilled?.requiredFieldsNotFilledKeys
        );
      } else {
        setRequiredFieldsNotFilled([]);
      }
    }
  }, [designerState.schemasList]);
  const selectOptionType = async (type: string) => {
    console.log(type);
    if (type === "import_fields") {
      let isUserEnable = await isFeatureEnabled("IMPORT_FIELDS");
      if (!isUserEnable) {
        setUpgradeModalType("IMPORT_FIELDS");
        setShowUpgradeModal(true);
        return;
      } else {
        setShowImportFieldsModal(true);
      }
    } else {
      let isUserEnable = await isFeatureEnabled("REMINDERS_ACCESS");
      if (!isUserEnable) {
        setUpgradeModalType("REMINDERS_ACCESS");
        setShowUpgradeModal(true);
        return;
      } else {
        setSelectedSetting(type);
      }
    }
  };

  // used in extension iframe
  const savePdfInEmailDraft = async () => {
    try {
      if (
        designerState.receiversList.reduce((prev, curr, _, arr) => {
          if (arr.length > 1 || curr.email !== designerState.owner.email)
            return true;
          return prev;
        }, false)
      ) {
        toast.error("Remove all other recipients to save in email draft");
        return;
      }

      if (!countSchemas()) {
        toast.error("Please add at least one field to save in email draft");
        return;
      }

      setSavingInProgress(true);
      setShowRequiredFieldsMessage(true);
      setActiveElements([]);

      const response = await saveInputs({
        designerState,
        setRequiredFieldsNotFilled,
        savePDFInEmailDraft: true,
      });

      // if response is error
      if (response?.error) {
        toast.error(response.error);
        return;
      }

      // if required fields are not filled
      if (response?.requiredFieldsNotFilledKeys) {
        setRequiredFieldsNotFilled(response?.requiredFieldsNotFilledKeys);
        let activeElement = window.document.getElementById(
          response?.requiredFieldsNotFilledKeys?.[0]
        );
        if (activeElement) {
          setActiveElements([activeElement]);
        }
        toast.info(response?.message);
        return;
      }

      // response is success
      toast.success(response?.message);
      setTimeout(() => {
        window?.top?.postMessage({ action: "closeModalAndRefresh" }, "*");
      }, 2000);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setSavingInProgress(false);
    }
  };

  return (
    <div className="flex items-center justify-between p-2 h-14 min-h-[56px] max-h-[56px] bg-white border-b shadow px-4">
      <PleaseUpgradeModal
        setShow={setShowPaymentDisabledModal}
        show={showPaymentDisabledModal}
        blockingType={"STRIPE_INTEGRATION"}
        data={{
          closeText: "Procced without payments",
          closeCb: () => {
            setShowPaymentDisabledModal(false);
            setShowSendingOptions(true);
          },
        }}
      />
      {linkGroupData.linkgroupError && (
        <LinkGroupErrorModal
          setDesignerState={setDesignerState}
          setShow={(value: boolean) =>
            setLinkGroupData((prev) => ({ ...prev, linkgroupError: value }))
          }
          handleOk={
            data?.type !== "PDF"
              ? globalHelpers.saveHandler
              : () => {
                  setShowSendingOptions(true);
                }
          }
          errors={linkGroupData.errors}
          pageNum={linkGroupData.pageNum}
          errorSchema={linkGroupData.errorSchema}
          onEdit={onEdit}
          globalHelpers={globalHelpers}
        />
      )}
      {isZeroSchemaCount && (
        <BoloModal
          setShow={(value: boolean) => {
            setIsZeroSchemaCount(value);
          }}
          content={
            <CloseConfirmation
              setShow={(value: boolean) => {
                setIsZeroSchemaCount(value);
              }}
              handleOk={
                data?.type !== "PDF"
                  ? globalHelpers.saveHandler
                  : () => {
                      setShowSendingOptions(true);
                    }
              }
            />
          }
        />
      )}
      {isSelfSigning && (
        <SelfSignModal
          cbData={cbData}
          // documentData={designerState?.fetchedData?.document}
          setShow={setIsSelfSigning}
          // cb={({ redirect }) => globalHelpers.saveHandler(redirect)}
          instanceFrom="PDF"
          signLaterCB={() => window.open(`/dashboard/pdfs`, "_self")}
        />
      )}
      {showShareViaLink && (
        <ShareViaLink
          setShowShareViaLink={setShowShareViaLink}
          documentId={documentId}
          sendToSigners={pdfSend}
          generatedLinks={[]}
          type="PDF"
          sending={true}
        />
      )}
      {showSigningInPerson && (
        <SigningInPerson
          documentId={documentId}
          setShowSigningInPerson={setShowSigningInPerson}
          isSigningOrderData={isSigningOrderData}
          type="PDF"
          sending={true}
        />
      )}
      <div className="flex gap-4 items-center">
        <div
          className={`${
            isInChromeExtensionIframe ? "hidden" : "flex"
          } group cursor-pointer transition-all duration-100 ease-out h-8 w-8 items-center justify-center`}
        >
          <span
            className={`${
              isEmbeded()
                ? "group-hover:h-8 group-hover:w-8"
                : "group-hover:h-0 group-hover:w-0"
            } h-8 w-8 overflow-hidden  transition-all duration-200 flex items-center justify-center`}
          >
            {data?.icon}
          </span>
          <a
            className={`${
              isEmbeded()
                ? "group-hover:h-0 group-hover:w-0"
                : "group-hover:h-8 group-hover:w-8"
            } h-0 w-0 transition-all duration-200`}
            href={isEmbeded() ? getEmbedHome() : data?.backUrl}
          >
            <IoArrowBackCircleSharp className="h-full w-full rounded-sm text-gray-700 " />
          </a>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-700 font-semibold">
            {data?.name}
            {data?.type !== "FORM" && designerState?.mode === "EDITING" && (
              <MdEdit
                className="inline-block ml-2 hover:cursor-pointer "
                onClick={() => {
                  setTemplateName(data?.name || "");
                  setIsOpen(true);
                }}
              />
            )}
          </span>
          <div className="flex gap-3 items-center">
            {data?.folder && (
              <span className="flex gap-1 items-center text-xs text-gray-500">
                <FaFolder size={15} color={data?.folderColor} />
                {data?.folder}
              </span>
            )}
            {(designerState.instanceFrom === "PDF" ||
              designerState.instanceFrom === "PDF-TEMPLATE") && (
              <span
                className="flex gap-1 items-center text-xs text-gray-500 cursor-pointer"
                onClick={() => {
                  setDesignerState((prev) => ({
                    ...prev,
                    combinedSidebar: {
                      open: true,
                      selectedView: "DOCUMENT_VALUE",
                    },
                  }));
                }}
              >
                Document Value{" "}
                {formatPrice({
                  amount:
                    designerState?.settings?.documentValue?.valueType ===
                    "DOCUMENT_TOTAL"
                      ? designerState?.settings?.documentValue?.amount
                          .valueTotal
                      : designerState?.settings?.documentValue?.amount
                          .customAmount,
                  currency:
                    designerState?.settings?.documentValue?.amount.currency,
                })}
              </span>
            )}
            {data?.status && (
              <span className="flex gap-1 items-center text-xs text-gray-500">
                Status {data?.status}
              </span>
            )}
            {data?.updatedAt && (
              <span className="flex gap-1 items-center text-xs text-gray-500">
                Updated {formatTimeDifference(data?.updatedAt)}
              </span>
            )}
            {/* <span className="flex gap-1 items-center text-xs text-gray-500">
              {designerState?.saving
                ? "Saving Changes ..."
                : "All Changes Saved"}
            </span> */}
          </div>
        </div>
      </div>
      {designerState?.mode === "PREVIEW" && (
        <div className="flex gap-4">
          <p
            data-tooltip-id="preview-mode-help"
            data-tooltip-offset={10}
            className="text-sm font-semibold px-3 py-1.5 rounded flex items-center gap-2 "
            style={{
              color: COLORS.status.intermediate.primary,
              background: COLORS.status.intermediate.secondary,
            }}
          >
            <HiMiniEye size={20} />
            Preview Mode
            <CustomTooltip
              helpers={{
                id: "preview-mode-help",
                content:
                  "The document has already been sent you are just viewing the document",
                place: "left",
              }}
            />
          </p>
          <BoloButton
            size={"sm"}
            className="gap-2"
            orientation="center"
            disabled={incompleteDownloadLoading}
            onClick={async () => {
              setIncompleteDownloadLoading(true);
              try {
                if (designerState?.fetchedData?.document.finishedPdfUrl) {
                  let url = await getS3PreSignedUrl(
                    designerState?.fetchedData?.document?.finishedPdfUrl
                  );
                  window.open(url, "_blank");
                } else {
                  let url = `/downlod-semicomplete-pdf?documentId=${documentId}&type=${
                    designerState?.instanceFrom
                  }&respId=${templateId || ""}`;
                  let { data } = await axios.get(url);
                  if (data?.success) {
                    downloadBase64File(
                      data?.pdfBytes,
                      `${documentId}-${templateId || ""}.pdf`
                    );
                  }
                }
              } catch (error) {
                console.log(error);
                toast.error("Something went Wrong");
              }
              setIncompleteDownloadLoading(false);
            }}
          >
            {incompleteDownloadLoading && (
              <TailSpin height="20" width="20" color="#ffffff" />
            )}
            {DOCUMENT_COMPLETED_STATUS?.includes(
              designerState?.fetchedData?.document?.status
            )
              ? "Download"
              : "Download Incomplete"}
          </BoloButton>
          {isInChromeExtensionIframe && (
            <BoloButton
              data-tooltip-id="save-btn"
              data-tooltip-offset={10}
              variant="primary"
              size="sm"
              onClick={savePdfInEmailDraft}
              className="flex items-center gap-2 justify-center w-32"
              disabled={savingInProgress || loading}
            >
              {savingInProgress ? (
                <TailSpin height="20" width="20" color="#ffffff" />
              ) : (
                <FaSave />
              )}
              Save
              <CustomTooltip
                helpers={{
                  id: "save-btn",
                  content: "Save in email draft",
                  place: "bottom",
                }}
              />
            </BoloButton>
          )}
        </div>
      )}
      <div
        className={`flex gap-4 items-center ${
          designerState?.mode === "EDITING" ? "" : "hidden"
        }`}
      >
        {designerState?.view === "QUOTE_BUILDER" && (
          <>
            <BoloButton
              size={"sm"}
              variant={"secondary"}
              disabled={saveTableLoading}
              onClick={() =>
                setDesignerState((prev) => ({ ...prev, view: "EDITOR" }))
              }
            >
              Discard
            </BoloButton>
            <BoloButton
              size={"sm"}
              disabled={saveTableLoading}
              className="flex gap-2 items-center"
              onClick={async () => {
                setSaveTableLoading(true);
                await globalHelpers.updateDocumentValue();
                setSaveTableLoading(false);
              }}
            >
              {saveTableLoading && (
                <TailSpin height="20" width="20" color="#ffffff" />
              )}
              Save Table
            </BoloButton>
          </>
        )}

        {designerState?.view === "EDITOR" && (
          <>
            {!!data?.getCodeBtn?.text && !isInChromeExtensionIframe && (
              <BoloButton
                variant={"secondary"}
                size={"sm"}
                onClick={data?.getCodeBtn?.getCode}
                className="flex items-center gap-2 font-medium justify-center w-32"
              >
                {/* {data?.getCodeBtn?.icon}  */}
                {data?.getCodeBtn?.text}
              </BoloButton>
            )}

            {!!data?.saveBtn?.text && (
              <BoloButton
                disabled={loading || savingInProgress}
                variant={data?.btnVariant ? data?.btnVariant : "primary"}
                size={"sm"}
                onClick={data?.saveBtn?.save}
                className="flex items-center gap-3 justify-center min-w-32"
              >
                {loading && <BoloLoader height={20} width={20} />}
                {data?.saveBtn?.icon}
                {data?.saveBtn?.text}
              </BoloButton>
            )}

            {!isInChromeExtensionIframe && (
              <button
                data-tooltip-id="preview-btn"
                data-tooltip-offset={10}
                className="text-gray-500"
                onClick={() => {
                  setIsPreview(true);
                }}
              >
                <HiMiniEye size={25} />
                <CustomTooltip
                  helpers={{
                    id: "preview-btn",
                    content: "Preview",
                    place: "bottom",
                  }}
                />
              </button>
            )}

            {data?.type !== "FORM" && !isInChromeExtensionIframe && (
              <button
                data-tooltip-id="reminder-btn"
                data-tooltip-offset={10}
                className="text-gray-500 hover:bg-gray-100 cursor-pointer flex justify-center items-center p-3 rounded-full relative"
                onClick={() => {
                  setIsSubMenu(!isSubMenu);
                }}
              >
                <BsThreeDotsVertical size={20} />
                {isSubMenu && (
                  <SettingsMenu
                    designerState={designerState}
                    setIsSubMenu={setIsSubMenu}
                    selectOptionType={selectOptionType}
                    setShowImportFieldsModal={setShowImportFieldsModal}
                  />
                )}
              </button>
            )}

            {isInChromeExtensionIframe && (
              <BoloButton
                data-tooltip-id="save-btn"
                data-tooltip-offset={10}
                variant="primary"
                size="sm"
                onClick={savePdfInEmailDraft}
                className="flex items-center gap-2 justify-center w-32"
                disabled={savingInProgress || loading}
              >
                {savingInProgress ? (
                  <TailSpin height="20" width="20" color="#ffffff" />
                ) : (
                  <FaSave />
                )}
                Save
                <CustomTooltip
                  helpers={{
                    id: "save-btn",
                    content: "Save in email draft",
                    place: "bottom",
                  }}
                />
              </BoloButton>
            )}
          </>
        )}
      </div>
      {selectedSetting === "expiration" && (
        <ExpirationModal
          setSelectedSetting={setSelectedSetting}
          documentId={documentId}
          settings={designerState?.fetchedData?.document?.settings}
        />
      )}
      {selectedSetting === "reminder" && (
        <ReminderModal
          setSelectedSetting={setSelectedSetting}
          documentId={documentId}
          settings={designerState?.fetchedData?.document?.settings}
          isSendViaSMSDocument={designerState?.sentViaSMS}
        />
      )}
      {/* {selectedSetting === "renewal" && (
        <RenewalModal
          setSelectedSetting={setSelectedSetting}
          documentId={documentId}
          settings={settings}
        />
      )} */}
      {showImportFieldsModal && (
        <ImportFields
          setShowImportFieldsModal={setShowImportFieldsModal}
          documentId={documentId}
          designerState={designerState}
          setDesignerState={setDesignerState}
          globalHelpers={globalHelpers}
        />
      )}
      {showSendingOptions && (
        <div
          ref={dropdownShareOptionsRef}
          className={`absolute top-[42px] right-[46px] z-40 mt-2 border border-gray-300 rounded shadow-lg w-[250px]  cursor-pointer bg-white`}
        >
          <div className="flex flex-col items-start justify-start">
            <button
              onClick={() => {
                // check when sent via sms is there enough credits
                let creditsRequired = designerState?.receiversList?.length;

                // const availableCredits =
                //   currentPlan?.usageQuantityAllowed?.[8] ||
                //   0 - currentPlan?.usedQuanity?.[8] ||
                //   0;

                const availableCredits = currentPlan?.smsCredits?.count || 0;

                if (
                  creditsRequired > availableCredits &&
                  designerState?.fetchedData?.document?.sentViaSMS
                ) {
                  setSMSExhaustedModalData({
                    title: "Insufficient SMS Credits",
                    desc: `You need ${creditsRequired} SMS credits to send this document. You have ${availableCredits} SMS credits left.`,
                  });
                  setShowSMSExhaustedModal(true);
                  return;
                }

                setShowSendingOptions(false);
                pdfSend({ isLink: false });
                // sendToSigner({ isLink: false });
                new MixPanel().track("PDF", {
                  pdf_action: "pdf_send_document",
                  isSigningOrderData: designerState?.isSigningOrderData,
                  signers: designerState?.receiversList?.length,
                  sendACopy:
                    designerState?.fetchedData?.document?.responseReceivers
                      ?.length,
                  pdfLanguage:
                    designerState?.fetchedData?.document?.settings?.lang?.value,
                  paid: true,
                  method: "pdf_sent_via_email",
                });
                new MixPanel().increment("pdf_send_document");
              }}
              className={`flex flex-row items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium hover:bg-secondary`}
            >
              <MdEmail className="text-lg  hover:opacity-85 mr-2 w-fit" />
              <span className="w-fit whitespace-nowrap">
                {designerState?.fetchedData?.document?.sentViaSMS
                  ? "Send via SMS"
                  : "Send via Email"}
              </span>
              {designerState?.fetchedData?.document?.sentViaSMS && (
                <span className="whitespace-nowrap text-red-500 text-[10px]">
                  (-
                  {designerState?.receiversList?.length} SMS credits)
                </span>
              )}
            </button>
            <button
              // Add functionality for sharing via links
              onClick={() => {
                // pdfSend({ isLink: true });
                setShowShareViaLink(true);
                setShowSendingOptions(false);
                new MixPanel().track("PDF", {
                  pdf_action: "pdf_send_document",
                  isSigningOrderData: designerState?.isSigningOrderData,
                  signers: designerState?.receiversList?.length,
                  sendACopy:
                    designerState?.fetchedData?.document?.responseReceivers
                      ?.length,
                  pdfLanguage:
                    designerState?.fetchedData?.document?.settings?.lang?.value,
                  paid: true,
                  method: "pdf_sent_via_link",
                });
                new MixPanel().increment("pdf_send_document");
              }}
              disabled={isSigningOrderData}
              className={`flex flex-row items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium hover:bg-secondary`}
              data-tooltip-id={"share-via-link"}
            >
              <IoLinkOutline className="text-lg hover:opacity-85 mr-2" />
              Share via Link
            </button>
            <button
              // Add functionality for signing in person
              onClick={async () => {
                setShowSendingOptions(false);
                await pdfSend({ isLink: false, _redirect: false });
                new MixPanel().track("PDF", {
                  pdf_action: "pdf_send_document",
                  isSigningOrderData: designerState?.isSigningOrderData,
                  signers: designerState?.receiversList?.length,
                  sendACopy:
                    designerState?.fetchedData?.document?.responseReceivers
                      ?.length,
                  pdfLanguage:
                    designerState?.fetchedData?.document?.settings?.lang?.value,
                  paid: true,
                  method: "pdf_sent_via_person",
                });
                new MixPanel().increment("pdf_send_document");
              }}
              className={`flex flex-row items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium hover:bg-secondary`}
            >
              <MdPeopleAlt className="text-lg hover:opacity-85 mr-2" />
              Sign in Person
            </button>
            {isSigningOrderData && (
              <CustomTooltip
                helpers={{
                  id: "share-via-link",
                  content: "Remove sigining order data",
                  place: "right",
                }}
              />
            )}
          </div>
        </div>
      )}

      {/* Modal for updating name of template */}
      {/* convert this to component later on make it callable from any where */}
      {isOpen && (
        <UpdateNameModal
          handleRename={handleRename}
          templateName={templateName}
          setIsOpen={setIsOpen}
          setTemplateName={setTemplateName}
        />
      )}

      {
        // Get Code Modal
        showGetCodeModal && (
          <GetTemplateLink
            eTemplateLink={`${getDashboardUrlV1()}/etemplate?id=${
              fetchedData?.document?.eTemplateId
            }`}
            setIsOpen={setShowGetCodeModal}
          />
        )
      }
      {isQuotaExausted && (
        <PlanExaustedBannerEditor
          setShow={(value: boolean) => {
            setIsQuotaExausted(value);
          }}
          inNewTab={isInChromeExtensionIframe}
        />
      )}
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      {showSMSExhaustedModal && (
        <Modal
          modalContent={
            <div className="flex flex-col items-center justify-center gap-4 p-4">
              <FcCancel className="text-4xl" />
              <h2 className="text-lg font-semibold text-center">
                {SMSExhaustedModalData.title}
              </h2>
              <p className="text-sm text-center">
                {SMSExhaustedModalData.desc}
              </p>
              <BoloButton
                onClick={() => {
                  setShowSMSExhaustedModal(false);
                  window.open("/billings", "_self");
                }}
                variant="primary"
                orientation="center"
                size="sm"
              >
                Buy More SMS Credits
              </BoloButton>
            </div>
          }
        />
      )}
    </div>
  );
};

export default EditorNavbar;
