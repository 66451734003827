import React, { forwardRef, RefObject, Ref, ReactNode } from "react";
// import { SchemaForUI, isTextSchema, isImageSchema, isBarcodeSchema } from '@pdfme/common';

import { ZOOM, SELECTABLE_CLASSNAME } from "../../constants";
import TextSchema from "./TextSchema";
import ImageSchema from "./ImageSchema";
import BarcodeSchema from "./BarcodeSchema";
import FileUploadSchemaUI from "./FileUploadSchema";
import {
  SchemaForUI,
  isTextSchema,
  isImageSchema,
  isBarcodeSchema,
} from "../../../../common/src";
// import { isCheckboxSchema } from "../../../../common/src/type";
import CheckboxSchema from "./CheckboxSchema";
import RadioGroupSchema from "./RadioGroupSchema";
import DropdownSchema from "./DropdownSchema";
import { PreviewStateType } from "../Preview";
import { isTableSchema } from "../../../../common/src/type";
import TableSchemaUI from "./TableSchema";
import {
  ChangeSchemasArg,
  ChangeSchemasType,
  DesignerState,
} from "../Designer";
import { MdCheck, MdLink } from "react-icons/md";
import { dbHsl2Hex } from "../../../../../utils/uiUtils";
import { FaAsterisk } from "react-icons/fa";
import { checkSchemaFilled } from "../../customUtils";
import { isEditorSchema } from "../../designerHelper";

export interface SchemaUIProps {
  schema: SchemaForUI;
  editable: boolean;
  onChange: (value: string, extra?: ChangeSchemasArg[]) => void;
  tabIndex?: number;
  placeholder?: string;
  className?: string;
  isActive?: boolean;
  setActiveSchema?: (schema: SchemaForUI) => void;
  setShownModal?: (arg: boolean) => void;
  outlineStyle?: object;
  handleNext?: () => void;
  onCheck?: (value: boolean) => void;
  addSchema?: (schema: any) => void;
  handleRadioClick?: (schema: SchemaForUI) => void;
  activeElements?: HTMLElement[];
  pageSizes?: { height: number; width: number }[];
  pageCursor?: number;
  isOnlyPdfTemplate?: boolean;
  isPreview?: boolean;
  previewStyle?: string;
  isSigning?: boolean;
  instaceFrom?: string;
  previewState?: PreviewStateType;
  designerState?: DesignerState;
  setDesignerState?: React.Dispatch<React.SetStateAction<DesignerState>>;
  changeSchemas?: ChangeSchemasType;
  isApprover?: boolean;
  isHovering?: boolean;
}

type Props = SchemaUIProps & {
  outline: string;
  onChangeHoveringSchemaId?: (id: string | null) => void;
};

const Wrapper = ({
  children,
  outline,
  onChangeHoveringSchemaId,
  schema,
  className,
  outlineStyle,
  isPreview,
  isSigning,
  isApprover,
  isHovering,
}: Props & { children: ReactNode }) => {
  // Merge the outline style with the existing style object
  let mergedStyle = {
    position: "absolute",
    cursor: "pointer",
    height: schema.height * ZOOM,
    width: schema.width * ZOOM,
    top: schema.position.y * ZOOM,
    left: schema.position.x * ZOOM,
    // ...outlineStyle, // Merge the outline style here
  } as React.CSSProperties;

  if (!(schema?.subType == "variable" || schema?.isStatic)) {
    mergedStyle = { ...mergedStyle, ...outlineStyle };
  }
  // If the document is not in preview or sigining state then show the outlineStyle
  if (!isPreview && !isSigning) {
    mergedStyle = { ...mergedStyle, ...outlineStyle };
  }
  // At the time of signing hide the contact fields with empty value
  if (isSigning) {
    if (
      !schema.data &&
      (schema.subType === "contact_field" || schema.subType === "custom_field")
    ) {
      mergedStyle = { ...mergedStyle, display: "none" };
    }
  }

  const showReqHover =
    isSigning && isHovering && !schema.isStatic && schema.required;
  const isFilled = checkSchemaFilled(schema.subType || "", schema.data);
  return (
    <div
      title={schema.key}
      onMouseEnter={() =>
        onChangeHoveringSchemaId && onChangeHoveringSchemaId(schema.id)
      }
      onMouseLeave={() =>
        onChangeHoveringSchemaId && onChangeHoveringSchemaId(null)
      }
      className={SELECTABLE_CLASSNAME + " " + className}
      id={schema.id}
      style={mergedStyle}
    >
      <div
        className={`${
          showReqHover ? "h-6 px-2 py-1" : "h-0 p-0"
        } whitespace-nowrap transition-all duration-300 bg-[#545559] rounded text-white text-xs absolute translate-y-[calc(-100%_-_10px)] left-0 overflow-hidden `}
      >
        {isFilled.isFilled ? (
          <span className="flex items-center gap-2">
            <span className="text-green-500 font-bold">
              <MdCheck size={14} />
            </span>{" "}
            Completed
          </span>
        ) : schema?.required && isFilled.status !== "unchecked_c" ? (
          <span className="flex items-center gap-2">
            <span className="text-red-500 font-bold">
              <FaAsterisk size={8} />
            </span>{" "}
            Required
          </span>
        ) : (
          "Not Required"
        )}
      </div>

      {schema.linkGroup && (
        <button
          title="This is a linked field. Changes made here will reflect in other linked fields"
          className="text-white rounded flex items-center justify-center absolute -right-2 -top-2 h-4 w-5 bg-primary"
          style={{
            background:
              isPreview || isSigning
                ? ""
                : schema.role?.colour || schema.signatureColour,
          }}
        >
          <MdLink size={15} />
        </button>
      )}
      {children}
    </div>
  );
};

const SchemaUI = (
  props: Props,
  ref: Ref<HTMLTextAreaElement | HTMLInputElement>
) => {
  const { schema } = props;

  const isEditorARecipient = isEditorSchema({
    schema,
    receiversList: props.designerState?.receiversList || [],
    instanceFrom: props.designerState?.instanceFrom || "PDF-TEMPLATE",
  });
  const r = {
    [props.editable || props.schema?.isStatic || isEditorARecipient
      ? "ref"
      : ""]: ref as RefObject<HTMLTextAreaElement | HTMLInputElement>,
  };

  const newIsImageSchema = (schema: SchemaForUI): boolean => {
    if (schema.subType !== "checkbox" && schema.subType !== "radiogroup") {
      return true;
    }
    return false;
  };

  const newIsTextSchema = (schema: SchemaForUI): boolean => {
    if (schema.subType !== "dropdown" && schema.subType !== "file_upload") {
      return true;
    }
    return false;
  };

  return (
    <Wrapper {...props}>
      {/* Original Text schema */}
      {isTextSchema(schema) && newIsTextSchema(schema) && (
        <TextSchema {...r} {...props} schema={schema} />
      )}
      {/* Original Image schema */}
      {isImageSchema(schema) && newIsImageSchema(schema) && (
        <ImageSchema {...r} {...props} schema={schema} />
      )}

      {/* New schemas ---------------------- */}
      {isImageSchema(schema) && schema.subType === "checkbox" && (
        <CheckboxSchema {...r} {...props} schema={schema} />
      )}
      {isImageSchema(schema) && schema.subType === "radiogroup" && (
        <RadioGroupSchema {...r} {...props} schema={schema} />
      )}
      {isTextSchema(schema) && schema.subType === "dropdown" && (
        <DropdownSchema {...r} {...props} schema={schema} />
      )}
      {isBarcodeSchema(schema) && (
        <BarcodeSchema {...r} {...props} schema={schema} />
      )}
      {isTableSchema(schema) && (
        <TableSchemaUI {...r} {...props} schema={schema} />
      )}
      {isTextSchema(schema) && schema.subType === "file_upload" && (
        <FileUploadSchemaUI {...r} {...props} schema={schema} />
      )}
    </Wrapper>
  );
};
export default forwardRef<HTMLTextAreaElement | HTMLInputElement, Props>(
  SchemaUI
);
