// @ts-nocheck

import React, { useState, useEffect } from "react";
import { LuFileJson2, LuPlusCircle } from "react-icons/lu";
import Modal from "../../Modal";
import { MdClose, MdContentCopy, MdOutlineCreate } from "react-icons/md";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { getWorkSpaceDetails } from "../../../utils";
import axios from "../../../utils/axios";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { InfinitySpin } from "react-loader-spinner";

import JsonView from "@uiw/react-json-view";

//themes
import { lightTheme } from "@uiw/react-json-view/light";
import { VscJson } from "react-icons/vsc";
import { isFeatureEnabled } from "../../../utils/paidFeat";
import PleaseUpgradeModal from "../../PleaseUpgradeModal";
import { useAppSelector } from "../../../redux/store";
import MixPanel from "../../../utils/services/mixpanel";
import BoloLoader from "../../Common/Loader/BoloLoader";

let webHookSteps = [
  "All the events will be triggered on the added webhook URL.",
  "You can select the events for specific webhooks URL.",
  "You can set up upto 5 webhooks URLs.",
];

let triggerEvents = [
  { value: "new_form_response", label: "New Form Response" },
  { value: "new_signature_completed", label: "New Signature Completed" },
  { value: "new_template_response", label: "New Template Response" },
];

type WebhooksProps = {};

const Webhooks = ({}: WebkooksProps) => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  //for feature blockings

  const [upgradeModalType, setUpgradeModalType] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );
  const [owner, setOwner] = useState(
    JSON.parse(localStorage.getItem("owner") || "{}")
  );

  const [showAddWebhooks, setShowAddWebhooks] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testing, setTesting] = useState(false);

  const [webhookName, setWebhookName] = useState("");
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookEvent, setWebhookEvent] = useState("new_form_response");

  const [selectedDocs, setSelectedDocs] = useState([]);

  // will be used for pagination in the react select dropdown
  const [recentFormsPage, setRecentFormsPage] = useState(0);
  const [recentDocumentsPage, setRecentDocumentsPage] = useState(0);

  const [recentForms, setRecentForms] = useState([]);
  const [recentDocuments, setRecentDocuments] = useState([]);

  const [showEditWebhooks, setShowEditWebhooks] = useState(false);
  const [editingWebhook, setEditingWebhook] = useState({});

  const [deletingWebhook, setDeletingWebhook] = useState({});

  const [testWebhookData, setTestWebhookData] = useState({});

  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      setWorkSpaceDetails(localWorkSpaceDetails);
      setOwner(JSON.parse(localStorage.getItem("owner") || "{}"));
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  function isValidURL(url: string) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const addWebhookHandler = async (event: any) => {
    event.preventDefault();
    if (webhookName === "" || webhookUrl === "") {
      toast.error("Please fill all the fields");
      return;
    }
    if (!isValidURL(webhookUrl)) {
      toast.error("Please enter a valid URL");
      return;
    }

    let webhookId = v4();

    if (selectedDocs.length === 0) {
      toast.error("Please select atleast one document");
      return;
    }

    let webhookObj = {
      webhookId,
      webhookName,
      webhookUrl,
      webhookEvent,
      selectedDocs,
      webhookStatus: "ACTIVE",
    };
    try {
      setLoading(true);
      const { data } = await axios.post("/workspace/webhooks", {
        webhookObj: webhookObj,
        email: owner.email,
        task: "ADD",
      });

      if (data?.success) {
        localStorage.setItem("owner", JSON.stringify(data?.owner));
        window.dispatchEvent(new Event("storage"));
        toast.success("Webhook added successfully");
        setWebhookName("");
        setWebhookUrl("");
        setWebhookEvent("new_form_response");
        setSelectedDocs([]);
        setLoading(false);
        setShowAddWebhooks(false);
      } else {
        toast.error("Something went wrong");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
      setLoading(false);
    }
  };
  const editWebhookHandler = async (event: any) => {
    event.preventDefault();
    if (
      editingWebhook?.webhookName === "" ||
      editingWebhook?.webhookUrl === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    if (!isValidURL(editingWebhook?.webhookUrl)) {
      toast.error("Please enter a valid URL");
      return;
    }

    if (
      editingWebhook?.webhookEvent === "new_form_response" &&
      editingWebhook?.forms?.length === 0
    ) {
      toast.error("Please select atleast one form");
      return;
    }

    if (
      (editingWebhook?.webhookEvent === "new_signature_completed" ||
        editingWebhook?.webhookEvent === "new_template_response") &&
      editingWebhook?.documents?.length === 0
    ) {
      toast.error("Please select atleast one document");
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post("/workspace/webhooks", {
        webhookObj: editingWebhook,
        email: owner.email,
        task: "EDIT",
      });

      if (data?.success) {
        localStorage.setItem("owner", JSON.stringify(data?.owner));
        window.dispatchEvent(new Event("storage"));
        toast.success("Webhook updated successfully");

        setLoading(false);
        setShowEditWebhooks(false);
      } else {
        toast.error("Something went wrong");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
      setLoading(false);
    }
  };
  const deleteWebhookHandler = async (event: any) => {
    event.preventDefault();

    try {
      setLoading(true);
      const { data } = await axios.post("/workspace/webhooks", {
        webhookObj: deletingWebhook,
        email: owner.email,
        task: "DELETE",
      });

      if (data?.success) {
        localStorage.setItem("owner", JSON.stringify(data?.owner));
        window.dispatchEvent(new Event("storage"));
        toast.success("Webhook deleted successfully");
        setDeletingWebhook({});
        setLoading(false);
      } else {
        toast.error("Something went wrong");
        setDeletingWebhook({});
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
      setDeletingWebhook({});
      setLoading(false);
    }
  };

  const addWebhookModal = () => {
    return (
      <div className="w-[100%] md:w-[600px] rounded-lg">
        <div className="flex justify-between items-center border-b-2 border-primary/30 pb-2">
          <p className="text-lg font-semibold">Add Webhook</p>
          <MdClose
            onClick={() => {
              if (loading) {
                return;
              }
              setShowAddWebhooks(false);
            }}
            className="text-2xl text-gray-600 cursor-pointer"
          />
        </div>
        <div className="inputs flex flex-col gap-2">
          <div className="flex flex-col gap-2 mt-6">
            <label className="text-sm font-medium">
              Webhook Name{" "}
              <span className="text-red-600 text-xs font-light">
                (Required)
              </span>
            </label>
            <input
              type="text"
              value={webhookName}
              placeholder="Enter webhook name"
              className="border border-gray-200 rounded-md p-2 outline-none focus:border-primary bg-primary/10 text-sm"
              onChange={(e) => setWebhookName(e.target.value)}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">
              Webhook URL{" "}
              <span className="text-red-600 text-xs font-light">
                (Required)
              </span>
            </label>
            <input
              type="text"
              value={webhookUrl}
              placeholder="Enter webhook url"
              className="border border-gray-200 rounded-md p-2 outline-none focus:border-primary bg-primary/10 text-sm"
              onChange={(e) => {
                setWebhookUrl(e.target.value);
              }}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Select Events </label>
            <Select
              options={triggerEvents}
              onChange={(e) => {
                setWebhookEvent(e?.value);
              }}
              isSearchable={false}
              defaultValue={triggerEvents[0]}
              classNames={{
                control: (state) =>
                  state.isFocused ? "border-red-600" : "border-grey-300",
              }}
            />
          </div>
          {webhookEvent === "new_form_response" && (
            <AsyncPaginate
              loadOptions={loadFormsOptions}
              isMulti={true}
              isSearchable={false}
              placeholder={"Choose your form"}
              className="cursor-pointer mb-4"
              onChange={(e) => {
                setSelectedDocs(e);
              }}
            />
          )}
          {webhookEvent === "new_signature_completed" && (
            <AsyncPaginate
              loadOptions={loadPDFOptions}
              isMulti={true}
              isSearchable={false}
              placeholder={"Choose your signature document"}
              onChange={(e) => {
                setSelectedDocs(e);
              }}
            />
          )}
          {webhookEvent === "new_template_response" && (
            <AsyncPaginate
              loadOptions={loadTemplateOptions}
              isMulti={true}
              isSearchable={false}
              placeholder={"Choose your Template document"}
              onChange={(e) => {
                setSelectedDocs(e);
              }}
            />
          )}
        </div>
        <div className="flex justify-end gap-4 mt-6">
          {/* <button className="text-sm text-primary border border-primary rounded-md px-4 py-2 hover:cursor-pointer">
            Cancelloading
          </button> */}
          <button
            className="text-sm text-white bg-primary rounded-md px-4 py-2 hover:cursor-pointer disabled:opacity-40"
            disabled={loading || testing}
            onClick={async (e) => {
              await addWebhookHandler(e);
            }}
          >
            {loading ? "Adding Webhook..." : "Add Webhook"}
          </button>
        </div>
      </div>
    );
  };
  const editWebhookModal = () => {
    return (
      <div className="w-[100%] md:w-[600px] rounded-lg">
        <div className="flex justify-between items-center border-b-2 border-primary/30 pb-2">
          <p className="text-lg font-semibold">Edit Webhook</p>
          <MdClose
            onClick={() => {
              if (loading) {
                return;
              }
              setShowEditWebhooks(false);
              setEditingWebhook({});
            }}
            className="text-2xl text-gray-600 cursor-pointer"
          />
        </div>
        <div className="inputs flex flex-col gap-2">
          <div className="flex flex-col gap-2 mt-6">
            <label className="text-sm font-medium">
              Webhook Name{" "}
              <span className="text-red-600 text-xs font-light">
                (Required)
              </span>
            </label>
            <input
              type="text"
              value={editingWebhook?.webhookName}
              className="border border-gray-200 rounded-md p-2 outline-none focus:border-primary bg-primary/10 text-sm"
              onChange={(e) => {
                setEditingWebhook({
                  ...editingWebhook,
                  webhookName: e.target.value,
                });
              }}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">
              Webhook URL{" "}
              <span className="text-red-600 text-xs font-light">
                (Required)
              </span>
            </label>
            <input
              type="text"
              value={editingWebhook?.webhookUrl}
              className="border border-gray-200 rounded-md p-2 outline-none focus:border-primary bg-primary/10 text-sm"
              onChange={(e) => {
                setEditingWebhook({
                  ...editingWebhook,
                  webhookUrl: e.target.value,
                });
              }}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="text-sm font-medium">Select Events </label>
            <Select
              options={triggerEvents}
              onChange={(e) => {
                setEditingWebhook({
                  ...editingWebhook,
                  webhookEvent: e?.value,
                });
              }}
              isSearchable={false}
              defaultValue={triggerEvents.filter(
                (event) => event.value === editingWebhook?.webhookEvent
              )}
              className="cursor-pointer"
            />
          </div>
          {editingWebhook?.webhookEvent === "new_form_response" && (
            <AsyncPaginate
              loadOptions={loadFormsOptions}
              isMulti={true}
              isSearchable={false}
              placeholder={"Choose your form"}
              className="cursor-pointer mb-4"
              defaultValue={editingWebhook?.forms?.map((form) => {
                return {
                  value: form?.id,
                  label: form?.name,
                };
              })}
              onChange={(e) => {
                setEditingWebhook({
                  ...editingWebhook,
                  forms: e?.map((form) => {
                    return {
                      id: form?.value,
                      name: form?.label,
                    };
                  }),
                });
              }}
            />
          )}
          {editingWebhook?.webhookEvent === "new_signature_completed" && (
            <AsyncPaginate
              loadOptions={loadPDFOptions}
              isMulti={true}
              isSearchable={false}
              placeholder={"Choose your signature document"}
              defaultValue={editingWebhook?.documents?.map((doc) => {
                return {
                  value: doc?.id,
                  label: doc?.name,
                };
              })}
              onChange={(e) => {
                setEditingWebhook({
                  ...editingWebhook,
                  documents: e?.map((doc) => {
                    return {
                      id: doc?.value,
                      name: doc?.label,
                    };
                  }),
                });
              }}
            />
          )}
          {editingWebhook?.webhookEvent === "new_template_response" && (
            <AsyncPaginate
              loadOptions={loadTemplateOptions}
              isMulti={true}
              isSearchable={false}
              placeholder={"Choose your Template document"}
              defaultValue={editingWebhook?.documents?.map((doc) => {
                return {
                  value: doc?.id,
                  label: doc?.name,
                };
              })}
              onChange={(e) => {
                setEditingWebhook({
                  ...editingWebhook,
                  documents: e?.map((doc) => {
                    return {
                      id: doc?.value,
                      name: doc?.label,
                    };
                  }),
                });
              }}
            />
          )}
        </div>
        <div className="flex justify-end gap-4 mt-6">
          {/* <button className="text-sm text-primary border border-primary rounded-md px-4 py-2 hover:cursor-pointer">
            Cancelloading
          </button> */}
          <button
            className="text-sm text-white bg-primary rounded-md px-4 py-2 hover:cursor-pointer disabled:opacity-40"
            disabled={loading || testing}
            onClick={async (e) => {
              await editWebhookHandler(e);
            }}
          >
            {loading ? "Updating Webhook..." : "Update Webhook"}
          </button>
        </div>
      </div>
    );
  };
  const deleteWebhookModal = () => {
    return (
      <div className="w-[100%] md:w-[500px] py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            if (loading) {
              return;
            }
            setDeletingWebhook({});
          }}
          className="absolute top-1 md:top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <div className="flex flex-col items-center justify-center px-3 md:px-10">
          <p className="mb-1 text-lg font-semibold">
            {`Are you sure want to  delete the Webhook ?`}
          </p>
          <p className="text-sm  text-gray-500 mb-10 text-center">
            The webhook{" "}
            <span className="font-semibold">
              {deletingWebhook?.webhookName}
            </span>{" "}
            for{" "}
            <span className="font-semibold">
              {deletingWebhook?.webhookEvent}
            </span>{" "}
            will be deleted and can not be undone.
          </p>
          <div
            className="flex gap-x-5"
            onClick={async (e) => {
              await deleteWebhookHandler(e);
            }}
          >
            <button className="text-xs md:text-sm text-white bg-[#ff5151] hover:bg-[#fe7777] px-6 py-2 rounded-full">
              Delete
            </button>

            <button className="text-xs md:text-sm text-white bg-primary hover:bg-primary/80 px-6 py-2 rounded-full">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };
  const testWebhookDataModal = () => {
    return (
      <div className="">
        <div className="flex items-center justify-between py-4 border-b">
          <h3 className="text-lg font-medium text-gray-900">Sample Data</h3>
          <button
            className="text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={() => {
              if (loading) {
                return;
              }
              setTestWebhookData({});
            }}
          >
            <MdClose className="w-5 h-5" />
          </button>
        </div>

        <p className="my-4 text-xs text-green-600 flex items-start ">
          <LuFileJson2 className="w-5 h-5 mr-2" />
          Sent a post request to the webhook URL with this sample data.
        </p>

        <div className="max-h-[400px] overflow-y-scroll bg-slate-500">
          <JsonView
            value={testWebhookData?.testSampleData || {}}
            style={lightTheme}
            displayDataTypes={false}
          />
        </div>
      </div>
    );
  };

  const loadFormsOptions = async (
    _search: string,
    loadedOptions: unknown[]
  ) => {
    const start = loadedOptions.length;
    const end = start + 10; // 10 is limit

    try {
      const { data } = await axios.get(
        `/workspace/webhooks/recent-forms?start=${start}&end=${end}`
      );

      if (!data?.has_more) {
        toast.info("No more forms to load");
      }

      let isShowMore = data?.has_more;

      // if (end >= 50) {
      //   isShowMore = false;
      // }

      return {
        options: data?.formsArray?.map((form: any) => ({
          value: form.value,
          label: form.label,
        })),
        hasMore: isShowMore,
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const loadTemplateOptions = async (
    _search: string,
    loadedOptions: unknown[]
  ) => {
    const start = loadedOptions.length;
    const end = start + 10; // 10 is limit
    try {
      const { data } = await axios.get(
        `/workspace/webhooks/recent-documents?start=${start}&end=${end}&isStandAloneTemplate=${true}`
      );

      if (!data?.has_more) {
        toast.info("No more forms to load");
      }

      let isShowMore = data?.has_more;

      // if (end >= 50) {
      //   isShowMore = false;
      // }

      return {
        options: data?.signatureArray?.map((signature: any) => ({
          value: signature.value,
          label: signature.label,
        })),
        hasMore: isShowMore,
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const loadPDFOptions = async (_search: string, loadedOptions: unknown[]) => {
    const start = loadedOptions.length;
    const end = start + 10; // 10 is limit
    try {
      const { data } = await axios.get(
        `/workspace/webhooks/recent-documents?start=${start}&end=${end}&isStandAloneTemplate=${false}`
      );

      if (!data?.has_more) {
        toast.info("No more forms to load");
      }

      let isShowMore = data?.has_more;

      if (end >= 50) {
        isShowMore = false;
      }

      return {
        options: data?.signatureArray?.map((signature: any) => ({
          value: signature.value,
          label: signature.label,
        })),
        hasMore: isShowMore,
      };
    } catch (error) {
      console.log(error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          blockingType={upgradeModalType}
        />
      )}
      {owner?.webhooks?.length === 0 || !owner?.webhooks ? (
        <div className="w-full mr-auto shadow rounded-md border">
          <div className="p-4 border-b border-primary/20 flex justify-between items-center">
            <h1 className="font-semibold">Webhooks</h1>
            {/* <div className="flex gap-2 text-sm text-primary border border-primary justify-center items-center rounded-md p-2 hover:cursor-pointer">
          <LuPlusCircle />
          Add Webhook
        </div> */}
          </div>
          {/* <div className="p-4 flex gap-4 flex-col md:flex-row flex-grow">
            <div className="rounded-md bg-primary/20 w-fit px-8 py-4 h-full m-auto">
              <img src="https://i.imgur.com/oBzPp5B.png" alt="" className="" />
            </div>
            <div>
              <h1 className="text-xl font-medium">Add Web hook URl</h1>
              <p className="text-xs py-2">
                Set up webhooks and receive notification for different events.
              </p>
              <div className="text-xs mt-2">
                {webHookSteps.map((step, index) => {
                  return (
                    <div key={index} className="flex items-start gap-2">
                      <div className="rounded-full bg-primary min-w-[4px] h-[4px] mt-[6px]"></div>
                      <p>{step}</p>
                    </div>
                  );
                })}
              </div>
              <button
                className="flex gap-2 text-sm text-primary border border-primary justify-center items-center rounded-md p-2 hover:cursor-pointer mt-4 w-fit disabled:opacity-40"
                disabled={loading}
                onClick={() => {
                  if (!workSpaceDetails?.isWorkSpaceOwner) {
                    toast.error("Only owner can add webhooks");
                    return;
                  }
                  setShowAddWebhooks(true);
                }}
              >
                Add Webhook
              </button>
            </div>
          </div> */}
          <div className="flex items-center justify-center flex-col gap-y-5 p-10">
            <p className="text-sm md:text-base font-semibold">
              You don't have webhooks Yet.
            </p>
            <button
              disabled={
                (!workSpaceDetails.isWorkSpaceOwner &&
                  workSpaceDetails.currentWorkSpaceRole !== "admin") ||
                loading ||
                testing
              }
              onClick={async () => {
                new MixPanel().track("API", {
                  api_action: "webhook_create",
                  paid: true,
                });
                new MixPanel().increment("webhook_create");
                let isFeature = await isFeatureEnabled("WEBHOOKS");
                if (!isFeature) {
                  setUpgradeModalType("WEBHOOKS");
                  setShowUpgradeModal(true);
                  return;
                }

                if (
                  !(
                    workSpaceDetails?.isWorkSpaceOwner ||
                    workSpaceDetails?.currentWorkSpaceRole === "admin"
                  )
                ) {
                  toast.error("Only owner and admins can add webhooks");
                  return;
                }
                setShowAddWebhooks(true);
              }}
              className="text-xs text-white bg-primary hover:opacity-80 px-10 py-3 rounded-full disabled:opacity-50"
            >
              Add Webhooks
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full border rounded-md ">
          <div className="p-4 border-b-2 border-primary/50 flex justify-between items-center">
            <h1 className="font-semibold">Webhooks</h1>
            <div
              className="flex gap-2 text-sm text-primary border border-primary justify-center items-center rounded-md p-2 hover:cursor-pointer hover:text-white hover:bg-primary "
              onClick={async () => {
                let isFeature = await isFeatureEnabled("WEBHOOKS");
                if (!isFeature) {
                  setUpgradeModalType("WEBHOOKS");
                  setShowUpgradeModal(true);
                  return;
                }

                if (
                  !(
                    workSpaceDetails?.isWorkSpaceOwner ||
                    workSpaceDetails?.currentWorkSpaceRole === "admin"
                  )
                ) {
                  toast.error("Only owner and admins can add webhooks");
                  return;
                }

                setShowAddWebhooks(true);
              }}
            >
              <LuPlusCircle />
              Add Webhook
            </div>
          </div>
          <div className="flex flex-col w-full justify-center items-center">
            <div className="hidden md:flex px-2 py-5 border-b-[1px] font-semibold border-gray-300 w-full gap-x-5 lg:px-10 text-xs">
              <span className="flex-1">Webhook Name</span>
              <span className="flex-1">Webhook Url</span>
              <span className="flex-1">Actions</span>
              <span className="flex-1">Test Webhook</span>
            </div>
            {loading ? (
              <BoloLoader />
            ) : (
              owner?.webhooks?.map((webhook) => {
                return (
                  <div className="flex items-center px-2 py-5 text-sm hover:bg-secondary/80 w-full gap-x-5 lg:px-10 overflow-x-auto border-b border-gray-200">
                    <span className="flex flex-col flex-1 ">
                      <span>{webhook?.webhookName}</span>
                      <span className="text-xs block text-gray-500">
                        {webhook?.webhookEvent}
                      </span>
                    </span>
                    <span className="flex-1 md:w-[50px]">
                      {webhook?.webhookUrl?.substring(0, 15) + "..."}
                    </span>
                    <div className="flex gap-4  flex-1">
                      <MdOutlineCreate
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          setEditingWebhook(webhook);
                          setShowEditWebhooks(true);
                        }}
                      />
                      <RiDeleteBinLine
                        className="text-xl cursor-pointer text-red-500 ml-2"
                        onClick={() => {
                          setDeletingWebhook(webhook);
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <button
                        disabled={loading || testing}
                        className="border border-primary text-primary rounded-md whitespace-nowrap p-2 hover:bg-primary hover:text-white disabled:opacity-50"
                        onClick={async () => {
                          try {
                            setTesting(true);
                            setTestWebhookData({
                              ...testWebhookData,
                              webhookId: webhook?.webhookId,
                            });
                            const { data: webhookTestResponse } =
                              await axios.post(
                                "/workspace/webhooks/test-webhook",
                                {
                                  webhookObj: webhook,
                                }
                              );
                            if (webhookTestResponse?.success) {
                              setTestWebhookData({
                                ...testWebhookData,
                                testSampleData: webhookTestResponse?.sampleData,
                                webhookName: webhook?.webhookName,
                                webhookEvent: webhook?.webhookEvent,
                                webhookUrl: webhook?.webhookUrl,
                              });
                              toast.success(
                                "Successfully sent sample data to the webhook url"
                              );
                              setTesting(false);
                            } else {
                              toast.error(
                                "Failed to send sample data to the webhook url"
                              );
                              setTesting(false);
                            }
                          } catch (err) {
                            console.log(err);
                            toast.error(
                              "Failed to send sample data to the webhook url"
                            );
                            setTesting(false);
                          }
                        }}
                      >
                        {testing &&
                        testWebhookData?.webhookId === webhook?.webhookId
                          ? "Testing.."
                          : "Test Webhook"}
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
      {showAddWebhooks && <Modal modalContent={addWebhookModal()} />}
      {showEditWebhooks && <Modal modalContent={editWebhookModal()} />}
      {deletingWebhook?.webhookId && (
        <Modal modalContent={deleteWebhookModal()} />
      )}
      {testWebhookData?.webhookUrl && (
        <Modal modalContent={testWebhookDataModal()} />
      )}
    </>
  );
};

export default Webhooks;
