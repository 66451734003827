import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { convertToTitleCase } from "../../utils/contacts";
import QuillEditor from "./QuillEditor";
import BrandingPreview from "../BrandingPreview/BrandingPreview";
import { useAppSelector } from "../../redux/store";
import { BoloButton } from "../Common/Button/BoloButton";
import "./quill-editor.css";
import {
  applyStyleToTags,
  convertHtmlToText,
  convertObjectsToTags,
  convertTagsToObjects,
  convertTextToHtml,
  convertToNormalText,
} from "../../utils/quillEditorUtils";
interface MailDataFormTemplatesProps {
  setMailData?: (mailData: { subject: string; body: string }) => void;
  mailData?: any;
  contactVariables?: any;
  variables?: any;
  receiversList?: any[];
  savedMailData?: any;
  className?: string;
}

const MailDataFormTemplates: React.FC<MailDataFormTemplatesProps> = ({
  mailData,
  setMailData,
  contactVariables,
  variables,
  receiversList,
  savedMailData,
  className,
}) => {
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const [mailSubject, setMailSubject] = useState<string>("");
  const [mailBody, setMailBody] = useState<string>("");
  const [brandingPreview, setBrandingPreview] = useState(false);
  const currentBrandingTab = "EMAIL";
  const [accountInfo, setAccountInfo] = useState({
    name: owner?.name || "",
    photo: "",
    photoFile: null,
    org: owner?.accountInfo?.companyName || "",
    orgLogo: owner?.accountInfo?.companyLogo || "",
    orgSize: owner?.accountInfo?.companySize || "",
    orgIndustry: owner?.accountInfo?.companyIndustry || "",
    orgWebsite: owner?.accountInfo?.companyWebsite || "",
    role: owner?.accountInfo?.role || "",
    department: owner?.accountInfo?.department || "",
    timeZone: owner?.accountInfo?.timeZone || "",
    theme: owner?.accountInfo?.theme || "",
    defaultWaterMark: owner?.accountInfo?.defaultWatermark || "",
    selectedLanguage: owner?.accountInfo?.selectedLanguage || "en",
  });

  const subjectModules = {
    toolbar: false, // Disable the toolbar
    keyboard: {
      bindings: {
        bold: null,
        italic: null,
        underline: null,
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };
  const bodyModules = {
    toolbar: [
      [{ bold: true }, { italic: true }, { underline: true }],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const [variablesList, setVariablesList] = useState<
    { title: string; value: string; key: string; type: string }[]
  >([]);

  useEffect(() => {
    let tagsList: any = [];
    let seen = new Set();
    let uniqueContactVars = contactVariables.filter((variable: any) => {
      const duplicate = seen.has(variable.fieldName);
      seen.add(variable.fieldName);
      return !duplicate;
    });
    uniqueContactVars.forEach((variable: any) => {
      const data = {
        title: convertToTitleCase(variable?.fieldName),
        value: convertToTitleCase(variable?.fieldName),
        key: variable?.fieldName,
        type: "contact_field",
      };
      tagsList.push(data);
    });
    variables.forEach((variable: any) => {
      const data = {
        title: variable?.varName?.replace(/[[\]]/g, "") || "",
        value: variable?.varName?.replace(/[[\]]/g, "") || "",
        key: variable?._id,
        type: "variable",
      };
      tagsList.push(data);
    });
    const signerEmailandName = [
      {
        title: "Signer Name",
        value: "Signer Name",
        key: "name",
        type: "default",
      },
      {
        title: "Signer Email",
        value: "Signer Email",
        key: "email",
        type: "default",
      },
    ];
    signerEmailandName.forEach((signer) => {
      tagsList.push(signer);
    });
    setVariablesList(tagsList);
  }, [receiversList, contactVariables, variables]);

  useEffect(() => {
    setMailData &&
      setMailData({
        subject: convertToNormalText(convertTagsToObjects(mailSubject)),
        body:
          convertTextToHtml(applyStyleToTags(convertTagsToObjects(mailBody))) ||
          "",
      });
  }, [mailBody, setMailData, mailSubject]);

  useEffect(() => {
    setMailSubject(convertObjectsToTags(savedMailData?.subject));
    const mailBodyData = convertHtmlToText(
      convertObjectsToTags(savedMailData?.body)
    );
    setMailBody(mailBodyData);
  }, [savedMailData]);

  useEffect(() => {
    // const owner = JSON.parse(localStorage.getItem("owner") || "{}");
    setAccountInfo({
      name: owner?.name || "",
      photo: "",
      photoFile: null,
      org: owner?.accountInfo?.companyName || "",
      orgLogo: owner?.accountInfo?.companyLogo || "",
      orgSize: owner?.accountInfo?.companySize || "",
      orgIndustry: owner?.accountInfo?.companyIndustry || "",
      orgWebsite: owner?.accountInfo?.companyWebsite || "",
      role: owner?.accountInfo?.role || "",
      department: owner?.accountInfo?.department || "",
      timeZone: owner?.accountInfo?.timeZone || "",
      theme: owner?.accountInfo?.theme || "",
      defaultWaterMark: owner?.accountInfo?.defaultWatermark || "",
      selectedLanguage: owner?.accountInfo?.selectedLanguage || "en",
    });
  }, [owner]);

  return (
    <div className={`py-2 md:py-4 w-[100%] md:w-[65%] max-w-[800px] mb-4`}>
      <div>
        <div className={`inputs flex flex-col gap-2 md:gap-4 `}>
          <div className="email flex flex-col">
            <p className="text-xs mb-2 font-medium">Email Subject (Optional)</p>{" "}
            <p className="text-xs mb-4 font-medium text-gray-500">
              Type <strong>@</strong> to access the custom variable dropdown
              option
            </p>
            <div className="flex flex-row justify-center items-start gap-2 rounded-md">
              <QuillEditor
                varsList={variablesList?.map((question) => ({
                  key: question?.key,
                  value: question?.value,
                  title: question?.title,
                  type: question?.type,
                }))}
                className=""
                placeholder="Email subject here..."
                modules={subjectModules}
                data={mailSubject}
                setData={setMailSubject}
              />
            </div>
          </div>
          <div className="name flex flex-col mt-2">
            <p className="text-xs mb-2 font-medium">Email Body (Optional)</p>{" "}
            <p className="text-xs mb-4 font-medium text-gray-500">
              Type <strong>@</strong> to access the custom variable dropdown
              option
            </p>
            <div className="items-center gap-4">
              <div className="flex flex-row justify-center items-start gap-2 rounded-md ">
                <QuillEditor
                  varsList={variablesList?.map((question) => ({
                    key: question?.key,
                    value: question?.value,
                    title: question?.title,
                    type: question?.type,
                  }))}
                  className="quill-textarea"
                  placeholder="Email body here..."
                  modules={bodyModules}
                  data={mailBody}
                  setData={setMailBody}
                />
              </div>
            </div>
          </div>
        </div>
        <BoloButton
          variant={"tertiary"}
          orientation={"center"}
          size="sm"
          className="w-48 float-right mt-2"
          onClick={() => setBrandingPreview(true)}
        >
          <span> Preview </span>
        </BoloButton>
      </div>
      {brandingPreview && (
        <BrandingPreview
          accountInfo={accountInfo}
          show={brandingPreview}
          setShow={setBrandingPreview}
          currentBrandingTab={currentBrandingTab}
          signerEmailContent={{
            contactVariables,
            variables,
            receiversList,
            mailData,
          }}
        />
      )}
    </div>
  );
};

export default MailDataFormTemplates;
