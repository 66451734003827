import { useEffect, useState } from "react";

import axios from "../../utils/axios";
// import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { MdClose, MdOutlineKeyboardBackspace } from "react-icons/md";
import { v4 } from "uuid";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
import { InfinitySpin } from "react-loader-spinner";
import PdfMapper from "../../components/PdfMapper";
import { fetchOwner } from "../../utils";
import MobileDeviceNotSupported from "../../components/MobileDeviceNotSupported";
import { AiFillEye } from "react-icons/ai";
import PdfPreview from "../../components/PDFPreview";
import useFetch from "../../hooks/useFetch";
import { DEFAULT_DATA, STANDARD_DATA } from "../../pdfme/ui/src/constants";
import { IoArrowBackCircle } from "react-icons/io5";
import { Helmet } from "react-helmet";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import TrialExtendedModal from "../../components/TrialExtendedModal";
type Props = {};

const DesignTemplate = (props: Props) => {
  const form = {
    title: "Form name (BoloForms)",
    description: "No Description",
    logo: "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/BoloForms+Logo+(1)/2.svg",
    fontFamily: "Poppins",
    accentColor: COLORS.primary,
    backgroundColor: COLORS.secondary,
    themeTemplate: "google-forms",
    darkMode: false,
    pages: [
      {
        title: "Page 1",
        description: "Page Description",
        id: v4(),
        questions: [
          {
            type: "Text",
            title: "Email",
            description: "",
            required: true,
            id: v4(),
            value: "",
            validation: "respondent_email",
          },
        ],
        goToNextPage: "next",
        goToPrevPage: "prev",
      },
      {
        title: "Page 2",
        description: "Page Description",
        id: v4(),
        questions: [],
        goToNextPage: "next",
        goToPrevPage: "prev",
      },
    ],
  };
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  let navigate = useNavigate();
  const [owner, setOwner] = useState<any>({});
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [schemas, setSchemas] = useState<any>([]);
  const [sampledata, setSampledata] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [pdfPages, setPdfPages] = useState<number>(1);
  const [templateAvailable, setTemplateAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [formResponseData, setFormResponseData] = useState<any>(null);
  const [formState, setFormState] = useState<any>(null);
  const [startSave, setStartSave] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [formState_, setFormState_] = useState<any>({});
  const [fetchData, setFetchData] = useState<any>({});
  const [reload, setReload] = useState<boolean>(false);

  const [fileName, setFileName] = useState<string>("");

  const [searchParams] = useSearchParams();
  let formId = searchParams.get("id");

  const { data } = useFetch(`/forms?formId=${formId}`);
  let { forms }: any = data || {};

  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
    };
    updatedOwner();
  }, []);

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  const parseDefaultResponses = () => {
    let currentResponse: any = {};
    let responseItemsId: any = {};
    let today = new Date();
    let valueObj: any = {
      Text: "Test Answer",
      LongText: "Test Answer",
      Checkbox: "Test Option",
      RadioGroup: "Test Option",
      Dropdown: "Test Option",
      Date: `${today.getDate()}/${today.getMonth()}/${today.getFullYear()}`,
      Time: `${today.getHours()}:${today.getMinutes()}`,
      Signature: DEFAULT_DATA.signature.unsigned,
      Linear: "Test Value",
      GridCheckbox: "Test Option",
      FileUpload: DEFAULT_DATA.imageFileUpload.defaultLink,
    };
    formState?.pages?.forEach((page: any) => {
      page.questions.forEach((question: any) => {
        currentResponse[question.id] =
          valueObj[question?.type] || "Default Value";

        if (question?.validation === "respondent_email") {
          currentResponse[question.id] = "example@domain.com";
        }
        // if (question?.type == "GridCheckbox") {
        //   let newQuestion = { ...question };
        //   newQuestion?.gridRange?.rows?.map((row: any, rowIdx: any) => {
        //     responseItemsId[question?.id + "@" + rowIdx] =
        //       question?.title?.trim() + "@" + row;
        //   });
        // }
        // // gotta test this with else
        responseItemsId[question.id] = question.title;
      });
    });

    let data = {
      formId,
      respondentEmail: "example@domain.com",
      response: currentResponse,
      responseItemsId,
      addUniqueIdToRow: null,
      from: "BOLOFORMS_FORM",
    };

    setFormResponseData(data);
  };

  const parseSchemaForDeletedQuestion = (schema: any, formJson: any) => {
    if (!formJson) {
      return schema;
    }
    const questions = formJson?.pages?.flatMap((page: any) => page?.questions);
    // console.log({ questions });
    // console.log({ schema });
    let newSchema = schema?.map((page: any) => {
      let newPage = { ...page };
      for (let [key, value] of Object.entries(newPage)) {
        //@ts-ignore
        const splitId = value?.id?.split("_")?.[1];
        let questionExist = questions?.find(
          (question: any) => question?.id && question?.id == splitId
        );
        if (!questionExist) {
          //@ts-ignore
          newPage[key] = { ...value, isQuestionDeleted: true };
        }
      }
      return newPage;
    });
    return newSchema;
  };

  useEffect(() => {
    parseDefaultResponses();
  }, [formState]);

  useEffect(() => {
    let getFormDetails = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`/forms?formId=${formId}`);
        setFetchData(data);
        let { forms }: any = data || {};
        setFormState_(forms);
        if (!data.error) {
          toast.success(data.message);
        }
        let fileName = data.forms[0].pdfTemplate.basePdfName;
        setFileName(fileName);

        let pdfUrl = data.forms[0].pdfTemplate.basePdf;
        const responseFile = await fetch(pdfUrl);
        const pdfBuffer = await responseFile.arrayBuffer();

        // Convert PDF buffer to a base64 string
        let pdfBase64 = arrayBufferToBase64(pdfBuffer);
        pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
        setPdfFile(pdfBase64);
        const parsedSchema = parseSchemaForDeletedQuestion(
          data?.forms[0]?.pdfTemplate?.schemas,
          data?.forms[0]?.formJson
        );
        // console.log({
        //   parsedSchema,
        //   schemas: data?.forms[0]?.pdfTemplate?.schemas,
        // });
        // await setSchemas(data?.forms[0]?.pdfTemplate?.schemas);
        await setSchemas(parsedSchema);
        if (data?.forms[0]?.formJson) {
          setFormState(data?.forms[0]?.formJson);
        } else {
          setFormState(form);
        }
        setSampledata(data?.forms[0]?.pdfTemplate?.sampledata);
        setLoading(false);
      } catch (errror) {
        setLoading(false);
        toast.error("Error fetching form");
      }
    };

    getFormDetails();
  }, [formId, isSaved]);

  if (loading) {
    return (
      <div className="h-screen -mt-[70px] flex justify-center items-center bg-gray-200">
        <BoloLoader />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title translate="no">Pdf Mapping</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Form Pdf Mapping" />
        <meta
          name="description"
          content="Efficiently map your form questions to PDFs with our PDF Mapping feature, empowering users to seamlessly integrate and organize data."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/create/pdf-mapping`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Form Pdf Mapping - Boloforms`} />
        <meta
          property="og:description"
          content="Efficiently map your form questions to PDFs with our PDF Mapping feature, empowering users to seamlessly integrate and organize data."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/create/pdf-mapping"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <>
        <MobileDeviceNotSupported />
        <div className="p-display">
          {pdfFile && (
            <div className="w-full h-full">
              {/* <div className="fixed top-0 right-0 z-50 p-2 -ml-12 mt-2">
              {isPreview ? (
                <button onClick={() => setIsPreview(false)} className="-ml-20">
                  <MdClose className="text-gray-700" size={20} />
                </button>
              ) : (
                <button
                  onClick={() => {
                    // setIsPreview(true);
                    setStartSave(true);
                  }}
                >
                  <AiFillEye className="text-white" size={25} />
                </button>
              )}
            </div>
            {!isPreview && (
              <div className="fixed top-0 z-50 right-[300px] translate-x-[100%] template-navbar h-14 px-4 py-2 bg-primary flex gap-2 text-white items-center">
                <IoArrowBackCircle
                  className="hover:opacity-70 text-3xl cursor-pointer rounded-sm"
                  onClick={() => {
                    // navigate("/dashboard?documentType=FORM");
                    navigate(`/create/forms/?id=${formId}`);
                  }}
                />
                <p className="text-sm ml-2">Edit Template</p>
              </div>
            )} */}
              {/* <div
              className="template-navbar px-8 py-2 bg-primary flex gap-2 text-white items-center h-8"
            >
              <div className="flex gap-2 items-center w-[100%]">
                <MdOutlineKeyboardBackspace
                  className="hover:bg-white hover:text-primary text-2xl cursor-pointer rounded-sm"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                />
                <p className="text-sm">
                  Edit Template:{" "}
                  <span className="font-semibold">{fileName}</span>
                </p>
              </div>
              {isPreview && (
                <div className="text-sm flex items-center justify-center w-[100%] ">
                  This is a Preview
                </div>
              )}
              <div className="flex items-end justify-end w-[100%]">
                {isPreview ? (
                  <button onClick={() => setIsPreview(false)}>
                    <MdClose className="text-white" size={20} />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      // setIsPreview(true);
                      setStartSave(true);
                    }}
                  >
                    <AiFillEye className="text-white" size={25} />
                  </button>
                )}
              </div>
            </div> */}
              {!isPreview ? (
                <PdfMapper
                  setIsSaved={setIsSaved}
                  setStartSave={setStartSave}
                  startSave={startSave}
                  setIsPreview={setIsPreview}
                  file={pdfFile}
                  numberOfPages={pdfPages}
                  schemas={schemas}
                  sampledata={sampledata}
                  fetchedData={fetchData}
                  setReload={setReload}
                />
              ) : (
                <div className="fixed top-0 left-0 w-screen h-screen bg-gray-300 flex items-start justify-start z-50 overflow-auto">
                  <div className="w-full h-full flex flex-col justify-between">
                    {/* Cross button to close the modal */}
                    <div className="w-full flex items-center justify-start h-[40px] bg-black text-white gap-6 shadow text-sm">
                      <div className="flex gap-2 items-center w-[100%]">
                        <button
                          className=" text-gray-800 hover:text-primary ml-2 shadow px-2 py-1 rounded-[3px]"
                          onClick={() => {
                            setIsPreview(false);
                            // setIsShowPreview(false);
                            // setCheckboxVerificationChecked(false);
                          }}
                        >
                          <MdOutlineKeyboardBackspace className=" text-2xl text-white" />
                        </button>
                        <span className="text-sm">{fileName}</span>
                      </div>
                      <div className="flex gap-2 items-center justify-center w-[100%]">
                        This is a Preview
                      </div>
                      <div className="flex gap-2 items-center justify-end w-[100%] pr-6">
                        <button onClick={() => setIsPreview(false)}>
                          <MdClose className="text-white" size={20} />
                        </button>
                      </div>
                    </div>

                    <PdfPreview
                      form={formState_?.[0]}
                      pdfFile={pdfFile}
                      formResponseData={formResponseData}
                      heightSub={80}
                    />

                    <div className="w-full flex items-center justify-center h-[60px] bg-white gap-5 shadow">
                      <label className="flex items-center text-sm">
                        <input
                          type="checkbox"
                          className="mr-2"
                          disabled
                          checked={false}
                          onChange={() => {}}
                        />
                        I have read and verified the document
                      </label>

                      <button
                        className={`px-4 py-2 bg-primary text-white text-sm rounded-[3px] disabled:opacity-50 disabled:cursor-not-allowed`}
                        disabled={true}
                        onClick={async () => {
                          // setLoading(true);
                          // await handleFormSubmit();
                          // setIsShowPreview(false);
                          // setCheckboxVerificationChecked(false);
                          // setLoading(false);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <ToastContainer
            transition={Slide}
            position="bottom-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
            closeButton={false}
          />
        </div>
      </>
    </>
  );
};

export default DesignTemplate;
