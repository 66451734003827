import React from "react";
import { MdClose } from "react-icons/md";
import { BoloLoaderLine } from "../../../../../../components/Common/Loader/BoloLoader";

type ViewHeaderType = {
  name: string;
  open: boolean;
  // setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: (value: boolean) => void;
  loading?: boolean;
};
const ViewHeader = ({ name, open, setOpen, loading }: ViewHeaderType) => {
  return (
    <div className="mb-4">
      <div className="border-b -mx-4  px-4 py-2 shadow-sm">
        <div className="flex justify-between items-center text-gray-700 p-2 text-sm">
          <span className="font-semibold">{name}</span>
          <MdClose
            className={`text-xl cursor-pointer translate-x-[0.5rem] ${
              open ? "rotate-0" : "rotate-45"
            } transition-all duration-300`}
            onClick={() => {
              setOpen(false);
            }}
          />
        </div>
      </div>
      <BoloLoaderLine
        outerClass="-mx-4 w-[calc(100%_+_32px)]"
        loading={loading}
      />
    </div>
  );
};

export default ViewHeader;
