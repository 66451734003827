import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getOwnerPaymentStatusThunk } from "../../redux/slices/ownerSlice";
import { ALL_FEATURES } from "../../types/tiers";

export type FeaturesEnabledType = {
  [key in ALL_FEATURES]: boolean;
};

export const isFeatureEnabledHookHelper = (
  feature: string,
  paymentStatus: any
) => {
  let {
    enabledFeatures,
    isOnFreeTrial,
    isOnPaidTrial,
    isPayingCustomer,
    planEndDate,
  } = paymentStatus;

  let isPaidPlan =
    (isOnFreeTrial || isOnPaidTrial || isPayingCustomer) &&
    // isPlanActive &&
    enabledFeatures?.includes(feature);

  return isPaidPlan;
};

const useIsFeatureEnabled = () => {
  const dispatch = useAppDispatch();
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const [featuresEnabled, setFeaturesEnabled] = useState<FeaturesEnabledType>({
    "": false,
    API_ACCESS: false,
    BULK_DOWNLOAD: false,
    BULK_SEND: false,
    COLLABORATION: false,
    CUSTOM_PDF_NAME: false,
    DISABLE_THANK_YOU_PAGE_BRANDING: false,
    DNS_SETTING: false,
    EMAIL_BRANDING: false,
    FOLDER_ORG: false,
    FORM_ANALYTICS: false,
    FORM_BRANDING: false,
    GOOGLE_DRIVE_INTEGRATION: false,
    INDIVIDUAL_FILE_SHARING: false,
    MAKE_INTEGRATION: false,
    REDIRECTION_URL: false,
    SEND_A_COPY: false,
    VARIABLES: false,
    WATERMARK: false,
    WEBHOOKS: false,
    WORKSPACE: false,
    ZAPIER_INTEGRATION: false,
    CSV_EXPORT: false,
    CRM_INTEGRATIONS: false,
    REMINDERS_ACCESS: false,
    FORM_CUSTOM_URL: false,
    ACCESS_CODE: false,
    SLACK_INTEGRATION: false,
    E_TEMPLATES: false,
    SIGNER_ATTACHMENTS: false,
    CUSTOM_EMAIL_DOMAIN: false,
    ONLINE_NOTARY: false,
    SMS_AUTHENTICATION: false,
    SALESFORCE: false,
    MICROSOFT_TEAMS: false,
    FIFTEEN_MB_PDF_SIZE: false,
    FIFTY_MB_PDF_SIZE: false,
    PASSWORD_PROTECTED_PDF: false,
    IMPORT_FIELDS: false,
    STRIPE_INTEGRATION: false,
    PDF_REDIRECT_URL: false,
    PABBLY_INTEGRATION: false,
    CUSTOM_FIELDS: false,
    DYNAMIC_SUBJECT_AND_BODY: false,
    DROPBOX_INTEGRATION: false,
    EMBEDDING: false,
    APPROVAL_WORKFLOW: false,
    ONEDRIVE_INTEGRATION: false,
    ENCRYPTED_FILE_UPLOAD: false,
  });

  const checkFeatures = () => {
    Object.keys(featuresEnabled).map((feature: string) => {
      setFeaturesEnabled((prev) => ({
        ...prev,
        [feature]: isFeatureEnabledHookHelper(feature, owner?.paymentStatus),
      }));
    });
  };

  useEffect(() => {
    if (!owner?.paymentStatus) {
      dispatch(getOwnerPaymentStatusThunk());
    } else {
      checkFeatures();
    }
  }, [owner?.paymentStatus]);
  return featuresEnabled;
};

export default useIsFeatureEnabled;
