import { useEffect, useRef, useState } from "react";
import { RiMailSendFill } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import EmailInputWithRoles from "../../components/Ui/EmailInputWithRoles";
import MailDataForm from "../../components/Ui/MailDataForm";
import VariableInput from "../../components/Ui/VariableInput";
import { fetchOwner } from "../../utils";
import axios from "../../utils/axios";
import { GiConfirmed } from "react-icons/gi";
import PlanExhaustedNavBanner from "../../components/PlanExhaustedNavBanner";
import ReceivesASignedCopy from "../../components/Ui/ReceivesASignedCopy";
import { validateEmail } from "../../utils/validateEmail";
import { MdEmail, MdPeopleAlt, MdSend } from "react-icons/md";
import { IoArrowBackCircle, IoLinkOutline } from "react-icons/io5";
import SelfSignModal from "../../pdfme/ui/src/components/Designer/SelfSign/SelfSignModal";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  getOwnerThunk,
  increaseTrialEndDateByOneDay,
  increaseUsedQuantity,
  setOwnerRx,
} from "../../redux/slices/ownerSlice";
import ShareViaLink from "../../components/ShareViaLink";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import SigningInPerson from "../../components/SigningInPerson";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { IMAGE_LINKS } from "../../constants/common";
import {
  DocumentValueType,
  getDocumentValueInfo,
} from "../../pdfme/ui/src/components/Designer";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import CustomFieldsValues from "../../components/Ui/CustomFieldsValues";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import MailDataFormTemplates from "../../components/Ui/MailDataFormTemplates";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { ALL_FEATURES } from "../../types/tiers";
import Modal from "../../components/Modal";
import { FcCancel } from "react-icons/fc";
import TrialExtendedModal from "../../components/TrialExtendedModal";
import SMSBodyDataForm from "../../components/Ui/SMSBodyDataForm";
interface AddPDFTemplateSignersProps {}

const AddPDFTemplateSigners = ({}: AddPDFTemplateSignersProps) => {
  const [searchParams] = useSearchParams();
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const dispatch = useAppDispatch();
  const documentId = searchParams.get("documentId");
  const pipedriveDealId = searchParams.get("pipedriveDealId");
  const hubspotDealId = searchParams.get("hubspotDealId");
  let navigate = useNavigate();
  const workSpaceDetails = useWorkSpaceDetails();
  const featuresEnabled = useIsFeatureEnabled();
  const [loading, setLoading] = useState(false);
  const [resetAll, setResetAll] = useState(false);
  // const [owner, setOwner] = useState<any>({});
  const [receiversList, setReceiversList] = useState<{}[]>([]);
  const [mailData, setMailData] = useState<{ subject: string; body: string }>({
    subject: "",
    body: "",
  });

  const [smsBody, setSmsBody] = useState("");

  const [mailDataApprover, setMailDataApprover] = useState<{
    subject: string;
    body: string;
  }>({
    subject: "",
    body: "",
  });
  const [roles, setRoles] = useState<{ title: string; colour: string }[]>([]);

  const [showExhausted, setShowExhausted] = useState(false);
  const [isSigningOrderData, setIsSigningOrderData] = useState(false);

  const [sendViaSMS, setSendViaSMS] = useState(false);

  const [emailTags, setEmailTags] = useState([]);
  // these are varibales used in the schema
  const [customVariables, setCustomVariables] = useState<any>([]);
  const [contactVariables, setContactVariables] = useState<any>([]);
  const [isSelfSigning, setIsSelfSigning] = useState(false);
  const [showPaymentDisabledModal, setShowPaymentDisabledModal] =
    useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState<
    (DocumentValueType & { currAmount: number }) | null
  >(null);
  const [cbData, setCbData] = useState<any>(null);
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);

  const currentPlan = owner?.planhistory?.[owner?.planhistory?.length - 1];

  // let { usedQuanity, usageQuantityAllowed } = currentPlan;

  const [showSendingOptions, setShowSendingOptions] = useState<boolean>(false);
  const [showShareViaLink, setShowShareViaLink] = useState<boolean>(false);
  const dropdownShareOptionsRef = useRef<any>(null);
  const [generatedLinks, setGeneratedLinks] = useState<any>([]);
  const [savedMailData, setSavedMailData] = useState<any>([]);
  const [showSigningInPerson, setShowSigningInPerson] =
    useState<boolean>(false);
  const [schemas, setSchemas] = useState<any>([]);

  const [showSMSExhaustedModal, setShowSMSExhaustedModal] =
    useState<boolean>(false);
  const [SMSExhaustedModalData, setSMSExhaustedModalData] = useState({
    title: "",
    desc: "",
  });

  const [approvalWorkflowEnabled, setApprovalWorkflowEnabled] =
    useState<boolean>(false);
  const [approvalWorkflowSettedUp, setApprovalWorkflowSettedUp] =
    useState<boolean>(false);
  const [hubspotDealInfo, setHubspotDealInfo] = useState<string | null>(
    hubspotDealId
  );
  function checkStripeIntegration(): {
    isPayment: boolean;
    stripeConnectedAndActive: boolean;
  } {
    let stripeConnectedAndActive =
      workSpaceDetails?.stripeData?.isStripeAccountActive &&
      workSpaceDetails?.stripeData?.isStripeConnectedAccount;

    let isPayment: boolean = !!(paymentInfo && paymentInfo.currAmount > 0);
    // console.log({ paymentInfo });

    return { isPayment, stripeConnectedAndActive };
  }

  const [showTrialExtendedModal, setShowTrialExtendedModal] = useState({
    show: false,
    days: 0,
    redirectOnClose: "",
  });

  useEffect(() => {
    dispatch(getOwnerThunk());
  }, []);

  // useEffect(() => {
  //   let updatedOwner = async () => {
  //     const owner = await fetchOwner(
  //       // @ts-ignore
  //       JSON.parse(localStorage.getItem("owner"))?._id
  //     );
  //     setOwner(owner);
  //   };
  //   updatedOwner();
  // }, []);

  useEffect(() => {
    let getDocumentDetails = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `/getOneDocument?documentId=${documentId}`
        );
        if (!data.error) {
          // toast.success(data.message);
        }

        setDocumentData(data?.document);
        if (data?.document?.settings?.documentValue) {
          setPaymentInfo(getDocumentValueInfo(data?.document));
        }
        if (
          data?.document?.settings?.approvalWorkflow?.enabled &&
          data?.document?.settings?.approvalWorkflow?.approvers?.length > 0
        ) {
          setApprovalWorkflowEnabled(true);
        }
        if (data?.document?.settings?.approvalWorkflow?.approvers?.length > 0) {
          setApprovalWorkflowSettedUp(true);
        }
        let schemas = data?.document?.pdfTemplate?.schemas || [];
        setSavedMailData(data?.document?.mailData);

        // keep tarck of all variables added
        let savedCustomVariables = data?.document?.variables || [];
        let variablesAdded: any = {};
        schemas.forEach((item: any) => {
          Object.values(item).forEach((value: any) => {
            // if subtype varibale and not in added object
            // we find the variable from saved variables
            // we check value add the value and name pair to customvaribles

            if (
              value?.subType === "variable" &&
              !variablesAdded?.[value?.variableName]
            ) {
              let foundVariable = savedCustomVariables?.find(
                (elm: any) => elm?.varName == value?.variableName
              );
              variablesAdded[value?.variableName] = value?.variableName;
              setCustomVariables((prev: any) => [...prev, foundVariable]);
            }
          });
        });
        setSchemas(schemas);

        setIsSigningOrderData(data?.document?.isSigningOrderData);
        let roles = data.document?.roles || [];
        setRoles(roles);
        let receiversArray = roles.map((role: any, index: any) => {
          return {
            email: "",
            name: "",
            message: "",
            roleTitle: role.title,
            roleColour: role.colour,
            signingOrderNo: role?.signingOrderNo || index + 1,
            isEnabled: true,
          };
        });
        if (receiversArray.length > 0) {
          const email = searchParams.get("email")!;
          const name = searchParams.get("name") || "";
          receiversArray[0].email = email;
          receiversArray[0].name = name;
        }

        setReceiversList(receiversArray);
        setLoading(false);
      } catch (errror) {
        setLoading(false);
        // toast.error("Error fetching form");
      }
    };
    getDocumentDetails();
  }, [documentId]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClickShareOptions);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClickShareOptions);
    };
  }, []);

  const handleOutsideClickShareOptions = (e: any) => {
    if (dropdownShareOptionsRef.current !== null) {
      if (dropdownShareOptionsRef.current.contains(e.target)) return;
    }
    setShowSendingOptions(false);
  };
  const axiosCallback = async ({ redirect = true, isLink = false }) => {
    try {
      let responseReceivers = emailTags;
      const ownerEmail = owner.email;
      let ownerId = owner._id;
      setLoading(true);

      //* get Ip address
      const response = getIpAddressLocal();
      const ipData = await response;
      let ip = ipData.ip;

      // remove the signer from the receiver list whose isEnabled is false also remove the isEnabled key from the object
      let filteredReceivers = receiversList.filter(
        (elm: any) => elm?.isEnabled !== false
      );
      filteredReceivers = filteredReceivers.map((elm: any) => {
        delete elm.isEnabled;
        return elm;
      });

      if (filteredReceivers.length === 0) {
        toast.error("Please add at least one signer");
        setLoading(false);
        return;
      }
      const { data } = await axios.post(`/add-respondent-pdfTemplate`, {
        documentId,
        receiversList: filteredReceivers,
        mailData,
        ownerEmail,
        ownerId,
        ip,
        responseReceivers,
        isSigningOrderData,
        customVariables, // adding custom Variables
        isLink,
        sentViaSMS: sendViaSMS,
        contactVariables, // adding custom contact fields
        hubspotDealInfo,
        smsBody,
      });

      if (data.error) {
        if (data.error === "You have exhausted your allowed quota") {
          setShowExhausted(true);
        }
        toast.error(data.error);
        setResetAll(true);
      } else {
        if (!isLink) {
          toast.success("Successfully sent");
          setResetAll(true);

          // update the 5th index (template send quota) in owner redux

          // increase the index and trial day by one using redux reducer
          dispatch(increaseTrialEndDateByOneDay());
          dispatch(increaseUsedQuantity({ index: 5, amount: 1 }));

          if (redirect) {
            let trialExtended = data?.trialExtended;
            if (trialExtended.extended) {
              setShowTrialExtendedModal({
                show: true,
                days: trialExtended.days,
                redirectOnClose: `/dashboard/templates/respondents?documentId=${documentId}`,
              });
              return;
            } else {
              setTimeout(() => {
                navigate(
                  `/dashboard/templates/respondents?documentId=${documentId}`
                );
              }, 2000);
            }
          } else {
            setLoading(false);
            let trialExtended = data?.trialExtended;
            if (trialExtended.extended) {
              setShowTrialExtendedModal({
                show: true,
                days: trialExtended.days,
                redirectOnClose: "",
              });
            }

            return data?.createdRespondents;
          }
        } else {
          toast.success("Successfully generated links");
          console.log(data);
          let trialExtended = data?.trialExtended;
          if (trialExtended.extended) {
            setShowTrialExtendedModal({
              show: true,
              days: trialExtended.days,
              redirectOnClose: "",
            });
          }

          setGeneratedLinks(data?.createdRespondents?.signers || []);
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      toast.error("Something went wrong");
      toast.error(error.message);
    }
  };

  const sendForApprovals = async () => {
    try {
      let responseReceivers = emailTags;
      const ownerEmail = owner.email;
      let ownerId = owner._id;
      setLoading(true);

      //* get Ip address
      const response = getIpAddressLocal();
      const ipData = await response;
      let ip = ipData.ip;

      // remove the signer from the receiver list whose isEnabled is false also remove the isEnabled key from the object
      let filteredReceivers = receiversList.filter(
        (elm: any) => elm?.isEnabled !== false
      );
      filteredReceivers = filteredReceivers.map((elm: any) => {
        delete elm.isEnabled;
        return elm;
      });

      if (filteredReceivers.length === 0) {
        toast.error("Please add at least one signer");
        setLoading(false);
        return;
      }

      const { data } = await axios.post(`/send-for-approvals`, {
        documentId,
        receiversList: filteredReceivers,
        mailData,
        ownerEmail,
        ownerId,
        ip,
        responseReceivers,
        isSigningOrderData,
        customVariables, // adding custom Variables
        mailDataApprover,
        contactVariables, // adding custom contact fields
        hubspotDealInfo,
        smsBody,
      });

      if (!data?.result) {
        if (data.error === "You have exhausted your allowed quota") {
          setShowExhausted(true);
        }
        toast.error(data.error);
        setResetAll(true);
      } else {
        toast.success("Successfully sent");

        setTimeout(() => {
          navigate(`/dashboard/templates/respondents?documentId=${documentId}`);
        }, 2000);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const redirectCB = () => {
    navigate(`/dashboard/templates/respondents?documentId=${documentId}`);
  };

  function validateReceiversList() {
    let invalidOrderNumber = false;
    if (isSigningOrderData) {
      receiversList?.map((elm: any) => {
        if (
          isNaN(parseInt(elm?.signingOrderNo)) ||
          parseInt(elm?.signingOrderNo) < 1 ||
          parseInt(elm?.signingOrderNo) > receiversList?.length
        ) {
          invalidOrderNumber = true;
        }
      });
    }
    if (invalidOrderNumber) {
      toast.error("Please provide appropriate Signing Order numbers");
      return false;
    }

    let hasError = false;

    receiversList.forEach((receiver: any) => {
      if (
        !sendViaSMS &&
        !validateEmail(receiver.email) &&
        receiver?.isEnabled
      ) {
        hasError = true;
        return; // Exit the current iteration
      }

      if (
        sendViaSMS &&
        receiver?.mobileNo?.trim() === "" &&
        receiver?.isEnabled
      ) {
        hasError = true;
        return; // Exit the current iteration
      }

      if (receiver.name.trim() === "" && receiver?.isEnabled) {
        hasError = true;
        return; // Exit the current iteration
      }
    });

    if (hasError) {
      toast.error("Please enter valid email/mobile no and name for each role.");
      return;
    }

    let isSendToEmailNotValid = false;
    emailTags.forEach((email: any) => {
      if (!validateEmail(email)) {
        toast.error("Please enter a valid email");
        isSendToEmailNotValid = true;
      }
    });

    if (isSendToEmailNotValid) return false;

    return true;
  }

  const sendToSigners = async (isLink: boolean, redirect?: boolean) => {
    let isValid = validateReceiversList();
    if (!isValid) {
      return;
    }
    // let invalidOrderNumber = false;
    // if (isSigningOrderData) {
    //   receiversList?.map((elm: any) => {
    //     if (
    //       isNaN(parseInt(elm?.signingOrderNo)) ||
    //       parseInt(elm?.signingOrderNo) < 1 ||
    //       parseInt(elm?.signingOrderNo) > receiversList?.length
    //     ) {
    //       invalidOrderNumber = true;
    //     }
    //   });
    // }
    // if (invalidOrderNumber) {
    //   toast.error("Please provide appropriate Signing Order numbers");
    //   return;
    // }

    // let hasError = false;

    // receiversList.forEach((receiver: any) => {
    //   if (!validateEmail(receiver.email) && receiver?.isEnabled) {
    //     hasError = true;
    //     return; // Exit the current iteration
    //   }
    //   if (receiver.name.trim() === "" && receiver?.isEnabled) {
    //     hasError = true;
    //     return; // Exit the current iteration
    //   }
    // });

    // if (hasError) {
    //   toast.error("Please enter valid email and name for each role.");
    //   return;
    // }

    // let isSendToEmailNotValid = false;
    // emailTags.forEach((email: any) => {
    //   if (!validateEmail(email)) {
    //     toast.error("Please enter a valid email");
    //     isSendToEmailNotValid = true;
    //   }
    // });

    // if (isSendToEmailNotValid) return;

    let isSelfASigner = false;
    // ideal should use for loop
    receiversList.map((email: any) => {
      console.log({ ownerEmail: owner?.email, recEmail: email?.email });
      if (
        email?.email == owner?.email &&
        !isSigningOrderData &&
        email?.isEnabled
      ) {
        isSelfASigner = true;
      }
    });
    // This is for not showing self sign modal in share via link
    if (isLink) {
      isSelfASigner = false;
    }

    if (approvalWorkflowEnabled) {
      await sendForApprovals();
      return;
    }
    // if (isSelfASigner) {
    //   return;
    // }

    // regradless self signer we send email
    // with redirect disabled
    let cbData;
    if (typeof redirect === "undefined") {
      // redirect is not defined
      cbData = await axiosCallback({
        redirect: !isSelfASigner,
        isLink,
      });
    } else {
      console.log(redirect);
      cbData = await axiosCallback({
        redirect,
        isLink,
      });
    }
    setCbData(cbData);
    if (typeof redirect === "undefined") {
      setIsSelfSigning(isSelfASigner);
    }
    if (redirect === false && isLink === false) {
      setShowSigningInPerson(true);
    }
    // try {
    //   let responseReceivers = emailTags;
    //   const ownerEmail = owner.email;
    //   let ownerId = owner._id;
    //   setLoading(true);

    //   const { data } = await axios.post(`/add-respondent-pdfTemplate`, {
    //     documentId,
    //     receiversList,
    //     mailData,
    //     ownerEmail,
    //     ownerId,
    //     ip,
    //     responseReceivers,
    //     isSigningOrderData,
    //     customVariables, // adding custom Variables
    //   });

    //   if (data.error) {
    //     if (data.error === "You have exhausted your allowed quota") {
    //       setShowExhausted(true);
    //     }
    //     toast.error(data.error);
    //     setResetAll(true);
    //   } else {
    //     toast.success("Successfully sent");
    //     setResetAll(true);
    //     setTimeout(() => {
    //       navigate(`/dashboard/templates/respondents?documentId=${documentId}`);
    //     }, 2000);
    //   }
    //   setLoading(false);
    // } catch (error: any) {
    //   setLoading(false);
    //   console.log(error);
    //   toast.error("Something went wrong");
    //   toast.error(error.message);
    // }
  };

  const getReceiversList = (
    receivers: {
      name: string;
      email?: string;
      message?: string;
      mobileNo?: string;
    }[]
  ) => {
    console.log("getReceiversList");
    setReceiversList(receivers);
  };
  const getMailDataObject = (mailObject: { subject: string; body: string }) => {
    if (mailObject.subject && mailObject.body) {
      setMailData(mailObject);
    }
  };
  const getMailDataObjectApprover = (mailObject: {
    subject: string;
    body: string;
  }) => {
    if (mailObject.subject && mailObject.body) {
      setMailData(mailObject);
    }
  };

  if (showExhausted) {
    return <PlanExhaustedNavBanner />;
  }

  return (
    <div className="flex flex-col justify-start px-2 md:px-4 mb-4">
      <Helmet>
        <title translate="no">Add Template Signers</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Add pdf-template signers." />
        <meta
          name="description"
          content="Simplify your workflow by effortlessly adding signers to PDF templates on BoloForms."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/create/pdf-template/send-for-signature`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Add pdf-template signers.`} />
        <meta
          property="og:description"
          content="Simplify your workflow by effortlessly adding signers to PDF templates on BoloForms."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/create/pdf-template/send-for-signature"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {isSelfSigning && (
        <SelfSignModal
          setShow={(value) => setIsSelfSigning(value)}
          // documentData={documentData}
          // cb={axiosCallback}
          instanceFrom="PDF-TEMPLATE"
          signLaterCB={redirectCB}
          cbData={cbData}
        />
      )}
      <EmailInputWithRoles
        onSave={getReceiversList}
        resetAll={false}
        isPdfTemplate={true}
        roles={roles}
        receiversList={receiversList}
        setReceiversList={setReceiversList}
        isSigningOrderData={isSigningOrderData}
        setIsSigningOrderData={setIsSigningOrderData}
        documentData={documentData}
        sendViaSMS={sendViaSMS}
        setSendViaSMS={setSendViaSMS}
        approvalWorkflowSettedUp={approvalWorkflowSettedUp}
        approvalWorkflowEnabled={approvalWorkflowEnabled}
        setApprovalWorkflowEnabled={setApprovalWorkflowEnabled}
      />

      <CustomFieldsValues
        receiversList={receiversList}
        schemas={schemas}
        contactVariables={contactVariables}
        setContactVariables={setContactVariables}
      />

      {customVariables.length > 0 && (
        <VariableInput
          variables={customVariables}
          setVariables={setCustomVariables}
          owner={owner}
          dealId={pipedriveDealId ? pipedriveDealId : ""}
          hubspotdealId={hubspotDealId ? hubspotDealId : ""}
          setHubspotDealInfo={setHubspotDealInfo}
        />
      )}

      {approvalWorkflowEnabled && (
        <>
          <p className="text-sm mb-2 font-medium">
            Approver's Mail Data{" "}
            <span className="text-gray-500 font-normal">(Optional)</span>
          </p>{" "}
          <MailDataForm
            onSave={getMailDataObjectApprover}
            setMailData={setMailDataApprover}
            isPdfTemplate={true}
            className=""
          />
        </>
      )}
      {approvalWorkflowEnabled && (
        <p className="text-sm mb-2 font-medium">Signer's Mail Data </p>
      )}

      {!sendViaSMS ? (
        <MailDataFormTemplates
          mailData={mailData}
          setMailData={setMailData}
          className=""
          contactVariables={contactVariables}
          variables={customVariables}
          receiversList={receiversList}
          savedMailData={savedMailData}
        />
      ) : (
        <SMSBodyDataForm
          setSmsBody={setSmsBody}
          smsBody={smsBody}
          isTemplate={true}
        />
      )}

      <ReceivesASignedCopy
        emailTags={emailTags}
        setEmailTags={setEmailTags}
        owner={owner}
        className=""
      />
      <PleaseUpgradeModal
        setShow={setShowPaymentDisabledModal}
        show={showPaymentDisabledModal}
        blockingType={"STRIPE_INTEGRATION"}
        data={{
          closeText: "Procced without payments",
          closeCb: () => {
            setShowPaymentDisabledModal(false);
            setShowSendingOptions(true);
          },
        }}
      />
      <div className="w-[100%] md:w-[65%] max-w-[800px] relative mb-4 gap-4  my-4 flex flex-row items-end pb-10">
        <BoloButton
          variant={"tertiary"}
          orientation={"center"}
          size="sm"
          className="w-full gap-3"
          // className="text-primary w-full bg-secondary px-4 md:px-6 py-2 rounded-md text-xs md:text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
          onClick={() => navigate(-1)}
        >
          <IoArrowBackCircle className="inline-block text-2xl" />
          <span className=""> Cancel</span>{" "}
        </BoloButton>
        <BoloButton
          orientation={"center"}
          size="sm"
          className="w-full gap-3"
          onClick={() => {
            let status = checkStripeIntegration();
            console.log({ status, s: featuresEnabled.STRIPE_INTEGRATION });
            if (
              !featuresEnabled.STRIPE_INTEGRATION &&
              status.isPayment &&
              status.stripeConnectedAndActive
            ) {
              setShowPaymentDisabledModal(true);
              return;
            }
            setShowSendingOptions((prev) => !prev);
          }}
          // onClick={sendToSigners}
          // className="bg-primary w-full text-white px-4 md:px-6 py-2 rounded-md text-xs md:text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
        >
          <MdSend size={18} className="w-6 h-6 cursor-pointer" />
          <p className="">{loading ? "Sending" : "Send"}</p>

          {/* <MdEmail className="w-6 h-6 cursor-pointer" />
          <p className="">{loading ? "Sending" : "Send Email"}</p> */}
        </BoloButton>
        {showSendingOptions && (
          <div
            ref={dropdownShareOptionsRef}
            className={`absolute bottom-[90px] right-0 mt-2 border border-gray-300 rounded shadow-lg w-[250px] cursor-pointer bg-white`}
            style={{ zIndex: 100 }}
          >
            <div className="flex flex-col items-start justify-start ">
              <button
                disabled={sendViaSMS && approvalWorkflowEnabled}
                onClick={() => {
                  if (sendViaSMS && approvalWorkflowEnabled) {
                    return;
                  }

                  // check when sent via sms is there enough credits
                  let creditsRequired = receiversList.filter(
                    (elm: any) => elm?.isEnabled
                  ).length;

                  // const availableCredits =
                  //   (currentPlan?.usageQuantityAllowed?.[8] || 0) -
                  //   (currentPlan?.usedQuanity?.[8] || 0);
                  const availableCredits = currentPlan?.smsCredits?.count || 0;

                  console.log({ creditsRequired, availableCredits });

                  if (creditsRequired > availableCredits && sendViaSMS) {
                    setSMSExhaustedModalData({
                      title: "Insufficient SMS Credits",
                      desc: `You need ${creditsRequired} SMS credits to send this document. You have ${availableCredits} SMS credits left.`,
                    });
                    setShowSMSExhaustedModal(true);
                    return;
                  }

                  setShowSendingOptions(false);
                  sendToSigners(false);
                  new MixPanel().track("PDF template ", {
                    pdf_template_action: "pdf_template_send_document",
                    isSigningOrderData: isSigningOrderData,
                    roles: roles?.length,
                    sendACopy: emailTags?.length,
                    method: "pdf_template_sent_via_email",
                    paid: true,
                  });
                  new MixPanel().increment("pdf_template_send_document");
                }}
                className={`flex flex-row items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium hover:bg-primary/20`}
              >
                <MdEmail className="text-lg  hover:opacity-85 mr-2 w-fit" />
                <span className="w-fit whitespace-nowrap">
                  {sendViaSMS ? `Send via SMS ` : "Send via Email"}
                </span>
                {sendViaSMS && (
                  <span className="whitespace-nowrap text-red-500 text-[10px]">
                    (-
                    {
                      receiversList.filter((elm: any) => elm?.isEnabled).length
                    }{" "}
                    SMS credits)
                  </span>
                )}
              </button>
              <button
                // Add functionality for sharing via links
                onClick={() => {
                  let isValid = validateReceiversList();
                  if (!isValid) {
                    return;
                  }
                  setShowSendingOptions(false);
                  setShowShareViaLink(true);
                  new MixPanel().track("PDF template ", {
                    pdf_template_action: "pdf_template_send_document",
                    isSigningOrderData: isSigningOrderData,
                    roles: roles?.length,
                    sendACopy: emailTags?.length,
                    method: "pdf_template_sent_via_link",
                    paid: true,
                  });
                  new MixPanel().increment("pdf_template_send_document");
                }}
                disabled={isSigningOrderData || approvalWorkflowEnabled}
                className={`flex flex-row items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium hover:bg-primary/20`}
                data-tooltip-id={"share-via-link"}
              >
                <IoLinkOutline className="text-lg hover:opacity-85 mr-2" />
                Share via Link
              </button>
              <button
                // Add functionality for sharing via links
                onClick={async () => {
                  setShowSendingOptions(false);
                  await sendToSigners(false, false);
                  new MixPanel().track("PDF template ", {
                    pdf_template_action: "pdf_template_send_document",
                    isSigningOrderData: isSigningOrderData,
                    roles: roles?.length,
                    sendACopy: emailTags?.length,
                    method: "pdf_template_sent_via_person",
                    paid: true,
                  });
                }}
                className={`flex flex-row items-center text-center text-xs capitalize p-3  gap-2 w-full font-medium hover:bg-primary/20`}
                disabled={approvalWorkflowEnabled}
                data-tooltip-id={"approval-workflow"}
              >
                <MdPeopleAlt className="text-lg hover:opacity-85 mr-2" />
                Sign in Person
              </button>
              {isSigningOrderData && (
                <CustomTooltip
                  helpers={{
                    id: "share-via-link",
                    content: approvalWorkflowEnabled
                      ? "When the approval workflow is enabled, the only available sending option is 'via Email.'"
                      : "Remove sigining order data",
                    place: "left",
                  }}
                />
              )}
              {approvalWorkflowEnabled && (
                <CustomTooltip
                  helpers={{
                    id: "approval-workflow",
                    content:
                      "When the approval workflow is enabled, the only available sending option is 'via Email.'",
                    place: "left",
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {showShareViaLink && (
        <ShareViaLink
          setShowShareViaLink={setShowShareViaLink}
          documentId={documentId || ""}
          sendToSigners={sendToSigners}
          generatedLinks={generatedLinks}
          receiversList={receiversList}
          type="PDF-TEMPLATE"
          sending={true}
        />
      )}
      {showSigningInPerson && (
        <SigningInPerson
          documentId={documentId || ""}
          setShowSigningInPerson={setShowSigningInPerson}
          isSigningOrderData={isSigningOrderData}
          type={"PDF-TEMPLATE"}
          generatedLink={cbData?.signers ?? []}
          sending={true}
        />
      )}

      {showTrialExtendedModal.show && (
        <TrialExtendedModal
          setShow={() => {
            setShowTrialExtendedModal({
              show: false,
              days: 0,
              redirectOnClose: "",
            });

            if (showTrialExtendedModal.redirectOnClose) {
              setTimeout(() => {
                navigate(showTrialExtendedModal.redirectOnClose);
              }, 1500);
            }
          }}
          show={showTrialExtendedModal.show}
          completedTask={{
            title: "Send a PDF Template for signing",
            description: "Send a PDF Template for signing to your user",
            increasedDays: showTrialExtendedModal.days,
          }}
        />
      )}

      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
      {showSMSExhaustedModal && (
        <Modal
          modalContent={
            <div className="flex flex-col items-center justify-center gap-4 p-4">
              <FcCancel className="text-4xl" />
              <h2 className="text-lg font-semibold text-center">
                {SMSExhaustedModalData.title}
              </h2>
              <p className="text-sm text-center">
                {SMSExhaustedModalData.desc}
              </p>
              <BoloButton
                onClick={() => {
                  setShowSMSExhaustedModal(false);
                  navigate("/billings");
                }}
                variant="primary"
                orientation="center"
                size="sm"
              >
                Buy More SMS Credits
              </BoloButton>
            </div>
          }
        />
      )}
    </div>
  );
};

export default AddPDFTemplateSigners;
