function setIntercom() {
  let user = JSON.parse(localStorage.getItem("owner"));
  let planhistory = user?.planhistory[user?.planhistory.length - 1];
  // console.log(user)

  if (!user) return;
  console.log("setting up intercom for ...", user.email);
  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "ijjql330",
    name: user?.name, // Full name
    email: user?.email, // Email address
    user_id: user?._id, // current_user_i d
    signature_plan_tier: planhistory?.tier,
    signature_plan_is_active: Boolean(planhistory?.isActive),
    signature_plan_price: Number(planhistory?.planPrice ?? 0),
    created_at: new Date(), // Signup date as a Unix timestamp
    product: "BoloForms Signature",
    signature_plan: planhistory.planName,
    signature_from: user?.from,
  });

}

export { setIntercom };
