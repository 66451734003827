import React, { useEffect, useState } from "react";
import BoloRadioButtonMui from "../Common/RadioButton/BoloRadioButtonMui";
import { useAppSelector } from "../../redux/store";
import BoloModal from "../Common/Modal/BoloModal";
import { Slide, toast, ToastContainer } from "react-toastify";
import axios from "../../utils/axios";
import {
  microsoftAuthRedirectUrl,
  microsoftClientID,
  microsoftScopes,
} from "../../constants/Integrations/Microsoft";
import OnedriveModalFolderSelect from "../Ui/OndriveModalFolderSelect";

interface OnedriveModalUploadTypes {
  setShowOneDriveModal: React.Dispatch<React.SetStateAction<boolean>>;
  onedriveModalDoc: any;
  setOnedriveModalDoc: React.Dispatch<React.SetStateAction<any>>;
}
const OnedriveModalUpload = ({
  setShowOneDriveModal,
  onedriveModalDoc,
  setOnedriveModalDoc,
}: OnedriveModalUploadTypes) => {
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const [showOnedriveFolderSelect, setShowOnedriveFolderSelect] =
    useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<any>();
  const [documentId, setDocumentId] = useState(onedriveModalDoc?.documentId);
  const isIntegrated =
    owner?.integrationTokens?.microsoft?.access_token &&
    owner?.integrationTokens?.microsoft?.access_token !== "";
  const handleSubmit = async (folder: any, enabled = true) => {
    try {
      const { data } = await axios.post("/microsoft/setFolderForDoc", {
        documentId: documentId,
        folder,
        enabled,
      });
      if (data?.success) {
        toast.success("Microsoft info updated!");
        console.log(data?.onedriveFields);
        setOnedriveModalDoc(data?.onedriveFields);
      } else {
        toast.error(data?.message);
      }
      setShowOnedriveFolderSelect(false);
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  console.log("Selected folder", selectedFolder);

  return (
    <div>
      <BoloModal
        setShow={setShowOneDriveModal}
        content={
          <>
            <h2 className="mb-1 text-lg md:text-xl font-semibold">
              OneDrive Integration
            </h2>
            <p className="text-xs text-gray-600 pb-2 mb-6 border-b-2 border-gray-200">
              Select drive folder to save the response pdf files to your
              Onedrive.
            </p>

            {!isIntegrated && (
              <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
                <h2 className="mb-1 text-xs md:text-sm">
                  You are not connected to OneDrive.
                </h2>
                <p className="text-xs text-gray-500 mb-4">
                  Connect your OneDrive to save the finished PDF to your
                  specified OneDrive folder.
                </p>
                <button
                  className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
                  onClick={() => {
                    window.open(
                      `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${microsoftClientID}&scope=${microsoftScopes}&response_type=code&redirect_uri=${microsoftAuthRedirectUrl}&state=${owner?.email};${window.location.pathname}`,
                      "_self"
                    );
                  }}
                >
                  Connect to OneDrive
                </button>
              </div>
            )}
            {isIntegrated && (
              <div className="flex justify-between">
                {!onedriveModalDoc?.parentFolderId ? (
                  <div>
                    <h2 className="mb-1 text-xs md:text-smfont-medium text-gray-700">
                      Your document is not connected to any OneDrive Folder
                    </h2>
                    <p className="text-xs text-gray-500 mb-4">
                      Pick a folder to save your form responses as PDFs.
                    </p>
                    <button
                      className="w-[50%] md:w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                      onClick={async () => {
                        setShowOnedriveFolderSelect(true);
                      }}
                      disabled={showOnedriveFolderSelect}
                    >
                      Select Folder
                    </button>
                  </div>
                ) : (
                  <div>
                    <h2 className="mb-1 text-xs md:text-sm font-medium text-gray-700">
                      Your document is connected to OneDrive.
                    </h2>
                    <p className="text-xs text-gray-500 mb-4">
                      Your document responses will be saved to this Drive
                      Folder:{" "}
                      <span
                        className="text-primary font-semibold cursor-pointer"
                        onClick={() => {
                          window.open(
                            onedriveModalDoc?.parentFolderUrl,
                            "_blank"
                          );
                        }}
                      >
                        {onedriveModalDoc?.parentFolderName}
                      </span>
                    </p>
                    <div className="w-full flex justify-between gap-4">
                      <button
                        className="w-full border-2 border-primary bg-white inset-2 text-primary px-4 py-2 rounded-md text-xs mr-1"
                        onClick={async (e) => {
                          e.stopPropagation();
                          setShowOnedriveFolderSelect(true);
                        }}
                      >
                        Update Folder
                      </button>
                      <button
                        className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            onedriveModalDoc?.parentFolderUrl,
                            "_blank"
                          );
                        }}
                      >
                        View Folder
                      </button>
                    </div>
                  </div>
                )}
                <BoloRadioButtonMui
                  variant="bolo-primary"
                  checked={onedriveModalDoc?.enabled}
                  onChange={async (e) => {
                    setOnedriveModalDoc((prev: any) => ({
                      ...prev,
                      enabled: e.target.checked,
                    }));
                    await handleSubmit(
                      {
                        id: onedriveModalDoc?.parentFolderId,
                        name: onedriveModalDoc?.parentFolderName,
                        driveId: onedriveModalDoc?.parentFolderDriveId,
                        webUrl: onedriveModalDoc?.parentFolderUrl,
                      },
                      e.target.checked
                    );
                  }}
                />
              </div>
            )}
          </>
        }
      />
      {showOnedriveFolderSelect && (
        <OnedriveModalFolderSelect
          setShowOnedriveModal={setShowOnedriveFolderSelect}
          ownerEmail={owner?.email}
          setSelectedFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
          handleSubmit={handleSubmit}
        />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default OnedriveModalUpload;
