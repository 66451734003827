//@ts-nocheck
import React, { useEffect, useState } from "react";
import axios from "../../utils/axios";
import {
  MdChevronLeft,
  MdClose,
  MdFolder,
  MdOutlineChevronRight,
} from "react-icons/md";
import { toast } from "react-toastify";
import NoFolders from "../NoFolders";
import CreateFolderModal from "../CreateFolderModal";

const MoveItemModal = ({
  item,
  fromFolder,
  setShow,
  stateUpdate,
  isAdd,
  setReload,
}) => {
  const owner = JSON.parse(localStorage.getItem("owner") || "{}");
  const [folders, setFolders] = useState([]);
  const [prevFolder, setPrevFolder] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const [udpateDone, setUpdateDone] = useState(false);

  const getTopLevelFolder = async () => {
    try {
      const { data } = await axios.post("/folder/get-by-parent", {
        ownerId: owner?._id,
        parentFolder: selectedFolder?._id || null,
      });
      if (data) {
        const { folders } = data;
        setFolders(folders || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = async (toFolder) => {
    try {
      const { data } = await axios.post("/folder/add-item", {
        type: item?.type,
        itemId: item?.id,
        folderId: toFolder?.folderId,
      });
      if (data) {
        const { success, alreadyExits } = data;
        console.log(data);
        success && toast.success("Added to Folder");
        alreadyExits &&
          toast.success(data?.message || "Already Exists in a Folder");
        stateUpdate && stateUpdate((prev) => !prev);
        setReload && setReload((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
      toast.error("Could not Add item");
    }
    setShow && setShow(false);
  };

  const handleMove = async (toFolder) => {
    try {
      const { data } = await axios.post("/folder/move-item", {
        type: item?.type,
        itemId: item?.id,
        fromFolder: fromFolder?._id,
        toFolder: toFolder?._id,
      });
      if (data) {
        const { toFolder, fromFolder } = data;
        console.log(data);
        stateUpdate && stateUpdate((prev) => !prev);
        setReload && setReload((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
      toast.error("Could not move item");
    }
    setShow && setShow(false);
  };

  useEffect(() => {
    getTopLevelFolder();
  }, [selectedFolder, udpateDone]);

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        {createFolderModal && (
          <CreateFolderModal
            setShow={setCreateFolderModal}
            parentFolder={selectedFolder?._id}
            stateUpdate={setUpdateDone}
            isShared={false}
            sharedOwnerId={""}
          />
        )}
        <div className="relative flex flex-col p-2 md:p-0">
          <MdClose
            className="absolute top-0 right-0 text-xl md:text-2xl cursor-pointer"
            onClick={() => {
              setShow(false);
            }}
          />
          <span className="text-lg md:text-xl font-semibold">
            {isAdd ? "Add" : "Move"} "
            {item?.type == "FORM" && item?.value?.formJson?.title}
            {item?.type == "PDF" && item?.value?.documentName}
            {item?.type == "PDF-TEMPLATE" && item?.value?.documentName}
            {item?.type == "FOLDER" && item?.value?.name}"
          </span>
          {!isAdd && (
            <span className="mt-2 text-xs md:text-sm">
              Current Location : {fromFolder?.name || "Root"}
            </span>
          )}
          <div className="flex flex-col gap-1 text-xs md:text-sm mt-4">
            {prevFolder?.length !== 0 && (
              <span
                className="flex items-center gap-2 cursor-pointer mb-2"
                onClick={() => {
                  setSelectedFolder(prevFolder[prevFolder.length - 1]);
                  const removeLast = [...prevFolder.slice(0, -1)];
                  setPrevFolder(removeLast);
                }}
              >
                <MdChevronLeft className="text-2xl text-[#3c4043]" />
                {prevFolder[prevFolder?.length - 1]?.name || "Root"}
              </span>
            )}
            <div
              className="h-[350px] max-h-[400px] overflow-y-auto"
              id="mini-pdf-custom-scroll"
            >
              {folders?.length === 0 && (
                <div className="w-full m-auto">
                  <NoFolders
                    helpers={{
                      onClickHandler: () => {
                        setCreateFolderModal(true);
                      },
                    }}
                  />
                </div>
              )}
              {folders?.map((folder) => {
                const isCurrentFolder = folder?._id == fromFolder?._id;
                const isSelfFolder = folder?._id == item?.value?._id;
                return (
                  <div
                    className={`flex justify-between hover:bg-slate-100 py-1.5 px-1 md:px-3 rounded ${
                      // isCurrentFolder || isSelfFolder
                      // ? "opacity-70 cursor-not-allowed" :
                      "cursor-pointer opacity-100"
                    }`}
                  >
                    <div className="flex gap-2 items-center">
                      <MdFolder
                        className="text-2xl"
                        style={{ color: folder?.color || "#3c4043" }}
                      />
                      {folder?.name}
                      {isCurrentFolder && (
                        <span className="text-slate-500"> Current Folder</span>
                      )}
                      {isSelfFolder && (
                        <span className="text-slate-500">Selected Folder</span>
                      )}
                    </div>
                    <div className="flex gap-4 items-center">
                      <button
                        disabled={isCurrentFolder || isSelfFolder}
                        className="text-primary w-full bg-secondary px-4 py-1 rounded-md flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70 disabled:cursor-not-allowed"
                        onClick={() => {
                          isAdd ? handleAdd(folder) : handleMove(folder);
                        }}
                      >
                        {isAdd ? "Add" : "Move"}
                      </button>
                      <button
                        className={`${
                          // isCurrentFolder ||
                          isSelfFolder && item?.type == "FOLDER"
                            ? ""
                            : "hover:scale-150"
                        } cursor-pointer disabled:cursor-not-allowed`}
                        // disabled={isCurrentFolder || isSelfFolder}
                        disabled={isSelfFolder && item?.type == "FOLDER"}
                        onClick={() => {
                          setPrevFolder((prev) => [...prev, selectedFolder]);
                          setSelectedFolder(folder);
                        }}
                      >
                        <MdOutlineChevronRight className="text-2xl text-[#3c4043]" />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {item?.type == "FOLDER" && (
            <div className="flex justify-end mt-3">
              <button
                className="text-xs md:text-sm text-primary w-full bg-secondary px-4 py-1 rounded-md flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
                onClick={() => {
                  handleMove(null);
                }}
              >
                Move to Root Folder
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MoveItemModal;
