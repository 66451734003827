// @ts-nocheck

import React from "react";
import { MdClose } from "react-icons/md";

const Modal = ({ modalContent, isWidthFit = true }) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`${
          isWidthFit
            ? "w-[95%] md:w-fit"
            : "min-h-fit max-h-[650px] w-[95%] md:w-[700px]"
        }  relative bg-white rounded-sm md:rounded px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        {modalContent}
      </div>
    </div>
  );
};

export default Modal;
