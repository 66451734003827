import { useContext, useEffect, useState } from "react";
// import { SchemaForUI, Size } from '@pdfme/common';
import { getFallbackFontName } from "@pdfme/common";
import {
  HiArrowLeft as ArrowLeftIcon,
  HiArrowRight as ArrowRightIcon,
} from "react-icons/hi";
import {
  MdOutlineKeyboardBackspace,
  MdPlaylistAdd,
  MdPlaylistAddCheck,
  MdSend,
} from "react-icons/md";
import { SchemaForUI, Size, Template } from "../../../../../common/src";
import { SIDEBAR_WIDTH } from "../../../constants";
import { FontContext, I18nContext } from "../../../contexts";
import Divider from "../../Divider";
import DetailView from "./DetailView/index";
import ListView from "./ListView/index";
import { DropdownOptionSchema } from "../../../../../common/src/type";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaSave } from "react-icons/fa";
import { AddSchemaType } from "..";
export type SidebarProps = {
  height: number;
  pageNum: number;
  pageCursor: number;
  hoveringSchemaId: string | null;
  onChangeHoveringSchemaId: (id: string | null) => void;
  size: Size;
  pageSize: Size;
  activeElements: HTMLElement[];
  schemas: SchemaForUI[];
  onSortEnd: (sortedSchemas: SchemaForUI[]) => void;
  onEdit: (id: string) => void;
  onEditEnd: () => void;
  changeSchemas: (
    objs: {
      key: string;
      value:
        | undefined
        | string
        | number
        | {
            min: number;
            max: number;
          }
        | boolean
        | DropdownOptionSchema[];
      schemaId: string;
    }[]
  ) => void;
  addSchema: AddSchemaType;
  formQuestions?: Array<{ type: string; title: string; required: boolean }>;
  receiversList?: Array<{
    name: string;
    email: string;
    message?: string | undefined;
    permission?: string | undefined;
    sentUrl?: string | undefined;
  }>;
  schemasList: SchemaForUI[][];
  setSchemasList: (schemasList: SchemaForUI[][]) => void;
  onSaveTemplateHandler: any;
  template?: Template;
  isOnlyPdfTemplate?: boolean;
  // sendToSigners?: (redirect?: boolean) => Promise<any>;
};

const Sidebar = (props: SidebarProps) => {
  const {
    height,
    size,
    activeElements,
    schemas,
    addSchema,
    formQuestions,
    receiversList,
    schemasList,
    onSaveTemplateHandler,
    template,
    onEditEnd,
    onEdit,
    isOnlyPdfTemplate,
    // sendToSigners,
  } = props;
  const isReceiversListValid =
    Array.isArray(receiversList) && receiversList.length > 0;
  // let navigate = useNavigate();

  const i18n = useContext(I18nContext);
  const fonts = useContext(FontContext);
  const fallbackFont = getFallbackFontName(fonts);
  const [open, setOpen] = useState(true);
  const [questionsAnomaly, setQuestionsAnomaly] = useState<any>([]);
  const [showAttentionDetails, setShowAttentionDetails] = useState(false);

  const [showFormQuestionDropdown, setShowFormQuestionDropdown] =
    useState(true);
  const [selectedFormQuestion, setSelectedFormQuestion] = useState(
    !isReceiversListValid
      ? formQuestions
        ? formQuestions[0]
        : null
      : receiversList
      ? receiversList[0]
      : null
  );
  const [selectedQuestionIndex, setSelectedQuestionIndex] =
    useState<number>(-1);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let sampleData = template?.sampledata;
    let availableQuestions = formQuestions || [];
    const isReceiversListValid =
      Array.isArray(receiversList) && receiversList.length > 0;
    if (isReceiversListValid) {
      return;
    }
    // console.log("anomaly checking useEffect running");
    if (
      Array.isArray(sampleData) &&
      sampleData.length > 0 &&
      Array.isArray(availableQuestions)
    ) {
      // Check if any question from sampleData is not available in formQuestions
      const missingQuestionsArray = sampleData.reduce((acc, dataObject) => {
        const missingKeys: any = Object.keys(dataObject).filter((key) => {
          return !availableQuestions.some(
            (question) =>
              question.title.replace(/\n$/, "") === key.split("___")[0]
          );
        });
        return acc.concat(missingKeys);
      }, []);

      if (missingQuestionsArray.length > 0) {
        // console.log(
        //   "Some questions from sampleData are not available in formQuestions."
        // );
        // setIsQuestionsAnomaly(true);
        // Set the state with missing questions
        setQuestionsAnomaly(missingQuestionsArray);
      } else {
        // console.log("No questions are missing.");
        // setIsQuestionsAnomaly(false);
        // Clear the state if there are no missing questions
        setQuestionsAnomaly([]);
      }
    }
  }, [formQuestions, template]);

  const getActiveSchemas = () => {
    const ids = activeElements.map((ae) => ae.id);
    const activeSchema = schemas.find((s) => ids.includes(s.id));

    if (activeSchema?.type === "text") {
      if (!activeSchema.fontName) {
        activeSchema.fontName = fallbackFont;
      }
    }

    return schemas.filter((s) => ids.includes(s.id));
  };

  const getLastActiveSchema = () => {
    const activeSchemas = getActiveSchemas();
    return activeSchemas[activeSchemas.length - 1];
  };

  const FormQuestionDropdown = ({
    formQuestions,
    receiversList,
    onSelect,
    schemas,
    selectedFormQuestion,
  }: any) => {
    // Step 1: Flatten the array of arrays into a single array
    // const flattenedSchemas = schemas;

    // Step 2: Create a Set of unique IDs from the flattened array
    // const usedQuestionIds = new Set(
    //   flattenedSchemas.map((schema: any) => schema.id)
    // );

    const isReceiversListValid =
      Array.isArray(receiversList) && receiversList.length > 0;
    return (
      <div className="py-3">
        <h3 className="text-xs capitalize font-medium mb-1.5">
          {!isReceiversListValid
            ? "Pick questions from your form"
            : "Select a Signer"}
        </h3>
        <div
          className={`w-full pr-4 cursor-pointer border text-sm text-gray-800 rounded-md overflow-hidden`}
        >
          <select
            className="w-full px-4 py-2 outline-none"
            // onClick={(e: any) => {
            //   // Store the current value before it changes
            //   e.target.dataset.prevValue = e.target.value;
            // }}
            onChange={(e: any) => {
              const selectedValue = e.target.value;
              let selectedQuestion = "";
              if (!isReceiversListValid) {
                selectedQuestion = formQuestions.find(
                  (question: any) => String(question.id) === selectedValue
                );
                console.log("Selected Question", selectedValue);
              } else if (isReceiversListValid && receiversList.length > 0) {
                selectedQuestion = receiversList.find(
                  (receiver: any) => receiver.email === selectedValue
                );
              }

              if (selectedQuestion) {
                onSelect(selectedQuestion);
              }
            }}
            // value={isReceiversListValid ? selectedFormQuestion?.email : selectedFormQuestion?.id}
          >
            <option className="disabled:opcaity-10" value="" disabled selected>
              Select
            </option>
            {!isReceiversListValid && formQuestions && formQuestions.length > 0
              ? formQuestions.map((question: any, index: number) => (
                  <option
                    key={index}
                    value={question.id}
                    // Disable the option if the question ID is already used
                    // disabled={usedQuestionIds.has(question.id)}
                    disabled={
                      (question.type === "FileUpload" &&
                        question.fileType !== "image") ||
                      question.type === "GridCheckbox" ||
                      question.type === "GridRadioGroup" ||
                      question.multiple
                    }
                  >
                    {`${question.title} ${
                      question.type === "FileUpload" &&
                      question.fileType !== "image"
                        ? "Only image can be mapped"
                        : question.multiple && question.fileType === "image"
                        ? "Cannot map multiple files"
                        : ""
                    }${
                      question.type === "GridCheckbox" ||
                      question.type === "GridRadioGroup"
                        ? "(Grid questions cannot be mapped)"
                        : ""
                    }`}
                  </option>
                ))
              : receiversList &&
                receiversList.length > 0 &&
                receiversList.map((receiver: any, index: number) => (
                  <option key={index} value={receiver.email}>
                    {receiver.name}
                  </option>
                ))}
          </select>
        </div>
      </div>
    );
  };

  const onFormQuestionSelect = (selectedQuestion: {
    title: string;
    type: string;
    required: boolean;
    id?: string;
    _id?: string;
  }) => {
    // setSelectedFormQuestion(selectedQuestion);
    // setShowFormQuestionDropdown(false);
    // addSchema(selectedQuestion);
    console.log("DATA", selectedQuestion);
    if (!selectedQuestion) {
      return;
    }
    const isReceiversListValid =
      Array.isArray(receiversList) && receiversList.length > 0;
    if (!isReceiversListValid && formQuestions && selectedQuestion) {
      // @ts-ignore
      addSchema(selectedQuestion, true);
      setSelectedFormQuestion(null);
    } else if (isReceiversListValid && receiversList && selectedQuestion) {
      // @ts-ignore
      addSchema(selectedQuestion, false);
      setSelectedFormQuestion(null);
    }
  };

  const addSchemaHandler = () => {
    if (!selectedFormQuestion) {
      return;
    }
    const isReceiversListValid =
      Array.isArray(receiversList) && receiversList.length > 0;
    if (!isReceiversListValid && formQuestions && selectedFormQuestion) {
      // @ts-ignore
      addSchema(selectedFormQuestion, true);
      setSelectedFormQuestion(null);
    } else if (isReceiversListValid && receiversList && selectedFormQuestion) {
      // @ts-ignore
      addSchema(selectedFormQuestion, false);
      setSelectedFormQuestion(null);
    }
  };

  const saveTemplate = async () => {
    onSaveTemplateHandler();
  };

  // const sendToSigner = async () => {
  //   if (!sendToSigners) {
  //     return;
  //   } else {
  //     setLoading(true);
  //     await sendToSigners();
  //     setLoading(false);
  //   }
  // };

  return (
    <div
      style={{
        width: "300px",
        minWidth: "300px",
        maxWidth: "300px",
        height: "100%",
        background: "#ffffffed",
      }}
      className="flex-col shadow"
    >
      {/* <div className="template-navbar h-14 px-4 py-2 bg-primary flex gap-2 text-white items-center">
        <IoArrowBackCircle className="hover:opacity-70 text-3xl cursor-pointer rounded-sm" />
        <p className="text-sm ml-2">Edit Template</p>
      </div> */}
      <div className="flex h-[calc(100%)] justify-between flex-col">
        <div className="px-4">
          {questionsAnomaly.length > 0 && !receiversList && (
            <span
              className="text-red-500 text-sm font-semibold cursor-pointer hover:text-red-600 hover:underline transition-all ease-in-out duration-200 tracking-wide"
              onClick={() => {
                setShowAttentionDetails(!showAttentionDetails);
              }}
            >
              Attention Required*{" "}
            </span>
          )}
          {questionsAnomaly.length > 0 && showAttentionDetails && (
            <div className="mt-6 p-2 bg-gray-100 rounded-md max-h-full overflow-y-scroll">
              <span className="text-red-500 text-xs inline-block">
                You have a few questions already mapped in the template that
                <span className="font-medium">
                  {" "}
                  might have been removed
                </span>{" "}
                from the Form{" "}
              </span>
              <ul className="text-red-600 font-semibold text-sm cursor-pointer flex flex-col gap-2">
                {questionsAnomaly.map((questionTitle: any, index: number) => (
                  <li
                    key={index}
                    className="bg-white p-1 rounded-md"
                    onClick={() => {
                      let questionId = schemas.find(
                        (schema: any) =>
                          schema?.key?.replace(/\n$/, "") === questionTitle
                      )?.id;
                      if (questionId) {
                        setShowAttentionDetails(false);
                        onEdit(questionId);
                      }
                    }}
                  >
                    {questionTitle}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div>
            {showFormQuestionDropdown && !showAttentionDetails && (
              <FormQuestionDropdown
                formQuestions={formQuestions}
                receiversList={receiversList}
                onSelect={onFormQuestionSelect}
                schemas={schemas}
                selectedFormQuestion={selectedFormQuestion}
              />
            )}
            <Divider />
            {!showAttentionDetails &&
              (getActiveSchemas().length === 0 ? (
                <ListView {...props} />
              ) : (
                <DetailView
                  {...props}
                  activeSchema={getLastActiveSchema()}
                  onEditEnd={onEditEnd}
                />
              ))}
          </div>
        </div>

        <div
          className={`items-center justify-between pt-4 w-full gap-2 ${
            showAttentionDetails ? "hidden" : "flex"
          } `}
        >
          {/* <button
              className="border-2 flex-1 inset-0 border-primary text-primary font-semibold py-2 px-4 rounded text-sm tracking-wide hover:bg-primary hover:text-white flex gap-2 items-center disabled:opacity-50 "
              onClick={addSchemaHandler}
              disabled={!selectedFormQuestion || !formQuestions}
            >
              <MdPlaylistAdd size={20} className="inline-block" />
              Add Field
            </button> */}
          <button
            className="border-2 flex-1 flex justify-center items-center border-primary bg-primary hover:shadow-2xl text-white py-2 px-4 text-sm tracking-wide text-center disabled:opacity-50"
            // onClick={isReceiversListValid ? sendToSigner : saveTemplate}
            onClick={saveTemplate}
            disabled={loading}
          >
            {/* {isReceiversListValid ? (
              <MdSend size={18} className="inline-block" />
            ) : ( */}
            <FaSave size={18} className="inline-block" />
            {/* )} */}
            <span className="ml-4 ">
              {/* {isReceiversListValid
                ? loading
                  ? "Sending"
                  : "Send"
                : loading
                ? "Saving"
                  : "Save"} */}
              {loading ? "Saving" : "Save"}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
