import {
  PDFDocument,
  PDFForm,
  PDFField,
  PDFTextField,
  PDFCheckBox,
  PDFRadioGroup,
  PDFDropdown,
} from "pdf-lib";
import { v4 } from "uuid";
import { S3PATHS } from "../constants/s3Folders";
import { generalizedFileUploadViaSignedUrlPrivate } from "./fileUpload";

function base64ToPdfBytes(base64Pdf: string): Uint8Array | undefined {
  // console.log(base64Pdf);
  try {
    let base64Pdf_ = base64Pdf.replace(/^data:application\/pdf;base64,/, "");
    // console.log({ base64Pdf_ });
    const binaryData = atob(base64Pdf_);
    const length = binaryData.length;
    const bytes = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      bytes[i] = binaryData.charCodeAt(i);
    }

    return bytes;
  } catch (error) {
    console.log(error);
    return;
  }
}

async function createPdfFromBase64(
  base64Pdf: string
): Promise<Uint8Array | undefined> {
  try {
    const bytes = base64ToPdfBytes(base64Pdf);
    if (!bytes) {
      return;
    }

    const pdfDoc = await PDFDocument.create();
    const externalPdfDoc = await PDFDocument.load(bytes);
    const externalPages = await pdfDoc.copyPages(
      externalPdfDoc,
      externalPdfDoc.getPageIndices()
    );
    externalPages.forEach((page) => pdfDoc.addPage(page));
    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
  } catch (error) {
    console.log(error);
    return;
  }
}

/*
=============================================================================
Note : 
Any function modifying the pdf should always return the type of 
Promise<
  { updatedBytes: Uint8Array; updatedBase64: string } | undefined
>;
=============================================================================
*/

async function addBlankPageToPdf(
  base64Pdf: string,
  pageNumber: number
): Promise<{ updatedBytes: Uint8Array; updatedBase64: string } | undefined> {
  const bytes = base64ToPdfBytes(base64Pdf);
  if (!bytes) {
    return;
  }

  const pdfDoc = await PDFDocument.load(bytes);

  // If the specified page number is invalid, add the page at the end
  const targetPageNumber = Math.min(
    Math.max(0, pageNumber),
    pdfDoc.getPageCount() + 1
  );

  const firstPage = pdfDoc.getPage(0);
  const { width, height } = firstPage.getSize();

  const blankPage = pdfDoc.insertPage(targetPageNumber, [width, height]);
  // this is to avoid the "Error generating PDF: Error: Can't embed page with missing Contents"
  blankPage.drawText(" ");
  // blankPage.drawText("This is a blank page", { x: 50, y: 450 });

  const updatedPdfBytes = await pdfDoc.save();
  const updatedPdfArray = Array.from(updatedPdfBytes);
  const updatedBase64 = pdfBytesToBase64Chunked(updatedPdfArray);

  if (!updatedBase64) {
    return;
  }

  return { updatedBytes: updatedPdfBytes, updatedBase64 };
}

async function appendPdfPageAtPageNumber(
  base64Pdf: string,
  pageNumber: number,
  base64PdfToAdd: string
): Promise<
  | { updatedBytes: Uint8Array; updatedBase64: string; numPagesToAdd: number }
  | undefined
> {
  const bytes = base64ToPdfBytes(base64Pdf);
  if (!bytes) {
    return;
  }

  const bytesToAdd = base64ToPdfBytes(base64PdfToAdd);
  if (!bytesToAdd) {
    return;
  }

  const pdfDoc = await PDFDocument.load(bytes);
  const pdfToAdd = await PDFDocument.load(bytesToAdd);
  const mergedPdf = await PDFDocument.create();

  const ogPdfNumPages = pdfDoc.getPageCount();
  const newPdfNumPages = pdfToAdd.getPageCount();
  // If the specified page number is invalid, add the page at the end
  const targetPageNumber = Math.min(
    Math.max(0, pageNumber),
    pdfDoc.getPageCount() + 1
  );
  const startingPages = Array(targetPageNumber)
    .fill(0)
    .map((_, idx) => idx);
  const endingPages = Array(ogPdfNumPages - targetPageNumber)
    .fill(0)
    .map((_, idx) => idx + targetPageNumber);

  // console.log({ startingPages, endingPages, targetPageNumber });

  // Copy pages of PDF 1 before the insertion point
  if (startingPages.length > 0) {
    const copiedPagesOg = await mergedPdf.copyPages(pdfDoc, startingPages); // Pages 1 to 5 of PDF 1
    copiedPagesOg.forEach((page) => {
      mergedPdf.addPage(page);
    });
  }

  // Copy pages of new pdf
  const copiedPagesNew = await mergedPdf.copyPages(
    pdfToAdd,
    pdfToAdd.getPageIndices()
  ); // Pages 1 to 5 of PDF 2
  copiedPagesNew.forEach((page) => {
    mergedPdf.addPage(page);
  });

  // Copy remaining pages of new pdf
  if (endingPages.length > 0) {
    const copiedPagesOgRemaining = await mergedPdf.copyPages(
      pdfDoc,
      endingPages
    ); // Pages 5 to 10 of PDF 1
    copiedPagesOgRemaining.forEach((page) => {
      mergedPdf.addPage(page);
    });
  }

  // Save the updated PDF document
  const updatedPdfBytes = await mergedPdf.save();
  // downloadPdfInBrowser(updatedPdfBytes, "merged.pdf");
  const updatedPdfArray = Array.from(updatedPdfBytes);
  const updatedBase64 = pdfBytesToBase64Chunked(updatedPdfArray);

  if (!updatedBase64) {
    return;
  }

  return {
    updatedBytes: updatedPdfBytes,
    updatedBase64,
    numPagesToAdd: newPdfNumPages,
  };
}

async function duplicatePage(
  base64Pdf: string,
  pageNumber: number
): Promise<{ updatedBytes: Uint8Array; updatedBase64: string } | undefined> {
  const bytes = base64ToPdfBytes(base64Pdf);
  if (!bytes) {
    return;
  }

  const pdfDoc = await PDFDocument.load(bytes);
  const newPdf = await PDFDocument.create();

  const targetPageNumber = Math.min(
    Math.max(0, pageNumber),
    pdfDoc.getPageCount() + 1
  );

  const ogPdfNumPages = pdfDoc.getPageCount();

  const startingPages = Array(targetPageNumber)
    .fill(0)
    .map((_, idx) => idx);
  const endingPages = Array(ogPdfNumPages - targetPageNumber)
    .fill(0)
    .map((_, idx) => idx + targetPageNumber);

  // Copy pages of PDF 1 before the insertion point
  if (startingPages.length > 0) {
    const copiedPagesOg = await newPdf.copyPages(pdfDoc, startingPages); // Pages 1 to 5 of PDF 1
    copiedPagesOg.forEach((page) => {
      newPdf.addPage(page);
    });
  }

  // Copy pages of new pdf
  const copiedPagesNew = await newPdf.copyPages(pdfDoc, [targetPageNumber]); // Pages 1 to 5 of PDF 2
  copiedPagesNew.forEach((page) => {
    newPdf.addPage(page);
  });

  // Copy remaining pages of new pdf
  if (endingPages.length > 0) {
    const copiedPagesOgRemaining = await newPdf.copyPages(pdfDoc, endingPages); // Pages 5 to 10 of PDF 1
    copiedPagesOgRemaining.forEach((page) => {
      newPdf.addPage(page);
    });
  }

  const updatedPdfBytes = await newPdf.save();
  const updatedPdfArray = Array.from(updatedPdfBytes);
  const updatedBase64 = pdfBytesToBase64Chunked(updatedPdfArray);

  if (!updatedBase64) {
    return;
  }

  return { updatedBytes: updatedPdfBytes, updatedBase64 };
}

async function deletePageFromPdf(
  base64Pdf: string,
  pageNumberToDelete: number
): Promise<{ updatedBytes: Uint8Array; updatedBase64: string } | undefined> {
  const bytes = base64ToPdfBytes(base64Pdf);
  if (!bytes) {
    return;
  }

  const pdfDoc = await PDFDocument.load(bytes);

  // Ensure the specified page number is within valid range
  if (
    pageNumberToDelete < 0 ||
    pageNumberToDelete > pdfDoc.getPageCount() - 1
  ) {
    return;
  }

  pdfDoc.removePage(pageNumberToDelete); // Page numbers are 0-indexed

  const updatedPdfBytes = await pdfDoc.save();
  const updatedPdfArray = Array.from(updatedPdfBytes);
  const updatedBase64 = pdfBytesToBase64Chunked(updatedPdfArray);

  if (!updatedBase64) {
    return;
  }

  return { updatedBytes: updatedPdfBytes, updatedBase64 };
}

async function rearrangePagesInPdf(
  base64Pdf: string,
  pageOrder: number[]
): Promise<{ updatedBytes: Uint8Array; updatedBase64: string } | undefined> {
  try {
    const bytes = base64ToPdfBytes(base64Pdf);
    if (!bytes) {
      console.log({ bytes });
      return;
    }

    const pdfDoc = await PDFDocument.load(bytes);
    const pageCount = pdfDoc.getPageCount();

    // Ensure that the provided pageOrder array is valid
    const isValidPageOrder = pageOrder.every(
      (pageNumber) => pageNumber >= 0 && pageNumber < pageCount
    );
    if (!isValidPageOrder) {
      console.log({ isValidPageOrder });
      return;
    }

    const pages = pdfDoc.getPages();
    for (let currentPage = 0; currentPage < pageOrder.length; currentPage++) {
      pdfDoc.removePage(currentPage);
      pdfDoc.insertPage(currentPage, pages[pageOrder[currentPage]]);
    }

    const updatedPdfBytes = await pdfDoc.save();
    const updatedPdfArray = Array.from(updatedPdfBytes);
    const updatedBase64 = pdfBytesToBase64Chunked(updatedPdfArray);

    if (!updatedBase64) {
      return;
    }

    return { updatedBytes: updatedPdfBytes, updatedBase64 };
  } catch (error) {
    console.log(error);
    return;
  }
}

function pdfBytesToBase64Chunked(pdfBytes: number[]): string | undefined {
  try {
    // 8kb chunks are most optmised
    // can theoretically support 64k
    const chunk = 8 * 1024;
    let res2 = "";

    for (let i = 0; i < pdfBytes.length / chunk; i++) {
      const start = i * chunk;
      const end = (i + 1) * chunk;
      const chunkBytes = pdfBytes.slice(start, end);
      const binaryString = String.fromCharCode.apply(null, chunkBytes);
      res2 += binaryString;
    }
    let base64String = btoa(res2);

    return `data:application/pdf;base64,${base64String}`;
  } catch (error) {
    console.log(error);
    return;
  }
}

function downloadPdfInBrowser(
  pdfBytes: Uint8Array,
  fileName: string = "document.pdf"
) {
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

async function updateBasePdfLink(
  base64: string,
  documentId: string,
  cb: (link: string) => void
): Promise<string | undefined> {
  try {
    let folderName = S3PATHS.UPDATED_BASE64;
    let fileName = `${documentId || v4()}.pdf`;
    const file = base64toFile(base64, fileName);
    if (file) {
      /*
      This uploads to approval bucket chnage in priority
      */
      const url = await generalizedFileUploadViaSignedUrlPrivate({
        file: file,
        folderName: folderName,
        notUnique: true,
      });
      cb(url);
      return url;
    }
    return;
  } catch (error) {
    console.log(error);
    return;
  }
}

function base64toFile(
  base64String: string,
  fileName: string
): File | undefined {
  try {
    let base64String_ = base64String.replace(
      /^data:application\/pdf;base64,/,
      ""
    );
    const byteCharacters = atob(base64String_);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new File([byteArray], fileName, { type: "application/pdf" });

    // console.log("File created:", file);
    return file;
  } catch (error) {
    console.log(error);
    return;
  }
}

async function fileToBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      try {
        if (typeof reader.result === "string") {
          const base64String = reader.result.split(",")[1]; // Extract base64 string from data URL
          resolve(base64String);
        } else {
          reject("");
        }
      } catch (error) {
        console.log("Error in fileToBase64");
        reject(error);
      }
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
}
function Imagebase64ToFile(base64String: string, filename: string): File {
  // Split the base64 string into data and contentType
  const [contentTypePart, base64Data] = base64String.split(";base64,");
  const contentType = contentTypePart.split(":")[1];

  // Decode the Base64 string
  const byteCharacters = atob(base64Data);

  // Create an array for the decoded characters
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Create a Uint8Array from the array of byte numbers
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the byte array
  const blob = new Blob([byteArray], { type: contentType });

  // Create a File from the Blob
  const file = new File([blob], filename, { type: contentType });

  return file;
}

function downloadBase64File(base64: string, fileName: string) {
  const link = document.createElement("a");
  link.href = base64;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

async function copyPdfBytes(sourceFile: File): Promise<File> {
  try {
    const sourcePdfBytes = await sourceFile.arrayBuffer();
    const sourcePdfDoc = await PDFDocument.load(sourcePdfBytes);
    // ! Error in form fields therefore flattening form fields
    const form = sourcePdfDoc.getForm();
    form.flatten();

    const newPdfDoc = await PDFDocument.create();

    const copiedPages = await newPdfDoc.copyPages(
      sourcePdfDoc,
      sourcePdfDoc.getPageIndices()
    );
    copiedPages.forEach((page) => newPdfDoc.addPage(page));

    //   const form = sourcePdfDoc.getForm();
    // const newForm = newPdfDoc.getForm();

    // form.getFields().forEach((field) => {
    //   const fieldName = field.getName();
    //   const fieldType = field.constructor.name;

    //   if (fieldType === "PDFTextField") {
    //     const textField = field;
    //     const newTextField = newForm.createTextField(fieldName);
    //     newTextField.setText(textField.getText());
    //   } else if (fieldType === "PDFCheckBox") {
    //     const checkBox = field;
    //     const newCheckBox = newForm.createCheckBox(fieldName);
    //     newCheckBox.check(checkBox.isChecked());
    //   } else if (fieldType === "PDFRadioGroup") {
    //     const radioGroup = field;
    //     const newRadioGroup = newForm.createRadioGroup(fieldName);
    //     newRadioGroup.select(radioGroup.getSelected().getName());
    //   } else if (fieldType === "PDFDropDown") {
    //     const dropDown = field;
    //     const newDropDown = newForm.createDropDown(fieldName);
    //     newDropDown.select(dropDown.getSelected().getName());
    //   }
    //   // Add other field types as needed
    // });

    const pdfBytes = await newPdfDoc.save();
    return new File([pdfBytes], sourceFile.name, { type: "application/pdf" });
  } catch (error) {
    console.log("Error in copyPdfBytes");
    console.log(error);
    return sourceFile;
  }
}

const updatePdfWithBlankPage = async (pdfFile: File) => {
  try {
    const arrayBuffer = await pdfFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const pages = pdfDoc.getPages();
    let isEdited = false;
    let index = 0;
    for (const page of pages) {
      const { width, height } = page.getSize();

      // Check if the page has content
      const contents = await page.node.Contents();

      if (!contents) {
        // Adjust check as needed
        console.log("No content on this page.");
        // Draw a space or perform other actions if necessary
        page.drawText(" "); // You might want to specify position and size
        isEdited = true; // Set flag indicating that changes were made
      }
    }

    const updatedPdfBytes = await pdfDoc.save();
    const normalPdfBlob = new Blob([updatedPdfBytes], {
      type: "application/pdf",
    });
    const updatedPdfFile = new File([normalPdfBlob], pdfFile?.name, {
      type: "application/pdf",
    });

    // Download the Blob as a file
    // saveAs(normalPdfBlob, 'normal.pdf');
    return isEdited ? updatedPdfFile : pdfFile;
  } catch (err) {
    console.log("error", err);
  }
};
export {
  base64ToPdfBytes,
  createPdfFromBase64,
  addBlankPageToPdf,
  downloadPdfInBrowser,
  updateBasePdfLink,
  deletePageFromPdf,
  rearrangePagesInPdf,
  fileToBase64,
  appendPdfPageAtPageNumber,
  duplicatePage,
  Imagebase64ToFile,
  downloadBase64File,
  copyPdfBytes,
  updatePdfWithBlankPage,
};
