import { useEffect, useRef, useState, useCallback } from "react";

import axios from "../../utils/axios";
// import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
import { fetchOwner } from "../../utils";
import { Template } from "../../pdfme/generator/src";
import { Designer } from "../../pdfme/ui/src";
import _ from "lodash";
import { PREVIEW_AVAILABLE_STATUS } from "../../constants/common";

interface MapperProps {
  file?: File | null;
  numberOfPages?: number;
  schemas?: any;
  sampledata?: any;
  roles?: {
    title: string;
    colour: string;
  }[];
  isPreview?: boolean;
  isSaved?: boolean;
  startSave?: boolean;
  setIsSaved?: (value: any) => void;
  setIsPreview: (value: any) => void;
  setStartSave: (value: any) => void;
  setReload: (value: any) => void;
  fetchedData: any;
}

type customVariableProps = {
  varName: string;
  varValue: string;
};

const PdfTemplateMapper = ({
  file,
  numberOfPages,
  schemas,
  sampledata,
  roles,
  setIsSaved,
  isPreview,
  startSave,
  setIsPreview,
  setStartSave,
  setReload,
  fetchedData,
}: MapperProps) => {
  let navigate = useNavigate();
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);
  const [smallDisplay, setSmallDisplay] = useState(true);
  const [owner, setOwner] = useState<any>({});
  const [formQuestions, setFormQuestions] = useState<any>([]);
  const [templateSchema, setTemplateSchema] = useState<any>([]);
  const [templateSampleData, setTemplateSampleData] = useState<any>([]);
  const [searchParams] = useSearchParams();

  // Custom Variables
  const [customVariables, setCustomVariables] = useState<customVariableProps[]>(
    []
  );
  let documentId = searchParams.get("documentId");
  // const { data, loading, error }: any = useFetch(`/forms?formId=${formId}`);
  // let { forms } = data || {};

  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
    };
    updatedOwner();
  }, []);

  //   useEffect(() => {
  //     if (forms) {
  //       const allQuestions = forms[0].formJson.pages.flatMap(
  //         (page: any) => page.questions
  //       );
  //       setFormQuestions(allQuestions);
  //     }
  //   }, [forms]);

  function isObjectEmpty(obj: object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  //   useEffect(() => {
  //     if (numberOfPages && formQuestions.length > 0) {
  //       const schemas = [];
  //       const schemaPage: any = {};
  //       let question = formQuestions[0];
  //       const key = question.title.replace(/[^a-zA-Z0-9]/g, ""); // Removing special characters from the title to use it as a key
  //       if (question.type === "Text") {
  //         schemaPage[key] = {
  //           type: "text",
  //           position: {
  //             x: 25.06,
  //             y: 26.35,
  //           },
  //           width: 77.77,
  //           height: 18.7,
  //           fontSize: 20,
  //           fontColor: "#14b351",
  //         };
  //       } else if (question.type === "Signature") {
  //         schemaPage[key] = {
  //           type: "image",
  //           position: {
  //             x: 24.99,
  //             y: 65.61,
  //           },
  //           width: 60.66,
  //           height: 93.78,
  //         };
  //       }
  //       // @ts-ignore
  //       schemas.push(schemaPage);
  //       setTemplateSchema(schemas);
  //       // const sampledata = [];
  //       // const pageData: any = {};
  //       // for (const key in schemas[0]) {
  //       //   if (schemas[0][key].type === "text") {
  //       //     pageData[key] = "Example..";
  //       //   } else if (schemas[0][key].type === "image") {
  //       //     // Using a placeholder image URL from picsum.photos
  //       //     pageData[key] = "https://picsum.photos/200/300";
  //       //   }
  //       // }
  //       // sampledata.push(pageData);
  //       setTemplateSampleData(sampledata);

  //     }
  //   }, [numberOfPages, sampledata]);

  useEffect(() => {
    setSmallDisplay(window.innerWidth < 900);
  }, []);

  // @ts-ignore
  useEffect(() => {
    const template: Template = {
      // schemas: templateSchema,
      schemas: schemas || [{}],
      // sampledata: [{ Name: "Input example", photo: "https://picsum.photos/200/300"}],
      sampledata: [{}],
      // @ts-ignore
      basePdf: file,
    };
    // console.log(template, "template");

    const inputs = [
      {
        name: "Pet Name",
        photo: "https://picsum.photos/200/300",
        age: "4 years",
        sex: "Male",
        weight: "33 pounds",
        breed: "Mutt",
        owner: "https://pdfme.com/",
      },
    ];
    // Run this effect when the containerRef is available
    if (designerRef.current) {
      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
        isOnlyPdfTemplate: true,
        roles: roles,
        fetchedData,
        setReload,
        setIsPreview: setStartSave,
        // variablesHelper: {
        //   customVariables,
        //   setCustomVariables: () => {
        //     console.log("rahul is ");
        //   },
        // },
        instanceFrom: "PDF-TEMPLATE",
        inputs: fetchedData?.document?.pdfTemplate?.inputs,
      });
      designer.current.onSaveTemplate(saveTemplate);
      designer.current.onSaveTemplateWithoutRedirect(
        saveTemplateWithoutRedirect
      );
      // designer.current.onChangeTemplate(setTemplate);
    }
    return () => {
      // Destroy the designer when the component unmounts
      // @ts-ignore
      // designer.current.destroy();
    };
  }, [designerRef, file]);

  const saveTemplate = async () => {
    // downloadJsonFile(designer.current.getTemplate(), 'template');
    // @ts-ignore
    let template = designer.current.getTemplate();
    console.log(template, "template");
    if (template) {
      toast.info("Saving template...");
    }
    try {
      const { data } = await axios.post("/update-signature-template", {
        documentId,
        schemas: template.schemas,
        ownerId: owner._id,
      });
      if (!data.error) {
        toast.success(data.message);
        navigate(
          `/create/pdf-template/send-for-signature?documentId=${documentId}`
        );
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error saving template");
    }
  };

  const handleSave = async (template: any) => {
    console.log("handle save pdf template");
    try {
      const { data } = await axios.post("/update-signature-template", {
        documentId,
        schemas: template.schemas,
        ownerId: owner._id,
      });
      if (data.error) {
        toast.error(data.error);
      }
      setIsSaved && (await setIsSaved((prev: boolean) => !prev));
    } catch (error) {
      console.log(error);
      toast.error("Error saving template");
    }
  };

  const debounceCallHandleSave = useCallback(
    _.debounce((template: any) => handleSave(template), 2000),
    []
  );

  const saveTemplateWithoutRedirect = async () => {
    if (PREVIEW_AVAILABLE_STATUS.includes(fetchedData?.document?.status)) {
      console.log("Returning without saving schema this is preview mode");
      return;
    }
    // @ts-ignore
    let template = designer.current.getTemplate();
    console.log("template saved without redirect");
    debounceCallHandleSave(template);
    // handleSave(template);
    // setIsSaved && setIsSaved((prev: boolean) => !prev);
  };

  // useEffect(() => {
  //   // @ts-ignore
  //   let template = designer.current.getTemplate();
  //   handleSave(template);
  // }, [isPreview]);

  // console.log("isPreview in side template mapper", isPreview);

  const handlePreviewAndSave = async () => {
    if (startSave) {
      // @ts-ignore
      let template = designer.current.getTemplate();
      await handleSave(template);
      setStartSave && (await setStartSave(false));
      setIsPreview && setIsPreview(true);
    }
  };

  useEffect(() => {
    handlePreviewAndSave();
  }, [startSave]);

  return (
    <div>
      <div className="overflow-hidden" ref={designerRef} id="container"></div>{" "}
      {/* This will be used as the container */}
      {/* <button className="p-2 rounded-lg bg-blue-300" onClick={saveTemplate}>
        Save template
      </button> */}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default PdfTemplateMapper;
