export type BaseInitialStateType = {
  loading: boolean;
  isError: boolean;
  updateDone: boolean;
  errorData: {
    message: string;
    type: string;
    errors: string[];
  };
};
export const BASE_INITIAL_STATE: BaseInitialStateType = {
  loading: false,
  isError: false,
  updateDone: false,
  errorData: {
    message: "",
    type: "",
    errors: [],
  },
};
export type ParamsType = { [key: string]: string };

export function parseQueryParams(url: string, params?: ParamsType): string {
  if (!params) {
    return url;
  }

  const parsedParams = new URLSearchParams(params).toString();
  return `${url}?${parsedParams}`;
}
