// @ts-nocheck

import React, { useEffect, useState } from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { PiFilePdfThin } from "react-icons/pi";
import { InfinitySpin } from "react-loader-spinner";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTable } from "react-table";
import { Slide, ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../constants/constants";
import { fetchOwner } from "../../utils";
import axios from "./../../utils/axios";
import NoDocuments from "../../components/NoDocuments";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import getS3PreSignedUrl from "../../utils/getS3PreSignedUrl";
import { IoTimeOutline } from "react-icons/io5";
import { BiDollarCircle } from "react-icons/bi";
import { FaRegFilePdf } from "react-icons/fa6";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/Common/BoloSelect/BoloSelect";
import NoDocumentsV1 from "../../components/NoDocuments/NoDocumentsV1";
import { CgFileAdd } from "react-icons/cg";
import { FiPlusSquare } from "react-icons/fi";

const AssignedToMe = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  const [owner, setOwner] = useState({});
  const [sharedAdmins, setSharedAdmins] = useState<any[]>([]);
  const PAGE_LIMIT = 10;
  const [searchParams] = useSearchParams();
  const [loading, setloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  //   const [filter, setFilter] = useState("ACTIVE");
  const [filter, setFilter] = useState("PENDING");

  const [roleInCollaboration, setRoleInCollaboration] = useState("owner");
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [fetchedDocuments, setfetchedDocuments] = useState([]);
  const [formsCount, setFormsCount] = useState(0);
  const [tableData, setTableData] = useState({
    columns: [],
    respondent: [],
  });
  const [rowData, setRowData] = useState([]);

  const [reload, setReload] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);

  useEffect(() => {
    const fetchAllForms = async (page, limit, filter = "PENDING") => {
      try {
        setloading(true);
        let owner = JSON.parse(localStorage.getItem("owner"));
        let currentUserEmail =
          localStorage.getItem("currentUserEmail") ?? owner?.email;
        //   let accountType =
        //     localStorage.getItem("activeView")?.toLowerCase() ??
        //     (localStorage.getItem("accountType") === "admin"
        //       ? "owner"
        //       : localStorage.getItem("accountType"));

        const ownerId = owner?._id;
        //   const { activeOwner } = getState().AppStateReducer;
        //   let currentOwnerId = activeOwner?._id ?? "";

        let { data: assignedToMeDocuments } = await axios.get(
          `/get-assigned-to-me?page=${page}&limit=${limit}&filter=${filter}&ownerId=${ownerId}&currentUserEmail=${currentUserEmail}`
        );
        setfetchedDocuments(assignedToMeDocuments.documents);
        setFormsCount(assignedToMeDocuments.formCount);
        setloading(false);
      } catch (error) {
        setloading(false);
        console.error(error.message);
        toast.error(error.message);
      }
    };

    fetchAllForms(page, PAGE_LIMIT, filter);
  }, [page, PAGE_LIMIT, filter]);

  const getFormsBySearch = async (e: any) => {
    e && e.preventDefault();
  };

  function getFromFillLink(form: any) {
    if (form?.formType === "GOOGLE_FORM") {
      return `${BASE_URL?.slice(0, BASE_URL?.length - 4)}/form/${form?._id}`;
    }
    return form.formUrl;
  }
  function copyLink(link: any) {
    addToast(MakeToast("Link Copied"));
    navigator.clipboard.writeText(link);
  }

  function openExternalLink(link: any) {
    window.open(link, "_blank");
  }

  const actionColumn = () => (
    <div className="flex items-center">
      <div className="truncate flex items-center gap-2">
        Action
        {/* <span
          className="inline-block cursor-pointer"
          data-tooltip-id="assigned-tooltip"
        >
          <FaInfoCircle className="text-gray-700" />
        </span> */}
      </div>
    </div>
  );
  function getDocumentDisplayStatus(document) {
    if (document?.isApprover) {
      if (document?.documentStatus === "VOID") {
        return "VOID";
      } else if (
        document?.status === "SENT FOR APPROVAL" ||
        document?.status === "VIEWED"
      ) {
        return "PENDING";
      } else {
        return document?.status;
      }
    } else {
      if (document?.documentStatus === "VOID") {
        return "VOID";
      } else if (document?.documentStatus === "PAID") {
        return "PAID";
      } else if (document?.documentDeclined) {
        return "DECLINED";
      } else if (document?.status === "SIGNED") {
        return "SIGNED";
      } else {
        return "PENDING";
      }
    }
  }
  function getBackgroundColorClass(document): {
    backGround: string;
    color: string;
  } {
    if (
      document?.documentStatus === "VOID" ||
      document?.documentDeclined ||
      document?.status === "REJECTED"
    ) {
      return {
        color: COLORS.status.failure.primary,
        backGround: COLORS.status.failure.secondary,
      };
    } else if (document?.documentStatus === "PAID") {
      return {
        color: COLORS.status.success.primary,
        backGround: COLORS.status.success.secondary,
      };
    } else if (document?.status === "SIGNED") {
      return {
        color: COLORS.status.success.primary,
        backGround: COLORS.status.success.secondary,
      };
    } else if (document?.status === "APPROVED") {
      return {
        color: COLORS.status.approved.primary,
        backGround: COLORS.status.approved.secondary,
      };
    } else {
      return {
        color: COLORS.status.intermediate.primary,
        backGround: COLORS.status.intermediate.secondary,
      };
    }
  }

  function getButtonText({ documentStatus, status, isPayer, isPayment }) {
    if (documentStatus === "PAID") {
      return "Download";
    }

    // doc ka status tabhi signed hoga jub signer ka status signed hai
    if (documentStatus === "SIGNED") {
      if (isPayment) {
        if (isPayer) {
          return "Pay Now";
        }
        return "Donwload";
      } else {
        return "Download";
      }
    }

    if (status === "SIGNED") {
      return "Download";
    }
    if (
      documentStatus === "SENT FOR APPROVAL" &&
      (status === "SENT FOR APPROVAL" || status === "VIEWED")
    ) {
      return "Review Now";
    }

    return "Sign Now";
  }

  async function handleButtonClick(rowData) {
    if (rowData?.documentStatus === "PAID") {
      let url = await getS3PreSignedUrl(rowData?.finishedPdfUrl);
      window.open(url, "_blank");
      return;
    }

    if (rowData?.documentStatus === "SIGNED") {
      if (rowData?.isPayment) {
        if (rowData?.isPayer) {
          window.open(rowData?.sentUrl, "_blank");
          return;
        }
        let url = await getS3PreSignedUrl(rowData?.finishedPdfUrl);
        window.open(url, "_blank");
        return;
      } else {
        let url = await getS3PreSignedUrl(rowData?.finishedPdfUrl);
        window.open(url, "_blank");
        return;
      }
    }

    if (rowData?.status !== "SIGNED") {
      window.open(rowData?.sentUrl, "_blank");
      return;
    }

    window.open(rowData?.sentUrl, "_blank");
    return;
  }

  React.useMemo(() => {
    let cols = [
      // {
      //   name: "#",
      //   Header: "#",
      //   accessor: "#", // accessor is the "key" in the data
      // },
      {
        name: "Document",
        Header: "Document",
        accessor: "Document", // accessor is the "key" in the data
      },
      {
        name: "Status",
        Header: "Status",
        accessor: "Status",
      },
      {
        name: "Received on",
        Header: "Received on",
        accessor: "Received on",
      },
      {
        name: "Sender",
        Header: "Sender",
        accessor: "Sender",
      },
      // {
      //   name: "Action",
      //   Header: actionColumn(),
      //   accessor: "Action",
      // },
    ];
    // conditionally showing actions as actions are not shown in void and declined
    if (
      filter !== "VOID" &&
      filter !== "DECLINED" &&
      filter !== "REJECTED" &&
      filter !== "APPROVED"
    ) {
      cols.push({
        name: "Action",
        Header: actionColumn(),
        accessor: "Action",
      });
    }
    if (window.innerWidth < 640) {
      cols = cols.filter(
        (col) => col.name === "Download" || col.name === "Forms"
      );
    }

    // cols.push({
    //   accessor: "hidden",
    // });

    // @ts-ignore
    setTableData((prev) => {
      return {
        ...prev,
        columns: cols,
      };
    });
  }, [filter]);

  React.useMemo(() => {
    let documents =
      searchResults?.documents?.length > 0 ||
      searchResults?.message === "No result found for this search"
        ? searchResults?.documents
        : fetchedDocuments?.length > 0
        ? fetchedDocuments
        : [];

    setRowData(documents);

    let data = documents?.map((document, i) => {
      // let isInvoiceType =
      //   form?.renderer === "BILL" || form?.renderer === "PURCHASE_ORDER";
      // console.log({ document });

      return {
        id: document.documentId,
        "#": (
          <span className="bg-gray-300 bg-opacity-40 shadow px-3 py-1.5 rounded-md">
            {page * PAGE_LIMIT + i + 1 - 10}
          </span>
        ),
        Document: (
          <span translate="no" className="text-gray-800 text-sm pl-4">
            {/* <FaRegFilePdf className="text-gray-600 mr-2 inline-block" /> */}
            <span>
              {document?.documentName || document?.name || "Untitled"}
            </span>
          </span>
        ),
        Status:
          filter !== "VOID" && filter !== "DECLINED" ? (
            <div
              data-tip={`the Document is in ${getDocumentDisplayStatus(
                document
              )} state`}
              className="flex flex-row items-center w-fit"
            >
              <p
                className="text-[10px] tracking-wider px-2 rounded font-semibold"
                style={{
                  color: getBackgroundColorClass(document).color,
                  background: getBackgroundColorClass(document).backGround,
                }}
              >
                {getDocumentDisplayStatus(document)}
              </p>
              {document?.isPayment && document?.documentStatus === "SIGNED" && (
                <p className="text-[10px] text-gray-500 ml-2">
                  Waiting for payment
                </p>
              )}
            </div>
          ) : (
            <div
              data-tip={`the Document is in ${filter} state`}
              className="flex flex-row items-center w-fit"
            >
              {/* <div className={`w-2 h-2 rounded-full bg-red-500`}></div> */}
              <p
                className="text-[10px] tracking-wider px-2 rounded font-semibold"
                style={{
                  color: getBackgroundColorClass(document).color,
                  background: getBackgroundColorClass(document).backGround,
                }}
              >
                {filter}
              </p>
            </div>
          ),
        "Received on": (
          <div>
            <h3 className="text-gray-700 text-xs">
              {new Date(document.receivedOn).toDateString()}
            </h3>
            <p className="text-gray-500 text-xs mt-1">
              {new Date(document.receivedOn).toLocaleTimeString()}
            </p>
          </div>
        ),

        Sender: (
          <div>
            <h3 className="text-gray-700 text-xs font-medium">
              {document?.ownerName}
            </h3>
            <p className="text-gray-500 text-xs mt-0.5">
              {document?.ownerEmail}
            </p>
          </div>
        ),
        Action: (
          <BoloButton
            size={"sm"}
            variant="tertiary"
            className={`text-xs md:text-xs ${
              document?.isApprover &&
              (document?.status === "REJECTED" ||
                document?.status === "APPROVED") &&
              "hidden"
            }`}
            disabled={
              (document?.status === "SIGNED" && !document?.finishedPdfUrl) ||
              document?.documentStatus === "VOID" ||
              document?.documentDeclined
            }
            onClick={async () => {
              if (document?.status === "SIGNED") {
                new MixPanel().track("Assigned to me", {
                  assign_to_me_action: "assigned_to_me_sign_download",
                  paid: false,
                });
                new MixPanel().increment("assigned_to_me_sign_download");
              } else {
                new MixPanel().track("Assigned to me", {
                  assign_to_me_action: "assigned_to_me_sign_now",
                  paid: false,
                });
                new MixPanel().increment("assigned_to_me_sign_now");
              }
              // if (document?.status === "SIGNED" && document?.finishedPdfUrl) {
              //   // window.open(document?.finishedPdfUrl, "_blank");
              //   let url = await getS3PreSignedUrl(document?.finishedPdfUrl);
              //   window.open(url, "_blank");
              // } else {
              //   window.open(document?.sentUrl, "_blank");
              // }

              handleButtonClick(document);
            }}
            // className={`flex flex-row justify-center items-center text-center hover:opacity-90 text-xs rounded-md capitalize border px-3 py-2.5 bg-secondary text-primary font-medium border-tertiary hover:border-primary shadow-sm`}
          >
            {/* <LiaSignatureSolid className="text-2xl text-primary hover:opacity-85 mr-2" /> */}
            {getButtonText({
              documentStatus: document?.documentStatus,
              status: document?.status,
              isPayer: document?.isPayer,
              isPayment: document?.isPayment,
            })}
          </BoloButton>
        ),
      };
    });

    setTableData((prev) => {
      return {
        ...prev,
        data,
      };
    });
  }, [fetchedDocuments, searchResults]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //@ts-ignore
    useTable({ columns: tableData.columns ?? [], data: tableData.data ?? [] });

  /* This is checking if the user is already logged in. If the user is logged in, it will redirect to
  the dashboard. */
  let user = localStorage.getItem("owner");
  if (!user) {
    console.log("user not found");
    return <Navigate to="/login" />;
  }

  // console.log({ rowData });

  return (
    <div className="-m-4 bg-white h-[100%]">
      <Helmet>
        <title translate="no">Assigned To Me</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Assign To Me" />
        <meta
          name="description"
          content="Access and manage all documents assigned to you for signing in one place."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/assigned-to-me`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Assign To Me`} />
        <meta
          property="og:description"
          content="Access and manage all documents assigned to you for signing in one place."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/assigned-to-me"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="px-3 md:px-4 h-[32px] my-3 md:my-6 text-gray-600 flex flex-col md:flex-row  items-center justify-between">
        <p className="font-semibold text-sm">Assigned to me</p>
        <div className="flex gap-5 items-center">
          <p className="text-xs whitespace-nowrap font-medium">
            Sort By Status
          </p>
          <Select
            onValueChange={(value) => {
              setFilter(value);
              setPage(1);
            }}
            value={filter}
          >
            <SelectTrigger className="max-w-[300px] min-w-[300px]">
              <SelectValue placeholder="Select Status" />
            </SelectTrigger>
            <SelectContent className="">
              <SelectGroup>
                <SelectItem value="ALL">
                  <p>ALL</p>
                </SelectItem>
                <SelectItem value="PENDING">
                  <p style={{ color: COLORS.status.intermediate.primary }}>
                    PENDING
                  </p>
                </SelectItem>
                <SelectItem value="PAYMENT_PENDING">
                  <p style={{ color: COLORS.status.intermediate.primary }}>
                    PAYMENT PENDING
                  </p>
                </SelectItem>
                <SelectItem value="APPROVED">
                  <p style={{ color: COLORS.status.approved.primary }}>
                    APPROVED
                  </p>
                </SelectItem>
                <SelectItem value="SIGNED">
                  <p style={{ color: COLORS.status.success.primary }}>SIGNED</p>
                </SelectItem>
                <SelectItem value="PAID">
                  <p style={{ color: COLORS.status.success.primary }}>PAID</p>
                </SelectItem>
                <SelectItem value="REJECTED">
                  <p style={{ color: COLORS.status.failure.primary }}>
                    REJECTED
                  </p>
                </SelectItem>
                <SelectItem value="DECLINED">
                  <p style={{ color: COLORS.status.failure.primary }}>
                    DECLINED
                  </p>
                </SelectItem>
                <SelectItem value="VOID">
                  <p style={{ color: COLORS.status.failure.primary }}>VOID</p>
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </div>
      {/* PC layout */}
      <div
        className={`hidden md:block relative`}
        style={{
          overflowY:
            fetchedDocuments?.length === 0 && !loading ? "hidden" : "auto",
          maxHeight:
            fetchedDocuments?.length === 0 && !loading
              ? 0
              : "calc(100% - 120px)",
          height:
            fetchedDocuments?.length === 0 && !loading
              ? 0
              : "calc(100% - 120px)",
        }}
        id="mini-pdf-custom-scroll"
      >
        <table {...getTableProps()} className="w-full">
          <thead className="max-h-[40px] h-[40px] sticky top-0 bg-white shadow z-20">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`${
                      column.name === "Document" ? "pl-5" : ""
                    } px-3 py-2 text-left text-gray-600 font-semibold text-xs truncate`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading ? (
              <tr role={"row"} align={"center"} className={"w-full"}>
                <td className={"w-full"} colSpan={8}>
                  <div className="p-8">
                    <BoloLoader />
                  </div>
                </td>
              </tr>
            ) : (
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="hover:bg-secondary/40 cursor-pointer border-t"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${
                            cell.column.name === "#"
                              ? "text-center"
                              : "min-w-[150px] max-w-[300px]"
                          } px-2 py-3 text-sm truncate`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      {/* Mobile Layout */}
      <div className="block md:hidden">
        <div className="flex flex-col overflow-x-auto">
          {rowData.map((row, idx) => {
            // console.log(row);
            return (
              <div
                className={`relative border border-t py-3 px-3  flex gap-2 justify-between items-center`}
              >
                <div className="flex gap-3">
                  <div
                    data-tip={`the Document is in ${getDocumentDisplayStatus(
                      row
                    )} state`}
                    className="flex flex-col gap-1 items-center justify-center w-fit"
                  >
                    <div
                      className={`w-3 h-3 rounded-full ${getBackgroundColorClass(
                        row
                      )}`}
                    ></div>
                    {row?.documentStatus === "SIGNED" && row?.isPayment && (
                      <div className="flex items-center text-gray-500">
                        <BiDollarCircle />
                      </div>
                    )}
                  </div>
                  <div className="break-all flex text-xs flex-col ml-2">
                    <div className="font-semibold">{row?.documentName}</div>
                    <div className="flex flex-wrap gap-2 text-[10px] mb-[2px]">
                      {row?.ownerName && <span>{row?.ownerName}</span>}
                      <span>{row?.ownerEmail}</span>
                    </div>
                    <div className="flex text-[10px] gap-2">
                      <span className="text-gray-700">
                        {new Date(row.receivedOn).toDateString()}
                      </span>
                      <span className="text-gray-500">
                        {new Date(row.receivedOn).toLocaleTimeString()}
                      </span>
                    </div>
                  </div>
                </div>
                {filter !== "DECLINED" &&
                  filter !== "VOID" &&
                  filter !== "REJECTED" &&
                  filter !== "APPROVED" && (
                    <button
                      disabled={
                        (row?.status === "SIGNED" && !row?.finishedPdfUrl) ||
                        row?.documentStatus === "VOID" ||
                        row?.documentDeclined
                      }
                      onClick={() => {
                        if (row?.status === "SIGNED") {
                          new MixPanel().track("Assigned to me", {
                            assign_to_me_action: "assigned_to_me_sign_download",
                            paid: false,
                          });
                          new MixPanel().increment(
                            "assigned_to_me_sign_download"
                          );
                        } else {
                          new MixPanel().track("Assigned to me", {
                            assign_to_me_action: "assigned_to_me_sign_now",
                            paid: false,
                          });
                          new MixPanel().increment("assigned_to_me_sign_now");
                        }
                        // if (row?.status === "SIGNED" && row?.finishedPdfUrl) {
                        //   window.open(row?.finishedPdfUrl, "_blank");
                        // } else {
                        //   window.open(row?.sentUrl, "_blank");
                        // }
                        handleButtonClick(row);
                      }}
                      className={`w-fit whitespace-nowrap flex flex-row justify-center items-center text-center hover:opacity-90 text-xs rounded capitalize border px-2 py-1.5 bg-secondary text-primary font-medium border-tertiary hover:border-primary shadow-sm`}
                    >
                      {/* <LiaSignatureSolid className="text-2xl text-[#9a6afd] hover:opacity-85 mr-2" /> */}
                      {/* {row?.status === "SIGNED" ? "Download" : "Sign Now"} */}
                      {getButtonText({
                        documentStatus: row?.documentStatus,
                        status: row?.status,
                        isPayer: row?.isPayer,
                        isPayment: row?.isPayment,
                      })}
                    </button>
                  )}
              </div>
            );
          })}
        </div>
      </div>

      {/* //? condition was previously like this searchResults?.message === "No result found for this search" */}

      {fetchedDocuments?.length === 0 && !loading ? (
        <div className="w-full flex flex-col items-center py-12 text-gray-600">
          {fetchedDocuments?.length === 0 && filter !== "INACTIVE" ? (
            // <NoDocuments navLink={"/create"} helpers={{ sharedAdmins }} />
            <NoDocumentsV1
              resourceType="ASSIGNED2ME"
              cb={() => {
                if (workSpaceDetails?.currentWorkSpaceRole !== "readonly") {
                  navigate("/create");
                } else {
                  toast.error("Readonly role are not allowed to create files");
                }
              }}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div className="flex flex-row justify-between items-center px-3 md:px-5 py-3 border-t md:border-t-2">
          <div className="flex gap-2 items-center">
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              onClick={() => setPage(page - 1)}
              disabled={page === 1 || searchText.trim().length > 0}
              // className="mr-2 py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
            >
              Previous
            </BoloButton>
            <BoloButton
              size={"sm"}
              variant="gray-shadow"
              disabled={
                page * PAGE_LIMIT >= formsCount || searchText.trim().length > 0
              }
              onClick={() => setPage(page + 1)}
              // className="py-2 px-4 border rounded-md text-xs md:text-sm font-medium border-gray-300 text-gray-500 cursor-pointer hover:opacity-90 "
            >
              Next
            </BoloButton>
          </div>
          <div className="text-gray-500 text-xs md:text-sm">
            {" "}
            <div>{`Page ${page} of ${Math.ceil(formsCount / PAGE_LIMIT)}`}</div>
          </div>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />

      {/* <Tooltip id="assigned-tooltip">
        <div
          className="text-xs"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <p>
            <span>You will be able to download </span>
            <span>when all parties have signed!</span>
          </p>
          <p>
            <span>Note: Documents signed before 7th September </span>
            <span>are not available to download</span>
          </p>
        </div>
      </Tooltip> */}
    </div>
  );
};

export default AssignedToMe;
