import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

export const buttonVariants = cva(
  "cursor-pointer border disabled:opacity-50 hover:opacity-80 shadow hover:shadow",
  {
    variants: {
      variant: {
        primary: "text-white bg-primary border-primary",
        secondary: "bg-white text-primary border-slate-200",
        tertiary: "text-primary bg-secondary border-primary",
        danger: "text-white bg-red-600 border-red-600",
        success: "text-white bg-green-600 border-green-600",
        "danger-border": "text-red-600 border-red-600 bg-red-50",
        "success-border": "text-green-600 border-green-600 bg-green-50",
        "bolo-primary": "text-white bg-primary-bolo border-primary-bolo",
        "bolo-secondary": "bg-white text-primary-bolo border-primary-bolo",
        "bolo-tertiary":
          "bg-secondary-bolo text-primary-bolo border-primary-bolo",
        "gray-primary":
          "hover:bg-gray-100 text-primary border-transparent shadow-none hover:shadow-none",
        gray: "hover:bg-gray-100 text-gray-600 border-transparent shadow-none hover:shadow-none",
        "gray-shadow": "hover:bg-gray-100 text-gray-600 border-transparent",
        // can have here branded variants
        "gray-secondary-border": "bg-white text-gray-600 border-slate-400",
        yellow: "bg-yellow-400 font-semibold !text-black border-yellow-400",
      },
      size: {
        xxs: "px-1 py-0.5 text-xs rounded",
        xs: "px-2 py-1 text-xs rounded",
        sm: "px-2 sm:px-3 py-1   sm:py-1.5 sm:text-sm text-xs rounded",
        md: "px-3 sm:px-4 py-1.5 sm:py-2   sm:text-base text-sm rounded-md",
        lg: "px-4 sm:px-5 py-2   sm:py-2.5 sm:text-lg text-sm rounded-lg",
        "icon-xs": "p-1                         text-xs rounded",
        "icon-sm": "p-1   sm:p-1.5 sm:text-sm   text-xs rounded",
        "icon-md": "p-1.5 sm:p-2   sm:text-base text-lg rounded",
        "icon-lg": "p-2   sm:p-2.5 sm:text-lg   text-xl rounded",
        // xl: "",
      },
      orientation: {
        default: "",
        center: "flex items-center justify-center",
      },
    },
    // compoundVariants: [{ intent: "primary", size: "md", class: "uppercase" }],
    defaultVariants: {
      variant: "primary",
      size: "md",
      orientation: "default",
    },
  }
);

export interface BoloButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const BoloButton = React.forwardRef<HTMLButtonElement, BoloButtonProps>(
  ({ className, variant, size, orientation, ...props }, ref) => (
    <button
      ref={ref}
      className={buttonVariants({ variant, size, className, orientation })}
      {...props}
    />
  )
);

BoloButton.displayName = "BoloButton";

export { BoloButton };
