import React, { useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { MdClose, MdDelete, MdImage } from "react-icons/md";
import { toast } from "react-toastify";
import { v4 } from "uuid";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/Common/BoloSelect/BoloSelect";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/Common/Dialog/BoloDialog";
import {
  BoloInput,
  BoloTextarea,
} from "../../components/Common/Input/BoloInput";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/Common/SelectDropDown/BoloDropDown";
import { CURRENCIES } from "../../constants/currencies";
import { BOLO_CURRENCIES } from "../../constants/currency";
import useQuoteBuilder, {
  CreateFunctionStateType,
  CreateProductFuncType,
  DeleteProductFuncType,
  ProductCategoryType,
  ProductType,
} from "../../hooks/reduxHooks/useQuoteBuilder";
import { filesToDataURIs } from "../../utils/uiUtils";
import { ProductState } from "./ProductCatalog";

const UploadImage = ({
  files,
  setFiles,
}: {
  files: File[] | null;
  setFiles: (file: File[] | null) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localFiles, setLocalFiles] = useState<File[] | null>(null);

  function handleFileChange(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.DragEvent<HTMLLabelElement>
  ) {
    let files: FileList | null = null;

    if (event.target instanceof HTMLInputElement && event.target.files) {
      // If file input triggered the change event
      files = event.target.files;
      //@ts-ignore
    } else if (event.dataTransfer) {
      // If drag and drop triggered the change event
      event.preventDefault();
      //@ts-ignore
      files = event.dataTransfer.files;
    }

    if (files) {
      // if (files.length > 1) {
      //   return toast.error("Only One file accepted");
      // }

      const fileArray: File[] = Array.from(files);
      for (let file of fileArray) {
        if (!file.type?.includes("image")) {
          return toast.error("Only Image file type accepted");
        }
      }

      setLocalFiles(fileArray);
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <BoloButton size={"xs"} variant="tertiary">
          Upload Image
        </BoloButton>
      </DialogTrigger>
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Upload Images</DialogTitle>
        </DialogHeader>

        <div>
          <label
            htmlFor="dropzone-file"
            className={`notranslate flex flex-col items-center justify-center p-8 border-2 border-gray-300 hover:border-gray-500 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 w-full text-gray-600 transition-all ease-in-out duration-150 ${
              localFiles ? "bg-secondary/80 border-primary/80" : ""
            } `}
            onDrop={(e) => {
              handleFileChange(e);
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
          >
            <p
              className={`text-base font-semibold ${
                localFiles && localFiles?.length
                  ? "text-primary"
                  : "text-gray-600"
              }`}
            >
              {localFiles && localFiles?.length > 0 ? (
                <div className="flex gap-5 items-center">
                  {" "}
                  <MdImage size={25} />
                  {localFiles?.[0]?.name}
                </div>
              ) : (
                <div className="flex gap-5 items-center">
                  {" "}
                  <MdImage size={25} /> Please Select an Image File
                </div>
              )}
            </p>
            <input
              id="dropzone-file"
              type="file"
              className="appearance-none hidden disabled:opacity-60"
              onChange={handleFileChange}
              accept={"image/*"}
            />
          </label>
        </div>

        <DialogFooter>
          <BoloButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => {
              setLocalFiles(null);
              setIsOpen(false);
            }}
          >
            Cancel
          </BoloButton>

          <BoloButton
            variant={"primary"}
            size={"sm"}
            onClick={() => {
              setFiles(localFiles);
              setLocalFiles(null);
              setIsOpen(false);
            }}
          >
            Upload
          </BoloButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const ProductInfo = ({
  productState,
  createProductFunc,
  setProductState,
}: {
  createProductFunc: CreateProductFuncType;
  productState: ProductState;
  setProductState: React.Dispatch<React.SetStateAction<ProductState>>;
}) => {
  const { category } = useQuoteBuilder({});
  const [images, setImages] = useState<File[] | null>(null);
  const [imageData, setImageData] = useState<string[]>([]);
  const [productDetails, setProductDetails] = useState<ProductType>(
    productState?.productDetails
      ? productState?.productDetails
      : {
          name: "",
          productId: v4(),
          SKU: "",
          description: "",
          category: "",
          subCategroy: "",
          pricing: {
            price: 0,
            cost: 0,
            currencyCode: "",
            period: "WEEKLY",
            pricingMethod: "FIXED",
          },
          images: [],
        }
  );
  const [productErrors, setProductError] = useState({
    name: "",
    SKU: "",
    cost: "",
    price: "",
    currencyCode: "",
  });

  const handleInputChange = (e: any) => {
    // console.log(e);
    // console.log({ name: e.target.name });
    setProductDetails((prev) => {
      const clonedDetails: ProductType = structuredClone(prev);
      //@ts-ignore
      clonedDetails[e.target.name] = e.target.value;

      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let isError = false;
    if (!productDetails.name) {
      setProductError((prev) => ({
        ...prev,
        name: "Product name is required",
      }));
      toast.error("Product name is required");
      isError = true;
    }
    if (!productDetails.SKU) {
      setProductError((prev) => ({
        ...prev,
        SKU: "Product SKU is required",
      }));
      toast.error("Product SKU is required");
      isError = true;
    }
    if (!productDetails.pricing.currencyCode) {
      setProductError((prev) => ({
        ...prev,
        currencyCode: "Currency Code is required",
      }));
      toast.error("Currency Code is required");
      isError = true;
    }
    if (
      productDetails.pricing.cost === "" ||
      isNaN(Number(productDetails.pricing.cost)) ||
      Number(productDetails.pricing.cost) < 0
    ) {
      setProductError((prev) => ({
        ...prev,
        cost: "Product cost is must be a positive number",
      }));
      toast.error("Product cost is must be a positive number");
      isError = true;
    }
    if (
      productDetails.pricing.price === "" ||
      isNaN(Number(productDetails.pricing.price)) ||
      Number(productDetails.pricing.price) < 0
    ) {
      setProductError((prev) => ({
        ...prev,
        price: "Product price is must be a positive number",
      }));
      toast.error("Product price is must be a positive number");
      isError = true;
    }
    if (isError) {
      return;
    }

    try {
      const data = await createProductFunc({
        product: { ...productDetails },
        images: images ? images : [],
      });

      // console.log(data);
      if (data?.data?.success) {
        setProductState((prev) => ({
          ...prev,
          show: "PROD_TABLE",
          productDetails: null,
        }));
      }
    } catch (error) {
      console.log("Error in handleSubmit");
      console.log(error);
    }
  };

  const generateDataURI = async () => {
    if (images) {
      setImageData(await filesToDataURIs(images));
    }
  };

  useEffect(() => {
    generateDataURI();
  }, [images]);

  useEffect(() => {
    setImageData(productDetails?.images ? productDetails?.images : []);
  }, [productDetails]);

  useEffect(() => {
    setProductDetails(
      productState?.productDetails
        ? productState?.productDetails
        : {
            name: "",
            productId: v4(),
            SKU: "",
            description: "",
            category: "",
            subCategroy: "",
            pricing: {
              price: 0,
              cost: 0,
              currencyCode: "",
              period: "WEEKLY",
              pricingMethod: "FIXED",
            },
            images: [],
          }
    );
  }, [productState]);

  return (
    <div className="">
      <form onSubmit={handleSubmit} className="flex flex-col w-full">
        <div className="flex justify-between items-center w-full mb-4">
          <p className="font-semibold text-gray-600">
            {productState?.productDetails?.productId
              ? productState?.productDetails?.name
              : "New Product"}
          </p>
          <div className="flex gap-2 items-center">
            <Dialog>
              <DialogTrigger asChild>
                <BoloButton
                  size={"xs"}
                  variant="secondary"
                  onClick={() => {
                    // setProductState((prev) => ({ ...prev, show: "PROD_TABLE" }))
                  }}
                >
                  Discard
                </BoloButton>
              </DialogTrigger>
              <DialogContent className="">
                <DialogHeader>
                  <DialogTitle>Discard Changes</DialogTitle>
                </DialogHeader>
                <div>
                  <p>
                    You are about do discard any changes made to the product
                  </p>
                  <p>You wish to really discard the changes ?</p>
                </div>
                <DialogFooter>
                  <DialogClose variant={"secondary"} size={"sm"}>
                    Cancel
                  </DialogClose>
                  <BoloButton
                    variant={"danger-border"}
                    size={"sm"}
                    onClick={() => {
                      setProductState((prev) => ({
                        ...prev,
                        show: "PROD_TABLE",
                      }));
                    }}
                  >
                    Discard Changes
                  </BoloButton>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <BoloButton size={"xs"} variant="primary" type="submit">
              Save
            </BoloButton>
          </div>
        </div>

        <div className="rounded shadow-md my-2 p-4">
          <div className="font-semibold text-gray-500 text-sm mb-4">
            Product Information
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className=" w-full">
              <label
                htmlFor="name"
                className="block text-xs text-gray-600 uppercase mt-1"
              >
                NAME
              </label>
              <BoloInput
                type="text"
                id="name"
                name="name"
                className="w-full"
                bolosize={"xs"}
                value={productDetails.name}
                onChange={handleInputChange}
                required
                placeholder="Enter Product Name"
              />
            </div>
            <div className=" w-full">
              <label
                htmlFor="SKU"
                className="block text-xs text-gray-600 uppercase mt-1"
              >
                SKU
              </label>
              <BoloInput
                type="text"
                id="SKU"
                name="SKU"
                className="w-full"
                bolosize={"xs"}
                value={productDetails.SKU}
                onChange={handleInputChange}
                required
                placeholder="Enter Product SKU"
              />
            </div>
            <div className="w-full">
              <p className="block text-xs text-gray-600 uppercase mt-1">
                CATEGORY
              </p>
              <Select
                required
                value={productDetails.category}
                onValueChange={(value) => {
                  setProductDetails((prev) => ({
                    ...prev,
                    category: value,
                  }));
                }}
              >
                <SelectTrigger className="w-full ">
                  <SelectValue placeholder="Select Category" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {/* <SelectLabel>Currency Code</SelectLabel> */}
                    {category?.categories?.map(
                      (cat: ProductCategoryType, idx: number) => {
                        return (
                          <SelectItem value={cat.category}>
                            <p className="text-gray-500 text-xs">
                              {cat?.category}
                            </p>
                          </SelectItem>
                        );
                      }
                    )}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            {/* <div className="w-full">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className="text-xs text-gray-500 cursor-pointer hover:opacity-70 border-b-2 ">
                    <p className="block text-xs text-gray-600 uppercase mt-1">
                      Category
                    </p>
                    <div className="flex justify-between">
                      <p className={`py-1 text-black`}>
                        {productDetails.category || "No Category"}
                      </p>
                      <FaCaretDown className="my-auto mr-1" />
                    </div>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuGroup>
                  <DropdownMenuContent
                    align="start"
                    sideOffset={5}
                    className="min-w-[100%] max-h-[200px] overflow-y-auto"
                    id="mini-pdf-custom-scroll"
                  >
                    {category?.categories?.map(
                      (cat: ProductCategoryType, idx: number) => {
                        return (
                          <DropdownMenuItem
                            onClick={() =>
                              setProductDetails((prev) => ({
                                ...prev,
                                category: cat.category,
                              }))
                            }
                          >
                            <p className="text-gray-500 text-xs">
                              {cat?.category}
                            </p>
                          </DropdownMenuItem>
                        );
                      }
                    )}
                  </DropdownMenuContent>
                </DropdownMenuGroup>
              </DropdownMenu>
            </div> */}

            <div className="w-full">{/* for sub category */}</div>

            <div className=" w-full col-span-2">
              <label
                htmlFor="description"
                className="block text-xs text-gray-600 uppercase mt-1 mb-1"
              >
                DESCRIPTION
              </label>
              <BoloTextarea
                id="description"
                name="description"
                className="w-full"
                bolosize={"xs"}
                value={productDetails.description}
                onChange={handleInputChange}
                placeholder="Enter Product Description"
              />
            </div>
          </div>
        </div>

        <div className="rounded shadow-md my-2 p-4">
          <div className="font-semibold text-gray-500 text-sm mb-4">
            Pricing
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className=" w-full">
              <label
                htmlFor="pricing.cost"
                className="block text-xs text-gray-600 uppercase mt-1"
              >
                COST
              </label>
              <BoloInput
                type="number"
                min={"0"}
                id="pricing.cost"
                name="pricing.cost"
                className="w-full"
                bolosize={"xs"}
                value={productDetails.pricing.cost}
                onChange={(e) => {
                  setProductDetails((prev) => ({
                    ...prev,
                    pricing: { ...prev.pricing, cost: e.target.value },
                  }));
                }}
                required
                placeholder="Enter Product Cost"
              />
            </div>
            <div className=" w-full">
              <label
                htmlFor="pricing.price"
                className="block text-xs text-gray-600 uppercase mt-1"
              >
                PRICE
              </label>
              <BoloInput
                type="number"
                min={"0"}
                id="pricing.price"
                name="pricing.price"
                className="w-full"
                bolosize={"xs"}
                value={productDetails.pricing.price}
                onChange={(e) => {
                  setProductDetails((prev) => ({
                    ...prev,
                    pricing: { ...prev.pricing, price: e.target.value },
                  }));
                }}
                required
                placeholder="Enter Product Cost"
              />
            </div>
            {/* can be changed later */}
            <div className="w-full">
              <p className="block text-xs text-gray-600 uppercase mt-1">
                CURRENCY
              </p>
              <Select
                required
                value={productDetails.pricing.currencyCode}
                onValueChange={(value) => {
                  setProductDetails((prev) => ({
                    ...prev,
                    pricing: {
                      ...prev.pricing,
                      currencyCode: value,
                    },
                  }));
                }}
              >
                <SelectTrigger className="w-full ">
                  <SelectValue placeholder="Select Currency" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {/* <SelectLabel>Currency Code</SelectLabel> */}
                    {BOLO_CURRENCIES?.map((cur, idx) => {
                      return (
                        <SelectItem value={cur.code}>
                          <div className="flex gap-2 text-gray-500 text-xs">
                            <div className="font-semibold pr-2 w-10">
                              {cur.code}
                            </div>
                            <div className=" px-2">{cur.currency}</div>
                          </div>
                        </SelectItem>
                      );
                    })}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        <div className="rounded shadow-md my-2 p-4">
          <div className="flex justify-between font-semibold text-gray-500 text-sm mb-4">
            Product Image
            <UploadImage files={images} setFiles={setImages} />
          </div>
          <div className="flex gap-2">
            {imageData.map((img, idx) => (
              <div className="relative p-1">
                {/* <MdClose
                  className="absolute  cursor-pointer right-1 top-1"
                  onClick={() => {
                    let imagesClone = images;
                    let imageDataClone = imageData;
                    imagesClone?.splice(idx, 1);
                    imageDataClone?.splice(idx, 1);
                    setImages(imagesClone);
                    setImageData(imageDataClone);
                  }}
                /> */}
                <img src={img} className="max-h-[80px]" />
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProductInfo;
