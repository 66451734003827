import { useEffect, useMemo, useRef, useState } from "react";
import { FiCheckCircle, FiCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BoloButton } from "../Common/Button/BoloButton";
import { useAppSelector } from "../../redux/store";
import { FaCircleCheck } from "react-icons/fa6";

const ExtendTrialSetup = ({ showDropdown }: { showDropdown: boolean }) => {
  const navigate = useNavigate();

  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);

  const currentPlan = owner?.planhistory?.[owner?.planhistory?.length - 1];

  const collaborators = owner?.collaborators;

  const collaboratorAlreadyAdded = owner?.collaboratorAlreadyAdded;
  const isBranding =
    owner?.accountInfo?.companyLogo || owner?.accountInfo?.theme || false;

  let { usedQuanity, usageQuantityAllowed } = currentPlan;

  const countDaysRemaining = () => {
    let trialEndDate = currentPlan?.trialEndDate;
    let differenceInDays = 7;
    if (trialEndDate) {
      const givenDate = new Date(trialEndDate).getTime();
      const currentDate = new Date().getTime();
      const differenceInMs = givenDate - currentDate;
      differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
    } else {
      const givenDate = new Date(owner?.createdAt).getTime();
      const currentDate = new Date().getTime();
      const differenceInMs = currentDate - givenDate;
      differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    }
    return differenceInDays;
  };

  const remainingSteps = useMemo(() => {
    return [
      {
        title: "Create a form",
        description: "Create a form with Boloforms.",
        isDone: usedQuanity[1] > 0,
        link: "/dashboard/forms",
        extendableDays: 1,
      },
      {
        title: "Send a PDF for signing",
        description: "Send a PDF for signing to your user",
        isDone: usedQuanity[0] > 0,
        link: "/dashboard/pdfs",
        extendableDays: 1,
      },
      {
        title: "Custom branding",
        description: "Customize your workspace with your brand",
        isDone: isBranding,
        link: "/settings?type=BRANDING&subType=",
        extendableDays: 1,
      },
      {
        title: "Invite a collaborator",
        description: "Add a collaborator to your workspace to work together",
        isDone: collaboratorAlreadyAdded,
        link: "/teams",
        extendableDays: 1,
      },
      {
        title: "Add custom PDF to your form",
        description: "Customize your form with a PDF template",
        isDone: usedQuanity[3] > 0,
        extendableDays: 1,
        link: "/dashboard/forms",
      },
      {
        title: "Send a PDF Template for signing",
        description: "Send a PDF Template for signing to your user",
        isDone: usedQuanity[5] > 0,
        link: "/dashboard/templates",
        extendableDays: 1,
      },
    ];
  }, [usedQuanity, isBranding, collaboratorAlreadyAdded]);
  // Your component logic here

  const [showSetupSteps, setShowSetupSteps] = useState(false);

  useEffect(() => {
    setShowSetupSteps(showDropdown);
    setShowSetupStepsMobile(showDropdown);
  }, [showDropdown]);

  const [showSetupStepsMobile, setShowSetupStepsMobile] = useState(false);

  const isAllCompleted = remainingSteps.every((step) => step.isDone);

  const setupDivRef = useRef(null);
  const initializerRef = useRef(null);
  const mobileInitializerRef = useRef(null);

  const SetupOptions = ({ initializerRef }: any) => {
    const handleOutsideClickNew = (e: any) => {
      console.log(initializerRef);
      if (initializerRef?.current !== null) {
        if (initializerRef?.current.contains(e.target)) return;
      }
      setShowSetupSteps(false);
      setShowSetupStepsMobile(false);
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClickNew);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClickNew);
      };
    }, []);

    return (
      <div
        className="text-sm pb-4 text-gray-700 p-4 font-medium flex flex-col gap-6 shadow-2xl absolute z-50 top-0 right-0 mt-[43px] ml-2 bg-white rounded-lg overflow-hidden w-[90%] md:w-[350px] animate-fade-down animate-ease-in-out"
        ref={setupDivRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="mb-2 font-normal text-left">
          Completing each step will increase your free trial{" "}
          <span className="font-semibold">period by 1 day.</span>
        </p>
        {remainingSteps.map((step, index) => {
          return (
            <div
              key={index}
              className="flex flex-col gap-1 "
              onClick={() => {
                if (step.isDone) return;
                if (step.link) {
                  setShowSetupSteps(false);
                  navigate(step.link);
                }
              }}
            >
              <p className="flex items-center gap-2 text-left">
                {step.isDone ? (
                  <FaCircleCheck className="text-emerald-600" size={18} />
                ) : (
                  <FiCircle className="text-gray-500" size={18} />
                )}
                <p className={`${step.isDone ? "line-through" : ""} `}>
                  {step.title}
                </p>
                <span
                  className={`font-medium whitespace-nowrap  px-3 py-0.5 rounded-md  text-[10px] ml ${
                    step.isDone
                      ? "animate-pulse bg-emerald-600 text-white"
                      : "bg-gray-200 text-gray-500"
                  }  `}
                >
                  +{step.extendableDays || 1} days
                </span>
              </p>
              <p
                className={`flex items-center font-normal pl-6 text-gray-500 text-xs text-left ${
                  step.isDone ? "line-through text-gray-400" : ""
                } `}
              >
                {step.description}
              </p>
            </div>
          );
        })}

        <p className="p-2 bg-green-50 border border-emerald-500 rounded-lg text-emerald-600 font-normal text-center shadow-md">
          {" "}
          You have{" "}
          <span className="font-bold text-xl">{countDaysRemaining()}</span> days
          remaining of your trial
        </p>
      </div>
    );
  };

  return (
    <>
      <div
        className="flex md:hidden items-center justify-center bg-black rounded-full w-6 h-6 text-xs font-medium text-center ml-2 "
        onClick={(e) => {
          e.stopPropagation();
          setShowSetupStepsMobile(!showSetupStepsMobile);
        }}
        ref={mobileInitializerRef}
      >
        <span className="mt-[2px]">
          {remainingSteps.filter((step) => step.isDone).length}/
          {remainingSteps.length}
        </span>
        {showSetupStepsMobile && (
          <SetupOptions initializerRef={mobileInitializerRef} />
        )}
      </div>
      <div
        className="hidden md:flex relative w-[300px] md:w-[350px] bg-black px-2 rounded-md border-b-[3px] border-gray-200 items-center justify-between text-xs ml-2 pt-[2px] h-[40px] cursor-pointer mr-4"
        onClick={(e) => {
          e.stopPropagation();
          setShowSetupSteps(!showSetupSteps);
        }}
        ref={initializerRef}
      >
        {!isAllCompleted ? (
          <span className="font-medium ">Extend Trial</span>
        ) : (
          <span className="font-medium ">Boloforms Trial Unlocked 🎉 </span>
        )}
        <div className="">
          <span className="pr-2 border-r ">Steps</span>
          <span className="pl-2">
            {remainingSteps.filter((step) => step.isDone).length}/
            {remainingSteps.length}
          </span>
        </div>
        {showSetupSteps && <SetupOptions initializerRef={initializerRef} />}
      </div>
    </>
  );
};

export default ExtendTrialSetup;
