// @ts-nocheck
import { ImDrive } from "react-icons/im";
import { FaFolder } from "react-icons/fa";
import { FaFile } from "react-icons/fa6";
import { useEffect, useState } from "react";
import BoloModal from "../Common/Modal/BoloModal";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import BoloLoader from "../Common/Loader/BoloLoader";
import { MdOutlineNavigateNext } from "react-icons/md";

const OnedriveModal = ({
  setShowOnedriveModal,
  ownerEmail,
  isSignature = false,
  onFileUpload = () => {},
  setFileName,
  isOnlyPdfTemplate = false,
  setIsDriveFileUploading = () => {},
  setFile,
}) => {
  const [loading, setLoading] = useState(false);
  const [drives, setDrives] = useState([]);
  const [driveItems, setDriveItems] = useState([]);
  const [path, setPath] = useState([]);
  // const dispatch = useAppDispatch();

  const fetchUsersDrive = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/microsoft/getAllDrives?ownerEmail=${ownerEmail}`
      );
      if (data?.success) {
        setDrives(data?.data);
      } else {
        toast.error("Request failed! Try again.");

        setShowOnedriveModal(false);
      }
      setLoading(false);
    } catch (error) {
      toast.error("Request failed! Try again.");

      setShowOnedriveModal(false);
    }
  };
  const getDriveItems = async (drive) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/microsoft/driveItems?ownerEmail=${ownerEmail}&driveId=${drive?.id}`
      );
      if (data?.success && data?.newData) {
        setDriveItems(data?.newData);
        setLoading(false);
        setDrives([]);
      } else {
        setLoading(false);
        toast.error("Request failed! Try again.");
      }
    } catch (error) {
      toast.error("Error while fecthing drive items");
    }
  };
  const fetchFolderItems = async (folder) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/microsoft/folderItems?ownerEmail=${ownerEmail}&folderId=${folder?.id}&driveId=${folder?.driveId}`
      );
      if (data?.success) {
        setDriveItems(data?.newData);
        setLoading(false);
        setDrives([]);
      } else {
        setLoading(false);
        toast.error("Request failed! Try again.");
      }
    } catch (error) {
      toast.error("Error while fecthing drive items");
    }
  };
  const disconnectOnedrive = async () => {
    try {
      const { data } = await axios.post("/microsoft/uninstall", { ownerEmail });
      if (data?.success) {
        toast.success("Microsoft Uninstalled!");
        setShowOnedriveModal(false);
        setIsDriveFileUploading(false);
        window.location.reload();
        // dispatch(getOwnerThunk());
      }
    } catch (error) {
      toast.error("Error while disconnecting!");
    }
  };
  useEffect(() => {
    fetchUsersDrive();
    // dispatch(getOwnerThunk());
  }, []);
  return (
    <>
      <BoloModal
        content={
          <div className="min-h-[600px]">
            <div className="text-lg md:text-xl mb-2 font-semibold ">
              OneDrive
            </div>

            <div className="gap-2 flex  items-center justify-between text-sm text-gray-600 mb-4">
              <p>Upload your own file and convert into an editable file</p>
              <div
                className="text-xs pl-1 text-gray-400 hover:underline cursor-pointer hover:text-red-400"
                onClick={disconnectOnedrive}
              >
                Disconnect
              </div>
            </div>
            {loading ? (
              <div className="mt-60">
                <BoloLoader height={40} width={40} />
              </div>
            ) : (
              <>
                {path.length > 0 && (
                  <div className="text-xs md:text-sm  flex gap-1 ">
                    <div
                      onClick={() => {
                        setPath([]);
                        fetchUsersDrive();
                        setDriveItems([]);
                      }}
                      className="hover:underline cursor-pointer"
                    >
                      My drives
                    </div>
                    {path?.map((ele, index) => {
                      if (index === path?.length - 1) {
                        return <></>;
                      }
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (ele?.type === "drive") {
                              getDriveItems(ele);
                            } else if (ele?.type === "Folder") {
                              fetchFolderItems(ele);
                            }
                            setPath((prevArray) =>
                              prevArray.slice(0, index + 1)
                            );
                          }}
                          className="flex items-center hover:underline cursor-pointer"
                        >
                          <MdOutlineNavigateNext className="text-black text-xs" />{" "}
                          {ele?.name}
                        </div>
                      );
                    })}
                  </div>
                )}
                {drives?.length > 0 && (
                  <div className="overflow-y-auto">
                    <div className="text-base mb-2 mt-3 font-semibold ">
                      My drives
                    </div>
                    {drives.map((drive, index) => (
                      <div
                        key={index}
                        className="flex gap-2 items-center border-t py-3 hover:bg-gray-100 cursor-pointer px-2 text-sm"
                        onClick={() => {
                          let newdrive = { ...drive, type: "drive" };
                          if (!drive?.name) {
                            newdrive = { ...newdrive, name: "Untitled drive" };
                          }
                          setPath((prev) => [...prev, newdrive]);

                          setLoading(true);
                          getDriveItems(drive);
                        }}
                      >
                        <ImDrive className="text-blue-500" />
                        {drive?.name || "Untitled drive"}
                      </div>
                    ))}
                  </div>
                )}
                {driveItems?.length > 0 && (
                  <div className="overflow-y-auto" id="mini-pdf-custom-scroll">
                    <div className="text-base mb-2  font-semibold ">
                      {path?.[path?.length - 1]?.name || "Untitled"}
                    </div>
                    <div className="text-gray-400 flex border-b items-center text-xs pb-3 justify-between px-3 ">
                      {" "}
                      <span>TITLE</span> <span>SIZE</span>
                    </div>

                    {driveItems.map((item, index) => {
                      let typeFolder = item?.type === "Folder" ? true : false;
                      if (typeFolder) {
                        return (
                          <div
                            key={index}
                            className="flex gap-2 items-center border-b  py-3 hover:bg-gray-100 cursor-pointer px-2 text-sm"
                            onClick={() => {
                              setPath((prev) => [...prev, item]);

                              setLoading(true);
                              fetchFolderItems(item);
                            }}
                          >
                            <FaFolder className="text-blue-500" />

                            {item?.name}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={index}
                            className="flex justify-between items-center border-b py-3 hover:bg-gray-100 cursor-pointer px-2 text-sm"
                            onClick={async () => {
                              const response = await fetch(item?.downloadUrl);
                              const blob = await response.blob();
                              const file = new File([blob], item?.name, {
                                type: "application/pdf",
                              });
                              if (file?.size > 10 * 1024 * 1024) {
                                toast.error(
                                  "Total size of the files should be less than 10 MB"
                                );
                                return;
                              }

                              setFile(file);
                              if (isSignature) {
                                onFileUpload(file);
                                setFileName && setFileName(file.name);
                              } else if (isOnlyPdfTemplate) {
                                onFileUpload && onFileUpload(file);
                              }
                              setShowOnedriveModal(false);
                              setIsDriveFileUploading(false);
                            }}
                          >
                            <div className="text-sm flex gap-2 items-center">
                              {/* <FaFile className="text-blue-500 text-xs" /> */}
                              <img
                                src={item?.thumbnail}
                                alt="thumbnail"
                                className="w-[15px] h-[20px]"
                              />
                              {item?.name}
                            </div>
                            <span className="text-sm">
                              {(item?.size / (1024 * 1024))?.toFixed(2)} MB
                            </span>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        }
        setShow={setShowOnedriveModal}
      />
    </>
  );
};

export default OnedriveModal;
