import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { FaCaretSquareRight } from "react-icons/fa";
import { IoKey } from "react-icons/io5";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { LuExternalLink, LuFileSymlink, LuSendToBack } from "react-icons/lu";
import {
  MdAttachment,
  MdClose,
  MdOutlineAlternateEmail,
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardBackspace,
  MdOutlineMailLock,
  MdOutlineSendToMobile,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { PiPasswordDuotone, PiStampDuotone } from "react-icons/pi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { TbFileUpload, TbWorldWww } from "react-icons/tb";
import { VscFilePdf } from "react-icons/vsc";
import { Slide, ToastContainer, toast } from "react-toastify";
import AddonCard from "../../components/AddonCard";
import AddonModal from "../../components/AddonUis/AddonModal";
import AddonImageSlider from "../../components/AddonUis/ImageSlider";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import { ADDON_IMAGES, IMAGE_LINKS } from "../../constants/common";
import { PAYMENTS_BASE_URL, getDashboardUrl } from "../../constants/constants";
import useIsFeatureEnabled from "../../hooks/reduxHooks/useIsFeatureEnabled";
import { getOwnerThunk } from "../../redux/slices/ownerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import axios from "../../utils/axios";
import { getOwnerPlanDetails } from "../../utils";
import { Navigate } from "react-router-dom";
// const LazyLoadedImage = React.lazy(() => import("./LazyLoadedImage")); // Import LazyLoadedImage component

type Addon = {
  name: string;
  description: string;
  icon: JSX.Element;
  planName: string;
  price: string;
  subType: string;
  isImage?: boolean;
  about: string;
  helpBlogs?: {
    name: string;
    link: string;
  }[];
  images: string[];
  featureName: string;
  isComingSoon?: boolean;
  featureRequestUrl?: string;
  extraDetails?: string[];
  isAnnual?: boolean;
  isContactUs?: boolean;
};

const addOns = [
  {
    name: "Bulk Send",
    description: "Send documents & forms to multiple recipients at once",
    icon: <LuSendToBack className="w-8 h-8 " />,
    planName: "Workflow Plus",
    price: "15",
    subType: "Workflow",
    about:
      "Bulk Send allows you to streamline your document distribution process by sending documents and forms to multiple recipients simultaneously. Whether you're managing contracts, agreements, or any other documents, Bulk Send saves you time and effort. It's an essential tool for optimizing your workflow and increasing productivity.",
    images: ADDON_IMAGES.BULK_SEND,
    featureName: "BULK_SEND",
    helpBlogs: [
      {
        name: "Send Bulk Signature Requests",
        link: "https://help.boloforms.com/en/articles/8556616-send-bulk-signature-requests",
      },
    ],
    extraDetails: [
      "Bulk Send allows to send documents to 50 recipients at once",
    ],
  },
  // {
  //   name: "E-Templates",
  //   description: "Create and send documents to be signed via a web link",
  //   icon: <LuFileSymlink className="w-8 h-8 " />,
  //   planName: "Workflow Pro",
  //   price: "15",
  //   subType: "Workflow",
  //   about:
  //     "E-Templates empower you to create and send documents to be signed conveniently via a web link. Whether you're collecting signatures for contracts, agreements, or consent forms, E-Templates make the process seamless for both you and your recipients. Say goodbye to the hassle of printing, scanning, and mailing documents, and embrace the efficiency of electronic signatures.",
  //   helpBlogs: [
  //     {
  //       name: "How to create E-Templates",
  //       link: "https://help.boloforms.com/en/articles/9214515-how-do-you-generate-a-global-link-in-a-pdf-template",
  //     },
  //   ],
  //   images: ADDON_IMAGES.E_TEMPLATE,
  //   featureName: "E_TEMPLATES",
  // },
  {
    name: "Signer Attachments",
    description: "Attach downloadable files to your documents",
    icon: <MdAttachment className="w-8 h-8 " />,
    planName: "Workflow Basic",
    price: "15",
    subType: "Workflow",
    about:
      "Signer Attachments enable you to enhance your documents by attaching downloadable files. Whether you need to include additional information, reference materials, or supporting documents, Signer Attachments provide a convenient way to enrich your documents and provide comprehensive information to your recipients.",
    images: ADDON_IMAGES.ATTACHMENTS,
    featureName: "SIGNER_ATTACHMENTS",
    isComingSoon: false,
  },
  {
    name: "Upload PDF of upto 50MB",
    description: "Upload PDF files of upto 50MB in size",
    icon: <TbFileUpload className="w-8 h-8 " />,
    planName: "Workflow Basic",
    price: "15",
    subType: "Workflow",
    about:
      "Upload PDF of upto 50MB allows you to upload PDF files of upto 50MB in size. Whether you're working with large documents, images, or other files, Upload PDF of upto 50MB provides a flexible way to handle your document management needs. Enhance your workflow and streamline your document processes with Upload PDF of upto 50MB.",
    images: ADDON_IMAGES.PDF_SIZE,
    featureName: "FIFTY_MB_PDF_SIZE",
  },
  {
    name: "Redirection Url",
    description:
      "Redirect users to a specific URL after form submission or PDF signing",
    icon: <LuExternalLink className="w-8 h-8 " />,
    planName: "Workflow Basic",
    price: "15",
    subType: "Workflow",
    about:
      "Redirection URL allows you to redirect users to a specific URL after they finish filling a form or signing a PDF. Whether you want to direct users to a thank you page, a confirmation page, or any other destination, Redirection URL provides a seamless way to guide users to the next step in their workflow. Enhance user experience and streamline your processes with Redirection URL.",
    images: ADDON_IMAGES.REDIRECTION_URL,
    featureName: "REDIRECTION_URL",
    helpBlogs: [
      {
        name: "How to use Redirection URL in forms",
        link: "https://help.boloforms.com/en/articles/8755736-redirection-url-in-forms",
      },
      {
        name: "Available settings in forms",
        link: "https://help.boloforms.com/en/articles/8557784-form-settings",
      },
    ],
  },
  {
    name: "Customized PDF Name",
    description:
      "Customize the name of the PDF generated after form submission",
    icon: <VscFilePdf className="w-8 h-8 " />,
    planName: "Workflow Basic",
    price: "15",
    subType: "Workflow",
    about:
      "Customized PDF Name allows you to personalize the name of the PDF generated after form submission. Whether you want to include the user's name, the form title, or any other information, Customized PDF Name provides a flexible way to customize the PDF file name. Enhance user experience and streamline your document management with Customized PDF Name.",
    images: ADDON_IMAGES.CUSTOM_PDF_NAME,
    featureName: "CUSTOM_PDF_NAME",
    helpBogs: [
      {
        name: "How to customize PDF name",
        link: "https://help.boloforms.com/en/articles/8735299-how-to-customize-the-name-of-pdfs-in-form-settings",
      },
      {
        name: "Available settings in forms",
        link: "https://help.boloforms.com/en/articles/8557784-form-settings",
      },
    ],
  },
  {
    name: "Quote Builder & Pricing Table",
    description:
      "Create and send quotes with a pricing table to your customers",
    icon: <LiaFileInvoiceDollarSolid className="w-8 h-8 " />,
    planName: "Workflow Basic",
    price: "15",
    subType: "Workflow",
    about:
      "Quote Builder & Pricing Table enables you to create and send quotes with a pricing table to your customers. Whether you're providing cost estimates, proposals, or quotes for your products or services, Quote Builder & Pricing Table makes it easy to present pricing information in a clear and professional manner. Impress your customers and streamline your sales process with Quote Builder & Pricing Table.",
    images: ADDON_IMAGES.PRICING_TABLE,
    featureName: "QUOTE_BUILDER",
    isComingSoon: true,
    featureRequestUrl:
      "https://boloforms.canny.io/feature-requests/p/interactive-pricing-tables",
  },
  {
    name: "Custom Email Branding",
    description: "Personalize your document invites with a custom email theme",
    icon: <MdOutlineMailLock className="w-8 h-8 " />,
    planName: "Workflow Plus",
    price: "25",
    subType: "Workflow",
    about:
      "Custom Email Branding allows you to personalize your document invites with a custom email theme. Whether you're sending contracts, agreements, or other documents, Custom Email Branding provides a professional and branded appearance for your email communications. Enhance your brand identity and impress your recipients with Custom Email Branding.",
    images: ADDON_IMAGES.CUSTOM_EMAIL_BRANDING,
    featureName: "EMAIL_BRANDING",
    helpBlogs: [
      {
        name: "Customizing email settings in the dashboard",
        link: "https://help.boloforms.com/en/articles/8878399-customizing-email-settings-in-the-dashboard",
      },
    ],
  },
  {
    name: "Custom Email Domain",
    description: "Send document invites from your own email domain",
    icon: <MdOutlineAlternateEmail className="w-8 h-8 " />,
    planName: "Workflow Plus",
    price: "15",
    subType: "Workflow",
    about:
      "Custom Email Domain allows you to personalize your document invites by sending them from your own email domain. Impress your recipients with professional and branded communication while maintaining consistency across your workflow. With Custom Email Domain, you can strengthen your brand identity and enhance the credibility of your documents.",
    images: ADDON_IMAGES.CUSTOM_EMAIL_DOMAIN,
    featureName: "DNS_SETTING",
  },
  {
    name: "Custom Domain Name",
    description:
      "Strengthen your online presence with a custom domain name for your document management.",
    icon: <TbWorldWww className="w-8 h-8" />,
    planName: "Workflow Plus",
    price: "49",
    subType: "Workflow",
    about:
      "Custom Domain Name allows you to use your own domain for accessing and managing your documents, providing a seamless and professional experience for your users. Whether you're sharing contracts, agreements, or other important documents, using a custom domain enhances your brand's credibility and ensures a cohesive online presence. Impress your clients and stakeholders with a branded domain that reflects your company's identity.",
    images: ADDON_IMAGES.CUSTOM_DOMAIN,
    featureName: "CUSTOM_DOMAIN_SETTINGS",
    isContactUs: true,
    isComingSoon: true,
  },
  {
    name: "Online Notary",
    description:
      "Let your notaries conduct online notarization transactions from anywhere",
    icon: <PiStampDuotone className="w-8 h-8 " />,
    planName: "Tracking Pro",
    price: "30",
    subType: "Tracking",
    about:
      "Online Notary revolutionizes the notarization process by enabling your notaries to conduct online transactions from anywhere. Whether you're finalizing contracts, agreements, or legal documents, Online Notary provides a secure and convenient platform for notarizing documents remotely. Say goodbye to traditional notarization hassles and embrace the efficiency of online notarization with confidence.",
    images: ADDON_IMAGES.ONLINE_NOTARY,
    featureName: "ONLINE_NOTARY",
    isComingSoon: true,
    featureRequestUrl:
      "https://boloforms.canny.io/feature-requests/p/online-notary",
  },
  {
    name: "SMS Authentication",
    description:
      "Add an extra layer of security to your documents with SMS authentication",
    icon: <MdOutlineSendToMobile className="w-8 h-8  " />,
    planName: "Tracking Plus",
    price: "15",
    subType: "Tracking",
    about:
      "SMS Authentication enhances the security of your documents by adding an extra layer of authentication through SMS. Protect sensitive information and ensure that only authorized individuals can access and sign your documents. With SMS Authentication, you can safeguard your documents and maintain the integrity of your workflow.",
    images: ADDON_IMAGES.OTP_AUTH,
    featureName: "SMS_AUTHENTICATION",
    isComingSoon: false,
    featureRequestUrl:
      "https://boloforms.canny.io/feature-requests/p/sending-signing-links-trough-sms",
  },
  {
    name: "Recipient Verification",
    description:
      "Add an extra layer of security to your documents with Access Code verification",
    icon: <IoKey className="w-8 h-8  " />,
    planName: "Tracking Basic",
    price: "15",
    subType: "Tracking",
    about:
      "Recipient Verification strengthens the security of your documents by implementing Access Code verification. Ensure that only intended recipients can access and sign your documents by requiring them to enter a unique Access Code. With Recipient Verification, you can enhance the confidentiality and integrity of your document transactions.",
    images: ADDON_IMAGES.ACCESS_CODE,
    featureName: "ACCESS_CODE",
    helpBlogs: [
      {
        name: "How to use Access Code Verification",
        link: "https://help.boloforms.com/en/articles/9323342-how-do-you-protect-your-pdf-with-an-otp",
      },
    ],
  },
  {
    name: "Password Protected PDF",
    description:
      "Protect your PDF documents with a password to restrict unauthorized access",
    icon: <PiPasswordDuotone className="w-8 h-8  " />,
    planName: "Tracking Basic",
    price: "15",
    subType: "Tracking",
    about:
      "Password Protected PDF allows you to secure your PDF documents by adding a password to restrict unauthorized access. Protect sensitive information and ensure that only authorized individuals can view and sign your documents. With Password Protected PDF, you can safeguard your documents and maintain the confidentiality of your information",
    images: ADDON_IMAGES.PASSWORD_PDF,
    featureName: "PASSWORD_PROTECTED_PDF",
    helpBlogs: [
      {
        name: "How to password protect your PDF",
        link: "https://help.boloforms.com/en/articles/9323389-how-to-create-password-protected-pdfs",
      },
    ],
  },
  {
    name: "Salesforce",
    description:
      "Integrate your documents with Salesforce to automate your workflows",
    icon: (
      <img
        src={`${IMAGE_LINKS.SALESFORCELOGO}`}
        alt="Salesforce logo"
        className="w-8"
      />
    ),
    planName: "Integration Plus",
    price: "15",
    subType: "Integrations",
    isImage: true,
    about:
      "Salesforce integration streamlines your workflows by connecting your documents with Salesforce. Automate document generation, distribution, and tracking processes directly from your Salesforce environment. With seamless integration, you can enhance collaboration, efficiency, and productivity across your organization.",
    images: ADDON_IMAGES.SALESFORCE,
    featureName: "SALESFORCE",
    isComingSoon: true,
    featureRequestUrl:
      "https://boloforms.canny.io/feature-requests/p/sending-signing-links-trough-sms",
  },
  {
    name: "Microsoft Teams",
    description:
      "The wildly popular collaboration hub that integrates people, content, and tools.",
    icon: (
      <img
        src={`${IMAGE_LINKS.MSTEAMS}`}
        alt="Microsoft Teams logo"
        className="w-8 "
      />
    ),
    planName: "Integration Plus",
    price: "15",
    subType: "Integrations",
    isImage: true,
    about:
      "Microsoft Teams integration empowers your team collaboration by seamlessly integrating documents with Microsoft Teams. Share, collaborate, and manage documents directly within your Teams environment, streamlining your workflow and enhancing productivity. With Microsoft Teams integration, you can centralize communication and collaboration efforts effortlessly.",
    images: ADDON_IMAGES.MS_TEAMS,
    featureName: "MICROSOFT_TEAMS",
    isComingSoon: true,
    featureRequestUrl:
      "https://boloforms.canny.io/feature-requests/p/ms-teams-integration",
  },
  {
    name: "Slack",
    description:
      "Slack is a collaboration hub that connects your work with the people you work with.",
    icon: (
      <img src={`${IMAGE_LINKS.SLACK}`} alt="Slack logo" className="w-8 " />
    ),
    planName: "Integration Plus",
    price: "15",
    subType: "Integrations",
    isImage: true,
    about:
      "Slack integration fosters seamless collaboration by connecting your documents with Slack. Share, discuss, and collaborate on documents directly within your Slack workspace, enhancing team communication and productivity. With Slack integration, you can streamline collaboration efforts and accelerate project workflows effortlessly.",
    images: ADDON_IMAGES.SLACK,
    featureName: "SLACK_INTEGRATION",
    isComingSoon: true,
    featureRequestUrl:
      "https://boloforms.canny.io/feature-requests/p/slack-integration",
  },
  {
    name: "Stripe",
    description:
      "Stripe is a suite of payment APIs that powers commerce for businesses of all sizes.",
    icon: (
      <img src={`${IMAGE_LINKS.STRIPE}`} alt="Stripe logo" className="w-8 " />
    ),
    planName: "Integration Plus",
    price: "15",
    subType: "Integrations",
    isImage: true,
    about:
      "Stripe integration enables secure and seamless payment processing by connecting your documents with Stripe. Accept payments, manage subscriptions, and handle transactions directly within your document workflow. With Stripe integration, you can streamline payment processes and enhance the user experience for your customers.",
    images: ADDON_IMAGES.STRIPE,
    featureName: "STRIPE_INTEGRATION",
    isComingSoon: false,
    // featureRequestUrl:
    //   "https://boloforms.canny.io/feature-requests/p/stripe-integration",
  },
];

// group the addons by subType
const groupedByAddOns = addOns.reduce((acc, addon) => {
  if (acc[addon.subType]) {
    acc[addon.subType].push(addon);
  } else {
    acc[addon.subType] = [addon];
  }
  return acc;
}, {} as Record<string, Addon[]>);

const AddonStore: React.FC = () => {
  const dispatch = useAppDispatch();

  const featuresEnabled = useIsFeatureEnabled();

  const owner = useAppSelector((state) => state.root.ownerSlice.data.owner);

  const currentPlanHistory = owner?.paymentStatus?.workSpacePlanHistory;

  useEffect(() => {
    dispatch(getOwnerThunk());
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const [showingDetailsFor, setShowingDetailsFor] = useState<Addon | null>(
    null
  );

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchClick = () => {
    setIsExpanded(!isExpanded);

    setSearchTerm("");
    setSearchedAddons({});
  };

  const [searchedAddons, setSearchedAddons] = useState<Record<string, Addon[]>>(
    {}
  );

  const [addonCart, setAddonCart] = useState<Addon[]>([]);

  const [cartPrice, setCartPrice] = useState(0);

  const [isCartOpen, setIsCartOpen] = useState(false);

  const [purchasing, setPurchasing] = useState(false);

  const [cancellingSubscription, setCancellingSubscription] = useState(false);

  // Add event listener to close cart details when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      if (isCartOpen) setIsCartOpen(false);
    };
    if (isCartOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isCartOpen]);

  useEffect(() => {
    let price = 0;
    addonCart.forEach((addon) => {
      price += parseInt(addon.price);
    });
    setCartPrice(price);

    setIsCartOpen(addonCart.length > 0);
  }, [addonCart]);

  const handleSearch = (event: any) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredAddons = addOns.filter((addon) => {
      const addonName = addon.name.toLowerCase();
      const addonDescription = addon.description.toLowerCase();
      const addonAbout = addon.about?.toLowerCase();
      return (
        addonName.includes(searchTerm) ||
        addonDescription.includes(searchTerm) ||
        (addonAbout && addonAbout.includes(searchTerm))
      );
    });
    // Update groupedByAddOns based on filtered addons
    const filteredGroupedByAddOns = filteredAddons.reduce((acc, addon) => {
      if (acc[addon.subType]) {
        acc[addon.subType].push(addon);
      } else {
        acc[addon.subType] = [addon];
      }
      return acc;
    }, {} as Record<string, Addon[]>);

    filteredAddons.length > 0
      ? setSearchedAddons(filteredGroupedByAddOns)
      : setSearchedAddons({});

    // (Optional) Update state to reflect filtered data
    // setFilteredAddons(filteredAddons);
    // setFilteredGroupedByAddOns(filteredGroupedByAddOns);
  };

  const isAnyAddonSubscribed = currentPlanHistory?.addonFeatures?.some(
    (addon: any) => addon.isActive
  );

  const allAvailableAddons = addOns.filter((addon) => {
    return (
      !addon.isComingSoon &&
      // @ts-ignore
      !featuresEnabled[addon.featureName]
    );
  });

  const ShowAddonDetailsModal = () => {
    let alreadyInCart = addonCart.find(
      (addon) => addon.featureName === showingDetailsFor?.featureName
    );

    const cancelAddonSubscription = async () => {
      try {
        setCancellingSubscription(true);

        const addonSubscription = currentPlanHistory.addonFeatures;

        const subscriptionItem = addonSubscription.find((addon: any) => {
          return (
            addon?.feature === showingDetailsFor?.featureName && addon?.isActive
          );
        });

        if (!subscriptionItem) {
          setCancellingSubscription(false);
          toast.info(
            "This addon comes with the lifetime deal. No subscription found"
          );
          return;
        }

        const subscriptionItemId = subscriptionItem?.subscriptionItemId;

        const { data } = await axios.post(
          `${PAYMENTS_BASE_URL}/cancelSubscriptionItemUS`,
          {
            ownerId: owner._id,
            subscriptionItemId,
          }
        );

        if (data.success) {
          setCancellingSubscription(false);
          toast.success("Addon subscription cancelled successfully");
          window.location.reload();
        } else {
          setCancellingSubscription(false);
          toast.error("Failed to cancel addon subscription, contact us");
        }
      } catch (error) {
        setCancellingSubscription(false);
        console.log("Error in cancelAddonSubscription", error);
      }
    };

    return (
      <div className="w-full py-4">
        <div
          className="flex gap-2 items-center text-gray-500 font-medium cursor-pointer w-fit rounded-sm hover:bg-stone-200 py-1 px-4 ml-4 transition-all ease-in-out duration-500"
          onClick={() => setShowingDetailsFor(null)}
        >
          <MdOutlineKeyboardBackspace size={20} />
          Marketplace
        </div>
        <div className="bg-secondary h-40 flex items-center mt-4"></div>
        <div className="w-[95%] md:w-[80%] lg:w-[55%] mx-auto h-fit rounded-md">
          <div className="-mt-28 mb-6 flex gap-4 items-center">
            <div className="bg-primary p-3 rounded-sm max-w-fit text-white">
              {showingDetailsFor?.icon}
            </div>
            <div className="-mt-2">
              <h3 className="font-bold mt-2 text-3xl">
                {showingDetailsFor?.name}
              </h3>
              <p className="text-xs text-gray-700">by BoloForms</p>
            </div>
          </div>
          <div className="flex gap-4 relative w-full flex-col md:flex-row">
            {/* <Suspense fallback={<div>Loading...</div>}> */}
            {showingDetailsFor && (
              <AddonImageSlider images={showingDetailsFor.images} />
            )}
            {/* </Suspense> */}
            <div className="p-4 rounded-md shadow-lg border-[1.5px] border-gray-300 bg-white whitespace-nowrap h-fit min-w-[30%]">
              <h2 className="font-bold text-2xl whitespace-pre-wrap ">
                Available with Lifetime Deal
              </h2>
              <button
                className=" text-white bg-[#d4af37] px-3 py-2 w-full font-medium sm:text-sm text-xs rounded-[4px] mt-4 hover:opacity-90"
                onClick={() => {
                  if (showingDetailsFor?.isContactUs) {
                    window.open(
                      "https://cal.com/boloforms/signature",
                      "_blank"
                    );
                    return;
                  }
                  if (showingDetailsFor?.isComingSoon) return;

                  if (showingDetailsFor) {
                    if (alreadyInCart) {
                      toast.error("Addon already in cart");
                    } else {
                      setAddonCart([...addonCart, showingDetailsFor]);
                      toast.success("Addon added to cart");
                    }
                  }
                }}
                disabled={
                  (showingDetailsFor?.isComingSoon ||
                    // @ts-ignore
                    featuresEnabled[showingDetailsFor?.featureName]) &&
                  !showingDetailsFor?.isContactUs
                }
              >
                {showingDetailsFor?.isContactUs
                  ? "Contact Us"
                  : showingDetailsFor?.isComingSoon
                  ? "Coming soon"
                  : // @ts-ignore
                  featuresEnabled[showingDetailsFor?.featureName]
                  ? "Installed"
                  : alreadyInCart
                  ? "Added to cart"
                  : `Buy Now for $${showingDetailsFor?.price}`}
              </button>
              {(showingDetailsFor?.isComingSoon &&
                showingDetailsFor?.featureRequestUrl) ||
              // @ts-ignore
              featuresEnabled[showingDetailsFor?.featureName] ? (
                <button
                  className="text-gray-700 bg-slate-200 px-3 py-2 w-full font-medium sm:text-sm text-xs rounded-[4px] mt-2 hover:opacity-90 block"
                  onClick={async () => {
                    if (
                      showingDetailsFor?.isComingSoon &&
                      showingDetailsFor?.featureRequestUrl
                    ) {
                      window.open(
                        showingDetailsFor?.featureRequestUrl,
                        "_blank"
                      );
                    }

                    if (
                      // @ts-ignore
                      featuresEnabled[showingDetailsFor?.featureName]
                    ) {
                      await cancelAddonSubscription();
                    }
                  }}
                  disabled={
                    showingDetailsFor?.isComingSoon &&
                    !showingDetailsFor?.featureRequestUrl
                  }
                >
                  {showingDetailsFor?.isComingSoon &&
                  showingDetailsFor?.featureRequestUrl ? (
                    "Show Interest"
                  ) : // @ts-ignore
                  featuresEnabled[showingDetailsFor?.featureName] ? (
                    cancellingSubscription ? (
                      <AiOutlineLoading3Quarters
                        size={22}
                        className="animate-spin mx-auto"
                      />
                    ) : (
                      "Cancel Addon"
                    )
                  ) : (
                    "Request Feature"
                  )}
                </button>
              ) : null}
            </div>
          </div>
          <div className="max-w-full md:max-w-[70%]">
            <h2 className="font-bold text-2xl mt-10">About</h2>
            <p className="text-gray-700 mt-4">{showingDetailsFor?.about}</p>

            {showingDetailsFor?.extraDetails &&
              showingDetailsFor?.extraDetails.length > 0 &&
              showingDetailsFor?.extraDetails.map((detail) => {
                return (
                  <div
                    key={detail}
                    className="flex gap-2 items-center font-semibold mt-4"
                  >
                    <FaCaretSquareRight size={16} />
                    <span>{detail}</span>
                  </div>
                );
              })}

            {showingDetailsFor?.helpBlogs &&
              showingDetailsFor?.helpBlogs?.length > 0 && (
                <h2 className="font-bold text-2xl mt-10">Help Topics</h2>
              )}
            <p className="text-gray-700 mt-4">
              {showingDetailsFor?.helpBlogs?.map((blog) => (
                <div key={blog.name} className="flex gap-2 items-center">
                  <MdOutlineKeyboardArrowRight size={20} />
                  <a
                    href={blog.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary"
                  >
                    {blog.name}
                  </a>
                </div>
              ))}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const purchaseNowHandler = async () => {
    try {
      setPurchasing(true);

      let addonsData = addonCart.map((addon) => {
        return {
          quantity: 1,
          price: addon.price,
          name: addon.name,
          tierId: addon.featureName,
        };
      });

      const { data } = await axios.post(
        `${PAYMENTS_BASE_URL}/createStripeSubscriptionForAddonsUS`,
        {
          items: addonsData,
          ownerEmail: owner?.email,
          successUrl: `${getDashboardUrl()}/apps`,
          purchaseAll:
            !isAnyAddonSubscribed &&
            addonCart.length === allAvailableAddons.length,
        }
      );

      if (!data.sessionUrl) {
        throw new Error("Something went wrong. Please try again later.");
      }
      let sessionUrl = data.sessionUrl;
      window.open(sessionUrl, "_self");
      setPurchasing(false);
    } catch (error) {
      setPurchasing(false);
      console.log("Error in purchaseNowHandler", error);
    }
  };

  if (!getOwnerPlanDetails()?.isLifeTime) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="notranslate relative pb-16">
      <div className="header-div flex justify-between items-center border-b-[1.5px] border-gray-300 sticky -top-4 pt-4 bg-white px-2 z-[20]">
        <div className="font-semibold gap-2 hidden sm:flex">
          {/* <TbAppsFilled className="w-8 h-8 " /> */}
          <span className="text-lg">Marketplace</span>
        </div>
        {/* <div className="no-scrollbar flex gap-8 overflow-scroll w-full cursor-pointer">
          {Object.keys(groupedByAddOns).map((subType) => (
            <span key={subType} className="text-sm">
              {subType}
            </span>
          ))}
        </div> */}
        <div className="flex gap-1 mb-2 items-center">
          <div
            className={`opacity-0 transition-all duration-300 ${
              isExpanded ? "md:w-[250px] opacity-100" : "md:w-[0px]"
            }`}
          >
            <input
              id="addon-search"
              type="text"
              placeholder="Search Marketplace"
              value={searchTerm}
              className="w-full h-full text-sm px-2 border-b-[1.5px] border-primary outline-none"
              onChange={handleSearch}
            />
          </div>
          <div
            className={`p-2 rounded-md cursor-pointer hover:bg-gray-100`}
            onClick={handleSearchClick}
          >
            <BsSearch size={20} className=" text-gray-500" />
          </div>
          <div
            className={`relative ml-2 p-2 rounded-md cursor-pointer hover:bg-gray-100 ${
              addonCart.length > 0
                ? "bg-secondary text-primary"
                : "text-gray-500"
            } md:mr-6`}
            onClick={(e) => {
              e.stopPropagation();
              if (addonCart.length > 0) {
                setIsCartOpen(true);
              }
            }}
          >
            {addonCart.length > 0 && (
              <span className="absolute -top-2 -right-1 text-xs font-medium bg-primary text-white rounded-full w-5 h-5 flex items-center justify-center ">
                {addonCart.length}
              </span>
            )}
            <MdOutlineShoppingCart size={22} className=" " />
          </div>
          {/* <BoloButton
            variant="primary"
            size="sm"
            data-tooltip-id="purchase-all-addons-tooltip"
            onClick={(e) => {
              e.stopPropagation();

              if (isAnyAddonSubscribed) {
                toast.error(
                  "You already have an active addon subscription. Please contact us to purchase all addons for $39"
                );
                return;
              }

              setIsCartOpen(true);

              let availableAddons = addOns.filter((addon) => {
                return (
                  !addon.isComingSoon &&
                  // @ts-ignore
                  !featuresEnabled[addon.featureName]
                );
              });

              setAddonCart(availableAddons);
            }}
            disabled={
              addOns.filter((addon) => {
                return (
                  !addon.isComingSoon &&
                  // @ts-ignore
                  !featuresEnabled[addon.featureName]
                );
              }).length === 0 || purchasing
            }
            className={`whitespace-nowrap ${
              isAnyAddonSubscribed ? "opacity-80" : ""
            }`}
          >
            {" "}
            Purchase all for <span className="text-lg font-semibold">$39</span>
          </BoloButton> */}

          {isAnyAddonSubscribed && (
            <CustomTooltip
              helpers={{
                id: "purchase-all-addons-tooltip",
                content: (
                  <div className="text-xs">
                    You already have an active addon subscription.
                    <p>Contact us to purchase all addons for $39</p>
                  </div>
                ),
                place: "top",
              }}
            />
          )}
        </div>
      </div>

      <div className="flex flex-col gap-8 mt-4">
        {isExpanded && Object.keys(searchedAddons).length > 0 ? (
          Object.keys(searchedAddons).map((subType) => (
            <div key={subType} className="mt-4">
              <h2 className="font-semibold ">{subType}</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-2">
                {searchedAddons[subType].map((addOn) => (
                  <AddonCard
                    key={addOn.name}
                    addOn={addOn}
                    setShowingDetailsFor={setShowingDetailsFor}
                    addonCart={addonCart}
                    setAddonCart={setAddonCart}
                    featuresEnabled={featuresEnabled}
                  />
                ))}
              </div>
            </div>
          ))
        ) : searchTerm === "" ? (
          Object.keys(groupedByAddOns).map((subType) => (
            <div key={subType} className="mt-4">
              <h2 className="font-semibold ">{subType}</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-2">
                {groupedByAddOns[subType].map((addOn) => (
                  <AddonCard
                    key={addOn.name}
                    addOn={addOn}
                    setShowingDetailsFor={setShowingDetailsFor}
                    addonCart={addonCart}
                    setAddonCart={setAddonCart}
                    featuresEnabled={featuresEnabled}
                  />
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col justify-center items-center text-gray-500 mt-6">
            <img
              src={IMAGE_LINKS.ADDON_NO_RESULT}
              alt="no addon"
              className="mb-4"
            />
            <p className=" font-bold">No results found for "{searchTerm}"</p>
            <p className="text-sm">Try searching something else.</p>
          </div>
        )}
      </div>

      {showingDetailsFor && showingDetailsFor?.name && (
        <AddonModal modalContent={ShowAddonDetailsModal()} />
      )}
      {addonCart.length > 0 && (
        <div
          className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-end ${
            isCartOpen ? "animate-slide-in" : "animate-slide-out none"
          }`}
        >
          <div
            className={`w-full sm:w-[400px] bg-white h-full overflow-y-auto `}
          >
            <div
              className="py-4"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className="text-lg font-semibold mb-6 animate-slide-in px-4"
                // style={{
                //   animationDuration: "0.5s",
                // }}
              >
                Cart Details
                <MdClose
                  size={20}
                  className="float-right cursor-pointer"
                  onClick={() => setIsCartOpen(false)}
                />
              </div>
              <div className="h-[83vh] overflow-y-auto no-scrollbar px-4">
                {addonCart.map((addon, index) => (
                  <div
                    key={index}
                    className="flex w-full items-center border-b-[1px] border-gray-200 cursor-pointer transition-all ease-in-out duration-200 relative py-4 gap-4 h-fit "
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {/* <div
                    className={`h-full w-1 py-4 rounded-r-md ${
                      addon.subType === "Workflow"
                        ? "bg-blue-600"
                        : addon.subType === "Tracking"
                        ? "bg-green-600"
                        : addon.subType === "Integration"
                        ? "bg-orange-600"
                        : ""
                    } `}
                  >
                    <p className="ml-2">
                      {addon.subType === "Workflow" ? (
                        <GoWorkflow size={20} className="text-blue-600" />
                      ) : addon.subType === "Tracking" ? (
                        <AiOutlineHourglass
                          size={20}
                          className="text-green-600"
                        />
                      ) : addon.subType === "Integration" ? (
                        <MdOutlineIntegrationInstructions
                          size={20}
                          className="text-orange-600"
                        />
                      ) : (
                        <></>
                      )}
                    </p>
                  </div> */}
                    <div className="bg-gray-100 text-primary p-2 rounded-md h-full scale-150 mx-2">
                      {addon.icon}
                    </div>

                    <div className="flex flex-col justify-between w-full gap-[5px] h-full">
                      <p className="font-semibold flex items-center gap-1 text-sm">
                        {addon.name}{" "}
                      </p>
                      <span className=" text-gray-700 font-semibold">
                        ${addon.price} <span className="text-xs">/ month</span>
                      </span>
                      <span
                        className={`font-medium rounded-md w-fit text-[10px] px-1 py-[2px] ${
                          addon.subType === "Workflow"
                            ? "text-blue-600 bg-blue-100"
                            : addon.subType === "Tracking"
                            ? "text-green-600 bg-green-100"
                            : addon.subType === "Integrations"
                            ? "text-orange-600 bg-orange-100 "
                            : ""
                        } `}
                      >
                        {addon.subType}
                      </span>
                    </div>

                    <div className="flex items-start mb-auto">
                      <RiDeleteBin5Line
                        size={20}
                        className="text-gray-400 cursor-pointer"
                        onClick={() => {
                          const newCart = addonCart.filter(
                            (item) => item.name !== addon.name
                          );
                          setAddonCart(newCart);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="border-t border-gray-300 pt-4 px-4 flex justify-between w-full gap-4">
                <div className="flex flex-col w-full">
                  <p className="font-bold text-2xl">
                    $
                    {!isAnyAddonSubscribed &&
                    allAvailableAddons.length === addonCart.length
                      ? "39"
                      : cartPrice}
                    <span className="text-xs text-gray-400 font-medium italic">
                      {" "}
                      / per month
                    </span>
                  </p>
                  <span className="text-[10px] italic">
                    Inclusive of all taxes
                  </span>
                </div>
                <BoloButton
                  variant="primary"
                  size="md"
                  className="w-full h-fit"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await purchaseNowHandler();
                  }}
                >
                  {purchasing ? (
                    <AiOutlineLoading3Quarters
                      size={22}
                      className="animate-spin mx-auto"
                    />
                  ) : (
                    "Purchase Now"
                  )}
                </BoloButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default AddonStore;
