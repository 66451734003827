import React, { Fragment, useCallback, useEffect, useState } from "react";
import { toast, ToastContainer, Slide } from "react-toastify";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import _ from "lodash";
import {
  BoloDropDown,
  BoloDropDownOption,
} from "../../../../../../components/Common/DropDown/BoloDropDown";
import { BASE_URL } from "../../../../../../constants/constants";
import axios from "../../../../../../utils/axios";
import ViewHeader from "./ViewHeader";
import { DesignerState, GlobalHelpersType } from "..";
import BoloRadioButtonMui from "../../../../../../components/Common/RadioButton/BoloRadioButtonMui";
import { BoloButton } from "../../../../../../components/Common/Button/BoloButton";
import { getOwnerPlanDetails } from "../../../../../../utils";
import { isFeatureEnabled } from "../../../../../../utils/paidFeat";
import PleaseUpgradeModal from "../../../../../../components/PleaseUpgradeModal";
import CustomPdfName from "./CustomPdfName";
import { GoProjectSymlink } from "react-icons/go";
import MixPanel from "../../../../../../utils/services/mixpanel";
import { PiSignatureFill } from "react-icons/pi";
// @ts-ignore
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { MdArrowDropDown } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { ALL_FEATURES } from "../../../../../../types/tiers";

function CustomDropDown({
  opts,
  setSelectedOpt,
  selectedOpt,
  defaultText,
}: any) {
  let activeQuestion = opts.find(
    (option: any) => option?.key === selectedOpt?.key
  );
  const questionTypeDropdownRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [displacementAmount, setDisplacementAmount] = useState(0);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: any, index: any) => {
    let displacement = -(index - 1) * 48;
    setDisplacementAmount(displacement);
    setSelectedOpt(option);
    setIsOpen((prev) => !prev);
  };

  const handleOutsideClick = (e: any) => {
    if (questionTypeDropdownRef.current !== null) {
      if ((questionTypeDropdownRef.current as HTMLElement).contains(e.target))
        return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative ml-6 max-w-[240px] w-[200px] border rounded-sm hover:shadow">
      <div className="relative">
        <div
          onClick={toggleDropdown}
          className="h-[36px] px-3 rounded flex items-center cursor-pointer"
        >
          <div className="ml-1 mr-2">
            <span className="text-gray-800 text-sm">
              {defaultText || activeQuestion?.value}
            </span>
          </div>
          <div className="ml-auto">
            <MdArrowDropDown className="text-2xl text-gray-500" />
          </div>
        </div>
        {isOpen && (
          <div
            ref={questionTypeDropdownRef}
            style={{
              marginTop: "-48px",
              position: "absolute",
              boxShadow:
                "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
              // top: `${displacementAmount}px`,
              zIndex: 5,
            }}
            className="py-2 max-w-[200px] min-w-[200px] bg-white border border-[#0000001f] rounded z-150 max-h-[250px] overflow-y-scroll"
            id="mini-pdf-custom-scroll"
          >
            {opts.map((option: any, index: any) => {
              return (
                <Fragment key={index}>
                  <div
                    onClick={() => handleOptionClick(option, index)}
                    className={`${
                      activeQuestion?.key === option?.key && "bg-[#e4f0fc]"
                    } min-h-[48px] w-full py-2 pl-4 pr-8 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <span className="text-gray-800 text-sm font-medium">
                      {option?.value}
                    </span>
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const RedirectUrlComponent = ({
  designerState,
  setDesignerState,
}: {
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
}) => {
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
    redirect: "",
    redirectText: "",
  });
  const [redirectUrl, setRedirectUrl] = useState<{
    enabled: boolean;
    url: string;
  }>({
    enabled:
      designerState?.fetchedData?.document?.settings?.redirectionUrl?.enabled ||
      false,
    url:
      designerState?.fetchedData?.document?.settings?.redirectionUrl?.url || "",
  });

  const [passwordPatternFields, setPasswordPatternFields] = useState<any[]>([
    {
      id: "1",
      title: "What is your name?",
    },
    {
      id: "2",
      title: "What is your age?",
    },
    {
      id: "3",
      title: "What is your favorite color?",
    },
  ]);
  const [passwordProtection, setPasswordProtection] = useState<{
    enabled: boolean;
    password: string;
  }>({
    enabled:
      designerState?.fetchedData?.document?.settings?.passwordProtection
        ?.enabled || false,
    password:
      designerState?.fetchedData?.document?.settings?.passwordProtection
        ?.password || randomPassword(),
  });

  const [mixedTagsSettings, setMixedTagsSettings] = React.useState({
    pattern: /@/, // <- must define "patten" in mixed mode
    dropdown: {
      enabled: 1,
      position: "text",
    },
    whitelist: [],
    duplicates: true,
  });
  const [mixedTagsValue, setMixedTagsValue] = useState(
    designerState?.fetchedData?.document?.settings?.passwordProtection
      ?.password || ""
  );

  const onChangePasswordPattern = useCallback((e: any) => {
    setMixedTagsValue(e?.detail?.value?.replace(/\n$/, ""));
  }, []);
  function isValidURL(url: string) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const disableRedirection = async (enabled: boolean) => {
    try {
      const isPDFRedirectUrlEnabled = await isFeatureEnabled(
        "PDF_REDIRECT_URL"
      );

      if (!isPDFRedirectUrlEnabled) {
        setShowUpgradeModal(true);
        setUpgradeModalType("PDF_REDIRECT_URL");
        return;
      }

      let trimmedUrlInput = redirectUrl?.url?.trim();
      // if (trimmedUrlInput && !isValidURL(trimmedUrlInput)) {
      //   toast.error(
      //     "Please enter a valid URL including 'https://' or 'http://"
      //   );
      //   return;
      // }
      // if (!trimmedUrlInput) {
      //   toast.error(
      //     "Please enter a valid URL including 'https://' or 'http://'"
      //   );
      //   return;
      // }

      const { data } = await axios.post(`${BASE_URL}/document/settings`, {
        documentId: designerState?.fetchedData?.document?.documentId,
        redirectionUrl: {
          enabled,
          url: trimmedUrlInput,
        },
      });
      if (data?.success) {
        toast.success(
          `Redirection url ${enabled ? "enabled" : "disabled"} successfully`
        );

        setRedirectUrl((prev) => ({
          ...prev,
          enabled,
        }));

        if (data?.document?.settings) {
          // if settings exists on returned object
          // as when we change language settings will always exist on the document
          setDesignerState((prev) => ({
            ...prev,
            fetchedData: { ...prev.fetchedData, document: data?.document },
          }));
        }
      }
      if (!data?.success) {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRedirectUrlChange = async (url: string) => {
    try {
      const isPDFRedirectUrlEnabled = await isFeatureEnabled(
        "PDF_REDIRECT_URL"
      );

      if (!isPDFRedirectUrlEnabled) {
        setShowUpgradeModal(true);
        setUpgradeModalType("PDF_REDIRECT_URL");
        return;
      }

      if (!url) {
        toast.error(
          "Please enter a valid URL including 'https://' or 'http://'"
        );
        return;
      }
      const { data } = await axios.post(`${BASE_URL}/document/settings`, {
        documentId: designerState?.fetchedData?.document?.documentId,
        redirectionUrl: {
          enabled: redirectUrl?.enabled,
          url,
        },
      });
      if (data?.success) {
        // toast.success("Redirection url updated successfully");
        setRedirectUrl((prev) => ({
          ...prev,
          url,
        }));

        if (data?.document?.settings) {
          // if settings exists on returned object
          // as when we change url settings will always exist on the document
          setDesignerState((prev) => ({
            ...prev,
            fetchedData: { ...prev.fetchedData, document: data?.document },
          }));
        }
        if (!redirectUrl?.enabled && url) {
          disableRedirection(true);
        } else {
          toast.success("Redirection url updated successfully");
        }
      }
      if (!data?.success) {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGeneratePDFPasswordPattern = (queKey: any) => {
    // const alreadyExists = generatedPDFName?.find(
    //   (question) => question?.id && question?.id == queKey?.key
    // );

    // if (alreadyExists) {
    //   toast.error("Question Already exists");
    //   return;
    // }

    // const questionToAdd = generatedPDFNameQuestions?.find(
    //   (question) => question?.id && question?.id == queKey?.key
    // );
    // if (questionToAdd) {
    //   setGeneratedPDFName((prev) => [...prev, questionToAdd]);
    // }

    const questionToAdd = passwordPatternFields?.find(
      (question) => question?.id && question?.id == queKey?.key
    );
    const addStringify = [
      [{ value: questionToAdd?.title, prefix: "@", title: questionToAdd?.id }],
    ];
    setMixedTagsValue((mixedTagsValue || "") + JSON.stringify(addStringify));
  };

  const handlePasswordProtectionUpdate = async (
    enabled: boolean,
    password: string
  ) => {
    const isPasswordProtectedPDFEnabled = await isFeatureEnabled(
      "PASSWORD_PROTECTED_PDF"
    );

    if (!isPasswordProtectedPDFEnabled) {
      setShowUpgradeModal(true);
      setUpgradeModalType("PASSWORD_PROTECTED_PDF");
      return;
    }

    if (!password?.trim()) {
      toast.error("Please enter a password");
      return;
    }

    setPasswordProtection((prev) => ({
      ...prev,
      enabled,
    }));

    const { data } = await axios.post(`${BASE_URL}/document/settings`, {
      documentId: designerState?.fetchedData?.document?.documentId,
      passwordProtection: {
        enabled,
        password,
      },
    });
    console.log(data);

    toast.success(`Settings updated successfully`);
  };

  return (
    <div>
      <p className="text-sm font-semibold mb-2 flex gap-2 items-center ">
        <GoProjectSymlink className="text-xl text-black" />
        Redirection URL
      </p>
      <p className="text-gray-500 text-xs pr-4 mb-2">
        Signers of this PDF will be redirected in 5 seconds to this URL after
        signing.
      </p>

      <div className="flex items-center justify-between">
        <p className="text-sm">Enable Redirection</p>
        <BoloRadioButtonMui
          // {...{
          //   inputProps: {
          //     "aria-label": "Switch demo",
          //   },
          // }}
          checked={redirectUrl.enabled}
          onChange={(e) => {
            disableRedirection(e.target.checked);
          }}
        />
      </div>
      {redirectUrl.enabled && (
        <div className="mt-4 w-full text-sm relative">
          <label htmlFor="redirectUrl" className="">
            Redirect to
          </label>
          <textarea
            className="w-full max-h-40 border border-gray-300 rounded-md px-2 py-1 mt-1 focus:outline-none focus:ring-1 focus:ring-violet-400 focus:border-transparent resize-y italic"
            id="redirectUrl"
            name="redirectUrl"
            placeholder="Enter URL"
            style={{
              textAlign: "left",
              lineHeight: "inherit",
            }}
            value={redirectUrl?.url}
            onChange={(e) => {
              setRedirectUrl((prev) => ({
                ...prev,
                url: e.target.value,
              }));
            }}
          ></textarea>
          <BoloButton
            size={"xs"}
            className=" w-full rounded-md"
            onClick={async () => {
              if (designerState?.instanceFrom === "PDF-TEMPLATE") {
                new MixPanel().track("PDF template", {
                  pdf_template_action: "pdf_template_redirect_url",
                  paid: true,
                });
                new MixPanel().increment("pdf_template_redirect_url");
              } else if (designerState?.instanceFrom === "PDF") {
                new MixPanel().track("PDF", {
                  pdf_action: "pdf_redirect_url",
                  paid: true,
                });
                new MixPanel().increment("pdf_redirect_url");
              }
              let trimmedUrlInput = redirectUrl?.url?.trim();
              if (trimmedUrlInput && !isValidURL(trimmedUrlInput)) {
                toast.error(
                  "Please enter a valid URL including 'https://' or 'http://"
                );
                return;
              }
              await handleRedirectUrlChange(trimmedUrlInput);
            }}
            disabled={
              redirectUrl?.url ===
              designerState?.fetchedData?.document?.settings?.redirectionUrl
                ?.url
            }
          >
            Add
          </BoloButton>
        </div>
      )}
      <hr className="my-4 -mx-4" />

      <div className="text-sm font-semibold mb-2 flex gap-2 items-center ">
        <RiLockPasswordLine className="text-xl text-black" />
        <p className="text-sm font-semibold">Password Protected PDF</p>
      </div>
      <p className="text-gray-500 text-xs mt-2 mb-2">
        Final Document will be password protected for the signers.
      </p>
      <div className="flex items-center justify-between">
        <p className="text-sm">Enable Password Protection</p>
        <BoloRadioButtonMui
          checked={passwordProtection.enabled}
          onChange={(e) => {
            handlePasswordProtectionUpdate(
              e.target.checked,
              passwordProtection?.password
            );
          }}
        />
      </div>

      {passwordProtection.enabled && (
        <div className="mt-4 w-full text-sm relative">
          <label htmlFor="redirectUrl" className="text-sm">
            Password
          </label>
          <input
            type="text"
            className="w-full border border-gray-300 rounded-md px-3 py-2 mt-1 mb-2 text-gray-700 focus:outline-none focus:ring-1 focus:ring-violet-400 focus:border-transparent text-xs"
            value={passwordProtection?.password}
            onChange={(e) => {
              setPasswordProtection((prev) => ({
                ...prev,
                password: e.target.value,
              }));
            }}
          />
          <BoloButton
            size={"xs"}
            className=" w-full rounded-md"
            onClick={async () => {
              await handlePasswordProtectionUpdate(
                passwordProtection.enabled,
                passwordProtection?.password
              );
            }}
          >
            Update
          </BoloButton>
          {/* <CustomDropDown
            opts={passwordPatternFields?.map((question) => ({
              key: question?.id,
              value: question?.title,
            }))}
            selectedOpt={null}
            defaultText={"Add Question"}
            setSelectedOpt={handleGeneratePDFPasswordPattern}
          />
          <div>
            <MixedTags
              className="text-xs w-full myTags"
              autoFocus={true}
              settings={mixedTagsSettings}
              readOnly={false}
              onChange={onChangePasswordPattern}
              value={mixedTagsValue}
            />
          </div> */}
        </div>
      )}
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      <hr className="my-4 -mx-4" />

      {/* <div className="border-b-2 mt-5 -mx-4 border-gray-300 "></div> */}
    </div>
  );
};

const SigningType = ({
  designerState,
  setDesignerState,
}: {
  designerState: DesignerState;
  setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
}) => {
  const [signingType, setSigningType] = useState<SignatureType>({
    image:
      designerState?.settings?.signingType?.image !== undefined
        ? designerState?.settings?.signingType?.image
        : true,
    typing:
      designerState?.settings?.signingType?.typing !== undefined
        ? designerState?.settings?.signingType?.typing
        : true,
    drawn:
      designerState?.settings?.signingType?.drawn !== undefined
        ? designerState?.settings?.signingType?.drawn
        : true,
  });
  const debouncedUpdateSettings = _.debounce(async (newSigningType) => {
    try {
      const { data } = await axios.post(`/document/settings`, {
        documentId: designerState?.fetchedData?.document?.documentId,
        signingType: newSigningType,
      });

      if (data?.success) {
        setDesignerState((prev) => ({
          ...prev,
          settings: {
            ...prev?.settings,
            signingType: data?.document?.settings?.signingType,
          },
        }));
        toast.success("Signing types updated!");
      }
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("Failed to update signing types.");
    }
  }, 500);
  return (
    <div className="pt-2">
      {" "}
      <p className="text-sm font-semibold  flex gap-2 items-center">
        <PiSignatureFill className="text-2xl" />
        Select Sign/Initials type
      </p>
      <p className="text-gray-500 text-xs pr-4 my-2">
        Signers of this PDF will only be able to sign using permitted signature
        types.
      </p>
      <div className="flex items-center justify-between">
        <p className="text-sm">Enable Drawing </p>
        <BoloRadioButtonMui
          checked={signingType?.drawn}
          onChange={() => {
            toast.info("Drawing option can not be disabled!");
          }}
        />
      </div>
      <div className="flex items-center justify-between">
        <p className="text-sm">Enable Upload</p>
        <BoloRadioButtonMui
          checked={signingType?.image}
          onChange={() => {
            const newSigningType = {
              ...signingType,
              image: !signingType.image,
            };
            debouncedUpdateSettings(newSigningType);
            setSigningType(newSigningType);
          }}
        />
      </div>
      <div className="flex items-center justify-between mb-8">
        <p className="text-sm">Enable Typing</p>
        <BoloRadioButtonMui
          checked={signingType?.typing}
          onChange={() => {
            const newSigningType = {
              ...signingType,
              typing: !signingType.typing,
            };
            debouncedUpdateSettings(newSigningType);
            setSigningType(newSigningType);
          }}
        />
      </div>
      {/* <BoloButton
        size={"xs"}
        className=" w-full rounded-md"
        onClick={async () => {
          const { data } = await axios.post(`/document/settings`, {
            documentId: designerState?.fetchedData?.document?.documentId,
            signingType,
          });

          if (data?.success) {
            setDesignerState((prev) => ({
              ...prev,
              settings: {
                ...prev?.settings,
                signingType: data?.document?.settings?.signingType,
              },
            }));
            toast.success("Signing types updated!");
          }
        }}
      >
        Save
      </BoloButton> */}
    </div>
  );
};
export type SignatureType = {
  image: boolean;
  typing: boolean;
  drawn: boolean;
};
export type SettingsViewProps = {
  helpers: {
    open: boolean;
    setOpen: any;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};

function randomPassword() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

const SettingsView = ({ helpers }: SettingsViewProps) => {
  const { open, setOpen, designerState, setDesignerState } = helpers;
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
    redirect: "",
    redirectText: "",
  });
  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      <div className="h-[100%]">
        <div className="px-4">
          <ViewHeader name="Settings" open={open} setOpen={setOpen} />
        </div>
        <div
          className="px-4 max-h-[calc(100%_-_80px)] min-h-[calc(100%_-_80px)] overflow-y-auto"
          id="mini-pdf-custom-scroll"
        >
          <RedirectUrlComponent {...helpers} />
          <SigningType {...helpers} />
          <hr className="my-4 -mx-4" />
          <CustomPdfName
            designerState={designerState}
            setDesignerState={setDesignerState}
            setShowUpgradeModal={setShowUpgradeModal}
            setUpgradeModalType={setUpgradeModalType}
          />
        </div>
      </div>
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default SettingsView;
