import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import useQueryHook from "./useQueryHook";

export type ProductCategoryType = { category: string; subCategory: string[] };
export type ProductType = {
  productId: string;
  _id?: string;
  name: string;
  description?: string;
  SKU: string;
  category?: string;
  subCategroy?: string;
  pricing: {
    currencyCode: string;
    cost: number | string;
    price: number | string;
    pricingMethod: "FIXED" | "RECURRING";
    period: "WEEKLY" | "MONTHLY" | "QUARTERLY" | "SEMIANNUALLY" | "ANNUALLY";
  };
  images: string[];
  createdAt?: string;
  updatedAt?: string;
};
export type CreateFunctionStateType = {
  loading: boolean;
  isError: boolean;
  error: string;
};

export type GetProductStateType = {
  products: ProductType[];
  productCount: number;
  loading: boolean;
  isError: boolean;
  error: string;
};

export type GetCategoryStateType = {
  categories: ProductCategoryType[];
  loading: boolean;
  isError: boolean;
  error: string;
};

export type CreateProductFuncType = ({
  product,
  images,
}: {
  product: ProductType;
  images: File[];
}) => Promise<{ data: any } | undefined | null>;

export type DeleteProductFuncType = ({
  productId,
}: {
  productId: string;
}) => Promise<{ data: any } | undefined | null>;

export type DeleteCategoryFuncType = ({
  name,
  action,
}: {
  name: string;
  action: "delete" | "move";
}) => Promise<{ data: any } | undefined | null>;

export type RenameCategoryFuncType = ({
  category,
  toCategory,
}: {
  category: string;
  toCategory: string;
}) => Promise<{ data: any } | undefined | null>;

const useQuoteBuilder = ({
  reloadCats,
  reloadProducts,
}: {
  reloadCats?: boolean;
  reloadProducts?: boolean;
}) => {
  const { getSearchData } = useQueryHook();
  const [category, setCategories] = useState<GetCategoryStateType>({
    categories: [],
    loading: false,
    isError: false,
    error: "",
  });

  const [product, setProduct] = useState<GetProductStateType>({
    products: [],
    productCount: 0,
    loading: false,
    isError: false,
    error: "",
  });

  const [categoryReqState, setCategoryReqState] =
    useState<CreateFunctionStateType>({
      loading: false,
      isError: false,
      error: "",
    });
  const [productReqState, setProductReqState] =
    useState<CreateFunctionStateType>({
      loading: false,
      isError: false,
      error: "",
    });

  const [refetchCats, setReFetchCats] = useState(false);
  const [refetchProducts, setReFetchProducts] = useState(false);

  const getCats = async () => {
    try {
      setCategories((prev) => ({
        ...prev,
        loading: true,
        isError: false,
        error: "",
      }));
      const { data } = await axios.get("/category");
      if (data?.success) {
        setCategories((prev) => ({
          ...prev,
          categories: data?.categories || [],
          loading: false,
        }));
      } else {
        setCategories((prev) => ({
          ...prev,
          categories: [],
          loading: false,
          isError: true,
          error: data?.message,
        }));
      }
    } catch (error) {
      console.log("Error in getCats useQuotebuilder");
      console.log(error);
      setCategories((prev) => ({
        ...prev,
        loading: false,
        isError: true,
      }));
    }
    setCategories((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const getProducts = async () => {
    try {
      setProduct((prev) => ({
        ...prev,
        loading: true,
        isError: false,
        error: "",
      }));
      const params = getSearchData();
      const { data } = await axios.get(
        `/product?page=${params.pageNo_}&limit=${params.limit_}&query=${params.query_}&sortBy=${params.sortBy_}&sortOrder=${params.sortOrder_}&dateFrom=${params.dateFrom_}&dateTo=${params.dateTo_}&category=${params.pCategory_}`
      );
      if (data?.success) {
        setProduct((prev) => ({
          ...prev,
          products: data?.products || [],
          productCount: data?.productCount || 0,
          loading: false,
        }));
      } else {
        setProduct((prev) => ({
          ...prev,
          products: [],
          loading: false,
          isError: true,
          error: data?.message,
        }));
      }
    } catch (error) {
      console.log("Error in getProducts useQuotebuilder");
      console.log(error);
      setProduct((prev) => ({
        ...prev,
        loading: false,
        isError: true,
      }));
    }
    setProduct((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const createCatFunc = async ({ name }: { name: string }) => {
    setCategoryReqState((prev) => ({
      ...prev,
      loading: true,
      isError: false,
      error: "",
    }));
    try {
      const { data } = await axios.post("/category", { name });
      if (data?.success) {
        toast.success("Category Created");
        setReFetchCats((prev) => !prev);
      } else {
        toast.error(data?.message || "Something went wrong");
        setCategoryReqState((prev) => ({
          ...prev,
          isError: true,
          error: data?.message,
        }));
      }
      setCategoryReqState((prev) => ({
        ...prev,
        loading: false,
      }));
    } catch (error) {
      setCategoryReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      console.log("error in createCatFunc useQuotebuilder");
      console.log(error);
      toast.error("Something went wrong");
    }
    setCategoryReqState((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  const deleteCatFunc: DeleteCategoryFuncType = async ({
    name,
    action,
  }: {
    name: string;
    action: "delete" | "move";
  }) => {
    setCategoryReqState((prev) => ({
      ...prev,
      loading: true,
      isError: false,
      error: "",
    }));
    try {
      const { data } = await axios.delete(
        `/category?category=${name}&action=${action}`
      );
      if (data?.success) {
        toast.success("Category Deleted");
        setReFetchCats((prev) => !prev);
      } else {
        toast.error(data?.message || "Something went wrong");
        setCategoryReqState((prev) => ({
          ...prev,
          isError: true,
          error: data?.message,
        }));
      }
      setCategoryReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      return { data };
    } catch (error) {
      setCategoryReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      console.log("error in deleteCatFunc useQuotebuilder");
      console.log(error);
      toast.error("Something went wrong");
    }
    setCategoryReqState((prev) => ({
      ...prev,
      loading: false,
    }));
    return null;
  };

  const renameCatFunc: RenameCategoryFuncType = async ({
    category,
    toCategory,
  }) => {
    setCategoryReqState((prev) => ({
      ...prev,
      loading: true,
      isError: false,
      error: "",
    }));
    try {
      const { data } = await axios.post(`/category/rename`, {
        category,
        toCategory,
      });
      if (data?.success) {
        toast.success("Category Renamed");
        setReFetchCats((prev) => !prev);
      } else {
        toast.error(data?.message || "Something went wrong");
        setCategoryReqState((prev) => ({
          ...prev,
          isError: true,
          error: data?.message,
        }));
      }
      setCategoryReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      return { data };
    } catch (error) {
      setCategoryReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      console.log("error in deleteCatFunc useQuotebuilder");
      console.log(error);
      toast.error("Something went wrong");
    }
    setCategoryReqState((prev) => ({
      ...prev,
      loading: false,
    }));
    return null;
  };

  const createProductFunc: CreateProductFuncType = async ({
    product,
    images,
  }: {
    product: ProductType;
    images: File[];
  }) => {
    setProductReqState((prev) => ({
      ...prev,
      loading: true,
      isError: false,
      error: "",
    }));
    try {
      let formData = new FormData();
      formData.append("productId", product.productId);
      formData.append("name", product.name);
      formData.append("description", product.description || "");
      formData.append("SKU", product.SKU);
      formData.append("category", product.category || "");
      formData.append("subCategroy", product.subCategroy || "");
      formData.append("pricing.cost", product.pricing.cost.toString());
      formData.append("pricing.price", product.pricing.price.toString());
      formData.append("pricing.currencyCode", product.pricing.currencyCode);
      formData.append("pricing.pricingMethod", product.pricing.pricingMethod);
      formData.append("product.pricing.period", product.pricing.period);
      for (let imgFile of images) {
        if (imgFile instanceof File) {
          formData.append("images", imgFile);
        }
      }
      // console.log(formData);

      const { data } = await axios.post("/product", formData);
      if (data?.success) {
        toast.success("Product Created");
        setReFetchProducts((prev) => !prev);
      } else {
        toast.error(data?.message || "Something went wrong");
        setProductReqState((prev) => ({
          ...prev,
          isError: true,
          error: data?.message,
        }));
      }
      setProductReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      return { data: data };
    } catch (error) {
      setProductReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      console.log(error);
      console.log("error in createProductFunc useQuotebuilder");
      toast.error("Something went wrong");
    }
    setProductReqState((prev) => ({
      ...prev,
      loading: false,
    }));
    return null;
  };

  const deleteProductFunc: DeleteProductFuncType = async ({
    productId,
  }: {
    productId: string;
  }) => {
    setProductReqState((prev) => ({
      ...prev,
      loading: true,
      isError: false,
      error: "",
    }));
    try {
      const { data } = await axios.delete(`/product?productId=${productId}`);
      if (data?.success) {
        toast.success("Product Deleted");
        setReFetchProducts((prev) => !prev);
      } else {
        toast.error(data?.message || "Something went wrong");
        setProductReqState((prev) => ({
          ...prev,
          isError: true,
          error: data?.message,
        }));
      }
      setProductReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      return { data: data };
    } catch (error) {
      setProductReqState((prev) => ({
        ...prev,
        loading: false,
      }));
      console.log(error);
      console.log("error in createProductFunc useQuotebuilder");
      toast.error("Something went wrong");
    }
    setProductReqState((prev) => ({
      ...prev,
      loading: false,
    }));
    return null;
  };

  useEffect(() => {
    getCats();
    // console.log("refetching cats");
  }, [reloadCats, refetchCats]);

  useEffect(() => {
    getProducts();
    // console.log("refetching cats");
  }, [
    reloadProducts,
    refetchProducts,
    getSearchData().pCategory_,
    getSearchData().rT_,
    getSearchData().query_,
    getSearchData().pageNo_,
    getSearchData().limit_,
    getSearchData().sortBy_,
    getSearchData().sortOrder_,
    getSearchData().dateFrom_,
    getSearchData().dateTo_,
  ]);

  return {
    category,
    categoryReqState,
    createCatFunc,
    deleteCatFunc,
    renameCatFunc,
    product,
    productReqState,
    createProductFunc,
    deleteProductFunc,
  };
};

export default useQuoteBuilder;
