import { cva, VariantProps } from "class-variance-authority";
import React, { useEffect, useRef, useState } from "react";
import { cn } from "../lib/utils";

type BoloTabType = {
  value: string;
};

type BoloTabSwitcherProps = {
  //   tabs: BoloTabType[];
  value: string;
  onChangeTab?: (tabId: string) => void;
  activeColor?: string;
};

const tabVariants = cva("cursor-pointer px-3 py-1", {
  variants: {
    size: {
      xs: "text-xs px-2 py-0.5",
      sm: "text-sm px-3 py-1",
      md: "text-base px-4 py-1.5",
      lg: "text-lg px-5 py-2",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const switcherVariants = cva("", {
  variants: {
    variant: {
      inactive: "",
      primary: "text-primary",
      secondary: "text-secondary",
    },
  },
  defaultVariants: {
    variant: "inactive",
  },
});

const switcherBorderVariants = cva(
  "absolute bottom-0 h-0.5 bg-primary transition-all duration-300",
  {
    variants: {
      bordersize: {
        na: "",
        sm: "h-[1px]",
        md: "h-[2px]",
        lg: "h-[3px]",
        xl: "h-[4px]",
      },
    },
    defaultVariants: {
      bordersize: "md",
    },
  }
);

const BoloTab = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div"> &
    BoloTabType &
    VariantProps<typeof tabVariants>
>(({ className, value, size, ...props }, ref) => (
  <div
    ref={ref}
    className={tabVariants({ size, className })}
    data-value={value}
    {...props}
  />
));

const BoloTabSwitcher = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div"> &
    BoloTabSwitcherProps &
    VariantProps<typeof switcherVariants> &
    VariantProps<typeof switcherBorderVariants>
>(
  (
    {
      className,
      value,
      variant,
      bordersize,
      activeColor,
      children,
      onChangeTab,
      ...props
    },
    ref
  ) => {
    const [underlineStyle, setUnderlineStyle] = useState<React.CSSProperties>(
      {}
    );
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const activeTabElement = containerRef.current?.querySelector(
        `[data-value='${value}']`
      ) as HTMLElement;

      if (activeTabElement) {
        setUnderlineStyle({
          left: activeTabElement.offsetLeft,
          width: activeTabElement.offsetWidth,
        });
      }
    }, [value, children]);

    const handleClick = (newValue: string) => {
      if (onChangeTab) {
        onChangeTab(newValue);
      }
    };

    return (
      <div
        ref={containerRef}
        className={cn("relative flex items-end ", className)}
        {...props}
      >
        {React.Children.map(children, (child) => {
          if (child) {
            return React.cloneElement(child as React.ReactElement<any>, {
              className: switcherVariants({
                className: (child as React.ReactElement<any>).props.className,
                variant:
                  (child as React.ReactElement<any>).props.value === value
                    ? variant || "primary"
                    : "inactive",
              }),
              onClick: () =>
                handleClick((child as React.ReactElement<any>).props.value),
            });
          }
          return child;
        })}
        <div
          className={switcherBorderVariants({
            bordersize: bordersize,
            className: "",
          })}
          style={{ ...underlineStyle, background: activeColor }}
        />
      </div>
    );
  }
);

export { BoloTabSwitcher, BoloTab };
