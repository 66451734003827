import { PriceSubTotalType, TableSchema } from "../pdfme/common/src/type";
import _ from "lodash";
import { getWorkSpaceDetails } from ".";
import { DocumentValueType } from "../pdfme/ui/src/components/Designer";
import { COLORS } from "../constants/common";

let PRECISION = 2;

function formatPrice({
  amount,
  currency,
  locale,
}: {
  amount: number;
  currency: string;
  locale?: string;
}): string {
  try {
    const userLocale = locale || navigator.language;

    return new Intl.NumberFormat(userLocale, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    }).format(amount);
  } catch (error) {
    console.log("error in formatPrice");
    // console.log(error.message);
    return `${currency || ""} ${amount}`;
  }
}

function formatPercent({ percent }: { percent: number }): string {
  return `${percent || 0}%`;
}

function isValidArg({
  value,
  argType,
}: {
  value: number;
  argType: "price" | "qty" | "percent";
}): boolean {
  if (argType == "qty") {
    return typeof value === "number" && value > 0;
  }

  if (argType == "price") {
    return typeof value === "number" && value >= 0;
  }

  if (argType === "percent") {
    return typeof value === "number" && value >= 0 && value <= 100;
  }

  return false;
}

function getRowSubtotal({ price, qty }: { price: number; qty: number }): {
  subtotal: number;
} {
  let subtotal: number;
  subtotal = price * qty;
  subtotal = _.round(subtotal, PRECISION);
  return { subtotal };
}

function getTableGrandTotal({
  schema,
}: {
  schema: TableSchema;
}): PriceSubTotalType | undefined {
  if (!schema.subtotalMenu) {
    return schema.subtotalMenu;
  }

  let currency = schema.subtotalMenu?.currency;
  let subTotalAmount = 0;
  let discountAmount = 0;
  let feeAmount = 0;
  let taxAmount = 0;
  let totalAmount = 0;

  let rows = schema.rows || [];

  for (let row of rows) {
    let rowSubTotal = row?.[3]?.subtotal || 0;
    subTotalAmount += rowSubTotal;
  }

  totalAmount = subTotalAmount;

  // discount ===========================================================
  if (schema.subtotalMenu.discount.format === "percent") {
    let discountPercent = schema.subtotalMenu.discount.percentage;
    discountAmount = totalAmount * (discountPercent / 100);
    discountAmount = _.round(discountAmount, PRECISION);
  }

  if (schema.subtotalMenu.discount.format === "absolute") {
    discountAmount = schema.subtotalMenu.discount.amount;
    if (discountAmount > 0) {
      let discountPercent = _.round(
        (discountAmount / totalAmount) * 100,
        PRECISION
      );
      schema.subtotalMenu.discount.percentage = discountPercent;
    }
  }
  totalAmount -= discountAmount;
  // discount ===========================================================

  // fee ===========================================================
  if (schema.subtotalMenu.fee.format === "percent") {
    let feePercent = schema.subtotalMenu.fee.percentage;
    feeAmount = totalAmount * (feePercent / 100);
    feeAmount = _.round(feeAmount, PRECISION);
  }

  if (schema.subtotalMenu.fee.format === "absolute") {
    feeAmount = schema.subtotalMenu.fee.amount;
    if (feeAmount > 0) {
      let feePercent = _.round((feeAmount / totalAmount) * 100, PRECISION);
      schema.subtotalMenu.fee.percentage = feePercent;
    }
  }
  totalAmount += feeAmount;
  // fee ===========================================================

  // tax ===========================================================
  if (schema.subtotalMenu.tax.format === "percent") {
    let taxPercent = schema.subtotalMenu.tax.percentage;
    taxAmount = totalAmount * (taxPercent / 100);
    taxAmount = _.round(taxAmount, PRECISION);
  }

  if (schema.subtotalMenu.tax.format === "absolute") {
    taxAmount = schema.subtotalMenu.tax.amount;
    if (taxAmount > 0) {
      let taxPercent = _.round((taxAmount / totalAmount) * 100, PRECISION);
      schema.subtotalMenu.fee.percentage = taxPercent;
    }
  }

  totalAmount += taxAmount;
  // tax ===========================================================

  totalAmount = Math.max(0, totalAmount);

  return {
    currency,
    subtotal: {
      amount: subTotalAmount,
      data: formatPrice({ amount: subTotalAmount, currency }),
    },
    discount: {
      amount: discountAmount,
      percentage: schema.subtotalMenu.discount.percentage,
      data:
        schema.subtotalMenu.discount.format === "absolute"
          ? formatPrice({ amount: discountAmount, currency })
          : formatPercent({ percent: schema.subtotalMenu.discount.percentage }),
      format: schema.subtotalMenu.discount.format,
      show2Rec: schema.subtotalMenu.discount.show2Rec,
    },
    fee: {
      amount: feeAmount,
      percentage: schema.subtotalMenu.fee.percentage,
      data:
        schema.subtotalMenu.fee.format === "absolute"
          ? formatPrice({ amount: feeAmount, currency })
          : formatPercent({ percent: schema.subtotalMenu.fee.percentage }),
      format: schema.subtotalMenu.fee.format,
      show2Rec: schema.subtotalMenu.fee.show2Rec,
    },
    tax: {
      amount: taxAmount,
      percentage: schema.subtotalMenu.tax.percentage,
      data:
        schema.subtotalMenu.tax.format === "absolute"
          ? formatPrice({ amount: taxAmount, currency })
          : formatPercent({ percent: schema.subtotalMenu.tax.percentage }),
      format: schema.subtotalMenu.tax.format,
      show2Rec: schema.subtotalMenu.tax.show2Rec,
    },
    total: {
      amount: totalAmount,
      data: formatPrice({ amount: totalAmount, currency }),
    },
  };
}

/**
 * Gets the default currency from the workspace details.
 *
 * This function retrieves the workspace details and extracts the default currency from the current workspace settings.
 * If the default currency is not specified, it defaults to "USD".
 *
 * Note: This function should not be called outside of a component context as it will cause the login page to reload infinitely.
 *
 * @returns {string} The default currency, or "USD" if not specified.
 */
function getDefaultCurrency(): string {
  const workSpaceDetails = getWorkSpaceDetails();
  return workSpaceDetails?.currentWorkSpace?.settings?.defaultCurrency || "USD";
}

function getStatusByPayment({ document }: { document: any }): {
  status: string;
  isPayment: boolean;
  paymentPending: boolean;
  color: string;
  backGround: string;
  message: string;
} {
  let message = "";
  let msgArr = [];
  let status =
    document?.status === "PAID"
      ? "PAID"
      : document?.isAllSigned
      ? "SIGNED"
      : document.status;
  let isPayment = false;
  let paymentPending = false;
  let color = COLORS.status.failure.primary; // #ef4444
  let backGround = COLORS.status.failure.secondary;
  let documentValue: DocumentValueType = document?.settings?.documentValue;
  let amount = 0;

  // approval logic
  let isApproval = false;
  let isApproved = false;
  let approvalWorkflow = document?.settings?.approvalWorkflow;
  let statusNotApproved = ["REJECTED", "SENT FOR APPROVAL"];

  if (documentValue?.valueType == "CUSTOM") {
    amount = documentValue?.amount?.customAmount;
  }
  if (documentValue?.valueType == "DOCUMENT_TOTAL") {
    amount = documentValue?.amount?.valueTotal;
  }

  isPayment = amount > 0;
  paymentPending = isPayment && status === "SIGNED";

  isApproval =
    approvalWorkflow?.approvers?.length > 0 && approvalWorkflow?.enabled;
  isApproved = isApproval && !statusNotApproved.includes(status);

  if (status === "DRAFT") {
    color = COLORS.status.intermediate.primary; // #EAB308
    backGround = COLORS.status.intermediate.secondary;
  }

  if (status === "SIGNED" || status === "PAID") {
    color = COLORS.status.success.primary; // #22C55E
    backGround = COLORS.status.success.secondary;
  }

  if (isApproved) {
    msgArr.push("Document approved");
  }

  if (paymentPending) {
    msgArr.push("Waiting for payment");
  }

  if (status === "SENT FOR APPROVAL") {
    color = COLORS.status.approved.primary;
    backGround = COLORS.status.approved.secondary;
  }
  if (status === "SENT" || status === "SENT VIA LINK") {
    color = COLORS.status.sent.primary;
    backGround = COLORS.status.sent.secondary;
  }

  message = msgArr.join(", ");
  return {
    status,
    isPayment,
    paymentPending,
    color,
    backGround,
    message,
  };
}

export {
  getRowSubtotal,
  getTableGrandTotal,
  formatPrice,
  isValidArg,
  getDefaultCurrency,
  getStatusByPayment,
};
