import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  BoloTab,
  BoloTabSwitcher,
} from "../../Common/BoloTabSwitcher/BoloTabSwitcher";
import APIKey from "../APIKey/APIKey";
import Embed from "../Embed/Embed";

const DeveloperSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="flex flex-col md:mx-4">
      <div className="mb-4">
        <h3 className="text-md font-semibold mb-4">Developer Section</h3>
        {/* <p className="text-xs text-gray-500 mb-4">
          Now edit documents without leaving your website, integrate BoloForms
          into your website
        </p> */}
        <BoloTabSwitcher
          value={searchParams.get("subType") || "APIKEY"}
          onChangeTab={(tab) => {
            searchParams.set("subType", tab);
            setSearchParams(searchParams);
          }}
        >
          <BoloTab value="APIKEY" size={"sm"}>
            {" "}
            API Key
          </BoloTab>
          <BoloTab value="EMBED" size={"sm"}>
            Embed Code
          </BoloTab>
        </BoloTabSwitcher>
      </div>
      <div>
        {(!searchParams.get("subType") ||
          searchParams.get("subType") === "APIKEY") && <APIKey />}
        {searchParams.get("subType") === "EMBED" && <Embed />}
      </div>
    </div>
  );
};

export default DeveloperSection;
