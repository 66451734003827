import React from "react";
import { IconType } from "react-icons";
import { FiPlusSquare } from "react-icons/fi";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { VscEmptyWindow } from "react-icons/vsc";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";

const NoDocumentsV1 = ({
  //   header,
  //   desc,
  //   btnText,
  //   Icon,
  cb,
  resourceType,
}: {
  //   header: string;
  //   desc: string;
  //   btnText: string;
  //   Icon: IconType;
  resourceType: "DOCUMENT" | "FOLDER" | "ASSIGNED2ME" | "FORM";
  cb: () => void;
}) => {
  const workSpaceDetails = useWorkSpaceDetails();
  const data = {
    FORM: {
      header: "No Forms !",
      desc: workSpaceDetails?.isWorkSpaceOwner
        ? "You haven't been assigned any forms yet"
        : "No forms are assigned to you yet",
      btnText: "Create your own",
      Icon: FiPlusSquare,
    },
    ASSIGNED2ME: {
      header: "No Documents !",
      desc: workSpaceDetails?.isWorkSpaceOwner
        ? "You haven't been assigned any documents yet"
        : "No documents are assigned to you yet",
      btnText: "Create your own",
      Icon: FiPlusSquare,
    },
    DOCUMENT: {
      header: "No Documents !",
      desc: workSpaceDetails?.isWorkSpaceOwner
        ? "You haven't created any documents yet"
        : "No documents are assigned to you yet",
      btnText: "Create your own",
      Icon: FiPlusSquare,
    },
    FOLDER: {
      header: "No Folders !",
      desc: workSpaceDetails?.isWorkSpaceOwner
        ? "You haven't created any folders yet"
        : "No folders are assigned to you yet",
      btnText: "Create your own",
      Icon: MdOutlineCreateNewFolder,
    },
  };
  const { Icon, header, desc, btnText } = data[resourceType];

  return (
    <div className="border-2 border-primary rounded-md mb-4 w-[100%] md:w-[400px] shadow hover:scale-[1.02] hover:shadow-xl transition-all duration-300">
      <VscEmptyWindow className="text-9xl text-primary p-8 m-auto" />
      <h1 className="text-lg md:text-2xl font-medium mb-2 text-center">
        {header}
      </h1>
      <h2 className="text-xs text-center">{desc}</h2>
      <button
        onClick={() => {
          cb();
        }}
        className="w-full mt-6 px-6 py-3 bg-primary text-white text-sm hover:opacity-90 border-primary border"
      >
        {btnText}
      </button>
    </div>
  );
};

export default NoDocumentsV1;
