import { useEffect, useRef, useState } from "react";

import axios from "../../utils/axios";
// import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import useFetch from "../../hooks/useFetch";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
import { fetchOwner } from "../../utils";
import { Template } from "../../pdfme/generator/src";
import { Designer } from "../../pdfme/ui/src";
import TrialExtendedModal from "../TrialExtendedModal";
import { increaseTrialEndDateByOneDay } from "../../redux/slices/ownerSlice";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../../redux/store";

interface MapperProps {
  file?: File | null;
  numberOfPages?: number;
  schemas?: any;
  sampledata?: any;
  isPreview?: boolean;
  isSaved?: boolean;
  startSave?: boolean;
  setIsSaved?: (value: any) => void;
  setIsPreview: (value: any) => void;
  setStartSave: (value: any) => void;
  setReload: (value: any) => void;
  fetchedData: any;
}

const PdfMapper = ({
  file,
  numberOfPages,
  schemas,
  sampledata,
  startSave,
  setIsPreview,
  setStartSave,
  setIsSaved,
  fetchedData,
  setReload,
}: MapperProps) => {
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);
  const [smallDisplay, setSmallDisplay] = useState(true);
  const [owner, setOwner] = useState<any>({});
  const [formQuestions, setFormQuestions] = useState<any>([]);
  const [templateSchema, setTemplateSchema] = useState<any>([]);
  const [templateSampleData, setTemplateSampleData] = useState<any>([]);
  const [searchParams] = useSearchParams();
  let formId = searchParams.get("id");
  const [data, setData] = useState({});

  const dispatch = useAppDispatch();

  let trialExtended = searchParams.get("trialExtended");

  const [showTrialExtendedModal, setShowTrialExtendedModal] = useState(
    // trialExtended ? true : false
    false
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/private-forms?formId=${formId}`);
      setData(response?.data || {});
    };
    fetchData();
  }, [formId]);

  let { forms }: any = data || {};

  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
    };
    updatedOwner();
  }, []);

  useEffect(() => {
    if (forms) {
      const allQuestions = forms[0].formJson.pages.flatMap(
        (page: any) => page.questions
      );
      setFormQuestions(allQuestions);
    }
  }, [forms]);

  function isObjectEmpty(obj: object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  useEffect(() => {
    if (numberOfPages && formQuestions.length > 0) {
      const schemas = [];
      const schemaPage: any = {};
      let question = formQuestions[0];
      const key = question.title.replace(/[^a-zA-Z0-9]/g, ""); // Removing special characters from the title to use it as a key
      if (question.type === "Text") {
        schemaPage[key] = {
          type: "text",
          position: {
            x: 25.06,
            y: 26.35,
          },
          width: 77.77,
          height: 18.7,
          fontSize: 20,
          fontColor: "#14b351",
        };
      } else if (question.type === "Signature") {
        schemaPage[key] = {
          type: "image",
          position: {
            x: 24.99,
            y: 65.61,
          },
          width: 60.66,
          height: 93.78,
        };
      }
      // @ts-ignore
      schemas.push(schemaPage);
      setTemplateSchema(schemas);
      // const sampledata = [];
      // const pageData: any = {};
      // for (const key in schemas[0]) {
      //   if (schemas[0][key].type === "text") {
      //     pageData[key] = "Example..";
      //   } else if (schemas[0][key].type === "image") {
      //     // Using a placeholder image URL from picsum.photos
      //     pageData[key] = "https://picsum.photos/200/300";
      //   }
      // }
      // sampledata.push(pageData);
      setTemplateSampleData(sampledata);
    }
  }, [numberOfPages, formQuestions, sampledata]);

  useEffect(() => {
    setSmallDisplay(window.innerWidth < 900);
  }, []);

  // @ts-ignore
  useEffect(() => {
    const template: Template = {
      // schemas: templateSchema,
      schemas: schemas || [{}],
      // sampledata: [{ Name: "Input example", photo: "https://picsum.photos/200/300"}],
      sampledata: sampledata || [{}],
      // @ts-ignore
      basePdf: file,
    };

    const inputs = [
      {
        name: "Pet Name",
        photo: "https://picsum.photos/200/300",
        age: "4 years",
        sex: "Male",
        weight: "33 pounds",
        breed: "Mutt",
        owner: "https://pdfme.com/",
      },
    ];
    // Run this effect when the containerRef is available
    if (designerRef.current) {
      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
        formQuestions,
        fetchedData,
        setReload,
        setIsPreview: setStartSave,
        instanceFrom: "FORM",
      });
      designer.current.onSaveTemplate(saveTemplate);
      // designer.current.onChangeTemplate(setTemplate);
    }
    return () => {
      // Destroy the designer when the component unmounts
      // @ts-ignore
      // designer.current.destroy();
    };
  }, [designerRef, file, formQuestions]);

  const saveTemplate = async () => {
    // downloadJsonFile(designer.current.getTemplate(), 'template');
    // @ts-ignore
    let template = designer.current.getTemplate();
    console.log(template, "template");
    try {
      const { data } = await axios.post("/forms/update-schemas", {
        formId,
        schemas: template.schemas,
        sampledata: template.sampledata,
        columns: template.columns,
      });
      if (!data.error) {
        toast.success(data.message);
        // trialExtended && setShowTrialExtendedModal(true);

        if (trialExtended) {
          dispatch(increaseTrialEndDateByOneDay());
          setShowTrialExtendedModal(true);
        }
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (template: any) => {
    try {
      const { data } = await axios.post("/forms/update-schemas", {
        formId,
        schemas: template.schemas,
        sampledata: template.sampledata,
        columns: template.columns,
      });
      if (data.error) {
        toast.error(data.error);
      }
      setIsSaved && (await setIsSaved((prev: boolean) => !prev));

      if (trialExtended) {
        dispatch(increaseTrialEndDateByOneDay());
        setShowTrialExtendedModal(true);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error saving template");
    }
  };

  const handlePreviewAndSave = async () => {
    if (startSave) {
      // @ts-ignore
      let template = designer.current.getTemplate();
      await handleSave(template);
      setStartSave && (await setStartSave(false));
      setIsPreview && setIsPreview(true);
    }
  };

  useEffect(() => {
    handlePreviewAndSave();
  }, [startSave]);
  return (
    <div className="">
      <div className="overflow-hidden" ref={designerRef} id="container"></div>{" "}
      {/* This will be used as the container */}
      {/* <button className="p-2 rounded-lg bg-blue-300" onClick={saveTemplate}>
        Save template
      </button> */}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
      {showTrialExtendedModal && (
        <TrialExtendedModal
          setShow={() => {
            setShowTrialExtendedModal(false);
          }}
          show={showTrialExtendedModal}
          completedTask={{
            title: "Add custom PDF to your form",
            description: "Customize your form with a PDF template",
            increasedDays: 1,
          }}
        />
      )}
    </div>
  );
};

export default PdfMapper;
