import { cva, type VariantProps } from "class-variance-authority";
import { IconBaseProps } from "react-icons";
import { CgTemplate } from "react-icons/cg";
import { MdFormatListBulleted } from "react-icons/md";
import { TbPdf } from "react-icons/tb";
import { COLORS } from "../../../constants/common";
import { cn } from "../lib/utils";

export const IconVariants = cva("p-[12%]", {
  variants: {
    bolosize: {
      xs: "rounded-sm text-lg p-[1px]",
      sm: "rounded-sm text-xl p-[2px]",
      lg: "rounded-sm text-2xl p-[3px]",
      xl: "rounded-sm text-3xl p-[4px]",
      "create-page": "rounded-2xl text-[80px] p-[16px]",
    },
  },
  defaultVariants: {
    bolosize: "lg",
  },
});

interface BoloIconProps
  extends IconBaseProps,
    VariantProps<typeof IconVariants> {}

const BoloIconForm: React.FC<BoloIconProps> = ({
  className,
  bolosize,
  size,
  ...props
}) => {
  //   console.log({
  //     className,
  //     c1: cn("text-[#7248B9] bg-[#7248B9]/20", className),
  //     c2: IconVariants({
  //       className: cn("text-[#7248B9] bg-[#7248B9]/20", className),
  //       bolosize,
  //     }),
  //   });
  return (
    <MdFormatListBulleted
      style={{
        background: COLORS.icons.FORM + 30,
        color: COLORS.icons.FORM,
      }}
      className={IconVariants({
        className: cn("", className),
        bolosize,
      })}
      {...props}
    />
  );
};

const BoloIconPDFTemplate: React.FC<BoloIconProps> = ({
  className,
  bolosize,
  size,
  ...props
}) => {
  return (
    <CgTemplate
      style={{
        background: COLORS.icons["PDF-TEMPLATE"] + 30,
        color: COLORS.icons["PDF-TEMPLATE"],
      }}
      className={IconVariants({
        className: cn(" p-[10%]", className),
        bolosize,
      })}
      {...props}
    />
  );
};

const BoloIconPDF: React.FC<BoloIconProps> = ({
  className,
  bolosize,
  size,
  ...props
}) => {
  return (
    <TbPdf
      style={{ background: COLORS.icons.PDF + 30, color: COLORS.icons.PDF }}
      className={IconVariants({
        className: cn(" p-[10%]", className),
        bolosize,
      })}
      {...props}
    />
  );
};

export { BoloIconForm, BoloIconPDF, BoloIconPDFTemplate };
