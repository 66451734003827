// import { SchemaForUI } from '@pdfme/common';
import { v4 } from "uuid";
import { SidebarProps } from "..";
import { BoloButton } from "../../../../../../../components/Common/Button/BoloButton";
import { SchemaForUI } from "../../../../../../common/src";
import { uuid } from "../../../../helper";
const CloneField = (
  props: Pick<
    SidebarProps,
    | "changeSchemas"
    | "pageNum"
    | "pageCursor"
    | "schemasList"
    | "addSchema"
    | "setSchemasList"
  > & { activeSchema: SchemaForUI }
) => {
  const { activeSchema, pageCursor, schemasList, setSchemasList } = props;

  const handleDuplicateToAllPages = (isAll: boolean = false) => {
    const clonedSchema = { ...activeSchema }; // Clone the active schema
    const generateUniqueKeyOld = (
      baseKey: string,
      explicitPageCursor: number
    ) => {
      const pageSchemas = schemasList[explicitPageCursor];
      let uniqueKey = baseKey.replace(/\n$/, "");
      let index = 1;
      // let uniqueKey = baseKey;

      // @ts-ignore
      while (pageSchemas.some((schema) => schema.key === uniqueKey)) {
        uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
        index++;
      }
      return uniqueKey;
    };

    const generateUniqueKey = (baseKey: string, explicitPageCursor: number) => {
      const pageSchemas = schemasList[explicitPageCursor];
      let uniqueKey = baseKey.replace(/\n$/, "");
      let index = 1;
      while (
        pageSchemas.some(
          (schema) => schema.key === uniqueKey + `(${explicitPageCursor + 1})`
        )
      ) {
        uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
        index++;
      }
      uniqueKey = `${uniqueKey}(${explicitPageCursor + 1})`;
      return uniqueKey;
    };

    const newSchemasList = schemasList.map((page, index) => {
      if (!isAll && index <= pageCursor) {
        return page;
      }
      if (isAll && index === pageCursor) {
        return page;
      }
      let baseKey = clonedSchema.key.includes("___")
        ? clonedSchema.key.split("___")[0]
        : clonedSchema.key.split("(")[0];
      let prevId = clonedSchema.id.includes("_")
        ? clonedSchema.id.split("_")[1]
        : v4();
      let thisPagesSchema: SchemaForUI = {
        ...clonedSchema,
        key: generateUniqueKey(baseKey, index),
        // id: uuid() + "_" + v4(),
        id: uuid() + "_" + prevId,
      };

      return [...page, thisPagesSchema];
    });

    setSchemasList(newSchemasList);

    // Update the state or data source with the new schemas list
    // For example: changeSchemas(newSchemasList);
  };

  return (
    <div className="mt-4 text-xs flex flex-col gap-2">
      <BoloButton
        variant={"tertiary"}
        size="xs"
        onClick={() => {
          handleDuplicateToAllPages(true);
        }}
      >
        Duplicate to all Pages
      </BoloButton>
      <BoloButton
        variant={"tertiary"}
        size="xs"
        onClick={() => {
          handleDuplicateToAllPages();
        }}
      >
        Duplicate to following Pages
      </BoloButton>
    </div>
  );
};

export default CloneField;
