import React from "react";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";

const NavButton = ({ recordCount }: { recordCount: number }) => {
  const { getSearchData, setSearchData } = useQueryHook();
  return (
    <div className="hidden md:flex flex-row h-full text-gray-700 mr-4">
      <button
        onClick={() => {
          let data = getSearchData();
          setSearchData({ pageNo_: data.pageNo_ - 1 });
        }}
        disabled={getSearchData().pageNo_ === 1}
        className="border p-3 shadow hover:bg-gray-200 cursor-pointer hover:opacity-90 rounded-l-md"
      >
        <MdOutlineArrowBackIos />
      </button>
      <button
        onClick={() => {
          let data = getSearchData();
          setSearchData({ pageNo_: data.pageNo_ + 1 });
        }}
        disabled={
          getSearchData().pageNo_ * getSearchData().limit_ >= recordCount
        }
        className="border p-3 shadow hover:bg-gray-200 cursor-pointer hover:opacity-90 rounded-r-md"
      >
        <MdOutlineArrowForwardIos />
      </button>
    </div>
  );
};

export default NavButton;
