import React from "react";

import { MdClose } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";
import { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "../../../../../utils/axios";
import { TiWarningOutline } from "react-icons/ti";
interface FormData {
  firstReminder: number;
  repeat: string;
}

const ReminderModal = ({
  setSelectedSetting,
  documentId,
  settings,
  isSendViaSMSDocument,
}: any) => {
  const initialData: FormData = {
    firstReminder: 5,
    repeat: "every week",
  };
  const [checks, setChecks] = useState([false, false]);
  const [form, setForm] = useState(initialData);
  const [isDaysValid, setIsDaysValid] = useState(true);
  const options = ["every week", "every 3 days"];
  const repeatRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Function to fill the form and set checks based on settings.reminders
    const fillFormFromSettings = () => {
      if (settings && settings?.reminders && settings?.reminders.length > 0) {
        const firstReminder = settings.reminders.find(
          (reminder: any) => reminder.type === "FIRST"
        );
        const recurringReminder = settings.reminders.find(
          (reminder: any) => reminder.type === "RECURRING"
        );
        let arr = [...checks];
        if (firstReminder) {
          arr[0] = true;
          setForm((form) => ({ ...form, firstReminder: firstReminder.days }));
        }
        if (recurringReminder) {
          arr[1] = true;
          setForm((form) => ({
            ...form,
            repeat:
              recurringReminder.recurringInterval === "week"
                ? "every week"
                : "every 3 days",
          }));
        }
        setChecks(arr);
      }
    };

    fillFormFromSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (
      !form?.firstReminder ||
      form?.firstReminder < 1 ||
      form?.firstReminder > 3650
    ) {
      setIsDaysValid(false);
    } else {
      setIsDaysValid(true);
    }
  }, [form?.firstReminder]);

  const fillForm = async () => {
    try {
      let arr = [];
      if (checks[0]) {
        arr.push({
          type: "FIRST",
          days: form?.firstReminder,
        });
      }
      if (checks[1]) {
        arr.push({
          type: "RECURRING",
          recurringInterval: form?.repeat === "every week" ? "week" : "3day",
        });
      }
      const response = await axios.post("/save-reminder", {
        reminders: arr,
        documentId,
      });
      if (response.status === 200) {
        toast.success("Reminder saved");
        setSelectedSetting("");
      }
    } catch (error) {
      toast.error("Error saving reminder");
      console.error(error);
      // Handle error
    }
  };
  const clearReminder = async () => {
    try {
      const response = await axios.post("/save-reminder", {
        reminders: [],
        documentId,
      });
      if (response.status === 200) {
        toast.success("Reminder removed");
        setSelectedSetting("");
      }
    } catch (error) {
      toast.error("Error saving reminder");
      console.error(error);
    }
  };
  const toggleChecks = (index: number) => {
    let arr = [...checks];
    arr[index] = !arr[index];
    setChecks(arr);
  };
  const Checkbox = ({ index }: any) => {
    if (checks[index]) {
      return (
        <MdCheckBox
          className="w-6 h-6 text-primary p-0 cursor-pointer"
          onClick={() => {
            toggleChecks(index);
          }}
        />
      );
    } else {
      return (
        <MdCheckBoxOutlineBlank
          className="w-6 h-6 text-gray-400 hover:bg-gray-100 p-0 cursor-pointer"
          onClick={() => {
            toggleChecks(index);
          }}
        />
      );
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (repeatRef.current !== null) {
        //@ts-ignore
        if (repeatRef.current.contains(e.target)) return;
      }
      setIsOpen(false);
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.4)",
        zIndex: 100,
      }}
      className="fixed top-0 left-0 w-full h-screen  flex justify-center items-start md:items-center p-4"
    >
      <div
        className={`w-[540px] max-w-full bg-white rounded-md px-5 md:px-8 py-6`}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-gray-700 text-md font-semibold">
            Auto reminders settings
          </h2>
          <MdClose
            onClick={() => {
              setSelectedSetting("");
            }}
            className="text-2xl cursor-pointer"
          />
        </div>
        <p className="w-[360px] max-w-full text-sm text-gray-500">
          Automatically send email reminders to signers who have not yet
          completed the document.
        </p>
        <p className="mt-4 mb-3 text-primary text-sm font-semibold">
          FIRST REMINDER
        </p>
        <div className="flex justify-start items-center mb-2 gap-2">
          <Checkbox index={0} />
          <span className="text-sm">Send first reminder in </span>
          <div
            className={` w-20 border-b pb-1${
              isDaysValid
                ? "focus-within:border-primary border-gray-300"
                : " border-red-500 focus-within:border-red-500"
            }`}
          >
            <input
              type="number"
              className="outline-none w-10 text-sm disabled:text-gray-400"
              placeholder="5"
              value={form?.firstReminder}
              min={1}
              max={3650}
              onInput={(e: any) => {
                const inputValue = e.target.value;
                const firstFourDigits = inputValue.slice(0, 4);
                setForm({ ...form, firstReminder: parseInt(firstFourDigits) });
              }}
              disabled={!checks[0]}
            />
            <span className="text-sm text-gray-500 float-right">
              {form?.firstReminder === 1 || !form?.firstReminder
                ? "day"
                : "days"}
            </span>
          </div>
        </div>
        {!isDaysValid && checks[0] ? (
          <p className="text-red-500 text-sm mb-3">
            Please enter an integer between 1 and 3650.
          </p>
        ) : null}
        <p className="mt-5 mb-3 text-primary text-sm font-semibold">
          RECURRING REMINDERS
        </p>
        <div className="flex justify-start items-start mb-5 gap-2">
          <Checkbox index={1} />
          <span className="text-sm">Repeat</span>
          <div
            onClick={() => {
              if (checks[1]) {
                setIsOpen(!isOpen);
              }
            }}
            className={`text-sm border-b  pb-2 cursor-pointer px-2 w-32 flex justify-between items-center relative ${
              !checks[1] ? "text-gray-400 hover:cursor-auto" : null
            } ${isOpen ? "border-b-primary" : "border-b-gray-300"}`}
          >
            <span>{form?.repeat}</span>
            <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>

            {isOpen && (
              <div
                ref={repeatRef}
                className="py-[2px] w-32 bg-white border border-[#0000001f] rounded z-50 absolute top-[30px] left-0"
              >
                {options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className={`"bg-[#e4f0fc]"
                      } w-full py-1 px-3 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                      onClick={() => {
                        setForm({ ...form, repeat: option });
                        setIsOpen(false);
                      }}
                    >
                      {option}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <span className="text-sm">until completed or expired</span>
        </div>

        {/* <div className="text-xs font-medium text-gray-700 flex flex-col gap-2 bg-yellow-100 border border-yellow-500 rounded-md w-full p-2 mb-4">
          <span className="font-semibold flex gap-2 items-center">
            <TiWarningOutline size={20} className="text-yellow-600" />
            Note:
          </span>

          <p>
            When sending documents via SMS, reminders will be sent as long as
            there is enough credit. If credits are insufficient, reminders will
            be stopped.
          </p>
        </div> */}

        {settings &&
          settings?.reminders &&
          parseInt(settings?.reminders?.length) > 0 && (
            <button
              className="items-center justify-center py-2 mb-5 px-4 cursor-pointer text-sm border-2 border-primary text-primary rounded float-left disabled:hover:cursor-auto mt-4"
              onClick={clearReminder}
            >
              Remove
            </button>
          )}
        <button
          className="flex items-center justify-center py-2 mb-5 px-4 cursor-pointer text-sm bg-primary text-white rounded float-right disabled:hover:cursor-auto mt-4"
          onClick={fillForm}
          disabled={
            !(
              (checks[0] && isDaysValid) ||
              (checks[1] && !checks[0]) ||
              (checks[1] && checks[0] && isDaysValid)
            )
          }
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default ReminderModal;
