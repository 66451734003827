import React from "react";
import { MdInfo } from "react-icons/md";
import CustomTooltip from "../../../../components/EditForm/CustomTooltip";

const InfoContent = ({ helper, id }: { helper: string; id: string }) => {
  return (
    <div data-tooltip-id={id} className="my-auto">
      <MdInfo
        size={18}
        className="cursor-pointer text-gray-600 hover:text-primary-bolo"
      />
      <CustomTooltip
        helpers={{
          id,
          content: helper,
          place: "right",
        }}
      />
    </div>
  );
};

export default InfoContent;
