// @ts-nocheck

import React from "react";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";
import {
  AiOutlineDelete,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";

const Checkbox = ({ data }) => {
  let { question, setFormState, isEditor, questionNo, formState } = data;
  const [isShowOtherOptionTextInput, setIsShowOtherOptionTextInput] =
    React.useState(false);

  const handleOptionAddDelete = (option, index) => {
    if (option.enabled && option.type === "option") {
      const newOptions = [...question.options];
      newOptions.splice(index, 1);
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.options = newOptions;
            }
          });
        });
        return newState;
      });
    } else if (option.type === "other") {
      const newOptions = [...question.options];
      newOptions[index].enabled = !option.enabled;
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.options = newOptions;
              question_.hasOtherOption = newOptions[index].enabled;
            }
          });
        });

        return newState;
      });
    } else {
      const newOptions = [...question.options];
      option.text = "Option " + newOptions.length;

      let newOption = {
        type: "option",
        text: `Option ${newOptions.length - 1}`,
        value: "",
        enabled: true,
      };
      newOptions.splice(index, 0, newOption);
      // console.log(newOptions);
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.options = newOptions;
            }
          });
        });
        return newState;
      });
    }
  };

  const setIsShowOtherOption = (question) => {
    let show = false;
    question.options.forEach((option) => {
      if (option.type === "other") {
        show =
          question.value.findIndex((value) => value === option.text) !== -1;
      }
    });

    setIsShowOtherOptionTextInput(show);
  };

  return (
    <div className="mb-4">
      <div className="w-full flex flex-row ">
        {/* {questionNo && <div className="mr-2">{questionNo}.</div>} */}
        <div className="w-full">
          <div className="mb-4">
            <div className="flex flex-col">
              <div className="flex flex-row">
                {isEditor ? (
                  <input
                    type="text"
                    value={question.title}
                    placeholder="Question"
                    onChange={(e) => {
                      setFormState((prevState) => {
                        let newState = { ...prevState };
                        newState.pages.forEach((page) => {
                          page.questions.forEach((question_) => {
                            if (question_.id === question.id) {
                              question_.title = e.target.value;
                            }
                          });
                        });
                        return newState;
                      });
                    }}
                    className="w-full"
                    disabled={!isEditor}
                  />
                ) : (
                  <div className="text-gray-700 dark:text-gray-300 text-xs flex flex-row">
                    <div
                      className="w-full"
                      dangerouslySetInnerHTML={{
                        __html: question.titleHtml ?? question.title,
                      }}
                    ></div>
                    {question?.required && (
                      <div className="text-red-500 h-fit  ml-1">*</div>
                    )}
                  </div>
                )}
              </div>
              {isEditor ? (
                <input
                  className="text-xs mt-1"
                  type="text"
                  value={question.description}
                  placeholder="Description"
                  onChange={(e) => {
                    setFormState((prevState) => {
                      let newState = { ...prevState };
                      newState.pages.forEach((page) => {
                        page.questions.forEach((question_) => {
                          if (question_.id === question.id) {
                            question_.description = e.target.value;
                          }
                        });
                      });
                      return newState;
                    });
                  }}
                  disabled={!isEditor}
                />
              ) : (
                question.description && (
                  <div
                    className="w-full text-xs text-gray-500 mt-1"
                    dangerouslySetInnerHTML={{
                      __html: question.descriptionHtml ?? question.description,
                    }}
                  ></div>
                )
              )}
            </div>
          </div>
          {isEditor &&
            question?.options?.map((item, index) => {
              return (
                <div key={index} className="flex flex-row items-center mt-4">
                  {item.enabled ? (
                    <AiOutlineMinusCircle
                      onClick={() => handleOptionAddDelete(item, index)}
                      className="text-red-500 mr-8 cursor-pointer"
                    />
                  ) : (
                    <AiOutlinePlusCircle
                      onClick={() => handleOptionAddDelete(item, index)}
                      className="text-green-600 mr-8 cursor-pointer"
                    />
                  )}

                  <input
                    type="checkbox"
                    className="mr-2 h-4 w-4"
                    disabled={isEditor}
                    onChange={(e) => {
                      setFormState((prevState) => {
                        let newState = { ...prevState };
                        newState.pages.forEach((page) => {
                          page.questions.forEach((question_) => {
                            if (question_.id === question.id) {
                              question_.value = e.target.checked
                                ? [...question_.value, item.text]
                                : question_.value.filter(
                                    (item_) => item_ !== item.text
                                  );
                              setIsShowOtherOption(question_);
                            }
                          });
                        });
                        // console.log("newState", newState);
                        return newState;
                      });
                    }}
                  />

                  <input
                    type="text"
                    value={item.text}
                    onChange={(e) => {
                      const newOptions = [...question.options];
                      newOptions[index].text = e.target.value;
                      setFormState((prevState) => {
                        let newState = { ...prevState };
                        newState.pages.forEach((page) => {
                          page.questions.forEach((question_) => {
                            if (question_.id === question.id) {
                              question_.options = newOptions;
                            }
                          });
                        });
                        return newState;
                      });
                    }}
                    disabled={item.type === "add"}
                    className="text-sm ml-2 disabled:opacity-70 disabled:bg-transparent"
                  />
                </div>
              );
            })}
          {/* this options is for actual render */}
          {!isEditor &&
            question?.options?.map((item, index) => {
              return item.type === "option" ? (
                <div key={index} className="flex flex-row items-center mt-4">
                  <input
                    type="checkbox"
                    className="mr-2 min-h-4 min-w-4"
                    disabled={isEditor}
                    onChange={(e) => {
                      setFormState((prevState) => {
                        let newState = { ...prevState };
                        newState.pages.forEach((page) => {
                          page.questions.forEach((question_) => {
                            if (question_.id === question.id) {
                              // question_.value = [];
                              question_.value = e.target.checked
                                ? [...question_.value, item.text]
                                : question_.value.filter(
                                    (item_) => item_ !== item.text
                                  );
                              setIsShowOtherOption(question_);
                            }
                          });
                        });
                        // console.log("newState", newState);
                        return newState;
                      });
                    }}
                  />
                  <p className="text-xs text-gray-700 ml-2">{item.text}</p>
                </div>
              ) : (
                item.type === "other" && question?.hasOtherOption && (
                  <div className="flex flex-row items-center mt-4">
                    {isEditor &&
                      (item.enabled ? (
                        <AiOutlineMinusCircle
                          onClick={() => handleOptionAddDelete(item, index)}
                          className="text-red-500 mr-8 cursor-pointer"
                        />
                      ) : (
                        <AiOutlinePlusCircle
                          onClick={() => handleOptionAddDelete(item, index)}
                          className="text-green-600 mr-8 cursor-pointer"
                        />
                      ))}

                    <input
                      type="checkbox"
                      className="mr-2 min-h-4 min-w-4"
                      disabled={isEditor}
                      onChange={(e) => {
                        setFormState((prevState) => {
                          let newState = { ...prevState };
                          newState.pages.forEach((page) => {
                            page.questions.forEach((question_) => {
                              if (question_.id === question.id) {
                                question_.value = e.target.checked
                                  ? [...question_.value, item.text]
                                  : question_.value.filter(
                                      (item_) => item_ !== item.text
                                    );
                                setIsShowOtherOption(question_);
                              }
                            });
                          });
                          // console.log("newState", newState);
                          return newState;
                        });
                      }}
                    />

                    <p className="text-xs text-gray-700 ml-2 disabled:opacity-70 disabled:bg-transparent">
                      {item.text}
                    </p>
                  </div>
                )
              );
            })}
          {question?.hasOtherOption &&
            !isEditor &&
            isShowOtherOptionTextInput && (
              <div>
                <textarea
                  style={{
                    outlineColor: formState?.accentColor,
                  }}
                  type="text"
                  placeholder="Describe your answer"
                  className="border border-gray-300 mt-4 w-full py-1.5 px-4 rounded-md text-sm resize-none"
                  value={question.otherValue}
                  onChange={(e) => {
                    setFormState((prevState) => {
                      let newState = { ...prevState };
                      newState.pages.forEach((page) => {
                        page.questions.forEach((question_) => {
                          if (question_.id === question.id) {
                            question_.otherValue = e.target.value;
                          }
                        });
                      });
                      return newState;
                    });
                  }}
                />
              </div>
            )}
          {isEditor && (
            <div className="mt-4 flex">
              <button
                onClick={() => {
                  setFormState((prevState) => {
                    let newState = { ...prevState };
                    newState.pages.forEach((page) => {
                      page.questions.forEach((question_) => {
                        if (question_.id === question.id) {
                          question_.required = !question_.required;
                        }
                      });
                    });
                    return newState;
                  });
                }}
                className="ml-auto text-xs flex flex-row items-center"
              >
                {question.required ? (
                  <BiToggleRight className="text-lg mr-2 text-primary" />
                ) : (
                  <BiToggleLeft className="text-lg mr-2" />
                )}
                Required
              </button>
              <button
                onClick={() => {
                  setFormState((prevState) => {
                    let newState = { ...prevState };
                    newState.pages.map((page) => {
                      let questions = page.questions.filter(
                        (question_) => question_.id !== question.id
                      );
                      page.questions = questions;
                    });
                    // console.log(newState);
                    return newState;
                  });
                }}
                className="ml-6 text-xs flex flex-row items-center text-red-600"
              >
                <AiOutlineDelete className="text-lg mr-2" />
                Delete
              </button>
            </div>
          )}
          {/* required question error  */}
          {!isEditor && question.error && (
            <div className="text-red-500 text-[10px] mt-4">
              {"This is a required question"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
