// @ts-nocheck

import React, { useState, useEffect } from "react";
import "./index.css";
import { MdArrowDropDown, MdClose } from "react-icons/md";
import { isUndefined } from "lodash";

const dropdownOptionLabels = {
    number: "Number",
    text: "Text",
    length: "Length",
    regularExpression: "Regular expression",
    greaterThan: "Greater than",
    greaterThanOrEqualTo: "Greater than or equal to",
    lessThan: "Less than",
    lessThanOrEqualTo: "Less than or equal to",
    equalTo: "Equal to",
    notEqualTo: "Not equal to",
    between: "Between",
    notBetween: "Not between",
    isNumber: "Is number",
    wholeNumber: "Whole number",
    contains: "Contains",
    doesntContain: "Doesn't contain",
    email: "Email",
    url: "URL",
    maximumCharacterCount: "Maximum character count",
    minimumCharacterCount: "Minimum character count",
    matches: "Matches",
    doesntMatch: "Doesn't match",
};

function CustomDropDown({ isResponseValidationType, opts, setSelectedOpt, selectedOpt, setReponseValidationSubTypeOpts, setResponseValidationSubType, setFirstInputValue, setIsFirstInputError, setSecondInputValue, setIsSecondInputError, setCustomErrorText }) {
    let activeQuestion = opts.find((option) => option === selectedOpt);
    const questionTypeDropdownRef = React.useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [displacementAmount, setDisplacementAmount] = useState(0);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option, index) => {
        let displacement = -(index - 1) * 48;
        setDisplacementAmount(displacement);
        setIsOpen((prev) => !prev);
        if (option !== selectedOpt) {

            if (["between", "notBetween"].includes(option) || ["between", "notBetween"].includes(selectedOpt)) {

                if (!((option === "between" && selectedOpt === "notBetween") || (option === "notBetween" && selectedOpt === "between"))) {
                    setFirstInputValue('');
                    setIsFirstInputError(false);
                    setIsSecondInputError(false);
                    setSecondInputValue('');
                }
            }

            if (["isNumber", "wholeNumber", "email", "url"].includes(option)) {
                setFirstInputValue('');
                setIsFirstInputError(false);
                setIsSecondInputError(false);
                setSecondInputValue('');
            }

            setSelectedOpt(option);

            if (isResponseValidationType) {
                setReponseValidationSubTypeOpts(getResponseValidationSubTypeOptions(option));
                setResponseValidationSubType(getResponseValidationSubTypeOptions(option)[0]);
                setFirstInputValue('');
                setIsFirstInputError(false);
                setIsSecondInputError(false);
                setSecondInputValue('');
                setCustomErrorText('');
            }
        }
    };

    const handleOutsideClick = (e) => {
        if (questionTypeDropdownRef.current !== null) {
            if (questionTypeDropdownRef.current.contains(e.target)) return;
        }
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <div className="relative w-[150px]">
            <div className="relative">
                <div
                    onClick={toggleDropdown}
                    className="h-[36px] mr-2 rounded flex items-start cursor-pointer"
                >
                    <div className="mr-1">
                        <span className="text-gray-800 text-[12px]">{dropdownOptionLabels[selectedOpt]}</span>
                    </div>
                    <div className="ml-auto">
                        <MdArrowDropDown className="text-2xl text-gray-500" />
                    </div>
                </div>
                {isOpen && (
                    <div
                        ref={questionTypeDropdownRef}
                        style={{
                            marginTop: "-48px",
                            position: "absolute",
                            boxShadow:
                                "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
                        }}
                        className="py-2 w-[155px] bg-white border border-[#0000001f] rounded z-50"
                    >
                        {opts.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => handleOptionClick(option, index)}
                                className={`${selectedOpt === option && "bg-[#e4f0fc]"} h-[48px] w-full py-2 pl-4 pr-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                            >
                                <span className="text-gray-800 text-[12px] font-medium">
                                    {dropdownOptionLabels[option]}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

const ResponseValidation = ({ helpers }) => {
    let {
        question,
        setFormState,
        questionIndex,
        activeState,
        setActiveState,
        formState,
        setActive,
        options,
    } = helpers;

    const { responseValidationValue } = question;

    const [responseValidationState, setResponseValidationState] = useState({
        responseValidationType: responseValidationValue?.responseValidationType || options[0],
        reponseValidationSubTypeOpts: getResponseValidationSubTypeOptions(responseValidationValue?.responseValidationType || options[0]),
        responseValidationSubType: responseValidationValue?.responseValidationSubType || getResponseValidationSubTypeOptions(responseValidationValue?.responseValidationType || options[0])[0],
        firstInputValue: responseValidationValue?.firstInputValue || '',
        isFirstInputError: false,
        secondInputValue: responseValidationValue?.secondInputValue || '',
        isSecondInputError: false,
        customErrorText: responseValidationValue?.customErrorText || '',
    });

    const {
        responseValidationType,
        reponseValidationSubTypeOpts,
        responseValidationSubType,
        firstInputValue,
        isFirstInputError,
        secondInputValue,
        isSecondInputError,
        customErrorText,
    } = responseValidationState;

    const isEditor =
        questionIndex.p === activeState.pageIndex &&
        questionIndex.q === activeState.questionIndex;

    const closeResponseValidation = () => {
        setFormState((prevState) => {
            let newState = { ...prevState };
            newState.pages.forEach((page) => {
                page.questions.forEach((question_) => {
                    if (question_.id === question.id) {
                        question_["responseValidation"] = isUndefined(question_["responseValidation"])
                            ? false
                            : !question_["responseValidation"];
                        question_.responseValidationValue = {};
                    }
                });
            });
            return newState;
        });
    }

    useEffect(() => {
        if (isFirstInputError || isSecondInputError) return;

        const newResponseValidationValue = {
            responseValidationType,
            responseValidationSubType,
            firstInputValue: /^\s*$/.test(firstInputValue) ? '' : firstInputValue,
            secondInputValue,
            customErrorText: /^\s*$/.test(customErrorText) ? '' : customErrorText,
        };

        setFormState((prevState) => {
            let newState = { ...prevState };
            newState.pages.forEach((page) => {
                page.questions.forEach((question_) => {
                    if (question_.id === question.id) {
                        question_.responseValidationValue = newResponseValidationValue;
                    }
                });
            });
            return newState;
        });

    }, [responseValidationState]);

    if (
        questionIndex.p !== activeState.pageIndex ||
        questionIndex.q !== activeState.questionIndex
    ) {
        return null;
    }

    return (
        <div className="mb-10 mx-2">
            <div className="flex items-start gap-x-4">
                <div>
                    <CustomDropDown
                        isResponseValidationType={true}
                        opts={options}
                        selectedOpt={responseValidationType}
                        setSelectedOpt={(type) => setResponseValidationState((prevState) => ({ ...prevState, responseValidationType: type }))}
                        setReponseValidationSubTypeOpts={(options) => setResponseValidationState((prevState) => ({ ...prevState, reponseValidationSubTypeOpts: options }))}
                        setResponseValidationSubType={(subtype) => setResponseValidationState((prevState) => ({ ...prevState, responseValidationSubType: subtype }))}
                        setFirstInputValue={(value) => setResponseValidationState((prevState) => ({ ...prevState, firstInputValue: value }))}
                        setIsFirstInputError={(error) => setResponseValidationState((prevState) => ({ ...prevState, isFirstInputError: error }))}
                        setSecondInputValue={(value) => setResponseValidationState((prevState) => ({ ...prevState, secondInputValue: value }))}
                        setIsSecondInputError={(error) => setResponseValidationState((prevState) => ({ ...prevState, isSecondInputError: error }))}
                        setCustomErrorText={(text) => setResponseValidationState((prevState) => ({ ...prevState, customErrorText: text }))}
                    />
                </div>
                <div>
                    <CustomDropDown
                        opts={reponseValidationSubTypeOpts}
                        selectedOpt={responseValidationSubType}
                        setSelectedOpt={(subtype) => setResponseValidationState((prevState) => ({ ...prevState, responseValidationSubType: subtype }))}
                        setFirstInputValue={(value) => setResponseValidationState((prevState) => ({ ...prevState, firstInputValue: value }))}
                        setIsFirstInputError={(error) => setResponseValidationState((prevState) => ({ ...prevState, isFirstInputError: error }))}
                        setSecondInputValue={(value) => setResponseValidationState((prevState) => ({ ...prevState, secondInputValue: value }))}
                        setIsSecondInputError={(error) => setResponseValidationState((prevState) => ({ ...prevState, isSecondInputError: error }))}
                    />
                </div>
                {
                    !["isNumber", "wholeNumber", "email", "url"].includes(responseValidationSubType) &&
                    <div>
                        <input
                            type="text"
                            placeholder={responseValidationType === "regularExpression" ? "Pattern" : responseValidationType === "length" ? "Number" : dropdownOptionLabels[responseValidationType]}
                            value={firstInputValue}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                responseValidationType === "number" && setResponseValidationState((prevState) => ({ ...prevState, isFirstInputError: inputValue !== "" && !/^-?\d*\.?\d+$/.test(inputValue) }));

                                responseValidationType === "length" && setResponseValidationState((prevState) => ({ ...prevState, isFirstInputError: inputValue !== "" && (!/^\d+$/.test(inputValue) || parseInt(inputValue, 10) <= 0) }));

                                if (responseValidationType === "regularExpression") {
                                    try {
                                        new RegExp(inputValue);
                                        setResponseValidationState((prevState) => ({ ...prevState, isFirstInputError: false }));
                                    } catch (error) {
                                        setResponseValidationState((prevState) => ({ ...prevState, isFirstInputError: true }));
                                    }
                                }

                                setResponseValidationState((prevState) => ({ ...prevState, firstInputValue: inputValue }));
                            }}
                            className={`${isEditor && "hover:border-gray-200"
                                } ${["number", "length"].includes(responseValidationType) ? "min-w-[70px] w-[70px] max-w-[70px]" : "w-[150px]"} option-input text-[12px] disabled:bg-transparent text-gray-600 border-white outline-none`}
                        />
                        <div className={`${["number", "length"].includes(responseValidationType) ? "min-w-[70px] w-[70px] max-w-[70px]" : "w-[150px]"} option-input-border`}></div>

                        {
                            isFirstInputError && <p className={`text-red-500 text-[12px] mt-1 ${["number", "length"].includes(responseValidationType) ? "max-w-[70px]" : "w-[150px]"}`}>{
                                responseValidationType === "number" ? "Please enter a valid number" : responseValidationType === "length" ? "Please enter a whole number greater than zero" : "Please enter a valid regular expression"
                            }
                            </p>
                        }
                    </div>
                }
                {
                    ["between", "notBetween"].includes(responseValidationSubType) &&
                    <>
                        <span className="text-gray-800 text-[12px] mt-[2px]">and</span>
                        <div>
                            <input
                                type="text"
                                placeholder={responseValidationType === "regularExpression" ? "Pattern" : dropdownOptionLabels[responseValidationType]}
                                value={secondInputValue}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    responseValidationType === "number" && setResponseValidationState((prevState) => ({ ...prevState, isSecondInputError: inputValue !== "" && !/^-?\d+$/.test(inputValue) }));

                                    setResponseValidationState((prevState) => ({ ...prevState, secondInputValue: inputValue }));
                                }}
                                className={`${isEditor && "hover:border-gray-200"} ${["number", "length"].includes(responseValidationType) ? "min-w-[70px] w-[70px] max-w-[70px]" : "w-[150px]"} option-input w-full text-[12px] disabled:bg-transparent text-gray-600 border-white outline-none`}
                            />
                            <div className={`${["number", "length"].includes(responseValidationType) ? "min-w-[70px] w-[70px] max-w-[70px]" : "w-[150px]"} option-input-border`}></div>

                            {isSecondInputError && <p className="text-red-500 text-[12px] mt-1 max-w-[70px]">Please enter a valid number</p>}
                        </div>
                    </>
                }
                <div className="w-full">
                    <input
                        type="text"
                        placeholder="Custom error text"
                        value={customErrorText}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            setResponseValidationState((prevState) => ({ ...prevState, customErrorText: inputValue }));
                        }}
                        className={`${isEditor && "hover:border-gray-200"
                            } option-input w-full text-[12px] disabled:bg-transparent text-gray-600 border-white outline-none`}
                    />
                    <div className="option-input-border"></div>
                </div>
                <div>
                    <MdClose onClick={closeResponseValidation} className="text-[20px] text-[#a8a8a9]" />
                </div>
            </div>
        </div>
    );
};

export default ResponseValidation;

function getResponseValidationSubTypeOptions(selectedOption) {
    switch (selectedOption) {
        case "number":
            return [
                "greaterThan",
                "greaterThanOrEqualTo",
                "lessThan",
                "lessThanOrEqualTo",
                "equalTo",
                "notEqualTo",
                "between",
                "notBetween",
                "isNumber",
                "wholeNumber",
            ];
        case "text":
            return ["contains", "doesntContain", "email", "url"];
        case "length":
            return ["maximumCharacterCount", "minimumCharacterCount"];
        case "regularExpression":
            return ["contains", "doesntContain", "matches", "doesntMatch"];
        default:
            return [];
    }
};