import React, { useState } from "react";
import { DesignerState, GlobalHelpersType } from "..";
import { getStatusByPayment } from "../../../../../../utils/quoteBuilder";
import ViewHeader from "./ViewHeader";

export type PriceViewProps = {
  helpers: {
    open: boolean;
    setOpen: any;
    designerState: DesignerState;
    setDesignerState: React.Dispatch<React.SetStateAction<DesignerState>>;
    globalHelpers: GlobalHelpersType;
  };
};

const PreviewView = ({ helpers }: PriceViewProps) => {
  const { open, setOpen, designerState, setDesignerState, globalHelpers } =
    helpers;
  const [loading, setLoading] = useState(false);

  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      <div className="overflow-y-auto h-[100%]">
        <div className="px-4">
          <ViewHeader
            name="Audit Trail"
            open={open}
            setOpen={setOpen}
            loading={loading}
          />
        </div>
        <div
          className="px-4 max-h-[calc(100%_-_80px)] min-h-[calc(100%_-_80px)] overflow-y-auto"
          id="mini-pdf-custom-scroll"
        >
          <p className="text-gray-500 text-xs pr-4 mb-2">
            Audit trail gives you a step-by-step history of a document's
            changes.
          </p>
          <div className="flex flex-col text-xs text-gray-500 gap-4">
            {designerState?.fetchedData?.document?.history?.map((hist: any) => {
              return (
                <div>
                  <p
                    className="text-[10px] font-semibold capitalize w-fit px-2 py-0.5 rounded"
                    style={{
                      background: getStatusByPayment({
                        document: {
                          ...(designerState?.fetchedData?.document || {}),
                          status: hist?.status,
                        },
                      }).backGround,
                      color: getStatusByPayment({
                        document: {
                          ...(designerState?.fetchedData?.document || {}),
                          status: hist?.status,
                        },
                      }).color,
                    }}
                  >
                    {hist?.action}
                  </p>
                  <p className="border-l-2 p-1.5 mt-1">{hist?.activity}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewView;
