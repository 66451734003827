import TiktokPixel from 'tiktok-pixel';
import { TIKTOK_PIXEL_ID } from "../../constants/constants";


// const advancedMatching = {
//   email: 'some@email.com',
//   phone_number: '0123456789',
// }; // optional, more info: https://ads.tiktok.com/help/article?aid=10007891


const options = {
  debug: true, // enable logs
};

class TikTok {
  constructor(email) {
    this.pixel = TiktokPixel;
    // console.log("(process.env.TIKTOK_PIXEL_ID)", TIKTOK_PIXEL_ID)
    this.pixel.init(
      TIKTOK_PIXEL_ID,
      email ? { email: email } : null, options
    );
    this.pixel.pageView()
  }

  trackPurchase(product, priceId, quantity, value) {
    console.log(product, priceId, quantity, value)
    this.pixel.track('CompletePayment', {
      price: value,
      quantity: quantity,
      value: value * quantity,
      currency: 'USD',
      content_type: "product",
      content_ids: [priceId],
      content_name: product,
      description: product,
    });
  }

  trackInitiateCheckout(product, priceId, quantity, value,) {
    console.log(product, priceId, quantity, value)
    this.pixel.track('InitiateCheckout', {
      price: value,
      quantity: quantity,
      value: value * quantity,
      currency: 'USD',
      content_type: "product",
      content_ids: [priceId],
      content_name: product,
      description: product,
    });
  }


  trackAddPaymentInfo(product, priceId, quantity, value) {
    console.log(product, priceId, quantity, value)
    this.pixel.track('AddPaymentInfo', {
      price: value,
      quantity: quantity,
      value: value * quantity,
      currency: 'USD',
      content_type: "product",
      content_ids: [priceId],
      content_name: product,
      description: product,

    });
  }

  trackSignUp(email) {
    this.pixel.track('CompleteRegistration');
  }



  trackCustom(event, data) {
    this.pixel.track(event, data);
  }

}


export default TikTok


