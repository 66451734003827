import * as React from "react";
import { OTPInput, OTPInputContext } from "input-otp";

import { cn } from "../lib/utils";
import { VscDash } from "react-icons/vsc";
import { cva, VariantProps } from "class-variance-authority";

const otpSlotVariant = cva(
  "relative flex h-10 w-10 md:h-12 md:w-12 items-center justify-center border-y border-r border-input text-sm shadow-sm transition-all first:rounded-l-md first:border-l last:rounded-r-md",
  {
    variants: {
      variant: {
        primary: "ring-primary",
        secondary: "ring-secondary",
        "bolo-primary": "ring-primary-bolo",
        "bolo-secondary": "ring-secondary-bolo",
      },
      isActive: {
        "1": "z-10 ring-1 ring-ring ",
        "0": "",
      },
    },
    defaultVariants: {
      variant: "primary",
      isActive: "0",
    },
  }
);

const caretVariant = cva("h-4 w-px animate-caret-blink duration-1000", {
  variants: {
    variant: {
      primary: "bg-primary",
      secondary: "bg-secondary",
      "bolo-primary": "bg-primary-bolo",
      "bolo-secondary": "bg-secondary-bolo",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

const BoloInputOTP = React.forwardRef<
  React.ElementRef<typeof OTPInput>,
  React.ComponentPropsWithoutRef<typeof OTPInput>
>(({ className, containerClassName, ...props }, ref) => (
  <OTPInput
    ref={ref}
    containerClassName={cn(
      "flex items-center gap-2 has-[:disabled]:opacity-50",
      containerClassName
    )}
    className={cn("disabled:cursor-not-allowed", className)}
    {...props}
  />
));
BoloInputOTP.displayName = "BoloInputOTP";

const BoloInputOTPGroup = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("flex items-center", className)} {...props} />
));
BoloInputOTPGroup.displayName = "BoloInputOTPGroup";

const BoloInputOTPSlot = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div"> & { index: number } & VariantProps<
      typeof otpSlotVariant
    >
>(({ index, variant, className, ...props }, ref) => {
  const inputOTPContext = React.useContext(OTPInputContext);
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

  return (
    <div
      ref={ref}
      className={otpSlotVariant({
        variant,
        isActive: isActive ? "1" : "0",
        className,
      })}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div className={caretVariant({ variant, className: "" })} />
        </div>
      )}
    </div>
  );
});
BoloInputOTPSlot.displayName = "BoloInputOTPSlot";

const BoloInputOTPSeparator = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ ...props }, ref) => (
  <div ref={ref} role="separator" {...props}>
    <VscDash />
  </div>
));
BoloInputOTPSeparator.displayName = "BoloInputOTPSeparator";

export {
  BoloInputOTP,
  BoloInputOTPGroup,
  BoloInputOTPSlot,
  BoloInputOTPSeparator,
};
