import React, { useState } from "react";
import { BsCardImage, BsUpload } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { toast } from "react-toastify";
import { BoloButton } from "../../../../../components/Common/Button/BoloButton";
import BoloModal from "../../../../../components/Common/Modal/BoloModal";
import { S3PATHS } from "../../../../../constants/s3Folders";
import { generalizedFileUploadViaSignedUrlPublic } from "../../../../../utils/fileUpload";

export type StaticImageUploadProps = {
  setShow: (value: boolean) => void;
  saveSchemaData: (imgData: string) => void;
};

const StaticImageUpload = ({
  setShow,
  saveSchemaData,
}: StaticImageUploadProps) => {
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>();

  // make this s3 upload instead
  // NOte

  const handleDrop = (e: any) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    if (
      selectedFile.type === "image/png" ||
      selectedFile.type === "image/jpg" ||
      selectedFile.type === "image/jpeg"
    ) {
      if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
        toast.error("File should be no larger than 5mb");
        return;
      }
      if (selectedFile) {
        const reader = new FileReader();

        reader.onload = (e) => {
          // @ts-ignore
          const dataUrl = e.target?.result; // Data URL of the uploaded file
          setFile({ file: selectedFile, dataUrl }); // Store the file and its data URL
        };

        reader.readAsDataURL(selectedFile);
      }
    } else {
      toast.error("Only jpg, png and jpeg files accecpted");
    }
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      toast.error("File should be no larger than 5mb");
      return;
    }
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // @ts-ignore
        const dataUrl = e.target?.result; // Data URL of the uploaded file
        setFile({ file: selectedFile, dataUrl }); // Store the file and its data URL
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <div className="min-w-[550px] max-w-[550px]">
      <div className="text-base md:text-lg mb-4 font-semibold">
        Upload Image
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="flex items-center w-full justify-between">
          <label
            htmlFor={`template-upload`}
            className={`flex flex-col items-center justify-center p-8 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-secondary hover:border-primary/80 w-full text-gray-600 hover:text-primary transition-all ease-in-out duration-150 ${
              file ? "bg-secondary border-primary/80" : ""
            } `}
            onDrop={handleDrop}
            onDragOver={(e) => {
              // Handle drag over event if needed
              e.preventDefault();
            }}
          >
            {!file && (
              <div className="flex flex-col items-center justify-center gap-8 py-8">
                <BsCardImage size={50} className="" />
                <div className="m-0 flex flex-col items-center">
                  <div className="text-lg font-semibold flex items-center gap-2 justify-center">
                    <BsUpload size={15} className="" />
                    Click to upload
                  </div>
                  <p> or drag and drop</p>
                </div>
              </div>
            )}
            {file && (
              <div className="flex flex-col items-center justify-center gap-8 py-8">
                <BsCardImage size={50} className="text-primary" />
                <div className="flex gap-4 items-center justify-center">
                  <TiTick size={30} className="text-primary" />
                  <p>{file.file.name}</p>
                </div>
              </div>
            )}
            <input
              id={`template-upload`}
              type="file"
              className="appearance-none hidden"
              onChange={handleFileChange} // Call the handleFileChange function on file selection
              accept="image/png"
            />
          </label>
        </div>
        <div className="flex justify-end mt-4 gap-2">
          <BoloButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => {
              if (file) {
                setFile(null);
              } else {
                setShow(false);
              }
            }}
          >
            Cancel
          </BoloButton>
          <BoloButton
            variant={"primary"}
            size={"sm"}
            disabled={loading || !file}
            onClick={async () => {
              setLoading(true);
              // const url = await uploadViaSignedUrl(file.file);
              const url = await generalizedFileUploadViaSignedUrlPublic({
                file: file.file,
                folderName: S3PATHS.STATIC_ASSETS,
              });
              saveSchemaData(url || "");
              setLoading(false);
              //   saveSchemaData(file.dataUrl || "");
              setShow(false);
            }}
          >
            {loading ? "Uploading ..." : "Upload"}
          </BoloButton>
        </div>
      </div>
    </div>
  );
};

const StaticImageUploadModal = (props: StaticImageUploadProps) => {
  const { setShow } = props;
  return (
    <BoloModal
      isWidthFit={true}
      setShow={setShow}
      content={<StaticImageUpload {...props} />}
    />
  );
};

export default StaticImageUploadModal;
