import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { generateQRThunk, verifyQRThunk } from "../../redux/slices/2faSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  BoloInputOTP,
  BoloInputOTPGroup,
  BoloInputOTPSeparator,
  BoloInputOTPSlot,
} from "../../components/Common/OTP/BoloOTP";
import { Link } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/Common/Dialog/BoloDialog";
import { LiaRedoAltSolid } from "react-icons/lia";
import { BASE_URL } from "../../constants/constants";
import axios from "../../utils/axios";
import { updateLoginInfo } from "../../redux/api/owner";
import { FiExternalLink } from "react-icons/fi";

const RegenerateToken = ({
  email,
  userData,
}: {
  email: string;
  userData: { result: any; method: string };
}) => {
  const dispatch = useAppDispatch();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <p className="text-center border rounded p-1">
          <LiaRedoAltSolid
            size={16}
            className="cursor-pointer transition-all duration-300 hover:text-primary text-gray-600"
            data-tooltip-id="regen-secret"
            onClick={() => {}}
          />
        </p>
      </DialogTrigger>
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Regenrate Two-Factor token</DialogTitle>
        </DialogHeader>
        <p className="text-xs md:text-sm text-gray-600">
          Regenerating the 2FA token will invalidate all session in previous
          devices, would you like to procced ?
        </p>
        <DialogFooter>
          <DialogClose variant={"secondary"} size={"sm"}>
            Cancel
          </DialogClose>
          <DialogClose>
            <BoloButton
              variant={"danger-border"}
              size={"sm"}
              onClick={() => {
                dispatch(
                  generateQRThunk({
                    email,
                    action: "generate",
                  })
                );
              }}
            >
              Procced
            </BoloButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const Login2FA = ({
  userData,
}: {
  userData: { result: any; method: string };
}) => {
  // this will come from props
  // console.log(userData);

  const dispatch = useAppDispatch();
  const email = userData?.result?.user?.email;
  const [showType, setShowType] = useState<"qr" | "otp">("qr");
  const twoFA = useAppSelector((state) => state?.root?.twoFASlice?.data);
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data
  );
  const [qrImage, setQrImage] = useState<string>("");
  const [OTP, setOTP] = useState<string>("");

  const handleVerify = async () => {
    try {
      let url = `${BASE_URL}/2fa/verify-qr`;
      const body = { otp: OTP, email };
      const { data } = await axios.post(url, body);
      console.log(data);
      if (data?.success && data?.isValid) {
        await updateLoginInfo(userData?.result, userData?.method);
      } else {
        toast.error(data?.message || "Something went wrong");
      }
    } catch (error) {
      console.log("error in handleVerify");
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleOTPAuth = (otpAuth: string) => {
    QRCode.toDataURL(
      otpAuth,
      (error: Error | null | undefined, url: string) => {
        if (error) {
          console.log(error);
        } else {
          setQrImage(url);
        }
      }
    );
  };

  useEffect(() => {
    if (twoFA.otpAuth) {
      handleOTPAuth(twoFA.otpAuth);
    }
  }, [twoFA.otpAuth]);

  // useEffect(() => {
  //   dispatch(
  //     generateQRThunk({
  //       email,
  //       action: "get",
  //     })
  //   );
  // }, []);

  // useEffect(() => {
  //   if (twoFA.secretExists) {
  //     setShowType("otp");
  //   } else {
  //     setShowType("qr");
  //   }
  // }, [twoFA.secretExists]);

  return (
    <div className="flex flex-col items-center justify-center p-2">
      {/* <img
        className="my-8 h-[150px] w-[150px] rounded-full"
        alt="qr"
        src={
          whiteLabelInfo?.whiteLabelOwner?.settings?.organization?.logo ||
          IMAGE_LINKS.BOLOLOGO
        }
      ></img> */}
      <p className="font-semibold text-lg sm:text-2xl mb-2">
        Two-Factor Authentication
      </p>
      <p className="text-xs sm:text-base text-gray-600 max-w-[550px] text-center">
        To access two-factor authentication please use an TOTP app for example{" "}
        {/* <Link
          className="underline"
          target={"_blank"}
          to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
        > */}
        Google Authenticator
        {/* </Link> */}
      </p>
      {/* {showType === "qr" && (
        <div
          className={`my-6 flex flex-col gap-4 justify-center items-center h-fit`}
        >
          <div
            className={`flex flex-col gap-4 justify-center transition-all duration-300 items-center overflow-hidden ${
              qrImage ? "h-fit" : "h-0"
            }`}
          >
            <img className="max-w-[250px]" src={qrImage}></img>
            <div className="flex items-center max-w-[450px] gap-1 md:gap-2 my-6">
              <div className="border-b border-secondary w-[80px]"></div>
              <p className="text-[10px] text-gray-500 text-center">
                or enter code manually
              </p>
              <div className="border-b border-secondary w-[80px] "></div>
            </div>
            <div className="flex gap-2 items-center">
              <p className="text-xs sm:text-sm text-gray-600 max-w-[450px] text-center border rounded p-1 px-2">
                {twoFA.secret}
              </p>
              <p className="text-center border rounded p-1">
                <MdContentCopy
                  size={16}
                  className="cursor-pointer transition-all duration-300 hover:text-primary text-gray-600"
                  data-tooltip-id="copy-secret"
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(twoFA.secret);
                      toast.success("Key copied to clipboard");
                    } catch (err) {
                      console.error("Failed to copy: ", err);
                    }
                  }}
                />
              </p>
              <RegenerateToken email={email} userData={userData} /> 
            </div>
          </div>
          {qrImage ? (
            <BoloButton
              className="w-full"
              size={"sm"}
              onClick={() => setShowType("otp")}
            >
              Continue
            </BoloButton>
          ) : (
            <BoloButton
              className="w-[250px] flex items-center gap-4 justify-center"
              size={"sm"}
              onClick={() => {
                dispatch(
                  generateQRThunk({
                    email,
                    action: "generate",
                  })
                );
              }}
            >
              Generate QR <MdOutlineQrCodeScanner size={20} />
            </BoloButton>
          )}
        </div>
      )} 
      <CustomTooltip
        helpers={{
          id: "copy-secret",
          content: "Copy to Clipboard",
          place: "right",
        }}
      />
      <CustomTooltip
        helpers={{
          id: "regen-secret",
          content: "Regenerate Token and QR",
          place: "right",
        }}
      />*/}

      <div className="mt-6 flex flex-col gap-4 justify-center transition-all duration-300 items-center ">
        <BoloInputOTP
          className="text-sm"
          maxLength={6}
          minLength={6}
          value={OTP}
          onChange={(value) => setOTP(value)}
        >
          <BoloInputOTPGroup>
            <BoloInputOTPSlot index={0} variant="primary" />
            <BoloInputOTPSlot index={1} variant="primary" />
            <BoloInputOTPSlot index={2} variant="primary" />
          </BoloInputOTPGroup>
          <BoloInputOTPSeparator />
          <BoloInputOTPGroup>
            <BoloInputOTPSlot index={3} variant="primary" />
            <BoloInputOTPSlot index={4} variant="primary" />
            <BoloInputOTPSlot index={5} variant="primary" />
          </BoloInputOTPGroup>
        </BoloInputOTP>

        {/* <div
            className={`${
              twoFA.isValid ? "text-green-600" : "text-red-600"
            } font-bold text-center w-full`}
          >
            {twoFA.isValid ? "Valid" : "InValid"}
          </div> */}

        <div className="flex flex-col gap-2 w-full mt-6">
          <BoloButton
            className="w-[100%] flex items-center gap-4 justify-center"
            size={"sm"}
            disabled={OTP.length !== 6}
            onClick={() => handleVerify()}
          >
            Confirm
          </BoloButton>
          <Link
            to={`mailto:${
              whiteLabelInfo?.whiteLabelOwner?.settings?.organization
                ?.pricingContactEmail || "support@boloforms.com"
            }`}
            target={"_blank"}
            className="text-xs text-gray-500 mt-6 w-fit border-b border-gray-500 flex gap-2 items-center"
          >
            In case you do not have access to you device contact us{" "}
            <FiExternalLink />
          </Link>
        </div>
      </div>

      {/* <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      /> */}
    </div>
  );
};

const Login2FAModal = ({
  // email,
  show,
  setShow,
  userData,
}: {
  // email: string;
  show: boolean;
  setShow: (value: boolean) => void;
  userData: { result: any; method: string };
}) => {
  return (
    <Dialog open={show} onOpenChange={setShow}>
      <DialogContent
        onEscapeKeyDown={(e) => {
          e.preventDefault();
        }}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <Login2FA
          // email={email}
          // cb={cb}
          userData={userData}
        />
      </DialogContent>
    </Dialog>
  );
};

export { Login2FAModal };

export default Login2FA;
