import { defaultContactFields } from "../constants/common";

// This convert the text from key to name in custom contact field (eg. org_id to Org Id)
export const convertToTitleCase = (Str: string) => {
  if (!Str) return Str;

  // Convert camel case to space-separated words
  const words = Str.replace(/([a-z])([A-Z])/g, "$1 $2").split(/[_\s]+/);

  // Convert the first character of each word to uppercase
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getContactFieldValue = (
  email: any,
  fieldName: any,
  type?: string
) => {
  let value = "";
  let owner = null;
  if (localStorage.getItem("owner")) {
    owner = JSON.parse(localStorage.getItem("owner") || "");
  }
  let contacts = owner?.contacts || [];
  if (contacts) {
    contacts.forEach((contact: any) => {
      if (contact.email === email) {
        if (type === "custom_field") {
          if (contact?.customFields && contact?.customFields[fieldName]) {
            value = contact?.customFields[fieldName];
          }
        } else if (type === "contact_field") {
          value = contact[fieldName];
        }
      }
    });
  }

  if (value === null) {
    value = "";
  }
  return value;
};

export const getCustomFieldsData = () => {
  const owner = JSON.parse(localStorage.getItem("owner") || "");
  let fieldsData: any = defaultContactFields;
  owner?.workSpaces?.forEach((workspace: any) => {
    if (workspace?.workSpaceId === owner?.currentWorkSpaceId) {
      if (workspace?.customFields?.length > 0) {
        fieldsData = fieldsData.concat(workspace?.customFields);
      }
    }
  });
  return fieldsData;
};
