//@ts-nocheck

import React, { useEffect, useState, Fragment } from "react";
import "./index.css";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { BsFillFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { FiUpload } from "react-icons/fi";
import { fileToBase64 } from "../../../../utils/FileDataToBase64";
import QuestionBottomOptions from "../../QuestionBottomOptions";
import QuestionInput from "../../QuestionInput";
import QuestionDesc from "../../QuestionDesc";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import ImageQuestionPreview from "../../ImageQuestionPreview";
import { RiImageAddLine } from "react-icons/ri";
import { BsFiletypeCsv } from "react-icons/bs";
import { MdArrowDropDown, MdOutlineCloudUpload, MdClose } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import axios from "../../../../utils/axios";
import { toast } from "react-toastify";
import { COLORS } from "../../../../constants/common";

const PurpleSwitch = styled(Switch)(({ theme, inputProps }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: inputProps?.accentColor ?? COLORS.primary,
    "&:hover": {
      backgroundColor: alpha(
        inputProps?.accentColor ?? COLORS.primary,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: inputProps?.accentColor ?? COLORS.primary,
  },
}));

// A warning modal for user to confirm before changing the input (isMultiple OR fileUploadType)
const FileUploadConfirmation = ({
  setShow,
  isMultiple,
  handleFileUploadTypeChange,
  handleIsMultipleFileInput,
  tempFileType,
}) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />
          <div className="text-base md:text-lg mb-4 font-semibold">
            {isMultiple ? "Multiple File Upload" : "Change Upload File Type"}
          </div>
          <span className="text-xs md:text-sm text-gray-600">
            {isMultiple
              ? "This question is already mapped as an image in the PDF. Adding multiple files will remove the mapped question."
              : "This question is mapped as an image in the PDF. Changing the file type will remove it from the document. Only image files can be mapped to the PDF."}
          </span>
          <span className="text-xs md:text-sm mt-2 text-gray-600">
            {isMultiple
              ? "Are you sure you want to take input of multiple files for this question?"
              : "Are you sure you want to change the file upload type for this question?"}
          </span>
          <div className="flex gap-2 items-center justify-end mt-8">
            <button
              className="text-xs md:text-sm text-primary border px-4 py-2 rounded-md"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              className="text-xs md:text-sm text-white bg-primary px-4 py-2 rounded-md"
              onClick={() => {
                if (isMultiple) {
                  handleIsMultipleFileInput();
                } else {
                  handleFileUploadTypeChange(tempFileType);
                }
                setShow(false);
              }}
            >
              {isMultiple ? "Allow Multiple Files" : "Change Upload Type"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FileUpload = ({ data }) => {
  let {
    question,
    formState,
    setFormState,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
    schemas,
  } = data;
  // Allowed Type for file upload
  const allowedFileTypes = {
    all: "all",
    image: "image",
    csv: "csv",
  };
  // Text,Icon and type value for file type options
  let options = [
    {
      type: allowedFileTypes.all,
      name: "All",
      icon: <MdOutlineCloudUpload className="text-2xl text-gray-600" />,
    },
    {
      type: allowedFileTypes.image,
      name: "Only Images",
      icon: <RiImageAddLine className="text-2xl text-gray-600" />,
    },
    {
      type: allowedFileTypes.csv,
      name: "Only CSV",
      icon: <BsFiletypeCsv className="text-2xl text-gray-600" />,
    },
  ];
  const [fileType, setFileType] = useState(
    question.fileType || allowedFileTypes.all
  );
  const [tempFileType, setTempFileType] = useState("");
  const [isMultiple, setIsMultiple] = useState(false);
  const [tempSchema, setTempSchema] = useState(schemas);
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = React.useState([]);
  const [show, setShow] = useState(false);
  const [searchParams] = useSearchParams();
  const isEditor =
    questionIndex.p === activeState.pageIndex &&
    questionIndex.q === activeState.questionIndex;
  // Check for question exists in schema or not
  const isQuestionInSchema = () => {
    let isInSchema = false;
    tempSchema?.forEach((page) => {
      Object.values(page).forEach((que) => {
        const splitId = que?.id?.split("_")?.[1];
        // eslint-disable-next-line eqeqeq
        if (splitId == question?.id) {
          isInSchema = true;
        }
      });
    });
    return isInSchema;
  };
  // This will retrun the updated schema after removing a input in schema
  const removeSchemaId = () => {
    const newSchema = schemas?.map((page, index) => {
      return Object.values(page).filter((que) => {
        const splitId = que?.id?.split("_")?.[1];
        return splitId !== question?.id;
      });
    });
    return newSchema;
  };

  useEffect(() => {
    if (files?.length > 0) {
      let value = [];
      (async () => {
        for (let file of files) {
          let data = await fileToBase64(file);
          value.push(data);
        }
        // console.log("value", value);
        setFormState((prevState) => {
          let newState = { ...prevState };
          newState.pages.forEach((page) => {
            page.questions.forEach((question_) => {
              if (question_.id === question.id) {
                question_.value = value;
              }
            });
          });
          return newState;
        });
      })();
    }
    // console.log("files", files);
  }, [files]);
  // This is used to find the current file type that is used in showin the icon, text for the dropdown option
  const findCurrentFileType = () => {
    const data = options.filter((option) => option.type === fileType);
    if (data.length > 0) {
      return data[0];
    } else {
      return allowedFileTypes.all;
    }
  };
  const questionTypeDropdownRef = React.useRef(null);
  const handleOutsideClick = (e) => {
    if (questionTypeDropdownRef.current !== null) {
      if (questionTypeDropdownRef.current.contains(e.target)) return;
    }
    setIsOpen(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.fileType = fileType;
          }
        });
      });
      return newState;
    });
  }, [fileType, question.id, setFormState]);

  // When file type is changed for file Upload
  const handleChangeFileType = (type) => {
    const isInSchema = isQuestionInSchema();
    // Check if this question mapped to pdf or not
    if (!isInSchema) {
      // Just update the file upload type if not mapped
      handleFileUploadTypeChange(type);
      return;
    }
    // Open a modal for warning message
    setShow(true);
    setIsMultiple(false);
    // TempFileType is used in the handleFileUploadTypeChange as parameter in modal
    setTempFileType(type);
  };
  // Change the file type of the file upload question
  const handleFileUploadTypeChange = (type) => {
    setFileType(type);
    setIsOpen(false);
    const isInSchema = isQuestionInSchema();
    // If file type is changed and it is already in schema then clean the schema
    if (isInSchema) {
      cleanSchema();
    }
  };
  // This function changes the schema in DB
  const cleanSchema = async () => {
    const newSchema = removeSchemaId();
    setTempSchema(newSchema);
    const formId = searchParams.get("id");
    try {
      const { data } = await axios.post("/forms/update-only-schema", {
        formId,
        schemas: newSchema,
      });
      if (!data.error) {
        toast.success("Question mapping removed");
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // When user toggles the allow multiple files option
  const handleIsMultipleFileChange = () => {
    const isInSchema = isQuestionInSchema();
    const isMultiple = question?.multiple || false;
    if (!isInSchema || isMultiple) {
      // Toggle the button if question is not mapped or multiple files are allowed
      handleIsMultipleFileInput();
      return;
    }
    // Open the warning modal
    setShow(true);
    // To show the warning content of the multiple file upload mapping
    setIsMultiple(true);
  };
  // Toggle the multiple file button
  const handleIsMultipleFileInput = () => {
    const isInSchema = isQuestionInSchema();
    // If question is mapped into PDF clean the Schema
    if (isInSchema) {
      cleanSchema();
    }
    // Update the formState
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.multiple = !question_.multiple;
          }
        });
      });
      return newState;
    });
  };
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <QuestionInput
            helpers={{
              question,
              setFormState,
              isEditor,
              questionIndex,
              activeState,
            }}
          />
        </div>

        <QuestionDesc
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
      </div>
      <ImageQuestionPreview
        helpers={{ question, setFormState, questionIndex, activeState }}
      />
      {questionIndex.p === activeState.pageIndex &&
      questionIndex.q === activeState.questionIndex ? (
        <div className="mb-10">
          <div
            className={`w-full mt-1 mb-4 py-1 text-sm text-[#374151] flex items-center`}
          >
            <p className="w-[180px]">Allow Multiple files</p>{" "}
            <span className="ml-10 w-fit">
              <PurpleSwitch
                {...{
                  inputProps: {
                    accentColor: formState?.accentColor,
                    "aria-label": "Switch demo",
                  },
                }}
                checked={question.multiple}
                onChange={handleIsMultipleFileChange}
              />
            </span>
          </div>
          <div className={`w-full mt-1 mb-5 py-1 text-sm text-[#374151] flex`}>
            <p className="w-[180px]">Maximum file size</p>{" "}
            <span className="ml-12 font-medium">5 MB</span>
          </div>
          <div className="text-[10px] text-gray-400">
            This form can accept up to 10 MB of files.
          </div>
          <div className="flex justify-start gap-4 items-center">
            <p className="text-sm text-gray-700 ">
              The type of file users can upload for this question.
            </p>
            <div className="relative">
              <div
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className="h-[48px] w-[242px] border py-2 px-3 rounded flex items-center cursor-pointer "
              >
                <div className="mr-2">{findCurrentFileType().icon}</div>
                <div className="ml-1 mr-2">
                  <span className="text-gray-600 text-sm">
                    {findCurrentFileType().name}
                  </span>
                </div>
                <div className="ml-auto">
                  <MdArrowDropDown className="text-2xl text-gray-500" />
                </div>
              </div>
              {isOpen && (
                <div
                  ref={questionTypeDropdownRef}
                  style={{
                    marginTop: "-48px",
                    position: "absolute",
                    boxShadow:
                      "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
                  }}
                  className="py-2 min-w-[242px] bg-white border border-[#0000001f] rounded z-50"
                >
                  {options.map((option, index) => {
                    return (
                      <Fragment key={index}>
                        <div
                          onClick={() => {
                            handleChangeFileType(option.type);
                          }}
                          className={`${
                            findCurrentFileType().type === option.type &&
                            "bg-[#e4f0fc]"
                          } h-[48px] w-full py-2 px-3 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                        >
                          <div className="mr-2">{option.icon}</div>
                          <div className="ml-1 mr-2">
                            <span className="text-gray-600 text-sm">
                              {option.name}
                            </span>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-7 w-fit h-[34px] border text-gray-400 rounded flex items-center pl-[11px] pr-[15px]">
          <FiUpload className="mr-3 text-sm" />{" "}
          <span className="text-sm">Add file</span>
        </div>
      )}

      {/* <div className="formbold-mb-5 formbold-file-input">
        <input
          type="file"
          name={`file-${question.id}`}
          id={`file-${question.id}`}
          onChange={(e) => {
            // console.log("e.target.files", e.target.files);
            setFiles(e.target.files);
            // console.log("files", files);
          }}
          disabled={isEditor}
          multiple={question?.multiple}
          className="w-full"
        />
        <label htmlFor={`file-${question.id}`}>
          {files?.length > 0 ? (
            <div className="flex flex-row items-center flex-wrap">
              {[...files]?.map((file, i) => {
                return (
                  <div className="w-[100px] p-4 h-full mr-4 border flex flex-col items-center bg-gray-100 rounded-md">
                    <div>
                      <BsFillFileEarmarkSpreadsheetFill className="text-4xl text-gray-500" />
                    </div>
                    <div className="mt-6 w-full text-xs truncate">
                      {file?.name}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <span className="formbold-drop-file text-gray-500">
                Drop a file here or click the button below to load the file.
              </span>
              <span className="formbold-browse">Choose file</span>
            </div>
          )}
        </label>
      </div> */}

      <QuestionBottomOptions
        helpers={{
          question,
          setFormState,
          questionIndex,
          activeState,
          setActiveState,
          setActive,
          schemas,
        }}
      />
      {show && (
        <FileUploadConfirmation
          isMultiple={isMultiple}
          setShow={setShow}
          handleFileUploadTypeChange={handleFileUploadTypeChange}
          handleIsMultipleFileInput={handleIsMultipleFileInput}
          tempFileType={tempFileType}
        />
      )}
    </div>
  );
};

export default FileUpload;
