import { useEffect, useState } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { validateEmail, validateMobileNo } from "../../utils/validateEmail";
import { toast } from "react-toastify";
import zIndex from "@mui/material/styles/zIndex";

interface MobileNoSelectProps {
  selectedValue: any;
  updateMobileValue: any;
  index?: any;
  disabled?: boolean;
  isCustomStyle?: boolean;
  filterList?: string[];
}

const customStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    fontSize: "14px", // Adjust as needed
    border: "none",
    borderBottom: state.isFocused ? "2px solid #4c2b87" : "1px solid #e5e5e5",
    borderRadius: "0",
    outline: "none",
    boxShadow: "none",
    transition: "border-bottom-color 0.2s ease-in-out",
    zIndex: "200",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#a0aec0",
  }),
};
const MobileNoSingleSelect: React.FC<MobileNoSelectProps> = ({
  selectedValue,
  updateMobileValue,
  index,
  disabled,
  isCustomStyle,
  filterList,
}) => {
  const [workSpaceContacts, setWorkSpaceContacts] = useState([]);
  // Get contacts list of the workspace from localstorage
  useEffect(() => {
    const owner = JSON.parse(localStorage.getItem("owner") || "");
    let contacts = owner?.contacts;
    if (filterList) {
      contacts = owner?.contacts?.filter((contact: any) => {
        return !filterList?.includes(contact?.phoneNumber);
      });
    }
    setWorkSpaceContacts(contacts);
  }, [filterList]);
  return (
    <>
      <CreatableSelect
        isDisabled={disabled}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor:
              state.isFocused || state.menuIsOpen
                ? "hsl(var(--primary))"
                : provided.borderColor,
            "&:hover": {
              borderColor: "hsl(var(--primary))",
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "hsl(var(--primary))"
              : state.isFocused
              ? "hsl(var(--secondary))"
              : undefined,
            color: state.isSelected ? "white" : "black",
            "&:hover": {
              backgroundColor: "hsl(var(--secondary))",
              color: "hsl(var(--primary))",
            },
          }),
        }}
        className={`w-full text-xs md:text-sm outline-none`}
        isMulti={false}
        value={
          selectedValue ? { value: selectedValue, label: selectedValue } : null
        }
        options={workSpaceContacts
          ?.filter((contact: any) => contact?.phone)
          .map((contact: any) => ({
            value: contact?.phone,
            label: `${contact?.phone} - ${contact?.firstName || ""}`,
          }))}
        onChange={(newValue) => {
          const details: any = workSpaceContacts.find((contact: any) => {
            return contact.phone === newValue?.value;
          });
          const name =
            (details?.firstName?.trim() || "") +
            " " +
            (details?.lastName?.trim() || "");
          updateMobileValue(
            newValue?.value,
            index,
            name.trim(),
            details?.email
          );
        }}
        onCreateOption={(inputValue) => {
          if (validateMobileNo(inputValue.trim())) {
            const data = {
              name: "",
              source: "MANUAL",
              email: "",
              phone: inputValue.trim(),
            };
            const temp: any = [...workSpaceContacts];
            // Don't add new email if already exists
            if (
              temp.filter((option: any) => option.phone === data.phone)
                .length === 0
            ) {
              temp.push(data);
            }
            setWorkSpaceContacts(temp);
            updateMobileValue(inputValue.trim(), index);
          } else {
            toast.error(
              "Please enter a valid phone number with proper country code!"
            );
            updateMobileValue("", index);
          }
        }}
        // formatCreateLabel={(inputValue) => {
        //   return `Add phone number: ${inputValue}`;
        // }}
        placeholder="e.g. +1 408 XXX XXXX"
        components={{
          DropdownIndicator: isCustomStyle
            ? null
            : components.DropdownIndicator,
        }}
      />
    </>
  );
};

export default MobileNoSingleSelect;
