import { useEffect, useRef, useState, useCallback } from "react";

import axios from "../../utils/axios";
// import axios from "axios";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import { v4 } from "uuid";
// import { fetchOwner } from "../../redux/actions/OwnerAction";
// import { connect } from "react-redux";
import { fetchOwner } from "../../utils";
import { Template } from "../../pdfme/generator/src";
import { Designer } from "../../pdfme/ui/src";
import { getSampleByType } from "../../pdfme/ui/src/helper";
import _ from "lodash";
import { SendToSignersArgsTypes } from "../../pdfme/common/src/type";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import { getEmbedHome, isEmbeded } from "../../utils/embed";
import TrialExtendedModal from "../TrialExtendedModal";
import { PREVIEW_AVAILABLE_STATUS } from "../../constants/common";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  increaseTrialEndDateByOneDay,
  setOwnerRx,
} from "../../redux/slices/ownerSlice";
import { useDispatch } from "react-redux";

interface MapperProps {
  file?: File | null;
  numberOfPages?: number;
  receiversList?: any;
  schemas?: any;
  isSaved?: boolean;
  startSave?: boolean;
  setIsSaved?: (value: any) => void;
  setIsPreview: (value: any) => void;
  setStartSave: (value: any) => void;
  setReload: (value: any) => void;
  fetchedData: any;
}

const SignatureMapper = ({
  file,
  numberOfPages,
  receiversList,
  schemas,
  setIsSaved,
  startSave,
  setIsPreview,
  setStartSave,
  setReload,
  fetchedData,
}: MapperProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);
  const [owner, setOwner] = useState<any>({});
  const [templateSchema, setTemplateSchema] = useState<any>([]);
  const [searchParams] = useSearchParams();
  let documentId = searchParams.get("documentId");

  const [showTrialExtendedModal, setShowTrialExtendedModal] = useState({
    show: false,
    days: 0,
    redirectOnClose: "",
  });

  useEffect(() => {
    let updatedOwner = async () => {
      const owner = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))?._id
      );
      setOwner(owner);
    };
    updatedOwner();
  }, []);

  function isObjectEmpty(obj: object) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  // @ts-ignore
  useEffect(() => {
    const explicitySchemas = [];
    const schemaPage: any = {};
    let firstReceiver = receiversList?.[0];
    const key = firstReceiver?.name;

    schemaPage[key + `(1)`] = {
      id: v4() + "_" + firstReceiver?._id,
      type: "image",
      data: getSampleByType("image"),
      position: {
        x: 5,
        y: 5,
      },
      width: 70,
      height: 20,
    };

    // @ts-ignore
    explicitySchemas.push(schemaPage);
    const template: Template = {
      // schemas: templateSchema,
      // schemas: schemas || explicitySchemas || [{}],
      schemas: schemas || [{}],
      // sampledata: [{ Name: "Input example", photo: "https://picsum.photos/200/300"}],
      //   sampledata: sampledata || [{}],
      // @ts-ignore
      basePdf: file,
    };

    const inputs = [
      {
        name: "Pet Name",
        photo: "https://picsum.photos/200/300",
        age: "4 years",
        sex: "Male",
        weight: "33 pounds",
        breed: "Mutt",
        owner: "https://pdfme.com/",
      },
    ];

    // Run this effect when the containerRef is available
    if (designerRef.current) {
      designer.current = new Designer({
        domContainer: designerRef.current,
        template,
        receiversList,
        sendToSigners: sendToSigners,
        fetchedData,
        setReload,
        setIsPreview: setStartSave,
        instanceFrom: "PDF",
        inputs: fetchedData?.document?.pdfTemplate?.inputs,
      });
      designer.current.onSaveTemplate(saveTemplate);
      designer.current.onSaveTemplateWithoutRedirect(
        saveTemplateWithoutSending
      );
      // designer.current.onChangeTemplate(setTemplate);
    }
    return () => {
      // Destroy the designer when the component unmounts
      // @ts-ignore
      // designer.current.destroy();
    };
  }, [designerRef, file, receiversList]);

  //! function to send to signers
  const saveSignatureTemplateSchema = async (
    schemas: {}[],
    sendToSignerArgs: SendToSignersArgsTypes
    // redirect = true
  ): Promise<any> => {
    let ownerId =
      owner._id || JSON.parse(localStorage.getItem("owner") || "")?._id;
    if (!ownerId) {
      toast.error("something went wrong, please try again later");
    }

    try {
      let redirect = true;
      if (typeof sendToSignerArgs?.redirect !== "undefined") {
        redirect = sendToSignerArgs?.redirect;
      }
      const response = getIpAddressLocal();
      const { ip } = await response;
      const { data } = await axios.post(`/saveSignatureTemplateSchema`, {
        templateSchema: schemas,
        documentId,
        ip,
        ownerId,
        isLink: sendToSignerArgs?.isLink,
      });
      // Special return when quota exhausted
      if (data.error === "You have exhausted your allowed quota") {
        return "quota_exhausted";
      }

      if (data?.error) {
        toast.error(data.error);
        return "failed";
      }
      console.log("sendToSignerArgs", sendToSignerArgs);

      if (!data?.error) {
        toast.success(data?.message);
        let trialExtended = data?.trialExtended;

        if (trialExtended?.extended) {
          dispatch(increaseTrialEndDateByOneDay());
        }

        if (redirect && !sendToSignerArgs?.isLink) {
          if (trialExtended?.extended) {
            setShowTrialExtendedModal({
              show: true,
              days: trialExtended.days,
              redirectOnClose: `/dashboard/${
                localStorage.getItem("documentType")
                  ? localStorage.getItem("documentType")
                  : "templates"
              }`,
            });
            return;
          } else {
            setTimeout(() => {
              if (isEmbeded()) {
                navigate(getEmbedHome());
              } else {
                navigate(
                  `/dashboard/${
                    localStorage.getItem("documentType")
                      ? localStorage.getItem("documentType")
                      : "templates"
                  }`
                );
              }
            }, 2000);
            return;
          }
        }

        if (trialExtended?.extended) {
          setShowTrialExtendedModal({
            show: true,
            days: trialExtended.days,
            redirectOnClose: "",
          });
        }

        return data;
      }

      return "failed";
    } catch (error) {
      toast.error("Error saving signature template schema");
      return "failed";
    }
  };

  //! end of function to send to signers

  // const sendToSigners = async (redirect?: boolean) => {
  const sendToSigners = async (sendToSignerArgs: SendToSignersArgsTypes) => {
    // @ts-ignore
    let template = designer.current.getTemplate();
    let response: any = await saveSignatureTemplateSchema(
      template.schemas,
      sendToSignerArgs
    );
    return response;
  };

  const saveTemplate = async () => {
    // downloadJsonFile(designer.current.getTemplate(), 'template');
    // @ts-ignore
    let template = designer.current.getTemplate();
    console.log(template, "template");
  };

  const handleSave = async (template: any) => {
    console.log("handle save only pdf");
    try {
      const { data } = await axios.post("/saveasdraftSchemaOnly", {
        documentId,
        templateSchema: template.schemas,
      });
      if (data.error) {
        // toast.error(data.error);
      }
      setIsSaved && (await setIsSaved((prev: boolean) => !prev));
    } catch (error) {
      console.log(error);
      toast.error("Error saving template");
    }
  };

  const debounceCallHandleSave = useCallback(
    _.debounce((template: any) => handleSave(template), 2000),
    []
  );

  const saveTemplateWithoutSending = async () => {
    if (PREVIEW_AVAILABLE_STATUS.includes(fetchedData?.document?.status)) {
      console.log("Returning without saving schema this is preview mode");
      return;
    }
    // @ts-ignore
    let template = designer.current.getTemplate();
    debounceCallHandleSave(template);
  };

  const handlePreviewAndSave = async () => {
    if (startSave) {
      // @ts-ignore
      let template = designer.current.getTemplate();
      await handleSave(template);
      setStartSave && (await setStartSave(false));
      setIsPreview && setIsPreview(true);
    }
  };

  useEffect(() => {
    handlePreviewAndSave();
  }, [startSave]);
  return (
    <div>
      <div className="overflow-hidden" ref={designerRef} id="container"></div>{" "}
      {/* This will be used as the container */}
      {/* <button className="p-2 rounded-lg bg-blue-300" onClick={saveTemplate}>
        Save template
      </button> */}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
      {showTrialExtendedModal.show && (
        <TrialExtendedModal
          setShow={() => {
            setShowTrialExtendedModal({
              show: false,
              days: 0,
              redirectOnClose: "",
            });

            if (showTrialExtendedModal.redirectOnClose) {
              setTimeout(() => {
                if (isEmbeded()) {
                  navigate(getEmbedHome());
                } else {
                  navigate(showTrialExtendedModal.redirectOnClose);
                }
              }, 2000);
            }
          }}
          show={showTrialExtendedModal.show}
          completedTask={{
            title: "Send a PDF for signing",
            description: "Send a PDF for signing to your user",
            increasedDays: showTrialExtendedModal.days,
          }}
        />
      )}
    </div>
  );
};

export default SignatureMapper;
