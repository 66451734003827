import {
  PDFDocument,
  PDFName,
  PDFArray,
  PDFTextField,
  PDFRadioGroup,
  PDFCheckBox,
  PDFDropdown,
  PDFSignature,
} from "pdf-lib";
import { v4 } from "uuid";
import { SchemaForUI as SchemaForUIZod } from "../pdfme/common/src/schema";
import { generateUniqueKeyRoot } from "../pdfme/ui/src/components/Designer";
import { SchemaForUI } from "../pdfme/common/src";
import { getRandomColorV1 } from "./uiUtils";
const ZOOM = 2.835;

const baseFieldSchema = {
  alignment: "left",
  fontSize: 10,
  characterSpacing: 0,
  lineHeight: 1,
  required: true,
  ifDateFormat: "mm/dd/yyyy",
  radiogroupId: "",
  dropdownOptions: [{ option: "Option 1", value: "Option 1" }],
};
// if any new fields are added in content view this it to be updated
const subType2TitlePdfTempalte: { [key: string]: string } = {
  signature: "Signature",
  initials: "Initials",
  stamp: "Stamp",
  text: "Text",
  number: "Number",
  date: "Date",
  checkbox: "Checkbox",
  radiogroup: "RadioGroup",
  dropdown: "DropDown",
  name: "Name",
  email: "Email",
  static_image: "Image",
  table: "Table",
};
const subType2TitlePdf: { [key: string]: string } = {
  checkbox: "Checkbox",
  radiogroup: "RadioGroup",
};

const formInputs: { [key: string]: string } = {
  PDFSignature: "PDFSignature",
  PDFRadioGroup: "PDFRadioGroup",
  PDFTextField: "PDFTextField",
  PDFCheckBox: "PDFCheckBox",
  PDFDropdown: "PDFDropdown",
};

const fieldSize = (rect: any, pageSize: any, inputType: string) => {
  //! divided the value by ZOOM factor to fix the size of input
  // PDF coordinates originate from the bottom left corner of the page in pdf-lib data.
  // However, our editor uses the top left corner as the origin.
  // Adjusting the x and y coordinates accordingly.
  let x = rect.x;
  let y = pageSize.height - rect.y - rect.height;
  // For Dropdown the height is fixed
  const height = inputType === formInputs.PDFDropdown ? 10 : rect.height / ZOOM;
  const sizeData = {
    position: {
      x: parseFloat((x / ZOOM).toFixed(2)),
      y: parseFloat((y / ZOOM).toFixed(2)),
    },
    width: parseFloat((rect.width / ZOOM).toFixed(2)),
    height: parseFloat(height.toFixed(2)),
  };
  return sizeData;
};

export async function getEditableFields(
  newFile: any,
  newRoles: any,
  instanceFrom: "PDF" | "PDF-TEMPLATE" | "FORM"
) {
  try {
    // Get the pdfBytes from the file
    const pdfBytes = new Uint8Array(await newFile.arrayBuffer());
    // Load data using PDFDocument
    const pdfDoc = await PDFDocument.load(pdfBytes);
    // Get form data for the pdf
    const form = pdfDoc.getForm();
    // Get all the fields in the form
    const fields = form.getFields();
    console.log(fields);
    // Get all pages (It is used in finding the page number of the field)
    const pages = pdfDoc.getPages();
    // roles data for the schema
    const rolesData = {
      role: newRoles[0],
      signatureColour: newRoles[0].colour,
    };
    // SchemaList help to convert schema to Editor Schema
    let schemasList: SchemaForUI[][] = pages.map((_: any) => []);
    // This schema is the final schema that is added to db
    let schema: any = pages.map((_: any) => ({}));
    // Loop over each field from the list of fields (Find the pageNumber, type and location of the field)
    fields.forEach((field: any) => {
      // use the name of the field as key
      let key = field.getName();
      // -1 pageNumber means no page found
      let pageNumber = -1;
      // Get the widget of the field that helps in finding the position (x,y) and (width,height)
      const widget = field.acroField.getWidgets()[0];

      // Loop over pages to find the page number of the field
      for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        const pageRaw = page.node.lookupMaybe(PDFName.of("Annots"), PDFArray);
        const pageAnnots = pageRaw ? pageRaw.asArray() : [];
        // Page Annots gives all the ref of the annotaions , fields present on the page
        // Check for the field ref on the page (If exits then store the page Number)
        if (pageAnnots.includes(field.ref)) {
          pageNumber = i;
          break;
        }
        // This helps to find the page number of the sub-fields (Radio & Checkbox)
        // It gives the array of kids of the grouped element
        const optionsRef = field?.acroField?.normalizedEntries()?.Kids?.array;
        // If any of the option from the group element is present then return the page number of the group element
        if (optionsRef.some((option: any) => pageAnnots.includes(option))) {
          pageNumber = i;
          break;
        }
        //! NOTE: The (Radio/Checkbox)group  element's annotion is not found in the page annotaions so the above process is required for sub-options
      }
      // If page Number is found for the field
      if (pageNumber !== -1) {
        const page = pages[pageNumber];
        const pageSize = page.getSize();
        // Input type is signature
        if (field instanceof PDFSignature) {
          const inputType = formInputs.PDFSignature;
          // Modify the key according to pagenumber
          key = key + "_pg_" + pageNumber;
          const rect = widget.getRectangle();
          const data = {
            ...baseFieldSchema,
            ...rolesData,
            ...fieldSize(rect, pageSize, inputType),
            id: v4(),
            key: key,
            showKey: "Signature",
            data: "unsigned_DBKEYV1",
            type: "image",
            subType: "signature",
          };
          SchemaForUIZod.parse(data);
          schema[pageNumber][key] = data;
        } else if (field instanceof PDFRadioGroup) {
          const inputType = formInputs.PDFRadioGroup;
          const options = field.acroField.getWidgets();
          const radioGroupId = v4();
          for (let i = 0; i < options.length; i++) {
            key = field.getName() + "_pg_" + pageNumber + "_opt_" + i;
            const option = options[i];
            const rect = option.getRectangle();
            const data = {
              ...baseFieldSchema,
              ...rolesData,
              ...fieldSize(rect, pageSize, inputType),
              id: v4(),
              key: key,
              showKey: "RadioGroup",
              data: "unchecked_DBKEYV1",
              type: "image",
              subType: "radiogroup",
              radioGroupColor: getRandomColorV1(),
              radiogroupId: radioGroupId,
            };
            SchemaForUIZod.parse(data);
            schema[pageNumber][key] = data;
          }
        } else if (field instanceof PDFTextField) {
          const inputType = formInputs.PDFTextField;
          key = key + "_pg_" + pageNumber;
          const rect = widget.getRectangle();
          const fieldData = field.getText();
          const data = {
            ...baseFieldSchema,
            ...rolesData,
            ...fieldSize(rect, pageSize, inputType),
            id: v4(),
            key: key,
            showKey: "Text",
            data: fieldData ? fieldData : "Text",
            type: "text",
            subType: "text",
          };
          SchemaForUIZod.parse(data);
          schema[pageNumber][key] = data;
        } else if (field instanceof PDFCheckBox) {
          const inputType = formInputs.PDFCheckBox;
          const options = field.acroField.getWidgets();
          for (let i = 0; i < options.length; i++) {
            const rect = options[i].getRectangle();
            key = field.getName() + "_pg_" + pageNumber + "_check_" + i;
            const data = {
              ...baseFieldSchema,
              ...rolesData,
              ...fieldSize(rect, pageSize, inputType),
              id: v4(),
              key: key,
              showKey: "CheckBox",
              data: "unchecked_DBKEYV1",
              type: "image",
              subType: "checkbox",
            };
            SchemaForUIZod.parse(data);
            schema[pageNumber][key] = data;
          }
        } else if (field instanceof PDFDropdown) {
          const inputType = formInputs.PDFDropdown;
          key = key + "_pg_" + pageNumber;
          const rect = widget.getRectangle();
          const options = field.getOptions();
          var finalOptions = options.map((option: string) => ({
            option: option,
            value: option,
          }));
          const data = {
            ...baseFieldSchema,
            ...rolesData,
            ...fieldSize(rect, pageSize, inputType),
            id: v4(),
            key: key,
            showKey: "DropDown",
            data: "",
            type: "text",
            subType: "dropdown",
            dropdownOptions: finalOptions,
          };
          SchemaForUIZod.parse(data);
          schema[pageNumber][key] = data;
        }
      }
    });
    // Convert the schema to editor type Schema List for proper adding of key
    for (let pgIdx in schema) {
      let page = schema?.[pgIdx];
      for (let [, value] of Object.entries<{ [key: string]: any }>(page)) {
        let baseKey =
          subType2TitlePdf[value?.subType] || rolesData.role?.title || "Signer";
        if (instanceFrom === "PDF-TEMPLATE") {
          baseKey = subType2TitlePdfTempalte[value?.subType] || "Signer";
        }
        //@ts-ignore
        let newSchema: SchemaForUI = {
          ...value,
          key: generateUniqueKeyRoot({
            baseKey: baseKey,
            schemasList,
            instanceFrom: instanceFrom,
            receiversList: [],
            pageCursor: parseInt(pgIdx),
            pageNo: parseInt(pgIdx),
          }),
        };
        SchemaForUIZod.parse(newSchema);
        schemasList[parseInt(pgIdx)].push(newSchema);
      }
    }
    // Revert back schemaList to Schema to store into DB
    let modifiedSchema = schemasList.map((page: SchemaForUI[]) => {
      let newPage: { [key: string]: SchemaForUI } = {};
      page.forEach((schema: SchemaForUI) => {
        newPage[schema.key] = schema;
      });
      return newPage;
    });
    console.log("Schema", modifiedSchema);
    return modifiedSchema;
  } catch (error) {
    console.log("ERROR: ", error);
    return [];
  }
}
