import React, {
  KeyboardEvent,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  DEFAULT_ALIGNMENT,
  DEFAULT_CHARACTER_SPACING,
  DEFAULT_FONT_COLOR,
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  TextSchema,
  calculateDynamicFontSize,
} from "../../../../common/src";
import { STATIC_SCHEMA, ZOOM } from "../../constants";
import { FontContext } from "../../contexts";
import { SchemaUIProps } from "./SchemaUI";
import { getWhiteLabelInfoLocal } from "../../../../../utils";
import { getCSSFont } from "../../../../common/src/constants";
import { v4 } from "uuid";
import { COLORS } from "../../../../../constants/common";
import { PiUploadSimpleFill } from "react-icons/pi";
import UploadFileModal from "../UploadFileModal";
import ReactDOM from "react-dom";
import { MdDelete } from "react-icons/md";
import { isEditorSchema } from "../../designerHelper";

type Props = SchemaUIProps & { schema: TextSchema };

const FileUploadSchemaUI = (
  {
    schema,
    editable,
    placeholder,
    tabIndex,
    onChange,
    isActive,
    setActiveSchema,
    setShownModal,
    outlineStyle,
    isPreview,
    previewStyle,
    isSigning,
    previewState,
    changeSchemas,
    pageSizes,
    pageCursor,
    isApprover,
    designerState,
  }: Props,
  ref: Ref<HTMLTextAreaElement> | Ref<HTMLInputElement>
) => {
  const [showUploadFileModal, setShowUploadFileModal] =
    useState<boolean>(false);
  const [fileUploadName, setFileUploadName] = useState<string>("");

  const whiteLabelInfo = getWhiteLabelInfoLocal();
  const primaryColor =
    whiteLabelInfo?.settings?.organization?.themeHex?.primary || COLORS.primary;
  const font = useContext(FontContext);

  const [dynamicFontSize, setDynamicFontSize] = useState<number | undefined>(
    undefined
  );
  const isEditorARecipient = isEditorSchema({
    schema,
    receiversList: designerState?.receiversList || [],
    instanceFrom: designerState?.instanceFrom || "PDF-TEMPLATE",
  });

  useEffect(() => {
    if (schema.dynamicFontSize && schema.data) {
      calculateDynamicFontSize({
        textSchema: schema,
        font,
        input: schema.data,
      }).then(setDynamicFontSize);
    } else {
      setDynamicFontSize(undefined);
    }
  }, [
    schema.data,
    schema.width,
    schema.fontName,
    schema.fontSize,
    schema.dynamicFontSize,
    schema.dynamicFontSize?.max,
    schema.dynamicFontSize?.min,
    schema.characterSpacing,
    font,
  ]);

  const getBackground = () => {
    if (isPreview && previewStyle !== "DEFAULT") return "transparent";

    if (schema?.isQuestionDeleted) {
      return "rgb(255 0 0 / 0.30)";
    }

    if (schema.backgroundColor) {
      return schema.backgroundColor;
    }

    if (isPreview || isSigning) {
      if (schema.isStatic) {
        return "transparent";
      }
      if (previewState?.fetchedData?.accountInfo?.theme) {
        if (isActive && editable) {
          return previewState?.fetchedData?.accountInfo?.theme + "70";
        } else {
          return previewState?.fetchedData?.accountInfo?.theme + "20";
        }
      } else {
        if (isActive && editable) {
          // return primaryColor + "70";
          return `hsl(var(--primary) / 0.55)`;
        } else {
          // return primaryColor + "20";
          return `hsl(var(--primary) / 0.2)`;
        }
      }
    }
    if (isApprover && schema?.isStatic) {
      return "transparent";
    }

    if (isActive && editable) {
      if (schema?.isStatic) return STATIC_SCHEMA.color + 70;

      if (schema?.signatureColour) return schema?.signatureColour + 70;

      if (schema?.role?.colour) return schema?.role?.colour + "70";
    } else {
      if (schema?.isStatic) return STATIC_SCHEMA.color + 20;

      if (schema?.signatureColour) return schema?.signatureColour + 20;

      if (schema?.role?.colour) return schema?.role?.colour + "20";
    }
  };

  const style: React.CSSProperties = {
    padding: "0.2rem",
    resize: "none",
    position: "absolute",
    fontFamily:
      getCSSFont({
        currFont: schema?.fontName,
      }) || "Poppins",
    height: schema.height * ZOOM,
    width: schema.width * ZOOM,
    textAlign: schema.alignment ?? DEFAULT_ALIGNMENT,
    verticalAlign: "middle",
    fontSize: `${dynamicFontSize ?? schema.fontSize ?? DEFAULT_FONT_SIZE}pt`,
    letterSpacing: `${schema.characterSpacing ?? DEFAULT_CHARACTER_SPACING}pt`,
    lineHeight: `${schema.lineHeight ?? DEFAULT_LINE_HEIGHT}em`,
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    border: "none",
    color: schema.fontColor ? schema.fontColor : DEFAULT_FONT_COLOR,
    backgroundColor: getBackground(),
    fontWeight: schema.bold ? 600 : 400,
    fontStyle: schema.italic ? "italic" : "normal",
  };
  // here in preview schema data contains the name of the file so data is shown
  // in signing schema data contains the url of the file so first the name is extracted and that name is shown else the the placeholder or showkey
  function getEditorSpanData(): string {
    if (isPreview) {
      // This condition is for if the editor has already filled the schema before so show the data accordingly in preview screen
      if (schema.data?.startsWith("https://boloforms-signatures")) {
        let uploadFileName = "";

        // Here schema.data comes as a url now we have to extract the name from the url string
        let fileNameSplitArray = schema?.data?.split("/");
        // url contains v4 uuid then file name so v4 has 36 characters
        uploadFileName =
          fileNameSplitArray[fileNameSplitArray.length - 1]?.substring(36);
        return (
          uploadFileName ||
          schema?.placeholder ||
          schema?.data ||
          schema.showKey ||
          ""
        );
      }
      return schema?.data || schema?.placeholder || schema.showKey || "";
    }
    if (isSigning || isEditorARecipient) {
      let uploadFileName = "";
      if (schema?.data !== "") {
        // Here schema.data comes as a url now we have to extract the name from the url string
        let fileNameSplitArray = schema?.data?.split("/");
        // url contains v4 uuid then file name so v4 has 36 characters
        uploadFileName =
          fileNameSplitArray[fileNameSplitArray.length - 1]?.substring(36);
      }
      return (
        uploadFileName ||
        schema?.placeholder ||
        schema?.data ||
        schema.showKey ||
        ""
      );
    }
    return (
      fileUploadName ||
      schema?.placeholder ||
      schema?.data ||
      schema.showKey ||
      ""
    );
  }
  function getTitle(): string {
    if (schema?.isQuestionDeleted) {
      return "This Question is Deleted";
    } else {
      if (isApprover) {
        if (
          (!schema?.isStatic || schema?.subType !== "variable") &&
          schema?.role
        ) {
          let signer = previewState?.signersData?.[schema?.role?.title];
          return signer?.name || signer?.email || schema?.showKey;
        }
      }
      return schema?.showKey;
    }
  }
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };
  const handleClick = () => {
    if (isApprover) {
      return;
    }
    if (setActiveSchema) {
      setActiveSchema(schema);
    }
    if (schema?.data === "") {
      setShowUploadFileModal(true);
    }
  };
  // for all normal i.e non static schemas it should works as it worked previously
  // for staic schemas
  // condition 1. in designer it should be editable
  // condition 2. in form viewer it should be shown as text
  let newEditable = editable || isEditorARecipient;
  if (schema.isStatic) {
    newEditable = !(isPreview || isSigning || isApprover);
  }
  // format the below return properly
  // too many ternaries
  // console.log(schema, "newEditable", newEditable);

  return (
    <div title={getTitle()} className={`w-0 h-0`}>
      {newEditable ? (
        <div
          data-schema-sub-type={schema.subType}
          data-schema-id={schema.id}
          tabIndex={tabIndex}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          style={style}
          className={` ${
            isActive && newEditable
              ? "bg-primary/70"
              : "bg-primary bg-opacity-20"
          }`}
        >
          <div className="flex gap-2 items-center justify-center">
            {schema?.data === "" && <PiUploadSimpleFill />}
            {/*  Set the letterSpacing of the last character to 0. */}
            <span>
              {getEditorSpanData()
                .split("")
                .map((l, i) => (
                  <span
                    key={i}
                    style={{
                      letterSpacing:
                        String(getEditorSpanData()).length === i + 1
                          ? 0
                          : "inherit",
                    }}
                  >
                    {l}
                  </span>
                ))}
            </span>
            <div
              style={{
                fontSize: `${
                  dynamicFontSize ?? schema.fontSize ?? DEFAULT_FONT_SIZE
                }pt`,
              }}
              title={"Delete attachment"}
              onClick={() => {
                onChange("");
              }}
            >
              {schema?.data !== "" && <MdDelete className="text-red-500" />}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={style}
          className={` ${
            isActive && newEditable
              ? "bg-primary/70"
              : "bg-primary bg-opacity-20"
          }`}
        >
          <div className="flex gap-2 items-center justify-center">
            <PiUploadSimpleFill />
            {/*  Set the letterSpacing of the last character to 0. */}
            <span>
              {getEditorSpanData()
                .split("")
                .map((l, i) => (
                  <span
                    key={i}
                    style={{
                      letterSpacing:
                        String(getEditorSpanData()).length === i + 1
                          ? 0
                          : "inherit",
                    }}
                  >
                    {l}
                  </span>
                ))}
            </span>
          </div>
        </div>
      )}
      {showUploadFileModal &&
        ReactDOM.createPortal(
          <UploadFileModal
            onChange={onChange}
            setShowUploadFileModal={setShowUploadFileModal}
            brandingColor={previewState?.fetchedData?.accountInfo}
            schema={schema}
            setFileUploadName={setFileUploadName}
            isSigning={isSigning}
            isEditorARecipient={isEditorARecipient}
          />,
          document.getElementById("modal-root")!
        )}
    </div>
  );
};

export default forwardRef<HTMLTextAreaElement, Props>(FileUploadSchemaUI);
