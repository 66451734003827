import React from "react";
import BoloCheckboxMui from "../Common/Checkbox";
import { BsCartCheck, BsCartCheckFill } from "react-icons/bs";
import { BoloButton } from "../Common/Button/BoloButton";
import { secondsInDay } from "date-fns";
import { IoMdCheckmark } from "react-icons/io";

type AddonCardType = {
  name: string;
  description: string;
  icon: JSX.Element;
  planName: string;
  price: string;
  subType: string;
  isImage?: boolean;
  about: string;
  helpBlogs?: {
    name: string;
    link: string;
  }[];
  images: string[];
  featureName: string;
  isComingSoon?: boolean;
  featureRequestUrl?: string;
  isAnnual?: boolean;
  isContactUs?: boolean;
};

interface AddonCardProps {
  addOn: AddonCardType;
  setShowingDetailsFor: React.Dispatch<
    React.SetStateAction<AddonCardType | null>
  >;
  addonCart: AddonCardType[];
  setAddonCart: React.Dispatch<React.SetStateAction<AddonCardType[]>>;
  featuresEnabled: any;
}

const AddonCard = ({
  addOn,
  setShowingDetailsFor,
  addonCart,
  setAddonCart,
  featuresEnabled,
}: AddonCardProps) => {
  return (
    <div
      key={addOn.name}
      className=" m-0 flex flex-col items-start justify-between p-5 border border-gray-200 rounded-[4px] shadow-md cursor-pointer"
      onClick={() => {
        setShowingDetailsFor(addOn);
      }}
    >
      <div className="flex flex-col gap-1">
        <div className="flex justify-between">
          {!addOn?.isImage && (
            <div className="bg-secondary text-primary p-2 rounded-sm max-w-fit">
              {addOn.icon}
            </div>
          )}

          {addOn?.isImage && <div className="">{addOn.icon}</div>}
        </div>

        <h3 className="font-semibold mt-2">{addOn.name}</h3>
        <p className="text-xs text-gray-500 h-[36px] lg:h-[44px]">
          {addOn.description}
        </p>
      </div>
      <div className="flex flex-col gap-1 mt-8">
        {!addOn?.isComingSoon && (
          <>
            <span className="text-sm text-gray-500">
              Available with your plan for
            </span>
            <p className="text-sm font-semibold">
              <span className="text-primary text-lg">${addOn.price} </span>
              <span className="">per month</span>
            </p>
          </>
        )}
        {addOn?.isContactUs && (
          <>
            <span className="text-sm text-gray-500">Starts at</span>
            <p className="text-sm font-semibold">
              <span className="text-primary text-lg">${addOn.price} </span>
              <span className="">per month</span>
            </p>
          </>
        )}
        {addOn?.isComingSoon ? (
          <BoloButton
            variant="secondary"
            className={` px-3 py-2 w-fit sm:text-sm text-xs mt-2 hover:opacity-90 flex items-center justify-center gap-2 whitespace-nowrap shadow-md z-[10]`}
            onClick={(e) => {
              e.stopPropagation();

              if (addOn?.isContactUs) {
                window.open("https://cal.com/boloforms/signature", "_blank");
                return;
              }

              window.open(addOn.featureRequestUrl, "_blank");
            }}
            disabled={!addOn?.featureRequestUrl && !addOn?.isContactUs}
          >
            {addOn?.featureRequestUrl
              ? "Show Interest"
              : addOn?.isContactUs
              ? "Contact Us"
              : "Coming Soon"}
          </BoloButton>
        ) : featuresEnabled[addOn.featureName] ? (
          <button
            className={` px-3 py-2 w-fit sm:text-sm text-xs rounded-md mt-2 opacity-90 flex items-center justify-center gap-2 whitespace-nowrap shadow-md border-2 border-green-600   z-[10]`}
          >
            <IoMdCheckmark size={20} className="text-green-600" />
            <span className="text-green-600">Subscribed</span>
          </button>
        ) : (
          <BoloButton
            variant="secondary"
            className={` px-3 py-2 w-fit sm:text-sm text-xs mt-2 hover:opacity-90 flex items-center justify-center gap-2 whitespace-nowrap shadow-md `}
            onClick={(e) => {
              e.stopPropagation();
              setAddonCart((prev) =>
                prev.some((item) => item.featureName === addOn.featureName)
                  ? prev.filter(
                      (item) => item.featureName !== addOn.featureName
                    )
                  : [...prev, addOn]
              );
            }}
          >
            {addonCart.some(
              (item) => item.featureName === addOn.featureName
            ) ? (
              <>
                <BsCartCheckFill size={20} /> Added to cart
              </>
            ) : (
              <>
                <BsCartCheck size={20} /> Add to cart
              </>
            )}
          </BoloButton>
        )}
      </div>
    </div>
  );
};

export default AddonCard;
