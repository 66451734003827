// @ts-nocheck

import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import { MdOutlineDragIndicator } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { BsPinAngleFill } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";

const ColumnsShowHide = ({ columns, setTableData }) => {
  const [searchParams] = useSearchParams();
  let formId = searchParams.get("id");

  const [searchCols, setSearchCols] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [activeColumns, setActiveColumns] = useState(columns);

  const saveColumnPrefrencesToLocalStorage = (cols) => {
    let formColumnPrefrences = localStorage.getItem("formColumnPrefrences");
    if (formColumnPrefrences) {
      formColumnPrefrences = JSON?.parse(formColumnPrefrences);

      cols = cols.map((col) => {
        return {
          ...col,
          Header: col.name,
        };
      });
      formColumnPrefrences[formId] = cols;
      let formColumnPrefrencesString = JSON.stringify(formColumnPrefrences);
      localStorage.setItem("formColumnPrefrences", formColumnPrefrencesString);

      return;
    }
    formColumnPrefrences = {};
    cols = cols.map((col) => {
      return {
        ...col,
        Header: col.name,
      };
    });
    formColumnPrefrences[formId] = cols;
    let formColumnPrefrencesString = JSON.stringify(formColumnPrefrences);
    localStorage.setItem("formColumnPrefrences", formColumnPrefrencesString);
  };

  const handleColumnPinUnpin = (accessor) => {
    setTableData((prev) => {
      let cols = prev.columns.map((col) => {
        if (col.accessor === accessor) {
          return {
            ...col,
            pinned: !col.pinned,
          };
        }
        return col;
      });

      // console.log(cols);
      saveColumnPrefrencesToLocalStorage(cols);

      return {
        ...prev,
        columns: cols,
      };
    });

    if (searchCols.length > 0 && searchText.trim() !== "") {
      setSearchCols((prev) =>
        prev.map((col) => {
          if (col.accessor === accessor) {
            return {
              ...col,
              pinned: !col.pinned,
            };
          }
          return col;
        })
      );
    }
  };

  const handleCheckboxChange = (e, accessor) => {
    setTableData((prev) => {
      let cols = prev.columns.map((col) => {
        if (col.accessor === accessor) {
          return {
            ...col,
            show: e.target.checked,
          };
        }
        return col;
      });
      // console.log("columns", cols);
      saveColumnPrefrencesToLocalStorage(cols);

      return {
        ...prev,
        columns: cols,
      };
    });
    if (searchCols.length > 0 && searchText.trim() !== "") {
      setSearchCols((prev) =>
        prev.map((col) => {
          if (col.accessor === accessor) {
            return {
              ...col,
              show: e.target.checked,
            };
          }
          return col;
        })
      );
    }

    // JSON?.parse(localStorage.getItem("formColumnPrefrences"))
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText.trim().toLowerCase());
    if (searchText.trim() === "") return setSearchCols([]);

    let filteredColumns = columns.filter((col) => {
      return col.name.toLowerCase().includes(searchText.toLowerCase());
    });
    setSearchCols(filteredColumns);
  };

  let onSortEnd = (columns) => {
    if (searchCols.length !== 0) return;
    // console.log("updatedColumns", columns);
    setTableData((prev) => {
      return { ...prev, columns: columns };
    });
    saveColumnPrefrencesToLocalStorage(columns);
  };

  useEffect(() => {
    let cols =
      searchCols.length > 0 || searchText.length > 0 ? searchCols : columns;
    setActiveColumns(cols);
  }, [searchCols, searchText, columns]);

  return (
    <div
      style={{
        zIndex: 50,
      }}
      className="w-[400px] h-[350px] border absolute  left-0 mt-1 bg-white rounded-lg shadow-lg overflow-hidden"
    >
      <div className="font-medium py-4 px-3 text-sm text-gray-700 flex flex-row items-center ">
        Show/Hide Columns
      </div>
      <div className="py-2 px-3 text-sm text-gray-700 flex flex-row items-center border-y border-gray-300">
        <BiSearch className="text-xl mr-3" />{" "}
        <input
          type="text"
          placeholder="Search in columns..."
          className="w-full py-2 outline-none"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="h-[244px] overflow-y-auto">
        {searchCols.length > 0 ||
          (searchText.length > 0 && (
            <p className="text-sm text-center px-4 py-8">
              There is no result for <strong>{searchText}</strong>
            </p>
          ))}

        <ReactSortable
          animation={200}
          delayOnTouchStart={true}
          delay={2}
          list={activeColumns}
          setList={onSortEnd}
        >
          {activeColumns?.map((column, i) => {
            return (
              <div
                style={{
                  zIndex: 100,
                  display:
                    !column.pinned || column.accessor === "hidden"
                      ? "none"
                      : "flex",
                }}
                key={i}
                className="py-4 px-3 text-sm text-gray-700 flex-row items-center hover:bg-gray-100 bg-gray-100"
              >
                <MdOutlineDragIndicator className="text-xl mr-3 text-gray-600 cursor-pointer" />
                <div className="flex justify-center">
                  <input
                    type="checkbox"
                    className="mr-3 h-4 w-4"
                    onChange={(e) => handleCheckboxChange(e, column.accessor)}
                    value={column?.show}
                    checked={column?.show}
                  />
                </div>
                <div className="w-3/4 truncate">{column?.name}</div>
                <div onClick={() => handleColumnPinUnpin(column.accessor)}>
                  <BsPinAngleFill
                    className={`${
                      column.pinned ? "opacity-100" : "opacity-50"
                    } text-xl ml-auto mr-3 text-gray-600 cursor-pointer`}
                  />
                </div>
              </div>
            );
          })}
        </ReactSortable>

        <ReactSortable
          animation={200}
          delayOnTouchStart={true}
          delay={2}
          list={activeColumns}
          setList={onSortEnd}
        >
          {activeColumns?.map((column, i) => {
            return (
              <div
                style={{
                  zIndex: 100,
                  display: column.pinned ? "none" : "flex",
                }}
                key={i}
                className="py-4 px-3 text-sm text-gray-700 flex-row items-center hover:bg-gray-100"
              >
                <MdOutlineDragIndicator className="text-xl mr-3 text-gray-600 cursor-pointer" />
                <div className="flex justify-center">
                  <input
                    type="checkbox"
                    className="mr-3 h-4 w-4"
                    onChange={(e) => handleCheckboxChange(e, column.accessor)}
                    value={column?.show}
                    checked={column?.show}
                  />
                </div>
                <div className="w-3/4 truncate">{column?.name}</div>
                <div onClick={() => handleColumnPinUnpin(column.accessor)}>
                  <BsPinAngleFill
                    className={`${
                      column.pinned ? "opacity-100" : "opacity-50"
                    } text-xl ml-auto mr-3 text-gray-600 cursor-pointer`}
                  />
                </div>
              </div>
            );
          })}
        </ReactSortable>
      </div>
    </div>
  );
};

export default ColumnsShowHide;
