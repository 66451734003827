import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { getDocumentValueInfo } from "../../pdfme/ui/src/components/Designer";
import { formatPrice } from "../../utils/quoteBuilder";
import { BoloButton } from "../Common/Button/BoloButton";
import {
  BoloPopover,
  BoloPopoverContent,
  BoloPopoverTrigger,
} from "../Common/PopOver/BoloPopOver";
import CustomTooltip from "../EditForm/CustomTooltip";

const PaymentInfoModal = ({
  document,
  docType,
  pdfTemplateRespondent,
  brandingInfo,
}: {
  document: any;
  docType: "PDF" | "PDF_TEMPLATE";
  pdfTemplateRespondent?: any;
  brandingInfo?: any;
}) => {
  let documentValue = getDocumentValueInfo(document);
  const [open, setOpen] = useState(false);
  return (
    // <BoloPopover open={true} onOpenChange={(value) => setOpen(value)}>
    //   <BoloPopoverTrigger asChild>
    <>
      <BoloButton
        data-tooltip-id="document-value"
        variant={"secondary"}
        size="sm"
        className="w-[36px] px-0 py-0 sm:px-4 sm:py-1.5 sm:w-auto h-[36px] sm:h-auto disabled:!opacity-100 flex items-center justify-center"
        onClick={() => {
          setOpen(!open);
        }}
        style={{
          color: brandingInfo?.theme,
          borderColor: brandingInfo?.theme,
        }}
      >
        <span className="hidden sm:block">
          {formatPrice({
            amount: documentValue.currAmount,
            currency: documentValue.amount.currency,
          })}
        </span>
        <FaInfoCircle className="text-lg sm:ml-2" />
        <CustomTooltip
          helpers={{
            id: "document-value",
            content: `Document Value: ${formatPrice({
              amount: documentValue.currAmount,
              currency: documentValue.amount.currency,
            })} to be paid after the document is signed by all parties`,
            place: "top",
          }}
        />
      </BoloButton>
    </>
    //   </BoloPopoverTrigger>
    //   <BoloPopoverContent align="end" className="z-[30]">
    //     <div className="bg-white h-[500px]">Testabac</div>
    //   </BoloPopoverContent>
    // </BoloPopover>
  );
};

export default PaymentInfoModal;
