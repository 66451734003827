//@ts-nocheck

import React, { useEffect } from "react";
import GoogleDescInput from "../GoogleDescInput";
import { BsThreeDotsVertical } from "react-icons/bs";
import SectionOptions from "../SectionOptions";
import { replaceHTags } from "../../../utils/editor";
import { COLORS } from "../../../constants/common";

const PageSection = ({ helpers }) => {
  const moreOptionsRef = React.useRef(null);

  let { index, page, formState, setFormState, active, setActive } = helpers;
  const [titleUnprivilegedEditor, setTitleUnprivilegedEditor] =
    React.useState(null);
  const [descUnprivilegedEditor, setDescUnprivilegedEditor] =
    React.useState(null);
  const [show, setShow] = React.useState({
    moreOptions: false,
  });

  const onChangeTitleInput = async (value) => {
    let newValue = value;
    newValue = await replaceHTags(newValue);

    setFormState((prevState) => {
      let newForm = { ...prevState };
      newForm.pages[index].titleHtml = newValue;
      let value_ = titleUnprivilegedEditor?.getText()?.replace(/\n/g, "");
      titleUnprivilegedEditor?.getText() &&
        (newForm.pages[index].title = value_);
      return newForm;
    });
  };
  const onChangeDescInput = async (value) => {
    let newValue = value;
    newValue = await replaceHTags(newValue);

    setFormState((prevState) => {
      let newForm = { ...prevState };
      newForm.pages[index].descriptionHtml = newValue;
      let value_ = descUnprivilegedEditor?.getText()?.replace(/\n/g, "");
      descUnprivilegedEditor?.getText() &&
        (newForm.pages[index].description = value_);
      return newForm;
    });
  };

  const handleOutsideClick = (e) => {
    if (moreOptionsRef.current !== null) {
      if (moreOptionsRef.current.contains(e.target)) return;
    }
    setShow((prev) => ({ ...prev, moreOptions: false }));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="max-w-[768px] mx-auto">
      <div className="h-[36px]">
        <div
          style={{
            background: formState.accentColor ?? COLORS.primary,
          }}
          className="border-x text-sm w-fit h-full text-white flex items-center px-4 rounded-t-lg"
        >
          Section {index + 1} of {formState.pages.length - 1}
        </div>
      </div>
      <div
        onClick={() => {
          setActive((prev) => ({
            ...prev,
            pageIndex: index,
            questionIndex: null,
          }));
        }}
        className={`border rounded-tr-lg rounded-b-lg bg-white border-[#dadce0] cursor-pointer`}
      >
        <div className="flex flex-row h-full">
          <div
            className={`${
              active.pageIndex === index &&
              active.questionIndex === null &&
              "bg-blue-500"
            } min-w-[6px] flex flex-col mr-2.5 rounded-bl-lg`}
          ></div>
          <div className="pl-2 pt-3 pb-6 pr-6 w-full flex flex-row items-center">
            <div className="w-full flex flex-col">
              <div className="flex">
                <GoogleDescInput
                  helpers={{
                    setUnprivilegedEditor: setTitleUnprivilegedEditor,
                    value: page?.titleHtml ?? page?.title,
                    onChange: onChangeTitleInput,
                    fontSize: "16px",
                    isDescOption: false,
                  }}
                />
                <div ref={moreOptionsRef} className="relative">
                  <div
                    onClick={() =>
                      setShow((prev) => ({
                        ...prev,
                        moreOptions: !prev.moreOptions,
                      }))
                    }
                    className="ml-4 w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
                  >
                    <BsThreeDotsVertical className="text-xl text-gray-600" />
                  </div>
                  {show.moreOptions && (
                    <SectionOptions
                      helpers={{
                        index,
                        page,
                        formState,
                        setFormState,
                        active,
                        setActive,
                        setShow,
                      }}
                    />
                  )}
                </div>
              </div>
              <GoogleDescInput
                helpers={{
                  setUnprivilegedEditor: setDescUnprivilegedEditor,
                  value: page?.descriptionHtml ?? page?.description,
                  onChange: onChangeDescInput,
                  fontSize: "14px",
                  isDescOption: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSection;
