import React from "react";
import { Oval } from "react-loader-spinner";
import { cn } from "../lib/utils";

export type BoloLoaderProps = {
  height?: number;
  width?: number;
  color?: string;
  secondaryColor?: string;
  strokeWidth?: number;
};

const BoloLoader = ({
  height,
  width,
  color,
  secondaryColor,
  strokeWidth = 2,
}: BoloLoaderProps) => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <Oval
      visible={true}
      height={height || "60"}
      width={width || "60"}
      color={color || "#6B7280"}
      secondaryColor={secondaryColor || "#c4c7cc"}
      ariaLabel="oval-loading"
      wrapperStyle={style}
      wrapperClass=""
      strokeWidth={strokeWidth}
    />
  );
};

const BoloLoaderLine = ({
  outerClass,
  innerClass,
  loading,
  bgColor = "",
}: {
  outerClass?: string;
  innerClass?: string;
  loading?: boolean;
  bgColor?: string;
}) => {
  return (
    <div
      className={cn(
        "loader-line w-full h-0.5 relative overflow-hidden bg-transparent",
        outerClass
      )}
    >
      {loading && (
        <div
          className={cn("loader-bar absolute h-0.5 bg-primary", innerClass)}
          style={{ backgroundColor: bgColor && `${bgColor}` }}
        ></div>
      )}
    </div>
  );
};

export { BoloLoaderLine };

export default BoloLoader;
