import React, { useEffect, useState } from "react";

const useActiveElmData = (scrollTop?: number) => {
  const [data, setData] = useState({
    isActive: false,
    schema: { subType: "", id: "" },
    pos: {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    },
  });

  function resetData() {
    setData((prevData) => ({
      ...prevData,
      isActive: false,
      schema: { subType: "", id: "" },
    }));
  }

  function getActiveElmPosRoot(elm: Element | null) {
    if (elm) {
      const activeElement = elm;
      const rect = activeElement.getBoundingClientRect();
      const position = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
        height: rect.height,
      };
      const subType = activeElement.getAttribute("data-schema-sub-type");
      const id = activeElement.getAttribute("data-schema-id");
      setData((prev) => {
        const data: any = {
          isActive: !!subType,
          schema: { subType: subType || "", id },
        };
        if (subType) {
          data.pos = position;
        }
        return {
          ...prev,
          ...data,
        };
      });
    } else {
      resetData();
    }
  }

  function getActiveElmPosEv(event: any) {
    if (
      event?.target instanceof Element &&
      (event?.target?.matches(":focus-visible") ||
        event?.target?.matches(":focus"))
    ) {
      const activeElement = event.target;
      getActiveElmPosRoot(activeElement);
    }
  }

  function getActiveElmPosGL() {
    getActiveElmPosRoot(document.activeElement);
  }

  useEffect(() => {
    document.addEventListener("focusin", getActiveElmPosEv);
    document.addEventListener("focus", getActiveElmPosEv);
    document.addEventListener("blur", resetData);
    document.addEventListener("focusout", resetData);

    return () => {
      document.removeEventListener("focusin", getActiveElmPosEv);
      document.removeEventListener("focus", getActiveElmPosEv);
      document.removeEventListener("blur", resetData);
      document.removeEventListener("focusout", resetData);
    };
  }, []);

  useEffect(() => {
    getActiveElmPosGL();
  }, [scrollTop]);

  return { elmData: data };
};

export default useActiveElmData;
