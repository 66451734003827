import { PDFDocument } from "pdf-lib";

interface Position {
  top: number;
  left: number;
}

export function ggID(): () => number {
  let id = 0;
  return function genId() {
    return id++;
  };
}

export const getMovePosition = (
  x: number,
  y: number,
  dragX: number,
  dragY: number,
  width: number,
  height: number,
  pageWidth: number,
  pageHeight: number,
  isPreMoved: boolean
): Position => {
  if (isPreMoved) {
    return {
      top: y,
      left: x,
    };
  }
  const newPositionTop = y + dragY;
  const newPositionLeft = x + dragX;
  const newPositionRight = newPositionLeft + width;
  const newPositionBottom = newPositionTop + height;

  let top =
    newPositionTop < 0
      ? 0
      : newPositionBottom > pageHeight
      ? pageHeight - height
      : newPositionTop;
  let left =
    newPositionLeft < 0
      ? 0
      : newPositionRight > pageWidth
      ? pageWidth - width
      : newPositionLeft;

  return {
    top,
    left,
  };
};

export const normalize = (value: number): number =>
  parseFloat((value / 255).toFixed(1));

export const removeDefaultInputFields = async (
  pdfFile: File
): Promise<{ error?: string; file: File }> => {
  try {
    const pdfBytes = await pdfFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const form = pdfDoc.getForm();
    // Get all form fields in the pdf
    const formFields = form.getFields();

    // Loop through each form field and remove it
    formFields.forEach((field) => {
      form.removeField(field);
    });

    const modifiedPdfBytes = await pdfDoc.save();

    // Create a new File object with the modified PDF bytes
    const fileWithNoInputs = new File([modifiedPdfBytes], pdfFile.name, {
      type: "application/pdf",
      lastModified: new Date().getTime(),
    });

    return { file: fileWithNoInputs };
  } catch (error) {
    console.log("Error in removing fields", error);
    // If an error occurs, return the original PDF file
    if (error instanceof Error) {
      return { error: error?.message, file: pdfFile };
    } else {
      return { error: "Error in removing fields", file: pdfFile };
    }
  }
};
