import React, { useEffect, useState } from "react";
import { MdOutlineDownloadDone, MdOutlinePending } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { getWorkSpaceDetails } from "../../utils";
import MixPanel from "../../utils/services/mixpanel";
import { getStatusByPayment } from "../../utils/quoteBuilder";
import { BoloButton } from "../Common/Button/BoloButton";
import BoloCheckboxMui from "../Common/Checkbox";

interface MobileLayoutDashboardProps {
  documentType: string;
  rowData: any;
  ActionsFormOptions?: any;
  ActionsPDFOptions?: any;
  ActionsPDFTemplateOptions?: any;
  downloadPlainSignature?: any;
  showSelectedDownload?: any;
  setSelectedPdfs?: any;
  selectedPdfs?: any;
}

const MobileLayoutDashboard: React.FC<MobileLayoutDashboardProps> = ({
  documentType,
  rowData,
  ActionsFormOptions,
  downloadPlainSignature,
  ActionsPDFOptions,
  ActionsPDFTemplateOptions,
  showSelectedDownload,
  selectedPdfs,
  setSelectedPdfs,
}) => {
  // Your component logic here
  let navigate = useNavigate();
  const [workSpaceDetails, setWorkSpaceDetails] = useState(
    getWorkSpaceDetails()
  );
  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      setWorkSpaceDetails(localWorkSpaceDetails);
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);
  return (
    <div className="block md:hidden">
      <div className="flex flex-col">
        {rowData.map((row: any, idx: any) => {
          return (
            <div
              key={idx}
              className={`relative border border-t py-3 px-3 flex gap-2 justify-between items-center hover:bg-secondary/40 cursor-pointer`}
              onClick={() => {
                if (
                  showSelectedDownload &&
                  row?.isAllSigned &&
                  documentType == "PDF"
                ) {
                  if (
                    selectedPdfs?.some(
                      (item: any) => item?.documentId === row?.documentId
                    )
                  ) {
                    setSelectedPdfs((prev: any) =>
                      prev.filter(
                        (item: any) => item?.documentId !== row?.documentId
                      )
                    );
                  } else {
                    setSelectedPdfs((prev: any) => [...prev, row]);
                  }
                }
              }}
            >
              <div className="flex gap-3">
                {showSelectedDownload && documentType === "PDF" && (
                  <BoloCheckboxMui
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setSelectedPdfs((prevSelected: any) => {
                        let newSelected = [...prevSelected];
                        if (
                          isChecked &&
                          !newSelected.some(
                            (item) => item?.documentId === row?.documentId
                          )
                        ) {
                          newSelected.push(row);
                        } else if (!isChecked) {
                          newSelected = newSelected.filter(
                            (item: any) => item.documentId !== row.documentId
                          );
                        }
                        return newSelected;
                      });
                    }}
                    disabled={!row?.isAllSigned}
                    checked={selectedPdfs.some(
                      (item: any) => item.documentId === row.documentId
                    )}
                  />
                )}
                <div className="break-all flex text-xs flex-col ml-2">
                  <div className="">
                    <span translate="no" className=" font-semibold">
                      {workSpaceDetails?.isWorkSpaceOwner &&
                        row?.folder?.name && (
                          <>
                            <Link
                              to={`/folder/items?folderId=${row?.folder?.folderId}`}
                            >
                              {row?.folder?.name}
                            </Link>{" "}
                            /{" "}
                          </>
                        )}
                    </span>
                    {row?.documentName ||
                      row?.formJson?.title ||
                      row?.name ||
                      "Untitled"}
                  </div>
                  <div className="flex flex-wrap gap-2 text-[10px] mb-[2px]">
                    <span
                      style={{
                        color:
                          documentType == "PDF"
                            ? getStatusByPayment({ document: row }).color
                            : "",
                      }}
                    >
                      {documentType == "FORM"
                        ? row?.pdfTemplate?.basePdfName || "Default PDF"
                        : documentType == "PDF"
                        ? getStatusByPayment({ document: row }).status
                        : // : "\u00A0"}
                          ""}
                    </span>
                    <span className="text-gray-600">
                      {getStatusByPayment({ document: row }).message}
                    </span>
                  </div>
                  <div className="flex text-[10px] gap-2">
                    <span className="text-gray-700">
                      {new Date(row.createdAt).toDateString()}
                    </span>
                    <span className="text-gray-500">
                      {new Date(row.createdAt).toLocaleTimeString()}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {documentType == "FORM" && (
                  <>
                    <BoloButton
                      variant={"tertiary"}
                      size="xs"
                      className="text-xs md:text-xs"
                      onClick={() => {
                        console.log("response");
                        new MixPanel().track("Form", {
                          form_action: "form_see_responses",
                          paid: false,
                        });
                        new MixPanel().increment("form_see_responses");
                        navigate(`/form?id=${row?.formId}`);
                      }}
                      // className={`w-fit whitespace-nowrap flex flex-row justify-center items-center text-center hover:opacity-90 text-xs rounded capitalize border px-2 py-1.5 bg-secondary text-primary font-medium border-primary/50 hover:border-primary shadow-sm`}
                    >
                      Responses
                    </BoloButton>
                    <ActionsFormOptions
                      form={row}
                      i={idx}
                      formDocuments={rowData}
                      instanceFrom={"MOBILE"}
                    />
                  </>
                )}
                {documentType == "PDF" && (
                  <>
                    <div
                      //... people out of ${document.totalRespondants}`} @aysh why was it document.totalRespondants ?

                      data-tip={`This document is signed by ${row?.totalSignedRespondants} people out of ${row?.totalRespondants}`}
                      className="flex flex-col justify-center items-center w-fit mr-2 gap-1"
                    >
                      <div className={``}>
                        {row?.totalSignedRespondants ===
                          row?.totalRespondants && (
                          <MdOutlineDownloadDone className="text-lg text-green-400" />
                        )}
                        {row?.totalSignedRespondants !==
                          row?.totalRespondants && (
                          <MdOutlinePending className="text-lg text-yellow-400" />
                        )}
                      </div>
                      <p className="text-[10px] text-gray-500">
                        {row?.totalSignedRespondants} / {row?.totalRespondants}
                      </p>
                    </div>
                    <BoloButton
                      variant={"tertiary"}
                      size="xs"
                      className="text-xs md:text-xs"
                      onClick={async () => {
                        new MixPanel().track("PDF", {
                          pdf_action: "pdf_download",
                          paid: false,
                        });
                        new MixPanel().increment("pdf_download");
                        if (row?.finishedPdfUrl) {
                          window.open(row?.finishedPdfUrl, "_blank");
                        } else {
                          await downloadPlainSignature(row?.documentId);
                        }
                      }}
                      // className={`w-fit whitespace-nowrap flex flex-row justify-center items-center text-center hover:opacity-90 text-xs rounded capitalize border px-2 py-1.5 bg-secondary text-primary font-medium border-primary/50 hover:border-primary shadow-sm`}
                    >
                      Download
                    </BoloButton>
                    <ActionsPDFOptions documents={rowData} documentData={row} />
                  </>
                )}
                {documentType == "PDF-TEMPLATE" && (
                  <>
                    <BoloButton
                      size={"xs"}
                      variant={"tertiary"}
                      className="text-xs md:text-xs"
                      onClick={() => {
                        new MixPanel().track("PDF template", {
                          pdf_template_action: "pdf_template_see_responses",
                          paid: false,
                        });
                        new MixPanel().increment("pdf_template_see_responses");
                        navigate(
                          `/dashboard/templates/respondents?documentId=${row?.documentId}`
                        );
                      }}
                      // className={`w-fit whitespace-nowrap flex flex-row justify-center items-center text-center hover:opacity-90 text-xs rounded capitalize border px-2 py-1.5 bg-secondary text-primary font-medium border-primary/50 hover:border-primary shadow-sm`}
                    >
                      Responses
                    </BoloButton>
                    <ActionsPDFTemplateOptions
                      i={idx}
                      documents={rowData}
                      documentData={row}
                    />
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileLayoutDashboard;
