import React from "react";
import ReactDOM from "react-dom";
// import { PreviewProps } from '@pdfme/common';
import { PreviewProps } from "../../common/src";
import { PreviewUI } from "./class";
import { DESTROYED_ERR_MSG } from "./constants";
import { I18nContext, FontContext } from "./contexts";
import Preview from "./components/Preview";

class Viewer extends PreviewUI {
  private isPreview: boolean;
  constructor(props: PreviewProps) {
    super(props);
    this.render();
    this.isPreview = props?.isPreview ? true : false;
  }

  protected render() {
    if (!this.domContainer) throw Error(DESTROYED_ERR_MSG);
    ReactDOM.render(
      <I18nContext.Provider value={this.getI18n()}>
        <FontContext.Provider value={this.getFont()}>
          <Preview
            template={this.template}
            size={this.size}
            inputs={this.inputs}
            isPreview={this.isPreview}
          />
        </FontContext.Provider>
      </I18nContext.Provider>,
      this.domContainer
    );
  }
}

export default Viewer;
