// @ts-nocheck
import { FaRegFilePdf } from "react-icons/fa6";
import { MdClose } from "react-icons/md";

const SamplePdfModal = ({ setShowSamplePdfModal, handleFileInputChange }) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`${"w-[95%] sm:w-[600px]"}  relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-5 overflow-y-auto`}
      >
        <div className="w-full  bg-white rounded-lg relative">
          <MdClose
            onClick={() => setShowSamplePdfModal(false)}
            className="absolute text-2xl top-3 md:top-4 right-2 md:right-4 text-gray-400 cursor-pointer"
          />
          <div className="pb-6 pt-4 px-1 md:px-3">
            <h1 className="text-lg  font-semibold text-gray-700">Sample Pdf</h1>
          </div>
          <div
            className="border-y border-1 hover:border-primary flex gap-4 py-4 px-5 cursor-pointer hover:bg-secondary"
            // onClick={(e) => {
            //   // handleFileInputChange(e);
            //   console.log(samplePdfFile);
            //   setFile(samplePdfFile);
            //   if (isSignature) {
            //     onFileUpload(samplePdfFile);
            //     setFileName && setFileName(samplePdfFile.name);
            //   } else if (isOnlyPdfTemplate) {
            //     onFileUpload && onFileUpload(samplePdfFile);
            //   }
            //   setShowSamplePdfModal(false);
            // }}
            onClick={handleFileInputChange}
          >
            <FaRegFilePdf className="inline-block text-xl text-gray-700" />{" "}
            <span className="text-b"> Sample</span>
          </div>
          <div className="p-3 flex justify-end gap-4 mt-32">
            <button
              onClick={() => setShowSamplePdfModal(false)}
              className="px-5 py-2 bg-primary rounded text-white ml-2 font-medium text-xs md:text-sm hover:opacity-75"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamplePdfModal;
