import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import { DropdownMenuItem } from "../Common/SelectDropDown/BoloDropDown";
import { toast } from "react-toastify";
import { LiaSignatureSolid } from "react-icons/lia";
import {
  MdOutlineDelete,
  MdOutlineDriveFileMoveRtl,
  MdOutlineMessage,
  MdOutlinePictureAsPdf,
} from "react-icons/md";
import { CiBoxList } from "react-icons/ci";
import { LuFolderPlus } from "react-icons/lu";
import { IoCopyOutline } from "react-icons/io5";
import { FiExternalLink, FiShare2 } from "react-icons/fi";
import { SlSocialDropbox } from "react-icons/sl";
import { SiMicrosoftonedrive } from "react-icons/si";
import { isFeatureEnabled } from "../../utils/paidFeat";
import MixPanel from "../../utils/services/mixpanel";
import { openExternalLinkUtil } from "../../utils";
import { getDashboardUrl } from "../../constants/constants";
import { LiaGoogleDrive } from "react-icons/lia";
import "./action-dropdown.css";
const DropdownActionsTemplate = ({
  owner,
  document,
  dropdownState,
  updateDropdownState,
  isIntegrationPermissionAvailable,
  type = "DEFAULT",
  folderId = null,
}: any) => {
  let navigate = useNavigate();

  const cloneTemplates = async (documentId: any) => {
    try {
      let cloneData = {
        documentId: documentId,
      };
      if (type === "FOLDER" && folderId) {
        //@ts-ignore
        cloneData = { ...cloneData, folderId: folderId };
      }
      const { data } = await axios.post(`/copy-template`, cloneData);
      if (!data.error) {
        updateDropdownState("reload", !dropdownState.reload);
        return toast.success("Template cloned successfully");
      } else {
        return toast.error(data.error);
      }
    } catch (error) {
      console.log("cloning Template error: ", error);
      return toast.error("Failed to clone Template");
    }
  };

  const actions = [
    {
      show: true,
      onClick: () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF template", {
            pdf_template_action: "pdf_template_send_for_signature",
            paid: false,
          });
          new MixPanel().increment("pdf_template_send_for_signature");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_template_send_for_signature",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_template_send_for_signature");
        }

        navigate(
          `/create/pdf-template/send-for-signature?documentId=${document.documentId}`
        );
      },
      disabled:
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
            "readonly") ||
        dropdownState.loading,
      icon: <LiaSignatureSolid className="text-xl" />,
      label: "Send for Signatures",
      hiddenOnDesktop: type === "DEFAULT",
    },
    {
      show: type === "FOLDER",
      onClick: (e: any) => {
        e.stopPropagation();
        new MixPanel().track("Folder", {
          folder_action: "folder_pdf_template_see_responses",
          paid: false,
        });
        new MixPanel().increment("folder_pdf_template_see_responses");
        navigate(
          `/dashboard/templates/respondents?documentId=${document?.documentId}`
        );
      },
      disabled: false,
      icon: <MdOutlineMessage className="text-xl" />,
      label: "Responses",
      hiddenOnDesktop: false,
    },
    {
      show: document?.eTemplateId,
      onClick: () => {
        new MixPanel().track("PDF template", {
          pdf_template_action: "pdf_template_etemplate",
          paid: false,
        });
        new MixPanel().increment("pdf_template_etemplate");
        openExternalLinkUtil(
          `${getDashboardUrl()}/etemplate?id=${document?.eTemplateId}`
        );
      },
      disabled:
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
            "readonly") ||
        dropdownState.loading,
      icon: <FiExternalLink className="text-xl " />,
      label: "Send Web link",
      hiddenOnDesktop: type === "DEFAULT",
    },
    {
      show: true,
      onClick: () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF template", {
            pdf_template_action: "pdf_template_edit",
            paid: false,
          });
          new MixPanel().increment("pdf_template_edit");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_template_edit",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_template_edit");
        }
        navigate(`/create/pdf-template?documentId=${document.documentId}`);
      },
      disabled:
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
            "readonly") ||
        dropdownState.loading,
      icon: <CiBoxList className="text-xl" />,
      label: "Edit Fields",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async (e: any) => {
        e.stopPropagation();
        if (type === "DEFAULT") {
          new MixPanel().track("PDF template", {
            pdf_template_action: "pdf_template_copy",
            paid: true,
          });
          new MixPanel().increment("pdf_template_copy");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_template_copy",
            paid: true,
          });
          new MixPanel().increment("folder_pdf_template_copy");
        }
        await cloneTemplates(document?.documentId);
      },
      disabled:
        (dropdownState.isSharedDashboard &&
          dropdownState.roleInCollaboration === "readonly") ||
        dropdownState.loading,
      icon: <IoCopyOutline className="text-xl" />,
      label: "Copy Template",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF template", {
            pdf_template__action: "pdf_template_replace_pdf",
            paid: false,
          });
          new MixPanel().increment("pdf_template_replace_pdf");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_template_replace_pdf",
            paid: false,
          });
          new MixPanel().increment("folder_pdf_template_replace_pdf");
        }
        navigate(
          `/replace/pdf?documentId=${document?.documentId}&type=PDF-TEMPLATE`
        );
      },
      disabled:
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
            "readonly") ||
        dropdownState.loading,
      icon: <MdOutlinePictureAsPdf className="text-xl" />,
      label: "Replace Pdf",
      hiddenOnDesktop: false,
    },
    {
      show: !dropdownState.isSharedDashboard && type === "DEFAULT",
      onClick: (e: any) => {
        e.stopPropagation();
        new MixPanel().track("PDF template", {
          pdf_template_action: "pdf_template_add_to_folder",
          paid: false,
        });
        new MixPanel().increment("pdf_template_add_to_folder");
        let newFolderOptions = dropdownState.folderOptions;
        newFolderOptions = {
          ...newFolderOptions,
          move: {
            show: true,
            item: {
              id: document?._id,
              type: "PDF-TEMPLATE",
              value: document,
            },
            fromFolder: document?.folder ?? null,
          },
          isAdd: !document?.folder?.name,
        };
        updateDropdownState("folderOptions", newFolderOptions);
      },
      disabled:
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
            "readonly") ||
        dropdownState.loading,
      icon: document?.folder?.name ? (
        <MdOutlineDriveFileMoveRtl className="text-xl" />
      ) : (
        <LuFolderPlus className="text-xl " />
      ),
      label: document?.folder?.name ? "Move to Folder" : "Add to Folder",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async (e: any) => {
        e.stopPropagation();
        updateDropdownState("activeDoc", document);
        updateDropdownState("showShare", true);
        if (type === "FOLDER") {
          updateDropdownState("showShareDocType", "templates");
        }
      },
      icon: <FiShare2 className="text-xl " />,
      label: "Share",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF template", {
            pdf_template_action: "pdf_template_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("pdf_template_add_to_drive");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_template_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("folder_pdf_template_add_to_drive");
        }
        if (dropdownState.isSharedDashboard) {
          toast.error(
            "Contact the owner of this form to integrate with Google Drive"
          );
          return;
        }
        const isDriveIntegrationsEnabled = await isFeatureEnabled(
          "GOOGLE_DRIVE_INTEGRATION"
        );
        if (!isDriveIntegrationsEnabled) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("upgradeModalType", "GOOGLE_DRIVE_INTEGRATION");
          return;
        }

        let isFreePlan =
          owner?.planhistory[owner?.planhistory.length - 1]?.planId ===
          "FREE_PLAN";

        if (isFreePlan) {
          updateDropdownState("showGoogleDriveModal", true);
          return;
        }
        let isDrivePermission = await isIntegrationPermissionAvailable();

        let { data: updatedDocument } = await axios.get(
          `/getOneDocument?documentId=${document.documentId}`
        );

        let isEnabled =
          updatedDocument?.document?.metafields?.googleDrive?.enabled;
        let parentFolderId =
          updatedDocument?.document?.metafields?.googleDrive?.parentFolderId;
        let parentFolderName =
          updatedDocument?.document?.metafields?.googleDrive?.parentFolderName;
        let parentFolderUrl =
          updatedDocument?.document?.metafields?.googleDrive?.parentFolderUrl;
        updateDropdownState("showGoogleDriveModal", true);
        updateDropdownState("driveModalDocStatus", isEnabled);
        updateDropdownState("driveModalDoc", {
          document: updatedDocument.document,
          isDrivePermission: isDrivePermission,
          isEnabled: isEnabled,
          parentFolderId: parentFolderId,
          parentFolderName: parentFolderName,
          parentFolderUrl,
        });
      },
      disabled: !dropdownState.workSpaceDetails?.isWorkSpaceOwner,
      icon: <LiaGoogleDrive className="text-xl" />,
      label: "Integrate With Drive",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async (e: any) => {
        new MixPanel().track("PDF template", {
          pdf_template_action: "pdf_template_add_to_drive",
          paid: true,
        });
        new MixPanel().increment("pdf_template_add_to_drive");

        const isDriveIntegrationsEnabled = await isFeatureEnabled(
          "DROPBOX_INTEGRATION"
        );
        if (!isDriveIntegrationsEnabled) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("upgradeModalType", "DROPBOX_INTEGRATION");
          return;
        }

        let { data: updatedDocument } = await axios.get(
          `/getOneDocument?documentId=${document.documentId}`
        );
        let newdocument = updatedDocument?.document;
        console.log("document", newdocument);
        let driveDocData = {
          documentId: newdocument?.documentId,
          enabled: newdocument?.metafields?.dropboxDrive?.enabled,
          parentFolderName:
            newdocument?.metafields?.dropboxDrive?.parentFolderName,
          parentFolderUrl:
            newdocument?.metafields?.dropboxDrive?.parentFolderUrl,
          parentFolderId: newdocument?.metafields?.dropboxDrive?.parentFolderId,
        };
        console.log("driveDocData", driveDocData);
        updateDropdownState("dropboxDoc", driveDocData);
        updateDropdownState("showDropboxDriveModal", true);
        e.stopPropagation();
      },
      disabled: !dropdownState.workSpaceDetails?.isWorkSpaceOwner,
      icon: <SlSocialDropbox className="text-lg" />,
      label: "Integrate With Dropbox",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: async () => {
        if (type === "DEFAULT") {
          new MixPanel().track("PDF template", {
            pdf_template_action: "pdf_template_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("pdf_template_add_to_drive");
        } else {
          new MixPanel().track("Folder", {
            folder_action: "folder_pdf_template_add_to_drive",
            paid: true,
          });
          new MixPanel().increment("folder_pdf_template_add_to_drive");
        }
        const isDriveIntegrationsEnabled = await isFeatureEnabled(
          "ONEDRIVE_INTEGRATION"
        );
        if (!isDriveIntegrationsEnabled) {
          updateDropdownState("showUpgradeModal", true);
          updateDropdownState("upgradeModalType", "ONEDRIVE_INTEGRATION");
          return;
        }

        let { data: updatedDocument } = await axios.get(
          `/getOneDocument?documentId=${document.documentId}`
        );
        let newdocument = updatedDocument?.document;
        console.log("document", newdocument);
        let driveDocData = {
          documentId: newdocument?.documentId,
          enabled: newdocument?.metafields?.msOneDrive?.enabled,
          parentFolderName:
            newdocument?.metafields?.msOneDrive?.parentFolderName,
          parentFolderUrl: newdocument?.metafields?.msOneDrive?.parentFolderUrl,
          parentFolderId: newdocument?.metafields?.msOneDrive?.parentFolderId,
        };
        updateDropdownState("showOneDriveModal", true);
        updateDropdownState("oneDriveDoc", driveDocData);
        return;
      },
      disabled: !dropdownState.workSpaceDetails?.isWorkSpaceOwner,
      icon: <SiMicrosoftonedrive className="text-xl" />,
      label: "Integrate With OneDrive",
      hiddenOnDesktop: false,
    },
    {
      show: true,
      onClick: (e: any) => {
        updateDropdownState("deletingResponse", document);
        if (type === "DEFAULT") {
          updateDropdownState("show", {
            ...dropdownState.show,
            deleteOneResponseModal: true,
          });
        } else {
          updateDropdownState("showDeletingResponse", {
            ...dropdownState.show,
            deleteOneResponseModal: true,
          });
        }
        e.stopPropagation();
      },
      disabled:
        (!dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
          dropdownState.workSpaceDetails?.currentWorkSpaceRole ===
            "readonly") ||
        dropdownState.loading,
      icon: <MdOutlineDelete className="text-xl" />,
      label: "Delete",
      hiddenOnDesktop: false,
      dangerHover: true,
    },
  ];

  return (
    <>
      {actions.map((action, index) => {
        if (!action.show) return null;
        return (
          <DropdownMenuItem
            key={index}
            onClick={action.onClick}
            disabled={action.disabled}
            className={`flex flex-row items-center text-center text-xs capitalize text-gray-700 px-3 py-2 gap-2 w-full  ${
              action?.dangerHover
                ? "hover:!bg-red-100 text-red-400 hover:!text-red-400"
                : ""
            } ${action?.hiddenOnDesktop ? "flex md:hidden" : ""}`}
          >
            {action.icon}
            {action.label}
          </DropdownMenuItem>
        );
      })}
    </>
  );
};

export default DropdownActionsTemplate;
