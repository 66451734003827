//@ts-nocheck
import { useEffect, useState } from "react";
import {
  MdClose,
  MdDelete,
  MdOutlineDriveFileRenameOutline,
} from "react-icons/md";
import CreateWorkSpace from "../CreateWorkSpace";
import { getOwnerPlanDetails, getWorkSpaceDetails, logOut } from "../../utils";
import CustomTooltip from "../EditForm/CustomTooltip";
import DeleteWorkSpace from "../DeleteWorkSpace";
import { toast } from "react-toastify";
import { isFeatureEnabled } from "../../utils/paidFeat";
import { BLOCKING } from "../../constants/blockingConsants";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
import { getDashboardUrl } from "../../constants/constants";
import { useAppSelector } from "../../redux/store";
import MixPanel from "../../utils/services/mixpanel";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import useWhiteLabelHelper from "../../hooks/reduxHooks/useWhiteLabelHelper";
import { BoloButton } from "../Common/Button/BoloButton";

type SwitchWorkSpacesComponentProps = {
  isModal?: boolean;
  isDownGrade?: boolean;
};
export const SwitchWorkSpacesComponent = ({
  isModal,
  isDownGrade,
}: SwitchWorkSpacesComponentProps) => {
  const [owner, setOwner] = useState(
    JSON.parse(localStorage.getItem("owner") || "{}") || {}
  );
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const whiteLabelHelper = useWhiteLabelHelper();
  const [workSpaces, setWorkSpaces] = useState(owner?.workSpaces || []);
  const [createWorkSpace, setCreateWorkSpace] = useState({
    rename: false,
    create: true,
    show: false,
    workSpace: {},
  });
  const [deleteWorkSpace, setDeleteWorkSpace] = useState({
    show: false,
    workSpace: {},
  });
  const workSpaceDetails = getWorkSpaceDetails();
  const currentWorkSpaceId = workSpaceDetails.currentWorkSpaceId;
  const [currentWorkSpace, setCurrentWorkSpace] = useState(
    currentWorkSpaceId ||
      workSpaces?.find(
        (workspace) => workspace?.workSpaceId == currentWorkSpaceId
      )
  );
  const [upgradeModalType, setUpgradeModalType] = useState("");
  // WorkSpaceBlocking
  const [isWorkSpaceEnbaled, setIsWorkSpaceEnbaled] = useState(false);
  const [upgradeModalData, setUpgradeModalData] = useState({
    data: { title: "", desc: "", videoSrc: "" },
    show: false,
  });
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;
  const [selfWorkSpaceCount, setSelfWorkSpaceCount] = useState(1);

  useEffect(() => {
    let count = 0;
    workSpaces?.map((workspace) => {
      if (workspace?.email == owner?.email) {
        count += 1;
      }
    });
    setSelfWorkSpaceCount(count);
  }, []);

  // console.log({ selfWorkSpaceCount });

  const getWorkSpaceRole = (workspace) => {
    const currentWorkSpaceRole = workspace?.collaborators?.find(
      (collaborator) => collaborator?.collaboratorId?.email == owner?.email
    )?.role;
    let formattedRole = "";
    if (currentWorkSpaceRole == "readonly") {
      formattedRole = "Read Only";
    }
    if (currentWorkSpaceRole == "editor") {
      formattedRole = "Editor";
    }
    if (currentWorkSpaceRole == "admin") {
      formattedRole = "Administrator";
    }
    return { formattedRole, currentWorkSpaceRole };
  };

  const checkFeatures = async () => {
    const isWorkSpaceEnbaledFeature = await isFeatureEnabled("WORKSPACE");
    setIsWorkSpaceEnbaled(isWorkSpaceEnbaledFeature);
  };

  useEffect(() => {
    checkFeatures();
  });

  return (
    // <div
    //   style={{
    //     background: "rgba(0, 0, 0, 0.3)",
    //     zIndex: 100,
    //   }}
    //   className="w-full h-screen fixed inset-0 flex justify-center items-center"
    // >
    //   <div
    //     className={`min-h-fit max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
    //   >
    // <div className="w-[100%] relative flex flex-col">
    //   <MdClose
    //     className="absolute top-0 right-0 cursor-pointer text-2xl"
    //     onClick={() => {
    //       setShow(false);
    //     }}
    //   />{" "}
    //   <div className="text-base md:text-lg mb-4 font-semibold">
    //     Switch Workspace
    //   </div>
    <>
      {createWorkSpace.show && (
        <CreateWorkSpace
          setShow={(value) =>
            setCreateWorkSpace((prev) => ({
              ...prev,
              show: value,
              create: false,
              rename: false,
              workSpace: {},
            }))
          }
          showData={createWorkSpace}
          setWorkSpaces={setWorkSpaces}
        />
      )}
      {deleteWorkSpace.show && (
        <DeleteWorkSpace
          setShow={(value) =>
            setDeleteWorkSpace((prev) => ({
              ...prev,
              show: value,
              workSpace: {},
            }))
          }
          showData={deleteWorkSpace}
          setWorkSpaces={setWorkSpaces}
        />
      )}
      {upgradeModalData.show && (
        <PleaseUpgradeModal
          show={upgradeModalData.show}
          setShow={(value) =>
            setUpgradeModalData((prev) => ({
              ...prev,
              show: value,
              data: BLOCKING.BLANK,
            }))
          }
          data={upgradeModalData.data}
          blockingType={upgradeModalType}
        />
      )}
      <div
        className={`flex flex-col w-full gap-2 text-sm ${
          isModal && "max-h-[450px]"
        } overflow-y-auto`}
        id="mini-pdf-custom-scroll"
      >
        {workSpaces?.map((workspace) => {
          const localWorkSpaceRole = getWorkSpaceRole(workspace);
          return (
            <div
              key={workspace?.workSpaceId}
              className="group flex flex-col-reverse md:flex-row w-full justify-between border px-3 py-3 h-auto md:h-20 items-center rounded cursor-pointer"
              style={{
                borderLeft: `8px solid ${
                  workspace?.accountInfo?.theme ||
                  whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
                  COLORS.primary
                }`,
              }}
              onClick={() => {
                localStorage.setItem("workspaceid", workspace?.workSpaceId);
                window.open("/dashboard/forms", "_self");
              }}
            >
              <div className="flex gap-2 md:gap-8 items-end md:items-center justify-between md:justify-start md:w-[55%] w-[100%]">
                <div className="h-14 w-14">
                  <img
                    className="border"
                    src={
                      workspace?.accountInfo?.companyLogo ||
                      whiteLabelInfo?.settings?.organization?.logo ||
                      IMAGE_LINKS.BOLOLOGO
                    }
                    alt="Company logo"
                  />
                </div>
                <div className="flex md:gap-8 gap-2 mb-1 md:mb-0 items-center justify-between flex-row w-[calc(100%_-_90px)]">
                  {workspace?.email == owner?.email ? (
                    <div className="flex flex-col">
                      <div className="text-xs font-semibold text-left">
                        Owner
                      </div>
                      <div className="text-xs">{owner?.email}</div>
                    </div>
                  ) : (
                    <div className="flex flex-col">
                      <div className="flex flex-row md:flex-col gap-2 md:gap-0">
                        <div className="text-xs font-semibold">Shared</div>
                        <div className="text-xs">
                          {localWorkSpaceRole.formattedRole}
                        </div>
                      </div>
                      <div className="text-xs">{workspace?.email}</div>
                    </div>
                  )}
                  {currentWorkSpaceId == workspace?.workSpaceId && (
                    <div className="text-xs text-green-700 font-semibold rounded bg-green-100 px-3 py-1">
                      Active
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-4 w-[12%]">
                {(workspace?.email == owner?.email ||
                  localWorkSpaceRole.currentWorkSpaceRole == "admin") && (
                  <button
                    data-tooltip-id="hover-btn-rename"
                    data-tooltip-offset={10}
                    className="hidden md:flex items-center justify-center h-8 w-8 min-h-8 min-w-8 rounded-full group-hover:bg-gray-100 opacity-80 hover:opacity-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      new MixPanel().track("Workspace", {
                        workspace_action: "workspace_edit",
                        paid: false,
                      });
                      new MixPanel().increment("workspace_edit");
                      setCreateWorkSpace((prev) => ({
                        ...prev,
                        show: true,
                        rename: true,
                        create: false,
                        workSpace: workspace,
                      }));
                    }}
                  >
                    <MdOutlineDriveFileRenameOutline size={18} />
                    <CustomTooltip
                      helpers={{
                        id: "hover-btn-rename",
                        content: "Rename WorkSpace",
                        place: "top",
                      }}
                    />
                  </button>
                )}
                {workspace?.email == owner?.email && (
                  <button
                    data-tooltip-id="hover-btn-delete"
                    data-tooltip-offset={10}
                    disabled={
                      workSpaces?.length == 1 ||
                      (selfWorkSpaceCount == 1 &&
                        workspace?.email == owner?.email)
                    }
                    className="hidden md:flex items-center justify-center h-8 w-8 min-h-8 min-w-8 rounded-full group-hover:bg-gray-100 opacity-80 hover:opacity-100"
                    onClick={(e) => {
                      e.stopPropagation();
                      new MixPanel().track("Workspace", {
                        workspace_action: "workspace_delete",
                        paid: false,
                      });
                      new MixPanel().increment("workspace_delete");
                      if (
                        workspace?.workSpaceId ===
                        getWorkSpaceDetails().currentWorkSpaceId
                      ) {
                        return toast.error("Cannot delete Active WorkSpace");
                      }
                      setDeleteWorkSpace((prev) => ({
                        ...prev,
                        show: true,
                        workSpace: workspace,
                      }));
                    }}
                  >
                    <MdDelete size={18} />
                    <CustomTooltip
                      helpers={{
                        id: "hover-btn-delete",
                        content: "Delete WorkSpace",
                        place: "top",
                      }}
                    />
                  </button>
                )}
              </div>
              <div className="flex flex-row md:flex-col gap-1 justify-between items-end md:w-[25%] w-[100%] mb-2 md:mb-0">
                <span className="font-semibold truncate max-w-[100%]">
                  {workspace?.name}
                </span>
                <span className="text-xs flex items-center">
                  <span className="mr-4 md:mr-0">
                    Members {workspace?.collaborators?.length || 0}
                  </span>
                  <div className="block md:hidden">
                    <div className="flex gap-2">
                      {(workspace?.email == owner?.email ||
                        localWorkSpaceRole.currentWorkSpaceRole == "admin") && (
                        <button
                          className="flex items-center justify-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            new MixPanel().track("Workspace", {
                              workspace_action: "workspace_edit",
                              paid: false,
                            });
                            new MixPanel().increment("workspace_edit");
                            setCreateWorkSpace((prev) => ({
                              ...prev,
                              show: true,
                              rename: true,
                              create: false,
                              workSpace: workspace,
                            }));
                          }}
                        >
                          <MdOutlineDriveFileRenameOutline size={18} />
                        </button>
                      )}
                      {workspace?.email == owner?.email && (
                        <button
                          className="flex items-center justify-center"
                          disabled={workSpaces?.length == 1}
                          onClick={(e) => {
                            e.stopPropagation();
                            new MixPanel().track("Workspace", {
                              workspace_action: "workspace_delete",
                              paid: false,
                            });
                            new MixPanel().increment("workspace_delete");
                            if (
                              workspace?.workSpaceId ===
                              getWorkSpaceDetails().currentWorkSpaceId
                            ) {
                              return toast.error(
                                "Cannot delete Active WorkSpace"
                              );
                            }
                            setDeleteWorkSpace((prev) => ({
                              ...prev,
                              show: true,
                              workSpace: workspace,
                            }));
                          }}
                        >
                          <MdDelete size={18} />
                        </button>
                      )}
                    </div>
                  </div>
                </span>
              </div>
            </div>
          );
        })}
      </div>
      {!whiteLabelHelper?.isWhiteLabel && (
        <div className="flex justify-end gap-4 mt-6">
          {isDownGrade ? (
            <BoloButton
              disabled={false}
              size="sm"
              // className="text-xs md:text-sm text-white bg-primary px-4 py-2 rounded-md"
              onClick={() => {
                logOut();
              }}
            >
              Logout
            </BoloButton>
          ) : (
            <BoloButton
              disabled={false}
              size="sm"
              // className="text-xs md:text-sm text-white bg-primary px-4 py-2 rounded"
              onClick={() => {
                new MixPanel().track("Workspace", {
                  workspace_action: "workspace_add",
                  paid: true,
                });
                new MixPanel().increment("workspace_add");
                const ownerPlanDetails = getOwnerPlanDetails();
                if (!owner?.stripeCustomerId) {
                  setUpgradeModalType("WORKSPACE_NO_STRIPE");
                  setUpgradeModalData((prev) => ({
                    ...prev,
                    show: true,
                    data: {
                      // title: "Buy additional WorkSpace from Billings page",
                      // desc: `You are on ${currentPlan} upgrade to use this feature.`,
                      // redirectText: "Go To Billings",
                      // redirect: `${getDashboardUrl()}/billings`,
                    },
                  }));
                  return;
                }
                if (
                  !(
                    ownerPlanDetails?.tier?.includes("TIER_3") ||
                    ownerPlanDetails?.tier?.includes("APP_SUMO_ENTERPRISE_TIER")
                  )
                ) {
                  setUpgradeModalType("WORKSPACE");
                  setUpgradeModalData((prev) => ({
                    ...prev,
                    show: true,
                    data: {
                      // title:
                      //   "WorkSpaces are available in Growing Business Plan and above",
                      // desc: `You are on ${currentPlan} upgrade to use this feature.`,
                    },
                  }));
                  return;
                }
                if (!isWorkSpaceEnbaled) {
                  setUpgradeModalType("WORKSPACE");
                  setUpgradeModalData((prev) => ({
                    ...prev,
                    show: true,
                    data: {
                      // title:
                      //   "WorkSpaces are available in Growing Business Plan and above",
                      // desc: `You are on ${currentPlan} upgrade to use this feature.`,
                    },
                  }));
                  return;
                }
                setCreateWorkSpace((prev) => ({
                  ...prev,
                  show: true,
                  create: true,
                  rename: false,
                }));
              }}
            >
              Create New
            </BoloButton>
          )}
        </div>
      )}
    </>
    // </div>
    //   </div>
    // </div>
  );
};

export const SwitchWorkSpaces = ({ setShow }) => {
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[800px] relative bg-white rounded px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-0 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />{" "}
          <div className="text-base md:text-lg mb-4 font-semibold">
            Switch Workspace
          </div>
          <SwitchWorkSpacesComponent setShow={setShow} isModal={true} />
        </div>
      </div>
    </div>
  );
};

export default SwitchWorkSpaces;
