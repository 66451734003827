import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

const input = cva(
  "border-b-2 outline-none disabled:opacity-50 hover:opacity-80 focus:border-b-2",
  {
    variants: {
      variant: {
        primary: "focus:border-primary",
        secondary: "focus:border-primary",
        tertiary: "focus:border-primary",
        danger: "focus:border-red-600",
        "bolo-primary": "focus:border-primary-bolo ",
        // can have here branded variants
      },
      bolosize: {
        xs: "py-0.5 text-xs",
        sm: "py-1 text-sm",
        md: "py-1.5 text-base",
        lg: "py-2 text-lg",
        xl: "py-2.5 text-xl ",
      },
    },
    // compoundVariants: [{ intent: "primary", size: "md", class: "uppercase" }],
    defaultVariants: {
      variant: "primary",
      bolosize: "md",
    },
  }
);

export interface BoloInputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof input> {}

export const BoloInput: React.FC<BoloInputProps> = ({
  className,
  variant,
  bolosize,
  ...props
}) => <input className={input({ variant, className, bolosize })} {...props} />;

const textareaInput = cva(
  "border-b-2 outline-none disabled:opacity-50 hover:opacity-80 focus:border-b-2",
  {
    variants: {
      variant: {
        primary: "focus:border-primary",
        secondary: "focus:border-primary",
        tertiary: "focus:border-primary",
        danger: "focus:border-red-600",
        "bolo-primary": "focus:border-primary-bolo",
        // can have here branded variants
      },
      bolosize: {
        xs: "py-0.5 text-xs",
        sm: "py-1 text-sm",
        md: "py-1.5 text-base",
        lg: "py-2 text-lg",
        xl: "py-2.5 text-xl ",
      },
    },
    defaultVariants: {
      variant: "primary",
      bolosize: "md",
    },
  }
);

export interface BoloTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof textareaInput> {}

export const BoloTextarea: React.FC<BoloTextareaProps> = ({
  className,
  variant,
  bolosize,
  ...props
}) => (
  <textarea
    className={textareaInput({ variant, className, bolosize })}
    {...props}
  />
);
