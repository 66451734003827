import React, { useEffect } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import routes from "../../routes";
import { fetchOwner } from "../../utils";

const AppContent = () => {
  const [searchParams] = useSearchParams();
  const refCode = searchParams.get("ref");
  const refReward = localStorage.getItem("refReward");

  if (refCode && !refReward) {
    localStorage.setItem("ref", refCode);
  }

  return (
    // <div className="">
    // {" "}
    <Routes>
      {routes.map((route, idx) => {
        return (
          route.element && (
            <Route key={idx} path={route.path} element={<route.element />} />
          )
        );
      })}
      {localStorage.getItem("owner") ? (
        <Route
          path="/"
          element={<Navigate to={"/dashboard/forms"} replace />}
        />
      ) : (
        <Route path="/" element={<Navigate to="/login" replace />} />
      )}
    </Routes>
    // </div>
  );
};

export default AppContent;
