import React, { useContext } from "react";
// import { SchemaForUI } from '@pdfme/common';
import { I18nContext } from "../../../../contexts";
import Divider from "../../../Divider";
import { SidebarProps } from "../index";
import TextPropEditor from "./TextPropEditor";
import ExampleInputEditor from "./ExampleInputEditor";
import PositionAndSizeEditor from "./PositionAndSizeEditor";
import TypeAndKeyEditor from "./TypeAndKeyEditor";
import { SchemaForUI } from "../../../../../../common/src";
import CloneField from "./CloneField";
import { IoArrowBackCircle, IoCaretBackSharp } from "react-icons/io5";
const DetailView = (
  props: Pick<
    SidebarProps,
    | "schemas"
    | "pageSize"
    | "changeSchemas"
    | "activeElements"
    | "pageNum"
    | "pageCursor"
    | "schemasList"
    | "addSchema"
    | "setSchemasList"
  > & {
    activeSchema: SchemaForUI;
    onEditEnd: any;
  }
) => {
  const { activeSchema, onEditEnd } = props;
  const i18n = useContext(I18nContext);

  return (
    <div className="">
      <div className="bg-secondary flex gap-2 items-center -m-4 p-4">
        <span className="h-7 w-7 rounded-[3px] flex items-center justify-center cursor-pointer">
          <IoArrowBackCircle
            className="text-2xl inline-block cursor-pointer text-primary"
            onClick={() => {
              onEditEnd();
            }}
          />
        </span>

        <span className="ml-2 w-full font-medium text-primary text-sm">
          {i18n("editField")}
        </span>
      </div>
      {/* <Divider /> */}
      <div style={{ fontSize: "0.9rem" }} className="pt-4">
        {/* <TypeAndKeyEditor {...props} /> */}
        {/* <Divider /> */}
        <PositionAndSizeEditor {...props} />
        <Divider />
        {activeSchema.type === "text" && (
          <>
            <TextPropEditor {...props} />
            {/* <Divider /> */}
          </>
        )}

        <CloneField {...props} />
        {/* <Divider /> */}
        {/* <ExampleInputEditor {...props} /> */}
      </div>
    </div>
  );
};

export default DetailView;
