import { useState } from "react";
import axios from "../../../utils/axios";
import { toast } from "react-toastify";
import { defaultContactFields } from "../../../constants/common";
import useIsFeatureEnabled from "../../../hooks/reduxHooks/useIsFeatureEnabled";

type FormState = {
  field_name: string;
  type: string;
};

const AddFieldForm = ({
  fetchFields,
  setUpgradeModalType,
  setShowUpgradeModal,
}: any) => {
  const featuresEnabled = useIsFeatureEnabled();
  const initialState: FormState = {
    field_name: "",
    type: "text",
  };
  const [form, setForm] = useState<FormState>(initialState);
  const inputStyles =
    "py-2 w-full h-9 border px-3 rounded-md border-gray-300 focus:outline-none text-gray-700 focus:border-b focus:border-primary placeholder:font-light placeholder:text-gray-500 placeholder:text-xs text-xs";
  const labelStyles =
    "block text-[12px] text-gray-900 uppercase font-semibold mt-1 mb-1";

  const addField = async () => {
    if (!featuresEnabled?.CUSTOM_FIELDS) {
      setUpgradeModalType("CUSTOM_FIELDS");
      setShowUpgradeModal(true);
      return;
    }
    try {
      // Converting user entered text to key
      const formattedValue = form.field_name
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters except spaces
        .replace(/\s+/g, "_") // Replace spaces with underscores
        .toLowerCase();
      // Check for field name cannot be in default contact fields
      const isPresent = defaultContactFields.some(
        (field) => field.field_name.toLowerCase() === formattedValue
      );
      // Field name cannot be email or name as these are already used
      const isInvalidValue = ["name", "email"].includes(formattedValue);
      if (isPresent || isInvalidValue) {
        toast.error("Field exists as default contact field");
        return;
      }
      form.field_name = formattedValue;

      const response = await axios.post("/workspace/addCustomField", form);
      if (response?.data) {
        const { message, success } = response?.data;
        //In case field added successfully
        if (success) {
          toast.success(message);
          fetchFields();
        } else {
          // field already exists
          toast.error(message);
        }
        setForm(initialState);
      }
    } catch (error) {
      console.error("ERROR: ", error);
      toast.error("Error in adding field");
    }
  };

  return (
    <div className="w-full border-b border-gray-200 pt-1 pb-4 z-0">
      <h2 className=" text-sm md:text-[18px] font-medium ">Add New Field</h2>
      <p className="text-[10px] md:text-xs leading-4 text-gray-600 mb-1">
        Each field must have a unique name; duplicate field names are not
        allowed.
      </p>
      <p className="text-[10px] md:text-xs  leading-4 text-orange-500 mb-3">
        Warning: Once a custom field is added, it cannot be edited or deleted.
      </p>
      <div className="flex justify-start items-end gap-1 flex-wrap">
        <div className="md:w-auto w-full md:flex-1">
          <label htmlFor="fieldName" className={labelStyles}>
            Field Name *
          </label>
          <input
            type="text"
            spellCheck={false}
            placeholder="Field name..."
            id="fieldName"
            className={inputStyles}
            value={form.field_name}
            onChange={(e) => {
              const value = e.target.value;
              setForm({ ...form, field_name: value });
            }}
          />
        </div>
        <div className=" md:w-40 md:flex-none flex-1">
          <label htmlFor="selectType" className={labelStyles}>
            Select Type
          </label>
          <select
            className={inputStyles}
            id="selectType"
            value={form.type}
            onChange={(e) => {
              const value = e.target.value;
              setForm({ ...form, type: value });
            }}
          >
            <option value={"text"}>Text</option>
            <option value={"number"}>Number</option>
          </select>
        </div>

        <button
          className=" bg-primary text-white px-4 rounded-md text-xs h-9 z-0"
          disabled={form.field_name.length < 1}
          onClick={addField}
        >
          Add Field
        </button>
      </div>
    </div>
  );
};

export default AddFieldForm;
