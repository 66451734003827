import { useContext, useState } from "react";
// import { SchemaForUI } from '@pdfme/common';
import { IoArrowBackCircle, IoCaretBackSharp } from "react-icons/io5";
import { SchemaForUI } from "../../../../../../common/src";
import { I18nContext } from "../../../../contexts";
import Divider from "../../../Divider";
import { SidebarProps } from "../index";
import CloneField from "./CloneField";
import ExampleInputEditor from "./ExampleInputEditor";
import MakeFieldRequired from "./MakeRequired";
import PositionAndSizeEditor from "./PositionAndSizeEditor";
import TextPropEditor from "./TextPropEditor";
import DropDownOptions from "./DropDownOptions";
import DataEditor from "./DataEditor";
import LinkFields from "./LinkFields";

export const DetailViewContent = (
  props: SidebarProps & {
    schemasList: SchemaForUI[][];
    setSchemasList: (schemasList: SchemaForUI[][]) => void;
    activeSchema: SchemaForUI;
    onEditEnd: any;
    isNew?: boolean;
  }
) => {
  const { changeSchemas } = props;
  const [dateFormat, setDateFormat] = useState(
    props?.activeSchema?.ifDateFormat || "mm/dd/yyyy"
  ); // Set default date format
  const { activeSchema } = props;

  return (
    <div
      style={{ fontSize: "0.9rem" }}
      className={`${props.isNew ? "py-0" : "py-2"}`}
    >
      {/* <TypeAndKeyEditor {...props} /> */}
      {/* <Divider /> */}
      {(activeSchema.subType === "text" ||
        activeSchema.subType === "date" ||
        activeSchema.subType === "number" ||
        activeSchema.subType === "file_upload" ||
        activeSchema.subType === "dropdown") &&
        !activeSchema.isStatic && (
          <>
            <DataEditor {...props} />
            <Divider />
          </>
        )}
      <PositionAndSizeEditor {...props} />
      <Divider />
      {(activeSchema.type === "text" ||
        (activeSchema.type == "table" && activeSchema?.subType == "table")) && (
        <>
          <TextPropEditor {...props} />
          <Divider />
        </>
      )}
      {activeSchema.subType === "dropdown" && (
        <>
          <DropDownOptions
            activeSchema={activeSchema}
            dropdownOptions={activeSchema.dropdownOptions}
            changeSchemas={changeSchemas}
          />
          <Divider />
        </>
      )}
      {!(activeSchema?.isStatic || activeSchema?.subType == "variable") && (
        <>
          <MakeFieldRequired {...props} />
          <Divider />
        </>
      )}
      {activeSchema.subType === "date" && (
        <>
          <div className="my-2 mt-4 flex items-center gap-4">
            <label
              htmlFor="dateFormat"
              className="block text-xs text-gray-700 mb-1 w-[50%]"
            >
              Select Date Format
            </label>
            <select
              id="dateFormat"
              name="dateFormat"
              // className="mt-1 block w-full pl-3 pr-10 py-1 focus:outline-none sm:text-sm rounded-[3px] shadow border border-gray-200 text-sm"
              className="w-[50%] border-2 border-primary/90 rounded-[3px] px-1 py-[2px] bg-white text-xs"
              value={dateFormat}
              onChange={(e) => {
                const selectedFormat = e.target.value;
                setDateFormat(selectedFormat);
                changeSchemas([
                  {
                    key: "ifDateFormat",
                    value: e.target.value,
                    schemaId: activeSchema.id,
                  },
                ]);

                // changeSchemas([{ key: 'data', value: e.target.value, schemaId: activeSchema.id }]);
              }}
            >
              <option value="mm/dd/yyyy">mm/dd/yyyy</option>
              <option value="dd/mm/yyyy">dd/mm/yyyy</option>
              <option value="yyyy/mm/dd">yyyy/mm/dd</option>
              {/* Add more date formats as needed */}
            </select>
          </div>
          <Divider />
        </>
      )}
      {!activeSchema.isStatic &&
        activeSchema.type == "text" &&
        !(activeSchema.subType == "email") &&
        !(activeSchema.subType == "name") &&
        !(activeSchema.subType == "contact_field") &&
        !(activeSchema.subType == "custom_field") && (
          <>
            <LinkFields {...props} />
            <Divider />
          </>
        )}
      {/* <Divider /> */}
      <CloneField {...props} />
      <Divider />
      {/* Dropdown for selecting date formats */}

      {/* <ExampleInputEditor {...props} /> */}
    </div>
  );
};

const DetailView = (
  props: SidebarProps & {
    schemasList: SchemaForUI[][];
    setSchemasList: (schemasList: SchemaForUI[][]) => void;
    activeSchema: SchemaForUI | undefined;
    onEditEnd: any;
  }
) => {
  // const { changeSchemas } = props;
  // const [dateFormat, setDateFormat] = useState(
  //   props?.activeSchema?.ifDateFormat || "mm/dd/yyyy"
  // ); // Set default date format
  const { onEditEnd } = props;
  const i18n = useContext(I18nContext);
  const activeSchema_ = props.activeSchema;

  return (
    <div className="mt-4  h-[100%] max-h-[100%]">
      <div className="bg-secondary flex gap-2 items-center -m-4 p-4">
        <span className="h-7 w-7 rounded-[3px] flex items-center justify-center cursor-pointer">
          <IoArrowBackCircle
            className="text-2xl inline-block cursor-pointer text-primary"
            onClick={() => {
              onEditEnd();
            }}
          />
        </span>

        <span className="ml-2 w-full font-medium text-primary text-sm">
          {i18n("editField")}
        </span>
      </div>

      <Divider />
      {activeSchema_ !== undefined ? (
        <DetailViewContent {...props} activeSchema={activeSchema_} />
      ) : (
        <div className="text-sm py-4">
          <p>No single active element</p>
          <p>Please select only one schema</p>
        </div>
      )}
      {/* <div style={{ fontSize: "0.9rem" }} className="py-2">
        <PositionAndSizeEditor {...props} />
        <Divider />
        {activeSchema.type === "text" && (
          <>
            <TextPropEditor {...props} />
            <Divider />
          </>
        )}
        {activeSchema.subType === "dropdown" && (
          <>
            <DropDownOptions
              activeSchema={activeSchema}
              dropdownOptions={activeSchema.dropdownOptions}
              changeSchemas={changeSchemas}
            />
            <Divider />
          </>
        )}
        <MakeFieldRequired {...props} />
        {activeSchema.subType === "date" && (
          <div className="my-2 mt-4 flex items-center gap-4">
            <label
              htmlFor="dateFormat"
              className="block text-xs text-gray-700 mb-1 w-[50%]"
            >
              Select Date Format
            </label>
            <select
              id="dateFormat"
              name="dateFormat"
              // className="mt-1 block w-full pl-3 pr-10 py-1 focus:outline-none sm:text-sm rounded-[3px] shadow border border-gray-200 text-sm"
              className="w-[50%] border-2 border-primary/90 rounded-[3px] px-1 py-[2px] bg-white text-xs"
              value={dateFormat}
              onChange={(e) => {
                const selectedFormat = e.target.value;
                setDateFormat(selectedFormat);
                changeSchemas([
                  {
                    key: "ifDateFormat",
                    value: e.target.value,
                    schemaId: activeSchema.id,
                  },
                ]);

                // changeSchemas([{ key: 'data', value: e.target.value, schemaId: activeSchema.id }]);
              }}
            >
              <option value="mm/dd/yyyy">mm/dd/yyyy</option>
              <option value="dd/mm/yyyy">dd/mm/yyyy</option>
              <option value="yyyy/mm/dd">yyyy/mm/dd</option>
            </select>
          </div>
        )}

        <Divider />
        <CloneField {...props} />
        <Divider />
      </div> */}
    </div>
  );
};

export default DetailView;
