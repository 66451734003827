// @ts-nocheck

import React from "react";
import { Tooltip } from "react-tooltip";
import "./index.css";

const CustomTooltip = ({ helpers }) => {
  let { id, content, place } = helpers;

  return (
    <div className="customTooltipWrapper">
      <Tooltip
        id={id}
        content={content}
        place={place}
        noArrow={true}
        className="customTooltip"
      />
    </div>
  );
};

export default CustomTooltip;
