//@ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { COLORS } from "../../constants/common";

const DateRangeFilter = ({
  dateRangeFilter,
  setDateRangeFilter,
  setSearchDateFilter,
  right,
}) => {
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    console.log("selection", selection);
    let dateRange = {
      start: new Date(selection.startDate).toISOString(),
      end: new Date(selection.endDate).toISOString(),
    };
    console.log("dateRange", dateRange);
    setSearchDateFilter(dateRange);
    setDateRangeFilter([selection]);
  };

  return (
    <div
      className={`mt-[44px] absolute top-0 ${
        right ? "right-0" : "left-0"
      } z-[10] shadow-lg rounded-md overflow-hidden border`}
    >
      <DateRangePicker
        onChange={handleOnChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={dateRangeFilter}
        direction="horizontal"
        rangeColors={[COLORS.primary]}
      />
    </div>
  );
};

DateRangeFilter.propTypes = {
  onChange: PropTypes.func,
};

export default DateRangeFilter;
