import { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import "./react-tags.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { validateEmail } from "../../utils/validateEmail";
import PleaseUpgradeModal from "../PleaseUpgradeModal";
import { isFeatureEnabled } from "../../utils/paidFeat";
import CreatableSelect from "react-select/creatable";
import EmailMultiSelect from "./EmailMultiSelect";

const ReceivesASignedCopy = ({
  emailTags,
  setEmailTags,
  owner,
  className = "mx-auto",
}: any) => {
  const [needPaidPlanError, setNeedPaidPlanError] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;

  const getPaymentStatus = async () => {
    const isSendACopyEnabled = await isFeatureEnabled("SEND_A_COPY");
    if (!isSendACopyEnabled) {
      setShowUpgradeModal(true);
    }

    return isSendACopyEnabled;
  };

  const handleChange = async (newTag: any) => {
    // let isFreePlan =
    //   owner?.planhistory[owner?.planhistory.length - 1]?.planId === "FREE_PLAN";

    // if (isFreePlan && newTag?.length > 0) {
    //   setNeedPaidPlanError(true);
    //   return;
    // }

    const isEnabled = await getPaymentStatus();
    if (isEnabled) {
      setEmailTags(newTag);
    }
  };

  const [workSpaceContacts, setWorkSpaceContacts] = useState([]);
  // Get contacts list of the workspace from localstorage
  useEffect(() => {
    const owner = JSON.parse(localStorage.getItem("owner") || "");
    const contacts = owner?.contacts;
    setWorkSpaceContacts(contacts);
  }, []);

  const updateEmailValues = (newValues: any) => {
    setEmailTags(newValues);
  };

  return (
    <div
      className={`${className} py-2 md:py-4 w-[100%] md:w-[65%] max-w-[800px] mb-4`}
    >
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          // data={{
          //   title: "Send a Copy is available in Freelancer Plan and above ",
          //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
          // }}
          data={{}}
          blockingType={"SEND_A_COPY"}
        />
      )}
      <div
        className="cursor-pointer flex flex-col justify-between items-start gap-2"
        // onClick={handleTextClick}
      >
        <h2 className="text-xs md:text-sm mb-1 font-medium">
          Send copy of signed document(s) to
        </h2>
        <div className="flex justify-between gap-2 w-full">
          {/* It can select multiple emails from the contacts lists and can add new email to it */}
          <EmailMultiSelect
            selectedValues={emailTags}
            updateEmailValues={updateEmailValues}
          />
        </div>
      </div>

      {/* {needPaidPlanError && (
        <PleaseUpgradeModal
          setShow={setNeedPaidPlanError}
          show={needPaidPlanError}
          data={{
            title: "Sending a copy to receivers is a Premium feature!",
            desc: "You need to upgrade your plan to send copies of signed documents",
          }}
        />
      )} */}
    </div>
  );
};

export default ReceivesASignedCopy;
