import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../../components/Common/BoloCarousel/BoloCarousel";
import DataPrivacyListAndLinks from "../../components/DataPrivacyListAndLinks";
import Reviews from "../../components/Reviews";
import Autoplay from "embla-carousel-autoplay";
import CustomerReviews from "./CustomerReviews";
import { Link } from "react-router-dom";
import { FaRegStarHalf, FaStar, FaStarHalf } from "react-icons/fa";
import { useAppSelector } from "../../redux/store";
import useWhiteLabelHelper from "../../hooks/reduxHooks/useWhiteLabelHelper";

const securityCertifications = [
  {
    name: "ISO 27001:2022",
    image:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/v2/security/iso.png",
  },
  {
    name: "SOC 2",
    image:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/v2/security/soc1.png",
  },
  {
    name: "GDPR",
    image:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/v2/security/gdpr.png",
  },
  {
    name: "eIDAS",
    image:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/v2/security/eidas.png",
  },
  {
    name: "ESIGN act",
    image:
      "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/assets/v2/security/esign.png",
  },
];

const Certifications = ({
  certifications,
}: {
  certifications: { name: string; image: string }[];
}) => {
  return (
    <div className={`flex flex-wrap justify-center gap-4`}>
      {certifications.map((certification, index) => (
        <Link
          to={`https://security.boloforms.com/`}
          target="_blank"
          key={index}
          className="flex flex-col gap-2 justify-end items-center flex-wrap"
          title={certification.name}
        >
          <img
            src={certification.image}
            className="h-8 xl:h-10 2xl:h-12 w-auto rounded-full"
            width={15}
            height={15}
            loading="lazy"
            alt={certification.name}
          />
        </Link>
      ))}
    </div>
  );
};

const customerReviews = [
  {
    image:
      "https://cdn0.capterra-static.com/profile-images/linkedin/1faa16f4f199c7ee2352a70adfa03fd7.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Graham L.",
    stars: 5,
    title: "Finally a simple to use yet full featured PDF signature app!",
    content:
      "Ive tried so many apps over the years, they either dont handle PDF's which mean clause numbering get messed up when pasting in long contracts or they are ridiculously over priced. Legal signature collection really shouldnt be that hard and finally I'm happy to have found a decent solution for my entire organisation",
  },
  {
    image:
      "https://reviews.capterra.com/cdn/profile-images/linkedin/e46a105735199119c232dda21bb21f5940eac735b6d16aea85ae22a0efa01118.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Sam K.",
    stars: 5,
    title: "Great alternative to Docusign",
    content:
      "It's cheap and it just works! I had to do mass signatures of our team members and Boloforms made it easy for us to get signatures from our team faster.",
  },
  {
    image:
      "https://reviews.capterra.com/cdn/profile-images/linkedin/a5edcd125d34505458c7b981265afc5a20b66c6b378c40d101406351f123e025.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Thomas H.",
    stars: 5,
    title: "Love this product",
    content:
      "The features and ease of use. Made getting digital contracts a breeze for my rental units and made me look like a high-tech ma & pa landlord by having that feature for leasees",
  },
  {
    image:
      "https://cdn0.capterra-static.com/profile-images/linkedin/3912060389b7e15725bd261949747205.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Ali K.",
    stars: 5,
    title: "Best competitor DocuSign",
    content: "More features are available in boloforms out of the box.",
  },
  {
    image:
      "https://reviews.capterra.com/cdn/profile-images/linkedin/acdaa6afc2ddeaaf87d582b1fc740bf9f5a9e9dc954ebe169b179b28a08e7a6c.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Babar Bilal B.",
    stars: 5,
    title: "Great product",
    content: " Document sending feature is truly great as of google form",
  },
  {
    image:
      "https://reviews.capterra.com/cdn/profile-images/linkedin/46f3f197a85380cd567f30e1102e092dd2eb17a04fd5b1aa9c240c9299063267.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Supreet S.",
    stars: 5,
    title: "BoloForms has it all!",
    content:
      "It's easy to use, has all the features I want in a document signing software. Folders, pdfs, order signing, payments. New company and price was affordable. The customer service is great as well.",
  },
  {
    image:
      "https://reviews.capterra.com/cdn/profile-images/linkedin/b82a019d9f28009d0b8c579469959e12b0b65ba32e084094f2d00773faf91692.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "David L.",
    stars: 5,
    title: "Nice for the price",
    content: "It fills out pdfs and no other software does that",
  },
  {
    image:
      "https://reviews.capterra.com/cdn/profile-images/linkedin/d9e222693148a8cebff102e46cd06ff4eccaa0be0ffc4f655c86e62711fa20fb.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Lisa S.",
    stars: 5,
    title:
      "Excellent replacement for other more expensive document signing solutions",
    content: "Ease of use, functionality. Doesn't try to do too many things.",
  },
  {
    image:
      "https://reviews.capterra.com/cdn/profile-images/linkedin/f8921ff997633469fbf6e063e9be7455575f40180ad39d285edb4fa5d5ac1f2c.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Etienne G.",
    stars: 5,
    title: "Boloforms is a very good option for esignatures",
    content: "I like very much the simplicity of use of Boloforms.",
  },
  {
    image:
      "https://reviews.capterra.com/cdn/profile-images/linkedin/0b6d6a24123b176e4eb691e87e5344492de29d416f146a93858d2d7e99c424c1.jpeg?auto=format%2Ccompress&fit=max&w=96&q=75&ch=Width%2CDPR",
    name: "Rafel L.",
    stars: 5,
    title: "BoloForms",
    content:
      "Easy to use and understand. Finally, one solution, practical and simple.",
  },
];

const LoginReviews = () => {
  const plugin = React.useRef(
    Autoplay({ delay: 2000, stopOnInteraction: true })
  );
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const whiteLabelHelper = useWhiteLabelHelper();

  const [parsedReviews, setParsedReviews] = useState(customerReviews);
  useEffect(() => {
    let reviews = whiteLabelHelper?.isWhiteLabel
      ? whiteLabelInfo?.settings?.organization?.reviews || []
      : customerReviews;
    console.log({
      reviews,
      isW: whiteLabelHelper.isWhiteLabel,
      wR: whiteLabelInfo?.settings?.organization?.reviews,
    });
    setParsedReviews(reviews);
  }, [whiteLabelInfo, whiteLabelHelper]);

  return (
    <div
      className="hidden pb-4 pt-4 xl:-pt-10 2xl:pt-20 2xl:pb-8 lg:flex h-full w-full flex-col justify-between items-center relative z-10 overflow-hidden bg-primary text-white "
      // style={{
      //   background:
      //     "radial-gradient(circle, hsl(var(--primary)) 0%, #ffffff 50%)",
      // }}
    >
      <div className="w-[90%] mb-4">
        <h2 className="text-[28px] mb-2 font-bold text-center">
          The eSignature Platform for Small Businesses
        </h2>
        <p className="text-sm  mb-4 text-center">
          Unlimited signatures, templates, forms, and team members. One fixed
          price. No extra charges, ever.
        </p>
      </div>

      {/* <div className="flex flex-row 2xl:flex-col justify-between items-center mb-3">
        <div className="h-8 2xl:h-10">
          <img
            src={
              "https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/website/ltd/capterra.svg"
            }
            className="w-full h-full"
            width={50}
            height={50}
            alt="review site logo"
          />
        </div>

        <div className="bg-white mt-0 2xl:mt-2 flex gap-1 items-center ml-2 border py-1 px-2 rounded-full text-xs font-sans shadow-sm">
          {[1, 2, 3, 4, 5].map((star, index) => (
            <div key={index} className="">
              {4.9 >= star ? (
                <FaStar className="text-yellow-400" />
              ) : 4.9 >= star - 0.5 ? (
                <FaStarHalf className="text-yellow-400" />
              ) : (
                <FaRegStarHalf className="text-yellow-400" />
              )}
            </div>
          ))}
          <div className="">
            <span className="text-gray-400">4.9/5</span>
          </div>
        </div>
      </div> */}

      <div className="max-w-[50vw] mb-6">
        <CustomerReviews
          className="scroll-left"
          customerReviews={parsedReviews.slice(0, 5)}
        />
        <CustomerReviews
          className="scroll-right"
          customerReviews={parsedReviews.slice(5, 10)}
        />
      </div>
      <Certifications certifications={securityCertifications} />
      {/* <Reviews /> */}
      <DataPrivacyListAndLinks />
    </div>
  );
};

export default LoginReviews;
