import { toast } from "react-toastify";
import { getIpAddressLocal } from "../../../hooks/reduxHooks/useIpHook";
import axios from "../../../utils/axios";
import { SchemaForUI } from "../../common/src";
import { DesignerState } from "./components/Designer";
import { DEFAULT_DATA, STANDARD_DATA } from "./constants";
import { fmtTemplate } from "./helper";
import dayjs from "dayjs";

export const isEditorSchema = ({
  schema,
  receiversList,
  instanceFrom,
}: {
  schema: SchemaForUI;
  receiversList: any[];
  instanceFrom: "PDF" | "PDF-TEMPLATE" | "FORM";
}) => {
  if (instanceFrom !== "PDF") {
    return false;
  }
  let respondentId = schema?.id?.split("_")?.[1];
  let owner = JSON.parse(localStorage.getItem("owner") || "{}");
  let isEditorSchema = false;
  for (let respondent of receiversList) {
    if (respondent?._id === respondentId) {
      if (owner?.email?.toLowerCase() === respondent?.email?.toLowerCase()) {
        isEditorSchema = true;
        break;
      }
    }
  }
  return isEditorSchema;
};
const checkNotFilled = (
  schema: SchemaForUI,
  radioGroupChecker: any,
  allRadioGroups: Set<unknown>
) => {
  console.log("Schema", schema.subType, "", schema.data);
  switch (schema.subType) {
    case "initials":
      return (
        schema.data === "" ||
        schema.data === DEFAULT_DATA.initials.defaultAWSLink
      );
    case "stamp":
      return (
        schema.data === "" || schema.data === DEFAULT_DATA.stamp.defaultLink
      );
    case "dropdown":
      return schema.data === "";
    case "number":
      return schema.data === "" || schema.data === "Number";
    case "date":
      return schema.data === "" || schema.data === "Date";
    case "signature":
      return (
        schema.data === "https://i.imgur.com/AfyPZQg.png" ||
        schema.data === "" ||
        schema.data === STANDARD_DATA.signature.unsigned_DBKEYV1.key
      );
    case "text":
      return schema.data === "" || schema.data === "Text";
    case "radiogroup":
      if (
        schema.data == STANDARD_DATA.radiogroup.checked_DBKEYV1.key &&
        schema.radiogroupId
      ) {
        radioGroupChecker[schema?.radiogroupId] = true;
      }
      allRadioGroups.add(schema.radiogroupId);
      return false;
    case "file_upload":
      return schema.data === "";
    default:
      return false;
  }
};
const giveRequiredFieldsNotFilled = (designerState: DesignerState) => {
  console.log("designerState: ", designerState);
  const schemas = designerState?.schemasList?.flat();
  // console.log("schema", schemas);
  let index = 0;
  let radioGroupChecker: any = {};
  let allRadioGroups = new Set();
  let requiredFieldsNotFilledKeys: any[] = [];
  let responseInputs: any = {};
  for (let schema of schemas) {
    let notFilledSignaturesFields = false;
    responseInputs[schema.key] = schema.data;
    if (schema.subType === "name" || schema.subType === "email") {
      let receiver = designerState?.receiversList?.[0];
      responseInputs[schema.key] = receiver[schema.subType];
    }
    if (schema?.subType == "date" && schema?.isStatic) {
      responseInputs[schema.key] = dayjs(new Date()).format(
        schema?.ifDateFormat?.toUpperCase() || "MM/DD/YYYY"
      );
    }
    if (schema?.required && schema?.subType !== "checkbox") {
      notFilledSignaturesFields = checkNotFilled(
        schema,
        radioGroupChecker,
        allRadioGroups
      );
      console.log("checkNotfilled", schema.subType, notFilledSignaturesFields);
      if (notFilledSignaturesFields) {
        requiredFieldsNotFilledKeys.push(schema?.id);
      }
      if (notFilledSignaturesFields) console.log("Schema: ", ++index, schema);
    }
  }
  allRadioGroups.forEach((radGrpId: any) => {
    if (!radioGroupChecker[radGrpId]) {
      requiredFieldsNotFilledKeys.push(radGrpId);
    }
  });
  console.log({
    responseInputs,
    requiredFieldsNotFilledKeys,
  });
  return {
    responseInputs,
    requiredFieldsNotFilledKeys,
  };
};
const saveInputs = async ({
  designerState,
  setRequiredFieldsNotFilled,
  savePDFInEmailDraft = false,
}: {
  designerState: DesignerState;
  setRequiredFieldsNotFilled: React.Dispatch<React.SetStateAction<any[]>>;
  savePDFInEmailDraft?: boolean;
}) => {
  if (designerState?.instanceFrom !== "PDF") {
    return;
  }
  console.log("designerState: ", designerState);

  const modifiedTemplate = fmtTemplate(
    designerState?.template,
    designerState?.schemasList
  );
  const { responseInputs, requiredFieldsNotFilledKeys } =
    giveRequiredFieldsNotFilled(designerState);

  const signerId = designerState?.fetchedData?.document?.respondants?.[0]?._id;
  console.log("Signer Id: ", signerId);
  const response = getIpAddressLocal();
  const { ip } = await response;

  if (requiredFieldsNotFilledKeys?.length === 0) {
    setRequiredFieldsNotFilled([]);
    // Wait for 4 seconds

    try {
      const { data } = await axios.post(`/saveInputs`, {
        inputs: [responseInputs],
        documentId: designerState?.fetchedData?.document?.documentId,
        ip,
        signerId,
        isEditorOnlyReceiver: true,
        schemas: modifiedTemplate?.schemas,
        savePDFInEmailDraft, // only used while using browser extension
      });
      if (data.error) {
        return { error: data.error };
      }
      if (data.message === "Signature inputs saved successfully.") {
        return {
          message: "Signature saved successfully",
          documentId: designerState?.fetchedData?.document?.documentId,
        };
      }
    } catch (e) {
      console.error("Error saving inputs: ", e);
      return { error: "Error while saving inputs" };
    }
  } else {
    return {
      requiredFieldsNotFilledKeys,
      message: "Please fill all required fields!",
    };
  }
  console.log(`required fields not filled`, requiredFieldsNotFilledKeys);
};
export { saveInputs, giveRequiredFieldsNotFilled };
