// TODO Update pdfjs-dist. (might be able to reduce the bundle size.)
import _ from "lodash";
import hotkeys from "hotkeys-js";
import {
  getB64BasePdf,
  b64toUint8Array,
  Template,
  SchemaForUI,
  Schema,
  SchemaType,
  BarCodeType,
  Size,
  DEFAULT_ALIGNMENT,
  DEFAULT_FONT_SIZE,
  DEFAULT_CHARACTER_SPACING,
  DEFAULT_LINE_HEIGHT,
} from "../../common/src";
import { pdfjs } from "react-pdf";
import {
  ZOOM,
  RULER_HEIGHT,
  STANDARD_DATA,
  DEFAULT_DATA,
  POSITION,
  BLANK_PG_SCHEMAS,
} from "./constants";
// @ts-ignore
import PDFJSWorker from "pdfjs-dist/build/pdf.worker.entry.js";
import {
  getDocument,
  GlobalWorkerOptions,
} from "pdfjs-dist/legacy/build/pdf.js";
import { ChangeSchemasArg, CustomSchemaType } from "./components/Designer";
GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js`;
console.log("pdfjs.version", pdfjs.version);

export const uuid = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const set = <T extends object>(
  obj: T,
  path: string | string[],
  value: any
) => {
  path = Array.isArray(path)
    ? path
    : path.replace("[", ".").replace("]", "").split(".");
  let src: any = obj;
  path.forEach((key, index, array) => {
    if (index == path.length - 1) {
      src[key] = value;
    } else {
      if (!src.hasOwnProperty(key)) {
        const next = array[index + 1];
        src[key] = String(Number(next)) === next ? [] : {};
      }
      src = src[key];
    }
  });
};

export const debounce = <T extends Function>(cb: T, wait = 20) => {
  let h: null | ReturnType<typeof setTimeout> = null;
  const callable = (...args: any) => {
    if (h) clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  return <T>(<any>callable);
};

const shift = (number: number, precision: number, reverseShift: boolean) => {
  if (reverseShift) {
    precision = -precision;
  }
  const numArray = `${number}`.split("e");

  return Number(
    `${numArray[0]}e${
      numArray[1] ? Number(numArray[1]) + precision : precision
    }`
  );
};

export const round = (number: number, precision: number) => {
  return shift(Math.round(shift(number, precision, false)), precision, true);
};

export const cloneDeep = <T>(value: T): T => JSON.parse(JSON.stringify(value));

export const flatten = <T>(arr: T[][]): T[] => ([] as T[]).concat(...arr);

const up = "up";
const shiftUp = "shift+up";
const down = "down";
const shiftDown = "shift+down";
const left = "left";
const shiftLeft = "shift+left";
const right = "right";
const shiftRight = "shift+right";

const rmWin = "backspace";
const rmMac = "delete";
const esc = "esc";
const copyWin = "ctrl+c";
const copyMac = "command+c";
const pasteWin = "ctrl+v";
const pasteMac = "command+v";
const redoWin = "ctrl+y";
const redoMac = "shift+command+z";
const undoWin = "ctrl+z";
const undoMac = "command+z";
const saveWin = "ctrl+s";
const saveMac = "command+s";
const selectAllWin = "ctrl+a";
const selectAllMac = "command+a";

const keys = [
  up,
  shiftUp,
  down,
  shiftDown,
  left,
  shiftLeft,
  right,
  shiftRight,
  rmMac,
  rmWin,
  esc,
  copyWin,
  copyMac,
  pasteWin,
  pasteMac,
  redoWin,
  redoMac,
  undoWin,
  undoMac,
  saveWin,
  saveMac,
  selectAllWin,
  selectAllMac,
];

export const initShortCuts = (arg: {
  move: (command: "up" | "down" | "left" | "right", isShift: boolean) => void;
  remove: () => void;
  esc: () => void;
  copy: () => void;
  paste: () => void;
  redo: () => void;
  undo: () => void;
  save: () => void;
  selectAll: () => void;
}) => {
  hotkeys(keys.join(), (e, handler) => {
    switch (handler.shortcut) {
      case up:
      case shiftUp:
        e.preventDefault();
        arg.move("up", hotkeys.shift);
        break;
      case down:
      case shiftDown:
        e.preventDefault();
        arg.move("down", hotkeys.shift);
        break;
      case left:
      case shiftLeft:
        e.preventDefault();
        arg.move("left", hotkeys.shift);
        break;
      case right:
      case shiftRight:
        e.preventDefault();
        arg.move("right", hotkeys.shift);
        break;
      case rmWin:
      case rmMac:
        arg.remove();
        break;
      case esc:
        arg.esc();
        break;
      case copyWin:
      case copyMac:
        arg.copy();
        break;
      case pasteWin:
      case pasteMac:
        arg.paste();
        break;
      case redoWin:
      case redoMac:
        arg.redo();
        break;
      case undoWin:
      case undoMac:
        arg.undo();
        break;
      case saveWin:
      case saveMac:
        e.preventDefault();
        arg.save();
        break;
      case selectAllWin:
      case selectAllMac:
        e.preventDefault();
        arg.selectAll();
        break;
      default:
        break;
    }
  });
};

export const destroyShortCuts = () => {
  hotkeys.unbind(keys.join());
};

const readFile = (
  file: File | null,
  type: "text" | "dataURL" | "arrayBuffer"
) => {
  return new Promise<string | ArrayBuffer>((r) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", (e) => {
      if (e && e.target && e.target.result && file !== null) {
        r(e.target.result);
      }
    });
    if (file !== null) {
      if (type === "text") {
        fileReader.readAsText(file);
      } else if (type === "dataURL") {
        fileReader.readAsDataURL(file);
      } else if (type === "arrayBuffer") {
        fileReader.readAsArrayBuffer(file);
      }
    }
  });
};

export const readFiles = (
  files: FileList | null,
  type: "text" | "dataURL" | "arrayBuffer"
) => {
  return new Promise<string | ArrayBuffer>((r) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", (e) => {
      if (e && e.target && e.target.result && files !== null) {
        r(e.target.result);
      }
    });
    if (files !== null && files[0]) {
      readFile(files[0], type).then((data) => r(data));
    }
  });
};

const pt2mm = (pt: number) => {
  // https://www.ddc.co.jp/words/archives/20090701114500.html
  const mmRatio = 0.3527;

  return parseFloat(String(pt)) * mmRatio;
};

export const px2mm = (px: number) => {
  // http://www.unitconversion.org/typography/millimeters-to-pixels-y-conversion.html
  const mmRatio = 0.264583333;

  return parseFloat(String(px)) * mmRatio;
};

export const getPdfPageSizes = async (pdfBlob: Blob) => {
  const url = URL.createObjectURL(pdfBlob);
  const pdfDoc = await getDocument({ url }).promise;

  const promises = Promise.all(
    new Array(pdfDoc.numPages).fill("").map(async (_, i) => {
      const pageSize = await pdfDoc.getPage(i + 1).then((page) => {
        const { height, width } = page.getViewport({ scale: 1 });

        return { height: pt2mm(height), width: pt2mm(width) };
      });

      return pageSize;
    })
  );

  URL.revokeObjectURL(url);

  return promises;
};

const pdf2Images = async (
  pdfBlob: Blob,
  width: number,
  imageType: "png" | "jpeg"
) => {
  const url = URL.createObjectURL(pdfBlob);
  const pdfDoc = await getDocument({ url }).promise;

  const promises = Promise.all(
    new Array(pdfDoc.numPages).fill("").map(async (_, i) => {
      const image = await pdfDoc.getPage(i + 1).then((page) => {
        const canvas = document.createElement("canvas");
        canvas.width = width * 2;
        const canvasContext = canvas.getContext("2d")!;
        const scaleRequired =
          canvas.width / page.getViewport({ scale: 1 }).width;
        const viewport = page.getViewport({ scale: scaleRequired });
        canvas.height = viewport.height;

        return page
          .render({ canvasContext, viewport })
          .promise.then(() => canvas.toDataURL(`image/${imageType}`));
      });

      return image;
    })
  );
  URL.revokeObjectURL(url);

  return promises;
};

export const pdf2Pngs = (pdfBlob: Blob, width: number) =>
  pdf2Images(pdfBlob, width, "png");

export const b64toBlob = (base64: string) => {
  const uniy8Array = b64toUint8Array(base64);
  const [, , mimeType] = base64.match(/(:)([a-z/]+)(;)/)!;

  return new Blob([uniy8Array.buffer], { type: mimeType });
};

const sortSchemasList = (
  template: Template,
  pageNum: number
): SchemaForUI[][] =>
  new Array(pageNum).fill("").reduce((acc, _, i) => {
    acc.push(
      template.schemas[i]
        ? Object.entries(template.schemas[i])
            .sort((a, b) => {
              const aIndex = (template.columns ?? []).findIndex(
                (c) => c === a[0]
              );
              const bIndex = (template.columns ?? []).findIndex(
                (c) => c === b[0]
              );

              return aIndex > bIndex ? 1 : -1;
            })
            .map((e) => {
              const [key, value] = e;
              // console.log("key", key);
              // console.log("value", value);
              // console.log("template.sampledata", template.sampledata);
              // console.log("template.sampledata[0]", template.sampledata?.[0]);
              // console.log("template.sampledata[0][key]", template.sampledata?.[0][key]);
              // const data = template.sampledata
              //   ? template.sampledata[0][key]
              //   : "Sample Input";
              // const data = key;
              // @ts-ignore
              const data = value.data ?? key;

              return Object.assign(value, {
                key,
                data,
              });
            })
        : []
    );
    return acc;
  }, [] as SchemaForUI[][]);

export const templateSchemas2SchemasList = async (_template: Template) => {
  const template = cloneDeep(_template);
  const sortedSchemasList = sortSchemasList(template, template.schemas.length);
  const basePdf = await getB64BasePdf(template.basePdf);
  const pdfBlob = b64toBlob(basePdf);
  const pageSizes = await getPdfPageSizes(pdfBlob);
  const ssl = sortedSchemasList.length;
  const psl = pageSizes.length;
  const schemasList = (
    ssl < psl
      ? sortedSchemasList.concat(new Array(psl - ssl).fill(cloneDeep([])))
      : sortedSchemasList.slice(0, pageSizes.length)
  ).map((schema, i) => {
    Object.values(schema).forEach((value) => {
      const { width, height } = pageSizes[i];
      const xEdge = value.position.x + value.width;
      const yEdge = value.position.y + value.height;
      if (width < xEdge) {
        const diff = xEdge - width;
        value.position.x += diff;
      }
      if (height < yEdge) {
        const diff = yEdge - height;
        value.position.y += diff;
      }
    });

    return schema;
  });

  return schemasList;
};

export const fmtTemplate = (
  template: Template,
  schemasList: SchemaForUI[][]
): Template => {
  const schemaAddedTemplate: Template = {
    ...template,
    schemas: cloneDeep(schemasList).map((schema) =>
      schema.reduce((acc, cur) => {
        const k = cur.key;
        // @ts-ignore
        // delete cur.id;
        // @ts-ignore
        // delete cur.key;
        // @ts-ignore
        // delete cur.data;
        acc[k] = cur;

        return acc;
      }, {} as { [key: string]: Schema })
    ),
    columns: cloneDeep(schemasList).reduce(
      (acc, cur) => acc.concat(cur.map((s) => s.key)),
      [] as string[]
    ),
    sampledata: [
      cloneDeep(schemasList).reduce((acc, cur) => {
        cur.forEach((c) => {
          acc[c.key] = c.data;
        });
        return acc;
      }, {} as { [key: string]: string }),
    ],
    basePdf: template.basePdf,
  };

  return schemaAddedTemplate;
};

export const getInitialSchema = (): SchemaForUI => ({
  id: uuid(),
  key: "",
  showKey: "",
  data: "",
  type: "text",
  position: { x: 0, y: 0 },
  width: 10,
  height: 7,
  alignment: DEFAULT_ALIGNMENT,
  fontSize: DEFAULT_FONT_SIZE,
  characterSpacing: DEFAULT_CHARACTER_SPACING,
  lineHeight: DEFAULT_LINE_HEIGHT,
  required: true,
  subType: "default",
  ifDateFormat: "mm/dd/yyyy",
  radiogroupId: "",
  dropdownOptions: [{ option: "Option 1", value: "Option 1" }],
});

export const getSampleByType = (type: SchemaType) => {
  const defaultValueNew: { [K in SchemaType]: string } = {
    text: "sample input here..",
    number: "Number",
    email: "Email",
    name: "Name",
    dropdown: "",
    stamp: DEFAULT_DATA.stamp.defaultLink,
    static_image: DEFAULT_DATA.static_image.defaultLink,
    imageFileUpload: DEFAULT_DATA.imageFileUpload.defaultLink,
    checkbox: STANDARD_DATA.checkbox.unchecked_DBKEYV1.key,
    radiogroup: STANDARD_DATA.radiogroup.unchecked_DBKEYV1.key,
    image: STANDARD_DATA.signature.unsigned_DBKEYV1.key,
    qrcode: "https://pdfme.com/",
    japanpost: "6540123789-A-K-Z",
    ean13: "2112345678900",
    ean8: "02345673",
    code39: "THIS IS CODE 39",
    code128: "This is Code 128!",
    nw7: "A0123456789B",
    itf14: "04601234567893",
    upca: "416000336108",
    upce: "00123457",
    gs1datamatrix: "(01)03453120000011(17)191125(10)ABCD1234",
    initial: "Initial",
    date: `Date`,
    signature: STANDARD_DATA.signature.unsigned_DBKEYV1.key,
    line: "",
    // this should never be empty string or else the table will not generate
    table: "data",
    file_upload: "",
  };
  return defaultValueNew[type];
};

export const getKeepRatioHeightByWidth = (type: BarCodeType, width: number) => {
  const ratio: { [K in BarCodeType]: number } = {
    qrcode: 1,
    japanpost: 0.09,
    ean13: 0.4,
    ean8: 0.5,
    code39: 0.5,
    code128: 0.5,
    nw7: 0.5,
    itf14: 0.3,
    upca: 0.4,
    upce: 0.5,
    gs1datamatrix: 1,
  };
  return width * ratio[type];
};

export const getUniqSchemaKey = (arg: {
  copiedSchemaKey: string;
  schema: SchemaForUI[];
  stackUniqSchemaKeys: string[];
}) => {
  const { copiedSchemaKey, schema, stackUniqSchemaKeys } = arg;
  const schemaKeys = schema.map((s) => s.key).concat(stackUniqSchemaKeys);
  const tmp: { [originalKey: string]: number } = schemaKeys.reduce(
    (acc, cur) => Object.assign(acc, { originalKey: cur, copiedNum: 0 }),
    {}
  );
  const extractOriginalKey = (key: string) =>
    key.replace(/ copy$| copy [0-9]*$/, "");
  schemaKeys
    .filter((key) => / copy$| copy [0-9]*$/.test(key))
    .forEach((key) => {
      const originalKey = extractOriginalKey(key);
      const match = key.match(/[0-9]*$/);
      const copiedNum = match && match[0] ? Number(match[0]) : 1;
      if ((tmp[originalKey] ?? 0) < copiedNum) {
        tmp[originalKey] = copiedNum;
      }
    });

  const originalKey = extractOriginalKey(copiedSchemaKey);
  if (tmp[originalKey]) {
    const copiedNum = tmp[originalKey];
    const uniqKey = `${originalKey} copy ${copiedNum + 1}`;
    stackUniqSchemaKeys.push(uniqKey);

    return uniqKey;
  }
  const uniqKey = `${copiedSchemaKey} copy`;
  stackUniqSchemaKeys.push(uniqKey);

  return uniqKey;
};

export const moveCommandToChangeSchemasArg = (props: {
  command: "up" | "down" | "left" | "right";
  activeSchemas: SchemaForUI[];
  isShift: boolean;
  pageSize: Size;
}) => {
  const { command, activeSchemas, isShift, pageSize } = props;
  const key = command === "up" || command === "down" ? "y" : "x";
  const num = isShift ? 0.1 : 1;

  const getValue = (as: SchemaForUI) => {
    let value = 0;
    const { position } = as;
    switch (command) {
      case "up":
        value = round(position.y - num, 2);
        break;
      case "down":
        value = round(position.y + num, 2);
        break;
      case "left":
        value = round(position.x - num, 2);
        break;
      case "right":
        value = round(position.x + num, 2);
        break;
      default:
        break;
    }

    return value > 0 ? value : 0;
  };

  return activeSchemas.map((as) => {
    let value = getValue(as);
    const { width, height } = as;
    if (key === "x") {
      value =
        value > pageSize.width - width
          ? round(pageSize.width - width, 2)
          : value;
    } else {
      value =
        value > pageSize.height - height
          ? round(pageSize.height - height, 2)
          : value;
    }

    return { key: `position.${key}`, value, schemaId: as.id };
  });
};

export const getPagesScrollTopByIndex = (
  pageSizes: {
    width: number;
    height: number;
  }[],
  index: number,
  scale: number
) => {
  return pageSizes
    .slice(0, index)
    .reduce((acc, cur) => acc + 16 + cur.height * ZOOM * scale, 0);
};

// get heights
export const getSchemaHeightForDiv = ({
  divId,
  schema,
  pageSize,
}: {
  divId?: string;
  schema: SchemaForUI;
  pageSize: Size;
  // pageSizes: { height: number; width: number }[];
  // pageCursor: number;
}): { changeSchemaArg: ChangeSchemasArg[]; height: number } => {
  // @ts-ignore
  let changeSchemaArg: ChangeSchemasArg[] = [];
  let height: number = schema?.height || 5.4;

  const idsMap: { [key: string]: string } = {
    table: `table-schema-${schema.id}`,
  };

  // console.log({ id: idsMap[schema.type] });

  const divElm = document.getElementById(
    idsMap[schema.type] || divId || "not an id ------------------------"
  ) as HTMLElement;
  if (divElm) {
    const scrollHeight = divElm.scrollHeight;
    let calcHeight = _.round(scrollHeight / ZOOM, 2);
    // console.log({ scrollHeight, calcHeight, pHeight: pageSize.height });
    if (calcHeight > pageSize.height) {
      return { changeSchemaArg, height };
    } else {
      height = calcHeight;
      changeSchemaArg.push({
        key: "height",
        value: calcHeight,
        schemaId: schema.id,
      });
      return { changeSchemaArg, height };
    }
  }

  return { changeSchemaArg, height };
};

export const validBlankPageSchema = (s: SchemaForUI) => {
  return { isValid: BLANK_PG_SCHEMAS.includes(s.subType) && s.isStatic };
};

export const getStaticPosForBlankPage = (
  s: SchemaForUI,
  schemasList: SchemaForUI[][],
  pageCursor: number,
  pageSizes: Size[]
) => {
  let activePage = schemasList[pageCursor];
  let data = { position: { ...POSITION.A4 } };
  let maxHeight = POSITION.A4.y;
  for (let schema of activePage) {
    if (
      validBlankPageSchema(s).isValid &&
      validBlankPageSchema(schema).isValid
    ) {
      maxHeight = Math.max(
        maxHeight,
        schema.position.y + schema.height + POSITION.A4Gap.y
      );
    }
  }

  data.position.y = maxHeight;

  let lowestPoint = data.position.y + s.height;
  // check for page overflow
  if (lowestPoint > pageSizes[pageCursor].height) {
    data.position.y =
      pageSizes[pageCursor].height - POSITION.A4Gap.y - s.height;
  }
  return data;
};

export const fmt4Num = (prop: string) => Number(prop.replace("px", ""));
export const fmt = (prop: string) => round(fmt4Num(prop) / ZOOM, 2);

export const onResizeMoveOtherSchema = ({
  customSchema,
  pageCursor,
  pageSizes,
  schemasList,
  target,
  preventOverflow,
}: {
  customSchema: CustomSchemaType[];
  pageCursor: number;
  pageSizes: Size[];
  schemasList: SchemaForUI[][];
  target: HTMLElement | SVGElement;
  preventOverflow?: boolean;
}): { changeSchemas: ChangeSchemasArg[]; isBottomOverFlow: boolean } => {
  // console.log({ style: target.style });

  let currCustomPage = customSchema?.[pageCursor];
  if (!currCustomPage) {
    return { changeSchemas: [], isBottomOverFlow: false };
  }
  let otherChangeSchemas: ChangeSchemasArg[] = [];
  let isBottomOverFlow = false;
  preventOverflow = preventOverflow ?? false;
  let bottomSchemasStats = { height: 0, count: 0 };
  let blankPgStTargets: { above: SchemaForUI[]; below: SchemaForUI[] } = {
    above: [],
    below: [],
  };
  let lowestSchemaFromAbove: SchemaForUI | undefined = undefined;
  let highestSchemaFromBelow: SchemaForUI | undefined = undefined;
  const pageHeight = pageSizes[pageCursor].height;

  if (currCustomPage.pageType === "BLANK") {
    let activeSchema: SchemaForUI | undefined = schemasList?.[pageCursor].find(
      (elm) => elm.id == target.id
    );
    schemasList?.[pageCursor].map((elm) => {
      if (validBlankPageSchema(elm).isValid) {
        if (elm.position.y >= (activeSchema?.position.y || 0)) {
          blankPgStTargets.below.push(elm);
        } else {
          blankPgStTargets.above.push(elm);
        }
      }
    });

    for (let s of blankPgStTargets.above) {
      if (!lowestSchemaFromAbove) {
        lowestSchemaFromAbove = s;
      } else {
        lowestSchemaFromAbove =
          lowestSchemaFromAbove.position.y < s.position.y
            ? s
            : lowestSchemaFromAbove;
      }
    }

    for (let s of blankPgStTargets.below) {
      if (!highestSchemaFromBelow) {
        highestSchemaFromBelow = s;
      } else {
        highestSchemaFromBelow =
          highestSchemaFromBelow.position.y > s.position.y
            ? s
            : highestSchemaFromBelow;
      }
    }

    if (highestSchemaFromBelow) {
      // * consideration taken there will always exists a highestSchemaFromBelow basically this schema is the schema that we select
      // ! HACK_FIX using round to remove calc errors errors caused in fmt
      // ! Still does not work sometimes
      let draggedFromAbove =
        _.round(highestSchemaFromBelow.position.y, 2) !=
        _.round(fmt(target.style.top), 2);
      let heightdiff = fmt(target.style.height) - highestSchemaFromBelow.height;

      // console.log({
      //   draggedFromAbove,
      //   heightdiff,
      //   old: highestSchemaFromBelow.position.y,
      //   new: fmt(target.style.top),
      // });

      for (let s of blankPgStTargets.below) {
        if (s.id == target.id) {
          // dont do anything as this is top schema and dragged from below
          // i.e the top has remained the same
          if (draggedFromAbove) {
            let newY = fmt(target.style.top) + heightdiff;
            newY =
              newY + fmt(target.style.height) > pageHeight
                ? pageHeight - fmt(target.style.height)
                : newY;
            otherChangeSchemas.push({
              key: "position.y",
              value: fmt(target.style.top) + heightdiff,
              schemaId: s.id,
            });
          }
        } else {
          let newY = s.position.y + heightdiff;
          let isOverFlow = newY + s.height + POSITION.A4.y > pageHeight;
          newY = isOverFlow ? pageHeight - s.height - POSITION.A4.y : newY;
          isBottomOverFlow = isBottomOverFlow || isOverFlow;
          bottomSchemasStats.count++;
          bottomSchemasStats.height += s.height;
          otherChangeSchemas.push({
            key: "position.y",
            value: newY,
            schemaId: s.id,
          });
        }
      }

      // console.log({ preventOverflow, isBottomOverFlow });

      if (preventOverflow && isBottomOverFlow) {
        let totalBtmSpace =
          bottomSchemasStats.height +
          POSITION.A4Gap.y * bottomSchemasStats.count +
          POSITION.A4.y;
        let idealHeight = _.round(
          Math.max(
            pageSizes[pageCursor].height -
              fmt(target.style.top) -
              totalBtmSpace,
            0
          ),
          2
        );
        otherChangeSchemas = [];
        otherChangeSchemas.push({
          key: "height",
          value: idealHeight,
          schemaId: target.id,
        });

        // ! *** : IMP CODE HERE
        heightdiff = idealHeight - highestSchemaFromBelow.height;
        for (let s of blankPgStTargets.below) {
          if (s.id == target.id) {
            // dont do anything as this is top schema and dragged from below
            // i.e the top has remained the same
            if (draggedFromAbove) {
              let newY = fmt(target.style.top) + heightdiff;
              newY =
                newY + idealHeight > pageHeight
                  ? pageHeight - idealHeight
                  : newY;
              otherChangeSchemas.push({
                key: "position.y",
                value: fmt(target.style.top) + heightdiff,
                schemaId: s.id,
              });
            }
          } else {
            let newY = s.position.y + heightdiff;
            let isOverFlow = newY + s.height + POSITION.A4.y > pageHeight;
            newY = isOverFlow ? pageHeight - s.height - POSITION.A4.y : newY;
            otherChangeSchemas.push({
              key: "position.y",
              value: newY,
              schemaId: s.id,
            });
          }
        }
      }
    }
  }
  return { changeSchemas: otherChangeSchemas, isBottomOverFlow };
};
