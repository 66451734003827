// @ts-nocheck
import React, { Fragment, useState, useRef, useEffect } from "react";
import { MdClose, MdDragIndicator } from "react-icons/md";
import { ReactSortable } from "react-sortablejs";

const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  ghostClass: "ghost",
  group: "shared",
  forceFallback: true,
};

const Sorting = ({ helpers }) => {
  let { formState, setFormState, active, setActive } = helpers;
  const [blocks, setBlocks] = useState(formState.pages.slice(0, -1));
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setActive((prev) => ({ ...prev, sorting: false }));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    // Remove Event listener after component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // previously when questions were being reordered, formstate was not being updated, instead blocks were being updated
  // so, now we are updating formstate when blocks are updated using useEffect
  useEffect(() => {
    if (active?.sorting === "questions") {
      setFormState((prevState) => {
        const lastPageIndex = prevState.pages.length - 1;
        const lastPage = prevState.pages[lastPageIndex];

        return {
          ...prevState,
          pages: [...blocks, lastPage],
        };
      });
    }
  }, [blocks, active.sorting]);

  return (
    <div className="px-[38px] pt-[40px] fixed inset-0 bg-black bg-opacity-40 flex justify-center">
      <div
        ref={modalRef}
        className="w-[440px] max-h-[90vh] overflow-y-auto h-fit bg-white rounded-lg border"
      >
        <div className="px-5 py-4 border-b flex items-center justify-between">
          {" "}
          <div>
            <h2 className="font-medium capitalize">Reorder {active.sorting}</h2>
          </div>
          <MdClose
            onClick={() => {
              setActive((prev) => ({ ...prev, sorting: false }));
            }}
            className="text-gray-600 text-2xl cursor-pointer"
          />
        </div>
        <div>
          {/* <ReactSortable list={blocks} setList={setBlocks} {...sortableOptions}> */}
          {active.sorting === "questions" &&
            blocks.map((block, blockIndex) => (
              <BlockWrapper
                key={block.id}
                block={block}
                blockIndex={[blockIndex]}
                setBlocks={setBlocks}
              />
            ))}
          {/* </ReactSortable> */}
          {active.sorting === "pages" && (
            <ReactSortable
              direction={"vertical"}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={formState?.pages}
              setList={(newState) => {
                setFormState((prevState) => {
                  const newForm = { ...prevState };
                  newForm.pages = newState;
                  return newForm;
                });
              }}
            >
              {formState?.pages?.slice(0, -1)?.map((page, index) => {
                return (
                  <div
                    translate="no"
                    key={page?.id}
                    className="px-4 py-3 border-t text-sm flex flex-row bg-white"
                  >
                    <div className="flex items-center justify-center mr-4 cursor-move">
                      <MdDragIndicator className="text-gray-500 text-lg" />
                    </div>
                    <div className="w-10/12">
                      <h2 className="text-gray-700 truncate">
                        {page?.title || "Untitled"}
                      </h2>
                      <p className="text-gray-500 text-xs mt-1">
                        {page?.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </ReactSortable>
          )}
          {/*
          {active.sorting === "questions" && (
            <Fragment>
              <h2
                translate="no"
                className="px-6 my-3 text-gray-700 text-sm font-medium truncate"
              >
                {
                  formState?.pages[
                    active.pageIndex > formState?.pages?.length - 1
                      ? 0
                      : active.pageIndex
                  ]?.title
                }
              </h2>
              <ReactSortable
                direction={"vertical"}
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={
                  formState?.pages[
                    active.pageIndex > formState?.pages?.length - 1
                      ? 0
                      : active.pageIndex
                  ]?.questions
                }
                setList={(newState) => {
                  setFormState((prevState) => {
                    const newForm = { ...prevState };
                    newForm.pages[
                      active.pageIndex > formState?.pages?.length - 1
                        ? 0
                        : active.pageIndex
                    ].questions = newState;
                    return newForm;
                  });
                }}
              >
                {formState?.pages[
                  active.pageIndex > formState?.pages?.length - 1
                    ? 0
                    : active.pageIndex
                ]?.questions?.map((question, index) => {
                  return (
                    <div
                      style={{
                        display:
                          // question.validation !== "respondent_email"
                          //   ? "flex"
                          //   : "none",
                          "flex",
                      }}
                      translate="no"
                      key={question?.id}
                      className="px-4 py-3 border-t text-sm flex flex-row bg-white"
                    >
                      <div className="flex items-center justify-center mr-4 cursor-move">
                        <MdDragIndicator className="text-gray-500 text-lg" />
                      </div>
                      <div className="w-10/12">
                        <h2 className="text-gray-700 truncate">
                          {question?.title || "Untitled"}
                        </h2>
                        <p className="text-gray-500 text-xs mt-1">
                          {question?.type}
                        </p>
                      </div>
                      {question.validation === "respondent_email" && (
                        <span className="text-[10px] whitespace-nowrap text-red-600">
                          <span className="text-base">*</span>Respondent Email
                        </span>
                      )}
                    </div>
                  );
                })}
              </ReactSortable>
            </Fragment>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Sorting;

function Container({ block, blockIndex, setBlocks }) {
  return (
    <>
      <ReactSortable
        key={block.id}
        list={block.questions}
        setList={(currentList) => {
          console.log("currentList ==>", currentList);
          setBlocks((sourceList) => {
            const tempList = [...sourceList];
            const _blockIndex = [...blockIndex];
            const lastIndex = _blockIndex.pop();
            const lastArr = _blockIndex.reduce(
              (arr, i) => arr[i]["questions"],
              tempList
            );
            console.log("lastIndex ==>", lastIndex);
            lastArr[lastIndex]["questions"] = currentList;
            return tempList;
          });
        }}
        {...sortableOptions}
      >
        {block.questions &&
          block.questions.map((childBlock, index) => {
            return (
              <BlockWrapper
                key={childBlock.id}
                block={childBlock}
                blockIndex={[...blockIndex, index]}
                setBlocks={setBlocks}
              />
            );
          })}
      </ReactSortable>
    </>
  );
}
function BlockWrapper({ block, blockIndex, setBlocks }) {
  //   console.log(block);
  if (!block) return null;
  if (block.questions) {
    return (
      <div className="block">
        {/* container: {block.title} */}
        <div
          translate="no"
          className="flex px-4 py-3 border-t text-sm flex-row"
        >
          {/* <div className="flex items-center justify-center mr-4 cursor-move">
            <MdDragIndicator className="text-gray-500 text-lg " />
          </div> */}
          <div className="w-10/12">
            <h2 className="text-gray-700 truncate">
              {block?.title || "Untitled"}
            </h2>
            <p className="text-gray-500 text-xs mt-1">{block?.description}</p>
          </div>
        </div>
        <Container
          block={block}
          setBlocks={setBlocks}
          blockIndex={blockIndex}
        />
      </div>
    );
  } else {
    return (
      <div className="block">
        {/* text: skhjk {block.title} */}

        <div
          style={{
            display: block.validation !== "respondent_email" ? "flex" : "none",
          }}
          translate="no"
          key={block?.id}
          className="px-4 py-3 border-t text-sm flex flex-row"
        >
          <div className="ml-7 flex items-center justify-center mr-4 cursor-move">
            <MdDragIndicator className="text-gray-500 text-lg" />
          </div>
          <div className="w-10/12">
            <h2 className="text-gray-700 truncate">
              {block?.title || "Untitled"}
            </h2>
            <p className="text-gray-500 text-xs mt-1">{block?.type}</p>
          </div>
        </div>
      </div>
    );
  }
}
