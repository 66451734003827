import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import getS3PreSignedUrl from "../../utils/getS3PreSignedUrl";
import { IMAGE_LINKS } from "../../constants/common";
import { useAppSelector } from "../../redux/store";
import axios from "../../utils/axios";

const OpenSecureLink = () => {
  const [searchParams] = useSearchParams();
  let url = searchParams.get("url");
  let workspaceId = searchParams.get("wsId");
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  let owner = JSON.parse(localStorage.getItem("owner"));
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [signedUrl, setSignedUrl] = useState(null);

  async function openSecureUrl() {
    let { data } = await axios.get(
      `/common/s3-presigned-url?s3Url=${url}&ownerId=${owner._id}&workspaceId=${workspaceId}&email=${currentUserEmail}`
    );

    // let signedUrl = await getS3PreSignedUrl(url, workspaceId, currentUserEmail);
    // console.log(signedUrl);
    setSignedUrl(data.signedUrl);
  }

  useEffect(() => {
    openSecureUrl();
  }, []);

  if (!owner) {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <p>Looks like you are not logged in. Please login to view the link. </p>
        <p className="text-center mt-2">
          <a
            href={`/login?redirect=${window.location.href?.replace(
              "&",
              "%26"
            )}`}
            className="text-blue-500 underline"
          >
            Login Here
          </a>
        </p>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div>
        <div className="flex items-center justify-center mb-2">
          {/* white label logo */}
          <img
            src={
              whiteLabelInfo?.settings?.organization?.logo ||
              IMAGE_LINKS.BOLOLOGO
            }
            alt="logo"
            className="w-14 h-14 rounded-full"
          />
        </div>
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-center">Open Secure Link</h1>
          <p className="mt-2 text-sm text-gray-500 text-center">
            This link is secure and can only be opened by the authorized user
            and will expire in 24 hours.
          </p>
        </div>
      </div>

      {signedUrl ? (
        <div className="flex flex-col justify-center items-center">
          <a href={signedUrl} className="text-blue-500 underline">
            Open Link
          </a>
          <p className="text-center text-sm my-3">or copy the below link</p>
          <p className="w-2/3 text-center text-gray-500">{signedUrl}</p>
        </div>
      ) : (
        <div>
          <p>
            You are not authorized to view this link. Please contact the owner
            of the link.{" "}
          </p>
          <p className="text-center mt-2">
            <a href="/login" className="text-blue-500 underline">
              Go to Home
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default OpenSecureLink;
