import React, { Fragment, useEffect, useState } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { MdArrowDropDown } from "react-icons/md";

const dropDown = cva("cursor-pointer w-[100%] hover:shadow", {
  variants: {
    variant: {
      primary: "",
      secondary: "",
      tertiary: "",
      // can have here branded variants
    },
    size: {
      xs: "py-1 text-xs rounded-sm",
      sm: "py-1.5 text-sm rounded-md",
      md: "py-2 text-base rounded",
      lg: "py-2.5 text-lg rounded-lg",
      // xl: "",
    },
  },
  // compoundVariants: [{ intent: "primary", size: "md", class: "uppercase" }],
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

export type BoloDropDownOption = {
  key: string;
  value: string;
};
type DropDownProps = {
  options: BoloDropDownOption[];
  selectedOption?: BoloDropDownOption;
  defaultText?: string;
  selectOption: (value: BoloDropDownOption) => void;
};

export interface BoloDropDownProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof dropDown>,
    DropDownProps {}

/*
---------------------------------------------------------------
Note this is a semi complete component without styling to be updated later
---------------------------------------------------------------
*/

export const BoloDropDown: React.FC<BoloDropDownProps> = ({
  className,
  variant,
  size,
  options,
  selectedOption,
  defaultText,
  selectOption,
  ...props
  // }) => <button className={dropDown({ variant, size, className })} {...props} />;
}) => {
  const questionTypeDropdownRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [displacementAmount, setDisplacementAmount] = useState(0);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: BoloDropDownOption, index: number) => {
    let displacement = -(index - 1) * 48;
    setDisplacementAmount(displacement);
    selectOption(option);
    setIsOpen((prev) => !prev);
  };

  // bad code any **
  const handleOutsideClick = (e: any) => {
    if (questionTypeDropdownRef.current !== null) {
      // bad code ** check later
      // @ts-ignore
      if (questionTypeDropdownRef?.current?.contains(e.target)) return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={dropDown({ variant, size, className })} {...props}>
      <div className="relative">
        <div
          onClick={toggleDropdown}
          className="h-[36px] px-3 rounded flex items-center cursor-pointer"
        >
          <div className="ml-1 mr-2">
            <span className="text-gray-800">
              {selectedOption?.value || defaultText}
            </span>
          </div>
          <div className="ml-auto">
            <MdArrowDropDown className="text-2xl text-gray-500" />
          </div>
        </div>
        {isOpen && (
          <div
            ref={questionTypeDropdownRef}
            style={{
              marginTop: "-42px",
              position: "absolute",
              boxShadow:
                "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
              // top: `${displacementAmount}px`,
              zIndex: 5,
            }}
            className="py-2 w-[100%] bg-white border border-[#0000001f] rounded z-150 max-h-[250px] overflow-y-scroll"
            id="mini-pdf-custom-scroll"
          >
            {options.map((option, index) => {
              return (
                <Fragment key={index}>
                  <div
                    onClick={() => handleOptionClick(option, index)}
                    className={`${
                      selectedOption?.key === option?.key && "bg-[#e4f0fc]"
                    } min-h-[48px] w-full py-2 pl-4 pr-8 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <span className="text-gray-800 font-medium">
                      {option?.value}
                    </span>
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
