// @ts-nocheck
import { useRef, useState } from "react";
import PlanCards from "../Billings/PlanCards";
import { MdClose } from "react-icons/md";
import { FaMinus, FaPlus } from "react-icons/fa6";
import ComparisonTable from "../Billings/ComparisonTable";
import { useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";

const UpgradeModal = ({ setShow }) => {
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const [showCompare, setShowCompare] = useState(true);
  const [isAnnualBilling, setIsAnnualBilling] = useState(true);
  const targetRef = useRef(null);
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  let fromTrial =
    owner?.planhistory?.length > 1 &&
    owner?.planhistory?.[owner?.planhistory?.length - 2]?.tier ===
      "FREE_TRIAL_TIER";
  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={` h-full md:h-[90%] w-full  md:w-fit
         relative bg-white  px-4 md:px-8 py-5 md:py-5 overflow-y-auto`}
        id="mini-pdf-custom-scroll"
      >
        <div className="w-[100%] relative flex flex-col">
          <MdClose
            className="absolute top-2 md:top-3 right-0 cursor-pointer text-2xl"
            onClick={() => {
              setShow(false);
            }}
          />
        </div>
        <div className="flex gap-2 items-center">
          <img
            src={
              whiteLabelInfo?.settings?.organization?.logo ||
              IMAGE_LINKS?.BOLOLOGO
            }
            alt={
              whiteLabelInfo?.settings?.organization?.name ||
              "BoloForms Signature"
            }
            className=" w-12 h-12 md:w-14  md:h-14"
          />
          <span className=" text-lg md:text-xl font-semibold">
            {whiteLabelInfo?.settings?.organization?.name ||
              "BoloForms Signature"}
          </span>
        </div>
        <div
          ref={targetRef}
          className="my-6 mx-auto gap-y-8 w-full flex flex-col items-center justify-center max-w-[1300px]"
        >
          <div className="text-center">
            <h1 className="text-2xl md:text-4xl font-semibold">
              {fromTrial
                ? "Your Trial has Expired"
                : "Reactivate your subscription"}
            </h1>
            <p className="text-gray-500 mt-4 text-sm md:text-base">
              {fromTrial
                ? "Your trial has expired. Please selects a plan to start using all"
                : "Your subscription has expired. Please reactivate it to start using"}{" "}
              {whiteLabelInfo?.settings?.organization?.name || "BoloForms"}{" "}
              features again.
            </p>
          </div>
          <div className="flex flex-col gap-y-5 py-4 w-full  ">
            <h3 className="text-xl md:text-3xl font-semibold">Pricing</h3>
            <div className="text-xs mb-3 sm:text-sm flex items-center justify-start">
              <span className="font-medium mr-3">Monthly</span>
              <label
                className={`switch-button m-0 ${
                  isAnnualBilling && "switch-button-checked"
                }`}
              >
                <input
                  type="checkbox"
                  id="isApprovalWorkflow"
                  name="isApprovalWorkflow"
                  checked={isAnnualBilling}
                  onChange={() => setIsAnnualBilling((prev) => !prev)}
                />
                <div></div>
              </label>
              <span className="font-medium ml-3">Annually</span>
              <div className=" flex items-center justify-center ml-2 rounded-3xl bg-green-700 text-white px-2 text-[10px] font-medium">
                save 20%
              </div>
            </div>
            <PlanCards isAnnualBilling={isAnnualBilling} owner={owner} />
          </div>
          <div className="flex flex-col gap-y-5 py-4 mt-0 w-full">
            <h3 className="text-xl md:text-3xl font-semibold flex items-center gap-8 ">
              <span>Compare Plans</span>{" "}
              {showCompare ? (
                <FaMinus
                  className="cursor-pointer"
                  onClick={() => {
                    setShowCompare(false);
                  }}
                />
              ) : (
                <FaPlus
                  className="cursor-pointer"
                  onClick={() => {
                    setShowCompare(true);
                  }}
                />
              )}
            </h3>
            {showCompare && <ComparisonTable scrollToTarget={scrollToTarget} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;
