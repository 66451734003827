import axios from "../../utils/axios";
import { ProductType } from "../../hooks/reduxHooks/useQuoteBuilder";

const fetchAllProducts = async ({
  page = 1,
  limit = 10,
  query = "",
  dateFrom = "",
  dateTo = "",
  sortBy = "",
  sortOrder = "-1",
  pCategory = "",
}: {
  page?: number;
  limit?: number;
  query?: string;
  dateFrom?: string;
  dateTo?: string;
  sortBy?: string;
  sortOrder?: string;
  pCategory?: string;
}): Promise<
  { products?: ProductType[]; productCount?: number } | undefined
> => {
  try {
    let requestUrl;
    requestUrl = `/product?page=${page}&limit=${limit}&query=${query}&sortBy=${sortBy}&sortOrder=${sortOrder}&dateFrom=${dateFrom}&dateTo=${dateTo}&category=${pCategory}`;

    let { data } = await axios.get(requestUrl);
    return data;
  } catch (error) {
    console.log("error in fetchAllDocuments");
    console.log(error);
  }
};

export { fetchAllProducts };
