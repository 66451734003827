import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  MdOutlineDelete,
  MdPersonAdd,
  MdPersonAddAlt1,
  MdPersonRemove,
} from "react-icons/md";
import { AiFillDelete, AiTwotoneDelete } from "react-icons/ai";
import { resolveSoa } from "dns";
import { getRandomColorV1 } from "../../utils/uiUtils";
import { BoloButton } from "../Common/Button/BoloButton";

const RolesInput = ({ onSave, resetAll, preFetchedRoles }: any) => {
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color; // Add opacity (70%)
  };

  const [roles, setRoles] = useState<any>(
    preFetchedRoles?.length > 0
      ? preFetchedRoles
      : [
          {
            title: "",
            colour: getRandomColorV1(),
          },
        ]
  );
  const [rolesField, setRolesField] = useState<any>([]);
  const [roleTitle, setRoleTitle] = useState<string>("");
  const [onKeyDownVerfiy, setOnKeyDownVerfiy] = useState<string>("");
  const inputRef = React.useRef<HTMLInputElement[] | null[]>([]);

  useEffect(() => {
    if (resetAll) {
      setRoles([]);
      onSave([]);
    } else {
      return;
    }
  }, [resetAll]);

  useEffect(() => {
    if (preFetchedRoles?.length > 0) {
      setRoles(preFetchedRoles);
    }
  }, [preFetchedRoles]);

  const handleSave = async () => {
    // Perform save operation with the emails array
    if (roles.length > 0) {
      let filteredRoles = roles.filter((elm: any) => !!elm.title);
      onSave(filteredRoles);
      return toast.success("Roles saved successfully, you can add PDF now");
    } else {
      return toast.error("Please enter at least one role");
    }
  };

  //   const handleTextClick = () => {
  //     setShowInputForm(!showInputForm);
  //   };
  useEffect(() => {
    let rolesModified = [...roles, { title: "", colour: "" }];
    setRolesField(rolesModified);
  }, [roles]);

  const handleRemoveRole = (index: number) => {
    setRoles((prevRoles: any) =>
      prevRoles.filter((_: any, roleIndex: any) => roleIndex !== index)
    );
    onSave((prevRoles: any) =>
      prevRoles.filter((_: any, roleIndex: any) => roleIndex !== index)
    );
  };

  useEffect(() => {
    let modifiedRoles = roles
      .map((role: any) => {
        if (role?.title.trim() !== "") {
          return {
            ...role,
            colour: getRandomColorV1(),
          };
        }
        return null; // Exclude roles with empty name or email
      })
      .filter((role: any) => role !== null);

    //@ts-ignore
    // onSave(modifiedRoles);
  }, [roles]);

  return (
    <div className={`w-[100%] md:w-[65%] max-w-[800px] mx-auto`}>
      <div className="inputs flex flex-col gap-4">
        <div className="role flex flex-col">
          <p className="text-xs mb-2 font-medium">Role</p>{" "}
          {/* <div className="flex flex-wrap">
            {roles.map((role: any, index: any) => (
              <div
                key={index}
                className={`flex items-center shadow-md rounded-md p-2 mr-2 mb-2 border border-gray-200`}
                style={{ backgroundColor: `${role.colour}40` }}
              >
                <div className="">{role.title}</div>
                <button
                  className="ml-4 text-red-500"
                  onClick={() => handleRemoveRole(index)}
                >
                  <MdPersonRemove size={20} className="text-red-500" />
                </button>
              </div>
            ))}
          </div> */}
          <div className="">
            {roles.map((current: any, index: any) => {
              return (
                <div className="flex gap-2 w-full py-3">
                  <div className="email flex flex-col w-full">
                    <div className="flex flex-row justify-center items-center gap-2 border border-gray-200 rounded-[3px] p-2 bg-white">
                      <input
                        ref={(element) => {
                          inputRef.current[index] = element;
                        }}
                        type="text"
                        id="email"
                        placeholder="Enter Role"
                        className="w-full text-xs md:text-sm outline-none"
                        value={current.title || roleTitle}
                        onChange={(e) => {
                          if (roles[index]) {
                            roles[index].title = e.target.value;
                            setRoles([...roles]);
                            const filteredRoles = roles.filter(
                              (role: any) => !!role.title
                            );
                            onSave([...filteredRoles]);
                          }
                          setOnKeyDownVerfiy(e.target.value);
                        }}
                        onKeyDown={async (e) => {
                          if (
                            e.key === "Enter" &&
                            onKeyDownVerfiy &&
                            index == roles?.length - 1
                          ) {
                            const newColour = getRandomColor(); // Generate random colo
                            const newRole = roleTitle.trim();
                            setRoles("");
                            await setRoles([
                              ...roles,
                              { title: "", colour: newColour },
                            ]);
                            // await onSave([
                            //   ...roles,
                            //   { title: "", colour: newColour },
                            // ]);
                            inputRef.current[index + 1]?.focus();
                          }
                        }}
                      />
                    </div>
                  </div>
                  {roles[index] && (
                    <BoloButton
                      variant={"danger-border"}
                      size={"icon-sm"}
                      orientation="center"
                      className={`min-h-[38px] min-w-[38px] ${
                        roles?.length === 1
                          ? "opacity-70 hover:opacity-70 hover:cursor-not-allowed cursor-not-allowed"
                          : ""
                      }`}
                      onClick={() => {
                        if (roles?.length > 1) {
                          handleRemoveRole(index);
                        }
                      }}
                    >
                      <MdOutlineDelete size={20} className="" />
                    </BoloButton>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <BoloButton
          size={"sm"}
          variant={"tertiary"}
          className="w-full gap-3"
          orientation={"center"}
          // className="text-primary ml-auto w-full bg-secondary px-4 py-1.5 rounded-md text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
          onClick={() => {
            const newColour = getRandomColor(); // Generate random colo
            const newRole = roleTitle.trim();
            // setRoles("");
            setRoles([...roles, { title: "", colour: newColour }]);
          }}
        >
          <span className=""> Add Role</span>{" "}
          <MdPersonAddAlt1 className="inline-block text-xl md:text-2xl" />
        </BoloButton>
        {/* <div className="message flex flex-col mt-2">
          <p className="text-xs mb-2 font-medium">Add Private Message</p>{" "}
          <div className="text-sm flex flex-row justify-center items-center gap-2 border border-gray-300 rounded-md p-2">
            <textarea
              id="note"
              placeholder="Enter note"
              className="w-full outline-none text-sm"
              rows={6}
              //   value={sendToNote}
              //   onChange={(e) => setSendToNote(e.target.value)}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RolesInput;
