import React, { CSSProperties, useContext } from "react";
// import { SchemaForUI } from '@pdfme/common';
import { round } from "../../../../helper";
import { SidebarProps } from "../index";
import { FontContext } from "../../../../contexts";
import { SchemaForUI } from "../../../../../../common/src";
import {
  MdAlignHorizontalLeft,
  MdOutlineAlignHorizontalCenter,
  MdOutlineAlignHorizontalRight,
  MdOutlineAlignVerticalTop,
  MdOutlineAlignVerticalCenter,
  MdOutlineAlignVerticalBottom,
} from "react-icons/md";
const inputSetStyle: CSSProperties = {
  marginRight: "1rem",
  display: "flex",
  alignItems: "center",
};

const inputStyle: CSSProperties = {
  width: 70,
  border: "1px solid #767676",
  borderRadius: 2,
  color: "#333",
  background: "none",
};

const svgBaseProp = {
  style: { width: "100%", height: "100%" },
  xmlns: "http://www.w3.org/2000/svg",
  enableBackground: "new 0 0 24 24",
  height: "24px",
  viewBox: "0 0 24 24",
  width: "24px",
  fill: "#000000",
};

const PositionAndSizeEditor = (
  props: Pick<
    SidebarProps,
    "pageSize" | "schemas" | "changeSchemas" | "activeElements"
  > & {
    activeSchema: SchemaForUI;
  }
) => {
  const font = useContext(FontContext);
  const { changeSchemas, schemas, activeSchema, activeElements, pageSize } =
    props;

  const align = (
    type: "left" | "center" | "right" | "top" | "middle" | "bottom"
  ) => {
    const ids = activeElements.map((ae) => ae.id);
    const ass = schemas.filter((s) => ids.includes(s.id));

    const isVertical = ["left", "center", "right"].includes(type);
    const tgtPos = isVertical ? "x" : "y";
    const tgtSize = isVertical ? "width" : "height";
    const isSingle = ass.length === 1;
    const root = pageSize[tgtSize];

    const min = isSingle
      ? 0
      : Math.min(...ass.map((as) => as.position[tgtPos]));
    const max = isSingle
      ? root
      : Math.max(...ass.map((as) => as.position[tgtPos] + as[tgtSize]));

    let basePos = min;
    let adjust = (_: number) => 0;

    if (["center", "middle"].includes(type)) {
      basePos = (min + max) / 2;
      adjust = (num: number) => num / 2;
    } else if (["right", "bottom"].includes(type)) {
      basePos = max;
      adjust = (num: number) => num;
    }

    changeSchemas(
      ass.map((as) => ({
        key: `position.${tgtPos}`,
        value: round(basePos - adjust(as[tgtSize]), 2),
        schemaId: as.id,
      }))
    );
  };

  const distribute = (type: "vertical" | "horizontal") => {
    const ids = activeElements.map((ae) => ae.id);
    const ass = schemas.filter((s) => ids.includes(s.id));

    const isVertical = type === "vertical";
    const tgtPos = isVertical ? "y" : "x";
    const tgtSize = isVertical ? "height" : "width";
    const min = Math.min(...ass.map((as) => as.position[tgtPos]));
    const max = Math.max(...ass.map((as) => as.position[tgtPos] + as[tgtSize]));

    if (ass.length < 3) return;

    const boxPos = min;
    const boxSize = max - min;
    const sum = ass.reduce((acc, cur) => acc + cur[tgtSize], 0);
    const remain = boxSize - sum;
    const unit = remain / (ass.length - 1);

    let prev = 0;
    changeSchemas(
      ass.map((as, index) => {
        prev += index === 0 ? 0 : ass[index - 1][tgtSize] + unit;
        const value = round(boxPos + prev, 2);
        return { key: `position.${tgtPos}`, value, schemaId: as.id };
      })
    );
  };

  const layoutBtns: { id: string; icon: any; action: () => void }[] = [
    {
      id: "left",
      icon: <MdAlignHorizontalLeft size={15} />,
      action: () => align("left"),
    },
    {
      id: "center",
      icon: <MdOutlineAlignHorizontalCenter size={15} />,
      action: () => align("center"),
    },
    {
      id: "right",
      icon: <MdOutlineAlignHorizontalRight size={15} />,
      action: () => align("right"),
    },
    {
      id: "top",
      icon: <MdOutlineAlignVerticalTop size={15} />,
      action: () => align("top"),
    },
    {
      id: "middle",
      icon: <MdOutlineAlignVerticalCenter size={15} />,
      action: () => align("middle"),
    },
    {
      id: "bottom",
      icon: <MdOutlineAlignVerticalBottom size={15} />,
      action: () => align("bottom"),
    },
    // {
    //   id: 'vertical',
    //   icon: (
    //     <svg {...svgBaseProp}>
    //       <rect fill="none" height="24" width="24" />
    //       <path d="M22,2v2H2V2H22z M7,10.5v3h10v-3H7z M2,20v2h20v-2H2z" />
    //     </svg>
    //   ),
    //   action: () => distribute('vertical'),
    // },
    // {
    //   id: 'horizontal',
    //   icon: (
    //     <svg {...svgBaseProp}>
    //       <rect fill="none" height="24" width="24" />
    //       <path d="M4,22H2V2h2V22z M22,2h-2v20h2V2z M13.5,7h-3v10h3V7z" />
    //     </svg>
    //   ),
    //   action: () => distribute('horizontal'),
    // },
  ];

  return (
    <section>
      <div className="flex items-center gap-2 w-full mb-4 text-xs mt-4">
        <label className="mb-0 ">
          Allignments:
          {/* <u style={{ fontSize: '0.7rem' }}>
            (<ErrorLabel msg={i18n('require')} isError={blankKey} />+
            <ErrorLabel msg={i18n('uniq')} isError={hasSameKey} />)
          </u> */}
        </label>
        <div className="flex items-center justify-center gap-1">
          {layoutBtns.map((b) => (
            <button
              key={b.id}
              title={b.id}
              onClick={b.action}
              className="p-1 border-2 border-primary/90 rounded-[3px] flex items-center justify-center hover:bg-primary hover:text-white"
            >
              {b.icon}
            </button>
          ))}
        </div>
      </div>
      <div className="flex items-center justify-between mb-2 text-xs gap-4">
        <div className="flex items-center justify-between">
          <label className="w-4 mr-1">X</label>
          <input
            className="w-[80%] border-2 border-primary/90 rounded-[3px] pl-1  outline-none"
            type="number"
            onChange={(e) => {
              const value = Number(e.target.value);
              if (value >= 0 && activeSchema.width + value < pageSize.width) {
                changeSchemas([
                  { key: "position.x", value, schemaId: activeSchema.id },
                ]);
              }
            }}
            value={activeSchema.position.x}
          />
        </div>
        <div className="flex items-center justify-between">
          <label className="w-4 mr-1 ">Y</label>
          <input
            className="w-[80%] border-2 border-primary/90 rounded-[3px] pl-1  outline-none"
            type="number"
            onChange={(e) => {
              const value = Number(e.target.value);
              if (value >= 0 && activeSchema.height + value < pageSize.height) {
                changeSchemas([
                  { key: "position.y", value, schemaId: activeSchema.id },
                ]);
              }
            }}
            value={activeSchema.position.y}
          />
        </div>
      </div>
      <div className="flex items-center justify-between text-xs gap-4">
        <div className="flex items-center justify-between">
          <label className="w-4 mr-1 ">W</label>
          <input
            className="w-[80%] border-2 border-primary/90 rounded-[3px] pl-1  outline-none"
            type="number"
            onChange={(e) => {
              const value = Number(e.target.value);
              if (
                value >= 0 &&
                activeSchema.position.x + value < pageSize.width
              ) {
                changeSchemas([
                  { key: "width", value, schemaId: activeSchema.id },
                ]);
              }
            }}
            value={activeSchema.width}
          />
        </div>
        <div className="flex items-center justify-between">
          <label className="w-4 mr-1 ">H</label>
          <input
            className="w-[80%] border-2 border-primary/90 rounded-[3px] pl-1  outline-none"
            name="input-height"
            type="number"
            onChange={(e) => {
              const value = Number(e.target.value);
              if (
                value >= 0 &&
                activeSchema.position.y + value < pageSize.height
              ) {
                changeSchemas([
                  { key: "height", value, schemaId: activeSchema.id },
                ]);
              }
            }}
            value={activeSchema.height}
          />
        </div>
      </div>
    </section>
  );
};

export default PositionAndSizeEditor;
