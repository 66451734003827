export const LANGUAGE = {
  en: {
    hello: "Hello",
    messageFromSenderText: "This is a message from the sender",
    thankYou: "Thank You!",
    the: "The",
    team: "Team",
    signNowText: "Sign Now",
  },
  iw: {
    hello: "שלום",
    messageFromSenderText: "זו הודעה מהשולח",
    thankYou: "תודה לך!",
    the: "ה",
    team: "צוות",
    signNowText: "חתום עכשיו",
  },
  de: {
    hello: "Hallo",
    messageFromSenderText: "Dies ist eine Nachricht des Absenders",
    thankYou: "Danke!",
    the: "Die",
    team: "Mannschaft",
    signNowText: "Jetzt unterschreiben",
  },
  fr: {
    hello: "Bonjour",
    messageFromSenderText: "Ceci est un message de l'expéditeur",
    thankYou: "Merci !",
    the: "Le",
    team: "Équipe",
    signNowText: "Signez maintenant",
  },
  nl: {
    hello: "Hallo",
    messageFromSenderText: "Dit is een bericht van de afzender",
    thankYou: "Bedankt!",
    the: "De",
    team: "Ploeg",
    signNowText: "Teken nu",
  },
  da: {
    hello: "Hej",
    messageFromSenderText: "Dette er en besked fra afsenderen",
    thankYou: "Tak!",
    the: "Den",
    team: "Hold",
    signNowText: "Signer nu",
  },
  cs: {
    hello: "Dobrý den",
    messageFromSenderText: "Toto je zpráva od odesílatele",
    thankYou: "Děkuji!",
    the: "",
    team: "Tým",
    signNowText: "Podepište se nyní",
  },
  id: {
    hello: "Halo",
    messageFromSenderText: "Ini adalah pesan dari pengirimnya",
    thankYou: "Terima kasih!",
    the: "Itu",
    team: "Tim",
    signNowText: "Masuk Sekarang",
  },
  it: {
    hello: "Ciao",
    messageFromSenderText: "Questo è un messaggio dal mittente",
    thankYou: "Grazie!",
    the: "Il",
    team: "Squadra",
    signNowText: "Firma ora",
  },
  pt: {
    hello: "Olá",
    messageFromSenderText: "Esta é uma mensagem do remetente",
    thankYou: "Obrigado!",
    the: "O",
    team: "Equipe",
    signNowText: "Assine agora",
  },
  es: {
    hello: "Hola",
    messageFromSenderText: "Este es un mensaje del remitente.",
    thankYou: "¡Gracias!",
    the: "El",
    team: "equipo",
    signNowText: "Firma ahora",
  },
  ru: {
    hello: "Здравствуйте",
    messageFromSenderText: "Это сообщение от отправителя",
    thankYou: "Спасибо!",
    the: "",
    team: "Команда",
    signNowText: "Подпишите сейчас",
  },
  th: {
    hello: "สวัสดี",
    messageFromSenderText: "นี่คือข้อความจากผู้ส่ง",
    thankYou: "ขอบคุณ!",
    the: "",
    team: "ทีม",
    signNowText: "ลงชื่อทันที",
  },
  tr: {
    hello: "Merhaba",
    messageFromSenderText: "Bu gönderenden gelen bir mesajdır",
    thankYou: "Teşekkür ederim!",
    the: "",
    team: "Takım",
    signNowText: "Şimdi İmzala",
  },
  vi: {
    hello: "Xin chào",
    messageFromSenderText: "Đây là tin nhắn từ người gửi",
    thankYou: "Cảm ơn bạn!",
    the: "",
    team: "Đội",
    signNowText: "Ký ngay",
  },
  ja: {
    hello: "こんにちは。",
    messageFromSenderText: "差出人からのメッセージです",
    thankYou: "ありがとう!",
    the: "ザ、",
    team: "チーム、",
    signNowText: "今すぐ署名してください",
  },
  "zh-CN": {
    hello: "你好，",
    messageFromSenderText: "这是来自发件人的消息",
    thankYou: "谢谢！",
    the: "",
    team: "团队，",
    signNowText: "立即签名",
  },
  ar: {
    hello: "مرحبا،",
    messageFromSenderText: "هذه رسالة من المرسل",
    thankYou: "شكرا لك!،",
    the: "ال،",
    team: "الفريق،",
    signNowText: "وقع الآن،",
  },
  ko: {
    hello: "안녕하세요",
    messageFromSenderText: "발신인이 보낸 메시지입니다",
    thankYou: "감사합니다!",
    the: "그만큼",
    team: "팀",
    signNowText: "지금 서명하세요",
  },
  uk: {
    hello: "Привіт",
    messageFromSenderText: "Це повідомлення від відправника",
    thankYou: "Дякую тобі!",
    the: "The",
    team: "Команда",
    signNowText: "Підписати зараз",
  },
  bg: {
    hello: "Здравейте",
    messageFromSenderText: "Това е съобщение от подателя",
    thankYou: "Благодаря ти!",
    the: "The",
    team: "Екип",
    signNowText: "Подпишете сега",
  },
};

// these are pdf generation languages
// this will break backend if changed
// do not change without discussing with chirag or ayush
export const languages = [
  { key: "ar", value: "Arabic" },
  { key: "ch", value: "Chinese" },
  { key: "nl", value: "Dutch" },
  { key: "en", value: "English" },
  { key: "fr", value: "French" },
  { key: "de", value: "German" },
  { key: "hr", value: "Hebrew" },
  { key: "id", value: "Indonesian" },
  { key: "jp", value: "Japanese" },
  { key: "pt", value: "Portuguese" },
  { key: "es", value: "Spanish" },
  { key: "th", value: "Thai" },
  { key: "uk", value: "Ukrainian" },
  { key: "bg", value: "Bulgarian" },
  { key: "ru", value: "Russian" },
  { key: "ko", value: "Korean" },
];
