// @ts-nocheck
import { Template, generate } from "@pdfme/generator";
import download from "downloadjs";
import React, { Fragment, useEffect, useRef, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { AiFillCrown, AiFillEdit, AiOutlineClockCircle } from "react-icons/ai";
import { BsSearch, BsThreeDots } from "react-icons/bs";
import { FaEye, FaPlus, FaUserEdit } from "react-icons/fa";
import {
  MdAccountCircle,
  MdClose,
  MdFolder,
  MdFolderOpen,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdOutlineDoneAll,
  MdOutlineDownload,
  MdOutlineDownloadDone,
  MdOutlinePending,
} from "react-icons/md";
import { VscEmptyWindow } from "react-icons/vsc";
import { InfinitySpin, TailSpin } from "react-loader-spinner";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTable } from "react-table";
import { Slide, ToastContainer, toast } from "react-toastify";
import { v4 } from "uuid";
import Modal from "../../components/Modal";
import HistoryModal from "../../components/Ui/HistoryModal";
import { BASE_URL } from "../../constants/constants";
import { fetchOwner, getWorkSpaceDetails } from "../../utils";
import axios from "./../../utils/axios";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import JSZip from "jszip";
import { HiDownload } from "react-icons/hi";
import MoveItemModal from "../../components/MoveItemModal";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import SigningOrderDiagram from "../../components/SigningOrderDiagram";
import SendACopy from "../../components/Ui/SendACopy";
import { isFeatureEnabled } from "../../utils/paidFeat";
import DropdownActionsSignature from "../../components/DropdownActions/DropdownActionsPdfs";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import MobileLayoutDashboard from "../../components/MobileLayoutDashboard";
import { FaUserGear } from "react-icons/fa6";
import ShareViaLink from "../../components/ShareViaLink";
import SigningInPerson from "../../components/SigningInPerson";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import { Tooltip } from "react-tooltip";
import getS3PreSignedUrl from "../../utils/getS3PreSignedUrl";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import { fetchAllDocuments } from "../../redux/apis/dashboards";
import DashboardFilter from "../../components/DashboardFilter/DashboardFilter";
import NoSearchResults from "../../components/NoSearchResults/NoSearchResults";
import DashboardBottomNav from "../../components/DashboardBottomNav/DashboardBottomNav";
import GenerateLinksModal from "../../components/GenerateLinks";
import NavButton from "../../components/DashboardFilter/NavButton";
import { getStatusByPayment } from "../../utils/quoteBuilder";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { IoSearch } from "react-icons/io5";
import NoDocumentsV1 from "../../components/NoDocuments/NoDocumentsV1";
import BoloCheckboxMui from "../../components/Common/Checkbox";
import ShareWithTeammates from "../../components/ShareWithTeammates";
import DropboxDriveModal from "../../components/DropboxDriveModal";
import OnedriveModalUpload from "../../components/OndeDriveModalUpload";
import { IoMdInformationCircle } from "react-icons/io";
import { downloadBase64File } from "../../utils/localPdfUtils";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../../components/Common/SelectDropDown/BoloDropDown";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/Common/Dialog/BoloDialog";
const PdfsDashboard = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  // new search based filters
  const { getSearchData, setSearchData, isFilter } = useQueryHook();
  const [owner, setOwner] = useState({});
  const [sharedAdmins, setSharedAdmins] = useState<any[]>([]);
  // const PAGE_LIMIT = 10;
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("ALL");

  // New Combined Dropdown State
  const [dropdownState, setDropdownState] = useState({
    loading: false,
    isModalOpen: false,
    documentHistory: [],
    documentForHistory: [],
    isSendACopyModalOpen: false,
    documentForSendACopy: {
      documentName: "",
      documentUrl: "",
      documentType: "",
    },
    isSigningOrderDiagram: false,
    signingOrderRecipientList: [],
    currOrderDocument: null,
    folderOptions: {
      move: { item: {}, show: false },
    },
    showUpgradeModal: false,
    showGoogleDriveModal: false,
    driveModalDoc: {},
    deletingResponse: null,
    show: {
      formMoreOptions: {
        active: false,
        id: null,
      },
    },
    isSharedDashboard: false,
    workSpaceDetails: getWorkSpaceDetails(),
    upgradeModalType: "",
    showShareViaLink: false,
    showSigningInPerson: false,
    generatedLinksData: [],
    showGenerateLinksModal: false,
    showShare: false,
    activeDoc: null,
    showDropboxDriveModal: false,
    dropboxDoc: {
      documentId: "",
      enabled: false,
      parentFolderName: "",
      parentFolderUrl: "",
      parentFolderId: "",
    },
    showOneDriveModal: false,
    oneDriveDoc: {
      documentId: "",
      enabled: false,
      parentFolderName: "",
      parentFolderUrl: "",
      parentFolderId: "",
      parentFolderDriveId: "",
    },
  });

  const updateDropdownState = (key, value) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // will be used only for shared dashboard
  const [whichAdmin, setWhichAdmin] = useState("");

  const [driveModalDocStatus, setDriveModalDocStatus] = useState(false);
  const [roleInCollaboration, setRoleInCollaboration] = useState("owner");
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [fetchedDocuments, setfetchedDocuments] = useState([]);
  const [formsCount, setFormsCount] = useState(0);
  const [collabrator, setCollabrator] = useState({});
  const [tableData, setTableData] = useState({
    columns: [],
    respondent: [],
  });
  const [rowData, setRowData] = useState([]);

  const [reload, setReload] = useState(false);
  // will be used only for main dashboard
  const [bulkSignaturePdfDownloading, setBulkSignaturePdfDownloading] =
    useState(false);
  const [actionShowingSignature, setActionShowingSignature] = useState<any>("");
  const [lastAction, setLastAction] = useState<any>(false);

  const [openPicker, authResponse] = useDrivePicker();
  const [isDriveParentFolderId, setIsDriveParentFolderId] = useState(false);

  // google drive integration modal

  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });

  const [activeForSendModal, setActiveForSendModal] = useState("email");
  const [emailForSendModal, setEmailForSendModal] = useState({
    to: "",
    subject: "I am sharing a signed pdf with you",
    message: "Consider this response document you asked for..",
  });

  const [showSignerDetailsInTooltip, setShowSignerDetailsInTooltip] =
    useState<any>([]);
  const [showSelectedDownload, setShowSelectedDownload] = useState(false);
  const [selectedPdfs, setSelectedPdfs] = useState([]);
  // Dropbox integration modal

  // Onedrive integration modal

  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;

  const handleSearch = (value) => {};
  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      updateDropdownState("workSpaceDetails", localWorkSpaceDetails);
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  const PurpleSwitch = styled(Switch)(({}) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
      "&:hover": {
        backgroundColor: alpha(
          whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
          0
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
    },
  }));

  const updateIntegrationStatus = async (
    status,
    integrationName,
    documentId,
    parentFolderId,
    parentFolderName,
    parentFolderUrl
  ) => {
    console.log(status);
    try {
      let res = await axios.put(`/common/update-drive-folder`, {
        documentId,
        enabled: status,
        parentFolderId,
        parentFolderName,
        parentFolderUrl,
        documentType: "DOCUMENT",
      });
      toast.success("Updated");
      return true;
    } catch (error) {
      console.log("updateIntegrationStatus error", error);
      return false;
    }
  };

  async function isIntegrationPermissionAvailable() {
    const { data: permissionDetails } = await axios.post(
      `/forms/check-google-permission`,
      {
        ownerEmail: owner?.email,
        isFormDashboard: true,
      }
    );
    return permissionDetails?.isDrivePermission;
  }

  const handleChoice = (choice: string) => {
    if (choice === "forms") {
      // navigate("/create/forms");
      handleCreateForm();
    } else if (choice === "pdf") {
      navigate("/create/pdf");
    } else if (choice === "template") {
      navigate(`/create/form-template?id=${v4()}`);
    } else if (choice === "pdf-template") {
      navigate(`/create/pdf-template?documentId=${v4()}`);
    }
  };

  let navigate = useNavigate();
  //   let paramsDocumentType = searchParams.get("documentType");

  // useEffect(() => {
  //   // let previousSessionPage = localStorage.getItem("pdfsPage");
  //   // if (previousSessionPage) {
  //   //   setPage(parseInt(previousSessionPage));
  //   // } else {
  //   //   setPage(1);
  //   //   localStorage.setItem("pdfsPage", "1");
  //   // }

  //   let pdfsPage = searchParams.get("page");
  //   if (pdfsPage) {
  //     setPage(parseInt(pdfsPage));
  //   }

  //   setFilter("ALL");
  //   setSearchText("");
  //   localStorage.setItem("documentType", "pdfs");
  // }, []);

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);

  useEffect(() => {
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      setSharedAdmins(owner?.addedBy);
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      setWhichAdmin(owner?.addedBy?.[0]?.ownerId?.email);
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      setRoleInCollaboration(owner?.addedBy?.[0]?.role);
  }, [owner, dropdownState.isSharedDashboard]);

  async function handleGetAllDocuments() {
    updateDropdownState("loading", true);
    try {
      let params = getSearchData();
      // console.log(params);
      let data = await fetchAllDocuments({
        page: params.pageNo_,
        limit: params.limit_,
        isStandAloneTemplate: false,
        query: params.query_,
        dateFrom: params.dateFrom_,
        dateTo: params.dateTo_,
        sortBy: params.sortBy_,
        sortOrder: params.sortOrder_,
        filter: params.filter_,
      });
      if (data) {
        setfetchedDocuments(data.documents);
        setFormsCount(data.documentsCount);
      }
      updateDropdownState("loading", false);
    } catch (error) {
      updateDropdownState("loading", false);
      console.log("Error getting documents", error);
    }

    updateDropdownState("loading", false);
  }

  // if page num updates we make a query
  useEffect(() => {
    handleGetAllDocuments();
  }, [
    searchParams,
    getSearchData().rT_,
    getSearchData().pageNo_,
    getSearchData().limit_,
    getSearchData().filter_,
    getSearchData().sortBy_,
    getSearchData().sortOrder_,
    getSearchData().dateFrom_,
    getSearchData().dateTo_,
    reload,
  ]);

  // if searchText updates
  // and is "" then we go back to the state where to page one with no search query
  useEffect(() => {
    if (!searchText && getSearchData().query_) {
      let data = getSearchData();
      setSearchData({ query_: "", pageNo_: data.pageNo_ });
      // handleGetAllDocuments();
    }
  }, [searchText]);

  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };
  const downloadPlainSignature = async (documentId) => {
    if (documentId) {
      try {
        const { data: documentData } = await axios.get(
          `/getOneDocument?documentId=${documentId}`
        );
        let documentDetails = documentData.document;
        let pdfUrl = documentDetails?.documentUrl;

        const responseFile = await fetch(pdfUrl);
        const pdfBuffer = await responseFile.arrayBuffer();

        // Convert PDF buffer to a base64 string
        let pdfBase64 = arrayBufferToBase64(pdfBuffer);
        pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;

        let schemas = documentDetails?.pdfTemplate?.schemas;

        let responseInputs = documentDetails?.pdfTemplate?.inputs;
        const urlToReplace =
          "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature";
        const updatedResponse = await Promise.all(
          responseInputs.map(async (obj: any) => {
            const updatedObj: any = {};
            for (const key in obj) {
              if (Object.hasOwnProperty.call(obj, key)) {
                const value = obj[key];

                if (
                  value ===
                    "https://boloforms-signatures-bucket.s3.ap-south-1.amazonaws.com/signature-template/4vnRsfZ.png" ||
                  value === "https://i.imgur.com/AfyPZQg.png"
                ) {
                  updatedObj[key] = "";
                } else if (
                  typeof value === "string" &&
                  value.includes(urlToReplace)
                  // (value.includes(urlToReplace) ||
                  //   value.includes(
                  //     "https://i.imgur.com/AfyPZQg.png"
                  //   ) ||
                  //   value.includes("https://i.imgur.com/AfyPZQg.png"))
                ) {
                  const responseFile = await fetch(value);
                  const imageBuffer = await responseFile.arrayBuffer();
                  const imageBase64 = arrayBufferToBase64(imageBuffer);
                  const fileExtension = value.split(".").pop();
                  console.log("detectedType", fileExtension);
                  updatedObj[key] =
                    `data:image/${fileExtension ?? "png"};base64,` +
                    imageBase64;
                } else {
                  updatedObj[key] = value;
                }
              }
            }
            return updatedObj;
          })
        );

        console.log("input responses ==>", updatedResponse);

        const inputs = updatedResponse;
        const template: Template = {
          schemas: schemas,
          basePdf: pdfBase64,
        };

        let downloadbledPdf = await generate({ template, inputs });

        const pdfArray = Uint8Array.from(downloadbledPdf);
        const pdfBlob = new Blob([pdfArray], {
          type: "application/pdf",
        });

        download(
          pdfBlob,
          `${documentDetails?.documentName}_${documentId.toString()}.pdf`,
          "application/pdf"
        );
      } catch (error) {
        console.error("An error occurred:", error);
        toast.error(error.message);
        // Handle error, show error message to the user, etc.
      }
    }
  };

  const handleBulkDownload = async (downloadAll = false) => {
    try {
      setBulkSignaturePdfDownloading(true);
      let formDocuments =
        searchResults?.documents?.length > 0 ||
        searchResults?.message === "No result found for this search"
          ? searchResults?.documents
          : fetchedDocuments?.length > 0
          ? fetchedDocuments
          : [];
      // for selected pdf download feat
      if (!downloadAll) {
        formDocuments = selectedPdfs;
      }
      const zip = new JSZip();

      if (!formDocuments || formDocuments?.length === 0) {
        toast.error("No signed documents found for bulk download");
        setBulkSignaturePdfDownloading(false);
        return;
      }

      const pdfsWithFinishedUrl = formDocuments.filter(
        (document) => document?.isAllSigned && document?.finishedPdfUrl
      );

      if (pdfsWithFinishedUrl.length === 0) {
        toast.error("No signed documents found for bulk download");
        setBulkResponsePdfDownloading(false);
        return;
      }

      const documentDetailsForBulkDownload = pdfsWithFinishedUrl.map((doc) => {
        return {
          finishedPdfUrl: doc?.finishedPdfUrl,
          documentName: doc?.documentName,
          documentId: doc?.documentId,
          authorEmail: doc?.authorEmail,
        };
      });

      let { data: preSignedUrlData } = await axios.post(
        "/document/get-presigned-url",
        {
          documentDetails: documentDetailsForBulkDownload,
        }
      );

      const { presignedUrls } = preSignedUrlData;

      // console.log("presignedUrls", presignedUrls);

      const pdfPromises = presignedUrls.map(async (document, index) => {
        const pdfUrlResponse = await fetch(document?.presignedUrl);
        if (!pdfUrlResponse?.ok) {
          throw new Error("Failed to fetch PDF");
        }
        const pdfBlob = await pdfUrlResponse.blob();
        zip.file(
          `${document?.documentName || "signature"}-signed-${index + 1}.pdf`,
          pdfBlob
        );
      });

      await Promise.all(pdfPromises);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      download(
        zipBlob,
        `${
          new Date().toDateString() + " - responses - page (" + page + ")"
        }.zip`
      );
      setBulkSignaturePdfDownloading(false);
      setShowSelectedDownload(false);
      setSelectedPdfs([]);
    } catch (error) {
      setBulkSignaturePdfDownloading(false);
      setShowSelectedDownload(false);
      setSelectedPdfs([]);
      console.log("Error during bulk PDF generation and download:", error);
    }
  };

  const handleOneResponseDelete = async () => {
    updateDropdownState("show", { ...dropdownState.show, oneDeleting: true });

    let id = dropdownState.deletingResponse._id;
    console.log(id);

    try {
      let { data } = await axios.post(
        `/respondents/delete-pdfdocument-response`,
        {
          id,
        }
      );
      // console.log(data);
      if (data?.success) {
        setReload(!reload);
        // setIsDeleted(id); // this setIsDeleted is not defined anywhere
      }
    } catch (error) {
      console.log(error);
    }
    updateDropdownState("show", {
      ...dropdownState.show,
      deleteOneResponseModal: false,
      oneDeleting: false,
    });
  };

  const voidDocumentModal = () => (
    <Fragment>
      <div>
        <div className="py-6 px-6">
          <h2 className="text-lg font-semibold mb-1">
            Are you sure you want to void this document?
          </h2>
          <p className="text-sm">This document will be void.</p>
        </div>
        <button
          disabled={dropdownState.show.oneDeleting}
          onClick={handleVoidDocumentDelete}
          className="mx-6 bg-red-600 text-md text-white px-8 py-2.5 rounded-xl shadow mr-4 mb-4 hover:opacity-80"
        >
          Void Document
        </button>
      </div>
      {/* )} */}
      <div
        onClick={() => {
          updateDropdownState("show", {
            ...dropdownState.show,
            voidDocumentModal: false,
          });
        }}
        className="absolute top-0 right-0 cursor-pointer"
      >
        <MdClose className="text-2xl m-4" />
      </div>
    </Fragment>
  );

  const handleVoidDocumentDelete = async () => {
    updateDropdownState("show", { ...dropdownState.show, onDeleting: true });

    let id = dropdownState.deletingResponse._id;
    // console.log(id);
    let owner = JSON.parse(localStorage.getItem("owner"));

    try {
      const response = getIpAddressLocal();
      const ipData = await response;
      let { data } = await axios.post(
        `/respondents/void-pdfdocument-response`,
        {
          id,
          email: owner?.email,
          ip: ipData?.ip,
        }
      );
      // console.log(data);
      if (data?.success) {
        setReload(!reload);
        // setIsDeleted(id); // this setIsDeleted is not defined anywhere
      }
    } catch (error) {
      console.log(error);
    }
    updateDropdownState("show", {
      ...dropdownState.show,
      voidDocumentModal: false,
      oneDeleting: false,
    });
  };

  const GoogleDriveModal = () => {
    let isFreePlan =
      owner?.planhistory[owner?.planhistory.length - 1]?.planId === "FREE_PLAN";

    return (
      <div className="md:w-[600px] py-2 md:py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            updateDropdownState("showGoogleDriveModal", false);
          }}
          className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <h2 className="mb-1 text-lg md:text-xl font-semibold">
          Google Drive Integration
        </h2>
        <p className="text-xs text-gray-600 pb-2 mb-6 border-b-2 border-gray-200">
          Select drive folder to save the response pdf files to your Google
          Drive.
        </p>
        {!dropdownState.driveModalDoc?.isDrivePermission && !isFreePlan && (
          <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
            <h2 className="mb-1 text-xs md:text-sm">
              You are not connected to Google Drive
            </h2>
            <p className="text-xs text-gray-500 mb-4">
              Connect your Google Drive to save the finished PDF to your
              specified Google Drive folder.
            </p>
            <button
              className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
              onClick={() => {
                window.open(
                  `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/dashboard/pdfs`,
                  "_self"
                );
              }}
            >
              Connect to Google Drive
            </button>
          </div>
        )}
        {dropdownState.driveModalDoc?.isDrivePermission && !isFreePlan && (
          <div className="flex justify-between">
            {!dropdownState.driveModalDoc?.parentFolderId ? (
              <div>
                <h2 className="mb-1 text-xs md:text-smfont-medium text-gray-700">
                  Your document is not connected to any Google Drive Folder
                </h2>
                <p className="text-xs text-gray-500 mb-4">
                  Pick a folder to save your form responses as PDFs.
                </p>
                <button
                  className="w-[50%] md:w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                  onClick={async () => {
                    await handleGooglePicker(
                      dropdownState.driveModalDoc?.document?.documentId
                    );
                  }}
                >
                  Select Folder
                </button>
              </div>
            ) : (
              <div>
                <h2 className="mb-1 text-xs md:text-sm font-medium text-gray-700">
                  Your document is connected to Google Drive
                </h2>
                <p className="text-xs text-gray-500 mb-4">
                  Your document responses will be saved to this Drive Folder:{" "}
                  <span
                    className="text-primary font-semibold cursor-pointer"
                    onClick={() => {
                      window.open(
                        dropdownState.driveModalDoc?.parentFolderUrl,
                        "_blank"
                      );
                    }}
                  >
                    {dropdownState.driveModalDoc?.parentFolderName}
                  </span>
                </p>
                <div className="w-full flex justify-between gap-4">
                  <button
                    disabled={isFreePlan}
                    className="w-full border-2 border-primary bg-white inset-2 text-primary px-4 py-2 rounded-md text-xs mr-1"
                    onClick={async () => {
                      await handleGooglePicker(
                        dropdownState.driveModalDoc?.document?.documentId
                      );
                    }}
                  >
                    Update Folder
                  </button>
                  <button
                    className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                    onClick={() => {
                      window.open(
                        dropdownState.driveModalDoc?.parentFolderUrl,
                        "_blank"
                      );
                    }}
                  >
                    View Folder
                  </button>
                </div>
              </div>
            )}
            <PurpleSwitch
              checked={driveModalDocStatus}
              onChange={async (e) => {
                await updateIntegrationStatus(
                  e.target.checked,
                  "googleDrive",
                  dropdownState.driveModalDoc?.document?.documentId,
                  dropdownState.driveModalDoc?.parentFolderId,
                  dropdownState.driveModalDoc?.parentFolderName,
                  dropdownState.driveModalDoc?.parentFolderUrl
                );
                setDriveModalDocStatus(!driveModalDocStatus);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  React.useMemo(() => {
    let cols = [
      // {
      //   name: "#",
      //   Header: "#",
      //   accessor: "#", // accessor is the "key" in the data
      // },
      {
        name: "Document",
        Header: "Document",
        accessor: "Document", // accessor is the "key" in the data
      },
      {
        name: "Created",
        Header: "Created",
        accessor: "Created",
      },
      {
        name: "Created By",
        Header: "Created By",
        accessor: "Created By",
      },
      {
        name: "Status",
        Header: "Status",
        accessor: "Status",
      },
      {
        name: "Signed",
        Header: "Signed",
        accessor: "Signed",
      },
      {
        name: "Download",
        Header: "Download",
        accessor: "Download",
      },
      {
        name: "Actions",
        Header: "Actions",
        accessor: "Actions",
      },
    ];
    if (showSelectedDownload) {
      let newCols = [
        {
          name: "Select",
          Header: "Select",
          accessor: "Select", // accessor is the "key" in the data
        },
        ...cols,
      ];
      cols = newCols;
    }
    console.log("cols", cols);
    if (window.innerWidth < 640) {
      cols = cols.filter(
        (col) => col.name === "Download" || col.name === "Forms"
      );
    }

    // cols.push({
    //   accessor: "hidden",
    // });

    // @ts-ignore
    setTableData((prev) => {
      return {
        ...prev,
        columns: cols,
      };
    });
  }, [showSelectedDownload]);

  const handleGooglePicker = async (documentId) => {
    let isSufficientPermission = await isIntegrationPermissionAvailable();

    if (!isSufficientPermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/dashboard/pdfs`,
        "_self"
      );
      return;
    }

    let clientId, developerKey;

    try {
      const { data } = await axios.get(
        `${BASE_URL}/common/get-google-credentials`
      );
      clientId = data?.clientId;
      developerKey = data?.developerKey;
    } catch (error) {
      console.error(
        "Error fetching client ID and developer key from the backend",
        error
      );
    }

    if (clientId && developerKey) {
      openPicker({
        clientId,
        developerKey,
        viewId: "FOLDERS",
        token: owner?.integrationTokens?.google?.oAuthToken,
        setIncludeFolders: true,
        setSelectFolderEnabled: true,
        showUploadView: false,
        supportDrives: true,
        multiselect: false,
        appId: 204560571245,
        callbackFunction: async (driveData) => {
          if (driveData.action === "cancel") {
            console.log("User clicked cancel/close button");
          }

          if (driveData?.docs) {
            try {
              let res = await axios.put(
                `${BASE_URL}/common/update-drive-folder`,
                {
                  documentId,
                  parentFolderId: driveData?.docs[0]?.id,
                  parentFolderName: driveData?.docs[0]?.name,
                  parentFolderUrl: driveData?.docs[0]?.url,
                  enabled: true,
                  documentType: "DOCUMENT",
                }
              );
              toast.success("Folder Updated");
              setIsDriveParentFolderId(true);
              setDriveModalDocStatus(true);
              updateDropdownState("driveModalDoc", {
                ...dropdownState.driveModalDoc,
                parentFolderId: driveData?.docs[0]?.id,
                parentFolderName: driveData?.docs[0]?.name,
                parentFolderUrl: driveData?.docs[0]?.url,
              });
            } catch (error) {
              console.log("error", error);
              toast.error("Something went wrong");
            }
          }
        },
      });
    }
  };

  const ActionsPDFOptions = ({ i, documents, documentData }: any) => {
    const selfRef = useRef();
    const handleOutsideClick = (e) => {
      if (actionShowingSignature !== documentData.documentId) {
        return;
      }
      if (selfRef.current !== null) {
        if (selfRef.current.contains(e.target)) return;
      }
      setActionShowingSignature(null);
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div className="" ref={selfRef}>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <BoloButton
              size={"icon-sm"}
              variant="gray-shadow"
              className="bg-white"
              // className={`m-auto hover:text-gray-800 text-gray-600`}
              onClick={(e) => {
                if (i >= documents?.length - 2) {
                  setLastAction(documents?.length - i);
                } else {
                  setLastAction(0);
                }
                e.stopPropagation();
                // setShowActions(true);
                if (actionShowingSignature === documentData.documentId) {
                  setActionShowingSignature(null);
                  return;
                }
                setActionShowingSignature(documentData.documentId);
              }}
            >
              <BsThreeDots />
            </BoloButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            sideOffset={5}
            className="p-0 w-52 max-h-60 overflow-y-auto action-dropdown"
          >
            <DropdownActionsSignature
              owner={owner}
              document={documentData}
              isIntegrationPermissionAvailable={
                isIntegrationPermissionAvailable
              }
              dropdownState={dropdownState}
              updateDropdownState={updateDropdownState}
            />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  };

  React.useMemo(() => {
    let documents =
      searchResults?.documents?.length > 0 ||
      searchResults?.message === "No result found for this search"
        ? searchResults?.documents
        : fetchedDocuments?.length > 0
        ? fetchedDocuments
        : [];
    setRowData(documents);

    let data = documents?.map((document, i) => {
      let ownerName = document?.ownerId?.name || "Sender";
      let lastModified = new Date(document?.updatedAt).toLocaleString();
      let ownerEmail = document?.authorEmail;
      const rolesArray = document?.respondentsOfDoc?.map((signer) => {
        let role = {
          colour: signer?.colour,
          status: signer?.status,
          name: signer?.name,
          email: signer?.email,
          owner: ownerName,
          lastModified: lastModified,
          ownerEmail: ownerEmail,
          hasDeclined: signer?.hasDeclined,
          accessCode: signer?.accessCode,
          phone: signer?.phone,
        };
        return role;
      });

      return {
        id: document.documentId,
        "#": (
          <span className="bg-gray-300 bg-opacity-40 shadow px-3 py-1.5 rounded-md">
            {getSearchData().pageNo_ * getSearchData().limit_ +
              i +
              1 -
              getSearchData().limit_}
          </span>
        ),
        Select: showSelectedDownload && (
          <BoloCheckboxMui
            onChange={(e) => {
              const isChecked = e.target.checked;
              setSelectedPdfs((prevSelected: any) => {
                let newSelected = [...prevSelected];
                if (
                  isChecked &&
                  !newSelected.some(
                    (item) => item.documentId === document?.documentId
                  )
                ) {
                  newSelected.push(row);
                } else if (!isChecked) {
                  newSelected = newSelected.filter(
                    (item: any) => item.documentId !== document?.documentId
                  );
                }
                return newSelected;
              });
            }}
            disabled={!document?.isAllSigned}
            checked={selectedPdfs.some(
              (item) => item.documentId === document?.documentId
            )}
          />
        ),
        Document: (
          <div className="flex gap-1 items-center text-gray-700 pl-4 text-sm max-w-[100%]">
            {dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
              document?.folder?.name && (
                <>
                  <MdFolder
                    style={{
                      color: document?.folder?.color || "#3c4043",
                    }}
                    className="text-base min-w-[20px]"
                  />
                  <Link
                    className="font-semibold"
                    to={
                      dropdownState.isSharedDashboard
                        ? `/shared-with-me/folder/items?folderId=${document?.folder?.folderId}`
                        : `/folder/items?folderId=${document?.folder?.folderId}`
                    }
                  >
                    {document?.folder?.name}
                  </Link>
                  <span>/</span>
                </>
              )}
            <span
              data-tooltip-id={`pdf-file-name-${document?._id}`}
              data-tooltip-offset={10}
              translate="no"
              className="text-gray-800 text-sm flex items-center truncate"
            >
              {/* <PiFilePdfThin className="text-2xl text-gray-600 mr-2 inline-block" /> */}
              <span className="truncate">
                {document?.documentName || document?.name || "Untitled"}
              </span>
            </span>
            <CustomTooltip
              helpers={{
                id: `pdf-file-name-${document?._id}`,
                content: document?.documentName || document?.name || "Untitled",
                place: "top",
              }}
            />
          </div>
        ),

        Created: (
          <div>
            <h3 className="text-gray-600">
              {new Date(document?.createdAt).toDateString()}
            </h3>
            <p className="text-gray-400 text-xs mt-1">
              {new Date(document?.createdAt).toLocaleTimeString()}
            </p>
          </div>
        ),
        "Created By": (
          <div className="flex items-center">
            <div className="mr-4 w-8 aspect-square bg-white rounded-full overflow-hidden border cursor-pointer">
              <img
                src={
                  document?.createdBy?.profilePic ||
                  owner?.profilePic ||
                  "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
                }
                alt="profile"
                referrerPolicy="no-referrer"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h3 className="text-gray-600">
                {document?.createdBy?.name ?? owner?.name ?? "User"}
              </h3>
              <p className="text-gray-400 text-xs mt-1">
                {document?.createdBy?.email ?? owner?.email}
              </p>
            </div>
          </div>
        ),
        Status: (
          <div className="text-[10px]">
            <div
              data-tip={`the Document is in ${document.status} state`}
              className="flex flex-row items-center w-fit"
            >
              {/* <div
                style={{
                  backgroundColor: getStatusByPayment({ document }).color,
                }}
                className={`w-2 h-2 rounded-full ${
                  // document.status === "SIGNED" || document.isAllSigned === true
                  //   ? "bg-green-500"
                  //   : document.status === "DRAFT"
                  //   ? "bg-yellow-500"
                  //   : "bg-red-500"
                  ""
                }`}
              ></div>
              <p className=" text-gray-500 ml-2">
                {document.isAllSigned ? "SIGNED" : document.status}
                {document?.sentViaSMS && (
                  <span className="text-xs text-gray-500"> (Sent via SMS)</span>
                )}
              ></div> */}
              <p
                className=" text-gray-500 rounded px-2 font-semibold tracking-wider"
                style={{
                  backgroundColor: getStatusByPayment({ document }).backGround,
                  color: getStatusByPayment({ document }).color,
                }}
              >
                {getStatusByPayment({ document }).status}
              </p>
            </div>
            <p className=" text-gray-500 leading-4">
              {getStatusByPayment({ document }).message}
            </p>
            <button
              className="text-blue-500 hover:underline"
              data-tooltip-id="single-pdf-details-tooltip"
              data-tooltip-place="bottom-start"
              onMouseEnter={() => {
                setShowSignerDetailsInTooltip(rolesArray);
              }}

              // there should be a way to setShowSignerDetailsInTooltip as empty array when mouse leaves
              // but @SmurtiranjanSahoo removed the onMouseLeave event on template response dashboard so there must be some reason, thus i am not adding it here
            >
              Show Details
            </button>
          </div>
        ),
        Signed: (
          <div
            data-tip={`This document is signed by ${document.totalSignedRespondants} people out of ${document.totalRespondants}`}
            className="flex flex-row items-center w-fit"
          >
            <div className={``}>
              {document.totalSignedRespondants ===
                document.totalRespondants && (
                <MdOutlineDownloadDone className="text-lg text-green-400" />
              )}
              {document.totalSignedRespondants !==
                document.totalRespondants && (
                <MdOutlinePending className="text-lg text-yellow-400" />
              )}
            </div>
            <p className="text-[10px] text-gray-500 ml-2">
              {document.totalSignedRespondants} / {document.totalRespondants}
            </p>
          </div>
        ),
        Download: (
          <div className="flex gap-2 items-center">
            <BoloButton
              // disabled={document.isAllSigned !== true}
              disabled={
                document.status === "DRAFT" || document.status === "VOID"
              }
              size={"sm"}
              variant="tertiary"
              className="text-xs md:text-xs"
              onClick={async () => {
                new MixPanel().track("PDF", {
                  pdf_action: "pdf_download",
                  paid: false,
                });
                new MixPanel().increment("pdf_download");
                // setLoading(true);
                if (document.finishedPdfUrl) {
                  // window.open(document.finishedPdfUrl, "_blank");

                  let url = await getS3PreSignedUrl(document?.finishedPdfUrl);
                  window.open(url, "_blank");
                } else {
                  // await downloadPlainSignature(document.documentId);
                  let url = `/downlod-semicomplete-pdf?documentId=${
                    document?.documentId
                  }&type=${"PDF"}`;
                  let { data } = await axios.get(url);
                  if (data?.success) {
                    downloadBase64File(
                      data?.pdfBytes,
                      `${document?.documentId}.pdf`
                    );
                    // window.open(data?.pdfBytes, "_blank");
                  }
                }
                // setLoading(false);
              }}
              // className={`flex flex-row justify-center items-center text-center hover:opacity-90 text-xs rounded-md capitalize border px-3 py-2.5 bg-secondary/80 text-primary font-medium border-primary/50 hover:border-primary shadow-sm`}
            >
              {/* <AiFillEye className="text-2xl text-primary hover:opacity-85 mr-2" /> */}
              Download
            </BoloButton>
            {document.isAllSigned !== true &&
            !(document.status === "DRAFT" || document.status === "VOID") ? (
              <IoMdInformationCircle
                data-tooltip-id="incomplete-download"
                className="text-gray-500"
                size={16}
              />
            ) : null}
            <CustomTooltip
              helpers={{
                id: "incomplete-download",
                content: "Incomplete Download",
                place: "right",
              }}
            />
          </div>
        ),
        Actions: (
          <ActionsPDFOptions documents={documents} documentData={document} />
        ),
      };
    });

    setTableData((prev) => {
      return {
        ...prev,
        data,
      };
    });
  }, [
    fetchedDocuments,
    actionShowingSignature,
    searchResults,
    dropdownState.show.oneDeleting,
    showSelectedDownload,
    selectedPdfs,
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //@ts-ignore
    useTable({ columns: tableData.columns ?? [], data: tableData.data ?? [] });

  // console.log({ rowData, documentType });

  /* This is checking if the user is already logged in. If the user is logged in, it will redirect to
  the dashboard. */
  let user = localStorage.getItem("owner");
  if (!user) {
    console.log("user not found");
    return <Navigate to="/login" />;
  }

  console.log({ tableData, headerGroups, rows });

  return (
    <div
      className="-m-4 bg-white h-[100%]"
      // style={{
      //   paddingBottom:
      //     lastAction && actionShowingSignature ? `${250}px` : "64px",
      // }}
    >
      <Helmet>
        <title translate="no">Pdfs Dashboard</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Pdfs Dashboard" />
        <meta
          name="description"
          content="Centralize and manage all your PDF effortlessly on the Pdf Dashboard for streamlined organization and accessibility."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/dashboard/pdfs`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Pdfs Dashboard - Boloforms`} />
        <meta
          property="og:description"
          content="Centralize and manage all your PDF effortlessly on the Pdf Dashboard for streamlined organization and accessibility."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/dashboard/pdfs"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {dropdownState.showShareViaLink && (
        <ShareViaLink
          setShowShareViaLink={(value) => {
            updateDropdownState("showShareViaLink", value);
          }}
          type="PDF"
          sendToSigners={() => {
            return;
          }}
          sending={false}
          documentId={dropdownState.generatedLinksData?.documentId}
          generatedLinks={
            dropdownState.generatedLinksData?.respondentsOfDoc || []
          }
        />
      )}
      {dropdownState.showSigningInPerson && (
        <SigningInPerson
          setShowSigningInPerson={(value) => {
            updateDropdownState("showSigningInPerson", value);
          }}
          type="PDF"
          documentId={dropdownState.generatedLinksData?.documentId}
          isSigningOrderData={
            dropdownState.generatedLinksData?.isSigningOrderData
          }
          generatedLink={
            dropdownState.generatedLinksData?.respondentsOfDoc || []
          }
          sending={false}
        />
      )}
      {dropdownState.showGenerateLinksModal && (
        <GenerateLinksModal
          setShowGenerateLinksModal={(value) => {
            updateDropdownState("showGenerateLinksModal", value);
          }}
          setShowShareViaLink={(value) => {
            updateDropdownState("showShareViaLink", value);
          }}
          docType="PDF"
          documentId={dropdownState.generatedLinksData?.documentId}
          document={dropdownState.generatedLinksData}
          setGeneratedLinksData={(value) => {
            updateDropdownState("generatedLinksData", value);
          }}
        />
      )}
      {dropdownState.showUpgradeModal && (
        <PleaseUpgradeModal
          show={dropdownState.showUpgradeModal}
          setShow={(value) => {
            updateDropdownState("showUpgradeModal", value);
          }}
          data={upgradeModalData}
          blockingType={dropdownState.upgradeModalType}
        />
      )}
      {dropdownState.isSigningOrderDiagram && (
        <SigningOrderDiagram
          recipientList={dropdownState.signingOrderRecipientList}
          setIsSigningOrderDiagram={(value) => {
            updateDropdownState("isSigningOrderDiagram", value);
          }}
          isSigningOrderDiagram={dropdownState.isSigningOrderDiagram}
          currDocument={dropdownState.currOrderDocument}
        />
      )}
      {dropdownState.folderOptions?.move?.show && (
        <MoveItemModal
          item={dropdownState.folderOptions?.move?.item}
          fromFolder={dropdownState.folderOptions.move.fromFolder}
          setShow={(value) => {
            updateDropdownState("folderOptions", {
              ...dropdownState.folderOptions,
              move: {
                ...dropdownState.folderOptions.move,
                show: value,
                fromFolder: null,
              },
            });
          }}
          stateUpdate={(value) => {}}
          isAdd={dropdownState.folderOptions.isAdd}
          setReload={setReload}
        />
      )}
      <ShareWithTeammates
        helpers={{
          documentType: "pdfs",
          documentName:
            dropdownState.activeDoc?.documentName ||
            dropdownState.activeDoc?.name ||
            "Untitled",
          documentId: dropdownState.activeDoc?.documentId,
          isOpen: dropdownState.showShare,
          setIsOpen: (value) => {
            updateDropdownState("showShare", value);
          },
        }}
      />
      <div
        className={`px-3 md:px-4 flex-col md:flex-row md:h-[40px] my-4 sm:my-6 text-gray-600 w-full flex gap-2 md:justify-between items-center`}
      >
        <div className="flex items-center gap-2 w-full md:w-fit">
          <form className={`h-full text-xs w-full`}>
            <div
              className={`h-full flex flex-row rounded-md overflow-hidden ml-auto  md:w-[500px] border`}
            >
              <input
                placeholder="Search by document name, signer name, or email"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full h-full py-2 px-4   outline-none text-gray-700 "
              />
              <button
                className="min-w-[40px] flex justify-center items-center text-gray-500 cursor-pointer hover:opacity-90"
                onClick={(e) => {
                  e.preventDefault();
                  new MixPanel().track("PDF", {
                    pdf_action: "pdf_search",
                    paid: false,
                  });
                  new MixPanel().increment("pdf_search");
                  // getDocumentsBySearch(e, "PDF");
                  setSearchData({ query_: searchText, pageNo_: 1 });
                  handleGetAllDocuments();
                }}
              >
                <IoSearch className="text-xl" />
              </button>
            </div>
          </form>
          <div className="hidden md:flex">
            <DashboardFilter />
          </div>
        </div>
        <div className={`h-full flex flex-row  items-center w-[100%] md:w-fit`}>
          <div
            className={`flex gap-4 justify-between md:justify-start w-[100%] md:w-fit`}
          >
            <div className="hidden md:flex h-[32px] flex-row">
              {showSelectedDownload ? (
                <>
                  <BoloButton
                    size={"sm"}
                    variant="gray-shadow"
                    className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
                    onClick={async (e) => {
                      e.stopPropagation();
                      setBulkSignaturePdfDownloading(true);
                      await handleBulkDownload(true);
                      setBulkSignaturePdfDownloading(false);
                    }}
                    disabled={dropdownState.loading}
                  >
                    <MdOutlineDownload className="" size={16} />
                    <span className="">
                      {bulkSignaturePdfDownloading
                        ? "Downloading"
                        : "Download All"}
                    </span>
                  </BoloButton>
                  <BoloButton
                    onClick={async (e) => {
                      e.stopPropagation();
                      setBulkSignaturePdfDownloading(true);
                      await handleBulkDownload();
                      setBulkSignaturePdfDownloading(false);
                    }}
                    disabled={dropdownState.loading}
                    size={"sm"}
                    variant="gray-shadow"
                    className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
                  >
                    <MdOutlineDownload className="" size={16} />
                    {bulkSignaturePdfDownloading ? "Downloading" : "Selected"}
                  </BoloButton>
                  <BoloButton
                    onClick={async (e) => {
                      setShowSelectedDownload(false);
                      setSelectedPdfs([]);
                    }}
                    disabled={dropdownState.loading}
                    size={"sm"}
                    variant="gray-shadow"
                    className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
                  >
                    <span className=" md:block text-red-400">Cancel</span>
                  </BoloButton>
                </>
              ) : (
                <BoloButton
                  variant={"gray-shadow"}
                  size={"icon-xs"}
                  data-tooltip-id="bulk-pdf-download"
                  onClick={async (e) => {
                    new MixPanel().track("PDF", {
                      pdf_action: "pdf_bulk_download",
                      paid: true,
                    });
                    new MixPanel().increment("pdf_bulk_download");
                    const isBulkDownloadEnabled = await isFeatureEnabled(
                      "BULK_DOWNLOAD"
                    );
                    if (!isBulkDownloadEnabled) {
                      updateDropdownState("showUpgradeModal", true);
                      updateDropdownState("upgradeModalType", "BULK_DOWNLOAD");
                      return;
                    }
                    setShowSelectedDownload(true);
                    e.stopPropagation();
                    // setBulkSignaturePdfDownloading(true);
                    // await handleBulkDownload();
                    // setBulkSignaturePdfDownloading(false);
                  }}
                  disabled={dropdownState.loading}
                  className="flex items-center justify-center h-[32px] w-[32px]"
                >
                  {bulkSignaturePdfDownloading ? (
                    <TailSpin height={"20"} width={"20"} color={"#dfdfdf"} />
                  ) : (
                    <HiDownload size={18} />
                  )}
                </BoloButton>
              )}
            </div>
            {!showSelectedDownload && (
              <BoloButton
                size={"sm"}
                className="h-[32px] whitespace-nowrap text-xs md:text-sm flex gap-3 items-center"
                disabled={
                  !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
                  dropdownState.workSpaceDetails?.currentWorkSpaceRole ==
                    "readonly"
                  // || dropdownState.workSpaceDetails?.currentWorkSpaceRole == "editor"
                }
                onClick={async () => {
                  navigate("/create/pdf");
                }}
              >
                <FaPlus /> Create Signature PDF
              </BoloButton>
            )}

            <div
              className={`flex md:hidden gap-3 ${
                showSelectedDownload && "justify-between w-full"
              }`}
            >
              <div className="flex  h-[32px] flex-row">
                {showSelectedDownload ? (
                  <>
                    <BoloButton
                      size={"sm"}
                      variant="gray-shadow"
                      className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
                      onClick={async (e) => {
                        e.stopPropagation();
                        setBulkSignaturePdfDownloading(true);
                        await handleBulkDownload(true);
                        setBulkSignaturePdfDownloading(false);
                      }}
                      data-tooltip-id="download-all"
                      disabled={dropdownState.loading}
                    >
                      <MdOutlineDownload className="" size={16} />
                      <span className="">
                        {bulkSignaturePdfDownloading ? "Downloading" : "All"}
                      </span>
                    </BoloButton>
                    <BoloButton
                      size={"sm"}
                      variant="gray-shadow"
                      className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
                      onClick={async (e) => {
                        e.stopPropagation();
                        setBulkSignaturePdfDownloading(true);
                        await handleBulkDownload();
                        setBulkSignaturePdfDownloading(false);
                      }}
                      disabled={dropdownState.loading}
                    >
                      <MdOutlineDownload className="" size={16} />
                      {bulkSignaturePdfDownloading ? "Downloading" : "Selected"}
                    </BoloButton>
                    <BoloButton
                      onClick={async (e) => {
                        setShowSelectedDownload(false);
                        setSelectedPdfs([]);
                      }}
                      disabled={dropdownState.loading}
                      size={"sm"}
                      variant="gray-shadow"
                      className="flex items-center gap-2 h-[32px] text-xs md:text-xs ml-2"
                    >
                      <span className=" md:block text-red-400">Cancel</span>
                    </BoloButton>
                    <CustomTooltip
                      helpers={{
                        id: "download-all",
                        content: "Download All",
                        place: "top",
                      }}
                    />
                  </>
                ) : (
                  <BoloButton
                    variant={"gray-shadow"}
                    size={"icon-xs"}
                    data-tooltip-id="bulk-pdf-download"
                    onClick={async (e) => {
                      new MixPanel().track("PDF", {
                        pdf_action: "pdf_bulk_download",
                        paid: true,
                      });
                      new MixPanel().increment("pdf_bulk_download");
                      const isBulkDownloadEnabled = await isFeatureEnabled(
                        "BULK_DOWNLOAD"
                      );
                      if (!isBulkDownloadEnabled) {
                        updateDropdownState("showUpgradeModal", true);
                        updateDropdownState(
                          "upgradeModalType",
                          "BULK_DOWNLOAD"
                        );
                        return;
                      }
                      setShowSelectedDownload(true);
                      e.stopPropagation();
                      // setBulkSignaturePdfDownloading(true);
                      // await handleBulkDownload();
                      // setBulkSignaturePdfDownloading(false);
                    }}
                    disabled={dropdownState.loading}
                    className="flex items-center justify-center h-[32px] w-[32px]"
                  >
                    {bulkSignaturePdfDownloading ? (
                      <TailSpin height={"20"} width={"20"} color={"#dfdfdf"} />
                    ) : (
                      <HiDownload size={18} />
                    )}
                    <CustomTooltip
                      helpers={{
                        id: "bulk-pdf-download",
                        content: "Bulk PDF Download",
                        place: "top",
                      }}
                    />
                  </BoloButton>
                )}
              </div>
              <DashboardFilter />
            </div>
          </div>
        </div>
      </div>
      {/* PC layout*/}
      {window.innerWidth > 768 && (
        <div
          className={`hidden md:block relative`}
          style={{
            overflowY:
              fetchedDocuments?.length === 0 && !dropdownState.loading
                ? "hidden"
                : "auto",
            maxHeight:
              fetchedDocuments?.length === 0 && !dropdownState.loading
                ? 0
                : "calc(100% - 130px)",
            height:
              fetchedDocuments?.length === 0 && !dropdownState.loading
                ? 0
                : "calc(100% - 130px)",
          }}
          id="mini-pdf-custom-scroll"
        >
          <table {...getTableProps()} className="w-full pt-[40px]">
            <thead className="max-h-[40px] h-[40px] sticky top-0 bg-white shadow z-20">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`${
                        column.name === "Document" ? "pl-5" : ""
                      } px-3 py-2 text-left text-gray-600 font-semibold text-xs truncate`}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {dropdownState.loading ? (
                <tr role={"row"} align={"center"} className={"w-full"}>
                  <td className={"w-full"} colSpan={8}>
                    <div className="p-8">
                      <BoloLoader />
                    </div>
                  </td>
                </tr>
              ) : (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="hover:bg-secondary/40 cursor-pointer border-t"
                      onClick={() => {
                        if (showSelectedDownload) {
                          let document = rowData[row?.id];
                          if (!document?.isAllSigned) {
                            return;
                          }
                          if (
                            selectedPdfs.some(
                              (item) =>
                                item?.documentId === document?.documentId
                            )
                          ) {
                            setSelectedPdfs((prev) =>
                              prev.filter(
                                (item) =>
                                  item?.documentId !== document?.documentId
                              )
                            );
                          } else {
                            setSelectedPdfs((prev) => [...prev, document]);
                          }
                        }
                      }}
                      aria-disabled={
                        showSelectedDownload && !rowData[row?.id]?.isAllSigned
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${
                              cell.column.name === "#"
                                ? "text-center"
                                : "min-w-[150px] max-w-[300px]"
                            } px-2 py-3 text-sm truncate`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* Mobile Layout */}
      {window.innerWidth <= 768 && (
        <MobileLayoutDashboard
          documentType="PDF"
          rowData={rowData}
          ActionsPDFOptions={ActionsPDFOptions}
          downloadPlainSignature={downloadPlainSignature}
          showSelectedDownload={showSelectedDownload}
          setSelectedPdfs={setSelectedPdfs}
          selectedPdfs={selectedPdfs}
        />
      )}
      {/* //? condition was previously like this searchResults?.message === "No result found for this search" */}
      {fetchedDocuments?.length === 0 && isFilter && <NoSearchResults />}
      {fetchedDocuments?.length === 0 && !isFilter ? (
        <div className="w-full flex flex-col items-center py-12 text-gray-600">
          {!dropdownState.loading && (
            <NoDocumentsV1
              resourceType="DOCUMENT"
              cb={() => {
                if (
                  dropdownState.workSpaceDetails?.currentWorkSpaceRole !==
                  "readonly"
                ) {
                  handleChoice("pdf");
                } else {
                  toast.error("Readonly role are not allowed to create PDFs");
                }
              }}
            />
          )}
        </div>
      ) : (
        <DashboardBottomNav count={formsCount} />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
      {dropdownState.isModalOpen && (
        <HistoryModal
          setIsModalOpen={(value) => {
            updateDropdownState("isModalOpen", value);
          }}
          history={dropdownState.documentHistory}
          documentDetails={dropdownState.documentForHistory}
        />
      )}
      {dropdownState.showGoogleDriveModal && (
        <Modal modalContent={GoogleDriveModal()} />
      )}
      {dropdownState.showDropboxDriveModal && (
        <DropboxDriveModal
          setShowDropboxDriveModal={(value) => {
            updateDropdownState("showDropboxDriveModal", value);
          }}
          driveModalDoc={dropdownState.dropboxDoc}
          setDropboxModalDoc={(value) => {
            updateDropdownState("dropboxDoc", value);
          }}
        />
      )}
      {dropdownState.showOneDriveModal && (
        <OnedriveModalUpload
          setOnedriveModalDoc={(value) => {
            updateDropdownState("oneDriveDoc", value);
          }}
          onedriveModalDoc={dropdownState.oneDriveDoc}
          setShowOneDriveModal={(value) => {
            updateDropdownState("showOneDriveModal", value);
          }}
        />
      )}
      <Dialog
        open={dropdownState.show.deleteOneResponseModal}
        onOpenChange={(value) => {
          updateDropdownState("show", {
            ...dropdownState.show,
            deleteOneResponseModal: value,
          });
        }}
      >
        <DialogContent className="">
          <DialogHeader>
            <DialogTitle>Remove Document</DialogTitle>
          </DialogHeader>
          <div>
            <h2 className="text-sm md:text-base font-medium md:leading-[2] leading-[2]">
              Are you sure you want to delete this document?
            </h2>
            <p className="text-[10px] md:text-xs text-gray-600">
              This document will be deleted.
            </p>
          </div>

          <DialogFooter>
            <BoloButton
              variant={"danger-border"}
              size={"sm"}
              onClick={handleOneResponseDelete}
              disabled={dropdownState.show.oneDeleting}
            >
              {dropdownState.show.oneDeleting ? "Deleting..." : "Delete"}
            </BoloButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={dropdownState.show.voidDocumentModal}
        onOpenChange={(value) => {
          updateDropdownState("show", {
            ...dropdownState.show,
            voidDocumentModal: value,
          });
        }}
      >
        <DialogContent className="">
          <DialogHeader>
            <DialogTitle>Void Document</DialogTitle>
          </DialogHeader>
          <div>
            <h2 className="text-sm md:text-base font-medium md:leading-[2] leading-[2]">
              Are you sure you want to void this document?
            </h2>
            <p className="text-[10px] md:text-xs text-gray-600">
              This document will be void.
            </p>
          </div>

          <DialogFooter>
            <BoloButton
              variant={"danger-border"}
              size={"sm"}
              disabled={dropdownState.show.oneDeleting}
              onClick={handleVoidDocumentDelete}
            >
              Void Document
            </BoloButton>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {dropdownState.isSendACopyModalOpen &&
        dropdownState.documentForSendACopy?.documentUrl && (
          <Modal
            modalContent={SendACopy({
              documentId: dropdownState.documentForSendACopy?.documentId,
              documentName: dropdownState.documentForSendACopy?.documentName,
              documentUrl: dropdownState.documentForSendACopy?.documentUrl,
              documentType: dropdownState.documentForSendACopy?.documentType,
              setting: dropdownState.documentForSendACopy?.setting,
              setOpen: (value) => {
                updateDropdownState("isSendACopyModalOpen", value);
              },
              active: activeForSendModal,
              setActive: setActiveForSendModal,
              email: emailForSendModal,
              setEmail: setEmailForSendModal,
            })}
          />
        )}

      {/* the tooltip for signer details */}
      <Tooltip
        id="single-pdf-details-tooltip"
        className="border border-gray-300 rounded-md shadow-md"
        style={{
          backgroundColor: "#f3f4f6",
          padding: "0px",
        }}
        noArrow={true}
        openOnClick={true}
        closeOnScroll={true}
        closeOnEsc={true}
        clickable={true}
        opacity={95}
      >
        <div
          className="gap-2 p-2"
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "250px",
            overflowY: "auto",
          }}
        >
          {/* <span className="text-xs text-gray-700 mb-2">Owner</span> */}
          <div className="p-2 flex gap-2 items-start justify-start rounded-md w-full bg-white mb-2">
            <MdAccountCircle className="text-xl rounded-full bg-gray-500" />
            <div className="flex flex-col gap-1">
              <p className="text-xs text-gray-700 flex items-center gap-2">
                {showSignerDetailsInTooltip?.[0]?.owner}
              </p>
              <span className="text-xs text-gray-500">
                {showSignerDetailsInTooltip?.[0]?.ownerEmail}
              </span>
              <span className="text-xs text-gray-500">
                Last modified {showSignerDetailsInTooltip?.[0]?.lastModified}
              </span>
            </div>
          </div>
          <span className="text-xs text-gray-700 mb-2">Signers</span>
          {showSignerDetailsInTooltip?.map((item: any, index: any) => {
            return (
              <div
                className="p-2 flex gap-2 items-start justify-start rounded-md shadow hover:bg-secondary w-full bg-white"
                key={index}
              >
                <MdAccountCircle
                  className="text-xl rounded-full"
                  style={{ background: item.colour }}
                />
                <div className="flex flex-col gap-1">
                  <p className="text-xs text-gray-700 flex items-center gap-2">
                    {item.name}
                    <span className="">
                      {item?.status === "SIGNED" ? (
                        <MdOutlineDoneAll className="text-green-600" />
                      ) : (
                        <AiOutlineClockCircle className="text-red-600" />
                      )}
                    </span>{" "}
                  </p>
                  {item.phone ? (
                    <span className="text-xs text-gray-500">{item.phone}</span>
                  ) : (
                    <span className="text-xs text-gray-500">{item.email}</span>
                  )}
                  <span className="text-xs text-gray-500">{item.status}</span>
                  {item.accessCode && (
                    <span className="text-xs text-gray-500">
                      Access Code:{" "}
                      <span className="font-semibold tracking-wide">
                        {item?.accessCode}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Tooltip>
    </div>
  );
};

export default PdfsDashboard;
