import React from "react";
import { Link } from "react-router-dom";

const PlanExhaustedNavBanner = () => {
  return (
    <div className="flex flex-col items-center justify-between gap-[100px] overflow-y-hidden ">
      {/* <div className="bg-plan-exhaust-animation top-0 left-0 z-20 py-6 w-full bg-black pl-[150px] text-white flex items-center overflow-hidden">
        <h2>
          Your plan has been exhausted. Please{" "}
          <Link to="/billings" className="underline font-semibold ">
            upgrade
          </Link>{" "}
          to a higher plan to continue.
        </h2>
      </div> */}
      <div className="flex flex-col items-center justify-center">
        <img
          src="https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/approval-images/tired-employee-exhausted-with-work_74855-4820.avif"
          alt="logo"
          className="h-[60vh] mb-4"
          loading="lazy"
        />
        <h1 className="text-xl">
          Your plan has been exhausted. Please{" "}
          <Link
            to="/billings"
            className="underline font-semibold text-[#9A6AFB] "
          >
            upgrade
          </Link>{" "}
          to a higher plan to continue.
        </h1>
      </div>
    </div>
  );
};

export default PlanExhaustedNavBanner;
