// @ts-nocheck
import React, { Fragment, useEffect, useRef, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { BsSearch, BsThreeDots } from "react-icons/bs";
import { FaEye, FaPlus, FaUserEdit } from "react-icons/fa";
import {
  MdClose,
  MdFolder,
  MdFolderOpen,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { VscEmptyWindow } from "react-icons/vsc";
import { InfinitySpin } from "react-loader-spinner";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useTable } from "react-table";
import { Slide, ToastContainer, toast } from "react-toastify";
import { v4 } from "uuid";
import Modal from "../../components/Modal";
import { BASE_URL, getDashboardUrl } from "../../constants/constants";
import {
  fetchOwner,
  getWorkSpaceDetails,
  openExternalLinkUtil,
} from "../../utils";
import axios from "./../../utils/axios";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { FaUserGear } from "react-icons/fa6";
import DropdownActionsTemplate from "../../components/DropdownActions/DropdownActionsTemplates";
import CustomTooltip from "../../components/EditForm/CustomTooltip";
import MobileLayoutDashboard from "../../components/MobileLayoutDashboard";
import MoveItemModal from "../../components/MoveItemModal";
import PleaseUpgradeModal from "../../components/PleaseUpgradeModal";
import { Helmet } from "react-helmet";
import MixPanel from "../../utils/services/mixpanel";
import { useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import { FiExternalLink } from "react-icons/fi";
import { fetchAllDocuments } from "../../redux/apis/dashboards";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import DashboardFilter from "../../components/DashboardFilter/DashboardFilter";
import NoSearchResults from "../../components/NoSearchResults/NoSearchResults";
import DashboardBottomNav from "../../components/DashboardBottomNav/DashboardBottomNav";
import { isFeatureEnabledHookHelper } from "../../hooks/reduxHooks/useIsFeatureEnabled";
import { isFeatureEnabled } from "../../utils/paidFeat";
import NavButton from "../../components/DashboardFilter/NavButton";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { IoSearch } from "react-icons/io5";
import NoDocumentsV1 from "../../components/NoDocuments/NoDocumentsV1";
import ShareWithTeammates from "../../components/ShareWithTeammates";
import DropboxDriveModal from "../../components/DropboxDriveModal";
import OnedriveModalUpload from "../../components/OndeDriveModalUpload";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "../../components/Common/SelectDropDown/BoloDropDown";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/Common/Dialog/BoloDialog";
const TemplatesDashboard = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const [owner, setOwner] = useState({});
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  // new search based filters
  const { getSearchData, setSearchData, isFilter } = useQueryHook();
  const [sharedAdmins, setSharedAdmins] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [whichAdmin, setWhichAdmin] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [fetchedDocuments, setfetchedDocuments] = useState([]);
  const [formsCount, setFormsCount] = useState(0);

  /* New Combined State  */
  const [dropdownState, setDropdownState] = useState({
    roleInCollaboration: "owner",
    loading: false,
    folderOptions: { move: { item: {}, show: false } },
    showUpgradeModal: false,
    showGoogleDriveModal: false,
    driveModalDocStatus: false,
    driveModalDoc: {},
    deletingResponse: null,
    show: { formMoreOptions: { active: false, id: null } },
    isSharedDashboard: false,
    reload: false,
    workSpaceDetails: getWorkSpaceDetails(),
    upgradeModalType: "",
    showShare: false,
    activeDoc: null,
    dropboxDoc: {
      documentId: "",
      enabled: false,
      parentFolderName: "",
      parentFolderUrl: "",
      parentFolderId: "",
    },
    showDropboxDriveModal: false,
    showOneDriveModal: false,
    oneDriveDoc: {
      documentId: "",
      enabled: false,
      parentFolderName: "",
      parentFolderUrl: "",
      parentFolderId: "",
      parentFolderDriveId: "",
    },
  });

  const updateDropdownState = (key, value) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const currentPlan =
    owner?.planhistory?.[owner?.planhistory?.length - 1]?.planName;

  const [tableData, setTableData] = useState({
    columns: [],
    respondent: [],
  });
  const [dealId, setDealId] = useState(searchParams.get("dealId"));
  const [type, setType] = useState(searchParams.get("type"));
  const [rowData, setRowData] = useState([]);
  const [actionShowingDocument, setActionShowingDocument] = useState<any>("");
  const [openPicker, authResponse] = useDrivePicker();
  const [isDriveParentFolderId, setIsDriveParentFolderId] = useState(false);
  const [lastAction, setLastAction] = useState<any>(false);

  const [upgradeModalData, setUpgradeModalData] = useState({
    title: "",
    desc: "",
    videoSrc: "",
  });
  const [signerEmail, setSignerEmail] = useState("");

  useEffect(() => {
    function checkUserDetails() {
      const localWorkSpaceDetails = getWorkSpaceDetails();
      updateDropdownState("workSpaceDetails", localWorkSpaceDetails);
    }
    window.addEventListener("storage", checkUserDetails);
    return () => {
      window.removeEventListener("storage", checkUserDetails);
    };
  }, []);

  const PurpleSwitch = styled(Switch)(({}) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
      "&:hover": {
        backgroundColor: alpha(
          whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
            COLORS.primary,
          0
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor:
        whiteLabelInfo?.settings?.organization?.themeHex?.primary ||
        COLORS.primary,
    },
  }));

  const updateIntegrationStatus = async (
    status,
    integrationName,
    documentId,
    parentFolderId,
    parentFolderName,
    parentFolderUrl
  ) => {
    console.log(status);
    try {
      let res = await axios.put(`/common/update-drive-folder`, {
        documentId,
        enabled: status,
        parentFolderId,
        parentFolderName,
        parentFolderUrl,
        documentType: "DOCUMENT",
      });
      toast.success("Updated");
      return true;
    } catch (error) {
      console.log("updateIntegrationStatus error", error);
      return false;
    }
  };

  async function isIntegrationPermissionAvailable() {
    const { data: permissionDetails } = await axios.post(
      `/forms/check-google-permission`,
      {
        ownerEmail: owner?.email,
        isFormDashboard: true,
      }
    );
    return permissionDetails?.isDrivePermission;
  }

  // adding page in the search params

  useEffect(() => {
    let signEmail = searchParams.get("email");
    let signName = searchParams.get("name");
    // Update the 'page' search parameter when the 'page' state changes
    // Update the 'page' search parameter when the 'page' state changes
    const updatedSearchParams = {};
    // Conditionally update email and name if they are not null
    if (signEmail !== null) {
      updatedSearchParams.email = signEmail;
    }

    if (signName !== null) {
      updatedSearchParams.name = signName;
    }

    setSearchParams(updatedSearchParams);
    if (signEmail) {
      setSignerEmail(signEmail);
      toast.success("Click Send for Signature to proceed", {
        autoClose: false,
        toastId: "click-send",
      });
    }
  }, []);

  const handleChoice = (choice: string) => {
    if (choice === "forms") {
      // navigate("/create/forms");
      handleCreateForm();
    } else if (choice === "pdf") {
      navigate("/create/pdf");
    } else if (choice === "template") {
      navigate(`/create/form-template?id=${v4()}`);
    } else if (choice === "pdf-template") {
      navigate(`/new/pdf-template`);
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem("owner"))._id) {
      navigate("/login");
    }
    const getUser = async () => {
      let user = await fetchOwner(
        // @ts-ignore
        JSON.parse(localStorage.getItem("owner"))._id
      );
      setOwner(user);
    };
    getUser();
  }, []);

  useEffect(() => {
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      setSharedAdmins(owner?.addedBy);
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      setWhichAdmin(owner?.addedBy?.[0]?.ownerId?.email);
    owner?.addedBy &&
      dropdownState.isSharedDashboard &&
      updateDropdownState("roleInCollaboration", owner?.addedBy?.[0]?.ownerId);
  }, [owner, dropdownState.isSharedDashboard]);

  async function handleGetAllDocuments() {
    updateDropdownState("loading", true);
    try {
      let params = getSearchData();
      // console.log(params);
      let data = await fetchAllDocuments({
        page: params.pageNo_,
        limit: params.limit_,
        isStandAloneTemplate: true,
        query: params.query_,
        dateFrom: params.dateFrom_,
        dateTo: params.dateTo_,
        sortBy: params.sortBy_,
        sortOrder: params.sortOrder_,
      });
      if (data) {
        setfetchedDocuments(data.documents);
        setFormsCount(data.documentsCount);
      }
      updateDropdownState("loading", false);
    } catch (error) {
      updateDropdownState("loading", false);
      console.log("Error getting documents", error);
    }

    updateDropdownState("loading", false);
  }

  // if page num updates we make a query
  useEffect(() => {
    handleGetAllDocuments();
  }, [
    searchParams,
    getSearchData().rT_,
    getSearchData().pageNo_,
    getSearchData().limit_,
    getSearchData().sortBy_,
    getSearchData().sortOrder_,
    getSearchData().dateFrom_,
    getSearchData().dateTo_,
    dropdownState.reload,
  ]);

  // if searchText updates
  // and is "" then we go back to the state where to page one with no search query
  useEffect(() => {
    if (!searchText && getSearchData().query_) {
      let data = getSearchData();
      setSearchData({ query_: "", pageNo_: data.pageNo_ });
      // handleGetAllDocuments();
    }
  }, [searchText]);
  const handleOneResponseDelete = async () => {
    updateDropdownState("show", { ...dropdownState.show, oneDeleting: true });
    console.log("Delete", dropdownState);
    let id = dropdownState.deletingResponse._id;
    console.log(id);

    try {
      let { data } = await axios.post(
        `/respondents/delete-pdfdocument-response`,
        {
          id,
        }
      );
      if (data?.success) {
        const prevReload = dropdownState.reload;
        updateDropdownState("reload", !prevReload);
      }
    } catch (error) {
      console.log(error);
    }
    updateDropdownState("show", {
      ...dropdownState.show,
      deleteOneResponseModal: false,
      oneDeleting: false,
    });
  };

  const GoogleDriveModal = () => {
    let isFreePlan =
      owner?.planhistory[owner?.planhistory.length - 1]?.planId === "FREE_PLAN";

    return (
      <div className="md:w-[600px] py-2 md:py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            updateDropdownState("showGoogleDriveModal", false);
          }}
          className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <h2 className="mb-1 text-lg md:text-xl font-semibold">
          Google Drive Integration
        </h2>
        <p className="text-xs text-gray-600 pb-2 mb-6 border-b-2 border-gray-200">
          Select drive folder to save the response pdf files to your Google
          Drive.
        </p>
        {!dropdownState.driveModalDoc?.isDrivePermission && !isFreePlan && (
          <div className="bg-white rounded-md px-4 py-3 mb-4 border text-sm mt-4">
            <h2 className="mb-1 text-xs md:text-sm">
              You are not connected to Google Drive
            </h2>
            <p className="text-xs text-gray-500 mb-4">
              Connect your Google Drive to save the finished PDF to your
              specified Google Drive folder.
            </p>
            <button
              className="w-full bg-primary text-white px-4 py-2 rounded-md text-sm mr-1"
              onClick={() => {
                window.open(
                  `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/dashboard/templates`,
                  "_self"
                );
              }}
            >
              Connect to Google Drive
            </button>
          </div>
        )}
        {dropdownState.driveModalDoc?.isDrivePermission && !isFreePlan && (
          <div className="flex justify-between">
            {!dropdownState.driveModalDoc?.parentFolderId ? (
              <div>
                <h2 className="mb-1 text-xs md:text-smfont-medium text-gray-700">
                  Your document is not connected to any Google Drive Folder
                </h2>
                <p className="text-xs text-gray-500 mb-4">
                  Pick a folder to save your form responses as PDFs.
                </p>
                <button
                  className="w-[50%] md:w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                  onClick={async () => {
                    await handleGooglePicker(
                      dropdownState.driveModalDoc?.document?.documentId
                    );
                  }}
                >
                  Select Folder
                </button>
              </div>
            ) : (
              <div>
                <h2 className="mb-1 text-xs md:text-sm font-medium text-gray-700">
                  Your document is connected to Google Drive
                </h2>
                <p className="text-xs text-gray-500 mb-4">
                  Your document responses will be saved to this Drive Folder:{" "}
                  <span
                    className="text-primary font-semibold cursor-pointer"
                    onClick={() => {
                      window.open(
                        dropdownState.driveModalDoc?.parentFolderUrl,
                        "_blank"
                      );
                    }}
                  >
                    {dropdownState.driveModalDoc?.parentFolderName}
                  </span>
                </p>
                <div className="w-full flex justify-between gap-4">
                  <button
                    disabled={isFreePlan}
                    className="w-full border-2 border-primary bg-white inset-2 text-primary px-4 py-2 rounded-md text-xs mr-1"
                    onClick={async () => {
                      await handleGooglePicker(
                        dropdownState.driveModalDoc?.document?.documentId
                      );
                    }}
                  >
                    Update Folder
                  </button>
                  <button
                    className="w-full bg-primary text-white px-4 py-2 rounded-md text-xs mr-1"
                    onClick={() => {
                      window.open(
                        dropdownState.driveModalDoc?.parentFolderUrl,
                        "_blank"
                      );
                    }}
                  >
                    View Folder
                  </button>
                </div>
              </div>
            )}
            <PurpleSwitch
              checked={dropdownState.driveModalDocStatus}
              onChange={async (e) => {
                await updateIntegrationStatus(
                  e.target.checked,
                  "googleDrive",
                  dropdownState.driveModalDoc?.document?.documentId,
                  dropdownState.driveModalDoc?.parentFolderId,
                  dropdownState.driveModalDoc?.parentFolderName,
                  dropdownState.driveModalDoc?.parentFolderUrl
                );
                updateDropdownState(
                  "driveModalDocStatus",
                  !dropdownState.driveModalDocStatus
                );
              }}
            />
          </div>
        )}
      </div>
    );
  };

  React.useMemo(() => {
    let cols = [
      {
        name: "Document",
        Header: "Document",
        accessor: "Document", // accessor is the "key" in the data
      },
      {
        name: "Created",
        Header: "Created",
        accessor: "Created",
      },
      {
        name: "Created By",
        Header: "Created By",
        accessor: "Created By",
      },
      {
        name: "Responses",
        Header: "Responses",
        accessor: "Responses",
      },
      {
        name: "Send for Signatures",
        Header: "Send for Signatures",
        accessor: "Send for Signatures",
      },
      {
        name: "Actions",
        Header: "Actions",
        accessor: "Actions",
      },
    ];
    let signEmail = searchParams.get("email");
    console.log(signEmail);
    if (signEmail) {
      cols = cols.filter(
        (col) => col.name !== "Actions" && col.name !== "Responses"
      );
      console.log(cols);
    }

    if (window.innerWidth < 640) {
      cols = cols.filter(
        (col) => col.name === "Download" || col.name === "Forms"
      );
    }

    // @ts-ignore
    setTableData((prev) => {
      return {
        ...prev,
        columns: cols,
      };
    });
  }, []);

  const handleGooglePicker = async (documentId) => {
    let isSufficientPermission = await isIntegrationPermissionAvailable();

    if (!isSufficientPermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=drive&redirectUrl=/dashboard/templates`,
        "_self"
      );
      return;
    }

    let clientId, developerKey;

    try {
      const { data } = await axios.get(
        `${BASE_URL}/common/get-google-credentials`
      );
      clientId = data?.clientId;
      developerKey = data?.developerKey;
    } catch (error) {
      console.error(
        "Error fetching client ID and developer key from the backend",
        error
      );
    }

    if (clientId && developerKey) {
      openPicker({
        clientId,
        developerKey,
        viewId: "FOLDERS",
        token: owner?.integrationTokens?.google?.oAuthToken,
        setIncludeFolders: true,
        setSelectFolderEnabled: true,
        showUploadView: false,
        supportDrives: true,
        multiselect: false,
        appId: 204560571245,
        callbackFunction: async (driveData) => {
          if (driveData.action === "cancel") {
            console.log("User clicked cancel/close button");
          }

          if (driveData?.docs) {
            try {
              let res = await axios.put(
                `${BASE_URL}/common/update-drive-folder`,
                {
                  documentId,
                  parentFolderId: driveData?.docs[0]?.id,
                  parentFolderName: driveData?.docs[0]?.name,
                  parentFolderUrl: driveData?.docs[0]?.url,
                  enabled: true,
                  documentType: "DOCUMENT",
                }
              );
              toast.success("Folder Updated");
              setIsDriveParentFolderId(true);
              updateDropdownState("driveModalDocStatus", true);
              updateDropdownState("driveModalDoc", {
                ...dropdownState.driveModalDoc,
                parentFolderId: driveData?.docs[0]?.id,
                parentFolderName: driveData?.docs[0]?.name,
                parentFolderUrl: driveData?.docs[0]?.url,
              });
            } catch (error) {
              console.log("error", error);
              toast.error("Something went wrong");
            }
          }
        },
      });
    }
  };

  const ActionsPDFTemplateOptions = ({ i, documents, documentData }: any) => {
    const selfRef = useRef();
    const handleOutsideClick = (e) => {
      if (actionShowingDocument !== documentData.documentId) {
        return;
      }
      if (selfRef.current !== null) {
        if (selfRef.current.contains(e.target)) return;
      }
      setActionShowingDocument(null);
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div className="" ref={selfRef}>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <BoloButton
              size={"icon-sm"}
              variant="gray-shadow"
              className="bg-white"
              // className={`m-auto hover:text-gray-800 text-gray-600`}
              onClick={(e) => {
                if (i >= documents?.length - 2) {
                  setLastAction(documents?.length - i);
                } else {
                  setLastAction(0);
                }
                e.stopPropagation();
                if (actionShowingDocument === documentData.documentId) {
                  setActionShowingDocument(null);
                  return;
                }
                setActionShowingDocument(documentData.documentId);
              }}
            >
              <BsThreeDots />
            </BoloButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            sideOffset={5}
            className="p-0 w-52 max-h-60 overflow-y-auto action-dropdown"
          >
            <DropdownActionsTemplate
              document={documentData}
              updateDropdownState={updateDropdownState}
              dropdownState={dropdownState}
              isIntegrationPermissionAvailable={
                isIntegrationPermissionAvailable
              }
              owner={owner}
            />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  };

  React.useMemo(() => {
    let documents =
      searchResults?.documents?.length > 0 ||
      searchResults?.message === "No result found for this search"
        ? searchResults?.documents
        : fetchedDocuments?.length > 0
        ? fetchedDocuments
        : [];
    setRowData(documents);

    let data = documents?.map((document, i) => {
      return {
        id: document.documentId,
        "#": (
          <span className="bg-gray-300 bg-opacity-40 shadow px-3 py-1.5 rounded-md">
            {getSearchData().pageNo_ * getSearchData().limit_ +
              i +
              1 -
              getSearchData().limit_}
          </span>
        ),

        Document: (
          <div className="flex gap-1 items-center text-gray-700 pl-4 text-sm max-w-[100%]">
            {dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
              document?.folder?.name && (
                <>
                  <MdFolder
                    style={{
                      color: document?.folder?.color || "#3c4043",
                    }}
                    className="mr-1 text-base min-w-[20px]"
                  />
                  <Link
                    className="font-semibold"
                    to={
                      dropdownState.isSharedDashboard
                        ? `/shared-with-me/folder/items?folderId=${document?.folder?.folderId}`
                        : `/folder/items?folderId=${document?.folder?.folderId}`
                    }
                  >
                    {document?.folder?.name}
                  </Link>
                  <span>/</span>
                </>
              )}
            <span
              data-tooltip-id={`pdf-template-name-${document?._id}`}
              data-tooltip-offset={10}
              translate="no"
              className="text-gray-800 text-sm flex items-center truncate"
            >
              <span className="truncate">
                {document?.documentName || document?.name || "Untitled"}
              </span>
              <CustomTooltip
                helpers={{
                  id: `pdf-template-name-${document?._id}`,
                  content:
                    document?.documentName || document?.name || "Untitled",
                  place: "top",
                }}
              />
            </span>
          </div>
        ),
        Created: (
          <div>
            <h3 className="text-gray-600">
              {new Date(document?.createdAt).toDateString()}
            </h3>
            <p className="text-gray-400 text-xs mt-1">
              {new Date(document?.createdAt).toLocaleTimeString()}
            </p>
          </div>
        ),
        "Created By": (
          <div className="flex items-center">
            <div className="mr-4 w-8 aspect-square bg-white rounded-full overflow-hidden border cursor-pointer">
              <img
                src={
                  document?.createdBy?.profilePic ||
                  owner?.profilePic ||
                  "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
                }
                alt="profile"
                referrerPolicy="no-referrer"
                className="w-full h-full object-cover"
              />
            </div>
            <div>
              <h3 className="text-gray-600">
                {document?.createdBy?.name ?? owner?.name ?? "User"}
              </h3>
              <p className="text-gray-400 text-xs mt-1">
                {document?.createdBy?.email ?? owner?.email}
              </p>
            </div>
          </div>
        ),
        Responses: !signerEmail && (
          <BoloButton
            variant={"tertiary"}
            size="sm"
            onClick={() => {
              new MixPanel().track("PDF template", {
                pdf_template_action: "pdf_template_see_responses",
                paid: false,
              });
              new MixPanel().increment("pdf_template_see_responses");
              if (dropdownState.isSharedDashboard) {
                navigate(
                  `/dashboard/templates/respondents?documentId=${document.documentId}&whichAdmin=${whichAdmin}`
                );
              } else {
                navigate(
                  `/dashboard/templates/respondents?documentId=${document.documentId}`
                );
              }
            }}
            className="text-xs md:text-xs"
          >
            Responses
          </BoloButton>
        ),
        "Send for Signatures": (
          <div className="flex gap-2 items-center">
            <BoloButton
              size={"sm"}
              variant="secondary"
              disabled={
                (dropdownState.isSharedDashboard &&
                  dropdownState.roleInCollaboration === "readonly") ||
                dropdownState.loading
              }
              onClick={() => {
                new MixPanel().track("PDF template", {
                  pdf_template_action: "pdf_template_send_for_signature",
                  paid: false,
                });
                new MixPanel().increment("pdf_template_send_for_signature");
                let newParams = "";
                let signEmail = searchParams.get("email");
                const signName = searchParams.get("name");
                if (signEmail) {
                  newParams = `&email=${signEmail}`;
                }
                if (signName) {
                  newParams = newParams + `&name=${signName}`;
                }
                if (dealId) {
                  navigate(
                    `/create/pdf-template/send-for-signature?documentId=${document.documentId}&${type}DealId=${dealId}${newParams}`
                  );
                } else {
                  console.log(
                    `/create/pdf-template/send-for-signature?documentId=${document.documentId}${newParams}`
                  );
                  navigate(
                    `/create/pdf-template/send-for-signature?documentId=${document.documentId}${newParams}`
                  );
                }
              }}
              className="text-xs md:text-xs"
            >
              Send for Signatures
            </BoloButton>
            {!signerEmail && document?.eTemplateId && (
              <BoloButton
                size={"icon-sm"}
                variant="secondary"
                data-tip={"E-template link"}
                onClick={async () => {
                  new MixPanel().track("PDF template", {
                    pdf_template_action: "pdf_template_etemplate",
                    paid: false,
                  });
                  new MixPanel().increment("pdf_template_etemplate");
                  const featureEnabled = await isFeatureEnabled("E_TEMPLATES");
                  if (!featureEnabled) {
                    updateDropdownState("showUpgradeModal", true);
                    updateDropdownState("upgradeModalType", "E_TEMPLATES");
                    return;
                  }
                  openExternalLinkUtil(
                    `${getDashboardUrl()}/etemplate?id=${document?.eTemplateId}`
                  );
                }}
              >
                <FiExternalLink className="" />
              </BoloButton>
            )}
          </div>
        ),

        Actions: !signerEmail && (
          <ActionsPDFTemplateOptions
            documents={documents}
            documentData={document}
          />
        ),
      };
    });

    setTableData((prev) => {
      return {
        ...prev,
        data,
      };
    });
  }, [
    fetchedDocuments,
    actionShowingDocument,
    searchResults,
    dropdownState.show.oneDeleting,
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //@ts-ignore
    useTable({ columns: tableData.columns ?? [], data: tableData.data ?? [] });

  /* This is checking if the user is already logged in. If the user is logged in, it will redirect to
  the dashboard. */
  let user = localStorage.getItem("owner");
  if (!user) {
    console.log("user not found");
    return <Navigate to="/login" />;
  }

  return (
    <div className="-m-4 bg-white h-[100%]">
      <Helmet>
        <title translate="no">Pdf Templates Dashboard</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Template Dashboard" />
        <meta
          name="description"
          content="Centralize and manage all your PDF templates effortlessly on the Template Dashboard for streamlined organization and accessibility."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/dashboard/templates`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Template Dashboard - Boloforms`} />
        <meta
          property="og:description"
          content="Centralize and manage all your PDF templates effortlessly on the Template Dashboard for streamlined organization and accessibility."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/dashboard/templates"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {dropdownState.showUpgradeModal && (
        <PleaseUpgradeModal
          show={dropdownState.showUpgradeModal}
          setShow={(value) => {
            updateDropdownState("upgradeModal", value);
          }}
          data={upgradeModalData}
          blockingType={dropdownState.upgradeModalType}
        />
      )}
      {dropdownState.showDropboxDriveModal && (
        <DropboxDriveModal
          setShowDropboxDriveModal={(value) => {
            updateDropdownState("showDropboxDriveModal", value);
          }}
          driveModalDoc={dropdownState.dropboxDoc}
          setDropboxModalDoc={(value) => {
            updateDropdownState("dropboxDoc", value);
          }}
        />
      )}
      {dropdownState.showOneDriveModal && (
        <OnedriveModalUpload
          setOnedriveModalDoc={(value) => {
            updateDropdownState("oneDriveDoc", value);
          }}
          onedriveModalDoc={dropdownState.oneDriveDoc}
          setShowOneDriveModal={(value) => {
            updateDropdownState("showOneDriveModal", value);
          }}
        />
      )}
      {!dropdownState.isSharedDashboard &&
        dropdownState.folderOptions?.move?.show && (
          <MoveItemModal
            item={dropdownState.folderOptions?.move?.item}
            fromFolder={dropdownState.folderOptions.move.fromFolder}
            setShow={(value) => {
              updateDropdownState("folderOptions", {
                ...dropdownState.folderOptions,
                move: {
                  ...dropdownState.folderOptions.move,
                  show: value,
                  fromFolder: null,
                },
              });
            }}
            stateUpdate={(value) => {}}
            isAdd={dropdownState.folderOptions.isAdd}
            setReload={(value) => {
              updateDropdownState("reload", value);
            }}
          />
        )}
      <ShareWithTeammates
        helpers={{
          documentType: "templates",
          documentName:
            dropdownState.activeDoc?.documentName ||
            dropdownState.activeDoc?.name ||
            "Untitled",
          documentId: dropdownState.activeDoc?.documentId,
          isOpen: dropdownState.showShare,
          setIsOpen: (value) => {
            updateDropdownState("showShare", value);
          },
        }}
      />
      <div
        className={`px-3 md:px-4 md:h-[40px] my-4 sm:my-6 flex-col md:flex-row text-gray-600 flex items-center gap-2`}
      >
        <div className="flex gap-2 w-full md:w-fit">
          <form
            className={`h-full text-xs w-full ${
              dropdownState.isSharedDashboard
                ? "flex flex-row gap-2 w-full mt-2 md:mt-0"
                : "md:w-full"
            } `}
          >
            <div
              className={`h-full flex flex-row rounded-md overflow-hidden md:w-[500px] border`}
            >
              <input
                placeholder="Search by title..."
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full h-full py-2 px-4   outline-none text-gray-700 "
              />
              <button
                className="min-w-[40px] flex justify-center items-center text-gray-500 cursor-pointer hover:opacity-90"
                onClick={(e) => {
                  e.preventDefault();
                  new MixPanel().track("PDF Template", {
                    pdf_template_action: "pdf_template_search",
                    paid: false,
                  });
                  new MixPanel().increment("pdf_template_search");
                  setSearchData({ query_: searchText, pageNo_: 1 });
                  handleGetAllDocuments();
                }}
              >
                <IoSearch className="text-lg" />
              </button>
            </div>
          </form>
          <div className="hidden md:flex">
            <DashboardFilter />
          </div>
        </div>
        <div
          className={`h-full flex flex-row  items-center w-full md:w-fit md:ml-auto`}
        >
          <div className="flex gap-2 md:gap-4 w-full justify-between md:justify-start">
            <BoloButton
              size={"sm"}
              className="h-[32px] whitespace-nowrap text-xs md:text-sm flex gap-3 items-center"
              disabled={
                !dropdownState.workSpaceDetails?.isWorkSpaceOwner &&
                dropdownState.workSpaceDetails?.currentWorkSpaceRole ==
                  "readonly"
                // || workSpaceDetails?.currentWorkSpaceRole == "editor"
              }
              onClick={async () => {
                navigate(`/new/pdf-template`);
              }}
            >
              <FaPlus />
              Create Template
            </BoloButton>
            <div className="flex md:hidden">
              <DashboardFilter />
            </div>
          </div>
        </div>
      </div>
      {/* PC layout*/}
      {window.innerWidth > 768 && (
        <div
          className={`hidden md:block relative`}
          style={{
            overflowY:
              fetchedDocuments?.length === 0 && !dropdownState.loading
                ? "hidden"
                : "auto",
            maxHeight:
              fetchedDocuments?.length === 0 && !dropdownState.loading
                ? 0
                : "calc(100% - 130px)",
            height:
              fetchedDocuments?.length === 0 && !dropdownState.loading
                ? 0
                : "calc(100% - 130px)",
          }}
          id="mini-pdf-custom-scroll"
        >
          <table {...getTableProps()} className="w-full pt-[40px]">
            <thead className="max-h-[40px] h-[40px] sticky top-0 bg-white shadow z-20">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      className={`${
                        column.name === "Document" ? "pl-5" : ""
                      } px-3 py-2 text-left text-gray-600 font-semibold text-xs truncate `}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {dropdownState.loading ? (
                <tr role={"row"} align={"center"} className={"w-full"}>
                  <td className={"w-full"} colSpan={8}>
                    <div className="p-8">
                      <BoloLoader />
                    </div>
                  </td>
                </tr>
              ) : (
                rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="hover:bg-secondary/40 cursor-pointer border-t"
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${
                              cell.column.name === "#"
                                ? "text-center"
                                : "min-w-[150px] max-w-[300px]"
                            } px-2 py-3 text-sm truncate`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* Mobile Layout */}
      {window.innerWidth <= 768 && (
        <MobileLayoutDashboard
          documentType="PDF-TEMPLATE"
          rowData={rowData}
          ActionsPDFTemplateOptions={ActionsPDFTemplateOptions}
        />
      )}

      {/* //? condition was previously like this searchResults?.message === "No result found for this search" */}
      {fetchedDocuments?.length === 0 && isFilter && <NoSearchResults />}
      {fetchedDocuments?.length === 0 && !isFilter ? (
        <div className="w-full flex flex-col items-center py-12 text-gray-600">
          {!dropdownState.loading && (
            <NoDocumentsV1
              cb={() => {
                if (
                  dropdownState.workSpaceDetails?.currentWorkSpaceRole !==
                  "readonly"
                ) {
                  handleChoice("pdf-template");
                } else {
                  toast.error(
                    "Readonly role are not allowed to create PDF Templates"
                  );
                }
              }}
              resourceType="DOCUMENT"
            />
          )}
        </div>
      ) : (
        <DashboardBottomNav count={formsCount} />
      )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />

      {!dropdownState.isSharedDashboard &&
        dropdownState.showGoogleDriveModal && (
          <Modal modalContent={GoogleDriveModal()} />
        )}
      {!dropdownState.isSharedDashboard && (
        <Dialog
          open={dropdownState.show.deleteOneResponseModal}
          onOpenChange={(value) => {
            updateDropdownState("show", {
              ...dropdownState.show,
              deleteOneResponseModal: value,
            });
          }}
        >
          <DialogContent className="">
            <DialogHeader>
              <DialogTitle>Remove Document</DialogTitle>
            </DialogHeader>
            <div>
              <h2 className="text-sm md:text-base font-medium md:leading-[2] leading-[2]">
                Are you sure you want to delete this document?
              </h2>
              <p className="text-[10px] md:text-xs text-gray-600">
                This document will be deleted.
              </p>
            </div>

            <DialogFooter>
              <BoloButton
                variant={"danger-border"}
                size={"sm"}
                onClick={handleOneResponseDelete}
                disabled={dropdownState.show.oneDeleting}
              >
                {dropdownState.show.oneDeleting ? "Deleting..." : "Delete"}
              </BoloButton>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default TemplatesDashboard;
