//@ts-nocheck
import React, { useState, useEffect, useRef, MouseEvent } from "react";

const ResizableImage = ({ src, outerWidth, question, setFormState }) => {
  const [width, setWidth] = useState(question?.width);
  const [height, setHeight] = useState(question?.height);
  const [isResizing, setIsResizing] = useState(false);
  const [ResizingType, setResizingType] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const resizableRef = useRef(null);
  const [aspectRatio, setAspectRatio] = useState(1);
  const types = {
    BR: {
      cursor: "se-resize",
      top: "auto",
      bottom: "1px",
      right: "1px",
      left: "auto",
    },
    TR: {
      cursor: "ne-resize",
      top: "1px",
      bottom: "auto",
      right: "1px",
      left: "auto",
    },
    TL: {
      cursor: "nw-resize",
      top: "1px",
      bottom: "auto",
      right: "auto",
      left: "1px",
    },
    BL: {
      cursor: "sw-resize",
      top: "auto",
      bottom: "1px",
      right: "auto",
      left: "1px",
    },
  };

  const handleOutsideClick = (e) => {
    if (resizableRef.current !== null) {
      if (resizableRef.current.contains(e.target)) return;
    }
    setIsEditing(false);
  };

  const handleMouseDown = (type) => {
    setIsResizing(true);
    setResizingType(type);
  };

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    const { width, height } = question;
    if (height) {
      setWidth(Math.min(width, outerWidth));
      setHeight(height);
    } else {
      setWidth(Math.min(naturalWidth, outerWidth));
      setHeight(
        Math.min(naturalHeight, outerWidth / (naturalWidth / naturalHeight))
      );
    }
    setAspectRatio(naturalWidth / naturalHeight);
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isResizing && resizableRef.current) {
        const { x } = resizableRef.current.getBoundingClientRect();
        let newWidth, newHeight;
        if (ResizingType === "BR" || ResizingType === "TR") {
          newWidth = event.clientX - x;
        } else {
          newWidth = width + x - event.clientX;
        }
        newHeight = newWidth / aspectRatio;

        if (newWidth < 200) {
          newWidth = 200;
        }
        if (newHeight < 200 / aspectRatio) {
          newHeight = 200 / aspectRatio;
        }
        setWidth(Math.min(newWidth, outerWidth));
        setHeight(Math.min(newHeight, outerWidth / aspectRatio));
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (isResizing) {
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.width = width;
              question_.height = height;
            }
          });
        });
        return newState;
      });
    }
  }, [isResizing, height, width]);

  useEffect(() => {
    if (!question?.width) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setWidth(Math.min(img.naturalWidth, outerWidth));
        setHeight(
          Math.min(
            img.naturalHeight,
            outerWidth / (img.naturalWidth / img.naturalHeight)
          )
        );
        setAspectRatio(img.naturalWidth / img.naturalHeight);
      };
    }
  }, [src, outerWidth, question]);

  return (
    <div
      ref={resizableRef}
      className="resizable flex justify-center items-center relative overflow-hidden"
      onClick={() => setIsEditing((prev) => !prev)}
      style={{
        width: `${width + 8}px`,
        height: `${height + 8}px`,
        // zIndex: isEditing ? 100 : 30,
      }}
    >
      <img
        src={src}
        alt="question-img"
        className="block pointer-events-none"
        onLoad={handleImageLoad}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          border: isEditing ? "1px solid #1a73e8" : "none",
          userSelect: "none",
        }}
      />
      {isEditing && (
        <>
          {Object.keys(types).map((ele) => (
            <div
              key={ele}
              className={`absolute bg-[#1a73e8]`}
              style={{
                height: "8px",
                width: "8px",
                cursor: types[ele].cursor,
                top: types[ele].top,
                bottom: types[ele].bottom,
                right: types[ele].right,
                left: types[ele].left,
              }}
              onMouseDown={() => handleMouseDown(ele)}
            ></div>
          ))}
        </>
      )}
    </div>
  );
};

export default ResizableImage;
