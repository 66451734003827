//@ts-nocheck
import React, { Fragment, useEffect, useState } from "react";
import QuestionInput from "../../QuestionInput";
import QuestionDesc from "../../QuestionDesc";
import ImageQuestionPreview from "../../ImageQuestionPreview";
import QuestionBottomOptions from "../../QuestionBottomOptions";
import { MdArrowDropDown } from "react-icons/md";

function CustomDropDown({ opts, setSelectedOpt, selectedOpt }) {
  let activeQuestion = opts.find((option) => option === selectedOpt);
  const questionTypeDropdownRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [displacementAmount, setDisplacementAmount] = useState(0);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, index) => {
    let displacement = -(index - 1) * 48;
    setDisplacementAmount(displacement);
    setSelectedOpt(option);
    setIsOpen((prev) => !prev);
  };

  const handleOutsideClick = (e) => {
    if (questionTypeDropdownRef.current !== null) {
      if (questionTypeDropdownRef.current.contains(e.target)) return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative ml-2 max-w-[80px]">
      <div className="relative">
        <div
          onClick={toggleDropdown}
          className="h-[48px] py-2 px-3 rounded flex items-center cursor-pointer"
        >
          {/* <div className="mr-2">{activeQuestion.icon}</div> */}
          <div className="ml-1 mr-2">
            <span className="text-gray-800 text-sm">{activeQuestion}</span>
          </div>
          <div className="ml-auto">
            <MdArrowDropDown className="text-2xl text-gray-500" />
          </div>
        </div>
        {isOpen && (
          <div
            ref={questionTypeDropdownRef}
            style={{
              marginTop: "-48px",
              position: "absolute",
              boxShadow:
                "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
              // top: `${displacementAmount}px`,
            }}
            className="py-2 min-w-8 bg-white border border-[#0000001f] rounded z-50"
          >
            {opts.map((option, index) => {
              return (
                <Fragment key={index}>
                  <div
                    onClick={() => handleOptionClick(option, index)}
                    className={`${
                      activeQuestion === option && "bg-[#e4f0fc]"
                    } h-[48px] w-full py-2 pl-4 pr-8 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <span className="text-gray-800 text-sm font-medium">
                      {option}
                    </span>
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const Linear = ({ data }) => {
  let {
    question,
    formState,
    setFormState,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
    schemas,
  } = data;
  const isEditor =
    questionIndex.p === activeState.pageIndex &&
    questionIndex.q === activeState.questionIndex;

  function setSelectedFirst(value) {
    const newLinearRange = {
      ...question.linearRange,
      first: parseInt(value),
    };
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.linearRange = newLinearRange;
          }
        });
      });
      return newState;
    });
  }

  function setSelectedLast(value) {
    const newLinearRange = {
      ...question.linearRange,
      last: parseInt(value),
    };
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.linearRange = newLinearRange;
          }
        });
      });
      return newState;
    });
  }
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <QuestionInput
            helpers={{
              question,
              setFormState,
              isEditor,
              questionIndex,
              activeState,
            }}
          />
        </div>
        <QuestionDesc
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
      </div>
      <ImageQuestionPreview
        helpers={{ question, setFormState, questionIndex, activeState }}
      />
      <div className="mb-12 mt-12">
        {!isEditor && (
          <div className="mb-6 text-sm w-full">
            <div className="flex gap-4 items-end">
              <div className="">{question?.linearRange?.labelFirst}</div>
              <div className="flex justify-between w-full">
                {[
                  ...Array(
                    parseInt(question?.linearRange?.last) -
                      parseInt(question?.linearRange?.first) +
                      1
                  ).keys(),
                ]?.map((option, optIdx) => {
                  let optionWithOffset =
                    option + parseInt(question?.linearRange?.first);
                  return (
                    <div
                      className="flex flex-row items-center gap-4 sm:flex-col"
                      key={optIdx}
                    >
                      <p className="w-[25px] text-center">{optionWithOffset}</p>
                      <div className="rounded-full border-2 h-[20px] w-[20px]"></div>
                    </div>
                  );
                })}
              </div>
              <div>{question?.linearRange?.labelLast}</div>
            </div>
          </div>
        )}
        {isEditor && (
          <div className="h-[48px] flex flex-row items-center">
            <div className="mb-6">
              <div className="flex gap-2 items-end">
                <CustomDropDown
                  opts={[0, 1]}
                  selectedOpt={question?.linearRange?.first}
                  setSelectedOpt={setSelectedFirst}
                />
                <span className="text-sm mb-3">to</span>
                <CustomDropDown
                  opts={[2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  selectedOpt={question?.linearRange?.last}
                  setSelectedOpt={setSelectedLast}
                />
              </div>
              <div className="mt-4 text-sm">
                <div className="mb-5">
                  <span className="text-gray-800 w-[30px] inline-block ">
                    {question?.linearRange?.first}
                  </span>
                  <input
                    type="text"
                    placeholder={"Label (optional)"}
                    value={question?.linearRange?.labelFirst}
                    onChange={(e) => {
                      const newLinearRange = {
                        ...question.linearRange,
                        labelFirst: e.target.value,
                      };
                      setFormState((prevState) => {
                        let newState = { ...prevState };
                        newState.pages.forEach((page) => {
                          page.questions.forEach((question_) => {
                            if (question_.id === question.id) {
                              question_.linearRange = newLinearRange;
                            }
                          });
                        });
                        return newState;
                      });
                    }}
                    className="outline-none max-w-[200px] border-b border-gray-500 b-btm-fade pb-1 pl-1"
                  />
                </div>
                <div>
                  <span className="text-gray-800 w-[30px] inline-block ">
                    {question?.linearRange?.last}
                  </span>
                  <input
                    type="text"
                    placeholder={"Label (optional)"}
                    value={question?.linearRange?.labelLast}
                    onChange={(e) => {
                      const newLinearRange = {
                        ...question.linearRange,
                        labelLast: e.target.value,
                      };
                      setFormState((prevState) => {
                        let newState = { ...prevState };
                        newState.pages.forEach((page) => {
                          page.questions.forEach((question_) => {
                            if (question_.id === question.id) {
                              question_.linearRange = newLinearRange;
                            }
                          });
                        });
                        return newState;
                      });
                    }}
                    className="outline-none max-w-[200px] border-b border-gray-500 b-btm-fade pb-1 pl-1"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <QuestionBottomOptions
        helpers={{
          question,
          setFormState,
          questionIndex,
          activeState,
          setActiveState,
          setActive,
          formState,
          schemas,
        }}
      />
    </div>
  );
};

export default Linear;
