import React, { useState } from "react";
import { toast } from "react-toastify";
import { BoloButton } from "../../../../components/Common/Button/BoloButton";
import Papa from "papaparse";
import { validateEmail } from "../../../../utils/validateEmail";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/Common/Dialog/BoloDialog";
import axios from "../../../../utils/axios";
import { BASE_URL } from "../../../../constants/constants";
import { useAppSelector } from "../../../../redux/store";
import { TailSpin } from "react-loader-spinner";
import { SiteReviewType } from "../../../../redux/slices/whiteLabelSlice";
import InfoContent from "../InfoContent/InfoContent";

const InvalidDataModal = ({
  data,
  show,
  setShow,
}: {
  data: any[];
  show: boolean;
  setShow: (value: boolean) => void;
}) => {
  function downloadCSV(data: any[], filename: string) {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      data.map((row) => row.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setShow(false);
  }

  function parseData(data: any[]): any[] {
    let newData: any[] = [
      ["name", "title", "content", "stars", "image", "error"],
    ];
    for (let row of data) {
      // console.log(row);
      let newRow = [];
      newRow.push(row.name);
      newRow.push(`"${row.title}"`);
      newRow.push(`"${row.content}"`);
      newRow.push(row.stars);
      newRow.push(row.image);
      newRow.push(row?.message?.join("; "));
      newData.push(newRow);
    }
    return newData;
  }

  function handleClick() {
    let parsedData = parseData(data);
    downloadCSV(
      parsedData,
      "ERRORS : remove the error column before uploading"
    );
  }

  return (
    <Dialog open={show} onOpenChange={setShow}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Invalid Data</DialogTitle>
          <DialogDescription>
            The CSV contains {data.length} invalid records
          </DialogDescription>
        </DialogHeader>
        <div>
          You can download the invalid records and upload them later after
          correcting them
        </div>
        <DialogFooter>
          <BoloButton
            variant={"bolo-secondary"}
            size={"sm"}
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </BoloButton>
          <BoloButton
            variant={"bolo-primary"}
            size={"sm"}
            onClick={() => handleClick()}
          >
            Download Invalid Records
          </BoloButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const BulkReviewUpload = ({
  setReviews,
  reviews,
  handleSave,
}: {
  setReviews: (arg: SiteReviewType[]) => void;
  reviews: SiteReviewType[];
  handleSave: () => Promise<void>;
}) => {
  const whiteLabelPartner = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelPartner
  );
  const expectedFields: string[] = [
    "name",
    "title",
    "content",
    "stars",
    "image",
  ];
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [isCsvValid, setIsCsvValid] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [hasInvalidData, setHasInvalidData] = useState(false);
  const [invalidData, setInvalidData] = useState<any[]>([]);
  const COLOR_MAP: { [key: string]: string } = {
    superadmin: "red",
    admin: "orange",
    editor: "green",
    readonly: "",
  };
  const [addUserLoading, setAddUserLoading] = useState(false);

  const handleTemplateDownload = () => {
    //@ts-ignore
    const blob = new Blob([expectedFields], {
      type: "text/csv",
    });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link and trigger a click to download the file
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `bulk-user-review-template.csv`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  function checkCSVData(data: any[]): {
    invalidData: any[];
    validData: any[];
    mesage: string[];
  } {
    let invalidData: any[] = [];
    let validData: any[] = [];
    let gMsg: string[] = [];

    for (let row of data) {
      // console.log({ row });
      let isInvalid = false;
      let message: any = [];

      if (!row?.name) {
        message.push("No Name Provided");
        isInvalid = true;
      }

      if (!row?.title) {
        message.push("No Title Provided");
        isInvalid = true;
      }

      if (!row?.content) {
        message.push("No Content Provided");
        isInvalid = true;
      }

      if (!row?.stars) {
        message.push("Star Count not provided");
        isInvalid = true;
      }

      if (isInvalid) {
        let newRow: any = { ...row };
        newRow.message = message;
        invalidData.push(newRow);
      }

      if (!isInvalid) {
        validData.push(row);
      }
    }

    return { invalidData, validData, mesage: gMsg };
  }

  const handleCsvUpload = ({ file }: { file: File }) => {
    // e.target.value = "";
    // // Read the file and parse it
    setCsvFile(file);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const result = event.target.result;
      if (!result) {
        toast.error("Please check csv file.");
        setCsvFile(null);
        setIsCsvValid(false);
        return;
      }
      //   // Parse the CSV data
      Papa.parse(result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (parsedData: any) => {
          // console.log({
          //   parsedData,
          //   1: !parsedData,
          //   2: !parsedData.data,
          //   3: parsedData.data.length === 0,
          //   4: !parsedData.data[0],
          // });
          if (
            !parsedData ||
            !parsedData.data ||
            parsedData.data.length === 0 ||
            !parsedData.data[0]
          ) {
            toast.error("No data or invalid CSV");
            setCsvFile(null);
            setIsCsvValid(false);
            return;
          }

          const userCsvFields = Object.keys(parsedData.data[0]);
          const fieldsExists = expectedFields.every((field) =>
            userCsvFields.includes(field)
          );
          const checkedData = checkCSVData(parsedData.data);
          console.log(checkedData);

          if (checkedData.mesage.length > 0) {
            return toast.error(checkedData.mesage.join(", "));
          }

          if (checkedData.invalidData.length > 0) {
            setHasInvalidData(true);
            setInvalidData(checkedData.invalidData);
          }

          if (fieldsExists) {
            setCsvData(checkedData.validData);
            setIsCsvValid(true);
          } else {
            toast.error("Please ensure all required fields are present.");
            setCsvFile(null);
            setIsCsvValid(false);
          }
        },
        error: (error: any) => {
          console.error("CSV parsing error:", error.message);
        },
      });
    };
    if (file) {
      reader.readAsText(file);
    } else {
      setCsvFile(null);
      setIsCsvValid(false);
    }
  };

  function handleCancel() {
    setIsCsvValid(false);
    setCsvData([]);
    setCsvFile(null);
  }

  async function handleAddReviews() {
    try {
      setAddUserLoading(true);

      // let { data } = await axios.post(
      //   `${BASE_URL}/whitelable/helper/bulk-user-upload`,
      //   { users: csvData, forWhiteLabelOwner: whiteLabelPartner?._id }
      // );
      // // console.log(data);
      // if (data.success) {
      //   handleCancel();
      //   toast.success("Users Imported");
      // } else {
      //   toast.error(data.message || "Something went wrong");
      // }
      let csvDataParsed: SiteReviewType[] = csvData?.map((elm) => ({
        name: elm.name,
        title: elm.title,
        content: elm.content,
        stars: elm.stars,
        image: elm.image,
      }));
      setReviews(csvDataParsed);
      toast.info("Please click on Save button to apply changes");
      // await handleSave();
      handleCancel();
      setAddUserLoading(false);
    } catch (error) {
      console.log(error);
      setAddUserLoading(false);
      toast.error("Something went wrong");
    }
  }

  return (
    <div className="flex flex-col p-2">
      <InvalidDataModal
        data={invalidData}
        show={hasInvalidData}
        setShow={setHasInvalidData}
      />
      <div className="text-gray-700 mb-3 flex gap-4 items-center">
        <p>Add multiple reviews at once </p>

        <InfoContent
          helper="These Reviews will be shown on login screen"
          id="bulk-review-upload"
        />
      </div>
      <p className="text-xs text-gray-600 mb-1">
        Upload reviews in multiples of 5 for best UI
      </p>
      <p className="text-sm font-semibold">STEP 1</p>
      <p className="text-gray-700 mb-2">
        Download the csv template and fill the details
      </p>
      <div className="flex gap-4">
        <BoloButton
          variant={"bolo-secondary"}
          size="xs"
          className="w-fit"
          onClick={() => handleTemplateDownload()}
        >
          Download
        </BoloButton>
      </div>
      <p className="text-sm font-semibold mt-4">STEP 2</p>
      <p className="text-gray-700 mb-2">Upload the filled csv file</p>
      <div className="flex gap-4 items-center">
        <div className="flex items-center">
          <label
            htmlFor="csv-upload"
            onClick={() => {}}
            className="px-2 py-1 text-xs rounded cursor-pointer min-w-[120px] bg-secondary-bolo text-primary-bolo font-medium border-primary-bolo border-2 flex items-center justify-center hover:opacity-80"
          >
            Choose File
          </label>
        </div>
        <input
          className="self-start px-4 w-full outline-none text-gray-700 rounded-md border disabled:opacity-50 text-sm hidden"
          id="csv-upload"
          type="file"
          accept=".csv, .xlsx"
          onChange={(e: any) => {
            const file = e.target.files[0];
            handleCsvUpload({ file });
          }}
        />
        <p className="ml-2 text-xs text-gray-700">
          {csvFile ? csvFile?.name : "No file chosen"}
        </p>
      </div>

      {(!!csvData.length || !!reviews.length) && (
        <div className="flex flex-col mt-6">
          <p className="text-sm font-semibold mb-2">USERS</p>
          <div className="flex border-b px-2 py-1 max-w-[760px] font-semibold">
            <div className="w-[10%] px-2">Name</div>
            <div className="w-[10%] px-2">Title</div>
            <div className="w-[60%] px-2">Content</div>
            <div className="w-[10%] px-2">Stars</div>
            <div className="w-[10%] px-2">Image</div>
          </div>
          <div
            className="relative flex flex-col max-h-[200px] overflow-y-auto max-w-[760px]"
            id="mini-pdf-custom-scroll"
          >
            {(csvData.length ? csvData : reviews).map((elm) => {
              return (
                <div
                  className="flex border-b px-2 py-1 items-center"
                  key={elm?.email}
                >
                  <div className="truncate w-[10%] px-2">{elm.name}</div>
                  <div className="truncate w-[10%] px-2">{elm.title}</div>
                  <div className="truncate w-[60%] px-2">{elm.content}</div>
                  <div className="truncate w-[10%] px-2">{elm.stars}</div>
                  <div className="truncate w-[10%] px-2">
                    <img
                      className="max-h-10 max-w-10 object-contain"
                      src={elm.image}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {csvData.length > 0 && (
            <div className="flex gap-2 mt-4">
              <BoloButton
                size={"xs"}
                variant="bolo-secondary"
                onClick={() => handleCancel()}
              >
                Cancel
              </BoloButton>
              <BoloButton
                size={"xs"}
                disabled={addUserLoading}
                variant="bolo-primary"
                onClick={() => handleAddReviews()}
              >
                {addUserLoading ? (
                  <TailSpin color="#ffffff" height={15} width={15} />
                ) : (
                  "Add Reviews"
                )}
              </BoloButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BulkReviewUpload;
