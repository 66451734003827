import React, { useContext, useState } from "react";
import { RULER_HEIGHT, SIDEBAR_WIDTH } from "../../../../constants";
import { I18nContext } from "../../../../contexts";
import Divider from "../../../Divider";
import SelectableSortableContainer from "./SelectableSortableContainer";
import { SidebarProps } from "../index";
import { AiOutlineCaretDown } from "react-icons/ai";
const ListView = (
  props: Pick<
    SidebarProps,
    | "schemas"
    | "onSortEnd"
    | "onEdit"
    | "size"
    | "hoveringSchemaId"
    | "onChangeHoveringSchemaId"
    | "changeSchemas"
    | "receiversList"
  >
) => {
  const {
    schemas,
    onSortEnd,
    onEdit,
    size,
    hoveringSchemaId,
    onChangeHoveringSchemaId,
    changeSchemas,
    receiversList,
  } = props;
  const i18n = useContext(I18nContext);
  const [isBulkUpdateFieldNamesMode, setIsBulkUpdateFieldNamesMode] =
    useState(false);
  const [fieldNamesValue, setFieldNamesValue] = useState("");
  const height = size.height - RULER_HEIGHT - RULER_HEIGHT / 2 - 145;
  const isReceiversListValid =
    Array.isArray(receiversList) && receiversList.length > 0;
  return (
    <div
      className="overflow-y-auto pt-2"
      style={
        {
          // previosly was  h-[500px]
          // height: "calc(100% - 250px)",
        }
      }
    >
      {schemas?.length > 0 && (
        <div className="rounded flex items-center">
          <h3 className="text-xs capitalize font-medium mb-1.5">
            {isReceiversListValid ? "Signers List" : i18n("fieldsList")}
            {/* {!isShowList ? <AiOutlineCaretDown className="inline-block ml-2" /> : <AiOutlineCaretDown className="inline-block ml-2 transform rotate-180" />} */}
          </h3>
        </div>
      )}
      {/* <Divider /> */}
      {isBulkUpdateFieldNamesMode ? (
        <div>
          <textarea
            wrap="off"
            value={fieldNamesValue}
            onChange={(e) => setFieldNamesValue(e.target.value)}
            style={{
              height: height - 5,
              width: SIDEBAR_WIDTH,
              fontSize: "1rem",
              lineHeight: "2.5rem",
              background: "transparent",
              margin: 0,
              padding: "1rem",
              boxSizing: "border-box",
              fontFamily: "inherit",
            }}
          ></textarea>
        </div>
      ) : (
        <SelectableSortableContainer
          height={height}
          schemas={schemas}
          hoveringSchemaId={hoveringSchemaId}
          onChangeHoveringSchemaId={onChangeHoveringSchemaId}
          onSortEnd={onSortEnd}
          onEdit={onEdit}
        />
      )}

      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          fontSize: "0.75rem",
        }}
      > */}
      {/* {isBulkUpdateFieldNamesMode ? (
          <>
            <u
              onClick={() => {
                const names = fieldNamesValue.split('\n');
                if (names.length !== schemas.length) {
                  alert(i18n('errorBulkUpdateFieldName'));
                } else {
                  changeSchemas(
                    names.map((value, index) => ({
                      key: 'key',
                      value,
                      schemaId: schemas[index].id,
                    }))
                  );
                  setIsBulkUpdateFieldNamesMode(false);
                }
              }}
            >
              {i18n('commitBulkUpdateFieldName')}
            </u>
            <span style={{ margin: '0 1rem' }}>/</span>
            <u onClick={() => setIsBulkUpdateFieldNamesMode(false)}>{i18n('cancel')}</u>
          </>
        ) : (
          <u
            onClick={() => {
              setFieldNamesValue(schemas.map((s) => s.key).join('\n'));
              setIsBulkUpdateFieldNamesMode(true);
            }}
          >
            {i18n('bulkUpdateFieldName')}
          </u>
        )} */}
      {/* </div> */}
    </div>
  );
};

export default ListView;
