//@ts-nocheck

import React from "react";
import "./index.css";
import { useState } from "react";
import Modal from "../../Modal";
import { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { FiYoutube } from "react-icons/fi";

// Use @aws-sdk/client-s3 for uploading files to S3
// const AWS = require("aws-sdk");
// AWS.config.update({
//   region: "ap-south-1",
//   accessKeyId: "AKIAYJTP7V2HVEJ4PL55",
//   secretAccessKey: "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",
// });
// const s3 = new AWS.S3({
//   params: { Bucket: "boloforms-main/approval-dash" },
//   region: "ap-south-1",
// });

const PopUpContent = ({ popUp, setPopUp, question, setFormState }) => {
  const [videoUrl, setVideoUrl] = React.useState("");
  const [active, setActive] = React.useState({
    tab: "by-url",
  });

  useEffect(() => {
    if (videoUrl) {
      handleUrl(videoUrl);
    }
  }, [videoUrl]);

  function handleUrl(url_) {
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.videoUrl = url_;
          }
        });
      });
      return newState;
    });
  }

  function handleDelete() {
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.videoUrl = "";
          }
        });
      });
      return newState;
    });
    setPopUp((prev) => !prev);
  }

  function getVideoId(url) {
    const regExp = /(?:v=|youtu\.be\/|\/embed\/|watch\?v=)([a-zA-Z0-9-_]+)/;
    const match = regExp.exec(url);
    if (match) {
      return match[1];
    } else {
      return null;
    }
  }

  return (
    <div className="relative w-[768px] min-h-[440px]">
      <div className="mt-2 mb-6 flex justify-between">
        <h1 className="text-xl text-gray-500">Select Video</h1>
        <MdClose
          className="cursor-pointer text-2xl text-gray-600"
          onClick={() => setPopUp((prev) => !prev)}
        />
      </div>
      <div className="-mx-1 h-[48px] flex">
        {/* <div
          onClick={() => setActive((prev) => ({ ...prev, tab: "upload" }))}
          className={`${
            active.tab === "upload" ? "text-blue-500" : "text-[#3C4043]"
          }  relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
        >
          <span className="px-3">Upload</span>
          {active.tab === "upload" && (
            <div className="absolute bottom-0 w-full h-[3px] rounded-t-xl bg-blue-500"></div>
          )}
        </div> */}
        <div
          onClick={() => setActive((prev) => ({ ...prev, tab: "by-url" }))}
          className={`${
            active.tab === "by-url" ? "text-blue-500" : "text-[#3C4043]"
          }  relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
        >
          <span className="px-3">Youtube URL</span>
          {active.tab === "by-url" && (
            <div className="absolute bottom-0 w-full h-[3px] rounded-t-xl bg-blue-500"></div>
          )}
        </div>
      </div>
      <hr className="-mx-8" />
      {active.tab === "by-url" && (
        <div className="h-[420px] flex flex-col items-center relative">
          <div className="w-[420px] pt-[60px] flex flex-col items-center">
            <input
              type="text"
              value={videoUrl}
              placeholder="Paste Youtube URL here..."
              onChange={(e) => {
                setVideoUrl(e.target.value);
              }}
              className="w-full pb-0.5 text-gray-600 border-b-2 border-gray-200 hover:border-gray-200 disabled:hover:border-white focus:border-b-2 focus:border-blue-500 outline-none"
            />
            {videoUrl ? (
              <div className="mt-12">
                <iframe
                  width="305"
                  height="230"
                  src={`https://www.youtube.com/embed/${getVideoId(videoUrl)}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullscreen
                ></iframe>
              </div>
            ) : (
              <div>
                <p className="mt-12 w-full text-sm text-gray-400">
                  If your URL is correct, you'll see a video preview here. Large
                  videos may take a few minutes to appear.
                </p>
                <p className="mt-2 w-full text-xs text-gray-400">
                  Remember: Using others' videos on the web without their
                  permission may be bad manners, or worse, copyright
                  infringement.
                </p>
              </div>
            )}
          </div>
          <div className="absolute bottom-0 right-0 flex">
            <button
              className="mr-12 text-sm text-gray-500 hover:text-gray-600 uppercase"
              onClick={handleDelete}
            >
              Cancel
            </button>
            <button
              className="text-blue-500 font-semibold text-sm hover:text-gray-600 uppercase"
              onClick={() => setPopUp((prev) => !prev)}
            >
              Select
            </button>
          </div>
        </div>
      )}
      {/* <p className="p-2 text-sm text-gray-300">or</p>
      <div className="formbold-mb-5 formbold-file-input">
        <input
          type="file"
          name={`file-${question.id}`}
          id={`file-${question.id}`}
          accept="image/jpeg, image/png, image/svg, image/jpg"
          onChange={async (e) => {
            console.log(e.target.files);
            if (e.target.files[0]?.size > 10485760) {
              return toast.error("File size should be less than 10MB");
            }
            let location = await uploadFile(e.target.files[0]);
            if (location) {
              handleUrl(location);
            } else {
              toast.error("Could Not upload image");
            }
            // setFiles(e.target.files);
            // handleFile(e.target.files);
          }}
          className="w-full"
        />
        <label htmlFor={`file-${question.id}`}>
          {files?.length > 0 ? (
            <div className="flex flex-row items-center flex-wrap">
              <div
                className={`${
                  files?.length > 0 && "opacity-60"
                } w-[100px] p-4 h-full mr-4 border flex flex-col items-center bg-gray-100 rounded-md`}
              >
                <div>
                  <BsFillFileEarmarkSpreadsheetFill className="text-4xl text-gray-500" />
                </div>
                <div className="mt-6 w-full text-xs truncate">
                  {files[0].name}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <span className="formbold-drop-file text-gray-500">
                Drop a file here or click the button below to load the file.
              </span>
              <span className="formbold-browse">Choose file</span>
            </div>
          )}
        </label>
      </div>
      <p className="text-xs text-gray-500">
        Only .jpeg, .jpg, .png, .svg images accepted
      </p> */}
    </div>
  );
};

const QuestionVideo = ({ helpers }) => {
  let { question, setFormState, VideoIcon } = helpers;
  const [popUp, setPopUp] = useState(false);

  return (
    <div>
      {VideoIcon ? (
        <div
          onClick={(e) => {
            // e.stopPropagation();
            setPopUp((prev) => !prev);
          }}
        >
          {VideoIcon}
        </div>
      ) : (
        <div className="h-full ml-2">
          <div
            onClick={() => setPopUp((prev) => !prev)}
            className="min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
          >
            <FiYoutube className="text-2xl text-gray-600" />
          </div>
        </div>
      )}
      {popUp && (
        <Modal
          modalContent={
            <PopUpContent
              question={question}
              popUp={popUp}
              setPopUp={setPopUp}
              setFormState={setFormState}
            />
          }
        />
      )}
    </div>
  );
};

export default QuestionVideo;
