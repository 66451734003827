//@ts-nocheck

import React from "react";
import QuestionDesc from "../../QuestionDesc";
import QuestionInputOther from "../../QuestionInputOther";
import ImageQuestionPreview from "../../ImageQuestionPreview";

const Image = ({ data }) => {
  let {
    question,
    setFormState,
    isEditor,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
  } = data;

  return (
    <div className="w-full flex flex-row pb-2">
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <QuestionInputOther
              helpers={{
                question,
                setFormState,
                isEditor,
                questionIndex,
                activeState,
                setActiveState,
                setActive,
              }}
            />
          </div>
          <QuestionDesc
            helpers={{ question, setFormState, questionIndex, activeState }}
          />
        </div>
        <ImageQuestionPreview
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
        {/* <div
          style={{
            display: "flex",
            justifyContent: question?.align ?? "left",
          }}
          className="w-full"
        >
          {question?.imageUrl && (
            <div className="w-fit mb-6 relative">
              <div ref={moreOptionsRef} className="h-full">
                {isEditorActive && (
                  <div
                    onClick={() =>
                      setShow((prev) => ({
                        ...prev,
                        moreOptions: !prev.moreOptions,
                      }))
                    }
                    className="absolute w-[48px] h-[48px] flex justify-center items-center shadow-lg bg-gray-200 hover:bg-gray-300 cursor-pointer rounded-full"
                  >
                    <BsThreeDotsVertical className="text-xl text-gray-600" />
                  </div>
                )}
                {show.moreOptions && (
                  <MoreOptions helpers={{ question, setFormState, setShow }} />
                )}
                <img
                  src={question?.imageUrl}
                  alt="question-img"
                  className="w-fit h-fit pointer-events-none"
                />
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Image;
