import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store";

export const getWorkSpaceDetailsHookHelper = (user: any) => {
  const currentWorkSpaceId =
    localStorage.getItem("workspaceid") || user?.currentWorkSpaceId;

  if (!currentWorkSpaceId || currentWorkSpaceId == "undefined") {
    localStorage.removeItem("owner");
    localStorage.removeItem("workspaceid");
    localStorage.removeItem("currentUserEmail");
    localStorage.removeItem("accountType");
    localStorage.removeItem("isApprovalTimeline");
    localStorage.removeItem("formColumnPrefrences");
    localStorage.removeItem("respondentPageNo");
    localStorage.removeItem("x-auth-token");
    window.location.href = "/login";
  }
  const currentWorkSpace = user?.workSpaces?.find(
    (workspace: any) => workspace?.workSpaceId == currentWorkSpaceId
  );
  const isWorkSpaceOwner = user?.isWorkSpaceOwner;
  const currentWorkSpaceRole: "owner" | "admin" | "editor" | "readonly" =
    user?.currentWorkSpaceRole;
  let formattedRole = "";
  if (isWorkSpaceOwner) {
    formattedRole = "Owner";
  }
  if (currentWorkSpaceRole == "readonly") {
    formattedRole = "Read Only";
  }
  if (currentWorkSpaceRole == "editor") {
    formattedRole = "Editor";
  }
  if (currentWorkSpaceRole == "admin") {
    formattedRole = "Administrator";
  }

  let isStripeConnectedAccount = !!currentWorkSpace?.stripeConnectedAccount;
  let isStripeAccountActive =
    !!currentWorkSpace?.stripeConnectedAccount?.charges_enabled &&
    !!currentWorkSpace?.stripeConnectedAccount?.payouts_enabled;
  // this is just for easy access and linting
  let stripeData = {
    isStripeConnectedAccount,
    isStripeAccountActive,
    id: currentWorkSpace?.stripeConnectedAccount?.id,
    default_currency:
      currentWorkSpace?.stripeConnectedAccount?.default_currency,
  };

  return {
    currentWorkSpace,
    currentWorkSpaceId,
    isWorkSpaceOwner,
    currentWorkSpaceRole,
    formattedRole,
    stripeData,
  };
};

const useWorkSpaceDetails = () => {
  const user = useAppSelector((state) => state.root.ownerSlice.data.owner);

  const [data, setData] = useState(getWorkSpaceDetailsHookHelper(user));

  useEffect(() => {
    setData(getWorkSpaceDetailsHookHelper(user));
  }, [user]);

  return data;
};

export default useWorkSpaceDetails;
