import { readAsArrayBuffer } from "./asyncReader";
// import { getAsset } from './prepareAssets';
import { normalize } from "./helpers";
import {
  pushGraphicsState,
  setLineCap,
  popGraphicsState,
  setLineJoin,
  LineCapStyle,
  LineJoinStyle,
  rgb,
} from "pdf-lib";
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";
import PDFMerger from "pdf-merger-js/browser";

export async function save(
  pdfFile: File,
  objects: Attachments[],
  name: string
) {
  // const PDFLib = await getAsset('PDFLib');
  // const download = await getAsset('download');
  let pdfDoc: any;
  // let pdfDoc: {
  //   getPages: () => any[];
  //   embedFont: (arg0: unknown) => any;
  //   embedJpg: (arg0: unknown) => any;
  //   embedPng: (arg0: unknown) => any;
  //   embedPdf: (arg0: any) => [any] | PromiseLike<[any]>;
  //   save: () => any;
  // };
  try {
    pdfDoc = await PDFDocument.load(await readAsArrayBuffer(pdfFile));
  } catch (e) {
    console.log("Failed to load PDF.");
    throw e;
  }

  const pagesProcesses = pdfDoc
    .getPages()
    .map(async (page: any, pageIndex: any) => {
      const pageObjects = objects[pageIndex];
      // 'y' starts from bottom in PDFLib, use this to calculate y
      const pageHeight = page.getHeight();
      const embedProcesses = pageObjects.map(async (object: Attachment) => {
        if (object.type === "image") {
          const { file, x, y, width, height } = object as ImageAttachment;
          let img: any;
          try {
            if (file.type === "image/jpeg") {
              img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
            } else {
              img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
            }

            // Calculate aspect ratio
            const aspectRatio = img.width / img.height;

            // Calculate the adjusted height based on the specified width
            const adjustedHeight = width / aspectRatio;

            return () =>
              page.drawImage(img, {
                x,
                y: pageHeight - y - adjustedHeight, // Adjusted height instead of height
                width,
                height: adjustedHeight, // Adjusted height instead of height
              });
          } catch (e) {
            console.log("Failed to embed image.", e);
            throw e;
          }
        } else if (object.type === "text") {
          const { x, y, text, lineHeight, size, fontFamily, width } =
            object as TextAttachment;
          console.log("text", fontFamily);
          const pdfFont = await pdfDoc.embedFont(fontFamily);
          return () =>
            page.drawText(text, {
              maxWidth: width,
              font: pdfFont,
              size,
              lineHeight,
              x,
              y: pageHeight - size! - y,
            });
        } else if (object.type === "placeholder") {
          const { x, y, email, fontFamily, size, width } =
            object as PlaceholderAttachment;
          console.log("placeholder", fontFamily);
          const pdfFont = await pdfDoc.embedFont("Helvetica");
          return () =>
            page.drawText(`____ ${email}`, {
              maxWidth: width,
              size: 16,
              x,
              y: pageHeight - size! - y,
              font: pdfFont,
            });
        } else if (object.type === "drawing") {
          const { x, y, path, scale, stroke, strokeWidth, height, width } =
            object as DrawingAttachment;
          return () => {
            page.pushOperators(
              pushGraphicsState(),
              setLineCap(LineCapStyle.Round),
              setLineJoin(LineJoinStyle.Round)
            );

            const color = window.w3color(stroke!).toRgb();
            // console.log("drawing line 106", color);
            // const color = {r: 128, g: 0, b: 128, a: 1}
            page.drawSvgPath(path, {
              borderColor: rgb(
                normalize(color.r),
                normalize(color.g),
                normalize(color.b)
              ),
              borderWidth: strokeWidth,
              scale: Math.min((110 / height) * scale!, (180 / width) * scale!),
              x: x + 180 > page.getWidth() ? x - 5 : x,
              y: pageHeight - y + 20,

              //! WHEN YOU ARE ADDING THESE ATTACHMENTS THERE ARE 5 TO 10 pxs DISCREPANCY IN THE X AND Y COORDINATES
            });
            page.pushOperators(popGraphicsState());
          };
        }
      });
      // embed objects in order
      const drawProcesses: any[] = await Promise.all(embedProcesses);
      drawProcesses.forEach((p) => p());
    });
  await Promise.all(pagesProcesses);
  try {
    const pdfBytes = await pdfDoc.save();
    console.log("PDF download started.");

    // const { data, error } = await supabase.storage
    //   .from("pdf-store")
    //   .upload(name, pdfBytes, {
    //     cacheControl: "3600",
    //     upsert: false,
    //     contentType: "application/pdf",
    //   });

    // if (error) {
    //   console.log(error);
    // }
    // const uploadedUrl = supabase.storage.from("pdf-store").getPublicUrl(name);
    // console.log(uploadedUrl);

    download(pdfBytes, name, "application/pdf");
  } catch (e: any) {
    console.log(e.message);
    console.log("Failed to save PDF.");
    throw e;
  }
}

export const mergePdfs = async (files: File[]) => {
  try {
    const merger = new PDFMerger();

    for (const file of files) {
      await merger.add(file);
    }

    await merger.setMetadata({
      producer: "pdf-merger-js based script",
    });

    const mergedPdf = await merger.saveAsBlob();

    return mergedPdf;
  } catch (error) {
    console.log("Error while merging  pdf");
  }
};
