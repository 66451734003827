import { FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BsSearch, BsThreeDotsVertical } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import { MdDelete, MdEdit } from "react-icons/md";
import { Slide, toast, ToastContainer } from "react-toastify";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/Common/Dialog/BoloDialog";
import { BoloInput } from "../../components/Common/Input/BoloInput";
import BoloRadioGroupMui, {
  BoloRadioMui,
} from "../../components/Common/RadioGroup/BoloRadioGroupMui";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/Common/SelectDropDown/BoloDropDown";
import DashboardFilter from "../../components/DashboardFilter/DashboardFilter";
import NavButton from "../../components/DashboardFilter/NavButton";
import { IMAGE_LINKS } from "../../constants/common";
import useWorkSpaceDetails from "../../hooks/reduxHooks/useGetWorkSpaceDetails";
import useQueryHook from "../../hooks/reduxHooks/useQueryHook";
import useQuoteBuilder, {
  DeleteCategoryFuncType,
  GetCategoryStateType,
  ProductCategoryType,
  ProductType,
  RenameCategoryFuncType,
} from "../../hooks/reduxHooks/useQuoteBuilder";
import { useAppSelector } from "../../redux/store";
import ProductInfo from "./ProductInfo";
import ProductsTable from "./ProductsTable";

export type ProductState = {
  show: "PROD_TABLE" | "PROD_INFO";
  productDetails: ProductType | null;
  category: {
    show: "rename" | "delete" | null;
    category: ProductCategoryType | null;
  };
};

const CreatCategory = ({
  createCatFunc,
  category,
}: {
  category: GetCategoryStateType;
  createCatFunc: ({ name }: { name: string }) => Promise<void>;
}) => {
  const workSpaceDetails = useWorkSpaceDetails();
  const [categoryName, setCategoryName] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  // const handleCreate = async () => {
  //   try {
  //     const { data } = await axios.post("/category", { name: category });
  //     if (data?.success) {
  //       toast.success("Category Created");
  //     } else {
  //       toast.error(data?.message || "Something went wrong");
  //     }
  //     setCategoryName("");
  //   } catch (error) {
  //     console.log("error in handle create");
  //     console.log(error);
  //     toast.error("Something went wroong");
  //   }
  //   setIsOpen(false);
  // };
  const handleCreate = async () => {
    try {
      if (
        category?.categories?.filter(
          (cat) => cat.category === categoryName.trim()
        ).length
      ) {
        return toast.error("Category Already Exixts");
      }
      await createCatFunc({ name: categoryName?.trim() });
      setCategoryName("");
    } catch (error) {
      console.log("Error in handle create");
      console.log(error);
      toast.error("Something went wroong");
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <BoloButton
          variant={"gray"}
          size="icon-sm"
          className="font-semibold"
          disabled={
            {
              owner: false,
              admin: false,
              editor: false,
              readonly: true,
            }[workSpaceDetails.currentWorkSpaceRole]
          }
        >
          <FaPlus size={16} />
        </BoloButton>
      </DialogTrigger>
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Create Category</DialogTitle>
        </DialogHeader>
        <BoloInput
          bolosize={"sm"}
          placeholder="Category Name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <DialogFooter>
          <BoloButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </BoloButton>

          <BoloButton
            variant={"primary"}
            size={"sm"}
            onClick={() => {
              handleCreate();
            }}
          >
            Create
          </BoloButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const DeleteCategory = ({
  selectedCategory,
  category,
  setIsOpen,
  isOpen,
  deleteCatFunc,
}: {
  category: GetCategoryStateType;
  selectedCategory: ProductCategoryType | null;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  deleteCatFunc: DeleteCategoryFuncType;
}) => {
  const [option, setOption] = useState<string>("move");
  const { setSearchData } = useQueryHook();
  const handleDelete = async () => {
    try {
      const data = await deleteCatFunc({
        name: selectedCategory?.category || "",
        action: option === "move" ? "move" : "delete",
      });
      setSearchData({ pageNo_: 1, pCategory_: "" });
    } catch (error) {
      console.log("Error in handle create");
      console.log(error);
      toast.error("Something went wroong");
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {/* <DialogTrigger asChild>
        <BoloButton variant={"gray"} size="icon-sm" className="font-semibold">
          <FaPlus size={16} />
        </BoloButton>
      </DialogTrigger> */}
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Delete Category</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-1">
          <p className="pb-4">
            You're about to{" "}
            <span className="text-red-600 font-semibold">
              permanently delete
            </span>{" "}
            this category "{selectedCategory?.category}", This action cannot be
            undone
          </p>
          <BoloRadioGroupMui
            value={option}
            onChange={(e) => {
              setOption(e.target.value);
            }}
          >
            <label htmlFor="" className="">
              <div className="flex gap-2 items-center">
                <FormControlLabel
                  sx={{ marginRight: 0 }}
                  control={<BoloRadioMui value="move" />}
                  label=""
                  value="move"
                />
                <p>
                  <span className="font-semibold mr-2">
                    Delete only this category.
                  </span>
                  Items within it will be moved to the root category.
                </p>
              </div>
            </label>
            <div className="flex gap-2 items-center">
              <FormControlLabel
                sx={{ marginRight: 0 }}
                control={<BoloRadioMui />}
                label=""
                value="delete"
              />
              <p>
                <span className="font-semibold mr-2">
                  Delete category and all items within.
                </span>
                You'll be unable to recover these items.
              </p>
            </div>
          </BoloRadioGroupMui>
        </div>

        <DialogFooter>
          <BoloButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </BoloButton>

          <BoloButton
            variant={"danger-border"}
            size={"sm"}
            onClick={() => {
              handleDelete();
            }}
          >
            Delete
          </BoloButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const RenameCategory = ({
  selectedCategory,
  category,
  setIsOpen,
  isOpen,
  renameCatFunc,
}: {
  category: GetCategoryStateType;
  selectedCategory: ProductCategoryType | null;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  renameCatFunc: RenameCategoryFuncType;
}) => {
  const [newName, setNewName] = useState<string>(
    selectedCategory?.category || ""
  );
  const { setSearchData } = useQueryHook();
  const handleRename = async () => {
    try {
      const data = await renameCatFunc({
        category: selectedCategory?.category || "",
        toCategory: newName,
      });
      setNewName("");
      setSearchData({ pageNo_: 1, pCategory_: "" });
    } catch (error) {
      console.log("Error in handle create");
      console.log(error);
      toast.error("Something went wroong");
    }
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      {/* <DialogTrigger asChild>
        <BoloButton variant={"gray"} size="icon-sm" className="font-semibold">
          <FaPlus size={16} />
        </BoloButton>
      </DialogTrigger> */}
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Rename Category</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-1">
          <p className="pb-4">
            Renaming category will result in all products listed in this
            category to change to the new category
          </p>
          <BoloInput
            bolosize={"sm"}
            placeholder={`New Name for ${selectedCategory?.category || ""}`}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </div>

        <DialogFooter>
          <BoloButton
            variant={"secondary"}
            size={"sm"}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </BoloButton>

          <BoloButton
            variant={"primary"}
            size={"sm"}
            onClick={() => {
              handleRename();
            }}
          >
            Rename
          </BoloButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const ProductCatalog = () => {
  const [searchText, setSearchText] = useState("");
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const {
    category,
    createCatFunc,
    deleteCatFunc,
    renameCatFunc,
    product,
    createProductFunc,
    deleteProductFunc,
  } = useQuoteBuilder({});
  const workSpaceDetails = useWorkSpaceDetails();
  const { getSearchData, setSearchData } = useQueryHook();
  const [productState, setProductState] = useState<ProductState>({
    show: "PROD_TABLE",
    productDetails: null,
    category: {
      show: null,
      category: null,
    },
  });

  const handleSearch = () => {
    console.log("handle search called");
    setSearchData({ pageNo_: 1, query_: searchText });
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData({ pageNo_: 1, query_: "" });
    }
  }, [searchText]);

  return (
    <div className="flex flex-col h-full">
      <Helmet>
        <title translate="no">Product Catalog</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Product Catalog" />
        <meta
          name="description"
          content="Access and manage all products and categories in one place."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/product-catalog`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Product Catalog`} />
        <meta
          property="og:description"
          content="Access and manage all products and categories in one place"
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/product-catalog"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      <div className="flex flex-col gap-2 md:flex-row items-center justify-between w-full max-h-[60px] h-[60px] pb-4 border-b border-gray-300">
        <div
          className="flex gap-2 w-[100%] justify-start 
                      md:justify-between md:items-center md:w-fit"
        >
          <NavButton recordCount={0} />
          {/* <CreatCategory /> */}
          <BoloButton
            variant={"primary"}
            size={"sm"}
            disabled={
              { owner: false, admin: false, editor: false, readonly: true }[
                workSpaceDetails.currentWorkSpaceRole
              ]
            }
            className="flex items-center gap-2 h-[34px] md:h-10 whitespace-nowrap text-xs md:text-sm px-2 md:px-4 rounded-md"
            onClick={() =>
              setProductState((prev) => ({
                ...prev,
                show: "PROD_INFO",
                productDetails: null,
              }))
            }
          >
            Product <FaPlus />
          </BoloButton>
        </div>
        <div
          className="flex gap-2 w-[100%] justify-between 
                        md:items-center md:w-fit"
        >
          <form
            onSubmit={handleSearch}
            className={`h-full text-xs flex flex-row gap-2 w-full md:w-[500px]`}
          >
            <div className="border shadow overflow-hidden h-full flex rounded-md w-full md:w-[500px]">
              <input
                placeholder="Search by Name, SKU or Category"
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full md:w-[500px] h-full py-2 px-4   outline-none text-gray-700 "
              />
              <button
                type="submit"
                className="min-w-[40px] flex justify-center items-center bg-primary text-white cursor-pointer hover:opacity-90"
                onClick={(e) => {
                  e.preventDefault();
                  handleSearch();
                }}
              >
                <BsSearch className="text-xl" />
              </button>
            </div>
          </form>
          <DashboardFilter />
        </div>
      </div>
      {/* main products view with categories */}

      <div className="flex-col pb-4 flex h-[calc(100%_-_60px)]">
        <div className="flex h-[calc(100%_-_4px)]">
          <div
            className="flex flex-col pr-3 text-xs border-r border-gray-300 w-[200px] pt-4 overflow-y-auto"
            id="mini-pdf-custom-scroll"
          >
            <BoloButton
              variant={"gray"}
              size="sm"
              className={`text-left font-semibold text-xs md:text-xs py-2 sm:py-2 mb-4 ${
                getSearchData().pCategory_ ? "" : "bg-gray-100"
              }`}
              onClick={() => {
                setProductState((prev) => ({ ...prev, show: "PROD_TABLE" }));
                setSearchData({ pCategory_: "", pageNo_: 1 });
              }}
            >
              ALL
            </BoloButton>
            <div className="pl-3 text-gray-600 font-semibold flex items-center justify-between py-1 mb-2">
              CATEGORIES{" "}
              <CreatCategory
                category={category}
                createCatFunc={createCatFunc}
              />
            </div>
            {category?.categories?.map((cat) => {
              return (
                <BoloButton
                  variant={"gray"}
                  size="sm"
                  className={`group flex justify-between items-center text-left text-xs md:text-xs py-2 sm:px-2 pr-0 truncate min-h-[40px] ${
                    getSearchData()?.pCategory_ === cat.category
                      ? "bg-gray-100"
                      : ""
                  }`}
                  onClick={() => {
                    setProductState((prev) => ({
                      ...prev,
                      show: "PROD_TABLE",
                    }));
                    setSearchData({ pCategory_: cat.category, pageNo_: 1 });
                  }}
                >
                  <p className="truncate pr-2">{cat?.category}</p>

                  {/* <BsThreeDotsVertical size={16} /> */}
                  <div>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <div
                          className="group-hover:block min-w-[16px] rounded hover:bg-gray-200 py-1"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <BsThreeDotsVertical size={16} />
                        </div>
                      </DropdownMenuTrigger>
                      <DropdownMenuGroup>
                        <DropdownMenuContent
                          align="end"
                          sideOffset={10}
                          className=""
                        >
                          <DropdownMenuItem
                            disabled={
                              {
                                owner: false,
                                admin: false,
                                editor: false,
                                readonly: true,
                              }[workSpaceDetails.currentWorkSpaceRole]
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              setProductState((prev) => ({
                                ...prev,
                                category: {
                                  ...prev.category,
                                  show: "rename",
                                  category: cat,
                                },
                              }));
                            }}
                          >
                            <div className="flex items-center text-xs">
                              <MdEdit className="mr-2" /> Rename
                            </div>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            disabled={
                              {
                                owner: false,
                                admin: false,
                                editor: true,
                                readonly: true,
                              }[workSpaceDetails.currentWorkSpaceRole]
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              setProductState((prev) => ({
                                ...prev,
                                category: {
                                  ...prev.category,
                                  show: "delete",
                                  category: cat,
                                },
                              }));
                            }}
                          >
                            <div className="flex items-center text-xs text-red-600">
                              <MdDelete className="mr-2" /> Delete
                            </div>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenuGroup>
                    </DropdownMenu>
                  </div>
                </BoloButton>
              );
            })}
          </div>
          <DeleteCategory
            deleteCatFunc={deleteCatFunc}
            isOpen={productState.category.show === "delete"}
            setIsOpen={(value) =>
              setProductState((prev) => ({
                ...prev,
                category: { ...prev.category, show: value ? "delete" : null },
              }))
            }
            category={category}
            selectedCategory={productState.category.category}
          />
          <RenameCategory
            renameCatFunc={renameCatFunc}
            isOpen={productState.category.show === "rename"}
            setIsOpen={(value) =>
              setProductState((prev) => ({
                ...prev,
                category: { ...prev.category, show: value ? "rename" : null },
              }))
            }
            category={category}
            selectedCategory={productState.category.category}
          />
          <div
            className="p-4 w-[calc(100%_-_200px)] overflow-y-auto pb-0"
            id="mini-pdf-custom-scroll"
          >
            {productState?.show === "PROD_INFO" && (
              <ProductInfo
                createProductFunc={createProductFunc}
                productState={productState}
                setProductState={setProductState}
              />
            )}
            {productState?.show === "PROD_TABLE" && (
              <ProductsTable
                product={product}
                deleteProdFunc={deleteProductFunc}
                products={product.products}
                productState={productState}
                setProductState={setProductState}
              />
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default ProductCatalog;
