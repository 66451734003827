import React from "react";
import { MdLink } from "react-icons/md";
import { v4 } from "uuid";
import { SidebarProps } from "..";
import { BoloButton } from "../../../../../../../components/Common/Button/BoloButton";
import { SchemaForUI } from "../../../../../../common/src";
import MixPanel from "../../../../../../../utils/services/mixpanel";

// AY Note
/*
This function is exported from designer index
if i get time use the  function exported from designer index
and remove copy form here
 */

const generateUniqueKey = (
  schemasList: SchemaForUI[][],
  baseKey: string,
  explicitPageCursor: number
) => {
  const pageSchemas = schemasList[explicitPageCursor];
  let uniqueKey = baseKey.replace(/\n$/, "");
  let index = 1;
  while (
    pageSchemas.some(
      (schema) => schema.key === uniqueKey + `(${explicitPageCursor + 1})`
    )
  ) {
    uniqueKey = `${baseKey.replace(/\n$/, "")}___${index.toString()}`;
    index++;
  }
  uniqueKey = `${uniqueKey}(${explicitPageCursor + 1})`;

  return uniqueKey;
};

const LinkFields = (
  props: SidebarProps & { activeSchema: SchemaForUI | undefined }
) => {
  const { activeSchema, designerState, setDesignerState, pageSize } = props;

  const handleCopyAndLink = () => {
    const schema = designerState?.schemasList;
    const newSchema: SchemaForUI[][] = [];
    let linkGroupId = activeSchema?.linkGroup || v4();

    for (let page of schema) {
      let newPage: SchemaForUI[] = [];
      for (let schema_ of page) {
        if (schema_?.id == activeSchema?.id) {
          let originalSchema = { ...schema_ };
          let linkedSchema = { ...schema_ };

          originalSchema.linkGroup = linkGroupId;
          linkedSchema.linkGroup = linkGroupId;

          let baseKey = schema_.key.includes("___")
            ? schema_.key.split("___")[0]
            : schema_.key.split("(")[0];
          const oldQuestionId = schema_?.id?.split("_")?.[1];

          let newPosition = { ...linkedSchema.position };

          let heightx2 = linkedSchema.height * 2;
          let newLineEnd = linkedSchema.position.y + heightx2 + 4;
          if (newLineEnd > pageSize.height) {
            newPosition.y = linkedSchema.position.y - linkedSchema.height - 4;
          } else {
            newPosition.y = linkedSchema.position.y + linkedSchema.height + 4;
          }

          linkedSchema.key = generateUniqueKey(
            designerState?.schemasList,
            baseKey,
            designerState?.pageCursor // considering we can duplicate only on the same page
          );
          linkedSchema.id = v4() + "_" + oldQuestionId + "_" + oldQuestionId;
          linkedSchema.position = newPosition;

          console.log({ linkedSchema, originalSchema });

          newPage.push(originalSchema);
          newPage.push(linkedSchema);
        } else {
          newPage.push(schema_);
        }
      }
      newSchema.push(newPage);
    }

    console.log({ newSchema });
    setDesignerState((prev) => ({ ...prev, schemasList: newSchema }));
  };

  return (
    <div>
      <p className="text-gray-600 text-xs mb-4">
        Fields sharing the same field ID will be linked and autofilled.{" "}
      </p>
      <BoloButton
        onClick={() => {
          let Mixpanel = new MixPanel();

          if (designerState?.instanceFrom === "PDF") {
            Mixpanel.track("PDF", {
              pdf_action: `pdf_editor_copy_link_field`,
              paid: false,
            });
            Mixpanel.increment(`pdf_editor_copy_link_field`);
          } else if (designerState?.instanceFrom === "PDF-TEMPLATE") {
            Mixpanel.track("PDF template", {
              pdf_template_action: `pdf_template_editor_copy_link_field`,
              paid: false,
            });
            Mixpanel.increment(`pdf_template_editor_copy_link_field`);
          }
          handleCopyAndLink();
        }}
        size={"xs"}
        variant="secondary"
        className="flex items-center gap-2 px-4 w-full justify-center"
      >
        <MdLink size={20} /> Copy and Link field
      </BoloButton>
    </div>
  );
};

export default LinkFields;
