import { useEffect, useRef, useState } from "react";
// import axios from "axios";
import { FaArrowDown, FaExternalLinkAlt, FaSignature } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { IoLanguageSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../utils/axios";
import { BASE_URL } from "../../constants/constants";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IMAGE_LINKS } from "../../constants/common";
import { googleTranslateElementInit } from "../../utils/uiUtils";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import zIndex from "@mui/material/styles/zIndex";
import { HiOutlineDocumentSearch } from "react-icons/hi";
type Props = {
  loading: boolean;
  isSigner: boolean;
  startSigning?: boolean;
  setStartSigning: any;
  onClick: () => void;
  email?: string;
  documentId: any;
  extraDetails?: {
    authorName: string;
    authorEmail: string;
    signerName: string;
    signerId: string;
  };
  signerName?: string;
  authorName?: string;
  isOnlyPDF: Boolean;
  isDocumentAvailableToSign: Boolean;
  accountInfo?: any;
  isApprover?: boolean;
};

const StartSigningSignerPage = ({
  loading,
  isSigner,
  setStartSigning,
  onClick,
  email,
  documentId,
  extraDetails,
  isOnlyPDF,
  isDocumentAvailableToSign,
  accountInfo,
  isApprover = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );

  const [authorEmail, setAuthorEmail] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [signerName, setSignerName] = useState("");
  const [otherOptions, setOtherOptions] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(false);
  const [isDeclineToSign, setIsDeclineToSign] = useState(false);
  // const [accountInfo, setAccountInfo] = useState<any>(null);

  const otherOptionsRef = useRef<any>(null);
  console.log("isDocumentAvailableToSign", isDocumentAvailableToSign);

  // const googleTranslateElementInit = () => {
  //   new (window as any).google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       includedLanguages:
  //         "en,fr,es,de,id,pt,nl,zh-CN,th,ja,ar,iw,cs,da,fil,it,ru,tr,vi",
  //     },
  //     "google_translate_element"
  //   );

  //   let skiptranslate = document.querySelector(".skiptranslate");
  //   if (skiptranslate?.lastChild) {
  //     skiptranslate.removeChild(skiptranslate.lastChild);
  //   }
  //   // removes google translate script error
  //   if (skiptranslate?.lastChild) {
  //     skiptranslate.removeChild(skiptranslate.lastChild);
  //   }
  // };

  // const getBrandingInfo = async () => {
  //   try {
  //     let { data } = await axios.post(`${BASE_URL}/owner/get-branding-info`, {
  //       id: documentId,
  //       type: isOnlyPDF ? "PDF" : "PDF-TEMPLATE",
  //     });
  //     if (data?.accountInfo) {
  //       setAccountInfo(data?.accountInfo);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    let googleTranslateScript = document.getElementById(
      "google_translate_script"
    );
    // removes set attribute error for google translate
    if (googleTranslateScript) {
    } else {
      googleTranslateScript = document.createElement("script");
      googleTranslateScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      googleTranslateScript.setAttribute("id", "google_translate_script");
      document.body.appendChild(googleTranslateScript);
      (window as any).googleTranslateElementInit = googleTranslateElementInit;
    }
  }, []);

  useEffect(() => {
    const getDocumentDetails = async () => {
      if (!documentId) return;
      try {
        const { data } = await axios.post(`/getDocumentDetails`, {
          documentId,
          email,
        });
        if (!data.error) {
          setAuthorEmail(data.document.authorEmail);
          setAuthorName(data.document.authorName);
          setSignerName(data.document.signerName);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (extraDetails) {
      setAuthorEmail(extraDetails.authorEmail);
      setAuthorName(extraDetails.authorName);
      setSignerName(extraDetails.signerName);
    } else {
      getDocumentDetails();
    }
    //todo replace this extra query to backend
  }, [authorEmail, documentId, email, authorName, signerName, extraDetails]);

  const startSigningHandler = () => {
    if (!loading && isSigner) {
      onClick();
      setStartSigning(true);
    } else {
      toast.error("Please upload a PDF file first");
      return;
    }
  };

  const goBackHandler = () => {
    setStartSigning(false);
  };

  const handleDeclineSigning = async () => {
    const ipData = getIpAddressLocal();
    const { ip } = await ipData;
    const response = await axios.post(`${BASE_URL}/declineSigning`, {
      documentId: documentId,
      isOnlyPDF: isOnlyPDF,
      signerId: extraDetails?.signerId,
      email: email,
      ip: ip,
      signerName,
    });
    window.location.reload();
  };

  const handleOutsideClick = (e: any) => {
    if (otherOptionsRef.current !== null) {
      if (otherOptionsRef.current.contains(e?.target)) return;
    }
    setOtherOptions(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const selectedLanguage = accountInfo?.selectedLanguage;
    if (selectedLanguage) {
      window.location.hash = `googtrans(${selectedLanguage})`;
    }
  }, []);

  // commenting this out i dont know why i did this twice
  // useEffect(() => {
  //   dispatch(getWhiteLabelInfoThunk());
  // }, []);

  if (loading) {
    return null;
  }

  const faqData = [
    {
      id: 1,
      question: "What is BoloForms Signature?",
      answer:
        "BoloForms Signature is a user-friendly electronic signature solution that enables you to easily request, collect, and manage electronic signatures on PDF documents & Forms,  eliminating the need for manual printing, signing, and scanning.",
    },
    {
      id: 2,
      question: "How does BoloForms Signature work?",
      answer:
        "For the sender, the process involves adding recipient details, uploading the PDF document, placing signature fields, and sending the request. Receivers receive an email, sign the document online, and the signed document is available for download by the sender.",
    },
    {
      id: 3,
      question: "Is BoloForms Signature secure and legally binding?",
      answer:
        "Yes, BoloForms Signature implements robust security measures to protect sensitive documents and ensure the legality and validity of the electronic signatures, making them legally binding in many jurisdictions.",
    },
    {
      id: 4,
      question: "Can I request signatures from multiple recipients?",
      answer:
        "Absolutely! BoloForms Signature supports multi-recipient signature requests, allowing you to collaborate and gather signatures from multiple individuals on a single document.",
    },
    {
      id: 5,
      question: "What signature options are available for recipients?",
      answer:
        "Recipients have versatile options: they can draw their signature using a mouse or touchscreen, upload a pre-made signature image, or simply type their signature as text.",
    },
    {
      id: 6,
      question: "How can I track the progress of my signature requests?",
      answer:
        "You can use the real-time dashboard provided by BoloForms Signature to track the progress of your signature requests. The dashboard offers an overview of pending and completed signatures, along with notifications when documents are signed.",
    },
    {
      id: 7,
      question: "How many signatures can I take with BoloForms Signature?",
      answer: `You get 10 signatures for free to get started. Visit our pricing page to `,
    },
  ];

  const DeclineToSign = () => {
    const divRef = useRef<any>(null);
    const handleOutsideClick = (e: any) => {
      if (divRef.current !== null) {
        if (divRef.current.contains(e?.target)) return;
      }
      setIsDeclineToSign(false);
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div className="flex z-30 absolute h-screen w-full top-0  left-0 items-center justify-center">
        <div
          className="flex flex-col gap-6 p-4 bg-white shadow rounded text-sm max-w-[95vw]"
          ref={divRef}
        >
          <div className="font-semibold text-base">
            Caution{" "}
            <MdClose
              className="absolute right-4 top-4 cursor-pointer"
              size={20}
              onClick={() => {
                setIsDeclineToSign(false);
              }}
            />
          </div>
          <div className="border-b -mx-4"></div>
          <div className="flex flex-col gap-4 text-xs">
            <div>
              If you select <span className="font-semibold">Continue</span>,
              this document will be void.
            </div>
            <div>
              To request changes to this document, please select{" "}
              <span className="font-semibold">Cancel</span> and contact the
              sender directly with your request.
            </div>
          </div>
          <div className="border-b -mx-4"></div>
          <div className="flex gap-4 items-center justify-start text-sm">
            <button
              tabIndex={7}
              className="px-3 py-1 bg-primary hover:bg-primary/80 font-semibold rounded shadow  text-white transition-all duration-300 ease-in-out"
              style={{
                background: accountInfo?.theme,
                color: accountInfo?.themeText,
              }}
              onClick={() => {
                handleDeclineSigning();
              }}
            >
              CONTINUE
            </button>
            <button
              tabIndex={8}
              className="px-3 py-1 bg-white hover:bg-gray-100 font-semibold rounded shadow  text-primary transition-all duration-300 ease-in-out"
              style={{
                color: accountInfo?.theme,
              }}
              onClick={() => {
                setIsDeclineToSign(false);
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    );
  };

  const SessionInformation = () => {
    const divRef = useRef<any>(null);
    const handleOutsideClick = (e: any) => {
      if (divRef.current !== null) {
        if (divRef.current.contains(e?.target)) return;
      }
      setSessionInfo(false);
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, []);

    return (
      <div className="flex z-30 absolute h-screen w-full top-0  left-0 items-center justify-center">
        {" "}
        <div
          className="flex flex-col gap-6 p-4 bg-white shadow rounded text-sm relative max-w-[95vw]"
          ref={divRef}
        >
          <div className="font-semibold text-base">
            Session Information{" "}
            <button
              className="absolute right-4 top-4 cursor-pointer"
              autoFocus
              onClick={() => {
                setSessionInfo(false);
              }}
            >
              <MdClose size={20} />
            </button>
          </div>
          <div className="border-b -mx-4"></div>
          <div className="flex justify-between gap-8 text-xs">
            <div>Envelope ID</div>
            <div className="font-semibold">{documentId}</div>
          </div>
          <div className="flex justify-between gap-8 text-xs">
            <div>Signer ID</div>
            <div className="font-semibold">{extraDetails?.signerId}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="fixed h-screen bg-black bg-opacity-10 inset-0 z-10 flex flex-col">
      <div
        className="flex absolute top-5 right-6 ml-auto gap-2 p-1 bg-primary/80 hover:text-primary rounded-full transition-all ease-in-out duration-300 shadow hover:scale-110"
        style={{
          background: accountInfo?.theme,
          color: accountInfo?.themeLight,
          zIndex: 100,
        }}
      >
        <div className="flex justify-start items-center p-[10px] gap-4">
          <IoLanguageSharp
            className={`text-lg md:text-xl xl:text-2xl `}
            style={{
              color: accountInfo?.themeText,
            }}
          />
        </div>
        <div
          id="google_translate_element"
          className="absolute inset-0 h-full opacity-0 cursor-pointer"
        ></div>
      </div>
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 50px",
          background: accountInfo?.themeLight,
        }}
        className="slide-top-enter px-2 md:px-12 h-full md:h-fit border bg-secondary flex flex-col items-center justify-center py-20"
      >
        <div className="heading flex gap-3 items-center justify-center">
          <img
            src={
              accountInfo?.companyLogo ||
              whiteLabelInfo?.settings?.organization?.logo ||
              IMAGE_LINKS.BOLOLOGO
            }
            alt="logo"
            className="h-12 aspect-square rounded-full"
          />
          <h1
            className="text-gray-700 text-2xl md:text-2xl font-bold"
            style={{
              color: accountInfo?.themeText,
            }}
          >
            {accountInfo?.companyName ||
              whiteLabelInfo?.settings?.organization?.name ||
              "BoloForms Signature"}
          </h1>
        </div>

        <div className="mt-6 flex flex-col justify-center items-center mx-auto rounded-md text-gray-700">
          <div className="inviteText md:text-xl">
            <p
              className="inviteText text-center mb-1 md:mb-2 capitalize"
              style={{
                color: accountInfo?.themeText,
              }}
            >
              Hey,{" "}
              <span
                className="font-semibold text-primary"
                style={{ color: accountInfo?.theme }}
              >
                {signerName || "..."}
              </span>
            </p>
            <p
              className="text-center"
              style={{
                color: accountInfo?.themeText,
              }}
            >
              {!isDocumentAvailableToSign
                ? `
                This document is no longer in effect and has been voided by
              `
                : isApprover
                ? "You have been invited to review this document by"
                : "You have been invited to sign a document by"}{" "}
              <span
                className="text-primary font-semibold"
                style={{ color: accountInfo?.theme }}
              >
                {authorName || "..."}
              </span>
            </p>
          </div>

          {sessionInfo && <SessionInformation />}
          {isDeclineToSign && <DeclineToSign />}
          {isDocumentAvailableToSign && !isApprover && (
            <div
              autoFocus
              tabIndex={-1}
              className="w-full sm:w-fit mt-12 flex flex-col lg:flex-row gap-4 relative"
            >
              <button
                tabIndex={2}
                className="min-w-[200px] flex flex-row justify-center items-center text-primary font-semibold rounded-lg shadow-md py-2.5 px-6 bg-white gap-3 hover:scale-110 border-white hover:border-primary transition-all duration-300 ease-in-out focus:scale-105"
                style={{
                  color: accountInfo?.theme,
                }}
                onClick={() => {
                  setOtherOptions(!otherOptions);
                }}
              >
                <div>
                  <FaArrowDown size={15} className="inline-block" />
                </div>
                Other Options
              </button>
              <button
                tabIndex={1}
                className="min-w-[200px] flex flex-row justify-center items-center bg-primary font-semibold rounded-lg shadow-md py-2.5 px-6  text-white  gap-3 hover:scale-110 border-primary hover:border-white transition-all duration-300 ease-in-out outline-white focus:scale-105"
                style={{
                  background: accountInfo?.theme,
                  color: accountInfo?.themeText,
                }}
                onClick={startSigningHandler}
              >
                <div>
                  <FaSignature size={20} className="inline-block" />
                </div>
                Start Signing
              </button>
              {otherOptions && (
                <div
                  ref={otherOptionsRef}
                  className="flex flex-col pt-2 w-[200px] absolute h-[190px] bottom-[-200px] left-0 text-xs text-gray-600 bg-white rounded shadow"
                >
                  <button
                    tabIndex={3}
                    className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer"
                    onClick={() => window.close()}
                  >
                    Finish Later
                  </button>
                  <button
                    tabIndex={4}
                    className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer"
                    onClick={() => {
                      setIsDeclineToSign(true);
                      setOtherOptions(false);
                    }}
                  >
                    Decline to Sign
                  </button>
                  <div className="border-b my-2 w-full"></div>
                  <a
                    href="https://help.boloforms.com/"
                    target="_blank"
                    tabIndex={5}
                  >
                    <div className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer">
                      Help and support
                      <FaExternalLinkAlt size={11} />
                    </div>
                  </a>
                  <a
                    href="https://www.boloforms.com/signature"
                    target="_blank"
                    tabIndex={6}
                  >
                    <div className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer">
                      About Boloforms
                      <FaExternalLinkAlt size={11} />
                    </div>
                  </a>
                  <button
                    tabIndex={7}
                    className="px-3 py-2 hover:bg-gray-100 flex items-center gap-2 cursor-pointer"
                    onClick={() => {
                      setSessionInfo(!sessionInfo);
                      setOtherOptions(false);
                    }}
                  >
                    Session Information
                  </button>
                </div>
              )}
            </div>
          )}
          {isDocumentAvailableToSign && isApprover && (
            <div className="w-full sm:w-fit mt-12 flex flex-col lg:flex-row gap-4 relative">
              <button
                className="min-w-[200px] flex flex-row justify-center items-center bg-primary font-semibold rounded-lg shadow-md py-2.5 px-6  text-white  gap-3 hover:scale-110 border-primary hover:border-white transition-all duration-500 ease-in-out"
                style={{
                  background: accountInfo?.theme,
                  color: accountInfo?.themeText,
                }}
                onClick={startSigningHandler}
              >
                <div>
                  <HiOutlineDocumentSearch size={20} className="inline-block" />
                </div>
                Review Document
              </button>
            </div>
          )}
        </div>
      </div>
      {/* <div className="faqs py-8 w-full mb-16">
        <div className="mx-auto px-5 bg-white w-full">
          <div className="grid divide-y divide-neutral-200 w-[80%] lg:w-[60%] mx-auto mt-8">
            {faqData.map((item) => {
              return (
                <div className="py-5 w-full" key={item.id}>
                  <details className="group w-full">
                    <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-lg">
                      <span className="flex gap-2 items-center justify-center ">
                        {item.question}{" "}
                        {item.id === 3 && (
                          <FaInfoCircle
                            className="text-primary"
                            onClick={() => {
                              window.open(
                                "https://www.boloforms.com/signature/electric-signature-legality/",
                                "_blank"
                              );
                            }}
                          />
                        )}
                      </span>

                      <FaChevronDown
                        size={20}
                        className="transition group-open:rotate-180"
                      />
                    </summary>
                    <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                      {item.answer}{" "}
                      {item.id === 7 && (
                        <span
                          className="underline text-primary cursor-pointer"
                          onClick={() => {
                            window.open(
                              "https://www.boloforms.com/signature/pricing/",
                              "_blank"
                            );
                          }}
                        >
                          learn more.
                        </span>
                      )}
                    </p>
                  </details>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default StartSigningSignerPage;

// <div className="flex flex-col items-center justify-center h-screen">
//   <div className="p-4 w-[60%] border-y-[1px] border-gray-600 mb-4">
//     <div
//       className="flex flex-row justify-end items-center cursor-pointer"
//       onClick={startSigningHandler}
//     >
//       <p className="py-2 px-4 rounded bg-yellow-400 font-bold">
//         START SIGNING
//       </p>
//     </div>
//   </div>
// </div>
