import { useEffect, useState } from "react";
import { components, ControlProps, CSSObjectWithLabel } from "react-select";
import CreatableSelect from "react-select/creatable";
import { validateEmail } from "../../utils/validateEmail";
import { toast } from "react-toastify";
import zIndex from "@mui/material/styles/zIndex";
import { COLORS } from "../../constants/common";

interface EmailSelectProps {
  selectedValue: any;
  updateEmailValue: any;
  index?: any;
  disabled?: boolean;
  isCustomStyle?: boolean;
  filterList?: string[];
}

const customStyle = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    fontSize: "14px", // Adjust as needed
    border: "none",
    borderBottom: state.isFocused ? "2px solid #4c2b87" : "1px solid #e5e5e5",
    borderRadius: "0",
    outline: "none",
    boxShadow: "none",
    transition: "border-bottom-color 0.2s ease-in-out",
    zIndex: "100",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: "0",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#a0aec0",
  }),
};

const defaultStyle = {
  control: (provided: CSSObjectWithLabel, state: ControlProps) => ({
    ...provided,
    borderColor:
      state.isFocused || state.menuIsOpen ? "hsl(hsl(var(--primary)))" : "",
  }),
};
const EmailSingleSelect: React.FC<EmailSelectProps> = ({
  selectedValue,
  updateEmailValue,
  index,
  disabled,
  isCustomStyle,
  filterList,
}) => {
  const [workSpaceContacts, setWorkSpaceContacts] = useState([]);
  // Get contacts list of the workspace from localstorage
  useEffect(() => {
    const getContactsData = () => {
      const owner = JSON.parse(localStorage.getItem("owner") || "");
      let contacts = owner?.contacts;
      if (filterList) {
        contacts = owner?.contacts?.filter((contact: any) => {
          return !filterList?.includes(contact?.email);
        });
      }
      if (contacts) {
        setWorkSpaceContacts(contacts);
      }
    };
    getContactsData();
  }, [filterList]);
  return (
    <>
      <CreatableSelect
        isDisabled={disabled}
        // styles={isCustomStyle ? customStyle : defaultStyle}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor:
              state.isFocused || state.menuIsOpen
                ? "hsl(var(--primary))"
                : provided.borderColor,
            "&:hover": {
              borderColor: "hsl(var(--primary))",
            },
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "hsl(var(--primary))"
              : state.isFocused
              ? "hsl(var(--secondary))"
              : undefined,
            color: state.isSelected ? "white" : "black",
            "&:hover": {
              backgroundColor: "hsl(var(--secondary))",
              color: "hsl(var(--primary))",
            },
          }),
        }}
        className={`w-full text-xs md:text-sm outline-none`}
        isMulti={false}
        value={
          selectedValue ? { value: selectedValue, label: selectedValue } : null
        }
        options={workSpaceContacts?.map((contact: any) => ({
          value: contact?.email,
          label: `${contact?.email} - ${contact?.firstName || ""}`,
        }))}
        onChange={(newValue) => {
          const details: any = workSpaceContacts.find((contact: any) => {
            return contact.email === newValue?.value;
          });
          const name =
            (details?.firstName?.trim() || "") +
            " " +
            (details?.lastName?.trim() || "");
          updateEmailValue(newValue?.value, index, name.trim());
        }}
        onCreateOption={(inputValue) => {
          if (validateEmail(inputValue.trim())) {
            const data = {
              name: "",
              source: "MANUAL",
              email: inputValue.trim(),
              phoneNumber: "",
            };
            const temp: any = [...workSpaceContacts];
            // Don't add new email if already exists
            if (
              temp.filter((option: any) => option.email === data.email)
                .length === 0
            ) {
              temp.push(data);
            }
            setWorkSpaceContacts(temp);
            updateEmailValue(inputValue.trim(), index);
          } else {
            toast.error("Please enter a valid email");
            updateEmailValue("", index);
          }
        }}
        placeholder="Enter email address"
        components={{
          DropdownIndicator: isCustomStyle
            ? null
            : components.DropdownIndicator,
        }}
      />
    </>
  );
};

export default EmailSingleSelect;
