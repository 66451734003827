import { useState } from "react";
import { IoArrowBackCircle, IoArrowForwardCircle } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import Uploader from "../../components/Ui/Uploader";
import MixPanel from "../../utils/services/mixpanel";
import PlanExhaustedNavBanner from "../../components/PlanExhaustedNavBanner";
import axios from "../../utils/axios";
import { v4 } from "uuid";

import RolesInput from "../../components/Ui/Roles";
import { toast, ToastContainer, Slide } from "react-toastify";
import { getEditableFields } from "../../utils/fillablePDF";
import BoloCheckboxMui from "../../components/Common/Checkbox";
import { removeDefaultInputFields } from "../../utils/helpers";
import {
  blobToFile,
  convertToNormalPDF,
  convertToNormalPDFFile,
  downloadFile,
} from "../../utils/uiUtils";
import { delay } from "../../utils";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import { BoloButton } from "../../components/Common/Button/BoloButton";
import { detectStatusandBody } from "../../redux/apis/fileupload";
import { copyPdfBytes } from "../../utils/localPdfUtils";
type Props = {};

const CreateTemplate = (props: Props) => {
  const currentUserEmail = localStorage.getItem("currentUserEmail");
  let navigate = useNavigate();
  const [roles, setRoles] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const [fileName, setFileName] = useState<string>("");
  const [documentId] = useState(() => v4());
  const [initialUploadedFile, setInitialUploadedFile] = useState<any>(null);
  const [pdfLoading, setPdfLoading] = useState(false);
  const folderId = searchParams.get("folderId");
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [quotaExhausted, setQuotaExhausted] = useState(false);
  const [mapFields, setMapFields] = useState(true);
  const [pdfCount, setPdfCount] = useState(1);
  const [fieldsCountInPdf, setFieldsCountInPdf] = useState(0);

  if (quotaExhausted) {
    return <PlanExhaustedNavBanner />;
  }
  // Remove input fields when  user is mapping fields

  const handlerBuildTemplate = async () => {
    new MixPanel().track("PDF Template", {
      pdf_template_action: "pdf_template_confirm",
      paid: true,
    });
    new MixPanel().increment("pdf_template_confirm");
    if (!initialUploadedFile) {
      console.error("No file selected.");
      return;
    }
    if (roles.length === 0) {
      console.error("No roles selected.");
      return;
    }
    setPdfLoading(true);

    console.log("A pdf template is being created");

    let pdfFile: File = initialUploadedFile;
    let errorInFieldRemoval = false;
    // const pdfBuffer = await pdfFile.arrayBuffer();

    try {
      let fileWithDocIdFile = pdfFile;
      if (mapFields && fieldsCountInPdf > 0) {
        let processedFile = await removeDefaultInputFields(fileWithDocIdFile);
        if (!processedFile.error) {
          fileWithDocIdFile = processedFile.file;
        } else {
          errorInFieldRemoval = true;
          toast.info("Could not map fields with AI");
          toast.info("Proceeding with standard PDF");
          await delay(2000); // delay to let users read the message
          fileWithDocIdFile = await convertToNormalPDFFile(fileWithDocIdFile);
        }
      } else {
        // if not checked the use AI mapping checkbox and there are fillable fields in the pdf
        // we flatten so that the fields seen in editor are also reflected in generation
        fileWithDocIdFile = await convertToNormalPDFFile(fileWithDocIdFile);
      }

      const ipResponse = getIpAddressLocal();
      const { ip } = await ipResponse;

      const formData = new FormData();

      //@ts-ignore
      formData.append("documentId", documentId);
      //@ts-ignore
      formData.append("folderId", folderId);
      formData.append("pdfFile", fileWithDocIdFile);
      // @ts-ignore
      formData.append("ownerId", JSON.parse(localStorage.getItem("owner"))._id);
      formData.append("roles", JSON.stringify(roles));
      formData.append("ip", ip);
      // @ts-ignore
      formData.append("authorEmail", currentUserEmail);
      formData.append(
        "createdBy",
        JSON.parse(localStorage.getItem("owner") || "")._id
      );

      if (mapFields && fieldsCountInPdf > 0 && !errorInFieldRemoval) {
        const instanceFrom = "PDF-TEMPLATE";
        // Create a schema of fillable fields from the pdf file
        const finalSchema = await getEditableFields(
          pdfFile,
          roles,
          instanceFrom
        );
        // Send schema data at the time of document creation for adding fields
        formData.append("schema", JSON.stringify(finalSchema));
      }

      // const { data } = await axios.post(
      //   "/create/signature-pdf-template",
      //   formData,
      //   {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   }
      // );

      let handledUpload = await detectStatusandBody(
        "/create/signature-pdf-template",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (handledUpload.error && handledUpload.error === "403-nb") {
        formData.delete("pdfFile");
        formData.append("pdfFile", await copyPdfBytes(pdfFile));
        handledUpload = await detectStatusandBody(
          "/create/signature-pdf-template",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      }
      if (handledUpload.error && handledUpload.error !== "403-nb") {
        console.log(handledUpload.error);
        console.log(handledUpload.errorData);
        toast.error("403 response unhandled error");
      }

      const { data } = handledUpload;
      if (!data.error) {
        toast.success(data.message);
        const reader = new FileReader();
        reader.readAsDataURL(fileWithDocIdFile);
        reader.onload = async () => {
          const base64File = reader.result;
          setUploadedFile(base64File);
          // setFileName && setFileName(file.name);
        };
        // await getDocumentDetails();
        // Redirect to create/pdf-template?documentId = ...
        navigate(`/create/pdf-template?documentId=${documentId}`);
      } else {
        toast.error(data.error);
        if (data.error === "You have exhausted your allowed quota") {
          setQuotaExhausted(true);
        }
        return;
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error?.message);
    }
    setPdfLoading(false);
    return;
  };
  const handleFileUpload = (base64File: any, numberOfPages: number) => {
    setInitialUploadedFile(base64File);
  };
  function hasDuplicateTitles(roles: { title: string }[]) {
    const titleSet = new Set();

    for (const role of roles) {
      // Convert the title to a string to handle different data types
      const titleString = String(role.title).toLowerCase();

      if (titleSet.has(titleString)) {
        // Duplicate title found
        // toast.error("There can not be more than one role with same title");
        return true;
      }

      titleSet.add(titleString);
    }

    // No duplicate titles found
    return false;
  }
  const setPdfsCount = (count: number) => {
    setPdfCount(count);
  };
  const setFieldsCount = (count: number) => {
    setFieldsCountInPdf(count);
  };

  return (
    <>
      <div className="template-navbar sticky top-0 z-10 h-10 md:h-14 px-3 md:px-8 py-2 bg-primary flex gap-2 text-white items-center">
        <IoArrowBackCircle
          className="hover:opacity-70 text-3xl cursor-pointer rounded-sm"
          onClick={() => {
            navigate("/create");
          }}
        />
        <p className="text-sm ml-2">Create Template</p>
        <p className="ml-auto text-sm">
          <span className="font-semibold">Preparing Template</span>
        </p>
      </div>
      <div className="mt-2 md:mt-8 flex flex-col md:py-4 px-3 md:px-8 gap-4">
        <div className="uploader flex flex-col ">
          <div className="hidden md:block w-[100%] md:w-[65%] max-w-[800px] mx-auto">
            <h1 className="text-lg md:text-2xl font-semibold md:font-bold mb-1 md:mb-3 text-gray-700">
              Upload your PDF
            </h1>
            <p className="mb-3 w-full capitalize text-sm md:text-base md:font-medium">
              Drop your pdf to map your form questions in our pdf editor{" "}
            </p>
          </div>
          <Uploader
            preUploadedFile={null}
            onFileUpload={handleFileUpload}
            isOnlyPdfTemplate={true}
            isWithRole={true}
            isRolesAdded={roles.length > 0}
            roles={roles}
            setFileName={setFileName}
            documentId={documentId!}
            setPdfsCount={setPdfsCount}
            setFieldsCount={setFieldsCount}
          />
        </div>
        {fieldsCountInPdf > 0 && (
          <div className=" w-[100%] md:w-[65%] max-w-[800px] mx-auto">
            <BoloCheckboxMui
              id="mapFields"
              disabled={pdfCount > 1}
              onChange={(e: any) => {
                setMapFields(e.target.checked);
              }}
              checked={pdfCount > 1 ? false : mapFields}
            />
            <label
              htmlFor="mapFields"
              className="cursor-pointer text-sm md:text-base"
            >
              Automagically map fields using AI
            </label>
          </div>
        )}

        <div className="role flex flex-col ">
          <RolesInput onSave={setRoles} />
        </div>
        <div className="w-[100%] md:w-[65%] max-w-[800px] mx-auto">
          <BoloButton
            size={"sm"}
            className="w-full gap-3"
            orientation={"center"}
            // className="bg-primary w-full text-white px-6 py-2 rounded-md text-sm flex justify-center gap-3 items-center transition-all ease-in-out duration-400 hover:opacity-70"
            onClick={handlerBuildTemplate}
            disabled={
              !initialUploadedFile ||
              roles.length === 0 ||
              hasDuplicateTitles(roles) ||
              pdfLoading
            }
          >
            <span className="">Confirm</span>{" "}
            <IoArrowForwardCircle className="inline-block text-2xl" />
          </BoloButton>
        </div>
      </div>
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </>
  );
};

export default CreateTemplate;
