import {
  Table,
  TableRow,
  TableHeader,
  TableHead,
  TableBody,
  TableCell,
} from "../../../../components/Common/Table/BoloTable";
import {
  BoloUser,
  setSelectedBoloUser,
} from "../../../../redux/slices/whiteLabelSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";

const UserTable = ({ users }: { users?: BoloUser[] }) => {
  const dispatch = useAppDispatch();

  const selectedBoloUser = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.helpers?.selectedUser
  );
  const handleRowClick = (user: BoloUser) => {
    dispatch(setSelectedBoloUser(user));
  };
  return (
    <div className="max-w-[100%]">
      <Table className="border rounded-md">
        <TableHeader>
          <TableRow>
            <TableHead className="text-xs p-2 font-semibold min-w-[120px]">
              Name
            </TableHead>
            <TableHead className="text-xs p-2 font-semibold min-w-[150px]">
              Email
            </TableHead>
            <TableHead className="text-xs p-2 font-semibold min-w-[150px]">
              Tier
            </TableHead>
            {/* <TableHead className="text-xs p-2 font-semibold">From</TableHead>
            <TableHead className="text-xs p-2 font-semibold">
              Plan History
            </TableHead>
            <TableHead className="text-xs p-2 font-semibold">
              Account Info
            </TableHead> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          {users?.map((user) => {
            const lastPlanHistroy =
              user?.planhistory?.[user?.planhistory?.length - 1];
            const tier = lastPlanHistroy?.tier;
            return (
              <TableRow
                className={`hover:cursor-pointer hover:bg-secondary-bolo/30 ${
                  selectedBoloUser?.email == user?.email && user?.email
                    ? "bg-secondary-bolo hover:bg-secondary-bolo"
                    : ""
                }`}
                onClick={() => handleRowClick(user)}
              >
                <TableCell className="text-xs p-2">{user?.name}</TableCell>
                <TableCell className="text-xs p-2">{user?.email}</TableCell>
                <TableCell className="text-xs p-2">{tier}</TableCell>
                {/* <TableCell className="text-xs p-2">{user?.from}</TableCell>
                <TableCell className="text-xs p-2">
                  {JSON.stringify(user?.planhistory)?.slice(0, 100) + "..."}
                </TableCell>
                <TableCell className="text-xs p-2">
                  {JSON.stringify(user?.accountInfo)?.slice(0, 100) + "..."}
                </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default UserTable;
