//@ts-nocheck
import { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { calculateContrast, getTintFromHex } from "../../utils/uiUtils";
import { isFeatureEnabled } from "../../utils/paidFeat";
import { LANGUAGE } from "../../constants/languages";
import { useAppSelector } from "../../redux/store";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import "./../Ui/quill-editor.css";

import SelectSigner from "./SelectSigner";
const findValueViaObj = (objData, stringData) => {
  const { recipient, customVariables, contactVariables } = stringData;
  let varValue = "";
  if (objData?.type === "default") {
    if (objData?.key === "email") {
      varValue = recipient?.email.trim();
    } else if (objData?.key === "name") {
      varValue = recipient?.name.trim();
    }
  } else if (objData?.type === "variable") {
    if (customVariables) {
      for (let i = 0; i < customVariables.length; i++) {
        let variable = customVariables[i];
        if (variable?._id === objData?.key) {
          varValue = variable?.varValue.toString();
          break;
        }
      }
    }
  } else if (objData?.type === "contact_field") {
    if (contactVariables) {
      for (let i = 0; i < contactVariables.length; i++) {
        let variable = contactVariables[i];
        if (
          variable?.fieldName === objData?.key &&
          recipient?.roleTitle === variable?.roleTitle
        ) {
          varValue = variable?.fieldValue.toString();
          break;
        }
      }
    }
  }
  return varValue.trim();
};

const tagStringParser = (stringData) => {
  const { inputString } = stringData;

  // Check if inputString exists and is not empty
  if (!inputString) {
    return "";
  }

  const regex = /\[\[([^\]]+)\]\]/g;
  const extractedObjects = [];

  // Replace the matched content and extract objects
  const modifiedInput = inputString?.replace(regex, (match, p1) => {
    try {
      // Parse the JSON content and store the object
      const extObj = JSON.parse(p1);
      extractedObjects.push(extObj);
      const valueOfInput = findValueViaObj(extObj, stringData);
      return valueOfInput;
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return match; // Return the original match if parsing fails
    }
  });
  return modifiedInput;
};
const BrandingPreview = ({
  show,
  setShow,
  accountInfo,
  currentBrandingTab,
  signerEmailContent = {},
}) => {
  const { contactVariables, variables, receiversList, mailData } =
    signerEmailContent;
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [signerEmail, setSignerEmail] = useState(
    receiversList && receiversList.length > 0 && receiversList[0]?.email
  );
  const [signerName, setSignerName] = useState("");
  const [tab, setTab] = useState("EMAIL");
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );
  const textColor =
    calculateContrast(accountInfo.theme, "#FFFFFF") > 3 ? "#FFFFFF" : "#000000";
  const [watermarkImageURL, setWaterMarkUrl] = useState(IMAGE_LINKS.WHITE_RECT);
  const [logoImageURL, setLogoUrl] = useState("");
  const [isEmailBranding, setIsEmailBranding] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    if (accountInfo?.defaultWaterMark) {
      // this means either link or file object exists
      if (accountInfo?.defaultWaterMark?.name) {
        // this means file object
        const reader = new FileReader();
        reader.readAsDataURL(accountInfo?.defaultWaterMark);
        reader.onload = (e) => {
          setWaterMarkUrl(e.target?.result);
        };
      } else {
        // this means file link
        setWaterMarkUrl(accountInfo?.defaultWaterMark);
      }
    }
    if (accountInfo?.orgLogo) {
      // this means either link or file object exists
      if (accountInfo?.orgLogo?.name) {
        // this means file object
        const reader = new FileReader();
        reader.readAsDataURL(accountInfo?.orgLogo);
        reader.onload = (e) => {
          setLogoUrl(e.target?.result);
        };
      } else {
        // this means file link
        setLogoUrl(accountInfo?.orgLogo);
      }
    }
    if (accountInfo?.selectedLanguage) {
      setSelectedLanguage(accountInfo.selectedLanguage);
    }
  }, []);
  // console.log("themr", accountInfo);

  const checkFeatures = async () => {
    const isWaterMarkEnabled = await isFeatureEnabled("WATERMARK");
    const isEmailBrandingEnabled = await isFeatureEnabled("EMAIL_BRANDING");
    setIsEmailBranding(isEmailBrandingEnabled);
    if (!isWaterMarkEnabled) {
      setWaterMarkUrl(IMAGE_LINKS.WHITE_RECT);
    }
    if (!isEmailBrandingEnabled) {
      setLogoUrl(
        whiteLabelInfo?.settings?.organization?.logo || IMAGE_LINKS.BOLOLOGO
      );
    }
  };

  useEffect(() => {
    checkFeatures();
  }, []);

  useEffect(() => {
    if (signerEmail && signerEmail !== "select") {
      const recipient = receiversList.find((signer) => {
        return signer.email === signerEmail;
      });
      setSignerName(recipient?.name);
      const subjectObj = {
        inputString: mailData?.subject,
        recipient: recipient,
        customVariables: variables,
        contactVariables: contactVariables,
      };
      const resEmailSubject = tagStringParser(subjectObj);
      setEmailSubject(resEmailSubject);

      const bodyObj = {
        inputString: mailData?.body,
        recipient: recipient,
        customVariables: variables,
        contactVariables: contactVariables,
      };
      const resEmailBody = tagStringParser(bodyObj);
      setEmailBody(resEmailBody);
    }
  }, [signerEmail]);

  return (
    <div className="fixed top-0 left-0 right-0 z-[1000] h-[100vh] w-[100vw] bg-black/10 flex items-center justify-center py-4">
      <div
        className="bg-white w-[calc(100%_-_1.5rem)] md:w-fit p-4 relative rounded-lg max-h-full overflow-y-auto overflow-x-hidden"
        style={{ scrollbarWidth: "thin" }}
      >
        <div className="text-lg font-semibold">Preview</div>
        <MdClose
          className="absolute top-5 right-5 text-2xl cursor-pointer"
          onClick={() => {
            setShow(false);
          }}
        />
        <div className="w-[100%] md:w-[600px]  min-h-[650px] my-4 text-sm flex flex-col items-center">
          {signerEmail && (
            <div className="flex flex-col  w-full">
              <p className="text-gray-700 text-xs mb-1">
                Select signer to see the preview of the email
              </p>
              <SelectSigner
                options={receiversList}
                changeValue={setSignerEmail}
                value={signerEmail}
              />
            </div>
          )}
          <h1
            className="text-xl mt-4 leading-normal self-start px-5"
            style={{ fontFamily: "sans-serif, Arial, Tahoma" }}
          >
            {emailSubject}
          </h1>
          <div
            className="w-[100%] mt-4 flex justify-center"
            style={{ fontFamily: "sans-serif, Arial, Tahoma" }}
          >
            {currentBrandingTab == "EMAIL" && (
              <div className="border w-[100%] max-w-[560px] md:w-[560px]">
                <div
                  className="h-[100px] py-[10px] px-[20px]"
                  style={{
                    border: `2px solid ${
                      accountInfo?.theme && isEmailBranding
                        ? accountInfo?.theme
                        : whiteLabelInfo?.settings?.organization?.themeHex
                            ?.primary || COLORS.primary
                    } `,
                    background:
                      accountInfo?.theme && isEmailBranding
                        ? getTintFromHex(accountInfo?.theme, 70)
                        : whiteLabelInfo?.settings?.organization?.themeHex
                            ?.secondary || COLORS.secondary,
                  }}
                >
                  <img
                    className="max-h-[80px]"
                    src={
                      logoImageURL ||
                      whiteLabelInfo?.settings?.organization?.logo ||
                      IMAGE_LINKS.BOLOLOGO
                    }
                    alt=""
                  />
                </div>
                <div
                  className=" p-[40px]"
                  style={{
                    borderStyle: "none solid solid solid",
                    borderColor: `${
                      accountInfo?.theme && isEmailBranding
                        ? accountInfo?.theme
                        : whiteLabelInfo?.settings?.organization?.themeHex
                            ?.primary || COLORS.primary
                    }`,
                    borderWidth: "2px",
                  }}
                >
                  <div
                    className="rounded-[5px]"
                    style={{
                      background: "#fff",
                      color: "#000",
                    }}
                  >
                    <div className="text-[21px] font-bold mb-[10px]">
                      Hello, {signerName ? signerName : "Signer"}
                    </div>
                    <div className="text-[13px] leading-[18px]">
                      This is a message from the sender
                      <div
                        dangerouslySetInnerHTML={{ __html: emailBody }}
                        className="preview-email-body"
                      ></div>
                    </div>
                    <br />
                    <div className="flex justify-between">
                      <div>
                        <div
                          className="px-[20px] py-[12px] mt-[10px] rounded-[20px] w-fit text-[16px] mb-[10px] font-bold"
                          style={{
                            color:
                              accountInfo?.theme && isEmailBranding
                                ? textColor
                                : whiteLabelInfo?.settings?.organization
                                    ?.themeHex?.textPrimary || "#fff",
                            background:
                              accountInfo?.theme && isEmailBranding
                                ? accountInfo?.theme
                                : whiteLabelInfo?.settings?.organization
                                    ?.themeHex?.primary || COLORS.primary,
                          }}
                        >
                          Sign Now
                        </div>
                        <br />
                        <div className="text-[12px] leading-[18px] text-gray-600 font-bold">
                          Thank you!
                        </div>
                        <div className="text-[12px] leading-[18px] text-gray-600">
                          The{" "}
                          {/* {" " + accountInfo.org && isEmailBranding
                            ? accountInfo?.org
                            : "Boloforms Team"} */}
                          BoloForms Team
                        </div>
                      </div>
                      <div className="pr-[20px]">
                        <img
                          style={{
                            border: `2px solid ${
                              accountInfo?.theme && isEmailBranding
                                ? getTintFromHex(accountInfo?.theme, 70)
                                : whiteLabelInfo?.settings?.organization
                                    ?.themeHex?.secondary || COLORS.secondary
                            }`,
                          }}
                          className="h-[200px]"
                          src={IMAGE_LINKS.EMAIL_PDF_PREVIEW}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {currentBrandingTab == "PDF" && (
              <div className="p-2 relative">
                <p className="text-sm mb-2">
                  Preview of the watermark in a basic A4 aspect ratio
                </p>
                <div className="w-[100%] max-w-[400px] md:w-[400px] h-[564px] border border-gray-300 flex items-center justify-center">
                  <img
                    src={watermarkImageURL}
                    alt="Watermark"
                    className="m-auto w-[201px] h-[201px] opacity-40"
                  />
                </div>
              </div>
            )}
            {currentBrandingTab == "LANGUAGE" && (
              <div className=" w-[100%] max-w-[560px] md:w-[560px]">
                <div
                  className="h-[100px] py-[10px] px-[20px]"
                  style={{
                    border: `2px solid ${
                      accountInfo?.theme && isEmailBranding
                        ? accountInfo?.theme
                        : whiteLabelInfo?.settings?.organization?.themeHex
                            ?.primary || COLORS.primary
                    } `,
                    background:
                      accountInfo?.theme && isEmailBranding
                        ? getTintFromHex(accountInfo?.theme, 70)
                        : whiteLabelInfo?.settings?.organization?.themeHex
                            ?.secondary || COLORS.secondary,
                  }}
                >
                  <img
                    className="max-h-[80px]"
                    src={
                      logoImageURL ||
                      whiteLabelInfo?.settings?.organization?.logo ||
                      IMAGE_LINKS.BOLOLOGO
                    }
                    alt=""
                  />
                </div>
                <div
                  className=" p-[40px]"
                  style={{
                    borderStyle: "none solid solid solid",
                    borderColor: `${
                      accountInfo?.theme && isEmailBranding
                        ? accountInfo?.theme
                        : whiteLabelInfo?.settings?.organization?.themeHex
                            ?.primary || COLORS.primary
                    }`,
                    borderWidth: "2px",
                  }}
                >
                  <div
                    className="rounded-[5px] "
                    style={{
                      background: "#fff",
                      color: "#000",
                    }}
                  >
                    <div className="text-[21px] mb-[10px] notranslate font-bold">
                      {LANGUAGE[selectedLanguage].hello}, Signer
                    </div>
                    <div className="text-[13px] leading-[18px] notranslate">
                      {LANGUAGE[selectedLanguage].messageFromSenderText}
                    </div>
                    <br />
                    <div className="flex justify-between">
                      <div>
                        <div
                          className="px-[20px] py-[12px] mt-[10px] rounded-[20px] w-fit text-[16px] notranslate mb-[10px] font-bold"
                          style={{
                            background:
                              accountInfo?.theme && isEmailBranding
                                ? accountInfo?.theme
                                : whiteLabelInfo?.settings?.organization
                                    ?.themeHex?.primary || COLORS.primary,
                            color:
                              accountInfo?.theme && isEmailBranding
                                ? textColor
                                : whiteLabelInfo?.settings?.organization
                                    ?.themeHex?.textPrimary || "#fff",
                          }}
                        >
                          {LANGUAGE[selectedLanguage].signNowText}
                        </div>
                        <br />
                        <div className="text-[12px] leading-[18px] text-gray-600 notranslate font-bold">
                          {LANGUAGE[selectedLanguage].thankYou}
                        </div>
                        <div className="text-[12px] leading-[18px] text-gray-600 notranslate">
                          {LANGUAGE[selectedLanguage].the}{" "}
                          {/* {" " +
                            (accountInfo.org && isEmailBranding
                              ? accountInfo?.org
                              : `Boloforms ${LANGUAGE[selectedLanguage].team}`)} */}
                          Boloforms {LANGUAGE[selectedLanguage].team}
                        </div>
                      </div>
                      <div className="pr-[20px]">
                        <img
                          style={{
                            border: `2px solid ${
                              accountInfo?.theme && isEmailBranding
                                ? getTintFromHex(accountInfo?.theme, 70)
                                : whiteLabelInfo?.settings?.organization
                                    ?.themeHex?.secondary || COLORS.secondary
                            }`,
                          }}
                          className="h-[200px]"
                          src={IMAGE_LINKS.EMAIL_PDF_PREVIEW}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandingPreview;
