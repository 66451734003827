import FB from './facebook';
import TikTok from './tiktok';
import Reddit from './reddit';
// now use this class for all the tracking

class Tracking {
  constructor(email) {
    this.facebook = new FB(email);
    this.tiktok = new TikTok(email)
    this.reddit = new Reddit(email)

  }

  trackPurchase(product, priceId, quantity, value) {
    this.facebook.trackPurchase(product, priceId, quantity, value)
    this.tiktok.trackPurchase(product, priceId, quantity, value)
    this.reddit.trackPurchase(product, priceId, quantity, value)
  }

  trackInitiateCheckout(product, priceId, quantity, value,) {
    this.facebook.trackInitiateCheckout(product, priceId, quantity, value)
    this.tiktok.trackInitiateCheckout(product, priceId, quantity, value)
    this.reddit.trackInitiateCheckout(product, priceId, quantity, value)
  }


  trackAddPaymentInfo(product, priceId, quantity, value) {
    this.facebook.trackAddPaymentInfo(product, priceId, quantity, value)
    this.tiktok.trackAddPaymentInfo(product, priceId, quantity, value)
    // not add payment info in reddit
  }

  trackSignUp(email) {
    this.facebook.trackSignUp(email)
    this.tiktok.trackSignUp(email)
    this.reddit.trackSignUp(email)

  }


  trackCustom(event, data) {
    this.facebook.trackCustom(event, data)
    this.tiktok.trackCustom(event, data);
    this.reddit.trackCustom(event, data);
  }

}


export default Tracking


