//@ts-nocheck

import React, { Fragment, useEffect, useState } from "react";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { DatePicker, Radio, Space } from "antd";
import QuestionBottomOptions from "../../QuestionBottomOptions";
import QuestionInput from "../../QuestionInput";
import QuestionDesc from "../../QuestionDesc";
import { MdArrowDropDown, MdOutlineEvent } from "react-icons/md";
import ImageQuestionPreview from "../../ImageQuestionPreview";

function CustomDropDown({ opts, setSelectedOpt, selectedOpt }) {
  let activeQuestion = opts.find((option) => option === selectedOpt);
  const questionTypeDropdownRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [displacementAmount, setDisplacementAmount] = useState(0);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, index) => {
    let displacement = -(index - 1) * 48;
    setDisplacementAmount(displacement);
    setSelectedOpt(option);
    setIsOpen((prev) => !prev);
  };

  const handleOutsideClick = (e) => {
    if (questionTypeDropdownRef.current !== null) {
      if (questionTypeDropdownRef.current.contains(e.target)) return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative ml-2 max-w-[240px]">
      <div className="relative">
        <div
          onClick={toggleDropdown}
          className="h-[36px] px-3 rounded flex items-center cursor-pointer"
        >
          {/* <div className="mr-2">{activeQuestion.icon}</div> */}
          <div className="ml-1 mr-2">
            <span className="text-gray-800 text-sm">{activeQuestion}</span>
          </div>
          <div className="ml-auto">
            <MdArrowDropDown className="text-2xl text-gray-500" />
          </div>
        </div>
        {isOpen && (
          <div
            ref={questionTypeDropdownRef}
            style={{
              marginTop: "-48px",
              position: "absolute",
              boxShadow:
                "0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)",
              // top: `${displacementAmount}px`,
            }}
            className="py-2 min-w-8 bg-white border border-[#0000001f] rounded z-50"
          >
            {opts.map((option, index) => {
              return (
                <Fragment key={index}>
                  <div
                    onClick={() => handleOptionClick(option, index)}
                    className={`${
                      activeQuestion === option && "bg-[#e4f0fc]"
                    } h-[48px] w-full py-2 pl-4 pr-8 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
                  >
                    <span className="text-gray-800 text-sm font-medium">
                      {option}
                    </span>
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const Date = ({ data }) => {
  let {
    question,
    setFormState,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
    formState,
    schemas,
  } = data;
  const isEditor =
    questionIndex.p === activeState.pageIndex &&
    questionIndex.q === activeState.questionIndex;

  const onChange = (date, dateString) => {
    // console.log(dateString);

    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.value = dateString;
          }
        });
      });
      return newState;
    });
  };

  const setDateFormat = (format) => {
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.dateFormat = format;
          }
        });
      });
      return newState;
    });
  };

  const defaultValueParser = () => {
    const textMap = {
      "DD/MM/YYYY": "Day, Month, Year",
      "MM/DD/YYYY": "Month, Day, Year",
      "YYYY/MM/DD": "Year, Month, Day",
    };

    if (textMap[question?.dateFormat]) {
      return textMap[question?.dateFormat];
    }

    return "Day, Month, Year";
  };

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex flex-row">
          <QuestionInput
            helpers={{
              question,
              setFormState,
              isEditor,
              questionIndex,
              activeState,
            }}
          />
        </div>

        <QuestionDesc
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
      </div>
      <ImageQuestionPreview
        helpers={{ question, setFormState, questionIndex, activeState }}
      />
      <div className="flex gap-4 items-start">
        <div className="w-[30%] relative">
          <input
            type="text"
            className={`${
              questionIndex.p === activeState.pageIndex &&
              questionIndex.q === activeState.questionIndex &&
              "border-b"
            } w-full mt-1 mb-10 py-1 text-sm text-[#70757a] border-[#dadce0] outline-none`}
            disabled
            // defaultValue={"Year, month, day"}
            // defaultValue={defaultValueParser()}
            placeholder={defaultValueParser()}
          />
          <MdOutlineEvent className="text-2xl text-gray-400 absolute top-[5px] right-0" />
        </div>
        <div>
          <CustomDropDown
            opts={["DD/MM/YYYY", "MM/DD/YYYY", "YYYY/MM/DD"]}
            selectedOpt={question?.dateFormat || "DD/MM/YYYY"}
            setSelectedOpt={setDateFormat}
          />
        </div>
      </div>
      {/* <div className="mt-4 border rounded-md">
        <DatePicker
          onChange={onChange}
          // disabled={isEditor}
          className="w-full py-2  focus:border-none border-none shadow-none"
        />
      </div> */}

      <QuestionBottomOptions
        helpers={{
          question,
          setFormState,
          questionIndex,
          activeState,
          setActiveState,
          setActive,
          formState,
          schemas,
        }}
      />
    </div>
  );
};

export default Date;
