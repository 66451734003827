//@ts-nocheck
import { useState } from "react";
import { GrClose } from "react-icons/gr";
import Papa from "papaparse";
import { toast } from "react-toastify";

const SingleColOptions = ({ setModalType, addOptions }) => {
  const expectedFields = ["option"];

  const [csvData, setCsvData] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [isCsvValid, setIsCsvValid] = useState(false);

  const addOptionsBulk = async () => {
    const options = csvData;
    if (options.length > 100) {
      toast.error("Maximum limit options is 100");
      setIsCsvValid(false);
      setCsvData([]);
      setCsvFile(null);
      return;
    }
    addOptions(options);
    setModalType("");
  };

  return (
    <>
      <div className="flex fixed top-0 left-0 z-[51] w-full h-full p-2 md:p-8 bg-[#00000091] justify-center items-start overflow-y-auto">
        <div className="bg-white md:p-8 p-4 md:rounded-md rounded-sm  max-w-xl w-full">
          <div className="flex justify-between items-center mb-6">
            <h2 className=" text-xl md:text-xl font-medium tracking-tight">
              Upload Options
            </h2>
            <GrClose
              className="text-xl hover:cursor-pointer"
              onClick={() => {
                setModalType("");
              }}
            />
          </div>
          <div className="flex flex-col">
            <h2 className="text-base font-medium text-gray-800">Bulk Upload</h2>
            <p className="text-sm text-gray-600 mb-6">
              Add multiple options at once by completing this form.
            </p>
            <div className="flex flex-col">
              <p className="text-md w-fit uppercase font-semibold rounded-md flex items-center justify-center">
                Step 1
              </p>
              <p className="text-sm text-gray-600 mt-1 mb-4">
                Download the csv template and fill the details
              </p>
              <div>
                <button
                  onClick={() => {
                    // Create a Blob containing the CSV data
                    const blob = new Blob([expectedFields], {
                      type: "text/csv",
                    });

                    // Create a temporary URL for the Blob
                    const url = window.URL.createObjectURL(blob);

                    // Create a link and trigger a click to download the file
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = `bulk-options-template.csv`;
                    document.body.appendChild(a);
                    a.click();

                    // Clean up
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                  }}
                  className="px-4 py-1 bg-primary text-sm text-white rounded-md hover:opacity-90 mr-4"
                >
                  Download
                </button>
              </div>
            </div>
            <div className="mt-4 flex flex-col">
              <p className="text-md w-fit uppercase font-semibold rounded-md flex items-center justify-center">
                Step 2
              </p>
              <p className="text-sm text-gray-600 mt-1 mb-4">
                Upload the filled csv file
              </p>
              <div>
                <div className="flex items-center min-h-[40px]">
                  <label
                    htmlFor="csv-upload"
                    onClick={() => {}}
                    className="cursor-pointer min-w-[120px] text-sm bg-violet-200 text-violet-500 font-medium rounded-md px-4 py-1.5 flex items-center justify-center"
                  >
                    Choose File
                  </label>
                  <p className="ml-2 text-sm text-gray-700">
                    {csvFile ? csvFile?.name : "No file chosen"}
                  </p>
                </div>
                <input
                  className="py-2 self-start px-4 w-full outline-none text-gray-700 rounded-md border disabled:opacity-50 text-sm hidden"
                  id="csv-upload"
                  type="file"
                  accept=".csv, .xlsx"
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    e.target.value = "";
                    // Read the file and parse it
                    setCsvFile(file);
                    const reader = new FileReader();
                    reader.onload = (event: any) => {
                      const result = event.target.result;
                      if (!result) {
                        toast.error("Please check csv file.");
                        setCsvFile(null);
                        setIsCsvValid(false);
                        return;
                      }
                      // Parse the CSV data
                      Papa.parse(result, {
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        complete: (parsedData: any) => {
                          if (
                            !parsedData ||
                            !parsedData.data ||
                            !parsedData.data.length === 0 ||
                            !parsedData.data[0]
                          ) {
                            toast.error("No data or invalid csv");
                            setCsvFile(null);
                            setIsCsvValid(false);
                            return;
                          }
                          const userCsvFields = Object.keys(parsedData.data[0]);
                          const isValidCsv = expectedFields.every((field) =>
                            userCsvFields.includes(field)
                          );
                          if (isValidCsv) {
                            setCsvData(parsedData.data);
                            setIsCsvValid(true);
                          } else {
                            toast.error(
                              "Please ensure all required fields are present."
                            );
                            setCsvFile(null);
                            setIsCsvValid(false);
                          }
                        },
                        error: (error: any) => {
                          console.error("CSV parsing error:", error.message);
                        },
                      });
                    };
                    if (file) {
                      reader.readAsText(file);
                    } else {
                      setCsvFile(null);
                      setIsCsvValid(false);
                    }
                  }}
                />
              </div>
            </div>

            <div className="mt-4 flex justify-end">
              <button
                className="px-6 py-2 mt-4 bg-primary text-xs md:text-sm text-white rounded-lg hover:opacity-90 md:mr-4 disabled:opacity-50"
                disabled={!isCsvValid}
                onClick={addOptionsBulk}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleColOptions;
