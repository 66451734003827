import React, { useState } from "react";
import { IoKey } from "react-icons/io5";
import {
  MdDelete,
  MdOutlineContentCopy,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { toast } from "react-toastify";

interface AccessCodeDetailsComponentProps {
  showAccessCode: {
    show: boolean;
    email: string;
    roleTitle: string;
  };
  setShowAccessCode: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      email: string;
      roleTitle: string;
    }>
  >;
  receiversList: any[];
  index: number;
  updateAccessCodeState: (email: string, roleTitle: string) => void;
  setReceiversList: any;
  isSinglePdf?: boolean;
}

const AccessCodeDetailsComponent = ({
  showAccessCode,
  setShowAccessCode,
  receiversList,
  index,
  updateAccessCodeState,
  setReceiversList,
  isSinglePdf = false,
}: AccessCodeDetailsComponentProps) => {
  const [inputError, setInputError] = useState({
    show: false,
    message: "",
  });

  return (
    <div
      className={`${
        isSinglePdf
          ? showAccessCode.email === receiversList[index]?.email
            ? "block animate-slide-down "
            : "hidden "
          : showAccessCode.roleTitle === receiversList[index]?.roleTitle
          ? "block animate-slide-down "
          : "hidden "
      }
    w-full bg-zinc-100 border border-gray-300 rounded-md p-4 shadow-md mb-2`}
    >
      <div className="flex flex-col">
        <div className="flex justify-between w-full">
          <div className="flex gap-3 items-center">
            <IoKey size={20} className="text-gray-500" />
            <span className=" font-semibold">
              Access Code:{" "}
              <span
                style={{
                  color:
                    receiversList[index]?.roleColour ||
                    receiversList[index]?.colour ||
                    "black",
                }}
              >
                {showAccessCode.roleTitle || showAccessCode.email}
              </span>{" "}
            </span>
          </div>
          <div className="flex gap-3 items-center cursor-pointer">
            <MdDelete
              size={20}
              className="text-gray-500"
              onClick={() => {
                updateAccessCodeState("", "");
                setReceiversList((prev: any) => {
                  const temp = [...prev];
                  temp[index].accessCode = "";
                  return temp;
                });
              }}
            />
            <MdOutlineKeyboardArrowUp
              size={24}
              className="text-primary "
              onClick={() => {
                setShowAccessCode({
                  show: false,
                  email: "",
                  roleTitle: "",
                });
              }}
            />
          </div>
        </div>
        <div className=" mt-4 mb-1 flex gap-2">
          <input
            type="text"
            placeholder="Access Code"
            className="border border-gray-300 rounded-md px-2 py-[6px] w-fit outline-none"
            //   maxLength={10}
            value={receiversList[index]?.accessCode}
            onChange={(e) => {
              if (e.target.value.includes(" ")) {
                setInputError({
                  show: true,
                  message: "Access code cannot contain spaces",
                });
                return;
              }
              if (e.target.value.length > 50) {
                setInputError({
                  show: true,
                  message: "Access code cannot be more than 50 characters",
                });
                return;
              }
              setInputError({
                show: false,
                message: "",
              });
              setReceiversList((prev: any) => {
                const temp = [...prev];
                temp[index].accessCode = e.target.value;
                return temp;
              });
            }}
          />
          <div className="border border-gray-300 rounded-md p-1 flex items-center justify-center cursor-pointer">
            <MdOutlineContentCopy
              size={16}
              className="text-gray-600 cursor-pointer hover:text-primary"
              onClick={() => {
                if (receiversList[index]?.accessCode === "") return;
                navigator.clipboard.writeText(receiversList[index]?.accessCode);
                toast.success("Access code copied to clipboard");
              }}
            />
          </div>
        </div>
        {inputError.show && inputError.message && (
          <p className="text-red-600 mb-2">* {inputError.message}</p>
        )}

        <div className="mt-2 text-gray-700 font-light flex flex-col gap-2">
          <p className="">Codes are not case-sensitive.</p>
          <p className="">You must provide this code to the signer.</p>
          <p className="">
            This code is available for you to view on the document dashboard
            page.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccessCodeDetailsComponent;
