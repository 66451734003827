//@ts-nocheck
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { validateEmail } from "../../utils/validateEmail";
import axios from "../../utils/axios";
import { IoWarning } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
const CsvUploadModal = ({ setModalType }) => {
  const expectedFields = [
    "firstName",
    "lastName",
    "email",
    "phone",
    "company",
    "jobTitle",
    "streetAddress",
    "city",
    "postalCode",
    "country",
    "state",
  ];
  const [customFields, setCustomFields] = useState([]); // List of all the custom fields in DB
  const [fieldTypes, setFieldTypes] = useState({}); // It is used to get the correct data associated with the field

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await axios.get("/workspace/getCustomFields");
        const data = response?.data;
        const customFieldsList = data?.fields?.customFields || [];
        const fieldsArray = customFieldsList.map((field) => {
          return field.field_name;
        });
        // Extract all the number fields as key as field_name: and value as "number"
        const numberFields = customFieldsList.reduce((acc, field) => {
          if (field.type === "number") {
            acc[field.field_name] = "number";
          }
          return acc;
        }, {});
        setFieldTypes(numberFields);
        setCustomFields(fieldsArray);
      } catch (error) {
        console.error("Error fetching custom contact fields", error);
      }
    };
    fetchFields();
  }, []);
  // Check if data is valid or not for each data according to type
  const isValidType = (value, type) => {
    if (type === "number") {
      if (value !== null) {
        return typeof value === "number" && !isNaN(value);
      }
    }
    return true;
  };
  const transformValues = (values) => {
    const transformedValues = {};
    const customData = {};

    expectedFields.forEach((field) => {
      if (values[field] !== null) {
        transformedValues[field] = values[field];
      } else {
        transformedValues[field] = "";
      }
    });
    // Add data in-front of custom field
    customFields.forEach((field) => {
      if (values[field] !== null) {
        customData[field] = String(values[field]);
      } else {
        customData[field] = null;
      }
    });
    // This data contain both default contact fields and custom contact fields
    const finalFieldsData = { ...transformedValues, customFields: customData };

    return finalFieldsData;
  };
  const [csvData, setCsvData] = useState([]);
  const [allCsvData, setAllCsvData] = useState([]); // This is used to get the default data in csv
  const [csvFile, setCsvFile] = useState(null);
  const [isCsvValid, setIsCsvValid] = useState(false);
  const [message, setMessage] = useState("");
  const [validContacts, setValidContacts] = useState([]);
  const [invalidContacts, setInvalidContacts] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState("");
  const [uploadBtn, setUploadBtn] = useState("Upload");

  const verifiedContacts = (data) => {
    // Remove the duplicate emails form the csvData
    const uniqueEmails = [...new Set(data.map((contact) => contact.email))];
    const validData = [];
    let invalidData = [];
    if (uniqueEmails.length < 100 && uniqueEmails.length < data.length) {
      setDuplicateMessage("& few emails are duplicate");
    }
    for (let i = 0; i < uniqueEmails.length; i++) {
      const email = uniqueEmails[i];
      const contact = data.find((contact) => contact.email === email);
      if (validateEmail(email)) {
        validData.push(contact);
        if (validData.length === 100) {
          break; // Break the loop when 100 valid contacts are found
        }
      } else {
        invalidData.push(contact);
      }
    }
    setInvalidContacts(invalidData);
    setValidContacts(validData);
    return validData;
  };

  const addContactsBulk = async () => {
    setUploadBtn("Uploading...");
    setIsCsvValid(false);
    const contacts = verifiedContacts(csvData);
    if (csvData.length > 100) {
      setMessage("100 contacts are allowed per upload");
    }
    try {
      // Perform bulk addContacts operation
      const modifiedContacts = contacts?.map((contact) => {
        return transformValues(contact);
      });
      if (modifiedContacts.length > 0) {
        const response = await axios.post("/workspace/bulkContactAdd", {
          contacts: modifiedContacts,
        });
        const data = response?.data;
        if (data) {
          if (!data.success) {
            toast.error("Something went wrong during bulk contact addition");
            return;
          }
          toast.success("Contacts added successfully!");
          setUploadStatus(true);
        }
      } else {
        setUploadStatus(true);
      }
    } catch (error) {
      console.error("Error while adding contacts in bulk", error);
      setUploadStatus(false);
      toast.error("Error while adding contacts in bulk");
    }
  };
  // Sample CSV file with all the  header data
  const downloadSampleCSV = () => {
    // Create a Blob containing the CSV data @ts-ignore
    const blob = new Blob([expectedFields.concat(customFields)], {
      type: "text/csv",
    });

    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link and trigger a click to download the file
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `bulk-contacts-template.csv`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };
  // For downloading the csv file of the incorrect contacts for updating and reupload
  const downloadIncorrectContacts = () => {
    const contacts = invalidContacts;
    // Map the invalidContacts data into CSV format
    const csvDataNew = [
      expectedFields.join(","), // Header Row
      ...contacts.map((contact) =>
        expectedFields.map((field) => contact[field] || "").join(",")
      ),
    ].join("\n");
    const blob = new Blob([csvDataNew], {
      type: "text/csv",
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `contacts_correction_data.csv`;
    document.body.appendChild(a);
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    uploadMore();
  };
  // Handle the csv file upload and set the data to csvData
  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    e.target.value = "";
    // Read the file and parse it
    setCsvFile(file);
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const result = event.target.result;
      if (!result) {
        toast.error("Please check csv file.");
        setCsvFile(null);
        setIsCsvValid(false);
        return;
      }
      // Parse the CSV data
      Papa.parse(result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (parsedData: any) => {
          if (
            !parsedData ||
            !parsedData.data ||
            !parsedData.data.length === 0 ||
            !parsedData.data[0]
          ) {
            toast.error("No data or invalid csv");
            setCsvFile(null);
            setIsCsvValid(false);
            return;
          }
          const userCsvFields = Object.keys(parsedData.data[0]);
          const isValidCsv = expectedFields
            .concat(customFields)
            .every((field) => userCsvFields.includes(field));
          // If all the fields are present
          if (isValidCsv) {
            // Filter the rows to remove invalid entries based on fieldType
            // This will remove all the rows that contain string inside a number type input
            const filteredData = parsedData.data.filter((row) => {
              return Object.keys(fieldTypes).every((field) => {
                return isValidType(row[field], fieldTypes[field]);
              });
            });
            setAllCsvData(parsedData.data); //
            setCsvData(filteredData);
            setIsCsvValid(true);
          } else {
            toast.error("Please ensure all required fields are present.");
            setCsvFile(null);
            setIsCsvValid(false);
          }
        },
        error: (error: any) => {
          console.error("CSV parsing error:", error.message);
        },
      });
    };
    if (file) {
      reader.readAsText(file);
    } else {
      setCsvFile(null);
      setIsCsvValid(false);
    }
  };
  // Clear the input and show the screen for uploading
  const uploadMore = () => {
    setIsCsvValid(false);
    setCsvData([]);
    setCsvFile(null);
    setUploadStatus(false);
    setMessage("");
    setDuplicateMessage("");
    setUploadBtn("Upload");
  };

  return (
    <>
      <div className="flex fixed top-0 left-0 z-[51] w-full h-full p-2 md:p-8 bg-[#00000091] justify-center items-start overflow-y-auto">
        <div className="bg-white  md:rounded-md rounded-sm  max-w-xl w-full min-h-[464px]">
          <div className="flex justify-between items-center mb-6 border-b py-2 md:py-4 md:px-8 px-4 bg-gray-50 md:rounded-t-md rounded-t-sm">
            <h2 className=" text-xl md:text-xl font-medium tracking-tight">
              Upload Contacts
            </h2>
            <GrClose
              className="text-xl hover:cursor-pointer"
              onClick={() => setModalType("")}
            />
          </div>
          {!uploadStatus && (
            <div className="flex flex-col md:px-8 px-4 pb-4 md:pb-8">
              <h2 className="text-base font-medium text-gray-800">
                Bulk Upload
              </h2>
              <p className="text-sm text-gray-600 mb-6">
                Add multiple contacts at once by completing this form.
              </p>
              <div className="flex flex-col">
                <p className="text-md w-fit uppercase font-semibold rounded-md flex items-center justify-center">
                  Step 1
                </p>
                <p className="text-sm text-gray-600 mt-1 mb-4">
                  Download the csv template and fill the details
                </p>
                <div>
                  <button
                    onClick={downloadSampleCSV}
                    className="px-4 py-1 bg-primary text-sm text-white rounded-md hover:opacity-90 mr-4"
                  >
                    Download
                  </button>
                </div>
              </div>
              <div className="mt-4 flex flex-col">
                <p className="text-md w-fit uppercase font-semibold rounded-md flex items-center justify-center">
                  Step 2
                </p>
                <p className="text-sm text-gray-600 mt-1 mb-4">
                  Upload the filled csv file
                </p>
                <div>
                  <div className="flex items-center min-h-[40px]">
                    <label
                      htmlFor="csv-upload"
                      onClick={() => {}}
                      className="cursor-pointer min-w-[120px] text-sm bg-secondary text-primary font-medium rounded-md px-4 py-1.5 flex items-center justify-center"
                    >
                      Choose File
                    </label>
                    <p className="ml-2 text-sm text-gray-700">
                      {csvFile ? csvFile?.name : "No file chosen"}
                    </p>
                  </div>
                  <input
                    className="py-2 self-start px-4 w-full outline-none text-gray-700 rounded-md border disabled:opacity-50 text-sm hidden"
                    id="csv-upload"
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={handleFileUpload}
                  />
                </div>
              </div>

              <div className="mt-4 flex justify-end">
                <button
                  className="px-6 py-2 mt-4 bg-primary text-xs md:text-sm text-white rounded-lg hover:opacity-90 md:mr-4 disabled:opacity-50"
                  disabled={!isCsvValid}
                  onClick={addContactsBulk}
                >
                  {uploadBtn}
                </button>
              </div>
            </div>
          )}
          {uploadStatus && (
            <div className="flex flex-col md:px-8 px-4 pb-4 md:pb-8">
              <div className="mt-2 mb-2 md:mb-8 text-center">
                <h1 className="text-[16px] md:text-[24px] font-bold">
                  Contacts Upload Status
                </h1>
                <p className="text-xs md:text-sm text-gray-600 mt-1">
                  <strong>{validContacts?.length}</strong> contacts uploaded out
                  of {allCsvData?.length} {duplicateMessage}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="pb-2 text-[#4BB71B] flex justify-center items-center flex-col gap-3">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </p>
              </div>
              {invalidContacts.length > 0 && (
                <div className="text-center mt-4">
                  <p className="text-xs md:text-sm text-red-600 mt-1">
                    {invalidContacts?.length} contacts were not uploaded due to
                    incorrect email format.
                  </p>
                  <p className="text-xs md:text-sm text-gray-600 mt-1">
                    Download incorrect data, update, and re-upload.
                  </p>
                  <button
                    className="px-6 py-2 bg-primary text-xs md:text-sm text-white  rounded-lg hover:opacity-90 md:mr-4 disabled:opacity-50 w-auto self-center my-2"
                    onClick={downloadIncorrectContacts}
                  >
                    <GoDownload className="inline mr-1 mb-[2px]" />
                    <span>Download CSV</span>
                  </button>
                </div>
              )}

              {invalidContacts.length === 0 && (
                <div className="text-center my-4">
                  <button
                    className="px-6 py-2 bg-primary text-xs md:text-sm text-white  rounded-lg hover:opacity-90 md:mr-4 disabled:opacity-50 w-auto self-center my-2"
                    onClick={uploadMore}
                  >
                    <span>Upload More</span>
                  </button>
                </div>
              )}
              {message.length > 0 && (
                <div className="text-center ">
                  <p className="text-xs md:text-sm text-yellow-600 mt-1">
                    <IoWarning className="inline mr-1 mb-[2px]" /> {message}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CsvUploadModal;
