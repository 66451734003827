//@ts-nocheck
import React, { Fragment, useState, useCallback, useEffect } from "react";
import _, { add } from "lodash";
import { HiPlus } from "react-icons/hi2";
import {
  MdClose,
  MdDelete,
  MdDragIndicator,
  MdOutlineDriveFileRenameOutline,
} from "react-icons/md";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../../../components/Modal";
import { v4, validate } from "uuid";
import { SIZES } from "../../../constants";
import { toast } from "react-toastify";
import axios from "../../../../../../utils/axios";
import { BASE_URL } from "../../../../../../constants/constants";
import {
  addSquareBrackets,
  removeSquareBrackets,
} from "../../../../../../utils/uiUtils";
import { isFeatureEnabled } from "../../../../../../utils/paidFeat";
import PleaseUpgradeModal from "../../../../../../components/PleaseUpgradeModal";
import { BLOCKING } from "../../../../../../constants/blockingConsants";
import { getOwnerPlanDetails } from "../../../../../../utils";
import ViewHeader from "./ViewHeader";
import { pipedriveVariablesMapping } from "../../../../../../constants/Integrations/pipedrive";
import MixPanel from "../../../../../../utils/services/mixpanel";
import { gohighlevelVariableMapping } from "../../../../../../constants/Integrations/goHighLevel";
import { hubspotvariableMapping } from "../../../../../../constants/Integrations/hubspot";

const AddVariable = ({ helpers }: any) => {
  const { customVariables, setVariableInputPopUp, addVariable, designerState } =
    helpers;
  const [nameWithPipedrive, setNameWithPipedrive] = useState(false);
  const [localVarName, setLocalVarName] = useState("");

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`w-fit relative bg-white rounded-xl px-8 py-5 overflow-y-auto`}
      >
        <div className="lg:w-[25vw]">
          <div className="">
            <p className="text-gray-700 text-md font-semibold mb-2">
              Add New Variable
            </p>
            {/* <p className="text-sm">This document will be deleted.</p> */}
          </div>
          <input
            placeholder="Variable Name"
            value={localVarName}
            className="outline-none w-full border-b-2 focus:border-primary p-2 pl-0 mb-3"
            onChange={(e) => {
              if (
                e.target.value.toLowerCase().startsWith("pipedrive") ||
                e.target.value.toLowerCase().startsWith("crm") ||
                e.target.value.toLowerCase().startsWith("hubspot")
              ) {
                setNameWithPipedrive(true);
              } else {
                setNameWithPipedrive(false);
              }
              setLocalVarName(e.target.value);
            }}
          />
          {designerState.customVariables.some(
            (ele) =>
              ele?.varName?.trim() === addSquareBrackets(localVarName)?.trim()
          ) && (
            <span className="text-red-400 text-xs">
              Variable already exists
            </span>
          )}
          {nameWithPipedrive && (
            <span className="text-red-400 text-xs">
              Variable name can not start with {localVarName}
            </span>
          )}

          <div className="flex justify-between gap-10 mt-5">
            <button
              onClick={() => {
                setVariableInputPopUp("");
              }}
              className={` flex items-center justify-center py-1 px-2 lg:mr-6 xl:mr-9 cursor-pointer text-sm bg-[#EAEAEA]  font-lightboldrounded-md rounded overflow-hidden `}
            >
              <span className="font-medium"> Cancel</span>
            </button>
            <button
              onClick={() => addVariable(addSquareBrackets(localVarName))}
              disabled={
                designerState.customVariables.some(
                  (ele) =>
                    ele?.varName?.trim() ===
                      addSquareBrackets(localVarName)?.trim() ||
                    localVarName == ""
                ) || nameWithPipedrive
              }
              className={` flex items-center justify-center py-1 px-2 cursor-pointer text-sm bg-primary text-white font-lightboldrounded-md rounded overflow-hidden`}
            >
              <span className="font-medium"> Add variable</span>
            </button>
          </div>
        </div>
        <div
          onClick={() => {
            setVariableInputPopUp("");
          }}
          className="absolute top-0 md:top-1 right-0 cursor-pointer"
        >
          <MdClose className="text-2xl m-4" />
        </div>
        <div></div>
      </div>
    </div>
  );
};

const EditVariable = ({ helpers }) => {
  const {
    customVariables,
    setVariableInputPopUp,
    editVariableIndex,
    setEditVariableIndex,
    editVariableName,
    newCustomVariableName,
    designerState,
  } = helpers;
  const [localVarName, setLocalVarName] = useState(
    removeSquareBrackets(newCustomVariableName)
  );
  const [nameWithPipedrive, setNameWithPipedrive] = useState(false);

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`w-fit relative bg-white rounded-xl px-8 py-5 overflow-y-auto`}
      >
        <div className="lg:w-[25vw]">
          <div className="mb-4">
            <p className="text-gray-700 text-md font-semibold mb-2">
              Rename variable
            </p>
            <p className="text-gray-500 text-sm pr-4">
              After saving, variable value will remain the same
            </p>
          </div>
          <input
            placeholder="Variable Name"
            value={localVarName}
            className="outline-none w-full border-b-2 focus:border-primary p-2 pl-0 mb-3"
            onChange={(e) => {
              if (
                e.target.value.toLowerCase().startsWith("pipedrive") ||
                e.target.value.toLowerCase().startsWith("crm") ||
                e.target.value.toLowerCase().startsWith("hubspot")
              ) {
                setNameWithPipedrive(true);
              } else {
                setNameWithPipedrive(false);
              }
              setLocalVarName(e.target.value);
            }}
          />
          {designerState.customVariables.some(
            (ele, i) =>
              ele?.varName?.trim() == addSquareBrackets(localVarName)?.trim() &&
              i !== editVariableIndex
          ) && (
            <span className="text-red-400 text-xs">
              Variable already exists
            </span>
          )}
          {nameWithPipedrive && (
            <span className="text-red-400 text-xs">
              Variable name can not start with {localVarName}
            </span>
          )}

          <div className="flex justify-between gap-10 mt-5">
            <button
              onClick={() => {
                setVariableInputPopUp("");
                setEditVariableIndex(-1);
              }}
              className={` flex items-center justify-center py-1 px-2 lg:mr-6 xl:mr-9 cursor-pointer text-sm bg-[#EAEAEA]  font-lightboldrounded-md rounded overflow-hidden `}
            >
              <span className="font-medium"> Cancel</span>
            </button>
            <button
              onClick={() =>
                editVariableName(
                  editVariableIndex,
                  addSquareBrackets(localVarName)
                )
              }
              disabled={
                designerState.customVariables.some(
                  (ele, i) =>
                    (ele?.varName?.trim() ===
                      addSquareBrackets(localVarName)?.trim() &&
                      i !== editVariableIndex) ||
                    ele?.varName == ""
                ) || nameWithPipedrive
              }
              className={` flex items-center justify-center py-1 px-2 cursor-pointer text-sm bg-primary text-white font-lightboldrounded-md rounded overflow-hidden`}
            >
              <span className="font-medium"> Save </span>
            </button>
          </div>
        </div>
        <div
          onClick={() => {
            setVariableInputPopUp("");
            setEditVariableIndex(-1);
          }}
          className="absolute top-0 md:top-1 right-0 cursor-pointer"
        >
          <MdClose className="text-2xl m-4" />
        </div>
      </div>
    </div>
  );
};
const integrations = [
  {
    key: "custom",
    name: "Custom",
  },
  {
    key: "pipedrive",
    name: "Pipedrive",
  },
  {
    key: "gohighlevel",
    name: "CRM",
  },
  {
    key: "hubspot",
    name: "HubSpot",
  },
];

const VariableView = ({ helpers }) => {
  const {
    open,
    setOpen,
    onDragStartHandle,
    addSchema,
    schemasList,
    setSchemasList,
    // ! Note : do not use this var
    customVariables,
    setCustomVariables,
    fetchedData,
    isPipedriveIntegrated,
    isGohighlevelIntegrated,
    isHubspotIntegrated,
    designerState,
  } = helpers;
  const [variableInputPopUp, setVariableInputPopUp] = useState<string>("");
  const [editVariableIndex, setEditVariableIndex] = useState<number>(-1);
  const [newCustomVariableName, setNewCustomVariableName] =
    useState<string>("");
  const [isVariableEnabled, setIsVariableEnabled] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeModalType, setUpgradeModalType] = useState("");
  const [showDropdown, setShowDropdown] = useState({
    custom: true,
    gohighlevel: false,
    pipedrive: false,
  });
  const [upgradeModalData, setUpgradeModalData] = useState<any>({
    title: "",
    desc: "",
    videoSrc: "",
  });
  // console.log("customVariables", customVariables);
  // console.log("designerState.customVariables", designerState.customVariables);

  const editVariableName = (index: number, value: string) => {
    let name = designerState.customVariables[index]?.varName;
    setCustomVariables((prev: any) =>
      prev.map((v: any, i: number) =>
        i === index ? { ...v, varName: value } : v
      )
    );
    let newSchemasList = schemasList.map((elements: SchemaForUI[]) =>
      elements.map((elem: SchemaForUI) =>
        elem?.subType === "variable" && elem?.variableName === name
          ? { ...elem, variableName: value }
          : elem
      )
    );
    setSchemasList(newSchemasList);
    setNewCustomVariableName("");
    setVariableInputPopUp("");
    setEditVariableIndex(-1);
  };

  const handleSaveVariables = async (debounceVariables) => {
    try {
      const data = await axios.post(`${BASE_URL}/add-variables`, {
        documentId: fetchedData?.document?.documentId,
        variables: debounceVariables,
      });
    } catch (error) {
      // toast.error("Something went wrong");
      console.log(error);
    }
  };

  // function to add Variables
  const addVariable = (value): void => {
    setCustomVariables((prev: any) => [
      ...prev,
      { varName: value, varValue: "" },
    ]);
    setVariableInputPopUp("");
    setNewCustomVariableName("");
  };

  const debounceCallHandleSave = useCallback(
    _.debounce(
      (debounceVariables: any) => handleSaveVariables(debounceVariables),
      2000
    ),
    []
  );

  useEffect(() => {
    debounceCallHandleSave(designerState.customVariables);
  }, [designerState.customVariables]);

  const checkFeature = async () => {
    let isEnabled = await isFeatureEnabled("VARIABLES");
    setIsVariableEnabled(isEnabled);
  };

  useEffect(() => {
    checkFeature();
  }, []);
  useEffect(() => {
    if (
      isPipedriveIntegrated &&
      !designerState.customVariables.some(
        (ele) => ele.varName == "[Pipedrive.Deal.ActivitiesToDo]"
      )
    ) {
      let pipedriveCustomVariables = [];
      const pipedriveKeys = Object.keys(pipedriveVariablesMapping);
      pipedriveKeys.forEach((ele) =>
        pipedriveCustomVariables.push({ varName: ele, varValue: "" })
      );
      setCustomVariables((prev) => [...prev, ...pipedriveCustomVariables]);
    }
    if (
      isGohighlevelIntegrated &&
      !designerState.customVariables.some(
        (ele) => ele.varName == "[crm.Opportunity.Status]"
      )
    ) {
      let goHighLevlCustomVariables = [];
      const goHighLevelKeys = Object.keys(gohighlevelVariableMapping);
      goHighLevelKeys.forEach((ele) =>
        goHighLevlCustomVariables.push({ varName: ele, varValue: "" })
      );
      setCustomVariables((prev) => [...prev, ...goHighLevlCustomVariables]);
    }
    if (
      isHubspotIntegrated &&
      !designerState.customVariables.some(
        (ele) => ele.varName == "[Hubspot.Deal.Amount]"
      )
    ) {
      let hubspotCustomVariables = [];
      const hubspotKeys = Object.keys(hubspotvariableMapping);
      hubspotKeys.forEach((ele) =>
        hubspotCustomVariables.push({ varName: ele, varValue: "" })
      );
      console.log(hubspotCustomVariables);
      setCustomVariables((prev) => [...prev, ...hubspotCustomVariables]);
    }
  }, [isPipedriveIntegrated, isGohighlevelIntegrated, isHubspotIntegrated]);

  return (
    <div className="flex h-[100%] max-h-[100%] flex-col w-[100%]">
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          data={upgradeModalData}
          blockingType={upgradeModalType}
        />
      )}
      <div className="px-4 overflow-y-auto">
        <div className="">
          {/* <div className="flex justify-between items-center text-gray-700 p-2 text-sm mb-2 mt-2">
            <span>Variables</span>
            <MdClose
              className={`text-xl cursor-pointer translate-x-[0.5rem] ${
                open ? "rotate-0" : "rotate-45"
              } transition-all duration-300`}
              onClick={() => {
                setOpen(false);
              }}
            />
          </div> */}
          <ViewHeader name="Variables" open={open} setOpen={setOpen} />
        </div>

        {/* this is code where user would add variables to the form */}
        {/* <div className="p-2 mx-4 h-[calc(68vh+1em)] overflow-y-auto max-h-[calc(68vh+1em)]"> */}
        <div>
          <button
            onClick={() => {
              new MixPanel().track("PDF template", {
                pdf_template_action: "pdf_template_add_custom_variable",
                paid: true,
              });
              new MixPanel().increment("pdf_template_add_custom_variable");
              const ownerPlanDetails = getOwnerPlanDetails();
              // console.log({ ownerPlanDetails, isVariableEnabled });
              if (ownerPlanDetails?.isAppSumo && !isVariableEnabled) {
                setShowUpgradeModal(true);
                setUpgradeModalType("VARIABLES");
                // setUpgradeModalData({
                //   title: BLOCKING.FOLDER.title,
                //   desc: BLOCKING.FOLDER.desc,
                //   redirect: BLOCKING.FOLDER.redirect,
                //   redirectText: BLOCKING.FOLDER.redirectText,
                // });
                return;
              }
              if (!isVariableEnabled) {
                setShowUpgradeModal(true);
                setUpgradeModalType("VARIABLES");
                // setUpgradeModalData({
                //   title:
                //     "Variables are available in FreeLancer plan and above.",
                //   desc: `You are on ${ownerPlanDetails?.currentPlan?.planName} upgrade to use this feature.`,
                // });
                return;
              }
              setVariableInputPopUp("newVariable");
            }}
            className={`mb-4 flex gap-2 items-center justify-center w-full py-2.5 px-3 cursor-pointer text-sm bg-primary text-white font-lightboldrounded-md rounded overflow-hidden`}
          >
            <HiPlus size={19} className="font-extrabold text-xl" />
            <span className="font-medium"> Add custom variable</span>
          </button>

          {/* <button
            onClick={() => handleSaveVariables()}
            className={`mb-4 flex items-center justify-center w-full py-2.5 px-3 cursor-pointer text-sm bg-primary text-white font-lightboldrounded-md rounded overflow-hidden`}
          >
            <span className="font-medium">Save Variables</span>
          </button> */}

          <p className="text-gray-500 text-xs pr-4 mb-2">
            Using a variable saves time.
          </p>

          <p className="text-gray-500 text-xs pr-4 mb-2">
            Simply add it once and it will automatically populate your page or
            template.
          </p>
          <p className="text-gray-500 text-xs pr-4 mb-2">
            A variable is enclosed within brackets, such as: [YourVariable.Name]
            — you can select from our list or create your own.
          </p>

          <div
            className="flex flex-col gap-1 overflow-y-auto overflow-x-hidden max-h-[calc(100vh_-_370px)] my-2 pr-2"
            id="mini-pdf-custom-scroll"
          >
            {integrations.map((ele) => {
              if (ele.key === "pipedrive" && !isPipedriveIntegrated) {
                return <></>;
              }
              if (ele.key === "gohighlevel" && !isGohighlevelIntegrated) {
                return <></>;
              }
              if (ele.key === "hubspot" && !isHubspotIntegrated) {
                return <></>;
              }

              let variables = [];
              if (ele.key === "custom") {
                variables = designerState.customVariables?.filter(
                  (variable) =>
                    !variable.varName.startsWith("[Pipedrive.") &&
                    !variable.varName.startsWith("[crm.") &&
                    !variable.varName.startsWith("[Hubspot.")
                );
              } else if (ele.key === "pipedrive") {
                variables = designerState.customVariables?.filter((variable) =>
                  variable.varName.startsWith("[Pipedrive.")
                );
              } else if (ele.key === "gohighlevel") {
                variables = designerState.customVariables?.filter((variable) =>
                  variable.varName.startsWith("[crm.")
                );
              } else if (ele.key === "hubspot") {
                variables = designerState.customVariables?.filter((variable) =>
                  variable.varName.startsWith("[Hubspot.")
                );
              }

              if (variables.length == 0) {
                return null;
              }

              return (
                <div className="mt-3" key={ele.key}>
                  <p
                    className={`flex justify-between items-center text-xs md:text-sm font-medium px-3 ${
                      !showDropdown[ele.key] &&
                      "bg-[#FCD94030] border-2 border-[#FCD940] text-gray-700 rounded"
                    } `}
                  >
                    {ele.name}
                    <button
                      className="ml-auto"
                      onClick={() =>
                        setShowDropdown((prev) => ({
                          ...prev,
                          [ele.key]: !prev[ele.key],
                        }))
                      }
                    >
                      {showDropdown[ele.key] ? (
                        <ChevronUpIcon className="h-6 w-6 text-gray-500" />
                      ) : (
                        <ChevronDownIcon className="h-6 w-6 text-gray-500" />
                      )}
                    </button>
                  </p>
                  <div
                    className={`overflow-hidden transition-max-height duration-700 ease-in-out ${
                      showDropdown[ele.key] ? "max-h-content" : "max-h-0"
                    }`}
                  >
                    {variables.map((variable: CustomVariableType) => {
                      let index = designerState.customVariables.findIndex(
                        (ele) => ele?.varName == variable?.varName
                      );
                      console.log(index, variable?.varName);
                      return (
                        <div className="my-3" key={`${ele.key}-` + index}>
                          <div className="flex justify-between items-center mb-1 relative">
                            <span
                              className={`text-gray-700 whitespace-nowrap text-ellipsis overflow-hidden w-[100%] placeholder:text-gray-500 p-1 text-xs outline-none min-w-2 px-2  bg-[#FCD94030] border-2 border-[#FCD940] rounded `}
                            >
                              <div
                                className={`whitespace-nowrap text-ellipsis overflow-hidden ${
                                  ele.key == "custom"
                                    ? "w-[calc(100%_-_55px)]"
                                    : "w-full"
                                } `}
                              >
                                {removeSquareBrackets(variable.varName)}
                              </div>
                            </span>
                            {ele.key == "custom" && (
                              <div className="flex gap-2 text-gray-400 items-center p-1 absolute right-1">
                                <button
                                  onClick={() => {
                                    setVariableInputPopUp("editVariable");
                                    setNewCustomVariableName(variable.varName);
                                    setEditVariableIndex(index);
                                  }}
                                >
                                  <MdOutlineDriveFileRenameOutline size={17} />
                                </button>
                                <button
                                  onClick={() => {
                                    let newSchemasList = schemasList.map(
                                      (elements: SchemaForUI[]) =>
                                        elements.filter(
                                          (elem: SchemaForUI) =>
                                            !(
                                              elem?.subType === "variable" &&
                                              elem?.variableName ==
                                                variable.varName
                                            )
                                        )
                                    );
                                    setSchemasList(newSchemasList);

                                    setCustomVariables((prev: any) =>
                                      prev.filter(
                                        (_: any, i: number) => i != index
                                      )
                                    );
                                  }}
                                >
                                  <MdDelete size={17} />
                                </button>
                              </div>
                            )}
                          </div>
                          <div
                            key={index}
                            className={`flex items-center cursor-pointer hover:opacity-70 relative`}
                            onClick={(e) => {
                              addSchema({
                                title: variable?.varValue,
                                type: "text",
                                id: v4(),
                                required: false,
                                subType: "variable",
                                role: {
                                  title: "",
                                  colour: "#FCD940",
                                },
                                variableName: variable?.varName,
                              });
                            }}
                            draggable
                            onDragStart={(e) => {
                              onDragStartHandle(e, {
                                data: {
                                  title: variable?.varValue,
                                  type: "text",
                                  id: v4(),
                                  required: false,
                                  subType: "variable",
                                  role: {
                                    title: "",
                                    colour: "#FCD940",
                                  },
                                  variableName: variable?.varName,
                                },
                                type: "PDF_TEMPLATE",
                                size: {
                                  width:
                                    SIZES.isFormMapper.isNotSignature.width,
                                  height:
                                    SIZES.isFormMapper.isNotSignature.height,
                                },
                              });
                            }}
                          >
                            <input
                              type="text"
                              data-tooltip-id={`input-value-${index}`}
                              data-tooltip-offset={10}
                              // className={`text-gray-700 text-sm placeholder:text-gray-500 outline-none bg-transparent `}
                              className="rounded bg-slate-50 outline-none w-full p-1 px-2 border-2 border-gray-200 focus:border-gray-400 text-xs text-gray-700 placeholder:text-gray-500"
                              placeholder={"Default Value"}
                              value={variable.varValue}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => {
                                setCustomVariables((prev: any) =>
                                  prev.map((v: any, i: number) =>
                                    i === index
                                      ? { ...v, varValue: e.target.value }
                                      : v
                                  )
                                );
                                let newSchemasList = schemasList.map(
                                  (elements: SchemaForUI[]) =>
                                    elements.map((elem: SchemaForUI) =>
                                      elem?.subType === "variable" &&
                                      elem?.variableName == variable.varName
                                        ? { ...elem, data: e.target.value }
                                        : elem
                                    )
                                );
                                setSchemasList(newSchemasList);
                              }}
                            />
                            <MdDragIndicator className="text-gray-400 text-[22px] absolute right-1 cursor-move" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {variableInputPopUp == "newVariable" && (
          <AddVariable
            helpers={{
              customVariables,
              setVariableInputPopUp,
              addVariable,
              designerState,
            }}
          />
        )}
        {variableInputPopUp == "editVariable" && (
          <EditVariable
            helpers={{
              customVariables,
              setVariableInputPopUp,
              editVariableIndex,
              setEditVariableIndex,
              editVariableName,
              newCustomVariableName,
              designerState,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default VariableView;
