import React, { useState } from "react";
import { BsChevronDown, BsChevronUp, BsInfoCircle } from "react-icons/bs";
import { MdClose, MdContentCopy, MdOutlineCreate } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../constants/constants";
import useWorkSpaceDetails from "../../../hooks/reduxHooks/useGetWorkSpaceDetails";
import useIsFeatureEnabled from "../../../hooks/reduxHooks/useIsFeatureEnabled";
import { setOwnerRx } from "../../../redux/slices/ownerSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { ALL_FEATURES } from "../../../types/tiers";
import { getWorkSpaceDetails } from "../../../utils";
import axios from "../../../utils/axios";
import MixPanel from "../../../utils/services/mixpanel";
import BoloLoader from "../../Common/Loader/BoloLoader";
import Modal from "../../Modal";
import PleaseUpgradeModal from "../../PleaseUpgradeModal";
import Webhooks from "../Webhooks";

const APIKey = () => {
  const dispatch = useAppDispatch();
  const owner = useAppSelector((state) => state?.root?.ownerSlice?.data?.owner);
  const [loadingKey, setLoadingKey] = useState(false);
  const [isKeyActionDelete, setIsKeyActionDelete] = useState(false);
  const [showKeyActionsModal, setShowKeyActionsModal] = useState(false);
  const [showKeyActions, setShowKeyActions] = useState(false);
  const featuresEnabled = useIsFeatureEnabled();
  const [upgradeModalType, setUpgradeModalType] = useState<ALL_FEATURES>("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showGenerateKeyModal, setShowGenerateKeyModal] = useState(false);
  const workSpaceDetails = useWorkSpaceDetails();
  const [keyName, setKeyName] = useState("");
  const [showCopyKeyModal, setShowCopyKeyModal] = useState(false);
  const [showKeyNameError, setShowKeyNameError] = useState(false);

  const DropdownActionsKey = () => {
    return (
      <div
        className={`absolute top-5 border border-gray-300 rounded-lg shadow-lg w-[180px] cursor-pointer bg-white ${
          !showKeyActions ? "hidden" : ""
        } `}
      >
        <div className="flex flex-col py-2">
          <div
            onClick={() => {
              setIsKeyActionDelete(false);
              setShowKeyActionsModal(true);
              setShowKeyActions(false);
            }}
            className="flex items-center gap-x-3 border-b-[1px] border-gray-300 p-3 hover:bg-primary/20"
          >
            <MdOutlineCreate className="text-xl" />
            <span>Regenerate</span>
          </div>
          <div
            onClick={() => {
              setIsKeyActionDelete(true);
              setShowKeyActionsModal(true);
              setShowKeyActions(false);
            }}
            className="flex items-center gap-x-3 text-red-500 p-3 hover:bg-primary/20"
          >
            <RiDeleteBinLine className="text-xl" />
            <span>Delete</span>
          </div>
        </div>
      </div>
    );
  };

  const generateKeyModalContent = () => {
    return (
      <div className="w-[100%] md:w-[600px] py-4 rounded-lg relative">
        <MdClose
          onClick={() => setShowGenerateKeyModal(false)}
          className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />

        <div className="flex flex-col px-3 md:px-10">
          <p className="mb-1 text-lg font-semibold text-center">
            Generate your API
          </p>
          <p className="text-sm font-semibold text-gray-500 mb-10 text-center">
            Type a API Key name that you will remember so you can identify it to
            monitor future usage.
          </p>
          <div className="mb-5 flex flex-col gap-y-3">
            <span className="text-sm font-semibold">API Key Name</span>
            <input
              type="text"
              placeholder="API Key Name"
              value={keyName}
              onChange={(e) => setKeyName(e.target.value)}
              className="text-xs md:text-base border border-gray-300 rounded-md py-2 px-3 outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-50"
              required
            />
            {keyName.trim() === "" && showKeyNameError && (
              <p className="text-xs text-red-500">API Key Name is required.</p>
            )}
          </div>
          <div className="flex gap-x-5">
            <button
              onClick={async (e) => {
                if (keyName.trim() === "") {
                  setShowKeyNameError(true);
                  return;
                }
                e.stopPropagation();

                setLoadingKey(true);
                console.log(keyName);
                try {
                  const { data } = await axios.post(`${BASE_URL}/apikey`, {
                    regenerateApiKey: false,
                    ownerId: owner?._id,
                    apiKeyName: keyName?.trim(),
                  });

                  // setOwner((prev: any) => ({
                  //   ...prev,
                  //   apiKey: data?.apiKeyObj,
                  // }));
                  dispatch(setOwnerRx({ ...owner, apiKey: data?.apiKeyObj }));

                  // localStorage.setItem(
                  //   "owner",
                  //   JSON.stringify({
                  //     ...owner,
                  //     apiKey: data?.apiKeyObj,
                  //   })
                  // );
                  toast.success("API key generated successfully");
                  setLoadingKey(false);
                  setShowKeyNameError(false);
                  setKeyName("");
                  setShowCopyKeyModal(true);
                } catch (error) {
                  setLoadingKey(false);
                  setShowKeyNameError(false);
                  setKeyName("");
                  toast.error("Error generating API key");
                }
                setShowGenerateKeyModal(false);
              }}
              disabled={
                !workSpaceDetails.isWorkSpaceOwner &&
                workSpaceDetails.currentWorkSpaceRole !== "admin"
              }
              className="text-xs md:text-sm text-white bg-primary hover:bg-tertiary px-6 py-2 rounded-full disabled:opacity-50"
            >
              {loadingKey ? "Generating..." : "Generate API Key"}
            </button>
            <button
              onClick={() => {
                setShowKeyNameError(false);
                setShowGenerateKeyModal(false);
                setKeyName("");
              }}
              className="text-xs md:text-sm text-white bg-[#ff5151] hover:bg-[#fe7777] px-6 py-2 rounded-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };
  const keyActionsModalContent = () => {
    return (
      <div className="w-[100%] md:w-[500px] py-4 rounded-lg relative">
        <MdClose
          onClick={() => {
            setIsKeyActionDelete(false);
            setShowKeyActionsModal(false);
          }}
          className="absolute top-1 md:top-0 right-0 text-2xl text-gray-600 cursor-pointer"
        />
        <div className="flex flex-col items-center justify-center px-3 md:px-10">
          <p className="mb-1 text-lg font-semibold">
            {`Are you sure want to ${
              isKeyActionDelete ? "delete" : "regenerate"
            } API key?`}
          </p>
          <p className="text-sm font-semibold text-gray-500 mb-10 text-center">
            {isKeyActionDelete
              ? "Deleting this API key will completely remove it. This cannot be undone."
              : "Regenerating this API key will create a new one with updated credentials, and it cannot be undone."}
          </p>
          <div className="flex gap-x-5">
            {isKeyActionDelete ? (
              <button
                onClick={async (e) => {
                  e.stopPropagation();

                  setLoadingKey(true);

                  try {
                    const { data } = await axios.put(
                      `${BASE_URL}/apikey/delete`,
                      {
                        ownerId: owner?._id,
                      }
                    );

                    // setOwner((prev: any) => data.updatedOwner);
                    dispatch(setOwnerRx({ ...owner, ...data?.updatedOwner }));

                    // localStorage.setItem(
                    //   "owner",
                    //   JSON.stringify(data.updatedOwner)
                    // );
                    setLoadingKey(false);
                    setIsKeyActionDelete(false);
                    setShowKeyActionsModal(false);
                    toast.success("API key deleted successfully");
                  } catch (error) {
                    setLoadingKey(false);
                    toast.error("Error deleting API key");
                  }
                }}
                className="text-sm text-white bg-[#ff5151] hover:bg-[#fe7777] px-6 py-2 rounded-full"
              >
                {loadingKey ? "Deleting..." : "Delete"}
              </button>
            ) : (
              <button
                onClick={async (e) => {
                  e.stopPropagation();

                  setLoadingKey(true);

                  try {
                    const { data } = await axios.post(`${BASE_URL}/apikey`, {
                      regenerateApiKey: true,
                      ownerId: owner?._id,
                      apiKeyName: owner?.apiKey?.apiKeyName,
                    });

                    // setOwner((prev: any) => ({
                    //   ...prev,
                    //   apiKey: data?.apiKeyObj,
                    // }));

                    dispatch(setOwnerRx({ ...owner, apiKey: data?.apiKeyObj }));

                    // localStorage.setItem(
                    //   "owner",
                    //   JSON.stringify({
                    //     ...owner,
                    //     apiKey: data?.apiKeyObj,
                    //   })
                    // );
                    setLoadingKey(false);
                    setShowKeyActionsModal(false);
                    toast.success("API key regenerated successfully");
                  } catch (error) {
                    setLoadingKey(false);
                    toast.error("Error regenerating API key");
                  }
                }}
                className="text-xs md:text-sm text-white bg-[#ff5151] hover:bg-[#fe7777] px-6 py-2 rounded-full"
              >
                {loadingKey ? "Regenerating..." : "Regenerate"}
              </button>
            )}
            <button
              onClick={() => {
                setIsKeyActionDelete(false);
                setShowKeyActionsModal(false);
              }}
              className="text-xs md:text-sm text-white bg-primary hover:bg-tertiary px-6 py-2 rounded-full"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-y-10">
      {showUpgradeModal && (
        <PleaseUpgradeModal
          show={showUpgradeModal}
          setShow={setShowUpgradeModal}
          blockingType={upgradeModalType}
        />
      )}
      {showGenerateKeyModal && (
        <Modal modalContent={generateKeyModalContent()} />
      )}
      {showKeyActionsModal && <Modal modalContent={keyActionsModalContent()} />}
      <div className="flex flex-col md:flex-row items-center gap-x-4 gap-y-2 lg:flex-row">
        <p className="text-xs md:text-sm font-semibold text-gray-400">
          Learn How to Use APIs: Click Here for a Comprehensive Guide
        </p>
        <button
          onClick={() =>
            window.open(
              "https://help.boloforms.com/en/collections/6817890-api",
              "_blank"
            )
          }
          className={`text-xs md:text-sm flex gap-x-1 flex-row justify-center items-center text-center hover:opacity-90 text-[13px] rounded-md capitalize border px-3 py-2 bg-white text-primary font-medium border-primary/50 hover:border-primary`}
        >
          <BsInfoCircle className="text-primary text-[15px]" />
          Learn More
        </button>
      </div>
      <div className="mx-2 text-xs md:text-sm ">
        {/* <h3 className="mb-2 text-base md:text-lg font-semibold">
              API Key
            </h3> */}
        <div className="flex gap-2 items-center">
          <span className="font-semibold">WorkSpace ID</span>:{" "}
          {getWorkSpaceDetails()?.currentWorkSpaceId}
          <MdContentCopy
            title="Copy to Clipboard"
            className="text-md cursor-pointer"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(
                  getWorkSpaceDetails()?.currentWorkSpaceId
                );
                toast.success("WorkSpace ID successfully copied to clipboard");
              } catch (err) {
                console.error("Failed to copy: ", err);
              }
            }}
          />
        </div>
      </div>
      <div className="mx-0 mb-10 border rounded-md flex items-center justify-center">
        {owner?.apiKey ? (
          <div className="w-full">
            <div className="p-4 border-b-2 border-primary/50 flex justify-between items-center">
              <h1 className="font-semibold">API Key</h1>
            </div>
            <div className="flex flex-col w-full justify-center items-center">
              <div className="hidden md:flex px-2 py-5 border-b-[1px] font-semibold border-gray-300 w-full gap-x-5 lg:px-10 text-xs">
                <span className="flex-1">NAME</span>
                <span className="flex-1">PREFIX</span>
                <span className="flex-1">DATE CREATED</span>
                <span className="flex-1">ACTION</span>
              </div>
              {loadingKey ? (
                <BoloLoader />
              ) : (
                <div className="flex items-center px-2 py-5 text-sm hover:bg-primary/20 w-full gap-x-5 lg:px-10">
                  <span className="flex flex-col gap-2 flex-1 ">
                    <span>{owner?.apiKey?.apiKeyName}</span>
                    <span className="text-xs block md:hidden">
                      {new Date(owner?.apiKey?.dateCreated)?.toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        }
                      )}
                    </span>
                  </span>
                  <div className="flex-1 flex items-center gap-x-2">
                    <span>{owner?.apiKey?.value?.substring(0, 7) + "..."}</span>
                    <MdContentCopy
                      title="Copy to Clipboard"
                      className="text-md cursor-pointer"
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText(
                            owner?.apiKey?.value
                          );
                          toast.success(
                            "API key successfully copied to clipboard"
                          );
                        } catch (err) {
                          console.error("Failed to copy: ", err);
                        }
                      }}
                    />
                  </div>
                  <span className="hidden md:block flex-1">
                    {new Date(owner?.apiKey?.dateCreated)?.toLocaleDateString(
                      "en-US",
                      {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                  </span>
                  <div className="block md:hidden">
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => {
                          setIsKeyActionDelete(false);
                          setShowKeyActionsModal(true);
                          setShowKeyActions(false);
                        }}
                        className="flex items-center gap-x-3 border-gray-300 p-3 hover:bg-primary/20"
                      >
                        <MdOutlineCreate className="text-xl" />
                      </div>
                      <div
                        onClick={() => {
                          setIsKeyActionDelete(true);
                          setShowKeyActionsModal(true);
                          setShowKeyActions(false);
                        }}
                        className="flex items-center gap-x-3 text-red-500 p-3 hover:bg-primary/20"
                      >
                        <RiDeleteBinLine className="text-xl" />
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block flex-1 relative">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowKeyActions(!showKeyActions);
                      }}
                    >
                      <span>Options</span>
                      <span className="text-[13px] fade hidden sm:block ml-2">
                        {showKeyActions ? <BsChevronUp /> : <BsChevronDown />}
                      </span>
                    </div>
                    <DropdownActionsKey />
                  </div>
                </div>
              )}
            </div>{" "}
          </div>
        ) : (
          <div className="w-full mr-auto shadow rounded-md">
            <div className="p-4 border-b border-primary/20 flex justify-between items-center">
              <h1 className="font-semibold">API Key</h1>
            </div>
            <div className="flex items-center justify-center flex-col gap-y-5 p-10">
              <p className="text-sm md:text-base font-semibold">
                You don't have any API key yet.
              </p>
              <button
                onClick={async (e) => {
                  new MixPanel().track("API", {
                    api_action: "api_create",
                    paid: true,
                  });
                  new MixPanel().increment("api_create");
                  // const isApiEnabled = await isFeatureEnabled(
                  //   "API_ACCESS"
                  // );
                  const isApiEnabled = featuresEnabled.API_ACCESS;
                  if (!isApiEnabled) {
                    setShowUpgradeModal(true);
                    setUpgradeModalType("API_ACCESS");
                    // setUpgradeModalData({
                    //   title: "APIs is a Premium Feature",
                    //   desc: `You are on ${currentPlan} upgrade to use this feature.`,
                    //   videoSrc: "",
                    // });
                    return;
                  }
                  // if (
                  //   JSON.parse(localStorage.getItem("owner") || "")
                  //     ?.planhistory[
                  //     JSON.parse(localStorage.getItem("owner") || "")
                  //       ?.planhistory?.length - 1
                  //   ]?.planId === "FREE_PLAN" &&
                  //   JSON.parse(localStorage.getItem("owner") || "")
                  //     ?.planhistory[
                  //     JSON.parse(localStorage.getItem("owner") || "")
                  //       ?.planhistory?.length - 1
                  //   ]?.isActive === true
                  // ) {
                  //   toast.error(
                  //     "You can't generate API key with free plan"
                  //   );
                  //   return;
                  // }
                  e.stopPropagation();
                  setShowGenerateKeyModal((prev) => !prev);
                }}
                disabled={
                  !workSpaceDetails.isWorkSpaceOwner &&
                  workSpaceDetails.currentWorkSpaceRole !== "admin"
                }
                className="text-xs text-white bg-primary hover:opacity-80 px-10 py-3 rounded-full disabled:opacity-50"
              >
                Generate API Key
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mx-0 mb-10 rounded-md flex items-center justify-center">
        <Webhooks />
      </div>
    </div>
  );
};

export default APIKey;
