/**
 * The key is window.location.hostname and the value is domain
 * After a new domain is added we need to add its pair
 * Why is this done
 *
 * We were previously using psl which used to convert
 * "signature.boloforms.com" -> "boloforms.com"
 * "app.dgsigner.com" -> "dgsigner.com"
 *
 * since we cant do this now as psl causes error
 * we use this method i.e. a constant obj
 *
 * why we just dont use spit ?
 * split will split based on "."
 * which is unrelaible as instead of .com the domain can also have .com.uk or .edu.in etc
 * this is what the psl was solving for us
 */
export function pslAltParse(url: string): string {
  return PSL_ALT_DOMAIN_MAP[url] || url;
}

export const PSL_ALT_DOMAIN_MAP: { [key: string]: string } = {
  "signature.boloforms.com": "boloforms.com", // cname // prod
  "siganture.bolosign.com": "bolosign.com", // white // Dev server
  "app.dgsigner.com": "dgsigner.com", // white & cname // actual prod
};
