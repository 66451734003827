// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  MdDelete,
  MdFormatAlignCenter,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdImage,
} from "react-icons/md";
import QuestionImage from "../QuestionImage";
import ResizableImage from "../ResizableImage";

const ImageQuestionPreview = ({ helpers }) => {
  const { question, setFormState, questionIndex, activeState } = helpers;
  let isEditorActive =
    questionIndex.p === activeState.pageIndex &&
    questionIndex.q === activeState.questionIndex;
  const moreOptionsRef = React.useRef(null);
  const newref = useRef(null);
  const [show, setShow] = React.useState({
    moreOptions: false,
  });

  const [formWidth, setFormWidth] = useState();

  const handleOutsideClick = (e) => {
    if (moreOptionsRef.current !== null) {
      if (moreOptionsRef.current.contains(e.target)) return;
    }
    setShow((prev) => ({ ...prev, moreOptions: false }));
  };

  useEffect(() => {
    if (newref.current) {
      const width = newref.current.offsetWidth;
      setFormWidth(width - 15);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div
      ref={newref}
      style={{
        display: "flex",
        justifyContent: question?.align ?? "left",
      }}
      className="w-full"
    >
      {question?.imageUrl && (
        <div className="w-fit mb-6 relative">
          <div ref={moreOptionsRef} className="h-full">
            {isEditorActive && (
              <div
                onClick={() =>
                  setShow((prev) => ({
                    ...prev,
                    moreOptions: !prev.moreOptions,
                  }))
                }
                className="absolute w-[48px] h-[48px] flex justify-center items-center shadow-lg bg-gray-200 hover:bg-gray-300 cursor-pointer rounded-full z-50"
              >
                <BsThreeDotsVertical className="text-xl text-gray-600" />
              </div>
            )}
            {show.moreOptions && (
              <MoreOptions helpers={{ question, setFormState, setShow }} />
            )}
            <div className="w-fit h-full">
              <ResizableImage
                src={question?.imageUrl}
                outerWidth={formWidth}
                setFormState={setFormState}
                question={question}
              />
            </div>
            {/* <img
                src={question?.imageUrl}
                alt="question-img"
                className="w-fit h-fit pointer-event-none"
              /> */}
          </div>
          {/* <div
        // onClick={() =>
        //   setShow((prev) => ({
        //     ...prev,
        //     moreOptions: !prev.moreOptions,
        //   }))
        // }
        className="absolute w-[48px] h-[48px] flex justify-center items-center shadow-lg bg-gray-200 hover:bg-gray-300 cursor-pointer rounded-full"
      >
        <BsThreeDotsVertical className="text-xl text-gray-600" />
      </div> */}
        </div>
      )}
    </div>
  );
};

export default ImageQuestionPreview;

const MoreOptions = ({ helpers }) => {
  let { question, setFormState, setShow } = helpers;

  const handleOptionClick = (option) => {
    const isAlign = ["left", "center", "right"].includes(option);

    if (isAlign) {
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.align = option;
            }
          });
        });
        return newState;
      });
    }
    if (option === "remove") {
      setFormState((prevState) => {
        let newState = { ...prevState };
        newState.pages.forEach((page) => {
          page.questions.forEach((question_) => {
            if (question_.id === question.id) {
              question_.imageUrl = "";
              if (question_?.width) {
                delete question_.width;
                delete question_.height;
              }
            }
          });
        });
        return newState;
      });
    }
    setShow((prevState) => {
      let newState = { ...prevState };
      newState.moreOptions = !newState.moreOptions;
      return newState;
    });
  };

  return (
    <div
      style={{
        marginTop: "48px",
        position: "absolute",
        top: 0,
        left: "-50px",
        boxShadow:
          "0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122)",
      }}
      className="py-2 w-[192px] bg-white rounded z-50"
    >
      <div
        onClick={() => handleOptionClick("left")}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdFormatAlignLeft className="text-2xl text-gray-800 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Left align</span>
        </div>
      </div>
      <div
        onClick={() => handleOptionClick("center")}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdFormatAlignCenter className="text-2xl text-gray-800 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Center align</span>
        </div>
      </div>
      <div
        onClick={() => handleOptionClick("right")}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdFormatAlignRight className="text-2xl text-gray-800 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Right align</span>
        </div>
      </div>
      <div
        // onClick={() => handleOptionClick("change")}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <QuestionImage
          helpers={{
            question,
            setFormState,
            isEditor: true,
            ImageIcon: (
              <div className="flex">
                <div className="w-[40px]">
                  <MdImage className="text-2xl text-gray-800 ml-auto" />
                </div>
                <div className="ml-1 px-4">
                  <span className="text-gray-600 text-sm">Change</span>
                </div>
              </div>
            ),
          }}
        />
        {/* <div className="w-[40px]">
            <MdImage className="text-2xl text-gray-800 ml-auto" />
          </div> */}
      </div>
      <div
        onClick={() => handleOptionClick("remove")}
        className={`h-[48px] w-full py-2 flex items-center cursor-pointer hover:bg-[#f2f3f4]`}
      >
        <div className="w-[40px]">
          <MdDelete className="text-2xl text-gray-800 ml-auto" />
        </div>
        <div className="ml-1 px-4">
          <span className="text-gray-600 text-sm">Remove</span>
        </div>
      </div>
    </div>
  );
};
