//@ts-nocheck

import React from "react";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import QuestionBottomOptions from "../../QuestionBottomOptions";
import QuestionInput from "../../QuestionInput";
import QuestionDesc from "../../QuestionDesc";
import QuestionInputOther from "../../QuestionInputOther";
import VideoQuestionPreview from "../../VideoQuestionPreview";

const Video = ({ data }) => {
  let {
    question,
    setFormState,
    isEditor,
    questionIndex,
    activeState,
    setActiveState,
    setActive,
    formState,
  } = data;

  return (
    <div className="w-full flex flex-row pb-2">
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <QuestionInputOther
              helpers={{
                question,
                setFormState,
                isEditor,
                questionIndex,
                activeState,
                setActive,
              }}
            />
          </div>
          <QuestionDesc
            helpers={{ question, setFormState, questionIndex, activeState }}
          />
        </div>
        <VideoQuestionPreview
          helpers={{ question, setFormState, questionIndex, activeState }}
        />
      </div>
    </div>
  );
};

export default Video;
