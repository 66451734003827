import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import getS3PreSignedUrl from "../../utils/getS3PreSignedUrl";
import { saveAs } from "file-saver";

const HubSpotPDfDownload = () => {
  const [searchParams] = useSearchParams();
  async function downloadPDF(url: string, fileName: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, fileName);
  }

  const redirectPdf = async () => {
    try {
      let pdfUrl = searchParams.get("pdfUrl");
      let fileName = searchParams.get("fileName");
      let url = await getS3PreSignedUrl(pdfUrl);
      downloadPDF(url, fileName + ".pdf");
    } catch (err) {
      console.log("error while downloading PDF");
    }
  };
  useEffect(() => {
    redirectPdf();
  }, []);
  return <div></div>;
};

export default HubSpotPDfDownload;
