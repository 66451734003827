import React from "react";
import { FaStar, FaStarHalf, FaRegStar } from "react-icons/fa";
import { twMerge } from "tailwind-merge";
import "./CustomerReview.css";

export type CustomerReview = {
  name: string;
  title: string;
  content: string;
  image: string;
  stars: number;
};

export const CustomerReviewCard = ({
  name,
  title,
  content,
  image,
  stars,
}: CustomerReview) => {
  return (
    <div
      className={`flex-[0_0_auto] bg-white flex flex-col rounded-3xl shadow-lg p-4 w-full border border-gray-300`}
    >
      <div className="flex flex-col xl:flex-row items-center gap-2">
        <div className={`w-14 h-14 rounded-full p-2 grid place-items-center`}>
          <img src={image} alt={name} className="rounded-full" />
        </div>

        <div>
          <p className="text-center xl:text-left text-gray-700 font-semibold">
            {name}
          </p>
          <div>
            <div className="flex gap-1 items-center text-xs">
              {[1, 2, 3, 4, 5].map((star, index) => (
                <div key={index} className="">
                  {stars >= star ? (
                    <FaStar className="text-yellow-400" />
                  ) : stars >= star - 0.5 ? (
                    <FaStarHalf className="text-yellow-400" />
                  ) : (
                    <FaRegStar className="text-yellow-400" />
                  )}
                </div>
              ))}
              <div className="">
                <span className="text-gray-400">{stars}/ 5</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-1 text-center xl:text-left pb-2 xl:pb-0 grow text-gray-700">
        <div>{`"${title}"`}</div>

        <p className="text-gray-900 text-xs font-light leading-4 mt-3 [display:-webkit-box] [-webkit-line-clamp:3] [-webkit-box-orient:vertical] overflow-hidden text-ellipsis max-h-14">
          {content}
        </p>
      </div>
    </div>
  );
};

function CustomerReviews({
  customerReviews = [],
  className = "",
}: {
  customerReviews: CustomerReview[];
  className: string;
}) {
  const newClassNames = (() => {
    const allClassWithScroll = className
      .split(" ")
      .filter((item) => item.includes("scroll"));
    const parentArr = [
      ...className.split(" ").filter((item) => !item.includes("scroll")),
    ];
    let childClassName = "";

    for (const item of allClassWithScroll) {
      const responsiveClassSplit = item.split(":");

      const responsiveClass =
        responsiveClassSplit.length > 1 ? responsiveClassSplit[0] + ":" : "";
      const scrollDirection =
        responsiveClassSplit.length > 1
          ? responsiveClassSplit[1]
          : responsiveClassSplit[0];

      if (
        scrollDirection === "scroll-down" ||
        scrollDirection === "scroll-up"
      ) {
        childClassName += ` ${responsiveClass}flex-col ${responsiveClass}${
          scrollDirection === "scroll-down"
            ? "animate-scroll-down"
            : "animate-scroll-up"
        }`;
        parentArr.push(
          ` ${responsiveClass} [mask:linear-gradient(0deg,transparent,#fff_5%,#fff_95%,transparent)] ${responsiveClass} [-webkit-mask:linear-gradient(0deg,transparent,#fff_5%,#fff_95%,transparent)]`
        );
      } else {
        childClassName += ` ${responsiveClass}animation-duration-20s ${responsiveClass}flex-row ${responsiveClass}${
          scrollDirection === "scroll-left"
            ? "animate-scroll-left"
            : "animate-scroll-right"
        }`;
        parentArr.push(
          ` ${responsiveClass} [mask:linear-gradient(90deg,transparent,#fff_5%,#fff_95%,transparent)] ${responsiveClass} [-webkit-mask:linear-gradient(90deg,transparent,#fff_5%,#fff_95%,transparent)]`
        );
      }
    }

    return [parentArr.join(" "), childClassName];
  })();

  return (
    <div
      className={twMerge(
        `overflow-hidden h-fit relative p-4`,
        newClassNames[0]
      )}
    >
      <div
        className={twMerge(
          `flex flex-row gap-4 items-stretch`,
          newClassNames[1]
        )}
      >
        {customerReviews.map((item, index) => (
          <div
            key={index}
            className="flex items-stretch min-w-[min(100%,300px)]"
          >
            <CustomerReviewCard {...item} />
          </div>
        ))}

        {customerReviews.map((item, index) => (
          <div
            key={index + customerReviews.length}
            className="flex items-stretch min-w-[min(100%,300px)]"
          >
            <CustomerReviewCard {...item} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CustomerReviews;
