import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import SignatureTemplateResponseMapper from "../../components/SignatureTemplateResponseMapper";
import StartSigningSignerPage from "../../components/Ui/StartSigningPageComponent";
import { Helmet } from "react-helmet";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
import axios from "../../utils/axios";
import { getDocument } from "pdfjs-dist/legacy/build/pdf.js";
import IpTrackCheck from "../../components/IpTrackCheck/IpTrackCheck";
import BoloLoader from "../../components/Common/Loader/BoloLoader";
import { IMAGE_LINKS } from "../../constants/common";

const DocumentTemplateApprover = () => {
  const whiteLabelInfo = useAppSelector(
    (state) => state?.root?.whiteLabelSlice?.data?.whiteLabelOwner
  );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [documentId, setDocumentId] = useState("");
  const key = searchParams.get("key");
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState<any>(null);
  const [isStartSigningPage, setIsStartSigningPage] = useState(true);
  const [schemas, setSchemas] = useState<any>([]);
  const [authorEmail, setAuthorEmail] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [signerName, setSignerName] = useState("");
  const [shownModal, setShownModal] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [viewLimit, setViewLimit] = useState(false);
  const [refetch, setRefetchData] = useState(false);
  const [approverDetails, setApproverDetails] = useState<any>(null);
  const [approverId, setApproverId] = useState<string>("");
  const [fetchedData, setFetchedData] = useState<{
    accountInfo: any;
    document: any;
    // !NOTE: this is not supported in old docmentId signerId system
    // ! and will only be supported in getDocumentByKey
    paymentStatus: {
      isPayment: boolean;
      message: string;
      isPaid?: boolean;
    } | null;
    pdfTemplateRespondent: any;
  }>({
    accountInfo: null,
    document: null,
    paymentStatus: null,
    pdfTemplateRespondent: null,
  });
  const [documentDataFetched, setDocumentDataFetched] =
    useState<boolean>(false);
  const [brandingInfofetched, setBrandingInfoFetched] =
    useState<boolean>(false);
  const arrayBufferToBase64 = (buffer: any) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };
  const getDocumentDetailsByKey = async () => {
    if (!key) {
      return;
    }
    console.log("getDocumentDetails By key ----------");
    try {
      const ipResponse = getIpAddressLocal();
      const ipData = await ipResponse;

      const { data } = await axios.get(
        `/signingLog/getOneDocumentByKey-approval?key=${key}&ip=${ipData.ip}`
      );

      if (data?.success === false) {
        setViewLimit(true);
      }

      if (data.error) {
        toast.error(data.error);
        return;
      }
      if (!data.error) {
        let { approver, document } = data;
        setApproverDetails(approver);
        setApproverId(approver?.approverId || "");
        setDocumentId(data?.document?.documentId || "");
        let pdfUrl =
          approver.pdfLink ||
          data.document.documentUrl ||
          data.document.pdfTemplate?.basePdf;
        onClickStart(pdfUrl);
        setFetchedData((prev) => ({
          ...prev,
          document: data?.document,
          paymentStatus: data?.paymentStatus,
          pdfTemplateRespondent: data?.pdfTemplateRespondent,
        }));
        setSchemas(approver?.templateSchema);
        setAuthorEmail(data.document.authorEmail);
        setAuthorName(data.document.ownerId.name);
        setSignerName(approver.name);
        setDocumentDataFetched(true);
        if (
          approver?.status === "APPROVED" ||
          approver?.status === "REJECTED"
        ) {
          navigate(`/approver-success?key=${key}`);
        }
      }

      // setLoading(false);
    } catch (error) {
      console.log("Error in fetching key", error);
      // setLoading(false);
      toast.error("Error fetching form");
    }
  };
  useEffect(() => {
    getDocumentDetailsByKey();
  }, [documentId, key, refetch]);
  const getBrandingInfo = async () => {
    try {
      let { data } = await axios.post(`/owner/get-branding-info`, {
        id: documentId,
        type: "PDF-TEMPLATE",
      });

      if (data?.accountInfo) {
        await setFetchedData((prev) => ({
          ...prev,
          accountInfo: data?.accountInfo,
        }));
        setBrandingInfoFetched(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (documentId) {
      getBrandingInfo();
    }
  }, [documentId]);

  useEffect(() => {
    if (pdfFile) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [pdfFile]);
  let onClickStart = async (pdfUrl: string) => {
    const responseFile = await fetch(pdfUrl);
    const pdfBuffer = await responseFile.arrayBuffer();

    // Load PDF document
    const pdfDoc = await getDocument(pdfUrl).promise;

    // Get number of pages
    const numPages = pdfDoc.numPages;

    setNumberOfPages(numPages);

    // Convert PDF buffer to a base64 string

    let pdfBase64 = arrayBufferToBase64(pdfBuffer);
    pdfBase64 = `data:application/pdf;base64,${pdfBase64}`;
    setPdfFile(pdfBase64);
  };

  if (viewLimit) {
    return (
      <div className="h-screen flex justify-center items-center flex-col">
        <div className="text-3xl font-bold mb-16">
          Due to Security Reasons your Link has Expired
        </div>
        <div className="flex gap-4">
          <img
            // src="https://i.imgur.com/wJRAEFT.png"
            src="https://i.imgur.com/hlqjxfi.png"
            alt="logo"
            className="h-[45vh]"
            loading="lazy"
          />
          <div className="flex flex-col gap-2 max-w-[300px]">
            <div className="font-semibold text-lg">To regenrate your link</div>
            <div className="text-sm">
              Select the "Open The Document" button in the email that redirected
              you to this page to continue
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (loading || !brandingInfofetched || !documentDataFetched) {
    return (
      <div className="h-screen flex justify-center items-center">
        <IpTrackCheck />
        <BoloLoader />
      </div>
    );
  }
  return (
    // removed negative margins
    <div className={`w-full`}>
      <IpTrackCheck />
      <Helmet>
        <title translate="no">Pdf Template Approve</title>
        <meta charSet="utf-8" />
        <meta name="title" content="Pdf Template Approve" />
        <meta
          name="description"
          content="Signer's dedicated page for completing and adding signatures to the pdf template document."
        />
        <meta name="author" content="BoloForms" />
        <meta name="publisher" content="BoloForms" />
        <meta
          property="og:url"
          content={`https://signature.boloforms.com/sign-pdf-template`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Pdf Template Approve - Boloforms`}
        />
        <meta
          property="og:description"
          content="Signer's dedicated page for completing and adding signatures to the pdf template document."
        />
        <link
          rel="canonical"
          href="https://signature.boloforms.com/sign-pdf-template"
        />
        <link
          rel="icon"
          type="image/png"
          href={
            whiteLabelInfo?.settings?.organization?.favicon ||
            IMAGE_LINKS.FAVICON
          }
        />
      </Helmet>
      {shownModal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.6)", // Dark overlay color
            zIndex: 999, // Place the overlay above other content
          }}
        ></div>
      )}
      {pdfFile && approverDetails && (
        <div
          className={`w-full h-full overflow-y-hidden absolute ${
            documentId &&
            // signerId &&
            approverDetails &&
            isStartSigningPage &&
            "blur-sm"
          }`}
        >
          <SignatureTemplateResponseMapper
            file={pdfFile}
            numberOfPages={numberOfPages}
            signerDetails={approverDetails}
            schemas={schemas}
            loading={loading}
            setShownModal={setShownModal}
            isSigning={false}
            documentIdProp={documentId}
            signerIdProp={approverId}
            fetchedData={fetchedData}
            setRefetchData={setRefetchData}
            isApprover={true}
          />
        </div>
      )}
      {documentId &&
        approverId &&
        approverDetails &&
        isStartSigningPage &&
        fetchedData?.accountInfo &&
        brandingInfofetched && (
          <StartSigningSignerPage
            loading={loading}
            isSigner={true}
            onClick={() => {
              setIsStartSigningPage(false);
            }}
            setStartSigning={() => {}}
            email={approverDetails.email}
            documentId={documentId}
            extraDetails={{
              authorEmail,
              authorName,
              signerName,
              signerId: "",
            }}
            isOnlyPDF={false}
            isDocumentAvailableToSign={
              (approverDetails?.isDeleted
                ? !approverDetails?.isDeleted
                : true) &&
              (approverDetails?.isVoid ? !approverDetails?.isVoid : true)
            }
            accountInfo={fetchedData?.accountInfo}
            isApprover={true}
          />
        )}
      <ToastContainer
        transition={Slide}
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </div>
  );
};

export default DocumentTemplateApprover;
