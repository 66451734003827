// @ts-nocheck
import React from "react";
import "./index.css";
import { useState } from "react";
import { MdClose, MdOutlineImage } from "react-icons/md";
import { toast } from "react-toastify";
import Modal from "../../Modal";
import { GrClose } from "react-icons/gr";
import { BsCheckLg, BsFillFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import imageCompression from "browser-image-compression";
import { v4 } from "uuid";
import { generalizedFileUploadViaSignedUrlPublic } from "../../../utils/fileUpload";

// const AWS = require("aws-sdk");
// AWS.config.update({
//   region: "ap-south-1",
//   accessKeyId: "AKIAYJTP7V2HVEJ4PL55",
//   secretAccessKey: "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",
// });
// const s3 = new AWS.S3({
//   params: { Bucket: "boloforms-main/approval-dash" },
//   region: "ap-south-1",
// });
// import { S3, PutObjectCommand } from "@aws-sdk/client-s3";
// const s3 = new S3({
//   region: "ap-south-1",
//   credentials: {
//     accessKeyId: "AKIAYJTP7V2HVEJ4PL55",
//     secretAccessKey: "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",
//   },
// });

const PopUpContent = ({ popUp, setPopUp, question, setFormState }) => {
  const [files, setFiles] = React.useState([]);
  const [imageUrl, setImageUrl] = React.useState("");
  const [active, setActive] = React.useState({
    tab: "upload",
  });
  //   console.log(question);

  useEffect(() => {
    if (imageUrl) {
      setFiles([]);
      handleUrl(imageUrl);
    }
  }, [imageUrl]);

  function handleUrl(url_) {
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.imageUrl = url_;
          }
        });
      });
      return newState;
    });
  }

  function handleDelete() {
    setFiles([]);
    setFormState((prevState) => {
      let newState = { ...prevState };
      newState.pages.forEach((page) => {
        page.questions.forEach((question_) => {
          if (question_.id === question.id) {
            question_.imageUrl = "";
          }
        });
      });
      return newState;
    });
    setPopUp((prev) => !prev);
  }

  const uploadFile = async (file) => {
    try {
      if (file.type?.includes("image")) {
        const options = {
          maxSizeMB: 5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        file = await imageCompression(file, options);
        // console.log(`file after compreess`, file);
      }

      const link = await generalizedFileUploadViaSignedUrlPublic({
        file,
        folderName: `/approval-dash/questionImages/${v4()}`,
        bucketName: "boloforms-main",
      });

      return link;

      // let params = {
      //   Bucket: `boloforms-main`,
      //   Key: `/approval-dash/questionImages/${v4()}/${file.name}`,
      //   Body: file,
      // };
      // // let uploaded = await s3.upload(params).promise();
      // let command = new PutObjectCommand(params);
      // let uploaded = await s3.send(command);
      // uploaded.Location = `https://boloforms-main.s3.ap-south-1.amazonaws.com/${params.Key}`;
      // console.log("uploaded", uploaded);
      // return uploaded.Location;
    } catch (err) {
      console.log(err);

      return false;
    }
  };

  return (
    <div className="relative w-[768px] min-h-[440px]">
      <div className="mt-2 mb-6 flex justify-between">
        <h1 className="text-xl text-gray-500">Insert image</h1>
        <MdClose
          className="cursor-pointer text-2xl text-gray-600"
          onClick={() => setPopUp((prev) => !prev)}
        />
      </div>
      <div className="-mx-1 h-[48px] flex">
        <div
          onClick={() => setActive((prev) => ({ ...prev, tab: "upload" }))}
          className={`${
            active.tab === "upload" ? "text-blue-500" : "text-[#3C4043]"
          }  relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
        >
          <span className="px-3">Upload</span>
          {active.tab === "upload" && (
            <div className="absolute bottom-0 w-full h-[3px] rounded-t-xl bg-blue-500"></div>
          )}
        </div>
        <div
          onClick={() => setActive((prev) => ({ ...prev, tab: "by-url" }))}
          className={`${
            active.tab === "by-url" ? "text-blue-500" : "text-[#3C4043]"
          }  relative h-full text-sm w-fit font-medium flex items-center cursor-pointer`}
        >
          <span className="px-3">By URL</span>
          {active.tab === "by-url" && (
            <div className="absolute bottom-0 w-full h-[3px] rounded-t-xl bg-blue-500"></div>
          )}
        </div>
      </div>
      <hr className="-mx-8" />

      {active.tab === "upload" && (
        <div className="flex flex-col items-center justify-between">
          <div className="my-4">
            <img
              src="https://ssl.gstatic.com/docs/picker/images/upload_background.png"
              alt="upload"
              className="h-[180px]"
            />
          </div>
          <div className="file-input relative">
            <input
              type="file"
              name={`file-${question.id}`}
              id={`file-${question.id}`}
              accept="image/jpeg, image/png, image/svg, image/jpg"
              onChange={async (e) => {
                console.log(e.target.files);
                if (e.target.files[0]?.size > 10485760) {
                  return toast.error("File size should be less than 10MB");
                }
                let location = await uploadFile(e.target.files[0]);
                if (location) {
                  handleUrl(location);
                  setPopUp((prev) => !prev);
                } else {
                  toast.error("Could Not upload image");
                }
                // setFiles(e.target.files);
                // handleFile(e.target.files);
              }}
              className="w-full absolute top-0 left-0 opacity-0"
            />
            <label htmlFor={`file-${question.id}`} className="border-none">
              <div className="flex justify-center">
                <span className="text-sm bg-blue-500 text-white px-6 py-2 rounded">
                  Browse
                </span>
              </div>
            </label>
          </div>
          <p className="text-gray-500 text-lg mt-2">or drop file here</p>
        </div>
      )}
      {active.tab === "by-url" && (
        <div className="h-[320px] flex flex-col items-center relative">
          <div className="w-[420px] pt-[60px] flex flex-col items-center">
            <input
              type="text"
              value={imageUrl}
              placeholder={imageUrl || "Paste URL of image"}
              onChange={(e) => {
                setImageUrl(e.target.value);
              }}
              className="w-full pb-0.5 text-gray-600 border-b-2 border-gray-200 hover:border-gray-200 disabled:hover:border-white focus:border-b-2 focus:border-blue-500 outline-none"
            />
            <p className="mt-3 w-full text-xs text-gray-400">
              Only select images that you've confirmed you have the licence to
              use.
            </p>
          </div>
          <div className="absolute bottom-0 right-0 flex">
            <button
              className="mr-12 text-sm text-gray-500 hover:text-gray-600 uppercase"
              onClick={handleDelete}
            >
              Cancel
            </button>
            <button
              className="text-gray-500 text-sm hover:text-gray-600 uppercase"
              onClick={() => setPopUp((prev) => !prev)}
            >
              Insert Image
            </button>
          </div>
        </div>
      )}
      {/* <p className="p-2 text-sm text-gray-300">or</p>
      <div className="formbold-mb-5 formbold-file-input">
        <input
          type="file"
          name={`file-${question.id}`}
          id={`file-${question.id}`}
          accept="image/jpeg, image/png, image/svg, image/jpg"
          onChange={async (e) => {
            console.log(e.target.files);
            if (e.target.files[0]?.size > 10485760) {
              return toast.error("File size should be less than 10MB");
            }
            let location = await uploadFile(e.target.files[0]);
            if (location) {
              handleUrl(location);
            } else {
              toast.error("Could Not upload image");
            }
            // setFiles(e.target.files);
            // handleFile(e.target.files);
          }}
          className="w-full"
        />
        <label htmlFor={`file-${question.id}`}>
          {files?.length > 0 ? (
            <div className="flex flex-row items-center flex-wrap">
              <div
                className={`${
                  files?.length > 0 && "opacity-60"
                } w-[100px] p-4 h-full mr-4 border flex flex-col items-center bg-gray-100 rounded-md`}
              >
                <div>
                  <BsFillFileEarmarkSpreadsheetFill className="text-4xl text-gray-500" />
                </div>
                <div className="mt-6 w-full text-xs truncate">
                  {files[0].name}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <span className="formbold-drop-file text-gray-500">
                Drop a file here or click the button below to load the file.
              </span>
              <span className="formbold-browse">Choose file</span>
            </div>
          )}
        </label>
      </div>
      <p className="text-xs text-gray-500">
        Only .jpeg, .jpg, .png, .svg images accepted
      </p> */}
    </div>
  );
};

const QuestionImage = ({ helpers }) => {
  let { question, setFormState, isEditor, ImageIcon } = helpers;
  const [popUp, setPopUp] = useState(false);

  if (!isEditor) {
    return (
      <div className="max-h-[500px] my-4">
        <img
          className="h-auto w-auto rounded-md"
          src={question?.imageUrl || ""}
          alt=""
        />
      </div>
    );
  }

  return (
    <div>
      {ImageIcon ? (
        <div
          onClick={(e) => {
            // e.stopPropagation();
            setPopUp((prev) => !prev);
          }}
        >
          {ImageIcon}
        </div>
      ) : (
        <div
          onClick={() => setPopUp((prev) => !prev)}
          className="ml-2 min-w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
        >
          <MdOutlineImage className="text-2xl text-gray-500" />
        </div>
      )}
      {popUp && (
        <Modal
          modalContent={
            <PopUpContent
              question={question}
              popUp={popUp}
              setPopUp={setPopUp}
              setFormState={setFormState}
            />
          }
        />
      )}
    </div>
  );
};

export default QuestionImage;
