import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export type UseQueryDashboardInstanceType =
  | "FORM_DASHBOARD"
  | "PDF_DASHBOARD"
  | "PDF_TEMPLATE_DASHBOARD"
  | "PDF_TEMPLATE_RESPONSE_DASHBOARD"
  | "PRODUCT_CATALOG"
  | "OVERVIEW"
  | "PAYMENTS";

const useQueryHook = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dashboardInstance, setDashboardInstance] =
    useState<UseQueryDashboardInstanceType>("FORM_DASHBOARD");
  const [isFilter, setIsFilter] = useState(false);
  // new search based filters
  function getSearchData() {
    return {
      pageNo_: !isNaN(parseInt(searchParams.get("pageNo_") ?? "1"))
        ? parseInt(searchParams.get("pageNo_") ?? "1")
        : 1,
      limit_: !isNaN(parseInt(searchParams.get("limit_") ?? "10"))
        ? parseInt(searchParams.get("limit_") ?? "10")
        : 10,
      query_: searchParams.get("query_") ?? "",
      dateFrom_: searchParams.get("dateFrom_") ?? "",
      dateTo_: searchParams.get("dateTo_") ?? "",
      sortBy_: searchParams.get("sortBy_") ?? "", // consdering backend will always sort by created at
      sortOrder_: searchParams.get("sortOrder_") ?? "-1",
      filter_: searchParams.get("filter_") ?? "ALL",
      overViewType_: searchParams.get("overViewType_") ?? "ALL",
      // reload trigger flip to reload again
      rT_: searchParams.get("rT_") ?? "0",
      pCategory_: searchParams.get("pCategory_") ?? "",
      currency_: searchParams.get("currency_") ?? "",
    };
  }

  function setSearchData({
    pageNo_,
    limit_,
    query_,
    dateFrom_,
    dateTo_,
    sortBy_,
    sortOrder_,
    filter_,
    rT_,
    pCategory_,
    currency_,
    overViewType_,
  }: {
    pageNo_?: number;
    limit_?: number;
    query_?: string;
    dateFrom_?: Date;
    dateTo_?: Date;
    sortBy_?: string;
    sortOrder_?: "1" | "-1";
    filter_?: string;
    rT_?: "0" | "1";
    pCategory_?: string;
    currency_?: string;
    overViewType_?: string;
  }) {
    if (pageNo_ !== undefined) {
      searchParams.set("pageNo_", pageNo_.toString());
    }
    if (limit_ !== undefined) {
      searchParams.set("limit_", limit_.toString());
    }
    if (query_ !== undefined) {
      searchParams.set("query_", query_);
    }
    if (dateFrom_ !== undefined) {
      searchParams.set("dateFrom_", dateFrom_?.toISOString());
    }
    if (dateTo_ !== undefined) {
      searchParams.set("dateTo_", dateTo_?.toISOString());
    }
    if (sortBy_ !== undefined) {
      searchParams.set("sortBy_", sortBy_);
    }
    if (sortOrder_ !== undefined) {
      searchParams.set("sortOrder_", sortOrder_);
    }
    if (filter_ !== undefined) {
      searchParams.set("filter_", filter_);
    }
    if (rT_ !== undefined) {
      searchParams.set("rT_", rT_);
    }
    if (pCategory_ !== undefined) {
      searchParams.set("pCategory_", pCategory_);
    }
    if (currency_ !== undefined) {
      searchParams.set("currency_", currency_);
    }
    if (overViewType_ !== undefined) {
      searchParams.set("overViewType_", overViewType_);
    }
    setSearchParams(searchParams);
  }

  function reset(
    key?:
      | "pageNo_"
      | "limit_"
      | "query_"
      | "dateFrom_"
      | "dateTo_"
      | "sortBy_"
      | "sortOrder_"
      | "filter_"
      | "pCategory_"
      | "currency_"
      | "overViewType_"
      | "all"
  ) {
    // console.log({ key });
    if (key === "all" || key === "pageNo_") {
      searchParams.set("pageNo_", "1");
    }
    if (key === "all" || key === "limit_") {
      searchParams.set("limit_", "10");
    }
    if (key === "all" || key === "query_") {
      searchParams.delete("query_");
    }
    if (key === "all" || key === "dateFrom_") {
      searchParams.delete("dateFrom_");
    }
    if (key === "all" || key === "dateTo_") {
      searchParams.delete("dateTo_");
    }
    if (key === "all" || key === "sortBy_") {
      searchParams.delete("sortBy_");
    }
    if (key === "all" || key === "sortOrder_") {
      searchParams.delete("sortOrder_");
    }
    if (key === "all" || key === "filter_") {
      searchParams.delete("filter_");
    }
    if (key === "all" || key === "pCategory_") {
      searchParams.delete("pCategory_");
    }
    if (key === "all" || key === "currency_") {
      searchParams.delete("currency_");
    }
    if (key === "all" || key === "overViewType_") {
      searchParams.delete("overViewType_");
    }
    let data = getSearchData();
    if (data.rT_ === "0" || !data.rT_) {
      searchParams.set("rT_", "1");
    }
    if (data.rT_ === "1") {
      searchParams.set("rT_", "0");
    }

    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (location?.pathname?.includes("form"))
      setDashboardInstance("FORM_DASHBOARD");

    if (location?.pathname?.includes("pdf"))
      setDashboardInstance("PDF_DASHBOARD");

    if (location?.pathname?.includes("template"))
      setDashboardInstance("PDF_TEMPLATE_DASHBOARD");

    if (location?.pathname?.includes("product"))
      setDashboardInstance("PRODUCT_CATALOG");

    if (location?.pathname?.includes("payments"))
      setDashboardInstance("PAYMENTS");

    if (location?.pathname?.includes("templates/respondents"))
      setDashboardInstance("PDF_TEMPLATE_RESPONSE_DASHBOARD");

    if (location?.pathname?.includes("overview"))
      setDashboardInstance("OVERVIEW");

    let data = getSearchData();
    // console.log(data);
    if (
      data.query_ ||
      data.dateFrom_ ||
      data.dateTo_ ||
      (data.filter_ && data.filter_ !== "ALL")
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [location]);

  return { getSearchData, setSearchData, dashboardInstance, reset, isFilter };
};

export default useQueryHook;
