import { useEffect, useRef, useState } from "react";
// import axios from "axios";
import { FaArrowDown, FaExternalLinkAlt, FaSignature } from "react-icons/fa";
import { IoLanguageSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { COLORS, IMAGE_LINKS } from "../../constants/common";
import { BASE_URL } from "../../constants/constants";
import { getWhiteLabelInfoThunk } from "../../redux/slices/whiteLabelSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import axios from "../../utils/axios";
import { sortAndGroupBySigningOrderNo } from "../SigningOrderDiagram";
import { validateEmail } from "../../utils/validateEmail";
import { googleTranslateElementInit } from "../../utils/uiUtils";
import { getIpAddressLocal } from "../../hooks/reduxHooks/useIpHook";
type Props = {
  loading: boolean;
  accountInfo: any;
  documentDetails: any;
  roles: { title: string; colour: string; signingOrderNo?: number }[];
};

type RoleDetailsType = {
  email: string;
  name: string;
  message: string;
  roleTitle: string;
  roleColour: string;
  signingOrderNo?: number;
  isEnabled?: true;
};

const SendETemplateToRoles = ({
  loading,
  accountInfo,
  documentDetails,
  roles,
}: Props) => {
  const dispatch = useAppDispatch();
  const whiteLabelInfo = useAppSelector(
    (state) => state.root.whiteLabelSlice.data.whiteLabelOwner
  );

  const [takingDetailsOfRole, setTakingDetailsOfRole] = useState(0);

  const [receiversList, setReceiversList] = useState<RoleDetailsType[]>([]);

  const [isSending, setIsSending] = useState(false);

  // const googleTranslateElementInit = () => {
  //   new (window as any).google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       includedLanguages:
  //         "en,fr,es,de,id,pt,nl,zh-CN,th,ja,ar,iw,cs,da,fil,it,ru,tr,vi",
  //     },
  //     "google_translate_element"
  //   );

  //   let skiptranslate = document.querySelector(".skiptranslate");
  //   if (skiptranslate?.lastChild) {
  //     skiptranslate.removeChild(skiptranslate.lastChild);
  //   }
  //   // removes google translate script error
  //   if (skiptranslate?.lastChild) {
  //     skiptranslate.removeChild(skiptranslate.lastChild);
  //   }
  // };

  useEffect(() => {
    let googleTranslateScript = document.getElementById(
      "google_translate_script"
    );
    // removes set attribute error for google translate
    if (googleTranslateScript) {
    } else {
      googleTranslateScript = document.createElement("script");
      googleTranslateScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      googleTranslateScript.setAttribute("id", "google_translate_script");
      document.body.appendChild(googleTranslateScript);
      (window as any).googleTranslateElementInit = googleTranslateElementInit;
    }
  }, []);

  useEffect(() => {
    let receivers = roles.map((role, index) => {
      let roleDetails: RoleDetailsType = {
        email: "",
        name: "",
        message: "",
        roleTitle: role.title,
        roleColour: role.colour,
      };

      if (role?.signingOrderNo) {
        roleDetails.signingOrderNo = role.signingOrderNo;
      }

      return roleDetails;
    });

    setReceiversList(receivers);
  }, [roles]);

  useEffect(() => {
    const selectedLanguage = accountInfo?.selectedLanguage;
    if (selectedLanguage) {
      window.location.hash = `googtrans(${selectedLanguage})`;
    }
  }, []);

  // commenting this out i dont know why i did this twice
  // useEffect(() => {
  //   dispatch(getWhiteLabelInfoThunk());
  // }, []);

  const startSigningHandler = async () => {
    try {
      setIsSending(true);
      const ipResponse = getIpAddressLocal();
      const ipData = await ipResponse;
      const ip = ipData.ip;

      const { data } = await axios.post(`/public/add-respondent-pdfTemplate`, {
        documentId: documentDetails?.documentId,
        receiversList: receiversList,
        // mailData, // TODO: add mail data
        ownerId: documentDetails?.ownerId,
        ip,
        isSigningOrderData: documentDetails?.isSigningOrderData,
        // customVariables, // TODO: adding custom Variables
        sentAsETemplate: true,
        workspaceIdFromReqBody: documentDetails?.workSpaceId,
      });
      if (data.error) {
        if (data.error === "You have exhausted your allowed quota") {
          toast.error(
            "The owner of this document have exhausted the allowed quota."
          );
        }
        toast.error(data.error);
        setIsSending(false);
      } else {
        toast.success("Successfully sent");

        let currentSignerUrl = data?.createdRespondents?.signers[0]?.sentUrl;

        if (currentSignerUrl) {
          setTimeout(() => {
            window.open(currentSignerUrl, "_self");
          }, 1000);
        }
      }
    } catch (error: any) {
      console.log("Error in start signing ", error);
      toast.error(error.message);
      setIsSending(false);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <div className="fixed h-screen bg-black bg-opacity-20 inset-0 z-10 flex flex-col p-2">
      {/* <div
        className="flex absolute top-5 right-6 ml-auto gap-2 p-1 bg-primary/80 hover:text-primary rounded-full transition-all ease-in-out duration-300 shadow hover:scale-110"
        style={{
          background: accountInfo?.theme,
          color: accountInfo?.themeLight,
        }}
      >
        <div className="flex justify-start items-center p-[10px] gap-4">
          <IoLanguageSharp className={`text-lg md:text-xl xl:text-2xl`} />
        </div>
        <div
          id="google_translate_element"
          className="absolute inset-0 h-full opacity-0 cursor-pointer"
        ></div>
      </div> */}
      <div className="m-auto max-w-full w-[500px] bg-slate-50 rounded-sm text-gray-700 flex flex-col justify-between">
        <div className="p-10">
          <h1 className="text-2xl font-extrabold mb-4">
            {documentDetails?.documentName}
          </h1>
          <p className="text-sm w-full md:w-[70%] mb-2">
            Enter your contact information to start the signing process.
          </p>

          <p className="font-semibold text-sm my-4 flex items-center gap-2">
            {roles[takingDetailsOfRole].title || "Sample Role 1"}{" "}
            {takingDetailsOfRole === 0 && (
              <span className="text-gray-500 font-normal ">(you)</span>
            )}
            <div
              className="w-5 h-2 rounded-sm"
              style={{
                backgroundColor: roles[takingDetailsOfRole].colour,
              }}
            ></div>
          </p>
          <div className="flex flex-col gap-4 text-sm">
            <input
              type="email"
              placeholder="Email"
              className="w-full p-2 bg-stone-100 border-b-[1.5px] border-gray-400 outline-none"
              value={receiversList[takingDetailsOfRole]?.email}
              onChange={(e) => {
                setReceiversList((prevReceiversList) =>
                  prevReceiversList.map((receiver, index) =>
                    index === takingDetailsOfRole
                      ? { ...receiver, email: e.target.value }
                      : receiver
                  )
                );
              }}
            />
            <input
              type="text"
              placeholder="Full Name"
              className="w-full p-2 bg-stone-100 border-b-[1.5px] border-gray-400 outline-none"
              value={receiversList[takingDetailsOfRole]?.name}
              onChange={(e) => {
                setReceiversList((prevReceiversList) =>
                  prevReceiversList.map((receiver, index) =>
                    index === takingDetailsOfRole
                      ? { ...receiver, name: e.target.value }
                      : receiver
                  )
                );
              }}
            />
          </div>
          <div className="flex justify-between mt-6 text-sm w-full">
            {takingDetailsOfRole > 0 && (
              <button
                className="rounded-sm px-4 py-1 font-medium bg-stone-200 text-gray-400 shadow-sm"
                onClick={() => {
                  if (takingDetailsOfRole > 0) {
                    setTakingDetailsOfRole((prev) => prev - 1);
                  }
                }}
              >
                Back
              </button>
            )}
            <button
              className="max-w-full ml-auto rounded-sm px-4 py-1 font-medium shadow-sm"
              style={{
                background: accountInfo?.theme || COLORS.primary,
                color: accountInfo?.themeText || COLORS.secondary,
              }}
              onClick={async () => {
                if (
                  !receiversList[takingDetailsOfRole].email ||
                  !receiversList[takingDetailsOfRole].name
                ) {
                  toast.error("Please fill the required fields");
                  return;
                }

                if (!validateEmail(receiversList[takingDetailsOfRole].email)) {
                  toast.error("Please enter a valid email address");
                  return;
                }

                if (takingDetailsOfRole < roles.length - 1) {
                  setTakingDetailsOfRole((prev) => prev + 1);
                }

                // this is the start signing logic

                if (takingDetailsOfRole === roles.length - 1) {
                  console.log("start signing", receiversList);
                  await startSigningHandler();
                }
              }}
            >
              {isSending
                ? "Please wait.."
                : takingDetailsOfRole === roles.length - 1
                ? "Start signing"
                : "Next"}
            </button>
          </div>
        </div>

        <div className="text-[9px] bg-stone-200 py-4 flex items-center justify-center">
          <p className="w-[90%] md:w-[70%] text-center">
            By clicking the button above you are accepting BoloForm's{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                window.open(
                  "https://www.boloforms.com/privacy-policy/",
                  "_blank"
                );
              }}
            >
              Privacy Policy.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SendETemplateToRoles;
