//@ts-nocheck
import React from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import MixPanel from "../../utils/services/mixpanel";

const DeleteFolderModal = ({ item, setShow, stateUpdate }) => {
  const handleDeleteFolder = async (folderId) => {
    try {
      const { data } = await axios.post("/folder/delete", {
        folderId: folderId,
      });
      if (data) {
        console.log(data);
      }
      new MixPanel().track("Folder", {
        folder_action: "folder_delete",
        paid: false,
      });
      new MixPanel().increment("folder_delete");
      stateUpdate && stateUpdate((prev) => !prev);
      setShow && setShow(false);
    } catch (error) {
      toast.error("Could not Delete Folder");
      console.log(error);
    }
  };

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 100,
      }}
      className="w-full h-screen fixed inset-0 flex justify-center items-center"
    >
      <div
        className={`min-h-fit max-h-[650px] w-[95%] md:w-[700px] relative bg-white rounded-lg md:rounded-xl px-4 md:px-8 py-2 md:py-5 overflow-y-auto`}
      >
        <div className="relative flex flex-col">
          <div className="text-base md:text-lg mb-4 font-semibold">
            Delete Folder
          </div>
          <MdClose
            className="text-xl md:text-2xl cursor-pointer absolute top-0 right-0"
            onClick={() => {
              setShow(false);
            }}
          />
          <span className="text-xs md:text-sm">
            Deleting the folder{" "}
            <span className="text-red-600 font-semibold">
              {item?.value?.name}
            </span>{" "}
            will delete it and it's subfolders.
          </span>
          <span className="text-xs md:text-sm">
            Any Forms, PDFs and Templates will not be deleted they will be still
            accessible from the dashboard.
          </span>
          <div className="flex gap-2 items-center justify-end mt-8">
            <button
              className="text-xs md:text-sm text-primary border px-4 py-2 rounded-md"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              className="text-xs md:text-sm text-white bg-red-600 px-4 py-2 rounded-md"
              onClick={() => {
                handleDeleteFolder(item?.id);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFolderModal;
