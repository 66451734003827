import React from "react";

export default function MobileDeviceNotSupported() {
  return (
    <div className="m-display flex-col items-center justify-center h-[100vh] gap-4">
      <img
        src="https://boloforms-internal-images.s3.ap-south-1.amazonaws.com/approval-images/tired-employee-exhausted-with-work_74855-4820.avif"
        alt="logo"
        className="h-[50%] "
        loading="lazy"
      />
      <span>Sorry Mobile devices are not supported yet !!!</span>
      <button
        className="border bg-primary hover:shadow text-white py-1 px-2 rounded-[3px] text-sm text-center w-fit"
        onClick={() => window.history.back()}
      >
        Go Back
      </button>
    </div>
  );
}
