//@ts-nocheck
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { FormControlLabel, Radio } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { BASE_URL } from "../../../constants/constants";
import useDrivePicker from "react-google-drive-picker";
import axios from "../../../utils/axios";
import { toast } from "react-toastify";
import CustomTooltip from "../CustomTooltip";
import { COLORS } from "../../../constants/common";

const GoogleSheetModal = ({ helpers }) => {
  const [selectedOpt, setSelectedOpt] = useState("new");
  const [isLoadingSpreadsheet, setIsLoadingSpreadsheet] = useState(false);

  let {
    formState,
    setShow,
    setIntegrations,
    updateIntegrationStatus,
    isIntegrationPermissionAvailable,
    owner,
    formId,
  } = helpers;

  const [openPicker, authResponse] = useDrivePicker();

  const handleRadioChange = (value) => {
    setSelectedOpt(value);
  };

  const handleCreateSpreadsheet = async () => {
    setIsLoadingSpreadsheet(true);
    try {
      await updateIntegrationStatus(true, "googleSheet");
      setIsLoadingSpreadsheet(false);
      setShow((prev) => ({ ...prev, googleSheetModal: false }));
    } catch (error) {
      setIsLoadingSpreadsheet(false);
    }
  };

  const handleSelectSpreadsheet = async () => {
    let isSufficientPermission =
      isIntegrationPermissionAvailable("googleSheet");

    if (!isSufficientPermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=sheets&redirectUrl=/create/forms?id=${formId}`,
        "_self"
      );
      return;
    }
    const { data: permissionDetails } = await axios.post(
      `/forms/check-google-permission`,
      {
        ownerEmail: owner?.email,
        formId: formId,
        isFormDashboard: true,
      }
    );
    if (!permissionDetails?.isSheetsPermission) {
      window.open(
        `${BASE_URL}/auth/google?ownerEmail=${owner?.email}&scopesType=sheets&redirectUrl=/create/forms?id=${formId}`,
        "_self"
      );
      return;
    }
    let clientId, developerKey;

    try {
      const { data } = await axios.get(
        `${BASE_URL}/common/get-google-credentials`
      );
      clientId = data?.clientId;
      developerKey = data?.developerKey;
    } catch (error) {
      console.error(
        "Error fetching client ID and developer key from the backend",
        error
      );
    }

    if (clientId && developerKey) {
      openPicker({
        clientId,
        developerKey,
        token: owner?.integrationTokens?.google?.oAuthToken,
        viewId: "SPREADSHEETS",
        showUploadView: false,
        multiselect: false,
        appId: 204560571245,
        callbackFunction: async (sheetsData) => {
          if (sheetsData.action === "cancel") {
            console.log("User clicked cancel/close button");
          }

          if (sheetsData?.docs) {
            try {
              await axios.put(`${BASE_URL}/forms/spreadsheet-url`, {
                formId,
                destinationId: sheetsData?.docs[0]?.id,
                spreadsheetUrl: sheetsData?.docs[0]?.url,
              });

              setShow((prev) => ({ ...prev, googleSheetModal: false }));
              toast.success("Spreadsheet updated successfully");

              setIntegrations((prev) => {
                return {
                  ...prev,
                  googleSheet: {
                    ...prev.googleSheet,
                    enabled: true,
                    destinationId: sheetsData?.docs[0]?.id,
                    spreadsheetUrl: sheetsData?.docs[0]?.url,
                  },
                };
              });
            } catch (error) {
              console.log("error", error);
              toast.error("Something went wrong");
            }
          }
        },
      });
    }
  };

  return (
    <div className="px-[38px] pt-[40px] fixed inset-0 bg-black bg-opacity-50 flex justify-center">
      <div className="w-[550px] max-h-[90vh] h-fit overflow-y-auto bg-white rounded-lg border pl-6 pr-4 pt-2 pb-1">
        <div className="flex justify-between items-center">
          <h2 className="text-md font-medium">
            Select destination for responses
          </h2>
          <div
            data-tooltip-id="close"
            data-tooltip-offset={0}
            onClick={() => {
              setShow((prev) => ({ ...prev, googleSheetModal: false }));
            }}
            className="ml-auto w-[48px] h-[48px] flex justify-center items-center hover:bg-gray-50 cursor-pointer rounded-full"
          >
            <MdClose className="text-2xl text-gray-600" />
          </div>
          <CustomTooltip
            helpers={{
              id: "close",
              content: "Close",
              place: "bottom",
            }}
          />
        </div>
        <div className="pt-4 h-[170px]">
          {isLoadingSpreadsheet ? (
            <div className="flex h-full items-center justify-center">
              <TailSpin
                height="40"
                width="40"
                color={formState?.accentColor ?? COLORS.primary}
              />
            </div>
          ) : (
            <div className="h-full">
              <div className="flex flex-col gap-y-3">
                <div className="flex flex-row items-center">
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: formState?.accentColor ?? COLORS.primary,
                          },
                          height: "40px",
                          width: "40px",
                        }}
                        checked={selectedOpt === "new"}
                        onChange={() => handleRadioChange("new")}
                      />
                    }
                    label={
                      <p className="text-sm text-[#202124]">
                        Create a new spreadsheet
                      </p>
                    }
                  />
                </div>

                <div className="flex flex-row items-center">
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: formState?.accentColor ?? COLORS.primary,
                          },
                          height: "40px",
                          width: "40px",
                        }}
                        checked={selectedOpt === "existing"}
                        onChange={() => handleRadioChange("existing")}
                      />
                    }
                    label={
                      <p className="text-sm font-medium">
                        Select existing spreadsheet
                      </p>
                    }
                  />
                </div>
              </div>
              <div className="flex items-center justify-end pt-4">
                <div className="flex gap-x-3">
                  <button
                    onClick={() => {
                      setShow((prev) => ({ ...prev, googleSheetModal: false }));
                    }}
                    className="text-sm text-[#808181] p-[6px] hover:bg-[#f8f8f8] font-medium rounded-sm"
                  >
                    Cancel
                  </button>
                  {selectedOpt === "new" ? (
                    <button
                      onClick={handleCreateSpreadsheet}
                      style={{
                        color: formState?.accentColor || COLORS.primary,
                      }}
                      className="text-sm p-[6px] hover:bg-[#f8f8f8] font-medium rounded-sm"
                    >
                      Create
                    </button>
                  ) : (
                    <button
                      onClick={handleSelectSpreadsheet}
                      style={{
                        color: formState?.accentColor || COLORS.primary,
                      }}
                      className="text-sm p-[6px] hover:bg-[#f8f8f8] font-medium rounded-sm"
                    >
                      Select
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoogleSheetModal;
