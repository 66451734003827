import React, { useEffect, useState } from "react";
import { UTIL_ROUTES } from "../../constants/constants";
import axios from "../../utils/axios";

type IpDataType = { ip: string; error?: string };

export const getIpAddressLocal = (): IpDataType => {
  return { ip: localStorage.getItem("ip_data") || "" };
};

export const getIpAddressRemote = async (): Promise<IpDataType> => {
  try {
    let ip = "";
    const { data } = await axios.get(UTIL_ROUTES.IP);
    ip = data?.ip || "";
    localStorage.setItem("ip_data", ip);
    return { ip };
  } catch (error) {
    console.log("Error in getIpAddress");
    if (error instanceof Error) {
      return { ip: "", error: error.message };
    } else {
      return { ip: "", error: "Something went wrong in getIpAddress" };
    }
  }
};

const useIpHook = () => {
  const [ipData, setIpData] = useState<IpDataType>({
    ip: localStorage.getItem("ip_data") || "",
  });

  async function getIpData() {
    let ipData = await getIpAddressRemote();
    setIpData(ipData);
  }

  useEffect(() => {
    getIpData();
  }, []);

  return { ipData };
};

export default useIpHook;
